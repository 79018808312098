import * as React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";

interface TroubleshootingModalState {
  openScreenShareHelp: boolean;
}

interface TroubleshootingModalProps {
  openModal: number;
}

export default class TroubleshootingModal extends React.Component<
  TroubleshootingModalProps,
  TroubleshootingModalState
> {
  constructor(props: TroubleshootingModalProps) {
    super(props);

    this.state = {
      openScreenShareHelp: false,
    };

    this.closeScreenShareHelp = this.closeScreenShareHelp.bind(this);
  }

  public componentDidUpdate(prevProps: TroubleshootingModalProps): void {
    const { openModal } = this.props;
    if (prevProps.openModal !== openModal) {
      this.setState({ openScreenShareHelp: true });
    }
  }

  public closeScreenShareHelp(): void {
    this.setState({ openScreenShareHelp: false });
  }

  public render(): JSX.Element {
    const { openScreenShareHelp } = this.state;

    return (
      <Modal show={openScreenShareHelp} onHide={this.closeScreenShareHelp} size="lg" backdrop="static">
        <div className="general-wizard">
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Screen Share Troubleshooting</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <h3>
                      <strong>Question 1</strong>: Did the user receive the email?
                    </h3>

                    <ul className="screen-share-troubles">
                      <li>
                        <u>If no:</u> <br />
                        <br />
                        <ul>
                          <li>
                            <strong>Option 1</strong>: Have the user check their SPAM or Junk Email Box.
                          </li>
                          <li>
                            <strong>Option 2</strong>: The Screen Share invite email was also sent to the Job's Sales
                            Representative. You can simply access your email and forward to the user.
                          </li>
                          <li>
                            <strong>Option 3</strong>: Try another email address for the user.
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <u>If yes, skip to Question 2.</u>
                      </li>
                    </ul>

                    <br />
                    <br />

                    <h3>
                      <strong>Question 2</strong>: The user has received the email and is unable to access the screen
                      share.
                    </h3>

                    <h4>
                      <u>
                        <strong>The User receives the "Waiting for Presenter" screen.</strong>
                      </u>
                    </h4>
                    <ul className="screen-share-troubles">
                      <li>
                        Have you started the screen share? If not, start the screen share and the user should be
                        connected momentarily.
                      </li>
                      <li>
                        If you already started the screen share, allow 5 to 10 seconds for the session to connect. If
                        the user is still unable to access the screen share, please have the user click the refresh
                        button.
                      </li>
                      <li>If that does not work, try the options below.</li>
                    </ul>

                    <br />
                    <br />

                    <h4>
                      <u>
                        <strong>The user receives a blank white screen or is unable to connect.</strong>
                      </u>
                    </h4>
                    <ul className="screen-share-troubles">
                      <li>
                        In most cases, this is caused by an unsupported browser. Please ask the user which browser they
                        are using. We support Internet Explorer 10+, the latest versions of Edge, Firefox, Safari, and
                        Chrome.
                      </li>
                      <li>
                        At the bottom of the screen share email invitation, there is a link you can have the user copy
                        and paste into the other browser.
                      </li>
                      <li>
                        You can also text the user an invitation to the Screen Share for access on a mobile device.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <Button variant="cancel" type="button" onClick={this.closeScreenShareHelp}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
