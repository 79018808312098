import { IJob, IJobAssignment } from "app/src/Models/Job";
import { IAddress } from "app/src/Models/Address";
import { IUserResponse, IUser } from "app/src/Models/User";
import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { ISession } from "app/src/Common/SessionService";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { BaseTabCtrl } from "app/src/Jobs/tabs/BaseTabCtrl";
import * as angulartics from "angulartics";
import { IDirtyMerge, IDirtyWatcher } from "app/src/Common/DirtyWatcher";
import { IPhoneNumber } from "../../Models/PhoneNumber";
import { IRepository } from "app/src/Common/Repository";
import { IPretty } from "../../Common/PrettyNameService";
import { JobInfoTourService } from "../../Common/Tours/JobInfoTourService";
import { RsfRootScope } from "../../Common/RsfRootScope";
import { INavDisplayArgs } from "../JobShowCtrl";
import { IEventingFactory } from "../../Common/EventingFactory";
import { IJobStateMetadata } from "app/src/Models/Org";
import { subscriber } from "app2/src/helpers/Subscribe";
import { dispatch, useSelector } from "app2/src/storeRegistry";
import { JobObjectRecord, JobAttributeRecordType } from "app2/src/records/JobAttribute";
import { JobAttributeState } from "app2/src/reducers/jobAttribute.reducer";
import * as JobAttributeActions from "app2/src/reducers/jobAttribute.actions";
import { List } from "immutable";
import { getActiveJobAttributesList } from "app2/src/selectors/jobAttribute.selectors";
import * as jobService from "app2/src/api/job.service";
import { IOrgData, JobAttributeTypes } from "app2/src/records/OrgRecord";
import * as orgActions from "app2/src/reducers/org.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import { emailListRegex } from "app2/src/helpers/InputValidator";

export class InfoTabCtrl extends BaseTabCtrl implements IDirtyMerge {
  public form: { ctrl: ng.IFormController } = { ctrl: null };
  public stateAction: string = null;
  public state = "new";
  public assignedUser: IUser;
  public orgUsers: { [id: number]: IUserResponse } = {};
  public phoneNames: string[] = ["Home", "Mobile", "Office", "Other"];
  public userResult: IUserResponse;
  public leadSources: string[] = ["Manual"];
  public addLeadSource = false;
  public newPhoneNumber: IPhoneNumber = <IPhoneNumber>{ name: "", number: "" };
  public stateInfo: IJobStateMetadata;
  public jobTypes: List<JobAttributeRecordType> = List();
  public useJobAddress = true;
  public emailListPattern = emailListRegex;

  private _isSaveable = true;
  private _defaultLeadSources: string[] = ["Manual"];
  private _jobNameChanged = false;
  private _originalState: any;
  private _originalUser: number;
  private _currentJobtypes: JobAttributeRecordType[];

  public static $inject = [
    "JobFetcher",
    "Repository",
    "Flash",
    "NgMap",
    "Session",
    "US_STATES",
    "$q",
    "$timeout",
    "DirtyWatcher",
    "$scope",
    "$state",
    "$stateParams",
    "$window",
    "$analytics",
    "COUNTRIES",
    "Pretty",
    "GeoCoder",
    "JobInfoTourService",
    "$rootScope",
    "EventingFactory",
  ];
  constructor(
    public JobFetcher: IJobFetcherService,
    public Repository: IRepository,
    public Flash: IFlash,
    public ngMap: angular.map.INgMap,
    public Session: ISession,
    public US_STATES: string[],
    public $q: ng.IQService,
    public $timeout: ng.ITimeoutService,
    public DirtyWatcher: IDirtyWatcher,
    public $scope: ng.IScope,
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public $window: ng.IWindowService,
    protected $analytics: angulartics.IAnalyticsService,
    public COUNTRIES: string[],
    public Pretty: IPretty,
    public GeoCoder: any,
    public JobInfoTourService: JobInfoTourService,
    public $rootScope: RsfRootScope,
    public EventingFactory: IEventingFactory,
  ) {
    super(JobFetcher, $scope, $analytics, $window, $stateParams["id"]);

    const unsubscribe = subscriber.subscribe<JobAttributeState>("jobAttributes", (data) => {
      this.jobTypes = useSelector((state) =>
        getActiveJobAttributesList(state, { orgId: this.job.org_id, jobAttrType: JobAttributeTypes.job_type } as any),
      );
    });

    if ($stateParams["id"] && $stateParams["id"] !== "new") {
      this.spinnerPromise = this.job.$promise.then(() => {
        this.initPhoneNumber();
        this.initUseJobAddress();
        this.analyticsInit();

        dispatch(
          JobAttributeActions.Actions.receiveJobAttributes(
            this.job.org.id,
            this.job.org.job_types,
            JobAttributeTypes.job_type,
          ),
        );

        return this.orgChanged().then(() => {
          this.reset();
          DirtyWatcher.setup($scope, this);
        });
      });

      this.state = "edit";
    } else {
      this.job = Repository.Job.fromJSON({
        state: "New",
        name: "",
        customer_name: "",
        email: "",
        lead_source: "Manual",
        phone_numbers: [],
        job_types: [],
        address: Repository.Address.fromJSON({
          line_1: "",
          line_2: "",
          city: "",
          state: "",
          postal_code: "",
          zip: "",
          country: "",
        }),
        customer_address: Repository.Address.fromJSON({
          line_1: "",
          line_2: "",
          city: "",
          state: "",
          postal_code: "",
          zip: "",
          country: "",
        }),
        use_job_address: "yes",
      });
      this.useJobAddress = true;
      this.initPhoneNumber();

      Session.currentUser.$promise.then(() => {
        this.job.org = Repository.Org.get({
          id: Session.currentUser.org_id,
          "include[]": ["settings", "lead_sources", "job_states", "job_types"],
        });
        this.job.org.$promise.then(() => {
          this.job.org_id = this.job.org.id;

          dispatch(
            JobAttributeActions.Actions.receiveJobAttributes(
              this.job.org.id,
              this.job.org.job_types,
              JobAttributeTypes.job_type,
            ),
          );

          this.analyticsInit();
          this.orgChanged().then(() => {
            this.reset();
            DirtyWatcher.setup($scope, this);
          });
        });
      });

      this.state = "new";
    }

    const helperListener = $rootScope.$on("header:help:job_info", (event: ng.IAngularEvent, args: INavDisplayArgs) => {
      this.tourInit();
    });
    this.tourInit();

    $scope.$on("$destroy", () => {
      helperListener();
      unsubscribe();
    });

    this.updateJobTypes = this.updateJobTypes.bind(this);
  }

  public tourInit(): void {
    this.JobInfoTourService.init({
      jobInfoCtrl: this,
    });
  }

  public analyticsInit(): void {
    this.EventingFactory.init({
      category: "Job",
      job: this.job.id,
      org: this.job.org_id,
      navDisplay: !this.navDisplay,
    });
  }

  public initPhoneNumber() {
    if (this.job.phone_numbers.length > 0) {
      return;
    }
    this.addPhoneNumber();
  }

  public addPhoneNumber() {
    // ensure that a new number gets a value less than 0, but an already used one
    this.newPhoneNumber.id =
      _.chain(this.job.phone_numbers)
        .pluck("id")
        .tap((value) => {
          value.push(0);
        })
        .min()
        .value() - 1;

    this.job.phone_numbers.push(this.newPhoneNumber);
    this.newPhoneNumber = <IPhoneNumber>{ name: "", number: "" };
    this.trackEvent("phone added", {
      category: "Job",
    });
  }

  public removePhoneNumber(phoneNumber: any) {
    if (phoneNumber.id <= 0) {
      this.job.phone_numbers = _.filter(this.job.phone_numbers, (pn) => {
        return pn.id !== phoneNumber.id;
      });
    } else {
      phoneNumber._destroy = true;
    }

    this.trackEvent("phone removed", {
      category: "Job",
    });
  }

  public async unarchiveJob() {
    const { org_id, id } = this.job;
    try {
      await jobService.unarchive({ org_id, id });
      this.Flash.addMessage(FlashLevels.success, "Job Unarchived");
      this.trackEvent("job unarchived", {
        category: "Job",
      });
      this.$state.reload();
    } catch (error) {
      this.Flash.addMessage(FlashLevels.danger, "Unable to unarchive Job");
    }
  }

  public enableLeadSourceAdd() {
    this.addLeadSource = true;
    this.job.lead_source = "";
  }

  public addNewLeadSource() {
    if (this.job.lead_source !== "") {
      this.leadSources = _.uniq(this._defaultLeadSources.concat(this.job.org.lead_sources || []));
      this.leadSources.push(this.job.lead_source);
    } else {
      this.job.lead_source = "Manual";
    }

    this.addLeadSource = false;

    this.trackEvent("lead_source added", {
      category: "Job",
      leadSource: this.job.lead_source,
    });
  }

  public jobJobTypes() {
    if (this._currentJobtypes) {
      return this._currentJobtypes;
    }

    if (!this.job || !this.job.job_types || (this.jobTypes.size <= 0 && this.$stateParams["id"] !== "new")) {
      return;
    }

    this._currentJobtypes = this.job.job_types.map((jt) => this.jobTypes.find((record) => record.id === jt.id));
    return this._currentJobtypes;
  }

  public updateJobTypes(jobTypes: JobObjectRecord[]) {
    this.$scope.$apply(() => {
      this.job.job_types = jobTypes.map((jt) => jt.toJS()) as any;
      this._currentJobtypes = undefined;
    });
  }

  public addressChanged() {
    this._isSaveable = false;
    this.debouncedAddressChanged();
  }

  public locationUpdated(address: IAddress) {
    this.job.address.lat = Math.round(address.lat * Math.pow(10, 6)) / Math.pow(10, 6);
    this.job.address.lon = Math.round(address.lon * Math.pow(10, 6)) / Math.pow(10, 6);
    this.$scope.$digest();
  }

  public deleteJob() {
    this.job
      .$delete()
      .then(() => {
        this.Flash.addMessage(FlashLevels.success, "Job Archived");
        this.trackEvent("job deleted", {
          category: "Job",
        });
        this.$state.go("root.job_list");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "Problems archiving Job!!");
      });
  }

  public save() {
    let assignment: IUser;

    // if it"s not new and the currently selected is not already assigned, we"ll have to assign it
    if (this.job.id) {
      if (!this.job.assigned_to(this.assignedUser)) {
        assignment = this.assignedUser;
        this.trackEvent("assigned", {
          category: "Job",
          assignedTo: this.assignedUser.id,
        });
      }
    } else {
      // else we need to setup the "built in" assignment stuff up to assign on creation so the current user
      // definitely has permissions
      this.job.assignments = [
        {
          user_id: this.assignedUser.id,
        } as any as IJobAssignment,
      ];
    }

    this.spinnerPromise = this.job
      .$saveOrCreate({ "include[]": this.JobFetcher.includes })
      .then(() => {
        dispatch(jobActions.Actions.receiveJob(JSON.decycle(this.job)));
        const promises: ng.IPromise<any>[] = [];
        if (assignment) {
          promises.push(this.job.$assign({ user_id: assignment.id, "include[]": this.JobFetcher.includes }));
        }
        if (this.stateAction !== null && this.stateAction !== "") {
          this.trackEvent("state changed", {
            category: "Job",
            jobState: this.stateAction,
          });
          promises.push(this.performSelectedAction());
        }

        return this.$q.all(promises).then(() => {
          dispatch(orgActions.Actions.setOrg(this.job.org as any as IOrgData));
          this.userResult.users = _.clone(this.orgUsers[this.job.org_id].users);
          this.setAssignedUser(this.job.org_id, this.job.assignments[0].user.id);
          if (this.$stateParams["id"] === "new") {
            this.job.$promise = this.spinnerPromise;
            this.JobFetcher.set(this.job);
            this.reset();
            this.trackEvent("created", {
              category: "Job",
            });
            this.$state.go("job_header.job_show.info", { id: this.job.id }, { notify: false });
          } else {
            this.initPhoneNumber();
            this.reset();
            this.trackEvent("updated", {
              category: "Job",
            });
          }

          this.Flash.addMessage(FlashLevels.success, "Job Saved");
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "Problems saving Job.");
      });
  }

  public saveable() {
    return this._isSaveable;
  }

  public customerNameChanged() {
    if (this.state === "edit") {
      return;
    }

    if (this._jobNameChanged) {
      return;
    }

    this.job.name = this.job.customer_name;
  }

  public jobNameChanged() {
    this._jobNameChanged = true;
  }

  public orgChanged(): ng.IPromise<any> {
    this.job.org_id = this.job.org.id;
    this.leadSources = _.uniq(this._defaultLeadSources.concat(this.job.org.lead_sources || []));

    this.stateInfo = this.job.org.job_states;

    return this.loadOrgUsers(this.job.org_id);
  }

  public reset(): ng.IPromise<any> {
    this._originalState = angular.toJson(this.Repository.Job.transformRequest(this.job.cloneMin()));
    this._originalUser = this.assignedUser.id;
    this.stateAction = null;
    this.form.ctrl.$rollbackViewValue();
    const deferred = this.$q.defer();
    this.spinnerPromise = deferred.promise;
    deferred.resolve();
    return deferred.promise;
  }

  public check(): boolean {
    if (!this.job) {
      return false;
    }

    return (
      (this._originalState !== "" &&
        this._originalState !== angular.toJson(this.Repository.Job.transformRequest(this.job.cloneMin()))) ||
      (this.stateAction !== null && this.stateAction !== "") ||
      this._originalUser !== this.assignedUser.id
    );
  }

  public nameFromRawState() {
    if (!this.stateInfo) {
      return "";
    }

    const state = this.job.raw_state;
    if (this.stateInfo && this.stateInfo.state_info[state] && this.stateInfo.state_info[state].pretty_name) {
      return this.stateInfo.state_info[state].pretty_name;
    }

    return this.stateInfo.states[state];
  }

  public toggleUseJobAddress(): void {
    if (this.useJobAddress) {
      this.job.use_job_address = "yes";
    } else {
      this.job.use_job_address = "no";
    }
  }

  public initUseJobAddress() {
    this.useJobAddress = this.job.use_job_address === "yes" ? true : false;
  }

  private loadOrgUsers(orgId: number): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const promises = [];
    promises.push(deferred.promise);
    if (!this.orgUsers[orgId]) {
      this.orgUsers[orgId] = <IUserResponse>this.Repository.User.query({
        org_id: this.job.org_id,
        version: "v2",
      });
      promises.push(this.orgUsers[orgId].$promise);
    }

    deferred.resolve();
    return this.$q.all(promises).then(() => {
      this.userResult = <IUserResponse>{};
      this.userResult.users = _.clone(this.orgUsers[orgId].users);
      if (this.job && _.isArray(this.job.assignments) && this.job.assignments.length > 0) {
        this.setAssignedUser(orgId, this.job.assignments[0].user.id);
      } else {
        this.setAssignedUser(orgId, this.Session.currentUser.id);
      }
    });
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  public debouncedAddressChanged = _.debounce(() => {
    this.GeoCoder.geocode({ address: this.job.address.fullAddress() })
      .then((results: any) => {
        const geoLocation = results[0].geometry.location;

        this.job.address.lat = _.round(geoLocation.lat(), -6);
        this.job.address.lon = _.round(geoLocation.lng(), -6);

        const area_2 = _.find(results[0].address_components, function (ac: any) {
          return _.contains(ac["types"], "administrative_area_level_2");
        });

        const area_3 = _.find(results[0].address_components, function (ac: any) {
          return _.contains(ac["types"], "administrative_area_level_3");
        });

        if (_.isUndefined(area_2["short_name"])) {
          this.job.address.administrative_area_level_2 = "";
        } else {
          this.job.address.administrative_area_level_2 = area_2["short_name"];
        }

        if (_.isUndefined(area_3["short_name"])) {
          this.job.address.administrative_area_level_3 = "";
        } else {
          this.job.address.administrative_area_level_3 = area_3["short_name"];
        }
      })
      .finally(() => {
        this._isSaveable = true;
      });
  }, 500);
  /* tslint:enable:member-ordering */

  private setAssignedUser(orgId, userId) {
    this.assignedUser = _.find(this.orgUsers[orgId].users, (user: IUser) => {
      return user.id === userId;
    });

    if (!this.assignedUser && _.isArray(this.job.assignments) && this.job.assignments.length > 0) {
      this.job.assignments[0].user.last_name += " INACTIVE";
      this.assignedUser = this.job.assignments[0].user;
      this.userResult.users.push(this.assignedUser);
    } else if (!this.assignedUser) {
      this.assignedUser = this.orgUsers[orgId].users[0];
    }
  }

  private performSelectedAction(): ng.IPromise<any> {
    return this.job
      .$change_state({
        event: this.stateAction,
        test: true,
        "include[]": this.JobFetcher.includes,
      })
      .then(() => {
        this.stateAction = null;
      });
  }
}
