import { IProduct } from "app/src/Models/Product";

export class OrgParentSelectorCtrl {
  public static $inject = ["$uibModalInstance", "currentSelection", "product"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public currentSelection: IProduct[],
    public product: IProduct,
  ) {
    this.currentSelection = _.filter(this.currentSelection, (p) => {
      return this.product.id !== p.id;
    });
  }

  public makeSelection(product: IProduct) {
    this.$uibModalInstance.close(product);
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }
}
