import * as React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useTracking } from "react-tracking";
import { push } from "connected-react-router/immutable";
import { currentJobId as currentJobIdSelector } from "app2/src/selectors/job.selectors";
import { useSelector, useDispatch } from "react-redux";
import { Context } from "app2/src/components/Homevest/Context";

interface HomevestModalProps {
  children: JSX.Element;
}

const HomevestModal: React.FunctionComponent<HomevestModalProps> = ({ children }) => {
  const { estimate } = React.useContext(Context);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { trackEvent } = useTracking({});

  const currentJobId = useSelector(currentJobIdSelector);

  const navigate = React.useCallback(() => {
    trackEvent({ action: "close Homevest modal" });
    dispatch(push(`/jobs/${currentJobId}/estimates/${estimate.id}`));
  }, [currentJobId, estimate, dispatch]);

  return (
    <Modal show={true} size="xl" backdrop="static" onHide={navigate} className="homevest homevest-modal">
      <Modal.Header closeButton>
        <Row>
          <Col>
            <Modal.Title>
              <div>Finance Options</div>
              <Switch>
                <Route path={`${match.url}/create`} render={() => <p>Make comparisons, choose a finance option</p>} />
              </Switch>
              <Switch>
                <Route path={`${match.url}/apply`} render={() => <p>Make comparisons, choose a finance option</p>} />
              </Switch>
            </Modal.Title>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="p-0 overflow-y-auto overflow-x-hidden">{children}</Modal.Body>
    </Modal>
  );
};

export default HomevestModal;
