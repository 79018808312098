import * as React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { Context } from "./Context";
import { useSelector } from "react-redux";
import { currentOrgId as currentOrgIdSelector } from "app2/src/selectors/org.selectors";

export interface OrgCheckboxRowProps {
  org: OrgRecord;
  rowClickHandler: (orgId: number) => void;
}

export const OrgCheckboxRow: React.FC<OrgCheckboxRowProps> = ({ org, rowClickHandler }) => {
  const { selectedOrgs } = React.useContext(Context);
  const currentOrgId = useSelector(currentOrgIdSelector);

  if (org.get("id") === currentOrgId) return null;

  return (
    <Row className="align-items-center py-3 border-top mw-100 m-0 text-center">
      <Col sm={2}>
        <Form.Check
          id={`org-${org.get("id")}-checkbox`}
          checked={selectedOrgs.includes(org.id)}
          onChange={() => rowClickHandler(org.get("id"))}
        />
      </Col>
      <Col sm={2}>{org.get("id")}</Col>
      <Col>{org.get("name")}</Col>
      <Col>{org.get("status")}</Col>
    </Row>
  );
};
