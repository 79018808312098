import * as React from "react";
import { useState } from "react";
import { connectFC } from "app2/src/connect";
import { useTracking } from "react-tracking";
import { useDispatch } from "react-redux";
import { RootDispatchType } from "app2/src/store";
import { Button } from "app2/src/components/Chargebee/Button";
import { Portal } from "app2/src/components/Chargebee/Portal";
import { Spinner } from "app2/src/components/SpinnerComponent";
import { ContextRecord, Context, SubscriptionAction } from "app2/src/components/Chargebee/Context";
import { portalSession } from "app2/src/api/billing/subscription.service";
import { handleErrors } from "app2/src/reducers/Utils";

interface ChargebeeSubscriptionProps {
  subscriptions: { customer_id: string }[];
  orgId: number;
}

export const ChargebeeSubscription: React.FC<ChargebeeSubscriptionProps> = ({ subscriptions, orgId }) => {
  //Hooks
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch: RootDispatchType = useDispatch();
  const { trackEvent } = useTracking();

  //Constants
  const customerIds = [...new Set(subscriptions.map((sd) => sd.customer_id))];
  const sessionUrl = session?.access_url;

  //Methods
  const createPortal = async (customerId, orgId) => {
    try {
      const session = await portalSession(customerId, orgId);

      if (!session.session) {
        handleErrors(
          [
            "There were problems loading your billing information.  Please refresh the page to try again.  For help contact Support.",
          ],
          dispatch,
        );
      }
      setSession(session.session);
    } catch (response) {
      handleErrors(response, dispatch);
    }
    setLoading(false);
  };

  const subscriptionReducer = async (action: SubscriptionAction, payload: { custId: string; orgId: number } | null) => {
    switch (action) {
      case "createPortalSession":
        setLoading(true);
        const { custId, orgId } = payload;
        await createPortal(custId, orgId);
        trackEvent({ action: "Portal Session Created", customerId: custId });
        return;
      case "close":
        setSession(null);
        trackEvent({ action: "Portal Session Ended" });
        return;
    }
  };

  return (
    <>
      <Context.Provider
        value={
          new ContextRecord({
            sessionUrl,
            orgId,
            dispatch: subscriptionReducer,
          })
        }>
        <Spinner localProperty={loading} />
        <Button customerIds={customerIds} />
        {session && <Portal />}
      </Context.Provider>
    </>
  );
};

export default connectFC(ChargebeeSubscription);
