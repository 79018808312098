import * as React from "react";
import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Context } from "app2/src/components/SignatureRequest/Context";
import { currentSignedDocument as currentSignedDocSelector } from "app2/src/selectors/signedDocument.selectors";
import { SignedDocumentForm } from "app2/src/components/Common/SignedDocumentForm";

export const SignatureConfiguration: React.FunctionComponent = () => {
  const { dispatch: contextDispatch } = useContext(Context);
  const currentSignedDocument = useSelector(currentSignedDocSelector);

  if (!currentSignedDocument) {
    return null;
  }

  return (
    <div>
      <Modal.Header closeButton>
        <h3>Create Signature Request</h3>
      </Modal.Header>

      <SignedDocumentForm
        close={() => contextDispatch("close", { modalType: "", destroy: true })}
        onSubmit={(event) => contextDispatch("handleSubmit", { event })}
        back={() => contextDispatch("back", { screen: "viewDocument" })}
        message
      />
    </div>
  );
};
