import { useSelector } from "app2/src/storeRegistry";
import { currentAccessUid as accessUidSelector } from "app2/src/selectors/auth.selectors";

export default function ($timeout) {
  return {
    restrict: "A",
    priority: 1,
    link: function (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) {
      const currentAccessUid = useSelector(accessUidSelector);
      if (!currentAccessUid) {
        return;
      }
      const href = attrs["href"];

      if (!href) return;

      let currentParams = "";
      let path = href;
      if (href.includes("?")) {
        [path, currentParams] = href.split("?");
      }
      const searchParams = new URLSearchParams(currentParams);
      searchParams.set("authUser", currentAccessUid);
      const newHref = `${path}?${searchParams.toString()}`;
      $timeout(() => attrs.$set("href", newHref));
    },
  };
}
