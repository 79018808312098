//noinspection TypeScriptCheckImport
import { Finance } from "financejs";

export interface ILoanCalcCtrl {
  loanCalcForm: ng.IFormController;
  data: { amount: number; term: number; rate: number };
  result: number;

  calculate();
}

class LoanCalcCtrl implements ILoanCalcCtrl {
  public loanCalcForm: ng.IFormController;
  public finance: Finance;
  public data;

  public result = 0;

  constructor() {
    this.finance = new Finance();
  }

  public calculate() {
    if (this.data.rate === 0) {
      this.result = _.round(this.data.amount / this.data.term, -2);
    } else {
      //noinspection TypeScriptUnresolvedFunction
      this.result = this.finance.AM(this.data.amount, this.data.rate, this.data.term, 1);
    }
  }
}

export class LoanCalc implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    data: "<",
  };
  public templateUrl = "src/Estimator/loan_calc.html";
  /**/
  constructor() {
    this.controller = LoanCalcCtrl;
    this.controller.$inject = [];
  }
}
