import { LocalAudioTrack } from "twilio-video";
import { useCallback, useEffect } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";
import useIsTrackEnabled from "app2/src/components/Twilio/hooks/useIsTrackEnabled/useIsTrackEnabled";

export default function useLocalAudioToggle() {
  const {
    localTracks,
    roomParams: { audio_preference },
  } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);

  useEffect(() => {
    if (audioTrack && audioTrack.isEnabled && (audio_preference === "disabled" || audio_preference === "mute")) {
      audioTrack.disable();
    }
  }, [audioTrack ? audioTrack.id : 1]);

  return [isEnabled, toggleAudioEnabled];
}
