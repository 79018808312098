import { ActionsUnion, createAction } from "../Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../index";
import { IToolData, ToolRecord } from "app2/src/records/Tool";
import { toolService } from "app2/src/api/tool.service";
import { RootDispatchType } from "../../store";

export const FETCH_ORG_TOOLS = "@tools/FETCH_ORG_TOOLS";
export const FETCH_TOOL = "@tools/FETCH_TOOL";
export const RECEIVE_ORG_TOOLS = "@tools/RECEIVE_ORG_TOOLS";
export const RECEIVE_TOOL = "@tools/RECEIVE_TOOL";
export const REMOVE_ORG_TOOL = "@tools/REMOVE_ORG_TOOL";
export const REMOVE_TOOL = "@tools/REMOVE_TOOL";
export const RECEIVE_ORG_ERRORS = "@tools/RECEIVE_ORG_ERRORS";
export const RECEIVE_TOOL_ERROR = "@tools/RECEIVE_TOOL_ERROR";

export const Actions = {
  fetchTool: (orgId: number, toolId: number) => createAction(FETCH_TOOL, { orgId: orgId, toolId: toolId }),
  receiveToolError: (orgId, toolId: number, errors: string[]) =>
    createAction(RECEIVE_TOOL_ERROR, { orgId: orgId, toolId: toolId, errors: errors }),
  receiveTool: (tool: Partial<IToolData>) => createAction(RECEIVE_TOOL, tool),
  fetchTools: (orgId: number) => createAction(FETCH_ORG_TOOLS, orgId),
  receiveErrors: (orgId, errors: string[]) => createAction(RECEIVE_ORG_ERRORS, { orgId: orgId, errors: errors }),
  receiveTools: (orgId: number, tools: Partial<IToolData>[]) =>
    createAction(RECEIVE_ORG_TOOLS, { orgId: orgId, tools: tools }),
  removeOrgTool: (orgId: number, toolId: number) => createAction(REMOVE_ORG_TOOL, { orgId: orgId, toolId: toolId }),
  removeTool: (toolId: number) => createAction(REMOVE_TOOL, toolId),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  addTool: (tool: ToolRecord): ThunkResult<Promise<IToolData>> => {
    return (dispatch: RootDispatchType) => {
      const org_id = tool.org_id;

      return toolService.create(org_id, tool).then(
        (result) => {
          dispatch(Actions.receiveTool(result));

          return result;
        },
        (errors) => {
          return Promise.reject(errors);
        },
      );
    };
  },
  updateTool: (tool: ToolRecord): ThunkResult<Promise<IToolData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchTool(tool.org_id, tool.id));

      return toolService.update(tool).then(
        (tool) => {
          dispatch(Actions.receiveTool(tool));
          return tool;
        },
        (errors) => {
          dispatch(Actions.receiveToolError(tool.org_id, tool.id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  removeImage: (tool: ToolRecord): ThunkResult<Promise<IToolData>> => {
    const brand_image = tool.brand_image.set("_destroy", true);
    tool = tool.set("brand_image", brand_image);
    return AsyncActions.updateTool(tool);
  },
  archiveTool: (tool: ToolRecord): ThunkResult<Promise<IToolData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchTool(tool.org_id, tool.id));

      return toolService.archive(tool).then(
        (archived) => {
          dispatch(Actions.removeOrgTool(archived.org_id, archived.id));
          dispatch(Actions.removeTool(archived.id));
          return archived;
        },
        (errors) => {
          dispatch(Actions.receiveToolError(tool.org_id, tool.id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  listTools: (orgId: number, jobId: number): ThunkResult<any> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchTools(orgId));

      return toolService.loadByOrg(orgId, jobId).then(
        (tools) => {
          dispatch(Actions.receiveTools(orgId, tools));
          return tools;
        },
        (errors) => {
          dispatch(Actions.receiveErrors(orgId, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
