import * as React from "react";
import { RootState } from "app2/src/reducers";
import PaymentList from "app2/src/components/Payments/PaymentList";
import FinanceApplicationList from "./FinanceApplicationList";
import { Row, Col } from "react-bootstrap";
import { paymentAuthorized as paymentAuthorizedSelector } from "app2/src/selectors/payment.selectors";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { connectFC } from "app2/src/connect";
import { useSelector } from "react-redux";
import PaymentRequestList from "./PaymentRequestList";

const PaymentsAndApplications: React.FC = () => {
  const paymentAuthorized = useSelector(paymentAuthorizedSelector);
  const jobId = useSelector(currentJobId);
  const showApplications = useSelector((state: RootState) => {
    const orgId = currentOrgId(state);
    return getAuthorization(state, { orgId, integration: "fin_mkt" });
  });

  if (!jobId) return null;

  return (
    <>
      {paymentAuthorized && (
        <Row>
          <Col md="12">
            <div className="form-section tour-job-details">
              <div className="form-section-heading">
                <h3>Payments</h3>
              </div>
              <div className="form-section-content">
                <PaymentList jobId={jobId} />
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="12">
          <div className="form-section tour-job-details">
            <div className="form-section-heading">
              <h3>Payment Requests</h3>
            </div>
            <div className="form-section-content">
              <PaymentRequestList />
            </div>
          </div>
        </Col>
      </Row>
      {showApplications && (
        <Row>
          <Col md="12">
            <div className="form-section tour-job-details">
              <div className="form-section-heading">
                <h3>Finance Applications</h3>
              </div>
              <div className="form-section-content">
                <FinanceApplicationList />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default connectFC(PaymentsAndApplications);
