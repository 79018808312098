import { push } from "connected-react-router/immutable";
import * as React from "react";
import { Col, Row, ButtonGroup, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { pathname } from "app2/src/selectors/router.selectors";
import { useTracking } from "react-tracking";
import { currentOrgId, preferencesConfig } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { exteriorMeasurements } from "app2/src/records/Measurement";

interface IInteriorExteriorButton {
  kind: "interior" | "exterior";
}

export const InteriorExteriorButton: React.FC<IInteriorExteriorButton> = ({ kind }) => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "InteriorExteriorButton" });

  // Selectors
  const path = useSelector(pathname);
  const permissions = useSelector((state: RootState) =>
    preferencesConfig(state, { orgId: currentOrgId(state), path: ["measurement", "job_tab"], notSet: List() }),
  );
  const validExteriorTabs = exteriorMeasurements.filter((tab) => {
    return permissions.includes(tab);
  });
  const showButton = permissions.includes("rooms") && validExteriorTabs.size > 0;

  // Methods
  const navigate = (data) => {
    trackEvent({ action: `click ${data.currentTarget.name} navigate` });
    const root = path.split("/measurements")[0];
    dispatch(push(`${root}/measurements/${data.currentTarget.name}`));
  };

  return (
    <>
      {showButton ? (
        <Row className="mb-3">
          <Col>
            <ButtonGroup className="w-100">
              <Button name="exterior" variant={kind === "exterior" ? "primary" : "light"} onClick={navigate}>
                Exterior
              </Button>
              <Button name="interior" variant={kind === "interior" ? "primary" : "light"} onClick={navigate}>
                Interior
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ) : null}
    </>
  );
};
