export const JobMenuValues = [
  "information",
  "appointments",
  "tools",
  "images",
  "documents",
  "measurements",
  "inspection",
  "notes",
  "history",
  "presentations",
  "design_tools",
  "estimates",
  "proposals",
  "contracts",
  "signatures",
];
