import { PlansRoutes } from "./Plans/PlansRoutes";
import { FeesRoutes } from "./Fees/FeesRoutes";
import { StripeInput } from "./Common/StripeInput";
import { StripeUnit } from "./Common/StripeUnit";
import { BillingModelRoutes } from "./Models/BillingModelRoutes";
import { CouponsRoutes } from "./Coupons/CouponsRoutes";
import { react2angular } from "react2angular";
import { BillingStatusModal } from "app2/src/components/Payments/BillingStatusModal";
import { InvoiceText } from "app2/src/components/Payments/InvoiceText";
import MakePaymentButton from "app2/src/components/PaymentsModal/MakePaymentButton";
import PaymentsAndApplications from "app2/src/components/JobTabs/InformationTab/PaymentsAndApplications/index";
import AccountPlanManagerWrapper from "app2/src/components/Billing/AccountPlanManagerWrapperContainer";

export class BillingRoutes {
  public static modules = [BillingModelRoutes, PlansRoutes, FeesRoutes, CouponsRoutes];

  public static load(app: ng.IModule) {
    _.each(BillingRoutes.modules, (module) => {
      module.load(app);
    });

    app.component(
      "billingStatusModal",
      react2angular(BillingStatusModal, ["show", "onClose"], ["Session", "$state", "$analytics"]),
    );
    app.component("billingInvoiceText", react2angular(InvoiceText, ["lines", "ctrl"], []));
    app.component("paymentsAndApplications", react2angular(PaymentsAndApplications));
    app.component("makePaymentButton", react2angular(MakePaymentButton));
    app.component("accountPlanManager", react2angular(AccountPlanManagerWrapper, ["account"]));
    app.directive("stripeInput", StripeInput.factory());
    app.filter("stripe", StripeUnit.factory);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    _.each(BillingRoutes.modules, (module) => {
      module.configure($stateProvider, resolveAuth);
    });
  }
}
