import * as angulartics from "angulartics";
import { IJob } from "app/src/Models/Job";

export class JobAnalytics implements ng.IDirective {
  public restrict = "A";

  constructor(private $analytics: angulartics.IAnalyticsService) {}

  public link = ($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes) => {
    const trackingData: any = {};

    (<IJob>$scope.$eval($attrs["jobAnalytics"])).$promise.then((job: IJob) => {
      trackingData.job = job.id;
      trackingData.org = job.org_id;
    });

    _.each($attrs.$attr as any, (attr: string, name: string) => {
      if (this._isProperty(name)) {
        trackingData[this._propertyName(name)] = $attrs[name];
        $attrs.$observe(name, (value) => {
          trackingData[this._propertyName(name)] = value;
        });
      }
    });

    angular.element($element[0]).bind("click", ($event) => {
      const eventName = $attrs.analyticsEvent || this._inferEventName($element[0]);
      trackingData.eventType = $event.type;

      if ($attrs.analyticsIf) {
        if (!$scope.$eval($attrs.analyticsIf)) {
          return; // Cancel this event if we don"t pass the analytics-if condition
        }
      }
      // Allow components to pass through an expression that gets merged on to the event properties
      // eg. analytics-properites="myComponentScope.someConfigExpression.$analyticsProperties"
      if ($attrs.analyticsProperties) {
        angular.extend(trackingData, $scope.$eval($attrs.analyticsProperties));
      }
      this.$analytics.eventTrack(eventName, trackingData);
    });
  };

  private _isProperty(name) {
    return (
      name.substr(0, 9) === "analytics" &&
      ["On", "Event", "If", "Properties", "EventType"].indexOf(name.substr(9)) === -1
    );
  }

  private _propertyName(name) {
    const s = name.slice(9); // slice off the "analytics" prefix
    if (typeof s !== "undefined" && s !== null && s.length > 0) {
      return s.substring(0, 1).toLowerCase() + s.substring(1);
    } else {
      return s;
    }
  }

  private _isCommand(element) {
    return (
      ["a:", "button:", "button:button", "button:submit", "input:button", "input:submit"].indexOf(
        element.tagName.toLowerCase() + ":" + (element.type || ""),
      ) >= 0
    );
  }

  private _inferEventName(element) {
    if (this._isCommand(element)) return element.innerText || element.value;
    return element.id || element.name || element.tagName;
  }

  public static factory(): ng.IDirectiveFactory {
    const directive = ($analytics: angulartics.IAnalyticsService) => new JobAnalytics($analytics);
    directive.$inject = ["$analytics"];
    return directive;
  }
}
