import { fetcher } from "../helpers/Fetcher";
import { IImageData, ImageRecord, ImageDisplayType, toFormData } from "app2/src/records/Image";
import { QueryParamsRecord } from "app2/src/records/Page";
import { IMetaPaginationData } from "app2/src/records/MetaPagination";
import { List } from "immutable";
import { baseCleanProps, listParams } from "app2/src/api/Api";

export interface IImageIndexResponse {
  images: IImageData[];
  meta: { pagination: IMetaPaginationData };
}

export interface IImageResponse {
  image?: IImageData;
  images?: IImageData[];
}

export type ImageableType = "org" | "job";
export const ImageableTypeConversion = { org: "Org", job: "Job" };

export interface IImageOptions {
  imageableId: number;
  imageableType: ImageableType;
  image_ids?: List<number>;
  folder_id?: number;
  display?: ImageDisplayType;
  refreshLoad?: boolean;
}

const urls = {
  org: "api/v1/orgs/:id/images",
  job: "api/v1/jobs/:id/images",
};

export const list = (
  options: IImageOptions,
  queryParams: QueryParamsRecord = new QueryParamsRecord(),
): Promise<IImageIndexResponse> => {
  const actionUrl = urls[options.imageableType].replace(":id", options.imageableId.toString());
  const params = listParams(queryParams);

  if (options.folder_id && options.folder_id !== 0) {
    params.folder_id = options.folder_id;
  }

  if (options.display) {
    params.display = options.display;
  }

  if (options.image_ids) {
    params["image_ids[]"] = options.image_ids.toArray();
  }

  return fetcher.get<IImageIndexResponse>(actionUrl, params);
};

export const updateOrCreate = (image: ImageRecord): Promise<IImageResponse> => {
  if (image.id > 0) {
    return update(image);
  } else {
    return create(image);
  }
};

export const create = (image: ImageRecord): Promise<IImageResponse> => {
  const actionUrl = urlSetup(image);
  return fetcher.post<IImageResponse>(actionUrl, toFormData(image), { formData: true });
};

export const update = (image: ImageRecord): Promise<IImageResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(image), image.id.toString());
  return fetcher.patch(actionUrl, cleanProps(image));
};

export const destroy = (image: ImageRecord): Promise<IImageResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(image), image.id.toString());
  return fetcher.delete(actionUrl);
};

export const cleanProps = (image: ImageRecord): IImageData => {
  let imageParams = image.toJS();
  imageParams = baseCleanProps(imageParams);

  if (_.isNullOrUndefined(imageParams.folder_id)) {
    delete imageParams.folder_id;
  }

  delete imageParams.displayInProposal;
  delete imageParams.displayInAgreement;

  return imageParams;
};

export const urlSetup = (image: ImageRecord): string => {
  return urls[image.imageable_type.toLowerCase()].replace(":id", image.imageable_id.toString());
};
