import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { token } from "app2/src/selectors/token.selectors";
import { Form as FinalForm } from "react-final-form";
import { RsfFormGroup } from "app2/src/components/RsfForms/RsfFormGroup";
import { TokenRecord } from "app2/src/records/Token";
import * as tokenActions from "app2/src/reducers/token.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import Spinner from "app2/src/components/SpinnerComponent";
import { validateInputs } from "app2/src/helpers/InputValidator";

const mapStateToProps = (state: RootState, ownProps: HomevestFinanceModalProps) => {
  return {
    token: token(state, { kind: "fin_mkt" }),
    orgId: currentOrgId(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: HomevestFinanceModalProps,
) => {
  return {
    updateOrCreateToken: (newToken: TokenRecord) => dispatch(tokenActions.AsyncActions.updateCreateToken(newToken)),
    getToken: (orgId: number) => dispatch(tokenActions.AsyncActions.getToken(orgId, "fin_mkt")),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface HomevestFinanceModalProps {
  triggerOpen: number;
  tokenUpdated: () => void;
}

interface HomevestFinanceModalState {
  open: boolean;
  loading: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HomevestFinanceModalProps;

class HomevestFinanceModal extends React.Component<Props, HomevestFinanceModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
    };

    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const { triggerOpen, getToken, orgId } = this.props;
    if (prevProps.triggerOpen !== triggerOpen) {
      this.setState({ open: true });
      getToken(orgId);
    }
  }

  public async onSubmit(formData) {
    const { updateOrCreateToken, tokenUpdated, token } = this.props;
    this.setState({ loading: true });
    const newToken = token
      .setIn(["data", "username"], formData.username)
      .setIn(["data", "password"], formData.password);
    await updateOrCreateToken(newToken);
    tokenUpdated();

    this.setState({ loading: false });
    this.onHide();
  }

  public onHide() {
    this.setState({ open: false });
  }

  public render() {
    const { token } = this.props;
    const { open, loading } = this.state;
    if (!open) {
      return null;
    }

    if (loading || _.isNullOrUndefined(token) || (token.id > 0 && (token.id === 0 || token.loading))) {
      return <Spinner localProperty={true} />;
    }

    return (
      <Modal show={open} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>HomevestFinance</Modal.Title>
        </Modal.Header>

        <FinalForm
          className="form"
          onSubmit={this.onSubmit}
          initialValues={{
            username: token.getIn(["data", "username"]),
            password: token.getIn(["data", "password"]),
          }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col>
                    <RsfFormGroup
                      label="Username"
                      name="username"
                      component="input"
                      placeholder="Username"
                      required={true}
                      validate={validateInputs}
                    />
                    <RsfFormGroup
                      label="Password"
                      name="password"
                      component="input"
                      placeholder="Password"
                      required={true}
                      validate={validateInputs}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <div className="button-footer">
                  <Button type="submit">Authorize</Button>
                  <Button variant="cancel" onClick={this.onHide}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </form>
          )}
        </FinalForm>
      </Modal>
    );
  }
}

export default connector(HomevestFinanceModal);
