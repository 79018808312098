import { Record, List } from "immutable";
import {
  fromJSON as eclFromJSON,
  IEstimateComparisonLinkData,
  EstimateComparisonLinkRecord,
} from "./EstimateComparisonLink";

export const fromJSON = (json: Partial<IEstimateComparisonData>): EstimateComparisonRecord => {
  const record: IEstimateComparisonRecord = { ...(json as any) };
  if (json.estimate_comparison_links) {
    record.estimate_comparison_links = List(json.estimate_comparison_links.map((ecl) => eclFromJSON(ecl)));
  }

  return new EstimateComparisonRecord(record);
};

export const toJSON = (ec: EstimateComparisonRecord): IEstimateComparisonData => {
  return ec.toJS();
};

export interface IEstimateComparisonData {
  id: number;
  job_id: number;
  estimate_comparison_links: IEstimateComparisonLinkData[];
  show: boolean;
  loading: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IEstimateComparisonRecord {
  id: number;
  job_id: number;
  estimate_comparison_links: List<EstimateComparisonLinkRecord>;
  show: boolean;
  loading: boolean;
  created_at: Date;
  updated_at: Date;
}

export const defaultEstimateComparisonProps: IEstimateComparisonRecord = {
  id: 0,
  job_id: -1,
  estimate_comparison_links: List<EstimateComparisonLinkRecord>(),
  show: false,
  loading: false,
  created_at: null,
  updated_at: null,
};

export class EstimateComparisonRecord
  extends Record(defaultEstimateComparisonProps)
  implements IEstimateComparisonRecord
{
  public id!: number;
  public readonly job_id!: number;
  public readonly estimate_comparison_links!: List<EstimateComparisonLinkRecord>;
  public readonly show!: boolean;
  public readonly loading!: boolean;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IEstimateComparisonRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
