import * as ng from "angular";
import { IAddress } from "app/src/Models/Address";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { ISku, ISkuResponse } from "../Billing/Models/Sku";
import { IBaseConfig } from "../Common/IBaseConfig";

export type SkyMeasureReportType = "Roof" | "Wall";

export class ICoreLogicModalOrder {
  public customer_name: string;
  public claim_number: string;
  public address: IAddress;
  public property_type: "commercial" | "residential";
  public report_type: SkyMeasureReportType[];
  public delivery_option: string;
  public hail: boolean;
  public wind: boolean;
  public lightning: boolean;
  public permit: boolean;
  public ortho_image: boolean;
  public date_of_loss: Date;
  public start_date: Date;
  public end_date: Date;
  public timezone_offset: string;
  public search_radius: number;
  public tall_structure: boolean;
  public time_period: number;
  public comments: string;
}

export class ISkyMeasureRoofReport {
  public Address: string;
  public City: string;
  public State: string;
  public Zip: string;
  public Country: string;
  public HomeOwnerName: string;
  public ReportType: SkyMeasureReportType[];
  public Commercial: number;
  public AdditionalDeliveryOptions: string;
  public Comments: string;
  public Latitude: number;
  public Longitude: number;
}

export class IWvsRequestReport {
  public idClaim: string;
  public streetAddress: string;
  public city: string;
  public state: string;
  public zipcode: string;
}

export class IWvsReportResponse {
  public reportGenerated: Date;
  public reportId: string;
  public startDate: Date;
  public endDate: Date;
  public latitude: string;
  public longitude: string;
  public events: IWvsEvent[];
}

export class IWvsLightningRequestReport {
  public idClaim: string;
  public streetAddress: string;
  public city: string;
  public state: string;
  public zipcode: string;
  public DateOfLoss: Date;
  public StartDate: Date;
  public EndDate: Date;
  public TimezoneOffset: string;
  public SearchRadius: number;
  public tallStructure = false;
}

export class IWvsLightningReportResponse {
  public reportGenerated: Date;
  public reportId: string;
  public startDate: Date;
  public endDate: Date;
  public latitude: string;
  public longitude: string;
  public events: IWvsLightningEvent;
}

export class IWvsLightningEvent {
  public date: Date;
  public time: string;
  public ground: string;
  public signal: string;
  public lat: string;
  public lon: string;
  public chi: string;
}

export class IWvsEvent {
  public date: Date;
  public atLoc: string;
  public within1mi: string;
  public within3mi: string;
  public within10mi: string;
}

export class IReportDiscount {
  public name: string;
  public discount: number;
}

export interface IReportResponse extends ng.resource.IResourceArray<IReport> {
  reports: IReport[];
  meta: any;
}

export interface IReportResource extends ng.resource.IResourceClass<IReport>, IRsfResource {
  reportsAvailable: string[];

  create?(params: any): IReport;
  check?(params: any): any;
  pricing?(params: any): ISkuResponse;
  imagery_available?(params: any): any;
  fromJSON?(data: any): IReport;
  formatWvsRequestReport?(order: ICoreLogicModalOrder): IWvsRequestReport;
  formatWvsLightningRequestReport?(order: ICoreLogicModalOrder): IWvsLightningRequestReport;
}

export interface IReport extends ng.resource.IResource<IReport>, ReportPrototype {
  $create(): ng.IPromise<IReport>;
  $createAtOrg(): ng.IPromise<IReport>;
}

class ReportPrototype {
  public id: number;
  public classy: string;
  public job_id: number;
  public org_id: number;
  public user_id: number;
  public billing_order_item_id: number;
  public kind: string;
  public order_data: any;
  public order_items: any;
  public order_result: any;
  public metadata: any;
  public provider: string;
  public status: string;
  public updated_at: Date;
  public created_at: Date;
  public delivered_at: Date;

  public prettyId() {
    if (
      _.isNull(this.metadata["order_id"]) ||
      _.isUndefined(this.metadata["order_id"]) ||
      this.metadata["order_id"] === ""
    ) {
      return this.id;
    } else {
      return this.metadata["order_id"];
    }
  }

  public partialUrl(type: string) {
    return "src/Reports/partials/" + this.provider + "/" + type + ".html";
  }
}

let resources: IRepository;

const factory = (
  $resource: ng.resource.IResourceService,
  BaseConfig: IBaseConfig,
  US_STATES_ABBREV,
  COUNTRIES_ABBREV,
) => {
  const nested_url = BaseConfig.BASE_URL + "/api/v1/jobs/:job_id/reports/:id";
  const org_nested_url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/reports/:id";
  const url = BaseConfig.BASE_URL + "/api/v1/reports/:id";

  const transformSingleResponse = (response: string, headers: ng.IHttpHeadersGetter, status: number): IReport => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data: any = JSON.parse(response).report;

    return Report.fromJSON(data);
  };

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number): IReport => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta = angular.fromJson(response);

    _.each(meta.reports, (rp, index) => {
      meta.reports[index] = Report.fromJSON(rp);
    });

    return meta;
  };

  const transformPricingResponse = (response: string, headers: ng.IHttpHeadersGetter, status: number): ISkuResponse => {
    const data: ISkuResponse = <ISkuResponse>JSON.parse(response);

    _.each(data.skus, (s_data: ISku, idx: number) => {
      data.skus[idx] = resources.Sku.fromJSON(s_data);
    });

    return data;
  };

  const requestTransform = (report: IReport): string => {
    //noinspection TypeScriptUnresolvedFunction
    const data = JSON.parse(angular.toJson(JSON.decycle(report)));

    return angular.toJson({ report: data });
  };

  const Report: IReportResource = <IReportResource>$resource(
    url,
    { id: "@id", job_id: "@job_id", org_id: "@org_id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        paramSerializer: "$httpParamSerializerJQLike",
        transformResponse: transformMultiple,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: nested_url,
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
      },
      createAtOrg: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: org_nested_url,
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
      },
      check: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/check",
      },
      pricing: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/pricing",
        transformResponse: transformPricingResponse,
      },
      imagery_available: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/imagery_available",
      },
    },
  );

  Report.reportsAvailable = [
    "eagleview_roofing",
    "hover",
    "quick_measure_roofing",
    "roof_scope_roofing",
    "plnar_interior",
  ];

  Report.formatWvsRequestReport = (order: ICoreLogicModalOrder) => {
    const wvs_report: IWvsRequestReport = <IWvsRequestReport>{};
    wvs_report.idClaim = order.claim_number;
    wvs_report.streetAddress = (order.address.line_1 + " " + order.address.line_2).trim();
    wvs_report.city = order.address.city;
    wvs_report.zipcode = order.address.postal_code;
    wvs_report.state = US_STATES_ABBREV[order.address.state];

    return wvs_report;
  };

  Report.formatWvsLightningRequestReport = (order: ICoreLogicModalOrder) => {
    const wvs_report: IWvsLightningRequestReport = <IWvsLightningRequestReport>{};
    wvs_report.idClaim = order.claim_number;
    wvs_report.streetAddress = (order.address.line_1 + " " + order.address.line_2).trim();
    wvs_report.city = order.address.city;
    wvs_report.zipcode = order.address.postal_code;
    wvs_report.state = US_STATES_ABBREV[order.address.state];
    wvs_report.DateOfLoss = order.date_of_loss;
    wvs_report.StartDate = order.start_date;
    wvs_report.EndDate = order.end_date;
    wvs_report.TimezoneOffset = order.timezone_offset;
    wvs_report.SearchRadius = order.search_radius;
    wvs_report.tallStructure = order.tall_structure;

    return wvs_report;
  };

  Report.fromJSON = (data: any) => {
    data.classy = "Report";

    if (data.provider === "wvs" && data.order_result) {
      if (data.order_result.endDate) {
        data.order_result.endDate = data.order_result.endDate.replace(" ", "T");
      }
      if (data.order_result.startDate) {
        data.order_result.startDate = data.order_result.startDate.replace(" ", "T");
      }
    }

    return new Report(data);
  };

  Report.inject = (injected: IRepository) => {
    resources = injected;
  };

  _.hiddenExtend(Report.prototype, ReportPrototype.prototype);

  return Report;
};

factory.$inject = ["$resource", "BaseConfig", "US_STATES_ABBREV", "COUNTRIES_ABBREV"];

export default factory;
