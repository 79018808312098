import { Map, Record, List, fromJS } from "immutable";

export const fromJSON = (json: Partial<ISettingsData>): SettingsRecord => {
  const record: ISettingsRecord = { ...(json as any) };

  record.acl = fromJS(json.acl);
  record.config = fromJS(JSON.parse(JSON.stringify(json.config || {})));

  return new SettingsRecord(record);
};

export const toJSON = (setting: SettingsRecord): ISettingsData => {
  const json: ISettingsData = setting.toJS();

  if (json.id === 0) {
    delete json.id;
  }

  if (json.org_id === 0) {
    delete json.org_id;
  }

  return json;
};

export type SettingsAclKeys = "tools" | "design_tools" | "visualizations" | "profile" | "reports" | "measurements";

export type SettingsAclValues =
  | "renoworks_masking"
  | "eagleview_order"
  | "google_maps"
  | "bing_ortho"
  | "bing_birdseye"
  | "zoom_earth"
  | "terra_server"
  | "pictometry_online"
  | "pictometry_connect"
  | "provia"
  | "renoworks"
  | "hover"
  | "cronofy"
  | "annotator"
  | "rapid_area"
  | "satellite_measure"
  | "deck_wizard"
  | "fencing_tool"
  | "pool_tool";

export type SettingsConfigKeys =
  | "billing"
  | "email"
  | "integrations"
  | "job_menu"
  | "screen_share"
  | "signature_settings"
  | "visualization"
  | "commission_calculator";

export interface ISettingsData {
  id: number;
  org_id: number;
  acl: { [key in SettingsAclKeys]?: SettingsAclValues[] };
  config: { [key in SettingsConfigKeys]?: any };
  created_at: string;
  updated_at: string;
}

export interface ISettingsRecord {
  id: number;
  org_id: number;
  acl: Map<SettingsAclKeys, List<SettingsAclValues>>;
  config: Map<SettingsConfigKeys, any>;
  created_at: string;
  updated_at: string;
}

const defaultSettingsProps: ISettingsRecord = {
  id: 0,
  org_id: 0,
  acl: Map<SettingsAclKeys, List<SettingsAclValues>>(),
  config: Map<SettingsConfigKeys, any>(),
  created_at: "",
  updated_at: "",
};

export class SettingsRecord extends Record(defaultSettingsProps) implements ISettingsRecord {
  public readonly id!: number;
  public readonly org_id!: number;
  public readonly acl!: Map<SettingsAclKeys, List<SettingsAclValues>>;
  public readonly config!: Map<SettingsConfigKeys, any>;
  public readonly created_at!: string;
  public readonly updated_at!: string;

  public constructor(values?: Partial<ISettingsRecord>) {
    values ? super(values) : super();
  }
}
