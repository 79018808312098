import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { EstimateRecord } from "app2/src/records/Estimate";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { JobRecord } from "app2/src/records/Job";
import { FinanceOptionRecord } from "app2/src/records/FinanceOption";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { estimate, landingEstimate } from "app2/src/selectors/estimate.selectors";
import { org } from "app2/src/selectors/org.selectors";
import { job } from "app2/src/selectors/job.selectors";
import { financeOption } from "app2/src/selectors/financeOption.selectors";
import { LandingEstimateRecord } from "app2/src/records/LandingEstimate";
import SpinnerComponent from "../SpinnerComponent";
import ErrorPage from "app2/src/components/Common/ErrorPage";
import { IframeRecord } from "app2/src/records/Iframe";

const mapStateToProps = (state: RootState, ownProps: LandingEstimateWrapperProps) => {
  const le = landingEstimate(state, { key: ownProps.id });
  if (!le) {
    return {
      landingEstimateProp: null,
      estimate: null,
      org: null,
      job: null,
      financeOption: null,
      iframe: null,
    };
  }

  const { estimateId, orgId, jobId, financeOptionId } = le.toJS();
  return {
    landingEstimateProp: le,
    estimate: estimate(state, { estimateId }),
    org: org(state, { orgId }),
    job: job(state, { jobId }),
    financeOption: financeOption(state, { financeOptionId }),
    iframe: le.iframe,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: LandingEstimateWrapperProps,
) => {
  return {
    landingEstimate: () =>
      dispatch(estimateActions.AsyncActions.landingEstimate({ ...ownProps.queryParams, key: ownProps.id })),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface LandingEstimateWrapperProps {
  id: string;
  queryParams: any;
  WrappedComponent: new (props: any) => React.Component<any>;
}

export interface LandingEstimateWrapperState {
  invalidKey: boolean;
  error: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & LandingEstimateWrapperProps;

class LandingEstimateWrapper extends React.Component<Props, LandingEstimateWrapperState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      invalidKey: true,
      error: "",
    };
  }

  public componentDidMount() {
    this.loadLandingEstimate();
  }

  public async loadLandingEstimate() {
    try {
      await this.props.landingEstimate();
      this.setState({ invalidKey: false });
    } catch (errors) {
      this.setState({ invalidKey: true, error: errors[0] });
    }
  }

  public render() {
    const { WrappedComponent, landingEstimateProp } = this.props;
    const { invalidKey, error } = this.state;

    if (!landingEstimateProp || landingEstimateProp.loading) {
      return <SpinnerComponent localProperty={true} />;
    }

    if (invalidKey) {
      return <ErrorPage message={error} />;
    }

    return <WrappedComponent {...this.props} {...this.state} />;
  }
}

export default connector(LandingEstimateWrapper);
