import * as ng from "angular";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface ILpUser extends ng.resource.IResource<ILpUser>, LpUserPrototype {
  $update(): ng.IPromise<ILpUser>;
}

export interface ILpUserResource extends ng.resource.IResourceClass<ILpUser>, IRsfResource {
  fromJSON?(data: any): ILpUser;
}

class LpUserPrototype {
  public classy: string;
  public id: number;
  public user_id: number;
  public email: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/lp_users/:id";

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta: any = angular.fromJson(response);

    _.each(meta.crm_users, (jn, index) => {
      meta.crm_users[index] = LpUser.fromJSON(jn);
    });

    return meta;
  };

  const transformSingle = (data) => {
    const jsonData = angular.fromJson(data);

    return LpUser.fromJSON(jsonData.crm_user);
  };

  const LpUser: ILpUserResource = <ILpUserResource>$resource(
    url,
    { org_id: "@org_id", id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingle,
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultiple,
        isArray: false,
      },
    },
  );

  LpUser.fromJSON = (data: any) => {
    const ms = new LpUser(data);
    ms.classy = "LpUser";

    return ms;
  };

  _.hiddenExtend(LpUser.prototype, LpUserPrototype.prototype);

  LpUser.inject = (injected: IRepository) => {
    resources = injected;
  };

  return LpUser;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
