import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import track from "react-tracking";

interface ConfirmDialogState {
  open: boolean;
  callback: () => void;
}

interface ConfirmDialogProps {
  title: string;
  description?: string;
  openDialog?: number;
  onConfirm?: () => void;
  tracking?: any;
  hideCancel?: boolean;
}

@track((props) => {
  return {
    component: "ConfirmDialog",
    title: props.title,
    description: props.description,
  };
})
export class ConfirmDialog extends React.Component<ConfirmDialogProps, ConfirmDialogState> {
  public constructor(props: ConfirmDialogProps) {
    super(props);

    this.state = {
      open: false,
      callback: props.onConfirm || null,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  public componentDidUpdate(prevProps: ConfirmDialogProps): void {
    const { openDialog, onConfirm } = this.props;
    if (openDialog > prevProps.openDialog) {
      this.setState({
        open: true,
        callback: onConfirm,
      });
    }
  }

  public show(callback) {
    return (event) => {
      this.props.tracking.trackEvent({ action: "shown" });
      event.preventDefault();

      event = {
        ...event,
        target: { ...event.target, value: event.target?.value },
      };

      this.setState({
        open: true,
        callback: () => callback(event),
      });
    };
  }

  public hide(): void {
    this.setState({ open: false, callback: null });
  }

  @track(() => ({
    action: "confirm",
  }))
  public confirm(): void {
    this.state.callback();
    this.hide();
  }

  @track(() => ({
    action: "cancel",
  }))
  public cancel(): void {
    this.hide();
  }

  public render() {
    let body = null;
    if (this.props.description && this.props.description !== "") {
      body = (
        <Modal.Body>
          <p className="break-word">{this.props.description}</p>
        </Modal.Body>
      );
    }

    return (
      <React.Fragment>
        {this.props.children &&
          //@ts-ignore
          this.props.children(this.show)}

        <Modal
          show={this.state.open}
          onHide={this.cancel}
          size="sm"
          backdropClassName="confirm-dialog-backdrop"
          className="confirm-dialog">
          <Modal.Header closeButton>
            <h3 className="break-word">{this.props.title}</h3>
          </Modal.Header>
          {body}
          <Modal.Footer>
            <Button data-testid="ok-button" variant="ok" onClick={this.confirm}>
              OK
            </Button>
            {this.props.hideCancel ? null : (
              <Button data-testid="cancel-button" variant="cancel" onClick={this.cancel}>
                Cancel
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
