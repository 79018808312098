import { Record, Map, List } from "immutable";
import * as eventResultActions from "./eventResult.actions";
import { EventResultRecord, fromJSON } from "../records/EventResult";
import { RootActions, RootState } from "app2/src/reducers";

export const EventResultStateRecord = Record({
  byId: Map<number, EventResultRecord>(),
  byOrgId: Map<number, List<number>>(),
});

export const initialState = EventResultStateRecord();
export type EventResultState = typeof initialState;

export const model = "eventResults";
export const reducer = (state: RootState, action: RootActions): RootState => {
  switch (action.type) {
    case eventResultActions.RECEIVE_EVENT_RESULT:
      const result = fromJSON(action.payload.result);
      return state.setIn([model, "byId", result.id], result);
    case eventResultActions.RECEIVE_EVENT_RESULTS:
      const byOrgId = [];
      action.payload.results.forEach((result) => {
        state = reducer(state, eventResultActions.Actions.receiveEventResult(action.payload.orgId, result));
        byOrgId.push(result.id);
      });

      return state.setIn([model, "byOrgId", action.payload.orgId], List(byOrgId));

    default:
      return state;
  }
};
