import { ActionsUnion, createAction } from "./Utils";
import { IWebhookData, WebhookRecord } from "app2/src/records/Webhook";
import { IWebhookEventData, WebhookEventRecord } from "app2/src/records/WebhookEvent";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { RootDispatchType } from "app2/src/store";
import { load, update, create, destroy, loadEvents, IWebhookResponse } from "../api/webhook.service";
import { handleErrors } from "app2/src/reducers/Utils";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { QueryParamsRecord, IPageData } from "../records/Page";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { List } from "immutable";

export const RECEIVE_WEBHOOK = "@webhooks/RECEIVE_WEBHOOK";
export const RECEIVE_WEBHOOK_EVENT = "@webhooks/RECEIVE_WEBHOOK_EVENT";
export const RECEIVE_WEBHOOKS = "@webhooks/RECEIVE_WEBHOOKS";
export const RECEIVE_WEBHOOK_EVENTS = "@webhooks/RECEIVE_WEBHOOK_EVENTS";
export const REMOVE_WEBHOOK = "@webhooks/REMOVE_WEBHOOK";
export const RESET_WEBHOOK = "@webhooks/RESET_WEBHOOK";
export const FETCH_WEBHOOK = "@webhooks/FETCH_WEBHOOK";
export const UPDATE_FORM = "@webhooks/UPDATE_FORM";
export const SET_UP_NEW_WEBHOOK = "@webhooks/SET_UP_NEW_WEBHOOK";
export const SET_WEBHOOK_LOADED = "@webhooks/SET_WEBHOOK_LOADED";

export const Actions = {
  receiveWebhook: (webhook: Partial<IWebhookData>) => createAction(RECEIVE_WEBHOOK, webhook),
  receiveWebhookEvent: (webhook_event: Partial<IWebhookEventData>) =>
    createAction(RECEIVE_WEBHOOK_EVENT, webhook_event),
  receiveWebhooks: (webhooks: Partial<IWebhookData>[]) => createAction(RECEIVE_WEBHOOKS, { webhooks }),
  receiveWebhookEvents: (webhook_events: Partial<IWebhookEventData>[]) =>
    createAction(RECEIVE_WEBHOOK_EVENTS, { webhook_events }),
  removeWebhook: (webhookId: string) => createAction(REMOVE_WEBHOOK, { webhookId }),
  fetchWebhook: (id: string) => createAction(FETCH_WEBHOOK, id),
  setWebhookLoaded: (id: string) => createAction(SET_WEBHOOK_LOADED, id),
  updateForm: (event: { rootPath: (string | number)[]; name: string; value: any }) =>
    createAction(UPDATE_FORM, { event }),
  resetWebhook: (webhookId: string) => createAction(RESET_WEBHOOK, { webhookId }),
  // editWebhook: (webhookId: number, webhook: string) => createAction(EDIT_WEBHOOK, { noteId, note }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  listWebhooks: (
    orgId: number,
    queryParams: QueryParamsRecord = new QueryParamsRecord(),
    modelName = "webhook",
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(paginationActions.Actions.fetchPage(modelName, queryParams));
      try {
        const response = await load(orgId, queryParams);
        dispatch(Actions.receiveWebhooks(response.webhooks));

        const pageData: Partial<IPageData> = {
          ids: List(response.webhooks.map((webhook: IWebhookData) => webhook.id)),
          pagination: (response as any).meta.pagination,
        };

        dispatch(paginationActions.Actions.receivePage(modelName, queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(paginationActions.Actions.receivePageError(modelName, queryParams, errors));
      }
    };
  },

  listWebhookEvents: (
    weebhookId: string,
    orgId: number,
    queryParams: QueryParamsRecord = new QueryParamsRecord(),
    modelName = "webhook_event",
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(paginationActions.Actions.fetchPage(modelName, queryParams));
      try {
        const response = await loadEvents(weebhookId, orgId, queryParams);
        dispatch(Actions.receiveWebhookEvents(response.webhook_events));

        const pageData: Partial<IPageData> = {
          ids: List(response.webhook_events.map((event: IWebhookEventData) => event.id)),
          pagination: (response as any).meta.pagination,
        };

        dispatch(paginationActions.Actions.receivePage(modelName, queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(paginationActions.Actions.receivePageError(modelName, queryParams, errors));
      }
    };
  },
  updateOrCreateWebhook: (webhook: WebhookRecord, orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchWebhook(webhook.id));

      try {
        let result: IWebhookResponse;
        if (webhook.id === "-1") {
          result = await create(webhook, orgId);
          dispatch(Actions.receiveWebhook(result.webhook));
          dispatch(paginationActions.Actions.pushId(result.webhook.id, "webhook"));
        } else {
          result = await update(webhook, orgId);
          dispatch(Actions.receiveWebhook(result.webhook));
        }

        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Webhook Saved Successfully"));
      } catch (err) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, handleErrors(err)));
        dispatch(Actions.setWebhookLoaded(webhook.id));
      }
    };
  },
  destroyWebhook: (webhook: WebhookRecord, orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        await destroy(webhook, orgId);
        dispatch(Actions.removeWebhook(webhook.id));
        await dispatch(AsyncActions.checkPageEmpty(orgId));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Webhook deleted successfully!"));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },

  checkPageEmpty: (orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      return paginationActions.checkPageEmpty(getState(), "webhook", (newQueryParams) =>
        dispatch(AsyncActions.listWebhooks(orgId, newQueryParams)),
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
