import { currentJob, selectedEstimateId } from "app2/src/selectors/job.selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncActions } from "app2/src/reducers/estimate.actions";
import { useTracking } from "react-tracking";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import { capitalizeString } from "app2/src/services/string.service";
import { EmailMessageArgs, EmailMessageModal } from "app2/src/components/Common/EmailMessageModal";

export interface EmailDocumentModalProps {
  documentType: "estimate" | "contract";
}

export const EmailDocumentModal: React.FC<EmailDocumentModalProps> = ({ documentType }) => {
  // Selectors
  const estimateId = useSelector(selectedEstimateId);
  const job = useSelector(currentJob);

  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({
    category: "Estimate",
    estimate: estimateId,
  });
  const { goBack } = usePreviousPath();

  // Methods
  const close = () => {
    goBack("/email");
  };

  const emailEstimate = (args: EmailMessageArgs) => {
    trackEvent({
      action: `${documentType} emailed`,
      category: capitalizeString(documentType),
      estimate: estimateId,
    });
    dispatch(AsyncActions.emailEstimate({ kind: "email", doc_type: documentType, ...args }));
    close();
  };

  const cancel = () => {
    trackEvent({
      action: `email ${documentType} canceled`,
      category: capitalizeString(documentType),
      estimate: estimateId,
    });
    close();
  };

  return (
    <EmailMessageModal cancel={cancel} initEmail={job.email} emailMessage={emailEstimate} title="Email Document" />
  );
};
