import { PaymentRequestRecord } from "app2/src/records/PaymentRequest";
import { RootState } from "app2/src/reducers";

/**
 * Loads the paymentRequest
 *
 * @param {RootState} state The RootState
 * @param {{paymentRequestId: number}} options
 * @returns {PaymentRequestRecord} PaymentRequestRecord
 */
export const paymentRequest = (state: RootState, props: any): PaymentRequestRecord =>
  state.getIn(["paymentRequests", "byId", props.paymentRequestId]);
