import { IProviaOrder } from "app/src/Estimator/ProviaApi";
import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { ICommand } from "app/src/Commands/Command";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import { ICustomProduct } from "app/src/Models/Product";
import { AddEstimateLineItemCmd } from "app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { EstimatorService } from "app/src/Estimator/EstimatorService";

export class AddProviaOrderCmd implements ICommand {
  public hasLineItemPricing: boolean;

  constructor(
    public estimate: IEstimate,
    public order: IProviaOrder,
    public group: IEstimateGroup,
    public fileQueue: IFileQueueFactory,
    public $http: ng.IHttpService,
    public spinnerPromise: ng.IPromise<any>,
    public $q: ng.IQService,
    public EstimatorService: EstimatorService,
  ) {}

  public execute(): boolean {
    const promises = [];
    if (this.order.line.length < 1) {
      return false;
    }

    this.hasLineItemPricing = false;

    this.hasLineItemPricing = _.all(this.order.line, (line) => {
      return line.sellprice !== undefined || line.extcost !== undefined;
    });

    _.each(this.order.line, (line) => {
      const new_product = <ICustomProduct>{};
      new_product.name = line.description;
      new_product.quantity = line.qty;
      new_product.description = this.combineOptions(line.options.option);
      if (line.tagno) {
        new_product.description += "Tag #: " + line.tagno;
      }
      if (line.comments) {
        if (new_product.description.length > 0) {
          new_product.description += "\n";
        }
        new_product.description += "Comments: " + line.comments;
      }

      if (this.hasLineItemPricing) {
        if (_.isUndefined(line.extsellprice)) {
          new_product.product_price = _.round(line.useprice / line.qty, -2);
        } else {
          new_product.product_price = _.round(line.useprice, -2);
        }
      } else {
        new_product.product_price = 0;
      }

      new_product.labor_price = 0.0;
      new_product.uom = "ea";

      const cmd = new AddEstimateLineItemCmd(this.estimate, new_product, this.group, this.EstimatorService);
      cmd.execute();
      cmd.lineItem.newly_added = false;

      if (line.imageurl) {
        promises.push(this.fileQueue.getRemoteFile(line.imageurl, cmd.lineItem));
      }
    });

    // Labor
    const labor_price = this.order.sellpricelabor;
    if (labor_price > 0) {
      const labor_product = <ICustomProduct>{};
      labor_product.name = "Labor";
      labor_product.description = "";
      labor_product.quantity = 1;
      labor_product.labor_price = labor_price;
      labor_product.product_price = 0.0;
      labor_product.uom = "ea";
      const labor_cmd = new AddEstimateLineItemCmd(this.estimate, labor_product, this.group, this.EstimatorService);
      labor_cmd.execute();
      labor_cmd.lineItem.newly_added = false;
    }

    if (!this.hasLineItemPricing) {
      const total_price = _.round(this.order.sellprice, -2);

      const sum_product = <ICustomProduct>{};
      sum_product.name = "ProVia Custom";
      sum_product.description = "";
      sum_product.quantity = 1;
      sum_product.product_price = total_price;
      sum_product.labor_price = 0.0;
      sum_product.uom = "ea";
      sum_product.visibility = "company_only";

      const sum_cmd = new AddEstimateLineItemCmd(this.estimate, sum_product, this.group, this.EstimatorService);
      sum_cmd.execute();
      sum_cmd.lineItem.newly_added = false;
    }

    this.spinnerPromise = this.$q.all(promises).then(() => {
      this.fileQueue.setQueue();
    });

    return true;
  }

  private combineOptions(options) {
    let description = "";
    _.each(options, (o) => {
      description += o + "\n";
    });
    return description;
  }
}
