import { List, Record } from "immutable";
import {
  EstimateCommissionOutputObjectsRecord,
  IEstimateCommissionOutputObjectsData,
} from "./EstimateCommissionOutputObjects";
import { EstimateCommissionVariablesRecord, IEstimateCommissionVariablesData } from "./EstimateCommissionVariables";
import { fromJSON as variablesFromJson } from "./EstimateCommissionVariables";
import { fromJSON as outputObjectsFromJson } from "./EstimateCommissionOutputObjects";

export const fromJSON = (data: Partial<IEstimateCommissionOutputData>): EstimateCommissionOutputRecord => {
  const record: IEstimateCommissionOutputRecord = { ...(data as any) };

  if (data.variables) {
    record.variables = variablesFromJson(data.variables);
  }

  if (data.verbose_output) {
    record.verbose_output = List(data.verbose_output);
  }

  if (data.verbose_output_objects) {
    record.verbose_output_objects = List(
      data.verbose_output_objects.map((outputObject) => outputObjectsFromJson(outputObject)),
    );
  }

  return new EstimateCommissionOutputRecord(record);
};

export interface IEstimateCommissionOutputData {
  variables: IEstimateCommissionVariablesData;
  verbose_output: string[];
  verbose_output_objects: IEstimateCommissionOutputObjectsData[];
}

export interface IEstimateCommissionOutputRecord {
  variables: EstimateCommissionVariablesRecord;
  verbose_output: List<string>;
  verbose_output_objects: List<EstimateCommissionOutputObjectsRecord>;
}

const defaultIEstimateCommissionOutputProps: IEstimateCommissionOutputRecord = {
  variables: null,
  verbose_output: List(),
  verbose_output_objects: List(),
};

export class EstimateCommissionOutputRecord
  extends Record(defaultIEstimateCommissionOutputProps)
  implements IEstimateCommissionOutputRecord {}
