import * as angulartics from "angulartics";
import { IDateSource, IEventService } from "app/src/Calendar/EventService";
import { ISession } from "app/src/Common/SessionService";
import { IEvent } from "app/src/Models/Event";
import { IUserResource, IUserResponse } from "app/src/Models/User";
import { BrowserDetection } from "app2/src/helpers/BrowserDetection";
import * as moment from "moment";
import { IBaseConfig } from "../Common/IBaseConfig";
import SchedulerEditEvent = kendo.ui.SchedulerEditEvent;
import SchedulerMoveEvent = kendo.ui.SchedulerMoveEvent;
import SchedulerMoveStartEvent = kendo.ui.SchedulerMoveStartEvent;

export class CalendarCtrl {
  /**
   * Due to KendoUI, the _dateSource field has to be before the schedulerOptions
   */
  /* eslint-disable @typescript-eslint/member-ordering */
  private _dateSource: IDateSource = {
    start: moment().startOf("month").subtract(1, "week"),
    end: moment().endOf("month").add(1, "week"),
  };

  public dataSource: kendo.data.SchedulerDataSource;
  public scheduler: kendo.ui.Scheduler;
  public userResponse: IUserResponse;
  public schedulerOptions = <kendo.ui.SchedulerOptions>{
    views: ["day", "workWeek", "week", { type: "month", selected: true }],
    autoBind: false,
    allDaySlot: false,
    //eventTemplate: kendo.template(jQuery("#event-template").html()),
    editable: { template: kendo.template(jQuery("#customEditorTemplate").html()) },
    timezone: "Etc/UTC",
    height: "85vh",
    dataSource: this.getDataSource(),
    resources: this.EventService.getSchedulerResourceDatasource(),
    edit: (e: SchedulerEditEvent) => {
      if ((e.event as any).isEditable === false) {
        e.preventDefault();
        return;
      }
      e.event.set("isAllDay", false);

      const validator = e.container.data("kendoValidator");

      delete validator.options.rules.dateCompare;
      _.extend(validator.options.rules, {
        greaterDate: function (input) {
          if (input.is("[data-greaterdate]") && input.val() !== "") {
            // @ts-ignore
            const startDate = kendo.parseDate($("[name='start']").val());
            // @ts-ignore
            const endDate = kendo.parseDate($("[name='end']").val());
            const theReturn = endDate == null || endDate.getTime() > startDate.getTime();
            if (!theReturn) {
              $("#date-errors").text("The End Date must be greater than the Start Date.");
              $(".k-scheduler-update").prop("disabled", true);
            } else {
              $("#date-errors").text("");
              $(".k-scheduler-update").prop("disabled", false);
            }
            return theReturn;
          }

          $("#date-errors").text("");
          $(".k-scheduler-update").prop("disabled", false);
          return true;
        },
        required: function (input) {
          if (input.is("[required]") && input.val().trim().length === 0) {
            return false;
          }

          return true;
        },
      });
      this.trackEvents("appt edit");
    },
    moveStart: (e: SchedulerMoveStartEvent) => {
      if ((e.event as any).isEditable === false) {
        e.preventDefault();
        return;
      }
    },
    navigate: (e) => {
      this.$analytics.eventTrack("switch view", { category: "Calendar", value: e.view });
      const dateSource = this.EventService.getPeriod(e.view, e.action, moment(e.date));
      this._dateSource.start = dateSource.start;
      this._dateSource.end = dateSource.end;
      this.dataSource.read();

      if (e.view === "week" || e.view === "workWeek" || e.view === "day") {
        this.$timeout(() => {
          this.scrollToCurrent();
        });
      }
    },
  };

  private selectedUsers: number[] = [];
  private moment = moment();
  /* tslint:enable:member-ordering */

  public static $inject = ["BaseConfig", "EventService", "Session", "User", "$analytics", "$timeout", "$scope", "$q"];
  constructor(
    public BaseConfig: IBaseConfig,
    public EventService: IEventService,
    public Session: ISession,
    private User: IUserResource,
    private $analytics: angulartics.IAnalyticsService,
    public $timeout: ng.ITimeoutService,
    private $scope: ng.IScope,
    private $q: ng.IQService,
  ) {
    this.schedulerOptions = _.extend(this.schedulerOptions, {
      add: this.trackEvents("add appt"),
      save: this.trackEvents("appt saved"),
      remove: this.trackEvents("appt removed"),
      cancel: this.trackEvents("appt canceled"),
      changed: this.trackEvents("appt changed"),
      moveEnd: this.trackEvents("appt moved"),
      resizeEnd: this.trackEvents("appt resized"),
    });
    this.schedulerOptions.mobile = BrowserDetection.isBrowserMobile();

    $scope.$on("kendoRendered", () => {
      Session.currentUser.$promise.then(() => {
        const promises: ng.IPromise<boolean>[] = [];

        promises.push(this.Session.can("read_self", "event", null));
        promises.push(this.Session.can("read_assigned", "event", null));

        this.$q.all(promises).then((results) => {
          if (
            _.some(results, (result) => {
              return result;
            })
          ) {
            this.userResponse = <IUserResponse>{ users: [this.Session.currentUser] };
          } else {
            this.userResponse = <IUserResponse>this.User.query({
              org_id: Session.currentUser.org_id,
              version: "v2",
            });
          }
          this.toggleStateFilter(this.Session.currentUser.id);
        });
      });

      this.scheduler.bind("edit", (e: kendo.ui.SchedulerEditEvent) => {
        e.event.set("isAllDay", false);
      });
    });
  }

  public getDataSource() {
    this.dataSource = this.EventService.getSchedulerDatasource(this._dateSource, {
      preCreate: (event) => {
        event.user_id = this.Session.currentUser.id;
        event.org_id = this.Session.currentUser.org.id;
      },
      postRead: (data) => {
        (this.scheduler as any).resources[0].dataSource.data(this.EventService.resources);
        this.scheduler.refresh();
      },
    });
    return this.dataSource;
  }

  public getResources() {
    return [
      {
        field: "resourceId",
        name: "Appointments",
        title: "Appointments",
        dataSource: [],
      },
    ];
  }

  public editableEvent(event: IEvent): boolean {
    if (event.metadata.cronofy_id) {
      return false;
    }

    return true;
  }

  public toggleStateFilter(id: number) {
    if (!_.include(this.selectedUsers, id)) {
      this.selectedUsers.push(id);
    } else {
      this.selectedUsers = _.without(this.selectedUsers, id);
    }

    this._dateSource.userIds = this.selectedUsers;
    this.dataSource.read();
  }

  public stateSelected(id: number) {
    return _.include(this.selectedUsers, id);
  }

  private scrollToCurrent() {
    const time = new Date();
    time.setHours(time.getHours() + 1);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);

    const contentDiv = this.scheduler.element.find("div.k-scheduler-content");
    const rows = contentDiv.find("tr");

    for (let i = 0; i < rows.length; i++) {
      const slot = this.scheduler.slotByElement(rows[i]);

      const slotTime = kendo.toString(slot.startDate, "HH:mm");
      const targetTime = kendo.toString(time, "HH:mm");

      if (targetTime === slotTime) {
        // noinspection TypeScriptUnresolvedFunction
        (this.scheduler.view() as any)._scrollTo($(rows[i]).find("td:first")[0], contentDiv[0]);
      }
    }
  }

  private trackEvents(eventName: string) {
    return (e: any) => {
      if (e.event.isEditable === false) {
        e.preventDefault();
        return;
      }
      this.$analytics.eventTrack(eventName, { category: "Calendar", value: e.event.id });
    };
  }
}
