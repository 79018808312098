import { CommissionMethod } from "../records/Estimate";
import { EstimateCommissionRecord } from "../records/EstimateCommission";
import { RootState } from "../reducers";

/**
 * Returns an array with calculated commmission data
 *
 * @param {RootState} state
 * @returns {string[]}
 */
export const estimateCommission = (state: RootState): EstimateCommissionRecord =>
  state.getIn(["estimates", "calculatedCommission"]);

/**
 * Returns a summary from the estimate commission
 *
 * @param {RootState} state
 * @returns {string}
 */
export const estimateCommissionSummary = (state: RootState, commissionMethod = ""): string => {
  let output: any;
  if (commissionMethod === CommissionMethod.custom) {
    output = state
      .getIn(["estimates", "calculatedCommission", "output", "verbose_output_objects"], [])
      .map((obj) => obj["formatted"]);
  } else {
    output = state.getIn(["estimates", "calculatedCommission", "output", "verbose_output"]);
  }
  return (output || []).join("\n");
};
