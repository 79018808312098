import * as React from "react";
import { DateFormat } from "../../helpers/Format";
import { connect, ConnectedProps } from "app2/src/connect";
import { ModelCache } from "./ModelCache";
import { RootState } from "app2/src/reducers";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { UserRecord, IUserData } from "app2/src/records/UserRecord";

const mapStateToProps = (state: RootState, ownProps: EventStatProps) => {
  let user = new UserRecord();
  if (ownProps.event.data.userId) {
    user = state.getIn(["users", "usersById", parseInt(ownProps.event.data.userId)], {});
  }

  let org = new OrgRecord();
  if (ownProps.event.data.orgId) {
    org = state.getIn(["orgs", "orgsById", parseInt(ownProps.event.data.orgId)], org);
  }

  return {
    cachedUser: user,
    cachedOrg: org,
  };
};

const mapDispatchToProps = () => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface EventStatProps {
  event: any;
  ModelCache: ModelCache;
}

export interface EventStatState {
  expanded: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & EventStatProps;

class EventStat extends React.Component<Props, EventStatState> {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.renderEvent.bind(this);
    this.renderPageView.bind(this);
  }

  public componentDidMount() {
    const { ModelCache, event } = this.props;

    if (event.data.userId) {
      ModelCache.getUser(parseInt(event.data.userId));
    }

    if (event.data.orgId) {
      ModelCache.getOrg(parseInt(event.data.orgId));
    }
  }

  public render() {
    const { event } = this.props;

    switch (event.data.hitType) {
      case "pageview":
        return this.renderPageView(event);
      case "event":
        return this.renderEvent(event);
    }
  }

  public renderEvent(event) {
    const { cachedUser, cachedOrg } = this.props;
    let orgCol = <td></td>;
    let userCol = <td></td>;

    if (cachedOrg) {
      orgCol = (
        <td>
          <a ui-sref="root.org_show.info({org.id})">{cachedOrg.name}</a>
        </td>
      );
    }

    if (cachedUser) {
      userCol = <td>{cachedUser.email}</td>;
    }

    return (
      <tr key={event.id}>
        <td>Event</td>
        <td>{event.data.category}</td>
        <td>{event.data.action}</td>
        {userCol}
        {orgCol}
        <td>{DateFormat(event.inserted_at, "short")}</td>
      </tr>
    );
  }

  public renderPageView(event) {
    return (
      <tr key={event.id}>
        <td>Page View</td>
        <td></td>
        <td>{event.data.page}</td>
        <td></td>
        <td></td>
        <td>{DateFormat(event.inserted_at, "short")}</td>
      </tr>
    );
  }
}

export default connector(EventStat);
