import { useDispatch, useSelector } from "react-redux";
import { pathname } from "app2/src/selectors/router.selectors";
import { replace, goBack as historyGoBack } from "connected-react-router/immutable";
import { useLocation } from "react-router";

export const usePreviousPath = (): {
  goBack: (pathToRemove: string) => void;
} => {
  const dispatch = useDispatch();
  const path = useSelector(pathname);
  const location = useLocation();

  const goBack = (pathToRemove: string) => {
    // https://stackoverflow.com/questions/37385570/how-to-know-if-react-router-can-go-back-to-display-back-button-in-react-app
    // location.key chosen for 2 reasons:
    // 1. location.key is defined after a refresh in an existing browser tab (when OCC app routing has occurred)
    //     a. any solution relying on redux would use 'replace' when `goBack` is available
    // 2. location.key = 'default' or undefined when pasting a link into a new browser tab
    if (!["", "default", undefined].includes(location.key)) {
      dispatch(historyGoBack());
    } else {
      const newPath = path.split(pathToRemove)[0];
      dispatch(replace(newPath));
    }
  };

  return {
    goBack,
  };
};
