import { Map, Record, List } from "immutable";
import { fromJSON, FeeRecord } from "app2/src/records/billing/Fee";
import { RootState, RootActions } from "..";
import * as feeActions from "./fee.actions";

export const FeeStateRecord = Record({
  byId: Map<number, FeeRecord>(),
  loading: false,
  errors: List<string>(),
});

export const initialState = FeeStateRecord();
export type FeeState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  switch (action.type) {
    case feeActions.RECEIVE_FEE:
      const fee = fromJSON({ ...action.payload.fee, loading: false });

      return state.setIn(["billing", "fees", "byId", fee.id], fee);
    case feeActions.RECEIVE_FEE_ERROR:
      return state
        .setIn(["billing", "fees", "byId", action.payload.id, "errors"], List<string>(action.payload.errors))
        .setIn(["billing", "fees", "byId", action.payload.id, "loading"], false);
    case feeActions.FETCH_FEE:
      if (state.getIn(["billing", "fees", "byId", action.payload.id])) {
        return state.setIn(["billing", "fees", "byId", action.payload.id, "loading"], true);
      }
      return state.setIn(
        ["billing", "fees", "byId", action.payload.id],
        fromJSON({ id: action.payload.id, loading: true }),
      );
    case feeActions.FETCH_FEES:
      return state.setIn(["billing", "fees", "loading"], true);
    case feeActions.RECEIVE_FEES:
      action.payload.fees.forEach((d) => {
        state = reducer(state, feeActions.Actions.receiveFee(d));
      });

      return state.setIn(["billing", "fees", "loading"], false);
    case feeActions.RECEIVE_ERRORS:
      return state
        .setIn(["billing", "fees", "errors"], List<string>(action.payload.errors))
        .setIn(["billing", "fees", "loading"], false);
    case feeActions.REMOVE_FEE:
      return state.removeIn(["billing", "fees", "byId", action.payload.id]);
    case feeActions.CLEAR_FEES:
      return state.setIn(["billing", "fees", "byId"], Map());
    default:
      return state;
  }
};
