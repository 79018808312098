import { baseCleanProps } from "app2/src/api/Api";
import { ICalculatedFinancingResponse } from "app2/src/api/estimate.service";
import { fetcher } from "../helpers/Fetcher";
import { IFinanceOptionData, FinanceOptionRecord, FinanceOptionProvider } from "../records/FinanceOption";

export interface IFinanceOptionIndexResponse {
  finance_options: IFinanceOptionData[];
}

interface IFinanceOptionResponse {
  finance_option: IFinanceOptionData;
}

export interface FinanceOptionIndexOptions {
  provider?: FinanceOptionProvider;
  remove_provider?: FinanceOptionProvider[];
}

class FinanceOptionService {
  public orgUrl = "api/v1/orgs/:id/finance_options";
  public url = "api/v1/finance_options";

  public loadByOrg(orgId: number, options: FinanceOptionIndexOptions): Promise<IFinanceOptionIndexResponse> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    return fetcher.get<IFinanceOptionIndexResponse>(actionUrl, options);
  }

  public create(orgId: number, financeOption: Partial<IFinanceOptionData>): Promise<IFinanceOptionData> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    return fetcher.post<IFinanceOptionResponse>(actionUrl, { finance_option: this.cleanProps(financeOption) }).then(
      (response) => response.finance_option,
      (response) => Promise.reject(response.data.errors),
    );
  }

  public update(orgId: number, financeOption: Partial<IFinanceOptionData>): Promise<IFinanceOptionData> {
    let actionUrl = this.orgUrl.replace(":id", orgId.toString());
    actionUrl = fetcher.joinUrls(actionUrl, financeOption.id.toString());

    return fetcher.patch<IFinanceOptionResponse>(actionUrl, { finance_option: this.cleanProps(financeOption) }).then(
      (response) => response.finance_option,
      (response) => {
        if (response.status === 500) {
          return Promise.reject(["Internal Server Error"]);
        }

        if (_.isArray(response.data.errors)) {
          return Promise.reject(response.data.errors);
        }

        return Promise.reject(["Unknown Error"]);
      },
    );
  }

  public archive(orgId: number, financeOption: FinanceOptionRecord): Promise<IFinanceOptionData> {
    let actionUrl = this.orgUrl.replace(":id", orgId.toString());
    actionUrl = fetcher.joinUrls(actionUrl, financeOption.id.toString());

    return fetcher.delete<any>(actionUrl).then(
      () => {
        return financeOption.set("archived", true);
      },
      (response) => Promise.reject(response.data.errors),
    );
  }

  public calculate(
    orgId: number,
    financeOptionIds: number[],
    financedAmount: number,
  ): Promise<ICalculatedFinancingResponse> {
    const actionUrl = fetcher.joinUrls(this.url, "calculate");

    return fetcher.get<ICalculatedFinancingResponse>(actionUrl, {
      org_id: orgId,
      "finance_option_ids[]": financeOptionIds,
      financed_amount: financedAmount,
    });
  }

  public cleanProps(financeOption: Partial<IFinanceOptionData>): Partial<IFinanceOptionData> {
    financeOption = baseCleanProps(financeOption as IFinanceOptionData);

    return financeOption;
  }
}

export const financeOptionService = new FinanceOptionService();
