import { useEffect } from "react";
import { Room } from "twilio-video";
import { Callback } from "app2/src/components/VideoProvider/types";

export default function useHandleOnDisconnect(room: Room, onDisconnect: Callback) {
  useEffect(() => {
    room.on("disconnected", onDisconnect);
    return () => {
      room.removeListener("disconnected", onDisconnect);
    };
  }, [room, onDisconnect]);
}
