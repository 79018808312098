export class AbstractWizardController {
  public activeStep = 0;

  constructor() {}

  public isStepValid(step) {
    return this[`isStep${step}Valid`]();
  }

  public nextStep() {
    this.activeStep += 1;
  }

  public gotoStep(step: number) {
    this.activeStep = step;
  }

  public back() {
    if (this.activeStep <= 0) this.activeStep = 0;
    else {
      this.activeStep -= 1;
    }
  }
}
