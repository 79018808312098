import * as React from "react";
import { Form } from "react-bootstrap";

export interface IncludeCheckBoxProps {
  checked: boolean;
  name: string;
  label?: string;
  onChange: (event) => void;
}

export const IncludeCheckBox = ({ checked, label, name, onChange }: IncludeCheckBoxProps): JSX.Element => {
  return (
    <Form.Check
      data-testid={name}
      type="checkbox"
      name={name}
      inline
      label={label}
      checked={checked}
      onChange={onChange}
    />
  );
};
