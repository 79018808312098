import * as React from "react";
import { connectFC } from "app2/src/connect";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import { Context } from "app2/src/components/Chargebee/Context";

export const Portal: React.FC = () => {
  const { sessionUrl, dispatch: contextDispatch } = React.useContext(Context);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <Modal show={true} className={"d-flex justify-content-center chargebee-modal"}>
              <Modal.Header>
                <Modal.Title>
                  <div className="row-eq-height">
                    <Col md={12}>
                      <h3>Billing Information</h3>
                    </Col>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <iframe src={sessionUrl} title="Chargebee Self-Serve Portal"></iframe>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => contextDispatch("close")}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default connectFC(Portal);
