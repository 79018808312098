import { Record } from "immutable";

export const fromJSON = (json: Partial<IFinanceOptionValidationData>): FinanceOptionValidationRecord => {
  const record: IFinanceOptionValidationRecord = { ...(json as any) };

  return new FinanceOptionValidationRecord(record);
};

export interface IFinanceOptionValidationData {
  active: boolean;
  min_amount: string;
  max_amount: string;
  min_payment: string;
  state: string;
}

export interface IFinanceOptionValidationRecord {
  active: boolean;
  min_amount: number;
  max_amount: number;
  min_payment: number;
  state: string;
}

export const defaultFinanceOptionValidationProps = {
  active: false,
  min_amount: 0,
  max_amount: 0,
  min_payment: 0,
  state: "",
};

export class FinanceOptionValidationRecord
  extends Record<IFinanceOptionValidationRecord>(defaultFinanceOptionValidationProps)
  implements IFinanceOptionValidationRecord
{
  public readonly active!: boolean;
  public readonly min_amount!: number;
  public readonly max_amount!: number;
  public readonly min_payment!: number;

  public constructor(values?: Partial<IFinanceOptionValidationRecord>) {
    values ? super(values) : super();
  }
}
