import { IUserResource } from "app/src/Models/User";
import { ISession } from "app/src/Common/SessionService";
import { IBaseConfig } from "../Common/IBaseConfig";

export class AcceptTosCtrl {
  public static $inject = ["User", "Session", "$state", "BaseConfig"];
  constructor(
    public User: IUserResource,
    public Session: ISession,
    public $state: ng.ui.IStateService,
    public BaseConfig: IBaseConfig,
  ) {}

  public ok() {
    this.User.save({ id: this.Session.currentUser.id }, { accepted_tos: true }, () => {
      this.$state.go(this.BaseConfig.DEFAULT_ROUTE);
    });
  }

  public cancel() {
    this.User.save({ id: this.Session.currentUser.id }, { accepted_tos: false }, () => {
      this.$state.go("root.logout");
    });
  }
}
