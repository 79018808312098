import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ancestors } from "app2/src/selectors/folder.selectors";
import { DropdownButton } from "react-bootstrap";
import { FileType } from "app2/src/components/Folders";
import DropdownItem from "app2/src/components/Folders/MoveFilesDropdown/DropdownItem";
import { ids } from "app2/src/selectors/pagination.selectors";
import track from "react-tracking";

const mapStateToProps = (state: RootState, ownProps: MoveFilesDropdownProps) => {
  const modelName = "folder";
  return {
    folderIds: ids(state, { modelName }),
    parents: ancestors(state, { folderId: ownProps.folderParentId }).slice(1, -1),
  };
};

const connector = connect(mapStateToProps, null);

interface MoveFilesDropdownProps {
  fileType: FileType;
  folderParentId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & MoveFilesDropdownProps;

@track(() => {
  return {
    component: "MoveFilesDropdown",
  };
})
class MoveFilesDropdown extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
  }

  @track((props, _state, [isOpen]) => ({
    action: `toggle move ${props.fileType} dropdown`,
    open: isOpen,
  }))
  public onToggle(_isOpen: boolean): void {}

  public render() {
    const { folderIds, parents, fileType } = this.props;
    return (
      <DropdownButton
        data-testid="move-button"
        className="pull-right"
        title={`Move ${_.toTitleCase(fileType)} To  `}
        variant="default"
        onToggle={this.onToggle}>
        {parents.map((parent) => (
          <DropdownItem key={parent.id.toString()} folderId={parent.id} icon="angle-left" fileType={fileType} />
        ))}
        {folderIds.map((folderId) => (
          <DropdownItem key={folderId.toString()} folderId={folderId} icon="angle-right" fileType={fileType} />
        ))}
      </DropdownButton>
    );
  }
}

export default connector(MoveFilesDropdown);
