import { FlashLevels } from "app/src/Common/FlashLevels";
import {
  fromJSON,
  IPaymentAuthorizationData,
  PaymentAuthorizationRecord,
} from "app2/src/records/PaymentAuthorizationRecord";
import { fetcher } from "app2/src/helpers/Fetcher";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as config from "react-global-configuration";
import { ThunkResult } from ".";
import { IPaymentData, PaymentRecord } from "../records/PaymentRecord";
import { ActionsUnion, createAction } from "./Utils";
import { RootDispatchType } from "../store";

export const RECEIVE_PAGE = "@payments/RECEIVE_PAGE";
export const RESET_PAGES = "@payments/RESET_PAGES";
export const FETCH_PAGE = "@payments/FETCH_PAGE";
export const POST_JOB = "@payments/POST_JOB";
export const FETCH_AUTHORIZED = "@payments/FETCH_AUTHORIZED";
export const RECEIVE_AUTHORIZED = "@payments/RECEIVE_AUTHORIZED";
export const SET_PAYMENT_ERROR = "@payments/SET_PAYMENT_ERROR";
export const CLEAR_LAST_PAYMENT = "@payments/CLEAR_LAST_PAYMENT";
export const SET_PROVIDER_LOADING = "@payments/SET_PROVIDER_LOADING";
export const SET_PROVIDER_VALID = "@payments/SET_PROVIDER_VALID";
export const SET_PROVIDER_SUBMITTING = "@payments/SET_PROVIDER_SUBMITTING";
export const MAKE_PAYMENT = "@payments/MAKE_PAYMENT";
export const RECEIVE_PAYMENT = "@payments/RECEIVE_PAYMENT";
export const SET_SENDING_RECEIPT = "@payments/SET_SENDING_RECEIPT";

export interface IPagination {
  pagination: {
    total_count: number;
    total_pages: number;
    current_page: number;
  };
}

export interface IPaymentPageData {
  page: number;
  payments: IPaymentData[];
  meta: IPagination;
}

export interface IPaymentQueryParams {
  page: number;
  job_id: number;
}

export const Actions = {
  receivePage: (page: IPaymentPageData) => createAction(RECEIVE_PAGE, page),
  fetchPage: (params: IPaymentQueryParams) => createAction(FETCH_PAGE, params),
  resetPages: () => createAction(RESET_PAGES),
  createJob: (payment_id: number) => createAction(POST_JOB, payment_id),
  makePayment: () => createAction(MAKE_PAYMENT),
  receivePayment: (payment: PaymentRecord) => createAction(RECEIVE_PAYMENT, payment),
  clearLastPayment: () => createAction(CLEAR_LAST_PAYMENT),
  fetchAuthorized: (orgId: number) => createAction(FETCH_AUTHORIZED, { orgId }),
  receiveAuthorized: (orgId: number, auth: PaymentAuthorizationRecord) =>
    createAction(RECEIVE_AUTHORIZED, { orgId, auth }),
  paymentError: (error: string) => createAction(SET_PAYMENT_ERROR, { error }),
  providerValid: (valid: boolean) => createAction(SET_PROVIDER_VALID, { valid }),
  providerLoading: (loading: boolean) => createAction(SET_PROVIDER_LOADING, { loading }),
  providerSubmitting: (submitting: boolean) => createAction(SET_PROVIDER_SUBMITTING, { submitting }),
  setSendingReceipt: (sending: boolean) => createAction(SET_SENDING_RECEIPT, { sending }),
};

export const AsyncActions = {
  getPage: (params: IPaymentQueryParams): ThunkResult<Promise<IPaymentPageData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPage(params));
      const actionUrl = fetcher.joinUrls(config.get("RSF_ANALYTICS_URL"), "payments/v1/payments");

      const options = { method: "GET" };
      return fetcher.fetch(actionUrl, params, options).then((response) => {
        const headers = response.headers;

        return fetcher.json(response).then((data) => {
          data.page = params.page;
          (data as IPaymentPageData).meta = {
            pagination: {
              total_count: parseInt(headers.get("total")),
              total_pages: parseInt(headers.get("total-pages")),
              current_page: parseInt(headers.get("page-number")),
            },
          };
          dispatch(Actions.receivePage(data));
          return data;
        });
      });
    };
  },
  getAuthorized: (orgId: number): ThunkResult<Promise<IPaymentAuthorizationData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchAuthorized(orgId));
      return fetcher
        .get<IPaymentAuthorizationData>(
          fetcher.joinUrls(config.get("RSF_ANALYTICS_URL"), `payments/v1/payments/${orgId}/authorized`),
        )
        .then((result) => {
          result.orgId = orgId;
          dispatch(Actions.receiveAuthorized(orgId, fromJSON(result)));
          //dispatch(paysimpleActions.Actions.setAuthorized(orgId, result.pay_simple));
          return result;
        });
    };
  },
  sendReceipt: (payment: PaymentRecord): ThunkResult<Promise<boolean>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.setSendingReceipt(true));

      return fetcher
        .post<any>(`payments/v1/payments/${payment.id}/receipt`)
        .then((result) => {
          dispatch(Actions.setSendingReceipt(false));
          return true;
        })
        .catch(() => {
          dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, "There were problems sending the receipt."));
          dispatch(Actions.setSendingReceipt(false));
          return false;
        });
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
