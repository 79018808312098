import { recordChanged } from "app2/src/helpers/Record";
import { PresentationRecord } from "app2/src/records/Presentation";
import { ids } from "app2/src/selectors/pagination.selectors";
import { List } from "immutable";
import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const presentationsById = (state: RootState) => state.getIn(["presentations", "byId"]);
export const presentation = (state: RootState, props: any) =>
  state.getIn(["presentations", "byId", props.presentationId]);
export const lastSavedPresentation = (state: RootState, props: any) =>
  state.getIn(["presentations", "lastSavedById", props.presentationId]);
export const currentPresentationId = (state: RootState, props?: any) =>
  state.getIn(["presentations", "currentPresentationId"]);

export const presentationChanged = createSelector(
  [presentation, lastSavedPresentation],
  (presentation: PresentationRecord, lastSavedPresentation: PresentationRecord) => {
    return recordChanged(presentation, lastSavedPresentation);
  },
);

export const presentationsSelectedIds = createSelector(
  [
    presentationsById,
    (state: RootState, props: any) => ({
      state,
      props,
    }),
  ],
  (presentationsById, stateProps: any): List<number> => {
    const { state } = stateProps;
    const presentationIds = ids(state, { modelName: "presentation" });
    if (!presentationIds || presentationIds.size === 0) {
      return List();
    }

    const presentations = presentationIds.map((presentationId: number) => presentationsById.get(presentationId));

    return presentations
      .filter((presentation: PresentationRecord) => {
        return presentation.selected === true;
      })
      .map((presentation: PresentationRecord) => presentation.id);
  },
);

export const selectedChecked = createSelector(
  [presentationsById, (_: RootState, props: any) => props],
  (presentationsById, props: any): boolean => {
    const { presentationIds } = props;
    if (!presentationIds) {
      return false;
    }

    const presentations = presentationIds.map((docId: number) => presentationsById.get(docId));

    if (presentations.size === 0) {
      return false;
    }

    return !presentations.find((presentation: PresentationRecord) => {
      return presentation.selected === false;
    });
  },
);

export const presentationsSelected = createSelector([presentationsSelectedIds], (presentationsSelectedIds): boolean => {
  return presentationsSelectedIds.size > 0;
});
