import { RootState, RootActions } from "app2/src/reducers";
import { createSelector } from "reselect";
import { PlanRecord, IPlanData } from "app2/src/records/billing/Plan";
import { Map, List } from "immutable";

export const plansById = (state: RootState) => state.getIn(["billing", "plans", "byId"]);
export const planById = (state: RootState, props: { id }) =>
  state.getIn(["billing", "plans", "byId", parseInt(props.id)]);

export const getPlanRoots = createSelector([plansById], (byId: Map<number, PlanRecord>) => {
  if (!byId) {
    return List();
  }

  const [...keys] = byId.keySeq();
  const list: PlanRecord[] = [];

  keys.forEach((key) => {
    const plan = byId.get(key);

    // keep track of root plans
    if (!plan.children_ids || plan.children_ids.count() <= 0) {
      list.push(plan);
    }
  });

  return List<PlanRecord>(list);
});

export const getPlanFamilyRoots = createSelector(
  [getPlanRoots, (_, props) => props.family],
  (roots: List<PlanRecord>, family: string) => {
    return roots.filter((p) => p.family === family);
  },
);

export const getPlanHierarchy = createSelector(
  [plansById, planById],
  (byId: Map<number, PlanRecord>, plan: PlanRecord) => {
    if (!byId || !plan) {
      return List();
    }

    const list: PlanRecord[] = [plan];

    let current = plan.parent_id;
    while (current) {
      const parent = byId.get(current);
      current = parent.parent_id;
      list.push(parent);
    }

    let currentChildren = plan.children_ids;
    while (currentChildren && currentChildren.count() > 0) {
      const child = byId.get(currentChildren.get(0));
      currentChildren = child.children_ids;
      list.unshift(child);
    }

    return List<PlanRecord>(list);
  },
);
