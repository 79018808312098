import { IBaseConfig } from "../Common/IBaseConfig";
import { ISession } from "app/src/Common/SessionService";

export class ChangePasswordCtrl {
  public $errors: string[] = [];
  public current_password: string;
  public new_password: string;
  public confirm_password: string;

  public static $inject = ["$http", "$stateParams", "$state", "BaseConfig", "Session"];
  constructor(
    public $http: ng.IHttpService,
    public $stateParams: ng.ui.IStateParamsService,
    public $state: ng.ui.IStateService,
    public BaseConfig: IBaseConfig,
    public Session: ISession,
  ) {}

  public changePassword(form: ng.IFormController) {
    if (form.$invalid) {
      return;
    }

    this.$http
      .post(this.BaseConfig.BASE_URL + "/api/v1/users/change_password", {
        password: this.current_password,
        user: {
          password: this.new_password,
          password_confirmation: this.confirm_password,
        },
      })
      .then(() => {
        this.$state.go("root.login");
      })
      .catch((response: ng.IHttpResponse<any>) => {
        if (response.status === 401) {
          this.$errors.push("Invalid Password.");
        }
      });
  }
}
