import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IProviaOrder {
  ponumber: number;
  orderno: number;
  locationid: number;
  locationname: string;
  locationhandle: string;
  vendor: string;
  jobname: string;
  enterdate: Date;
  shipdate: Date;
  status: string;
  user1: string;
  user2: string;
  subtotal: number;
  tax: number;
  total: number;
  sellprice: number;
  sellpricelabor: number;
  sellpricetax: number;
  comments: string;
  line: IProviaLineItem[];
}

export interface IProviaLineItem {
  lineno: number;
  parentline: number;
  parent: IProviaLineItem;
  model: string;
  description: string;
  qty: number;
  cost: number;
  useprice: number;
  extcost: number;
  sellprice: number;
  listprice: number;
  extsellprice: number;
  tagno: number;
  imageurl: string;
  comments: string;
  options: IOptions;
}

export interface IOptions {
  option: string[];
}

export interface IProviaApi {
  username: string;
  password: string;
  portal: boolean;
  orders: IProviaOrder[];

  fetchOrders(portal: boolean): ng.IPromise<IProviaOrder[]>;
  fetchOrderId(id: number, portal: boolean): ng.IPromise<IProviaOrder>;
}

export class ProviaApi implements IProviaApi {
  public username: string;
  public password: string;
  public orders: IProviaOrder[];
  public order: IProviaOrder;
  public portal: boolean;

  constructor(
    public $http: ng.IHttpService,
    public $q: ng.IQService,
    public org_id: number,
    public BaseConfig: IBaseConfig,
    public fileQueue: IFileQueueFactory,
  ) {}

  public fetchOrders(portal: boolean): ng.IPromise<IProviaOrder[]> {
    const url = this.BaseConfig.BASE_URL + "/integrations/provia_orders";
    const params = { params: { user: this.username, password: this.password, org_id: this.org_id, portal } };
    return this.$http.get(url, params).then((resp) => {
      this.transformMultipleOrders(resp);
      return this.orders;
    });
  }

  public fetchOrderId(order_number: number, portal: boolean): ng.IPromise<IProviaOrder> {
    const url = this.BaseConfig.BASE_URL + "/integrations/provia_order";
    const params = {
      params: { user: this.username, password: this.password, order_number: order_number, org_id: this.org_id, portal },
    };
    return this.$http.get(url, params).then((resp) => {
      return this.transformSingleOrder(resp);
    });
  }

  public transformSingleOrder(resp) {
    const order = resp.data.orderfile.order;

    if (order.sellprice && !_.isNumber(order.sellprice)) {
      order.sellprice = parseFloat(order.sellprice.replace(/\,/g, ""));
    } else {
      order.sellprice = 0;
    }

    if (order.sellpricelabor && !_.isNumber(order.sellpricelabor)) {
      order.sellpricelabor = parseFloat(order.sellpricelabor.replace(/\,/g, ""));
    } else {
      order.sellpricelabor = 0;
    }

    if (!_.isArray(order.line)) {
      const line = order.line;
      order.line = [];
      order.line.push(<IProviaLineItem>line);
    }
    _.each(order.line, (l: any) => {
      if (!_.isNumber(l.qty)) {
        l.qty = parseInt(l.qty);
      }

      if (l.sellprice && !_.isNumber(l.sellprice)) {
        l.useprice = _.parseFloat(l.sellprice.replace(/,/g, ""));
      } else if (l.listprice) {
        l.useprice = _.parseFloat(l.listprice.replace(/,/g, ""));
      } else if (l.extcost) {
        l.useprice = _.parseFloat(l.extcost.replace(/,/g, ""));
      } else {
        l.useprice = 0;
      }

      if (l.options.option) {
        if (!_.isArray(l.options.option)) {
          const option = l.options.option;
          l.options.option = [];
          l.options.option.push(option);
        }
      }
    });
    return order;
  }

  private transformMultipleOrders(resp) {
    this.orders = resp.data.orders.order;
    if (!_.isArray(this.orders)) {
      const orders = this.orders;
      this.orders = [];
      this.orders.push(<IProviaOrder>orders);
    }
  }
}
