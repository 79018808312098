import { Record, Map } from "immutable";

export const fromJSON = (json: Partial<IDefaultsData>): DefaultsRecord => {
  const record: IDefaultsRecord = { ...(json as any) };

  if (json.options) {
    record.options = Map(json.options).map((opt) => defaultOptionFromJSON(opt));
  }

  return new DefaultsRecord(record);
};

export interface IDefaultsData {
  options: { string: IDefaultOptionData };
}

export interface IDefaultsRecord {
  options: Map<string, DefaultOptionRecord>;
}

const defaultDefaultsRecordProps: IDefaultsRecord = {
  options: Map<string, DefaultOptionRecord>(),
};

export class DefaultsRecord extends Record(defaultDefaultsRecordProps) implements IDefaultsRecord {}

export const defaultOptionFromJSON = (json: Partial<IDefaultOptionData>): DefaultOptionRecord => {
  const record: IDefaultOptionRecord = { ...(json as any) };

  return new DefaultOptionRecord(record);
};

export interface IDefaultOptionData {
  option_group_uuid: string;
  quantity: number;
}

export interface IDefaultOptionRecord {
  option_group_uuid: string;
  quantity: number;
}

const defaultDefaultOptionProps: IDefaultOptionRecord = {
  option_group_uuid: "",
  quantity: 0,
};

export class DefaultOptionRecord extends Record(defaultDefaultOptionProps) implements IDefaultOptionRecord {}
