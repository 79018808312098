import * as screenShareActions from "./screenShare.actions";
import { fromJSON, ScreenShareRecord } from "app2/src/records/ScreenShare";
import { Record, List, Map } from "immutable";

export type ScreenShareStatusValues = "off" | "start_requested" | "stop_requested" | "tab_opened" | "active";
export type ScreenShareKindValues = "basic" | "premium";

export interface IJobScreenShareRecord {
  screenShare: ScreenShareRecord;
  screenShareStatus: ScreenShareStatusValues;
  screenShareKind: ScreenShareKindValues;
}

export const JobScreenShareRecord: Record.Factory<IJobScreenShareRecord> = Record<IJobScreenShareRecord>({
  screenShare: null,
  screenShareStatus: "off",
  screenShareKind: "basic",
});

export const ScreenShareStateRecord = Record({
  screenShareToken: null,
  activeScreenShareJobId: null,
  byJobId: Map<number, typeof JobScreenShareRecord>(),
  roomExists: false,
});

export const initialState = ScreenShareStateRecord();
export type ScreenShareState = typeof initialState;

export const reducer = (state = initialState, action: screenShareActions.Actions): ScreenShareState => {
  switch (action.type) {
    case screenShareActions.SET_SCREEN_SHARE_STATUS:
      if (!state.getIn(["byJobId", action.payload.jobId])) {
        state = state.setIn(["byJobId", action.payload.jobId], JobScreenShareRecord());
      }
      return state.setIn(["byJobId", action.payload.jobId, "screenShareStatus"], action.payload.status);
    case screenShareActions.SET_SCREEN_SHARE_KIND:
      if (!state.getIn(["byJobId", action.payload.jobId])) {
        state = state.setIn(["byJobId", action.payload.jobId], JobScreenShareRecord());
      }
      return state.setIn(["byJobId", action.payload.jobId, "screenShareKind"], action.payload.kind);
    case screenShareActions.SET_ACTIVE_SCREEN_SHARE_JOB:
      return state.setIn(["activeScreenShareJobId"], action.payload);
    case screenShareActions.FETCH_SCREEN_SHARE:
      if (!state.getIn(["byJobId", action.payload.jobId])) {
        state = state.setIn(["byJobId", action.payload.jobId], JobScreenShareRecord());
      }
      if (state.getIn(["byJobId", action.payload.jobId, "screenShare"])) {
        return state.setIn(["byJobId", action.payload.jobId, "screenShare", "loading"], true);
      }
      return state.setIn(
        ["byJobId", action.payload.jobId, "screenShare"],
        fromJSON({
          id: action.payload.id,
          job_id: action.payload.jobId,
          loading: true,
        }),
      );
    case screenShareActions.RECEIVE_SCREEN_SHARE:
      const screenShare = fromJSON({ ...action.payload.screenShare, loading: false });

      if (!state.getIn(["byJobId", action.payload.jobId])) {
        state = state.setIn(["byJobId", action.payload.jobId], JobScreenShareRecord());
      }
      return state.setIn(["byJobId", action.payload.jobId, "screenShare"], screenShare);
    case screenShareActions.RECEIVE_SCREEN_SHARE_ERROR:
      return state
        .setIn(["byJobId", action.payload.jobId, "screenShare", "errors"], List<string>(action.payload.errors))
        .setIn(["byJobId", action.payload.jobId, "screenShare", "loading"], false);
    case screenShareActions.RECEIVE_SCREEN_SHARE_TOKEN:
      return state.setIn(["screenShareToken"], action.payload);
    case screenShareActions.RECEIVE_ROOM_STATUS:
      return state.setIn(["roomExists"], action.payload);
    default:
      return state;
  }
};
