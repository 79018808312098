import * as React from "react";
import { Form } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";

export const BootstrapInputAdapter = ({
  input,
  meta,
  children,
  storevalue,
  append,
  ...rest
}: FieldRenderProps<string>): JSX.Element => {
  const customOnChange = rest["onChange"];
  const { value, ...inputCopy } = { ...input };

  return (
    <>
      <Form.Control
        {...inputCopy}
        {...rest}
        value={input.value || storevalue || ""}
        onChange={(event) => {
          if (customOnChange) {
            customOnChange(event);
          }
          input.onChange(event.target.value);
        }}>
        {children}
      </Form.Control>
      {append}
      <span className="has-error" style={{ flexBasis: "100%" }}>
        {(meta.error || meta.submitError) && meta.touched && <span>{meta.error || meta.submitError}</span>}
      </span>
    </>
  );
};
