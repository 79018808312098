import { Record, List } from "immutable";
import { Nullable } from ".";
import { fromJSON as fileFromJSON, FileRecord, IFileData } from "./File";
import { ISlideData, SlideRecord, fromJSON as slideFromJSON, toJSON as slideToJSON } from "./Slide";
import {
  IDynamicPresentationLinkData,
  DynamicPresentationLinkRecord,
  fromJSON as dplFromJSON,
  toJSON as dplToJSON,
} from "./DynamicPresentationLink";
import { ITaskData } from "./Task";

export const fromJSON = (json: Partial<IPresentationData>): PresentationRecord => {
  const data: Partial<IPresentationRecord> = { ...(json as any) };

  if (json.cover_image) {
    data.cover_image = fileFromJSON(json.cover_image);
  }

  if (json.task) {
    data.task_id = json.task.id;
  }

  if (json.file) {
    data.file = fileFromJSON(json.file);
  }

  if (json.slides && Array.isArray(json.slides)) {
    data.slides = List<SlideRecord>(json.slides.map((s) => slideFromJSON(s)));
  }

  if (json.dynamic_slides && Array.isArray(json.dynamic_slides)) {
    data.dynamic_slides = List<SlideRecord>(json.dynamic_slides.map((s) => slideFromJSON(s)));
  }

  if (json.dynamic_links && Array.isArray(json.dynamic_links)) {
    data.dynamic_links = List<DynamicPresentationLinkRecord>(json.dynamic_links.map((dl) => dplFromJSON(dl)));
  }

  return new PresentationRecord(data);
};

export const toJSON = (record: PresentationRecord): IPresentationData => {
  const presentation: IPresentationData = record.toJSON() as any as IPresentationData;

  if (presentation.id <= 0) {
    delete presentation.id;
  }

  presentation.dynamic_links = record.dynamic_links.map((p) => dplToJSON(p)).toArray();

  presentation.slides = record.slides.map((p) => slideToJSON(p)).toArray();

  return presentation;
};

export const toFormData = (record: PresentationRecord): any => {
  const data = new FormData();
  if (record.id > 0) {
    data.set("presentation[id]", record.id as any);
  }
  data.set("presentation[name]", record.name);
  if (record.data) {
    data.set("presentation[file]", record.data);
  }
  if (record.cover_image) {
    data.set("presentation[cover_image]", record.cover_image as any);
  }
  data.set("presentation[kind]", record.kind);
  if (record.folder_id > 0) {
    data.set("presentation[folder_id]", record.folder_id.toString());
  }

  return data;
};

export type PresentationKind = "default" | "link" | "dynamic";
export type PresentationTarget = "iframe" | "blank";

export interface IPresentationOptions {
  name?: string;
  kind: PresentationKind;
  folderId?: number;
}

export interface IPresentationData {
  id: number;
  cover_image: IFileData;
  file: IFileData;
  kind: PresentationKind;
  target: PresentationTarget;
  link: string;
  name: string;
  org_id: Nullable<number>;
  job_id: Nullable<number>;
  folder_id: Nullable<number>;
  presentation_id: number;
  url: string;
  sort_order: number;
  slides?: ISlideData[];
  dynamic_links?: IDynamicPresentationLinkData[];
  dynamic_slides?: ISlideData[];
  loading?: boolean;
  selected?: boolean;
  data?: Nullable<File>;
  task?: ITaskData;

  created_at: Date;
  updated_at: Date;
}

export interface IPresentationRecord {
  id: number;
  cover_image: FileRecord;
  file: FileRecord;
  kind: PresentationKind;
  target: PresentationTarget;
  link: string;
  name: string;
  org_id: Nullable<number>;
  job_id: Nullable<number>;
  folder_id: Nullable<number>;
  presentation_id: Nullable<number>;
  url: Nullable<string>;
  sort_order: number;
  slides: List<SlideRecord>;
  dynamic_links: List<DynamicPresentationLinkRecord>;
  dynamic_slides: List<SlideRecord>;
  loading: boolean;
  selected: boolean;
  errors: List<string>;
  data?: Nullable<File>;
  task_id?: Nullable<string>;

  created_at: Date;
  updated_at: Date;
}

const defaultPresentationProps: IPresentationRecord = {
  id: 0,
  cover_image: null,
  file: null,
  kind: "default",
  target: "iframe",
  link: "",
  name: "",
  org_id: null,
  job_id: null,
  folder_id: null,
  presentation_id: null,
  url: null,
  sort_order: null,
  slides: List<SlideRecord>(),
  dynamic_links: List<DynamicPresentationLinkRecord>(),
  dynamic_slides: List<SlideRecord>(),
  loading: false,
  selected: false,
  errors: List<string>(),
  data: null,
  task_id: null,
  created_at: new Date(),
  updated_at: new Date(),
};

export class PresentationRecord extends Record(defaultPresentationProps) implements IPresentationRecord {}
