import { IPlanResource, IPlan, IPlanResponse } from "../Models/Plan";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";

export interface IPlanStateParams extends ng.ui.IStateParamsService {
  id: number;
}

export class PlanNewCtrl {
  public plan: IPlan;
  public permission = "create";

  public static $inject = ["Plan", "Flash", "$stateParams", "$state"];
  constructor(
    public PlanResource: IPlanResource,
    public Flash: IFlash,
    public $stateParams: IPlanStateParams,
    public $state: ng.ui.IStateService,
  ) {
    if ($stateParams.id) {
      this.plan = PlanResource.get({ id: $stateParams.id, "include[]": ["parent"] });
      this.plan.$promise.then(() => {
        if (this.plan.in_stripe) {
          Flash.addMessage(
            FlashLevels.danger,
            "This Plan has already been pushed to Stripe and most properties are uneditable",
          );
        }
      });
      this.permission = "update";
    } else {
      this.plan = new PlanResource({
        interval: "month",
        interval_count: 1,
        min_interval: "month",
        min_interval_count: 0,
        billing_method: "flat_per_user",
        family: "estimates",
        kind: "stripe",
      });
    }
  }

  public submit(form: ng.IFormController) {
    if (form.$invalid) {
      return;
    }

    this.plan.$saveOrCreate().then(() => {
      this.$state.go("root.plan_show", this.plan);
    });
  }
}
