import { IUserResource } from "app/src/Models/User";
import { IUserPreferenceResource } from "app/src/Models/UserPreference";
import { IAddressResource } from "app/src/Models/Address";
import { IDocResource } from "app/src/Models/Doc";
import { IMeasurementResource } from "app/src/Models/Measurement";
import { IOrgResource } from "app/src/Models/Org";
import { IJobResource } from "app/src/Models/Job";
import { IProductResource } from "app/src/Models/Product";
import { IEstimateResource } from "app/src/Models/Estimate";
import { IPaymentTermResource } from "app/src/Models/PaymentTerm";
import { IEstimateTemplateResource } from "app/src/Models/EstimateTemplate";
import { IMeasurementLinkResource } from "app/src/Models/MeasurementLink";
import { IImageResource } from "app/src/Models/Image";
import { IPresentationResource } from "app/src/Models/Presentation";
import { INoteResource } from "app/src/Models/Note";
import { IEventResource } from "app/src/Models/Event";
import { IResultResource } from "app/src/Models/Result";
import { IResultReasonResource } from "app/src/Models/ResultReason";
import { IScreenShareResource } from "app/src/Models/ScreenShare";
import { ISignedDocumentRecipientResource } from "app/src/Models/SignedDocumentRecipient";
import { ISignedDocumentResource } from "app/src/Models/SignedDocument";
import { IProductOptionGroupResource } from "app/src/Models/ProductOptionGroup";
import { IProductOptionResource } from "app/src/Models/ProductOption";
import { IPriceListResource } from "app/src/Models/PriceList";
import { IActivatedPriceListResource } from "app/src/Models/ActivatedPriceList";
import { IPlanResource } from "app/src/Billing/Models/Plan";
import { IFeeResource } from "app/src/Billing/Models/Fee";
import { ISubscriptionResource } from "app/src/Billing/Models/Subscription";
import { IAccountResource } from "app/src/Billing/Models/Account";
import { IInvoiceItemResource } from "app/src/Billing/Models/InvoiceItem";
import { IVisualizationResource } from "app/src/Models/Visualization";
import { ICalendarResource } from "app/src/Models/Calendar";
import { IReportResource } from "app/src/Models/Report";
import { ICouponResource } from "../Billing/Models/Coupon";
import { IOrderResource } from "../Billing/Models/Order";
import { ISkuResource } from "../Billing/Models/Sku";
import { IPhoneNumberResource } from "../Models/PhoneNumber";
import { ISfUserResource } from "app/src/Models/SfUser";
import { II360UserResource } from "app/src/Models/I360User";
import { IEmailResource } from "../Models/Email";
import { IElevationResource } from "../Models/Elevation";
import { IChargebeeSubscriptionResource } from "app/src/Billing/Models/ChargebeeSubscription";

export interface IRepository {
  User: IUserResource;
  UserPreference: IUserPreferenceResource;
  Address: IAddressResource;
  Doc: IDocResource;
  Measurement: IMeasurementResource;
  Org: IOrgResource;
  Job: IJobResource;
  Product: IProductResource;
  Estimate: IEstimateResource;
  PaymentTerm: IPaymentTermResource;
  EstimateTemplate: IEstimateTemplateResource;
  MeasurementLink: IMeasurementLinkResource;
  Image: IImageResource;
  Presentation: IPresentationResource;
  Note: INoteResource;
  Event: IEventResource;
  Result: IResultResource;
  ResultReason: IResultReasonResource;
  ScreenShare: IScreenShareResource;
  SignedDocumentRecipient: ISignedDocumentRecipientResource;
  SignedDocument: ISignedDocumentResource;
  ProductOptionGroup: IProductOptionGroupResource;
  ProductOption: IProductOptionResource;
  PriceList: IPriceListResource;
  ActivatedPriceList: IActivatedPriceListResource;
  Plan: IPlanResource;
  Fee: IFeeResource;
  Subscription: ISubscriptionResource;
  ChargebeeSubscription: IChargebeeSubscriptionResource;
  Account: IAccountResource;
  InvoiceItem: IInvoiceItemResource;
  Visualization: IVisualizationResource;
  Order: IOrderResource;
  Calendar: ICalendarResource;
  Report: IReportResource;
  Coupon: ICouponResource;
  Sku: ISkuResource;
  PhoneNumber: IPhoneNumberResource;
  SfUser: ISfUserResource;
  I360User: II360UserResource;
  Email: IEmailResource;
  Elevation: IElevationResource;
}

export interface IRsfResource {
  inject(resources: IRepository);
}

export class Repository {
  public static $inject = [
    "User",
    "UserPreference",
    "Address",
    "Doc",
    "Measurement",
    "Org",
    "Job",
    "Product",
    "Estimate",
    "PaymentTerm",
    "EstimateTemplate",
    "MeasurementLink",
    "Image",
    "Presentation",
    "Note",
    "Event",
    "Result",
    "ResultReason",
    "ScreenShare",
    "SignedDocumentRecipient",
    "SignedDocument",
    "ProductOptionGroup",
    "ProductOption",
    "PriceList",
    "ActivatedPriceList",
    "Plan",
    "Fee",
    "Subscription",
    "Account",
    "InvoiceItem",
    "Order",
    "Visualization",
    "Calendar",
    "Report",
    "Coupon",
    "Sku",
    "PhoneNumber",
    "SfUser",
    "I360User",
    "Email",
    "Elevation",
    "ChargebeeSubscription",
  ];
  constructor(
    public User: IUserResource,
    public UserPreference: IUserPreferenceResource,
    public Address: IAddressResource,
    public Doc: IDocResource,
    public Measurement: IMeasurementResource,
    public Org: IOrgResource,
    public Job: IJobResource,
    public Product: IProductResource,
    public Estimate: IEstimateResource,
    public PaymentTerm: IPaymentTermResource,
    public EstimateTemplate: IEstimateTemplateResource,
    public MeasurementLink: IMeasurementLinkResource,
    public Image: IImageResource,
    public Presentation: IPresentationResource,
    public Note: INoteResource,
    public Event: IEventResource,
    public Result: IResultResource,
    public ResultReason: IResultReasonResource,
    public ScreenShare: IScreenShareResource,
    public SignedDocumentRecipient: ISignedDocumentRecipientResource,
    public SignedDocument: ISignedDocumentResource,
    public ProductOptionGroup: IProductOptionGroupResource,
    public ProductOption: IProductOptionResource,
    public PriceList: IPriceListResource,
    public ActivatedPriceList: IActivatedPriceListResource,
    public Plan: IPlanResource,
    public Fee: IFeeResource,
    public Subscription: ISubscriptionResource,
    public Account: IAccountResource,
    public InvoiceItem: IInvoiceItemResource,
    public Order: IOrderResource,
    public Visualization: IVisualizationResource,
    public Calendar: ICalendarResource,
    public Report: IReportResource,
    public Coupon: ICouponResource,
    public Sku: ISkuResource,
    public PhoneNumber: IPhoneNumberResource,
    public SfUser: ISfUserResource,
    public I360User: II360UserResource,
    public Email: IEmailResource,
    public Elevation: IElevationResource,
    public ChargebeeSubscription: IChargebeeSubscriptionResource,
  ) {
    User.inject(this);
    UserPreference.inject(this);
    Address.inject(this);
    Doc.inject(this);
    Measurement.inject(this);
    Org.inject(this);
    Job.inject(this);
    Product.inject(this);
    Estimate.inject(this);
    PaymentTerm.inject(this);
    EstimateTemplate.inject(this);
    MeasurementLink.inject(this);
    Image.inject(this);
    Presentation.inject(this);
    Note.inject(this);
    Event.inject(this);
    Result.inject(this);
    ResultReason.inject(this);
    ScreenShare.inject(this);
    SignedDocumentRecipient.inject(this);
    SignedDocument.inject(this);
    ProductOptionGroup.inject(this);
    ProductOption.inject(this);
    PriceList.inject(this);
    ActivatedPriceList.inject(this);
    Plan.inject(this);
    Fee.inject(this);
    Subscription.inject(this);
    Account.inject(this);
    InvoiceItem.inject(this);
    Order.inject(this);
    Visualization.inject(this);
    Calendar.inject(this);
    Report.inject(this);
    Coupon.inject(this);
    Sku.inject(this);
    PhoneNumber.inject(this);
    SfUser.inject(this);
    I360User.inject(this);
    Email.inject(this);
    Elevation.inject(this);
    ChargebeeSubscription.inject(this);
  }
}
