import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";

export class RsfConfirmationDialog implements ng.IDirective {
  public restrict = "A";
  public scope = {
    rsfConfirmationMessage: "@",
    rsfConfirmationDialog: "&",
  };

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
    public ConfirmDialog: IConfirmDialog,
  ) {}

  public link = (scope: ng.IScope, element: ng.IAugmentedJQuery) => {
    const app = this;
    element.bind("click", function () {
      app.ConfirmDialog.confirm(scope["rsfConfirmationMessage"]).then(() => {
        scope["rsfConfirmationDialog"]({});
      });
    });
  };

  public static factory(): ng.IDirectiveFactory {
    const directive = ($uibModal: ng.ui.bootstrap.IModalService, ConfirmDialog: IConfirmDialog) =>
      new RsfConfirmationDialog($uibModal, ConfirmDialog);
    directive.$inject = ["$uibModal", "ConfirmDialog"];
    return directive;
  }
}
