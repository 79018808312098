import * as React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { RootActions, RootState } from "app2/src/reducers";
import { ThunkDispatch } from "redux-thunk";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { connect, ConnectedProps } from "app2/src/connect";
import { UserRecord } from "app2/src/records/UserRecord";
import CameraPhoto from "jslib-html5-camera-photo";
import * as FontAwesome from "react-fontawesome";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";

const mapStateToProps = (state: RootState, ownProps: WebCamModalProps) => {
  return {
    displayWebCam: state.getIn(["components", "common", "displayWebCam"], false),
    currentUser: denormalizedReduxUser(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: WebCamModalProps) => {
  return {
    closeWebCam: () => dispatch(commonActions.Actions.closeWebCamModal()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface WebCamModalState {
  size: string;
}

interface WebCamModalProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & WebCamModalProps;

class WebCamModal extends React.Component<Props, WebCamModalState> {
  public videoRef: any;
  public cameraPhoto: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      size: "small",
    };

    this.videoRef = React.createRef();
    this.toggleSize = this.toggleSize.bind(this);
  }

  public componentDidUpdate(prevProps): void {
    const { displayWebCam } = this.props;
    if (!prevProps.displayWebCam && displayWebCam) {
      this.setupCamera();
      this.setState({
        size: "small",
      });
    }
    if (prevProps.displayWebCam && !displayWebCam) {
      this.stopCamera();
    }
  }

  public stopCamera() {
    this.cameraPhoto.stopCamera().catch((error) => {
      console.error("No camera to stop!:", error);
    });
  }

  public setupCamera() {
    this.cameraPhoto = new CameraPhoto(this.videoRef.current);
    this.cameraPhoto.startCamera().catch((err) => {
      console.error(err);
    });
  }

  public toggleSize() {
    this.setState((state) => {
      let size = "small";
      if (state.size === "small") {
        size = "large";
      }
      return {
        size: size,
      };
    });
  }

  public render() {
    const { displayWebCam, closeWebCam, currentUser } = this.props;
    const { size } = this.state;
    const modalClass = "web-cam-modal " + size;

    return (
      <Modal
        show={displayWebCam}
        onHide={closeWebCam}
        className={modalClass}
        backdrop={false}
        autoFocus={false}
        enforceFocus={false}>
        <Modal.Header closeButton>
          <Row>
            <Col>
              <Modal.Title>
                {currentUser ? (
                  <h3>
                    {currentUser.first_name} {currentUser.last_name}
                  </h3>
                ) : (
                  <h3 />
                )}
              </Modal.Title>
            </Col>
            <Col>
              <Button className="btn-expand" onClick={this.toggleSize}>
                <FontAwesome name="expand" />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <video ref={this.videoRef} autoPlay={true} />
          {size === "large" ? (
            <a className="fullscreen" onClick={this.toggleSize}>
              <FontAwesome className="link" name="close" />
              &nbsp;Close
            </a>
          ) : null}
        </Modal.Body>
      </Modal>
    );
  }
}

export default connector(WebCamModal);
