import { ActionsUnion, createAction } from "./Utils";
import { IWindowData } from "../records/Window";
import { List } from "immutable";

export const RECEIVE_WINDOW = "@windows/RECEIVE_WINDOW";
export const EDIT_WINDOW = "@windows/EDIT_WINDOW";
export const RESET_WINDOWS = "@windows/RESET_WINDOWS";
export const UPDATE_FORM = "@windows/UPDATE_FORM";

export const Actions = {
  receiveWindow: (window: Partial<IWindowData>) => createAction(RECEIVE_WINDOW, { window }),
  editWindow: (window: Partial<IWindowData>) => createAction(EDIT_WINDOW, { window }),
  resetWindows: (windowIds: List<number>) => createAction(RESET_WINDOWS, { windowIds }),
  updateForm: (event: { rootPath: any; name: string; value: any }) => createAction(UPDATE_FORM, { event }),
};

export type Actions = ActionsUnion<typeof Actions>;
