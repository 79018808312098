import { IWallFacadeData } from "app2/src/records/WallFacade";

export interface IWallFacade extends IWallFacadeData {
  clone(): IWallFacade;
}

export class WallFacade implements IWallFacade {
  public id: number;
  public name: string;
  public designator: string;
  public ref_id: string;
  public location: string;
  public level: string;
  public orientation: number;
  public pitch: number;
  public area: number;
  public ui: number;
  public created_at: Date;
  public updated_at: Date;

  public _destroy?: boolean;

  constructor() {}

  public clone(): IWallFacade {
    return WallFacade.fromJSON(Object.assign({}, this));
  }

  public static fromJSON = (data: any): IWallFacade => {
    const wall_facade = new WallFacade();
    for (const key in data) {
      if (data.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        switch (key) {
          case "id":
          case "measurement_id":
            data[key] = parseInt(data[key]);
            break;
          case "area":
          case "orientation":
          case "pitch":
            data[key] = parseFloat(data[key]);
            break;
          case "updated_at":
          case "created_at":
            data[key] = new Date(data[key]);
            break;
        }

        wall_facade[key] = data[key];
      }
    }
    return wall_facade;
  };
}
