import * as integrationsViewActions from "./integrationsView.actions";
import { Record } from "immutable";

export const IntegrationsViewStateRecord = Record({
  showQuickMeasureModal: false,
  showRoofScopeModal: false,
  showPlnarModal: false,
});

export const initialState = IntegrationsViewStateRecord();
export type CommonState = typeof initialState;

export const reducer = (state = initialState, action: integrationsViewActions.Actions): CommonState => {
  switch (action.type) {
    case integrationsViewActions.OPEN_QUICKMEASURE_MODAL:
      return state.setIn(["showQuickMeasureModal"], true);
    case integrationsViewActions.CLOSE_QUICKMEASURE_MODAL:
      return state.setIn(["showQuickMeasureModal"], false);
    case integrationsViewActions.OPEN_ROOF_SCOPE_MODAL:
      return state.setIn(["showRoofScopeModal"], true);
    case integrationsViewActions.CLOSE_ROOF_SCOPE_MODAL:
      return state.setIn(["showRoofScopeModal"], false);
    case integrationsViewActions.OPEN_PLNAR_MODAL:
      return state.setIn(["showPlnarModal"], true);
    case integrationsViewActions.CLOSE_PLNAR_MODAL:
      return state.setIn(["showPlnarModal"], false);
    default:
      return state;
  }
};
