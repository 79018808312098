import * as estimatorActions from "./estimator.actions";
import { Record } from "immutable";

export const EstimatorStateRecord = Record({
  showingPaymentOptionEditor: false,
  showingPricingDrawer: false,
});

export const initialState = EstimatorStateRecord();
export type EstimatorState = typeof initialState;

export const reducer = (state = initialState, action: estimatorActions.Actions): EstimatorState => {
  switch (action.type) {
    case estimatorActions.SET_PAYMENT_OPTION_EDITOR_VISIBLE:
      return state.set("showingPaymentOptionEditor", action.payload.visible);
    case estimatorActions.SET_PRICING_DRAWER_VISIBLE:
      return state.set("showingPaymentOptionEditor", action.payload.visible);
    default:
      return state;
  }
};
