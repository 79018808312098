import { validate } from "./Validator";
import { isObject } from "underscore";
import ReactGA from "react-ga4";
import * as config from "react-global-configuration";

export class Dispatch {
  public static analytics: angulartics.IAnalyticsService = null;

  public static dispatch(data: any): boolean {
    if (Dispatch.analytics) {
      /* eslint-disable prefer-const */
      let { action, ...props } = data;
      /* eslint-enable prefer-const */

      if (!action) {
        action = "";
      }
      validate(props);
      Dispatch.analytics.eventTrack(action, props);
      return true;
    }
    return false;
  }
}

export interface TrackingData {
  category: string;
  action: string;
  job: number;
  org: number;
  user: number;
  estimate?: number;
  tool?: number;
}

if (config.get("GA_KEY")) {
  ReactGA.initialize(config.get("GA_KEY"), {
    testMode: process.env.NODE_ENV === "test" ? true : false,
  });
}

const localStorageKey = "rsf-analytics-queue";
const lastExceptionAction = {},
  counters = {},
  exceptionTimeout = 2000;

export const settings: any = {
  userId: null,
  orgId: null,
  appVersion: null,
};

let pagePathCache: string;

export const setUserProperties = (props: { userId: number; orgId: number; appVersion: string }) => {
  settings.userId = props.userId;

  if (props.orgId) {
    settings.orgId = props.orgId;
  }

  if (props.appVersion) {
    settings.appVersion = props.appVersion;
  }
};

export const dispatchException = (error: Error) => {
  console.error(error);
  // @ts-ignore
  newrelic?.noticeError(error);
  const properties: any = {};

  if (window.location.search.indexOf("mobile") >= 0) {
    properties.mobile = true;
  }

  if (window.location.search.indexOf("embedded") >= 0) {
    properties.embedded = true;
  }

  const action = error.toString();
  if (_.keys(lastExceptionAction).includes(action)) {
    if (_.isNullOrUndefined(counters[action])) {
      counters[action] = 0;
    }
    counters[action] += 1;
    if (!_.isNullOrUndefined(lastExceptionAction[action])) {
      clearTimeout(lastExceptionAction[action]);
    }
    lastExceptionAction[action] = setTimeout(() => {
      delete lastExceptionAction[action];
      dispatchToRsf({
        hitType: "event",
        category: "Duplicate Exceptions",
        count: counters[action],
        module: config.get("ENVIRONMENT"),
        action,
        label: error.stack,
        page: getPage_({}),
        isException: true,
        ...properties,
      });
      delete counters[action];
    }, exceptionTimeout);
    return;
  }
  lastExceptionAction[action] = setTimeout(() => {
    delete lastExceptionAction[action];
  }, exceptionTimeout);

  dispatchToRsf({
    hitType: "event",
    category: "Exceptions",
    module: config.get("ENVIRONMENT"),
    action,
    label: error.stack,
    page: getPage_({}),
    isException: true,
    ...properties,
  });
};

const getPage_ = (properties: any) => {
  return properties.page || pagePathCache || window.location.hash.substring(1) || window.location.pathname;
};

export const dispatchToRsf = async (obj: any) => {
  if (settings.userId && isObject(obj)) {
    obj.userId = settings.userId;
  }

  if (settings.orgId && isObject(obj)) {
    obj.orgId = settings.orgId;
  }

  if (settings.appVersion && isObject(obj)) {
    obj.appVersion = settings.appVersion;
  }

  obj.appModule = config.get("ENVIRONMENT");

  const actionUrl = `${config.get("RSF_ANALYTICS_URL")}/api/v1/events`;
  const code = config.get("RSF_ANALYTICS_KEY") || "test123 ";
  const body = { code, event: { data: obj } };

  try {
    const response = await fetch(actionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      // Don't save offline logs in development
      if (code !== "test123") {
        enqueueWithEventTime(obj);
      }
      return;
    }

    const chunk = dequeueAnalyticsChunk();
    if (!chunk.length) {
      return;
    }

    // Attempt to send any events generated without connectivity with
    // the analytics server.
    try {
      const response2 = await fetch(actionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, events: chunk }),
      });

      if (!response2.ok) {
        enqueueAnalyticsData(chunk);
      }
    } catch (e) {
      enqueueAnalyticsData(chunk);
    }
  } catch (e) {
    // Don't save offline logs in development
    if (code !== "test123") {
      enqueueWithEventTime(obj);
    }
  }
};

/**
 * Call enqueueAnalyticsData with new Date()
 */
const enqueueWithEventTime = (obj: any | any[]) => {
  // Set the event time locally to preserve accuracy when posting
  // the events when connectivity has been restored.
  const eventTime = new Date().toISOString();
  enqueueAnalyticsData({ data: obj, inserted_at: eventTime });
};

/**
 * Adds element(s) to the RSF-Analytics queue and updates `localStorage`
 */
const enqueueAnalyticsData = (data: any | any[]) => {
  // Queue the record to be POSTED later
  const queue = getAnalyticsQueue();
  if (Array.isArray(data)) {
    queue.push(...data);
  } else {
    queue.push(data);
  }
  setAnalyticsQueue(queue);
};

/**
 * Dequeue a batch of records that matches `chunkSize` (or 5,000 when
 * omitted). The remaining items are returned to `localStorage`.
 */
const dequeueAnalyticsChunk = (chunkSize?: number) => {
  if (chunkSize === undefined) {
    chunkSize = 5_000;
  }

  const queue = getAnalyticsQueue();
  const chunk = queue.splice(0, chunkSize);
  setAnalyticsQueue(queue);

  return chunk;
};

/**
 * Returns an array representing RSF-Analytics data that exists in
 * `localStorage`
 */
const getAnalyticsQueue = (): any[] => {
  const queueJSON = localStorage.getItem(localStorageKey) || "[]";
  return JSON.parse(queueJSON);
};

/**
 * Overwrites the RSF-Analytics queue in `localStorage` with the value in
 * `queue`
 */
const setAnalyticsQueue = (queue: any[]) => {
  localStorage.setItem(localStorageKey, JSON.stringify(queue));
};
