export class BrowserDetection {
  public static isBrowserMs11() {
    return /rv:11.0/i.test(navigator.userAgent);
  }

  public static isBrowserMs() {
    if (
      /MSIE 10/i.test(navigator.userAgent) ||
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      // This is internet explorer 9, 10, 11 or Edge
      return true;
    }

    return false;
  }

  public static isBrowserMobile() {
    return /Android|iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  }

  public static isBrowserSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  public static isMobileIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  }
}
