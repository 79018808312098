import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { RootDispatchType } from "../store";
import { PriceListProcessRecords, IPriceListProcessData } from "app2/src/records/PriceListProcess";
import { priceListService } from "app2/src/api/priceListProcess.service";
import * as taskActions from "app2/src/reducers/task.actions";
import { IPriceList } from "app/src/Models/PriceList";

export const FETCH_PROCESSES = "@priceListProcesses/FETCH_PROCESSES";
export const RECEIVE_PROCESSES = "@priceListProcesses/RECEIVE_PROCESSES";
export const RECEIVE_PROCESS_ERRORS = "@priceListProcesses/RECEIVE_PROCESS_ERRORS";

export const Actions = {
  fetchProcesses: () => createAction(FETCH_PROCESSES),
  receiveProcesses: (processes: Partial<IPriceListProcessData>) => createAction(RECEIVE_PROCESSES, processes),
  receiveProcessErrors: (errors: string[]) => createAction(RECEIVE_PROCESS_ERRORS, errors),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  getProcesses: (): ThunkResult<Promise<PriceListProcessRecords>> => {
    return (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.fetchProcesses());

      return priceListService.loadProcesses().then(
        (processes) => {
          dispatch(Actions.receiveProcesses(processes));

          return getState().getIn(["priceLists", "processes"]);
        },
        (errors) => {
          dispatch(Actions.receiveProcessErrors(errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  processPriceList: (price_list_id: number, params: any): ThunkResult<Promise<IPriceList>> => {
    return (dispatch: RootDispatchType, getState: () => RootState) => {
      return priceListService.process(price_list_id, params).then(
        (response) => {
          const task = response.task;
          dispatch(taskActions.Actions.receiveTask(task));
          dispatch(taskActions.AsyncActions.pollTask(task));
          return response.product;
        },
        (errors) => {
          return Promise.reject(errors);
        },
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
