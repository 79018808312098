import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { jobStats } from "app2/src/selectors/job.selectors";
import { Can } from "app2/src/components/Common/CanComponent";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

export const Statistics: React.FC = () => {
  const stats = useSelector(jobStats);
  const data = {
    labels: stats?.map((s) =>
      s.name.length > 20 ? `${s.count}: - ${s.name.substring(0, 20)}...` : `${s.count}: ${s.name}`,
    ),
    datasets: [
      {
        data: stats?.map((s) => s.count),
        backgroundColor: stats?.map((s) => s.color || "#fc0303"),
      },
    ],
  };

  return (
    <Can resource="job" permission="statistics">
      <Row className="mb-2 statistics">
        <Col>
          <Card>
            <Card.Body>
              <h3 className="text-center mb-1">Total Customers</h3>
              <Row>
                <Col xs={6}>
                  <Doughnut
                    data={data}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (item) => `${stats[item.dataIndex].name}: ${stats[item.dataIndex].count}`,
                          },
                        },
                        legend: {
                          display: false,
                        },
                      },
                      maintainAspectRatio: false,
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <div className="legend">
                    {stats?.map((s, index) => (
                      <p key={index}>
                        <span style={{ background: s.color }} className="number-circle" title={s.name}>
                          {s.count}
                        </span>
                        &nbsp;&nbsp;{s.name}
                      </p>
                    ))}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Can>
  );
};
