import * as React from "react";
import FullCalendar, { EventApi } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { initDescription, IEventData } from "app2/src/records/Event";
import { useDispatch, useSelector } from "react-redux";
import { currentJob } from "app2/src/selectors/job.selectors";
import { EditAppointmentModal } from "./EditAppointmentModal";
import { Actions, AsyncActions } from "app2/src/reducers/event.actions";
import { Route, Switch, useRouteMatch } from "react-router";
import { push } from "connected-react-router/immutable";
import { Context, DateRange } from "./Context";
import { useTracking } from "react-tracking";
import { currentAccessUid } from "app2/src/reducers/auth.selectors";
import { currentUserId as currentUserIdSelector } from "app2/src/selectors/user.selectors";
import { currentOrgId as currentOrgIdSelector } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";

export interface EventsCalendarProps {
  onDateChange: (dateRange: DateRange) => void;
  disabledViews?: ["timeGridWeek" | "timeGridDay" | "dayGridMonth"];
  eventAddDisabled?: boolean;
}

export const EventsCalendar: React.FC<EventsCalendarProps> = ({ onDateChange, disabledViews, eventAddDisabled }) => {
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { trackEvent } = useTracking<any>({ component: "EventsCalendar" });
  const { setDateRange, events, loading, setEventColor, includes } = React.useContext(Context);

  // Selectors
  const job = useSelector(currentJob);
  const accessUid = useSelector(currentAccessUid);
  const currentUserId = useSelector(currentUserIdSelector);
  const currentOrgId = useSelector(currentOrgIdSelector);
  const calendarEvents = useSelector((state: RootState) => {
    if (events?.size) {
      return events
        .map((event) => ({
          title: event.summary,
          start: event.start_time,
          end: event.end_time,
          id: event.id,
          color: setEventColor(state, { event }),
          editable: event.editable,
          startEditable: event.editable,
          durationEditable: event.editable,
        }))
        .toJS();
    } else return [];
  });

  // Methods
  const handleSelect = (arg) => {
    trackEvent({ action: "add" });
    const newEvent: Partial<IEventData> = {
      id: 0,
      start_time: arg.start,
      end_time: arg.end,
      summary: "Sales Call",
      job_id: job?.id || null,
    };

    if (job) {
      newEvent.description = initDescription(job, accessUid);
      newEvent.summary = "Sales Call: " + job.name;
    } else {
      newEvent.user_id = currentUserId;
      newEvent.org_id = currentOrgId;
    }

    dispatch(Actions.initializeEvent(newEvent));
    dispatch(push(`${match.url}/edit/0`));
  };

  const handleEventChange = (arg) => {
    const eventId = parseInt(arg.event.id);
    trackEvent({ action: "appt changed", event: eventId });
    dispatch(
      AsyncActions.updateTime(
        {
          id: eventId,
          start_time: arg.event.start,
          end_time: arg.event.end,
        },
        includes,
      ),
    );
  };

  const handleEventClick = ({ event }) => {
    if (event.startEditable && event.durationEditable) {
      trackEvent({ action: "Edit Appointment", event: event.id });
      dispatch(push(`${match.url}/edit/${event.id}`));
    }
  };

  const handleDatesSet = async (dateRange: DateRange) => {
    trackEvent({ action: "change date range", startTime: dateRange.start, endTime: dateRange.end });
    onDateChange(dateRange);
    setDateRange(dateRange);
  };

  const availableViews = React.useMemo(() => {
    const views = ["timeGridDay", "timeGridWeek", "dayGridMonth"];
    if (!disabledViews) return views.join(",");

    return views.filter((view: any) => !disabledViews.includes(view)).join(",");
  }, [disabledViews]);

  const handleEvents = (events: EventApi[]) => {
    const lookup = events.reduce((a, e) => {
      a[e.id] = ++a[e.id] || 0;
      return a;
    }, {});
    const duplicatedEvents = events.filter((e) => lookup[e.id]);
    if (duplicatedEvents.length) {
      duplicatedEvents.splice(-1, 1); // keep the last one
      duplicatedEvents.map((evt) => evt.remove()); // remove the others, may be more than one
    }
  };

  return (
    <>
      <FullCalendar
        height={"100%"}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin as any]}
        themeSystem="bootstrap"
        headerToolbar={{
          left: "today prev,next",
          center: "title",
          right: availableViews,
        }}
        businessHours={{
          startTime: "8:00",
        }}
        buttonText={{
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
        }}
        initialView="timeGridWeek"
        events={calendarEvents}
        eventsSet={handleEvents}
        select={handleSelect}
        eventChange={handleEventChange}
        eventClick={handleEventClick}
        datesSet={handleDatesSet}
        allDaySlot={false}
        nowIndicator
        editable
        selectable={!eventAddDisabled}
        selectMirror
      />
      {!loading && (
        <Switch>
          <Route path={`${match.url}/edit/:eventId`} component={EditAppointmentModal} />
        </Switch>
      )}
    </>
  );
};
