export interface ITask {
  id: string;
  job_id: string;
  logs: string;
  status: string;
  lock_version: number;
  user_id: number;
  created_at: Date;
  updated_at: Date;
}

export class Task implements ITask {
  public id: string;
  public job_id: string;
  public logs: string;
  public status: string;
  public lock_version: number;
  public user_id: number;
  public created_at: Date;
  public updated_at: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.job_id = data.job_id;
      this.logs = data.logs;
      this.status = data.status;
      this.lock_version = data.lock_version;
      this.user_id = data.user_id;
      this.created_at = new Date(data.created_at);
      this.updated_at = new Date(data.updated_at);
    }
  }

  public update(data: any) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }

      if (data.job_id) {
        this.job_id = data.job_id;
      }

      if (data.logs) {
        this.logs = data.logs;
      }

      if (data.status) {
        this.status = data.status;
      }

      if (data.lock_version) {
        this.lock_version = data.lock_version;
      }

      if (data.user_id) {
        this.user_id = data.user_id;
      }

      if (data.created_at) {
        this.created_at = new Date(data.created_at);
      }

      if (data.updated_at) {
        this.updated_at = new Date(data.updated_at);
      }
    }
  }

  public watch($timeout, $http, location, callback, timeout = 5000) {
    $timeout(() => {
      $http.get(location).then((resp: any) => {
        this.update(resp.data.task);
        callback(this);
        if (this.status !== "error" && this.status !== "finished") {
          this.watch($timeout, $http, location, callback);
        }
      });
    }, timeout);
  }

  public static watch($timeout, $http, location, callback, timeout = 5000) {
    const task = new Task();
    task.watch($timeout, $http, location, callback, timeout);

    return task;
  }
}
