import * as React from "react";
import { connect } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { Button, Modal, Form, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { PresentationRecord, PresentationTarget } from "app2/src/records/Presentation";
import { RootDispatchType } from "app2/src/store";
import { ConnectedProps } from "react-redux";
import track from "react-tracking";
import { fromJSON } from "app2/src/records/Presentation";
import { presentation } from "app2/src/selectors/presentation.selectors";
import * as presentationActions from "app2/src/reducers/presentation.actions";

const mapStateToProps = (state: RootState, ownProps: PresentationLinkModalProps) => {
  return {
    presentation: presentation(state, { presentationId: ownProps.presentationId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: PresentationLinkModalProps) => {
  const folderId = ownProps.folderParentId;
  return {
    receivePresentation: () =>
      dispatch(
        presentationActions.Actions.receivePresentation(fromJSON({ id: -1, folder_id: folderId, kind: "link" })),
      ),
    editName: (name: string) => dispatch(presentationActions.Actions.editName(ownProps.presentationId, name)),
    editLink: (link: string) => dispatch(presentationActions.Actions.editLink(ownProps.presentationId, link)),
    editTarget: (target: PresentationTarget) =>
      dispatch(presentationActions.Actions.editTarget(ownProps.presentationId, target)),
    updatePresentation: (presentation: PresentationRecord) =>
      dispatch(presentationActions.AsyncActions.updatePresentation(presentation)),
    createPresentation: (presentation: PresentationRecord) =>
      dispatch(presentationActions.AsyncActions.createPresentation(presentation, "org")),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface PresentationLinkModalState {
  show: boolean;
}

export interface PresentationLinkModalProps {
  show: number;
  presentationId: number;
  folderParentId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentationLinkModalProps;

@track(() => {
  return {
    component: "PresentationLinkModal",
  };
})
class PresentationLinkModal extends React.Component<Props, PresentationLinkModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  public componentDidUpdate(prevProps) {
    const { presentationId, receivePresentation } = this.props;
    if (this.props.show !== prevProps.show && !this.state.show) {
      if (presentationId < 0) {
        receivePresentation();
      }
      this.handleShow();
    }
  }

  @track({ action: "close" })
  public handleClose() {
    this.setState({
      show: false,
    });
  }

  public handleInput(e: any): void {
    const { editName, editLink, editTarget } = this.props;
    const value = e.target.value;
    const name = e.target.id;
    switch (name) {
      case "name":
        editName(value);
        break;
      case "link":
        editLink(value);
        break;
      case "target":
        editTarget(value);
        break;
    }
  }

  @track({ action: "show" })
  public handleShow() {
    this.setState({
      show: true,
    });
  }

  @track({ action: "save" })
  public async handleSave(e: React.FormEvent<any>) {
    e.preventDefault();
    e.stopPropagation();

    const { presentation } = this.props;
    if (presentation.id && presentation.id > 0) {
      return this.updatePresentation();
    } else {
      return this.addPresentation();
    }
  }

  @track({ action: "updatePresentation" })
  public async updatePresentation() {
    const { presentation, updatePresentation } = this.props;
    await updatePresentation(presentation);
    this.handleClose();
  }

  @track({ action: "addPresentation" })
  public async addPresentation() {
    const { presentation, createPresentation } = this.props;
    await createPresentation(presentation);
    this.handleClose();
  }

  public render() {
    const { presentation } = this.props;
    const { show } = this.state;

    return (
      <Modal size="lg" show={show} onHide={this.handleClose} backdrop="static">
        <Form onSubmit={this.handleSave}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Presentation Link Editor</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormLabel>Name</FormLabel>
              <FormControl
                type="text"
                data-testid="name-input"
                id="name"
                value={presentation?.name}
                onChange={this.handleInput}
                required></FormControl>
            </FormGroup>
            <FormGroup>
              <FormLabel title="Includes ability to add information from org and job via handlebars ex: {{job.email}}">
                Link - Must start with https:// or ingage://
              </FormLabel>
              <FormControl
                type="text"
                id="link"
                data-testid="link-input"
                value={presentation?.link}
                onChange={this.handleInput}
                pattern="^(?:(http(s)|ingage)?:\/\/).+"
                required></FormControl>
              <br />
              <p>Example Links:</p>
              <p>https://www.google.com</p>
              <p>ingage://applet/ingage-id</p>
            </FormGroup>
            <FormGroup>
              <FormLabel title="Open in an iframe or in a new tab">Link Target</FormLabel>
              <FormControl
                as="select"
                type="text"
                id="target"
                value={presentation?.target}
                onChange={this.handleInput}
                required>
                <option key={0} value="iframe">
                  open link in an iframe
                </option>
                <option key={1} value="blank">
                  open link in a new browser tab
                </option>
              </FormControl>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="cancel" data-testid="cancel-btn" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="save" data-testid="save-btn" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default connector(PresentationLinkModal);
