/*eslint camelcase: 0*/
import { Record } from "immutable";
import { Nullable } from ".";
import {
  EstimateCommissionDetailsRecord,
  IEstimateCommissionDetailsData,
  fromJSON as commissionDetailsFromJSON,
} from "./EstimateCommissionDetails";
import {
  EstimateCommissionOutputRecord,
  IEstimateCommissionOutputData,
  fromJSON as commissionOutputFromJSON,
} from "./EstimateCommissionOutput";
import {
  EstimateCommissionUserRecord,
  IEstimateCommissionUserData,
  fromJSON as commissionUserFromJSON,
} from "./EstimateCommissionUser";

export const fromJSON = (data: Partial<IEstimateCommissionData>): EstimateCommissionRecord => {
  const record: IEstimateCommissionRecord = { ...(data as any) };

  if (data.commission) {
    record.commission = commissionDetailsFromJSON(data.commission);
  }

  if (data.user) {
    record.user = commissionUserFromJSON(data.user);
  }

  if (data.output) {
    record.output = commissionOutputFromJSON(data.output);
  }

  return new EstimateCommissionRecord(record);
};

export interface IEstimateCommissionData {
  estimate_id: number;
  estimate_last_updated: Date;
  apl_id: number;
  apl_current: boolean;
  generated_at: Date;
  generated_by_user_id: number;
  generated_by_user_name: string;
  generated_by_user_email: string;
  commission: IEstimateCommissionDetailsData;
  user: IEstimateCommissionUserData;
  output: IEstimateCommissionOutputData;
  loading?: boolean;
}

export interface IEstimateCommissionRecord {
  estimate_id: number;
  estimate_last_updated: Nullable<Date>;
  apl_id: number;
  apl_current: boolean;
  generated_at: Nullable<Date>;
  generated_by_user_id: number;
  generated_by_user_name: string;
  generated_by_user_email: string;
  commission: EstimateCommissionDetailsRecord;
  user: EstimateCommissionUserRecord;
  output: EstimateCommissionOutputRecord;
  loading?: boolean;
}

const defaultEstimateCommissionProps: IEstimateCommissionRecord = {
  estimate_id: 0,
  estimate_last_updated: null,
  apl_id: 0,
  apl_current: false,
  generated_at: null,
  generated_by_user_id: 0,
  generated_by_user_name: "",
  generated_by_user_email: "",
  commission: new EstimateCommissionDetailsRecord(),
  user: new EstimateCommissionUserRecord(),
  output: new EstimateCommissionOutputRecord(),
  loading: false,
};

export class EstimateCommissionRecord
  extends Record(defaultEstimateCommissionProps)
  implements IEstimateCommissionRecord {}
