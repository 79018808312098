import { ActionsUnion, createAction } from "./Utils";
import { IMeasurementData } from "../records/Measurement";
import { RootState, ThunkResult } from "app2/src/reducers";
import { RootDispatchType } from "app2/src/store";
import { currentMeasurementId, getMeasurementHydrated } from "app2/src/selectors/measurement.selectors";
import { load, update } from "app2/src/api/measurement.service";
import { handleErrors } from "app2/src/reducers/Utils";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { AsyncActions as FencingAsyncActions } from "app2/src/reducers/measurements/fencing.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { IPoolData } from "app2/src/records/Pool";
import { IDeckData } from "app2/src/records/Deck";
import { IOpeningData } from "app2/src/records/Opening";
import { measurementFencingDirtyTree } from "app2/src/selectors/measurements/fencing.selectors";
import { IRoomData } from "app2/src/records/Room";
import { IElevationData } from "app2/src/records/Elevation";
import { IWallFacadeData } from "app2/src/records/WallFacade";

export const FETCH_MEASUREMENT = "@measurements/FETCH_MEASUREMENT";
export const RECEIVE_MEASUREMENT = "@measurements/RECEIVE_MEASUREMENT";
export const SET_MEASUREMENT_LOADED = "@measurements/SET_MEASUREMENT_LOADED";
export const UPDATE_FORM = "@measurements/UPDATE_FORM";
export const UPDATE_ROOF_WASTE = "@measurements/UPDATE_ROOF_WASTE";
export const UPDATE_SLOPED_ROOF_WASTE = "@measurements/UPDATE_SLOPED_ROOF_WASTE";
export const UPDATE_LINE_MEASUREMENTS = "@measurements/UPDATE_LINE_MEASUREMENTS";
export const UPDATE_SIDING_WASTE = "@measurements/UPDATE_SIDING_WASTE";
export const UPDATE_SUNROOM = "@measurements/UPDATE_SUNROOM";
export const UPDATE_SUNROOM_WALL = "@measurements/UPDATE_SUNROOM_WALL";
export const UPDATE_SUNROOM_KNEE_WALL = "@measurements/UPDATE_SUNROOM_KNEE_WALL";
export const UPDATE_RAPID_AREA = "@measurements/UPDATE_RAPID_AREA";
export const UPDATE_SATELLITE = "@measurements/UPDATE_SATELLITE";
export const UPDATE_POOLS = "@measurements/UPDATE_POOLS";
export const UPDATE_DECKS = "@measurements/UPDATE_DECKS";
export const UPDATE_WINDOWS = "@measurements/UPDATE_WINDOWS";
export const UPDATE_DOORS = "@measurements/UPDATE_DOORS";
export const UPDATE_ROOMS = "@measurements/UPDATE_ROOMS";
export const UPDATE_ELEVATIONS = "@measurements/UPDATE_ELEVATIONS";
export const UPDATE_WALL_FACADES = "@measurements/UPDATE_WALL_FACADES";
export const RESET_MEASUREMENT = "@measurements/RESET_MEASUREMENT";
export const SET_EDITING = "@measurements/SET_EDITING";
export const INIT_ADD_MEASUREMENT = "@measurements/INIT_ADD_MEASUREMENT";
export const UPDATE_ADD_MEASUREMENT = "@measurements/UPDATE_ADD_MEASUREMENT";
export const SAVE_ADD_MEASUREMENT = "@measurements/SAVE_ADD_MEASUREMENT";

export const Actions = {
  fetchMeasurement: (measurementId: number) => createAction(FETCH_MEASUREMENT, { measurementId }),
  receiveMeasurement: (measurement: Partial<IMeasurementData>) => createAction(RECEIVE_MEASUREMENT, { measurement }),
  setMeasurementLoaded: (measurementId: number) => createAction(SET_MEASUREMENT_LOADED, { measurementId }),
  updateForm: (event: { rootPath: any; name: string; value: any }) => createAction(UPDATE_FORM, { event }),
  updateRoofWaste: () => createAction(UPDATE_ROOF_WASTE, {}),
  updateSlopedRoofWaste: () => createAction(UPDATE_SLOPED_ROOF_WASTE, {}),
  updateLineMeasurements: () => createAction(UPDATE_LINE_MEASUREMENTS, {}),
  updateSidingWaste: () => createAction(UPDATE_SIDING_WASTE, {}),
  updateSunroom: () => createAction(UPDATE_SUNROOM, {}),
  updateSunroomWall: () => createAction(UPDATE_SUNROOM_WALL, {}),
  updateSunroomKneeWall: () => createAction(UPDATE_SUNROOM_KNEE_WALL, {}),
  updateRapidAreaTool: (rapidArea: any[]) => createAction(UPDATE_RAPID_AREA, { rapidArea }),
  updateSatelliteMeasurementTool: (leaflet: any[], leafletZoom: number) =>
    createAction(UPDATE_SATELLITE, { leaflet, leafletZoom }),
  updatePools: (pools: IPoolData[]) => createAction(UPDATE_POOLS, { pools }),
  updateDecks: (decks: IDeckData[]) => createAction(UPDATE_DECKS, { decks }),
  updateWindows: (windows: IOpeningData[]) => createAction(UPDATE_WINDOWS, { windows }),
  updateDoors: (doors: IOpeningData[]) => createAction(UPDATE_DOORS, { doors }),
  updateRooms: (rooms: IRoomData[]) => createAction(UPDATE_ROOMS, { rooms }),
  updateElevations: (elevations: IElevationData[]) => createAction(UPDATE_ELEVATIONS, { elevations }),
  updateWallFacades: (wallFacades: IWallFacadeData[]) => createAction(UPDATE_WALL_FACADES, { wallFacades }),
  resetMeasurement: () => createAction(RESET_MEASUREMENT, {}),
  setEditing: (value: boolean) => createAction(SET_EDITING, { value }),
  initAddMeasurement: () => createAction(INIT_ADD_MEASUREMENT, {}),
  updateAddMeasurement: (event: { rootPath: any; name: string; value: any }) =>
    createAction(UPDATE_ADD_MEASUREMENT, { event }),
  saveAddMeasurement: () => createAction(SAVE_ADD_MEASUREMENT, {}),
};

export type Actions = ActionsUnion<typeof Actions>;

export const AsyncActions = {
  loadMeasurement: (include: string[] = []): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const measurementId = currentMeasurementId(getState(), {});
      try {
        dispatch(Actions.fetchMeasurement(measurementId));

        const response = await load(measurementId, include);

        dispatch(Actions.receiveMeasurement(response.measurement));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setMeasurementLoaded(measurementId));
      }
    };
  },
  updateMeasurement: (include: string[] = []): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const measurementId = currentMeasurementId(getState(), {});
      const measurement = getMeasurementHydrated(getState(), { measurementId });
      try {
        dispatch(Actions.fetchMeasurement(measurementId));

        const promises = [];
        // measurement.elevations.forEach((elevation) => {
        //   // TODO: only want to call if elevation changed - use selector
        //   // Elevation fromJSON will need to allow this to work
        //   promises.push(dispatch(ImageAsyncActions.updateOrCreateImage(elevation.rendered_image)));
        // });

        if (measurementFencingDirtyTree(getState(), { measurementId })) {
          promises.push(dispatch(FencingAsyncActions.save(measurementId)));
        }

        await Promise.all(promises);

        const response = await update(measurement, include);

        dispatch(Actions.receiveMeasurement(response.measurement));
        dispatch(Actions.setEditing(false));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved measurement successfully"));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setMeasurementLoaded(measurementId));
      }
    };
  },
};
