import * as fencingActions from "./fencing.actions";
import { Map, Record, List } from "immutable";
import { FencingRecord, fromJSON } from "app2/src/records/measurements/Fencing";
import { FenceRecord } from "app2/src/records/measurements/Fence";
import { RootActions, RootState } from "app2/src/reducers";
import * as fenceActions from "./fence.actions";
import { reducer as fenceReducer } from "./fence.reducer";
import { rootKey, fencingFences, fencingFenceIds } from "app2/src/selectors/measurements/fencing.selectors";

export const FencingStateRecord = Record({
  byId: Map<number, FencingRecord>(),
  lastSavedById: Map<number, FencingRecord>(),
  byMeasurementId: Map<number, number>(),
  errorsByMeasurementId: Map<number, List<string>>(),
  unsavedId: -1,
});

export const initialState = FencingStateRecord();

export type FencingState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(rootKey)) {
    state = state.set(rootKey, initialState);
  }

  let fencingRecord: FencingRecord;

  switch (action.type) {
    case fencingActions.UPDATE_TOTALS:
      let fenceLinePost4 = 0,
        fenceLinePost6 = 0,
        fenceLinePost8 = 0,
        fenceGateCount = 0,
        fenceGateLength = 0,
        fenceTotalLength = 0,
        fenceNetLength = 0;

      fencingFences(state, action.payload)
        .filter((f) => !f._destroy)
        .forEach((fence: FenceRecord) => {
          fenceLinePost4 += fence.linePosts4;
          fenceLinePost6 += fence.linePosts6;
          fenceLinePost8 += fence.linePosts8;
          fenceGateCount += fence.gateCount;
          fenceGateLength += fence.gateLength;
          fenceTotalLength += fence.totalLength;
          fenceNetLength += fence.netLength;
        });

      state = state.updateIn([rootKey, "byId", action.payload.fencingId], (fencing: FencingRecord) => {
        return fencing.merge({
          linePosts4: fenceLinePost4,
          linePosts6: fenceLinePost6,
          linePosts8: fenceLinePost8,
          gateCount: fenceGateCount,
          gateLength: fenceGateLength,
          netLength: fenceNetLength,
          totalLength: fenceTotalLength,
        });
      });
      return state;

    case fencingActions.CREATE_FENCING_BY_MEASUREMENT_ID:
      const newId = state.getIn([rootKey, "unsavedId"]);
      const newFencing = fromJSON({
        id: newId,
        measurement_id: action.payload.measurementId,
      });

      return state
        .setIn([rootKey, "byId", newId], newFencing)
        .setIn([rootKey, "lastSavedById", newId], newFencing)
        .setIn([rootKey, "byMeasurementId", action.payload.measurementId], newId)
        .setIn([rootKey, "unsavedId"], newId - 1);

    case fencingActions.FETCH_FENCING_BY_MEASUREMENT_ID:
      return state
        .deleteIn([rootKey, "errorsByMeasurementId", action.payload.measurementId])
        .deleteIn([rootKey, "byMeasurementId", action.payload.measurementId]);

    case fencingActions.RECEIVE_FENCING_ERRORS:
      return state.setIn([rootKey, "errorsByMeasurementId", action.payload.measurementId], List(action.payload.errors));

    case fencingActions.RECEIVE_FENCING:
      const { fences, ...fencing } = action.payload.fencing;
      if (!fencing.id || fencing.id === 0) {
        fencing.id = state.getIn([rootKey, "unsavedId"]);
        state = state.setIn([rootKey, "unsavedId"], fencing.id - 1);
      }

      fencingRecord = fromJSON(fencing);
      state = state
        .setIn([rootKey, "byId", fencingRecord.id], fencingRecord)
        .setIn([rootKey, "byMeasurementId", fencingRecord.measurementId], fencingRecord.id);

      if (Array.isArray(fences)) {
        state = fences.reduce((state, fence) => {
          fence.fencing_id = fencingRecord.id;
          return fenceReducer(state, fenceActions.Actions.receiveFence(fence));
        }, state);
      }

      return state.setIn(
        [rootKey, "lastSavedById", fencingRecord.id],
        state.getIn([rootKey, "byId", fencingRecord.id]),
      );

    case fencingActions.SET_FENCING:
      fencingRecord = action.payload.fencing;

      state = state.setIn([rootKey, "byId", fencingRecord.id], fencingRecord);

      state = fencingRecord.fences.reduce((state, fence) => {
        return fenceReducer(state, fenceActions.Actions.setFence(fence.set("fencingId", fencingRecord.id)));
      }, state);

      return state.setIn([rootKey, "byId", fencingRecord.id, "fences"], List());

    case fencingActions.RESET_FENCING:
      const fenceIds = fencingFenceIds(state, { fencingId: action.payload.fencingId });

      state = state.setIn(
        [rootKey, "byId", action.payload.fencingId],
        state.getIn([rootKey, "lastSavedById", action.payload.fencingId]),
      );
      return fenceIds
        .toSet()
        .union(fencingFenceIds(state, { fencingId: action.payload.fencingId }))
        .reduce((state: RootState, id: number) => fenceReducer(state, fenceActions.Actions.resetFence(id)), state);

    case fencingActions.DELETE_FENCING:
      if (action.payload.cascade) {
        const fenceIds: List<number> = state.getIn([rootKey, "byId", action.payload.fencingId, "fenceIds"]);

        state = fenceIds.reduce((prevState, fenceId) => {
          return fenceReducer(prevState, fenceActions.Actions.deleteFence(fenceId, true));
        }, state);
      }

      return state.deleteIn([rootKey, "byId", action.payload.fencingId]);

    default:
      return state;
  }
};
