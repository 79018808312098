import { ActionsUnion, createAction } from "app2/src/reducers/Utils";
import { ThunkAction } from "redux-thunk";
import { IValidationData } from "app2/src/records/Validation";
import { validationService } from "app2/src/api/validation.service";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { handleErrors } from "app2/src/reducers/Utils";
import { FlashLevels } from "app/src/Common/FlashLevels";

export const RECEIVE_VALIDATION = "@validations/RECEIVE_VALIDATION";
export const FETCH_ORG_VALIDATIONS = "@validations/FETCH_ORG_VALIDATIONS";
export const RECEIVE_ORG_VALIDATIONS = "@validations/RECEIVE_ORG_VALIDATIONS";
export const ORG_VALIDATIONS_LOADED = "@validations/RECEIVE_VALIDATION_ERROR";

export const Actions = {
  receiveValidation: (validation: Partial<IValidationData>) => createAction(RECEIVE_VALIDATION, { validation }),
  fetchValidations: (orgId: number) => createAction(FETCH_ORG_VALIDATIONS, { orgId }),
  receiveValidations: (orgId: number, validations: Partial<IValidationData>[]) =>
    createAction(RECEIVE_ORG_VALIDATIONS, { orgId, validations }),
  validationsLoaded: (orgId: number) => createAction(ORG_VALIDATIONS_LOADED, { orgId }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  listValidations: (orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchValidations(orgId));

      try {
        const response = await validationService.loadByOrg(orgId);
        dispatch(Actions.receiveValidations(orgId, response.validations));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.validationsLoaded(orgId));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
