import * as React from "react";

export interface GridContainerProps {
  columnWidth?: number;
}

export const GridContainer: React.FC<GridContainerProps> = ({ children, columnWidth = 240 }) => {
  return (
    <div
      className="grid-container"
      data-testid="gridContainer"
      style={{ gridTemplateColumns: `repeat(auto-fill, minmax(${columnWidth}px, 1fr))` }}>
      {children}
    </div>
  );
};
