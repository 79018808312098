import * as React from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import SaveablePdfDisplay from "app2/src/components/Pdf/SaveablePdfDisplay";
import { Context } from "app2/src/components/SignatureRequest/Context";
import { document as combinedDoc } from "app2/src/selectors/document.selectors";
import { currentSignedDocument as currentSignedDocSelector } from "app2/src/selectors/signedDocument.selectors";
import { RootState } from "app2/src/reducers";
import { sleep } from "app2/src/helpers/Time";

export const ReviewSignatureRequest: React.FunctionComponent = () => {
  const { dispatch: contextDispatch, job } = React.useContext(Context);

  const currentSignedDocument = useSelector(currentSignedDocSelector);
  const document = useSelector((s: RootState) =>
    combinedDoc(s, { documentId: currentSignedDocument?.document_id || -1 }),
  );

  if (!document || !document?.file?.url) return null;

  return (
    <div>
      <Modal.Header closeButton>
        <h3>Review Signature Request</h3>
      </Modal.Header>

      <Modal.Body className="overflow-hidden document-pdf">
        <p>Please review the document below before sending for signature.</p>
        <div className="d-flex align-items-center justify-content-center">
          <SaveablePdfDisplay
            pdfUrl={document.file.url}
            job={job}
            documentName={document.file.filename}
            modifyDoc={(isModified, getFile) =>
              contextDispatch("modifyDoc", { isModified: isModified, getModifiedFile: getFile })
            }
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="button-footer d-flex justify-content-between w-100 flex-md-row-reverse">
          <div className="right-footer-buttons">
            <Button variant="cancel" onClick={() => contextDispatch("close", { modalType: "view", destroy: true })}>
              Back
            </Button>
            <Button
              variant="save"
              type="submit"
              onClick={() => {
                contextDispatch("validate");
              }}>
              Next
            </Button>
          </div>
          <div className="left-footer-buttons">
            <Button variant="cancel" onClick={() => contextDispatch("close", { modalType: "", destroy: true })}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </div>
  );
};
