import { IOrg } from "app/src/Models/Org";
import { IJob } from "app/src/Models/Job";
import { ISignedDocument } from "app/src/Models/SignedDocument";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IDoc extends ng.resource.IResource<IDoc>, DocPrototype {
  $update(): ng.IPromise<IDoc>;
  $update(params?: Object, success?: Function, error?: Function): ng.IPromise<IDoc>;
  $update(success: Function, error?: Function): ng.IPromise<IDoc>;

  $import(): ng.IPromise<IDoc>;
  $import(params?: Object, success?: Function, error?: Function): ng.IPromise<IDoc>;
  $import(success: Function, error?: Function): ng.IPromise<IDoc>;
}

export interface IDocResource extends ng.resource.IResourceClass<IDoc>, IRsfResource {
  fromJSON?(data: any): IDoc;
  tooltipHtml?(): string;
}

export interface IDocFile {
  url: string;
}

class DocPrototype {
  public id: number;
  public file: IDocFile;
  public name: string;
  public documentable_id: number;
  public documentable_type: string;
  public sort_order: number;
  public md5_sum: string;
  public display: string;
  public import_type: string;
  public type: string;
  public signed_document?: ISignedDocument;
  public org?: IOrg;
  public job?: IJob;
  public created_at: Date;
  public updated_at: Date;
  public displayInProposal: boolean;
  public displayInAgreement: boolean;
  public displayInProposalWatermark: boolean;
  public displayInAgreementWatermark: boolean;
  public displayInResource: boolean;
  public location?: string;
  public _destroy: boolean;

  public clone() {
    //noinspection TypeScriptUnresolvedFunction
    return resources.Doc.fromJSON(JSON.retrocycle(this.decycleToJSON()));
  }

  public decycleToJSON(): any {
    return angular.fromJson(angular.toJson(JSON.decycle(this)));
  }

  public cloneMin(): any {
    const clone_doc: IDoc = <IDoc>{};
    clone_doc.id = this.id;
    clone_doc.displayInProposal = this.displayInProposal;
    clone_doc.displayInAgreement = this.displayInAgreement;

    return clone_doc;
  }

  public merge(doc: { displayInAgreement: boolean; displayInProposal: boolean }): IDoc {
    this.displayInAgreement = doc.displayInAgreement;
    this.displayInProposal = doc.displayInProposal;

    return this as any as IDoc;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IDocResource => {
  const url = BaseConfig.BASE_URL + "/api/v1/:documentable_type_url/:documentable_id/documents/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IDoc => {
    if (status < 200 || status > 299) {
      return new Doc({});
    }

    return Doc.fromJSON(JSON.parse(response).document);
  };

  const transformRequest = (doc: IDoc): string => {
    const data = JSON.decycle(doc);
    if (data.displayInProposal === true && data.displayInAgreement === true) {
      data.display = "both";
    } else if (data.displayInProposal === false && data.displayInAgreement === true) {
      data.display = "contract";
    } else if (data.displayInProposal === true && data.displayInAgreement === false) {
      data.display = "estimate";
    } else if (data.displayInProposalWatermark === true) {
      data.display = "estimate_watermark";
    } else if (data.displayInAgreementWatermark === true) {
      data.display = "contract_watermark";
    } else if (data.displayInResource === true) {
      data.display = "resource";
    } else {
      data.display = "do_not_display";
    }
    delete data.displayInProposal;
    delete data.displayInAgreement;
    delete data.displayInProposalWatermark;
    delete data.displayInAgreementWatermark;
    delete data.displayInResource;
    return angular.toJson({ document: data });
  };

  const responseMultiTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);
    _.each(data.documents, (user, index) => {
      data.documents[index] = Doc.fromJSON(user);
    });

    return data;
  };

  const Doc: IDocResource = <IDocResource>$resource(
    url,
    { documentable_type_url: "@documentable_type_url", documentable_id: "@documentable_id", id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        transformResponse: responseMultiTransform,
        isArray: false,
      },
      import: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformResponse: singleResponseTransform,
        url: url + "/import",
        params: {
          type: "@import_type",
        },
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
    },
  );

  Doc.fromJSON = (data: any): IDoc => {
    if (data.documentable_type) {
      data.documentable_type_url = _.toUnderscore(data.documentable_type) + "s";
    }

    data.type = "document";
    data.displayInProposal = data.display === "both" || data.display === "estimate";
    data.displayInAgreement = data.display === "both" || data.display === "contract";
    data.displayInProposalWatermark = data.display === "estimate_watermark";
    data.displayInAgreementWatermark = data.display === "contract_watermark";
    data.displayInResource = data.display === "resource";
    if (data.created_at) {
      data.created_at = new Date(data.created_at as string);
    }
    if (data.updated_at) {
      data.updated_at = new Date(data.updated_at as string);
    }
    return new Doc(data);
  };

  Doc.inject = (injected: IRepository) => {
    resources = injected;
  };

  _.hiddenExtend(Doc.prototype, DocPrototype.prototype);

  return Doc;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
