import * as routerActions from "./router.actions";
import { Record } from "immutable";
import { fromJSON as locationFromJSON } from "app2/src/records/Location";
import { RootActions, RootState } from ".";

export const RouterStateRecord = Record({
  location: locationFromJSON({}),
  action: "",
});

export const initialState = RouterStateRecord();

export type RouterState = typeof initialState;

const model = "routerPush";

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case routerActions.LOCATION_CHANGE:
      return state
        .setIn([model, "location"], locationFromJSON(action.payload.location))
        .setIn([model, "action"], action.payload.action);

    default:
      return state;
  }
};
