import { RootState } from "./reducers";
import { RootDispatchType, RootStoreType } from "./store";

class StoreRegistryClass {
  protected _store: RootStoreType;
  protected _kvStore: any = {};

  public constructor() {}

  public setStore(store) {
    this._store = store;
  }

  public getStore() {
    return this._store;
  }

  public set(key: string, value: any) {
    this._kvStore[key] = value;
  }

  public get<T>(key: string): T {
    return this._kvStore[key] as T;
  }
}

export const StoreRegistry = new StoreRegistryClass();

export const useState = (): RootState => {
  return StoreRegistry.getStore().getState();
};

export const useSelector = <T>(fun: (state: RootState) => T): T => {
  return fun(useState());
};

export const dispatch: RootDispatchType = (action) => {
  return StoreRegistry.getStore().dispatch(action);
};
