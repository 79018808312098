import { baseCleanProps } from "app2/src/api/Api";
import { fetcher } from "app2/src/helpers/Fetcher";
import {
  IJobObjectData,
  ILevelData,
  ILocationData,
  JobAttributeLinkRecordType,
  JobAttributeRecordType,
} from "app2/src/records/JobAttribute";
import { orgJobAttributeLinkKey, JobAttributeTypes } from "app2/src/records/OrgRecord";
import { JobAttribute } from "../records/JobAttribute";

export interface IJobTypeIndexResponse {
  job_types: IJobObjectData[];
}
export interface ILevelIndexResponse {
  levels: ILevelData[];
}
export interface ILocationIndexResponse {
  locations: ILocationData[];
}
export interface IJobTypeResponse {
  job_type: IJobObjectData;
}
export interface ILevelResponse {
  level: ILevelData;
}
export interface ILocationResponse {
  location: ILocationData;
}

export const orgUrl = "api/v1/orgs/:org_id/";

export const load = async <T>(orgId: number, jobAttrType: JobAttributeTypes): Promise<T> => {
  const actionUrl = fetcher.joinUrls(orgUrl.replace(":org_id", orgId.toString()), `${jobAttrType}s`);
  return fetcher.get<T>(actionUrl, {});
};

export const create = async <T>(
  orgId: number,
  jobAttributeRecord: JobAttributeRecordType,
  jobAttrType: JobAttributeTypes,
): Promise<T> => {
  const actionUrl = fetcher.joinUrls(orgUrl.replace(":org_id", orgId.toString()), `${jobAttrType}s`);
  const object = cleanProps(jobAttributeRecord);
  const data = {};
  data[jobAttrType] = object;
  return fetcher.post<T>(actionUrl, { ...data });
};

export const update = async <T>(
  orgId: number,
  jobAttributeRecord: JobAttributeRecordType,
  jobAttributeLinkRecord: JobAttributeLinkRecordType,
  jobAttrType: JobAttributeTypes,
): Promise<T> => {
  const actionUrl = fetcher.joinUrls(
    orgUrl.replace(":org_id", orgId.toString()),
    `${jobAttrType}s/${jobAttributeRecord.id.toString()}`,
  );
  const object = cleanProps(jobAttributeRecord);

  if (jobAttributeLinkRecord) {
    const link = cleanProps(jobAttributeLinkRecord);
    object[`${orgJobAttributeLinkKey(jobAttrType)}s_attributes`] = [link];
  }

  const data = {};
  data[jobAttrType] = object;
  return fetcher.patch<T>(actionUrl, { ...data });
};

export const removeJobAttribute = async <T>(
  orgId: number,
  jobAttributeId: number | string,
  jobAttrType: JobAttributeTypes,
): Promise<T> => {
  const actionUrl = fetcher.joinUrls(
    orgUrl.replace(":org_id", orgId.toString()),
    `${jobAttrType}s/${jobAttributeId}`,
    "remove",
  );
  return fetcher.delete(actionUrl);
};

export const addJobAttribute = async <T>(
  orgId: number,
  jobAttributeId: number | string,
  jobAttrType: JobAttributeTypes,
): Promise<T> => {
  const actionUrl = fetcher.joinUrls(
    orgUrl.replace(":org_id", orgId.toString()),
    `${jobAttrType}s/${jobAttributeId}`,
    "add",
  );
  return fetcher.post(actionUrl);
};

export const destroy = async <T>(
  orgId: number,
  jobAttrId: number | string,
  jobAttrType: JobAttributeTypes,
): Promise<T> => {
  const actionUrl = fetcher.joinUrls(
    orgUrl.replace(":org_id", orgId.toString()),
    `${jobAttrType}s/${jobAttrId.toString()}`,
  );

  return fetcher.delete<T>(actionUrl);
};

export const cleanProps = (jobAttributeRecord: JobAttributeRecordType | JobAttributeLinkRecordType): JobAttribute => {
  let jobAttribute = jobAttributeRecord.toJS() as any;
  jobAttribute = baseCleanProps(jobAttribute);

  return jobAttribute;
};
