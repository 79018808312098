import * as React from "react";
import { RootState } from "app2/src/reducers";
import { IEstimate } from "app/src/Models/Estimate";
import { preferencesConfig } from "app2/src/selectors/org.selectors";
import { connect, ConnectedProps } from "app2/src/connect";
import { Form, Col, Row, Button, Card } from "react-bootstrap";
import { DecimalInputComponent } from "app2/src/components/Common/DecimalInputComponent";
import * as FontAwesome from "react-fontawesome";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Acl } from "app2/src/helpers/Acl";
import InputNumber from "rc-input-number";

const mapStateToProps = (state: RootState, ownProps: MarkupEditorProps) => {
  const config = preferencesConfig(state, ownProps);
  if (!config || !config.get("markup")) {
    return {
      product_min: 0,
      product_max: 0,
      labor_min: 0,
      labor_max: 0,
      enabled: false,
    };
  }
  const { product_min, product_max, labor_min, labor_max, enabled } = config.get("markup").toJS();
  return {
    product_min: product_min || 0,
    product_max: product_max || 0,
    labor_min: labor_min || 0,
    labor_max: labor_max || 0,
    enabled,
  };
};

const connector = connect(mapStateToProps, null);

interface MarkupEditorProps {
  orgId: number;
  estimate: IEstimate;
  lineItemEditorOpen: boolean;
  updated: (markups: any) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & MarkupEditorProps;

export interface MarkupEditorState {
  show: boolean;
  product_markup: number;
  labor_markup: number;
}

export class MarkupEditor extends React.Component<Props, MarkupEditorState> {
  protected debouncedUpdate: (markups: any) => void;

  constructor(props: Props) {
    super(props);
    const { product_markup, labor_markup } = props.estimate;

    this.state = {
      show: false,
      product_markup,
      labor_markup,
    };

    this.debouncedUpdate = _.debounce(props.updated, 1000);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const { product_markup, labor_markup } = prevProps.estimate;
    const { estimate } = this.props;

    const prev = { product_markup, labor_markup };
    const current = { product_markup: estimate.product_markup, labor_markup: estimate.labor_markup };

    if (JSON.stringify(prev) === JSON.stringify(current)) {
      return;
    }

    this.setState(current);
  }

  public handleNumberChange(value: number, name: string) {
    const { product_min, product_max, labor_min, labor_max } = this.props;
    const markups = this.state;
    markups[name] = value;
    const product_markup = _.min([_.max([markups.product_markup, product_min]), product_max]);
    const labor_markup = _.min([_.max([markups.labor_markup, labor_min]), labor_max]);

    this.debouncedUpdate({ product_markup, labor_markup });
    this.setState({
      product_markup,
      labor_markup,
    });
  }

  public toggleShow() {
    this.setState((state) => ({
      show: !state.show,
    }));
  }

  public displayEditor() {
    const { product_markup, labor_markup } = this.state;
    const { product_min, product_max, labor_min, labor_max, lineItemEditorOpen } = this.props;
    return (
      <Card>
        <Card.Body>
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Product Markup</Form.Label>
                <InputNumber
                  data-testid="productMarkupInput"
                  type="number"
                  min={product_min}
                  step="1"
                  id="product_markup"
                  max={product_max}
                  required
                  name="product_markup"
                  value={product_markup}
                  disabled={lineItemEditorOpen}
                  onChange={(value: number) => this.handleNumberChange(value, "product_markup")}
                />
              </Form.Group>
            </Col>
            <Col md={9}>
              <Form.Group>
                <Form.Label className="width-100">
                  {product_min} <div className="pull-right">{product_max}</div>
                </Form.Label>
                <DecimalInputComponent
                  title={product_markup?.toString()}
                  data-testid="productMarkupRange"
                  type="range"
                  min={product_min}
                  step="1"
                  id="product_markup"
                  max={product_max}
                  required
                  name="product_markup"
                  value={product_markup}
                  disabled={lineItemEditorOpen}
                  onNumberChange={this.handleNumberChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Labor Markup</Form.Label>
                <InputNumber
                  data-testid="laborMarkupInput"
                  type="number"
                  min={labor_min}
                  step="1"
                  id="labor_markup"
                  max={labor_max}
                  required
                  name="labor_markup"
                  value={labor_markup}
                  disabled={lineItemEditorOpen}
                  onChange={(value: number) => this.handleNumberChange(value, "labor_markup")}
                />
              </Form.Group>
            </Col>
            <Col md={9}>
              <Form.Group>
                <Form.Label className="width-100">
                  {labor_min} <div className="pull-right">{labor_max}</div>
                </Form.Label>
                <DecimalInputComponent
                  title={labor_markup?.toString()}
                  data-testid="laborMarkupRange"
                  type="range"
                  min={labor_min}
                  step="1"
                  id="labor_markup"
                  max={labor_max}
                  required
                  name="labor_markup"
                  value={labor_markup}
                  disabled={lineItemEditorOpen}
                  onNumberChange={this.handleNumberChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  public render() {
    const { orgId, enabled } = this.props;
    const { show } = this.state;

    if (!enabled || !Acl.can("update_markup", "estimate")) {
      return null;
    }

    if (!orgId) {
      return (
        <div data-testid="inline-spinner">
          <SpinnerComponent inline localProperty={true} />
        </div>
      );
    }

    return (
      <>
        <Row>
          <Col>
            <Button id="estimate-mark-up-expand" variant="link" onClick={this.toggleShow} data-testid="markup-button">
              MARKUPS&nbsp;
              {show ? (
                <FontAwesome name="minus" data-testid="minus-icon" />
              ) : (
                <FontAwesome name="plus" data-testid="plus-icon" />
              )}
            </Button>
          </Col>
        </Row>
        {show && this.displayEditor()}
      </>
    );
  }
}

export default connector(MarkupEditor);
