import { fetcher } from "../helpers/Fetcher";
import { List } from "immutable";
import {
  IPresentationTemplateData,
  PresentationTemplateRecord,
  toJSON,
  fromJSON,
} from "../records/PresentationTemplate";
import { baseCleanProps } from "app2/src/api/Api";

interface IPresentationTemplateIndexResponse {
  presentation_templates: IPresentationTemplateData[];
}

interface IPresentationTemplateResponse {
  presentation_template: IPresentationTemplateData;
}

class PresentationTemplateService {
  public orgUrl = "api/v1/orgs/:id/presentation_templates";

  public loadAll(orgId: number): Promise<List<PresentationTemplateRecord>> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    return fetcher
      .get<IPresentationTemplateIndexResponse>(actionUrl)
      .then((response) => List(response.presentation_templates.map((p) => fromJSON(p))));
  }

  public create(
    orgId: number,
    presentationTemplate: PresentationTemplateRecord,
    kind: "org" | "job" = "org",
  ): Promise<PresentationTemplateRecord> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    const data: Partial<IPresentationTemplateData> = this.cleanProps(toJSON(presentationTemplate));

    return fetcher.post<IPresentationTemplateResponse>(actionUrl, { presentation_template: data }).then(
      (response) => fromJSON(response.presentation_template),
      (response) => Promise.reject(response.data.errors),
    );
  }

  public update(presentationTemplate: PresentationTemplateRecord): Promise<PresentationTemplateRecord> {
    const actionUrl = fetcher.joinUrls(
      this.orgUrl.replace(":id", presentationTemplate.org_id.toString()),
      presentationTemplate.id.toString(),
    );

    const data: Partial<IPresentationTemplateData> = this.cleanProps(toJSON(presentationTemplate));

    return fetcher.patch<IPresentationTemplateResponse>(actionUrl, { presentation_template: data }).then(
      (response) => fromJSON(response.presentation_template),
      (response) => {
        if (response.status === 500) {
          return Promise.reject(["Internal Server Error"]);
        }

        if (_.isArray(response.data.errors)) {
          return Promise.reject(response.data.errors);
        }

        return Promise.reject(["Unknown Error"]);
      },
    );
  }

  public delete(presentationTemplate: PresentationTemplateRecord): Promise<boolean> {
    const actionUrl = fetcher.joinUrls(
      this.orgUrl.replace(":id", presentationTemplate.org_id.toString()),
      presentationTemplate.id.toString(),
    );

    return fetcher.delete(actionUrl).then(
      (_response) => true,
      (response) => {
        if (response.status === 500) {
          return Promise.reject(["Internal Server Error"]);
        }

        if (_.isArray(response.data.errors)) {
          return Promise.reject(response.data.errors);
        }

        return Promise.reject(["Unknown Error"]);
      },
    );
  }

  public cleanProps(presentationTemplate: Partial<IPresentationTemplateData>): Partial<IPresentationTemplateData> {
    presentationTemplate = baseCleanProps(JSON.parse(JSON.stringify(presentationTemplate)));

    return presentationTemplate;
  }
}

export const presentationTemplateService = new PresentationTemplateService();
