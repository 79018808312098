import { RoomRecord } from "app2/src/records/Room";
import { List } from "immutable";
import * as React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { IndividualRoom } from "./IndividualRoom";
import { imperial_uom } from "app/src/Common/Constants";
import { IPretty } from "app/src/Common/PrettyNameService";
import * as FontAwesome from "react-fontawesome";
import { roomKeys } from ".";
import { useTracking } from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";
import { RoomMeasurementInfo } from "app2/src/components/Common/RoomMeasurementInfo";
import { StoreRegistry } from "app2/src/storeRegistry";

export interface GroupedRoomsProps {
  rooms: List<RoomRecord>;
}

export const GroupedRooms: React.FC<GroupedRoomsProps> = ({ rooms }) => {
  // Hooks
  const { trackEvent } = useTracking<any>({ component: "GroupedRooms" }, { dispatch: Dispatch.dispatch });

  const roomValues = React.useMemo(() => {
    if (rooms?.size < 1) return List([]);

    const pretty: IPretty = StoreRegistry.get("Pretty");
    return roomKeys.map((key) => ({
      label: pretty.name[key],
      value: rooms.reduce((prev, current) => prev + current[key], 0).toFixed(2),
      uom: imperial_uom[`interior_${key}`],
    }));
  }, [rooms]);

  const [isOpen, setIsOpen] = React.useState(false);

  // Methods
  const toggleIsOpen = () => {
    trackEvent({ action: `${isOpen ? "close" : "open"} accordion` });
    setIsOpen(!isOpen);
  };

  return (
    <Accordion>
      <Card className="room-summary">
        <Card.Body>
          <div className="d-flex justify-content-between">
            <Card.Title>Rooms Summary</Card.Title>
            <div className="d-flex align-items-center">
              <RoomMeasurementInfo />
              <Accordion.Toggle as="button" eventKey="0" className="btn" onClick={toggleIsOpen}>
                <FontAwesome name={`angle-${isOpen ? "up" : "down"}`} size="lg" />
              </Accordion.Toggle>
            </div>
          </div>
          <Row>
            {roomValues.map((obj, idx) => (
              <Col key={"room-" + idx}>
                <div>
                  <span className="label">{obj.label}: </span> {obj.value} {obj.uom}
                </div>
              </Col>
            ))}
          </Row>

          <Accordion.Collapse eventKey="0" className="my-3 mx-2">
            <div>
              {rooms.map((r) => (
                <IndividualRoom room={r} grouped key={r.id} />
              ))}
            </div>
          </Accordion.Collapse>
        </Card.Body>
      </Card>
    </Accordion>
  );
};
