import * as accessActions from "app2/src/reducers/access.actions";
import { accessFromJSON } from "app2/src/records/UserRecord";
import { fromJS, List, Map, Record, Set } from "immutable";
import { AccessRecord } from "../records/UserRecord";
import { RootState, RootActions } from "app2/src/reducers";
import { reducer as orgReducer } from "app2/src/reducers/org.reducer";
import { Actions as OrgActions } from "app2/src/reducers/org.actions";
import { reset } from "./Reducer";

export const AccessStateRecord = Record({
  byId: Map<number, AccessRecord>(),
  lastSavedById: Map<number, AccessRecord>(),
});

export const initialState = AccessStateRecord();

export type AccessState = typeof initialState;

export const model = "accesses";

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }
  switch (action.type) {
    case accessActions.SET_ACCESS: {
      const { access, id } = action.payload;
      const immutableAccess = accessFromJSON(access);

      return state.setIn([model, "byId", id], immutableAccess).setIn([model, "lastSavedById", id], immutableAccess);
    }
    case accessActions.RESET_ACCESS: {
      const { id } = action.payload;
      return reset(state, model, id);
    }
    case accessActions.SET_ACCESS_ACL: {
      const { acl, id } = action.payload;
      return state.updateIn([model, "byId", id], accessFromJSON({ id } as any), (ar: AccessRecord) =>
        ar.set("acl", fromJS(acl)),
      );
    }
    case accessActions.RECEIVE_ACCESSES:
      action.payload.accesses.forEach((access) => {
        state = reducer(state, accessActions.Actions.setAccess(access.id, access));
        if (access.org) {
          state = orgReducer(state, OrgActions.setOrg(access.org));
        }
      });
      return state;

    case accessActions.UPDATE_FORM_ACL: {
      const { id, resource, permission, value } = action.payload;

      if (value) {
        return state.updateIn([model, "byId", id, "acl", resource], List(), (permissions: List<string>) => {
          if (permissions.indexOf(permission) >= 0) {
            return permissions;
          }
          return permissions.push(permission);
        });
      } else {
        return state.updateIn([model, "byId", id, "acl", resource], List(), (permissions: List<string>) => {
          if (permissions.indexOf(permission) >= 0) {
            return permissions.filter((p) => p !== permission);
          }
          return permissions;
        });
      }
    }
    case accessActions.UPDATE_FORM_RECORD: {
      const { id, path, value } = action.payload;
      const parts = path.split(".");
      return state.setIn([model, "byId", id, ...parts], value);
    }
    default:
      return state;
  }
};
