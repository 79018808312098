import { RootState } from "app2/src/reducers";
import { pageControlData } from "app2/src/selectors/pagination.selectors";
import * as React from "react";
import { Col, Pagination, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";

export interface PaginationControlProps {
  pageChanged: (page: number) => void;
  modelName: string;
}

export const PaginationControl: React.FC<PaginationControlProps> = ({ pageChanged, modelName }) => {
  const { trackEvent } = useTracking();
  const metadata = useSelector((state: RootState) => pageControlData(state, { modelName }));

  const handlePageChange = (page: number) => {
    trackEvent({ action: "pagination page changed", page });
    pageChanged(page);
  };

  if (!metadata || metadata?.total_pages === 1) {
    return (
      <Row>
        <Col md={12}>
          <Pagination className="justify-content-center">
            <Pagination.Ellipsis />
          </Pagination>
        </Col>
      </Row>
    );
  }
  const { total_pages, current_page } = metadata;

  let preEllipsis = null,
    preArrow = null;
  let postEllipsis = null,
    postArrow = null;
  const pre = [],
    post = [];

  if (current_page > 1) {
    const preNumber = Math.max(1, current_page - 2);

    for (let i = preNumber; i < current_page; i++) {
      pre.push(
        <Pagination.Item key={i} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }

    if (preNumber > 1) {
      preEllipsis = <Pagination.Ellipsis />;
    }

    preArrow = <Pagination.Prev onClick={() => handlePageChange(current_page - 1)} />;
  }

  if (current_page < total_pages) {
    const available = 4 - pre.length;
    const postNumber = Math.min(total_pages, current_page + available);

    for (let i = current_page + 1; i <= postNumber; i++) {
      post.push(
        <Pagination.Item key={i} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }

    if (postNumber < total_pages) {
      postEllipsis = <Pagination.Ellipsis />;
    }

    postArrow = <Pagination.Next onClick={() => handlePageChange(current_page + 1)} />;
  }

  return (
    <Row>
      <Col md={12}>
        <Pagination className="justify-content-center">
          <Pagination.First disabled={current_page === 1} onClick={() => handlePageChange(1)} />
          {preArrow}
          {preEllipsis}
          {pre}
          <Pagination.Item active onClick={() => handlePageChange(current_page)}>
            {current_page}
          </Pagination.Item>
          {post}
          {postEllipsis}
          {postArrow}
          <Pagination.Last disabled={current_page === total_pages} onClick={() => handlePageChange(total_pages)} />
        </Pagination>
      </Col>
    </Row>
  );
};
