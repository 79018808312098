import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { List } from "immutable";
import { ITaskData } from "app2/src/records/Task";

const mapStateToProps = (state: RootState, ownProps: ListProps) => {
  const tasksById = state.getIn(["tasks", "byId"]);
  let tasks = List();

  tasksById.map((task) => {
    tasks = tasks.push(task);
  });

  return {
    tasks: tasks,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ListProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ListProps {}

export interface ListState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ListProps;

class ListComponent extends React.Component<Props, ListState> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <Row>
        <Col md={12}>
          <Row>
            <Col md={6}>
              <h1>Tasks</h1>
            </Col>
            <Col md={6}></Col>
          </Row>
          {this.renderHeader()}
          {this.renderBody()}
        </Col>
      </Row>
    );
  }

  private renderHeader() {
    return (
      <Row>
        <Col lg={{ span: 10, offset: 1 }} md={{ span: 12, offset: 0 }}>
          <div className="match-form-section">
            <Row className="match-table-header">
              <Col md={6}>Name</Col>
              <Col md={6}>Progress</Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  private renderBody() {
    const { tasks } = this.props;

    return (
      <React.Fragment>
        {tasks.map((task, idx) => {
          return (
            <Row key={idx}>
              <Col lg={{ span: 10, offset: 1 }} md={{ span: 12, offset: 0 }}>
                <div className="match-form-section">{this.renderTask(task)}</div>
              </Col>
            </Row>
          );
        })}
      </React.Fragment>
    );
  }

  private renderTask(task) {
    const status = task.status || "loading";
    let progress = status.charAt(0).toUpperCase() + status.slice(1);
    let name = task.id;

    if (!_.isUndefined(task.results)) {
      if (task.results.progress && task.results.total && task.results.progress_string) {
        progress += `: ${task.results.progress} / ${task.results.total} - ${
          task.results.progress_string.substring(0, 29) || 0
        }`;
      }

      if (task.results.class_name) {
        name = task.results.class_name;
      }

      if (task.results.product && task.results.product.name) {
        name += ` - ${task.results.product.name}`;
      }

      if (task.results.form_data && task.results.form_data.parent_name) {
        name += ` >> ${task.results.form_data.parent_name}`;
      }
    }

    return (
      <Row className="match-table-body">
        <Col md={6}>{name}</Col>
        <Col md={6}>
          {task.status === "error" && task.results && task.results.error_message ? (
            <span>Error: {task.results.error_message}</span>
          ) : (
            progress
          )}
        </Col>
      </Row>
    );
  }
}

export default connector(ListComponent);
