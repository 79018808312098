import { ICustomProduct } from "app/src/Models/Product";
import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { ICommand } from "app/src/Commands/Command";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import { AddEstimateLineItemCmd } from "app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";

export class AddProductFromXmlCmd implements ICommand {
  public lineItems: IEstimateLineItem[] = [];
  constructor(
    public estimate: IEstimate,
    public xml_data: string,
    public group: IEstimateGroup,
    public FileQueue: IFileQueueFactory,
    public EstimatorService: EstimatorService,
  ) {}

  public execute(): boolean {
    if (this.xml_data[0] !== "<") {
      this.xml_data = this.xml_data.substring(this.xml_data.indexOf("\n") + 1);
    }
    const products = this.xml_type(this.xml_data);
    if (products.length < 1) {
      return false;
    }
    _.each(products, (product: any) => {
      const cmd = new AddEstimateLineItemCmd(this.estimate, product, this.group, this.EstimatorService);
      cmd.execute();
      cmd.lineItem.newly_added = false;
      this.lineItems.push(cmd.lineItem);
      if (!_.isEmpty(product.image)) {
        this.FileQueue.getObjectBlob("image", product.image, cmd.lineItem);
      }
    });

    this.FileQueue.setQueue();
    return true;
  }

  private xml_type(data): ICustomProduct[] {
    let products = [];
    if (data.indexOf("lineitemmasters") > 0 && data.indexOf("quote") > 0) {
      products = this.parseWTSParadigm(data);
    } else if (data.indexOf("Order_Information") > 0 && data.indexOf("Item") > 0) {
      products = this.parseSealRite(data);
    } else if (data.indexOf("JobName") > 0 && data.indexOf("Lines") > 0) {
      products = this.parseMarvin(data);
    } else if ((data.indexOf("m2oFile") > 0 && data.indexOf("Unit") > 0) || data.indexOf("Composite") > 0) {
      products = this.parseAndersenIQ(data);
    } else if (data.indexOf("SaberisImportDocument") > 0) {
      products = this.parseSaberisDaVinci(data);
    }
    return products;
  }

  private parseSaberisDaVinci(data: any) {
    data = data.replace(/&lt;br \/&gt;/g, ": ");
    const xml = data.replace(/<br \/>/g, ": ");
    const raw_object = _.parseXml(xml, ["Group"]);
    const products = [];
    _.each(raw_object.SaberisImportDocument.Order.Group, (group: any) => {
      _.each(group.Item, (item: any) => {
        products.push(this.saberisItem(item));
      });
    });

    return products;
  }

  private saberisItem(item: any): ICustomProduct {
    const name = item.Model["#text"] || "";
    const description = item.ProductDescription["#text"] || "";
    const quantity = item.Quantity["#text"];
    const product_price = item.UnitSelling["#text"];
    const hardware = item.Hardware["#text"] || "";
    const int_color = item.IntColour["#text"] || "";
    const ext_color = item.ExtColour["#text"] || "";
    const frame_wh = item.FrameWH["#text"] || "";
    const rso = item.RSO["#text"] || "";
    const Image = item?.Image?.["#text"] || "";

    const theQuantity = _.parseFloat(quantity);
    const theProductPrice = _.round(_.parseFloat(product_price) / theQuantity, -2);

    const theDescription = `${description}Hardware: ${hardware}\nFrame WH: ${frame_wh}\nExt Color: ${ext_color}\nInt Color: ${int_color}\nRSO: ${rso}`;

    return <ICustomProduct>{
      name: name,
      description: theDescription,
      price: theProductPrice,
      product_price: theProductPrice,
      quantity: theQuantity,
      labor_price: 0.0,
      uom: "ea",
      image: Image ? this.imageAndersenIq(Image) : "",
    };
  }

  private parseAndersenIQ(data: any): ICustomProduct[] {
    const xml = data.replace(/<!DOCTYPE m2oFile >/g, "").replace(/<!-- m2o XML ORDER EXPORT FILE V1.0 -->/g, "");
    const raw_object = _.parseXml(xml, ["Unit", "Composite"]);
    const products = [];
    _.each(raw_object.m2oFile.m2oOrder.Unit, (u: any) => {
      products.push(this.unitAndersenIq(u));
    });

    _.each(raw_object.m2oFile.m2oOrder.Composite, (c: any) => {
      products.push(this.compositeAndersenIq(c));
    });

    return products;
  }

  private compositeAndersenIq(item: any): ICustomProduct {
    const PRODLINE = _.findWhere(item.Attribute, { Name: "PRODLINE" })["#text"];
    const PROD = _.findWhere(item.Attribute, { Name: "PROD" })["#text"];
    const PRODTYPE = _.findWhere(item.Attribute, { Name: "PRODTYPE" })["#text"];
    const QUOTE_QUANTITY = _.findWhere(item.Attribute, { Name: "QUOTE_QUANTITY" })["#text"];
    const Total_Price_in_USD = _.findWhere(item.Attribute, { Name: "Total_Price_in_USD" })["#text"];
    const DescCode = _.findWhere(item.Attribute, { Name: "DescCode" })["#text"];
    const Image = _.findWhere(item.Attribute, { Name: "Image" })["#text"];

    const totalPrice = _.parseFloat(Total_Price_in_USD);

    return <ICustomProduct>{
      name: PRODLINE + ": " + PROD + ": " + PRODTYPE,
      description: DescCode,
      quantity: _.parseFloat(QUOTE_QUANTITY),
      price: totalPrice,
      product_price: totalPrice,
      labor_price: 0.0,
      uom: "ea",
      image: this.imageAndersenIq(Image),
    };
  }

  private unitAndersenIq(item: any): ICustomProduct {
    let PRODLINE = _.findWhere(item.Attribute, { Name: "PRODLINE" });

    if (PRODLINE !== undefined) {
      PRODLINE = _.findWhere(item.Attribute, { Name: "PRODLINE" })["#text"];
    } else {
      PRODLINE = "";
    }

    const PROD = _.findWhere(item.Attribute, { Name: "PROD" })["#text"];
    const PRODTYPE = _.findWhere(item.Attribute, { Name: "PRODTYPE" })["#text"];
    const QUOTE_QUANTITY = _.findWhere(item.Attribute, { Name: "QUOTE_QUANTITY" })["#text"];
    const Total_Price_in_USD = _.findWhere(item.Attribute, { Name: "Total_Price_in_USD" })["#text"];
    const DescCode = _.findWhere(item.Attribute, { Name: "DescCode" })["#text"];
    const Image = _.findWhere(item.Attribute, { Name: "Image" })["#text"];

    if (!_.isArray(item.Accessory)) {
      item.Accessory = [item.Accessory];
    }

    const totalPrice: number = _.reduce(
      _.compact(item.Accessory),
      (memo: number, acc: any) => {
        const qty = _.parseFloat(_.findWhere(acc.Attribute, { Name: "QUOTE_QUANTITY" })["#text"]) || 0;
        const price = _.parseFloat(_.findWhere(acc.Attribute, { Name: "Total_Price_in_USD" })["#text"]) || 0;
        return (memo += qty * price);
      },
      _.parseFloat(Total_Price_in_USD),
    );

    return <ICustomProduct>{
      name: PRODLINE + ": " + PROD + ": " + PRODTYPE,
      description: DescCode,
      quantity: _.parseFloat(QUOTE_QUANTITY),
      price: totalPrice,
      product_price: totalPrice,
      labor_price: 0.0,
      uom: "ea",
      image: this.imageAndersenIq(Image),
    };
  }

  private imageAndersenIq(image: any): string {
    return "data:image/png;base64," + image;
  }

  private parseWTSParadigm(data: any): ICustomProduct[] {
    const xml = data.replace(/<!\[CDATA\[/g, "").replace(/]]>/g, "");
    const raw_object = _.parseXml(xml, ["lineitemmaster", "lineitem"]);
    const products = [];
    _.each(raw_object.quote.lineitemmasters.lineitemmaster, (i: any) => {
      products.push(<ICustomProduct>{
        name: this.nameWTSParadigm(i),
        description: this.descriptionWTSParadigm(i),
        quantity: _.parseFloat(i.quantity.Value),
        price: this.priceWTSParadigm(i.lineitems.lineitem),
        product_price: this.priceWTSParadigm(i.lineitems.lineitem),
        labor_price: 0.0,
        uom: "ea",
        image: this.imageWTSParadigm(i),
      });
    });
    return products;
  }

  private nameWTSParadigm(line_item: any): string {
    let name = line_item.description.Value.split("\n")[0].trim();

    if (_.isEmpty(name)) {
      name = "";
      _.each(line_item.lineitems.lineitem, (li: any) => {
        name += li.category1.Value.trim() + " ";
        name += li.category2.Value.trim() + " ";
        name += li.category3.Value.trim() + " ";
        name += li.category4.Value.trim() + " ";
        name += li.category5.Value.trim() + " ";
        name = name.trim();
      });
    }

    return name;
  }

  private descriptionWTSParadigm(line_item: any): string {
    let description = line_item.description.Value;

    // Beechworth Extra Data
    if (
      !_.isUndefined(line_item.lineitems.lineitem[0].extradata.extrafields) &&
      !_.isEmpty(line_item.lineitems.lineitem[0].extradata.extrafields.clsNameValues)
    ) {
      _.each(line_item.lineitems.lineitem[0].extradata.extrafields.clsNameValues.clsnamevalue, (clsN: any) => {
        if (clsN.name["Value"] === "Long Description") {
          description = clsN.value["Value"].trim();
        }
      });
    }

    // Fail-safe
    if (_.isEmpty(description)) {
      description = "";
      _.each(line_item.lineitems.lineitem, (li: any) => {
        if (li.description) {
          description += li.description["#text"].trim();
        }
      });
    }

    return description;
  }

  private imageWTSParadigm(line_item: any): string {
    if (line_item.bmp && line_item.bmp["#text"]) {
      return "data:image/bmp;base64," + line_item.bmp["#text"].replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
    }
    return "";
  }

  private priceWTSParadigm(line_items: any[]): number {
    let price = 0;
    _.each(line_items, (li) => {
      price += _.parseFloat(li.customerprice.Value);
    });
    return price;
  }

  private parseSealRite(data: any): ICustomProduct[] {
    const raw_object = _.parseXml(data, ["Item"]);
    const products = [];
    _.each(raw_object.Project.Item, (i: any) => {
      products.push(<ICustomProduct>{
        name: i.Description["#text"].split(" - ")[0].trim(),
        description: i.Description["#text"].trim(),
        quantity: _.parseFloat(i.Qty["#text"]),
        price: _.parseFloat(i.Price["#text"]),
        product_price: _.parseFloat(i.Price["#text"]),
        labor_price: 0.0,
        uom: "ea",
      });
    });
    return products;
  }

  private parseMarvin(data: any): ICustomProduct[] {
    const raw_object = _.parseXml(data, ["LineItem", "detail"]);
    const products = [];
    _.each(raw_object.Project.Lines.LineItem, (li: any) => {
      products.push(<ICustomProduct>{
        name: this.nameMarvin(li),
        description: this.descriptionMarvin(li),
        quantity: _.parseFloat(li.Quantity["#text"]),
        product_price: _.round(
          _.parseFloat(li.AssemblyInfo.MarvinAssembly["pr"]) / _.parseFloat(li.Quantity["#text"]),
          -2,
        ),
        labor_price: 0.0,
        uom: "ea",
      });
    });
    return products;
  }

  private nameMarvin(li: any): string {
    return li.Brand["#text"] + " " + li.Series["#text"];
  }

  private descriptionMarvin(li: any): string {
    let description = "";
    _.each(li.AssemblyInfo.MarvinAssembly.summary.detail, (d: any) => {
      description += d["desc"] + "\n";
    });
    return description.trim();
  }
}
