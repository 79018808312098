export interface IPretty {
  name: { [details: string]: string };
}

export class PrettyNameService implements IPretty {
  public name: { [details: string]: string } = {
    always: "Always",
    never: "Never",
    company_only: "Edit Mode Only",
    customer_only: "Presentation Mode Only",
    customer_main_only: "Presentation Mode - Included Estimate Groups Only",
    customer_option_only: "Presentation Mode - Not Included Estimate Groups Only",
    company_main_only: "Edit Mode - Included Estimate Groups Only",
    company_option_only: "Edit Mode - Not Included Estimate Groups Only",
    company_customer_main: "Edit and Presentation Mode - Included Estimate Groups Only",
    company_customer_option: "Edit and Presentation Mode - Not Included Estimate Groups Only",
    proposal_only: "Proposal Only",
    agreement_only: "Agreement Only",

    // fill_pdf values
    fill_estimate: "Proposal",
    fill_contract: "Agreement",
    fill_inspection: "Inspection",

    // billing
    leads: "Leads",

    // Time Periods
    month: "Month",
    week: "Week",
    day: "Day",
    quarter: "Quarter",
    year: "Year",

    // Default Taxable Amount
    product: "Product Total",
    labor: "Labor Total",
    product_labor: "Product + Labor Total",

    // Document Order
    cover_page: "Cover Page",
    estimate_pdf: "Estimate PDF",
    product_images: "Product Images",
    job_photos: "Job Photos",
    job_documents: "Job Documents",
    org_documents: "Org Documents",
    product_brochures: "Product Documents",
    resource_documents: "Resource Documents",
    inspection: "Inspection Results",
    inspection_pdf: "Inspection Results PDF",
    presentation_template: "Presentation",
    commission: "Commission",
    slide_template: "Slide Template",
    labor_summary: "Labor Summary",

    // PDF Options
    inspection_include_all_items: "Include All Inspection Items",

    // Alignment Values
    left: "Left",
    center: "Center",
    right: "Right",

    // Location Values
    header: "Header",
    footer: "Footer",

    // Display Values
    do_not_display: "Do Not Display",
    both: "Both",
    contract: "Contract",
    estimate: "Estimate",

    // Product Kind
    unset: "Unset",
    window: "Window",
    door: "Door",

    // Email
    customer_document_signed: "Signed Contract to Customer",
    signable_document_signed: "Signable Document to Customer",

    // Tools
    renoworks_masking: "Order Renoworks Design",
    show_renoworks: "Renoworks",
    eagleview_order: "Order EagleView",
    eagleview_order_decra: "Order Eagleview: Decra",
    google_maps: "Google Maps",
    bing_ortho: "Bing Ortho",
    bing_birdseye: "Bing Birdseye",
    zoom_earth: "Zoom Earth",
    terra_server: "Terra Server",
    pictometry_online: "Pictometry Online",
    pictometry_connect: "Pictometry Connect",
    chameleon: "GAF Chameleon",
    chameleon_v2: "OCC Chameleon",
    chi_overhead_door: "C.H.I. Overhead Door",
    map_developers_area: "Map Developers Area Tool",
    ca_3d_demo: "Chief Architect 3D Demo",
    daltile_demo: "daltile 3D Demo",
    abbeycarpet_demo: "Abbey Carpet Demo",
    renuit_cabinet_demo: "Renuit Cabinet Refacing Demo",
    zillow: "Zillow",
    trulia: "Trulia",
    greensky_link: "GreenSky Financing",
    homeguard: "HomeGuard Windows & Doors",
    plygem_visualizer: "PlyGem Visualizer",
    oc_design_eyeq: "Owens Corning DESIGN EyeQ",
    gaf_vhr: "GAF Virtual Home Remodeler",
    gaf_vhr_link: "GAF Virtual Home Remodeler",
    gaf_vent_calc_link: "GAF Attic Ventilation Calculator",
    provia: "ProVia",
    provia_non_portal: "ProVia (Non-Portal)",
    hover: "HOVER",
    quick_measure: "GAF QuickMeasure",
    quick_measure_roofing: "GAF QuickMeasure",
    houzz_link: "Houzz",
    home_advisor_link: "Home Advisor",
    angies_list_link: "Angie's List",
    gaf_quick_measure_link: "GAF QuickMeasure",
    gaf_smart_money_link: "GAF SmartMoney",
    sketchup: "Sketchup",
    global_transport_link: "Global Transport VT",
    service_finance_link: "Service Finance",
    ami_design_showcase_siding: "AMI Design Showcase: Preservation Siding",
    ami_design_showcase_alside_window: "AMI Design Showcase: Alside Windows",
    ami_design_showcase_pres_window: "AMI Design Showcase: Preservation Windows",
    foundation_finance_link: "Foundation Finance Company",
    sighten_solar: "Sighten Solar",
    enerbank_link: "EnerBank",
    pella_portal: "Pella Portal",
    colorview: "CertainTeed Colorview",
    alside_renoworks: "Alside Visualizer",
    advisar_link: "Advisar",
    square_d_link: "Square D",
    lp_smartside_link: "LP BuildSmart",
    lp_smartside_visualizer: "LP SmartSide Visualizer",
    sunrise_configurator: "Sunrise Windows",
    royal_homeplay: "HomePlay by Royal",
    sherwin_williams_colorsnap: "Sherwin Williams COLORSNAP",
    york_hvac_link: "York HVAC",
    foundation_finance_dealer_link: "Foundation Finance Company Dealer",
    sigler_online_link: "Sigler Online",
    hearth_financing_link: "Hearth Financing",
    wellsfargo_link: "Wells Fargo",
    iko_roof_viewer: "IKO Roof Viewer TM",
    square_d_ezsel: "Square D EZ Selector",
    paragon_visualizer: "Paragon Visualizer",
    timbertech_deck_designer: "TimberTech Deck Designer",
    timbertech_worry_less: "TimberTech: Worry Less",
    roof_scope_roofing: "RoofScope",
    plnar_interior: "plnar",
    plnar: "plnar",
    interior: "Interior",
    lowes_deck_designer: "Lowe's Deck Designer",
    thermatru_design_your_door: "ThermaTru Design Your Door",
    pella_lowes_product_designer: "Pella Lowe's Product Designer",

    // Plnar Report Types
    "3dSnapPlan": "Basic Room",
    fullScope: "Detailed Room",

    // Org Settings
    IN_BROWSER: "Chrome Plugin",
    NATIVE: "Desktop App",

    // Org Preferences
    BROWSER: "Browser Window",
    USER_SELECT: "User Selection",

    // Window Estimator and Use Sales Tax
    disabled: "Disabled",
    enabled: "Enabled",

    // Cronofy
    by_choice: "Default to No",
    by_default: "Default to Yes",
    cronofy: "Cronofy Calendar Sync",

    // Billing
    chameleon_gaf: "GAF Chameleon",
    renoworks: "Renoworks",

    // Users
    org_admin: "Org Admin",
    admin: "Admin",
    user: "User",
    salesrep: "Salesrep",
    custom: "Custom",

    // Billable
    billable: "Billable",
    free: "Free",

    // Audio
    mute: "Mute",
    basic: "Basic",
    premium: "Premium",

    // Measurements
    imperial: "Imperial",
    metric: "Metric",
    none: "None",
    roof_total_area: "Roof Total Area",
    roof_5pw_total_area: "Roof Area + 5% Waste",
    roof_10pw_total_area: "Roof Area + 10% Waste",
    roof_12pw_total_area: "Roof Area + 12% Waste",
    roof_15pw_total_area: "Roof Area + 15% Waste",
    roof_20pw_total_area: "Roof Area + 20% Waste",
    roof_0_area: "0/12 Pitch Area",
    roof_1_area: "1/12 Pitch Area",
    roof_2_area: "2/12 Pitch Area",
    roof_3_area: "3/12 Pitch Area",
    roof_4_area: "4/12 Pitch Area",
    roof_5_area: "5/12 Pitch Area",
    roof_6_area: "6/12 Pitch Area",
    roof_7_area: "7/12 Pitch Area",
    roof_8_area: "8/12 Pitch Area",
    roof_9_area: "9/12 Pitch Area",
    roof_10_area: "10/12 Pitch Area",
    roof_11_area: "11/12 Pitch Area",
    roof_12_area: "12/12 Pitch Area",
    roof_13_area: "13/12 Pitch Area",
    roof_14_area: "14/12 Pitch Area",
    roof_15_area: "15/12 Pitch Area",
    roof_16_area: "16/12 Pitch Area",
    roof_17_area: "17/12 Pitch Area",
    roof_18_area: "18/12 Pitch Area",
    roof_19_area: "19/12 Pitch Area",
    roof_20_area: "20/12 Pitch Area",
    roof_21_area: "21/12 Pitch Area",
    roof_22_area: "22/12 Pitch Area",
    roof_23_area: "23/12 Pitch Area",
    roof_24_area: "24/12 Pitch Area",
    roof_25_area: "25/12 Pitch Area",
    roof_26p_area: "26/12+ Pitch Area",
    roof_flat_shallow_area: "Roof Flat Shallow Area",
    roof_flat_area: "Roof Flat Area",
    roof_low_slope_area: "Roof Low Slope Area",
    roof_average_slope_area: "Roof Average Slope Area",
    roof_steep_slope_area: "Roof Steep Slope Area",
    roof_ultra_steep_slope_area: "Roof Ultra Steep Slope Area",
    roof_high_roof_area: "Roof High Roof Area",
    roof_sloped_area: "Roof Sloped Area",
    roof_10pw_sloped_area: "Roof Sloped Area + 10% Waste",
    roof_79_area: "Roof 7-9/12 Pitch Area",
    roof_1012_area: "Roof 10-12/12 Pitch Area",
    roof_13p_area: "Roof 13/12+ Pitch Area",
    roof_eave: "Roof Eave",
    roof_rake: "Roof Rake",
    roof_perimeter: "Roof Perimeter",
    roof_ridge: "Roof Ridge",
    roof_hip: "Roof Hip",
    roof_ridge_hip: "Roof Ridge Hip",
    roof_valley: "Roof Valley",
    roof_valley_eave: "Roof Valley Eave",
    roof_valley_eave_rake: "Roof Valley Eave Rake",
    roof_step_flashing: "Roof Step Flashing",
    roof_headwall_flashing: "Roof Headwall Flashing",
    roof_total_flashing: "Roof Total Flashing",
    roof_gutter_miters: "Roof Gutter Miters",
    roof_downspouts: "Roof Downspouts",
    roof_downspout_elbows: "Roof Downspout Elbows",
    siding_total_area: "Siding Total Area",
    siding_5pw_total_area: "Siding Area + 5% Waste",
    siding_10pw_total_area: "Siding Area + 10% Waste",
    siding_15pw_total_area: "Siding Area + 15% Waste",
    siding_18pw_total_area: "Siding Area + 18% Waste",
    siding_inside_corner_qty: "Siding Inside Corner Quantity",
    siding_inside_corner_length: "Siding Inside Corner Length",
    siding_outside_corner_qty: "Siding Outside Corner Quantity",
    siding_outside_corner_length: "Siding Outside Corner Length",
    siding_level_starter: "Siding Level Starter",
    siding_sloped_trim: "Siding Sloped Trim",
    siding_vertical_trim: "Siding Vertical Trim",
    siding_level_frieze_board: "Siding Level Frieze Board",
    siding_sloped_frieze_board: "Siding Sloped Frieze Board",
    siding_frieze_board: "Siding Frieze Board",
    siding_opening_qty: "Siding Opening Quantity",
    siding_windows_qty: "Siding Windows Quantity",
    siding_doors_qty: "Siding Doors Quantity",
    siding_garage_doors_qty: "Siding Garage Doors Quantity",
    siding_opening_tops_length: "Siding Opening Tops Length",
    siding_opening_sills_length: "Siding Opening Sills Length",
    siding_opening_sides_length: "Siding Opening Sides Length",
    siding_shutter_qty: "Siding Shutter Quantity",
    siding_shutter_area: "Siding Shutter Area",
    siding_vents_qty: "Siding Vents Quantity",
    siding_vents_area: "Siding Vents Area",
    siding_soffit_eaves: "Siding Soffit Eaves",
    siding_soffit_level_frieze: "Siding Soffit Level Frieze",
    siding_soffit_rakes: "Siding Soffit Rakes",
    siding_soffit_sloped_frieze: "Siding Soffit Sloped Frieze",
    siding_soffit_total: "Siding Soffit Total",
    roof_faces: "Roof Faces",
    walls: "Walls",
    windows: "Windows",
    doors: "Doors",
    roofing: "Roofing",
    siding: "Siding",
    sunroom: "Sunroom",
    rooms: "Rooms",
    decks: "Decks",
    pools: "Pools",
    fencing: "Fencing",

    // Sunroom
    sunroom_a_wall_length: "Wall A Length",
    sunroom_a_wall_height: "Wall A Height",
    sunroom_a_wall_area: "Wall A Area",
    sunroom_b_wall_length: "Wall B Length",
    sunroom_b_wall_height: "Wall B Height",
    sunroom_b_wall_area: "Wall B Area",
    sunroom_c_wall_length: "Wall C Length",
    sunroom_c_wall_height: "Wall C Height",
    sunroom_c_wall_area: "Wall C Area",
    sunroom_wall_perimeter: "Perimeter",
    sunroom_wall_total_area: "Wall Total Area",
    sunroom_deck_total_area: "Deck Total Area",
    sunroom_ceiling_area: "Ceiling Area",
    sunroom_studio_roof_area: "Studio Roof Area",
    sunroom_cathedral_roof_area: "Cathedral Roof Area",
    sunroom_kw_a_length: "Knee Wall A Length",
    sunroom_kw_a_height: "Knee Wall A Height",
    sunroom_kw_a_area: "Knee Wall A Area",
    sunroom_kw_b_length: "Knee Wall B Length",
    sunroom_kw_b_height: "Knee Wall B Height",
    sunroom_kw_b_area: "Knee Wall B Area",
    sunroom_kw_c_length: "Knee Wall C Length",
    sunroom_kw_c_height: "Knee Wall C Height",
    sunroom_kw_c_area: "Knee Wall C Area",
    sunroom_kw_total_length: "Knee Wall Total Length",
    sunroom_kw_total_area: "Knee Wall Total Area",

    // Interior Room
    area: "Area",
    perimeter: "Perimeter",
    inside_corner: "Inside Corner",
    outside_corner: "Outside Corner",
    threshold: "Threshold",
    ceiling_area: "Ceiling Area",
    ceiling_area_less_structures: "Ceiling Area Less Structures",
    ceiling_perimeter: "Ceiling Perimeter",
    ceiling_perimeter_less_structures: "Ceiling Perimeter Less Structures",
    door_area: "Door Area",
    door_count: "Door Count",
    door_total_width: "Door Total Width",
    exposed_structure_perimeter: "Exposed Structure Perimeter",
    floor_area_less_structures: "Floor Area Less Structures",
    floor_opening_total_width: "Floor Opening Total Width",
    floor_plan_area: "Floor Plan Area",
    floor_plan_perimeter: "Floor Plan Perimeter",
    floor_plan_perimeter_less_structures: "Floor Plan Perimeter Less Structures",
    floor_plan_perimeter_without_openings: "Floor Plan Perimeter Without Openings",
    object_count: "Object Count",
    opening_count: "Opening Count",
    opening_total_area: "Opening Total Area",
    opening_total_width: "Opening Total Width",
    structure_total_ceiling_area: "Structure Total Ceiling Area",
    structure_total_ceiling_width: "Structure Total Ceiling Width",
    structure_total_floor_area: "Structure Total Floor Area",
    structure_total_floor_width: "Structure Total Floor Width",
    structure_total_wall_area: "Structure Total Wall Area",
    volume: "Volume",
    wall_count: "Wall Count",
    wall_total_area: "Wall Total Area",
    wall_total_area_without_openings: "Wall Total Area without Openings",
    window_count: "Window Count",
    window_total_area: "Window Total Area",

    // Units of Measure
    ea: "Each",
    sqft: "Square Foot",
    lft: "Lineal Foot",
    ui: "United Inch",
    in: "Inch",
    sq: "Square",
    pair: "Pair",
    sash: "Sash",
    mile: "Mile",
    gal: "Gallon",
    layer: "Layer",
    hr: "Hour",
    cm: "Centimeters",
    m: "Meters",
    sqm: "Square Meter",
    km: "Kilometers",
    l: "Liter",
    percent: "Percent",
    all: "All",
    w: "Watts",
    kW: "Kilowatts",

    // Signature Platform
    scribble: "Scribble",

    // Import Type
    hover_excel: "HOVER Excel",
    roofscope_csv: "RoofScope CSV",
    roofsnap_csv: "RoofSnap CSV",
    eagleview_xml: "EagleView XML",
    window_nation_json: "Window Nation JSON",
    hover_xml: "HOVER XML",
    picto_connect_csv: "Pictometry Connect CSV",
    skymeasure_csv: "SkyMeasure CSV",
    skymeasure_xml: "SkyMeasure XML",
    aerialreports_xml: "Aerial Reports XML",
    eagleview_qs_json: "EagleView QuickSquares JSON",
    xactimate_xml: "Xactimate XML",
    gaf_quick_measure_xml: "GAF QuickMeasure XML",
    gaf_quickmeasure_xml: "GAF QuickMeasure XML",
    ez3d_csv: "EZ3D CSV",

    // Estimator Add Products
    replace: "Replace",
    merge: "Merge",
    ask: "Ask",

    //
    sent: "Sent",
    created: "Created",
    viewed: "Viewed",
    recipient_signed: "Signed",
    signed: "Signed",

    // Product Option Group Selection Mode
    multi: "Multiple",
    single: "Single",

    true: "True",
    false: "False",

    // Product Option Type
    per_quantity: "Per Line Item Quantity",
    per: "Per Line Item",
    per_ui: "Per UI",
    per_sqft: "Per SQFT",

    sky_measure: "Sky Measure",
    sky_measure_roofing: "CoreLogic SkyMeasure Roofing",
    wvs_hail: "CoreLogic WVS Hail",
    wvs_wind: "CoreLogic WVS Wind",
    wvs_lightning: "CoreLogic WVS Lightning",
    hail: "Hail",
    wind: "Wind",
    lightning: "Lightning",
    wvs: "Weather Verification Service",
    eagleview_roofing: "EagleView",
    eagleview: "EagleView",
    permit: "Permit",

    // Job Menu
    information: "Information",
    appointments: "Appointments",
    tools: "Tools",
    images: "Photos",
    documents: "Documents",
    measurements: "Measurements",
    notes: "Notes",
    history: "History",
    presentations: "Presentations",
    design_tools: "Design Tools",
    estimates: "Estimates",
    proposals: "Proposals",
    contracts: "Contracts",
    signatures: "Signatures",

    // Job Menu URL path
    info: "Information",
    property: "Property Data",
    "design-tools": "Design Tools",

    // Status
    ordered: "Ordered",
    exception: "Exception",
    canceled: "Canceled",
    complete: "Complete",
    paid: "Paid",
    refunded: "Refunded",
    partially_refunded: "Partially Refunded",

    // Email Kind
    appointment_invite: "Appointment",
    document_signed_notification: "Doc Signed",
    image_uploader: "Image",
    proposal: "Proposal",
    screen_share: "Screen Share",
    signature_link: "Signature",
    payment_info_setup: "Payment Info Request",
    payment_info_reminder: "Payment Info Reminder",

    // Email Provider
    postmark: "Email",
    twilio: "Text",

    // Email Statuses
    Delivery: "Delivered",
    Bounce: "Bounced",
    SpamComplaint: "Reported as Spam",
    Open: "Opened",

    // Bracket Type
    unset_bt: "Unset",
    ui_bt: "UI Based",
    width_height_bt: "Width/Height Based",

    // Job Nimbus
    job: "Job",
    contact: "Contact",

    // Option Group Type
    default: "Default",
    cost: "Cost",
    link: "Link",

    // Preferences - Measurements
    annotator: "Window-inator",
    elevation_scaling: "Opening Measuring",
    rapid_area: "Rapid Area",
    satellite_measure: "Satellite Measure",
    sidinginator: "Siding-inator",
    deck_wizard: "Deck Measurement Tool",
    pool_tool: "Pool Measurement Tool",
    fencing_tool: "Fencing Measurement Tool",

    // Org Status Events
    activate: "Activate",
    onboard: "Onboard",
    freemium: "Freemium",
    cancel: "Cancel",
    suspend: "Suspend",
    recreate: "Re-create",
    onboarding: "Onboarding",
    activated: "Activated",
    suspended: "Suspended",

    // Address Types
    administrative_area_level_2: "County",
    administrative_area_level_3: "Township",

    // Estimate Templates
    template: "template",

    // Module Names
    estimate_module: "One Click Contractor",
    leads_module: "One Click Leads",
    job_nimbus: "JobNimbus",
    jobprogress: "JobProgress",
    roof_scope: "RoofScope",
    companycam: "CompanyCam",
    improveit360: "ImproveIt360",

    // Job Attributes
    job_type: "Job Type",
    level: "Level",
    location: "Location",

    // Job Query Updated filters
    "7days": "This Week",
    "14days": "Last 2 Weeks",
    "1months": "Last Month",
    "3months": "Last 3 Months",
    "6months": "Last 6 Months",
    "1years": "Last 12 Months",
    Custom: "Custom",

    //ImproveIt360 platforms
    i360_classic: "Classic",
    i360_lightning: "Lightning",
  };

  public eagleview_delivery_product_name: { [details: string]: string } = {
    "1": "JPG",
    "2": "PDF",
    "3": "DOC",
    "4": "XML",
    "5": "DXF",
    "6": "RXF",
    "7": "XLS",
    "8": "HTML",
    "9": "EMF",
    "10": "PNG",
    "11": "GIF",
    "12": "EPS",
    "13": "DOCX",
    "14": "DOT",
    "15": "DOTX",
    "16": "XLSX",
    "18": "JSON",
    "19": "ZIPJPGS",
  };

  public eagleview_product_name: { [details: string]: string } = {
    // Eagleview Report Types
    "1": "PremiumResidential",
    "2": "PremiumCommercial",
    "3": "StandardResidential",
    "9": "Images",
    "10": "ParkingLot",
    "11": "SolarResidential",
    "12": "SolarCommercial",
    "13": "ClaimsReadyResidential",
    "14": "ClaimsReadyCommercial",
    "15": "AA2D",
    "16": "AA3DResidential",
    "17": "SketchOnly",
    "18": "AA3DCommercial",
    "20": "InstallerReport",
    "25": "EC3DResidential",
    "26": "EC3DCommercial",
    "27": "EC2DResidential",
    "28": "EC2DCommercial",
    "29": "PremiumAWResidential",
    "30": "PremiumAWCommercial",
    "31": "ECPremiumResidential",
    "32": "ECPremiumCommercial",
    "34": "PremiumResidentialWallonly",
    "35": "PremiumCommercialWallonly",
    "36": "FirstChoicePM",
    "37": "RiskManagementResidential",
    "38": "RiskManagementCommercial",
    "39": "Estimate",
    "40": "RoofGeometryResidential",
    "41": "CarrierEstimate",
    "42": "PictometryPremium",
    "43": "PictometryStandard",
    "44": "QuickSquares",
    "46": "GutterResidential",
    "47": "GutterCommercial",
    "48": "DiagramResidential",
    "49": "DiagramCommercial",
  };

  public static $inject = [];
  constructor() {}
}
