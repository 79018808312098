import { IReport, IReportResource } from "../Models/Report";
import { IPretty } from "../Common/PrettyNameService";
import { IUser } from "../Models/User";
import { IOrg } from "../Models/Org";
import { IRepository } from "../Common/Repository";

export class ReportShowCtrl {
  public spinnerPromise: ng.IPromise<any>;
  public report: IReport;

  protected users: { number: IUser } = {} as { number: IUser };
  protected orgs: { number: IOrg } = {} as { number: IOrg };

  public static $inject = ["$state", "$stateParams", "Report", "Pretty", "$window", "Repository"];

  constructor(
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public Report: IReportResource,
    public Pretty: IPretty,
    public $window: ng.IWindowService,
    public Repository: IRepository,
  ) {
    this.spinnerPromise = this._loadReport();
  }

  public fetchUser(userId: number) {
    // 2022-09-22 CTT & MFG: In the case a userId is NOT yet loaded, do not load all users
    if (!userId) {
      return;
    }
    if (!this.users[userId]) {
      this.users[userId] = this.Repository.User.get({ id: userId });
    }

    return this.users[userId];
  }

  public fetchOrg(orgId: number) {
    // 2022-09-22 CTT & MFG: In the case a userId is NOT yet loaded, do not load all orgs
    if (!orgId) {
      return;
    }
    if (!this.orgs[orgId]) {
      this.orgs[orgId] = this.Repository.Org.get({ id: orgId });
    }

    return this.orgs[orgId];
  }

  public goBack() {
    this.$window.history.back();
  }

  private _loadReport(): ng.IPromise<any> {
    return this.Report.get({ id: this.$stateParams["id"] }, (report) => {
      this.report = report;
    }).$promise;
  }
}
