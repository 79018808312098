import { Record } from "immutable";

export interface IBaseRecord {
  id: number | string;
  loading: boolean;
}
export interface IEditPayload<T> {
  id: number;
  name: string;
  value: T;
}

export const fetch = <T extends IBaseRecord>(state: Record<any>, model: string, record: Record<T>): Record<any> => {
  const id = record.get("id");
  if (state.getIn([model, "byId", id])) {
    return state.setIn([model, "byId", id, "loading"], true);
  }
  return state.setIn([model, "byId", id], record.setIn(["loading"], true));
};

export const receive = <T extends IBaseRecord>(state: Record<any>, model: string, record: Record<T>): Record<any> => {
  const id = record.get("id");
  record = record.set("loading", false);
  return state.setIn([model, "byId", id], record).setIn([model, "lastSavedById", id], record);
};

export const reset = (state: Record<any>, model: string, id: number | string): Record<any> => {
  const lastSaved = state.getIn([model, "lastSavedById", id]);

  if (!lastSaved) return state.deleteIn([model, "byId", id]);
  return state.setIn([model, "byId", id], lastSaved);
};

export const loaded = (state: Record<any>, model: string, id: number | string): Record<any> => {
  return state.setIn([model, "byId", id, "loading"], false);
};

/**
 * Made for usage in FinalForm/Redux based forms.
 *
 * Takes the default values for a record and uses the typeof the value to determine
 * if the value found at the name should be coerced to a number from a form.
 *
 * @param {{[key: string]: Record<any>}} defaultProps - the default props for the Record expected at the `name` keypath.
 * @param {string} name - period (.) seperated keypaths: e.g. job.user.first_name
 * @param {string} value - the value to be coerced
 * @returns  {string|number} - either the value or the value coerced into a {number}
 */
export const parseUpdateForm = (
  defaultProps: { [key: string]: Record<any> },
  name: string,
  value: string,
): number | string => {
  const nameArray = name.split(".");
  const lastName = nameArray[nameArray.length - 1];

  if (typeof defaultProps[lastName] === "number") {
    value = _.round(+value || 0, -2);
  }

  return value;
};
