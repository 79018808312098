import { RootDispatchType } from "../store";
import { ThunkResult } from "./index";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { sendMessage, ISendMessageParams } from "app2/src/api/email.service";
import { RootState } from "app2/src/reducers";
import { handleErrors } from "app2/src/reducers/Utils";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";

export const AsyncActions = {
  sendMessage: (messageParams: ISendMessageParams): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const jobId = currentJobId(getState());
      try {
        await sendMessage(jobId, messageParams);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Message successfully sent"));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
};
