import { Record, List } from "immutable";
import { Nullable } from ".";
import { EstimateLineItemRecord } from "./EstimateLineItem";

export const fromJSON = (json: Partial<IEstimateLineItemOptionData>): EstimateLineItemOptionRecord => {
  const record: IEstimateLineItemOptionRecord = { ...(json as any) };

  return new EstimateLineItemOptionRecord(record);
};

export const calculateQuantity = (option: EstimateLineItemOptionRecord, line_item: EstimateLineItemRecord): number => {
  if (!line_item) {
    return 0;
  }
  if (option.charge_type === "per") {
    return _.round(option.quantity, -2);
  } else {
    return _.round(option.quantity * line_item.quantity, -2);
  }
};

export const calculateProductPrice = (
  option: EstimateLineItemOptionRecord,
  line_item: EstimateLineItemRecord,
): number => {
  if (!line_item) {
    return 0;
  }
  // needs to handle percentage based pricing (percentage based product price already calculated in angular)
  return _.round(option.product_price * calculateQuantity(option, line_item), -2);
};

export const calculateLaborPrice = (
  option: EstimateLineItemOptionRecord,
  line_item: EstimateLineItemRecord,
): number => {
  if (!line_item) {
    return 0;
  }
  // needs to handle percentage based pricing (percentage labor product price already calculated in angular)
  return _.round(option.labor_price * calculateQuantity(option, line_item), -2);
};

export interface IEstimateLineItemOptionData {
  id: number;
  quantity: number;
  name: string;
  description: string;
  ext_price: number;
  uom: string;
  price: number;
  cost: number;
  msrp: number;
  product_option_uuid: string;
  product_option_match_id: string;
  estimate_line_item_id: number;
  product_option_group_id: number;
  product_option_group_name: string;
  product_option_id: number;
  product_option_image_ids: number[];
  labor_price: number;
  product_price: number;
  base_labor_price: number;
  base_product_price: number;
  labor_factor: number;
  product_factor: number;
  created_at: Date;
  updated_at: Date;
  charge_type: string;
  pog_type: string;
  _destroy: Nullable<boolean>;
}

export interface IEstimateLineItemOptionRecord {
  id: number;
  quantity: number;
  name: string;
  description: string;
  ext_price: number;
  uom: string;
  price: number;
  cost: number;
  msrp: number;
  product_option_uuid: string;
  product_option_match_id: string;
  estimate_line_item_id: number;
  product_option_group_id: number;
  product_option_group_name: string;
  product_option_id: number;
  product_option_image_ids: List<number>;
  labor_price: number;
  product_price: number;
  base_labor_price: number;
  base_product_price: number;
  labor_factor: number;
  product_factor: number;
  created_at: Date;
  updated_at: Date;
  charge_type: string;
  pog_type: string;
  _destroy: Nullable<boolean>;
}

const defaultEstimateLineItemOptionProps: IEstimateLineItemOptionRecord = {
  id: 0,
  quantity: 0,
  name: "",
  description: "",
  ext_price: 0,
  uom: "",
  price: 0,
  cost: 0,
  msrp: 0,
  product_option_uuid: "",
  product_option_match_id: "",
  estimate_line_item_id: 0,
  product_option_group_id: 0,
  product_option_group_name: "",
  product_option_id: 0,
  product_option_image_ids: List<number>(),
  labor_price: 0,
  product_price: 0,
  base_labor_price: 0,
  base_product_price: 0,
  labor_factor: 0,
  product_factor: 0,
  created_at: new Date(),
  updated_at: new Date(),
  charge_type: "",
  pog_type: "",
  _destroy: null,
};

export class EstimateLineItemOptionRecord
  extends Record(defaultEstimateLineItemOptionProps)
  implements IEstimateLineItemOptionRecord {}
