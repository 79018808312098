import { fetcher } from "../../helpers/Fetcher";
import { toJSON, IFeeData, FeeRecord } from "../../records/billing/Fee";

export interface IFeeIndexResponse {
  fees: IFeeData[];
}

export interface IFeeResponse {
  fee: IFeeData;
}

export interface IFeeQueryOptions {
  archived: boolean;
}

const url = "billing/v1/fees";

export const query = async (options: Partial<IFeeQueryOptions> = {}): Promise<IFeeIndexResponse> => {
  return await fetcher.get<IFeeIndexResponse>(url, { ...options });
};

export const load = async (feeId: number): Promise<IFeeResponse> => {
  const actionUrl = fetcher.joinUrls(url, feeId.toString());

  return await fetcher.get<IFeeResponse>(actionUrl);
};

export const create = async (fee: FeeRecord): Promise<IFeeResponse> => {
  const actionUrl = url;

  const data = toJSON(fee);

  return await fetcher.post<IFeeResponse>(actionUrl);
};

export const update = async (fee: FeeRecord): Promise<IFeeResponse> => {
  const actionUrl = fetcher.joinUrls(url, fee.id.toString());

  return await fetcher.patch<IFeeResponse>(actionUrl, { fee });
};

export const archive = async (fee: FeeRecord): Promise<IFeeResponse> => {
  const actionUrl = fetcher.joinUrls(url, fee.id.toString());

  return await fetcher.delete<IFeeResponse>(actionUrl);
};

export const unarchive = async (fee: FeeRecord): Promise<IFeeResponse> => {
  const actionUrl = fetcher.joinUrls(url, fee.id.toString(), "unarchive");

  return await fetcher.patch<IFeeResponse>(actionUrl);
};
