import * as ng from "angular";
import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IScreenShare extends ng.resource.IResource<IScreenShare>, ScreenSharePrototype {
  $stop?(): IScreenShare;
  $stop?(params?: Object, success?: Function, error?: Function): IScreenShare;
  $stop?(success: Function, error?: Function): IScreenShare;
}

export interface IScreenShareResource extends ng.resource.IResourceClass<IScreenShare>, IRsfResource {
  landing?(): IScreenShare;
  landing?(params?: Object, success?: Function, error?: Function): IScreenShare;
  landing?(success: Function, error?: Function): IScreenShare;

  create?(): IScreenShare;
  create?(params?: Object, success?: Function, error?: Function): IScreenShare;
  create?(success: Function, error?: Function): IScreenShare;

  fromJSON?(data: any): IScreenShare;
}

export interface IScreenShareResponse extends ng.resource.IResourceArray<IScreenShare> {
  screen_shares: IScreenShare[];
  meta: IPagingMetadata;
}

class ScreenSharePrototype {
  public uuid: string;
  public id: number;
  public kind: string;
  public uid: string;
  public job_id: number;
  public shared: boolean;
  public start_time: Date;
  public started: Date;
  public ended: Date;
  // noinspection JSUnusedGlobalSymbols
  public event_id: number;
  public estimate_id: number;
  public metadata: any;
  public created_at: Date;
  public updated_at: Date;
  public redirect_on_end_url: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/screen_shares/:id";

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta = angular.fromJson(response);

    _.each(meta.screen_shares, (pt, index) => {
      meta.screen_shares[index] = ScreenShare.fromJSON(pt);
    });
    return meta;
  };

  const transformSingle = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return new ScreenShare({});
    }

    const data = angular.fromJson(response);
    return ScreenShare.fromJSON(data.screen_share);
  };

  const transformRequest = (data) => {
    return angular.toJson({ screen_share: data });
  };

  const ScreenShare: IScreenShareResource = <IScreenShareResource>$resource(
    url,
    { id: "@id" },
    {
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: transformSingle,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultiple,
        isArray: false,
      },
      stop: <ng.resource.IActionDescriptor>{
        url: BaseConfig.BASE_URL + "/api/v1/screen_shares/:id/stop",
        method: "PATCH",
        transformResponse: transformSingle,
        isArray: false,
      },
      landing: <ng.resource.IActionDescriptor>{
        url: BaseConfig.BASE_URL + "/api/v1/screen_shares/landing",
        method: "GET",
        transformResponse: transformSingle,
        isArray: false,
      },
    },
  );

  ScreenShare.fromJSON = (data: any) => {
    return new ScreenShare(data);
  };

  _.hiddenExtend(ScreenShare.prototype, ScreenSharePrototype.prototype);
  ScreenShare.prototype.payment_term_items = [];

  ScreenShare.inject = (injected: IRepository) => {
    resources = injected;
  };

  return ScreenShare;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
