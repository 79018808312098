import { Record, List } from "immutable";
import { Nullable } from ".";
import { IOpeningEstimationData, OpeningEstimationRecord } from "./OpeningEstimation";
import { IAnnotationData, AnnotationRecord } from "./Annotation";

export const calculateOpeningArea = (height: number, width: number): number => {
  if (_.isNullOrUndefined(height) || _.isNullOrUndefined(width)) {
    return 0;
  }

  return _.round((width * height) / 144, -2);
};

export const calculateOpeningUI = (height: number, width: number): number => {
  if (_.isNullOrUndefined(height) || _.isNullOrUndefined(width)) {
    return 0;
  }

  return _.round(width + height, -2);
};

export interface IOpeningData {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  location: string;
  level: string;
  height: number;
  width: number;
  area: number;
  ui: number;
  kind: string;
  sort_order: number;

  opening_estimations?: IOpeningEstimationData[];

  annotation_id?: number;
  annotation?: IAnnotationData;

  _destroy?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface IOpeningRecord {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  location: string;
  level: string;
  height: number;
  width: number;
  area: number;
  ui: number;
  kind: string;
  sort_order: number;

  opening_estimations: List<OpeningEstimationRecord>;

  annotation_id: Nullable<number>;
  annotation: Nullable<AnnotationRecord>;

  _destroy: Nullable<boolean>;

  created_at: Date;
  updated_at: Date;
}

export const defaultOpeningProps: IOpeningRecord = {
  id: 0,
  name: "",
  designator: "",
  location: "",
  level: "",
  height: 0,
  width: 0,
  area: 0,
  ui: 0,
  kind: "",
  sort_order: null,
  opening_estimations: List<OpeningEstimationRecord>(),
  annotation_id: null,
  annotation: null,
  _destroy: null,
  created_at: new Date(),
  updated_at: new Date(),
};

export class OpeningRecord extends Record(defaultOpeningProps) implements IOpeningRecord {}
