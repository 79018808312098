import * as uuid from "uuid/v4";
import { IJobResource, IJob } from "app/src/Models/Job";
import { IJobEstimateAddedEvent } from "app/src/Estimator/EstimatorEvents";
import { IEstimate } from "app/src/Models/Estimate";
import { IOrg, IOrgResource } from "app/src/Models/Org";
import { IEventResource } from "app/src/Models/Event";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IVisualizationResource } from "app/src/Models/Visualization";
import { embeddedService } from "app2/src/services/embedded.service";
import { dispatch } from "app2/src/storeRegistry";
import { fromJSON } from "app2/src/records/Job";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import { orgService } from "app2/src/api/org.service";
import { includes } from "app2/src/api/job.service";

export interface IJobFetcherService {
  includes: string[];
  Job: IJobResource;
  get(id: number, includes?: string[]): IJob;
  set(job: IJob): void;
  refresh(id: number): IJob;

  getOrg(id: number): IOrg;

  setPresentationState(state: boolean): void;
  observePresentationState(func: (state: boolean) => void): string;
  removePresentationStateObserver(id): boolean;
  currentPresentationState(): boolean;
  isObservingPresentationState(key: string): boolean;
}

export class JobFetcherService implements IJobFetcherService {
  public includes: string[] = includes;
  public navDisplay = true;

  private _presentationStateObservers: { string: (state: boolean) => void } = {} as {
    string: (state: boolean) => void;
  };
  private _presentationState = true;
  private jobs: { [key: number]: IJob } = {} as { [key: number]: IJob };
  private orgs: { [key: number]: IOrg } = {} as { [key: number]: IOrg };

  public static $inject = ["Job", "Org", "Event", "Visualization", "$rootScope"];
  constructor(
    public Job: IJobResource,
    public Org: IOrgResource,
    public Event: IEventResource,
    public Visualization: IVisualizationResource,
    public $rootScope: RsfRootScope,
  ) {
    $rootScope.$on("job:estimate_added", (event: ng.IAngularEvent, args: IJobEstimateAddedEvent) => {
      if (this.jobs[args.id]) {
        this.jobs[args.id].estimates.push(args.estimate);
      }

      embeddedService.sendEvent("RSF.estimateOverview", args.estimate.decycleToJSON());
    });
    $rootScope.$on("job:estimate_updated", (event: ng.IAngularEvent, args: IJobEstimateAddedEvent) => {
      if (this.jobs[args.id]) {
        const estimate: IEstimate = _.find(this.jobs[args.id].estimates, (e: IEstimate) => {
          return e.id === args.estimate.id;
        });

        estimate.name = args.estimate.name;
        estimate.total = args.estimate.total;
        estimate.line_items_count = args.estimate.line_items_count;
        //noinspection TypeScriptValidateTypes
        estimate.updated_at = args.estimate.updated_at.toISOString() as any as Date;
      }

      embeddedService.sendEvent("RSF.estimateOverview", args.estimate.decycleToJSON());
    });
  }

  public get(id: number, includes?: string[]): IJob {
    if (typeof id === "string") {
      id = parseInt(id);
    }
    dispatch(jobActions.Actions.setCurrentJobId(id));
    dispatch(jobActions.Actions.fetchJob(id));
    const mergedIncludes = includes ? includes : this.includes;
    if (!this.jobs[id]) {
      this.jobs[id] = this.Job.get({ id: id, "include[]": mergedIncludes });
      this.jobs[id].$promise.catch((e) => {
        delete this.jobs[id];
      });
    } else {
      // noinspection TypeScriptUnresolvedVariable
      if ((<any>this.jobs[id].$promise).$$state.status === 1) {
        this.jobs[id].$promise = this.jobs[id].$get({ "include[]": mergedIncludes });
      }
    }

    this.jobs[id].$promise.then(() => {
      orgService.setCurrentOrgId(this.jobs[id].org_id);
      dispatch(jobActions.Actions.receiveJob(JSON.decycle(this.jobs[id])));
      const job_data = JSON.parse(JSON.stringify(this.jobs[id]));
      dispatch(commonActions.Actions.setJob(fromJSON(job_data)));
      embeddedService.sendEvent("RSF.job", JSON.parse(angular.toJson(this.jobs[id])));
    });

    return this.jobs[id];
  }

  public set(job: IJob): void {
    this.jobs[job.id] = job;
  }

  public refresh(id: number): IJob {
    this.get(id);

    return this.jobs[id];
  }

  public getOrg(id: number): IOrg {
    if (!this.orgs[id]) {
      this.orgs[id] = this.Org.get({ id: id, "include[]": ["brand", "settings", "preferences", "lead_sources"] });
    }

    return this.orgs[id];
  }

  public setPresentationState(state: boolean) {
    if (this._presentationState === state) {
      return;
    }

    this._presentationState = state;

    _.each(this._presentationStateObservers, (func) => {
      func(this._presentationState);
    });
  }

  public observePresentationState(func: (state: boolean) => void): string {
    const key = uuid();

    this._presentationStateObservers[key] = func;

    return key;
  }

  public currentPresentationState() {
    return this._presentationState;
  }

  public removePresentationStateObserver(id): boolean {
    if (_.has(this._presentationStateObservers, id)) {
      delete this._presentationStateObservers[id];
      return true;
    }

    return false;
  }

  public isObservingPresentationState(key: string) {
    return _.has(this._presentationStateObservers, key);
  }
}
