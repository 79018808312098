import { ActionsUnion, createAction } from "./Utils";
import { orgService, IOrgIndexResponse } from "app2/src/api/org.service";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { IOrgData, OrgRecord } from "../records/OrgRecord";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as taskActions from "app2/src/reducers/task.actions";
import * as tokenActions from "app2/src/reducers/token.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { RootDispatchType } from "app2/src/store";
import { IPageData, QueryParamsRecord } from "app2/src/records/Page";
import { List } from "immutable";
import { Actions as PagingActions } from "app2/src/reducers/pagination.actions";
import { handleErrors } from "app2/src/reducers/Utils";
import { DocType } from "app2/src/records/Estimate";
import { DocumentRecord } from "app2/src/records/Document";
import { currentOrg, currentOrgId, preferencesConfig } from "app2/src/selectors/org.selectors";
import { migrateOldDocs } from "app2/src/components/OrgTabs/DocumentTab/DocumentOrder/DocumentOrder.service";
import { token } from "app2/src/selectors/token.selectors";
import { JobRecord } from "../records/Job";
import { cleanProps } from "app2/src/api/token.service";

export const SET_ORG = "@org/SET_ORG";
export const RESET_ORG = "@org/RESET_ORG";
export const SET_CURRENT_ORG_ID = "@org/SET_CURRENT_ORG_ID";
export const FETCH_ORG = "@org/FETCH_ORG";
export const RECEIVE_ORG_ERRORS = "@org/RECEIVE_ORG_ERRORS";
export const RECEIVE_ORGS = "@org/RECEIVE_ORGS";
export const UPDATE_SETTINGS_CONFIG = "@org/UPDATE_SETTINGS_CONFIG";
export const UPDATE_PREF_CONFIG = "@org/UPDATE_PREF_CONFIG";
export const UPDATE_DOCUMENT_ORDER = "@org/UPDATE_DOCUMENT_ORDER";
export const CLEAR_ORGS = "@org/CLEAR_ORGS";
export const ADD_TO_DOCUMENT_ORDER = "@org/ADD_TO_DOCUMENT_ORDER";
export const UPDATE_FORM = "@org/UPDATE_FORM";
export const ADD_SIGNATURE_RECIPIENTS_INFO = "@org/ADD_SIGNATURE_RECIPIENTS_INFO";
export const UPDATE_SIGNATURE_RECIPIENT_INFO = "@org/UPDATE_SIGNATURE_RECIPIENT_INFO";

export const Actions = {
  setCurrentOrgId: (id: number) => createAction(SET_CURRENT_ORG_ID, { id }),
  setOrg: (org: Partial<IOrgData>) => createAction(SET_ORG, org),
  resetOrg: () => createAction(RESET_ORG, {}),
  fetchOrg: (id: number) => createAction(FETCH_ORG, id),
  receiveOrgError: (id: number, errors: string[]) => createAction(RECEIVE_ORG_ERRORS, { id: id, errors: errors }),
  receiveOrgs: (orgs: IOrgData[]) => createAction(RECEIVE_ORGS, { orgs }),
  updateSettingsConfig: (orgId: number, path: any, value: any) =>
    createAction(UPDATE_SETTINGS_CONFIG, { orgId, path, value }),
  updatePrefConfig: (orgId: number, path: any, value: any) => createAction(UPDATE_PREF_CONFIG, { orgId, path, value }),
  updateDocumentOrder: (docType: DocType, list: List<any>) => createAction(UPDATE_DOCUMENT_ORDER, { docType, list }),
  addToDocumentOrder: (docType: DocType, document: DocumentRecord) =>
    createAction(ADD_TO_DOCUMENT_ORDER, { docType, document }),
  updateForm: (event: any) => createAction(UPDATE_FORM, { event }),
  addSignatureRecipientsInfo: (job: JobRecord, orgId: number) =>
    createAction(ADD_SIGNATURE_RECIPIENTS_INFO, { job, orgId }),
  updateSignatureRecipientInfo: (event: any) => createAction(UPDATE_SIGNATURE_RECIPIENT_INFO, { event }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  getOrg: (
    id: number,
    includes: string[] = orgService.orgIncludes,
    set_current_org = false,
  ): ThunkResult<Promise<IOrgData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchOrg(id));
      if (set_current_org) {
        dispatch(Actions.setCurrentOrgId(id));
      }

      return orgService.load(id, includes).then(
        (org) => {
          dispatch(Actions.setOrg(org));
          return org;
        },
        (errors) => {
          dispatch(Actions.receiveOrgError(id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  listOrgs: (queryParams: QueryParamsRecord = new QueryParamsRecord(), modelName = "org"): ThunkResult<void> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(PagingActions.fetchPage(modelName, queryParams));
      try {
        const response: IOrgIndexResponse = await orgService.list(queryParams);
        dispatch(Actions.receiveOrgs(response.orgs));
        const pageData: Partial<IPageData> = {
          ids: List(response.orgs.map((org: IOrgData) => org.id)),
          pagination: response.meta.pagination,
        };
        dispatch(PagingActions.receivePage(modelName, queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(PagingActions.receivePageError(modelName, queryParams, errors));
      }
    };
  },
  updateOrg: (org: OrgRecord): ThunkResult<Promise<IOrgData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchOrg(org.id));

      return orgService.update(org).then(
        (orgData) => {
          dispatch(Actions.setOrg(orgData));
          dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved organization successfully"));
          return orgData;
        },
        (errors) => {
          dispatch(Actions.receiveOrgError(org.id, errors));
          dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  updateCurrentOrg: (): ThunkResult<Promise<IOrgData>> => {
    return (dispatch: RootDispatchType, getState) => {
      const orgRecord = currentOrg(getState());
      return dispatch(AsyncActions.updateOrg(orgRecord));
    };
  },
  syncIntegration: (orgId: number, process: string, name: string) => {
    return async (dispatch: RootDispatchType) => {
      try {
        await orgService.sync(orgId, {
          process: process,
          integration: name,
        });
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Sync Requested Successfully"));
      } catch (response) {
        dispatch(
          commonActions.Actions.flashAddAlert(
            FlashLevels.danger,
            "There were problems requesting the sync. Make sure all your information is correct. If the problem continues, contact support.",
          ),
        );
      }
    };
  },
  testIntegration: (integration: string): ThunkResult<Promise<{ taskId: string; close: boolean }>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const orgId = currentOrgId(getState());
        const tokenRecord = token(getState(), { kind: integration });
        const response = await orgService.test(orgId, { integration, token: cleanProps(tokenRecord) });
        dispatch(taskActions.Actions.receiveTask(response.task));
        let close = false;
        if (response.task.status === "finished") {
          await dispatch(tokenActions.AsyncActions.updateCreateToken(tokenRecord));
          close = true;
        } else {
          console.error(response.task);
          dispatch(
            commonActions.Actions.flashAddAlert(
              FlashLevels.danger,
              "There was a problem with your settings, review the log and try again. If the problem continues, contact support.",
            ),
          );
        }
        return { taskId: response.task.id, close };
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  removeFromDocumentOrder: (documentId: number) => {
    return (dispatch: RootDispatchType, getState: () => RootState): void => {
      const orgId = currentOrgId(getState());
      ["estimate", "contract", "inspection"].forEach((docType: DocType) => {
        let list = preferencesConfig(getState(), { orgId, path: ["document_order", docType] }) || List();
        list = migrateOldDocs(getState(), list);
        const filteredList = list.filter((item) => {
          if (typeof item === "string") {
            return true;
          }
          return item.get("id") !== documentId;
        });
        dispatch(Actions.updateDocumentOrder(docType, filteredList));
      });

      dispatch(AsyncActions.updateCurrentOrg());
    };
  },
  duplicateOrg: (selectedDataTypes: List<string>, selectedOrgs: List<number>) => {
    return async (dispatch: RootDispatchType, getState: () => RootState): Promise<void> => {
      const sourceOrdId = currentOrgId(getState());

      return new Promise((resolve, reject) => {
        selectedOrgs.forEach(async (orgId, index) => {
          try {
            const { task } = await orgService.duplicate(orgId, sourceOrdId, selectedDataTypes.toJS());

            dispatch(taskActions.Actions.receiveTask(task));
            dispatch(taskActions.AsyncActions.pollTask(task));
            dispatch(
              commonActions.Actions.flashAddAlert(
                FlashLevels.success,
                `Submitted task to duplicate org ${orgId} successfully. Click on the "Tasks" button to see the status.`,
              ),
            );

            if (index === selectedOrgs.size - 1) resolve();
          } catch (errors) {
            handleErrors(errors, dispatch);
            if (index === selectedOrgs.size - 1) resolve();
          }
        });
      });
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
