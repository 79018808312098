import * as ng from "angular";
import { ISession } from "app/src/Common/SessionService";
import { IUser } from "app/src/Models/User";
import { IFlash } from "app/src/Common/FlashService";
import { INavDisplayArgs } from "app/src/Jobs/JobShowCtrl";
import { JobFetcherService } from "app/src/Jobs/JobFetcherService";
import { IJob } from "app/src/Models/Job";
import * as angulartics from "angulartics";
import { TransitionService } from "@uirouter/angularjs";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IAdInfo } from "../Common/AdService";
import { JobShowTourService } from "../Common/Tours/JobShowTourService";
import { JobInfoTourService } from "../Common/Tours/JobInfoTourService";
import { RootStoreType } from "app2/src/store";
import { IRepository } from "app/src/Common/Repository";

export interface IHeaderShowArgs {
  show: boolean;
}

export interface IHeaderSearchArgs {
  searchString?: string;
}

export class HeaderCtrl {
  public job: IJob;
  public user: IUser;
  public search = "";
  public navDisplay = true;
  public showSearchBar = false;
  public isNavCollapsed = true;
  public adUrl: string;
  public testimonial: any;
  public hidden = false;
  public store: RootStoreType;
  public _cleanupListeners: Function[] = [];

  public static $inject = [
    "Session",
    "Flash",
    "JobFetcher",
    "$state",
    "$scope",
    "$rootScope",
    "$location",
    "$timeout",
    "$analytics",
    "$transitions",
    "BaseConfig",
    "AdService",
    "JobShowTourService",
    "JobInfoTourService",
    "Repository",
  ];
  constructor(
    private Session: ISession,
    public Flash: IFlash,
    public JobFetcher: JobFetcherService,
    public $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    public $timeout: ng.ITimeoutService,
    private $analytics: angulartics.IAnalyticsService,
    public $transitions: TransitionService,
    public BaseConfig: IBaseConfig,
    public AdService: IAdInfo,
    public JobShowTourService: JobShowTourService,
    public JobInfoTourService: JobInfoTourService,
    public Repository: IRepository,
  ) {
    this.constructorInit();
    this.user = Session.currentUser;

    this.hidden = this.$state.params["header"] === "false" ? true : false;

    this._cleanupListeners.push(
      $rootScope.$on("auth:login", (event: ng.IAngularEvent, user: IUser) => {
        this.user = user;
      }),
    );

    this._cleanupListeners.push(
      $rootScope.$on("auth:logout", () => {
        this.user = null;
      }),
    );

    this._cleanupListeners.push(
      $rootScope.$on("nav:display", (event: ng.IAngularEvent, args: INavDisplayArgs) => {
        this.navDisplay = args.navDisplay;
      }),
    );

    this._cleanupListeners.push(
      $rootScope.$on("header:search", (event: ng.IAngularEvent, args: IHeaderShowArgs) => {
        this.clearSearchText();
        this.showSearchBar = args.show;
      }),
    );

    // noinspection TypeScriptValidateTypes
    this._cleanupListeners.push(
      $transitions.onStart({}, (transition) => {
        if (!_.isUndefined(transition) && this.Session.isFreemium()) {
          this.AdService.randomAd(transition.entering()[0]["name"], "job_header_url").then((resolve) => {
            this.adUrl = resolve;
          });
          this.AdService.randomTestimonial().then((resolve) => {
            this.testimonial = resolve;
          });
        }
        this.showSearchBar = false;
      }),
    );

    if (!_.isUndefined(this.AdService) && !_.isUndefined(this.$state.current)) {
      this.AdService.randomAd(this.getCurrentStateName(), "job_header_url").then((resolve) => {
        this.adUrl = resolve;
      });

      this.AdService.randomTestimonial().then((resolve) => {
        this.testimonial = resolve;
      });
    }

    this._cleanupListeners.push(
      $rootScope.$on("header:search_update", (event: ng.IAngularEvent, args: IHeaderSearchArgs) => {
        this.search = args.searchString;
      }),
    );

    $scope.$on("$destroy", () => {
      _.each(this._cleanupListeners, (func: () => void) => {
        if (_.isFunction(func)) {
          func();
        }
      });
    });

    this.refreshQuery = this.refreshQuery.bind(this);
  }

  public constructorInit() {}

  public getCurrentStateName(): string {
    return this.$state.current.name.replace(/\./g, "_");
  }

  public isCurrentPage(page: string): boolean {
    return this.$location.path() === page;
  }

  public isCurrentState(name: string): boolean {
    return this.$state.current.name === name;
  }

  public help(type) {
    switch (type) {
      case "estimator":
        this.$analytics.eventTrack("estimate tour requested", {
          category: "Job Header",
          navDisplay: !this.navDisplay,
        });
        this.$rootScope.$broadcast("header:help:estimator");
        break;
      case "job_info":
        this.$analytics.eventTrack("job tour requested", {
          category: "Job Header",
          navDisplay: !this.navDisplay,
        });
        this.JobInfoTourService.help().then(() => {
          this.$rootScope.$broadcast("header:help:job_info");
          this.JobShowTourService.help();
        });
        break;
    }
  }

  public searchChanged() {
    this.$rootScope.$broadcast("header:search_change", <IHeaderSearchArgs>{ searchString: this.search });
  }

  public clearSearchText() {
    this.search = "";
  }

  public refreshQuery() {
    this.$rootScope.$broadcast("updateJobList");
  }
}
