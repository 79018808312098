import * as React from "react";
import { connect } from "app2/src/connect";
import { history } from "app2/src/store";
import { ConnectedRouter } from "connected-react-router/immutable";

const connector = connect(null, null);

interface ConnectorProps {}

interface ConnectorState {}

class Connector extends React.Component<ConnectorProps, ConnectorState> {
  constructor(props: ConnectorProps) {
    super(props);
  }

  public render() {
    return <ConnectedRouter history={history}></ConnectedRouter>;
  }
}

export default connector(Connector);
