import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IProduct } from "app/src/Models/Product";
import { IActivatedPriceList, IActivatedPriceListResource } from "app/src/Models/ActivatedPriceList";
import { IFlash } from "app/src/Common/FlashService";
import { IMeasurementLinkResponse, IMeasurementLinkResource } from "app/src/Models/MeasurementLink";
import { IPretty } from "app/src/Common/PrettyNameService";
import * as angulartics from "angulartics";
import { IBaseConfig } from "../../Common/IBaseConfig";

export class OrgMeasurementLinksTabCtrl extends BaseOrgTabCtrl {
  public priceListPromise: ng.IPromise<any>;
  public priceList: IActivatedPriceList;
  public currentSelection: IProduct[];
  public rootProducts: IProduct[];
  public parent: IProduct;
  public search: { name: string } = { name: "" };
  public measurement: { string: string } = {} as { string: string };
  public links: IMeasurementLinkResponse;

  public static $inject = [
    "OrgFetcher",
    "ActivatedPriceList",
    "MeasurementLink",
    "Flash",
    "BaseConfig",
    "$timeout",
    "$http",
    "$stateParams",
    "$window",
    "$uibModal",
    "Pretty",
    "$analytics",
  ];
  constructor(
    OrgFetcher: IOrgFetcherService,
    public ActivatedPriceList: IActivatedPriceListResource,
    public MeasurementLink: IMeasurementLinkResource,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
    public $timeout: ng.ITimeoutService,
    public $http: ng.IHttpService,
    public $stateParams: ng.ui.IStateParamsService,
    public $window: ng.IWindowService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public Pretty: IPretty,
    protected $analytics: angulartics.IAnalyticsService,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    OrgFetcher.orgPromise.then(() => {
      this.priceListPromise = this.ActivatedPriceList.fetchCached(this.org.id, this.org.activated_price_list.id).then(
        (pl: IActivatedPriceList) => {
          this.priceList = pl;
          this.currentSelection = this.priceList.products;
          this.rootProducts = this.priceList.products;
          this.links = <IMeasurementLinkResponse>this.MeasurementLink.query({ org_id: this.org.id });
          this.links.$promise.then(() => {
            this.matchLinks();
          });
          return pl;
        },
      );
    });
  }

  public makeSelection(product: IProduct) {
    if (product.children.length <= 0) {
    } else {
      this.clearSearchText();
      product.parent = this.parent;
      this.currentSelection = product.children;
      this.parent = product;
      this.matchLinks();
    }
  }

  public matchLinks() {
    _.each(this.currentSelection, (p) => {
      const link = _.find(this.links.measurement_links, (ml) => {
        return ml.item_uuid === p.uuid;
      });
      if (_.isUndefined(link)) {
        if (!_.isUndefined(this.parent)) {
          p.measurement_link = _.clone(this.parent.measurement_link);
          if (!_.isUndefined(p.measurement_link)) {
            p.measurement_link.inherited = true;
          }
        }
      } else {
        p.measurement_link = link;
        p.measurement_link.inherited = false;
      }
    });
  }

  public back() {
    this.trackEvent("measurement_link_back", {
      category: "OrgMeasurementLinks",
    });
    this.clearSearchText();
    if (!this.parent) {
      return;
    }

    if (!this.parent.parent) {
      this.parent = null;
      this.currentSelection = this.rootProducts;
      return;
    }

    this.parent = this.parent.parent;
    this.currentSelection = this.parent.children;
  }

  public clearSearchText() {
    this.search.name = "";
  }

  public editMeasurementLink(product: IProduct) {
    this.trackEvent("edit_measurement_link", {
      category: "OrgMeasurementLinks",
      productId: product.id,
    });
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        animation: true,
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        controller: "OrgMeasurementLinkEditCtrl",
        controllerAs: "ctrl",
        size: "xl",
        templateUrl: "src/Orgs/tabs/measurement_link_editor.html",

        resolve: {
          product: product,
          org_id: this.org.id,
        },
      })
      .result.then((respObj) => {
        if (_.isObject(respObj)) {
          const link = _.find(this.links.measurement_links, (ml) => {
            return ml.id === respObj.id;
          });
          if (_.isUndefined(link)) {
            this.links.measurement_links.push(respObj);
          } else {
            link.measure_value.measurement = respObj.measure_value.measurement;
          }
        }
      });
  }

  public stringOrNumber(measurement: string) {
    if (_.isUndefined(measurement)) {
      return false;
    }
    return measurement.match(/^[0-9]+$/) != null;
  }

  public removeMeasurementLink(product: IProduct) {
    this.trackEvent("remove_measurement_link", {
      category: "OrgMeasurementLinks",
      productId: product.id,
    });
    product.measurement_link.$delete().then(() => {
      this.links.measurement_links = _.filter(this.links.measurement_links, (ml) => {
        return product.measurement_link.id !== ml.id;
      });
      product.measurement_link = undefined;
      product.measurement_link = product.parent.measurement_link;
      if (!_.isUndefined(product.measurement_link)) {
        product.measurement_link.inherited = true;
      }
    });
  }
}
