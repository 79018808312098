import * as React from "react";
import { Card } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import * as FontAwesome from "react-fontawesome";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import track from "react-tracking";
import { presentation } from "app2/src/selectors/presentation.selectors";
import * as presentationActions from "app2/src/reducers/presentation.actions";
import * as config from "react-global-configuration";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { queryParams } from "app2/src/selectors/pagination.selectors";
import { currentAccessUid } from "app2/src/reducers/auth.selectors";
import { addAuthUser } from "app2/src/components/Common/CustomLink";

const mapStateToProps = (state: RootState, ownProps: PresentationProps) => {
  return {
    presentation: presentation(state, { presentationId: ownProps.presentationId }),
    jobId: currentJobId(state),
    folderParentId: queryParams(state, { modelName: "folder" }).getIn(["folder_id"]),
    accessUid: currentAccessUid(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: PresentationProps) => {
  return {
    setCurrentPresentationId: () =>
      dispatch(presentationActions.Actions.setCurrentPresentationId(ownProps.presentationId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PresentationState {}

interface PresentationProps {
  presentationId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentationProps;

@track((props) => {
  return {
    component: "Presentation",
    presentation: props.presentationId,
  };
})
class Presentation extends React.Component<Props, PresentationState> {
  public componentRef: any;
  public confirm: any;
  constructor(props: Props) {
    super(props);

    this.present = this.present.bind(this);
  }

  @track(() => ({
    action: "Show Presentation",
  }))
  public present(): void {
    const { presentation, setCurrentPresentationId, jobId, folderParentId, accessUid } = this.props;
    if (presentation.target === "blank") {
      let url = presentation.url;
      if (url.startsWith("ingage://applet/")) {
        const route =
          `/jobs/${jobId}/presentations` +
          (_.isNullOrUndefined(folderParentId) ? "" : `?folder_parent_id=${folderParentId}`);
        const callbackUrl = config.get("APP_URL") + addAuthUser(route, accessUid);

        url = url.concat("?callback-url=" + encodeURIComponent(callbackUrl));
      }
      window.open(url, "_blank");
    } else {
      setCurrentPresentationId();
    }
  }

  public render(): React.ReactNode {
    const { presentation } = this.props;
    let coverImageUrl = "/assets/images/icons-large/presentation_placeholder.8046bc02.png";
    if (presentation?.cover_image?.medium?.url) {
      coverImageUrl = presentation.cover_image.medium.url;
    }

    return (
      <Card className="folder-card" data-testid="presentation-card" onClick={this.present}>
        <div className="card-title-image">
          <img className="img-contain" data-testid="cover_image" src={coverImageUrl} />
        </div>

        <Card.Footer>
          <p className="mb-0">
            {presentation?.name}
            <FontAwesome name="arrow-right" className="float-right fa-gray" />
          </p>
        </Card.Footer>
      </Card>
    );
  }
}

export default connector(Presentation);
