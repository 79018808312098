import { FolderRecord } from "app2/src/records/Folder";
import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";

export const folder = (state: RootState, props: any): FolderRecord => state.getIn(["folders", "byId", props.folderId]);
export const lastSavedFolder = (state: RootState, props: any): FolderRecord =>
  state.getIn(["folders", "lastSavedById", props.folderId]);

export const ancestors = createSelector(
  [folder, (state: RootState, props: any) => ({ state, props })],
  (childFolder, stateProps: any): List<FolderRecord> => {
    const { state, props } = stateProps;
    let folders = List();
    if (!childFolder) {
      return folders;
    }

    if (childFolder.parent_id) {
      folders = folders.concat(ancestors(state, { ...props, folderId: childFolder.parent_id }));
    }
    folders = folders.push(childFolder);
    return folders;
  },
);

export const folderChanged = createSelector(
  [folder, lastSavedFolder],
  (folder: FolderRecord, lastSavedFolder: FolderRecord) => {
    if (!lastSavedFolder) {
      return true;
    }
    if (folder.equals(lastSavedFolder)) {
      return false;
    } else {
      return true;
    }
  },
);
