import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { token, tokenChanged } from "app2/src/selectors/token.selectors";
import * as tokenActions from "app2/src/reducers/token.actions";
import { TokenRecord } from "app2/src/records/Token";
import { RsfForm, FormControl } from "@tberrysoln/rsf-form";
import { required } from "app2/src/helpers/FinalFormValidator";

const mapStateToProps = (state: RootState, ownProps: GreenSkyProps) => {
  const integrationName = "green_sky";

  return {
    greenSkyToken: token(state, { kind: integrationName }) as TokenRecord,
    dirty: tokenChanged(state, { kind: integrationName }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: GreenSkyProps) => {
  return {
    getGreenSkyToken: () => dispatch(tokenActions.AsyncActions.getToken(ownProps.orgId, "green_sky")),
    updateCreateSRToken: (newToken: TokenRecord) => dispatch(tokenActions.AsyncActions.updateCreateToken(newToken)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface GreenSkyProps {
  orgId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & GreenSkyProps;

class GreenSky extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getGreenSkyToken();
  }

  public render() {
    const integrationName = "green_sky";
    const { orgId, dirty, greenSkyToken, updateCreateSRToken } = this.props;
    const integrationPath = ["tokens", "byOrgId", orgId, integrationName, "data"];

    return (
      <div className="form-section">
        <div className="form-section-content">
          <RsfForm
            rootPath={integrationPath}
            updateFormReducer={tokenActions.Actions.update}
            onSubmit={() => updateCreateSRToken(greenSkyToken)}>
            <Row className="d-flex align-items-center">
              <Col sm={4} className="d-flex">
                <img
                  className="img-fluid self-align-center integration-logo"
                  src="/assets/images/greensky.39c8126d.png"
                  title="GreenSky"
                />
              </Col>
              <Col sm={4}>
                <FormControl label="Dealer ID" name="dealer_id" validate={required} />
              </Col>
              <Col sm={4}>
                <Button type="submit" variant="default" disabled={!dirty}>
                  Apply
                </Button>
              </Col>
            </Row>
          </RsfForm>
        </div>
      </div>
    );
  }
}

export default connector(GreenSky);
