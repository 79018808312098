import { AddressRecord } from "app2/src/records/Address";
import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { JobQueryRecord } from "../components/JobList/JobQuery";
import { recordChanged } from "../helpers/Record";
import { JobRecord } from "../records/Job";

export const job = (state: RootState, props: any) => state.getIn(["jobs", "byId", props.jobId]);
export const currentJob = (state: RootState, _props?: any) => state.getIn(["jobs", "byId", currentJobId(state)]);
export const currentJobName = (state: RootState) => state.getIn(["jobs", "byId", currentJobId(state), "name"]);
export const currentJobOrgId = (state: RootState, _props?: any) =>
  state.getIn(["jobs", "byId", currentJobId(state), "org_id"], 0);
export const currentJobId = (state: RootState, _props?: any) => state.getIn(["jobs", "currentJobId"]);

/**
 * Loads the current job address
 *
 * @param {RootState} state The RootState
 * @returns {AddressRecord} AddressRecord
 */
export const currentJobAddress = (state: RootState): AddressRecord => currentJob(state).get("address");

export const jobLoading = (state: RootState, _props?: any) =>
  state.getIn(["jobs", "byId", currentJobId(state), "loading"]);
export const resourceDocuments = (state: RootState, _props?: any) =>
  state.getIn(["jobs", "byId", currentJobId(state), "config", "resource_documents"], List());
export const selectedEstimateId = (state: RootState) => state.getIn(["jobs", "selectedEstimateId"]);
/**
 * Loads the current job query
 *
 * @param {RootState} state The RootState
 * @returns {JobQueryRecord} JobQueryRecord
 */
export const jobQuery = (state: RootState): JobQueryRecord => state.getIn(["jobs", "jobQuery"]);
/**
 * Loads the previously submitted job query
 *
 * @param {RootState} state The RootState
 * @returns {JobQueryRecord} JobQueryRecord
 */
export const lastSavedJobQuery = (state: RootState): JobQueryRecord => state.getIn(["jobs", "lastSavedJobQuery"]);
/**
 * Loads the fields changed since the job filter menu was last opened
 *
 * @param {RootState} state The RootState
 * @returns {string[]} string[]
 */
export const lastChangesJobQuery = (state: RootState): string[] =>
  Object.keys(state.getIn(["jobs", "lastChangesJobQuery"]).toJS());
/**
 * Returns true if the previously submitted job query is different than the current job query
 *
 * @param {RootState} state The RootState
 * @returns {boolean} boolean
 */
export const jobQueryChanged = createSelector(
  [jobQuery, lastSavedJobQuery],
  (presentation: JobQueryRecord, lastSavedPresentation: JobQueryRecord) => {
    return recordChanged(presentation, lastSavedPresentation);
  },
);
export const jobStates = (state: RootState, props: any) => state.getIn(["jobs", "jobStates"], List());
export const jobDocumentCount = (state: RootState, props: any) =>
  state.getIn(["jobs", "byId", props.jobId, "document_count"]);
export const jobImageCount = (state: RootState, props: any) =>
  state.getIn(["jobs", "byId", props.jobId, "image_count"]);
export const jobResourceIds = (state: RootState, _props?: any) => resourceDocuments(state).map((doc) => doc.get("id"));
export const jobStats = (state: RootState) => state.getIn(["jobs", "stats"]);
export const sampleJobs = (state: RootState) => state.getIn(["jobs", "sampleJobs"]);

/**
 * Checks if imageUuid passed in as a prop is a cover image on the currentJob
 *
 * @param {RootState} state The RootState
 * @param {{ imageUuid: string }} options
 * @returns {boolean} boolean
 */
export const coverImage = (state: RootState, props: any) =>
  currentJob(state, props).get("cover_image_uuid") === props.imageUuid;

/**
 * Gets the job's assigned user id
 *
 * @param {RootState} state The RootState
 * @param {{jobId: number }} options
 * @returns user id
 */
export const jobAssignedUserId = createSelector(
  [job],
  (job: JobRecord): number => job?.getIn(["assignments", 0, "user", "id"]),
);
