import { IEstimateTemplate, IEstimateTemplateResponse } from "app/src/Models/EstimateTemplate";
import { SortableOptions } from "../Common/SortableOptions";
import { IPretty } from "app/src/Common/PrettyNameService";

export class TemplateEditorCtrl {
  public name: string;
  public templateName = "";
  public sortableOptions = new SortableOptions();

  public static $inject = ["$uibModalInstance", "templates", "Pretty"];
  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public templates: IEstimateTemplateResponse,
    public Pretty: IPretty,
  ) {
    this.templateName = _.toTitleCase(this.Pretty.name["template"]);

    this.sortableOptions.stop = () => {
      _.each(this.templates.estimate_templates, (et: IEstimateTemplate, index: number) => {
        if (et.sort_order !== index) {
          et.sort_order = index;
          et.$update();
        }
      });
      this.templates.estimate_templates = _.sortBy(this.templates.estimate_templates, "sort_order");
    };
  }

  public removeTemplate(template) {
    template.$delete().then(() => {
      this.templates.estimate_templates = _.filter(this.templates.estimate_templates, (t) => {
        return t.id !== template.id;
      });
    });
  }

  public updateName(template: IEstimateTemplate) {
    template.$update();
  }

  public editTemplate(template: IEstimateTemplate) {
    this.$uibModalInstance.close(template);
  }

  public save() {
    this.$uibModalInstance.close(this.name);
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }
}
