import * as React from "react";
import { PresentationTemplateRecord } from "app2/src/records/PresentationTemplate";
import { Form, Row, Col } from "react-bootstrap";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";

export interface IManagementItemProps {
  presentationTemplate: PresentationTemplateRecord;
  update: (pt: PresentationTemplateRecord) => void;
  updateName: (pt: PresentationTemplateRecord) => void;
  remove: (pt: PresentationTemplateRecord) => void;
  tracking?: TrackingProp;
}

const track: Track<TrackingData, IManagementItemProps> = _track;

export interface IManagementItemState {
  presentationTemplate: PresentationTemplateRecord;
  editing: boolean;
}

@track(
  //@ts-ignore
  (props: IManagementItemProps) => {
    return {
      presentationTemplate: props.presentationTemplate.id,
    };
  },
  { dispatchOnMount: false },
)
export default class ManagementItem extends React.Component<IManagementItemProps, IManagementItemState> {
  public state = {
    presentationTemplate: this.props.presentationTemplate,
    editing: false,
  };

  constructor(props) {
    super(props);

    this.startEditName = this.startEditName.bind(this);
  }

  public componentDidUpdate(prevProps: IManagementItemProps, prevState: IManagementItemState) {
    if (prevProps.presentationTemplate.id !== this.props.presentationTemplate.id) {
      this.setState({
        presentationTemplate: this.props.presentationTemplate,
      });
    }
  }

  public render() {
    const { editing, presentationTemplate } = this.state;
    return (
      <tr>
        <td>
          <Row className="align-items-center">
            <Col sm={4} lg={2}>
              {!editing && <i className="rsf-edit-link rsf-base-66 action-button" onClick={this.startEditName}></i>}
              &nbsp;&nbsp;
              {editing && <i className="rsf-save-link rsf-base-66 action-button" onClick={this.updateName}></i>}
              &nbsp;&nbsp;
            </Col>
            <Col sm={8} lg={10}>
              {!editing && presentationTemplate.name}
              {editing && <Form.Control type="text" value={presentationTemplate.name} onChange={this.editName} />}
            </Col>
          </Row>
        </td>
        <td>
          <ConfirmDialog title={`Are you sure you want to update template: ${presentationTemplate.name}?`}>
            {(confirm) => (
              <i
                className="rsf-save-link rsf-base-66 action-button"
                onClick={(event) => this.confirmUpdate(event, confirm)}></i>
            )}
          </ConfirmDialog>
          <ConfirmDialog title={`Are you sure you want to delete template: ${presentationTemplate.name}?`}>
            {(confirm) => (
              <i
                className="rsf-delete-link rsf-base-66 action-button"
                onClick={(event) => this.confirmDelete(event, confirm)}></i>
            )}
          </ConfirmDialog>
        </td>
      </tr>
    );
  }

  @track({ action: "Start Name Edit" })
  public startEditName() {
    this.setState({
      editing: true,
    });
  }

  public editName = (e) => {
    const { presentationTemplate } = this.state;

    this.setState({
      presentationTemplate: presentationTemplate.set("name", e.target.value),
    });
  };

  public updateName = () => {
    const { updateName } = this.props;
    const { presentationTemplate } = this.state;
    this.setState({
      editing: false,
    });

    updateName(presentationTemplate);
  };

  public confirmDelete(event, confirm: (arg: any) => any) {
    const { tracking, presentationTemplate } = this.props;

    tracking.trackEvent({
      action: "Start Delete Presentation Template",
      presentationTemplate: presentationTemplate.id,
    });

    confirm(this.remove)(event);
  }

  public confirmUpdate(event, confirm: (arg: any) => any) {
    const { tracking, presentationTemplate } = this.props;

    tracking.trackEvent({
      action: "Start Full Presentation Template Update",
      presentationTemplate: presentationTemplate.id,
    });

    confirm(this.updateTemplate)(event);
  }

  public remove = () => {
    const { remove } = this.props;
    const { presentationTemplate } = this.state;

    remove(presentationTemplate);
  };

  public updateTemplate = () => {
    const { update } = this.props;
    const { presentationTemplate } = this.state;

    update(presentationTemplate);
  };
}
