import * as React from "react";
import { Record, List } from "immutable";
import { noop } from "underscore";
import { JobRecord } from "../../records/Job";

interface ContextProps {
  requestName: string; //
  documentIds: List<number>; //
  job: JobRecord; //
  orgId: number; //
  dispatch: SignatureDispatch; //
}

export type SignatureAction =
  | "onDragEnd"
  | "addDocumentId"
  | "removeDocumentId"
  | "setRequestName"
  | "combineDocuments"
  | "close"
  | "validate"
  | "back"
  | "modifyDoc"
  | "handleSubmit"
  | "spinner";
export type SignatureDispatch = (action: SignatureAction, payload?: any) => void;

const defaultContextProps: ContextProps = {
  requestName: "",
  documentIds: List<number>(),
  orgId: 0,
  job: null,
  dispatch: noop,
};

export class ContextRecord extends Record(defaultContextProps) implements ContextProps {}

export const Context = React.createContext(new ContextRecord());
