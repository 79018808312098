import { ICoupon } from "../Models/Coupon";
import couponTemplates, { ICouponTemplate } from "./CouponTemplates";
import { IAccount } from "../Models/Account";
import { IRepository } from "../../Common/Repository";
import { ISku } from "../Models/Sku";

export class CouponEditModalCtrl {
  public durationTypes: string[] = ["forever", "once", "repeating"];
  public dateOptions: ng.ui.bootstrap.IDatepickerConfig = {
    formatYear: "yy",
    minDate: new Date(),
    startingDay: 1,
  };
  public selectedTemplate: ICouponTemplate;
  public templates: ICouponTemplate[] = couponTemplates;
  public skuResponse: ng.resource.IResourceArray<ISku>;

  public static $inject = ["$uibModalInstance", "Repository", "coupon", "account"];
  constructor(
    protected $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    protected Repository: IRepository,
    public coupon: ICoupon,
    protected account: IAccount,
  ) {
    if (!coupon.id) {
      this.selectedTemplate = couponTemplates[0];
      this.applyTemplate();
    }

    this.skuResponse = Repository.Sku.query();
  }

  public save() {
    this.$uibModalInstance.close(this.coupon);
  }

  public cancel() {
    this.$uibModalInstance.dismiss("closed");
  }

  public applyTemplate() {
    this.coupon.applyTemplate(this.selectedTemplate, { account_id: this.account.id });
  }
}
