import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IDeckData>): DeckRecord => {
  const recordData: IDeckRecord = { ...(json as any) };

  return new DeckRecord(recordData);
};

export interface IDeckData {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  area: number;
  perimeter: number;
  wall_length: number;
  railing_length: number;
  railing_area: number;
  metadata: any;
  _destroy?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface IDeckRecord {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  area: number;
  perimeter: number;
  wall_length: number;
  railing_length: number;
  railing_area: number;
  metadata: any;

  _destroy: Nullable<boolean>;
  created_at: Date;
  updated_at: Date;
}

export const defaultDeckProps: IDeckRecord = {
  id: 0,
  name: "",
  designator: "",
  area: 0,
  perimeter: 0,
  wall_length: 0,
  railing_length: 0,
  railing_area: 0,
  metadata: {},

  _destroy: null,
  created_at: new Date(),
  updated_at: new Date(),
};

export class DeckRecord extends Record(defaultDeckProps) implements IDeckRecord {
  public readonly id!: number;
  public readonly name!: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  public readonly designator!: string;
  public readonly area!: number;
  public readonly perimeter!: number;
  public readonly wall_length!: number;
  public readonly railing_length!: number;
  public readonly railing_area!: number;
  public readonly metadata!: any;

  public readonly _destroy!: Nullable<boolean>;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IDeckRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
