import { fromJS, Record, List, Map } from "immutable";
import { MetaPaginationRecord, IMetaPaginationData } from "app2/src/records/MetaPagination";
import { Nullable } from "app2/src/records";

export const fromJSON = (json: Partial<IPageData>): PageRecord => {
  const record: IPageRecord = { ...(json as any) };

  if (record.pagination) {
    record.pagination = new MetaPaginationRecord(record.pagination);
  }

  return new PageRecord(record);
};

export interface IPageData {
  ids: List<number | string>;
  pagination: IMetaPaginationData;
  loading?: boolean;
  errors?: string[];
}

export interface IPageRecord {
  ids: List<number>;
  pagination: MetaPaginationRecord;
  loading: boolean;
  errors: string[];
}

const defaultPageProps: IPageRecord = {
  ids: List(),
  pagination: new MetaPaginationRecord(),
  loading: false,
  errors: null,
};

export class PageRecord extends Record(defaultPageProps) implements IPageRecord {
  public readonly ids!: List<number>;
  public readonly pagination: MetaPaginationRecord;
  public readonly loading: boolean;
  public readonly errors: string[];

  public constructor(values?: Partial<IPageRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}

export interface IQueryParamsData {
  page: number;
  per_page: number;
  filter: string;
  query: string;
  folder_id?: Nullable<number>;
  parameters: { [key: string]: string | string[] | Date | number };
}

export const queryParamsFromJSON = (data: Partial<IQueryParamsData>): QueryParamsRecord => {
  const record: IQueryParamsRecord = { ...(data as any) };
  if (data.parameters) {
    record.parameters = fromJS(data.parameters);
  }

  return new QueryParamsRecord(record);
};

export interface IQueryParamsRecord {
  page: number;
  per_page: number;
  filter: string;
  query: string;
  folder_id?: Nullable<number>;
  parameters: Map<string, string | List<string> | Date>;
}

const defaultQueryParams: IQueryParamsRecord = {
  page: 1,
  per_page: 25,
  filter: "",
  query: "",
  folder_id: null,
  parameters: Map({}),
};

export class QueryParamsRecord extends Record(defaultQueryParams) implements IQueryParamsRecord {}

export const emptyQueryParamsRecord = queryParamsFromJSON({} as any);
