import { ActionsUnion, createAction } from "./Utils";
import { IElevationData } from "../records/Elevation";
import { RootState } from "app2/src/reducers";
import { ThunkAction } from "redux-thunk";
import { RootDispatchType } from "app2/src/store";
import { handleErrors } from "app2/src/reducers/Utils";
import { list } from "app2/src/api/elevation.service";
import { currentMeasurementId } from "app2/src/selectors/measurementCommon.selectors";

// SINGLE
export const RECEIVE_ELEVATION = "@elevations/RECEIVE_ELEVATION";
// MULTIPLE
export const FETCH_ELEVATIONS = "@elevations/FETCH_ELEVATIONS";
export const RECEIVE_ELEVATIONS = "@elevations/RECEIVE_ELEVATIONS";
export const SET_ELEVATIONS_LOADED = "@elevations/SET_ELEVATIONS_LOADED";

export const Actions = {
  // SINGLE
  receiveElevation: (elevation: Partial<IElevationData>) => createAction(RECEIVE_ELEVATION, { elevation }),
  // MULTIPLE
  fetchElevations: (measurementId: number) => createAction(FETCH_ELEVATIONS, { measurementId }),
  receiveElevations: (measurementId: number, elevations: Partial<IElevationData[]>) =>
    createAction(RECEIVE_ELEVATIONS, { measurementId, elevations }),
  setElevationsLoaded: (measurementId: number) => createAction(SET_ELEVATIONS_LOADED, { measurementId }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  listElevationsByMeasurement: (): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const measurementId = currentMeasurementId(getState(), {});
      dispatch(Actions.fetchElevations(measurementId));

      try {
        const response = await list(measurementId);

        dispatch(Actions.receiveElevations(measurementId, response.elevations));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setElevationsLoaded(measurementId));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
