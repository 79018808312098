import { ILocationData } from "app2/src/records/Location";
import { ActionsUnion, createAction } from "./Utils";

export const LOCATION_CHANGE = "@routers/LOCATION_CHANGE";

export const Actions = {
  locationChange: (action: string, location: Partial<ILocationData>) =>
    createAction(LOCATION_CHANGE, { location, action }),
};

export type Actions = ActionsUnion<typeof Actions>;

export const push = _.partial(Actions.locationChange, "push");
export const replace = _.partial(Actions.locationChange, "replace");

export const routerActions = { push, replace };
