import * as React from "react";
import { Card, Col, Row, RowProps } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SearchBox } from "../SearchBox";
import { List, Map } from "immutable";
import { useSelector } from "react-redux";
import {
  currentPage,
  getPaginationByModel,
  pageRecord,
  pagination,
  queryParams,
} from "app2/src/selectors/pagination.selectors";
import { RootState } from "app2/src/reducers";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { AsyncActions as orgAsyncActions } from "app2/src/reducers/org.actions";
import { StandardPagination } from "../../Pagination/Standard";
import { QueryParamsRecord } from "app2/src/records/Page";
import Spinner from "app2/src/components/SpinnerComponent";

export interface OrgListProps {
  containerProps?: RowProps;
  statusFilters?: string[];
  HeaderComponent: React.FC<any>;
  RowComponent: React.FC<any>;
  rowClickHandler: (orgId: number) => void;
  headerTitle?: string;
}

export const OrgList: React.FC<OrgListProps> = ({
  containerProps,
  HeaderComponent,
  RowComponent,
  statusFilters,
  rowClickHandler,
  headerTitle,
}) => {
  const dispatch = useDispatch();

  const modelName = "org";
  const orgs: List<OrgRecord> = useSelector((state: RootState) =>
    getPaginationByModel(state, { path: ["orgs", "orgsById"], modelName }),
  );
  const queryParamsRecord: QueryParamsRecord = useSelector((state: RootState) => queryParams(state, { modelName }));
  const currentPageRecord = useSelector((state: RootState) => {
    const page = currentPage(state, { modelName });
    return pageRecord(state, { modelName, page });
  });
  const paginationRecord = useSelector((state: RootState) =>
    pagination(state, { modelName, page: queryParamsRecord.get("page") }),
  );

  const [query, setQuery] = React.useState("");

  const queryOrgs = (page: number) => {
    const record = queryParamsRecord.merge({
      parameters: Map<string, string | List<string>>([
        ["query", query],
        ["status[]", List(statusFilters)],
      ]),
      page: page,
      per_page: 15,
    });

    dispatch(orgAsyncActions.listOrgs(record));
  };

  React.useEffect(() => {
    queryOrgs(1);
  }, [query, statusFilters?.length]);

  return (
    <Row {...(containerProps || {})}>
      <Col>
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              {headerTitle && <h4 className="mb-0">{headerTitle}</h4>}
              <div style={{ maxWidth: "300px" }}>
                <SearchBox query={query} searching={setQuery} placeholder={"Search..."} />
              </div>
            </div>
          </Card.Header>
          {orgs.size > 0 ? (
            <Card.Body className="p-0 max-height-75vh">
              <HeaderComponent />
              {orgs?.map((org, index) => {
                return <RowComponent org={org} key={index} rowClickHandler={rowClickHandler} />;
              })}
            </Card.Body>
          ) : (
            <Card.Body style={{ minHeight: "200px" }}>
              {currentPageRecord?.get("loading") ? <Spinner inline localProperty /> : <div>No results</div>}
            </Card.Body>
          )}
          <Card.Footer>
            <StandardPagination metadata={paginationRecord} pageChanged={queryOrgs} />
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};
