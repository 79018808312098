import { IBaseConfig } from "app/src/Common/IBaseConfig";
import * as L from "leaflet";

class LeafletMapCtrl implements ng.IComponentController {
  [s: string]: any;
  public mapData: any;
  public mapDataChanged: (params: any) => void;
  public myMap: any;
  public marker: any;
  public icon: any;

  public static $inject = ["$timeout", "BaseConfig"];

  constructor(
    public $timeout: ng.ITimeoutService,
    public BaseConfig: IBaseConfig,
  ) {
    this.$timeout(() => {
      this.initMap();
    });
  }

  public initMap() {
    if (this.mapData) {
      this.myMap = L.map("mapid", <L.MapOptions>{ measureControl: false, attributionControl: false }).setView(
        [this.mapData.lat, this.mapData.lng],
        this.mapData.zoom,
      );

      const nearmap_attribution = L.control.attribution({ prefix: false });
      nearmap_attribution.addAttribution("Imagery &copy; NearMap");
      this.myMap.addControl(nearmap_attribution);

      // Nearmap.com Tiles
      const nmMap = L.tileLayer(
        "https://{rc}{number}.nearmap.com/maps?x={x}&y={y}&z={z}&nml={nml}&version={version}&httpauth={httpauth}&apikey={apikey}",
        {
          maxZoom: 25,
          rc: "us",
          number: "0",
          nml: "v",
          version: 2,
          httpauth: false,
          apikey: this.BaseConfig.NEARMAP_API_KEY,
        } as any,
      );
      nmMap.addTo(this.myMap);

      const googleRoadonly = (L.gridLayer as any).googleMutant({
        type: "roadmap",
        styles: [
          { featureType: "geometry", stylers: [{ visibility: "off" }] },
          { featureType: "road", stylers: [{ visibility: "on" }] },
          {
            featureType: "administrative.land_parcel",
            elementType: "geometry.stroke",
            stylers: [{ visibility: "on" }],
          },
        ],
      });
      googleRoadonly.addTo(this.myMap);

      const Icon = L.Icon.extend({
        options: {
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          tooltipAnchor: [16, -28],
          shadowSize: [41, 41],
        },
      });
      this.icon = new (Icon as any)({
        iconUrl: "/assets/images/marker-icon.2273e3d8.png",
        shadowUrl: "/assets/images/marker-shadow.44a526ee.png",
      });

      this.setMarker();
    }
  }

  public $onChanges() {
    if (this.myMap) {
      this.moveMap();
    }
  }

  public moveMap() {
    this.myMap.setView([this.mapData.lat, this.mapData.lng], this.mapData.zoom);
    this.myMap.removeLayer(this.marker);
    this.setMarker();
  }

  public setMarker() {
    this.marker = L.marker([this.mapData.lat, this.mapData.lng], { icon: this.icon, draggable: true }).addTo(
      this.myMap,
    );
    this.marker.on("moveend", () => {
      const place = this.marker.getLatLng();
      this.mapDataChanged({ mapData: { lat: place.lat, lng: place.lng } });
    });
  }
}

export class LeafletMapComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/LeafletMap/leaflet_map_component.html";
  public bindings: any = {
    mapData: "<",
    mapDataChanged: "&",
  };

  constructor() {
    this.controller = LeafletMapCtrl;
  }
}
