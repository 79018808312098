import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { List, getIn } from "immutable";
import { EventResultRecord } from "../records/EventResult";

const model = "eventResults";
export const eventResultsById = (state: RootState) => state.getIn([model, "byId"]);

export const orgEventResultIds = (state: RootState, props: { orgId: number }) =>
  getIn(state, [model, "byOrgId", props.orgId], List());

export const getOrgEventResults = createSelector(
  [eventResultsById, orgEventResultIds, (state: RootState, props: any) => ({ state, props })],
  (eventResultsById: Map<number, EventResultRecord>, orgEventResultIds: List<number>, stateProps) => {
    const { props } = stateProps;
    let { kind } = props;

    if (!kind) kind = "default";

    return orgEventResultIds.map((id) => eventResultsById.get(id)).filter((result) => result.kind === kind);
  },
);

export const eventResult = (state: RootState, props: { eventsResultId: number }): EventResultRecord =>
  state.getIn([model, "byId", props.eventsResultId]);
