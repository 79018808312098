import { useCallback, useEffect, useState } from "react";
import * as Video from "twilio-video";
import * as VideoProcessor from "@twilio/video-processors";
import { LocalVideoTrack, LocalAudioTrack, CreateLocalTrackOptions } from "twilio-video";
import { BlurTypes, LocalStorageKeys } from "../types";
import { BrowserDetection } from "app2/src/helpers/BrowserDetection";
import { useFlashAlert } from "app2/src/components/VideoProvider/FlashAlertContext";

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions(flashAlert?: (level: string, message: string) => void) {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        const videoConstraints = BrowserDetection.isBrowserMobile()
          ? true
          : { width: { min: 360, max: 1280 }, height: { min: 360, max: 1280 } };

        return navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: videoConstraints,
          })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()))
          .catch((e) => {
            if (flashAlert) {
              if (e.name === "NotAllowedError") {
                flashAlert("danger", "Please allow access to your microphone and camera to continue.");
              } else if (e.name === "NotFoundError") {
                flashAlert(
                  "danger",
                  "No microphone or camera found. Please connect a microphone or camera to continue.",
                );
              }
            }

            throw e;
          });
      }
    });
}

export function useLocalAudioTrack(): [LocalAudioTrack, () => Promise<LocalAudioTrack | null>] {
  const flashAlert = useFlashAlert();
  const [track, setTrack] = useState<LocalAudioTrack>();

  const getLocalAudioTrack = useCallback(() => {
    const options: CreateLocalTrackOptions = {};
    const deviceId = JSON.parse(localStorage.getItem(LocalStorageKeys.AudioInputId));

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return ensureMediaPermissions(flashAlert)
      .then(() => {
        return Video.createLocalAudioTrack(options).then((newTrack) => {
          setTrack(newTrack);
          return newTrack;
        });
      })
      .catch(() => null);
  }, []);

  useEffect(() => {
    getLocalAudioTrack();
  }, [getLocalAudioTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on("stopped", handleStopped);
      return () => {
        track.removeListener("stopped", handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalAudioTrack];
}

export function useLocalVideoTrack(): [
  LocalVideoTrack,
  (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack | null>,
  boolean,
] {
  const flashAlert = useFlashAlert();
  const [track, setTrack] = useState<LocalVideoTrack>();
  const [isLoading, setIsLoading] = useState(false);

  const getLocalVideoTrack = useCallback(() => {
    setIsLoading(true);

    return ensureMediaPermissions(flashAlert)
      .then(async () => {
        // Local Storage settings
        const deviceId = JSON.parse(localStorage.getItem(LocalStorageKeys.VideoInputId));
        const graphicsSettings = JSON.parse(localStorage.getItem(LocalStorageKeys.GraphicsSetting));
        const reduceResolution = JSON.parse(localStorage.getItem(LocalStorageKeys.ReduceResolution));
        const defaultPipeline =
          BrowserDetection.isMobileIOS() || BrowserDetection.isBrowserSafari()
            ? VideoProcessor.Pipeline.WebGL2
            : VideoProcessor.Pipeline.Canvas2D;
        const pipeline = graphicsSettings || defaultPipeline;

        const lowRes = { width: 640, height: 360 };
        const highRes = { width: 1280, height: 720 };

        const resolution = reduceResolution ? lowRes : highRes;

        // In the DeviceSelector and FlipCameraButton components, a new video track is created,
        // then the old track is unpublished and the new track is published. Unpublishing the old
        // track and publishing the new track at the same time sometimes causes a conflict when the
        // track name is 'camera', so here we append a timestamp to the track name to avoid the
        // conflict.
        const options: CreateLocalTrackOptions = {
          frameRate: 24,
          name: `camera-${Date.now()}`,
          deviceId: deviceId ? { exact: deviceId } : undefined,
        };

        if (!BrowserDetection.isBrowserMobile() && !BrowserDetection.isBrowserSafari()) {
          options.width = resolution.width;
          options.height = resolution.height;
        } else {
          options.width = 640;
          options.height = 480;
        }

        return Video.createLocalVideoTrack(options)
          .then(async (newTrack) => {
            const backgroundSetting = JSON.parse(localStorage.getItem(LocalStorageKeys.SelectedBackground));
            if (backgroundSetting || backgroundSetting === 0) {
              const baseOptions = {
                assetsPath: "/twilio",
                maskBlurRadius: 5,
                pipeline,
                debounce: true,
              };

              let backgroundProcessor:
                | VideoProcessor.VirtualBackgroundProcessor
                | VideoProcessor.GaussianBlurBackgroundProcessor;

              if (backgroundSetting === BlurTypes.Full || backgroundSetting === BlurTypes.Slight) {
                const blurFilterRadius = backgroundSetting === BlurTypes.Slight ? 5 : 15;
                backgroundProcessor = new VideoProcessor.GaussianBlurBackgroundProcessor({
                  ...baseOptions,
                  blurFilterRadius,
                });
              } else {
                const src = JSON.parse(localStorage.getItem(LocalStorageKeys.CustomBackgrounds))[backgroundSetting];
                const image = new Image();
                image.src = src;
                await image.decode();
                backgroundProcessor = new VideoProcessor.VirtualBackgroundProcessor({
                  ...baseOptions,
                  backgroundImage: image,
                });
              }

              await backgroundProcessor.loadModel();
              newTrack = newTrack.addProcessor(
                backgroundProcessor,
                pipeline === VideoProcessor.Pipeline.WebGL2
                  ? {
                      inputFrameBufferType: "video",
                      outputFrameBufferContextType: "webgl2",
                    }
                  : undefined,
              );
            }

            setTrack(newTrack);
            setIsLoading(false);
            return newTrack;
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        return null;
      });
  }, []);

  useEffect(() => {
    // Set full blur as default for non-mobile devices
    const currentBackground = localStorage.getItem(LocalStorageKeys.SelectedBackground);
    if (currentBackground === null && !BrowserDetection.isBrowserMobile()) {
      // Interface is expected to be JSON.stringify() -> JSON.parse() between updates
      localStorage.setItem(LocalStorageKeys.SelectedBackground, JSON.stringify(BlurTypes.Full));
    }
  }, []);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on("stopped", handleStopped);
      return () => {
        track.removeListener("stopped", handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalVideoTrack, isLoading];
}

export default function useLocalTracks() {
  const [audioTrack, getLocalAudioTrack] = useLocalAudioTrack();
  const [videoTrack, getLocalVideoTrack, isVideoTrackLoading] = useLocalVideoTrack();

  const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
  )[];

  return { localTracks, getLocalVideoTrack, getLocalAudioTrack, isVideoTrackLoading };
}
