import * as React from "react";
import { Map, List } from "immutable";
import { Card, Row, Col, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import SlideComponent from "app2/src/components/JobTabs/PresentationTab/Customer/PresentationBuilder/SlideComponent";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { SlideRecord } from "app2/src/records/Slide";
import { PresentationRecord } from "app2/src/records/Presentation";
import { activeDynamicLinks, moveSlide, getSortOrder } from "./PresentationBuilder.service";
import ListPicker from "../PresentationTemplate/ListPicker";
import ListManager from "../PresentationTemplate/Management";
import { PresentationTemplateRecord } from "app2/src/records/PresentationTemplate";
import { InspectionSlides } from "./InspectionSlides";
import _track, { Track, TrackingProp } from "react-tracking";
import { TrackingData } from "app2/src/helpers/Analytics";
import { Can } from "app2/src/components/Common/CanComponent";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { DynamicPresentationLinkRecord } from "app2/src/records/DynamicPresentationLink";
import { connect, ConnectedProps } from "app2/src/connect";
import * as presentationActions from "app2/src/reducers/presentation.actions";
import { Nullable } from "app2/src/records";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePresentation: (presentation) => {
      dispatch(presentationActions.Actions.receivePresentation(presentation));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IDynamicPresentationComponentProps {
  presentation: PresentationRecord;
  orgPresentations: List<PresentationRecord>;
  inspectionId: Nullable<number>;
  slidesById: Map<number, SlideRecord>;
  orgId: number;
  tracking?: TrackingProp;
  dirty: boolean;
  goTo: () => void;
  save(presentation: PresentationRecord);
  applyTemplate(template: PresentationTemplateRecord);
}

const track: Track<TrackingData, IDynamicPresentationComponentProps> = _track;

export interface IDynamicPresentationComponentState {
  showTemplates: boolean;
  showTemplateEditor: boolean;
  sortOrder: "default" | "free-for-all";
  dynamicSlides: List<DynamicPresentationLinkRecord>;
  currentlyReordering: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IDynamicPresentationComponentProps;

@track(
  //@ts-ignore
  (props) => {
    return {
      category: "Dynamic Presentation View",
      action: "Show",
      presentation: (props.presentation || { id: null }).id,
    };
  },
  { dispatchOnMount: false },
)
class DynamicPresentationComponent extends React.Component<Props, IDynamicPresentationComponentState> {
  public sliderRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  public state: IDynamicPresentationComponentState = {
    showTemplates: false,
    showTemplateEditor: false,
    sortOrder: "default",
    dynamicSlides: List<DynamicPresentationLinkRecord>(),
    currentlyReordering: false,
  };

  constructor(props: Props) {
    super(props);

    this.applyTemplate = this.applyTemplate.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.closeTemplatePicker = this.closeTemplatePicker.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.closeTemplateEditor = this.closeTemplateEditor.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.reorderPresentation = this.reorderPresentation.bind(this);
  }

  public componentDidUpdate(prevProps: IDynamicPresentationComponentProps) {
    const { orgPresentations } = this.props;
    if (this.props.presentation !== prevProps.presentation) {
      this.setState({
        sortOrder: getSortOrder(this.props.presentation, orgPresentations),
        dynamicSlides: activeDynamicLinks(this.props.presentation),
      });
    }
  }

  public onDragEnd(result: DropResult) {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const { dynamicSlides } = this.state;
    const { updatePresentation, presentation } = this.props;
    const from = result.source.index;
    const to = result.destination.index;
    const slide = dynamicSlides.get(result.source.index);

    const updatedSlides: List<DynamicPresentationLinkRecord> = moveSlide(dynamicSlides, slide, from, to);
    let pres = presentation;
    pres = pres.update("dynamic_links", (_s: List<DynamicPresentationLinkRecord>) => updatedSlides);
    updatePresentation(pres);
    this.setState({
      sortOrder: "free-for-all",
      dynamicSlides: updatedSlides,
    });
  }

  public reorderPresentation() {
    this.setState({
      currentlyReordering: true,
    });
  }

  public render() {
    const { presentation, orgId, slidesById, save, goTo, dirty, inspectionId } = this.props;
    const { showTemplates, showTemplateEditor, dynamicSlides, currentlyReordering } = this.state;

    if (!presentation || dynamicSlides === undefined) {
      return <SpinnerComponent localProperty={true} />;
    }

    const noSlidesView = activeDynamicLinks(presentation).size === 0;

    return (
      <SpinnerComponent localProperty={presentation.loading}>
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Header className="d-flex align-items-center justify-content-between h-100">
                Final Presentation
                <div className="d-sm-flex flex-sm-column flex-md-row justify-content-sm-between">
                  <Dropdown as={ButtonGroup} className="pull-right">
                    <Button variant="default" onClick={this.selectTemplate}>
                      Select a Template
                    </Button>
                    <Can resource="presentation_template" permission="create">
                      <Dropdown.Toggle split variant="default" className="btn-narrow"></Dropdown.Toggle>
                      <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" onClick={this.editTemplate}>
                          Manage Templates
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Can>
                  </Dropdown>
                  <Button
                    variant="default"
                    className={"pull-right mb-sm-1 mb-md-0"}
                    disabled={presentation.id <= 0 || dirty ? true : false}
                    onClick={goTo}>
                    Present
                  </Button>
                  <Button
                    variant="default"
                    className={"pull-right"}
                    disabled={presentation.id <= 0 || dirty ? true : false || currentlyReordering}
                    onClick={() => this.reorderPresentation()}>
                    Reorder
                  </Button>
                  <Button
                    variant="default"
                    disabled={!dirty}
                    className="pull-right"
                    onClick={() => {
                      this.setState({ currentlyReordering: false });
                      save(presentation);
                    }}>
                    Save
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  {/*
                  // @ts-ignore */}
                  <Col sm={12} ref={this.sliderRef} className="slide-container">
                    {noSlidesView ? <span>No Slides</span> : null}
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable direction="horizontal" droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            className="droppable scroll-bar-box"
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            {dynamicSlides.map((dl: DynamicPresentationLinkRecord, idx: number) => {
                              if (dl.kind === "default") {
                                return (
                                  <Draggable
                                    key={idx}
                                    draggableId={idx.toString()}
                                    index={idx}
                                    isDragDisabled={!currentlyReordering}>
                                    {(provided, snapshot) => (
                                      <div
                                        key={idx}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <SlideComponent slide={slidesById.get(dl.presentable_id)} showToggle={false} />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              } else if (dl.kind === "inspection") {
                                return (
                                  <Draggable
                                    key={idx}
                                    draggableId={idx.toString()}
                                    index={idx}
                                    isDragDisabled={!currentlyReordering}>
                                    {(provided, snapshot) => (
                                      <div
                                        key={idx}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="droppable">
                                        {currentlyReordering ? (
                                          <div className="slide">
                                            <div className="placeholder">
                                              <span>Inspection Slides</span>
                                            </div>
                                          </div>
                                        ) : (
                                          <InspectionSlides inspectionId={inspectionId} />
                                        )}
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              }
                            })}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ListPicker
          show={showTemplates}
          orgId={orgId}
          onSelect={this.applyTemplate}
          onClose={this.closeTemplatePicker}
        />
        <ListManager
          show={showTemplateEditor}
          orgId={orgId}
          presentation={presentation}
          onClose={this.closeTemplateEditor}
        />
      </SpinnerComponent>
    );
  }

  public applyTemplate(pt: PresentationTemplateRecord) {
    const { applyTemplate, tracking } = this.props;

    this.setState({ showTemplates: false });

    tracking.trackEvent({
      action: "Apply Template",
      presentationTemplate: pt.id,
    });
    applyTemplate(pt);
  }

  @track({ action: "Show Template Selector" })
  public selectTemplate() {
    this.setState({ showTemplates: true });
  }

  @track({ action: "Close Template Selector" })
  public closeTemplatePicker() {
    this.setState({ showTemplates: false });
  }

  @track({ action: "Open Template Editor" })
  public editTemplate() {
    this.setState({ showTemplateEditor: true });
  }

  @track({ action: "Close Template Editor" })
  public closeTemplateEditor() {
    this.setState({ showTemplateEditor: false });
  }
}

export default connector(DynamicPresentationComponent);
