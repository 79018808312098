import * as React from "react";
import { LocalVideoTrack } from "twilio-video";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useVideoInputDevices } from "app2/src/components/DeviceSelector/hooks/deviceHooks";
import VideoTrack from "app2/src/components/Twilio/components/VideoTrack";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import { useTracking } from "react-tracking";
import { useLocalStorage } from "app2/src/hooks/useLocalStorage";
import * as FontAwesome from "react-fontawesome";
import { getBase64 } from "app2/src/records/File";
import { BlurTypes, LocalStorageKeys } from "../../VideoProvider/types";
import { useDispatch } from "react-redux";
import { Actions } from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import SpinnerComponent from "../../SpinnerComponent";

export default function VideoInputList() {
  // Hooks
  const dispatch = useDispatch();
  const videoInputDevices = useVideoInputDevices();
  const { trackEvent } = useTracking();
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    isVideoTrackLoading,
  } = useVideoContext();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [background, setBackground] = useLocalStorage<BlurTypes.Slight | BlurTypes.Full | number | null>(
    LocalStorageKeys.SelectedBackground,
    null,
  );
  const [customBackgrounds, setCustomBackgrounds] = useLocalStorage<string[]>(
    LocalStorageKeys.CustomBackgrounds,
    [],
    () => dispatch(Actions.flashAddAlert(FlashLevels.danger, "Failed to upload image. Browser storage is full.")),
  );
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useLocalStorage<string>(
    LocalStorageKeys.VideoInputId,
    null,
  );

  const localVideoTrack = localTracks.find((track) => track.kind === "video") as LocalVideoTrack;
  let localVideoInputDeviceId = null;
  if (localVideoTrack) {
    localVideoInputDeviceId = localVideoTrack.mediaStreamTrack.getSettings().deviceId;
  }

  function replaceTrack(newDeviceId: string, newBackground?: BlurTypes.Slight | BlurTypes.Full | number | null) {
    if (localVideoTrack) {
      localVideoTrack.stop();
    }
    getLocalVideoTrack().then((newTrack) => {
      if (newBackground === undefined) {
        trackEvent({ action: "changeVideoInput", videoInputName: newTrack?.name, videoInputId: newTrack?.id });
      } else {
        const background =
          typeof newBackground === "number" ? "custom image" : newBackground === null ? "none" : newBackground;
        trackEvent({ action: "changeVirtualBackground", background });
      }
      if (localParticipant) {
        if (localVideoTrack) {
          const localTrackPublication = localParticipant.unpublishTrack(localVideoTrack);
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant.emit("trackUnpublished", localTrackPublication);
        }

        localParticipant.publishTrack(newTrack);
      }
    });
  }

  const handleVideoInputChange = (newDeviceId) => {
    setSelectedVideoDeviceId(newDeviceId);
    replaceTrack(newDeviceId);
  };

  const activeStyle = (backgroundOption: BlurTypes.Slight | BlurTypes.Full | number | null) =>
    background === backgroundOption ? "border border-primary border-3" : "";

  function handleBackgroundSelect(background: BlurTypes.Slight | BlurTypes.Full | number | null) {
    setBackground(background);
    replaceTrack(selectedVideoDeviceId, background);
  }

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size / 1024 / 1024 > 2) {
        dispatch(Actions.flashAddAlert(FlashLevels.danger, "Failed to upload image. File size must be under 2 MB."));
        return;
      }
      const base64 = (await getBase64(file)) as string;
      setCustomBackgrounds([...customBackgrounds, base64]);
    }
  }

  function handleUploadClick() {
    fileInputRef.current?.click();
  }

  function deleteBackground(index: number) {
    setCustomBackgrounds(customBackgrounds.filter((_, i) => i !== index));
    setBackground(null);
    replaceTrack(selectedVideoDeviceId, null);
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Label>Video Input</Form.Label>
          {videoInputDevices.length > 1 ? (
            <Form.Control
              as="select"
              onChange={(e: any) => handleVideoInputChange(e.target.value as string)}
              value={selectedVideoDeviceId || localVideoInputDeviceId || ""}>
              {videoInputDevices.map((device) => (
                <option value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </Form.Control>
          ) : (
            <p>{localVideoTrack?.mediaStreamTrack.label || "No Local Video"}</p>
          )}
        </Col>
      </Row>
      {videoInputDevices && (
        <Row>
          <Col>
            <div className="d-flex">
              <div style={{ flex: 1 }}>
                <Row>
                  <Col>
                    <Form.Label>Virtual Background</Form.Label>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="d-flex flex-wrap">
                      <div
                        onClick={() => handleBackgroundSelect(null)}
                        className={"background-option-container mr-3 mb-3 " + activeStyle(null)}
                        style={{ backgroundColor: "rgba(0,0,0,80%)" }}>
                        <div>None</div>
                      </div>
                      <div className="relative mr-3 mb-3">
                        <img src={"/assets/images/slight-blur.e9569bcd.jpg"} alt="Slight Blur" width={100} />
                        <div
                          onClick={() => handleBackgroundSelect(BlurTypes.Slight)}
                          className={"background-option-container top-0 absolute " + activeStyle(BlurTypes.Slight)}
                          style={{ backgroundColor: "rgba(0,0,0,35%)" }}>
                          <div>Slight Blur</div>
                        </div>
                      </div>
                      <div className="relative mr-3 mb-3">
                        <img src={"/assets/images/full-blur.45036e1f.jpg"} alt="Full Blur" width={100} />
                        <div
                          onClick={() => handleBackgroundSelect(BlurTypes.Full)}
                          className={"background-option-container top-0 absolute " + activeStyle(BlurTypes.Full)}
                          style={{ backgroundColor: "rgba(0,0,0,35%)" }}>
                          <div>Full Blur</div>
                        </div>
                      </div>
                      {customBackgrounds.map((base64, idx) => (
                        <div
                          className={"custom-background relative mr-3 mb-3 link " + activeStyle(idx)}
                          style={{
                            backgroundImage: `url('${base64}')`,
                            backgroundSize: "cover",
                          }}
                          key={`background-${idx}`}>
                          <FontAwesome
                            onClick={() => deleteBackground(idx)}
                            name="times-circle"
                            className="delete-btn"
                            size="lg"
                            id={`delete-btn-${idx}`}
                          />
                          <div id={`custom-img-${idx}`} onClick={() => handleBackgroundSelect(idx)}></div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <input
                      ref={fileInputRef}
                      type="file"
                      onChange={handleFileChange}
                      hidden
                      id="upload-background"
                      accept="image/png, image/jpeg"
                    />
                    <Button
                      onClick={handleUploadClick}
                      disabled={customBackgrounds.length >= 3}
                      title={customBackgrounds.length >= 3 ? "Image number limit reached" : "Upload Image"}
                      id="upload-btn">
                      Upload Image
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="video-preview">
                {localVideoTrack && !isVideoTrackLoading && <VideoTrack isLocal track={localVideoTrack} />}
                <SpinnerComponent localProperty={isVideoTrackLoading} />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}
