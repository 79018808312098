import * as React from "react";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import * as documentActions from "app2/src/reducers/document.actions";
import { Row, Col } from "react-bootstrap";
import { List } from "immutable";
import { booleanChecked } from "app2/src/selectors/document.selectors";
import { DocumentableType } from "app2/src/api/document.service";
import track from "react-tracking";
import { estimateTitle, contractTitle } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: TableHeaderProps) => {
  const documentIds = ownProps.documentIds;
  return {
    proposalCheck: booleanChecked(state, { documentIds, booleanName: "displayInProposal", filtered: true }),
    agreementCheck: booleanChecked(state, { documentIds, booleanName: "displayInAgreement", filtered: true }),
    selectedCheck: booleanChecked(state, { documentIds, booleanName: "selected" }),
    estimateTitle: estimateTitle(state),
    contractTitle: contractTitle(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: TableHeaderProps) => {
  return {
    batchEditBoolean: (name: string, value: boolean) =>
      dispatch(documentActions.Actions.batchEditBoolean(ownProps.documentIds, name, value)),
    batchUpdateDocument: () => dispatch(documentActions.AsyncActions.batchUpdateDocument(ownProps.documentIds)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface TableHeaderProps {
  documentableType: DocumentableType;
  documentIds?: List<number>;
}

interface TableHeaderState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & TableHeaderProps;

@track(() => {
  return {
    component: "TableHeader",
  };
})
class TableHeader extends React.Component<Props, TableHeaderState> {
  constructor(props: Props) {
    super(props);

    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
  }

  @track((props, _state, [event]) => ({
    action: "checkAll",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
    documents: props.documentIds.toArray(),
  }))
  public checkBoxOnChange(event: any): void {
    const { batchEditBoolean, batchUpdateDocument } = this.props;
    batchEditBoolean(event.target.name, event.target.checked);
    if (event.target.name !== "selected") {
      batchUpdateDocument();
    }
  }

  public render(): React.ReactNode {
    const { documentableType, proposalCheck, agreementCheck, selectedCheck, estimateTitle, contractTitle } = this.props;

    return (
      <Row className="table-header">
        <Col sm={1} style={{ paddingLeft: "15px" }}>
          {documentableType === "job" && (
            <IncludeCheckBox name="selected" checked={selectedCheck} onChange={this.checkBoxOnChange} />
          )}
        </Col>
        <Col>Name</Col>
        <Col sm={2}>Uploaded On</Col>
        <Col sm={2}>
          {documentableType === "job" ? (
            <IncludeCheckBox
              name="displayInProposal"
              label={estimateTitle}
              checked={proposalCheck}
              onChange={this.checkBoxOnChange}
            />
          ) : (
            estimateTitle
          )}
        </Col>
        <Col sm={2}>
          {documentableType === "job" ? (
            <IncludeCheckBox
              name="displayInAgreement"
              label={contractTitle}
              checked={agreementCheck}
              onChange={this.checkBoxOnChange}
            />
          ) : (
            contractTitle
          )}
        </Col>
        <Col sm={2}>Actions</Col>
      </Row>
    );
  }
}

export default connector(TableHeader);
