import * as React from "react";
import { useRef, useEffect, useState } from "react";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import pdfTronConfig from "@pdftron/webviewer/package.json";
import { useSelector } from "react-redux";
import { currentToken } from "app2/src/reducers/auth.selectors";
import { connectFC } from "app2/src/connect";
import { fetcher } from "app2/src/helpers/Fetcher";

export const licenseKey =
  "Remote Sales Force, LLC:OEM:One Click Contractor::B+:AMS(20260427):60A5D5DD04D7480A4360B13AC9A2537860612FA55C60F5A2BD22EE94347028BE4AB231F5C7";

interface PdfDisplayProps {
  pdfUrl: string;
  additionalItems?: any[];
  disabledFeatures?: any[];
  disabledElements?: any[];
  viewerBuiltCallback?: (instance: WebViewerInstance) => void;
}

const bad = ["searchButton"];

export const PdfDisplay: React.FunctionComponent<PdfDisplayProps> = ({
  pdfUrl,
  additionalItems,
  disabledFeatures,
  disabledElements,
  viewerBuiltCallback,
}) => {
  const token = useSelector(currentToken);
  let instance: WebViewerInstance = null;
  let defaultDisabledFeatures: string[] = ["NotesPanel", "Redaction", "ThumbnailMerging", "ThumbnailReordering"];
  let defaultDisabledElements: string[] = [
    "toolbarGroup-FillAndSign",
    "toolbarGroup-Insert",
    "toolbarGroup-Edit",
    "toolbarGroup-Forms",
    "polygonCloudToolGroupButton",
    "stickyToolGroupButton",
    "themeChangeButton",
  ];
  if (disabledFeatures) {
    defaultDisabledFeatures = defaultDisabledFeatures.concat(disabledFeatures);
  }
  if (disabledElements) {
    defaultDisabledElements = defaultDisabledElements.concat(disabledElements);
  }
  const viewer = useRef(null);

  additionalItems = additionalItems || [];

  const updateUrl = () => {
    const options = fetcher.credentials({}, pdfUrl);
    instance.UI.loadDocument(pdfUrl, { customHeaders: options.headers });
  };

  const initializeAnnotationTools = () => {
    const { documentViewer, Annotations, Tools } = instance.Core;
    const defaultsSet = localStorage.getItem("toolData-defaultsSet");

    [Tools.ToolNames.FREEHAND, Tools.ToolNames.FREEHAND_HIGHLIGHT].forEach((toolText) => {
      const tool: any = documentViewer.getTool(toolText);
      if (tool) {
        tool.createDelay = 0;
      }
    });

    if (_.isNullOrUndefined(defaultsSet)) {
      const pdfTronBlue = new Annotations.Color(78, 125, 233, 1);
      const pdfTronRed = new Annotations.Color(228, 66, 52, 1);
      const pdfTronYellow = new Annotations.Color(255, 230, 162, 1);

      const pdfTronAnnotationTools = {
        AnnotationCreateRectangle: pdfTronBlue,
        AnnotationCreateRectangle2: pdfTronRed,
        AnnotationCreateEllipse: pdfTronBlue,
        AnnotationCreateEllipse2: pdfTronRed,
        AnnotationCreateLine: pdfTronBlue,
        AnnotationCreateLine2: pdfTronRed,
        AnnotationCreateArrow: pdfTronBlue,
        AnnotationCreateArrow2: pdfTronRed,
        AnnotationCreatePolyline: pdfTronBlue,
        AnnotationCreatePolyline2: pdfTronRed,
        AnnotationCreatePolygon: pdfTronBlue,
        AnnotationCreatePolygon2: pdfTronRed,
        AnnotationCreateTextUnderline: pdfTronBlue,
        AnnotationCreateTextUnderline2: pdfTronRed,
        AnnotationCreateTextSquiggly: pdfTronBlue,
        AnnotationCreateTextSquiggly2: pdfTronRed,
        AnnotationCreateTextStrikeout: pdfTronBlue,
        AnnotationCreateTextStrikeout2: pdfTronRed,
        AnnotationCreateFreeHand: pdfTronBlue,
        AnnotationCreateFreeHand3: pdfTronRed,
        AnnotationCreateFreeHandHighlight: pdfTronYellow,
      };

      Object.keys(pdfTronAnnotationTools).forEach((toolText) => {
        const tool = documentViewer.getTool(toolText);

        if (!_.isNullOrUndefined(tool)) {
          tool.setStyles({
            StrokeColor: pdfTronAnnotationTools[toolText],
          });
        }
      });

      const pdfTronTextTools = {
        AnnotationCreateFreeText: pdfTronBlue,
        AnnotationCreateFreeText3: pdfTronRed,
      };

      Object.keys(pdfTronTextTools).forEach((toolText) => {
        const tool = documentViewer.getTool(toolText);
        if (!_.isNullOrUndefined(tool)) {
          tool.setStyles({
            TextColor: pdfTronTextTools[toolText],
          });
        }
      });

      localStorage.setItem("toolData-defaultsSet", "true");
    }
  };

  const buildViewer = async () => {
    try {
      instance = await WebViewer(
        {
          path: `/webviewer/${pdfTronConfig.version}`,
          extension: "pdf",
          licenseKey,
          useDownloader: false,
        },
        viewer.current,
      );

      const options = fetcher.credentials({}, pdfUrl);
      instance.UI.loadDocument(pdfUrl, { customHeaders: options.headers });
      const { Core, UI } = instance;
      const { documentViewer, annotationManager } = Core;
      UI.setPrintQuality(5);
      UI.disableFeatures(defaultDisabledFeatures);
      UI.disableElements(defaultDisabledElements);

      if (!defaultDisabledFeatures.includes("Annotations")) {
        documentViewer.addEventListener("documentLoaded", initializeAnnotationTools);
      }

      UI.setHeaderItems((header) => {
        let items: any[] = header.getItems();
        items = items.filter((item) => {
          return bad.indexOf(item.toolGroup) < 0 && bad.indexOf(item.toolName) < 0 && bad.indexOf(item.dataElement) < 0;
        });

        items = items.concat(
          additionalItems.map((item) => {
            if (item.onClickHandler) {
              item.onClick = () => {
                item.onClickHandler(documentViewer, annotationManager);
              };
            }

            return item;
          }),
        );

        header.update(items);
      });
      UI.setFitMode(UI.FitMode.FitWidth);

      if (viewerBuiltCallback) {
        viewerBuiltCallback(instance);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!pdfUrl) {
      return;
    }
    if (!instance) {
      buildViewer();
    } else {
      updateUrl();
    }
  }, [pdfUrl]);

  return <div ref={viewer} className="embed-pdf"></div>;
};

export default connectFC(PdfDisplay);
