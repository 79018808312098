import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IJob } from "app/src/Models/Job";
import { IUser } from "app/src/Models/User";
import { IBaseConfig } from "../Common/IBaseConfig";

let Event: IEventResource;

export interface IEvent extends ng.resource.IResource<IEvent>, EventPrototype {
  $result(...args: any[]): ng.IPromise<Blob>;
}

export interface IEventResource extends ng.resource.IResourceClass<IEvent>, IRsfResource {
  fromJSON?(data: any): IEvent;
}

class EventPrototype {
  public id: number;
  public user_id: number;
  public user: IUser;
  public job_id: number;
  public job: IJob;
  public summary: string;
  public description: string;
  public start_time: Date;
  public end_time: Date;
  public location: string;
  public deleted: boolean;
  public participation_status: string;
  public transparency: string;
  public event_status: string;
  public categories: any;
  public attendees: any;
  public notes: string;

  public events_result_id: number;
  public events_result_reason_id: number;

  public created_at: Date;
  public updated_at: Date;

  public metadata: IEventMetadata;

  public clone(): IEvent {
    return Event.fromJSON(JSON.parse(angular.toJson(this)));
  }

  public isMs(): boolean {
    return !!this.metadata && this.metadata.marketsharp_id !== undefined && this.metadata.marketsharp_id != null;
  }

  public isI360(): boolean {
    return (
      !!this.metadata &&
      this.metadata.improveit360_appointment_id !== undefined &&
      this.metadata.improveit360_appointment_id != null
    );
  }

  public isSr(): boolean {
    return !!this.metadata && this.metadata.sales_rabbit_id !== undefined && this.metadata.sales_rabbit_id != null;
  }

  public isSalesforce(): boolean {
    return (
      !!this.metadata && this.metadata.salesforce_event_id !== undefined && this.metadata.salesforce_event_id != null
    );
  }

  public isLeadPerfection(): boolean {
    return (
      !!this.metadata && this.metadata.lead_perfection_id !== undefined && this.metadata.lead_perfection_id != null
    );
  }

  public $saveOrCreate(params?: any, callback?) {
    if (this.id <= 0 || (this.id as any as string) === "Unsaved") {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$save(params, callback);
    }
  }
}

export interface IEventMetadata {
  marketsharp_id?: string;
  improveit360_appointment_id?: string;
  cronofy_id?: string;
  calendar_id?: string;
  sales_rabbit_id?: string;
  salesforce_event_id?: string;
  lead_perfection_id?: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IEventResource => {
  const url = BaseConfig.BASE_URL + "/api/v1/events/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IEvent => {
    if (status < 200 || status > 299) {
      return new Event({});
    }
    return Event.fromJSON(JSON.parse(response).event);
  };

  const transformRequest = (event: IEvent): string => {
    const data = JSON.decycle(event);
    return angular.toJson({ event: data });
  };

  Event = <IEventResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const meta = JSON.parse(response);

          _.each(meta.events, (json, index) => {
            meta.events[index] = Event.fromJSON(json);
          });

          return meta;
        },
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      result: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: url + "/result",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  Event.fromJSON = (data: any): IEvent => {
    if (data.job) {
      data.job = resources.Job.fromJSON(data.job);
    }
    return new Event(data);
  };

  _.hiddenExtend(Event.prototype, EventPrototype.prototype);

  Event.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Event;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
