import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { WallFacadeRecord } from "app2/src/records/WallFacade";

export const rootKey = "wallFacades";

/**
 * Returns a WallFacade record
 *
 * @param {RootState} state
 * @param {{ wallFacadeId: number }} options
 * @returns {WallFacadeRecord}
 */
export const wallFacade = (state: RootState, props: any) => state.getIn([rootKey, "byId", props.wallFacadeId]);

/**
 * Returns a list of WallFacade records
 *
 * @param {RootState} state
 * @param {{ wallFacadeIds: number }} options
 * @returns {List<WallFacadeRecord>}
 */
export const wallFacades = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<WallFacadeRecord> => {
    const { state, props } = stateProps;
    const { wallFacadeIds } = props;
    return wallFacadeIds.map((id: number) => wallFacade(state, { wallFacadeId: id }));
  },
);
