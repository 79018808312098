import { ActionsUnion, createAction } from "./Utils";
import {
  load,
  list,
  update,
  create,
  loadByEmail,
  crmUserCheck,
  includes as userIncludes,
  IUserResponse,
  revokeAccess,
  sendInvitation,
  loadAclValues,
  IAclResponse,
} from "app2/src/api/user.service";
import { ThunkAction } from "redux-thunk";
import { RootState } from ".";
import { IUserData, UserRecord } from "../records/UserRecord";
import * as orgActions from "./org.actions";
import { RootDispatchType } from "app2/src/store";
import { IPageData, QueryParamsRecord } from "app2/src/records/Page";
import { List } from "immutable";
import { Actions as PagingActions } from "app2/src/reducers/pagination.actions";
import { handleErrors } from "app2/src/reducers/Utils";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as accessActions from "app2/src/reducers/access.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { currentUserId, user, userHydratedForSave } from "app2/src/selectors/user.selectors";
import { CrmUserType } from "app2/src/records/integrations/CrmUser";
import { fromJSON } from "../records/UserRecord";

export const SET_USER = "@user/SET_USER";
export const SET_CURRENT_USER = "@user/SET_CURRENT_USER";
export const RESET_USER = "@user/RESET_USER";
export const FETCH_USER = "@user/FETCH_USER";
export const SET_USER_LOADED = "@user/SET_USER_LOADED";
export const RECEIVE_USERS = "@user/RECEIVE_USERS";
export const SET_CRM_USER_CHECK = "@user/SET_CRM_USER_CHECK";
export const UPDATE_FORM = "@user/UPDATE_FORM";
export const FETCH_ALLOWED_ACL = "@user/FETCH_ALLOWED_ACL";
export const RECEIVE_ALLOWED_ACL = "@user/RECEIVE_ALLOWED_ACL";
export const ADD_ACCESSES = "@user/ADD_ACCESSES";

export const Actions = {
  setUser: (user: IUserData) => createAction(SET_USER, user),
  setUserLoaded: (userId: number) => createAction(SET_USER_LOADED, { userId }),
  resetUser: (userId: number) => createAction(RESET_USER, { userId }),
  setCurrentUser: (user: Partial<IUserData>) => createAction(SET_CURRENT_USER, user),
  fetchUser: (id: number) => createAction(FETCH_USER, id),
  receiveUsers: (users: IUserData[]) => createAction(RECEIVE_USERS, { users }),
  updateForm: (event: { rootPath: (string | number)[]; name: string; value: string }) =>
    createAction(UPDATE_FORM, { event }),
  setCrmUserCheck: (orgId: number, crmUserType: CrmUserType, result: boolean) =>
    createAction(SET_CRM_USER_CHECK, { orgId, crmUserType, result }),
  fetchAllowedAcl: (userId: number, accessUid: string, template: string) =>
    createAction(FETCH_ALLOWED_ACL, { userId, accessUid, template }),
  receiveAllowedAcl: (userId: number, accessUid: string, template: string, acl: IAclResponse) =>
    createAction(RECEIVE_ALLOWED_ACL, { userId, accessUid, template, acl }),
  addAccesses: (userId: number, accessIds: number[]) => createAction(ADD_ACCESSES, { userId, accessIds }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions | orgActions.Actions>;

export const AsyncActions = {
  getUser: (id: number, includes: string[] = [], force = false): ThunkResult<Promise<IUserData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchUser(id));

      return load(id, includes)
        .then(
          (user) => {
            dispatch(Actions.setUser(user));
            return user;
          },
          (errors) => {
            handleErrors(errors);
            return {} as IUserData;
          },
        )
        .catch((errors) => {
          handleErrors(errors);
          return {} as IUserData;
        });
    };
  },
  save: (userId: number, includes: string[] = userIncludes, sendInvitation = false): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.fetchUser(userId));

      const user = userHydratedForSave(getState(), { userId });
      try {
        let result: IUserResponse;
        if (user.id <= 0) {
          result = await create(user, includes);
          dispatch(Actions.setUser(result.user));
          dispatch(paginationActions.Actions.pushId(result.user.id, "user"));
        } else {
          result = await update(user, includes);
          dispatch(Actions.setUser(result.user));
        }

        if (sendInvitation) {
          dispatch(AsyncActions.sendInvitation(result.user.id, result.user.email));
        }
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, `User saved: ${user.email}`));
      } catch (err) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, handleErrors(err)));
        dispatch(Actions.setUserLoaded(user.id));
      }
    };
  },
  revokeAccess: (userId: number, accessId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.fetchUser(userId));

      const userRecord = user(getState(), { userId });
      try {
        await revokeAccess(userId, accessId);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, `Access revoked for ${userRecord.email}`));
      } catch (err) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, handleErrors(err)));
      }
      dispatch(Actions.setUserLoaded(userId));
    };
  },
  sendInvitation: (userId: number, email: string): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.fetchUser(userId));

      try {
        await sendInvitation(email);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, `Invitation sent for ${email}`));
      } catch (err) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, handleErrors(err)));
      }
      dispatch(Actions.setUserLoaded(userId));
    };
  },
  getCurrentUserByEmail: (email: string, token?: string): ThunkResult<Promise<IUserData>> => {
    return (dispatch: RootDispatchType) => {
      return loadByEmail(email, userIncludes, token).then((user: IUserData) => {
        if (user.org) {
          dispatch(orgActions.Actions.setOrg(user.org));
        }
        dispatch(Actions.setCurrentUser(user));
        return user;
      });
    };
  },
  listUsers: (
    queryParams: QueryParamsRecord = new QueryParamsRecord(),
    modelName = "user",
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(PagingActions.fetchPage(modelName, queryParams));
      try {
        const response = await list(queryParams);
        dispatch(Actions.receiveUsers(response.users));

        const pageData: Partial<IPageData> = {
          ids: List(response.users.map((user: IUserData) => user.id)),
          pagination: (response as any).meta.pagination,
        };

        dispatch(PagingActions.receivePage(modelName, queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(PagingActions.receivePageError(modelName, queryParams, errors));
      }
    };
  },
  crmUserCheck: (crmUserType: CrmUserType): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const orgId = currentOrgId(getState());
      if (!_.isNullOrUndefined(getState().getIn(["users", "crmUserCheckByOrgId", orgId, "FmUser"]))) {
        return;
      }

      const userId = currentUserId(getState());
      try {
        await crmUserCheck(orgId, userId, crmUserType);
        dispatch(Actions.setCrmUserCheck(orgId, crmUserType, true));
      } catch (response) {
        if (response?.status === 404) {
          dispatch(Actions.setCrmUserCheck(orgId, crmUserType, false));
        }
        handleErrors(response);
      }
    };
  },
  loadAllowedAcl: (userId: number, accessUid: string, template: string): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchAllowedAcl(userId, accessUid, template));

      try {
        const response = await loadAclValues(userId, accessUid, template);
        dispatch(Actions.receiveAllowedAcl(userId, accessUid, template, response));
      } catch (err) {
        dispatch(Actions.receiveAllowedAcl(userId, accessUid, template, { acl: {}, templates: [] }));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, handleErrors(err)));
      }
    };
  },
  loadAndSetAccessAcl: (
    accessId: number,
    userId: number,
    accessUid: string,
    template: string,
    loadUserId?: number,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.fetchAllowedAcl(userId, accessUid, template));
      const loadId = loadUserId ? loadUserId : userId;
      try {
        const response = await loadAclValues(loadId, accessUid, template);
        dispatch(Actions.receiveAllowedAcl(loadId, accessUid, template, response));

        dispatch(
          accessActions.Actions.setAccessAcl(
            accessId,
            getState().getIn(["users", "allowedAclByUserAccess", "acl", `${loadId}:${accessUid}`, template]),
          ),
        );
      } catch (err) {
        dispatch(Actions.receiveAllowedAcl(userId, accessUid, template, { acl: {}, templates: [] }));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, handleErrors(err)));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
