import { Nullable } from "app2/src/records";
import { Record, List } from "immutable";

export interface ISalesOpportunityData {
  id: string;
  name: string;
  result_id: number;
  result_reason_id: number;
}

export interface ISalesOpportunityRecord {
  id: string;
  name: string;
  result_id: number;
  result_reason_id: number;
}

export const defaultSalesOpportunityProps = {
  id: "",
  name: "",
  result_id: 0,
  result_reason_id: 0,
} as ISalesOpportunityRecord;

export class SalesOpportunityRecord extends Record(defaultSalesOpportunityProps) {}

export const fromJSON = (json: IEventMetadata) => {
  const recordData: IEventMetadataRecord = { ...(json as any) };

  if (json.sales_opportunities) {
    recordData.sales_opportunities = List(json.sales_opportunities.map((obj) => new SalesOpportunityRecord(obj)));
  }

  return new EventMetadataRecord(recordData);
};

export interface IEventMetadata {
  sales_opportunities?: ISalesOpportunityData[];
  marketsharp_id?: number;
  improveit360_appointment_id?: number;
  sales_rabbit_id?: number;
  salesforce_event_id?: number;
  lead_perfection_id?: number;
}

export interface IEventMetadataRecord {
  sales_opportunities: Nullable<List<SalesOpportunityRecord>>;
  marketsharp_id: Nullable<number>;
  improveit360_appointment_id: Nullable<number>;
  sales_rabbit_id: Nullable<number>;
  salesforce_event_id: Nullable<number>;
  lead_perfection_id: Nullable<number>;
}

export const defaultEventMetadataProps = {
  sales_opportunities: null,
  marketsharp_id: null,
  improveit360_appointment_id: null,
  sales_rabbit_id: null,
  salesforce_event_id: null,
  lead_perfection_id: null,
} as IEventMetadataRecord;

export class EventMetadataRecord extends Record(defaultEventMetadataProps) {}
