import * as React from "react";
import * as config from "react-global-configuration";
import * as authActions from "app2/src/reducers/auth.actions";
import { connect, ConnectedProps } from "app2/src/connect";
import { InvitationView } from "./components/InvitationView";
import { ResetPasswordView } from "./components/ResetPasswordView";
import { RootState } from "app2/src/reducers";
import { ClaimOrgView } from "./components/ClaimOrgView";
import { TokenRequiredView } from "./components/TokenRequiredView";
import { IUser } from "app/src/Models/User";
import { RootDispatchType } from "app2/src/store";

const mapStateToProps = (state: RootState, ownProps: UserInvitationProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: UserInvitationProps) => {
  return {
    resetPassword: (token: string, token_type: string, user: IUser) => {
      return dispatch(authActions.AsyncActions.resetPassword(token, token_type, user));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const resetTypes = ["claim_account", "reset", "invite"];

export interface UserInvitationViewProps {
  onAction: (password: string, password_confirmation: string, acceptTos: boolean) => void;
  serverErrors: string[];
}

export interface UserInvitationViewState {
  password: string;
  passwordChanged: boolean;
  passwordValid: boolean;
  passwordError: string;
  passwordConfirmation: string;
  passwordConfirmationChanged: boolean;
  passwordConfirmationValid: boolean;
  passwordConfirmationError: string;
  acceptTos: boolean;
}

interface UserInvitationProps {
  $stateParams: ng.ui.IStateParamsService;
  $state: ng.ui.IStateService;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & UserInvitationProps;

export type InvitationTypes = "reset" | "invite" | "claim_account" | "";
export interface UserInvitationState {
  invitationType: InvitationTypes;
  token: string;
  lead: boolean;
  serverErrors: string[];
}

export class UserInvitationContainer extends React.Component<Props, UserInvitationState> {
  public constructor(props) {
    super(props);

    this.state = {
      invitationType: "",
      token: "",
      lead: false,
      serverErrors: [],
    };

    this.claimInvitation = this.claimInvitation.bind(this);
  }
  public componentDidMount() {
    const { $state, $stateParams } = this.props;
    const state = _.last($state.current.name.split("."));

    let invitationType = _.find(resetTypes, (t: string) => state.indexOf(t) >= 0) as InvitationTypes;

    switch (state) {
      case "invitation":
        invitationType = "invite";
        break;
      case "claim_org":
        invitationType = "claim_account";
        break;
    }

    this.setState({
      token: $stateParams.token,
      lead: $stateParams.lead,
      invitationType: invitationType,
    });
  }

  public claimInvitation(password: string, password_confirmation: string, acceptTos: boolean): Promise<any> {
    const { lead, token, invitationType } = this.state;
    const { $state, resetPassword } = this.props;

    const user: any = {
      password: password,
      password_confirmation: password_confirmation,
      accepted_tos: acceptTos,
    };

    return resetPassword(token, invitationType, user)
      .then((data: any) => {
        if (lead) {
          $state.go("root.leads_list");
        } else {
          $state.go(config.get("DEFAULT_ROUTE"));
        }
      })
      .catch((response) => {
        if (response.status === 422) {
          this.setState({
            serverErrors: ["Invalid or Expired reset request."],
          });
        }
      });
  }

  public render() {
    const { serverErrors, invitationType, token } = this.state;

    if (!token) {
      return <TokenRequiredView />;
    }

    switch (invitationType) {
      case "reset":
        return <ResetPasswordView serverErrors={serverErrors} onAction={this.claimInvitation} />;
      case "invite":
        return <InvitationView serverErrors={serverErrors} onAction={this.claimInvitation} />;
      case "claim_account":
        return <ClaimOrgView serverErrors={serverErrors} onAction={this.claimInvitation} />;
      default:
        return <span>Nothing</span>;
    }
  }
}

export default connector(UserInvitationContainer);
