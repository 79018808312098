import * as React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import { currentImageId, image } from "app2/src/selectors/image.selectors";
import * as imageActions from "app2/src/reducers/image.actions";
import track from "react-tracking";
import { RsfFormGroup } from "app2/src/components/RsfForms/RsfFormGroup";
import { Form as FinalForm } from "react-final-form";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";

const mapStateToProps = (state: RootState) => {
  const imageId = currentImageId(state);
  return {
    imageId: imageId,
    image: image(state, { imageId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    saveForm: (formData: any) => dispatch(imageActions.AsyncActions.saveForm(formData)),
    setCurrentImageId: () => dispatch(imageActions.Actions.setCurrentImageId(null)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImageEditorModalState {
  show: boolean;
}

interface ImageEditorModalProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImageEditorModalProps;
@track((props) => {
  return {
    component: "ImageEditorModal",
    image: props.imageId,
  };
})
class ImageEditorModal extends React.Component<Props, ImageEditorModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { imageId } = this.props;
    if (_.isNull(prevProps.imageId) && !_.isNullOrUndefined(imageId)) {
      this.setState({ show: true });
    }
  }

  @track(() => ({
    action: "save photo title and description",
  }))
  public onSubmit(formData): void {
    const { saveForm } = this.props;
    saveForm(formData);
    this.onHide();
  }

  @track(() => ({
    action: "close modal",
  }))
  public onHide(): void {
    this.props.setCurrentImageId();
    this.setState({ show: false });
  }

  public render() {
    const { image, imageId } = this.props;
    const { show } = this.state;

    if (_.isNullOrUndefined(imageId)) {
      return null;
    }

    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Image Editor</h3>
          </Modal.Title>
        </Modal.Header>
        <FinalForm
          onSubmit={this.onSubmit}
          initialValues={{
            id: imageId,
            title: image.title,
            description: image.description,
          }}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col>
                    <RsfFormGroup label="Title" name="title" component="input" placeholder="Title" />
                    <RsfFormGroup
                      label="Description"
                      name="description"
                      as="textarea"
                      component="input"
                      placeholder="Description"
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <ButtonFooter cancel={this.onHide} />
              </Modal.Footer>
            </form>
          )}
        </FinalForm>
      </Modal>
    );
  }
}

export default connector(ImageEditorModal);
