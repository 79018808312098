import { RecipientRecord, IRecipientData, fromJSON as recipientFromJSON } from "app2/src/records/Recipient";
import { Record, List, Map, fromJS } from "immutable";
import { Nullable } from ".";
import { IDocumentData } from "app2/src/records/Document";

export const fromJSON = (json: Partial<ISignedDocumentData>): SignedDocumentRecord => {
  const record: ISignedDocumentRecord = { ...(json as any) };

  if (json.recipients) {
    record.recipients = List(
      json.recipients.map((r) => {
        return recipientFromJSON(r);
      }),
    );
  }

  if (json.metadata) {
    record.metadata = fromJS(json.metadata);
  }

  return new SignedDocumentRecord(record);
};

export const allSigned = (sd: SignedDocumentRecord) => {
  const unsigned = sd.recipients.find((r) => {
    return r.status !== "recipient_signed";
  });

  return unsigned === undefined;
};

export interface ISignedDocumentData {
  id: number;
  estimate_id: number;
  recipients: Array<Partial<IRecipientData>>;
  status: string;
  url: string;
  platform: string;
  metadata: any;
  document: IDocumentData;
  document_id: Nullable<number>;
  signed_document_id: Nullable<number>;
  validation_status: Nullable<number>;
  message: string;
  loading: boolean;
}

export interface ISignedDocumentRecord {
  id: Nullable<number>;
  estimate_id: Nullable<number>;
  status: Nullable<string>;
  url: Nullable<string>;
  document_id: Nullable<number>;
  signed_document_id: Nullable<number>;
  recipients: List<RecipientRecord>;
  platform: Nullable<string>;
  metadata: Map<string, any>;
  loading: boolean;
  validation: Nullable<Map<string, any>>;
  validation_status: Nullable<number>;
  message: Nullable<string>;
}

export const defaultSignedDocumentProps = {
  id: 0,
  estimate_id: null,
  status: null,
  url: null,
  recipients: List<RecipientRecord>(),
  loading: false,
  platform: "scribble",
  metadata: Map(),
  document_id: null,
  signed_document_id: null,
  validation: null,
  validation_status: null,
  message: null,
};

export class SignedDocumentRecord
  extends Record<ISignedDocumentRecord>(defaultSignedDocumentProps)
  implements ISignedDocumentRecord {}
