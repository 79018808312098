import * as ng from "angular";
import { SignedDocListCtrl } from "./SignedDocListCtrl";

export class SignedDocRoutes {
  public static load(app: ng.IModule) {
    app.controller("SignedDocListCtrl", SignedDocListCtrl);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider.state("root.signed_doc_list", <ng.ui.IState>{
      url: "/signed_docs?page&per&by&order&q",
      views: {
        "container@": {
          templateUrl: "src/SignedDocuments/list.html",
          controller: "SignedDocListCtrl",
          controllerAs: "$ctrl",
        },
      },
      resolve: resolveAuth,
    });
  }
}
