import { PreferenceRecord } from "app2/src/records/Preference";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { orgService } from "app2/src/api/org.service";

export interface ISetupFormData {
  pricingView: string;
  show_estimated_dates: string;
  show_discount_detail: string;
  show_customer_phone: string;
  show_customer_email: string;
}

export enum AlwaysNever {
  always = "always",
  never = "never",
}

export enum PricingView {
  name_and_description = "name_and_description",
  quantity_and_uom = "quantity_and_uom",
  all_details = "all_details",
  custom = "custom",
}

enum NameAndDescription {
  show_detailed_measurements = "company_only",
  show_detailed_pricing = "company_only",
  show_detailed_unit_price = "company_only",
}

enum QuantityAndUom {
  show_detailed_measurements = "always",
  show_detailed_pricing = "company_only",
  show_detailed_unit_price = "company_only",
}

enum AllDetails {
  show_detailed_measurements = "always",
  show_detailed_pricing = "always",
  show_detailed_unit_price = "always",
}

export enum PricingViewImages {
  name_and_description = "/assets/images/admin-setup/name_and_description.9564b378.png",
  quantity_and_uom = "/assets/images/admin-setup/quantity_and_uom.4ad8cdb3.png",
  all_details = "/assets/images/admin-setup/all_details.819c5cbc.png",
}

export enum DiscountDetailImages {
  always = "/assets/images/admin-setup/discount_always.720f1d4c.png",
  never = "/assets/images/admin-setup/discount_never.58552cb9.png",
}

export enum EstimatedDatesImages {
  always = "/assets/images/admin-setup/estimated_dates_always.e0146133.png",
  never = "/assets/images/admin-setup/estimated_dates_never.ffc89f60.png",
}

export enum CoverPagePhoneImages {
  always = "/assets/images/admin-setup/cover_page_phone_always.e987dadb.png",
  never = "/assets/images/admin-setup/cover_page_phone_never.57899f30.png",
}

export enum CoverPageEmailImages {
  always = "/assets/images/admin-setup/cover_page_email_always.2146b3dd.png",
  never = "/assets/images/admin-setup/cover_page_email_never.1642b96f.png",
}

export type PricingViewTypes = keyof typeof PricingView;

export const setupForm = (preference: PreferenceRecord): ISetupFormData => {
  const estimator = preference.config.get("estimator");
  const cover_page = preference.config.get("cover_page");
  if (!estimator || !cover_page) {
    return {
      pricingView: PricingView.all_details,
      show_estimated_dates: "always",
      show_discount_detail: "always",
      show_customer_phone: "always",
      show_customer_email: "always",
    } as ISetupFormData;
  }

  const check = [];
  _.each(Object.keys(NameAndDescription), (key) => {
    check.push(estimator.get(key));
  });

  let pricingView = null;
  switch (check.join(",")) {
    case "company_only,company_only,company_only":
      pricingView = PricingView.name_and_description;
      break;
    case "always,company_only,company_only":
      pricingView = PricingView.quantity_and_uom;
      break;
    case "always,always,always":
      pricingView = PricingView.all_details;
      break;
    default:
      pricingView = PricingView.custom;
      break;
  }

  return {
    pricingView: pricingView,
    show_estimated_dates: estimator.get("show_estimated_dates"),
    show_discount_detail: estimator.get("show_discount_detail"),
    show_customer_phone: cover_page.get("show_customer_phone"),
    show_customer_email: cover_page.get("show_customer_email"),
  };
};

export const saveForm = (org: OrgRecord, formData: ISetupFormData) => {
  let preferences = org.preferences;

  let theEnum = null;
  switch (formData.pricingView) {
    case PricingView.name_and_description:
      theEnum = NameAndDescription;
      break;
    case PricingView.quantity_and_uom:
      theEnum = QuantityAndUom;
      break;
    case PricingView.all_details:
      theEnum = AllDetails;
      break;
  }

  if (!_.isNull(theEnum)) {
    _.each(Object.keys(theEnum), (key) => {
      preferences = preferences.setIn(["config", "estimator", key], theEnum[key]);
    });
  }

  preferences = preferences
    .setIn(["config", "estimator", "show_estimated_dates"], formData.show_estimated_dates)
    .setIn(["config", "estimator", "show_discount_detail"], formData.show_discount_detail)
    .setIn(["config", "cover_page", "show_customer_phone"], formData.show_customer_phone)
    .setIn(["config", "cover_page", "show_customer_email"], formData.show_customer_email);

  orgService.updateOrg(org.set("preferences", preferences));
};
