import { IChameleonEvent } from "app/src/Visualization/Chameleon/ChameleonEvent";
import { IVisualization, IVisualizationResource } from "app/src/Models/Visualization";
import { IJob } from "app/src/Models/Job";
export class ChameleonService {
  public baseChameleon = "https://remotesfcontractor-v1.chameleonpower.com";
  public _chameleonCode = "?ccode=";

  constructor(
    public Visualization: IVisualizationResource,
    public $q: ng.IQService,
  ) {}

  public handleEvents(job: IJob, event: any, vis: IVisualization): ng.IPromise<IVisualization> {
    const deferred: ng.IDeferred<IVisualization> = this.$q.defer();

    if (event.origin.indexOf(this.baseChameleon) !== 0) {
      deferred.resolve();
      return deferred.promise;
    }
    //"{"specifier":"RemoteSF_USER_318d7474c01846eb9a0e3e101215f736","sel":"363318","title":"Test","email":"","action":"save","ccode":"678498"}"

    const e: IChameleonEvent = JSON.parse(event.data);
    switch (e.action) {
      case "save":
        if (vis) {
          vis.data = e;
          return vis.$save();
        } else {
          return this._createVisualization(job, "chameleon", e.projectname, e);
        }
    }

    deferred.resolve();
    return deferred.promise;
  }

  private _createVisualization(job, type: string, name: string, data: any): ng.IPromise<IVisualization> {
    return this.Visualization.create({ job_id: job.id, name: name, kind: type, data: data }).$promise.then(
      (vis: IVisualization) => {
        job.visualizations.unshift(vis);
        return vis;
      },
    );
  }
}
