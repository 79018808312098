import { Record, List } from "immutable";
import { Nullable } from ".";
import { fromJSON as imageFromJSON, toJSON as imageToJSON, ImageRecord, IImageData } from "app2/src/records/Image";

export const fromJSON = (json: Partial<IToolData>): ToolRecord => {
  const data: IToolRecord = { ...(json as any) };

  if (json.brand_image) {
    data.brand_image = imageFromJSON(json.brand_image);
  } else {
    data.brand_image = imageFromJSON({});
  }

  return new ToolRecord(data);
};

export const toJSON = (record: ToolRecord): IToolData => {
  const Tool: IToolData = record.toJSON() as any as IToolData;

  if (Tool.id <= 0) {
    delete Tool.id;
  }

  return Tool;
};

export const toFormData = (record: ToolRecord): any => {
  const data = new FormData();
  if (record.id > 0) {
    data.set("tool[id]", record.id as any);
  }
  data.set("tool[name]", record.name);
  data.set("tool[link]", record.link);
  data.set("tool[kind]", record.kind);
  data.set("tool[org_id]", record.org_id as any);
  if (record.brand_image) {
    if (record.brand_image.id > 0 && record.brand_image._destroy) {
      data.set("tool[brand_image_attributes][id]", record.brand_image.id.toString());
      data.set("tool[brand_image_attributes][_destroy]", record.brand_image._destroy.toString());
    } else if (record.brand_image.file_queue) {
      data.set("tool[brand_image_attributes][file]", record.brand_image.file_queue);
    }
  }
  return data;
};

export interface IToolData {
  id: number;
  brand_image: any;
  name: string;
  link: string;
  kind: string;
  org_id: number;
  archived: boolean;
  loading?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface IToolRecord {
  id: number;
  brand_image: ImageRecord;
  name: string;
  link: string;
  kind: string;
  org_id: number;
  archived: boolean;

  loading: boolean;
  errors: Nullable<List<string>>;

  created_at: Date;
  updated_at: Date;
}

const defaultToolProps: IToolRecord = {
  id: 0,
  brand_image: null,
  name: "",
  link: "",
  kind: "",
  org_id: null,
  archived: false,
  loading: false,
  errors: List<string>(),
  created_at: new Date(),
  updated_at: new Date(),
};

export class ToolRecord extends Record(defaultToolProps) implements IToolRecord {
  public readonly id!: number;
  public readonly brand_image!: ImageRecord;
  public readonly name!: string;
  public readonly link!: string;
  public readonly kind!: string;
  public readonly org_id!: Nullable<number>;
  public readonly archived!: boolean;

  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IToolRecord>) {
    values ? super(values) : super();
  }
}
