import * as React from "react";
import { useTracking } from "react-tracking";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { SelectProposal } from "./SelectProposal";
import { ViewProposal } from "./ViewProposal";
import { selectedEstimateId as selectedEstimateIdSelector } from "app2/src/selectors/job.selectors";
import { useDispatch, useSelector } from "react-redux";
import { replace } from "connected-react-router/immutable";

export const ProposalsTab: React.FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { Track } = useTracking<any>({
    component: "ProposalsTab",
    category: "Proposals",
  });
  const selectedEstimateId = useSelector(selectedEstimateIdSelector);

  React.useEffect(() => {
    if (selectedEstimateId && selectedEstimateId !== "new") {
      dispatch(replace(`${match.url}/${selectedEstimateId}`));
    }
  }, []);

  return (
    <Track>
      <Switch>
        <Route path={`${match.url}/:estimateId`} component={ViewProposal} />
        <Route path={`${match.url}`} component={SelectProposal} />
      </Switch>
    </Track>
  );
};
