import * as analyticsActions from "./analytics.actions";
import { Record, Map } from "immutable";
import { EventData } from "./analytics.actions";

const EventPagination = Record({
  total_count: 0,
  total_pages: 0,
  page: 1,
  pages: Map<number, typeof EventPageRecord>(),
});

const UserPagination = Record({
  total_count: 0,
  total_pages: 0,
  page: 1,
  pages: Map<number, typeof UserEventPageRecord>(),
});

export const EventPageRecord = Record({
  loading: false,
  data: [] as EventData[],
  pagination: {},
});

export const UserEventPageRecord = Record({
  loading: false,
  data: [] as analyticsActions.IUserAnalyticsData[],
  pagination: {},
});

export const AnalyticStateRecord = Record({
  eventsById: Map<number, EventData>(),
  eventPagination: EventPagination(),
  userPagination: UserPagination(),
});

export const initialState = AnalyticStateRecord();
export type AnalyticState = typeof initialState;

export const reducer = (state = initialState, action: analyticsActions.Actions): AnalyticState => {
  switch (action.type) {
    case analyticsActions.FETCH_PAGE:
      if (!state.getIn(["eventPagination", "pages", action.payload])) {
        return state
          .setIn(["eventPagination", "page"], action.payload)
          .setIn(["eventPagination", "pages", action.payload], EventPageRecord({ loading: true }));
      }
      return state
        .setIn(["eventPagination", "page"], action.payload)
        .setIn(["eventPagination", "pages", action.payload, "loading"], true)
        .setIn(["eventPagination", "pages", action.payload, "data"], []);
    case analyticsActions.RESET_PAGES:
      return state.setIn(["eventPagination", "pages"], Map());
    case analyticsActions.RECEIVE_PAGE:
      return state
        .setIn(["eventPagination", "pages", action.payload.page, "data"], action.payload.data)
        .setIn(["eventPagination", "pages", action.payload.page, "loading"], false)
        .setIn(["eventPagination", "pages", action.payload.page, "pagination"], action.payload.pagination)
        .setIn(["eventPagination", "total_count"], action.payload.pagination.total_count)
        .setIn(["eventPagination", "total_pages"], action.payload.pagination.total_pages);
    case analyticsActions.FETCH_USER_PAGE:
      if (!state.getIn(["userPagination", "pages", action.payload])) {
        return state
          .setIn(["userPagination", "page"], action.payload)
          .setIn(["userPagination", "pages", action.payload], EventPageRecord({ loading: true }));
      }
      return state
        .setIn(["userPagination", "page"], action.payload)
        .setIn(["userPagination", "pages", action.payload, "loading"], true)
        .setIn(["userPagination", "pages", action.payload, "data"], []);
    case analyticsActions.RESET_USER_PAGES:
      return state.setIn(["userPagination", "pages"], Map());
    case analyticsActions.RECEIVE_USER_PAGE:
      return state
        .setIn(["userPagination", "pages", action.payload.page, "data"], action.payload.data)
        .setIn(["userPagination", "pages", action.payload.page, "loading"], false)
        .setIn(["userPagination", "pages", action.payload.page, "pagination"], action.payload.pagination)
        .setIn(["userPagination", "total_count"], action.payload.pagination.total_count)
        .setIn(["userPagination", "total_pages"], action.payload.pagination.total_pages);
    default:
      return state;
  }
};
