import { checkDate, Nullable } from "app2/src/records";
import { toOneLiner } from "app2/src/records/Address";
import { JobRecord } from "app2/src/records/Job";
import { IJobBasicData, JobBasicRecord, fromJSON as jobBasicFromJson } from "app2/src/records/JobBasic";
import { Record } from "immutable";
import { addAuthUser } from "app2/src/components/Common/CustomLink";
import * as config from "react-global-configuration";
import { EventMetadataRecord, IEventMetadata, fromJSON as eventMetadataFromJSON } from "app2/src/records/EventMetadata";

export const fromJSON = (json: Partial<IEventData>): EventRecord => {
  const recordData: IEventRecord = { ...(json as any) };

  if (json.job) {
    recordData.job = jobBasicFromJson(json.job);
  }

  if (json.start_time) {
    recordData.start_time = checkDate(json.start_time);
  }

  if (json.end_time) {
    recordData.end_time = checkDate(json.end_time);
  }

  if (json.metadata) {
    recordData.metadata = eventMetadataFromJSON(json.metadata);
  }

  return new EventRecord(recordData);
};

export const initDescription = (job: JobRecord, accessUid: string): string => {
  const location = `Location:\n${toOneLiner(job.address)}\n\n`;
  const additionalInfo = job.additional_info ? `Additional Info:\n${job.additional_info}\n\n` : "";
  const phoneNumbers = `Phone Numbers:\n${job.phone_numbers.map((pn) => `${pn.name}: ${pn.number}`).join("\n")}\n\n`;
  const link = addAuthUser(`${config.get("APP_URL")}/jobs/${job.id}`, accessUid);
  return `${location}${additionalInfo}${phoneNumbers}Link: ${link}`;
};

export interface IEventData {
  id: number;
  summary: string;
  start_time: string | Date;
  end_time: string | Date;
  job_id: Nullable<number>;
  job?: IJobBasicData;
  description: string;
  metadata?: IEventMetadata;
  events_result_id?: number;
  events_result_reason_id?: number;
  notes?: string;
  user_id: Nullable<number>;
  org_id: Nullable<number>;
  editable?: boolean;
}

export interface IEventRecord {
  id: number;
  summary: string;
  start_time: Nullable<Date>;
  end_time: Nullable<Date>;
  job_id: Nullable<number>;
  job: Nullable<JobBasicRecord>;
  description: string;
  metadata?: EventMetadataRecord;
  events_result_id: Nullable<number>;
  events_result_reason_id: Nullable<number>;
  notes: Nullable<string>;
  user_id: Nullable<number>;
  org_id: Nullable<number>;
  editable: boolean;

  loading: boolean;
}

export const defaultEventProps = {
  id: 0,
  summary: "",
  start_time: null,
  end_time: null,
  job_id: null,
  job: null,
  description: "",
  metadata: null,
  events_result_id: null,
  events_result_reason_id: null,
  notes: null,
  user_id: null,
  org_id: null,
  editable: true,
  loading: false,
};

export class EventRecord extends Record(defaultEventProps) implements IEventRecord {
  public readonly id!: number;
  public readonly summary!: string;
  public readonly start_time!: Date;
  public readonly end_time!: Date;
  public readonly job!: Nullable<JobBasicRecord>;
  public readonly editable!: boolean;
  public readonly metadata!: EventMetadataRecord;
}
