import { FlashLevels } from "app/src/Common/FlashLevels";
import { connect, ConnectedProps } from "app2/src/connect";
import { checkoutParams, cleanupGreenSky, loadGreenSkyModalOnGlobal } from "app2/src/api/greenSky.service";
import ErrorPage from "app2/src/components/Common/ErrorPage";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { JobRecord } from "app2/src/records/Job";
import { RootActions, RootState } from "app2/src/reducers";
import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as config from "react-global-configuration";
import track from "react-tracking";

const mapStateToProps = (state: RootState, ownProps: GreenSkyApplicationProps) => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: GreenSkyApplicationProps,
) => {
  return {
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface GreenSkyApplicationProps {
  job: JobRecord;
  dealer: string;
  basePlan: string;
  financedAmount: number;
  closeModal: () => void;
}

interface GreenSkyApplicationState {
  greenSkyLoaded: boolean;
  error: boolean;
  errorMsg: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & GreenSkyApplicationProps;

@track(
  (props) => {
    return {
      component: "Green Sky Application",
      dealer: props.dealer,
      basePlan: props.basePlan,
      financedAmount: props.financedAmount,
    };
  },
  { dispatchOnMount: (cd) => ({ action: "shown" }) },
)
class GreenSkyApplication extends React.Component<Props, GreenSkyApplicationState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      greenSkyLoaded: false,
      error: false,
      errorMsg: "",
    };
    this.applicationDeclined = this.applicationDeclined.bind(this);
    this.applicationSuccess = this.applicationSuccess.bind(this);
  }

  public setupListeners() {
    window.addEventListener("greensky.closemodal", this.props.closeModal);
    window.addEventListener("greensky.application.success", this.applicationSuccess);
    window.addEventListener("greensky.declined", this.applicationDeclined);
  }

  public removeListeners() {
    window.removeEventListener("greensky.closemodal", this.props.closeModal);
    window.removeEventListener("greensky.application.success", this.applicationSuccess);
    window.removeEventListener("greensky.declined", this.applicationDeclined);
  }

  @track({ action: "applicationSuccess" })
  public applicationSuccess() {}

  @track({ action: "applicationDeclined" })
  public applicationDeclined() {}

  public async componentDidMount() {
    const { job, financedAmount, dealer, basePlan, addFlashMessage } = this.props;
    // Test Environment Setup
    // let promise = loadGreenSkyApplicationOnGlobal({
    //   GS_DEALER: "81001234", // org integrations preference
    //   GS_BASE_PLAN: "9999", // finance
    //   GS_PROGRAM: "GreenSky Consumer Projects",
    //   GS_PROMO: "",
    //   GS_API_KEY: "bWVyY2hhbnQxMDI6bWVyY2hhbnQxMDI=",
    //   GS_EXPERIENCE: 1,
    //   GS_ENV: 1,
    // });

    try {
      const promise = loadGreenSkyModalOnGlobal({
        GS_DEALER: dealer, // org integrations preference
        GS_BASE_PLAN: basePlan, // finance
        GS_PROGRAM: "GreenSky Consumer Projects",
        GS_PROMO: "",
        GS_API_KEY: config.get("GREEN_SKY_API_KEY"),
        GS_EXPERIENCE: 1,
        GS_ENV: config.get("GREEN_SKY_ENV"),
      });

      await promise;
      this.setState({
        greenSkyLoaded: true,
      });

      // @ts-ignore
      await window.greensky.checkout(checkoutParams(job, financedAmount));
      this.setupListeners();
    } catch (e) {
      const msg = "Invalid GreenSky Merchant ID or Plan";

      addFlashMessage(FlashLevels.danger, msg);
      // @ts-ignore
      if (window.greensky) {
        // @ts-ignore
        window.greensky.checkoutModal.close();
      }

      this.setState({
        error: true,
        errorMsg: msg,
      });
    }
  }

  @track({ action: "closeWindow" })
  public componentWillUnmount() {
    this.removeListeners();
    cleanupGreenSky();
  }

  public render() {
    const { greenSkyLoaded, error, errorMsg } = this.state;

    if (!greenSkyLoaded) return <SpinnerComponent localProperty={true} />;

    if (error) return <ErrorPage message={errorMsg} />;

    return <h1 className="green-sky"></h1>;
  }
}

export default connector(GreenSkyApplication);
