import * as React from "react";
import * as FontAwesome from "react-fontawesome";
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import Comparator from "./Comparator";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { EstimateRecord } from "app2/src/records/Estimate";
import {
  estimateComparison,
  estimateComparisonLinks,
  expandDrawer,
} from "app2/src/selectors/estimateComparison.selectors";
import { List } from "immutable";
import { EstimateComparisonLinkRecord } from "app2/src/records/EstimateComparisonLink";
import { org } from "app2/src/selectors/org.selectors";
import { getPricePresentationName, OrgRecord } from "app2/src/records/OrgRecord";
import { EstimateComparisonRecord } from "app2/src/records/EstimateComparison";
import track from "react-tracking";
import { calculatedFinancingsEstimatesLoading } from "app2/src/selectors/calculatedFinancing.selectors";
import { RootDispatchType } from "app2/src/store";

function mapStateToProps(state: RootState, ownProps: IDrawerContainerProps): ReduxProps {
  const { jobId, orgId } = ownProps;
  const comparison = estimateComparison(state, { jobId: jobId });
  const estimateIds = comparison.estimate_comparison_links.map((ecl) => ecl.estimate_id);
  return {
    expandDrawer: expandDrawer(state),
    estimateComparisonLinks: estimateComparisonLinks(state, { jobId: jobId }),
    estimateComparison: comparison,
    calculatedFinancingsEstimatesLoading: calculatedFinancingsEstimatesLoading(state, { estimateIds }),
    org: org(state, { orgId: orgId }),
  };
}

function mapDispatchToProps(dispatch: RootDispatchType, ownProps: IDrawerContainerProps) {
  const { orgId } = ownProps;
  return {
    getEstimate: (estimateId: number) => dispatch(estimateActions.AsyncActions.getEstimate(estimateId, orgId)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IDrawerContainerProps {
  orgId: number;
  jobId: number;
  dirty: boolean;
  goToEstimate: (estimateId: number) => void;
  drawerToggle: () => void;
  saveComparison: (e: any) => void;
}

interface DispatchProps {
  getEstimate(id: number): Promise<void>;
}

interface ReduxProps {
  expandDrawer: boolean;
  estimateComparison: EstimateComparisonRecord;
  estimateComparisonLinks: List<EstimateComparisonLinkRecord>;
  calculatedFinancingsEstimatesLoading: boolean;
  org: OrgRecord;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IDrawerContainerProps & DispatchProps & ReduxProps;

@track({ component: "Drawer Container" })
class DrawerContainer extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onRefresh = this.onRefresh.bind(this);
  }

  public componentDidMount() {
    const { expandDrawer } = this.props;

    if (expandDrawer) {
      window.addEventListener("beforeunload", this.onRefresh);
      this.loadEstimates();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { expandDrawer } = this.props;
    if (expandDrawer && !prevProps.expandDrawer) {
      window.addEventListener("beforeunload", this.onRefresh);
      this.loadEstimates();
    } else if (!expandDrawer && prevProps.expandDrawer) {
      window.removeEventListener("beforeunload", this.onRefresh);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onRefresh);
  }

  @track({ action: "beforeunload onRefresh" })
  public onRefresh(e) {
    if (!this.props.dirty) {
      return undefined;
    } else {
      const msg = "There are unsaved changes that will not show in the compare. Would you like to save changes?";
      e.returnValue = msg;
      return msg;
    }
  }

  @track({ action: "Load Estimates" })
  public loadEstimates() {
    const { estimateComparisonLinks, getEstimate } = this.props;
    estimateComparisonLinks.forEach((ecl) => {
      if (ecl.estimate_id > 0) {
        getEstimate(ecl.estimate_id);
      }
    });
  }

  public render() {
    const {
      orgId,
      org,
      jobId,
      expandDrawer,
      estimateComparison,
      calculatedFinancingsEstimatesLoading,
      dirty,
      drawerToggle,
      goToEstimate,
      saveComparison,
    } = this.props;

    return (
      <React.Fragment>
        <Accordion className="bottom" activeKey={expandDrawer ? "0" : ""}>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              onClick={drawerToggle}
              className="drawer-header"
              data-testid="accordion-toggle">
              <Row>
                <Col sm={11}>
                  <h2>{getPricePresentationName(org)}</h2>
                </Col>
                <Col sm={1}>
                  {expandDrawer ? (
                    <Button
                      className="save-estimate-comparison"
                      onClick={saveComparison}
                      disabled={!dirty || estimateComparison.loading || calculatedFinancingsEstimatesLoading}>
                      {estimateComparison.loading || calculatedFinancingsEstimatesLoading ? (
                        <FontAwesome name="spinner" className="fa-pulse" data-testid="button-spinner" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  ) : null}
                  <FontAwesome
                    name={`${expandDrawer ? "angle-down" : "angle-up"}`}
                    data-testid={`${expandDrawer ? "angle-down" : "angle-up"}`}
                    title="Expand"
                    size="2x"
                    className="drawer-arrow"
                  />
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {expandDrawer && <Comparator orgId={orgId} jobId={jobId} goToEstimate={goToEstimate} />}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {expandDrawer && <div className="background-overlay" />}
      </React.Fragment>
    );
  }
}

export default connector(DrawerContainer);
