import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateLineItem, EstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { IRoomEstimation } from "app/src/Models/RoomEstimation";

export interface IEstimateGroup {
  id: number;
  name: string;
  included: boolean;
  subtotal: number;
  total: number;
  labor_total: number;
  product_total: number;
  line_items: IEstimateLineItem[];
  room_estimations: IRoomEstimation[];
  created_at: Date;
  updated_at: Date;
  sort_order: number;
  _destroy: boolean;
  $$hashKey: string;

  getId(): number;
  decycleToJSON(): any;
  updateTotal(): number;
  existingLineItems(): IEstimateLineItem[];
}

export class EstimateGroup implements IEstimateGroup {
  public id: number;
  public name: string;
  public included: boolean;
  public subtotal: number;
  public total: number;
  public labor_total: number;
  public product_total: number;
  public line_items: IEstimateLineItem[] = [];
  public room_estimations: IRoomEstimation[] = [];
  public created_at: Date;
  public updated_at: Date;
  public sort_order: number;
  public _destroy: boolean;
  public $$hashKey: string;

  constructor(estimate: IEstimate, data?: any) {
    if (data) {
      if (!_.isUndefined(data.id)) {
        this.id = data.id;
      }
      if (!_.isUndefined(data.name)) {
        this.name = data.name;
      }
      if (!_.isUndefined(data.included)) {
        this.included = data.included;
      }
      if (!_.isUndefined(data.sort_order)) {
        this.sort_order = data.sort_order;
      }
      if (!_.isUndefined(data.line_items)) {
        this.line_items = data.line_items;
      }
      if (!_.isUndefined(data.created_at)) {
        this.created_at = data.created_at;
      }
      if (!_.isUndefined(data.updated_at)) {
        this.updated_at = data.updated_at;
      }
      if (!_.isUndefined(data.subtotal)) {
        this.subtotal = data.subtotal;
      }
      if (!_.isUndefined(data.total)) {
        this.total = data.total;
      }
      if (!_.isUndefined(data.room_estimations)) {
        this.room_estimations = data.room_estimations;
      }
    }

    if (!this.id) {
      this.id = estimate.getId();
    }
  }

  public getId(): number {
    const firstId = -1;

    const currentMin = _.chain(this.line_items).pluck("id").min().value();

    return _.min([firstId, currentMin - 1]);
  }

  public updateTotal(): number {
    const totals = _.chain([this])
      .map((g: IEstimateGroup) => {
        return g.line_items;
      })
      .flatten()
      .compact()
      .reduce(
        (sum: any, item: IEstimateLineItem) => {
          if (item._destroy) {
            return sum;
          }

          sum.total = _.round(sum.total + item.ext_price, -2);
          sum.labor = sum.labor + _.round(item.quantity * item.labor_price, -2);
          sum.product = sum.product + _.round(item.quantity * item.product_price, -2);
          _.each(item.options, (option: IEstimateLineItemOption) => {
            if (option._destroy || option.pog_type === "cost") {
              return;
            }
            sum.total = sum.total + option.ext_price;
            sum.labor = sum.labor + option.calculateLaborPrice();
            sum.product = sum.product + option.calculateProductPrice();
          });

          return sum;
        },
        { total: 0, product: 0, labor: 0 },
      )
      .value();
    this.subtotal = totals.total;
    this.product_total = totals.product;
    this.labor_total = totals.labor;
    this.total = this.subtotal;

    return this.total;
  }

  public existingLineItems() {
    return _.filter(this.line_items, (g) => {
      return g._destroy !== true;
    });
  }

  public static transformRequest(group: any): any {
    if (group.id < 0) {
      delete group.id;
    }

    group.line_items_attributes = group.line_items;
    group.room_estimations_attributes = group.room_estimations;

    // clean out temp ID from "new" line items
    _.each(group.line_items_attributes, (lineItem: IEstimateLineItem) => {
      if (lineItem.id <= 0) {
        delete lineItem.id;
      }

      delete lineItem.estimateGroup;
      delete lineItem.orig_estimate_group_id;
      if (lineItem.estimate_group_id <= 0) {
        delete lineItem.estimate_group_id;
      }

      (lineItem as any).options_attributes = lineItem.options;
      _.each((lineItem as any).options_attributes, (option: IEstimateLineItemOption) => {
        if (option.id <= 0) {
          delete option.id;
        }
        delete option.estimate_line_item;
      });

      delete lineItem.options;

      (lineItem as any).images_attributes = lineItem.images;
      (lineItem as any).documents_attributes = lineItem.documents;
      (lineItem as any).opening_estimations_attributes = lineItem.opening_estimations;
      delete lineItem.images;
      delete lineItem.documents;
    });

    delete group.estimate;
    delete group.line_items;
    delete group.product_total;
    delete group.labor_total;
    delete group.room_estimations;

    return group;
  }

  public decycleToJSON() {
    const data = JSON.decycle(this);

    if (this.line_items) {
      _.each(this.line_items, (lineItem, idx) => {
        data.line_items[idx] = lineItem.decycleToJSON();
      });
    }

    return data;
  }

  public static fromJSON(estimate: IEstimate, json: any): IEstimateGroup {
    if (json.created_at) {
      json.created_at = new Date(json.created_at as string);
    }
    if (json.updated_at) {
      json.updated_at = new Date(json.updated_at as string);
    }

    const doOrder = !_.any(json.line_items, (item: any) => {
      return item.sort_order;
    });

    const estimateGroup = new EstimateGroup(estimate, json);
    _.each(json.line_items, (li, index) => {
      estimateGroup.line_items[index] = EstimateLineItem.fromJSON(estimateGroup, li);

      if (doOrder) {
        estimateGroup.line_items[index].sort_order = index;
      }
    });

    estimateGroup.line_items = _.sortBy(estimateGroup.line_items, "sort_order");

    return estimateGroup;
  }
}
