import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { Row, Col, Modal, Form, FormControl, FormGroup, FormLabel, Button } from "react-bootstrap";
import { AddressShow } from "app2/src/components/Address/AddressShow";
import { GoogleMap } from "app2/src/components/Common/GoogleMap";
import { AddressRecord } from "app2/src/records/Address";
import { FlashLevels } from "app/src/Common/FlashService";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as reportActions from "app2/src/reducers/report.actions";
import * as config from "react-global-configuration";
import { fromJSON, ReportRecord } from "app2/src/records/Report";
import * as integrationsViewActions from "app2/src/reducers/components/integrationsView.actions";
import { is } from "immutable";
import Spinner from "app2/src/components/SpinnerComponent";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";
import { currentJob } from "app2/src/selectors/job.selectors";
import { quickMeasureProductCodes } from "app/src/Common/Constants";

const mapStateToProps = (state: RootState, ownProps: QuickMeasureOrderProps) => {
  return {
    authorized: state.getIn(["integrations", "quickMeasure", "authorized"], false),
    currentUser: denormalizedReduxUser(state),
    openModal: state.getIn(["components", "integrations", "showQuickMeasureModal"]),
    job: currentJob(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: QuickMeasureOrderProps) => {
  return {
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
    submitReport: (jobId: number, report: ReportRecord) =>
      dispatch(reportActions.AsyncActions.addJobReport(jobId, report)),
    closeModal: () => dispatch(integrationsViewActions.Actions.closeQuickMeasureModal()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface QuickMeasureOrderProps {
  address: AddressRecord;
  product_code: string;
}

interface QuickMeasureOrderState {
  address: AddressRecord;
  loading: boolean;
  product_code: string;
  question: string;
  instructions: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & QuickMeasureOrderProps;

class QuickMeasureOrder extends React.Component<Props, QuickMeasureOrderState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      address: null,
      loading: false,
      product_code: "SF-QM-USA",
      question: "",
      instructions: "",
    };

    this.request = this.request.bind(this);
    this.update = this.update.bind(this);
    this.mapChange = this.mapChange.bind(this);
    this.close = this.close.bind(this);
    this.handleProductCode = this.handleProductCode.bind(this);
    this.handleStructures = this.handleStructures.bind(this);
    this.handleInstructions = this.handleInstructions.bind(this);
  }

  public componentDidUpdate(prevProps) {
    const { authorized, addFlashMessage, address, openModal } = this.props;
    if (!openModal) {
      return;
    }

    if (!is(address, prevProps.address)) {
      if (authorized) {
        this.update(address);
      } else {
        addFlashMessage(
          FlashLevels.danger,
          "QuickMeasure is not authorized. Contact your Organization Administrator to enable QuickMeasure Measurements directly from " +
            config.get("APP_NAME") +
            ".",
        );
        this.props.closeModal();
      }
    }
  }

  public handleProductCode(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    this.setState({ product_code: value });
  }

  public handleStructures(e: React.ChangeEvent<HTMLInputElement>): void {
    const { id } = e.target;
    this.setState({ question: id });
    switch (id) {
      case "primary_structure":
        this.setState({
          instructions: "Primary Structure Only - Please include only the primary structure in my report.",
        });
        break;
      case "all_structures":
        this.setState({
          instructions: "All Structures on Parcel - Please include all structures on the property in my report.",
        });
        break;
      case "all_except_primary_structure":
        this.setState({
          instructions:
            "All Structures Except Primary Structure - Please include all structures except the primary structure in my report.",
        });
        break;
    }
  }

  public handleInstructions(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    this.setState({ instructions: value });
  }

  public async request(e) {
    const { currentUser, job } = this.props;
    const { address, product_code, instructions } = this.state;
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      loading: true,
    });
    try {
      await this.props.submitReport(
        job.id,
        fromJSON({
          kind: "roofing",
          provider: "quick_measure",
          job_id: job.id,
          user_id: currentUser.id,
          order_data: { app_address: address, product_code, instructions },
        }),
      );
      this.props.closeModal();
    } catch (error) {
      console.error(error);
    }
    this.setState({
      loading: false,
    });
  }

  public update(address: AddressRecord) {
    this.setState({
      address: address,
      loading: false,
    });
  }

  public mapChange(e) {
    let { address } = this.state;
    address = address.merge({
      lat: e.center.lat,
      lon: e.center.lng,
    });
    this.update(address);
  }

  public close() {
    this.props.closeModal();
  }

  public render() {
    const { openModal, authorized } = this.props;
    const { address, loading, product_code, instructions } = this.state;
    const address_loading = _.isNull(address) || _.isUndefined(address);
    if (!authorized) {
      return <span />;
    }

    return (
      <Modal className="quick-measure-modal" show={openModal} onHide={this.close} size="lg" backdrop={"static"}>
        <Spinner localProperty={loading} />
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>GAF QuickMeasure Request</h3>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.request}>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <FormLabel>Product Type</FormLabel>
                  <FormControl
                    as="select"
                    type="text"
                    id="product_type"
                    value={product_code}
                    onChange={this.handleProductCode}>
                    {quickMeasureProductCodes.map(({ label, value, description }, index) => (
                      <option key={index} value={value} title={description}>
                        {label}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            {address_loading ? (
              "Loading..."
            ) : (
              <React.Fragment>
                <AddressShow update={this.update} address={address} />
                <GoogleMap address={address} googleMapOptions={{ tilt: 0 }} mapChange={this.mapChange} />
              </React.Fragment>
            )}
            <Row className="mt-4">
              <Col>
                <h4 className="mb-1">Structures</h4>
                <p>What structures would you like included in your reports?</p>
                <Form.Check
                  className="mt-2"
                  label="Primary Structure Only"
                  type="radio"
                  checked={this.state.question === "primary_structure"}
                  onChange={this.handleStructures}
                  id="primary_structure"
                />
                <Form.Check
                  className="mt-2"
                  label="All Structures on Parcel"
                  type="radio"
                  checked={this.state.question === "all_structures"}
                  onChange={this.handleStructures}
                  id="all_structures"
                />
                <Form.Check
                  className="mt-2"
                  label="All Structures Except Primary Structure"
                  type="radio"
                  checked={this.state.question === "all_except_primary_structure"}
                  onChange={this.handleStructures}
                  id="all_except_primary_structure"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormGroup>
                  <FormLabel htmlFor="instructions">Instructions</FormLabel>
                  <FormControl
                    id="instructions"
                    type="text"
                    as="textarea"
                    value={instructions}
                    onChange={this.handleInstructions}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" type="submit">
              Request
            </Button>
            <Button type="button" variant="cancel" onClick={this.close}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default connector(QuickMeasureOrder);
