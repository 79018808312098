import { ActionsUnion, createAction } from "./Utils";
import { IProductData } from "app2/src/records/Product";
import { Map } from "immutable";

export const RECEIVE_PRODUCT = "@products/RECEIVE_PRODUCT";

export const Actions = {
  receiveProduct: (product: IProductData, options: any = Map()) => createAction(RECEIVE_PRODUCT, { product, options }),
};

export type Actions = ActionsUnion<typeof Actions>;
