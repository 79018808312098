import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../../Common/IBaseConfig";

export interface ISubscription extends ng.resource.IResource<ISubscription>, SubscriptionPrototype {}

export interface ISubscriptionResource extends ng.resource.IResourceClass<ISubscription>, IRsfResource {
  fromJSON?(data: any): ISubscription;
}

export interface ISubscriptionResponse extends ng.resource.IResourceArray<ISubscription> {
  plans: ISubscription[];
  meta: IPagingMetadata;
}

class SubscriptionPrototype {
  public id: number;
  public stripe_id: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): ISubscriptionResource => {
  const url = BaseConfig.BASE_URL + "/billing/v1/subscriptions/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): ISubscription => {
    if (status < 200 || status > 299) {
      return new Subscription({});
    }

    return Subscription.fromJSON(JSON.parse(response).subscription);
  };

  const multipleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): ISubscriptionResponse => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);
    _.each(data.subscriptions, (subscription, index) => {
      data.subscriptions[index] = Subscription.fromJSON(subscription);
    });

    return data;
  };

  const Subscription: ISubscriptionResource = <ISubscriptionResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Subscription.prototype, SubscriptionPrototype.prototype);

  Subscription.fromJSON = (data: any): ISubscription => {
    return new Subscription(data);
  };

  Subscription.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Subscription;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
