import { IPagingMetadata } from "../../Models/PagingMetadata";
import { ICharge } from "./Charge";
import { IRepository } from "../../Common/Repository";
import { IBaseConfig } from "../../Common/IBaseConfig";

export interface IOrder extends ng.resource.IResource<IOrder>, OrderPrototype {}

export interface IOrderResource extends ng.resource.IResourceClass<IOrder> {
  fromJSON?(data: any): IOrder;
  inject(repository: IRepository): void;
}

export interface IOrderResponse extends ng.resource.IResourceArray<IOrder> {
  orders: IOrder[];
  meta: IPagingMetadata;
}

class OrderPrototype {
  public amount: number;
  public amount_returned: number;
  public application: string;
  public application_fee: string;
  public billing_customer_id: number;
  public created: string;
  public created_at: Date;
  public currency: string;
  public email: string;
  public id: number;
  public livemode: string;
  public metadata: any;
  public returns: any;
  public selected_shipping_method: string;
  public shipping: any;
  public shipping_methods: string;
  public status: string;
  public status_transitions: string;
  public stripe_charge: string;
  public stripe_coupon: string;
  public stripe_customer: string;
  public stripe_data: any;
  public stripe_id: string;
  public stripe_updated: string;
  public updated_at: Date;
  public charge: ICharge;

  public refunded(): boolean {
    if (!this.charge) {
      return false;
    }

    if (this.charge.refunds.length <= 0) {
      return false;
    }

    return true;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IOrderResource => {
  const url = BaseConfig.BASE_URL + "/billing/v1/orders/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IOrder => {
    if (status < 200 || status > 299) {
      return new Order({});
    }

    return Order.fromJSON(JSON.parse(response).order);
  };

  const multipleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IOrderResponse => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);
    _.each(data.orders, (order, index) => {
      data.orders[index] = Order.fromJSON(order);
    });

    return data;
  };

  const transformRequest = (order: IOrder): string => {
    const data = JSON.decycle(order);
    return angular.toJson({ order: data });
  };

  const Order: IOrderResource = <IOrderResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Order.prototype, OrderPrototype.prototype);

  Order.fromJSON = (data: any): IOrder => {
    return new Order(data);
  };

  Order.inject = (repository: IRepository) => {
    resources = repository;
  };

  return Order;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
