import { RootState } from "../reducers";
import { createSelector } from "reselect";
import { EstimateFinanceOptionRecord, IEstimateFinanceOptionData } from "../records/EstimateFinanceOption";
import { List } from "immutable";
import { calculatedFinancing } from "app2/src/selectors/calculatedFinancing.selectors";

export const estimateFinanceOptionsById = (state: RootState) => state.getIn(["estimateFinanceOptions", "byId"]);
export const lastSavedEstimateFinanceOptionsById = (state: RootState) =>
  state.getIn(["estimateFinanceOptions", "lastSavedById"]);
export const estimateFinanceOption = (state: RootState, props: any): EstimateFinanceOptionRecord =>
  state.getIn(["estimateFinanceOptions", "byId", props.estimateFinanceOptionId]);
export const financeOptionsById = (state: RootState) => state.getIn(["orgs", "financeOptions", "byId"]);

export const updateEstimateFinanceOptionPayments = createSelector(
  [estimateFinanceOption, (state, props) => ({ state, props })],
  (estimateFinanceOption, stateProps) => {
    const { state, props } = stateProps;
    const { financedAmount } = props;
    const calcFinancing = calculatedFinancing(state, {
      financeOptionId: estimateFinanceOption.finance_option_id,
      financedAmount,
    });

    if (!calcFinancing || calcFinancing.loading) {
      return estimateFinanceOption;
    }

    return estimateFinanceOption.merge({
      monthly_payment: calcFinancing.get("monthly_payment"),
      promo_payment: calcFinancing.get("promo_payment"),
    });
  },
);

export const estimateFinanceOptions = createSelector(
  [estimateFinanceOptionsById, (_: RootState, props: any) => props],
  (
    estimateFinanceOptionsById: Map<number, EstimateFinanceOptionRecord>,
    props: any,
  ): List<EstimateFinanceOptionRecord> => {
    return estimateFinanceOptionsProcess(estimateFinanceOptionsById, props);
  },
);

export const lastSavedEstimateFinanceOptions = createSelector(
  [lastSavedEstimateFinanceOptionsById, (_: RootState, props: any) => props],
  (
    estimateFinanceOptionsById: Map<number, EstimateFinanceOptionRecord>,
    props: any,
  ): List<EstimateFinanceOptionRecord> => {
    return estimateFinanceOptionsProcess(estimateFinanceOptionsById, props);
  },
);

const estimateFinanceOptionsProcess = (estimateFinanceOptionsById, props) => {
  const { estimateFinanceOptionIds, fullEstimate } = props;

  return estimateFinanceOptionIds
    .map((foId: number) => {
      const fo = estimateFinanceOptionsById.get(foId) as EstimateFinanceOptionRecord;
      return fo;
    })
    .filter((fo: EstimateFinanceOptionRecord) => {
      return fullEstimate || !fo._destroy;
    })
    .toList();
};

export const toEstimateFinanceOptionsJson = createSelector(
  [estimateFinanceOptions],
  (estimateFinanceOptions: List<EstimateFinanceOptionRecord>): IEstimateFinanceOptionData[] => {
    return estimateFinanceOptions
      .map((fo: EstimateFinanceOptionRecord) => {
        const foData = fo.toJSON() as any;

        if (!foData._destroy) {
          delete foData._destroy;
        }

        return foData as IEstimateFinanceOptionData;
      })
      .toArray();
  },
);
