import * as ng from "angular";
import { IOrgResource } from "app/src/Models/Org";
import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { IFlash } from "app/src/Common/FlashService";
import { IDirtyWatcher } from "app/src/Common/DirtyWatcher";
import * as angulartics from "angulartics";
import { IReportResource } from "app/src/Models/Report";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { IUserResource, IUser } from "app/src/Models/User";

export class OrgIntegrationsTabCtrl extends BaseOrgTabCtrl {
  public users: IUser[];

  public static $inject = [
    "$uibModal",
    "OrgFetcher",
    "Org",
    "Report",
    "Flash",
    "User",
    "$stateParams",
    "$state",
    "$http",
    "DirtyWatcher",
    "$scope",
    "$analytics",
    "BaseConfig",
  ];
  constructor(
    protected $uibModal: ng.ui.bootstrap.IModalService,
    public OrgFetcher: IOrgFetcherService,
    protected Org: IOrgResource,
    protected Report: IReportResource,
    protected Flash: IFlash,
    protected User: IUserResource,
    public $stateParams: ng.ui.IStateParamsService,
    protected $state: ng.ui.IStateService,
    protected $http: ng.IHttpService,
    public DirtyWatcher: IDirtyWatcher,
    public $scope: ng.IScope,
    protected $analytics: angulartics.IAnalyticsService,
    protected BaseConfig: IBaseConfig,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.User.query({
      org_id: this.$stateParams.id,
      version: "v2",
    }).$promise.then((data: any) => {
      this.users = data.users;
    });
  }
}
