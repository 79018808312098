import Spinner from "app2/src/components/SpinnerComponent";
import { connect } from "app2/src/connect";
import { defaultAccount, IAccountData } from "app2/src/records/billing/Account";
import { PlanFamily } from "app2/src/records/billing/Plan";
import { fromJSON as planSubFromJSON } from "app2/src/records/billing/PlanSubscription";
import { RootActions, RootState } from "app2/src/reducers";
import * as accountActions from "app2/src/reducers/billing/account.actions";
import * as planActions from "app2/src/reducers/billing/plan.actions";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import PlanSubscriptionViewer from "./PlanSubscriptionViewer";

export interface IAccountPlanManagerProps {
  account: IAccountData;
}

export interface IAccountPlanManagerState {
  accountJSON: string;
}

function mapStateToProps(state: RootState, ownProps: IAccountPlanManagerProps) {
  return {
    planFamilies: state.getIn(["billing", "plans", "planFamilies"]),
    accountRecord: state.getIn(["billing", "accounts", "byId", ownProps.account.id], defaultAccount),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IAccountPlanManagerProps) {
  return {
    loadPlans: () => dispatch(planActions.AsyncActions.listPlans({ archived: false })),
    importAccount: (account: IAccountData) => dispatch(accountActions.Actions.receiveAccount(account)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IAccountPlanManagerProps;

class AccountPlanManager extends React.Component<Props, IAccountPlanManagerState> {
  public constructor(props) {
    super(props);

    this.state = {
      accountJSON: "",
    };
  }

  public componentDidMount() {
    const { account, loadPlans, importAccount } = this.props;
    if (account) {
      (account as any).$promise.then(() => {
        importAccount(account);
        this.setState({
          accountJSON: JSON.stringify(this.props.account),
        });
      });
    }

    loadPlans();
  }

  public componentDidUpdate() {
    const { account, importAccount } = this.props;
    const { accountJSON } = this.state;

    const newJSON = JSON.stringify(account);
    if (accountJSON !== newJSON) {
      importAccount(account);
      this.setState({
        accountJSON: newJSON,
      });
    }
  }

  public render() {
    const { accountRecord, planFamilies } = this.props;

    return (
      <Spinner stateProperty={["billing", "plans", "loading"]}>
        <Spinner stateProperty={["billing", "accounts", "byId", accountRecord.id, "loading"]}>
          <Row>
            <Col md={12}>
              {planFamilies.map((pf: PlanFamily, idx: number) => {
                let planSub = accountRecord.plan_subscriptions.find((ps) => ps.family === pf);
                if (!planSub) {
                  planSub = planSubFromJSON({ family: pf });
                }

                return <PlanSubscriptionViewer key={idx} account={accountRecord} planSubscription={planSub} />;
              })}
            </Col>
          </Row>
        </Spinner>
      </Spinner>
    );
  }
}

export default connector(AccountPlanManager);
