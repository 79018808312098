import { IOrg } from "../Models/Org";
import { IEstimate, ISalesTaxable } from "../Models/Estimate";
import { IPretty } from "app/src/Common/PrettyNameService";

class SalesTaxEditorCtrl {
  public salesTaxable: ISalesTaxable;
  public estimate: IEstimate;
  public org: IOrg;
  public updated: (param: { salesTaxable: ISalesTaxable }) => void;
  public showingTax = false;
  public defaultTaxableAmountValues: string[] = ["none", "product", "labor", "product_labor"];

  constructor(public Pretty: IPretty) {}

  public $onInit() {
    this.showingTax = false;
  }

  public $onChanges() {
    if (this.estimate) {
      this.salesTaxable = this.estimate.cloneSalesTax();
    }
  }

  public $doCheck() {
    if (this.estimate && !angular.equals(this.salesTaxable, this.estimate.cloneSalesTax())) {
      // If it's already initialized, the default_taxable_amount is set, and the tax_rate or taxable_amount is undefined, then return
      if (
        this.salesTaxable.default_taxable_amount !== undefined &&
        (this.salesTaxable.tax_rate === undefined || this.salesTaxable.taxable_amount === undefined)
      ) {
        return;
      }
      this.salesTaxable = this.estimate.cloneSalesTax();
    }
  }

  public updateTax() {
    if (this.salesTaxable.tax_rate === undefined || this.salesTaxable.taxable_amount === undefined) {
      return;
    }

    if (this.updated) {
      if (angular.equals(this.salesTaxable, this.estimate.cloneSalesTax())) {
        return;
      }
      this.updated({ salesTaxable: this.salesTaxable });
    }
  }
}

export class SalesTaxEditor implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    estimate: "<",
    updated: "&",
    org: "<",
  };
  public templateUrl = "src/Estimator/salesTaxEditor.html";

  constructor() {
    this.controller = SalesTaxEditorCtrl;
    this.controller.$inject = ["Pretty"];
  }
}
