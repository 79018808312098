import { RootState } from "app2/src/reducers";
import { AsyncActions } from "app2/src/reducers/event.actions";
import * as eventResultActions from "app2/src/reducers/eventResult.actions";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as eventResultReasonActions from "app2/src/reducers/eventResultReason.actions";
import { currentJobId } from "app2/src/selectors/job.selectors";
import * as React from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hasIntegrations } from "app2/src/selectors/event.selectors";
import { SendInviteModal } from "./SendInviteModal";
import { ResultAppointmentModal } from "./ResultAppointmentModal";
import { Route, Switch, useRouteMatch } from "react-router";
import { push } from "connected-react-router/immutable";
import { EventCalendarModal } from "./EventCalendarModal";
import { EventRow } from "./EventRow";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { queryParamsFromJSON } from "app2/src/records/Page";
import { currentPage, getPaginationByModel, pageRecord } from "app2/src/selectors/pagination.selectors";
import SpinnerComponent from "../../SpinnerComponent";
import { PaginationControl } from "../../Common/PaginationControl";
import { useTracking } from "react-tracking";
import * as FontAwesome from "react-fontawesome";
import { I360ResultAppointmentModal } from "app2/src/components/JobTabs/AppointmentsTab/I360ResultAppointmentModal";
import * as tokenActions from "app2/src/reducers/token.actions";

export const AppointmentsTab: React.FC = () => {
  const modelName = "events";
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch();

  // Selectors
  const jobId = useSelector(currentJobId);
  const orgId = useSelector(currentOrgId);
  const jobEvents = useSelector((state: RootState) =>
    getPaginationByModel(state, { path: [modelName, "byId"], modelName }),
  );
  const { hasMarketSharp, hasI360, hasSR, hasSalesForce, hasLeadPerfection } = React.useMemo(
    () => hasIntegrations(jobEvents),
    [jobEvents],
  );
  const loading = useSelector((state: RootState) => {
    return Boolean(pageRecord(state, { modelName, page: currentPage(state, { modelName }) })?.get("loading"));
  });
  const { Track, trackEvent } = useTracking<any>({ component: "AppointmentsTab", category: "Appointment", job: jobId });

  // Lifecycle
  React.useEffect(() => {
    loadEvents(1);

    return () => {
      dispatch(paginationActions.Actions.clearPage("events"));
      dispatch(paginationActions.Actions.clearPage("userEvents"));
    };
  }, []);

  React.useEffect(() => {
    if (orgId) {
      dispatch(eventResultActions.AsyncActions.getEventResults(orgId));
      dispatch(eventResultReasonActions.AsyncActions.getEventResultReasons(orgId));
    }
  }, [dispatch, orgId]);

  React.useEffect(() => {
    if (orgId && hasI360) dispatch(tokenActions.AsyncActions.getToken(orgId, "improveit360"));
  }, [dispatch, hasI360, orgId]);

  // Methods
  const openEventCalendarModal = () => {
    trackEvent({ action: "add appointment button" });
    dispatch(push(`${match.url}/calendar`));
  };

  const loadEvents = (page: number) => {
    dispatch(
      AsyncActions.loadEvents(
        queryParamsFromJSON({
          page: page,
          per_page: 10,
          parameters: { jobId: jobId?.toString() },
        }),
      ),
    );
  };

  return (
    <>
      <SpinnerComponent localProperty={loading} />
      <Track>
        <Row>
          <Col>
            <Row>
              <Col>
                <h1 className="tab-title">Appointments</h1>
              </Col>
              <Col>
                <Button className="pull-right add-appt" onClick={openEventCalendarModal}>
                  <FontAwesome name="plus" />
                  &nbsp; Add Appointment
                </Button>
              </Col>
            </Row>
            {jobEvents?.size === 0 && (
              <div className="form-section blank-state">
                <img src="/assets/images/icons-large/calendar.052935c6.png" />
                <h2>No appointments added. Let's add one.</h2>
                <p>Click on the add appointment button in the top right to add one.</p>
              </div>
            )}
            {jobEvents?.size > 0 && (
              <Row>
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Description</th>
                      {hasMarketSharp && <td>MarketSharp</td>}
                      {hasI360 && <td>Improveit360</td>}
                      {hasSR && <td>SalesRabbit</td>}
                      {hasSalesForce && <td>Salesforce</td>}
                      {hasLeadPerfection && <td>LeadPerfection</td>}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobEvents?.map((event) => (
                      <EventRow
                        event={event}
                        key={event.id}
                        jobHasCRM={hasMarketSharp || hasI360 || hasSR || hasSalesForce || hasLeadPerfection}
                      />
                    ))}
                  </tbody>
                </Table>
              </Row>
            )}

            <PaginationControl modelName={modelName} pageChanged={loadEvents} />

            <Switch>
              <Route path={`${match.url}/result/i360/:eventId`} component={I360ResultAppointmentModal} />
              <Route path={`${match.url}/result/:eventId`} component={ResultAppointmentModal} />
              <Route path={`${match.url}/calendar`} component={EventCalendarModal} />
              <Route path={`${match.url}/invite/:eventId`} component={SendInviteModal} />
            </Switch>
          </Col>
        </Row>
      </Track>
    </>
  );
};

export default AppointmentsTab;
