import { Record } from "immutable";
import { Nullable } from ".";
import { fromJSON as fileFromJSON, FileRecord, IFileData } from "./File";

export const fromJSON = (json: Partial<ISlideData>): SlideRecord => {
  const data: ISlideRecord = { ...(json as any) };

  if (json.cover_image) {
    data.cover_image = fileFromJSON(json.cover_image);
  }

  return new SlideRecord(data);
};

export const toJSON = (record: SlideRecord): ISlideData => {
  const slide: ISlideData = record.toJSON() as any as ISlideData;

  if (slide.id <= 0) {
    delete slide.id;
  }

  return slide;
};

export interface ISlideData {
  id: number;
  cover_image: IFileData;
  html: string;
  name: string;
  presentation_id: number;
  sort_order: number;

  created_at: Date;
  updated_at: Date;
}

export interface ISlideRecord {
  id: number;
  cover_image: FileRecord;
  html: string;
  name: string;
  presentation_id: Nullable<number>;
  sort_order: number;
  loading: boolean;

  created_at: Date;
  updated_at: Date;
}

const defaultSlideProps: ISlideRecord = {
  id: 0,
  cover_image: null,
  html: "",
  name: "",
  presentation_id: null,
  sort_order: 0,
  loading: false,
  created_at: new Date(),
  updated_at: new Date(),
};

export class SlideRecord extends Record(defaultSlideProps) implements ISlideRecord {}
