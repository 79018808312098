import * as React from "react";
import { connectFC } from "app2/src/connect";
import { Col, Row } from "react-bootstrap";
import { OrgList } from "../Common/OrgList";
import { OrgListDefaultRow } from "../Common/OrgList/OrgListDefaultRow";
import { OrgListDefaultHeader } from "../Common/OrgList/OrgListDefaultHeader";
import { StoreRegistry } from "app2/src/storeRegistry";
export interface OrgAdminProps {
  filters: string[];
}

const OrgAdmin: React.FC<OrgAdminProps> = ({ filters }) => {
  const goToOrg = (id: number) => {
    const $state = StoreRegistry.get<ng.ui.IStateService>("$state");
    $state.go("root.org_show.info", { id });
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <OrgList
            HeaderComponent={OrgListDefaultHeader}
            RowComponent={OrgListDefaultRow}
            statusFilters={filters}
            rowClickHandler={goToOrg}
          />
        </Col>
      </Row>
    </>
  );
};

export default connectFC(OrgAdmin);
