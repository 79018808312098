import { Record } from "immutable";

export const fromJSON = (json: Partial<IIframeData>): IframeRecord => {
  const record: IIframeRecord = { ...(json as any) };

  return new IframeRecord(record);
};

export interface IIframeData {
  url: string;
  error: string;
}

export interface IIframeRecord {
  url: string;
  error: string;
}

const defaultIframeProps: IIframeRecord = {
  url: "",
  error: "Not a valid url",
};

export class IframeRecord extends Record(defaultIframeProps) implements IIframeRecord {}
