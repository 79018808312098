import { Record } from "immutable";

export const fromJSON = (locationData: Partial<ILocationData>): LocationRecord => {
  const recordData: ILocationRecord = { ...(locationData as any) };

  return new LocationRecord(recordData);
};

export interface ILocationData {
  pathname: string;
  search: string;
  hash: string;
  key: string;
  query: any;
}

export interface ILocationRecord {
  pathname: string;
  search: string;
  hash: string;
  key: string;
  query: any;
}

export const defaultLocationProps = {
  pathname: "",
  search: "",
  hash: "",
  key: "",
  query: {},
};

export class LocationRecord extends Record<ILocationRecord>(defaultLocationProps) implements ILocationRecord {}
