import * as React from "react";
import { Participant } from "twilio-video";
import useParticipantIsReconnecting from "app2/src/components/Twilio/hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";

export default function ParticipantConnectionIndicator({ participant }: { participant: Participant }) {
  const isReconnecting = useParticipantIsReconnecting(participant);
  return (
    <span
      className={isReconnecting ? "connection-indicator is-reconnecting" : "connection-indicator"}
      title={isReconnecting ? "Participant is reconnecting" : "Participant is connected"}></span>
  );
}
