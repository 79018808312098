import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { IDiscountData, formatKind, formatBreakdown, DiscountRecord } from "app2/src/records/DiscountRecord";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";

export interface DisplayProps {
  discount: IDiscountData;
  edit: (id: number) => void;
  archive: (id: number) => void;
}

export default class Display extends React.Component<DisplayProps, any> {
  constructor(props) {
    super(props);

    this.formatBreakdown = this.formatBreakdown.bind(this);
  }

  public formatBreakdown(discount): string {
    return "Breakdown";
  }
  public render() {
    const { discount, edit, archive } = this.props;

    return (
      <div className="form-section">
        <Row>
          <Col md={1}>
            <img className="dragHandle link" src="/assets/images/icons/ic_sort.ea6f8933.png" title="Order Discount" />
            &nbsp;&nbsp;
          </Col>
          <Col md={2}>{discount.name}</Col>
          <Col md={2}>{discount.description}</Col>
          <Col md={2}>{formatKind(discount.breakdown.kind)}</Col>
          <Col md={2}>{formatBreakdown(discount.breakdown)}</Col>
          <Col md={2}>
            <i className="rsf-edit-link rsf-base-66 action-button" onClick={() => edit(discount.id)} />
            <ConfirmDialog description="" title={`Are you sure you want to delete discount: ${discount.name}?`}>
              {(confirm) => (
                <i
                  className="rsf-delete-link rsf-base-66 action-button"
                  onClick={confirm(() => archive(discount.id))}
                />
              )}
            </ConfirmDialog>
          </Col>
        </Row>
        {this.showErrors(discount)}
      </div>
    );
  }

  public showErrors(discount) {
    if (discount.errors.size <= 0) {
      return null;
    }

    return (
      <Row>
        <Col md={12}>
          {discount.errors.map((error: string, idx) => {
            return (
              <div key={idx} className="has-error">
                {error}
              </div>
            );
          })}
        </Col>
      </Row>
    );
  }
}
