import * as React from "react";
import { useCallback } from "react";
import { EmbeddedView } from "app2/src/components/Homevest/EmbeddedView";
import { push } from "connected-react-router/immutable";
import { useDispatch } from "react-redux";
import Spinner from "app2/src/components/SpinnerComponent";
import { Context } from "app2/src/components/Homevest/Context";
import { useTracking } from "react-tracking";

export interface IApplyProps {}

export const Apply: React.FunctionComponent<IApplyProps> = (props: IApplyProps) => {
  const { financeApplication, dispatch: contextDispatch } = React.useContext(Context);
  const dispatch = useDispatch();
  const tracking = useTracking(
    {
      action: "Homevest Apply Shown",
    },
    {
      dispatchOnMount: true,
    },
  );

  const closeCallback = useCallback(() => {
    tracking.trackEvent({ action: "Apply closed" });
    dispatch(push("../.."));
  }, [dispatch]);

  const offersCallback = useCallback(() => {
    tracking.trackEvent({ action: "Application Offers received" });
    contextDispatch("pendingApproval");
    dispatch(push("../homevest/offers"));
  }, [dispatch]);

  if (!financeApplication || financeApplication.get("loading")) {
    return <Spinner localProperty={true} />;
  }

  return (
    <EmbeddedView url={financeApplication.redirect_url} closeHandler={closeCallback} offersRedirect={offersCallback} />
  );
};
