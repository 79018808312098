import { DocumentableType, IDocumentOptions } from "app2/src/api/document.service";
import { RootState } from "app2/src/reducers";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { ids, queryParams } from "app2/src/selectors/pagination.selectors";
import { pathname, queryNumber, queryString } from "app2/src/selectors/router.selectors";
import * as React from "react";
import { Button, ButtonGroup, Col, Row, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router/immutable";
import Folders from "app2/src/components/Folders";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import * as documentActions from "app2/src/reducers/document.actions";
import * as folderActions from "app2/src/reducers/folder.actions";
import { queryParamsFromJSON } from "app2/src/records/Page";
import { Document } from "app2/src/components/SignatureRequest/DocumentsChooser/Document";
import { PaginationControl } from "app2/src/components/Common/PaginationControl";
import { capitalize } from "humanize-plus";
import { SelectedDocuments } from "app2/src/components/SignatureRequest/DocumentsChooser/SelectedDocuments";
import { RequestName } from "app2/src/components/SignatureRequest/DocumentsChooser/RequestName";
import { Context } from "app2/src/components/SignatureRequest/Context";
import { IncludeCheckBox } from "../../Common/IncludeCheckBox";
import { document } from "app2/src/selectors/document.selectors";

export const DocumentsChooser: React.FunctionComponent = () => {
  // hooks
  const dispatch = useDispatch();

  // selectors
  const modelName = "documentsChooser";

  const [requestDocId, setRequestDocId] = React.useState(null);

  const documentableType = useSelector(
    (state: RootState) => (queryString(state, { queryKey: "documentable_type" }) as DocumentableType) || "job",
  );
  const folderParentId = useSelector((state: RootState) =>
    queryParams(state, { modelName: "folder" }).getIn(["folder_id"]),
  );
  const queryFolderParentId = useSelector((state: RootState) => queryNumber(state, { queryKey: "folder_parent_id" }));
  const reduxDocumentIds = useSelector((state: RootState) => ids(state, { modelName }));
  const jobId = useSelector(currentJobId);
  const orgId = useSelector(currentOrgId);
  const path = useSelector(pathname);
  const currentQueryParams = useSelector((state: RootState) => queryParams(state, { modelName }));
  const requestDoc = useSelector((state: RootState) => document(state, { documentId: requestDocId }));

  const { requestName, documentIds, dispatch: contextDispatch } = React.useContext(Context);

  React.useEffect(() => {
    if (requestName !== "") return;
    contextDispatch("setRequestName", { requestName: requestDoc?.get("name", "").split(".")[0] || "" });
  }, [requestDocId]);

  // methods
  const checkBoxOnChange = (event) => {
    if (event.target.checked) {
      if (!requestName) {
        setRequestDocId(reduxDocumentIds.get("0"));
      }
      reduxDocumentIds.forEach((id) => {
        contextDispatch("addDocumentId", { id });
      });
    } else {
      reduxDocumentIds.forEach((id) => {
        contextDispatch("removeDocumentId", { id });
      });
    }
  };

  const navigateButton = React.useCallback(
    (documentableType: DocumentableType) => {
      const queryParams = new URLSearchParams({
        documentable_type: documentableType as string,
      });
      dispatch(push(`${path}?${queryParams.toString()}`));
    },
    [path, dispatch],
  );

  const navigate = React.useCallback(
    (folderId: number) => {
      if (_.isNullOrUndefined(folderId)) {
        const queryParams = new URLSearchParams({
          documentable_type: documentableType as string,
        });
        dispatch(push(`${path}?${queryParams.toString()}`));
      } else {
        const queryParams = new URLSearchParams({
          documentable_type: documentableType as string,
          folder_parent_id: folderId.toString(),
        });
        dispatch(push(`${path}?${queryParams.toString()}`));
      }
    },
    [dispatch, documentableType],
  );

  const pageChanged = (page: number) => {
    const params = {
      content_type: "application/pdf",
      documentableType: documentableType,
      folder_id: folderParentId,
    } as Partial<IDocumentOptions>;
    if (documentableType === "org") {
      params.display = "resource";
    } else {
      params.signed = "unsigned";
    }

    const queryParamsRecord = currentQueryParams.set("page", page);
    dispatch(documentActions.AsyncActions.listDocuments(params, queryParamsRecord, modelName));
  };

  // lifecycle
  React.useEffect(() => {
    if (!_.isNullOrUndefined(folderParentId)) {
      pageChanged(1);
    }
  }, [dispatch, folderParentId]);

  React.useEffect(() => {
    dispatch(
      folderActions.AsyncActions.listFolders(queryFolderParentId, {
        folderable_type: capitalize(documentableType),
        name: "Documents",
      }),
    );
  }, [dispatch, queryFolderParentId, documentableType]);

  const allDocumentsSelected = React.useMemo(() => {
    if (reduxDocumentIds?.size) {
      const result = reduxDocumentIds.filter((docId) => !documentIds.some((selectedDocId) => selectedDocId === docId));
      return result.size === 0;
    }
    return false;
  }, [documentIds, reduxDocumentIds]);

  const DocumentsTableHeader = () => {
    return (
      <Row className="table-header pb-0">
        <Col sm={1} style={{ paddingLeft: "15px" }}>
          <IncludeCheckBox name="select-all" checked={allDocumentsSelected} onChange={checkBoxOnChange} />
        </Col>
        <Col lg={8} md={7}>
          Name
        </Col>
        <Col lg={2} md={3}>
          Upload Date
        </Col>
        <Col sm={1}>Actions</Col>
      </Row>
    );
  };

  return (
    <div>
      <Modal.Header closeButton>
        <h3>Create a Signature Request</h3>
      </Modal.Header>
      <Modal.Body>
        <Row className="pb-0">
          <Col>
            <Row className="pb-0">
              <Col>
                <ButtonGroup>
                  <Button
                    variant={documentableType === "job" ? "primary" : "light"}
                    onClick={() => navigateButton("job")}>
                    Job Documents
                  </Button>
                  <Button
                    variant={documentableType === "org" ? "primary" : "light"}
                    onClick={() => navigateButton("org")}>
                    Resources
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row className="pb-0">
              <Col xs={7} sm={7} md={8} lg={9} className="overflow-auto" id="documents_container">
                <Folders
                  readOnly={true}
                  jobId={jobId}
                  orgId={documentableType === "org" ? orgId : undefined}
                  folderParentId={folderParentId}
                  documentableType={documentableType}
                  fileType="documents"
                  navigate={navigate}
                />
                <br />
                {_.isNullOrUndefined(reduxDocumentIds) || reduxDocumentIds?.size === 0 ? (
                  <div className="form-section blank-state">
                    <img src="/assets/images/icons-large/docs.33cddf3f.png" />
                    <h2>No documents added. Let's add one.</h2>
                    <p>Navigate to the Job Documents tab to add documents for a signature request.</p>
                  </div>
                ) : null}
                <div className="standard-rl-margin">
                  {reduxDocumentIds?.size > 0 && <DocumentsTableHeader />}
                  {reduxDocumentIds &&
                    reduxDocumentIds.map((documentId) => (
                      <div key={documentId}>
                        <Document documentId={documentId} mode="adding" />
                      </div>
                    ))}
                  <PaginationControl modelName={modelName} pageChanged={pageChanged} />
                </div>
              </Col>
              <Col xs={5} sm={5} md={4} lg={3} id="selected_documents_container">
                <RequestName />
                <SelectedDocuments />
              </Col>
            </Row>
            <br />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-footer">
          <Button
            variant="save"
            type="submit"
            onClick={() => contextDispatch("combineDocuments")}
            disabled={requestName === "" || !documentIds?.size}>
            Create
          </Button>
          <Button variant="cancel" onClick={() => contextDispatch("close", { modalType: "select", destroy: false })}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
};
