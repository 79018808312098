import * as React from "react";
import { Can } from "app2/src/components/Common/CanComponent";
import { Row, Col } from "react-bootstrap";
import OrgAccessList from "../Common/OrgAccessList";

export interface OrgAccessChooserProps {
  $state: any;
}

export const OrgAccessChooser: React.FunctionComponent<OrgAccessChooserProps> = ({ $state }) => {
  return (
    <Can resource="org" permission="read">
      <Row className="justify-content-center">
        <Col xs={10} lg={6}>
          <OrgAccessList $state={$state} />
        </Col>
      </Row>
    </Can>
  );
};
