import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { Row, Col, Button } from "react-bootstrap";
import { RootDispatchType } from "app2/src/store";
import Folders from "app2/src/components/Folders";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { ILocationData } from "app2/src/records/Location";
import { push, replace } from "app2/src/reducers/router.actions";
import track, { TrackingProp } from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";
import { RootState } from "app2/src/reducers";
import Presentations from "app2/src/components/OrgTabs/PresentationTab/Presentations";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { presentationsSelected } from "app2/src/selectors/presentation.selectors";
import { Can } from "app2/src/components/Common/CanComponent";
import Files from "react-files";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as presentationActions from "app2/src/reducers/presentation.actions";
import * as folderActions from "app2/src/reducers/folder.actions";
import * as FontAwesome from "react-fontawesome";
import MoveFilesDropdown from "app2/src/components/Folders/MoveFilesDropdown";
import { IPresentationOptions } from "app2/src/records/Presentation";
import PresentationLinkModal from "app2/src/components/OrgTabs/PresentationTab/PresentationLinkModal";
import { queryParams } from "app2/src/selectors/pagination.selectors";
import { QueryParamsRecord } from "app2/src/records/Page";

const mapStateToProps = (state: RootState) => {
  return {
    folderParentId: queryParams(state, { modelName: "folder" }).getIn(["folder_id"]),
    orgId: currentOrgId(state),
    presentationsSelected: presentationsSelected(state, {}),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    listFolders: (folderParentId: number, replace: () => void) =>
      dispatch(
        folderActions.AsyncActions.listFolders(
          folderParentId,
          { folderable_type: "Org", name: "Presentations" },
          replace,
        ),
      ),
    listPresentations: (folderId: number) =>
      dispatch(
        presentationActions.AsyncActions.listPresentations(
          {
            kind: "org",
            folderId,
          },
          new QueryParamsRecord(),
        ),
      ),
    createPresentations: (files: any[], options: IPresentationOptions) =>
      dispatch(presentationActions.AsyncActions.batchCreatePresentation(files, options)),
    push: (location: Partial<ILocationData>) => dispatch(push(location)),
    replace: (location: Partial<ILocationData>) => dispatch(replace(location)),
    flashAddAlert: (level: FlashLevels, msg: string) => dispatch(commonActions.Actions.flashAddAlert(level, msg)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PresentationTabProps {
  queryFolderParentId: number;
  tracking?: TrackingProp;
}

interface PresentationTabState {
  loading: boolean;
  show: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentationTabProps;

@track(
  (props) => {
    return {
      category: "OrgPresentations",
      component: "OrgPresentationsTab",
      action: "show",
      org: props.orgId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
class PresentationTab extends React.Component<Props, PresentationTabState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      show: 0,
    };

    this.onFilesChange = this.onFilesChange.bind(this);
    this.onFilesError = this.onFilesError.bind(this);
    this.navigate = this.navigate.bind(this);
    this.addPresentationLink = this.addPresentationLink.bind(this);

    props.tracking.trackEvent({ action: "shown" });
  }

  public componentDidMount(): void {
    const { listFolders, orgId, queryFolderParentId, replace } = this.props;
    listFolders(queryFolderParentId, () =>
      replace({ pathname: "root.org_show.presentations", query: { id: orgId, folder_parent_id: null } }),
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    const { listPresentations, folderParentId } = this.props;

    if (!_.isNullOrUndefined(folderParentId) && prevProps.folderParentId !== folderParentId) {
      listPresentations(folderParentId);
    }
  }

  public navigate(folderId: number): void {
    const { orgId, push } = this.props;
    push({ pathname: "root.org_show.presentations", query: { id: orgId, folder_parent_id: folderId } });
  }

  public async onFilesChange(files: any[]): Promise<void> {
    const { tracking, createPresentations, folderParentId } = this.props;

    if (files.length > 0) {
      this.setState({ loading: true });
      await createPresentations(files, { kind: "default", folderId: folderParentId });
      files.forEach((file) => {
        tracking.trackEvent({ action: "presentation uploaded", document: file.id, file: file.name });
      });

      this.setState({ loading: false });
    }
  }

  public onFilesError(error: any, file: any): void {
    const { flashAddAlert, tracking } = this.props;
    flashAddAlert(FlashLevels.danger, error.message);
    tracking.trackEvent({ action: "invalid file", file: file.name });
  }

  @track(() => ({
    action: "open presentation link modal",
  }))
  public addPresentationLink() {
    this.setState((state) => ({
      show: state.show + 1,
    }));
  }

  public render() {
    const { loading, show } = this.state;
    const { orgId, folderParentId, presentationsSelected } = this.props;

    if (_.isNullOrUndefined(orgId)) {
      return null;
    }

    return (
      <Row data-testid="presentationTab">
        <SpinnerComponent localProperty={loading} />
        <PresentationLinkModal show={show} presentationId={-1} folderParentId={folderParentId} />
        <Col>
          <Row className="presentations title-row">
            <Col sm={4}>
              <h1 className="admin-title">Presentations</h1>
            </Col>
            <Col sm={8}>
              <Can resource="org" permission="update">
                <Files
                  className="files-dropzone pull-right"
                  onChange={this.onFilesChange}
                  onError={this.onFilesError}
                  accepts={[".pdf", ".html"]}
                  multiple
                  maxFileSize={30000000}
                  minFileSize={0}
                  clickable>
                  <Button variant="default">
                    <FontAwesome name="plus" /> Add Presentations
                  </Button>
                </Files>
                <Button variant="default" className="pull-right" onClick={this.addPresentationLink}>
                  <FontAwesome name="plus" /> Add Link
                </Button>
              </Can>
              {presentationsSelected && <MoveFilesDropdown {...this.props} fileType="presentations" />}
              <p className="text--small mb-0">You can upload PDFs and revealjs html</p>
            </Col>
          </Row>
          <Folders {...this.props} documentableType="org" navigate={this.navigate} fileType="presentations" />
          <br />
          <Presentations folderParentId={folderParentId} />
        </Col>
      </Row>
    );
  }
}

export default connector(PresentationTab);
