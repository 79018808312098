import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";
import { IOrg } from "app/src/Models/Org";
import Spinner from "app2/src/components/SpinnerComponent";
import { Modal, Col, Row, FormGroup, FormControl, FormLabel, Button } from "react-bootstrap";
import { IJob } from "app/src/Models/Job";
import { IEstimate } from "app/src/Models/Estimate";
import PaySimpleForm from "./PaySimple/PaySimpleForm";
import CardConnectForm from "./CardConnect/CardConnectForm";
import { AccountInfoRecord, fromJSON as accountInfoFromJSON } from "./AccountInfo.model";
import * as paymentActions from "app2/src/reducers/payment.actions";
import PaymentView from "./PaymentView";
import { DecimalInputComponent } from "../Common/DecimalInputComponent";
import { currentOrg } from "app2/src/selectors/org.selectors";
import { currentJob, selectedEstimateId } from "app2/src/selectors/job.selectors";
import { paymentAuth } from "app2/src/selectors/payment.selectors";
import { estimate } from "app2/src/selectors/estimate.selectors";
import { paymentProviders } from "app2/src/records/PaymentAuthorizationRecord";
import { firstName, lastName } from "app2/src/services/string.service";

const mapStateToProps = (state: RootState, ownProps: IMakePaymentProps) => {
  const org = currentOrg(state);
  const estimateId = selectedEstimateId(state);
  const auth = paymentAuth(state, { orgId: org?.id });
  return {
    payment: state.getIn(["payments", "lastPayment"], null),
    makingPayment: state.getIn(["payments", "makingPayment"], false),
    paymentError: state.getIn(["payments", "error"], null),
    providerLoading: state.getIn(["payments", "providerLoading"]),
    providerValid: state.getIn(["payments", "providerValid"]),
    resolve: {
      org: org?.toJS() as any as IOrg,
      job: currentJob(state)?.toJS() as IJob,
      estimate: estimate(state, { estimateId })?.toJS() as IEstimate,
      payment_provider: paymentProviders.find((v) => auth?.get(v as any)),
    },
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IMakePaymentProps) => {
  return {
    setProviderSubmitting: (submitting: boolean) => {
      return dispatch(paymentActions.Actions.providerSubmitting(submitting));
    },
    clearLastPayment: () => {
      return dispatch(paymentActions.Actions.clearLastPayment());
    },
    clearPaymentError: () => {
      return dispatch(paymentActions.Actions.paymentError(""));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IMakePaymentProps {
  closeModal: () => void;
  tracking?: TrackingProp;
  id?: string;
}

const track: Track<TrackingData, IMakePaymentProps> = _track;

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IMakePaymentProps;

export interface IMakePaymentState {
  accountInfo: AccountInfoRecord;
  submitAttempted: boolean;
}

@track(
  (props: Props) => {
    return {
      category: "Billing Payment",
      action: "Show",
      job: props.resolve.job?.id,
      org: props.resolve.org?.id,
      estimate: props.resolve?.estimate?.id,
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: true,
  },
)
class MakePayment extends React.Component<Props, IMakePaymentState> {
  public formRef: React.RefObject<HTMLFormElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      accountInfo: accountInfoFromJSON({}),
      submitAttempted: false,
    };

    this.formRef = React.createRef();

    this.renderForm = this.renderForm.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.formValid = this.formValid.bind(this);
  }

  public componentDidMount() {
    const { resolve } = this.props;

    const newState: Partial<IMakePaymentState> = {};

    if (resolve.job) {
      const name = resolve.job.customer_name;
      let amount = 0;

      if (resolve.estimate) {
        amount = resolve.estimate.total;
      }

      newState.accountInfo = accountInfoFromJSON({
        firstName: firstName(name),
        lastName: lastName(name),
        email: resolve.job.email || "",
        description: `${resolve.job.customer_name} - ${resolve.job.name}` || "",
        postalCode: resolve.job.address.postal_code || "",
        amount: amount,
      });
    } else if (resolve.estimate) {
      newState.accountInfo = newState.accountInfo.set("amount", resolve.estimate.total);
    }

    this.setState(newState as IMakePaymentState);
  }

  public componentWillUnmount() {
    const { clearLastPayment, clearPaymentError, setProviderSubmitting } = this.props;

    clearLastPayment();
    clearPaymentError();
    setProviderSubmitting(false);
  }

  public handleInput(e: any): void {
    //@ts-ignore
    const value = e.target.value;
    //@ts-ignore
    const name = e.target.id;

    let { accountInfo } = this.state;
    switch (name) {
      case "amount":
        accountInfo = accountInfo.set(name, parseFloat(value || "0"));
        break;
      default:
        accountInfo = accountInfo.set(name, value);
        break;
    }

    this.setState({ accountInfo });
  }

  public handleNumberChange(value: number, name: string) {
    let { accountInfo } = this.state;

    accountInfo = accountInfo.set(name as any, value);

    this.setState({ accountInfo });
  }

  public render() {
    const { payment, makingPayment, paymentError, providerLoading } = this.props;

    let caseValue = "form";
    if (paymentError) {
      caseValue = "error";
    }

    if (payment) {
      caseValue = "payment";
    }

    return (
      <Spinner localProperty={providerLoading || makingPayment}>
        {(() => {
          switch (caseValue) {
            case "form":
              return this.renderForm();
            case "error":
              return this.renderError(paymentError);
            case "payment":
              return this.renderPayment(payment);
          }
        })()}
      </Spinner>
    );
  }

  public renderPayment(payment) {
    const { closeModal } = this.props;
    return (
      <React.Fragment>
        <Modal.Header key={4} closeButton>
          <Modal.Title>
            <div className="row-eq-height">
              <Col md={12}>
                <h3>Payment Result</h3>
              </Col>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentView payment={payment} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="cancel" type="button" className="pull-right" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }

  public renderError(error) {
    const { closeModal } = this.props;
    return (
      <React.Fragment>
        <Modal.Header key={0} closeButton>
          <Modal.Title>
            <div className="row-eq-height">
              <Col md={12}>
                <h3>Payment Error</h3>
              </Col>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{error}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="cancel" type="button" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }

  public renderForm() {
    const { providerValid, resolve, closeModal } = this.props;
    const { accountInfo, submitAttempted } = this.state;

    return (
      <form ref={this.formRef} id="paysimple-form" className="form" onSubmit={this.submitForm}>
        <Modal.Header key="header" closeButton>
          <Modal.Title>
            <div className="row-eq-height">
              <Col md={12}>
                <h3>Make a Payment</h3>
              </Col>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body key="body">
          <Row>
            <Col md={6}>
              <FormGroup>
                <FormLabel>First Name</FormLabel>
                <FormControl
                  type="text"
                  id="firstName"
                  value={accountInfo.firstName}
                  onChange={this.handleInput}
                  required></FormControl>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormLabel>Last Name</FormLabel>
                <FormControl
                  type="text"
                  id="lastName"
                  value={accountInfo.lastName}
                  onChange={this.handleInput}
                  required></FormControl>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormLabel>Email</FormLabel>
                <FormControl
                  type="email"
                  id="email"
                  value={accountInfo.email}
                  onChange={this.handleInput}
                  required></FormControl>
              </FormGroup>
            </Col>
            {resolve.payment_provider === "card_connect" && (
              <Col md={6}>
                <FormGroup>
                  <FormLabel>Postal Code</FormLabel>
                  <FormControl
                    type="text"
                    id="postalCode"
                    value={accountInfo.postalCode}
                    onChange={this.handleInput}
                    required></FormControl>
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <FormLabel>Description</FormLabel>
                <FormControl
                  type="description"
                  id="description"
                  value={accountInfo.description}
                  onChange={this.handleInput}
                  required></FormControl>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <FormLabel>Amount</FormLabel>
                <DecimalInputComponent
                  type="number"
                  min="1"
                  step="0.01"
                  id="amount"
                  max="9999999"
                  required
                  name="amount"
                  title="Amount"
                  value={accountInfo.amount}
                  onNumberChange={this.handleNumberChange}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>{this.ccForm()}</Col>
            {submitAttempted && !providerValid ? (
              <Col md={12}>
                <span className="alert alert-danger">Credit Card Information is incomplete or invalid.</span>
              </Col>
            ) : null}
          </Row>
        </Modal.Body>
        <Modal.Footer key="footer">
          <Button variant="cancel" type="button" onClick={closeModal} className="pull-right">
            Cancel
          </Button>
          <Button
            variant="default"
            type="submit"
            id="submit"
            disabled={!providerValid || !this.formValid()}
            className="pull-right">
            Complete Payment
          </Button>
        </Modal.Footer>
      </form>
    );
  }

  public submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const { providerValid, setProviderSubmitting } = this.props;
    this.setState({ submitAttempted: true });

    if (providerValid) {
      setProviderSubmitting(true);
    }
  }

  protected formValid() {
    if (!this.formRef || !this.formRef.current || !this.formRef.current.reportValidity) {
      return false;
    }

    return this.formRef.current.reportValidity();
  }

  protected ccForm() {
    const { accountInfo } = this.state;
    const { resolve } = this.props;

    switch (resolve.payment_provider) {
      case "pay_simple":
        return <PaySimpleForm accountInfo={accountInfo} resolve={resolve} />;
      case "card_connect":
        return <CardConnectForm accountInfo={accountInfo} resolve={resolve} />;
    }
  }
}

export default connector(MakePayment);
