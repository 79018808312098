import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IPaymentTerm, IPaymentTermResource, PaymentTermItem } from "app/src/Models/PaymentTerm";
import * as angulartics from "angulartics";
import { SortableOptions } from "../../Common/SortableOptions";

export class OrgPaymentTermsTabCtrl extends BaseOrgTabCtrl {
  public payment_terms: IPaymentTerm[];

  public static $inject = ["OrgFetcher", "PaymentTerm", "$uibModal", "$stateParams", "$analytics"];
  constructor(
    public OrgFetcher: IOrgFetcherService,
    public PaymentTerm: IPaymentTermResource,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $stateParams: ng.ui.IStateParamsService,
    protected $analytics: angulartics.IAnalyticsService,
    public sortableOptions = new SortableOptions(),
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.sortableOptions.stop = () => {
      _.each(this.payment_terms, (pt: IPaymentTerm, index: number) => {
        if (pt.sort_order !== index) {
          pt.sort_order = index;
          pt.$update();
        }
      });
      this.payment_terms = _.sortBy(this.payment_terms, "sort_order");
    };

    OrgFetcher.orgPromise.then(() => {
      return PaymentTerm.query({
        org_id: $stateParams["id"],
      }).$promise.then((data: any) => {
        this.payment_terms = data.payment_terms;
      });
    });
  }

  public deletePaymentTerm(pt: IPaymentTerm) {
    this.trackEvent("delete_payment_term", {
      category: "OrgPaymentTerms",
      paymentTermId: pt.id,
    });
    pt.$delete().then(() => {
      this.payment_terms = _.select(this.payment_terms, (pti: IPaymentTerm) => {
        return pti.id !== pt.id;
      });
    });
  }

  public addPaymentTerm() {
    this.trackEvent("add_payment_term", {
      category: "OrgPaymentTerms",
    });
    const item = new PaymentTermItem();
    const pt = this.PaymentTerm.fromJSON({
      org_id: this.org.id,
      breakdown: {
        items: [item],
      },
    });
    this.openModal(pt);
  }

  public editPaymentTerm(pt: IPaymentTerm) {
    const edit_pt = pt.clone();
    this.trackEvent("edit_payment_term", {
      category: "OrgPaymentTerms",
      paymentTermId: edit_pt.id,
    });
    if (edit_pt.breakdown.items.length === 0) {
      edit_pt.breakdown.items.push(new PaymentTermItem());
    }
    this.openModal(edit_pt);
  }

  public openModal(pt: IPaymentTerm) {
    this.trackEvent("open_modal", {
      category: "OrgPaymentTerms",
      paymentTermId: pt.id,
    });
    this.$uibModal.open({
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "OrgPaymentTermEditCtrl",
      controllerAs: "ctrl",
      size: "lg",
      templateUrl: "src/Orgs/tabs/payment_term_editor.html",

      resolve: {
        props: { paymentTerms: this.payment_terms },
        paymentTerm: pt,
      },
    });
  }
}
