import { ActionsUnion, createAction } from "./Utils";
import { IDeckData } from "../records/Deck";

export const RECEIVE_DECK = "@decks/RECEIVE_DECK";
export const EDIT_DECK = "@decks/EDIT_DECK";

export const Actions = {
  receiveDeck: (deck: Partial<IDeckData>) => createAction(RECEIVE_DECK, { deck }),
  editDeck: (deck: Partial<IDeckData>) => createAction(EDIT_DECK, { deck }),
};

export type Actions = ActionsUnion<typeof Actions>;
