import { List, Record } from "immutable";
import { fromJSON as fileFromJSON, IFileData, FileRecord } from "./File";
import { fromJSON as signedDocFromJSON, ISignedDocumentData, SignedDocumentRecord } from "./SignedDocument";
import { Nullable } from ".";
import { ITaskData } from "app2/src/records/Task";

export const fromJSON = (json: Partial<IDocumentData>): DocumentRecord => {
  const record: IDocumentRecord = { ...(json as any) };

  if (json.file) {
    record.file = fileFromJSON(json.file);
  }

  if (json.signed_document) {
    record.signed_document = signedDocFromJSON(json.signed_document);
  }

  if (json.task) {
    record.task_id = json.task.id;
  }

  record.displayInProposal = record.display === "both" || record.display === "estimate";
  record.displayInAgreement = record.display === "both" || record.display === "contract";
  record.displayInProposalWatermark = record.display === "estimate_watermark";
  record.displayInAgreementWatermark = record.display === "contract_watermark";
  record.displayInResource = record.display === "resource";

  return new DocumentRecord(record);
};

export const toJSON = (record: DocumentRecord): IDocumentData => {
  return record.toJS();
};

export const toFormData = (record: DocumentRecord): any => {
  const data = new FormData();
  if (record.uuid) {
    data.set("document[uuid]", record.uuid);
  }
  if (record.id > 0) {
    data.set("document[id]", record.id as any);
  }
  data.set("document[name]", record.name);
  data.set("document[link]", record.import_type);
  if (record.data && record.data instanceof File) {
    data.set("document[file]", record.data as any);
  } else if (record.data) {
    data.set("document[file]", record.data as any, record.name);
  }
  data.set("document[display]", record.display);
  data.set("document[documentable_id]", record.documentable_id.toString());
  data.set("document[documentable_type]", record.documentable_type);
  if (record.folder_id > 0) {
    data.set("document[folder_id]", record.folder_id.toString());
  }
  return data;
};

export const setDisplay = (record: DocumentRecord): DocumentDisplayType => {
  let display = DocumentDisplay.do_not_display;
  if (record.displayInProposal === true && record.displayInAgreement === true) {
    display = DocumentDisplay.both;
  } else if (record.displayInProposal === false && record.displayInAgreement === true) {
    display = DocumentDisplay.contract;
  } else if (record.displayInProposal === true && record.displayInAgreement === false) {
    display = DocumentDisplay.estimate;
  } else if (record.displayInProposalWatermark === true) {
    display = DocumentDisplay.estimate_watermark;
  } else if (record.displayInAgreementWatermark === true) {
    display = DocumentDisplay.contract_watermark;
  } else if (record.displayInResource === true) {
    display = DocumentDisplay.resource;
  }
  return display;
};

export const isMeasurementFile = (document: DocumentRecord) => {
  return _.contains(validImportTypes, document.import_type);
};

export const isPdfFile = (document: DocumentRecord): boolean => {
  return document?.content_type === "application/pdf";
};

export const validImportTypes: string[] = [
  "hover_excel",
  "roofscope_csv",
  "roofsnap_csv",
  "eagleview_xml",
  "window_nation_json",
  "hover_xml",
  "picto_connect_csv",
  "skymeasure_csv",
  "skymeasure_xml",
  "aerialreports_xml",
  "gaf_quickmeasure_xml",
  "xactimate_xml",
  "ez3d_csv",
];

export enum DocumentDisplay {
  do_not_display = "do_not_display",
  contract = "contract",
  estimate = "estimate",
  both = "both",
  estimate_watermark = "estimate_watermark",
  contract_watermark = "contract_watermark",
  resource = "resource",
}

export type DocumentDisplayType =
  | "do_not_display"
  | "contract"
  | "estimate"
  | "both"
  | "estimate_watermark"
  | "contract_watermark"
  | "resource";

export interface IDocumentData {
  uuid: string;
  id: number;
  content_type: string;
  name?: string;
  import_type?: string;
  file?: IFileData | File;
  display?: DocumentDisplayType;
  folder_id?: number;
  documentable_id?: number;
  documentable_type?: string;
  md5_sum?: string;
  sort_order?: number;
  url?: string;
  data?: Nullable<File>;
  task?: ITaskData;
  signed_document?: ISignedDocumentData;
  loading?: boolean;

  created_at: Date;
  updated_at: Date;
  base64: Nullable<string>;
}

export interface IDocumentRecord {
  uuid: Nullable<string>;
  id: Nullable<number>;
  content_type: Nullable<string>;
  title: Nullable<string>;
  name: Nullable<string>;
  display: DocumentDisplayType;
  displayInProposal: Nullable<boolean>;
  displayInAgreement: Nullable<boolean>;
  displayInProposalWatermark: Nullable<boolean>;
  displayInAgreementWatermark: Nullable<boolean>;
  displayInResource: Nullable<boolean>;
  file: Nullable<FileRecord>;
  file_size: Nullable<number>;
  import_type?: Nullable<string>;
  folder_id: Nullable<number>;
  documentable_id: Nullable<number>;
  documentable_type: Nullable<string>;
  md5_sum: Nullable<string>;
  sort_order: Nullable<number>;
  data?: Nullable<File>;
  task_id?: Nullable<string>;
  url: Nullable<string>;
  loading: boolean;
  selected: boolean;
  _destroy: Nullable<boolean>;

  signed_document: Nullable<SignedDocumentRecord>;

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
  base64: Nullable<string>;
}

export const defaultDocumentProps = {
  uuid: null,
  id: 0,
  content_type: null,
  title: null,
  name: null,
  display: null,
  displayInProposal: null,
  displayInAgreement: null,
  displayInProposalWatermark: null,
  displayInAgreementWatermark: null,
  displayInResource: null,
  file: null,
  data: null,
  task_id: null,
  url: null,
  file_size: null,
  folder_id: null,
  documentable_id: null,
  documentable_type: null,
  import_type: null,
  md5_sum: null,
  sort_order: null,
  signed_document: null,
  _destroy: null,
  created_at: null,
  updated_at: null,
  selected: false,
  loading: false,
  base64: null,
};

export class DocumentRecord extends Record<IDocumentRecord>(defaultDocumentProps) implements IDocumentRecord {}
