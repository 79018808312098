// Product.ts
export const valid_uom = [
  "ea",
  "sqft",
  "lft",
  "ui",
  "in",
  "sq",
  "gal",
  "pair",
  "sash",
  "mile",
  "layer",
  "hr",
  "day",
  "percent",
  "all",
  "cm",
  "m",
  "km",
  "sqm",
  "l",
  "w",
  "kW",
];

// Discount.ts
export const valid_kind = ["percent", "percent_limit", "flat"];

// Product Codes for QuickMeasure API
export const quickMeasureProductCodes = [
  { label: "Single Family", value: "SF-QM-USA", description: "Roof Measurement" },
  { label: "Multi Family", value: "MF-QM-USA", description: "Roof Measurement" },
  { label: "Commercial", value: "CM-QM-USA", description: "Roof Measurement" },
];

// Imperial Units of Measure
export const imperial_uom = {
  roof_total_area: "sqft",
  roof_5pw_total_area: "sqft",
  roof_10pw_total_area: "sqft",
  roof_12pw_total_area: "sqft",
  roof_15pw_total_area: "sqft",
  roof_20pw_total_area: "sqft",
  roof_flat_area: "sqft",
  roof_flat_shallow_area: "sqft",
  roof_low_slope_area: "sqft",
  roof_average_slope_area: "sqft",
  roof_steep_slope_area: "sqft",
  roof_ultra_steep_slope_area: "sqft",
  roof_high_roof_area: "sqft",
  roof_sloped_area: "sqft",
  roof_10pw_sloped_area: "sqft",
  roof_79_area: "sqft",
  roof_1012_area: "sqft",
  roof_13p_area: "sqft",
  roof_0_area: "sqft",
  roof_1_area: "sqft",
  roof_2_area: "sqft",
  roof_3_area: "sqft",
  roof_4_area: "sqft",
  roof_5_area: "sqft",
  roof_6_area: "sqft",
  roof_7_area: "sqft",
  roof_8_area: "sqft",
  roof_9_area: "sqft",
  roof_10_area: "sqft",
  roof_11_area: "sqft",
  roof_12_area: "sqft",
  roof_13_area: "sqft",
  roof_14_area: "sqft",
  roof_15_area: "sqft",
  roof_16_area: "sqft",
  roof_17_area: "sqft",
  roof_18_area: "sqft",
  roof_19_area: "sqft",
  roof_20_area: "sqft",
  roof_21_area: "sqft",
  roof_22_area: "sqft",
  roof_23_area: "sqft",
  roof_24_area: "sqft",
  roof_25_area: "sqft",
  roof_26p_area: "sqft",
  roof_eave: "lft",
  roof_rake: "lft",
  roof_perimeter: "lft",
  roof_ridge: "lft",
  roof_hip: "lft",
  roof_ridge_hip: "lft",
  roof_valley: "lft",
  roof_valley_eave: "lft",
  roof_valley_eave_rake: "lft",
  roof_step_flashing: "lft",
  roof_headwall_flashing: "lft",
  roof_total_flashing: "lft",
  roof_gutter_miters: "ea",
  roof_downspouts: "lft",
  roof_downspout_elbows: "ea",
  siding_total_area: "sqft",
  siding_5pw_total_area: "sqft",
  siding_10pw_total_area: "sqft",
  siding_15pw_total_area: "sqft",
  siding_18pw_total_area: "sqft",
  siding_inside_corner_qty: "ea",
  siding_inside_corner_length: "lft",
  siding_outside_corner_qty: "ea",
  siding_outside_corner_length: "lft",
  siding_level_starter: "lft",
  siding_sloped_trim: "lft",
  siding_vertical_trim: "lft",
  siding_level_frieze_board: "lft",
  siding_sloped_frieze_board: "lft",
  siding_frieze_board: "lft",
  siding_opening_qty: "ea",
  siding_windows_qty: "ea",
  siding_doors_qty: "ea",
  siding_garage_doors_qty: "ea",
  siding_opening_tops_length: "lft",
  siding_opening_sills_length: "lft",
  siding_opening_sides_length: "lft",
  siding_shutter_qty: "ea",
  siding_shutter_area: "sqft",
  siding_vents_qty: "ea",
  siding_vents_area: "sqft",
  siding_soffit_eaves: "sqft",
  siding_soffit_level_frieze: "sqft",
  siding_soffit_rakes: "sqft",
  siding_soffit_sloped_frieze: "sqft",
  siding_soffit_total: "sqft",
  roof_faces: "ea",
  wall_facades: "ea",
  windows: "ea",
  doors: "ea",
  sunroom_a_wall_length: "lft",
  sunroom_a_wall_height: "lft",
  sunroom_a_wall_area: "sqft",
  sunroom_b_wall_length: "lft",
  sunroom_b_wall_height: "lft",
  sunroom_b_wall_area: "sqft",
  sunroom_c_wall_length: "lft",
  sunroom_c_wall_height: "lft",
  sunroom_c_wall_area: "sqft",
  sunroom_wall_perimeter: "lft",
  sunroom_wall_total_area: "sqft",
  sunroom_deck_total_area: "sqft",
  sunroom_ceiling_area: "sqft",
  sunroom_studio_roof_area: "sqft",
  sunroom_cathedral_roof_area: "sqft",
  sunroom_kw_a_length: "lft",
  sunroom_kw_a_height: "in",
  sunroom_kw_a_area: "sqft",
  sunroom_kw_b_length: "lft",
  sunroom_kw_b_height: "in",
  sunroom_kw_b_area: "sqft",
  sunroom_kw_c_length: "lft",
  sunroom_kw_c_height: "in",
  sunroom_kw_c_area: "sqft",
  sunroom_kw_total_length: "lft",
  sunroom_kw_total_area: "sqft",
  roof_face_area: "sqft",
  wall_facade_area: "sqft",
  window_width: "in",
  window_height: "in",
  window_ui: "in",
  window_area: "sqft",
  door_width: "in",
  door_height: "in",
  door_ui: "in",
  rooms: "ea",
  room_area: "sqft",
  room_perimeter: "lft",
  room_inside_corner: "ea",
  room_outside_corner: "ea",
  room_threshold: "lft",
  decks: "ea",
  deck_area: "sqft",
  deck_perimeter: "lft",
  deck_wall_length: "lft",
  deck_railing_length: "lft",
  deck_railing_area: "sqft",
  pools: "ea",
  pool_area: "sqft",
  pool_perimeter: "lft",
  pool_average_depth: "lft",
  pool_volume: "gal",
  interior_door_area: "sqft",
  interior_area: "sqft",
  interior_perimeter: "lft",
  interior_inside_corner: "ea",
  interior_outside_corner: "ea",
  interior_threshold: "lft",
  interior_ceiling_area: "sqft",
  interior_ceiling_area_less_structures: "sqft",
  interior_ceiling_perimeter: "lft",
  interior_ceiling_perimeter_less_structures: "lft",
  interior_door_count: "ea",
  interior_door_total_width: "lft",
  interior_exposed_structure_perimeter: "lft",
  interior_floor_area_less_structures: "sqft",
  interior_floor_opening_total_width: "lft",
  interior_floor_plan_area: "sqft",
  interior_floor_plan_perimeter: "lft",
  interior_floor_plan_perimeter_less_structures: "lft",
  interior_floor_plan_perimeter_without_openings: "lft",
  interior_object_count: "ea",
  interior_opening_count: "ea",
  interior_opening_total_area: "sqft",
  interior_opening_total_width: "lft",
  interior_structure_total_ceiling_area: "sqft",
  interior_structure_total_ceiling_width: "lft",
  interior_structure_total_floor_area: "sqft",
  interior_structure_total_floor_width: "lft",
  interior_structure_total_wall_area: "sqft",
  interior_volume: "cuft",
  interior_wall_count: "ea",
  interior_wall_total_area: "sqft",
  interior_wall_total_area_without_openings: "sqft",
  interior_window_count: "ea",
  interior_window_total_area: "sqft",
};

// Metric Units of Measure
export const metric_uom = {
  roof_total_area: "sqm",
  roof_5pw_total_area: "sqm",
  roof_10pw_total_area: "sqm",
  roof_12pw_total_area: "sqm",
  roof_15pw_total_area: "sqm",
  roof_20pw_total_area: "sqm",
  roof_flat_area: "sqm",
  roof_flat_shallow_area: "sqm",
  roof_low_slope_area: "sqm",
  roof_average_slope_area: "sqm",
  roof_steep_slope_area: "sqm",
  roof_ultra_steep_slope_area: "sqm",
  roof_high_roof_area: "sqm",
  roof_sloped_area: "sqm",
  roof_10pw_sloped_area: "sqm",
  roof_79_area: "sqm",
  roof_1012_area: "sqm",
  roof_13p_area: "sqm",
  roof_0_area: "sqm",
  roof_1_area: "sqm",
  roof_2_area: "sqm",
  roof_3_area: "sqm",
  roof_4_area: "sqm",
  roof_5_area: "sqm",
  roof_6_area: "sqm",
  roof_7_area: "sqm",
  roof_8_area: "sqm",
  roof_9_area: "sqm",
  roof_10_area: "sqm",
  roof_11_area: "sqm",
  roof_12_area: "sqm",
  roof_13_area: "sqm",
  roof_14_area: "sqm",
  roof_15_area: "sqm",
  roof_16_area: "sqm",
  roof_17_area: "sqm",
  roof_18_area: "sqm",
  roof_19_area: "sqm",
  roof_20_area: "sqm",
  roof_21_area: "sqm",
  roof_22_area: "sqm",
  roof_23_area: "sqm",
  roof_24_area: "sqm",
  roof_25_area: "sqm",
  roof_26p_area: "sqm",
  roof_eave: "m",
  roof_rake: "m",
  roof_perimeter: "m",
  roof_ridge: "m",
  roof_hip: "m",
  roof_ridge_hip: "m",
  roof_valley: "m",
  roof_valley_eave: "m",
  roof_valley_eave_rake: "m",
  roof_step_flashing: "m",
  roof_headwall_flashing: "m",
  roof_total_flashing: "m",
  roof_gutter_miters: "ea",
  roof_downspouts: "m",
  roof_downspout_elbows: "ea",
  siding_total_area: "sqm",
  siding_5pw_total_area: "sqm",
  siding_10pw_total_area: "sqm",
  siding_15pw_total_area: "sqm",
  siding_18pw_total_area: "sqm",
  siding_inside_corner_qty: "ea",
  siding_inside_corner_length: "m",
  siding_outside_corner_qty: "ea",
  siding_outside_corner_length: "m",
  siding_level_starter: "m",
  siding_sloped_trim: "m",
  siding_vertical_trim: "m",
  siding_level_frieze_board: "m",
  siding_sloped_frieze_board: "m",
  siding_frieze_board: "m",
  siding_opening_qty: "ea",
  siding_windows_qty: "ea",
  siding_doors_qty: "ea",
  siding_garage_doors_qty: "ea",
  siding_opening_tops_length: "m",
  siding_opening_sills_length: "m",
  siding_opening_sides_length: "m",
  siding_shutter_qty: "ea",
  siding_shutter_area: "sqm",
  siding_vents_qty: "ea",
  siding_vents_area: "sqm",
  siding_soffit_eaves: "sqm",
  siding_soffit_level_frieze: "sqm",
  siding_soffit_rakes: "sqm",
  siding_soffit_sloped_frieze: "sqm",
  siding_soffit_total: "sqm",
  roof_faces: "ea",
  wall_facades: "ea",
  windows: "ea",
  doors: "ea",
  sunroom_a_wall_length: "m",
  sunroom_a_wall_height: "m",
  sunroom_a_wall_area: "sqm",
  sunroom_b_wall_length: "m",
  sunroom_b_wall_height: "m",
  sunroom_b_wall_area: "sqm",
  sunroom_c_wall_length: "m",
  sunroom_c_wall_height: "m",
  sunroom_c_wall_area: "sqm",
  sunroom_wall_perimeter: "m",
  sunroom_wall_total_area: "sqm",
  sunroom_deck_total_area: "sqm",
  sunroom_ceiling_area: "sqm",
  sunroom_studio_roof_area: "sqm",
  sunroom_cathedral_roof_area: "sqm",
  sunroom_kw_a_length: "m",
  sunroom_kw_a_height: "cm",
  sunroom_kw_a_area: "sqm",
  sunroom_kw_b_length: "m",
  sunroom_kw_b_height: "cm",
  sunroom_kw_b_area: "sqm",
  sunroom_kw_c_length: "m",
  sunroom_kw_c_height: "cm",
  sunroom_kw_c_area: "sqm",
  sunroom_kw_total_length: "m",
  sunroom_kw_total_area: "sqm",
  roof_face_area: "sqm",
  wall_facade_area: "sqm",
  window_width: "in",
  window_height: "in",
  window_ui: "in",
  window_area: "sqft",
  door_width: "in",
  door_height: "in",
  door_ui: "in",
  rooms: "ea",
  room_area: "sqft",
  room_perimeter: "lft",
  room_inside_corner: "ea",
  room_outside_corner: "ea",
  room_threshold: "lft",
  decks: "ea",
  deck_area: "sqft",
  deck_perimeter: "lft",
  deck_wall_length: "lft",
  deck_railing_length: "lft",
  deck_railing_area: "sqft",
  pools: "ea",
  pool_area: "sqft",
  pool_perimeter: "lft",
  pool_average_depth: "lft",
  pool_volume: "gal",
  interior_door_area: "sqft",
  interior_area: "sqm",
  interior_perimeter: "m",
  interior_inside_corner: "ea",
  interior_outside_corner: "ea",
  interior_threshold: "m",
  interior_ceiling_area: "sqm",
  interior_ceiling_area_less_structures: "sqm",
  interior_ceiling_perimeter: "m",
  interior_ceiling_perimeter_less_structures: "m",
  interior_door_count: "ea",
  interior_door_total_width: "m",
  interior_exposed_structure_perimeter: "m",
  interior_floor_area_less_structures: "sqm",
  interior_floor_opening_total_width: "m",
  interior_floor_plan_area: "sqm",
  interior_floor_plan_perimeter: "m",
  interior_floor_plan_perimeter_less_structures: "m",
  interior_floor_plan_perimeter_without_openings: "m",
  interior_object_count: "ea",
  interior_opening_count: "ea",
  interior_opening_total_area: "sqm",
  interior_opening_total_width: "m",
  interior_structure_total_ceiling_area: "sqm",
  interior_structure_total_ceiling_width: "m",
  interior_structure_total_floor_area: "sqm",
  interior_structure_total_floor_width: "m",
  interior_structure_total_wall_area: "sqm",
  interior_volume: "m3",
  interior_wall_count: "ea",
  interior_wall_total_area: "sqm",
  interior_wall_total_area_without_openings: "sqm",
  interior_window_count: "ea",
  interior_window_total_area: "sqm",
};

const jobBuilderTypes = {
  occ: "OCC",
  csv: "CSV Import",
  salesforce: "Salesforce",
  companycam: "CompanyCam",
  improveit360: "Improveit360",
  job_nimbus: "JobNimbus",
  jobprogress: "JobProgress",
  lead_perfection: "LeadPerfection",
  marketsharp: "MarketSharp",
  sales_rabbit: "SalesRabbit",
  creator: "Creator",
  rsf_leads_api_lead: "Lead Conversion Callback",
};

export const validJobBuilderTypes = Object.keys(jobBuilderTypes).map((value) => ({
  value,
  label: jobBuilderTypes[value],
}));
