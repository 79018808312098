import * as ng from "angular";
import { OrgListCtrl } from "app/src/Orgs/OrgListCtrl";
import { OrgShowCtrl } from "app/src/Orgs/OrgShowCtrl";
import { OrgInfoTabCtrl } from "app/src/Orgs/tabs/OrgInfoTabCtrl";
import { OrgSettingsTabCtrl } from "app/src/Orgs/tabs/OrgSettingsTabCtrl";
import { OrgSignatureSettingsEditCtrl } from "app/src/Orgs/tabs/OrgSignatureSettingsEditCtrl";
import { OrgPreferencesTabCtrl } from "app/src/Orgs/tabs/OrgPreferencesTabCtrl";
import { OrgBillingTabCtrl } from "app/src/Orgs/tabs/OrgBillingTabCtrl";
import { OrgJobsTabCtrl } from "app/src/Orgs/tabs/OrgJobsTabsCtrl";
import { OrgPaymentTermsTabCtrl } from "app/src/Orgs/tabs/OrgPaymentTermsTabCtrl";
import { OrgPaymentTermEditCtrl } from "app/src/Orgs/tabs/OrgPaymentTermEditCtrl";
import { OrgPriceListsTabCtrl } from "app/src/Orgs/tabs/OrgPriceListsTabCtrl";
import { OrgPriceListEditorCtrl } from "app/src/Orgs/tabs/OrgPriceListEditorCtrl";
import { OrgPriceListHeaderCtrl } from "app/src/Orgs/tabs/OrgPriceListHeaderCtrl";
import { OrgPriceListPropEditorCtrl } from "app/src/Orgs/tabs/OrgPriceListPropEditorCtrl";
import { OrgProductEditorCtrl } from "app/src/Orgs/tabs/OrgProductEditorCtrl";
import { OrgProductOptionGroupEditorCtrl } from "app/src/Orgs/tabs/PriceListEditor/OrgProductOptionGroupEditorCtrl";
import { OrgProductOptionEditorCtrl } from "app/src/Orgs/tabs/PriceListEditor/OrgProductOptionEditorCtrl";
import { OrgParentSelectorCtrl } from "app/src/Orgs/tabs/OrgParentSelectorCtrl";
import { OrgMeasurementLinksTabCtrl } from "app/src/Orgs/tabs/OrgMeasurementLinksTabCtrl";
import { OrgMeasurementLinkEditCtrl } from "app/src/Orgs/tabs/OrgMeasurementLinkEditCtrl";
import { OrgChildrenTabCtrl } from "app/src/Orgs/tabs/OrgChildrenTabCtrl";
import { OrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { OrgPresentationTabCtrl } from "app/src/Orgs/tabs/OrgPresentationTabCtrl";
import { ProductOptionsEditorComponent } from "app/src/Orgs/tabs/PriceListEditor/ProductOptionsEditorComponent";
import { OptionGroupsMatchComponent } from "app/src/Orgs/tabs/PriceListEditor/OptionGroupsMatchComponent";
import { OptionsMatchComponent } from "app/src/Orgs/tabs/PriceListEditor/OptionsMatchComponent";
import { MatchWizardCtrl } from "app/src/Orgs/tabs/PriceListEditor/MatchWizardCtrl";
import { OrgStatsTabCtrl } from "app/src/Orgs/tabs/OrgStatsTabCtrl";
import { GlobalStatsCtrl } from "app/src/Orgs/GlobalStatsCtrl";
import { GlobalPatternsCtrl } from "app/src/Orgs/GlobalPatternsCtrl";
import { PatternEditorComponent } from "app/src/Patterns/PatternEditorComponent";
import { OrgPatternsTabCtrl } from "app/src/Orgs/tabs/OrgPatternsTabCtrl";
import { OrgIntegrationsTabCtrl } from "./tabs/OrgIntegrationsTabCtrl";
import { OrgEmailsTabCtrl } from "./tabs/OrgEmailsTabCtrl";
import { ReportShowCtrl } from "./ReportShowCtrl";
import HoverAuthCallback from "app2/src/components/Integrations/Hover/AuthCallback";
import DiscountList from "app2/src/components/Discount/List";
import FinanceOptionList from "app2/src/components/FinanceOption/List";
import ProductProcessSelector from "app2/src/components/ProductProcess/ProductProcessSelector";
import PriceListProcessSelector from "app2/src/components/PriceListProcess/PriceListProcessSelector";
import ListComponent from "app2/src/components/AngularHelper/List";
import OrgSetup from "app2/src/components/OrgSetup/OrgSetup";
import OrgNotifications from "app2/src/components/OrgNotifications/OrgNotifications";
import { react2angular } from "react2angular";
import Integrations from "app2/src/components/Integrations/Integrations";
import { OrgAccessChooser } from "app2/src/components/OrgAccessChooser";
import MeasurementLinkEditor from "app2/src/components/MeasurementLink/Editor";
import InteriorMeasurementLinkEditor from "app2/src/components/MeasurementLink/InteriorEditor";
import DocumentTab from "app2/src/components/OrgTabs/DocumentTab";
import PresentationTab from "app2/src/components/OrgTabs/PresentationTab";
import BillingTab from "app2/src/components/OrgTabs/BillingTab/BillingInvites";
import UsersTab from "app2/src/components/OrgTabs/UsersTab";
import SkuInput from "app2/src/components/Sku/SkuInput";
import NgCountless from "app2/src/components/Pagination/NgCountless";
import DuplicateData from "app2/src/components/OrgTabs/DuplicateData";
import OrgAdmin from "app2/src/components/OrgAdmin/index";
import SettingsTab from "app2/src/components/OrgTabs/SettingsTab/index";
import PreferencesTab from "app2/src/components/OrgTabs/PreferencesTab/index";
import ChargebeeSubscription from "app2/src/components/Chargebee/index";

export class OrgRoutes {
  public static load(app: ng.IModule) {
    app.controller("OrgListCtrl", OrgListCtrl);
    app.controller("OrgShowCtrl", OrgShowCtrl);
    app.controller("OrgInfoTabCtrl", OrgInfoTabCtrl);
    app.controller("OrgSettingsTabCtrl", OrgSettingsTabCtrl);
    app.controller("OrgSignatureSettingsEditCtrl", OrgSignatureSettingsEditCtrl);
    app.controller("OrgPreferencesTabCtrl", OrgPreferencesTabCtrl);
    app.controller("OrgPatternsTabCtrl", OrgPatternsTabCtrl);
    app.controller("OrgBillingTabCtrl", OrgBillingTabCtrl);
    app.controller("OrgIntegrationsTabCtrl", OrgIntegrationsTabCtrl);
    app.controller("OrgJobsTabCtrl", OrgJobsTabCtrl);
    app.controller("OrgChildrenTabCtrl", OrgChildrenTabCtrl);
    app.controller("OrgPresentationTabCtrl", OrgPresentationTabCtrl);
    app.controller("OrgPaymentTermsTabCtrl", OrgPaymentTermsTabCtrl);
    app.controller("OrgPaymentTermEditCtrl", OrgPaymentTermEditCtrl);
    app.controller("OrgPriceListsTabCtrl", OrgPriceListsTabCtrl);
    app.controller("OrgPriceListEditorCtrl", OrgPriceListEditorCtrl);
    app.controller("OrgPriceListHeaderCtrl", OrgPriceListHeaderCtrl);
    app.controller("OrgPriceListPropEditorCtrl", OrgPriceListPropEditorCtrl);
    app.controller("OrgProductEditorCtrl", OrgProductEditorCtrl);
    app.controller("OrgProductOptionGroupEditorCtrl", OrgProductOptionGroupEditorCtrl);
    app.controller("OrgProductOptionEditorCtrl", OrgProductOptionEditorCtrl);
    app.controller("OrgParentSelectorCtrl", OrgParentSelectorCtrl);
    app.controller("OrgMeasurementLinksTabCtrl", OrgMeasurementLinksTabCtrl);
    app.controller("OrgMeasurementLinkEditCtrl", OrgMeasurementLinkEditCtrl);
    app.controller("OrgStatsTabCtrl", OrgStatsTabCtrl);
    app.controller("OrgEmailsTabCtrl", OrgEmailsTabCtrl);
    app.controller("GlobalStatsCtrl", GlobalStatsCtrl);
    app.controller("GlobalPatternsCtrl", GlobalPatternsCtrl);
    app.controller("MatchWizardCtrl", MatchWizardCtrl);
    app.controller("ReportShowCtrl", ReportShowCtrl);

    app.component("productOptionsEditor", new ProductOptionsEditorComponent());
    app.component("optionGroupsMatch", new OptionGroupsMatchComponent());
    app.component("optionsMatch", new OptionsMatchComponent());
    app.component("patternEditor", new PatternEditorComponent());
    app.component("hoverAuthCallback", react2angular(HoverAuthCallback, null, ["$state", "$stateParams"]));
    app.component("orgDiscountList", react2angular(DiscountList, ["orgId"], ["DirtyWatcher", "$scope", "Flash"]));
    app.component(
      "orgFinanceOptionList",
      react2angular(FinanceOptionList, ["orgid"], ["DirtyWatcher", "$scope", "Flash"]),
    );
    app.component("productProcessSelector", react2angular(ProductProcessSelector, ["controller", "product"]));
    app.component("priceListProcessSelector", react2angular(PriceListProcessSelector, ["controller", "priceList"]));
    app.component(
      "angularToReactList",
      react2angular(ListComponent, ["caption", "name", "rowType", "list", "available", "updateList", "orgId"]),
    );
    app.component("orgNotifications", react2angular(OrgNotifications, null, ["DirtyWatcher", "$scope"]));
    app.component("orgSetup", react2angular(OrgSetup, null, ["DirtyWatcher", "$scope"]));
    app.component("integrations", react2angular(Integrations, ["users"]));
    app.component("orgAccessChooser", react2angular(OrgAccessChooser, [], ["$state"]));
    app.component(
      "measurementLinkEditor",
      react2angular(MeasurementLinkEditor, ["measureValue", "measurement", "product", "update"]),
    );
    app.component(
      "interiorMeasurementLinkEditor",
      react2angular(InteriorMeasurementLinkEditor, ["measureValue", "measurement", "product", "update"]),
    );
    app.component("orgDocumentTab", react2angular(DocumentTab, ["queryFolderParentId"]));
    app.component("orgPresentationTab", react2angular(PresentationTab, ["queryFolderParentId"], []));
    app.component("orgBillingTab", react2angular(BillingTab, ["uuid"], []));
    app.component("orgUsersTab", react2angular(UsersTab));
    app.component("skuInput", react2angular(SkuInput, ["skuId"]));
    app.component("countlessPagination", react2angular(NgCountless, ["metadata", "pageChanged"]));
    app.component("duplicateData", react2angular(DuplicateData));
    app.component("settingsTab", react2angular(SettingsTab, ["updateSettingsConfig"]));
    app.component("preferencesTab", react2angular(PreferencesTab, ["updatePreferencesConfig"]));
    app.component("orgAdmin", react2angular(OrgAdmin, ["orgs", "filters"]));
    app.component("chargebeeButton", react2angular(ChargebeeSubscription, ["subscriptions", "orgId"]));

    app.service("OrgFetcher", OrgFetcherService);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth: any): void {
    $stateProvider
      .state("root.org_list", <ng.ui.IState>{
        url: "/orgs?status=",
        views: {
          "container@": {
            templateUrl: "src/Orgs/list.html",
            controller: "OrgListCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.global_stats", <ng.ui.IState>{
        url: "/orgs/global_stats",
        views: {
          "container@": {
            templateUrl: "src/Orgs/global_stats.html",
            controller: "GlobalStatsCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.patterns", <ng.ui.IState>{
        url: "/orgs/patterns",
        views: {
          "container@": {
            templateUrl: "src/Orgs/global_patterns.html",
            controller: "GlobalPatternsCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.accesses", <ng.ui.IState>{
        url: "/orgs/accesses",
        views: {
          "container@": {
            component: "orgAccessChooser",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.hoverCallback", <ng.ui.IState>{
        url: "/hover/callback?code",
        views: {
          "container@": {
            component: "hoverAuthCallback",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show", <ng.ui.IState>{
        url: "/orgs/:id?parent_id",
        views: {
          "container@": {
            templateUrl: "src/Orgs/show.html",
            controller: "OrgShowCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.info", <ng.ui.IState>{
        url: "/info",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/info.html",
            controller: "OrgInfoTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.settings", <ng.ui.IState>{
        url: "/settings",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/settings.html",
            controller: "OrgSettingsTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.duplicate_data", <ng.ui.IState>{
        url: "/duplicate_data",
        views: {
          "orgShow@root.org_show": {
            component: "duplicateData",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.setup", <ng.ui.IState>{
        url: "/setup",
        views: {
          "orgShow@root.org_show": {
            component: "orgSetup",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.notifications", <ng.ui.IState>{
        url: "/notifications",
        views: {
          "orgShow@root.org_show": {
            component: "orgNotifications",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.preferences", <ng.ui.IState>{
        url: "/preferences",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/preferences.html",
            controller: "OrgPreferencesTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.billing", <ng.ui.IState>{
        url: "/billing",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/billing.html",
            controller: "OrgBillingTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.integrations", <ng.ui.IState>{
        url: "/integrations",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/integrations.html",
            controller: "OrgIntegrationsTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.integrations.react-integrations", <ng.ui.IState>{
        url: "/{path:.*}",
        views: {},
      })
      .state("root.org_show.orgs", <ng.ui.IState>{
        url: "/orgs",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/children.html",
            controller: "OrgChildrenTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.jobs", <ng.ui.IState>{
        url: "/jobs",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/jobs.html",
            controller: "OrgJobsTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.documents", <ng.ui.IState>{
        url: "/documents?folder_parent_id",
        views: {
          "orgShow@root.org_show": {
            component: "orgDocumentTab",
          },
        },
        resolve: {
          ...resolveAuth,
          queryFolderParentId: [
            "$stateParams",
            ($stateParams) => {
              if ($stateParams.folder_parent_id) {
                return parseInt($stateParams.folder_parent_id);
              }
            },
          ],
        },
      })
      .state("root.org_show.presentations", <ng.ui.IState>{
        url: "/presentations?folder_parent_id",
        views: {
          "orgShow@root.org_show": {
            component: "orgPresentationTab",
          },
        },
        resolve: {
          ...resolveAuth,
          queryFolderParentId: [
            "$stateParams",
            ($stateParams) => {
              if ($stateParams.folder_parent_id) {
                return parseInt($stateParams.folder_parent_id);
              }
            },
          ],
        },
      })
      .state("root.org_show.price_lists", <ng.ui.IState>{
        url: "/price_lists",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/price_lists.html",
            controller: "OrgPriceListsTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.price_list_header_list", <ng.ui.IState>{
        url: "/price_list_editor",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/price_list_header.html",
            controller: "OrgPriceListHeaderCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.price_list_header", <ng.ui.IState>{
        url: "/price_list_editor/:price_list_id",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/price_list_header.html",
            controller: "OrgPriceListHeaderCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.price_list_header.editor", <ng.ui.IState>{
        url: "/editor?product_parent_id?leaf_view&query&active_tab&option_group_match_id",
        views: {
          "priceListEditor@root.org_show.price_list_header": {
            templateUrl: "src/Orgs/tabs/price_list_editor.html",
            controller: "OrgPriceListEditorCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.patterns", <ng.ui.IState>{
        url: "/patterns",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/patterns.html",
            controller: "OrgPatternsTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.measurement_links", <ng.ui.IState>{
        url: "/measurement_links",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/measurement_links.html",
            controller: "OrgMeasurementLinksTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.payment_terms", <ng.ui.IState>{
        url: "/payment_terms",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/payment_terms.html",
            controller: "OrgPaymentTermsTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.stats", <ng.ui.IState>{
        url: "/stats",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/statistics.html",
            controller: "OrgStatsTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.emails", <ng.ui.IState>{
        url: "/emails",
        views: {
          "orgShow@root.org_show": {
            templateUrl: "src/Orgs/tabs/emails.html",
            controller: "OrgEmailsTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.org_show.discounts", <ng.ui.IState>{
        url: "/discounts",
        views: {
          "orgShow@root.org_show": {
            component: "orgDiscountList",
          },
        },
        resolve: {
          ...resolveAuth,
          orgId: [
            "$stateParams",
            ($stateParams) => {
              return $stateParams.id;
            },
          ],
        },
      })
      .state("root.org_show.react-org-tabs", <ng.ui.IState>{
        url: "/:path",
        params: {
          path: { type: "string", raw: true },
        },
        views: {
          "orgShow@root.org_show": {},
        },
      });
  }
}
