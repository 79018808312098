import { FeeRecord } from "app2/src/records/billing/Fee";
import * as feeActions from "app2/src/reducers/billing/fee.actions";
import { feesById } from "app2/src/selectors/fee.selectors";
import { dispatch, useSelector } from "app2/src/storeRegistry";
import { subscriber } from "app2/src/helpers/Subscribe";

export class FeeListCtrl {
  public fees: FeeRecord[];
  public showArchive = false;

  private _unsubscribeFees: () => void;

  public static $inject = ["$state", "$scope"];
  constructor(
    public $state: ng.ui.IStateService,
    public $scope: ng.IScope,
  ) {
    dispatch(feeActions.Actions.clearFees());
    this.showArchive = false;
    this.loadFees();

    this._unsubscribeFees = subscriber.subscribe<string>("billing.fees.byId", () => {
      this.fees = useSelector(feesById).toList().toArray();
      this.fees.sort((fee1, fee2) => (fee1.name > fee2.name ? 1 : -1));
      if (!($scope.$$phase === "$apply" || $scope.$$phase === "$digest")) {
        $scope.$digest();
      }
    });

    $scope.$on("$destroy", () => {
      this._unsubscribeFees();
    });
  }

  public async loadFees() {
    await dispatch(feeActions.AsyncActions.listFees({ archived: this.showArchive }));
  }

  public async toggleArchive(fee) {
    if (fee.archived_at) {
      dispatch(feeActions.AsyncActions.unarchiveFee(fee));
    } else {
      dispatch(feeActions.AsyncActions.archiveFee(fee, !this.showArchive));
    }
  }

  public toggleShowArchived() {
    dispatch(feeActions.Actions.clearFees());
    if (this.showArchive) {
      this.showArchive = false;
    } else {
      this.showArchive = true;
    }
    this.loadFees();
  }
}
