import { Record, Map } from "immutable";
import { fromJSON as feeFromJSON, IFeeData, FeeRecord } from "./Fee";

export type FeeSubscriptionStatus = "active" | "error" | "complete";

export const fromJSON = (json: Partial<IFeeSubscriptionData>): FeeSubscriptionRecord => {
  const record = { ...json } as any as IFeeSubscriptionRecord;

  if (json.fee) {
    record.fee = feeFromJSON(json.fee);
  }

  return new FeeSubscriptionRecord(record);
};

export interface IFeeSubscriptionData {
  id: number;
  billing_account_id: number;
  billing_customer_id: number;
  billing_fee_id: number;
  history: any;
  next_date: Date | string;
  start_date: Date | string;
  status: FeeSubscriptionStatus;

  fee: IFeeData;

  created_at: Date | string;
  updated_at: Date | string;
}

export interface IFeeSubscriptionRecord {
  id: number;
  billing_account_id: number;
  billing_customer_id: number;
  billing_fee_id: number;
  history: Map<string, any>;
  next_date: Date;
  start_date: Date;
  status: FeeSubscriptionStatus;

  fee: FeeRecord;

  created_at: Date;
  updated_at: Date;
}

const defaultFeeSubscriptionProps: IFeeSubscriptionRecord = {
  id: 0,
  billing_account_id: 0,
  billing_customer_id: 0,
  billing_fee_id: 0,
  history: Map<string, any>(),
  next_date: new Date(),
  start_date: new Date(),
  status: "active",
  fee: feeFromJSON({}),
  created_at: new Date(),
  updated_at: new Date(),
};

export class FeeSubscriptionRecord extends Record(defaultFeeSubscriptionProps) implements IFeeSubscriptionRecord {
  public readonly id!: number;
  public readonly billing_account_id!: number;
  public readonly billing_customer_id!: number;
  public readonly billing_fee_id!: number;
  public readonly history!: Map<string, any>;
  public readonly next_date!: Date;
  public readonly start_date!: Date;
  public readonly status!: FeeSubscriptionStatus;

  public readonly fee!: FeeRecord;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IFeeSubscriptionRecord>) {
    values ? super(values) : super();
  }
}
