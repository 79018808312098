import { IPretty } from "app/src/Common/PrettyNameService";
import { IProductOption, IProductOptionResource } from "app/src/Models/ProductOption";
import { ISkuData } from "app2/src/records/Sku";
import { Actions } from "app2/src/reducers/sku.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import { skuRecord } from "app2/src/selectors/sku.selectors";
import { RootState } from "app2/src/reducers";

export class OrgProductOptionEditorCtrl {
  public activeTab: number;
  public valid_charge_type: string[] = ["per_quantity", "per", "per_ui", "per_sqft"];

  public static $inject = ["$uibModalInstance", "Pretty", "option", "ProductOption", "multi", "valid_uom"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public Pretty: IPretty,
    public option: IProductOption,
    public ProductOption: IProductOptionResource,
    public multi: boolean,
    public valid_uom: string[],
  ) {
    const skuId = this.option.sku_id;
    const sku = this.option.sku;
    if (!skuId) {
      const orgId = useSelector(currentOrgId);
      dispatch(
        Actions.receiveSku({
          item_uuid: this.option.uuid,
          value: "",
          org_id: orgId,
        } as ISkuData),
      );
    } else if (!useSelector((state: RootState) => skuRecord(state, skuId)) && sku) {
      dispatch(Actions.receiveSku(sku));
    }
  }

  public save() {
    const the_option = this.ProductOption.fromJSON(this.option);
    this.$uibModalInstance.close({
      option: the_option,
    });
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }
}
