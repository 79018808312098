import * as React from "react";
import { Modal } from "react-bootstrap";
import { Context } from "app2/src/components/SignatureRequest/Context";

export interface SignatureRequestModalProps {
  children?: React.ReactNode;
}

export const SignatureRequestModal: React.FC<SignatureRequestModalProps> = ({ children }) => {
  const { dispatch: contextDispatch } = React.useContext(Context);
  return (
    <Modal
      show={true}
      onHide={() => contextDispatch("close", { modalType: "select" })}
      size="xl"
      backdrop="static"
      animation={false}>
      {children}
    </Modal>
  );
};
