import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";

export const validationsById = (state: RootState, props: any) => state.getIn(["validations", "byId"]);
export const validationIdFromItemUuid = (state: RootState, props: any) =>
  state.getIn(["validations", "byItemUuid", props.itemUuid]);

export const validationFromItemUuid = createSelector(
  [validationsById, validationIdFromItemUuid],
  (validationsById, validationId) => {
    return validationsById.get(validationId);
  },
);
