import { IMeasurement } from "app/src/Models/Measurement";

export interface IRoomMetadata extends IRoom {
  quantity: number;
}

export interface IRoom {
  id: number;
  name: string;
  designator: string;
  location: string;
  level: string;
  area: number;
  perimeter: number;
  inside_corner: number;
  outside_corner: number;
  threshold: number;
  ceiling_area: number;
  ceiling_area_less_structures: number;
  ceiling_perimeter: number;
  ceiling_perimeter_less_structures: number;
  door_area: number;
  door_count: number;
  door_total_width: number;
  exposed_structure_perimeter: number;
  floor_area_less_structures: number;
  floor_opening_total_width: number;
  floor_plan_area: number;
  floor_plan_perimeter: number;
  floor_plan_perimeter_less_structures: number;
  floor_plan_perimeter_without_openings: number;
  object_count: number;
  opening_count: number;
  opening_total_area: number;
  opening_total_width: number;
  structure_total_ceiling_area: number;
  structure_total_ceiling_width: number;
  structure_total_floor_area: number;
  structure_total_floor_width: number;
  structure_total_wall_area: number;
  volume: number;
  wall_count: number;
  wall_total_area: number;
  wall_total_area_without_openings: number;
  window_count: number;
  window_total_area: number;
  measurement_id: number;
  ref_id: number;
  created_at: Date;
  updated_at: Date;
}

export class Room implements IRoom {
  public id: number;
  public name: string;
  public designator: string;
  public location: string;
  public level: string;
  public area: number;
  public perimeter: number;
  public inside_corner: number;
  public outside_corner: number;
  public threshold: number;
  public ceiling_area: number;
  public ceiling_area_less_structures: number;
  public ceiling_perimeter: number;
  public ceiling_perimeter_less_structures: number;
  public door_area: number;
  public door_count: number;
  public door_total_width: number;
  public exposed_structure_perimeter: number;
  public floor_area_less_structures: number;
  public floor_opening_total_width: number;
  public floor_plan_area: number;
  public floor_plan_perimeter: number;
  public floor_plan_perimeter_less_structures: number;
  public floor_plan_perimeter_without_openings: number;
  public object_count: number;
  public opening_count: number;
  public opening_total_area: number;
  public opening_total_width: number;
  public structure_total_ceiling_area: number;
  public structure_total_ceiling_width: number;
  public structure_total_floor_area: number;
  public structure_total_floor_width: number;
  public structure_total_wall_area: number;
  public volume: number;
  public wall_count: number;
  public wall_total_area: number;
  public wall_total_area_without_openings: number;
  public window_count: number;
  public window_total_area: number;
  public measurement_id: number;
  public ref_id: number;
  public created_at: Date;
  public updated_at: Date;

  constructor() {}

  public static fromJSON = (data: any): IRoom => {
    const room = new Room();

    Room.copyValues(room, data);
    room["kind"] = "Room";
    return room;
  };

  public static addRooms(measurement: IMeasurement, room: IRoomMetadata) {
    if (!room.quantity || room.quantity <= 1) {
      this._addRooms(measurement, room);
    } else {
      for (let i = 0; i < room.quantity; i++) {
        let index = i + 1;
        while (_.any(measurement["room"], (r: IRoom) => r.name === room.name + "-" + index)) {
          index += 1;
        }
        const clone = JSON.parse(JSON.stringify(room));
        clone.name = room.name + "-" + index;
        this._addRooms(measurement, clone);
      }
    }
  }

  public static copyValues(room: IRoom, data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        switch (key) {
          case "id":
            data[key] = parseInt(data[key]);
            break;
          case "area":
            data[key] = parseFloat(data[key]);
            break;
          case "perimeter":
            data[key] = parseFloat(data[key]);
            break;
          case "inside_corner":
            data[key] = parseInt(data[key]);
            break;
          case "outside_corner":
            data[key] = parseInt(data[key]);
            break;
          case "updated_at":
            data[key] = new Date(data[key]);
            break;
          case "created_at":
            data[key] = new Date(data[key]);
            break;
        }

        room[key] = data[key];
      }
    }
  }

  private static _addRooms(measurement: IMeasurement, room: IRoom) {
    if (room.id) {
      const index = _.findIndex(measurement.rooms, (r) => {
        return r.id === room.id;
      });
      measurement.rooms[index] = room;
    } else {
      room.id = measurement.getId();
      measurement.rooms.push(room);
    }
  }
}
