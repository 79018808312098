import * as priceListProcessActions from "./priceListProcess.actions";
import { List, Record } from "immutable";
import { PriceListProcessRecords, fromJSON } from "app2/src/records/PriceListProcess";

export const PriceListProcessStateRecord = Record({
  processes: new PriceListProcessRecords(),
});

export const initialState = PriceListProcessStateRecord();
export type PriceListProcessState = typeof initialState;

export const reducer = (state = initialState, action: priceListProcessActions.Actions): PriceListProcessState => {
  let processes: PriceListProcessRecords;
  switch (action.type) {
    case priceListProcessActions.FETCH_PROCESSES:
      if (state.getIn(["processes"])) {
        return state.setIn(["processes", "loading"], true);
      }
      return state.setIn(["processes"], fromJSON({ list: {}, loading: true }));
    case priceListProcessActions.RECEIVE_PROCESSES:
      processes = fromJSON({ ...action.payload, loading: false });

      return state.setIn(["processes"], processes);
    case priceListProcessActions.RECEIVE_PROCESS_ERRORS:
      return state.setIn(["processes", "errors"], List<string>(action.payload)).setIn(["processes", "loading"], false);
    default:
      return state;
  }
};
