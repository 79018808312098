import * as React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { preferencesConfig } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: AngiesListProps) => {
  const prefs = preferencesConfig(state, { orgId: ownProps.orgId });
  return {
    alUrl: prefs?.getIn(["integrations", "angies_list", "url"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: AngiesListProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface AngiesListProps {
  orgId: number;
  updatePrefConfig: (path: any, value: any) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & AngiesListProps;

class AngiesList extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { alUrl, updatePrefConfig } = this.props;

    return (
      <div className="form-section">
        <div className="form-section-content">
          <Row>
            <Col sm={4} className="d-flex">
              <img
                className="img-fluid align-self-center integration-logo"
                src="/assets/images/angies_list.efa51597.png"
                title="Angie's List"
              />
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  id="al_url"
                  defaultValue={alUrl}
                  onChange={(e) => updatePrefConfig(["integrations", "angies_list", "url"], e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connector(AngiesList);
