import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import { EstimatePrototype } from "app/src/Models/Estimate";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { ValidationRecord } from "app2/src/records/Validation";
import { RootState } from "app2/src/reducers";
import { cachedProduct } from "app2/src/selectors/product.selectors";
import { validationFromItemUuid } from "app2/src/selectors/validation.selectors";

export const validationMessage = (validation_results: any[], ConfirmDialog: IConfirmDialog) => {
  const messages = [];
  _.each(validation_results, (result) => {
    let message = `<h2>${result.name}:</h2>`;
    if (result.required) {
      message += resultMessage(result.required, "required");
    }

    if (result.optional) {
      message += resultMessage(result.optional, "optional");
    }
    messages.push(message);
  });

  ConfirmDialog.confirm("", {
    template: `<div class="modal-body">${messages.join(
      "<br>",
    )}</div><div class="modal-footer"><button class="btn btn-default" type="button" ng-click="$close()">OK</button></div>`,
    size: "lg",
  });
};

export const resultMessage = (data: any, type: string) => {
  return `<p>${data.qty_met} out of ${
    data.qty_required
  } ${type} categories met. Choose a product from the following categories to pass validation.<p><ul class="list-group"><li class="list-group-item">${data.categories.join(
    '</li><li class="list-group-item">',
  )}</li></ul><br>`;
};

export const validateLineItem = (
  state: RootState,
  estimate: EstimatePrototype,
  lineItem: IEstimateLineItem,
): { validated: boolean; validation_result: any } => {
  const validation = findValidation(state, lineItem.product_id, estimate.activated_price_list_id);
  if (!validation) {
    return { validated: true, validation_result: {} };
  }
  const definition = validation.definition;
  const required = [];
  let validated = true;
  _.each(definition.required_categories || [], (category: any) => {
    if (findLineItemProduct(state, estimate, category.product_uuids)) {
      required.push(category.name);
    }
  });
  const validation_result: any = { name: validation.name };
  if (required.length < definition.required_categories.length) {
    validated = false;
    validation_result.required = {
      qty_met: required.length,
      qty_required: definition.required_categories.length,
      categories: _.difference(_.pluck(definition.required_categories, "name"), required),
    };
  }

  const optional = [];
  _.each(definition.categories || [], (category: any) => {
    if (findLineItemProduct(state, estimate, category.product_uuids)) {
      optional.push(category.name);
    }
  });
  if (optional.length < definition.min_required_qty) {
    validated = validated && false;
    validation_result.optional = {
      qty_met: optional.length,
      qty_required: definition.min_required_qty,
      categories: _.difference(_.pluck(definition.categories, "name"), optional),
    };
  }
  return { validated, validation_result };
};

export const findValidation = (state: RootState, productId: number, activatedPriceListId: number): ValidationRecord => {
  if (!productId) {
    return;
  }
  const product = cachedProduct(state, {
    activatedPriceListId: activatedPriceListId,
    productId: productId,
  });
  if (!product) {
    console.error(`ProductId ${productId} not found in redux - activatedPriceListId ${activatedPriceListId}`);
    return;
  }

  const validation = validationFromItemUuid(state, { itemUuid: product.uuid });
  if (validation) {
    return validation;
  }
  return findValidation(state, product.parent_id, activatedPriceListId);
};

export const findLineItemProduct = (
  state: RootState,
  estimate: EstimatePrototype,
  uuids: string[],
): IEstimateLineItem => {
  const lineItem = _.find(estimate.lineItems(estimate.estimateGroups(true)), (lineItem: IEstimateLineItem) => {
    if (!lineItem.product_id || lineItem.quantity <= 0) {
      return false;
    }

    return checkLineItemProduct(state, lineItem.product_id, uuids, estimate.activated_price_list_id);
  });

  return lineItem;
};

export const checkLineItemProduct = _.memoize(
  (state: RootState, productId: number, uuids: string[], activatedPriceListId: number): boolean => {
    if (!productId) {
      return false;
    }
    const product = cachedProduct(state, {
      activatedPriceListId: activatedPriceListId,
      productId: productId,
    });
    if (!product) {
      return false;
    }

    if (_.includes(uuids, product.uuid)) {
      return true;
    }

    return checkLineItemProduct(state, product.parent_id, uuids, activatedPriceListId);
  },
  (state, productId, uuids) => {
    let hash = "undefined";
    if (productId) {
      hash = productId;
    }
    return hash + uuids.join(",");
  },
);
