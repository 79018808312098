import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as estimateTemplateActions from "app2/src/reducers/estimateTemplate.actions";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { Row, Col, Button } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Sortable } from "app2/src/components/Common/Sortable";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { List } from "immutable";
import track from "react-tracking";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import EstimateTemplate from "app2/src/components/JobTabs/EstimateTab/EstimateTemplateModal/EstimateTemplates/EstimateTemplate";
import { IEstimateTemplate } from "app/src/Models/EstimateTemplate";
import { Can } from "app2/src/components/Common/CanComponent";

const mapStateToProps = (state: RootState) => {
  const modelName = "estimateTemplate",
    templateQueryParams = queryParams(state, { modelName });
  const page = templateQueryParams.get("page");
  const estimateTemplateIds = ids(state, { modelName });
  return {
    estimateTemplateIds,
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    templateQueryParams,
    orgId: currentOrgId(state),
    selectedCheck: false,
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: EstimateTemplatesProps) => {
  return {
    listEstimateTemplates: (orgId: number, queryParams: QueryParamsRecord) =>
      dispatch(
        estimateTemplateActions.AsyncActions.listEstimateTemplates(
          {
            id: orgId,
            folderId: ownProps.folderParentId,
          },
          queryParams,
        ),
      ),
    onSortEnd: (oldIndex: number, newIndex: number) =>
      dispatch(estimateTemplateActions.AsyncActions.onSortEnd(oldIndex, newIndex)),
    batchEditSelected: (estimateTemplateIds: List<number>, value: boolean) =>
      dispatch(estimateTemplateActions.Actions.batchEditSelected(estimateTemplateIds, value)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface EstimateTemplatesProps {
  folderParentId: number;
  readOnly: boolean;
  templateName: string;
  saveAs: (selection: string | IEstimateTemplate) => void;
  select: (selection: IEstimateTemplate) => void;
  setReadOnly: () => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & EstimateTemplatesProps;

@track(() => {
  return {
    component: "EstimateTemplates",
  };
})
class EstimateTemplates extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.queryEstimateTemplates = this.queryEstimateTemplates.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
  }

  @track((_props, _state, [page]) => ({
    action: "queryEstimateTemplates",
    page: page,
  }))
  public queryEstimateTemplates(page: number): void {
    const { listEstimateTemplates, templateQueryParams, orgId } = this.props;
    const newQueryParams = templateQueryParams.setIn(["page"], page);

    listEstimateTemplates(orgId, newQueryParams);
  }

  @track((props, _state, [oldIndex, newIndex]) => ({
    action: "onDragEnd",
    oldIndex: oldIndex,
    newIndex: newIndex,
    estimateTemplates: props.estimateTemplateIds.toArray(),
  }))
  public onDragEnd(oldIndex: number, newIndex: number): void {
    const { onSortEnd } = this.props;

    onSortEnd(oldIndex, newIndex);
  }

  @track((props, _state, [event]) => ({
    action: "checkAll",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
    estimateTemplates: props.estimateTemplateIds.toArray(),
  }))
  public checkBoxOnChange(event: any): void {
    const { estimateTemplateIds, batchEditSelected } = this.props;
    batchEditSelected(estimateTemplateIds, event.target.checked);
  }

  public render(): React.ReactNode {
    const {
      readOnly,
      estimateTemplateIds,
      page,
      pagination,
      selectedCheck,
      templateQueryParams,
      templateName,
      saveAs,
      select,
      setReadOnly,
    } = this.props;

    if (page?.loading) {
      return <SpinnerComponent localProperty={true} />;
    }

    if (_.isNullOrUndefined(page) || estimateTemplateIds.size === 0) {
      return (
        <Row>
          <Col>
            <div className="form-section blank-state">
              <img src="/assets/images/icons-large/docs.33cddf3f.png" />
              <h2>
                No Estimate {templateName}s.{" "}
                <Can resource="estimate_template" permission="update">
                  Let's add one
                </Can>
              </h2>
              <Can resource="estimate_template" permission="update">
                {!readOnly && <p>Add a name below and click save.</p>}
              </Can>
              <Can resource="estimate_template" permission="update" inverse>
                <p>Contact your organization administrator to add them.</p>
              </Can>
              <Can resource="estimate_template" permission="update">
                {readOnly && <Button onClick={setReadOnly}>Add Estimate Template</Button>}
              </Can>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col>
          <div className="standard-rl-margin">
            <Row className="table-header">
              <Col sm={2} style={{ paddingLeft: "15px" }}>
                {!readOnly && (
                  <IncludeCheckBox name="selected" checked={selectedCheck} onChange={this.checkBoxOnChange} />
                )}
              </Col>
              <Col>Name</Col>
              <Col sm={3}>Updated On</Col>
              {!readOnly && <Col sm={2}>Actions</Col>}
            </Row>
            <Sortable
              dragHandle
              disableDrag={templateQueryParams.get("page") !== 1}
              items={estimateTemplateIds}
              onDragEnd={this.onDragEnd}
              renderItem={({ item, dragHandle }) => {
                return (
                  <EstimateTemplate
                    saveAs={saveAs}
                    select={select}
                    readOnly={readOnly}
                    estimateTemplateId={item}
                    dragHandle={dragHandle}
                  />
                );
              }}
            />
            <StandardPagination metadata={pagination} pageChanged={this.queryEstimateTemplates} />
            <br />
          </div>
        </Col>
      </Row>
    );
  }
}

export default connector(EstimateTemplates);
