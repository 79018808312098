import * as React from "react";
import { useState } from "react";
import * as FontAwesome from "react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import AudioInputList from "./components/AudioInputList";
import VideoInputList from "./components/VideoInputList";
import AudioOutputList from "./components/AudioOutputList";
import { useTracking } from "react-tracking";
import AdvancedSettings from "./components/AdvancedSettings";

export function DeviceSelector(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const { trackEvent } = useTracking();

  const open = () => {
    trackEvent({ action: "config opened" });
    setIsOpen(true);
  };

  const close = () => {
    trackEvent({ action: "config closed" });
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="light" onClick={open} className="control-button">
        <FontAwesome name="cog" size="lg" />
      </Button>
      <Modal show={isOpen} size="lg" backdrop={"static"} onHide={() => setIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Configuration</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AudioInputList />
          <AudioOutputList />
          <VideoInputList />
          <AdvancedSettings />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close}>Done</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
