import * as ng from "angular";
// @ts-ignore
import ace = require("ace-builds");

import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import * as angulartics from "angulartics";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { IJob, IJobResource } from "app/src/Models/Job";
import { IEstimateResource } from "app/src/Models/Estimate";
import { IActivatedPriceList } from "app/src/Models/ActivatedPriceList";
import { IOrgResource } from "app/src/Models/Org";
import { IPattern, IPatternResource } from "app/src/Models/Pattern";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { IPretty } from "app/src/Common/PrettyNameService";
import { HandlebarsHelperService } from "app/src/Patterns/HandlebarsHelperService";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { dispatch, useSelector } from "app2/src/storeRegistry";
import { jobOverviewEstimates } from "app2/src/selectors/estimate.selectors";
import { EstimateOverviewRecord } from "app2/src/records/EstimateOverview";

export class PatternEditorComponentCtrl implements ng.IComponentController {
  [s: string]: any;
  public pattern: IPattern;
  public edit_pattern: IPattern;
  public compiled_html = "";
  public doc_type = "estimate";
  public jobs: IJob[];
  public job: IJob;
  public estimate: EstimateOverviewRecord;
  public estimates: EstimateOverviewRecord[];
  public activated_price_list: IActivatedPriceList;
  public spinnerPromise: ng.IPromise<any>;
  public editor: any;
  public locals: any;
  public fullscreen = false;
  public onClose: () => void;
  public patternDocTypes: string[] = [
    "estimate",
    "cover_page",
    "job_photos",
    "product_images",
    "cost",
    "inspection",
    "presentation_template",
    "commission",
    "labor_summary",
  ];
  public docTypes: string[] = ["estimate", "contract"];

  public sort: any = {
    by: "jobs.updated_at",
    order: "desc",
  };

  constructor(
    public OrgFetcher: IOrgFetcherService,
    public JobFetcher: IJobFetcherService,
    public Org: IOrgResource,
    public Job: IJobResource,
    public Pattern: IPatternResource,
    public Estimate: IEstimateResource,
    public $stateParams: ng.ui.IStateParamsService,
    public $sce: ng.ISCEService,
    public $window: ng.IWindowService,
    public Flash: IFlash,
    public Pretty: IPretty,
    private $analytics: angulartics.IAnalyticsService,
    private $scope: ng.IScope,
    public HandlebarsHelperService: HandlebarsHelperService,
  ) {}

  public $onChanges() {
    if (this.pattern) {
      this.edit_pattern = this.Pattern.fromJSON(this.pattern);
      // noinspection BadExpressionStatementJS
      ace;
      this.editor = this.$window.ace.edit("editor");
      this.editor.setValue(this.edit_pattern.view);

      const defaultParams = { pattern: true };
      defaultParams["page"] = 1;
      defaultParams["sort_by"] = this.sort.by;
      defaultParams["sort_order"] = this.sort.order;

      this.spinnerPromise = this.Job.query(defaultParams, (response) => {
        this.jobs = response.jobs;
      }).$promise.then(() => {
        this.job = this.jobs[0];
        return this.jobChanged().then(() => {
          this.compile();
        });
      });
    }
  }

  public compile(): boolean {
    try {
      this.compiled_html = this.$sce.trustAsHtml(
        this.HandlebarsHelperService.compile(this.editor.getValue(), this.locals),
      );
      return true;
    } catch (err) {
      this.Flash.addMessage(FlashLevels.danger, err.message);
      return false;
    }
  }

  public jobChanged(): ng.IPromise<any> {
    const getEstimatesPromise = dispatch(estimateActions.AsyncActions.getJobEstimates(this.job.id));
    return getEstimatesPromise.then(() => {
      this.estimates = useSelector((state) => jobOverviewEstimates(state, { jobId: this.job.id })).toArray();
      this.estimate = this.estimates[0];

      return this.estimateChanged();
    });
  }

  public estimateChanged(): ng.IPromise<any> {
    return this.Estimate.pattern({
      doc_type: this.doc_type,
      id: this.estimate.id,
      job_id: this.job.id,
    }).$promise.then((resp) => {
      this.locals = resp;
      this.compile();
    });
  }

  public fullScreen() {
    this.fullscreen = !this.fullscreen;
  }

  public save() {
    if (!this.compile()) {
      this.Flash.addMessage(FlashLevels.danger, "Unable to save since there is an error!");
      return;
    }
    this.edit_pattern.view = this.editor.getValue();
    const new_pattern = this.edit_pattern.id === ("new" as any as number);
    this.spinnerPromise = this.edit_pattern
      .$saveOrCreate()
      .then((resp) => {
        if (new_pattern) {
          if (this.edit_pattern.patternable_id) {
            this.$scope.$emit("org:pattern_added", { pattern: resp });
          } else {
            this.$scope.$emit("global:pattern_added", { pattern: resp });
          }
        } else {
          this.pattern.name = this.edit_pattern.name;
          this.pattern.doc_type = this.edit_pattern.doc_type;
          this.pattern.view = this.edit_pattern.view;
          this.pattern.active = this.edit_pattern.active;
        }
        this.Flash.addMessage(FlashLevels.success, "Pattern successfully saved.");
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There was a problem saving the pattern. Please try again. If the problem persists, please contact support.",
        );
      });
  }

  public close() {
    this.onClose();
  }
}

export class PatternEditorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Patterns/pattern_editor_component.html";
  public bindings: any = {
    pattern: "<",
    onClose: "&",
  };

  constructor() {
    this.controller = PatternEditorComponentCtrl;
    this.controller.$inject = [
      "OrgFetcher",
      "JobFetcher",
      "Org",
      "Job",
      "Pattern",
      "Estimate",
      "$stateParams",
      "$sce",
      "$window",
      "Flash",
      "Pretty",
      "$analytics",
      "$scope",
      "HandlebarsHelperService",
    ];
  }
}
