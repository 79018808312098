import { queryParamsFromJSON } from "app2/src/records/Page";
import { currentJobId } from "app2/src/selectors/job.selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as documentActions from "app2/src/reducers/document.actions";
import { Col, Row } from "react-bootstrap";
import { List } from "immutable";
import { DocumentRecord } from "app2/src/records/Document";
import { RootState } from "app2/src/reducers";
import { currentPageRecord, getPaginationByModel } from "app2/src/selectors/pagination.selectors";
import SpinnerComponent from "../../SpinnerComponent";
import { PaginationControl } from "../../Common/PaginationControl";
import { SignaturesDocumentsTable } from "./SignaturesDocumentsTable";

export const SignableDocuments: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();

  // Selectors
  const jobId = useSelector(currentJobId);
  const signableDocuments: List<DocumentRecord> = useSelector((state: RootState) =>
    getPaginationByModel(state, {
      modelName: "signableDocuments",
      path: ["documents", "byId"],
    }),
  );
  const loading = useSelector(
    (state: RootState) => currentPageRecord(state, { modelName: "signableDocuments" })?.loading,
  );

  const load = (page: number) => {
    dispatch(
      documentActions.AsyncActions.listDocuments(
        {
          documentableId: jobId,
          documentableType: "job",
          signed: "signed",
          signed_kind: "document",
          sort_by: "documents.created_at",
          sort_order: "asc",
        },
        queryParamsFromJSON({ page }),
        "signableDocuments",
      ),
    );
  };

  React.useEffect(() => {
    load(1);
  }, []);

  if (loading) return <SpinnerComponent localProperty={true} inline />;

  if (signableDocuments?.size > 0)
    return (
      <>
        <SignaturesDocumentsTable documents={signableDocuments} />
        <PaginationControl modelName="signableDocuments" pageChanged={load} />
      </>
    );

  return (
    <Row>
      <Col md={12}>
        <div className="form-section blank-state">
          <img src="/assets/images/icons-large/contracts.c69d9978.png" />
          <h2>No signable documents.</h2>
        </div>
      </Col>
    </Row>
  );
};

export default SignableDocuments;
