import * as React from "react";
import { JobTabTitle } from "../../JobTabTitle";
import MakePaymentButton from "app2/src/components/PaymentsModal/MakePaymentButton";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { currentJobId, selectedEstimateId } from "app2/src/selectors/job.selectors";
import { RootState } from "app2/src/reducers";
import { contractTitle } from "app2/src/selectors/org.selectors";
import SignedDocumentsTable from "app2/src/components/SignedDocument/SignedDocumentsTable";
import { estimateSignedDocuments } from "app2/src/selectors/estimate.selectors";
import { DocumentRecord } from "app2/src/records/Document";
import { push, replace } from "connected-react-router/immutable";
import { locationState as locationStateSelector, pathname } from "app2/src/selectors/router.selectors";
import * as jobActions from "app2/src/reducers/job.actions";
import { Route, Switch, useRouteMatch } from "react-router";
import { SigningRecipientsModal } from "../../SignaturesTab/SigningRecipientsModal";
import { PaymentsModal } from "app2/src/components/PaymentsModal";
import { useTracking } from "react-tracking";

export const SelectContract: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch<{ estimateId: string }>();
  const { trackEvent } = useTracking<any>({
    category: "Contract",
  });

  // Selectors
  const path = useSelector(pathname);
  const jobId = useSelector(currentJobId);
  const estimateId = useSelector(selectedEstimateId);
  const title = useSelector(contractTitle);
  const documents = useSelector((state: RootState) => estimateSignedDocuments(state, { jobId, estimateId }));
  const locationState = useSelector(locationStateSelector);

  // Methods
  const createContract = () => {
    dispatch(push(`${path}/view_pdf/new`));
  };
  const emailOrSign = (document: DocumentRecord) => {
    dispatch(push(`${path}/signing/${document.signed_document.id}`));
  };
  const showPDF = (document: DocumentRecord) => {
    trackEvent({ action: "existing contract selected" });
    dispatch(push(`${path}/view_pdf/${document.id}`));
  };
  const backToAll = () => {
    trackEvent({ action: "cleared" });
    dispatch(jobActions.Actions.setSelectedEstimateId(null));
    dispatch(push(path.split(`/contracts/${estimateId}`)[0] + "/contracts"));
  };

  // Lifecycle
  React.useEffect(() => {
    if (!estimateId) {
      dispatch(jobActions.Actions.setSelectedEstimateId(Number(match.params.estimateId)));
    }
    if (documents?.isEmpty() && !locationState?.get("noRedirect")) {
      dispatch(replace(`${path}/view_pdf/new`));
    }
  }, []);

  return (
    <>
      <JobTabTitle title={title}>
        <div className="pull-right">
          <MakePaymentButton />
          <Button onClick={backToAll}>Back to All</Button>
          <Button onClick={createContract}>Create {title} for Signature</Button>
        </div>
      </JobTabTitle>
      {documents?.size > 0 ? (
        <SignedDocumentsTable
          documents={documents}
          emailOrSign={emailOrSign}
          showPDF={showPDF}
          onDocumentClick={showPDF}
        />
      ) : (
        <div className="form-section blank-state">
          <img src="/assets/images/icons-large/estimates.022a621b.png" />
          <h2>No {title}s added. Let's add one.</h2>
          <Button onClick={createContract}>Create {title} for Signature</Button>
        </div>
      )}
      <Switch>
        <Route path={`${match.url}/signing/:signedDocumentId`} component={SigningRecipientsModal} />
        <Route path={`${match.url}/payments`} component={PaymentsModal} />
      </Switch>
    </>
  );
};
