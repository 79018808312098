import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { formatStatus, IFinanceOptionData } from "app2/src/records/FinanceOption";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { CurrencyFormat } from "app2/src/helpers/Format";

export interface DisplayProps {
  financeOption: IFinanceOptionData;
  dragHandle: any;
  edit: (id: number) => void;
  archive: (id: number) => void;
}

export default class Display extends React.Component<DisplayProps, any> {
  constructor(props) {
    super(props);
    this.applyNow = this.applyNow.bind(this);
  }

  public applyNow() {
    const { financeOption } = this.props;
    window.open(financeOption.apply_now_url, "_blank");
  }

  public render() {
    const { financeOption, edit, dragHandle, archive } = this.props;

    return (
      <div className="form-section">
        <Row>
          <Col md={1}>
            <img {...dragHandle} src="/assets/images/icons/ic_sort.ea6f8933.png" title="Order Finance Option" />
          </Col>
          <Col md={3}>
            <p>{financeOption.name}</p>
            <p className={financeOption.status === "inactive" ? "red-text" : ""}>
              {formatStatus(financeOption.status)}
            </p>
            {financeOption.apply_now_url ? (
              <Button variant="default" onClick={this.applyNow}>
                Apply Now
              </Button>
            ) : (
              <p>No Url</p>
            )}
          </Col>
          <Col md={3}>
            {financeOption.formula ? <p>Formula</p> : <p>Rate & Term</p>}
            {financeOption.formula ? (
              <p>{financeOption.formula}</p>
            ) : (
              <p>
                {financeOption.rate}% for {financeOption.term} months
              </p>
            )}
          </Col>
          <Col md={3}>
            {financeOption.validation.active && (
              <>
                <p>Min Amount: {CurrencyFormat(parseInt(financeOption.validation.min_amount))}</p>
                <p>Max Amount: {CurrencyFormat(parseInt(financeOption.validation.max_amount))}</p>
                <p>Min Payment: {CurrencyFormat(parseInt(financeOption.validation.min_payment))}</p>
                <p>State: {financeOption.validation.state}</p>
              </>
            )}
          </Col>
          {financeOption.provider !== "fin_mkt" && financeOption.provider !== "fin_mkt_offer" && (
            <Col md={2}>
              <i
                className="rsf-edit-link rsf-base-66 action-button"
                title="Edit Finance Option"
                onClick={() => edit(financeOption.id)}
              />
              <ConfirmDialog
                title="Delete Finance Option"
                description={`Are you sure you want to delete finance option: ${financeOption.name}?`}>
                {(confirm) => (
                  <i
                    className="rsf-delete-link rsf-base-66 action-button"
                    title="Delete Finance Option"
                    onClick={confirm(() => archive(financeOption.id))}
                  />
                )}
              </ConfirmDialog>
            </Col>
          )}
          {this.showErrors(financeOption)}
        </Row>
      </div>
    );
  }

  public showErrors(financeOption) {
    if (financeOption.errors.size <= 0) {
      return null;
    }

    return (
      <Row>
        <Col md={12}>
          {financeOption.errors.map((error: string, idx) => {
            return (
              <div key={idx} className="has-error">
                {error}
              </div>
            );
          })}
        </Col>
      </Row>
    );
  }
}
