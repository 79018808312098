import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IResultReason extends ng.resource.IResource<IResultReason>, ResultReasonPrototype {}

export interface IResultReasonResource extends ng.resource.IResourceClass<IResultReason>, IRsfResource {
  fromJSON?(data: any): IResultReason;
}

export interface IResultReasonResponse extends ng.resource.IResourceArray<IResultReason> {
  result_reasons: IResultReason[];
  meta: IPagingMetadata;
}

class ResultReasonPrototype {
  public id: number;

  public $saveOrCreate(params?: any, callback?) {
    if (this.id <= 0 || (this.id as any as string) === "Unsaved") {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$save(params, callback);
    }
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, $http: ng.IHttpService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/events/result_reasons/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IResultReason => {
    if (status < 200 || status > 299) {
      return new ResultReason({});
    }

    return ResultReason.fromJSON(JSON.parse(response).result_reason);
  };

  const multiResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return { result_reasons: [], metadata: {} };
    }

    const meta: any = JSON.parse(response);

    _.each(meta.result_reasons, (child, index) => {
      meta.result_reasons[index] = ResultReason.fromJSON(child);
    });

    return meta;
  };

  const transformRequest = (result_reason: IResultReason): string => {
    const data = JSON.decycle(result_reason);
    return angular.toJson({ result_reason: data });
  };

  const ResultReason: IResultReasonResource = <IResultReasonResource>$resource(
    url,
    {},
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multiResponseTransform,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
      },
    },
  );

  ResultReason.fromJSON = (data: any): IResultReason => {
    return new ResultReason(data);
  };

  _.hiddenExtend(ResultReason.prototype, ResultReasonPrototype.prototype);

  ResultReason.inject = (injected: IRepository) => {
    resources = injected;
  };

  return ResultReason;
};

factory.$inject = ["$resource", "$http", "BaseConfig"];

export default factory;
