import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { DocumentsChooser } from "app2/src/components/SignatureRequest/DocumentsChooser";
import { push } from "connected-react-router/immutable";
import { Context, ContextRecord, SignatureAction } from "app2/src/components/SignatureRequest/Context";
import { List } from "immutable";
import { onSortEnd } from "app2/src/helpers/Record";
import * as documentActions from "../../reducers/document.actions";
import { SignatureRequestModal } from "app2/src/components/SignatureRequest/SignatureRequestModal";
import { ReviewSignatureRequest } from "app2/src/components/SignatureRequest/ReviewSignatureRequest";
import { SignatureConfiguration } from "./SignatureConfiguration";
import { DocumentRecord } from "../../records/Document";
import * as signedDocumentActions from "app2/src/reducers/signedDocument.actions";
import { pathname } from "app2/src/selectors/router.selectors";
import { currentJob as currentJobSelector } from "app2/src/selectors/job.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { document as combinedDoc } from "app2/src/selectors/document.selectors";
import { currentSignedDocument as currentSignedDocSelector } from "app2/src/selectors/signedDocument.selectors";
import { RootDispatchType } from "app2/src/store";
import { Nullable } from "app2/src/records";
import { useTracking } from "react-tracking";

export type ViewStates = "chooser" | "review" | "configuration" | "signing";

export const SignatureRequest: React.FunctionComponent = () => {
  // hooks
  const dispatch: RootDispatchType = useDispatch();
  const { trackEvent } = useTracking();

  // states
  const [documentIds, setDocumentIds] = useState(List<number>());
  const [requestName, setRequestName] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [modified, setModified] = useState(false);
  const [viewState, setViewState] = useState<ViewStates>("chooser");
  const downloadRef = React.useRef<Nullable<() => Promise<DocumentRecord>>>(null);
  // For analytics only
  const [documentNameChanged, setDocumentNameChanged] = useState(false);

  // selectors
  const currentSignedDocument = useSelector(currentSignedDocSelector);
  const sdDocument = useSelector((s: RootState) =>
    combinedDoc(s, { documentId: currentSignedDocument?.document_id || -1 }),
  );

  const path = useSelector(pathname);
  const job = useSelector(currentJobSelector);
  const orgId = useSelector((s: RootState) => currentOrgId(s));

  // useEffect
  useEffect(() => {
    resetSignedDocument();
  }, []);

  useEffect(() => {
    if (!currentSignedDocument) return;
    if (currentSignedDocument.validation_status === null) return;

    if (currentSignedDocument.validation_status === 422) {
      close("view", false);
    }
  }, [currentSignedDocument]);

  useEffect(() => {
    if (viewState === "signing" && currentSignedDocument.id > 0) {
      const prevPath = path.split("/signatures")[0];
      dispatch(push(`${prevPath}/signatures/signable_documents/signing/${currentSignedDocument.id}`));
    }
  }, [currentSignedDocument, viewState]);

  const resetSignedDocument = () => {
    dispatch(signedDocumentActions.Actions.setCurrentSignedDocumentId(-1));
    dispatch(signedDocumentActions.Actions.receiveDocument({ id: -1 }));
  };

  const back = (screen) => {
    setDownloading(true);
    switch (screen) {
      case "chooseDocuments":
        setViewState("chooser");
        break;
      case "viewDocument":
        setModified(false);
        downloadRef.current = null;
        setViewState("review");
        break;
      case "configureSigners":
        setViewState("configuration");
        break;
    }
    setDownloading(false);
  };

  const close = async (modalType: string, destroy = false) => {
    setDownloading(true);
    if (destroy === true) {
      dispatch(documentActions.AsyncActions.deleteDocument(sdDocument));
    }

    resetSignedDocument();
    setViewState("chooser");
    setModified(false);
    setDownloading(false);

    if (modalType !== "view") {
      const prevPath = path.split("/signature_request")[0];
      dispatch(push(prevPath));
    }
  };

  const validate = async () => {
    let modifiedDoc: DocumentRecord;
    if (modified) {
      modifiedDoc = await downloadRef.current();
      dispatch(documentActions.AsyncActions.deleteDocument(sdDocument));
    }
    setDownloading(true);

    await dispatch(
      signedDocumentActions.AsyncActions.validateSignedDocument(!modifiedDoc ? sdDocument : modifiedDoc, modified),
    );
    setViewState("configuration");
    setDownloading(false);
  };

  const combineDocuments = async () => {
    setDownloading(true);

    try {
      await dispatch(signedDocumentActions.AsyncActions.combineDocument(documentIds, requestName, job.id));
      setViewState("review");
    } finally {
      setDownloading(false);
    }
  };

  const modifyDoc = useCallback((isModified: boolean, getModifiedFile: () => Promise<DocumentRecord>) => {
    setModified(isModified);
    downloadRef.current = getModifiedFile;
  }, []);

  const handleSubmit = async (event) => {
    let notify = false;
    let message = "";

    setDownloading(true);
    if (event?.nativeEvent?.submitter?.name === "email") {
      notify = true;
      message = (document.getElementById("message") as HTMLInputElement).value;
    }

    await dispatch(signedDocumentActions.AsyncActions.create(sdDocument, { notify, message }));

    if (notify) {
      close("", false);
    } else {
      setViewState("signing");
    }

    const recipientsCount = currentSignedDocument.recipients.size;
    trackEvent({ action: "Request Submitted", recipientsCount });
  };

  // methods
  const signatureReducer = (action: SignatureAction, payload: any) => {
    switch (action) {
      case "addDocumentId":
        const { id: addId } = payload;
        setDocumentIds((docIds) => docIds.push(addId).toSet().toList());
        trackEvent({ action: "Document Added", documentId: addId });
        return;
      case "removeDocumentId":
        const { id: removeId } = payload;
        setDocumentIds((docIds) =>
          docIds
            .filter((documentId) => documentId !== removeId)
            .toSet()
            .toList(),
        );
        trackEvent({ action: "Document Removed", documentId: removeId });
        return;
      case "onDragEnd":
        const { oldIndex, newIndex } = payload;
        setDocumentIds((docIds) => onSortEnd(docIds, oldIndex, newIndex, []).list);
        trackEvent({ action: "Document Dragged", oldIndex, newIndex });
        return;
      case "setRequestName":
        if (requestName !== "") setDocumentNameChanged(true);
        setRequestName(payload.requestName);
        return;
      case "combineDocuments":
        if (documentNameChanged) trackEvent({ action: "Document Name updated" });
        trackEvent({ action: "Document(s) submitted for combination", documentCount: documentIds.size });
        combineDocuments();
        return;
      case "validate":
        validate();
        trackEvent({ action: "Document Validated" });
        return;
      case "close":
        close(payload.modalType, payload.destroy || false);
        trackEvent({ action: "Modal Closed", modalType: payload.modalType });
        return;
      case "back":
        const { screen } = payload;
        back(screen);
        trackEvent({ action: "Back button clicked", screen });
        return;
      case "modifyDoc":
        const { getModifiedFile, isModified } = payload;
        modifyDoc(isModified, getModifiedFile);
        trackEvent({ action: "Document Modified" });
        return;
      case "handleSubmit":
        const { event } = payload;
        handleSubmit(event);
        return;
      case "spinner":
        setDownloading(true);
        return;
    }
  };

  return (
    <Context.Provider
      value={
        new ContextRecord({
          requestName,
          documentIds,
          job,
          orgId,
          dispatch: signatureReducer,
        })
      }>
      <SignatureRequestModal>
        {downloading && (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <img className="spinner-graphic" />
          </div>
        )}
        {(() => {
          switch (downloading ? "" : viewState) {
            case "chooser":
              return <DocumentsChooser />;
            case "review":
              return <ReviewSignatureRequest />;
            case "configuration":
              return <SignatureConfiguration />;
            case "signing":
              return (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <img className="spinner-graphic" />
                </div>
              );
            default:
              return null;
          }
        })()}
      </SignatureRequestModal>
    </Context.Provider>
  );
};
