import * as React from "react";
import useTrack from "app2/src/components/Twilio/hooks/useTrack/useTrack";
import { IVideoTrack } from "app2/src/components/VideoProvider/types";
import VideoTrack from "app2/src/components/Twilio/components/VideoTrack";
import AudioTrack from "app2/src/components/Twilio/components/AudioTrack";
import { AudioTrack as IAudioTrack, LocalTrackPublication, RemoteTrackPublication, Track } from "twilio-video";

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  isLocal: boolean;
  disableAudio?: boolean;
  videoPriority?: Track.Priority | null;
}

export default function Publication({ publication, isLocal, disableAudio, videoPriority }: PublicationProps) {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case "video":
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes("camera") && isLocal}
        />
      );
    case "audio":
      return disableAudio ? null : <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
}
