import * as hoverActions from "./hover.actions";
import { Map, fromJS } from "immutable";
import { Record } from "immutable";

export const HoverStateRecord = Record({
  importing: false,
  importLog: "",
  authorizing: false,
  authorized: false,
  pages: Map<number, hoverActions.Page>(),
  page: 1,
  search: "",
  currentJob: null,
  errors: [],
});

export const initialState = HoverStateRecord();
export type HoverState = typeof initialState;

export const reducer = (state = initialState, action: hoverActions.Actions): HoverState => {
  switch (action.type) {
    case hoverActions.RECEIVE_PAGE:
      return state
        .setIn(["pages", action.payload.page], action.payload)
        .setIn(["pages", action.payload.page, "loading"], false);
    case hoverActions.FETCH_PAGE:
      if (state.getIn(["pages", action.payload.page])) {
        return state
          .setIn(["pages", action.payload.page, "loading"], true)
          .setIn(["pages", action.payload.page, "jobs"], [])
          .set("page", action.payload.page)
          .set("search", action.payload.search);
      }
      return state.setIn(["pages", action.payload.page], fromJS({ loading: true })).set("page", action.payload.page);
    case hoverActions.RESET_PAGES:
      return state.set("pages", Map());
    case hoverActions.SET_AUTHORIZING:
      return state.set("authorizing", action.payload);
    case hoverActions.SET_AUTHORIZED:
      return state.set("authorized", action.payload);
    case hoverActions.SET_ERRORS:
      return state.set("errors", action.payload);
    case hoverActions.SET_IMPORTING:
      return state.set("importing", action.payload);
    case hoverActions.SET_IMPORT_LOG:
      return state.set("importLog", action.payload);
    case hoverActions.SET_CURRENT_JOB:
      return state.set("currentJob", action.payload);
    default:
      return state;
  }
};
