import { RootState } from "app2/src/reducers";
import * as React from "react";
import { useContext } from "react";
import { Col, Image, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector } from "react-redux";
import { document } from "app2/src/selectors/document.selectors";
import DateTime from "app2/src/components/Common/DateTime";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { Context } from "app2/src/components/SignatureRequest/Context";
import DownloadDocumentButton from "../../Document/DownloadDocumentButton";
import * as FontAwesome from "react-fontawesome";

export interface IDocumentProps {
  documentId: number;
  dragHandle?: any;
  mode: "adding" | "removing";
}

export const Document: React.FunctionComponent<IDocumentProps> = ({ documentId, dragHandle, mode }) => {
  // hooks
  const { requestName, documentIds, dispatch: contextDispatch } = useContext(Context);

  // selectors
  const documentRecord = useSelector((state: RootState) => document(state, { documentId }));

  // methods
  const checkBoxOnChange = (event) => {
    if (event.target.checked) {
      if (requestName === "") {
        contextDispatch("setRequestName", { requestName: documentRecord.get("name", "").split(".")[0] });
      }
      contextDispatch("addDocumentId", { id: documentRecord.id });
    } else {
      removeDocument();
    }
  };

  const removeDocument = () => {
    contextDispatch("removeDocumentId", { id: documentRecord.id });
  };

  if (_.isNullOrUndefined(documentRecord)) {
    return (
      <div className="standard-rl-margin">
        <Row className="table-row">
          <Col>{documentId} - not loaded</Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      {mode === "removing" ? (
        <Row className="table-row">
          <Col sm={2}>
            <Image {...dragHandle} src="/assets/images/icons/ic_sort.ea6f8933.png" title="Sort Document" className="mr-3" />
          </Col>
          <Col sm={8}>
            <OverlayTrigger
              overlay={
                <Tooltip id={`selected_document_${documentRecord.id.toString()}`}>{documentRecord.name}</Tooltip>
              }>
              <span className="ellipsis">{documentRecord.name}</span>
            </OverlayTrigger>
          </Col>
          <Col sm={2}>
            <FontAwesome
              name="trash"
              className="rsf-base-66 rsf-base-link"
              title="Remove Document"
              onClick={removeDocument}
            />
          </Col>
        </Row>
      ) : (
        <Row className="table-row">
          <Col sm={1}>
            <IncludeCheckBox
              name="selected"
              checked={documentIds.includes(documentRecord.id)}
              onChange={checkBoxOnChange}
            />
          </Col>
          <Col lg={8} md={7}>
            <OverlayTrigger
              overlay={
                <Tooltip id={`selected_document_${documentRecord.id.toString()}`}>{documentRecord.name}</Tooltip>
              }>
              <span className="ellipsis">{documentRecord.name}</span>
            </OverlayTrigger>
          </Col>
          <Col lg={2} md={3}>
            <DateTime date={documentRecord.created_at} variant="vertical" />
          </Col>
          <Col sm={1}>
            <DownloadDocumentButton documentId={documentRecord.id} />
          </Col>
        </Row>
      )}
    </>
  );
};
