import { IPaymentTermResource, IPaymentTerm, IPaymentTermItem, PaymentTermItem } from "app/src/Models/PaymentTerm";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";

export class OrgPaymentTermEditCtrl {
  public newItem: any = {};

  public static $inject = ["$uibModalInstance", "PaymentTerm", "OrgFetcher", "paymentTerm", "props", "Flash"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public PaymentTerm: IPaymentTermResource,
    public OrgFetcher: IOrgFetcherService,
    public paymentTerm: IPaymentTerm,
    public props: { paymentTerms: IPaymentTerm[] },
    private Flash: IFlash,
  ) {}

  public delete(pti: IPaymentTermItem) {
    this.paymentTerm.breakdown.items = _.select(this.paymentTerm.breakdown.items, (existing: IPaymentTermItem) => {
      return existing.uuid !== pti.uuid;
    });
  }

  public add() {
    this.paymentTerm.breakdown.items.push(new PaymentTermItem(this.newItem));

    this.newItem = {};
  }

  public save() {
    this.paymentTerm.$saveOrCreate().then(
      (resp) => {
        const paymentTerm: IPaymentTerm = this.PaymentTerm.fromJSON(resp);
        let spliceIndex = -1;
        const foundUser = _.find(this.props.paymentTerms, (pt, index) => {
          spliceIndex = index;
          return pt.id === paymentTerm.id;
        });

        if (foundUser) {
          this.props.paymentTerms.splice(spliceIndex, 1, paymentTerm);
        } else {
          this.props.paymentTerms.push(paymentTerm);
        }

        this.$uibModalInstance.close("manual");
      },
      (error) => {
        this.Flash.addMessage(FlashLevels.danger, error.data.errors);
      },
    );
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }
}
