import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { Button } from "react-bootstrap";
import { RootDispatchType } from "app2/src/store";
import track, { TrackingProp } from "react-tracking";
import { RootState } from "app2/src/reducers";
import { presentation } from "app2/src/selectors/presentation.selectors";
import * as presentationActions from "app2/src/reducers/presentation.actions";

const mapStateToProps = (state: RootState, ownProps: ShowPresentationProps) => {
  return {
    presentation: presentation(state, { presentationId: ownProps.presentationId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    setCurrentPresentationId: () => dispatch(presentationActions.Actions.setCurrentPresentationId(null)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ShowPresentationProps {
  presentationId: number;
  tracking?: TrackingProp;
}

interface ShowPresentationState {
  fullscreen: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ShowPresentationProps;

@track((props) => {
  return {
    category: "Presentation",
    component: "ShowPresentation",
    presentation: props.presentationId,
  };
})
class ShowPresentation extends React.Component<Props, ShowPresentationState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fullscreen: false,
    };

    this.openFullScreen = this.openFullScreen.bind(this);
    this.closeFullScreen = this.closeFullScreen.bind(this);
    this.close = this.close.bind(this);
    this.focusIframe = this.focusIframe.bind(this);
    this.slideEventListener = this.slideEventListener.bind(this);
  }

  @track(() => ({
    action: "Fullscreen",
  }))
  public openFullScreen(): void {
    this.setState({ fullscreen: true });
    this.focusIframe();
  }

  @track(() => ({
    action: "Exit Fullscreen",
  }))
  public closeFullScreen(): void {
    this.setState({ fullscreen: false });
    this.focusIframe();
  }

  public focusIframe() {
    const element = document.getElementsByName("iframe")[0] as HTMLIFrameElement;
    if (element) {
      element.focus();
    }
  }

  public componentDidMount() {
    window.addEventListener("message", this.slideEventListener);
  }

  public componentWillUnmount() {
    this.props.setCurrentPresentationId();
    window.removeEventListener("message", this.slideEventListener);
  }

  @track(() => ({
    action: "Close",
  }))
  public close() {
    this.props.setCurrentPresentationId();
  }

  public slideEventListener(event: any): void {
    let data: any;

    if (_.isString(event.data)) {
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        data = {};
      }
    } else {
      data = event.data;
    }
    if (data.namespace === "reveal" && data.eventName === "slidechanged") {
      this.props.tracking.trackEvent({
        action: "Slide Changed",
        page: data.state.indexh,
        level: data.state.indexv,
      });
    }
  }

  public render() {
    const { fullscreen } = this.state;
    const { presentation } = this.props;

    return (
      <>
        <div className="pull-right">
          {fullscreen ? (
            <Button variant="link" className="fullscreen" onClick={this.closeFullScreen}>
              <i className="fa fa-close link"></i>&nbsp;Close
            </Button>
          ) : (
            <>
              <Button variant="link" onClick={this.openFullScreen}>
                <i className="fa fa-expand link"></i>&nbsp;Fullscreen
              </Button>
              <Button variant="link" onClick={this.close}>
                <i className="fa fa-close link"></i>&nbsp;Close
              </Button>
            </>
          )}
        </div>
        <iframe
          name="iframe"
          data-testid="iframe"
          onLoad={this.focusIframe}
          className={fullscreen ? "fullscreen" : ""}
          src={presentation?.url}></iframe>
      </>
    );
  }
}

export default connector(ShowPresentation);
