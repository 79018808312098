import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { Row, Col } from "react-bootstrap";
import { RootDispatchType } from "app2/src/store";
import Folders from "app2/src/components/Folders";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import track, { TrackingProp } from "react-tracking";
import { RootState } from "app2/src/reducers";
import { currentJobId } from "app2/src/selectors/job.selectors";
import Presentations from "app2/src/components/JobTabs/PresentationTab/Presentations";
import ShowPresentation from "app2/src/components/JobTabs/PresentationTab/Customer/ShowPresentation";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { currentPresentationId } from "app2/src/selectors/presentation.selectors";
import { queryNumber } from "app2/src/selectors/router.selectors";
import { push, replace } from "connected-react-router/immutable";
import * as presentationActions from "app2/src/reducers/presentation.actions";
import * as folderActions from "app2/src/reducers/folder.actions";
import { QueryParamsRecord } from "app2/src/records/Page";
import { queryParams } from "app2/src/selectors/pagination.selectors";
import { JobTabTitle } from "app2/src/components/JobTabs/JobTabTitle";

const mapStateToProps = (state: RootState) => {
  return {
    jobId: currentJobId(state),
    orgId: currentOrgId(state),
    presentationId: currentPresentationId(state),
    queryFolderParentId: queryNumber(state, { queryKey: "folder_parent_id" }),
    folderParentId: queryParams(state, { modelName: "folder" }).getIn(["folder_id"]),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    listFolders: (folderParentId: number, jobId: number) =>
      dispatch(
        folderActions.AsyncActions.listFolders(folderParentId, { folderable_type: "Org", name: "Presentations" }, () =>
          replace(`/jobs/${jobId}/presentations`),
        ),
      ),
    listPresentations: (folderId: number) =>
      dispatch(
        presentationActions.AsyncActions.listPresentations(
          {
            kind: "org",
            folderId,
          },
          new QueryParamsRecord(),
        ),
      ),
    push: (path: string) => dispatch(push(path)),
    replace: (path: string) => dispatch(replace(path)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PresentationTabProps {
  tracking?: TrackingProp;
}

interface PresentationTabState {
  loading: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentationTabProps;

@track((props) => {
  return {
    category: "Presentations",
    component: "JobPresentationsTab",
    action: "show",
    job: props.jobId,
    org: props.orgId,
  };
})
class PresentationTab extends React.Component<Props, PresentationTabState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.navigate = this.navigate.bind(this);

    props.tracking.trackEvent({ action: "shown" });
  }

  public componentDidMount(): void {
    const { listFolders, jobId, queryFolderParentId } = this.props;
    listFolders(queryFolderParentId, jobId);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { listPresentations, folderParentId } = this.props;

    if (!_.isNullOrUndefined(folderParentId) && prevProps.folderParentId !== folderParentId) {
      listPresentations(folderParentId);
    }
  }

  public navigate(folderId: number): void {
    const { jobId, push, listFolders } = this.props;
    if (_.isNullOrUndefined(folderId)) {
      push(`/jobs/${jobId}/presentations/existing`);
    } else {
      push(`/jobs/${jobId}/presentations/existing?folder_parent_id=${folderId}`);
    }
    listFolders(folderId, jobId);
  }

  public render() {
    const { loading } = this.state;
    const { presentationId } = this.props;
    let childRender = null;

    if (_.isNullOrUndefined(presentationId)) {
      childRender = this.renderPresentations();
    } else {
      childRender = <ShowPresentation presentationId={presentationId} />;
    }

    return (
      <Row data-testid="presentationTab">
        <SpinnerComponent localProperty={loading} />
        <Col>{childRender}</Col>
      </Row>
    );
  }

  public renderPresentations() {
    const { orgId, folderParentId } = this.props;

    if (_.isNullOrUndefined(orgId)) {
      return null;
    }

    return (
      <>
        <JobTabTitle title="Presentations" />
        <Folders {...this.props} documentableType="org" navigate={this.navigate} fileType="presentations" readOnly />
        <hr />
        <Presentations folderParentId={folderParentId} />
      </>
    );
  }
}

export default connector(PresentationTab);
