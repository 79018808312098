import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentJobId, jobAssignedUserId } from "app2/src/selectors/job.selectors";
import { RootState } from "app2/src/reducers";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { AsyncActions } from "app2/src/reducers/access.actions";
import { accessConfig } from "app2/src/selectors/access.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { calculateEstimateCost } from "app2/src/selectors/estimate.selectors";

export interface MarginWithOverheadProps {
  estimateTotal: number;
  estimateId: number;
}

export const MarginWithOverhead: React.FC<MarginWithOverheadProps> = ({ estimateTotal, estimateId }) => {
  // Hooks
  const dispatch = useDispatch();

  const assignedUserId = useSelector((state: RootState) => jobAssignedUserId(state, { jobId: currentJobId(state) }));
  const overhead: number = useSelector((state: RootState) =>
    accessConfig(state, {
      userId: assignedUserId,
      orgId: currentOrgId(state),
      path: ["commission_calculator", "overhead"],
      notSet: 0,
    }),
  );
  const commissionRate: number = useSelector((state: RootState) =>
    accessConfig(state, {
      userId: assignedUserId,
      orgId: currentOrgId(state),
      path: ["commission_calculator", "commission_rate"],
      notSet: 0,
    }),
  );
  const cost = useSelector((state: RootState) => calculateEstimateCost(state, estimateId));

  // Lifecycle
  React.useEffect(() => {
    if (assignedUserId) {
      dispatch(AsyncActions.getAccess(assignedUserId));
    }
  }, [assignedUserId]);

  if (!commissionRate)
    return (
      <p className="text-center mt-4">Commission Rate is set to 0. Please verify the job's assigned user's settings</p>
    );

  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Container} fluid eventKey="0">
            <Row className="pb-0">
              <Col>Sale Price</Col>
              <Col sm={4}>{CurrencyFormat(estimateTotal)}</Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>This includes existing discounts</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Container} fluid eventKey="1">
            <Row className="pb-0">
              <Col>After Overhead</Col>
              <Col sm={4}>{CurrencyFormat(estimateTotal * (1 - overhead / 100))}</Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>This represents the Sale Price minus {overhead}%</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Container} fluid eventKey="2">
            <Row className="pb-0">
              <Col>After Cost</Col>
              <Col sm={4}>{CurrencyFormat(estimateTotal * (1 - overhead / 100) - cost)}</Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>Subtracting the total cost of {CurrencyFormat(cost)}</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Container} fluid eventKey="3">
            <Row className="pb-0">
              <Col>Estimated Commission</Col>
              <Col sm={4} className="font-weight-bolder">
                {CurrencyFormat((estimateTotal * (1 - overhead / 100) - cost) * (commissionRate / 100))} (
                {commissionRate.toFixed(2)}%)
              </Col>
            </Row>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>This represents {commissionRate.toFixed(2)}% of the Sale Price after Overhead and Cost</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
