import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ButtonBar } from "app2/src/components/JobTabs/MeasurementTab/ButtonBar";
import { useDispatch, useSelector } from "react-redux";
import * as measurementActions from "app2/src/reducers/measurement.actions";
import { currentMeasurement, measurementEditing } from "app2/src/selectors/measurement.selectors";
import { RootState } from "app2/src/reducers";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { Exteriors } from "app2/src/components/JobTabs/MeasurementTab/Exteriors";
import { Interiors } from "app2/src/components/JobTabs/MeasurementTab/Interiors";
import { useTracking } from "react-tracking";
import { Actions } from "app2/src/reducers/measurement.actions";
import { Actions as RoomActions } from "app2/src/reducers/room.actions";
import { Actions as DoorActions } from "app2/src/reducers/door.actions";
import { Actions as WindowActions } from "app2/src/reducers/window.actions";
import { measurementChanged } from "app2/src/selectors/measurementCommon.selectors";
import { doorsChanged } from "app2/src/selectors/door.selectors";
import { windowsChanged } from "app2/src/selectors/window.selectors";
import { ReactRouterDirtyWatcher } from "app2/src/components/Common/ReactRouterDirtyWatcher";
import {
  addMeasurementFilter,
  AddMeasurementModal,
} from "app2/src/components/JobTabs/MeasurementTab/AddMeasurementModal";
import { roomsChanged } from "app2/src/selectors/room.selectors";
import { List } from "immutable";
import { currentOrgId, preferencesConfig } from "app2/src/selectors/org.selectors";
import { exteriorMeasurements } from "app2/src/records/Measurement";

export const MeasurementTab: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { Track } = useTracking({ category: "Measurements" });

  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const changed = useSelector((state: RootState) => measurementChanged(state, {}));
  const windowChanged = useSelector((state: RootState) => windowsChanged(state, {}));
  const doorChanged = useSelector((state: RootState) => doorsChanged(state, {}));
  const roomChanged = useSelector((state: RootState) => roomsChanged(state, {}));
  const permissions = useSelector((state: RootState) =>
    preferencesConfig(state, { orgId: currentOrgId(state), path: ["measurement", "job_tab"], notSet: List() }),
  );
  const validExteriorTabs = exteriorMeasurements.filter((tab) => {
    return permissions.includes(tab);
  });
  let redirectTab = "exterior";
  if (validExteriorTabs.size === 0 && permissions.includes("rooms")) {
    redirectTab = "interior";
  }
  const enabledMeasurements = React.useMemo(() => addMeasurementFilter(permissions), [permissions]);

  const editing = useSelector(measurementEditing);

  // Methods
  const reset = () => {
    dispatch(Actions.resetMeasurement());
    dispatch(RoomActions.resetRooms(measurement?.get("room_ids") || List()));
    dispatch(DoorActions.resetDoors(measurement?.get("door_ids") || List()));
    dispatch(WindowActions.resetWindows(measurement?.get("window_ids") || List()));
  };
  const check = () => {
    return changed || windowChanged || doorChanged || roomChanged;
  };

  // Lifecycle
  React.useEffect(() => {
    dispatch(measurementActions.AsyncActions.loadMeasurement(["elevations"]));
  }, []);

  return (
    <div className="measurements-tab">
      <Track>
        <SpinnerComponent localProperty={measurement?.loading} />
        {editing ? (
          <ReactRouterDirtyWatcher check={check} reset={reset} validPathTest={/measurements\/exterior|interior/} />
        ) : null}
        <div className={editing ? "edit-mode bg-primary card mb-1" : "mb-3"}>
          <div className={editing ? "card-body" : ""}>
            <Row className="title-row">
              <Col md={editing ? 8 : 5}>
                <h1 className="tab-title mb-0">Measurements{editing ? " - Editing Mode" : ""}</h1>
                <p className="tab-subtitle mb-0">You can {editing ? "edit" : "add or import"} measurements here.</p>
              </Col>
              <Col md={editing ? 4 : 7}>
                <ButtonBar changed={check()} reset={reset} />
              </Col>
            </Row>
          </div>
        </div>

        <Switch>
          {validExteriorTabs.size > 0 && <Route path={`${match.url}/exterior`} component={Exteriors} />}
          {permissions.includes("rooms") && <Route path={`${match.url}/interior`} component={Interiors} />}
          <Route path={`${match.url}`} render={() => <Redirect to={`${match.url}/${redirectTab}`} />} />
        </Switch>
        {enabledMeasurements.size > 0 && <AddMeasurementModal />}
      </Track>
    </div>
  );
};
