import * as React from "react";
import { Col, Row, Form, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { getContractName, OrgRecord } from "app2/src/records/OrgRecord";
import { FloatButton } from "app2/src/components/Common/FloatButton";
import { useDirtyWatcher } from "app2/src/hooks/useDirtyWatcher";
import { IDirtyWatcher } from "app/src/Common/DirtyWatcher";
import { setupForm, saveForm } from "./NotificationForm.service";
import { INotificationFormData } from "app2/src/components/OrgNotifications/NotificationForm.service";
import { emailListRegex } from "app2/src/helpers/InputValidator";

export interface NotificationFormProps {
  org: OrgRecord;
  $scope: ng.IScope;
  DirtyWatcher: IDirtyWatcher;
}

export const NotificationForm = (props: NotificationFormProps) => {
  const { org, $scope, DirtyWatcher } = props;
  const { preferences } = org;
  const { register, handleSubmit, watch, formState, reset, errors } = useForm({
    defaultValues: setupForm(preferences),
  });
  const { dirty } = formState;

  useDirtyWatcher($scope, DirtyWatcher, dirty, reset);

  const onSubmit = (data: INotificationFormData) => {
    saveForm(org, data);
  };

  return (
    <form className="notificationForm" onSubmit={handleSubmit(onSubmit)}>
      <FloatButton variant="save" buttonName="Save" disabled={!dirty} submit={handleSubmit(onSubmit)} />
      <Row>
        <Col>
          <h1 className="admin-title">Notifications</h1>
        </Col>
      </Row>
      <div className="form-section">
        <div className="form-section-heading">
          <h3>Signed Documents</h3>
        </div>
        <div className="form-section-content">
          <Table striped>
            <thead>
              <tr>
                <th>Notification</th>
                <th>Preference</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td title="This comma separated list of emails will receive a signed document once all parties.">
                  Fully Executed {getContractName(org)} Email List
                </td>
                <td>
                  <input
                    name="signedNotification"
                    className="form-control"
                    ref={register({
                      pattern: emailListRegex,
                    })}
                  />
                  <div className="has-error">
                    {errors.signedNotification && "Improperly formatted emails '_@__.__' (comma separated)"}
                  </div>
                </td>
              </tr>
              <tr>
                <td title="This comma separated list of emails will receive a signed document once all parties.">
                  Fully Executed Signable Documents Email List
                </td>
                <td>
                  <input
                    aria-label="signable-notification"
                    name="signableNotification"
                    className="form-control"
                    ref={register({
                      pattern: emailListRegex,
                    })}
                  />
                  <div className="has-error">
                    {errors.signableNotification && "Improperly formatted emails '_@__.__' (comma separated)"}
                  </div>
                </td>
              </tr>
              <tr>
                <td title="The customer will receive a signed document once all parties sign.">Email Customer</td>
                <td>
                  <Form.Check name="customerDocumentSigned" type="checkbox" ref={register} />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </form>
  );
};
