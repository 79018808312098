import * as React from "react";
import { List } from "immutable";
import { Table } from "react-bootstrap";
import { IPretty } from "app/src/Common/PrettyNameService";
import { Droppable, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd";
import { ListManagerOption } from "app2/src/components/Common/ListManager/ListManager";
import { StoreRegistry } from "app2/src/storeRegistry";

interface DragTableProps {
  name: string;
  options: List<ListManagerOption>;
  id: string;
  RenderRow: new (props: DraggableRowProps) => React.Component<DraggableRowProps>;
  addOption(option: ListManagerOption): void;
  removeOption(option: ListManagerOption): void;
}

export interface DraggableRowProps extends DragTableProps {
  option: ListManagerOption;
  index: number;
}

interface DragTableState {}

export class DragTable extends React.Component<DragTableProps, DragTableState> {
  public Pretty: IPretty;

  constructor(props: DragTableProps) {
    super(props);

    this.Pretty = StoreRegistry.get("Pretty");
  }

  public render() {
    const { options, id, name, RenderRow } = this.props;

    return (
      <React.Fragment>
        <h4>
          {id === "list" ? "Enabled" : "Available"} {name}
        </h4>
        <Table striped className="drag-table">
          <thead>
            <tr>
              <th>{name}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <Droppable droppableId={id}>
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
              return (
                <tbody key={id + name} ref={provided.innerRef}>
                  {options.toArray().map((option, idx) => {
                    return <RenderRow key={idx} option={option} index={idx} {...this.props} />;
                  })}
                  {provided.placeholder}
                  {options.count() < 1 ? (
                    <tr>
                      <td>
                        Drag Here to&nbsp;
                        {id === "list" ? "Enable" : "Disable"} {name}
                      </td>
                      <td></td>
                    </tr>
                  ) : null}
                </tbody>
              );
            }}
          </Droppable>
        </Table>
      </React.Fragment>
    );
  }
}
