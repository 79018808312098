import { combineReducers } from "./utilities/combine.reducers";
import { Record, Map } from "immutable";
import { Reducer } from "redux";
import { reducer as authReducer, initialState as AuthInitialState } from "./auth.reducer";
import { Actions as authActions } from "./auth.actions";
import { reducer as paginationReducer, initialState as PaginationInitialState } from "./pagination.reducer";
import { Actions as paginationActions } from "./pagination.actions";
import { reducer as estimateReducer, initialState as EstimateInitialState } from "./estimate.reducer";
import { reducer as estimateGroupReducer, initialState as EstimateGroupInitialState } from "./estimateGroup.reducer";
import { Actions as estimateGroupActions } from "./estimateGroup.actions";
import {
  reducer as estimateLineItemReducer,
  initialState as EstimateLineItemInitialState,
} from "./estimateLineItem.reducer";
import { Actions as estimateLineItemActions } from "./estimateLineItem.actions";
import {
  reducer as estimateLineItemOptionReducer,
  initialState as EstimateLineItemOptionInitialState,
} from "./estimateLineItemOption.reducer";
import { Actions as estimateLineItemOptionActions } from "./estimateLineItemOption.actions";
import {
  reducer as openingEstimationReducer,
  initialState as OpeningEstimationInitialState,
} from "./openingEstimation.reducer";
import { Actions as openingEstimationActions } from "./openingEstimation.actions";
import { reducer as imageReducer, initialState as ImageInitialState } from "./image.reducer";
import { Actions as imageActions } from "./image.actions";
import { Actions as estimateActions } from "./estimate.actions";
import { reducer as usersReducer, initialState as UsersInitialState } from "./user.reducer";
import { reducer as jobsReducer, initialState as JobsInitialState } from "./job.reducer";
import { Actions as jobActions } from "./job.actions";
import { reducer as orgsReducer, initialState as OrgsInitialState } from "./org.reducer";
import { Actions as orgActions } from "./org.actions";
import {
  initialState as ProductProcessessInitialState,
  reducer as productProcessReducer,
} from "./productProcess.reducer";
import {
  initialState as PriceListProcessesInitialState,
  reducer as priceListProcessReducer,
} from "./priceListProcess.reducer";
import { Actions as productProcessActions } from "./productProcess.actions";
import { Actions as priceListProcessActions } from "./priceListProcess.actions";
import { Actions as userActions } from "./user.actions";
import { reducer as integrationsReducer, IntegrationsInitialState, IntegrationsActions } from "./integrations.reducer";
import { reducer as analyticsReducer, initialState as AnalyticsInitialState } from "./analytics.reducer";
import { Actions as analyticsActions } from "./analytics.actions";
import { LeadsInitialState, reducer as leadsReducer } from "./leads.reducer";
import { Actions as eventActions } from "./event.actions";
import { reducer as eventReducer, initialState as EventInitialState } from "./event.reducer";
import { Actions as leadsActions } from "./leads.actions";
import { initialState as DiscountsInitialState, reducer as discountsReducer } from "./discount.reducer";
import { Actions as discountsActions } from "./discount.actions";
import {
  initialState as PaymentTermTemplatesInitialState,
  reducer as paymentTermTemplatesReducer,
} from "./org/paymentTermTemplate.reducer";
import { Actions as paymentTermTemplateActions } from "./org/paymentTermTemplate.actions";
import { Actions as financeActions } from "./org/financeOption.actions";
import { Actions as toolActions } from "./org/tool.actions";
import { initialState as ToolInitialState, reducer as toolReducer } from "./org/tool.reducer";
import { initialState as PaymentInitialState, reducer as paymentReducer } from "./payment.reducer";
import { Actions as paymentActions } from "./payment.actions";
import { reducer as taskReducer, initialState as TaskInitialState } from "./task.reducer";
import { Actions as taskActions } from "./task.actions";
import { reducer as jobAttributeReducer, initialState as JobAttributeInitialState } from "./jobAttribute.reducer";
import { Actions as jobAttributeActions } from "./jobAttribute.actions";
import { reducer as presentationReducer, initialState as PresentationInitialState } from "./presentation.reducer";
import { Actions as presentationActions } from "./presentation.actions";
import { reducer as documentReducer, initialState as DocumentInitialState } from "./document.reducer";
import { Actions as documentActions } from "./document.actions";
import { reducer as reportReducer, initialState as ReportInitialState } from "./report.reducer";
import { Actions as reportActions } from "./report.actions";
import { reducer as componentsReducer, ComponentsInitialState, ComponentsActions } from "./components/index";
import {
  reducer as presentationTemplateReducer,
  initialState as PresentationTemplateInitialState,
} from "./presentationTemplate.reducer";
import { Actions as presentationTemplateActions } from "./presentationTemplate.actions";
import { reducer as screenSharesReducer, initialState as ScreenShareInitialState } from "./screenShare.reducer";
import { Actions as screenShareActions } from "./screenShare.actions";
import {
  reducer as estimateComparisonReducer,
  initialState as EstimateComparisonInitialState,
} from "./estimateComparison.reducer";
import { Actions as estimateComparisonActions } from "./estimateComparison.actions";
import { billingReducer, BillingActions, initialState as InitBillingRootState } from "./billing";
import { ThunkAction } from "redux-thunk";
import { reducer as tokenReducer, initialState as TokenInitialState } from "./token.reducer";
import { Actions as tokenActions } from "./token.actions";
import { reducer as validationReducer, initialState as ValidationInitialState } from "./validation.reducer";
import { Actions as validationActions } from "./validation.actions";
import {
  reducer as estimateFinanceOptionReducer,
  initialState as EstimateFinanceOptionsInitialState,
} from "./estimateFinanceOption.reducer";
import { Actions as estimateFinanceOptionActions } from "./estimateFinanceOption.actions";
import {
  reducer as activatedPriceListReducer,
  initialState as ActivatedPriceListsInitialState,
} from "./activatedPriceList.reducer";
import { Actions as activatedPriceListActions } from "./activatedPriceList.actions";
import { reducer as priceListReducer, initialState as PriceListsInitialState } from "./priceList.reducer";
import { Actions as priceListActions } from "./priceList.actions";
import { reducer as productReducer, initialState as ProductsInitialState } from "./product.reducer";
import { Actions as productActions } from "./product.actions";
import {
  reducer as productOptionGroupReducer,
  initialState as ProductOptionGroupsInitialState,
} from "./productOptionGroup.reducer";
import { Actions as productOptionGroupActions } from "./productOptionGroup.actions";
import { reducer as productOptionReducer, initialState as ProductOptionsInitialState } from "./productOption.reducer";
import { Actions as productOptionActions } from "./productOption.actions";
import { reducer as measurementReducer, initialState as MeasurementInitialState } from "./measurement.reducer";
import { Actions as measurementActions } from "./measurement.actions";
import { reducer as roofFaceReducer, initialState as RoofFaceInitialState } from "./roofFace.reducer";
import { Actions as roofFaceActions } from "./roofFace.actions";
import { reducer as wallFacadeReducer, initialState as WallFacadeInitialState } from "./wallFacade.reducer";
import { Actions as wallFacadeActions } from "./wallFacade.actions";
import { reducer as windowReducer, initialState as WindowInitialState } from "./window.reducer";
import { Actions as windowActions } from "./window.actions";
import { reducer as doorReducer, initialState as DoorInitialState } from "./door.reducer";
import { Actions as doorActions } from "./door.actions";
import { reducer as roomReducer, initialState as RoomInitialState } from "./room.reducer";
import { Actions as roomActions } from "./room.actions";
import { reducer as elevationReducer, initialState as ElevationInitialState } from "./elevation.reducer";
import { Actions as elevationActions } from "./elevation.actions";
import { reducer as deckReducer, initialState as DeckInitialState } from "./deck.reducer";
import { Actions as deckActions } from "./deck.actions";
import { reducer as poolReducer, initialState as PoolInitialState } from "./pool.reducer";
import { Actions as poolActions } from "./pool.actions";
import { reducer as accessReducer, initialState as AccessInitialState } from "./access.reducer";
import { Actions as accessActions } from "./access.actions";
import { reducer as routerReducer, initialState as RouterInitialState } from "./router.reducer";
import { Actions as routerActions } from "./router.actions";
import { reducer as folderReducer, initialState as FolderInitialState } from "./folder.reducer";
import { Actions as folderActions } from "./folder.actions";
import {
  reducer as fencingReducer,
  initialState as MeasurementsFencingInitialState,
} from "./measurements/fencing.reducer";
import { Actions as fencingActions } from "./measurements/fencing.actions";
import { reducer as fenceReducer, initialState as MeasurementsFenceInitialState } from "./measurements/fence.reducer";
import { Actions as fenceActions } from "./measurements/fence.actions";
import {
  reducer as segmentReducer,
  initialState as MeasurementsSegmentInitialState,
} from "./measurements/segment.reducer";
import { Actions as segmentActions } from "./measurements/segment.actions";
import {
  reducer as estimateTemplateReducer,
  initialState as EstimateTemplateInitialState,
} from "./estimateTemplate.reducer";
import { Actions as estimateTemplateActions } from "./estimateTemplate.actions";
import { History } from "history";
import {
  connectRouter,
  routerActions as routerActionsConnected,
  CallHistoryMethodAction,
} from "connected-react-router/immutable";
import { ActionsUnion } from "app2/src/reducers/Utils";
import { reducer as noteReducer, initialState as NoteInitialState } from "./note.reducer";
import { Actions as noteActions } from "./note.actions";
import { reducer as crmUserReducer, initialState as CrmUserInitialState } from "./crmUser.reducer";
import { Actions as crmUserActions } from "./crmUser.actions";
import { reducer as eventResultReducer, initialState as EventResultInitialState } from "./eventResult.reducer";
import { Actions as eventResultActions } from "./eventResult.actions";
import {
  reducer as eventResultReasonReducer,
  initialState as EventResultReasonInitialState,
} from "./eventResultReason.reducer";
import { Actions as eventResultReasonActions } from "./eventResultReason.actions";
import {
  reducer as financeApplicationReducer,
  initialState as FinanceApplicationInitialState,
} from "./financeApplication.reducer";
import { Actions as financeApplicationActions } from "./financeApplication.actions";
import { reducer as skuReducer, initialState as SkuInitialState } from "./sku.reducer";
import { Actions as skuActions } from "./sku.actions";
import { reducer as paymentRequestReducer, initialState as PaymentRequestInitialState } from "./paymentRequest.reducer";
import { Actions as paymentRequestActions } from "./paymentRequest.actions";
import { Actions as signedDocumentActions } from "./signedDocument.actions";
import { reducer as signedDocumentReducer, initialState as SignedDocumentInitialState } from "./signedDocument.reducer";
import { reducer as webhookReducer, initialState as WebhookInitialState } from "./webhook.reducer";
import { Actions as webhookActions } from "./webhook.actions";
import { Nullable } from "app2/src/records";

export const ConnectedRouterInitialState = Map({ location: Map({ pathname: "/", query: Map() }), action: "test" });

export const InitRootState = Record({
  auth: AuthInitialState,
  users: UsersInitialState,
  jobs: JobsInitialState,
  integrations: IntegrationsInitialState,
  analytics: AnalyticsInitialState,
  leads: LeadsInitialState,
  discounts: DiscountsInitialState,
  jobAttributes: JobAttributeInitialState,
  paymentTermTemplates: PaymentTermTemplatesInitialState,
  payments: PaymentInitialState,
  estimates: EstimateInitialState,
  estimateGroups: EstimateGroupInitialState,
  estimateLineItems: EstimateLineItemInitialState,
  estimateLineItemOptions: EstimateLineItemOptionInitialState,
  orgs: OrgsInitialState,
  priceListProcesses: PriceListProcessesInitialState,
  productProcesses: ProductProcessessInitialState,
  events: EventInitialState,
  presentations: PresentationInitialState,
  presentationTemplates: PresentationTemplateInitialState,
  tasks: TaskInitialState,
  reports: ReportInitialState,
  documents: DocumentInitialState,
  tools: ToolInitialState,
  screenShares: ScreenShareInitialState,
  components: ComponentsInitialState,
  estimateComparisons: EstimateComparisonInitialState,
  pagination: PaginationInitialState,
  billing: InitBillingRootState,
  tokens: TokenInitialState,
  images: ImageInitialState,
  openingEstimations: OpeningEstimationInitialState,
  validations: ValidationInitialState,
  estimateFinanceOptions: EstimateFinanceOptionsInitialState,
  activatedPriceLists: ActivatedPriceListsInitialState,
  priceLists: PriceListsInitialState,
  products: ProductsInitialState,
  productOptionGroups: ProductOptionGroupsInitialState,
  productOptions: ProductOptionsInitialState,
  measurements: MeasurementInitialState,
  roofFaces: RoofFaceInitialState,
  wallFacades: WallFacadeInitialState,
  windows: WindowInitialState,
  doors: DoorInitialState,
  rooms: RoomInitialState,
  elevations: ElevationInitialState,
  decks: DeckInitialState,
  pools: PoolInitialState,
  accesses: AccessInitialState,
  routerPush: RouterInitialState,
  folders: FolderInitialState,
  measurementsFencings: MeasurementsFencingInitialState,
  measurementsFences: MeasurementsFenceInitialState,
  measurementsSegments: MeasurementsSegmentInitialState,
  estimateTemplates: EstimateTemplateInitialState,
  notes: NoteInitialState,
  crmUsers: CrmUserInitialState,
  eventResults: EventResultInitialState,
  eventResultReasons: EventResultReasonInitialState,
  financeApplications: FinanceApplicationInitialState,
  skus: SkuInitialState,
  paymentRequests: PaymentRequestInitialState,
  signedDocuments: SignedDocumentInitialState,
  webhooks: WebhookInitialState,
  router: undefined as Nullable<typeof ConnectedRouterInitialState>,
})({ router: ConnectedRouterInitialState });

export type RootState = typeof InitRootState;

const createCombineRootReducer = (history: History<any>) =>
  combineReducers<RootState, RootActions>({
    //@ts-ignore
    auth: authReducer,
    integrations: integrationsReducer,
    analytics: analyticsReducer,
    leads: leadsReducer,
    discounts: discountsReducer,
    paymentTermTemplates: paymentTermTemplatesReducer,
    payments: paymentReducer,
    priceListProcesses: priceListProcessReducer,
    productProcesses: productProcessReducer,
    presentationTemplates: presentationTemplateReducer,
    tasks: taskReducer,
    tools: toolReducer,
    screenShares: screenSharesReducer,
    components: componentsReducer,
    tokens: tokenReducer,
    validations: validationReducer,
    router: connectRouter(history),
  });

export const createRootReducer = (history: History<any>) => {
  return ((state: RootState, action: RootActions) => {
    if (!state) {
      // LBC: If `router` is not set to undefined, connected-react-router won't initialize it correctly
      //      with the current information.  No immediate "errors", just bad data causing issues.
      state = InitRootState.set("router", undefined);
    }
    const localRootReducer = createCombineRootReducer(history);
    state = estimateComparisonReducer(state, action);
    state = estimateReducer(state, action);
    state = estimateGroupReducer(state, action);
    state = estimateLineItemReducer(state, action);
    state = estimateLineItemOptionReducer(state, action);
    state = openingEstimationReducer(state, action);
    state = billingReducer(state, action);
    state = imageReducer(state, action);
    state = documentReducer(state, action);
    state = estimateFinanceOptionReducer(state, action);
    state = activatedPriceListReducer(state, action);
    state = priceListReducer(state, action) as RootState;
    state = productReducer(state, action) as RootState;
    state = productOptionGroupReducer(state, action) as RootState;
    state = productOptionReducer(state, action) as RootState;
    state = jobsReducer(state, action);
    state = measurementReducer(state, action);
    state = roofFaceReducer(state, action);
    state = wallFacadeReducer(state, action);
    state = windowReducer(state, action);
    state = doorReducer(state, action);
    state = roomReducer(state, action);
    state = elevationReducer(state, action);
    state = deckReducer(state, action);
    state = poolReducer(state, action);
    state = usersReducer(state, action);
    state = orgsReducer(state, action);
    state = accessReducer(state, action);
    state = routerReducer(state, action);
    state = folderReducer(state, action);
    state = paginationReducer(state, action);
    state = fencingReducer(state, action);
    state = fenceReducer(state, action);
    state = segmentReducer(state, action);
    state = presentationReducer(state, action);
    state = estimateTemplateReducer(state, action);
    state = noteReducer(state, action);
    state = crmUserReducer(state, action);
    state = eventResultReducer(state, action);
    state = eventResultReasonReducer(state, action);
    state = financeApplicationReducer(state, action);
    state = skuReducer(state, action);
    state = paymentRequestReducer(state, action);
    state = eventReducer(state, action);
    state = localRootReducer(state, action);
    state = signedDocumentReducer(state, action);
    state = reportReducer(state, action);
    state = jobAttributeReducer(state, action);
    state = webhookReducer(state, action);

    return state;
  }) as Reducer<RootState, RootActions>;
};

export type RootActions =
  | userActions
  | orgActions
  | authActions
  | IntegrationsActions
  | analyticsActions
  | leadsActions
  | discountsActions
  | jobAttributeActions
  | paymentActions
  | estimateActions
  | estimateGroupActions
  | estimateLineItemActions
  | estimateLineItemOptionActions
  | openingEstimationActions
  | imageActions
  | productProcessActions
  | priceListProcessActions
  | taskActions
  | financeActions
  | eventActions
  | presentationActions
  | jobActions
  | reportActions
  | presentationTemplateActions
  | toolActions
  | paymentTermTemplateActions
  | ComponentsActions
  | documentActions
  | screenShareActions
  | estimateComparisonActions
  | paginationActions
  | tokenActions
  | validationActions
  | BillingActions
  | estimateFinanceOptionActions
  | activatedPriceListActions
  | priceListActions
  | productActions
  | productOptionGroupActions
  | productOptionActions
  | measurementActions
  | roofFaceActions
  | wallFacadeActions
  | windowActions
  | doorActions
  | roomActions
  | elevationActions
  | deckActions
  | poolActions
  | accessActions
  | routerActions
  | folderActions
  | fencingActions
  | fenceActions
  | segmentActions
  | estimateTemplateActions
  | noteActions
  | crmUserActions
  | eventResultActions
  | eventResultReasonActions
  | financeApplicationActions
  | skuActions
  | paymentRequestActions
  | ActionsUnion<typeof routerActionsConnected>
  | CallHistoryMethodAction
  | signedDocumentActions
  | webhookActions;

export type ThunkResult<T> = ThunkAction<T, RootState, undefined, RootActions>;
