import * as doorActions from "./door.actions";
import { Map, Record } from "immutable";
import { DoorRecord, fromJSON } from "../records/Door";
import { RootActions, RootState } from ".";
import { rootKey } from "app2/src/selectors/door.selectors";
import { calculateOpeningArea, calculateOpeningUI, defaultOpeningProps } from "app2/src/records/Opening";
import { parseUpdateForm, reset } from "app2/src/reducers/Reducer";

export const DoorStateRecord = Record({
  byId: Map<string, DoorRecord>(),
  lastSavedById: Map<string, DoorRecord>(),
  unsavedId: -1,
});

export const initialState = DoorStateRecord();

export type DoorState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(rootKey)) {
    state = state.set(rootKey, initialState);
  }

  let door: DoorRecord;
  switch (action.type) {
    case doorActions.RECEIVE_DOOR:
      door = fromJSON({ ...action.payload.door });

      return state
        .setIn([rootKey, "byId", door.id.toString()], door)
        .setIn([rootKey, "lastSavedById", door.id.toString()], door);

    case doorActions.EDIT_DOOR:
      door = fromJSON({ ...action.payload.door });

      return state.setIn([rootKey, "byId", door.id.toString()], door);

    case doorActions.RESET_DOORS:
      action.payload.doorIds.forEach((doorId) => {
        state = reset(state, rootKey, doorId.toString());
      });
      return state;

    case doorActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      const nameArray = name.split(".");
      const rootPathName = rootPath.concat(nameArray);
      state = state.setIn(rootPathName, parseUpdateForm(defaultOpeningProps, name, value));

      nameArray.pop();
      const width = state.getIn(rootPath.concat(nameArray.concat(["width"])));
      const height = state.getIn(rootPath.concat(nameArray.concat(["height"])));
      return state
        .setIn(rootPath.concat(nameArray.concat(["area"])), calculateOpeningArea(width, height))
        .setIn(rootPath.concat(nameArray.concat(["ui"])), calculateOpeningUI(width, height));

    default:
      return state;
  }
};
