import { FlashLevels, IFlash } from "../../Common/FlashService";
import { IImage, IImageResource } from "../../Models/Image";
import { IJob } from "../../Models/Job";
import { IBaseConfig } from "../../Common/IBaseConfig";

class ImageUploadController implements ng.IComponentController {
  public spinnerPromise: ng.IPromise<any>;
  public job: IJob;
  public analyticsCategory: string;
  public analyticsEvent: string;
  public classes: string;

  public static $inject = ["BaseConfig", "Flash", "Image", "$analytics", "Upload", "$q"];
  constructor(
    protected BaseConfig: IBaseConfig,
    protected Flash: IFlash,
    protected Image: IImageResource,
    protected $analytics: angulartics.IAnalyticsService,
    protected Upload: ng.angularFileUpload.IUploadService,
    protected $q: ng.IQService,
  ) {}

  public $onInit() {
    if (!this.analyticsCategory) {
      this.analyticsCategory = "Photos";
    }

    if (!this.analyticsEvent) {
      this.analyticsEvent = "add photo";
    }

    if (!this.classes) {
      this.classes = "btn btn-default";
    }
  }

  public upload(files: any, invalidFiles: any) {
    if (!files) {
      if (invalidFiles.length > 0) {
        this.trackEvent("invalid files", {
          category: this.analyticsCategory,
          files: _.map(invalidFiles, (file: File) => {
            return file.name;
          }),
        });
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " +
            _.map(invalidFiles, (file: File) => {
              return file.name;
            }).join(", ") +
            ".  Only image file types are allowed.",
        );
      }
      return;
    }

    let imageSuccess = 0;
    let promises = [];
    let sort_order = this.job.images.length;
    _.each(files, (file) => {
      promises = promises.concat(
        this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
          url: this.BaseConfig.BASE_URL + "/api/v1/jobs/" + this.job.id + "/images",
          data: { image: { file: file, sort_order: sort_order } },
        }).then((resp: any) => {
          const img: IImage = this.Image.fromJSON(resp.data.image);
          this.job.images.push(img);
          this.trackEvent("photo uploaded", {
            category: this.analyticsCategory,
            file: img.id,
          });
          imageSuccess += 1;
        }),
      );
      sort_order += 1;
    });
    this.spinnerPromise = this.$q.all(promises).finally(() => {
      if (imageSuccess === 0 && files.length === 0) {
        return;
      }
      this.job.images = _.sortBy(this.job.images, "sort_order");
      let flashLevel = FlashLevels.warning;
      if (imageSuccess === files.length) {
        flashLevel = FlashLevels.success;
      }
      this.Flash.addMessage(flashLevel, imageSuccess + "/" + files.length + " Images successfully uploaded");
    });
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        job: this.job.id,
        org: this.job.org_id,
      }),
    );
  }
}

export class ImageUploadComponent implements ng.IComponentOptions {
  public controller = ImageUploadController;
  public templateUrl = "src/Jobs/Components/image_upload.html";
  public bindings: any = {
    job: "<",
    analyticsCategory: "<",
    analyticsEvent: "<",
    classes: "<",
  };
}
