/* eslint-disable no-inner-declarations */
import { FlashLevels } from "app/src/Common/FlashLevels";
import {
  DocumentableType,
  DocumentableTypeConversion,
  IDocumentOptions,
  combineDocuments,
  create,
  destroy,
  directUpload,
  importMeasurement,
  list,
  load,
  update,
  updateOrCreate,
} from "app2/src/api/document.service";
import { DocumentRecord, IDocumentData, fromJSON } from "app2/src/records/Document";
import { IPageData, QueryParamsRecord } from "app2/src/records/Page";
import { handleErrors } from "app2/src/reducers/Utils";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as estimateLineItemActions from "app2/src/reducers/estimateLineItem.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as taskActions from "app2/src/reducers/task.actions";
import { document, documentsSelectedIds } from "app2/src/selectors/document.selectors";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { dirtyIds } from "app2/src/selectors/pagination.selectors";
import { query } from "app2/src/selectors/router.selectors";
import { RootDispatchType } from "app2/src/store";
import { List } from "immutable";
import { ThunkAction } from "redux-thunk";
import { downloadFile } from "../helpers/File";
import { ActionsUnion, createAction } from "./Utils";
import { RootState } from "./index";
import { ITaskContext, ITaskData } from "app2/src/records/Task";

// SINGLE
export const FETCH_DOCUMENT = "@documents/FETCH_DOCUMENT";
export const RECEIVE_DOCUMENT = "@documents/RECEIVE_DOCUMENT";
export const RESET_DOCUMENT = "@documents/RESET_DOCUMENT";
export const SET_DOCUMENT_LOADED = "@documents/SET_DOCUMENT_LOADED";
export const DESTROY_DOCUMENT = "@documents/DESTROY_DOCUMENT";
export const EDIT_DOCUMENT = "@documents/EDIT_DOCUMENT";
export const EDIT_BOOLEAN = "@documents/EDIT_BOOLEAN";
// MULTIPLE
export const FETCH_DOCUMENTS = "@documents/FETCH_DOCUMENTS";
export const RECEIVE_DOCUMENTS = "@documents/RECEIVE_DOCUMENTS";
export const RECEIVE_DOCUMENTS_BY_ID = "@documents/RECEIVE_DOCUMENTS_BY_ID";
export const SET_DOCUMENTS_LOADED = "@documents/SET_DOCUMENTS_LOADED";
export const BATCH_EDIT_BOOLEAN = "@documents/BATCH_EDIT_BOOLEAN";
export const BATCH_EDIT_FOLDER_ID = "@documents/BATCH_EDIT_FOLDER_ID";
export const BATCH_REMOVE_IDS = "@documents/BATCH_REMOVE_IDS";
// JOB
export const CREATE_JOB_DOCUMENT = "@documents/CREATE_JOB_DOCUMENT";
export const CREATE_JOB_ERRORS = "@documents/CREATE_JOB_ERRORS";
// ESTIMATE
export const DUPLICATE_LINE_ITEM_DOCUMENTS = "@documents/DUPLICATE_LINE_ITEM_DOCUMENTS";

export const Actions = {
  // SINGLE
  fetchDocument: (id: number) => createAction(FETCH_DOCUMENT, { id }),
  receiveDocument: (document: Partial<IDocumentData>) => createAction(RECEIVE_DOCUMENT, { document }),
  resetDocument: (id: number) => createAction(RESET_DOCUMENT, { id }),
  setDocumentLoaded: (id: number) => createAction(SET_DOCUMENT_LOADED, { id }),
  destroyDocument: (id: number) => createAction(DESTROY_DOCUMENT, { id }),
  editSortOrder: (id: number, sortOrder: number) =>
    createAction(EDIT_DOCUMENT, { id, name: "sort_order", value: sortOrder }),
  editBoolean: (id: number, name: string, value: boolean) => createAction(EDIT_BOOLEAN, { id, name, value }),
  editName: (id: number, name: string) => createAction(EDIT_DOCUMENT, { id, name: "name", value: name }),
  editImportType: (id: number, importType: string) =>
    createAction(EDIT_DOCUMENT, { id, name: "import_type", value: importType }),
  //  MULTIPLE
  fetchDocuments: (ids: List<number>) => createAction(FETCH_DOCUMENTS, { ids }),
  receiveDocuments: (documents: IDocumentData[]) => createAction(RECEIVE_DOCUMENTS, { documents }),
  receiveDocumentsById: (ids: List<number>, documents: IDocumentData[]) =>
    createAction(RECEIVE_DOCUMENTS_BY_ID, { ids, documents }),
  setDocumentsLoaded: (ids: List<number>) => createAction(SET_DOCUMENTS_LOADED, { ids }),
  batchEditBoolean: (documentIds: List<number>, name: string, value: boolean) =>
    createAction(BATCH_EDIT_BOOLEAN, { documentIds, name, value }),
  batchEditFolderId: (documentIds: List<number>, folderId: number) =>
    createAction(BATCH_EDIT_FOLDER_ID, { documentIds, folderId }),
  batchRemoveIds: (documentIds: List<number>) => createAction(BATCH_REMOVE_IDS, { documentIds }),
  // JOB
  createJobDocument: (jobId: number, document: DocumentRecord) =>
    createAction(CREATE_JOB_DOCUMENT, { jobId, document }),
  createJobDocumentErrors: (jobId: number, errors: string[]) => createAction(CREATE_JOB_ERRORS, { jobId, errors }),
  // ESTIMATE
  duplicateLineItemDocuments: (lineItemId: number, docIds: List<number>) =>
    createAction(DUPLICATE_LINE_ITEM_DOCUMENTS, { lineItemId, docIds }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  checkAndLoadDocument: (documentId: number, options: IDocumentOptions): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      if (document(getState(), { documentId })) {
        return;
      }
      dispatch(Actions.fetchDocument(documentId));
      try {
        const response = await load(documentId, options);
        dispatch(Actions.receiveDocument(response.document));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setDocumentLoaded(documentId));
      }
    };
  },
  listDocuments: (
    options: Partial<IDocumentOptions>,
    queryParams: QueryParamsRecord,
    modelName = "document",
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const mergedOptions = setOptionsId(options, getState());

      dispatch(paginationActions.Actions.fetchPage(modelName, queryParams));

      try {
        const response = await list(mergedOptions, queryParams);
        dispatch(Actions.receiveDocuments(response.documents));
        const pageData: Partial<IPageData> = {
          ids: List(response.documents.map((document: IDocumentData) => document.id)),
          pagination: response.meta.pagination,
        };
        dispatch(paginationActions.Actions.receivePage(modelName, queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response, dispatch);
        dispatch(paginationActions.Actions.receivePageError(modelName, queryParams, errors));
      }
    };
  },
  deleteDocument: (document: DocumentRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      await destroy(document);
      dispatch(Actions.destroyDocument(document.id));
    };
  },
  destroyDocument: (document: DocumentRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchDocument(document.id));

      try {
        await destroy(document);
        dispatch(Actions.destroyDocument(document.id));
        if (document.documentable_type === "Job") {
          dispatch(jobActions.Actions.decreaseCount("document", document.documentable_id));
        }
        if (document.documentable_type === "Org") {
          dispatch(orgActions.AsyncActions.removeFromDocumentOrder(document.id));
        }
        await dispatch(AsyncActions.checkPageEmpty(_.toUnderscore(document.documentable_type), document.folder_id));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Document successfully deleted"));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setDocumentLoaded(document.id));
      }
    };
  },
  updateOrCreateDocument: (document: DocumentRecord): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchDocument(document.id));

      try {
        const response = await updateOrCreate(document);
        dispatch(Actions.receiveDocument(response.document));
        if (document.id <= 0) {
          dispatch(AsyncActions.processDocument(response.document));
        }
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved document successfully"));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setDocumentLoaded(document.id));
      }
    };
  },
  batchCreateDocument: (files: any[], options: IDocumentOptions): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        options = setOptionsId(options, getState());
        options = setPresignedOptions(options, getState());
        const { documentableType, documentableId, presignedType, presignedId, display, folder_id, disableFlash } =
          options;
        const fileCount = files.length;
        const { urls } = await directUpload(fileCount, presignedType, presignedId);

        let docSuccess = 0;
        await Promise.all(
          urls.map(async (obj, index) => {
            try {
              const file = files[index];
              await fetch(obj.url, { body: file, method: "PUT", headers: { "Content-Type": file.type } });

              const record = fromJSON({
                uuid: obj.id,
                name: file.name,
                display: display || "do_not_display",
                documentable_id: documentableId,
                documentable_type: DocumentableTypeConversion[documentableType],
                folder_id: folder_id,
              });
              const response = await create(record);
              if (_.isArray(response.documents)) {
                const docs = response.documents;
                dispatch(Actions.receiveDocuments(docs));
                docs.forEach((doc) => {
                  dispatch(AsyncActions.processDocument(doc));
                });

                docSuccess += 1;
                return response;
              } else {
                dispatch(Actions.receiveDocument({ ...response.document, content_type: file.type }));
                dispatch(AsyncActions.processDocument(response.document));
                docSuccess += 1;
                return response;
              }
            } catch (response) {
              handleErrors(response, dispatch);
            }
          }),
        );

        let flashLevel = FlashLevels.warning;
        if (docSuccess === files.length) {
          flashLevel = FlashLevels.success;
        }

        if (!disableFlash) {
          dispatch(
            commonActions.Actions.flashAddAlert(
              flashLevel,
              docSuccess + "/" + files.length + " Documents successfully uploaded",
            ),
          );
        }
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  batchUpdateDocument: (documentIds: List<number>, reject = false): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const promises = [];
      dispatch(Actions.fetchDocuments(documentIds));

      documentIds.forEach((documentId) => {
        const updatedDocument = document(getState(), { documentId });
        promises.push(update(updatedDocument));
      });

      try {
        const responses = await Promise.all(promises);
        const documents = responses.map((response) => response.document);
        dispatch(Actions.receiveDocuments(documents));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved documents successfully"));
      } catch (response) {
        dispatch(Actions.setDocumentsLoaded(documentIds));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, ["Update failed, please try again."]));
        if (reject) {
          return Promise.reject();
        }
      }
    };
  },
  importMeasurementDocument: (document: DocumentRecord): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const response = await importMeasurement(document);
        dispatch(Actions.receiveDocument(response.document));
        const { task } = response.document;
        if (task) {
          dispatch(taskActions.Actions.receiveTask(task));
          await dispatch(taskActions.AsyncActions.pollTask(task));
        }
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
      }
    };
  },
  onSortEnd: (oldIndex: number, newIndex: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      await dispatch(paginationActions.AsyncActions.onSortEnd("document", oldIndex, newIndex));

      dispatch(AsyncActions.batchUpdateDocument(dirtyIds(getState())));
      dispatch(paginationActions.Actions.setDirtyIds(null));
    };
  },
  loadDocumentsById: (documentIds: List<number>, options: Partial<IDocumentOptions>): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const mergeOptions = setOptionsId(options, getState());

      if (documentIds.size === 0) return;

      dispatch(Actions.fetchDocuments(documentIds));

      try {
        const response = await list({ ...mergeOptions, document_ids: documentIds });
        dispatch(Actions.receiveDocumentsById(documentIds, response.documents));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.setDocumentsLoaded(documentIds));
      }
    };
  },
  processDocument: (document: IDocumentData): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      if (document.documentable_type === "Job") {
        dispatch(jobActions.Actions.increaseCount("document", document.documentable_id));
      }

      if (document.documentable_type === "EstimateLineItem") {
        dispatch(estimateLineItemActions.Actions.pushDocumentId(document.documentable_id, document.id));
      }

      const folderParentId = query(getState()).get("folder_parent_id");
      if (!folderParentId || document.folder_id === parseInt(folderParentId)) {
        dispatch(paginationActions.Actions.pushId(document.id, "document"));
      }

      const { task } = document;
      if (task) {
        const contextObj: ITaskContext = {
          model: "document",
          action: "taskHandler",
          action_payload: { id: document.id },
          error_action: "errorTaskHandler",
        };
        task.context = contextObj;
        dispatch(taskActions.Actions.receiveTask(task));
      }
    };
  },

  taskHandler: (task: ITaskData, payload: any = {}): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.editImportType(payload.id, task.results.import_type));
    };
  },

  moveDocuments: (folderId: number): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const documentIds = documentsSelectedIds(getState(), {});
      const doc = document(getState(), { documentId: documentIds.first() });

      dispatch(Actions.batchEditFolderId(documentIds, folderId));
      try {
        await dispatch(AsyncActions.batchUpdateDocument(documentIds, true));

        dispatch(Actions.batchRemoveIds(documentIds));
        await dispatch(AsyncActions.checkPageEmpty(_.toUnderscore(doc.documentable_type), doc.folder_id));
      } catch {
        dispatch(Actions.batchEditFolderId(documentIds, doc.folder_id));
      }
    };
  },
  checkPageEmpty: (kind: DocumentableType, folder_id: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      return paginationActions.checkPageEmpty(getState(), "document", (newQueryParams) =>
        dispatch(
          AsyncActions.listDocuments(
            {
              documentableType: kind,
              folder_id,
              signed: "unsigned",
            },
            newQueryParams,
          ),
        ),
      );
    };
  },
  downloadDocument: (document: DocumentRecord, signed = false): ThunkResult<Promise<void>> => {
    return async () => {
      const url = signed ? document.signed_document.url : document.file.url;
      return downloadFile(url, document.name);
    };
  },
  combine: (document_ids: List<number>, name: string, jobId: number): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const document = (await combineDocuments(document_ids, name, jobId)).document;
        const taskResponse: any = await dispatch(taskActions.AsyncActions.pollTask(document.task));
        dispatch(Actions.receiveDocument(taskResponse.results.document));
        return taskResponse.results.document.id;
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
      }
    };
  },
  errorTaskHandler: (task: ITaskData, payload: any = {}): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const errorMessage =
        task?.results?.error_message ||
        "There were problems processing your task. Please try again. If the problem persists contact support.";
      dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errorMessage));

      dispatch(Actions.destroyDocument(payload.id));
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;

const setOptionsId = (options: Partial<IDocumentOptions>, state: RootState): IDocumentOptions => {
  if (_.isNullOrUndefined(options.documentableId)) {
    switch (options.documentableType) {
      case "job":
        options.documentableId = currentJobId(state);
        break;
      case "org":
        options.documentableId = currentOrgId(state);
        break;
    }
  }

  return options as IDocumentOptions;
};

const setPresignedOptions = (options: Partial<IDocumentOptions>, state: RootState): IDocumentOptions => {
  switch (options.documentableType) {
    case "estimate_line_item":
      options.presignedType = "job";
      options.presignedId = currentJobId(state);
      break;
    default:
      options.presignedType = options.documentableType;
      options.presignedId = options.documentableId;
      break;
  }

  return options as IDocumentOptions;
};
