import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as tokenActions from "app2/src/reducers/token.actions";
import { token as selectToken } from "app2/src/selectors/token.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import { RootState } from "app2/src/reducers";
import { TokenKind } from "app2/src/records/Token";
import { IUser } from "app/src/Models/User";
import IntegrationList from "../../Common/IntegrationList";

export interface ICrmUserQuery {
  page: number;
  query: string;
}
interface Improveit360Props {
  integration: TokenKind;
  integrationLogo: string;
  tokenUpdated: () => void;
  occUsers?: IUser[];
  settingsButton?: boolean;
  authorize?: () => void;
  authless?: boolean;
}
const Improveit360: React.FunctionComponent<Improveit360Props> = ({
  integration,
  integrationLogo,
  tokenUpdated,
  occUsers,
  settingsButton,
}) => {
  //Hooks
  const dispatch = useDispatch();
  //Selectors
  const orgId = useSelector(currentOrgId);
  const authorized = useSelector((state: RootState) =>
    getAuthorization(state, { orgId: orgId, integration: integration }),
  );

  const token = useSelector((state: RootState) => selectToken(state, { kind: integration }));

  const platform = React.useMemo(() => {
    return token ? token?.getIn(["data", "platform"], "classic") : "classic";
  }, [token]);

  //Lifecycle
  React.useEffect(() => {
    if (!token && authorized) dispatch(tokenActions.AsyncActions.getToken(orgId, integration));
  }, [authorized]);
  return (
    <IntegrationList
      integration={integration}
      integrationLogo={integrationLogo}
      tokenUpdated={tokenUpdated}
      settingsButton={settingsButton}
      occUsers={occUsers}
      platform={platform}
    />
  );
};

export default Improveit360;
