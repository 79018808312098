import { hasIntegration } from "app2/src/records/Job";
import { RootState } from "app2/src/reducers";
import { AsyncActions } from "app2/src/reducers/estimate.actions";
import { currentJob } from "app2/src/selectors/job.selectors";
import { currentOrgId, preferencesConfig } from "app2/src/selectors/org.selectors";
import { List } from "immutable";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";

export interface IntegrationsButtonsProps {
  estimateId: number;
  documentType: "estimate" | "contract";
}

export const IntegrationsButtons: React.FC<IntegrationsButtonsProps> = ({ estimateId, documentType }) => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const jobRecord = useSelector(currentJob);
  const estimatePref = useSelector((state: RootState) =>
    preferencesConfig(state, { path: ["marketsharp", "estimate"], orgId: currentOrgId(state), notSet: List() }),
  );
  const hasSalesforce = React.useMemo(() => {
    return hasIntegration(jobRecord, "salesforce_opportunity_id");
  }, [jobRecord]);

  const attachIntegration = (integration: string) => {
    // Match old tracking event names
    const integrationName =
      integration === "job_nimbus"
        ? "job nimbus"
        : integration === "job_progress"
          ? "jobprogress"
          : integration === "lead_perfection"
            ? "LeadPerfection"
            : integration;
    trackEvent({ action: `attach proposal to ${integrationName}` });
    dispatch(AsyncActions.attachIntegration(estimateId, integration, documentType));
  };

  if (!estimatePref.includes("button")) {
    return null;
  }

  return (
    <>
      {jobRecord?.metadata?.get("marketsharp_contact_id") && (
        <Button className="ml-2" onClick={() => attachIntegration("marketsharp")}>
          Marketsharp
        </Button>
      )}
      {hasSalesforce && (
        <Button className="ml-2" onClick={() => attachIntegration("salesforce")}>
          Salesforce
        </Button>
      )}
      {jobRecord?.metadata?.get("job_nimbus_contact_id") && (
        <Button className="ml-2" onClick={() => attachIntegration("job_nimbus")}>
          Job Nimbus
        </Button>
      )}
      {jobRecord?.metadata?.get("jobprogress_job_id") && (
        <Button className="ml-2" onClick={() => attachIntegration("job_progress")}>
          JobProgress
        </Button>
      )}
      {jobRecord?.metadata?.get("lead_perfection_id") && (
        <Button className="ml-2" onClick={() => attachIntegration("lead_perfection")}>
          LeadPerfection
        </Button>
      )}
    </>
  );
};
