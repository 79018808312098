import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import * as ng from "angular";
import { dispatch, StoreRegistry } from "app2/src/storeRegistry";
import { push } from "connected-react-router/immutable";

export interface IDirtyMerge {
  check(): boolean;
  reset(): ng.IPromise<any>;
  trackEvent(action, props): void;
}

export interface IDirtyWatcher {
  setup($scope: ng.IScope, watched: IDirtyMerge);
  cleanup();
  trackEvent(action, props): void;
}

class DirtyWatcher implements IDirtyWatcher {
  private watched: IDirtyMerge;
  private _locationChangeBinding: () => void;
  private _unloadBinding: () => void;
  private _unblock: () => void;
  private history;

  constructor(
    public ConfirmDialog: IConfirmDialog,
    public $state: ng.ui.IStateService,
    public $analytics: angulartics.IAnalyticsService,
  ) {
    this.history = StoreRegistry.get("history");
  }

  public setup($scope: ng.IScope, obj: IDirtyMerge) {
    this.watched = obj;

    this._locationChangeBinding = $scope.$on("RSF_StateChange", (event: ng.IAngularEvent, args: any) => {
      if (this.watched.check()) {
        args.transition._deferred.reject();
        this.ConfirmDialog.confirm(
          "There are unsaved changes that will be lost.  Are you sure you want to navigate away?",
        )
          .then(() => {
            this.watched.reset().then(() => {
              this.watched.trackEvent("close dirty", {});
              this.$state.go(args.transition.to().name, args.transition.params());
            });
          })
          .catch(() => {
            this.watched.trackEvent("don't close", {});
          });
      }
    });

    this._unblock = this.history.block((prompt) => {
      if (this.watched.check()) {
        this._locationChangeBinding();
        this.ConfirmDialog.confirm(
          "There are unsaved changes that will be lost.  Are you sure you want to navigate away?",
        )
          .then(async () => {
            this.watched.trackEvent("close dirty", {});
            await this.watched.reset();
            this._unblock();
            dispatch(push(prompt.pathname + (prompt.search || "")));
          })
          .catch(() => {
            this.watched.trackEvent("don't close", {});
          });
        return false;
      }
    });

    this._unloadBinding = $scope.$on("onBeforeUnload", (event: ng.IAngularEvent, args: any) => {
      if (this.watched.check()) {
        args["message"] = "There are unsaved changes that will be lost.  Are you sure you want to continue?";
        event.preventDefault();
      }
    });

    $scope.$on("$destroy", () => {
      this.cleanup();
    });
  }

  public cleanup() {
    if (this._locationChangeBinding) {
      this._locationChangeBinding();
    }
    if (this._unloadBinding) {
      this._unloadBinding();
    }
    if (this.history) {
      this._unblock();
    }
  }

  public trackEvent(action, props): void {
    this.$analytics.eventTrack(action, props);
  }
}

const factory = (
  ConfirmDialog: IConfirmDialog,
  $state: ng.ui.IStateService,
  $analytics: angulartics.IAnalyticsService,
) => {
  return new DirtyWatcher(ConfirmDialog, $state, $analytics);
};

factory.$inject = ["ConfirmDialog", "$state", "$analytics"];

export { factory as DirtyWatcher };
