import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { BaseTabCtrl } from "app/src/Jobs/tabs/BaseTabCtrl";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import * as angulartics from "angulartics";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IToolInfo } from "app/src/Common/ToolInfoService";
import { IBaseConfig } from "../../Common/IBaseConfig";
import * as tokenActions from "app2/src/reducers/token.actions";
import { dispatch } from "app2/src/storeRegistry";

export class ToolTabCtrl extends BaseTabCtrl {
  public toolsLoaded = false;

  public static $inject = [
    "JobFetcher",
    "$state",
    "$stateParams",
    "$scope",
    "$rootScope",
    "$analytics",
    "$window",
    "BaseConfig",
    "Pretty",
    "ToolInfo",
  ];
  constructor(
    public JobFetcher: IJobFetcherService,
    protected $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public $scope: ng.IScope,
    public $rootScope: RsfRootScope,
    protected $analytics: angulartics.IAnalyticsService,
    public $window: ng.IWindowService,
    public BaseConfig: IBaseConfig,
    public Pretty: IPretty,
    public ToolInfo: IToolInfo,
  ) {
    super(JobFetcher, $scope, $analytics, $window, $stateParams["id"]);

    this.job.$promise.then(() => {
      this.loadTools().then(() => {
        this.toolsLoaded = true;
        this.$scope.$digest();
      });
      dispatch(tokenActions.AsyncActions.getToken(this.job.org.id, "provia"));
    });
  }

  public goToAddress(): void {
    this.$state.go("job_header.job_show.info", { id: this.job.id });
  }
}
