import { useEffect } from "react";
import { IDirtyWatcher, IDirtyMerge } from "app/src/Common/DirtyWatcher";

export const useDirtyWatcher = ($scope: ng.IScope, DirtyWatcher: IDirtyWatcher, dirty: boolean, reset: () => void) => {
  const check = () => {
    return dirty;
  };
  const resetForm = (): ng.IPromise<any> => {
    reset();

    return Promise.resolve(true) as unknown as ng.IPromise<any>;
  };
  const trackEvent = (action, props) => {
    DirtyWatcher.trackEvent(action, props);
  };

  useEffect(() => {
    DirtyWatcher.setup($scope, {
      check: check,
      reset: resetForm,
      trackEvent: trackEvent,
    } as IDirtyMerge);
    return () => {
      DirtyWatcher.cleanup();
    };
  });
};
