import * as jobAttributeActions from "app2/src/reducers/jobAttribute.actions";
import { Map, Record, List } from "immutable";
import {
  JobAttributeLinkRecordType,
  JobObjectRecord,
  fromJsonByType,
  linkFromJsonByType,
} from "app2/src/records/JobAttribute";
import { LevelRecord, LocationRecord, JobAttributeRecordType } from "../records/JobAttribute";
import { JobAttributeTypes, orgJobAttributeLinkIdName, orgJobAttributeLinkKey } from "../records/OrgRecord";
import { RootActions, RootState } from ".";
import { rootKey } from "app2/src/selectors/jobAttribute.selectors";

export const JobAttributeStateRecord = Record({
  activeByTypeAndOrgId: Map<string, Map<number, List<JobAttributeLinkRecordType>>>(),
  availableByTypeAndOrgId: Map<string, Map<number, List<string | number>>>(),
  byTypeAndId: Map<string, Map<string | number, JobAttributeRecordType>>(),
  lastSavedByTypeAndId: Map<string, Map<string | number, JobAttributeRecordType>>(),
  loadingByTypeAndOrgId: Map<string, Map<number, boolean>>(),
});

export const initialState = JobAttributeStateRecord();

export type JobAttributeState = typeof initialState;

export const model = rootKey;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  const jobAttrType: JobAttributeTypes = ((action as any)?.payload as any)?.jobAttrType;

  switch (action.type) {
    case jobAttributeActions.FETCH_JOB_ATTRIBUTES:
      return state.setIn([model, "loadingByTypeAndOrgId", jobAttrType, action.payload.orgId], true);

    case jobAttributeActions.FETCH_JOB_ATTRIBUTE:
      return state.updateIn(
        [model, "byTypeAndId", jobAttrType, action.payload.jobAttributeId],
        (record: JobAttributeRecordType | null) => {
          if (!record) {
            return fromJsonByType[jobAttrType]({ id: action.payload.jobAttributeId, loading: true } as any);
          }

          return (record as any).set("loading", true);
        },
      );

    case jobAttributeActions.RECEIVE_JOB_ATTRIBUTES:
      const { orgId } = action.payload;
      let newState = state;

      action.payload.jobAttributes.forEach((ja) => {
        newState = reducer(newState, jobAttributeActions.Actions.receiveJobAttribute(ja, orgId, jobAttrType));
      });

      return newState.setIn([model, "loadingByTypeAndOrgId", jobAttrType, orgId], false);

    case jobAttributeActions.RECEIVE_JOB_ATTRIBUTE:
      const jobAttribute = action.payload.jobAttribute;
      if (jobAttribute[orgJobAttributeLinkKey(jobAttrType)]) {
        state = reducer(
          state,
          jobAttributeActions.Actions.receiveActiveOrgJobAttribute(
            action.payload.orgId,
            jobAttribute[orgJobAttributeLinkKey(jobAttrType)],
            jobAttrType,
          ),
        );
      } else {
        state = reducer(
          state,
          jobAttributeActions.Actions.receiveAvailableOrgJobAttribute(
            action.payload.orgId,
            jobAttribute.id,
            jobAttrType,
          ),
        );
      }
      const jobAttributeRecord = fromJsonByType[jobAttrType]({ ...jobAttribute, loading: false } as any);
      return state
        .setIn([model, "byTypeAndId", jobAttrType, jobAttributeRecord.id], jobAttributeRecord)
        .setIn([model, "lastSavedByTypeAndId", jobAttrType, jobAttributeRecord.id], jobAttributeRecord);

    case jobAttributeActions.RECEIVE_ACTIVE_ORG_JOB_ATTRIBUTE:
      return state.updateIn(
        [model, "activeByTypeAndOrgId", jobAttrType, action.payload.orgId],
        (value: List<JobAttributeLinkRecordType> | undefined) => {
          if (!value) {
            value = List<JobAttributeLinkRecordType>();
          }
          const linkRecord = linkFromJsonByType[jobAttrType](action.payload.linkData as any);
          const linkIdProperty = orgJobAttributeLinkIdName(jobAttrType);

          const existingIdx = value.findIndex((v) => v.get(linkIdProperty, "") === linkRecord.get(linkIdProperty, ""));

          if (existingIdx >= 0) {
            return value.set(existingIdx, linkRecord).sortBy((v) => v.sort_order);
          }
          return value.push(linkRecord).sortBy((v) => v.sort_order);
        },
      );

    case jobAttributeActions.RECEIVE_AVAILABLE_ORG_JOB_ATTRIBUTE:
      return state
        .updateIn(
          [model, "availableByTypeAndOrgId", jobAttrType, action.payload.orgId],
          (value: List<string | number> | undefined) => {
            if (!value) {
              value = List<string | number>();
            }

            if (value.includes(action.payload.jobAttributeId)) {
              return value;
            }
            return value.push(action.payload.jobAttributeId);
          },
        )
        .setIn([model, "byTypeAndId", jobAttrType, action.payload.jobAttributeId, "loading"], false);

    case jobAttributeActions.REMOVE_AVAILABLE_ORG_JOB_ATTRIBUTE:
      return state.updateIn(
        [model, "availableByTypeAndOrgId", jobAttrType, action.payload.orgId],
        (value: List<string | number>) => (value || List()).filter((a) => a !== action.payload.jobAttributeId),
      );

    case jobAttributeActions.REMOVE_ACTIVE_JOB_ATTRIBUTE:
      const linkIdProperty = orgJobAttributeLinkIdName(jobAttrType);
      return state.updateIn(
        [model, "activeByTypeAndOrgId", jobAttrType, action.payload.orgId],
        (value: List<JobAttributeLinkRecordType>) => {
          return (value || List())
            .filter((a) => a.get(linkIdProperty, 0) !== action.payload.jobAttributeId)
            .map((a, idx) => a.set("sort_order", idx));
        },
      );

    case jobAttributeActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);

    case jobAttributeActions.RESET_JOB_ATTRIBUTE:
      const { id: resetId, jobAttrType: resetJobAttrType } = action.payload;
      return state.setIn(
        [model, "byTypeAndId", resetJobAttrType, resetId],
        state.getIn([model, "lastSavedByTypeAndId", resetJobAttrType, resetId]),
      );

    case jobAttributeActions.RECEIVE_JOB_ATTRIBUTE_ERROR:
      const { id } = action.payload;
      return state.setIn([model, "byTypeAndId", jobAttrType, id, "loading"], false);

    default:
      return state;
  }
};
