import * as productOptionGroupActions from "./productOptionGroup.actions";
import * as productOptionActions from "./productOption.actions";
import { Map, Record } from "immutable";
import { ProductOptionGroupRecord, fromJSON } from "../records/ProductOptionGroup";
import { RootActions, RootState } from ".";
import { ActivatedPriceListRecord } from "app2/src/records/ActivatedPriceList";
import { reducer as productOptionReducer } from "app2/src/reducers/productOption.reducer";

export const ProductOptionGroupStateRecord = Record({
  byId: Map<number, ProductOptionGroupRecord>(),
});

export const initialState = ProductOptionGroupStateRecord();

export type ProductOptionGroupState = typeof initialState;

export type ProductOptionGroupReducerState = RootState | ActivatedPriceListRecord;

export const reducer = (state: ProductOptionGroupReducerState, action: RootActions): ProductOptionGroupReducerState => {
  const model = "productOptionGroups";
  if (state && !state.getIn([model])) {
    state = state.setIn([model], initialState);
  }

  let productOptionGroup: ProductOptionGroupRecord;
  switch (action.type) {
    case productOptionGroupActions.RECEIVE_PRODUCT_OPTION_GROUP:
      productOptionGroup = fromJSON({ ...action.payload.productOptionGroup });

      action.payload.productOptionGroup.options.forEach((option) => {
        state = productOptionReducer(state, productOptionActions.Actions.receiveProductOption(option));
      });

      return state.setIn([model, "byId", productOptionGroup.id], productOptionGroup);

    default:
      return state;
  }
};
