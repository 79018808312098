import { emailListRegex } from "app2/src/helpers/InputValidator";
import { IPretty } from "app/src/Common/PrettyNameService";

export class EmailModal {
  public emails: string;
  public message = "";
  public pdf_options: any;
  public emailListPattern = emailListRegex;

  public static $inject = ["$uibModalInstance", "Pretty", "job_email", "options"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public Pretty: IPretty,
    public job_email: any,
    public options: any,
  ) {
    this.emails = job_email.email;
    this.pdf_options = options;
  }

  public send() {
    this.$uibModalInstance.close({
      emails: this.emails,
      message: this.message,
      options: this.pdf_options,
    });
  }
}
