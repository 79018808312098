import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import track from "react-tracking";
import { orgChanged, orgLoading } from "app2/src/selectors/org.selectors";
import * as orgActions from "app2/src/reducers/org.actions";
import Order from "app2/src/components/OrgTabs/DocumentTab/DocumentOrder/Order";
import { Button, Row, Col } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Can } from "app2/src/components/Common/CanComponent";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";

const mapStateToProps = (state: RootState) => {
  return {
    orgChanged: orgChanged(state),
    orgLoading: orgLoading(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    updateCurrentOrg: () => dispatch(orgActions.AsyncActions.updateCurrentOrg()),
    resetOrg: () => dispatch(orgActions.Actions.resetOrg()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DocumentOrderProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DocumentOrderProps;

@track(() => {
  return {
    component: "DocumentOrder",
  };
})
class DocumentOrder extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.check = this.check.bind(this);
    this.save = this.save.bind(this);
  }

  public check(): boolean {
    const { orgChanged } = this.props;
    return orgChanged;
  }

  @track(() => ({
    action: "save document ordering changes",
  }))
  public save() {
    const { updateCurrentOrg } = this.props;
    updateCurrentOrg();
  }

  public render(): React.ReactNode {
    const { orgLoading, orgChanged, resetOrg } = this.props;

    return (
      <>
        <SpinnerComponent localProperty={orgLoading} />
        <DirtyWatcher check={this.check} reset={resetOrg} />
        <Row>
          <Col>
            <Can resource="org" permission="update">
              <Button onClick={this.save} disabled={!orgChanged} className="pull-right">
                Save Document Ordering Changes
              </Button>
            </Can>
          </Col>
        </Row>
        <br />
        <Order docType="estimate" />
        <Order docType="contract" />
        <Order docType="inspection" />
      </>
    );
  }
}

export default connector(DocumentOrder);
