import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { orgService } from "app2/src/api/org.service";
import Spinner from "app2/src/components/SpinnerComponent";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { SetupForm } from "./SetupForm";
import { IDirtyWatcher } from "app/src/Common/DirtyWatcher";
import { currentOrg } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: OrgSetupProps) => {
  return {
    org: currentOrg(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: OrgSetupProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OrgSetupProps {
  DirtyWatcher: IDirtyWatcher;
  $scope: ng.IScope;
}

interface OrgSetupState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OrgSetupProps;

class OrgSetup extends React.Component<Props, OrgSetupState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { org, DirtyWatcher, $scope } = this.props;
    if (!org || !org.preferences) {
      return <Spinner localProperty={true}></Spinner>;
    }

    return (
      <Spinner localProperty={org.loading}>
        <SetupForm org={org} DirtyWatcher={DirtyWatcher} $scope={$scope} />
      </Spinner>
    );
  }
}

export default connector(OrgSetup);
