import * as ng from "angular";
import { IProductOption } from "app/src/Models/ProductOption";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IEstimateLineItemOption } from "./EstimateLineItemOption";

export interface IProductOptionGroupsMatch {
  matched: IProductOptionGroup[];
  uuid: string;
  match_id: string;
  name: string;
  selection_mode: string;
  pog_type: string;
  option_count: number;
  product_count: number;
  option_group_count: number;
  option_group_ids: number[];
  show: boolean;
  sort_order: number;
  price_list_id: number;
  options: IProductOption[];
}

export interface IProductOptionGroup extends ng.resource.IResource<IProductOptionGroup>, ProductOptionGroupPrototype {
  show?: boolean;
  $saveOrCreate(...args: any[]): ng.IPromise<IProductOptionGroup>;
  $create(): ng.IPromise<IProductOptionGroup>;
  $save(): ng.IPromise<IProductOptionGroup>;
}

export interface IProductOptionGroupResource extends ng.resource.IResourceClass<IProductOptionGroup>, IRsfResource {
  fromJSON?(data: any): IProductOptionGroup;
  bulk?(data: any): IProductOptionGroup[];
  query_match?(data: any): IOptionGroupsMatchResponse;
  byMatchId?(data: any): IProductOptionGroupResponse;
  groupOptionGroupsByMatchId?(array: IProductOptionGroup[]): IProductOptionGroupsMatch[];
  groupOptionGroupsByUuid?(array: IProductOptionGroup[]): IProductOptionGroupsMatch[];
}

export interface IProductOptionGroupResponse extends ng.resource.IResourceArray<IProductOptionGroup> {
  product_option_groups: IProductOptionGroup[];
  meta: any;
}

export interface IOptionGroupsMatchResponse extends ng.resource.IResource<IOptionGroupsMatchResponse> {
  option_groups_matches: IProductOptionGroupsMatch[];
  price_list_id: number;
  meta: any;
}

export class ProductOptionGroupPrototype {
  public id: number;
  public classy: string;
  public name: string;
  public uuid: string;
  public match_id: string;
  public price_list_id: number;
  public selection_mode: string;
  public pog_type: string;
  public product_ids: number[];
  public options: IProductOption[];
  public options_count: number;
  public sort_order: number;
  public created_at: Date;
  public updated_at: Date;
  public check: boolean;

  public $saveOrCreate(params, callback) {
    if (!this.id || this.id <= 0) {
      return (<any>this).$create(params, callback);
    } else {
      return (<any>this).$save(params, callback);
    }
  }

  public copyValues(from: IProductOptionGroup) {
    for (const key in from) {
      if (from.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        this[key] = from[key];
      }
    }
  }

  public optionSelected(lineItem) {
    const option_uuids = _.pluck(this.options, "uuid");
    return _.chain(lineItem.existingOptions())
      .any((elio: IEstimateLineItemOption) => {
        return _.contains(option_uuids, elio.product_option_uuid);
      })
      .value();
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/product_option_groups/:id";
  const nested_url = BaseConfig.BASE_URL + "/api/v1/price_lists/:price_list_id/product_option_groups/:id";
  let bulk_request: IProductOptionGroup[];

  const transformBulkResponse = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IProductOptionGroup[] => {
    if (status < 200 || status > 299) {
      return [];
    }

    const product_option_groups = JSON.parse(response).product_option_groups;
    _.each(bulk_request, (pog) => {
      const found_pog = _.find(product_option_groups, (rpog: any) => {
        return rpog.id === pog.id;
      });
      if (found_pog !== undefined) {
        pog.name = found_pog.name;
        pog.selection_mode = found_pog.selection_mode;
        pog.pog_type = found_pog.pog_type;
        pog.match_id = found_pog.match_id;
        pog.sort_order = found_pog.sort_order;
      }
    });
    return bulk_request;
  };

  const transformBulkRequest = (data: any): string => {
    bulk_request = data.edit_product_option_groups;
    let ids = [];
    if (_.isEmpty(bulk_request)) {
      ids = data.pog_ids;
    } else {
      ids = _.pluck(bulk_request, "id");
    }

    return angular.toJson({ ids: ids, product_option_group: data.product_option_group });
  };

  const transformSingleResponse = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IProductOptionGroup => {
    if (status < 200 || status > 299) {
      return new ProductOptionGroup({});
    }

    const data: any = JSON.parse(response).product_option_group;

    return ProductOptionGroup.fromJSON(data);
  };

  const transformMultipleResponse = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta: any = JSON.parse(response);

    _.each(meta.product_option_groups, (child, index) => {
      meta.product_option_groups[index] = ProductOptionGroup.fromJSON(child);
    });

    return meta;
  };

  const requestTransform = (product_option_group: IProductOptionGroup): string => {
    //noinspection TypeScriptUnresolvedFunction
    const data = JSON.parse(angular.toJson(JSON.decycle(product_option_group)));

    return angular.toJson({ product_option_group: data });
  };

  const ProductOptionGroup: IProductOptionGroupResource = <IProductOptionGroupResource>$resource(
    url,
    { id: "@id", price_list_id: "@price_list_id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultipleResponse,
        isArray: false,
      },
      byMatchId: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: nested_url + "/match_id/:match_id",
        transformResponse: transformMultipleResponse,
        isArray: false,
      },
      query_match: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: nested_url + "/index_match",
        transformResponse: (response: string) => {
          return JSON.parse(response);
        },
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
      },
      bulk: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: nested_url + "/update_bulk",
        isArray: true,
        transformRequest: transformBulkRequest,
        transformResponse: transformBulkResponse,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: nested_url,
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
      },
    },
  );

  ProductOptionGroup.fromJSON = (data: any) => {
    data.classy = "ProductOptionGroup";
    if (_.isArray(data.options)) {
      _.each(data.options, (opt, idx) => {
        data.options[idx] = resources.ProductOption.fromJSON(opt);
      });
    } else {
      data.options = [];
    }

    if (!data.match_id) {
      data.match_id = data.uuid;
    }

    return new ProductOptionGroup(data);
  };

  ProductOptionGroup.groupOptionGroupsByMatchId = (array: IProductOptionGroup[]) => {
    const array_of_objects = [];
    const groups = _.groupBy(array, "match_id");
    _.each(Object.keys(groups), (match_id) => {
      let option_count = 0;
      let product_count = 0;
      _.each(groups[match_id], (pog) => {
        option_count += pog.options_count;
        product_count += pog.product_ids.length;
      });
      array_of_objects.push({
        match_id: match_id,
        name: groups[match_id][0].name,
        selection_mode: groups[match_id][0].selection_mode,
        pog_type: groups[match_id][0].pog_type,
        option_count: option_count,
        option_group_count: groups[match_id].length,
        product_count: product_count,
        matched: groups[match_id],
        show: false,
        sort_order: groups[match_id][0].sort_order,
        price_list_id: groups[match_id][0].price_list_id,
      });
    });
    return array_of_objects;
  };

  ProductOptionGroup.groupOptionGroupsByUuid = (array: IProductOptionGroup[]) => {
    const array_of_objects = [];
    const groups = _.groupBy(array, "uuid");
    _.each(Object.keys(groups), (uuid) => {
      let option_count = 0;
      let product_count = 0;
      _.each(groups[uuid], (pog) => {
        option_count += pog.options_count;
        product_count += pog.product_ids.length;
      });
      array_of_objects.push({
        uuid: uuid,
        name: groups[uuid][0].name,
        selection_mode: groups[uuid][0].selection_mode,
        pog_type: groups[uuid][0].pog_type,
        option_count: option_count,
        option_group_count: groups[uuid].length,
        product_count: product_count,
        matched: groups[uuid],
        show: false,
        sort_order: groups[uuid][0].sort_order,
        price_list_id: groups[uuid][0].price_list_id,
      });
    });
    return array_of_objects;
  };

  _.hiddenExtend(ProductOptionGroup.prototype, ProductOptionGroupPrototype.prototype);

  ProductOptionGroup.inject = (injected: IRepository) => {
    resources = injected;
  };

  return ProductOptionGroup;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
