import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IRepository } from "../../Common/Repository";
import { IProduct } from "./Product";
import { ICoupon } from "./Coupon";
import { IBaseConfig } from "../../Common/IBaseConfig";

export interface ISku extends ng.resource.IResource<ISku>, SkuPrototype {
  attributes: any;
}

export interface ISkuResource extends ng.resource.IResourceClass<ISku> {
  fromJSON?(data: any): ISku;
  inject(repository: IRepository): void;
}

export interface ISkuResponse extends ng.resource.IResourceArray<ISku> {
  skus: ISku[];
  meta: IPagingMetadata;
}

class SkuPrototype {
  public id: number;
  public product: IProduct;
  public active: boolean;
  public billing_product_id: number;
  public created_at: Date;
  public currency: string;
  public image: string;
  public inventory: any;
  public livemode: boolean;
  public metadata: any;
  public package_dimensions: any;
  public price: number;
  public coupons: ICoupon[];
  public stripe_attributes: any;
  public stripe_created: string;
  public stripe_data: any;
  public stripe_id: string;
  public stripe_product: string;
  public stripe_updated: string;
  public updated_at: Date;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/billing/v1/skus/:id";

  const multiResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return { skus: [], metadata: {} };
    }

    const meta: any = JSON.parse(response);

    _.each(meta.skus, (child, index) => {
      meta.skus[index] = Sku.fromJSON(child);
    });

    return meta;
  };

  const Sku: ISkuResource = <ISkuResource>$resource(
    url,
    {},
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multiResponseTransform,
        isArray: false,
      },
    },
  );

  Sku.fromJSON = (data: any): ISku => {
    if (data.coupons) {
      _.each(data.coupons, (c_data: any, idx: number) => {
        data.coupons[idx] = resources.Coupon.fromJSON(c_data);
      });
    }

    return new Sku(data);
  };

  Sku.inject = (repository: IRepository) => {
    resources = repository;
  };

  _.hiddenExtend(Sku.prototype, SkuPrototype.prototype);

  return Sku;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
