import { connectFC } from "app2/src/connect";
import * as React from "react";
import { useSelector } from "react-redux";
import * as FontAwesome from "react-fontawesome";
import { IEstimate } from "app/src/Models/Estimate";
import { currentOrgId, settingsConfig } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";
import { Can } from "app2/src/components/Common/CanComponent";
import { CommissionCalculatorModal } from "./CommissionCalculatorModal";
import { DownloadCommissionButton } from "./DownloadCommissionButton";

export interface CommissionActionsProps {
  estimateId: number;
}

const CommissionActions: React.FC<CommissionActionsProps> = ({ estimateId }) => {
  const [showModal, setShowModal] = React.useState(false);

  const calculatorMethod = useSelector((state: RootState) =>
    settingsConfig(state, {
      orgId: currentOrgId(state),
      path: ["estimator", "commission_calculator", "method"],
      notSet: "off",
    }),
  );

  if (calculatorMethod === "off") return null;
  return (
    <>
      <Can resource="estimate" permission="commission">
        <FontAwesome
          name="usd"
          className="rsf-base-link rsf-base-66 action-button"
          title="Calculate Commission"
          onClick={() => setShowModal(true)}
        />
        {showModal && (
          <CommissionCalculatorModal show={showModal} close={() => setShowModal(false)} estimateId={estimateId} />
        )}
      </Can>
      <DownloadCommissionButton estimateId={estimateId} />
    </>
  );
};

export default connectFC(CommissionActions);
