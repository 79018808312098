import * as config from "react-global-configuration";

// prettier-ignore
config.set({
  "ENVIRONMENT": "rsf",
  "BASE_URL": "https://api.remotesf.com",
  "PRICELIST_API_URL": "https://api.remotesf.com",
  "APP_URL": "https://app.remotesf.com",
  "STORAGE_URL": "https://storage.remotesf.com",
  "CUSTOMER_URL": "http://customer.remotesf.com",
  "CUSTOMER_URL_SSL": "https://customer.remotesf.com",
  "ALT_URL": "https://app2.remotesf.com",
  "LEADS_URL": "https://app.remotesf.com/leads_module/",
  "GA_KEY": "G-4XKZ6G1615",
  "NEWRELIC_APP_ID": "1588648437",
  "RSF_ANALYTICS_KEY": "prod_a2efr83sspq",
  "RSF_ANALYTICS_URL": "https://analytics.remotesf.com",
  "RSF_ANALYTICS_WS": "wss://analytics.remotesf.com",
  "OIDC_AUTHORITY_URL": "https://login.microsoftonline.com/bcfa3e87-841e-48c7-983b-584159dd1a69/v2.0/.well-known/openid-configuration",
  "OIDC_CLIENT_ID": "2596cc49-72c4-4305-b315-13d900069782",
  "OIDC_REDIRECT_URL": "https://app.remotesf.com/auth/oidccallback",
  "OIDC_SCOPE": ".default openid",
  "STRIPE_KEY": "pk_live_0wOH6GDjH8wlzgN51EccRNwH",
  "LEARN_MORE_URL": "https://oneclickcontractor.com",
  "APP_NAME": "One Click Contractor",
  "APP_NAME_SHORT": "One Click Contractor",
  "APP_LOGO_URL": "/assets/images/big_logo-01.a3b2ddd4.png",
  "APP_ICON_URL": "/assets/images/logo.d0e212c3.png",
  "APP_ICON_BIG_URL": "/assets/images/occ-logo_rgb_lightbkg.a3b2ddd4.png",
  "COMPANY_NAME": "Remote Sales Force",
  "STATES_FILE": "us-ca.ts",
  "FAVICON_FILE": "occ-favicon.ico",
  "ENFORCE_TOS": false,
  "DEFAULT_ROUTE": "root.job_list",
  "DEFAULT_ROUTE_LINK": "/jobs",
  "REPORT_PROVIDERS": [
    "wvs",
    "eagleview"
  ],
  "REPORT_KINDS": [
    "roofing",
    "walls",
    "hail",
    "wind",
    "lightning"
  ],
  "REPORT_INIT_LAT": 38.8668583,
  "REPORT_INIT_LNG": -100.6228446,
  "REPORT_INIT_ZOOM": 4,
  "AUTOCOMPLETE_COUNTRIES": [
    "us",
    "ca"
  ],
  "NEARMAP_API_KEY": "MTgzNzU5NzItMDVmZi00NGYyLWI1ZGItNTFhZmUxNjBiMzNi",
  "SUPPORT_URL": "https://help.oneclickcontractor.com/knowledge",
  "LEARNING_URL": "https://learnoneclick.com/login/login.php?access_token=",
  "SAMPLE_JOB_CSV_FILE_URL": "https://help.oneclickcontractor.com/knowledge/how-to-import-jobs-from-a-csv-file",
  "IMAGERY_AVAILABLE": false,
  "CONTACT_ADDRESS": "879 W Carmel Drive",
  "CONTACT_CITY": "Carmel",
  "CONTACT_STATE": "IN",
  "CONTACT_POSTAL": "46032",
  "CONTACT_COUNTRY": "US",
  "CONTACT_PHONE": "877-320-2391",
  "CONTACT_EMAIL": "support@oneclickcontractor.com",
  "CONTACT_HOURS": "",
  "ADS_URL": "https://s3.us-east-2.amazonaws.com/ads.oneclickcontractor.com/occ-freemium/occ_freemium_ads.json",
  "CUSTOMERIO_SITEID": "",
  "ONBOARDING_NAME": "Customer Support",
  "ONBOARDING_EMAIL": "onboarding@oneclickcontractor.com",
  "ONBOARDING_PHONE": "877-320-2391",
  "ONBOARDING_PHONE_DIAL": "tel:+18773202391",
  "PAYSIMPLE_SCRIPT": "https://api.paysimple.com/paysimplejs/v1/scripts/client.js",
  "CARD_CONNECT_IFRAME_URL": "https://fts.cardconnect.com/itoke/ajax-tokenizer.html",
  "TWILIO_NUMBER": "(844) 299-2269",
  "BILLING_LEAD_SOURCES": [
    "unset",
    "RSF",
    "Marketsharp",
    "Royal",
    "LP",
    "GAF",
    "James Hardie",
    "EcoView",
    "Sunrise",
    "LivingSpace",
    "Renoworks",
    "Chameleon",
    "Internal",
    "LP Freemium",
    "OCC Freemium",
    "Sunrise TSM"
  ],
  "DOCUMENT_ORDER_VALUES": [
    "cover_page",
    "estimate_pdf",
    "product_images",
    "job_photos",
    "job_documents",
    "product_brochures",
    "resource_documents",
    "inspection",
    "inspection_pdf"
  ],
  "GREEN_SKY_API_KEY": "bWVyY2hhbnQxMDI6bWVyY2hhbnQxMDI=",
  "GREEN_SKY_ENV": 0,
  "STRIPE_NAME": "OCC Subscription Plan",
  "STRIPE_IMAGE": "/assets/images/rsf-circle-128x128.d0e212c3.png",
  "HOMEVEST_CALLBACK": "https://api.remotesf.com/integrations/fin_mkt/callback",
  "HOMEVEST_FAILURE": "https://homevestfinance.com/result/failure",
  "HOMEVEST_SUCCESS": "https://homevestfinance.com/result/success",
  "HOMEVEST_PREQUALSUCCESS": "https://homevestfinance.com/result/offers",
  "HOMEVEST_PREQUALFAILURE": "https://homevestfinance.com/result/offersfailure",
  "VERSION": "dc7a286cb"
});
