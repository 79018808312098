import { isString } from "underscore";

export const capitalizeString = (input: string) => {
  if (!input) {
    return "";
  }
  return `${input.charAt(0).toUpperCase()}${input.slice(1).toLowerCase()}`;
};

export const titleCaseString = (input: string) => {
  if (!input) {
    return "";
  }
  return input
    .replace(/([^A-Z])([A-Z])/g, "$1 $2") // split cameCase
    .replace(/[_\-]+/g, " ") // split snake_case and lisp-case
    .toLowerCase()
    .replace(/(^\w|\b\w)/g, function (m) {
      return m.toUpperCase();
    }) // title case words
    .replace(/\s+/g, " ") // collapse repeated whitespace
    .replace(/^\s+|\s+$/, ""); // remove leading/trailing whitespace
};

export const firstName = (input: string): string => {
  if (!input || !isString(input)) return "";

  return input.split(" ")[0];
};

export const lastName = (input: string): string => {
  if (!input || !isString(input)) return "";

  const names = input.split(" ");
  names.shift(); // drop the first name
  return names.join(" "); // join the rest
};
