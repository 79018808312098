import { IEvent } from "app/src/Models/Event";
import { IJob, IJobResource } from "app/src/Models/Job";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import * as angulartics from "angulartics";
import { ITask, Task } from "../Models/Task";
import { emailListRegex } from "app2/src/helpers/InputValidator";

export class InviteModal {
  public emails: string;
  public emailListPattern = emailListRegex;

  public static $inject = [
    "$uibModalInstance",
    "$analytics",
    "Flash",
    "appointment",
    "job",
    "Job",
    "$timeout",
    "$http",
  ];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public $analytics: angulartics.IAnalyticsService,
    public Flash: IFlash,
    public appointment: IEvent,
    public job: IJob,
    public Job: IJobResource,
    public $timeout: ng.ITimeoutService,
    public $http: ng.IHttpService,
  ) {
    this.emails = job.email;
  }

  public send() {
    this.Job.invite({
      id: this.job.id,
      event_id: this.appointment.id,
      emails: this.emails,
      summary: this.appointment.summary,
    })
      .$promise.then((response: any) => {
        Task.watch(this.$timeout, this.$http, response.location, (task: ITask) => {
          if (task.status === "error") {
            this.Flash.addMessage(FlashLevels.danger, "There was an error with your email: " + task.logs);
          }
        });

        this.Flash.addMessage(FlashLevels.success, "Invite successfully sent.");
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems sending the invite. If the problem continues, contact support.",
        );
      });
    this.$uibModalInstance.close();
  }
}
