import { useSelector } from "app2/src/storeRegistry";
import { JobRecord } from "app2/src/records/Job";
import { RootState } from "app2/src/reducers";
import { currentOrgPrefConfig } from "app2/src/selectors/org.selectors";
import { isAddressValid, isValidLatLon, zillowAddress } from "app2/src/helpers/Address";
import { org as orgSelector } from "app2/src/selectors/org.selectors";
import { toOneLiner } from "app2/src/records/Address";
import { token } from "app2/src/selectors/token.selectors";
import { TokenRecord } from "app2/src/records/Token";

export interface ToolConfig {
  title: string;
  image: string;
  link?: (job: JobRecord, base_url: string) => string;
  action?: (job: JobRecord) => void;
  paid: boolean;
  visible: (job: JobRecord, navDisplay: boolean) => boolean;
}

export const getToolConfig = (toolKey: string): ToolConfig => toolConfigs[toolKey];

const getIntegrationLink = (toolKey: string) =>
  useSelector((state: RootState) => currentOrgPrefConfig(state, { path: ["integrations", toolKey, "url"] }));
const getRenoworksLink = () =>
  useSelector((state: RootState) => {
    const renoworksToken = token(state, { kind: "renoworks" }) as TokenRecord;
    if (!renoworksToken) return "";

    return renoworksToken.getIn(["data", "renoworks_url"]) || "";
  });
const getProviaToken = () =>
  useSelector((state: RootState) => {
    const proviaToken = token(state, { kind: "provia" }) as TokenRecord;
    return (proviaToken?.get("data")?.toJSON() || {}) as any;
  });

const toolConfigs: Record<string, ToolConfig> = {
  renoworks_masking: {
    title: "Order Renoworks Design",
    image: "/assets/images/image-tools/renoworks.b4f1f801.jpg",
    link: (job: JobRecord, base_url: string) => {
      const visualizationUrl = getRenoworksLink();
      return (
        "https://www.renoworkspro.com/designrequest?job_id=" +
        job.id +
        "&email=" +
        job.assignments.first()?.user?.email +
        "&rw_site_id=" +
        visualizationUrl +
        "&mode=" +
        base_url
      );
    },
    paid: true,
    visible: (job: JobRecord, navDisplay: boolean) => {
      const visualizationUrl = getRenoworksLink();
      return !_.isEmpty(visualizationUrl) && visualizationUrl.indexOf("http") > -1 && isAddressValid(job.address);
    },
  },
  eagleview_order: {
    title: "Order EagleView",
    image: "/assets/images/image-tools/eagleview.7264a419.png",
    action: async (job: JobRecord) => {
      const form = document.createElement("form");
      form.action = "https://my.eagleview.com/Order.aspx";
      form.method = "POST";
      form.target = "_blank";
      form.setAttribute("style", "display: none;");

      const address = document.createElement("input");
      address.type = "text";
      address.name = "address";
      address.value = job.address.line_1;
      form.appendChild(address);

      const city = document.createElement("input");
      city.type = "text";
      city.name = "city";
      city.value = job.address.city;
      form.appendChild(city);

      const state = document.createElement("input");
      state.type = "text";
      state.name = "state";
      state.value = job.address.state;
      form.appendChild(state);

      const zip = document.createElement("input");
      zip.type = "text";
      zip.name = "zip";
      zip.value = job.address.postal_code;
      form.appendChild(zip);

      const submit = document.createElement("input");
      submit.type = "submit";
      submit.value = "Submit";
      form.appendChild(submit);

      document.body.appendChild(form);
      submit.click();

      document.body.removeChild(form);
    },
    paid: true,
    visible: (job: JobRecord, navDisplay: boolean) => {
      return navDisplay && isValidLatLon(job.address);
    },
  },
  eagleview_order_decra: {
    title: "Order EagleView: Decra",
    image: "/assets/images/image-tools/eagleview-decra.f196df2e.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.eagleview.com/decra/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  google_maps: {
    title: "Google Maps",
    image: "/assets/images/image-tools/google-maps.77fc6ed8.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "http://maps.google.com/maps?f=q&source=s_q&hl=en&q=" + job.address.lat + ",+" + job.address.lon + "&t=h";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isValidLatLon(job.address);
    },
  },
  bing_ortho: {
    title: "Bing Ortho",
    image: "/assets/images/image-tools/bing-maps.736170f1.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return (
        "http://maps.live.com/default.aspx?v=2&cp=" +
        job.address.lat +
        "~" +
        job.address.lon +
        "&sp=point." +
        job.address.lat +
        "_" +
        job.address.lon +
        "_Location&style=a&lvl=19"
      );
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isValidLatLon(job.address);
    },
  },
  bing_birdseye: {
    title: "Bing Birdseye",
    image: "/assets/images/image-tools/bing-maps.736170f1.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return (
        "http://maps.live.com/default.aspx?v=2&cp=" +
        job.address.lat +
        "~" +
        job.address.lon +
        "&sp=point." +
        job.address.lat +
        "_" +
        job.address.lon +
        "_Location&style=o&lvl=19"
      );
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isValidLatLon(job.address);
    },
  },
  zoom_earth: {
    title: "Zoom Earth",
    image: "/assets/images/image-tools/zoom-earth.5fd286a4.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "http://www.flashearth.com/?lat=" + job.address.lat + "&lon=" + job.address.lon + "&z=18&r=0&src=msl";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isValidLatLon(job.address);
    },
  },
  terra_server: {
    title: "Terra Server",
    image: "/assets/images/image-tools/terraserver.ebabb553.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return (
        "https://www.terraserver.com/view?utf8=%E2%9C%93&search_text=&searchLat=&searchLng=&lat=" +
        job.address.lat +
        "&lng=" +
        job.address.lon +
        "&bbox=&center="
      );
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isValidLatLon(job.address);
    },
  },
  pictometry_online: {
    title: "Pictometry Online",
    image: "/assets/images/image-tools/pictometry.ba413df1.jpg",
    paid: true,
    link: (job: JobRecord, base_url: string) => {
      return (
        "http://pol.pictometry.com/en-us/app/default.php?lat=" +
        job.address.lat +
        "&lon=" +
        job.address.lon +
        "&v=p&o=n&type=ob&level=n"
      );
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isValidLatLon(job.address);
    },
  },
  pictometry_connect: {
    title: "Pictometry Connect",
    image: "/assets/images/image-tools/pictometry.ba413df1.jpg",
    paid: true,
    link: (job: JobRecord, base_url: string) => {
      return (
        "http://explorer.pictometry.com/index.php?lat=" +
        job.address.lat +
        "&lon=" +
        job.address.lon +
        "&angle=Ob&zoom=18"
      );
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isValidLatLon(job.address);
    },
  },
  chi_overhead_door: {
    title: "C.H.I. Overhead Door",
    image: "/assets/images/image-tools/chi.2ee188c1.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://doorvisions.chiohd.com";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  map_developers_area: {
    title: "Map Developers Area Tool",
    image: "/assets/images/image-tools/measure-area.d8345cee.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return (
        "https://www.mapdevelopers.com/area_calculator_adv.php?zoom=19&lat=" +
        job.address.lat +
        "&lng=" +
        job.address.lon +
        "&height=600&width=600&square_meter=true&acres=true&square_mile=true&square_km=true"
      );
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isValidLatLon(job.address);
    },
  },
  ca_3d_demo: {
    title: "Chief Architect 3D Demo",
    image: "/assets/images/image-tools/chief-architect.3be4717b.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://accounts.chiefarchitect.com/viewer-uploads/embed/919911640610451?autoplay=true";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  daltile_demo: {
    title: "daltile 3D Demo",
    image: "/assets/images/image-tools/daltile-3d.b0358778.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://visualize.daltile.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  alside_renoworks: {
    title: "Alside Visualizer",
    image: "/assets/images/image-tools/alside-renoworks.abfa27b1.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://alside.renoworks.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  abbeycarpet_demo: {
    title: "Abbey Carpet Demo",
    image: "/assets/images/image-tools/abbey-carpet.d1f97bcd.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://abbeycarpet.chameleonpower.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  renuit_cabinet_demo: {
    title: "Renuit Cabinet Refacing Demo",
    image: "/assets/images/image-tools/renuit-cabinet.1e1567ba.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://renuit.renoworks.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  zillow: {
    title: "Zillow",
    image: "/assets/images/image-tools/zillow.2004fc7a.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.zillow.com/homes/" + zillowAddress(job.address);
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isAddressValid(job.address);
    },
  },
  trulia: {
    title: "Trulia",
    image: "/assets/images/image-tools/trulia.d59c3c65.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return (
        "https://www.trulia.com/submit_search/?display_select=for_sale&search=" +
        toOneLiner(job.address) +
        "&locationType=address&tst=h&ac_entered_query=" +
        toOneLiner(job.address) +
        "&ac_index=0&propertyIndex=sold&bedFilter=&bathFilter=&propertyFilter=&display=for+sale&maxPrice="
      );
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return isAddressValid(job.address);
    },
  },
  houzz_link: {
    title: "Houzz",
    image: "/assets/images/image-tools/houzz.090177e8.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://houzz.com/" + getIntegrationLink("houzz");
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return !_.isEmpty(getIntegrationLink("houzz"));
    },
  },
  home_advisor_link: {
    title: "Home Advisor",
    image: "/assets/images/image-tools/home-advisor.cef530c8.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return getIntegrationLink("home_advisor");
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return !_.isEmpty(getIntegrationLink("home_advisor"));
    },
  },
  angies_list_link: {
    title: "Angie's List",
    image: "/assets/images/image-tools/angies-list.d9d503d0.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return getIntegrationLink("angies_list");
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return !_.isEmpty(getIntegrationLink("angies_list"));
    },
  },
  gaf_quick_measure_link: {
    title: "GAF QuickMeasure",
    image: "/assets/images/image-tools/gaf-quickmeasure.1cce6eec.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://eave.us";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  gaf_smart_money_link: {
    title: "GAF SmartMoney",
    image: "/assets/images/image-tools/gaf-smart-money.6fee8391.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.payzer.com/Entry/Login";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  gaf_vhr_link: {
    title: "GAF Virtual Remodeler",
    image: "/assets/images/image-tools/gaf-vhr.8e7970e9.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://virtualremodeler.gaf.com";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  gaf_vent_calc_link: {
    title: "GAF Attic Ventilation Calculator",
    image: "/assets/images/image-tools/gaf-vent-calc.307968a7.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.gaf.com/en-us/for-professionals/tools/ventilation-calculator";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  greensky_link: {
    title: "GreenSky Financing",
    image: "/assets/images/image-tools/greensky-tool.b944e2e1.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://portal.greenskycredit.com/MerchantLogin#stay";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  wellsfargo_link: {
    title: "Wells Fargo",
    image: "/assets/images/image-tools/wellsfargo-link.add16f5c.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return getIntegrationLink("wells_fargo");
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return !_.isEmpty(getIntegrationLink("wells_fargo"));
    },
  },
  homeguard: {
    title: "HomeGuard Windows & Doors",
    image: "/assets/images/image-tools/homeguard-tool.f6cb5a7c.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "http://homeguard.renoworks.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  plygem_visualizer: {
    title: "PlyGem Visualizer",
    image: "/assets/images/image-tools/plygem-visualizer.07457cdb.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://plygemcorp.chameleonpower.com/Visualizer.aspx";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  oc_design_eyeq: {
    title: "Owens Corning DESIGN EyeQ",
    image: "/assets/images/image-tools/oc-design-eyeq.d1ba2eb1.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://roofvisualizer.owenscorning.com";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  gaf_vhr: {
    title: "GAF Virtual Remodeler",
    image: "/assets/images/image-tools/gaf-vhr.8e7970e9.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://virtualremodeler.gaf.com";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  colorview: {
    title: "CertainTeed Colorview",
    image: "/assets/images/image-tools/colorview.2ff6449d.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://colorview.certainteed.com";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  provia: {
    title: "ProVia EntryLink",
    image: "/assets/images/image-tools/provia.d881f989.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      const portalID = getProviaToken().portal_id;
      return `https://entrylink.provia.com/entrylink/PortalLogin.aspx?PortalID=${portalID}`;
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return getProviaToken().portal;
    },
  },
  provia_non_portal: {
    title: "ProVia EntryLink (Non-Portal)",
    image: "/assets/images/image-tools/provia.d881f989.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://entrylink.provia.com/entrylink/login.aspx";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return getProviaToken().non_portal;
    },
  },
  sketchup: {
    title: "Sketchup",
    image: "/assets/images/image-tools/sketchup.b207b481.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://app.sketchup.com/app?hl=en";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  global_transport_link: {
    title: "Global Transport VT",
    image: "/assets/images/image-tools/global-transport.29f79afc.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://vt.globalpay.com/admin/login.aspx";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  sigler_online_link: {
    title: "Sigler Online",
    image: "/assets/images/image-tools/sigler-online.72b50bc7.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.siglers.com/store/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  service_finance_link: {
    title: "Service Finance",
    image: "/assets/images/image-tools/service-finance.9aff4bc2.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.svcfin.com";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  ami_design_showcase_siding: {
    title: "AMI Design Showcase: Preservation Siding",
    image: "/assets/images/image-tools/ami-design-showcase.e5d2aced.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://sdspr.amidesignshowcases.com/app";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  ami_design_showcase_alside_window: {
    title: "AMI Design Showcase: Alside Windows",
    image: "/assets/images/image-tools/ami-design-showcase.e5d2aced.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://wdsal.amidesignshowcases.com/app";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  ami_design_showcase_pres_window: {
    title: "AMI Design Showcase: Preservation Windows",
    image: "/assets/images/image-tools/ami-design-showcase.e5d2aced.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://wdspr.amidesignshowcases.com/app";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  foundation_finance_dealer_link: {
    title: "Foundation Finance Company Dealer",
    image: "/assets/images/image-tools/foundation-finance-company.6b85ed63.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://foundationfinance.com/dealer-login/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  foundation_finance_link: {
    title: "Foundation Finance Company",
    image: "/assets/images/image-tools/foundation-finance-company.6b85ed63.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://sb7.compass-technologies.com:8158/k_dealer/f/104.html";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  advisar_link: {
    title: "Advisar",
    image: "/assets/images/image-tools/advisar-link.eeae2a05.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.advisar.com/residential/login";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  square_d_link: {
    title: "Square D",
    image: "/assets/images/image-tools/square-d-link.6eda3aa8.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://partner.schneider-electric.com/partners/Menu/Login";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  lp_smartside_visualizer: {
    title: "LP SmartSide Visualizer",
    image: "/assets/images/image-tools/lp-smartside-link.bb300452.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://lp2.renoworks.com/#projectId=&userId=&open=samples&share=https://www.upsidetosmartside.com/explore-ideas/visualize-home/samples&planner=https://www.upsidetosmartside.com/explore-ideas/visualize-home&login=https://www.upsidetosmartside.com/wishlist/?lpwaction=visualizer&api=https://www.upsidetosmartside.com/sfs-webservice&whereToBuy=https://www.upsidetosmartside.com";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  lp_smartside_link: {
    title: "LP BuildSmart",
    image: "/assets/images/image-tools/lp-smartside-link.bb300452.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://lpcorp.com/buildsmart";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  square_d_ezsel: {
    title: "Square D EZ Selector",
    image: "/assets/images/image-tools/square-d-ez-selector.b7db522b.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://ezlist.schneider-electric.com/?prod=E2.D01A&subset=LC&lang=en_US&apiKey=dbfs1df383";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  hearth_financing_link: {
    title: "Hearth Financing",
    image: "/assets/images/image-tools/hearth-financing-link.364c467f.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.gethearth.com/contractors/login";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  york_hvac_link: {
    title: "York HVAC",
    image: "/assets/images/image-tools/york-link.a75a1604.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.upgnet.com/Site/_mem_bin/FormsLogin.asp";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  sighten_solar: {
    title: "Sighten Solar",
    image: "/assets/images/image-tools/sighten-solar.3ef37e5d.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://engine.sighten.io/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  enerbank_link: {
    title: "EnerBank",
    image: "/assets/images/image-tools/enerbank.12483ec2.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "http://www.enerbank.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  pella_portal: {
    title: "Contractor Program Portal Login",
    image: "/assets/images/image-tools/pella-platinum.c06b8f5f.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "http://professional.pella.com/support/contractors";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  sunrise_configurator: {
    title: "Sunrise Windows",
    image: "/assets/images/image-tools/sunrise-configurator.bc624e33.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.sunrisewindows.com/visualizer/sunrise_ui.html";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  royal_homeplay: {
    title: "HomePlay by Royal",
    image: "/assets/images/image-tools/royal-homeplay.193e7c4d.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://homeplay.renoworks.com/#";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  sherwin_williams_colorsnap: {
    title: "Sherwin Williams COLORSNAP",
    image: "/assets/images/image-tools/sherwin-williams-colorsnap.08613b9b.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.sherwin-williams.com/visualizer";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  iko_roof_viewer: {
    title: "IKO Roof Viewer TM",
    image: "/assets/images/image-tools/iko-roof-viewer.ec37bd65.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://iko.chameleonpower.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  paragon_visualizer: {
    title: "Paragon Visualizer",
    image: "/assets/images/image-tools/paragon-door-visualizer.d4a1f5a1.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://sunrise.renoworks.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  timbertech_worry_less: {
    title: "TimberTech: Worry Less",
    image: "/assets/images/image-tools/timbertech-worry-less.5c2429d9.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.timbertech.com/imagine/stories/worry-less-about-your-deck-weathering-with-timbertech-decking";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  timbertech_deck_designer: {
    title: "TimberTech Deck Designer",
    image: "/assets/images/image-tools/timbertech-deck-designer.f7c1365b.jpg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://deckdesigner.timbertech.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  lowes_deck_designer: {
    title: "Lowe's Deck Designer",
    image: "/assets/images/image-tools/lowes-deck-designer.a17840f6.png",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://deckdesigner.lowes.com/";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  thermatru_design_your_door: {
    title: "ThermaTru Design Your Door",
    image: "/assets/images/image-tools/therma-tru-doors.f3115ad7.jpeg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.thermatru.com/explore-products/design-your-door";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
  pella_lowes_product_designer: {
    title: "Pella Lowe's Product Designer",
    image: "/assets/images/image-tools/pella-at-lowes.d7a83ac3.jpeg",
    paid: false,
    link: (job: JobRecord, base_url: string) => {
      return "https://www.pellaatlowes.com/product-designer";
    },
    visible: (job: JobRecord, navDisplay: boolean) => {
      return true;
    },
  },
};
