import * as ng from "angular";
import * as angulartics from "angulartics";
import { IRepository } from "app/src/Common/Repository";
import { IFlash } from "app/src/Common/FlashService";
import { ISession } from "app/src/Common/SessionService";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { IMeasurement } from "app/src/Models/Measurement";
import { IAddress } from "app/src/Models/Address";
import { toLatLng, fromJSON } from "app2/src/records/Address";
class SatelliteMeasureCtrl implements ng.IComponentController {
  [s: string]: any;
  public close: (params: any) => void;
  public dismiss: () => void;
  public resolve: { measurement: IMeasurement; address: IAddress };
  public measurement: IMeasurement;
  public new_geojson: any = {};
  public address: IAddress;
  public activeTab = 0;

  public static $inject = ["$scope", "Repository", "Session", "Flash", "BaseConfig", "$analytics", "$sce"];

  constructor(
    public $scope: ng.IScope,
    public Repository: IRepository,
    public Session: ISession,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
    public $analytics: angulartics.IAnalyticsService,
    public $sce: ng.ISCEService,
  ) {}

  public $onInit() {
    this.measurement = this.Repository.Measurement.fromJSON(this.resolve.measurement);
    this.address = this.resolve.address;
    this.new_geojson.leaflet = this.measurement.geojson.leaflet;
    this.new_geojson.leafletZoom = this.measurement.geojson.leafletZoom;
  }

  public save() {
    this.close({ $value: this.new_geojson });
  }

  public cancelBtn() {
    this.trackEvent("cancel button", {
      Category: "SatelliteMeasureComponent",
    });
    this.dismiss();
  }

  public exportData(data) {
    this.new_geojson.leaflet = data.points;
    this.new_geojson.leafletZoom = data.zoom;
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        measurement: this.measurement.id,
      }),
    );
  }

  public isValidAddress(address: IAddress): boolean {
    const addressRecord = fromJSON(address);
    return !_.isNull(toLatLng(addressRecord));
  }
}

export class SatelliteMeasureComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/RsfMap/satellite_measure_component.html";
  public bindings: any = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };

  constructor() {
    this.controller = SatelliteMeasureCtrl;
  }
}
