import * as React from "react";
import { User } from "oidc-client";
import { lowesTokenData } from "./Authentication.service";

export interface OidcViewProps {
  errorMsg: string;
  user: User;
}

export const OidcView: React.FunctionComponent<OidcViewProps> = (props) => {
  const { errorMsg, user } = props;
  return (
    <div className="form-section blank-state">
      <img src="/assets/images/lowes_logo.ece98ed5.png" />
      {errorMsg ? (
        <h2 data-testid="error-msg" className="text-danger">
          {errorMsg}
        </h2>
      ) : (
        <h2>Logging In...</h2>
      )}
      {errorMsg ? (
        <h3 data-testid="error-data" className="text-danger">
          {lowesTokenData(user.id_token)}
        </h3>
      ) : (
        <></>
      )}
    </div>
  );
};
