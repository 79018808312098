import * as L from "leaflet";
import "leaflet-draw";
import { Feature, FeatureCollection, Geometry } from "geojson";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";

let polygonIdx = 1;
let markerIdx = 1;
let drawnItems: L.FeatureGroup<any>;

export interface GeoJSONProperties {
  id: number;
  markers: Array<string>;
  area?: number;
  length;
  number;
}

let map: L.Map;

let doUpdate: (geoJSON: FeatureCollection) => void;
let layers = [];

export const setup = (
  containerId: string,
  updated?: (geoJSON: FeatureCollection) => void,
  onChangeStart?: () => void,
  onChangeEnd?: () => void,
): void => {
  if (containerId.length > 0) map = new L.Map(containerId, <L.MapOptions>{ zoomSnap: 0.1 });
  layers.forEach((layer) => {
    map.removeLayer(layer);
  });
  layers = [];
  layers = [
    L.gridLayer
      .googleMutant({
        type: "satellite",
      })
      .addTo(map),
    L.gridLayer
      .googleMutant({
        type: "roadmap",
        styles: [
          { featureType: "geometry", stylers: [{ visibility: "off" }] },
          { featureType: "road", stylers: [{ visibility: "off" }] },
          {
            featureType: "administrative.land_parcel",
            elementType: "geometry.stroke",
            stylers: [{ visibility: "on" }],
          },
        ],
      })
      .addTo(map),
  ];

  if (onChangeStart) {
    map.on("draw:editstart", () => {
      onChangeStart();
    });

    map.on("draw:drawstart", () => {
      onChangeStart();
    });

    map.on("draw:deletestart", () => {
      onChangeStart();
    });
  }

  if (onChangeEnd) {
    map.on("draw:drawstop", () => {
      onChangeEnd();
    });
    map.on("draw:editstop", () => {
      onChangeEnd();
    });

    map.on("draw:deletestop", () => {
      onChangeEnd();
    });
  }

  doUpdate = (geoJSON: FeatureCollection) => {
    if (updated) {
      updated(geoJSON);
    }
  };
};

export const setupAndExport = (cb?: (image) => void): void => {
  layers.forEach((layer) => {
    map.removeLayer(layer);
  });
  layers = [];
  map.createPane("snapshot-pane");
  map.createPane("dont-include");
  centerViewOnFeatures();
  const baselayer = L.tileLayer("https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
    maxZoom: 20,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
    pane: "snapshot-pane",
  }).addTo(map);

  layers.push(baselayer);
  baselayer.on("load", function () {
    exportMapAsImage().then(cb);
  });
};

export const exportMapAsImage = (): Promise<any> => {
  const snapshotOptions = {
    hideElementsWithSelectors: [".leaflet-control-container", ".leaflet-dont-include-pane", "#snapshot-button"],
    hidden: true,
    waitInterval: 1000,
  };
  const screenshotter: SimpleMapScreenshoter = new SimpleMapScreenshoter(snapshotOptions);
  screenshotter.addTo(map);
  return screenshotter.takeScreen("image");
};

/**
 * Used to setup the Drawing tool in Leaflet.  Can be used to reset the Draw Tool after an Export
 *
 * @param options options to enab/edisable in the Drawing Tool
 * @param reset if true, only the drawing tool enabling will run, another Control will not be added to the Map
 * @returns void
 */
export const setupDrawing = (options: Partial<L.Control.DrawOptions>, reset = false): void => {
  markerIdx = 1;
  const drawOptions: L.Control.DrawOptions = Object.assign(
    {
      rectangle: false,
      marker: false,
      circle: false,
      circlemarker: false,
      polyline: false,
      polygon: false,
    },
    options,
  );

  drawnItems = L.featureGroup().addTo(map);

  if (reset) {
    return;
  }

  map.addControl(
    new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
      },
      draw: drawOptions,
    }),
  );

  map.on(L.Draw.Event.CREATED, function (event: L.DrawEvents.Created) {
    const layer = event.layer;
    layer.feature = layer.feature || layer.toGeoJSON();

    drawnItems.addLayer(layer);

    layer.feature.properties.id = polygonIdx++;

    bindMarkers(layer);

    doUpdate(drawnItems.toGeoJSON() as FeatureCollection);
  });

  map.on(L.Draw.Event.EDITSTART, function () {
    map.eachLayer((l) => {
      l.unbindTooltip();
      if (l instanceof L.CircleMarker) {
        l.removeFrom(map);
      }
    });
  });

  map.on(L.Draw.Event.DELETESTART, function () {
    map.eachLayer((l) => {
      l.unbindTooltip();
      if (l instanceof L.CircleMarker) {
        l.removeFrom(map);
      }
    });
  });

  map.on(L.Draw.Event.DELETESTOP, function () {
    markerIdx = 1;
    map.eachLayer((l) => {
      bindMarkers(l);
    });
  });

  map.on(L.Draw.Event.DELETED, function () {
    markerIdx = 1;
    map.eachLayer((l) => {
      bindMarkers(l);
    });

    doUpdate(drawnItems.toGeoJSON() as FeatureCollection);
  });

  map.on(L.Draw.Event.EDITED, function () {
    markerIdx = 1;
    map.eachLayer((l) => {
      bindMarkers(l);
    });

    doUpdate(drawnItems.toGeoJSON() as FeatureCollection);
  });
};

export const lettersToNumber = (input: string): number => {
  return input.split("").reduce((sum, character) => sum * 26 + parseInt(character, 36) - 9, 0);
};

export const numberToLetters = (input: number): string => {
  let output = "";

  do {
    input -= 1;
    output = String.fromCharCode(65 + (input % 26)) + output;
    input = Math.floor(input / 26);
  } while (input > 0);

  return output;
};

export const centerView = (lat: number, lon: number): void => {
  map.setView(new L.LatLng(lat, lon), 20);
};

export const centerViewOnFeatures = (): void => {
  const points = allPoints();

  if (points.length <= 0) return;

  map.fitBounds(L.latLngBounds(points).pad(0.1));
};

const bindMarkers = (layer: L.Layer): void => {
  if (!(layer instanceof L.Polygon || layer instanceof L.Polyline)) {
    return;
  }
  let markers: Array<Array<number>> = [...layer.toGeoJSON().geometry.coordinates] as Array<Array<number>>;
  let mLength = markers.length;

  if (layer.feature.geometry.type === "Polygon") {
    markers = markers[0] as any;
    mLength = markers.length - 1;
  }

  if (!layer.feature.properties.id) {
    layer.feature.properties.id = polygonIdx++;
  } else {
    polygonIdx = Math.max(polygonIdx, layer.feature.properties.id + 1);
  }
  if (!layer.feature.properties.markers) {
    layer.feature.properties.markers = [];
  }

  for (let i = 0; i < mLength; i++) {
    layer.feature.properties.markers[i] = numberToLetters(markerIdx++);
    L.circleMarker([markers[i][1], markers[i][0]], { color: "green" })
      .bindTooltip(layer.feature.properties.markers[i], {
        permanent: true,
        className: "marker-tooltip",
        direction: "left",
      })
      .addTo(map);
  }
};

const allPoints = () => {
  const points = [];
  map.eachLayer((l) => {
    if (!(l instanceof L.Polygon || l instanceof L.Polyline)) {
      return;
    }
    if (l instanceof L.Polygon) {
      points.push(...(l.feature.geometry.coordinates[0] as any).map((a) => a.slice().reverse()));
      return;
    }

    if (l instanceof L.Polyline) {
      points.push(...l.feature.geometry.coordinates.map((a) => a.slice().reverse()));
      return;
    }
  });

  return points;
};

export const load = (geoJson: FeatureCollection): void => {
  markerIdx = 1;

  L.geoJSON(geoJson, {
    onEachFeature: (f: Feature<Geometry, GeoJSONProperties>, l) => {
      drawnItems.addLayer(l);
      bindMarkers(l);
    },
  }).addTo(map);
};

export const save = (): FeatureCollection => {
  const geoJSON: FeatureCollection<Geometry, any> = drawnItems.toGeoJSON() as FeatureCollection<Geometry, any>;

  return geoJSON;
};

export const downloadExport = (): void => {
  centerViewOnFeatures();

  setTimeout(() => {
    (map as any).downloadExport({
      fileName: "test.png",
      container: map.getContainer(),
      exclude: [".leaflet-control-zoom", ".leaflet-control-attribution", ".leaflet-tile-pane", ".leaflet-draw"],
    });
  }, 1000);
};
