import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { IOrgResponse } from "app/src/Models/Org";
import * as angulartics from "angulartics";

export class OrgChildrenTabCtrl extends BaseOrgTabCtrl {
  public orgResponse: IOrgResponse;
  public totalCount = 0;
  public params: any = {
    perPage: 25,
    currentPage: 1,
  };

  public static $inject = ["OrgFetcher", "$state", "$stateParams", "$analytics"];
  constructor(
    public OrgFetcher: IOrgFetcherService,
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    protected $analytics: angulartics.IAnalyticsService,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.query();
  }

  public query() {
    const queryParams = {
      parent_id: this.$stateParams["id"],
      page: this.params.currentPage,
      per_page: this.params.perPage,
      sort_by: "updated_at",
      sort_order: "desc",
    };

    if (this.params.search) {
      queryParams["query"] = this.params.search;
    }

    this.orgResponse = <IOrgResponse>this.OrgFetcher.Org.query(queryParams);

    //noinspection TypeScriptUnresolvedVariable
    this.orgResponse.$promise.then(() => {
      this.totalCount = this.orgResponse.meta.pagination.total_count;
    });
  }
}
