import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Row, Col } from "react-bootstrap";
import { org } from "app2/src/selectors/org.selectors";
import { rangeFinanceOptions } from "app2/src/selectors/financeOption.selectors";
import { calculatedFinancingsLoading, range } from "app2/src/selectors/calculatedFinancing.selectors";
import { estimateFinancedAmount } from "app2/src/selectors/estimate.selectors";
import { getFinanceName } from "app2/src/records/OrgRecord";

const mapStateToProps = (state: RootState, ownProps: RangeProps) => {
  const financedAmount = estimateFinancedAmount(state, ownProps);
  const financeOptions = rangeFinanceOptions(state, ownProps);

  const financeOptionIds = financeOptions.map((fo) => fo.id);
  let rangeString = "";
  const loading = calculatedFinancingsLoading(state, { financeOptionIds, financedAmount });
  if (!loading) {
    rangeString = range(state, { financedAmount, financeOptionIds });
  }

  return {
    loading,
    range: rangeString,
    org: org(state, ownProps),
  };
};

const connector = connect(mapStateToProps, null);

interface RangeProps {
  orgId: number;
  estimateId: number;
  asterisk: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RangeProps;

export class Range extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { range, loading, org, asterisk } = this.props;

    if (loading) {
      return (
        <div className="range-spinner" data-testid="inline-spinner">
          <SpinnerComponent inline localProperty={true} />
        </div>
      );
    }

    return (
      <Row>
        <Col className="text-center">
          <h1 className="mb-0">
            {range}
            {`${asterisk ? "*" : ""}`}
          </h1>
          <p>{` ${getFinanceName(org)}`}</p>
        </Col>
      </Row>
    );
  }
}

export default connector(Range);
