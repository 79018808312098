import { IOrg } from "app/src/Models/Org";
import { IRepository } from "app/src/Common/Repository";
import { useState, dispatch } from "app2/src/storeRegistry";
import * as userActions from "app2/src/reducers/user.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { UserRecord } from "app2/src/records/UserRecord";

export class ModelCache {
  public cache: any = {
    org: {},
  };

  public constructor(public Repository: IRepository) {}

  public getUser(userId: number): UserRecord {
    const cached = useState().getIn(["users", "usersById", userId]);
    if (cached) {
      return cached;
    }

    dispatch(userActions.AsyncActions.getUser(userId));

    return new UserRecord();
  }

  public getOrg(orgId: number): OrgRecord {
    const cached = useState().getIn(["orgs", "orgsById", orgId]);
    if (cached) {
      return cached;
    }

    dispatch(orgActions.AsyncActions.getOrg(orgId));

    return new OrgRecord();
  }
}
