import { Map, List } from "immutable";
import { FenceRecord } from "app2/src/records/measurements/Fence";
import { FenceSegmentRecord } from "app2/src/records/measurements/Segment";
import { createSelector } from "reselect";
import { RootState } from "app2/src/reducers";
import { segmentsById, dirty as fenceDirty } from "./segment.selectors";

export const rootKey = "measurementsFences";

/**
 * Returns the byId map
 *
 * @param {RootState} state
 * @returns Map<number, FenceRecord>
 */
export const fencesById = (state: RootState) => state.getIn([rootKey, "byId"]);
/**
 * returns the lastSavedById map
 * @param {RootState} state
 * @returns Map<number, FenceRecord>
 */
export const fencesLastSavedById = (state: RootState) => state.getIn([rootKey, "lastSavedById"]);

/**
 * Takes a fenceId and returns that fences List of segmentIds
 * @param {RootState} state
 * @param {{ fenceId: number }} props
 * @returns List<number>
 */
export const fenceSegmentIds = (state: RootState, props: { fenceId: number }) =>
  state.getIn([rootKey, "byId", props.fenceId, "segmentIds"], List());

/**
 * Takes a fenceId and returns that fences SegmentRecords
 *
 * @param {RootState} state
 * @param {{ fenceId: number }} props
 * @returns List<FenceSegementRecord>
 */
export const fenceSegments = createSelector(
  fenceSegmentIds,
  segmentsById,
  (segmentIds: List<number>, byId: Map<number, FenceSegmentRecord>) => {
    return (segmentIds || List()).map((id) => byId.get(id));
  },
);

/**
 * Takes a fenceId and returns the FenceRecord (normalized)
 *
 * @param {RootState} state
 * @param {{fenceId: number}} props
 * @returns FenceRecord
 */
export const fence = (state: RootState, props: { fenceId: number }) => state.getIn([rootKey, "byId", props.fenceId]);

/**
 * Takes a fenceId and returns the FenceRecord from the lastSavedById map (normalized)
 *
 * @param {RootState} state
 * @param {{ fenceId: number }} props
 * @returns FenceRecord
 */
export const fenceLastSaved = (state: RootState, props: { fenceId: number }) =>
  state.getIn([rootKey, "lastSavedById", props.fenceId]);

/**
 * Takes a fenceId and finds the fencingId if the fenceId exists in the byId map
 *
 * @param {RootState} state
 * @param {{ fenceId: number }} props
 * @returns {number} fencingId
 */
export const fenceFencingId = (state: RootState, props: { fenceId }) =>
  state.getIn([rootKey, "byId", props.fenceId, "fencingId"]);

/**
 * Takes a fenceId and checks to see if the FenceRecord is dirty
 * This does NOT look down the tree to Segments.
 *
 * @param {RootState} state
 * @param {{ fenceId: number }} params
 * @returns {boolean} dirty
 */
export const dirty = createSelector(fence, fenceLastSaved, (fence: FenceRecord, lastSaved: FenceRecord) => {
  if (!fence) return false;

  return !fence.equals(lastSaved);
});

/**
 * Takes a fenceId and checks to see if the FenceRecord is dirty
 * This DOES look down the tree to Segments.
 *
 * @param {RootState} state
 * @param {{ fenceId: number }} params
 * @returns {boolean} dirty
 */
export const dirtyTree = createSelector(
  dirty,
  fenceSegmentIds,
  (state, _) => ({ state }),
  (dirty: boolean, segmentIds: List<number>, stateProps: { state: RootState }) => {
    if (dirty) return dirty;

    const segmentsDirty = segmentIds.every((segmentId) => !fenceDirty(stateProps.state, { segmentId }));
    return !segmentsDirty;
  },
);
