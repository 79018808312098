import * as React from "react";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import { Form, Col, Row } from "react-bootstrap";
import { useAudioInputDevices } from "../hooks/deviceHooks";
import LocalAudioLevelIndicator from "app2/src/components/Twilio/components/LocalAudioLevelIndicator";
import { useTracking } from "react-tracking";
import { useLocalStorage } from "app2/src/hooks/useLocalStorage";
import { LocalStorageKeys } from "../../VideoProvider/types";

export default function AudioInputList(): JSX.Element {
  const audioInputDevices = useAudioInputDevices();
  const {
    room: { localParticipant },
    localTracks,
    getLocalAudioTrack,
  } = useVideoContext();
  const { trackEvent } = useTracking();
  const [selectedDevice, setSelectedDevice] = useLocalStorage<string>(LocalStorageKeys.AudioInputId, null);

  const localAudioTrack = localTracks.find((track) => track.kind === "audio");

  function replaceTrack(newDeviceId: string) {
    if (localAudioTrack) {
      localAudioTrack.stop();
    }

    setSelectedDevice(newDeviceId);

    getLocalAudioTrack().then((newTrack) => {
      if (!newTrack) return;
      trackEvent({
        action: "changeAudioInput",
        audioInputName: newTrack?.mediaStreamTrack?.label,
        audioInputId: newTrack?.id,
      });
      if (localParticipant) {
        if (localAudioTrack) {
          const localTrackPublication = localParticipant.unpublishTrack(localAudioTrack);
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant.emit("trackUnpublished", localTrackPublication);
        }
        localParticipant.publishTrack(newTrack);
      }
    });
  }

  return (
    <Row>
      <Col xs={11}>
        <Form.Label>Audio Input</Form.Label>
        {audioInputDevices.length > 1 ? (
          <Form.Control
            as="select"
            onChange={(e: any) => replaceTrack(e.target.value as string)}
            value={selectedDevice || ""}>
            {audioInputDevices.map((device) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </option>
            ))}
          </Form.Control>
        ) : (
          <p>{localAudioTrack?.mediaStreamTrack.label || "No Local Audio"}</p>
        )}
      </Col>
      <Col xs={1} className="audio-select-icon">
        <LocalAudioLevelIndicator />
      </Col>
    </Row>
  );
}
