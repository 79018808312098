import { ActionsUnion, createAction } from "./Utils";
import { IRoofFaceData } from "../records/RoofFace";

export const RECEIVE_ROOF_FACE = "@roofFaces/RECEIVE_ROOF_FACE";

export const Actions = {
  receiveRoofFace: (roofFace: Partial<IRoofFaceData>) => createAction(RECEIVE_ROOF_FACE, { roofFace }),
};

export type Actions = ActionsUnion<typeof Actions>;
