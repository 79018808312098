import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import BreadCrumbs from "app2/src/components/Common/BreadCrumbs";
import { ancestors } from "app2/src/selectors/folder.selectors";
import { Row, Col } from "react-bootstrap";

const mapStateToProps = (state: RootState, ownProps: BreadCrumbWrapperProps) => {
  return {
    parents: ancestors(state, { folderId: ownProps.folderParentId }).slice(1),
  };
};

const connector = connect(mapStateToProps, null);

interface BreadCrumbWrapperProps {
  folderParentId: number;
  navigate: (id: number) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & BreadCrumbWrapperProps;

class BreadCrumbWrapper extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { folderParentId, parents, navigate } = this.props;
    return (
      <Row>
        <Col>
          <h4>
            <BreadCrumbs showRoot={!!folderParentId} parents={parents} navigate={navigate} />
          </h4>
        </Col>
      </Row>
    );
  }
}

export default connector(BreadCrumbWrapper);
