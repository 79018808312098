import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { IOrg, OrgPrefType } from "app/src/Models/Org";
import { BaseTabCtrl } from "app/src/Jobs/tabs/BaseTabCtrl";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IVisualization, IVisualizationResource } from "app/src/Models/Visualization";
import { ChameleonService } from "app/src/Visualization/Chameleon/ChameleonService";
import { ChameleonV2Service } from "app/src/Visualization/Chameleon/ChameleonV2Service";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import * as angulartics from "angulartics";
import { IToolInfo } from "app/src/Common/ToolInfoService";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { IVisualizationConfig } from "../../Models/OrgSetting";
import * as tokenActions from "app2/src/reducers/token.actions";
import { dispatch } from "app2/src/storeRegistry";

export class DesignToolTabCtrl extends BaseTabCtrl {
  public selectedVisualization: IVisualization;
  public org: IOrg;
  public visualizationUrl: any;
  public showVisualizationIFrame = false;
  public showRenoworksIFrame = false;
  public fullscreen = false;
  public jobLoaded = false;
  public toolIFrameUrl: any;
  public showToolIFrame = false;
  public sortableOptions = {
    placeholder: "card-placeholder col-md-4",
    helper: "clone",
    items: "> .sortable-card",
    distance: 15,
    start: (e, ui) => {
      ui.placeholder.height(ui.item.height());
    },
    stop: () => {
      this.orderVisualizations();
    },
  };
  public toolsLoaded = false;

  private _chameleonService;
  private _chameleonV2Service;
  private _eventListener;

  public static $inject = [
    "JobFetcher",
    "Visualization",
    "Flash",
    "$sce",
    "$stateParams",
    "$q",
    "BaseConfig",
    "$scope",
    "$rootScope",
    "$window",
    "$analytics",
    "ToolInfo",
    "Pretty",
    "$uibModal",
  ];
  constructor(
    public JobFetcher: IJobFetcherService,
    public Visualization: IVisualizationResource,
    public Flash: IFlash,
    public $sce: ng.ISCEService,
    public $stateParams: ng.ui.IStateParamsService,
    public $q: ng.IQService,
    public BaseConfig: IBaseConfig,
    private $scope: ng.IScope,
    public $rootScope: RsfRootScope,
    public $window: ng.IWindowService,
    protected $analytics: angulartics.IAnalyticsService,
    public ToolInfo: IToolInfo,
    public Pretty: IPretty,
    public $uibModal: ng.ui.bootstrap.IModalService,
  ) {
    super(JobFetcher, $scope, $analytics, $window, $stateParams["id"]);

    // Chameleon V1 - GAF
    this._chameleonService = new ChameleonService(Visualization, $q);
    this._eventListener = (e) => {
      this._chameleonService.handleEvents(this.job, e, this.selectedVisualization).then((result: IVisualization) => {
        if (!result) {
          return;
        }

        this.spinnerPromise = result.$promise;
        this.showVisualization(result);
      });
    };

    // Chameleon V2 - OCC
    this._chameleonV2Service = new ChameleonV2Service(Visualization, $q);
    this._eventListener = (e) => {
      this._chameleonV2Service.handleEvents(this.job, e, this.selectedVisualization).then((result: IVisualization) => {
        if (!result) {
          return;
        }

        this.spinnerPromise = result.$promise;
        this.showVisualization(result);
      });
    };

    // Global Items
    $window.addEventListener("message", this._eventListener);

    $scope.$on("$destroy", () => {
      $window.removeEventListener("message", this._eventListener);
    });

    this.job.$promise.then(() => {
      this.org = this.job.org;
      this.jobLoaded = true;
      this.loadTools().then(() => {
        this.toolsLoaded = true;
        this.$scope.$digest();
      });
      dispatch(tokenActions.AsyncActions.getToken(this.org.id, "provia"));
      dispatch(tokenActions.AsyncActions.getToken(this.org.id, "renoworks"));
    });
  }

  public showIFrame(name: string) {
    if (name === "renoworks") {
      this.showRenoworksIFrame = true;
      return true;
    }

    this.toolIFrameUrl = this.$sce.trustAsResourceUrl(this.ToolInfo.tools(name).link(this.job));
    this.showToolIFrame = true;
  }

  public close() {
    this.selectedVisualization = null;

    this.showRenoworksIFrame = false;
    this.showVisualizationIFrame = false;
    this.showToolIFrame = false;
  }

  // noinspection JSUnusedGlobalSymbols
  public showingIFrame() {
    return this.showToolIFrame || this.showRenoworksIFrame || this.showVisualizationIFrame;
  }

  // noinspection JSUnusedGlobalSymbols
  public fullScreen() {
    this.fullscreen = !this.fullscreen;
  }

  public startNewVisualization(kind: string) {
    this.selectedVisualization = null;

    switch (kind) {
      case "chameleon":
        this.visualizationUrl = this.$sce.trustAsResourceUrl(
          this.Visualization.buildNewProjectUrl(
            kind,
            this.org.fetchPref<IVisualizationConfig>(OrgPrefType.visualization).chameleon.code,
            this.job.address.postal_code,
            "",
            "",
          ),
        );
        break;
      case "chameleon_v2":
        this.visualizationUrl = this.$sce.trustAsResourceUrl(
          this.Visualization.buildNewProjectUrl(
            kind,
            "",
            this.job.address.postal_code,
            this.org.fetchPref<IVisualizationConfig>(OrgPrefType.visualization).chameleon.url,
            this.job.assignedUser().email,
          ),
        );
        break;
    }

    this.showVisualizationIFrame = true;
  }

  public showVisualization(vis: IVisualization) {
    this.selectedVisualization = vis;

    this.visualizationUrl = this.$sce.trustAsResourceUrl(this.ToolInfo.tools(vis.kind).visualization(this.job, vis));

    if (vis.kind === "renoworks") {
      this.showRenoworksIFrame = true;
    } else {
      this.showVisualizationIFrame = true;
    }

    // Event category & action used for analytics job reporting - do not rename and keep upon refactor
    this.trackEvent("Show Visualization", {
      category: "Design Tools",
      visualization: vis.id,
    });
  }

  // noinspection JSUnusedGlobalSymbols
  public deleteVisualization(vis: IVisualization) {
    vis
      .$delete()
      .then(() => {
        this.job.visualizations = _.select(this.job.visualizations, (v: IVisualization) => {
          return vis.id !== v.id;
        });
        this.Flash.addMessage(FlashLevels.success, "Visualization successfully deleted.");
        this.trackEvent("Deleted Visualization", {
          category: "Design Tools",
          visualization: vis.id,
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems deleting the visualization.");
        this.trackEvent("Visualization Deleted Successfully", {
          category: "Design Tools",
          visualization: vis.id,
        });
      });
  }

  // noinspection JSUnusedGlobalSymbols
  public addVisualization() {
    const newVis = this.Visualization.fromJSON({
      job_id: this.job.id,
      sort_order: _.getSortOrder(this.job.visualizations),
    });
    this.visualizationModal(newVis).result.then((vis) => {
      if (this.ToolInfo.tools(vis.kind).api) {
        this.startNewVisualization(vis.kind);
      } else {
        this.spinnerPromise = vis
          .$create()
          .then(() => {
            this.JobFetcher.refresh(this.job.id);
            this.Flash.addMessage(FlashLevels.success, "Visualization successfully added.");
            this.trackEvent("Added Visualization Successfully", {
              category: "Design Tools",
              visualization: vis.id,
            });
            return this.job.$promise;
          })
          .catch(() => {
            this.Flash.addMessage(FlashLevels.danger, "There were problems adding the visualization.");
            this.trackEvent("Visualization Add Failed", {
              category: "Design Tools",
              visualization: vis.id,
            });
          });
      }
    });
  }

  // noinspection JSUnusedGlobalSymbols
  public editVisualization(vis: IVisualization) {
    const editVis = this.Visualization.fromJSON(vis);
    this.visualizationModal(editVis).result.then((edited_vis) => {
      this.spinnerPromise = edited_vis
        .$save()
        .then(() => {
          vis.name = edited_vis.name;
          if (!this.ToolInfo.tools(vis.kind).api) {
            vis.data.id = edited_vis.data.id;
          }
          this.Flash.addMessage(FlashLevels.success, "Visualization successfully edited.");
          this.trackEvent("Edited Visualization Successfully", {
            category: "Design Tools",
            visualization: vis.id,
          });
        })
        .catch(() => {
          this.Flash.addMessage(FlashLevels.danger, "There were problems editing the visualization.");
          this.trackEvent("Visualization Edit Failed", {
            category: "Design Tools",
            visualization: vis.id,
          });
        });
    });
  }

  // noinspection JSUnusedGlobalSymbols
  public emailVisualization(vis: IVisualization) {
    vis
      .$email()
      .then(() => {
        this.Flash.addMessage(FlashLevels.success, "Visualization successfully emailed.");
        this.trackEvent("Emailed Visualization Successfully", {
          category: "Design Tools",
          visualization: vis.id,
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems emailing the visualization.");
        this.trackEvent("Email Visualization Failed", {
          category: "Design Tools",
          visualization: vis.id,
        });
      });
  }

  public visualizationModal(vis: IVisualization) {
    return this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "src/Jobs/tabs/visualization_modal.html",
      controller: "VisualizationModalCtrl",
      backdrop: "static",
      controllerAs: "$ctrl",
      size: "md",
      resolve: {
        job: this.job,
        visualization: vis,
      },
    });
  }

  public orderVisualizations() {
    this.job.visualizations.map((v, index) => {
      if (v.sort_order !== index) {
        v.sort_order = index;
        v.$save();
      }
    });
    this.job.visualizations = _.sortBy(this.job.visualizations, "sort_order");
  }
}
