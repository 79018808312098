import * as measurementActions from "./measurement.actions";
import { fromJS, List, Map, Record } from "immutable";
import {
  MeasurementRecord,
  fromJSON,
  IMeasurementRecord,
  defaultMeasurementProps,
  lineMeasurementUpdate,
  sunroomUpdate,
  sunroomKneeWallUpdate,
  getUoM,
  calculateArea,
} from "../records/Measurement";
import { RootActions, RootState } from ".";
import { fetch, loaded, receive, reset } from "app2/src/reducers/Reducer";

import * as roofFaceActions from "app2/src/reducers/roofFace.actions";
import { reducer as roofFaceReducer } from "app2/src/reducers/roofFace.reducer";

import * as wallFacadeActions from "app2/src/reducers/wallFacade.actions";
import { reducer as wallFacadeReducer } from "app2/src/reducers/wallFacade.reducer";

import * as windowActions from "app2/src/reducers/window.actions";
import { reducer as windowReducer } from "app2/src/reducers/window.reducer";

import * as doorActions from "app2/src/reducers/door.actions";
import { reducer as doorReducer } from "app2/src/reducers/door.reducer";

import * as roomActions from "app2/src/reducers/room.actions";
import { reducer as roomReducer } from "app2/src/reducers/room.reducer";

import * as elevationActions from "app2/src/reducers/elevation.actions";
import { reducer as elevationReducer } from "app2/src/reducers/elevation.reducer";

import * as deckActions from "app2/src/reducers/deck.actions";
import { reducer as deckReducer } from "app2/src/reducers/deck.reducer";

import * as poolActions from "app2/src/reducers/pool.actions";
import { reducer as poolReducer } from "app2/src/reducers/pool.reducer";

import { addMeasurementForm, currentMeasurement, rootKey } from "app2/src/selectors/measurement.selectors";
import { unWindAngular } from "app2/src/records/Elevation";
import { IElevation } from "app/src/Models/Elevation";
import { calculateOpeningArea, calculateOpeningUI } from "app2/src/records/Opening";

export const defaultAddMeasurementForm = Map({
  quantity: 1,
  name: "",
  width: 0,
  height: 0,
  area: 0,
  ui: 0,
  perimeter: 0,
  kind: "windows",
  level: "",
  location: "",
});

export const MeasurementStateRecord = Record({
  byId: Map<number, MeasurementRecord>(),
  lastSavedById: Map<number, MeasurementRecord>(),
  unsavedId: -1,
  editing: false,
  addMeasurementForm: defaultAddMeasurementForm,
});

export const initialState = MeasurementStateRecord();

export type MeasurementState = typeof initialState;

export const model = rootKey;
export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let measurement: MeasurementRecord;
  switch (action.type) {
    case measurementActions.FETCH_MEASUREMENT:
      return fetch(state, model, fromJSON({ id: action.payload.measurementId }));

    case measurementActions.RECEIVE_MEASUREMENT:
      const payloadMeasurement = action.payload.measurement;
      measurement = fromJSON({ ...payloadMeasurement });

      payloadMeasurement.roof_faces.forEach((roofFace) => {
        state = roofFaceReducer(state, roofFaceActions.Actions.receiveRoofFace(roofFace));
      });

      payloadMeasurement.wall_facades.forEach((wallFacade) => {
        state = wallFacadeReducer(state, wallFacadeActions.Actions.receiveWallFacade(wallFacade));
      });

      payloadMeasurement.windows.forEach((window) => {
        state = windowReducer(state, windowActions.Actions.receiveWindow(window));
      });

      payloadMeasurement.doors.forEach((door) => {
        state = doorReducer(state, doorActions.Actions.receiveDoor(door));
      });

      payloadMeasurement.rooms.forEach((room) => {
        state = roomReducer(state, roomActions.Actions.receiveRoom(room));
      });

      if (payloadMeasurement.elevations) {
        payloadMeasurement.elevations.forEach((elevation) => {
          state = elevationReducer(state, elevationActions.Actions.receiveElevation(elevation));
        });
      }

      payloadMeasurement.decks.forEach((deck) => {
        state = deckReducer(state, deckActions.Actions.receiveDeck(deck));
      });

      payloadMeasurement.pools.forEach((pool) => {
        state = poolReducer(state, poolActions.Actions.receivePool(pool));
      });

      return receive(state, model, measurement);

    case measurementActions.SET_MEASUREMENT_LOADED:
      return loaded(state, model, action.payload.measurementId);

    case measurementActions.UPDATE_FORM:
      const { rootPath, name } = action.payload.event;
      let { value } = action.payload.event;
      const nameArray = name.split(".");
      const lastName = nameArray[nameArray.length - 1];

      if (typeof defaultMeasurementProps[lastName] === "number") {
        value = _.round(+value || 0, -2);
      }

      state = state.setIn(rootPath.concat(name.split(".")), value);

      if (lastName === "roof_total_area") {
        state = reducer(state, measurementActions.Actions.updateRoofWaste());
      }

      if (lastName === "siding_total_area") {
        state = reducer(state, measurementActions.Actions.updateSidingWaste());
      }

      if (lastName === "roof_sloped_area") {
        state = reducer(state, measurementActions.Actions.updateSlopedRoofWaste());
      }

      if (lineMeasurementUpdate.includes(lastName)) {
        state = reducer(state, measurementActions.Actions.updateLineMeasurements());
      }

      if (sunroomUpdate.includes(lastName)) {
        state = reducer(state, measurementActions.Actions.updateSunroom());
      }

      if (sunroomKneeWallUpdate.includes(lastName)) {
        state = reducer(state, measurementActions.Actions.updateSunroomKneeWall());
      }

      return state;

    case measurementActions.UPDATE_ROOF_WASTE:
      measurement = currentMeasurement(state, {});
      const percents = [5, 10, 12, 15, 20];
      percents.forEach((percent) => {
        const key = `roof_${percent}pw_total_area` as keyof IMeasurementRecord;
        const value = _.round(measurement.get("roof_total_area") * (1 + percent / 100), -2);
        measurement = measurement.set(key, value);
      });
      return state.setIn([model, "byId", measurement.get("id")], measurement);

    case measurementActions.UPDATE_SLOPED_ROOF_WASTE:
      measurement = currentMeasurement(state, {});
      const slopedPercents = [10];
      slopedPercents.forEach((percent) => {
        const key = `roof_${percent}pw_sloped_area` as keyof IMeasurementRecord;
        const value = _.round(measurement.get("roof_sloped_area") * (1 + percent / 100), -2);
        measurement = measurement.set(key, value);
      });
      return state.setIn([model, "byId", measurement.get("id")], measurement);

    case measurementActions.UPDATE_LINE_MEASUREMENTS:
      measurement = currentMeasurement(state, {});
      measurement = measurement
        // perimeter (rake + eave)
        .set("roof_perimeter", _.round(measurement.get("roof_eave") + measurement.get("roof_rake"), -2))
        // ridge & hip (ridge + hip)
        .set("roof_ridge_hip", _.round(measurement.get("roof_ridge") + measurement.get("roof_hip"), -2))
        // total flashing (step + headwall)
        .set(
          "roof_total_flashing",
          _.round(measurement.get("roof_step_flashing") + measurement.get("roof_headwall_flashing"), -2),
        )
        // valley & eave (valley + eave)
        .set("roof_valley_eave", _.round(measurement.get("roof_valley") + measurement.get("roof_eave"), -2))
        // valley, eave, rake (valley + eave + rake)
        .set(
          "roof_valley_eave_rake",
          _.round(measurement.get("roof_valley") + measurement.get("roof_eave") + measurement.get("roof_rake"), -2),
        );

      return state.setIn([model, "byId", measurement.get("id")], measurement);

    case measurementActions.UPDATE_SIDING_WASTE:
      measurement = currentMeasurement(state, {});
      const sidingPercents = [5, 10, 15, 18];
      sidingPercents.forEach((percent) => {
        const key = `siding_${percent}pw_total_area` as keyof IMeasurementRecord;
        const value = _.round(measurement.get("siding_total_area") * (1 + percent / 100), -2);
        measurement = measurement.set(key, value);
      });
      return state.setIn([model, "byId", measurement.get("id")], measurement);

    case measurementActions.UPDATE_SUNROOM:
      measurement = currentMeasurement(state, {});
      measurement = measurement.set(
        "sunroom_wall_perimeter",
        _.round(
          measurement.get("sunroom_a_wall_length") +
            measurement.get("sunroom_b_wall_length") +
            measurement.get("sunroom_c_wall_length"),
          -2,
        ),
      );

      if (measurement.get("sunroom_kw_a_length") === 0) {
        measurement = measurement.set("sunroom_kw_a_length", measurement.get("sunroom_a_wall_length"));
      }

      if (measurement.get("sunroom_kw_b_length") === 0) {
        measurement = measurement.set("sunroom_kw_b_length", measurement.get("sunroom_b_wall_length"));
      }

      if (measurement.get("sunroom_kw_c_length") === 0) {
        measurement = measurement.set("sunroom_kw_c_length", measurement.get("sunroom_c_wall_length"));
      }

      const maxWidth = _.max(
        [measurement.get("sunroom_a_wall_length"), measurement.get("sunroom_b_wall_length")],
        function (value: number) {
          return value;
        },
      );

      // Deck Total Area
      measurement = measurement.set(
        "sunroom_deck_total_area",
        _.round(maxWidth * measurement.get("sunroom_c_wall_length"), -2),
      );

      // Ceiling Area
      measurement = measurement
        .set("sunroom_ceiling_area", _.round(measurement.get("sunroom_deck_total_area") * 1.2, -2))
        // Studio Roof Area
        .set("sunroom_studio_roof_area", _.round(measurement.get("sunroom_deck_total_area") * 1.25, -2))
        // Cathedral Roof Area
        .set("sunroom_cathedral_roof_area", _.round(measurement.get("sunroom_deck_total_area") * 1.3, -2));

      state = state.setIn([model, "byId", measurement.get("id")], measurement);
      return reducer(state, measurementActions.Actions.updateSunroomWall());

    case measurementActions.UPDATE_SUNROOM_WALL:
      measurement = currentMeasurement(state, {});
      measurement = measurement
        .set(
          "sunroom_a_wall_area",
          calculateArea(
            measurement.get("sunroom_a_wall_height"),
            measurement.get("sunroom_a_wall_length"),
            getUoM(measurement)["sunroom_a_wall_height"],
          ),
        )
        .set(
          "sunroom_b_wall_area",
          calculateArea(
            measurement.get("sunroom_b_wall_height"),
            measurement.get("sunroom_b_wall_length"),
            getUoM(measurement)["sunroom_b_wall_height"],
          ),
        )
        .set(
          "sunroom_c_wall_area",
          calculateArea(
            measurement.get("sunroom_c_wall_height"),
            measurement.get("sunroom_c_wall_length"),
            getUoM(measurement)["sunroom_c_wall_height"],
          ),
        );

      measurement = measurement.set(
        "sunroom_wall_total_area",
        _.round(
          measurement.get("sunroom_a_wall_area") +
            measurement.get("sunroom_b_wall_area") +
            measurement.get("sunroom_c_wall_area"),
          -2,
        ),
      );

      return state.setIn([model, "byId", measurement.get("id")], measurement);

    case measurementActions.UPDATE_SUNROOM_KNEE_WALL:
      measurement = currentMeasurement(state, {});
      measurement = measurement
        .set(
          "sunroom_kw_total_length",
          measurement.get("sunroom_kw_a_length") +
            measurement.get("sunroom_kw_b_length") +
            measurement.get("sunroom_kw_c_length"),
        )
        .set(
          "sunroom_kw_a_area",
          calculateArea(
            measurement.get("sunroom_kw_a_height"),
            measurement.get("sunroom_kw_a_length"),
            getUoM(measurement)["sunroom_kw_a_height"],
          ),
        )
        .set(
          "sunroom_kw_b_area",
          calculateArea(
            measurement.get("sunroom_kw_b_height"),
            measurement.get("sunroom_kw_b_length"),
            getUoM(measurement)["sunroom_kw_b_height"],
          ),
        )
        .set(
          "sunroom_kw_c_area",
          calculateArea(
            measurement.get("sunroom_kw_c_height"),
            measurement.get("sunroom_kw_c_length"),
            getUoM(measurement)["sunroom_kw_c_height"],
          ),
        );

      measurement = measurement.set(
        "sunroom_kw_total_area",
        _.round(
          measurement.get("sunroom_kw_a_area") +
            measurement.get("sunroom_kw_b_area") +
            measurement.get("sunroom_kw_c_area"),
          -2,
        ),
      );
      return state.setIn([model, "byId", measurement.get("id")], measurement);

    case measurementActions.UPDATE_RAPID_AREA:
      measurement = currentMeasurement(state, {})
        .setIn(["geojson", "rapid_area"], fromJS(action.payload.rapidArea))
        .set("roof_total_area", action.payload.rapidArea[0].properties.measurements.pitched_area);
      state = state.setIn([model, "byId", measurement.get("id")], measurement);
      return reducer(state, measurementActions.Actions.updateRoofWaste());

    case measurementActions.UPDATE_SATELLITE:
      measurement = currentMeasurement(state, {})
        .setIn(["geojson", "leaflet"], fromJS(action.payload.leaflet))
        .setIn(["geojson", "leafletZoom"], action.payload.leafletZoom);
      return state.setIn([model, "byId", measurement.get("id")], measurement);

    case measurementActions.UPDATE_POOLS:
      measurement = currentMeasurement(state, {});
      let poolIds = List();
      action.payload.pools.forEach((pool) => {
        if (_.isNaN(parseInt(pool.id as any))) {
          pool.id = state.getIn(["pools", "unsavedId"]);
          state = state.setIn(["pools", "unsavedId"], pool.id - 1);
        }
        poolIds = poolIds.push(pool.id);
        state = poolReducer(state, poolActions.Actions.editPool(pool));
      });
      return state.setIn([model, "byId", measurement.get("id"), "pool_ids"], poolIds);

    case measurementActions.UPDATE_DECKS:
      measurement = currentMeasurement(state, {});
      let deckIds = List();
      action.payload.decks.forEach((deck) => {
        if (_.isNaN(parseInt(deck.id as any))) {
          deck.id = state.getIn(["decks", "unsavedId"]);
          state = state.setIn(["decks", "unsavedId"], deck.id - 1);
        }
        deckIds = deckIds.push(deck.id);
        state = deckReducer(state, deckActions.Actions.editDeck(deck));
      });
      return state.setIn([model, "byId", measurement.get("id"), "deck_ids"], deckIds);

    case measurementActions.UPDATE_WINDOWS:
      measurement = currentMeasurement(state, {});
      let windowIds = List();
      action.payload.windows.forEach((window) => {
        if (_.isNaN(parseInt(window.id as any))) {
          window.id = state.getIn(["windows", "unsavedId"]);
          state = state.setIn(["windows", "unsavedId"], window.id - 1);
        }
        windowIds = windowIds.push(window.id);
        state = windowReducer(state, windowActions.Actions.editWindow(window));
      });
      return state.setIn([model, "byId", measurement.get("id"), "window_ids"], windowIds);

    case measurementActions.UPDATE_DOORS:
      measurement = currentMeasurement(state, {});
      let doorIds = List();
      action.payload.doors.forEach((door) => {
        if (_.isNaN(parseInt(door.id as any))) {
          door.id = state.getIn(["doors", "unsavedId"]);
          state = state.setIn(["doors", "unsavedId"], door.id - 1);
        }
        doorIds = doorIds.push(door.id);
        state = doorReducer(state, doorActions.Actions.editDoor(door));
      });
      return state.setIn([model, "byId", measurement.get("id"), "door_ids"], doorIds);

    case measurementActions.UPDATE_ELEVATIONS:
      measurement = currentMeasurement(state, {});
      let elevationIds = List();
      action.payload.elevations.forEach((elevation) => {
        elevationIds = elevationIds.push(elevation.id);
        const newElevation = unWindAngular(elevation as unknown as IElevation);
        state = elevationReducer(state, elevationActions.Actions.receiveElevation(newElevation));
      });
      return state
        .setIn([model, "byId", measurement.get("id"), "elevation_ids"], elevationIds)
        .setIn([model, "lastSavedById", measurement.get("id"), "elevation_ids"], elevationIds);

    case measurementActions.UPDATE_WALL_FACADES:
      measurement = currentMeasurement(state, {});
      let wallFacadeIds = List();
      action.payload.wallFacades.forEach((wall) => {
        if (_.isNaN(parseInt(wall.id as any))) {
          wall.id = state.getIn(["wallFacades", "unsavedId"]);
          state = state.setIn(["wallFacades", "unsavedId"], wall.id - 1);
        }
        wallFacadeIds = wallFacadeIds.push(wall.id);
        state = wallFacadeReducer(state, wallFacadeActions.Actions.editWallFacade(wall));
      });
      return state.setIn([model, "byId", measurement.get("id"), "wall_facade_ids"], wallFacadeIds);
    case measurementActions.UPDATE_ROOMS:
      measurement = currentMeasurement(state, {});
      let roomIds = List();
      action.payload.rooms.forEach((room) => {
        if (_.isNaN(parseInt(room.id as any))) {
          room.id = state.getIn(["rooms", "unsavedId"]);
          state = state.setIn(["rooms", "unsavedId"], room.id - 1);
        }
        roomIds = roomIds.push(room.id);
        state = roomReducer(state, roomActions.Actions.editRoom(room));
      });
      return state.setIn([model, "byId", measurement.get("id"), "room_ids"], roomIds);

    case measurementActions.RESET_MEASUREMENT:
      measurement = currentMeasurement(state, {});
      return reset(state, model, measurement.id);

    case measurementActions.SET_EDITING:
      return state.setIn([model, "editing"], action.payload.value);

    case measurementActions.INIT_ADD_MEASUREMENT:
      return state.setIn([model, "addMeasurementForm"], defaultAddMeasurementForm);

    case measurementActions.UPDATE_ADD_MEASUREMENT:
      const { rootPath: rootPathAdd, name: nameAdd } = action.payload.event;
      let { value: valueAdd } = action.payload.event;

      if (["width", "height"].includes(nameAdd)) {
        valueAdd = _.round(+valueAdd || 0, -2);
      }

      if (["quantity"].includes(nameAdd)) {
        valueAdd = _.round(+valueAdd || 0, 0);
      }

      state = state.setIn(rootPathAdd.concat(nameAdd.split(".")), valueAdd);

      let measurementForm = addMeasurementForm(state);

      if (["width", "height"].includes(nameAdd)) {
        const width = measurementForm.get("width");
        const height = measurementForm.get("height");
        measurementForm = measurementForm
          .set("area", calculateOpeningArea(width, height))
          .set("ui", calculateOpeningUI(width, height));
      }

      return state.setIn([model, "addMeasurementForm"], measurementForm);

    case measurementActions.SAVE_ADD_MEASUREMENT:
      const form = addMeasurementForm(state).toJS();
      const forms = [];
      for (let i = 0; i < form.quantity; i++) {
        let name = form.name;
        if (form.quantity > 1) {
          name = `${form.name}-${i + 1}`;
        }
        forms.push({ ...form, name });
      }
      switch (form.kind) {
        case "windows":
          return reducer(state, measurementActions.Actions.updateWindows(forms));
        case "doors":
          return reducer(state, measurementActions.Actions.updateDoors(forms));
        case "rooms":
          return reducer(state, measurementActions.Actions.updateRooms(forms));
      }

      return state;

    default:
      return state;
  }
};
