import { IChameleonEvent } from "app/src/Visualization/Chameleon/ChameleonEvent";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";
const baseChameleon = "https://remotesfcontractor-v1.chameleonpower.com";
const chameleonCode = "?ccode=";

export interface IVisualization extends ng.resource.IResource<IVisualization>, VisualizationPrototype {
  $create(): ng.IPromise<IVisualization>;
  $create(params?: Object, success?: Function, error?: Function): ng.IPromise<IVisualization>;
  $create(success: Function, error?: Function): ng.IPromise<IVisualization>;
  $email?(params?: any): ng.IPromise<IVisualization>;
}

export interface IVisualizationResource extends ng.resource.IResourceClass<IVisualization>, IRsfResource {
  create?(params: any, data?: any): IVisualization;
  fromJSON?(data: any): IVisualization;
  buildNewProjectUrl?(kind: string, ccode: string, postalCode: string, chameleonUrl: string, userInfo: string): string;
}

class VisualizationPrototype {
  public id: number;
  public job_id: number;
  public name: string;
  public kind: string;
  public target: string;
  public data: any;
  public created_at: Date;
  public updated_at: Date;
  public sort_order: number;

  public getUrl(chameleon_url: string, userInfo = "") {
    let url = "";
    const e: IChameleonEvent = this.data;

    switch (this.kind) {
      case "chameleon":
        url = baseChameleon + "/" + chameleonCode + e.ccode;
        url = url + "/#/scene/" + e.specifier;
        url = url + "/products/" + e.sel;
        break;
      case "chameleon_v2":
        url = chameleon_url;
        url = url + "/#/scene/" + e.specifier;
        url = url + "/products/" + e.sel;
        url = url + "&user=" + userInfo;
        break;
    }

    return url;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/jobs/:job_id/visualizations/:id";

  const transformSingle = (response: string, headers: ng.IHttpHeadersGetter, status: number): IVisualization => {
    if (status < 200 || status > 299) {
      return new Visualization({});
    }
    return Visualization.fromJSON(JSON.parse(response).visualization);
  };

  const transformRequest = (visualization: IVisualization) => {
    const data = JSON.parse(angular.toJson(JSON.decycle(visualization)));

    return angular.toJson({ visualization: data });
  };

  const Visualization: IVisualizationResource = <IVisualizationResource>$resource(
    url,
    { job_id: "@job_id", id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: transformSingle,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: transformSingle,
      },
      email: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/email",
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const meta = JSON.parse(response);

          _.each(meta.visualizations, (jsonVisualization, index) => {
            meta.visualizations[index] = Visualization.fromJSON(jsonVisualization);
          });

          return meta;
        },
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Visualization.prototype, VisualizationPrototype.prototype);

  Visualization.fromJSON = (data: any) => {
    return new Visualization(data);
  };

  Visualization.buildNewProjectUrl = (
    kind: string,
    ccode: string,
    postalCode: string,
    chameleonUrl: string,
    userInfo: string,
  ) => {
    switch (kind) {
      case "chameleon":
        return baseChameleon + "/" + chameleonCode + ccode + "&zip=" + postalCode;
      case "chameleon_v2":
        return chameleonUrl + "&user=" + userInfo;
    }
  };

  Visualization.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Visualization;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
