import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { RootState } from "app2/src/reducers";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router/immutable";
import SpinnerComponent from "../../../SpinnerComponent";
import MakePaymentButton from "../../../PaymentsModal/MakePaymentButton";
import { JobTabTitle } from "../../JobTabTitle";
import { pathname } from "app2/src/selectors/router.selectors";
import SaveablePdfDisplay from "../../../Pdf/SaveablePdfDisplay";
import { currentJob, selectedEstimateId } from "app2/src/selectors/job.selectors";
import { estimate as estimateSelector } from "app2/src/selectors/estimate.selectors";
import { document as documentSelector } from "app2/src/selectors/document.selectors";
import { IntegrationsButtons } from "app2/src/components/Estimate/IntegrationsButtons";
import { SigningRecipientsModal } from "../../SignaturesTab/SigningRecipientsModal";
import { PaymentsModal } from "app2/src/components/PaymentsModal";
import { useTracking } from "react-tracking";
import { contractTitle } from "app2/src/selectors/org.selectors";

export const ViewContract: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch<{ documentId: string; estimateId: string }>();
  const documentId = Number(match.params.documentId);
  const { trackEvent } = useTracking<any>({
    category: "Contract",
  });

  // Selectors
  const path = useSelector(pathname);
  const job = useSelector(currentJob);
  const estimateId = useSelector(selectedEstimateId);
  const estimate = useSelector((state: RootState) => estimateSelector(state, { estimateId }));
  const document = useSelector((state: RootState) => documentSelector(state, { documentId }));
  const title = useSelector(contractTitle);

  // Methods
  const backToAll = () => {
    trackEvent({ action: "clear selected contract" });
    dispatch(push(path.split("/view_pdf")[0]));
  };
  const emailOrSign = () => {
    dispatch(push(`${path}/signing/${document.signed_document.id}`));
  };

  return (
    <>
      <SpinnerComponent localProperty={estimate?.get("loading")} />
      <JobTabTitle title={title}>
        <div className="pull-right">
          <IntegrationsButtons estimateId={estimateId} documentType="contract" />
          <MakePaymentButton />
          <Button onClick={() => emailOrSign()}>Email/Sign</Button>
          <Button onClick={() => backToAll()}>Back to All</Button>
        </div>
      </JobTabTitle>
      <Row>
        <Col>
          <SaveablePdfDisplay pdfUrl={document?.url} job={job} disabledFeatures={["Annotations"]} />
        </Col>
      </Row>
      <Switch>
        <Route path={`${match.url}/signing/:signedDocumentId`} component={SigningRecipientsModal} />
        <Route path={`${match.url}/payments`} component={PaymentsModal} />
      </Switch>
    </>
  );
};
