import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { Row, Col } from "react-bootstrap";
import track from "react-tracking";
import ListComponent from "app2/src/components/AngularHelper/List";
import { currentOrgId, preferencesConfig } from "app2/src/selectors/org.selectors";
import * as orgActions from "app2/src/reducers/org.actions";
import * as documentActions from "app2/src/reducers/document.actions";
import {
  migrateOldDocs,
  oldDocValueIds,
  setupAvailableDocuments,
} from "app2/src/components/OrgTabs/DocumentTab/DocumentOrder/DocumentOrder.service";
import { List } from "immutable";
import { DocType } from "app2/src/records/Estimate";
import { CommonTitles } from "app2/src/helpers/Format";

const mapStateToProps = (state: RootState, ownProps: OrderProps) => {
  const orgId = currentOrgId(state);
  let list = preferencesConfig(state, { orgId: orgId, path: ["document_order", ownProps.docType] }) || List();
  list = migrateOldDocs(state, list);
  const availableValues = setupAvailableDocuments(list);
  return {
    orgId,
    list,
    availableValues,
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: OrderProps) => {
  return {
    updateDocumentOrder: (list: List<any>) => dispatch(orgActions.Actions.updateDocumentOrder(ownProps.docType, list)),
    loadDocumentsById: (resourceIds) =>
      dispatch(
        documentActions.AsyncActions.loadDocumentsById(resourceIds, {
          documentableType: "org",
        }),
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OrderProps {
  docType: DocType;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OrderProps;

@track(() => {
  return {
    component: "Order",
    category: "DocumentOrder",
  };
})
class Order extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.updateList = this.updateList.bind(this);
  }

  public componentDidMount() {
    const { list, loadDocumentsById } = this.props;
    const ids = oldDocValueIds(list);
    loadDocumentsById(ids);
  }

  public updateList(list: any[]) {
    const { updateDocumentOrder } = this.props;
    updateDocumentOrder(List(list));
  }

  public render(): React.ReactNode {
    const { orgId, docType, list, availableValues } = this.props;

    return (
      <Row>
        <Col>
          <ListComponent
            caption={`${CommonTitles(docType)} Document Ordering`}
            name="Documents"
            list={list.toJS()}
            available={availableValues.toJS()}
            rowType="orgdoc"
            orgId={orgId}
            updateList={this.updateList}
          />
        </Col>
      </Row>
    );
  }
}

export default connector(Order);
