import * as React from "react";
import { createContext, useContext, useState, useEffect } from "react";
import { useTracking } from "react-tracking";
import { Participant, Room } from "twilio-video";

type selectedParticipantContextType = [Participant | null, (participant: Participant) => void];

export const selectedParticipantContext = createContext<selectedParticipantContextType>(null!);

export default function useSelectedParticipant() {
  const [selectedParticipant, setSelectedParticipant] = useContext(selectedParticipantContext);
  return [selectedParticipant, setSelectedParticipant];
}

type SelectedParticipantProviderProps = {
  room: Room;
  children: React.ReactNode;
};

export function SelectedParticipantProvider({ room, children }: SelectedParticipantProviderProps) {
  const [selectedParticipant, _setSelectedParticipant] = useState<Participant | null>(null);
  const { trackEvent } = useTracking();
  const setSelectedParticipant = (participant: Participant) =>
    _setSelectedParticipant((prevParticipant) => {
      let newParticipant = null;
      let action = "unpin viewer";
      if (prevParticipant !== participant) {
        newParticipant = participant;
        action = "pin viewer";
      }
      trackEvent({
        action,
        participant: newParticipant?.identity,
      });
      return newParticipant;
    });

  useEffect(() => {
    const onDisconnect = () => _setSelectedParticipant(null);
    room.on("disconnected", onDisconnect);
    return () => {
      room.removeListener("disconnected", onDisconnect);
    };
  }, [room]);

  return (
    <selectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>
      {children}
    </selectedParticipantContext.Provider>
  );
}
