import * as React from "react";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { List } from "immutable";
import { useEffect, useRef, useState } from "react";
import { connectFC } from "app2/src/connect";
import { Container, Row, Col, Table, Modal, Button, Form } from "react-bootstrap";
import * as userActions from "app2/src/reducers/user.actions";
import { RsfForm } from "@tberrysoln/rsf-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { currentOrg } from "app2/src/selectors/org.selectors";
import { getPaginationByModel, pagination } from "app2/src/selectors/pagination.selectors";
import { queryParamsFromJSON } from "app2/src/records/Page";
import { currentUser as currentUserSelector, user as userSelector } from "app2/src/selectors/user.selectors";
import {
  getFullName,
  UserRecord,
  emptyUserValue,
  emptyAccessValue,
  placeholderEmail,
} from "app2/src/records/UserRecord";
import { UserRow } from "./UserRow";
import { Nullable } from "app2/src/records";
import { Editor } from "./Editor";
import Spinner from "app2/src/components/SpinnerComponent";
import { OrgRecord, defaultUserAcl } from "app2/src/records/OrgRecord";
import { Can } from "app2/src/components/Common/CanComponent";
import { MetaPaginationRecord } from "app2/src/records/MetaPagination";
import { EditCommission } from "./EditCommission";
import { CreateSampleJobs } from "./CreateSampleJobs";

const modelName = "user";

const UsersTab: React.FC = () => {
  // hooks
  const dispatch = useDispatch();
  const currentPage = useRef(1);
  const [editingUserId, setEditingUserId] = useState<Nullable<number>>(null);
  const [isEditingCommission, setIsEditingCommision] = useState(false);
  const [sampleJobsUserId, setSampleJobsUserId] = useState<Nullable<number>>(null);
  const rootPath = React.useMemo(() => ["users", "usersById", editingUserId], [editingUserId]);

  // selectors
  const org = useSelector<RootState, OrgRecord>(currentOrg);
  const currentUser = useSelector<RootState, UserRecord>(currentUserSelector);
  const editingUser = useSelector<RootState, UserRecord>((state) =>
    editingUserId ? userSelector(state, { userId: editingUserId }) : null,
  );
  const paginationRecord = useSelector<RootState, MetaPaginationRecord>((state) =>
    pagination(state, { modelName, page: currentPage.current }),
  );
  const page = useSelector<RootState, List<UserRecord>>(
    (state) =>
      getPaginationByModel(state, {
        path: ["users", "usersById"],
        modelName,
      }) as List<UserRecord>,
  );

  // lifecycle
  useEffect(() => {
    queryUserPage(1);
  }, []);
  useEffect(() => {
    if (currentUser) {
      dispatch(userActions.AsyncActions.loadAllowedAcl(currentUser.id, "", ""));
    }
  }, [currentUser]);

  // methods
  const queryUserPage = (page: number) => {
    currentPage.current = page;
    dispatch(
      userActions.AsyncActions.listUsers(
        queryParamsFromJSON({
          page: page,
          parameters: { org_id: org.id.toString(), version: "v2", includes: ["accesses"] },
        }),
      ),
    );
  };

  const saveEditingUser = async (e) => {
    let sendInvitation = false;
    if (e.nativeEvent.submitter.name === "save_and_invite") {
      sendInvitation = true;
    }

    await dispatch(userActions.AsyncActions.save(editingUserId, ["accesses"], sendInvitation));
    setEditingUserId(null);
  };

  const addUser = () => {
    const newUser = emptyUserValue.merge({
      id: -1,
      email: placeholderEmail(org.name, paginationRecord.total_count),
      accesses: List([
        emptyAccessValue.merge({
          id: -1,
          billable: "billable",
          template: defaultUserAcl(org),
          user_id: -1,
          org_id: org.id,
          org: org,
        }) as any,
      ]),
    });
    dispatch(userActions.Actions.setUser(newUser.toJS()));

    setEditingUserId(-1);
  };

  const resetUser = () => {
    dispatch(userActions.Actions.resetUser(editingUser.id));
    setEditingUserId(null);
  };

  const editCommission = (id: number) => {
    setEditingUserId(id);
    setIsEditingCommision(true);
  };

  const resetCommission = () => {
    setEditingUserId(null);
    setIsEditingCommision(false);
  };

  return (
    <>
      {editingUserId && <Spinner stateProperty={["users", "usersById", editingUserId, "loading"]} />}
      <Container fluid="xl">
        <Row>
          <Col md={6}>
            <h1 className="admin-title">Users</h1>
          </Col>
          <Col md={6}>
            <Can resource="User" permission="create">
              <div className="pull-right">
                <button type="button" className="btn btn-default" onClick={addUser}>
                  <i className="fa fa-plus"></i>&nbsp;Add User
                </button>
              </div>
            </Can>
          </Col>
        </Row>
        {page.count() <= 0 ? (
          <Row>
            <Col>
              <div className="form-section blank-state">
                <img src="/assets/images/icons-large/customers.13dee16d.png" />
                <h2>No users added. Let's add one.</h2>
                <p>Click on the 'Add Users' button in the top right to add one.</p>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <Table striped>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Template</th>
                    <th rsf-permissions="user" permissions-on="Global">
                      Status
                    </th>
                    <th>Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {page.map((user, idx) => (
                    <UserRow
                      key={idx}
                      user={user}
                      editUserCallback={setEditingUserId}
                      editCommissionCallback={editCommission}
                      createSampleJobsCallback={setSampleJobsUserId}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={6}>
                      <StandardPagination metadata={paginationRecord} pageChanged={queryUserPage} />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        )}
      </Container>
      <Modal show={editingUserId !== null && !isEditingCommission} size="xl" backdrop="static">
        <RsfForm rootPath={rootPath} updateFormReducer={userActions.Actions.updateForm} onSubmit={saveEditingUser}>
          <Modal.Header>
            <h3>Editing: {editingUser && getFullName(editingUser)}</h3>
          </Modal.Header>
          <Modal.Body className="overflow-y-auto">
            <Editor userId={editingUserId} />
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row align-end">
            <input className="btn btn-default" type="submit" name="save" value="Save" />
            <input className="btn btn-secondary" type="submit" name="save_and_invite" value="Save and Invite" />
            <Button variant="cancel" onClick={resetUser}>
              Cancel
            </Button>
          </Modal.Footer>
        </RsfForm>
      </Modal>
      <Modal show={isEditingCommission} size="sm" backdrop="static">
        <EditCommission fullName={getFullName(editingUser)} close={resetCommission} user={editingUser} />
      </Modal>
      <Modal show={sampleJobsUserId !== null} size="lg" backdrop="static">
        <CreateSampleJobs userId={sampleJobsUserId} close={() => setSampleJobsUserId(null)} />
      </Modal>
    </>
  );
};

export default connectFC(UsersTab);
