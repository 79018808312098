import * as React from "react";
import Select from "react-select";

export const RsfSelectAdapter = ({ input, meta, ...rest }) => (
  <>
    <Select {...input} {...rest} searchable isMulti closeMenuOnSelect={false} />
    <span className="has-error" style={{ flexBasis: "100%" }}>
      {(meta.error || meta.submitError) && meta.touched && <span>{meta.error || meta.submitError}</span>}
    </span>
  </>
);
