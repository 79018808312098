export class SetFocus implements ng.IDirective {
  public restrict = "A";

  constructor(
    public $parse: ng.IParseService,
    public $timeout: ng.ITimeoutService,
  ) {}

  public link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
    const model = this.$parse(attrs.setFocus);
    scope.$watch(model, (value) => {
      if (value === true) {
        this.$timeout(function () {
          element[0].focus();
        });
      }
    });

    element.bind("blur", function () {
      scope.$apply(model.assign(scope, false));
    });
  };

  public static factory(): ng.IDirectiveFactory {
    const directive = ($parse: ng.IParseService, $timeout: ng.ITimeoutService) => new SetFocus($parse, $timeout);
    directive.$inject = ["$parse", "$timeout"];
    return directive;
  }
}
