import * as React from "react";
import { FormControl } from "@tberrysoln/rsf-form";
import { composeValidators, email, required } from "app2/src/helpers/FinalFormValidator";

export const SettingsForm: React.FC = () => {
  const requiredEmail = composeValidators(required, email);

  return (
    <>
      <FormControl
        label="QuickMeasure Email"
        name="data.quick_measure_email"
        validate={requiredEmail}
        formControlProps={{ type: "text" }}
      />
    </>
  );
};
