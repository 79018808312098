import { connectFC } from "app2/src/connect";
import * as React from "react";
import { Statistics } from "./Statistics";
import { Appointments } from "./Appointments";
import { SystemNotes } from "./SystemNotes";

export const Sidebar: React.FC = () => {
  return (
    <div className="job-list-sidebar">
      <Statistics />
      <Appointments count={3} />
      <SystemNotes />
    </div>
  );
};

export default connectFC(Sidebar);
