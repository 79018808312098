import { FeeRecord } from "app2/src/records/billing/Fee";
import { IFeeStateParams } from "app/src/Billing/Fees/FeeNewCtrl";
import { ISubscriptionResource, ISubscriptionResponse } from "../Models/Subscription";
import { subscriber } from "app2/src/helpers/Subscribe";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import * as feeActions from "app2/src/reducers/billing/fee.actions";
import { feeById } from "app2/src/selectors/fee.selectors";

export class FeeShowCtrl {
  public includes: string[] = ["subscriptions"];
  public fee: FeeRecord;
  public subscriptions: ISubscriptionResponse;

  private _unsubscribeFee: () => void;

  public static $inject = ["Subscription", "$stateParams", "$scope"];
  constructor(
    public SubscriptionResource: ISubscriptionResource,
    public $stateParams: IFeeStateParams,
    public $scope: ng.IScope,
  ) {
    this.fee = useSelector((state) => feeById(state, { id: $stateParams.id }));
    if (!this.fee) {
      this.loadFee();
    }

    this._unsubscribeFee = subscriber.subscribe<string>("billing.fees.byId", () => {
      this.fee = useSelector((state) => feeById(state, { id: $stateParams.id }));
      if (!($scope.$$phase === "$apply" || $scope.$$phase === "$digest")) {
        $scope.$digest();
      }
    });

    this.subscriptions = <ISubscriptionResponse>(
      SubscriptionResource.query({ fee_id: $stateParams.id, "include[]": ["account"] })
    );

    $scope.$on("$destroy", () => {
      this._unsubscribeFee();
    });
  }

  public async loadFee() {
    dispatch(feeActions.AsyncActions.loadFee(this.$stateParams.id));
  }

  public toggleArchive() {
    if (this.fee.archived_at) {
      dispatch(feeActions.AsyncActions.unarchiveFee(this.fee));
    } else {
      dispatch(feeActions.AsyncActions.archiveFee(this.fee));
    }
  }
}
