import { List, Record } from "immutable";

export const fromJSON = (json: Partial<IPriceListProcessData>): PriceListProcessRecords => {
  const record: IPriceListProcessRecords = { ...(json as any) };

  return new PriceListProcessRecords(record);
};

export interface IPriceListProcessData {
  list: any;
  loading?: boolean;
}

export interface IPriceListProcessRecords {
  list: any;
  errors: List<string>;
  loading: boolean;
}

const defaultPriceListProcessProps: IPriceListProcessRecords = {
  list: {},
  loading: false,
  errors: List<string>(),
};

export class PriceListProcessRecords extends Record(defaultPriceListProcessProps) implements IPriceListProcessRecords {
  public readonly list!: List<any>;
  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public constructor(values?: Partial<IPriceListProcessRecords>) {
    values ? super(values) : super();
  }
}
