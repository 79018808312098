import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IProduct } from "app/src/Models/Product";
import { IProductOptionGroup } from "app/src/Models/ProductOptionGroup";
import * as moment from "moment";
import { IDoc } from "app/src/Models/Doc";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IPriceList extends ng.resource.IResource<IPriceList>, PriceListPrototype {
  $export(data: any): ng.IPromise<IPriceList>;
  $merge(data: any): ng.IPromise<IPriceList>;
  $activate(): ng.IPromise<IPriceList>;
  $save(): ng.IPromise<IPriceList>;
}

export interface IPriceListResource extends ng.resource.IResourceClass<IPriceList>, IRsfResource {
  fromJSON?(data: any): IPriceList;
  activate?(data: any): IPriceList;
  create?(data: any): IPriceList;
}

export interface IPriceListResponse extends ng.resource.IResourceArray<IPriceList> {
  price_lists: IPriceList[];
  meta: IPagingMetadata;
}

class PriceListPrototype {
  public id: number;
  public name: string;
  public org_id: number;
  public file: IDoc;
  public products: IProduct[];
  public product_option_groups: IProductOptionGroup[];
  public hidden_products: boolean;
  public csv_url: string;
  public csv_generated_on: Date;
  public created_at: Date;
  public updated_at: Date;
  public location: string;
  public estimate_count: number;
  public kind: string;

  public isCsvCurrent() {
    return moment(this.csv_generated_on).diff(moment(this.updated_at)) / 1000 >= -2;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, $http: ng.IHttpService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/price_lists/:id";
  const nested_url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/price_lists";

  const transform = (data: any): IPriceList => {
    _.each(data.products, (product, index) => {
      data.products[index] = resources.Product.fromJSON(product);
    });

    _.each(data.product_option_groups, (pog, index) => {
      data.product_option_groups[index] = resources.ProductOptionGroup.fromJSON(pog);
    });

    // This was added because old activated price lists didn't have the hidden_products variable set on them
    // I'm making an assumption that if you're loading a cached price list it has to be activated
    if (data.hidden_products === undefined) {
      data.hidden_products = false;
    }

    return new PriceList(data);
  };

  const requestTransform = (price_list: IPriceList) => {
    const data = JSON.parse(angular.toJson(JSON.decycle(price_list)));

    return angular.toJson({ price_list: data });
  };

  const transformSingleResponse = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return new PriceList({});
    }

    const data: any = JSON.parse(response).price_list;

    return transform(data);
  };

  const PriceList: IPriceListResource = <IPriceListResource>$resource(
    url,
    { id: "@id", org_id: "@org_id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: nested_url,
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: nested_url,
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const data: any = JSON.parse(response);

          data.price_lists = _.map(data.price_lists, (obj: any) => {
            return PriceList.fromJSON(obj);
          });

          return data;
        },
      },
      activate: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/activate",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      export: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/export",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      merge: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/merge",
        isArray: false,
      },
    },
  );

  PriceList.fromJSON = (data: any): IPriceList => {
    return transform(data);
  };

  _.hiddenExtend(PriceList.prototype, PriceListPrototype.prototype);

  PriceList.inject = (injected: IRepository) => {
    resources = injected;
  };

  return PriceList;
};

factory.$inject = ["$resource", "$http", "BaseConfig"];

export default factory;
