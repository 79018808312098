import * as React from "react";
import { Fragment } from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { List } from "immutable";
import { Alert } from "react-bootstrap";
import { FlashLevels, FlashLevelTypes } from "app/src/Common/FlashLevels";
import { IFlashAlertData } from "app2/src/records/FlashAlert";
import * as commonActions from "app2/src/reducers/components/common.actions";

const mapStateToProps = (state: RootState, ownProps: FlashAlertProps) => {
  const flashAlerts: List<IFlashAlertData> = state.getIn(["components", "common", "flashAlerts"], List());

  return {
    flashAlerts: flashAlerts,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: FlashAlertProps) => {
  return {
    closeFlashMessage: (idx: number) => dispatch(commonActions.Actions.flashRemoveAlert(idx)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface FlashAlertProps {}

interface FlashAlertState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & FlashAlertProps;

class FlashAlert extends React.Component<Props, FlashAlertState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { flashAlerts, closeFlashMessage } = this.props;

    return (
      <div className="alert-stack">
        {flashAlerts.toArray().map((the_alert: IFlashAlertData, idx: number) => {
          setTimeout(() => {
            closeFlashMessage(idx);
          }, 5000);
          return (
            <Alert
              key={idx}
              onClose={() => closeFlashMessage(idx)}
              variant={FlashLevels[the_alert.level] as FlashLevelTypes}
              dismissible>
              <div className="alert-circle"></div>
              <p className="alert-message">
                {typeof the_alert.message === "string"
                  ? the_alert.message
                  : the_alert.message.map((m, idx) => (
                      <Fragment key={idx}>
                        {m} <br />
                      </Fragment>
                    ))}
              </p>
            </Alert>
          );
        })}
      </div>
    );
  }
}

export default connector(FlashAlert);
