import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { SortableOptions } from "app/src/Common/SortableOptions";
import { IOrgPrefDocumentOrder, IOrgPrefNotes } from "app/src/Models/OrgPreference";
import { IDirtyMerge, IDirtyWatcher } from "app/src/Common/DirtyWatcher";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IReport } from "../../Models/Report";
import * as angulartics from "angulartics";
import { IPatternResource, IPatternResponse } from "app/src/Models/Pattern";
import { ISideBarMenu } from "../../JobMenu/JobMenuOrderService";
import { IContractLanguage } from "../../Models/OrgSetting";
import { OrgAclType } from "../../Models/Org";
import { ToolService } from "app/src/Common/ToolService";
import { IOrg } from "app/src/Models/Org";
import { setIn } from "immutable";
import { SupportedTimeZones } from "app2/src/helpers/Time";
import { useSelector } from "app2/src/storeRegistry";
import { getAuthorizedTokens } from "app2/src/selectors/token.selectors";
export class OrgPreferencesTabCtrl extends BaseOrgTabCtrl implements IDirtyMerge {
  public sharedNeverAlwaysValues: string[] = ["never", "always"];
  public sharedTrueFalseValues: boolean[] = [true, false];
  public financeTypeValues: string[] = ["active", "inactive", "active_range"];
  public sharedDetailedPricingValues: string[] = [
    "never",
    "always",
    "customer_only",
    "company_only",
    "customer_main_only",
    "customer_option_only",
    "company_main_only",
    "company_option_only",
    "company_customer_main",
    "company_customer_option",
  ];
  public sharedDetailVisibilityValues: string[] = ["never", "always", "customer_only", "company_only"];
  public audioValues: string[] = ["disabled", "mute", "enabled"];
  public screenShareKindValues: string[] = ["basic", "premium"];
  public timezones: Array<string> = SupportedTimeZones.toArray();
  public productSwatchesValues: string[] = ["never", "always", "proposal_only", "agreement_only"];
  public alignmentValues: string[] = ["left", "center", "right"];
  public locationValues: string[] = ["header", "footer"];
  public displayValues: string[] = ["do_not_display", "contract", "estimate", "both"];
  public cronofyInviteValues: string[] = ["never", "always", "by_choice", "by_default"];
  public msSignableDocValues: string[] = ["signed"];
  public msContractValues: string[] = ["button", "email", "sent", "signed", "result_signed"];
  public msEstimateValues: string[] = ["button", "email"];
  public measurementPrefValues: string[] = [
    "roofing",
    "siding",
    "windows",
    "doors",
    "roof_faces",
    "walls",
    "fencing",
    "sunroom",
    "rooms",
    "decks",
    "pools",
  ];
  public sendEmailValues: string[] = ["customer_document_signed", "signable_document_signed"];
  public availablePdfFillValues: string[] = ["fill_estimate", "fill_contract", "fill_inspection"];
  public estimatorAddProductValues: string[] = ["replace", "merge", "ask"];
  public defaultTaxableAmountValues: string[] = ["none", "product", "labor", "product_labor"];
  public useSalesTaxValues: string[] = ["disabled", "enabled"];
  public screenleapShareValues: string[] = ["BROWSER", "USER_SELECT"];
  public estimatorAclValues: string[] = ["full", "multiple_groups"];
  public discountViewValues: string[] = ["vertical", "side_by_side"];
  public estimateTotalValues: string[] = ["hide_before_discount", "always"];
  public measurementSystems: string[] = ["imperial", "metric"];
  public orgTokens: any = {};
  public sortableEstimate = new SortableOptions();
  public sortableContract = new SortableOptions();
  public sortableLanguage = new SortableOptions();
  public grouped_patterns: any;
  public document_order: IOrgPrefDocumentOrder;
  public boolCon: {} = {};
  public boolSignable: {} = {};
  public boolEst: {} = {};
  public boolLsCon: {} = {};
  public boolLsEst: {} = {};
  public boolJob: {} = {};
  public boolLineItemEditor: {} = {};
  public boolSendEmail: {} = {};
  public boolEstimatorAcl: any = {};
  public toolService: ToolService;
  public markupValid: any = {
    product: true,
    labor: true,
  };
  public orgCopy: IOrg;

  public enableNoteEditing = true;

  public static $inject = [
    "OrgFetcher",
    "Flash",
    "Report",
    "$stateParams",
    "DirtyWatcher",
    "$scope",
    "Pretty",
    "Pattern",
    "$analytics",
  ];
  constructor(
    OrgFetcher: IOrgFetcherService,
    private Flash: IFlash,
    public Report: IReport,
    public $stateParams: ng.ui.IStateParamsService,
    public DirtyWatcher: IDirtyWatcher,
    public $scope: ng.IScope,
    public Pretty: IPretty,
    public Pattern: IPatternResource,
    protected $analytics: angulartics.IAnalyticsService,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.sortableLanguage.stop = () => {
      this.sort();
    };

    this.sortableLanguage.update = () => {
      this.sort();
    };

    OrgFetcher.orgPromise
      .then(
        () => {
          this.orgCopy = JSON.parse(JSON.stringify(this.org));
          this.orgTokens = useSelector(getAuthorizedTokens);
          this.toolService = new ToolService(this.org, "preference", $scope);
          this.enableNoteEditing = this.org.preferences.config?.notes?.enable_editing === false ? false : true;
          _.each(this.msContractValues, (cv: string) => {
            this.boolCon[cv] = _.contains(this.org.preferences.config.marketsharp.contract, cv);
          });
          _.each(this.msSignableDocValues, (cv: string) => {
            this.boolSignable[cv] = _.contains(this.org.preferences.config.marketsharp.signable, cv);
          });
          _.each(this.msEstimateValues, (ev: string) => {
            this.boolEst[ev] = _.contains(this.org.preferences.config.marketsharp.estimate, ev);
          });
          _.each(this.sendEmailValues, (ev: string) => {
            this.boolSendEmail[ev] = _.contains(this.org.preferences.config.send_email.notify, ev);
          });
          _.each(this.estimatorAclValues, (v: string) => {
            this.boolEstimatorAcl[v] = _.contains(this.org.fetchPreferencesAcl(OrgAclType.estimator), v);
          });
          _.each(this.measurementPrefValues, (mp: string) => {
            this.boolLineItemEditor[mp] = _.contains(this.org.preferences.config.measurement.line_item_editor, mp);
            this.boolJob[mp] = _.contains(this.org.preferences.config.measurement.job_tab, mp);
          });

          this.validateMarkup();
          const patternResponse = <IPatternResponse>(
            this.Pattern.query({ patternable_id: this.org.id, patternable_type: "Org" })
          );
          return patternResponse.$promise.then((resp: IPatternResponse) => {
            this.grouped_patterns = _.groupBy(resp.patterns, (pattern) => {
              return pattern.doc_type;
            });
          });
        },
        (e) => console.error(e),
      )
      .catch((e) => console.error(e));

    DirtyWatcher.setup($scope, this);

    this.updateReports = this.updateReports.bind(this);
    this.updateTools = this.updateTools.bind(this);
    this.updateDesignTools = this.updateDesignTools.bind(this);
    this.updatePdfFillValues = this.updatePdfFillValues.bind(this);
    this.updatePreferencesConfig = this.updatePreferencesConfig.bind(this);
  }

  public getTooltipHtml(document_type: any) {
    switch (document_type) {
      case "standalone":
        return "Used for " + this.org.getTitle("estimate") + " and " + this.org.getTitle("contract", true);
      default:
        return "Used for Inspection Results pages in " + this.org.getTitle("inspection") + " documents";
    }
  }

  public sort() {
    for (const index in this.org.contract_languages) {
      this.org.contract_languages[index].sort_order = parseInt(index);
    }
  }

  public updatePattern() {
    const patterns = this.org.preferences.config.document_order.patterns;
    const copyPatterns = this.orgCopy.preferences.config.document_order.patterns || {};
    for (const pattern in patterns) {
      if (patterns[pattern].id !== copyPatterns[pattern]?.id) {
        patterns[pattern].selected_timestamp = new Date();
      } else {
        patterns[pattern].selected_timestamp = copyPatterns[pattern].selected_timestamp;
      }
    }
  }

  public updatePreferencesConfig(key: string, value: any) {
    this.$scope.$apply(() => {
      this.org.preferences.config = setIn(this.org.preferences.config, key.split("."), value);
    });
  }

  public save() {
    this.updatePattern();
    this.spinnerPromise = this.OrgFetcher.save(this.org)
      .then((org: IOrg) => {
        this.org = org;
        this.orgCopy = JSON.parse(JSON.stringify(this.org));
        this.toolService = new ToolService(this.org, "preference", this.$scope);
        this.Flash.addMessage(FlashLevels.success, "Preferences Saved");
        this.trackEvent("save_preference", {
          category: "OrgPreference",
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "Problems Saving Preferences!");
      });
  }

  public updateReports(values: string[]) {
    this.org.setPreferencesAcl(OrgAclType.reports, values);
    this.trackEvent("update_reports", {
      category: "OrgPreference",
    });
  }

  public updatePdfFillValues(values: string[]) {
    this.org.setPreferencesAcl(OrgAclType.document, values);
    this.trackEvent("update_pdf_fill_values", {
      category: "OrgPreference",
    });
  }

  public updateTools(values: string[]) {
    this.toolService.update(values, "tools");
    this.trackEvent("update_tools", {
      category: "OrgPreferences",
    });
  }

  public updateDesignTools(values: string[]) {
    this.toolService.update(values, "design_tools");
    this.trackEvent("update_design_tools", {
      category: "OrgPreferences",
    });
  }

  public updateJobMenu(menu: ISideBarMenu) {
    this.org.preferences.config.job_menu = menu;
  }

  public reportFilter(option: string) {
    return _.include(this.org.fetchSettingsAcl(OrgAclType.reports), option);
  }

  public removeContract(contract_language: IContractLanguage) {
    this.trackEvent("remove_contract", {
      category: "OrgPreference",
      contractValue: contract_language.language,
    });
    contract_language._destroy = true;
  }

  public addContract() {
    const max_sort_order = this.org.contract_languages.length;
    const new_contract = <IContractLanguage>{
      sort_order: max_sort_order,
      format_options: {
        size: 10,
        align: "center",
      },
      display: "both",
      location: "footer",
    };
    this.trackEvent("add_contract", {
      category: "OrgPreference",
    });
    this.org.contract_languages.push(new_contract);
  }

  public syncEstimatorAcl(value: string) {
    const data = this.org.fetchPreferencesAcl(OrgAclType.estimator);
    if (this.boolEstimatorAcl[value]) {
      this.org.setPreferencesAcl(OrgAclType.estimator, data.concat(value));
    } else {
      this.org.setPreferencesAcl(
        OrgAclType.estimator,
        _.filter(data, (c) => {
          return c !== value;
        }),
      );
    }
  }

  public syncJobTab(item: string) {
    const jobPref = this.org.preferences.config.measurement.job_tab;
    if (this.boolJob[item]) {
      jobPref.push(item);
    } else {
      this.org.preferences.config.measurement.job_tab = _.filter(jobPref, (e) => {
        return e !== item;
      });
    }
  }

  public syncLineItemEditor(item: string) {
    const liePref = this.org.preferences.config.measurement.line_item_editor;
    if (this.boolLineItemEditor[item]) {
      liePref.push(item);
    } else {
      this.org.preferences.config.measurement.line_item_editor = _.filter(liePref, (e) => {
        return e !== item;
      });
    }
  }

  public syncEstimate(item: string) {
    const estimatePref = this.org.preferences.config.marketsharp.estimate;
    if (this.boolEst[item]) {
      estimatePref.push(item);
    } else {
      this.org.preferences.config.marketsharp.estimate = _.filter(estimatePref, (e) => {
        return e !== item;
      });
    }
  }

  public syncContract(item) {
    const contractPref = this.org.preferences.config.marketsharp.contract;
    if (this.boolCon[item]) {
      contractPref.push(item);
    } else {
      this.org.preferences.config.marketsharp.contract = _.filter(contractPref, (c) => {
        return c !== item;
      });
    }
  }

  public syncSignable(item) {
    let signablePref = this.org.preferences.config.marketsharp.signable;
    if (!signablePref) {
      this.org.preferences.config.marketsharp.signable = [];
      signablePref = this.org.preferences.config.marketsharp.signable;
    }

    if (this.boolSignable[item]) {
      signablePref.push(item);
    } else {
      this.org.preferences.config.marketsharp.signable = _.filter(signablePref, (c) => {
        return c !== item;
      });
    }
  }

  public syncSendEmail(item) {
    const sendEmailPref = this.org.preferences.config.send_email.notify;
    if (this.boolSendEmail[item]) {
      sendEmailPref.push(item);
    } else {
      this.org.preferences.config.send_email.notify = _.filter(sendEmailPref, (c) => {
        return c !== item;
      });
    }
  }

  public validateMarkup() {
    Object.keys(this.markupValid).forEach((type) => {
      if (!this.org.preferences?.config?.markup?.enabled) {
        this.markupValid[type] = true;
      } else if (
        parseInt(this.org.preferences.config.markup[`${type}_min`]) <=
          parseInt(this.org.preferences.config.markup[`${type}_default`]) &&
        parseInt(this.org.preferences.config.markup[`${type}_default`]) <=
          parseInt(this.org.preferences.config.markup[`${type}_max`])
      ) {
        this.markupValid[type] = true;
      } else {
        this.markupValid[type] = false;
      }
    });
  }

  public setNoteEditingFlag() {
    if (!this.org.preferences.config.notes) {
      this.org.preferences.config["notes"] = { enable_editing: this.enableNoteEditing } as IOrgPrefNotes;
    } else {
      this.org.preferences.config.notes.enable_editing = this.enableNoteEditing;
    }
  }

  public formValid(): boolean {
    return this.check() && this.markupValid.product && this.markupValid.labor;
  }

  public authorizedIntegrations(): boolean {
    if (!Object.keys(this.orgTokens).length) return false;

    const orgTokensJS = this.orgTokens.toJS();

    const marketSharpAuthorized: boolean = orgTokensJS.marketsharp?.authorized;
    const improveit360Authorized: boolean = this.improveit360Authorized();
    const jobNimbusAuthorized: boolean = orgTokensJS.job_nimbus?.authorized;
    const salesforceAuthorized: boolean = orgTokensJS.salesforce?.authorized;
    const leadPerfectionAuthorized: boolean = orgTokensJS.lead_perfection?.authorized;
    const jobProgressAuthorized: boolean = orgTokensJS.jobprogress?.authorized;
    const serviceMinderAuthorized: boolean = orgTokensJS.service_minder?.authorized;

    return (
      marketSharpAuthorized ||
      improveit360Authorized ||
      jobNimbusAuthorized ||
      salesforceAuthorized ||
      leadPerfectionAuthorized ||
      jobProgressAuthorized ||
      serviceMinderAuthorized ||
      false
    );
  }

  public improveit360Authorized(): boolean {
    if (!Object.keys(this.orgTokens).length) return false;

    const orgTokensJS = this.orgTokens.toJS();

    return orgTokensJS.improveit360?.authorized || false;
  }
}
