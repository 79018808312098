import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import CrmAuthorize from "../Common/CrmAuthorize";
import { IUser } from "app/src/Models/User";
import * as crmUserActions from "app2/src/reducers/crmUser.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import * as tokenActions from "app2/src/reducers/token.actions";
import CrmUserMatchTable from "../Common/CrmUserMatchTable";
import { CrmUserRecord } from "app2/src/records/integrations/CrmUser";
import { QueryParamsRecord } from "app2/src/records/Page";
import { getPaginationByModel, queryParams, pagination } from "app2/src/selectors/pagination.selectors";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import AccuLynxModal from "./AccuLynxModal";
import track from "react-tracking";
import { ICrmUserQuery } from "../../Common/IntegrationList";

const mapStateToProps = (state: RootState, ownProps: AccuLynxProps) => {
  const modelName = "AlUser";
  const crmUsers = getPaginationByModel(state, {
    path: ["crmUsers", "byId"],
    modelName,
  });
  const sfQueryParams = queryParams(state, { modelName });

  return {
    authorized: getAuthorization(state, { orgId: ownProps.orgId, integration: "accu_lynx" }),
    pagination: pagination(state, { modelName, page: sfQueryParams.get("page") }),
    queryParams: sfQueryParams,
    crmUsers,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: AccuLynxProps) => {
  return {
    sync: () => dispatch(orgActions.AsyncActions.syncIntegration(ownProps.orgId, "InitialSyncJob", "accu_lynx")),
    rejectAuthorization: (orgId: number) => dispatch(tokenActions.AsyncActions.deleteToken(orgId, "accu_lynx")),
    updateUser: (crmUser: CrmUserRecord) => dispatch(crmUserActions.AsyncActions.updateUser(crmUser)),
    queryUsers: (orgId: number, sfQueryParams: QueryParamsRecord) =>
      dispatch(crmUserActions.AsyncActions.queryUsers({ orgId: orgId, userType: "AlUser" }, sfQueryParams)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface AccuLynxProps {
  users: IUser[];
  orgId: number;
  tokenUpdated: () => void;
}

interface AccuLynxState {
  triggerOpen: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & AccuLynxProps;

@track({
  component: "AccuLynx",
  integration: "accu_lynx",
})
class AccuLynx extends React.Component<Props, AccuLynxState> {
  constructor(props: Props) {
    super(props);

    this.state = { triggerOpen: 0 };

    this.openModal = this.openModal.bind(this);
    this.rejectAuthorization = this.rejectAuthorization.bind(this);
    this.queryUsers = this.queryUsers.bind(this);
  }

  public componentDidMount() {
    const { queryUsers, orgId, authorized } = this.props;
    if (authorized) {
      queryUsers(orgId, new QueryParamsRecord());
    }
  }

  @track(() => ({
    action: "open modal",
  }))
  public openModal() {
    this.setState((state) => ({ triggerOpen: state.triggerOpen + 1 }));
  }

  @track(() => ({
    action: "reject authorization",
  }))
  public async rejectAuthorization() {
    const { orgId, rejectAuthorization, tokenUpdated } = this.props;
    await rejectAuthorization(orgId);
    tokenUpdated();
  }

  @track(() => ({
    action: "query users",
  }))
  public queryUsers(queryObj: ICrmUserQuery) {
    const { queryUsers, queryParams, orgId } = this.props;
    let newQueryParams = queryParams;
    Object.entries(queryObj).map((entry: any[]) => {
      newQueryParams = newQueryParams.setIn([entry[0]], entry[1]);
    });

    queryUsers(orgId, newQueryParams);
  }
  public render() {
    const { authorized, crmUsers, pagination, users, updateUser, sync, orgId, tokenUpdated } = this.props;
    const { triggerOpen } = this.state;
    return (
      <div className="form-section">
        <div className="form-section-content">
          <p className="authorize-apps" title="name">
            AccuLynx
          </p>
          <CrmAuthorize
            title="AccuLynx"
            image="/assets/images/image-tools/acculynx-logo.513381ac.png"
            authorized={authorized}
            authorize={this.openModal}
            rejectAuthorization={this.rejectAuthorization}
          />
          {authorized && (
            <CrmUserMatchTable
              integrationType="AccuLynx"
              occUsers={users}
              integrationUsers={crmUsers}
              pagination={pagination}
              queryUsers={this.queryUsers}
              saveUser={updateUser}
              sync={sync}
            />
          )}
          <AccuLynxModal triggerOpen={triggerOpen} tokenUpdated={tokenUpdated} />
        </div>
      </div>
    );
  }
}

export default connector(AccuLynx);
