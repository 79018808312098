import { fetcher } from "app2/src/helpers/Fetcher";
import { IDocumentData, DocumentRecord, toFormData, DocumentDisplayType } from "app2/src/records/Document";
import { QueryParamsRecord } from "app2/src/records/Page";
import { IMetaPaginationData } from "app2/src/records/MetaPagination";
import { List } from "immutable";
import { baseCleanProps } from "app2/src/api/Api";
import { listParams } from "app2/src/api/Api";
import { CamelToSnake } from "app2/src/helpers/Format";

export interface IDocumentIndexResponse {
  documents: IDocumentData[];
  meta: { pagination: IMetaPaginationData };
}

export interface IDocumentResponse {
  document?: IDocumentData;
  documents?: IDocumentData[];
}

export interface IDirectUploadResponse {
  urls: {
    id: string;
    url: string;
  }[];
}

export type DocumentableType = "org" | "job" | "estimate_line_item";
export const DocumentableTypeConversion = { org: "Org", job: "Job", estimate_line_item: "EstimateLineItem" };
export type DocumentSignedType = "signed" | "unsigned";

export interface IDocumentOptions {
  documentableId: number;
  documentableType: DocumentableType;
  presignedId?: number;
  presignedType?: DocumentableType;
  document_ids?: List<number>;
  folder_id?: number;
  job_id?: number;
  signed?: DocumentSignedType;
  display?: DocumentDisplayType;
  disableFlash?: boolean;
  content_type?: string;
  signed_kind?: "document" | "contract";
  sort_by?: string;
  sort_order?: "asc" | "dec";
}

const urls = {
  org: "api/v1/orgs/:id/documents",
  job: "api/v1/jobs/:id/documents",
  estimate_line_item: "api/v1/estimate_line_items/:id/documents",
};

export const list = (
  options: IDocumentOptions,
  queryParams: QueryParamsRecord = new QueryParamsRecord(),
): Promise<IDocumentIndexResponse> => {
  const actionUrl = urls[options.documentableType].replace(":id", options.documentableId.toString());
  const params = listParams(queryParams);

  if (options.folder_id && options.folder_id !== 0) {
    params.folder_id = options.folder_id;
  }

  if (options.signed) {
    params.signed = options.signed;
  }

  if (options.content_type) {
    params.content_type = options.content_type;
  }

  if (options.display) {
    params.display = options.display;
  }

  if (options.document_ids) {
    params["document_ids[]"] = options.document_ids.toArray();
  }

  if (options.signed_kind) {
    params.signed_kind = options.signed_kind;
  }

  if (options.sort_by) {
    params.sort_by = options.sort_by;
  }

  if (options.sort_order) {
    params.sort_order = options.sort_order;
  }

  return fetcher.get<IDocumentIndexResponse>(actionUrl, params);
};

export const updateOrCreate = (document: DocumentRecord): Promise<IDocumentResponse> => {
  if (document.id > 0) {
    return update(document);
  } else {
    return create(document);
  }
};

export const load = (documentId: number, options: IDocumentOptions): Promise<IDocumentResponse> => {
  let actionUrl = urls[options.documentableType].replace(":id", options.documentableId.toString());
  actionUrl = fetcher.joinUrls(actionUrl, documentId.toString());
  return fetcher.get<IDocumentResponse>(actionUrl);
};

export const create = (document: DocumentRecord): Promise<IDocumentResponse> => {
  const actionUrl = urlSetup(document);
  return fetcher.post<IDocumentResponse>(actionUrl, toFormData(document), { formData: true });
};

export const update = (document: DocumentRecord): Promise<IDocumentResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(document), document.id.toString());
  return fetcher.patch(actionUrl, cleanProps(document));
};

export const importMeasurement = (document: DocumentRecord): Promise<IDocumentResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(document), document.id.toString(), "import");
  return fetcher.post(actionUrl, cleanProps(document));
};

export const combineDocuments = (
  documentIds: List<number>,
  name: string,
  jobId: number,
): Promise<IDocumentResponse> => {
  const actionUrl = urls.job.replace(":id", jobId.toString()).concat("/combine");
  return fetcher.post(actionUrl, { document_ids: documentIds, name: name });
};

export const destroy = (document: DocumentRecord): Promise<IDocumentResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(document), document.id.toString());
  return fetcher.delete(actionUrl);
};

export const directUpload = (
  fileCount: number,
  documentableType: DocumentableType,
  documentableId: number,
): Promise<IDirectUploadResponse> => {
  const actionUrl = fetcher.joinUrls(urls[documentableType].replace(":id", documentableId.toString()), "direct");
  return fetcher.post(actionUrl, { count: fileCount });
};

export const cleanProps = (document: DocumentRecord): IDocumentData => {
  let documentParams = document.toJS();
  documentParams = baseCleanProps(documentParams);

  if (_.isNullOrUndefined(documentParams.folder_id)) {
    delete documentParams.folder_id;
  }
  return documentParams;
};

export const urlSetup = (document: DocumentRecord): string => {
  return urls[CamelToSnake(document.documentable_type)].replace(":id", document.documentable_id.toString());
};

export const download = async (document: DocumentRecord): Promise<File> => {
  const result = await fetcher.fetch(document.url, {}, { method: "GET" });
  const fileBlob = await result.blob();
  return new File([fileBlob], document.name, { type: fileBlob.type });
};
