import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import * as productActions from "app2/src/reducers/productProcess.actions";
import { IProduct } from "app/src/Models/Product";
import { OrgPriceListEditorCtrl } from "app/src/Orgs/tabs/OrgPriceListEditorCtrl";
import { ProductProcessRecords } from "app2/src/records/ProductProcessRecord";
import { ProductRecord } from "app2/src/records/Product";
import ProcessModal from "app2/src/components/Process/ProcessModal";
import { productProcesses } from "app2/src/selectors/productProcess.selectors";

const mapStateToProps = (state: RootState, ownProps: ProductProcessSelectorProps) => {
  return {
    processes: productProcesses(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: ProductProcessSelectorProps,
) => {
  return {
    loadProcesses: () => dispatch(productActions.AsyncActions.getProcesses()),
    process: (product_id: number, params: any) =>
      dispatch(productActions.AsyncActions.processProduct(product_id, params)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ProductProcessSelectorProps {
  product: IProduct;
  controller: OrgPriceListEditorCtrl;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ProductProcessSelectorProps;

export class ProductProcessSelector extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.process = this.process.bind(this);
  }

  public componentDidMount() {
    if (!this.props.processes.cached) {
      this.props.loadProcesses();
    }
  }

  public onClose() {
    this.props.controller.closeProcess();
  }

  public process(id: number, params: any) {
    this.props.process(id, params);
  }

  public render() {
    const { processes, product } = this.props;

    return <ProcessModal processes={processes} object={product} onClose={this.onClose} process={this.process} />;
  }
}

export default connector(ProductProcessSelector);
