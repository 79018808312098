import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { RootDispatchType } from "../store";
import { ProductProcessRecords, IProductProcessData } from "app2/src/records/ProductProcessRecord";
import { productService } from "app2/src/api/productProcess.service";
import { ProductRecord } from "app2/src/records/Product";
import * as taskActions from "app2/src/reducers/task.actions";

export const FETCH_PROCESSES = "@productProcesses/FETCH_PROCESSES";
export const RECEIVE_PROCESSES = "@productProcesses/RECEIVE_PROCESSES";
export const RECEIVE_PROCESS_ERRORS = "@productProcesses/RECEIVE_PROCESS_ERRORS";

export const Actions = {
  fetchProcesses: () => createAction(FETCH_PROCESSES),
  receiveProcesses: (processes: Partial<IProductProcessData>) => createAction(RECEIVE_PROCESSES, processes),
  receiveProcessErrors: (errors: string[]) => createAction(RECEIVE_PROCESS_ERRORS, errors),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  getProcesses: (): ThunkResult<Promise<ProductProcessRecords>> => {
    return (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.fetchProcesses());

      return productService.loadProcesses().then(
        (processes) => {
          dispatch(Actions.receiveProcesses(processes));

          return getState().getIn(["products", "processes"]);
        },
        (errors) => {
          dispatch(Actions.receiveProcessErrors(errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  processProduct: (product_id: number, params: any): ThunkResult<Promise<ProductRecord>> => {
    return (dispatch: RootDispatchType, getState: () => RootState) => {
      return productService.process(product_id, params).then(
        (response) => {
          const task = response.task;
          dispatch(taskActions.Actions.receiveTask(task));
          dispatch(taskActions.AsyncActions.pollTask(task));
          return response.product;
        },
        (errors) => {
          return Promise.reject(errors);
        },
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
