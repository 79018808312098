import * as ng from "angular";
import { IProductOptionsMatch, IProductOption, IProductOptionResponse } from "app/src/Models/ProductOption";
import {
  IProductOptionGroupsMatch,
  IProductOptionGroup,
  IProductOptionGroupResponse,
  IOptionGroupsMatchResponse,
} from "app/src/Models/ProductOptionGroup";
import { SortableOptions } from "app/src/Common/SortableOptions";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { IModalSettings } from "angular-ui-bootstrap";
import { IRepository } from "app/src/Common/Repository";
import { minMaxIndex } from "app2/src/helpers/Format";
import { dispatch } from "app2/src/storeRegistry";
import { Actions, AsyncActions } from "app2/src/reducers/sku.actions";

class ProductOptionsEditorCtrl implements ng.IComponentController {
  [s: string]: any;
  public showProductOptions = false;
  public productOptions: IProductOption[] = [];
  public optionGroupsMatches: IProductOptionGroupsMatch[];
  public optionsMatches: IProductOptionsMatch[];
  public optionGroupsMatchResponse: IOptionGroupsMatchResponse;
  public optionsResponse: IProductOptionResponse;
  public optionGroupResponse: IProductOptionGroupResponse;
  public spinnerPromise: ng.IPromise<any>;
  public sortableOptionGroups = new SortableOptions();
  public sortableProductOptions = new SortableOptions();
  public selectedOptionGroupsMatch: IProductOptionGroupsMatch;
  public currentOptionGroupPage = 1;
  public currentOptionPage = 1;
  public pageSize = 20;
  public initialized = false;

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $scope: ng.IScope,
    public Repository: IRepository,
    public Flash: IFlash,
    public $q: ng.IQService,
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public $rootScope: ng.IRootScopeService,
  ) {
    this.sortableOptionGroups.stop = (_event, ui) => {
      const { min, max } = minMaxIndex(ui.item.sortable.index, ui.item.sortable.dropindex);
      if (min === max) {
        return;
      }
      _.map(this.optionGroupsMatches, (optGroupMatch, index) => {
        optGroupMatch.sort_order = index;
        if (index >= min && index <= max) {
          Repository.ProductOptionGroup.bulk({
            price_list_id: this.optionGroupsMatchResponse.price_list_id,
            pog_ids: optGroupMatch.option_group_ids,
            edit_product_option_groups: optGroupMatch.matched,
            product_option_group: { sort_order: index },
          });
        }
      });
      this.updatePriceList();
      this.optionGroupsMatches = _.sortBy(this.optionGroupsMatches, "sort_order");
      if (!_.isEmpty(this.optionGroupResponse)) {
        this.optionGroupResponse.product_option_groups = _.sortBy(
          this.optionGroupResponse.product_option_groups,
          "sort_order",
        );
      }
    };

    this.sortableProductOptions.stop = (_event, ui) => {
      const { min, max } = minMaxIndex(ui.item.sortable.index, ui.item.sortable.dropindex);
      if (min === max) {
        return;
      }
      _.map(this.optionsMatches, (optMatch, index) => {
        optMatch.sort_order = index;
        if (index >= min && index <= max) {
          Repository.ProductOption.bulk({
            price_list_id: this.optionGroupsMatchResponse.price_list_id,
            edit_product_options: optMatch.matched,
            product_option: { sort_order: index },
          });
        }
      });
      this.updatePriceList();
      this.optionsMatches = _.sortBy(this.optionsMatches, "sort_order");
      this.productOptions = _.sortBy(this.productOptions, "sort_order");
    };
  }

  public $onChanges() {
    if (!_.isEmpty(this.optionGroupsMatchResponse)) {
      this.optionGroupsMatchResponse.$promise.then(() => {
        this.optionGroupsMatches = _.sortBy(this.optionGroupsMatchResponse.option_groups_matches, "sort_order");
        if (!this.initialized) {
          if (this.$stateParams.option_group_match_id) {
            this.selectOptionGroupsMatchById(this.$stateParams.option_group_match_id);
          } else {
            this.back();
          }
        }
        this.initialized = true;
      });
    }
  }

  public removeOptionGroupsMatch(optionGroupsMatch) {
    const promises = [];
    _.each(optionGroupsMatch.matched, (group: IProductOptionGroup) => {
      promises.push(
        group.$delete().then(() => {
          this.optionGroupResponse.product_option_groups = _.filter(
            this.optionGroupResponse.product_option_groups,
            (pog: IProductOptionGroup) => {
              return group.id !== pog.id;
            },
          );
        }),
      );
    });
    this.spinnerPromise = this.$q
      .all(promises)
      .then(() => {
        this.refreshOptionGroupsMatches();
        this.Flash.addMessage(FlashLevels.success, "Product Option Group successfully deleted!");
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "Product Option Group could not be deleted. Please try again. If the problem persists, please contact support.",
        );
      });
  }

  public editOptionGroupsMatch(optionGroupsMatch) {
    const copiedObject: IProductOptionGroup = _.clone(optionGroupsMatch);

    this.openProductOptionGroupEditor(copiedObject).result.then((selection) => {
      const request = this.Repository.ProductOptionGroup.bulk({
        price_list_id: this.optionGroupsMatchResponse.price_list_id,
        pog_ids: optionGroupsMatch.option_group_ids,
        edit_product_option_groups: optionGroupsMatch.matched,
        product_option_group: {
          name: selection.option_group.name,
          selection_mode: selection.option_group.selection_mode,
          pog_type: selection.option_group.pog_type,
        },
      });

      this.spinnerPromise = request.$promise
        .then(() => {
          this.updatePriceList();
          if (_.isEmpty(this.optionGroupResponse)) {
            optionGroupsMatch.name = selection.option_group.name;
            optionGroupsMatch.selection_mode = selection.option_group.selection_mode;
            optionGroupsMatch.pog_type = selection.option_group.pog_type;
          } else {
            this.refreshOptionGroupsMatches();
          }
          this.Flash.addMessage(FlashLevels.success, "Product Option Group successfully edited.");
        })
        .catch(() => {
          this.Flash.addMessage(
            FlashLevels.danger,
            "Product Option Group could not be edited. Please try again. If the problem persists, please contact support.",
          );
        });
    });
  }

  public refreshOptionGroupsMatches() {
    this.optionGroupsMatches = this.Repository.ProductOptionGroup.groupOptionGroupsByMatchId(
      this.optionGroupResponse.product_option_groups,
    );
  }

  public openProductOptionGroupEditor(option_group: IProductOptionGroup) {
    return this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "OrgProductOptionGroupEditorCtrl",
      controllerAs: "ctrl",
      templateUrl: "src/Orgs/tabs/PriceListEditor/product_option_group_editor.html",

      resolve: {
        option_group: option_group,
      },
    });
  }

  public selectOptionGroupsMatchById(optionGroupMatchId) {
    const match = _.find(this.optionGroupsMatchResponse.option_groups_matches, (match) => {
      return match.match_id === optionGroupMatchId;
    });
    if (match) {
      this.selectOptionGroupsMatch(match);
    }
  }

  public selectOptionGroupsMatch(optionGroupsMatch) {
    this.$state.go("root.org_show.price_list_header.editor", { option_group_match_id: optionGroupsMatch.match_id });
    this.selectedOptionGroupsMatch = optionGroupsMatch;
    this.loadOptions(optionGroupsMatch);
    this.spinnerPromise = this.optionsResponse.$promise.then(() => {
      this.productOptions = [];
      this.productOptions = optionGroupsMatch.options;
      this.productOptions = _.sortBy(this.productOptions, "name");
      this.refreshOptionsMatches();
      this.showProductOptions = true;
    });
  }

  public back() {
    this.$state.go("root.org_show.price_list_header.editor", { option_group_match_id: null });
    this.productOptions = [];
    this.selectedOptionGroupsMatch = <IProductOptionGroupsMatch>{};
    this.showProductOptions = false;
    this.refreshOptionsMatches();
  }

  public editProductOption(option) {
    const copiedObject: IProductOption = JSON.retrocycle(JSON.parse(JSON.stringify(JSON.decycle(option))));

    this.openProductOptionEditor(copiedObject, false).result.then((selection) => {
      const deferred = this.$q.defer();
      dispatch(AsyncActions.saveOrCreateOrDestroy(selection.option.sku_id)).then(() => {
        deferred.resolve();
      });

      this.spinnerPromise = deferred.promise.then(() => {
        return selection.option
          .$save({ "include[]": ["sku"] })
          .then(() => {
            this.updatePriceList();
            option.copyValues(selection.option);
            this.refreshOptionsMatches();
            this.Flash.addMessage(FlashLevels.success, "Product Option successfully edited.");
          })
          .catch(() => {
            this.Flash.addMessage(
              FlashLevels.danger,
              "Product Option could not be edited. Please try again. If the problem persists, please contact support.",
            );
          });
      });
    });
  }

  public editOptionsMatch(optionsMatch) {
    if (optionsMatch.matched.length === 1) {
      return this.editProductOption(optionsMatch.matched[0]);
    }
    const copiedObject: IProductOption = JSON.retrocycle(
      JSON.parse(JSON.stringify(JSON.decycle(optionsMatch.matched[0]))),
    );

    this.openProductOptionEditor(copiedObject, true).result.then((selection) => {
      const request = this.Repository.ProductOption.bulk({
        price_list_id: this.optionGroupsMatches[0].price_list_id,
        edit_product_options: optionsMatch.matched,
        product_option: {
          name: selection.option.name,
          description: selection.option.description,
        },
      });

      // noinspection TypeScriptUnresolvedVariable
      this.spinnerPromise = request.$promise
        .then(() => {
          this.updatePriceList();
          this.refreshOptionsMatches();
          this.Flash.addMessage(FlashLevels.success, "Product Option successfully edited.");
        })
        .catch(() => {
          this.Flash.addMessage(
            FlashLevels.danger,
            "Product Option could not be edited. Please try again. If the problem persists, please contact support.",
          );
        });
    });
  }

  public openProductOptionEditor(option: IProductOption, multi: boolean) {
    return this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "OrgProductOptionEditorCtrl",
      controllerAs: "ctrl",
      templateUrl: "src/Orgs/tabs/PriceListEditor/product_option_editor.html",

      resolve: {
        option: option,
        multi: multi,
      },
    });
  }

  public removeOptionsMatch(optionsMatch: IProductOptionsMatch) {
    const promises = [];
    _.each(optionsMatch.matched, (option) => {
      promises.push(
        option.$delete().then(() => {
          this.productOptions = _.filter(this.productOptions, (po) => {
            return option.id !== po.id;
          });
        }),
      );
    });
    this.spinnerPromise = this.$q
      .all(promises)
      .then(() => {
        this.refreshOptionsMatches();
        this.Flash.addMessage(FlashLevels.success, "Product Option Group successfully deleted!");
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "Product Option Group could not be deleted. Please try again. If the problem persists, please contact support.",
        );
      });
  }

  public refreshOptionsMatches() {
    this.optionsMatches = this.Repository.ProductOption.groupOptionsByMatchId(this.productOptions);
  }

  public matchOptionsMatch(optionsMatch) {
    this.openMatchWizard(optionsMatch, this.productOptions).result.then((list: IProductOptionGroup[]) => {
      const promises = [];

      const matched_options = _.filter(list, (option: IProductOptionGroup) => {
        return option.check === true && option.match_id !== optionsMatch.match_id;
      });
      if (matched_options.length > 0) {
        const matched_request = this.Repository.ProductOption.bulk({
          price_list_id: this.optionGroupsMatchResponse.price_list_id,
          edit_product_options: matched_options,
          product_option: { match_id: optionsMatch.match_id },
        });
        promises.push(matched_request.$promise);
      }

      const unmatched_options = _.filter(list, (option: IProductOptionGroup) => {
        return option.check === false && option.match_id === optionsMatch.match_id;
      });
      if (unmatched_options.length > 0) {
        const unmatched_request = this.Repository.ProductOption.bulk({
          price_list_id: this.optionGroupsMatchResponse.price_list_id,
          edit_product_options: unmatched_options,
          product_option: { match_id: "default" },
        });
        promises.push(unmatched_request.$promise);
      }

      this.spinnerPromise = this.$q.all(promises).then(() => {
        this.updatePriceList();
        this.Flash.addMessage(FlashLevels.success, "Successfully matched!");
        this.refreshOptionsMatches();
        this.sortableProductOptions.stop(null, null);
      });
    });
  }

  public loadOptionGroups() {
    if (_.isEmpty(this.optionGroupResponse)) {
      this.optionGroupResponse = <IProductOptionGroupResponse>this.Repository.ProductOptionGroup.query({
        price_list_id: this.optionGroupsMatchResponse.price_list_id,
      });
      this.spinnerPromise = <ng.IPromise<any>>this.optionGroupResponse.$promise;
    }
  }

  public loadOptions(optionGroupsMatch) {
    if (_.isEmpty(optionGroupsMatch.options)) {
      this.optionsResponse = <IProductOptionResponse>this.Repository.ProductOption.byGroupMatchId({
        price_list_id: this.optionGroupsMatchResponse.price_list_id,
        option_group_match_id: optionGroupsMatch.match_id,
      });
      this.spinnerPromise = this.optionsResponse.$promise.then(() => {
        optionGroupsMatch.options = this.optionsResponse.product_options;
      });
    }
  }

  public matchOptionGroupsMatch(optionGroupsMatch) {
    this.loadOptionGroups();
    this.optionGroupResponse.$promise.then(() => {
      this.openMatchWizard(optionGroupsMatch, this.optionGroupResponse.product_option_groups).result.then(
        (group_list) => {
          const promises = [];

          const matched_option_groups = _.filter(group_list, (option_group) => {
            return option_group.check === true && option_group.match_id !== optionGroupsMatch.match_id;
          });

          if (matched_option_groups.length > 0) {
            const matched_request = this.Repository.ProductOptionGroup.bulk({
              price_list_id: this.optionGroupsMatchResponse.price_list_id,
              pog_ids: optionGroupsMatch.option_group_ids,
              edit_product_option_groups: matched_option_groups,
              product_option_group: { match_id: optionGroupsMatch.match_id },
            });
            promises.push(matched_request.$promise);
          }

          const unmatched_option_groups = _.filter(group_list, (option_group) => {
            return option_group.check === false && option_group.match_id === optionGroupsMatch.match_id;
          });

          if (unmatched_option_groups.length > 0) {
            const unmatched_request = this.Repository.ProductOptionGroup.bulk({
              price_list_id: this.optionGroupsMatchResponse.price_list_id,
              pog_ids: optionGroupsMatch.option_group_ids,
              edit_product_option_groups: unmatched_option_groups,
              product_option_group: { match_id: "default" },
            });
            promises.push(unmatched_request.$promise);
          }

          this.spinnerPromise = this.$q.all(promises).then(() => {
            this.updatePriceList();
            this.refreshOptionGroupsMatches();
            this.Flash.addMessage(FlashLevels.success, "Successful match!");
            this.sortableOptionGroups.stop(null, null);
          });
        },
      );
    });
  }

  public openMatchWizard(matchesInput, objects): { result: ng.IPromise<IProductOptionGroup[]> } {
    return this.$uibModal.open(<IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "MatchWizardCtrl",
      controllerAs: "ctrl",
      size: "lg",
      templateUrl: "src/Orgs/tabs/PriceListEditor/match_wizard.html",

      resolve: {
        objects: () => objects,
        matchesInput: matchesInput,
      },
    });
  }

  public updatePriceList() {
    this.$rootScope.$broadcast("price_list_header:update_price_list");
  }
}

export class ProductOptionsEditorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Orgs/tabs/PriceListEditor/product_options_editor_component.html";
  public bindings: any = {
    optionGroupsMatchResponse: "<",
  };

  constructor() {
    this.controller = ProductOptionsEditorCtrl;
    this.controller.$inject = [
      "$uibModal",
      "$scope",
      "Repository",
      "Flash",
      "$q",
      "$state",
      "$stateParams",
      "$rootScope",
    ];
  }
}
