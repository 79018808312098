import { fetcher } from "../helpers/Fetcher";
import { EventRecord, IEventData } from "../records/Event";
import { IMetaPaginationData } from "../records/MetaPagination";
import { QueryParamsRecord } from "../records/Page";
import { ITaskData } from "../records/Task";
import { listParams } from "./Api";

export const url = "api/v1/events";
export interface IInviteParams {
  emails: string;
  event_id: number;
  summary: string;
}
export interface IEventIndexResponse {
  events: IEventData[];
  meta: { pagination: IMetaPaginationData };
}
export interface IEventQueryOptions {
  start?: Date;
  end?: Date;
  start_time?: Date;
  end_time?: Date;
  start_time_after?: Date;
  user_ids?: number[] | number;
  jobs_only?: boolean;
  orgs_only?: boolean;
  sort_by?: string;
  sort_order?: string;
  includes?: string[];
}

export const load = (queryParams: QueryParamsRecord): Promise<IEventIndexResponse> => {
  const jobId = queryParams.getIn(["parameters", "jobId"]);
  const actionUrl = jobId ? `api/v1/jobs/${jobId}/events` : url;

  const params = listParams(queryParams);
  const options = cleanQueryOptions(queryParams.parameters.toJS());
  const parameters = { ...params, ...options };

  if (!queryParams.getIn(["parameters", "sort_order"])) parameters["sort_order"] = "asc";
  if (!queryParams.getIn(["parameters", "sort_by"])) parameters["sort_by"] = "start_time";
  if (parameters.jobId) delete parameters.jobId;

  return fetcher.get<IEventIndexResponse>(actionUrl, parameters);
};

export const list = (params: any): Promise<IEventIndexResponse> => {
  const options = cleanQueryOptions(params);
  return fetcher.get(url, options);
};

export const create = (event: EventRecord, includes: string[]): Promise<{ event: IEventData }> => {
  return fetcher.post(url, { event: event.toJS(), include: includes });
};

export const update = (event: EventRecord, includes: string[]): Promise<{ event: IEventData }> => {
  return fetcher.patch(fetcher.joinUrls(url, event.id.toString()), { event: event.toJS(), include: includes });
};

export const deleteEvent = (eventId: number): Promise<{ event: IEventData }> => {
  return fetcher.delete(fetcher.joinUrls(url, eventId.toString()));
};

export const sendInvite = (jobId: number, inviteParams: IInviteParams): Promise<{ task: ITaskData }> => {
  return fetcher.post(`api/v1/jobs/${jobId}/invite`, inviteParams);
};

export const resultEvent = (event: EventRecord): Promise<{ event: IEventData }> => {
  return fetcher.patch(fetcher.joinUrls(url, event.id.toString(), "result"), { event: event.toJS() });
};

const cleanQueryOptions = (src: IEventQueryOptions): any => {
  const props: any = { ...src };
  if (src.start_time) {
    props.start_time = src.start_time.toUTCString();
  }

  if (src.end_time) {
    props.end_time = src.end_time.toUTCString();
  }

  if (src.start) {
    props.start = src.start.toUTCString();
  }

  if (src.start_time_after) {
    props.start_time_after = src.start_time_after.toUTCString();
  }

  if (src.end) {
    props.end = src.end.toUTCString();
  }

  if (src.includes) {
    props["include[]"] = src.includes;
  }
  return props;
};
