import * as estimateGroupActions from "./estimateGroup.actions";
import { Map, Record, List } from "immutable";
import { EstimateGroupRecord, fromJSON } from "../records/EstimateGroup";
import { RootState, RootActions } from ".";
import * as estimateLineItemActions from "./estimateLineItem.actions";
import { reducer as estimateLineItemReducer } from "./estimateLineItem.reducer";
import { updateBaseTotal } from "app2/src/selectors/estimateGroup.selectors";
import { model as estimateModel } from "./estimate.reducer";

export const EstimateGroupStateRecord = Record({
  byId: Map<number, EstimateGroupRecord>(),
  lastSavedById: Map<number, EstimateGroupRecord>(),
  unsavedId: -1,
});

export const initialState = EstimateGroupStateRecord();

export type EstimateState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "estimateGroups";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let estimateGroup: EstimateGroupRecord;
  switch (action.type) {
    case estimateGroupActions.RECEIVE_ESTIMATE_GROUP:
      estimateGroup = fromJSON({ ...action.payload.estimateGroup });

      action.payload.estimateGroup.line_items.map((li) => {
        state = estimateLineItemReducer(state, estimateLineItemActions.Actions.receiveEstimateLineItem(li));
      });

      state = state.setIn([model, "byId", estimateGroup.id], estimateGroup);
      state = reducer(state, estimateGroupActions.Actions.updateBaseTotal(estimateGroup.id));

      const totEstimateGroup = state.getIn([model, "byId", estimateGroup.id]);
      return state.setIn([model, "lastSavedById", estimateGroup.id], totEstimateGroup);

    case estimateGroupActions.DUPLICATE_ESTIMATE_GROUPS:
      const estimate = state.getIn([estimateModel, "byId", action.payload.estimateId]);

      let groupIds = List();
      let unsavedId = state.getIn([model, "unsavedId"]);
      action.payload.groupIds.map((gid: number) => {
        let group = state.getIn([model, "byId", gid]);
        group = group.set("id", unsavedId);
        let unsavedRoomEstimationId = -1;
        group = group.set(
          "room_estimations",
          group.room_estimations.map((roomEstimation) => {
            roomEstimation = roomEstimation.set("id", unsavedRoomEstimationId).set("estimate_group_id", unsavedId);
            unsavedRoomEstimationId -= 1;
            return roomEstimation;
          }),
        );
        groupIds = groupIds.push(unsavedId);
        state = state.setIn([model, "byId", group.id], group);
        state = estimateLineItemReducer(
          state,
          estimateLineItemActions.Actions.duplicateEstimateLineItems(group.id, group.line_item_ids),
        );
        unsavedId -= 1;
      });

      return state
        .setIn([estimateModel, "byId", estimate.id, "group_ids"], groupIds)
        .setIn([model, "unsavedId"], unsavedId);

    case estimateGroupActions.UPDATE_BASE_TOTAL:
      return state.setIn(
        [model, "byId", action.payload.estimateGroupId],
        updateBaseTotal(state, { estimateGroupId: action.payload.estimateGroupId }),
      );

    default:
      return state;
  }
};
