import * as React from "react";
import { List, Record } from "immutable";

interface ContextProps {
  selectedDataTypes: List<string>;
  selectedOrgs: List<number>;
}

const defaultContextProps: ContextProps = {
  selectedDataTypes: List(),
  selectedOrgs: List(),
};

export class ContextRecord extends Record(defaultContextProps) implements ContextProps {}

export const Context = React.createContext(new ContextRecord());
