import { IJob } from "app/src/Models/Job";

export class OrderCoreLogicCtrl {
  public static $inject = ["$uibModalInstance", "job"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public job: IJob,
  ) {}
}
