import { FeeListCtrl } from "./FeeListCtrl";
import { FeeNewCtrl } from "./FeeNewCtrl";
import { FeeShowCtrl } from "./FeeShowCtrl";

export class FeesRoutes {
  public static load(app: ng.IModule) {
    app.controller("FeeListCtrl", FeeListCtrl);
    app.controller("FeeNewCtrl", FeeNewCtrl);
    app.controller("FeeShowCtrl", FeeShowCtrl);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("root.fee_list", <ng.ui.IState>{
        url: "/fees",
        views: {
          "container@": {
            templateUrl: "src/Billing/Fees/list.html",
            controller: "FeeListCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.fee_new", <ng.ui.IState>{
        url: "/fees/new",
        views: {
          "container@": {
            templateUrl: "src/Billing/Fees/new.html",
            controller: "FeeNewCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.fee_edit", <ng.ui.IState>{
        url: "/fees/:id/edit",
        views: {
          "container@": {
            templateUrl: "src/Billing/Fees/new.html",
            controller: "FeeNewCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.fee_show", <ng.ui.IState>{
        url: "/fees/:id",
        views: {
          "container@": {
            templateUrl: "src/Billing/Fees/show.html",
            controller: "FeeShowCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      });
  }
}
