import { IDoc, IDocResource } from "app/src/Models/Doc";
import { IImage, IImageResource } from "app/src/Models/Image";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEventingFactory } from "app/src/Common/EventingFactory";
import { IBaseConfig } from "./IBaseConfig";

export interface IFileQueueFactory {
  queue: IFileQueue;
  temp_queue: IFileQueue;
  getObject(file, parent, display?): ng.IPromise<any>;
  getObjectBlob(type, blob, parent, display?): ng.IPromise<any>;
  clearTempQueue(): void;
  clearQueue(): void;
  getFileId(queue_name): number;
  setQueue(): void;
  cleanFileQueue(parent: any, clean_parent: boolean);
  mergeQueue(parent: any, queue: IFileQueue);
  uploadFileQueue(parent: any);
  uploadFile(type: string, model: string, id: number, file: any, sort_order: number, display?): ng.IPromise<any>;
  getRemoteFile(url: string, object: any): ng.IPromise<any>;
}

export interface IFileQueueInit {
  getInstance(): IFileQueueFactory;
}

export interface IFileQueue {
  images: {};
  documents: {};
}

class FileQueueInit implements IFileQueueInit {
  constructor(
    public Doc: IDocResource,
    public Image: IImageResource,
    public EstimatorService: EstimatorService,
    public $q: ng.IQService,
    public Upload: ng.angularFileUpload.IUploadService,
    public BaseConfig: IBaseConfig,
    public EventingFactory: IEventingFactory,
    public $http: ng.IHttpService,
  ) {}

  public getInstance() {
    return new FileQueueFactory(
      this.Doc,
      this.Image,
      this.EstimatorService,
      this.$q,
      this.Upload,
      this.BaseConfig,
      this.EventingFactory,
      this.$http,
    );
  }
}

class FileQueueFactory implements IFileQueueFactory {
  public queue: IFileQueue = <IFileQueue>{ images: {}, documents: {} };
  public temp_queue: IFileQueue = <IFileQueue>{ images: {}, documents: {} };
  public fileValues: string[] = ["image", "document"];

  constructor(
    public Doc: IDocResource,
    public Image: IImageResource,
    public EstimatorService: EstimatorService,
    public $q: ng.IQService,
    public Upload: ng.angularFileUpload.IUploadService,
    public BaseConfig: IBaseConfig,
    public EventingFactory: IEventingFactory,
    public $http: ng.IHttpService,
  ) {}

  public getObject(file, parent, display = "do_not_display"): ng.IPromise<any> {
    const type = _.fileType(file);
    const app = this;
    return this.$q(function (resolve) {
      const newFile = new FileReader();
      newFile.onload = function (e: any) {
        //noinspection TypeScriptUnresolvedVariable
        const newFile = e.target.result;
        app.setupTempQueueObject(type, file, parent, display, newFile);
        resolve();
      };
      newFile.readAsDataURL(file);
    });
  }

  public getObjectBlob(type, blob, parent, display = "do_not_display"): ng.IPromise<any> {
    let fileType: string;

    try {
      fileType = blob.split(",")[0].split(":")[1].split(";")[0].split("/")[1];
    } catch (e) {
      console.error(blob, e);
      fileType = "bmp";
    }

    const file = _.dataURLToFile(blob, "new." + fileType);
    this.setupTempQueueObject(type, file, parent, display, blob);
    const promise = this.$q.defer();
    promise.resolve();
    return promise.promise;
  }

  public setQueue() {
    _.each(this.fileValues, (file: string) => {
      _.each(Object.keys(this.temp_queue[file + "s"]), (key: string) => {
        this.queue[file + "s"][key] = this.temp_queue[file + "s"][key];
      });
    });
    this.clearTempQueue();
  }

  public clearTempQueue() {
    this.temp_queue.images = {};
    this.temp_queue.documents = {};
  }

  public clearQueue() {
    this.clearTempQueue();
    this.queue.images = {};
    this.queue.documents = {};
  }

  public getFileId(queue_name: string): number {
    const firstId = -1;
    const keys = Object.keys(this.temp_queue[queue_name + "s"]).concat(Object.keys(this.queue[queue_name + "s"]));

    const currentMin = _.chain(keys)
      .map((k) => {
        return _.parseFloat(k);
      })
      .min()
      .value();

    return _.min([firstId, currentMin - 1]);
  }

  public cleanFileQueue(parent: any, clean_parent: boolean) {
    _.each(this.fileValues, (type) => {
      if (parent[type + "s"].length > 0) {
        _.each(parent[type + "s"], (obj: IDoc | IImage) => {
          if (obj.id < 0 && !obj._destroy && parent.classy === "EstimateLineItem") {
            this.queue[type + "s"][obj.id].object.li_sort_order = parent.sort_order;
            this.queue[type + "s"][obj.id].object.g_sort_order = parent.estimateGroup.sort_order;
          } else if (obj.id < 0 && obj._destroy) {
            delete this.queue[type + "s"][obj.id];
          }
        });
        if (clean_parent) {
          parent[type + "s"] = _.filter(parent[type + "s"], (obj: IDoc | IImage) => {
            return obj.id > 0;
          });
        }
      }
    });
  }

  public uploadFileQueue(parent: any): ng.IPromise<any> {
    const promises2 = [];
    _.each(this.fileValues, (type) => {
      const queue = this.queue[type + "s"];
      _.each(Object.keys(queue), (key) => {
        const obj = queue[key];
        let new_parent = parent;
        if (parent.classy === "Estimate") {
          new_parent = _.find(parent.lineItems(), (l) => {
            return l.estimateGroup.sort_order === obj.object.g_sort_order && l.sort_order === obj.object.li_sort_order;
          });
          if (!new_parent) {
            return;
          }
        }
        const parent_class = _.toUnderscore(new_parent.classy);
        promises2.push(
          this.uploadFile(
            type,
            parent_class + "s",
            new_parent.id,
            obj.file,
            obj.object.sort_order,
            obj.object.display,
          ).then((respObj) => {
            if (new_parent.classy !== "EstimateTemplate") {
              this.EventingFactory.trackEvent(type + " saved to " + parent_class, {
                [parent_class]: new_parent.id,
                [type]: respObj.id,
              });
              new_parent[type + "s"].push(respObj);
              new_parent[type + "s"] = _.sortBy(new_parent[type + "s"], "sort_order");
            } else {
              const group = _.find(new_parent.template.groups, (g: any) => {
                return g.sort_order === obj.object.g_sort_order;
              });
              const custom_product = _.find(group.custom_products, (cp: any) => {
                return cp.sort_order === obj.object.li_sort_order;
              });
              custom_product[type + "_ids"].push(respObj.id);
              this.EventingFactory.trackEvent(type + " saved to estimate_template", {
                estimate_template: new_parent.id,
                [type]: respObj.id,
              });
              return this.EstimatorService.updateTemplate(new_parent);
            }
          }),
        );
      });
    });
    return this.$q.all(promises2).finally(() => {
      if (parent.classy !== "EstimateTemplate") {
        this.clearQueue();
      }
    });
  }

  public uploadFile(
    type: string,
    model: string,
    id: number,
    file: any,
    sort_order: number,
    display = "do_not_display",
  ): ng.IPromise<any> {
    return this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
      url: this.BaseConfig.BASE_URL + "/api/v1/" + model + "/" + id + "/" + type + "s",
      data: { [type]: { file: file, sort_order: sort_order, display: display } },
    }).then((resp) => {
      let respObj: any;
      const props: any = {};

      if (type === "document") {
        respObj = this.Doc.fromJSON(resp.data[type]);
        props.document = respObj.id;
      } else if (type === "image") {
        respObj = this.Image.fromJSON(resp.data[type]);
        props.image = respObj.id;
      }
      this.EventingFactory.trackEvent("uploaded " + type, props);
      return respObj;
    });
  }

  public getRemoteFile(url, lineItem): ng.IPromise<any> {
    const request_url = this.BaseConfig.BASE_URL + "/integrations/remote_file_url";
    const params = { params: { url: url } };
    const get = this.$http
      .get(request_url, params)
      .then((resp) => {
        const filename = url.split("/").reverse()[0];
        const file = _.dataURLToFile(resp.data, filename);
        let display = "do_not_display";
        if (_.fileType(file) === "document") {
          display = "estimate";
        }
        return this.getObject(file, lineItem, display);
      })
      .catch(() => {
        // don't want to stop on fail
      });
    return get;
  }

  public mergeQueue(parent, temp_queue) {
    _.each(Object.keys(temp_queue.images), (key) => {
      parent.images = _.filter(parent.images, (img: IImage) => {
        return img.id.toString() !== key;
      });
      temp_queue.images[key].object.id = this.getFileId("image");
      parent.images.unshift(temp_queue.images[key].object);
      this.addTempQueue("image", temp_queue.images[key]);
    });
    _.each(Object.keys(temp_queue.documents), (key) => {
      parent.documents = _.filter(parent.documents, (doc: IDoc) => {
        return doc.id.toString() !== key;
      });
      temp_queue.documents[key].object.id = this.getFileId("document");
      parent.documents.unshift(temp_queue.documents[key].object);
      this.addTempQueue("document", temp_queue.documents[key]);
    });
  }

  private setupTempQueueObject(type, file, parent, display, blob) {
    const data = {
      id: this.getFileId(type),
      name: file.name,
      type: type,
      created_at: new Date(Date.now()),
      [type + "able_type"]: parent.classy,
      [type + "able_id"]: parent.id,
      sort_order: -1,
      display: display,
    };
    if (parent[type + "s"][0]) {
      data["sort_order"] = parent[type + "s"][0].sort_order - 1;
    }

    this.EventingFactory.trackEvent(type + " added to temp queue", {
      [_.toUnderscore(parent.classy)]: parent.id,
      [type]: data.id,
    });
    if (type === "document") {
      const doc: IDoc = this.Doc.fromJSON(data);
      parent.documents.unshift(doc);
      this.addTempQueue(type, { file: file, object: doc });
    } else if (type === "image") {
      const img: IImage = this.Image.fromJSON(data);
      img._blob = blob;
      parent.images.unshift(img);
      this.addTempQueue(type, { file: file, object: img });
    }
  }

  private addTempQueue(queue_name: string, item: any) {
    this.temp_queue[queue_name + "s"][item.object.id] = item;
  }
}

const factory = (
  Doc: IDocResource,
  Image: IImageResource,
  EstimatorService: EstimatorService,
  $q: ng.IQService,
  Upload: ng.angularFileUpload.IUploadService,
  BaseConfig: IBaseConfig,
  EventingFactory: IEventingFactory,
  $http: ng.IHttpService,
) => {
  return new FileQueueInit(Doc, Image, EstimatorService, $q, Upload, BaseConfig, EventingFactory, $http);
};

factory.$inject = ["Doc", "Image", "EstimatorService", "$q", "Upload", "BaseConfig", "EventingFactory", "$http"];

export default factory;
