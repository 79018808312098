import * as React from "react";
import track from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";
import { ThunkDispatch } from "redux-thunk";
import { RootActions, RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { Jumbotron, Container, Button, Row, Col } from "react-bootstrap";
import { currentUserAccesses, currentUser } from "app2/src/selectors/access.selectors";
import { CustomLink } from "app2/src/components/Common/CustomLink";
import { Actions as commonactions } from "app2/src/reducers/components/common.actions";
import { LocationStateRecord } from "app2/src/records/LocationState";
import { StoreRegistry } from "app2/src/storeRegistry";

export const mapStateToProps = (state: RootState, ownProps: PermissionsErrorPageProps) => {
  return {
    hasMultipleAccesses: (currentUser(state)?.accesses_count || 0) > 1,
    currentSavedLocationState: state.getIn(["components", "common", "savedLocationState"]),
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, RootActions>,
  ownProps: PermissionsErrorPageProps,
) => {
  return {
    clearSavedLocationState: () => dispatch(commonactions.setSavedLocationState(null)),
    setSavedLocationState: (location: LocationStateRecord) => dispatch(commonactions.setSavedLocationState(location)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface PermissionsErrorPageProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PermissionsErrorPageProps;

export interface PermissionsErrorPageState {
  savedLocation: LocationStateRecord;
}

@track(
  () => {
    return {
      category: "Permissions Error Page",
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: false,
  },
)
export class PermissionsErrorPage extends React.Component<Props, PermissionsErrorPageState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      savedLocation: props.currentSavedLocationState,
    };

    this.withMultipleAccesses = this.withMultipleAccesses.bind(this);
    this.withSingleAccess = this.withSingleAccess.bind(this);
    this.setSaveLocation = this.setSaveLocation.bind(this);
    this.homeClicked = this.homeClicked.bind(this);
  }

  public componentDidMount(): void {
    const { clearSavedLocationState } = this.props;

    clearSavedLocationState();
  }

  public render(): JSX.Element {
    const { hasMultipleAccesses } = this.props;
    return (
      <Container>
        <Jumbotron>{hasMultipleAccesses ? this.withMultipleAccesses() : this.withSingleAccess()}</Jumbotron>
      </Container>
    );
  }

  protected withMultipleAccesses(): JSX.Element {
    const $state = StoreRegistry.get<ng.ui.IStateService>("$state");

    return (
      <Row>
        <Col sm={12} data-testid="error-message" className="text-center">
          Sorry, the requested resource does not exist or you do not have permission to view.
        </Col>
        <Col sm={12} className="text-center">
          Request access through a different Organization:&nbsp;
          <CustomLink
            data-testid="chooser-btn"
            tag={Button}
            variant="primary"
            to={$state.href("root.accesses")}
            onClickCapture={this.setSaveLocation}>
            Choose Organization
          </CustomLink>
        </Col>
        <Col sm={12} className="text-center">
          <CustomLink
            data-testid="home-btn"
            tag={Button}
            variant="cancel"
            to={$state.href("root.job_list")}
            onClickCapture={this.homeClicked}>
            Go Home
          </CustomLink>
        </Col>
      </Row>
    );
  }

  protected withSingleAccess(): JSX.Element {
    return (
      <>
        <Row>
          <Col sm={12} data-testid="error-message" className="text-center">
            Sorry, the requested resource does not exist.
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="text-center">
            <CustomLink data-testid="home-btn" tag={Button} to="/" onClickCapture={this.homeClicked}>
              Go Home
            </CustomLink>
          </Col>
        </Row>
      </>
    );
  }

  @track({ action: "Choosing Organization" })
  protected setSaveLocation(): void {
    const { setSavedLocationState } = this.props;
    const { savedLocation } = this.state;
    setSavedLocationState(savedLocation);
  }

  @track({ action: "Go Home" })
  protected homeClicked(): void {}
}

export default connector(PermissionsErrorPage);
