export class FakeFormAction implements ng.IDirective {
  public restrict = "A";
  public link = (s, e, a) => {
    e.attr("action", "");
  };

  public static factory(): ng.IDirectiveFactory {
    const directive = () => new FakeFormAction();
    directive.$inject = [];
    return directive;
  }
}
