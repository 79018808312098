import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import * as segmentActions from "app2/src/reducers/measurements/segment.actions";
import { segment } from "app2/src/selectors/measurements/segment.selectors";
import { RootDispatchType } from "app2/src/store";

interface SegmentDisplayProps {
  segmentId: number;
  readOnly: boolean;
}

const mapStateToProps = (state: RootState, ownProps: SegmentDisplayProps) => {
  return {
    segment: segment(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: SegmentDisplayProps) => {
  return {
    setGateCount: (segmentId: number, gateCount: number) =>
      dispatch(segmentActions.AsyncActions.setGateCount(segmentId, gateCount)),
    setGateLength: (segmentId: number, gateLength: number) =>
      dispatch(segmentActions.AsyncActions.setGateLength(segmentId, gateLength)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & SegmentDisplayProps;

class SegmentMeasurements extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);

    this.inputChanged = this.inputChanged.bind(this);
  }

  public render() {
    const { segment, readOnly } = this.props;

    if (!segment) return null;

    if (segment._destroy) return null;

    return (
      <tr data-testid={`segment-measurements-${segment.id}`}>
        <td data-testid="segment-name" className="align-self-center">
          {segment.name}
        </td>
        <td data-testid="segment-length" className="align-self-center">
          {segment.length.toFixed(1)}
        </td>
        <td>
          {readOnly ? (
            segment.gateCount
          ) : (
            <input
              data-testid={`gateCount+${segment.id}`}
              name={`gateCount+${segment.id}`}
              value={segment.gateCount}
              className="form-control"
              type="number"
              step="1"
              min="0"
              onChange={this.inputChanged}
            />
          )}
        </td>
        <td>
          {readOnly ? (
            segment.gateLength
          ) : (
            <input
              data-testid={`gateLength+${segment.id}`}
              name={`gateLength+${segment.id}`}
              value={segment.gateLength}
              className="form-control"
              type="number"
              step="1"
              min="0"
              onChange={this.inputChanged}
            />
          )}
        </td>
        <td data-testid="segment-net-length" className="align-self-center">
          {segment.netLength.toFixed(1)}
        </td>
      </tr>
    );
  }

  protected inputChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const { setGateCount, setGateLength } = this.props;

    const [kind, segmentIdStr] = e.target.name.split("+");

    const value = parseInt(e.target.value) || 0;
    const segmentId = parseInt(segmentIdStr);

    if (kind === "gateCount") {
      setGateCount(segmentId, value);
    } else if (kind === "gateLength") {
      setGateLength(segmentId, value);
    }
  }
}

export default connector(SegmentMeasurements);
