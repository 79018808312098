import { ActionsUnion, createAction } from "./Utils";
import { IWallFacadeData } from "../records/WallFacade";

export const RECEIVE_WALL_FACADE = "@wallFacades/RECEIVE_WALL_FACADE";
export const EDIT_WALL_FACADE = "@wallFacades/EDIT_WALL_FACADE";

export const Actions = {
  receiveWallFacade: (wallFacade: Partial<IWallFacadeData>) => createAction(RECEIVE_WALL_FACADE, { wallFacade }),
  editWallFacade: (wallFacade: Partial<IWallFacadeData>) => createAction(EDIT_WALL_FACADE, { wallFacade }),
};

export type Actions = ActionsUnion<typeof Actions>;
