import * as React from "react";
import { imperial_uom, valid_uom } from "app/src/Common/Constants";
import { MeasureValue } from "app/src/Models/MeasurementLink";
import { IMeasurement } from "app/src/Models/Measurement";
import { IProduct } from "app/src/Models/Product";
import { propertiesMapping, properties, calculateFormula, columnKeys } from "app2/src/records/Measurement";
import { Button, Form, Row, Col } from "react-bootstrap";
import { IPretty } from "app/src/Common/PrettyNameService";
import { capitalizeString } from "app2/src/services/string.service";
import { convert, InvalidConversionError } from "app2/src/services/conversion.service";
import { dummyData } from "app2/src/records/measurements/Fencing";
import { StoreRegistry } from "app2/src/storeRegistry";

export interface EditorProps {
  product: IProduct;
  measureValue: MeasureValue;
  measurement: IMeasurement;
  update: (ev: any) => {};
}

export interface EditorState {
  formulaResult: number;
  formulaError: string;
  measureValue: MeasureValue;
  productResult: string;
}

export default class Editor extends React.Component<EditorProps, EditorState> {
  public Pretty: IPretty;
  private formulaInput = React.createRef<HTMLInputElement>();

  constructor(props: EditorProps) {
    super(props);

    this.state = {
      formulaResult: null,
      formulaError: null,
      measureValue: props.measureValue,
      productResult: "",
    };

    this.Pretty = StoreRegistry.get("Pretty");
    this.testFormula = this.testFormula.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount() {
    this.setState({
      formulaResult: null,
      formulaError: null,
      measureValue: this.props.measureValue,
    });
    this.testFormula();
  }

  public testFormula() {
    const { measurement, product } = this.props;
    const { measureValue } = this.state;
    const { formula, formula_uom } = measureValue;

    const calc = calculateFormula(formula, measurement, { fencing: dummyData() });
    let productResult = "";
    try {
      productResult = convert(
        calc.result,
        formula_uom,
        product.uom,
        product.standard_measurement,
        product.standard_uom,
      ).toString();
      productResult = `${productResult} ${product.uom}`;
    } catch (e) {
      if (e instanceof InvalidConversionError) {
        productResult = `${calc.result} ${e.toUom} - Invalid Conversion from ${e.fromUom} to ${e.toUom}`;
      }
    }

    this.setState({
      formulaResult: calc.result,
      formulaError: calc.error,
      productResult: productResult,
    });
  }

  public addMeasurementText(key: string) {
    const { measureValue } = this.state;

    measureValue.formula = measureValue.formula + key;
    this.updateAndTestMeasureValue(measureValue);
    if (this.formulaInput.current) {
      this.formulaInput.current.focus();
    }
  }

  public onChange(ev: any) {
    const { measureValue } = this.state;
    const name = ev.target.name;
    const value = ev.target.value;

    measureValue[name] = value;

    this.updateAndTestMeasureValue(measureValue);
  }

  public updateAndTestMeasureValue(measureValue: MeasureValue) {
    const { update } = this.props;
    this.setState({ measureValue: measureValue });
    update(measureValue);

    this.testFormula();
  }

  public render() {
    const { measurement } = this.props;
    const { formulaError, formulaResult, measureValue, productResult } = this.state;
    const { formula, formula_uom } = measureValue;

    return (
      <div className="ml-editor-parent">
        <Row>
          <Col>
            <p
              id="formula-result"
              className={formulaError && "red-text"}
              title="The formula calculation result before converting units with the product.">
              Formula Result: {formulaError ? formulaError : formulaResult} {formula_uom}
            </p>
            <p
              id="product-conversion"
              className={formulaError && "red-text"}
              title="The quantity that will show in the estimate when selecting this product.">
              Product Conversion: {productResult}
            </p>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Formula</Form.Label>
          <Form.Control
            id="formula"
            ref={this.formulaInput as React.RefObject<any>}
            name="formula"
            type="text"
            value={formula}
            onChange={this.onChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Resulting Unit of Measure</Form.Label>
          <Form.Control as="select" id="formula_uom" name="formula_uom" value={formula_uom} onChange={this.onChange}>
            {valid_uom.map((uom, idx) => {
              return (
                <option key={idx} value={uom}>
                  {uom}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <p>Available Measurements - Test Value - Click to insert measurement into formula input above</p>
        <Row className="title-measurement-header">
          {propertiesMapping.map((map, idx) => {
            return (
              <Col key={idx}>
                <h4>{capitalizeString(properties.get(idx))}</h4>
              </Col>
            );
          })}
        </Row>
        <Row className="ml-scroll">
          {propertiesMapping.map((map, idx) => {
            return (
              <Col key={idx}>
                <div className="measurement-scroll">
                  {_.filter(columnKeys(measurement), (key) => key.indexOf(map) === 0).map((key, keyIdx) => {
                    return (
                      <div key={`${idx}-${keyIdx}`}>
                        <Button variant="link" key={idx} onClick={() => this.addMeasurementText(key)}>
                          {key} - {measurement[key]} - {imperial_uom[key]}
                        </Button>
                        <br />
                      </div>
                    );
                  })}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
