import * as fenceActions from "./fence.actions";
import { Map, Record, List } from "immutable";
import { FenceRecord, fromJSON } from "app2/src/records/measurements/Fence";
import { FenceSegmentRecord } from "app2/src/records/measurements/Segment";
import { RootActions, RootState } from "app2/src/reducers";
import * as segmentActions from "./segment.actions";
import { reducer as segmentReducer } from "./segment.reducer";
import { rootKey, fenceSegments, fenceSegmentIds } from "app2/src/selectors/measurements/fence.selectors";
import { rootKey as fencingRootKey } from "app2/src/selectors/measurements/fencing.selectors";

export const FenceStateRecord = Record({
  byId: Map<number, FenceRecord>(),
  lastSavedById: Map<number, FenceRecord>(),
  unsavedId: -1,
});

export const initialState = FenceStateRecord();

export type FenceState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(rootKey)) {
    state = state.set(rootKey, initialState);
  }

  let fenceRecord;
  switch (action.type) {
    case fenceActions.UPDATE_TOTALS:
      let fenceLinePost4 = 0,
        fenceLinePost6 = 0,
        fenceLinePost8 = 0,
        fenceGateCount = 0,
        fenceGateLength = 0,
        fenceTotalLength = 0,
        fenceNetLength = 0;

      fenceSegments(state, action.payload)
        .filter((s) => !s._destroy)
        .forEach((segment: FenceSegmentRecord) => {
          fenceLinePost4 += segment.linePosts4;
          fenceLinePost6 += segment.linePosts6;
          fenceLinePost8 += segment.linePosts8;
          fenceGateCount += segment.gateCount;
          fenceNetLength += segment.netLength;
          fenceGateLength += segment.gateLength;
          fenceTotalLength += segment.length;
        });

      return state.updateIn([rootKey, "byId", action.payload.fenceId], (fence: FenceRecord) => {
        return fence.merge({
          linePosts4: fenceLinePost4,
          linePosts6: fenceLinePost6,
          linePosts8: fenceLinePost8,
          gateCount: fenceGateCount,
          gateLength: fenceGateLength,
          totalLength: fenceTotalLength,
          netLength: fenceNetLength,
        });
      });

    case fenceActions.RECEIVE_FENCE:
      const { segments, ...fenceData } = action.payload.fence;

      if (_.isNullOrUndefined(fenceData.id) || fenceData.id === 0) {
        fenceData.id = state.getIn([rootKey, "unsavedId"]);
        state = state.setIn([rootKey, "unsavedId"], fenceData.id - 1);
      }

      fenceRecord = fromJSON({ ...fenceData });

      state = state.setIn([rootKey, "byId", fenceRecord.id], fenceRecord);

      if (Array.isArray(segments)) {
        state = segments.reduce((state, segment) => {
          segment.fence_id = fenceRecord.id;
          return segmentReducer(state, segmentActions.Actions.receiveSegment(segment));
        }, state);
      }

      if (fenceRecord.fencingId && fenceRecord.fencingId !== 0) {
        state = state.updateIn(
          [fencingRootKey, "byId", fenceRecord.fencingId, "fenceIds"],
          (fenceIds: List<number>) => {
            if (!fenceIds.contains(fenceRecord.id)) {
              fenceIds = fenceIds.push(fenceRecord.id);
            }
            return fenceIds;
          },
        );
      }

      return state.setIn([rootKey, "lastSavedById", fenceRecord.id], state.getIn([rootKey, "byId", fenceRecord.id]));

    case fenceActions.RESET_FENCE:
      const segmentIds: List<number> = fenceSegmentIds(state, { fenceId: action.payload.fenceId });
      state = state.setIn(
        [rootKey, "byId", action.payload.fenceId],
        state.getIn([rootKey, "lastSavedById", action.payload.fenceId]),
      );

      return segmentIds
        .toSet()
        .union(fenceSegmentIds(state, { fenceId: action.payload.fenceId }))
        .reduce(
          (state: RootState, id: number) => segmentReducer(state, segmentActions.Actions.resetSegment(id)),
          state,
        );

    case fenceActions.SET_FENCE:
      fenceRecord = action.payload.fence;

      if (_.isNullOrUndefined(fenceRecord.id) || fenceRecord.id === 0) {
        fenceRecord = fenceRecord.set("id", state.getIn([rootKey, "unsavedId"]));
        state = state.setIn([rootKey, "unsavedId"], fenceRecord.id - 1);
      }

      state = state.setIn([rootKey, "byId", fenceRecord.id], fenceRecord);

      state = fenceRecord.segments.reduce((state, segment) => {
        return segmentReducer(state, segmentActions.Actions.setSegment(segment.set("fenceId", fenceRecord.id)));
      }, state);

      if (fenceRecord.fencingId && fenceRecord.fencingId !== 0) {
        state = state.updateIn(
          [fencingRootKey, "byId", fenceRecord.fencingId, "fenceIds"],
          (fenceIds: List<number>) => {
            if (!fenceIds.contains(fenceRecord.id)) {
              fenceIds = fenceIds.push(fenceRecord.id);
            }
            return fenceIds;
          },
        );
      }

      return state.setIn([rootKey, "byId", fenceRecord.id, "segments"], List());

    case fenceActions.DELETE_FENCE:
      if (action.payload.cascade) {
        const segmentIds: List<number> = state.getIn([rootKey, "byId", action.payload.fenceId, "segmentIds"]);

        state = segmentIds.reduce((prevState, segmentId) => {
          return segmentReducer(prevState, segmentActions.Actions.deleteSegment(segmentId));
        }, state);
      }

      return state.deleteIn([rootKey, "byId", action.payload.fenceId]);

    default:
      return state;
  }
};
