import * as React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";
import { FormControl } from "@tberrysoln/rsf-form";
import { IPretty } from "app/src/Common/PrettyNameService";
import { StoreRegistry } from "app2/src/storeRegistry";
import { getUoM } from "app2/src/records/Measurement";

interface IMeasurementTable {
  values: string[];
}

export const MeasurementTable: React.FC<IMeasurementTable> = ({ values }) => {
  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const editing = useSelector(measurementEditing);
  const pretty: IPretty = StoreRegistry.get("Pretty");
  const uom = getUoM(measurement);

  return (
    <Table className={editing ? "reduce-padding" : ""}>
      <thead>
        <tr>
          <th>Measurement</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {values.map((value, idx) => {
          return (
            <tr key={idx}>
              <td>{pretty.name[value]}</td>
              <td>
                {editing ? (
                  <>
                    <FormControl
                      formGroupProps={{ className: "small floater-right" }}
                      formControlProps={{ type: "number" }}
                      label=""
                      name={value}
                    />
                    <div className="floater">{uom[value]}</div>
                  </>
                ) : (
                  `${measurement?.get(value) ?? 0} ${uom[value]}`
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
