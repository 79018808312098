import * as React from "react";
import { ISession } from "../../../../app/src/Common/SessionService";
import { Button, Modal, OverlayTrigger, Tooltip, Popover, Container, Col, Row } from "react-bootstrap";
import { IBillingStatus } from "../../../../app/src/Billing/Models/Account";
import { Track, TrackingProp } from "react-tracking";
import track from "react-tracking";
import * as angulartics from "angulartics";
import { Dispatch } from "app2/src/helpers/Analytics";

export interface BillingStatusModalProps {
  show: boolean;
  onClose: (value?: boolean) => void;
  Session: ISession;
  $state: ng.ui.IStateService;
  $analytics: angulartics.IAnalyticsService;
  tracking?: any;
}

export interface BillingStatusModalState {
  show: boolean;
  billingStatus: IBillingStatus;
}

@track(
  { category: "Billing Status" },
  {
    dispatch: Dispatch.dispatch,
  },
)
export class BillingStatusModal extends React.Component<BillingStatusModalProps, BillingStatusModalState> {
  protected billingStatus: IBillingStatus;

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      billingStatus: this.props.Session.billingStatus,
    };

    this.gotoProfile = this.gotoProfile.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.show && !this.state.show) {
      this.handleShow();
    }
  }

  @track({ action: "close" })
  public handleClose() {
    this.setState({ show: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  @track({ action: "shown" })
  public handleShow() {
    this.setState({ show: true });
  }

  @track({ event: "goto profile" })
  public gotoProfile() {
    this.setState({ show: false });
    this.props.$state.go("root.profile");
    if (this.props.onClose) {
      this.props.onClose(true);
    }
  }

  public render() {
    const popover = (
      <Popover id="modal-popover" title="popover">
        very popover. such engagement
      </Popover>
    );

    const tooltip = <Tooltip id="modal-tooltip">wow.</Tooltip>;

    const modalBody = this.buildBody();

    return (
      <Container>
        <Row>
          <Col md={12}>
            <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <div className="row-eq-height">
                    <Col md={12}>
                      <h3>Billing Information</h3>
                    </Col>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalBody}</Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }

  protected buildBody = () => {
    const { billingStatus } = this.state;

    // in our current workflows, the only '0' status will be Freemium, so it's unlikely a Stripe user will ever
    // get into buildNoPayment, hence the no phone number for now.

    // if it's not setup
    if (billingStatus.status === 0) {
      return this.buildNoPayment();
    }

    return this.buildBadPayment();
  };

  protected buildNoPayment = () => {
    // The assumption is that this modal would never be shown if the org is freemium and they already have
    // billing setup.

    return (
      <Row>
        <div className="form-section blank-state">
          <img src="/assets/images/icons-large/billing-info.ef3f76bb.png" />
          <h2>No Billing Information has been provided for this Organization.</h2>
          <p>Please add Billing Information under your Profile on the Organization tab.</p>
          <p>
            <Button onClick={this.gotoProfile}>Profile</Button>
          </p>
        </div>
      </Row>
    );
  };

  protected buildBadPayment = () => {
    const { billingStatus } = this.state;
    const { Session } = this.props;

    // The assumption is that this modal would never be shown if the org is paid and nothing is wrong

    return (
      <Row>
        <Col md={12}>
          <div className="form-section blank-state">
            <img src="/assets/images/icons-large/billing-info.ef3f76bb.png" />
            <h2>There is a problem with your current Billing Information.</h2>
            <br />
            <p className="text-danger">{Session.billingStatusMessage()}</p>
            <br />
            <p>Please update the Billing Information under your Profile on the Organization tab.</p>
            <p>If your Billing Information is up to date please contact Customer Support</p>
            <p>
              <Button onClick={this.gotoProfile}>Profile</Button>
            </p>
          </div>
        </Col>
      </Row>
    );
  };
}
