import * as React from "react";
import { DateFormat } from "app2/src/helpers/Format";
import { EventRecord } from "app2/src/records/Event";
import { pathname } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDialog } from "../../Common/ConfirmDialog";
import { AsyncActions } from "app2/src/reducers/event.actions";
import { eventResult } from "app2/src/selectors/eventResult.selectors";
import { eventResultReason } from "app2/src/selectors/eventResultReason.selectors";
import { RootState } from "app2/src/reducers";
import { eventHasCRM } from "app2/src/selectors/event.selectors";
import { useTracking } from "react-tracking";
import { currentJob } from "app2/src/selectors/job.selectors";
import { token } from "app2/src/selectors/token.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { hasIntegration } from "app2/src/records/Job";

export interface EventRowProps {
  event: EventRecord;
  jobHasCRM?: boolean;
}

export const EventRow: React.FC<EventRowProps> = ({ event, jobHasCRM }) => {
  // Hooks
  const dispatch = useDispatch();
  const path = useSelector(pathname);
  const { trackEvent } = useTracking<any>({ component: "EventRow" });

  // Selectors
  const result = useSelector((state: RootState) => eventResult(state, { eventsResultId: event.events_result_id }));
  const reason = useSelector((state: RootState) =>
    eventResultReason(state, { reasonId: event.events_result_reason_id }),
  );
  const hasCRM: boolean = useSelector((state: RootState) => eventHasCRM(state, { eventId: event.id }));
  const isI360Lightning = useSelector((state: RootState) => {
    const orgId = currentOrgId(state);
    const job = currentJob(state);
    const currentOrgToken = token(state, { orgId, kind: "improveit360" });
    return (
      event.metadata?.improveit360_appointment_id &&
      hasIntegration(job, "i360_lightning_account") &&
      currentOrgToken?.getIn(["data", "platform"]) === "lightning"
    );
  });

  // Methods
  const openResultModal = (eventId: number) => {
    if (isI360Lightning) {
      trackEvent({ action: "Open I360 Result Modal", event: event.id });
      dispatch(push(`${path}/result/i360/${eventId}`));
    } else {
      trackEvent({ action: "Open Result Modal", event: event.id });
      dispatch(push(`${path}/result/${eventId}`));
    }
  };

  const deleteEvent = (event: EventRecord) => {
    trackEvent({ action: "appt removed", event: event.id });
    dispatch(AsyncActions.delete(event.id));
  };

  const editEvent = () => {
    trackEvent({ action: "Edit Appointment", event: event.id });
    dispatch(push(`${path}/calendar/edit/${event.id}`));
  };

  const openInviteModal = () => {
    trackEvent({ action: "Show Invite", event: event.id });
    dispatch(push(`${path}/invite/${event.id}`));
  };

  return (
    <>
      <tr>
        <td>{DateFormat(event.start_time, "shortName")}</td>
        <td>
          {DateFormat(event.start_time, "shortTime")} - {DateFormat(event.end_time, "shortTime")}
        </td>
        <td>{event.summary}</td>
        {jobHasCRM && (
          <td>
            {hasCRM && (
              <Row>
                <Col md={2}>
                  <i
                    onClick={() => openResultModal(event.id)}
                    className="rsf-cogs-link rsf-base-66"
                    title="Result Appointment"></i>
                </Col>
                {result && (
                  <Col md={8}>
                    <div>
                      <small>{result.description}</small>
                    </div>
                    {reason && (
                      <div>
                        <small>{reason.description}</small>
                      </div>
                    )}
                  </Col>
                )}
              </Row>
            )}
          </td>
        )}
        <td>
          <Button onClick={openInviteModal}>Send Invite</Button>
          {!hasCRM && (
            <>
              <i onClick={editEvent} className="rsf-edit-link rsf-base-66 mx-3" title="Edit Appointment" />
              <ConfirmDialog title="Are you sure you wish to delete this appointment?">
                {(confirm) => (
                  <i
                    onClick={confirm(() => deleteEvent(event))}
                    className="rsf-delete-link rsf-base-66"
                    title="Delete Appointment"
                  />
                )}
              </ConfirmDialog>
            </>
          )}
        </td>
      </tr>
      {event.notes && (
        <tr>
          <td className="pull-right">Notes:</td>
          <td colSpan={4}>{event.notes}</td>
        </tr>
      )}
    </>
  );
};
