import { createSelector } from "reselect";
import { RootState } from "app2/src/reducers";
import { FenceSegmentRecord } from "app2/src/records/measurements/Segment";

export const rootKey = "measurementsSegments";

/**
 * Fetches the segments 'byId' map
 *
 * @param {RootState} state
 * @returns {Map<number, FenceSegmentRecord>}
 */
export const segmentsById = (state: RootState) => state.getIn([rootKey, "byId"]);

/**
 * Fetches the segments 'lastSavedById' map
 *
 * @param {RootState} state
 * @returns {Map<number, FenceSegmentRecord>}
 */
export const segmentsLastSavedById = (state: RootState) => state.getIn([rootKey, "lastSavedById"]);

/**
 * Takes a segmentId and finds the associated segment in the byId map
 *
 * @param {RootState} state
 * @param {{ segmentId: number }} params
 * @returns {Map<number, FenceSegmentRecord>}
 */
export const segment = (state: RootState, props: { segmentId: number }) =>
  state.getIn([rootKey, "byId", props.segmentId]);

/**
 * Takes a segmentId and finds the associated segment in the lastSavedById map
 *
 * @param {RootState} state
 * @param {{ segmentId: number }} params
 * @returns {Map<number, FenceSegmentRecord>}
 */
export const segmentLastSaved = (state: RootState, props: { segmentId: number }) =>
  state.getIn([rootKey, "lastSavedById", props.segmentId]);

/**
 * Takes a segmentId and finds the associated FenceSegmentRecords fenceId
 *
 * @param {RootState} state
 * @param {{ segmentId: number }} params
 * @returns {number} fenceId
 */
export const segmentFenceId = createSelector(segment, (segment: FenceSegmentRecord) => segment.fenceId);

/**
 * Takes a segmentId and checks to see if the FenceSegmentRecord is dirty
 *
 * @param {RootState} state
 * @param {{ segmentId: number }} params
 * @returns {boolean} dirty
 */
export const dirty = createSelector(
  segment,
  segmentLastSaved,
  (segment: FenceSegmentRecord, lastSaved: FenceSegmentRecord) => {
    if (!segment) return false;

    return !segment.equals(lastSaved);
  },
);
