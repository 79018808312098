import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IPatternResponse extends ng.resource.IResourceArray<IPattern> {
  patterns: IPattern[];
  meta: any;
}

export interface IPattern extends ng.resource.IResource<IPattern>, PatternPrototype {
  $delete_global(): ng.IPromise<IPattern>;
}

export interface IPatternResource extends ng.resource.IResourceClass<IPattern>, IRsfResource {
  fromJSON?(data: any): IPattern;
  query_global?(data?: any): IPatternResponse;
}

class PatternPrototype {
  public classy: string;
  public id: number;
  public name: string;
  public doc_type: string;
  public view: string;
  public active: boolean;
  public patternable_id: number;
  public patternable_type: string;
  public parent_id: number;
  public updated_at: Date;
  public created_at: Date;

  public $saveOrCreate(params?: any, callback?): ng.IPromise<any> {
    if (this.id <= 0 || (this.id as any as string) === "new") {
      if (this.patternable_id) {
        return (this as any).$create(params, callback);
      } else {
        return (this as any).$create_global(params, callback);
      }
    } else {
      if (this.patternable_id) {
        return (this as any).$save(params, callback);
      } else {
        //noinspection TypeScriptUnresolvedFunction
        return (this as any).$save_global(params, callback);
      }
    }
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:patternable_id/patterns/:id";

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta = angular.fromJson(response);

    _.each(meta.patterns, (et, index) => {
      meta.patterns[index] = Pattern.fromJSON(et);
    });

    return meta;
  };

  const transformSingle = (data) => {
    const jsonData = angular.fromJson(data);
    return Pattern.fromJSON(jsonData.pattern);
  };

  const Pattern: IPatternResource = <IPatternResource>$resource(
    url,
    { patternable_id: "@patternable_id", id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: BaseConfig.BASE_URL + "/api/v1/orgs/:patternable_id/patterns",
        transformResponse: transformSingle,
        isArray: false,
      },
      create_global: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: BaseConfig.BASE_URL + "/api/v1/patterns",
        transformResponse: transformSingle,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingle,
        isArray: false,
      },
      save_global: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: BaseConfig.BASE_URL + "/api/v1/patterns/:id",
        transformResponse: transformSingle,
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      delete_global: <ng.resource.IActionDescriptor>{
        url: BaseConfig.BASE_URL + "/api/v1/patterns/:id",
        method: "DELETE",
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultiple,
        isArray: false,
      },
      query_global: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/api/v1/patterns",
        transformResponse: transformMultiple,
        isArray: false,
      },
    },
  );

  Pattern.fromJSON = (data: any) => {
    const pattern = new Pattern(data);
    pattern.classy = "Pattern";

    return pattern;
  };

  _.hiddenExtend(Pattern.prototype, PatternPrototype.prototype);

  Pattern.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Pattern;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
