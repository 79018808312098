import * as React from "react";
import { Button } from "react-bootstrap";
import useScreenShareParticipant from "app2/src/components/Twilio/hooks/useScreenShareParticipant/useScreenShareParticipant";
import * as FontAwesome from "react-fontawesome";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";

export const SCREEN_SHARE_TEXT = "Share Screen";
export const STOP_SCREEN_SHARE_TEXT = "Stop Sharing Screen";
export const SHARE_IN_PROGRESS_TEXT = "Cannot share screen when another user is sharing";
export const SHARE_NOT_SUPPORTED_TEXT = "Screen sharing is not supported with this browser";

interface ToggleScreenShareButtonProps {
  screenShare: any;
  disabled?: boolean;
}

export default function ToggleScreenShareButton({ disabled, screenShare }: ToggleScreenShareButtonProps) {
  const [isScreenShared, toggleScreenShare] = screenShare;
  const screenShareParticipant = useScreenShareParticipant();
  const { room } = useVideoContext();
  const disableScreenShareButton = screenShareParticipant && screenShareParticipant !== room.localParticipant;
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = disabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = SCREEN_SHARE_TEXT;

  if (isScreenShared) {
    tooltipMessage = STOP_SCREEN_SHARE_TEXT;
  }

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  return (
    <Button
      // @ts-ignore
      onClick={toggleScreenShare}
      className="control-button"
      title={tooltipMessage}
      disabled={isDisabled}>
      {isScreenShared ? <FontAwesome name="stop" size="lg" /> : <FontAwesome name="desktop" size="lg" />}
    </Button>
  );
}
