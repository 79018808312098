import * as React from "react";
import { Participant, Track } from "twilio-video";
import Publication from "app2/src/components/Twilio/components/Publication";
import usePublications from "app2/src/components/Twilio/hooks/usePublications/usePublications";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";

interface ParticipantTracksProps {
  participant: Participant;
  isScreenShared: boolean;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
  participant,
  isScreenShared,
  disableAudio,
  enableScreenShare,
  videoPriority,
}: ParticipantTracksProps) {
  const { room } = useVideoContext();
  const publications = usePublications(participant);
  const localPublications = usePublications(room.localParticipant);
  const isLocal = participant === room.localParticipant;

  let filteredPublications;
  const screenSharePublication = localPublications.filter((p) => p.trackName.includes("screen"))[0];

  if (enableScreenShare && !isScreenShared && publications.some((p) => p.trackName === "screen")) {
    filteredPublications = publications.filter((p) => !p.trackName.includes("camera"));
  } else {
    filteredPublications = publications.filter((p) => p.trackName !== "screen");
  }

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          isLocal={isLocal}
          disableAudio={disableAudio}
          videoPriority={videoPriority}
        />
      ))}
      {isScreenShared && screenSharePublication && (
        <div className="screen-share-pip">
          <p>You are sharing your screen</p>
          <Publication
            key="screen-share-pip"
            publication={screenSharePublication}
            isLocal={isLocal}
            disableAudio={disableAudio}
            videoPriority={videoPriority}
          />
        </div>
      )}
    </>
  );
}
