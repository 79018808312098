import { fetcher } from "../../helpers/Fetcher";
import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction, handleErrors } from "../Utils";
import { RootState } from "..";
import { RootDispatchType } from "../../store";

export const SET_AUTHORIZING = "@integrations/quickMeasure/SET_AUTHORIZING";
export const SET_AUTHORIZED = "@integrations/quickMeasure/SET_AUTHORIZED";
export const SET_ERRORS = "@integrations/quickMeasure/SET_ERRORS";

export const Actions = {
  setAuthorizing: (authorizing: boolean) => createAction(SET_AUTHORIZING, authorizing),
  setAuthorized: (authorized: boolean) => createAction(SET_AUTHORIZED, authorized),
  setErrors: (errors: string[]) => createAction(SET_ERRORS, errors),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, ActionsUnion<typeof Actions>>;

export const AsyncActions = {
  getAuthorization: (code: string): ThunkResult<Promise<boolean>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.setAuthorizing(true));
      return fetcher.post("/integrations/quick_measure/callback", { code: code }).then(
        () => {
          dispatch(Actions.setAuthorizing(false));
          dispatch(Actions.setAuthorized(true));
          return true;
        },
        (errors) => {
          handleErrors(errors);
          dispatch(Actions.setErrors(["There was a problem with your authorization code.  Please try again."]));
          dispatch(Actions.setAuthorizing(false));
          return false;
        },
      );
    };
  },
  getAuthorized: (): ThunkResult<Promise<{ authorized: boolean }>> => {
    return (dispatch: RootDispatchType) => {
      return fetcher.get<{ authorized: boolean }>("/integrations/quick_measure/authorized").then((result) => {
        dispatch(Actions.setAuthorized(result.authorized));
        return result;
      });
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
