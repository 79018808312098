import * as React from "react";
import { List } from "immutable";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import CrmAuthorize from "../Common/CrmAuthorize";
import { IUser } from "app/src/Models/User";
import * as crmUserActions from "app2/src/reducers/crmUser.actions";
import { getAuthorizationUrl } from "app2/src/api/integrationAuth.service";
import * as tokenActions from "app2/src/reducers/token.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as commonActions from "app2/src/reducers/components/common.actions";
import CrmUserMatchTable from "../Common/CrmUserMatchTable";
import { CrmUserRecord } from "app2/src/records/integrations/CrmUser";
import { QueryParamsRecord } from "app2/src/records/Page";
import { orgService } from "app2/src/api/org.service";
import { getPaginationByModel, queryParams, pagination } from "app2/src/selectors/pagination.selectors";
import { MetaPaginationRecord } from "app2/src/records/MetaPagination";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import { ICrmUserQuery } from "../../Common/IntegrationList";

const mapStateToProps = (state: RootState, ownProps: HoverProps) => {
  const modelName = "HoverUser";
  const users = getPaginationByModel(state, {
    path: ["crmUsers", "byId"],
    modelName,
  });
  const hoverQueryParams = queryParams(state, { modelName });

  return {
    authorized: getAuthorization(state, { orgId: ownProps.orgId, integration: "hover" }),
    hoverPagination: pagination(state, { modelName, page: hoverQueryParams.get("page") }),
    hoverQueryParams: hoverQueryParams,
    hoverUsers: users,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: HoverProps) => {
  return {
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
    rejectAuthorization: (orgId: number) => dispatch(tokenActions.AsyncActions.deleteToken(orgId, "hover")),
    updateUser: (hoverUser: CrmUserRecord) => dispatch(crmUserActions.AsyncActions.updateUser(hoverUser)),
    queryUsers: (orgId: number, queryParams: QueryParamsRecord) =>
      dispatch(crmUserActions.AsyncActions.queryUsers({ orgId, userType: "HoverUser" }, queryParams)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface HoverProps {
  users: IUser[];
  orgId: number;
  tokenUpdated: () => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HoverProps;

class Hover extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.authorize = this.authorize.bind(this);
    this.rejectAuthorization = this.rejectAuthorization.bind(this);
    this.queryUsers = this.queryUsers.bind(this);
    this.sync = this.sync.bind(this);
  }

  public componentDidMount() {
    const { queryUsers, orgId, authorized } = this.props;
    if (authorized) {
      queryUsers(orgId, new QueryParamsRecord());
    }
  }

  public async authorize() {
    try {
      const url = await getAuthorizationUrl("hover");
      window.open(url, "_self");
    } catch (e) {
      this.props.addFlashMessage(
        FlashLevels.danger,
        "There were problems with the authorization. If the problem continues, contact support.",
      );
    }
  }

  public async sync() {
    const { orgId, addFlashMessage } = this.props;
    try {
      await orgService.sync(orgId, {
        process: "InitialSyncJob",
        integration: "hover",
      });
      addFlashMessage(FlashLevels.success, "Sync Requested Successfully");
    } catch (e) {
      addFlashMessage(
        FlashLevels.danger,
        "There were problems requesting the sync. Try to reathorize. If the problem continues, contact support.",
      );
    }
  }

  public async rejectAuthorization() {
    const { orgId, rejectAuthorization, tokenUpdated } = this.props;
    await rejectAuthorization(orgId);
    tokenUpdated();
  }

  public queryUsers(queryObj: ICrmUserQuery) {
    const { queryUsers, hoverQueryParams, orgId } = this.props;
    const newQueryParams = hoverQueryParams.merge(queryObj);
    queryUsers(orgId, newQueryParams);
  }

  public render() {
    const { authorized, hoverUsers, hoverPagination, users, updateUser } = this.props;
    return (
      <div className="form-section">
        <div className="form-section-content">
          <CrmAuthorize
            title="Hover"
            image="/assets/images/hover.0d2315c6.png"
            authorized={authorized}
            authorize={this.authorize}
            rejectAuthorization={this.rejectAuthorization}
          />
          {authorized && (
            <CrmUserMatchTable
              integrationType="Hover"
              occUsers={users}
              integrationUsers={hoverUsers}
              pagination={hoverPagination}
              queryUsers={this.queryUsers}
              saveUser={updateUser}
              sync={this.sync}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connector(Hover);
