import * as React from "react";
import Files from "react-files";
import { Row, Col, Button, Table, FormControl } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IDoc } from "app/src/Models/Doc";
import { IImage } from "app/src/Models/Image";
import track from "react-tracking";
import { FlashLevels } from "app/src/Common/FlashService";
import { EstimateLineItemComponentCtrl } from "app/src/Estimator/EstimateLineItemComponent";

export interface FilesTabProps {
  lineItem: IEstimateLineItem;
  handleFile: any;
  controller: EstimateLineItemComponentCtrl;
  handleFileDestroy: any;
  handleFileChange: any;
}

export interface FilesTabState {
  images: IImage[];
  documents: IDoc[];
}

@track({ component: "Files Tab" })
export class FilesTab extends React.Component<FilesTabProps, FilesTabState> {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.lineItem.images,
      documents: this.props.lineItem.documents,
    };
  }

  public onFilesUpload = (files) => {
    if (files.length > 0) {
      this.props.handleFile(files[0]).then(() => {
        this.setState((state, props: FilesTabProps) => {
          return { images: props.lineItem.images, documents: props.lineItem.documents };
        });
      });
    }
  };

  public onFilesError = (error, file) => {
    this.props.controller.Flash.addMessage(FlashLevels.danger, error.message);
    this.props.controller.$rootScope.$digest();
  };

  public resourceList() {
    const files = [];
    this.state.images.map((image) => {
      if (!image._destroy) {
        files.push(this.fileRow(image));
      }
    });
    this.state.documents.map((doc) => {
      if (!doc._destroy) {
        files.push(this.fileRow(doc));
      }
    });
    return files;
  }

  public onFileChange(event, file) {
    this.props.handleFileChange(event.target.value, file);
  }

  public onFileDestroy(file) {
    this.props.handleFileDestroy(file);
  }

  public fileRow(file) {
    return (
      <tr key={file.id}>
        <td>{file.name}</td>
        <td>{file.created_at.toLocaleString()}</td>
        <td>
          {file.type === "document" ? (
            <FormControl
              as="select"
              placeholder="select"
              value={file.display}
              onChange={(ev) => {
                this.onFileChange(ev, file);
              }}>
              <option value="estimate">Estimate</option>
              <option value="contract">Contract</option>
              <option value="both">Both</option>
              <option value="do_not_display">Do Not Display</option>
            </FormControl>
          ) : null}
        </td>
        <td>
          <FontAwesome
            className="fa-66 fa-gray link"
            name="trash"
            title="Delete File"
            onClick={() => {
              this.onFileDestroy(file);
            }}
          />
        </td>
      </tr>
    );
  }

  public render() {
    const resourceList = this.resourceList();
    let noFiles = null;
    if (resourceList.length === 0) {
      noFiles = (
        <Col md={12} className="form-section blank-state">
          <h2>No files added. Let's add one.</h2>
        </Col>
      );
    }

    return (
      <div className="files-tab">
        <Row>
          <Col md={12}>
            <Table striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created</th>
                  <th>Display</th>
                  <th>
                    <Files
                      className="files-dropzone pull-right"
                      onChange={this.onFilesUpload}
                      onError={this.onFilesError}
                      accepts={["image/*", ".pdf"]}
                      multiple={false}
                      maxFileSize={30000000}
                      minFileSize={0}
                      clickable>
                      <Button variant="default">
                        <FontAwesome name="plus" /> Add Files
                      </Button>
                    </Files>
                  </th>
                </tr>
              </thead>
              <tbody>{resourceList}</tbody>
            </Table>
            {noFiles}
          </Col>
        </Row>
      </div>
    );
  }
}
