import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { RsfForm, FormControl } from "@tberrysoln/rsf-form";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import * as orgActions from "app2/src/reducers/org.actions";
import { orgChanged } from "app2/src/selectors/org.selectors";
import CrmUserMatchTable from "app2/src/components/Integrations/Common/CrmUserMatchTable";
import { QueryParamsRecord } from "app2/src/records/Page";
import { getPaginationByModel, queryParams, pagination } from "app2/src/selectors/pagination.selectors";
import { IUser } from "app/src/Models/User";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as crmUserActions from "app2/src/reducers/crmUser.actions";
import { CrmUserRecord } from "app2/src/records/integrations/CrmUser";
import { getAuthorization, token, tokenChanged } from "app2/src/selectors/token.selectors";
import * as FontAwesome from "react-fontawesome";
import SalesRabbitModal from "app2/src/components/Integrations/SalesRabbit/SalesRabbitModal";
import { ICrmUserQuery } from "app2/src/components/Common/IntegrationList";
import * as tokenActions from "app2/src/reducers/token.actions";
import { TokenRecord } from "app2/src/records/Token";

const mapStateToProps = (state: RootState, ownProps: SalesRabbitProps) => {
  const modelName = "SrUser";
  const integrationName = "sales_rabbit";

  const users = getPaginationByModel(state, {
    path: ["crmUsers", "byId"],
    modelName,
  });
  const srQueryParams = queryParams(state, { modelName });

  return {
    salesRabbitToken: token(state, { kind: integrationName }) as TokenRecord,
    srPagination: pagination(state, { modelName, page: srQueryParams.get("page") }),
    srQueryParams: srQueryParams,
    srUsers: users,
    authorized: getAuthorization(state, { orgId: ownProps.orgId, integration: integrationName }),
    dirty: orgChanged(state),
    dirtyForToken: tokenChanged(state, { kind: integrationName }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: SalesRabbitProps) => {
  const integrationName = "sales_rabbit";
  return {
    updateUser: (srUser: CrmUserRecord) => dispatch(crmUserActions.AsyncActions.updateUser(srUser)),
    queryUsers: (orgId: number, srQueryParams: QueryParamsRecord) =>
      dispatch(crmUserActions.AsyncActions.queryUsers({ orgId, userType: "SrUser" }, srQueryParams)),
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
    sync: () => dispatch(orgActions.AsyncActions.syncIntegration(ownProps.orgId, "InitialSyncJob", integrationName)),
    getSalesRabbitToken: () => dispatch(tokenActions.AsyncActions.getToken(ownProps.orgId, integrationName)),
    updateCreateSRToken: (newToken: TokenRecord) => dispatch(tokenActions.AsyncActions.updateCreateToken(newToken)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface SalesRabbitProps {
  orgId: number;
  users: IUser[];
}

export interface SalesRabbitState {
  triggerOpen: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & SalesRabbitProps;

class SalesRabbit extends React.Component<Props, SalesRabbitState> {
  constructor(props) {
    super(props);

    this.state = {
      triggerOpen: 0,
    };

    this.queryUsers = this.queryUsers.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    this.props.getSalesRabbitToken();
    this.queryUsers({ page: 1, query: "" });
  }

  public queryUsers(queryObj: ICrmUserQuery) {
    const { queryUsers, srQueryParams, orgId } = this.props;
    const newQueryParams = srQueryParams.merge(queryObj);
    queryUsers(orgId, newQueryParams);
  }

  public openModal() {
    this.setState((state) => ({ triggerOpen: state.triggerOpen + 1 }));
  }

  public render() {
    const integrationName = "sales_rabbit";
    const {
      orgId,
      dirtyForToken,
      salesRabbitToken,
      updateCreateSRToken,
      users,
      srUsers,
      srPagination,
      updateUser,
      authorized,
      dirty,
      sync,
    } = this.props;
    const { triggerOpen } = this.state;
    const integrationPath = ["tokens", "byOrgId", orgId, integrationName, "data"];

    return (
      <div className="form-section">
        <div className="form-section-content">
          <Row>
            <Col sm={12}>
              <RsfForm
                rootPath={integrationPath}
                updateFormReducer={tokenActions.Actions.update}
                onSubmit={() => updateCreateSRToken(salesRabbitToken)}>
                <Row>
                  <Col sm={4} className="d-flex">
                    <img
                      className="img-fluid align-self-center integration-logo"
                      src="/assets/images/sales_rabbit.6932c46b.png"
                      title="SalesRabbit"
                    />
                  </Col>
                  <Col sm={8}>
                    <Row>
                      <Col sm={6}>
                        <FormControl label="Company Token" name="company_token" />
                      </Col>
                      <Col sm={6}>
                        <FormControl
                          label="Default Appt. Duration"
                          name="default_duration"
                          formControlProps={{ type: "number" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button type="submit" variant="default" className="pull-right" disabled={!dirtyForToken}>
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </RsfForm>
            </Col>
            {authorized && (
              <Col sm={1}>
                <FontAwesome
                  name="cog"
                  className="rsf-base-66 rsf-base-link"
                  title="Settings"
                  onClick={this.openModal}
                />
              </Col>
            )}
          </Row>
          {!authorized && !dirty && <Button onClick={sync}>Initial Sync</Button>}
          {authorized && (
            <React.Fragment>
              <CrmUserMatchTable
                integrationType="SalesRabbit"
                occUsers={users}
                integrationUsers={srUsers}
                pagination={srPagination}
                queryUsers={this.queryUsers}
                saveUser={updateUser}
                sync={sync}
              />
            </React.Fragment>
          )}
          <SalesRabbitModal orgId={orgId} triggerOpen={triggerOpen} />
        </div>
      </div>
    );
  }
}

export default connector(SalesRabbit);
