import * as ng from "angular";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IProduct, IProductResource, IProductResponse } from "app/src/Models/Product";
import { IPriceListResource } from "app/src/Models/PriceList";
import { SortableOptions } from "app/src/Common/SortableOptions";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { IFileQueueFactory, IFileQueueInit } from "app/src/Common/FileQueueFactory";
import { IProductOptionGroupResource, IOptionGroupsMatchResponse } from "app/src/Models/ProductOptionGroup";
import * as angulartics from "angulartics";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import { IProductSearchArgs } from "app/src/Orgs/tabs/OrgPriceListHeaderCtrl";
import { minMaxIndex } from "app2/src/helpers/Format";
import { dispatch } from "app2/src/storeRegistry";
import { Actions, AsyncActions } from "app2/src/reducers/sku.actions";

export class OrgPriceListEditorCtrl extends BaseOrgTabCtrl {
  public currentSelection: IProduct[];
  public optionGroupsMatchResponse: IOptionGroupsMatchResponse;
  public parent: IProduct;
  public query = "";
  public measurement: { string: string } = {} as { string: string };
  public sortableProducts = new SortableOptions();
  public activeTab: any;
  public productLeavesResponse: IProductResponse;
  public leafView = false;
  public perPage = 20;
  public totalLeafCount: number;
  public currentLeafPage = 1;
  public fileQueue: IFileQueueFactory;
  public confirmNavigate = false;
  public processProduct: IProduct = null;

  public _cleanupListeners: Function[] = [];

  public static $inject = [
    "OrgFetcher",
    "PriceList",
    "Product",
    "ProductOptionGroup",
    "Flash",
    "BaseConfig",
    "$timeout",
    "$http",
    "$stateParams",
    "$window",
    "$uibModal",
    "$q",
    "Upload",
    "FileQueueFactory",
    "ConfirmDialog",
    "$scope",
    "$state",
    "$analytics",
    "$document",
    "$location",
    "$rootScope",
  ];
  constructor(
    public OrgFetcher: IOrgFetcherService,
    public PriceList: IPriceListResource,
    public Product: IProductResource,
    public ProductOptionGroup: IProductOptionGroupResource,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
    public $timeout: ng.ITimeoutService,
    public $http: ng.IHttpService,
    public $stateParams: ng.ui.IStateParamsService,
    public $window: ng.IWindowService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $q: ng.IQService,
    public Upload: ng.angularFileUpload.IUploadService,
    public FileQueue: IFileQueueInit,
    public ConfirmDialog: IConfirmDialog,
    public $scope: ng.IScope,
    public $state: ng.ui.IStateService,
    protected $analytics: angulartics.IAnalyticsService,
    public $document: ng.IDocumentService,
    public $location: ng.ILocationService,
    public $rootScope: ng.IRootScopeService,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.fileQueue = FileQueue.getInstance();

    if ($stateParams.active_tab) {
      this.activeTab = _.parseFloat($stateParams.active_tab);
    }

    this.spinnerPromise = OrgFetcher.orgPromise.then(() => {
      return this.setupPriceList().then(() => {
        if ($stateParams.query) {
          this.searchChanged($stateParams.query);
        } else if ($stateParams.leaf_view) {
          this.leafView = $stateParams.leaf_view;
          this.leafQuery();
        } else {
          this.leafView = false;
        }
        if (this.activeTab === 1) {
          this.loadProductOptionGroupsMatches();
        }
      });
    });

    this.sortableProducts.stop = (_event, ui) => {
      const { min, max } = minMaxIndex(ui.item.sortable.index, ui.item.sortable.dropindex);
      if (min === max) {
        return;
      }
      this.currentSelection.map(function (p, index) {
        p.sort_order = index;
        if (index >= min && index <= max) {
          _.clone(p).$saveOrCreate();
        }
      });
      this.updatePriceList();
      this.currentSelection = _.sortBy(this.currentSelection, "sort_order");
    };

    this.$document.keydown((e) => {
      if (e.ctrlKey === true && e.shiftKey === true && e.keyCode === 80 && this.activeTab === 0) {
        this.addProduct();
      }
    });

    this._cleanupListeners.push(
      $rootScope.$on("price_list_header:search_change", (event: ng.IAngularEvent, args: IProductSearchArgs) => {
        this.searchChanged(args.searchString);
      }),
    );

    $scope.$on("$destroy", () => {
      _.each(this._cleanupListeners, (func: () => void) => {
        if (_.isFunction(func)) {
          func();
        }
      });
    });
  }

  public loadProductOptionGroupsMatches() {
    this.$state.go("root.org_show.price_list_header.editor", { active_tab: 1 });
    if (_.isEmpty(this.optionGroupsMatchResponse)) {
      this.optionGroupsMatchResponse = <IOptionGroupsMatchResponse>(
        this.ProductOptionGroup.query_match({ price_list_id: this.$stateParams.price_list_id })
      );
      this.spinnerPromise = this.optionGroupsMatchResponse.$promise.then(() => {
        this.optionGroupsMatchResponse.price_list_id = this.$stateParams.price_list_id;
      });
    }
  }

  public setupPriceList(): ng.IPromise<any> {
    if (this.$stateParams.product_parent_id) {
      const query = <IProductResponse>(
        this.Product.query({ id: this.$stateParams.product_parent_id, "include[]": ["sku"] })
      );
      return query.$promise.then(() => {
        this.parent = query.parents[0];
        this.setupProduct(query);
      });
    } else {
      return this.priceListProductQuery(this.$stateParams.price_list_id);
    }
  }

  public priceListProductQuery(id: number) {
    const query = <IProductResponse>this.Product.query({ price_list_id: id, "include[]": ["sku"] });

    return query.$promise.then(() => {
      this.parent = null;
      this.setupProduct(query);
    });
  }

  public makeSelection(product: IProduct) {
    this.clearSearchText();
    if (product && product.leaf) {
      return;
    }
    this.goToProduct(product);
  }

  public setupProduct(query: IProductResponse) {
    if (this.parent) {
      this.parent.parents = query.parents;
    }
    this.currentSelection = query.products;
  }

  public clearSearchText() {
    this.query = "";
    this.$rootScope.$broadcast("price_list_header:search_clear");
  }

  public addProduct() {
    let price_list_id = null;
    let parent_id = null;
    if (this.parent) {
      parent_id = this.parent.id;
    } else {
      price_list_id = this.$stateParams.price_list_id;
    }
    this.openProductEditor(<IProduct>{
      classy: "Product",
      parent_id: parent_id,
      price_list_id: price_list_id,
      visibility: "always",
      uom: "ea",
      bracket_type: "unset_bt",
      kind: "unset",
      product_price: 0,
      labor_price: 0,
      cost: 0,
      msrp: 0,
      standard_measurement: 0,
      children: [],
      documents: [],
      images: [],
      include_parent_names: true,
      sku_id: 0,
    }).result.then((selection) => {
      this.fileQueue.setQueue();
      this.fileQueue.cleanFileQueue(selection.product, true);

      this.spinnerPromise = selection.product
        .$saveOrCreate()
        .then((product: IProduct) => {
          dispatch(
            Actions.update({
              rootPath: ["skus", "byId", 0],
              name: "item_uuid",
              value: product.uuid,
            }),
          );
          const deferred = this.$q.defer();
          dispatch(AsyncActions.saveOrCreateOrDestroy(selection.product.sku_id)).then((skuId: number) => {
            deferred.resolve(skuId);
          });
          return deferred.promise.then((skuId: number) => {
            this.updatePriceList();
            const new_product = this.Product.fromJSON(product);
            if (skuId > 0) {
              new_product.sku_id = skuId;
            }
            this.currentSelection.push(new_product);
            return this.fileQueue.uploadFileQueue(new_product).then(() => {
              this.Flash.addMessage(FlashLevels.success, "Product successfully created!");
            });
          });
        })
        .catch(() => {
          this.Flash.addMessage(
            FlashLevels.danger,
            "Product could not be created. Please try again. If the problem persists, please contact support.",
          );
        });
    });
  }

  public editProduct(product: IProduct) {
    const copiedObject: IProduct = JSON.retrocycle(JSON.parse(JSON.stringify(JSON.decycle(product))));
    let parent_docs = [];
    if (this.parent) {
      parent_docs = this.parent.priceListEditorParentDocs("both", true);
    }
    copiedObject.parent_docs = parent_docs;

    this.openProductEditor(copiedObject)
      .result.then((selection) => {
        this.fileQueue.setQueue();
        this.fileQueue.cleanFileQueue(selection.product, true);

        const deferred = this.$q.defer();
        dispatch(AsyncActions.saveOrCreateOrDestroy(product.sku_id)).then(() => {
          deferred.resolve();
        });

        this.spinnerPromise = deferred.promise.then(() => {
          return selection.product
            .$saveOrCreate({ "include[]": ["sku"] })
            .then((p: IProduct) => {
              this.updatePriceList();
              product.copyValues(p);

              return this.fileQueue.uploadFileQueue(product).then(() => {
                this.Flash.addMessage(FlashLevels.success, "Product successfully edited!");
              });
            })
            .catch(() => {
              this.Flash.addMessage(
                FlashLevels.danger,
                "Product could not be edited. Please try again. If the problem persists, please contact support.",
              );
            });
        });
      })
      .catch(() => {
        if (product.sku_id) {
          dispatch(Actions.clearSku(product.sku_id));
        }
      });
  }

  public openProductEditor(product: IProduct) {
    return this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      size: "xl",
      ariaDescribedBy: "modal-body",
      controller: "OrgProductEditorCtrl",
      controllerAs: "$ctrl",
      templateUrl: "src/Orgs/tabs/product_editor.html",

      resolve: {
        product: product,
        fileQueue: this.fileQueue,
      },
    });
  }

  public moveProductUp(product: IProduct) {
    this.ConfirmDialog.confirm("Are you sure you want to move this product up one level?", {}).then(() => {
      const moved_product = _.clone(product);
      if (this.parent && this.parent.parents && this.parent.parents.length > 1) {
        moved_product.parent_id = this.parent.parents[1].id;
        moved_product.sort_order = 100;
      } else {
        moved_product.parent_id = null;
        moved_product.price_list_id = this.$stateParams.price_list_id;
        moved_product.sort_order = 100;
      }

      this.spinnerPromise = moved_product
        .$saveOrCreate()
        .then((new_product) => {
          this.updatePriceList();
          this.currentSelection = _.filter(this.currentSelection, (p) => {
            return p.id !== product.id;
          });
        })
        .catch(() => {
          this.Flash.addMessage(
            FlashLevels.danger,
            "Product could not be made a parent. Please try again. If the problem persists, please contact support.",
          );
        });
    });
  }

  public updateProcessProduct(product: IProduct) {
    this.processProduct = product;
  }

  public closeProcess() {
    this.processProduct = null;
    this.$scope.$digest();
  }

  public moveProductDown(product: IProduct) {
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        animation: true,
        size: "md",
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        controller: "OrgParentSelectorCtrl",
        controllerAs: "ctrl",
        templateUrl: "src/Orgs/tabs/parent_selector.html",
        resolve: {
          currentSelection: () => {
            return this.currentSelection;
          },
          product: product,
        },
      })
      .result.then((selection: IProduct) => {
        selection.leaf = false;
        product.price_list_id = null;
        product.parent_id = selection.id;
        const moved_product = _.clone(product);
        moved_product.sort_order = 100;
        this.spinnerPromise = moved_product
          .$saveOrCreate()
          .then(() => {
            this.updatePriceList();
            this.currentSelection = _.filter(this.currentSelection, (p) => {
              return p.id !== product.id;
            });
            selection.children = [];
          })
          .catch(() => {
            this.Flash.addMessage(
              FlashLevels.danger,
              "Product could not be made a child. Please try again. If the problem persists, please contact support.",
            );
          });
      });
  }

  public removeProduct(product: IProduct) {
    this.spinnerPromise = product
      .$delete()
      .then(() => {
        this.updatePriceList();
        this.currentSelection = _.filter(this.currentSelection, (p) => {
          return p.id !== product.id;
        });
        this.Flash.addMessage(FlashLevels.success, "Product successfully deleted!");
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "Product could not be deleted. Please try again. If the problem persists, please contact support.",
        );
      });
  }

  public toggleLeafView() {
    this.leafView = !this.leafView;
    if (this.leafView) {
      this.$state.go("root.org_show.price_list_header.editor", {
        leaf_view: true,
      });
    } else {
      this.clearSearchText();
      this.$state.go("root.org_show.price_list_header.editor", { leaf_view: null, query: null });
    }
  }

  public searchChanged(query: string) {
    this.query = query;
    this.leafView = this.query.length > 0;
    if (this.leafView) {
      this.leafQuery();
    } else {
      this.$state.go("root.org_show.price_list_header.editor", { leaf_view: null, query: null });
    }
  }

  public leafQuery() {
    const query_params = {
      page: this.currentLeafPage,
      per_page: this.perPage,
      query: this.query,
      price_list_id: this.$stateParams.price_list_id,
      "include[]": ["sku"],
    };
    this.productLeavesResponse = <IProductResponse>this.Product.query(query_params);
    this.spinnerPromise = this.productLeavesResponse.$promise.then(() => {
      this.$state.go("root.org_show.price_list_header.editor", {
        leaf_view: true,
        query: this.query,
      });
      this.totalLeafCount = this.productLeavesResponse.meta.pagination.total_count;
    });
  }

  public selectProductTab() {
    this.$state.go("root.org_show.price_list_header.editor", { active_tab: 0 });
  }

  public goToProduct(product: IProduct) {
    let id = null;
    if (product) {
      id = product.id;
    }
    this.$state.go("root.org_show.price_list_header.editor", {
      product_parent_id: id,
    });
  }

  public updatePriceList() {
    this.$rootScope.$broadcast("price_list_header:update_price_list");
  }
}
