import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface ISignedDocumentRecipient
  extends ng.resource.IResource<ISignedDocumentRecipient>,
    SignedDocumentRecipientPrototype {
  $email(): ng.IPromise<ISignedDocumentRecipient>;
  $email(params?: Object, success?: Function, error?: Function): ng.IPromise<ISignedDocumentRecipient>;
  $email(success: Function, error?: Function): ng.IPromise<ISignedDocumentRecipient>;
}

export interface ISignedDocumentRecipientResource
  extends ng.resource.IResourceClass<ISignedDocumentRecipient>,
    IRsfResource {
  sign?(): ISignedDocumentRecipient;
  sign?(params?: Object, success?: Function, error?: Function): ISignedDocumentRecipient;
  sign?(success: Function, error?: Function): ISignedDocumentRecipient;
  email?(): ISignedDocumentRecipient;
  email?(params?: Object, success?: Function, error?: Function): ISignedDocumentRecipient;
  email?(success: Function, error?: Function): ISignedDocumentRecipient;

  viewed?(params?): ISignedDocumentRecipient;

  fromJSON?(data: any): ISignedDocumentRecipient;
}

class SignedDocumentRecipientPrototype {
  public id: number;
  public signature_guid: string;
  public status: string;
  public rs_url: string;
  public name: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/signed_document_recipients/:id";

  const transformSingle = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return new SignedDocumentRecipient({});
    }

    const meta = angular.fromJson(response);
    return SignedDocumentRecipient.fromJSON(meta.signed_document_recipient);
  };

  const SignedDocumentRecipient: ISignedDocumentRecipientResource = <ISignedDocumentRecipientResource>$resource(
    url,
    { id: "@id" },
    {
      sign: <ng.resource.IActionDescriptor>{
        url: BaseConfig.BASE_URL + "/api/v1/signed_documents/sign",
        method: "GET",
        transformResponse: transformSingle,
        isArray: false,
      },
    },
  );

  SignedDocumentRecipient.fromJSON = (data: any) => {
    return new SignedDocumentRecipient(data);
  };

  _.hiddenExtend(SignedDocumentRecipient.prototype, SignedDocumentRecipientPrototype.prototype);

  SignedDocumentRecipient.inject = (injected: IRepository) => {
    resources = injected;
  };

  return SignedDocumentRecipient;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
