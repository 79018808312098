import { IJob } from "app/src/Models/Job";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface INote extends ng.resource.IResource<INote>, NotePrototype {
  $create(): ng.IPromise<INote>;
  $create(params?: Object, success?: Function, error?: Function): ng.IPromise<INote>;
  $create(success: Function, error?: Function): ng.IPromise<INote>;
}

export interface INoteResource extends ng.resource.IResourceClass<INote>, IRsfResource {
  fromJSON?(data: any): INote;
}

class NotePrototype {
  public id: number;
  public note: string;
  public name: string;
  public user_id: number;
  public noteable: any;
  public noteable_id: number;
  public noteable_type: string;
  public job?: IJob;
  public user: string;
  public created_at: Date;
  public updated_at: Date;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): INoteResource => {
  const url = BaseConfig.BASE_URL + "/api/v1/:noteable_type/:noteable_id/notes/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): INote => {
    if (status < 200 || status > 299) {
      return new Note({});
    }

    return Note.fromJSON(JSON.parse(response).note);
  };

  const transformRequest = (note: INote): string => {
    const data = JSON.decycle(note);
    return angular.toJson({ note: data });
  };

  const Note: INoteResource = <INoteResource>$resource(
    url,
    { noteable_type: "@noteable_type", noteable_id: "@noteable_id", id: "@id" },
    {
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  Note.fromJSON = (data: any): INote => {
    if (data.noteable && data.noteable_type) {
      switch (data.noteable_type) {
        case "Job":
          data.job = resources.Job.fromJSON(data.noteable);
      }
    }
    return new Note(data);
  };

  Note.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Note;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
