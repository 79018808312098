import { IActivatedPriceListData } from "app2/src/records/ActivatedPriceList";
import { IPriceListData } from "app2/src/records/PriceList";
import { fetcher } from "../helpers/Fetcher";

export interface IActivatedPriceListResponse {
  activated_price_list: IActivatedPriceListData;
}

export interface ICachedPriceListResponse {
  price_list: IPriceListData;
}

class ActivatedPriceListService {
  public url = "api/v1/orgs/:org_id/activated_price_lists";

  public loadCached(orgId: number, id: number): Promise<IActivatedPriceListResponse> {
    const actionUrl = fetcher.joinUrls(this.url.replace(":org_id", orgId.toString()), id.toString(), "cached");

    return fetcher.get<IActivatedPriceListResponse>(actionUrl);
  }

  public loadFile(url: string): Promise<ICachedPriceListResponse> {
    return fetch(url, {}).then(fetcher.standardStatus).then(fetcher.json);
  }
}

export const activatedPriceListService = new ActivatedPriceListService();
