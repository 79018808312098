import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as tokenActions from "app2/src/reducers/token.actions";
import { token as selectToken } from "app2/src/selectors/token.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import CrmAuthorize from "app2/src/components/Integrations/Common/CrmAuthorize";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import { RootState } from "app2/src/reducers";
import { TokenKind } from "app2/src/records/Token";
import Spinner from "app2/src/components/SpinnerComponent";
import { push } from "connected-react-router/immutable";
import CrmUserMatchTable from "app2/src/components/Integrations/Common/CrmUserMatchTable";
import { getPaginationByModel, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { IUser } from "app/src/Models/User";
import * as crmUserActions from "app2/src/reducers/crmUser.actions";
import { CrmUserRecord, IntegrationUserType } from "app2/src/records/integrations/CrmUser";
import { queryParamsFromJSON, QueryParamsRecord } from "app2/src/records/Page";
import * as orgActions from "app2/src/reducers/org.actions";
import { IPretty } from "app/src/Common/PrettyNameService";
import { useTracking } from "react-tracking";
import { StoreRegistry } from "app2/src/storeRegistry";
import { fromJSON as tokenFromJSON } from "app2/src/records/Token";
import { currentUserId as currentUserIdSelector } from "app2/src/selectors/user.selectors";
export interface ICrmUserQuery {
  page: number;
  query: string;
}
interface IntegrationListProps {
  integration: TokenKind;
  integrationLogo: string;
  tokenUpdated: () => void;
  occUsers?: IUser[];
  settingsButton?: boolean;
  authorize?: () => void;
  authless?: boolean;
  platform?: string;
}
const IntegrationList: React.FunctionComponent<IntegrationListProps> = ({
  integration,
  integrationLogo,
  tokenUpdated,
  occUsers,
  settingsButton,
  authorize,
  authless,
  platform = "",
}) => {
  //Constants
  const { trackEvent } = useTracking<any>({ component: "IntegrationList", integration: integration });
  const pretty: IPretty = StoreRegistry.get("Pretty");
  const integrationType = pretty.name[integration];
  //Hooks
  const dispatch = useDispatch();
  //Selectors
  const orgId = useSelector(currentOrgId);
  const authorized = useSelector((state: RootState) =>
    getAuthorization(state, { orgId: orgId, integration: integration }),
  );
  const token = useSelector((state: RootState) => selectToken(state, { kind: integration }));
  const modelName = IntegrationUserType[integration + platform];
  const users = useSelector((state: RootState) =>
    getPaginationByModel(state, {
      path: ["crmUsers", "byId"],
      modelName: modelName,
    }),
  );
  const modelQueryParams = useSelector((state: RootState) => queryParams(state, { modelName: modelName }));
  const modelPagination = useSelector((state: RootState) =>
    pagination(state, { modelName: modelName, page: modelQueryParams.get("page") }),
  );
  const currentUserId = useSelector((state: RootState) => currentUserIdSelector(state));
  //Methods
  const modelQueryUsers = (newQueryParams: QueryParamsRecord) => {
    dispatch(crmUserActions.AsyncActions.queryUsers({ orgId: orgId, userType: modelName }, newQueryParams));
  };
  const queryUsers = (queryObj: ICrmUserQuery) => {
    trackEvent({ action: "query users" });
    let newQueryParams = modelQueryParams;
    Object.entries(queryObj).map((entry: any[]) => {
      newQueryParams = newQueryParams.setIn([entry[0]], entry[1]);
    });
    modelQueryUsers(newQueryParams);
  };
  const modelUpdateUser = (crmUser: CrmUserRecord) => dispatch(crmUserActions.AsyncActions.updateUser(crmUser));
  const sync = () => {
    trackEvent({ action: "sync" });
    if (integration === "fin_mkt") {
      dispatch(orgActions.AsyncActions.syncIntegration(orgId, "SyncJob", "fin_mkt"));
    } else {
      dispatch(orgActions.AsyncActions.syncIntegration(orgId, "InitialSyncJob", integration));
    }
  };
  const rejectAuthorization = async () => {
    trackEvent({ action: "reject authorization" });
    await dispatch(tokenActions.AsyncActions.deleteToken(orgId, integration));
    tokenUpdated();
  };
  const submitAuthorization = async () => {
    trackEvent({ action: "open authorization modal" });
    if (authless) {
      const newToken = tokenFromJSON({
        kind: integration,
        org_id: orgId,
        user_id: currentUserId,
        data: { enabled: true },
      });
      await dispatch(tokenActions.AsyncActions.updateCreateToken(newToken));
      tokenUpdated();
    } else if (authorize) {
      await authorize();
    } else {
      dispatch(push(`integrations/authorization/${integration}`));
    }
  };
  const settings = () => {
    trackEvent({ action: "open settings modal" });
    dispatch(push(`integrations/settings/${integration}`));
  };

  //Lifecycle
  React.useEffect(() => {
    if (authorized && occUsers) {
      modelQueryUsers(queryParamsFromJSON({}));
    }
  }, [authorized, token]);
  return (
    <div className="form-section">
      <div className="form-section-content">
        <Spinner localProperty={token?.loading} />
        <CrmAuthorize
          title={_.toTitleCase(integration)}
          image={integrationLogo}
          authorized={authorized}
          authorize={submitAuthorization}
          rejectAuthorization={rejectAuthorization}
          settings={settingsButton && settings}
          authless={authless}
        />
        {authorized && occUsers && (
          <CrmUserMatchTable
            integrationType={integrationType}
            occUsers={occUsers}
            integrationUsers={users}
            pagination={modelPagination}
            queryUsers={queryUsers}
            saveUser={modelUpdateUser}
            sync={sync}
          />
        )}
      </div>
    </div>
  );
};

export default IntegrationList;
