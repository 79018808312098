import * as React from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Actions, AsyncActions } from "app2/src/reducers/event.actions";
import { Context } from "./Context";
import { push } from "connected-react-router/immutable";
import { RootState } from "app2/src/reducers";
import { event as eventSelector } from "app2/src/selectors/event.selectors";
import { useRouteMatch } from "react-router";
import { RsfForm, FormControl, FormControlDate, FormControlTime } from "@tberrysoln/rsf-form";
import { required } from "app2/src/helpers/FinalFormValidator";
import { useTracking } from "react-tracking";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";
import { CustomLink } from "app2/src/components/Common/CustomLink";
import * as FontAwesome from "react-fontawesome";
import SpinnerComponent from "app2/src/components/SpinnerComponent";

export const EditAppointmentModal: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "EditAppointmentModal" });
  const match = useRouteMatch<{ eventId: string }>();

  // Selectors
  const eventId = parseInt(match.params.eventId);
  const event = useSelector((state: RootState) => eventSelector(state, { eventId }));

  // Context
  const { includes } = React.useContext(Context);

  // Local State
  const [showError, setShowError] = React.useState(false);

  // Methods
  const close = () => {
    const prevPath = match.path.split("/edit")[0];
    dispatch(push(prevPath));
  };

  const cancel = () => {
    trackEvent({ action: "appt edit canceled", event: eventId });
    dispatch(Actions.resetEvent(eventId));
    close();
  };

  const deleteEvent = async () => {
    trackEvent({ action: "appt removed", event: eventId });
    await dispatch(AsyncActions.delete(eventId));
    close();
  };

  const submit = async () => {
    trackEvent({ action: "appt saved", event: eventId });
    await dispatch(AsyncActions.createOrUpdate(eventId, includes));
    close();
  };

  const validateForm = () => {
    if (event && event.start_time >= event.end_time) {
      setShowError(true);
      return [
        { name: "start_time", error: " " },
        { name: "end_time", error: " " },
      ];
    } else {
      setShowError(false);
      return [{ name: "start_time" }, { name: "end_time" }];
    }
  };

  return (
    <Modal show={true} onHide={cancel} backdrop="static" backdropClassName="z-index-1060" className="z-index-1070">
      <Modal.Header closeButton>
        <Modal.Title>Edit Appointment</Modal.Title>
      </Modal.Header>
      <SpinnerComponent localProperty={event?.loading} />
      <RsfForm
        rootPath={["events", "byId", eventId]}
        onSubmit={submit}
        updateFormReducer={Actions.updateForm}
        validate={validateForm}>
        <Modal.Body>
          <FormControl name="summary" label="Title" validate={required} autoFocus />
          <Row>
            <Col>
              <FormControlDate name="start_time" label="Start Date" id="start_date" />
            </Col>
            <Col>
              <FormControlTime name="start_time" label="Start Time" id="start_time" />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControlDate name="end_time" label="End Date" id="end_date" />
            </Col>
            <Col>
              <FormControlTime name="end_time" label="End Time" id="end_time" />
            </Col>
          </Row>
          {showError && (
            <div className="mb-2 text-center red-text" style={{ marginTop: "-10px" }}>
              Start Time must be before End Time
            </div>
          )}
          <FormControl name="description" label="Description" as="textarea" />
          {eventId > 0 && event?.job_id ? (
            <>
              <CustomLink tag="a" to={`/jobs/${event.job_id}/info`}>
                Navigate to Job
              </CustomLink>
              <CustomLink
                className="btn-link ml-2"
                tag="a"
                to={`/jobs/${event.job_id}/info`}
                target="_blank"
                id="job-link-new-window">
                <FontAwesome name="external-link" />
              </CustomLink>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <ButtonFooter cancel={cancel} destroy={eventId !== 0 ? deleteEvent : null} itemName="appointment" />
        </Modal.Footer>
      </RsfForm>
    </Modal>
  );
};
