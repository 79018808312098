import { dispatch } from "../storeRegistry";
import * as jobActions from "app2/src/reducers/job.actions";
import { IJobData } from "../records/Job";

export interface JobIndexResponse {
  jobs: IJobData[];
  meta: {
    state_info: Record<string, { pretty_name: string; color: string }>;
  };
  stats: Record<string, number>;
}

/**
 * Currently getting called in the JobListCtrl when loading jobs.
 * Sets up the statistics data and stores it in Redux.
 */
export const loadStatistics = (response: JobIndexResponse): void => {
  const states = Object.keys(response.meta?.state_info || {});
  const stats = states.map((state) => ({
    name: response.meta.state_info[state].pretty_name,
    color: response.meta.state_info[state].color,
    count: response.stats[state] || 0,
  }));
  const statistics = {
    stats,
    total: response.stats.total,
  };
  dispatch(jobActions.Actions.receiveStats(statistics));
};
