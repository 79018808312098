import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { IScreenShareData } from "app2/src/records/ScreenShare";
import { screenShareService } from "app2/src/api/screenShare.service";
import { RootDispatchType } from "../store";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { getRemoteStorage } from "app2/src/api/remoteStorage.service";
import { RoomParams } from "app2/src/components/VideoProvider/VideoProvider";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { ScreenShareKindValues, ScreenShareStatusValues } from "./screenShare.reducer";

export const SET_SCREEN_SHARE_STATUS = "@screenShares/SET_SCREEN_SHARE_STATUS";
export const SET_SCREEN_SHARE_KIND = "@screenShares/SET_SCREEN_SHARE_KIND";
export const SET_ACTIVE_SCREEN_SHARE_JOB = "@screenShares/SET_ACTIVE_SCREEN_SHARE_JOB";
export const FETCH_SCREEN_SHARE = "@screenShares/FETCH_SCREEN_SHARE";
export const RECEIVE_SCREEN_SHARE = "@screenShares/RECEIVE_SCREEN_SHARE";
export const RECEIVE_SCREEN_SHARE_TOKEN = "@screenShares/RECEIVE_SCREEN_SHARE_TOKEN";
export const RECEIVE_SCREEN_SHARE_ERROR = "@screenShares/RECEIVE_SCREEN_SHARE_ERROR";
export const RECEIVE_ROOM_STATUS = "@screenShares/RECEIVE_ROOM_STATUS";

export const Actions = {
  setScreenShareStatus: (jobId: number, status: ScreenShareStatusValues) =>
    createAction(SET_SCREEN_SHARE_STATUS, { jobId: jobId, status: status }),
  setScreenShareKind: (jobId: number, kind: ScreenShareKindValues) =>
    createAction(SET_SCREEN_SHARE_KIND, { jobId: jobId, kind: kind }),
  setActiveScreenShareJob: (jobId: number) => createAction(SET_ACTIVE_SCREEN_SHARE_JOB, jobId),
  fetchScreenShare: (jobId: number, id: number) => createAction(FETCH_SCREEN_SHARE, { id: id, jobId: jobId }),
  receiveScreenShareError: (jobId: number, id: number, errors: string[]) =>
    createAction(RECEIVE_SCREEN_SHARE_ERROR, {
      id: id,
      jobId: jobId,
      errors: errors,
    }),
  receiveScreenShare: (jobId: number, screenShare: Partial<IScreenShareData>) =>
    createAction(RECEIVE_SCREEN_SHARE, { jobId: jobId, screenShare: screenShare }),
  receiveToken: (token: string) => createAction(RECEIVE_SCREEN_SHARE_TOKEN, token),
  receiveRoomStatus: (status: boolean) => createAction(RECEIVE_ROOM_STATUS, status),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  setRemoteScreenShareStatus: (jobId: number, status: ScreenShareStatusValues): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.setScreenShareStatus(jobId, status));
      const kind = getState().getIn(["screenShares", "byJobId", jobId, "screenShareKind"]);
      if (
        !(kind === "basic" && _.includes(["start_requested", "stop_requested"], status)) ||
        !(kind === "premium" && _.includes(["start_requested"], status))
      ) {
        await getRemoteStorage().setItem(SET_SCREEN_SHARE_STATUS, { jobId: jobId, status: status });
      }
    };
  },
  setRemoteScreenShareKind: (jobId: number, kind: ScreenShareKindValues): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.setScreenShareKind(jobId, kind));
      await getRemoteStorage().setItem(SET_SCREEN_SHARE_KIND, { jobId: jobId, kind: kind });
    };
  },
  setRemoteActiveScreenShareJob: (jobId: number): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.setActiveScreenShareJob(jobId));
      await getRemoteStorage().setItem(SET_ACTIVE_SCREEN_SHARE_JOB, jobId);
    };
  },
  addScreenShare: (jobId: number, kind: ScreenShareKindValues): ThunkResult<Promise<IScreenShareData>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const result = await screenShareService.create(jobId, kind);
        const activeJobId = getState().getIn(["screenShares", "activeScreenShareJobId"]);
        if (activeJobId && activeJobId !== jobId) {
          dispatch(AsyncActions.setRemoteScreenShareStatus(activeJobId, "stop_requested"));
        }
        dispatch(Actions.receiveScreenShare(jobId, result));
        dispatch(AsyncActions.setRemoteActiveScreenShareJob(jobId));
        dispatch(AsyncActions.setRemoteScreenShareKind(jobId, kind));
        getRemoteStorage().setItem(RECEIVE_SCREEN_SHARE, result);

        return result;
      } catch (errors) {
        return Promise.reject(errors);
      }
    };
  },
  joinOrAddScreenShareRoom: (jobId: number, roomParams: RoomParams): ThunkResult<Promise<string>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        await dispatch(AsyncActions.addScreenShare(jobId, "premium"));
      } catch (e) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.warning, "Joining Existing Room"));
      }

      try {
        return await dispatch(
          AsyncActions.getToken({
            room: roomParams.roomUid,
            identity: roomParams.identity,
          }),
        );
      } catch (errors) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors[0]));
        return Promise.reject(errors);
      }
    };
  },
  getRoomStatus: (uid: string): ThunkResult<Promise<boolean>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const result = await screenShareService.getRoomStatus(uid);
        dispatch(Actions.receiveRoomStatus(result));
        return result;
      } catch (errors) {
        return Promise.reject(errors);
      }
    };
  },
  saveScreenShareStop: (jobId: number, id: number): ThunkResult<Promise<IScreenShareData>> => {
    return async (dispatch: RootDispatchType) => {
      if (id === 0) {
        return Promise.reject(["Error Stopping"]);
      }
      dispatch(Actions.fetchScreenShare(jobId, id));
      try {
        const result = await screenShareService.stop(id);
        dispatch(Actions.receiveScreenShare(jobId, null));
        getRemoteStorage().setItem(RECEIVE_SCREEN_SHARE, { jobId: jobId, screenShare: null });
        return result;
      } catch (errors) {
        dispatch(Actions.receiveScreenShareError(jobId, id, errors));
        return Promise.reject(errors);
      }
    };
  },
  getToken: (params): ThunkResult<Promise<string>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const result = await screenShareService.loadToken(params);
        dispatch(Actions.receiveToken(result));
        return result;
      } catch (errors) {
        return errors;
      }
    };
  },
  startFallbackScreenShare: (jobId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      await dispatch(AsyncActions.setRemoteScreenShareStatus(jobId, "stop_requested"));
      await dispatch(AsyncActions.addScreenShare(jobId, "basic"));
      dispatch(AsyncActions.setRemoteScreenShareStatus(jobId, "start_requested"));
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
