import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Row, Col, Card, Button } from "react-bootstrap";
import { RootDispatchType } from "app2/src/store";
import * as folderActions from "app2/src/reducers/folder.actions";
import { ids, pageRecord, queryParams } from "app2/src/selectors/pagination.selectors";
import Folder from "app2/src/components/Folders/Folder";
import MiniFolder from "app2/src/components/Folders/MiniFolder";
import * as FontAwesome from "react-fontawesome";
import { Sortable } from "app2/src/components/Common/Sortable";
import { GridContainer } from "app2/src/components/Common/GridContainer";
import BreadCrumbWrapper from "app2/src/components/Folders/BreadCrumbWrapper";
import { DocumentableType } from "app2/src/api/document.service";
import { capitalizeString } from "app2/src/services/string.service";
import track from "react-tracking";

const mapStateToProps = (state: RootState) => {
  const modelName = "folder",
    page = 1;
  return {
    folderIds: ids(state, { modelName }),
    page: pageRecord(state, { modelName, page }),
    queryParams: queryParams(state, { modelName }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: FoldersProps) => {
  const folderable_id = ownProps.documentableType === "job" ? ownProps.jobId : ownProps.orgId;
  const folderable_type = capitalizeString(ownProps.documentableType);
  return {
    createFolder: (id: number) =>
      dispatch(
        folderActions.Actions.createFolder(id, {
          folderable_id,
          folderable_type,
          parent_name: FileTypeFolderName[ownProps.fileType],
        }),
      ),
    onSortEnd: (oldIndex: number, newIndex: number) =>
      dispatch(folderActions.AsyncActions.onSortEnd(oldIndex, newIndex)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FileType = "images" | "documents" | "presentations" | "estimateTemplates";
const FileTypeFolderName = {
  images: "Photos",
  documents: "Documents",
  presentations: "Presentations",
  estimateTemplates: "Estimate Templates",
};
interface FoldersProps {
  jobId?: number;
  orgId?: number;
  fileType: FileType;
  folderParentId: number;
  documentableType: DocumentableType;
  navigate: (folderId: number) => void;
  readOnly?: boolean;
  miniView?: boolean;
}

interface FoldersState {
  dragLock: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FoldersProps;

class Folders extends React.Component<Props, FoldersState> {
  constructor(props: Props) {
    super(props);
    this.state = { dragLock: false };

    this.navigate = this.navigate.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.setDragLock = this.setDragLock.bind(this);
  }

  @track((_props, _state, [folderId]) => ({
    action: "navigate",
    folder: folderId,
  }))
  public navigate(folderId: number): void {
    this.props.navigate(folderId);
  }

  @track(() => ({
    action: "create folder",
  }))
  public handleCreate(): void {
    const { folderParentId, createFolder } = this.props;
    createFolder(folderParentId);
  }

  @track((props, _state, [oldIndex, newIndex]) => ({
    action: "onDragEnd",
    oldIndex: oldIndex,
    newIndex: newIndex,
    folders: props.folderIds.toArray(),
  }))
  public onSortEnd(oldIndex: number, newIndex: number): void {
    const { onSortEnd } = this.props;

    onSortEnd(oldIndex, newIndex);
  }

  public setDragLock(dragLock: boolean): void {
    this.setState({ dragLock });
  }

  public render(): React.ReactNode {
    const { folderIds, page, folderParentId, readOnly, miniView } = this.props;
    const { dragLock } = this.state;

    if (page?.loading) {
      return (
        <span data-testid="spinner">
          <SpinnerComponent localProperty={true} />
        </span>
      );
    }

    if (_.isNullOrUndefined(folderIds)) {
      return null;
    }

    return (
      <>
        <BreadCrumbWrapper folderParentId={folderParentId} navigate={this.navigate} />
        <Row>
          <Col>
            <Sortable
              items={folderIds}
              Container={GridContainer}
              containerProps={{ columnWidth: 245 }}
              onDragEnd={this.onSortEnd}
              dragHandle
              renderItem={(props) => {
                const { item, dragHandle } = props;
                if (miniView) {
                  return <MiniFolder folderId={item} navigate={this.navigate} />;
                }
                return (
                  <Folder
                    folderId={item}
                    navigate={this.navigate}
                    dragHandle={dragHandle}
                    readOnly={readOnly}
                    dragLock={dragLock}
                    setDragLock={this.setDragLock}
                  />
                );
              }}>
              {folderIds.size <= 24 && !readOnly && (
                <Card className="folder-card create" onClick={this.handleCreate}>
                  <Card.Body className="text-center" data-testid="create-folder">
                    <FontAwesome className="card-icon-top fa-gray" name="folder-open-o" />
                    <Card.Title>Create a New Folder</Card.Title>
                  </Card.Body>
                </Card>
              )}
            </Sortable>
          </Col>
        </Row>
      </>
    );
  }
}

export default connector(Folders);
