import { RsfForm } from "@tberrysoln/rsf-form";
import { MeasurementTable } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/MeasurementTable";
import { RootState } from "app2/src/reducers";
import { Actions } from "app2/src/reducers/measurement.actions";
import { rootKey } from "app2/src/selectors/measurement.selectors";
import { currentMeasurementId } from "app2/src/selectors/measurementCommon.selectors";
import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

export const sidingTotal1 = [
  "siding_total_area",
  "siding_5pw_total_area",
  "siding_10pw_total_area",
  "siding_15pw_total_area",
  "siding_18pw_total_area",
  "siding_inside_corner_qty",
  "siding_inside_corner_length",
  "siding_outside_corner_qty",
  "siding_outside_corner_length",
  "siding_soffit_level_frieze",
  "siding_soffit_eaves",
  "siding_soffit_rakes",
  "siding_soffit_sloped_frieze",
  "siding_soffit_total",
];
export const sidingTotal2 = [
  "siding_opening_qty",
  "siding_opening_tops_length",
  "siding_opening_sills_length",
  "siding_opening_sides_length",
  "siding_level_starter",
  "siding_sloped_trim",
  "siding_vertical_trim",
  "siding_level_frieze_board",
  "siding_sloped_frieze_board",
  "siding_frieze_board",
  "siding_windows_qty",
  "siding_doors_qty",
  "siding_garage_doors_qty",
  "siding_shutter_qty",
  "siding_shutter_area",
  "siding_vents_qty",
  "siding_vents_area",
];

export const Siding: React.FC = () => {
  // Selectors
  const measurementId = useSelector((state: RootState) => currentMeasurementId(state, {}));

  return (
    <RsfForm rootPath={[rootKey, "byId", measurementId]} updateFormReducer={Actions.updateForm} onSubmit={() => {}}>
      <Row>
        <Col>
          <h2>Siding</h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={6}>
              <MeasurementTable values={sidingTotal1} />
            </Col>
            <Col lg={6}>
              <MeasurementTable values={sidingTotal2} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </RsfForm>
  );
};
