import { Record } from "immutable";
import { fromJSON as fileFromJSON, FileRecord, IFileData } from "./File";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IImageData>): ImageRecord => {
  const record: IImageRecord = { ...(json as any) };

  if (json.file) {
    record.file = fileFromJSON(json.file);
  }

  record.displayInProposal = record.display === "both" || record.display === "estimate";
  record.displayInAgreement = record.display === "both" || record.display === "contract";

  return new ImageRecord(record);
};

export const toJSON = (image: ImageRecord): IImageData => {
  const img = image.toJSON() as IImageData;

  if (img.id <= 0) {
    delete img.id;
  }

  return img;
};

export const toFormData = (record: ImageRecord): any => {
  const data = new FormData();
  if (record.id > 0) {
    data.set("image[id]", record.id as any);
  }
  data.set("image[name]", record.name);
  if (record.file_queue && record.file_queue instanceof File) {
    data.set("image[file]", record.file_queue as any);
  }
  data.set("image[display]", record.display);
  data.set("image[imageable_id]", record.imageable_id.toString());
  data.set("image[imageable_type]", record.imageable_type);
  if (record.folder_id > 0) {
    data.set("image[folder_id]", record.folder_id.toString());
  }
  return data;
};

export const getUrl = (image: ImageRecord, size: string): string => {
  if (!image || (!image.file && !image.file_queue)) {
    return "";
  }

  if (image.id <= 0 && image.file_queue) {
    return image.file_queue.preview.url;
  }

  return image.file[size].url;
};

export const setDisplay = (record: ImageRecord): ImageDisplayType => {
  let display = ImageDisplay.do_not_display;
  if (record.displayInProposal === true && record.displayInAgreement === true) {
    display = ImageDisplay.both;
  } else if (record.displayInProposal === false && record.displayInAgreement === true) {
    display = ImageDisplay.contract;
  } else if (record.displayInProposal === true && record.displayInAgreement === false) {
    display = ImageDisplay.estimate;
  }
  return display;
};

export enum ImageDisplay {
  do_not_display = "do_not_display",
  contract = "contract",
  estimate = "estimate",
  both = "both",
  logo = "logo",
}

export type ImageDisplayType = "do_not_display" | "contract" | "estimate" | "both" | "logo";

export interface IImageData {
  id: number;
  uuid: string;
  folder_id: number;
  title?: string;
  description?: string;
  display?: ImageDisplayType;
  file?: IFileData;
  file_size?: number;
  imageable_id?: number;
  imageable_type?: string;
  md5_sum?: string;
  name?: string;
  sort_order?: number;
  selected?: boolean;
  loading?: boolean;
  file_queue?: any;

  created_at: Date;
  updated_at: Date;
}

export interface IImageRecord {
  id: number;
  uuid: Nullable<string>;
  folder_id: Nullable<number>;
  title: Nullable<string>;
  name: Nullable<string>;
  description: Nullable<string>;
  display: Nullable<ImageDisplayType>;
  file: Nullable<FileRecord>;
  file_queue: Nullable<any>;
  file_size: Nullable<any>;
  imageable_id: Nullable<number>;
  imageable_type: Nullable<string>;
  md5_sum: Nullable<string>;
  sort_order: Nullable<number>;
  _destroy: Nullable<boolean>;
  loading: boolean;
  selected: boolean;
  displayInProposal: Nullable<boolean>;
  displayInAgreement: Nullable<boolean>;

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

export const defaultImageProps = {
  id: 0,
  uuid: null,
  folder_id: null,
  title: null,
  name: null,
  description: null,
  display: null,
  file: null,
  file_queue: null,
  file_size: null,
  imageable_id: null,
  imageable_type: null,
  md5_sum: null,
  sort_order: null,
  _destroy: null,
  loading: false,
  selected: false,
  displayInProposal: null,
  displayInAgreement: null,
  created_at: null,
  updated_at: null,
};

export class ImageRecord extends Record<IImageRecord>(defaultImageProps) implements IImageRecord {}
