import * as React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { settingsConfig } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: HomeAdvisorProps) => {
  const settings = settingsConfig(state, { orgId: ownProps.orgId });
  return {
    homeAdvisorSpId: settings?.getIn(["integrations", "home_advisor", "spEntityId"]),
    homeAdvisorUrl: settings?.getIn(["integrations", "home_advisor", "url"]),
    crmKey: state.getIn(["orgs", "orgsById", ownProps.orgId, "base36_id"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: HomeAdvisorProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface HomeAdvisorProps {
  orgId: number;
  updateSettingsConfig: (path: any, value: any) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HomeAdvisorProps;

class HomeAdvisor extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { homeAdvisorSpId, homeAdvisorUrl, crmKey, updateSettingsConfig } = this.props;

    return (
      <div className="form-section">
        <div className="form-section-content">
          <Row>
            <Col sm={4} className="d-flex">
              <img
                className="img-fluid align-self-center integration-logo"
                src="/assets/images/home_advisor.e8925074.png"
                title="HomeAdvisor"
              />
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Home Advisor spEntityId</Form.Label>
                <Form.Control
                  type="text"
                  id="ha_spid"
                  defaultValue={homeAdvisorSpId}
                  onChange={(e) => updateSettingsConfig(["integrations", "home_advisor", "spEntityId"], e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>CRM Key</Form.Label>
                <Form.Control type="text" id="ha_crm_key" defaultValue={crmKey} readOnly />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  id="ha_url"
                  defaultValue={homeAdvisorUrl}
                  onChange={(e) => updateSettingsConfig(["integrations", "home_advisor", "url"], e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connector(HomeAdvisor);
