import * as React from "react";
import * as FontAwesome from "react-fontawesome";

export interface SearchBoxProps {
  searching: (query: string) => void;
  placeholder: string;
  query: string;
  containerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export const SearchBox: React.FC<SearchBoxProps> = ({ searching, placeholder, query, containerProps, inputProps }) => {
  const [localQuery, setLocalQuery] = React.useState(query);

  const debouncedSearch = React.useCallback(_.debounce(searching, 500), []);

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newQuery = e.target.value;
    setLocalQuery(newQuery);
    debouncedSearch(newQuery);
  };

  const clearSearch = (): void => {
    const newQuery = "";
    setLocalQuery(newQuery);
    debouncedSearch(newQuery);
  };

  return (
    <div className="search-box" {...(containerProps || {})}>
      <FontAwesome name="search" className="fa-gray" />
      <input
        id="estimate-product-search"
        onChange={searchChange}
        type="text"
        autoFocus
        value={localQuery}
        name="search"
        className="form-control"
        placeholder={placeholder}
        {...(inputProps || {})}
      />
      {query.length > 0 && <FontAwesome title="clear search" onClick={clearSearch} name="times" className="fa-gray" />}
    </div>
  );
};
