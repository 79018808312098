import { baseCleanProps, listParams } from "app2/src/api/Api";
import { FolderRecord, IFolderData } from "app2/src/records/Folder";
import { QueryParamsRecord } from "app2/src/records/Page";
import { fetcher } from "../helpers/Fetcher";

export const url = "api/v1/folders";

export interface IFolderIndexResponse {
  folders: IFolderData[];
  parents: IFolderData[];
}

export interface IFolderOptions {
  folderable_id: number;
  folderable_type: string;
  name: string;
}

export interface IFolderResponse {
  folder: IFolderData;
}

export const list = (folderParentId: number, queryParams: QueryParamsRecord): Promise<IFolderIndexResponse> => {
  const params = listParams(queryParams);
  params.id = folderParentId;

  return fetcher.get<IFolderIndexResponse>(url, params);
};

export const byName = (options: IFolderOptions, queryParams: QueryParamsRecord): Promise<IFolderIndexResponse> => {
  const params = { ...listParams(queryParams), ...options };

  const actionUrl = fetcher.joinUrls(url, "by_name");
  return fetcher.get<IFolderIndexResponse>(actionUrl, params);
};

export const updateOrCreate = (folder: FolderRecord): Promise<IFolderResponse> => {
  if (folder.id > 0) {
    return update(folder);
  } else {
    return create(folder);
  }
};

export const create = (folder: FolderRecord): Promise<IFolderResponse> => {
  return fetcher.post<IFolderResponse>(url, { folder: cleanProps(folder), ...folder.createParams.toJS() });
};

export const archive = (id: number): Promise<IFolderResponse> => {
  const actionUrl = fetcher.joinUrls(url, id.toString());
  return fetcher.delete(actionUrl);
};

export const update = (folder: FolderRecord): Promise<IFolderResponse> => {
  const actionUrl = fetcher.joinUrls(url, folder.id.toString());
  return fetcher.patch(actionUrl, cleanProps(folder));
};

export const cleanProps = (folder: FolderRecord): IFolderData => {
  const folderParams = baseCleanProps(folder.toJS());

  if (folderParams.parent_id <= 0) {
    delete folderParams.parent_id;
  }

  delete folderParams.createParams;

  return folderParams;
};
