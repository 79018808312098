import * as React from "react";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ImageRecord, getUrl } from "app2/src/records/Image";
import { List } from "immutable";

export interface ImagePickerModalProps {
  show: boolean;
  closeHandler: () => void;
  images: List<ImageRecord>;
  selectionHandler: (images: List<ImageRecord>) => void;
  selectMultiple: boolean;
}

export interface ImagePickerModalState {
  show: boolean;
  images: List<ImageRecord>;
  selectedImages: List<ImageRecord>;
}

export const useSelectedImages = (
  images: List<ImageRecord>,
  selectMultiple: boolean,
): [List<ImageRecord>, (ImageRecord) => void, () => {}] => {
  const [selectedImages, setSelectedImages] = useState(List<ImageRecord>());

  const clearSelectedImages = () => setSelectedImages(List());
  const setSelectedImage = (image: ImageRecord) => {
    // we dont want to select more than one image if we have said not to
    // but we should check to make sure we aren't deselecting the single
    // image we selected first
    if (selectMultiple === false && selectedImages.size === 1) {
      if (selectedImages.map((i) => i.id).contains(image.id)) clearSelectedImages();
      return;
    }
    const selectedImgIdx = selectedImages.indexOf(image);

    // if we select an already selected image, we should remove it
    // otherwise, go ahead and actually select it
    if (selectedImages.map((i) => i.id).contains(image.id)) {
      setSelectedImages((curImages) => curImages.filter((i) => i.id !== image.id));
    } else {
      setSelectedImages((curImages) => List([...curImages, image]));
    }
  };
  return [
    selectedImages as List<ImageRecord>,
    setSelectedImage as (ImageRecord) => void,
    clearSelectedImages as () => {},
  ];
};

export const ImagePickerModal: React.FunctionComponent<ImagePickerModalProps> = (props) => {
  const [show, setShow] = useState(props.show);
  const [selectedImages, setSelectedImage, clearSelectedImages] = useSelectedImages(List(), props.selectMultiple);

  useEffect(() => {
    if (props.show === true && show === false) {
      setShow(true);
    }
  }, [props.show, show]);

  const handleClose = () => {
    clearSelectedImages();
    props.closeHandler();
    setShow(false);
  };

  const handleSelection = () => {
    if (selectedImages.size > 0) {
      props.selectionHandler(selectedImages);
    }
    handleClose();
  };

  const borderClass = (image) =>
    selectedImages.map((i) => i.id).contains(image.id) ? "selected-image" : "unselected-image";

  return (
    <Modal show={show} size="xl">
      <Modal.Body className="overflow-y-scroll">
        <div className="grid-row">
          {props.images.map((image, idx) => (
            <a href="#" key={idx} onClick={() => setSelectedImage(image)} className="selectable-image">
              <img
                // @ts-ignore
                src={getUrl(image, "small")}
                className={borderClass(image)}
              />
              <h3>{image.name}</h3>
            </a>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSelection}>Select Image{selectedImages.size === 1 ? "" : "s"}</Button>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
