import * as React from "react";
import useFullScreenToggle from "app2/src/hooks/useFullScreenToggle/useFullScreenToggle";
import * as FontAwesome from "react-fontawesome";
import { BrowserDetection } from "app2/src/helpers/BrowserDetection";
import { useTracking } from "react-tracking";

export default function ToggleFullscreenButton() {
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle();
  const { trackEvent } = useTracking();
  if (BrowserDetection.isBrowserMobile()) {
    return null;
  }

  const toogleScreen = () => {
    toggleFullScreen();
    const action = isFullScreen ? "exit full screen" : "full screen";
    trackEvent({ action });
  };

  return (
    <div onClick={toogleScreen}>
      {isFullScreen ? (
        <FontAwesome name="compress fa-gray link" title="Exit Full Screen" size="lg" />
      ) : (
        <FontAwesome name="expand fa-gray link" title="Full Screen" size="lg" />
      )}
    </div>
  );
}
