import * as React from "react";
import { Button, Row, Col } from "react-bootstrap";
import track from "react-tracking";

interface ErrorPageProps {
  message: string;
}

interface ErrorPageState {}
@track(
  (props) => {
    return {
      component: "Error Page",
      message: props.message,
    };
  },
  { dispatchOnMount: () => ({ action: "shown" }) },
)
export default class ErrorPage extends React.Component<ErrorPageProps, ErrorPageState> {
  constructor(props: ErrorPageProps) {
    super(props);

    this.closeTab = this.closeTab.bind(this);
  }

  @track({ action: "closeWindow" })
  public closeTab(): any {
    window.close();
  }

  public render(): any {
    const { message } = this.props;

    return (
      <Row>
        <Col>
          <h1 className="text-center">Error: {message}</h1>
          <h3 className="text-center">
            The URL you're trying to visit is not valid. Please try again. If the problem persists, please contact
            support.
          </h3>
          <div className="text-center">
            <Button onClick={this.closeTab}>Close Window</Button>
          </div>
        </Col>
      </Row>
    );
  }
}
