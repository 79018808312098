import { dispatch } from "app2/src/storeRegistry";
import { Actions as commonActions } from "app2/src/reducers/components/common.actions";
import { LocationStateRecord } from "app2/src/records/LocationState";

export class ForbiddenHttpInterceptor implements ng.IHttpInterceptor {
  constructor(
    private $q: ng.IQService,
    private $injector: ng.auto.IInjectorService,
  ) {}

  public responseError = (rejection: ng.IHttpResponse<any>): ng.IPromise<any> => {
    if (rejection.status === 404) {
      const $state = this.$injector.get("$state") as ng.ui.IStateService;
      $state.go("root.error");
    }

    if (rejection.status === 403 && rejection.config?.params?.catchAuth !== false) {
      const $state = this.$injector.get("$state") as ng.ui.IStateService;
      const $window = this.$injector.get("$window") as ng.IWindowService;
      dispatch(
        commonActions.setSavedLocationState(
          new LocationStateRecord({
            pathname: $window.location.pathname,
            search: $window.location.search,
            hash: $window.location.hash,
          }),
        ),
      ),
        $state.go("root.permissions");
    }
    return this.$q.reject(rejection);
  };

  public static factory($q: ng.IQService, $injector: ng.auto.IInjectorService): ForbiddenHttpInterceptor {
    return new ForbiddenHttpInterceptor($q, $injector);
  }
}

ForbiddenHttpInterceptor.factory.$inject = ["$q", "$injector"];
