import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { centerView, centerViewOnFeatures, geoJSONToFenceMeasurement, load, setup } from "app2/src/lib/FencingTool";
import { setupAndExport } from "app2/src/lib/MappingTool";
import { FencingRecord } from "app2/src/records/measurements/Fencing";
import { RootState } from "app2/src/reducers";
import * as fencingActions from "app2/src/reducers/measurements/fencing.actions";
import {
  measurementIdFullFencing as selectFencing,
  dirty as fencingIsDirty,
} from "app2/src/selectors/measurements/fencing.selectors";
import { RootDispatchType } from "app2/src/store";
import { FeatureCollection } from "geojson";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Fencing from "./FencingSummary";
import { currentJob } from "app2/src/selectors/job.selectors";
import { toLatLng } from "app2/src/records/Address";
import * as imageActions from "app2/src/reducers/image.actions";
import { IImageOptions } from "app2/src/api/image.service";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import track from "react-tracking";

interface FencingContainerProps {
  openIdx: number;
}

const mapStateToProps = (state: RootState, ownProps: FencingContainerProps) => {
  const job = currentJob(state);
  const measurementId = job.get("measurement_id");
  const fencingRecord = selectFencing(state, { measurementId });
  return {
    address: job.get("address"),
    fullFencing: fencingRecord,
    measurementId,
    isDirty: fencingRecord?.id ? fencingIsDirty(state, { fencingId: fencingRecord.id }) : false,
    jobId: job.get("id"),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: FencingContainerProps) => {
  return {
    load: (measurementId: number) => dispatch(fencingActions.AsyncActions.fetchByMeasurementId(measurementId)),
    save: (measurementId: number) => dispatch(fencingActions.AsyncActions.save(measurementId)),
    update: (fencing: FencingRecord) => dispatch(fencingActions.Actions.setFencing(fencing)),
    reset: (fencingId: number) => dispatch(fencingActions.Actions.resetFencing(fencingId)),
    createImages: (files: any[], options: IImageOptions) =>
      dispatch(imageActions.AsyncActions.batchCreateImage(files, options)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FencingContainerProps;

interface FencingContainerState {
  open: boolean;
  currentIdx: number;
  exporting: boolean;
  disabled: boolean;
}

@track(() => ({
  component: "FencingContainer",
}))
class FencingContainer extends React.Component<Props, FencingContainerState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      currentIdx: 0,
      exporting: false,
      disabled: false,
    };

    this.saveFencing = this.saveFencing.bind(this);
    this.shouldOpen = this.shouldOpen.bind(this);
    this.mergeFeatures = this.mergeFeatures.bind(this);
    this.cancel = this.cancel.bind(this);
    this.takeScreenShot = this.takeScreenShot.bind(this);
  }

  public componentDidUpdate() {
    this.shouldOpen();
  }

  public setDisabled = () => {
    this.setState({ disabled: true });
  };
  public setAvailable = () => {
    this.setState({ disabled: false });
  };

  public render() {
    const { open, exporting, disabled } = this.state;
    const { fullFencing, address, isDirty } = this.props;

    return (
      <Modal show={open} size="xl" backdrop="static">
        <SpinnerComponent localProperty={exporting} />
        <Modal.Header closeButton={true}>Fencing Tool</Modal.Header>
        <Modal.Body>
          {_.isNull(toLatLng(address)) ? (
            <Row>
              <Col>
                <div className="form-section blank-state">
                  <img src="/assets/images/icons-large/location.86981dce.png" />
                  <h2>No valid address</h2>
                  <p>Enter in a valid address on the Information tab to generate a map.</p>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="h-100">
              <Col lg={6} xl={7}>
                <div id="map" style={{ width: "100%", height: "100%" }}></div>
              </Col>
              <Col lg={6} xl={5} className="overflow-scroll mh-100">
                <Fencing fencingId={fullFencing?.id} expanded={true} />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            id="fence-export-button"
            className="pull-left"
            onClick={this.takeScreenShot}
            disabled={(fullFencing?.id || 0) <= 0 || !fullFencing?.get("totalLength") || disabled || isDirty}>
            Export Fence Drawing
          </Button>
          <div>
            <Button data-testid="save" className="pull-right" onClick={this.saveFencing} disabled={!isDirty}>
              Save
            </Button>
            <Button data-testid="cancel" className="pull-right" onClick={this.cancel}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  @track(() => ({
    action: "cancel fencing tool",
  }))
  protected cancel() {
    const { reset, fullFencing } = this.props;

    reset(fullFencing.id);
    this.setState({ open: false });
  }

  @track(() => ({
    action: "save fencing tool",
  }))
  protected saveFencing() {
    const { measurementId, save } = this.props;
    save(measurementId);
    centerViewOnFeatures();
  }

  protected mergeFeatures(geoJSON: FeatureCollection) {
    const { fullFencing, update } = this.props;

    this.setAvailable();
    update(geoJSONToFenceMeasurement(geoJSON, fullFencing));
  }

  @track(() => ({
    action: "take screen shot",
  }))
  protected takeScreenShot() {
    this.setState({ exporting: true });
    const { jobId, createImages } = this.props;
    const self = this;
    const callBack = (image) => {
      const file: any = _.dataURLToFile(image, `Fence Drawing ${new Date().toDateString()}.jpg`);
      createImages([file], { imageableType: "job", imageableId: jobId }).then(() => {
        self.setState({ exporting: false });
        setup("", this.mergeFeatures);
        centerViewOnFeatures();
      });
    };
    setupAndExport(callBack);
    centerViewOnFeatures();
  }

  protected shouldOpen() {
    const { openIdx, address, load: loadFencing, measurementId, fullFencing } = this.props;
    const { currentIdx } = this.state;

    if (openIdx > currentIdx) {
      if (!fullFencing && measurementId) {
        loadFencing(measurementId).then(() => {
          if (this.props.fullFencing && this.props.fullFencing.geoJSON) load(this.props.fullFencing.geoJSON);
          centerViewOnFeatures();
        });
      }

      this.setState(
        {
          open: true,
          currentIdx: openIdx,
        },
        () => {
          if (_.isNull(toLatLng(address))) {
            return;
          }

          setup("map", this.mergeFeatures, this.setDisabled, this.setAvailable);
          centerView(address.lat, address.lon);

          if (fullFencing && fullFencing.geoJSON) {
            load(fullFencing.geoJSON);
            centerViewOnFeatures();
          }
        },
      );
    }
  }
}

export default connector(FencingContainer);
