import * as React from "react";
import { RootState } from "app2/src/reducers";
import { jobEstimateSignedDocuments } from "app2/src/selectors/estimate.selectors";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { EstimateDocumentsRow } from "./EstimateDocumentsRow";
import { currentJobId } from "app2/src/selectors/job.selectors";
import * as signedDocumentActions from "app2/src/reducers/signedDocument.actions";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { currentPageRecord } from "app2/src/selectors/pagination.selectors";

export const EstimateDocuments: React.FC = () => {
  const dispatch = useDispatch();

  const jobId = useSelector(currentJobId);
  const estimates = useSelector((state: RootState) => jobEstimateSignedDocuments(state, { jobId }));
  const loading = useSelector(
    (state: RootState) => currentPageRecord(state, { modelName: "estimateDocuments" })?.loading,
  );

  React.useEffect(() => {
    dispatch(signedDocumentActions.AsyncActions.listEstimateSignedDocuments(jobId));
  }, []);

  if (loading) return <SpinnerComponent localProperty={true} inline />;

  if (estimates?.size > 0)
    return (
      <>
        {estimates?.map((estimate) => (
          <EstimateDocumentsRow
            key={estimate.estimateId}
            estimateId={estimate.estimateId}
            signedDocumentIds={estimate.signedDocumentIds}
          />
        ))}
      </>
    );

  return (
    <Row>
      <Col md={12}>
        <div className="form-section blank-state">
          <img src="/assets/images/icons-large/contracts.c69d9978.png" />
          <h2>No signed documents.</h2>
        </div>
      </Col>
    </Row>
  );
};

export default EstimateDocuments;
