import * as React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";
import { FormControl } from "@tberrysoln/rsf-form";
import { IPretty } from "app/src/Common/PrettyNameService";
import { StoreRegistry } from "app2/src/storeRegistry";
import { getUoM } from "app2/src/records/Measurement";
import { room as roomSelector } from "app2/src/selectors/room.selectors";

interface IMeasurementTable {
  values: string[];
  roomId: string | number;
}

export const RoomMeasurementTable: React.FC<IMeasurementTable> = ({ values, roomId }) => {
  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const room = useSelector((state: RootState) => roomSelector(state, { roomId }));
  const editing = useSelector(measurementEditing);
  const pretty: IPretty = StoreRegistry.get("Pretty");
  const uom = getUoM(measurement);

  return (
    <Table className={editing ? "reduce-padding" : ""}>
      <thead>
        <tr>
          <th>Measurement</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {values.map((value, idx) => {
          return (
            <tr key={idx}>
              <td>{pretty.name[value]}</td>
              <td>
                {editing ? (
                  <>
                    <FormControl
                      formGroupProps={{ className: "small floater-right" }}
                      formControlProps={{ type: "number" }}
                      label=""
                      name={value}
                    />
                    <div className="floater">{uom[`interior_${value}`]}</div>
                  </>
                ) : (
                  `${room?.get(value)} ${uom[`interior_${value}`]}`
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
