import * as React from "react";
import { Row, Col } from "react-bootstrap";
import AuthorizeButton from "./AuthorizeButton";

export interface CrmAuthorizeProps {
  image: string;
  title: string;
  authorized: boolean;
  authorize: () => void;
  settings?: () => void;
  rejectAuthorization: () => void;
  authless?: boolean;
}

export default function CrmAuthorize(props: CrmAuthorizeProps) {
  return (
    <Row className="integration-heading-logo">
      <Col md={4} className="text-center">
        <img src={props.image} title={props.title} />
      </Col>
      <Col md={8}>
        <div className="align-center">
          <AuthorizeButton
            authorized={props.authorized}
            authorize={props.authorize}
            rejectAuthorization={props.rejectAuthorization}
            settings={props.settings}
            authless={props.authless}
          />
        </div>
      </Col>
    </Row>
  );
}
