import { ISignedDocumentRecipient, ISignedDocumentRecipientResource } from "../Models/SignedDocumentRecipient";
import { ISignedDocument, ISignedDocumentResource } from "../Models/SignedDocument";
import { IPretty } from "../Common/PrettyNameService";
import { FlashLevels, IFlash } from "../Common/FlashService";
import { IOrg } from "../Models/Org";

class SignatureNotificationCtrl implements ng.IComponentController {
  public signedDocument: ISignedDocument;
  public org: IOrg;
  public resolve: any;
  protected windowRef: any;

  public static $inject = ["$window", "Pretty", "SignedDocument", "SignedDocumentRecipient", "Flash"];
  constructor(
    protected $window: ng.IWindowService,
    public Pretty: IPretty,
    protected SignedDocument: ISignedDocumentResource,
    protected SignedDocumentRecipient: ISignedDocumentRecipientResource,
    protected Flash: IFlash,
  ) {}

  public $onInit() {
    this.signedDocument = this.resolve.signedDocument;
    this.org = this.resolve.org;

    if (_.isFunction(this.signedDocument.$get)) {
      this.signedDocument.$get({ "include[]": ["document", "recipients"] });
    } else {
      this.signedDocument = this.SignedDocument.get({
        id: this.signedDocument.id,
        "include[]": ["document", "recipients"],
      });
    }
  }

  public signOnscreen(recipient: ISignedDocumentRecipient) {
    this.windowRef = this.$window.open("", "_blank");
    this.redirectToSign(recipient);
  }

  public email(recipient: ISignedDocumentRecipient) {
    this.signedDocument.$email({ "recipients[]": [recipient.id] }).then(() => {
      this.Flash.addMessage(FlashLevels.success, "Signature Document successfully sent.");
    });
  }

  public emailAll() {
    this.signedDocument.$email().then(() => {
      this.Flash.addMessage(FlashLevels.success, "Signature Document successfully sent.");
    });
  }

  private redirectToSign(recipient: ISignedDocumentRecipient) {
    recipient.status = "viewed";
    this.resolve.signedDocument.status = "viewed";
    this.windowRef.location = recipient.rs_url as any;
    this.windowRef = null;
  }
}

export class SignatureNotificationComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };
  public templateUrl = "src/Signature/signature_notification.html";

  constructor() {
    this.controller = SignatureNotificationCtrl;
  }
}
