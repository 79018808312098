import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { calculateRate, filterByValidation, filterByStatus, FinanceOptionRecord } from "app2/src/records/FinanceOption";
import { Map, List } from "immutable";
import { job } from "app2/src/selectors/job.selectors";
import { calculatedFinancing } from "app2/src/selectors/calculatedFinancing.selectors";
import { EstimateRecord } from "app2/src/records/Estimate";
import { estimateFinanceOptions } from "app2/src/selectors/estimateFinanceOption.selectors";
import { estimate } from "app2/src/selectors/estimate.selectors";

export const financeOption = (state: RootState, props: any) =>
  state.getIn(["orgs", "financeOptions", "byId", props.financeOptionId]);
export const financeOptionsById = (state: RootState) => state.getIn(["orgs", "financeOptions", "byId"]);
export const orgFinanceOptionIds = (state: RootState, props: any) =>
  state.getIn(["orgs", "financeOptions", "byOrgId", parseInt(props.orgId), "financeOptions"]);

export const financeOptions = createSelector(
  [financeOptionsById, (state, props) => ({ state, props })],
  (byId: Map<number, FinanceOptionRecord>, stateProps): List<FinanceOptionRecord> => {
    const { props } = stateProps;
    const { financeOptionIds } = props;
    if (!financeOptionIds) {
      return List();
    }

    return financeOptionIds.map((id: number) => byId.get(id));
  },
);

export const filteredFinanceOptions = createSelector(
  [financeOptions, (state, props) => ({ state, props })],
  (financeOptions: List<FinanceOptionRecord>, stateProps): List<FinanceOptionRecord> => {
    const { state, props } = stateProps;
    const { providers, financedAmount, forceRefresh } = props;

    let safeFinanceOptions = financeOptions.filter((fo) => !!fo);

    if (providers) {
      safeFinanceOptions = safeFinanceOptions.filter((fo) => providers.includes(fo.provider));
    }

    if (!forceRefresh && financedAmount) {
      // calulated financing already validly calculated
      safeFinanceOptions = safeFinanceOptions.filter((fo) => {
        return !calculatedFinancing(state, { financedAmount, financeOptionId: fo.id });
      });
    }

    return safeFinanceOptions;
  },
);

export const getOrgFinanceOptions = createSelector(
  [financeOptionsById, orgFinanceOptionIds],
  (byId: Map<number, FinanceOptionRecord>, financeOptions: List<number>): List<FinanceOptionRecord> => {
    if (!financeOptions) {
      return List();
    }

    return financeOptions.map((id) => byId.get(id)).sortBy((fo) => fo.sort_order);
  },
);

export const rangeFinanceOptions = createSelector(
  [estimate, (state, props) => ({ state, props })],
  (estimate: EstimateRecord, stateProps: any): List<FinanceOptionRecord> => {
    const { state, props } = stateProps;
    const estimateFinanceOptionsList = estimateFinanceOptions(state, {
      estimateFinanceOptionIds: estimate.finance_option_ids,
    });
    let financeOptions = sortedFilteredFinanceOptions(state, {
      orgId: props.orgId,
      estimate: estimate,
      filterValidation: true,
    });

    if (estimateFinanceOptionsList.size > 0) {
      const financeOptionEstimateIds = estimateFinanceOptionsList.map((efo) => efo.finance_option_id);
      financeOptions = financeOptions.filter((o) => financeOptionEstimateIds.contains(o.id));
    }

    return financeOptions;
  },
);

export const sortedFilteredFinanceOptions = createSelector(
  [getOrgFinanceOptions, (state, props) => ({ state, props })],
  (financeOptions: List<FinanceOptionRecord>, stateProps): List<FinanceOptionRecord> => {
    const { state, props } = stateProps;
    const { sort, reverse, estimate, filterValidation, providers } = props;

    financeOptions = filterByStatus(financeOptions);

    if (filterValidation && estimate) {
      const jobState = job(state, { jobId: estimate.job_id }).getIn(["address", "state"], "");
      financeOptions = filterByValidation(financeOptions, estimate, jobState);
    }

    if (!sort || sort === "payment") {
      if (estimate) {
        financeOptions = financeOptions.sortBy((option) => calculateRate(option, estimate));
      }
    } else {
      // @ts-ignore - this complains, but "sort" will always be an attribute of options
      financeOptions = financeOptions.sortBy((option) => option.get(sort));
    }

    if (reverse) {
      return financeOptions.reverse();
    }

    if (providers) {
      financeOptions = financeOptions.filter((fo) => providers.includes(fo.provider));
    }

    return financeOptions;
  },
);
