import { FinanceApplicationRecord } from "app2/src/records/FinanceApplication";
import { RootState } from "app2/src/reducers";

interface IFinanceApplicationProps {
  estimateId: number;
}

/**
 * Loads the finance application by estimate id
 *
 * @param {RootState} state The RootState
 * @param {{ estimateId: number }} options
 * @returns {FinanceApplicationRecord} FinanceApplicationRecord
 */
export const financeApplication = (state: RootState, props: IFinanceApplicationProps): FinanceApplicationRecord =>
  state.getIn(["financeApplications", "byEstimateId", props.estimateId]);
