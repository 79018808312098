import { createSelector } from "reselect";
import { List } from "immutable";
import { DocumentRecord, IDocumentData, isPdfFile } from "../records/Document";
import { RootState } from "../reducers";
import { resourceDocuments } from "app2/src/selectors/job.selectors";
import { ids } from "app2/src/selectors/pagination.selectors";
import { recordChanged } from "app2/src/helpers/Record";

export const documentsById = (state: RootState) => state.getIn(["documents", "byId"]);
export const document = (state: RootState, props: any) => state.getIn(["documents", "byId", props.documentId]);
export const lastSavedDocument = (state: RootState, props: any) =>
  state.getIn(["documents", "lastSavedById", props.documentId]);

export const documentChanged = createSelector(
  [document, lastSavedDocument],
  (document: DocumentRecord, lastSavedDocument: DocumentRecord) => {
    return recordChanged(document, lastSavedDocument);
  },
);

export const documents = createSelector(
  [documentsById, (_: RootState, props: any) => props],
  (documentsById, props: any): List<DocumentRecord> => {
    const { documentIds } = props;
    let { fullEstimate } = props;
    if (_.isUndefined(fullEstimate)) {
      fullEstimate = false;
    }
    return documentIds
      .map((docId: number) => {
        return documentsById.get(docId);
      })
      .filter((doc: DocumentRecord) => {
        return fullEstimate || !doc._destroy;
      })
      .toList();
  },
);

export const jobResourceDocument = createSelector(
  [document, resourceDocuments],
  (document, resourceDocuments): DocumentRecord => {
    if (_.isNullOrUndefined(document)) {
      return null;
    }

    const jobResourceDocument = resourceDocuments.find((rd) => rd.get("id") === document.id);

    if (!jobResourceDocument) {
      return document;
    }

    return document
      .set("displayInAgreement", jobResourceDocument.get("displayInAgreement"))
      .set("displayInProposal", jobResourceDocument.get("displayInProposal"));
  },
);

export const documentsSelected = createSelector(
  [
    documentsById,
    (state: RootState, props: any) => ({
      state,
      props,
    }),
  ],
  (documentsById, stateProps: any): boolean => {
    const { state } = stateProps;
    const documentIds = ids(state, { modelName: "document" });
    if (!documentIds || documentIds.size === 0) {
      return false;
    }

    const documents = documentIds.map((docId: number) => documentsById.get(docId));

    return !!documents.find((document: DocumentRecord) => {
      return document.selected === true;
    });
  },
);

export const documentsSelectedIds = createSelector(
  [
    documentsById,
    (state: RootState, props: any) => ({
      state,
      props,
    }),
  ],
  (documentsById, stateProps: any): List<number> => {
    const { state } = stateProps;
    const documentIds = ids(state, { modelName: "document" });
    if (!documentIds || documentIds.size === 0) {
      return List();
    }

    const documents = documentIds.map((docId: number) => documentsById.get(docId));

    return documents
      .filter((document: DocumentRecord) => {
        return document.selected === true;
      })
      .map((document: DocumentRecord) => document.id);
  },
);

export const booleanChecked = createSelector(
  [documentsById, (_: RootState, props: any) => props],
  (documentsById, props: any): boolean => {
    const { documentIds, booleanName, filtered } = props;
    if (!documentIds) {
      return false;
    }

    const documents = documentIds
      .map((docId: number) => documentsById.get(docId))
      .filter((document: DocumentRecord) => !filtered || isPdfFile(document));

    if (documents.size === 0) {
      return false;
    }

    return !documents.find((document: DocumentRecord) => {
      return document[booleanName] === false;
    });
  },
);

export const toDocumentsJson = createSelector([documents], (documents: List<DocumentRecord>): IDocumentData[] => {
  return documents
    .map((doc: DocumentRecord) => {
      const docData = doc.toJS() as any;

      if (!docData._destroy) {
        delete docData._destroy;
      }

      return docData as IDocumentData;
    })
    .toArray();
});
