import * as React from "react";
import { getIn } from "immutable";
import { Can } from "../../../Common/CanComponent";
import { AccessRecord, UserRecord, getFullName } from "app2/src/records/UserRecord";
import { IPretty } from "app/src/Common/PrettyNameService";
import { DateFormat } from "app2/src/helpers/Format";
import { selectById } from "app2/src/selectors/access.selectors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { StoreRegistry } from "app2/src/storeRegistry";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { Actions as PaginationActions } from "app2/src/reducers/pagination.actions";
import { AsyncActions as UserAsyncActions } from "app2/src/reducers/user.actions";
import { usersAccessAtOrgId } from "app2/src/selectors/user.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { Dropdown } from "react-bootstrap";

export interface IUserRowProps {
  user: UserRecord;
  editUserCallback: (userId: number) => void;
  editCommissionCallback: (userId: number) => void;
  createSampleJobsCallback: (userId: number) => void;
}

export const UserRow: React.FC<IUserRowProps> = ({
  user,
  editUserCallback,
  editCommissionCallback,
  createSampleJobsCallback,
}) => {
  // hooks
  const dispatch = useDispatch();
  const [Pretty] = React.useState(() => StoreRegistry.get<IPretty>("Pretty"));

  // selectors
  const access = useSelector<RootState, AccessRecord>((state) =>
    usersAccessAtOrgId(state, { userId: user.id, orgId: currentOrgId(state) }),
  );

  return (
    <tr>
      <td>{user.email}</td>
      <td>{getFullName(user)}</td>
      <td>{Pretty.name[access.template || "none"]}</td>
      <Can resource="Global" permission="user">
        <td>{Pretty.name[access.billable || "none"]}</td>
      </Can>
      <td>{DateFormat(user.created_at)}</td>
      <td>
        <Can resource="User" permission="update_billing">
          <a className="action-button" title="Edit User" onClick={() => editUserCallback(user.id)}>
            <i className="rsf-edit-link rsf-base-66 action-button" />
          </a>
          <ConfirmDialog title={`Are you sure you wish to send an invitation to ${user.email}?`}>
            {(confirm) => (
              <a
                className="action-button"
                title="Send Invitation"
                onClick={confirm(() => dispatch(UserAsyncActions.sendInvitation(user.id, user.email)))}>
                <i className="rsf-send-link rsf-base-66 action-button" />
              </a>
            )}
          </ConfirmDialog>
        </Can>
        <Can permission="delete" resource="User">
          <ConfirmDialog title={`Are you sure you want to revoke access to ${user.email}?`}>
            {(confirm) => (
              <a
                className="action-button"
                title="Revoke Access"
                onClick={confirm(() => {
                  dispatch(UserAsyncActions.revokeAccess(user.id, access.id));
                  dispatch(PaginationActions.removeId(user.id, "user"));
                })}>
                <i className="rsf-delete-link rsf-base-66 action-button" />
              </a>
            )}
          </ConfirmDialog>
        </Can>
        <Can resource="user" permission="update">
          <a className="action-button" title="Edit Commission Values" onClick={() => editCommissionCallback(user.id)}>
            <i className="rsf-usd-link rsf-base-66 action-button" />
          </a>
        </Can>
        <Can resource="global" permission="user">
          <Dropdown className="d-inline">
            <Dropdown.Toggle variant="link" bsPrefix="p-0">
              <i className="rsf-ellipsis-v-link rsf-base-66 action-button mb-2" title="More Options" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => createSampleJobsCallback(user.id)}>Sample Jobs</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Can>
      </td>
    </tr>
  );
};
