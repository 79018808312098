import { fetcher } from "../helpers/Fetcher";
import { ISkuData, SkuRecord } from "app2/src/records/Sku";
import { baseCleanProps } from "app2/src/api/Api";

export interface ISkuResponse {
  sku?: ISkuData;
}

const url = "api/v1/orgs/:id/skus";

export const create = (sku: SkuRecord): Promise<ISkuResponse> => {
  const actionUrl = urlSetup(sku);
  return fetcher.post<ISkuResponse>(actionUrl, { sku: baseCleanProps(sku.toJS()) });
};

export const update = (sku: SkuRecord): Promise<ISkuResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(sku), sku.id.toString());
  return fetcher.patch(actionUrl, { sku: baseCleanProps(sku.toJS()) });
};

export const destroy = (sku: SkuRecord): Promise<ISkuResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(sku), sku.id.toString());
  return fetcher.delete(actionUrl);
};

export const urlSetup = (sku: SkuRecord): string => {
  return url.replace(":id", sku.org_id.toString());
};
