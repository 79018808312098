import * as React from "react";
import { usePrevious } from "../usePrevious";

/**
 * Checks if the ref scrollHeight is greater than its width
 */
export const useIsOversized = (
  ref: React.MutableRefObject<HTMLDivElement>,
  callback?: (isOversized: boolean, resizedUp: boolean, scrollHeight: number) => void,
  offset = 0,
): boolean => {
  const [isOversized, setIsOversized] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(0);
  const prevWindowWidth = usePrevious(windowWidth);

  const trigger = () => {
    const { clientHeight, scrollHeight } = ref.current;
    const isOversized = clientHeight + offset < scrollHeight;
    const resizedUp = prevWindowWidth < windowWidth;

    setIsOversized(isOversized);
    if (callback) callback(isOversized, resizedUp, scrollHeight);
  };

  React.useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", _.debounce(onResize, 100));

    return () => {
      window.removeEventListener("resize", _.debounce(onResize, 100));
    };
  }, []);

  React.useEffect(() => {
    const { current } = ref;

    if (current) {
      trigger();
    }
  }, [callback, ref, windowWidth]);

  return isOversized;
};
