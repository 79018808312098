import { fromJS, List, Map, Record } from "immutable";
import { RootActions, RootState } from ".";
import { baseColors, parseHueValue } from "../helpers/Color";
import { EventRecord, fromJSON } from "../records/Event";
import * as eventActions from "./event.actions";
import { fetch, receive, reset } from "./Reducer";

export const EventStateRecord = Record({
  busyInfo: Map<string, boolean>(),
  lastSavedById: Map<number, EventRecord>(),
  byId: Map<number, EventRecord>(),
  colors: Map<"byUserId", Map<number, string>>(),
});

export const initialState = EventStateRecord();
export type AppointmentState = typeof initialState;

const model = "events";

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case eventActions.FETCH_EVENT:
      return fetch(state, model, fromJSON({ id: action.payload.eventId }));

    case eventActions.RECEIVE_EVENT:
      return receive(state, model, fromJSON(action.payload.event));

    case eventActions.RECEIVE_EVENTS:
      action.payload.events.forEach((event) => {
        state = reducer(state, eventActions.Actions.receiveEvent(event));
      });
      return state;

    case eventActions.FETCH_BUSY:
      return state;

    case eventActions.RECEIVE_BUSY:
      return state.updateIn([model, "busyInfo"], (busyInfo) => busyInfo.merge(fromJS(action.payload.busyInfo)));

    case eventActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;

      const nameSplit = List(name.split("."));
      const lastName = nameSplit.last();
      const rootName = nameSplit.splice(-1, 1);

      if (lastName === "events_result_id") {
        const eventResultReasonName = rootName.push("events_result_reason_id").toArray();
        state = state.setIn(rootPath.concat(eventResultReasonName), null);
      }

      if (lastName === "result_id") {
        const resultReasonName = rootName.push("result_reason_id").toArray();
        state = state.setIn(rootPath.concat(resultReasonName), 0);
      }

      return state.setIn(rootPath.concat(name.split(".")), value);

    case eventActions.INITIALIZE_EVENT:
      const newState = state.setIn([model, "byId", 0], fromJSON(action.payload.newEventData));
      return newState;

    case eventActions.RESET_EVENT:
      return reset(state, model, action.payload.eventId);

    case eventActions.REMOVE_EVENT:
      return state.deleteIn([model, "byId", action.payload.eventId]);

    case eventActions.GENERATE_COLORS:
      const { userIds, userToAssignId } = action.payload;
      let colorsByUserId: Map<number, string> = state.getIn([model, "colors", "byUserId"], Map<number, string>());

      let colors = baseColors;

      // Generate 20 more colors if needed (should rarely happen)
      if (colorsByUserId.size + 1 > colors.size) {
        colors = colors.concat(
          baseColors.map((c) => {
            return `hsl(${(parseHueValue(c) + 10) % 360}, 50%, 35%)`;
          }),
        );
      }

      // Remove used colors
      colorsByUserId.toList().forEach((color) => {
        if (colors.includes(color)) {
          colors = colors.filter((c) => c !== color);
        }
      });

      userIds.forEach((id) => {
        // Only userIds that haven't been assigned a color
        if (!colorsByUserId.get(id) || colorsByUserId.get(id) === "hsl(0, 0%, 45%)") {
          // Assign colors starting from the top
          let lastAssignedIndex = 0;
          colorsByUserId = colorsByUserId.set(id, colors.get(lastAssignedIndex));
          lastAssignedIndex += 1;
        }
      });

      // Should always be gray
      if (userToAssignId) {
        colorsByUserId = colorsByUserId.set(userToAssignId, "hsl(0, 0%, 45%)");
      }
      return state.setIn([model, "colors", "byUserId"], colorsByUserId);

    default:
      return state;
  }
};
