import { Record } from "immutable";
import { FlashLevels } from "app/src/Common/FlashLevels";

export const fromJSON = (json: Partial<IFlashAlertData>): FlashAlertRecord => {
  return new FlashAlertRecord(json);
};

export interface IFlashAlertData {
  message: string | string[];
  level: FlashLevels;
}

export interface IFlashAlertRecord {
  message: string | string[];
  level: FlashLevels;
}

export const defaultFlashAlertProps = {
  message: "",
  level: FlashLevels.success,
};

export class FlashAlertRecord extends Record<IFlashAlertRecord>(defaultFlashAlertProps) implements IFlashAlertRecord {}
