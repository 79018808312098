import * as React from "react";
import { connectFC } from "app2/src/connect";
import { Button as ReactButton } from "react-bootstrap";
import { Context } from "app2/src/components/Chargebee/Context";

interface ButtonProps {
  customerIds: string[];
}
export const Button: React.FC<ButtonProps> = ({ customerIds }) => {
  const { orgId, dispatch: contextDispatch } = React.useContext(Context);

  return (
    <div>
      {customerIds.map((custID, idx) => (
        <ReactButton
          key={idx}
          onClick={() => contextDispatch("createPortalSession", { custId: custID, orgId: orgId })}
          className="mr-2">
          Manage Subscription
        </ReactButton>
      ))}
    </div>
  );
};

export default connectFC(Button);
