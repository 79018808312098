import { ActionsUnion, createAction } from "../Utils";

export const OPEN_QUICKMEASURE_MODAL = "@components/integrations_view/OPEN_QUICKMEASURE_MODAL";
export const CLOSE_QUICKMEASURE_MODAL = "@components/integrations_view/CLOSE_QUICKMEASURE_MODAL";
export const OPEN_ROOF_SCOPE_MODAL = "@components/integrations_view/OPEN_ROOF_SCOPE_MODAL";
export const CLOSE_ROOF_SCOPE_MODAL = "@components/integrations_view/CLOSE_ROOF_SCOPE_MODAL";
export const OPEN_PLNAR_MODAL = "@components/integrations_view/OPEN_PLNAR_MODAL";
export const CLOSE_PLNAR_MODAL = "@components/integrations_view/CLOSE_PLNAR_MODAL";

export const Actions = {
  openQuickMeasureModal: () => createAction(OPEN_QUICKMEASURE_MODAL),
  closeQuickMeasureModal: () => createAction(CLOSE_QUICKMEASURE_MODAL),
  openRoofScopeModal: () => createAction(OPEN_ROOF_SCOPE_MODAL),
  closeRoofScopeModal: () => createAction(CLOSE_ROOF_SCOPE_MODAL),
  openPlnarModal: () => createAction(OPEN_PLNAR_MODAL),
  closePlnarModal: () => createAction(CLOSE_PLNAR_MODAL),
};

export type Actions = ActionsUnion<typeof Actions>;
