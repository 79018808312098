import { IRoom } from "app/src/Models/Room";
import { List, Record } from "immutable";
import { Nullable } from ".";
import { Parser } from "hot-formula-parser";
import { IRoomEstimationData, RoomEstimationRecord, fromJSON as roomEstimationFromJson } from "./RoomEstimation";

export const fromJSON = (json: Partial<IRoomData>): RoomRecord => {
  const recordData: IRoomRecord = { ...(json as any) };

  if (json.room_estimations) {
    recordData.room_estimations = List(json.room_estimations.map((estimation) => roomEstimationFromJson(estimation)));
  }

  return new RoomRecord(recordData);
};

export interface IRoomData {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  location: string;
  level: string;
  area: number;
  perimeter: number;
  inside_corner: number;
  outside_corner: number;
  threshold: number;
  ceiling_area: number;
  ceiling_area_less_structures: number;
  ceiling_perimeter: number;
  ceiling_perimeter_less_structures: number;
  door_area: number;
  door_count: number;
  door_total_width: number;
  exposed_structure_perimeter: number;
  floor_area_less_structures: number;
  floor_opening_total_width: number;
  floor_plan_area: number;
  floor_plan_perimeter: number;
  floor_plan_perimeter_less_structures: number;
  floor_plan_perimeter_without_openings: number;
  object_count: number;
  opening_count: number;
  opening_total_area: number;
  opening_total_width: number;
  structure_total_ceiling_area: number;
  structure_total_ceiling_width: number;
  structure_total_floor_area: number;
  structure_total_floor_width: number;
  structure_total_wall_area: number;
  volume: number;
  wall_count: number;
  wall_total_area: number;
  wall_total_area_without_openings: number;
  window_count: number;
  window_total_area: number;
  measurement_id: number;
  ref_id: number;
  room_estimations: IRoomEstimationData[];
  viewer_url: Nullable<string>;
  _destroy?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface IRoomRecord {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  location: string;
  level: string;
  area: number;
  perimeter: number;
  inside_corner: number;
  outside_corner: number;
  threshold: number;
  ceiling_area: number;
  ceiling_area_less_structures: number;
  ceiling_perimeter: number;
  ceiling_perimeter_less_structures: number;
  door_area: number;
  door_count: number;
  door_total_width: number;
  exposed_structure_perimeter: number;
  floor_area_less_structures: number;
  floor_opening_total_width: number;
  floor_plan_area: number;
  floor_plan_perimeter: number;
  floor_plan_perimeter_less_structures: number;
  floor_plan_perimeter_without_openings: number;
  object_count: number;
  opening_count: number;
  opening_total_area: number;
  opening_total_width: number;
  structure_total_ceiling_area: number;
  structure_total_ceiling_width: number;
  structure_total_floor_area: number;
  structure_total_floor_width: number;
  structure_total_wall_area: number;
  volume: number;
  wall_count: number;
  wall_total_area: number;
  wall_total_area_without_openings: number;
  window_count: number;
  window_total_area: number;
  measurement_id: number;
  ref_id: number;
  room_estimations: List<RoomEstimationRecord>;
  viewer_url: Nullable<string>;

  _destroy: Nullable<boolean>;
  created_at: Date;
  updated_at: Date;
}

export const defaultRoomProps: IRoomRecord = {
  id: 0,
  name: "",
  designator: "",
  location: "",
  level: "",
  area: 0,
  perimeter: 0,
  inside_corner: 0,
  outside_corner: 0,
  threshold: 0,
  ceiling_area: 0,
  ceiling_area_less_structures: 0,
  ceiling_perimeter: 0,
  ceiling_perimeter_less_structures: 0,
  door_area: 0,
  door_count: 0,
  door_total_width: 0,
  exposed_structure_perimeter: 0,
  floor_area_less_structures: 0,
  floor_opening_total_width: 0,
  floor_plan_area: 0,
  floor_plan_perimeter: 0,
  floor_plan_perimeter_less_structures: 0,
  floor_plan_perimeter_without_openings: 0,
  object_count: 0,
  opening_count: 0,
  opening_total_area: 0,
  opening_total_width: 0,
  structure_total_ceiling_area: 0,
  structure_total_ceiling_width: 0,
  structure_total_floor_area: 0,
  structure_total_floor_width: 0,
  structure_total_wall_area: 0,
  volume: 0,
  wall_count: 0,
  wall_total_area: 0,
  wall_total_area_without_openings: 0,
  window_count: 0,
  window_total_area: 0,
  measurement_id: 0,
  ref_id: 0,
  room_estimations: List(),
  viewer_url: null,

  _destroy: null,
  created_at: new Date(),
  updated_at: new Date(),
};

export class RoomRecord extends Record(defaultRoomProps) implements IRoomRecord {
  public constructor(values?: Partial<IRoomRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}

export const roomColumnKeys = (room: IRoom | RoomRecord) => {
  let keys: string[];
  if (Record.isRecord(room)) {
    keys = Object.keys(room.toJS());
  } else {
    keys = Object.keys(room);
  }

  return keys.filter(
    (k) =>
      ![
        "id",
        "name",
        "designator",
        "location",
        "level",
        "kind",
        "created_at",
        "updated_at",
        "_destroy",
        "ref_id",
        "measurement_id",
        "room_estimations",
      ].includes(k),
  );
};

export const setupFormulaParser = (room: IRoom) => {
  const parser = new Parser();
  _.each(roomColumnKeys(room), (k) => {
    parser.setVariable(k, room[k]);
  });
  return parser;
};

export const calculateRoomFormula = (formula: string, room: IRoom): { result: number; error: string } => {
  if (!formula) {
    return { result: 1, error: null };
  }

  try {
    const parser = setupFormulaParser(room);
    const { error } = parser.parse(formula);
    let { result } = parser.parse(formula);
    if (result) {
      result = _.round(result, -2);
    }
    return { result, error };
  } catch (error) {
    return { result: null, error: "ERROR!" };
  }
};
