import { IElevation } from "./Elevation";

export interface IAnnotation {
  id: number;
  guid: string;
  location: string;
  level: string;
  width: number;
  height: number;
  x: number;
  y: number;
  elevation: IElevation;
  opening_type: string;
  opening_id: number;

  text?: string;
  sort_order?: number;

  update(annotation: IAnnotation): void;
  clone(): IAnnotation;
  decycleToJSON(): any;
}

export class Annotation implements IAnnotation {
  public height: number;
  public level: string;
  public location: string;
  public x: number;
  public y: number;
  public width: number;
  public guid: string;
  public opening_id: number;
  public opening_type = "window";
  public sort_order: number;

  constructor(
    public id: number,
    public elevation: IElevation,
  ) {}

  public get text(): string {
    return `${this.elevation.abbr || ""}${this.id}`;
  }

  public update(annotation: IAnnotation): void {
    this.height = annotation.height;
    this.level = annotation.level;
    this.location = annotation.location;
    this.x = annotation.x;
    this.y = annotation.y;
    this.width = annotation.width;
    this.opening_id = annotation.opening_id;
    this.opening_type = annotation.opening_type;
    this.sort_order = annotation.sort_order;
  }

  public clone(): IAnnotation {
    //noinspection TypeScriptUnresolvedFunction
    const annotation: IAnnotation = new Annotation(this.id, this.elevation);

    annotation.height = this.height;
    annotation.level = this.level;
    annotation.location = this.location;
    annotation.x = this.x;
    annotation.y = this.y;
    annotation.width = this.width;
    annotation.guid = this.guid;
    annotation.opening_id = this.opening_id;
    annotation.opening_type = this.opening_type;
    annotation.sort_order = this.sort_order;

    return annotation;
  }

  public decycleToJSON(): any {
    return angular.fromJson(angular.toJson(JSON.decycle(this)));
  }

  public static fromJSON(data: IAnnotation) {
    const a = new Annotation(data.id, data.elevation);

    a.opening_type = data.opening_type;
    a.opening_id = data.opening_id;
    a.guid = data.guid;
    a.location = data.location;
    a.level = data.level;
    a.width = Number(data.width || 0);
    a.height = Number(data.height || 0);
    a.x = data.x;
    a.y = data.y;

    return a;
  }
}
