import { Record, List } from "immutable";
import { EstimateGroupRecord } from "./EstimateGroup";
import { EstimateFinanceOptionRecord } from "./EstimateFinanceOption";
import { EstimateLineItemOptionRecord } from "app2/src/records/EstimateLineItemOption";
import { EstimateLineItemRecord } from "app2/src/records/EstimateLineItem";
import { OpeningEstimationRecord } from "app2/src/records/OpeningEstimation";
import { ImageRecord } from "app2/src/records/Image";
import { DocumentRecord } from "app2/src/records/Document";
import { EstimateRecord, fromJSON as estimateFromJSON } from "app2/src/records/Estimate";

export interface IRecordsForEstimateRecord {
  estimate: EstimateRecord;
  estimateFinanceOptions: List<EstimateFinanceOptionRecord>;
  estimateGroups: List<EstimateGroupRecord>;
  estimateLineItems: List<EstimateLineItemRecord>;
  estimateLineItemOptions: List<EstimateLineItemOptionRecord>;
  estimateLineItemOpeningEstimations: List<OpeningEstimationRecord>;
  estimateLineItemImages: List<ImageRecord>;
  estimateLineItemDocuments: List<DocumentRecord>;
}

const defaultRecordsForEstimateProps: IRecordsForEstimateRecord = {
  estimate: estimateFromJSON({}),
  estimateFinanceOptions: List<EstimateFinanceOptionRecord>(),
  estimateGroups: List<EstimateGroupRecord>(),
  estimateLineItems: List<EstimateLineItemRecord>(),
  estimateLineItemOptions: List<EstimateLineItemOptionRecord>(),
  estimateLineItemOpeningEstimations: List<OpeningEstimationRecord>(),
  estimateLineItemImages: List<ImageRecord>(),
  estimateLineItemDocuments: List<DocumentRecord>(),
};

export class RecordsForEstimateRecord
  extends Record(defaultRecordsForEstimateProps)
  implements IRecordsForEstimateRecord {}
