import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Row, Col, Button } from "react-bootstrap";
import * as discountActions from "app2/src/reducers/discount.actions";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { is, List } from "immutable";
import { Can } from "app2/src/components/Common/CanComponent";
import { DiscountRecord, IDiscountData } from "app2/src/records/DiscountRecord";
import { Editor } from "./components/Editor";
import Display from "./components/Display";
import { IDirtyWatcher, IDirtyMerge } from "app/src/Common/DirtyWatcher";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { getOrgDiscounts } from "app2/src/selectors/discount.selectors";
import Spinner from "app2/src/components/SpinnerComponent";
import { nextNewId, sortDragEnd } from "app2/src/helpers/Record";

const mapStateToProps = (state: RootState, ownProps: ListProps) => {
  const d = getOrgDiscounts(state, ownProps) as List<DiscountRecord>;
  return {
    discounts: d,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ListProps) => {
  return {
    loadDiscounts: (orgId: number) => dispatch(discountActions.AsyncActions.listDiscounts(orgId)),
    save: (discount: IDiscountData) => {
      if (discount.id <= 0) {
        return dispatch(discountActions.AsyncActions.addDiscount(parseInt(ownProps.orgId), discount));
      }

      return dispatch(discountActions.AsyncActions.updateDiscount(discount));
    },
    archive: (discount: DiscountRecord) => {
      if (discount.id <= 0) {
        dispatch(discountActions.Actions.removeOrgDiscount(discount.org_id, discount.id));
        dispatch(discountActions.Actions.removeDiscount(discount.id));
        return Promise.resolve(discount.set("archived", true));
      }

      return dispatch(discountActions.AsyncActions.archiveDiscount(discount));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ListProps {
  $scope: ng.IScope;
  DirtyWatcher: IDirtyWatcher;
  orgId: string;
  Flash: IFlash;
}

export interface ListState {
  editing: number;
  adding: boolean;
  discounts: List<DiscountRecord>;
  dirty: List<number>;
  spinner: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ListProps;

class ListComponent extends React.Component<Props, ListState> implements IDirtyMerge {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: null,
      adding: false,
      discounts: props.discounts,
      dirty: List<number>(),
      spinner: false,
    };

    const { DirtyWatcher, $scope } = props;

    DirtyWatcher.setup($scope, this);

    this.addNew = this.addNew.bind(this);
    this.updateDiscount = this.updateDiscount.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.edit = this.edit.bind(this);
    this.archive = this.archive.bind(this);
    this.save = this.save.bind(this);
    this.canSave = this.canSave.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!is(this.props.discounts, prevProps.discounts)) {
    }
  }

  public componentDidMount() {
    const { orgId, loadDiscounts } = this.props;

    loadDiscounts(parseInt(orgId)).then(() => {
      this.setState({
        discounts: this.props.discounts,
      });
    });
  }

  public addNew() {
    const { discounts } = this.state;
    const { newId, newSortOrder } = nextNewId(discounts);

    const newDisc = new DiscountRecord({ id: newId, sort_order: newSortOrder });

    this.setState((prevState) => {
      return {
        discounts: prevState.discounts.push(newDisc),
        editing: newDisc.id,
        adding: true,
        dirty: prevState.dirty.push(newDisc.id),
      };
    });
  }

  public updateDiscount(discount: DiscountRecord) {
    const { discounts } = this.state;
    let { dirty } = this.state;

    const idx = _.findIndexBy(discounts.toArray(), (d) => d.id === discount.id);

    const original = this.props.discounts.find((d) => d.id === discount.id);

    if (!is(original, discount)) {
      dirty = dirty.filter((d) => d !== discount.id).push(discount.id);
    } else {
      dirty = dirty.filter((d) => d !== discount.id);
    }

    this.setState({
      discounts: discounts.set(idx, discount),
      editing: null,
      adding: false,
      dirty: dirty,
    });
  }

  public save() {
    const { save, Flash, $scope } = this.props;
    let { dirty, discounts } = this.state;

    const promises = dirty.map((discountId) => {
      return save(discounts.find((d) => d.id === discountId).toJSON()).then(
        (a) => Promise.resolve(a),
        (a) => Promise.resolve(a),
      );
    });

    this.setState({
      spinner: true,
    });

    Promise.all(promises).then((results) => {
      const success = [];
      let errors = [];
      results.forEach((result: IDiscountData | string[], idx) => {
        if (_.isArray(result)) {
          const discIdx = discounts.findIndex((d) => d.id === dirty.get(idx));
          discounts = discounts.setIn([discIdx, "errors"], List(result));
          errors = result;
        } else {
          const discIdx = discounts.findIndex((d) => d.id === dirty.get(idx));
          const newDisc = this.props.discounts.find((d) => d.id === result.id);

          discounts = discounts.set(discIdx, newDisc);
          success.push(idx);
        }
      });

      _.chain(success)
        .sort()
        .reverse()
        .each((idx) => {
          dirty = dirty.delete(idx);
        });

      if (dirty.size <= 0) {
        $scope.$apply(() => Flash.addMessage(FlashLevels.success, "Discounts successfully saved."));
      } else {
        errors.forEach((error) => {
          $scope.$apply(() => Flash.addMessage(FlashLevels.danger, error));
        });
      }

      this.setState({
        discounts: discounts,
        dirty: dirty,
        spinner: false,
      });
    });
  }

  public edit(id: number) {
    this.setState({
      editing: id,
    });
  }

  public archive(id: number) {
    const { archive } = this.props;
    const { discounts, dirty } = this.state;

    const discount = discounts.find((d) => d.id === id);
    this.setState({
      discounts: discounts.filter((d) => d.id !== id),
    });
    if (discount.id > 0) {
      this.setState({
        spinner: true,
      });
      archive(discount).then(
        () => {
          this.setState({
            spinner: false,
          });
        },
        () => {
          this.setState({
            spinner: false,
          });
        },
      );
    } else {
      this.setState({
        dirty: dirty.filter((id) => id !== discount.id),
      });
    }
  }

  public cancelEditing() {
    this.setState((prevState) => {
      const { adding, editing, dirty } = prevState;
      let { discounts } = prevState;

      const nextState: Partial<ListState> = {
        editing: null,
        adding: false,
      };

      if (adding) {
        discounts = discounts.filter((discount) => discount.id !== editing);
        nextState.dirty = dirty.filter((id) => id !== editing);
        nextState.discounts = discounts;
      }

      return nextState as ListState;
    });
  }

  public canSave() {
    const { editing } = this.state;
    return this.check() && _.isNull(editing);
  }

  public onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (result.source.index === result.destination.index) {
      return;
    }
    const { discounts, dirty } = this.state;
    const { list, dirtyIds } = sortDragEnd(discounts, result);

    this.setState({
      discounts: list,
      dirty: dirty.concat(dirtyIds),
    });
  }

  public render() {
    const { discounts, editing, spinner } = this.state;

    return (
      <Spinner localProperty={spinner}>
        <div className="admin-discounts">
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <h1 className="admin-title">Discounts</h1>
                </Col>
                <Col md={6}>
                  <Can resource="discount" permission="create">
                    <Button type="button" variant="add" className="pull-right" onClick={this.addNew}>
                      Add Discount
                    </Button>
                  </Can>
                  <Button
                    variant="save"
                    className="pull-right"
                    onClick={this.save}
                    disabled={!this.canSave()}
                    data-testid="save-all">
                    Save
                  </Button>
                </Col>
              </Row>
              {this.renderHeader(discounts)}
              <Row>
                <Col md={12}>{this.renderBody(discounts, editing)}</Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Spinner>
    );
  }

  public check(): boolean {
    return (
      JSON.stringify(this.props.discounts.sortBy((d) => d.sort_order).toJSON()) !==
      JSON.stringify(this.state.discounts.sortBy((d) => d.sort_order).toJSON())
    );
  }

  public reset() {
    this.setState({
      discounts: this.props.discounts,
    });

    return Promise.resolve(true) as any as ng.IPromise<boolean>;
  }

  public trackEvent(action: any, props: any): void {
    //console.info(action, props);
  }

  private renderHeader(discounts: List<DiscountRecord>) {
    if (discounts.size > 0) {
      return (
        <Row>
          <Col md={12}>
            <div className="match-form-section">
              <Row className="match-table-header">
                <Col md={1}>&nbsp;</Col>
                <Col md={2}>Code</Col>
                <Col md={2}>Description</Col>
                <Col md={2}>Type</Col>
                <Col md={2}>Amount / Limit</Col>
                <Col md={2}></Col>
              </Row>
            </div>
          </Col>
        </Row>
      );
    }

    return <span></span>;
  }

  private renderBody(discounts, editing) {
    if (discounts.size <= 0) {
      return (
        <div className="form-section blank-state">
          <img src="/assets/images/icons-large/estimates.022a621b.png" />
          <h2>No Discounts added. Let's add one.</h2>
          <p>Click on the add discount button in the top right to begin.</p>
        </div>
      );
    }
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="discounts">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
            return (
              <div ref={provided.innerRef}>
                <Row>
                  <Col md={12}>
                    {discounts
                      .toArray()
                      .map((discount) => {
                        if (editing === discount.id) {
                          return (
                            <Editor discount={discount} update={this.updateDiscount} cancel={this.cancelEditing} />
                          );
                        }
                        return <Display discount={discount} edit={this.edit} archive={this.archive} />;
                      })
                      .map((element, idx) => {
                        return (
                          <Draggable
                            key={idx}
                            draggableId={element.props.discount.id.toString()}
                            index={idx}
                            isDragDisabled={!_.isNull(editing)}>
                            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                              return (
                                <div ref={provided.innerRef}>
                                  <Row
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    className="estimate-table">
                                    <Col md={12}>{element}</Col>
                                  </Row>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                  </Col>
                </Row>
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default connector(ListComponent);
