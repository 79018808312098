import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { WindowRecord } from "app2/src/records/Window";
import { recordChanged } from "app2/src/helpers/Record";
import { currentLastSavedMeasurement, currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";

export const rootKey = "windows";

export const window = (state: RootState, props: any) => state.getIn([rootKey, "byId", props.windowId.toString()]);
export const lastSavedWindow = (state: RootState, props: any) =>
  state.getIn([rootKey, "lastSavedById", props.windowId.toString()]);

export const windows = createSelector([(state, props) => ({ state, props })], (stateProps: any): List<WindowRecord> => {
  const { state, props } = stateProps;
  const { windowIds } = props;
  return windowIds.map((id: number) => window(state, { windowId: id }));
});

export const lastSavedWindows = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<WindowRecord> => {
    const { state, props } = stateProps;
    const { windowIds } = props;
    return windowIds.map((id: number) => lastSavedWindow(state, { windowId: id }));
  },
);

/**
 * Looks to the currentJob in redux to get the measurement_id then loads that measurement from the store
 *
 * @params {RootState} state RootState
 * @params {{}} options NONE
 * @returns {MeasurementRecord} measurement
 */
export const windowsChanged = (state: RootState, props: any): boolean => {
  const measurement = currentMeasurement(state, props);
  const lastSavedMeasurement = currentLastSavedMeasurement(state, props);
  return recordChanged(
    windows(state, { windowIds: measurement?.get("window_ids") || List() }),
    lastSavedWindows(state, { windowIds: lastSavedMeasurement?.get("window_ids") || List() }),
  );
};
