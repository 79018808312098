import { Workbox } from "workbox-window";

const serviceWorkerExpiration = 1800000;

export const setupServiceWorker = async function (
  globalNavigator: Navigator,
  globalWindow: Window,
  globalLocalStorage: Storage,
): Promise<void> {
  try {
    globalLocalStorage.setItem("serviceWorkerUpdateExpiration", Date.now().toString());
    const registration = await globalNavigator.serviceWorker.register("/service-worker.js");
    registration.addEventListener("updatefound", () => {
      const wb = new Workbox("/service-worker.js");

      const showSkipWaiting = () => {
        checkForUpdate(wb, globalNavigator, globalWindow, globalLocalStorage);
      };

      wb.addEventListener("waiting", showSkipWaiting);

      wb.register();
    });

    await globalNavigator.serviceWorker.ready;
    if (!globalNavigator.serviceWorker.controller) {
      globalWindow.location.reload();
    }
  } catch (error) {
    console.error("ServiceWorker registration failed", error);
  }
};

export const checkForUpdate = (
  reg: any,
  globalNavigator: Navigator,
  globalWindow: Window,
  globalLocalStorage: Storage,
) => {
  if (!globalNavigator.serviceWorker.controller) {
    console.error("No service worker controller found, do nothing");
    return;
  }

  const expiration = globalLocalStorage.getItem("serviceWorkerUpdateExpiration");

  if (expiration) {
    const expirationDate = new Date(parseInt(expiration, 10));
    const now = new Date();

    if (now > expirationDate) {
      globalWindow.location.reload();
    }
  } else {
    globalLocalStorage.setItem("serviceWorkerUpdateExpiration", (Date.now() + serviceWorkerExpiration).toString());
    globalWindow.location.reload();
  }
};
