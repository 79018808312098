import { ActionsUnion, createAction } from "../Utils";

export const SET_PAYMENT_OPTION_EDITOR_VISIBLE = "@components/common/SET_PAYMENT_OPTION_EDITOR_VISIBLE";
export const SET_PRICING_DRAWER_VISIBLE = "@components/common/SET_PRICING_DRAWER_VISIBLE";

export const Actions = {
  setPaymentOptionEditorVisible: (visible: boolean) => createAction(SET_PAYMENT_OPTION_EDITOR_VISIBLE, { visible }),
  setPricingDrawerVisible: (visible: boolean) => createAction(SET_PRICING_DRAWER_VISIBLE, { visible }),
};

export type Actions = ActionsUnion<typeof Actions>;
