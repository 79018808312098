import { connectFC } from "app2/src/connect";
import { FC, useState } from "react";
import * as React from "react";
import * as FontAwesome from "react-fontawesome";
import { Button, Row, Col } from "react-bootstrap";
import JobQueryForm from "app2/src/components/JobList/JobQueryForm";
import { RootState } from "app2/src/reducers";
import { useDispatch, useSelector } from "react-redux";
import { jobQuery as jobQuerySelector } from "app2/src/selectors/job.selectors";
import { Actions as JobActions } from "app2/src/reducers/job.actions";
import { useTracking } from "react-tracking";
import { lastChangesJobQuery as lastChangesJobQuerySelector } from "app2/src/selectors/job.selectors";
import { getJobQueryChanges } from "./JobQuery";
import { Dispatch } from "app2/src/helpers/Analytics";
export interface FilterButtonProps {
  $scope: ng.IScope;
}

const FilterButton: FC<FilterButtonProps> = ({ $scope }) => {
  // hooks
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const { Track, trackEvent } = useTracking<any>({ component: "Job Filter" }, { dispatch: Dispatch.dispatch });

  // selectors
  const jobQuery = useSelector((state: RootState) => jobQuerySelector(state));
  const lastChangesJobQuery = useSelector((state: RootState) => lastChangesJobQuerySelector(state));

  // methods
  const toggleJobQueryForm = () => {
    if (!showForm) {
      trackEvent({
        action: "open",
      });
    }
    setShowForm((state) => !state);
  };

  const onSubmit = () => {
    setShowForm(false);
    $scope.$emit("filters:trigger_search");
  };

  const onClose = () => {
    dispatch(JobActions.revertJobQuery());
    setShowForm(false);

    const changes = getJobQueryChanges(jobQuery);
    trackEvent({
      action: "close",
      touched: lastChangesJobQuery,
      filters: changes,
    });
  };

  return (
    <Track>
      <Button id="jobs-filter" onClick={toggleJobQueryForm}>
        <FontAwesome name="sliders" />
      </Button>
      {showForm && (
        <div className="job-filter-overlay">
          <Row className="job-filter">
            <Col>
              <Row className="job-filter-title">
                <Col md={10}>
                  <h2>Filter By:</h2>
                </Col>
                <Col md={2} className="right-align">
                  <Button onClick={onClose} id="jobs_filter_close">
                    <FontAwesome name="times" />
                  </Button>
                </Col>
              </Row>
              <JobQueryForm toggleJobQueryForm={toggleJobQueryForm} onSubmit={onSubmit} closeOnSubmit={true} />
            </Col>
          </Row>
        </div>
      )}
    </Track>
  );
};

export default connectFC(FilterButton);
