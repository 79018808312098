import { Record, Map, fromJS } from "immutable";
import { checkDate, Nullable } from "..";

const dateFields = [
  "billing_cycle_anchor",
  "canceled_at",
  "current_period_end",
  "current_period_start",
  "ended_at",
  "proration_date",
  "start",
  "stripe_created",
  "trial_start",
  "trial_end",
  "created_at",
  "updated_at",
];

export const fromJSON = (json: Partial<ISubscriptionData>): SubscriptionRecord => {
  const record = { ...json } as any as ISubscriptionRecord;

  if (json.stripe_data) {
    record.stripe_data = fromJS(json.stripe_data);
  }

  if (json.metadata) {
    record.metadata = fromJS(json.metadata);
  }

  if (json.source) {
    record.source = fromJS(json.source);
  }

  if (json.discount) {
    record.discount = fromJS(json.discount);
  }

  dateFields.forEach((f) => {
    if (json[f]) {
      record[f] = checkDate(json[f] as string | Date);
    }
  });

  return new SubscriptionRecord(record);
};

export interface ISubscriptionData {
  id: number;
  billing_customer_id: number;
  billing_cycle_anchor: Date | string;
  billing_plan_id: number;
  cancel_at_period_end: boolean;
  canceled_at: null | Date | string;
  coupon: string;
  current_period_end: Date | string;
  current_period_start: Date | string;
  days_until_due: boolean;
  discount: any;
  ended_at: null | Date | string;
  metadata: any;
  prorate: boolean;
  proration_date: Date | string;
  quantity: number;
  source: any;
  start: Date | string;
  status: string;
  stripe_billing: string;
  stripe_created: Date | string;
  stripe_data: any;
  stripe_id: string;
  tax_percent: number;
  trial_end: null | Date | string;
  trial_start: null | Date | string;

  created_at: Date | string;
  updated_at: Date | string;
}

export interface ISubscriptionRecord {
  id: number;
  billing_customer_id: number;
  billing_cycle_anchor: Date;
  billing_plan_id: number;
  cancel_at_period_end: boolean;
  canceled_at: Nullable<Date>;
  coupon: string;
  current_period_end: Date;
  current_period_start: Date;
  days_until_due: boolean;
  ended_at: Nullable<Date>;
  prorate: boolean;
  proration_date: Date;
  quantity: number;
  start: Date;
  status: string;
  stripe_billing: string;
  stripe_created: Date;
  stripe_id: string;
  tax_percent: number;
  trial_end: Nullable<Date>;
  trial_start: Nullable<Date>;
  discount: Map<string, any>;
  metadata: Map<string, any>;
  source: Map<string, any>;
  stripe_data: Map<string, any>;

  created_at: Date;
  updated_at: Date;
}

const defaultSubscriptionProps: ISubscriptionRecord = {
  id: 0,
  billing_customer_id: 0,
  billing_cycle_anchor: new Date(),
  billing_plan_id: 0,
  cancel_at_period_end: true,
  canceled_at: null,
  coupon: "",
  current_period_end: new Date(),
  current_period_start: new Date(),
  days_until_due: false,
  discount: Map<string, any>(),
  ended_at: null,
  prorate: true,
  proration_date: new Date(),
  quantity: 0,
  source: Map<string, any>(),
  start: new Date(),
  status: "",
  stripe_billing: "",
  stripe_created: new Date(),
  stripe_id: "",
  tax_percent: 0,
  trial_end: null,
  trial_start: null,
  metadata: Map<string, any>(),
  stripe_data: Map<string, any>(),
  created_at: new Date(),
  updated_at: new Date(),
};

export class SubscriptionRecord extends Record(defaultSubscriptionProps) implements ISubscriptionRecord {
  public readonly id!: number;
  public readonly billing_customer_id!: number;
  public readonly billing_cycle_anchor!: Date;
  public readonly billing_plan_id!: number;
  public readonly cancel_at_period_end!: boolean;
  public readonly canceled_at!: Date;
  public readonly coupon!: string;
  public readonly current_period_end!: Date;
  public readonly current_period_start!: Date;
  public readonly days_until_due!: boolean;
  public readonly discount!: Map<string, any>;
  public readonly ended_at!: Date;
  public readonly metadata!: Map<string, any>;
  public readonly prorate!: boolean;
  public readonly proration_date!: Date;
  public readonly quantity!: number;
  public readonly source!: Map<string, any>;
  public readonly start!: Date;
  public readonly status!: string;
  public readonly stripe_billing!: string;
  public readonly stripe_created!: Date;
  public readonly stripe_data!: Map<string, any>;
  public readonly stripe_id!: string;
  public readonly tax_percent!: number;
  public readonly trial_end!: Date;
  public readonly trial_start!: Date;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<ISubscriptionRecord>) {
    values ? super(values) : super();
  }
}
