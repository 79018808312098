import { IPriceList } from "app/src/Models/PriceList";

export class OrgPriceListPropEditorCtrl {
  public static $inject = ["$uibModalInstance", "price_list"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public price_list: IPriceList,
  ) {}

  public save() {
    this.$uibModalInstance.close(this.price_list);
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }
}
