import * as React from "react";
import { LocalVideoTrack, Participant, RemoteVideoTrack } from "twilio-video";
import usePublications from "app2/src/components/Twilio/hooks/usePublications/usePublications";
import useTrack from "app2/src/components/Twilio/hooks/useTrack/useTrack";
import useIsTrackSwitchedOff from "app2/src/components/Twilio/hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import BandwidthWarning from "app2/src/components/Twilio/components/BandwidthWarning";

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
  const publications = usePublications(participant);
  const videoPublication = publications.find((p) => p.trackName.includes("camera"));
  const screenSharePublication = publications.find((p) => p.trackName === "screen");
  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const identity = participant.identity.split(" -")[0];

  return (
    <div className="main-participant-info">
      <div className="info-container">
        <div className="info-row">
          <h4>{identity}</h4>
        </div>
      </div>
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
    </div>
  );
}
