import { subscriber } from "app2/src/helpers/Subscribe";
import { List } from "immutable";
import { FlashAlertRecord } from "app2/src/records/FlashAlert";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { dispatch } from "app2/src/storeRegistry";
import { FlashLevels } from "app/src/Common/FlashLevels";
export { FlashLevels } from "app/src/Common/FlashLevels";

export interface IFlash {
  alerts: (typeof FlashAlertRecord)[];
  addMessage(level: FlashLevels, message: string);
  closeAlert(index: number);
}

export class FlashService implements IFlash {
  public alerts: (typeof FlashAlertRecord)[] = [];
  private _flashSubscribe: () => void;

  public static $inject = [];
  constructor() {
    this._flashSubscribe = subscriber.subscribe<List<typeof FlashAlertRecord>>(
      "components.common.flashAlerts",
      (flashAlerts: List<typeof FlashAlertRecord>) => {
        this.alerts = flashAlerts.toArray();
      },
    );
  }

  public addMessage(level: FlashLevels, message: string) {
    dispatch(commonActions.Actions.flashAddAlert(level, message));
  }

  public closeAlert(index) {
    dispatch(commonActions.Actions.flashRemoveAlert(index));
  }
}
