import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import * as React from "react";
import { Button, ButtonProps } from "react-bootstrap";

export interface ButtonFooterProps {
  cancel: () => void;
  update?: () => void;
  destroy?: () => void;
  itemName?: string;
  saveButtonProps?: ButtonProps;
  deleteButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  saveText?: string;
}

export default class ButtonFooter extends React.Component<ButtonFooterProps> {
  constructor(props: ButtonFooterProps) {
    super(props);
  }

  public render() {
    const { update, cancel, destroy } = this.props;
    const { saveButtonProps, deleteButtonProps, cancelButtonProps } = this.props;
    let { itemName, saveText } = this.props;
    if (!itemName) itemName = "item";
    if (!saveText) saveText = "Save Changes";

    return (
      <>
        {destroy && (
          <ConfirmDialog title={`Are you sure you wish to delete this ${itemName}?`}>
            {(confirm) => (
              <Button variant="delete" onClick={confirm(() => destroy())} className="mr-auto" {...deleteButtonProps}>
                Delete
              </Button>
            )}
          </ConfirmDialog>
        )}

        <div className="button-footer">
          <Button variant="save" type="submit" onClick={update} {...saveButtonProps}>
            {saveText}
          </Button>
          <Button variant="cancel" onClick={cancel} {...cancelButtonProps}>
            Cancel
          </Button>
        </div>
      </>
    );
  }
}
