import * as React from "react";
import { DateFormat, CurrencyFormat } from "app2/src/helpers/Format";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PaymentRecord } from "app2/src/records/PaymentRecord";

export interface ICardConnectListViewProps {
  idx: number;
  payment: PaymentRecord;
}

export default class CardConnectListView extends React.PureComponent<ICardConnectListViewProps> {
  public render() {
    const { idx, payment } = this.props;
    return (
      <tr key={idx}>
        <td>{DateFormat(payment.created_at)}</td>
        <td>
          <OverlayTrigger overlay={<Tooltip id={payment.id.toString()}>Clover Connect</Tooltip>}>
            <span>{payment.payload.get("authcode") || payment.payload.getIn(["FailureData", "Description"])}</span>
          </OverlayTrigger>
        </td>
        <td>{CurrencyFormat(payment.amount)}</td>
        <td>{payment.charge_type === "credit_card" ? "Credit Card" : "ACH"}</td>
        <td>{payment.status}</td>
      </tr>
    );
  }
}
