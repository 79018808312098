import { IEstimateGroupData, EstimateGroupRecord } from "../records/EstimateGroup";
import { createSelector } from "reselect";
import { List } from "immutable";
import { RootState } from "../reducers";
import { estimateLineItems, toEstimateLineItemsJson } from "./estimateLineItem.selectors";
import { IBaseTotals } from "app2/src/records/Estimate";
import { EstimateLineItemRecord } from "app2/src/records/EstimateLineItem";
import { calculateLineItemSum, estimate } from "app2/src/selectors/estimate.selectors";
import { selectedEstimateId } from "./job.selectors";

export const estimateGroupsById = (state: RootState) => state.getIn(["estimateGroups", "byId"]);
export const lastSavedEstimateGroupsById = (state: RootState) => state.getIn(["estimateGroups", "lastSavedById"]);
export const estimateGroup = (state: RootState, props: any) =>
  state.getIn(["estimateGroups", "byId", props.estimateGroupId]);

export const lastSavedEstimateGroups = createSelector(
  [lastSavedEstimateGroupsById, (_: RootState, props: any) => props],
  (estimateGroupsById: Map<number, EstimateGroupRecord>, props: any): List<EstimateGroupRecord> => {
    return estimateGroupsProcess(estimateGroupsById, props);
  },
);

export const estimateGroups = createSelector(
  [estimateGroupsById, (_: RootState, props: any) => props],
  (estimateGroupsById: Map<number, EstimateGroupRecord>, props: any): List<EstimateGroupRecord> => {
    return estimateGroupsProcess(estimateGroupsById, props);
  },
);

export const estimateGroupsProcess = (estimateGroupsById, props: any) => {
  const { estimateGroupIds } = props;
  let { included, fullEstimate } = props;
  if (_.isUndefined(included)) {
    included = true;
  }
  if (_.isUndefined(fullEstimate)) {
    fullEstimate = false;
  }

  return estimateGroupIds
    .map((gid: number) => {
      const group = estimateGroupsById.get(gid) as EstimateGroupRecord;
      return group;
    })
    .filter((group: EstimateGroupRecord) => {
      return fullEstimate || (!group._destroy && group.included === included);
    })
    .toList();
};

export const lineItemsEstimateGroup = createSelector(
  [estimateGroup, (state, props) => ({ state, props })],
  (estimateGroup: EstimateGroupRecord, stateProps: any) => {
    const { state, props } = stateProps;
    const estimateLineItemIds = estimateGroup.line_item_ids;
    return estimateLineItems(state, { ...props, estimateLineItemIds });
  },
);

export const lineItemTotals = createSelector(
  [lineItemsEstimateGroup, (state, props) => ({ state, props })],
  (lineItemsEstimateGroup: List<EstimateLineItemRecord>, stateProps: any): IBaseTotals => {
    return calculateLineItemSum(stateProps, lineItemsEstimateGroup);
  },
);

export const updateBaseTotal = createSelector(
  [estimateGroup, lineItemTotals],
  (estimateGroup: EstimateGroupRecord, lineItemTotals: IBaseTotals): EstimateGroupRecord => {
    delete lineItemTotals.discountable_total;
    lineItemTotals.total = lineItemTotals.subtotal;
    return estimateGroup.merge(lineItemTotals);
  },
);

export const toEstimateGroupsJson = createSelector(
  [estimateGroups, (state, props) => ({ state, props })],
  (estimateGroups: List<EstimateGroupRecord>, stateProps: any): IEstimateGroupData[] => {
    const { state, props } = stateProps;
    return estimateGroups
      .map((group: EstimateGroupRecord) => {
        const groupData = group.toJSON() as any;

        groupData.line_items = toEstimateLineItemsJson(state, {
          ...props,
          estimateLineItemIds: groupData.line_item_ids,
        });

        groupData.room_estimations = group.room_estimations.toJS();

        delete groupData.product_total;
        delete groupData.labor_total;
        delete groupData.line_item_ids;
        if (!groupData._destroy) {
          delete groupData._destroy;
        }

        return groupData as IEstimateGroupData;
      })
      .toArray();
  },
);

/**
 * Gets the selected estimate's groups
 * Pass in the `includeAll` prop to get all groups, not just the included ones.
 * @param {RootState} state The RootState
 * @param {{ includeAll?: boolean }} props The props
 */
export const currentEstimateGroups = createSelector(
  [selectedEstimateId, (state, props) => ({ state, props })],
  (estimateId: number, { state, props }) => {
    const estimateGroupIds = estimate(state, { estimateId })?.group_ids;
    if (!estimateGroupIds) return List();

    return props.includeAll
      ? List([
          ...estimateGroups(state, { estimateGroupIds, included: true }),
          ...estimateGroups(state, { estimateGroupIds, included: false }),
        ])
      : estimateGroups(state, { estimateGroupIds });
  },
);
