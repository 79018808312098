import * as React from "react";
import { Row, Col, InputGroup, Button, FormControl, ButtonGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import DateTime from "app2/src/components/Common/DateTime";
import { estimateTemplate } from "app2/src/selectors/estimateTemplate.selectors";
import { EstimateTemplateRecord } from "app2/src/records/EstimateTemplate";
import * as estimateTemplateActions from "app2/src/reducers/estimateTemplate.actions";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { List } from "immutable";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import track from "react-tracking";
import * as FontAwesome from "react-fontawesome";
import { Can } from "app2/src/components/Common/CanComponent";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { estimateTemplateChanged } from "app2/src/selectors/estimateTemplate.selectors";
import { IEstimateTemplate } from "app/src/Models/EstimateTemplate";
import { CamelToSnakeKeys } from "app2/src/helpers/Format";

const mapStateToProps = (state: RootState, ownProps: EstimateTemplateProps) => {
  return {
    estimateTemplate: estimateTemplate(state, { estimateTemplateId: ownProps.estimateTemplateId }),
    estimateTemplateChanged: estimateTemplateChanged(state, { estimateTemplateId: ownProps.estimateTemplateId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: EstimateTemplateProps) => {
  return {
    destroyEstimateTemplate: (estimateTemplate: EstimateTemplateRecord) =>
      dispatch(estimateTemplateActions.AsyncActions.destroyEstimateTemplate(estimateTemplate)),
    editSelected: (value: boolean) =>
      dispatch(estimateTemplateActions.Actions.editSelected(ownProps.estimateTemplateId, value)),
    updateEstimateTemplate: () =>
      dispatch(estimateTemplateActions.AsyncActions.batchUpdateEstimateTemplate(List([ownProps.estimateTemplateId]))),
    editName: (estimateTemplateId: number, name: string) =>
      dispatch(estimateTemplateActions.Actions.editName(estimateTemplateId, name)),
    resetEstimateTemplate: () =>
      dispatch(estimateTemplateActions.Actions.resetEstimateTemplate(ownProps.estimateTemplateId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface EstimateTemplateState {
  editing: boolean;
}

interface EstimateTemplateProps {
  estimateTemplateId: number;
  readOnly: boolean;
  saveAs: (selection: string | IEstimateTemplate) => void;
  select: (selection: IEstimateTemplate) => void;
  dragHandle?: any;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & EstimateTemplateProps;
@track((props) => {
  return {
    component: "EstimateTemplate",
    estimateTemplate: props.estimateTemplateId,
  };
})
class EstimateTemplate extends React.Component<Props, EstimateTemplateState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
    this.destroyEstimateTemplate = this.destroyEstimateTemplate.bind(this);
    this.edit = this.edit.bind(this);
    this.check = this.check.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.save = this.save.bind(this);
    this.saveAs = this.saveAs.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
  }

  @track((_props, _state, [event]) => ({
    action: "checkBoxChanged",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
  }))
  public checkBoxOnChange(event: any): void {
    const { editSelected } = this.props;
    editSelected(event.target.checked);
  }

  @track(() => ({
    action: "edit org estimateTemplate name",
  }))
  public edit(): void {
    this.setState({ editing: true });
  }

  public handleNameChange(event): void {
    const { estimateTemplate, editName } = this.props;
    editName(estimateTemplate.id, event.target.value);
  }

  @track((props) => ({
    action: "save org estimateTemplate name",
    doc: props.estimateTemplate.name,
  }))
  public save(): void {
    const { updateEstimateTemplate } = this.props;
    this.setState({ editing: false });
    updateEstimateTemplate();
  }

  @track((props) => ({
    action: "save over estimateTemplate",
    doc: props.estimateTemplate.name,
  }))
  public saveAs(): void {
    const { saveAs, estimateTemplate } = this.props;
    saveAs(CamelToSnakeKeys(estimateTemplate.toJS()));
  }

  public handleKeyPress(target): void {
    if (target.charCode === 13) {
      this.save();
    }
  }

  @track(() => ({
    action: "estimateTemplate deleted",
  }))
  public async destroyEstimateTemplate() {
    const { estimateTemplate, destroyEstimateTemplate } = this.props;
    await destroyEstimateTemplate(estimateTemplate);
  }

  @track(() => ({
    action: "estimateTemplate selected",
  }))
  public selectTemplate() {
    const { select, estimateTemplate } = this.props;
    select(CamelToSnakeKeys(estimateTemplate.toJS()));
  }

  public check(): boolean {
    const { estimateTemplateChanged } = this.props;

    return estimateTemplateChanged;
  }

  public render() {
    const { readOnly, estimateTemplate, dragHandle, resetEstimateTemplate } = this.props;
    const { editing } = this.state;

    if (_.isNullOrUndefined(estimateTemplate)) {
      return null;
    }

    return (
      <>
        <SpinnerComponent localProperty={estimateTemplate.loading} />
        <Row
          className="table-row"
          data-testid="EstimateTemplate"
          onClick={readOnly ? this.selectTemplate : null}
          style={{ cursor: readOnly ? "pointer" : "default" }}>
          <Col sm={2}>
            {!readOnly && (
              <Can resource="estimate_template" permission="update">
                <IncludeCheckBox name="selected" checked={estimateTemplate.selected} onChange={this.checkBoxOnChange} />
                {dragHandle && <img {...dragHandle} src="/assets/images/icons/ic_sort.ea6f8933.png" />}
              </Can>
            )}
          </Col>
          <Col className="break-word">
            {readOnly ? (
              <div className="d-flex align-items-center">
                {estimateTemplate.name}
                {estimateTemplate.kind === "interior" && (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={
                      <Popover id={"interior-popover-" + estimateTemplate.id}>
                        <Popover.Content>Interior measurement template</Popover.Content>
                      </Popover>
                    }>
                    <img
                      style={{ width: "22px" }}
                      className="ml-1 mb-1"
                      src="/assets/images/icons/interior-measurement.de7e8aee.png"
                      alt="(Interior Template)"
                    />
                  </OverlayTrigger>
                )}
              </div>
            ) : (
              <>
                {editing ? (
                  <InputGroup>
                    <DirtyWatcher check={this.check} reset={resetEstimateTemplate} />
                    <FormControl
                      autoFocus
                      data-testid="name-editing"
                      placeholder="EstimateTemplate Name"
                      name="name"
                      value={estimateTemplate.name}
                      onChange={this.handleNameChange}
                      onKeyPress={this.handleKeyPress}
                    />
                    <InputGroup.Append>
                      <Button onClick={this.save} data-testid="save-button">
                        <FontAwesome name="save" />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                ) : (
                  <div className="d-flex align-items-center">
                    <Can resource="estimate_template" permission="update">
                      <a className="action-button" href="#" title="Edit Name" onClick={this.edit}>
                        <i className="rsf-edit-link rsf-base-66"></i>
                      </a>
                    </Can>
                    {estimateTemplate.name}
                    {estimateTemplate.kind === "interior" && (
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="right"
                        overlay={
                          <Popover id={"interior-popover-" + estimateTemplate.id}>
                            <Popover.Title>Interior Measurement</Popover.Title>
                            <Popover.Content>This is an interior measurement template.</Popover.Content>
                          </Popover>
                        }>
                        <img
                          style={{ width: "22px" }}
                          className="ml-1 mb-1"
                          src="/assets/images/icons/interior-measurement.de7e8aee.png"
                          alt="(Interior Template)"
                        />
                      </OverlayTrigger>
                    )}
                  </div>
                )}
              </>
            )}
          </Col>
          <Col sm={3}>
            <DateTime date={estimateTemplate.updatedAt} variant="vertical" />
          </Col>
          {!readOnly && (
            <Col sm={2}>
              <ButtonGroup>
                <Can resource="estimate_template" permission="update">
                  <a className="action-button" data-testid="save" title="Save Estimate Template" onClick={this.saveAs}>
                    <i className="rsf-save-link rsf-base-66"></i>
                  </a>
                  <ConfirmDialog title={`Are you sure you want to delete estimate template: ${estimateTemplate.name}?`}>
                    {(confirm) => (
                      <a
                        className="action-button"
                        data-testid="destroy"
                        title="Delete Estimate Template"
                        onClick={confirm(this.destroyEstimateTemplate)}>
                        <i className="rsf-delete-link rsf-base-66"></i>
                      </a>
                    )}
                  </ConfirmDialog>
                </Can>
              </ButtonGroup>
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default connector(EstimateTemplate);
