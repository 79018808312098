import * as React from "react";
import { Button } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import track from "react-tracking";
import { push } from "connected-react-router/immutable";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import { crmUserCheck, currentUser } from "app2/src/selectors/user.selectors";
import { AsyncActions } from "app2/src/reducers/user.actions";

const mapStateToProps = (state: RootState) => {
  const orgId = currentUser(state).get("org_id");
  return {
    currentJobId: currentJobId(state),
    fnMktAuthorized: getAuthorization(state, { orgId, integration: "fin_mkt" }),
    fmUserCheck: crmUserCheck(state, { crmUserType: "FmUser" }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    push: (path: string) => dispatch(push(path)),
    crmUserCheck: () => dispatch(AsyncActions.crmUserCheck("FmUser")),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface FinanceOptionsButtonState {
  editing: boolean;
}

interface FinanceOptionsButtonProps {
  estimateId: number | string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FinanceOptionsButtonProps;

@track((props) => {
  return {
    component: "FinanceOptionsButton",
    estimate: props.estimateId,
  };
})
class FinanceOptionsButton extends React.Component<Props, FinanceOptionsButtonState> {
  constructor(props: Props) {
    super(props);

    this.navigate = this.navigate.bind(this);
  }

  public componentDidMount(): void {
    this.props.crmUserCheck();
  }

  public navigate(): void {
    const { currentJobId, estimateId, push } = this.props;
    push(`/jobs/${currentJobId}/estimates/${estimateId}/homevest`);
  }

  public render(): React.ReactNode {
    const { fmUserCheck, estimateId, fnMktAuthorized } = this.props;

    if (!fmUserCheck || !fnMktAuthorized || _.isNullOrUndefined(estimateId) || estimateId === "new") {
      return null;
    }

    return <Button onClick={this.navigate}>Finance Options</Button>;
  }
}

export default connector(FinanceOptionsButton);
