import * as React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  FinanceOptionProviders,
  DisabledCreationProviders,
  FinanceOptionRecord,
  FinanceOptionStatuses,
  formatStatus,
} from "app2/src/records/FinanceOption";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";
import { us_states } from "app/src/Common/StatesConstant";

export interface EditorProps {
  financeOption: FinanceOptionRecord;
  update: (financeOption: FinanceOptionRecord) => void;
  cancel: () => void;
}

export interface EditorState {
  financeOption: FinanceOptionRecord;
  formula: boolean;
  editingDisabled: boolean;
}

export class Editor extends React.Component<EditorProps, EditorState> {
  constructor(props: EditorProps) {
    super(props);

    this.state = {
      financeOption: props.financeOption,
      formula: props.financeOption.formula !== "",
      editingDisabled: DisabledCreationProviders.includes(props.financeOption.provider),
    };

    this.handleFinanceOptionInput = this.handleFinanceOptionInput.bind(this);
    this.canSave = this.canSave.bind(this);
    this.toggleType = this.toggleType.bind(this);
  }

  public canSave(): boolean {
    return !_.isEqual(this.props.financeOption, this.state.financeOption) && this.state.financeOption.name.length > 0;
  }

  public handleFinanceOptionInput(e: React.FormEvent) {
    const target = e.target as any;
    const value = target.value;

    const name = target.name.split(".");

    let parsedValue: any;
    switch (_.last(name)) {
      case "rate":
        parsedValue = parseFloat((Math.round(parseFloat(value) * 100) / 100).toFixed(2));
        parsedValue = this.removeNegative(parsedValue);
        if (parsedValue > 999) {
          parsedValue = 999;
        }
        break;
      case "term":
        parsedValue = parseFloat(value).toFixed(0);
        parsedValue = this.removeNegative(parsedValue);
        if (parsedValue > 999) {
          parsedValue = 999;
        }
        break;
      case "active":
        // @ts-ignore
        parsedValue = e.target.checked;
        break;
      default:
        parsedValue = value;
        break;
    }

    this.setState((prevState) => {
      return {
        financeOption: prevState.financeOption.setIn(name, parsedValue),
      };
    });
  }

  public removeNegative(value) {
    if (Number.isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }
    return value;
  }

  public toggleType() {
    this.setState((prevState) => {
      return {
        formula: !prevState.formula,
      };
    });
  }

  public render() {
    const { financeOption, formula, editingDisabled } = this.state;
    const { cancel, update } = this.props;
    const formulaTitle =
      "Formula variables available from the estimate (subtotal, total, product_total, taxable_amount, labor_total, financed_amount)";
    const fo_provider_to_skip = ["fin_mkt", "fin_mkt_offer"];
    return (
      <div className="form-section">
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                placeholder={"Name"}
                disabled={editingDisabled}
                value={financeOption.name}
                onChange={this.handleFinanceOptionInput}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={financeOption.status}
                onChange={this.handleFinanceOptionInput}
                required>
                {FinanceOptionStatuses.map((status, idx) => {
                  return (
                    <option key={idx} value={status}>
                      {formatStatus(status)}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Apply Now URL</Form.Label>
              <Form.Control
                name="apply_now_url"
                type="text"
                value={financeOption.apply_now_url || ""}
                disabled={editingDisabled}
                onChange={this.handleFinanceOptionInput}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Provider</Form.Label>
              <Form.Control
                as="select"
                name="provider"
                value={financeOption.provider}
                onChange={this.handleFinanceOptionInput}
                disabled={editingDisabled}
                required>
                {FinanceOptionProviders.filter((provider) => !fo_provider_to_skip.includes(provider)).map(
                  (provider, idx) => {
                    if (!editingDisabled && DisabledCreationProviders.includes(provider)) {
                      return null;
                    }

                    return (
                      <option key={idx} data-testid={provider} value={provider}>
                        {_.toTitleCase(provider)}
                      </option>
                    );
                  },
                )}
              </Form.Control>
            </Form.Group>
            {financeOption.provider === "green_sky" && (
              <Form.Group>
                <Form.Label>Integrations Identifier</Form.Label>
                <Form.Control
                  name="integrations_identifier"
                  type="text"
                  value={financeOption.integrations_identifier}
                  onChange={this.handleFinanceOptionInput}
                />
              </Form.Group>
            )}
          </Col>
          <Col md={3}>
            <Row>
              <Col md={12} className="text-center">
                <Button data-testid="btn-rateterm" onClick={this.toggleType} disabled={editingDisabled}>
                  {formula ? <span title={formulaTitle}>Formula</span> : <span>Rate & Term</span>}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {formula ? (
                  <Form.Group>
                    <Form.Label title={formulaTitle}>Formula</Form.Label>
                    <Form.Control
                      id="formula"
                      title={formulaTitle}
                      name="formula"
                      type="text"
                      placeholder="Formula"
                      value={financeOption.formula}
                      disabled={editingDisabled}
                      onChange={this.handleFinanceOptionInput}
                    />
                  </Form.Group>
                ) : (
                  <>
                    <Form.Group>
                      <Form.Label>Rate</Form.Label>
                      <Form.Control
                        id="rate"
                        name="rate"
                        type="number"
                        min="0"
                        max="9999999"
                        step="0.01"
                        placeholder="Rate"
                        value={financeOption.rate.toString()}
                        disabled={editingDisabled}
                        onChange={this.handleFinanceOptionInput}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Term (months)</Form.Label>
                      <Form.Control
                        id="term"
                        name="term"
                        type="number"
                        min="0"
                        max="9999999"
                        step="1"
                        placeholder="Term"
                        value={financeOption.term.toString()}
                        disabled={editingDisabled}
                        onChange={this.handleFinanceOptionInput}
                      />
                    </Form.Group>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Check
                    name="validation.active"
                    type="checkbox"
                    inline
                    label="Active Filter"
                    placeholder="Active Filter"
                    checked={financeOption.validation.active}
                    disabled={editingDisabled}
                    onChange={this.handleFinanceOptionInput}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Min Financed Amount</Form.Label>
                  <Form.Control
                    name="validation.min_amount"
                    type="number"
                    min="0"
                    max="9999999"
                    step="1"
                    placeholder="Minimum Financed Amount"
                    disabled={!financeOption.validation.active || editingDisabled}
                    value={financeOption.validation.min_amount.toString()}
                    onChange={this.handleFinanceOptionInput}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Max Financed Amount</Form.Label>
                  <Form.Control
                    name="validation.max_amount"
                    type="number"
                    min="0"
                    max="9999999"
                    step="1"
                    placeholder="Maximum Financed Amount"
                    disabled={!financeOption.validation.active || editingDisabled}
                    value={financeOption.validation.max_amount.toString()}
                    onChange={this.handleFinanceOptionInput}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Min Payment</Form.Label>
                  <Form.Control
                    name="validation.min_payment"
                    type="number"
                    min="0"
                    max="9999999"
                    step="1"
                    placeholder="Minimum Payment Amount"
                    disabled={!financeOption.validation.active || editingDisabled}
                    value={financeOption.validation.min_payment.toString()}
                    onChange={this.handleFinanceOptionInput}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    name="validation.state"
                    placeholder="Required Address State"
                    onChange={this.handleFinanceOptionInput}
                    disabled={!financeOption.validation.active || editingDisabled}
                    value={financeOption.validation.state}>
                    <option value={""} key="none">
                      {""}
                    </option>
                    {us_states.map((state) => (
                      <option value={state} key={state}>
                        {state}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md={3}></Col>
        </Row>
        <ButtonFooter update={() => update(financeOption)} cancel={cancel} />
      </div>
    );
  }
}
