import * as Handlebars from "handlebars";

export class HandlebarsHelperService {
  public static $inject = ["$filter"];

  constructor(public $filter: ng.IFilterService) {
    Handlebars.registerHelper("ifCond", this.ifCond);
    Handlebars.registerHelper("formatCurrency", this.formatCurrency);
    Handlebars.registerHelper("formatDate", this.formatDate);
    Handlebars.registerHelper("ifTrue", this.ifTrue);
    Handlebars.registerHelper("moneyOperation", this.moneyOperation);
    Handlebars.registerHelper("round", this.round);
    Handlebars.registerHelper("addDays", this.addDays);
    Handlebars.registerHelper("mathOperation", this.mathOperation);
  }

  public compile(value: any, locals: any) {
    return Handlebars.compile(value)(locals);
  }

  public formatCurrency = (value: any) => {
    if (value === undefined || value === null) {
      return "$0.00";
    }
    value = this.$filter("currency")(value, "$", 2);
    return value.toString();
  };

  public addDays = (value: any, days: any) => {
    if (value === undefined || value === null) {
      return "";
    }

    let d = new Date(value);
    d.setDate(d.getDate() + days);

    d = this.$filter("date")(d, "MM/dd/yyyy");
    return d.toString();
  };

  public formatDate = (value: any) => {
    if (value === undefined || value === null) {
      return "";
    }
    value = this.$filter("date")(value, "MM/dd/yyyy");
    return value.toString();
  };

  public ifTrue = function (value: any, options: any) {
    if (value === "true" || value === true) {
      return options.fn(this);
    }
    return options.inverse(this);
  };

  public round = (value: any, operator: any, precision: any) => {
    if (
      value === undefined ||
      value === null ||
      operator === undefined ||
      operator === null ||
      precision === undefined ||
      precision === null
    ) {
      return "0";
    }

    let new_value: any = 0;
    const precisionValue = Math.pow(10, precision);

    switch (operator) {
      case "ceil":
        new_value = Math.ceil(value * precisionValue) / precisionValue;
        break;
      case "floor":
        new_value = Math.floor(value * precisionValue) / precisionValue;
        break;
      case "normal":
        new_value = Math.round(value * precisionValue) / precisionValue;
        break;
    }

    return new_value.toString();
  };

  public moneyOperation = (v1: any, operator: any, v2: any) => {
    if (v1 === undefined || v1 === null || v2 === undefined || v2 === null) {
      return "$0.00";
    }

    let value: any = 0;
    switch (operator) {
      case "+":
        value = v1 + v2;
        break;
      case "*":
        value = v1 * v2;
        break;
      case "-":
        value = v1 - v2;
        break;
      case "/":
        value = v1 / v2;
        break;
    }

    value = this.$filter("currency")(value, "$", 2);
    return value.toString();
  };

  public mathOperation = (v1: any, operator: any, v2: any) => {
    if (v1 === undefined || v1 === null || v2 === undefined || v2 === null) {
      return 0.0;
    }

    let value: any = 0;
    switch (operator) {
      case "+":
        value = v1 + v2;
        break;
      case "*":
        value = v1 * v2;
        break;
      case "-":
        value = v1 - v2;
        break;
      case "/":
        value = v1 / v2;
        break;
    }

    return value;
  };

  public ifCond = function (v1: any, operator: any, v2: any, options: any) {
    switch (operator) {
      case "==":
        return v1 === v2 ? options.fn(this) : options.inverse(this);
      case "===":
        return v1 === v2 ? options.fn(this) : options.inverse(this);
      case "!=":
        return v1 !== v2 ? options.fn(this) : options.inverse(this);
      case "!==":
        return v1 !== v2 ? options.fn(this) : options.inverse(this);
      case "<":
        return v1 < v2 ? options.fn(this) : options.inverse(this);
      case "<=":
        return v1 <= v2 ? options.fn(this) : options.inverse(this);
      case ">":
        return v1 > v2 ? options.fn(this) : options.inverse(this);
      case ">=":
        return v1 >= v2 ? options.fn(this) : options.inverse(this);
      case "&&":
        return v1 && v2 ? options.fn(this) : options.inverse(this);
      case "||":
        return v1 || v2 ? options.fn(this) : options.inverse(this);
      default:
        return options.inverse(this);
    }
  };
}
