import * as React from "react";
import { Button } from "react-bootstrap";

export interface UsageButtonsProps {
  value: number;
  uom: string;
  handleClick: (value: number, action: string, uom: string) => void;
}

export const UsageButtons: React.FunctionComponent<UsageButtonsProps> = (props: UsageButtonsProps) => {
  const { value, uom, handleClick } = props;

  return (
    <>
      <Button
        data-testid="minus"
        className="opening-ui-minus"
        size="sm"
        onClick={() => {
          handleClick(value, "-", uom);
        }}>
        -
      </Button>
      <Button
        data-testid="use"
        className="opening-ui-use"
        size="sm"
        onClick={() => {
          handleClick(value, "use", uom);
        }}>
        Use
      </Button>
      <Button
        data-testid="plus"
        className="opening-ui-plus"
        size="sm"
        onClick={() => {
          handleClick(value, "+", uom);
        }}>
        +
      </Button>
    </>
  );
};
