import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import * as priceListProcessActions from "app2/src/reducers/priceListProcess.actions";
import { OrgPriceListHeaderCtrl } from "app/src/Orgs/tabs/OrgPriceListHeaderCtrl";
import { PriceListProcessRecords } from "app2/src/records/PriceListProcess";
import Spinner from "app2/src/components/SpinnerComponent";
import ProcessModal from "app2/src/components/Process/ProcessModal";
import { IPriceList } from "app/src/Models/PriceList";
import { priceListProcesses } from "app2/src/selectors/priceListProcess.selectors";

const mapStateToProps = (state: RootState, ownProps: PriceListProcessSelectorProps) => {
  return {
    processes: priceListProcesses(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: PriceListProcessSelectorProps,
) => {
  return {
    loadProcesses: () => dispatch(priceListProcessActions.AsyncActions.getProcesses()),
    process: (price_list_id: number, params: any) =>
      dispatch(priceListProcessActions.AsyncActions.processPriceList(price_list_id, params)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PriceListProcessSelectorProps {
  priceList: IPriceList;
  controller: OrgPriceListHeaderCtrl;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PriceListProcessSelectorProps;

export class PriceListProcessSelector extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.process = this.process.bind(this);
  }

  public componentDidMount() {
    this.props.loadProcesses();
  }

  public onClose() {
    this.props.controller.closeProcess();
  }

  public process(id: number, params: any) {
    this.props.process(id, params);
  }

  public render() {
    const { processes, priceList } = this.props;

    if (processes.loading) {
      return <Spinner localProperty={processes.loading} />;
    }

    return <ProcessModal processes={processes} object={priceList} onClose={this.onClose} process={this.process} />;
  }
}

export default connector(PriceListProcessSelector);
