import * as React from "react";
import { useAudioOutputDevices } from "../hooks/deviceHooks";
import { Form, Col, Row } from "react-bootstrap";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import { useTracking } from "react-tracking";

export default function AudioOutputList() {
  const audioOutputDevices = useAudioOutputDevices();
  const { activeSinkId, setActiveSinkId } = useVideoContext();
  const { trackEvent } = useTracking();
  const device = audioOutputDevices.find((device) => device.deviceId === activeSinkId);
  let activeOutputLabel = null;
  if (device) {
    activeOutputLabel = device.label;
  }

  const changeOutput = (e: any) => {
    trackEvent({ action: "changeAudioOutput", output: e.target.value });
    setActiveSinkId(e.target.value as string);
  };

  return (
    <Row>
      <Col>
        <Form.Label>Audio Output</Form.Label>
        {audioOutputDevices.length > 1 ? (
          // @ts-ignore
          <Form.Control as="select" onChange={changeOutput} value={activeSinkId}>
            {audioOutputDevices.map((device) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </option>
            ))}
          </Form.Control>
        ) : (
          <p>{activeOutputLabel || "System Default Audio Output"}</p>
        )}
      </Col>
    </Row>
  );
}
