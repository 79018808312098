export class DecimalPlaces implements ng.IDirective {
  public restrict = "A";

  constructor() {}

  public link = (scope: ng.IScope, ele: ng.IAugmentedJQuery, attrs) => {
    ele.bind("keypress", function (e) {
      let decimals = "[0-9]";
      for (let i = 1; i < parseInt(attrs.decimalPlaces); i++) {
        decimals = decimals + decimals;
      }
      const expression = new RegExp("(.*)\\." + decimals);
      const newVal = $(this).val() + (e.charCode !== 0 ? String.fromCharCode(e.charCode) : "");
      if (attrs.decimalPlaces === "0") {
        if (e.charCode === 46) {
          e.preventDefault();
        }
      } else if (($(this).val() as any).search(expression) === 0 && newVal.length > ($(this).val() as any).length) {
        e.preventDefault();
      }
    });
  };

  public static factory(): ng.IDirectiveFactory {
    const directive = () => new DecimalPlaces();
    return directive;
  }
}
