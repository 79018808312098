import * as React from "react";
import ToolEditorModal from "./ToolEditorModal";
import { fromJSON, ToolRecord } from "app2/src/records/Tool";
import _track, { Track } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";
import { Button } from "react-bootstrap";

export interface ToolAddProps {
  name: string;
  rowType: string;
  orgId: number;
  toolAdded: (tool_id: number) => void;
}

interface ToolAddState {
  editing: boolean;
  tool: ToolRecord;
}

const track: Track<TrackingData, ToolAddProps> = _track;

@track(
  (props: ToolAddProps) => {
    return {
      category: "ToolAdd",
      action: "show",
      org: props.orgId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
export default class ToolAdd extends React.Component<ToolAddProps, ToolAddState> {
  constructor(props: ToolAddProps) {
    super(props);

    this.state = {
      editing: false,
      tool: null,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.addTool = this.addTool.bind(this);
  }

  public addTool() {
    const { orgId, rowType } = this.props;
    this.setState({
      editing: true,
      tool: fromJSON({ name: "", link: "", kind: rowType, org_id: orgId, brand_image: {}, loading: false }),
    });
  }

  public onCloseModal(id: number) {
    const { toolAdded } = this.props;

    this.setState({
      editing: false,
    });
    if (toolAdded && id > 0) {
      toolAdded(id);
    }
  }

  public render() {
    const { name, rowType } = this.props;
    const { editing, tool } = this.state;

    if (rowType === "string") {
      return null;
    }

    return [
      <ToolEditorModal key={rowType} show={editing} tool={tool} onClose={this.onCloseModal}></ToolEditorModal>,
      <Button variant="add" type="button" className="pull-right" key={name} onClick={this.addTool}>
        <i className="fa fa-plus"></i>&nbsp;Add {name}
      </Button>,
    ];
  }
}
