import * as React from "react";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { Modal, Button } from "react-bootstrap";
import ListComponent from "app2/src/components/AngularHelper/List";
import { token, tokenChanged } from "app2/src/selectors/token.selectors";
import { TokenRecord } from "app2/src/records/Token";
import * as tokenActions from "app2/src/reducers/token.actions";
import Spinner from "app2/src/components/SpinnerComponent";
import { getOrgEventResults } from "app2/src/selectors/eventResult.selectors";
import * as eventResultActions from "app2/src/reducers/eventResult.actions";

const mapStateToProps = (state: RootState, ownProps: SalesRabbitModalProps) => {
  return {
    statuses: getOrgEventResults(state, ownProps).map((s) => s.description),
    srToken: token(state, { kind: "sales_rabbit" }),
    tokenChanged: tokenChanged(state, { kind: "sales_rabbit" }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: SalesRabbitModalProps) => {
  return {
    getToken: () => dispatch(tokenActions.AsyncActions.getToken(ownProps.orgId, "sales_rabbit")),
    updateToken: (token: TokenRecord) => dispatch(tokenActions.AsyncActions.updateCreateToken(token)),
    resetToken: () => dispatch(tokenActions.Actions.resetToken(ownProps.orgId, "sales_rabbit")),
    getEventResults: () => dispatch(eventResultActions.AsyncActions.getEventResults(ownProps.orgId)),
    editTokenData: (data: Map<string, any>) =>
      dispatch(tokenActions.Actions.editTokenData(ownProps.orgId, "sales_rabbit", data)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface SalesRabbitModalProps {
  orgId: number;
  triggerOpen: number;
}

export interface SalesRabbitModalState {
  open: boolean;
  openDirtyCheck: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = SalesRabbitModalProps & PropsFromRedux;

class SalesRabbitModal extends React.Component<Props, SalesRabbitModalState> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openDirtyCheck: false,
    };

    this.onHide = this.onHide.bind(this);
    this.updateStatuses = this.updateStatuses.bind(this);
    this.updateToken = this.updateToken.bind(this);
    this.hideDirtyCheck = this.hideDirtyCheck.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const { triggerOpen, getToken, getEventResults } = this.props;
    if (prevProps.triggerOpen !== triggerOpen) {
      this.setState({ open: true });
      getEventResults();
      getToken();
    }
  }

  public onHide() {
    const { tokenChanged } = this.props;
    if (tokenChanged) {
      this.setState({ openDirtyCheck: true });
    } else {
      this.setState({ open: false });
    }
  }

  public hideDirtyCheck(closeAll: boolean) {
    if (closeAll) {
      this.setState({ open: false, openDirtyCheck: false });
    } else {
      this.setState({ openDirtyCheck: false });
    }
  }

  public updateStatuses(statuses: string[]) {
    const { editTokenData, srToken } = this.props;
    const newToken = srToken.mergeIn(["data"], { filter_status: statuses });
    editTokenData(newToken.get("data"));
  }

  public updateToken() {
    this.props.updateToken(this.props.srToken);
    this.setState({ open: false });
  }

  public render() {
    const { orgId, statuses, srToken, tokenChanged } = this.props;
    const { open, openDirtyCheck } = this.state;

    if (!open) {
      return null;
    }
    if (_.isNullOrUndefined(srToken)) {
      return <Spinner localProperty={true} />;
    }

    return (
      <React.Fragment>
        <Modal title="sr-modal" show={open} onHide={this.onHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>SalesRabbit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListComponent
              caption="Synced Statuses"
              name=""
              list={srToken?.toJS()?.data?.filter_status}
              available={statuses.toArray()}
              rowType="string"
              orgId={orgId}
              updateList={this.updateStatuses}
              save={this.updateToken}
              saveDisabled={!tokenChanged}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="button-footer">
              <Button title="close" variant="cancel" onClick={this.onHide}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal title="check-modal" show={openDirtyCheck} onHide={() => {}}>
          <Modal.Header>There are unsaved changes that will be lost. Are you sure you want to continue?</Modal.Header>
          <Modal.Footer>
            <div className="button-footer">
              <Button variant="save" onClick={() => this.hideDirtyCheck(true)}>
                Ok
              </Button>
              <Button variant="cancel" onClick={() => this.hideDirtyCheck(false)}>
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connector(SalesRabbitModal);
