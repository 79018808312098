import * as React from "react";
import { List } from "immutable";
import { Row, Col } from "react-bootstrap";
import { DragTable } from "app2/src/components/Common/ListManager/components/DragTable";
import { DragDropContext, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { DraggableRowProps } from "app2/src/components/Common/ListManager/components/DragTable";
import _track, { Track } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";

export interface IdObject {
  id: number;
}

export type ListManagerOption = string | number | IdObject;

interface ListManagerProps {
  name: string;
  available: List<ListManagerOption>;
  list: List<ListManagerOption>;
  orgId: number;
  RenderRow: new (props: DraggableRowProps) => React.Component<DraggableRowProps>;
  update(options: List<any>): void;
}

interface ListManagerState {
  available: List<any>;
}

const track: Track<TrackingData, ListManagerProps> = _track;

@track(
  (props: ListManagerProps) => {
    return {
      category: `ListManager ${props.name}`,
      action: "show",
      org: props.orgId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: true,
  },
)
export class ListManager extends React.Component<ListManagerProps, ListManagerState> {
  constructor(props: ListManagerProps) {
    super(props);

    this.state = {
      available: this.filterAvailable(),
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
  }

  public componentDidUpdate(prevProps: ListManagerProps): void {
    if (prevProps.list !== this.props.list || prevProps.available !== this.props.available) {
      this.setState({
        available: this.filterAvailable(),
      });
    }
  }

  public onDragEnd(result: DropResult, _provided: ResponderProvided): void {
    const { destination, source } = result;
    const { draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Remove Option
    if (destination.droppableId === "available" && source.droppableId === "list") {
      const parsedDraggableId = this.parseToolDraggableId(draggableId);
      return this.removeOption(parsedDraggableId);
    }

    // Move Option
    if (destination.droppableId === "list" && source.droppableId === "list") {
      this.moveOption(result);
    }

    // Add & Move Option
    if (destination.droppableId === "list" && source.droppableId === "available") {
      this.addAndMoveOption(result);
    }
  }

  public filterAvailable(): List<ListManagerOption> {
    const { list, available } = this.props;

    return available.filter((o) => {
      const index = list.findIndex((s) => {
        if (typeof s !== typeof o) {
          return false;
        }
        if (typeof s === "string" || typeof s === "number") {
          return s === o;
        }
        return s.id === (o as IdObject).id;
      });
      return index < 0;
    });
  }

  // custom tools will be stored with their id instead of name
  // we have to convert to string when rendering ot make
  // beautiful-dnd happy. before we do any operation, convert back
  // to integer if need be.
  public parseToolDraggableId(draggableId: string): string | number {
    let result: string | number = draggableId;
    if (!Number.isNaN(parseInt(draggableId))) {
      result = parseInt(draggableId);
    }

    return result;
  }

  @track({ action: "moveOption" })
  public moveOption(result: DropResult): void {
    const { destination, source, draggableId } = result;
    const { list, available, update } = this.props;
    const parsedDraggableId = this.parseToolDraggableId(draggableId);

    let newList = list.splice(source.index, 1);

    if (available.indexOf(parsedDraggableId) >= 0) {
      newList = newList.splice(destination.index, 0, parsedDraggableId);
    } else {
      newList = newList.splice(
        destination.index,
        0,
        available.find((a) => typeof a !== "string" && typeof a !== "number" && a.id === parsedDraggableId),
      );
    }
    return update(newList);
  }

  @track({ action: "addAndMoveOption" })
  public addAndMoveOption(result: DropResult): void {
    const { destination, draggableId } = result;
    const { list, update, available } = this.props;
    const parsedDraggableId = this.parseToolDraggableId(draggableId);

    let newList: List<ListManagerOption>;

    if (available.indexOf(parsedDraggableId) >= 0) {
      newList = list.splice(destination.index, 0, parsedDraggableId);
    } else {
      newList = list.splice(
        destination.index,
        0,
        available.find((a) => typeof a !== "string" && typeof a !== "number" && a.id === parsedDraggableId),
      );
    }
    update(newList);
  }

  @track({ action: "addOption" })
  public addOption(option: ListManagerOption): void {
    const { list, update } = this.props;

    const listData = list.splice(list.count(), 0, option);
    update(listData);
  }

  @track({ action: "removeOption" })
  public removeOption(option: ListManagerOption): void {
    const { list, update } = this.props;

    const listData = list.filter((o) => {
      if (typeof option === "string" || typeof option === "number") {
        if (typeof o === "string" || typeof o === "number") {
          return o !== option;
        }

        return o.id !== option;
      } else if (option) {
        if (typeof o === "string" || typeof o === "number") {
          return true;
        }

        return o.id !== option.id;
      } else {
        return true;
      }
    });
    update(listData);
  }

  public render(): JSX.Element {
    const { name, list, RenderRow } = this.props;
    const { available } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Row>
          <Col md={6}>
            <DragTable
              name={name}
              id="list"
              options={list}
              addOption={this.addOption}
              removeOption={this.removeOption}
              RenderRow={RenderRow}
            />
          </Col>
          <Col md={6}>
            <DragTable
              name={name}
              id="available"
              options={available}
              addOption={this.addOption}
              removeOption={this.removeOption}
              RenderRow={RenderRow}
            />
          </Col>
        </Row>
      </DragDropContext>
    );
  }
}
