import * as React from "react";
import { Form } from "react-bootstrap";
import { Field, FieldProps } from "react-final-form";
import { BootstrapInputAdapter } from "./BootstrapInputAdapter";

export interface RsfFormGroupProps extends FieldProps<any, any> {
  /** The input element type. If undefined, "input" will be used */
  as?: "input" | "select" | "textarea" | undefined;
  /** If the input field should be disabled */
  disabled?: boolean;
  /** Text to display in the field label */
  label: string;
  /** HTML name of the form element. This should match the record's key name. */
  name: string;
  /** A custom onChange event handler to fire */
  onChange?: any;
  /** Placeholder text. If undefined, the label will be used */
  placeholder?: string;
  /** If the presence of a value should be validated by the UI */
  required?: boolean;
  /** The value to set on the input field */
  storevalue?: string;
  /** Optional input validator */
  validate?: any;
  className?: string;
}

export const RsfFormGroup: React.FunctionComponent<RsfFormGroupProps> = (props) => {
  const {
    as,
    children,
    disabled,
    label,
    name,
    onChange,
    placeholder,
    required,
    storevalue,
    validate,
    type,
    className,
    testId,
    id,
  } = props;
  const inputType = as || "input";
  const nameTestId = testId || name;
  const nameId = id || name;

  return (
    <Form.Group className={className}>
      {label === "" ? null : (
        <Form.Label htmlFor={name} className="p-copy">
          {label}
        </Form.Label>
      )}
      <Field
        as={inputType}
        component={BootstrapInputAdapter}
        disabled={disabled}
        name={name}
        onChange={onChange}
        parse={(val) => (val == null ? "" : val)}
        placeholder={placeholder || label}
        required={required}
        storevalue={storevalue}
        type={type || inputType}
        data-testid={nameTestId}
        aria-label={label === "" ? name : null}
        id={nameId}
        validate={validate}>
        {children}
      </Field>
    </Form.Group>
  );
};
