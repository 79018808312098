import * as React from "react";
import { Nullable } from "app2/src/records";
import { FencingRecord } from "app2/src/records/measurements/Fencing";
import { BaseMeasurementCard } from "./BaseMeasurementCard";
import { UsageButtons } from "./UsageButtons";

export interface FencingMeasurementCardProps {
  fencing: FencingRecord;
  handleClick: (value: number, action: string, uom: string) => void;
}

export class FencingMeasurementCard extends React.Component<FencingMeasurementCardProps> {
  public panels4: Nullable<number> = null;
  public panels6: Nullable<number> = null;
  public panels8: Nullable<number> = null;
  public segmentCount: Nullable<number> = null;

  public constructor(props) {
    super(props);

    this.panelsCount = this.panelsCount.bind(this);
  }

  public panelsCount(size: 4 | 6 | 8): number {
    const { fencing } = this.props;

    if (!this.segmentCount) {
      this.segmentCount = fencing.fences.reduce((sum, f) => f.segmentIds.size + sum, 0);
    }
    switch (size) {
      case 4:
        if (!this.panels4) {
          this.panels4 = fencing.linePosts4 + this.segmentCount;
        }

        return this.panels4;

      case 6:
        if (!this.panels6) {
          this.panels6 = fencing.linePosts6 + this.segmentCount;
        }

        return this.panels6;
      case 8:
        if (!this.panels8) {
          this.panels8 = fencing.linePosts8 + this.segmentCount;
        }

        return this.panels8;
    }
  }

  public render() {
    const { fencing, handleClick } = this.props;

    return (
      <BaseMeasurementCard name="Fencing">
        <>
          <tr>
            <td>Fencing Totals</td>
            <td data-testid="line-posts-4">4' Line Posts ({fencing.linePosts4})</td>
            <td data-testid="line-posts-4-btns">
              <UsageButtons value={fencing.linePosts4} uom={"ea"} handleClick={handleClick} />
            </td>
            <td data-testid="panels-4">4' Panels ({this.panelsCount(4)})</td>
            <td data-testid="panels-4-btns">
              <UsageButtons value={this.panelsCount(4)} uom={"ea"} handleClick={handleClick} />
            </td>
          </tr>
          <tr>
            <td>Fencing Totals</td>
            <td data-testid="line-posts-6">6' Line Posts ({fencing.linePosts6})</td>
            <td data-testid="line-posts-6-btns">
              <UsageButtons value={fencing.linePosts6} uom={"ea"} handleClick={handleClick} />
            </td>
            <td data-testid="panels-6">6' Panels ({this.panelsCount(6)})</td>
            <td data-testid="panels-6-btns">
              <UsageButtons value={this.panelsCount(6)} uom={"ea"} handleClick={handleClick} />
            </td>
          </tr>
          <tr>
            <td>Fencing Totals</td>
            <td data-testid="line-posts-8">8' Line Posts ({fencing.linePosts8})</td>
            <td data-testid="line-posts-8-btns">
              <UsageButtons value={fencing.linePosts8} uom={"ea"} handleClick={handleClick} />
            </td>
            <td data-testid="panels-8">8' Panels ({this.panelsCount(8)})</td>
            <td data-testid="panels-8-btns">
              <UsageButtons value={this.panelsCount(8)} uom={"ea"} handleClick={handleClick} />
            </td>
          </tr>
          <tr>
            <td>Fencing Totals</td>
            <td data-testid="terminal-posts">Terminal Posts ({fencing.terminalPosts + fencing.gateCount * 2})</td>
            <td data-testid="terminal-posts-btns">
              <UsageButtons
                value={fencing.terminalPosts + fencing.gateCount * 2}
                uom={"ea"}
                handleClick={handleClick}
              />
            </td>
            <td data-testid="corner-posts">Corner Posts ({fencing.cornerPosts})</td>
            <td data-testid="corner-posts-btns">
              <UsageButtons value={fencing.cornerPosts} uom={"ea"} handleClick={handleClick} />
            </td>
          </tr>
          <tr>
            <td>Fencing Totals</td>
            <td data-testid="gate-count">Gate Count ({fencing.gateCount})</td>
            <td data-testid="gate-count-btns">
              <UsageButtons value={fencing.gateCount} uom={"ea"} handleClick={handleClick} />
            </td>
            <td data-testid="gate-length">Gate Length ({fencing.gateLength}')</td>
            <td data-testid="gate-length-btns">
              <UsageButtons value={fencing.gateLength} uom={"ft"} handleClick={handleClick} />
            </td>
          </tr>
          <tr>
            <td>Fencing Totals</td>
            <td data-testid="net-length">Net Length ({fencing.netLength}')</td>
            <td data-testid="net-length-btns">
              <UsageButtons value={fencing.netLength} uom={"ft"} handleClick={handleClick} />
            </td>
            <td data-testid="total-length">Total Length ({fencing.totalLength}')</td>
            <td data-testid="total-length-btns">
              <UsageButtons value={fencing.totalLength} uom={"ft"} handleClick={handleClick} />
            </td>
          </tr>
          {fencing.fences.map((fence, idx) => (
            <React.Fragment key={idx}>
              <tr>
                <td>Fence {idx + 1}</td>
                <td data-testid="fence-line-posts-4">4' Line Posts ({fence.linePosts4})</td>
                <td data-testid="fence-btns-line-posts-4">
                  <UsageButtons value={fence.linePosts4} uom={"ea"} handleClick={handleClick} />
                </td>
                <td data-testid="fence-panels-4">4' Panels ({fence.linePosts4 + fence.segments.size})</td>
                <td data-testid="fence-btns-panels-4">
                  <UsageButtons value={fence.linePosts4 + fence.segments.size} uom={"ea"} handleClick={handleClick} />
                </td>
              </tr>
              <tr>
                <td>Fence {idx + 1}</td>
                <td data-testid="fence-line-posts-6">6' Line Posts ({fence.linePosts6})</td>
                <td data-testid="fence-btns-line-posts-6">
                  <UsageButtons value={fence.linePosts6} uom={"ea"} handleClick={handleClick} />
                </td>
                <td data-testid="fence-panels-6">6' Panels ({fence.linePosts6 + fence.segments.size})</td>
                <td data-testid="fence-btns-panels-6">
                  <UsageButtons value={fence.linePosts6 + fence.segments.size} uom={"ea"} handleClick={handleClick} />
                </td>
              </tr>
              <tr>
                <td>Fence {idx + 1}</td>
                <td data-testid="fence-line-posts-8">8' Line Posts ({fence.linePosts8})</td>
                <td data-testid="fence-btns-line-posts-8">
                  <UsageButtons value={fence.linePosts8} uom={"ea"} handleClick={handleClick} />
                </td>
                <td data-testid="fence-panels-8">8' Panels ({fence.linePosts8 + fence.segments.size})</td>
                <td data-testid="fence-btns-panels-8">
                  <UsageButtons value={fence.linePosts8 + fence.segments.size} uom={"ea"} handleClick={handleClick} />
                </td>
              </tr>
              <tr>
                <td>Fence {idx + 1}</td>
                <td data-testid="fence-terminal-posts">Terminal Posts ({fence.terminalPosts + fence.gateCount * 2})</td>
                <td data-testid="fence-btns-terminal-posts">
                  <UsageButtons
                    value={fence.terminalPosts + fence.gateCount * 2}
                    uom={"ea"}
                    handleClick={handleClick}
                  />
                </td>
                <td data-testid="fence-corner-posts">Corner Posts ({fence.cornerPosts})</td>
                <td data-testid="fence-btns-corner-posts">
                  <UsageButtons value={fence.cornerPosts} uom={"ea"} handleClick={handleClick} />
                </td>
              </tr>
              <tr>
                <td>Fence {idx + 1}</td>
                <td data-testid="fence-gate-count">Gate Count ({fence.gateCount})</td>
                <td data-testid="fence-btns-gate-count">
                  <UsageButtons value={fence.gateCount} uom={"ea"} handleClick={handleClick} />
                </td>
                <td data-testid="fence-gate-length">Gate Length ({fence.gateLength}')</td>
                <td data-testid="fence-btns-gate-length">
                  <UsageButtons value={fence.gateLength} uom={"ft"} handleClick={handleClick} />
                </td>
              </tr>
              <tr>
                <td>Fence {idx + 1}</td>
                <td data-testid="fence-net-length">Net Length ({fence.netLength}')</td>
                <td data-testid="fence-btns-net-length">
                  <UsageButtons value={fence.netLength} uom={"ft"} handleClick={handleClick} />
                </td>
                <td data-testid="fence-total-length">Total Length ({fence.totalLength}')</td>
                <td data-testid="fence-btns-total-length">
                  <UsageButtons value={fence.totalLength} uom={"ft"} handleClick={handleClick} />
                </td>
              </tr>
              {fence.segments.map((segment, sidx) => (
                <React.Fragment key={sidx}>
                  <tr>
                    <td>{segment.name}</td>
                    <td data-testid="segment-line-posts-4">4' Line Posts ({segment.linePosts4})</td>
                    <td data-testid="segment-btns-line-posts-4">
                      <UsageButtons value={segment.linePosts4} uom={"ea"} handleClick={handleClick} />
                    </td>
                    <td data-testid="segment-panels-4">4' Panels ({segment.linePosts4 + 1})</td>
                    <td data-testid="segment-btns-panels-4">
                      <UsageButtons value={segment.linePosts4 + 1} uom={"ea"} handleClick={handleClick} />
                    </td>
                  </tr>
                  <tr>
                    <td>{segment.name}</td>
                    <td data-testid="segment-line-posts-6">6' Line Posts ({segment.linePosts6})</td>
                    <td data-testid="segment-btns-line-posts-6">
                      <UsageButtons value={segment.linePosts6} uom={"ea"} handleClick={handleClick} />
                    </td>
                    <td data-testid="segment-panels-6">6' Panels ({segment.linePosts6 + 1})</td>
                    <td data-testid="segment-btns-panels-6">
                      <UsageButtons value={segment.linePosts6 + 1} uom={"ea"} handleClick={handleClick} />
                    </td>
                  </tr>
                  <tr>
                    <td>{segment.name}</td>
                    <td data-testid="segment-line-posts-8">8' Line Posts ({segment.linePosts8})</td>
                    <td data-testid="segment-btns-line-posts-8">
                      <UsageButtons value={segment.linePosts8} uom={"ea"} handleClick={handleClick} />
                    </td>
                    <td data-testid="segment-panels-8">8' Panels ({segment.linePosts8 + 1})</td>
                    <td data-testid="segment-btns-panels-8">
                      <UsageButtons value={segment.linePosts8 + 1} uom={"ea"} handleClick={handleClick} />
                    </td>
                  </tr>
                  <tr>
                    <td>{segment.name}</td>
                    <td data-testid="segment-net-length">Net Length ({segment.netLength}')</td>
                    <td data-testid="segment-btns-net-length">
                      <UsageButtons value={segment.netLength} uom={"ft"} handleClick={handleClick} />
                    </td>
                    <td data-testid="segment-total-length">Total Length ({segment.length}')</td>
                    <td data-testid="segment-btns-total-length">
                      <UsageButtons value={segment.length} uom={"ft"} handleClick={handleClick} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </>
      </BaseMeasurementCard>
    );
  }
}
