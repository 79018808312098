import { DateFormat } from "app2/src/helpers/Format";
import { DocumentRecord } from "app2/src/records/Document";
import { capitalizeString } from "app2/src/services/string.service";
import { List } from "immutable";
import * as React from "react";
import { Table } from "react-bootstrap";
import DownloadDocumentButton from "../Document/DownloadDocumentButton";

export interface SignedDocumentsTableProps {
  documents: List<DocumentRecord>;
  showPDF: (document: DocumentRecord) => void;
  emailOrSign: (document: DocumentRecord) => void;
  onDocumentClick?: (document: DocumentRecord) => void;
}

export const SignedDocumentsTable: React.FC<SignedDocumentsTableProps> = ({
  documents,
  showPDF,
  emailOrSign,
  onDocumentClick,
}) => {
  const handleClick = (document: DocumentRecord) => {
    if (onDocumentClick) onDocumentClick(document);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: "25%" }}>Name</th>
          <th>Created</th>
          <th>Actions</th>
          <th style={{ width: "25%" }}>Recipients</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {documents.map((document) => (
          <tr key={document.id}>
            <td className={onDocumentClick ? "link" : ""} onClick={() => handleClick(document)}>
              {document.name}
            </td>
            <td className={onDocumentClick ? "link" : ""} onClick={() => handleClick(document)}>
              {DateFormat(document.created_at)}
            </td>
            <td>
              <a className="btn btn-secondary" title="View File" onClick={() => showPDF(document)}>
                <i className="fa fa-file-pdf-o"></i>
              </a>
              <a className="btn btn-secondary" title="Email/Sign" onClick={() => emailOrSign(document)}>
                <i className="fa fa-paper-plane-o"></i>
              </a>
              <DownloadDocumentButton documentId={document.id} />
            </td>
            <td>
              {document.signed_document?.recipients?.map((r) => (
                <div key={r.id}>
                  {r.name}: {capitalizeString(r.status)}
                </div>
              ))}
            </td>
            <td>
              {capitalizeString(document.signed_document.status)}{" "}
              {document.signed_document?.url && <DownloadDocumentButton documentId={document.id} signed />}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SignedDocumentsTable;
