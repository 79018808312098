import { ActionsUnion, createAction, handleErrors } from "./Utils";
import { IAccessData, IUserData } from "app2/src/records/UserRecord";
import { RootState, ThunkResult } from ".";
import { RootDispatchType } from "../store";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as userActions from "app2/src/reducers/user.actions";
import { QueryParamsRecord } from "../records/Page";
import { getAccess, loadAccesses, updateAccess } from "../api/access.service";
import { List } from "immutable";
import { currentOrgId } from "../selectors/org.selectors";
import { selectById } from "app2/src/selectors/access.selectors";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";

export const SET_ACCESS = "@access/SET_ACCESS";
export const RESET_ACCESS = "@access/RESET_ACCESS";
export const SET_ACCESS_ACL = "@access/SET_ACCESS_ACL";
export const RECEIVE_ACCESSES = "@access/RECEIVE_ACCESSES";
export const UPDATE_FORM_ACL = "@access/UPDATE_FORM_ACL";
export const UPDATE_FORM_RECORD = "@access/UPDATE_FORM_RECORD";

export const Actions = {
  setAccess: (id: number, access: IAccessData) => createAction(SET_ACCESS, { id, access }),
  resetAccess: (id: number) => createAction(RESET_ACCESS, { id }),
  setAccessAcl: (id: number, acl: { [key: string]: string[] }) => createAction(SET_ACCESS_ACL, { id, acl }),
  receiveAccesses: (accesses: IAccessData[]) => createAction(RECEIVE_ACCESSES, { accesses }),
  updateFormAcl: (id: number, resource: string, permission: string, value: boolean) =>
    createAction(UPDATE_FORM_ACL, { id, resource, permission, value }),
  updateFormRecord: (id: number, path: string, value: any) => createAction(UPDATE_FORM_RECORD, { id, path, value }),
};

export const AsyncActions = {
  loadAccesses: (userId: number, orgName: string, queryParams: QueryParamsRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(paginationActions.Actions.fetchPage("accesses", queryParams));

        const response = await loadAccesses(userId, orgName, queryParams);
        const pageData = {
          ids: List(_.pluck(response.accesses, "id")),
          pagination: response.meta.pagination,
        };

        dispatch(Actions.receiveAccesses(response.accesses));
        dispatch(
          userActions.Actions.addAccesses(
            userId,
            response.accesses.map((a) => a.id),
          ),
        );
        dispatch(paginationActions.Actions.receivePage("accesses", queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response, dispatch);
        dispatch(paginationActions.Actions.receivePageError("accesses", queryParams, errors));
      }
    };
  },
  getAccess: (userId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const orgId = currentOrgId(getState());
        const { access } = await getAccess(userId, orgId);
        dispatch(Actions.setAccess(access.id, access));
        dispatch(userActions.Actions.addAccesses(userId, [access.id]));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  updateAccess: (accessId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const config = selectById(getState(), { accessId }).get("config");
        const { access } = await updateAccess(accessId, config);
        dispatch(Actions.setAccess(access.id, access));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Commission values saved"));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
