import { FlashLevels, IFlash } from "../Common/FlashService";
import { IBaseConfig } from "../Common/IBaseConfig";

class EagleviewLoginModalCtrl implements ng.IComponentController {
  public spinnerPromise: ng.IPromise<any>;
  public resolve: any;
  public close: () => null;
  public dismiss: () => null;

  public credentials = {
    username: "",
    password: "",
  };

  public static $inject = ["$http", "$scope", "Flash", "BaseConfig"];
  constructor(
    protected $http: ng.IHttpService,
    protected $scope: ng.IScope,
    protected Flash: IFlash,
    protected BaseConfig: IBaseConfig
  ) {}

  public login() {
    this.spinnerPromise = this.$http
      .post(this.BaseConfig.BASE_URL + "/integrations/eagleview/login", this.credentials)
      .then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully authenticated with EagleView");
        this.close();
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems authenticating with EagleView.  Please check your username & password then try again."
        );
      });
  }
}

export class EagleviewLoginModalComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };
  public templateUrl = "src/Reports/eagleview_login_modal.html";

  constructor() {
    this.controller = EagleviewLoginModalCtrl;
  }
}
