import { ActionsUnion, createAction } from "./Utils";
import { IEstimateFinanceOptionData } from "../records/EstimateFinanceOption";
import { List } from "immutable";
import { ThunkResult } from "app2/src/reducers";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { RootDispatchType } from "../store";

export const RECEIVE_ESTIMATE_FINANCE_OPTION = "@estimateFinanceOptions/RECEIVE_ESTIMATE_FINANCE_OPTION";
export const DUPLICATE_ESTIMATE_FINANCE_OPTIONS = "@estimateFinanceOptions/DUPLICATE_ESTIMATE_FINANCE_OPTIONS";
export const ADD_FINANCE_OPTION = "@estimateFinanceOptions/ADD_FINANCE_OPTION";
export const REMOVE_FINANCE_OPTION = "@estimateFinanceOptions/REMOVE_FINANCE_OPTION";
export const CALCULATE_FINANCE_OPTION = "@estimateFinanceOptions/CALCULATE_FINANCE_OPTIONS";

export const Actions = {
  receiveEstimateFinanceOption: (estimateFinanceOption: Partial<IEstimateFinanceOptionData>) =>
    createAction(RECEIVE_ESTIMATE_FINANCE_OPTION, { estimateFinanceOption }),
  duplicateEstimateFinanceOptions: (estimateId: number, financeOptionIds: List<number>) =>
    createAction(DUPLICATE_ESTIMATE_FINANCE_OPTIONS, { estimateId, financeOptionIds }),
  addFinanceOption: (estimateId: number, financeOptionId: number) =>
    createAction(ADD_FINANCE_OPTION, { estimateId, financeOptionId }),
  removeFinanceOption: (estimateId: number, estimateFinanceOptionId: number) =>
    createAction(REMOVE_FINANCE_OPTION, { estimateId, estimateFinanceOptionId }),
  calculateFinanceOption: (estimateId: number, estimateFinanceOptionId: number) =>
    createAction(CALCULATE_FINANCE_OPTION, { estimateId, estimateFinanceOptionId }),
};

export const AsyncActions = {
  addFinanceOption: (estimateId: number, financeOptionId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const estimateFinanceOptionId = getState().getIn(["estimateFinanceOptions", "unsavedId"]);
      dispatch(Actions.addFinanceOption(estimateId, financeOptionId));
      dispatch(estimateActions.AsyncActions.addFinanceOptionId(estimateId, estimateFinanceOptionId));
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
