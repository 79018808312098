import { Map, Record, List } from "immutable";
import { EstimateFinanceOptionRecord, fromJSON } from "../records/EstimateFinanceOption";
import { RootState, RootActions } from ".";
import * as estimateFinanceOptionActions from "./estimateFinanceOption.actions";
import { updateEstimateFinanceOptionPayments } from "../selectors/estimateFinanceOption.selectors";
import { estimateFinancedAmount } from "app2/src/selectors/estimate.selectors";
import { model as estimateModel } from "./estimate.reducer";

export const EstimateFinanceOptionStateRecord = Record({
  byId: Map<number, EstimateFinanceOptionRecord>(),
  lastSavedById: Map<number, EstimateFinanceOptionRecord>(),
  unsavedId: -1,
});

export const initialState = EstimateFinanceOptionStateRecord();

export type EstimateFinanceOptionState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "estimateFinanceOptions";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let estimateFinanceOption: EstimateFinanceOptionRecord;
  let unsavedId: number;
  switch (action.type) {
    case estimateFinanceOptionActions.RECEIVE_ESTIMATE_FINANCE_OPTION:
      estimateFinanceOption = fromJSON({ ...action.payload.estimateFinanceOption });

      state = state.setIn([model, "byId", estimateFinanceOption.id], estimateFinanceOption);
      return state.setIn([model, "lastSavedById", estimateFinanceOption.id], estimateFinanceOption);

    case estimateFinanceOptionActions.DUPLICATE_ESTIMATE_FINANCE_OPTIONS:
      const estimate = state.getIn([estimateModel, "byId", action.payload.estimateId]);

      let financeOptionIds = List();
      unsavedId = state.getIn([model, "unsavedId"]);
      action.payload.financeOptionIds.map((foId: number) => {
        let efo = state.getIn([model, "byId", foId]);
        efo = efo.set("id", unsavedId);
        financeOptionIds = financeOptionIds.push(unsavedId);
        state = state.setIn([model, "byId", efo.id], efo);
        unsavedId -= 1;
      });

      return state
        .setIn([estimateModel, "byId", estimate.id, "finance_option_ids"], financeOptionIds)
        .setIn([model, "unsavedId"], unsavedId);

    case estimateFinanceOptionActions.ADD_FINANCE_OPTION:
      unsavedId = state.getIn([model, "unsavedId"]);
      estimateFinanceOption = fromJSON({
        id: unsavedId,
        estimate_id: action.payload.estimateId,
        finance_option_id: action.payload.financeOptionId,
      });
      state = state.setIn([model, "byId", estimateFinanceOption.id], estimateFinanceOption);
      unsavedId -= 1;
      return state.setIn([model, "unsavedId"], unsavedId);

    case estimateFinanceOptionActions.REMOVE_FINANCE_OPTION:
      return state.setIn([model, "byId", action.payload.estimateFinanceOptionId, "_destroy"], true);

    case estimateFinanceOptionActions.CALCULATE_FINANCE_OPTION:
      return state.setIn(
        [model, "byId", action.payload.estimateFinanceOptionId],
        updateEstimateFinanceOptionPayments(state, {
          financedAmount: estimateFinancedAmount(state, { estimateId: action.payload.estimateId }),
          estimateFinanceOptionId: action.payload.estimateFinanceOptionId,
        }),
      );

    default:
      return state;
  }
};
