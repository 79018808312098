import * as React from "react";
import { Can } from "app2/src/components/Common/CanComponent";
import * as FontAwesome from "react-fontawesome";
import { useDispatch } from "react-redux";
import { AsyncActions } from "app2/src/reducers/estimate.actions";

export interface DownloadCommissionButtonProps {
  estimateId: number;
}

export const DownloadCommissionButton: React.FC<DownloadCommissionButtonProps> = ({ estimateId }) => {
  const dispatch = useDispatch();

  const downloadPdf = () => {
    dispatch(AsyncActions.getCommissionPdf(estimateId));
  };

  return (
    <Can resource="estimate" permission="commission_pdf">
      <FontAwesome
        name="download"
        className="rsf-base-link rsf-base-66 action-button"
        title="Download Commission Sheet"
        onClick={() => downloadPdf()}
      />
    </Can>
  );
};
