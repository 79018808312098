import { IEmailData } from "app2/src/records/Email";
import { fetcher } from "../helpers/Fetcher";

export interface IEmailResponse {
  email: IEmailData;
}

export interface ISendMessageParams {
  kind: "payment_request";
  provider: "text" | "email";
  to: string;
  data: any;
}

export const url = "api/v1/jobs/:job_id/emails";

export const sendMessage = (jobId: number, params: ISendMessageParams): Promise<IEmailResponse> => {
  const actionUrl = url.replace(":job_id", jobId.toString());
  return fetcher.post<IEmailResponse>(actionUrl, params);
};
