import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as presentationActions from "app2/src/reducers/presentation.actions";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { Row, Col } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import track from "react-tracking";
import Presentation from "app2/src/components/JobTabs/PresentationTab/Presentations/Presentation";
import { List } from "immutable";
import { GridContainer } from "app2/src/components/Common/GridContainer";
import { push } from "app2/src/reducers/router.actions";
import { ILocationData } from "app2/src/records/Location";

const mapStateToProps = (state: RootState) => {
  const modelName = "presentation",
    presenationQueryParams = queryParams(state, { modelName });
  const page = presenationQueryParams.get("page");
  const presentationIds = ids(state, { modelName }) || List();
  return {
    presentationIds,
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    presenationQueryParams,
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: PresentationsProps) => {
  return {
    listPresentations: (queryParams: QueryParamsRecord) =>
      dispatch(
        presentationActions.AsyncActions.listPresentations(
          {
            kind: "org",
            folderId: ownProps.folderParentId,
          },
          queryParams,
        ),
      ),
    push: (location: Partial<ILocationData>) => dispatch(push(location)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PresentationsProps {
  folderParentId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentationsProps;

@track(() => {
  return {
    component: "Presentations",
  };
})
class Presentations extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.queryPresentations = this.queryPresentations.bind(this);
  }

  @track((_props, _state, [page]) => ({
    action: "queryPresentations",
    page: page,
  }))
  public queryPresentations(page: number): void {
    const { listPresentations, presenationQueryParams } = this.props;
    const newQueryParams = presenationQueryParams.setIn(["page"], page);

    listPresentations(newQueryParams);
  }

  public render(): React.ReactNode {
    const { presentationIds, page, pagination } = this.props;

    if (page?.loading) {
      return <SpinnerComponent localProperty={true} />;
    }

    if (_.isNullOrUndefined(page) || presentationIds.size === 0) {
      return (
        <Row>
          <Col>
            <div className="form-section blank-state">
              <img src="/assets/images/icons-large/presentations.6c04ec4d.png" />
              <h2>No presentations added.</h2>
              <p>Contact your organization administrator to add them.</p>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col>
          <GridContainer columnWidth={245}>
            {presentationIds.map((id: number) => (
              <Presentation key={id.toString()} presentationId={id} />
            ))}
          </GridContainer>
          <StandardPagination metadata={pagination} pageChanged={this.queryPresentations} />
          <br />
        </Col>
      </Row>
    );
  }
}

export default connector(Presentations);
