import * as React from "react";
import { FormControl } from "react-bootstrap";

export interface IDecimalInputComponentProps {
  value: number;
  onNumberChange: (value: number, name: string) => void;
  type?: string;
  name?: string;
  min?: string;
  max?: string;
}

export interface IDecimalInputComponentState {
  display: string;
  value: number;
  isDecimal: boolean;
  decimalPlaces: number;
}

type Props = IDecimalInputComponentProps & React.InputHTMLAttributes<HTMLInputElement>;

export class DecimalInputComponent extends React.Component<Props, IDecimalInputComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = this.split(props.value?.toString(), true);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  public componentDidUpdate(_prevState, prevProps) {
    const { value, onNumberChange, name } = this.props;
    if (_.isNullOrUndefined(value)) {
      return;
    }

    if (prevProps && +prevProps.value !== +value && value !== this.state.value) {
      const newState = this.split(value.toString() || "0");
      if (newState.value !== value) {
        onNumberChange(newState.value, name);
      } else {
        this.setState(newState);
      }
    }
  }

  public split(value: string, initial = false): IDecimalInputComponentState {
    const parts = (value || 0).toString().split(".");
    const isDecimal = parts.length > 1;
    let decimalPlaces = parts.length > 1 ? Math.min(parts[1].length, 2) : 0;
    // So that something that was saved as 3.3 shows up as
    // 3.30, I'm going with the fact that chances are that's what was typed
    // originally AND that's what looks 'better'
    if (initial && isDecimal) {
      decimalPlaces = 2;
    }

    // Make sure to use round, toFixed has an issue rounding with 1.335 = 1.33
    const roundedValue = _.round(+value, -2);

    let display = isDecimal ? roundedValue.toFixed(decimalPlaces) : value;
    // Currently this case doesn't happen.  For a 'number' input the onChange doesn't fire on entering
    // the period. It'll fire for '3', won't fire for '3.', then fire for '3.1'.  This does handle it
    // in tests though.
    if (isDecimal && decimalPlaces <= 0) {
      display = `${display}.`;
    }

    return {
      isDecimal: isDecimal,
      decimalPlaces: decimalPlaces,
      value: roundedValue,
      display: display,
    };
  }

  public handleOnChange(event: any) {
    const { onNumberChange, name } = this.props;
    const value = event.target.value;

    this.setState(this.split(value), () => {
      onNumberChange(this.state.value, name);
    });
  }

  public render() {
    const { value, onNumberChange, ...props } = this.props;
    const { display } = this.state;
    return <FormControl value={display} onChange={this.handleOnChange as any} {...(props as any)} />;
  }
}
