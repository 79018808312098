import * as React from "react";
import { ConnectOptions } from "twilio-video";
import { VideoProvider } from "app2/src/components/VideoProvider/VideoProvider";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "app2/src/components/Twilio/components/Main";
import { BrowserDetection } from "app2/src/helpers/BrowserDetection";
import { RoomParams } from "app2/src/components/VideoProvider/VideoProvider";
import * as Video from "twilio-video";
import { BrowserSupportModal } from "../Common/BrowserSupportModal";
import { useTracking } from "react-tracking";
import { currentJob } from "app2/src/selectors/job.selectors";
import { Dispatch } from "app2/src/helpers/Analytics";
import { JobRecord } from "app2/src/records/Job";
import { FlashAlertProvider } from "app2/src/components/VideoProvider/FlashAlertContext";

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  // Bandwidth Profile, Dominant Speaker, and Network Quality
  // features are only available in Small Group or Group Rooms.
  // Please set "Room Type" to "Group" or "Small Group" in your
  // Twilio Console: https://www.twilio.com/console/video/configure
  bandwidthProfile: {
    video: {
      mode: "collaboration",
      dominantSpeakerPriority: "standard",
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  networkQuality: { local: 1, remote: 1 },

  // Comment this line if you are playing music.
  maxAudioBitrate: 16000,

  // VP8 simulcast enables the media server in a Small Group or Group Room
  // to adapt your encoded video quality for each RemoteParticipant based on
  // their individual bandwidth constraints. This has no effect if you are
  // using Peer-to-Peer Rooms.
  preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
};

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (BrowserDetection.isBrowserMobile() && connectionOptions && connectionOptions.bandwidthProfile.video) {
  connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
}

interface TwilioAppProps {
  roomParams: RoomParams;
  joinRoom(): Promise<string>;
  leaveRoom(): void;
}

export default function TwilioApp(props: TwilioAppProps) {
  const { roomParams, joinRoom, leaveRoom } = props;
  const dispatch = useDispatch();
  const addFlashMessage = useCallback(
    (level: FlashLevels, message: string) => dispatch(commonActions.Actions.flashAddAlert(level, message)),
    [dispatch],
  );
  const notifier = (msg: string) => dispatch(commonActions.AsyncActions.notifierAddRemoteMessage(msg));
  const job: JobRecord = useSelector(currentJob);
  const { Track } = useTracking(
    {
      category: "Job Header",
      kind: "premium",
      host: roomParams.host,
      identity: roomParams.identity,
      roomName: roomParams.roomName,
      roomUid: roomParams.roomUid,
      audioPreference: roomParams.audio_preference,
      job: job?.id,
      org: job?.org_id,
    },
    { dispatch: Dispatch.dispatch },
  );

  const setError = (error) => {
    addFlashMessage(FlashLevels.danger, `${error.code} - ${error.message}`);
  };

  return (
    <Track>
      <BrowserSupportModal open={!Video.isSupported} />
      <FlashAlertProvider
        flashAlert={addFlashMessage as any as (level: string, message: string) => void}
        notifier={notifier}>
        <VideoProvider
          options={connectionOptions}
          roomParams={roomParams}
          joinRoom={joinRoom}
          onError={setError}
          onDisconnect={leaveRoom}>
          <Main />
        </VideoProvider>
      </FlashAlertProvider>
    </Track>
  );
}
