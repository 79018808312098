import * as React from "react";
import * as hoverActions from "app2/src/reducers/integrations/hover.actions";
import { connect, ConnectedProps } from "app2/src/connect";
import { JobListContainer, IJobListContainerData } from "../JobListContainer";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";
import { RootState } from "app2/src/reducers";
import { TaskPoller } from "app2/src/api/task.service";
import { SearchBox } from "app2/src/components/Common/SearchBox";
import { RootDispatchType } from "app2/src/store";

const mapStateToProps = (state: RootState, ownProps: ImportJobProps) => {
  const page = state.getIn(["integrations", "hover", "page"], 1);

  return {
    page: page,
    search: state.getIn(["integrations", "hover", "search"]),
    reports: state.getIn(["integrations", "hover", "pages", page]),
    jobId: state.getIn(["integrations", "hover", "currentJob"]),
    importing: state.getIn(["integrations", "hover", "importing"]),
    importLogs: state.getIn(["integrations", "hover", "importLog"]),
    errorLogs: state.getIn(["integrations", "hover", "errors"]),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: ImportJobProps) => {
  return {
    queryReportPage: (params: hoverActions.IHoverParams) => {
      return dispatch(hoverActions.AsyncActions.getPage(params));
    },
    importReport: (report: IJobListContainerData, jobId: number) => {
      return dispatch(hoverActions.AsyncActions.importReport(report.id, jobId)) as any as Promise<TaskPoller>;
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface ImportJobProps {
  modalInstance: ng.ui.bootstrap.IModalInstanceService;
  tracking?: TrackingProp;
}

const track: Track<TrackingData, ImportJobProps> = _track;

export interface ImportJobState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImportJobProps;

@track(
  (props: Props) => {
    return {
      category: "HOVER Import",
      job: props.jobId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
export class ImportJobComponent extends React.Component<Props, ImportJobState> {
  constructor(props: Props) {
    super(props);

    props.queryReportPage({
      page: 1,
      search: "",
    });

    this.startImport = this.startImport.bind(this);
    this.cancelImport = this.cancelImport.bind(this);
    this.queryPage = this.queryPage.bind(this);
    this.searching = this.searching.bind(this);
  }

  @track({ action: "Importing Job" })
  public startImport(hoverJob: IJobListContainerData): Promise<boolean> {
    const { modalInstance, jobId, importReport } = this.props;

    return importReport(hoverJob, jobId).then((poller) => {
      return poller.promise.then(() => {
        modalInstance.close();
        return true;
      });
    });
  }

  public searching(query: string) {
    this.props.queryReportPage({
      page: 1,
      search: query,
    });
  }

  public queryPage(page: number) {
    this.props.queryReportPage({
      page: page,
      search: this.props.search,
    });
  }

  @track((props: Props) => ({
    action: `${props.importing ? "Background" : "Cancel"} HOVER Import`,
  }))
  public cancelImport(): void {
    const { modalInstance } = this.props;
    modalInstance.close();
  }

  public render() {
    const { importing, importLogs, errorLogs, reports } = this.props;

    let jobs, meta;
    if (reports && reports.jobs) {
      jobs = reports.jobs.map((r) => {
        return {
          id: r.id,
          name: `${r.name}: ${r.location_line_1} ${r.location_city}, ${r.location_region} ${r.location_postal_code}`,
        };
      });

      meta = reports.meta.pagination;
    } else {
      jobs = [];
      meta = {};
    }

    let header = "Choose a HOVER Job";
    let cancel = "Cancel";

    let modalBody = (
      <Modal.Body className="vh-height-modal-body">
        {reports && reports.loading ? (
          <div className="text-center">
            <img className="spinner-graphic" />
          </div>
        ) : (
          <>
            <JobListContainer action={"Import"} actionFired={this.startImport} jobs={jobs}></JobListContainer>
            <StandardPagination pageChanged={this.queryPage} metadata={meta}></StandardPagination>
          </>
        )}
      </Modal.Body>
    );

    if (importing) {
      modalBody = (
        <Modal.Body>
          <pre style={{ minHeight: "200px", lineHeight: "1.5em" }}>{importLogs}</pre>
        </Modal.Body>
      );

      header = "Importing HOVER Measurements";
      cancel = "Finish in Background";
    }

    if (_.compact(errorLogs).length > 0) {
      modalBody = <Modal.Body>{_.compact(errorLogs).join("<br />")}</Modal.Body>;
    }

    return (
      <>
        <Modal.Header onHide={this.cancelImport} closeButton>
          <Row>
            <Col>
              <Modal.Title>
                <h3>{header}</h3>
              </Modal.Title>
            </Col>
            <Col>
              {importing ? null : <SearchBox searching={this.searching} placeholder="Search Hover Jobs" query="" />}
            </Col>
          </Row>
        </Modal.Header>
        {modalBody}
        <Modal.Footer>
          <Button type="button" variant="cancel" onClick={this.cancelImport}>
            {cancel}
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export default connector(ImportJobComponent);
