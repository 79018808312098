import { List } from "immutable";
import * as React from "react";
import { Card, Form } from "react-bootstrap";

export interface DataTypesListProps {
  dataTypes: List<string>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DataTypesList: React.FC<DataTypesListProps> = ({ dataTypes, handleChange }) => {
  return (
    <Card>
      <Card.Header>Items Available to be Duplicated</Card.Header>
      <Card.Body>
        <Form.Group>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Measurement Links"
              id="measurement_links"
              name="measurement_links"
              onChange={handleChange}
              checked={dataTypes.includes("measurement_links")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Estimate Templates"
              id="estimate_templates"
              name="estimate_templates"
              onChange={handleChange}
              checked={dataTypes.includes("estimate_templates")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Documents"
              id="documents"
              name="documents"
              onChange={handleChange}
              checked={dataTypes.includes("documents")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Presentations"
              id="presentations"
              name="presentations"
              onChange={handleChange}
              checked={dataTypes.includes("presentations")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Payment Terms"
              id="payment_terms"
              name="payment_terms"
              onChange={handleChange}
              checked={dataTypes.includes("payment_terms")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Discounts"
              id="discounts"
              name="discounts"
              onChange={handleChange}
              checked={dataTypes.includes("discounts")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Job Types"
              id="job_types"
              name="job_types"
              onChange={handleChange}
              checked={dataTypes.includes("job_types")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Finance Options"
              id="finance_options"
              name="finance_options"
              onChange={handleChange}
              checked={dataTypes.includes("finance_options")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Validations"
              id="validations"
              name="validations"
              onChange={handleChange}
              checked={dataTypes.includes("validations")}
            />
          </div>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};
