import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { EventsCalendar } from "../../Common/EventsCalendar";
import { useDispatch, useSelector } from "react-redux";
import { pathname } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import * as eventActions from "app2/src/reducers/event.actions";
import { queryParamsFromJSON } from "app2/src/records/Page";
import { Context, ContextRecord, DateRange } from "app2/src/components/Common/EventsCalendar/Context";
import { getAllPaginationByModel, pageRecord } from "app2/src/selectors/pagination.selectors";
import { RootState } from "app2/src/reducers";
import { currentUserId as currentUserIdSelector } from "app2/src/selectors/user.selectors";
import { List } from "immutable";
import { jobTabColor } from "app2/src/selectors/event.selectors";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { useTracking } from "react-tracking";

export const EventCalendarModal = () => {
  const modelName = "events";
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "EventRow" });

  // Selectors
  const path = useSelector(pathname);
  const userEvents = useSelector((state: RootState) =>
    getAllPaginationByModel(state, { path: [modelName, "byId"], modelName: "userEvents" }),
  );
  const currentUserId: number = useSelector(currentUserIdSelector);
  const loading = useSelector((state: RootState) => {
    return Boolean(pageRecord(state, { modelName: "userEvents", page: 1 })?.get("loading"));
  });

  // Local State
  const [dateRange, setDateRange] = React.useState<DateRange>({ start: null, end: null });
  const [userIds, setUserIds] = React.useState(List([currentUserId]));

  // Methods
  const close = () => {
    trackEvent({ action: "close" });
    const prevPath = path.split("/calendar")[0];
    dispatch(push(prevPath));
  };
  const loadUserEvents = (dateRange: DateRange) => {
    dispatch(
      eventActions.AsyncActions.loadEvents(
        queryParamsFromJSON({
          page: 1,
          parameters: {
            start_time: dateRange.start,
            end_time: dateRange.end,
          },
        }),
        "userEvents",
        true,
      ),
    );
  };
  const handleDateChange = (newDateRange) => {
    loadUserEvents(newDateRange);
  };

  return (
    <Context.Provider
      value={
        new ContextRecord({
          dateRange,
          setDateRange,
          userIds,
          setUserIds,
          events: userEvents,
          loading,
          setEventColor: jobTabColor,
        })
      }>
      <SpinnerComponent localProperty={loading} />
      <Modal show={true} onHide={close} size="xl" backdrop="static">
        <Modal.Header className="d-flex justify-content-between align-items-center" closeButton>
          <Modal.Title>Set Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "400px" }}>
          <EventsCalendar onDateChange={handleDateChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Context.Provider>
  );
};
