export class CalendarProviderUnit {
  public static factory() {
    const factoryFunction = (text) => {
      let result = text;
      switch (text) {
        case "apple":
          result = "Apple iCloud";
          break;
        case "cronofy":
          result = "Cronofy";
          break;
        case "exchange":
          result = "Microsoft Exchange";
          break;
        case "google":
          result = "Google Calendar";
          break;
        case "live_connect":
          result = "Outlook";
          break;
        case "office365":
          result = "Office 365";
          break;
      }

      return result;
    };

    factoryFunction.$inject = [];

    return factoryFunction;
  }
}
