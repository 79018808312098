import { List, Record } from "immutable";

export const estimateSignedDocumentFromJSON = (
  json: Partial<IEstimateSignedDocumentData>,
): EstimateSignedDocumentRecord => {
  const recordData: IEstimateSignedDocumentRecord = { ...(json as any) };

  recordData.signedDocumentIds = List(recordData.signedDocumentIds);

  return new EstimateSignedDocumentRecord(recordData);
};
export interface IEstimateSignedDocumentData {
  estimateId: number;
  signedDocumentIds: number[];
}

export interface IEstimateSignedDocumentRecord {
  estimateId: number;
  signedDocumentIds: List<number>;
}

const defaultEstimateSignedDocumentRecord: IEstimateSignedDocumentRecord = {
  estimateId: 0,
  signedDocumentIds: List(),
};

export class EstimateSignedDocumentRecord
  extends Record(defaultEstimateSignedDocumentRecord)
  implements IEstimateSignedDocumentRecord {}
