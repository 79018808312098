import * as React from "react";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { StoreRegistry } from "app2/src/storeRegistry";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { ILocationData } from "app2/src/records/Location";
import { push } from "app2/src/reducers/router.actions";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { dirtyWatcherTitle, ReactRouterDirtyWatcher } from "app2/src/components/Common/ReactRouterDirtyWatcher";

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return { push: (location: Partial<ILocationData>) => dispatch(push(location)) };
};

const connector = connect(null, mapDispatchToProps);

export interface DirtyWatcherProps {
  check: () => boolean;
  reset: () => void;
  reactRouter?: boolean;
}

interface DirtyWatcherState {
  openDialog: number;
  location: Partial<ILocationData>;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DirtyWatcherProps;

class DirtyWatcher extends React.Component<Props, DirtyWatcherState> {
  public title = dirtyWatcherTitle;
  private _locationChangeBinding: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      openDialog: 0,
      location: null,
    };

    this.onRefresh = this.onRefresh.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  public componentDidMount(): void {
    const $rootScope = StoreRegistry.get<RsfRootScope>("$rootScope");

    window.addEventListener("beforeunload", this.onRefresh);

    this._locationChangeBinding = $rootScope.$on("RSF_StateChange", (event: ng.IAngularEvent, args: any) => {
      const { check } = this.props;
      if (check()) {
        args.transition._deferred.reject();
        this.setState((state) => ({
          openDialog: state.openDialog + 1,
          location: { pathname: args.transition.to().name, query: args.transition.params() },
        }));
      }
    });
  }

  public componentWillUnmount(): void {
    window.removeEventListener("beforeunload", this.onRefresh);
    if (this._locationChangeBinding) {
      this._locationChangeBinding();
    }
  }

  public onRefresh(e): any {
    const { check } = this.props;
    if (check()) {
      e.returnValue = this.title;
      return this.title;
    } else {
      return undefined;
    }
  }

  public navigate() {
    const { location } = this.state;
    const { reset, push } = this.props;
    reset();
    push(location);
  }

  public render(): React.ReactNode {
    const { openDialog } = this.state;
    const { children, reactRouter, check, reset } = this.props;
    return (
      <div>
        <ConfirmDialog title={this.title} openDialog={openDialog} onConfirm={this.navigate} />
        {reactRouter ? <ReactRouterDirtyWatcher title={this.title} check={check} reset={reset} /> : null}
        {children}
      </div>
    );
  }
}

export default connector(DirtyWatcher);
