import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { UserInvitationViewProps } from "../UserInvitationContainer";
import { FormView } from "./FormView";

export class ResetPasswordView extends React.Component<UserInvitationViewProps, {}> {
  public constructor(props) {
    super(props);
  }

  public render() {
    return (
      <Row>
        <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
          <div className="form-section text-center">
            <div className="form-section-heading">
              <h3>Complete the Password Reset Process</h3>
            </div>
            <div className="form-section-content">
              <FormView {...this.props} enforceTos={false} />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
