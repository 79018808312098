import { FormControl, Select } from "@tberrysoln/rsf-form";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { getUoM } from "app2/src/records/Measurement";
import { OpeningRecord } from "app2/src/records/Opening";
import { RootState } from "app2/src/reducers";
import { annotation } from "app2/src/selectors/elevation.selectors";
import { currentMeasurement, measurementEditing } from "app2/src/selectors/measurement.selectors";
import * as React from "react";
import { useSelector } from "react-redux";
import * as FontAwesome from "react-fontawesome";

interface IOpeningRowProps {
  opening: OpeningRecord;
  destroyCallback: (id: number) => void;
}

export const OpeningRow: React.FC<IOpeningRowProps> = ({ opening, destroyCallback }) => {
  // Selectors
  const editing = useSelector(measurementEditing);
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const uom = getUoM(measurement);
  const associatedAnnotation = useSelector((state: RootState) =>
    annotation(state, { openingId: opening.id, openingType: opening.kind }),
  );

  // Methods
  const destroy = () => {
    destroyCallback(opening.id);
  };

  if (opening._destroy) {
    return null;
  }

  const annotationTitle = `This ${opening.kind.toLowerCase()} is associated with an elevation. It can be edited/deleted in the Window-inator.`;
  const oeTitle = `This ${opening.kind.toLowerCase()} is associated with an estimate. Deletion is disabled.`;

  if (editing && !associatedAnnotation) {
    return (
      <tr>
        <td>{opening.designator}</td>
        <td className="mwpx-150">
          <FormControl formGroupProps={{ className: "small" }} label="" name={`${opening.get("id")}.name`} />
        </td>
        <td className="mwpx-150">
          <Select className="small" label="" name={`${opening.get("id")}.location`}>
            <option key={-1} value={""}></option>
            {measurement.get("locations").map((location, idx) => (
              <option key={idx} value={location}>
                {location}
              </option>
            ))}
          </Select>
        </td>
        <td className="mwpx-100">
          <Select className="small" label="" name={`${opening.get("id")}.level`}>
            <option key={-1} value={""}></option>
            {measurement.get("levels").map((level, idx) => (
              <option key={idx} value={level}>
                {level}
              </option>
            ))}
          </Select>
        </td>
        <td className="mwpx-125">
          <FormControl
            formGroupProps={{ className: "small floater-right" }}
            formControlProps={{ type: "number" }}
            label=""
            name={`${opening.get("id")}.width`}
          />
          <div className="floater">{uom["window_width"]}</div>
        </td>
        <td className="mwpx-125">
          <FormControl
            formGroupProps={{ className: "small floater-right" }}
            formControlProps={{ type: "number" }}
            label=""
            name={`${opening.get("id")}.height`}
          />
          <div className="floater">{uom["window_height"]}</div>
        </td>
        <td className="mwpx-125">
          <FormControl
            formGroupProps={{ className: "small floater-right" }}
            formControlProps={{ type: "number" }}
            disabled
            label=""
            name={`${opening.get("id")}.ui`}
          />
          <div className="floater">{uom["window_ui"]}</div>
        </td>
        <td className="mwpx-125">
          <FormControl
            formGroupProps={{ className: "small floater-right" }}
            formControlProps={{ type: "number" }}
            disabled
            label=""
            name={`${opening.get("id")}.area`}
          />
          <div className="floater">{uom["window_area"]}</div>
        </td>
        <td>
          {opening.opening_estimations.size > 0 ? (
            <ConfirmDialog title={oeTitle} hideCancel>
              {(confirm) => (
                <FontAwesome name="info-circle rsf-base-link" size="lg" title={oeTitle} onClick={confirm(() => {})} />
              )}
            </ConfirmDialog>
          ) : (
            <ConfirmDialog title={`Are you sure you want to delete: ${opening.name}?`}>
              {(confirm) => (
                <a className="action-button" data-testid="destroy" title="Delete" onClick={confirm(destroy)}>
                  <i className="rsf-delete-link rsf-base-66"></i>
                </a>
              )}
            </ConfirmDialog>
          )}
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td>{opening.designator}</td>
      <td>{opening.name}</td>
      <td>{opening.location}</td>
      <td>{opening.level}</td>
      <td>{`${opening.width} ${uom["window_width"]}`}</td>
      <td>{`${opening.height} ${uom["window_height"]}`}</td>
      <td>{`${opening.ui} ${uom["window_ui"]}`}</td>
      <td>{`${opening.area} ${uom["window_area"]}`}</td>
      {associatedAnnotation && editing && (
        <td>
          <ConfirmDialog title={annotationTitle} hideCancel>
            {(confirm) => (
              <FontAwesome
                name="info-circle rsf-base-link"
                size="lg"
                title={annotationTitle}
                onClick={confirm(() => {})}
              />
            )}
          </ConfirmDialog>
        </td>
      )}
    </tr>
  );
};
