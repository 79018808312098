import * as React from "react";
import { Button } from "react-bootstrap";

export interface LearnMoreAdsProps {
  index: number;
  controller: any;
}

export class LearnMoreAds extends React.Component {
  public props: LearnMoreAdsProps;

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  public handleClose() {
    this.props.controller.toggleAds(true);
  }

  public isDefaultSlide(index: number) {
    return this.props.index === index;
  }

  public render() {
    return (
      <div>
        <div className="occ-modal">
          <div className="occ-modal-body">
            <div className="occ-modal-content">
              <div className="slider">
                <input
                  type="radio"
                  name="slider"
                  title="slide1"
                  defaultChecked={this.isDefaultSlide(1)}
                  className="slider__nav"
                />
                <input
                  type="radio"
                  name="slider"
                  title="slide2"
                  defaultChecked={this.isDefaultSlide(2)}
                  className="slider__nav"
                />
                <input
                  type="radio"
                  name="slider"
                  title="slide3"
                  defaultChecked={this.isDefaultSlide(3)}
                  className="slider__nav"
                />
                <input
                  type="radio"
                  name="slider"
                  title="slide4"
                  defaultChecked={this.isDefaultSlide(4)}
                  className="slider__nav"
                />
                <input
                  type="radio"
                  name="slider"
                  title="slide5"
                  defaultChecked={this.isDefaultSlide(5)}
                  className="slider__nav"
                />
                <div className="slider__inner">
                  <div className="slider__contents">
                    <img className="img-responsive" src="/assets/images/ads/freemium_need_more_photos.0961c15c.jpg" />
                  </div>
                  <div className="slider__contents">
                    <img className="img-responsive" src="/assets/images/ads/freemium_need_more_docs.e31c321f.jpg" />
                  </div>
                  <div className="slider__contents">
                    <img className="img-responsive" src="/assets/images/ads/freemium_need_more_screenshare.e5c71fb8.jpg" />
                  </div>
                  <div className="slider__contents">
                    <img className="img-responsive" src="/assets/images/ads/freemium_more_robust_estimation.cd983a52.jpg" />
                  </div>
                  <div className="slider__contents">
                    <img className="img-responsive" src="/assets/images/ads/freemium_want_to_collaborate.7b29e7d3.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className={"occ-modal-footer"}>
              <Button variant="default" onClick={this.handleClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
