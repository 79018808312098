import { RecordsForEstimateRecord } from "app2/src/records/RecordsForEstimate";
import { estimate, lastSavedEstimate } from "app2/src/selectors/estimate.selectors";
import {
  estimateFinanceOptions,
  estimateGroups,
  estimateLineItems,
  estimateLineItemOptions,
  estimateLineItemImages,
  estimateLineItemDocuments,
  estimateLineItemOpeningEstimations,
  lastSavedEstimateFinanceOptions,
  lastSavedEstimateGroups,
  lastSavedEstimateLineItems,
  lastSavedEstimateLineItemOptions,
  lastSavedEstimateLineItemImages,
  lastSavedEstimateLineItemDocuments,
  lastSavedEstimateLineItemOpeningEstimations,
} from "app2/src/selectors/estimateAccessor.selectors";
import { createSelector } from "reselect";

/**
 * Fetches all the records associated with a specific Estimate
 *
 * @param {RootState} state
 * @param {estimateId: number} params
 * @returns {RecordsForEstimateRecord}
 */
export const recordsForEstimate = createSelector(
  [
    estimate,
    estimateFinanceOptions,
    estimateGroups,
    estimateLineItems,
    estimateLineItemOptions,
    estimateLineItemImages,
    estimateLineItemDocuments,
    estimateLineItemOpeningEstimations,
  ],
  (
    estimate,
    estimateFinanceOptions,
    estimateGroups,
    estimateLineItems,
    estimateLineItemOptions,
    estimateLineItemImages,
    estimateLineItemDocuments,
    estimateLineItemOpeningEstimations,
  ) => {
    return new RecordsForEstimateRecord({
      estimate,
      estimateFinanceOptions,
      estimateGroups,
      estimateLineItems,
      estimateLineItemOptions,
      estimateLineItemImages,
      estimateLineItemDocuments,
      estimateLineItemOpeningEstimations,
    });
  },
);

/**
 * Fetches all the lastSavedById records associated with a specific Estimate
 *
 * @param {RootState} state
 * @param {estimateId: number} params
 * @returns {RecordsForEstimateRecord}
 */
export const lastSavedRecordsForEstimate = createSelector(
  [
    lastSavedEstimate,
    lastSavedEstimateFinanceOptions,
    lastSavedEstimateGroups,
    lastSavedEstimateLineItems,
    lastSavedEstimateLineItemOptions,
    lastSavedEstimateLineItemImages,
    lastSavedEstimateLineItemDocuments,
    lastSavedEstimateLineItemOpeningEstimations,
  ],
  (
    estimate,
    estimateFinanceOptions,
    estimateGroups,
    estimateLineItems,
    estimateLineItemOptions,
    estimateLineItemImages,
    estimateLineItemDocuments,
    estimateLineItemOpeningEstimations,
  ) => {
    return new RecordsForEstimateRecord({
      estimate,
      estimateFinanceOptions,
      estimateGroups,
      estimateLineItems,
      estimateLineItemOptions,
      estimateLineItemImages,
      estimateLineItemDocuments,
      estimateLineItemOpeningEstimations,
    });
  },
);

/**
 * compares current Estimate records against lastSavedById records and returns {true} if they are different {false} if they are the same
 *
 * @param {RootState} state
 * @param {estimateId: number} params
 * @returns {boolean}
 */
export const estimateRecordsDirty = createSelector(
  [recordsForEstimate, lastSavedRecordsForEstimate],
  (estimateRecords: RecordsForEstimateRecord, lastSavedEstimateRecords: RecordsForEstimateRecord) =>
    !estimateRecords.equals(lastSavedEstimateRecords),
);
