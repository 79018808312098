import { handleErrors } from "app2/src/reducers/Utils";
import * as jobAttributeService from "app2/src/api/jobAttribute.service";
import { RootDispatchType } from "../store";
import { ThunkResult } from "./index";
import { ActionsUnion, createAction } from "./Utils";
import { Actions as viewActions } from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { JobAttributeTypes } from "app2/src/records/OrgRecord";
import {
  JobAttribute,
  JobAttributeRecordType,
  JobAttributeLinkRecordType,
  JobAttributeLink,
} from "../records/JobAttribute";

export const FETCH_JOB_ATTRIBUTES = "@job_attribute/FETCH_JOB_ATTRIBUTES";
export const FETCH_JOB_ATTRIBUTE = "@job_attribute/FETCH_JOB_ATTRIBUTE";
export const RECEIVE_JOB_ATTRIBUTES = "@job_attribute/RECEIVE_JOB_ATTRIBUTES";
export const RECEIVE_JOB_ATTRIBUTE = "@job_attribute/RECEIVE_JOB_ATTRIBUTE";
export const RECEIVE_ACTIVE_ORG_JOB_ATTRIBUTE = "@job_attribute/RECEIVE_ACTIVE_ORG_JOB_ATTRIBUTE";
export const RECEIVE_AVAILABLE_ORG_JOB_ATTRIBUTE = "@job_attribute/RECEIVE_AVAILABLE_ORG_JOB_ATTRIBUTE";
export const REMOVE_AVAILABLE_ORG_JOB_ATTRIBUTE = "@job_attribute/REMOVE_AVAILABLE_ORG_JOB_ATTRIBUTE";
export const REMOVE_ACTIVE_JOB_ATTRIBUTE = "@job_attribute/REMOVE_JOB_ATTRIBUTE";
export const ADD_JOB_ATTRIBUTE = "@job_attribute/ADD_JOB_ATTRIBUTE";
export const RESET_JOB_ATTRIBUTE = "@job_attribute/RESET_JOB_ATTRIBUTE";
export const RECEIVE_JOB_ATTRIBUTE_ERROR = "@job_attribute/RECEIVE_JOB_ATTRIBUTE_ERROR";
export const UPDATE_FORM = "@job_attribute/UPDATE_FORM";

export const Actions = {
  fetchJobAttributes: (orgId: number, jobAttrType: JobAttributeTypes) =>
    createAction(FETCH_JOB_ATTRIBUTES, { orgId, jobAttrType }),
  fetchJobAttribute: (jobAttributeId: number | string, jobAttrType: JobAttributeTypes) =>
    createAction(FETCH_JOB_ATTRIBUTE, { jobAttributeId, jobAttrType }),
  receiveJobAttributes: (orgId: number, jobAttributes: JobAttribute[], jobAttrType: JobAttributeTypes) =>
    createAction(RECEIVE_JOB_ATTRIBUTES, { orgId, jobAttributes, jobAttrType }),
  receiveJobAttribute: (jobAttribute: Partial<JobAttribute>, orgId: number, jobAttrType: JobAttributeTypes) =>
    createAction(RECEIVE_JOB_ATTRIBUTE, { jobAttribute, orgId, jobAttrType }),
  receiveActiveOrgJobAttribute: (orgId: number, linkData: JobAttributeLink, jobAttrType: JobAttributeTypes) =>
    createAction(RECEIVE_ACTIVE_ORG_JOB_ATTRIBUTE, { orgId, linkData, jobAttrType }),
  receiveAvailableOrgJobAttribute: (orgId: number, jobAttributeId: number | string, jobAttrType: JobAttributeTypes) =>
    createAction(RECEIVE_AVAILABLE_ORG_JOB_ATTRIBUTE, { orgId, jobAttributeId, jobAttrType }),
  removeAvailableOrgJobAttribute: (orgId: number, jobAttributeId: number | string, jobAttrType: JobAttributeTypes) =>
    createAction(REMOVE_AVAILABLE_ORG_JOB_ATTRIBUTE, { orgId, jobAttributeId, jobAttrType }),
  removeActiveJobAttribute: (orgId: number, jobAttributeId: number | string, jobAttrType: JobAttributeTypes) =>
    createAction(REMOVE_ACTIVE_JOB_ATTRIBUTE, { orgId, jobAttributeId, jobAttrType }),
  updateForm: (event: { rootPath: (string | number)[]; name: string; value: string }) =>
    createAction(UPDATE_FORM, { event }),
  receiveJobAttributeErrors: (jobAttrType: JobAttributeTypes, id: number | string) =>
    createAction(RECEIVE_JOB_ATTRIBUTE_ERROR, { jobAttrType, id }),
  resetJobAttribute: (jobAttrType: JobAttributeTypes, id: number | string) =>
    createAction(RESET_JOB_ATTRIBUTE, { jobAttrType, id }),
};

export const AsyncActions = {
  getJobAttributes: (orgId: number, jobAttrType: JobAttributeTypes): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchJobAttributes(orgId, jobAttrType));
      try {
        const response = (await jobAttributeService.load(orgId, jobAttrType))[`${jobAttrType}s`];
        dispatch(Actions.receiveJobAttributes(orgId, response, jobAttrType));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  createJobAttribute: (
    orgId: number,
    object: JobAttributeRecordType,
    jobAttrType: JobAttributeTypes,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const id = object.id || 0;
      dispatch(Actions.fetchJobAttribute(id, jobAttrType));
      try {
        const response = (
          await jobAttributeService.create<jobAttributeService.ILocationResponse>(orgId, object, jobAttrType)
        )[jobAttrType];
        dispatch(Actions.receiveJobAttribute(response, orgId, jobAttrType));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.receiveJobAttributeErrors(jobAttrType, -1));
        dispatch(Actions.resetJobAttribute(jobAttrType, -1));
      }
    };
  },
  updateJobAttribute: (
    orgId: number,
    jobAttr: JobAttributeRecordType,
    jobAttrLink: JobAttributeLinkRecordType | null,
    jobAttrType: JobAttributeTypes,
    displayFlash = true,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchJobAttribute(jobAttr.id, jobAttrType));
      try {
        const response = (
          await jobAttributeService.update<jobAttributeService.ILocationResponse>(
            orgId,
            jobAttr,
            jobAttrLink,
            jobAttrType,
          )
        )[jobAttrType];
        dispatch(Actions.receiveJobAttributes(orgId, [response], jobAttrType));
        if (displayFlash) {
          dispatch(viewActions.flashAddAlert(FlashLevels.success, `Edited ${_.toTitleCase(jobAttrType)} Successfully`));
        }
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.receiveJobAttributeErrors(jobAttrType, jobAttr.get("id", 0)));
        dispatch(Actions.resetJobAttribute(jobAttrType, jobAttr.get("id", 0)));
      }
    };
  },
  activateJobAttribute: (
    orgId: number,
    jobAttributeId: number | string,
    jobAttrType: JobAttributeTypes,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchJobAttribute(jobAttributeId, jobAttrType));

      try {
        const response = (await jobAttributeService.addJobAttribute(orgId, jobAttributeId, jobAttrType))[jobAttrType];

        dispatch(Actions.receiveJobAttribute(response, orgId, jobAttrType));
        dispatch(Actions.removeAvailableOrgJobAttribute(orgId, jobAttributeId, jobAttrType));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.receiveJobAttributeErrors(jobAttrType, jobAttributeId));
      }
    };
  },
  removeJobAttribute: (
    orgId: number,
    jobAttributeId: string | number,
    jobAttrType: JobAttributeTypes,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(Actions.fetchJobAttribute(jobAttributeId, jobAttrType));

        await jobAttributeService.removeJobAttribute(orgId, jobAttributeId, jobAttrType);

        dispatch(Actions.removeActiveJobAttribute(orgId, jobAttributeId, jobAttrType));
        dispatch(Actions.receiveAvailableOrgJobAttribute(orgId, jobAttributeId, jobAttrType));

        dispatch(viewActions.flashAddAlert(FlashLevels.success, `Removed ${_.toTitleCase(jobAttrType)} Successfully`));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.receiveJobAttributeErrors(jobAttrType, jobAttributeId));
      }
    };
  },
  destroyJobAttribute: (
    orgId: number,
    jobAttributeId: string | number,
    jobAttrType: JobAttributeTypes,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(Actions.fetchJobAttribute(jobAttributeId, jobAttrType));

        await jobAttributeService.destroy(orgId, jobAttributeId, jobAttrType);

        dispatch(Actions.removeAvailableOrgJobAttribute(orgId, jobAttributeId, jobAttrType));

        dispatch(viewActions.flashAddAlert(FlashLevels.success, `Deleted ${_.toTitleCase(jobAttrType)} Successfully`));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.receiveJobAttributeErrors(jobAttrType, jobAttributeId));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
