import { connectFC } from "app2/src/connect";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import CommissionSettings from "app2/src/components/OrgTabs/SettingsTab/CommissionSettings";
import Dashboard from "app2/src/components/OrgTabs/SettingsTab/Dashboard";

export interface SettingsTabProps {
  updateSettingsConfig: (key: string, value: any) => void;
}

const SettingsTab: React.FC<SettingsTabProps> = ({ updateSettingsConfig }) => {
  return (
    <Row>
      <Col>
        <CommissionSettings updateSettingsConfig={updateSettingsConfig} />
        <Dashboard updateSettingsConfig={updateSettingsConfig} />
      </Col>
    </Row>
  );
};

export default connectFC(SettingsTab);
