import { List, Record } from "immutable";
import { defaultOpeningProps, IOpeningData, IOpeningRecord } from "app2/src/records/Opening";
import { fromJSON as openingEstimationFromJSON } from "app2/src/records/OpeningEstimation";

export const fromJSON = (json: Partial<IDoorData>): DoorRecord => {
  const recordData: IDoorRecord = { ...(json as any) };
  recordData.kind = "Door";

  if (json.opening_estimations) {
    recordData.opening_estimations = List(json.opening_estimations.map((oe) => openingEstimationFromJSON(oe)));
  }

  return new DoorRecord(recordData);
};

export type IDoorData = IOpeningData;

export type IDoorRecord = IOpeningRecord;

export class DoorRecord extends Record(defaultOpeningProps) implements IDoorRecord {}
