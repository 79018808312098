import * as ng from "angular";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IJnUser extends ng.resource.IResource<IJnUser>, JnUserPrototype {
  $update(): ng.IPromise<IJnUser>;
}

export interface IJnUserResource extends ng.resource.IResourceClass<IJnUser>, IRsfResource {
  fromJSON?(data: any): IJnUser;
}

class JnUserPrototype {
  public classy: string;
  public id: number;
  public user_id: number;
  public email: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/jn_users/:id";

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta: any = angular.fromJson(response);

    _.each(meta.crm_users, (jn, index) => {
      meta.crm_users[index] = JnUser.fromJSON(jn);
    });

    return meta;
  };

  const transformSingle = (data) => {
    const jsonData = angular.fromJson(data);

    return JnUser.fromJSON(jsonData.crm_user);
  };

  const JnUser: IJnUserResource = <IJnUserResource>$resource(
    url,
    { org_id: "@org_id", id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingle,
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultiple,
        isArray: false,
      },
    },
  );

  JnUser.fromJSON = (data: any) => {
    const ms = new JnUser(data);
    ms.classy = "JnUser";

    return ms;
  };

  _.hiddenExtend(JnUser.prototype, JnUserPrototype.prototype);

  JnUser.inject = (injected: IRepository) => {
    resources = injected;
  };

  return JnUser;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
