import * as ng from "angular";
import { IJob } from "app/src/Models/Job";
import { IEstimateGroup, EstimateGroup } from "app/src/Models/EstimateGroup";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { IDiscount, Discount } from "app/src/Models/Discount";
import { IImage } from "app/src/Models/Image";
import { IDoc } from "app/src/Models/Doc";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";
import { AddEstimateLineItemOptionCmd } from "../Commands/Estimator/AddEstimateLineItemOptionCmd";
import {
  fromJSON as paymentTermsFromJSON,
  toJSON as paymentTermsToJSON,
  memoizedCalculatePaymentTerms,
  PaymentTermRecord,
} from "app2/src/records/PaymentTerm";
import { useSelector, dispatch, useState } from "app2/src/storeRegistry";
import { validateLineItem } from "app/src/Estimator/ValidationService";
import {
  EstimateFinanceOptionRecord,
  IEstimateFinanceOptionData,
  fromJSON as efoFromJSON,
  toJSON as estimateFinanceOptionsToJSON,
} from "app2/src/records/EstimateFinanceOption";
import { financeOptionCleanProps } from "app2/src/api/estimate.service";
import { IEstimateData, getTimezoneString } from "app2/src/records/Estimate";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { updateEstimateFinanceOptionPayments } from "app2/src/selectors/estimateFinanceOption.selectors";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { List } from "immutable";
import { isDiscountable } from "app2/src/records/EstimateLineItem";

export type EstimateKind = "price" | "inspection" | "survey";

export interface IEstimate extends ng.resource.IResource<IEstimate>, EstimatePrototype, ISalesTaxable {
  id: number;

  $pdf(...args: any[]): ng.IPromise<Blob>;
  $csv(...args: any[]): ng.IPromise<Blob>;
  $email(...args: any[]): ng.IPromise<boolean>;
  $ms_upload(...args: any[]): ng.IPromise<boolean>;
}

export interface IEstimateResource extends ng.resource.IResourceClass<IEstimate>, IRsfResource {
  create(data: any): IEstimate;
  createNew(data: any): IEstimate;
  fromJSON?(data: any): IEstimate;
  clone?(estimate: IEstimate): IEstimate;
  pattern?(data?: any): IEstimate;
  pdf?(params: any): ng.resource.IResource<IEstimate>;
  csv?(params: any): ng.resource.IResource<IEstimate>;
  email?(params: any): ng.resource.IResource<IEstimate>;
  transformRequest?(estimate: IEstimate): any;
  ms_upload(...args: any[]): ng.resource.IResource<boolean>;
}

export interface ISalesTaxable {
  tax_rate: number;
  taxable_amount: number;
  default_taxable_amount: string;
  tax_total: number;
}

export interface IDefaults {
  options: { string: IDefaultOption };
}

export interface IDefaultOption {
  option_group_uuid: string;
  quantity: number;
}

export interface IEstimateMarkups {
  product_markup: number;
  labor_markup: number;
}

export class EstimatePrototype {
  public classy: string;
  public id: number;
  public activated_price_list_id: number;
  public customer_name: string;
  public kind: "default" | "inspection" | "survey";
  public name: string;
  public org_uid: string;
  public uid: string;
  // noinspection JSUnusedGlobalSymbols
  public customer_phone: string;
  // noinspection JSUnusedGlobalSymbols
  public payment_term_id: number;
  public payment_terms: PaymentTermRecord;
  public estimated_start: Date;
  public estimated_end: Date;
  public groups: IEstimateGroup[];
  public discounts: IDiscount[];
  public finance_options: EstimateFinanceOptionRecord[];
  public job_id: number;
  public job: IJob;
  public discount: number;
  public subtotal: number;
  public total: number;
  public labor_total: number;
  public product_total: number;
  public discountable_total: number;
  public created_at: Date;
  public updated_at: Date;
  public line_items_count?: number;
  public tax_rate = 0.0;
  public tax_total = 0.0;
  public taxable_amount = 0.0;
  public default_taxable_amount: string;
  public defaults: IDefaults;
  public compare: boolean;
  public product_markup: number;
  public labor_markup: number;

  public $saveOrCreate(params?: any, callback?) {
    if (this.id <= 0 || (this.id as any as string) === "new") {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$save(params, callback);
    }
  }

  public estimateGroups(included: boolean) {
    return _.filter(this.existingGroups(), (g) => {
      return g.included === included;
    });
  }

  public existingGroups() {
    return _.filter(this.groups, (g) => {
      return g._destroy !== true;
    });
  }

  public createGroup(params) {
    if (!_.isArray(this.groups)) {
      this.groups = [];
    }
    if (!params["id"]) {
      params["id"] = this.getId();
    }
    if (!params["sort_order"]) {
      params["sort_order"] = _.getSortOrder(this.groups);
    }
    if (params["rooms"]) {
      params.room_estimations = params.rooms.map((r) => ({
        room_id: r.id,
        estimate_group_id: params.id,
      }));
      delete params.rooms;
    }
    const estimate_group = new EstimateGroup(this as any as IEstimate, params);
    this.groups.push(estimate_group);
    this.orderGroups();
    return estimate_group;
  }

  public cloneSalesTax(): ISalesTaxable {
    return {
      tax_rate: this.tax_rate,
      taxable_amount: this.taxable_amount,
      default_taxable_amount: this.default_taxable_amount,
      tax_total: this.tax_total,
    };
  }

  public orderGroups() {
    this.groups = _.chain(this.groups)
      .groupBy((g) => {
        return g.included;
      })
      .pairs()
      .sortBy((a) => {
        return (a[0] as any as string) === "true" ? 0 : 1;
      })
      .map((a) => {
        return a[1];
      })
      .flatten()
      .each((g: IEstimateGroup, idx: number) => {
        g.sort_order = idx;
      })
      .value();
  }

  public lineItems(groups: IEstimateGroup[] = this.groups): IEstimateLineItem[] {
    return _.chain(groups)
      .select((g: IEstimateGroup) => {
        return !g._destroy;
      })
      .map((g: IEstimateGroup) => {
        return g.line_items;
      })
      .flatten()
      .select((li: IEstimateLineItem) => {
        return !li._destroy;
      })
      .value();
  }

  public hidden_line_items(): boolean {
    const idx = _.findIndex(this.lineItems(), (li) => {
      return !li.isVisible(false);
    });
    return idx >= 0;
  }

  public decycleToJSON(): any {
    return JSON.decycle(this);
  }

  public toEstimateDataJSON(): IEstimateData {
    const data = this.decycleToJSON();

    if (this.payment_terms) {
      data.payment_terms = paymentTermsToJSON(this.payment_terms);
    }

    if (this.groups) {
      _.each(this.groups, (group, idx) => {
        data.groups[idx] = group.decycleToJSON();
      });
    }

    if (this.finance_options) {
      _.each(this.finance_options, (fo: EstimateFinanceOptionRecord, index) => {
        data.finance_options[index] = estimateFinanceOptionsToJSON(fo);
      });
    }

    return data;
  }

  public updateSalesTax(salesTax: ISalesTaxable): void {
    if (!_.isNull(salesTax.tax_rate) && !_.isUndefined(salesTax.tax_rate)) {
      this.tax_rate = salesTax.tax_rate;
    }
    if (!_.isNull(salesTax.taxable_amount) && !_.isUndefined(salesTax.taxable_amount)) {
      this.taxable_amount = salesTax.taxable_amount;
    }
    if (!_.isNull(salesTax.default_taxable_amount) && !_.isUndefined(salesTax.default_taxable_amount)) {
      this.default_taxable_amount = salesTax.default_taxable_amount;
    }

    this.updateTotal();
  }

  public updateTaxTotal(): number {
    if (this.taxable_amount && this.tax_rate) {
      this.tax_total = _.round(this.taxable_amount * (this.tax_rate / 100.0), -2);
    } else {
      this.tax_total = 0.0;
    }

    return this.tax_total;
  }

  public updateTotal(): number {
    const totals = _.chain(this.existingGroups())
      .select((g: IEstimateGroup) => {
        return g.included;
      })
      .map((g: IEstimateGroup) => {
        return g.line_items;
      })
      .flatten()
      .compact()
      .reduce(
        (sum: any, item: IEstimateLineItem) => {
          if (item._destroy) {
            return sum;
          }
          if (item.product_id) {
            const val = validateLineItem(useState(), this, item);
            item.validation_result = val.validation_result;
            item.validated = val.validated;
          }
          sum.total = _.round(sum.total + item.ext_price, -2);
          sum.labor = sum.labor + _.round(item.quantity * item.labor_price, -2);
          sum.product = sum.product + _.round(item.quantity * item.product_price, -2);
          if (isDiscountable(item)) {
            sum.discountable = _.round(sum.discountable + item.ext_price, -2);
          }
          _.each(item.options, (option: IEstimateLineItemOption) => {
            if (option._destroy || option.pog_type === "cost") {
              return;
            }
            option.calculate(this);
            sum.total = _.round(sum.total + option.ext_price, -2);
            sum.labor = sum.labor + option.calculateLaborPrice();
            sum.product = sum.product + option.calculateProductPrice();
            if (isDiscountable(item)) {
              sum.discountable = sum.discountable + option.ext_price;
            }
          });

          return sum;
        },
        { total: 0, labor: 0, product: 0, discountable: 0 },
      )
      .value();
    this.subtotal = totals.total;
    this.labor_total = totals.labor;
    this.product_total = totals.product;
    this.discountable_total = totals.discountable;

    this.discount = _.reduce(
      this.discounts,
      (memo: number, discount: IDiscount) => {
        this.discountable_total = discount.perform(this.discountable_total);
        return memo + discount.calculated;
      },
      0,
    );

    // Discounts Come Off Product Total
    this.product_total = this.product_total - this.discount;

    switch (this.default_taxable_amount) {
      case "product":
        this.taxable_amount = this.product_total;
        break;
      case "labor":
        this.taxable_amount = this.labor_total;
        break;
      case "product_labor":
        this.taxable_amount = this.product_total + this.labor_total;
        break;
    }

    this.taxable_amount = _.max([this.taxable_amount, 0]);

    this.updateTaxTotal();

    this.total = _.round(this.product_total + this.labor_total + this.tax_total, -2);

    if (this.payment_terms) {
      this.payment_terms = memoizedCalculatePaymentTerms(this.payment_terms, this.total);
    }

    this.updateEstimateFinanceOptionPayments();

    _.each(this.existingGroups(), (g) => {
      g.updateTotal();
    });

    return this.total;
  }

  public updateEstimateFinanceOptionPayments(): void {
    if (this.finance_options) {
      const financeOptionIds = List(_.pluck(this.finance_options, "finance_option_id"));
      const financedAmount = this.payment_terms.financed_amount;
      dispatch(
        estimateActions.AsyncActions.calculateCalculatedFinancing(
          this as unknown as IEstimate,
          financedAmount,
          financeOptionIds,
          false,
        ),
      );
      _.each(this.finance_options, (fo, index) => {
        this.finance_options[index] = useSelector((state) =>
          updateEstimateFinanceOptionPayments(state, {
            estimateFinanceOptionId: fo.id,
            financedAmount,
          }),
        );
      });
    }
  }

  public getId(): number {
    const firstId = -1;

    const currentMin = _.chain(this.groups).pluck("id").min().value();

    return _.min([firstId, currentMin - 1]);
  }

  public getSortOrder(): number {
    const firstSO = 0;

    const currentMax = _.chain(this.groups).pluck("sort_order").max().value();

    return _.max([firstSO, currentMax + 1]);
  }

  public getLineItemId(): number {
    const firstId = -1;

    const currentMin = _.chain(this.lineItems()).pluck("id").min().value();

    return _.min([firstId, currentMin - 1]);
  }

  public editing(): boolean {
    return _.any(this.lineItems(), (lineItem) => {
      return lineItem.editing;
    });
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/estimates/:id";

  const requestTransform = (estimate: IEstimate): string => {
    const data: any = JSON.parse(angular.toJson(estimate.decycleToJSON()));
    data.groups_attributes = data.groups;
    data.estimate_finance_options_attributes = data.finance_options;

    if (data.estimated_start instanceof Date) {
      data.estimated_start = (data.estimated_start as Date).toISOString();
    }
    if (typeof data.estimated_start === "string") {
      data.estimated_start = data.estimated_start.split("T")[0];
    }

    if (data.estimated_end instanceof Date) {
      data.estimated_end = (data.estimated_end as Date).toISOString();
    }
    if (typeof data.estimated_start === "string") {
      data.estimated_end = data.estimated_end.split("T")[0];
    }

    if (data.job) {
      delete data.job;
    }

    // handle line items that have moved groups
    _.each(estimate.lineItems(), (li) => {
      if (!li.orig_estimate_group_id || li.orig_estimate_group_id === li.estimate_group_id) {
        return;
      }

      const originalGroup: any = _.find(data.groups_attributes, (ga: any) => {
        return li.orig_estimate_group_id === ga.id;
      });
      let groupDeleteIndex = -1;
      _.find(data.groups_attributes, (ga: any, index: number) => {
        groupDeleteIndex = index;
        return li.estimate_group_id === ga.id;
      });
      if (originalGroup && groupDeleteIndex >= 0) {
        originalGroup.line_items.push(li.decycleToJSON());
        data.groups_attributes[groupDeleteIndex].line_items = _.filter(
          data.groups_attributes[groupDeleteIndex].line_items,
          (liData: any) => {
            return liData.id !== li.id;
          },
        );
      }
    });

    _.each(data.groups_attributes, (ga, index) => {
      data.groups_attributes[index] = EstimateGroup.transformRequest(ga);
    });

    _.each(estimate.finance_options, (fo: EstimateFinanceOptionRecord, index) => {
      data.estimate_finance_options_attributes[index] = financeOptionCleanProps(fo.toJS());
    });

    delete data.groups;
    delete data.finance_options;
    delete data.job;
    delete data.url;
    delete data.id;
    delete data.line_items_count;
    delete data.$promise;
    delete data.$resolved;

    if (estimate.payment_terms) {
      data.payment_terms = paymentTermsToJSON(estimate.payment_terms);
    }

    if (estimate.discounts && estimate.discounts.length > 0) {
      data.discounts = _.map(estimate.discounts, (d: IDiscount) => {
        return d.toJsonObj();
      });
    }

    const requestData = { estimate: data };
    if (estimate["include"]) {
      requestData["include"] = estimate["include"];
    }

    return JSON.stringify(requestData);
  };

  const responseSingleTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IEstimate => {
    if (status < 200 || status > 299) {
      return new Estimate({});
    }

    const data = JSON.parse(response);
    return Estimate.fromJSON(data.estimate);
  };

  //noinspection TypeScriptValidateTypes
  const Estimate: IEstimateResource = <IEstimateResource>$resource(
    url,
    { id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: responseSingleTransform,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: BaseConfig.BASE_URL + "/api/v1/jobs/:job_id/estimates",
        params: {
          job_id: "@job_id",
        },
        transformRequest: requestTransform,
        transformResponse: responseSingleTransform,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: BaseConfig.BASE_URL + "/api/v1/jobs/:job_id/estimates/:id",
        params: {
          job_id: "@job_id",
        },
        transformRequest: requestTransform,
        transformResponse: responseSingleTransform,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        url: BaseConfig.BASE_URL + "/api/v1/jobs/:job_id/estimates/:id",
        params: {
          job_id: "@job_id",
        },
      },
      pattern: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + ".pattern",
        transformResponse: function (data: string, headers: ng.IHttpHeadersGetter, status: number) {
          if (status < 200 || status > 299) {
            return { response: null };
          }

          return JSON.parse(data);
        },
        isArray: false,
      },
      pdf: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + ".pdf",
        headers: {
          accept: "application/pdf",
        },
        params: {
          doc_type: "@doc_type",
          options: "@options",
        },
        paramSerializer: "$httpParamSerializerJQLike",
        responseType: "arraybuffer",
        transformResponse: function (data: string, headers: ng.IHttpHeadersGetter, status: number) {
          if (status < 200 || status > 299) {
            return { response: null };
          }

          let pdf;
          if (data) {
            pdf = new Blob([data], {
              type: "application/pdf",
            });
          }
          return {
            response: pdf,
          };
        },
      },
      csv: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + ".csv",
        headers: {
          accept: "text/csv",
        },
        params: {
          csv_type: "@csv_type",
        },
        responseType: "arraybuffer",
        transformResponse: function (data: string, headers: ng.IHttpHeadersGetter, status: number) {
          if (status < 200 || status > 299) {
            return { response: null };
          }

          let csv;
          if (data) {
            csv = new Blob([data], {
              type: "text/csv",
            });
            return {
              response: csv,
            };
          }
        },
      },
      email: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/email",
        params: {
          doc_type: "@doc_type",
        },
        transformRequest: function (data) {
          return JSON.stringify({
            emails: data.emails,
            doc_type: data.doc_type,
            message: data.message,
            options: data.options,
          });
        },
      },
      ms_upload: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/ms_upload",
        params: {
          doc_type: "@doc_type",
        },
        transformRequest: function (data) {
          return JSON.stringify({ doc_type: data.doc_type });
        },
      },
    },
  );

  Estimate.fromJSON = (data: any): IEstimate => {
    data.classy = "Estimate";
    if (data.estimated_start && typeof data.estimated_start === "string") {
      const timezoneDate = new Date(data.estimated_start);
      data.estimated_start = new Date(
        `${data.estimated_start}T00:00:00.000${getTimezoneString(timezoneDate.getTimezoneOffset())}`,
      );
    }
    if (data.estimated_end && typeof data.estimated_end === "string") {
      const timezoneDate = new Date(data.estimated_end);
      data.estimated_end = new Date(
        `${data.estimated_end}T00:00:00.000${getTimezoneString(timezoneDate.getTimezoneOffset())}`,
      );
    }
    if (data.created_at) {
      data.created_at = new Date(data.created_at as string);
    } else {
      data.created_at = new Date();
    }
    if (data.updated_at) {
      data.updated_at = new Date(data.updated_at as string);
    } else {
      data.updated_at = new Date();
    }

    if (data.job) {
      data.job = resources.Job.fromJSON(data.job);
    }
    if (data.payment_terms) {
      data.payment_terms = paymentTermsFromJSON(data.payment_terms);
    } else {
      data.payment_terms = paymentTermsFromJSON({});
    }

    if (data.finance_options) {
      _.each(data.finance_options, (fo: IEstimateFinanceOptionData, index) => {
        data.finance_options[index] = efoFromJSON(fo);
      });
    } else {
      data.finance_options = [];
    }

    if (!data.defaults) {
      data.defaults = { options: {} as { string: IDefaultOption } };
    }

    data.total = _.parseFloat(data.total);
    data.subtotal = _.parseFloat(data.subtotal);
    data.discount = _.parseFloat(data.discount);

    const estimate = new Estimate(data);

    const doOrder = !_.any(estimate.groups, (item: IEstimateGroup) => {
      return !!item.sort_order;
    });

    _.each(estimate.groups, (g, index) => {
      estimate.groups[index] = EstimateGroup.fromJSON(estimate, g);

      if (doOrder) {
        estimate.groups[index].sort_order = index;
      }

      _.each(estimate.groups[index].line_items, (li: IEstimateLineItem) => {
        _.each(li.images, (image: IImage, index) => {
          li.images[index] = resources.Image.fromJSON(image);
        });
        _.each(li.documents, (doc: IDoc, index) => {
          li.documents[index] = resources.Doc.fromJSON(doc);
        });
      });
    });

    if (!estimate.discounts) {
      estimate.discounts = [];
    } else if (_.isArray(estimate.discounts)) {
      estimate.discounts = _.map(estimate.discounts, (d: any) => {
        return Discount.fromJSON(d);
      });
    }

    return estimate;
  };

  Estimate.clone = (estimate: IEstimate): IEstimate => {
    return Estimate.fromJSON(estimate.decycleToJSON());
  };

  Estimate.createNew = (data: any): IEstimate => {
    const newE = new Estimate(data);
    if (!newE.payment_terms) {
      newE.payment_terms = paymentTermsFromJSON({});
    }

    if (!newE.discounts) {
      newE.discounts = [];
    }

    if (!newE.groups) {
      newE.groups = [];
    }

    if (!newE.finance_options) {
      newE.finance_options = [];
    }

    newE.defaults = { options: {} as { string: IDefaultOption } };

    newE.labor_markup = 0;
    newE.product_markup = 0;

    return newE;
  };

  Estimate.transformRequest = (estimate: IEstimate) => {
    return requestTransform(estimate);
  };

  _.hiddenExtend(Estimate.prototype, EstimatePrototype.prototype);

  Estimate.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Estimate;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;

export const AddLineItemOption = (
  eli: IEstimateLineItem,
  option: ProductOptionRecord,
  optionGroup: ProductOptionGroupRecord,
  quantity = 0,
  estimate: IEstimate,
): IEstimateLineItem => {
  if (optionGroup.selection_mode === "single") {
    const elio = eli.existingSingleSelectOption(optionGroup.id);
    if (elio !== null) {
      eli = RemoveLineItemOption(eli, elio.product_option_uuid);
    }
  }

  const cmd_po = new AddEstimateLineItemOptionCmd(option, optionGroup, eli, estimate);
  cmd_po.execute(quantity);

  return eli;
};

export const RemoveLineItemOption = (eli: IEstimateLineItem, option_uuid: string): IEstimateLineItem => {
  const toRemove = eli.existingEliForProductOption(option_uuid);

  if (toRemove.id && toRemove.id > 0) {
    toRemove._destroy = true;
  } else {
    eli.options = _.select(eli.options, (elio: IEstimateLineItemOption) => {
      return elio._destroy === true || elio.product_option_uuid !== option_uuid;
    });
  }

  return eli;
};
