import { analyticsFetcher } from "app2/src/helpers/Fetcher";
import {
  CardConnectAuthorizationRecord,
  ICardConnectAuthorizationData,
} from "app2/src/records/CardConnectAuthorization";
import { IMakePaymentData } from "app2/src/records/PaymentRecord";

export interface AuthorizationResponse {
  authorized: boolean;
  error?: string;
}

const baseUrl = "payments/v1/cardconnect";

export const getAuthorization = (orgId: number): Promise<ICardConnectAuthorizationData> => {
  const url = analyticsFetcher.joinUrls(baseUrl, orgId.toString(), "authorization");
  return analyticsFetcher.get<ICardConnectAuthorizationData>(url);
};

export const authorize = (
  authorizationRecord: CardConnectAuthorizationRecord,
  orgId: number,
): Promise<AuthorizationResponse> => {
  const url = analyticsFetcher.joinUrls(baseUrl, orgId.toString(), "authorization");
  return analyticsFetcher.post<AuthorizationResponse>(url, {
    payload: {
      ...authorizationRecord.toJS(),
    },
  });
};

export const makePayment = (
  orgId: number,
  token: string,
  accountInfo: any,
  payment: IMakePaymentData,
): Promise<any> => {
  const url = analyticsFetcher.joinUrls(baseUrl, orgId.toString(), "payment");
  return analyticsFetcher.post<any>(url, {
    payment,
    token,
    customer: accountInfo,
  });
};
