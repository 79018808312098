import * as React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { titleCaseString } from "app2/src/services/string.service";

export interface InlineRadioGroupProps {
  title: string;
  dataName: string;
  register: any;
  keys: any[];
  formState?: any;
  imageSrc?: string;
  updateSelection?: () => void;
}

export const InlineRadioGroup = (props: InlineRadioGroupProps) => {
  const { title, imageSrc, keys, updateSelection, register, dataName, formState } = props;

  const { dirtyFields, touched } = formState;

  return (
    <div className="form-section">
      <div className="form-section-heading">
        <Row className="inline-radio-group">
          <Col md={4}>
            <h3>{title}</h3>
          </Col>
          <Col md={8}>
            <Form.Group controlId={dataName}>
              {keys.map((item, index) => {
                return (
                  <Form.Check
                    name={dataName}
                    inline
                    id={`${title}-${item}-${index}`}
                    key={index}
                    type="radio"
                    onClick={updateSelection}
                    label={titleCaseString(item)}
                    value={item}
                    ref={register}
                  />
                );
              })}
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="form-section-content">
        {imageSrc && (touched[dataName] || dirtyFields.has(dataName)) ? (
          <Row>
            <Col className="text-center">
              <h4>Preview</h4>
              <img className="img-responsive" src={imageSrc} />
            </Col>
          </Row>
        ) : null}
      </div>
    </div>
  );
};
