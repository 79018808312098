import { fetcher } from "../helpers/Fetcher";
import { IEstimateComparisonData, EstimateComparisonRecord, toJSON } from "../records/EstimateComparison";

interface IEstimateComparisonResponse {
  estimate_comparison: IEstimateComparisonData;
}

export const url = "api/v1/jobs/:job_id/estimate_comparisons";

export const loadByJob = async (jobId: number): Promise<IEstimateComparisonData> => {
  const actionUrl = url.replace(":job_id", jobId.toString());
  const resp = await fetcher.get<IEstimateComparisonResponse>(actionUrl);
  return resp.estimate_comparison;
};

export const create = async (estimateComparison: EstimateComparisonRecord): Promise<IEstimateComparisonData> => {
  const actionUrl = url.replace(":job_id", estimateComparison.job_id.toString());

  const resp = await fetcher.post<IEstimateComparisonResponse>(actionUrl, {
    estimate_comparison: cleanProps(toJSON(estimateComparison)),
  });
  return resp.estimate_comparison;
};

export const update = async (estimateComparison: EstimateComparisonRecord): Promise<IEstimateComparisonData> => {
  const tempUrl = url.replace(":job_id", estimateComparison.job_id.toString());
  const actionUrl = fetcher.joinUrls(tempUrl, estimateComparison.id.toString());
  const resp = await fetcher.patch<IEstimateComparisonResponse>(actionUrl, {
    estimate_comparison: cleanProps(toJSON(estimateComparison)),
  });
  return resp.estimate_comparison;
};

export const cleanProps = (ecl: IEstimateComparisonData): any => {
  if (ecl.id === 0) {
    delete ecl.id;
  }

  if (ecl.estimate_comparison_links) {
    (ecl as any).estimate_comparison_links_attributes = ecl.estimate_comparison_links.map((link) => {
      if (link.id === 0) {
        delete link.id;
      }
      return link;
    });
    delete ecl.estimate_comparison_links;
  }
  return ecl;
};
