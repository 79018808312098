export class Deferred<T> {
  public resolved = false;

  public get promise(): Promise<T> {
    return this._promise;
  }

  private _reject: (value: T) => void;
  private _resolve: (value: T) => void;
  private _promise: Promise<T>;

  private constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public resolve(value: T) {
    this.resolved = true;
    this._resolve(value);
  }

  public reject(value: T) {
    this.resolved = true;
    this._reject(value);
  }
  public static defer<T>(): Deferred<T> {
    return new Deferred<T>();
  }
}
