import * as poolActions from "./pool.actions";
import { Map, Record } from "immutable";
import { PoolRecord, fromJSON } from "../records/Pool";
import { RootActions, RootState } from ".";

export const PoolStateRecord = Record({
  byId: Map<number, PoolRecord>(),
  lastSavedById: Map<number, PoolRecord>(),
  unsavedId: -1,
});

export const initialState = PoolStateRecord();

export type PoolState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "pools";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let pool: PoolRecord;
  switch (action.type) {
    case poolActions.RECEIVE_POOL:
      pool = fromJSON({ ...action.payload.pool });

      return state.setIn([model, "byId", pool.id], pool).setIn([model, "lastSavedById", pool.id], pool);
    case poolActions.EDIT_POOL:
      pool = fromJSON({ ...action.payload.pool });

      return state.setIn([model, "byId", pool.id], pool);

    default:
      return state;
  }
};
