import { Record } from "immutable";

export interface ILocationState {
  pathname: string;
  search: string;
  hash: string;
}

export const defaultLocationStateProps = {
  pathname: "",
  search: "",
  hash: "",
};

export class LocationStateRecord extends Record<ILocationState>(defaultLocationStateProps) implements ILocationState {}
export const emptyLocationStateValue = new LocationStateRecord();
