import { List } from "immutable";
import { nextNewId } from "app2/src/helpers/Record";
import { FinanceOptionRecord, fromJSON } from "app2/src/records/FinanceOption";

export const createNew = (
  financeOptions: List<FinanceOptionRecord>,
): { newId: number; financeOptions: List<FinanceOptionRecord> } => {
  const { newId, newSortOrder } = nextNewId(financeOptions);
  const newFinanceOptions = financeOptions.push(fromJSON({ id: newId, sort_order: newSortOrder }));
  return { newId: newId, financeOptions: newFinanceOptions };
};
