import { ICustomProduct } from "app/src/Models/Product";
import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { ICommand } from "app/src/Commands/Command";
import { AddEstimateLineItemCmd } from "app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";

export class AddProductFromXlsxCmd implements ICommand {
  public lineItems: IEstimateLineItem[] = [];
  public marvinValues: string[] = ["Product", "Quantity", "Brand", "Series", "Extended Net Price", "Size"];
  constructor(
    public estimate: IEstimate,
    public xlsx_data: string[][],
    public group: IEstimateGroup,
    public EstimatorService: EstimatorService,
  ) {}

  public execute(): boolean {
    const products = this.xlsx_type(this.xlsx_data);
    if (products.length < 1) {
      return false;
    }
    _.each(products, (product) => {
      const cmd = new AddEstimateLineItemCmd(this.estimate, product, this.group, this.EstimatorService);
      cmd.execute();
      cmd.lineItem.newly_added = false;
      this.lineItems.push(cmd.lineItem);
    });

    return true;
  }

  private xlsx_type(data): ICustomProduct[] {
    let products = [];
    if (this.isMarvin(data)) {
      products = this.parseMarvin(data);
    }
    return products;
  }

  private parseMarvin(data): ICustomProduct[] {
    const columns = {};
    _.each(this.marvinValues, (v) => {
      columns[v] = _.indexOf(data[0], v);
    });
    const products = [];
    _.each(data, (row, i) => {
      if (i === 0) {
        return;
      }
      products.push({
        name: row[columns["Brand"]] + " " + row[columns["Series"]] + " " + row[columns["Product"]],
        description: row[columns["Size"]],
        quantity: _.parseFloat(row[columns["Quantity"]]),
        product_price: _.round(
          _.parseFloat(row[columns["Extended Net Price"]]) / _.parseFloat(row[columns["Quantity"]]),
          -2,
        ),
        labor_price: 0.0,
        uom: "ea",
      });
    });
    return products;
  }

  private isMarvin(data): boolean {
    const diff = _.difference(this.marvinValues, data[0]);
    return diff.length === 0;
  }
}
