import { ActivatedPriceListRecord } from "app2/src/records/ActivatedPriceList";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { RootState } from "app2/src/reducers";
import { activatedPriceList } from "app2/src/selectors/activatedPriceList.selectors";
import { product } from "app2/src/selectors/product.selectors";
import { productOptionGroupByMatchId, productOptionGroups } from "app2/src/selectors/productOptionGroup.selectors";
import { List } from "immutable";
import { createSelector } from "reselect";

export const productOption = (state: RootState | ActivatedPriceListRecord, props: any) =>
  state.getIn(["productOptions", "byId", props.productOptionId]);

export const cachedProductOption = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): ProductOptionRecord => {
    return productOption(activatedPriceList, props);
  },
);

export const cachedProductOptions = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): List<ProductOptionRecord> => {
    return productOptions(activatedPriceList, props);
  },
);

export const productOptions = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<ProductOptionRecord> => {
    const { state, props } = stateProps;
    const { productOptionIds } = props;
    return productOptionIds.map((id: number) => productOption(state, { productOptionId: id }));
  },
);

export const cachedProductOptionByMatchId = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): ProductOptionRecord => {
    const { productId, productOptionGroupMatchId, productOptionMatchId } = props;
    const prod = product(activatedPriceList, { productId });
    const group = productOptionGroupByMatchId(activatedPriceList, {
      productOptionGroupMatchId,
      productOptionGroupIds: prod.option_group_ids,
    });
    if (_.isNullOrUndefined(group)) {
      return;
    }
    return productOptions(activatedPriceList, { productOptionIds: group.option_ids }).find(
      (opt) => opt.match_id === productOptionMatchId,
    );
  },
);

export const cachedProductOptionByMatchIdWithIds = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): ProductOptionRecord => {
    const { productOptionIds, productOptionMatchId } = props;
    return productOptions(activatedPriceList, { productOptionIds }).find(
      (opt) => opt.match_id === productOptionMatchId,
    );
  },
);

export const cachedProductOptionsByUuid = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): List<ProductOptionRecord> => {
    const { productId, optionUuids } = props;
    const prod = product(activatedPriceList, { productId });
    const groups = productOptionGroups(activatedPriceList, {
      productOptionGroupIds: prod.option_group_ids,
    });
    const productOptionIds = groups.map((group) => group.option_ids).flatten();
    return productOptions(activatedPriceList, { productOptionIds }).filter((opt) => optionUuids.includes(opt.uuid));
  },
);
