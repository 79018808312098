import { IOrgFetcherService } from "../OrgFetcherService";
import { IEmailResource } from "../../Models/Email";
import { BaseOrgTabCtrl } from "./BaseOrgTabCtrl";
import * as angulartics from "angulartics";
import { IPretty } from "../../Common/PrettyNameService";

export class OrgEmailsTabCtrl extends BaseOrgTabCtrl {
  public static $inject = ["OrgFetcher", "Email", "$stateParams", "$analytics", "Pretty"];
  constructor(
    OrgFetcher: IOrgFetcherService,
    public Email: IEmailResource,
    public $stateParams: ng.ui.IStateParamsService,
    protected $analytics: angulartics.IAnalyticsService,
    public Pretty: IPretty,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);
  }

  public toggleEventDisplay(email) {
    if (email.events.length > 0) {
      email._display = !email._display;
    }
  }
}
