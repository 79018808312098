import { fetcher } from "app2/src/helpers/Fetcher";
import { IFinanceApplicationData } from "app2/src/records/FinanceApplication";
import * as config from "react-global-configuration";
import { IMetaPaginationData } from "../records/MetaPagination";
import { QueryParamsRecord } from "../records/Page";

export interface IFinanceApplicationResponse {
  finance_application: IFinanceApplicationData;
}

export interface IFinanceApplicationIndexResponse {
  finance_applications: IFinanceApplicationData[];
  meta: { pagination: IMetaPaginationData };
}

export interface ISelectOfferResponse {
  url: string;
}

export interface ISelectOfferParameters {
  finance_option_id: number;
}

const url = "api/v1/estimates/:id/finance_application";
const financeApplicationUrl = "api/v1/finance_applications/:id";

export const load = (estimateId: number): Promise<IFinanceApplicationResponse> => {
  const actionUrl = url.replace(":id", estimateId.toString());
  return fetcher.get<IFinanceApplicationResponse>(actionUrl);
};

export const create = (
  estimateId: number,
  financeApplication: Partial<IFinanceApplicationData>,
): Promise<IFinanceApplicationResponse> => {
  const actionUrl = url.replace(":id", estimateId.toString());
  return fetcher.post<IFinanceApplicationResponse>(actionUrl, transformPostData(financeApplication));
};

export const loadByJob = (jobId: number, queryParams: QueryParamsRecord): Promise<IFinanceApplicationIndexResponse> => {
  const actionUrl = "api/v1/finance_applications";
  const params = queryParams.toJSON() as any;
  params.job_id = jobId;
  return fetcher.get<IFinanceApplicationIndexResponse>(actionUrl, params);
};

/**
 * Triggers the recalculate logic for FinMkt.
 *
 * @param estimateId - ID of the estimate for the financeApplication
 * @returns Promise<void> - success is a status of 204
 */
export const recalculate = (estimateId: number) => {
  const actionUrl = fetcher.joinUrls(url.replace(":id", estimateId.toString()), "recalculate");

  return fetcher.post<void>(actionUrl);
};

export const selectOffer = (financeApplicationId: number, params: ISelectOfferParameters) => {
  const actionUrl = fetcher.joinUrls(
    financeApplicationUrl.replace(":id", financeApplicationId.toString()),
    "select_offer",
  );

  return fetcher.patch<ISelectOfferResponse>(actionUrl, params);
};

export interface CallbackUrls {
  callbackUrl: string;
  failureUrl: string;
  successUrl: string;
  preQualSuccessUrl: string;
  preQualFailureUrl: string;
}

export const callbackUrls = (): CallbackUrls => {
  return {
    callbackUrl: config.get("HOMEVEST_CALLBACK"),
    failureUrl: config.get("HOMEVEST_FAILURE"),
    successUrl: config.get("HOMEVEST_SUCCESS"),
    preQualSuccessUrl: config.get("HOMEVEST_PREQUALSUCCESS"),
    preQualFailureUrl: config.get("HOMEVEST_PREQUALFAILURE"),
  };
};

export const transformPostData = (financeApplication: Partial<IFinanceApplicationData>): any => {
  return {
    finance_application: { offer_code: financeApplication.offer_code, callback_urls: callbackUrls() },
  };
};
