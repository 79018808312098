import { RootState } from "app2/src/reducers";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { document as documentSelector } from "app2/src/selectors/document.selectors";
import { connectFC } from "app2/src/connect";
import { AsyncActions } from "app2/src/reducers/document.actions";
import { Button } from "react-bootstrap";
import { useState } from "react";
import * as FontAwesome from "react-fontawesome";
import { sleep } from "app2/src/helpers/Time";

export interface DownloadDocumentButtonProps {
  documentId: number;
  signed?: boolean;
  children?: React.ReactNode;
}

/**
 * Used in contracts.html and DocumentRow.tsx to download document files using proper authentication
 */
export const DownloadDocumentButton: React.FC<DownloadDocumentButtonProps> = ({ documentId, signed, children }) => {
  const dispatch = useDispatch();
  const document = useSelector((state: RootState) => documentSelector(state, { documentId }));
  const [downloading, setDownloading] = useState(false);

  const download = () => {
    setDownloading(true);
    (dispatch(AsyncActions.downloadDocument(document, signed)) as any as Promise<void>)
      .then(() => {
        sleep(2000).then(() => {
          setDownloading(false);
        });
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  if (downloading) {
    return (
      <Button className="btn btn-borderless">
        <FontAwesome name="spinner" className="fa-pulse" data-testid="button-spinner" />
      </Button>
    );
  }

  if (children) {
    return (
      <span title="Download Document" onClick={() => download()}>
        {children}
      </span>
    );
  }

  if (signed)
    return (
      <Button title="Download Signed Document" onClick={() => download()} disabled={downloading}>
        <i className="fa fa-file-pdf-o"></i>
      </Button>
    );

  return (
    <Button variant="secondary" title="Download File" onClick={() => download()} disabled={downloading}>
      <i className="fa fa-download"></i>
    </Button>
  );
};

export default connectFC(DownloadDocumentButton);
