import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IOrg, OrgAclType } from "app/src/Models/Org";
import { IImage } from "../Models/Image";
import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEstimate } from "app/src/Models/Estimate";
import { IEventingFactory } from "../Common/EventingFactory";
import { IFileQueue, IFileQueueFactory, IFileQueueInit } from "../Common/FileQueueFactory";
import * as ng from "angular";
import { ISession } from "../Common/SessionService";
import { IFlash } from "app/src/Common/FlashService";
import { EditLineItemCmd } from "app/src/Commands/Estimator/EditLineItemCmd";
import { validationMessage } from "app/src/Estimator/ValidationService";
import { ProductRecord } from "app2/src/records/Product";
import { useSelector } from "app2/src/storeRegistry";
import { cachedProduct } from "app2/src/selectors/product.selectors";
import { getUrl } from "app2/src/records/Image";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { cachedProductOption } from "app2/src/selectors/productOption.selectors";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { presentModePreferencesConfig } from "app2/src/selectors/org.selectors";

export class EstimateLineItemComponentCtrl implements ng.IComponentController {
  public lineItem: IEstimateLineItem;
  public estimate: IEstimate;
  public remove: (args: any) => null;
  public org: IOrg;
  public navDisplay: boolean;
  public collapsedView: boolean;
  public columnWidths: any[];
  public descriptionStyle: string;
  public detailsStyle: string;
  public quantityStyle: string;
  public pricingStyle: string;
  public hiddenLineItems: boolean;
  public fileQueue: IFileQueueFactory;
  public fullTitles = true;
  public showPricingInLineItemEditor = false;
  public product: ProductRecord;
  public estimateGroup: IEstimateGroup;
  [s: string]: any;

  constructor(
    public ConfirmDialog: IConfirmDialog,
    public EstimatorService: EstimatorService,
    public EventingFactory: IEventingFactory,
    public FileQueue: IFileQueueInit,
    public $rootScope: ng.IScope,
    public Session: ISession,
    public Flash: IFlash,
    private $timeout: ng.ITimeoutService,
    private $scope: ng.IScope,
  ) {
    this.fileQueue = FileQueue.getInstance();
  }

  public $onChanges() {
    if (this.columnWidths) {
      this.descriptionStyle = "col-" + this.columnWidths[0];
      this.detailsStyle = this.columnWidths[1] > 0 ? "col-" + this.columnWidths[1] : "d-none";
      this.quantityStyle = this.columnWidths[2];
      this.pricingStyle = this.columnWidths[3];
    }
    if (this.org) {
      this.fullTitles = _.include(this.org.fetchAcl(OrgAclType.estimator), "full");
      this.showPricingInLineItemEditor = useSelector((state) =>
        presentModePreferencesConfig(state, {
          path: ["estimator", "show_pricing", "line_item_editor"],
        }),
      );
      this.EventingFactory.init({
        category: "Estimate",
        estimate: this.estimate.id,
        job: this.estimate.job_id,
        org: this.org.id,
        navDisplay: !this.navDisplay,
      });
    }
  }

  public lineItemImage(line_item: IEstimateLineItem) {
    if (!this.product || line_item.product_id !== this.product.id) {
      this.product = useSelector((state) =>
        cachedProduct(state, {
          productId: this.lineItem.product_id,
          activatedPriceListId: this.estimate.activated_price_list_id,
        }),
      );
    }

    const images = _.filter(line_item.images, (img: IImage) => {
      return !img._destroy;
    });

    if (images.length > 0) {
      return images[0].getUrl("medium");
    }

    if (this.product && this.product.images && this.product.images.size > 0) {
      return getUrl(this.product.images.first(), "medium");
    }

    return false;
  }

  public lineItemOptionImage(option: IEstimateLineItemOption) {
    let productOption: ProductOptionRecord;
    if (option.product_option_id) {
      productOption = useSelector((state) =>
        cachedProductOption(state, {
          activatedPriceListId: this.estimate.activated_price_list_id,
          productOptionId: option.product_option_id,
        }),
      );
    }
    if (productOption && productOption.images && productOption.images.size > 0) {
      return getUrl(productOption.images.first(), "medium");
    }

    return false;
  }

  public editLineItem() {
    this.$scope.$emit("estimateLineItem.edit", { estimateLineItem: this.lineItem });
  }

  public moveLineItemGroup(group: IEstimateGroup) {
    const cmd = new EditLineItemCmd(this.lineItem, this.lineItem.clone(), this.estimate.existingGroups(), group);
    cmd.execute();
  }

  public saveLineItem(state) {
    if (state.quantity === 0) {
      this.ConfirmDialog.confirm("Are you sure you want to add a line item with a quantity of 0?", {}).then(() => {
        this.saveConfirmed(state);
      });
    } else {
      this.saveConfirmed(state);
    }
  }

  public saveConfirmed(state) {
    this.lineItem.editing = false;
    this.lineItem.newly_added = false;
    state.newly_added = false;
    const temp_queue = this.fileQueue.temp_queue;
    this.$scope.$emit("estimateLineItem.edited", {
      newEstimateLineItem: state,
      oldEstimateLineItem: this.lineItem,
      estimateGroup: this.lineItem.estimateGroup,
      temp_queue: temp_queue,
    });
    this.fileQueue.clearTempQueue();
  }

  public cancelEdit() {
    this.lineItem.editing = false;
    this.EventingFactory.trackEvent("cancel line item edit", { category: "EstimateLineItemComponent" });
    if (this.lineItem.newly_added) {
      this.remove({ lineItem: this.lineItem });
    }
    this.$timeout(() => {
      this.$scope.$digest();
    });
  }

  public removeLineItem() {
    this.ConfirmDialog.confirm("Are you sure you want to delete this line item?", {}).then(() => {
      this.lineItem.editing = false;
      this.remove({ lineItem: this.lineItem });
    });
  }

  public addOpening(product) {
    this.$scope.$emit("estimateLineItem.add_opening", { product: product, estimateGroup: this.lineItem.estimateGroup });
    this.cancelEdit();
  }

  public importSelected() {
    this.$scope.$emit("estimateLineItem.import_selected", { estimateGroup: this.lineItem.estimateGroup });
    this.cancelEdit();
  }

  public feneTechOrderSelected(data: any) {
    this.$scope.$emit("estimateLineItem.fenetech_order", { estimateGroup: this.lineItem.estimateGroup, data });
    this.cancelEdit();
  }

  public onFileDestroy(file) {
    return this.ConfirmDialog.confirm("Are you sure you want to delete the file?", {}).then(() => {
      file._destroy = true;
      this.EventingFactory.trackEvent("destroy file", {
        file: file.id,
        category: "EstimateLineItemComponent",
      });
    });
  }

  public onFileChange(value, file) {
    file.display = value;
    this.EventingFactory.trackEvent("file display value changed", {
      file: file.id,
      display: value,
      category: "EstimateLineItemComponent",
    });
  }

  public validation() {
    validationMessage([this.lineItem.validation_result], this.ConfirmDialog);
  }
}

export class EstimateLineItemComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Estimator/estimate_line_item_component.html";
  public bindings: any = {
    lineItem: "<",
    estimate: "<",
    navDisplay: "<",
    remove: "&",
    groupType: "<",
    collapsedView: "<",
    columnWidths: "<",
    org: "<",
    hiddenLineItems: "<",
    estimateGroup: "<",
  };
  public replace = true;

  constructor() {
    this.controller = EstimateLineItemComponentCtrl;
    this.controller.$inject = [
      "ConfirmDialog",
      "EstimatorService",
      "EventingFactory",
      "FileQueueFactory",
      "$rootScope",
      "Session",
      "Flash",
      "$timeout",
      "$scope",
    ];
  }
}

export interface IEstimateLineItemEvent {
  estimateLineItem: IEstimateLineItem;
}

export interface IEstimateLineItemGroupEvent {
  newEstimateLineItem: IEstimateLineItem;
  oldEstimateLineItem: IEstimateLineItem;
  estimateGroup: IEstimateGroup;
  temp_queue: IFileQueue;
}

export interface IEstimateLineItemOpeningEvent {
  product: ProductRecord;
  estimateGroup: IEstimateGroup;
}
