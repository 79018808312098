import { Record, List } from "immutable";
import { Nullable } from "..";
import { PlanFamily, PlanRecord, fromJSON as planFromJSON, IPlanData } from "./Plan";
import { fromJSON as subscriptionFromJSON, ISubscriptionData, SubscriptionRecord } from "./Subscription";

export const fromJSON = (json: Partial<IPlanSubscriptionData>): PlanSubscriptionRecord => {
  const record = { ...json } as any as IPlanSubscriptionRecord;

  if (json.plan) {
    record.plan = planFromJSON(json.plan);
  }

  if (json.subscription) {
    record.subscription = subscriptionFromJSON(json.subscription);
  }

  return new PlanSubscriptionRecord(record);
};

export interface IPlanSubscriptionData {
  id: number;
  account_id: number;
  family: PlanFamily;
  plan_id: number;
  subscription_id: number;

  plan: IPlanData;
  subscription: ISubscriptionData;

  created_at: Date | string;
  updated_at: Date | string;
}

export interface IPlanSubscriptionRecord {
  id: number;
  account_id: number;
  family: PlanFamily;
  plan_id: Nullable<number>;
  subscription_id: Nullable<number>;

  plan: Nullable<PlanRecord>;
  subscription: Nullable<SubscriptionRecord>;

  created_at: Date;
  updated_at: Date;
}

const defaultPlanSubscriptionProps: IPlanSubscriptionRecord = {
  id: 0,
  account_id: 0,
  plan_id: null,
  subscription_id: null,
  family: "estimates",
  plan: null,
  subscription: null,
  created_at: new Date(),
  updated_at: new Date(),
};

export class PlanSubscriptionRecord extends Record(defaultPlanSubscriptionProps) implements IPlanSubscriptionRecord {
  public readonly id!: number;
  public readonly account_id!: number;
  public readonly family!: PlanFamily;
  public readonly plan_id!: Nullable<number>;
  public readonly subscription_id!: Nullable<number>;

  public readonly plan!: Nullable<PlanRecord>;
  public readonly subscription!: Nullable<SubscriptionRecord>;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IPlanSubscriptionRecord>) {
    values ? super(values) : super();
  }
}
