import * as FontAwesome from "react-fontawesome";
import { useEffect, useState } from "react";
import * as React from "react";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { useDispatch } from "react-redux";
import { FlashLevels } from "app/src/Common/FlashLevels";

export interface CopyToClipboardButtonProps {
  content: string;
  contentDescription?: string;
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ content, contentDescription }) => {
  const title = contentDescription ? `Copy ${contentDescription} to clipboard` : "Copy to clipboard";
  const dispatch = useDispatch();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(content);
    dispatch(
      commonActions.Actions.flashAddAlert(
        FlashLevels.success,
        `${contentDescription || "Content"} successfully copied.`,
      ),
    );
  };

  return (
    <FontAwesome
      name="file-text-o"
      className="rsf-base-link rsf-base-66 action-button"
      title={title}
      onClick={copyToClipboard}
    />
  );
};
