import * as eagleviewActions from "./eagleview.actions";
import { Record, fromJS, Map } from "immutable";
import { Page } from "app2/src/api/integrations/eagleview.service";

export const EagleviewStateRecord = Record({
  pages: Map<number, Page>(),
  page: 1,
  total_count: 0,
  total_pages: 0,
  importing: false,
});

export const initialState = EagleviewStateRecord({});
export type EagleviewState = typeof initialState;

export const reducer = (state = initialState, action: eagleviewActions.Actions): EagleviewState => {
  switch (action.type) {
    case eagleviewActions.RECEIVE_PAGE:
      return state
        .setIn(["pages", action.payload.page], action.payload)
        .setIn(["pages", action.payload.page, "loading"], false);

    case eagleviewActions.FETCH_PAGE:
      if (state.getIn(["pages", action.payload])) {
        return state.setIn(["pages", action.payload, "loading"], true).set("page", action.payload);
      }
      return state.setIn(["pages", action.payload], fromJS({ loading: true })).set("page", action.payload);

    case eagleviewActions.RESET_PAGES:
      return state.set("pages", Map());

    case eagleviewActions.START_IMPORT:
      return state.set("importing", true);

    case eagleviewActions.FINISH_IMPORT:
      return state.set("importing", false);

    default:
      return state;
  }
};
