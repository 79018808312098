import * as React from "react";
import track from "react-tracking";
import { Button } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";

interface RefreshButtonProps {
  className?: string;
  trigger?: number;
  refresh: () => void;
}

interface RefreshButtonState {
  refreshing: boolean;
  counter: number;
  interval: any;
}

@track(() => ({
  component: "RefreshButton",
}))
export default class RefreshButton extends React.Component<RefreshButtonProps, RefreshButtonState> {
  constructor(props: RefreshButtonProps) {
    super(props);

    this.state = {
      refreshing: false,
      counter: 0,
      interval: null,
    };

    this.startRefreshing = this.startRefreshing.bind(this);
    this.stopRefreshing = this.stopRefreshing.bind(this);
    this.callRefresh = this.callRefresh.bind(this);
    this.counter = this.counter.bind(this);
  }

  public componentDidUpdate(prevProps: RefreshButtonProps): void {
    if (prevProps.trigger !== this.props.trigger) {
      this.startRefreshing();
    }
  }

  public componentWillUnmount(): void {
    this.stopRefreshing();
  }

  @track(() => ({
    action: "start refreshing",
  }))
  public startRefreshing(): void {
    this.setState({
      refreshing: true,
      counter: 10,
      interval: setInterval(() => this.counter(), 1000),
    });
  }

  @track(() => ({
    action: "stop refreshing",
  }))
  public stopRefreshing(): void {
    const { interval } = this.state;
    clearInterval(interval);

    this.setState({
      refreshing: false,
      interval: null,
    });
  }

  @track(() => ({
    action: "refresh",
  }))
  public callRefresh(): void {
    const { refresh } = this.props;
    refresh();
  }

  public counter(): void {
    this.setState((state: RefreshButtonState) => {
      if (state.counter === 0) {
        this.callRefresh();
        return { counter: 10 };
      }
      return {
        counter: state.counter - 1,
      };
    });
  }

  public render(): React.ReactNode {
    const { refreshing, counter } = this.state;
    const { className } = this.props;

    if (refreshing) {
      return (
        <Button variant="borderless" className={className || ""} onClick={this.stopRefreshing}>
          Refreshing in {counter} <FontAwesome name="close" />
        </Button>
      );
    }

    return (
      <Button variant="borderless" className={className} onClick={this.startRefreshing}>
        Refresh <FontAwesome name="refresh" />
      </Button>
    );
  }
}
