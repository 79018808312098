import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootActions, RootState } from "./index";
import { EstimateComparisonRecord } from "app2/src/records/EstimateComparison";
import * as estimateComparisonService from "app2/src/api/estimateComparison.service";
import { fromJSON as ecFromJSON } from "app2/src/records/EstimateComparison";
import * as estimateActions from "app2/src/reducers/estimate.actions";

export const FETCH_ESTIMATE_COMPARISON = "@estimateComparison/FETCH_ESTIMATE_COMPARISON";
export const RECEIVE_ESTIMATE_COMPARISON = "@estimateComparison/RECEIVE_ESTIMATE_COMPARISON";
export const SET_LOADED = "@estimateComparison/SET_LOADED";
export const BUILD_ESTIMATE_COMPARISON = "@estimateComparison/BUILD_ESTIMATE_COMPARISON";
export const SHOW_DRAWER = "@estimateComparison/SHOW_DRAWER";
export const HIDE_DRAWER = "@estimateComparison/HIDE_DRAWER";
export const VIEW_ESTIMATE_COMPARISON = "@estimateComparison/VIEW_ESTIMATE_COMPARISON";
export const ADD_ESTIMATE_COMPARISON_LINK = "@estimateComparison/ADD_ESTIMATE_COMPARISON_LINK";
export const REMOVE_ESTIMATE_COMPARISON_LINK = "@estimateComparison/REMOVE_ESTIMATE_COMPARISON_LINK";

export const Actions = {
  fetchEstimateComparison: (jobId: number) => createAction(FETCH_ESTIMATE_COMPARISON, { jobId }),
  receiveEstimateComparison: (estimateComparison: EstimateComparisonRecord) =>
    createAction(RECEIVE_ESTIMATE_COMPARISON, { estimateComparison }),
  setLoaded: (jobId: number) => createAction(SET_LOADED, { jobId }),
  buildEstimateComparison: (jobId: number, estimateIds: number[]) =>
    createAction(BUILD_ESTIMATE_COMPARISON, { jobId, estimateIds }),
  viewEstimateComparison: (jobId: number) => createAction(VIEW_ESTIMATE_COMPARISON, { jobId }),
  showDrawer: () => createAction(SHOW_DRAWER),
  hideDrawer: () => createAction(HIDE_DRAWER),
  addEstimateComparisonLink: (estimateId: number, jobId: number) =>
    createAction(ADD_ESTIMATE_COMPARISON_LINK, { estimateId, jobId }),
  removeEstimateComparisonLink: (estimateId: number, jobId: number) =>
    createAction(REMOVE_ESTIMATE_COMPARISON_LINK, { estimateId, jobId }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  saveEstimateComparison: (estimateComparison: EstimateComparisonRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: ThunkDispatch<RootState, {}, RootActions>) => {
      dispatch(Actions.fetchEstimateComparison(estimateComparison.job_id));
      const promises = [];
      estimateComparison.estimate_comparison_links.forEach((ecl, idx) => {
        if (ecl._destroy && ecl.id === 0) {
          promises.push(Promise.resolve({ id: 0, index: idx }));
        } else {
          promises.push(dispatch(estimateActions.AsyncActions.updateOrCreateEstimate(ecl.estimate_id, idx)));
        }
      });

      const response = await Promise.all(promises);
      let ecls = estimateComparison.estimate_comparison_links.map((ecl, index) => {
        if (ecl.id === 0 || ecl.estimate_id < 1) {
          return ecl.set("estimate_id", response.find((object) => object.index === index).id);
        } else {
          return ecl;
        }
      });

      ecls = ecls.filter((ecl) => ecl.estimate_id > 0);

      estimateComparison = estimateComparison.set("estimate_comparison_links", ecls);

      if (estimateComparison.id === 0) {
        try {
          const response = await estimateComparisonService.create(estimateComparison);
          dispatch(Actions.receiveEstimateComparison(ecFromJSON(response)));
        } catch (response) {
          dispatch(Actions.setLoaded(estimateComparison.job_id));
        }
      } else {
        try {
          const response = await estimateComparisonService.update(estimateComparison);
          dispatch(Actions.receiveEstimateComparison(ecFromJSON(response)));
        } catch (response) {
          dispatch(Actions.setLoaded(estimateComparison.job_id));
        }
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
