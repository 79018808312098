export interface IRoofFace {
  id: number;
  name: string;
  designator: string;
  location: string;
  level: string;
  orientation: number;
  pitch: number;
  area: number;
  ui: number;
  created_at: Date;
  updated_at: Date;

  clone(): IRoofFace;
}

export class RoofFace implements IRoofFace {
  public id: number;
  public name: string;
  public designator: string;
  public location: string;
  public level: string;
  public orientation: number;
  public pitch: number;
  public area: number;
  public ui: number;
  public created_at: Date;
  public updated_at: Date;

  constructor() {}

  public clone(): IRoofFace {
    return RoofFace.fromJSON(Object.assign({}, this));
  }

  public static fromJSON = (data: any): IRoofFace => {
    const roof_face = new RoofFace();
    for (const key in data) {
      if (data.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        switch (key) {
          case "id":
          case "measurement_id":
            data[key] = parseInt(data[key]);
            break;
          case "area":
          case "orientation":
          case "pitch":
            data[key] = parseFloat(data[key]);
            break;
          case "updated_at":
          case "created_at":
            data[key] = new Date(data[key]);
            break;
        }

        roof_face[key] = data[key];
      }
    }
    return roof_face;
  };
}
