import { fetcher } from "../helpers/Fetcher";
import { IReportData, ReportRecord, toJSON } from "../records/Report";
import { baseCleanProps, listParams } from "app2/src/api/Api";
import { QueryParamsRecord } from "app2/src/records/Page";
import { IMetaPaginationData } from "app2/src/records/MetaPagination";

interface IReportIndexResponse {
  reports: IReportData[];
  meta: { pagination: IMetaPaginationData };
}

interface IReportResponse {
  report: IReportData;
}

export interface IPlnarPricingResponse {
  pricing: { type: string; display: string; amount: number }[];
}

const urls = {
  org: "api/v1/orgs/:id/reports",
  job: "api/v1/jobs/:id/reports",
  default: "api/v1/reports",
};

export const list = (queryParams: QueryParamsRecord): Promise<IReportIndexResponse> => {
  const reportableId = queryParams.getIn(["parameters", "reportable_id"]);
  const reportableType = queryParams.getIn(["parameters", "reportable_type"])?.toLowerCase() || "default";
  const actionUrl = urls[reportableType].replace(":id", reportableId?.toString());

  const params = listParams(queryParams);
  const parameters = { ...params, ...queryParams.parameters.toJS() };

  if (!queryParams.getIn(["parameters", "sort_order"])) parameters["sort_order"] = "desc";
  if (!queryParams.getIn(["parameters", "sort_by"])) parameters["sort_by"] = "created_at";
  delete parameters.reportable_id;
  delete parameters.reportable_type;

  return fetcher.get<IReportIndexResponse>(actionUrl, parameters);
};

export const create = (id: number, report: ReportRecord, kind: "org" | "job" = "org"): Promise<IReportData> => {
  const actionUrl = urls[kind].replace(":id", id.toString());

  const data: Partial<IReportData> = cleanProps(toJSON(report));

  return fetcher.post<IReportResponse>(actionUrl, { report: data }).then(
    (response) => response.report,
    (response) => Promise.reject(response.data.errors),
  );
};

export const getPricing = <T>(params: any): Promise<T> => {
  const actionUrl = fetcher.joinUrls(urls.default, "pricing");
  return fetcher.get(actionUrl, params);
};

export const cleanProps = (report: Partial<IReportData>): Partial<IReportData> => {
  report = baseCleanProps(JSON.parse(JSON.stringify(report)));

  return report;
};
