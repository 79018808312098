import * as productActions from "./product.actions";
import { Map, Record } from "immutable";
import { ProductRecord, fromJSON } from "../records/Product";
import { RootActions, RootState } from ".";
import { ActivatedPriceListRecord } from "app2/src/records/ActivatedPriceList";
import { ancestralName } from "app2/src/selectors/product.selectors";

export const ProductStateRecord = Record({
  byId: Map<number, ProductRecord>(),
});

export const initialState = ProductStateRecord();

export type ProductState = typeof initialState;

export type ProductReducerState = RootState | ActivatedPriceListRecord;

export const reducer = (state: ProductReducerState, action: RootActions): ProductReducerState => {
  const model = "products";
  if (state && !state.getIn([model])) {
    state = state.setIn([model], initialState);
  }

  let product: ProductRecord;
  switch (action.type) {
    case productActions.RECEIVE_PRODUCT:
      const { product: productData } = action.payload;
      let { options } = action.payload;

      // check if we need to pass down discountable
      // else if options has discountable, set this data as discountable if it's a leaf node
      if (productData.discountable) {
        options = options.set("discountable", productData.discountable);
      } else if (
        options.get("discountable") &&
        productData.children !== undefined &&
        _.isArray(productData.children) &&
        productData.children.length <= 0
      ) {
        productData.discountable = options.get("discountable");
      }

      product = fromJSON({ ...productData });
      state = state.setIn([model, "byId", product.id], product);

      const name = ancestralName(state, { productId: product.id });
      product = product.merge({ ancestral_name: name, ancestral_name_lowercase: name.toLowerCase() });

      state = state.setIn([model, "byId", product.id], product);

      productData.children.forEach((child) => {
        state = reducer(state, productActions.Actions.receiveProduct(child, options));
      });

      return state;

    default:
      return state;
  }
};
