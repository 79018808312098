import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IDiscount, Discount } from "app/src/Models/Discount";
import { IEstimate } from "app/src/Models/Estimate";
import { ISession } from "app/src/Common/SessionService";
import { List } from "immutable";
import { EstimateDiscountRecord, fromJSON } from "app2/src/records/EstimateDiscount";

class DiscountEditorCtrl {
  public added: (param: { discount: IDiscount }) => void;
  public removed: (param: { discount: IDiscount }) => void;
  public updated: (param: { discount: IDiscount }) => void;
  public estimateId: number;
  public params: any = { type: "view" };
  public editorParams: any;
  public discounts: List<EstimateDiscountRecord> = List();
  public showHeaderText: boolean;

  public showing = false;
  public estimate: IEstimate;
  public selectedDiscount: IDiscount;
  public showLimit = false;
  private creating = false;

  constructor(
    private $scope: ng.IScope,
    private EstimatorService: EstimatorService,
    private Session: ISession,
  ) {
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.update = this.update.bind(this);
  }

  public $onInit() {
    this.showing = this.editorParams.showing;
  }

  public $onChanges() {
    if (this.estimateId && (!this.estimate || this.estimate.id !== this.estimateId)) {
      this.estimate = this.EstimatorService.loadEstimate(this.estimateId);
      this.estimate.$promise.then(() => {
        this.setupDiscounts();
      });
    }
  }

  public setupDiscounts() {
    this.discounts = List();
    _.each(this.estimate.discounts, (dis) => {
      const disRec = fromJSON(dis);
      this.discounts = this.discounts.push(disRec);
    });
  }

  public add(discount: any) {
    this.added({ discount: Discount.fromJSON(discount) });
    this.setupDiscounts();
  }

  public update(discount: EstimateDiscountRecord) {
    this.$scope.$apply(() => {
      this.estimate.discounts = this.estimate.discounts.map((d) => {
        if (d.uuid === discount.uuid) {
          return Discount.fromJSON(discount.toJSON());
        }
        return d;
      });
      this.estimate.updateTotal();
      this.setupDiscounts();
    });
  }

  public remove(discount: EstimateDiscountRecord) {
    if (this.removed) {
      this.removed({ discount: Discount.fromJSON(discount.toJSON()) });
      this.setupDiscounts();
    }
  }

  protected _findId(): number {
    const currentMin = _.chain(this.estimate.discounts).pluck("id").min().value();

    return _.min([-1, currentMin - 1]);
  }
}

export class DiscountEditor implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    estimateId: "<",
    editorParams: "<",
    showHeaderText: "<",
    added: "&",
    updated: "&",
    removed: "&",
  };
  public templateUrl = "src/Estimator/Discounts/discount_editor.html";
  /**/
  constructor() {
    this.controller = DiscountEditorCtrl;
    this.controller.$inject = ["$scope", "EstimatorService", "Session"];
  }
}
