import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { EstimateEditor } from "app/src/Estimator/EstimateEditorComponent";
import { ProductSelectorCtrl } from "app/src/Estimator/ProductSelectorCtrl";
import { LoanCalc } from "app/src/Estimator/LoanCalculator";
import { DiscountEditor } from "app/src/Estimator/Discounts/DiscountEditor";
import { TemplateEditorCtrl } from "app/src/Estimator/TemplateEditorCtrl";
import { EstimateLineItemComponent } from "app/src/Estimator/EstimateLineItemComponent";
import { EstimateGroupComponent } from "app/src/Estimator/EstimateGroupComponent";
import { OpeningsWizard } from "app/src/Estimator/OpeningsWizard";
import { SalesTaxEditor } from "app/src/Estimator/SalesTaxEditor";
import { WindowAnnotatingComponent } from "./WindowAnnotatingComponent";
import { react2angular } from "react2angular";
import LineItemEditor from "app2/src/components/LineItemEditor/LineItemEditor";
import DiscountChooser from "app2/src/components/Discount/Chooser";
import PaymentTermChooser from "app2/src/components/PaymentTerm/Chooser";
import PriceDrawerWrapper from "app2/src/components/PriceDrawer";
import MarkupEditor from "app2/src/components/Estimate/MarkupEditor";
import EstimateTemplateModal from "app2/src/components/JobTabs/EstimateTab/EstimateTemplateModal";
import FinanceOptionsButton from "app2/src/components/Homevest/FinanceOptionsButton";
import CommissionActions from "app2/src/components/JobTabs/EstimateTab/CommissionActions";
import RoomSummary from "app2/src/components/JobTabs/EstimateTab/RoomSummary";

export class EstimatorRoutes {
  public static load(app: ng.IModule) {
    app.controller("ProductSelectorCtrl", ProductSelectorCtrl);
    app.controller("TemplateEditorCtrl", TemplateEditorCtrl);
    app.controller("OpeningsWizard", OpeningsWizard);
    app.component("estimateEditor", new EstimateEditor());
    app.component("estimateLineItem", new EstimateLineItemComponent());
    app.component("estimateGroup", new EstimateGroupComponent());
    app.component("loanCalc", new LoanCalc());
    app.component("discountEditor", new DiscountEditor());
    app.component("salesTaxEditor", new SalesTaxEditor());
    app.component("windowAnnotator", new WindowAnnotatingComponent());
    app.component(
      "priceDrawerWrapper",
      react2angular(
        PriceDrawerWrapper,
        ["orgId", "jobId", "currentEstimate", "openDrawer", "saveEditorEstimate"],
        ["$state", "EstimatorService", "ConfirmDialog"],
      ),
    );
    app.service("EstimatorService", EstimatorService);

    app.component(
      "lineItemEditor",
      react2angular(
        LineItemEditor,
        ["lineItem", "estimate", "measurementId", "controller", "estimateGroup"],
        ["$analytics"],
      ),
    );

    app.component(
      "discountChooser",
      react2angular(DiscountChooser, [
        "onSelect",
        "existing",
        "showHeaderText",
        "total",
        "update",
        "destroy",
        "params",
      ]),
    );
    app.component("financeOptionsButton", react2angular(FinanceOptionsButton, ["estimateId"]));
    app.component(
      "paymentTermChooser",
      react2angular(PaymentTermChooser, [
        "orgId",
        "paymentTerm",
        "showHeaderText",
        "addItem",
        "addTemplate",
        "removeItem",
        "updateItem",
        "doneButton",
        "view",
      ]),
    );

    app.component("markupEditor", react2angular(MarkupEditor, ["orgId", "estimate", "lineItemEditorOpen", "updated"]));
    app.component(
      "estimateTemplateModal",
      react2angular(EstimateTemplateModal, ["show", "readOnly", "saveAs", "select"]),
    );
    app.component("commissionActions", react2angular(CommissionActions, ["estimateId"]));
    app.component("roomSummary", react2angular(RoomSummary, ["estimateGroup"]));
  }

  public static configure($stateProvider: ng.ui.IStateProvider): void {
    $stateProvider.state("job_header.job_show.estimate.homevest", <ng.ui.IState>{
      url: "/homevest",
      views: {},
    });
    $stateProvider.state("job_header.job_show.estimate.homevest_route", <ng.ui.IState>{
      url: "/homevest/:route",
      views: {},
    });
  }
}
