import * as ng from "angular";
import * as angulartics from "angulartics";
import { IOrgResponse, IOrgResource } from "app/src/Models/Org";
import { IHeaderSearchArgs, IHeaderShowArgs } from "app/src/Header/HeaderCtrl";
import { IPretty } from "../Common/PrettyNameService";
import { titleCaseString } from "app2/src/services/string.service";

export class OrgListCtrl {
  public orgResponse: IOrgResponse;
  public stateFilters: string[] = [];
  public spinnerPromise: ng.IPromise<any>;
  public totalCount = 0;
  public currentPage = 1;
  public perPage = 20;
  public search: string;

  public static $inject = ["Org", "$scope", "$analytics", "$state", "$stateParams", "Pretty"];
  constructor(
    public Org: IOrgResource,
    public $scope: ng.IScope,
    public $analytics: angulartics.IAnalyticsService,
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public Pretty: IPretty,
  ) {
    if ($stateParams["status"]) {
      this.stateFilters.push($stateParams["status"]);
    }

    this.spinnerPromise = this.query().then(() => {
      if (this.totalCount === 1 && this.currentPage === 1) {
        this.$state.go("root.org_show.info", { id: this.orgResponse.orgs[0].id });
      }
    });

    $scope.$emit("header:search", <IHeaderShowArgs>{ show: true });
    $scope.$on("header:search_change", (e: ng.IAngularEvent, args: IHeaderSearchArgs) => {
      this.$analytics.eventTrack("search", { category: "Org List" });
      this.search = args.searchString;
      this.query();
    });
  }

  public toggleStateFilter(filter: string) {
    if (_.indexOf(this.stateFilters, filter) >= 0) {
      this.$analytics.eventTrack("filter added", { category: "Org List", value: filter });
      this.stateFilters = _.filter(this.stateFilters, (s) => {
        return s !== filter;
      });
    } else {
      this.$analytics.eventTrack("filter removed", { category: "Org List", value: filter });
      this.stateFilters.push(filter);
    }

    this.refreshQuery();
  }

  public stateSelected(state: string) {
    return _.indexOf(this.stateFilters, state) >= 0;
  }

  public query(): ng.IPromise<any> {
    const params: any = {
      page: this.currentPage,
      per_page: this.perPage,
      sort_by: "id",
      sort_order: "desc",
    };

    if (this.search) {
      params["query"] = this.search;
    }

    if (this.stateFilters.length > 0) {
      params["status[]"] = this.stateFilters;
    }

    this.orgResponse = <IOrgResponse>this.Org.query(params);

    return this.orgResponse.$promise.then(() => {
      this.totalCount = this.orgResponse.meta.pagination.total_count;
    });
  }

  public titleCaseStringHelper(input: string): string {
    return titleCaseString(input);
  }

  private refreshQuery(): ng.IPromise<any> {
    this.currentPage = 1;
    this.orgResponse = null;
    return this.query();
  }
}
