import { RoomRecord } from "app2/src/records/Room";
import * as React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { imperial_uom } from "app/src/Common/Constants";
import { IPretty } from "app/src/Common/PrettyNameService";
import { ceiling1, ceiling2, flooring1, flooring2, walls1, walls2, general } from "../../MeasurementTab/Interiors/Room";
import * as FontAwesome from "react-fontawesome";
import { RoomMeasurementTable } from "../../MeasurementTab/Interiors/RoomMeasurementTable";
import { roomKeys } from ".";
import { useTracking } from "react-tracking";
import { VisualizeRoom } from "./VisualizeRoom";
import { RoomMeasurementInfo } from "app2/src/components/Common/RoomMeasurementInfo";
import { StoreRegistry } from "app2/src/storeRegistry";

export interface IndividualRoomProps {
  room: RoomRecord;
  grouped?: boolean;
}

export const IndividualRoom: React.FC<IndividualRoomProps> = ({ room, grouped }) => {
  // Hooks
  const { trackEvent } = useTracking<any>({ component: "IndividualRoom", room: room.id });

  const roomValues = React.useMemo(() => {
    const pretty: IPretty = StoreRegistry.get("Pretty");
    return roomKeys.map((key) => ({
      label: pretty.name[key],
      value: room[key].toFixed(2),
      uom: imperial_uom[`interior_${key}`],
    }));
  }, [room]);

  // Local State
  const [activeKey, setActiveKey] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  // Methods
  const toggleIsOpen = () => {
    trackEvent({ action: `${isOpen ? "close" : "open"} accordion` });
    setIsOpen(!isOpen);
  };

  const toggleAccordion = (key: string) => {
    if (activeKey === key) {
      trackEvent({ action: "close accordion", accordionName: key });
      setActiveKey("");
    } else {
      trackEvent({ action: "open accordion", accordionName: key });
      setActiveKey(key);
    }
  };

  const detailsAccordion = () => {
    return (
      <Accordion activeKey={activeKey}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="ceiling"
            onClick={() => toggleAccordion("ceiling")}>
            Ceiling
            <span className="pull-right">
              <FontAwesome
                name={`${activeKey === "ceiling" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="ceiling">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <RoomMeasurementTable values={ceiling1} roomId={room.id} />
                </Col>
                <Col lg={6}>
                  <RoomMeasurementTable values={ceiling2} roomId={room.id} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="flooring"
            onClick={() => toggleAccordion("flooring")}>
            Flooring
            <span className="pull-right">
              <FontAwesome
                name={`${activeKey === "flooring" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="flooring">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <RoomMeasurementTable values={flooring1} roomId={room.id} />
                </Col>
                <Col lg={6}>
                  <RoomMeasurementTable values={flooring2} roomId={room.id} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle className="link" as={Card.Header} eventKey="walls" onClick={() => toggleAccordion("walls")}>
            Walls
            <span className="pull-right">
              <FontAwesome
                name={`${activeKey === "walls" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="walls">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <RoomMeasurementTable values={walls1} roomId={room.id} />
                </Col>
                <Col lg={6}>
                  <RoomMeasurementTable values={walls2} roomId={room.id} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="general"
            onClick={() => toggleAccordion("general")}>
            General
            <span className="pull-right">
              <FontAwesome
                name={`${activeKey === "general" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="general">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <RoomMeasurementTable values={general} roomId={room.id} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  return (
    <Accordion>
      <Card className={"room-summary" + (grouped ? "-grouped" : "")}>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <Card.Title>{grouped ? room.name : `Room Summary - ${room.name}`}</Card.Title>
            <div className="d-flex pb-2 align-items-center">
              {room.viewer_url && <VisualizeRoom url={room.viewer_url} />}
              {!grouped && <RoomMeasurementInfo />}
              <Accordion.Toggle as="button" eventKey="0" className="btn" onClick={toggleIsOpen}>
                <FontAwesome name={`angle-${isOpen ? "up" : "down"}`} size="lg" />
              </Accordion.Toggle>
            </div>
          </div>
          <Row>
            {roomValues.map((obj, idx) => (
              <Col key={"room-" + idx}>
                <div>
                  <span className="label">{obj.label}: </span> {obj.value} {obj.uom}
                </div>
              </Col>
            ))}
          </Row>
          <Accordion.Collapse eventKey="0" className="mt-3">
            {detailsAccordion()}
          </Accordion.Collapse>
        </Card.Body>
      </Card>
    </Accordion>
  );
};
