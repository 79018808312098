import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import * as imageActions from "app2/src/reducers/image.actions";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { Row, Col } from "react-bootstrap";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import track from "react-tracking";
import Image from "app2/src/components/JobTabs/ImageTab/Images/ImageList/Image";
import { List } from "immutable";
import { push } from "app2/src/reducers/router.actions";
import { ILocationData } from "app2/src/records/Location";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { allImagesBoolean } from "app2/src/selectors/image.selectors";
import { Sortable } from "app2/src/components/Common/Sortable";
import { contractTitle, estimateTitle } from "app2/src/selectors/org.selectors";
import ImageEditorModal from "app2/src/components/JobTabs/ImageTab/Images/ImageList/ImageEditorModal";

const mapStateToProps = (state: RootState) => {
  const modelName = "image",
    imageQueryParams = queryParams(state, { modelName });
  const page = imageQueryParams.get("page");
  const imageIds = ids(state, { modelName }) || List();
  return {
    imageIds,
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    selectedCheck: allImagesBoolean(state, { booleanName: "selected" }),
    estimateTitle: estimateTitle(state),
    contractTitle: contractTitle(state),
    proposalCheck: allImagesBoolean(state, { booleanName: "displayInProposal" }),
    agreementCheck: allImagesBoolean(state, { booleanName: "displayInAgreement" }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    push: (location: Partial<ILocationData>) => dispatch(push(location)),
    onSortEnd: (oldIndex: number, newIndex: number) =>
      dispatch(imageActions.AsyncActions.onSortEnd(oldIndex, newIndex)),
    batchEditBoolean: (imageIds: List<number>, name: string, value: boolean) =>
      dispatch(imageActions.Actions.batchEditBoolean(imageIds, name, value)),
    batchUpdateImage: (imageIds: List<number>) => dispatch(imageActions.AsyncActions.batchUpdateImage(imageIds)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImageListProps {
  queryImages: (page: number) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImageListProps;

@track(() => {
  return {
    component: "ImageList",
  };
})
class ImageList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  @track((props, _state, [event]) => ({
    action: "checkAll",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
    images: props.imageIds.toArray(),
  }))
  public checkBoxOnChange(event: any): void {
    const { imageIds, batchEditBoolean, batchUpdateImage } = this.props;
    batchEditBoolean(imageIds, event.target.name, event.target.checked);
    if (event.target.name !== "selected") {
      batchUpdateImage(imageIds);
    }
  }

  @track((props, _state, [oldIndex, newIndex]) => ({
    action: "onDragEnd",
    oldIndex: oldIndex,
    newIndex: newIndex,
    images: props.imageIds.toArray(),
  }))
  public onDragEnd(oldIndex: number, newIndex: number): void {
    const { onSortEnd } = this.props;
    onSortEnd(oldIndex, newIndex);
  }

  public render(): React.ReactNode {
    const {
      imageIds,
      pagination,
      selectedCheck,
      estimateTitle,
      contractTitle,
      proposalCheck,
      agreementCheck,
      queryImages,
    } = this.props;

    return (
      <Row>
        <Col>
          <Row className="table-header">
            <Col sm={1} style={{ paddingLeft: "15px" }}>
              <IncludeCheckBox name="selected" checked={selectedCheck} onChange={this.checkBoxOnChange} />
            </Col>
            <Col>Name</Col>
            <Col sm={2}>Uploaded On</Col>
            <Col sm={2}>
              <IncludeCheckBox
                name="displayInProposal"
                label={estimateTitle}
                checked={proposalCheck}
                onChange={this.checkBoxOnChange}
              />
            </Col>
            <Col sm={2}>
              <IncludeCheckBox
                name="displayInAgreement"
                label={contractTitle}
                checked={agreementCheck}
                onChange={this.checkBoxOnChange}
              />
            </Col>
            <Col sm={1}>Actions</Col>
          </Row>
          <Sortable
            dragHandle
            items={imageIds}
            onDragEnd={this.onDragEnd}
            renderItem={({ item, dragHandle }) => {
              return <Image imageId={item} dragHandle={dragHandle} />;
            }}
          />
          <StandardPagination metadata={pagination} pageChanged={queryImages} />
          <br />
          <ImageEditorModal />
        </Col>
      </Row>
    );
  }
}

export default connector(ImageList);
