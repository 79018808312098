export class EmbedSrc implements ng.IDirective {
  public restrict = "A";

  constructor() {}

  public link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
    let current: ng.IAugmentedJQuery = element;
    scope.$watch(
      () => attrs["embedSrc"],
      () => {
        const clone: ng.IAugmentedJQuery = <ng.IAugmentedJQuery>element.clone().attr("src", attrs["embedSrc"]);
        current.replaceWith(clone);
        current = clone;
      },
    );
  };

  public static factory(): ng.IDirectiveFactory {
    const directive = () => new EmbedSrc();
    directive.$inject = [];
    return directive;
  }
}
