import { IProduct, ICustomProduct, AbleProp } from "app/src/Models/Product";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { IEstimateLineItemOption, EstimateLineItemOption } from "./EstimateLineItemOption";
import { IImage } from "app/src/Models/Image";
import { IDoc } from "app/src/Models/Doc";
import { IOpeningEstimation } from "app/src/Models/OpeningEstimation";
import { IOpening } from "app/src/Models/Opening";
import { Nullable } from "app2/src/records";
import { ProductRecord } from "app2/src/records/Product";
import { ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { List, Map } from "immutable";
import { useSelector } from "app2/src/storeRegistry";
import { cachedProductOption, cachedProductOptionByMatchIdWithIds } from "app2/src/selectors/productOption.selectors";
import { cachedProductOptionGroup } from "app2/src/selectors/productOptionGroup.selectors";
import { cachedAncestorDocuments } from "app2/src/selectors/product.selectors";
import { IEstimateMarkups } from "app/src/Models/Estimate";
import { markupMultiplier } from "app2/src/records/Estimate";
import { isMarkupable } from "app2/src/records/EstimateLineItem";

export interface IEstimateLineItem {
  classy: string;
  id: number;
  product_uuid: string;
  estimate_id: number;
  product_id: number;
  estimate_group_id: number;
  orig_estimate_group_id: number;
  color_id: number;
  sort_order: number;
  estimateGroup: IEstimateGroup;
  name: string;
  description: string;
  cost: number;
  price: number;
  product_price: number;
  labor_price: number;
  base_product_price: number;
  base_labor_price: number;
  visibility: string;
  quantity: number;
  discountable: AbleProp;
  markupable: AbleProp;
  uom: string;
  ext_price: number;
  msrp: number;
  created_at: Date;
  updated_at: Date;
  _destroy: boolean;
  options: IEstimateLineItemOption[];
  documents: IDoc[];
  images: IImage[];
  opening_estimations: IOpeningEstimation[];
  product_image_urls: string[];
  estimate_doc_ids: number[];
  contract_doc_ids: number[];
  newly_added: boolean;
  editing: boolean;
  validated: boolean;
  validation_result: any;

  optionsType(type: string): IEstimateLineItemOption[];
  existingOptions(): IEstimateLineItemOption[];
  existingSingleSelectOption(optionGroupId: number): Nullable<IEstimateLineItemOption>;
  existingEliForProductOption(productOptionUuid: string): Nullable<IEstimateLineItemOption>;
  calculate(estimate: IEstimateMarkups): void;
  optionsTotal(): void;
  isVisible(presentation_mode: boolean): boolean;
  isCustom(): boolean;
  clone(): IEstimateLineItem;
  copyValues(from: IEstimateLineItem);
  setProduct(activatedPriceListId: number, product: ProductRecord, setDescription?: boolean): void;
  windowPriceAdjustment(product: ProductRecord): void;
  setCustomProduct(product: ICustomProduct): void;
  checkDescription(product: ProductRecord): boolean;
  splitName(): string[];
  leafName(): string;
  getOptionId(): number;
  decycleToJSON(): any;
  openingEstimations(): IOpeningEstimation[];
  openingEstimationMeasurement(charge_type: string, quantity: number): number;
  forceToZero(): void;
  compareOptions(activatedPriceListId: number, newProductOptionGroups: List<ProductOptionGroupRecord>): any;
  transformRequest(): any;
}

export class EstimateLineItem implements IEstimateLineItem {
  public classy: string;
  public id: number;
  public product_uuid: string;
  public estimate_id: number;
  public product_id: number;
  public estimate_group_id: number;
  public orig_estimate_group_id: number;
  public product: IProduct;
  public color_id: number;
  public sort_order: number;
  public name: string;
  public description: string;
  public cost = 0;
  public price = 0;
  public product_price = 0;
  public labor_price = 0;
  public base_product_price = 0;
  public base_labor_price = 0;
  public visibility: string;
  public quantity = 0;
  public uom = "ea";
  public discountable: AbleProp;
  public markupable: AbleProp;
  public ext_price = 0;
  public msrp = 0;
  public created_at: Date;
  public updated_at: Date;
  public estimateGroup: IEstimateGroup;
  public _destroy: boolean;
  public options: IEstimateLineItemOption[] = [];
  public documents: IDoc[] = [];
  public images: IImage[] = [];
  public product_image_urls: string[] = [];
  public estimate_doc_ids: number[] = [];
  public contract_doc_ids: number[] = [];
  public opening_estimations: IOpeningEstimation[] = [];
  public newly_added: boolean;
  public editing = false;
  public validated = true;
  public validation_result: any = {};

  constructor(estimateGroup: IEstimateGroup) {
    this.estimateGroup = estimateGroup;
    this.classy = "EstimateLineItem";
    this.newly_added = false;
  }

  public optionsType(type: string): IEstimateLineItemOption[] {
    return _.filter(this.options, (opt) => {
      let backwards = false;
      if (type === "default") {
        backwards = _.isUndefined(opt.pog_type);
      }
      return opt.pog_type === type || backwards;
    });
  }

  public existingOptions(): IEstimateLineItemOption[] {
    return _.filter(this.options, (g) => {
      return g._destroy !== true;
    });
  }

  public existingSingleSelectOption(optionGroupId: number): Nullable<IEstimateLineItemOption> {
    const r = _.find(this.existingOptions(), (e: IEstimateLineItemOption) => {
      return e.product_option_group_id === optionGroupId;
    });

    return r || null;
  }

  public existingEliForProductOption(productOptionUuid: string): Nullable<IEstimateLineItemOption> {
    const r = _.find(this.existingOptions(), (elio: IEstimateLineItemOption) => {
      return elio.product_option_uuid === productOptionUuid;
    });

    return r || null;
  }

  public calculate(estimate: IEstimateMarkups): number {
    if (isMarkupable(this)) {
      this.product_price = _.round(this.base_product_price * markupMultiplier(estimate.product_markup), -2);
      this.labor_price = _.round(this.base_labor_price * markupMultiplier(estimate.labor_markup), -2);
    } else {
      this.product_price = this.base_product_price || 0;
      this.labor_price = this.base_labor_price || 0;
    }

    this.price = this.product_price + this.labor_price;

    this.ext_price = _.round((this.price || 0) * (this.quantity || 0), -2);

    return this.ext_price;
  }

  public isVisible(presentation_mode = true): boolean {
    // always, company_only
    if (presentation_mode) {
      return this.visibility === "always" || this.visibility === undefined;
    } else {
      return this.visibility === "always" || this.visibility === "company_only" || this.visibility === undefined;
    }
  }

  public isCustom(): boolean {
    return (this.name !== "" || this.description !== "") && _.isUndefined(this.product_id);
  }

  public optionsTotal() {
    return _.reduce(
      this.options || [],
      (memo: number, option: IEstimateLineItemOption) => {
        if (option._destroy) {
          return memo;
        }
        return memo + option.ext_price;
      },
      0,
    );
  }

  public clone() {
    //noinspection TypeScriptUnresolvedFunction
    const estimate_line_item = EstimateLineItem.fromJSON(this.estimateGroup, JSON.retrocycle(this.decycleToJSON()));
    estimate_line_item.images = _.map(this.images, (i) => i.clone());
    estimate_line_item.documents = _.map(this.documents, (d) => d.clone());
    estimate_line_item.product_id = this.product_id;
    estimate_line_item.opening_estimations = this.opening_estimations;

    _.each(estimate_line_item.opening_estimations, (oe: IOpeningEstimation) => {
      const blah = _.find(_.pluck(this.opening_estimations, "openable"), (o: IOpening) => oe.openable_id === o.id);
      Object.defineProperty(oe, "openable", { value: blah });
    });

    return estimate_line_item;
  }

  public decycleToJSON(): any {
    return angular.fromJson(angular.toJson(JSON.decycle(this)));
  }

  public copyValues(from: IEstimateLineItem) {
    for (const key in from) {
      if (key === "editing") {
      } else if (from.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        this[key] = from[key];
      }
    }
  }

  public setProduct(activatedPriceListId: number, product: ProductRecord, setDescription = true) {
    this.product_id = product.id;
    this.name = product.ancestral_name;
    this.price = product.price;
    this.base_product_price = product.product_price;
    this.base_labor_price = product.labor_price;
    this.cost = product.cost;
    this.msrp = product.msrp;
    this.visibility = product.visibility;
    this.product_uuid = product.uuid;
    this.discountable = product.discountable || "default";
    this.markupable = product.markupable || "default";
    this.uom = product.uom;
    this.product_image_urls = [];
    product.images.forEach((i) => {
      const url = i.file?.url;
      if (url) this.product_image_urls.push(url);
    });
    this.images = [];
    this.documents = [];
    this.estimate_doc_ids = useSelector((state) =>
      cachedAncestorDocuments(state, {
        activatedPriceListId,
        display: "estimate",
        productId: product.id,
      }),
    )
      .map((doc) => doc.id)
      .toArray();
    this.contract_doc_ids = useSelector((state) =>
      cachedAncestorDocuments(state, {
        activatedPriceListId,
        display: "contract",
        productId: product.id,
      }),
    )
      .map((doc) => doc.id)
      .toArray();

    if (setDescription) {
      this.description = product.description;
    }
  }

  public windowPriceAdjustment(product: ProductRecord) {
    switch (product.bracket_type) {
      case "per_ui":
        if (this.openingEstimations().length === 0) {
          return;
        }
        const opening_ui = this.openingEstimations()[0].openable.ui;
        const total_ui = _.round(opening_ui - product.base_measure, -2);
        this.name = this.perName(`${opening_ui} UI`);
        this.calculateWindowPrice(total_ui, product);
        break;
      case "per_sqft":
        if (this.openingEstimations().length === 0) {
          return;
        }
        const opening_area = this.openingEstimations()[0].openable.area;
        const total_area = _.round(opening_area - product.base_measure, -2);
        this.name = this.perName(`${opening_area} SQFT`);
        this.calculateWindowPrice(total_area, product);
        break;
    }
  }

  public setCustomProduct(product: ICustomProduct) {
    this.name = product.name;
    this.product_uuid = product.uuid;
    this.cost = 0;
    this.price = product.price;
    this.base_product_price = product.product_price;
    this.base_labor_price = product.labor_price;
    this.description = product.description;
    this.uom = product.uom;
    this.quantity = product.quantity;
    this.visibility = product.visibility;
  }

  public checkDescription(product: IProduct | ProductRecord) {
    return this.description === product.description;
  }

  public splitName() {
    if (!this.name) {
      return [""];
    }
    let parts = this.name.split(">");
    _.each(parts, (part: string, index: number) => {
      parts[index] = part.trim();
    });

    if (parts.length > 1) {
      parts.splice(-1, 1);
    } else if (this.product && this.product.parent) {
      parts = [];
    }

    return parts;
  }

  public leafName() {
    if (!this.name) {
      return "";
    }
    return this.name.split(">").pop().trim();
  }

  public getOptionId() {
    const firstId = -1;

    const currentMin = _.chain(this.options).pluck("id").min().value();

    return _.min([firstId, currentMin - 1]);
  }

  public openingEstimations() {
    return _.select(this.opening_estimations, (oe: IOpeningEstimation) => !oe._destroy);
  }

  public openingEstimationMeasurement(charge_type: string, quantity: number) {
    if (this.openingEstimations()[0]) {
      switch (charge_type) {
        case "per_ui":
          return this.openingEstimations()[0].openable.ui;
        case "per_sqft":
          return this.openingEstimations()[0].openable.area;
        default:
          return quantity;
      }
    } else {
      return quantity;
    }
  }

  public forceToZero() {
    if (!this.quantity) {
      this.quantity = 0;
    }
    if (!this.price) {
      this.price = 0;
    }
    if (!this.product_price) {
      this.product_price = 0;
    }
    if (!this.labor_price) {
      this.labor_price = 0;
    }
  }

  public compareOptions(activatedPriceListId: number, newOptionGroups: List<ProductOptionGroupRecord>): any {
    const matchedOptions = [];
    const rejectedOptions = [];
    let quantities = Map();
    _.each(this.existingOptions(), (elio: IEstimateLineItemOption) => {
      const elioProductOptionGroup = useSelector((state) =>
        cachedProductOptionGroup(state, {
          activatedPriceListId,
          productOptionGroupId: elio.product_option_group_id,
        }),
      );
      const elioProductOption = useSelector((state) =>
        cachedProductOption(state, {
          activatedPriceListId,
          productOptionId: elio.product_option_id,
        }),
      );

      const new_pog = newOptionGroups.find((nog: ProductOptionGroupRecord) => {
        return nog.match_id === elioProductOptionGroup.match_id;
      });
      if (new_pog) {
        const new_po = useSelector((state) =>
          cachedProductOptionByMatchIdWithIds(state, {
            activatedPriceListId,
            productOptionIds: new_pog.option_ids,
          }),
        );
        if (new_po) {
          quantities = quantities.set(new_po.id, elio.quantity);
          matchedOptions.push(new_po);
        } else {
          rejectedOptions.push(elioProductOptionGroup.name + ": " + elioProductOption.name);
        }
      } else {
        rejectedOptions.push(elioProductOptionGroup.name + ": " + elioProductOption.name);
      }
    });
    return [matchedOptions, quantities, rejectedOptions];
  }

  public transformRequest() {
    const lineItem = JSON.parse(angular.toJson(this.decycleToJSON()));
    delete lineItem.product;
    delete lineItem.estimateGroup;
    delete lineItem.orig_estimate_group_id;
    delete lineItem.editing;
    delete lineItem.validated;
    delete lineItem.validation_result;
    delete lineItem.newly_added;
    delete lineItem.updated_at;
    delete lineItem.created_at;
    if (lineItem.estimate_group_id <= 0) {
      delete lineItem.estimate_group_id;
    }

    (lineItem as any).options_attributes = lineItem.options;
    _.each((lineItem as any).options_attributes, (option: IEstimateLineItemOption) => {
      if (option.id <= 0) {
        delete option.id;
      }
      delete option.estimate_line_item;
    });

    delete lineItem.options;

    (lineItem as any).images_attributes = lineItem.images;
    (lineItem as any).documents_attributes = lineItem.documents;
    (lineItem as any).opening_estimations_attributes = lineItem.opening_estimations;
    delete lineItem.images;
    delete lineItem.documents;

    return lineItem;
  }

  public static fromJSON = (estimateGroup: IEstimateGroup, data: any): IEstimateLineItem => {
    const estimateLineItem = new EstimateLineItem(estimateGroup);
    for (const key in data) {
      if (data.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        switch (key) {
          case "updated_at":
          case "created_at":
            estimateLineItem[key] = new Date(data[key]);
            break;
          case "options":
            estimateLineItem[key] = _.map(data[key] || [], (opt) => {
              return new EstimateLineItemOption(opt);
            });
            break;
          case "estimate_group_id":
            if (data["estimate_group_id"] > 0 && estimateLineItem.orig_estimate_group_id === undefined) {
              estimateLineItem["orig_estimate_group_id"] = data[key];
              estimateLineItem[key] = data[key];
            }
            break;
          case "documents":
            estimateLineItem[key] = Array.from(data[key]);
            break;
          case "images":
            estimateLineItem[key] = Array.from(data[key]);
            break;
          default:
            if (Array.isArray(data[key])) {
              estimateLineItem[key] = Array.from(data[key]);
            } else {
              estimateLineItem[key] = data[key];
            }
            break;
        }
      }
    }

    return estimateLineItem;
  };

  private perName(name) {
    return `${this.name.substring(0, this.name.lastIndexOf(">") + 1)} ${name}`.trim();
  }

  private calculateWindowPrice(factor: number, product: ProductRecord) {
    this.uom = "ea";
    this.base_product_price = _.round(product.base_product_price + product.product_price * factor, -2);
    this.base_labor_price = _.round(product.base_labor_price + product.labor_price * factor, -2);
  }
}

export const IsOptionSelected = (lineItem: IEstimateLineItem, option: ProductOptionRecord): boolean => {
  return _.chain(lineItem.existingOptions())
    .select((elio: IEstimateLineItemOption) => !elio._destroy)
    .any((elio: IEstimateLineItemOption) => {
      return elio.product_option_uuid === option.uuid;
    })
    .value();
};
