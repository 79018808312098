import * as React from "react";
import { RootState } from "app2/src/reducers";
import { useSelector } from "react-redux";
import { token as selectToken } from "app2/src/selectors/token.selectors";
import { Check, Select } from "@tberrysoln/rsf-form";
import { IPretty } from "app/src/Common/PrettyNameService";
import { StoreRegistry } from "app2/src/storeRegistry";

export const SettingsForm: React.FC = () => {
  const token = useSelector((state: RootState) => selectToken(state, { kind: "improveit360" }));
  const platform = token?.getIn(["data", "platform"]);
  const pretty: IPretty = StoreRegistry.get("Pretty");

  return (
    <>
      <Select label="Platform" name="data.platform">
        <option value="classic">{pretty.name["i360_classic"]}</option>
        <option value="lightning">{pretty.name["i360_lightning"]}</option>
      </Select>
      {platform !== "lightning" && (
        <>
          <Check
            label="Create a Prospect Sale in I360 when an Appointment is Resulted"
            name="data.create_prospect_sale.appointment_resulted"
          />
          <Check
            label="Create a Prospect Sale in I360 when an Agreement is Fully Executed"
            name="data.create_prospect_sale.signed_document_signed"
          />
          <Check
            label="Create a Marketing Opportunity in I360 when an Appointment is Resulted"
            name="data.create_marketing_opportunity"
          />
        </>
      )}
    </>
  );
};
