import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import * as tokenActions from "app2/src/reducers/token.actions";
import { FormControl, RsfForm } from "@tberrysoln/rsf-form";
import { required, validateBaseUrl } from "app2/src/helpers/FinalFormValidator";
import { Context } from "app2/src/components/Integrations/LeadPerfection/Context";

const AuthorizationModal: React.FunctionComponent = () => {
  //hooks
  const { orgId, dispatch: contextDispatch } = React.useContext(Context);

  return (
    <Modal show={true}>
      <Modal.Header closeButton>
        <Modal.Title>Lead Perfection</Modal.Title>
      </Modal.Header>

      <RsfForm
        rootPath={["tokens", "byOrgId", orgId, "lead_perfection"]}
        updateFormReducer={tokenActions.Actions.update}
        onSubmit={() => contextDispatch("submit", {})}>
        <Modal.Body>
          <FormControl label="Username" name="data.username" validate={required} />
          <FormControl label="Password" name="data.password" validate={required} />
          <FormControl label="Client ID" name="data.clientid" validate={required} />
          <FormControl label="Base URL" name="data.base_url" validate={validateBaseUrl} />
        </Modal.Body>

        <Modal.Footer key={2}>
          <Button type="button" variant="cancel" className="pull-right" onClick={() => contextDispatch("close", {})}>
            Close
          </Button>
          <Button type="submit" variant="default" className="pull-right">
            Authorize
          </Button>
        </Modal.Footer>
      </RsfForm>
    </Modal>
  );
};

export default AuthorizationModal;
