import * as ng from "angular";
import { IAddress } from "app/src/Models/Address";
import { IActivatedPriceList } from "app/src/Models/ActivatedPriceList";
import { IPaymentTerm } from "app/src/Models/PaymentTerm";
import { IUser } from "app/src/Models/User";
import { IImage } from "app/src/Models/Image";
import { IPresentation } from "app/src/Models/Presentation";
import { IDoc } from "app/src/Models/Doc";
import { ISignatureSettings, SignatureSettings } from "./SignatureSettings";
import { IOrgPreference, DocumentOrder, IOrgPrefTitles } from "./OrgPreference";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IContractLanguage, IOrgSetting, IOrgSettingIntegrations } from "./OrgSetting";
import { Actions } from "../Common/SessionService";
import { IJobStateEventInfo, IJobStateInfo } from "app2/src/records/Job";
import { IJobObjectData } from "app2/src/records/JobAttribute";
import { SupportedTimeZones } from "app2/src/helpers/Time";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import { token } from "app2/src/selectors/token.selectors";
import { RootState } from "app2/src/reducers";
import { TokenRecord } from "app2/src/records/Token";

const emptyValue = [];

export interface IOrg extends ng.resource.IResource<IOrg>, OrgPrototype {
  payment_terms?: IPaymentTerm[];
  images: IImage[];
  presentations: IPresentation[];
  documents: IDoc[];
  cover_pages: any;
  can(kind: "layered" | "setting" | "preference", name: OrgAclType, action: Actions | string): boolean;
  $save(...args: any[]): ng.IPromise<IOrg>;
  $invitePayMe(...args: any[]): ng.IPromise<IOrg>;
  $processMs?(data: any): ng.IPromise<IOrg>;
  $processSf?(data: any): ng.IPromise<IOrg>;
  $processI360?(data: any): ng.IPromise<IOrg>;
  $processJn?(data: any): ng.IPromise<IOrg>;
  $processLp?(data: any): ng.IPromise<IOrg>;
  $tree(data: any): ng.IPromise<IOrg>;
  $stats(): ng.IPromise<IOrg>;
  $csvStats(): ng.IPromise<IOrg>;
}

export interface IOrgResource extends ng.resource.IResourceClass<IOrg>, IRsfResource {
  presentationStrings: string[];
  stateFilters: string[];
  inject(injected: IRepository): void;
  fromJSON?(data: any): IOrg;

  update?(params: any, data: any, success?: Function, error?: Function): IOrg;
  update?(params: any, success?: Function, error?: Function): IOrg;
  byUuid?(data: any): IOrg;
  processMs?(data: any): IOrg;
  processSf?(data: any): IOrg;
  processI360?(data: any): IOrg;
  processJn?(data: any): IOrg;
  processLp?(data: any): IOrg;
  tree?(data: any): IOrg;
  stats?(data: any): any;
  csvStats?(data: any): any;
  globalStats?(data: any): any;
  globalCsvStats?(data: any): any;
}

export interface IOrgResponse extends ng.resource.IResourceArray<IOrg> {
  orgs: IOrg[];
  meta: any;
}

export interface IOrgBaseConfig {
  acl: string[];
}

export interface IJobStateMetadata {
  states: Array<string>;
  state_events: Array<IJobStateEventInfo>;
  state_info: { [key: string]: IJobStateInfo };
}

export enum OrgAclType {
  tools = "tools",
  design_tools = "design_tools",
  document = "document",
  visualizations = "visualizations",
  profile = "profile",
  measurements = "measurements",
  reports = "reports",
  estimator = "estimator",
  sales_tax = "sales_tax",
  job_list = "job_list",
  modules = "modules",
}

export enum OrgPrefType {
  billing = "billing",
  estimator = "estimator",
  email = "email",
  integrations = "integrations",
  job_menu = "job_menu",
  screen_share = "screen_share",
  signature_settings = "signature_settings",
  visualization = "visualization",
  cover_page = "cover_page",
  cronofy = "cronofy",
  document_order = "document_order",
  document_signed_notification = "document_signed_notification",
  layout = "layout",
  marketsharp = "marketsharp",
  measurement = "measurement",
  photos = "photos",
  report_emails = "report_emails",
  sales_tax = "sales_tax",
  send_email = "send_email",
  signatures = "signatures",
  titles = "titles",
  price_presentation = "price_presentation",
  markup = "markup",
  link_expiration = "link_expiration",
  signed_documents = "signed_documents",
}

class OrgPrototype {
  public id: number;
  public parent_id: number;
  public org_count: number;
  public parent: IOrg;
  public children: IOrg[];
  public name: string;
  public phone_number: string;
  public website: string;
  public address: IAddress;
  public settings?: IOrgSetting;
  public preferences?: IOrgPreference;
  public activated_price_list: IActivatedPriceList;
  public activated_inspection_list: IActivatedPriceList;
  public activated_survey_list: IActivatedPriceList;
  public lead_sources?: string[];
  public job_types?: IJobObjectData[];
  public users?: IUser[];
  public contract_languages?: IContractLanguage[];
  public images: IImage[];
  public presentations?: IPresentation[];
  public presentation_count: number;
  public documents: IDoc[];
  public base36_id: string;
  public job_count: number;
  public user_count: number;
  public salesforce_token_id: number;
  public salesforce_authorized: boolean;
  public improveit360_token_id: number;
  public improveit360_authorized: boolean;
  public job_nimbus_token_id: number;
  public job_nimbus_authorized: boolean;
  public status: string;
  public events: string[];
  public job_states: IJobStateMetadata;
  public metadata: any;

  private _savedSetAcl: { string: string[] };
  private _savedPrefAcl: { string: string[] };

  public $save(params?, callback?): ng.IPromise<IOrg> {
    if (this.id && this.id > 0) {
      return (this as any).$update(params, callback);
    }
  }

  public measurementTypes() {
    const types = [];
    if (_.include(this.preferences.config.measurement.job_tab, "windows")) {
      types.push("Window");
    }
    if (_.include(this.preferences.config.measurement.job_tab, "doors")) {
      types.push("Door");
    }
    if (_.include(this.preferences.config.measurement.job_tab, "rooms")) {
      types.push("Room");
    }
    if (_.include(this.preferences.config.measurement.job_tab, "decks")) {
      types.push("Deck");
    }
    return types;
  }

  public showAddMeasurement(where: string) {
    switch (where) {
      case "job_tab":
        return (
          _.include(this.preferences.config.measurement.job_tab, "windows") ||
          _.include(this.preferences.config.measurement.job_tab, "doors") ||
          _.include(this.preferences.config.measurement.job_tab, "rooms")
        );
      case "line_item_editor":
        return (
          _.include(this.preferences.config.measurement.line_item_editor, "windows") ||
          _.include(this.preferences.config.measurement.line_item_editor, "doors") ||
          _.include(this.preferences.config.measurement.line_item_editor, "rooms")
        );
    }
  }

  public showMeasurementSection(what: string, where: string) {
    switch (where) {
      case "job_tab":
        return _.include(this.preferences.config.measurement.job_tab, what);
      case "line_item_editor":
        return _.include(this.preferences.config.measurement.line_item_editor, what);
    }
  }

  public showTotals(presentationMode = false, included = true) {
    // included
    if (included) {
      return this.showIncludedTotal(presentationMode);
    } else {
      return this.showOptionTotal(presentationMode);
    }
  }

  public showIncludedText(presentation_mode = false) {
    if (presentation_mode) {
      return _.include(["always", "customer_only"], this.preferences.config.estimator.show_included_text);
    }
    return _.include(["always", "company_only"], this.preferences.config.estimator.show_included_text);
  }

  public showOptionTotal(presentation_mode = false) {
    if (presentation_mode) {
      return _.include(["always", "customer_only"], this.preferences.config.estimator.show_option_total);
    }
    return _.include(["always", "company_only"], this.preferences.config.estimator.show_option_total);
  }

  public showIncludedTotal(presentation_mode = false) {
    if (presentation_mode) {
      return _.include(["always", "customer_only"], this.preferences.config.estimator.show_included_total);
    }
    return _.include(["always", "company_only"], this.preferences.config.estimator.show_included_total);
  }

  public showEstimateListPricing(presentation_mode = false) {
    if (presentation_mode) {
      return _.include(["always", "customer_only"], this.preferences.config.estimator.show_estimate_list_pricing);
    }
    return _.include(["always", "company_only"], this.preferences.config.estimator.show_estimate_list_pricing);
  }

  public showDiscountDetail(presentation_mode = false, total: number, subtotal: number) {
    if (subtotal !== total) {
      if (presentation_mode) {
        return _.include(["always", "customer_only"], this.preferences.config.estimator.show_discount_detail);
      }
      return _.include(["always", "company_only"], this.preferences.config.estimator.show_discount_detail);
    }
    return false;
  }

  public showDetailedUnitPrice(presentation_mode = false, mode = "", hidden_line_items = false) {
    // never, always, customer_only, company_only, customer_main_only
    // customer_option_only, company_main_only, company_option_only
    // company_customer_main, company_customer_option
    if (presentation_mode) {
      switch (mode) {
        case "option":
          if (!this.activated_price_list.hidden_products && !hidden_line_items) {
            return _.include(
              ["always", "customer_only", "company_customer_option", "customer_option_only"],
              this.preferences.config.estimator.show_detailed_unit_price,
            );
          }
          return false;
        case "main":
          if (!this.activated_price_list.hidden_products && !hidden_line_items) {
            return _.include(
              ["always", "customer_only", "company_customer_main", "customer_main_only"],
              this.preferences.config.estimator.show_detailed_unit_price,
            );
          }
          return false;
        default:
          return false;
      }
    } else {
      switch (mode) {
        case "option":
          return _.include(
            ["always", "company_only", "company_option_only", "company_customer_main", "company_customer_option"],
            this.preferences.config.estimator.show_detailed_unit_price,
          );
        case "main":
          return _.include(
            ["always", "company_only", "company_main_only", "company_customer_main", "company_customer_option"],
            this.preferences.config.estimator.show_detailed_unit_price,
          );
        default:
          return false;
      }
    }
  }

  public showDetailedPricing(presentation_mode = false, mode = "", hidden_line_items = false) {
    // never, always, customer_only, company_only, customer_main_only
    // customer_option_only, company_main_only, company_option_only
    // company_customer_main, company_customer_option

    if (presentation_mode) {
      switch (mode) {
        case "option":
          if (!this.activated_price_list.hidden_products && !hidden_line_items) {
            return _.include(
              ["always", "customer_only", "company_customer_option", "customer_option_only"],
              this.preferences.config.estimator.show_detailed_pricing,
            );
          }
          return false;
        case "main":
          if (!this.activated_price_list.hidden_products && !hidden_line_items) {
            return _.include(
              ["always", "customer_only", "company_customer_main", "customer_main_only"],
              this.preferences.config.estimator.show_detailed_pricing,
            );
          }
          return false;
        default:
          return false;
      }
    } else {
      switch (mode) {
        case "option":
          return _.include(
            ["always", "company_only", "company_option_only", "company_customer_main", "company_customer_option"],
            this.preferences.config.estimator.show_detailed_pricing,
          );
        case "main":
          return _.include(
            ["always", "company_only", "company_main_only", "company_customer_main", "company_customer_option"],
            this.preferences.config.estimator.show_detailed_pricing,
          );
        default:
          return false;
      }
    }
  }

  public showDetailedMeasurements(presentation_mode = false) {
    // never, always, customer_only, company_only
    if (presentation_mode) {
      return _.include(["always", "customer_only"], this.preferences.config.estimator.show_detailed_measurements);
    } else {
      return _.include(["always", "company_only"], this.preferences.config.estimator.show_detailed_measurements);
    }
  }

  public showSalesTax(tax_total: number) {
    if (tax_total > 0) {
      return true;
    }

    return _.include(["enabled"], this.preferences.config.sales_tax.use_sales_tax);
  }

  public showPricePresentation() {
    if (_.isUndefined(this.settings) || _.isUndefined(this.preferences)) {
      return false;
    }
    const set_enable = this.settings.config.price_presentation.enable;
    const pp_pref = this.preferences.config.price_presentation;

    if (_.isUndefined(pp_pref) || _.isUndefined(pp_pref.enable)) {
      return false;
    }

    return set_enable && pp_pref.enable;
  }

  public showImageUploader(presentation_mode = false) {
    if (presentation_mode) {
      return false;
    }

    return _.include(["always"], this.preferences.config.photos.show_image_uploader);
  }

  public estimatorTitle(plural = false) {
    return this.getTitle("estimator", plural);
  }

  public openingNameTitle(plural = false) {
    return this.getTitle("opening_name", plural);
  }

  public estimateTitle(plural = false) {
    return this.getTitle("estimate", plural);
  }

  public contractTitle(plural = false) {
    return this.getTitle("contract", plural);
  }

  // the view won"t wait for a promise to resolve, so this can be called before data fully loaded
  public getTitle(document_type: string, plural = false) {
    let val = "";

    const config = this.fetchPref<IOrgPrefTitles>(OrgPrefType.titles);

    switch (document_type) {
      case "contract":
        val = !config.contract_short ? "Agreement" : config.contract_short;
        break;
      case "estimate":
        val = !config.estimate_short ? "Proposal" : config.estimate_short;
        break;
      case "opening_name":
        val = !config.opening_name ? "Name" : config.opening_name;
        break;
      case "estimator":
        val = !config.estimator ? "Estimate" : config.estimator;
        break;
      case "inspection":
        val = !config.inspection_short ? "Inspection" : config.inspection_short;
        break;
    }

    if (plural) {
      val = val + "s";
    }
    return val;
  }

  public showEstimatedDates() {
    return _.contains(["always"], this.preferences.config.estimator.show_estimated_dates);
  }

  public canSign() {
    if (!this.settings) {
      return false;
    } else {
      const config = this.fetchPref<ISignatureSettings>(OrgPrefType.signature_settings);
      if (!config || _.isEmpty(config)) {
        return false;
      } else {
        return config.signers.length > 0;
      }
    }
  }

  public reportsEnabled(value) {
    return (
      _.contains(this.fetchPreferencesAcl(OrgAclType.reports), value) &&
      _.contains(this.fetchSettingsAcl(OrgAclType.reports), value)
    );
  }

  public hasMs() {
    const marketSharpToken = useSelector((state: RootState) => token(state, { kind: "marketsharp" }) as TokenRecord);
    if (!marketSharpToken) {
      return false;
    }
    const marketSharpCompanyId: any = marketSharpToken?.getIn(["data", "company_id"]);

    return !!marketSharpCompanyId;
  }

  public partialUrl(type: string) {
    return "src/Orgs/partials/" + type + ".html";
  }

  public resourceDocuments() {
    return _.filter(this.documents, (doc) => {
      return doc.displayInResource;
    });
  }

  public fetchPref<T extends IOrgBaseConfig>(key: OrgPrefType): T {
    if (!this.preferences || !this.settings) {
      return {} as any as T;
    }

    const pref: IOrgBaseConfig = this.preferences.config[key.toString()];
    const set: IOrgBaseConfig = this.settings.config[key.toString()];

    if (!pref) {
      return (set || {}) as T;
    }

    if (!set) {
      return (pref || {}) as T;
    }

    const merged: IOrgBaseConfig = _.extend({}, pref, set);

    if (!pref.acl) {
      merged.acl = set.acl || [];
    } else if (!set.acl) {
      merged.acl = pref.acl || [];
    } else {
      merged.acl = _.intersection(pref.acl, set.acl);
    }

    return merged as T;
  }

  public fetchPreferencesAcl(key: OrgAclType): string[] {
    if (!this.preferences) {
      return emptyValue;
    }
    if (!this.preferences.acl[key]) {
      this.preferences.acl[key] = [];
    }

    return this.preferences.acl[key];
  }

  public fetchSettingsAcl(key: OrgAclType): string[] {
    if (!this.settings) {
      return emptyValue;
    }
    if (!this.settings.acl[key]) {
      this.settings.acl[key] = [];
    }
    return this.settings.acl[key];
  }

  public setSettingsAcl(key: OrgAclType, values: string[]): void {
    this.settings.acl[key] = values;
  }

  public setPreferencesAcl(key: OrgAclType, value: string[]): void {
    this.preferences.acl[key] = value;
  }

  public fetchAcl(key: OrgAclType): string[] {
    if (!this.preferences || !this.settings) {
      return [];
    }

    this._savedPrefAcl = this._savedPrefAcl || this.pluckAcl(this.preferences.config);
    this._savedSetAcl = this._savedSetAcl || this.pluckAcl(this.settings.config);

    let pref: string[] = this.preferences.acl[key.toString()];
    if (!pref) {
      pref = this._savedPrefAcl[key.toString()];
    }

    let set: string[] = this.settings.acl[key.toString()];
    if (!set) {
      set = this._savedSetAcl[key.toString()];
    }

    if (!pref) {
      return set || [];
    }

    if (!set) {
      return pref || [];
    }

    const merged = _.intersection(pref, set);
    return merged;
  }

  public can(kind: "setting" | "preference" | "layered", key: OrgAclType, action: Actions | string): boolean {
    let acl, permission;
    switch (kind) {
      case "setting":
        acl = (this.settings || { acl: {} }).acl || {};
        permission = acl[key.toString()] || [];
        break;
      case "preference":
        acl = (this.preferences || { acl: {} }).acl || {};
        permission = acl[key.toString()] || [];
        break;
      case "layered":
        permission = this.fetchAcl(key);
        break;
    }
    return _.include(permission, action);
  }

  public defaultUserAcl(): string {
    if (this.settings.config && this.settings.config.user_acl && this.settings.config.user_acl.default) {
      return this.settings.config.user_acl.default;
    }

    return "salesrep";
  }

  public getMeasurementSystem(): string {
    if (this.preferences.config && this.preferences.config.measurement && this.preferences.config.measurement.system) {
      return this.preferences.config.measurement.system;
    }

    return "imperial";
  }

  protected pluckAcl(config: any): { string: string[] } {
    const keys = Object.keys(config);
    const result: { string: string[] } = {} as { string: string[] };

    _.each(keys, (key) => {
      result[key] = config[key].acl;
    });

    return result;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:id";

  const transformSingleResponse = (response: string, headers: ng.IHttpHeadersGetter, status: number): IOrg => {
    if (status < 200 || status > 299) {
      return new Org({});
    }

    const data: any = JSON.parse(response).org;

    return Org.fromJSON(data);
  };

  const transformRequest = (org: IOrg): string => {
    const data = JSON.parse(angular.toJson(org));

    if (data.address) {
      data.address_attributes = data.address;
      delete data.address;
    }

    if (data.settings) {
      data.settings_attributes = data.settings;
      delete data.settings;
    }

    if (data.preferences) {
      data.preferences_attributes = data.preferences;
      delete data.preferences;
    }

    if (data.activated_price_list) {
      delete data.activated_price_list;
    }
    if (data.activated_inspection_list) {
      delete data.activated_inspection_list;
    }
    if (data.activated_survey_list) {
      delete data.activated_survey_list;
    }

    if (data.contract_languages) {
      data.contract_languages_attributes = data.contract_languages;
      delete data.contract_languages;
    }

    return angular.toJson({ id: org.id, org: data });
  };

  const Org: IOrgResource = <IOrgResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const data: any = JSON.parse(response);

          _.each(data.orgs, (org, index) => {
            data.orgs[index] = Org.fromJSON(org);
          });

          return data;
        },
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      tree: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/tree",
        transformResponse: (response: string) => {
          return JSON.parse(response);
        },
        isArray: false,
      },
      stats: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/stats",
        transformResponse: (response: string) => {
          return JSON.parse(response);
        },
        isArray: false,
      },
      csvStats: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/stats.csv",
        headers: {
          accept: "text/csv",
        },
        responseType: "arraybuffer",
        transformResponse: function (data: string, headers: ng.IHttpHeadersGetter, status: number) {
          if (status < 200 || status > 299) {
            return { response: null };
          }

          let csv;
          if (data) {
            csv = new Blob([data], {
              type: "text/csv",
            });
            return {
              response: csv,
            };
          }
        },
      },
      globalStats: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/global_stats",
        transformResponse: (response: string) => {
          return JSON.parse(response);
        },
        isArray: false,
      },
      globalCsvStats: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/global_stats.csv",
        headers: {
          accept: "text/csv",
        },
        responseType: "arraybuffer",
        transformResponse: function (data: string, headers: ng.IHttpHeadersGetter, status: number) {
          if (status < 200 || status > 299) {
            return { response: null };
          }

          let csv;
          if (data) {
            csv = new Blob([data], {
              type: "text/csv",
            });
            return {
              response: csv,
            };
          }
        },
      },
      invitePayMe: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/invite_payment",
        transformResponse: [],
        isArray: false,
      },
      byUuid: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/api/v1/orgs/uuid/:uuid",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      processMs: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/sync/market_sharp",
        transformRequest: () => {
          return "{}";
        },
        isArray: false,
      },
      processSf: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/sync/salesforce",
        transformRequest: () => {
          return "{}";
        },
        isArray: false,
      },
      processI360: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/sync/improveit360",
        transformRequest: () => {
          return "{}";
        },
        isArray: false,
      },
      processJn: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/sync/job_nimbus",
        transformRequest: () => {
          return "{}";
        },
        isArray: false,
      },
      processLp: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/sync/lead_perfection",
        transformRequest: () => {
          return "{}";
        },
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformResponse: transformSingleResponse,
        transformRequest: transformRequest,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingleResponse,
        transformRequest: transformRequest,
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Org.prototype, OrgPrototype.prototype);

  Org.fromJSON = (data: any): IOrg => {
    if (data.parent) {
      data.parent = Org.fromJSON(data.parent);
    }

    if (data.children && _.isArray(data.children)) {
      _.each(data.children, (e, idx) => {
        data.children[idx] = Org.fromJSON(e);
      });
    } else {
      data.children = [];
    }

    if (_.isArray(data.images)) {
      _.each(data.images, (image, index) => {
        data.images[index] = resources.Image.fromJSON(image);
      });
    }

    if (Array.isArray(data.presentations)) {
      _.each(data.presentations, (presentation, index) => {
        data.presentations[index] = resources.Presentation.fromJSON(presentation);
      });
    }
    if (Array.isArray(data.documents)) {
      _.each(data.documents, (doc, index) => {
        data.documents[index] = resources.Doc.fromJSON(doc);
      });
    }

    if (data.settings && data.settings.config) {
      if (data.settings.config.signature_settings) {
        data.settings.config.signature_settings = SignatureSettings.fromJSON(data.settings.config.signature_settings);
      }

      if (data.settings.config.integrations) {
        if (data.settings.config.integrations.market_sharp) {
          if (data.settings.config.integrations.market_sharp.default_duration) {
            data.settings.config.integrations.market_sharp.default_duration = parseFloat(
              data.settings.config.integrations.market_sharp.default_duration,
            );
          }
        }
      }

      if (!data.settings.config.price_presentation) {
        data.settings.config.price_presentation = { enable: false };
      } else if (!data.settings.config.price_presentation.enable) {
        data.settings.config.price_presentation.enable = false;
      }
      if (!data.settings.config?.link_expiration) {
        data.settings.config.link_expiration = { disabled: true };
      }
    } else {
      data.settings = data.settings || {
        config: { price_presentation: { enable: false } },
      };
    }

    data.settings.acl = data.settings.acl || {};
    data.settings.config = data.settings.config || {};

    if (data.preferences && data.preferences.config) {
      if (data.preferences.config.document_order) {
        data.preferences.config.document_order = DocumentOrder.fromJSON(data.preferences.config.document_order);
      }

      if (!data.preferences.config?.signed_documents) {
        data.preferences.config.signed_documents = {
          expiration_in_days: 60,
        };
      }

      if (data.preferences.config.sales_tax) {
        if (data.preferences.config.sales_tax.default_rate) {
          data.preferences.config.sales_tax.default_rate = parseFloat(data.preferences.config.sales_tax.default_rate);
        }
      }
      if (!data.preferences.config?.link_expiration || data.settings.config?.link_expiration?.disabled) {
        data.preferences.config.link_expiration = {
          proposal: 7,
          agreement: 7,
          signed_agreement: 7,
        };
      }
      if (!data.preferences.config?.timezone) {
        data.preferences.config.timezone = SupportedTimeZones.get(0);
      }
    } else {
      data.preferences = { config: {}, acl: {} };
    }

    data.preferences.acl = data.preferences.acl || {};
    data.preferences.config = data.preferences.config || {};

    if (data.address) {
      data.address = resources.Address.fromJSON(data.address);
    }

    if (data.payment_terms) {
      _.each(data.payment_terms, (pt: any, index: number) => {
        data.payment_terms[index] = resources.PaymentTerm.fromJSON(pt);
      });
    }

    if (_.isArray(data.users)) {
      data.users = _.map(data.users, (u: any) => {
        return resources.User.fromJSON(u);
      });
    } else {
      data.users = [];
    }

    return new Org(data);
  };

  Org.stateFilters = [
    "created",
    "paid",
    "waiting_on_materials",
    "build_in_progress",
    "build_completed",
    "ongoing_support",
    "unclaimed",
    "suspended",
    "canceled",
    "freemium",
  ];

  Org.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Org;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
