import { PlanRecord, IPlanData } from "app2/src/records/billing/Plan";
import * as planService from "app2/src/api/billing/plan.service";
import { RootDispatchType } from "../../store";
import { ThunkResult } from "../index";
import { ActionsUnion, createAction } from "../Utils";
import { handleErrors } from "app2/src/reducers/Utils";
import { AccountRecord } from "app2/src/records/billing/Account";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";

export const FETCH_PLANS = "@plans/FETCH_PLANS";
export const FETCH_PLAN = "@plans/FETCH_PLAN";
export const RECEIVE_PLANS = "@plans/RECEIVE_PLANS";
export const RECEIVE_PLAN = "@plans/RECEIVE_PLAN";
export const REMOVE_PLAN = "@plans/REMOVE_PLAN";
export const RECEIVE_ERRORS = "@plans/RECEIVE_ERRORS";
export const RECEIVE_PLAN_ERROR = "@plans/RECEIVE_PLAN_ERROR";
export const CLEAR_PLANS = "@plans/CLEAR_PLANS";

export const Actions = {
  fetchPlans: () => createAction(FETCH_PLANS),
  fetchPlan: (id: number) => createAction(FETCH_PLAN, { id }),
  receivePlan: (plan: IPlanData) => createAction(RECEIVE_PLAN, { plan }),
  removePlan: (id: number) => createAction(REMOVE_PLAN, { id }),
  receivePlanError: (id: number, errors: string[]) => createAction(RECEIVE_PLAN_ERROR, { id, errors }),
  receiveErrors: (errors: string[]) => createAction(RECEIVE_ERRORS, { errors }),
  receivePlans: (plans: IPlanData[]) => createAction(RECEIVE_PLANS, { plans }),
  clearPlans: () => createAction(CLEAR_PLANS),
};

export const AsyncActions = {
  loadPlan: (planId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPlan(planId));

      try {
        const plan = await planService.load(planId);
        dispatch(Actions.receivePlan(plan.plan));
      } catch (e) {
        dispatch(Actions.receiveErrors(handleErrors(e)));
      }
    };
  },
  listPlans: (options: Partial<planService.IPlanQueryOptions>): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPlans());

      try {
        const plans = await planService.query(options);
        dispatch(Actions.receivePlans(plans.plans));
      } catch (e) {
        dispatch(Actions.receiveErrors(handleErrors(e)));
      }
    };
  },
  archivePlan: (plan: PlanRecord, remove = false): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const planResponse = await planService.archive(plan);
        if (remove) {
          dispatch(Actions.removePlan(planResponse.plan.id));
        } else {
          dispatch(Actions.receivePlan(planResponse.plan));
        }
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Plan Archived"));
      } catch (e) {
        dispatch(Actions.receiveErrors(handleErrors(e)));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, "Problems archiving Plan"));
      }
    };
  },
  unarchivePlan: (plan: PlanRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const planResponse = await planService.unarchive(plan);
        dispatch(Actions.receivePlan(planResponse.plan));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Plan Unarchived"));
      } catch (e) {
        dispatch(Actions.receiveErrors(handleErrors(e)));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, "Problems unarchiving Plan"));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
