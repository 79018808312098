import { IJobResource } from "app/src/Models/Job";
import { IOrg, IOrgResource } from "app/src/Models/Org";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { IBaseConfig } from "../../Common/IBaseConfig";

export class UploaderCtrl {
  public invalid = false;
  public imageTotal: number;
  public imageSuccess: number;
  public org: IOrg;
  public spinnerPromise: ng.IPromise<any>;

  public static $inject = ["$stateParams", "Flash", "Upload", "Job", "Org", "BaseConfig", "$q"];
  constructor(
    public $stateParams: ng.ui.IStateParamsService,
    public Flash: IFlash,
    public Upload: ng.angularFileUpload.IUploadService,
    public Job: IJobResource,
    public Org: IOrgResource,
    public BaseConfig: IBaseConfig,
    public $q: ng.IQService,
  ) {
    if (
      !$stateParams["key"] ||
      $stateParams["key"].split("-").length !== 2 ||
      !$stateParams["token"] ||
      $stateParams["token"].length <= 0
    ) {
      this.invalid = true;
    } else {
      this.org = this.Org.byUuid({ uuid: $stateParams.key.slice(0, $stateParams.key.indexOf("-")) });
    }
  }

  public upload(files: any, invalidFiles: any) {
    if (!files) {
      if (invalidFiles.length > 0) {
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " +
            _.map(invalidFiles, (file: File) => {
              return file.name;
            }).join(", ") +
            ".  Only image file types are allowed.",
        );
      }
      return;
    }

    this.imageTotal = files.length;
    this.imageSuccess = 0;

    let promises = [];
    _.each(files, (file) => {
      promises = promises.concat(
        this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
          url: this.BaseConfig.BASE_URL + "/api/v1/images/" + this.$stateParams["key"],
          data: {
            image: { file: file, folder_id: this.$stateParams["folder_id"] },
            token: this.$stateParams["token"],
          },
        }).then((resp) => {
          this.imageSuccess += 1;
        }),
      );
    });
  }
}
