import { ActionsUnion, createAction } from "./Utils";
import { IEstimateLineItemOptionData } from "../records/EstimateLineItemOption";
import { List } from "immutable";

export const RECEIVE_ESTIMATE_LINE_ITEM_OPTION = "@estimateLineItemOptions/RECEIVE_ESTIMATE_LINE_ITEM_OPTION";
export const DUPLICATE_ESTIMATE_LINE_ITEM_OPTIONS = "@estimateLineItemOptions/DUPLICATE_ESTIMATE_LINE_ITEM_OPTIONS";

export const Actions = {
  receiveEstimateLineItemOption: (estimateLineItemOption: Partial<IEstimateLineItemOptionData>) =>
    createAction(RECEIVE_ESTIMATE_LINE_ITEM_OPTION, estimateLineItemOption),
  duplicateEstimateLineItemOptions: (lineItemId: number, optionIds: List<number>) =>
    createAction(DUPLICATE_ESTIMATE_LINE_ITEM_OPTIONS, { lineItemId, optionIds }),
};

export type Actions = ActionsUnion<typeof Actions>;
