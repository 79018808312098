import { IDoc, IDocResource } from "../Models/Doc";
import { FlashLevels, IFlash } from "./FlashService";
import { ITask, Task } from "../Models/Task";
import { IImageResource } from "../Models/Image";
import { IJob } from "../Models/Job";
import * as angulartics from "angulartics";
import { IBaseConfig } from "./IBaseConfig";
import { validImportTypes } from "app2/src/records/Document";

export interface IMeasurementImporterFactory {
  job: any;
  category: string;
  autoImport: boolean;
  finishedCallback: (result?: any) => void;

  init(job: IJob, autoImport: boolean, category?: string, callback?: (result?: any) => void): void;
  upload(files: any, invalidFiles: any): ng.IPromise<any>;
  importMeasurementFile(doc: any, measure_type: string): ng.IPromise<any>;
}

class MeasurementImporterFactory implements IMeasurementImporterFactory {
  public validImportTypes: string[] = validImportTypes;
  public job: any;
  public category = "Documents";
  public autoImport = false;
  public finishedCallback: (result?: any) => void;

  constructor(
    public Flash: IFlash,
    public Upload: ng.angularFileUpload.IUploadService,
    public BaseConfig: IBaseConfig,
    public Doc: IDocResource,
    public $q: ng.IQService,
    public $http: ng.IHttpService,
    public $timeout: ng.ITimeoutService,
    public Image: IImageResource,
    public $analytics: angulartics.IAnalyticsService,
  ) {}

  public init(job: IJob, autoImport: boolean, category?: string, callback?: (result: any) => void) {
    this.job = job;
    this.autoImport = autoImport;
    if (category) {
      this.category = category;
    }
    if (callback) {
      this.finishedCallback = callback;
    }
  }

  public upload(files: any, invalidFiles: any): ng.IPromise<any> {
    if (!files) {
      if (invalidFiles && invalidFiles.length > 0) {
        this.trackEvent("invalid files", {
          category: this.category,
          files: _.map(invalidFiles, (file: File) => {
            return file.name;
          }),
        });
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " +
            _.map(invalidFiles, (file: File) => {
              return file.name;
            }).join(", ") +
            ".  Only document file types are allowed: (.xls, .xlsx, .csv, .xml, .json).",
        );
      }
      return;
    }
    let promises = [];
    let docSuccess = 0;
    _.each(files, (file) => {
      promises = promises.concat(
        this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
          url: this.BaseConfig.BASE_URL + "/api/v1/jobs/" + this.job.id + "/documents",
          data: { document: { file: file } },
        }).then((resp: any) => {
          let promise: ng.IPromise<any>;
          docSuccess += 1;
          if (_.isArray(resp.data.documents) || _.isArray(resp.data.images)) {
            const promised = [];
            _.each(resp.data.documents, (doc: any) => {
              const newDoc = this.Doc.fromJSON(doc);
              promised.push(this._watchDoc(newDoc));
              this.trackEvent("document uploaded", {
                category: this.category,
                document: newDoc.id,
                file: newDoc.name,
              });
            });
            promise = this.$q.all(promised);
          }

          if (resp.data.document) {
            const newDoc: IDoc = this.Doc.fromJSON(resp.data.document);
            promise = this._watchDoc(newDoc);
            this.trackEvent("uploaded", {
              category: this.category,
              document: newDoc.id,
              file: newDoc.name,
            });
          }
          if (this.autoImport) {
            return promise;
          }
        }),
      );
    });
    return this.$q.all(promises).finally(() => {
      if (docSuccess === 0 && files.length === 0) {
        return;
      }
      let flashLevel = FlashLevels.warning;
      if (docSuccess === files.length) {
        flashLevel = FlashLevels.success;
      }
      if (!this.autoImport) {
        this.Flash.addMessage(flashLevel, docSuccess + "/" + files.length + " Documents successfully uploaded");
      }
      if (!this.autoImport && this.finishedCallback) {
        this.finishedCallback();
      }
    });
  }

  public importMeasurementFile(doc: any, measure_type: string): ng.IPromise<any> {
    return doc.$import({ measure_type: measure_type }).then(() => {
      this.trackEvent("import measurements", {
        category: this.category,
        import_type: doc.import_type,
        document: doc.id,
      });
      this.Flash.addMessage(FlashLevels.success, "Processing Measurement Import for " + doc.name);
      return this._watchMeasurementDoc(doc);
    });
  }

  public trackEvent(action: string, props: any) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        job: this.job.id,
        org: this.job.org_id,
      }),
    );
  }

  public _watchMeasurementDoc(doc: any): ng.IPromise<any> {
    if (!doc.location) {
      return;
    }

    const deferred = this.$q.defer();
    doc.processing = true;

    Task.watch(this.$timeout, this.$http, doc.location, (task: ITask) => {
      if (task.status === "error") {
        this.trackEvent("import measurements error", {
          category: this.category,
          document: doc.id,
          import_type: doc.import_type,
          task: task.id,
        });
        doc.processing = false;
        deferred.reject();
        this.Flash.addMessage(FlashLevels.danger, "There was an error importing your document.");
      }

      if (task.status === "finished") {
        this.trackEvent("import measurements successfully", {
          category: this.category,
          document: doc.id,
          import_type: doc.import_type,
          task: task.id,
        });
        this.Flash.addMessage(FlashLevels.success, "Measurements successfully imported.");
        if (this.autoImport && this.finishedCallback) {
          this.finishedCallback("a");
        }

        doc.processing = false;
        deferred.resolve();
      }
    });
    return deferred.promise;
  }

  public _watchDoc(doc: any) {
    if (!doc.location) {
      return;
    }

    doc.processing = true;
    const deferred = this.$q.defer();

    Task.watch(this.$timeout, this.$http, doc.location, (task: ITask) => {
      if (task.status === "error") {
        doc.processing = false;
        deferred.reject();
        doc.$get();
        this.Flash.addMessage(FlashLevels.danger, "There was an error processing your document.");
      }

      if (task.status === "finished") {
        doc.$get().then(() => {
          if (this.autoImport) {
            if (_.include(this.validImportTypes, doc.import_type)) {
              return this.importMeasurementFile(doc, doc.import_type).then(() => {
                this.Flash.addMessage(FlashLevels.success, "Document successfully processed.");
                doc.processing = false;
                deferred.resolve();
              });
            } else {
              this.trackEvent("import file not supported", {
                category: this.category,
                import_type: doc.import_type,
                document: doc.id,
              });
              this.Flash.addMessage(FlashLevels.danger, "The measurement import file you uploaded is not supported!");
              doc.processing = false;
              deferred.reject();
            }
          } else {
            this.Flash.addMessage(FlashLevels.success, "Document successfully processed.");
            doc.processing = false;
            deferred.resolve();
          }
        });
      }
    });
    return deferred.promise;
  }
}

const factory = (
  Flash: IFlash,
  Upload: ng.angularFileUpload.IUploadService,
  BaseConfig: IBaseConfig,
  Doc: IDocResource,
  $q: ng.IQService,
  $http: ng.IHttpService,
  $timeout: ng.ITimeoutService,
  Image: IImageResource,
  $analytics: angulartics.IAnalyticsService,
) => {
  return new MeasurementImporterFactory(Flash, Upload, BaseConfig, Doc, $q, $http, $timeout, Image, $analytics);
};

factory.$inject = ["Flash", "Upload", "BaseConfig", "Doc", "$q", "$http", "$timeout", "Image", "$analytics"];

export default factory;
