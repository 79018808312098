import { Nullable } from "app2/src/records";
import { Record } from "immutable";

export const fromJSON = (json: Partial<IEventResultReasonData>): EventResultReasonRecord => {
  const eventResultData: IEventResultReasonData = { ...(json as any) };

  return new EventResultReasonRecord(eventResultData);
};

export interface IEventResultReasonData {
  description: string;
  id: number;
  ms_id: string;
  org_id: number;
  position: number;
  kind: string;
  events_result_id: Nullable<number>;
}

export interface IEventResultReasonRecord {
  description: string;
  id: number;
  ms_id: string;
  org_id: number;
  position: number;
  kind: string;
  events_result_id: number;
}

export const defaultEventResultReasonProps: IEventResultReasonData = {
  description: "",
  id: 0,
  ms_id: "",
  org_id: 0,
  position: 0,
  kind: "default",
  events_result_id: 0,
};

export class EventResultReasonRecord extends Record(defaultEventResultReasonProps) implements IEventResultReasonData {}
