import * as React from "react";
import { Nullable } from "app2/src/records";
import { useMemo, useState } from "react";
import { Actions } from "app2/src/reducers/user.actions";
import { Row, Col, Button } from "react-bootstrap";
import { FormControl, Select } from "@tberrysoln/rsf-form";
import { required, email, composeValidators } from "app2/src/helpers/FinalFormValidator";
import { UserRecord, generatePassword } from "app2/src/records/UserRecord";
import { useDispatch } from "react-redux";
import { Accesses } from "app2/src/components/OrgTabs/UsersTab/Editor/Accesses";
import { Can } from "app2/src/components/Common/CanComponent";
import * as config from "react-global-configuration";

export interface EditorProps {
  userId: number;
}

const requiredAndEmail = composeValidators(required, email);

export const Editor: React.FC<EditorProps> = ({ userId }) => {
  // hooks
  const dispatch = useDispatch();
  const rootPath = useMemo(() => ["users", "usersById", userId], [userId]);
  const [filledPasswd, setFilledPasswd] = useState<Nullable<string>>(null);
  const [passwdCopied, setPasswdCopied] = useState<boolean>(false);

  // methods
  const fillPassword = () => {
    const passwd = generatePassword();

    setInput("password", passwd);
    setInput("password_confirmation", passwd);

    setFilledPasswd(passwd);
    setPasswdCopied(false);
  };

  const setInput = (name: string, value: string) => {
    dispatch(Actions.updateForm({ rootPath, name, value }));
  };

  const validatePasswordMatch = (data: string, userRecord: UserRecord) => {
    if (userRecord?.get("password") !== userRecord?.get("password_confirmation")) {
      return "Password Confirmation must match Password";
    }
    return undefined;
  };

  if (!userId) return null;

  return (
    <>
      <Row>
        <Col md={6}>
          <FormControl label="First Name" name="first_name" validate={required} />
        </Col>
        <Col md={6}>
          <FormControl label="Last Name" name="last_name" validate={required} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormControl label="Email" name="email" validate={requiredAndEmail} />
        </Col>
        <Col md={6}>
          <FormControl label="Phone Number" name="phone" />
        </Col>
      </Row>
      {!(config.get("COGNITO_ENABLED") === "true" && userId < 0) && (
        <>
          <Row>
            <Col md={6}>
              <FormControl
                formControlProps={{ type: "password" }}
                label="Password"
                name="password"
                validate={(data) => (userId > 0 ? undefined : required(data))}
              />
            </Col>
            <Col md={6}>
              <FormControl
                formControlProps={{ type: "password" }}
                label="Password Confirmation"
                name="password_confirmation"
                validate={validatePasswordMatch}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Button onClick={fillPassword}>Generate Password</Button>
            </Col>
            <Col md={6}>
              {filledPasswd && (
                <>
                  <span data-testid="gen-password">Generated Password: {filledPasswd} </span>
                  <span
                    onClick={() => {
                      navigator.clipboard.writeText(filledPasswd);
                      setPasswdCopied(true);
                    }}>
                    <i className="rsf-clipboard-link" />
                    {passwdCopied && <i className="green-text fa fa-check" />}
                  </span>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
      <Can resource="Global" permission="user">
        <Row className="flex-grow-0 flex-shrink-0 flex-basis-100">
          <Col md={12}>
            <Accesses userId={userId} />
          </Col>
        </Row>
      </Can>
    </>
  );
};
