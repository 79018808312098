import { ICommand } from "app/src/Commands/Command";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";

export class EditLineItemCmd implements ICommand {
  constructor(
    public src: IEstimateLineItem,
    public dest: IEstimateLineItem,
    public groups: IEstimateGroup[],
    public group?: IEstimateGroup,
  ) {}

  public execute(): boolean {
    this.dest.copyValues(this.src);

    if (this.group) {
      let switched = false;

      if (this.dest.estimateGroup.id !== this.group.id) {
        switched = true;
        const fromGroup = _.find(this.groups, (g) => {
          return g.id === this.dest.estimateGroup.id;
        });
        const index = _.findIndex(fromGroup.line_items, (li) => {
          return li.id === this.dest.id;
        });
        fromGroup.line_items.splice(index, 1);
      } else {
        this.dest.estimateGroup = _.find(this.groups, (g) => {
          return g.id === this.dest.estimateGroup.id;
        });
      }

      if (switched) {
        const max_line_item = _.max(this.group.line_items, (li) => {
          return li.sort_order;
        });
        if ((max_line_item as any as number) === -Infinity) {
          this.dest.sort_order = 0;
        } else {
          this.dest.sort_order = max_line_item.sort_order + 1;
        }
        this.group.line_items.push(this.dest);
        this.dest.estimateGroup = this.group;
        if (this.dest.id > 0) {
          this.dest.estimate_group_id = this.group.id;
        }
      }
    }

    _.each(this.dest.options, (op: IEstimateLineItemOption) => {
      op.estimate_line_item = this.dest;
    });

    return true;
  }
}
