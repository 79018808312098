import * as React from "react";
import useRoomState from "app2/src/components/Twilio/hooks/useRoomState/useRoomState";
import LocalVideoPreview from "app2/src/components/Twilio/components/LocalVideoPreview";
import Room from "app2/src/components/Twilio/components/Room";
import { useTracking } from "react-tracking";

export default function Main() {
  const roomState = useRoomState();
  const preMeeting = roomState === "disconnected";
  const { Track } = useTracking({ preMeeting });

  return (
    <Track>
      <div className="video-container">{roomState === "disconnected" ? <LocalVideoPreview /> : <Room />}</div>
      {/* <ReconnectingNotification /> */}
    </Track>
  );
}
