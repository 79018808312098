import * as ng from "angular";
import { IBaseConfig } from "../Common/IBaseConfig";

class GmapsAutocompleteCtrl implements ng.IComponentController {
  [s: string]: any;
  public placeChanged: (params: any) => void;
  public address: string;
  public focus: number;
  public input: any;
  public autocomplete: any;

  public static $inject = ["$scope", "BaseConfig"];

  constructor(
    public $scope: ng.IScope,
    public BaseConfig: IBaseConfig,
  ) {
    this.input = document.getElementById("ac-input");
    this.autocomplete = new google.maps.places.Autocomplete(this.input);

    this.autocomplete.setComponentRestrictions({ country: this.BaseConfig.AUTOCOMPLETE_COUNTRIES });

    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();

      this.placeChanged({ place: place });
    });
  }
}

export class GmapsAutocompleteComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/GoogleMapsAutocomplete/gmaps_autocomplete_component.html";
  public bindings: any = {
    address: "<",
    focus: "<",
    placeChanged: "&",
  };

  constructor() {
    this.controller = GmapsAutocompleteCtrl;
  }
}
