import * as React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { DateFormat } from "app2/src/helpers/Format";

export interface OrgListDefaultRowProps {
  org: OrgRecord;
  rowClickHandler: (orgId: number) => void;
}

export const OrgListDefaultRow: React.FC<OrgListDefaultRowProps> = ({ org, rowClickHandler }) => {
  const img_url = org.get("images").first()?.file?.thumb?.url;
  return (
    <Row className="align-items-center py-3 border-top link mw-100 m-0" onClick={() => rowClickHandler(org.get("id"))}>
      <Col>{img_url && <img src={img_url} alt={`${org.get("name")} logo`} />}</Col>
      <Col>{org.get("id")}</Col>
      <Col>{org.get("name")}</Col>
      <Col>{org.get("phone_number")}</Col>
      <Col>{DateFormat(org.get("created_at"))}</Col>
      <Col>{DateFormat(org.get("updated_at"))}</Col>
      <Col>{org.get("status")}</Col>
      <Col>
        <Button>Show</Button>
      </Col>
    </Row>
  );
};
