import * as estimateLineItemOptionActions from "./estimateLineItemOption.actions";
import { Map, Record, List } from "immutable";
import { EstimateLineItemOptionRecord, fromJSON } from "../records/EstimateLineItemOption";
import { RootState, RootActions } from ".";

export const EstimateLineItemOptionStateRecord = Record({
  byId: Map<number, EstimateLineItemOptionRecord>(),
  lastSavedById: Map<number, EstimateLineItemOptionRecord>(),
  unsavedId: -1,
});

export const initialState = EstimateLineItemOptionStateRecord();

export type EstimateState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "estimateLineItemOptions";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let estimateLineItemOption: EstimateLineItemOptionRecord;
  switch (action.type) {
    case estimateLineItemOptionActions.RECEIVE_ESTIMATE_LINE_ITEM_OPTION:
      estimateLineItemOption = fromJSON({ ...action.payload });

      return state
        .setIn([model, "byId", estimateLineItemOption.id], estimateLineItemOption)
        .setIn([model, "lastSavedById", estimateLineItemOption.id], estimateLineItemOption);

    case estimateLineItemOptionActions.DUPLICATE_ESTIMATE_LINE_ITEM_OPTIONS:
      const lineItem = state.getIn(["estimateLineItems", "byId", action.payload.lineItemId]);

      let optionIds = List();
      let unsavedId = state.getIn([model, "unsavedId"]);
      action.payload.optionIds.map((optId: number) => {
        let option = state.getIn([model, "byId", optId]);
        option = option.set("id", unsavedId);
        optionIds = optionIds.push(unsavedId);
        state = state.setIn([model, "byId", option.id], option);
        unsavedId -= 1;
      });

      return state
        .setIn(["estimateLineItems", "byId", lineItem.id, "option_ids"], optionIds)
        .setIn([model, "unsavedId"], unsavedId);

    default:
      return state;
  }
};
