import { Record } from "immutable";

export const fromJSON = (json: Partial<IStatsData>): StatsRecord => new StatsRecord(json);

export interface IStatsData {
  stats: IStatisticData[];
  total: number;
}

export interface IStatisticData {
  name: string;
  color: string;
  count: number;
}

export interface IStatsRecord extends IStatsData {}

export const defaultStatsProps = {
  stats: [],
  total: 0,
};

export class StatsRecord extends Record<IStatsRecord>(defaultStatsProps) implements IStatsRecord {}
