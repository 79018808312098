import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IPhoneNumber } from "./PhoneNumber";

export interface IUserPreference extends ng.resource.IResource<IUserPreference>, UserPreferencePrototype {}

export interface IUserPreferenceResource extends ng.resource.IResourceClass<IUserPreference>, IRsfResource {
  fromJSON?(data: any): IUserPreference;
}

export interface IUserNotificationPreferences {
  email: string;
  via_email: string[];
  via_sms: string[];
}

export interface IUserPreferenceConfig {
  estimator_tour: boolean;
  job_info_tour: boolean;
  job_show_tour: boolean;
  notifications: IUserNotificationPreferences;
}

export enum UserPrefConfigType {
  estimator_tour = "estimator_tour",
  job_info_tour = "job_info_tour",
  job_show_tour = "job_show_tour",
  job_list_card_view = "job_list_card_view",
}

class UserPreferencePrototype {
  public id: number;
  public user_id: number;
  public estimator_tour: boolean;
  public config: IUserPreferenceConfig;
  public job_list_card_view: boolean;
  public sms_number: IPhoneNumber;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/users/:user_id/user_preferences/:id";

  const transformSingle = (response: string, headers: ng.IHttpHeadersGetter, status: number): IUserPreference => {
    if (status < 200 || status >= 300) {
      return JSON.parse(response);
    }

    return UserPreference.fromJSON(JSON.parse(response).user_preference);
  };

  const transformRequest = (user_preference: IUserPreference): string => {
    const data = JSON.parse(angular.toJson(user_preference));

    if (data.sms_number) {
      if (data.sms_number.id <= 0) {
        delete data.sms_number.id;
      }

      data.sms_number_attributes = data.sms_number;
      delete data.sms_number;
    }

    return angular.toJson({ user_preference: data });
  };

  const UserPreference: IUserPreferenceResource = <IUserPreferenceResource>$resource(
    url,
    { id: "@id", user_id: "@user_id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: transformSingle,
      },
    },
  );

  UserPreference.fromJSON = (data: any) => {
    const preference = new UserPreference(data);

    if (preference.config) {
      preference.config = JSON.parse(angular.toJson(preference.config));
    } else {
      preference.config = {} as IUserPreferenceConfig;
    }

    if (!preference.config.notifications) {
      preference.config.notifications = {
        email: null,
        via_email: [],
        via_sms: [],
      };
    }
    if (!preference.config.notifications.email) {
      preference.config.notifications.email = null;
    }
    if (!preference.config.notifications.via_email) {
      preference.config.notifications.via_email = [];
    }
    if (!preference.config.notifications.via_sms) {
      preference.config.notifications.via_sms = [];
    }
    if (data.sms_number) {
      preference.sms_number = resources.PhoneNumber.fromJSON(data.sms_number);
    } else {
      preference.sms_number = resources.PhoneNumber.fromJSON({});
    }

    return preference;
  };

  _.hiddenExtend(UserPreference.prototype, UserPreferencePrototype.prototype);

  UserPreference.inject = (injected: IRepository) => {
    resources = injected;
  };

  return UserPreference;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
