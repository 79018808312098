import { LatLngLiteral } from "leaflet";
import { IAddress, IAddressResource } from "../../Models/Address";

class PinDropCtrl implements ng.IComponentController {
  public address: IAddress;
  public update: (params: any) => void;
  public addressClone: IAddress;
  public tilt: boolean;

  protected mapPromise: ng.IPromise<any>;

  private _last: any;
  private _debouncedChange;

  public static $inject = ["NgMap", "$timeout", "Address"];
  constructor(
    public ngMap: angular.map.INgMap,
    protected $timeout: ng.ITimeoutService,
    protected Address: IAddressResource,
  ) {}

  public $onInit() {
    this.mapPromise = this.ngMap
      .getMap(<angular.map.IGetMapOptions>{ id: "addressMap" })
      .then((map: google.maps.Map) => {
        map.addListener("center_changed", () => {
          const center = map.getCenter();
          let updated = false;

          if (this.addressClone.lat !== center.lat()) {
            this.addressClone.lat = center.lat();
            updated = true;
          }
          if (this.addressClone.lon !== center.lng()) {
            this.addressClone.lon = center.lng();
            updated = true;
          }

          if (updated && this.update) {
            this._debouncedChange({ address: this.addressClone });
          }
        });

        if (!this.tilt) {
          map.setTilt(0);
        }

        google.maps.event.trigger(map, "resize");
        return map;
      });

    this._debouncedChange = _.debounce(this.update, 300);
  }

  public $doCheck() {
    if (!this.address) {
      return;
    }

    if (angular.equals(this.address.location(true), this._last)) {
      return true;
    }

    this.addressClone = this.address.clone();

    this._last = this.addressClone.location(true);

    this.mapPromise.then((map: google.maps.Map) => {
      google.maps.event.trigger(map, "resize");
      map.setCenter(this.addressClone.location(true) as any as LatLngLiteral);
    });
  }
}

export class PinDropComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Jobs/Components/pin_drop.html";
  public bindings: any = {
    address: "<",
    update: "&",
    tilt: "<",
  };

  constructor() {
    this.controller = PinDropCtrl;
  }
}
