import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IResult extends ng.resource.IResource<IResult>, ResultPrototype {}

export interface IResultResource extends ng.resource.IResourceClass<IResult>, IRsfResource {
  fromJSON?(data: any): IResult;
}

export interface IResultResponse extends ng.resource.IResourceArray<IResult> {
  results: IResult[];
  meta: IPagingMetadata;
}

class ResultPrototype {
  public id: number;

  public $saveOrCreate(params?: any, callback?) {
    if (this.id <= 0 || (this.id as any as string) === "Unsaved") {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$save(params, callback);
    }
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, $http: ng.IHttpService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/events/results/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IResult => {
    if (status < 200 || status > 299) {
      return new Result({});
    }

    return Result.fromJSON(JSON.parse(response).result);
  };

  const multiResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return { results: [], metadata: {} };
    }

    const meta: any = JSON.parse(response);

    _.each(meta.results, (child, index) => {
      meta.results[index] = Result.fromJSON(child);
    });

    return meta;
  };

  const transformRequest = (result: IResult): string => {
    const data = JSON.decycle(result);
    return angular.toJson({ result: data });
  };

  const Result: IResultResource = <IResultResource>$resource(
    url,
    {},
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multiResponseTransform,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
      },
    },
  );

  Result.fromJSON = (data: any): IResult => {
    return new Result(data);
  };

  _.hiddenExtend(Result.prototype, ResultPrototype.prototype);

  Result.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Result;
};

factory.$inject = ["$resource", "$http", "BaseConfig"];

export default factory;
