import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export type PhoneNumberSmsStatus = "subscribed" | "unsubscribed";
export interface IPhoneNumber extends ng.resource.IResource<IPhoneNumber>, PhoneNumberPrototype {}

export interface IPhoneNumberResource extends ng.resource.IResourceClass<IPhoneNumber>, IRsfResource {
  fromJSON?(data: any): IPhoneNumber;
}

class PhoneNumberPrototype {
  public id: number;
  public name: string;
  public number: string;
  public status: PhoneNumberSmsStatus;

  public created_at: Date;
  public updated_at: Date;

  public _destroy: boolean;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/phone_numbers/:id";

  const PhoneNumber: IPhoneNumberResource = <IPhoneNumberResource>$resource(
    url,
    { id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return new PhoneNumber({});
          }

          const data = JSON.parse(response).estimate;
          data.created_at = new Date(data.created_at as string);
          data.updated_at = new Date(data.updated_at as string);
          return new PhoneNumber(data);
        },
        isArray: false,
      },
    },
  );

  _.hiddenExtend(PhoneNumber.prototype, PhoneNumberPrototype.prototype);

  PhoneNumber.fromJSON = (data: any): IPhoneNumber => {
    if (!data.status) {
      data.status = "subscribed";
    }

    return new PhoneNumber(data);
  };

  PhoneNumber.inject = (injected: IRepository) => {
    resources = injected;
  };

  return PhoneNumber;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
