import { EstimateComparisonLinkRecord } from "app2/src/records/EstimateComparisonLink";
import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { lastSavedRecordsForEstimate, recordsForEstimate } from "app2/src/selectors/recordsForEstimate.selectors";

export const estimateComparison = (state: RootState, props: any) =>
  state.getIn(["estimateComparisons", "byJobId", props.jobId]);
export const lastSavedEstimateComparison = (state: RootState, props: any) =>
  state.getIn(["estimateComparisons", "lastSavedByJobId", props.jobId]);
export const jobEstimateComparisonLinks = (state: RootState, props: any) =>
  state.getIn(["estimateComparisons", "byJobId", props.jobId, "estimate_comparison_links"]);
export const expandDrawer = (state: RootState) => state.getIn(["estimateComparisons", "expandDrawer"]);

export const estimateComparisonLinks = createSelector(
  [jobEstimateComparisonLinks],
  (jobEstimateComparisonLinks: List<EstimateComparisonLinkRecord>) => {
    return jobEstimateComparisonLinks.filter((ecl) => !ecl._destroy);
  },
);

export const estimateComparisonChange = createSelector(
  [estimateComparison, lastSavedEstimateComparison, (state: RootState) => state],
  (estimateComparison, lastSavedEstimateComparison, state): boolean => {
    if (!lastSavedEstimateComparison) {
      return true;
    }
    if (estimateComparison.equals(lastSavedEstimateComparison)) {
      // returns false if all estimates are the same as their last saved version, true otherwise
      return (
        estimateComparison.estimate_comparison_links.find((ecl) => {
          const currentEstimate = recordsForEstimate(state, { estimateId: ecl.estimate_id });
          if (!currentEstimate) {
            return false;
          }
          const lastSavedEstimate = lastSavedRecordsForEstimate(state, { estimateId: ecl.estimate_id });
          // returns a value if true, undefined otherwise
          return !currentEstimate.equals(lastSavedEstimate);
        }) !== undefined
      );
    }
    return true;
  },
);
