import { combineReducers } from "redux-immutable";
import { Map } from "immutable";
import { reducer as hoverReducer, initialState as HoverInitialState } from "./integrations/hover.reducer";
import { Actions as hoverActions } from "./integrations/hover.actions";
import {
  reducer as quickMeasureReducer,
  initialState as QuickMeasureInitialState,
} from "app2/src/reducers/integrations/quickMeasure.reducer";
import { Actions as quickMeasureActions } from "app2/src/reducers/integrations/quickMeasure.actions";
import { reducer as eagleviewReducer, initialState as EagleviewInitialState } from "./integrations/eagleview.reducer";
import { Actions as eagleviewActions } from "./integrations/eagleview.actions";
import { reducer as paysimpleReducer, initialState as PaySimpleInitialState } from "./integrations/paysimple.reducer";
import { Actions as paysimpleActions } from "./integrations/paysimple.actions";
import {
  reducer as cardconnectReducer,
  initialState as CardConnectInitialState,
} from "./integrations/cardconnect.reducer";
import { Actions as cardconnectActions } from "./integrations/cardconnect.actions";
import { Actions as roofScopeActions } from "./integrations/roofscope.actions";
import { reducer as roofScopeReducer, initialState as RoofScopeInitialState } from "./integrations/roofscope.reducer";

export const IntegrationsInitialState = Map({
  hover: HoverInitialState,
  eagleview: EagleviewInitialState,
  paysimple: PaySimpleInitialState,
  cardconnect: CardConnectInitialState,
  quickMeasure: QuickMeasureInitialState,
  roofScope: RoofScopeInitialState,
});

export type IntegrationsState = typeof IntegrationsInitialState;

export const reducer = combineReducers<IntegrationsState, IntegrationsActions>({
  //@ts-ignore
  hover: hoverReducer,
  eagleview: eagleviewReducer,
  paysimple: paysimpleReducer,
  quickMeasure: quickMeasureReducer,
  cardconnect: cardconnectReducer,
  roofScope: roofScopeReducer,
});

export type IntegrationsActions =
  | hoverActions
  | eagleviewActions
  | paysimpleActions
  | cardconnectActions
  | quickMeasureActions
  | roofScopeActions;
