import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IAddressData>): AddressRecord => {
  json.lat = parseFloat(json.lat as any);
  json.lon = parseFloat(json.lon as any);
  return new AddressRecord(json);
};

export const toJSON = (address: AddressRecord): IAddressData => {
  const addr = address.toJSON() as IAddressData;

  if (addr.id <= 0) {
    delete addr.id;
  }
  return addr;
};

export const toOneLiner = (address: AddressRecord): string => {
  let oneLiner = `${address.line_1 || ""} ${address.line_2 || ""}`;
  oneLiner = `${oneLiner.trim()} ${address.city || ""}`;
  oneLiner = `${oneLiner.trim()}${address.state ? "," : ""} ${address.state || ""}`;
  oneLiner = `${oneLiner.trim()} ${address.postal_code || ""}`.trim();

  return oneLiner;
};

export const toCityStateZip = (address: AddressRecord): string => {
  let csz = `${address.city || ""}`;
  csz = `${csz.trim()}${address.state ? "," : ""} ${address.state || ""}`;
  csz = `${csz.trim()} ${address.postal_code || ""}`.trim();

  return csz;
};

export const toMultiLiner = (address: AddressRecord): string => {
  let line = `${address.line_1 || ""}\n${address.line_2 || ""}\n`;
  line = `${line.trim()}\n${address.city || ""}`;
  line = `${line.trim()}${!address.city ? "\n" : ""}${address.state && address.city ? ", " : ""}${address.state || ""}`;
  line = `${line.trim()} ${address.postal_code || ""}`.trim();

  return line;
};

export const toLatLng = (address: AddressRecord): Nullable<{ latitude: number; longitude: number }> => {
  if (!address || !address.get("lat") || !address.get("lon")) {
    return null;
  }

  return {
    latitude: address.get("lat") || 0,
    longitude: address.get("lon") || 0,
  };
};

export type AddressKind = "job" | "customer";

export interface IAddressData {
  id: number;
  kind?: AddressKind;
  description?: string;
  addressable_id?: number;
  addressable_type?: string;
  line_1?: string;
  line_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
  lat?: number;
  lon?: number;
  administrative_area_level_2?: string;
  administrative_area_level_3?: string;

  created_at: Date;
  updated_at: Date;
}

export interface IAddressRecord {
  id: Nullable<number>;
  kind: Nullable<AddressKind>;
  description: Nullable<string>;
  addressable_id: Nullable<number>;
  addressable_type: Nullable<string>;
  line_1: Nullable<string>;
  line_2: Nullable<string>;
  city: Nullable<string>;
  state: Nullable<string>;
  postal_code: Nullable<string>;
  country: Nullable<string>;
  lat: Nullable<number>;
  lon: Nullable<number>;
  administrative_area_level_2: Nullable<string>;
  administrative_area_level_3: Nullable<string>;
  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

export const defaultAddressProps = {
  id: 0,
  kind: null,
  description: null,
  addressable_id: null,
  addressable_type: null,
  line_1: null,
  line_2: null,
  city: null,
  state: null,
  postal_code: null,
  country: null,
  lat: null,
  lon: null,
  administrative_area_level_2: null,
  administrative_area_level_3: null,
  created_at: null,
  updated_at: null,
};

export class AddressRecord extends Record<IAddressRecord>(defaultAddressProps) implements IAddressRecord {}
