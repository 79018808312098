import * as React from "react";
import { Fragment } from "react";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Button, Col } from "react-bootstrap";
import { FinanceOptionRecord } from "app2/src/records/FinanceOption";
import { CalculatedFinancingRecord } from "app2/src/records/CalculatedFinancing";
import { CurrencyFormat } from "app2/src/helpers/Format";
import * as FontAwesome from "react-fontawesome";
import { RootDispatchType } from "app2/src/store";
import { connect, ConnectedProps } from "app2/src/connect";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { List } from "immutable";

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: FinanceOptionDisplayProps) => {
  return {
    calculateFinanceOptions: (financeOptionIds: List<number>) =>
      dispatch(
        estimateActions.AsyncActions.reduxCalculateCalculatedFinancing(ownProps.estimateId, financeOptionIds, true),
      ),
  };
};

const connector = connect(null, mapDispatchToProps);
interface FinanceOptionDisplayProps {
  estimateId: number;
  financeOption: FinanceOptionRecord;
  calculatedFinancing: CalculatedFinancingRecord;
  kind: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FinanceOptionDisplayProps;

export class FinanceOptionDisplay extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { financeOption, calculatedFinancing, kind, calculateFinanceOptions } = this.props;
    let payment = 0;
    if (calculatedFinancing && !calculatedFinancing.loading) {
      payment = calculatedFinancing.monthly_payment;
      if (kind === "promo") {
        payment = calculatedFinancing.promo_payment;
      }
    }
    let term = financeOption.term;

    let rate = financeOption.rate;
    if (kind === "promo") {
      term = financeOption.promo_term;
      rate = financeOption.promo_rate;
    }
    return (
      <Fragment>
        <Col sm={4} lg={4} className="no-padding-col">
          {!calculatedFinancing || calculatedFinancing.loading ? (
            <div style={{ height: "36px", width: "36px" }} data-testid="spinner">
              <SpinnerComponent inline localProperty={true} />
            </div>
          ) : (
            <Fragment>
              {calculatedFinancing?.error ? (
                <Button variant="link" onClick={() => calculateFinanceOptions(List([financeOption.id]))}>
                  <FontAwesome name="refresh fa-gray" size="lg" title={calculatedFinancing?.error} />
                </Button>
              ) : (
                <Fragment>
                  <span className="text-larger">{CurrencyFormat(payment, 0)}*</span>
                  <span className="finance-label text-smallest">/month</span>
                </Fragment>
              )}
            </Fragment>
          )}
        </Col>
        <Col sm={3} lg={3} className="no-padding-col">
          {!financeOption.formula && (
            <Fragment>
              <span className="finance-value text-large">{term}</span>
              <span className="finance-label text-smallest">months</span>
            </Fragment>
          )}
        </Col>
        <Col sm={4} lg={4} className="no-padding-col">
          {!financeOption.formula && (
            <Fragment>
              <span className="finance-value text-large">{rate}%</span>
              <span className="finance-label text-smallest">Interest Rate</span>
            </Fragment>
          )}
        </Col>
      </Fragment>
    );
  }
}

export default connector(FinanceOptionDisplay);
