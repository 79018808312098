import { connectFC } from "app2/src/connect";
import * as React from "react";
import { useEffect } from "react";
import {
  Check,
  FormControlDate,
  ReactSelect,
  RsfForm,
  Select,
  fromJSON as reactSelectRecordFromJSON,
} from "@tberrysoln/rsf-form";
import * as FontAwesome from "react-fontawesome";
import { Can } from "app2/src/components/Common/CanComponent";
import { JobAttributeTypes, orgNameAddress } from "app2/src/records/OrgRecord";
import { getFullName, UserRecord } from "app2/src/records/UserRecord";
import { leadSources as leadSourcesSelector } from "app2/src/selectors/user.selectors";
import { RootState } from "app2/src/reducers";
import { Actions as JobActions } from "app2/src/reducers/job.actions";
import * as jobAttributeActions from "app2/src/reducers/jobAttribute.actions";
import { getActiveJobAttributesList } from "app2/src/selectors/jobAttribute.selectors";
import * as orgActions from "app2/src/reducers/org.actions";
import * as userActions from "app2/src/reducers/user.actions";
import { currentUser } from "app2/src/selectors/user.selectors";
import {
  jobQuery as jobQuerySelector,
  jobStates as jobStatesSelector,
  jobQueryChanged,
} from "app2/src/selectors/job.selectors";
import { Button, Col, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { formatISO, subYears } from "date-fns";
import { RsfQueryableDropdown } from "../RsfForms/RsfQueryableDropdown";
import { org } from "app2/src/selectors/org.selectors";
import { QueryParamsRecord } from "app2/src/records/Page";
import { JobObjectRecord } from "app2/src/records/JobAttribute";
import { IPretty } from "app/src/Common/PrettyNameService";
import { StoreRegistry } from "app2/src/storeRegistry";
import { lastChangesJobQuery as lastChangesJobQuerySelector } from "app2/src/selectors/job.selectors";
import { useTracking } from "react-tracking";
import { calculateUpdatedBefore, calculateUpdatedSince, getJobQueryChanges } from "./JobQuery";
import { List } from "immutable";

export interface JobQueryFormProps {
  toggleJobQueryForm: () => void;
  closeOnSubmit?: boolean;
  onSubmit?: () => void;
  columnSize?: number;
}

const JobQueryForm: React.FC<JobQueryFormProps> = ({
  toggleJobQueryForm,
  onSubmit,
  columnSize = 12,
  closeOnSubmit = false,
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  // Selectors
  const user = useSelector((state: RootState) => currentUser(state));
  const currentUserOrg = useSelector((state: RootState) => org(state, { orgId: user.org_id }));
  const leadSources = useSelector((state: RootState) => leadSourcesSelector(state));
  const jobStates = useSelector((state: RootState) => jobStatesSelector(state, {}));
  const jobStatesReactSelectRecord = jobStates.map((state) =>
    reactSelectRecordFromJSON({ id: state.id, name: state.label }),
  );
  const jobTypes = useSelector((state: RootState) =>
    getActiveJobAttributesList(state, { orgId: user.org_id, jobAttrType: JobAttributeTypes.job_type }),
  );
  const jobQuery = useSelector((state: RootState) => jobQuerySelector(state));
  const hasJobQueryChanged = useSelector(jobQueryChanged);
  const pretty: IPretty = StoreRegistry.get("Pretty");
  const lastChangesJobQuery = useSelector((state: RootState) => lastChangesJobQuerySelector(state));

  // Local State
  const isUpdateFilterCustom: boolean = jobQuery?.updated_since === "Custom";
  const rootPath = ["jobs", "jobQuery"];
  const customStyles = {
    option: (styles, { data }) => {
      const state = jobStates.find((jobState) => jobState.id === data.id);
      return {
        ...styles,
        borderLeft: `5px solid ${state.color}`,
      };
    },
    multiValue: (styles, { data }) => {
      const state = jobStates.find((jobState) => jobState.id === data.id);
      return {
        ...styles,
        borderLeft: `5px solid ${state.color}`,
      };
    },
  };
  const allUsersOption = reactSelectRecordFromJSON({ name: "All assignees" });
  const lastUpdatedOptions = () => {
    const year = new Date().getFullYear();
    const years = [year, year - 1, year - 2, year - 3].map((year) => year.toString());
    const options = ["7days", "14days", "1months", "3months", "6months", "1years", ...years, "Custom"];
    return options;
  };

  // Methods
  const validateUpdatesDateRange = () => {
    const errors = [];
    let error = "";

    if (jobQuery?.updated_since !== "Custom") {
      errors.push({ name: "filter_by_updated_before", error });
      return errors;
    }

    const limit = subYears(jobQuery.filter_by_updated_before, 1);
    const invalidRange = jobQuery.filter_by_updated_since < limit;
    if (invalidRange) {
      error = "Date range should be less or equal to 12 months";
    }
    const invalidDateSelection = jobQuery.filter_by_updated_before <= jobQuery.filter_by_updated_since;
    if (invalidDateSelection) {
      error = "End date cannot be before start date";
    }
    errors.push({ name: "filter_by_updated_before", error });

    return errors;
  };

  const onSubmitForm = () => {
    dispatch(JobActions.setLastSavedJobQuery(jobQuery));
    if (closeOnSubmit) {
      toggleJobQueryForm();
    }
    const changes = getJobQueryChanges(jobQuery);
    trackEvent({
      action: "submit",
      touched: lastChangesJobQuery,
      filters: changes,
    });
    dispatch(JobActions.resetLastChangesJobQuery());
    onSubmit();
  };

  const onResetForm = () => {
    dispatch(JobActions.resetJobQuery());
    dispatch(JobActions.setLastSavedJobQuery(jobQuery));

    const changes = getJobQueryChanges(jobQuery);
    trackEvent({
      action: "reset",
      touched: lastChangesJobQuery,
      filters: changes,
    });
    dispatch(JobActions.resetLastChangesJobQuery());
    toggleJobQueryForm();
    onSubmit();
  };
  const queryOrgs = (params: QueryParamsRecord, modelName: string) => {
    params = params.setIn(["parameters", "include[]"], List(["address"]));
    dispatch(orgActions.AsyncActions.listOrgs(params, modelName));
  };
  const queryUsers = (params: QueryParamsRecord, modelName: string) => {
    dispatch(userActions.AsyncActions.listUsers(params, modelName));
  };
  const loadJobTypes = () => {
    if (!jobTypes.size) {
      dispatch(jobAttributeActions.AsyncActions.getJobAttributes(user?.org_id, JobAttributeTypes.job_type));
    }
  };
  const userNameFn = (user: UserRecord) => {
    if (user.id === -1) {
      return "All assignees";
    }
    return `${getFullName(user)} (${user.email})`;
  };

  // Lifecycle
  useEffect(() => {
    if (jobQuery.org.id === -1) {
      dispatch(
        JobActions.setJobQuery({
          name: "org",
          value: reactSelectRecordFromJSON({ id: currentUserOrg.id, name: orgNameAddress(currentUserOrg) }),
        }),
      );
    }

    dispatch(JobActions.setLastSavedJobQuery(jobQuery));
  }, []);

  return (
    <RsfForm
      onSubmit={onSubmitForm}
      rootPath={rootPath}
      updateFormReducer={JobActions.setJobQuery}
      validate={validateUpdatesDateRange}>
      <Row>
        <Can resource="global" permission="org">
          <Col sm={12} md={columnSize}>
            <RsfQueryableDropdown
              label="Company"
              name="org"
              modelName="jobQueryOrg"
              modelPath={["orgs", "orgsById"]}
              nameFn={orgNameAddress}
              query={queryOrgs}></RsfQueryableDropdown>
          </Col>
        </Can>
        <Can resource="job" permission="read" exactMatch={true}>
          <Col sm={12} md={columnSize}>
            <RsfQueryableDropdown
              label="Assignee"
              name="user"
              modelName="jobQueryUser"
              modelPath={["users", "usersById"]}
              query={queryUsers}
              queryParams={{ org_id: jobQuery?.org.id.toString() }}
              nameFn={userNameFn}
              allOption={allUsersOption}></RsfQueryableDropdown>
          </Col>
        </Can>
      </Row>
      <Row>
        <Col sm={12} md={columnSize}>
          <ReactSelect
            label="Statuses"
            name="state"
            isMulti
            options={jobStatesReactSelectRecord}
            selectProps={{
              styles: customStyles,
            }}></ReactSelect>
        </Col>
        <Col sm={12} md={columnSize}>
          <Select label="Job Type" name="job_type" formControlProps={{ onClick: loadJobTypes } as any}>
            <option value={""}>All Job Types</option>
            {jobTypes.map((jobType: JobObjectRecord, idx) => (
              <option key={idx} value={jobType.id} title={jobType.description}>
                {jobType.name}
              </option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={columnSize}>
          <Select label="Lead Source" name="lead_source">
            <option value={"_allleads_"}>All Lead Sources</option>
            {leadSources.map((leadSource: string, idx: number) => (
              <option key={idx} value={leadSource}>
                {leadSource || "Unset"}
              </option>
            ))}
          </Select>
        </Col>
        <Col sm={12} md={columnSize}>
          <Select label="Last Updated" name="updated_since">
            {lastUpdatedOptions().map((key) => (
              <option key={key} value={key}>
                {pretty.name[key] || key}
              </option>
            ))}
          </Select>
        </Col>
      </Row>
      <div style={{ opacity: isUpdateFilterCustom ? 1 : 0.5 }}>
        <Row>
          <Col sm={12} md={columnSize}>
            {isUpdateFilterCustom ? (
              <FormControlDate label="Start Date" name="filter_by_updated_since"></FormControlDate>
            ) : (
              <FormGroup>
                <FormLabel htmlFor="start_date">Start Date</FormLabel>
                <FormControl
                  id="start_date"
                  type="date"
                  value={formatISO(calculateUpdatedSince(jobQuery), { representation: "date" })}
                  disabled
                />
              </FormGroup>
            )}
          </Col>
          <Col sm={12} md={columnSize}>
            {isUpdateFilterCustom ? (
              <FormControlDate label="End Date" name="filter_by_updated_before"></FormControlDate>
            ) : (
              <FormGroup>
                <FormLabel htmlFor="end_date">End Date</FormLabel>
                <FormControl
                  id="end_date"
                  type="date"
                  value={formatISO(calculateUpdatedBefore(jobQuery), { representation: "date" })}
                  disabled
                />
              </FormGroup>
            )}
          </Col>
        </Row>
      </div>
      <Row>
        <Col sm={12} md={columnSize}>
          <Select label="Sort" name="sort_order">
            <option value="desc">Newest to Oldest</option>
            <option value="asc">Oldest to Newest</option>
          </Select>
        </Col>
        <Col sm={12} md={columnSize}>
          <Check label="Include Archived" name="archived"></Check>
        </Col>
      </Row>
      <Row>
        <Col className="text-center"></Col>
      </Row>
      <br />
      <Row>
        <Col className="text-center">
          <Button id="jobs-filter-apply" type="submit" disabled={!hasJobQueryChanged}>
            Apply Filters
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col className="text-center">
          <Button id="jobs-filter-reset-close" variant="link" onClick={onResetForm}>
            <FontAwesome name="angle-left" />
            &nbsp; Reset and Close
          </Button>
        </Col>
      </Row>
    </RsfForm>
  );
};

export default connectFC(JobQueryForm);
