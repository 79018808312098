import {
  IProductOptionGroupsMatch,
  IProductOptionGroupResource,
  IProductOptionGroupResponse,
} from "app/src/Models/ProductOptionGroup";
import { IPretty } from "app/src/Common/PrettyNameService";

class OptionGroupsMatchCtrl implements ng.IComponentController {
  public remove: (args: any) => null;
  public edit: (args: any) => null;
  public select: (args: any) => null;
  public match: (args: any) => null;
  public optionGroupsMatch: IProductOptionGroupsMatch;
  public spinnerPromise: ng.IPromise<any>;

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $scope: ng.IScope,
    public ProductOptionGroup: IProductOptionGroupResource,
    public $q: ng.IQService,
    public Pretty: IPretty,
  ) {}

  public $onChanges() {}

  public removeOptionGroupsMatch() {
    this.remove({ optionGroupsMatch: this.optionGroupsMatch });
  }

  public editOptionGroupsMatch() {
    this.edit({ optionGroupsMatch: this.optionGroupsMatch });
  }

  public selectOptionGroupsMatch() {
    this.select({ optionGroupsMatch: this.optionGroupsMatch });
  }

  public matchOptionGroupsMatch() {
    this.match({ optionGroupsMatch: this.optionGroupsMatch });
  }

  public showOptionGroups(value) {
    if (_.isEmpty(this.optionGroupsMatch.matched)) {
      const response = <IProductOptionGroupResponse>this.ProductOptionGroup.byMatchId({
        price_list_id: this.optionGroupsMatch.price_list_id,
        uuid: this.optionGroupsMatch.uuid,
      });
      this.spinnerPromise = response.$promise.then(() => {
        this.optionGroupsMatch.matched = response.product_option_groups;
        this.optionGroupsMatch.show = value;
      });
    } else {
      this.optionGroupsMatch.show = value;
    }
  }
}

export class OptionGroupsMatchComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Orgs/tabs/PriceListEditor/option_groups_match_component.html";
  public bindings: any = {
    optionGroupsMatch: "<",
    remove: "&",
    edit: "&",
    select: "&",
    match: "&",
  };

  constructor() {
    this.controller = OptionGroupsMatchCtrl;
    this.controller.$inject = ["$uibModal", "$scope", "ProductOptionGroup", "$q", "Pretty"];
  }
}
