import * as financeApplicationActions from "./financeApplication.actions";
import { FinanceApplicationRecord, fromJSON } from "app2/src/records/FinanceApplication";
import { Map, Record } from "immutable";
import { RootActions, RootState } from "app2/src/reducers";

export const FinanceApplicationStateRecord = Record({
  byEstimateId: Map<number, FinanceApplicationRecord>(),
});

export const initialState = FinanceApplicationStateRecord();
export type FinanceApplicationState = typeof initialState;

const model = "financeApplications";

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case financeApplicationActions.FETCH_FINANCE_APPLICATION:
      if (state.getIn([model, "byEstimateId", action.payload.estimateId])) {
        return state.setIn([model, "byEstimateId", action.payload.estimateId, "loading"], true);
      }
      return state.setIn([model, "byEstimateId", action.payload.estimateId], fromJSON({ loading: true }));

    case financeApplicationActions.RECEIVE_FINANCE_APPLICATION:
      return state.setIn(
        [model, "byEstimateId", action.payload.financeApplication.estimate_id],
        fromJSON({ ...action.payload.financeApplication, loading: false }),
      );

    case financeApplicationActions.SET_LOADED:
      return state.setIn([model, "byEstimateId", action.payload.estimateId, "loading"], false);

    case financeApplicationActions.SET_OFFER_STATUS:
      return state.setIn([model, "byEstimateId", action.payload.estimateId, "offer_status"], action.payload.status);

    case financeApplicationActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);

    case financeApplicationActions.RECEIVE_FINANCE_APPLICATIONS:
      action.payload.financeApplications.forEach((application) => {
        state = reducer(state, financeApplicationActions.Actions.receiveFinanceApplication(application));
      });
      return state;

    case financeApplicationActions.RECEIVE_APPLY_URL:
      return state.updateIn(
        [model, "byEstimateId", action.payload.estimateId],
        (financeApplication: FinanceApplicationRecord) => {
          return financeApplication.merge({
            apply_url: action.payload.applyUrl,
            loading: false,
          });
        },
      );

    default:
      return state;
  }
};
