export class TosHttpInterceptor implements ng.IHttpInterceptor {
  constructor(
    private $q: ng.IQService,
    private $injector: ng.auto.IInjectorService,
  ) {}

  public responseError = (rejection: ng.IHttpPromiseCallbackArg<any>): ng.IPromise<any> => {
    if (rejection.status === 454) {
      const $state = this.$injector.get("$state") as ng.ui.IStateService;
      $state.go("root.tos");
    }
    return this.$q.reject(rejection);
  };

  public static factory($q: ng.IQService, $injector: ng.auto.IInjectorService): TosHttpInterceptor {
    return new TosHttpInterceptor($q, $injector);
  }
}

TosHttpInterceptor.factory.$inject = ["$q", "$injector"];
