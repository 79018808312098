import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

interface BrowserSupportModalProps {
  open: boolean;
}

export const BrowserSupportModal: React.FC<BrowserSupportModalProps> = (props) => {
  const [open, setOpen] = useState(props.open);

  const show = () => {
    setOpen(true);
  };

  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open && props.open) {
      show();
    }
  }, [props.open]);

  return (
    <>
      <Modal show={open} onHide={hide} size="lg">
        <Modal.Header>
          <h3>This Browser is Not Supported</h3>
        </Modal.Header>
        <Modal.Body>
          <p>Old browsers can put your security at risk.</p>
          <p>
            Please look at updating to a browser such as&nbsp;
            <a className="underline" href="https://www.google.com/chrome/">
              Google Chrome
            </a>{" "}
            or&nbsp;
            <a className="underline" href="http://getfirefox.com">
              Firefox
            </a>
            .
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};
