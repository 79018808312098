import Plan from "app/src/Billing/Models/Plan";
import Fee from "app/src/Billing/Models/Fee";
import Order from "app/src/Billing/Models/Order";
import Subscription from "app/src/Billing/Models/Subscription";
import Account from "app/src/Billing/Models/Account";
import Coupon from "app/src/Billing/Models/Coupon";
import Sku from "app/src/Billing/Models/Sku";
import InvoiceItem from "app/src/Billing/Models/InvoiceItem";
import ChargebeeSubscription from "app/src/Billing/Models/ChargebeeSubscription";

export class BillingModelRoutes {
  public static load(app: ng.IModule) {
    app.factory("Plan", Plan);
    app.factory("Fee", Fee);
    app.factory("Subscription", Subscription);
    app.factory("ChargebeeSubscription", ChargebeeSubscription);
    app.factory("Order", Order);
    app.factory("Account", Account);
    app.factory("InvoiceItem", InvoiceItem);
    app.factory("Coupon", Coupon);
    app.factory("Sku", Sku);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {}
}
