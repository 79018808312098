import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { image } from "app2/src/selectors/image.selectors";
import { getUrl } from "app2/src/records/Image";

const mapStateToProps = (state: RootState, ownProps: ImageThumbProps) => {
  return {
    image: image(state, { imageId: ownProps.imageId }),
  };
};

const mapDispatchToProps = () => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImageThumbProps {
  imageId: number;
  index: number;
  handleSelect: (index: number) => void;
}

interface ImageThumbState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImageThumbProps;

class ImageThumb extends React.Component<Props, ImageThumbState> {
  constructor(props: Props) {
    super(props);
  }

  public render(): React.ReactNode {
    const { index, image, handleSelect } = this.props;
    return (
      <li key={index.toString()}>
        <a className="img-container" onClick={() => handleSelect(index)} href="#">
          <img className="img-contain" style={{ width: "50px", maxHeight: "35px" }} src={getUrl(image, "thumb")} />
        </a>
      </li>
    );
  }
}

export default connector(ImageThumb);
