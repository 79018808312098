import { ICustomProduct } from "app/src/Models/Product";
import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { ICommand } from "app/src/Commands/Command";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import { AddEstimateLineItemCmd } from "app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { Discount } from "app/src/Models/Discount";

export class AddEstimateFromOccImportCmd implements ICommand {
  public errors: string[] = [];
  public promise: ng.IPromise<any>;

  constructor(
    public estimate: IEstimate,
    public data: string[],
    public group: IEstimateGroup,
    public FileQueue: IFileQueueFactory,
    public EstimatorService: EstimatorService,
    public valid_kind: string[],
    public valid_uom: string[],
    public $q: ng.IQService,
  ) {}

  public execute(): boolean {
    const header = this.data[0];
    const typeIndex = _.findIndex(header, (title) => {
      return title === "type";
    });
    if (typeIndex < 0) {
      this.errors.push("Invalid Header: Need to include type in header.");
      return false;
    }
    if (this.isHeaderInvalid(header)) {
      return false;
    }

    const line_items = [];
    const discounts = [];
    _.each(this.data, (item, index) => {
      if (item[typeIndex] === "type") {
        return;
      }
      switch (item[typeIndex]) {
        case "line_item":
          line_items.push(item);
          break;
        case "discount":
          discounts.push(item);
          break;
        default:
          const line = index + 1;
          this.errors.push("Invalid Type: " + item[typeIndex] + " is not a valid type. See line " + line + ".");
          break;
      }
    });

    const promises = [];
    const overall_defer = this.$q.defer();
    promises.push(overall_defer.promise);
    const products = this.parseProducts(line_items, header);
    promises.push(this.addProductsToEstimate(products));

    this.addDiscountsToEstimate(discounts, header);

    overall_defer.resolve();
    this.promise = this.$q.all(promises);
    this.promise.then(() => {
      this.FileQueue.setQueue();
    });

    return true;
  }

  private addDiscountsToEstimate(discounts, header) {
    _.each(discounts, (discount, index) => {
      const discount_setup = <ICustomProduct>{};
      _.each(header, (title: string, index: number) => {
        if (title === "type") {
          return;
        }
        discount_setup[title] = discount[index];
      });

      if (this.isKindInvalid(discount_setup.uom)) {
        const line = index + 1;
        this.errors.push(
          "Invalid Discount Kind: " +
            discount_setup.uom +
            " is not a valid discount kind. Check discount " +
            line +
            ".",
        );
        return;
      }

      // convert from custom product import to discount (did not want to include headers for discounts)
      const new_discount = Discount.fromJSON({
        kind: discount_setup.uom,
        name: discount_setup.name,
        amount: discount_setup.quantity,
        limit: discount_setup.product_price,
      });
      this.estimate.discounts.push(new_discount);
    });
  }

  private addProductsToEstimate(products: ICustomProduct[]): ng.IPromise<any> {
    const promises = [];
    _.each(products, (product: ICustomProduct) => {
      const group_index = _.findIndex(_.pluck(this.estimate.estimateGroups(product.included), "name"), (name) => {
        return name === product.estimate_group;
      });
      let group: IEstimateGroup;
      if (group_index >= 0) {
        group = this.estimate.estimateGroups(product.included)[group_index];
      } else {
        group = this.estimate.createGroup({ name: product.estimate_group, included: product.included });
      }
      const cmd = new AddEstimateLineItemCmd(this.estimate, product, group, this.EstimatorService);
      cmd.execute();
      cmd.lineItem.newly_added = false;

      if (product.image_url) {
        promises.push(this.FileQueue.getRemoteFile(product.image_url, cmd.lineItem));
      }

      if (product.document_url) {
        promises.push(this.FileQueue.getRemoteFile(product.document_url, cmd.lineItem));
      }
    });
    return this.$q.all(promises);
  }

  private parseProducts(line_items, header): ICustomProduct[] {
    const products = [];

    _.each(line_items, (li, index) => {
      const new_product = <ICustomProduct>{};
      _.each(header, (title: string, index) => {
        if (title === "type") {
          return;
        }
        new_product[title] = li[index];
      });
      const line = index + 1;
      if (this.isUomInvalid(new_product.uom)) {
        this.errors.push(
          "Invalid Unit Of Measure: " +
            new_product.uom +
            " is not a valid unit of measure. Check line_item " +
            line +
            ".",
        );
        return;
      }
      if (this.isIncludedInvalid(new_product.included)) {
        this.errors.push(
          "Invalid Included Value: " +
            new_product.included +
            " is not a valid boolean. Needs to be true or false. Check line_item " +
            line +
            ".",
        );
        return;
      }

      new_product.visibility = this.parseVisibility(new_product.visibility);
      new_product.included = this.parseBool(new_product.included);
      new_product.quantity = _.parseFloat(new_product.quantity);
      new_product.product_price = _.parseFloat(new_product.product_price);
      new_product.labor_price = _.parseFloat(new_product.labor_price);
      new_product.price = _.round(_.parseFloat(new_product.product_price) + _.parseFloat(new_product.labor_price), -2);
      products.push(new_product);
    });
    return products;
  }

  private isHeaderInvalid(header) {
    const valid_headers = [
      "type",
      "estimate_group",
      "name",
      "description",
      "quantity",
      "product_price",
      "labor_price",
      "uom",
      "included",
      "image_url",
      "document_url",
      "visibility",
    ];
    let feedback = false;
    _.each(header, (h) => {
      const test = _.findIndex(valid_headers, (valid_h) => {
        return valid_h === h;
      });
      if (test < 0) {
        this.errors.push("Invalid Header: " + h + " is not a valid header name.");
        feedback = true;
      }
    });
    return feedback;
  }

  private isUomInvalid(uom) {
    let feedback = false;
    const test = _.findIndex(this.valid_uom, (valid_unit) => {
      return valid_unit === uom;
    });
    if (test < 0) {
      feedback = true;
    }
    return feedback;
  }

  private isKindInvalid(kind) {
    let feedback = false;
    const test = _.findIndex(this.valid_kind, (valid_k) => {
      return valid_k === kind;
    });
    if (test < 0) {
      feedback = true;
    }
    return feedback;
  }

  private isIncludedInvalid(included) {
    return !(included.toLowerCase().trim() === "true" || included.toLowerCase().trim() === "false");
  }

  private parseBool(included) {
    return included.toLowerCase().trim() === "true";
  }

  private parseVisibility(visibility) {
    let parsed_v = "always";
    if (visibility) {
      parsed_v = visibility.toLowerCase().trim();
    }
    if (!(parsed_v === "always" || parsed_v === "company_only")) {
      parsed_v = "always";
    }
    return parsed_v;
  }
}
