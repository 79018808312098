import { FormControl, RsfForm } from "@tberrysoln/rsf-form";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { commaSeparatedEmail, composeValidators, phoneNumber, required } from "app2/src/helpers/FinalFormValidator";
import { Actions, AsyncActions } from "app2/src/reducers/paymentRequest.actions";
import * as React from "react";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { paymentAuthRequestKind } from "app2/src/selectors/payment.selectors";
import { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";

export interface PaymentRequestProps {
  closeModal: () => void;
}

export const PaymentRequest: React.FC<PaymentRequestProps> = ({ closeModal }) => {
  // state
  const orgId = useSelector(currentOrgId);
  const authorizedPaymentRequestKind = useSelector((state: RootState) => paymentAuthRequestKind(state, { orgId }));
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!authorizedPaymentRequestKind) return;
    if (mounted) return;

    dispatch(Actions.initializePaymentRequest());
    setMounted(true);
  }, [authorizedPaymentRequestKind]);

  // hooks

  // callbacks
  const sendEmail = async () => {
    setLoading(true);
    await dispatch(AsyncActions.createPaymentRequest(-1, "email"));
    setLoading(false);
    closeModal();
  };

  const sendText = async () => {
    setLoading(true);
    await dispatch(AsyncActions.createPaymentRequest(-2, "text"));
    setLoading(false);
    closeModal();
  };

  return (
    <>
      <SpinnerComponent localProperty={loading} />
      <Modal.Header closeButton>
        <Modal.Title>Payment Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {mounted && (
            <Col>
              <RsfForm
                rootPath={["paymentRequests", "byId", -1]}
                updateFormReducer={Actions.update}
                onSubmit={sendEmail}>
                {authorizedPaymentRequestKind === "cardconnect" && (
                  <FormControl formControlProps={{ type: "number" }} label="Amount" name="amount" />
                )}
                <FormControl as="textarea" label="Description" name="description" />
                <Row>
                  <Col sm={9}>
                    <FormControl
                      label="Emails"
                      name="emails"
                      validate={composeValidators(required, commaSeparatedEmail)}
                    />
                  </Col>
                  <Col sm={3}>
                    <Button name="email" type="submit" className="dummy-label">
                      Send Email
                    </Button>
                  </Col>
                </Row>
              </RsfForm>
              <RsfForm
                rootPath={["paymentRequests", "byId", -2]}
                updateFormReducer={Actions.update}
                onSubmit={sendText}>
                <Row>
                  <Col sm={9}>
                    <FormControl label="Phone Number" name="sms" validate={composeValidators(required, phoneNumber)} />
                  </Col>
                  <Col sm={3}>
                    <Button name="sms" type="submit" className="dummy-label">
                      Send Text
                    </Button>
                  </Col>
                </Row>
              </RsfForm>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="cancel" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
};
