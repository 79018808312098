import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { IPretty } from "app/src/Common/PrettyNameService";
import { DraggableRowProps } from "app2/src/components/Common/ListManager/components/DragTable";
import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { us_states } from "app/src/Common/StatesConstant";
import { StoreRegistry } from "app2/src/storeRegistry";

interface OrgDocTableRowState {}

interface OrgDocTableObjectOption {
  id: number;
  file_name: string;
  doc_type: string;
  filter_states: string[];
}
interface OrgDocTableRowProps extends DraggableRowProps {
  option: string | OrgDocTableObjectOption;
}

export class OrgDocTableRow extends React.Component<OrgDocTableRowProps, OrgDocTableRowState> {
  public Pretty: IPretty;

  constructor(props: OrgDocTableRowProps) {
    super(props);

    this.Pretty = StoreRegistry.get("Pretty");
  }

  public render(): JSX.Element {
    const { option, id, addOption, removeOption, index } = this.props;

    if (typeof option === "string") {
      return this.renderString(id, option, addOption, removeOption, index);
    }

    return this.renderDocConfig(id, option, addOption, removeOption, index);
  }

  private renderString(
    id: string,
    option: string,
    addOption: (option: any) => void,
    removeOption: (option: any) => void,
    index: number,
  ) {
    const prettyName = this.Pretty.name[option] ? this.Pretty.name[option] : option;

    return (
      <Draggable key={option} draggableId={option} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          let draggingClass = "";
          if (snapshot.isDragging) {
            draggingClass = "table-row-dragging";
          }
          return (
            <tr
              className={draggingClass}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}>
              <td>
                <img className="dragHandle link" src="/assets/images/icons/ic_sort.ea6f8933.png" title={`Move ${prettyName}`} />{" "}
                {"\u00A0"}
                {prettyName}
              </td>
              <td>
                {id === "available" ? (
                  <i
                    className="rsf-plus-link rsf-base-66"
                    title={`Add ${prettyName}`}
                    onClick={() => {
                      addOption(option);
                    }}></i>
                ) : (
                  <i
                    className="rsf-delete-link rsf-base-66"
                    title={`Remove ${prettyName}`}
                    onClick={() => {
                      removeOption(option);
                    }}></i>
                )}
              </td>
            </tr>
          );
        }}
      </Draggable>
    );
  }

  private renderDocConfig(
    id: string,
    option: OrgDocTableObjectOption,
    addOption: (option: OrgDocTableObjectOption) => void,
    removeOption: (option: OrgDocTableObjectOption) => void,
    index: number,
  ) {
    const docType = _.toTitleCase(option.doc_type);
    const name = option.file_name ? option.file_name : option.id;
    const fullName = `${docType} Doc: ${name}`;

    return (
      <Draggable key={option.id} draggableId={option.id.toString()} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          let draggingClass = "";
          if (snapshot.isDragging) {
            draggingClass = "table-row-dragging";
          }
          return (
            <tr
              className={draggingClass}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}>
              <td>
                <Row>
                  <Col lg={8} sm={6}>
                    <img
                      className="dragHandle link"
                      src="/assets/images/icons/ic_sort.ea6f8933.png"
                      title={`Move ${fullName}`}
                    />{" "}
                    {"\u00A0"}
                    {fullName}
                  </Col>
                  <Col lg={4} sm={6}>
                    {id === "list" && (
                      <select
                        value={option.filter_states}
                        className="form-control states"
                        multiple
                        onChange={(e) => {
                          option.filter_states = _.chain(e.target.options)
                            .select((o: any) => o.selected)
                            .map((a: any) => a.value)
                            .value();

                          removeOption(null);
                        }}>
                        {us_states.map((state, idx) => (
                          <option key={idx} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    )}
                  </Col>
                </Row>
              </td>
              <td>
                {id === "available" ? (
                  <i
                    className="rsf-plus-link rsf-base-66"
                    title={`Add ${fullName}`}
                    onClick={() => {
                      addOption(option);
                    }}></i>
                ) : (
                  <i
                    className="rsf-delete-link rsf-base-66"
                    title={`Remove ${fullName}`}
                    onClick={() => {
                      removeOption(option);
                    }}></i>
                )}
              </td>
            </tr>
          );
        }}
      </Draggable>
    );
  }
}
