import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { Map, List } from "immutable";
import { PageRecord, QueryParamsRecord } from "../records/Page";
import { MetaPaginationRecord } from "../records/MetaPagination";

export const byId = (state: RootState, props: any): Map<number, any> => state.getIn(props.path);
export const ids = (state: RootState, props: any) =>
  state.getIn([
    "pagination",
    "byModel",
    props.modelName,
    "pages",
    currentPage(state, { modelName: props.modelName }),
    "ids",
  ]);
export const currentPage = (state: RootState, props: any) =>
  state.getIn(["pagination", "byModel", props.modelName, "queryParams", "page"]);
export const dirtyIds = (state: RootState, _props?: any) => state.getIn(["pagination", "dirtyIds"]);
export const queryParams = (state: RootState, props: any) =>
  state.getIn(["pagination", "byModel", props.modelName, "queryParams"], new QueryParamsRecord());
export const pagination = (state: RootState, props: any) =>
  state.getIn(["pagination", "byModel", props.modelName, "pages", props.page, "pagination"]);
export const pageRecord = (state: RootState, props: any) =>
  state.getIn(["pagination", "byModel", props.modelName, "pages", props.page]);

/**
 * Fetches the current page record
 * @param {RootState} state The RootState
 * @param {{modelName}} options
 * @returns {boolean} loading
 */
export const currentPageRecord = createSelector(
  [
    currentPage,
    (state, props) => ({
      state,
      props,
    }),
  ],
  (currentPage: number, { state, props }) => {
    return pageRecord(state, { modelName: props.modelName, page: currentPage });
  },
);

/**
 * Fetches the metadata of the current page
 *
 * @param {RootState} state The RootState
 * @param {{ modelName }} options
 * @returns {PagingMetadataRecord} PagingMetadataRecord
 */
export const pageControlData = (state: RootState, props: any): MetaPaginationRecord =>
  state.getIn(["pagination", "byModel", props.modelName, "pages", currentPage(state, props), "pagination"]);

/**
 * Fetches the data records of the current page
 *
 * @param {RootState} state The RootState
 * @param {{ modelName, path }} options
 * @returns {List<any>} List of records
 */
export const getPaginationByModel = createSelector([byId, ids], (modelById: Map<number, any>, ids: any) => {
  if (!ids) {
    return List();
  }

  const interim = ids
    .map((id) => modelById.get(id))
    .filter((object: any) => {
      if (object?.archived) {
        return !object.archived;
      } else {
        return true;
      }
    });
  return interim;
});

/**
 * Fetches the ids of all pages for the given model
 *
 * @param {RootState} state The RootState
 * @param {{ modelName, path }} options
 * @returns {List<any>} List of records
 */
export const allIds = (state: RootState, props: any) => {
  const pages: Map<number, PageRecord> = state.getIn(["pagination", "byModel", props.modelName, "pages"]);
  let ids = List();
  pages?.toList()?.forEach((page) => {
    if (page?.ids.size) {
      ids = ids.concat(page.ids);
    }
  });
  return ids;
};

/**
 * Fetches the data records of all pages
 *
 * @param {RootState} state The RootState
 * @param {{ modelName, path }} options
 * @returns {List<any>} List of records
 */
export const getAllPaginationByModel = createSelector([byId, allIds], (modelById: Map<number, any>, ids: any) => {
  if (!ids) {
    return List();
  }

  const interim = ids
    .map((id) => modelById.get(id))
    .filter((object: any) => {
      if (object?.archived) {
        return !object.archived;
      } else {
        return true;
      }
    });
  return interim;
});

export const viewPagination = createSelector(
  [getPaginationByModel, queryParams, (state, props) => ({ state, props })],
  (objects: List<any>, queryParams: QueryParamsRecord, stateProps: any) => {
    const { state, props } = stateProps;
    const { modelName } = props;
    const { page } = queryParams;
    return { objects, page: pageRecord(state, { modelName, page }), queryParams };
  },
);

/**
 * Gets current page record and returns loading
 * @param {RootState} state The RootState
 * @param {{modelName}} options
 * @returns {boolean} loading
 */
export const isCurrentPageLoading = createSelector(currentPageRecord, (currentPageRecord: PageRecord) => {
  return Boolean(currentPageRecord?.loading);
});
