import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEstimate } from "app/src/Models/Estimate";
import * as moment from "moment";
import { IOrg, OrgPrefType } from "app/src/Models/Org";
import { ISignatureSettings, ISigner } from "app/src/Models/SignatureSettings";
import { IDocResource } from "app/src/Models/Doc";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { ISignedDocument, ISignedDocumentResource } from "../Models/SignedDocument";
import { IBaseConfig } from "../Common/IBaseConfig";
import { emailRegex } from "app2/src/helpers/InputValidator";

interface ISignContractBindings {
  estimateId: number;
  triggerSigning: boolean;
  onCancel(): void;
  onFinish(params: any): void;
}

interface ISignatureContractScope extends ng.IScope {
  pdfUrl: string;
}

class SignatureSetupCtrl implements ISignContractBindings {
  public signForm: ng.IFormController;
  public estimateId: number;
  public estimate: IEstimate;
  public org: IOrg;
  public triggerSigning = false;
  public onCancel: () => any;
  public onFinish: (params: any) => any;
  public pdfUrl: string;
  public modalWindow: ng.ui.bootstrap.IModalServiceInstance;
  public signatureSettings: ISignatureSettings = <ISignatureSettings>{};
  public modalOpen = false;
  public emailPdfModal: boolean;
  public disabledFeatures = ["Annotations"];
  public emailPattern = emailRegex;

  private submitted = false;
  private file: any;
  private loadingPromise: ng.IDeferred<any>;

  constructor(
    public EstimatorService: EstimatorService,
    public SignedDocument: ISignedDocumentResource,
    public Doc: IDocResource,
    public Upload: ng.angularFileUpload.IUploadService,
    public BaseConfig: IBaseConfig,
    public $q: ng.IQService,
    public $timeout: ng.ITimeoutService,
    public $http: ng.IHttpService,
    public Flash: IFlash,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $scope: ISignatureContractScope,
  ) {
    this.loadingPromise = $q.defer();
  }

  public $onChanges() {
    if (!this.estimate && (this.estimateId >= 0 || (this.estimateId as any as string) === "new")) {
      this.submitted = false;
      this.estimate = this.EstimatorService.loadEstimate(this.estimateId);

      this.estimate.$promise.then(() => {
        this.org = this.EstimatorService.org;
        this.EstimatorService.generatePdf(this.estimate.id, "contract").then((data: any) => {
          this.file = data.response;
          this.pdfUrl = URL.createObjectURL(data.response);
          this.validateUniqueNames(this.signForm);
          this.loadingPromise.resolve();
        });
      });
    } else {
      if (this.triggerSigning) {
        this.triggerSigning = false;
        this.modalOpen = true;
        this.sign();
      }
    }
  }

  public $onDestroy() {
    if (this.loadingPromise) {
      this.loadingPromise.resolve();
    }
  }

  public cancelBtn() {
    this.modalOpen = false;
    this.modalWindow.dismiss();
  }

  public cancel() {
    if (this.onCancel && !this.submitted) {
      this.modalOpen = false;
      this.onCancel();
    }
  }

  public sign() {
    if (_.isUndefined(this.org.settings) || _.isEmpty(this.org.settings)) {
      this.loadingPromise = this.$q.defer();
      this.estimate = this.EstimatorService.reloadEstimate(this.estimate.id);
      this.estimate.$promise.then(() => {
        this.org = this.estimate.job.org;
        return this.loadingPromise.resolve();
      });
    }

    this.loadingPromise.promise.then(() => {
      this.signatureSettings = this.org.fetchPref<ISignatureSettings>(OrgPrefType.signature_settings);
      this.signatureSettings.buildRecipients(this.estimate);

      this.modalWindow = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
        size: "lg",
        templateUrl: "src/Signature/signature_settings_modal.html",
        scope: this.$scope,
      });

      this.modalWindow.closed.then(() => {
        this.cancel();
      });
    });
  }

  public upload(form: ng.IFormController, notify = false) {
    if (!form.$valid) {
      return;
    }

    const platform = this.org.settings.config?.signature_settings?.signature_platform || "scribble";

    this.loadingPromise = this.$q.defer();
    this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
      url: this.BaseConfig.BASE_URL + `/api/v1/estimates/${this.estimate.id}/signed_documents`,
      arrayKey: "[]",
      data: {
        include: ["document", "recipients"],
        notify: notify,
        document: {
          name:
            this.estimate.job.name +
            " - " +
            moment().format("MM-DD-Y HH:mm:ss") +
            " - " +
            this.org.contractTitle(false) +
            ".pdf",
          file: this.file,
        },
        signed_document: {
          platform: platform,
          recipients_attributes: _.select(this.signatureSettings.recipients, (recipient: ISigner) => {
            return recipient.required || recipient.include;
          }),
        },
      },
    })
      .then(
        (resp: any) => {
          this.submitted = true;
          this.Flash.addMessage(FlashLevels.info, "Signed Document uploaded.  Processing...");
          const sd: ISignedDocument = this.SignedDocument.fromJSON(resp.data.signed_document);
          sd.document = this.Doc.fromJSON(sd.document);
          this.onFinish({ signedDocument: sd, notify: notify });
        },
        () => {
          this.Flash.addMessage(FlashLevels.danger, "There were problems creating the Signed Document");
        },
      )
      .finally(() => {
        this.modalOpen = false;
        this.modalWindow.close();
        this.loadingPromise.resolve();
      });
  }

  public validateUniqueNames(form: ng.IFormController) {
    _.each(this.signatureSettings.recipients, (recipient: any, index: number) => {
      if (!recipient.required && !recipient.include) {
        form["name_" + index].$setValidity("duplicate", true);
        return;
      }

      const match: number = _.findIndex(this.signatureSettings.recipients, (nested) => {
        if (!nested.required && !nested.include) {
          return;
        }
        return nested.name === recipient.name;
      });

      if (match < index) {
        form["name_" + index].$setValidity("duplicate", false);
      } else {
        form["name_" + index].$setValidity("duplicate", true);
      }
    });
  }

  public isBrowserMs() {
    if (
      /MSIE 10/i.test(navigator.userAgent) ||
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      // This is internet explorer 9, 10, 11 or Edge
      return true;
    }

    return false;
  }
}

export class SignatureSetupComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    estimateId: "<",
    triggerSigning: "<",
    emailPdfModal: "<",
    onCancel: "&",
    onFinish: "&",
  };
  public templateUrl = "src/Signature/signature_setup.html";

  constructor() {
    this.controller = SignatureSetupCtrl;
    this.controller.$inject = [
      "EstimatorService",
      "SignedDocument",
      "Doc",
      "Upload",
      "BaseConfig",
      "$q",
      "$timeout",
      "$http",
      "Flash",
      "$uibModal",
      "$scope",
    ];
  }
}
