import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { Row, Col, Button } from "react-bootstrap";
import { RootDispatchType } from "app2/src/store";
import Folders from "app2/src/components/Folders";
import track, { TrackingProp } from "react-tracking";
import { currentJobId } from "app2/src/selectors/job.selectors";
import Images from "app2/src/components/JobTabs/ImageTab/Images";
import { Can } from "app2/src/components/Common/CanComponent";
import * as FontAwesome from "react-fontawesome";
import Files from "react-files";
import MoveFilesDropdown from "app2/src/components/Folders/MoveFilesDropdown";
import { imagesBoolean, showInfo } from "app2/src/selectors/image.selectors";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as imageActions from "app2/src/reducers/image.actions";
import * as elevationActions from "app2/src/reducers/elevation.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as folderActions from "app2/src/reducers/folder.actions";
import { IImageOptions } from "app2/src/api/image.service";
import { navDisplay, sendInviteSent } from "app2/src/selectors/components/common.selectors";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { QueryParamsRecord } from "app2/src/records/Page";
import { queryParams } from "app2/src/selectors/pagination.selectors";
import RefreshButton from "app2/src/components/Common/RefreshButton";
import { currentElevationsLoading } from "app2/src/selectors/elevation.selectors";
import { push, replace } from "connected-react-router/immutable";
import { queryNumber } from "app2/src/selectors/router.selectors";

const mapStateToProps = (state: RootState) => {
  return {
    queryFolderParentId: queryNumber(state, { queryKey: "folder_parent_id" }),
    folderParentId: queryParams(state, { modelName: "folder" }).getIn(["folder_id"]),
    jobId: currentJobId(state),
    selectedCheck: imagesBoolean(state, { booleanName: "selected" }),
    showInfo: showInfo(state),
    navDisplay: navDisplay(state),
    currentQueryParams: queryParams(state, { modelName: "image" }),
    sendInviteSent: sendInviteSent(state),
    elevationsLoading: currentElevationsLoading(state, {}),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    createImages: (files: any[], options: IImageOptions) =>
      dispatch(imageActions.AsyncActions.batchCreateImage(files, options)),
    push: (path: string) => dispatch(push(path)),
    replace: (path: string) => dispatch(replace(path)),
    flashAddAlert: (level: FlashLevels, msg: string) => dispatch(commonActions.Actions.flashAddAlert(level, msg)),
    setShowInfo: (showInfo: boolean) => dispatch(imageActions.Actions.setShowInfo(showInfo)),
    openSendInviteModal: (folderId: number) =>
      dispatch(commonActions.Actions.openSendInviteModal("image_uploader", { folder_id: folderId })),
    listFolders: (folderParentId: number, jobId: number) =>
      dispatch(
        folderActions.AsyncActions.listFolders(folderParentId, { folderable_type: "Job", name: "Photos" }, () =>
          replace(`/jobs/${jobId}/images`),
        ),
      ),
    listImages: (folderId: number, refreshLoad = false, queryParams: QueryParamsRecord = new QueryParamsRecord()) =>
      dispatch(
        imageActions.AsyncActions.listImages(
          {
            imageableType: "job",
            refreshLoad,
            folder_id: folderId,
          },
          queryParams,
        ),
      ),
    listElevations: () => dispatch(elevationActions.AsyncActions.listElevationsByMeasurement()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImageTabProps {
  tracking?: TrackingProp;
}

interface ImageTabState {
  loading: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImageTabProps;
@track((props) => {
  return {
    component: "JobImagesTab",
    category: "Photos",
    action: "show",
    job: props.jobId,
  };
})
class ImageTab extends React.Component<Props, ImageTabState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.navigate = this.navigate.bind(this);
    this.onFilesChange = this.onFilesChange.bind(this);
    this.onFilesError = this.onFilesError.bind(this);
    this.toggleShowInfo = this.toggleShowInfo.bind(this);
    this.openInviteModal = this.openInviteModal.bind(this);
    this.refreshListImages = this.refreshListImages.bind(this);
  }

  public componentDidMount(): void {
    const { listElevations, listFolders, jobId, queryFolderParentId } = this.props;
    listElevations();
    listFolders(queryFolderParentId, jobId);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { listImages, folderParentId } = this.props;

    if (!_.isNullOrUndefined(folderParentId) && prevProps.folderParentId !== folderParentId) {
      listImages(folderParentId);
    }
  }

  public navigate(folderId: number): void {
    const { jobId, push, listFolders } = this.props;
    if (_.isNullOrUndefined(folderId)) {
      push(`/jobs/${jobId}/images`);
    } else {
      push(`/jobs/${jobId}/images?folder_parent_id=${folderId}`);
    }
    listFolders(folderId, jobId);
  }

  @track((props) => ({
    action: "toggle show photo title",
    showInfo: !props.showInfo,
  }))
  public toggleShowInfo(): void {
    const { showInfo, setShowInfo } = this.props;
    setShowInfo(!showInfo);
  }

  public async onFilesChange(files: any[]): Promise<void> {
    const { jobId, createImages, tracking, folderParentId } = this.props;
    if (files.length > 0) {
      this.setState({ loading: true });
      await createImages(files, { imageableType: "job", imageableId: jobId, folder_id: folderParentId });
      files.forEach((file) => {
        tracking.trackEvent({ action: "photo uploaded", image: file.id, file: file.name });
      });

      this.setState({ loading: false });
    }
  }

  public onFilesError(error: any, file: any): void {
    const { flashAddAlert, tracking } = this.props;
    flashAddAlert(FlashLevels.danger, error.message);
    tracking.trackEvent({ action: "invalid file", file: file.name });
  }

  public openInviteModal(): void {
    const { openSendInviteModal, folderParentId } = this.props;
    openSendInviteModal(folderParentId);
  }

  public refreshListImages(): void {
    const { listImages, folderParentId, currentQueryParams } = this.props;
    listImages(folderParentId, true, currentQueryParams);
  }

  public render() {
    const { selectedCheck, showInfo, navDisplay, sendInviteSent, elevationsLoading, folderParentId } = this.props;
    const { loading } = this.state;

    return (
      <Row data-testid="imageTab">
        <Col>
          <SpinnerComponent localProperty={loading || elevationsLoading} />
          <Row className="images title-row sticky-job-title">
            <Col sm={4}>
              <h1 className="tab-title" style={{ marginBottom: "0" }}>
                Photos
              </h1>
            </Col>
            <Col sm={8}>
              <Can resource="job" permission="update">
                <Files
                  className="files-dropzone pull-right"
                  onChange={this.onFilesChange}
                  onError={this.onFilesError}
                  accepts={["image/*"]}
                  multiple
                  maxFileSize={30000000}
                  minFileSize={0}
                  clickable>
                  <Button id="job-add-photos" variant="default" className="ml-0">
                    <FontAwesome name="plus" /> Add Photos
                  </Button>
                </Files>
                {navDisplay ? (
                  <>
                    <Button
                      id="job-upload-invite-send"
                      variant="default"
                      className="pull-right"
                      onClick={this.openInviteModal}>
                      <FontAwesome name="paper-plane" /> Send Upload Invite
                    </Button>
                    <RefreshButton className="pull-right" refresh={this.refreshListImages} trigger={sendInviteSent} />
                  </>
                ) : (
                  <Button
                    title="Show image title and description"
                    className="pull-right"
                    variant={showInfo ? "success" : "secondary"}
                    onClick={this.toggleShowInfo}>
                    <FontAwesome name="info" />
                  </Button>
                )}
                {selectedCheck && <MoveFilesDropdown {...this.props} fileType="images" />}
              </Can>
            </Col>
          </Row>
          <br />
          <Folders
            {...this.props}
            fileType="images"
            documentableType="job"
            navigate={this.navigate}
            miniView={!navDisplay}
            readOnly={!navDisplay}
          />
          <br />
          <Images folderParentId={folderParentId} />
        </Col>
      </Row>
    );
  }
}

export default connector(ImageTab);
