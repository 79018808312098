import * as openingEstimationActions from "./openingEstimation.actions";
import { Map, Record, List } from "immutable";
import { OpeningEstimationRecord, fromJSON } from "../records/OpeningEstimation";
import { RootActions, RootState } from ".";

export const OpeningEstimationStateRecord = Record({
  byId: Map<number, OpeningEstimationRecord>(),
  lastSavedById: Map<number, OpeningEstimationRecord>(),
  unsavedId: -1,
});

export const initialState = OpeningEstimationStateRecord();

export type OpeningEstimationState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get("openingEstimations")) {
    state = state.set("openingEstimations", initialState);
  }

  let openingEstimation: OpeningEstimationRecord;
  switch (action.type) {
    case openingEstimationActions.RECEIVE_OPENING_ESTIMATION:
      openingEstimation = fromJSON({ ...action.payload.openingEstimation });

      return state
        .setIn(["openingEstimations", "byId", openingEstimation.id], openingEstimation)
        .setIn(["openingEstimations", "lastSavedById", openingEstimation.id], openingEstimation);

    case openingEstimationActions.DUPLICATE_OPENING_ESTIMATIONS:
      const lineItem = state.getIn(["estimateLineItems", "byId", action.payload.lineItemId]);

      let openingEstimationIds = List();
      let unsavedId = state.getIn(["openingEstimations", "unsavedId"]);
      action.payload.openingEstimationIds.map((oeId: number) => {
        let openingEstimation = state.getIn(["openingEstimations", "byId", oeId]);
        openingEstimation = openingEstimation.set("id", unsavedId).set("estimate_line_item_id", lineItem.id);
        openingEstimationIds = openingEstimationIds.push(unsavedId);
        state = state.setIn(["openingEstimations", "byId", openingEstimation.id], openingEstimation);
        unsavedId -= 1;
      });

      return state
        .setIn(["estimateLineItems", "byId", lineItem.id, "opening_estimation_ids"], openingEstimationIds)
        .setIn(["openingEstimations", "unsavedId"], unsavedId);

    default:
      return state;
  }
};
