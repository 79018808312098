import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { token as selectToken, tokenChanged } from "app2/src/selectors/token.selectors";
import * as tokenActions from "app2/src/reducers/token.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { TokenKind } from "app2/src/records/Token";
import { Button, Modal } from "react-bootstrap";
import { RsfForm } from "@tberrysoln/rsf-form";
import { AuthorizationForm as JobNimbusAuthForm } from "app2/src/components/Integrations/JobNimbus/AuthorizationForm";
import { AuthorizationForm as JobProgressAuthForm } from "app2/src/components/Integrations/Jobprogress/AuthorizationForm";
import { AuthorizationForm as RoofScopeAuthForm } from "app2/src/components/Integrations/RoofScope/AuthorizationForm";
import { AuthorizationForm as ImproveIt360AuthForm } from "app2/src/components/Integrations/ImproveIt360/AuthorizationForm";
import { connectFC } from "app2/src/connect";
import { useRouteMatch } from "react-router";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { IPretty } from "app/src/Common/PrettyNameService";
import { useTracking } from "react-tracking";
import { StoreRegistry } from "app2/src/storeRegistry";
import * as integrationAuthActions from "app2/src/reducers/integrationAuth.actions";

const validIntegrations = ["job_nimbus", "jobprogress", "roof_scope", "improveit360"];

const AuthorizationModal: React.FunctionComponent = () => {
  //Constants
  const match = useRouteMatch<{ integration: TokenKind }>();
  const integration = match.params.integration;
  const pretty: IPretty = StoreRegistry.get("Pretty");
  const integrationTitle = pretty.name[integration];
  const { trackEvent } = useTracking<any>({ component: "AuthorizationModal", integration: integration });

  // Selectors
  const orgId = useSelector(currentOrgId);

  //Hooks
  const dispatch = useDispatch();
  const { goBack } = usePreviousPath();
  if (!validIntegrations.includes(integration)) {
    goBack(`/authorization/${integration}`);
    return null;
  }

  //Selectors
  const token = useSelector((state: RootState) => selectToken(state, { kind: integration }));
  const tokenChangedFlag = useSelector((state: RootState) => tokenChanged(state, { kind: integration }));

  //Methods
  const close = () => {
    trackEvent({ action: "close" });
    goBack(`/authorization/${integration}`);
  };

  const submit = async () => {
    trackEvent({ action: "submit" });
    await dispatch(tokenActions.AsyncActions.updateCreateToken(token));
    if (integration === "improveit360")
      dispatch(integrationAuthActions.AsyncActions.getAndOpenAuthUrl(integration, orgId));
    close();
    dispatch(tokenActions.AsyncActions.getAuthorizedTokens(orgId));
  };

  // Lifecycle
  React.useEffect(() => {
    dispatch(tokenActions.AsyncActions.getToken(orgId, integration));
  }, []);

  return (
    <Modal show={true} backdrop="static" onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{integrationTitle} Authorization</Modal.Title>
      </Modal.Header>

      {token?.loading ? null : (
        <RsfForm
          rootPath={["tokens", "byOrgId", orgId, integration]}
          updateFormReducer={tokenActions.Actions.update}
          onSubmit={submit}>
          <DirtyWatcher check={() => tokenChangedFlag} reset={close} />
          <Modal.Body>
            {integration === "job_nimbus" && <JobNimbusAuthForm />}
            {integration === "jobprogress" && <JobProgressAuthForm />}
            {integration === "roof_scope" && <RoofScopeAuthForm />}
            {integration === "improveit360" && <ImproveIt360AuthForm />}
          </Modal.Body>

          <Modal.Footer>
            <Button type="button" variant="cancel" className="pull-right" onClick={close}>
              Close
            </Button>
            <Button type="submit" variant="default" className="pull-right">
              Authorize
            </Button>
          </Modal.Footer>
        </RsfForm>
      )}
    </Modal>
  );
};

export default connectFC(AuthorizationModal);
