import { Record, List } from "immutable";
import { Nullable } from ".";
import { AbleProp } from "app/src/Models/Product";
import { IMeasurementLink } from "app/src/Models/MeasurementLink";
import { IProductOptionData, ProductOptionRecord } from "./ProductOption";
import {
  ProductToOptionGroupLinkRecord,
  IProductToOptionGroupLinkData,
  fromJSON as ptogLinkFromJSON,
} from "./ProductToOptionGroupLink";
import { ImageRecord, fromJSON as imageFromJSON, IImageData } from "./Image";
import { DocumentRecord, fromJSON as documentFromJSON, IDocumentData } from "./Document";
import { IOpening } from "app/src/Models/Opening";

export const fromJSON = (json: Partial<IProductData>): ProductRecord => {
  const recordData: IProductRecord = { ...(json as any) };

  if (Array.isArray(json.option_groups)) {
    recordData.option_group_ids = List(json.option_groups);
  }

  let record = new ProductRecord(recordData);

  if (json.ptog_links) {
    record = record.set(
      "ptog_links",
      List(
        json.ptog_links.map((ptogl) => {
          return ptogLinkFromJSON(ptogl);
        }),
      ),
    );
  }

  if (Array.isArray(json.children)) {
    record = record.set("children_ids", List(json.children.map((c) => c.id)));
  }
  if (Array.isArray(json.images)) {
    record = record.set("images", List(json.images.map((i) => imageFromJSON(i))));
  }
  if (Array.isArray(json.documents)) {
    record = record.set("documents", List(json.documents.map((d) => documentFromJSON(d))));
  }

  return record;
};

export const findProductBracket = (
  parentProduct: ProductRecord,
  productChildren: List<ProductRecord>,
  opening: IOpening,
): ProductRecord => {
  if (parentProduct.kind !== "window" && parentProduct.kind !== "door") {
    return;
  }

  let product = null;
  switch (parentProduct.bracket_type) {
    case "width_height_bt":
    case "ui_bt":
    case "sqft":
      product = bracket(productChildren, opening, parentProduct.bracket_type);
      break;
    case "per_ui":
    case "per_sqft":
      product = perUI(productChildren, opening);
      break;
    default:
      product = bracket(productChildren, opening, "ui_bt");
  }

  if (product) {
    return product;
  }

  return null;
};

const bracket = (productChildren: List<ProductRecord>, opening: IOpening, bracket_type: string): ProductRecord => {
  return productChildren.find((product: ProductRecord) => {
    return bracketMatch(opening, product, bracket_type);
  });
};

const bracketMatch = (opening: IOpening, product: ProductRecord, bracket_type): boolean => {
  if (bracket_type === "width_height_bt") {
    return widthHeightBracketMatch(opening, product);
  }

  let measurement = "ui";
  if (bracket_type === "sqft") {
    measurement = "area";
  }

  const match = opening[measurement] >= product.min_ui && opening[measurement] < product.max_ui;

  if (!match) {
    return match;
  }

  return widthHeightMatch(opening, product);
};

const perUI = (productChildren: List<ProductRecord>, opening: IOpening): ProductRecord => {
  return productChildren.find((product: ProductRecord) => {
    let match = false;
    switch (product.bracket_type) {
      case "width_height_bt":
      case "ui_bt":
      case "sqft":
        match = bracketMatch(opening, product, product.bracket_type);
        break;
      case "per_ui":
      case "per_sqft":
        match = widthHeightMatch(opening, product);
        break;
      default:
        match = bracketMatch(opening, product, "ui_bt");
    }
    return match;
  });
};

export const widthHeightMatch = (opening: IOpening, product: ProductRecord): boolean => {
  let match = true;
  if (product.min_width > 0) {
    match = match && opening.width >= product.min_width;
  }

  if (product.min_height > 0) {
    match = match && opening.height >= product.min_height;
  }

  if (product.max_width > 0) {
    match = match && opening.width <= product.max_width;
  }

  if (product.max_height > 0) {
    match = match && opening.height <= product.max_height;
  }

  return match;
};

const widthHeightBracketMatch = (opening: IOpening, product: ProductRecord) => {
  return (
    opening.width >= product.min_width &&
    opening.height >= product.min_height &&
    opening.width < product.max_width &&
    opening.height < product.max_height
  );
};

export interface IProductData {
  classy: string;
  id: number;
  parent_id: number;
  price_list_id: number;
  parent: IProductData;
  children: IProductData[];
  images: IImageData[];
  documents: IDocumentData[];
  name: string;
  include_parent_names: boolean;
  option_groups: number[];
  description: string;
  cost: number;
  msrp: number;
  price: number;
  product_price: number;
  labor_price: number;
  visibility: string;
  uom: string;
  sort_order: number;
  created_at: Date;
  updated_at: Date;
  uuid: string;
  bracket_type: string;
  min_ui: number;
  max_ui: number;
  min_width: number;
  max_width: number;
  min_height: number;
  max_height: number;
  base_product_price: number;
  base_labor_price: number;
  base_measure: number;
  kind: string;
  standard_measurement: number;
  standard_uom: string;
  measurement_link: IMeasurementLink;
  size_html: string;
  doc_html: string;
  ptog_links: IProductToOptionGroupLinkData[];
  options?: IProductOptionData[];
  discountable?: AbleProp;
  parent_discountable?: AbleProp;
  markupable?: AbleProp;
  parent_markupable?: AbleProp;
  leaf: boolean;
}

export interface IProductRecord {
  classy: string;
  id: number;
  parent_id: Nullable<number>;
  price_list_id: Nullable<number>;
  children_ids: List<number>;
  images: List<ImageRecord>;
  documents: List<DocumentRecord>;
  name: string;
  include_parent_names: boolean;
  ancestral_name: string;
  ancestral_name_lowercase: string;
  option_group_ids: List<number>;
  description: string;
  cost: number;
  msrp: number;
  price: number;
  product_price: number;
  labor_price: number;
  visibility: string;
  uom: string;
  sort_order: number;
  created_at: Date;
  updated_at: Date;
  uuid: string;
  bracket_type: string;
  min_ui: number;
  max_ui: number;
  min_width: number;
  max_width: number;
  min_height: number;
  max_height: number;
  base_product_price: number;
  base_labor_price: number;
  base_measure: number;
  kind: string;

  standard_measurement: number;
  standard_uom: string;
  measurement_link: IMeasurementLink;
  size_html: string;
  doc_html: string;
  ptog_links: List<ProductToOptionGroupLinkRecord>;
  options?: List<ProductOptionRecord>;
  discountable?: AbleProp;
  parent_discountable?: AbleProp;
  markupable?: AbleProp;
  parent_markupable?: AbleProp;
  leaf: boolean;
}

const defaultProductProps: IProductRecord = {
  classy: "",
  id: 0,
  parent_id: null,
  price_list_id: null,
  children_ids: List<number>(),
  images: List<ImageRecord>(),
  documents: List<any>(),
  name: "",
  include_parent_names: true,
  ancestral_name: "",
  ancestral_name_lowercase: "",
  option_group_ids: List<number>(),
  description: "",
  cost: 0,
  msrp: 0,
  price: 0,
  product_price: 0,
  labor_price: 0,
  visibility: "",
  uom: "",
  sort_order: 0,
  created_at: new Date(),
  updated_at: new Date(),
  uuid: "",
  bracket_type: "",
  min_ui: 0,
  max_ui: 0,
  min_width: 0,
  max_width: 0,
  min_height: 0,
  max_height: 0,
  base_product_price: 0,
  base_labor_price: 0,
  base_measure: 0,
  kind: "",

  standard_measurement: 0,
  standard_uom: "",
  measurement_link: null,
  size_html: "",
  doc_html: "",
  ptog_links: List<ProductToOptionGroupLinkRecord>(),
  options: List<ProductOptionRecord>(),
  discountable: "default",
  parent_discountable: "default",
  markupable: "default",
  parent_markupable: "default",
  leaf: false,
};

export class ProductRecord extends Record(defaultProductProps) implements IProductRecord {
  public readonly classy!: string;
  public readonly id!: number;
  public readonly parent_id!: number;
  public readonly price_list_id!: number;
  public readonly children_ids!: List<number>;
  public readonly images!: List<ImageRecord>;
  public readonly documents!: List<any>;
  public readonly name!: string;
  public readonly include_parent_names!: boolean;
  public readonly ancestral_name!: string;
  public readonly ancestral_name_lowercase!: string;
  public readonly option_group_ids!: List<number>;
  public readonly description!: string;
  public readonly cost!: number;
  public readonly msrp!: number;
  public readonly price!: number;
  public readonly product_price!: number;
  public readonly labor_price!: number;
  public readonly visibility!: string;
  public readonly uom!: string;
  public readonly sort_order!: number;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;
  public readonly uuid!: string;
  public readonly bracket_type!: string;
  public readonly min_ui!: number;
  public readonly max_ui!: number;
  public readonly min_width!: number;
  public readonly max_width!: number;
  public readonly min_height!: number;
  public readonly max_height!: number;
  public readonly base_product_price!: number;
  public readonly base_labor_price!: number;
  public readonly base_measure!: number;
  public readonly kind!: string;

  public readonly standard_measurement!: number;
  public readonly standard_uom!: string;
  public readonly measurement_link!: IMeasurementLink;
  public readonly size_html!: string;
  public readonly doc_html!: string;
  public readonly ptog_links!: List<ProductToOptionGroupLinkRecord>;
  public readonly options!: List<ProductOptionRecord>;
  public readonly discountable!: AbleProp;
  public readonly parent_discountable!: AbleProp;
  public readonly markupable!: AbleProp;
  public readonly parent_markupable!: AbleProp;
  public readonly leaf!: boolean;

  public constructor(values?: Partial<IProductRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
