import { IUserResource } from "../Models/User";
import { ISession } from "../Common/SessionService";
import { IBaseConfig } from "../Common/IBaseConfig";

export class ErrorCtrl {
  public static $inject = ["User", "Session", "$state", "BaseConfig"];
  /* eslint-disable @typescript-eslint/naming-convention */
  constructor(
    public User: IUserResource,
    public Session: ISession,
    public $state: ng.ui.IStateService,
    public BaseConfig: IBaseConfig,
  ) {}
  /* eslint-enable @typescript-eslint/naming-convention */

  public cancel(): any {
    this.$state.go(this.BaseConfig.DEFAULT_ROUTE);
  }
}
