import * as React from "react";
import { Button } from "react-bootstrap";
import { RootState } from "app2/src/reducers";
import * as orgActions from "app2/src/reducers/org.actions";
import { orgChanged } from "app2/src/selectors/org.selectors";
import CrmUserMatchTable from "app2/src/components/Integrations/Common/CrmUserMatchTable";
import { QueryParamsRecord } from "app2/src/records/Page";
import { getPaginationByModel, queryParams, pagination } from "app2/src/selectors/pagination.selectors";
import { CrmUserRecord } from "app2/src/records/integrations/CrmUser";
import { IUser } from "app/src/Models/User";
import * as crmUserActions from "app2/src/reducers/crmUser.actions";
import { getAuthorization, token } from "app2/src/selectors/token.selectors";
import CrmAuthorize from "app2/src/components/Integrations/Common/CrmAuthorize";
import * as tokenActions from "app2/src/reducers/token.actions";
import AuthorizationModal from "app2/src/components/Integrations/LeadPerfection/AuthorizationModal";
import SettingsModal from "app2/src/components/Integrations/LeadPerfection/SettingsModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Context, LeadPerfectionAction } from "app2/src/components/Integrations/LeadPerfection/Context";
import { TokenRecord } from "app2/src/records/Token";
import Spinner from "app2/src/components/SpinnerComponent";
import { ContextRecord } from "app2/src/components/Integrations/LeadPerfection/Context";
import { ICrmUserQuery } from "../../Common/IntegrationList";

export interface LeadPerfectionProps {
  orgId: number;
  users: IUser[];
  tokenUpdated: () => void;
}

const LeadPerfection: React.FunctionComponent<LeadPerfectionProps> = (props: LeadPerfectionProps) => {
  const { orgId, users, tokenUpdated } = props;
  const modelName = "LpUser";

  //hooks
  const [openAuthorizationModal, setOpenAuthorizationModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(tokenActions.AsyncActions.getToken(orgId, "lead_perfection"));
    queryUsers({ page: 1, query: "" });
  }, []);

  //selectors
  const lpQueryParams: QueryParamsRecord = useSelector((s: RootState) => queryParams(s, { modelName }));
  const authorized: boolean = useSelector((s: RootState) =>
    getAuthorization(s, { orgId: orgId, integration: "lead_perfection" }),
  );
  const dirty: boolean = useSelector((s: RootState) => orgChanged(s));
  const lpUsers = useSelector((s: RootState) => getPaginationByModel(s, { path: ["crmUsers", "byId"], modelName }));
  const lpPagination = useSelector((s: RootState) => pagination(s, { modelName, page: lpQueryParams.get("page") }));
  const leadPerfectionToken: TokenRecord = useSelector((s: RootState) => token(s, { kind: "lead_perfection" }));

  const dispatch = useDispatch();

  //functions
  const queryUsers = (queryObj: ICrmUserQuery) => {
    const newQueryParams = lpQueryParams.merge(queryObj);
    dispatch(crmUserActions.AsyncActions.queryUsers({ orgId, userType: modelName }, newQueryParams));
  };

  const updateUser = (lpUser: CrmUserRecord) => {
    dispatch(crmUserActions.AsyncActions.updateUser(lpUser));
  };

  const submit = async () => {
    setLoading(true);
    await dispatch(tokenActions.AsyncActions.updateCreateToken(leadPerfectionToken));
    setLoading(false);
    tokenUpdated();
    leadPerfectionReducer("close", {});
  };

  const rejectAuthorization = async () => {
    setLoading(true);
    await dispatch(tokenActions.AsyncActions.deleteToken(orgId, "lead_perfection"));
    setLoading(false);
    tokenUpdated();
  };

  const sync = () => {
    dispatch(orgActions.AsyncActions.syncIntegration(orgId, "InitialSyncJob", "lead_perfection"));
  };

  function authorize() {
    setOpenAuthorizationModal(true);
  }

  //reducer
  const leadPerfectionReducer = async (action: LeadPerfectionAction, payload: any) => {
    switch (action) {
      case "submit":
        await submit();
        return;
      case "close":
        setOpenSettingsModal(false);
        setOpenAuthorizationModal(false);
        return;
    }
  };

  return loading ? (
    <Spinner localProperty={true} />
  ) : (
    <div className="form-section">
      <div className="form-section-content">
        <CrmAuthorize
          title="LeadPerfection"
          image="/assets/images/lead_perfection.b666110c.jpg"
          authorized={authorized}
          authorize={() => authorize()}
          rejectAuthorization={rejectAuthorization}
          settings={() => setOpenSettingsModal(true)}
        />

        <Context.Provider value={new ContextRecord({ orgId, dispatch: leadPerfectionReducer })}>
          {openAuthorizationModal && <AuthorizationModal />}
          {openSettingsModal && <SettingsModal />}
        </Context.Provider>

        {!authorized && !dirty && <Button onClick={sync}>Initial Sync</Button>}
        {authorized && (
          <CrmUserMatchTable
            integrationType="LeadPerfection"
            occUsers={users}
            integrationUsers={lpUsers}
            pagination={lpPagination}
            queryUsers={queryUsers}
            saveUser={updateUser}
            sync={sync}
          />
        )}
      </div>
    </div>
  );
};

export default LeadPerfection;
