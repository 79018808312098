import * as React from "react";
import { useSelector } from "react-redux";
import { currentOrgId, preferencesConfig } from "app2/src/selectors/org.selectors";
import CustomerTab from "app2/src/components/JobTabs/PresentationTab/Customer";
import PresentationsTab from "app2/src/components/JobTabs/PresentationTab/Existing";
import { RootState } from "app2/src/reducers";

export const PresentationRouting: React.FC = () => {
  const orgId = useSelector(currentOrgId);
  const dynamicEnabled: boolean = useSelector((state: RootState) =>
    preferencesConfig(state, { orgId: orgId, path: ["presentations", "dynamic_enabled"] }),
  );

  return dynamicEnabled === true ? <CustomerTab activeKey={"customer"} /> : <PresentationsTab />;
};

export default PresentationRouting;
