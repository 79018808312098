import * as presentationTemplateActions from "./presentationTemplate.actions";
import { PresentationTemplateRecord, fromJSON } from "app2/src/records/PresentationTemplate";
import { Map, Record, List } from "immutable";

export interface IOrgPresentationTemplatesRecord {
  presentationTemplates: List<number>;
  errors: List<string>;
  loading: boolean;
}
export const OrgPresentationTemplatesRecord: Record.Factory<IOrgPresentationTemplatesRecord> =
  Record<IOrgPresentationTemplatesRecord>({
    presentationTemplates: List<number>(),
    errors: List<string>(),
    loading: false,
  });

export const PresentationTemplateStateRecord = Record({
  presentationTemplatesById: Map<number, PresentationTemplateRecord>(),
  presentationTemplatesByOrgId: Map<number, typeof OrgPresentationTemplatesRecord>(),
});

export const initialState = PresentationTemplateStateRecord();
export type PresentationTemplateState = typeof initialState;

export const reducer = (
  state = initialState,
  action: presentationTemplateActions.Actions,
): PresentationTemplateState => {
  switch (action.type) {
    case presentationTemplateActions.FETCH_PRESENTATION_TEMPLATES:
      if (!state.getIn(["presentationTemplatesByOrgId", action.payload.orgId])) {
        return state.setIn(
          ["presentationTemplatesByOrgId", action.payload.orgId],
          OrgPresentationTemplatesRecord({ loading: true }),
        );
      }

      return state.setIn(["presentationTemplatesByOrgId", action.payload.orgId, "loading"], true);
    case presentationTemplateActions.RECEIVE_PRESENTATION_TEMPLATES:
      state = state.setIn(["presentationTemplatesByOrgId", action.payload.orgId, "presentationTemplates"], List([]));
      action.payload.presentationTemplates.forEach((d) => {
        state = reducer(
          state,
          presentationTemplateActions.Actions.receivePresentationTemplate(action.payload.orgId, d),
        );
      });

      return state.setIn(["presentationTemplatesByOrgId", action.payload.orgId, "loading"], false);
    case presentationTemplateActions.RECEIVE_PRESENTATION_TEMPLATES_ERROR:
      return state
        .setIn(["presentationTemplatesByOrgId", action.payload.orgId, "errors"], List<string>(action.payload.errors))
        .setIn(["presentationTemplatesByOrgId", action.payload.orgId, "loading"], false);
    case presentationTemplateActions.FETCH_PRESENTATION_TEMPLATE:
      if (state.getIn(["presentationTemplatesById", action.payload.id])) {
        return state.setIn(["presentationTemplatesById", action.payload.id, "loading"], true);
      }
      return state.setIn(
        ["presentationTemplatesById", action.payload.id],
        fromJSON({ id: action.payload.id, loading: true }),
      );
    case presentationTemplateActions.RECEIVE_PRESENTATION_TEMPLATE:
      const presentationTemplate = action.payload.presentationTemplate.set("loading", false);

      if (!state.getIn(["presentationTemplatesByOrgId", action.payload.orgId])) {
        state = state.setIn(["presentationTemplatesByOrgId", action.payload.orgId], OrgPresentationTemplatesRecord({}));
      }

      return state
        .setIn(["presentationTemplatesById", presentationTemplate.id], presentationTemplate)
        .updateIn(["presentationTemplatesByOrgId", action.payload.orgId, "presentationTemplates"], (pts) => {
          return pts.push(presentationTemplate.id).toSet().toList();
        });
    case presentationTemplateActions.RECEIVE_PRESENTATION_TEMPLATE_ERROR:
      return state
        .setIn(
          ["presentationTemplatesById", action.payload.presentationTemplateId, "errors"],
          List<string>(action.payload.errors),
        )
        .setIn(["presentationTemplatesById", action.payload.presentationTemplateId, "loading"], false);
    case presentationTemplateActions.REMOVE_PRESENTATION_TEMPLATE:
      return state
        .removeIn(["presentationTemplatesById", action.payload.presentationTemplate.id])
        .updateIn(
          ["presentationTemplatesByOrgId", action.payload.presentationTemplate.org_id, "presentationTemplates"],
          (presentationTemplates: List<number>) => {
            return presentationTemplates.filter((d) => d !== action.payload.presentationTemplate.id);
          },
        );
    default:
      return state;
  }
};
