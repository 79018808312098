import { Record, List } from "immutable";
import { Nullable } from ".";
import { IEstimateLineItemData } from "./EstimateLineItem";
import { IRoomEstimationData, RoomEstimationRecord, fromJSON as roomEstimationFromJson } from "./RoomEstimation";

export const fromJSON = (json: Partial<IEstimateGroupData>): EstimateGroupRecord => {
  const record: IEstimateGroupRecord = { ...(json as any) };

  if (json.line_items) {
    record.line_item_ids = List(json.line_items.map((li) => li.id));
  }

  if (json.room_estimations) {
    record.room_estimations = List(json.room_estimations.map((estimation) => roomEstimationFromJson(estimation)));
  }

  return new EstimateGroupRecord(record);
};

export interface IEstimateGroupData {
  id: number;
  name: string;
  included: boolean;
  subtotal: number;
  total: number;
  labor_total: number;
  product_total: number;
  line_items: IEstimateLineItemData[];
  room_estimations: IRoomEstimationData[];
  created_at: Date;
  updated_at: Date;
  sort_order: number;
  _destroy: boolean;
}

export interface IEstimateGroupRecord {
  id: number;
  name: string;
  included: boolean;
  subtotal: number;
  total: number;
  labor_total: number;
  product_total: number;
  line_item_ids: List<number>;
  room_estimations: List<RoomEstimationRecord>;
  created_at: Date;
  updated_at: Date;
  sort_order: number;
  _destroy: Nullable<boolean>;
}

const defaultEstimateGroupProps: IEstimateGroupRecord = {
  id: 0,
  name: "",
  included: true,
  subtotal: 0,
  total: 0,
  labor_total: 0,
  product_total: 0,
  line_item_ids: List<number>(),
  room_estimations: List<RoomEstimationRecord>(),
  created_at: new Date(),
  updated_at: new Date(),
  sort_order: 0,
  _destroy: null,
};

export class EstimateGroupRecord extends Record(defaultEstimateGroupProps) implements IEstimateGroupRecord {
  public readonly id!: number;
  public readonly name!: string;
  public readonly included!: boolean;
  public readonly subtotal!: number;
  public readonly total!: number;
  public readonly labor_total!: number;
  public readonly product_total!: number;
  public readonly line_item_ids!: List<number>;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;
  public readonly sort_order!: number;
  public readonly _destroy!: Nullable<boolean>;

  public constructor(values?: Partial<IEstimateGroupRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
