import * as React from "react";
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from "twilio-video";
import { useSelector } from "react-redux";
import usePublications from "app2/src/components/Twilio/hooks/usePublications/usePublications";
import useParticipantNetworkQualityLevel from "app2/src/components/Twilio/hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel";
import useTrack from "app2/src/components/Twilio/hooks/useTrack/useTrack";
import useIsTrackSwitchedOff from "app2/src/components/Twilio/hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import * as FontAwesome from "react-fontawesome";
import BandwidthWarning from "app2/src/components/Twilio/components/BandwidthWarning";
import NetworkQualityLevel from "app2/src/components/Twilio/components/NetworkQualityLevel";
import AudioLevelIndicator from "./AudioLevelIndicator";
import ParticipantConnectionIndicator from "app2/src/components/Twilio/components/ParticipantConnectionIndicator";
import { screenShareService } from "app2/src/api/screenShare.service";
import { RootState } from "app2/src/reducers";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
  remoteParticipant: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  remoteParticipant,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);
  const audioPublication = publications.find((p) => p.kind === "audio");
  const videoPublication = publications.find((p) => p.trackName.includes("camera"));
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find((p) => p.trackName === "screen");
  const { roomParams } = useVideoContext();
  const job = useSelector((state: RootState) => state.getIn(["components", "common", "currentJob"]));
  const activeScreenShare = useSelector((state: RootState) =>
    state.getIn(["screenShares", "byJobId", job.id, "screenShare"]),
  );

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;

  const identity = participant.identity.split(" -")[0];
  const hostPower = roomParams.host && remoteParticipant;
  const disconnect = () => {
    screenShareService.removeParticipant(activeScreenShare.id, participant.sid);
  };
  const remoteClass = roomParams.host && remoteParticipant ? "h4-hover" : "";

  return (
    <div className={"participant-info"} onClick={onClick} data-cy-participant={participant.identity}>
      <div className={isVideoEnabled ? "info-container" : "info-container hide-video"}>
        <div className="info-row">
          {hostPower ? (
            <ConfirmDialog title={`Are you sure you want to remove ${identity}?`}>
              {(confirm) => {
                return (
                  <>
                    <h4
                      className={remoteClass}
                      title={hostPower ? "Remove Participant" : ""}
                      onClick={confirm(disconnect)}>
                      <ParticipantConnectionIndicator participant={participant} />
                      {identity}
                    </h4>
                    <NetworkQualityLevel qualityLevel={networkQualityLevel} />
                  </>
                );
              }}
            </ConfirmDialog>
          ) : (
            <>
              <h4>
                <ParticipantConnectionIndicator participant={participant} />
                {identity}
              </h4>
              <NetworkQualityLevel qualityLevel={networkQualityLevel} />
            </>
          )}
        </div>
        <div>
          <AudioLevelIndicator audioTrack={audioTrack} background="#BFBFBF" />
          {isScreenShareEnabled && <FontAwesome name="desktop" />}
          {isSelected && <FontAwesome name="thumb-tack" />}
        </div>
      </div>
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
    </div>
  );
}
