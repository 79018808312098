import { Nullable, checkDate } from "app2/src/records";
import { Record } from "immutable";
import * as moment from "moment";

export const fromJSON = (json: Partial<IFinanceApplicationData>): FinanceApplicationRecord => {
  const recordData: IFinanceApplicationRecord = { ...(json as any) };

  if (recordData.valid_until) {
    recordData.valid_until = checkDate(recordData.valid_until);
  }

  return new FinanceApplicationRecord(recordData);
};

export interface IFinanceApplicationData {
  id: number;
  estimate_id: number;
  loan_id: string;
  redirect_url: string;
  loan_status: string;
  offer_status: string;
  offer_code: string;
  loading?: boolean;
  details_url: string;
  valid_until: string;
  max_approved_amount: number;
  apply_url?: string;

  created_at: Date | string;
  updated_at: Date | string;
}

export interface IFinanceApplicationRecord {
  id: number;
  estimate_id: Nullable<number>;
  loan_id: Nullable<string>;
  redirect_url: string;
  loan_status: string;
  offer_status: string;
  offer_code: string;
  verify_check: boolean;
  details_url: string;
  valid_until: Date;
  max_approved_amount: number;
  apply_url: Nullable<string>;
  loading: boolean;

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

const defaultFinanceApplicationProps: IFinanceApplicationRecord = {
  id: 0,
  estimate_id: null,
  loan_id: null,
  redirect_url: "",
  loan_status: "",
  offer_status: "",
  offer_code: "",
  verify_check: false,
  details_url: "",
  max_approved_amount: 0,
  valid_until: moment().add(30, "days").toDate(),
  apply_url: null,
  loading: false,

  created_at: null,
  updated_at: null,
};

export class FinanceApplicationRecord
  extends Record(defaultFinanceApplicationProps)
  implements IFinanceApplicationRecord {}
