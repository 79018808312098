import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction } from "./Utils";
import { RootState } from ".";
import { fetcher } from "app2/src/helpers/Fetcher";
import { RootDispatchType } from "../store";
import { handleErrors } from "app2/src/reducers/Utils";
import { create, update, deleteEvent, IInviteParams, sendInvite, resultEvent, load } from "../api/event.service";
import { EventRecord, IEventData } from "../records/Event";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { queryParamsFromJSON, QueryParamsRecord } from "../records/Page";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { IMetaPaginationData } from "../records/MetaPagination";
import { List } from "immutable";
import { event as eventSelector } from "../selectors/event.selectors";
import { pageRecord } from "app2/src/selectors/pagination.selectors";

export const RECEIVE_EVENTS = "@appointments/RECEIVE_EVENTS";
export const FETCH_EVENT = "@appointments/FETCH_EVENT";
export const RECEIVE_EVENT = "@appointments/RECEIVE_EVENT";
export const FETCH_BUSY = "@events/FETCH_BUSY";
export const RECEIVE_BUSY = "@events/RECEIVE_BUSY";
export const UPDATE_FORM = "@events/UPDATE_FORM";
export const INITIALIZE_EVENT = "@events/INITIALIZE_EVENT";
export const RESET_EVENT = "@events/RESET_EVENT";
export const REMOVE_EVENT = "@events/REMOVE_EVENT";
export const GENERATE_COLORS = "@events/GENERATE_COLORS";

export type IBusyInfo = { [key: string]: boolean };

export const Actions = {
  receiveEvents: (events: IEventData[]) => createAction(RECEIVE_EVENTS, { events }),
  fetchEvent: (eventId: number) => createAction(FETCH_EVENT, { eventId }),
  receiveEvent: (event: IEventData) => createAction(RECEIVE_EVENT, { event }),
  fetchMonthFreeBusy: (start: string, end: string) => createAction(FETCH_BUSY, { start, end }),
  receiveMonthFreeBusy: (start: string, end: string, busyInfo: IBusyInfo) =>
    createAction(RECEIVE_BUSY, { start, end, busyInfo }),
  updateForm: (event: { rootPath: any; name: string; value: any }) => createAction(UPDATE_FORM, { event }),
  resetEvent: (eventId: number) => createAction(RESET_EVENT, { eventId }),
  initializeEvent: (newEventData: Partial<IEventData>) => createAction(INITIALIZE_EVENT, { newEventData }),
  removeEvent: (eventId: number) => createAction(REMOVE_EVENT, { eventId }),
  generateColors: (userIds: List<number>, userToAssignId?: number) =>
    createAction(GENERATE_COLORS, { userIds, userToAssignId }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, ActionsUnion<typeof Actions>>;

export const AsyncActions = {
  fetchBusyInfo: (start: string, end: string): ThunkResult<Promise<IBusyInfo>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchMonthFreeBusy(start, end));
      const actionUrl = "/api/v1/events/busy";

      return fetcher
        .get(actionUrl, {
          start_date: start,
          end_date: end,
        })
        .then((data: { busy: IBusyInfo }) => {
          dispatch(Actions.receiveMonthFreeBusy(start, end, data.busy));
          return data.busy;
        });
    };
  },
  loadEvents: (
    queryParams: QueryParamsRecord = queryParamsFromJSON({}),
    modelName = "events",
    allPages = false,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(paginationActions.Actions.fetchPage(modelName, queryParams));

        const response = await load(queryParams);
        let pagination = {} as IMetaPaginationData;
        if (response.meta?.pagination) {
          pagination = response.meta.pagination;
        }
        const pageData = {
          ids: List(_.pluck(response.events, "id")),
          pagination,
        };

        dispatch(Actions.receiveEvents(response.events));
        dispatch(paginationActions.Actions.receivePage(modelName, queryParams, pageData));

        if (allPages && pagination.current_page < pagination.total_pages) {
          const totalEventsQueried = pagination.current_page * queryParams.per_page;

          // Limit to 300 queried events
          if (totalEventsQueried + queryParams.per_page > 300) {
            dispatch(
              commonActions.Actions.flashAddAlert(
                FlashLevels.warning,
                "You have reached the limit on the number of appointments we can load and display.  To see all the appointments please choose a view with a smaller timeframe.",
              ),
            );
            return;
          }
          return dispatch(
            AsyncActions.loadEvents(queryParams.set("page", pagination.current_page + 1), modelName, allPages),
          );
        }

        return;
      } catch (response) {
        const errors = handleErrors(response, dispatch);
        dispatch(paginationActions.Actions.receivePageError(modelName, queryParams, errors));
      }
    };
  },
  createOrUpdate: (eventId: number, includes: string[]): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      if (eventId === 0) {
        await dispatch(AsyncActions.create(eventId, includes));
      } else {
        await dispatch(AsyncActions.update(eventId, includes));
      }
    };
  },
  updateTime: (eventData: Partial<IEventData>, includes: string[]): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const rootPath = ["events", "byId", eventData.id];
      dispatch(Actions.updateForm({ rootPath, name: "start_time", value: eventData.start_time }));
      dispatch(Actions.updateForm({ rootPath, name: "end_time", value: eventData.end_time }));
      await dispatch(AsyncActions.update(eventData.id, includes));
    };
  },
  create: (eventId: number, includes: string[]): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const event = eventSelector(getState(), { eventId });

      try {
        dispatch(Actions.fetchEvent(eventId));
        const response = await create(event, includes);
        dispatch(Actions.receiveEvent(response.event));
        dispatch(paginationActions.Actions.pushId(response.event.id, "events"));
        dispatch(paginationActions.Actions.pushId(response.event.id, "userEvents"));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Appointment created successfully."));
        dispatch(Actions.resetEvent(eventId));
      } catch (response) {
        dispatch(Actions.resetEvent(eventId));
        handleErrors(response, dispatch);
      }
    };
  },
  update: (eventId: number, includes: string[]): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const event = eventSelector(getState(), { eventId });

      try {
        dispatch(Actions.fetchEvent(eventId));
        const response = await update(event, includes);
        dispatch(Actions.receiveEvent(response.event));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Appointment saved successfully."));
      } catch (response) {
        dispatch(Actions.resetEvent(eventId));
        handleErrors(response, dispatch);
      }
    };
  },
  delete: (eventId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        await deleteEvent(eventId);
        if (pageRecord(getState(), { modelName: "events", page: 1 })) {
          dispatch(paginationActions.Actions.removeId(eventId, "events"));
        }
        if (pageRecord(getState(), { modelName: "userEvents", page: 1 })) {
          dispatch(paginationActions.Actions.removeId(eventId, "userEvents"));
        }
        dispatch(Actions.removeEvent(eventId));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Appointment deleted successfully."));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  sendInvite: (jobId: number, params: IInviteParams): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        await sendInvite(jobId, params);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Invite successfully sent."));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  resultEvent: (eventRecord: EventRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const { event } = await resultEvent(eventRecord);
        dispatch(Actions.receiveEvent(event));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Appointment result saved successfully."));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
