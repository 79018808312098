import { IProductOptionsMatch, IProductOptionResource } from "app/src/Models/ProductOption";

class OptionsMatchCtrl implements ng.IComponentController {
  public remove: (args: any) => null;
  public edit: (args: any) => null;
  public match: (args: any) => null;
  public editOption: (args: any) => null;
  public optionsMatch: IProductOptionsMatch;

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $scope: ng.IScope,
    public ProductOption: IProductOptionResource,
    public $q: ng.IQService,
  ) {}

  public $onChanges() {}

  public removeOptionsMatch() {
    this.remove({ optionsMatch: this.optionsMatch });
  }

  public editOptionsMatch() {
    this.edit({ optionsMatch: this.optionsMatch });
  }

  public matchOptionsMatch() {
    this.match({ optionsMatch: this.optionsMatch });
  }

  public editProductOption(option) {
    this.editOption({ option: option });
  }

  public showOptions(value) {
    this.optionsMatch.show = value;
  }
}

export class OptionsMatchComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Orgs/tabs/PriceListEditor/options_match_component.html";
  public bindings: any = {
    optionsMatch: "<",
    remove: "&",
    edit: "&",
    match: "&",
    editOption: "&",
  };

  constructor() {
    this.controller = OptionsMatchCtrl;
    this.controller.$inject = ["$uibModal", "$scope", "ProductOption", "$q"];
  }
}
