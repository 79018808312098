import * as React from "react";
import { Accordion } from "react-bootstrap";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import InspectionItem from "app2/src/components/JobTabs/InspectionTab/InspectionItem";
import { IEstimate } from "app/src/Models/Estimate";
import { Nullable } from "app2/src/records";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";

export interface IInspectionGroupProps {
  activatedPriceListId: number;
  group: IEstimateGroup;
  queue: IFileQueueFactory;
  check: any;
  estimate: IEstimate;
}

export interface IInspectionGroupState {
  selectedEventKey: Nullable<string>;
}

export default class InspectionGroup extends React.Component<IInspectionGroupProps, IInspectionGroupState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedEventKey: null,
    };
  }

  public render() {
    const { group, queue, check, activatedPriceListId, estimate } = this.props;
    const { selectedEventKey } = this.state;
    return (
      <Accordion activeKey={selectedEventKey} onSelect={(event) => this.setState({ selectedEventKey: event })}>
        {group.line_items.map((li, idx) => (
          <InspectionItem
            activatedPriceListId={activatedPriceListId}
            selectedEventKey={selectedEventKey}
            lineItem={li}
            queue={queue}
            check={check}
            triggerExpand={(selectedEventKey: string) => this.setState({ selectedEventKey })}
            key={idx}
            estimate={estimate}
          />
        ))}
      </Accordion>
    );
  }
}
