import * as discountActions from "./discount.actions";
import { DiscountRecord } from "app2/src/records/DiscountRecord";
import { Map, Record, List } from "immutable";

export interface IOrgDiscountsRecord {
  discounts: List<number>;
  errors: List<string>;
  loading: boolean;
}
export const OrgDiscountsRecord: Record.Factory<IOrgDiscountsRecord> = Record<IOrgDiscountsRecord>({
  discounts: List<number>(),
  errors: List<string>(),
  loading: false,
});

export const DiscountStateRecord = Record({
  discountsById: Map<number, DiscountRecord>(),
  discountsByOrgId: Map<number, List<typeof OrgDiscountsRecord>>(),
});

export const initialState = DiscountStateRecord();
export type DiscountState = typeof initialState;

export const reducer = (state = initialState, action: discountActions.Actions): DiscountState => {
  switch (action.type) {
    case discountActions.RECEIVE_DISCOUNT:
      const discount = new DiscountRecord({ ...action.payload, loading: false });

      if (!state.getIn(["discountsByOrgId", discount.org_id])) {
        state = state.setIn(["discountsByOrgId", discount.org_id], OrgDiscountsRecord({}));
      }

      return state.setIn(["discountsById", discount.id], discount).setIn(
        ["discountsByOrgId", discount.org_id, "discounts"],
        state
          //@ts-ignore
          .getIn(["discountsByOrgId", discount.org_id, "discounts"], List<number>())
          .push(discount.id)
          .toSet()
          .toList(),
      );
    case discountActions.RECEIVE_DISCOUNT_ERROR:
      return state
        .setIn(["discountsById", action.payload.discountId, "errors"], List<string>(action.payload.errors))
        .setIn(["discountsById", action.payload.discountId, "loading"], false);
    case discountActions.FETCH_DISCOUNT:
      if (state.getIn(["discountsById", action.payload.discountId])) {
        return state.setIn(["discountsById", action.payload.discountId, "loading"], true);
      }
      return state.setIn(
        ["discountsById", action.payload.discountId],
        new DiscountRecord({ id: action.payload.discountId, org_id: action.payload.orgId, loading: true }),
      );
    case discountActions.FETCH_ORG_DISCOUNTS:
      if (!state.getIn(["discountsByOrgId", action.payload])) {
        return state.setIn(["discountsByOrgId", action.payload], OrgDiscountsRecord({ loading: true }));
      }

      return state.setIn(["discountsByOrgId", action.payload, "loading"], true);
    case discountActions.RECEIVE_ORG_DISCOUNTS:
      state = state.setIn(["discountsByOrgId", action.payload.orgId, "discounts"], List([]));
      action.payload.discounts.forEach((d) => {
        state = reducer(state, discountActions.Actions.receiveDiscount(d));
      });

      return state.setIn(["discountsByOrgId", action.payload.orgId, "loading"], false);
    case discountActions.RECEIVE_ORG_ERRORS:
      return state
        .setIn(["discountsByOrgId", action.payload.orgId, "errors"], List<string>(action.payload.errors))
        .setIn(["discountsByOrgId", action.payload.orgId, "loading"], false);
    case discountActions.REMOVE_DISCOUNT:
      return state.removeIn(["discountsById", action.payload]);
    case discountActions.REMOVE_ORG_DISCOUNT:
      return state.updateIn(["discountsByOrgId", action.payload.orgId, "discounts"], (discounts: List<number>) => {
        return discounts.filter((d) => d !== action.payload.discountId);
      });
    default:
      return state;
  }
};
