import { ThunkAction } from "redux-thunk";
import { IFinanceApplicationData, IFinanceApplicationRecord } from "app2/src/records/FinanceApplication";
import { ActionsUnion, createAction } from "app2/src/reducers/Utils";
import { RootState } from "app2/src/reducers";
import { RootDispatchType } from "app2/src/store";
import { load, create, loadByJob, recalculate, selectOffer } from "app2/src/api/financeApplication.service";
import { handleErrors } from "app2/src/reducers/Utils";
import { Actions as viewActions } from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { financeApplication } from "app2/src/selectors/financeApplication.selectors";
import { QueryParamsRecord } from "../records/Page";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { currentJobId } from "../selectors/job.selectors";
import { List } from "immutable";

// SINGLE
export const FETCH_FINANCE_APPLICATION = "@financeApplications/FETCH_FINANCE_APPLICATION";
export const RECEIVE_FINANCE_APPLICATION = "@financeApplications/RECEIVE_FINANCE_APPLICATION";
export const SET_LOADED = "@financeApplications/SET_LOADED";
export const UPDATE_FORM = "@financeApplications/UPDATE_FORM";
export const SET_OFFER_STATUS = "@financeApplications/SET_OFFER_STATUS";
export const RECEIVE_APPLY_URL = "@financeApplications/RECEIEVE_APPLY_URL";

// MULTIPLE
export const RECEIVE_FINANCE_APPLICATIONS = "@financeApplications/RECEIVE_FINANCE_APPLICATIONS";

export const Actions = {
  // SINGLE
  fetchFinanceApplication: (estimateId: number) => createAction(FETCH_FINANCE_APPLICATION, { estimateId }),
  receiveFinanceApplication: (financeApplication: IFinanceApplicationData) =>
    createAction(RECEIVE_FINANCE_APPLICATION, { financeApplication }),
  setLoaded: (estimateId: number) => createAction(SET_LOADED, { estimateId }),
  update: (event: any) => createAction(UPDATE_FORM, { event }),
  setOfferStatus: (estimateId: number, status: string) => createAction(SET_OFFER_STATUS, { estimateId, status }),
  receiveApplyUrl: (estimateId: number, applyUrl: string) => createAction(RECEIVE_APPLY_URL, { estimateId, applyUrl }),
  // MULTIPLE
  receiveFinanceApplications: (financeApplications: IFinanceApplicationData[]) =>
    createAction(RECEIVE_FINANCE_APPLICATIONS, { financeApplications }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  loadFinanceApplication: (estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchFinanceApplication(estimateId));

      try {
        const response = await load(estimateId);
        dispatch(Actions.receiveFinanceApplication(response.finance_application));
      } catch (response) {
        if (response.status === 404) {
          dispatch(
            Actions.receiveFinanceApplication({
              estimate_id: estimateId,
              loan_status: "none",
            } as IFinanceApplicationData),
          );
          return;
        }
        handleErrors(response, dispatch);
        dispatch(Actions.setLoaded(estimateId));
      }
    };
  },
  createFinanceApplication: (estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const record = financeApplication(getState(), { estimateId });
        dispatch(Actions.fetchFinanceApplication(estimateId));
        const response = await create(estimateId, record.toJS());
        dispatch(Actions.receiveFinanceApplication(response.finance_application));
        dispatch(viewActions.flashAddAlert(FlashLevels.success, "Created finance application successfully"));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setLoaded(estimateId));
      }
    };
  },
  loadFinanceApplications: (queryParams: QueryParamsRecord) => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const jobId = currentJobId(getState());
        dispatch(paginationActions.Actions.fetchPage("financeApplications", queryParams));

        const response = await loadByJob(jobId, queryParams);
        const pageData = {
          ids: List(_.pluck(response.finance_applications, "estimate_id")),
          pagination: response.meta.pagination,
        };

        dispatch(Actions.receiveFinanceApplications(response.finance_applications));
        dispatch(paginationActions.Actions.receivePage("financeApplications", queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(paginationActions.Actions.receivePageError("financeApplications", queryParams, errors));
      }
    };
  },
  recalculateFinanceOption: (estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(Actions.setOfferStatus(estimateId, "processing"));
        await recalculate(estimateId);
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setLoaded(estimateId));
      }
    };
  },
  selectOffer: (financeApplicationId: number, financeOptionId, estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(Actions.fetchFinanceApplication(estimateId));
        const result = await selectOffer(financeApplicationId, { finance_option_id: financeOptionId });
        dispatch(Actions.receiveApplyUrl(estimateId, result.url));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setLoaded(estimateId));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
