import { List, Record } from "immutable";
import { IProductData } from "./Product";
import { IProductOptionGroupData } from "./ProductOptionGroup";

export const fromJSON = (json: Partial<IPriceListData>): PriceListRecord => {
  const recordData: IPriceListRecord = { ...(json as any) };

  if (json.product_option_groups) {
    recordData.product_option_group_ids = List(json.product_option_groups.map((pog) => pog.id));
  }

  if (json.products) {
    recordData.product_ids = List(json.products.map((p) => p.id));
  }

  return new PriceListRecord(recordData);
};

export interface IPriceListData {
  id: number;
  org_id: number;
  name: string;
  kind: string;
  hidden_products: boolean;
  products: IProductData[];
  product_option_groups: IProductOptionGroupData[];
  loading: boolean;

  created_at: string;
  updated_at: string;
}

export interface IPriceListRecord {
  id: number;
  org_id: number;
  name: string;
  kind: string;
  hidden_products: boolean;
  product_ids: List<number>;
  product_option_group_ids: List<number>;
  loading: boolean;

  created_at: Date;
  updated_at: Date;
}

export const defaultPriceListProps = {
  id: 0,
  org_id: 0,
  name: "",
  kind: "default",
  hidden_products: false,
  product_ids: List<number>(),
  product_option_group_ids: List<number>(),
  loading: false,

  created_at: new Date(),
  updated_at: new Date(),
};

export class PriceListRecord extends Record<IPriceListRecord>(defaultPriceListProps) implements IPriceListRecord {
  public id!: number;
  public org_id!: number;
  public name!: string;
  public kind!: string;
  public hidden_products!: boolean;
  public product_ids!: List<number>;
  public product_option_group_ids!: List<number>;
  public loading!: boolean;

  public created_at!: Date;
  public updated_at!: Date;

  public constructor(values?: Partial<IPriceListRecord>) {
    values ? super(values) : super();
  }
}
