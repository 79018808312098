import { Record } from "immutable";
import { Nullable } from ".";
import { CommissionMethod } from "./Estimate";

export const fromJSON = (data: Partial<IEstimateCommissionDetailsData>): EstimateCommissionDetailsRecord => {
  const record: IEstimateCommissionDetailsRecord = { ...(data as any) };
  return new EstimateCommissionDetailsRecord(record);
};

export interface IEstimateCommissionDetailsData {
  method: CommissionMethod;
  overhead: number;
  formula: string;
  greenline_percentage: number;
}

export interface IEstimateCommissionDetailsRecord {
  method: Nullable<CommissionMethod>;
  overhead: number;
  formula: string;
  greenline_percentage: number;
}

const defaultEstimateCommissionDetailsProps: IEstimateCommissionDetailsRecord = {
  method: null,
  overhead: 0,
  formula: "",
  greenline_percentage: 0,
};

export class EstimateCommissionDetailsRecord
  extends Record(defaultEstimateCommissionDetailsProps)
  implements IEstimateCommissionDetailsRecord {}
