import * as React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { RootState } from "app2/src/reducers";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { RootDispatchType } from "app2/src/store";
import { ILocationData } from "app2/src/records/Location";
import { push } from "app2/src/reducers/router.actions";
import { connect, ConnectedProps } from "app2/src/connect";

const mapStateToProps = (state: RootState) => {
  return {
    jobId: currentJobId(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    push: (location: Partial<ILocationData>) => dispatch(push(location)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface SaveToDocumentsModalProps {
  save: any;
  contractName: string;
  estimateName: string;
  closeModal: any;
}

interface SaveToDocumentsModalState {
  contractChecked: boolean;
  estimateChecked: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & SaveToDocumentsModalProps;

class SaveToDocumentsModal extends React.Component<Props, SaveToDocumentsModalState> {
  constructor(props) {
    super(props);
    this.state = {
      contractChecked: false,
      estimateChecked: false,
    };

    this.save = this.save.bind(this);
  }

  public async save() {
    const { save, jobId, push } = this.props;
    const { contractChecked, estimateChecked } = this.state;
    await save(contractChecked, estimateChecked);
    push({ pathname: "job_header.job_show.documents", query: { id: jobId, document_id: undefined } });
  }

  public render() {
    const { contractName, estimateName, closeModal } = this.props;
    const { contractChecked, estimateChecked } = this.state;

    return (
      <React.Fragment>
        <Modal.Header closeButton={true}>
          <Row>
            <Col title="Heading" sm={12}>
              Save PDF
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Check
                title="Docs Check"
                type="checkbox"
                inline
                label="Save To Documents"
                checked
                readOnly></Form.Check>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Check
                title="Contract Check"
                type="checkbox"
                inline
                label={`Add To ${contractName}`}
                checked={contractChecked}
                onChange={() => this.setState({ contractChecked: !contractChecked })}></Form.Check>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Check
                title="Estimate Check"
                type="checkbox"
                inline
                label={`Add To ${estimateName}`}
                checked={estimateChecked}
                onChange={() => this.setState({ estimateChecked: !estimateChecked })}></Form.Check>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button title="Save" variant="save" onClick={this.save}>
            Save
          </Button>
          <Button title="Cancel" variant="cancel" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

export default connector(SaveToDocumentsModal);
