import { Record } from "immutable";

export const fromJSON = (json: Partial<INoteData>): NoteRecord => {
  const noteData: INoteRecord = { ...(json as any) };

  return new NoteRecord(noteData);
};

export const toJSON = (record: NoteRecord): INoteData => {
  return record.toJS();
};

export interface INoteData {
  id: number;
  note: string;
  user: string;
  user_id: number;
  noteable_id: number;
  noteable_type: string;
  loading?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface INoteRecord {
  id: number;
  note: string;
  user: string;
  user_id: number;
  noteable_id: number;
  noteable_type: string;
  loading: boolean;

  created_at: Date;
  updated_at: Date;
}

export const defaultNoteProps: INoteRecord = {
  id: 0,
  note: "",
  user: "",
  user_id: 0,
  noteable_id: 0,
  noteable_type: "",
  loading: true,

  created_at: new Date(),
  updated_at: new Date(),
};

export class NoteRecord extends Record(defaultNoteProps) implements INoteRecord {}
