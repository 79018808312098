import * as estimateLineItemActions from "./estimateLineItem.actions";
import { Map, Record, List } from "immutable";
import { EstimateLineItemRecord, fromJSON } from "../records/EstimateLineItem";
import { RootState, RootActions } from ".";
import * as estimateLineItemOptionActions from "./estimateLineItemOption.actions";
import { reducer as estimateLineItemOptionReducer } from "./estimateLineItemOption.reducer";
import * as openingEstimationActions from "./openingEstimation.actions";
import { reducer as openingEstimationReducer } from "./openingEstimation.reducer";
import * as documentActions from "./document.actions";
import { reducer as documentReducer } from "./document.reducer";
import * as imageActions from "./image.actions";
import { reducer as imageReducer } from "./image.reducer";

export const EstimateLineItemStateRecord = Record({
  byId: Map<number, EstimateLineItemRecord>(),
  lastSavedById: Map<number, EstimateLineItemRecord>(),
  unsavedId: -1,
});

export const initialState = EstimateLineItemStateRecord();

export type EstimateState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "estimateLineItems";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let estimateLineItem: EstimateLineItemRecord;
  switch (action.type) {
    case estimateLineItemActions.RECEIVE_ESTIMATE_LINE_ITEM:
      action.payload.estimateLineItem.options.map((li) => {
        state = estimateLineItemOptionReducer(
          state,
          estimateLineItemOptionActions.Actions.receiveEstimateLineItemOption(li),
        );
      });

      action.payload.estimateLineItem.opening_estimations.map((oe) => {
        state = openingEstimationReducer(state, openingEstimationActions.Actions.receiveOpeningEstimation(oe));
      });

      action.payload.estimateLineItem.documents.map((doc) => {
        state = documentReducer(state, documentActions.Actions.receiveDocument(doc));
      });

      action.payload.estimateLineItem.images.map((img) => {
        state = imageReducer(state, imageActions.Actions.receiveImage(img));
      });

      estimateLineItem = fromJSON(action.payload.estimateLineItem);

      return state
        .setIn([model, "byId", estimateLineItem.id], estimateLineItem)
        .setIn([model, "lastSavedById", estimateLineItem.id], estimateLineItem);

    case estimateLineItemActions.DUPLICATE_ESTIMATE_LINE_ITEMS:
      const group = state.getIn(["estimateGroups", "byId", action.payload.groupId]);

      let lineItemIds = List();
      let unsavedId = state.getIn([model, "unsavedId"]);
      action.payload.lineItemIds.map((lid: number) => {
        let lineItem = state.getIn([model, "byId", lid]);
        lineItem = lineItem.set("id", unsavedId).set("estimate_group_id", group.id);
        lineItemIds = lineItemIds.push(unsavedId);
        state = state.setIn([model, "byId", lineItem.id], lineItem);

        state = estimateLineItemOptionReducer(
          state,
          estimateLineItemOptionActions.Actions.duplicateEstimateLineItemOptions(lineItem.id, lineItem.option_ids),
        );

        state = openingEstimationReducer(
          state,
          openingEstimationActions.Actions.duplicateOpeningEstimations(lineItem.id, lineItem.opening_estimation_ids),
        );

        state = documentReducer(
          state,
          documentActions.Actions.duplicateLineItemDocuments(lineItem.id, lineItem.document_ids),
        );

        state = imageReducer(state, imageActions.Actions.duplicateLineItemImages(lineItem.id, lineItem.image_ids));

        unsavedId -= 1;
      });

      return state
        .setIn(["estimateGroups", "byId", group.id, "line_item_ids"], lineItemIds)
        .setIn([model, "unsavedId"], unsavedId);

    case estimateLineItemActions.PUSH_DOCUMENT_ID:
      const { estimateLineItemId, documentId } = action.payload;
      let ids = state.getIn([model, "byId", estimateLineItemId, "document_ids"], List());
      if (ids.indexOf(documentId) < 0) {
        ids = ids.push(documentId);
      }
      return state.setIn([model, "byId", estimateLineItemId, "document_ids"], ids);
    default:
      return state;
  }
};
