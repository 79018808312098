import { Nullable } from "app2/src/records";
import * as React from "react";
import { Row, Col, Card, Accordion } from "react-bootstrap";

export interface UnhandledExceptionProps {
  error: Nullable<Error>;
  errorInfo: Nullable<React.ErrorInfo>;
}

export const UnhandledException: React.FunctionComponent<UnhandledExceptionProps> = ({ error, errorInfo }) => {
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <h2 className="mb-0">Something went wrong!</h2>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs={12}>
                <p>
                  Something went wrong in the processing of your requests. Please refresh the page and try again. If
                  this problem persists contact support.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Accordion>
                  <Card style={{ borderBottom: "1px" }}>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h4 className="mb-0">More Detail</h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <Row>
                          <Col xs={12}>
                            <p>
                              {error?.name}: {error?.message}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <h4>Component Stacktrace: </h4>
                            <p className="description">{errorInfo?.componentStack}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <h4>Stacktrace: </h4>
                            <p className="description">{error?.stack}</p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
