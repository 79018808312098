import { Record, List, fromJS, Map } from "immutable";
import { Nullable } from ".";
import { IMeasurementMetadata } from "rsf-visualizer";
import { IImageData, ImageRecord } from "./Image";
import { IAnnotationData, AnnotationRecord } from "./Annotation";
import { IWallFacadeData, WallFacadeRecord } from "./WallFacade";
import { IMeasurement } from "app/src/Models/Measurement";
import { IElevation } from "app/src/Models/Elevation";

export const fromJSON = (json: Partial<IElevationData>): ElevationRecord => {
  const recordData: IElevationRecord = { ...(json as any) };

  recordData.annotations = fromJS(json.annotations);

  return new ElevationRecord(recordData);
};

export const unWindAngular = (elevation: IElevation): IElevationData => {
  const data = { ...elevation } as any;

  data.annotations = {
    list: _.map(data.annotations, (a: any) => {
      delete a.elevation;
      return a;
    }),
    wallFacades: data.wallFacades,
    measuring: data.drawingMetadata,
  };
  return data;
};

export interface IElevationData {
  id: number;
  name: string;
  abbr: string;
  src_image_id: number;
  src_image: IImageData;
  rendered_image_id: number;
  rendered_image: IImageData;
  annotations: { list: IAnnotationData[]; wallFacades: IWallFacadeData[]; measuring: IMeasurementMetadata };
  removedAnnotations: IAnnotationData[];
  removedWallFacades: IWallFacadeData[];
  measurement: IMeasurement;
  measurement_id: number;
  $hasChanged: boolean;
  renderedDataUrl?: string;
}

export interface IElevationRecord {
  id: number;
  name: string;
  abbr: string;
  src_image_id: number;
  src_image: ImageRecord;
  rendered_image_id: number;
  rendered_image: ImageRecord;
  annotations: Map<string, any>;
  removedAnnotations: List<AnnotationRecord>;
  removedWallFacades: List<WallFacadeRecord>;
  measurement: Nullable<IMeasurement>;
  measurement_id: number;
  $hasChanged: boolean;
  renderedDataUrl: Nullable<string>;
}

const defaultElevationProps: IElevationRecord = {
  id: 0,
  name: "",
  abbr: "",
  src_image_id: 0,
  src_image: new ImageRecord(),
  rendered_image_id: 0,
  rendered_image: new ImageRecord(),
  annotations: Map(),
  removedAnnotations: List<AnnotationRecord>(),
  removedWallFacades: List<WallFacadeRecord>(),
  measurement: null,
  measurement_id: 0,
  $hasChanged: false,
  renderedDataUrl: null,
};

export class ElevationRecord extends Record(defaultElevationProps) implements IElevationRecord {}
