import { ISession, Actions } from "app/src/Common/SessionService";

export class RsfPermissions implements ng.IDirective {
  public restrict = "A";
  public scope = {
    permissions: "=",
    onObject: "=",
  };

  constructor(
    private Session: ISession,
    private $q: ng.IQService,
  ) {}

  public link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
    const permissionsOns: string[] = _.map(attrs["permissionsOn"].split(","), (v: string) => v.trim());
    const permissions: string[] = _.map(attrs["rsfPermissions"].split(","), (v: string) => {
      v = v.trim();
      if (Actions[v]) {
        return Actions[v];
      }

      return v;
    });
    // let permissions: string[] = _.map((Actions[attrs["rsfPermissions"]] || attrs["rsfPermissions"]).split(","), (v: string) => v.trim());

    const that = this;
    element.hide();

    let inverse = false;
    if ("inverse" in attrs) {
      inverse = true;
    }

    const startWatch = () => {
      scope.$watch(
        () => this.Session.currentUser.permissions,
        function () {
          const promises: ng.IPromise<boolean>[] = <ng.IPromise<boolean>[]>[];

          _.each(permissions, (permission: string, idx: number) => {
            // @ts-ignore
            promises.push(that.Session.can(permission, permissionsOns[idx], scope["onObject"]));
          });

          that.$q.all(promises).then((values: boolean[]) => {
            if (_.all(values, (v: boolean) => v)) {
              if (inverse) {
                element.hide();
              } else {
                element.show();
              }
            } else {
              if (inverse) {
                element.show();
              } else {
                element.hide();
              }
            }
          });
        },
      );
    };

    const userWatch = scope.$watch(
      () => this.Session.currentUser,
      (newValue, oldValue) => {
        if (newValue === null || newValue === undefined) {
          return;
        }
        userWatch();
        if (this.Session.currentUser.$promise) {
          this.Session.currentUser.$promise.then(() => {
            //@ts-ignore
            if (this.Session.currentUser.$promise.$$state === undefined) {
              scope.$apply(() => {
                startWatch();
              });
            } else {
              startWatch();
            }
          });
        } else {
          startWatch();
        }
      },
    );
  };

  public static factory(): ng.IDirectiveFactory {
    const directive = (Session: ISession, $q: ng.IQService) => new RsfPermissions(Session, $q);
    directive.$inject = ["Session", "$q"];
    return directive;
  }
}
