import * as webhookActions from "./webhook.actions";
import * as paginationActions from "./pagination.actions";
import { Record, Map, getIn } from "immutable";
import { fromJSON, IWebhookData, WebhookRecord } from "../records/Webhook";
import { fromJSON as eventFromJSON, IWebhookEventData, WebhookEventRecord } from "../records/WebhookEvent";
import { RootState, RootActions } from "app2/src/reducers";
import { reducer as paginationReducer } from "app2/src/reducers/pagination.reducer";
import { reset } from "./Reducer";

export const WebhookStateRecord = Record({
  byId: Map<string, WebhookRecord>(),
  eventsById: Map<string, WebhookEventRecord>(),
  lastSavedById: Map<string, WebhookRecord>(),
});

export const initialState = WebhookStateRecord();

export type WebhookState = typeof initialState;

export const model = "webhooks";
export const eventsModel = "webhook_events";
export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case webhookActions.RECEIVE_WEBHOOK:
      const webhookRecord = fromJSON(action.payload);
      return state
        .setIn([model, "byId", action.payload.id], webhookRecord)
        .setIn([model, "lastSavedById", action.payload.id], webhookRecord);

    case webhookActions.RECEIVE_WEBHOOK_EVENT:
      const webhookEventRecord = eventFromJSON(action.payload);
      return state.setIn([model, "eventsById", action.payload.id], webhookEventRecord);

    case webhookActions.RESET_WEBHOOK: {
      const { webhookId } = action.payload;
      return reset(state, model, webhookId);
    }

    case webhookActions.SET_WEBHOOK_LOADED:
      if (getIn(state, [model, "byId", action.payload, "loading"], false)) {
        return state.setIn([model, "byId", action.payload, "loading"], false);
      }
      return state;

    case webhookActions.FETCH_WEBHOOK:
      if (state.getIn([model, "byId", action.payload])) {
        return state.setIn([model, "byId", action.payload, "loading"], true);
      }
      return state.setIn([model, "byId", action.payload], new WebhookRecord({ id: action.payload, loading: true }));

    case webhookActions.RECEIVE_WEBHOOKS:
      action.payload.webhooks.forEach((webhook: IWebhookData) => {
        state = reducer(state, webhookActions.Actions.receiveWebhook(webhook));
      });
      return state;

    case webhookActions.RECEIVE_WEBHOOK_EVENTS:
      action.payload.webhook_events.forEach((webhook: IWebhookEventData) => {
        state = reducer(state, webhookActions.Actions.receiveWebhookEvent(webhook));
      });
      return state;

    case webhookActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);

    case webhookActions.REMOVE_WEBHOOK:
      state = paginationReducer(state, paginationActions.Actions.removeId(action.payload.webhookId, "webhook"));
      return state.deleteIn(["webhooks", "byId", action.payload.webhookId]);
    default:
      return state;
  }
};
