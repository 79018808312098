import { IAccountData } from "app2/src/records/billing/Account";
import { handleErrors } from "app2/src/reducers/Utils";
import * as accountService from "app2/src/api/billing/account.service";
import { ThunkResult } from "../index";
import { ActionsUnion, createAction } from "../Utils";
import { RootDispatchType } from "app2/src/store";

export const FETCH_ACCOUNT = "@accounts/FETCH_ACCOUNT";
export const RECEIVE_ACCOUNT = "@accounts/RECEIVE_ACCOUNT";
export const RECEIVE_ACCOUNT_ERROR = "@accounts/RECEIVE_ACCOUNT_ERROR";

export const Actions = {
  fetchAccount: (id: number) => createAction(FETCH_ACCOUNT, { id }),
  receiveAccount: (account: IAccountData) => createAction(RECEIVE_ACCOUNT, { account }),
  receiveAccountError: (id: number, errors: string[]) => createAction(RECEIVE_ACCOUNT_ERROR, { id, errors }),
};

export const AsyncActions = {
  load: (id: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchAccount(id));

      try {
        const account = await accountService.load(id);
        dispatch(Actions.receiveAccount(account.account));
      } catch (e) {
        dispatch(Actions.receiveAccountError(id, handleErrors(e)));
      }
    };
  },
  subscribeToPlan: (
    accountId: number,
    planId: number,
    params: Partial<accountService.AccountPlanSubscriptionParams> = {},
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchAccount(accountId));

      try {
        const account = await accountService.subscribeToPlan(accountId, planId, params);
        dispatch(Actions.receiveAccount(account.account));
      } catch (e) {
        dispatch(Actions.receiveAccountError(accountId, handleErrors(e)));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
