import { SnakeToCamelKeys } from "app2/src/helpers/Format";
import { Nullable } from "app2/src/records";
import {
  ILineItemOrderData,
  ILineItemOrderRecord,
  fromJSON as lineItemOrderFromJSON,
} from "app2/src/records/LineItemOrder";
import {
  ICustomProductData,
  CustomProductRecord,
  fromJSON as customProductFromJSON,
} from "app2/src/records/CustomProduct";
import { Record, List, Map } from "immutable";

export const fromJSON = (data: Partial<IEstimateTemplateGroupData>): EstimateTemplateGroupRecord => {
  const record: IEstimateTemplateGroupRecord = SnakeToCamelKeys(data);

  if (data.items) {
    record.items = List(data.items);
  }

  if (data.line_item_order) {
    record.lineItemOrder = List(data.line_item_order.map((group) => lineItemOrderFromJSON(group)));
  }

  if (record.itemUuids) {
    record.itemUuids = Map(data.item_uuids);
    record.itemUuids.map((value, key) => {
      record.itemUuids = record.itemUuids.set(key, List(value));
    });
  }

  if (record.customProducts) {
    record.customProducts = List(data.custom_products).map((product) => customProductFromJSON(product));
  }

  return new EstimateTemplateGroupRecord(record);
};

export interface IEstimateTemplateGroupData {
  name: string;
  included: boolean;
  sort_order: number;
  items: string[];
  item_uuids: {};
  line_item_order: ILineItemOrderData[];
  custom_products: ICustomProductData[];
}

export interface IEstimateTemplateGroupRecord {
  name: string;
  included: boolean;
  sortOrder: Nullable<number>;
  items: List<string>;
  itemUuids: Map<string, List<string>>;
  lineItemOrder: List<ILineItemOrderRecord>;
  customProducts: List<CustomProductRecord>;
}

const defaultEstimateTemplateGroupProps: IEstimateTemplateGroupRecord = {
  name: "",
  included: false,
  sortOrder: null,
  items: List(),
  itemUuids: Map(),
  lineItemOrder: List(),
  customProducts: List(),
};

export class EstimateTemplateGroupRecord
  extends Record(defaultEstimateTemplateGroupProps)
  implements IEstimateTemplateGroupRecord {}
