import * as React from "react";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import Files from "react-files";
import * as jobActions from "app2/src/reducers/job.actions";
import * as config from "react-global-configuration";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { ITaskData } from "app2/src/records/Task";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";
import { Actions as CommonActions } from "app2/src/reducers/components/common.actions";
import { RootDispatchType } from "app2/src/store";

const mapStateToProps = (state: RootState) => {
  return {
    orgId: state.getIn(["users", "currentUser", "org_id"]),
    getTask: (taskId: string) => state.getIn(["tasks", "byId", taskId]),
    showImportModal: state.getIn(["components", "common", "showImportModal"]),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    importJobs: (orgId: number, csvText: string) => {
      return dispatch(jobActions.AsyncActions.importJobs(orgId, csvText));
    },
    closeImportModal: () => dispatch(CommonActions.closeImportModal()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImportCsvProps {
  refreshQuery: any;
  tracking?: TrackingProp;
}

interface ImportCsvState {
  csvText: string;
  fileName: string;
  taskId: string;
  updatedJobList: boolean;
  showInvalidCSVError: boolean;
  invalidCSVReason: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImportCsvProps;

const track: Track<TrackingData, ImportCsvProps> = _track;

@track(
  (props: ImportCsvProps) => {
    return {
      category: "ImportCsv Modal",
      action: "show",
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: true,
  },
)
class ImportCsv extends React.Component<Props, ImportCsvState> {
  constructor(props: Props) {
    super(props);

    this.importCsv = this.importCsv.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onFilesChanged = this.onFilesChanged.bind(this);
    this.updateJobList = this.updateJobList.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.cleanState = this.cleanState.bind(this);

    this.state = {
      csvText: "",
      fileName: "",
      taskId: "",
      updatedJobList: false,
      showInvalidCSVError: false,
      invalidCSVReason: "",
    };
  }

  public cleanState() {
    this.setState({
      csvText: "",
      fileName: "",
      taskId: "",
      updatedJobList: false,
      showInvalidCSVError: false,
      invalidCSVReason: "",
    });
  }

  public componentDidUpdate(prevProps: ImportCsvProps) {
    const { getTask, tracking } = this.props;
    const { updatedJobList, taskId } = this.state;
    const task = getTask(taskId);
    if (task && task.status === "finished" && updatedJobList === false) {
      this.updateJobList();
      this.setState({ updatedJobList: true });
      tracking.trackEvent({
        action: "Successfully Imported CSV",
      });
    } else if (task && task.status === "error") {
      tracking.trackEvent({
        action: "Error Importing CSV",
      });
    }
  }

  public updateJobList() {
    const { refreshQuery } = this.props;
    refreshQuery();
  }

  public closeModal() {
    const { closeImportModal } = this.props;
    closeImportModal();
    this.cleanState();
  }

  public trackSupportUrl() {
    const { tracking } = this.props;
    tracking.trackEvent({
      action: "Clicked Support URL",
    });
  }

  public trackSampleFileUrl() {
    const { tracking } = this.props;
    tracking.trackEvent({
      action: "Clicked Sample File URL",
    });
  }

  @track({ action: "CSV Import Started" })
  public async importCsv() {
    this.setState({
      showInvalidCSVError: false,
      invalidCSVReason: "",
    });
    const { csvText } = this.state;
    const { orgId, importJobs } = this.props;
    try {
      const taskId = await importJobs(orgId, csvText);
      this.setState({ taskId });
    } catch (e) {
      console.error(e);
      this.setState({
        showInvalidCSVError: true,
        invalidCSVReason: e.data.error,
      });
    }
  }

  @track({ action: "CSV File Uploaded" })
  public async onFilesChanged(files: any) {
    const file = files[0];
    const csvText = await file.text();
    this.setState({ csvText, fileName: file.name });
  }

  public closeAlert() {
    this.setState({
      showInvalidCSVError: false,
      invalidCSVReason: "",
    });
  }

  public render() {
    const { csvText, fileName, taskId, showInvalidCSVError, invalidCSVReason } = this.state;
    const { getTask, showImportModal } = this.props;
    const task = getTask(taskId);
    let logItems;
    if (task && task.logs) {
      logItems = task.logs.split("<br>").map((log: string, idx: number) => {
        return (
          <div key={idx}>
            {log}
            <br />
          </div>
        );
      });
    }
    return (
      <Modal show={showImportModal}>
        <Modal.Header>
          <Modal.Title>Import CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              To bulk import jobs from a CSV, please upload a file below.
              <br></br>
              <a href={config.get("SAMPLE_JOB_CSV_FILE_URL")} target="_blank" onClick={this.trackSampleFileUrl}>
                <u>Here is a sample import file</u>
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Files accepts={[".csv"]} maxFileSize={1000000} onChange={this.onFilesChanged} multiple={false} clickable>
                <Button variant="default">Select CSV</Button>
              </Files>
            </Col>
            <Col md={8}>
              <h5>Uploaded: {fileName !== "" ? fileName : "No File Uploaded"}</h5>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col md={6}>
              Need help?
              <br></br>
              <a href={config.get("SUPPORT_URL")} target="_blank" onClick={this.trackSupportUrl}>
                <u>Click here for support.</u>
              </a>
            </Col>
          </Row>
          <Row>
            <Alert show={showInvalidCSVError} variant="delete" dismissible onClose={this.closeAlert}>
              <br></br>
              {invalidCSVReason}
            </Alert>
          </Row>
          {taskId !== "" && task !== undefined && (
            <>
              <Row>
                <Col>
                  <h5>Status: {task.status}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>Results: {task.results[task.status] || ""}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>
                    Log:
                    <pre>{logItems}</pre>
                  </h5>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeModal} variant="cancel">
            Close
          </Button>
          <Button disabled={csvText === ""} onClick={this.importCsv} variant="default">
            Import
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connector(ImportCsv);
