import * as React from "react";
import { LocalVideoTrack } from "twilio-video";
import { Row, Col, Button } from "react-bootstrap";
import useRoomState from "app2/src/components/Twilio/hooks/useRoomState/useRoomState";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import VideoTrack from "app2/src/components/Twilio/components/VideoTrack";
import ToggleVideoButton from "app2/src/components/Twilio/components/ToggleVideoButton";
import ToggleAudioButton from "app2/src/components/Twilio/components/ToggleAudioButton";
import Header from "./Header";
import { DeviceSelector } from "../../DeviceSelector/DeviceSelector";
import useHeight from "app2/src/hooks/useHeight/useHeight";
import { useTracking } from "react-tracking";
import SpinnerComponent from "../../SpinnerComponent";

const LocalVideoPreview = (props) => {
  const { localTracks, isConnecting, connect, roomParams, joinRoom, isVideoTrackLoading } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  const [height, calculated] = useHeight();

  const videoTrack = localTracks.find((track) => track.name && track.name.includes("camera")) as LocalVideoTrack;
  const { trackEvent } = useTracking();

  const join = async () => {
    try {
      const token = await joinRoom();
      const action = roomParams.roomExists ? "screenshare viewer connected" : "screenshare started";
      connect(token);
      trackEvent({ action });
    } catch {
      // do nothing
    }
  };

  let joinButtonMessage = "Join Now";
  let titleMessage = `Join ${roomParams.roomName}`;
  if (roomParams.host) {
    if (roomParams.roomExists) {
      joinButtonMessage = "Join Existing";
      titleMessage = `Join Existing Room - ${roomParams.roomName}`;
    } else {
      joinButtonMessage = "Start Now";
      titleMessage = `Start New Room - ${roomParams.roomName}`;
    }
  }

  const videoHeight = calculated - 200 + "px";

  return (
    <div className="join-container" style={{ height }}>
      <Header showInvite={roomParams.host} />
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <div className="join-controls">
            <h3>{titleMessage}</h3>
            <DeviceSelector />
            {roomParams.audio_preference !== "disabled" && <ToggleAudioButton disabled={isReconnecting} />}
            <ToggleVideoButton disabled={isReconnecting} />
            <Button
              variant={roomParams.roomExists ? "danger" : "success"}
              onClick={join}
              disabled={isConnecting || !roomParams.identity || !roomParams.roomUid}>
              {joinButtonMessage}
            </Button>
          </div>
          <div style={{ height: videoHeight }}>
            {videoTrack ? (
              <VideoTrack track={videoTrack} isLocal />
            ) : isVideoTrackLoading ? (
              <SpinnerComponent localProperty />
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LocalVideoPreview;
