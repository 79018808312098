import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IValidationData>): ValidationRecord => {
  const data: IValidationRecord = { ...(json as any) };

  return new ValidationRecord(data);
};

export interface IValidationData {
  id: number;
  name: string;
  item_uuid: string;
  definition: any;
  org_id: number;
  loading?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface IValidationRecord {
  id: number;
  name: string;
  item_uuid: string;
  definition: any;
  org_id: number;
  loading: boolean;

  created_at: Date;
  updated_at: Date;
}

const defaultValidationProps: IValidationRecord = {
  id: 0,
  name: "",
  item_uuid: "",
  definition: {},
  org_id: null,
  loading: false,
  created_at: new Date(),
  updated_at: new Date(),
};

export class ValidationRecord extends Record(defaultValidationProps) implements IValidationRecord {
  public readonly id!: number;
  public readonly name!: string;
  public readonly item_uuid!: string;
  public readonly definition!: any;
  public readonly org_id!: Nullable<number>;
  public readonly loading!: boolean;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IValidationRecord>) {
    values ? super(values) : super();
  }
}
