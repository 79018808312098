import { IEstimate } from "app/src/Models/Estimate";
import * as React from "react";
import DrawerContainer from "./DrawerContainer";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { EstimateComparisonRecord } from "app2/src/records/EstimateComparison";
import { ThunkDispatch } from "redux-thunk";
import * as estimateComparisonActions from "app2/src/reducers/estimateComparison.actions";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import { JobRecord } from "app2/src/records/Job";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import {
  estimateComparison,
  expandDrawer,
  estimateComparisonChange,
} from "app2/src/selectors/estimateComparison.selectors";
import { Dispatch } from "app2/src/helpers/Analytics";
import track from "react-tracking";

const mapStateToProps = (state: RootState, ownProps: IDrawerProps) => {
  return {
    estimateComparison: estimateComparison(state, { jobId: ownProps.jobId }),
    expandDrawer: expandDrawer(state),
    dirty: estimateComparisonChange(state, { jobId: ownProps.jobId }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>) => {
  return {
    viewEstimateComparison: (jobId: number) =>
      dispatch(estimateComparisonActions.Actions.viewEstimateComparison(jobId)),
    showDrawer: () => dispatch(estimateComparisonActions.Actions.showDrawer()),
    hideDrawer: () => dispatch(estimateComparisonActions.Actions.hideDrawer()),
    buildEstimateComparison: (jobId: number, estimateId: number) =>
      dispatch(estimateComparisonActions.Actions.buildEstimateComparison(jobId, [estimateId])),
    receiveEstimateComparison: (estimate_comparison: EstimateComparisonRecord) =>
      dispatch(estimateComparisonActions.Actions.receiveEstimateComparison(estimate_comparison)),
    saveEstimateComparison: (estimateComparison) =>
      dispatch(estimateComparisonActions.AsyncActions.saveEstimateComparison(estimateComparison)),
    getJob: (id: number) => dispatch(jobActions.AsyncActions.getJob(id)),
    getJobEstimates: (jobId: number) => dispatch(estimateActions.AsyncActions.getJobEstimates(jobId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IDrawerProps {
  orgId: number;
  jobId: number;
  openDrawer: number;
  currentEstimate: IEstimate;
  $state: ng.ui.IStateService;
  EstimatorService: EstimatorService;
  ConfirmDialog: IConfirmDialog;
  saveEditorEstimate: () => Promise<void>;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IDrawerProps;

@track(
  (props) => {
    return {
      category: "Price Comparison",
      component: "Price Drawer Wrapper",
      action: "show",
      estimate: props.currentEstimate.id,
      job: props.jobId,
      org: props.orgId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: (cd) => ({ action: "shown" }),
  },
)
class PriceDrawer extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.goToEstimate = this.goToEstimate.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.drawerToggle = this.drawerToggle.bind(this);
    this.saveComparison = this.saveComparison.bind(this);
  }

  componentDidMount() {
    const { jobId, buildEstimateComparison, receiveEstimateComparison, currentEstimate, getJobEstimates, getJob } =
      this.props;

    getJobEstimates(jobId);
    getJob(jobId).then((job) => {
      if (job.estimate_comparison.id !== 0) {
        receiveEstimateComparison(job.estimate_comparison);
      } else {
        if (currentEstimate) {
          currentEstimate.$promise.then(() => {
            buildEstimateComparison(jobId, currentEstimate.id);
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { openDrawer } = this.props;

    if (openDrawer !== prevProps.openDrawer) {
      this.openDrawer();
    }
  }

  @track({ action: "Open Drawer" })
  public openDrawer() {
    const { jobId, viewEstimateComparison, showDrawer, EstimatorService, ConfirmDialog } = this.props;
    if (EstimatorService.checkEstimateState()) {
      ConfirmDialog.confirm("There are unsaved changes that will be lost.  Are you sure you want to continue?").then(
        () => {
          viewEstimateComparison(jobId);
          showDrawer();
        },
      );
    } else {
      viewEstimateComparison(jobId);
      showDrawer();
    }
  }

  @track({ action: "Close Drawer" })
  public closeDrawer() {
    const { hideDrawer, dirty, ConfirmDialog, currentEstimate, EstimatorService } = this.props;
    if (dirty) {
      ConfirmDialog.confirm("There are unsaved changes that will be lost.  Are you sure you want to continue?").then(
        () => {
          EstimatorService.loadEstimate(currentEstimate.id);
          hideDrawer();
        },
      );
    } else {
      EstimatorService.loadEstimate(currentEstimate.id);
      hideDrawer();
    }
  }

  @track({ action: "Go To Estimate" })
  public goToEstimate(estimateId: number): void {
    const { jobId, $state, hideDrawer, ConfirmDialog, dirty, EstimatorService } = this.props;

    if (estimateId > 0 && !dirty) {
      EstimatorService.loadEstimate(estimateId);
      hideDrawer();
      $state.go("job_header.job_show.estimate", { id: jobId, estimate_id: estimateId });
    } else {
      ConfirmDialog.confirm("There are unsaved changes that could be lost. Would you like to save changes?").then(
        () => {
          this.saveAndHideDrawer(estimateId, () =>
            $state.go("job_header.job_show.estimate", { id: jobId, estimate_id: estimateId }),
          );
        },
      );
    }
  }

  @track({ action: "Save and Hide Drawer" })
  public saveAndHideDrawer(estimateId: number, go = () => {}) {
    const { hideDrawer, EstimatorService } = this.props;
    this.saveComparison().then(() => {
      if (estimateId > 0) {
        EstimatorService.loadEstimate(estimateId);
        hideDrawer();
        go();
      }
    });
  }

  public drawerToggle() {
    const { expandDrawer } = this.props;
    if (expandDrawer) {
      this.closeDrawer();
    } else {
      this.openDrawer();
    }
  }

  @track({ action: "Save Comparison" })
  public async saveComparison(e: any = null) {
    const { estimateComparison, saveEstimateComparison } = this.props;
    if (e) {
      e.stopPropagation();
    }
    await saveEstimateComparison(estimateComparison);
  }

  public render() {
    const { estimateComparison, orgId, jobId, dirty } = this.props;

    if (estimateComparison && estimateComparison.show) {
      return (
        <DrawerContainer
          orgId={orgId}
          jobId={jobId}
          goToEstimate={this.goToEstimate}
          drawerToggle={this.drawerToggle}
          saveComparison={this.saveComparison}
          dirty={dirty}
        />
      );
    } else {
      return null;
    }
  }
}

export default connector(PriceDrawer);
