import { ISession } from "../SessionService";
import { FlashLevels, IFlash } from "../FlashService";
import { IJob } from "../../Models/Job";
import { ISection } from "../../JobMenu/JobMenuOrderService";
import { UserPrefConfigType } from "../../Models/UserPreference";
import { IBaseConfig } from "../IBaseConfig";

export class JobShowTourService {
  public tour: any;
  public props: any = {};
  public job: IJob;
  public tourData: any = {};
  protected _prevStep: any;

  public static $inject = ["uiTourService", "Session", "$sce", "Flash", "BaseConfig"];

  constructor(
    public uiTourService: any,
    private Session: ISession,
    public $sce: ng.ISCEService,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
  ) {}

  public init(props: any): ng.IPromise<any> {
    return this.Session.fetchUserPref(UserPrefConfigType.job_show_tour).then((show_tour) => {
      if (show_tour) {
        this.props = props || {};
        this.tour = this.uiTourService.getTourByName("job-show-tour");
        this.tour.do_not_show = true;
        return this.props.jobShowCtrl.$timeout().then(() => {
          if (this.tour._getSteps().length <= 0) {
            this.createTour();
          }
        });
      }
    });
  }

  public createTour() {
    let menuItems = [];
    this.tourData = {
      information: {
        title: "Information",
        content:
          "Welcome to a Job.<br/><br/><span class='tour-bold'>Click next</span> to get a walk through on all of the tabs available at the left.<br/><br/>Please don't hesitate to contact the <span class='tour-underline'>" +
          this.BaseConfig.ONBOARDING_NAME +
          "</span> with any questions you might have.</br></br> Email: <a href='mailto:" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "</span></a></br> Phone: <a href='" +
          this.BaseConfig.ONBOARDING_PHONE_DIAL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_PHONE +
          "</span></a><br/><br/>",
      },
      appointments: {
        title: "Appointments",
        content:
          "The appointments section allows you to create and edit appointments for the given job. These appointments have the ability to <span class='tour bold'>send a screen share invite</span> which you can use to connect directly with your customer remotely.<br/><br/>You can also link up your personal calendar to make sure you don't double book yourself.<br/><br/>",
      },
      tools: {
        title: "Tools",
        content:
          "The tools section allows you to access all of the powerful tools " +
          this.BaseConfig.APP_NAME +
          " has to offer.<br/><br/>",
      },
      images: {
        title: "Photos",
        content:
          "Upload photos of the property from your computer or device. You can also send an upload invitation (via text or email) for your customer to upload their own photos.<br/><br/>",
      },
      documents: {
        title: "Documents",
        content:
          "Upload documents (pdfs, spreadsheets, measurement data imports, etc.) and store them safely and securely with your job.<br/><br/>When stored in " +
          this.BaseConfig.APP_NAME +
          " you can always quickly and easily find the right document for the right job.<br/><br/>",
      },
      measurements: {
        title: "Measurements",
        content:
          "Add, edit, or reference measurements for the job quickly and easily in one place.<br/><br/>Measurements can be added manually or imported using one of the many remote measurement providers.<br/><br/>",
      },
      notes: {
        title: "Notes",
        content: "Add notes for the job that can be shared and referenced with the entire team.<br/><br/>",
      },
      history: {
        title: "History",
        content: "View the history of a job, status of emails sent in the system, all in one place.<br/><br/>",
      },
      presentations: {
        title: "Presentations",
        content:
          "Access your company's presentation material quickly and easily.<br/><br/>With presentations uploaded globally you know you'll always have what you need when you need it.<br/><br/>",
      },
      design_tools: {
        title: "Design Tools",
        content: "Utilizing integrated design tools you can help bring your customer's design needs to life.<br/><br/>",
      },
      estimates: {
        title: "Estimates",
        content:
          "Quickly and easily build a price for your customer utilizing the integrated estimation functionality. Our flexible estimator allows you to generate an estimate with your pricing information and communicate that professionally with your customer.<br/><br/>",
      },
      proposals: {
        title: this.Session.currentUser.org.estimateTitle(true),
        content:
          "Generate professional " +
          this.Session.currentUser.org.estimateTitle(true) +
          " for your customers each and every time. These documents can also be easily emailed to the customer.<br/><br/>",
      },
      contracts: {
        title: this.Session.currentUser.org.contractTitle(true),
        content:
          "Generate professional " +
          this.Session.currentUser.org.contractTitle(true) +
          " for your customer. Utilizing the integrated digital signature feature obtain a signature in the home or remote.<br/><br/>",
      },
    };
    _.each(this.props.jobShowCtrl.sidebar.sections, (section: ISection) => {
      menuItems = menuItems.concat(section.menuItems);
    });
    _.each(menuItems, (mi, idx) => {
      if (["signatures", "inspection"].includes(mi.id)) return;

      const placement = "right";
      this.tour.createStep({
        selector: "#" + mi.id,
        order: idx,
        title: this.tourData[mi.id].title,
        scrollIntoView: true,
        placement: placement,
        trustedContent: this.$sce.trustAsHtml(this.tourData[mi.id].content),
      });
    });

    this.tour.createStep({
      selector: "#presentation-mode-off",
      order: 100,
      title: "Presentation Mode",
      scrollIntoView: true,
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Turn on presentation mode to hide the menus at the left and enable the screen share.<br/><br/>",
      ),
    });

    this.tour.createStep({
      selector: "#presentation-mode-on",
      order: 101,
      title: "Exiting Presentation Mode",
      scrollIntoView: true,
      placement: "right-top",
      trustedContent: this.$sce.trustAsHtml("Exiting presentation mode is as easy as clicking the X.<br/><br/>"),
    });

    this.tour.createStep({
      selector: "#presentation-mode-start",
      order: 102,
      title: "Start Screen Share",
      scrollIntoView: true,
      placement: "right-top",
      trustedContent: this.$sce.trustAsHtml(
        "Instantly start the integrated screen share with the click of a button.<br/><br/>",
      ),
    });

    this.tour.createStep({
      selector: "#presentation-mode-invite",
      order: 104,
      title: "Send Invitation",
      scrollIntoView: true,
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Instantly send a screen share invitation via email or text message.<br/><br/>",
      ),
    });
  }

  public startTour() {
    this.Session.fetchUserPref(UserPrefConfigType.job_show_tour).then((show_tour) => {
      if (this.props.jobShowCtrl && show_tour) {
        this.props.jobShowCtrl.$timeout(() => {
          const tour = this.props.jobShowCtrl.JobInfoTourService.tour;
          const tour_feedback = _.isUndefined(tour) || tour.getStatus() === 0;
          if (this.props.jobShowCtrl.$stateParams["id"] !== "new" && tour_feedback) {
            this.tour.start();
          }
        });
      }
    });
  }

  public next() {
    if (this.tour) {
      this.props.jobShowCtrl.$timeout(() => {
        this.tour.next();
      });
    }
  }

  public help(): ng.IPromise<any> {
    this.Session.setTourPref(UserPrefConfigType.job_show_tour, true);
    const preference = this.Session.savePreference();
    preference.then(() => {
      this.Flash.addMessage(FlashLevels.success, "Job Tour Enabled.");
    });
    return preference;
  }

  public onShown() {
    this.props.jobShowCtrl.$timeout().then(() => {
      this.tour.reposition();
    });
  }

  public onEnd() {
    this.props.jobShowCtrl.EventingFactory.trackEvent("end tour", {
      tour: "JobShowTour",
      step_title: this.tour._getCurrentStep().title,
    });
    this.Session.setTourPref(UserPrefConfigType.job_show_tour, !this.tour.do_not_show);
    this.Session.savePreference();
  }

  public onNext() {
    if (this._prevStep) {
      this._prevStep.popup.remove();
      this._prevStep.popup = undefined;
      this._prevStep.tether = undefined;
    }

    this._prevStep = this.tour._getCurrentStep();
    const order = this._prevStep.order;

    if (order === 100) {
      this.props.jobShowCtrl.toggleNav();
    }

    this.props.jobShowCtrl.EventingFactory.trackEvent("next tour step", {
      tour: "JobShowTour",
      step_title: this.tour._getCurrentStep().title,
    });
  }

  public onPrev() {
    if (this._prevStep) {
      this._prevStep.popup.remove();
      this._prevStep.popup = undefined;
      this._prevStep.tether = undefined;
    }

    this._prevStep = this.tour._getCurrentStep();
    const order = this._prevStep.order;

    if (order === 101) {
      this.props.jobShowCtrl.toggleNav();
    }
    this.props.jobShowCtrl.EventingFactory.trackEvent("previous tour step", {
      tour: "JobShowTour",
      step_title: this.tour._getCurrentStep().title,
    });
  }
}
