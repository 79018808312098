import { ActionsUnion, createAction } from "./Utils";
import { IProductOptionGroupData } from "app2/src/records/ProductOptionGroup";

export const RECEIVE_PRODUCT_OPTION_GROUP = "@productOptionGroups/RECEIVE_PRODUCT_OPTION_GROUP";

export const Actions = {
  receiveProductOptionGroup: (productOptionGroup: IProductOptionGroupData) =>
    createAction(RECEIVE_PRODUCT_OPTION_GROUP, { productOptionGroup }),
};

export type Actions = ActionsUnion<typeof Actions>;
