import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { formatBreakdown } from "app2/src/records/DiscountRecord";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { EstimateDiscountRecord } from "app2/src/records/EstimateDiscount";

export interface EstimateDisplayProps {
  discount: EstimateDiscountRecord;
  destroy: (discount: EstimateDiscountRecord) => void;
}

export function EstimateDisplay(props: EstimateDisplayProps) {
  const { discount, destroy } = props;
  return (
    <div>
      <Row>
        <Col md={3} className="discount-col">
          {discount.name}
        </Col>
        <Col md={4} className="discount-col">
          {formatBreakdown(discount as any)}
        </Col>
        <Col md={3} className="discount-col">
          {discount.calculated ? CurrencyFormat(discount.calculated) : "$0.00"}
        </Col>
        <Col md={2}>
          <ConfirmDialog title={`Are you sure you want to delete discount: ${discount.name}?`} description={""}>
            {(confirm) => (
              <i
                id="delete-estimate-discount"
                className="rsf-delete-link rsf-base-66 action-button"
                onClick={confirm(() => destroy(discount))}
              />
            )}
          </ConfirmDialog>
        </Col>
      </Row>
    </div>
  );
}
