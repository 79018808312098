import { fetcher } from "../helpers/Fetcher";
import { IPaymentTermTemplateData } from "../records/PaymentTermTemplate";

interface IPaymentTermTemplateIndexResponse {
  payment_terms: IPaymentTermTemplateData[];
}

interface IPaymentTermTemplateResponse {
  payment_term: IPaymentTermTemplateData;
}

class PaymentTermTemplateService {
  public orgUrl = "api/v1/orgs/:id/payment_terms";

  public loadByOrg(orgId: number): Promise<IPaymentTermTemplateData[]> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    return fetcher.get<IPaymentTermTemplateIndexResponse>(actionUrl).then((response) => response.payment_terms);
  }

  public create(
    orgId: number,
    paymentTermTemplate: Partial<IPaymentTermTemplateData>,
  ): Promise<IPaymentTermTemplateData> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    return fetcher.post<IPaymentTermTemplateResponse>(actionUrl, { payment_term: paymentTermTemplate }).then(
      (response) => response.payment_term,
      (response) => Promise.reject(response.data.errors),
    );
  }

  public update(
    orgId: number,
    paymentTermTemplate: Partial<IPaymentTermTemplateData>,
  ): Promise<IPaymentTermTemplateData> {
    let actionUrl = this.orgUrl.replace(":id", orgId.toString());
    actionUrl = fetcher.joinUrls(actionUrl, paymentTermTemplate.id.toString());

    return fetcher.patch<IPaymentTermTemplateResponse>(actionUrl, { payment_term: paymentTermTemplate }).then(
      (response) => response.payment_term,
      (response) => {
        if (response.status === 500) {
          return Promise.reject(["Internal Server Error"]);
        }

        if (!_.isArray(response.data.errors)) {
          return Promise.reject(response.data.errors);
        }

        return Promise.reject(["Unknown Error"]);
      },
    );
  }

  // public archive(orgId: number, paymentTermTemplate: PaymentTermTemplateRecord): Promise<IPaymentTermTemplateData> {
  //   let actionUrl = this.orgUrl.replace(":id", orgId.toString());
  //   actionUrl = fetcher.joinUrls(actionUrl, paymentTermTemplate.id.toString());

  //   return fetcher.delete<any>(actionUrl).then(() => {

  //     return paymentTermTemplate.set("archived", true);
  //   }, (response) => Promise.reject(response.data.errors));
  // }
}

export const paymentTermTemplateService = new PaymentTermTemplateService();
