import * as jobActions from "./job.actions";
import { Map, Record, List } from "immutable";
import { fromJSON, IJobData, JobRecord, IJobsPageRecord, JobsPageRecord } from "../records/Job";
import { ImageRecord } from "../records/Image";
import { EstimateOverviewRecord, fromJSON as overviewFromJSON } from "../records/EstimateOverview";
import { RootActions, RootState } from "app2/src/reducers";
import { JobQueryRecord, jobStatesFromJSON } from "app2/src/components/JobList/JobQuery";
import { Nullable } from "app2/src/records";
import { fetch, receive } from "app2/src/reducers/Reducer";
import { fromJSON as statsFromJSON } from "app2/src/records/Statistics";
import { fromJSON as jobQueryFromJSON } from "app2/src/components/JobList/JobQuery";
import { endOfDay, startOfDay } from "date-fns";
import { currentUser } from "app2/src/selectors/userCommon.selectors";
import { org as orgSelector } from "app2/src/selectors/org.selectors";
import { orgNameAddress } from "app2/src/records/OrgRecord";

export const JobStateRecord = Record({
  // Job ID of the current view
  currentJobId: null as Nullable<number>,
  byId: Map<number, JobRecord>(),
  lastSavedById: Map<number, JobRecord>(),
  byPage: Map<number, IJobsPageRecord>(),
  jobQuery: jobQueryFromJSON({}),
  lastSavedJobQuery: jobQueryFromJSON({}),
  lastChangesJobQuery: Map<Partial<JobQueryRecord>>({}),
  jobStates: List(),
  selectedEstimateId: null,
  page: 1,
  total_count: 1,
  total_pages: 1,
  swiped_job: 0,
  uploading: false,
  stats: null,
  sampleJobs: List(),
});

export const initialState = JobStateRecord();

export type JobState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "jobs";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case jobActions.SET_CURRENT_JOB_ID:
      return state.setIn([model, "currentJobId"], action.payload.id);
    case jobActions.RECEIVE_JOB:
      const job = fromJSON({ ...action.payload });
      return receive(state, model, job);
    case jobActions.FETCH_JOB:
      return fetch(state, model, fromJSON({ id: action.payload.id }));
    case jobActions.FETCH_PAGE:
      state = state.setIn([model, "page"], action.payload);
      // if the page doesn't exist, the assumption is that no pages past it exist
      if (!state.getIn(["byPage", action.payload])) {
        return state.setIn([model, "byPage", action.payload], JobsPageRecord({ loading: true }));
      }

      // update the page in question, and since we're reloading it, clear out everything past it for reloading
      // as well.
      return state.setIn([model, "byPage", action.payload, "loading"], true).updateIn([model, "byPage"], (jbp) => {
        return jbp.filter((v, k) => {
          return k <= action.payload;
        });
      });
    case jobActions.RECEIVE_PAGE:
      const ids = List(
        action.payload.jobs.map((j) => {
          state = reducer(state, jobActions.Actions.receiveJob(j as any as IJobData));
          return j.id;
        }),
      );

      return state
        .setIn([model, "byPage", action.payload.page, "loading"], false)
        .setIn([model, "byPage", action.payload.page, model], ids)
        .setIn([model, "total_count"], action.payload.meta.pagination.total_count)
        .setIn([model, "total_pages"], action.payload.meta.pagination.total_pages);
    case jobActions.RESET_PAGES:
      return state.mergeIn([model], { page: 1, total_count: 1, total_pages: 1 });
    case jobActions.SWIPE_JOB:
      return state.setIn([model, "swiped_job"], action.payload);
    case jobActions.ADDING_PHOTO:
      return state.setIn([model, "uploading"], true);
    case jobActions.ADDED_PHOTO:
      return state
        .setIn([model, "uploading"], false)
        .updateIn([model, "byId", action.payload.jobId, "images"], (images: List<ImageRecord>) => {
          return images.push(action.payload.image);
        });
    case jobActions.SAVING_JOB:
      return state.setIn([model, "byId", action.payload, "loading"], true);
    case jobActions.CANCEL_SAVING_JOB:
      return state.setIn([model, "byId", action.payload, "loading"], false);
    case jobActions.SET_JOB_LOADED:
      return state.setIn([model, "byId", action.payload.id, "loading"], false);
    case jobActions.ADD_JOB_TO_PAGE:
      return state.updateIn([model, "byPage", action.payload.page, model], (jobIds: List<number>) => {
        const newJobs = jobIds.unshift(action.payload.jobId);
        return newJobs;
      });
    case jobActions.SET_JOB_QUERY:
      let { value } = action.payload.jobQuery;
      const { name } = action.payload.jobQuery;
      if (name === "state" && value === null) {
        value = undefined;
      }
      if (name === "updated_since") {
        if (value === "Custom") {
          state = state
            .setIn([model, "jobQuery", "filter_by_updated_since"], startOfDay(new Date()))
            .setIn([model, "jobQuery", "filter_by_updated_before"], endOfDay(new Date()));
        }
      }
      return state.setIn([model, "jobQuery", name], value).setIn([model, "lastChangesJobQuery", name], value);
    case jobActions.SET_LAST_SAVED_JOB_QUERY:
      return state.setIn([model, "lastSavedJobQuery"], action.payload.jobQuery);
    case jobActions.RESET_LAST_CHANGES_JOB_QUERY:
      return state.setIn([model, "lastChangesJobQuery"], Map({}));
    case jobActions.RESET_JOB_QUERY:
      const user = currentUser(state);
      const org = orgSelector(state, { orgId: user.org_id });
      const emptyJobQuery = jobQueryFromJSON({ org: { id: org.id, name: orgNameAddress(org) } });
      return state
        .setIn([model, "lastSavedJobQuery"], emptyJobQuery)
        .setIn([model, "jobQuery"], emptyJobQuery)
        .setIn([model, "lastChangesJobQuery"], Map({}));
    case jobActions.REVERT_JOB_QUERY:
      return state
        .setIn([model, "jobQuery"], state.getIn([model, "lastSavedJobQuery"]))
        .setIn([model, "lastChangesJobQuery"], Map({}));
    case jobActions.SET_JOB_STATES:
      return state.setIn([model, "jobStates"], jobStatesFromJSON(action.payload.jobStates));
    case jobActions.JOB_RECEIVE_ESTIMATE:
      return state.updateIn(
        [model, "byId", action.payload.jobId, "estimates"],
        (estimates: List<EstimateOverviewRecord>) => {
          const e = estimates.findIndex((e) => e.id === action.payload.estimate.id);
          if (e === -1) {
            return estimates.push(overviewFromJSON(action.payload.estimate));
          }

          return estimates.set(e, overviewFromJSON(action.payload.estimate));
        },
      );
    case jobActions.SET_SELECTED_ESTIMATE_ID:
      return state.setIn([model, "selectedEstimateId"], action.payload.estimateId);
    case jobActions.EDIT_RESOURCE_DISPLAY:
      let documents = state.getIn([model, "byId", action.payload.jobId, "config", "resource_documents"], List());
      const foundIndex = documents.findIndex((d) => d.get("id") === action.payload.documentId);
      if (foundIndex < 0) {
        let doc = Map({ id: action.payload.documentId, displayInAgreement: false, displayInProposal: false });
        doc = doc.set(action.payload.name, action.payload.value);
        documents = documents.push(doc);
      } else {
        documents = documents.setIn([foundIndex, action.payload.name], action.payload.value);
      }

      documents = documents.filter((doc) => {
        return doc.get("displayInAgreement") || doc.get("displayInProposal");
      });

      return state.setIn([model, "byId", action.payload.jobId, "config", "resource_documents"], documents);

    case jobActions.SET_RESOURCE_DOCUMENTS:
      return state.setIn(
        [model, "byId", action.payload.jobId, "config", "resource_documents"],
        action.payload.resourceDocuments,
      );

    case jobActions.INCREASE_COUNT:
      return state.updateIn(
        [model, "byId", action.payload.jobId, `${action.payload.fileType}_count`],
        (oldValue) => oldValue + 1,
      );

    case jobActions.DECREASE_COUNT:
      return state.updateIn(
        [model, "byId", action.payload.jobId, `${action.payload.fileType}_count`],
        (oldValue) => oldValue - 1,
      );

    case jobActions.EDIT_JOB:
      return state.setIn([model, "byId", action.payload.jobId, action.payload.name], action.payload.value);

    case jobActions.RECEIVE_STATS:
      const statsRecord = statsFromJSON(action.payload.statistics);
      return state.setIn(["jobs", "stats"], statsRecord.stats);
    case jobActions.SET_SAMPLE_JOBS:
      return state.setIn([model, "sampleJobs"], List(action.payload.sampleJobs));

    default:
      return state;
  }
};
