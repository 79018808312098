import { ActionButtonToggle } from "app2/src/components/Common/ActionButtonToggle";
import * as React from "react";
import { Dropdown } from "react-bootstrap";

export const EllipsisActionDropdown = ({ onToggle, children }): JSX.Element => {
  return (
    <Dropdown drop="left" onToggle={onToggle}>
      <Dropdown.Toggle as={ActionButtonToggle} />
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
};
