import { SignatureRedirectCtrl } from "app/src/Signature/SignatureRedirectCtrl";
import { SignatureSignCtrl } from "app/src/Signature/SignatureSignCtrl";
import { SignatureSignedCtrl } from "app/src/Signature/SignatureSignedCtrl";
import { SignatureSetupComponent } from "./SignatureSetupComponent";
import { SignatureNotificationComponent } from "./SignatureNotificationComponent";

export class SignatureRoutes {
  public static load(app: ng.IModule) {
    app.controller("SignatureRedirectCtrl", SignatureRedirectCtrl);
    app.controller("SignatureSignCtrl", SignatureSignCtrl);
    app.controller("SignatureSignedCtrl", SignatureSignedCtrl);
    app.component("signatureSetup", new SignatureSetupComponent());
    app.component("signatureNotification", new SignatureNotificationComponent());
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("signature_sign", <ng.ui.IState>{
        url: "/signatures/sign",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            templateUrl: "src/Signature/sign.html",
            controller: "SignatureSignCtrl",
            controllerAs: "ctrl",
          },
        },
      })
      .state("signature_signed", <ng.ui.IState>{
        url: "/signatures/signed",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            templateUrl: "src/Signature/signed.html",
            controller: "SignatureSignedCtrl",
            controllerAs: "ctrl",
          },
        },
      })
      .state("signature_redirect", <ng.ui.IState>{
        url: "/signatures/:id",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            templateUrl: "src/Signature/redirect.html",
            controller: "SignatureRedirectCtrl",
            controllerAs: "ctrl",
          },
        },
      });
  }
}
