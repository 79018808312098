import * as ng from "angular";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IProductOptionGroup, IProductOptionGroupResource } from "app/src/Models/ProductOptionGroup";
import { IBaseConfig } from "../../../Common/IBaseConfig";

export class OrgProductOptionGroupEditorCtrl {
  public selectionModeOptions: string[] = ["multi", "single"];
  public pogTypeOptions: string[] = ["default", "cost"];
  public activeTab: number;

  public static $inject = ["BaseConfig", "$uibModalInstance", "Pretty", "option_group", "ProductOptionGroup"];

  constructor(
    public BaseConfig: IBaseConfig,
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public Pretty: IPretty,
    public option_group: IProductOptionGroup,
    public ProductOptionGroup: IProductOptionGroupResource,
  ) {}

  public save() {
    const the_group = this.ProductOptionGroup.fromJSON(this.option_group);
    this.$uibModalInstance.close({
      option_group: the_group,
    });
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }
}
