export class ScreenShareJoinCtrl {
  public uid: string;

  public static $inject = ["$state"];
  constructor(public $state: ng.ui.IStateService) {}

  public openViewer() {
    this.$state.go("screen_share_presenter", { id: this.uid });
  }
}
