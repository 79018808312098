import { IPaymentRequestData, PaymentRequestRecord } from "app2/src/records/PaymentRequest";
import { analyticsFetcher } from "../helpers/Fetcher";
import { IMetaPaginationData } from "../records/MetaPagination";
import { QueryParamsRecord } from "../records/Page";

export interface IPaymentRequestResponse {
  payment_request: IPaymentRequestData;
}

export interface IPaymentRequestIndexResponse {
  payment_requests: IPaymentRequestData[];
  meta: { pagination: IMetaPaginationData };
}

export const url = "payments/v1/requests";

export const create = async (paymentRequest: PaymentRequestRecord): Promise<IPaymentRequestResponse> => {
  const options = { method: "POST" };
  const response = (await analyticsFetcher.fetch(url, { payment_request: paymentRequest.toJS() }, options)) as any;
  return response.json();
};

export const loadPaymentRequests = async (
  jobId: number,
  queryParams: QueryParamsRecord,
): Promise<IPaymentRequestIndexResponse> => {
  const params = {
    job_id: jobId,
    page_size: 5,
    page: queryParams.get("page"),
    sort_by: "updated_at",
    sort_order: "desc",
  };

  const response = await analyticsFetcher.fetch(url, params);
  const data = await analyticsFetcher.json(response);

  return {
    payment_requests: data.payment_requests,
    meta: {
      pagination: {
        total_count: parseInt(response.headers.get("total")),
        total_pages: parseInt(response.headers.get("total-pages")),
        current_page: parseInt(response.headers.get("page-number")),
      },
    },
  };
};
