import * as React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

interface SavePdfModalProps {
  download: any;
  saveToDocs: any;
  saveToDocsAndAgreement: any;
  agreementText: string;
}

interface SavePdfModalState {}

export default class SavePdfModal extends React.Component<SavePdfModalProps, SavePdfModalState> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { download, saveToDocs, saveToDocsAndAgreement, agreementText } = this.props;

    return (
      <React.Fragment>
        <Modal.Header closeButton={true}>
          <Row>
            <Col title="Heading" sm={12}>
              Save PDF
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <div className="row-even-spacing">
            <div className="col">
              Download
              <Button title="Download" variant="default" onClick={download}>
                <span>
                  <i className="fa fa-download" />
                </span>
              </Button>
            </div>
            <div className="col">
              Save to Documents
              <Button title="Save to Docs" variant="save" onClick={saveToDocs}>
                <span>
                  <i className="fa fa-save" />
                </span>
              </Button>
            </div>
            <div className="col">
              Save to Documents <br />
              {agreementText}
              <Button title="Save to Docs and Agreement" variant="save" onClick={saveToDocsAndAgreement}>
                <div>
                  <i className="fa fa-save" />
                  &nbsp;+&nbsp;
                  <img
                    ng-src="/assets/images/icons/i_contracts-agreement.7f7d6227.png"
                    title="Agreement"
                    src="/assets/images/icons/i_contracts-agreement.7f7d6227.png"
                  />
                </div>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </React.Fragment>
    );
  }
}
