export interface ICouponTemplate {
  name: string;
  duration: string;
  duration_in_months: number;
  code: any;
  max_redemptions: number;
  redeem_by_days_from_now?: number;
  redeem_by?: Date;
  amount_off: number;
  percent_off: number;
}

const couponTemplates: ICouponTemplate[] = [
  {
    name: "Core Logic Introductory Offer",
    duration: "once",
    duration_in_months: null,
    code: _.template("CLIO<%= account_id %>"),
    max_redemptions: 5,
    redeem_by_days_from_now: 30,
    amount_off: 2995,
    percent_off: null,
  },
];

export default couponTemplates;
