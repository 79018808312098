import { Record } from "immutable";
import { Nullable } from "app2/src/records";
import { IMetaPaginationData } from "../MetaPagination";

export const fromJSON = (json: Partial<ICrmUserData>): CrmUserRecord => {
  return new CrmUserRecord(json);
};

export interface ICrmUserIndexResponse {
  crm_users: ICrmUserData[];
  meta: { pagination: IMetaPaginationData };
}

export interface ICrmUserResponse {
  crm_user: ICrmUserData;
}

export type CrmUserType =
  | "MsUser"
  | "SfUser"
  | "I360User"
  | "JnUser"
  | "SrUser"
  | "LpUser"
  | "CcUser"
  | "JpUser"
  | "HoverUser"
  | "AlUser"
  | "FmUser";

export enum CrmUsers {
  MsUser,
  SfUser,
  I360User,
  JnUser,
  SrUser,
  LpUser,
  CcUser,
  JpUser,
  HoverUser,
  AlUser,
  FmUser,
}

export interface ICrmUserData {
  id: number;
  user_id: number;
  org_id: number;
  crm_id: string;
  username: string;
  email: string;
  name: string;
  active: boolean;
  type: CrmUserType;
  errors?: string[];
  loading?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface ICrmUserRecord {
  id: Nullable<number>;
  user_id: Nullable<number>;
  org_id: Nullable<number>;
  crm_id: Nullable<string>;
  username: Nullable<string>;
  email: Nullable<string>;
  name: Nullable<string>;
  active: boolean;
  type: Nullable<CrmUserType>;
  loading: boolean;
  errors: string[];

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

export const defaultCrmUserProps = {
  id: 0,
  user_id: null,
  org_id: null,
  crm_id: null,
  username: null,
  email: null,
  name: null,
  active: false,
  type: null,
  loading: false,
  errors: null,

  created_at: null,
  updated_at: null,
};

export const IntegrationUserType = {
  job_nimbus: "JnUser",
  jobprogress: "JpUser",
  companycam: "CcUser",
  improveit360classic: "I360User",
  improveit360lightning: "I360LightningUser",
};

export class CrmUserRecord extends Record<ICrmUserRecord>(defaultCrmUserProps) implements ICrmUserRecord {}
