import * as React from "react";
import { Col, Row } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import * as FontAwesome from "react-fontawesome";
import { AddressRecord } from "app2/src/records/Address";

interface GoogleMapState {
  googleMapOptions: google.maps.MapOptions;
  options: GoogleMapOptions;
  address: AddressRecord;
}

interface GoogleMapProps {
  address: AddressRecord;
  googleMapOptions?: google.maps.MapOptions;
  options?: GoogleMapOptions;
  mapChange?: (e) => void;
}

interface GoogleMapOptions {
  height: string;
}

export class GoogleMap extends React.Component<GoogleMapProps, GoogleMapState> {
  public defaultGoogleMapOptions: google.maps.MapOptions = {
    mapTypeControl: true,
    mapTypeId: google.maps.MapTypeId.SATELLITE,
    streetViewControl: true,
  };
  public defaultMapOptions: GoogleMapOptions = {
    height: "350px",
  };

  public constructor(props) {
    super(props);

    this.state = {
      googleMapOptions: { ...this.defaultGoogleMapOptions, ...props.googleMapOptions },
      options: { ...this.defaultMapOptions, ...props.options },
      address: props.address,
    };
  }

  public componentDidUpdate(prevProps) {
    const { address } = this.state;
    const { lat, lon } = this.props.address;
    if (!(lat && lon)) {
      return;
    }
    if (lat !== address.lat || lon !== address.lon) {
      this.setState({
        address: this.props.address,
      });
    }
  }

  public render() {
    const { mapChange } = this.props;
    const { googleMapOptions, options, address } = this.state;

    return (
      <Row>
        <Col style={{ height: options.height }}>
          {address.lat && address.lon ? (
            <React.Fragment>
              <GoogleMapReact
                onChange={mapChange}
                center={{
                  lat: address.lat,
                  lng: address.lon,
                }}
                defaultZoom={19}
                options={googleMapOptions}
              />
              <FontAwesome name="map-marker" className="map-overlay fa-3x" title="Location" size="lg" />
              <Row>
                <Col className="col-md-12">
                  <p className="pull-right">Click & drag the map to center the structure under the marker.</p>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <div className="form-section text-center">
              <h2>No valid address</h2>
              <p>Enter a valid address to generate a map.</p>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
