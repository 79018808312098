import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { Formula } from "./Formula";
import { MarginWithOverhead } from "./MarginWithOverhead";
import { DateFormat } from "app2/src/helpers/Format";
import { currentJobName } from "app2/src/selectors/job.selectors";
import { currentOrg, currentOrgId, settingsConfig } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";
import { DownloadCommissionButton } from "./DownloadCommissionButton";
import * as FontAwesome from "react-fontawesome";
import { AsyncActions } from "app2/src/reducers/estimate.actions";
import { estimate as estimateSelector } from "app2/src/selectors/estimate.selectors";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { isEstimateLoading as isEstimateLoadingSelector } from "app2/src/selectors/estimate.selectors";
import { Calculations } from "./Calculations";
import { CommissionMethod } from "app2/src/records/Estimate";
import { CopyToClipboardButton } from "app2/src/components/Clipboard/CopyToClipboardButton";
import {
  estimateCommission as estimateCommissionSelector,
  estimateCommissionSummary as estimateCommissionSummarySelector,
} from "app2/src/selectors/estimateCommission.selectors";

export interface CommissionCalculatorModalProps {
  show: boolean;
  close: () => void;
  estimateId: number;
}

export const CommissionCalculatorModal: React.FC<CommissionCalculatorModalProps> = ({ show, close, estimateId }) => {
  const dispatch = useDispatch();

  const isEstimateLoading = useSelector((state: RootState) => isEstimateLoadingSelector(state, estimateId));
  const jobName = useSelector(currentJobName);
  const calculatorMethod = useSelector((state: RootState) =>
    settingsConfig(state, {
      orgId: currentOrgId(state),
      path: ["estimator", "commission_calculator", "method"],
      notSet: "off",
    }),
  );
  const org = useSelector(currentOrg);
  const estimate = useSelector((state: RootState) => estimateSelector(state, { estimateId }));
  const estimateCommissionSummary = useSelector((state: RootState) =>
    estimateCommissionSummarySelector(state, calculatorMethod),
  );
  const estimateCommission = useSelector(estimateCommissionSelector);

  React.useEffect(() => {
    dispatch(AsyncActions.getEstimate(estimateId));
    if (calculatorMethod === CommissionMethod.greenline || calculatorMethod === CommissionMethod.custom) {
      dispatch(AsyncActions.calculateEstimateCommission(estimateId));
    }
  }, [estimateId]);

  const popover = (
    <Popover id="warn-message">
      <Popover.Content>This estimate was not generated with the current activated price list</Popover.Content>
    </Popover>
  );

  const isActivatedPriceList =
    estimate?.activated_price_list_id === (org.activated_price_list as any)?.id &&
    (calculatorMethod !== CommissionMethod.greenline || estimateCommission.apl_current);
  const showOverlay = !isEstimateLoading && !isActivatedPriceList;

  return (
    <Modal size="lg" show={show} onHide={close} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Commission Calculator - Estimate</Modal.Title>
      </Modal.Header>
      <Modal.Body className="position-relative">
        <div style={{ top: 0, right: 0 }} className="d-flex position-absolute pt-2 pr-2">
          {showOverlay && (
            <OverlayTrigger overlay={popover} trigger={["hover", "focus"]} placement="left">
              <FontAwesome
                id="price_list_error"
                name="exclamation-triangle"
                className="rsf-base-link rsf-base-66 action-button mr-2"
              />
            </OverlayTrigger>
          )}
          <div>
            {(calculatorMethod === CommissionMethod.greenline || calculatorMethod === CommissionMethod.custom) && (
              <CopyToClipboardButton content={estimateCommissionSummary} contentDescription="Commission Sheet" />
            )}
            <DownloadCommissionButton estimateId={estimateId} />
          </div>
        </div>

        <div className="mb-3 pl-lg-4">
          <h4 className="mb-1">Job Name: {jobName}</h4>
          <h5 className="mb-2">Estimate: {estimate?.name}</h5>
          <div>{DateFormat(new Date())}</div>
        </div>

        {isEstimateLoading ? (
          <Row style={{ height: "140px" }} className="pb-0">
            <SpinnerComponent inline localProperty={true} />
          </Row>
        ) : (
          <>
            {calculatorMethod === CommissionMethod.formula && (
              <Formula estimateTotal={estimate?.total} estimateId={estimateId} />
            )}

            {calculatorMethod === CommissionMethod.marginWithOverhead && (
              <MarginWithOverhead estimateTotal={estimate?.total} estimateId={estimateId} />
            )}

            {(calculatorMethod === CommissionMethod.greenline || calculatorMethod === CommissionMethod.custom) && (
              <Calculations />
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
