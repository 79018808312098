import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { List } from "immutable";
import * as paymentActions from "app2/src/reducers/payment.actions";
import { PaymentRecord } from "app2/src/records/PaymentRecord";
import { getPagedPayments } from "app2/src/selectors/payment.selectors";
import { DateFormat, CurrencyFormat } from "app2/src/helpers/Format";
import PaySimpleListView from "./PaySimple/PaySimpleListView";
import CardConnectListView from "./CardConnect/CardConnectListView";

const mapStateToProps = (state: RootState, ownProps: IPaymentListProps) => {
  const page = state.getIn(["leads", "page"], 1);

  return {
    payments: getPagedPayments(state, { page: page }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IPaymentListProps) => {
  return {
    loadPage: (jobId: number, page: number) => dispatch(paymentActions.AsyncActions.getPage({ page, job_id: jobId })),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IPaymentListProps {
  jobId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IPaymentListProps;

export interface IPaymentListState {
  page: number;
}

class PaymentList extends React.Component<Props, IPaymentListState> {
  private _initialized = false;

  public constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  public componentDidMount() {
    this.tryInitialize();
  }

  public componentDidUpdate() {
    this.tryInitialize();
  }

  public render() {
    const { payments } = this.props;

    if (payments.count() <= 0) {
      return (
        <Row>
          <Col md={12}>
            <p>No Payments</p>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col md={12}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Payment Method</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((p, idx) => {
                switch (p.kind) {
                  case "cardconnect":
                    return <CardConnectListView key={idx} idx={idx} payment={p} />;
                  default:
                    //default AND PaySimple
                    return <PaySimpleListView key={idx} idx={idx} payment={p} />;
                }
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  }

  protected tryInitialize() {
    if (this._initialized) {
      return;
    }

    const { page } = this.state;
    const { loadPage, jobId } = this.props;

    if (jobId > 0) {
      this._initialized = true;
      loadPage(jobId, page);
    }
  }
}

export default connector(PaymentList);
