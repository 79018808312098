import { ISession } from "app/src/Common/SessionService";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { OrgAclType } from "../../Models/Org";
import { UserPrefConfigType } from "../../Models/UserPreference";
import { IBaseConfig } from "../IBaseConfig";

export class EstimatorTourService {
  public tour: any;
  public props: any = {};
  public addLineItemStep: any;
  protected _prevStep: any;

  public static $inject = ["uiTourService", "Session", "$sce", "Flash", "BaseConfig"];

  constructor(
    public uiTourService: any,
    private Session: ISession,
    public $sce: ng.ISCEService,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
  ) {}

  public init(props: any) {
    if (!props.estimateCtrl.navDisplay) {
      return;
    }
    this.Session.fetchUserPref(UserPrefConfigType.estimator_tour).then((est_tour) => {
      if (est_tour) {
        this.props = props || {};
        this.props.estimateCtrl.activeGroupTab = "overview";
        this.props.estimateCtrl.EventingFactory.trackEvent("initialize tour", {});
        this.tour = this.uiTourService.getTourByName("estimate-tour");
        this.tour.do_not_show = true;
        this.props.estimateCtrl.$timeout().then(() => {
          if (this.tour._getSteps().length <= 0) {
            this.createTour();
          } else if (this.tour.getStatus() === this.tour.Status.OFF) {
            this.props.estimateCtrl.EventingFactory.trackEvent("start tour", {});
            this.startTour();
          }
        });
      }
    });
  }

  public createTour() {
    const full = _.include(this.props.estimateCtrl.org.fetchAcl(OrgAclType.estimator), "full");
    const multiple_groups = _.include(this.props.estimateCtrl.org.fetchAcl(OrgAclType.estimator), "multiple_groups");
    this.tour.createStep({
      selector: "#uitour000",
      order: 10,
      scrollOffset: 200,
      title: "Estimator",
      orphan: true,
      trustedContent: this.$sce.trustAsHtml(
        "Welcome to the Estimator.<br/><br/><span class='tour-bold'>Click next</span> to get a walk through on how to create an estimate.<br/><br/>Please don't hesitate to contact the <span class='tour-underline'>" +
          this.BaseConfig.ONBOARDING_NAME +
          "</span> with any questions you might have.</br></br> Email: <a href='mailto:" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "</span></a></br> Phone: <a href='" +
          this.BaseConfig.ONBOARDING_PHONE_DIAL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_PHONE +
          "</span></a><br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: "estimate-group",
      order: 12,
      scrollOffset: 200,
      title: "Included Estimate Group",
      placement: "top",
      trustedContent: this.$sce.trustAsHtml(
        "The first line item created is added to an estimate group and the line item editor is opened in line.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: "line-item-editor",
      order: 13,
      scrollOffset: 200,
      title: "Line Item Editor",
      placement: "top",
      trustedContent: this.$sce.trustAsHtml(
        "The line item editor provides everything you need to edit name, description, quantity, pricing, & files of the line item.<br/><br/>",
      ),
    });
    if (full) {
      this.tour.createStep({
        selector: "#tour-step-product-selector",
        order: 14,
        scrollOffset: 200,
        title: "Product Selector",
        placement: "top",
        trustedContent: this.$sce.trustAsHtml(
          "The product selector is now accessible via a drop down in the line item editor. Search and select products from your price list.<br/><br/>",
        ),
      });
    }
    this.tour.createStep({
      selector: ".line-item-input",
      order: 15,
      scrollOffset: 200,
      title: "Line Item Editing",
      placement: "top",
      trustedContent: this.$sce.trustAsHtml(
        "Edit the name, description, quantity, and pricing of your line item.<br/><br/>",
      ),
    });
    if (full) {
      this.tour.createStep({
        selector: ".line-item-editor-tabs",
        order: 16,
        scrollOffset: 200,
        title: "Line Item Editor Tabs",
        placement: "top",
        trustedContent: this.$sce.trustAsHtml(
          "The line item editor tabs will show available measurements, files, and product options to select from. The tabs show/hide based on what's available for the current line item.<br/><br/>",
        ),
      });
    }
    this.tour.createStep({
      selector: ".tour-save-button",
      order: 17,
      scrollOffset: 200,
      title: "Save Changes",
      placement: "top",
      trustedContent: this.$sce.trustAsHtml("Click Save Changes after you are done editing your line item.<br/><br/>"),
    });
    this.tour.createStep({
      selector: ".editable-footer",
      order: 18,
      scrollOffset: 200,
      title: "Save, Cancel, & Delete",
      placement: "top",
      trustedContent: this.$sce.trustAsHtml(
        "Click Cancel to undo any changes you made to the line item or Click Delete Item to remove the line item entirely.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: ".nav-tabs",
      order: 20,
      scrollOffset: 200,
      title: "Estimate Groups",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Estimate Groups allow you to <span class='tour-underline'>organize products</span> on your estimate.<br/><br/>Groups can be used for things like a <span class='tour-bold'>Good/Better/Best</span> organization of product options. It can also be used to group your products for easy reference and the ability to <span class='tour-underline'>total each group</span>.<br/><br/>",
      ),
    });
    if (multiple_groups) {
      this.tour.createStep({
        selector: ".nav-tabs",
        order: 25,
        scrollOffset: 200,
        title: "Drag to Reorder",
        placement: "bottom",
        trustedContent: this.$sce.trustAsHtml(
          "The tabs are <span class='tour-bold'>draggable</span> to allow reordering. <span class='tour-underline'>Included Estimate Groups</span> (with asterisk) will always show up first in the order.<br/><br/>",
        ),
      });
    }
    this.tour.createStep({
      selector: "#uitour030",
      order: 30,
      scrollOffset: 200,
      title: "Overview Tab",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "The overview section allows you to <span class='tour-bold'>view all groups</span> and products on your estimate.<br/><br/>It also allows you to <span class='tour-underline'>add discounts</span>, <span class='tour-underline'>set estimated start dates</span>, as well as access the <span class='tour-underline'>payment estimator</span>.<br/><br/>",
      ),
    });
    if (multiple_groups) {
      this.tour.createStep({
        selector: "#uitour040",
        order: 40,
        scrollOffset: 200,
        title: "Add Estimate Group",
        placement: "bottom",
        trustedContent: this.$sce.trustAsHtml(
          "By clicking on <span class='tour-underline'>Add Group</span>, you can create a new estimate group.<br/></br>",
        ),
      });
    }
    this.tour.createStep({
      selector: ".group-tab",
      order: 45,
      scrollOffset: 200,
      title: "Navigate to Group",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Click on an estimate group tab to <span class='tour-underline'>see more detail</span> on that estimate group.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: "#uitour050-0",
      order: 50,
      scrollOffset: 200,
      title: "Collapsed View",
      placement: "left",
      trustedContent: this.$sce.trustAsHtml(
        "The new <span class='tour-underline'>collapsed view</span> allows you to see more items in your estimate group. It also allows you to <span class='tour-bold'>reorganize and reorder</span> items quickly and easily.<br/><br/>Try it out!<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: "#uitour100-0-0",
      order: 55,
      scrollOffset: 200,
      title: "Drag Handle",
      placement: "top",
      popupClass: "tour-left-250",
      trustedContent: this.$sce.trustAsHtml(
        "<span class='tour-bold'>Drag</span> the estimate line item to <span class='tour-bold'>reorder</span> using the drag handle on the left.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: "#uitour060-0",
      order: 60,
      scrollOffset: 200,
      title: "Editable Group Name",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "<span class='tour-bold'>Click the pencil</span> to edit the estimate group name.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: "#uitour070-0",
      order: 70,
      scrollOffset: 200,
      title: "Edit Group Name",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "The estimate group name can have any name.<br/><br/>You can use this to <span class='tour-bold'>organize</span> your items.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: "#uitour080-0",
      order: 80,
      scrollOffset: 200,
      title: "Included Group",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Estimate groups marked as <span class='tour-underline'>Included</span> will be <span class='tour-bold'>totaled together</span> for the total price of the estimate.<br/><br/>Estimate groups marked as <span class='tour-underline'>Not Included</span> will be provided as optional items that are <span class='tour-bold'>not included in the total</span>.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: ".group-tab",
      order: 90,
      scrollOffset: 200,
      title: "Asterisk *",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Notice the <span class='tour-bold estimate-group-included'>blue *</span> next to the estimate group name. This denotes the estimate group is <span class='tour-underline'>included</span>.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: ".tour-add-template",
      order: 95,
      scrollOffset: 200,
      title: "Add Template",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Add line items from a Template to your estimate to get your most often quoted services quickly.<br/><br/>",
      ),
    });
    this.Session.can("update", "EstimateTemplate").then((value) => {
      if (value) {
        this.tour.createStep({
          selector: ".tour-save-template",
          order: 96,
          scrollOffset: 200,
          title: "Save Template",
          placement: "bottom",
          trustedContent: this.$sce.trustAsHtml("Save new Templates by clicking on the drop down button.<br/><br/>"),
        });
      }
    });
    this.tour.createStep({
      selector: ".tour-save-estimate",
      order: 100,
      scrollOffset: 200,
      title: "Save Estimate",
      placement: "left",
      trustedContent: this.$sce.trustAsHtml(
        "After completing the estimate, save the estimate by clicking here.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: "#uitour000",
      order: 110,
      scrollOffset: 200,
      title: "Questions?",
      orphan: true,
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Please don't hesitate to contact the <span class='tour-underline'>" +
          this.BaseConfig.ONBOARDING_NAME +
          "</span> with any questions you might have.</br></br> Email: <a href='mailto:" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "</span></a></br> Phone: <a href='" +
          this.BaseConfig.ONBOARDING_PHONE_DIAL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_PHONE +
          "</span></a> <br/><br/>",
      ),
    });
    this.props.estimateCtrl.EventingFactory.trackEvent("start tour", {});
    this.props.estimateCtrl.$timeout(() => {
      this.startTour();
    });
  }

  public startTour() {
    if (this.props.estimateCtrl.estimate.estimateGroups(true).length > 0) {
      this.tour.start();
      const group = this.props.estimateCtrl.estimate.existingGroups(true)[0];
      if (group.line_items.length > 0) {
        const lineItem = group.line_items[0];
        lineItem.editing = true;
      }
    }
  }

  public next() {
    if (this.tour) {
      this.props.estimateCtrl.$timeout(() => {
        this.tour.next();
      });
    }
  }

  public help(): ng.IPromise<any> {
    this.Session.setTourPref(UserPrefConfigType.estimator_tour, true);
    const preference = this.Session.savePreference();
    preference.then(() => {
      this.Flash.addMessage(FlashLevels.success, "Estimator Tour Enabled.");
    });
    return preference;
  }

  public onShown() {
    this.props.estimateCtrl.$timeout().then(() => {
      this.tour.reposition();
      if (this.tour._getCurrentStep().order === 12) {
        this.tour.removeStep(this.addLineItemStep);
        return;
      }
      if (this.tour._getCurrentStep().order === 10) {
        const group = this.props.estimateCtrl.estimate.existingGroups(true)[0];
        if (group.line_items.length === 0) {
          this.props.estimateCtrl.addLineItem(this.props.estimateCtrl.estimate.existingGroups(true)[0]);
        }
        return;
      }
    });
  }

  public onEnd() {
    this.props.estimateCtrl.EventingFactory.trackEvent("end tour", { step_title: this.tour._getCurrentStep().title });
    this.Session.setTourPref(UserPrefConfigType.estimator_tour, !this.tour.do_not_show);
    this.Session.savePreference();
  }

  public onNext() {
    if (this._prevStep) {
      this._prevStep.popup.remove();
      this._prevStep.popup = undefined;
      this._prevStep.tether = undefined;
    }
    this._prevStep = this.tour._getCurrentStep();
    const order = this._prevStep.order;
    this.props.estimateCtrl.EventingFactory.trackEvent("next tour step", {
      step_title: this.tour._getCurrentStep().title,
    });
    if (order === 20) {
      const group = this.props.estimateCtrl.estimate.existingGroups(true)[0];
      const lineItem = group.line_items[0];
      lineItem.name = "Product Name";
      lineItem.description = "This is the product description.";
      lineItem.quantity = 1;
      lineItem.product_price = 10;
      lineItem.price = 10;
      lineItem.ext_price = 10;
      lineItem.editing = false;
      lineItem.newly_added = false;
      this.props.estimateCtrl.selectedLineItem = lineItem;
      this.props.estimateCtrl.lineItemEdited(lineItem, group);
      return;
    }
    if (order === 30) {
      this.props.estimateCtrl.activeGroupTab = this.props.estimateCtrl.estimate.groups[0].$$hashKey;
      return;
    }
    if (order === 50) {
      this.props.estimateCtrl.collapsedView = true;
      return;
    }
    if (order === 60) {
      this.props.estimateCtrl.tourEditNameAction[0] = true;
      return;
    }
  }

  public onPrev() {
    if (this._prevStep) {
      this._prevStep.popup.remove();
      this._prevStep.popup = undefined;
      this._prevStep.tether = undefined;
    }
    this._prevStep = this.tour._getCurrentStep();
    const order = this._prevStep.order;
    this.props.estimateCtrl.EventingFactory.trackEvent("previous tour step", {
      step_title: this.tour._getCurrentStep().title,
    });
    if (order === 30) {
      this.props.estimateCtrl.activeGroupTab = "overview";
      const group = this.props.estimateCtrl.estimate.existingGroups(true)[0];
      const lineItem = group.line_items[0];
      lineItem.editing = true;
    }
    if (order === 70) {
      this.props.estimateCtrl.tourEditNameAction[0] = false;
      return;
    }
  }
}
