import * as React from "react";
import { RootState } from "app2/src/reducers";
import { Form, FormGroupProps, FormLabelProps } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select, { StylesConfig } from "react-select";
import { getPaginationByModel, isCurrentPageLoading } from "app2/src/selectors/pagination.selectors";

export interface QueryableDropdownProps {
  onChange: (newValue: number) => void;
  queryList: any;
  selectedId: number;
  selectedItem: any;
  modelName: string;
  label: string;
  rootPath: string[];
  optionsLabelFn: (any) => string;
  formGroupProps?: FormGroupProps;
  formLabelProps?: FormLabelProps;
}

export const QueryableDropdown: React.FC<QueryableDropdownProps> = ({
  onChange,
  queryList,
  selectedId,
  selectedItem,
  modelName,
  label,
  rootPath,
  optionsLabelFn,
  formGroupProps,
  formLabelProps,
}) => {
  // Hooks
  React.useEffect(() => {
    queryList("");
  }, []);

  // Local State
  const [lastInputValue, setLastInputValue] = React.useState("");

  // Selectors
  const items = useSelector((state: RootState) => {
    const items = getPaginationByModel(state, { path: rootPath, modelName });
    if (!selectedItem) {
      return items;
    }
    return items.filter((item) => item.id !== selectedId).insert(0, selectedItem);
  });
  const loading = useSelector((state: RootState) => isCurrentPageLoading(state, { modelName }));

  // Select Values
  const getOptions = items.toJS().map((item) => ({ label: optionsLabelFn(item), value: item?.id }));
  const getValue = () => getOptions.find((option) => option.value === selectedId);

  const customStyles: Partial<StylesConfig<any, false>> = {
    container: (styles) => ({
      ...styles,
      minWidth: "230px",
      zIndex: 2,
    }),
    singleValue: (styles) => ({
      ...styles,
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: "hsl(0, 0%, 45%)",
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
      },
    }),
  };

  // Methods
  const handleChange = (selectedOption) => {
    onChange(selectedOption.value);
  };

  const handleInputChange = _.debounce((query: string) => {
    if (lastInputValue !== query) {
      queryList(query);
    }
    setLastInputValue(query);
  }, 500);

  const onMenuOpen = () => {
    queryList();
  };

  return (
    <Form.Group {...formGroupProps}>
      <Form.Label {...formLabelProps}>{label}</Form.Label>
      <Select
        required
        name={label}
        onMenuOpen={onMenuOpen}
        styles={customStyles}
        options={getOptions}
        value={getValue()}
        onChange={handleChange}
        onInputChange={handleInputChange}
        isLoading={loading}
        filterOption={() => true}
      />
    </Form.Group>
  );
};
