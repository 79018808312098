import * as React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { pathname } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import { useTracking } from "react-tracking";

export const SignatureRequestButton: React.FunctionComponent = () => {
  // hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "SignaturesTab" });

  // selectors
  const path = useSelector(pathname);

  // methods
  const navigate = () => {
    trackEvent({ action: "Open Signature Request Modal" });
    dispatch(push(`${path}/signature_request`));
  };

  return (
    <Button className="pull-right" onClick={navigate}>
      Create Signable Document
    </Button>
  );
};
