import { RsfForm, FormControl } from "@tberrysoln/rsf-form";
import * as React from "react";
import { Actions } from "app2/src/reducers/integrations/cardconnect.actions";
import { Button, Col, Modal } from "react-bootstrap";
import { required, validateBaseUrl } from "app2/src/helpers/FinalFormValidator";

export interface AuthorizeCardConnectProps {
  orgId: number;
  close: () => void;
  authorize: (orgId: number) => void;
  loadAuthorization: (orgId: number) => void;
}

export const AuthorizeCardConnect: React.FC<AuthorizeCardConnectProps> = ({
  orgId,
  close,
  loadAuthorization,
  authorize,
}) => {
  React.useEffect(() => {
    loadAuthorization(orgId);
  }, []);

  const handleSubmit = () => {
    authorize(orgId);
    close();
  };

  return (
    <>
      <Modal.Header key={0}>
        <Modal.Title>
          <div className="row-eq-height">
            <Col md={12}>
              <h3>CloverConnect</h3>
            </Col>
          </div>
        </Modal.Title>
      </Modal.Header>

      <RsfForm
        rootPath={["integrations", "cardconnect", "byOrgId", orgId]}
        updateFormReducer={({ name, value }) => Actions.update({ rootPath: ["byOrgId", orgId], name, value })}
        onSubmit={handleSubmit}>
        <Modal.Body>
          <FormControl label="Merchant ID" name="merchant_id" validate={required} />
          <FormControl label="Username" name="username" validate={required} />
          <FormControl label="Token" name="token" validate={required} />
          <FormControl label="Hosted Payment Page URL" name="hpp_url" validate={validateBaseUrl} />
        </Modal.Body>

        <Modal.Footer key={2}>
          <Button type="button" variant="cancel" className="pull-right" onClick={() => close()}>
            Close
          </Button>
          <Button type="submit" variant="default" className="pull-right">
            Authorize
          </Button>
        </Modal.Footer>
      </RsfForm>
    </>
  );
};
