import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState, RootActions } from "./index";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { handleErrors } from "app2/src/reducers/Utils";
import { RootDispatchType } from "../store";
import { getEventResults } from "app2/src/api/eventResult.service";
import { IEventResultData } from "../records/EventResult";

export const RECEIVE_EVENT_RESULT = "@eventResults/RECEIVE_EVENT_RESULT";
export const RECEIVE_EVENT_RESULTS = "@eventResults/RECEIVE_EVENT_RESULTS";

export const Actions = {
  receiveEventResult: (orgId: number, result: IEventResultData) =>
    createAction(RECEIVE_EVENT_RESULT, { orgId, result }),
  receiveEventResults: (orgId: number, results: IEventResultData[]) =>
    createAction(RECEIVE_EVENT_RESULTS, { orgId, results }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, RootActions>;

export const AsyncActions = {
  getEventResults: (orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const result = await getEventResults(orgId);
        dispatch(Actions.receiveEventResults(orgId, result.results));
      } catch (response) {
        const err_msg = handleErrors(response)[0];
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, err_msg));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
