import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IProduct } from "app/src/Models/Product";
import { IProductOptionGroup } from "app/src/Models/ProductOptionGroup";
import { IDoc } from "app/src/Models/Doc";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IActivatedPriceList extends ng.resource.IResource<IActivatedPriceList>, ActivatedPriceListPrototype {
  $export(data: any): ng.IPromise<IActivatedPriceList>;
}

export interface IActivatedPriceListResource extends ng.resource.IResourceClass<IActivatedPriceList>, IRsfResource {
  fromJSON?(data: any): IActivatedPriceList;
  getCached?(data: any): IActivatedPriceList;
  fetchCached?(org_id, id): ng.IPromise<any>;
}

export interface IActivatedPriceListResponse extends ng.resource.IResourceArray<IActivatedPriceList> {
  activated_price_lists: IActivatedPriceList[];
  meta: IPagingMetadata;
}

class ActivatedPriceListPrototype {
  public id: number;
  public org_id: number;
  public price_list_id: number;
  public file: IDoc;
  public products: IProduct[];
  public productLeaves: IProduct[];
  public product_option_groups: IProductOptionGroup[];
  public hidden_products: boolean;
  public csv_url: string;
  public csv_generated_on: Date;
  public created_at: Date;
  public updated_at: Date;
  public location: string;
  public estimate_count: number;
  public activated_price_list_id: number;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, $http: ng.IHttpService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/activated_price_lists/:id";

  const transform = (data: any): IActivatedPriceList => {
    data.productLeaves = [];
    _.each(data.products, (child, index) => {
      data.products[index] = resources.Product.fromJSON(child);
    });
    _.each(data.products, (product: IProduct) => {
      _.each(product.selfAndDescendants(), (p) => {
        const opening = p.kind === "window" || p.kind === "door";
        p.ancestral_name = p.ancestral_names();
        p.ancestral_name_lowercase = p.ancestral_name.toLowerCase();
        if ((p.children.length === 0 && !opening) || (p.children.length > 0 && opening)) {
          data.productLeaves.push(p);
        }
      });
    });

    _.each(data.product_option_groups, (pog: IProductOptionGroup, index) => {
      data.product_option_groups[index] = resources.ProductOptionGroup.fromJSON(pog);
    });

    // This was added because old activated price lists didn't have the hidden_products variable set on them
    // I'm making an assumption that if you're loading a cached price list it has to be activated
    if (data.hidden_products === undefined) {
      data.hidden_products = false;
    }

    return new ActivatedPriceList(data);
  };

  const ActivatedPriceList: IActivatedPriceListResource = <IActivatedPriceListResource>$resource(
    url,
    {},
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return new ActivatedPriceList({});
          }

          const data: any = JSON.parse(response).activated_price_list;

          return transform(data);
        },
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const data: any = JSON.parse(response);

          data.activated_price_lists = _.map(data.activated_price_lists, (obj: any) => {
            return ActivatedPriceList.fromJSON(obj);
          });

          return data;
        },
      },
      getCached: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/cached",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return new ActivatedPriceList({});
          }

          const data: any = JSON.parse(response).activated_price_list;

          return transform(data);
        },
        isArray: false,
      },
      export: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/export",
        params: {
          org_id: "@org_id",
          id: "@id",
        },
        transformResponse: (response: string) => {
          const data: any = JSON.parse(response).activated_price_list;

          return transform(data);
        },
        isArray: false,
      },
    },
  );

  ActivatedPriceList.fromJSON = (data: any): IActivatedPriceList => {
    return transform(data);
  };

  ActivatedPriceList.fetchCached = (org_id, id) => {
    return ActivatedPriceList.getCached({ org_id: org_id, id: id }).$promise.then(
      (activatedPriceList: IActivatedPriceList) => {
        return $http
          .get(activatedPriceList.file.file.url, { headers: { Authorization: undefined } })
          .then((data: any) => {
            const edited_data = data.data.price_list;
            edited_data.activated_price_list_id = id;
            //noinspection TypeScriptUnresolvedVariable
            return ActivatedPriceList.fromJSON(edited_data);
          });
      },
    );
  };

  _.hiddenExtend(ActivatedPriceList.prototype, ActivatedPriceListPrototype.prototype);

  ActivatedPriceList.inject = (injected: IRepository) => {
    resources = injected;
  };

  return ActivatedPriceList;
};

factory.$inject = ["$resource", "$http", "BaseConfig"];

export default factory;
