import * as React from "react";
import { connectFC } from "app2/src/connect";
import { RsfForm, FormControl } from "@tberrysoln/rsf-form";
import { Actions } from "app2/src/reducers/sku.actions";

interface SkuInputProps {
  skuId: number;
}

const SkuInput: React.FC<SkuInputProps> = ({ skuId }) => {
  return (
    <RsfForm rootPath={["skus", "byId", skuId]} updateFormReducer={Actions.update} onSubmit={() => {}}>
      <FormControl label="sku" name="value" />
    </RsfForm>
  );
};

export default connectFC(SkuInput);
