import * as React from "react";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";
import { useParams } from "react-router";
import { Modal, Col, Row, Container } from "react-bootstrap";
import * as jobAttributeActions from "app2/src/reducers/jobAttribute.actions";
import { JobAttributeRecordType } from "app2/src/records/JobAttribute";
import { JobAttributeTypes } from "app2/src/records/OrgRecord";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import { RsfForm, FormControl } from "@tberrysoln/rsf-form";
import { rootKey, jobAttributeByTypeAndId, loadingByTypeAndOrgId } from "app2/src/selectors/jobAttribute.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { useDispatch, useSelector } from "react-redux";
import { required } from "app2/src/helpers/FinalFormValidator";
import { capitalize } from "humanize-plus";
import { StoreRegistry } from "app2/src/storeRegistry";
import { IPretty } from "app/src/Common/PrettyNameService";
import { RootState } from "app2/src/reducers";

export const JobAttributeModal = () => {
  const Pretty = StoreRegistry.get<IPretty>("Pretty");

  // hooks
  const dispatch = useDispatch();
  const { goBack } = usePreviousPath();
  const orgId = useSelector(currentOrgId);
  const { attribute: jobAttrType, method } = useParams<{ attribute: JobAttributeTypes; method: string }>();
  let { id } = useParams<{ id: string }>() as { id: string | number };
  const loadingJobAttrs = useSelector((s) => loadingByTypeAndOrgId(s, { orgId, jobAttrType }));

  if (!id) {
    id = -1;
  } else if (typeof id === "string" && id.match(/^\d+$/)) {
    id = parseInt(id);
  }

  // Selectors
  const jobAttr = useSelector<RootState, JobAttributeRecordType>((state) =>
    jobAttributeByTypeAndId(state, { jobAttrType: jobAttrType, id: id }),
  );

  // callbacks
  const hideModal = () => {
    if (id === -1) {
      dispatch(jobAttributeActions.Actions.removeAvailableOrgJobAttribute(orgId, -1, jobAttrType));
    }
    goBack("/add");
  };

  const saveJobAttribute = async () => {
    if (method === "add") {
      await dispatch(jobAttributeActions.AsyncActions.createJobAttribute(orgId, jobAttr, jobAttrType));
    } else {
      await dispatch(jobAttributeActions.AsyncActions.updateJobAttribute(orgId, jobAttr, null, jobAttrType));
    }
    hideModal();
  };

  // Lifecycle
  React.useEffect(() => {
    if (id === -1) {
      dispatch(jobAttributeActions.Actions.receiveJobAttribute({ id: -1, org_id: orgId }, orgId, jobAttrType));
    }
  }, [jobAttr?.id, id, orgId, jobAttrType]);

  React.useEffect(() => {
    if (!loadingJobAttrs && method === "edit" && (!jobAttr || jobAttr?.get("id", 0) === 0)) {
      goBack(`/edit/${id}`);
    }
  }, [loadingJobAttrs, method, jobAttr?.get("id", 0)]);

  return (
    <Modal show={true} onHide={() => hideModal()}>
      <RsfForm
        rootPath={[rootKey, "byTypeAndId", jobAttrType, id]}
        updateFormReducer={jobAttributeActions.Actions.updateForm}
        onSubmit={saveJobAttribute}>
        <Modal.Header closeButton>
          <Modal.Title>
            {_.toTitleCase(method)} {capitalize(Pretty.name[jobAttrType] || jobAttrType)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12}>
                <FormControl label="Name" name="name" validate={required} />
              </Col>
            </Row>
            {jobAttrType === "job_type" && (
              <Row>
                <Col md={12}>
                  <FormControl label="Description" name="description" as="textarea" />
                </Col>
              </Row>
            )}
            <Modal.Footer>
              <ButtonFooter cancel={hideModal} destroy={null} itemName={jobAttrType} saveText="Save" />
            </Modal.Footer>
          </Container>
        </Modal.Body>
      </RsfForm>
    </Modal>
  );
};
