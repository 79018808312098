import { fetcher } from "../helpers/Fetcher";
import { getIn, List } from "immutable";
import { IUserData, UserRecord } from "../records/UserRecord";
import { QueryParamsRecord } from "app2/src/records/Page";
import { baseCleanProps, listParams } from "app2/src/api/Api";
import { CrmUserType } from "app2/src/records/integrations/CrmUser";
import { CamelToSnake } from "app2/src/helpers/Format";
import { IUser } from "app/src/Models/User";
import { IMetaPaginationData } from "../records/MetaPagination";
import { IAuthService } from "../helpers/Auth.service";
import { StoreRegistry } from "../storeRegistry";

export interface ILoginData {
  token: string;
  refresh_token: string;
  user: IUserData;
  accessUid?: string;
}

export interface IUserIndexResponse {
  users: IUserData[];
  meta?: { pagination: IMetaPaginationData };
}

export interface IUserResponse {
  user: IUserData;
}

export interface IAclResponse {
  acl: { [key: string]: string[] };
  templates: { name: string; descriptor: string }[];
}

export const includes: string[] = ["permissions", "org", "lead_sources", "preferences", "settings", "address"];
export const url = "/api/v1/users/";

export const login = (email: string, password: string): Promise<ILoginData> => {
  const actionUrl = fetcher.joinUrls(url, "sign_in");

  return fetch(actionUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      include: includes,
    }),
  })
    .then(fetcher.standardStatus)
    .then(fetcher.json);
};

export const refreshToken = (token: string): Promise<ILoginData> => {
  const actionUrl = fetcher.urlOptions(fetcher.joinUrls(url, "refresh"), {
    token: token,
    "include[]": includes,
  });

  const authService = StoreRegistry.get<IAuthService>("authService");

  return fetch(actionUrl, {
    method: "GET",
    headers: {
      "x-rsf-auth": authService.getAccessUid(),
    },
  })
    .then(fetcher.standardStatus)
    .then(fetcher.json)
    .catch((err) => logout(token));
};

export const logout = (token: string): Promise<void> => {
  const actionUrl = fetcher.joinUrls(url, "logout");

  return fetch(actionUrl, {
    method: "POST",
    body: JSON.stringify({
      token: token,
    }),
  })
    .then(fetcher.standardStatus)
    .then(() => {});
};

export const resetPassword = (token: string, token_type: string, user: IUser): Promise<ILoginData> => {
  return fetch(fetcher.joinUrls(url, "reset_password"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: token,
      token_type: token_type,
      user: user,
      include: includes,
    }),
  })
    .then(fetcher.standardStatus)
    .then(fetcher.json);
};

export const create = (user: UserRecord, includes: string[] = []): Promise<IUserResponse> => {
  const postBody: any = { user: cleanProps(user) };

  if (includes?.length > 0) {
    postBody.include = includes;
  }
  return fetcher.post<IUserResponse>(url, postBody);
};

export const update = (user: UserRecord, includes: string[] = []): Promise<IUserResponse> => {
  const actionUrl = fetcher.joinUrls(url, user.id.toString());
  const postBody: any = { user: cleanProps(user) };

  if (includes?.length > 0) {
    postBody.include = includes;
  }

  return fetcher.patch(actionUrl, postBody);
};

export const load = (id: number, includes: string[] = []): Promise<IUserData> => {
  let actionUrl = fetcher.joinUrls(url, id.toString());

  if (includes.length > 0) {
    actionUrl = fetcher.urlOptions(actionUrl, {
      "include[]": includes,
    });
  }
  return fetcher.get<{ user: IUserData }>(actionUrl).then((data) => data.user);
};

export const loadByEmail = (email: string, includes: string[] = [], token?: string): Promise<IUserData> => {
  let actionUrl = fetcher.joinUrls(url, "email");
  const options: any = {};

  if (includes.length > 0) {
    actionUrl = fetcher.urlOptions(actionUrl, {
      email: email,
      "include[]": includes,
    });
  }

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return fetcher.get<{ user: IUserData }>(actionUrl, {}, options).then((data) => data.user);
};

export const loadByAltId = (
  altName: string,
  altId: string,
  includes: string[] = [],
  token?: string,
): Promise<IUserData> => {
  let actionUrl = fetcher.joinUrls(url, "by_alt_id");
  const options: any = {};

  if (includes.length > 0) {
    actionUrl = fetcher.urlOptions(actionUrl, {
      alt_name: altName,
      alt_id: altId,
      "include[]": includes,
    });
  }

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return fetcher.get<{ user: IUserData }>(actionUrl, {}, options).then((data) => data.user);
};

export const list = (queryParams: QueryParamsRecord = new QueryParamsRecord()): Promise<IUserIndexResponse> => {
  let actionUrl = url;
  const params: any = listParams(queryParams);
  params.version = getIn(queryParams, ["parameters", "version"], "v2");
  params.sort_by = "name";

  if (getIn(queryParams, ["parameters", "includes"], []).size > 0) {
    params["include[]"] = getIn(queryParams, ["parameters", "includes"], List()).toJS();
  }

  if (getIn(queryParams, ["parameters", "org_id"], []).length > 0) {
    params["org_id"] = getIn(queryParams, ["parameters", "org_id"], "");
  }

  actionUrl = fetcher.urlOptions(actionUrl, params);
  return fetcher.get<IUserIndexResponse>(actionUrl);
};

export const crmUserCheck = (orgId: number, userId: number, type: CrmUserType): Promise<void> => {
  const orgAssociation = CamelToSnake(type) + "s";
  const actionUrl = `api/v1/orgs/${orgId}/users/${userId}/${orgAssociation}/check`;

  return fetcher.get(actionUrl);
};

export const revokeAccess = async (userId: number, accessId: number): Promise<void> => {
  const actionUrl = fetcher.joinUrls(url, userId.toString(), "revoke_access");

  await fetcher.post(actionUrl, { access_id: accessId });
};

export const sendInvitation = async (email: string): Promise<void> => {
  const actionUrl = fetcher.joinUrls(url, "send_invitation");

  await fetcher.post(actionUrl, { email });
};

export const loadAclValues = (userId: number, accessUid: string, template: string): Promise<IAclResponse> => {
  const actionUrl = fetcher.joinUrls(url, userId.toString(), "acl");

  const params: { access_uid?: string; template?: string } = {};

  if (accessUid) {
    params.access_uid = accessUid;
  }

  if (template) {
    params.template = template;
  }

  return fetcher.get(actionUrl, params);
};

const cleanProps = (user: UserRecord): IUserData => {
  const userData: IUserData = baseCleanProps(user.toJS());

  if (userData.accesses) {
    (userData as any).accesses_attributes = userData.accesses.map((a) => {
      const cleaned = baseCleanProps(a);
      delete cleaned.org;

      if (cleaned.org_id <= 0) {
        delete cleaned.org_id;
      }

      return cleaned;
    });

    delete userData.accesses;
  }

  if (userData.org) {
    delete userData.org;
  }

  return userData;
};
