import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { DiscountKinds, formatKind } from "app2/src/records/DiscountRecord";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { EstimateDiscountRecord } from "app2/src/records/EstimateDiscount";
import { DecimalInputComponent } from "../../Common/DecimalInputComponent";

export interface EditorProps {
  discount: EstimateDiscountRecord;
  update: (discount: EstimateDiscountRecord) => void;
  destroy: (discount: EstimateDiscountRecord) => void;
}

export interface EditorState {
  discount: EstimateDiscountRecord;
}

export class EstimateEditor extends React.Component<EditorProps, EditorState> {
  protected debouncedUpdate: (discount: EstimateDiscountRecord) => void;
  constructor(props: EditorProps) {
    super(props);

    this.state = {
      discount: props.discount,
    };

    this.debouncedUpdate = _.debounce(props.update, 1000);
    this.handleDiscountInput = this.handleDiscountInput.bind(this);
    this.canSave = this.canSave.bind(this);
  }

  public componentDidUpdate(prevProps: EditorProps): void {
    const prev = prevProps.discount ? JSON.stringify(prevProps.discount) : "";
    const current = this.props.discount ? JSON.stringify(this.props.discount) : "";

    if (prev === current) {
      return;
    }

    if (current === "") {
      this.setState({
        discount: null,
      });
      return;
    }

    const discount = this.props.discount;

    this.setState({
      discount: discount,
    });
  }

  public canSave(): boolean {
    return !_.isEqual(this.props.discount, this.state.discount);
  }

  public handleDiscountInput(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
    const value = e.target.value;

    const name = e.target.name;

    let parsedValue: any;
    switch (name) {
      case "amount":
      case "limit":
        parsedValue = parseFloat(value);
        if (Number.isNaN(parsedValue)) {
          parsedValue = 0;
        }
        if (parsedValue < 0) {
          parsedValue = 0;
        }
        break;
      default:
        parsedValue = value;
        break;
    }

    const prevDiscount = this.state.discount.set(name as any, parsedValue);
    this.debouncedUpdate(prevDiscount);
    this.setState({
      discount: prevDiscount,
    });
  }

  public render(): React.ReactNode {
    const { destroy } = this.props;
    const { discount } = this.state;

    if (!discount) {
      return (
        <Row>
          <Col md={12}>Loading...</Col>
        </Row>
      );
    }

    return (
      <div>
        <Row>
          <Col md={12}>
            <label className="control-label">Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={discount.name}
              onChange={this.handleDiscountInput}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={4}>
            <label className="control-label">Discount</label>
            <DecimalInputComponent
              className="form-control"
              type="number"
              name="amount"
              min="0"
              max="9999999"
              value={discount.amount}
              onNumberChange={(value, name) => this.handleDiscountInput({ target: { name, value } } as any)}
            />
            <br />
            {discount.kind === "percent_limit" ? (
              <DecimalInputComponent
                className="form-control"
                type="number"
                name="limit"
                min="0"
                max="9999999"
                value={discount.limit}
                onNumberChange={(value, name) => this.handleDiscountInput({ target: { name, value } } as any)}
              />
            ) : null}
          </Col>
          <Col md={4}>
            <label className="control-label">Type</label>
            <select
              className="form-control"
              name="kind"
              onChange={this.handleDiscountInput}
              value={discount.kind}
              required>
              {DiscountKinds.map((kind, idx) => {
                return (
                  <option key={idx} value={kind}>
                    {formatKind(kind)}
                  </option>
                );
              })}
            </select>
          </Col>
          <Col md={3}>
            <label className="control-label">Amount</label>
            <p className="discount-amount">{discount.calculated ? CurrencyFormat(discount.calculated) : "$0.00"}</p>
          </Col>
          <Col md={1} className="align-middle">
            <ConfirmDialog title={`Are you sure you want to delete discount: ${discount.name}?`}>
              {(confirm) => (
                <i className="rsf-delete-link rsf-base-66 action-button" onClick={confirm(() => destroy(discount))} />
              )}
            </ConfirmDialog>
          </Col>
        </Row>
        <hr />
      </div>
    );
  }
}
