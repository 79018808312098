import * as React from "react";
import { Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { FinanceOptionRecord, getUrl, PrequalificationProviders } from "app2/src/records/FinanceOption";
import { EstimateRecord } from "app2/src/records/Estimate";
import track from "react-tracking";

export interface IApplicationButtonsProps {
  financeOption: FinanceOptionRecord;
  estimate: EstimateRecord;
  applyNowValidator: (financeOption: FinanceOptionRecord) => Array<string>;
}

export interface IApplicationButtonsState {
  showPrequalificationButton: boolean;
}

@track({ component: "ApplicationButtons" })
export class ApplicationButtons extends React.Component<IApplicationButtonsProps, IApplicationButtonsState> {
  constructor(props) {
    super(props);

    this.state = {
      showPrequalificationButton: PrequalificationProviders.includes(props.financeOption.provider),
    };
  }

  @track((_props, _state, [_financeOption, queryParams]) => ({ action: "Apply Now", queryParams }))
  public applyNow(financeOption: FinanceOptionRecord, queryParams: string) {
    const { estimate } = this.props;
    window.open(getUrl(financeOption, estimate) + queryParams, "_blank");
  }

  public addToolTip(id: any, errors: Array<string>, element: JSX.Element) {
    return (
      <OverlayTrigger
        key={id}
        placement="top"
        delay={{ show: 100, hide: 400 }}
        overlay={<Tooltip id="toolip">Missing Required Fields:&#013;{errors.join(", ")}</Tooltip>}>
        <span>{element}</span>
      </OverlayTrigger>
    );
  }

  public render() {
    const { financeOption, estimate, applyNowValidator } = this.props;
    const { showPrequalificationButton } = this.state;

    if (getUrl(financeOption, estimate) === "") {
      return null;
    }

    const errors = applyNowValidator(financeOption);

    let applyNowBtn = (
      <Button
        disabled={errors.length > 0}
        variant="light"
        className="width-100"
        style={errors.length > 0 ? { pointerEvents: "none" } : {}}
        onClick={() => this.applyNow(financeOption, "")}>
        Apply Now
        {financeOption.provider !== "" && (
          <>
            &nbsp;&nbsp;
            <FontAwesome data-testid="step-forward-apply" name="step-forward" />
          </>
        )}
      </Button>
    );

    if (errors.length > 0) {
      applyNowBtn = this.addToolTip(financeOption.id, errors, applyNowBtn);
    }

    return (
      <Row>
        {showPrequalificationButton && (
          <Col sm={6}>
            <Button
              disabled={errors.length > 0}
              variant="light"
              className="width-100"
              onClick={() => this.applyNow(financeOption, "?pq=1")}>
              Prequalify
              {financeOption.provider !== "" && (
                <>
                  &nbsp;&nbsp;
                  <FontAwesome data-testid="step-forward-pq" name="step-forward" />
                </>
              )}
            </Button>
          </Col>
        )}
        <Col sm={showPrequalificationButton ? 6 : 12}>{applyNowBtn}</Col>
      </Row>
    );
  }
}
