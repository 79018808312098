import { IJob } from "app/src/Models/Job";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { EmailEvent, IEmailEvent } from "./EmailEvent";
import { IPagingMetadata, IQueryMetadata } from "./PagingMetadata";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IEmail extends ng.resource.IResource<IEmail>, EmailPrototype {}

export interface IEmailResource extends ng.resource.IResourceClass<IEmail>, IRsfResource {
  fromJSON?(data: any): IEmail;
  queryByOrg?(date: any): ng.resource.IResourceMethod<IEmail>;
}

export interface IEmailResponse {
  emails: IEmail[];
  meta: IQueryMetadata;
}

class EmailPrototype {
  public id: number;
  public to: string;
  public cc: string;
  public bcc: string;
  public from: string;
  public date: any;
  public emailable_id: number;
  public emailable_type: string;
  public job?: IJob;
  public kind: string;
  public subject: string;
  public status: string;
  public created_at: Date;
  public updated_at: Date;
  public events: IEmailEvent;
  public provider: string;
  public _display: boolean;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IEmailResource => {
  const url = BaseConfig.BASE_URL + "/api/v1/:emailable_type_url/:emailable_id/emails/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IEmail => {
    if (status < 200 || status > 299) {
      return new Email({});
    }

    return Email.fromJSON(JSON.parse(response).email);
  };

  const multipleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IEmail => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);

    _.each(data.emails, (i: any, index: number) => {
      data.emails[index] = Email.fromJSON(i);
    });

    return data;
  };

  const transformRequest = (note: IEmail): string => {
    const data = JSON.decycle(note);
    return angular.toJson({ note: data });
  };

  const Email: IEmailResource = <IEmailResource>$resource(
    url,
    { emailable_type_url: "@emailable_type_url", emailable_id: "@emailable_id", id: "@id" },
    {
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
    },
  );

  Email.fromJSON = (data: any): IEmail => {
    if (data.emailable && data.emailable_type) {
      switch (data.emailable_type) {
        case "Job":
          data.job = resources.Job.fromJSON(data.emailable);
      }
    }

    data.to = data.to.replace(",", "\n");

    if (data.emailable_type) {
      data.emailable_type_url = data.emailable_type ? _.toUnderscore(data.emailable_type) + "s" : "";
    }

    const email = new Email(data);

    if (data.events) {
      _.each(data.events, (r, index) => {
        email.events[index] = EmailEvent.fromJSON(r);
      });
    }

    email._display = false;

    return email;
  };

  Email.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Email;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
