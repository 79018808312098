import { IEstimate } from "rsf-app/app/src/Models/Estimate";
import { IEstimateGroup } from "rsf-app/app/src/Models/EstimateGroup";
import { AddEstimateLineItemCmd } from "rsf-app/app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { EstimatorService } from "rsf-app/app/src/Estimator/EstimatorService";
import { IEstimateLineItem } from "rsf-app/app/src/Models/EstimateLineItem";
import { IImage } from "rsf-app/app/src/Models/Image";
import { cachedRootProducts, cachedProducts } from "rsf-app/app2/src/selectors/product.selectors";
import { StoreRegistry } from "rsf-app/app2/src/storeRegistry";
import { ProductRecord } from "rsf-app/app2/src/records/Product";

class InspectionService {
  public clearEstimate(estimate: IEstimate): IEstimate {
    estimate.groups = [];
    return estimate;
  }

  public setupEstimate(estimate: IEstimate, EstimatorService: EstimatorService): IEstimate {
    const rootProducts = cachedRootProducts(StoreRegistry.getStore().getState(), {
      activatedPriceListId: estimate.activated_price_list_id,
    });
    rootProducts.map((p: ProductRecord) => {
      const group: IEstimateGroup = estimate.createGroup({ name: p.name, included: true });

      const children = cachedProducts(StoreRegistry.getStore().getState(), {
        activatedPriceListId: estimate.activated_price_list_id,
        productIds: p.children_ids,
      });

      children.map((child) => {
        const cmd = new AddEstimateLineItemCmd(estimate, child, group, EstimatorService);
        cmd.execute();
        EstimatorService.addDefaultOptions(cmd.lineItem, child);
      });
    });

    EstimatorService.resetEstimateState();

    return estimate;
  }

  public setGroupVisibility(estimate: IEstimate, group: IEstimateGroup, prop: "a" | "b", value: boolean) {
    const edited_group = _.find(estimate.groups, (g: IEstimateGroup) => {
      return g.id === group.id;
    });
    _.each(edited_group?.line_items, (lineItem: IEstimateLineItem) => {
      this.setVisibility(lineItem, prop, value);
    });

    return estimate;
  }

  public checkGroupVisibility(group: IEstimateGroup, prop: "a" | "b") {
    let it = true;
    _.each(group.line_items, (lineItem) => {
      it = it && (lineItem.visibility === prop || lineItem.visibility === "ab");
    });

    return it;
  }

  public setVisibility(lineItem: IEstimateLineItem, prop: "a" | "b", value: boolean) {
    if (value === true) {
      if (prop === "a") {
        if (lineItem.visibility === "b" || lineItem.visibility === "ab") {
          lineItem.visibility = "ab";
        } else {
          lineItem.visibility = "a";
        }
      }
      if (prop === "b") {
        if (lineItem.visibility === "a" || lineItem.visibility === "ab") {
          lineItem.visibility = "ab";
        } else {
          lineItem.visibility = "b";
        }
      }
    } else {
      if (prop === "a") {
        if (lineItem.visibility === "ab") {
          lineItem.visibility = "b";
        } else {
          lineItem.visibility = "never";
        }
      }
      if (prop === "b") {
        if (lineItem.visibility === "ab") {
          lineItem.visibility = "a";
        } else {
          lineItem.visibility = "never";
        }
      }
    }

    return lineItem;
  }

  public setInclusion(image: IImage, include: boolean): IImage {
    if (include) {
      image.display = "contract";
    } else {
      image.display = "do_not_display";
    }

    return image;
  }
}

export default new InspectionService();
