import * as uuid from "uuid/v4";

export type IDiscount = Discount;

export class Discount {
  public uuid: string;
  public discountId: number;
  public discountRev: string;
  public kind = "percent";
  public name = "";
  public amount = 0;
  public calculated: number;
  public totalAfter: number;
  public limit = 0;

  constructor(data: any) {
    if (data) {
      this.uuid = data.uuid;
      this.discountId = data.discountId;
      this.discountRev = data.discountRev;
      this.kind = data.kind || "percent";
      this.name = data.name || "";
      this.amount = data.amount || 0;
      this.calculated = data.calculated;
      this.totalAfter = data.totalAfter;
      this.limit = data.limit || 0;
    }

    if (!this.uuid) {
      this.uuid = uuid();
    }
  }

  public perform(total: number): number {
    switch (this.kind) {
      case "percent":
        this.calculated = _.round(total * (this.amount / 100), -2);
        break;
      case "percent_limit":
        this.calculated = _.round(total * (this.amount / 100), -2);
        this.calculated = _.min([this.limit, this.calculated]);
        break;
      case "flat":
        this.calculated = this.amount;
        break;
    }
    this.totalAfter = total - this.calculated;

    return this.totalAfter;
  }

  public clone() {
    return Discount.fromJSON(JSON.parse(JSON.stringify(this.toJsonObj())));
  }

  public toJsonObj() {
    return {
      uuid: this.uuid,
      kind: this.kind,
      name: this.name,
      amount: this.amount,
      calculated: this.calculated,
      totalAfter: this.totalAfter,
      discountId: this.discountId,
      discountRev: this.discountRev,
      limit: this.limit,
    };
  }

  public static fromJSON = (data: any) => {
    data.amount = _.parseFloat(data.amount);
    data.calculated = _.parseFloat(data.calculated);
    data.limit = _.parseFloat(data.limit);
    data.totalAfter = _.parseFloat(data.totalAfter);

    return new Discount(data);
  };
}
