import * as paysimpleActions from "./paysimple.actions";
import { Record, fromJS, Map } from "immutable";

export const PaySimpleStateRecord = Record({
  fetchingToken: false,
  authorized: Map<number, boolean>(),
  authorization: Map<number, Map<string, string>>(),
  token: null,
  token_error: "",
  error: "",
});

export const initialState = PaySimpleStateRecord({});
export type PaySimpleState = typeof initialState;

export const reducer = (state = initialState, action: paysimpleActions.Actions): PaySimpleState => {
  switch (action.type) {
    case paysimpleActions.FETCH_AUTHORIZATION:
      if (state.getIn(["authorization", action.payload])) {
        return state.setIn(["authorization", action.payload, "loading"], true);
      }

      return state.setIn(
        ["authorization", action.payload],
        fromJS({ loading: true, username: "", api_key: "", hpp_url: "" }),
      );
    case paysimpleActions.SET_AUTHORIZATION:
      let newState = state
        .setIn(["authorization", action.payload.orgId], fromJS(action.payload.authorization))
        .setIn(["authorization", action.payload.orgId, "loading"], false);

      if (
        action.payload.authorization.username &&
        action.payload.authorization.username !== "" &&
        action.payload.authorization.api_key &&
        action.payload.authorization.api_key !== ""
      ) {
        newState = newState.setIn(["authorized", action.payload.orgId], true);
      }
      return newState;
    case paysimpleActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);
    case paysimpleActions.RESET:
      return state.setIn(["byOrgId", action.payload.id], state.getIn(["lastSavedByOrgId", action.payload.id]));
    case paysimpleActions.FETCH_CHECKOUT_TOKEN:
      return state.set("fetchingToken", true);
    case paysimpleActions.RECEIVE_CHECKOUT_TOKEN:
      return state.set("fetchingToken", false).set("token", fromJS(action.payload));
    case paysimpleActions.RECEIVE_CHECKOUT_TOKEN_ERROR:
      return state.set("fetchingToken", false).set("token_error", action.payload);
    case paysimpleActions.RECEIVE_GENERAL_ERROR:
      return state.set("error", action.payload);
    default:
      return state;
  }
};
