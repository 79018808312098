import { baseCleanProps } from "app2/src/api/Api";
import { fetcher } from "../helpers/Fetcher";
import { ISignedDocumentData, SignedDocumentRecord } from "../records/SignedDocument";
import { ISignedDocumentRecipient } from "app/src/Models/SignedDocumentRecipient";
import { DocumentRecord, IDocumentData, toFormData } from "app2/src/records/Document";

const url = "api/v1/signed_documents/";

export interface ISignedDocumentResponse {
  signed_document: ISignedDocumentData;
}

export interface IValidateSignedDocumentResponse {
  document: Partial<IDocumentData>;
  signature_validations: string;
}

export interface ICreateOptions {
  notify: boolean;
  message: string;
}

export const notify = (documentId: number, recipient?: number): Promise<void> => {
  const actionUrl = new URL(url.concat(`${documentId.toString()}/notify`), fetcher.baseUrl);
  if (recipient) actionUrl.searchParams.append("recipients[]", recipient.toString());
  return fetcher.post(actionUrl.toString());
};

export const loadSigned = (documentId: number): Promise<{ signed_document: ISignedDocumentData }> => {
  const actionUrl = url.concat(documentId.toString());
  return fetcher.get(actionUrl, { "include[]": ["document", "recipients"] });
};

export const validate = (document: DocumentRecord, isModified: boolean): Promise<IValidateSignedDocumentResponse> => {
  if (isModified) {
    const actionUrl = url.concat("validate");
    return fetcher.post(actionUrl.toString(), toFormData(document), { formData: true });
  } else {
    const actionUrl = url.concat("validate");
    return fetcher.post(actionUrl.toString(), { document_id: document.id });
  }
};

export const create = (
  document: DocumentRecord,
  signedDocument: SignedDocumentRecord,
  options: ICreateOptions,
): Promise<ISignedDocumentResponse> => {
  const params = {
    document: baseCleanProps(document.toJS()),
    signed_document: cleanProps(signedDocument),
    include: ["document", "recipients"],
    ...options,
  };
  return fetcher.post(url, params);
};

export const cleanProps = (signedDocument: SignedDocumentRecord): ISignedDocumentData => {
  let signedDocumentParams: any = signedDocument.toJS();
  signedDocumentParams = baseCleanProps(signedDocumentParams);
  signedDocumentParams.recipients_attributes = signedDocumentParams.recipients.filter(
    (rec) => (rec.required === true && rec.included === true) || rec.included === true,
  );
  delete signedDocumentParams.recipients;
  delete signedDocumentParams.validation_status;

  return signedDocumentParams as any as ISignedDocumentData;
};
