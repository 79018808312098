import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { List } from "immutable";
import { currentUserId } from "app2/src/selectors/user.selectors";
import { emptyOrgValue, OrgRecord } from "app2/src/records/OrgRecord";
import { currentUserAccesses } from "app2/src/selectors/access.selectors";
import { AccessRecord } from "../records/UserRecord";

export const currentAccessUid = (state: RootState) => state.getIn(["auth", "accessUid"]);

export const currentOrgFromAccessUid = createSelector(
  [currentUserId, currentAccessUid, currentUserAccesses],
  (currentUserId: number, accessUid: string, accesses: List<AccessRecord>): OrgRecord => {
    if (!currentUserId || accesses.count() <= 0) {
      return emptyOrgValue;
    }

    if (!accessUid) {
      return accesses.first<AccessRecord>({ org: emptyOrgValue } as any).org;
    }

    const match = accesses.find((a) => a.uid === accessUid);

    if (match) {
      return match.org;
    }

    return emptyOrgValue;
  },
);
