import { Record } from "immutable";

export const fromJSON = (json: Partial<IEventResultData>): EventResultRecord => {
  const eventResultData: IEventResultData = { ...(json as any) };

  return new EventResultRecord(eventResultData);
};

export const toJSON = (record: EventResultRecord): IEventResultData => {
  return record.toJS();
};

export interface IEventResultData {
  id: number;
  ms_id: string;
  description: string;
  position: number;
  presentation: boolean;
  sold: boolean;
  url: string;
  kind: string;

  created_at: Date;
  updated_at: Date;
}

export interface IEventResultRecord {
  id: number;
  ms_id: string;
  description: string;
  position: number;
  presentation: boolean;
  sold: boolean;
  url: string;
  kind: string;

  created_at: Date;
  updated_at: Date;
}

export const defaultEventResultProps: IEventResultRecord = {
  id: 0,
  ms_id: "",
  description: "",
  position: 0,
  presentation: false,
  sold: false,
  url: "",
  kind: "default",
  created_at: new Date(),
  updated_at: new Date(),
};

export class EventResultRecord extends Record(defaultEventResultProps) implements IEventResultRecord {}
