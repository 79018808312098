import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { task } from "app2/src/selectors/task.selectors";
import { Row, Col } from "react-bootstrap";

const mapStateToProps = (state: RootState, ownProps: TaskProps) => {
  return {
    task: task(state, { taskId: ownProps.taskId }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: TaskProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface TaskProps {
  taskId: string;
}

interface TaskState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & TaskProps;

class Task extends React.Component<Props, TaskState> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { task } = this.props;

    if (_.isNullOrUndefined(task)) {
      return null;
    }

    return (
      <Row>
        <Col>
          <p>Status: {task?.status}</p>
          <p>Logs:</p>
          <p className="description">{task?.logs}</p>
        </Col>
      </Row>
    );
  }
}

export default connector(Task);
