import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { ScreenShareService } from "app/src/Jobs/ScreenShareService";
import WebCamModal from "app2/src/components/ScreenShare/components/WebCamModal";
import { ScreenShareKindValues, ScreenShareStatusValues } from "app2/src/reducers/screenShare.reducer";
import { IJobRecord } from "app2/src/records/Job";
import { IScreenShareData, ScreenShareRecord } from "app2/src/records/ScreenShare";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as screenShareActions from "app2/src/reducers/screenShare.actions";
import { UserRecord } from "app2/src/records/UserRecord";
import { getPrefConfig } from "app2/src/records/OrgRecord";
import { getRemoteStorage } from "app2/src/api/remoteStorage.service";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";

const mapStateToProps = (state: RootState, ownProps: ScreenShareProps) => {
  const job = state.getIn(["components", "common", "currentJob"]);

  return {
    screenShareKind: state.getIn(["screenShares", "byJobId", job.id, "screenShareKind"], "basic"),
    screenShareStatus: state.getIn(["screenShares", "byJobId", job.id, "screenShareStatus"], null),
    token: state.getIn(["auth", "token"]),
    currentJob: job,
    currentUser: denormalizedReduxUser(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ScreenShareProps) => {
  return {
    setRemoteScreenShareKind: (jobId: number, kind: ScreenShareKindValues) => {
      dispatch(screenShareActions.AsyncActions.setRemoteScreenShareKind(jobId, kind));
    },
    setRemoteScreenShareStatus: (jobId: number, status: ScreenShareStatusValues) => {
      dispatch(screenShareActions.AsyncActions.setRemoteScreenShareStatus(jobId, status));
    },
    saveScreenShareStop: (jobId: number, screenShareId: number) =>
      dispatch(screenShareActions.AsyncActions.saveScreenShareStop(jobId, screenShareId)),
    notifierAddRemoteMessage: (msg) => dispatch(commonActions.AsyncActions.notifierAddRemoteMessage(msg)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ScreenShareProps {
  $scope: ng.IScope;
  ScreenShareService: ScreenShareService;
  $state: ng.ui.IStateService;
}

interface ScreenShareState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ScreenShareProps;

class ScreenShare extends React.Component<Props, ScreenShareState> {
  private _cleanupListeners: any[] = [];
  constructor(props: Props) {
    super(props);

    this.state = {};

    props.$scope.$on("$destroy", () => {
      _.each(this._cleanupListeners, (func: () => void) => {
        if (_.isFunction(func)) {
          func();
        }
      });
    });
  }

  public componentDidUpdate(prevProps: Props) {
    const { screenShareKind, currentJob, setRemoteScreenShareKind, currentUser, token } = this.props;

    if (token && currentUser && currentUser.id > 0 && !getRemoteStorage().initialized) {
      getRemoteStorage().connect(currentUser.uuid);
    }

    if (prevProps.token && !token) {
      getRemoteStorage().disconnect();
    }

    if (prevProps.currentJob.id !== currentJob.id) {
      setRemoteScreenShareKind(currentJob.id, getPrefConfig(currentUser.org, ["screen_share", "kind"]));
    } else {
      switch (screenShareKind) {
        case "basic":
          this.basicScreenShareWatch(prevProps);
          break;
        case "premium":
          this.premiumScreenShareWatch(prevProps);
          break;
      }
    }
  }

  public basicScreenShareWatch(prevProps: Props) {
    const { screenShareStatus, $scope, ScreenShareService, setRemoteScreenShareKind, currentUser, currentJob } =
      this.props;
    if (_.includes(["stop_requested", "off"], prevProps.screenShareStatus) && screenShareStatus === "start_requested") {
      ScreenShareService.setup($scope);
      ScreenShareService.startSharing();
    }
    if (
      _.includes(["start_requested", "active", "tab_opened"], prevProps.screenShareStatus) &&
      screenShareStatus === "stop_requested"
    ) {
      ScreenShareService.stop();
      setRemoteScreenShareKind(currentJob.id, getPrefConfig(currentUser.org, ["screen_share", "kind"]));
    }
  }

  public premiumScreenShareWatch(prevProps: Props) {
    const { screenShareStatus, $state, currentJob } = this.props;

    if (_.includes(["stop_requested", "off"], prevProps.screenShareStatus) && screenShareStatus === "start_requested") {
      const url = $state.href("screen_share_host", {
        uid: currentJob.uid,
        job_id: currentJob.id,
      });
      window.open(url, "_blank");
    }
  }

  public render() {
    return (
      <React.Fragment>
        <WebCamModal />
      </React.Fragment>
    );
  }
}

export default connector(ScreenShare);
