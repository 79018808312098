import { connectFC } from "app2/src/connect";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import EstimatorPreferences from "./EstimatorPreferences";

export interface PreferencesTabProps {
  updatePreferencesConfig: (key: string, value: any) => void;
}

const PreferencesTab: React.FC<PreferencesTabProps> = ({ updatePreferencesConfig }) => {
  return (
    <Row>
      <Col>
        <EstimatorPreferences updatePreferencesConfig={updatePreferencesConfig} />
      </Col>
    </Row>
  );
};

export default connectFC(PreferencesTab);
