import * as React from "react";
import { Form, Modal } from "react-bootstrap";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";

export interface EmailMessageArgs {
  email: string;
  message: string;
}

interface EmailMessageModalProps {
  initEmail: string;
  title: string;
  emailMessage: (args: EmailMessageArgs) => void;
  cancel: () => void;
}

export const EmailMessageModal: React.FC<EmailMessageModalProps> = ({ initEmail, title, emailMessage, cancel }) => {
  const [email, setEmail] = React.useState(initEmail);
  const [message, setMessage] = React.useState("");

  const update = () => {
    emailMessage({ email, message });
  };

  return (
    <Modal show={true} onHide={cancel} size={"lg"} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Message</Form.Label>
          <Form.Control
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            as="textarea"
            rows={4}
            placeholder="Message to the email recipient"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <ButtonFooter cancel={cancel} update={update} saveText={"Send"} />
      </Modal.Footer>
    </Modal>
  );
};
