import { List } from "immutable";
import { RootState } from "../reducers";

export const rootKey = "reports";

/**
 * returns the pricing for plnar
 *
 * @param {RootState} state The RootState
 * @returns {List} List
 */
export const plnarPricing = (state: RootState): List<Map<string, string | number>> => {
  return state.getIn(["reports", "pricingReports", "plnar_interior", "data"]);
};

/**
 * returns the loading flag for pricing reports
 *
 * @param {RootState} state The RootState
 * @returns {boolean} boolean
 */
export const plnarPricingLoading = (state: RootState): boolean => {
  return state.getIn(["reports", "pricingReports", "plnar_interior", "loading"]);
};
