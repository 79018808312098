import * as React from "react";
import * as config from "react-global-configuration";
import Toolbar from "app2/src/components/ScreenShare/Toolbar";
import { BrowserDetection } from "app2/src/helpers/BrowserDetection";
import ToggleFullscreenButton from "./ToggleFullScreenButton";

export default function Header(props: { showInvite: boolean }) {
  return (
    <nav className="navbar navbar-expand-md">
      <a className="navbar-brand">
        <img src={config.get("APP_ICON_URL")} alt={config.get("APP_NAME")} height="40" />
      </a>
      {props.showInvite && <Toolbar showSendInvite={true} />}
      {!BrowserDetection.isBrowserMobile() && <ToggleFullscreenButton />}
    </nav>
  );
}
