import { Col, Row } from "react-bootstrap";
import * as React from "react";

export const TokenRequiredView = () => {
  return (
    <Row>
      <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
        <div className="form-section text-center">
          <div className="form-section-heading">
            <h3>Invalid parameters</h3>
          </div>
        </div>
      </Col>
    </Row>
  );
};
