import { Record } from "immutable";
import { Nullable } from ".";
import { fromJSON as userFromJSON, IUserData, IUserRecord, UserRecord } from "./UserRecord";

export const fromJSON = (json: Partial<IJobAssignmentData>): JobAssignmentRecord => {
  const data: IJobAssignmentRecord = json as any as IJobAssignmentRecord;

  if (json.user) {
    if (json.user.org) {
      delete json.user.org;
    }
    data.user = userFromJSON(json.user) as any;
  }

  return new JobAssignmentRecord(data);
};

export const toJSON = (jobAssignment: JobAssignmentRecord): IJobAssignmentData => {
  const json = jobAssignment.toJSON() as any as IJobAssignmentData;

  if (json.id <= 0) {
    delete json.id;
  }

  return json;
};

export interface IJobAssignmentData {
  id: number;
  status: string;
  status_changed: Date;
  user: IUserData;
  user_id?: number;

  created_at: Date;
  updated_at: Date;
}

export interface IJobAssignmentRecord {
  id: Nullable<number>;
  status: Nullable<string>;
  status_changed: Nullable<Date>;
  user: Nullable<UserRecord>;
  user_id: Nullable<number>;
  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

export const defaultJobAssignmentProps = {
  id: 0,
  status: null,
  status_changed: null,
  user: null,
  user_id: null,
  created_at: null,
  updated_at: null,
};

export class JobAssignmentRecord
  extends Record<IJobAssignmentRecord>(defaultJobAssignmentProps)
  implements IJobAssignmentRecord
{
  public readonly id!: number;
  public readonly status!: string;
  public readonly status_changed!: Date;
  public readonly user!: UserRecord;
  public readonly user_id!: number;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IJobAssignmentRecord>) {
    values ? super(values) : super();
  }
}

export const emptyJobAssignmentValue = fromJSON({} as IJobAssignmentData);
