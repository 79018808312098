import * as documentActions from "./document.actions";
import { Map, Record, List } from "immutable";
import { DocumentRecord, fromJSON, setDisplay } from "../records/Document";
import { RootState, RootActions } from ".";
import { reducer as paginationReducer } from "app2/src/reducers/pagination.reducer";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { fetch, loaded, receive, reset } from "app2/src/reducers/Reducer";

export const DocumentStateRecord = Record({
  byId: Map<number, DocumentRecord>(),
  lastSavedById: Map<number, DocumentRecord>(),
  unsavedId: -1,
});

export const initialState = DocumentStateRecord();

export type DocumentState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "documents";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let document: DocumentRecord;
  switch (action.type) {
    // SINGLE
    case documentActions.FETCH_DOCUMENT:
      return fetch(state, model, fromJSON({ id: action.payload.id }));

    case documentActions.RECEIVE_DOCUMENT:
      document = fromJSON({ ...action.payload.document });
      return receive(state, model, document);

    case documentActions.RESET_DOCUMENT:
      return reset(state, model, action.payload.id);

    case documentActions.SET_DOCUMENT_LOADED:
      return loaded(state, model, action.payload.id);

    case documentActions.DESTROY_DOCUMENT:
      state = paginationReducer(state, paginationActions.Actions.removeId(action.payload.id, "document"));
      const base64 = state.getIn(["documents", "byId", action.payload.id, "base64"]);
      if (base64) {
        URL.revokeObjectURL(base64);
      }
      return state.deleteIn(["documents", "byId", action.payload.id]);

    case documentActions.EDIT_DOCUMENT:
      return state.setIn([model, "byId", action.payload.id, action.payload.name], action.payload.value);

    case documentActions.EDIT_BOOLEAN:
      state = state.setIn(["documents", "byId", action.payload.id, action.payload.name], action.payload.value);

      const record = state.getIn(["documents", "byId", action.payload.id]);
      return state.setIn(["documents", "byId", action.payload.id, "display"], setDisplay(record));

    // MULTIPLE
    case documentActions.FETCH_DOCUMENTS:
      const { ids } = action.payload;
      ids.forEach((id) => {
        state = reducer(state, documentActions.Actions.fetchDocument(id));
      });
      return state;

    case documentActions.RECEIVE_DOCUMENTS:
      action.payload.documents.forEach((document) => {
        state = reducer(state, documentActions.Actions.receiveDocument(document));
      });
      return state;

    case documentActions.RECEIVE_DOCUMENTS_BY_ID:
      state = reducer(state, documentActions.Actions.receiveDocuments(action.payload.documents));
      const responseIds = List(_.pluck(action.payload.documents, "id"));
      action.payload.ids
        .filter((id) => !responseIds.includes(id))
        .forEach((deletedId) => {
          state = reducer(state, documentActions.Actions.receiveDocument({ id: deletedId }));
        });

      return state;

    case documentActions.SET_DOCUMENTS_LOADED:
      action.payload.ids.forEach((id) => {
        state = reducer(state, documentActions.Actions.setDocumentLoaded(id));
      });

      return state;

    case documentActions.BATCH_EDIT_BOOLEAN:
      action.payload.documentIds.forEach((id) => {
        state = reducer(state, documentActions.Actions.editBoolean(id, action.payload.name, action.payload.value));
      });
      return state;

    case documentActions.BATCH_EDIT_FOLDER_ID:
      action.payload.documentIds.forEach((id) => {
        state = state.setIn(["documents", "byId", id, "folder_id"], action.payload.folderId);
      });
      return state;

    case documentActions.BATCH_REMOVE_IDS:
      action.payload.documentIds.forEach((id) => {
        state = paginationReducer(state, paginationActions.Actions.removeId(id, "document"));
      });

      return state;

    // ESTIMATE
    case documentActions.DUPLICATE_LINE_ITEM_DOCUMENTS:
      const lineItem = state.getIn(["estimateLineItems", "byId", action.payload.lineItemId]);

      let docIds = List();
      let unsavedId = state.getIn(["documents", "unsavedId"]);
      action.payload.docIds.map((docId: number) => {
        let doc = state.getIn(["documents", "byId", docId]);
        doc = doc.set("id", unsavedId);
        docIds = docIds.push(unsavedId);
        state = state.setIn(["documents", "byId", doc.id], doc);
        unsavedId -= 1;
      });

      return state
        .setIn(["estimateLineItems", "byId", lineItem.id, "document_ids"], docIds)
        .setIn(["documents", "unsavedId"], unsavedId);

    default:
      return state;
  }
};
