import { Record } from "immutable";

export const fromJSON = (json: Partial<IJobQueryStateData>): JobQueryStateRecord => {
  const record: IJobQueryStateRecord = { ...(json as any) };

  if (record.id === undefined) {
    record.id = record.value;
  }
  return new JobQueryStateRecord(record);
};

export interface IJobQueryStateData {
  id: string;
  label: string;
  value: string;
  color: string;
}

export interface IJobQueryStateRecord {
  id: string;
  label: string;
  value: string;
  color: string;
}

export const defaultJobQueryState = {
  id: "",
  label: "",
  value: "",
  color: "",
};

export class JobQueryStateRecord
  extends Record<IJobQueryStateRecord>(defaultJobQueryState)
  implements IJobQueryStateRecord {}
