import * as React from "react";
import { RootState } from "app2/src/reducers";
import { jobOverviewEstimates } from "app2/src/selectors/estimate.selectors";
import { currentJobId, selectedEstimateId as selectedEstimateIdSelector } from "app2/src/selectors/job.selectors";
import { getTitle } from "app2/src/selectors/org.selectors";
import { useDispatch, useSelector } from "react-redux";
import MakePaymentButton from "../../PaymentsModal/MakePaymentButton";
import { JobTabTitle } from "../JobTabTitle";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { Button, Col, Row } from "react-bootstrap";
import { EstimateOverviewTable } from "../../Estimate/EstimateOverviewTable";
import * as jobActions from "app2/src/reducers/job.actions";
import SpinnerComponent from "../../SpinnerComponent";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { StoreRegistry } from "app2/src/storeRegistry";
import { push } from "connected-react-router/immutable";
import { useTracking } from "react-tracking";
import { contractTitle as contractTitleSelector } from "app2/src/selectors/org.selectors";
import { pathname } from "app2/src/selectors/router.selectors";

export const SelectEstimate: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  // Selectors
  const path = useSelector(pathname);
  const jobId = useSelector(currentJobId);
  const estimatorTitle = useSelector((state: RootState) => getTitle(state, { documentType: "estimator" }));
  const contractTitle = useSelector(contractTitleSelector);
  const estimates = useSelector((state: RootState) => jobOverviewEstimates(state, { jobId }));
  const selectedEstimateId = useSelector(selectedEstimateIdSelector);

  // Local State
  const [loading, setLoading] = React.useState(false);

  // Methods
  const load = async () => {
    setLoading(true);
    await dispatch(estimateActions.AsyncActions.getJobEstimates(jobId));
    setLoading(false);
  };

  const onClickHandler = (estimateId: number) => {
    dispatch(push(path + "/" + estimateId));
    dispatch(jobActions.Actions.setSelectedEstimateId(estimateId));
  };

  const goToEstimates = () => {
    trackEvent({ action: "create estimate" });

    const $rootScope: RsfRootScope = StoreRegistry.get("$rootScope");
    dispatch(push("/jobs/" + jobId + "/estimates"));
    $rootScope.$digest();
  };

  React.useEffect(() => {
    if (!selectedEstimateId) load();
  }, []);

  return (
    <>
      <SpinnerComponent localProperty={loading} />
      <JobTabTitle title={contractTitle}>
        <div className="float-right">
          <MakePaymentButton />
        </div>
      </JobTabTitle>
      <Row>
        <Col>
          <h2>Select a {estimatorTitle}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {estimates?.size > 0 ? (
            <EstimateOverviewTable estimates={estimates} onClickHandler={onClickHandler} />
          ) : (
            <div className="form-section blank-state">
              <img src="/assets/images/icons-large/estimates.022a621b.png" />
              <h2>No estimates added. Let's add one.</h2>
              <Button onClick={goToEstimates}>Go to {estimatorTitle}</Button>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
