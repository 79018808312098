import { IOrg, IOrgResource } from "app/src/Models/Org";
import { IUserResource } from "app/src/Models/User";
import { IImageResource } from "app/src/Models/Image";
import { IOrgData, includeCheck, fromJSON as orgFromJSON } from "app2/src/records/OrgRecord";
import { orgService } from "app2/src/api/org.service";

export interface IOrgFetcherService {
  $q: ng.IQService;
  Org: IOrgResource;
  org: IOrg;
  orgPromise: ng.IPromise<IOrg>;

  loadOrg(id: number): ng.IPromise<IOrg>;
  reloadOrg(id: number): ng.IPromise<IOrg>;
  getParent(id: number): ng.IPromise<IOrg>;
  getTree(id: number, object: number): ng.IPromise<any>;
  save(org: IOrg): ng.IPromise<IOrg>;
  resetState();
  checkState(): boolean;
}

export class OrgFetcherService implements IOrgFetcherService {
  public org: IOrg;
  public orgPromise: ng.IPromise<IOrg>;
  private tree: any = [];
  private notInTree: any = [];
  private treeDeferred: ng.IPromise<any>[] = [];
  private _originalState: any;

  public static $inject = ["Org", "User", "Image", "$q"];

  constructor(
    public Org: IOrgResource,
    public User: IUserResource,
    public Image: IImageResource,
    public $q: ng.IQService,
  ) {}

  public loadOrg(id: number): ng.IPromise<IOrg> {
    const reduxOrg = orgService.getStoredOrg(id);
    orgService.setCurrentOrgId(id);

    if (reduxOrg) {
      if (reduxOrg.get("loading") && this.orgPromise) {
        return this.orgPromise;
      } else if (includeCheck(reduxOrg, orgService.orgIncludes)) {
        this.org = this.Org.fromJSON(reduxOrg.toJS());
        this.orgPromise = this.$q.resolve(this.org);
        this.resetState();
        return this.orgPromise;
      }
    }
    return this.reloadOrg(id);
  }

  public save(org: IOrg): ng.IPromise<IOrg> {
    this.orgPromise = this.reduxSave(org).then((org: IOrg) => {
      this.org = org;
      this.resetState();
      return this.org;
    });
    return this.orgPromise;
  }

  public reloadOrg(id: number): ng.IPromise<IOrg> {
    this.orgPromise = this.get(id, true).then((org: IOrg) => {
      this.org = org;
      this.resetState();
      return this.org;
    });
    return this.orgPromise;
  }

  public getParent(id: number): ng.IPromise<IOrg> {
    const reduxOrg = orgService.getStoredOrg(id);

    if (reduxOrg) {
      return this.$q.resolve(this.Org.fromJSON(reduxOrg.toJS()));
    } else {
      return this.get(id).then((org: IOrg) => {
        return org;
      });
    }
  }

  public getTree(id: number, childID: number): ng.IPromise<any> {
    const deferred = this.$q.defer();
    return this.$q.all(this.treeDeferred).then(() => {
      if (_.contains(this.tree, childID)) {
        deferred.resolve(true);
        return deferred.promise;
      } else if (_.contains(this.notInTree, childID)) {
        deferred.resolve(false);
        return deferred.promise;
      } else {
        const tree = this.Org.tree({ id: id, child: childID }).$promise.then((resp: any) => {
          if (resp.id) {
            this.tree.push(resp.id);
            return true;
          } else {
            this.notInTree.push(childID);
            return false;
          }
        });
        this.treeDeferred.push(tree);
        return tree;
      }
    });
  }

  public resetState() {
    this._originalState = angular.toJson(this.org);
  }

  public checkState(): boolean {
    if (!this.org) {
      return false;
    }
    return this._originalState !== "" && this._originalState !== angular.toJson(this.org);
  }

  protected get(id: number, set_current_org = false): ng.IPromise<IOrg> {
    const orgPromise = orgService.getOrg(id, set_current_org);
    return this.setupOrg(orgPromise);
  }

  protected reduxSave(org: IOrg): ng.IPromise<IOrg> {
    const orgData: IOrgData = JSON.parse(JSON.stringify(org));
    const orgPromise = orgService.updateOrg(orgFromJSON(orgData));
    return this.setupOrg(orgPromise);
  }

  protected setupOrg(orgPromise): ng.IPromise<IOrg> {
    const deferred = this.$q.defer();

    orgPromise.then(
      (orgData: IOrgData) => {
        deferred.resolve(this.Org.fromJSON(orgFromJSON(orgData).toJS()));
      },
      (errors: string[]) => {
        console.error(errors);
        deferred.reject(errors);
      },
    );

    return deferred.promise as ng.IPromise<IOrg>;
  }
}
