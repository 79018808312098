import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import * as paysimpleActions from "app2/src/reducers/integrations/paysimple.actions";
import * as cardconnectActions from "app2/src/reducers/integrations/cardconnect.actions";
import { AuthorizePaySimple } from "./AuthorizePaySimple";
import { AuthorizeCardConnect } from "./CardConnect/AuthorizeCardConnect";

const mapStateToProps = (state: RootState, ownProps: IWrapperAuthorizeProps) => {
  return {
    paySimpleAuth: state.getIn(["integrations", "paysimple", "authorization", ownProps.resolve.id]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IWrapperAuthorizeProps) => {
  return {
    loadAuthorizationPaySimple: (orgId: number) => {
      return dispatch(paysimpleActions.AsyncActions.getAuthorization(orgId));
    },
    authorizePaySimple: (orgId: number) => {
      return dispatch(paysimpleActions.AsyncActions.authorize(orgId));
    },
    loadAuthorizationCardConnect: (orgId: number) => {
      return dispatch(cardconnectActions.AsyncActions.getAuthorization(orgId));
    },
    authorizeCardConnect: (orgId: number) => {
      return dispatch(cardconnectActions.AsyncActions.authorize(orgId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IWrapperAuthorizeProps {
  resolve: { id: number; service: "PaySimple" | "CardConnect" };
  modalInstance: { close: () => void };
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IWrapperAuthorizeProps;

class WrapperAuthorize extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  public closeModal() {
    const { modalInstance } = this.props;

    modalInstance.close();
  }

  public render() {
    const {
      resolve,
      authorizePaySimple,
      authorizeCardConnect,
      loadAuthorizationPaySimple,
      loadAuthorizationCardConnect,
      paySimpleAuth,
    } = this.props;

    if (resolve.service === "PaySimple")
      return (
        <AuthorizePaySimple
          orgId={resolve.id}
          service={resolve.service}
          authorization={paySimpleAuth}
          authorize={authorizePaySimple}
          loadAuthorization={loadAuthorizationPaySimple}
          close={this.closeModal}
        />
      );
    if (resolve.service === "CardConnect")
      return (
        <AuthorizeCardConnect
          orgId={resolve.id}
          close={this.closeModal}
          authorize={authorizeCardConnect}
          loadAuthorization={loadAuthorizationCardConnect}
        />
      );
  }
}

export default connector(WrapperAuthorize);
