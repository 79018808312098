import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IRecipientData>): RecipientRecord => {
  const record: IRecipientRecord = { ...(json as any) };

  return new RecipientRecord(record);
};

export interface IRecipientData {
  id: number;
  signature_guid: string;
  status: string;
  rs_url: string;
  signer: Nullable<string>;
  name: string;
  email: string;
  required: boolean;
  role_id: string;
}

export interface IRecipientRecord {
  id: Nullable<number>;
  signature_guid: Nullable<string>;
  status: Nullable<string>;
  rs_url: Nullable<string>;
  signer: Nullable<string>;
  name: Nullable<string>;
  email: Nullable<string>;
  required: boolean;
  included: boolean;
  role_id: string;
}

export const defaultRecipientProps = {
  id: 0,
  signature_guid: null,
  status: "created",
  rs_url: null,
  signer: "",
  name: "",
  email: "",
  required: false,
  included: true,
  role_id: "",
};

export class RecipientRecord extends Record<IRecipientRecord>(defaultRecipientProps) implements IRecipientRecord {}
