import { IJob, IJobResource } from "app/src/Models/Job";
import { JobFetcherService } from "app/src/Jobs/JobFetcherService";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IBaseConfig } from "app/src/Common/IBaseConfig";

export class ScreenShareHostCtrl {
  public job: IJob;
  public sendInvite = false;

  private _sendInviteSubscribe: () => void;

  public static $inject = ["$stateParams", "JobFetcher", "Job", "$rootScope", "BaseConfig", "$scope"];

  constructor(
    public $stateParams: ng.ui.IStateParamsService,
    public JobFetcher: JobFetcherService,
    public Job: IJobResource,
    public $rootScope: RsfRootScope,
    public BaseConfig: IBaseConfig,
    private $scope: ng.IScope,
  ) {
    this.$rootScope.pageTitle = `DO NOT SCREEN SHARE - ${BaseConfig.APP_NAME} Video Call`;

    $scope.$on("$destroy", () => {
      this._sendInviteSubscribe();
    });
  }
}
