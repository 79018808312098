import * as paymentRequestActions from "./paymentRequest.actions";
import { Map, Record } from "immutable";
import { IPaymentRequestData, PaymentRequestRecord, fromJSON } from "../records/PaymentRequest";
import { RootActions, RootState } from ".";
import { fetch, loaded, receive } from "app2/src/reducers/Reducer";
import { Actions } from "app2/src/reducers/paymentRequest.actions";
import { currentJob } from "app2/src/selectors/job.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { currentUserId } from "app2/src/selectors/user.selectors";
import { paymentAuth } from "app2/src/selectors/payment.selectors";

export const PaymentRequestStateRecord = Record({
  byId: Map<number, PaymentRequestRecord>(),
  lastSavedById: Map<number, PaymentRequestRecord>(),
});

export const initialState = PaymentRequestStateRecord();

export type PaymentRequestState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "paymentRequests";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let paymentRequest: PaymentRequestRecord;
  switch (action.type) {
    case paymentRequestActions.FETCH_PAYMENT_REQUEST:
      return fetch(state, model, fromJSON({ id: action.payload.id }));

    case paymentRequestActions.RECEIVE_PAYMENT_REQUEST:
      paymentRequest = fromJSON(action.payload.paymentRequest);
      return receive(state, model, paymentRequest);

    case paymentRequestActions.SET_LOADED:
      return loaded(state, model, action.payload.id);

    case paymentRequestActions.INIT_PAYMENT_REQUEST:
      const job = currentJob(state);
      const orgId = currentOrgId(state);
      const user_id = currentUserId(state);
      const auth = paymentAuth(state, { orgId });
      const paymentRequestData = {
        id: -1,
        emails: job.email,
        sms: job.getIn(["phone_numbers", 0, "number"], ""),
        job_id: job.id,
        user_id,
        org_id: orgId,
        kind: auth?.get("card_connect") ? "cardconnect" : "paysimple",
      } as IPaymentRequestData;
      state = reducer(state, Actions.receivePaymentRequest(paymentRequestData));
      return reducer(
        state,
        Actions.receivePaymentRequest({
          ...paymentRequestData,
          id: -2,
        }),
      );

    case paymentRequestActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);

    case paymentRequestActions.RECEIVE_PAYMENT_REQUESTS:
      action.payload.paymentRequests.forEach((paymentRequest) => {
        state = reducer(state, Actions.receivePaymentRequest(paymentRequest));
      });
      return state;

    default:
      return state;
  }
};
