import * as paymentsActions from "./payment.actions";
import { Record, Map, List } from "immutable";
import { PaymentRecord, fromJSON as PaymentFromJSON } from "../records/PaymentRecord";
import { PaymentAuthorizationRecord } from "../records/PaymentAuthorizationRecord";

export const Page = Record({
  payments: List([]),
});

export const PaymentPageRecord = Record({
  loading: false,
  payments: List(),
  pagination: {},
});

export const PaymentStateRecord = Record({
  paymentsById: Map<number, PaymentRecord>(),
  pages: Map<number, typeof PaymentPageRecord>(),
  params: {
    page: 1,
    job_id: null,
  },
  total_count: 0,
  total_pages: 0,
  authByOrgId: Map<number, PaymentAuthorizationRecord>(),
  makingPayment: false,
  lastPayment: null,
  providerLoading: false,
  providerValid: false,
  providerSubmitting: false,
  sendingReceipt: false,
  error: null,
});

export const initialState = PaymentStateRecord();
export type PaymentState = typeof initialState;

export const reducer = (state = initialState, action: paymentsActions.Actions): PaymentState => {
  switch (action.type) {
    case paymentsActions.FETCH_PAGE:
      if (!state.getIn(["pages", action.payload.page])) {
        return state
          .set("params", action.payload)
          .setIn(["pages", action.payload.page], PaymentPageRecord({ loading: true }));
      }
      return state
        .set("params", action.payload)
        .setIn(["pages", action.payload.page, "loading"], true)
        .setIn(["pages", action.payload.page, "payments"], List());
    case paymentsActions.RESET_PAGES:
      return state.set("pages", Map());
    case paymentsActions.RECEIVE_PAGE:
      _.each(action.payload.payments, (payment) => {
        state = state.setIn(["paymentsById", payment.id], PaymentFromJSON(payment));
      });
      return state
        .setIn(["pages", action.payload.page, "payments"], List(_.pluck(action.payload.payments, "id")))
        .setIn(["pages", action.payload.page, "loading"], false)
        .setIn(["pages", action.payload.page, "pagination"], action.payload.meta.pagination)
        .set("total_count", action.payload.meta.pagination.total_count)
        .set("total_pages", action.payload.meta.pagination.total_pages);
    case paymentsActions.RECEIVE_PAYMENT:
      return state
        .setIn(["paymentsById", action.payload.id], action.payload)
        .setIn(["paymentsById", action.payload.id, "loading"], false)
        .set("makingPayment", false)
        .set("lastPayment", action.payload);
    case paymentsActions.CLEAR_LAST_PAYMENT:
      return state.set("lastPayment", null);
    case paymentsActions.POST_JOB:
      return state.setIn(["paymentsById", action.payload, "loading"], true);
    case paymentsActions.FETCH_AUTHORIZED:
      if (state.getIn(["authByOrgId", action.payload.orgId])) {
        return state.setIn(["authByOrgId", action.payload.orgId, "loading"], true);
      }
      return state.setIn(["authByOrgId", action.payload.orgId], new PaymentAuthorizationRecord({ loading: true }));
    case paymentsActions.RECEIVE_AUTHORIZED:
      return state.setIn(["authByOrgId", action.payload.orgId], action.payload.auth);
    case paymentsActions.SET_PAYMENT_ERROR:
      return state.set("error", action.payload.error).set("makingPayment", false);
    case paymentsActions.SET_PROVIDER_LOADING:
      return state.set("providerLoading", action.payload.loading);
    case paymentsActions.SET_PROVIDER_VALID:
      return state.set("providerValid", action.payload.valid);
    case paymentsActions.SET_PROVIDER_SUBMITTING:
      return state.set("providerSubmitting", action.payload.submitting);
    case paymentsActions.MAKE_PAYMENT:
      return state.set("makingPayment", true);
    case paymentsActions.SET_SENDING_RECEIPT:
      return state.set("sendingReceipt", action.payload.sending);
    default:
      return state;
  }
};
