import { Record, List, Map, fromJS } from "immutable";
import { PaySimplePaymentKeys } from "../components/Payments/PaySimple/PaySimple.service";

export const fromJSON = (json: Partial<IPaymentData>): PaymentRecord => {
  if (json.payload) {
    if (json.payload.receipt && typeof json.payload.receipt === "string") {
      try {
        json.payload.receipt = JSON.parse(json.payload.receipt);
      } catch (_e) {}
    }
    json.payload = fromJS(json.payload);
  }

  return new PaymentRecord(json);
};

export type PaymentChargeType = "ach" | "credit_card";

export interface IMakePaymentData {
  first_name: string;
  last_name: string;
  description: string;
  zipCode?: string;
  charge_type?: PaymentChargeType;
  postal?: string;
  amount: number;
  email: string;
  user_id: number;
  job_id: number;
  estimate_id?: number;
}

export interface IPaymentData {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  description: string;

  charge_type: PaymentChargeType;
  amount: number;
  status: string;
  kind: string;
  payment_date: Date;
  is_debit: boolean;
  payload: any;

  job_id: number;
  org_id: number;
  user_id: number;
  estimate_id: number;
  pay_simple_customer_id: number;

  created_at: Date;
  updated_at: Date;
}

export interface IPaymentRecord extends IPaymentData {
  payload: Map<string, PaySimplePaymentKeys>;

  loading: boolean;
  errors: List<string>;
}

const defaultPaymentProps: IPaymentRecord = {
  id: -1,
  first_name: "",
  last_name: "",
  email: "",
  description: "",
  charge_type: "credit_card",
  amount: 0,
  status: "created",
  kind: "",
  is_debit: false,
  payload: Map<string, any>(),
  payment_date: null,

  org_id: null,
  job_id: null,
  user_id: null,
  estimate_id: null,
  pay_simple_customer_id: null,

  created_at: null,
  updated_at: null,

  loading: false,
  errors: List<string>(),
};

export class PaymentRecord extends Record(defaultPaymentProps) implements IPaymentRecord {
  public id!: number;

  public charge_type!: PaymentChargeType;
  public amount!: number;
  public status!: string;
  public kind!: string;
  public payment_date!: Date;
  public is_debit!: boolean;
  public payload!: Map<string, any>;

  public org_id!: number;
  public job_id!: number;
  public user_id!: number;
  public estimate_id!: number;
  public pay_simple_customer_id!: number;

  public created_at!: Date;
  public updated_at!: Date;

  public loading!: boolean;
  public errors!: List<string>;

  public constructor(values?: Partial<IPaymentRecord>) {
    if (values) {
      if (values.amount) {
        values.amount = parseFloat(values.amount as any);
      }
      super(values);
    } else {
      super();
    }
  }
}
