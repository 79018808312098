import * as ng from "angular";
import * as angulartics from "angulartics";
import { IRepository } from "app/src/Common/Repository";
import { IFlash } from "app/src/Common/FlashService";
import { ISession } from "app/src/Common/SessionService";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { IAddress } from "app/src/Models/Address";
import { Pool, IPool } from "app/src/Models/Pool";
import { useSelector } from "app2/src/storeRegistry";
import { pools } from "app2/src/selectors/pool.selectors";
import { MeasurementRecord } from "app2/src/records/Measurement";
import { toLatLng, fromJSON } from "app2/src/records/Address";

class PoolToolCtrl implements ng.IComponentController {
  [s: string]: any;
  public close: (params: any) => void;
  public dismiss: () => void;
  public resolve: { measurement: MeasurementRecord; address: IAddress };
  public measurement: MeasurementRecord;
  public new_geojson: any = {};
  public geojson: any = {};
  public address: IAddress;
  public pools: Pool[];
  public activeTab = 0;

  public static $inject = ["$scope", "Repository", "Session", "Flash", "BaseConfig", "$analytics", "$sce"];

  constructor(
    public $scope: ng.IScope,
    public Repository: IRepository,
    public Session: ISession,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
    public $analytics: angulartics.IAnalyticsService,
    public $sce: ng.ISCEService,
  ) {}

  public $onInit() {
    this.measurement = this.resolve.measurement;
    const poolIds = this.measurement.get("pool_ids");
    this.pools = useSelector((state) => pools(state, { poolIds })).toJS();
    this.address = this.resolve.address;
    this.geojson = this.pools.map((d) => {
      d.metadata.properties.id = d.id;
      return d.metadata;
    });
  }

  public save() {
    this.close({ $value: { pools: this.pools } });
  }

  public cancelBtn() {
    this.trackEvent("cancel button", {
      Category: "PoolToolComponent",
    });
    this.dismiss();
  }

  public measurementAdded(data: { area: number; perimeter: number; points: Array<any> }) {
    const pool = Pool.fromJSON({
      area: Math.ceil(data.area),
      perimeter: Math.ceil(data.perimeter),
      average_depth: 0,
    });

    const newPoints = _.find(data.points, (geojson: any) => {
      return _.every(this.pools, (d) => d.id !== geojson.properties.id);
    });

    pool.id = newPoints.properties.id;
    pool.name = newPoints.properties.name;
    pool.designator = pool.name;
    pool.metadata = newPoints;
    this.pools.push(pool);
  }

  public averageDepthChanges(pool: IPool) {
    pool.volume = _.round(Math.ceil((pool.average_depth || 0) * pool.area) * 7.48, 0);
  }

  public exportData(data) {
    _.each(data.points, (geojson: any) => {
      const pool = _.find(this.pools, (d) => d.id === geojson.properties.id);
      if (!pool) {
        return;
      }
      pool.name = geojson.properties.name;
      pool.designator = pool.name;
      pool.metadata = geojson;
    });

    const ids = _.pluck(this.pools, "id");

    _.each(ids, (id) => {
      // if none of the existing geojsons match this id, it needs deleted;
      if (_.every(data.points, (geojson: any) => geojson.properties.id !== id)) {
        // if it's not a number, it's a temp id and we can just remove;
        if (_.isNaN(parseInt(id as any))) {
          this.pools = _.select(this.pools, (d) => d.id !== id);
        } else {
          // else it's a real id and we need to mark _destroy
          const pool = _.find(this.pools, (d) => d.id === id);
          (pool as IPool)._destroy = true;
        }
      }
    });
    this.$scope.$digest();
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        measurement: this.measurement.id,
      }),
    );
  }

  public isValidAddress(address: IAddress): boolean {
    const addressRecord = fromJSON(address);
    return !_.isNull(toLatLng(addressRecord));
  }

  private _addPool(points: any) {}
}

export class PoolToolComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/RsfMap/pool_tool_component.html";
  public bindings: any = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };

  constructor() {
    this.controller = PoolToolCtrl;
  }
}
