import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { useTracking } from "react-tracking";
import { selectedEstimateId as selectedEstimateIdSelector } from "app2/src/selectors/job.selectors";
import { SelectEstimate } from "./SelectEstimate";
import { EstimateContracts } from "./EstimateContracts";
import { replace } from "connected-react-router/immutable";

export const ContractsTab: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const routeMatch = useRouteMatch<{ estimateId: string }>(`${match.url}/:estimateId`);
  const { Track } = useTracking<any>({
    component: "ContractsTab",
    category: "Contracts",
  });

  // Selectors
  const selectedEstimateId = useSelector(selectedEstimateIdSelector);

  // Lifecycle
  React.useEffect(() => {
    if (selectedEstimateId && !routeMatch?.params?.estimateId && selectedEstimateId !== "new") {
      dispatch(replace(`${match.url}/${selectedEstimateId}`));
    }
  }, [selectedEstimateId]);

  return (
    <Track>
      <Switch>
        <Route path={`${match.url}/:estimateId`} component={EstimateContracts} />
        <Route path={`${match.url}`} component={SelectEstimate} />
      </Switch>
    </Track>
  );
};
