import * as estimateActions from "./estimate.actions";
import * as estimateGroupActions from "./estimateGroup.actions";
import { reducer as estimateGroupReducer } from "./estimateGroup.reducer";
import { List, Map, Record } from "immutable";
import { EstimateRecord, fromJSON, removeDiscount, addDiscount, updateDiscount } from "../records/Estimate";
import { EstimateOverviewRecord, fromJSON as overviewFromJSON } from "../records/EstimateOverview";
import { RootState, RootActions } from ".";
import {
  estimate,
  toEstimateOverview,
  updateBaseTotal,
  updateDiscountTotal,
  updateTaxTotal,
  updatePaymentTermTotal,
} from "../selectors/estimate.selectors";
import { fromJSON as paymentTermFromJSON } from "app2/src/records/PaymentTerm";
import * as estimateFinanceOptionActions from "./estimateFinanceOption.actions";
import * as financeOptionActions from "./org/financeOption.actions";
import * as orgActions from "./org.actions";
import * as jobActions from "./job.actions";
import { Actions as DocumentActions } from "./document.actions";
import { reducer as estimateFinanceOptionReducer } from "./estimateFinanceOption.reducer";
import { reducer as orgReducer } from "./org.reducer";
import { reducer as jobReducer } from "./job.reducer";
import { reducer as documentReducer } from "./document.reducer";
import { LandingEstimateRecord, fromJSON as landingEstimateFromJSON } from "app2/src/records/LandingEstimate";
import {
  CalculatedFinancingRecord,
  fromJSON as calculatedFinancingFromJSON,
} from "app2/src/records/CalculatedFinancing";
import { toCalculatedFinancing } from "app2/src/records/EstimateFinanceOption";
import { fetch } from "app2/src/reducers/Reducer";
import { EstimateSignedDocumentRecord } from "../records/EstimateSignedDocument";
import { EstimateCommissionRecord, fromJSON as estimateCommissionFromJSON } from "../records/EstimateCommission";
import { currentJob } from "app2/src/selectors/job.selectors";
import * as moment from "moment";
import { contractTitle } from "app2/src/selectors/org.selectors";

export const EstimateStateRecord = Record({
  estimateOverviewsByJob: Map<number, List<EstimateOverviewRecord>>(),
  byId: Map<number, EstimateRecord>(),
  byKey: Map<string, LandingEstimateRecord>(),
  lastSavedById: Map<number, EstimateRecord>(),
  calculatedFinancingByFinancedAmount: Map<number, Map<number, CalculatedFinancingRecord>>(),
  unsavedId: -1,
  calculatedCommission: EstimateCommissionRecord,
  errors: List<string>(),
  estimateDocumentsByJobId: Map<number, List<EstimateSignedDocumentRecord>>(),
});

export const initialState = EstimateStateRecord();

export type EstimateState = typeof initialState;

export const model = "estimates";

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let estimateRecord: EstimateRecord;
  let landingEstimateRecord: LandingEstimateRecord;
  switch (action.type) {
    case estimateActions.FETCH_ESTIMATE:
      return fetch(state, model, fromJSON({ id: action.payload.estimateId }));

    case estimateActions.RECEIVE_ESTIMATE:
      action.payload.estimate.groups.map((g) => {
        state = estimateGroupReducer(state, estimateGroupActions.Actions.receiveEstimateGroup(g));
      });
      const financedAmount = action.payload.estimate.payment_terms.financed_amount;
      const calculations = action.payload.estimate.finance_options.map((efo) =>
        toCalculatedFinancing(financedAmount, efo),
      );
      state = reducer(state, estimateActions.Actions.receiveCalculations(financedAmount, calculations));

      action.payload.estimate.finance_options.map((fo) => {
        state = estimateFinanceOptionReducer(
          state,
          estimateFinanceOptionActions.Actions.receiveEstimateFinanceOption(fo),
        );
      });

      estimateRecord = fromJSON({ ...action.payload.estimate, loading: false });

      return state.setIn([model, "byId", action.payload.estimate.id], estimateRecord);

    case estimateActions.SET_LAST_SAVED_ESTIMATE:
      const totEstimate = state.getIn([model, "byId", action.payload.estimateId]);
      return state.setIn([model, "lastSavedById", action.payload.estimateId], totEstimate);

    case estimateActions.RECEIVE_ESTIMATE_ERROR:
      return state.setIn([model, "errors"], List<string>(action.payload.errors));

    case estimateActions.RECEIVE_ESTIMATE_PDF: {
      const { estimateId, data, documentId } = action.payload;
      const tempDocumentId = documentId || -1;
      const job = currentJob(state);
      const name = job.name + " - " + moment().format("MM-DD-Y HH:mm") + " - " + contractTitle(state) + ".pdf";

      state = documentReducer(
        state,
        DocumentActions.receiveDocument({
          id: tempDocumentId,
          name,
          base64: URL.createObjectURL(data),
          documentable_id: job.get("id"),
          documentable_type: "Job",
        }),
      );

      return state.updateIn([model, "byId", estimateId], (est) => {
        if (!est) {
          est = fromJSON({ id: estimateId });
        }
        return est.merge({ tempDocumentId, loading: false });
      });
    }
    case estimateActions.REMOVE_ESTIMATE_PDF: {
      const { estimateId } = action.payload;
      return state.updateIn([model, "byId", estimateId], (est) => est.set("tempDocumentId", null));
    }
    case estimateActions.SET_ESTIMATE_LOADED:
      return state.setIn([model, "byId", action.payload.estimateId, "loading"], false);

    case estimateActions.FETCH_JOB_ESTIMATES:
      return state.setIn([model, "estimateOverviewsByJob", action.payload], List<EstimateOverviewRecord>());

    case estimateActions.RECEIVE_JOB_ESTIMATES:
      const overviews = action.payload.estimates.map((e) => overviewFromJSON(e));

      return state.setIn([model, "estimateOverviewsByJob", action.payload.jobId], List(overviews));

    case estimateActions.RECEIVE_JOB_ERRORS:
      return state.setIn([model, "errors"], List<string>(action.payload.errors));

    case estimateActions.UPDATE_ESTIMATE_OVERVIEW:
      if (state.getIn([model, "estimateOverviewsByJob", action.payload.estimate.job_id])) {
        return state.updateIn(
          [model, "estimateOverviewsByJob", action.payload.estimate.job_id],
          (list: List<EstimateOverviewRecord>) => {
            const idx = list.findIndex((e) => e.id === action.payload.estimate.id);

            if (idx >= 0) {
              return list.set(idx, toEstimateOverview(state, { estimateId: action.payload.estimate.id }));
            }

            return list.push(toEstimateOverview(state, { estimateId: action.payload.estimate.id }));
          },
        );
      }
      return state;

    case estimateActions.FETCH_LANDING_ESTIMATE:
      if (state.getIn([model, "byKey", action.payload.key])) {
        return state.setIn([model, "byKey", action.payload.key, "loading"], true);
      }

      landingEstimateRecord = landingEstimateFromJSON({ loading: true });

      return state.setIn([model, "byKey", action.payload.key], landingEstimateRecord);

    case estimateActions.RECEIVE_LANDING_ESTIMATE:
      landingEstimateRecord = landingEstimateFromJSON(action.payload.response);

      state = reducer(state, estimateActions.Actions.receiveEstimate(action.payload.response.estimate));

      state = jobReducer(state, jobActions.Actions.receiveJob(action.payload.response.job));

      state = orgReducer(
        state,
        financeOptionActions.Actions.receiveFinanceOption(action.payload.response.finance_option),
      );

      state = orgReducer(state, orgActions.Actions.setOrg(action.payload.response.org));

      return state.setIn([model, "byKey", action.payload.key], landingEstimateRecord);

    case estimateActions.SET_LANDING_LOADED:
      return state.setIn([model, "byKey", action.payload.key, "loading"], false);

    case estimateActions.ADD_PT_TEMPLATE:
      const template = action.payload.template;
      const newPaymentTerms = paymentTermFromJSON({
        id: template.id,
        name: template.name,
        description: template.description,
        payment_term_items: template.breakdown.items.toJS(),
      });
      return state.setIn([model, "byId", action.payload.estimateId, "payment_terms"], newPaymentTerms);

    case estimateActions.ADD_PT_ITEM:
      const paymentTerms = state.getIn([
        model,
        "byId",
        action.payload.estimateId,
        "payment_terms",
        "payment_term_items",
      ]);
      const addNewPaymentTerms = paymentTerms.push(action.payload.item);
      return state.setIn(
        [model, "byId", action.payload.estimateId, "payment_terms", "payment_term_items"],
        addNewPaymentTerms,
      );

    case estimateActions.UPDATE_PT_ITEM:
      const currentPaymentTerms = state.getIn([
        model,
        "byId",
        action.payload.estimateId,
        "payment_terms",
        "payment_term_items",
      ]);
      const index = currentPaymentTerms.findIndex((record) => record.uuid === action.payload.item.uuid);
      const updatedPaymentTerms = currentPaymentTerms.set(index, action.payload.item);
      return state.setIn(
        [model, "byId", action.payload.estimateId, "payment_terms", "payment_term_items"],
        updatedPaymentTerms,
      );

    case estimateActions.REMOVE_PT_ITEM:
      const existingPaymentTerms = state.getIn([
        model,
        "byId",
        action.payload.estimateId,
        "payment_terms",
        "payment_term_items",
      ]);
      const rptPaymentTerms = existingPaymentTerms.filter((pt) => pt !== action.payload.item).toList();
      return state.setIn(
        [model, "byId", action.payload.estimateId, "payment_terms", "payment_term_items"],
        rptPaymentTerms,
      );

    case estimateActions.REMOVE_DISCOUNT:
      estimateRecord = state.getIn([model, "byId", action.payload.estimateId]);

      return state.setIn(
        [model, "byId", action.payload.estimateId],
        removeDiscount(estimateRecord, action.payload.discount.uuid),
      );

    case estimateActions.ADD_DISCOUNT:
      estimateRecord = state.getIn([model, "byId", action.payload.estimateId]);

      return state.setIn(
        [model, "byId", action.payload.estimateId],
        addDiscount(estimateRecord, action.payload.discount),
      );

    case estimateActions.UPDATE_DISCOUNT:
      estimateRecord = state.getIn([model, "byId", action.payload.estimateId]);

      return state.setIn(
        [model, "byId", action.payload.estimateId],
        updateDiscount(estimateRecord, action.payload.discount),
      );

    case estimateActions.UPDATE_BASE_TOTAL:
      return state.setIn(
        [model, "byId", action.payload.estimateId],
        updateBaseTotal(state, { estimateId: action.payload.estimateId }),
      );

    case estimateActions.UPDATE_DISCOUNT_TOTAL:
      return state.setIn(
        [model, "byId", action.payload.estimateId],
        updateDiscountTotal(state, { estimateId: action.payload.estimateId }),
      );

    case estimateActions.UPDATE_TAX_TOTAL:
      return state.setIn(
        [model, "byId", action.payload.estimateId],
        updateTaxTotal(state, { estimateId: action.payload.estimateId }),
      );

    case estimateActions.UPDATE_PAYMENT_TERM_TOTAL:
      return state.setIn(
        [model, "byId", action.payload.estimateId],
        updatePaymentTermTotal(state, { estimateId: action.payload.estimateId }),
      );

    case estimateActions.DUPLICATE_ESTIMATE:
      let record = state.getIn([model, "byId", action.payload.estimateId]);
      const unsavedId = state.getIn([model, "unsavedId"]);
      record = record.set("id", unsavedId);
      record = record.set("name", `Copy of ${record.name}`);
      state = state.setIn([model, "byId", record.id], record);
      state = estimateGroupReducer(state, estimateGroupActions.Actions.duplicateGroups(record.id, record.group_ids));
      state = estimateFinanceOptionReducer(
        state,
        estimateFinanceOptionActions.Actions.duplicateEstimateFinanceOptions(record.id, record.finance_option_ids),
      );

      return state.setIn([model, "unsavedId"], unsavedId - 1);

    case estimateActions.EDIT_NAME:
      return state.setIn([model, "byId", action.payload.estimateId, "name"], action.payload.name);

    case estimateActions.ADD_FINANCE_OPTION_ID:
      estimateRecord = estimate(state, { estimateId: action.payload.estimateId });
      const addFinanceOptionIds = estimateRecord.finance_option_ids.push(action.payload.financeOptionId);
      return state.setIn([model, "byId", action.payload.estimateId, "finance_option_ids"], addFinanceOptionIds);

    case estimateActions.SET_ESTIMATE_FINANCE_OPTIONS:
      estimateRecord = estimate(state, { estimateId: action.payload.estimateId });
      estimateRecord.finance_option_ids.forEach((foId) => {
        state = estimateFinanceOptionReducer(
          state,
          estimateFinanceOptionActions.Actions.calculateFinanceOption(action.payload.estimateId, foId),
        );
      });

      return state;

    case estimateActions.FETCH_CALCULATIONS:
      action.payload.financeOptionIds.forEach((id: number) => {
        state = state.setIn(
          [model, "calculatedFinancingByFinancedAmount", action.payload.financedAmount, id],
          calculatedFinancingFromJSON({ loading: true }),
        );
      });

      return state;

    case estimateActions.RECEIVE_CALCULATIONS:
      action.payload.calculations.forEach((calculation) => {
        state = state.setIn(
          [model, "calculatedFinancingByFinancedAmount", action.payload.financedAmount, calculation.finance_option_id],
          calculatedFinancingFromJSON({ ...calculation, loading: false }),
        );
      });

      return state;

    case estimateActions.SET_CALCULATIONS_ERROR:
      const error = action.payload.errors.join(", ");
      action.payload.financeOptionIds.forEach((foid) => {
        let fo = state.getIn([model, "calculatedFinancingByFinancedAmount", action.payload.financedAmount, foid]);
        fo = fo.merge({ error: error, loading: false });
        state = state.setIn([model, "calculatedFinancingByFinancedAmount", action.payload.financedAmount, foid], fo);
      });

      return state;

    case estimateActions.SET_ESTIMATE_SIGNED_DOCUMENTS:
      return state.setIn(
        [model, "estimateDocumentsByJobId", action.payload.jobId],
        action.payload.estimateSignedDocuments,
      );

    case estimateActions.FETCH_ESTIMATE_COMMISSION:
      return state.setIn([model, "calculatedCommission"], estimateCommissionFromJSON({ loading: true }));

    case estimateActions.RECEIVE_ESTIMATE_COMMISSION:
      return state.setIn(
        [model, "calculatedCommission"],
        estimateCommissionFromJSON({ ...action.payload.commissionData, loading: false }),
      );

    default:
      return state;
  }
};
