import * as React from "react";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { List } from "immutable";
import { useEffect, useRef, useState } from "react";
import { connectFC } from "app2/src/connect";
import { Container, Row, Col, Table } from "react-bootstrap";
import * as webhookActions from "app2/src/reducers/webhook.actions";
import { RsfForm } from "@tberrysoln/rsf-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { currentOrg } from "app2/src/selectors/org.selectors";
import { getPaginationByModel, pagination } from "app2/src/selectors/pagination.selectors";
import { queryParamsFromJSON } from "app2/src/records/Page";
import { IWebhookData, WebhookRecord } from "app2/src/records/Webhook";
import { WebhookRow } from "./WebhookRow";
import { Nullable } from "app2/src/records";
import { WebhookEditor } from "./WebhookEditor";
import Spinner from "app2/src/components/SpinnerComponent";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { Can } from "app2/src/components/Common/CanComponent";
import { MetaPaginationRecord } from "app2/src/records/MetaPagination";

const modelName = "webhook";

const WebhooksTab: React.FC = () => {
  // hooks
  const dispatch = useDispatch();
  const currentPage = useRef(1);
  const [editingWebhookId, setEditingWebhookId] = useState<Nullable<string>>(null);
  const [showError, setShowError] = useState(false);

  const rootPath = React.useMemo(() => ["webhooks", "byId", editingWebhookId], [editingWebhookId]);

  // selectors
  const org = useSelector<RootState, OrgRecord>(currentOrg);
  // const currentUser = useSelector<RootState, UserRecord>(currentUserSelector);
  const editingWebhook = useSelector<RootState, WebhookRecord>((state) =>
    editingWebhookId ? state.getIn(["webhooks", "byId", editingWebhookId]) : null,
  );
  const paginationRecord = useSelector<RootState, MetaPaginationRecord>((state) =>
    pagination(state, { modelName, page: currentPage.current }),
  );
  const page = useSelector<RootState, List<WebhookRecord>>(
    (state) =>
      getPaginationByModel(state, {
        path: ["webhooks", "byId"],
        modelName,
      }) as List<WebhookRecord>,
  );

  useEffect(() => {
    if (editingWebhookId !== undefined) {
      validate();
    }
  }, [editingWebhook]);

  // lifecycle
  useEffect(() => {
    queryWebhookPage(1);
  }, []);

  // methods
  const queryWebhookPage = (page: number) => {
    currentPage.current = page;
    dispatch(
      webhookActions.AsyncActions.listWebhooks(
        org.id,
        queryParamsFromJSON({
          page: page,
          per_page: 10,
        }),
      ),
    );
  };

  const handleEditClick = (webhook: WebhookRecord) => {
    setEditingWebhookId(webhook.id);
  };

  const saveEditingWebhook = async () => {
    await dispatch(webhookActions.AsyncActions.updateOrCreateWebhook(editingWebhook, org.id));
    setEditingWebhookId(null);
  };

  const addWebhook = () => {
    const emptyWebhook = {
      id: "-1",
      auth_type: "none",
      org_id: org.uuid,
      events: [],
      endpoint: "",
    } as IWebhookData;

    dispatch(webhookActions.Actions.receiveWebhook(emptyWebhook));

    setEditingWebhookId("-1");
  };

  const resetWebhook = () => {
    dispatch(webhookActions.Actions.resetWebhook(editingWebhook.id));
    setEditingWebhookId(null);
  };

  const validate = () => {
    const errors = [];

    if (!editingWebhook?.events?.some((wEvent) => wEvent?.includes(true))) {
      setShowError(true);
      errors.push({ name: "events_empty", error: " " });
    } else {
      setShowError(false);
      errors.push({ name: "events_empty" });
    }

    return errors;
  };

  return (
    <>
      <Can resource="org" permission="webhooks" inverse>
        <h2>Incorrect Permissions</h2>
      </Can>

      <Can resource="org" permission="webhooks">
        <Spinner stateProperty={["pagination", "byModel", "webhook", "pages", currentPage.current, "loading"]} />

        {!editingWebhookId ? (
          <Container fluid="xl">
            <Row>
              <Col md={6}>
                <h1 className="admin-title">Webhooks</h1>
                <p className="small">Review and configure webhook configuration</p>
              </Col>
              <Col md={6}>
                <Can resource="org" permission="webhooks">
                  <div className="pull-right">
                    <button type="button" className="btn btn-default" onClick={addWebhook}>
                      <i className="fa fa-plus"></i>&nbsp;Create
                    </button>
                  </div>
                </Can>
              </Col>
            </Row>
            {page.count() <= 0 ? (
              <Row>
                <Col>
                  <div className="form-section blank-state">
                    <h2>No Webhooks added. Let's add one.</h2>
                    <p>Click on the 'Create' button in the top right to add one.</p>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={12}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Event</th>
                        <th>Date and Time</th>
                        <th>Running</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {page.map((webhook, idx) => (
                        <WebhookRow key={idx} onEditClick={handleEditClick} webhook={webhook} orgId={org.id} />
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={6}>
                          <StandardPagination metadata={paginationRecord} pageChanged={queryWebhookPage} />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
              </Row>
            )}
          </Container>
        ) : (
          <RsfForm
            rootPath={rootPath}
            updateFormReducer={webhookActions.Actions.updateForm}
            onSubmit={saveEditingWebhook}
            validate={validate}>
            <WebhookEditor webhookId={editingWebhookId} handleCancel={resetWebhook} showError={showError} />
          </RsfForm>
        )}
      </Can>
    </>
  );
};

export default connectFC(WebhooksTab);
