import * as React from "react";
import { List, Record } from "immutable";
import { EventRecord } from "app2/src/records/Event";
import { RootState } from "app2/src/reducers";

export interface DateRange {
  start: Date;
  end: Date;
}

interface ContextProps {
  dateRange: DateRange;
  setDateRange: (newRange: DateRange) => void;
  userIds: List<number>;
  setUserIds: (newUserIds: List<number>) => void;
  events: List<EventRecord>;
  loading: boolean;
  includes: string[];
  setEventColor: (state: RootState, props: any) => string;
}

const defaultContextProps: ContextProps = {
  dateRange: { start: null, end: null },
  setDateRange: () => null,
  userIds: List(),
  setUserIds: () => null,
  events: List(),
  loading: false,
  includes: [],
  setEventColor: (state: RootState, props: any) => null,
};

export class ContextRecord extends Record(defaultContextProps) implements ContextProps {}

export const Context = React.createContext(new ContextRecord());
