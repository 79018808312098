import { ICustomProduct } from "app/src/Models/Product";
import { SnakeToCamelKeys } from "app2/src/helpers/Format";
import { Record, List } from "immutable";

export const fromJSON = (data: Partial<ICustomProduct>): CustomProductRecord => {
  const record: Partial<ICustomProductRecord> = SnakeToCamelKeys(data);

  if (data.image_ids) {
    record.imageIds = List(data.image_ids);
  }

  if (data.document_ids) {
    record.documentIds = List(data.document_ids);
  }

  return new CustomProductRecord(record);
};

export interface ICustomProductData {
  name: string;
  uuid?: string;
  description: string;
  sort_order?: number;
  price: number;
  product_price: number;
  labor_price: number;
  included?: boolean;
  quantity: number;
  uom: string;
  image_ids?: number[];
  document_ids?: number[];
  images?: any[];
  documents?: any[];
  visibility?: string;
}

export interface ICustomProductRecord {
  name: string;
  uuid: string;
  description: string;
  sortOrder: number;
  price: number;
  productPrice: number;
  laborPrice: number;
  included: boolean;
  quantity: number;
  uom: string;
  imageIds: List<number>;
  documentIds: List<number>;
  visibility: string;
}

const defaultTemplateProps: ICustomProductRecord = {
  name: "",
  uuid: "",
  description: "",
  sortOrder: 0,
  price: 0,
  productPrice: 0,
  laborPrice: 0,
  included: false,
  quantity: 0,
  uom: "",
  imageIds: List(),
  documentIds: List(),
  visibility: "always",
};

export class CustomProductRecord extends Record(defaultTemplateProps) implements ICustomProductRecord {}
