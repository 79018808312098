import { IOpening, Opening } from "app/src/Models/Opening";

export interface IWindow extends IOpening {
  clone(): IWindow;
}

export class Window extends Opening implements IWindow {
  constructor() {
    super();
  }

  public clone(): IWindow {
    return Window.fromJSON(this);
  }

  public static fromJSON = (data: any): IWindow => {
    const window = new Window();

    Opening.copyValues(window, data);

    window["kind"] = "Window";
    return window;
  };
}
