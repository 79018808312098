import * as ng from "angular";
import * as angulartics from "angulartics";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IProductOptionGroup } from "app/src/Models/ProductOptionGroup";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { IProductOption } from "app/src/Models/ProductOption";
import { AddEstimateLineItemOptionCmd } from "app/src/Commands/Estimator/AddEstimateLineItemOptionCmd";
import { IEstimate } from "app/src/Models/Estimate";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { useSelector } from "app2/src/storeRegistry";
import { cachedProductOptionGroups } from "app2/src/selectors/productOptionGroup.selectors";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { cachedProductOptions } from "app2/src/selectors/productOption.selectors";

class ProductOptionSelector implements ng.IComponentController {
  [s: string]: any;
  public estimate: IEstimate;
  public lineItem: IEstimateLineItem;
  public selectedOptionGroup: ProductOptionGroupRecord;
  public addingOption = false;
  public changingElio: IEstimateLineItemOption = null;
  public newOptionQuantity: number;
  public type: string;

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $scope: ng.IScope,
    public EstimatorService: EstimatorService,
    private $analytics: angulartics.IAnalyticsService,
  ) {}

  public $onChanges() {}

  public productOptionGroupsByType(): ProductOptionGroupRecord[] {
    if (this.estimateLineItem && this.estimateLineItem.product_id) {
      return useSelector((state) =>
        cachedProductOptionGroups(state, {
          activatedPriceListId: this.estimate.activated_price_list_id,
          productId: this.estimateLineItem.product_id,
          type: this.type,
        }),
      ).toArray();
    } else {
      return [];
    }
  }

  public productOptions(): ProductOptionRecord[] {
    return useSelector((state) =>
      cachedProductOptions(state, {
        activatedPriceListId: this.estimate.activated_price_list_id,
        productOptionIds: this.selectedOptionGroup.option_ids,
      }),
    ).toArray();
  }

  public canAdd(pog: IProductOptionGroup): boolean {
    if (pog.selection_mode === "multi") {
      return true;
    }

    return !_.any(this.lineItem.existingOptions(), (opt: IEstimateLineItemOption) => {
      return opt.product_option_group_id === pog.id;
    });
  }

  public alreadyAdded(po: IProductOption): boolean {
    if (!this.lineItem) {
      return false;
    }
    return _.any(this.lineItem.existingOptions(), (opt: IEstimateLineItemOption) => {
      return opt.product_option_id === po.id;
    });
  }

  public addOptionGroup(optionGroup: ProductOptionGroupRecord) {
    this.selectedOptionGroup = optionGroup;
    this.addingOption = true;
  }

  public changeOptionGroup(pog: ProductOptionGroupRecord) {
    this.changingElio = _.find(this.lineItem.options, (opt: IEstimateLineItemOption) => {
      return opt.product_option_group_id === pog.id;
    });

    this.selectedOptionGroup = pog;
    this.addingOption = true;
  }

  public cancelAddingOptionGroup() {
    this.selectedOptionGroup = null;
    this.changingElio = null;
    this.addingOption = false;
  }

  public addProductOption(po: ProductOptionRecord) {
    if (this.changingElio) {
      this.changingElio.product_option_id = po.id;
      this.changingElio.quantity = this.newOptionQuantity;
      this.trackEvent("product option changed", {
        product_option: po.id,
      });
    } else {
      const cmd_po = new AddEstimateLineItemOptionCmd(po, this.selectedOptionGroup, this.lineItem, this.estimate);
      cmd_po.execute();
      this.trackEvent("product option added", {
        product_option: po.id,
      });
    }

    this.addingOption = false;
    this.selectedOptionGroup = null;
    this.changingElio = null;
    this.newOptionQuantity = 1;
  }

  public removeOption(elio: IEstimateLineItemOption) {
    if (elio.id < 0) {
      this.lineItem.options = _.filter(this.lineItem.options, (option: IEstimateLineItemOption) => {
        return elio.id !== option.id;
      });
    } else {
      elio._destroy = true;
    }
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        category: "ProductOptionSelector",
        estimate: this.estimate.id,
        job: this.estimate.job_id,
        org: this.EstimatorService.org.id,
        lineItem: this.lineItem.id,
      }),
    );
  }
}

export class ProductOptionSelectorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/ProductOptionSelector/product_option_selector_component.html";
  public bindings: any = {
    estimate: "<",
    lineItem: "<",
    type: "<",
  };

  constructor() {
    this.controller = ProductOptionSelector;
    this.controller.$inject = ["$uibModal", "$scope", "EstimatorService", "$analytics"];
  }
}
