import { fetcher } from "../helpers/Fetcher";
import { IAccessData } from "../records/UserRecord";
import { IMetaPaginationData } from "../records/MetaPagination";
import { QueryParamsRecord } from "../records/Page";
import { listParams } from "./Api";

export interface IAccessIndexResponse {
  accesses: IAccessData[];
  meta: { pagination: IMetaPaginationData };
}

export interface IAccessShowResponse {
  access: IAccessData;
  error?: string;
}

export const loadAccesses = (
  userId: number,
  orgName: string,
  queryParams: QueryParamsRecord,
): Promise<IAccessIndexResponse> => {
  const actionUrl = `api/v1/users/${userId}/accesses`;
  const params = listParams(queryParams);
  params.org_name = orgName;

  if (queryParams.getIn(["parameters", "includes"])) {
    params["include[]"] = queryParams.getIn(["parameters", "includes"]).toJS();
  }

  return fetcher.get<IAccessIndexResponse>(actionUrl, params);
};

export const getAccess = (user_id: number, org_id: number): Promise<IAccessShowResponse> => {
  const actionUrl = `api/v1/users/${user_id}/accesses/by_org_id`;
  return fetcher.get<IAccessShowResponse>(actionUrl, { user_id, org_id, include: ["config"] });
};

export const updateAccess = (access_id: number, config: any): Promise<IAccessShowResponse> => {
  const actionUrl = `api/v1/accesses/${access_id}`;
  return fetcher.patch<IAccessShowResponse>(actionUrl, { config });
};
