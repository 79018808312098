import { IPagingMetadata } from "../../Models/PagingMetadata";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../../Common/IBaseConfig";

export interface IFee extends ng.resource.IResource<IFee>, FeePrototype {
  $subscribe?(data: any): ng.IPromise<IFee>;
}

export interface IFeeResource extends ng.resource.IResourceClass<IFee>, IRsfResource {
  fromJSON?(data: any): IFee;
  subscribe?(data: any): IFee;
}

export interface IFeeResponse extends ng.resource.IResourceArray<IFee> {
  fees: IFee[];
  meta: IPagingMetadata;
}

class FeePrototype {
  public id: number;
  public name: string;
  public amount: number;
  public installments: number;
  public interval: string;
  public min_interval: string;
  public min_interval_count: number;

  public $saveOrCreate(): ng.IPromise<IFee> {
    if (!this.id || this.id <= 0 || (this.id as any as string) === "Unsaved") {
      return (this as any).$save();
    } else {
      return (this as any).$update();
    }
  }

  public getName(): string {
    return this.name + " [$" + (this.amount / 100).toFixed(2) + " for " + this.installments + " " + this.interval + "]";
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IFeeResource => {
  const url = BaseConfig.BASE_URL + "/billing/v1/fees/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IFee => {
    if (status < 200 || status > 299) {
      return new Fee({});
    }

    return Fee.fromJSON(JSON.parse(response).fee);
  };

  const multipleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IFeeResponse => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);
    _.each(data.fees, (fee, index) => {
      data.fees[index] = Fee.fromJSON(fee);
    });

    return data;
  };

  const transformRequest = (fee: IFee): string => {
    const data = JSON.decycle(fee);
    return angular.toJson({ fee: data });
  };

  const Fee: IFeeResource = <IFeeResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      subscribe: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: url + "/subscribe",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Fee.prototype, FeePrototype.prototype);

  Fee.fromJSON = (data: any): IFee => {
    return new Fee(data);
  };

  Fee.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Fee;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
