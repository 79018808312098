import { ProductRecord } from "../records/Product";

export class InvalidConversionError extends Error {
  public fromUom: string;
  public toUom: string;
  public quantity: number;

  constructor(fromUom: string, toUom: string, quantity = 0) {
    super(`Invalid unit conversion requested.  Unable to convert "${fromUom}" to "${toUom}."`);
    this.fromUom = fromUom;
    this.toUom = toUom;
    this.quantity = quantity;

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = InvalidConversionError.name;
  }
}

export const convert = (
  value: number,
  unitFrom: string,
  unitTo: string,
  standard_measurement = 0,
  standard_measurement_uom = "",
): number => {
  let quantity = value;
  if (unitFrom === unitTo) {
    return quantity;
  } else if (unitFrom === "sqft" && unitTo === "sq") {
    if (standard_measurement <= 0) {
      standard_measurement = 1;
    }
    quantity = _.round(Math.ceil((quantity / 100) * standard_measurement), -2);
  } else if (unitFrom === "sqm" && unitTo === "sq") {
    if (standard_measurement <= 0) {
      standard_measurement = 1;
    }
    quantity = _.round(Math.ceil(((quantity * 10.76391041671) / 100) * standard_measurement), -2);
  } else if (standard_measurement_uom) {
    quantity = convertWithStandardOfMeasure(value, unitFrom, unitTo, standard_measurement_uom, standard_measurement);
  } else if (unitFrom === "sqft" && unitTo === "sqm") {
    quantity = _.round(quantity / 10.76391041671, -2);
  } else if (unitFrom === "lft" && unitTo === "m") {
    quantity = _.round(quantity / 3.280839895, -2);
  } else if (unitFrom === "in" && unitTo === "cm") {
    quantity = _.round(quantity / 2.54, -2);
  } else if (unitFrom === "in" && unitTo === "m") {
    quantity = _.round(quantity / 39.3700787402, -2);
  } else if (unitFrom === "sqm" && unitTo === "sqft") {
    quantity = _.round(quantity * 10.76391041671, -2);
  } else if (unitFrom === "m" && unitTo === "lft") {
    quantity = _.round(quantity * 3.280839895, -2);
  } else if (unitFrom === "cm" && unitTo === "in") {
    quantity = _.round(quantity * 2.54, -2);
  } else if (unitFrom === "m" && unitTo === "in") {
    quantity = _.round(quantity * 39.3700787402, -2);
  } else if (unitFrom === "mile" && unitTo === "km") {
    quantity = _.round(quantity * 1.609344, -2);
  } else if (unitFrom === "km" && unitTo === "mile") {
    quantity = _.round(quantity / 1.609344, -2);
  } else if (unitFrom === "gal" && unitTo === "l") {
    quantity = _.round(quantity * 3.785411784, -2);
  } else if (unitFrom === "l" && unitTo === "gal") {
    quantity = _.round(quantity / 3.785411784, -2);
  } else if (unitFrom === "w" && unitTo === "kW") {
    quantity = _.round(quantity / 1000, -2);
  } else if (unitFrom === "kW" && unitTo === "w") {
    quantity = _.round(quantity * 1000, -2);
  } else {
    throw new InvalidConversionError(unitFrom, unitTo, quantity);
  }
  return quantity;
};

export const convertWithStandardOfMeasure = (
  quantity: number,
  unitFrom: string,
  unitTo: string,
  standard_measurement_uom: string,
  standard_measurement: number,
): number => {
  if (unitFrom === standard_measurement_uom && unitTo === "ea" && standard_measurement > 0) {
    quantity = Math.ceil(quantity / standard_measurement);
  } else if (unitFrom === "sqm" && standard_measurement_uom === "sq" && unitTo === "ea" && standard_measurement > 0) {
    // need to handle this explicitly as the `convert` method rounds when we don't want it to.
    quantity = convert(quantity, unitFrom, "sqft", null, null);
    quantity = Math.ceil(quantity / 100 / standard_measurement);
  } else if (unitFrom === "sqft" && standard_measurement_uom === "sq" && unitTo === "ea" && standard_measurement > 0) {
    // need to handle this explicitly as the `convert` method rounds when we don't want it to.
    quantity = Math.ceil(quantity / 100 / standard_measurement);
  } else {
    // let convert do any conversions besides sq, as that rounding is 'fine'?
    quantity = convert(quantity, unitFrom, standard_measurement_uom, null, null);
    quantity = Math.ceil(quantity / standard_measurement);
  }

  return quantity;
};
