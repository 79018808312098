import * as React from "react";
import SaveablePdfDisplay from "../../Pdf/SaveablePdfDisplay";
import { RootState } from "app2/src/reducers";
import { RootDispatchType } from "app2/src/store";
import { currentJob } from "app2/src/selectors/job.selectors";
import { document } from "app2/src/selectors/document.selectors";
import { connect, ConnectedProps } from "app2/src/connect";
import { Row, Col, Button } from "react-bootstrap";
import { DocumentableType, IDocumentOptions } from "app2/src/api/document.service";
import * as documentActions from "app2/src/reducers/document.actions";
import Spinner from "app2/src/components/SpinnerComponent";
import { push } from "connected-react-router/immutable";
import { query } from "app2/src/selectors/router.selectors";

const mapStateToProps = (state: RootState, ownProps: ViewPdfDocProps) => {
  return {
    document: document(state, { documentId: ownProps.documentId }),
    job: currentJob(state),
    query: query(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: ViewPdfDocProps) => {
  return {
    push: (path: string) => dispatch(push(path)),
    checkAndLoadDocument: (options: IDocumentOptions) =>
      dispatch(documentActions.AsyncActions.checkAndLoadDocument(ownProps.documentId, options)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ViewPdfDocProps {
  documentId: number;
  documentableType: DocumentableType;
}

interface ViewPdfDocState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ViewPdfDocProps;

class ViewPdfDoc extends React.Component<Props, ViewPdfDocState> {
  constructor(props) {
    super(props);

    this.navigate = this.navigate.bind(this);
    this.checkAndLoad = this.checkAndLoad.bind(this);
  }

  public componentDidMount() {
    this.checkAndLoad();
  }

  public componentDidUpdate() {
    this.checkAndLoad();
  }

  public checkAndLoad() {
    const { checkAndLoadDocument, job, documentableType, document } = this.props;

    if (document) {
      return;
    }

    const options = { id: job.id, kind: documentableType };

    if (documentableType === "org") {
      if (job.org_id <= 0) {
        return;
      }
      options.id = job.org_id;
    }

    checkAndLoadDocument(options);
  }

  public navigate() {
    const { push, job, query } = this.props;
    const params = query.toJS();
    delete params.document_id;
    const queryParams = new URLSearchParams(params);
    push(`/jobs/${job.id}/documents?${queryParams.toString()}`);
  }

  public render() {
    const { job, document } = this.props;

    if (_.isNullOrUndefined(document) || document.loading || job.loading) {
      return <Spinner localProperty={true} />;
    }

    return (
      <React.Fragment>
        <Row>
          <Col sm={10}>
            <h1 title="Document Name">{document.name}</h1>
          </Col>
          <Col sm={2}>
            <Button title="Close View" className="pull-right" variant="cancel" onClick={this.navigate}>
              Close
            </Button>
          </Col>
        </Row>
        <SaveablePdfDisplay job={job} pdfUrl={document?.file?.url} documentName={document?.name} />
      </React.Fragment>
    );
  }
}

export default connector(ViewPdfDoc);
