import { Record, Map } from "immutable";
import * as taskActions from "./task.actions";
import { TaskRecord, fromJSON } from "app2/src/records/Task";

export const TaskStateRecord = Record({
  byId: Map<string, typeof TaskRecord>(),
});

export const initialState = TaskStateRecord();
export type TaskState = typeof initialState;

export const reducer = (state = initialState, action: taskActions.Actions): TaskState => {
  switch (action.type) {
    case taskActions.RECEIVE_TASK:
      const task = state.getIn(["byId", action.payload.id]);
      if (task) {
        state = state.updateIn(["byId", action.payload.id], (oldTask: TaskRecord) => {
          return fromJSON({ ...oldTask.toJS(), ...action.payload });
        });
      } else {
        state = state.setIn(["byId", action.payload.id], fromJSON(action.payload));
      }
      localStorage.setItem("ngStorage-tasks", JSON.stringify(state.getIn(["byId"])));
      return state;
    default:
      return state;
  }
};
