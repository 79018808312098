import * as React from "react";
import { Record } from "immutable";
import { noop } from "underscore";

interface ContextProps {
  orgId: number;
  dispatch: LeadPerfectionDispatch;
}

export type LeadPerfectionAction = "submit" | "close";
export type LeadPerfectionDispatch = (action: LeadPerfectionAction, payload?: any) => void;

const defaultContextProps: ContextProps = {
  orgId: 0,
  dispatch: noop,
};

export class ContextRecord extends Record(defaultContextProps) implements ContextProps {}

export const Context = React.createContext(new ContextRecord());
