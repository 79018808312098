import * as React from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { estimateCommission as estimateCommissionSelector } from "app2/src/selectors/estimateCommission.selectors";

export const Calculations: React.FC = () => {
  const estimateCommission = useSelector(estimateCommissionSelector);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th style={{ paddingLeft: "24px" }}>User</th>
          <th style={{ paddingLeft: "24px" }}>{estimateCommission.generated_by_user_name}</th>
        </tr>
      </thead>

      <tbody className="pb-0">
        {estimateCommission.output?.verbose_output_objects?.map((obj) => (
          <tr key={obj.title}>
            <td>{obj.title}</td>
            <td>{obj.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
