import { currentJob } from "app2/src/selectors/job.selectors";
import * as React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getToolConfig } from "./tool.service";
import { ToolRecord } from "app2/src/records/Tool";
import * as config from "react-global-configuration";
import { navDisplay as navDisplaySelector } from "app2/src/selectors/components/common.selectors";
import { useTracking } from "react-tracking";
import { RootState } from "app2/src/reducers";
import { replaceRecordValues } from "app2/src/helpers/Record";

export interface ToolCardProps {
  toolKey?: string;
  toolRecord?: ToolRecord;
}

export const ToolCard: React.FC<ToolCardProps> = ({ toolKey, toolRecord }) => {
  const { trackEvent } = useTracking();
  const tool = getToolConfig(toolKey);
  const job = useSelector(currentJob);
  const navDisplay = useSelector((state: RootState) => navDisplaySelector(state));

  if (toolKey && !tool.visible(job, navDisplay)) return null;

  const handleClick = (e) => {
    if (tool?.action) {
      e.preventDefault();
      tool.action(job);
    }
    trackEvent({
      action: tool?.title || toolRecord?.name,
      category: "Tool",
      navDisplay: !navDisplay,
      toolId: toolRecord?.id,
    });
  };

  if (toolKey)
    return (
      <div className="tool-display">
        <Card className="rsf-card">
          <a href={tool?.link ? tool.link(job, config.get("APP_URL")) : "#"} onClick={handleClick} target="_blank">
            <div className="text-center">
              <img src={tool.image} alt={tool.title} />
            </div>
            <h3>
              {tool.title}
              {"  "}
              {tool.paid && <span>$</span>}
            </h3>
          </a>
        </Card>
      </div>
    );

  return (
    <div className="tool-display">
      <Card className="rsf-card" onClick={handleClick}>
        <a href={replaceRecordValues(toolRecord?.link, job, "job")} target="_blank">
          <div className="text-center">
            {toolRecord?.brand_image?.file?.url && <img src={toolRecord.brand_image.file.url} alt={toolRecord.name} />}
          </div>
          <h3>{toolRecord.name}</h3>
        </a>
      </Card>
    </div>
  );
};
