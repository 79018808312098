import { IUser } from "app/src/Models/User";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import * as angulartics from "angulartics";
import { IUserPreference, UserPrefConfigType } from "app/src/Models/UserPreference";
import { IBaseConfig } from "./IBaseConfig";
import { IRepository } from "./Repository";
import { IBillingStatus } from "../Billing/Models/Account";
import { OrgAclType } from "../Models/Org";
import { subscriber } from "app2/src/helpers/Subscribe";
import { StoreRegistry, dispatch, useState, useSelector } from "app2/src/storeRegistry";
import { Deferred } from "app2/src/services/deferred";
import * as authActions from "app2/src/reducers/auth.actions";
import * as userActions from "app2/src/reducers/user.actions";
import * as hoverActions from "app2/src/reducers/integrations/hover.actions";
import * as quickMeasureActions from "app2/src/reducers/integrations/quickMeasure.actions";
import * as paymentActions from "app2/src/reducers/payment.actions";
import * as tokenActions from "app2/src/reducers/token.actions";
import { IAuthService } from "app2/src/helpers/Auth.service";
import { Dispatch, setUserProperties } from "app2/src/helpers/Analytics";
import { Actions as RoofScopeActions } from "app2/src/reducers/integrations/roofscope.actions";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";
import { List } from "immutable";
import { Actions as JobActions } from "app2/src/reducers/job.actions";

export enum Actions {
  read = 0,
  create = 1,
  update = 2,
  destroy = 3,
}

const authService = StoreRegistry.get<IAuthService>("authService");

export interface ISession {
  currentUser: IUser;
  preferences: IUserPreference;
  billingStatus: IBillingStatus;
  permissions: any;

  login(email: string, password: string): ng.IPromise<IUser>;
  loginUserSetup(data: any);
  logout(): ng.IPromise<any>;
  refreshToken(): ng.IPromise<boolean>;
  hasToken(): boolean;
  getToken(): string;
  getRefreshToken(): string;
  can(action: Actions | string, name: string, object?: any): ng.IPromise<boolean>;
  canReport(report: string, action: string | string[]): ng.IPromise<boolean>;
  fetchUserPref(key): ng.IPromise<boolean>;
  setTourPref(key: UserPrefConfigType, value: boolean): void;
  loadPreference(): ng.IPromise<any>;
  savePreference(): ng.IPromise<any>;
  isFreemium(): boolean;
  billingStatusMessage(): string;
  stripAuthUser(): void;
  resetPassword(
    token: string,
    token_type: string,
    new_password: string,
    confirm_password: string,
    accepted_tos?: boolean,
  ): ng.IPromise<IUser>;
  updateBilling(): ng.IPromise<any>;
}

export interface ISessionObject {
  id: number;
  email: string;
  token: string;
}

export class Session implements ISession {
  public currentUser: IUser;
  public billingStatus: IBillingStatus;
  public preferences: IUserPreference;
  public permissions: any;
  public cannot: any;
  public authService: IAuthService = authService;
  private loggedOut: IUser;
  private _unsubscribeToken: () => void;
  private _unsubscribeLogin: () => void;
  private _unsubscribeRefreshToken: () => void;
  private _unsubscribeAccessUid: () => void;
  private _userDeferred: Deferred<IUser> = Deferred.defer<IUser>();
  private _currentToken: string;
  private _currentAccessUid: string;

  public static $inject = [
    "$http",
    "Repository",
    "BaseConfig",
    "OrgFetcher",
    "VERSION",
    "$rootScope",
    "$analytics",
    "$q",
    "$state",
    "$window",
  ];
  public constructor(
    private $http: ng.IHttpService,
    private Repository: IRepository,
    public BaseConfig: IBaseConfig,
    public OrgFetcher: IOrgFetcherService,
    public VERSION: string,
    private $rootScope: RsfRootScope,
    private $analytics: angulartics.IAnalyticsService,
    public $q: ng.IQService,
    public $state: ng.ui.IStateService,
    public $window: ng.IWindowService,
  ) {
    this.stripAuthUser();
    authService.initialize();
    const saved: ISessionObject = authService.getCurrentUser();
    this.loggedOut = { $promise: this.$q.defer().promise } as IUser;
    this.loadAuth();
    Dispatch.analytics = $analytics;

    this._unsubscribeToken = subscriber.subscribe<string>("auth.token", (token: string) => {
      if (token === this._currentToken) {
        return;
      }
      this._currentToken = token;
      this.setHTTPHeaderDefaults(token);
      this.loadAuth();
    });

    this._unsubscribeAccessUid = subscriber.subscribe<string>("auth.accessUid", (uid: string) => {
      this.changeOrgs(uid);
    });

    this._unsubscribeRefreshToken = subscriber.subscribe<boolean>(
      "auth.refreshTokenExpired",
      (refreshToken: boolean) => {
        if (refreshToken) {
          authService.logout(authService.getRefreshToken()).then(() => {
            //set window location to root url to force refresh, clear cache
            //@ts-ignore
            this.$window.location = BaseConfig.APP_URL;
          });
        }
      },
    );

    this._unsubscribeLogin = subscriber.subscribe<IUser>("users.currentUser", (user: IUser) => {
      if (!user) {
        this.currentUser = this.loggedOut;
        return;
      }

      this.setupReduxUser();
      if (!this.preferences) {
        this.loadPreference();
      }
    });

    if (saved && saved.token) {
      this.setHTTPHeaderDefaults(saved.token);

      // check if it's already done, else wait
      if (useState().getIn(["users", "currentUser"])) {
        this.setupReduxUser();
      } else {
        this._userDeferred = Deferred.defer<IUser>();

        this.currentUser = {
          $promise: this._userDeferred.promise as any as ng.IPromise<IUser>,
        } as any as IUser;
      }

      this.currentUser.$promise.then(() => {
        this.setupNewUser();
        this.loadPreference();
      });
    } else {
      this.currentUser = this.loggedOut;
    }
  }

  public login = (email: string, password: string): ng.IPromise<IUser> => {
    const userPromise = authService.login(email, password).then((data: any) => {
      this.setupReduxUser();
      return this.loginUserSetup(data);
    });

    return userPromise as any as ng.IPromise<IUser>;
  };

  public resetPassword(
    token: string,
    token_type: string,
    new_password: string,
    confirm_password: string,
    accepted_tos?: boolean,
  ): ng.IPromise<IUser> {
    const user: any = {
      password: new_password,
      password_confirmation: confirm_password,
    };

    if (accepted_tos) {
      user.accepted_tos = accepted_tos;
    }

    const userPromise = authService.resetPassword(token, token_type, user).then((data: any) => {
      return this.loginUserSetup(data);
    });

    return userPromise as any as ng.IPromise<IUser>;
  }

  public changeOrgs(uid) {
    if (uid === this._currentAccessUid || !uid) {
      return;
    }
    this._currentAccessUid = uid;
    this.setHTTPHeaderDefaults("", uid);
    this.loadAuth().then(() => {
      this.permissions = undefined;
      this.setAnalyticsTracking();
    });
  }

  public loginUserSetup(data: any) {
    this.currentUser.$promise.then(() => {
      this.loadPreference();
    });

    this.setHTTPHeaderDefaults(data.token);
    this.$rootScope.$broadcast("auth:login", this.currentUser);

    this.setupNewUser();
    return this.currentUser;
  }

  public hasToken = (): boolean => {
    return authService.hasToken();
  };

  public getToken() {
    return authService.getToken();
  }

  public getRefreshToken() {
    return authService.getRefreshToken();
  }

  public logout = (): ng.IPromise<any> => {
    if (this.currentUser !== this.loggedOut) {
      return this.currentUser.$promise.then(() => {
        return authService.logout(this.getRefreshToken()).then(() => {
          this.$rootScope.$broadcast("auth:logout");
          this.$analytics.setUsername(undefined);
          this.currentUser = this.loggedOut;
          this.$rootScope.currentUser = this.loggedOut;
          this.billingStatus = undefined;
          this.permissions = undefined;
          this.preferences = undefined;
        }) as ng.IPromise<void>;
      });
    } else {
      const defer = this.$q.defer<void>();
      defer.resolve();
      return defer.promise;
    }
  };

  public refreshToken = (): ng.IPromise<any> => {
    if (!authService.hasRefreshToken()) {
      return;
    }

    const promise = authService.refreshToken(authService.getRefreshToken());

    promise.then(
      (data: any) => {
        this.currentUser = this.Repository.User.fromJSON(data.user);
        this.currentUser.$promise = promise.then((data: any) => {
          return <IUser>data.user;
        }) as any as ng.IPromise<IUser>;

        this.setHTTPHeaderDefaults(data.token);
        this.$rootScope.$broadcast("auth:login", this.currentUser);

        if (!this.preferences) {
          this.loadPreference();
        }

        this.setupNewUser();
        return this.currentUser;
      },
      () => {
        this.logout();
      },
    );

    return promise as any as ng.IPromise<any>;
  };

  public fetchUserPref(key): ng.IPromise<boolean> {
    if (!this.currentUser || !this.preferences) {
      return this.asPromised(false);
    }

    return this.preferences.$promise.then(() => {
      let pref: boolean;
      if (key === "job_list_card_view") {
        pref = this.preferences[key.toString()];
      } else {
        pref = this.preferences.config[key.toString()];
      }
      if (_.isUndefined(pref)) {
        return true;
      }

      return pref;
    });
  }

  public setTourPref(key: UserPrefConfigType, value: boolean): void {
    this.preferences.config[key.toString()] = value;
  }

  /**
   * If action is an array, it is an 'any', not 'all'
   */
  public canReport(report: string, action: string | string[]): ng.IPromise<boolean> {
    let reportSettingsName = report.replace("eagleview", "eagleview_roofing");
    reportSettingsName = reportSettingsName.replace("quick_measure", "quick_measure_roofing");
    reportSettingsName = reportSettingsName.replace("roof_scope", "roof_scope_roofing");
    reportSettingsName = reportSettingsName.replace("plnar", "plnar_interior");

    if (!this.currentUser.org.reportsEnabled(reportSettingsName)) {
      return this.asPromised(false);
    }

    if (_.isArray(action)) {
      const promises = _.map(action, (a) => this.can(a as any as Actions, report));
      return this.$q.all(promises).then((results: boolean[]) => _.any(results));
    }

    return this.can(action as any as Actions, report);
  }

  public can = (action: Actions, name: string, object: any = null): ng.IPromise<boolean> => {
    if (!this.currentUser || !this.currentUser.permissions) {
      return this.asPromised(false);
    }

    if (name.indexOf("org_") === 0) {
      return this.asPromised(this.tools(name.substr(4), action));
    }

    if (name.indexOf("orgpref_") === 0) {
      return this.asPromised(this.preferencesSetup(name.substr(8), action));
    }

    if (name.indexOf("orgset_") === 0) {
      return this.asPromised(this.settingsSetup(name.substr(7), action));
    }

    this.permissions = this.permissions || this.currentUser.permissions;

    const resource: string = _.toUnderscore(name);
    const mappedAction: string = this.mapAction(_.isString(action) ? action : Actions[action]);

    if (!_.isArray(this.permissions[resource])) {
      return this.asPromised(false);
    }

    let matched_permission = "";
    if (
      _.any(this.permissions[resource], (defined: string) => {
        matched_permission = defined;
        return defined.indexOf(mappedAction) === 0;
      })
    ) {
      if (!object) {
        return this.asPromised(true);
      }
      if (!object.$promise) {
        const deferred = this.$q.defer();
        object.$promise = deferred.promise;
        deferred.resolve();
      }
      switch (resource) {
        case "org":
          return this.orgPolicy(object);
        case "user":
          return this.userPolicy(object, matched_permission);
        default:
          return this.asPromised(true);
      }
    }
    return this.asPromised(false);
  };

  public orgPolicy(object: any): ng.IPromise<any> {
    return object.$promise.then(() => {
      if (this.currentUser.org_id === object.id || object.id === undefined) {
        return this.asPromised(true);
      } else {
        return this.OrgFetcher.getTree(this.currentUser.org_id, object.id).then((resp: any) => {
          return resp;
        });
      }
    });
  }

  public userPolicy(object: any, matched_permission: string): ng.IPromise<any> {
    return object.$promise.then(() => {
      if (this.currentUser.id !== object.id && matched_permission === "update_self") {
        return this.asPromised(false);
      } else {
        return this.asPromised(true);
      }
    });
  }

  public loadPreference(): ng.IPromise<any> {
    const id = this.currentUser.preferences_id;
    const user_id = this.currentUser.id;
    if (!this.preferences) {
      this.preferences = this.Repository.UserPreference.get({ id: id, user_id: user_id });
    }
    return this.preferences.$promise;
  }

  public savePreference(): ng.IPromise<any> {
    return this.preferences.$save();
  }

  public isFreemium(): boolean {
    if (this.billingStatus && this.billingStatus.kind === "rsf") {
      return true;
    }

    return false;
  }

  public billingStatusMessage(): string {
    switch (this.billingStatus.status) {
      case 0:
        return "No billing information.";
      case 1:
        return "Ok";
      case 2:
        return "Outstanding payments.";
      default:
        return "Unknown";
    }
  }

  public updateBilling(): ng.IPromise<any> {
    return this.currentUser.$promise.then(() => {
      this.billingStatus = this.Repository.Account.check({ org_id: this.currentUser.org_id });
      return this.billingStatus.$promise;
    });
  }

  // again, need to really separate auth from angular, see loadAuth comment below.
  // for now this is being tested via the AppSpec.
  public stripAuthUser() {
    const [path, params] = this.$window.location.href.split("?");
    const urlParams = new URLSearchParams(params);
    if (urlParams.get("authUser") !== null) {
      authService.setAccessUid(urlParams.get("authUser"));
      const cleanUrl = new URL(path);
      urlParams.forEach((v, k) => {
        if (k === "authUser") return;
        cleanUrl.searchParams.append(k, v);
      });
      history.replaceState(null, "", cleanUrl.href);
    }
  }

  private asPromised(value: boolean): ng.IPromise<boolean> {
    const deferred: ng.IDeferred<boolean> = this.$q.defer();
    deferred.resolve(value);
    return deferred.promise;
  }

  private tools(name: string, action: Actions): boolean {
    const tools: any = (this.currentUser.org.settings || {})["acl"] || {};

    return _.include(tools[name] || [], action);
  }

  private preferencesSetup(name: string, action: Actions | string): boolean {
    return this.currentUser.org.can("preference", name as any as OrgAclType, action);
  }

  private settingsSetup(name: string, action: Actions | string): boolean {
    return this.currentUser.org.can("setting", name as any as OrgAclType, action);
  }

  private setupNewUser = () => {
    this.permissions = this.currentUser.permissions;
    this.updateBilling();
    this.$rootScope.currentUser = this.currentUser;
    this.setAnalyticsTracking();
    dispatch(tokenActions.AsyncActions.getAuthorizedTokens(this.currentUser.org_id));
  };

  private setHTTPHeaderDefaults(token = "", accessUid = "") {
    if (token) {
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
    }

    if (accessUid) {
      this.$http.defaults.headers.common["x-rsf-auth"] = accessUid;
    } else if (authService.hasAccessUid()) {
      this.$http.defaults.headers.common["x-rsf-auth"] = authService.getAccessUid();
    } else if (this.$http.defaults.headers.common["x-rsf-auth"]) {
      delete this.$http.defaults.headers.common["x-rsf-auth"];
    }
  }

  private setAnalyticsTracking() {
    this.currentUser.$promise.then(() => {
      (this.billingStatus as any).$promise.then(() => {
        this.$analytics.setUsername(this.currentUser.id.toString());
        this.$analytics.setUserProperties({
          orgId: this.currentUser.org.id,
          appVersion: this.VERSION,
          user: this.currentUser,
          billingStatus: this.billingStatus,
        });
      });
      setUserProperties({
        userId: this.currentUser.id,
        orgId: this.currentUser.org_id,
        appVersion: this.VERSION,
      });
      // @ts-ignore
      gtag("set", this.BaseConfig.GA_KEY, {
        user_id: this.currentUser.id,
      });
    });
  }

  private mapAction(action: string) {
    return action.replace("destroy", "delete");
  }

  private setupReduxUser() {
    const userRecord = useSelector(denormalizedReduxUser);
    if (!userRecord) {
      return;
    }
    this.currentUser = this.Repository.User.fromJSON(userRecord.toJS());
    this.currentUser.$promise = this._userDeferred.promise as any as ng.IPromise<IUser>;
    this._userDeferred.resolve(this.currentUser);
    this.$rootScope.$broadcast("auth:login", this.currentUser);

    if (this._userDeferred) {
      this._userDeferred.resolve(this.currentUser);
    }

    this._userDeferred = Deferred.defer<IUser>();
  }

  /**
   * TODO: This needs moved to a non angular class.  Testing this is impossible without major overhauling.
   * fetchMock isn't setup for angular tests, and this uses fetch.  And getting mockStore setup, and all the helper
   * methods in angular isn't currently supported.
   */
  private loadAuth(): Promise<any> {
    if (!this.hasToken()) {
      return Promise.reject();
    }

    dispatch(authActions.Actions.setAccessUid(authService.getAccessUid()));

    return dispatch(userActions.AsyncActions.getCurrentUserByEmail(authService.getCurrentUser().email)).then(() => {
      const currentUser = useSelector(denormalizedReduxUser);
      dispatch(paymentActions.AsyncActions.getAuthorized(currentUser.org_id));
      dispatch(hoverActions.AsyncActions.getAuthorized());
      dispatch(quickMeasureActions.AsyncActions.getAuthorized());

      if (!authService.hasAccessUid()) {
        authService.setAccessUid(currentUser.current_access_uid);
        this._currentAccessUid = currentUser.current_access_uid;
        dispatch(authActions.Actions.setAccessUid(currentUser.current_access_uid));
      }

      dispatch(
        RoofScopeActions.setAuthorized(
          (currentUser.getIn(["permissions", "roof_scope"]) || List()).indexOf("order") !== -1,
        ),
      );
    });
  }
}
