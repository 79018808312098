import * as React from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import WrapperAuthorize from "app2/src/components/Integrations/WrapperAuthorize";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";

const mapStateToProps = (state: RootState, ownProps: PaySimpleProps) => {
  return {
    authorized: state.getIn(["payments", "authByOrgId", ownProps.orgId, "pay_simple"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: PaySimpleProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface PaySimpleProps {
  orgId: number;
  tokenUpdated: () => void;
}

export interface PaySimpleState {
  show: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PaySimpleProps;

class PaySimple extends React.Component<Props, PaySimpleState> {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);

    this.state = {
      show: false,
    };
  }

  public handleShow() {
    this.setState({ show: true });
  }

  public handleHide() {
    const { tokenUpdated } = this.props;

    this.setState({ show: false });
    tokenUpdated();
  }

  public render() {
    const { orgId, authorized } = this.props;
    const { show } = this.state;

    return (
      <React.Fragment>
        <div className="rsf-card">
          <Row>
            <Col className="text-center">
              <img className="img-fluid integration-logo-card" src="/assets/images/paysimple.3b60683c.png" title="PaySimple" />
            </Col>
          </Row>
          <div className="integration-btn">
            <Button variant="add" onClick={this.handleShow}>
              {authorized ? "Reauthorize" : "Authorize"}
            </Button>
            <FontAwesome name={authorized ? "check" : "times"} />
          </div>
        </div>
        <Modal show={show} onHide={this.handleHide}>
          <WrapperAuthorize resolve={{ id: orgId, service: "PaySimple" }} modalInstance={{ close: this.handleHide }} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default connector(PaySimple);
