import { Record } from "immutable";
import { RootState, RootActions } from "..";
import { Actions as PlanActions } from "./plan.actions";
import { PlanStateRecord, reducer as planReducer } from "./plan.reducer";
import { Actions as AccountActions } from "./account.actions";
import { AccountStateRecord, reducer as accountReducer } from "./account.reducer";
import { Actions as FeeActions } from "./fee.actions";
import { FeeStateRecord, reducer as feeReducer } from "./fee.reducer";

export const BillingStateRecord = Record({
  plans: PlanStateRecord(),
  accounts: AccountStateRecord(),
  fees: FeeStateRecord(),
});

export const initialState = BillingStateRecord();
export type BillingState = typeof initialState;

export const billingReducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get("billing")) {
    state = state.set("billing", initialState);
  }

  state = planReducer(state, action);
  state = accountReducer(state, action);
  state = feeReducer(state, action);

  return state;
};

export type BillingActions = PlanActions | AccountActions | FeeActions;
