import * as React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { connectFC } from "app2/src/connect";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { push } from "connected-react-router/immutable";
import { pathname } from "app2/src/selectors/router.selectors";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { paymentAuth } from "app2/src/selectors/payment.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";
import { paymentProviders } from "app2/src/records/PaymentAuthorizationRecord";
import * as cardConnectActions from "app2/src/reducers/integrations/cardconnect.actions";
import * as paySimpleActions from "app2/src/reducers/integrations/paysimple.actions";
import { authorizationRecord as cardConnectAuthSelector } from "app2/src/selectors/cardconnect.selectors";
import { authorizationRecord as paySimpleAuthSelector } from "app2/src/selectors/paySimple.selectors";

const MakePaymentButton: React.FC = () => {
  // state
  const [hideButton, setHideButton] = useState(true);
  // selectors
  const path = useSelector(pathname);
  const jobId = useSelector(currentJobId);
  const orgId = useSelector(currentOrgId);
  const auth = useSelector((state: RootState) => paymentAuth(state, { orgId }));
  const cardConnectAuth = useSelector((state: RootState) => cardConnectAuthSelector(state, orgId));
  const paySimpleAuth = useSelector((state: RootState) => paySimpleAuthSelector(state, orgId));

  // hooks
  const tracking = useTracking();
  const dispatch = useDispatch();

  const navigate = () => {
    tracking.trackEvent({ action: "open make payment modal" });
    if (cardConnectAuth?.hpp_url || (paySimpleAuth && paySimpleAuth.get("hpp_url"))) {
      dispatch(push(`${path}/payments`));
    } else {
      dispatch(push(`${path}/payments/onscreen`));
    }
  };

  useEffect(() => {
    if (!jobId) {
      setHideButton(true);
    } else if (paymentProviders.some((v) => auth?.get(v as any))) {
      setHideButton(false);
      if (auth.get("card_connect")) dispatch(cardConnectActions.AsyncActions.getAuthorization(orgId));
      if (auth.get("pay_simple")) dispatch(paySimpleActions.AsyncActions.getAuthorization(orgId));
    } else {
      setHideButton(true);
    }
  }, [jobId, auth]);

  if (hideButton) return null;

  return <Button onClick={navigate}>Make Payment</Button>;
};

export default connectFC(MakePaymentButton);
