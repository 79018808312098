import * as React from "react";
import { Button } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { ConfirmDialog } from "../../Common/ConfirmDialog";

export interface AuthorizeButtonProps {
  authorized: boolean;
  authorize: () => void;
  rejectAuthorization: () => void;
  settings?: () => void;
  authless?: boolean;
}
export default function AuthorizeButton(props: AuthorizeButtonProps) {
  return (
    <div className="authorize-button">
      {(!props.authless || !props.authorized) && (
        <Button variant="add" onClick={props.authorize}>
          {props.authorized ? "Reauthorize" : "Authorize"}
        </Button>
      )}

      {props.authorized ? (
        <FontAwesome name="check" title="Authorized" />
      ) : (
        <FontAwesome name="times" title="Not Authorized" />
      )}

      {props.authorized && props.settings ? (
        <FontAwesome name="cog" className="rsf-base-66 rsf-base-link" title="Settings" onClick={props.settings} />
      ) : null}

      {props.authorized && (
        <ConfirmDialog title="Are you sure you want to disconnect?">
          {(show) => (
            <FontAwesome
              name="trash"
              className="rsf-base-66 rsf-base-link"
              title="Disconnect"
              onClick={show(() => props.rejectAuthorization())}
            />
          )}
        </ConfirmDialog>
      )}
    </div>
  );
}
