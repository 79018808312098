import { IEstimateData } from "app2/src/records/Estimate";
import { IFinanceOptionData } from "app2/src/records/FinanceOption";
import { IframeRecord, IIframeData, fromJSON as iframeFromJSON } from "app2/src/records/Iframe";
import { IJobData } from "app2/src/records/Job";
import { IOrgData } from "app2/src/records/OrgRecord";
import { Record } from "immutable";

export const fromJSON = (json: Partial<ILandingEstimateData>): LandingEstimateRecord => {
  const record: ILandingEstimateRecord = { ...(json as any) };

  if (json.estimate) {
    record.estimateId = json.estimate.id;
  }

  if (json.org) {
    record.orgId = json.org.id;
  }

  if (json.job) {
    record.jobId = json.job.id;
  }

  if (json.finance_option) {
    record.financeOptionId = json.finance_option.id;
  }

  if (json.iframe) {
    record.iframe = iframeFromJSON(json.iframe);
  }

  return new LandingEstimateRecord(record);
};

export interface ILandingEstimateData {
  estimate: IEstimateData;
  org: IOrgData;
  job: IJobData;
  finance_option: IFinanceOptionData;
  iframe: IIframeData;
  loading?: boolean;
}

export interface ILandingEstimateRecord {
  estimateId: number;
  orgId: number;
  jobId: number;
  financeOptionId: number;
  iframe: IframeRecord;
  loading: boolean;
}

const defaultLandingEstimateProps: ILandingEstimateRecord = {
  estimateId: null,
  orgId: null,
  jobId: null,
  financeOptionId: null,
  iframe: iframeFromJSON({}),
  loading: false,
};

export class LandingEstimateRecord extends Record(defaultLandingEstimateProps) implements ILandingEstimateRecord {}
