export enum PricingViewImages {
  name_and_description = "/assets/images/admin-setup/name_and_description.9564b378.png",
  quantity_and_uom = "/assets/images/admin-setup/quantity_and_uom.4ad8cdb3.png",
  all_details = "/assets/images/admin-setup/all_details.819c5cbc.png",
}

export enum DiscountDetailImages {
  always = "/assets/images/admin-setup/discount_always.720f1d4c.png",
  never = "/assets/images/admin-setup/discount_never.58552cb9.png",
}

export enum EstimatedDatesImages {
  always = "/assets/images/admin-setup/estimated_dates_always.e0146133.png",
  never = "/assets/images/admin-setup/estimated_dates_never.ffc89f60.png",
}

export enum CoverPagePhoneImages {
  always = "/assets/images/admin-setup/cover_page_phone_always.e987dadb.png",
  never = "/assets/images/admin-setup/cover_page_phone_never.57899f30.png",
}

export enum CoverPageEmailImages {
  always = "/assets/images/admin-setup/cover_page_email_always.2146b3dd.png",
  never = "/assets/images/admin-setup/cover_page_email_never.1642b96f.png",
}
