import { endOfYear, sub, subMonths } from "date-fns";
import { List, Record } from "immutable";
import {
  fromJSON as reactSelectRecordFromJSON,
  ReactSelectRecord,
  IReactSelectData,
  IReactSelectRecord,
} from "@tberrysoln/rsf-form";
import { fromJSON as jobQueryStateFromJSON } from "./JobQueryState";

export const fromJSON = (json: Partial<IJobQueryData>): JobQueryRecord => {
  const record: IJobQueryRecord = { ...(json as any) };

  if (json.state) {
    record.state = jobStatesFromJSON(json.state);
  } else {
    record.state = List();
  }

  record.user = reactSelectRecordFromJSON(json.user || { id: -1, name: "All assignees" });
  record.org = reactSelectRecordFromJSON(json.org || {});

  return new JobQueryRecord(record);
};

export const jobStatesFromJSON = (json: any): List<any> =>
  List(
    Object.entries(json).map(([key, data]: [string, any]) =>
      jobQueryStateFromJSON({ label: data.pretty_name, value: key, color: data.color }),
    ),
  );

export const getJobQueryChanges = (jobQuery: JobQueryRecord): string[] => {
  const defaultJobQuery = fromJSON({});
  return jobQuery
    .toSeq()
    .filter((value, key) => {
      if (key === "state" || key === "org" || key === "user") {
        return !jobQuery.get(key).equals(defaultJobQuery.get(key));
      }
      return value !== defaultJobQuery.get(key);
    })
    .keySeq()
    .toJS();
};

export const calculateUpdatedSince = (jobQuery: JobQueryRecord | IJobQueryData): Date => {
  const isYear = jobQuery.updated_since.length === Number.parseInt(jobQuery.updated_since).toString().length;

  if (isYear) {
    const year = parseInt(jobQuery.updated_since);
    const since = new Date(year, 0, 1, 0, 0, 0, 0);
    return since;
  } else if (jobQuery.updated_since !== "Custom") {
    // It is a number of months / days
    const quantity = parseInt(jobQuery.updated_since);
    const unit = jobQuery.updated_since.slice(quantity.toString().length);
    const now = new Date();

    return sub(now, { [unit]: quantity });
  } else {
    return jobQuery.filter_by_updated_since;
  }
};

export const calculateUpdatedBefore = (jobQuery: JobQueryRecord | IJobQueryData): Date => {
  const isYear = jobQuery.updated_since.length === Number.parseInt(jobQuery.updated_since).toString().length;

  if (isYear) {
    const year = parseInt(jobQuery.updated_since);
    const since = new Date(year, 0, 1, 0, 0, 0, 0);
    return endOfYear(since);
  } else if (jobQuery.updated_since !== "Custom") {
    const today = new Date();
    return today;
  } else {
    return jobQuery.filter_by_updated_before;
  }
};

export type SortOrderTypes = "desc" | "asc";

export interface IJobQueryData {
  org: IReactSelectData;
  user: IReactSelectData;
  archived: boolean;
  state: string[];
  job_type: string;
  sort_order: SortOrderTypes;
  sort_by: string;
  updated_since: string;
  updated_before: string;
  filter_by_updated_since: Date;
  filter_by_updated_before: Date;
  lead_source: string;
}

export interface IJobQueryRecord {
  org: IReactSelectRecord;
  user: IReactSelectRecord;
  archived: boolean;
  state: List<string>;
  job_type: string;
  sort_order: SortOrderTypes;
  sort_by: string;
  updated_since: string;
  updated_before: string;
  filter_by_updated_since: Date;
  filter_by_updated_before: Date;
  lead_source: string;
}
export const defaultJobQuery = {
  org: reactSelectRecordFromJSON({}),
  user: reactSelectRecordFromJSON({}),
  archived: false,
  state: List(),
  job_type: "",
  sort_order: "desc" as SortOrderTypes,
  sort_by: "jobs.updated_at",
  updated_since: "6months",
  updated_before: null,
  filter_by_updated_since: subMonths(new Date().setHours(0, 0, 0, 0), 6),
  filter_by_updated_before: null,
  lead_source: "_allleads_",
};

export class JobQueryRecord extends Record<IJobQueryRecord>(defaultJobQuery) implements IJobQueryRecord {}
