import * as config from "react-global-configuration";
import { fetcher } from "../../helpers/Fetcher";
import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction } from "../Utils";
import { RootState, RootActions } from "..";
import { fromJSON, IMakePaymentData } from "app2/src/records/PaymentRecord";
import { PaySimpleAccountInfo, PaySimpleToken } from "app2/src/components/Payments/PaySimple/PaySimple.service";
import * as paymentActions from "../payment.actions";
import { RootDispatchType } from "../../store";
import { authorizationRecord } from "app2/src/selectors/paySimple.selectors";

export const FETCH_CHECKOUT_TOKEN = "@integrations/paysimple/FETCH_CHECKOUT_TOKEN";
export const RECEIVE_CHECKOUT_TOKEN = "@integrations/paysimple/RECEIVE_CHECKOUT_TOKEN";
export const RECEIVE_CHECKOUT_TOKEN_ERROR = "@integrations/paysimple/RECEIVE_CHECKOUT_TOKEN_ERROR";
export const RECEIVE_GENERAL_ERROR = "@integrations/paysimple/RECEIVE_GENERAL_ERROR";
export const FETCH_AUTHORIZATION = "@integrations/paysimple/FETCH_AUTHORIZATION";
export const SET_AUTHORIZATION = "@integrations/paysimple/SET_AUTHORIZATION";
export const UPDATE_FORM = "@integrations/paysimple/UPDATE_FORM";
export const RESET = "@integrations/paysimple/RESET";

export interface PaySimpleCheckoutToken {
  JwtToken?: string;
  Expiration?: string;
  error?: string;
}

export const Actions = {
  fetchCheckoutToken: () => createAction(FETCH_CHECKOUT_TOKEN),
  receiveCheckoutToken: (token: PaySimpleCheckoutToken) => createAction(RECEIVE_CHECKOUT_TOKEN, token),
  receiveCheckoutTokenError: (error: string) => createAction(RECEIVE_CHECKOUT_TOKEN_ERROR, error),
  receiveGeneralError: (error: string) => createAction(RECEIVE_GENERAL_ERROR, error),
  fetchAuthorization: (orgId: number) => createAction(FETCH_AUTHORIZATION, orgId),
  setAuthorization: (orgId: number, authorization: PaySimpleToken) =>
    createAction(SET_AUTHORIZATION, { orgId: orgId, authorization: authorization }),
  update: (event: any) => createAction(UPDATE_FORM, { event }),
  reset: (id: number) => createAction(RESET, { id }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, RootActions>;

export const AsyncActions = {
  getCheckoutToken: (orgId: number): ThunkResult<Promise<PaySimpleCheckoutToken>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchCheckoutToken());

      return fetcher
        .post<PaySimpleCheckoutToken>(
          fetcher.joinUrls(config.get("RSF_ANALYTICS_URL"), `payments/v1/paysimple/${orgId}/auth`),
        )
        .then(
          (token: PaySimpleCheckoutToken) => {
            dispatch(Actions.receiveCheckoutToken(token));
            return token;
          },
          (error: any) => {
            dispatch(Actions.receiveCheckoutTokenError(error.data.error));
            return error;
          },
        );
    };
  },
  makePayment: (orgId, accountInfo: PaySimpleAccountInfo, payment: IMakePaymentData): ThunkResult<Promise<any>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(paymentActions.Actions.makePayment());
      return fetcher
        .post<any>(fetcher.joinUrls(config.get("RSF_ANALYTICS_URL"), `payments/v1/paysimple/${orgId}/payment`), {
          ...accountInfo,
          payment,
        })
        .then(
          (data) => {
            const record = fromJSON(data.payment);
            dispatch(paymentActions.Actions.receivePayment(record));
          },
          (error: any) => {
            dispatch(Actions.receiveGeneralError(error));
            return error;
          },
        );
    };
  },
  getAuthorization: (orgId: number): ThunkResult<Promise<PaySimpleToken>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchAuthorization(orgId));

      return fetcher
        .get<PaySimpleToken>(
          fetcher.joinUrls(config.get("RSF_ANALYTICS_URL"), `payments/v1/paysimple/${orgId}/authorization`),
        )
        .then((result) => {
          dispatch(Actions.setAuthorization(orgId, result));
          return result;
        });
    };
  },
  authorize: (orgId: number): ThunkResult<Promise<PaySimpleToken>> => {
    return (dispatch: RootDispatchType, getState: () => RootState) => {
      const auth = authorizationRecord(getState(), orgId);
      dispatch(Actions.fetchAuthorization(orgId));

      return fetcher
        .post<PaySimpleToken>(
          fetcher.joinUrls(config.get("RSF_ANALYTICS_URL"), `payments/v1/paysimple/${orgId}/authorization`),
          auth.toJS(),
        )
        .then((result) => {
          dispatch(Actions.setAuthorization(orgId, result));
          return result;
        });
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
