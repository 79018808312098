import * as React from "react";
import _track, { Track, TrackingProp } from "react-tracking";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { Row, Col, Form } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { IOrg } from "app/src/Models/Org";
import { IJob } from "app/src/Models/Job";
import { IEstimate } from "app/src/Models/Estimate";
import { TrackingData } from "app2/src/helpers/Analytics";
import * as paymentActions from "app2/src/reducers/payment.actions";
import * as cardconnectActions from "app2/src/reducers/integrations/cardconnect.actions";
import { AccountInfoRecord } from "../AccountInfo.model";
import * as config from "react-global-configuration";
import { IUser } from "app/src/Models/User";
import { IMakePaymentData, PaymentRecord, PaymentChargeType } from "app2/src/records/PaymentRecord";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";

const mapStateToProps = (state: RootState, ownProps: ICardConnectFormProps) => {
  return {
    currentUser: denormalizedReduxUser(state) as any as IUser,
    payment: state.getIn(["integrations", "paysimple", "payment"], null),
    providerSubmitting: state.getIn(["payments", "providerSubmitting"]),
    providerValid: state.getIn(["payments", "providerValid"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ICardConnectFormProps) => {
  return {
    makePayment: (
      orgId: number,
      token: string,
      accountInfo: { email: string; name: string },
      payment: IMakePaymentData,
    ) => {
      return dispatch(cardconnectActions.AsyncActions.makePayment(orgId, token, accountInfo, payment));
    },
    setPaymentError: (error: string) => {
      return dispatch(paymentActions.Actions.paymentError(error));
    },
    setProviderValid: (valid: boolean) => {
      return dispatch(paymentActions.Actions.providerValid(valid));
    },
    setProviderLoading: (loading: boolean) => {
      return dispatch(paymentActions.Actions.providerLoading(loading));
    },
    setProviderSubmitting: (submitting: boolean) => {
      return dispatch(paymentActions.Actions.providerSubmitting(submitting));
    },
    sendReceipt: (payment: PaymentRecord) => {
      return dispatch(paymentActions.AsyncActions.sendReceipt(payment));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ICardConnectFormProps {
  accountInfo: AccountInfoRecord;
  resolve: { org: IOrg; job: IJob; estimate: IEstimate };
  tracking?: TrackingProp;
}

const track: Track<TrackingData, ICardConnectFormProps> = _track;

export interface ICardConnectFormState {
  token: string;
  chargeType: PaymentChargeType;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ICardConnectFormProps;

@track()
class CardConnectForm extends React.Component<Props, ICardConnectFormState> {
  protected _listener;
  protected _mounted = false;
  constructor(props: Props) {
    super(props);

    this.state = {
      token: "",
      chargeType: "credit_card",
    };

    this._listener = (event) => {
      const { setProviderValid } = props;

      if (!event.origin.match(/cardconnect/)) {
        return;
      }

      if (event.data === undefined || typeof event.data !== "string") {
        return;
      }

      try {
        const json = JSON.parse(event.data);

        if (json.message && json.message !== "") {
          this.setState({ token: json.message });
          setProviderValid(true);
        }

        if (json.validationError && json.validationError !== "") {
          setProviderValid(false);
        }
      } catch (e) {
        console.error("Error parsing CC response: ", e);
      }
    };

    this.handleInput = this.handleInput.bind(this);
  }

  public componentDidMount() {
    this._mounted = true;
    window.addEventListener("message", this._listener, false);
  }

  public componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener("message", this._listener, false);
  }

  public componentDidUpdate(prevProps) {
    if (!this._mounted) {
      return;
    }

    if (!prevProps.providerSubmitting && this.props.providerSubmitting) {
      this.submit();
    }
  }

  public handleInput(e: React.FormEvent<any>): void {
    //@ts-ignore
    const value = e.target.value;
    //@ts-ignore
    const name = e.target.name;
    const newState = {};

    newState[name] = value;

    this.setState(newState);
  }

  public render() {
    const { chargeType } = this.state;
    const url = config.get("CARD_CONNECT_IFRAME_URL");
    let urlOptions = "maskfirsttwo=true&useexpiry=true&usecvv=true&invalidinputevent=true&highlightinvalidinput=true";
    if (chargeType === "ach") {
      urlOptions = "maskfirsttwo=true&invalidinputevent=true&highlightinvalidinput=true";
    }
    const css = `
      .error {
        color: red;
      }
      input, select {
        margin-bottom: 20px;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        border: 1px solid rgb(206, 212, 218);
        border-style: solid;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
      }
      label {
        font-family: Roboto, Helvetica, sans-serif;
        color: rgb(191, 191, 191);
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 700;
      }
    `;
    const cssOptions = `css=${encodeURI(css.replace(/\s+/gm, " ").replace(/#/g, "%23"))}`;

    return (
      <>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Payment Method</Form.Label>
              <Form.Check
                name="chargeType"
                type="radio"
                id="chargeType-cc"
                checked={chargeType === "credit_card"}
                value="credit_card"
                label="Credit Card"
                onChange={this.handleInput}
                required></Form.Check>
              <Form.Check
                name="chargeType"
                type="radio"
                id="chargeType-ach"
                checked={chargeType === "ach"}
                value="ach"
                label="ACH"
                onChange={this.handleInput}
                required></Form.Check>
            </Form.Group>
          </Col>
        </Row>
        {chargeType === "ach" && (
          <Row>
            <Col md={12}>
              To complete the homeowner's payment please use this format "ROUTING/ACCOUNT": <br />
              <br />
              <i className="information">Example: 074000010/111222333</i>
            </Col>
          </Row>
        )}
        <div>
          <br />
          <h3>{chargeType === "credit_card" ? "Credit Card Information" : "Account Information"}</h3>
          <iframe
            id="tokenFrame"
            name="tokenFrame"
            style={{ height: chargeType === "credit_card" ? "210px" : "70px" }}
            src={`${url}?${cssOptions}&${urlOptions}`}
            scrolling="no"
          />
        </div>
      </>
    );
  }

  public submit() {
    const { providerValid, accountInfo, makePayment, sendReceipt, resolve, currentUser, tracking } = this.props;
    const { token, chargeType } = this.state;
    const customer = {
      name: `${accountInfo.firstName} ${accountInfo.lastName}`,
      email: accountInfo.email,
    };

    const payment: IMakePaymentData = {
      first_name: accountInfo.firstName,
      last_name: accountInfo.lastName,
      email: accountInfo.email,
      description: accountInfo.description,
      amount: accountInfo.amount,
      postal: accountInfo.postalCode,
      charge_type: "credit_card",
      job_id: resolve.job.id,
      user_id: currentUser.id,
      estimate_id: resolve.estimate ? resolve.estimate.id : null,
    };

    if (chargeType === "ach") {
      payment.charge_type = "ach";
    }

    if (providerValid) {
      makePayment(resolve.org.id, token, customer, payment).then(
        (payment) => {
          if (payment.status === "Authorized") {
            tracking.trackEvent({
              action: "Authorized",
              payment_id: payment.id,
              ...customer,
              ...accountInfo.toJSON(),
            });
            return sendReceipt(payment).then(() => payment);
          } else {
            tracking.trackEvent({
              action: "Failed",
              payment_id: payment.id,
              ...customer,
              ...accountInfo.toJSON(),
            });
          }
        },
        (error) => {
          tracking.trackEvent({
            action: "Error",
            message: error,
            ...customer,
            ...accountInfo.toJSON(),
          });
        },
      );
    }
  }
}

export default connector(CardConnectForm);
