import * as React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { InlineRadioGroup } from "app2/src/components/Common/InlineRadioGroup";
import { useForm } from "react-hook-form";
import { OrgRecord } from "app2/src/records/OrgRecord";
import {
  ISetupFormData,
  setupForm,
  PricingView,
  AlwaysNever,
  saveForm,
} from "app2/src/components/OrgSetup/SetupForm.service";
import {
  PricingViewImages,
  DiscountDetailImages,
  EstimatedDatesImages,
  CoverPageEmailImages,
  CoverPagePhoneImages,
} from "app2/src/components/OrgSetup/SetupFormImages";
import { FloatButton } from "app2/src/components/Common/FloatButton";
import { IDirtyWatcher } from "app/src/Common/DirtyWatcher";
import * as ng from "angular";
import { useDirtyWatcher } from "app2/src/hooks/useDirtyWatcher";

export interface SetupFormProps {
  org: OrgRecord;
  $scope: ng.IScope;
  DirtyWatcher: IDirtyWatcher;
}

export const SetupForm = (props: SetupFormProps) => {
  const { org, $scope, DirtyWatcher } = props;
  const { preferences } = org;
  const { register, handleSubmit, watch, formState, reset } = useForm({
    defaultValues: setupForm(preferences),
  });
  const { dirty } = formState;

  useDirtyWatcher($scope, DirtyWatcher, dirty, reset);

  const onSubmit = (data: ISetupFormData) => {
    saveForm(org, data);
  };

  let pricingViewKeys = Object.keys(PricingView);
  if (watch().pricingView !== PricingView.custom) {
    pricingViewKeys = pricingViewKeys.slice(0, -1);
  }
  const watchInstance: ISetupFormData = watch() as ISetupFormData;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FloatButton variant="save" buttonName="Save" disabled={!dirty} submit={handleSubmit(onSubmit)} />
      <Row>
        <Col>
          <h1 className="admin-title">Setup</h1>
        </Col>
      </Row>

      <InlineRadioGroup
        register={register}
        formState={formState}
        title={"Pricing Detail"}
        dataName={"pricingView"}
        keys={pricingViewKeys}
        imageSrc={PricingViewImages[watchInstance.pricingView]}
      />
      <InlineRadioGroup
        register={register}
        formState={formState}
        title={"Discount Detail"}
        dataName={"show_discount_detail"}
        keys={Object.keys(AlwaysNever)}
        imageSrc={DiscountDetailImages[watchInstance.show_discount_detail]}
      />
      <InlineRadioGroup
        register={register}
        formState={formState}
        title={"Estimated Start/End Dates"}
        dataName={"show_estimated_dates"}
        keys={Object.keys(AlwaysNever)}
        imageSrc={EstimatedDatesImages[watchInstance.show_estimated_dates]}
      />
      <InlineRadioGroup
        register={register}
        formState={formState}
        title={"Cover Page Customer Phone Number"}
        dataName={"show_customer_phone"}
        keys={Object.keys(AlwaysNever)}
        imageSrc={CoverPagePhoneImages[watchInstance.show_customer_phone]}
      />
      <InlineRadioGroup
        register={register}
        formState={formState}
        title={"Cover Page Customer Email"}
        dataName={"show_customer_email"}
        keys={Object.keys(AlwaysNever)}
        imageSrc={CoverPageEmailImages[watchInstance.show_customer_email]}
      />
    </form>
  );
};
