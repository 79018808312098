import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { SubscriptionRecord } from "app2/src/records/billing/Subscription";
import { DateFormat } from "app2/src/helpers/Format";

export const SubscriptionStripeDetailView: React.FunctionComponent<{ subscription: SubscriptionRecord }> = ({
  subscription,
}) => {
  if (!subscription) {
    return <h4>Not In Stripe</h4>;
  }

  return (
    <>
      <h4>Stripe Details</h4>
      <div className="form-section-content" ng-if="$ctrl.subscription">
        {subscription.trial_start && (
          <Row>
            <Col md={3} sm={6}>
              <Row>
                <label className="col-6">Trial Start</label>
                <Col sm={6}>{DateFormat(subscription.trial_start, "short")}</Col>
              </Row>
            </Col>
            <Col md={3} sm={6}>
              <Row>
                <label className="col-6">Trial End</label>
                <Col sm={6}>{DateFormat(subscription.trial_end, "short")}</Col>
              </Row>
            </Col>
          </Row>
        )}
        {subscription.trial_start && <br />}
        <Row>
          <Col md={3} sm={6}>
            <Row>
              <label className="col-6">Cycle Anchor</label>
              <Col sm={6}>{DateFormat(subscription.billing_cycle_anchor, "short")}</Col>
            </Row>
          </Col>
          <Col md={3} sm={6}>
            <Row>
              <label className="col-6">Current Start</label>
              <Col sm={6}>{DateFormat(subscription.current_period_start, "short")}</Col>
            </Row>
          </Col>
          <Col md={3} sm={6}>
            <Row>
              <label className="col-6">Current End</label>
              <Col sm={6}>{DateFormat(subscription.current_period_end, "short")}</Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={3} sm={6}>
            <Row>
              <label className="col-6">Stripe Created</label>
              <Col sm={6}>{DateFormat(subscription.stripe_created, "short")}</Col>
            </Row>
          </Col>
          <Col md={3} sm={6}>
            <Row>
              <label className="col-6">Billing Type</label>
              <Col sm={6}>{subscription.stripe_billing}</Col>
            </Row>
          </Col>
          <Col md={3} sm={6}>
            <Row>
              <label className="col-6">Quantity</label>
              <Col sm={6}>{subscription.quantity}</Col>
            </Row>
          </Col>
        </Row>
        {(subscription.canceled_at || subscription.ended_at) && <br />}
        {(subscription.canceled_at || subscription.ended_at) && (
          <Row>
            {subscription.canceled_at && (
              <Col md={3} sm={6}>
                <Row>
                  <label className="col-6">Stripe Canceled</label>
                  <Col sm={6}>{DateFormat(subscription.canceled_at, "short")}</Col>
                </Row>
              </Col>
            )}
            {subscription.ended_at && (
              <Col md={3} sm={6}>
                <Row>
                  <label className="col-6">Ended</label>
                  <Col sm={6}>{DateFormat(subscription.ended_at, "short")}</Col>
                </Row>
              </Col>
            )}
          </Row>
        )}
      </div>
    </>
  );
};
