import * as React from "react";
import { UnhandledException } from "./UnhandledException";
import { Nullable } from "app2/src/records";
import { dispatchException } from "app2/src/helpers/Analytics";

export interface ErrorBoundaryState {
  hasError: boolean;
  error: Nullable<Error>;
  errorInfo: Nullable<React.ErrorInfo>;
}

interface ErrorBoundaryProps {}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  public componentDidCatch?(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
    console.error(error);
    console.error(errorInfo?.componentStack);
    dispatchException(error);
  }

  public render(): React.ReactNode {
    const { hasError, ...args } = this.state;
    if (hasError) {
      return <UnhandledException {...args} />;
    }

    return this.props.children;
  }
}
