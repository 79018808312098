import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { Modal } from "react-bootstrap";
import { PdfDisplay } from "./PdfDisplay";
import Spinner from "app2/src/components/SpinnerComponent";
import * as config from "react-global-configuration";
import { DocumentRecord, fromJSON, setDisplay } from "app2/src/records/Document";
import * as documentActions from "app2/src/reducers/document.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import { WebViewerInstance } from "@pdftron/webviewer";
import { getContractName, getEstimateName } from "app2/src/records/OrgRecord";
import { JobRecord } from "app2/src/records/Job";
import { fillableFields, buildFileName, downloadFileName } from "./pdf.service";
import { DateFormat } from "app2/src/helpers/Format";
import SavePdfModal from "./SavePdfModal";
import SaveToDocumentsModal from "./SaveToDocumentsModal";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { Map, List } from "immutable";
import { debounce, isFunction } from "underscore";

const mapStateToProps = (state: RootState, ownProps: SaveablePdfDisplayProps) => {
  let org = null;
  if (ownProps.job && ownProps.job.org_id) {
    org = state.getIn(["orgs", "orgsById", ownProps.job.org_id]);
  }
  return {
    org,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: SaveablePdfDisplayProps) => {
  return {
    createDocument: (doc: DocumentRecord) => {
      return dispatch(documentActions.AsyncActions.updateOrCreateDocument(doc));
    },
    loadOrg: () => {
      return dispatch(orgActions.AsyncActions.getOrg(ownProps.job.org_id));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface SaveablePdfDisplayProps {
  job: JobRecord;
  pdfUrl: string;
  documentName?: string;
  disabledFeatures?: string[];
  modifyDoc?: (isModified: boolean, getDoc: () => Promise<DocumentRecord>) => void;
}

interface SaveablePdfDisplayState {
  showingModal: boolean;
  saving: boolean;
  blob: Blob;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & SaveablePdfDisplayProps;

class SaveablePdfDisplay extends React.PureComponent<Props, SaveablePdfDisplayState> {
  public additionalItems: any[];
  public dirtyWatcherReset = false;
  public initialAnnotationState = Map();
  public pageLayoutChanges = Map<string, List<any>>({
    added: List(),
    removed: List(),
    moved: List(),
    contentChanged: List(),
    annotationsChanged: List(),
  });
  public instance: WebViewerInstance = null;
  protected debouncedModified: (document: any, isModified: boolean) => void;

  public constructor(props) {
    super(props);
    this.state = {
      showingModal: false,
      saving: false,
      blob: null,
    };

    this.additionalItems = [
      {
        type: "actionButton",
        img: `${config.get("APP_URL")}/assets/assets/icons8-save-64.e49a357c.png`,
        onClickHandler: (docViewer, annotationManager) => {
          if ((document as any).fullscreenElement) {
            (document as any).exitFullscreen();
          }

          this.setState({ saving: true });
          const doc = docViewer.getDocument();

          annotationManager.exportAnnotations().then((xfdfString) => {
            doc
              .getFileData({
                xfdfString,
                flatten: true,
              })
              .then(
                (data) => {
                  const arr = new Uint8Array(data);
                  const blob = new Blob([arr], { type: "application/pdf" });
                  this.setState({
                    showingModal: true,
                    blob: blob,
                    saving: false,
                  });
                },
                (err) => console.error(err),
              );
          });
        },
      },
    ];

    this.download = this.download.bind(this);
    this.save = this.save.bind(this);
    this.saveToDocs = this.saveToDocs.bind(this);
    this.saveToDocsAndAgreement = this.saveToDocsAndAgreement.bind(this);
    this.fillForm = this.fillForm.bind(this);
    this.init = this.init.bind(this);
    this.check = this.check.bind(this);
    this.reset = this.reset.bind(this);
    this.viewerBuilt = this.viewerBuilt.bind(this);
    this.initializeState = this.initializeState.bind(this);
    this.triggerModifed = this.triggerModifed.bind(this);
    this.getFile = this.getFile.bind(this);
    this.debouncedModified = debounce(this.triggerModifed, 25);
  }

  public componentDidMount() {
    this.init();
  }

  public componentDidUpdate() {
    this.init();
  }

  public async initializeState() {
    if (_.isNullOrUndefined(this.instance)) {
      return;
    }
    const annotations = this.instance.Core.annotationManager.getAnnotationsList();
    this.initialAnnotationState = buildAnnotation(annotations);
  }

  public viewerBuilt(instance: WebViewerInstance) {
    this.instance = instance;
    const { documentViewer, annotationManager } = this.instance.Core;

    documentViewer.addEventListener("annotationsLoaded", async () => {
      await this.fillForm();
      this.initializeState();
    });
    documentViewer.addEventListener("pagesUpdated", this.debouncedModified);
    documentViewer.addEventListener("pagesUpdated", (changes) => {
      /**
       THE CHANGES ARE UPDATED AS FOLLOWS:
       * Deleting a page => Updates the removed array but also updates the moved array because in the place of removed page, the page beneath it replaces it and so on.
       * Addition of a page => Updates the added array but also updates the moved array because due to the additions, the page beneath it moves down as well and so on.
       * Moving a page up/down => Updates the moved array.
       * Rotation of a page => Updates the contentChanged array.
       * As for the annotationsChanged array it does not change in any case I tested for so I guess the annotationChanged event handler is already doing the work for us so it is not required.
       */
      this.pageLayoutChanges = buildPageLayoutChanges(changes, this.pageLayoutChanges);
    });
    annotationManager.addEventListener("annotationChanged", this.debouncedModified);
    annotationManager.addEventListener("fieldChanged", this.debouncedModified);
  }

  public async fillForm(): Promise<void> {
    const { documentViewer, annotationManager } = this.instance.Core;
    const { job, org } = this.props;
    await documentViewer.getAnnotationsLoadedPromise();

    const fieldManager = annotationManager.getFieldManager();

    const fillableFieldsObject = fillableFields(job, org);

    Object.keys(fillableFieldsObject || {}).forEach((fieldKey) => {
      const formField = fieldManager.getField(fieldKey);

      if (!formField) {
        return;
      }

      formField.setValue(fillableFieldsObject[fieldKey] || "");
    });
  }

  public async triggerModifed() {
    const { modifyDoc } = this.props;

    if (!isFunction(modifyDoc)) return;

    modifyDoc(true, this.getFile);
  }

  public async getFile(): Promise<DocumentRecord> {
    const { documentViewer, annotationManager } = this.instance.Core;
    const { job, documentName } = this.props;

    const doc = documentViewer.getDocument();

    annotationManager.deselectAllAnnotations();
    const xfdfString = await annotationManager.exportAnnotations();
    const docData = await doc.getFileData({
      xfdfString,
      flatten: true,
    });

    const arr = new Uint8Array(docData);
    const blob = new Blob([arr], { type: "application/pdf" });

    let data: any;

    try {
      data = new File([blob], documentName, { type: "application/pdf" });
    } catch (_err) {
      data = new Blob([blob], { type: "application/pdf" });
    }

    const record = fromJSON({
      name: documentName,
      documentable_type: "Job",
      documentable_id: job.id,
      data,
    });

    return record.set("display", setDisplay(record));
  }

  public download(): void {
    const { blob } = this.state;
    const { job } = this.props;

    let name = buildFileName(job);
    name = downloadFileName(name);

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, name);
    } else {
      const tmpHref = document.createElement("a");
      document.body.appendChild(tmpHref);
      //@ts-ignore
      tmpHref.style = "display: none";
      const csvUrl = URL.createObjectURL(blob);
      tmpHref.href = csvUrl;
      tmpHref.download = name;
      tmpHref.click();
      URL.revokeObjectURL(tmpHref.href);
      tmpHref.remove();
    }

    this.setState({ showingModal: false });
  }

  public save(displayInAgreement: boolean, displayInProposal: boolean): Promise<void> {
    const { job, createDocument, documentName } = this.props;
    const { blob } = this.state;
    const name = buildFileName(job, documentName);

    let data: any;

    try {
      data = new File([blob], name, { type: "application/pdf" });
    } catch (_err) {
      data = new Blob([blob], { type: "application/pdf" });
    }

    let record = fromJSON({
      name: name,
      documentable_type: "Job",
      documentable_id: job.id,
      data,
    });
    record = record.merge({ displayInAgreement, displayInProposal });
    record = record.set("display", setDisplay(record));

    this.setState({ showingModal: false, saving: true });
    return createDocument(record).then(() => {
      this.setState({
        saving: false,
      });
      this.initializeState();
    });
  }

  public saveToDocs() {
    this.save(false, false);
  }

  public saveToDocsAndAgreement() {
    this.save(true, false);
  }

  public reset() {
    this.dirtyWatcherReset = true;
  }

  public check() {
    if (this.dirtyWatcherReset) {
      return false;
    }

    if (_.isNullOrUndefined(this.instance)) {
      return false;
    }

    const { annotationManager } = this.instance.Core;
    const annotations = annotationManager.getAnnotationsList();
    const newState = buildAnnotation(annotations);

    return (
      !newState.equals(this.initialAnnotationState) || !this.pageLayoutChanges.every((change) => change.size === 0)
    );
  }

  public render() {
    const { pdfUrl, org, documentName, disabledFeatures } = this.props;
    const { saving, showingModal } = this.state;
    let defaultDisabledFeatures = ["Download"];

    const contractName = getContractName(org);
    const estimateName = getEstimateName(org);

    if (disabledFeatures) {
      defaultDisabledFeatures = defaultDisabledFeatures.concat(disabledFeatures);
    }

    let agreementText = "";
    if (org) {
      agreementText = `& Add to ${contractName}`;
    }
    return (
      <React.Fragment>
        <Spinner localProperty={saving} />
        {defaultDisabledFeatures.includes("Annotations") ? null : (
          <DirtyWatcher check={this.check} reset={this.reset} reactRouter />
        )}
        <PdfDisplay
          pdfUrl={pdfUrl}
          additionalItems={this.additionalItems}
          disabledFeatures={defaultDisabledFeatures}
          viewerBuiltCallback={this.viewerBuilt}
        />
        <Modal
          className="save-pdf-dialog"
          size="lg"
          show={showingModal}
          onHide={() => this.setState({ showingModal: false })}>
          {documentName ? (
            <SaveToDocumentsModal
              save={this.save}
              contractName={contractName}
              estimateName={estimateName}
              closeModal={() => this.setState({ showingModal: false })}
            />
          ) : (
            <SavePdfModal
              download={this.download}
              saveToDocs={this.saveToDocs}
              saveToDocsAndAgreement={this.saveToDocsAndAgreement}
              agreementText={agreementText}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }

  protected init() {
    const { loadOrg, job, org } = this.props;
    if (!org && job && job.id) {
      loadOrg();
    }
  }
}

export default connector(SaveablePdfDisplay);

/**
 * builds a PDF annotation state with (Id (unique identifier) immutable Map to track changes before and after editing PDF annotations
 * and removes columns that do not represent changes while editing
 * @param {any[]} annotations annotations pulled from the PDF
 * @returns {PdfAnnotationState} uses the Id (unique identifier) as a key in an immutable Map of annotations
 */
export const buildAnnotation = (annotations: any[]) => {
  let initialAnnotations = Map();
  annotations.forEach((ann: any) => {
    initialAnnotations = initialAnnotations.set(ann.Id, ann.value);
  });
  return initialAnnotations;
};

export const buildPageLayoutChanges = (
  updatedLayoutChanges: any,
  initialPageLayoutChanges: Map<string, List<any>>,
): Map<string, List<any>> => {
  return initialPageLayoutChanges.reduce((reduction: Map<string, List<any>>, value: List<any>, key: string) => {
    if (key === "moved" && !_.isEmpty(updatedLayoutChanges[key])) {
      return reduction.set(key, value.push(updatedLayoutChanges.moved));
    }
    if (updatedLayoutChanges[key].length) {
      return reduction.set(key, value.push(...updatedLayoutChanges[key]));
    }

    return reduction;
  }, initialPageLayoutChanges);
};
