import * as React from "react";
import { RsfForm, Select, FormControl, Check } from "@tberrysoln/rsf-form";
import { Button, Col, Modal, Row, Card, FormGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Actions } from "app2/src/reducers/signedDocument.actions";
import { composeValidators, email, required } from "app2/src/helpers/FinalFormValidator";
import { currentSignedDocument as currentSignedDocSelector } from "app2/src/selectors/signedDocument.selectors";
import { Map } from "immutable";

export interface SignedDocumentFormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  close: () => void;
  back?: () => void;
  message?: boolean;
}

export const SignedDocumentForm: React.FunctionComponent<SignedDocumentFormProps> = ({
  onSubmit,
  back,
  close,
  message,
}) => {
  const [includedError, setIncludedError] = React.useState(false);

  const currentSignedDocument = useSelector(currentSignedDocSelector);

  const validate = (): { name: string; error?: string }[] => {
    const errors = [];
    let includedRecipients = 0;
    let duplicateNames = Map<string, number[]>();

    currentSignedDocument.recipients.forEach((recipient, idx) => {
      if (recipient.included) {
        includedRecipients += 1;
        errors.push({ name: `recipients.${idx}.email`, error: composeValidators(required, email)(recipient.email) });

        duplicateNames = duplicateNames.set(recipient.name, [...(duplicateNames.get(recipient.name) || []), idx]);
      } else {
        errors.push({ name: `recipients.${idx}.name` });
        errors.push({ name: `recipients.${idx}.email` });
      }
    });

    duplicateNames.forEach((indexes, name) => {
      if (indexes.length > 1) {
        indexes.forEach((idx) => {
          errors.push({ name: `recipients.${idx}.name`, error: "Name must be unique" });
        });
      } else {
        errors.push({ name: `recipients.${indexes[0]}.name`, error: required(name) });
      }
    });

    if (includedRecipients === 0) {
      errors.push({ name: "included_failed", error: " " });
      setIncludedError(true);
    } else {
      errors.push({ name: "included_failed" });
      setIncludedError(false);
    }
    return errors;
  };

  return (
    <RsfForm
      rootPath={["signedDocuments", "byId", -1]}
      validate={validate}
      updateFormReducer={Actions.updateForm}
      onSubmit={onSubmit}>
      <Modal.Body className="d-flex flex-column">
        <div className="d-flex">
          <p>
            In the event there is a signer(s) missing, please ensure all necessary job documents are included for the
            contract and confirm complete contact/address information of the signing recipients below. If you have
            questions or need further assistance, please contact support.
          </p>
          {includedError && <p className="text-center red-text ml-4">At least 1 recipient must be included</p>}
        </div>
        <Row className="pb-0 overflow-hidden">
          <Col id="recipients_container" className="overflow-y-auto pr-4">
            <Row>
              {currentSignedDocument.recipients.map((recipient, index) => (
                <Col md={6} key={index}>
                  <Card className="mb-4">
                    <Card.Header className="py-2 px-3">
                      <Card.Title className="mb-0">
                        <Select
                          label="title"
                          name={`recipients.${index}.signer`}
                          disabled={!recipient.included}
                          className="mb-1">
                          <option value={""}>Manual</option>
                          <option value={"salesrep"}>Sales Representative</option>
                          <option value={"job"}>Homeowner</option>
                        </Select>
                      </Card.Title>
                    </Card.Header>

                    <Card.Body className="p-3">
                      <FormGroup>
                        <Row className="signature-row">
                          <div className="col-md-12">
                            <FormControl
                              label="name"
                              name={`recipients.${index}.name`}
                              disabled={!recipient.included}
                            />
                          </div>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row className="signature-row">
                          <div className="col-md-12">
                            <FormControl
                              label="email"
                              name={`recipients.${index}.email`}
                              disabled={!recipient.included}
                            />
                          </div>
                        </Row>
                      </FormGroup>
                      {(!recipient.required || !currentSignedDocument.estimate_id) && (
                        <FormGroup>
                          <Row className="signature-row">
                            <div className="col-md-12">
                              <Check label="Include?" name={`recipients.${index}.included`} />
                            </div>
                          </Row>
                        </FormGroup>
                      )}
                      <FormGroup className="mb-0">
                        <Row className="signature-row">
                          <div className="col-md-12">
                            <FormControl label="role" name={`recipients.${index}.role_id`} disabled />
                          </div>
                        </Row>
                      </FormGroup>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          {message && (
            <Col md={4} className="border-left pl-4 pt-2">
              <h4>Message</h4>
              <Col>
                <FormControl name="message" label="Message" as="textarea" formControlProps={{ rows: 7 } as any} />
              </Col>
            </Col>
          )}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="button-footer d-flex justify-content-between w-100 flex-md-row-reverse">
          <div className="right-footer-buttons">
            {back && (
              <Button variant="cancel" onClick={back}>
                Back
              </Button>
            )}
            <Button variant="save" type="submit" name="onscreen" disabled={includedError}>
              Onscreen
            </Button>
            <Button variant="save" type="submit" name="email" disabled={includedError}>
              Email
            </Button>
          </div>
          <div className="left-footer-buttons">
            <Button variant="cancel" onClick={close}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </RsfForm>
  );
};
