import { ThunkResult } from ".";
import { handleErrors } from "app2/src/reducers/Utils";
import { getEventResultReasons } from "../api/eventResultReason.service";
import { IEventResultReasonData } from "../records/EventResultReason";
import { RootDispatchType } from "../store";

import { ActionsUnion, createAction } from "./Utils";

export const RECEIVE_EVENT_RESULT_REASON = "@eventResults/RECEIVE_EVENT_RESULT_REASON";
export const RECEIVE_EVENT_RESULT_REASONS = "@eventResults/RECEIVE_EVENT_RESULT_REASONS";

export const Actions = {
  receiveEventResultReason: (orgId: number, reason: IEventResultReasonData) =>
    createAction(RECEIVE_EVENT_RESULT_REASON, { orgId, reason }),
  receiveEventResultReasons: (orgId: number, reasons: IEventResultReasonData[]) =>
    createAction(RECEIVE_EVENT_RESULT_REASONS, { orgId, reasons }),
};

export const AsyncActions = {
  getEventResultReasons: (orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const { result_reasons } = await getEventResultReasons(orgId);
        dispatch(Actions.receiveEventResultReasons(orgId, result_reasons));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
