import { fetcher } from "app2/src/helpers/Fetcher";
import { IFencingData, FencingRecord, toFormData, IFencingRecord } from "app2/src/records/measurements/Fencing";

export interface IFencingResponse {
  fencing: IFencingData;
}

export const measurementUrl = "api/v1/measurements/:id/fencings";
export const byMeasurementUrl = "api/v1/measurements/:id/fencing";
export const url = "api/v1/measurements/fencings/:id";

export const loadByMeasurementId = async (measurementId: number): Promise<IFencingResponse> => {
  const actionUrl = byMeasurementUrl.replace(":id", measurementId.toString());

  const data = await fetcher.get<IFencingResponse>(actionUrl);

  return data;
};

export const loadById = async (id: number): Promise<IFencingResponse> => {
  const actionUrl = url.replace(":id", id.toString());

  const data = await fetcher.get<IFencingResponse>(actionUrl);

  return data;
};

export const create = async (measurementId: number, fencing: FencingRecord): Promise<IFencingResponse> => {
  const actionUrl = measurementUrl.replace(":id", measurementId.toString());
  const fencingFormData = { fencing: transformPostData(toFormData(fencing)) };

  const data = await fetcher.post<IFencingResponse>(actionUrl, fencingFormData);

  return data;
};

export const update = async (fencing: FencingRecord): Promise<IFencingResponse> => {
  const actionUrl = url.replace(":id", fencing.id.toString());
  const fencingFormData = { fencing: transformPostData(toFormData(fencing)) };

  const response = await fetcher.patch<IFencingResponse>(actionUrl, fencingFormData);

  return response;
};

const transformPostData = (data: IFencingData): any => {
  const { fences, ...rest } = data;
  const transformed: any = { ...rest };

  transformed.fences_attributes = [];

  if (transformed.id <= 0) {
    delete transformed.id;
  }

  fences.forEach((f) => {
    (f as any).segments_attributes = f.segments.map((s) => {
      if (s.id <= 0) {
        delete s.id;
      }

      if (s._destroy === false) {
        delete s._destroy;
      }

      return s;
    });

    delete f.segments;

    if (f.id <= 0) {
      delete f.id;
    }

    if (f._destroy === false) {
      delete f._destroy;
    }

    transformed.fences_attributes.push(f);
  });

  return transformed;
};
