import { List, Record } from "immutable";
import { DropResult } from "react-beautiful-dnd";

// Takes a List of records and returns an ID < 0 & sort_order before a Save
export const nextNewId = (
  recordList: List<{ id: number; sort_order: number }>,
): { newSortOrder: number; newId: number } => {
  const newId =
    _.chain(recordList.toArray())
      .select((r) => r.id < 0)
      .map((r) => r.id)
      .push(0)
      .min()
      .value() - 1;
  let newSortOrder = 0;
  if (recordList.count() > 0) {
    newSortOrder =
      _.chain(recordList.toArray())
        .map((r) => r.sort_order)
        .push(0)
        .max()
        .value() + 1;
  }
  return { newSortOrder: newSortOrder, newId: newId };
};

// react-beautiful-dnd backwards compatibility support
export const sortDragEnd = (
  recordList: List<any>,
  result: DropResult,
  attrPath: string[] = ["sort_order"],
): { list: List<any>; dirtyIds: List<any> } => {
  return onSortEnd(recordList, result.source.index, result.destination.index, attrPath);
};

// Takes a List of records and DropResult and returns new List with sort_order set and dirty ids
// Breaks out indices to support other libraries besides react-beautiful-dnd
export const onSortEnd = (
  recordList: List<any>,
  oldIndex: number,
  newIndex: number,
  attrPath: string[] = ["sort_order"],
): { list: List<any>; dirtyIds: List<any> } => {
  const moving = recordList.get(oldIndex);
  let dirtyIds = List();

  const min = _.min([oldIndex, newIndex]);
  const max = _.max([oldIndex, newIndex]);

  const setByAttrPath = attrPath.length > 0;
  let sorted;
  if (setByAttrPath) {
    sorted = recordList.map((l) => l.getIn(attrPath)).sortBy((l) => l);
  }

  recordList = recordList
    .delete(oldIndex)
    .insert(newIndex, moving)
    .map((record, idx) => {
      if (idx >= min && idx <= max) {
        dirtyIds = dirtyIds.push(record.id).toSet().toList();
        if (setByAttrPath) {
          return record.setIn(attrPath, sorted.get(idx));
        }
      }

      return record;
    });

  return { list: recordList, dirtyIds: dirtyIds };
};

export const recordChanged = (
  current: Record<any> | List<Record<any>>,
  lastSaved: Record<any> | List<Record<any>>,
): boolean => {
  if (!lastSaved) {
    return true;
  }
  if (current.equals(lastSaved)) {
    return false;
  } else {
    return true;
  }
};

/**
 * Replaces values in a string with values from a record
 * Expected syntax: {{objectName.key}}
 * @param string The string to replace values in
 * @param record The record to get values from
 * @param objectName The name of the object in the string
 * @returns The string with values replaced
 */
export const replaceRecordValues = (string: string, record: Record<any>, objectName: string): string => {
  const regex = new RegExp(`{{${objectName}\.(.*?)}}`, "g");
  return string.replace(regex, (match, key) => record.get(key.trim()));
};
