import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IOpeningEstimationData>): OpeningEstimationRecord => {
  const record: IOpeningEstimationRecord = { ...(json as any) };

  return new OpeningEstimationRecord(record);
};
export interface IOpeningEstimationData {
  id: number;
  openable_type: string;
  openable_id: number;
  estimate_line_item_id: number;
  created_at: Date;
  updated_at: Date;
  _destroy?: boolean;
}

export interface IOpeningEstimationRecord {
  id: number;
  openable_type: string;
  openable_id: number;
  estimate_line_item_id: number;
  created_at: Date;
  updated_at: Date;
  _destroy: Nullable<boolean>;
}

const defaultOpeningEstimationProps: IOpeningEstimationRecord = {
  id: 0,
  openable_type: "",
  openable_id: 0,
  estimate_line_item_id: 0,
  created_at: null,
  updated_at: null,
  _destroy: null,
};

export class OpeningEstimationRecord
  extends Record(defaultOpeningEstimationProps)
  implements IOpeningEstimationRecord {}
