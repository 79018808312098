import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as screenShareActions from "app2/src/reducers/screenShare.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { IScreenShare } from "app/src/Models/ScreenShare";
import { RoomParams } from "app2/src/components/VideoProvider/VideoProvider";
import TwilioApp from "app2/src/components/Twilio/TwilioApp";

const mapStateToProps = (state: RootState, ownProps: AttendeeProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: AttendeeProps) => {
  return {
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
    getToken: (room, identity) =>
      dispatch(
        screenShareActions.AsyncActions.getToken({
          room: room,
          identity: identity,
        }),
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface AttendeeProps {
  jobUid: string;
  screenShare: IScreenShare;
}

interface AttendeeState {
  roomParams: RoomParams;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & AttendeeProps;

class Attendee extends React.Component<Props, AttendeeState> {
  constructor(props: Props) {
    super(props);

    this.joinRoom = this.joinRoom.bind(this);
    this.state = {
      roomParams: {
        roomName: props.screenShare.metadata.room_name,
        roomUid: props.jobUid,
        identity: props.screenShare.metadata.identity,
        host: false,
        audio_preference: props.screenShare.metadata.attendee_audio,
        roomExists: false,
      },
    };
  }

  public async joinRoom() {
    const { getToken, jobUid } = this.props;
    const { roomParams } = this.state;
    const token = await getToken(jobUid, `${roomParams.identity} - ${Math.random()}`);
    return token;
  }

  public leaveRoom() {}

  public render() {
    const { roomParams } = this.state;

    return <TwilioApp roomParams={roomParams} joinRoom={this.joinRoom} leaveRoom={this.leaveRoom} />;
  }
}

export default connector(Attendee);
