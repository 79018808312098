export interface IConfirmDialog {
  confirm(msg: string, options?: any);
}

export class ConfirmDialogService implements IConfirmDialog {
  public static $inject = ["$uibModal"];
  constructor(public $uibModal: ng.ui.bootstrap.IModalService) {}

  public confirm(msg: string, options: any = {}) {
    let body = options.body || "";
    if (options.body) {
      body = '<div class="modal-body"><p>{{body}}</p></div>';
    }
    const defaultOptions = {
      /* tslint:disable:quotemark */
      template:
        "" +
        '<div class="modal-header confirm-modal">' +
        '<h3 class="modal-title description">{{msg}}</h3>' +
        '<button type="button" class="close" aria-label="Close" ng-click="$dismiss(false)">' +
        '<span aria-hidden="true">&times;</span>' +
        "</button>" +
        "</div>" +
        "{{body}}" +
        '<div class="modal-footer">' +
        '<button class="btn btn-default" type="button" ng-click="$close()">OK</button>' +
        '<button class="btn btn-cancel" type="button" ng-click="$dismiss(false)">Cancel</button>' +
        "</div>",
      size: "sm",
      windowClass: "on-top-modal",
      controller: [
        "$scope",
        function ($scope) {
          $scope.msg = msg;
        },
      ],
    };
    return this.$uibModal.open($.extend({}, defaultOptions, options)).result;
  }
}
