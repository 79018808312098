import * as React from "react";
import LandingEstimateWrapper from "app2/src/components/Estimate/LandingEstimateWrapper";
import ApplyByProvider from "app2/src/components/FinancingApplication/ApplyByProvider";

interface FinancingApplicationProps {
  $stateParams: { id: string };
  $location: any;
}

export default class FinancingApplication extends React.Component<FinancingApplicationProps> {
  constructor(props: FinancingApplicationProps) {
    super(props);
  }

  public render() {
    const id = this.props.$stateParams.id;
    const queryParams = this.props.$location.search();
    return <LandingEstimateWrapper id={id} queryParams={queryParams} WrappedComponent={ApplyByProvider} />;
  }
}
