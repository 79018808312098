import { InteriorExteriorButton } from "app2/src/components/JobTabs/MeasurementTab/InteriorExteriorButton";
import { exteriorMeasurements } from "app2/src/records/Measurement";
import { capitalize } from "humanize-plus";
import * as React from "react";
import { Col, Row, Tabs, Tab, Card } from "react-bootstrap";
import { currentOrgId, preferencesConfig } from "app2/src/selectors/org.selectors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { Roofing } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Roofing";
import { Siding } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Siding";
import { Sunroom } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Sunroom";
import { Walls } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Walls";
import { Windows } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Windows";
import { Doors } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Doors";
import { Decks } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Decks";
import { Pools } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Pools";
import { Fencing } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Fencing";
import { push } from "connected-react-router/immutable";
import { useTracking } from "react-tracking";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";

export const Exteriors: React.FC = () => {
  // Hooks
  const match = useRouteMatch();
  const route = useRouteMatch<{ activeKey: string }>(`${match.url}/:activeKey`);
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "Exteriors" });

  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const wallFacadeIds = measurement?.get("wall_facade_ids") || List();
  const permissions = useSelector((state: RootState) =>
    preferencesConfig(state, { orgId: currentOrgId(state), path: ["measurement", "job_tab"], notSet: List() }),
  );
  const validExteriorTabs = exteriorMeasurements.filter((tab) => {
    if (tab === "walls" && wallFacadeIds.size === 0) {
      return false;
    }
    return permissions.includes(tab);
  });

  // Methods
  const tabNavigate = (data) => {
    trackEvent({ action: "exterior tab navigate", tab: data });
    dispatch(push(data));
  };
  const renderRedirect = () => {
    if (validExteriorTabs.first()) {
      return <Redirect to={`${match.url}/${validExteriorTabs.first()}`} />;
    }
  };
  const tabComponent = (tab: string) => {
    switch (tab) {
      case "roofing":
        return Roofing;
      case "siding":
        return Siding;
      case "sunroom":
        return Sunroom;
      case "walls":
        return Walls;
      case "windows":
        return Windows;
      case "doors":
        return Doors;
      case "decks":
        return Decks;
      case "pools":
        return Pools;
      case "fencing":
        return Fencing;
      default:
        return null;
    }
  };

  return (
    <Row>
      <Col>
        <InteriorExteriorButton kind="exterior" />
        <Tabs onSelect={tabNavigate} aria-label="exterior-tabs" activeKey={route?.params?.activeKey}>
          {validExteriorTabs.map((tab) => (
            <Tab key={tab} eventKey={tab} title={capitalize(tab)} />
          ))}
        </Tabs>
        <Card className="border-top-0">
          <Card.Body>
            <Switch>
              {validExteriorTabs.map((tab) => (
                <Route key={tab} path={`${match.url}/${tab}`} component={tabComponent(tab)} />
              ))}
              <Route path={`${match.url}`} render={renderRedirect} />
            </Switch>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
