import { DocumentRecord, IDocumentData } from "app2/src/records/Document";
import { IImageData, ImageRecord } from "app2/src/records/Image";
import { Record, List } from "immutable";
import { SnakeToCamelKeys } from "app2/src/helpers/Format";
import { checkDate, Nullable } from "app2/src/records";
import {
  EstimateTemplateGroupRecord,
  IEstimateTemplateGroupData,
  fromJSON as estimateTemplateGroupFromJSON,
} from "app2/src/records/EstimateTemplateGroup";
import { fromJSON as imageFromJSON } from "app2/src/records/Image";
import { fromJSON as documentFromJSON } from "app2/src/records/Document";

export const fromJSON = (data: Partial<IEstimateTemplateData>): EstimateTemplateRecord => {
  const record: Partial<IEstimateTemplateRecord> = SnakeToCamelKeys(data);

  if (data.template) {
    record.template = templateFromJSON(data.template);
  }

  if (data.images) {
    record.images = List(data.images.map((image) => imageFromJSON(image)));
  }

  if (data.documents) {
    record.documents = List(data.documents.map((doc) => documentFromJSON(doc)));
  }

  if (data.created_at) {
    record.createdAt = checkDate(data.created_at);
  }

  if (data.updated_at) {
    record.updatedAt = checkDate(data.updated_at);
  }

  return new EstimateTemplateRecord(record);
};

export const templateFromJSON = (data: Partial<ITemplateData>): TemplateRecord => {
  const record: Partial<ITemplateRecord> = SnakeToCamelKeys(data);

  if (data.groups) {
    record.groups = List(data.groups.map((group) => estimateTemplateGroupFromJSON(group)));
  }

  return new TemplateRecord(record);
};

export interface ITemplateData {
  groups: IEstimateTemplateGroupData[];
}

export interface ITemplateRecord {
  groups: List<EstimateTemplateGroupRecord>;
}

const defaultTemplateProps: ITemplateRecord = {
  groups: List(),
};

export class TemplateRecord extends Record(defaultTemplateProps) implements ITemplateRecord {}

export interface IEstimateTemplateData {
  id: number;
  org_id: number;
  folder_id: number;
  name: string;
  template: ITemplateData;
  sort_order: number;
  images: IImageData[];
  documents: IDocumentData[];
  loading?: boolean;
  selected?: boolean;
  kind: "standard" | "interior";

  created_at: Date;
  updated_at: Date;
}

export interface IEstimateTemplateRecord {
  id: number;
  orgId: Nullable<number>;
  folderId: Nullable<number>;
  name: string;
  template: TemplateRecord;
  sortOrder: Nullable<number>;
  images: List<ImageRecord>;
  documents: List<DocumentRecord>;
  loading: boolean;
  selected: boolean;
  kind: "standard" | "interior";

  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
}

const defaultEstimateTemplateProps: IEstimateTemplateRecord = {
  id: 0,
  orgId: null,
  folderId: null,
  name: "",
  template: new TemplateRecord(),
  sortOrder: null,
  images: List(),
  documents: List(),
  loading: false,
  selected: false,
  kind: "standard",

  createdAt: null,
  updatedAt: null,
};

export class EstimateTemplateRecord extends Record(defaultEstimateTemplateProps) implements IEstimateTemplateRecord {}
