import * as React from "react";
import { connectFC } from "app2/src/connect";
import { history } from "app2/src/store";
import { ConnectedRouter } from "connected-react-router/immutable";
import { List } from "immutable";
import { Col, Row } from "react-bootstrap";
import { EventsCalendar } from "../Common/EventsCalendar";
import { Context, ContextRecord, DateRange } from "../Common/EventsCalendar/Context";
import { Switch, Route } from "react-router-dom";
import { UserFilter } from "../Common/EventsCalendar/UserFilter";
import { currentUser as currentUserSelector } from "app2/src/selectors/user.selectors";
import { useDispatch, useSelector } from "react-redux";
import { AsyncActions } from "app2/src/reducers/event.actions";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { JobsToAssignDropdown } from "./JobsToAssignDropdown";
import { getAllPaginationByModel, pageRecord } from "app2/src/selectors/pagination.selectors";
import { RootState, ThunkResult } from "app2/src/reducers";
import { queryParamsFromJSON } from "app2/src/records/Page";
import SpinnerComponent from "../SpinnerComponent";
import { eventColor } from "app2/src/selectors/event.selectors";
import { useTracking } from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";
import track from "react-tracking";

export const SchedulerTab: React.FC = () => {
  const modelName = "events";
  const includes = ["job_basic", "assignments"];
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "SchedulerTab" });

  const [userIds, setUserIds] = React.useState(List<number>());

  // Selectors
  const currentUser = useSelector(currentUserSelector);
  const jobAssignedEvents = useSelector((state: RootState) =>
    getAllPaginationByModel(state, { path: [modelName, "byId"], modelName }),
  );
  const [localLoading, setLocalLoading] = React.useState(false);
  const userEvents = useSelector((state: RootState) =>
    getAllPaginationByModel(state, { path: [modelName, "byId"], modelName: "userEvents" }),
  );

  // Local State
  const [dateRange, setDateRange] = React.useState<DateRange>({
    start: null,
    end: null,
  });
  const [userToAssignId, setUserToAssignId] = React.useState(currentUser?.get("id"));

  // Lifecycle
  React.useEffect(() => {
    return () => {
      dispatch(paginationActions.Actions.clearPage(modelName));
      dispatch(paginationActions.Actions.clearPage("userEvents"));
    };
  }, []);

  // Methods
  const loadToAssignEvents = (dateRange: DateRange, userToAssignId: number): ThunkResult<Promise<void>> => {
    dispatch(paginationActions.Actions.clearPage("events"));
    return dispatch(
      AsyncActions.loadEvents(
        queryParamsFromJSON({
          page: 1,
          parameters: {
            start_time: dateRange.start,
            end_time: dateRange.end,
            job_assigned_user_id: userToAssignId.toString(),
            "include[]": includes,
          },
        }),
        "events",
        true,
      ),
    );
  };

  const loadUserEvents = (dateRange: DateRange, userIds: List<number>): ThunkResult<Promise<void>> => {
    dispatch(paginationActions.Actions.clearPage("userEvents"));
    return dispatch(
      AsyncActions.loadEvents(
        queryParamsFromJSON({
          page: 1,
          parameters: {
            start_time: dateRange.start,
            end_time: dateRange.end,
            "user_ids_all_appointments[]": userIds.toJS(),
            "include[]": includes,
          },
        }),
        "userEvents",
        true,
      ),
    );
  };

  const handleUserFilterChange = async (newUsersList: List<number>) => {
    if (newUsersList.size > 0) {
      setLocalLoading(true);
      await loadUserEvents(dateRange, newUsersList);
      setLocalLoading(false);
    } else {
      dispatch(paginationActions.Actions.clearPage("userEvents"));
    }
  };

  const handleJobToAssignChange = async (newUserToAssignId: number) => {
    setLocalLoading(true);
    trackEvent({ action: "job assigned user changed", user: newUserToAssignId });
    setUserToAssignId(newUserToAssignId);
    setUserIds(List());

    await loadToAssignEvents(dateRange, newUserToAssignId);
    dispatch(paginationActions.Actions.clearPage("userEvents"));
    setLocalLoading(false);
  };

  const handleDateChange = async (dateRange: DateRange) => {
    setLocalLoading(true);
    if (userIds.size > 0) {
      await loadUserEvents(dateRange, userIds);
    }
    await loadToAssignEvents(dateRange, userToAssignId);
    setLocalLoading(false);
  };

  return (
    <Context.Provider
      value={
        new ContextRecord({
          dateRange,
          setDateRange,
          userIds,
          setUserIds,
          events: List([...jobAssignedEvents, ...userEvents]),
          loading: localLoading,
          includes,
          setEventColor: eventColor,
        })
      }>
      <SpinnerComponent localProperty={localLoading} />
      <Row className="height-100vh">
        <Col>
          <Row className="mb-3">
            <Col className="d-flex align-items-center">
              <h1 className="mb-0 mr-auto">Scheduler</h1>
              <JobsToAssignDropdown selectedUserId={userToAssignId} onChange={handleJobToAssignChange} />
              <UserFilter onUsersChange={handleUserFilterChange} userToAssignId={userToAssignId} />
            </Col>
          </Row>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/scheduler">
                <EventsCalendar onDateChange={handleDateChange} disabledViews={["dayGridMonth"]} eventAddDisabled />
              </Route>
            </Switch>
          </ConnectedRouter>
        </Col>
      </Row>
    </Context.Provider>
  );
};

export default connectFC(track({ category: "Scheduler" }, { dispatch: Dispatch.dispatch })(SchedulerTab));
