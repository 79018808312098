import { OpeningRow } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/OpeningRow";
import { OpeningRecord } from "app2/src/records/Opening";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";
import { List } from "immutable";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

interface IOpeningsProps {
  openingRecords: List<OpeningRecord>;
  destroyCallback: (id: number) => void;
}
export const Openings: React.FC<IOpeningsProps> = ({ openingRecords, destroyCallback }) => {
  // Selectors
  const editing = useSelector(measurementEditing);

  return (
    <div className="table-responsive">
      <Table className={editing ? "reduce-padding" : ""}>
        <thead>
          <tr>
            <th>Designator</th>
            <th>Name</th>
            <th>Location</th>
            <th>Level</th>
            <th>Width</th>
            <th>Height</th>
            <th>UI</th>
            <th>Area</th>
          </tr>
        </thead>
        <tbody>
          {openingRecords?.map((opening, idx) => {
            return <OpeningRow key={idx} opening={opening} destroyCallback={destroyCallback} />;
          })}
        </tbody>
      </Table>
    </div>
  );
};
