import { fetcher } from "../helpers/Fetcher";
import { INoteData, NoteRecord } from "../records/Note";
import { baseCleanProps } from "./Api";
import { QueryParamsRecord } from "../records/Page";
import { IMetaPaginationData } from "../records/MetaPagination";

export interface INoteIndexResponse {
  notes: INoteData[];
  meta: { pagination: IMetaPaginationData };
}

export interface INoteResponse {
  note: INoteData;
}

export const updateOrCreate = (note: NoteRecord): Promise<INoteResponse> => {
  if (note.id <= 0) {
    return create(note);
  }
  return update(note);
};

export const load = async (id: number, queryParams: QueryParamsRecord): Promise<INoteIndexResponse> => {
  return await fetcher.get<INoteIndexResponse>(`api/v1/jobs/${id}/notes`, queryParams.toJS());
};

export const create = async (note: NoteRecord): Promise<INoteResponse> => {
  return await fetcher.post<INoteResponse>(`api/v1/jobs/${note.noteable_id}/notes`, { note: cleanProps(note) });
};

export const update = async (note: NoteRecord): Promise<INoteResponse> => {
  return await fetcher.patch<INoteResponse>(`api/v1/jobs/${note.noteable_id}/notes/${note.id}`, {
    note: cleanProps(note),
  });
};

export const destroy = async (note: NoteRecord): Promise<INoteResponse> => {
  return await fetcher.delete<INoteResponse>(`api/v1/jobs/${note.noteable_id}/notes/${note.id}`);
};

export const cleanProps = (note: NoteRecord): INoteData => {
  let noteParams = note.toJS();
  noteParams = baseCleanProps(noteParams);
  return noteParams as any as INoteData;
};
