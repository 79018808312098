import * as deckActions from "./deck.actions";
import { Map, Record } from "immutable";
import { DeckRecord, fromJSON } from "../records/Deck";
import { RootActions, RootState } from ".";

export const DeckStateRecord = Record({
  byId: Map<number, DeckRecord>(),
  lastSavedById: Map<number, DeckRecord>(),
  unsavedId: -1,
});

export const initialState = DeckStateRecord();

export type DeckState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "decks";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let deck: DeckRecord;
  switch (action.type) {
    case deckActions.RECEIVE_DECK:
      deck = fromJSON({ ...action.payload.deck });

      return state.setIn([model, "byId", deck.id], deck).setIn([model, "lastSavedById", deck.id], deck);
    case deckActions.EDIT_DECK:
      deck = fromJSON({ ...action.payload.deck });

      return state.setIn([model, "byId", deck.id], deck);

    default:
      return state;
  }
};
