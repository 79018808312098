import * as React from "react";
import { Row, Col } from "react-bootstrap";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { connect, ConnectedProps } from "app2/src/connect";
import track from "react-tracking";
import { RootDispatchType } from "app2/src/store";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { Dispatch } from "app2/src/helpers/Analytics";
import * as config from "react-global-configuration";
import InputCopy from "app2/src/components/Common/InputCopy";

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    flashAddAlert: (msg: string) => dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, msg)),
  };
};

const connector = connect(null, mapDispatchToProps);

interface BillingInvitesState {}

interface BillingInvitesProps {
  uuid: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & BillingInvitesProps;

@track(
  () => {
    return {
      component: "BillingInvites",
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
class BillingInvites extends React.Component<Props, BillingInvitesState> {
  constructor(props: Props) {
    super(props);
  }

  public paymentInfoUrl() {
    const { uuid } = this.props;
    return `${config.get("APP_URL")}/payment_info/${uuid}`;
  }

  public hrefPaymentInfoUrl() {
    return `<a href="${this.paymentInfoUrl()}">Click Here to Complete the Payment Process</a>`;
  }

  public render() {
    return (
      <Row>
        <Col>
          <InputCopy value={this.paymentInfoUrl()} label="Pay On Screen Link" id="payOnScreenLink" />
        </Col>
        <Col>
          <InputCopy value={this.hrefPaymentInfoUrl()} label="A-HREF" id="aHref" />
        </Col>
      </Row>
    );
  }
}

export default connector(BillingInvites);
