import { IJob } from "app/src/Models/Job";
import * as angulartics from "angulartics";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IVisualization } from "app/src/Models/Visualization";
import { IToolInfo } from "app/src/Common/ToolInfoService";

export class VisualizationModalCtrl {
  public editing = false;

  public static $inject = ["$uibModalInstance", "job", "visualization", "$analytics", "Pretty", "ToolInfo"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public job: IJob,
    public visualization: IVisualization,
    public $analytics: angulartics.IAnalyticsService,
    public Pretty: IPretty,
    public ToolInfo: IToolInfo,
  ) {
    if (this.visualization.id) {
      this.editing = true;
    }
  }

  public cancel() {
    this.trackEvent("cancel button", {
      Category: "VisualizationModal",
    });
    this.$uibModalInstance.dismiss();
  }

  // noinspection JSUnusedGlobalSymbols
  public checkKind() {
    if (this.ToolInfo.tools(this.visualization.kind).api) {
      this.$uibModalInstance.close(this.visualization);
    }
  }

  public save() {
    this.$uibModalInstance.close(this.visualization);
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        job: this.job.id,
        org: this.job.org_id,
      }),
    );
  }
}
