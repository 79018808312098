import { RootDispatchType } from "app2/src/store";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { createAction } from "./UtilsTypes";
import type { Action, AnyFunction, StringMap, ActionsUnion } from "./UtilsTypes";
export { Action, AnyFunction, StringMap, ActionsUnion, createAction };

export const handleErrors = (response: any, flashDispatch?: RootDispatchType): string[] => {
  let errors = [];

  console.error(response);

  if (!response) {
    errors = ["Invalid Response - Please try again. If the problem persists, please contact support."];
  } else if (Array.isArray(response)) {
    errors = response;
  } else if (response.status === 500) {
    errors = ["Internal Server Error - Please try again. If the problem persists, please contact support."];
  } else if (Array.isArray(response.data?.errors) || typeof response.data?.errors === "string") {
    errors = errors.concat(response.data.errors);
  } else {
    errors = [
      "Unknown Error - Please check your internet connection. If the problem persists, please contact support.",
    ];
  }

  if (flashDispatch) {
    flashDispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
  }

  return errors;
};

export const handleErrorsWithRejection = (response: any): Promise<string[]> => Promise.reject(handleErrors(response));
