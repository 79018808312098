import { RootState } from "app2/src/reducers";
import { currentMeasurementId } from "app2/src/selectors/measurementCommon.selectors";
import { measurementIdFencingId } from "app2/src/selectors/measurements/fencing.selectors";
import * as React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FencingSummary from "app2/src/components/Drawing/Fencing/FencingSummary";
import { AsyncActions } from "app2/src/reducers/measurements/fencing.actions";
import { useTracking } from "react-tracking";
import FencingContainer from "app2/src/components/Drawing/Fencing";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";

export const Fencing: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "Fencing" });

  // Local State
  const [openFencing, setOpenFencing] = React.useState(0);

  // Selectors
  const measurementId = useSelector((state: RootState) => currentMeasurementId(state, {}));
  const fencingId = useSelector((state: RootState) => measurementIdFencingId(state, { measurementId }));
  const editing = useSelector(measurementEditing);

  // Methods
  const openFencingTool = () => {
    trackEvent({ action: "open fencing tool" });
    setOpenFencing((value) => value + 1);
  };

  // Lifecycle
  React.useEffect(() => {
    dispatch(AsyncActions.fetchByMeasurementId(measurementId));
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h2>Fencing</h2>
        </Col>
        <Col>
          <Button
            variant="primary"
            onClick={openFencingTool}
            className="float-right"
            disabled={editing}
            title={editing ? "Disabled for editing" : ""}>
            Fencing Tool
          </Button>
        </Col>
      </Row>
      <FencingSummary fencingId={fencingId} expanded={true} readOnly />
      <FencingContainer openIdx={openFencing} />
    </>
  );
};
