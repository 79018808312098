import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Card, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IJob } from "app/src/Models/Job";
import { IEstimate } from "app/src/Models/Estimate";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";
import InspectionGroup from "./InspectionGroup";
import inspectionService from "./Inspection.service";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import { IDirtyWatcher, IDirtyMerge } from "app/src/Common/DirtyWatcher";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import * as FontAwesome from "react-fontawesome";
import { currentJob } from "app2/src/selectors/job.selectors";
import { IJobFetcherService, JobFetcherService } from "app/src/Jobs/JobFetcherService";
import * as imageActions from "app2/src/reducers/image.actions";
import { QueryParamsRecord } from "app2/src/records/Page";

const mapStateToProps = (state: RootState, ownProps: IInspectionContainerProps) => {
  const job = currentJob(state);
  return {
    job: job,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: IInspectionContainerProps,
) => {
  return {
    listImages: (jobId: number) =>
      dispatch(
        imageActions.AsyncActions.listImages({ imageableId: jobId, imageableType: "job" }, new QueryParamsRecord()),
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IInspectionContainerProps {
  queue: IFileQueueFactory;
  EstimatorService: EstimatorService;
  JobFetcher: IJobFetcherService;
  $scope: ng.IScope;
  $analytics: angulartics.IAnalyticsService;
  DirtyWatcher: IDirtyWatcher;
  tracking?: TrackingProp;
  email(estimate: IEstimate): void;
  download(estimate: IEstimate): void;
}

const track: Track<TrackingData, IInspectionContainerProps> = _track;

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IInspectionContainerProps;

export interface IInspectionContainerState {
  loading: boolean;
  estimate: IEstimate;
  hasPriceList: boolean;
  dirty: boolean;
}

@track(
  (props: Props) => {
    return {
      category: "Inspection Editor",
      action: "Show",
      job: props.job.id,
      org: props.job.org_id,
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: true,
  },
)
class InspectionContainer extends React.Component<Props, IInspectionContainerState> implements IDirtyMerge {
  constructor(props: Props) {
    super(props);

    Dispatch.analytics = props.$analytics;

    this.state = {
      estimate: null,
      loading: true,
      hasPriceList: true,
      dirty: false,
    };

    const { DirtyWatcher, $scope } = props;

    DirtyWatcher.setup($scope, this);

    this.save = this.save.bind(this);
    this.check = this.check.bind(this);
  }

  public async componentDidMount() {
    const { job, EstimatorService, JobFetcher, listImages } = this.props;

    const fetchedJob: IJob = JobFetcher.get(job.id);
    await fetchedJob.$promise;

    if (!fetchedJob.org.activated_inspection_list || !fetchedJob.org.activated_inspection_list.id) {
      this.setState({
        loading: false,
        hasPriceList: false,
      });
      return;
    }
    let estimate: IEstimate;
    if (Array.isArray((fetchedJob as any).inspections) && (fetchedJob as any).inspections.length > 0) {
      estimate = EstimatorService.reloadEstimate((fetchedJob as any).inspections[0].id);
    } else {
      estimate = EstimatorService.createEstimate(fetchedJob.id, "inspection");
    }
    await estimate.$promise;
    await EstimatorService.priceListPromise;
    if ((estimate.id as any) === "new") {
      inspectionService.clearEstimate(estimate);
      inspectionService.setupEstimate(estimate, EstimatorService);
    }
    this.setState({
      estimate: estimate,
      loading: false,
    });

    listImages(job.id);
  }

  public render() {
    const { estimate, loading, hasPriceList, dirty } = this.state;
    const { queue, email, download } = this.props;

    if (!hasPriceList) {
      return this.noPriceList();
    }

    if (!estimate) {
      return <SpinnerComponent localProperty={loading} />;
    }

    return (
      <SpinnerComponent localProperty={loading}>
        <Row className="inspection">
          <Col sm={12}>
            <Button variant="primary" className="pull-right" onClick={this.save} disabled={!dirty}>
              Save
            </Button>
            <Button
              disabled={dirty || (estimate.id as any) === "new"}
              variant="primary"
              className="pull-right"
              onClick={() => {
                email(estimate);
              }}>
              Email Inspection
            </Button>
            <Button
              disabled={dirty || (estimate.id as any) === "new"}
              variant="primary"
              className="pull-right"
              onClick={() => {
                download(estimate);
              }}>
              <FontAwesome name="download" title="Download" />
            </Button>
          </Col>
          <Col sm={12}>
            <Tabs id="inspection">
              {estimate.groups.map((g, idx) => {
                return (
                  <Tab eventKey={g.name} title={g.name} key={idx}>
                    <Card>
                      <Card.Title>
                        <Row className="inspection-text ml-0 mr-0">
                          <Col sm={3} md={5} lg={5} className="d-flex align-items-center mb-0 pl-0">
                            <Row className="inspection-text mb-0">
                              <Col>Inspection Item</Col>
                            </Row>
                          </Col>
                          <Col sm={3} md={2} lg={2} className="center-checkbox">
                            Satisfactory
                          </Col>
                          <Col sm={1} md={2} lg={2} className="center-checkbox offset-sm-1 offset-md-0 offset-lg-0">
                            Needs Attention
                          </Col>
                          <Col sm={4} md={2} lg={2} className="center-checkbox">
                            Add Picture
                          </Col>
                        </Row>
                        <Row className="justify-content-end">
                          <Col sm={6} lg={3} md={3}>
                            {this.renderCheckBox(g, "a")}
                          </Col>
                          <Col sm={6} lg={3} md={3}>
                            {this.renderCheckBox(g, "b")}
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Body>
                        <InspectionGroup
                          activatedPriceListId={estimate.activated_price_list_id}
                          queue={queue}
                          group={g}
                          check={this.check}
                          estimate={estimate}
                        />
                      </Card.Body>
                    </Card>
                  </Tab>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </SpinnerComponent>
    );
  }

  public renderCheckBox(group, prop: "a" | "b") {
    return (
      <div className="form-group add-to-presentation inspection-text">
        <label>Add All to {prop === "a" ? "Agreement" : "Presentation"}</label>
        <div className="toggle-container">
          <input
            type="checkbox"
            checked={inspectionService.checkGroupVisibility(group, prop)}
            onChange={(event) => this.inclusionChanged(group, prop, event.target.checked)}
            className="toggle form-control"
            id="switch-normal"
          />
        </div>
      </div>
    );
  }

  /*
   * "a" -> Include on Agreement
   * "b" -> Include on Presentation
   */
  public inclusionChanged(group: IEstimateGroup, prop: "a" | "b", value: boolean) {
    const { estimate } = this.state;

    this.setState({
      estimate: inspectionService.setGroupVisibility(estimate, group, prop, value),
    });
    this.check();
  }

  public check(): boolean {
    const { EstimatorService } = this.props;
    const newCheck = EstimatorService.checkEstimateState();
    this.setState({
      dirty: newCheck,
    });
    return newCheck;
  }

  public reset() {
    const { EstimatorService } = this.props;
    const { estimate } = this.state;

    this.setState({
      loading: true,
    });

    const promise = EstimatorService.reloadEstimate(estimate.id).$promise;
    promise.then(
      () => {
        this.setState({
          loading: false,
        });
      },
      () => {
        this.setState({
          loading: false,
        });
      },
    );

    return promise;
  }

  public trackEvent = (action: any, props: any): void => {
    const { tracking } = this.props;
    tracking.trackEvent({
      action: action,
      ...props,
    });
  };

  protected save() {
    const { EstimatorService, queue } = this.props;
    const { estimate } = this.state;

    _.each(estimate.lineItems(), (li) => {
      queue.cleanFileQueue(li, true);
    });
    this.setState({ loading: true });

    EstimatorService.saveEstimate().then(
      () => {
        return queue.uploadFileQueue(estimate).then(() => {
          EstimatorService.resetEstimateState();
          this.check();
          this.setState({ loading: false });
        });
      },
      (errors) => console.error(errors),
    );
  }

  protected noPriceList = () => {
    return (
      <div className="inspection">
        <div className="form-section blank-state alert alert-danger">
          <h2>The Inspection Configuration for this Organization doesn't exist!</h2>
          <h4>An Inspection can not be created without Inspection Configuration.</h4>
        </div>
      </div>
    );
  };
}

export default connector(InspectionContainer);
