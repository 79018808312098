import { FormControl, RsfForm, Select } from "@tberrysoln/rsf-form";
import { connectFC } from "app2/src/connect";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as orgActions from "app2/src/reducers/org.actions";
import { useSelector } from "react-redux";
import { settingsConfig, currentOrgId } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";
import * as FontAwesome from "react-fontawesome";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";

export interface CommissionSettingsProps {
  updateSettingsConfig: (key: string, value: any) => void;
}

const CommissionSettings: React.FC<CommissionSettingsProps> = ({ updateSettingsConfig }) => {
  const orgId = useSelector(currentOrgId);
  const method = useSelector((state: RootState) =>
    settingsConfig(state, { orgId, path: ["estimator", "commission_calculator", "method"], notSet: "off" }),
  );

  const custom_config = useSelector((state: RootState) =>
    settingsConfig(state, { orgId, path: ["estimator", "commission_calculator", "custom_config"], notSet: null }),
  );

  const handleChange = (event) => {
    const { name, value } = event;
    if (value === "custom" && !custom_config)
      return commonActions.Actions.flashAddAlert(
        FlashLevels.danger,
        "'Custom' can not be selected  if config is empty",
      );
    updateSettingsConfig(name, value);
    return orgActions.Actions.updateForm(event);
  };

  return (
    <div className="form-section">
      <div className="form-section-heading">
        <h3>Commission Settings</h3>
      </div>
      <div className="form-section-content">
        <Row>
          <Col md="12">
            <RsfForm
              rootPath={["orgs", "orgsById", orgId, "settings", "config"]}
              updateFormReducer={handleChange}
              onSubmit={() => null}>
              <Row>
                <Col md="3">
                  <Select label="Method" name="estimator.commission_calculator.method">
                    <option value="off">Off</option>
                    <option value="margin_with_overhead">Margin with Overhead</option>
                    <option value="formula">Formula</option>
                    <option value="greenline">Greenline</option>
                    <option value="custom">Custom</option>
                  </Select>
                </Col>
                {method === "formula" && (
                  <Col md="3">
                    <FormControl label="Formula" name="estimator.commission_calculator.formula" />
                  </Col>
                )}
                {(method === "greenline" || method === "custom") && (
                  <div className="form-percentage">
                    <FormControl
                      label="Greenline Percentage"
                      name="estimator.commission_calculator.greenline_percentage"
                      formControlProps={{ type: "number" }}
                      numberInputProps={{ min: 0 } as any}
                    />
                    <FontAwesome name="percent" className="fa-gray" />
                  </div>
                )}
                {method === "custom" && (
                  <>
                    <Col md="7">
                      {/* Using Form.Control instead of FormControl so that real value does not get changed if readonly removed from browser console */}
                      <Form.Label htmlFor="custom_config">Custom Config</Form.Label>
                      <Form.Control
                        readOnly
                        as="textarea"
                        id="custom_config"
                        rows={3}
                        value={JSON.stringify(custom_config, null, 2)}
                      />
                    </Col>
                  </>
                )}
              </Row>
              {method === "formula" && (
                <Row>
                  <Col md="6">
                    <div>Available variables: sale_price, commission_rate, overhead_rate, cost</div>
                  </Col>
                </Row>
              )}
            </RsfForm>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default connectFC(CommissionSettings);
