import * as React from "react";
import { capitalizeString } from "app2/src/services/string.service";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { allSigned } from "app2/src/records/SignedDocument";
import { RecipientRecord } from "app2/src/records/Recipient";
import { AsyncActions } from "app2/src/reducers/signedDocument.actions";
import * as documentActions from "app2/src/reducers/signedDocument.actions";
import { signedDocument as signedDocumentSelector } from "app2/src/selectors/signedDocument.selectors";
import { useRouteMatch } from "react-router";
import { push } from "connected-react-router/immutable";
import { pathname } from "app2/src/selectors/router.selectors";
import { getTitle } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";

export const SigningRecipientsModal: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const path = useSelector(pathname);
  const match = useRouteMatch<{ signedDocumentId: string }>();

  // Selectors
  const signedDocumentId = parseInt(match.params.signedDocumentId);
  const signedDocument = useSelector((state: RootState) => signedDocumentSelector(state, signedDocumentId));
  const contractTitle = useSelector((state) => getTitle(state, { documentType: "contract" }));

  // Lifecycle
  React.useEffect(() => {
    dispatch(documentActions.AsyncActions.loadSignedDocument(signedDocumentId));
  }, [signedDocumentId]);

  // Methods
  const email = (recipientId: number) => {
    dispatch(documentActions.AsyncActions.notify(signedDocument.id, recipientId));
  };

  const emailAll = () => {
    dispatch(documentActions.AsyncActions.notify(signedDocument.id));
  };

  const close = () => {
    const prevPath = path.split("/signing")[0];
    dispatch(push(prevPath));
  };

  const isDocumentCompleted: boolean = React.useMemo(
    () => (signedDocument ? allSigned(signedDocument) : false),
    [signedDocument],
  );

  return (
    <Modal show={true} size="lg" onHide={close} backdrop="static">
      <Modal.Header closeButton>
        <h3 className="mb-0">Signing Recipients</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="standard-rl-margin">
          <Row className="table-header">
            <Col md={5}>Signer</Col>
            <Col md={2}>Status</Col>
            <Col md={5}>Actions</Col>
          </Row>
          {signedDocument?.recipients?.map((recipient, index) => (
            <Row key={recipient.id} className="table-row">
              <Col md={5}>
                {recipient.name} ({recipient?.email})
              </Col>
              <Col md={2}>{capitalizeString(recipient.status).replace("_", " ")}</Col>
              <Col md={5} style={{ whiteSpace: "nowrap" }}>
                <Row>
                  {isDocumentCompleted ? (
                    <Col sm={12}>
                      <Button className="btn-block" onClick={() => email(recipient.id)}>
                        Resend Completed {contractTitle} Email
                      </Button>
                    </Col>
                  ) : (
                    <>
                      <Col sm={6}>
                        {recipient.status === "recipient_signed" ? (
                          <a className="btn btn-primary-disabled" id={`onscreen_link_disabled_${index}`}>
                            Onscreen
                          </a>
                        ) : (
                          <a
                            href={recipient.rs_url}
                            target="_blank"
                            className="btn btn-primary"
                            id={`onscreen_link_${index}`}>
                            Onscreen
                          </a>
                        )}
                      </Col>
                      <Col sm={6}>
                        <Button
                          disabled={recipient.status === "recipient_signed"}
                          variant="success"
                          onClick={() => email(recipient.id)}
                          className="btn-block ml-2">
                          Email
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        {isDocumentCompleted ? (
          <Button onClick={() => emailAll()}>Resend All</Button>
        ) : (
          <Button onClick={() => emailAll()}>Email Unsigned</Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
