import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { Row, Col, Modal, Form, FormControl, FormGroup, FormLabel, Button } from "react-bootstrap";
import { AddressShow } from "app2/src/components/Address/AddressShow";
import { GoogleMap } from "app2/src/components/Common/GoogleMap";
import { AddressRecord } from "app2/src/records/Address";
import { FlashLevels } from "app/src/Common/FlashService";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as reportActions from "app2/src/reducers/report.actions";
import * as config from "react-global-configuration";
import { UserRecord } from "app2/src/records/UserRecord";
import { fromJSON, ReportRecord } from "app2/src/records/Report";
import * as integrationsViewActions from "app2/src/reducers/components/integrationsView.actions";
import { is } from "immutable";
import Spinner from "app2/src/components/SpinnerComponent";
import { toOneLiner } from "app2/src/records/Address";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";
import { currentJob } from "app2/src/selectors/job.selectors";

const mapStateToProps = (state: RootState, ownProps: RoofScopeReportModalProps) => {
  return {
    authorized: state.getIn(["integrations", "roofScope", "authorized"]),
    currentUser: denormalizedReduxUser(state),
    openModal: state.getIn(["components", "integrations", "showRoofScopeModal"]),
    job: currentJob(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: RoofScopeReportModalProps,
) => {
  return {
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
    submitReport: (jobId: number, report: ReportRecord) =>
      dispatch(reportActions.AsyncActions.addJobReport(jobId, report)),
    closeModal: () => dispatch(integrationsViewActions.Actions.closeRoofScopeModal()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface RoofScopeReportModalProps {
  address: AddressRecord;
}

interface RoofScopeReportModalState {
  address: AddressRecord;
  loading: boolean;
  scopeTypeId: string;
  propertyType: string;
  size: string;
  numberOfStructures: string;
  disableSubmit: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RoofScopeReportModalProps;

class RoofScopeReportModal extends React.Component<Props, RoofScopeReportModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      address: props.address,
      loading: false,
      scopeTypeId: "",
      propertyType: "",
      size: "",
      numberOfStructures: "",
      disableSubmit: true,
    };

    this.request = this.request.bind(this);
    this.update = this.update.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.mapChange = this.mapChange.bind(this);
    this.close = this.close.bind(this);
  }

  public componentDidUpdate(prevProps, prevState) {
    const { authorized, addFlashMessage, address, openModal } = this.props;
    const { address: stateAddress, scopeTypeId, propertyType, size, numberOfStructures } = this.state;
    if (!openModal) {
      return;
    }

    let localAddress: any = stateAddress;
    let localDisableSubmit = true;

    if (!is(address, prevProps.address)) {
      if (authorized) {
        localAddress = address;
      } else {
        addFlashMessage(
          FlashLevels.danger,
          "Scope Technologies is not authorized. Contact your Organization Administrator to enable Scope Technologies directly from " +
            config.get("APP_NAME") +
            ".",
        );
        this.props.closeModal();
      }
    }

    if (scopeTypeId !== "" && propertyType !== "" && parseInt(numberOfStructures) > 0 && size !== "") {
      localDisableSubmit = false;
    }

    if (!is(localAddress, stateAddress) || (localDisableSubmit !== true && prevState.disableSubmit === true)) {
      this.setState({
        address: localAddress,
        disableSubmit: localDisableSubmit,
      });
    }
  }

  public async request(e) {
    e.preventDefault();
    e.stopPropagation();
    const { currentUser, addFlashMessage, job } = this.props;
    const { address, scopeTypeId, propertyType, size, numberOfStructures } = this.state;

    this.setState({
      loading: true,
    });
    try {
      await this.props.submitReport(
        job.id,
        fromJSON({
          kind: "roofing",
          provider: "roof_scope",
          job_id: job.id,
          user_id: currentUser.id,
          order_data: {
            address: toOneLiner(address),
            lat: address.lat.toString(),
            lng: address.lon.toString(),
            scopeTypeId: parseInt(scopeTypeId),
            propertyType,
            size,
            numberOfStructures: parseInt(numberOfStructures),
          },
        }),
      );
      this.props.closeModal();
    } catch (errors) {
      console.error(errors);
    }
    this.setState({
      loading: false,
    });
  }

  public updateAddress(address: AddressRecord) {
    this.setState({
      address,
      loading: false,
    });
  }

  public update(formData: RoofScopeReportModalState) {
    this.setState({
      ...formData,
      loading: false,
    });
  }

  public mapChange(e) {
    let { address } = this.state;
    address = address.merge({
      lat: e.center.lat,
      lon: e.center.lng,
    });
    this.updateAddress(address);
  }

  public close() {
    const { address } = this.props;
    this.setState({
      address,
      loading: false,
      scopeTypeId: "",
      propertyType: "",
      size: "",
      numberOfStructures: "",
      disableSubmit: true,
    });
    this.props.closeModal();
  }

  public render() {
    const { openModal, authorized } = this.props;
    const { address, loading, scopeTypeId, propertyType, size, numberOfStructures, disableSubmit } = this.state;
    const address_loading = _.isNull(address) || _.isUndefined(address);
    if (!authorized) {
      return <span />;
    }

    return (
      <Modal show={openModal} onHide={this.close} size="lg" backdrop={"static"}>
        <Spinner localProperty={loading} />
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Scope Technologies Request</h3>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.request}>
          <Modal.Body>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <FormLabel>
                    <span className="asterisk">*</span>&nbsp;Scope Type
                  </FormLabel>
                  <FormControl
                    as="select"
                    type="text"
                    id="scopeTypeId"
                    data-testid="scopeTypeId"
                    value={scopeTypeId}
                    onChange={(e: any) =>
                      this.update({ [e.target.id]: e.target.value } as any as RoofScopeReportModalState)
                    }>
                    <option value="">--Select--</option>
                    <option value="1">RoofScope</option>
                    <option value="4">GutterScope</option>
                    <option value="6">SidingScope</option>
                    <option value="7">InsulationScope</option>
                    <option value="10">ConcreteScope</option>
                    <option value="11">PaintScope</option>
                    <option value="12">RoofScopeX</option>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <FormLabel>
                    <span className="asterisk">*</span>&nbsp;Property Type
                  </FormLabel>
                  <FormControl
                    as="select"
                    type="text"
                    id="propertyType"
                    data-testid="propertyType"
                    value={propertyType}
                    onChange={(e: any) =>
                      this.update({ [e.target.id]: e.target.value } as any as RoofScopeReportModalState)
                    }>
                    <option value="">--Select--</option>
                    <option value="Residential">Residential</option>
                    <option value="Commercial">Commercial</option>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <FormLabel>
                    <span className="asterisk">*</span>&nbsp;Size
                  </FormLabel>
                  <FormControl
                    as="select"
                    type="text"
                    id="size"
                    data-testid="size"
                    value={size}
                    onChange={(e: any) =>
                      this.update({ [e.target.id]: e.target.value } as any as RoofScopeReportModalState)
                    }>
                    <option value="">--Select--</option>
                    <option value="Small">Small</option>
                    <option value="Large">Large</option>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <FormLabel>
                    <span className="asterisk">*</span>&nbsp;Number of Structures
                  </FormLabel>
                  <FormControl
                    type="number"
                    id="numberOfStructures"
                    data-testid="numberOfStructures"
                    value={numberOfStructures}
                    onChange={(e: any) =>
                      this.update({ [e.target.id]: e.target.value } as any as RoofScopeReportModalState)
                    }></FormControl>
                </FormGroup>
              </Col>
            </Row>
            {address_loading ? (
              "Loading..."
            ) : (
              <React.Fragment>
                <AddressShow update={this.updateAddress} address={address} />
                <GoogleMap address={address} googleMapOptions={{ tilt: 0 }} mapChange={this.mapChange} />
              </React.Fragment>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" type="submit" disabled={disableSubmit}>
              Request
            </Button>
            <Button variant="cancel" type="button" onClick={this.close}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default connector(RoofScopeReportModal);
