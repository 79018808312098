import * as React from "react";
import useScreenShareParticipant from "app2/src/components/Twilio/hooks/useScreenShareParticipant/useScreenShareParticipant";
import useSelectedParticipant from "../../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import useMainSpeaker from "app2/src/components/Twilio/hooks/useMainSpeaker/useMainSpeaker";
import MainParticipantInfo from "app2/src/components/Twilio/components/MainParticipantInfo";
import ParticipantTracks from "./ParticipantTracks";
import { Participant } from "twilio-video";

interface MainPartipantProps {
  isScreenShared: boolean;
}

export default function MainParticipant({ isScreenShared }: MainPartipantProps) {
  const mainParticipant = useMainSpeaker() as Participant;
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant ? "high" : null;

  return (
    /* audio is disabled for this participant component because this participant's audio
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={mainParticipant}>
      <ParticipantTracks
        participant={mainParticipant}
        disableAudio
        enableScreenShare
        videoPriority={videoPriority}
        isScreenShared={isScreenShared}
      />
    </MainParticipantInfo>
  );
}
