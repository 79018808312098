import * as ng from "angular";
import { IRepository, IRsfResource } from "../../src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";
import { AddressKind } from "app2/src/records/Address";

export interface IAddress extends ng.resource.IResource<IAddress>, AddressPrototype {}

export interface IAddressResource extends ng.resource.IResourceClass<IAddress>, IRsfResource {
  fromJSON?(data: any): IAddress;
}

class AddressPrototype {
  public id: number;
  public kind: AddressKind;
  public description: string;
  public addressable_id: number;
  public addressable_type: string;
  public line_1: string;
  public line_2: string;
  public city: string;
  public state: string;
  public postal_code: string;
  public country: string;
  public lat: number;
  public lon: number;
  public administrative_area_level_2: string;
  public administrative_area_level_3: string;

  public created_at: Date;
  public updated_at: Date;

  public stateRequired() {
    if (this.line_1 || this.line_2 || this.city || this.postal_code || this.country) {
      if (
        !_.isEmpty(this.line_1?.trim()) ||
        !_.isEmpty(this.line_2?.trim()) ||
        !_.isEmpty(this.city?.trim()) ||
        !_.isEmpty(this.postal_code?.trim()) ||
        !_.isEmpty(this.country?.trim())
      ) {
        return true;
      }
    }

    return false;
  }

  public cityStateZip() {
    let addr = "";

    if (this.city) {
      addr = addr + " " + this.city;
    }
    if (this.state) {
      addr = addr + ", " + this.state;
    }
    if (this.postal_code) {
      addr = addr + " " + this.postal_code;
    }

    return addr.trim();
  }

  public fullAddress() {
    let addr = "";

    if (this.line_1) {
      addr = addr + " " + this.line_1;
    }
    if (this.line_2) {
      addr = addr + " " + this.line_2;
    }
    if (this.city) {
      addr = addr + " " + this.city;
    }
    if (this.state) {
      addr = addr + ", " + this.state;
    }
    if (this.postal_code) {
      addr = addr + " " + this.postal_code;
    }
    if (this.country) {
      addr = addr + " " + this.country;
    }

    return addr.trim();
  }

  public zillowAddress() {
    let addr = "";

    if (this.line_1) {
      addr = addr + "-" + this.line_1.split(" ").join("-");
      addr = addr.split(",").join("");
    }
    if (this.line_2) {
      addr = addr + "-" + this.line_2;
    }
    if (this.city) {
      addr = addr + "-" + this.city;
    }
    if (this.state) {
      addr = addr + "-" + this.state;
    }
    if (this.postal_code) {
      addr = addr + "-" + this.postal_code;
    }

    if (addr !== "") {
      addr = addr + "_rb";
    }

    return addr.trim().substr(1);
  }

  public copyGooglePlace(place, latlon = true) {
    const cmpts: any = {};
    _.each(place.address_components, (addr1: any) => {
      _.each(addr1.types, (type: any) => {
        cmpts[type] = addr1.long_name;
      });
    });
    this.line_1 = cmpts.street_number + " " + cmpts.route;
    this.line_2 = "";
    this.city = cmpts.locality;
    this.state = cmpts.administrative_area_level_1;
    this.postal_code = cmpts.postal_code;
    this.country = cmpts.country;
    if (latlon) {
      this.lat = place.geometry.location.lat();
      this.lon = place.geometry.location.lng();
    }
  }

  public location(latLngLiteral = false) {
    if (this.lat && this.lon) {
      if (latLngLiteral) {
        return { lat: this.lat, lng: this.lon };
      } else {
        return [this.lat, this.lon];
      }
    } else {
      return this.fullAddress();
    }
  }

  public isValid() {
    if (this.line_1 && this.city && this.state) {
      if (!_.isEmpty(this.line_1.trim()) && !_.isEmpty(this.city.trim()) && !_.isEmpty(this.state.trim())) {
        return true;
      }
    }

    return false;
  }

  public isValidLatLon() {
    if (this.lat && this.lon) {
      return this.lat >= -90 && this.lat <= 90 && this.lon >= -180 && this.lon <= 180;
    }

    return false;
  }

  public clone(): IAddress {
    return new resources.Address({
      id: this.id,
      kind: this.kind,
      addressable_id: this.addressable_id,
      addressable_type: this.addressable_type,
      description: this.description,
      line_1: this.line_1,
      line_2: this.line_2,
      city: this.city,
      state: this.state,
      postal_code: this.postal_code,
      country: this.country,
      lat: this.lat,
      lon: this.lon,
      created_at: this.created_at,
      updated_at: this.updated_at,
    });
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/addresses/:id";

  const Address: IAddressResource = <IAddressResource>$resource(
    url,
    { id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return new Address({});
          }

          const data = JSON.parse(response).estimate;
          data.created_at = new Date(data.created_at as string);
          data.updated_at = new Date(data.updated_at as string);
          return new Address(data);
        },
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Address.prototype, AddressPrototype.prototype);

  Address.fromJSON = (data: any): IAddress => {
    if (data.lat) {
      data.lat = parseFloat(data.lat);
    }
    if (data.lon) {
      data.lon = parseFloat(data.lon);
    }
    if (_.isNull(data.line_1)) {
      data.line_1 = "";
    }
    if (_.isNull(data.line_2)) {
      data.line_2 = "";
    }
    return new Address(data);
  };

  Address.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Address;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
