import { ISession } from "app/src/Common/SessionService";
import { IUser } from "app/src/Models/User";

export class TermsAndConditionsCtrl {
  public user: IUser;

  public static $inject = ["Session"];
  constructor(private Session: ISession) {
    Session.currentUser.$promise.then((user) => {
      this.user = user;
    });
  }
}
