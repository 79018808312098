import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { Map, List, getIn } from "immutable";
import { SnakeToCamel } from "../helpers/Format";
import {
  JobAttribute,
  JobAttributeLinkRecordType,
  JobAttributeRecordType,
  linkFromJsonByType,
} from "../records/JobAttribute";
import { JobAttributeTypes, orgJobAttributeLinkIdName, orgJobAttributeLinkKey } from "../records/OrgRecord";

export const rootKey = "jobAttributes";

export interface BaseSelectorProps {
  orgId: number;
  jobAttrType: JobAttributeTypes;
}

/**
 * Returns the job attributes for type in Map by id
 *
 * @param RootState state p
 * @param { jobAttrType: JobAttributeTypes } props
 * @returns Map<number | string, JobAttributeRecordType>
 */
export const jobAttributesByTypeAndId = (
  state: RootState,
  props: { jobAttrType: JobAttributeTypes },
): Map<number | string, JobAttributeRecordType> => state.getIn([rootKey, "byTypeAndId", props.jobAttrType]);

/**
 * Returns a List of ids for available job attributes for type and orgId
 * @param RootState state
 * @param BaseSelectorProps props
 * @returns List<number | string>
 */
export const availableJobAttributeIdsByTypeAndOrgId = (
  state: RootState,
  props: BaseSelectorProps,
): List<number | string> => getIn(state, [rootKey, "availableByTypeAndOrgId", props.jobAttrType, props.orgId], List());

/**
 * Returns a List of link records for active job attributes for type and orgId
 *
 * @param RootState state
 * @param BaseSelectorProps props
 * @returns List<JobAttributeLinkRecordType>
 */
export const activeJobAttributeLinksByTypeAndOrgId = (
  state: RootState,
  props: BaseSelectorProps,
): List<JobAttributeLinkRecordType> => {
  return getIn(state, [rootKey, "activeByTypeAndOrgId", props.jobAttrType, props.orgId], List());
};

/**
 * Returns boolean indiciating if job attributes for type and orgId are loading
 *
 * @param RootState state
 * @param BaseSelectorProps props
 * @returns boolean
 */
export const loadingByTypeAndOrgId = (state, props: BaseSelectorProps): boolean => {
  return state.getIn([rootKey, "loadingByTypeAndOrgId", props.jobAttrType, props.orgId], false);
};

/**
 * Returns a JobAttributeRecordType for the job attribute with id and type
 *
 * @param RootState state
 * @param { id: number | string; jobAttrType: JobAttributeTypes} props
 * @returns JobAttributeRecordType
 */
export const jobAttributeByTypeAndId = (
  state: RootState,
  props: { id: number | string; jobAttrType: JobAttributeTypes },
): JobAttributeRecordType => state.getIn([rootKey, "byTypeAndId", props.jobAttrType, props.id]);

/**
 * Returns the JobAttributeLinkRecordType for the JobAttribute with id and type
 *
 * @param RootState state
 * @param {BaseSelectorProps & { id: number | string }} props
 * @returns JobAttributeLinkRecordType
 */
export const jobAttributeLinkByTypeAndJobAttrId = (
  state: RootState,
  props: BaseSelectorProps & { id: number | string },
): JobAttributeLinkRecordType => {
  const { orgId, jobAttrType, id } = props;
  const active = getIn(state, [rootKey, "activeByTypeAndOrgId", jobAttrType, orgId], List());

  return (
    active.find((link) => {
      return link.get(orgJobAttributeLinkIdName(jobAttrType)) === id;
    }) || linkFromJsonByType[jobAttrType]({})
  );
};

/**
 * Returns a List of JobAttributes based on the list of active JobAttributeLinks for the given type and orgId
 *
 * @param state RootState
 * @param props BaseSelectorProps
 * @returns List<JobAttributeRecordType>
 */
export const getActiveJobAttributesList = createSelector(
  [
    (state: RootState, props: BaseSelectorProps) => ({ state, props }),
    jobAttributesByTypeAndId,
    activeJobAttributeLinksByTypeAndOrgId,
  ],
  ({ props }, byId: Map<string | number, JobAttributeRecordType>, links: List<JobAttributeLinkRecordType>) => {
    if (!links || links.count() <= 0) {
      return List();
    }

    return links
      .map((link) => {
        return byId.get(link.get(orgJobAttributeLinkIdName(props.jobAttrType), null));
      })
      .filter((a) => a !== null && a !== undefined);
  },
);
