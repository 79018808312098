export interface IEmbeddedEvent {
  event: string;
  payload: any;
}

class EmbeddedService {
  protected _listeners = [];
  protected _webViewProp?: string;

  public get listenerCount() {
    return this._listeners.length;
  }

  constructor() {
    this.postToParent = this.postToParent.bind(this);

    window.RSF =
      window.RSF ||
      ({
        postMessage: this.postToParent,
      } as RsfWindow);
  }

  public reset() {
    this._listeners = [];
  }

  public addListener(func: (event: IEmbeddedEvent) => void) {
    this._listeners.push(func);
  }

  public removeListener(func: (event: IEmbeddedEvent) => void) {
    this._listeners = this._listeners.filter((a) => a !== func);
  }

  public sendEvent(event: string, payload: any = {}) {
    const msg = { event, payload };

    this.postMessage(escape(JSON.stringify(msg)));
  }

  public postMessage(msg: string) {
    this._webViewProp = Object.keys(window).find((a) => a.endsWith("NativeWebView"));
    if (this._webViewProp && this._webViewProp !== "") {
      window[this._webViewProp].postMessage(msg);
    }
  }

  protected postToParent(msg: string) {
    const event: IEmbeddedEvent = JSON.parse(unescape(msg));
    try {
      this._listeners.forEach((func) => func(event));
    } catch (e) {
      console.error(e);
    }
  }
}

export const embeddedService = new EmbeddedService();
