import { ISession } from "../SessionService";
import { FlashLevels, IFlash } from "../FlashService";
import { IJob } from "../../Models/Job";
import { JobShowTourService } from "./JobShowTourService";
import { UserPrefConfigType } from "../../Models/UserPreference";
import { IBaseConfig } from "../IBaseConfig";

export class JobInfoTourService {
  public tour: any;
  public props: any = {};
  public job: IJob;
  protected _prevStep: any;

  public static $inject = ["uiTourService", "JobShowTourService", "Session", "$sce", "Flash", "BaseConfig"];

  constructor(
    public uiTourService: any,
    public JobShowTourService: JobShowTourService,
    private Session: ISession,
    public $sce: ng.ISCEService,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
  ) {}

  public init(props: any) {
    this.Session.fetchUserPref(UserPrefConfigType.job_info_tour).then((info_tour) => {
      if (info_tour) {
        this.props = props || {};
        this.tour = this.uiTourService.getTourByName("job-info-tour");
        this.tour.do_not_show = true;
        this.props.jobInfoCtrl.$timeout().then(() => {
          if (this.tour._getSteps().length <= 0) {
            this.createTour();
          } else if (this.tour.getStatus() === this.tour.Status.OFF) {
            this.startTour();
          }
        });
      }
    });
  }

  public createTour() {
    this.tour.createStep({
      selector: ".tour-customer-details",
      order: 10,
      scrollOffset: 200,
      title: "Job",
      orphan: true,
      trustedContent: this.$sce.trustAsHtml(
        "Welcome to the Job.<br/><br/><span class='tour-bold'>Click next</span> to get a walk through on how to create, edit, and navigate a job.<br/><br/>This information can also be automatically imported via one of our CRM integrations.<br/><br/>Please don't hesitate to contact the <span class='tour-underline'>" +
          this.BaseConfig.ONBOARDING_NAME +
          "</span> with any questions you might have.</br></br> Email: <a href='mailto:" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "</span></a></br> Phone: <a href='" +
          this.BaseConfig.ONBOARDING_PHONE_DIAL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_PHONE +
          "</span></a><br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: ".tour-customer-details",
      order: 20,
      scrollOffset: 500,
      title: "Customer Details",
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Add the customer's name, email address, lead source, and phone numbers.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: ".tour-job-details",
      order: 30,
      scrollOffset: 200,
      title: "Job Details",
      placement: "top",
      trustedContent: this.$sce.trustAsHtml("Add the job name, track the job status, and reassign jobs.<br/><br/>"),
    });
    this.tour.createStep({
      selector: ".tour-job-address",
      order: 40,
      scrollOffset: 200,
      title: "Job Address",
      placement: "top",
      trustedContent: this.$sce.trustAsHtml("Enter the job's address here.<br/><br/>"),
    });
    this.tour.createStep({
      selector: ".map-view",
      order: 50,
      scrollOffset: 200,
      title: "Map View",
      placement: "top",
      trustedContent: this.$sce.trustAsHtml(
        "The job's address is automatically located and Google Map is used to visualize the location.<br/><br/>",
      ),
    });
    this.tour.createStep({
      selector: ".tour-save",
      order: 60,
      scrollOffset: 200,
      title: "Save",
      placement: "left",
      trustedContent: this.$sce.trustAsHtml("Don't forget to save all the information for the customer.<br/><br/>"),
    });
    this.tour.createStep({
      selector: ".tour-save",
      order: 110,
      scrollOffset: 200,
      title: "Questions?",
      orphan: true,
      placement: "bottom",
      trustedContent: this.$sce.trustAsHtml(
        "Please don't hesitate to contact the <span class='tour-underline'>" +
          this.BaseConfig.ONBOARDING_NAME +
          "</span> with any questions you might have.</br></br> Email: <a href='mailto:" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_EMAIL +
          "</span></a></br> Phone: <a href='" +
          this.BaseConfig.ONBOARDING_PHONE_DIAL +
          "'><span class='tour-bold tour-underline'>" +
          this.BaseConfig.ONBOARDING_PHONE +
          "</span></a> <br/><br/>",
      ),
    });

    this.props.jobInfoCtrl.$timeout(() => {
      this.startTour();
    });
  }

  public startTour() {
    this.tour.start();
  }

  public next() {
    if (this.tour) {
      this.props.jobInfoCtrl.$timeout(() => {
        this.tour.next();
      });
    }
  }

  public help(): ng.IPromise<any> {
    this.Session.setTourPref(UserPrefConfigType.job_info_tour, true);
    const preference = this.Session.savePreference();
    preference.then(() => {
      this.Flash.addMessage(FlashLevels.success, "Job Tour Enabled.");
    });
    return preference;
  }

  public onShown() {
    this.props.jobInfoCtrl.$timeout().then(() => {
      this.tour.reposition();
    });
  }

  public onEnd() {
    this.props.jobInfoCtrl.EventingFactory.trackEvent("end tour", {
      tour: "JobInfoTour",
      step_title: this.tour._getCurrentStep().title,
    });
    this.Session.setTourPref(UserPrefConfigType.job_info_tour, !this.tour.do_not_show);
    this.Session.savePreference();
    this.props.jobInfoCtrl.$timeout(() => {
      this.JobShowTourService.startTour();
    });
  }

  public onNext() {
    if (this._prevStep) {
      this._prevStep.popup.remove();
      this._prevStep.popup = undefined;
      this._prevStep.tether = undefined;
    }
    this._prevStep = this.tour._getCurrentStep();
    const order = this._prevStep.order;
    this.props.jobInfoCtrl.EventingFactory.trackEvent("next tour step", {
      tour: "JobInfoTour",
      step_title: this.tour._getCurrentStep().title,
    });
    if (order === 10) {
      const job = this.props.jobInfoCtrl.job;
      if (job && job.id) {
        if (!job.address.isValidLatLon()) {
          job.address.line_1 = "5810 Corralberry Court";
          job.address.postal_code = "46033";
          job.address.city = "Carmel";
          job.address.state = "Indiana";
          job.address.lat = 39.98627;
          job.address.lon = -86.05835;
        }
      } else {
        job.name = "Sample Customer";
        job.customer_name = "Sample Customer";
        job.email = this.Session.currentUser.email;
        job.address.line_1 = "5810 Corralberry Court";
        job.address.postal_code = "46033";
        job.address.city = "Carmel";
        job.address.state = "Indiana";
        job.address.lat = 39.98627;
        job.address.lon = -86.05835;
      }
    }
  }

  public onPrev() {
    if (this._prevStep) {
      this._prevStep.popup.remove();
      this._prevStep.popup = undefined;
      this._prevStep.tether = undefined;
    }
    this._prevStep = this.tour._getCurrentStep();
    this.props.jobInfoCtrl.EventingFactory.trackEvent("previous tour step", {
      tour: "JobInfoTour",
      step_title: this.tour._getCurrentStep().title,
    });
  }
}
