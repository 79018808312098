import { getUoM } from "app2/src/records/Measurement";
import { RootState } from "app2/src/reducers";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { roofFaces } from "app2/src/selectors/roofFace.selectors";
import { List, Map } from "immutable";
import * as React from "react";
import { Accordion, Card, Col, Row, Table } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { useSelector } from "react-redux";

export const RoofFaces: React.FC = () => {
  // Local State
  const [accordionControl, setAccordionControl] = React.useState(Map({ roofFaces: "" }));

  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const roofFaceIds = measurement?.get("roof_face_ids") || List();
  const roofFaceRecords = useSelector((state: RootState) => roofFaces(state, { roofFaceIds }));
  const uom = getUoM(measurement);

  // Methods
  const toggleAccordion = (key: string) => {
    if (accordionControl.get(key) === "") {
      setAccordionControl(accordionControl.set(key, key));
    } else {
      setAccordionControl(accordionControl.set(key, ""));
    }
  };

  if (roofFaceIds.size === 0) {
    return null;
  }

  return (
    <Accordion activeKey={accordionControl.get("roofFaces")}>
      <Card>
        <Accordion.Toggle
          className="link"
          as={Card.Header}
          eventKey="roofFaces"
          onClick={() => toggleAccordion("roofFaces")}>
          Roof Faces
          <span className="pull-right">
            <FontAwesome
              name={`${accordionControl.get("roofFaces") !== "" ? "angle-down" : "angle-up"}`}
              title="Expand"
              size="lg"
              className="pull-right"
            />
          </span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="roofFaces">
          <Card.Body>
            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Designator</th>
                      <th>Pitch</th>
                      <th>Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roofFaceRecords?.map((rf, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{rf.designator}</td>
                          <td>{rf.pitch}/12</td>
                          <td>
                            {rf.area} {uom["roof_face_area"]}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <Col></Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
