import { ScreenShareShowCtrl } from "app/src/ScreenShare/ScreenShareShowCtrl";
import { ScreenShareJoinCtrl } from "app/src/ScreenShare/ScreenShareJoinCtrl";
import { ScreenShareIndexCtrl } from "app/src/ScreenShare/ScreenShareIndexCtrl";
import { ScreenShareHostCtrl } from "app/src/ScreenShare/ScreenShareHostCtrl";
import { react2angular } from "react2angular";
import Host from "app2/src/components/Twilio/Host";
import Attendee from "app2/src/components/Twilio/Attendee";

export class ScreenShareRoutes {
  public static load(app: ng.IModule) {
    app.controller("ScreenShareShowCtrl", ScreenShareShowCtrl);
    app.controller("ScreenShareJoinCtrl", ScreenShareJoinCtrl);
    app.controller("ScreenShareIndexCtrl", ScreenShareIndexCtrl);
    app.controller("ScreenShareHostCtrl", ScreenShareHostCtrl);
    app.component("host", react2angular(Host, ["jobId"]));
    app.component("attendee", react2angular(Attendee, ["jobUid", "screenShare"]));
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("root.screen_share_index", <ng.ui.IState>{
        url: "/screen_shares",
        views: {
          "container@": {
            templateUrl: "src/ScreenShare/index.html",
            controller: "ScreenShareIndexCtrl",
            controllerAs: "ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("screen_share_host", <ng.ui.IState>{
        url: "/jobs/:job_id/screen_shares/:uid",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            templateUrl: "src/ScreenShare/host.html",
            controller: "ScreenShareHostCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("screen_share_join", <ng.ui.IState>{
        url: "/screen_shares/join",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            templateUrl: "src/ScreenShare/join.html",
            controller: "ScreenShareJoinCtrl",
            controllerAs: "ctrl",
          },
        },
      })
      .state("screen_share_presenter", <ng.ui.IState>{
        url: "/screen_shares/:id",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            templateUrl: "src/ScreenShare/show.html",
            controller: "ScreenShareShowCtrl",
            controllerAs: "ctrl",
          },
        },
      });
  }
}
