import { ActionsUnion, createAction } from "app2/src/reducers/Utils";
import { FencingRecord, IFencingData } from "app2/src/records/measurements/Fencing";
import { RootState, ThunkResult } from "app2/src/reducers";
import * as fencingService from "app2/src/api/measurements/fencing.service";
import { handleErrors } from "app2/src/reducers/Utils";
import { RootDispatchType } from "app2/src/store";
import { measurementIdFullFencing } from "app2/src/selectors/measurements/fencing.selectors";

export const FETCH_FENCING_BY_MEASUREMENT_ID = "@measurements_fencings/FETCH_FENCING_BY_MEASUREMENT_ID";
export const CREATE_FENCING_BY_MEASUREMENT_ID = "@measurements_fencings/CREATE_FENCING_BY_MEASUREMENT_ID";
export const RECEIVE_FENCING = "@measurements_fencings/RECEIVE_FENCING";
export const RECEIVE_FENCING_ERRORS = "@measurements_fencings/RECEIVE_FENCING_ERRORS";
export const SET_FENCING = "@measurements_fencings/SET_FENCING";
export const DELETE_FENCING = "@measurements_fencings/DELETE_FENCING";
export const UPDATE_TOTALS = "@measurements_fencing/UPDATE_TOTALS";
export const RESET_FENCING = "@measurements_fencing/RESET_FENCING";

export const Actions = {
  updateTotals: (fencingId: number) => createAction(UPDATE_TOTALS, { fencingId }),
  setFencing: (fencing: FencingRecord) => createAction(SET_FENCING, { fencing }),
  resetFencing: (fencingId: number) => createAction(RESET_FENCING, { fencingId }),
  deleteFencing: (fencingId: number, cascade = true) => createAction(DELETE_FENCING, { fencingId, cascade }),
  fetchFencingByMeasurementId: (measurementId: number) =>
    createAction(FETCH_FENCING_BY_MEASUREMENT_ID, { measurementId }),
  receiveFencing: (fencing: Partial<IFencingData>) => createAction(RECEIVE_FENCING, { fencing }),
  receiveFencingErrors: (measurementId: number, errors: string[]) =>
    createAction(RECEIVE_FENCING_ERRORS, { measurementId, errors }),
  createFencingForMeasurementId: (measurementId: number) =>
    createAction(CREATE_FENCING_BY_MEASUREMENT_ID, { measurementId }),
};

export type Actions = ActionsUnion<typeof Actions>;

export const AsyncActions = {
  fetchByMeasurementId: (measurementId: number, createOnNotFound = true): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchFencingByMeasurementId(measurementId));

      try {
        const response = await fencingService.loadByMeasurementId(measurementId);

        dispatch(Actions.receiveFencing(response.fencing));
      } catch (error) {
        if (error.status === 404 && createOnNotFound) {
          dispatch(Actions.createFencingForMeasurementId(measurementId));
          return;
        }
        dispatch(Actions.receiveFencingErrors(measurementId, handleErrors(error)));
      }
    };
  },
  save: (measurementId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const fencing = measurementIdFullFencing(getState(), { measurementId: measurementId });

      dispatch(Actions.fetchFencingByMeasurementId(fencing.measurementId));

      try {
        let response: fencingService.IFencingResponse;
        if (fencing.id <= 0) {
          response = await fencingService.create(fencing.measurementId, fencing);
          dispatch(Actions.deleteFencing(fencing.id, true));
        } else {
          response = await fencingService.update(fencing);
        }

        dispatch(Actions.receiveFencing(response.fencing));
      } catch (error) {
        dispatch(Actions.receiveFencingErrors(fencing.measurementId, handleErrors(error)));
      }
    };
  },
};
