import { ActionsUnion, createAction } from "./Utils";
import { IOpeningEstimationData } from "../records/OpeningEstimation";
import { List } from "immutable";

export const RECEIVE_OPENING_ESTIMATION = "@openingEstimations/RECEIVE_OPENING_ESTIMATION";
export const DUPLICATE_OPENING_ESTIMATIONS = "@openingEstimations/DUPLICATE_OPENING_ESTIMATIONS";

export const Actions = {
  receiveOpeningEstimation: (openingEstimation: Partial<IOpeningEstimationData>) =>
    createAction(RECEIVE_OPENING_ESTIMATION, { openingEstimation }),
  duplicateOpeningEstimations: (lineItemId: number, openingEstimationIds: List<number>) =>
    createAction(DUPLICATE_OPENING_ESTIMATIONS, { lineItemId, openingEstimationIds }),
};

export type Actions = ActionsUnion<typeof Actions>;
