import { fetcher } from "app2/src/helpers/Fetcher";
import { IElevationData } from "app2/src/records/Elevation";

export interface IElevationIndexResponse {
  elevations: IElevationData[];
}

const url = "api/v1/measurements/:id/elevations";

export const list = (measurementId: number): Promise<IElevationIndexResponse> => {
  const actionUrl = url.replace(":id", measurementId.toString());

  return fetcher.get<IElevationIndexResponse>(actionUrl);
};
