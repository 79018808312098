import * as React from "react";
import { FormControl, RsfForm } from "@tberrysoln/rsf-form";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Actions as accessActions, AsyncActions as accessAsyncActions } from "app2/src/reducers/access.actions";
import { Nullable } from "app2/src/records";
import { UserRecord } from "app2/src/records/UserRecord";
import { RootState } from "app2/src/reducers";
import { accessByOrgId } from "app2/src/selectors/access.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import * as FontAwesome from "react-fontawesome";

export interface EditCommissionProps {
  fullName: string;
  close: () => void;
  user: Nullable<UserRecord>;
}

export const EditCommission: React.FC<EditCommissionProps> = ({ fullName, close, user }) => {
  // Hooks
  const dispatch = useDispatch();
  const accessId = useSelector((state: RootState) =>
    user ? accessByOrgId(state, { orgId: currentOrgId(state), userId: user.get("id") })?.get("id") : null,
  );

  // Lifecycle
  React.useEffect(() => {
    dispatch(accessAsyncActions.getAccess(user?.get("id")));
  }, []);

  // Methods
  const updateForm = ({ name, rootPath, value }) => {
    const path = rootPath.slice(3).join(".").concat(".", name);
    return accessActions.updateFormRecord(accessId, path, value || 0);
  };

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(accessAsyncActions.updateAccess(accessId));
    close();
  };

  if (!accessId) return null;

  return (
    <RsfForm
      rootPath={["accesses", "byId", accessId, "config", "commission_calculator"]}
      onSubmit={submitForm}
      updateFormReducer={updateForm}>
      <Modal.Header>
        <h3>User Properties - {fullName}</h3>
      </Modal.Header>
      <Modal.Body>
        <h5 className="font-weight-bold">Commission Values</h5>
        <div className="form-percentage">
          <FormControl
            label="Overhead"
            name="overhead"
            formControlProps={{ type: "number" }}
            numberInputProps={{ min: 0, step: 0.01 } as any}
          />
          <FontAwesome name="percent" className="fa-gray" />
        </div>
        <div className="form-percentage">
          <FormControl
            label="Commission Rate"
            name="commission_rate"
            formControlProps={{ type: "number" }}
            numberInputProps={{ min: 0, step: 0.01 } as any}
          />
          <FontAwesome name="percent" className="fa-gray" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="cancel" onClick={close}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </Modal.Footer>
    </RsfForm>
  );
};
