import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { IProduct } from "app/src/Models/Product";
import { IMeasurementLinkResource, IMeasurementLink, MeasureValue } from "app/src/Models/MeasurementLink";
import { IPretty } from "app/src/Common/PrettyNameService";
import * as measurementJson from "app/assets/model_data/measurement.json";
import { IMeasurementResource, IMeasurement } from "app/src/Models/Measurement";
import { columnKeys } from "app2/src/records/Measurement";
import { roomColumnKeys } from "app2/src/records/Room";

export class OrgMeasurementLinkEditCtrl {
  public measurement_link: IMeasurementLink;
  public selectionValues: string[];
  public activeTab: number;
  public roofValues: string[];
  public sidingValues: string[];
  public sunroomValues: string[];
  public roomValues: string[];
  public measurement: IMeasurement;

  public static $inject = [
    "$uibModalInstance",
    "Flash",
    "product",
    "org_id",
    "MeasurementLink",
    "Measurement",
    "Pretty",
    "$scope",
  ];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public Flash: IFlash,
    public product: IProduct,
    public org_id: number,
    public MeasurementLink: IMeasurementLinkResource,
    public Measurement: IMeasurementResource,
    public Pretty: IPretty,
    private $scope: ng.IScope,
  ) {
    if (_.isUndefined(this.product.measurement_link)) {
      this.measurement_link = MeasurementLink.fromJSON({
        org_id: this.org_id,
        item_uuid: this.product.uuid,
        kind: "measurement",
        measure_value: { measurement: "roof_total_area", formula: "1", formula_uom: "ea" },
        inherited: true,
      });
    } else {
      this.measurement_link = MeasurementLink.fromJSON(JSON.parse(JSON.stringify(this.product.measurement_link)));
    }
    this.measurement = Measurement.fromJSON(measurementJson.measurement);
    this.roofValues = columnKeys(this.measurement, ["roof"]);
    this.sidingValues = columnKeys(this.measurement, ["siding"]);
    this.sunroomValues = columnKeys(this.measurement, ["sunroom"]);
    this.roomValues = roomColumnKeys(this.measurement.rooms[0]);

    this.activeTab = this.setActiveTab();

    this.update = this.update.bind(this);
  }

  public save() {
    const ml = this.product.measurement_link;
    if (_.isUndefined(ml) || ml.inherited) {
      this.MeasurementLink.create(this.measurement_link)
        .$promise.then((resp) => {
          this.product.measurement_link = resp;
          this.Flash.addMessage(FlashLevels.success, "Measurement link created successfully!");
          this.$uibModalInstance.close(resp);
        })
        .catch(() => {
          this.Flash.addMessage(
            FlashLevels.danger,
            "Unable to create Measurement Link. Contact support if the problem continues.",
          );
        });
    } else {
      this.product.measurement_link = this.measurement_link;
      this.measurement_link
        .$update()
        .then(() => {
          this.Flash.addMessage(FlashLevels.success, "Measurement link updated successfully!");
          this.$uibModalInstance.close(this.measurement_link);
        })
        .catch(() => {
          this.Flash.addMessage(
            FlashLevels.danger,
            "Unable to update Measurement Link. Contact support if the problem continues.",
          );
        });
    }
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }

  public update(measureValue: MeasureValue) {
    this.$scope.$apply(() => {
      this.measurement_link.measure_value = measureValue;
    });
  }

  public setActiveTab() {
    if (this.measurement_link.kind === "formula") return 4;
    if (this.measurement_link.kind === "interior_measurement") return 5;
    if (this.measurement_link.kind === "interior_formula") return 6;

    if (_.contains(this.roofValues, this.measurement_link.measure_value.measurement)) {
      return 0;
    } else if (_.contains(this.sidingValues, this.measurement_link.measure_value.measurement)) {
      return 1;
    } else if (_.contains([], this.measurement_link.measure_value.measurement)) {
      return 2;
    } else {
      this.measurement_link.measure_value.formula = this.measurement_link.measure_value.measurement;
      return 4;
    }
  }

  public selectTab(idx: number) {
    if (idx === 4) {
      this.measurement_link.kind = "formula";
    } else if (idx === 5) {
      this.measurement_link.kind = "interior_measurement";
    } else if (idx === 6) {
      this.measurement_link.kind = "interior_formula";
    } else {
      this.measurement_link.kind = "measurement";
    }
  }
}
