import { subscriber } from "app2/src/helpers/Subscribe";
import { RouterState } from "app2/src/reducers/router.reducer";

class RouterHelper {
  private $state: ng.ui.IStateService;

  constructor() {
    subscriber.subscribe("routerPush", (data: RouterState) => {
      const { pathname, query } = data.location;

      if (!pathname) {
        return;
      }

      const options: any = {};
      if (data.action === "replace") {
        options.notify = false;
        options.location = "replace";
        options.reload = false;
      }

      this.$state.go(pathname, query, options);
    });
  }

  public setup($state: ng.ui.IStateService) {
    this.$state = $state;
  }
}

export const Router = new RouterHelper();
