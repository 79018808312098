import * as React from "react";
import { Form } from "react-bootstrap";
import { Field } from "react-final-form";

export interface RsfCheckboxGroupProps {
  label: string;
  name: string;
}

export const RsfCheckboxGroup: React.FunctionComponent<RsfCheckboxGroupProps> = (props) => {
  const { label, name } = props;

  return (
    <Form.Group>
      <div className="form-check-inline">
        <Field className="form-check-input" name={name} type="checkbox" component="input" aria-label={label} />
        <label className="form-check-label">{label}</label>
      </div>
    </Form.Group>
  );
};
