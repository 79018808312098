import { Record } from "immutable";
import * as uuid from "uuid/v4";

export const fromJSON = (json: Partial<IEstimateDiscountData>): EstimateDiscountRecord => {
  const record: IEstimateDiscountRecord = { ...(json as any) };

  if (!record.uuid) {
    record.uuid = uuid();
  }

  record.amount = _.parseFloat(record.amount);
  record.calculated = _.parseFloat(record.calculated);
  record.totalAfter = _.parseFloat(record.totalAfter);
  record.limit = _.parseFloat(record.limit);

  return new EstimateDiscountRecord(record);
};

export const toJSON = (record: EstimateDiscountRecord): IEstimateDiscountData => {
  return record.toJSON();
};

export const calculate = (discount: Partial<IEstimateDiscountData>, total: number): EstimateDiscountRecord => {
  let calculated = 0;
  switch (discount.kind) {
    case "percent":
      calculated = _.round(total * (discount.amount / 100), -2);
      break;
    case "percent_limit":
      calculated = _.round(total * (discount.amount / 100), -2);
      calculated = _.min([discount.limit, calculated]);
      break;
    case "flat":
      calculated = discount.amount;
      break;
  }

  return fromJSON({
    uuid: discount.uuid,
    kind: discount.kind,
    name: discount.name,
    amount: discount.amount,
    calculated: calculated,
    totalAfter: total - calculated,
    discountId: discount.discountId,
    discountRev: discount.discountRev,
    limit: discount.limit,
  });
};

export interface IEstimateDiscountData {
  uuid: string;
  kind: string;
  name: string;
  amount: number;
  calculated: number;
  totalAfter: number;
  discountId: number;
  discountRev: string;
  limit: number;
}

export type IEstimateDiscountRecord = IEstimateDiscountData;

const defaultDiscountProps: IEstimateDiscountRecord = {
  uuid: null,
  kind: "percent",
  name: "",
  amount: 0,
  calculated: 0,
  totalAfter: 0,
  discountId: 0,
  discountRev: "",
  limit: 0,
};

export class EstimateDiscountRecord extends Record(defaultDiscountProps) implements IEstimateDiscountRecord {
  public uuid!: string;
  public kind!: string;
  public name!: string;
  public amount!: number;
  public calculated!: number;
  public totalAfter!: number;
  public discountId!: number;
  public discountRev!: string;
  public limit!: number;

  public constructor(values?: Partial<IEstimateDiscountRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
