import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { Map, List, getIn, Set } from "immutable";
import { AccessRecord, emptyAccessValue, UserRecord } from "app2/src/records/UserRecord";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { byId as orgsById, currentOrgId } from "app2/src/selectors/org.selectors";
import { dirtyUserAccesses, byId as accessesById } from "app2/src/selectors/access.selectors";
import { model } from "app2/src/reducers/user.reducer";
import { user, currentUser } from "./userCommon.selectors";

// legacy code expects these here
export { user, currentUser };

export const byId = (state: RootState) => state.getIn([model, "usersById"]);

/**
 * Loads the users access_count from byID
 *
 * @param {RootState} state The RootState
 * @param {{userId: number}} options
 * @returns {number} accesses_count
 */
export const userAccessCount = (state: RootState, props: { userId: number }): number =>
  getIn(state, [model, "usersById", props.userId, "accesses_count"], 1);

/**
 * Loads the current logged in user id
 *
 * @param {RootState} state The RootState
 * @param {{}} options
 * @returns {number} number
 */
export const currentUserId = (state: RootState): number => state.getIn(["users", "currentUser", "id"]);

/**
 * Loads the current user's org
 *
 * The original attempt of using the current accessUid used a reselect memoized selector.  But, for some reason
 * that selector wasn't updating quickly enough when the accessUid changed.  In this selector, the memoized
 * version would show the old but using state.getIn directly showed the new value.  So, we're using state.getIn.
 *
 * Additionally, the users list of accesses isn't normalized and when the reloading of the user happens, the
 * list of accesses is reset to just 1.  So we're iterating over all byId to find the access by Uid.
 *
 * @param {RootState} state The RootState
 * @param {{}} options
 * @returns {OrgRecord} OrgRecord
 */
export const currentUserOrg = createSelector(
  [accessesById, orgsById, (state) => ({ state })],
  (allAccesses: Map<number, AccessRecord>, orgsById: Map<number, OrgRecord>, stateProps): OrgRecord => {
    const { state } = stateProps;
    const currentAccessUid = state.getIn(["auth", "accessUid"]);
    const currentAccess = allAccesses.find((access) => access.get("uid") === currentAccessUid);

    if (!currentAccess) {
      return null;
    }

    const currentUserOrg = orgsById.get(currentAccess.org_id);
    return currentUserOrg;
  },
);

/**
 * Loads the current user's org lead sources
 *
 * @param {RootState} state The RootState
 * @param {{}} options
 * @returns {List<string>} List<string>
 */
export const leadSources = createSelector(
  [currentUser, orgsById],
  (currentUser: UserRecord, orgsById: Map<number, OrgRecord>): List<string> => {
    if (!currentUser) {
      return List();
    }

    const currentUserOrg = orgsById.get(currentUser.org_id);
    if (!currentUserOrg) {
      return List();
    }
    return currentUserOrg.get("lead_sources");
  },
);

/**
 * this selector should only be used for old code that expects
 * denormalized redux state. no new code (components) should be using this.
 * new code should instead access redux state in a normalized fashion
 * using selectors
 */
export const denormalizedReduxUser = createSelector(
  [currentUser, orgsById],
  (currentUser: UserRecord, orgsById: Map<number, OrgRecord>): UserRecord => {
    if (!currentUser) {
      return new UserRecord();
    }

    const currentUserOrg = orgsById.get(currentUser.org_id);
    currentUser = currentUser.set("org", currentUserOrg);
    return currentUser;
  },
);

/**
 * Loads the crm user check for the current user
 *
 * @param {RootState} state The RootState
 * @param {{ crmUserType: CrmUserType }} options
 * @returns {boolean} boolean
 */
export const crmUserCheck = (state: RootState, props: any): boolean =>
  state.getIn(["users", "crmUserCheckByOrgId", currentOrgId(state), props.crmUserType], false);

/**
 * Returns the access that is for the given user at the given org.
 * Will ALWAYS return an AccessRecord, it will be empty if no access is found.
 *
 * @param {RootState} state The RootState
 * @param {{userId: number, orgId: number}} options
 * @returns {AccessRecord} access
 */
export const usersAccessAtOrgId = createSelector(
  [accessesById, user, (_, props: any) => ({ props })],
  (byId, user, stateProps) => {
    if (!user || user.accesses.size === 0) {
      return emptyAccessValue;
    }
    const { props } = stateProps;

    const id = user.accesses.find((accessId) => {
      const access = byId.get(accessId, emptyAccessValue);

      return access.org_id === props.orgId;
    }, 0);

    return byId.get(id, emptyAccessValue) || emptyAccessValue;
  },
);

export const userHydratedForSave = createSelector(
  [user, dirtyUserAccesses],
  (user: UserRecord, accesses: List<AccessRecord>) => {
    return user.set("accesses", accesses as any);
  },
);

/**
 * Takes a list of ids and returns a list of the records matching the ids
 *
 * @param {RootState} state The RootState
 * @param {{userIds: List<number>}} options
 * @returns {List<UserRecord>}
 */
export const users = createSelector([byId, (state, props) => ({ state, props })], (byId, { props }) => {
  return props.userIds.map((id) => byId.get(id));
});
