import { ActionsUnion, createAction } from "./Utils";
import { IPriceListData } from "app2/src/records/PriceList";

export const RECEIVE_PRICE_LIST = "@priceLists/RECEIVE_PRICE_LIST";

export const Actions = {
  receivePriceList: (priceList: IPriceListData) => createAction(RECEIVE_PRICE_LIST, { priceList }),
};

export type Actions = ActionsUnion<typeof Actions>;
