import * as Ajv from "ajv";
import * as schema from "../schemas/event_base.json";

let validateFunction;

if (PRODUCTION) {
  validateFunction = function () {};
}

if (!PRODUCTION) {
  const ajv = new Ajv();
  const validator = ajv.compile(schema);

  validateFunction = function (data) {
    if (!validator(data)) {
      /* eslint-disable no-console */
      console.info(validate.errors);
      /* eslint-enable no-console */
    }
  };
}

export const validate = validateFunction;
