import * as React from "react";
import Participant from "app2/src/components/Twilio/components/Participant";
import useParticipants from "app2/src/components/Twilio/hooks/useParticipants/useParticipants";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import useSelectedParticipant from "../../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import Header from "./Header";

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
    roomParams: { host },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  return (
    <div className="participant-strip">
      <Header showInvite={host} />
      <div className="participants">
        <Participant
          participant={localParticipant}
          isSelected={selectedParticipant === localParticipant}
          remoteParticipant={false}
          // @ts-ignore
          onClick={() => setSelectedParticipant(localParticipant)}
        />
        {participants.map((participant) => (
          <Participant
            key={participant.sid}
            participant={participant}
            remoteParticipant={true}
            isSelected={selectedParticipant === participant}
            // @ts-ignore
            onClick={() => setSelectedParticipant(participant)}
          />
        ))}
      </div>
    </div>
  );
}
