import { IEstimateTemplate, IEstimateTemplateResponse } from "app/src/Models/EstimateTemplate";
import { IProviaApi, ProviaApi } from "app/src/Estimator/ProviaApi";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IOrg } from "app/src/Models/Org";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import * as XLSX from "xlsx";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IPretty } from "app/src/Common/PrettyNameService";
import { dispatch } from "app2/src/storeRegistry";
import * as documentActions from "app2/src/reducers/document.actions";
import { useSelector } from "app2/src/storeRegistry";
import { token } from "app2/src/selectors/token.selectors";
import { TokenRecord } from "app2/src/records/Token";
import * as tokenActions from "app2/src/reducers/token.actions";

export class ProductSelectorCtrl {
  public showTemplates = false;
  public showProvia = true;
  public showProviaLogin = false;
  public portal = true;
  public showProviaOrders = false;
  public showProductFileUpload = true;
  public showBack = false;
  public showSearch = true;
  public chooseTemplates = false;
  public title: string = null;
  public search: { name: string } = { name: "" };
  public provia: IProviaApi;
  public spinnerPromise: ng.IPromise<any>;
  public order_number: number;

  public static $inject = [
    "$uibModalInstance",
    "EstimatorService",
    "BaseConfig",
    "navDisplay",
    "templates",
    "$http",
    "Flash",
    "$q",
    "org",
    "fileQueue",
    "setup",
    "Pretty",
  ];
  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public EstimatorService: EstimatorService,
    public BaseConfig: IBaseConfig,
    public navDisplay = true,
    public templates: IEstimateTemplateResponse,
    public $http: ng.IHttpService,
    public Flash: IFlash,
    public $q: ng.IQService,
    public org: IOrg,
    public fileQueue: IFileQueueFactory,
    public setup: any,
    public Pretty: IPretty,
  ) {
    switch (setup.type) {
      case "template":
        this.addTemplate();
        break;
      case "import":
        this.showSearch = false;
        this.title = "Import";
        break;
    }
  }

  public componentDidMount() {
    dispatch(tokenActions.AsyncActions.getToken(this.org.id, "provia"));
  }

  public makeTemplateSelection(template: IEstimateTemplate) {
    this.clearSearchText();
    this.$uibModalInstance.close({ type: "template", data: template });
  }

  public makeProviaSelection(portal: boolean) {
    this.portal = portal;
    this.title = portal ? "ProVia" : "ProVia (Non-Portal)";
    this.showProviaLogin = true;
    this.showProvia = false;
    this.showProductFileUpload = false;
    this.showSearch = false;
    this.showTemplates = false;
    this.showBack = true;
    this.provia = new ProviaApi(this.$http, this.$q, this.org.id, this.BaseConfig, this.fileQueue);
  }

  public proviaLogin() {
    this.spinnerPromise = this.provia
      .fetchOrders(this.portal)
      .then(() => {
        this.showProviaOrders = true;
        this.showProviaLogin = false;
      })
      .catch((resp) => {
        this.Flash.addMessage(FlashLevels.danger, "ProVia " + resp.data.errors + " Please try again.");
      });
  }

  public selectOrder(order: number = this.order_number) {
    this.spinnerPromise = this.provia
      .fetchOrderId(order, this.portal)
      .then((order_resp) => {
        this.$uibModalInstance.close({ type: "provia", data: order_resp });
      })
      .catch((resp) => {
        if (resp.data) {
          this.Flash.addMessage(FlashLevels.danger, "ProVia " + resp.data.errors + " Please try again.");
        } else {
          this.Flash.addMessage(FlashLevels.danger, "Something went wrong with that order. Please try again.");
        }
      });
  }

  public back() {
    this.title = "Import";
    this.showProviaLogin = false;
    this.showProviaOrders = false;
    this.showProvia = true;
    this.showProductFileUpload = true;
    this.showSearch = false;
    this.chooseTemplates = false;
    this.showBack = false;
  }

  public clearSearchText() {
    this.search.name = "";
  }

  public addTemplate() {
    this.clearSearchText();
    this.title = `My Estimate ${_.toTitleCase(this.Pretty.name["template"])}s`;
    this.showSearch = true;
    this.showTemplates = false;
    this.showProvia = false;
    this.showProductFileUpload = false;
    this.showBack = false;
    this.chooseTemplates = true;
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }

  public upload(file, invalidFiles) {
    if (!file) {
      if (invalidFiles.length > 0) {
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " + invalidFiles[0].name + ". Upload size limited to 30mb.",
        );
      }
      return;
    }

    const type = this.fileType(file.name);

    dispatch(
      documentActions.AsyncActions.batchCreateDocument([file], {
        documentableType: "job",
        documentableId: this.EstimatorService.job.id,
        disableFlash: true,
      }),
    );

    const deferred = this.$q.defer();
    const newFile = new FileReader();
    if (type === "xlsx") {
      this.readXlsxFile(newFile, file, deferred);
    } else {
      this.readXmlFile(newFile, file, deferred);
    }

    this.spinnerPromise = deferred.promise;
  }

  public readXmlFile(newFile, file, deferred) {
    const app = this;
    newFile.onload = function (e) {
      //noinspection TypeScriptUnresolvedVariable
      const fileString = e.target.result;
      const xml = atob(fileString.split(",")[1]);
      app.$uibModalInstance.close({ type: "xml", data: xml });

      deferred.resolve();
    };
    newFile.readAsDataURL(file);
  }

  public readXlsxFile(newFile, file, deferred) {
    const app = this;
    newFile.onload = function (e) {
      //noinspection TypeScriptUnresolvedVariable
      const fileString = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(fileString, { type: "binary" });
      if (wb.Sheets["OCC_EXPORT"]) {
        const json = XLSX.utils.sheet_to_json(wb.Sheets["OCC_EXPORT"], { header: 1 });
        app.$uibModalInstance.close({ type: "occ_import", data: json });
      } else {
        const json = XLSX.utils.sheet_to_json(wb.Sheets["Sheet1"], { header: 1 });
        app.$uibModalInstance.close({ type: "xlsx", data: json });
      }
      deferred.resolve();
    };
    newFile.readAsBinaryString(file);
  }

  public fileType(filename) {
    if (filename.indexOf(".xls") > 0) {
      return "xlsx";
    } else {
      return "xml";
    }
  }

  public getProviaData() {
    const proviaToken = useSelector((state) => token(state, { kind: "provia" })) as TokenRecord;
    const tokenData = proviaToken?.get("data")?.toJSON() || {};

    return tokenData;
  }
}
