import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { ToolRecord } from "app2/src/records/Tool";
import { Map, List } from "immutable";

export const toolsById = (state: RootState) => state.getIn(["tools", "byId"]);
export const orgToolIds = (state: RootState, props: any): List<number> =>
  state.getIn(["tools", "byOrgId", parseInt(props.orgId), "tools"]);

/**
 * Gets the tool records for the given orgId
 * @param {RootState} state RootState
 * @param {{ orgId: number }} props
 * @returns {List<ToolRecord>} The list of tool records
 */
export const getOrgTools = createSelector(
  [toolsById, orgToolIds],
  (byId: Map<number, ToolRecord>, toolIds: List<number>): List<ToolRecord> => {
    if (!toolIds) {
      return List();
    }

    return toolIds.map((id) => byId.get(id));
  },
);
