import { IOrg } from "app/src/Models/Org";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { ISession, Actions } from "app/src/Common/SessionService";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { orgService } from "app2/src/api/org.service";
import { push } from "connected-react-router";
import { dispatch } from "app2/src/storeRegistry";

export class OrgShowCtrl {
  public spinnerPromise: ng.IPromise<any>;
  public parentOrg: IOrg;
  public org: IOrg;
  public showChildOrgs = false;

  public static $inject = ["OrgFetcher", "Flash", "$stateParams", "$location", "$scope", "$state", "Session"];
  constructor(
    public OrgFetcher: IOrgFetcherService,
    public Flash: IFlash,
    public $stateParams: ng.ui.IStateParamsService,
    private $location: ng.ILocationService,
    private $scope: ng.IScope,
    protected $state: ng.ui.IStateService,
    private Session: ISession,
    private $q: ng.IQService,
  ) {
    if ((!$stateParams["id"] || parseInt($stateParams["id"]) <= 0) && $stateParams["id"] !== "new") {
      $state.go("root.org_list");
      return;
    }

    if ($stateParams["id"] !== "new") {
      this.spinnerPromise = this.loadOrg(this.$stateParams["id"]);
    } else {
      if ($stateParams["parent_id"] == null || parseInt($stateParams["parent_id"]) <= 0) {
        $state.go("root.org_list");
        return;
      }

      Session.can(Actions.read, "Org", <IOrg>{ id: $stateParams["parent_id"] }).then((value) => {
        if (value) {
          const parentPromise = OrgFetcher.getParent(parseInt($stateParams["parent_id"]));
          return parentPromise.then((org: IOrg) => {
            this.parentOrg = org;
            this.parentOrg.org_count += 1;
            return this.parentOrg;
          });
        }
      });

      const watcher = this.$scope.$watch(
        () => {
          return $location.path();
        },
        () => {
          if ($location.path().indexOf("new") >= 0) {
            return;
          }

          watcher();

          this.$stateParams = this.$state.$current.locals.globals["$stateParams"];
          this.spinnerPromise = this.loadOrg(this.$stateParams["id"]);
        },
      );
    }

    $scope.$on("$destroy", () => {
      orgService.setCurrentOrgId(null);
    });
  }

  public navigate(path, orgId) {
    dispatch(push(`/orgs/${orgId}/${path}`));
  }

  public loadOrg(id: string): ng.IPromise<any> {
    const orgPromise = this.OrgFetcher.loadOrg(parseInt(id));
    return orgPromise.then((org: IOrg) => {
      this.org = org;
      return this.resolveOrgPromise();
    });
  }

  public resolveOrgPromise() {
    const promises: ng.IPromise<any>[] = [];
    if (this.org.parent_id) {
      promises.push(
        this.Session.can(Actions.read, "Org", <IOrg>{
          id: this.org.parent_id,
        }).then((value) => {
          if (value) {
            const parentPromise = this.OrgFetcher.getParent(this.org.parent_id);
            return parentPromise.then((org: IOrg) => {
              this.parentOrg = org;
              return this.parentOrg;
            });
          }
        }),
      );
    }

    if (this.org.org_count > 0) {
      promises.push(
        this.Session.can(Actions.read, "Org", <IOrg>{ id: this.org.id }).then((value) => {
          this.showChildOrgs = value;
        }),
      );
    } else {
      promises.push(
        this.Session.can("org", "Global", <IOrg>{ id: this.org.id }).then((value) => {
          this.showChildOrgs = value;
        }),
      );
    }
    return this.$q.all(promises);
  }

  public goToPriceListEditor() {
    if (this.org.activated_price_list) {
      this.$state.go("root.org_show.price_list_header.editor", {
        price_list_id: this.org.activated_price_list.price_list_id,
        active_tab: 0,
      });
    } else {
      this.$state.go("root.org_show.price_list_header_list");
    }
  }

  public isCurrentPage(page: string): boolean {
    return new RegExp(page + "$").test(this.$location.path());
  }
}
