import * as React from "react";
import { Table } from "react-bootstrap";
import { IInvoiceTextLine } from "app/src/Billing/Models/Account";
import { OrgBillingTabCtrl } from "app/src/Orgs/tabs/OrgBillingTabCtrl";

export interface InvoiceTextProps {
  lines: IInvoiceTextLine[];
  ctrl: OrgBillingTabCtrl;
}

export interface InvoiceTextState {
  lines: IInvoiceTextLine[];
}

export class InvoiceText extends React.Component<InvoiceTextProps, InvoiceTextState> {
  constructor(props) {
    super(props);

    this.state = {
      lines: props.lines,
    };

    this.lineHeaderChanged = this.lineHeaderChanged.bind(this);
    this.lineTextChanged = this.lineTextChanged.bind(this);
    this.insertText = this.insertText.bind(this);
  }

  public insertText(index: number) {
    const { lines } = this.state;
    const { ctrl } = this.props;
    const clone = _.map(lines, (l) => ({ ...l }));

    clone.splice(index + 1, 0, { header: "", text: "" });

    this.setState({
      lines: clone,
    });

    ctrl.updateInvoiceText(clone);
  }

  public deleteText(index: number) {
    const { lines } = this.state;
    const { ctrl } = this.props;
    const clone = _.map(lines, (l) => ({ ...l }));

    clone.splice(index, 1);

    this.setState({
      lines: clone,
    });

    ctrl.updateInvoiceText(clone);
  }

  public lineHeaderChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const { lines } = this.state;
    const { ctrl } = this.props;
    const clone = _.map(lines, (l) => ({ ...l }));
    const idx = parseInt(event.target.name);

    clone[idx].header = event.target.value;
    this.setState({
      lines: clone,
    });

    ctrl.updateInvoiceText(clone);
  }

  public lineTextChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const { lines } = this.state;
    const { ctrl } = this.props;
    const clone = _.map(lines, (l) => ({ ...l }));
    const idx = parseInt(event.target.name);

    clone[idx].text = event.target.value;
    this.setState({
      lines: clone,
    });

    ctrl.updateInvoiceText(clone);
  }

  public render() {
    let { lines } = this.state;

    if (!Array.isArray(lines)) {
      lines = [];
    }

    return (
      <Table striped>
        <thead>
          <tr>
            <th>Header</th>
            <th>Text</th>
            <th>
              <i className="rsf-plus-link rsf-base-66 action-button" onClick={() => this.insertText(-1)}></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {lines.map((line, idx) => {
            return (
              <tr key={idx}>
                <td style={{ width: "30%" }}>
                  <input
                    className="form-control"
                    type="text"
                    value={line.header}
                    name={`${idx}`}
                    onChange={this.lineHeaderChanged}
                  />
                </td>
                <td style={{ width: "70%" }}>
                  <input
                    className="form-control"
                    type="text"
                    name={`${idx}`}
                    value={line.text}
                    onChange={this.lineTextChanged}
                  />
                </td>
                <td>
                  <i className="rsf-plus-link rsf-base-66 action-button" onClick={() => this.insertText(idx)}></i>
                  <i className="rsf-delete-link rsf-base-66 action-button" onClick={() => this.deleteText(idx)}></i>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}
