import { fetcher } from "app2/src/helpers/Fetcher";
import { IEstimateData, EstimateRecord, DocType } from "app2/src//records/Estimate";
import { IEstimateOverviewData } from "app2/src//records/EstimateOverview";
import { IEstimateGroupData } from "app2/src//records/EstimateGroup";
import { IEstimateFinanceOptionData } from "app2/src//records/EstimateFinanceOption";
import { IOrgData } from "app2/src/records/OrgRecord";
import { IJobData } from "app2/src/records/Job";
import { IFinanceOptionData } from "app2/src/records/FinanceOption";
import { IIframeData } from "app2/src/records/Iframe";
import { ICalculatedFinancingData } from "app2/src/records/CalculatedFinancing";
import { IEstimateCommissionData } from "app2/src//records/EstimateCommission";
import { IDocumentOptions } from "app2/src/api//document.service";

export interface IEstimateOverviewIndexResponse {
  estimates: IEstimateOverviewData[];
}

export interface IEstimateResponse {
  estimate: IEstimateData;
}

export interface IEstimateLandingResponse {
  estimate: IEstimateData;
  org: IOrgData;
  job: IJobData;
  finance_option: IFinanceOptionData;
  iframe: IIframeData;
}

export interface ICalculatedFinancingResponse {
  calculations: ICalculatedFinancingData[];
}

export interface EmailEstimateArgs {
  email: string;
  message: string;
  kind: "email" | "email_csv";
  doc_type?: "estimate" | "contract";
  csv_type?: "product" | "labor_summary" | "remeasure" | "order";
}

class EstimateService {
  public jobUrl = "api/v1/jobs/:id/estimates";
  public url = "api/v1/estimates";
  public include: string[] = [
    "job",
    "org",
    "settings",
    "address",
    "line_items",
    "elevations",
    "documents",
    "images",
    "assignments",
    "preferences",
    "finance_option",
  ];

  public async load(estimateId: number): Promise<IEstimateResponse> {
    const actionUrl = fetcher.joinUrls(this.url, estimateId.toString());

    return await fetcher.get<IEstimateResponse>(actionUrl, { "include[]": this.include });
  }

  public async loadByJob(jobId: number): Promise<IEstimateOverviewIndexResponse> {
    const actionUrl = this.jobUrl.replace(":id", jobId.toString());

    return await fetcher.get<IEstimateOverviewIndexResponse>(actionUrl);
  }

  public updateOrCreate(estimate: IEstimateData): Promise<IEstimateResponse> {
    if (estimate.id > 0) {
      return this.update(estimate);
    } else {
      return this.create(estimate.job_id, estimate);
    }
  }

  public async create(jobId: number, estimate: IEstimateData): Promise<IEstimateResponse> {
    const actionUrl = this.jobUrl.replace(":id", jobId.toString());

    const data = cleanProps(estimate);

    return await fetcher.post<IEstimateResponse>(actionUrl, {
      estimate: data,
      include: this.include,
    });
  }

  public update(estimate: IEstimateData): Promise<IEstimateResponse> {
    return this._update(cleanProps(estimate));
  }

  public shallowUpdate(estimate: EstimateRecord): Promise<IEstimateResponse> {
    return this._update(shallowCleanProps(estimate));
  }

  public async landing(queryParams: any): Promise<IEstimateLandingResponse> {
    const actionUrl = fetcher.joinUrls(this.url, "landing");

    return await fetcher.get<IEstimateLandingResponse>(actionUrl, {
      ...queryParams,
      "include[]": ["line_items", "address", "preferences"],
    });
  }

  public async fetchPdf(jobId: number, estimateId: number, docType: DocType, saveTemp = false): Promise<Response> {
    let actionUrl = this.url;
    if (jobId) {
      actionUrl = this.jobUrl.replace(":id", jobId.toString());
    }
    actionUrl = fetcher.joinUrls(actionUrl, `${estimateId.toString()}.pdf`);
    const params = { doc_type: docType, save_tmp: saveTemp };
    if (!saveTemp) {
      delete params.save_tmp;
    }
    const pdf = await fetcher.fetch(actionUrl, params, {
      method: "GET",
      headers: {
        accept: "application/pdf",
      },
    });
    return pdf;
  }

  public async getCommissionPdf(estimateId: number): Promise<Blob> {
    const pdf = await fetcher.fetch(`api/v1/estimates/${estimateId}/commission.pdf`);
    return pdf.blob();
  }

  public async emailEstimate(estimateId: number, args: EmailEstimateArgs): Promise<{ location: string }> {
    const { email, message, kind, csv_type, doc_type } = args;
    const url = fetcher.joinUrls(this.url, estimateId.toString(), kind);
    return await fetcher.post(url, { doc_type, csv_type, emails: email, message, options: {} });
  }

  public async getEstimateCommission(estimatedId: number): Promise<IEstimateCommissionData> {
    const url = fetcher.joinUrls(this.url, estimatedId.toString(), "commission");
    return fetcher.get(url);
  }

  public async attachIntegration(estimateId: number, documentType: "estimate" | "contract"): Promise<void> {
    const url = fetcher.joinUrls(this.url, estimateId.toString(), "ms_upload");
    return fetcher.post(url, { doc_type: documentType });
  }

  public async signContract(estimateId: number, data: any): Promise<{ signed_document: any }> {
    const url = fetcher.joinUrls(this.url, estimateId.toString(), "signed_documents");
    return fetcher.post(url, data);
  }

  public async manuallyTrigger(estimate_id: number): Promise<void> {
    const url = fetcher.joinUrls(this.url, estimate_id.toString(), "manual_trigger");
    return fetcher.post(url);
  }

  protected async _update(estimate: Partial<IEstimateData>): Promise<IEstimateResponse> {
    const jobUrl = this.jobUrl.replace(":id", estimate.job_id.toString());
    const actionUrl = fetcher.joinUrls(jobUrl, estimate.id.toString());

    return await fetcher.patch<IEstimateResponse>(actionUrl, {
      estimate,
      include: this.include,
    });
  }
}

export const estimateService = new EstimateService();

/**
 * ToJSON while removing fields that shouldn't go back to the server.
 * @param record - Estimate Record to be transformed
 */
export const cleanProps = (estimateData: IEstimateData): any => {
  let estimate: any = { ...estimateData };

  estimate = cleanEstimatedDates(estimate);

  if (estimate.id <= 0) {
    delete estimate.id;
  }

  if (estimate.job) {
    delete estimate.job;
  }

  // Need to handle 'line items that have moved groups' like the Angular based transform.
  // Thinking this will need to be handled before getting to cleanProps using lastSavedEstimateById?

  if (estimate.groups) {
    estimate.groups_attributes = [];
    _.each(estimate.groups, (groupData: IEstimateGroupData) => {
      estimate.groups_attributes.push(groupCleanProps(groupData));
    });
    delete estimate.groups;
  }

  if (estimate.finance_options) {
    estimate.estimate_finance_options_attributes = [];
    _.each(estimate.finance_options, (estimateFinanceOptionData: IEstimateFinanceOptionData) => {
      estimate.estimate_finance_options_attributes.push(financeOptionCleanProps(estimateFinanceOptionData));
    });
    delete estimate.finance_options;
  }

  if (estimate.created_at) {
    delete estimate.created_at;
  }

  if (estimate.updated_at) {
    delete estimate.updated_at;
  }

  if (!_.isUndefined(estimate.loading)) {
    delete estimate.loading;
  }

  return estimate;
};

/**
 * ToJSON while removing fields that shouldn't go back to the server.
 * @param record - Estimate Record to be transformed
 */
export const shallowCleanProps = (record: EstimateRecord): any => {
  let estimate: any = record.toJSON() as any as IEstimateData;

  if (estimate.group_ids) {
    delete estimate.group_ids;
  }

  if (estimate.groups_attributes) {
    delete estimate.groups_attributes;
  }

  estimate = cleanEstimatedDates(estimate);

  return estimate;
};

export const groupCleanProps = (groupData: IEstimateGroupData) => {
  const group: any = { ...groupData };
  if (group.id < 0) {
    delete group.id;
  }

  group.line_items_attributes = [];

  // clean out temp ID from "new" line items
  _.each(group.line_items, (lineItemData: any) => {
    const lineItem: any = { ...lineItemData };
    if (lineItem.id <= 0) {
      delete lineItem.id;
    }

    delete lineItem.product;
    delete lineItem.estimateGroup;
    delete lineItem.orig_estimate_group_id;
    if (lineItem.estimate_group_id <= 0) {
      delete lineItem.estimate_group_id;
    }

    lineItem.options_attributes = [];
    _.each(lineItem.options, (optionData: any) => {
      const option: any = { ...optionData };
      if (option.id <= 0) {
        delete option.id;
      }

      if (option.estimate_line_item_id <= 0) {
        delete option.estimate_line_item_id;
      }

      delete option.product_option;
      delete option.estimate_line_item;
      delete option.product_option_group;

      lineItem.options_attributes.push(option);
    });

    lineItem.opening_estimations_attributes = [];
    _.each(lineItem.opening_estimations, (openingEstimationData: any) => {
      const openingEstimation: any = { ...openingEstimationData };
      if (openingEstimation.id <= 0) {
        delete openingEstimation.id;
      }
      if (openingEstimation.estimate_line_item_id <= 0) {
        delete openingEstimation.estimate_line_item_id;
      }

      lineItem.opening_estimations_attributes.push(openingEstimation);
    });

    lineItem.images_attributes = [];
    _.each(lineItem.images, (imageData: any) => {
      const image: any = { ...imageData };
      if (image.id <= 0) {
        image.remote_file_url = image.file.url;
        delete image.id;
      }
      delete image.file;

      lineItem.images_attributes.push(image);
    });

    lineItem.documents_attributes = [];
    _.each(lineItem.documents, (documentData: any) => {
      const document: any = { ...documentData };
      if (document.id <= 0) {
        return;
      }
      delete document.file;

      lineItem.documents_attributes.push(document);
    });
    delete lineItem.options;
    delete lineItem.opening_estimations;
    delete lineItem.images;
    delete lineItem.documents;

    group.line_items_attributes.push(lineItem);
  });

  group.room_estimations_attributes = [];
  _.each(group.room_estimations, (roomEstimationData: any) => {
    const roomEstimation: any = { ...roomEstimationData };
    if (roomEstimation.id < 0) {
      delete roomEstimation.id;
    }

    group.room_estimations_attributes.push(roomEstimation);
  });

  delete group.estimate;
  delete group.line_items;

  return group;
};

const cleanEstimatedDates = (estimate: IEstimateData): IEstimateData => {
  if (estimate.estimated_start instanceof Date) {
    estimate.estimated_start = (estimate.estimated_start as Date).toISOString() as any;
  }
  if (typeof estimate.estimated_start === "string") {
    estimate.estimated_start = (estimate.estimated_start as string).split("T")[0] as any;
  }

  if (estimate.estimated_end instanceof Date) {
    estimate.estimated_end = (estimate.estimated_end as Date).toISOString() as any;
  }
  if (typeof estimate.estimated_end === "string") {
    estimate.estimated_end = (estimate.estimated_end as string).split("T")[0] as any;
  }

  return estimate;
};

export const financeOptionCleanProps = (financeOption) => {
  if (financeOption.id <= 0) {
    delete financeOption.id;
  }
  return financeOption;
};
