import * as React from "react";
import { Check, FormControl } from "@tberrysoln/rsf-form";
import { token as selectToken } from "app2/src/selectors/token.selectors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import * as tokenActions from "app2/src/reducers/token.actions";
import * as FontAwesome from "react-fontawesome";
import {
  composeValidators,
  greaterThanOrEqualToValue,
  greaterThanValue,
  lessThanOrEqualToValue,
  required,
} from "app2/src/helpers/FinalFormValidator";
import { useTracking } from "react-tracking";
import { List, Map } from "immutable";
import { ConfirmDialog } from "../../Common/ConfirmDialog";
import { InputNumberProps } from "rc-input-number/lib/interface";
import { TokenRecord } from "app2/src/records/Token";

export const SettingsForm: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>();

  // Selectors
  const token = useSelector((state: RootState) => selectToken(state, { kind: "ingage" }));
  const orgId = useSelector(currentOrgId);

  // Local State
  const maxAmountPackages = 4;
  const integrationPath = ["tokens", "byOrgId", orgId, "ingage"];
  const [accordionActiveKeys, setAccordionActiveKeys] = React.useState(
    Map({ presentation: "presentation", packages: "packages", discount: "discount", financeOptions: "financeOptions" }),
  );

  // Methods
  const toggleAccordion = (key: string) => {
    let action = "open accordion settings";
    let activeKey = key;
    const isExpanded = accordionActiveKeys.get(key) === key;
    if (isExpanded) {
      action = "close accordion settings";
      activeKey = "";
    }

    trackEvent({
      action,
      accordion: key,
    });
    setAccordionActiveKeys(accordionActiveKeys.set(key, activeKey));
  };

  const addFinanceOption = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    dispatch(
      tokenActions.Actions.update({
        name: "data.finance_options",
        value: { name: "", rate: 0, term: 0 },
        rootPath: integrationPath,
        isInsert: true,
      }),
    );
    if (!accordionActiveKeys.get("financeOptions")) {
      toggleAccordion("financeOptions");
    }
  };

  const deleteFinanceOption = (index: number) => {
    dispatch(
      tokenActions.Actions.update({
        name: `data.finance_options.${index}`,
        rootPath: integrationPath,
        isDelete: true,
      }),
    );
  };

  const setDefaultPackage = (index: number) => {
    token
      ?.get("data")
      .get("packages")
      .forEach((p, i) => {
        if (i === index || p.default) {
          dispatch(
            tokenActions.Actions.update({
              name: `data.packages.${i}.default`,
              value: i === index,
              rootPath: integrationPath,
            }),
          );
        }
      });
  };
  const addPackage = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    dispatch(
      tokenActions.Actions.update({
        name: "data.packages",
        value: { name: "", description: "", markup_factor: 0, markdown_factor: 0, default: false },
        rootPath: integrationPath,
        isInsert: true,
      }),
    );
    if (!accordionActiveKeys.get("packages")) {
      toggleAccordion("packages");
    }
  };
  const deletePackage = (index: number) => {
    dispatch(
      tokenActions.Actions.update({
        name: `data.packages.${index}`,
        rootPath: integrationPath,
        isDelete: true,
      }),
    );
  };
  const defaultValidate = (value, token: TokenRecord) => {
    const packages: List<any> = token.data.get("packages");
    if (!packages.find((p: any) => p.default)) {
      return "One package must be set default";
    }
    return "";
  };

  const requiredPercentage = composeValidators(required, greaterThanOrEqualToValue(0), lessThanOrEqualToValue(100));
  const markdownValidation = composeValidators(required, greaterThanValue(0), lessThanOrEqualToValue(5));
  const markupValidation = composeValidators(required, greaterThanValue(0), lessThanOrEqualToValue(1));
  const requiredValidTerm = composeValidators(required, greaterThanValue(0));

  return (
    <>
      <Accordion defaultActiveKey="presentation" activeKey={accordionActiveKeys.get("presentation")}>
        <Card>
          <Accordion.Toggle
            eventKey="presentation"
            className="link"
            as={Card.Header}
            onClick={() => toggleAccordion("presentation")}>
            <div className="accordion-header">
              <div className="text-large">Presentation</div>
              <span className="align-right">
                <FontAwesome
                  name={`${accordionActiveKeys.get("presentation") ? "angle-up" : "angle-down"}`}
                  title="Expand"
                  size="lg"
                />
              </span>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="presentation">
            <Card.Body>
              <FormControl
                label="Presentation URL"
                name="data.presentation_url"
                validate={required}
                formControlProps={{ type: "url" } as any}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion activeKey={accordionActiveKeys.get("packages")}>
        <Card>
          <Accordion.Toggle
            eventKey="packages"
            className="link"
            as={Card.Header}
            onClick={() => toggleAccordion("packages")}>
            <div className="accordion-header">
              <div className="text-large">Packages</div>
              {token?.get("data").get("packages").size < maxAmountPackages && (
                <Button
                  variant="primary"
                  onClick={(evt: React.MouseEvent<HTMLButtonElement>) => addPackage(evt)}
                  title="Add package"
                  className="align-right">
                  <FontAwesome name="plus" />
                  &nbsp;Add
                </Button>
              )}
              <span>
                <FontAwesome
                  name={`${accordionActiveKeys.get("packages") ? "angle-up" : "angle-down"}`}
                  title="Expand"
                  size="lg"
                />
              </span>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="packages">
            <Card.Body>
              {token
                ?.get("data")
                .get("packages")
                .map((p: any, index: number) => (
                  <div key={index}>
                    <Row key={index}>
                      <Col md={6}>
                        <FormControl
                          label="Name"
                          name={`data.packages.${index}.name`}
                          validate={required}
                          formControlProps={{ type: "text" } as any}
                        />
                      </Col>
                      <Col md={6}>
                        <FormControl
                          label="Description"
                          name={`data.packages.${index}.description`}
                          as="textarea"
                          validate={required}
                          formControlProps={{ type: "text" } as any}
                        />
                      </Col>
                      <Col md={3}>
                        <FormControl
                          label="Markup factor"
                          name={`data.packages.${index}.markup_factor`}
                          validate={markupValidation}
                          formControlProps={{ type: "number" } as any}
                          numberInputProps={{ precision: 2 } as InputNumberProps}
                        />
                      </Col>
                      <Col md={3}>
                        <FormControl
                          label="Markdown factor"
                          name={`data.packages.${index}.markdown_factor`}
                          validate={markdownValidation}
                          formControlProps={{ type: "number" } as any}
                          numberInputProps={{ precision: 2 } as InputNumberProps}
                        />
                      </Col>
                      <Col md={3} style={{ marginTop: "auto" }}>
                        <Check
                          name={`data.packages.${index}.default`}
                          label="Default"
                          type="radio"
                          formCheckProps={{ onChange: () => setDefaultPackage(index) }}
                          validate={defaultValidate}
                        />
                      </Col>
                      <Col md={3} style={{ marginBottom: "auto", marginTop: "auto" }}>
                        {token?.get("data").get("packages").size > 1 && (
                          <ConfirmDialog
                            title={`Are you sure you want to delete: ${
                              token?.get("data").get("packages").get(index.toString()).name || "Untitled"
                            }?`}>
                            {(confirm) => (
                              <a
                                className="action-button pull-right"
                                onClick={confirm(() => deletePackage(index))}
                                title="Remove package">
                                <i className="rsf-delete-link rsf-base-66"></i>
                              </a>
                            )}
                          </ConfirmDialog>
                        )}
                      </Col>
                    </Row>
                    {token?.get("data").get("packages").size > index + 1 && <hr />}
                  </div>
                ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion activeKey={accordionActiveKeys.get("discount")}>
        <Card>
          <Accordion.Toggle
            eventKey="discount"
            className="link"
            as={Card.Header}
            onClick={() => toggleAccordion("discount")}>
            <div className="accordion-header">
              <div className="text-large">Discount</div>
              <span className="align-right">
                <FontAwesome
                  name={`${accordionActiveKeys.get("discount") ? "angle-up" : "angle-down"}`}
                  title="Expand"
                  size="lg"
                  className="pull-right"
                />
              </span>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="discount">
            <Card.Body>
              <Row>
                <Col md={5}>
                  <FormControl
                    label="Name"
                    name="data.discount.name"
                    validate={required}
                    formControlProps={{ type: "text" } as any}
                  />
                </Col>
                <Col md={5}>
                  <FormControl
                    label="Description"
                    name="data.discount.description"
                    validate={required}
                    formControlProps={{ type: "text" } as any}
                  />
                </Col>
                <Col md={2}>
                  <FormControl
                    label="Amount"
                    name="data.discount.amount"
                    validate={requiredPercentage}
                    formControlProps={{ type: "number" } as any}
                    numberInputProps={{ precision: 2 } as InputNumberProps}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion activeKey={accordionActiveKeys.get("financeOptions")}>
        <Card>
          <Accordion.Toggle
            eventKey="financeOptions"
            className="link"
            as={Card.Header}
            onClick={() => toggleAccordion("financeOptions")}>
            <div className="accordion-header">
              <div className="text-large">Finance Options</div>
              <Button
                variant="primary"
                className="align-right"
                onClick={(evt: React.MouseEvent<HTMLButtonElement>) => addFinanceOption(evt)}
                title="Add package">
                <FontAwesome name="plus" />
                &nbsp;Add
              </Button>
              <span>
                <FontAwesome
                  name={`${accordionActiveKeys.get("financeOptions") ? "angle-up" : "angle-down"}`}
                  title="Expand"
                  size="lg"
                />
              </span>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="financeOptions">
            <Card.Body>
              {token
                ?.get("data")
                .get("finance_options")
                .map((p: any, index: number) => (
                  <div key={index}>
                    <Row>
                      <Col md={7}>
                        <FormControl
                          label="Name"
                          name={`data.finance_options.${index}.name`}
                          validate={required}
                          formControlProps={{ type: "text" } as any}
                        />
                      </Col>
                      <Col md={2}>
                        <FormControl
                          label="Rate"
                          name={`data.finance_options.${index}.rate`}
                          validate={requiredPercentage}
                          formControlProps={{ type: "number" } as any}
                          numberInputProps={{ precision: 2 } as InputNumberProps}
                        />
                      </Col>
                      <Col md={2}>
                        <FormControl
                          label="Term"
                          name={`data.finance_options.${index}.term`}
                          validate={requiredValidTerm}
                          formControlProps={{ type: "number" } as any}
                          numberInputProps={{ precision: 0 } as InputNumberProps}
                        />
                      </Col>
                      <Col md="1" style={{ marginBottom: "auto", marginTop: "auto" }}>
                        {token?.get("data").get("finance_options").size > 1 && (
                          <ConfirmDialog
                            title={`Are you sure you want to delete: ${
                              token?.get("data").get("finance_options").get(index.toString()).name || "Untitled"
                            }?`}>
                            {(confirm) => (
                              <a
                                className="action-button pull-right"
                                onClick={confirm(() => deleteFinanceOption(index))}
                                title="Remove Finance Option">
                                <i className="rsf-delete-link rsf-base-66"></i>
                              </a>
                            )}
                          </ConfirmDialog>
                        )}
                      </Col>
                    </Row>
                    {token?.get("data").get("finance_options").size > index + 1 && <hr />}
                  </div>
                ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};
