import { Door, IDoor } from "app/src/Models/Door";
import { Deck } from "app/src/Models/Deck";
import { IWindow, Window } from "app/src/Models/Window";
import { IWallFacade, WallFacade } from "app/src/Models/WallFacade";
import { IRoofFace, RoofFace } from "app/src/Models/RoofFace";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IRoom, Room } from "app/src/Models/Room";
import { IElevation } from "./Elevation";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IOpening } from "./Opening";
import { IDeck } from "./Deck";
import { Pool, IPool } from "./Pool";
import { metric_uom, imperial_uom } from "../Common/Constants";
import { propertiesMapping } from "app2/src/records/Measurement";

export interface IMeasurement extends ng.resource.IResource<IMeasurement>, MeasurementPrototype {
  $update(): ng.IPromise<IMeasurement>;
  $update(params?: Object, success?: Function, error?: Function): ng.IPromise<IMeasurement>;
  $update(success: Function, error?: Function): ng.IPromise<IMeasurement>;
}

export interface IMeasurementResource extends ng.resource.IResourceClass<IMeasurement>, IRsfResource {
  defaultLevels?: string[];
  defaultLocations?: string[];

  fromJSON?(data: any): IMeasurement;
  toLeafletMeasure?(data: any): any;
  transformRequest?(measurement: IMeasurement): any;
  update?(data: any): IMeasurement;
}

class MeasurementPrototype {
  public id: number;
  public job_id: number;
  public geojson: any;
  public system: string;
  public roof_total_area: number;
  public roof_5pw_total_area: number;
  public roof_10pw_total_area: number;
  public roof_12pw_total_area: number;
  public roof_15pw_total_area: number;
  public roof_20pw_total_area: number;
  public roof_flat_shallow_area: number;
  public roof_low_slope_area: number;
  public roof_average_slope_area: number;
  public roof_steep_slope_area: number;
  public roof_ultra_steep_slope_area: number;
  public roof_sloped_area: number;
  public roof_10pw_sloped_area: number;
  public roof_high_roof_area: number;
  public roof_eave: number;
  public roof_rake: number;
  public roof_perimeter: number;
  public roof_ridge: number;
  public roof_hip: number;
  public roof_ridge_hip: number;
  public roof_valley: number;
  public roof_valley_eave: number;
  public roof_valley_eave_rake: number;
  public roof_step_flashing: number;
  public roof_headwall_flashing: number;
  public roof_flat_area: number;
  public roof_total_flashing: number;
  public roof_gutter_miters: number;
  public roof_downspouts: number;
  public roof_downspout_elbows: number;
  public roof_79_area: number;
  public roof_1012_area: number;
  public roof_13p_area: number;
  public roof_0_area: number;
  public roof_1_area: number;
  public roof_2_area: number;
  public roof_3_area: number;
  public roof_4_area: number;
  public roof_5_area: number;
  public roof_6_area: number;
  public roof_7_area: number;
  public roof_8_area: number;
  public roof_9_area: number;
  public roof_10_area: number;
  public roof_11_area: number;
  public roof_12_area: number;
  public roof_13_area: number;
  public roof_14_area: number;
  public roof_15_area: number;
  public roof_16_area: number;
  public roof_17_area: number;
  public roof_18_area: number;
  public roof_19_area: number;
  public roof_20_area: number;
  public roof_21_area: number;
  public roof_22_area: number;
  public roof_23_area: number;
  public roof_24_area: number;
  public roof_25_area: number;
  public roof_26p_area: number;

  public siding_total_area: number;
  public siding_5pw_total_area: number;
  public siding_10pw_total_area: number;
  public siding_15pw_total_area: number;
  public siding_18pw_total_area: number;

  public sunroom_a_wall_length: number;
  public sunroom_a_wall_height: number;
  public sunroom_a_wall_area: number;
  public sunroom_b_wall_length: number;
  public sunroom_b_wall_height: number;
  public sunroom_b_wall_area: number;
  public sunroom_c_wall_length: number;
  public sunroom_c_wall_height: number;
  public sunroom_c_wall_area: number;
  public sunroom_wall_perimeter: number;
  public sunroom_wall_total_area: number;
  public sunroom_deck_total_area: number;
  public sunroom_ceiling_area: number;
  public sunroom_studio_roof_area: number;
  public sunroom_cathedral_roof_area: number;
  public sunroom_kw_a_length: number;
  public sunroom_kw_a_height: number;
  public sunroom_kw_a_area: number;
  public sunroom_kw_b_length: number;
  public sunroom_kw_b_height: number;
  public sunroom_kw_b_area: number;
  public sunroom_kw_c_length: number;
  public sunroom_kw_c_height: number;
  public sunroom_kw_c_area: number;
  public sunroom_kw_total_length: number;
  public sunroom_kw_total_area: number;

  public roof_faces: any[];
  public wall_facades: any[];
  public windows: IWindow[];
  public doors: IDoor[];
  public rooms: IRoom[];
  public elevations: IElevation[];
  public decks: IDeck[];
  public pools: IPool[];
  public locations: string[];
  public levels: string[];
  public newId: number;

  public room_ids: number[];

  public measurement_to_uom: { [details: string]: string };

  public created_at: Date;
  public updated_at: Date;

  public hasMeasurementProperties(searchString: string): boolean {
    const regexPattern = new RegExp("^" + searchString + ".*");

    let prop: string;
    for (prop in this) {
      if (_.isFunction(prop) || !regexPattern.test(prop)) {
        continue;
      }

      if (this[prop] > 0) {
        return true;
      }
    }
    return false;
  }

  public hasValue(key: string): boolean {
    if (key === undefined || key === null) {
      return false;
    }

    return this[key] > 0;
  }

  public hasMeasurements(org: any = undefined, location: string = undefined): boolean {
    if (org === undefined && location === undefined) {
      return (
        this.hasSpecificMeasurements("roof_faces") ||
        this.hasSpecificMeasurements("wall_facades") ||
        this.hasSpecificMeasurements("windows") ||
        this.hasSpecificMeasurements("doors") ||
        this.hasSpecificMeasurements("rooms") ||
        this.hasSpecificMeasurements("decks") ||
        this.hasSpecificMeasurements("pools") ||
        this.hasMeasurementProperties("roof_") ||
        this.hasMeasurementProperties("siding_") ||
        this.hasMeasurementProperties("sunroom_")
      );
    } else {
      return (
        (this.hasSpecificMeasurements("roof_faces") && org.showMeasurementSection("roof_faces", location)) ||
        (this.hasSpecificMeasurements("wall_facades") && org.showMeasurementSection("wall_facades", location)) ||
        (this.hasSpecificMeasurements("windows") && org.showMeasurementSection("windows", location)) ||
        (this.hasSpecificMeasurements("doors") && org.showMeasurementSection("doors", location)) ||
        (this.hasSpecificMeasurements("rooms") && org.showMeasurementSection("rooms", location)) ||
        (this.hasSpecificMeasurements("decks") && org.showMeasurementSection("decks", location)) ||
        (this.hasSpecificMeasurements("pools") && org.showMeasurementSection("decks", location)) ||
        (this.hasMeasurementProperties("roof_") && org.showMeasurementSection("roofing", location)) ||
        (this.hasMeasurementProperties("siding_") && org.showMeasurementSection("siding", location)) ||
        (this.hasMeasurementProperties("sunroom_") && org.showMeasurementSection("sunroom", location))
      );
    }
  }

  public hasSpecificMeasurements(searchString: string) {
    if (this[searchString] === undefined) {
      return false;
    }
    return this[searchString].length > 0;
  }

  public getId(): number {
    if (!this.newId) {
      this.newId = 0;
    }
    this.newId -= 1;
    return this.newId;
  }

  public deleteWindow(window: IOpening) {
    if (window.id <= 0) {
      this.windows = _.filter(this.windows, (w) => {
        return w.id !== window.id;
      });
    } else {
      /**
       * If it belongs to an estimate, we can't delete it.
       */
      if (window.opening_estimations && window.opening_estimations.length > 0) {
        return;
      }
      window._destroy = true;
    }
  }

  public deleteDoor(door) {
    if (door.id <= 0) {
      this.doors = _.filter(this.doors, (d) => {
        return d.id !== door.id;
      });
    } else {
      /**
       * If it belongs to an estimate, we can't delete it.
       */
      if (door.opening_estimations && door.opening_estimations.length > 0) {
        return;
      }

      door._destroy = true;
    }
  }

  public deleteRoom(room) {
    if (room.id <= 0) {
      this.rooms = _.filter(this.rooms, (r) => {
        return r.id !== room.id;
      });
    } else {
      room._destroy = true;
    }
  }

  public deleteDeck(deck) {
    if (deck.id <= 0) {
      this.decks = _.filter(this.decks, (d) => {
        return d.id !== deck.id;
      });
    } else {
      deck._destroy = true;
    }
  }

  public deletePool(pool) {
    if (pool.id <= 0) {
      this.pools = _.filter(this.pools, (d) => {
        return d.id !== pool.id;
      });
    } else {
      pool._destroy = true;
    }
  }

  public deleteWallFacade(wallFacade: IWallFacade): void {
    if (wallFacade.id <= 0) {
      this.wall_facades = _.filter(this.wall_facades, (wf) => wf.id !== wallFacade.id);
    } else {
      wallFacade._destroy = true;
    }
  }

  public existingDoors() {
    return _.filter(this.doors, (g) => {
      return g._destroy !== true;
    });
  }

  public existingWindows() {
    return _.filter(this.windows, (g) => {
      return g._destroy !== true;
    });
  }

  public existingWallFacades() {
    return _.filter(this.wall_facades, (g) => {
      return g._destroy !== true;
    });
  }

  public clone() {
    const measurement = resources.Measurement.fromJSON({});

    _.each(Object.keys(this), (prop) => {
      if (prop.indexOf("roof_") === 0 || prop.indexOf("siding_") === 0 || prop.indexOf("sunroom_") === 0) {
        measurement[prop] = this[prop];
      }
    });

    measurement.id = this.id;
    measurement.job_id = this.job_id;
    measurement.measurement_to_uom = this.measurement_to_uom;
    measurement.created_at = this.created_at;
    measurement.updated_at = this.updated_at;

    measurement.roof_faces = _.map(this.roof_faces, (rf: IRoofFace) => rf.clone());
    measurement.wall_facades = _.map(this.wall_facades, (wf: IWallFacade) => wf.clone());
    measurement.windows = _.map(this.windows, (w: IWindow) => w.clone());
    measurement.doors = _.map(this.doors, (d: IDoor) => d.clone());
    measurement.elevations = _.map(this.elevations, (e: IElevation) => e.clone());
    if (_.isArray(this.locations)) {
      measurement.locations = this.locations.concat([]);
    }
    if (_.isArray(this.levels)) {
      measurement.levels = this.levels.concat([]);
    }
    measurement.newId = this.newId;

    return measurement;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/measurements/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IMeasurement => {
    if (status < 200 || status > 299) {
      return new Measurement({});
    }

    const data = JSON.parse(response).measurement;
    return Measurement.fromJSON(data);
  };

  const requestTransform = (measurement: IMeasurement): string => {
    const data = JSON.parse(angular.toJson(JSON.decycle(measurement)));

    if (data.geojson) {
      data.geojson = measurement.geojson;
    }
    if (data.doors) {
      _.each(data.doors, (d: IOpening) => {
        if (d.id <= 0) {
          delete d.id;
        }
      });
      data.doors_attributes = data.doors;
      delete data.doors;
    }
    if (data.windows) {
      _.each(data.windows, (w: IOpening) => {
        if (w.id <= 0) {
          delete w.id;
        }
      });
      data.windows_attributes = data.windows;
      delete data.windows;
    }
    if (data.wall_facades) {
      _.each(data.wall_facades, (wf: any) => {
        if (wf.id <= 0) {
          delete wf.id;
        }
      });
      data.wall_facades_attributes = data.wall_facades;
      delete data.wall_facades;
    }
    if (data.roof_faces) {
      _.each(data.roof_faces, (rf: any) => {
        if (rf.id <= 0) {
          delete rf.id;
        }
      });
      data.roof_faces_attributes = data.roof_faces;
      delete data.roof_faces;
    }
    if (data.rooms) {
      _.each(data.rooms, (r: any) => {
        if (r.id <= 0) {
          delete r.id;
        }
      });
      data.rooms_attributes = data.rooms;
      delete data.rooms;
    }
    if (data.decks) {
      // had to use measurement.decks becausue decycle was causing issues with the points and $ref
      data.decks_attributes = JSON.parse(
        angular.toJson(
          measurement.decks.map((d) => {
            const deck = { ...d };
            if (_.isNaN(parseInt(deck.id as any))) {
              delete deck.id;
            }
            return deck;
          }),
        ),
      );
      delete data.decks;
    }
    if (data.pools) {
      // had to use measurement.pools becausue decycle was causing issues with the points and $ref
      data.pools_attributes = JSON.parse(
        angular.toJson(
          measurement.pools.map((d) => {
            const pool = { ...d };
            if (_.isNaN(parseInt(pool.id as any))) {
              delete pool.id;
            }
            return pool;
          }),
        ),
      );
      delete data.pools;
    }

    delete data.levels;
    delete data.locations;

    if (data.elevations) {
      delete data.elevations;
    }

    return angular.toJson({ measurement: data });
  };

  const Measurement: IMeasurementResource = <IMeasurementResource>$resource(
    url,
    { id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: requestTransform,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Measurement.prototype, MeasurementPrototype.prototype);

  Measurement.fromJSON = (data: any): IMeasurement => {
    if (!data.elevations) {
      data.elevations = [];
    }

    if (!data.system) {
      data.system = "imperial";
    }

    const measurement = new Measurement(data);

    _.each(data.doors, (d, index) => {
      measurement.doors[index] = Door.fromJSON(d);
    });
    _.each(data.windows, (w, index) => {
      measurement.windows[index] = Window.fromJSON(w);
    });
    _.each(data.wall_facades, (wf, index) => {
      measurement.wall_facades[index] = WallFacade.fromJSON(wf);
    });
    _.each(data.roof_faces, (rf, index) => {
      measurement.roof_faces[index] = RoofFace.fromJSON(rf);
    });
    _.each(data.rooms, (r, index) => {
      measurement.rooms[index] = Room.fromJSON(r);
    });
    _.each(data.decks, (d, idx) => {
      measurement.decks[idx] = Deck.fromJSON(d);
    });
    _.each(data.pools, (d, idx) => {
      measurement.pools[idx] = Pool.fromJSON(d);
    });

    _.each(data.elevations, (e, idx) => {
      measurement.elevations[idx] = resources.Elevation.fromJSON(e);
      measurement.elevations[idx].measurement = measurement;
      measurement.elevations[idx].associateOpenings();
    });

    if (data.system === "metric") {
      measurement.measurement_to_uom = metric_uom;
    } else {
      measurement.measurement_to_uom = imperial_uom;
    }

    ["doors", "windows", "rooms", "wall_facades", "decks", "pools"].forEach((name) => {
      if (!_.isArray(measurement[name])) {
        measurement[name] = [];
      }
    });

    return measurement;
  };

  Measurement.toLeafletMeasure = (geoJSON_data: any): any => {
    const leaflet_data = [];
    _.each(geoJSON_data, (m: any) => {
      const points = [];
      switch (m.geometry.type) {
        case "Polygon":
          _.each(m.geometry.coordinates[0], (point) => {
            points.push({ lat: point[1], lng: point[0] });
          });
          break;
        case "LineString":
          points.push({ lat: m.geometry.coordinates[0][1], lng: m.geometry.coordinates[0][0] });
          points.push({ lat: m.geometry.coordinates[1][1], lng: m.geometry.coordinates[1][0] });
          break;
        case "Point":
          points.push({ lat: m.geometry.coordinates[1], lng: m.geometry.coordinates[0] });
          break;
      }
      leaflet_data.push({ points: points, properties: m.properties });
    });
    return leaflet_data;
  };

  Measurement.transformRequest = (measurement: IMeasurement) => {
    return requestTransform(measurement);
  };

  Measurement.inject = (injected: IRepository) => {
    resources = injected;
  };

  Measurement.defaultLevels = ["1", "2", "3", "Basement"];

  Measurement.defaultLocations = [
    "Bedroom 1",
    "Bedroom 2",
    "Bedroom 3",
    "Master Bedroom",
    "Bathroom 1",
    "Bathroom 2",
    "Kitchen",
    "Living Room",
    "Family Room",
    "Dining Room",
    "Sunroom",
    "Entryway",
    "Basement",
    "Mud Room",
    "Office",
    "Den",
  ];

  _.hiddenExtend(Measurement.prototype, MeasurementPrototype.prototype);

  return Measurement;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
