import { Record, List } from "immutable";

export function fromJSON(json: Partial<IPaymentAuthorizationData>) {
  return new PaymentAuthorizationRecord(json);
}

export const paymentProviders = List(["pay_simple", "card_connect"]);

export interface IPaymentAuthorizationData {
  orgId: number;
  pay_simple: boolean;
  card_connect: boolean;
}

export interface IPaymentAuthorizationRecord extends IPaymentAuthorizationData {
  loading: boolean;
  errors: List<string>;
}

const defaultPaymentAuthProps: IPaymentAuthorizationRecord = {
  orgId: -1,
  pay_simple: false,
  card_connect: false,

  loading: false,
  errors: List<string>(),
};

export class PaymentAuthorizationRecord extends Record(defaultPaymentAuthProps) implements IPaymentAuthorizationRecord {
  public orgId!: number;

  public pay_simple!: boolean;
  public card_connect!: boolean;

  public loading!: boolean;
  public errors!: List<string>;

  public constructor(values?: Partial<IPaymentAuthorizationRecord>) {
    values ? super(values) : super();
  }
}
