import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { PoolRecord } from "app2/src/records/Pool";

export const pool = (state: RootState, props: any) => state.getIn(["pools", "byId", props.poolId]);

export const pools = createSelector([(state, props) => ({ state, props })], (stateProps: any): List<PoolRecord> => {
  const { state, props } = stateProps;
  const { poolIds } = props;
  return poolIds.map((id: number) => pool(state, { poolId: id }));
});
