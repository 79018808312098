import { ThunkAction } from "redux-thunk";
import { List } from "immutable";
import { ActionsUnion, createAction } from "./Utils";
import { ICrmUserData, CrmUserRecord } from "app2/src/records/integrations/CrmUser";
import { loadByOrg, updateUser, IIntegrationOptions } from "app2/src/api/crmUser.service";
import { QueryParamsRecord } from "app2/src/records/Page";
import { handleErrors } from "app2/src/reducers/Utils";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { Actions as PaginationActions } from "app2/src/reducers/pagination.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { RootState, RootActions } from ".";
import { RootDispatchType } from "app2/src/store";

export interface IAuthorizeReponse {
  authorized: boolean;
  id: number;
}

// SINGLE
export const FETCH_CRM_USER = "@crmUsers/FETCH_CRM_USER";
export const RECEIVE_CRM_USER = "@crmUsers/RECEIVE_CRM_USER";
export const SET_CRM_USER_LOADED = "@crmUsers/SET_CRM_USER_LOADED";
// MULTIPLE
export const RECEIVE_CRM_USERS = "@crmUsers/RECEIVE_CRM_USERS";

export const Actions = {
  // SINGLE
  fetchCrmUser: (id: number) => createAction(FETCH_CRM_USER, { id }),
  receiveCrmUser: (crmUser: ICrmUserData) => createAction(RECEIVE_CRM_USER, { crmUser }),
  setCrmUserLoaded: (id: number) => createAction(SET_CRM_USER_LOADED, { id }),
  // MULTIPLE
  receiveCrmUsers: (crmUsers: ICrmUserData[]) => createAction(RECEIVE_CRM_USERS, { crmUsers }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, RootActions>;

export const AsyncActions = {
  queryUsers: (options: IIntegrationOptions, queryParams: QueryParamsRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const model = options.modelName || options.userType;
      try {
        dispatch(PaginationActions.fetchPage(model, queryParams));

        const result = await loadByOrg(options, queryParams);
        const pageData = {
          ids: List(_.pluck(result.crm_users, "id")),
          pagination: result.meta.pagination,
        };
        dispatch(Actions.receiveCrmUsers(result.crm_users));
        dispatch(PaginationActions.receivePage(model, queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(PaginationActions.receivePageError(model, queryParams, errors));
      }
    };
  },
  updateUser: (crmUser: CrmUserRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(Actions.fetchCrmUser(crmUser.id));

        const result = await updateUser(crmUser);
        dispatch(Actions.receiveCrmUser(result.crm_user));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "User updated successfully"));
      } catch (response) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, handleErrors(response)));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
