import { RootState } from "app2/src/reducers";

/**
 * Returns the navDisplay state
 *
 * @param {RootState} state
 * @returns boolean
 */
export const navDisplay = (state: RootState, props?: any) => state.getIn(["components", "common", "navDisplay"]);

/**
 * Returns the sendInviteModal state
 *
 * @param {RootState} state
 * @returns boolean
 */
export const sendInviteModal = (state: RootState, props?: any) =>
  state.getIn(["components", "common", "sendInvite", "modal"]);

/**
 * Returns the sendInviteType state
 *
 * @param {RootState} state
 * @returns string as "screenshare" || "image_uploader"
 */
export const sendInviteType = (state: RootState, props?: any) =>
  state.getIn(["components", "common", "sendInvite", "type"]);

/**
 * Returns the sendInviteOptions state, additional options for image_uploader
 *
 * @param {RootState} state
 * @returns options
 */
export const sendInviteOptions = (state: RootState, props?: any) =>
  state.getIn(["components", "common", "sendInvite", "options"]);

/**
 * Returns the sendInviteSent state, an invite has been sent each time it's incremented
 *
 * @param {RootState} state
 * @returns number
 */
export const sendInviteSent = (state: RootState, props?: any) =>
  state.getIn(["components", "common", "sendInvite", "sent"]);

/**
 * Returns the `pageTitle` state
 *
 * @param {RootState} state
 * @returns {string} the Page title
 */
export const pageTitle = (state: RootState, props?: any) => state.getIn(["components", "common", "pageTitle"]);
