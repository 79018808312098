import * as Immutable from "immutable";
import { Action } from "redux";

const validateNextState = <S extends Immutable.Record<any>, A extends Action>(
  nextState: S,
  reducerName: string,
  action: A,
): void => {
  // eslint-disable-next-line no-undefined
  if (nextState === undefined) {
    throw new Error(
      'Reducer "' +
        reducerName +
        '" returned undefined when handling "' +
        action.type +
        '" action. To ignore an action, you must explicitly return the previous state.',
    );
  }
};

export const combineReducers = <S extends Immutable.Record<any>, A extends Action>(reducers: Object): Function => {
  const reducerKeys = Object.keys(reducers);

  const defaultState = Immutable.Record<any>({})();

  //@ts-ignore
  return (inputState: S = defaultState, action: A): S => {
    return inputState.withMutations((temporaryState) => {
      reducerKeys.forEach((reducerName) => {
        const reducer = reducers[reducerName];

        if (!_.includes(reducerKeys, reducerName)) {
          return temporaryState;
        }

        const currentDomainState = temporaryState.get(reducerName);
        const nextDomainState = reducer(currentDomainState, action);

        validateNextState<S, A>(nextDomainState, reducerName, action);

        temporaryState.set(reducerName, nextDomainState);
      });
    });
  };
};
