import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { DiscountRecord } from "app2/src/records/DiscountRecord";
import { Map, List } from "immutable";

const discountsById = (state: RootState) => state.getIn(["discounts", "discountsById"]);
const orgDiscountIds = (state: RootState, props: any) =>
  state.getIn(["discounts", "discountsByOrgId", parseInt(props.orgId), "discounts"]);

export const getOrgDiscounts = createSelector(
  [discountsById, orgDiscountIds],
  (byId: Map<number, DiscountRecord>, discounts: List<number>) => {
    if (!discounts) {
      return List();
    }

    const interim = discounts.map((id) => byId.get(id)).sortBy((d) => d.sort_order);
    return interim;
  },
);
