import { ICommand } from "app/src/Commands/Command";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";

export class RemoveEstimateLineItemCmd implements ICommand {
  public removedLineItem: IEstimateLineItem;
  public indexOf: number;

  constructor(
    public estimate: IEstimate,
    public lineItem: IEstimateLineItem,
  ) {}

  public execute(): boolean {
    if (this.lineItem.id < 0) {
      const group: IEstimateGroup = _.find<IEstimateGroup>(this.estimate.existingGroups(), (g) => {
        return g.id === this.lineItem.estimateGroup.id;
      });

      this.indexOf = _.findIndex(group.line_items, (li: IEstimateLineItem) => {
        return li.id === this.lineItem.id;
      });

      this.removedLineItem = group.line_items.splice(this.indexOf, 1)[0];
    } else {
      this.removedLineItem = this.lineItem;
      this.lineItem._destroy = true;
    }

    this.estimate.updateTotal();
    return true;
  }
}
