import * as estimateComparisonActions from "./estimateComparison.actions";
import { EstimateComparisonRecord, fromJSON } from "app2/src/records/EstimateComparison";
import { EstimateComparisonLinkRecord, fromJSON as eclFromJSON } from "app2/src/records/EstimateComparisonLink";
import { List, Map, Record } from "immutable";
import { RootState, RootActions } from ".";

export const EstimateComparisonStateRecord = Record({
  byJobId: Map<number, EstimateComparisonRecord>(),
  lastSavedByJobId: Map<number, EstimateComparisonRecord>(),
  expandDrawer: false,
});

export const initialState = EstimateComparisonStateRecord();
export type EstimateComparisonState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get("estimateComparisons")) {
    state = state.set("estimateComparisons", initialState);
  }

  let estimateComparisonRecord: EstimateComparisonRecord;
  switch (action.type) {
    case estimateComparisonActions.FETCH_ESTIMATE_COMPARISON:
      if (state.getIn(["estimateComparisons", "byJobId", action.payload.jobId])) {
        return state.setIn(["estimateComparisons", "byJobId", action.payload.jobId, "loading"], true);
      }

      estimateComparisonRecord = fromJSON({ job_id: action.payload.jobId, loading: true });

      return state.setIn(["estimateComparisons", "byJobId", action.payload.jobId], estimateComparisonRecord);

    case estimateComparisonActions.RECEIVE_ESTIMATE_COMPARISON:
      state = state.setIn(
        ["estimateComparisons", "byJobId", action.payload.estimateComparison.job_id],
        action.payload.estimateComparison,
      );
      state = state.setIn(
        ["estimateComparisons", "lastSavedByJobId", action.payload.estimateComparison.job_id],
        action.payload.estimateComparison,
      );
      state = state.setIn(
        ["estimateComparisons", "lastSavedByJobId", action.payload.estimateComparison.job_id, "show"],
        true,
      );
      return state.setIn(["estimateComparisons", "byJobId", action.payload.estimateComparison.job_id, "show"], true);

    case estimateComparisonActions.SET_LOADED:
      return state.setIn(["estimateComparisons", "byJobId", action.payload.jobId, "loading"], false);

    case estimateComparisonActions.BUILD_ESTIMATE_COMPARISON:
      const estimateComparison = state.getIn(["estimateComparisons", "byJobId", action.payload.jobId]);
      let builtEstimateComparison: EstimateComparisonRecord;
      if (estimateComparison) {
        const estimateComparisonJson = estimateComparison.toJS();
        estimateComparisonJson.estimate_comparison_links = List();
        builtEstimateComparison = fromJSON(estimateComparisonJson);
      } else {
        builtEstimateComparison = fromJSON({ job_id: action.payload.jobId });
      }

      state = state.setIn(["estimateComparisons", "byJobId", builtEstimateComparison.job_id], builtEstimateComparison);

      action.payload.estimateIds.forEach((estimate_id) => {
        state = reducer(
          state,
          estimateComparisonActions.Actions.addEstimateComparisonLink(estimate_id, action.payload.jobId),
        );
      });

      return state;

    case estimateComparisonActions.VIEW_ESTIMATE_COMPARISON:
      return state.setIn(["estimateComparisons", "byJobId", action.payload.jobId, "show"], true);

    case estimateComparisonActions.SHOW_DRAWER:
      return state.setIn(["estimateComparisons", "expandDrawer"], true);

    case estimateComparisonActions.HIDE_DRAWER:
      return state.setIn(["estimateComparisons", "expandDrawer"], false);

    case estimateComparisonActions.ADD_ESTIMATE_COMPARISON_LINK:
      const newECL = eclFromJSON({ estimate_id: action.payload.estimateId } as any);
      const currentECLs = state.getIn([
        "estimateComparisons",
        "byJobId",
        action.payload.jobId,
        "estimate_comparison_links",
      ]);
      const newECLs = currentECLs.push(newECL);
      return state.setIn(
        ["estimateComparisons", "byJobId", action.payload.jobId, "estimate_comparison_links"],
        newECLs,
      );

    case estimateComparisonActions.REMOVE_ESTIMATE_COMPARISON_LINK:
      const currentLinks = state.getIn([
        "estimateComparisons",
        "byJobId",
        action.payload.jobId,
        "estimate_comparison_links",
      ]);
      const newLinks = currentLinks.map((ecl: EstimateComparisonLinkRecord) => {
        if (ecl.estimate_id === action.payload.estimateId) {
          return ecl.set("_destroy", true);
        } else {
          return ecl;
        }
      });
      return state.setIn(
        ["estimateComparisons", "byJobId", action.payload.jobId, "estimate_comparison_links"],
        newLinks,
      );

    default:
      return state;
  }
};
