import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { PaymentRecord } from "../records/PaymentRecord";
import { fromJSON, PaymentAuthorizationRecord, paymentProviders } from "app2/src/records/PaymentAuthorizationRecord";
import { Map, List } from "immutable";
import { currentOrgId } from "./org.selectors";

const paymentsById = (state: RootState) => state.getIn(["payments", "paymentsById"]);
const paymentsByPage = (state: RootState, props: any) =>
  state.getIn(["payments", "pages", parseInt(props.page), "payments"]);

/**
 * Returns the payment authorization info for the given orgId
 *
 * @param {RootState} state
 * @param {{orgId: number}} props
 * @returns {Map}
 */
export const paymentAuth = (state: RootState, props: { orgId: number }): PaymentAuthorizationRecord =>
  state.getIn(["payments", "authByOrgId", props.orgId]);

/**
 * Returns the first PaymentRequestKind that is authorized for the given orgId, based on Payment Providers
 *
 * @param {RootState} state
 * @param {{orgId: number}} props
 * @returns {PaymentRequestKind | ""}
 */
export const paymentAuthRequestKind = createSelector([paymentAuth], (auth: PaymentAuthorizationRecord) => {
  if (!auth) return "";

  const found = paymentProviders.find((v) => auth.get(v as any));

  if (!found) return "";

  switch (found) {
    case "card_connect":
      return "cardconnect";
    case "pay_simple":
      return "paysimple";
  }
});

/**
 * Returns a boolean saying whether ANY payment method is authorized for the current OrgId
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const paymentAuthorized = (state: RootState): boolean => {
  const orgId = currentOrgId(state);
  const auth: PaymentAuthorizationRecord = state.getIn(["payments", "authByOrgId", orgId], fromJSON({}));

  return paymentProviders.some((v) => auth.get(v as any));
};

export const getPagedPayments = createSelector(
  [paymentsById, paymentsByPage],
  (byId: Map<number, PaymentRecord>, payments: List<number>) => {
    if (!payments) {
      return List();
    }

    return payments.map((id) => byId.get(id));
  },
);
