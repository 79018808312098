import * as React from "react";
import { DateFormat } from "app2/src/helpers/Format";

export interface IAttemptProps {
  attempt: any;
}

export const Attempt: React.FC<IAttemptProps> = ({ attempt }) => {
  // state

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const errorStatusRegex = /^[4-5]\d{2}$/;

  return (
    <>
      <tr>
        <td>
          {errorStatusRegex.test(attempt.get("status")?.toString()) ? (
            <span className={"badge badge-danger"}>Error</span>
          ) : (
            <span className={"badge badge-success"}>Success</span>
          )}
        </td>
        <td> {DateFormat(attempt.get("attempted_at"), "shortDate")}</td>
        <td> {DateFormat(attempt.get("attempted_at"), "shortTime")}</td>
        <td> {attempt.get("status")}</td>
        <td>
          <span
            className={`pull-right fa fa-gray ${expanded ? "fa-chevron-up" : "fa-chevron-down"}`}
            onClick={() => setExpanded(!expanded)}></span>
        </td>
      </tr>
      {expanded && (
        <>
          {" "}
          <tr className="border-0">
            <td className="d-flex">
              <strong> Response Code</strong>
            </td>
            <td>{attempt.get("status")}</td>
          </tr>
          <tr className="border-0">
            <td className="d-flex">
              <strong> Response Headers</strong>
            </td>
            <td>
              <pre className="code-snippet text_wrap">
                <code>{JSON.stringify(attempt.get("headers"), null, 2)}</code>
              </pre>
            </td>
          </tr>
          <tr className="border-0">
            <td className="d-flex">
              <strong> Response</strong>
            </td>
            <td>
              <pre className="code-snippet text_wrap">
                <code>{JSON.stringify(attempt.get("body"), null, 2)}</code>
              </pre>
            </td>
            <td></td>
          </tr>
        </>
      )}
    </>
  );
};
