import { RootState } from "app2/src/reducers";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { pools } from "app2/src/selectors/pool.selectors";
import { List } from "immutable";
import * as React from "react";
import { Col, Row, Table, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { StoreRegistry } from "app2/src/storeRegistry";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";
import { getUoM } from "app2/src/records/Measurement";

export const Pools: React.FC = () => {
  // Hooks
  const { trackEvent } = useTracking<any>({ component: "Pools" });

  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const poolIds = measurement?.get("pool_ids") || List();
  const poolRecords = useSelector((state: RootState) => pools(state, { poolIds }));
  const editing = useSelector(measurementEditing);
  const uom = getUoM(measurement);

  //  Methods
  const openPoolTool = () => {
    trackEvent({ action: "open pool tool" });
    StoreRegistry.get<any>("$rootScope").$emit("pool_tool:open", {});
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Pools</h2>
        </Col>
        <Col>
          <Button
            variant="primary"
            onClick={openPoolTool}
            className="float-right"
            disabled={editing}
            title={editing ? "Disabled for editing" : ""}>
            Pool Tool
          </Button>
        </Col>
      </Row>
      {poolRecords.size > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Area</th>
              <th>Perimeter</th>
              <th>Average Depth</th>
              <th>Gallons</th>
            </tr>
          </thead>
          <tbody>
            {poolRecords?.map((pool, idx) => {
              return (
                <tr key={idx}>
                  <td>{pool.name}</td>
                  <td>
                    {pool.area} {uom["pool_area"]}
                  </td>
                  <td>
                    {pool.perimeter} {uom["pool_perimeter"]}
                  </td>
                  <td>
                    {pool.average_depth} {uom["pool_average_depth"]}
                  </td>
                  <td>
                    {pool.volume} {uom["pool_volume"]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">
          <h2>No pools added. Let's add one.</h2>
          <Button
            variant="primary"
            onClick={openPoolTool}
            disabled={editing}
            title={editing ? "Disabled for editing" : ""}>
            Pool Tool
          </Button>
        </div>
      )}
    </>
  );
};
