import { authService, IAuthService } from "app2/src/helpers/Auth.service";
import * as config from "react-global-configuration";
import * as authActions from "../reducers/auth.actions";

import { useState, dispatch, StoreRegistry } from "../storeRegistry";

const getAuthService = () => {
  return StoreRegistry.get<IAuthService>("authService") || authService;
};

class Fetcher {
  public user_includes: string[] = ["permissions", "org", "preferences", "settings"];

  public baseUrl: string;
  public inRecovery = false;
  public recoveryPromise: Promise<any>;

  public get token(): string {
    return useState().getIn(["auth", "token"]);
  }

  public get refreshToken(): string {
    return useState().getIn(["auth", "refreshToken"]);
  }

  public get accessUid(): string {
    return useState().getIn(["auth", "accessUid"]) || getAuthService().getAccessUid();
  }

  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl || config.get("BASE_URL");
  }

  public fetch(url: string, params = {}, options = null, recovered = false): Promise<Response> {
    if (this.inRecovery) {
      return this.recoveryPromise.then(() => {
        return this.fetch(url, params, options, true);
      });
    }

    options = this.headers(this.credentials(options, url));
    if (options.method && (options.method === "POST" || options.method === "PATCH")) {
      if (options.formData) {
        options.body = params;
        options.headers["Accept"] = "application/json";
        delete options.headers["Content-Type"];
        delete options.formData;
      } else {
        options.body = JSON.stringify(params);
      }
      url = this.urlOptions(url);
    } else {
      url = this.urlOptions(url, params);
    }

    return fetch(url, options).then((response) => this.retryStatus(response, url, options, recovered));
  }

  public get<T>(url: string, params: any = {}, options: any = {}): Promise<T> {
    return this.fetch(url, params, options).then(this.json);
  }

  public patch<T>(url: string, params: any = {}, options: any = {}): Promise<T> {
    options = options || {};
    options.method = "PATCH";
    return this.fetch(url, params, options).then(this.json);
  }

  public post<T>(url: string, params: any = {}, options: any = {}): Promise<T> {
    options = options || {};
    options.method = "POST";
    return this.fetch(url, params, options).then(this.json);
  }

  public delete<T>(url: string, params: any = {}, options: any = {}): Promise<T> {
    options = options || {};
    options.method = "DELETE";
    return this.fetch(url, params, options).then(this.json);
  }

  public urlOptions(url: string, params = {}): string {
    let joined: URL;
    if (url.indexOf("http") !== 0) {
      joined = new URL(url, this.baseUrl);
    } else {
      joined = new URL(url);
    }
    if (Object.keys(params).length > 0) {
      const searchParams = new URLSearchParams();

      for (const k in params) {
        const v = params[k];

        if (Array.isArray(v)) {
          v.forEach((q) => {
            searchParams.append(k, q);
          });
        } else {
          searchParams.append(k, v);
        }
      }

      joined.search = searchParams.toString();
    }

    return joined.toString();
  }

  /**
   * This uses the URL API under the hood but essentially disabled the relative functionality.
   * You can't combine "api/v1/users" with "../orgs" to get "api/v1orgs" like you can with raw URL
   * @param url base URL to be joined too
   * @param args list of parts to be attached to base URL
   */
  public joinUrls(url: string, ...args: string[]): string {
    let joined = this.urlOptions(url);

    if (joined.slice(-1) !== "/") {
      joined = `${joined}/`;
    }

    args.forEach((arg, idx) => {
      joined = new URL(arg, joined).toString();

      if (joined.slice(-1) !== "/" && idx !== args.length - 1) {
        joined = `${joined}/`;
      }
    });

    return joined;
  }

  public headers(options) {
    options = options || {};
    options.headers = options.headers || {};
    options.headers["X-Requested-With"] = "XMLHttpRequest";

    if (options.method && (options.method === "PATCH" || options.method === "POST")) {
      options.headers["Content-Type"] = "application/json";
    }

    return options;
  }

  public credentials(options, url) {
    options = options || {};

    if (this.token) {
      options.headers = options.headers || {};
      const authHeader = url.startsWith(config.get("STORAGE_URL")) ? "occ-authorization" : "Authorization";
      options.headers[authHeader] = "Bearer " + this.token;
    }

    if (this.accessUid) {
      options.headers = options.headers || {};
      options.headers["x-rsf-auth"] = this.accessUid;
    }

    return options;
  }

  public standardStatus(response: Response) {
    if (response.ok) {
      return response;
    }

    const contentType = response.headers.get("content-type");
    let promise;
    if (contentType && contentType.indexOf("application/json") !== -1) {
      promise = response.json();
    } else {
      promise = response.text();
    }

    return promise.then((value: any) => {
      return Promise.reject({ status: response.status, statusText: response.statusText, data: value });
    });
  }

  public retryStatus(response: Response, url, options, recovered) {
    if (response.ok) {
      return response;
    } else if (response.status === 419 && !recovered) {
      if (!this.recoveryPromise) {
        this.recoveryPromise = this.retry();
      }
      return this.recoveryPromise.then(() => {
        return this.fetch(url, {}, options, true);
      });
    } else {
      return this.standardStatus(response);
    }
  }

  public json(response: Response): Promise<any> {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json();
    } else {
      return response.text();
    }
  }

  private async retry() {
    try {
      this.inRecovery = true;
      const response = await getAuthService().refreshToken(this.refreshToken);
      this.inRecovery = false;
      this.recoveryPromise = null;
      return response;
    } catch (e) {
      console.error("Fetcher Retry: ", e);
      this.inRecovery = false;
      dispatch(authActions.Actions.setRefreshTokenExpired(true));
    }
  }
}

export const fetcher = new Fetcher();
export const analyticsFetcher = new Fetcher(config.get("RSF_ANALYTICS_URL"));
(window as any).rsfFetcher = fetcher;
