import { IJob } from "app/src/Models/Job";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { IMeasurementImporterFactory } from "app/src/Common/MeasurementImporterFactory";
import { IReportResource } from "app/src/Models/Report";
import { ISession } from "app/src/Common/SessionService";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { useState, dispatch } from "app2/src/storeRegistry";
import * as hoverActions from "app2/src/reducers/integrations/hover.actions";

export class ImportMeasurementsCtrl {
  public spinnerPromise: ng.IPromise<any>;
  public showBillingStatusModal = false;
  public gafEnvironment = false;

  public canOrderEagleview = false;
  public canImportHover = false;
  public canOrderQuickMeasure = false;
  public canOrderRoofScope = false;
  public canOrderPlnar = false;

  public get canOrderMultiple(): boolean {
    return (
      _.chain([
        this.canOrderEagleview,
        this.canImportHover,
        this.canOrderQuickMeasure,
        this.canOrderPlnar,
        this.canOrderRoofScope,
      ])
        .groupBy((v) => v.toString())
        .value()["true"].length > 1
    );
  }

  public static $inject = [
    "$uibModalInstance",
    "job",
    "Flash",
    "$stateParams",
    "BaseConfig",
    "$rootScope",
    "MeasurementImporterFactory",
    "Report",
    "$uibModal",
    "Session",
    "Pretty",
  ];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public job: IJob,
    public Flash: IFlash,
    public $stateParams: ng.ui.IStateParamsService,
    public BaseConfig: IBaseConfig,
    public $rootScope: RsfRootScope,
    public MeasurementImporterFactory: IMeasurementImporterFactory,
    public Report: IReportResource,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public Session: ISession,
    public Pretty: IPretty,
  ) {
    this.gafEnvironment = BaseConfig.ENVIRONMENT === "gaf";
    MeasurementImporterFactory.init(job, true, "Measurements");

    Session.canReport("eagleview", ["order", "import"]).then((result: boolean) => (this.canOrderEagleview = result));
    Session.canReport("hover", ["import"]).then((result: boolean) => (this.canImportHover = result));
    Session.canReport("quick_measure", ["order"]).then(
      (result: boolean) => (this.canOrderQuickMeasure = !this.gafEnvironment && result),
    );
    Session.canReport("roof_scope", ["order"]).then((result: boolean) => (this.canOrderRoofScope = result));
    Session.canReport("plnar", ["order"]).then((result: boolean) => (this.canOrderPlnar = result));

    dispatch(hoverActions.Actions.setCurrentJob(this.job.id));
  }

  public order(provider, kind) {
    const billingStatus = this.Session.billingStatus;

    if (billingStatus.status !== 1) {
      this.showBillingStatusModal = true;
    } else {
      const order_modal = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
        animation: true,
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        templateUrl: "src/Jobs/tabs/core_logic_order_modal.html",
        controller: "CoreLogicOrderModalCtrl",
        backdrop: "static",
        controllerAs: "$ctrl",
        size: "lg",
        resolve: {
          job: this.job,
          args: { provider: provider, kind: kind },
        },
      });
      this.$uibModalInstance.close({ type: "order_modal", object: order_modal });
    }
  }

  public importHover() {
    if (useState().getIn(["integrations", "hover", "authorized"])) {
      const order_modal = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
        size: "lg",
        component: "hoverImport",
        backdrop: "static",
        resolve: {
          jobId: this.job.id,
        },
      });
      this.$uibModalInstance.close({ type: "hover_modal", object: order_modal });
    } else {
      this.Flash.addMessage(
        FlashLevels.danger,
        "HOVER Measurement importing is not authorized. Contact your Organization Administrator to enable importing HOVER Measurements directly from " +
          this.BaseConfig.APP_NAME +
          ".",
      );
    }
  }

  public orderQuickMeasure() {
    this.$uibModalInstance.close({ type: "quick_measure_modal" });
  }

  public orderEv() {
    const billing_ok = this.Report.check({ provider: "eagleview" });
    this.spinnerPromise = billing_ok.$promise
      .then(() => {
        const order_modal = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
          size: "lg",
          component: "eagleviewOrderingModal",
          resolve: {
            org: this.job.org,
            user: this.Session.currentUser,
            job: this.job,
          },
        });
        this.$uibModalInstance.close({ type: "order_modal", object: order_modal });
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "EagleView Report ordering is not available. Contact your Organization Administrator to enable ordering an EagleView Report directly from " +
            this.BaseConfig.APP_NAME +
            ".",
        );
      });
  }

  public orderRoofScope() {
    this.$uibModalInstance.close({ type: "roof_scope_modal" });
  }

  public orderPlnar() {
    this.$uibModalInstance.close({ type: "plnar_modal" });
  }

  public upload(files, invalidFiles) {
    this.$uibModalInstance.close({
      type: "upload",
      object: this.MeasurementImporterFactory.upload(files, invalidFiles),
    });
  }

  public cancel() {
    this.$uibModalInstance.dismiss();
  }

  public billingStatusModalClosed = (navigateAway: boolean) => {
    this.$rootScope.$apply(() => {
      this.showBillingStatusModal = false;
      if (navigateAway) {
        this.$uibModalInstance.close();
      }
    });
  };
}
