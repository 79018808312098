import * as ng from "angular";
import * as angulartics from "angulartics";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IPretty } from "../Common/PrettyNameService";
import { ISignedDocumentResponse } from "../Models/SignedDocument";
import { IUser } from "../Models/User";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IOrg } from "app/src/Models/Org";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { ISession } from "app/src/Common/SessionService";
import { IRepository } from "app/src/Common/Repository";
import { IHeaderSearchArgs, IHeaderShowArgs } from "app/src/Header/HeaderCtrl";

export class SignedDocListCtrl {
  public spinnerPromise: ng.IPromise<any>;
  public org: IOrg;
  public signedDocResponse: any;
  public totalCount = 0;
  public currentPage = 1;
  public perPage = 20;
  public sort: any = {
    by: "signed_documents.created_at",
    order: "desc",
  };
  public queryParams: any = {
    id: null,
    estimate_id: null,
    status: null,
  };
  public searchVisible = false;
  public search: string;

  protected users: { number: IUser } = <{ number: IUser }>{};
  protected orgs: { number: IOrg } = <{ number: IOrg }>{};

  private validSortOrders: string[] = [
    "signed_documents.id",
    "signed_documents.created_at",
    "signed_documents.updated_at",
    "signed_documents.status",
    "estimates.name",
  ];

  public static $inject = [
    "$location",
    "$state",
    "$stateParams",
    "Repository",
    "Pretty",
    "$window",
    "BaseConfig",
    "OrgFetcher",
    "Session",
    "$scope",
    "$analytics",
    "$rootScope",
  ];

  constructor(
    public $location: ng.ILocationService,
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public Repository: IRepository,
    public Pretty: IPretty,
    public $window: ng.IWindowService,
    public BaseConfig: IBaseConfig,
    public OrgFetcher: IOrgFetcherService,
    public Session: ISession,
    public $scope: ng.IScope,
    public $analytics: angulartics.IAnalyticsService,
    public $rootScope: RsfRootScope,
  ) {
    $scope.$emit("header:search", <IHeaderShowArgs>{ show: true });
    $scope.$on("header:search_change", (e: ng.IAngularEvent, args: IHeaderSearchArgs) => {
      this.$analytics.eventTrack("search", { category: "Signed Doc List" });
      this.search = args.searchString;
      this.$location.search("q", this.search);
      this.spinnerPromise = this.query();
    });
    this.spinnerPromise = this.updateFromQuerystring().then(() => {
      if (_.isUndefined(this.search) || this.search.length > 0) {
        $rootScope.$emit("header:search_update", { searchString: this.search });
      }
      this.totalCount = this.signedDocResponse.meta.pagination.total_count;
      return this.OrgFetcher.loadOrg(this.Session.currentUser.org_id).then((org: IOrg) => {
        this.org = org;
      });
    });
  }

  public sortBy(by: string) {
    if (!_.include(this.validSortOrders, by)) {
      return;
    }

    if (this.sort.by !== by) {
      this.sort.by = by;
      this.sort.order = "desc";
    } else {
      this.sort.order = this.sort.order === "asc" ? "desc" : "asc";
    }

    this.$location.search("by", this.sort.by);
    this.$location.search("order", this.sort.order);

    this.query();
  }

  public sortingBy(by: string, order: string) {
    return this.sort.by === by && this.sort.order === order;
  }

  public pageChanged() {
    this.$location.search("page", this.currentPage);
    return this.query();
  }

  public updateFromQuerystring() {
    this.currentPage = parseInt(this.$stateParams["page"]) || this.currentPage;
    this.perPage = parseInt(this.$stateParams["per"]) || this.perPage;
    this.sort.by = this.$stateParams["by"] || this.sort.by;
    this.sort.order = this.$stateParams["order"] || this.sort.order;
    this.search = this.$stateParams["q"] || this.search;
    return this.query();
  }

  public clearSearch() {
    this.search = "";
    this.$location.search("q", this.search);
    return this.query();
  }

  public query(): ng.IPromise<any> {
    const params: any = {
      page: this.currentPage,
      per_page: this.perPage,
      sort_by: this.sort.by,
      sort_order: this.sort.order,
      include: ["estimate", "assignments", "job"],
    };

    if (this.search) {
      params["query"] = this.search;
    }

    this.signedDocResponse = <ISignedDocumentResponse>this.Repository.SignedDocument.query(params);

    this.spinnerPromise = this.signedDocResponse.$promise.then(() => {
      if (this.signedDocResponse.meta.pagination.total_count > 0) {
        if (this.currentPage > this.signedDocResponse.meta.pagination.total_pages) {
          this.currentPage = 1;
          this.$location.search("page", this.currentPage);
          this.query();
        }
      }
      this.totalCount = this.signedDocResponse.meta.pagination.total_count;
    });

    return this.spinnerPromise;
  }
}
