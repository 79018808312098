import { IJob, IJobResource } from "app/src/Models/Job";
import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { BaseTabCtrl } from "app/src/Jobs/tabs/BaseTabCtrl";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import * as angulartics from "angulartics";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IReport, IReportResource } from "app/src/Models/Report";
import { IPretty } from "app/src/Common/PrettyNameService";
import { ITask, Task } from "../../Models/Task";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { ISession } from "../../Common/SessionService";

export class PropertyDataTabCtrl extends BaseTabCtrl {
  public job: IJob;
  public spinnerPromise: ng.IPromise<any>;
  public showBillingStatusModal = false;
  public propertyReports: IReport[] = [];
  public activeTab: number;

  public static $inject = [
    "JobFetcher",
    "Flash",
    "Session",
    "$stateParams",
    "BaseConfig",
    "$scope",
    "$rootScope",
    "$window",
    "$analytics",
    "$uibModal",
    "$state",
    "EstimatorService",
    "Report",
    "Pretty",
    "$timeout",
    "$q",
    "$http",
    "Job",
  ];
  constructor(
    public JobFetcher: IJobFetcherService,
    public Flash: IFlash,
    public Session: ISession,
    public $stateParams: ng.ui.IStateParamsService,
    public BaseConfig: IBaseConfig,
    public $scope: ng.IScope,
    public $rootScope: RsfRootScope,
    private $window: ng.IWindowService,
    protected $analytics: angulartics.IAnalyticsService,
    private $uibModal: ng.ui.bootstrap.IModalService,
    public $state: ng.ui.IStateService,
    public EstimatorService: EstimatorService,
    public Report: IReportResource,
    public Pretty: IPretty,
    public $timeout: ng.ITimeoutService,
    public $q: ng.IQService,
    public $http: ng.IHttpService,
    public Job: IJobResource,
  ) {
    super(JobFetcher, $scope, $analytics, $window, $stateParams["id"]);

    this.job.$promise.then(() => {
      this.setupReports();
    });
  }

  public setupReports() {
    this.propertyReports = _.filter(this.job.reports, (report) => {
      return report.provider === "wvs";
    });
    this.$timeout(() => {
      this.setActiveTab();
    });
  }

  public setActiveTab() {
    this.activeTab = 0;
  }

  public eventType(incident) {
    switch (incident.incident_type) {
      case "hail":
        return incident.value + '"';
      case "wind":
        return incident.value + " mph";
      default:
        return incident.value;
    }
  }

  public order() {
    this.trackEvent("order core logic weather verification", {
      category: "Job",
    });

    const billingStatus = this.Session.billingStatus;

    if (billingStatus.status !== 1) {
      this.showBillingStatusModal = true;
    } else {
      const modal = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
        animation: true,
        ariaLabelledBy: "modal-title",
        ariaDescribedBy: "modal-body",
        templateUrl: "src/Jobs/tabs/core_logic_order_modal.html",
        controller: "CoreLogicOrderModalCtrl",
        backdrop: "static",
        controllerAs: "$ctrl",
        size: "lg",
        resolve: {
          job: this.job,
          args: { provider: "property", kind: "" },
        },
      });

      modal.result.then(() => {
        this.job = this.JobFetcher.get(this.job.id);
        this.spinnerPromise = this.job.$promise;

        return this.job.$promise.then(() => {
          this.propertyReports = [];
          this.setupReports();
        });
      });
    }
  }

  public billingStatusModalClosed = () => {
    this.$scope.$apply(() => {
      this.showBillingStatusModal = false;
    });
  };
}
