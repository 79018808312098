import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import EstimatePresentationView from "app2/src/components/PricePresentation/components/EstimatePresentationView";
import { Row, Col, Button } from "react-bootstrap";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import { EstimateRecord } from "app2/src/records/Estimate";
import { IOrgData, OrgRecord, getPricePresentationName, getSettingsAcl } from "app2/src/records/OrgRecord";
import Spinner from "app2/src/components/SpinnerComponent";
import { FinanceOptionRecord } from "app2/src/records/FinanceOption";
import { ToolInfoService } from "app/src/Common/ToolInfoService";

const mapStateToProps = (state: RootState, ownProps: PresentProps) => {
  const estimateids = [];
  if (_.isString(ownProps.estimateids)) {
    estimateids.push(parseInt(ownProps.estimateids as string));
  } else {
    _.each(ownProps.estimateids, (id: string) => {
      estimateids.push(parseInt(id));
    });
  }
  const estimates = {} as { number: EstimateRecord };
  _.map(estimateids, (id) => {
    estimates[id] = state.getIn(["estimates", "byId", id]);
  });

  const org: OrgRecord = state.getIn(["orgs", "orgsById", parseInt(ownProps.orgid)]);
  const financeList: any = state.getIn(["orgs", "financeOptions", "byOrgId", parseInt(ownProps.orgid)]);

  const financeOptionsId = {} as { number: FinanceOptionRecord };
  if (financeList) {
    financeList.get("financeOptions").map((fo) => {
      financeOptionsId[fo] = state.getIn(["orgs", "financeOptions", "byId", fo]);
    });
  }
  return {
    estimatesByIdProp: estimates,
    org: org,
    financeList: financeList,
    financeOptionsById: financeOptionsId,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: PresentProps) => {
  const orgId = parseInt(ownProps.orgid);
  return {
    loadEstimate: (estId: number) => dispatch(estimateActions.AsyncActions.getEstimate(estId, orgId)),
    loadOrg: (orgId: number) => dispatch(orgActions.AsyncActions.getOrg(orgId)),
    updateEstimate: (id: number) => dispatch(estimateActions.AsyncActions.updateOrCreateEstimate(id)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PresentProps {
  estimateids: string[];
  orgid: string;
  $state: ng.ui.IStateService;
}

export interface PresentState {
  loading: boolean;
  showFinancing: boolean;
  estimatesById: { number: EstimateRecord };
  estimate_ids: number[];
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentProps;

class PresentationContainer extends React.Component<Props, PresentState> {
  public toolInfoService: ToolInfoService;
  constructor(props: Props) {
    super(props);
    this.toolInfoService = new ToolInfoService(null);
    const ids = this.setupIds();
    this.state = {
      loading: true,
      showFinancing: false,
      estimatesById: {} as { number: EstimateRecord },
      estimate_ids: ids,
    };

    this.saveEstimates = this.saveEstimates.bind(this);
    this.close = this.close.bind(this);
  }

  public componentDidMount() {
    this.setupEstimates();
  }

  public componentDidUpdate(_prevProps: PresentProps, prevState: PresentState) {
    const { estimatesByIdProp, financeList, org } = this.props;

    if (prevState.loading === true) {
      if (
        !_.any(
          Object.keys(estimatesByIdProp),
          (key) => _.isUndefined(estimatesByIdProp[key]) || estimatesByIdProp[key].loading,
        ) &&
        !_.isUndefined(financeList) &&
        !financeList.loading &&
        !_.isUndefined(org) &&
        !org.loading
      ) {
        this.setState({
          loading: false,
          estimatesById: estimatesByIdProp,
        });
      }
    } else {
      if (
        _.any(Object.keys(estimatesByIdProp), (key) => estimatesByIdProp[key].loading) ||
        financeList.loading ||
        org.loading
      ) {
        this.setState({
          loading: true,
        });
      }
    }
  }

  public close() {
    this.props.$state.go("job_header.job_show.estimates");
  }

  public setupEstimates() {
    const { loadEstimate, loadOrg, orgid } = this.props;
    const { estimate_ids } = this.state;

    _.each(estimate_ids, (id: number) => {
      loadEstimate(id);
    });
    const orgId = parseInt(orgid);
    loadOrg(orgId);
  }

  public setupIds() {
    const { estimateids } = this.props;
    const ids: number[] = [];
    if (_.isString(estimateids)) {
      ids.push(parseInt(estimateids as string));
    } else {
      _.each(estimateids, (id: string) => {
        ids.push(parseInt(id));
      });
    }
    return ids;
  }

  public saveEstimates() {
    const { estimatesById } = this.state;
    Object.keys(estimatesById).forEach((key: string) => {
      this.props.updateEstimate(parseInt(key));
    });
  }

  public render() {
    const { estimatesById, loading, estimate_ids } = this.state;
    const { org, financeOptionsById } = this.props;

    const params: any = { width: 4 };
    if (Object.keys(estimatesById).length === 2) {
      params.width = 6;
    } else if (Object.keys(estimatesById).length === 1) {
      params.width = 12;
    }

    if (loading) {
      return <Spinner localProperty={loading} />;
    }

    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <h1 className="price-presentation-title bold-header">{getPricePresentationName(org)}</h1>
          </Col>
          <Col md={4} className="text-center">
            {getSettingsAcl(org, "tools").includes("gaf_smart_money_link") ? (
              <a
                className="btn btn-default"
                role="button"
                target="_blank"
                href={this.toolInfoService.toolConfigs["gaf_smart_money_link"].link(undefined, "")}>
                GAF SmartMoney
              </a>
            ) : null}
          </Col>
          <Col md={4}>
            <Button variant="cancel" className="pull-right pp-close-btn" type="button" onClick={this.close}>
              Close
            </Button>
            <Button variant="save" className="pull-right pp-save-btn" type="button" onClick={this.saveEstimates}>
              Save
            </Button>
          </Col>
        </Row>
        <Row className="view-height">
          {Object.keys(estimatesById).length > 0 ? (
            <React.Fragment>
              {estimate_ids.map((key, idx) => {
                return (
                  <EstimatePresentationView
                    key={idx}
                    estimateId={key}
                    financeOptionsById={financeOptionsById}
                    params={params}
                    org={org}
                  />
                );
              })}
            </React.Fragment>
          ) : null}
        </Row>
      </React.Fragment>
    );
  }
}

export default connector(PresentationContainer);
