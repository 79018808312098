import * as React from "react";
import { getFullName, UserRecord } from "app2/src/records/UserRecord";
import { RootState } from "app2/src/reducers";
import { currentUser, user } from "app2/src/selectors/user.selectors";
import { List } from "immutable";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select, { StylesConfig } from "react-select";
import { getPaginationByModel, isCurrentPageLoading } from "app2/src/selectors/pagination.selectors";
import * as userActions from "app2/src/reducers/user.actions";
import { queryParamsFromJSON } from "app2/src/records/Page";

export interface JobsToAssignDropdownProps {
  selectedUserId: number;
  onChange: (newValue: number) => void;
}

export const JobsToAssignDropdown: React.FC<JobsToAssignDropdownProps> = ({ selectedUserId, onChange }) => {
  const modelName = "jobsToAssignUsers";
  // Hooks
  const dispatch = useDispatch();

  // Local State
  const [lastInputValue, setLastInputValue] = React.useState("");

  // Selectors
  const orgId = useSelector((state: RootState) => currentUser(state)?.get("org_id"));
  const selectedUser = useSelector((state: RootState) => user(state, { userId: selectedUserId }));
  const users: List<UserRecord> = useSelector((state: RootState) =>
    getPaginationByModel(state, { path: ["users", "usersById"], modelName })
      .filter((u) => u.id !== selectedUserId)
      .insert(0, selectedUser),
  );
  const loading = useSelector((state: RootState) => isCurrentPageLoading(state, { modelName }));

  // Select Values
  const selectOptions = React.useMemo(() => {
    return users.toJS().map((u) => ({ label: getFullName(u), value: u.id }));
  }, [users]);
  const selectValue = React.useMemo(() => {
    return selectOptions.find((o) => o.value === selectedUserId);
  }, [users, selectedUserId]);

  const customStyles: Partial<StylesConfig<any, false>> = {
    container: (styles) => ({
      ...styles,
      minWidth: "230px",
      zIndex: 2,
    }),
    singleValue: (styles) => ({
      ...styles,
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: "hsl(0, 0%, 45%)",
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
      },
    }),
  };

  // Methods
  const listUsers = (query = null) => {
    dispatch(
      userActions.AsyncActions.listUsers(
        queryParamsFromJSON({ parameters: { org_id: orgId.toString() }, query }),
        modelName,
      ),
    );
  };

  const handleChange = (selectedOption) => {
    onChange(selectedOption.value);
  };

  const handleInputChange = _.debounce((query: string) => {
    if (lastInputValue !== query) listUsers(query);
    setLastInputValue(query);
  }, 500);

  const onMenuOpen = () => {
    listUsers();
  };

  return (
    <Form.Group className="mr-3">
      <Form.Label>Jobs to Assign</Form.Label>
      <Select
        onMenuOpen={onMenuOpen}
        styles={customStyles}
        options={selectOptions}
        value={selectValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        isLoading={loading}
        filterOption={() => true}
      />
    </Form.Group>
  );
};
