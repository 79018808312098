import { DocumentRecord } from "app2/src/records/Document";
import { pathname } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import { List } from "immutable";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import SignedDocumentsTable from "app2/src/components/SignedDocument/SignedDocumentsTable";

export interface SignaturesDocumentsTableProps {
  documents: List<DocumentRecord>;
}

export const SignaturesDocumentsTable: React.FC<SignaturesDocumentsTableProps> = ({ documents }) => {
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch<{
    documentKind: string;
  }>();

  // Selectors
  const path = useSelector(pathname);

  // Methods
  const emailOrSign = (document: DocumentRecord) => {
    dispatch(push(`${path}/signing/${document.signed_document.id}`));
  };

  const showPDF = (document: DocumentRecord) => {
    const prevPath = path.split("/" + match.params.documentKind)[0];
    dispatch(push(`${prevPath}/view_pdf/${document.id}`));
  };

  return <SignedDocumentsTable documents={documents} emailOrSign={emailOrSign} showPDF={showPDF} />;
};
