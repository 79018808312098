import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import * as angulartics from "angulartics";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { IEstimate, IEstimateResource } from "../../Models/Estimate";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { BaseEstimateTabCtrl } from "app/src/Jobs/tabs/BaseEstimateTabCtrl";

export class EstimatesTabCtrl extends BaseEstimateTabCtrl {
  public estimateids: number[] = [];
  public showPricePresentation = false;

  public static $inject = [
    "JobFetcher",
    "$stateParams",
    "$state",
    "$scope",
    "$rootScope",
    "$analytics",
    "EstimatorService",
    "$window",
    "$uibModal",
    "Flash",
    "Estimate",
    "$http",
    "$timeout",
    "BaseConfig",
  ];

  constructor(
    JobFetcher: IJobFetcherService,
    $stateParams: ng.ui.IStateParamsService,
    $state: ng.ui.IStateService,
    $scope: ng.IScope,
    $rootScope: RsfRootScope,
    $analytics: angulartics.IAnalyticsService,
    EstimatorService: EstimatorService,
    $window: ng.IWindowService,
    $uibModal: ng.ui.bootstrap.IModalService,
    Flash: IFlash,
    Estimate: IEstimateResource,
    $http: ng.IHttpService,
    $timeout: ng.ITimeoutService,
    BaseConfig: IBaseConfig,
  ) {
    super(
      JobFetcher,
      EstimatorService,
      $scope,
      $stateParams,
      $state,
      $analytics,
      $window,
      "estimates",
      $uibModal,
      Flash,
      Estimate,
      $http,
      $timeout,
      $stateParams["id"],
    );

    this.job.$promise.then(() => {
      this.showPricePresentation = this.job.org.showPricePresentation();
      if (this.job.estimates.length === 0) {
        this.addEstimate();
      }
    });
  }

  public disablePresent() {
    let disable = true;
    _.each(this.job.estimates, (est: IEstimate) => {
      if (est.compare === true) {
        disable = false;
      }
    });
    return disable;
  }

  public toggleCompare(estimate) {
    if (this.estimateids.length === 3 && estimate.compare) {
      estimate.compare = false;
      this.Flash.addMessage(FlashLevels.warning, "Estimate Comparison is limited to 3.");
      return;
    }
    if (estimate.compare) {
      this.estimateids.push(estimate.id);
    } else {
      this.estimateids = _.filter(this.estimateids, (id) => {
        return id !== estimate.id;
      });
    }
  }

  public presentation() {
    this.$state.go("job_header.job_show.price", { estimateids: this.estimateids, orgid: this.job.org_id });
  }

  public pdf(estimate, doc_type) {
    if (!doc_type) {
      doc_type = "estimate";
    }
    estimate.job = this.job;
    this.spinnerPromise = this.EstimatorService.savePdf(estimate, doc_type);
    this.trackEvent(doc_type + " pdf downloaded", {
      category: "Estimate",
      pdfType: doc_type,
      estimate: estimate.id,
    });
  }

  public csv(estimate, csv_type) {
    if (!csv_type) {
      csv_type = "product";
    }
    estimate.job = this.job;
    this.spinnerPromise = this.EstimatorService.saveCsv(estimate, csv_type);
    this.trackEvent(csv_type + " csv downloaded", {
      category: "Estimate",
      estimate: estimate.id,
    });
  }

  public addEstimate() {
    this.trackEvent("add", {
      category: "Estimate",
    });
    this.addingEstimate = true;
    this.selectEstimate({ id: "new" } as unknown as IEstimate);
  }
}
