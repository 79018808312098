import * as React from "react";

export interface CardProps {
  className: string;
  title: string;
  link: string;
  imageUrl: string;
  paid?: boolean;
}

interface CardState {}

export default class Card extends React.Component<CardProps, CardState> {
  constructor(props: CardProps) {
    super(props);
  }

  public render() {
    const { link, imageUrl, title, paid, className } = this.props;

    return (
      <div className={className}>
        <div className="tool-container">
          <div className="rsf-card">
            <a target="_blank" href={link}>
              <div className="text-center">{imageUrl ? <img src={imageUrl} /> : null}</div>
              <h3>
                {title}&nbsp;&nbsp;
                {paid ? <span>$</span> : null}
              </h3>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
