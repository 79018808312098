import * as React from "react";
import { useMemo } from "react";
import { Actions } from "app2/src/reducers/webhook.actions";
import { Row, Col, Button } from "react-bootstrap";
import { FormControl, Check } from "@tberrysoln/rsf-form";
import { WebhookRecord } from "app2/src/records/Webhook";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { Events } from "./Events";
import { History } from "./History";
import { validateHttpsBaseUrl } from "app2/src/helpers/FinalFormValidator";

export interface IWebhookEditorProps {
  webhookId: string;
  handleCancel: () => any;
  showError?: boolean;
}

export const WebhookEditor: React.FC<IWebhookEditorProps> = ({ webhookId, handleCancel, showError }) => {
  const rootPath = React.useMemo(() => ["webhooks", "byId", webhookId], [webhookId]);

  // hooks
  const dispatch = useDispatch();

  const webhook = useSelector<RootState, WebhookRecord>((state) => state.getIn(["webhooks", "byId", webhookId]));

  const setAuthType = (event) => {
    dispatch(Actions.updateForm({ name: "auth_type", value: event.target.value, rootPath: rootPath }));
  };

  const AuthConfig = useMemo(() => {
    if (webhook.auth_type === "basic_auth") {
      return (
        <Row>
          <Col md={3}>
            <FormControl label="Username" name="auth_config.username" />
          </Col>
          <Col md={3}>
            <FormControl label="Password" name="auth_config.password" formControlProps={{ type: "password" }} />
          </Col>
        </Row>
      );

      // } else if (webhook.auth_type === "oauth2") {
      //   return (
      //     <Row>
      //       <Col md={3}>
      //         <FormControl label=" Client Id" name="auth_config.client_id" />
      //       </Col>
      //       <Col md={3}>
      //         <FormControl label="Client Secret Key" name="auth_config.client_secret_key" />
      //       </Col>
      //     </Row>
      //   );
    } else return "";
  }, [webhook.auth_type]);

  if (!webhookId) return null;

  return (
    <>
      <Row>
        <Col md={6}>
          <h1 className="admin-title">{webhookId === "-1" ? "Create" : "Editing"} a Webhook</h1>
        </Col>
        <Col md={6}>
          <div className="pull-right">
            <input className="btn btn-default" type="submit" name="save" value="Save" />
            <Button variant="cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormControl label="Webhook Name" name="name" />
        </Col>
        <Col md={6}>
          <FormControl label="Webhook Url" validate={validateHttpsBaseUrl} name="endpoint" />
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={6}>
          <div className="form-section-heading">
            <h3>Webhook Authentication Methods</h3>
          </div>
          <p className="small">Choose the right authentication method for your webhooks</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="d-flex">
            <Check
              name="auth_type"
              formCheckProps={{
                onChange: setAuthType,
                value: "none",
                checked: webhook.auth_type === "none",
                inline: true,
              }}
              type="radio"
              id="auth_type-none"
              label="No Auth"></Check>
            <Check
              name="auth_type"
              formCheckProps={{
                onChange: setAuthType,
                value: "basic_auth",
                checked: webhook.auth_type === "basic_auth",
                inline: true,
              }}
              type="radio"
              id="auth_type_basic"
              label="Basic Auth"></Check>
            {/* <Check
              name="auth_type"
              formCheckProps={{
                onChange: setAuthType,
                value: "oauth2",
                checked: webhook.auth_type === "oauth2",
                inline: true,
              }}
              type="radio"
              id="auth_type_oauth2"
              label="M2M OAuth"></Check> */}
          </div>
        </Col>
      </Row>
      {AuthConfig}
      <br />
      {showError && <p className="red-text">At least 1 event must be selected</p>}
      <Events />

      {webhookId !== "-1" && <History webhookId={webhookId} />}
    </>
  );
};
