import { ISideBarMenu } from "../JobMenu/JobMenuOrderService";
import { IOrgBaseConfig } from "./Org";

export interface IOrgPreference {
  acl: IOrgPrefAcl;
  config: IOrgPrefConfig;
}

export interface IOrgPrefAcl {
  tools: string[];
  design_tools: string[];
  document: string[];
  profile: string[];
  visualizations: string[];
  measurements: string[];
  reports: string[];
}

export interface IOrgPrefConfig {
  notes: IOrgPrefNotes;
  billing: IOrgPrefBilling;
  cover_page: IOrgPrefCoverPage;
  cronofy: IOrgPrefCronofy;
  document_order: IOrgPrefDocumentOrder;
  document_signed_notification: IOrgPrefDocumentSigned;
  estimator: IOrgPrefEstimator;
  integrations: IOrgPrefIntegrations;
  job_menu: ISideBarMenu;
  layout: IOrgPrefLayout;
  marketsharp: IOrgPrefMarketSharpPreference;
  measurement: IOrgPrefMeasurementPreference;
  photos: IOrgPrefPhotos;
  report_emails: IOrgPrefReportEmails;
  screen_share: IOrgPrefScreenShare;
  sales_tax: IOrgPrefSalesTax;
  send_email: IOrgPrefSendEmail;
  titles: IOrgPrefTitles;
  price_presentation: IOrgPrefPricePresentation;
  markup: IOrgPrefMarkup;
  link_expiration: IOrgPrefLinkExpiration;
  signed_documents: IOrgPrefSignedDocuments;
}

export interface IOrgPrefLinkExpiration {
  proposal: number;
  agreement: number;
  signed_agreement: number;
}

export interface IOrgPrefSignedDocuments {
  expiration_in_days: number;
}

export interface IOrgPrefMarkup extends IOrgBaseConfig {
  enabled: boolean;
  product_min: number;
  product_default: number;
  product_max: number;
  labor_min: number;
  labor_default: number;
  labor_max: number;
}

export interface IOrgPrefPricePresentation extends IOrgBaseConfig {
  enable: boolean;
  finance_type: string;
  finance_range: string;
}

export interface IOrgPrefBilling extends IOrgBaseConfig {
  notification_emails: string;
}

export interface IOrgPrefCoverPage extends IOrgBaseConfig {
  show_customer_phone: string;
  show_customer_email: string;
}

export interface IOrgPrefCronofy extends IOrgBaseConfig {
  invite_attendees: string;
}

export interface IOrgPrefDocumentSigned extends IOrgBaseConfig {
  emails: string;
  signable_document_emails: string;
}

export interface IOrgPrefEstimator extends IOrgBaseConfig {
  add_products: string;
  show_estimate_list_pricing: string;
  show_detailed_measurements: string;
  show_detailed_pricing: string;
  show_detailed_unit_price: string;
  show_discount_detail: string;
  show_option_total: string;
  show_included_total: string;
  show_estimated_dates: string;
  show_product_selector: string;
  show_included_text: string;
}

export interface IOrgPrefIntegrations extends IOrgBaseConfig {
  houzz: IOrgBaseIntegration;
  wells_fargo: IOrgBaseIntegration;
  angies_list: IOrgBaseIntegration;
  home_advisor: IOrgBaseIntegration;
}

export interface IOrgBaseIntegration {
  url: string;
}

export interface IOrgPrefLayout extends IOrgBaseConfig {
  estimate: string;
  image_pdf: string;
  cover_page: string;
  show_opt_group_on_contract: string;
  page_break_options: string;
}

export interface IOrgPrefPhotos extends IOrgBaseConfig {
  show_image_uploader: string;
}

export interface IOrgPrefReportEmails extends IOrgBaseConfig {
  send_report_ordered: string;
  show_report_files: string;
}

export interface IOrgPrefScreenShare extends IOrgBaseConfig {
  survey_url: string;
  screenleap_share: string;
}

export interface IOrgPrefSalesTax extends IOrgBaseConfig {
  default_rate: number;
  default_taxable_amount: string;
  use_sales_tax: string;
}

export interface IOrgPrefTitles extends IOrgBaseConfig {
  contract: string;
  contract_short: string;
  estimate: string;
  estimate_short: string;
  salesrep: string;
  opening_name: string;
  estimator: string;
  inspection: string;
  inspection_short: string;
}

export interface IOrgPrefMarketSharpPreference {
  contract: string[];
  estimate: string[];
  signable: string[];
}

export interface IOrgPrefNotes {
  enable_editing: boolean;
}

export type MeasurementSystem = "imperial" | "metric";

export interface IOrgPrefMeasurementPreference extends IOrgBaseConfig {
  job_tab: string[];
  line_item_editor: string[];
  system: MeasurementSystem;
}

export interface IOrgPrefSendEmail {
  notify: string[];
}

export interface IPatternSelection {
  id: number;
}

export interface IPatternPreferences {
  cover_page: IPatternSelection;
  job_photos: IPatternSelection;
  product_images: IPatternSelection;
  estimate: IPatternSelection;
  presentation: IPatternSelection;
  slides: IPatternSelection;
  inspection: IPatternSelection;
  inspecetion_pdf: IPatternSelection;
  commission: IPatternSelection;
}

export type IOrgPrefDocumentOrder = DocumentOrder;

export class DocumentOrder {
  public estimate: any;
  public contract: any;
  public inspection: any;
  public patterns: IPatternPreferences;

  constructor(data: any) {
    this.estimate = data.estimate;
    this.contract = data.contract;
    this.inspection = data.inspection || [];
    this.patterns = data.patterns;
  }

  public static fromJSON = (data: any) => {
    const documentOrder = new DocumentOrder(data);
    return documentOrder;
  };
}
