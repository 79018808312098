import { BaseTabCtrl } from "app/src/Jobs/tabs/BaseTabCtrl";
import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEstimate, IEstimateResource } from "app/src/Models/Estimate";
import * as angulartics from "angulartics";
import { IFlash } from "app/src/Common/FlashService";
import { dispatch, useSelector } from "app2/src/storeRegistry";
import * as jobActions from "app2/src/reducers/job.actions";
import { selectedEstimateId } from "app2/src/selectors/job.selectors";

export class BaseEstimateTabCtrl extends BaseTabCtrl {
  public selectedEstimateId: number;
  public addingEstimate = false;
  public modalOpen = false;

  constructor(
    protected JobFetcher: IJobFetcherService,
    protected EstimatorService: EstimatorService,
    protected $scope: ng.IScope,
    protected $stateParams: ng.ui.IStateParamsService,
    protected $state: ng.ui.IStateService,
    protected $analytics: angulartics.IAnalyticsService,
    protected $window: ng.IWindowService,
    protected resource: string,
    protected $uibModal: ng.ui.bootstrap.IModalService,
    protected Flash: IFlash,
    protected Estimate: IEstimateResource,
    protected $http: ng.IHttpService,
    protected $timeout: ng.ITimeoutService,
    protected jobId?: number,
  ) {
    super(JobFetcher, $scope, $analytics, $window, jobId);

    const estimateId = $stateParams["estimate_id"];
    if (estimateId && estimateId !== "new") {
      dispatch(jobActions.Actions.setSelectedEstimateId(parseInt(estimateId)));
    }
    this.selectedEstimateId = useSelector((state) => selectedEstimateId(state));
    this.job.$promise.then(() => {
      this.setupParams();
    });

    $scope.$on("$destroy", () => {
      if (
        !this.addingEstimate &&
        this.selectedEstimateId &&
        (!_.isNumber(this.selectedEstimateId) || this.selectedEstimateId < 0)
      ) {
        this.clearEstimate();
      }
    });
  }

  public selectEstimate(estimate: IEstimate): void {
    if (!estimate) {
      this.selectedEstimateId = null;
      dispatch(jobActions.Actions.setSelectedEstimateId(null));
      return;
    }

    this.selectedEstimateId = estimate.id;
    dispatch(jobActions.Actions.setSelectedEstimateId(estimate.id));
    this.trackEvent("selected", {
      category: "Estimate",
      estimate: estimate.id,
    });
    this.setupParams();
  }

  public setupParams() {
    if (!this.selectedEstimateId || this.$stateParams["estimate_id"]) {
      return;
    }

    this.$state.go("job_header.job_show." + this.resource.slice(0, -1), {
      id: this.job.id,
      estimate_id: this.selectedEstimateId,
    });
  }

  public clearEstimate() {
    this.selectedEstimateId = null;
    dispatch(jobActions.Actions.setSelectedEstimateId(null));
    this.trackEvent("cleared", {
      category: "Estimate",
    });
    this.$state.go(`job_header.job_show.${this.resource}`, {
      id: this.job.id,
      estimate_id: null,
    });
  }

  public sendPdf(docType) {
    this.trackEvent(`email ${docType}`, {
      category: _.toTitleCase(docType),
      estimate: this.selectedEstimateId,
    });

    this.modalOpen = true;
    const modalInstance: ng.ui.bootstrap.IModalServiceInstance = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "src/Jobs/tabs/email_modal.html",
      controller: "EmailModal",
      backdrop: "static",
      controllerAs: "$ctrl",
      size: "md",
      resolve: {
        job_email: { email: this.job.email },
        options: {},
      },
    });

    modalInstance.result
      .then((returnObject: any) => {
        this.spinnerPromise = this.EstimatorService.emailPdf(this.selectedEstimateId, docType, returnObject);
      })
      .catch(() => {
        this.trackEvent(`email ${docType} canceled`, {
          category: _.toTitleCase(docType),
          estimate: this.selectedEstimateId,
        });
      })
      .finally(() => {
        this.modalOpen = false;
      });
  }
}
