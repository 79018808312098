import * as React from "react";
import { useContext } from "react";
import { Form } from "react-bootstrap";
import { Context } from "app2/src/components/SignatureRequest/Context";

export const RequestName: React.FunctionComponent = () => {
  // hooks
  const { requestName, dispatch: contextDispatch } = useContext(Context);

  // methods
  const onChange = (event) => {
    contextDispatch("setRequestName", { requestName: event.target.value });
  };

  return (
    <Form.Group id="selected_documents_name">
      <Form.Label htmlFor="signatureRequestName">Name</Form.Label>
      <Form.Control
        autoFocus
        name="signatureRequestName"
        id="signatureRequestName"
        onChange={onChange}
        value={requestName}
      />
    </Form.Group>
  );
};
