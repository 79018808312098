import { AccessRecord } from "app2/src/records/UserRecord";
import * as React from "react";
import { Card, Col, ListGroup, Row, Image } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { getUrl } from "app2/src/records/Image";
import { toMultiLiner } from "app2/src/records/Address";
import { useSelector } from "react-redux";
import { currentAccessUid } from "app2/src/selectors/auth.selectors";
import { org } from "app2/src/selectors/org.selectors";

export interface OrgAccessListItemProps {
  access: AccessRecord;
  setCurrentAccess: (access: AccessRecord) => void;
}

export const OrgAccessListItem = (props: OrgAccessListItemProps) => {
  const { access, setCurrentAccess } = props;
  const selectedAccessUid = useSelector(currentAccessUid);
  const accessOrg = useSelector((state) => org(state, { orgId: access.org_id }));
  return (
    <ListGroup.Item
      action
      onClick={() => setCurrentAccess(access)}
      className={`${access.uid === selectedAccessUid ? "current-org" : ""}`}
      data-testid={`org-${access.id}`}>
      {access.uid === selectedAccessUid && <FontAwesome name="check" className="current-org-icon" size="2x" />}
      <Card className="org-access-item">
        <Card.Body>
          <Row className="h-100">
            <Col xs={4} md={6} className="d-flex align-items-center org-image ml-md-3">
              {accessOrg.images.size > 0 && <Image src={getUrl(accessOrg.images.first(), "medium")} fluid />}
            </Col>
            <Col
              xs={{ span: 7, offset: 1 }}
              md={{ span: 7, offset: 1 }}
              className="d-flex flex-column justify-content-center">
              <Card.Title>
                <b>{accessOrg.name}</b>
              </Card.Title>
              <div className="org-address mw-100 overflow-hidden">{toMultiLiner(accessOrg.address)}</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </ListGroup.Item>
  );
};
