import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { ITask, Task } from "app/src/Models/Task";
import { IPriceList, IPriceListResource, IPriceListResponse } from "app/src/Models/PriceList";
import {
  IActivatedPriceList,
  IActivatedPriceListResource,
  IActivatedPriceListResponse,
} from "app/src/Models/ActivatedPriceList";
import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import * as angulartics from "angulartics";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { IOrg } from "app/src/Models/Org";
import { downloadFile } from "app2/src/helpers/File";
import { DateFormat } from "app2/src/helpers/Format";

export class OrgPriceListsTabCtrl extends BaseOrgTabCtrl {
  public folder = "";
  public kind = "default";
  public priceListResponse: IPriceListResponse;
  public task: ITask;
  public activateTask: { number: ITask } = {} as { number: ITask };
  public generateTask: { number: ITask } = {} as { number: ITask };
  public generateTaskApl: { number: ITask } = {} as { number: ITask };
  public activatedPriceLists: { number: IActivatedPriceList } = {} as { number: IActivatedPriceList };
  public showUploadButton = true;
  public mergePriceList: IPriceList;
  public mergeToPriceList: IPriceList;
  public mergeToPriceLists: IPriceList[];
  public fileTypes: string[] = ["default", "leap", "table", "list"];
  public activeTab = "default";

  public static $inject = [
    "OrgFetcher",
    "PriceList",
    "ActivatedPriceList",
    "Flash",
    "BaseConfig",
    "Upload",
    "$timeout",
    "$http",
    "$stateParams",
    "$window",
    "ConfirmDialog",
    "$q",
    "$uibModal",
    "$scope",
    "$analytics",
  ];
  constructor(
    public OrgFetcher: IOrgFetcherService,
    public PriceList: IPriceListResource,
    public ActivatedPriceList: IActivatedPriceListResource,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
    public Upload: ng.angularFileUpload.IUploadService,
    public $timeout: ng.ITimeoutService,
    public $http: ng.IHttpService,
    public $stateParams: ng.ui.IStateParamsService,
    public $window: ng.IWindowService,
    public ConfirmDialog: IConfirmDialog,
    public $q: ng.IQService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $scope: ng.IScope,
    protected $analytics: angulartics.IAnalyticsService,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.spinnerPromise = this.query($stateParams["id"], this.activeTab);
  }

  public downloadCsv(priceList: IPriceList) {
    const csvGeneratedDate = `${DateFormat(priceList.csv_generated_on, "short-hyphenated")}`;
    const fileName = `price_list_export_${priceList.id}_${csvGeneratedDate}.csv`;
    if (!priceList.isCsvCurrent()) {
      this.ConfirmDialog.confirm("This CSV is not current. Are you sure you want to download?", {}).then(() => {
        this.trackEvent("download_csv_not_current", {
          category: "PriceLists",
          priceListId: priceList.id,
        });
        downloadFile(priceList.csv_url, fileName);
      });
    } else {
      this.trackEvent("download_csv_current", {
        category: "PriceLists",
        priceListId: priceList.id,
      });
      downloadFile(priceList.csv_url, fileName);
    }
  }

  public downloadACsv(activatedPriceList) {
    this.trackEvent("download_activated_csv", {
      category: "PriceLists",
      priceListId: activatedPriceList.id,
    });
    downloadFile(activatedPriceList.csv_url, activatedPriceList.name);
  }

  public canActivate(priceList) {
    if (!priceList.activated && priceList.activated_on === null) {
      return true;
    }
    return false;
  }

  public isActivating(id: number) {
    if (this.activateTask[id]) {
      return this.activateTask[id].status === "processing" || this.activateTask[id].status === undefined;
    } else {
      return false;
    }
  }

  public canGenerate(priceList) {
    if (this.generateTask[priceList.id]) {
      return (
        this.generateTask[priceList.id].status !== "processing" && this.generateTask[priceList.id].status !== undefined
      );
    } else {
      return true;
    }
  }

  public canGenerateApl(activatedPriceList) {
    if (this.generateTaskApl[activatedPriceList.id]) {
      return (
        this.generateTaskApl[activatedPriceList.id].status !== "processing" &&
        this.generateTaskApl[activatedPriceList.id].status !== undefined
      );
    } else {
      return true;
    }
  }

  public canMerge(priceList) {
    const apl = this.activatedPriceLists[priceList.id];
    return apl && apl.activated_price_lists && apl.activated_price_lists.length === 0;
  }

  public mergeModal(priceList) {
    this.mergePriceList = priceList;
    this.mergeToPriceLists = _.filter(this.priceListResponse.price_lists, (pl: IPriceList) => {
      return pl.id !== this.mergePriceList.id;
    });
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        size: "md",
        templateUrl: "src/Orgs/tabs/mergeModal.html",
        scope: this.$scope,
      })
      .result.then(() => {
        this.spinnerPromise = this.mergePriceList
          .$merge({ to_price_list_id: this.mergeToPriceList.id })
          .then(() => {
            return this.query(this.org.id, this.activeTab).then(() => {
              this.Flash.addMessage(FlashLevels.success, "Successfully Merged!");
            });
          })
          .catch((error) => {
            this.Flash.addMessage(FlashLevels.warning, error.data.errors[0]);
          });
      });
  }

  public activatePriceList(priceList) {
    if (!priceList.activated && priceList.activated_on === null) {
      priceList.$activate().then((resp) => {
        this.activateTask[priceList.id] = Task.watch(this.$timeout, this.$http, resp.location, (task: ITask) => {
          if (task.status === "error") {
            this.Flash.addMessage(FlashLevels.danger, "There was an error activating the Price List");
          }

          if (task.status === "finished") {
            this.spinnerPromise = this.OrgFetcher.reloadOrg(this.org.id).then((org: IOrg) => {
              this.org = org;
              this.Flash.addMessage(FlashLevels.success, "Price List successfully activated");
              return this.query(this.org.id, this.activeTab);
            });
          }
        });
      });
    }
  }

  public isCurrent(priceList, org) {
    if (this.activeTab === "default" && org.activated_price_list !== undefined) {
      return priceList.id === org.activated_price_list.id;
    } else if (this.activeTab === "inspection" && org.activated_inspection_list !== undefined) {
      return priceList.id === org.activated_inspection_list.id;
    } else {
      return false;
    }
  }

  public uploadUnitsCheck(file, invalidFiles, priceListKind) {
    // nothing to check
    if (!this.org.activated_price_list.price_list_id || priceListKind === "inspection") {
      this.uploadUnits(file, invalidFiles, priceListKind);
      return;
    }

    const priceList = _.findWhere(this.priceListResponse.price_lists, {
      id: this.org.activated_price_list.price_list_id,
    });

    // if we're current, we're good
    if (priceList.isCsvCurrent()) {
      this.uploadUnits(file, invalidFiles, priceListKind);
      return;
    }

    // we're not current, so let them decide
    this.ConfirmDialog.confirm(
      "The latest CSV created date for the current price list is after the updated date for the price list. Are you sure you wish to upload?",
      {},
    ).then(() => {
      this.uploadUnits(file, invalidFiles, priceListKind);
    });
  }

  public uploadUnits(file, invalidFiles, priceListKind) {
    if (!file) {
      if (invalidFiles && invalidFiles.length > 0) {
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " + invalidFiles[0].name + ".  Only .csv files are allowed.",
        );
      }
      return;
    }
    const data: any = { csv: file, folder: this.folder, org_id: this.org.id };
    if (priceListKind === "inspection") {
      data.price_list_kind = priceListKind;
    } else {
      data.kind = this.kind;
    }
    this.showUploadButton = false;
    this.spinnerPromise = this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
      url: this.BaseConfig.BASE_URL + "/api/v1/orgs/" + this.org.id + "/price_lists/import",
      data,
    }).then(
      (resp: any) => {
        if (resp.data) {
          this.task = Task.watch(this.$timeout, this.$http, resp.data.location, (task: ITask) => {
            if (task.status === "error") {
              this.Flash.addMessage(FlashLevels.danger, "There was an error running the task.");
              this.showUploadButton = true;
            }

            if (task.status === "finished") {
              this.spinnerPromise = this.query(this.org.id, this.activeTab);
              this.Flash.addMessage(FlashLevels.success, "Task successfully finished");
              this.showUploadButton = true;
            }
          });
        }
        this.Flash.addMessage(FlashLevels.success, "Price List successfully uploaded.");
        this.showUploadButton = true;
      },
      () => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems processing your price list file on our servers.  Please try again.  If the problem persists contact support.",
        );
        this.showUploadButton = true;
      },
    );
  }

  public query(org_id, kind): ng.IPromise<any> {
    this.priceListResponse = <IPriceListResponse>this.PriceList.query({
      org_id: org_id,
      sort_by: "updated_at",
      sort_order: "desc",
      kind,
    });
    return this.priceListResponse.$promise.then(() => {
      const promises = [];
      _.each(this.priceListResponse.price_lists, (pl) => {
        this.activatedPriceLists[pl.id] = <IActivatedPriceListResponse>this.ActivatedPriceList.query({
          org_id: org_id,
          price_list_id: pl.id,
          sort_by: "updated_at",
          sort_order: "desc",
        });
        promises.push(this.activatedPriceLists[pl.id].$promise);
      });
      return this.$q.all(promises);
    });
  }

  public upload(file, invalidFiles) {
    if (!file) {
      if (invalidFiles && invalidFiles.length > 0) {
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " +
            invalidFiles[0].name +
            ".  Only document file types are allowed: (.pdf, .xls, .xlsx, .csv, .xml, .json).",
        );
      }
      return;
    }
    this.showUploadButton = false;
    this.spinnerPromise = this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
      url: this.BaseConfig.BASE_URL + "/api/v1/orgs/" + this.org.id + "/price_lists/check",
      data: { csv: file, folder: this.folder, kind: this.kind },
    }).then(
      (resp: any) => {
        if (resp.data) {
          this.task = Task.watch(this.$timeout, this.$http, resp.data.location, (task: ITask) => {
            if (this.task.status === "error") {
              this.Flash.addMessage(FlashLevels.danger, "There was an error running the task.");
              this.showUploadButton = true;
            }

            if (this.task.status === "finished") {
              this.Flash.addMessage(FlashLevels.success, "Task successfully finished");
              this.showUploadButton = true;
            }
          });
        }
        this.Flash.addMessage(FlashLevels.success, "Price List successfully uploaded.");
      },
      () => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems processing your file on our servers. Please try again. If the problem persists contact support.",
        );
        this.showUploadButton = true;
      },
    );
  }

  public generateCsv(priceList: IPriceList) {
    priceList.$export({ org_id: priceList.org_id, id: priceList.id }).then(
      (resp: IPriceList) => {
        this.generateTask[priceList.id] = Task.watch(this.$timeout, this.$http, resp.location, (task: ITask) => {
          if (task.status === "error") {
            this.Flash.addMessage(FlashLevels.danger, "There was an error running the task.");
          }

          if (task.status === "finished") {
            this.trackEvent("generate_csv", {
              category: "PriceLists",
              priceListId: priceList.id,
            });
            this.Flash.addMessage(FlashLevels.success, "Task successfully finished");
            priceList.$get({ org_id: priceList.org_id, id: priceList.id });
          }
        });
      },
      () => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems processing your price list file on our servers.  Please try again.  If the problem persists contact support.",
        );
      },
    );
  }

  public generateCsvApl(activatedPriceList: IActivatedPriceList) {
    this.ConfirmDialog.confirm("Are you sure you wish to generate this price list csv?", {}).then(() => {
      activatedPriceList.$export({ org_id: activatedPriceList.org_id, id: activatedPriceList.id }).then(
        (resp: IActivatedPriceList) => {
          this.generateTaskApl[activatedPriceList.id] = Task.watch(
            this.$timeout,
            this.$http,
            resp.location,
            (task: ITask) => {
              if (task.status === "error") {
                this.Flash.addMessage(FlashLevels.danger, "There was an error running the task.");
              }

              if (task.status === "finished") {
                this.trackEvent("generate_csv_apl", {
                  category: "PriceLists",
                  activatedPriceListId: activatedPriceList.id,
                });
                this.Flash.addMessage(FlashLevels.success, "Task successfully finished");
                activatedPriceList.$get({ org_id: activatedPriceList.org_id, id: activatedPriceList.id });
              }
            },
          );
        },
        () => {
          this.Flash.addMessage(
            FlashLevels.danger,
            "There were problems processing your price list file on our servers.  Please try again.  If the problem persists contact support.",
          );
        },
      );
    });
  }

  public selectTab(tab: string) {
    this.spinnerPromise = this.query(this.$stateParams["id"], tab);
  }

  public changeKind(priceList: IPriceList) {
    let kind = "default";
    if (this.activeTab === "default") {
      kind = "inspection";
    }
    priceList.kind = kind;
    this.spinnerPromise = priceList.$save().then(() => {
      return this.query(this.$stateParams["id"], this.activeTab);
    });
  }
}
