import { IBaseConfig } from "../IBaseConfig";
import { FlashLevels, IFlash } from "../FlashService";
import { ISession } from "../SessionService";
import { IAdInfo, ILearnMore } from "../AdService";
import { emailRegex } from "app2/src/helpers/InputValidator";

export class LearnMoreCtrl {
  public testimonial: any = "";
  public emailPattern = emailRegex;
  public static $inject = ["$uibModalInstance", "BaseConfig", "Flash", "Session", "AdService", "$http"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public BaseConfig: IBaseConfig,
    public Flash: IFlash,
    public Session: ISession,
    public AdService: IAdInfo,
    private $http: ng.IHttpService,
  ) {
    this.AdService.randomTestimonial().then((resolve) => {
      this.testimonial = resolve;
    });
  }

  public cancel() {
    this.$uibModalInstance.dismiss();
  }

  public submitForm(learnMore: any) {
    this.$http
      .post(this.BaseConfig.BASE_URL + "/contact/learn_more", {
        first_name: learnMore.first_name,
        last_name: learnMore.last_name,
        company: learnMore.company,
        email: learnMore.email,
        phone: learnMore.phone,
        adUrl: learnMore.adUrl,
      })
      .then((resp: any) => {
        if (resp.status === 200) {
          this.Flash.addMessage(FlashLevels.success, "Learn More Form Submitted Successfully!");
          this.$uibModalInstance.dismiss();
        } else {
          this.Flash.addMessage(FlashLevels.danger, "Error encountered submitting form");
        }
      });
  }
}
