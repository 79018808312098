import { store } from "app2/src/store";
import { StoreRegistry } from "app2/src/storeRegistry";
import * as authActions from "./reducers/auth.actions";
import { authService } from "./helpers/Auth.service";
import { remoteStorage } from "app2/src/api/remoteStorage.service";

StoreRegistry.setStore(store);
StoreRegistry.set("window", window);
StoreRegistry.set("authService", authService);
StoreRegistry.set("remoteStorage", remoteStorage);

// load any saved authentication information
store.dispatch(
  authActions.Actions.setTokens({
    token: authService.getToken(),
    refreshToken: authService.getRefreshToken(),
  }),
);
