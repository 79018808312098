import { Record, List } from "immutable";
import * as moment from "moment";
import { CamelToSnakeKeys, SnakeToCamelKeys } from "app2/src/helpers/Format";
import { checkDate } from "app2/src/records";
import {
  IFenceSegmentData,
  FenceSegmentRecord,
  fromJSON as segmentFromJSON,
  toFormData as segmentToFormData,
} from "./Segment";

export const fromJSON = (data: Partial<IFenceData>): FenceRecord => {
  const record: Partial<IFenceRecord> = SnakeToCamelKeys(data);

  if (data.segments) {
    record.segments = List(data.segments.map((s) => segmentFromJSON(s)));
  }

  if (data.created_at) {
    record.createdAt = checkDate(data.created_at);
  }

  if (data.updated_at) {
    record.updatedAt = checkDate(data.updated_at);
  }

  return new FenceRecord(record);
};

export const toFormData = (record: FenceRecord): IFenceData => {
  const data = CamelToSnakeKeys(record.toJSON());

  delete (data as any).segment_ids;

  if (record.segments.size > 0) {
    data.segments = record.segments.map((r) => segmentToFormData(r)).toArray();
  } else {
    data.segments = [];
  }

  data.updated_at = moment(record.updatedAt).format("YYYY-MM-DDTHH:mm:ss.SSS");
  data.created_at = moment(record.createdAt).format("YYYY-MM-DDTHH:mm:ss.SSS");

  return data;
};

export interface IFenceData {
  id: number;
  segments: Array<Partial<IFenceSegmentData>>;
  fencing_id: number;
  corner_posts: number;
  terminal_posts: number;
  gate_count: number;
  gate_length: number;
  total_length: number;
  net_length: number;
  line_posts_4: number;
  line_posts_6: number;
  line_posts_8: number;
  _destroy?: boolean;
  created_at: string;
  updated_at: string;
}

export interface IFenceRecord {
  id: number;
  segments: List<FenceSegmentRecord>;
  segmentIds: List<number>;
  fencingId: number;
  cornerPosts: number;
  terminalPosts: number;
  gateCount: number;
  gateLength: number;
  totalLength: number;
  netLength: number;
  linePosts4: number;
  linePosts6: number;
  linePosts8: number;
  _destroy: boolean;
  updatedAt: Date;
  createdAt: Date;
}

export const defaultFenceProps = {
  id: 0,
  segments: List(),
  segmentIds: List(),
  fencingId: 0,
  cornerPosts: 0,
  terminalPosts: 0,
  totalLength: 0,
  gateCount: 0,
  gateLength: 0,
  netLength: 0,
  linePosts4: 0,
  linePosts6: 0,
  linePosts8: 0,
  _destroy: false,
  updatedAt: new Date(),
  createdAt: new Date(),
};

export class FenceRecord extends Record<IFenceRecord>(defaultFenceProps) implements IFenceRecord {}

export const emptyFenceRecord = new FenceRecord();
