import { Record, List } from "immutable";
import { IAddressData, fromJSON as addressFromJSON, AddressRecord } from "./Address";
import { IPhoneNumberData, fromJSON as phoneFromJSON, PhoneNumberRecord } from "./PhoneNumber";
import { Nullable } from "app2/src/records";
import { IJobAssignmentData, JobAssignmentRecord, fromJSON as jobAssignmentFromJSON } from "./JobAssignment";

export const fromJSON = (json: Partial<IJobBasicData>): JobBasicRecord => {
  const record: IJobBasicRecord = { ...(json as any) };

  if (json.address) {
    record.address = addressFromJSON(json.address);
  } else {
    record.address = addressFromJSON({});
  }

  if (json.phone_numbers) {
    record.phone_numbers = List<PhoneNumberRecord>(json.phone_numbers.map((pn) => phoneFromJSON(pn)));
  } else {
    record.phone_numbers = List<PhoneNumberRecord>();
  }

  if (json.assignments) {
    record.assignments = List(json.assignments.map((a) => jobAssignmentFromJSON(a)));
  }

  return new JobBasicRecord(record);
};

export interface IJobBasicData {
  id: number;
  name: string;
  customer_name: string;
  email: string;
  address: IAddressData;
  phone_numbers: IPhoneNumberData[];
  assignments?: IJobAssignmentData[];
}

export interface IJobBasicRecord {
  id: number;
  name: Nullable<string>;
  customer_name: Nullable<string>;
  address: AddressRecord;
  phone_numbers: List<PhoneNumberRecord>;
  assignments: List<JobAssignmentRecord>;
}

export const defaultJobBasicProps = {
  id: 0,
  name: "",
  customer_name: "",
  email: "",
  address: addressFromJSON({}),
  phone_numbers: List<PhoneNumberRecord>(),
  assignments: List<JobAssignmentRecord>(),
};

export class JobBasicRecord extends Record<IJobBasicRecord>(defaultJobBasicProps) implements IJobBasicRecord {}
