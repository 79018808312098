import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface ICalendar extends ng.resource.IResource<ICalendar>, CalendarPrototype {
  $syncEvents?(): ng.IPromise<ICalendar>;
}

export interface ICalendarResource extends ng.resource.IResourceClass<ICalendar>, IRsfResource {
  sync?(): ICalendar;
  fromJSON?(data: any): ICalendar;
}

export interface ICalendarResponse extends ng.resource.IResourceArray<ICalendar> {
  calendars: ICalendar[];
}

class CalendarPrototype {
  public id: number;
  public user_id: number;
  public calendar_id: string;
  public sync_dir: number;
  public is_deleted: boolean;
  public is_primary: boolean;
  public is_readonly: boolean;
  public metadata: any;
  public name: string;
  public provider: number;
  public created_at: Date;
  public updated_at: Date;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, $http: ng.IHttpService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/users/calendars/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): ICalendar => {
    if (status < 200 || status > 299) {
      return new Calendar({});
    }

    return Calendar.fromJSON(JSON.parse(response).calendar);
  };

  const multiResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return { calendars: [], metadata: {} };
    }

    const meta: any = JSON.parse(response);

    _.each(meta.calendars, (child, index) => {
      meta.calendars[index] = Calendar.fromJSON(child);
    });

    return meta;
  };

  const transformRequest = (calendar: ICalendar): string => {
    const data = JSON.decycle(calendar);
    return angular.toJson({ calendar: data });
  };

  const Calendar: ICalendarResource = <ICalendarResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multiResponseTransform,
        isArray: false,
      },
      syncEvents: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: BaseConfig.BASE_URL + "/api/v1/users/calendars/:id/sync",
      },
      sync: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: BaseConfig.BASE_URL + "/api/v1/users/calendars/sync",
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
      },
    },
  );

  Calendar.fromJSON = (data: any): ICalendar => {
    return new Calendar(data);
  };

  _.hiddenExtend(Calendar.prototype, CalendarPrototype.prototype);

  Calendar.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Calendar;
};

factory.$inject = ["$resource", "$http", "BaseConfig"];

export default factory;
