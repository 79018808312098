import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IPaymentRequestData>): PaymentRequestRecord => {
  const recordData: PaymentRequestRecord = { ...(json as any) };

  return new PaymentRequestRecord(recordData);
};

export type PaymentRequestKind = "cardconnect" | "paysimple";

export interface IPaymentRequestData {
  id: number;
  description: string;
  amount: number;
  to: string;
  provider: "email" | "text";
  job_id: number;
  user_id: number;
  org_id: number;
  loading?: boolean;
  sms?: string;
  emails?: string;
  kind?: PaymentRequestKind;
  created_at: Date;
  updated_at: Date;
}

export interface IPaymentRequestRecord {
  id: number;
  description: string;
  amount: number;
  to: string;
  provider: "email" | "text";
  job_id: number;
  user_id: number;
  org_id: number;
  loading: boolean;
  sms: string;
  emails: string;
  kind?: string;
  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

export const defaultPaymentRequestProps: IPaymentRequestRecord = {
  id: 0,
  description: "",
  amount: 0,
  to: "",
  provider: "email",
  job_id: 0,
  user_id: 0,
  org_id: 0,
  loading: false,
  sms: "",
  emails: "",
  kind: "",
  created_at: null,
  updated_at: null,
};

export class PaymentRequestRecord extends Record(defaultPaymentRequestProps) implements IPaymentRequestRecord {}
