import { RootState } from "../reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { ImageRecord, IImageData } from "../records/Image";
import { ids } from "app2/src/selectors/pagination.selectors";
import { recordChanged } from "app2/src/helpers/Record";

export const image = (state: RootState, props: any) => state.getIn(["images", "byId", props.imageId]);
export const lastSavedImage = (state: RootState, props: any) => state.getIn(["images", "lastSavedById", props.imageId]);
export const imagesById = (state: RootState) => state.getIn(["images", "byId"]);
export const lastSavedImagesById = (state: RootState) => state.getIn(["images", "lastSavedById"]);
export const currentImageId = (state: RootState, props?: any) => state.getIn(["images", "currentImageId"]);
export const showInfo = (state: RootState, props?: any) => state.getIn(["images", "showInfo"]);

/**
 * Loads image records of ids passed in
 *
 * @param {RootState} state The RootState
 * @param {{ imagesIds: List<number>, fullEstimate: boolean }} options
 * @returns {ImageRecord} ImageRecord
 */
export const images = createSelector(
  [imagesById, (_: RootState, props: any) => props],
  (imagesById: Map<number, ImageRecord>, props: any): List<ImageRecord> => {
    return imagesProcess(imagesById, props);
  },
);

/**
 * Fetches image records of ids passed in (lastSaved version)
 *
 * @param {RootState} state The RootState
 * @param {{ imagesIds: List<number>, fullEstimate: boolean }} options
 * @returns {ImageRecord} ImageRecord
 */
export const lastSavedImages = createSelector(
  [lastSavedImagesById, (_: RootState, props: any) => props],
  (imagesById: Map<number, ImageRecord>, props: any): List<ImageRecord> => {
    return imagesProcess(imagesById, props);
  },
);

/**
 * Checks to see if an image record has been edited since last loaded from the server
 *
 * @param {RootState} state The RootState
 * @param {{ imagesId: number }} options
 * @returns boolean
 */
export const imageChanged = createSelector(
  [image, lastSavedImage],
  (image: ImageRecord, lastSavedImage: ImageRecord) => {
    return recordChanged(image, lastSavedImage);
  },
);

/**
 * Fetches imageIds that have a booleanName property === true
 *
 * @param {RootState} state The RootState
 * @param {{ booleanName: string }} options
 * @returns List<number>
 */
export const imagesBooleanIds = createSelector(
  [
    imagesById,
    (state: RootState, props: any) => ({
      state,
      props,
    }),
  ],
  (imagesById, stateProps: any): List<number> => {
    const { state } = stateProps;
    const { props } = stateProps;
    const { booleanName } = props;
    const imageIds = ids(state, { modelName: "image" });
    if (!imageIds) {
      return List();
    }

    return imageIds.filter((imageId: number) => {
      return imagesById.getIn([imageId, booleanName]) === true;
    });
  },
);

/**
 * Returns true if at least one booleanName property === true
 *
 * @param {RootState} state The RootState
 * @param {{ booleanName: string }} options
 * @returns boolean
 */
export const imagesBoolean = createSelector([imagesBooleanIds], (imagesBooleanIds): boolean => {
  return imagesBooleanIds.size > 0;
});

/**
 * Returns true if all the images with booleanName property === true
 *
 * @param {RootState} state The RootState
 * @param {{ booleanName: string }} options
 * @returns boolean
 */
export const allImagesBoolean = createSelector(
  [
    imagesBooleanIds,
    (state: RootState, props: any) => ({
      state,
      props,
    }),
  ],
  (imagesBooleanIds, stateProps): boolean => {
    const imageIds = ids(stateProps.state, { modelName: "image" });
    return imagesBooleanIds.size === imageIds.size;
  },
);

const imagesProcess = (imagesById, props) => {
  const { imageIds } = props;
  let { fullEstimate } = props;
  if (_.isUndefined(fullEstimate)) {
    fullEstimate = false;
  }
  return imageIds
    .map((imageId: number) => {
      return imagesById.get(imageId);
    })
    .filter((image: ImageRecord) => {
      return fullEstimate || !image._destroy;
    })
    .toList();
};

/**
 * Returns json of all imageIds passed in
 *
 * @param {RootState} state The RootState
 * @param {{ imagesIds: List<number>, fullEstimate: boolean }} options
 * @returns List<number>
 */
export const toImagesJson = createSelector(
  [images, (state, props) => ({ state, props })],
  (images: List<ImageRecord>, stateProps: any): IImageData[] => {
    return images
      .map((image: ImageRecord) => {
        const imageData = image.toJS();

        if (!imageData._destroy) {
          delete imageData._destroy;
        }

        delete imageData.loading;
        delete imageData.selected;
        delete imageData.displayInProposal;
        delete imageData.displayInAgreement;
        delete imageData.file_queue;

        return imageData as IImageData;
      })
      .toArray();
  },
);
