import { fetcher } from "../../helpers/Fetcher";
import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction, handleErrors } from "../Utils";
import { RootState } from "..";
import { IPagination } from "app2/src/api/integrations/eagleview.service";
import { TaskPoller } from "app2/src/api/task.service";
import { ITaskData } from "app2/src/records/Task";
import { RootDispatchType } from "app2/src/store";

export const FETCH_PAGE = "@integrations/hover/FETCH_PAGE";
export const RECEIVE_PAGE = "@integrations/hover/RECEIVE_PAGE";
export const RESET_PAGES = "@integrations/hover/RESET_PAGES";
export const SET_AUTHORIZING = "@integrations/hover/SET_AUTHORIZING";
export const SET_AUTHORIZED = "@integrations/hover/SET_AUTHORIZED";
export const SET_ERRORS = "@integrations/hover/SET_ERRORS";
export const SET_CURRENT_JOB = "@integrations/hover/SET_CURRENT_JOB";
export const SET_IMPORTING = "@integrations/hover/SET_IMPORTING";
export const SET_IMPORT_LOG = "@integrations/hover/SET_IMPORT_LOG";
export const FETCH_HOVER_USER = "@integrations/hover/FETCH_HOVER_USER";
export const RECEIVE_HOVER_USER = "@integrations/hover/RECEIVE_HOVER_USER";
export const SET_HOVER_USERS_LOADED = "@ingetrations/hover/SET_HOVER_USERS_LOADED";
export const FETCH_ORG_HOVER_USERS = "@integrations/hover/FETCH_ORG_HOVER_USERS";
export const RECEIVE_ORG_HOVER_USERS = "@integrations/hover/RECEIVE_ORG_HOVER_USERS";
export const RECEIVE_ORG_ERRORS = "@integrations/hover/RECEIVE_ORG_ERRORS";

export interface IHoverJobData {
  id: number;
  archived: boolean;
  name: string;
  customer_notes: string;
  deliverable_id: number;
  location_line_1: string;
  location_line_2: string;
  location_city: string;
  location_region: string;
  location_postal_code: string;
  location_country: string;
  location_lat: string;
  location_lon: string;
  roof_estimate_access_level: string;
  estimated_hours_to_completion: number;
  approved: boolean;
  example: boolean;
  property_type: string;
  updated_at: string;
  completed_at: string;
  approved_at: string;
  created_at: string;
  org_id: number;
  user_id: number;
  captured_user_id: number;
  search_rank: string;
  state: string;
  via_job_share: number;
}

export interface Page {
  loading?: boolean;
  page: number;
  jobs: IHoverJobData[];
  meta: IPagination;
}

export interface IHoverParams {
  page: number;
  search: string;
}

export const Actions = {
  fetchPage: (params: IHoverParams) => createAction(FETCH_PAGE, params),
  receivePage: (page: Page) => createAction(RECEIVE_PAGE, page),
  resetPages: () => createAction(RESET_PAGES),
  setAuthorizing: (authorizing: boolean) => createAction(SET_AUTHORIZING, authorizing),
  setAuthorized: (authorized: boolean) => createAction(SET_AUTHORIZED, authorized),
  setErrors: (errors: string[]) => createAction(SET_ERRORS, errors),
  setImporting: (importing: boolean) => createAction(SET_IMPORTING, importing),
  setImportLog: (log: string) => createAction(SET_IMPORT_LOG, log),
  setCurrentJob: (jobId: number) => createAction(SET_CURRENT_JOB, jobId),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  getAuthorization: (code: string): ThunkResult<Promise<boolean>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.setAuthorizing(true));
      return fetcher.post("/integrations/hover/callback", { code: code }).then(
        () => {
          dispatch(Actions.setAuthorizing(false));
          dispatch(Actions.setAuthorized(true));
          return true;
        },
        (errors) => {
          handleErrors(errors);
          dispatch(Actions.setErrors(["There was a problem with your authorization code.  Please try again."]));
          dispatch(Actions.setAuthorizing(false));
          return false;
        },
      );
    };
  },
  getPage: (params: IHoverParams): ThunkResult<Promise<Page>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPage(params));
      return fetcher.get<Page>("/integrations/hover/jobs", params).then((data) => {
        data.page = params.page;
        dispatch(Actions.receivePage(data));
        return data;
      });
    };
  },
  getAuthorized: (): ThunkResult<Promise<{ authorized: boolean }>> => {
    return (dispatch: RootDispatchType) => {
      return fetcher.get<{ authorized: boolean }>("/integrations/hover/authorized").then((result) => {
        dispatch(Actions.setAuthorized(result.authorized));
        return result;
      });
    };
  },
  importReport: (reportId: number, jobId: number): ThunkResult<Promise<TaskPoller>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.setImporting(true));
      return fetcher
        .post<{ location: string }>("/integrations/hover/import", { hover_id: reportId, job_id: jobId })
        .then((result) => {
          const poller = new TaskPoller(result.location);

          poller.callback = (task: ITaskData) => {
            dispatch(Actions.setImportLog(task.logs));
          };

          poller.promise.then(
            () => {
              dispatch(Actions.setImporting(false));
            },
            (task: ITaskData) => {
              dispatch(Actions.setImportLog(task.logs));
              dispatch(Actions.setErrors([task.logs]));
              dispatch(Actions.setImporting(false));
            },
          );

          return poller;
        });
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
