import * as React from "react";
import { RootState } from "app2/src/reducers";
import { document } from "app2/src/selectors/document.selectors";
import { estimateOverview } from "app2/src/selectors/estimate.selectors";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { getTitle } from "app2/src/selectors/org.selectors";
import { List } from "immutable";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SignaturesDocumentsTable } from "../SignaturesDocumentsTable";

export interface EstimateDocumentsRowProps {
  estimateId: number;
  signedDocumentIds: List<number>;
}

export const EstimateDocumentsRow: React.FC<EstimateDocumentsRowProps> = ({ estimateId, signedDocumentIds }) => {
  const jobId = useSelector(currentJobId);
  const estimate = useSelector((state: RootState) => estimateOverview(state, { estimateId, jobId }));
  const documents = useSelector((state: RootState) =>
    signedDocumentIds.map((documentId) => document(state, { documentId })),
  );
  const estimateTitle = useSelector((state: RootState) => getTitle(state, { documentType: "estimator" }));

  return (
    <Card className="mb-4">
      <Card.Header>
        <h4 className="mb-0">
          {estimateTitle}: {estimate?.name}
        </h4>
      </Card.Header>
      <Card.Body>
        <SignaturesDocumentsTable documents={documents} />
      </Card.Body>
    </Card>
  );
};
