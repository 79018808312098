import { Annotation, IAnnotation } from "./Annotation";
import { IImage } from "./Image";
import { IMeasurement } from "./Measurement";
import { IMeasurementMetadata } from "rsf-visualizer";
import { IRepository, IRsfResource } from "../Common/Repository";
import { Door } from "./Door";
import { Window } from "./Window";
import { IOpening } from "./Opening";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IWallFacade, WallFacade } from "./WallFacade";

export interface IElevation extends ng.resource.IResource<IElevation>, ElevationPrototype {
  $saveOrCreate(...args: any[]): ng.IPromise<IElevation>;

  $create(): ng.IPromise<IElevation>;
  $create(params?: Object, success?: Function, error?: Function): ng.IPromise<IElevation>;
  $create(success: Function, error?: Function): ng.IPromise<IElevation>;
}

export interface IElevationResource extends ng.resource.IResourceClass<IElevation>, IRsfResource {
  fromJSON?(data: any): IElevation;
  checkDrawingMetadata(drawingMetadata: any): boolean;
}

export class IPoint {
  public x: number;
  public y: number;
}

export class IRect extends IPoint {
  public width: number;
  public height: number;
}

export class ILine {
  public start: IPoint;
  public end: IPoint;
}

class ElevationPrototype {
  public id: number;
  public name: string;
  public abbr: string;
  public src_image_id: number;
  public src_image: IImage;
  public rendered_image_id: number;
  public rendered_image: IImage;
  public annotations: IAnnotation[] = [];
  public wallFacades: IWallFacade[];
  public drawingMetadata: IMeasurementMetadata;
  public removedAnnotations: IAnnotation[];
  public removedWallFacades: IWallFacade[];
  public measurement: IMeasurement;
  public measurement_id: number;
  public $hasChanged: boolean;
  public renderedDataUrl?: string;

  public removeAnnotation(annotation: IAnnotation) {
    this.$hasChanged = true;
    this.annotations = _.select(this.annotations, (a: IAnnotation) => a.id !== annotation.id);
    this.removedAnnotations.push(annotation);
  }

  public removeWallFacade(wf: IWallFacade) {
    this.$hasChanged = true;
    this.wallFacades = _.select(this.wallFacades, (a: IWallFacade) => a.id !== wf.id);
    this.removedWallFacades.push(wf);
  }

  public saveRendered(
    BaseConfig: IBaseConfig,
    Upload: ng.angularFileUpload.IUploadService,
    sortOrder: number,
  ): ng.IPromise<IImage> {
    const file: any = _.dataURLToFile(this.renderedDataUrl, `Elevation ${this.name} - Labels.png`);

    const args: ng.angularFileUpload.IFileUploadConfigFile = <ng.angularFileUpload.IFileUploadConfigFile>{
      data: { image: { file: file } },
    };

    if (this.rendered_image_id) {
      args.url = `${BaseConfig.BASE_URL}/api/v1/jobs/${this.measurement.job_id}/images/${this.rendered_image_id}`;
      args.method = "PATCH";
    } else {
      args.data.image.display = "both";
      args.url = `${BaseConfig.BASE_URL}/api/v1/jobs/${this.measurement.job_id}/images`;
    }

    return Upload.upload(args).then((resp: any) => {
      const img: IImage = resources.Image.fromJSON(resp.data.image);
      this.rendered_image = img;
      this.rendered_image_id = img.id;

      return img;
    });
  }

  public merge(measurement: IMeasurement): void {
    this.measurement = measurement;
    _.each(this.annotations, (annotation: IAnnotation) => {
      let opening: IOpening;
      if (annotation.opening_id) {
        opening = _.find(measurement[`${annotation.opening_type}s`], (o: IOpening) => annotation.opening_id === o.id);

        if (!opening) {
          if (annotation.opening_type === "window") {
            const oldOpening = _.find(measurement.doors, (o: IOpening) => annotation.opening_id === o.id);
            if (oldOpening) {
              this.measurement.deleteDoor(oldOpening);
            }
          } else {
            const oldOpening = _.find(measurement.windows, (o: IOpening) => annotation.opening_id === o.id);
            if (oldOpening) {
              this.measurement.deleteWindow(oldOpening);
            }
          }
        }
      }

      if (_.isNumber(annotation.sort_order)) {
        opening = _.find(
          measurement[`${annotation.opening_type}s`],
          (o: IOpening) => annotation.sort_order === o.sort_order,
        );
      }

      if (!opening) {
        annotation.opening_id = null;
        if (annotation.opening_type === "window") {
          opening = Window.fromJSON({});
        } else {
          opening = Door.fromJSON({});
        }

        opening.sort_order = measurement[`${annotation.opening_type}s`].length;
        opening.annotation = annotation;
        opening.annotation_id = annotation.id;
        annotation.sort_order = opening.sort_order;
        measurement[`${annotation.opening_type}s`].push(opening);
      }

      opening.level = annotation.level;
      opening.location = annotation.location;
      opening.name = annotation.text;
      opening.height = Number(annotation.height || "0");
      opening.width = Number(annotation.width || "0");
      opening.area = _.round((opening.width * opening.height) / 144, -2);
      opening.ui = opening.width + opening.height;
    });
  }

  public associateOpenings(): void {
    _.each(this.annotations, (a: IAnnotation) => {
      const opening: IOpening = _.find(this.measurement[`${a.opening_type}s`], (o: IOpening) => o.id === a.opening_id);
      if (opening) {
        opening.annotation = a;
        opening.annotation_id = a.id;
      }
    });
  }

  public clone(): IElevation {
    const elevation: IElevation = resources.Elevation.fromJSON({
      id: this.id,
      name: this.name,
      abbr: this.abbr,
      src_image_id: this.src_image_id,
      src_image: this.src_image,
      rendered_image_id: this.rendered_image_id,
      rendered_image: this.rendered_image,
      removedAnnotations: _.map(this.removedAnnotations, (a: IAnnotation) => a.clone()),
      removedWallFacades: _.map(this.removedWallFacades, (a: IWallFacade) => a.clone()),
      measurement: this.measurement,
      $hasChanged: this.$hasChanged,
      renderedDataUrl: this.renderedDataUrl,
    });

    elevation.annotations = _.map(this.annotations, (a: IAnnotation) => a.clone());
    elevation.wallFacades = _.map(this.wallFacades, (a: IWallFacade) => a.clone());
    elevation.drawingMetadata = _.clone(this.drawingMetadata);

    return elevation;
  }

  public $saveOrCreate(params, callback): ng.IPromise<IElevation> {
    if (!this.id || this.id <= 0) {
      delete this.id;
      // @ts-ignore
      return this.$create(params, callback);
    } else {
      // @ts-ignore
      return this.$save(params, callback);
    }
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/measurements/:measurement_id/elevations/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IElevation => {
    if (status < 200 || status > 299) {
      return new Elevation({});
    }

    const data = JSON.parse(response).elevation;
    return Elevation.fromJSON(data);
  };

  const requestTransform = (elevation: IElevation): string => {
    const data = JSON.decycle(elevation);

    if (data.measurement) {
      delete data.measurement;
    }
    if (data.id < 0) {
      delete data.id;
    }

    if (data.renderedDataUrl) {
      delete data.renderedDataUrl;
    }

    data.annotations = {
      list: _.map(data.annotations, (a: IAnnotation) => {
        if (a.elevation) {
          delete a.elevation;
        }
        return a;
      }),
      wallFacades: data.wallFacades,
      measuring: data.drawingMetadata,
    };

    return angular.toJson({ elevation: data });
  };

  const Elevation: IElevationResource = <IElevationResource>$resource(
    url,
    { id: "@id", measurement_id: "@measurement_id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const meta = JSON.parse(response);

          _.each(meta.elevations, (jsonElevation, index) => {
            meta.elevations[index] = Elevation.fromJSON(jsonElevation);
          });

          return meta;
        },
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: requestTransform,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: requestTransform,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Elevation.prototype, ElevationPrototype.prototype);

  Elevation.fromJSON = (data: any): IElevation => {
    if (data.annotations) {
      if (data.annotations.measuring && Elevation.checkDrawingMetadata(data.annotations.measuring)) {
        data.drawingMetadata = data.annotations.measuring;
      } else {
        data.drawingMetadata = null;
      }

      if (data.annotations.wallFacades) {
        data.wallFacades = data.annotations.wallFacades;
      }

      if (data.annotations.list) {
        data.annotations = data.annotations.list;
      }
    }

    if (!data.annotations) {
      data.annotations = [];
    }

    if (!data.wallFacades) {
      data.wallFacades = [];
    }

    if (data.src_image) {
      data.src_image = resources.Image.fromJSON(data.src_image);
    }

    if (data.rendered_image) {
      data.rendered_image = resources.Image.fromJSON(data.rendered_image);
    }

    if (data.$hasChanged !== true) {
      data.$hasChanged = false;
    }

    const elevation = new Elevation(data);
    elevation.removedAnnotations = [];
    elevation.removedWallFacades = [];

    _.each(data.annotations, (a: IAnnotation, idx: number) => {
      a.elevation = elevation;
      elevation.annotations[idx] = Annotation.fromJSON(a);
    });

    _.each(data.wallFacades, (wf: IWallFacade, idx: number) => {
      elevation.wallFacades[idx] = WallFacade.fromJSON(wf);
    });

    return elevation;
  };

  Elevation.checkDrawingMetadata = (drawingMetadata: any) => {
    if (
      drawingMetadata.scale &&
      drawingMetadata.imageScale &&
      drawingMetadata.gridPosition &&
      drawingMetadata.measurements
    ) {
      return true;
    }
    return false;
  };

  Elevation.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Elevation;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
