import { InteriorExteriorButton } from "app2/src/components/JobTabs/MeasurementTab/InteriorExteriorButton";
import { Room } from "app2/src/components/JobTabs/MeasurementTab/Interiors/Room";
import { RoomRecord } from "app2/src/records/Room";
import { RootState } from "app2/src/reducers";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { rooms as roomsSelector } from "app2/src/selectors/room.selectors";
import { pathname, query } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import { List } from "immutable";
import * as React from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { InteriorTabs } from "./InteriorTabs";

export const Interiors: React.FC = () => {
  // Hooks
  const match = useRouteMatch();
  const dispatch = useDispatch();

  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const rooms = useSelector((state: RootState) =>
    roomsSelector(state, { roomIds: measurement?.get("room_ids") || List() }).filter((room) => !room._destroy),
  );
  const queryParams = useSelector(query);
  const rootPathname = useSelector(pathname);
  const editing = useSelector(measurementEditing);

  // Methods
  const renderRedirect = () => {
    const firstRoom: RoomRecord = rooms.first();
    if (firstRoom) {
      return <Redirect to={`${match.url}/${firstRoom.id}`} />;
    }
  };
  const openAddMeasurement = () => {
    const newQueryParams = new URLSearchParams({
      ...queryParams.toJS(),
      add: "rooms",
    });
    dispatch(push(`${rootPathname}?${newQueryParams.toString()}`));
  };

  return (
    <Row>
      <Col>
        <InteriorExteriorButton kind="interior" />
        {rooms.size > 0 && <InteriorTabs rooms={rooms} />}
        <Card className={rooms.size > 0 ? "border-top-0" : ""}>
          <Card.Body>
            {rooms.size === 0 && (
              <div className="text-center">
                <h2>No rooms added. Let's add one.</h2>
                <Button
                  variant="primary"
                  onClick={openAddMeasurement}
                  disabled={editing}
                  title={editing ? "Disabled for editing" : ""}>
                  <FontAwesome name="plus" />
                  &nbsp; Add
                </Button>
              </div>
            )}
            <Switch>
              <Route path={`${match.url}/:roomId`} component={Room} />
              <Route path={`${match.url}`} render={renderRedirect} />
            </Switch>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
