import * as React from "react";
import { Col, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { geocode } from "app2/src/services/geocode.service";
import { us_states } from "app/src/Common/StatesConstant";
import { AddressRecord, toOneLiner } from "app2/src/records/Address";

interface AddressShowState {
  address: AddressRecord;
}

interface AddressShowProps {
  address: AddressRecord;
  update: (address: AddressRecord) => void;
}

export class AddressShow extends React.Component<AddressShowProps, AddressShowState> {
  public constructor(props) {
    super(props);

    this.state = {
      address: props.address,
    };

    this.handleInput = this.handleInput.bind(this);
  }

  public handleInput(e: any): void {
    //@ts-ignore
    const value = e.target.value;
    //@ts-ignore
    const name = e.target.id;

    this.setState((state: AddressShowState) => {
      let address = state.address;
      address = address.set(name, value);

      this.props.update(address);
      return {
        address: address,
      };
    });

    this.geocode();
  }

  // eslint-disable-next-line
  public geocode = _.debounce(() => {
    let { address } = this.state;

    geocode({ address: toOneLiner(address) }).then((results: any) => {
      const geoLocation = results[0].geometry.location;

      const area_2 = _.find(results[0].address_components, function (ac: any) {
        return _.contains(ac["types"], "administrative_area_level_2");
      });
      let administrative_area_level_2 = "";
      if (area_2 && area_2["short_name"]) {
        administrative_area_level_2 = area_2["short_name"];
      }

      const area_3 = _.find(results[0].address_components, function (ac: any) {
        return _.contains(ac["types"], "administrative_area_level_3");
      });
      let administrative_area_level_3 = "";
      if (area_3 && area_3["short_name"]) {
        administrative_area_level_3 = area_3["short_name"];
      }

      address = address.merge({
        lat: _.round(geoLocation.lat(), -6),
        lon: _.round(geoLocation.lng(), -6),
        administrative_area_level_2: administrative_area_level_2,
        administrative_area_level_3: administrative_area_level_3,
      });

      this.props.update(address);
      this.setState({
        address: address,
      });
    });
  }, 500);

  public render() {
    const { address } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col md={6}>
            <FormGroup>
              <FormLabel>
                <span className="asterisk">*</span>&nbsp;Address 1
              </FormLabel>
              <FormControl type="text" id="line_1" value={address.line_1} onChange={this.handleInput} required />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <FormLabel>Address 2</FormLabel>
              <FormControl type="text" id="line_2" value={address.line_2} onChange={this.handleInput} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <FormLabel>
                <span className="asterisk">*</span>&nbsp;City
              </FormLabel>
              <FormControl type="text" id="city" value={address.city} onChange={this.handleInput} required />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <FormLabel>
                <span className="asterisk">*</span>&nbsp;State
              </FormLabel>
              <FormControl
                as="select"
                type="text"
                id="state"
                value={address.state}
                onChange={this.handleInput}
                required>
                <option key={-1} value={""} />
                {us_states.map((state, idx) => {
                  return (
                    <option key={idx} value={state}>
                      {state}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <FormLabel>
                <span className="asterisk">*</span>&nbsp;Zip Code
              </FormLabel>
              <FormControl
                type="text"
                id="postal_code"
                value={address.postal_code}
                maxLength={10}
                onChange={this.handleInput}
                required
              />
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
