import * as React from "react";
import * as config from "react-global-configuration";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { RootDispatchType } from "app2/src/store";
import { push, replace } from "app2/src/reducers/router.actions";
import { ILocationData } from "app2/src/records/Location";
import * as oidc from "oidc-client";
import { StoreRegistry } from "app2/src/storeRegistry";
import { IAuthService } from "app2/src/helpers/Auth.service";
import { lowesErrorMessage, lowesTokenData } from "./Authentication.service";
import { Nullable } from "app2/src/records";
import { OidcView } from "./OidcView";

export interface OidcCallbackProps {}

export interface OidcCallbackState {
  errorMsg: Nullable<string>;
  user: Nullable<oidc.User>;
}

const mapStateToProps = (state: RootState, ownProps: OidcCallbackProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: OidcCallbackProps) => {
  return {
    push: (location: Partial<ILocationData>) => dispatch(push(location)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OidcCallbackProps;

class OidcCallback extends React.Component<Props, OidcCallbackState> {
  public constructor(props) {
    super(props);

    this.state = {
      errorMsg: null,
      user: null,
    };
  }

  public async componentDidMount() {
    const { push } = this.props;
    const authService = StoreRegistry.get<IAuthService>("authService");
    let user: oidc.User;
    try {
      user = await (authService as any).userManager.signinCallback();
    } catch (e) {
      console.error(e);
      ((authService as any).userManager as oidc.UserManager).clearStaleState();
      push({ pathname: config.get("DEFAULT_ROUTE") });
      return;
    }

    if (!user) {
      return;
    }

    try {
      (authService as any).setupOidcUserManager(user);
      await (authService as any).setupOidcUser(user);

      let appState: any = sessionStorage.getItem("LoginAppState") || undefined;
      sessionStorage.removeItem("LoginAppState");

      if (appState) {
        appState = JSON.parse(appState);
      }

      push({ pathname: appState.goToRoute, query: appState.goToParams });
    } catch (e) {
      this.setState({
        errorMsg: lowesErrorMessage(e),
        user: user,
      });
    }
  }

  public render() {
    const { errorMsg, user } = this.state;

    return <OidcView errorMsg={errorMsg} user={user} />;
  }
}

export default connector(OidcCallback);
