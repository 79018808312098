import { ActionsUnion, createAction } from "./Utils";
import { IPoolData } from "../records/Pool";

export const RECEIVE_POOL = "@pools/RECEIVE_POOL";
export const EDIT_POOL = "@pools/EDIT_POOL";

export const Actions = {
  receivePool: (pool: Partial<IPoolData>) => createAction(RECEIVE_POOL, { pool }),
  editPool: (pool: Partial<IPoolData>) => createAction(EDIT_POOL, { pool }),
};

export type Actions = ActionsUnion<typeof Actions>;
