import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IJobResource, IJobResponse } from "app/src/Models/Job";
import * as angulartics from "angulartics";

export class OrgJobsTabCtrl extends BaseOrgTabCtrl {
  public jobResponse: IJobResponse;
  public params: any = {
    perPage: 25,
    currentPage: 1,
  };

  public static $inject = ["OrgFetcher", "Job", "$stateParams", "$analytics"];
  constructor(
    OrgFetcher: IOrgFetcherService,
    public Job: IJobResource,
    public $stateParams: ng.ui.IStateParamsService,
    protected $analytics: angulartics.IAnalyticsService,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.query(1);
    this.query = this.query.bind(this);
  }

  public query(page) {
    const queryParams = {
      org_id: this.$stateParams["id"],
      page,
      per_page: this.params.perPage,
      sort_by: "updated_at",
      sort_order: "desc",
      "include[]": ["assignments"],
    };

    if (this.params.search) {
      queryParams["query"] = this.params.search;
    }
    this.jobResponse = this.Job.queryByOrg(queryParams);
  }
}
