import * as React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { currentJobId, selectedEstimateId } from "app2/src/selectors/job.selectors";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import * as signedDocumentActions from "app2/src/reducers/signedDocument.actions";
import { useTracking } from "react-tracking";
import { SignedDocumentForm } from "app2/src/components/Common/SignedDocumentForm";
import { estimate } from "app2/src/selectors/estimate.selectors";
import { document } from "app2/src/selectors/document.selectors";
import { currentSignedDocument } from "app2/src/selectors/signedDocument.selectors";
import { AsyncActions as SignedDocumentAsyncActions } from "app2/src/reducers/signedDocument.actions";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { replace } from "connected-react-router/immutable";
import { pathname } from "app2/src/selectors/router.selectors";

export interface SignatureSetupModalProps {}

export const SignatureSetupModal: React.FC<SignatureSetupModalProps> = () => {
  // Hooks
  const dispatch = useDispatch();
  const { goBack } = usePreviousPath();
  const { trackEvent } = useTracking<any>({
    category: "Contract",
  });

  // Local State
  const [loading, setLoading] = React.useState(false);

  // Selectors
  const estimateId = useSelector(selectedEstimateId);
  const jobId = useSelector(currentJobId);
  const estimateRecord = useSelector((state: RootState) => estimate(state, { estimateId }));
  const documentRecord = useSelector((state: RootState) =>
    document(state, { documentId: estimateRecord?.get("tempDocumentId") }),
  );
  const signedDocument = useSelector(currentSignedDocument);
  const path = useSelector(pathname);

  // Methods
  const close = () => {
    trackEvent({ action: "cancel sign contract" });
    goBack("/signature");
  };

  const sign = async (event: React.FormEvent<HTMLFormElement>) => {
    let notify = false;
    let message = "";
    setLoading(true);

    // @ts-ignore
    if (event?.nativeEvent?.submitter?.name === "email") {
      notify = true;
      message = "";
    }

    const newSignedDocumentId = await dispatch(
      signedDocumentActions.AsyncActions.contractFlowCreate(documentRecord, { notify, message }),
    );
    await dispatch(signedDocumentActions.AsyncActions.listEstimateSignedDocuments(jobId));

    if (notify) {
      dispatch(replace(path.split("/view_pdf")[0]));
    } else {
      dispatch(replace(`${path.split("/view_pdf")[0]}/signing/${newSignedDocumentId}`));
    }
    setLoading(false);
    const recipientsCount = signedDocument.recipients.size;
    trackEvent({ action: "Request Submitted", recipientsCount });
  };

  React.useEffect(() => {
    dispatch(SignedDocumentAsyncActions.validateSignedDocument(documentRecord, false, estimateId));
  }, []);

  React.useEffect(() => {
    if (signedDocument?.get("validation_status") === 422) {
      const newPath = path.split("/view_pdf/new/signature")[0];
      dispatch(replace(newPath));
    }
  }, [signedDocument]);

  return (
    <>
      <SpinnerComponent localProperty={!signedDocument || signedDocument?.get("loading") || loading} />
      <Modal show={true} onHide={close} size="xl" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Signing Recipients</Modal.Title>
        </Modal.Header>
        {signedDocument && !signedDocument?.get("loading") && <SignedDocumentForm onSubmit={sign} close={close} />}
      </Modal>
    </>
  );
};
