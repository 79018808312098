import { currentJobId } from "app2/src/selectors/job.selectors";
import { List, Map } from "immutable";
import { createSelector } from "reselect";
import { EventRecord } from "../records/Event";
import { RootState } from "../reducers";

export const byId = (state: RootState): List<EventRecord> => state.getIn(["events", "byId"]).toList();

export const event = (state: RootState, props: { eventId: number }): EventRecord =>
  state.getIn(["events", "byId", props.eventId]);

/**
 * Iterates over the given events list and checks for possible integration ids
 * @param {List<EventRecord>} events
 * @returns Object with boolean expressions for each integration
 */
export const hasIntegrations = (events: List<EventRecord>) => {
  let hasMarketSharp = false;
  let hasI360 = false;
  let hasSR = false;
  let hasSalesForce = false;
  let hasLeadPerfection = false;

  events?.forEach((e) => {
    if (e.metadata?.get("marketsharp_id")) hasMarketSharp = true;
    if (e.metadata?.get("improveit360_appointment_id")) hasI360 = true;
    if (e.metadata?.get("sales_rabbit_id")) hasSR = true;
    if (e.metadata?.get("salesforce_event_id")) hasSalesForce = true;
    if (e.metadata?.get("lead_perfection_id")) hasLeadPerfection = true;
  });
  return {
    hasMarketSharp,
    hasI360,
    hasSR,
    hasSalesForce,
    hasLeadPerfection,
  };
};

/**
 * Returns true if event has any CRM appointment id
 * @param {RootState} state The RootState
 * @param {{ eventId: number }} options
 */
export const eventHasCRM = createSelector(event, (event: EventRecord) => {
  const metadata = event?.metadata;
  if (!metadata) return false;

  return Boolean(
    metadata.get("marketsharp_id") ||
      metadata.get("improveit360_appointment_id") ||
      metadata.get("sales_rabbit_id") ||
      metadata.get("salesforce_event_id") ||
      metadata.get("lead_perfection_id"),
  );
});

export const colorsByUserId = (state: RootState): Map<number, string> | undefined =>
  state.getIn(["events", "colors", "byUserId"]);

/**
 * Returns the event color that matches the user_id or job assigned user_id
 * @param {RootState} state The RootState
 * @param {{ event: EventRecord }} options
 */
export const eventColor = createSelector(
  [colorsByUserId, (state, props) => ({ state, props })],
  (colorsByUserId, { props }) => {
    return (
      colorsByUserId?.get(props.event.user_id || props.event.getIn(["job", "assignments", 0, "user_id"])) ||
      "hsl(210, 67%, 53%)"
    );
  },
);

/**
 * Returns the red for the current job id, otherwise blue for Job Appointments tab
 * @param {RootState} state The RootState
 * @param {{ event: EventRecord }} options
 * @returns string color hex
 */
export const jobTabColor = createSelector(
  [currentJobId, (state, props) => ({ state, props })],
  (currentJobId, stateProps) => {
    const { props } = stateProps;
    return props.event.job_id === currentJobId ? "#ed2e24" : "#3174ad";
  },
);
