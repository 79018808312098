import * as paymentTermTemplateActions from "./paymentTermTemplate.actions";
import { PaymentTermTemplateRecord, fromJSON } from "app2/src/records/PaymentTermTemplate";
import { Map, Record, List } from "immutable";

export interface IOrgPaymentTermTemplatesRecord {
  paymentTermTemplates: List<number>;
  errors: List<string>;
  loading: boolean;
}
export const OrgPaymentTermTemplatesRecord: Record.Factory<IOrgPaymentTermTemplatesRecord> =
  Record<IOrgPaymentTermTemplatesRecord>({
    paymentTermTemplates: List<number>(),
    errors: List<string>(),
    loading: false,
  });

export const PaymentTermTemplateStateRecord = Record({
  paymentTermTemplatesById: Map<number, PaymentTermTemplateRecord>(),
  paymentTermTemplatesByOrgId: Map<number, List<typeof OrgPaymentTermTemplatesRecord>>(),
});

export const initialState = PaymentTermTemplateStateRecord();
export type PaymentTermTemplateState = typeof initialState;

export const reducer = (state = initialState, action: paymentTermTemplateActions.Actions): PaymentTermTemplateState => {
  switch (action.type) {
    case paymentTermTemplateActions.RECEIVE_PAYMENT_TERM_TEMPLATE:
      const paymentTermTemplate = fromJSON({ ...action.payload, loading: false });

      if (!state.getIn(["paymentTermTemplatesByOrgId", paymentTermTemplate.org_id])) {
        state = state.setIn(
          ["paymentTermTemplatesByOrgId", paymentTermTemplate.org_id],
          OrgPaymentTermTemplatesRecord({}),
        );
      }

      return state.setIn(["paymentTermTemplatesById", paymentTermTemplate.id], paymentTermTemplate).setIn(
        ["paymentTermTemplatesByOrgId", paymentTermTemplate.org_id, "paymentTermTemplates"],
        state
          //@ts-ignore
          .getIn(["paymentTermTemplatesByOrgId", paymentTermTemplate.org_id, "paymentTermTemplates"], List<number>())
          .push(paymentTermTemplate.id)
          .toSet()
          .toList(),
      );
    case paymentTermTemplateActions.RECEIVE_PAYMENT_TERM_TEMPLATE_ERROR:
      return state
        .setIn(
          ["paymentTermTemplatesById", action.payload.paymentTermTemplateId, "errors"],
          List<string>(action.payload.errors),
        )
        .setIn(["paymentTermTemplatesById", action.payload.paymentTermTemplateId, "loading"], false);
    case paymentTermTemplateActions.FETCH_PAYMENT_TERM_TEMPLATE:
      if (state.getIn(["paymentTermTemplatesById", action.payload.paymentTermTemplateId])) {
        return state.setIn(["paymentTermTemplatesById", action.payload.paymentTermTemplateId, "loading"], true);
      }
      return state.setIn(
        ["paymentTermTemplatesById", action.payload.paymentTermTemplateId],
        new PaymentTermTemplateRecord({
          id: action.payload.paymentTermTemplateId,
          org_id: action.payload.orgId,
          loading: true,
        }),
      );
    case paymentTermTemplateActions.FETCH_ORG_PAYMENT_TERM_TEMPLATES:
      if (!state.getIn(["paymentTermTemplatesByOrgId", action.payload.orgId])) {
        return state.setIn(
          ["paymentTermTemplatesByOrgId", action.payload.orgId],
          OrgPaymentTermTemplatesRecord({ loading: true }),
        );
      }

      return state.setIn(["paymentTermTemplatesByOrgId", action.payload.orgId, "loading"], true);
    case paymentTermTemplateActions.RECEIVE_ORG_PAYMENT_TERM_TEMPLATES:
      state = state.setIn(["paymentTermTemplatesByOrgId", action.payload.orgId, "paymentTermTemplates"], List([]));
      action.payload.paymentTermTemplates.forEach((d) => {
        state = reducer(state, paymentTermTemplateActions.Actions.receivePaymentTermTemplate(d));
      });

      return state.setIn(["paymentTermTemplatesByOrgId", action.payload.orgId, "loading"], false);
    case paymentTermTemplateActions.RECEIVE_ORG_ERRORS:
      return state
        .setIn(["paymentTermTemplatesByOrgId", action.payload.orgId, "errors"], List<string>(action.payload.errors))
        .setIn(["paymentTermTemplatesByOrgId", action.payload.orgId, "loading"], false);
    case paymentTermTemplateActions.REMOVE_PAYMENT_TERM_TEMPLATE:
      return state.removeIn(["paymentTermTemplatesById", action.payload]);
    case paymentTermTemplateActions.REMOVE_ORG_PAYMENT_TERM_TEMPLATE:
      return state.updateIn(
        ["paymentTermTemplatesByOrgId", action.payload.orgId, "paymentTermTemplates"],
        (paymentTermTemplates: List<number>) => {
          return paymentTermTemplates.filter((d) => d !== action.payload.paymentTermTemplateId);
        },
      );
    default:
      return state;
  }
};
