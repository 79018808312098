import { useEffect, useRef } from "react";
import { AudioTrack as IAudioTrack } from "twilio-video";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";

interface AudioTrackProps {
  track: IAudioTrack;
}

export default function AudioTrack({ track }: AudioTrackProps) {
  const { activeSinkId } = useVideoContext();
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    audioEl.current = track.attach();
    document.body.appendChild(audioEl.current);
    return () => track.detach().forEach((el) => el.remove());
  }, [track]);

  useEffect(() => {
    //@ts-ignore
    if (audioEl && audioEl.current && audioEl.current.setSinkId) {
      //@ts-ignore
      audioEl.current.setSinkId(activeSinkId);
    }
  }, [activeSinkId]);

  return null;
}
