import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { RootDispatchType } from "app2/src/store";
import Documents from "app2/src/components/JobTabs/DocumentTab/Documents";
import Files from "react-files";
import * as documentActions from "app2/src/reducers/document.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { DocumentableType, IDocumentOptions } from "app2/src/api/document.service";
import ResourceDocuments from "app2/src/components/JobTabs/DocumentTab/ResourceDocuments";
import track, { TrackingProp } from "react-tracking";
import { RootState } from "app2/src/reducers";
import { documentsSelected } from "app2/src/selectors/document.selectors";
import MoveFilesDropdown from "app2/src/components/Folders/MoveFilesDropdown";
import { Can } from "app2/src/components/Common/CanComponent";
import { currentJobId } from "app2/src/selectors/job.selectors";
import ViewPdfDoc from "./ViewPdfDoc";
import { queryNumber, queryString } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import { queryParams } from "app2/src/selectors/pagination.selectors";

const mapStateToProps = (state: RootState) => {
  return {
    folderParentId: queryParams(state, { modelName: "folder" }).getIn(["folder_id"]),
    documentableType: (queryString(state, { queryKey: "documentable_type" }) as DocumentableType) || "job",
    documentId: queryNumber(state, { queryKey: "document_id" }) as number,
    documentsSelected: documentsSelected(state, {}),
    jobId: currentJobId(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    createDocuments: (files: any[], options: IDocumentOptions) =>
      dispatch(documentActions.AsyncActions.batchCreateDocument(files, options)),
    flashAddAlert: (level: FlashLevels, msg: string) => dispatch(commonActions.Actions.flashAddAlert(level, msg)),
    push: (path: string) => dispatch(push(path)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DocumentTabProps {
  tracking?: TrackingProp;
}

interface DocumentTabState {
  loading: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DocumentTabProps;

@track((props) => {
  return {
    category: "JobDocuments",
    component: "JobDocumentsTab",
    action: "show",
    job: props.jobId,
  };
})
class DocumentTab extends React.Component<Props, DocumentTabState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.onFilesChange = this.onFilesChange.bind(this);
    this.onFilesError = this.onFilesError.bind(this);
    this.navigate = this.navigate.bind(this);
    this.navigateButton = this.navigateButton.bind(this);

    props.tracking.trackEvent({ action: "shown" });
  }

  public async onFilesChange(files: any[]): Promise<void> {
    const { jobId, createDocuments, tracking, folderParentId } = this.props;

    if (files.length > 0) {
      this.setState({ loading: true });
      await createDocuments(files, { documentableType: "job", documentableId: jobId, folder_id: folderParentId });
      files.forEach((file) => {
        tracking.trackEvent({ action: "document uploaded", document: file.id, file: file.name });
      });

      this.setState({ loading: false });
    }
  }

  public onFilesError(error: any, file: any): void {
    const { flashAddAlert, tracking } = this.props;
    flashAddAlert(FlashLevels.danger, error.message);
    tracking.trackEvent({ action: "invalid file", file: file.name });
  }

  public navigate(folderId: number): void {
    const { jobId, push, documentableType } = this.props;
    if (_.isNullOrUndefined(folderId)) {
      const queryParams = new URLSearchParams({
        documentable_type: documentableType as string,
      });
      push(`/jobs/${jobId}/documents?${queryParams.toString()}`);
    } else {
      const queryParams = new URLSearchParams({
        documentable_type: documentableType as string,
        folder_parent_id: folderId.toString(),
      });
      push(`/jobs/${jobId}/documents?${queryParams.toString()}`);
    }
  }

  public navigateButton(documentableType: DocumentableType): void {
    const { jobId, push } = this.props;
    const queryParams = new URLSearchParams({
      documentable_type: documentableType as string,
    });
    push(`/jobs/${jobId}/documents?${queryParams.toString()}`);
  }

  public render() {
    const { documentableType, folderParentId, documentsSelected, documentId } = this.props;
    const { loading } = this.state;
    if (documentId) {
      return <ViewPdfDoc documentId={documentId} documentableType={documentableType} />;
    }
    return (
      <Row data-testid="documentTab">
        <SpinnerComponent localProperty={loading} />
        <Col>
          <Row className="documents title-row sticky-job-title">
            <Col sm={4}>
              <ButtonGroup>
                <Button
                  variant={documentableType === "job" ? "primary" : "light"}
                  onClick={() => this.navigateButton("job")}>
                  Job Documents
                </Button>
                <Button
                  variant={documentableType === "org" ? "primary" : "light"}
                  onClick={() => this.navigateButton("org")}>
                  Resources
                </Button>
              </ButtonGroup>
            </Col>
            <Col sm={8}>
              {documentableType !== "org" && (
                <>
                  <Can resource="job" permission="update">
                    <Files
                      className="files-dropzone pull-right"
                      onChange={this.onFilesChange}
                      onError={this.onFilesError}
                      accepts={[
                        ".pdf",
                        ".xls",
                        ".xlsx",
                        ".csv",
                        ".xml",
                        ".json",
                        ".zip",
                        ".window",
                        ".dxf",
                        ".skp",
                        ".dwg",
                        ".gltf",
                        ".doc",
                        ".docx",
                        ".odt",
                        ".ods",
                      ]}
                      multiple
                      maxFileSize={30000000}
                      minFileSize={0}
                      clickable>
                      <Button id="job-add-documents" variant="default">
                        <FontAwesome name="plus" /> Add Documents
                      </Button>
                    </Files>
                    {documentsSelected && <MoveFilesDropdown {...this.props} fileType="documents" />}
                  </Can>
                  <p className="text--small mb-0">
                    You can upload the following file type:
                    <br />
                    PDF, XLS, XLSX, CSV, XML, JSON, ZIP, WINDOW, DXF, SKP, DWG, GLTF, DOC, DOCX, ODT, ODS
                  </p>
                </>
              )}
            </Col>
          </Row>
          <br />
          {documentableType === "job" ? (
            <Documents folderParentId={folderParentId} navigate={this.navigate} />
          ) : (
            <ResourceDocuments folderParentId={folderParentId} navigate={this.navigate} />
          )}
        </Col>
      </Row>
    );
  }
}

export default connector(DocumentTab);
