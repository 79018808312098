import { baseCleanProps } from "app2/src/api/Api";
import { fetcher } from "../helpers/Fetcher";
import { IDiscountData, DiscountRecord } from "../records/DiscountRecord";

interface IDiscountIndexResponse {
  discounts: IDiscountData[];
}

interface IDiscountResponse {
  discount: IDiscountData;
}

class DiscountService {
  public orgUrl = "api/v1/orgs/:id/discounts";
  public url = "api/v1/discounts";

  public loadByOrg(orgId: number): Promise<IDiscountData[]> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    return fetcher.get<IDiscountIndexResponse>(actionUrl).then((response) => response.discounts);
  }

  public create(orgId: number, discount: Partial<IDiscountData>): Promise<IDiscountData> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    discount = this.cleanProps(discount);

    return fetcher.post<IDiscountResponse>(actionUrl, { discount: discount }).then(
      (response) => response.discount,
      (response) => Promise.reject(response.data.errors),
    );
  }

  public update(discount: Partial<IDiscountData>): Promise<IDiscountData> {
    const actionUrl = fetcher.joinUrls(this.url, discount.id.toString());

    discount = this.cleanProps(discount);

    return fetcher.patch<IDiscountResponse>(actionUrl, { discount: discount }).then(
      (response) => response.discount,
      (response) => {
        if (response.status === 500) {
          return Promise.reject(["Internal Server Error"]);
        }

        if (_.isArray(response.data.errors)) {
          return Promise.reject(response.data.errors);
        }

        return Promise.reject(["Unknown Error"]);
      },
    );
  }

  public archive(discount: DiscountRecord): Promise<DiscountRecord> {
    const actionUrl = fetcher.joinUrls(this.url, discount.id.toString());

    return fetcher.delete<any>(actionUrl).then(() => {
      return discount.set("archived", true);
    });
  }

  private cleanProps(discount: Partial<IDiscountData>): Partial<IDiscountData> {
    discount = baseCleanProps(JSON.parse(JSON.stringify(discount)));

    return discount;
  }
}

export const discountService = new DiscountService();
