import { Record, List } from "immutable";
import { Nullable } from "..";

export type FeeInterval = "month" | "week" | "day" | "year";

export const fromJSON = (json: Partial<IFeeData>): FeeRecord => {
  const record = { ...json } as any as IFeeRecord;

  return new FeeRecord(record);
};

export const toJSON = (fee: FeeRecord): IFeeData => {
  return fee.toJS();
};

export interface IFeeData {
  id: number;
  amount: number;
  archive_number: string;
  archived_at: null | Date | string;
  default: boolean;
  installments: number;
  interval: FeeInterval;
  kind: number;
  min_interval: string;
  min_interval_count: number;
  name: string;

  created_at: Date | string;
  updated_at: Date | string;

  loading?: boolean;
  errors?: string[];
}

export interface IFeeRecord {
  id: number;
  amount: number;
  archive_number: string;
  archived_at: Nullable<Date>;
  default: boolean;
  installments: number;
  interval: FeeInterval;
  kind: number;
  min_interval: string;
  min_interval_count: number;
  name: string;

  created_at: Date;
  updated_at: Date;

  loading: boolean;
  errors: List<string>;
}

const defaultFeeProps: IFeeRecord = {
  id: 0,
  amount: 0,
  archive_number: "",
  archived_at: null,
  default: false,
  installments: 0,
  interval: "month",
  kind: 0,
  min_interval: "month",
  min_interval_count: 0,
  name: "",
  created_at: new Date(),
  updated_at: new Date(),
  loading: false,
  errors: List<string>(),
};

export class FeeRecord extends Record(defaultFeeProps) implements IFeeRecord {
  public readonly id!: number;
  public readonly amount!: number;
  public readonly archive_number!: string;
  public readonly archived_at!: Date;
  public readonly default!: boolean;
  public readonly installments!: number;
  public readonly interval!: FeeInterval;
  public readonly kind!: number;
  public readonly min_interval!: string;
  public readonly min_interval_count!: number;
  public readonly name!: string;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public constructor(values?: Partial<IFeeRecord>) {
    values ? super(values) : super();
  }
}
