import { orgService } from "app2/src/api/org.service";
import { connect, ConnectedProps } from "app2/src/connect";
import { AccessRecord } from "app2/src/records/UserRecord";
import { RootState } from "app2/src/reducers";
import { AsyncActions as authAsyncActions } from "app2/src/reducers/auth.actions";
import { AsyncActions as orgAsyncActions } from "app2/src/reducers/org.actions";
import { AsyncActions as accessAsyncActions } from "app2/src/reducers/access.actions";
import { RootDispatchType } from "app2/src/store";
import { Actions as commonactions } from "app2/src/reducers/components/common.actions";
import { AsyncActions as tokenAsyncActions } from "app2/src/reducers/token.actions";
import * as React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { Nullable } from "app2/src/records";
import { LocationStateRecord } from "app2/src/records/LocationState";
import { StoreRegistry } from "app2/src/storeRegistry";
import { StandardPagination } from "../Pagination/Standard";
import { getPaginationByModel, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { QueryParamsRecord } from "app2/src/records/Page";
import { SearchBox } from "./SearchBox";
import { List } from "immutable";
import { OrgAccessListItem } from "./OrgAccessListItem";
import { currentUser } from "app2/src/selectors/user.selectors";

interface OrgAccessListProps {
  $state: any;
}

interface OrgAccessListState {
  showCsvModal: number;
  savedLocationState: Nullable<LocationStateRecord>;
  orgName: string;
}

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: OrgAccessListProps) => {
  return {
    getCurrentOrg: (orgId: number) => dispatch(orgAsyncActions.getOrg(orgId, orgService.orgIncludes)),
    setCurrentAccessUid: (uid: string) => dispatch(authAsyncActions.changeAccessUid(uid)),
    clearSavedLocationState: () => dispatch(commonactions.setSavedLocationState(null)),
    queryAccesses: (userId: number, orgName: string, newQueryParams: QueryParamsRecord) =>
      dispatch(accessAsyncActions.loadAccesses(userId, orgName, newQueryParams)),
    getAuthorizedTokens: (orgId: number) => dispatch(tokenAsyncActions.getAuthorizedTokens(orgId)),
  };
};

const mapStateToProps = (state: RootState, ownProps: OrgAccessListProps) => {
  const modelName = "accesses";
  const queryParamsRecord = queryParams(state, { modelName }).set("per_page", 5);
  const user = currentUser(state);
  return {
    currentUserAccesses: getPaginationByModel(state, {
      path: [modelName, "byId"],
      modelName,
    }) as List<AccessRecord>,
    accessCount: user?.accesses_count,
    userId: user?.id,
    currentSavedLocationState: state.getIn(["components", "common", "savedLocationState"]),
    paginationRecord: pagination(state, { modelName, page: queryParamsRecord.get("page") }),
    queryParamsRecord,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OrgAccessListProps & PropsFromRedux;
class OrgAccessList extends React.Component<Props, OrgAccessListState> {
  public constructor(props) {
    super(props);

    this.state = {
      showCsvModal: 0,
      savedLocationState: props.currentSavedLocationState,
      orgName: "",
    };

    this.setCurrentAccess = this.setCurrentAccess.bind(this);
    this.getAccesses = this.getAccesses.bind(this);
    this.searching = this.searching.bind(this);
  }

  public async setCurrentAccess(access: AccessRecord) {
    const { $state, getCurrentOrg, setCurrentAccessUid, getAuthorizedTokens } = this.props;
    const { savedLocationState } = this.state;

    await setCurrentAccessUid(access.uid);
    await getCurrentOrg(access.org_id);
    getAuthorizedTokens(access.org_id);

    if (savedLocationState) {
      StoreRegistry.get<ng.IRootScopeService>("$rootScope").$apply(() => {
        StoreRegistry.get<ng.ILocationService>("$location").url(
          `${savedLocationState.pathname}?${savedLocationState.search}`,
        );
      });
    } else {
      $state.go("root.job_list");
    }
  }

  public getAccesses(page: number) {
    const { userId, queryParamsRecord } = this.props;
    const newQueryParams = queryParamsRecord.set("page", page);
    this.props.queryAccesses(userId, this.state.orgName, newQueryParams);
  }

  public searching(query: string) {
    this.setState({ orgName: query }, () => this.getAccesses(1));
  }

  public componentDidMount() {
    const { accessCount, $state, clearSavedLocationState } = this.props;
    if (accessCount <= 1) {
      $state.go("root.job_list");
    }

    clearSavedLocationState();
    this.getAccesses(1);
  }

  public render() {
    const { currentUserAccesses, paginationRecord } = this.props;

    return (
      <Card>
        <Card.Header>
          <Row className="justify-content-between align-items-center">
            <Col>
              <Card.Title className="m-0">Select Active Organization</Card.Title>
            </Col>
            <Col>
              <SearchBox query={this.state.orgName} placeholder="Search..." searching={this.searching} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <ListGroup variant="flush" className="org-access-list overflow-y-auto">
            {currentUserAccesses.count() <= 0 && <div>No results</div>}
            {currentUserAccesses.map((access: AccessRecord, index: number) => {
              return <OrgAccessListItem access={access} setCurrentAccess={this.setCurrentAccess} key={index} />;
            })}
          </ListGroup>
        </Card.Body>
        <Card.Footer>
          <StandardPagination metadata={paginationRecord} pageChanged={this.getAccesses} />
        </Card.Footer>
      </Card>
    );
  }
}

export default connector(OrgAccessList);
