import * as userActions from "./user.actions";
import { Actions as JobActions } from "./job.actions";
import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction } from "./Utils";
import { ILoginData, login, logout, refreshToken, resetPassword } from "app2/src/api/user.service";
import { RootState } from ".";
import { RootDispatchType } from "../store";
import { IUser } from "app/src/Models/User";
import { IUserData } from "../records/UserRecord";
import { StoreRegistry } from "../storeRegistry";
import { IAuthService } from "../helpers/Auth.service";

let authService = StoreRegistry.get<IAuthService>("authService");
const getAuthService = () => {
  if (authService) {
    return authService;
  }

  authService = StoreRegistry.get<IAuthService>("authService");
  return authService;
};

export const SET_TOKENS = "@auth/SET_TOKENS";
export const SET_TOKEN = "@auth/SET_TOKEN";
export const SET_AUTHENTICATING = "@auth/SET_AUTHENTICATING";
export const SET_ACCESS_UID = "@auth/SET_ACCESS_UID";
export const SET_REFRESH_TOKEN_EXPIRED = "@auth/SET_REFRESH_TOKEN_EXPIRED";

export type LoginPayload = { email: string; password: string };
export type AuthTokens = { token: string; refreshToken: string };

export const Actions = {
  setTokens: (tokens: AuthTokens) => createAction(SET_TOKENS, tokens),
  setToken: (token: string) => createAction(SET_TOKEN, token),
  setAccessUid: (uid: string) => createAction(SET_ACCESS_UID, { uid }),
  setAuthenticating: (authing: boolean) => createAction(SET_AUTHENTICATING, authing),
  setRefreshTokenExpired: (refreshToken: boolean) => createAction(SET_REFRESH_TOKEN_EXPIRED, refreshToken),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, ActionsUnion<typeof Actions> | userActions.Actions>;

export const AsyncActions = {
  login: (email: string, password: string): ThunkResult<Promise<ILoginData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.setAuthenticating(true));
      return login(email, password)
        .then((tokenData: ILoginData) => {
          dispatchAuthUpdate(dispatch, tokenData);
          dispatch(Actions.setAuthenticating(false));
          dispatch(JobActions.resetJobQuery());
          return tokenData;
        })
        .catch((errors) => {
          dispatch(Actions.setAuthenticating(false));

          return Promise.reject(errors);
        });
    };
  },
  logout: (refreshToken: string): ThunkResult<Promise<void>> => {
    return (dispatch: RootDispatchType) => {
      return logout(refreshToken).then(() => {
        clearAuth(dispatch);
      });
    };
  },
  /**
   * You PROBABLY don't want to be calling this method directly.  This is used by the `Auth.service#refreshToken` method
   * to refresh in the case the deployment is using OCC authentication.  You probably want to be calling the
   * `Auth.service#refreshToken` method as it includes the logic that decides how to refresh (OCC auth vs external IDP auth)
   * and will call the proper method, potentially not this one.
   *
   * @param token refresh token to use to refresh the auth token
   * @returns Promise<void> Should not use return directly
   */
  refreshToken: (token: string): ThunkResult<Promise<ILoginData>> => {
    return (dispatch: RootDispatchType, getState: () => RootState) => {
      return refreshToken(token).then(
        (tokenData: ILoginData) => {
          if (getState().getIn(["auth", "accessUid"])) {
            tokenData.accessUid = getState().getIn(["auth", "accessUid"]);
          }

          dispatchAuthUpdate(dispatch, tokenData);

          return tokenData;
        },
        (errors) => {
          clearAuth(dispatch);
          return Promise.reject(errors);
        },
      );
    };
  },
  resetPassword: (token: string, token_type: string, user: IUser): ThunkResult<Promise<ILoginData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.setAuthenticating(true));
      return resetPassword(token, token_type, user)
        .then((tokenData: ILoginData) => {
          dispatchAuthUpdate(dispatch, tokenData);
          dispatch(Actions.setAuthenticating(false));
          return tokenData;
        })
        .catch((errors) => {
          dispatch(Actions.setAuthenticating(false));

          return Promise.reject(errors);
        });
    };
  },
  fromData: (token: string, user: IUserData, refreshToken: string) => {
    return (dispatch: RootDispatchType) => {
      const tokenData: ILoginData = {
        user,
        token,
        refresh_token: refreshToken,
      };
      dispatchAuthUpdate(dispatch, tokenData);

      return tokenData;
    };
  },
  changeAccessUid: (uid: string) => {
    return (dispatch: RootDispatchType) => {
      const authService = getAuthService();
      authService.setAccessUid(uid);
      dispatch(Actions.setAccessUid(uid));
      dispatch(JobActions.resetJobQuery());
    };
  },
};

const clearAuth = (dispatch: RootDispatchType) => {
  dispatch(Actions.setTokens({ token: null, refreshToken: null }));
  dispatch(Actions.setAccessUid(""));
  dispatch(userActions.Actions.setCurrentUser(null));
};

const dispatchAuthUpdate = (dispatch: RootDispatchType, tokenData: ILoginData): void => {
  const authService = getAuthService();
  authService.setRefreshToken(tokenData.refresh_token);
  authService.setAccessUid(tokenData.accessUid);
  authService.setCurrentUser(tokenData.user.id, tokenData.user.email, tokenData.token);

  dispatch(Actions.setTokens({ token: tokenData.token, refreshToken: tokenData.refresh_token }));
  dispatch(userActions.Actions.setCurrentUser(tokenData.user));
};

export type Actions = ActionsUnion<typeof Actions>;
