import { IVisualization, IVisualizationResource } from "app/src/Models/Visualization";
import { IJob } from "app/src/Models/Job";
import { IToolInfo } from "app/src/Common/ToolInfoService";

class VisualizationCardCtrl {
  public visualization: IVisualization;
  public job: IJob;
  public navDisplay: boolean;
  public onClick: (v: any) => void;
  public onDelete: (v: any) => void;
  public onEdit: (v: any) => void;
  public onEmail: (v: any) => void;
  public visualizationName = "";

  constructor(
    public Visualization: IVisualizationResource,
    public ToolInfo: IToolInfo,
  ) {}

  public $onChanges() {
    if (this.visualization && this.visualization.id) {
      this.visualizationName = this.ToolInfo.tools(this.visualization.kind).title;
    }
  }

  public showVisualization() {
    if (!this.onClick) {
      return;
    }

    this.onClick({ visualization: this.visualization });
  }

  public deleteVisualization() {
    if (!this.onDelete) {
      return;
    }

    this.onDelete({ visualization: this.visualization });
  }

  public editVisualization() {
    if (!this.onEdit) {
      return;
    }

    this.onEdit({ visualization: this.visualization });
  }

  public emailVisualization() {
    if (!this.onEmail) {
      return;
    }

    this.onEmail({ visualization: this.visualization });
  }
}

export class VisualizationCardComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    visualization: "<",
    job: "<",
    navDisplay: "<",
    onClick: "&",
    onDelete: "&",
    onEdit: "&",
    onEmail: "&",
  };
  public templateUrl = "src/Visualization/visualization_card.html";

  constructor() {
    this.controller = VisualizationCardCtrl;
    this.controller.$inject = ["Visualization", "ToolInfo"];
  }
}
