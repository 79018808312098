import * as React from "react";
import { connectFC } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import CrmAuthorize from "../Common/CrmAuthorize";
import * as tokenActions from "app2/src/reducers/token.actions";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import { useState } from "react";
import { NetSuiteModal } from "./NetSuiteModal";
import SettingsModal from "app2/src/components/Integrations/NetSuite/SettingsModal";
import { useDispatch, useSelector } from "react-redux";

export interface NetSuiteProps {
  orgId: number;
  tokenUpdated: () => void;
}

export const NetSuite: React.FunctionComponent<NetSuiteProps> = ({ orgId, tokenUpdated }) => {
  // Hooks
  const dispatch = useDispatch();

  // Local State
  const [triggerOpen, setTriggerOpen] = useState(0);
  const [settingsTriggerOpen, setSettingsTriggerOpen] = useState(0);

  // Selectors
  const authorized = useSelector((state: RootState) => getAuthorization(state, { orgId, integration: "net_suite" }));

  // Methods
  const openModal = () => {
    setTriggerOpen(triggerOpen + 1);
  };

  const openSettings = () => {
    setSettingsTriggerOpen(settingsTriggerOpen + 1);
  };

  const rejectAuthorization = async () => {
    await dispatch(tokenActions.AsyncActions.deleteToken(orgId, "net_suite"));
    tokenUpdated();
  };

  return (
    <div className="form-section">
      <div className="form-section-content">
        <CrmAuthorize
          title={"NetSuite"}
          image={"/assets/images/image-tools/net-suite.851576b8.png"}
          authorized={authorized}
          authorize={openModal}
          rejectAuthorization={rejectAuthorization}
          settings={openSettings}
        />
        <NetSuiteModal orgId={orgId} tokenUpdated={tokenUpdated} triggerOpen={triggerOpen} />
        <SettingsModal triggerOpen={settingsTriggerOpen} />
      </div>
    </div>
  );
};

export default connectFC(NetSuite);
