import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { Map, List } from "immutable";
import { FencingRecord } from "app2/src/records/measurements/Fencing";
import { FenceRecord } from "app2/src/records/measurements/Fence";
import { fencesById, dirtyTree as fenceDirtyTree } from "./fence.selectors";
import { segmentsById } from "./segment.selectors";

export const rootKey = "measurementsFencings";

/**
 * Fetches the byId map for FencingRecord
 *
 * @param {RootState} state
 * @returns {Map<number, FencingRecord>}
 */
export const fencingsById = (state: RootState) => state.getIn([rootKey, "byId"]);

/**
 * Fetches the lastSavedById map for FencingRecord
 *
 * @param {RootState} state
 * @returns {Map<number, FencingRecord>}
 */
export const fencingsLastSavedById = (state: RootState) => state.getIn([rootKey, "lastSavedById"]);

/**
 * Takes a measurementId and returns the associated fenceId
 * @param {RootState} state
 * @param {{measurementId: number }} props
 * @returns {number} fencingId
 */
export const measurementIdFencingId = (state: RootState, props: { measurementId: number }) =>
  state.getIn([rootKey, "byMeasurementId", props.measurementId]);

/**
 * Takes a fencingId and returns the FencingRecord from the byId map
 * @param {RootState} state
 * @param {{fencingId: number}} props
 * @returns FencingRecord
 */
export const fencing = (state: RootState, props: { fencingId: number }) =>
  state.getIn([rootKey, "byId", props.fencingId]);

/**
 * Takes a measurementId and returns the normalize FencingRecords (fenceIds, not fences)
 *
 * @param {RootState} state
 * @param {{ measurementId: number }} props
 * @returns {FencingRecord} fencing
 */
export const measurementFencing = createSelector([measurementIdFencingId, fencingsById], (byMeasurementId, byId) => {
  return byId.get(byMeasurementId);
});

/**
 * Takes a fencingId and returns the List of fenceIds associated with that FencingRecord
 *
 * @param {RootState} state
 * @param {{fencingId: number}} props
 * @returns {List<number>} fenceIds
 */
export const fencingFenceIds = (state: RootState, props: { fencingId: number }) =>
  state.getIn([rootKey, "byId", props.fencingId, "fenceIds"], List());

/**
 * Takes a fencingId and returns the List of FenceRecords associated with that FencingRecord
 * Pulls the FenceRecords from the normalized state
 *
 * @param {RootState} state
 * @param {{fencingId: number}} props
 * @returns {List<FenceRecord>} fences
 */
export const fencingFences = createSelector(
  fencingFenceIds,
  fencesById,
  (fenceIds: List<number>, fById: Map<number, FenceRecord>) => {
    return fenceIds.map((id) => fById.get(id));
  },
);

/**
 * Takes a measurementId and builds the full Fencing Tree (denormalized)
 * Fencing -> Fences -> Segments
 *
 * @param {RootState} state
 * @param {{measurementId: number}} params
 * @returns {FencingRecord} fencing - fully hydrated down the tree
 */
export const measurementIdFullFencing = createSelector(
  [measurementFencing, fencesById, segmentsById],
  (fencing, fencesById, segmentsById) => {
    if (_.isNullOrUndefined(fencing)) {
      return fencing;
    }
    return fencing.update("fences", () => {
      let fences = List();
      fencing.fenceIds.forEach((id) => {
        let fence = fencesById.get(id);

        fence = fence.update("segments", () => {
          let segments = List();

          fence.segmentIds.forEach((sId) => {
            segments = segments.push(segmentsById.get(sId));
          });

          return segments;
        });

        fences = fences.push(fence);
      });
      return fences;
    }) as FencingRecord;
  },
);

/**
 * Takes a fencingId and gets the FenceRecord from the lastSavedBy Map
 *
 * @param {RootState} state
 * @param {{fencingId: number}} params
 * @returns {FencingRecord} fence
 */
export const fencingLastSavedById = createSelector(
  fencingsLastSavedById,
  (_, props: { fencingId: number }) => props,
  (byId, props) => {
    return byId.get(props.fencingId);
  },
);

/**
 * Takes a fencingId and checks to see if the FencingRecord is dirty
 * This does NOT look down the tree to Fences/Segments.
 *
 * @param {RootState} state
 * @param {{ fencingId: number }} params
 * @returns {boolean} dirty
 */
export const dirty = createSelector(
  fencing,
  fencingLastSavedById,
  (fencing: FencingRecord, lastSaved: FencingRecord) => {
    if (!fencing) return false;

    return !fencing.equals(lastSaved);
  },
);

/**
 * Takes a fencingId and checks to see if the FencingRecord, or it's tree, is dirty
 * This DOES look down the tree to Fences/Segments and anything dirty returns true
 *
 * @param {RootState} state
 * @param {{ fencingId: number }} params
 * @returns {boolean} dirty
 */
export const dirtyTree = createSelector(
  dirty,
  fencingFenceIds,
  (state: RootState, _) => ({ state }),
  (dirty: boolean, fenceIds: List<number>, stateProps: { state: RootState }) => {
    if (dirty) return dirty;

    if (fenceIds.size <= 0) return false;

    const fenceDirty = fenceIds.every((fenceId) => !fenceDirtyTree(stateProps.state, { fenceId }));

    return !fenceDirty;
  },
);

/**
 * Takes a measurementId and checks to see if the associated FencingRecord, or it's tree, is dirty
 * This DOES look down the tree to Fences/Segments and anything dirty returns true
 *
 * @param {RootState} state
 * @param {{ fencingId: number }} params
 * @returns {boolean} dirty
 */
export const measurementFencingDirtyTree = createSelector(
  measurementIdFencingId,
  (state, _) => ({ state }),
  (fencingId: number, stateProps: { state: RootState }) => {
    return dirtyTree(stateProps.state, { fencingId });
  },
);
