import { Record, List } from "immutable";
import { AbleProp } from "app/src/Models/Product";
import { IEstimateLineItemOptionData } from "./EstimateLineItemOption";
import { IDocumentData } from "./Document";
import { IImageData } from "./Image";
import { IOpeningEstimationData } from "./OpeningEstimation";
import { estimateLineItem } from "app2/src/selectors/estimateLineItem.selectors";

export const fromJSON = (json: Partial<IEstimateLineItemData>): EstimateLineItemRecord => {
  const record: IEstimateLineItemRecord = { ...(json as any) };

  if (json.images) {
    record.image_ids = List(json.images.map((i) => i.id));
  }

  if (json.documents) {
    record.document_ids = List(json.documents.map((d) => d.id));
  }

  if (json.options) {
    record.option_ids = List(json.options.map((o) => o.id));
  }

  if (json.opening_estimations) {
    record.opening_estimation_ids = List(json.opening_estimations.map((oe) => oe.id));
  }

  return new EstimateLineItemRecord(record);
};

export const splitName = (lineItem: EstimateLineItemRecord): string[] => {
  if (!lineItem.name) {
    return [""];
  }
  const parts = lineItem.name.split(">");
  _.each(parts, (part: string, index: number) => {
    parts[index] = part.trim();
  });

  return parts;
};

export const isDiscountable = (estimateLineItem: IEstimateLineItemDiscountable): boolean => {
  return estimateLineItem.discountable !== "no";
};

export const isMarkupable = (estimateLineItem: IEstimateLineItemMarkupable): boolean => {
  return estimateLineItem.markupable !== "no";
};

export interface IEstimateLineItemDiscountable {
  discountable: string;
}

export interface IEstimateLineItemMarkupable {
  markupable: string;
}
export interface IEstimateLineItemData {
  id: number;
  product_uuid: string;
  product_id: number;
  estimate_group_id: number;
  sort_order: number;
  name: string;
  description: string;
  cost: number;
  msrp: number;
  price: number;
  base_product_price: number;
  base_labor_price: number;
  product_price: number;
  labor_price: number;
  visibility: string;
  quantity: number;
  discountable: AbleProp;
  markupable: AbleProp;
  uom: string;
  ext_price: number;
  created_at: Date;
  updated_at: Date;
  _destroy: boolean;
  options: IEstimateLineItemOptionData[];
  documents: IDocumentData[];
  images: IImageData[];
  opening_estimations: IOpeningEstimationData[];
  product_image_urls: string[];
  estimate_doc_ids: number[];
  contract_doc_ids: number[];
  newly_added: boolean;
  editing: boolean;
}

export interface IEstimateLineItemRecord {
  id: number;
  product_uuid: string;
  product_id: number;
  estimate_group_id: number;
  sort_order: number;
  name: string;
  description: string;
  cost: number;
  msrp: number;
  price: number;
  base_product_price: number;
  base_labor_price: number;
  product_price: number;
  labor_price: number;
  visibility: string;
  quantity: number;
  discountable: AbleProp;
  markupable: AbleProp;
  uom: string;
  ext_price: number;
  created_at: Date;
  updated_at: Date;
  _destroy: boolean;
  option_ids: List<number>;
  document_ids: List<number>;
  image_ids: List<number>;
  opening_estimation_ids: List<number>;
  product_image_urls: List<string>;
  estimate_doc_ids: List<number>;
  contract_doc_ids: List<number>;
  newly_added: boolean;
  editing: boolean;
}

const defaultEstimateLineItemProps: IEstimateLineItemRecord = {
  id: 0,
  product_uuid: "",
  product_id: 0,
  estimate_group_id: 0,
  sort_order: 0,
  name: "",
  description: "",
  cost: 0,
  msrp: 0,
  price: 0,
  base_product_price: 0,
  base_labor_price: 0,
  product_price: 0,
  labor_price: 0,
  visibility: "",
  quantity: 0,
  discountable: "yes",
  markupable: "yes",
  uom: "",
  ext_price: 0,
  created_at: new Date(),
  updated_at: new Date(),
  _destroy: null,
  option_ids: List<number>(),
  document_ids: List<number>(),
  image_ids: List<number>(),
  opening_estimation_ids: List<number>(),
  product_image_urls: List<string>(),
  estimate_doc_ids: List<number>(),
  contract_doc_ids: List<number>(),
  newly_added: false,
  editing: false,
};

export class EstimateLineItemRecord extends Record(defaultEstimateLineItemProps) implements IEstimateLineItemRecord {}
