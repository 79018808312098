import * as React from "react";
import { FormControl, Select } from "@tberrysoln/rsf-form";
import { required } from "../../../helpers/FinalFormValidator";

export const AuthorizationForm: React.FC = () => {
  return (
    <>
      <Select label="Type" name="data.jn_type" validate={required}>
        {["job", "contact"].map((type, idx) => (
          <option key={idx} value={type}>
            {_.toTitleCase(type)}
          </option>
        ))}
      </Select>
      <FormControl label="API Key" name="data.api_key" validate={required} />
    </>
  );
};
