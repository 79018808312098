import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { Toast } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { List } from "immutable";

const mapStateToProps = (state: RootState, ownProps: NotiferProps) => {
  return {
    notifierMessages: state.getIn(["components", "common", "notifierMessages"], List()),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: NotiferProps) => {
  return {
    closeNotifierMessage: (idx: number) => dispatch(commonActions.Actions.notifierRemoveMessage(idx)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface NotiferProps {}

interface NotiferState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & NotiferProps;

class Notifer extends React.Component<Props, NotiferState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { notifierMessages, closeNotifierMessage } = this.props;
    return (
      <div className="toast-container">
        <div className="toast-stack">
          <React.Fragment>
            {notifierMessages.toArray().map((message: string, idx: number) => {
              setTimeout(() => {
                closeNotifierMessage(idx);
              }, 5000);
              return (
                <Toast key={idx}>
                  <Toast.Body>
                    <FontAwesome name="info-circle" id="info-icon" />
                    &nbsp;&nbsp;
                    {message}
                  </Toast.Body>
                </Toast>
              );
            })}
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default connector(Notifer);
