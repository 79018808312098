import * as angulartics from "angulartics";

export interface IEventingFactory {
  init(props: any): null;
  trackEvent(action, props);
}

class EventingFactory {
  public props: any = {};

  constructor(protected $analytics: angulartics.IAnalyticsService) {}

  public init(props: any) {
    this.props = props || {};
  }

  public trackEvent(action: string, props: any) {
    let usedProps = JSON.parse(JSON.stringify(this.props));
    if (props) {
      usedProps = angular.extend(usedProps, props);
    }
    this.$analytics.eventTrack(action, usedProps);
  }
}

const factory = ($analytics: angulartics.IAnalyticsService) => {
  return new EventingFactory($analytics);
};

factory.$inject = ["$analytics"];

export default factory;
