import * as React from "react";
import { Form as FinalForm, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { fromJS } from "immutable";
import { FormState } from "final-form";

export interface RsfFinalFormProps {
  initialValues: any;
  formUpdate: (values: any) => void;
  onSubmit: (formData: any) => void;
  children: (formProps: any) => React.ReactNode;
}

interface RsfFinalFormState {
  lastUpdatedValues: any;
}

export default class RsfFinalForm extends React.Component<RsfFinalFormProps, RsfFinalFormState> {
  protected debouncedUpdate: (state: FormState<any, any>) => void;

  constructor(props: RsfFinalFormProps) {
    super(props);
    this.state = { lastUpdatedValues: fromJS(props.initialValues) };

    this.formUpdate = this.formUpdate.bind(this);
    // Debounce formUpdate
    this.debouncedUpdate = _.debounce(this.formUpdate, 300);
  }

  public formUpdate(state: FormState<any, any>): void {
    const { formUpdate } = this.props;
    const { lastUpdatedValues } = this.state;
    const { active, values } = state;

    // Only allow events through that are active and the values have changed since last update
    if (_.isNullOrUndefined(active) || fromJS(values).equals(lastUpdatedValues)) {
      return;
    }

    formUpdate(values);
    this.setState({ lastUpdatedValues: fromJS(values) });
  }

  public render(): React.ReactNode {
    const { onSubmit, children, initialValues } = this.props;

    // https://github.com/final-form/react-final-form/issues/246#issuecomment-466029308
    // Keeping keepDirtyOnReinitialize = true, but need initialValuesEqual = () => true in order to prevent FieldArray bug
    return (
      <FinalForm
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        keepDirtyOnReinitialize
        initialValuesEqual={() => true}
        initialValues={initialValues}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <FormSpy subscription={{ active: true, values: true }} onChange={this.debouncedUpdate} />
            {children(formProps)}
          </form>
        )}
      </FinalForm>
    );
  }
}
