import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentJobId, jobAssignedUserId } from "app2/src/selectors/job.selectors";
import { RootState } from "app2/src/reducers";
import { Col, Form, Row } from "react-bootstrap";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { AsyncActions } from "app2/src/reducers/access.actions";
import { accessConfig } from "app2/src/selectors/access.selectors";
import { settingsConfig, currentOrgId } from "app2/src/selectors/org.selectors";
import { AsyncActions as estimateAsyncActions } from "app2/src/reducers/estimate.actions";
import {
  calculateEstimateCost,
  isEstimateLoading as isEstimateLoadingSelector,
} from "app2/src/selectors/estimate.selectors";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Parser } from "hot-formula-parser";

export interface FormulaProps {
  estimateTotal: number;
  estimateId: number;
}

export const Formula: React.FC<FormulaProps> = ({ estimateTotal, estimateId }) => {
  // Hooks
  const dispatch = useDispatch();
  const formula = useSelector((state: RootState) =>
    settingsConfig(state, { orgId: currentOrgId(state), path: ["estimator", "commission_calculator", "formula"] }),
  );
  const assignedUserId = useSelector((state: RootState) => jobAssignedUserId(state, { jobId: currentJobId(state) }));
  const overhead = useSelector((state: RootState) =>
    accessConfig(state, {
      userId: assignedUserId,
      orgId: currentOrgId(state),
      path: ["commission_calculator", "overhead"],
      notSet: 0,
    }),
  );
  const commissionRate = useSelector((state: RootState) =>
    accessConfig(state, {
      userId: assignedUserId,
      orgId: currentOrgId(state),
      path: ["commission_calculator", "commission_rate"],
      notSet: 0,
    }),
  );
  const cost = useSelector((state: RootState) => calculateEstimateCost(state, estimateId));

  // Local state
  const [formulaResult, setFormulaResult] = React.useState("");

  // Lifecycle
  React.useEffect(() => {
    if (assignedUserId) {
      dispatch(AsyncActions.getAccess(assignedUserId));
    }
  }, [assignedUserId, dispatch]);

  React.useEffect(() => {
    parseFormula();
  }, [formula, estimateTotal, cost, overhead, commissionRate]);

  // Methods
  const parseFormula = () => {
    if (!formula) return;

    const parser = new Parser();
    parser.setVariable("sale_price", estimateTotal);
    parser.setVariable("cost", cost);
    parser.setVariable("overhead_rate", overhead);
    parser.setVariable("commission_rate", commissionRate);
    const parserResult = parser.parse(formula).result;

    if (parserResult) setFormulaResult(CurrencyFormat(parserResult));
  };

  const formulaWithValues = () => {
    return formula
      .replace("sale_price", CurrencyFormat(estimateTotal))
      .replace("cost", CurrencyFormat(cost))
      .replace("overhead_rate", overhead.toString())
      .replace("commission_rate", commissionRate.toString());
  };

  const prettyFormula = () => {
    return formula
      .replace("sale_price", "Sale Price")
      .replace("cost", "Cost")
      .replace("overhead_rate", "Overhead Rate")
      .replace("commission_rate", "Commission Rate");
  };

  if (!commissionRate)
    return (
      <p className="text-center mt-4">Commission Rate is set to 0. Please verify the job's assigned user's settings</p>
    );

  return (
    <Row className="pl-lg-4">
      <Col lg={8}>
        <Form>
          <Form.Group>
            <Form.Label htmlFor="formula">Formula</Form.Label>
            <Form.Control type="text" value={prettyFormula()} disabled id="formula" />
          </Form.Group>
        </Form>
        <div className="mt-2 text-monospace">
          <span>{formulaWithValues()} = </span>
          <span className="font-weight-bold">{formulaResult}</span>
        </div>
      </Col>
    </Row>
  );
};
