import * as React from "react";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Field } from "react-final-form";
import { required } from "app2/src/helpers/FinalFormValidator";
import { Condition } from "app2/src/components/RsfForms/Condition";
import { RsfCheckboxGroup } from "app2/src/components/RsfForms/RsfCheckboxGroup";
import track from "react-tracking";
import { FieldArray } from "react-final-form-arrays";
import * as FontAwesome from "react-fontawesome";
import { BootstrapInputAdapter } from "app2/src/components/RsfForms/BootstrapInputAdapter";

export interface FilterFormProps {
  mutators: any;
}

interface FilterFormState {}

@track(() => {
  return {
    component: "FilterForm",
  };
})
export default class FilterForm extends React.Component<FilterFormProps, FilterFormState> {
  constructor(props: FilterFormProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const { mutators } = this.props;
    return (
      <>
        <RsfCheckboxGroup label="Enable Filters" name="data.filters_enable" />
        <Condition when="data.filters_enable" is={true}>
          <p>
            The filter allows the integration to filter opportunties or leads based on a column and valid values. For
            example, an organization may only want to pull opportunties that have a LeadSource of 'Web' or 'Phone'
          </p>
          <Row>
            <Col>
              <Button
                className="pull-right"
                onClick={() => mutators.push("data.filters", { column: "", valid_values: [""] })}>
                Add Filter
              </Button>
            </Col>
          </Row>

          <FieldArray name="data.filters">
            {({ fields }) =>
              fields.map((name, index) => (
                <div key={name}>
                  <Row className="pb-0">
                    <Col>
                      <Form.Label className="p-copy">Column</Form.Label>
                    </Col>
                    <Col>
                      <Form.Label className="p-copy">Valid Values</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputGroup>
                        <InputGroup.Append>
                          <Button onClick={() => fields.remove(index)} title="Remove Filter">
                            <FontAwesome name="trash" />
                          </Button>
                        </InputGroup.Append>
                        <Field
                          name={`${name}.column`}
                          aria-label="Column"
                          component={BootstrapInputAdapter}
                          placeholder="Column"
                          validate={required}
                        />
                      </InputGroup>
                    </Col>
                    <Col>
                      <FieldArray name={`${name}.valid_values`}>
                        {({ fields: validValuesFields }) =>
                          validValuesFields.map((validValueName, validValueIndex) => (
                            <Row key={validValueName}>
                              <Col>
                                <InputGroup>
                                  <Field
                                    name={`${validValueName}`}
                                    component={BootstrapInputAdapter}
                                    aria-label="Valid Value"
                                    placeholder="Valid Value"
                                    validate={required}
                                    append={
                                      <InputGroup.Append>
                                        {validValuesFields.length === 1 ? null : (
                                          <Button
                                            onClick={() => validValuesFields.remove(validValueIndex)}
                                            title="Remove Valid Value">
                                            <FontAwesome name="trash" />
                                          </Button>
                                        )}
                                      </InputGroup.Append>
                                    }
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          ))
                        }
                      </FieldArray>
                      <Button
                        className="pull-right"
                        title="Add Valid Value"
                        onClick={() => mutators.push(`data.filters[${index}].valid_values`, "")}>
                        <FontAwesome name="plus" />
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))
            }
          </FieldArray>
        </Condition>
      </>
    );
  }
}
