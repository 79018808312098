import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { DiscountRecord, DiscountKinds, formatKind } from "app2/src/records/DiscountRecord";
import { DecimalInputComponent } from "../../Common/DecimalInputComponent";

export interface EditorProps {
  discount: DiscountRecord;
  update: (discount: DiscountRecord) => void;
  cancel: () => void;
}

export interface EditorState {
  discount: DiscountRecord;
}

export class Editor extends React.Component<EditorProps, EditorState> {
  constructor(props: EditorProps) {
    super(props);

    this.state = {
      discount: props.discount,
    };

    this.handleDiscountInput = this.handleDiscountInput.bind(this);
    this.canSave = this.canSave.bind(this);
  }

  public canSave(): boolean {
    return !_.isEqual(this.props.discount, this.state.discount);
  }

  public handleDiscountInput(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const value = e.target.value;

    const name = e.target.name.split(".");

    let parsedValue: any;
    switch (_.last(name)) {
      case "amount":
      case "limit":
        parsedValue = parseFloat(value);
        if (Number.isNaN(parsedValue)) {
          parsedValue = 0;
        }
        if (parsedValue < 0) {
          parsedValue = 0;
        }
        break;
      default:
        parsedValue = value;
        break;
    }

    this.setState((prevState) => {
      return {
        discount: prevState.discount.setIn(name, parsedValue),
      };
    });
  }

  public render() {
    const { update, cancel } = this.props;
    const { discount } = this.state;

    return (
      <div className="form-section">
        <Row>
          <Col md={1}></Col>
          <Col md={2}>
            <input
              className="form-control"
              type="text"
              name="name"
              value={discount.name}
              onChange={this.handleDiscountInput}
            />
          </Col>
          <Col md={2}>
            <input
              className="form-control"
              type="text"
              name="description"
              value={discount.description}
              onChange={this.handleDiscountInput}
            />
          </Col>
          <Col md={2}>
            <select
              className="form-control"
              name="breakdown.kind"
              onChange={this.handleDiscountInput}
              value={discount.breakdown.kind}
              required>
              {DiscountKinds.map((kind, idx) => {
                return (
                  <option key={idx} value={kind}>
                    {formatKind(kind)}
                  </option>
                );
              })}
            </select>
          </Col>
          <Col md={2}>
            <DecimalInputComponent
              name="breakdown.amount"
              type="number"
              min="0"
              max="9999999"
              value={discount.breakdown.amount}
              onNumberChange={(value, name) => this.handleDiscountInput({ target: { name, value } } as any)}
            />
            <br />
            {discount.breakdown.kind === "percent_limit" ? <br /> : null}
            {discount.breakdown.kind === "percent_limit" ? (
              <DecimalInputComponent
                className="form-control"
                type="number"
                name="breakdown.limit"
                min="0"
                max="9999999"
                value={discount.breakdown.limit}
                onNumberChange={(value, name) => this.handleDiscountInput({ target: { name, value } } as any)}
              />
            ) : null}
          </Col>
          <Col md={3}>
            <Button type="button" variant="cancel" className="pull-right" onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="button"
              variant="save"
              className="pull-right"
              onClick={() => update(discount)}
              disabled={!this.canSave()}>
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
