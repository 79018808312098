import { FormControl, RsfForm, Select } from "@tberrysoln/rsf-form";
import { connectFC } from "app2/src/connect";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as orgActions from "app2/src/reducers/org.actions";
import { useSelector } from "react-redux";
import { currentOrgId, getTitle } from "app2/src/selectors/org.selectors";

export interface EstimatorPreferencesProps {
  updatePreferencesConfig: (key: string, value: any) => void;
}

const EstimatorPreferences: React.FC<EstimatorPreferencesProps> = ({ updatePreferencesConfig }) => {
  const orgId = useSelector(currentOrgId);
  const contractTitle = useSelector((state) => getTitle(state, { documentType: "contract" }));

  const handleChange = (event) => {
    const { name, value } = event;
    updatePreferencesConfig(name, value);
    return orgActions.Actions.updateForm(event);
  };

  return (
    <div className="form-section">
      <div className="form-section-heading">
        <h3>Pricing Related Preferences</h3>
      </div>
      <div className="form-section-content">
        <Row>
          <Col md="12">
            <RsfForm
              rootPath={["orgs", "orgsById", orgId, "preferences", "config"]}
              updateFormReducer={handleChange}
              onSubmit={() => null}>
              <Row>
                <Col md="6">
                  <Select label="Show Detailed Pricing" name="estimator.show_detailed_pricing">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                    <option value="customer_main_only">Presentation Mode - Included Estimate Groups Only</option>
                    <option value="customer_option_only">Presentation Mode - Not Included Estimate Groups Only</option>
                    <option value="company_main_only">Edit Mode - Included Estimate Groups Only</option>
                    <option value="company_option_only">Edit Mode - Not Included Estimate Groups Only</option>
                    <option value="company_customer_main">
                      Edit and Presentation Mode - Included Estimate Groups Only
                    </option>
                    <option value="company_customer_option">
                      Edit and Presentation Mode - Not Included Estimate Groups Only
                    </option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select label="Show Detailed Unit Price" name="estimator.show_detailed_unit_price">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                    <option value="customer_main_only">Presentation Mode - Included Estimate Groups Only</option>
                    <option value="customer_option_only">Presentation Mode - Not Included Estimate Groups Only</option>
                    <option value="company_main_only">Edit Mode - Included Estimate Groups Only</option>
                    <option value="company_option_only">Edit Mode - Not Included Estimate Groups Only</option>
                    <option value="company_customer_main">
                      Edit and Presentation Mode - Included Estimate Groups Only
                    </option>
                    <option value="company_customer_option">
                      Edit and Presentation Mode - Not Included Estimate Groups Only
                    </option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select label="Show Detailed Measurements" name="estimator.show_detailed_measurements">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select label="Show Option Total" name="estimator.show_option_total">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select label="Show Estimated Dates" name="estimator.show_estimated_dates">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select label={`Show Option Group on ${contractTitle}`} name="estimator.show_opt_group_on_contract">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select label="Show Pricing in Line Item Editor" name="estimator.show_pricing.line_item_editor">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select label="Show Total Section of Estimator" name="estimator.show_pricing.total_section">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select
                    label="Show Pricing from Payment Term Display in Estimator"
                    name="estimator.show_pricing.payment_term_display">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select
                    label="Show Pricing from Payment Term Editor in Estimator"
                    name="estimator.show_pricing.payment_term_editor">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                  </Select>
                </Col>
                <Col md="6">
                  <Select
                    label="Show Discount and Payment Estimator"
                    name="estimator.show_pricing.discount_and_payment_estimator">
                    <option value="never">Never</option>
                    <option value="always">Always</option>
                    <option value="customer_only">Presentation Mode Only</option>
                    <option value="company_only">Edit Mode Only</option>
                  </Select>
                </Col>
              </Row>
            </RsfForm>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default connectFC(EstimatorPreferences);
