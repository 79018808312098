import { FileType } from "app2/src/components/Folders";
import { Map, Record } from "immutable";

export const fromJSON = (json: Partial<IFolderData>): FolderRecord => {
  const recordData: IFolderRecord = { ...(json as any) };

  if (json.createParams) {
    recordData.createParams = new FolderCreateRecord(json.createParams);
  }

  return new FolderRecord(recordData);
};

export interface IFolderCreateRecord {
  folderable_id: number;
  folderable_type: string;
  parent_name: string;
}

const defaultFolderCreateRecord: IFolderCreateRecord = {
  folderable_id: 0,
  folderable_type: "",
  parent_name: "",
};

export type RootFolderNames = "Presentations";
export const RootFolderNamesMap = Map<FileType, RootFolderNames>({
  presentations: "Presentations",
});

export class FolderCreateRecord extends Record(defaultFolderCreateRecord) implements IFolderCreateRecord {}

export interface IFolderData {
  id: number;
  name: string;
  sort_order: number;
  folderable_id: number;
  folderable_type: string;
  parent_id: number;
  loading?: boolean;
  archived?: boolean;
  createParams?: IFolderCreateRecord;

  created_at: Date;
  updated_at: Date;
}

export interface IFolderRecord {
  id: number;
  name: string;
  sort_order: number;
  folderable_id: number;
  folderable_type: string;
  parent_id: number;
  loading: boolean;
  archived: boolean;
  createParams: FolderCreateRecord;

  created_at: Date;
  updated_at: Date;
}

const defaultFolderProps: IFolderRecord = {
  id: 0,
  name: "",
  sort_order: null,
  folderable_id: 0,
  folderable_type: "",
  parent_id: 0,
  loading: true,
  archived: false,
  createParams: new FolderCreateRecord({}),

  created_at: new Date(),
  updated_at: new Date(),
};

export class FolderRecord extends Record(defaultFolderProps) implements IFolderRecord {}
