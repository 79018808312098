import * as React from "react";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MetaPaginationRecord } from "app2/src/records/MetaPagination";
import { queryParamsFromJSON } from "app2/src/records/Page";
import { RootState } from "app2/src/reducers";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { pagination, getPaginationByModel } from "app2/src/selectors/pagination.selectors";
import { List } from "immutable";
import * as webhookActions from "app2/src/reducers/webhook.actions";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { HistoryItem } from "./HistoryItem";
import { WebhookEventRecord } from "app2/src/records/WebhookEvent";

export interface IHistoryProps {
  webhookId: string;
}

export const History: React.FC<IHistoryProps> = ({ webhookId }) => {
  const modelName = "webhook_event";
  const dispatch = useDispatch();
  const currentPage = React.useRef(1);

  // selectors
  const orgId = useSelector<RootState, number>(currentOrgId);
  const paginationRecord = useSelector<RootState, MetaPaginationRecord>((state) =>
    pagination(state, { modelName, page: currentPage.current }),
  );
  const page = useSelector<RootState, List<WebhookEventRecord>>(
    (state) =>
      getPaginationByModel(state, {
        path: ["webhooks", "eventsById"],
        modelName,
      }) as List<WebhookEventRecord>,
  );

  // lifecycle
  React.useEffect(() => {
    if (webhookId !== "-1") queryWebhookEventPage(1);
    return () => dispatch(paginationActions.Actions.clearPage("webhook_event"));
  }, [webhookId]);

  // methods
  const queryWebhookEventPage = (page: number) => {
    currentPage.current = page;
    dispatch(
      webhookActions.AsyncActions.listWebhookEvents(
        webhookId,
        orgId,
        queryParamsFromJSON({
          page: page,
          per_page: 10,
        }),
      ),
    );
  };
  return (
    <>
      <Row>
        <Col md={6}>
          <h3 className="admin-title">Log History</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Date/Time</th>
                <th>Event</th>
                <th>Webhook URL</th>
                <th>Response</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {page.map((event, idx) => (
                <HistoryItem key={idx} event={event} />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={6}>
                  <StandardPagination metadata={paginationRecord} pageChanged={queryWebhookEventPage} />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </>
  );
};
