import { IUser } from "../Models/User";
import { IOrg } from "../Models/Org";
import { IReportResource } from "../Models/Report";
import { IEagleviewOrder, IEagleviewProduct, IEagleviewProductReturn } from "./EagleviewReport";
import { IJob } from "../Models/Job";
import { IAddress } from "../Models/Address";
import { FlashLevels, IFlash } from "../Common/FlashService";
import { ISession } from "../Common/SessionService";
import { IPretty } from "../Common/PrettyNameService";
import { AbstractWizardController } from "../Common/AbstractWizardController";

class EagleviewOrderingModalCtrl extends AbstractWizardController implements ng.IComponentController {
  public spinnerPromise: ng.IPromise<any>;
  public resolve: any;
  public importExisting = false;
  public close: () => null;
  public dismiss: () => null;

  public order: IEagleviewOrder = <IEagleviewOrder>{
    add_on_products: [],
  };
  public products: IEagleviewProductReturn;
  public org: IOrg;
  public job: IJob;
  public user: IUser;

  public static $inject = ["Report", "US_STATES", "$scope", "Flash", "Session", "Pretty", "COUNTRIES"];
  constructor(
    public Report: IReportResource,
    public US_STATES: string[],
    public $scope: ng.IScope,
    protected Flash: IFlash,
    protected Session: ISession,
    protected Pretty: IPretty,
    public COUNTRIES: string[]
  ) {
    super();
  }

  public $onInit() {
    this.user = this.resolve.user;
    this.org = this.resolve.org;
    this.job = this.resolve.job;

    this.order.insured_name = this.job.customer_name;
    this.order.address = this.job.address.clone();
    this.order.measurement_request_type = 2;

    this.products = (this.Report.pricing({ provider: "eagleview", kind: "roofing" }) as any) as IEagleviewProductReturn;
  }

  public productChanged() {
    this.order.delivery_product = _.first(this.order.product.delivery_products);
    this.order.add_on_products = [];
  }

  public isStep0Valid() {
    return true;
  }

  public isStep1Valid() {
    return !!this.order.product && !!this.order.delivery_product;
  }

  public isStep2Valid() {
    return this.order.address.isValid();
  }

  public isStep3Valid() {
    return !!this.order.insured_name;
  }

  public toggleSelection(product: IEagleviewProduct) {
    const idx = _.findIndex(this.order.add_on_products, (p: IEagleviewProduct) => {
      return p.product_id === product.product_id;
    });
    if (idx >= 0) {
      this.order.add_on_products = _.select(
        this.order.add_on_products,
        (p: IEagleviewProduct) => p.product_id !== product.product_id
      );
    } else {
      this.order.add_on_products.push(product);
    }
  }

  public selected(product: IEagleviewProduct): boolean {
    return _.any(this.order.add_on_products, (p: IEagleviewProduct) => {
      return p.product_id === product.product_id;
    });
  }

  public back() {
    if (this.activeStep > 3) {
      this.activeStep = 0;
    } else {
      super.back();
    }
  }

  public cancel() {
    if (this.importExisting) {
      this.importExisting = false;
      return;
    }

    if (this.dismiss) {
      this.dismiss();
    }
  }

  public submitOrder() {
    const orderData = {
      report_addresses: [this.addressToEv(this.order.address)],
      primary_product_id: this.order.product.product_id,
      delivery_product_id: this.order.delivery_product.product_id,
      add_on_product_ids: _.pluck(this.order.add_on_products, "product_id"),
      measurement_instruction_type: this.order.measurement_request_type,
      claim_number: this.order.claim_number,
      claim_info: this.order.claim_info,
      po_number: this.order.po_number,
      comments: this.order.comments,
      cat_id: this.order.cat_id,
      changes_in_last_4_years: this.order.changes_in_last_4_years,
      insured_name: this.order.insured_name,
    };

    const report = this.Report.fromJSON({
      job_id: this.job.id,
      order_data: orderData,
      provider: "eagleview",
      kind: "roofing",
      user_id: this.Session.currentUser.id,
    });

    this.spinnerPromise = report
      .$create()
      .then(() => {
        this.Flash.addMessage(
          FlashLevels.success,
          this.Pretty.name["eagleview_roofing"] + " report successfully ordered!"
        );
        this.close();
      })
      .catch((response) => {
        let errors = "";
        if (response.data["order_data"]) {
          errors = response.data["order_data"].join("  ") + "  ";
        }

        const message = `There was a problem ordering your report. ${errors}If the problem persists, please contact support.`;
        this.Flash.addMessage(FlashLevels.danger, message);
      });
  }

  public locationUpdated(address: IAddress) {
    this.job.address.lat = Math.round(address.lat * Math.pow(10, 6)) / Math.pow(10, 6);
    this.job.address.lon = Math.round(address.lon * Math.pow(10, 6)) / Math.pow(10, 6);
    this.$scope.$digest();
  }

  protected addressToEv(addr: IAddress) {
    return {
      address: `${addr.line_1} ${addr.line_2}`.trim(),
      city: addr.city,
      state: addr.state,
      zip: addr.postal_code,
      country: addr.country,
      latitude: addr.lat,
      longitude: addr.lon,
      address_type: 4,
    };
  }
}

export class EagleviewOrderingModalComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };
  public templateUrl = "src/Reports/eagleview_ordering_modal_component.html";

  constructor() {
    this.controller = EagleviewOrderingModalCtrl;
  }
}
