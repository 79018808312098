import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { List } from "immutable";
import { PresentationTemplateRecord, fromJSON } from "app2/src/records/PresentationTemplate";
import { SlideRecord } from "app2/src/records/Slide";
import { presentationTemplateService } from "app2/src/api/presentationTemplate.service";
import { RootDispatchType } from "../store";

// ORG
export const FETCH_PRESENTATION_TEMPLATES = "@presentationTemplates/FETCH_PRESENTATION_TEMPLATES";
export const FETCH_PRESENTATION_TEMPLATE = "@presentationTemplates/FETCH_PRESENTATION_TEMPLATE";
export const RECEIVE_PRESENTATION_TEMPLATES = "@presentationTemplates/RECEIVE_PRESENTATION_TEMPLATES";
export const RECEIVE_PRESENTATION_TEMPLATES_ERROR = "@presentationTemplates/RECEIVE_PRESENTATION_TEMPLATES_ERROR";
export const RECEIVE_PRESENTATION_TEMPLATE = "@presentationTemplates/RECEIVE_PRESENTATION_TEMPLATE";
export const RECEIVE_PRESENTATION_TEMPLATE_ERROR = "@presentationTemplates/RECEIVE_PRESENTATION_TEMPLATE_ERROR";
export const CREATE_PRESENTATION_TEMPLATE = "@presentationTemplates/CREATE_PRESENTATION_TEMPLATE";
export const SAVE_PRESENTATION_TEMPLATE = "@presentationTemplates/SAVE_PRESENTATION_TEMPLATE";
export const REMOVE_PRESENTATION_TEMPLATE = "@presentationTemplates/REMOVE_PRESENTATION_TEMPLATE";

export const Actions = {
  fetchPresentationTemplates: (orgId: number) => createAction(FETCH_PRESENTATION_TEMPLATES, { orgId }),
  fetchPresentationTemplate: (id: number) => createAction(FETCH_PRESENTATION_TEMPLATE, { id }),
  receivePresentationTemplates: (orgId: number, presentationTemplates: List<PresentationTemplateRecord>) =>
    createAction(RECEIVE_PRESENTATION_TEMPLATES, { orgId, presentationTemplates }),
  receivePresentationTemplate: (orgId: number, presentationTemplate: PresentationTemplateRecord) =>
    createAction(RECEIVE_PRESENTATION_TEMPLATE, { orgId, presentationTemplate }),
  createPresentationTemplate: (orgId: number, presentationTemplate: PresentationTemplateRecord) =>
    createAction(CREATE_PRESENTATION_TEMPLATE, { orgId, presentationTemplate }),
  receivePresentationTemplateError: (presentationTemplateId: number, errors: string[]) =>
    createAction(RECEIVE_PRESENTATION_TEMPLATE_ERROR, { presentationTemplateId, errors }),
  receivePresentationTemplatesError: (orgId, errors: string[]) =>
    createAction(RECEIVE_PRESENTATION_TEMPLATES_ERROR, { orgId, errors }),
  removePresentationTemplate: (presentationTemplate: PresentationTemplateRecord) =>
    createAction(REMOVE_PRESENTATION_TEMPLATE, { presentationTemplate }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  addPresentationTemplate: (
    orgId: number,
    presentationTemplate: PresentationTemplateRecord,
  ): ThunkResult<Promise<PresentationTemplateRecord>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.createPresentationTemplate(orgId, presentationTemplate));
      return presentationTemplateService.create(orgId, presentationTemplate, "org").then(
        (result) => {
          dispatch(Actions.receivePresentationTemplate(orgId, result));

          return result;
        },
        (errors) => {
          dispatch(Actions.receivePresentationTemplateError(presentationTemplate.id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  updatePresentationTemplate: (
    presentationTemplate: PresentationTemplateRecord,
  ): ThunkResult<Promise<PresentationTemplateRecord>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPresentationTemplate(presentationTemplate.id));
      return presentationTemplateService.update(presentationTemplate).then(
        (presentationTemplate: PresentationTemplateRecord) => {
          dispatch(Actions.receivePresentationTemplate(presentationTemplate.org_id, presentationTemplate));
          return presentationTemplate;
        },
        (errors) => {
          dispatch(Actions.receivePresentationTemplateError(presentationTemplate.id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  listPresentationTemplates: (orgId: number): ThunkResult<any> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPresentationTemplates(orgId));

      return presentationTemplateService.loadAll(orgId).then(
        (presentationTemplates: List<PresentationTemplateRecord>) => {
          dispatch(Actions.receivePresentationTemplates(orgId, presentationTemplates));
          return presentationTemplates;
        },
        (errors) => {
          dispatch(Actions.receivePresentationTemplatesError(orgId, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  deletePresentation: (presentationTemplate: PresentationTemplateRecord): ThunkResult<Promise<boolean>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPresentationTemplate(presentationTemplate.id));
      return presentationTemplateService.delete(presentationTemplate).then(
        () => {
          dispatch(Actions.removePresentationTemplate(presentationTemplate));
          return true;
        },
        (errors) => {
          dispatch(Actions.receivePresentationTemplateError(presentationTemplate.id, errors));
          return false;
        },
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
