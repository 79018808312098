import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import CrmAuthorize from "../Common/CrmAuthorize";
import { IUser } from "app/src/Models/User";
import * as crmUserActions from "app2/src/reducers/crmUser.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import * as tokenActions from "app2/src/reducers/token.actions";
import CrmUserMatchTable from "../Common/CrmUserMatchTable";
import { CrmUserRecord } from "app2/src/records/integrations/CrmUser";
import { QueryParamsRecord } from "app2/src/records/Page";
import { getPaginationByModel, queryParams, pagination } from "app2/src/selectors/pagination.selectors";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import { capitalize } from "humanize-plus";
import SalesforceModal from "./SalesforceModal";
import track from "react-tracking";
import SettingsModal from "app2/src/components/Integrations/Salesforce/SettingsModal";
import { ICrmUserQuery } from "../../Common/IntegrationList";

const mapStateToProps = (state: RootState, ownProps: SalesforceProps) => {
  const modelName = ownProps.integration === "salesforce" ? "SfUser" : "I360User";
  const crmUsers = getPaginationByModel(state, {
    path: ["crmUsers", "byId"],
    modelName,
  });
  const sfQueryParams = queryParams(state, { modelName });

  return {
    authorized: getAuthorization(state, { orgId: ownProps.orgId, integration: ownProps.integration }),
    pagination: pagination(state, { modelName, page: sfQueryParams.get("page") }),
    queryParams: sfQueryParams,
    crmUsers,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: SalesforceProps) => {
  return {
    sync: () =>
      dispatch(orgActions.AsyncActions.syncIntegration(ownProps.orgId, "InitialSyncJob", ownProps.integration)),
    rejectAuthorization: (orgId: number) =>
      dispatch(tokenActions.AsyncActions.deleteToken(orgId, ownProps.integration)),
    updateUser: (crmUser: CrmUserRecord) => dispatch(crmUserActions.AsyncActions.updateUser(crmUser)),
    queryUsers: (orgId: number, sfQueryParams: QueryParamsRecord) =>
      dispatch(
        crmUserActions.AsyncActions.queryUsers(
          { orgId: orgId, userType: ownProps.integration === "salesforce" ? "SfUser" : "I360User" },
          sfQueryParams,
        ),
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface SalesforceProps {
  users: IUser[];
  orgId: number;
  integration: "salesforce" | "improveit360";
  tokenUpdated: () => void;
}

interface SalesforceState {
  triggerOpen: number;
  settingsTriggerOpen: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & SalesforceProps;

@track((props: Props) => {
  return {
    component: "Salesforce",
    integration: props.integration,
  };
})
class Salesforce extends React.Component<Props, SalesforceState> {
  constructor(props: Props) {
    super(props);

    this.state = { triggerOpen: 0, settingsTriggerOpen: 0 };

    this.openModal = this.openModal.bind(this);
    this.openSettings = this.openSettings.bind(this);
    this.rejectAuthorization = this.rejectAuthorization.bind(this);
    this.queryUsers = this.queryUsers.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const { queryUsers, orgId, authorized } = this.props;
    if (authorized === true && prevProps.authorized === false) {
      queryUsers(orgId, new QueryParamsRecord());
    }
  }

  @track(() => ({
    action: "open modal",
  }))
  public openModal() {
    this.setState((state) => ({ triggerOpen: state.triggerOpen + 1 }));
  }

  @track(() => ({
    action: "open settings modal",
  }))
  public openSettings() {
    this.setState((state) => ({ settingsTriggerOpen: state.settingsTriggerOpen + 1 }));
  }

  @track(() => ({
    action: "reject authorization",
  }))
  public async rejectAuthorization() {
    const { orgId, rejectAuthorization, tokenUpdated } = this.props;
    await rejectAuthorization(orgId);
    tokenUpdated();
  }

  @track(() => ({
    action: "query users",
  }))
  public queryUsers(queryObj: ICrmUserQuery) {
    const { queryUsers, queryParams, orgId } = this.props;
    const newQueryParams = queryParams.merge(queryObj);
    queryUsers(orgId, newQueryParams);
  }

  public render() {
    const { integration, authorized, crmUsers, pagination, users, updateUser, sync } = this.props;
    const { triggerOpen, settingsTriggerOpen } = this.state;
    const imageUrl = integration === "salesforce" ? "/assets/images/salesforce.f372fee5.png" : "/assets/images/improveit360.9bed0d97.png";
    return (
      <div className="form-section">
        <div className="form-section-content">
          <CrmAuthorize
            title={capitalize(integration)}
            image={imageUrl}
            authorized={authorized}
            authorize={this.openModal}
            rejectAuthorization={this.rejectAuthorization}
            settings={integration === "salesforce" ? this.openSettings : null}
          />
          {authorized && (
            <CrmUserMatchTable
              integrationType={capitalize(integration)}
              occUsers={users}
              integrationUsers={crmUsers}
              pagination={pagination}
              queryUsers={this.queryUsers}
              saveUser={updateUser}
              sync={sync}
            />
          )}
          <SalesforceModal integration={integration} triggerOpen={triggerOpen} />
          <SettingsModal integration={integration} triggerOpen={settingsTriggerOpen} />
        </div>
      </div>
    );
  }
}

export default connector(Salesforce);
