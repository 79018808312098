import { Record } from "immutable";

export const fromJSON = (json: Partial<ICardConnectAuthorizationData>): CardConnectAuthorizationRecord => {
  const recordData: ICardConnectAuthorizationRecord = { ...(json as any) };

  return new CardConnectAuthorizationRecord(recordData);
};

export interface ICardConnectAuthorizationData {
  username: string;
  token: string;
  merchant_id: string;
  hpp_url: string;
  loading?: boolean;
}

export interface ICardConnectAuthorizationRecord {
  username: string;
  token: string;
  merchant_id: string;
  hpp_url: string;
  loading: boolean;
}

export const defaultCardConnectAuthorizationRecord: ICardConnectAuthorizationRecord = {
  username: "",
  token: "",
  merchant_id: "",
  hpp_url: "",
  loading: false,
};

export class CardConnectAuthorizationRecord
  extends Record(defaultCardConnectAuthorizationRecord)
  implements ICardConnectAuthorizationRecord {}
