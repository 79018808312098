import * as userActions from "./user.actions";
import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction } from "./Utils";
import { RootState } from ".";
import { fetcher } from "app2/src/helpers/Fetcher";
import { normalize, arrayOf, Schema } from "normalizr-immutable";
import { RootDispatchType } from "../store";

export const RECEIVE_PAGE = "@leads/RECEIVE_PAGE";
export const RESET_PAGES = "@leads/RESET_PAGES";
export const FETCH_PAGE = "@leads/FETCH_PAGE";
export const POST_JOB = "@leads/POST_JOB";
export const RECEIVE_LEAD = "@leads/RECEIVE_LEAD";

export interface ILeadData {
  id: number;
  first_name: string;
  last_name: string;
  email_address: string;
  lead_source: string;
  form_data: any;
  job_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface IPagination {
  pagination: {
    total_count: number;
    total_pages: number;
    current_page: number;
  };
}

export interface ILeadPageData {
  page: number;
  leads: ILeadData[];
  meta: IPagination;
}

export interface ILeadQueryParams {
  page: number;
  query: string;
  filter: string;
}

export const Actions = {
  receivePage: (page: ILeadPageData) => createAction(RECEIVE_PAGE, page),
  fetchPage: (params: ILeadQueryParams) => createAction(FETCH_PAGE, params),
  resetPages: () => createAction(RESET_PAGES),
  createJob: (lead_id: number) => createAction(POST_JOB, lead_id),
  receiveLead: (lead: ILeadData) => createAction(RECEIVE_LEAD, lead),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, ActionsUnion<typeof Actions> | userActions.Actions>;

export const AsyncActions = {
  getPage: (params: ILeadQueryParams): ThunkResult<Promise<ILeadPageData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPage(params));
      const actionUrl = "/api/v1/leads";

      return fetcher.get(actionUrl, params).then((data: ILeadPageData) => {
        data.page = params.page;
        dispatch(Actions.receivePage(data));
        return data;
      });
    };
  },
  createJob: (lead_id: number): ThunkResult<Promise<void>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.createJob(lead_id));
      const actionUrl = "api/v1/leads/" + lead_id + "/job";

      return fetcher.patch(actionUrl).then((data: any) => {
        dispatch(Actions.receiveLead(data.lead));
        return data.lead;
      });
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
