import * as ng from "angular";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface ISrUser extends ng.resource.IResource<ISrUser>, SrUserPrototype {
  $update(): ng.IPromise<ISrUser>;
}

export interface ISrUserResource extends ng.resource.IResourceClass<ISrUser>, IRsfResource {
  fromJSON?(data: any): ISrUser;
}

class SrUserPrototype {
  public classy: string;
  public id: number;
  public user_id: number;
  public email: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/sr_users/:id";

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta: any = angular.fromJson(response);

    _.each(meta.crm_users, (jn, index) => {
      meta.crm_users[index] = SrUser.fromJSON(jn);
    });

    return meta;
  };

  const transformSingle = (data) => {
    const jsonData = angular.fromJson(data);

    return SrUser.fromJSON(jsonData.crm_user);
  };

  const SrUser: ISrUserResource = <ISrUserResource>$resource(
    url,
    { org_id: "@org_id", id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingle,
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultiple,
        isArray: false,
      },
    },
  );

  SrUser.fromJSON = (data: any) => {
    const ms = new SrUser(data);
    ms.classy = "SrUser";

    return ms;
  };

  _.hiddenExtend(SrUser.prototype, SrUserPrototype.prototype);

  SrUser.inject = (injected: IRepository) => {
    resources = injected;
  };

  return SrUser;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
