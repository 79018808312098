import * as crmUserActions from "./crmUser.actions";
import { Record, List, Map } from "immutable";
import { CrmUserRecord, fromJSON } from "app2/src/records/integrations/CrmUser";
import { RootActions, RootState } from "app2/src/reducers";
import { fetch, loaded, receive } from "app2/src/reducers/Reducer";

export const CrmUserStateRecord = Record({
  byId: Map<number, List<typeof CrmUserRecord>>(),
  lastSavedById: Map<number, List<typeof CrmUserRecord>>(),
});

export const initialState = CrmUserStateRecord();
export type CrmUserState = typeof initialState;

const model = "crmUsers";
export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case crmUserActions.FETCH_CRM_USER:
      return fetch(state, model, fromJSON({ id: action.payload.id }));

    case crmUserActions.RECEIVE_CRM_USER:
      const user = fromJSON({ ...action.payload.crmUser });

      return receive(state, model, user);

    case crmUserActions.SET_CRM_USER_LOADED:
      return loaded(state, model, action.payload.id);

    case crmUserActions.RECEIVE_CRM_USERS:
      action.payload.crmUsers.forEach((user) => {
        state = reducer(state, crmUserActions.Actions.receiveCrmUser(user));
      });

      return state;
    default:
      return state;
  }
};
