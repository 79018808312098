import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";

export const RoomMeasurementInfo: React.FC = () => {
  const [show, setShow] = React.useState(false);

  const close = () => {
    setShow(false);
  };

  return (
    <>
      <button className="btn link p-1 text-blue-dark" onClick={() => setShow(true)}>
        <FontAwesome size="lg" name="info-circle" title="Measurement Information" />
      </button>
      <Modal show={show} onHide={close} size="xl">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <img
            src="/assets/images/plnar-measurement-graphic.a1973256.png"
            className="mw-100 mh-100 img-contain"
            alt="Measurement Information"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
