import { IMeasurement } from "app/src/Models/Measurement";
import { Opening } from "app/src/Models/Opening";
import { Room } from "app/src/Models/Room";
import { IOrg } from "app/src/Models/Org";

export class MeasurementEditorCtrl {
  private _originalState = "";
  public static $inject = ["$uibModalInstance", "measurement", "org", "opening"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public measurement: IMeasurement,
    public org: IOrg,
    public opening: any,
  ) {
    if (!opening.area) {
      opening.area = 0;
    }

    if (!opening.ui) {
      opening.ui = 0;
    }

    if (opening.kind === "Room") {
      if (!opening.threshold) {
        opening.threshold = 0;
      }
      if (!opening.perimeter) {
        opening.perimeter = 0;
      }
      if (!opening.inside_corner) {
        opening.inside_corner = 0;
      }
      if (!opening.outside_corner) {
        opening.outside_corner = 0;
      }
    }
    this._originalState = JSON.stringify(JSON.decycle(opening));
  }

  public cancel() {
    this.$uibModalInstance.dismiss();
  }

  public save() {
    if (this.opening.kind === "Room") {
      Room.addRooms(this.measurement, this.opening);
    } else {
      Opening.addOpenings(this.measurement, this.opening);
    }

    this.$uibModalInstance.close(this.opening);
  }
}
