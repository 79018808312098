import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { segment } from "app2/src/selectors/measurements/segment.selectors";

interface SegmentDisplayProps {
  segmentId: number;
}

const mapStateToProps = (state: RootState, ownProps: SegmentDisplayProps) => {
  return {
    segment: segment(state, ownProps),
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & SegmentDisplayProps;

class SegmentPosts extends React.Component<Props> {
  public render() {
    const { segment } = this.props;

    if (!segment) return null;

    if (segment._destroy) return null;

    return (
      <tr data-testid={`segment-posts-${segment.id}`}>
        <td data-testid="segment-name">{segment.name}</td>
        <td data-testid="segment-line-posts-4">{segment.linePosts4}</td>
        <td data-testid="segment-line-posts-6">{segment.linePosts6}</td>
        <td data-testid="segment-line-posts-8">{segment.linePosts8}</td>
        <td></td>
        <td></td>
      </tr>
    );
  }
}

export default connector(SegmentPosts);
