import { PlanRecord } from "app2/src/records/billing/Plan";
import { IPlanStateParams } from "app/src/Billing/Plans/PlanNewCtrl";
import { ISubscriptionResource, ISubscriptionResponse } from "../Models/Subscription";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import * as planActions from "app2/src/reducers/billing/plan.actions";
import { planById } from "app2/src/selectors/plan.selectors";
import { subscriber } from "app2/src/helpers/Subscribe";

export class PlanShowCtrl {
  public includes: string[] = ["subscriptions"];
  public plan: PlanRecord;
  public subscriptions: ISubscriptionResponse;

  private _unsubscribePlan: () => void;

  public static $inject = ["Subscription", "$stateParams", "$scope"];
  constructor(
    public SubscriptionResource: ISubscriptionResource,
    public $stateParams: IPlanStateParams,
    public $scope: ng.IScope,
  ) {
    this.plan = useSelector((state) => planById(state, { id: $stateParams.id }));
    if (!this.plan) {
      this.loadPlan();
    }

    this._unsubscribePlan = subscriber.subscribe<string>("billing.plans.byId", () => {
      this.plan = useSelector((state) => planById(state, { id: $stateParams.id }));
      $scope.$digest();
    });

    this.subscriptions = <ISubscriptionResponse>(
      SubscriptionResource.query({ plan_id: $stateParams.id, "include[]": ["account"] })
    );

    $scope.$on("$destroy", () => {
      this._unsubscribePlan();
    });
  }

  public async loadPlan() {
    dispatch(planActions.AsyncActions.loadPlan(this.$stateParams.id));
  }

  public toggleArchive() {
    if (this.plan.archived_at) {
      dispatch(planActions.AsyncActions.unarchivePlan(this.plan));
    } else {
      dispatch(planActions.AsyncActions.archivePlan(this.plan));
    }
  }
}
