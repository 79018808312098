import * as React from "react";
import { JobTabTitle } from "../JobTabTitle";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as tokenActions from "app2/src/reducers/token.actions";
import * as toolActions from "app2/src/reducers/org/tool.actions";
import { currentOrgId, currentOrgPrefAcl } from "app2/src/selectors/org.selectors";
import { currentJob } from "app2/src/selectors/job.selectors";
import { RootState } from "app2/src/reducers";
import { getOrgTools } from "app2/src/selectors/tool.selectors";
import { ToolCard } from "./ToolCard";
import { JobRecord } from "app2/src/records/Job";
import { push } from "connected-react-router/immutable";
import { StoreRegistry } from "app2/src/storeRegistry";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { isAddressValid, isValidLatLon } from "app2/src/helpers/Address";
import SpinnerComponent from "../../SpinnerComponent";
import { token } from "app2/src/selectors/token.selectors";
import { TokenRecord } from "app2/src/records/Token";

export const ToolsTab: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();

  // Selectors
  const orgId = useSelector(currentOrgId);
  const job: JobRecord = useSelector(currentJob);
  const customTools = useSelector((state: RootState) => getOrgTools(state, { orgId }));
  const aclTools = useSelector((state: RootState) => currentOrgPrefAcl(state, { path: ["tools"] }));
  const proviaToken = useSelector((state: RootState) => token(state, { kind: "provia" })) as TokenRecord;

  const address = job?.address;
  const isAddressInvalid = !isAddressValid(address);
  const isAddressNotGeocoded = !isValidLatLon(address);

  // Methods
  const goToAdress = () => {
    const $rootScope: RsfRootScope = StoreRegistry.get("$rootScope");
    dispatch(push(`/jobs/${job.id}/info`));
    $rootScope.$digest();
  };

  // Lifecycle
  React.useEffect(() => {
    dispatch(tokenActions.AsyncActions.getToken(orgId, "provia"));
    dispatch(toolActions.AsyncActions.listTools(orgId, job.id));
  }, []);

  return (
    <Row>
      <Col>
        <JobTabTitle title="Tools" />
        <SpinnerComponent stateProperty={["tools", "byOrgId", orgId, "loading"]} />
        <Row>
          <Col>
            {(isAddressInvalid || isAddressNotGeocoded) && (
              <div className="form-section blank-state">
                <img src="/assets/images/icons-large/tools.fea01226.png" />
                {isAddressInvalid && isAddressNotGeocoded && <h2>No valid address</h2>}
                {isAddressInvalid && !isAddressNotGeocoded && (
                  <h2>Address is not complete. Missing one of: Address 1, City, State</h2>
                )}
                {!isAddressInvalid && isAddressNotGeocoded && (
                  <h2>Address has not been geocoded. Please refresh the page to get the latest information.</h2>
                )}
                <p>
                  <span>Not all tools will be available until a complete address is provided </span>
                  <span className="underline link" onClick={goToAdress}>
                    here
                  </span>
                  <span>.</span>
                </p>
              </div>
            )}
          </Col>
        </Row>
        <div className="d-flex flex-wrap">
          {aclTools?.map((tool) => {
            if (typeof tool === "number") {
              const toolRecord = customTools.find((t) => t.get("id") === tool);

              if (!toolRecord) return undefined;
              return <ToolCard toolRecord={toolRecord} key={tool} />;
            }
            if (tool?.name === "provia") {
              if (proviaToken.loading === false) return <ToolCard toolKey={tool} key={tool} />;
            } else {
              return <ToolCard toolKey={tool} key={tool} />;
            }
          })}
        </div>
      </Col>
    </Row>
  );
};

export default ToolsTab;
