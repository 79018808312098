import { useEffect, useState } from "react";
import { RemoteParticipant } from "twilio-video";
import useVideoContext from "../useVideoContext/useVideoContext";
import useSelectedParticipant from "app2/src/components/VideoProvider/useSelectedParticipant/useSelectedParticipant";
import { useTracking } from "react-tracking";
import { useNotifier } from "app2/src/components/VideoProvider/FlashAlertContext";

export default function useParticipants(enableMessages = true) {
  // Hooks
  const { trackEvent } = useTracking<any>();
  const { room } = useVideoContext();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  const [participants, setParticipants] = useState(Array.from(room.participants.values()));
  const notifier = useNotifier();
  const addMessage = (msg) => {
    notifier(msg);
  };

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      if (enableMessages) {
        addMessage("Viewer Connected: " + participant.identity.split(" -")[0]);
      }
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
      trackEvent({ action: "screenshare viewer connected", participant: participant?.identity });
    };
    const participantDisconnected = (participant: RemoteParticipant) => {
      if (enableMessages) {
        addMessage("Viewer Disconnected: " + participant.identity.split(" -")[0]);
      }
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
      // @ts-ignore
      setSelectedParticipant(selectedParticipant);
      trackEvent({ action: "screenshare viewer disconnected", participant: participant?.identity });
    };
    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    return () => {
      room.removeListener("participantConnected", participantConnected);
      room.removeListener("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  return participants;
}
