import { Record } from "immutable";

export const fromJSON = (data: Partial<IEstimateCommissionVariablesData>): EstimateCommissionVariablesRecord => {
  const record: IEstimateCommissionVariablesRecord = { ...(data as any) };
  return new EstimateCommissionVariablesRecord(record);
};

export interface IEstimateCommissionVariablesData {
  adj_to_commission: number;
  base_commission: number;
  calculated_commission: string;
  greenline: string;
  percent_of_greenline: number;
  percent_payable: number;
  sale_price: string;
  sale_price_less_adm_fee: string;
  total_cost: string;
}

export interface IEstimateCommissionVariablesRecord {
  adj_to_commission: number;
  base_commission: number;
  calculated_commission: string;
  greenline: string;
  percent_of_greenline: number;
  percent_payable: number;
  sale_price: string;
  sale_price_less_adm_fee: string;
  total_cost: string;
}

const defaultIEstimateCommissionVariablesProps: IEstimateCommissionVariablesRecord = {
  adj_to_commission: 0,
  base_commission: 0,
  calculated_commission: "",
  greenline: "",
  percent_of_greenline: 0,
  percent_payable: 0,
  sale_price: "",
  sale_price_less_adm_fee: "",
  total_cost: "",
};

export class EstimateCommissionVariablesRecord
  extends Record(defaultIEstimateCommissionVariablesProps)
  implements IEstimateCommissionVariablesRecord {}
