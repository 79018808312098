import { JobMenuValues } from "./JobMenuValues";
import { JobMenuManagerComponent } from "./JobMenuManagerComponent";
import { JobMenuOrderService } from "./JobMenuOrderService";

export class JobMenuRoutes {
  public static load(app: ng.IModule) {
    app.component("rsfJobMenuManager", new JobMenuManagerComponent());

    app.service("JobMenuOrderService", JobMenuOrderService);

    app.value("JobMenuValues", JobMenuValues);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {}
}
