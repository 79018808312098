import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { Record, List } from "immutable";
import { IProductOptionData, ProductOptionRecord, fromJSON as poFromJSON } from "./ProductOption";

export const fromJSON = (json: Partial<IProductOptionGroupData>): ProductOptionGroupRecord => {
  const recordData: IProductOptionGroupRecord = { ...(json as any) };

  if (json.options) {
    recordData.option_ids = List(json.options.map((opt) => opt.id));
  }

  if (json.product_ids) {
    recordData.product_ids = List(json.product_ids);
  }

  if (!json.match_id) {
    recordData.match_id = json.uuid;
  }

  return new ProductOptionGroupRecord(recordData);
};

export const optionSelected = (options: List<ProductOptionRecord>, lineItem: IEstimateLineItem) => {
  const option_uuids = options.map((o) => o.uuid);
  return _.chain(lineItem.existingOptions())
    .any((elio: IEstimateLineItemOption) => {
      return option_uuids.includes(elio.product_option_uuid);
    })
    .value();
};

export interface IProductOptionGroupData {
  id: number;
  matched: IProductOptionGroupData[];
  uuid: string;
  match_id: string;
  name: string;
  selection_mode: string;
  pog_type: string;
  product_ids: number[];
  option_count: number;
  product_count: number;
  option_group_count: number;
  option_group_ids: number[];
  show: boolean;
  sort_order: number;
  price_list_id: number;
  options: IProductOptionData[];
}

export interface IProductOptionGroupRecord {
  id: number;
  matched: List<ProductOptionGroupRecord>;
  uuid: string;
  match_id: string;
  name: string;
  selection_mode: string;
  pog_type: string;
  product_ids: List<number>;
  option_count: number;
  product_count: number;
  option_group_count: number;
  option_group_ids: List<number>;
  show: boolean;
  sort_order: number;
  price_list_id: number;
  option_ids: List<number>;
}

const defaultProductOptionGroupProps: IProductOptionGroupRecord = {
  id: 0,
  matched: List<ProductOptionGroupRecord>(),
  uuid: "",
  match_id: "",
  name: "",
  selection_mode: "",
  // price lists that were activated prior to adding pog_type column are set to "default"
  pog_type: "default",
  product_ids: List<number>(),
  option_count: 0,
  product_count: 0,
  option_group_count: 0,
  option_group_ids: List<number>(),
  show: true,
  sort_order: 0,
  price_list_id: 0,
  option_ids: List<number>(),
};

export class ProductOptionGroupRecord
  extends Record(defaultProductOptionGroupProps)
  implements IProductOptionGroupRecord
{
  public readonly id!: number;
  public readonly matched!: List<ProductOptionGroupRecord>;
  public readonly uuid!: string;
  public readonly match_id!: string;
  public readonly name!: string;
  public readonly selection_mode!: string;
  public readonly pog_type!: string;
  public readonly product_ids!: List<number>;
  public readonly option_count!: number;
  public readonly product_count!: number;
  public readonly option_group_count!: number;
  public readonly option_group_ids!: List<number>;
  public readonly show!: boolean;
  public readonly sort_order!: number;
  public readonly price_list_id!: number;
  public readonly option_ids!: List<number>;

  public constructor(values?: Partial<IProductOptionGroupRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
