import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { Row, Col, Button } from "react-bootstrap";
import { getOrgDiscounts } from "app2/src/selectors/discount.selectors";
import { DiscountRecord } from "app2/src/records/DiscountRecord";
import { List } from "immutable";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { EstimateDisplay } from "./components/EstimateDisplay";
import { EstimateEditor } from "./components/EstimateEditor";
import * as FontAwesome from "react-fontawesome";
import { EstimateDiscountRecord, IEstimateDiscountData } from "app2/src/records/EstimateDiscount";
import { UserRecord } from "app2/src/records/UserRecord";
import { Acl } from "app2/src/helpers/Acl";
import { PresentAltDisplay } from "./components/PresentAltDisplay";
import { getPrefConfig, OrgRecord } from "app2/src/records/OrgRecord";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";
import { currentOrg } from "app2/src/selectors/org.selectors";
import track from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";

const mapStateToProps = (state: RootState) => {
  const org = currentOrg(state);
  const discounts = getOrgDiscounts(state, { orgId: org?.id }) as List<DiscountRecord>;
  return {
    currentUser: denormalizedReduxUser(state),
    discounts: discounts,
    org: org,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ChooserProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface ChooserParams {
  type: string;
}

interface ChooserProps {
  existing: List<EstimateDiscountRecord>;
  total: number;
  params: ChooserParams;
  showHeaderText?: boolean; // this is not watched for continually.  Current workflow says this is static once set.
  onSelect(breakdown: Partial<IEstimateDiscountData>): void;
  update(discount: EstimateDiscountRecord): void;
  destroy(discount: EstimateDiscountRecord): void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ChooserProps;

interface ChooserState {
  selected: DiscountRecord;
  manual: boolean;
  manualOnly: boolean;
  dropdown: boolean;
  showHeaderText: boolean;
}

@track(
  () => {
    return {
      component: "DiscountChooser",
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
export class Chooser extends React.Component<Props, ChooserState> {
  public constructor(props) {
    super(props);

    this.state = {
      selected: null,
      manual: false,
      manualOnly: true,
      dropdown: false,
      showHeaderText: true,
    };

    this.discountSelected = this.discountSelected.bind(this);
    this.addSelected = this.addSelected.bind(this);
    this.toggleDiscount = this.toggleDiscount.bind(this);
  }

  public componentDidMount() {
    const { showHeaderText } = this.props;

    if (showHeaderText === false) {
      this.setState({ showHeaderText });
    }

    this.componentDidUpdate();
  }

  public componentDidUpdate() {
    if (this.props.discounts.size <= 0 && this.state.manualOnly === false) {
      this.setState({
        manualOnly: true,
      });
    }
    if (this.props.discounts.size > 0 && this.state.manualOnly === true) {
      this.setState({
        manualOnly: false,
      });
    }
  }

  @track((_props, _state, [event]) => ({ action: "discount selected", selected: event.target.value }))
  public discountSelected(event: React.ChangeEvent<HTMLSelectElement>) {
    const { discounts } = this.props;

    if (event.target.value === "manual") {
      this.setState({
        manual: true,
        selected: null,
      });
      return;
    }

    if (event.target.value === "") {
      this.setState({
        selected: null,
        manual: false,
      });
      return;
    }

    const selected = discounts.find((d) => d.id === parseInt(event.target.value));
    this.setState({
      selected: selected,
      manual: false,
    });
  }

  public addSelected() {
    const { selected, manual, manualOnly } = this.state;
    const { onSelect } = this.props;

    if (manual || manualOnly) {
      onSelect({ kind: "percent" });
      this.setState({
        selected: null,
        manual: false,
      });
      return;
    }

    if (!selected) {
      return;
    }

    const newDisc: any = selected.breakdown.toJSON();
    newDisc.name = selected.name;
    newDisc.discountId = selected.id;
    newDisc.discountRev = selected.updated_at;
    onSelect(newDisc);
    this.setState({
      selected: null,
      dropdown: false,
    });
  }

  public canAdd() {
    const { selected, manual, manualOnly } = this.state;

    return !_.isNull(selected) || manual || (manualOnly && Acl.can("custom", "discount"));
  }

  public toggleDiscount() {
    this.setState((state) => {
      return { dropdown: !state.dropdown };
    });
  }

  public render() {
    const { params } = this.props;

    switch (params.type) {
      case "view":
        return this.estimateView();
      case "present":
        return this.presentView();
    }
  }

  public presentView() {
    const { manualOnly, dropdown, selected } = this.state;
    const { existing, destroy, org } = this.props;

    return (
      <React.Fragment>
        {existing.map((discount: EstimateDiscountRecord, idx) => {
          if (getPrefConfig(org, ["price_presentation", "discount_view"]) === "side_by_side") {
            return (
              <PresentAltDisplay key={idx} existing={existing} destroy={destroy} index={idx} discount={discount} />
            );
          }
          return this.presentExistingDiscount(discount, idx);
        })}
        <Row>
          <Col md={1}>
            <h3 onClick={this.toggleDiscount} className="link">
              {dropdown ? (
                <FontAwesome name="minus fa-gray" title="Close Discounts" size="lg" />
              ) : (
                <FontAwesome name="plus fa-gray" title="Edit Discounts" size="lg" />
              )}
            </h3>
          </Col>
          {dropdown ? (
            <React.Fragment>
              <Col md={8}>{manualOnly ? this.renderSelector(true) : this.renderSelector(true)}</Col>
              <Col md={3}>
                <Button variant="add" onClick={this.addSelected} disabled={!this.canAdd() || !selected}>
                  Add
                </Button>
              </Col>
            </React.Fragment>
          ) : null}
        </Row>
      </React.Fragment>
    );
  }

  public presentExistingDiscount(discount: EstimateDiscountRecord, idx: number) {
    const { destroy } = this.props;
    return (
      <Row key={idx}>
        <Col sm={6}>
          <h3 className="pull-right">
            <span>
              <FontAwesome id={discount.uuid} onClick={() => destroy(discount)} className="link" name="minus" />
              &nbsp;{CurrencyFormat(discount.calculated)}
            </span>
          </h3>
        </Col>
        <Col sm={6}>
          <h3>{discount.name}</h3>
        </Col>
      </Row>
    );
  }

  public estimateView() {
    const { manualOnly, showHeaderText } = this.state;

    return (
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <div className="form-section">
                <div className="form-section-heading">
                  <Row>
                    {showHeaderText && (
                      <Col md={4}>
                        <h3>Discounts</h3>
                      </Col>
                    )}
                    <Col md={showHeaderText ? 8 : 12}>
                      {manualOnly ? this.renderManualPicker() : this.renderFullPicker()}
                    </Col>
                  </Row>
                </div>
                <br />
                <div className="form-section-content">{this.renderList()}</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  public renderList() {
    const { existing, update, destroy } = this.props;
    if (existing.count() === 0) {
      return <span></span>;
    }
    let count = 0;
    return (
      <React.Fragment>
        {existing.map((d: EstimateDiscountRecord, idx: number) => {
          if (d.discountId) {
            count += 1;
            let labels = null;
            if (count === 1) {
              labels = (
                <Row>
                  <Col md={3}>
                    <label className="control-label">Name</label>
                  </Col>
                  <Col md={4}>
                    <label className="control-label">Discount</label>
                  </Col>
                  <Col md={3}>
                    <label className="control-label">Amount</label>
                  </Col>
                </Row>
              );
            }
            return (
              <div key={idx}>
                {labels}
                <EstimateDisplay discount={d} destroy={destroy} />
              </div>
            );
          }
          return <EstimateEditor key={idx} discount={d} update={update} destroy={destroy} />;
        })}
      </React.Fragment>
    );
  }

  public renderManualPicker() {
    return (
      <Row>
        <Col md={12}>
          {Acl.can("custom", "discount") && (
            <Button variant="add" className="pull-right" onClick={this.addSelected}>
              <FontAwesome name="plus" />
              &nbsp; Add Discount
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  public renderFullPicker() {
    return (
      <Row>
        <Col md={8}>{this.renderSelector(false)}</Col>
        <Col md={4}>
          <Button id="estimate-add-discount" variant="add" onClick={this.addSelected} disabled={!this.canAdd()}>
            <FontAwesome name="plus" />
            &nbsp;
          </Button>
        </Col>
      </Row>
    );
  }

  public renderSelector(hideCustom: boolean) {
    const { discounts } = this.props;
    const { selected, manual } = this.state;
    return (
      <select
        className="form-control"
        value={manual ? "manual" : selected ? selected.id : ""}
        onChange={this.discountSelected}>
        <option value="">Choose a Discount</option>
        {discounts.map((d, idx) => {
          return (
            <option key={idx} value={d.id}>
              {d.name}
            </option>
          );
        })}
        {hideCustom || !Acl.can("custom", "discount") ? null : <option value="manual">Add Custom</option>}
      </select>
    );
  }
}

export default connector(Chooser);
