import * as React from "react";

const STEP = 3;

export default function NetworkQualityLevel({ qualityLevel }: { qualityLevel: number | null }) {
  if (qualityLevel === null) return null;
  return (
    <div className="network-quality-level">
      {[0, 1, 2, 3, 4].map((level) => (
        <div
          key={level}
          style={{
            height: `${STEP * (level + 1)}px`,
            background: qualityLevel > level ? "#7ACC5A" : "#9EA1A6",
          }}
        />
      ))}
    </div>
  );
}
