import { ISession } from "app/src/Common/SessionService";
import { StoreRegistry, dispatch } from "app2/src/storeRegistry";
import * as authActions from "app2/src/reducers/auth.actions";

export interface IInterceptableHttpResponse<T> extends ng.IHttpResponse<T> {
  intercepted: boolean;
}

export class AuthHttpInterceptor implements ng.IHttpInterceptor {
  private inRecovery = false;
  private recoveryPromise: ng.IPromise<any>;

  constructor(
    private $q: ng.IQService,
    private $injector: ng.auto.IInjectorService,
  ) {}

  public responseError = (rejection: any): ng.IPromise<any> => {
    if (
      (rejection.status === 401 || rejection.status === 419) &&
      !/.*\/api\/v1\/users\/refresh.*/.test(rejection.config.url) &&
      !rejection.config.intercepted
    ) {
      const $state = this.$injector.get("$state") as ng.ui.IStateService;
      if (
        $state.current &&
        ($state.current.abstract || ($state.current.resolve && $state.current.resolve.hasOwnProperty("auth") !== false))
      ) {
        let promise: ng.IPromise<any>;
        const $http: ng.IHttpService = this.$injector.get("$http");
        const session = this.$injector.get("Session") as ISession;

        if (this.inRecovery) {
          promise = this.recoveryPromise.then(() => {
            rejection.config.headers["Authorization"] = $http.defaults.headers.common.Authorization;
            return $http(rejection.config);
          });
        } else {
          this.inRecovery = true;

          this.recoveryPromise = session.refreshToken();
          promise = this.recoveryPromise
            .then(
              () => {
                rejection.config.headers["Authorization"] = $http.defaults.headers.common.Authorization;
                // noinspection TypeScriptUnresolvedVariable
                rejection.config.intercepted = true;
                return $http(rejection.config);
              },
              () => {
                $state.go(StoreRegistry.get<any>("authService").getLoginRoute());
                return this.$q.reject(rejection);
              },
            )
            .catch((errors) => {
              console.error("Recovery Catch", errors);
              this.inRecovery = false;
              dispatch(authActions.Actions.setRefreshTokenExpired(true));
              return this.$q.reject(rejection);
            })
            .finally(() => {
              this.inRecovery = false;
            });
        }

        return promise;
      }
    }
    return this.$q.reject(rejection);
  };

  public static factory($q: ng.IQService, $injector: ng.auto.IInjectorService): AuthHttpInterceptor {
    return new AuthHttpInterceptor($q, $injector);
  }
}

AuthHttpInterceptor.factory.$inject = ["$q", "$injector"];
