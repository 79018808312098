import { IMeasurement } from "app/src/Models/Measurement";

export interface IDeckMetadata extends IDeck {
  quantity: number;
}

export interface IDeck {
  id: number;
  name: string;
  designator: string;
  area: number;
  perimeter: number;
  wall_length: number;
  railing_length: number;
  railing_area: number;
  metadata: any;
  created_at: Date;
  updated_at: Date;

  _destroy?: boolean;
}

export class Deck implements IDeck {
  public id: number;
  public name: string;
  public designator: string;
  public area: number;
  public perimeter: number;
  public wall_length: number;
  public railing_length: number;
  public railing_area: number;
  public metadata: any;
  public created_at: Date;
  public updated_at: Date;

  constructor() {}

  public static fromJSON = (data: any): IDeck => {
    const deck = new Deck();

    Deck.copyValues(deck, data);
    deck["kind"] = "Deck";
    return deck;
  };

  public static clone(deck: IDeck): IDeck {
    const clone = new Deck();
    Deck.copyValues(clone, deck);
    return clone;
  }

  public static addDecks(measurement: IMeasurement, deck: IDeckMetadata) {
    if (!deck.quantity || deck.quantity <= 1) {
      this._addDecks(measurement, deck);
    } else {
      for (let i = 0; i < deck.quantity; i++) {
        let index = i + 1;
        while (_.any(measurement["deck"], (r: IDeck) => r.name === deck.name + "-" + index)) {
          index += 1;
        }
        const clone = JSON.parse(JSON.stringify(deck));
        clone.name = deck.name + "-" + index;
        this._addDecks(measurement, clone);
      }
    }
  }

  public static copyValues(deck: IDeck, data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        switch (key) {
          case "id":
            deck[key] = !_.isNaN(parseInt(data[key])) ? parseInt(data[key]) : data[key];
            break;
          case "area":
          case "perimeter":
          case "railing_area":
          case "railing_length":
          case "wall_length":
            deck[key] = parseFloat(data[key]);
            break;
          case "updated_at":
          case "created_at":
            deck[key] = new Date(data[key]);
            break;
          default:
            deck[key] = data[key];
            break;
        }
      }
    }
  }

  private static _addDecks(measurement: IMeasurement, deck: IDeck) {
    if (deck.id) {
      const index = _.findIndex(measurement.decks, (r) => {
        return r.id === deck.id;
      });
      measurement.decks[index] = deck;
    } else {
      deck.id = measurement.getId();
      measurement.decks.push(deck);
    }
  }
}
