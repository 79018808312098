import * as React from "react";
import { WebhookRecord } from "app2/src/records/Webhook";
import { DateFormat } from "app2/src/helpers/Format";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import * as webhookActions from "app2/src/reducers/webhook.actions";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";

export interface IWebhookRowProps {
  webhook: WebhookRecord;
  onEditClick: (webhook: WebhookRecord) => any;
  orgId: number;
}

export const WebhookRow: React.FC<IWebhookRowProps> = ({ webhook, onEditClick, orgId }) => {
  // hooks
  const dispatch = useDispatch();

  const handleWebhookStatusChange = (event) => {
    dispatch(
      webhookActions.AsyncActions.updateOrCreateWebhook(
        webhook.set("status", event.target.checked ? "active" : "deactivated"),
        orgId,
      ),
    );
  };

  const formattedEvents = React.useMemo(() => {
    if (!webhook?.events) return [];
    const events = webhook.events.toJS();
    return _.chain(Object.keys(events))
      .map((model) => {
        return Object.keys(events[model])
          .filter((e) => events[model][e] === true)
          .map((filtered_event) => `${model}_${filtered_event}`);
      })
      .flatten()
      .value();
  }, [webhook]);

  return (
    <tr key={webhook.id}>
      <td>{webhook.name}</td>
      <td className="w-25">
        {formattedEvents.map((e, idx) => (
          <span key={idx} className="ml-1 badge badge-light">
            {" "}
            {e}{" "}
          </span>
        ))}
      </td>
      <td>{DateFormat(webhook.created_at)}</td>
      <td>
        <Form>
          <Form.Check
            type="switch"
            id={`custom-switch-${webhook.id}`}
            title="Activate / Deactivate"
            onChange={handleWebhookStatusChange}
            checked={webhook.status === "active"}
          />
        </Form>
      </td>
      <td>
        <span className="btn">
          <i className="fa fa-lg fa-pencil link" onClick={() => onEditClick(webhook)}></i>
        </span>
        <ConfirmDialog title={"Are you sure you wish to delete the webhook"}>
          {(confirm) => (
            <span className="btn">
              <i
                className="fa fa-lg fa-trash link"
                onClick={confirm(() => dispatch(webhookActions.AsyncActions.destroyWebhook(webhook, orgId)))}></i>
            </span>
          )}
        </ConfirmDialog>
      </td>
    </tr>
  );
};
