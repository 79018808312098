import * as React from "react";
import { Map } from "immutable";
import { Row, Col } from "react-bootstrap";
import { PaymentRecord } from "app2/src/records/PaymentRecord";
import { PaySimpleFailureKeys } from "./PaySimple.service";

interface PaySimplePaymentViewProps {
  payment: PaymentRecord;
}

export const PaySimplePaymentView: React.SFC<PaySimplePaymentViewProps> = (props) => {
  const { payment } = props;
  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={4}>Customer#: </Col>
          <Col md={8}>{payment.payload.get("CustomerId")}</Col>
        </Row>
        <Row>
          <Col md={4}>Customer: </Col>
          <Col md={8}>{`${payment.payload.get("CustomerFirstName")} ${payment.payload.get("CustomerLastName")}`}</Col>
        </Row>
        <Row>
          <Col md={4}>Status: </Col>
          <Col md={8}>{payment.status}</Col>
        </Row>
        <PaySimplePaymentFailureView failure={payment.payload.get("FailureData")} />
      </Col>
    </Row>
  );
};

interface PaySimplePaymentFailureViewProps {
  failure: Map<PaySimpleFailureKeys, string>;
}

export const PaySimplePaymentFailureView: React.SFC<PaySimplePaymentFailureViewProps> = (props) => {
  const { failure } = props;

  if (!failure || !failure.get("Code")) {
    return null;
  }
  return (
    <Row className="bg-danger">
      <Col md={12}>
        <Row>
          <Col md={12}>&nbsp;</Col>
        </Row>
        <Row>
          <Col md={4}>Code#: </Col>
          <Col md={8}>{failure.get("Code")}</Col>
        </Row>
        <Row>
          <Col md={4}>Description: </Col>
          <Col md={8}>{failure.get("Description")}</Col>
        </Row>
        <Row>
          <Col md={4}>Merchant Action: </Col>
          <Col md={8}>{failure.get("MerchantActionText")}</Col>
        </Row>
        <Row>
          <Col md={4}>Declined: </Col>
          <Col md={8}>{failure.get("IsDecline")}</Col>
        </Row>
      </Col>
    </Row>
  );
};
