import * as React from "react";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { valid_uom } from "app/src/Common/Constants";
import { IImage } from "app/src/Models/Image";
import { Col, Row, FormControl, FormLabel, Form } from "react-bootstrap";
import { Acl } from "app2/src/helpers/Acl";
import { DecimalInputComponent } from "../../Common/DecimalInputComponent";
import { getUrl } from "app2/src/records/Image";
import { cachedProduct } from "app2/src/selectors/product.selectors";
import { RootActions, RootState } from "app2/src/reducers";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { preferencesConfig, presentModePreferencesConfig } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: LineItemInputProps) => {
  const config = preferencesConfig(state, ownProps);
  let markupEnabled = false;
  if (config && config.get("markup")) {
    markupEnabled = config.getIn(["markup", "enabled"]);
  }
  const showPricingInLineItemEditor = presentModePreferencesConfig(state, {
    path: ["estimator", "show_pricing", "line_item_editor"],
  });

  if (ownProps.lineItem.product_id) {
    return {
      markupEnabled,
      product: cachedProduct(state, {
        activatedPriceListId: ownProps.activatedPriceListId,
        productId: ownProps.lineItem.product_id,
      }),
      showPricingInLineItemEditor,
    };
  } else {
    return {
      markupEnabled,
      product: null,
      showPricingInLineItemEditor,
    };
  }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: LineItemInputProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface LineItemInputProps {
  lineItem: IEstimateLineItem;
  forceRender: number;
  activatedPriceListId: number;
  orgId: number;
  handleNumberInputChange: (value: number, name: string) => void;
  handleInputChange: (event) => void;
  full: boolean;
}

export interface LineItemInputState {
  priceEdit: boolean;
  markup: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & LineItemInputProps;

class LineItemInput extends React.Component<Props, LineItemInputState> {
  public props: Props;
  public state: LineItemInputState;

  constructor(props) {
    super(props);
    const { lineItem } = props;
    this.state = {
      priceEdit: lineItem.isCustom(),
      markup: lineItem.markupable !== "no",
    };
  }

  public componentDidUpdate(prevProps, prevState) {
    const { product, lineItem } = this.props;

    const priceEdit =
      (product && product.product_price === 0 && product.labor_price === 0) || this.props.lineItem.isCustom();
    if (priceEdit && !prevState.priceEdit) {
      this.setState({
        priceEdit: priceEdit,
        markup: lineItem.markupable !== "no",
      });
    }
  }

  public lineItemImage(): string {
    const { lineItem, product, full } = this.props;
    const images = _.filter(lineItem.images, (img: IImage) => {
      return !img._destroy;
    });

    if (images.length > 0 && full) {
      return images[0].getUrl("medium");
    }

    if (product && product.images && product.images.size > 0 && full) {
      return getUrl(product.images.first(), "medium");
    }

    return "false";
  }

  public toggleEditing = () => {
    if (this.props.lineItem.isCustom() || Acl.can("update_pricing", "estimate")) {
      this.setState((state: LineItemInputState) => {
        return {
          priceEdit: !state.priceEdit,
        };
      });
    }
  };

  public handleMarkupChange = (event) => {
    this.setState({ markup: event.target.checked });
    event.target.value = event.target.checked ? "yes" : "no";
    this.props.handleInputChange(event);
  };

  public productName() {
    const { name } = this.props.lineItem;
    const { handleInputChange } = this.props;

    return (
      <React.Fragment>
        <FormControl
          id="estimate-li-name"
          type="text"
          name="name"
          value={name}
          title="Name"
          onChange={handleInputChange}
        />
        <br />
        {this.lineItemImage() === "false" ? null : <img className="pull-right" src={this.lineItemImage()} />}
      </React.Fragment>
    );
  }

  public render() {
    const { description, quantity, uom, price, ext_price, base_product_price, base_labor_price } = this.props.lineItem;
    const { handleInputChange, handleNumberInputChange, full, markupEnabled, showPricingInLineItemEditor } = this.props;
    let pricing = null;
    let descriptionRow = 6;
    let descriptionCol = 3;
    if (full) {
      pricing = (
        <Col md={5} className="no-padding">
          <Row>
            <Col md={showPricingInLineItemEditor ? 6 : 12} lg={showPricingInLineItemEditor ? 5 : 12}>
              <Row>
                <Col lg={6}>
                  <DecimalInputComponent
                    id="estimate-li-quantity"
                    type="number"
                    name="quantity"
                    title="Quantity"
                    value={quantity}
                    onNumberChange={handleNumberInputChange}
                  />
                </Col>
                <Col lg={6}>
                  <FormControl as="select" name="uom" id="uom" value={uom} title="Unit" onChange={handleInputChange}>
                    {valid_uom.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </FormControl>
                </Col>
              </Row>
            </Col>
            {showPricingInLineItemEditor ? (
              <Col md={6} lg={7}>
                <Row>
                  <Col lg={6} onClick={this.toggleEditing}>
                    {/*
                     * https://tberrysoln.atlassian.net/browse/RSF-16026
                     * Some browsers don't propagate/bubble events on 'disabled' inputs.
                     * Firefox hasn't for a long time and we have reports and are able to reproduce similar in Chrome/Edge.
                     * Adding this `pointer-events: none` fixes that swallowing of events.
                     */}
                    <FormControl
                      type="number"
                      name="price"
                      value={price.toString()}
                      disabled={true}
                      style={{ "pointer-events": "none" } as any}
                      title="Unit Price"
                    />
                  </Col>
                  <Col lg={6}>
                    <FormControl
                      type="number"
                      name="ext_price"
                      value={ext_price.toString()}
                      disabled={true}
                      title="Total Price"
                    />
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
          <Row>
            {this.state.priceEdit && showPricingInLineItemEditor ? (
              <Col lg={{ span: 7, offset: 5 }}>
                <Row>
                  <Col sm={6}>
                    <FormLabel>Product</FormLabel>
                    <DecimalInputComponent
                      id="estimate_li_base_product_price"
                      type="number"
                      name="base_product_price"
                      min="-9999999"
                      max="9999999"
                      title="Product Price"
                      value={base_product_price}
                      onNumberChange={handleNumberInputChange}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormLabel>Labor</FormLabel>
                    <DecimalInputComponent
                      id="estimate_li_base_labor_price"
                      type="number"
                      name="base_labor_price"
                      min="-9999999"
                      max="9999999"
                      title="Labor Price"
                      value={base_labor_price}
                      onNumberChange={handleNumberInputChange}
                    />
                  </Col>
                </Row>
                {Acl.can("update_markup", "estimate") && markupEnabled && (
                  <Row>
                    <Col className="text-center">
                      <Form.Group>
                        <Form.Check
                          id="estimate_li_markup_checkbox"
                          name="markupable"
                          type="checkbox"
                          inline
                          label="Markup"
                          checked={this.state.markup}
                          onChange={this.handleMarkupChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </Col>
            ) : null}
          </Row>
        </Col>
      );
    } else {
      descriptionRow = 3;
      descriptionCol = 5;
      pricing = (
        <Col md={3}>
          <FormControl
            type="number"
            name="base_product_price"
            value={base_product_price.toString()}
            title="Product Price"
            min="-9999999"
            max="9999999"
            onChange={handleInputChange}
          />
        </Col>
      );
    }

    return (
      <div className="line-item-input">
        <Row>
          <Col md={4}>{this.productName()}</Col>
          <Col md={descriptionCol}>
            <FormControl
              id="estimate-li-description"
              as="textarea"
              name="description"
              value={description}
              onChange={handleInputChange}
              title="Description"
              rows={descriptionRow}
            />
          </Col>
          {pricing}
        </Row>
      </div>
    );
  }
}

export default connector(LineItemInput);
