import * as React from "react";
import Chooser from "app2/src/components/PaymentTerm/Chooser";
import { EstimateRecord } from "app2/src/records/Estimate";
import { PaymentTermTemplateRecord, PaymentTermItemRecord } from "app2/src/records/PaymentTermTemplate";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { ThunkDispatch } from "redux-thunk";
import track from "react-tracking";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: IPaymentChooserWrapperProps,
) => {
  const { estimate } = ownProps;
  return {
    addPaymentTermTemplate: (template: PaymentTermTemplateRecord) =>
      dispatch(estimateActions.AsyncActions.addPaymentTermTemplate(estimate.id, template)),
    addPaymentTermItem: (item: PaymentTermItemRecord) =>
      dispatch(estimateActions.AsyncActions.addPaymentTermItem(estimate.id, item)),
    removePaymentTermItem: (item: PaymentTermItemRecord) =>
      dispatch(estimateActions.AsyncActions.removePaymentTermItem(estimate.id, item)),
    updatePaymentTermItem: (item: PaymentTermItemRecord) =>
      dispatch(estimateActions.AsyncActions.updatePaymentTermItem(estimate.id, item)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IPaymentChooserWrapperProps {
  orgId: number;
  estimate: EstimateRecord;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IPaymentChooserWrapperProps;

@track(() => {
  return {
    component: "PaymentChooserWrapper",
  };
})
class PaymentChooserWrapper extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.addTemplate = this.addTemplate.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  @track((_props, _state, [template]) => ({
    action: "payment term template added",
    payment_term_template: template.toJS(),
  }))
  public addTemplate(template: PaymentTermTemplateRecord) {
    this.props.addPaymentTermTemplate(template);
  }

  @track((_props, _state, [item]) => ({ action: "payment term added", payment_term: item.toJS() }))
  public addItem(item: PaymentTermItemRecord) {
    this.props.addPaymentTermItem(item);
  }

  @track((_props, _state, [item]) => ({ action: "payment term removed", payment_term: item.toJS() }))
  public removeItem(item: PaymentTermItemRecord) {
    this.props.removePaymentTermItem(item);
  }

  @track((_props, _state, [item]) => ({ action: "payment term updated", payment_term: item.toJS() }))
  public updateItem(item: PaymentTermItemRecord) {
    this.props.updatePaymentTermItem(item);
  }

  public render() {
    const { orgId, estimate } = this.props;

    return (
      <Chooser
        orgId={orgId}
        addItem={this.addItem}
        addTemplate={this.addTemplate}
        removeItem={this.removeItem}
        paymentTerm={estimate.payment_terms}
        updateItem={this.updateItem}
        view={"easyEdit"}
        doneButton={true}
      />
    );
  }
}

export default connector(PaymentChooserWrapper);
