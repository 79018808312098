import * as angulartics from "angulartics";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { IPattern, IPatternResource, IPatternResponse } from "app/src/Models/Pattern";
import { IPretty } from "app/src/Common/PrettyNameService";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";

export class GlobalPatternsCtrl {
  public patterns: IPattern[];
  public selected_pattern: IPattern;
  public spinnerPromise: ng.IPromise<any>;
  public groups: any;

  public static $inject = [
    "Pattern",
    "OrgFetcher",
    "$stateParams",
    "$state",
    "Pretty",
    "Flash",
    "$analytics",
    "$scope",
  ];

  constructor(
    public Pattern: IPatternResource,
    public OrgFetcher: IOrgFetcherService,
    public $stateParams: ng.ui.IStateParamsService,
    public $state: ng.ui.IStateService,
    public Pretty: IPretty,
    public Flash: IFlash,
    private $analytics: angulartics.IAnalyticsService,
    private $scope: ng.IScope,
  ) {
    $scope.$on("global:pattern_added", (event: ng.IAngularEvent, args: any) => {
      this.selected_pattern = args.pattern;
      this.patterns.push(this.selected_pattern);
      this.updateGroups();
    });

    const patternResponse = <IPatternResponse>this.Pattern.query_global({ global: "global" });

    this.spinnerPromise = patternResponse.$promise.then((resp: IPatternResponse) => {
      this.patterns = resp.patterns;
      this.updateGroups();
    });
  }

  public addPattern() {
    this.$analytics.eventTrack("add global pattern", { category: "Global" });
    this.selected_pattern = this.Pattern.fromJSON(this.newPattern());
  }

  public copyPattern(pattern) {
    this.$analytics.eventTrack("copy global pattern", { category: "Global", pattern: pattern.id });
    const copied_pattern = this.newPattern();
    copied_pattern.view = pattern.view;
    copied_pattern.name = pattern.name;
    copied_pattern.doc_type = pattern.doc_type;
    copied_pattern.parent_id = pattern.id;
    this.selected_pattern = copied_pattern;
  }

  public deletePattern(pattern) {
    this.$analytics.eventTrack("delete global pattern", { category: "Global", pattern: pattern.id });
    this.spinnerPromise = pattern
      .$delete_global()
      .then(() => {
        this.patterns = _.filter(this.patterns, (p) => {
          return p.id !== pattern.id;
        });
        this.Flash.addMessage(FlashLevels.success, "Pattern successfully deleted!");
        this.updateGroups();
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There was a problem deleting your pattern. Please try again. If the problem persists, please contact support.",
        );
      });
  }

  public editPattern(pattern) {
    this.$analytics.eventTrack("edit global pattern", { category: "Global", pattern: pattern.id });
    this.selected_pattern = pattern;
  }

  public clearPattern() {
    this.updateGroups();
    this.selected_pattern = null;
  }

  public newPattern() {
    this.$analytics.eventTrack("new global pattern", { category: "Global" });
    return this.Pattern.fromJSON({
      id: "new",
      name: "",
      doc_type: "",
      view: "",
      active: false,
    });
  }

  public updateGroups() {
    this.groups = _.groupBy(this.patterns, "doc_type");
  }
}
