import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IEstimateOverviewData>): EstimateOverviewRecord => {
  return new EstimateOverviewRecord(json);
};

export interface IEstimateOverviewData {
  id: number;
  name: string;
  line_items_count: number;
  total: number;

  created_at?: Date;
  updated_at: Date;
}

export interface IEstimateOverviewRecord {
  id: Nullable<number>;
  name: Nullable<string>;
  line_items_count: Nullable<number>;
  total: Nullable<number>;

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

export const defaultEstimateOverviewProps = {
  id: 0,
  name: null,
  line_items_count: 0,
  total: 0,
  created_at: null,
  updated_at: null,
};

export class EstimateOverviewRecord extends Record(defaultEstimateOverviewProps) implements IEstimateOverviewRecord {}
