import * as React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as tokenActions from "app2/src/reducers/token.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { connectFC } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { getAuthorization, token } from "app2/src/selectors/token.selectors";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { ConnectedRouter } from "connected-react-router/immutable";
import { history } from "app2/src/store";
import { Route, Switch } from "react-router";
import { push } from "connected-react-router/immutable";
import { pathname } from "app2/src/selectors/router.selectors";
import { PricePresentationModal } from "./PricePresentationModal";
import { selectedEstimateId } from "app2/src/selectors/job.selectors";

export interface PricePresentationButtonProps {
  isEstimateDirty: boolean;
}

export const PricePresentationButton: React.FC<PricePresentationButtonProps> = ({ isEstimateDirty }) => {
  // Hooks
  const dispatch = useDispatch();
  const path = useSelector(pathname);

  // Selectors
  const estimateId = useSelector(selectedEstimateId);
  const orgId = useSelector(currentOrgId);
  const authorized = useSelector((state: RootState) => getAuthorization(state, { integration: "ingage", orgId }));
  const ingageToken = useSelector((state: RootState) => token(state, { kind: "ingage" }));

  // Lifecycle
  React.useEffect(() => {
    dispatch(tokenActions.AsyncActions.getToken(orgId, "ingage"));
  }, []);

  // Methods
  const isTokenValid = () => {
    const data = ingageToken?.get("data");
    if (!data) return false;

    if (
      !data.get("presentation_url") ||
      data.get("finance_options")?.size < 1 ||
      !data.get("discount") ||
      data.get("packages")?.size < 1
    )
      return false;

    return true;
  };

  const handleOnClick = () => {
    if (!isTokenValid()) {
      dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, "Invalid Price Presentation Configuration"));
      return;
    }

    // open modal with url in iframe
    dispatch(push(`${path}/price_presentation`));
  };

  if (estimateId === "new" || estimateId === null) return null;

  return (
    <>
      {authorized && (
        <Button
          className="float-right"
          onClick={handleOnClick}
          disabled={isEstimateDirty}
          title={
            isEstimateDirty ? "Please save your current changes to utilize Price Presentation" : "Price Presentation"
          }>
          Price Presentation
        </Button>
      )}

      <Switch>
        <Route path="/jobs/:jobId/estimates/:estimateId/price_presentation" component={PricePresentationModal} />
      </Switch>
    </>
  );
};

export default connectFC((props: PricePresentationButtonProps) => (
  <ConnectedRouter history={history}>
    <PricePresentationButton {...props} />
  </ConnectedRouter>
));
