import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEstimate, ISalesTaxable } from "app/src/Models/Estimate";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { IOrg, OrgAclType, OrgPrefType } from "app/src/Models/Org";
import { IImageResource } from "app/src/Models/Image";
import { IDocResource } from "app/src/Models/Doc";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { EditLineItemCmd } from "app/src/Commands/Estimator/EditLineItemCmd";
import { AddEstimateLineItemCmd } from "app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { AddProviaOrderCmd } from "app/src/Commands/Estimator/AddProviaOrderCmd";
import { AddFeneTechOrderCmd } from "app/src/Commands/Estimator/AddFeneTechOrderCmd";
import { AddProductFromXmlCmd } from "app/src/Commands/Estimator/AddProductFromXmlCmd";
import { AddEstimateFromOccImportCmd } from "app/src/Commands/Estimator/AddEstimateFromOccImportCmd";
import { IJobEstimateAddedEvent } from "app/src/Estimator/EstimatorEvents";
import { validationMessage } from "app/src/Estimator/ValidationService";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { IDiscount } from "app/src/Models/Discount";
import { INavDisplayArgs } from "app/src/Jobs/JobShowCtrl";
import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import { IDirtyWatcher, IDirtyMerge } from "app/src/Common/DirtyWatcher";
import {
  IEstimateTemplateResource,
  IEstimateTemplate,
  IEstimateTemplateResponse,
  IEstimateTemplateGroups,
} from "app/src/Models/EstimateTemplate";
import { IEventingFactory } from "app/src/Common/EventingFactory";
import {
  IEstimateLineItemEvent,
  IEstimateLineItemGroupEvent,
  IEstimateLineItemOpeningEvent,
} from "app/src/Estimator/EstimateLineItemComponent";
import { IFileQueueFactory, IFileQueueInit } from "app/src/Common/FileQueueFactory";
import { EditSalesTaxCmd } from "app/src/Commands/Estimator/EditSalesTaxCmd";
import { IJob } from "app/src/Models/Job";
import { AddProductFromXlsxCmd } from "app/src/Commands/Estimator/AddProductFromXlsxCmd";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IOrgPrefEstimator } from "../Models/OrgPreference";
import { ISession } from "../Common/SessionService";
import * as ng from "angular";
import { IPretty } from "app/src/Common/PrettyNameService";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import * as estimatorActions from "app2/src/reducers/components/estimator.actions";
import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { PaymentTermItemRecord, PaymentTermTemplateRecord } from "app2/src/records/PaymentTermTemplate";
import { ProductRecord } from "app2/src/records/Product";
import { cachedProduct, cachedProducts } from "app2/src/selectors/product.selectors";
import * as jobActions from "app2/src/reducers/job.actions";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { subscriber } from "app2/src/helpers/Subscribe";
import { calculatedFinancingsLoading } from "app2/src/selectors/calculatedFinancing.selectors";
import { List } from "immutable";
import { currentOrgId, presentModePreferencesConfig } from "app2/src/selectors/org.selectors";
import { RoomRecord } from "app2/src/records/Room";
import { RootState } from "app2/src/reducers";
import { rooms as roomsSelector } from "app2/src/selectors/room.selectors";
import { estimateUnsavedId } from "app2/src/selectors/estimate.selectors";
import { push } from "connected-react-router/immutable";
import { token as tokenSelector } from "app2/src/selectors/token.selectors";
import * as tokenActions from "app2/src/reducers/token.actions";

class EstimateEditorCtrl implements IDirtyMerge {
  public job: IJob;
  public showLoanCalc = false;
  public activeGroupTab: any;
  public spinnerPromise: ng.IPromise<any>;
  public popups: any = {
    estimated_start_opened: false,
    estimated_end_opened: false,
  };
  public estimate: IEstimate;
  public org: IOrg;
  public priceListCurrent = true;
  public selectedLineItem: IEstimateLineItem;
  public navDisplay: boolean;
  public editNameStatus = false;
  public templates: IEstimateTemplateResponse;
  public collapsedView = false;
  public tourEditNameAction: boolean[];
  public sortableOptions = {
    items: ".group-tab",
    distance: 15,
    stop: () => {
      this.EventingFactory.trackEvent("group tabs reordered", {});
      this.estimate.orderGroups();
    },
  };
  public modalActive = false;
  public addProductCalled = false;
  public showAds = false;
  public addGroupTabs: any[] = [];
  public fileQueue: IFileQueueFactory;
  public capitalizeTemplate: string;
  public openDrawer = 0;
  public calculatedFinancingsLoading = false;
  public show = 0;
  public selectorMode = true;
  public showTotalSection = false;
  public showDiscountAndPaymentEstimator = false;

  //noinspection JSUnusedGlobalSymbols
  public dateOptions: ng.ui.bootstrap.IDatepickerConfig = {
    formatYear: "yy",
    minDate: new Date(),
    startingDay: 1,
  };
  public endDateOptions: ng.ui.bootstrap.IDatepickerConfig = {
    formatYear: "yy",
    minDate: "ctrl.estimate.estimated_start",
    startingDay: 1,
  };

  private _calculatedFinancingSub: () => void;

  constructor(
    public BaseConfig: IBaseConfig,
    public EstimatorService: EstimatorService,
    public Image: IImageResource,
    public Doc: IDocResource,
    public Flash: IFlash,
    public Upload: ng.angularFileUpload.IUploadService,
    public EventingFactory: IEventingFactory,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $stateParams: ng.ui.IStateParamsService,
    public $scope: ng.IScope,
    public $rootScope: ng.IScope,
    public $q: ng.IQService,
    public $timeout: ng.ITimeoutService,
    public ConfirmDialog: IConfirmDialog,
    public $state: ng.ui.IStateService,
    public DirtyWatcher: IDirtyWatcher,
    public EstimateTemplate: IEstimateTemplateResource,
    public EstimatorTourService: any,
    public FileQueue: IFileQueueInit,
    public $http: ng.IHttpService,
    public valid_kind: string[],
    public valid_uom: string[],
    public Session: ISession,
    public $document: ng.IDocumentService,
    public Pretty: IPretty,
    public JobFetcher: IJobFetcherService,
  ) {
    const navListener = $rootScope.$on("nav:display", (event: ng.IAngularEvent, args: INavDisplayArgs) => {
      this.navDisplay = args.navDisplay;
      this.setTotalSection();
      this.setDiscountAndPaymentEstimator();
    });

    const helpListener = $rootScope.$on("header:help:estimator", (event: ng.IAngularEvent, args: INavDisplayArgs) => {
      this.EstimatorTourService.help().then(() => {
        this.tourInit();
      });
    });

    $scope.$on("$destroy", () => {
      navListener();
      helpListener();
      this._calculatedFinancingSub();
    });

    $scope.$on("estimateLineItem.edit", (event: ng.IAngularEvent, args: IEstimateLineItemEvent) => {
      if (this.lineItemEmitSave()) {
        const listener = this.$rootScope.$on("estimateLineItem.confirmed", (event, args: any) => {
          if (args.continueOn) {
            this.editLineItem(args.estimateLineItem);
          }
          listener();
        });
      } else {
        this.editLineItem(args.estimateLineItem);
      }
    });

    $scope.$on("estimateLineItem.edited", (event: ng.IAngularEvent, args: IEstimateLineItemGroupEvent) => {
      this.fileQueue.mergeQueue(args.newEstimateLineItem, args.temp_queue);
      this.fileQueue.setQueue();
      this.selectedLineItem = args.oldEstimateLineItem;
      this.lineItemEdited(args.newEstimateLineItem, args.estimateGroup);
    });

    $scope.$on("estimateLineItem.collapse", (event: ng.IAngularEvent, args: any) => {
      this.collapsedView = args.collapsedView;
    });

    $scope.$on("estimateLineItem.add_opening", (event: ng.IAngularEvent, args: IEstimateLineItemOpeningEvent) => {
      this._handle_opening(args.product, args.estimateGroup);
    });

    $scope.$on("estimateLineItem.import_selected", (event: ng.IAngularEvent, args: IEstimateLineItemOpeningEvent) => {
      this.modalSetup("import", args.estimateGroup);
    });

    $scope.$on("estimateLineItem.fenetech_order", (event: ng.IAngularEvent, args: any) => {
      this._handle_fenetech(args.data, args.estimateGroup);
    });

    this._calculatedFinancingSub = subscriber.subscribe<string>("estimates.calculatedFinancingByFinancedAmount", () => {
      this.setCalculatedFinancingsLoading();
      this.$timeout(() => {
        this.$scope.$digest();
      });
    });

    const netSuiteToken = useSelector((state: RootState) =>
      tokenSelector(state, {
        kind: "net_suite",
      }),
    );
    const isLoaded = netSuiteToken?.getIn(["data", "events", "estimate"]);
    if (!isLoaded) {
      const orgId = useSelector(currentOrgId);
      dispatch(tokenActions.AsyncActions.getToken(orgId, "net_suite"));
    }

    DirtyWatcher.setup($scope, this);
    this.fileQueue = FileQueue.getInstance();

    this.tourEditNameAction = [false];
    this.capitalizeTemplate = _.toTitleCase(this.Pretty.name["template"]);

    this.addPaymentTermTemplate = this.addPaymentTermTemplate.bind(this);
    this.addPaymentTermItem = this.addPaymentTermItem.bind(this);
    this.removePaymentTermItem = this.removePaymentTermItem.bind(this);
    this.updatePaymentTermItem = this.updatePaymentTermItem.bind(this);
    this.markupUpdated = this.markupUpdated.bind(this);
    this.save = this.save.bind(this);
    this.saveAsCallback = this.saveAsCallback.bind(this);
    this.selectTemplateCallback = this.selectTemplateCallback.bind(this);
  }

  public $onChanges(): void {
    let estimateId = this.$stateParams["estimate_id"];
    if (estimateId === ("new" as any as number)) {
      this.editNameStatus = true;
    } else {
      estimateId = parseInt(estimateId);
    }

    if (!this.estimate && (estimateId >= 0 || estimateId === ("new" as any as number))) {
      this.estimate = this.EstimatorService.loadEstimate(estimateId);
      this.spinnerPromise = this.estimate.$promise.then(() => {
        this.org = this.EstimatorService.org;
        this.setTotalSection();
        this.setDiscountAndPaymentEstimator();
        if (this.org.can("layered", OrgAclType.estimator, "multiple_groups")) {
          this.addGroupTabs.push({});
        }

        return this.EstimatorService.priceListPromise.then(() => {
          this.priceListCurrent =
            this.estimate.activated_price_list_id === this.org.activated_price_list.id ||
            estimateId === ("new" as any as number);
          this.EventingFactory.init({
            category: "Estimate",
            estimate: this.estimate.id,
            job: this.estimate.job_id,
            org: this.org.id,
            navDisplay: !this.navDisplay,
          });
          this.tourInit();
          const pref: any = this.org.fetchPref(OrgPrefType.estimator);
          if (
            estimateId === ("new" as any as number) &&
            !this.addProductCalled &&
            pref.show_product_selector === "always"
          ) {
            this.addProductCalled = true;
            this.addLineItem();
          }
        });
      });
    }
  }

  public setTotalSection() {
    this.showTotalSection = useSelector((state) =>
      presentModePreferencesConfig(state, {
        path: ["estimator", "show_pricing", "total_section"],
      }),
    );
  }

  public setDiscountAndPaymentEstimator() {
    this.showDiscountAndPaymentEstimator = useSelector((state) =>
      presentModePreferencesConfig(state, {
        path: ["estimator", "show_pricing", "discount_and_payment_estimator"],
      }),
    );
  }

  public setCalculatedFinancingsLoading() {
    const financedAmount = this.estimate.payment_terms.financed_amount;
    const financeOptionIds = List(_.pluck(this.estimate.finance_options, "finance_option_id"));
    this.calculatedFinancingsLoading = useSelector((state) =>
      calculatedFinancingsLoading(state, {
        financedAmount,
        financeOptionIds,
      }),
    );
  }

  public toggleAds(digest = false) {
    this.showAds = !this.showAds;
    if (digest) {
      this.$scope.$digest();
    }
  }

  public salesTaxUpdated(salesTaxable: ISalesTaxable): void {
    const cmd = new EditSalesTaxCmd();
    cmd.execute(this.estimate, salesTaxable);
  }

  public total(): number {
    // @ts-ignore
    if (this.estimate && this.estimate.$promise.$$state.status === 1) {
      return this.estimate.updateTotal();
    }

    return 0;
  }

  public openDate(value): void {
    this.popups[value] = !this.popups[value];
  }

  public updateMinDate(): void {
    if (this.estimate.estimated_start) {
      this.endDateOptions.minDate = this.estimate.estimated_start;

      if (this.estimate.estimated_start > this.estimate.estimated_end || !this.estimate.estimated_end) {
        this.estimate.estimated_end = this.estimate.estimated_start;
      }
    }
  }

  public editLineItem(line_item: IEstimateLineItem, removable = false): void {
    const noProduct = _.isNull(line_item.product_id) || _.isUndefined(line_item.product_id);
    if (!noProduct) {
      const product = useSelector((state) =>
        cachedProduct(state, {
          activatedPriceListId: this.estimate.activated_price_list_id,
          productId: line_item.product_id,
        }),
      );
      let parent: ProductRecord;
      if (product.parent_id) {
        parent = useSelector((state) =>
          cachedProduct(state, {
            activatedPriceListId: this.estimate.activated_price_list_id,
            productId: product.parent_id,
          }),
        );
      }

      if (
        _.include(this.org.fetchSettingsAcl(OrgAclType.estimator), "window") &&
        product &&
        parent &&
        (parent.kind === "window" || parent.kind === "door")
      ) {
        const modal = this.windowModal(parent, line_item.estimateGroup, line_item.clone());
        this.EventingFactory.trackEvent("edit opening line item", { category: "EstimateLineItemComponent" });
        modal.result
          .then((result) => {
            const cmd = new EditLineItemCmd(result[0], line_item, this.estimate.existingGroups(), result[1]);
            cmd.execute();
          })
          .finally(() => {
            this.modalActive = false;
          });

        return;
      }
    }
    line_item.editing = true;
    this.$timeout(() => {
      this.scrollIntoView(line_item.id.toString());
    });

    if (!removable) {
      this.EventingFactory.trackEvent("edit line item", { category: "EstimateLineItemComponent" });
    }
  }

  public scrollIntoView(id: string) {
    const ele = document.getElementById(id);
    if (ele) {
      this.scrollElementIntoView(ele, "smooth");
    }
  }

  public scrollElementIntoView(element: HTMLElement, behavior?: ScrollBehavior) {
    const scrollTop = window.pageYOffset || element.scrollTop;
    const finalOffset = element.getBoundingClientRect().top + scrollTop - window.innerHeight / 2;
    window.parent.scrollTo({
      top: finalOffset,
      behavior: behavior || "auto",
    });
  }

  public showEditName(): boolean {
    if (!this.estimate) {
      return false;
    }

    return this.editNameStatus;
  }

  public deleteEstimate(): void {
    if (this.estimate) {
      this.estimate
        .$delete()
        .then(() => {
          this.Flash.addMessage(FlashLevels.success, "Estimate Deleted");
          this.trackEvent("estimate delete", {
            category: "Estimate",
          });
          this.closeEstimate();
        })
        .catch((err) => {
          this.Flash.addMessage(FlashLevels.danger, "Problems deleting Estimate!!");
        });
    }
  }

  public lineItemEdited(lineItem: IEstimateLineItem, group: IEstimateGroup): void {
    const cmd = new EditLineItemCmd(lineItem, this.selectedLineItem, this.estimate.existingGroups(), group);
    cmd.execute();

    this.selectedLineItem = null;

    this.EventingFactory.trackEvent("line item edited", { category: "EstimateLineItemComponent" });
  }

  public modalSetup(type: string, group: IEstimateGroup = undefined): void {
    this.EventingFactory.trackEvent("add product", {});
    this.productModal(type).result.then(
      (selectedItem) => {
        group = this.checkGroup(group);
        this["_handle_" + selectedItem.type](selectedItem.data, group);
      },
      () => {
        this.modalActive = false;
      },
    );
  }

  public addLineItem(group: IEstimateGroup = undefined): void {
    if (this.lineItemEmitSave()) {
      const listener = this.$rootScope.$on("estimateLineItem.confirmed", (event, args: any) => {
        if (args.continueOn) {
          group = this.checkGroup(group);
          this._handle_custom("line_item", group);
        }
        listener();
      });
    } else {
      group = this.checkGroup(group);
      this._handle_custom("line_item", group);
    }
  }

  public lineItemEmitSave(): boolean {
    const lineItemEditing = this.EstimatorService.lineItemEditorOpen();
    if (lineItemEditing && lineItemEditing.editing) {
      this.$scope.$emit("estimateLineItem.save." + lineItemEditing.id);
    }
    return lineItemEditing && lineItemEditing.editing;
  }

  public togglePaymentOptionEditor(): void {
    dispatch(estimatorActions.Actions.setPaymentOptionEditorVisible(true));
  }

  public _handle_product(product: ProductRecord, group: IEstimateGroup): void {
    const cmd = new AddEstimateLineItemCmd(this.estimate, product, group, this.EstimatorService);
    cmd.execute();

    this.EventingFactory.trackEvent("product added", {
      product: product.id,
      estimate_group_name: group.name,
      estimate_group_id: group.id,
    });
    this.editLineItem(cmd.lineItem, true);
  }

  public _handle_template(template: IEstimateTemplate): void {
    const type = template.kind === "interior" ? "interior_template" : "template";
    this.estimateImportOptions(template, undefined, type);
  }

  public _handle_opening(product: ProductRecord, group: IEstimateGroup): void {
    this.EventingFactory.trackEvent("opening product added", {
      product: product.id,
      estimate_group_name: group.name,
      estimate_group_id: group.id,
    });
    this.windowModal(product, group)
      .result.then(() => {})
      .finally(() => {
        this.modalActive = false;
      });
  }

  public _handle_provia(data, group): void {
    this.EventingFactory.trackEvent("add provia", {});
    const cmd = new AddProviaOrderCmd(
      this.estimate,
      data,
      group,
      this.fileQueue,
      this.$http,
      this.spinnerPromise,
      this.$q,
      this.EstimatorService,
    );
    if (cmd.execute() === false) {
      this.Flash.addMessage(FlashLevels.danger, "There are no products in that ProVia Order.");
    }
    this.modalActive = false;
  }

  public _handle_fenetech(data, group): void {
    this.EventingFactory.trackEvent("add fenetech", {});
    const cmd = new AddFeneTechOrderCmd(
      this.estimate,
      data,
      group,
      this.fileQueue,
      this.$http,
      this.spinnerPromise,
      this.$q,
      this.EstimatorService,
    );
    if (cmd.execute() === false) {
      this.Flash.addMessage(FlashLevels.danger, "There are no products in that FeneTech Order.");
    }
  }

  public _handle_xml(xml_data, group): void {
    this.EventingFactory.trackEvent("handle xml", {});
    const cmd = new AddProductFromXmlCmd(this.estimate, xml_data, group, this.fileQueue, this.EstimatorService);
    if (cmd.execute() === false) {
      this.Flash.addMessage(
        FlashLevels.danger,
        "The file you're trying to import is not supported or does not have products to import.",
      );
    }
    this.modalActive = false;
  }

  public _handle_xlsx(data, group): void {
    this.EventingFactory.trackEvent("handle xlsx", {});
    const cmd = new AddProductFromXlsxCmd(this.estimate, data, group, this.EstimatorService);
    if (cmd.execute() === false) {
      this.Flash.addMessage(
        FlashLevels.danger,
        "The file you're trying to import is not supported or does not have products to import.",
      );
    }
    this.modalActive = false;
  }

  public _handle_custom(product_string, group): void {
    this._handle_product(product_string, group);
  }

  public _handle_occ_import(data, group): void {
    this.estimateImportOptions(data, group, "occ_import");
  }

  public estimateImportOptions(data, group, type): void {
    switch (this.org.fetchPref<IOrgPrefEstimator>(OrgPrefType.estimator).add_products) {
      case "replace":
        this.replaceEstimate(data, group, type);
        break;
      case "merge":
        this.mergeEstimate(data, group, type);
        break;
      case "ask":
        if (this.estimate.lineItems().length === 0) {
          this.replaceEstimate(data, group, type);
        } else {
          this.ConfirmDialog.confirm("Would you like to Replace or Merge into the current estimate?", {
            template:
              '<div class="merge-replace confirm-modal modal-header"><div class="modal-title"><h3>{{msg}}</h3></div></div><div class="modal-footer"><button class="btn btn-merge" type="button" ng-click="$close()">Merge</button><button class="btn btn-replace" type="button" ng-click="$dismiss(false)">Replace</button></div>',
          })
            .then(() => {
              try {
                this.mergeEstimate(data, group, type);
              } catch (err) {
                console.error(err);
              }
            })
            .catch(() => {
              this.replaceEstimate(data, group, type);
            });
        }
        break;
    }
  }

  public mergeEstimate(data, group, type): void {
    this.EventingFactory.trackEvent("merge estimate with template", {});
    _.each(this.estimate.existingGroups(), (group) => {
      if (group.line_items.length === 0) {
        this.removeGroup(group);
      }
    });

    this.modalActive = false;
    this["add_" + type](data, group);
  }

  public replaceEstimate(data, group, type): void {
    this.EventingFactory.trackEvent("replace estimate with template", {});
    if (this.estimate.lineItems().length > 0) {
      this.ConfirmDialog.confirm(
        `There are products in the estimate that will be lost. Are you sure you want to replace them with this ${this.Pretty.name["template"]}?`,
      ).then(() => {
        _.each(this.estimate.groups, (eg) => {
          this.removeGroup(eg);
        });
        this.fileQueue.clearQueue();
        this.modalActive = false;
        this["add_" + type](data, group);
      });
    } else {
      _.each(this.estimate.groups, (eg) => {
        this.removeGroup(eg);
      });
      this.fileQueue.clearQueue();
      this.modalActive = false;
      this["add_" + type](data, group);
    }
  }

  public checkGroup(group): any {
    if (_.isUndefined(group)) {
      group = this.estimate.existingGroups()[0];
    }

    if (_.isUndefined(group)) {
      this.EventingFactory.trackEvent("estimate group created", {
        estimate_group_name: "",
      });
      group = this.estimate.createGroup({ name: "Included", included: true });
    }
    return group;
  }

  public addGroup(): void {
    this.EventingFactory.trackEvent("new group added", {});
    const group = this.estimate.createGroup({ included: false, name: "Group" });

    this.$timeout(() => {
      this.activeGroupTab = this.estimate.existingGroups()[this.estimate.existingGroups().length - 1].$$hashKey;
      this.$scope.$broadcast("estimateGroup.edit", { estimateGroup: group });
    }, 0);
  }

  public add_template(est_template: IEstimateTemplate): void {
    this.EstimatorService.setupTemplate(est_template);
    this.EventingFactory.trackEvent("add estimate template", {
      estimate_template: est_template.id,
      kind: est_template.kind,
    });
    this.spinnerPromise = est_template.addTemplate(
      this.estimate,
      this.EstimatorService,
      this.fileQueue,
      this.EventingFactory,
      this.$q,
      this.Flash,
    );
  }

  public add_interior_template(est_template: IEstimateTemplate): void {
    this.EstimatorService.setupTemplate(est_template);

    const rooms = useSelector((state: RootState) => roomsSelector(state, { roomIds: est_template.selectedRoomIds }));

    this.EventingFactory.trackEvent("add estimate template", {
      estimate_template: est_template.id,
      kind: "interior",
      roomQty: rooms.size,
      groupRooms: est_template.groupRooms,
    });

    if (est_template.groupRooms) {
      this.spinnerPromise = est_template.addInteriorTemplate(
        this.estimate,
        this.EstimatorService,
        this.fileQueue,
        this.EventingFactory,
        this.$q,
        this.Flash,
        rooms,
      );
    } else {
      const promises = [];
      rooms.forEach((r: RoomRecord, idx) => {
        promises.push(
          est_template.addInteriorTemplate(
            this.estimate,
            this.EstimatorService,
            this.fileQueue,
            this.EventingFactory,
            this.$q,
            this.Flash,
            List([r]),
            idx === rooms.size - 1, // only show message after last group is created
          ),
        );
      });
      this.spinnerPromise = this.$q.all(promises);
    }
  }

  public add_occ_import(data, group): void {
    this.EventingFactory.trackEvent("add occ import", {});
    const cmd = new AddEstimateFromOccImportCmd(
      this.estimate,
      data,
      group,
      this.fileQueue,
      this.EstimatorService,
      this.valid_kind,
      this.valid_uom,
      this.$q,
    );
    if (cmd.execute() === false) {
      this.Flash.addMessage(FlashLevels.danger, "There was an error importing the file. See Error Message!");
      cmd.errors.unshift("Check your import file and try again. Errors below:");
      this.ConfirmDialog.confirm(cmd.errors.join("\n").trim(), {
        size: "lg",
        template:
          '<div class="confirm-modal modal-header"><div class="modal-title"><p>{{msg}}</p></div></div><div class="modal-footer"><button class="btn btn-default" type="button" ng-click="$close()">OK</button></div>',
      });
    } else if (cmd.errors.length > 0) {
      this.Flash.addMessage(FlashLevels.danger, "There was an error importing the file. See Error Message!");
      cmd.errors.unshift("Check your import file and try again. Errors below:");
      this.ConfirmDialog.confirm(cmd.errors.join("\n").trim(), {
        size: "lg",
        template:
          '<div class="confirm-modal modal-header"><div class="modal-title"><p>{{msg}}</p></div></div><div class="modal-footer"><button class="btn btn-default" type="button" ng-click="$close()">OK</button></div>',
      });
    } else {
      this.spinnerPromise = cmd.promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "The file was successfully imported!");
      });
    }
  }

  public saveAsTemplate(): void {
    if (this.lineItemEmitSave()) {
      const listener = this.$rootScope.$on("estimateLineItem.confirmed", (event, args: any) => {
        if (args.continueOn) {
          this.confirmSaveAsTemplate();
        }
        listener();
      });
    } else {
      this.confirmSaveAsTemplate();
    }
  }

  public confirmSaveAsTemplate() {
    if (this.org.activated_price_list.id !== this.estimate.activated_price_list_id) {
      this.Flash.addMessage(
        FlashLevels.warning,
        `Please use a new estimate to generate an estimate ${this.Pretty.name["template"]}.`,
      );
      return;
    }

    this.EventingFactory.trackEvent("open template modal", {});
    this.selectorMode = false;
    this.show += 1;
  }

  public saveAsCallback(selection: string | IEstimateTemplate, folderId: number, kind: string) {
    _.each(this.estimate.lineItems(), (li) => {
      this.fileQueue.cleanFileQueue(li, false);
    });
    if (_.isString(selection)) {
      let template: IEstimateTemplateGroups;
      try {
        template = this.EstimatorService.setupNewTemplate();
        if (kind === "interior") {
          template.groups = [template.groups[0]];
        }
      } catch (err) {
        this.Flash.addMessage(
          FlashLevels.warning,
          `Unable to Create My Estimate ${this.capitalizeTemplate}. If the problem persists, contact support.`,
        );
        console.error(err);
        return;
      }

      if (folderId <= 0) {
        folderId = null;
      }

      this.spinnerPromise = this.EstimatorService.createTemplate({
        name: selection,
        template: template,
        folder_id: folderId,
        kind,
      })
        .then((est_template) => {
          this.EventingFactory.trackEvent("new template saved", { estimate_template: est_template.id });
          this.spinnerPromise = this.addFilesToTemplate(est_template).then(() => {
            this.Flash.addMessage(FlashLevels.success, `My Estimate ${this.capitalizeTemplate} Successfully Created.`);
          });
        })
        .catch(() => {
          this.Flash.addMessage(
            FlashLevels.warning,
            `Unable to Create My Estimate ${this.capitalizeTemplate}. If the problem persists, contact support.`,
          );
        });
    } else {
      this.EventingFactory.trackEvent("edit existing template", { estimate_template: selection.id });
      try {
        selection = this.EstimateTemplate.fromJSON(selection);
        selection.template = this.EstimatorService.setupNewTemplate();

        if (selection.kind === "interior") {
          selection.template.groups = [selection.template.groups[0]];
        }
      } catch (err) {
        this.Flash.addMessage(
          FlashLevels.warning,
          `Unable to Update My Estimate ${this.capitalizeTemplate}. If the problem persists, contact support.`,
        );
        console.error(err);
        return;
      }

      this.spinnerPromise = this.EstimatorService.updateTemplate(selection)
        .then((est_template) => {
          return this.addFilesToTemplate(est_template).then(() => {
            this.Flash.addMessage(FlashLevels.success, `My Estimate ${this.capitalizeTemplate} Successfully Updated.`);
          });
        })
        .catch(() => {
          this.Flash.addMessage(
            FlashLevels.warning,
            `Unable to Update My Estimate ${this.capitalizeTemplate}. If the problem persists, contact support.`,
          );
        });
    }
  }

  public addFilesToTemplate(et: IEstimateTemplate): ng.IPromise<any> {
    const promises = [];
    promises.push(this.fileQueue.uploadFileQueue(et));
    promises.push(
      et.uploadFromLineItemFiles(this.estimate, this.fileQueue, this.$q, this.EventingFactory, this.EstimatorService),
    );
    return this.$q.all(promises);
  }

  public windowModal(
    parentProduct: ProductRecord,
    group: IEstimateGroup,
    estimateLineItem: IEstimateLineItem = null,
  ): ng.ui.bootstrap.IModalServiceInstance {
    this.modalActive = true;
    const parentChildren = useSelector((state) =>
      cachedProducts(state, {
        activatedPriceListId: this.estimate.activated_price_list_id,
        productIds: parentProduct.children_ids,
      }),
    );
    return this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: false,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "OpeningsWizard",
      controllerAs: "$ctrl",
      backdrop: "static",
      templateUrl: "src/Estimator/openings_wizard.html",
      size: "xl",
      windowClass: "product-selector",

      resolve: {
        estimate: this.estimate,
        org: this.org,
        parentProduct: parentProduct,
        parentChildren: parentChildren,
        group: group,
        estimateLineItem: estimateLineItem,
        eventingFactory: this.EventingFactory,
        navDisplay: this.navDisplay,
        groups: () => this.estimate.existingGroups(),
      },
    });
  }

  public addTemplate() {
    if (this.lineItemEmitSave()) {
      const listener = this.$rootScope.$on("estimateLineItem.confirmed", (event, args: any) => {
        if (args.continueOn) {
          this.estimateTemplateModal();
        }
        listener();
      });
    } else {
      this.estimateTemplateModal();
    }
  }

  public estimateTemplateModal() {
    this.selectorMode = true;
    this.show += 1;
  }

  public selectTemplateCallback(selection: IEstimateTemplate) {
    this._handle_template(this.EstimateTemplate.fromJSON(selection));
  }

  public productModal(type: string): ng.ui.bootstrap.IModalInstanceService {
    this.modalActive = true;
    return this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "ProductSelectorCtrl",
      controllerAs: "ctrl",
      backdrop: "static",
      templateUrl: "src/Estimator/productSelector.html",
      size: "lg",
      windowClass: "product-selector",

      resolve: {
        setup: { type: type },
        navDisplay: this.navDisplay,
        templates: this.templates,
        org: this.org,
        fileQueue: this.fileQueue,
      },
    });
  }

  public removeGroup(group: IEstimateGroup): void {
    if (group.id > 0) {
      group._destroy = true;
    } else {
      this.estimate.groups = _.filter(this.estimate.groups, (eg) => {
        return eg.id !== group.id;
      });
    }
  }

  // if we have no estimate comparison, make one now
  // but dont save here.
  public async showEstimateComparisons() {
    this.openDrawer += 1;
  }

  public addPaymentTermTemplate(template: PaymentTermTemplateRecord): void {
    this.EventingFactory.trackEvent("payment term template added", {
      payment_term_template: template.id,
    });

    this.estimate.payment_terms = this.estimate.payment_terms.merge({
      id: template.id,
      name: template.name,
      description: template.description,
      payment_term_items: template.breakdown.items,
    });
    this.total();
  }

  public addPaymentTermItem(item: PaymentTermItemRecord): void {
    this.EventingFactory.trackEvent("payment term added", {
      payment_term: JSON.parse(angular.toJson(item)),
    });
    this.estimate.payment_terms = this.estimate.payment_terms.update("payment_term_items", (ptis) => {
      return ptis.push(item);
    });

    this.total();
  }

  public removePaymentTermItem(item: PaymentTermItemRecord): void {
    this.EventingFactory.trackEvent("payment term removed", {
      payment_term: JSON.parse(angular.toJson(item)),
    });

    this.estimate.payment_terms = this.estimate.payment_terms.update("payment_term_items", (ptis) => {
      return ptis.filter((pti) => pti.uuid !== item.uuid);
    });

    this.total();
  }

  public updatePaymentTermItem(item: PaymentTermItemRecord): void {
    this.EventingFactory.trackEvent("payment term updated", {
      payment_term: JSON.parse(angular.toJson(item)),
    });

    const index: number = this.estimate.payment_terms.payment_term_items.findIndex((pti) => pti.uuid === item.uuid);
    this.estimate.payment_terms = this.estimate.payment_terms.setIn(["payment_term_items", index], item);

    this.total();
  }

  //noinspection JSUnusedGlobalSymbols
  public discountAdded(discount: IDiscount): void {
    this.EventingFactory.trackEvent("discount added", {
      discount: JSON.parse(angular.toJson(discount)),
    });
    this.estimate.discounts = this.estimate.discounts.concat([discount]);
    this.estimate.updateTotal();
  }

  //noinspection JSUnusedGlobalSymbols
  public discountUpdated(discount: IDiscount): void {
    this.EventingFactory.trackEvent("discount updated", {
      discount: JSON.parse(angular.toJson(discount)),
    });
    _.find(this.estimate.discounts, (d: IDiscount, idx: number) => {
      if (d.uuid === discount.uuid) {
        this.estimate.discounts[idx] = discount;
        return true;
      }

      return false;
    });
    this.estimate.updateTotal();
  }

  //noinspection JSUnusedGlobalSymbols
  public discountRemoved(discount: IDiscount): void {
    this.EventingFactory.trackEvent("discount removed", {
      discount: JSON.parse(angular.toJson(discount)),
    });
    this.estimate.discounts = _.filter(this.estimate.discounts, (d: IDiscount) => {
      return d.uuid !== discount.uuid;
    });
    this.estimate.updateTotal();
  }

  public save(): ng.IPromise<void> {
    if (this.lineItemEmitSave()) {
      const listener = this.$rootScope.$on("estimateLineItem.confirmed", (event, args: any) => {
        listener();
        if (args.continueOn) {
          return this.saveEstimate();
        }
      });
    } else {
      return this.saveEstimate();
    }
  }

  public saveEstimate(): ng.IPromise<any> {
    const newEstimate = !(this.estimate.id > 0);
    let validated = true;
    const validation_results = [];
    _.each(this.estimate.lineItems(), (li) => {
      this.fileQueue.cleanFileQueue(li, true);
      if (li.estimateGroup.included) {
        validated = validated && li.validated;
        if (!li.validated) {
          validation_results.push(li.validation_result);
        }
      }
    });

    if (!validated) {
      validationMessage(validation_results, this.ConfirmDialog);
      return;
    }

    this.spinnerPromise = this.EstimatorService.saveEstimate()
      .then(() => {
        this.estimate = this.EstimatorService.estimate;
        return this.fileQueue.uploadFileQueue(this.estimate).then(() => {
          this.estimate = this.EstimatorService.receiveAndSetReduxEstimate(this.estimate);
          this.estimate.line_items_count = this.estimate.lineItems().length;
          return this.estimate.$promise.then(() => {
            if (newEstimate) {
              // Event category & action used for analytics job reporting - do not rename and keep upon refactor
              this.EventingFactory.trackEvent("saved", {
                estimate: this.estimate.id,
              });
              this.$rootScope.$emit("job:estimate_added", <IJobEstimateAddedEvent>{
                id: this.estimate.job_id,
                estimate: this.estimate,
              });
            } else {
              this.EventingFactory.trackEvent("updated", {});
              this.$rootScope.$emit("job:estimate_updated", <IJobEstimateAddedEvent>{
                id: this.estimate.job_id,
                estimate: this.estimate,
              });
            }

            this.Flash.addMessage(FlashLevels.success, this.org.estimatorTitle() + " was successfully saved.");
          });
        });
      })
      .catch((err) => {
        console.error(err);
        this.Flash.addMessage(
          FlashLevels.danger,
          "There was a problem saving the estimate. If the problem persists, contact support.",
        );
      });

    return this.spinnerPromise;
  }

  public close(): void {
    if (this.check()) {
      this.ConfirmDialog.confirm(
        "There are unsaved changes that will be lost.  Are you sure you want to navigate away?",
      )
        .then(() => {
          this.EstimatorService.reloadEstimate(this.estimate.id).$promise.then(() => {
            this.EventingFactory.trackEvent("close dirty", {});
            this.closeEstimate();
          });
        })
        .catch(() => {
          this.EventingFactory.trackEvent("don't close", {});
        });
    } else {
      this.closeEstimate();
    }
  }

  public closeEstimate(): void {
    dispatch(jobActions.Actions.setSelectedEstimateId(null));
    this.$state.go("job_header.job_show.estimates", { id: this.estimate.job_id }, { notify: false });
    this.EventingFactory.trackEvent("close", {});
  }

  public pdf(doc_type): void {
    if (!doc_type) {
      doc_type = "estimate";
    }
    this.EstimatorService.savePdf(undefined, doc_type);
  }

  public hidePriceListCaution(nav_display: boolean): boolean {
    if (nav_display) {
      return this.priceListCurrent;
    }
    return true;
  }

  public collapseChanged(): void {
    this.EventingFactory.trackEvent("overview collapse view toggled", {
      collapsedView: this.collapsedView,
    });
  }

  public tourInit(): void {
    this.EstimatorTourService.init({
      estimateCtrl: this,
    });
  }

  public checkTemplate() {
    const templates = this.templates || { estimate_templates: [] };
    return templates.estimate_templates.length === 0;
  }

  public markupUpdated(margins) {
    this.estimate.product_markup = margins.product_markup;
    this.estimate.labor_markup = margins.labor_markup;
    this.estimate.lineItems().forEach((lineItem) => {
      lineItem.calculate(this.estimate);
      lineItem.options.forEach((opt) => {
        opt.calculate(this.estimate);
      });
    });
    this.$timeout(() => {
      this.$scope.$digest();
    });
  }

  public reset(): ng.IPromise<any> {
    this.spinnerPromise = this.EstimatorService.reloadEstimate(this.estimate.id).$promise;
    return this.spinnerPromise;
  }

  public check(): boolean {
    if (!this.modalActive) {
      return this.EstimatorService.checkEstimateState();
    }
  }

  public trackEvent(action, props): void {
    this.EventingFactory.trackEvent(action, props);
  }

  public duplicateEstimate(): void {
    this.ConfirmDialog.confirm(`Are you sure you want to duplicate the estimate? ${this.estimate.name}`).then(
      async () => {
        const deferred: ng.IDeferred<void> = this.$q.defer();
        this.spinnerPromise = deferred.promise;
        try {
          const newEstimateId = useSelector(estimateUnsavedId);
          dispatch(estimateActions.Actions.duplicateEstimate(this.estimate.id));
          const response = await dispatch(estimateActions.AsyncActions.updateOrCreateEstimate(newEstimateId));
          deferred.resolve();
          dispatch(push(`/jobs/${this.job.id}/estimates/${response.id}`));
        } catch (e) {
          deferred.resolve();
          console.error(e);
        }
      },
    );
  }

  public openEmailOrderCsv(): void {
    this.trackEvent("open email order csv modal", {});
    dispatch(push(`/jobs/${this.estimate.job_id}/estimates/${this.estimate.id}/email_order_csv`));
  }

  public openManualTrigger(): void {
    this.trackEvent("open manual trigger modal", {});
    dispatch(push(`/jobs/${this.estimate.job_id}/estimates/${this.estimate.id}/manual_trigger`));
  }
}

export class EstimateEditor implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    navDisplay: "<",
    job: "<",
  };
  public templateUrl = "src/Estimator/estimate_editor.html";

  constructor() {
    this.controller = EstimateEditorCtrl;
    this.controller.$inject = [
      "BaseConfig",
      "EstimatorService",
      "Image",
      "Doc",
      "Flash",
      "Upload",
      "EventingFactory",
      "$uibModal",
      "$stateParams",
      "$scope",
      "$rootScope",
      "$q",
      "$timeout",
      "ConfirmDialog",
      "$state",
      "DirtyWatcher",
      "EstimateTemplate",
      "EstimatorTourService",
      "FileQueueFactory",
      "$http",
      "valid_kind",
      "valid_uom",
      "Session",
      "$document",
      "Pretty",
      "JobFetcher",
    ];
  }
}
