import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IDynamicPresentationLinkData>): DynamicPresentationLinkRecord => {
  const data: IDynamicPresentationLinkRecord = { ...(json as any) };

  return new DynamicPresentationLinkRecord(data);
};

export const toJSON = (record: DynamicPresentationLinkRecord): IDynamicPresentationLinkData => {
  const json = record.toJSON();

  if (json.id <= 0) {
    delete json.id;
  }

  if (json._destroy === false) {
    delete json._destroy;
  }

  return json;
};

export interface IDynamicPresentationLinkData {
  id: number;
  presentation_id: number;
  sort_order: number;
  kind: DynamicPresentationLinkKind;
  presentable_id: number;
  presentable_type: string;
  _destroy?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface IDynamicPresentationLinkRecord {
  id: number;
  presentation_id: Nullable<number>;
  kind: DynamicPresentationLinkKind;
  presentable_id: Nullable<number>;
  presentable_type: Nullable<string>;
  sort_order: number;
  _destroy: Nullable<boolean>;

  created_at: Date;
  updated_at: Date;
}

const defaultDynamicPresentationLinkProps: IDynamicPresentationLinkRecord = {
  id: 0,
  presentation_id: null,
  presentable_id: null,
  presentable_type: null,
  kind: "default",
  sort_order: 0,
  _destroy: false,
  created_at: new Date(),
  updated_at: new Date(),
};

export class DynamicPresentationLinkRecord
  extends Record(defaultDynamicPresentationLinkProps)
  implements IDynamicPresentationLinkRecord
{
  public readonly id!: number;
  public readonly presentation_id!: Nullable<number>;
  public readonly kind!: DynamicPresentationLinkKind;
  public readonly presentable_id!: Nullable<number>;
  public readonly presentable_type!: Nullable<string>;
  public readonly sort_order!: number;
  public readonly _destroy: Nullable<boolean>;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IDynamicPresentationLinkRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}

export type DynamicPresentationLinkKind = "default" | "inspection";
