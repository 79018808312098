import { Record, Map } from "immutable";
import { ProductOptionGroupRecord, IProductOptionGroupData, fromJSON as pogFromJSON } from "./ProductOptionGroup";
import { ProductRecord, IProductData } from "./Product";

export const fromJSON = (json: Partial<IProductToOptionGroupLinkData>) => {
  const recordData: IProductToOptionGroupLinkRecord = { ...(json as any) };

  return new ProductToOptionGroupLinkRecord(recordData);
};

export interface IProductToOptionGroupLinkData {
  id: number;
  option_group_id: number;
  group: IProductOptionGroupData;
  product_id: number;
  product: IProductData;
  options: any;
  required_option_group: boolean;
}

export interface IProductToOptionGroupLinkRecord {
  id: number;
  option_group_id: number;
  group: ProductOptionGroupRecord;
  product_id: number;
  product: ProductRecord;
  options: any;
  required_option_group: boolean;
}

const defaultProductToOptionGroupLinkProps: IProductToOptionGroupLinkRecord = {
  id: 0,
  option_group_id: 0,
  group: null,
  product_id: 0,
  product: null,
  options: Map,
  required_option_group: false,
};

export class ProductToOptionGroupLinkRecord
  extends Record(defaultProductToOptionGroupLinkProps)
  implements IProductToOptionGroupLinkRecord
{
  public id!: number;
  public option_group_id!: number;
  public group!: ProductOptionGroupRecord;
  public product_id!: number;
  public product!: ProductRecord;
  public options!: any;
  public required_option_group!: boolean;

  public constructor(values?: Partial<IProductToOptionGroupLinkRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
