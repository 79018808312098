import { Record, Map, List, fromJS } from "immutable";
import { Nullable, checkDate, PartialDeep } from "..";

export type PlanFamily = "estimates" | "leads";
export type PlanKind = "rsf" | "stripe";

export type PlanBillingMethod = "tiered_per_user" | "tiered_per" | "flat_per_user" | "flat_per";

export const fromJSON = (json: PartialDeep<IPlanData>): PlanRecord => {
  const record = { ...json } as any as IPlanRecord;

  if (json.stripe_data) {
    record.stripe_data = fromJS(json.stripe_data);
  }

  if (json.metadata) {
    record.metadata = fromJS(json.metadata);
  }

  if (json.parent) {
    record.parent = fromJSON(json.parent);
  }

  if (json.children_ids) {
    record.children_ids = List(json.children_ids);
  }

  if (json.created_at) {
    record.created_at = checkDate(json.created_at);
  }

  if (json.updated_at) {
    record.updated_at = checkDate(json.updated_at);
  }

  return new PlanRecord(record);
};

export const toJSON = (plan: PlanRecord): IPlanData => {
  return plan.toJS();
};

export interface IPlanData {
  id: number;
  amount: number;
  archive_number: string;
  archived_at: null | Date | string;
  billing_method: PlanBillingMethod;
  created: Date | string;
  currency: string;
  default: boolean;
  family: PlanFamily;
  in_stripe: boolean;
  interval: string;
  interval_count: number;
  is_active: boolean;
  kind: PlanKind;
  livemode: boolean;
  max_users: number;
  metadata: any;
  min_interval: string;
  min_interval_count: number;
  name: string;
  parent_id: number;
  statement_descriptor: string;
  stripe_data: any;
  stripe_id: string;
  trial_period_days: number;

  parent?: IPlanData;
  children_ids: number[];

  created_at: Date | string;
  updated_at: Date | string;

  loading?: boolean;
  errors?: string[];
}

export interface IPlanRecord {
  id: number;
  amount: number;
  archive_number: Nullable<string>;
  archived_at: Nullable<Date>;
  billing_method: PlanBillingMethod;
  created: Date;
  currency: string;
  default: boolean;
  family: PlanFamily;
  in_stripe: boolean;
  interval: string;
  interval_count: number;
  is_active: boolean;
  kind: PlanKind;
  livemode: boolean;
  max_users: number;
  metadata: Map<string, any>;
  min_interval: string;
  min_interval_count: number;
  name: string;
  parent_id: number;
  statement_descriptor: string;
  stripe_data: any;
  stripe_id: string;
  trial_period_days: number;

  parent: Nullable<PlanRecord>;
  children_ids: Nullable<List<number>>;
  children: Nullable<List<PlanRecord>>;

  created_at: Date;
  updated_at: Date;

  loading: boolean;
  errors: List<string>;
}

const defaultPlanProps: IPlanRecord = {
  id: 0,
  amount: 0,
  archive_number: null,
  archived_at: null,
  billing_method: "tiered_per_user",
  created: new Date(),
  currency: "usd",
  default: false,
  family: "estimates",
  in_stripe: false,
  interval: "month",
  interval_count: 1,
  is_active: false,
  kind: "rsf",
  livemode: false,
  max_users: 0,
  metadata: Map<string, any>(),
  min_interval: "month",
  min_interval_count: 0,
  name: "",
  parent_id: 0,
  statement_descriptor: "",
  stripe_data: Map<string, any>(),
  stripe_id: "",
  trial_period_days: 0,
  parent: null,
  children_ids: null,
  children: null,
  created_at: new Date(),
  updated_at: new Date(),
  loading: false,
  errors: List<string>(),
};

export class PlanRecord extends Record(defaultPlanProps) implements IPlanRecord {
  public readonly id!: number;
  public readonly amount!: number;
  public readonly archive_number!: Nullable<string>;
  public readonly archived_at!: Date;
  public readonly billing_method!: PlanBillingMethod;
  public readonly created!: Date;
  public readonly currency!: string;
  public readonly default!: boolean;
  public readonly family!: PlanFamily;
  public readonly in_stripe!: boolean;
  public readonly interval!: string;
  public readonly interval_count!: number;
  public readonly is_active!: boolean;
  public readonly kind!: PlanKind;
  public readonly livemode!: boolean;
  public readonly max_users!: number;
  public readonly metadata!: Map<string, any>;
  public readonly min_interval!: string;
  public readonly min_interval_count!: number;
  public readonly name!: string;
  public readonly parent_id!: number;
  public readonly statement_descriptor!: string;
  public readonly stripe_data!: any;
  public readonly stripe_id!: string;
  public readonly trial_period_days!: number;

  public readonly parent!: Nullable<PlanRecord>;
  public readonly children_ids!: Nullable<List<number>>;
  public readonly children!: Nullable<List<PlanRecord>>;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public constructor(values?: Partial<IPlanRecord>) {
    values ? super(values) : super();
  }

  public getName() {
    return (
      this.name +
      " [Trial: " +
      this.trial_period_days +
      " days | $" +
      (this.amount / 100).toFixed(2) +
      "/" +
      this.interval +
      "]"
    );
  }
}
