import { IJob } from "app/src/Models/Job";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { ICoreLogicModalOrder, IReportResource } from "app/src/Models/Report";
import { ISession } from "app/src/Common/SessionService";
import * as angulartics from "angulartics";
import { IPretty } from "app/src/Common/PrettyNameService";
import { ICoupon } from "../../Billing/Models/Coupon";
import { ISku, ISkuResponse } from "../../Billing/Models/Sku";
import { IAddress } from "../../Models/Address";
import * as moment from "moment";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { RsfRootScope } from "app/src/Common/RsfRootScope";

export class CoreLogicOrderModalCtrl {
  public order: ICoreLogicModalOrder = <ICoreLogicModalOrder>{};
  public activeStep = 0;
  public pricing: ISku[] = [];
  public stripePricing: ISkuResponse;
  public spinnerPromise: ng.IPromise<any>;
  public reportInfo: ng.IFormController;
  public roofReport = true;
  public wallReport = false;

  public static $inject = [
    "$uibModalInstance",
    "job",
    "args",
    "Report",
    "Flash",
    "$stateParams",
    "BaseConfig",
    "$rootScope",
    "US_STATES",
    "Session",
    "$uibModal",
    "$analytics",
    "$scope",
    "$q",
    "Pretty",
    "COUNTRIES",
  ];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public job: IJob,
    public args: any,
    public Report: IReportResource,
    public Flash: IFlash,
    public $stateParams: ng.ui.IStateParamsService,
    public BaseConfig: IBaseConfig,
    public $rootScope: RsfRootScope,
    public US_STATES: string[],
    public Session: ISession,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $analytics: angulartics.IAnalyticsService,
    public $scope: ng.IScope,
    public $q: ng.IQService,
    public Pretty: IPretty,
    public COUNTRIES: string[],
  ) {
    if (args.provider === "property") {
      this.order.claim_number = this.job.id + "";
      this.order.hail = false;
      this.order.wind = false;
      this.spinnerPromise = $q
        .all([
          this.Report.pricing({ kind: "hail", provider: "wvs", org_id: job.org_id }).$promise,
          this.Report.pricing({ kind: "wind", provider: "wvs", org_id: job.org_id }).$promise,
          this.Report.pricing({ kind: "lightning", provider: "wvs", org_id: job.org_id }).$promise,
        ])
        .then((results: any) => {
          results[0].skus = results[0].skus
            .concat(results[1].skus)
            .concat(results[2].skus)
            .concat(results[3].skus)
            .concat(results[4].skus);
          this.stripePricing = results[0];
        });
    }
    this.order.address = this.job.address;
  }

  public nextStep() {
    this.trackEvent("next button", {
      Category: "CoreLogicOrderModal",
    });
    this.activeStep += 1;
    if (this.activeStep === 1) {
      this.pricingInfo();
    }
  }

  public back() {
    this.trackEvent("back button", {
      Category: "CoreLogicOrderModal",
    });
    this.activeStep -= 1;
    if (this.activeStep === 0) {
      this.pricing = [];
    }
  }

  public coupons() {
    return _.chain(this.pricing)
      .map((s: ISku) => {
        _.each(s.coupons, (c: ICoupon) => {
          c.current_sku = s;
        });
        return s.coupons;
      })
      .flatten()
      .compact()
      .value();
  }

  public cancel() {
    this.trackEvent("cancel button", {
      Category: "CoreLogicOrderModal",
    });
    this.$uibModalInstance.dismiss();
  }

  public subtotal() {
    return _.reduce(
      this.pricing,
      (total, p) => {
        return (total += p.price);
      },
      0,
    );
  }

  public total() {
    return _.reduce(
      this.pricing,
      (total, p) => {
        total += p.price;
        _.each(p.coupons, (c: ICoupon): void => {
          total -= c.calculate(p.price);
        });
        return total;
      },
      0,
    );
  }

  public updateTimePeriod() {
    const date_start = moment(this.order.start_date);
    const date_end = moment(this.order.end_date);
    this.order.time_period = date_end.diff(date_start, "days");
  }

  public reportTypeChanged() {
    this.order.report_type = [];
    if (this.roofReport) {
      this.order.report_type.push("Roof");
    } else {
      this.order.delivery_option = "";
    }

    if (this.wallReport) {
      this.order.report_type.push("Wall");
    }
  }

  public pricingInfo() {
    if (this.order.hail) {
      const hail = _.find(this.stripePricing.skus, (sku) => {
        return sku.attributes.kind === "hail";
      });
      if (!_.isUndefined(hail)) {
        this.pricing.push(hail);
      }
    }

    if (this.order.wind) {
      const wind = _.find(this.stripePricing.skus, (sku) => {
        return sku.attributes.kind === "wind";
      });
      if (!_.isUndefined(wind)) {
        this.pricing.push(wind);
      }
    }

    if (this.order.lightning) {
      const lightning = _.find(this.stripePricing.skus, (sku) => {
        return sku.attributes.kind === "lightning";
      });
      if (!_.isUndefined(lightning)) {
        this.pricing.push(lightning);
      }
    }

    _.each(this.pricing, (price) => {
      this.textDisplay(price);
    });
  }

  public submitOrder() {
    this.trackEvent("submit order", {
      Category: "CoreLogicOrderModal",
    });
    if (this.args.provider === "property") {
      if (this.order.hail) {
        const order_data = this.Report.formatWvsRequestReport(this.order);
        this.orderReport(order_data, "wvs", "hail");
      }
      if (this.order.wind) {
        const order_data = this.Report.formatWvsRequestReport(this.order);
        this.orderReport(order_data, "wvs", "wind");
      }
      if (this.order.lightning) {
        const order_data = this.Report.formatWvsLightningRequestReport(this.order);
        this.orderReport(order_data, "wvs", "lightning");
      }
    }
  }

  public orderReport(order_data, provider, kind) {
    const report = this.Report.fromJSON({
      job_id: this.job.id,
      order_data: order_data,
      provider: provider,
      kind: kind,
      user_id: this.Session.currentUser.id,
    });

    this.spinnerPromise = report
      .$create()
      .then(() => {
        this.Flash.addMessage(
          FlashLevels.success,
          this.Pretty.name[provider] + " " + this.Pretty.name[kind] + " report successfully ordered!",
        );
        this.$uibModalInstance.close();
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There was a problem ordering your report. Please try again. If the problem persists, please contact support.",
        );
      });
  }

  public terms() {
    this.trackEvent("terms and conditions", {
      Category: "CoreLogicOrderModal",
    });
    this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "src/Common/termsModal.html",
      size: "lg",
    });
  }

  public textDisplay(price) {
    let text = "";
    if (!_.isUndefined(price.attributes.delivery)) {
      text = "Delivery Option: ";
      if (price.attributes.delivery === "") {
        text += "Delivered within 24 hours";
      } else if (price.attributes.delivery === "Express3") {
        text += "Express3 - delivered within 3 hours";
      } else if (price.attributes.delivery === "Express6") {
        text += "Express6 - delivered within 6 hours";
      }
    } else if (price.attributes.kind === "hail") {
      text = "Hail Verification Report";
    } else if (price.attributes.kind === "wind") {
      text = "Wind Verification Report";
    } else if (price.attributes.kind === "lightning") {
      text = "Lightning Verification Report";
    }
    price.display_name = text;
  }

  public isStep1Valid() {
    if (this.args.provider === "property") {
      return this.order.hail || this.order.wind || this.order.lightning;
    }
    return true;
  }

  public locationUpdated(address: IAddress) {
    this.job.address.lat = Math.round(address.lat * Math.pow(10, 6)) / Math.pow(10, 6);
    this.job.address.lon = Math.round(address.lon * Math.pow(10, 6)) / Math.pow(10, 6);
    this.$scope.$digest();
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        job: this.job.id,
        org: this.job.org_id,
        step: this.activeStep + 1,
      }),
    );
  }
}
