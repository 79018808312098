import { IScreenShare, IScreenShareResource, IScreenShareResponse } from "app/src/Models/ScreenShare";

export class ScreenShareIndexCtrl {
  public screenShareResponse: IScreenShareResponse;

  public static $inject = ["ScreenShare", "$stateParams"];

  constructor(
    public ScreenShare: IScreenShareResource,
    public $stateParams: ng.ui.IStateParamsService,
  ) {
    this.screenShareResponse = <IScreenShareResponse>ScreenShare.query({
      active: true,
      "include[]": ["estimate", "job", "assignments", "org"],
    });
  }
}
