import { ICommand } from "app/src/Commands/Command";
import { IEstimateLineItem } from "../../Models/EstimateLineItem";
import { IEstimateGroup } from "../../Models/EstimateGroup";

export class EditLineItemGroupCmd implements ICommand {
  constructor(
    public lineItem: IEstimateLineItem,
    public destGroup: IEstimateGroup,
  ) {}

  public execute(): boolean {
    this.lineItem.estimate_group_id = this.destGroup.id;
    this.lineItem.estimateGroup = this.destGroup;
    return true;
  }
}
