import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { List } from "immutable";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { EstimateDiscountRecord } from "app2/src/records/EstimateDiscount";
import * as FontAwesome from "react-fontawesome";

export interface PresentAltDisplayProps {
  discount: EstimateDiscountRecord;
  index: number;
  existing: List<EstimateDiscountRecord>;
  destroy(discount: EstimateDiscountRecord): void;
}

export class PresentAltDisplay extends React.Component<PresentAltDisplayProps> {
  constructor(props: PresentAltDisplayProps) {
    super(props);
  }

  public presentExistingDiscount() {
    const { index, discount, destroy } = this.props;
    return (
      <Row key={index}>
        <Col sm={6}>
          <h3 className="pull-right">{discount.name}</h3>
        </Col>
        <Col sm={6}>
          <h3>
            <span className="red-text">
              <FontAwesome id={discount.uuid} onClick={destroy} className="link" name="minus" />
              &nbsp;{CurrencyFormat(discount.calculated)}
            </span>
          </h3>
        </Col>
      </Row>
    );
  }

  public presentDiscountTotal() {
    const { existing, index, discount } = this.props;
    let offset = 0;
    switch (index) {
      case 0:
        offset = 0;
        break;
      case 1:
        offset = 6;
        break;
      default:
        offset = 6;
    }
    return (
      <React.Fragment>
        <Row>
          <Col md={{ span: 6, offset: offset }}>{this.presentExistingDiscount()}</Col>
        </Row>
        {index === 0 ? (
          <Row>
            <Col md={{ span: 6, offset: offset }} className="text-center">
              <h3>{CurrencyFormat(discount.totalAfter)}</h3>
            </Col>
            {existing.count() > 1 ? <Col md={6}>{this.presentExistingDiscount()}</Col> : null}
          </Row>
        ) : null}
      </React.Fragment>
    );
  }

  public render() {
    return this.presentDiscountTotal();
  }
}
