import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as documentActions from "app2/src/reducers/document.actions";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { Row, Col } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Sortable } from "app2/src/components/Common/Sortable";
import Document from "app2/src/components/OrgTabs/DocumentTab/Documents/Document";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { List } from "immutable";
import { booleanChecked } from "app2/src/selectors/document.selectors";
import track from "react-tracking";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { Can } from "app2/src/components/Common/CanComponent";
import { Acl } from "app2/src/helpers/Acl";
import { CommonTitles } from "app2/src/helpers/Format";

const mapStateToProps = (state: RootState) => {
  const modelName = "document",
    docQueryParams = queryParams(state, { modelName });
  const page = docQueryParams.get("page");
  const documentIds = ids(state, { modelName });
  return {
    selectedCheck: booleanChecked(state, { documentIds, booleanName: "selected" }),
    documentIds,
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    docQueryParams,
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: DocumentsProps) => {
  return {
    listDocuments: (queryParams: QueryParamsRecord) =>
      dispatch(
        documentActions.AsyncActions.listDocuments(
          {
            documentableType: "org",
            signed: "unsigned",
            folder_id: ownProps.folderParentId,
          },
          queryParams,
        ),
      ),
    onSortEnd: (oldIndex: number, newIndex: number) =>
      dispatch(documentActions.AsyncActions.onSortEnd(oldIndex, newIndex)),
    batchEditBoolean: (documentIds: List<number>, name: string, value: boolean) =>
      dispatch(documentActions.Actions.batchEditBoolean(documentIds, name, value)),
    batchUpdateDocument: (documentIds) => dispatch(documentActions.AsyncActions.batchUpdateDocument(documentIds)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DocumentsProps {
  folderParentId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DocumentsProps;

@track(() => {
  return {
    component: "Documents",
    category: "OrgDocs",
  };
})
export class Documents extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.queryDocuments = this.queryDocuments.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
  }

  @track((_props, _state, [page]) => ({
    action: "queryDocuments",
    page: page,
  }))
  public queryDocuments(page: number): void {
    const { listDocuments, docQueryParams } = this.props;
    const newQueryParams = docQueryParams.setIn(["page"], page);

    listDocuments(newQueryParams);
  }

  @track((props, _state, [oldIndex, newIndex]) => ({
    action: "onDragEnd",
    oldIndex: oldIndex,
    newIndex: newIndex,
    documents: props.documentIds.toArray(),
  }))
  public onDragEnd(oldIndex: number, newIndex: number): void {
    const { onSortEnd } = this.props;

    onSortEnd(oldIndex, newIndex);
  }

  @track((props, _state, [event]) => ({
    action: "checkAll",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
    documents: props.documentIds.toArray(),
  }))
  public checkBoxOnChange(event: any): void {
    const { documentIds, batchEditBoolean, batchUpdateDocument } = this.props;
    batchEditBoolean(documentIds, event.target.name, event.target.checked);
    if (event.target.name !== "selected") {
      batchUpdateDocument(documentIds);
    }
  }

  public render(): React.ReactNode {
    const { documentIds, page, pagination, selectedCheck } = this.props;

    if (page?.loading) {
      return <SpinnerComponent localProperty={true} />;
    }

    if (_.isNullOrUndefined(page) || documentIds.size === 0) {
      return (
        <Row>
          <Col>
            <div className="form-section blank-state">
              <img src="/assets/images/icons-large/docs.33cddf3f.png" />
              <h2>No documents added. Let's add one.</h2>
              <p>Click on the add document button in the top right and then select your file. You can upload PDF's.</p>
            </div>
          </Col>
        </Row>
      );
    }

    const permission = Acl.can("org", "global");

    return (
      <Row>
        <Col>
          <div className="standard-rl-margin">
            <Row className="table-header">
              <Col sm={1} style={{ paddingLeft: "15px" }}>
                <Can resource="org" permission="update">
                  <IncludeCheckBox name="selected" checked={selectedCheck} onChange={this.checkBoxOnChange} />
                </Can>
              </Col>
              <Col sm={4}>Name</Col>
              <Col sm={2}>Uploaded On</Col>
              <Col sm={permission ? 1 : 3}>Job Resource</Col>
              <Can resource="global" permission="org">
                <Col sm={1}>{CommonTitles("estimate")} Watermark</Col>
                <Col sm={1}>{CommonTitles("contract")} Watermark</Col>
              </Can>
              <Col sm={2}>Actions</Col>
            </Row>
            <Sortable
              dragHandle
              items={documentIds}
              onDragEnd={this.onDragEnd}
              renderItem={({ item, dragHandle }) => {
                return <Document documentId={item} dragHandle={dragHandle} />;
              }}
            />
            <StandardPagination metadata={pagination} pageChanged={this.queryDocuments} />
            <br />
          </div>
        </Col>
      </Row>
    );
  }
}

export default connector(Documents);
