import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { CreateContract } from "./CreateContract";
import { SelectContract } from "./SelectContract";
import { ViewContract } from "./ViewContract";
import { currentJobId, selectedEstimateId } from "app2/src/selectors/job.selectors";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import * as signedDocumentActions from "app2/src/reducers/signedDocument.actions";
import * as jobActions from "app2/src/reducers/job.actions";

export const EstimateContracts: React.FC = () => {
  // Hooks
  const match = useRouteMatch<{ estimateId: string }>();
  const dispatch = useDispatch();

  // Selectors
  const jobId = useSelector(currentJobId);
  const estimateId = useSelector(selectedEstimateId);

  // Local State
  const [loading, setLoading] = React.useState(true);

  // Methods
  const loadDocuments = async () => {
    await dispatch(signedDocumentActions.AsyncActions.listEstimateSignedDocuments(jobId));
    setLoading(false);
  };

  // Lifecycle
  React.useEffect(() => {
    loadDocuments();

    if (!estimateId) {
      dispatch(jobActions.Actions.setSelectedEstimateId(Number(match.params.estimateId)));
    }
  }, []);

  return (
    <>
      <SpinnerComponent localProperty={loading} />
      {estimateId && (
        <Switch>
          <Route path={`${match.path}/view_pdf/new`} component={CreateContract} />
          <Route path={`${match.url}/view_pdf/:documentId`} component={ViewContract} />
          {!loading && <Route path={`${match.path}`} component={SelectContract} />}
        </Switch>
      )}
    </>
  );
};
