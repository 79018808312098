export class StartFromFilter {
  public static factory() {
    const factoryFunction = (input, start) => {
      start = +start; //parse to int
      return input.slice(start);
    };

    factoryFunction.$inject = [];

    return factoryFunction;
  }
}
