import ErrorPage from "app2/src/components/Common/ErrorPage";
import GreenSkyApplication from "app2/src/components/FinancingApplication/GreenSky/GreenSkyApplication";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "app2/src/helpers/Analytics";
import { EstimateRecord } from "app2/src/records/Estimate";
import { FinanceOptionRecord } from "app2/src/records/FinanceOption";
import { IframeRecord } from "app2/src/records/Iframe";
import { JobRecord } from "app2/src/records/Job";
import { token } from "app2/src/selectors/token.selectors";
import { TokenRecord } from "app2/src/records/Token";
import { OrgRecord } from "app2/src/records/OrgRecord";
import * as React from "react";
import track from "react-tracking";

const mapStateToProps = (state: RootState, _ownProps: ApplyByProviderProps) => {
  return {};
};

const mapDispatchToProps = (
  _dispatch: ThunkDispatch<RootState, {}, RootActions>,
  _ownProps: ApplyByProviderProps,
) => ({});
const connector = connect(mapStateToProps, mapDispatchToProps);

interface ApplyByProviderProps {
  id: string;
  estimate: EstimateRecord;
  financeOption: FinanceOptionRecord;
  job: JobRecord;
  org: OrgRecord;
  iframe: IframeRecord;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ApplyByProviderProps;

@track(
  (props) => {
    return {
      category: "Financing Application",
      component: "Apply By Provider",
      estimate: props.estimate.id,
      job: props.job.id,
      org: props.org ? props.org.id : null,
      financeOption: props.financeOption ? props.financeOption.id : null,
    };
  },
  {
    dispatch: (data) => Dispatch.dispatch(data),
    dispatchOnMount: (cd) => ({ action: "shown" }),
  },
)
class ApplyByProvider extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public greenSky() {
    const { financeOption, estimate, job } = this.props;
    const dealerId = financeOption?.getIn(["metadata", "dealer_id"], 0);

    if (dealerId) {
      return (
        <GreenSkyApplication
          job={job}
          dealer={dealerId}
          basePlan={financeOption.integrations_identifier}
          financedAmount={estimate.payment_terms.financed_amount}
          closeModal={window.close}
        />
      );
    }

    return <ErrorPage message="The GreenSky integration is not setup on this organization" />;
  }

  public render() {
    const { financeOption, org, iframe } = this.props;

    if (!financeOption || !org) {
      return <ErrorPage message="Not a Valid Finance Option" />;
    }

    switch (financeOption.provider) {
      case "green_sky":
        return this.greenSky();
      default:
        if (iframe.error) {
          return <ErrorPage message={iframe.error} />;
        }
        return <iframe className="fullscreen" src={iframe.url}></iframe>;
    }
  }
}

export default connector(ApplyByProvider);
