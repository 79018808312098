import { RsfForm } from "@tberrysoln/rsf-form";
import { Openings } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/Openings";
import { RootState } from "app2/src/reducers";
import { doors } from "app2/src/selectors/door.selectors";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { rootKey } from "app2/src/selectors/door.selectors";
import { List } from "immutable";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "app2/src/reducers/door.actions";
import * as FontAwesome from "react-fontawesome";
import { push } from "connected-react-router/immutable";
import { pathname, query } from "app2/src/selectors/router.selectors";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";
import { useTracking } from "react-tracking";

export const Doors: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "Doors" });

  // Selectors
  const doorIds = useSelector((state: RootState) => currentMeasurement(state, {}))?.get("door_ids") || List();
  const doorRecords = useSelector((state: RootState) => doors(state, { doorIds }).filter((door) => !door._destroy));
  const queryParams = useSelector(query);
  const rootPathname = useSelector(pathname);
  const editing = useSelector(measurementEditing);

  // Methods
  const openAddMeasurement = () => {
    trackEvent({ action: "open add measurement" });
    const newQueryParams = new URLSearchParams({
      ...queryParams.toJS(),
      add: "doors",
    });
    dispatch(push(`${rootPathname}?${newQueryParams.toString()}`));
  };
  const destroyDoor = (id: number) => {
    trackEvent({ action: "destroy door", door: id });
    dispatch(Actions.updateForm({ rootPath: ["doors", "byId", id.toString()], name: "_destroy", value: true }));
  };

  return (
    <RsfForm rootPath={[rootKey, "byId"]} updateFormReducer={Actions.updateForm} onSubmit={() => {}}>
      <Row>
        <Col>
          <h2>Doors</h2>
        </Col>
        <Col>
          <Button
            variant="primary"
            className="float-right"
            onClick={openAddMeasurement}
            disabled={editing}
            title={editing ? "Disabled for editing" : ""}>
            <FontAwesome name="plus" />
            &nbsp;Add
          </Button>
        </Col>
      </Row>
      {doorRecords.size > 0 ? (
        <Openings openingRecords={doorRecords} destroyCallback={destroyDoor} />
      ) : (
        <div className="text-center">
          <h2>No doors added. Let's add one.</h2>
          <Button
            variant="primary"
            onClick={openAddMeasurement}
            disabled={editing}
            title={editing ? "Disabled for editing" : ""}>
            <FontAwesome name="plus" />
            &nbsp;Add
          </Button>
        </div>
      )}
    </RsfForm>
  );
};
