import { IEstimate } from "app/src/Models/Estimate";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IOrg } from "app/src/Models/Org";
import { IAddress } from "app/src/Models/Address";
import { IMeasurement } from "app/src/Models/Measurement";
import { IUser } from "app/src/Models/User";
import { IImage } from "app/src/Models/Image";
import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IVisualization } from "app/src/Models/Visualization";
import { IEvent } from "app/src/Models/Event";
import { IPresentation } from "app/src/Models/Presentation";
import { IReport } from "app/src/Models/Report";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IPhoneNumber } from "./PhoneNumber";
import { IDoc } from "./Doc";
import { INote } from "./Note";
import { IBaseConfig } from "../Common/IBaseConfig";
import { EstimateComparisonRecord } from "app2/src/records/EstimateComparison";
import { IJobObjectData } from "app2/src/records/JobAttribute";
import { YesNoProp } from "app2/src/records";
import { IMetaCountlessPaginationData } from "app2/src/records/MetaPagination";

export interface IJobEvent {
  id: number;
  job: IJob;
}

export interface IJobAssignment {
  id: number;
  user_id: number;
  user?: IUser;
  job_id: number;
  job?: IJob;
  status: string;
  status_changed: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IJob extends ng.resource.IResource<IJob>, JobPrototype {
  $create(): ng.IPromise<IJob>;
  $create(params?: Object, success?: Function, error?: Function): ng.IPromise<IJob>;
  $create(success: Function, error?: Function): ng.IPromise<IJob>;

  $assign(): ng.IPromise<IJob>;
  $assign(params?: Object, success?: Function, error?: Function): ng.IPromise<IJob>;
  $assign(success: Function, error?: Function): ng.IPromise<IJob>;

  $change_state(): ng.IPromise<IJob>;
  $change_state(params?: Object, success?: Function, error?: Function): ng.IPromise<IJob>;
  $change_state(success: Function, error?: Function): ng.IPromise<IJob>;

  $saveOrCreate(...args: any[]): ng.IPromise<IJob>;

  assigned_to(user: IUser): boolean;
  assignedUser(): IUser;
  cloneMin(): IJob;
}

export interface IJobResource extends ng.resource.IResourceClass<IJob>, IRsfResource {
  inject(injected: IRepository): void;
  queryByOrg?(data: any): IJobResponse;
  create?(data: any): IJobResource;
  monitor?(data: any): any;
  invite?(data: any): any;
  fromJSON?(data: any): IJob;
  transformRequest?(job: IJob): any;
}

export interface IJobPagingMetadata extends IPagingMetadata {
  states: any;
  state_info: any;
  pagination: IMetaCountlessPaginationData;
}

export interface IJobMetadata {
  marketsharp_id: string;
  marketsharp_contact_id: string;
  salesforce_opportunity_id: string;
  salesforce_contact_id: string;
  salesforce_user_id: string;
}

export interface IJobResponse extends ng.resource.IResourceArray<IJob> {
  jobs: IJob[];
  meta: IJobPagingMetadata;
  stats: any;
}

class JobPrototype {
  public id: number;
  public classy: string;
  public name: string;
  public customer_name: string;
  public org_id: number;
  public org_uid: string;
  public email: string;
  public org: IOrg;
  public estimates: IEstimate[];
  public appointments: IEvent[];
  public documents: IDoc[];
  public document_count: number;
  public images: IImage[];
  public image_count: number;
  public notes: INote[];
  public presentations: IPresentation[];
  public visualizations: IVisualization[];
  public assignments: IJobAssignment[];
  public address: IAddress;
  public use_job_address: YesNoProp;
  public customer_address: IAddress;
  public measurement: IMeasurement;
  public measurement_id: number;
  public state: string;
  public raw_state: string;
  public config: any;
  public lead_source: string;
  public job_types: IJobObjectData[];
  public phone_numbers: IPhoneNumber[];
  public created_at: Date;
  public updated_at: Date;
  public cover_image_uuid: string;
  public metadata: IJobMetadata;
  public report_count: number;
  public reports: IReport[];
  public assigned_user: IUser;
  public additional_info: string;
  public estimate_comparison: EstimateComparisonRecord;
  public archived: boolean;
  public viewed: boolean;

  public $saveOrCreate(params, callback) {
    if (!this.id || this.id <= 0 || (this.id as any as string) === "Unsaved") {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$save(params, callback);
    }
  }

  public assigned_to(user: IUser): boolean {
    return _.any(this.assignments, (assignment) => {
      return assignment.user.id === user.id;
    });
  }

  public assignedUser() {
    return this.assignments[0].user;
  }

  public nextAppointment() {
    //noinspection TypeScriptUnresolvedVariable
    if (this.appointments.length <= 0) {
      return "No Appt.";
    } else {
      //noinspection TypeScriptUnresolvedVariable
      return this.appointments[0].start_time;
    }
  }

  public lastPrice() {
    //noinspection TypeScriptUnresolvedVariable
    if (this.estimates.length <= 0) {
      return 0;
    } else {
      //noinspection TypeScriptUnresolvedVariable
      return this.estimates[0].total;
    }
  }

  public cloneMin() {
    const clone_job: IJob = <IJob>{};
    clone_job.name = this.name;
    clone_job.customer_name = this.customer_name;
    clone_job.email = this.email;
    clone_job.address = this.address;
    clone_job.customer_address = this.customer_address;
    clone_job.state = this.state;
    clone_job.raw_state = this.raw_state;
    clone_job.lead_source = this.lead_source;
    clone_job.job_types = this.job_types;
    clone_job.phone_numbers = this.phone_numbers;
    clone_job.assigned_user = this.assigned_user;
    clone_job.additional_info = this.additional_info;
    clone_job.use_job_address = this.use_job_address;

    return clone_job;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, $rootScope: RsfRootScope, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/jobs/:id";

  const transformSingleWithEvent = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
    event: string,
  ): IJob => {
    const job = transformSingle(response, headers, status);

    if (status >= 200 && status < 299) {
      $rootScope.$broadcast(event, <IJobEvent>{ id: job.id, job: job });
    }

    return job;
  };

  const transformSingle = (response: string, headers: ng.IHttpHeadersGetter, status: number): IJob => {
    if (status < 200 || status > 299) {
      return new Job({});
    }
    return Job.fromJSON(JSON.parse(response).job);
  };

  const requestTransform = (job: IJob) => {
    const data = JSON.parse(angular.toJson(JSON.decycle(job)));

    if (data.org) {
      data.org_id = data.org.id;
      delete data.org;
    }

    if (data.address) {
      data.address_attributes = data.address;
      delete data.address;
    }

    if (data.customer_address) {
      data.customer_address_attributes = data.customer_address;
      delete data.customer_address;
      if (data.customer_address_attributes.id <= 0) {
        delete data.customer_address_attributes.id;
      }
    }

    delete data.measurement;

    if (data.phone_numbers) {
      data.phone_numbers_attributes = data.phone_numbers;
      _.each(data.phone_numbers_attributes, (pn: IPhoneNumber) => {
        if (pn.name === "" && pn.number === "") {
          pn._destroy = true;
        }
        if (pn.id <= 0) {
          delete pn.id;
        }
      });
      delete data.phone_numbers;
    }

    if (data.job_types) {
      data.job_type_ids = data.job_types.map((jt) => jt.id);
      delete data.job_types;
    }

    if (data.assignments) {
      data.assignments_attributes = data.assignments;
      delete data.assignments;
    }

    return angular.toJson({ job: data });
  };

  const Job: IJobResource = <IJobResource>$resource(
    url,
    { id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
        isArray: false,
        cache: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/jobs",
        params: {
          org_id: "@org_id",
        },
        transformRequest: requestTransform,
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          return transformSingleWithEvent(response, headers, status, "job:created");
        },
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        url: BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/jobs/:id",
        params: {
          org_id: "@org_id",
        },
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: requestTransform,
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          return transformSingleWithEvent(response, headers, status, "job:updated");
        },
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const meta = JSON.parse(response);

          _.each(meta.jobs, (jsonJob, index) => {
            meta.jobs[index] = Job.fromJSON(jsonJob);
          });

          return meta;
        },
        isArray: false,
      },
      queryByOrg: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/jobs",
        params: {
          org_id: "@org_id",
        },
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const meta = JSON.parse(response);

          _.each(meta.jobs, (jsonJob, index) => {
            meta.jobs[index] = Job.fromJSON(jsonJob);
          });

          return meta;
        },
        isArray: false,
      },
      assign: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: url + "/assign?include[]=assignments",
        params: {
          id: "@id",
        },
        transformResponse: transformSingle,
      },
      invite: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/invite",
        transformRequest: (data) => {
          return JSON.stringify(data);
        },
        transformResponse: (data) => {
          return JSON.parse(data);
        },
      },
      change_state: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: url + "/state",
        params: {
          id: "@id",
        },
        transformRequest: () => {
          return JSON.stringify({});
        },
        transformResponse: transformSingle,
      },
      monitor: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingle,
        url: url + "/monitor",
        isArray: false,
      },
    },
  );

  Job.fromJSON = (data: any): IJob => {
    data.classy = "Job";
    if (data.org) {
      data.org = resources.Org.fromJSON(data.org);
    }

    if (data.address) {
      data.address = resources.Address.fromJSON(data.address);
    }

    if (data.customer_address) {
      data.customer_address = resources.Address.fromJSON(data.customer_address);
    }

    if (data.documents) {
      _.each(data.documents, (doc, index) => {
        data.documents[index] = resources.Doc.fromJSON(doc);
      });
    } else {
      data.documents = [];
    }

    if (data.images) {
      _.each(data.images, (img, index) => {
        data.images[index] = resources.Image.fromJSON(img);
      });
    } else {
      data.images = [];
    }

    if (data.measurement) {
      data.measurement_id = data.measurement.id;
      data.measurement = resources.Measurement.fromJSON(data.measurement);
    } else {
      data.measurement = resources.Measurement.fromJSON({});
    }

    if (data.emails) {
      _.each(data.emails, (email, index) => {
        data.emails[index] = resources.Email.fromJSON(email);
      });
    } else {
      data.emails = [];
    }

    if (data.phone_numbers) {
      _.each(data.phone_numbers, (phone_number: any, index) => {
        data.phone_numbers[index] = resources.PhoneNumber.fromJSON(phone_number);
      });
    } else {
      data.phone_numbers = [];
    }

    if (data.assignments) {
      _.each(data.assignments, (assignment: any, index) => {
        data.assignments[index].user = resources.User.fromJSON(assignment.user);
      });
    } else {
      data.assignments = [];
    }

    if (!data.assignments) {
      data.assignments = [];
    }

    if (!data.visualizations) {
      data.visualizations = [];
    }

    if (data.reports) {
      _.each(data.reports, (report, index) => {
        data.reports[index] = resources.Report.fromJSON(report);
      });
    }

    if (data.appointments) {
      data.appointments = _.map(data.appointments, (appt: any) => {
        return resources.Event.fromJSON(appt);
      });
    }

    if (data.estimates) {
      data.estimates = _.map(data.estimates, (est: any) => {
        return resources.Estimate.fromJSON(est);
      });
    }

    if (data.visualizations && data.visualizations.length > 0) {
      data.visualizations = _.map(data.visualizations, (visData: any) => {
        return resources.Visualization.fromJSON(visData);
      });
    }

    return new Job(data);
  };

  Job.inject = (injected: IRepository) => {
    resources = injected;
  };

  Job.transformRequest = (job: IJob) => {
    return requestTransform(job);
  };

  _.hiddenExtend(Job.prototype, JobPrototype.prototype);

  return Job;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "$rootScope", "BaseConfig"];

export default factory;
