import { Record, Map, List } from "immutable";
import * as paginationActions from "./pagination.actions";
import { IPageRecord, fromJSON, QueryParamsRecord, PageRecord } from "app2/src/records/Page";
import { RootActions, RootState } from "app2/src/reducers";
import { currentPage } from "app2/src/selectors/pagination.selectors";
import { Nullable } from "app2/src/records";

export const PaginationStateRecord = Record({
  byModel: Map<string, PaginationRecord>(),
  dirtyIds: null as Nullable<List<number>>,
});

export const initialState = PaginationStateRecord();
export type IPaginationStateRecord = typeof initialState;

export interface IPaginationRecord {
  pages: Map<number, IPageRecord>;
  queryParams: QueryParamsRecord;
}

const defaultPagination: IPaginationRecord = {
  pages: Map<number, IPageRecord>(),
  queryParams: new QueryParamsRecord(),
};

export class PaginationRecord extends Record(defaultPagination) implements IPaginationRecord {
  public readonly pages!: Map<number, IPageRecord>;
  public readonly queryParams!: QueryParamsRecord;

  public constructor(values?: Partial<IPaginationRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "pagination";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case paginationActions.FETCH_PAGE:
      const payloadFetch = action.payload;
      const pageFetch = payloadFetch.queryParams.page;
      const queryParamsFetch = payloadFetch.queryParams;
      if (!state.getIn([model, "byModel", payloadFetch.model])) {
        return state
          .setIn([model, "byModel", payloadFetch.model], new PaginationRecord())
          .setIn([model, "byModel", payloadFetch.model, "queryParams"], new QueryParamsRecord(queryParamsFetch))
          .setIn([model, "byModel", payloadFetch.model, "pages", pageFetch], fromJSON({ loading: true }));
      }

      return state
        .setIn([model, "byModel", payloadFetch.model, "queryParams"], new QueryParamsRecord(queryParamsFetch))
        .setIn([model, "byModel", payloadFetch.model, "pages", pageFetch], fromJSON({ loading: true }));
    case paginationActions.RECEIVE_PAGE:
      const payloadReceive = action.payload;
      const pageReceive = payloadReceive.queryParams.page;
      const queryParamsReceive = payloadReceive.queryParams;
      if (!state.getIn([model, "byModel", payloadReceive.model])) {
        state = state.setIn([model, "byModel", payloadReceive.model], new PaginationRecord());
      }
      return state
        .setIn([model, "byModel", payloadReceive.model, "queryParams"], queryParamsReceive)
        .setIn(
          [model, "byModel", payloadReceive.model, "pages", pageReceive],
          fromJSON({ ...payloadReceive.pageData, loading: false }),
        );
    case paginationActions.SET_PAGE_IDS:
      return state.setIn(
        [
          model,
          "byModel",
          action.payload.modelName,
          "pages",
          currentPage(state, { modelName: action.payload.modelName }),
          "ids",
        ],
        action.payload.ids,
      );
    case paginationActions.RECEIVE_PAGE_ERROR:
      const payloadError = action.payload;
      const pageError = payloadError.queryParams.page;
      const queryParamsError = payloadError.queryParams;

      return state
        .setIn([model, "byModel", payloadError.model, "queryParams"], new QueryParamsRecord(queryParamsError))
        .setIn(
          [model, "byModel", payloadError.model, "pages", pageError],
          fromJSON({ errors: payloadError.errors, loading: false }),
        );
    case paginationActions.PUSH_ID:
      let ids = state.getIn([model, "byModel", action.payload.modelName, "pages", 1, "ids"]);
      if (!ids) {
        return state;
      }

      if (ids.indexOf(action.payload.id) < 0) {
        ids = ids.push(action.payload.id);
      }

      return state.setIn([model, "byModel", action.payload.modelName, "pages", 1, "ids"], ids);
    case paginationActions.PREPEND_ID:
      let existingIds = state.getIn([model, "byModel", action.payload.modelName, "pages", 1, "ids"]);
      if (!existingIds) {
        return state;
      }

      existingIds = existingIds.unshift(action.payload.id);

      return state.setIn([model, "byModel", action.payload.modelName, "pages", 1, "ids"], existingIds);
    case paginationActions.REPLACE_ID:
      let replaceIds = state.getIn([model, "byModel", action.payload.modelName, "pages", 1, "ids"]);
      replaceIds = replaceIds.map((id) => {
        if (id === action.payload.replacedId) {
          return action.payload.newId;
        } else {
          return id;
        }
      });

      return state.setIn([model, "byModel", action.payload.modelName, "pages", 1, "ids"], replaceIds);
    case paginationActions.REMOVE_ID:
      state.getIn([model, "byModel", action.payload.modelName, "pages"])?.forEach((_value: PageRecord, key: number) => {
        let removeIds = state.getIn([model, "byModel", action.payload.modelName, "pages", key, "ids"]);
        removeIds = removeIds.filter((id: number) => {
          return id !== action.payload.removeId;
        });

        state = state.setIn([model, "byModel", action.payload.modelName, "pages", key, "ids"], removeIds);
      });

      return state;

    case paginationActions.CLEAR_PAGE:
      return state.setIn([model, "byModel", action.payload.modelName], new PaginationRecord());

    case paginationActions.SET_BY_ATTR_PATH:
      return state.setIn(
        [`${action.payload.modelName}s`, "byId", action.payload.id].concat(action.payload.attrPath),
        action.payload.value,
      );

    case paginationActions.SET_DIRTY_IDS:
      return state.setIn([model, "dirtyIds"], action.payload.ids);

    default:
      return state;
  }
};
