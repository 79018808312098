import { IResultResource, IResultResponse } from "app/src/Models/Result";
import { IResultReasonResource, IResultReasonResponse } from "app/src/Models/ResultReason";
import { IOrg } from "app/src/Models/Org";
import { IEvent } from "app/src/Models/Event";

class ResultModalCtrl implements ng.IComponentController {
  public close: () => void;
  public result: (params: any) => void;
  public org: IOrg;
  public eventSrc: IEvent;
  public event: IEvent;
  public resultWindow: ng.ui.bootstrap.IModalServiceInstance;
  public spinnerPromise: ng.IPromise<any>;
  public results: IResultResponse;
  public reasons: IResultReasonResponse;

  constructor(
    public $q: ng.IQService,
    public Result: IResultResource,
    public ResultReason: IResultReasonResource,
    private $uibModal: ng.ui.bootstrap.IModalService,
  ) {}

  public $onChanges() {
    if (this.org && !this.results) {
      this.results = <IResultResponse>this.Result.query({ org_id: this.org.id });
      this.reasons = <IResultReasonResponse>this.ResultReason.query({ org_id: this.org.id });
      this.spinnerPromise = this.$q.all([this.results.$promise, this.reasons.$promise]);
    }

    if (this.eventSrc) {
      this.event = this.eventSrc.clone();
      this.resultWindow = this.$uibModal.open({
        size: "md",
        templateUrl: "resultModalWindow.html",
        controller: [
          "$scope",
          "event",
          "results",
          "reasons",
          function ($scope, event, results, reasons) {
            this.event = event;
            this.results = results;
            this.reasons = reasons;
          },
        ],
        controllerAs: "$ctrl",
        resolve: {
          event: this.event,
          results: this.results,
          reasons: this.reasons,
        },
      });

      this.resultWindow.result.then(
        () => {
          this.sendResults();
        },
        () => {
          this.windowClosed();
        },
      );
    } else if (this.resultWindow) {
      this.resultWindow.close();
    }
  }

  public sendResults() {
    if (this.result) {
      this.result({
        result_id: this.event.events_result_id,
        reason_id: this.event.events_result_reason_id,
        notes: this.event.notes,
      });
    }
    this.windowClosed();
  }

  public windowClosed() {
    this.eventSrc = null;
    this.event = null;
    this.resultWindow = null;
    if (this.close) {
      this.close();
    }
  }
}

export class ResultModal implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Calendar/result_modal.html";
  public bindings: any = {
    org: "<",
    eventSrc: "<",
    result: "&",
    close: "&",
  };

  constructor() {
    this.controller = ResultModalCtrl;
    this.controller.$inject = ["$q", "Result", "ResultReason", "$uibModal"];
  }
}
