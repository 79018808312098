import { IJobResponse, IJobResource, IJob } from "app/src/Models/Job";
import { ISession } from "app/src/Common/SessionService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IHeaderShowArgs, IHeaderSearchArgs } from "app/src/Header/HeaderCtrl";
import * as angulartics from "angulartics";
import { IAdInfo } from "../Common/AdService";
import { UserPrefConfigType } from "app/src/Models/UserPreference";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { lighten } from "app2/src/services/color.service";
import { IJobStateInfo } from "app2/src/records/Job";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import { jobQuery } from "app2/src/selectors/job.selectors";
import * as jobActions from "app2/src/reducers/job.actions";
import { loadStatistics } from "app2/src/api/statistics.service";
import { RootState } from "app2/src/reducers";
import { currentUserOrg } from "app2/src/selectors/user.selectors";
import { fromJSON as reactSelectFromJSON } from "@tberrysoln/rsf-form";
import { calculateUpdatedSince } from "app2/src/components/JobList/JobQuery";
import { OrgRecord, orgNameAddress } from "app2/src/records/OrgRecord";

export class JobListCtrl {
  public loading = false;
  public promise: ng.IPromise<any>;
  public jobResponse: IJobResponse;
  public stateFilters: string[] = [];
  public search: string = null;
  public selectedJob: IJob;
  public states: any;
  public jobTypeId: any;
  public stateInfo: { [k: string]: IJobStateInfo };
  public jobListCardView: boolean;
  public showAds = false;
  public isFreemium = false;
  public archived = false;
  public adUrl: string;
  public throttledScroll: any;
  public page: any = {
    current: 1,
  };
  public sort: any = {
    by: "jobs.updated_at",
    order: "desc",
  };

  public panelBarOptions = {
    expandMode: "single",
  };

  public static $inject = [
    "$stateParams",
    "Job",
    "Session",
    "$scope",
    "$timeout",
    "EstimatorService",
    "AdService",
    "Flash",
    "$analytics",
  ];
  constructor(
    public $stateParams: ng.ui.IStateParamsService,
    public Job: IJobResource,
    public Session: ISession,
    public $scope: ng.IScope,
    public $timeout: ng.ITimeoutService,
    public EstimatorService: EstimatorService,
    public AdService: IAdInfo,
    public Flash: IFlash,
    public $analytics: angulartics.IAnalyticsService,
  ) {
    this.throttledScroll = _.throttle(this.scroll, 1000, { trailing: false });

    if ($stateParams["status"]) {
      this.stateFilters.push($stateParams["status"]);
    }

    const userOrg: OrgRecord = useSelector((state: RootState) => currentUserOrg(state));
    if (userOrg) {
      dispatch(
        jobActions.Actions.setJobQuery({
          name: "org",
          value: reactSelectFromJSON({ id: userOrg.id, name: orgNameAddress(userOrg) }),
        }),
      );
    }

    dispatch(
      jobActions.Actions.setJobQuery({ name: "user", value: reactSelectFromJSON({ id: -1, name: "All assignees" }) }),
    );

    this.Session.fetchUserPref(UserPrefConfigType.job_list_card_view).then((card_view: boolean) => {
      this.jobListCardView = card_view;
    });

    this.query().then(() => {
      this.states = this.jobResponse.meta.states;
      this.stateInfo = this.jobResponse.meta.state_info;
      dispatch(jobActions.Actions.setJobStates(this.stateInfo));
    });

    this.isFreemium = this.Session.isFreemium();

    this.AdService.randomAd("customer_list", "customer_list_url").then((resolve) => {
      this.adUrl = resolve;
    });

    $scope.$emit("header:search", <IHeaderShowArgs>{ show: true });
    $scope.$on("header:search_change", (e: ng.IAngularEvent, args: IHeaderSearchArgs) => {
      this.$analytics.eventTrack("search", { category: "Job List" });
      //TODO: action to set query?
      this.search = args.searchString;
      this.refreshQuery();
    });
    $scope.$on("updateJobList", (e: ng.IAngularEvent) => {
      this.refreshQuery();
    });

    $scope.$on("filters:trigger_search", () => {
      this.page.current = 1;
      this.jobResponse = null;
      this.refreshQuery();
    });

    this.refreshQuery = this.refreshQuery.bind(this);
  }

  public jobListPartialUrl(settings_acl) {
    let view_type = "card_view";
    if (!this.jobListCardView) {
      view_type = "list_view";
    }

    return `src/Jobs/partials/${view_type}/${settings_acl.sort().join("_")}_list.html`;
  }

  public jobTypes(job_types) {
    return job_types.map((jt) => jt.name).join(", ");
  }

  public cardViewChanged() {
    this.$analytics.eventTrack("toggle display", {
      category: "Job List",
      jobListCardView: this.jobListCardView,
    });
    this.jobListCardView = !this.jobListCardView;
  }

  public toggleAds(digest = false) {
    this.showAds = !this.showAds;
    if (digest) {
      this.$scope.$digest();
    }
  }

  public scroll() {
    if (!this.morePages()) {
      return;
    }

    if (this.loading) {
      this.promise = this.promise.then(() => {
        this.$analytics.eventTrack("infinite scroll", { category: "Job List" });
        this.query();
      });
    } else {
      this.$analytics.eventTrack("infinite scroll", { category: "Job List" });
      this.query();
    }
  }

  public toggleStateFilter(filter: string) {
    if (_.indexOf(this.stateFilters, filter) >= 0) {
      this.$analytics.eventTrack("filter added", { category: "Job List", value: filter });
      this.stateFilters = _.filter(this.stateFilters, (s) => {
        return s !== filter;
      });
    } else {
      this.$analytics.eventTrack("filter removed", { category: "Job List", value: filter });
      this.stateFilters.push(filter);
    }

    this.refreshQuery();
  }

  public stateSelected(state: string) {
    return _.indexOf(this.stateFilters, state) >= 0;
  }

  public sortBy(column: string) {
    if (this.sort.by === column) {
      this.sort.order = this.sort.order === "asc" ? "desc" : "asc";
    } else {
      this.sort.by = column;
      this.sort.order = "asc";
    }
    this.refreshQuery();
  }

  public jobTypeNames(job: IJob) {
    return job.job_types.map((j) => j.name).join(", ");
  }

  public jobTypeTooltip(job: IJob) {
    return `<ul>${job.job_types.map((j) => `<li>${j.name}</li>`).join(" ")}</ul>`;
  }

  public nameFromRawState(state: string) {
    if (this.stateInfo && this.stateInfo[state] && this.stateInfo[state].pretty_name) {
      return this.stateInfo[state].pretty_name;
    }

    return this.states[state];
  }

  public colorFromRawState(state: string, change = 0) {
    if (this.stateInfo && this.stateInfo[state] && this.stateInfo[state].color) {
      return lighten(this.stateInfo[state].color, change);
    }

    switch (state) {
      case "new":
        return lighten("#7ACC5A", change);
      case "followup":
        return lighten("#FFC539", change);
      case "scheduled":
        return lighten("#FE7328", change);
      case "scheduled_second":
        return lighten("#4FBDF4", change);
      case "closed_sold":
        return lighten("#7460EE", change);
      case "lost":
        return lighten("#FF5F5D", change);
      default:
        return lighten("#4FBDF4", change);
    }
  }

  /**
   * this.page.current is set to the 'next' page. so it's less than or equal to.
   */
  public morePages(): boolean {
    if (!this.jobResponse || !this.jobResponse.meta || !this.jobResponse.meta.pagination) {
      return true;
    }

    return !this.jobResponse.meta.pagination.last_page;
  }

  public sync() {
    this.Session.currentUser.org.$processSf({ process: "SyncJob" }).then(() => {
      this.Flash.addMessage(FlashLevels.success, "Job successfully enqueued");
    });
  }

  public refreshQuery(): ng.IPromise<any> {
    this.page.current = 1;
    this.jobResponse = null;
    return this.query();
  }

  private query(): ng.IPromise<any> {
    if (this.loading) {
      return this.promise;
    }

    if (!this.morePages()) {
      return this.promise;
    }

    this.loading = true;

    const defaultParams: any = {
      "include[]": ["address", "assignments", "appointments", "job_types"],
    };

    const jobQueryParams = useSelector((state) => jobQuery(state)).toJS();

    if (this.page.current === 1) {
      defaultParams["include[]"].push("stats");
    }

    defaultParams.page = this.page.current;

    if (this.stateFilters.length > 0) {
      defaultParams["state[]"] = this.stateFilters;
    }

    if (jobQueryParams.state.length > 0) {
      defaultParams["state[]"] = jobQueryParams.state.map((state) => state.id);
    }

    if (this.search !== null && this.search !== "") {
      defaultParams["query"] = this.search;
    }

    if (jobQueryParams.job_type) {
      defaultParams["job_type"] = jobQueryParams.job_type;
    }

    if (jobQueryParams.user.id > -1) {
      defaultParams["user_id"] = jobQueryParams.user.id;
    }

    const orgId =
      jobQueryParams.org.id > -1 ? jobQueryParams.org.id : useSelector((state) => currentUserOrg(state))?.id;
    if (orgId) {
      defaultParams["org_id"] = orgId;
    }

    if (jobQueryParams.updated_since || jobQueryParams.filter_by_updated_since) {
      defaultParams["updated_since"] = calculateUpdatedSince(jobQueryParams);
    }

    if (jobQueryParams.filter_by_updated_before) {
      defaultParams["updated_before"] = jobQueryParams.filter_by_updated_before;
    }

    if (jobQueryParams.lead_source !== "_allleads_") {
      defaultParams["lead_source"] = jobQueryParams.lead_source;
    }

    defaultParams["sort_by"] = jobQueryParams.sort_by;
    defaultParams["sort_order"] = jobQueryParams.sort_order;
    defaultParams["archived"] = jobQueryParams.archived;

    this.promise = this.Job.query(
      defaultParams,
      (response) => {
        if (this.jobResponse && this.jobResponse.jobs) {
          this.jobResponse.jobs = this.jobResponse.jobs.concat(response.jobs);
          this.jobResponse.meta.pagination = response.meta.pagination;
        } else {
          this.jobResponse = response;
        }

        if (response.stats) {
          loadStatistics(response);
        }
        this.page.current += 1;
        this.loading = false;
        //hit
      },
      (error) => {
        console.error("Error loading jobs", error);
        this.loading = false;
      },
    ).$promise;

    return this.promise;
  }
}
