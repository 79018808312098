import { ActionsUnion, createAction } from "./Utils";
import { IRoomData } from "../records/Room";
import { List } from "immutable";

export const RECEIVE_ROOM = "@rooms/RECEIVE_ROOM";
export const EDIT_ROOM = "@rooms/EDIT_ROOM";
export const RESET_ROOMS = "@rooms/RESET_ROOMS";
export const UPDATE_FORM = "@rooms/UPDATE_FORM";

export const Actions = {
  receiveRoom: (room: Partial<IRoomData>) => createAction(RECEIVE_ROOM, { room }),
  editRoom: (room: Partial<IRoomData>) => createAction(EDIT_ROOM, { room }),
  resetRooms: (roomIds: List<number>) => createAction(RESET_ROOMS, { roomIds }),
  updateForm: (event: { rootPath: any; name: string; value: any }) => createAction(UPDATE_FORM, { event }),
};

export type Actions = ActionsUnion<typeof Actions>;
