import { ISession } from "app/src/Common/SessionService";
import * as moment from "moment";
import Moment = moment.Moment;
import { IBaseConfig } from "../Common/IBaseConfig";
import StartOf = moment.unitOfTime.StartOf;

export interface IEventService {
  resources: any[];
  getSchedulerDatasource(dateSource: IDateSource, callbacks?: any): kendo.data.SchedulerDataSource;
  getSchedulerResourceDatasource(): any;

  getPeriod(view: string, action: string, date: Moment): IDateSource;
}

export interface IDateSource {
  start: Moment;
  end: Moment;
  userIds?: number[];
}

export class EventService implements IEventService {
  public resources: any[] = [];

  private colors = [
    "#445257",
    "#7ACC5A",
    "#FFC539",
    "#17283D",
    "#7460EE",
    "#FE7328",
    "#FAFAFA",
    "#FF5F5D",
    "#4FBDF4",
    "#F8F8F9",
  ];
  private crudServiceBaseUrl = "/api/v1/events";
  private lastData: any;

  public static $inject = ["BaseConfig", "$http", "Session", "$q"];
  constructor(
    public BaseConfig: IBaseConfig,
    public $http: ng.IHttpService,
    public Session: ISession,
    private $q: ng.IQService,
  ) {}

  public getSchedulerDatasource(dateSource: IDateSource, callbacks?: any): kendo.data.SchedulerDataSource {
    const dataSource = new kendo.data.SchedulerDataSource({
      transport: {
        read: (cb: kendo.data.DataSourceTransportOptions) => {
          const params = {
            start: dateSource.start.format(),
            end: dateSource.end.format(),
          };

          const promises = [];

          // Job Appointments
          const jobParams = _.clone(params);
          jobParams["jobs_only"] = true;
          promises.push(this.$http.get(this.BaseConfig.BASE_URL + this.crudServiceBaseUrl, { params: jobParams }));

          // User Appointments
          const userParams = _.clone(params);
          if (!dateSource.userIds) {
            userParams["user_ids[]"] = [this.Session.currentUser.id];
            promises.push(this.$http.get(this.BaseConfig.BASE_URL + this.crudServiceBaseUrl, { params: userParams }));
          } else if (_.isArray(dateSource.userIds) && dateSource.userIds.length > 0) {
            userParams["user_ids[]"] = dateSource.userIds;
            promises.push(this.$http.get(this.BaseConfig.BASE_URL + this.crudServiceBaseUrl, { params: userParams }));
          }

          // Org Parameters
          const orgParams = _.clone(params);
          orgParams["orgs_only"] = true;
          promises.push(this.$http.get(this.BaseConfig.BASE_URL + this.crudServiceBaseUrl, { params: orgParams }));

          this.$q
            .all(promises)
            .then((responses) => {
              let data = [];

              _.each(responses, (response: any) => {
                data = data.concat(response.data.events);
              });

              _.each(data, (record) => {
                if (record.job_id) {
                  record.resourceId = "job_" + record.job_id;
                } else if (record.org_id) {
                  record.resourceId = "org_" + record.org_id;
                } else if (record.user_id) {
                  record.resourceId = "user_" + record.user_id;
                }

                if (record.metadata && record.metadata.cronofy_id) {
                  record.editable = false;
                }
              });

              this.lastData = data;
              this.resources = _.chain(this.lastData)
                .map((resource) => {
                  return {
                    text: resource.resourceId,
                    value: resource.resourceId,
                    color: "#FFFFFF",
                  };
                })
                .uniq((resource) => {
                  return resource.value;
                })
                .each((resource, index) => {
                  resource.color = this.colors[index % this.colors.length];
                })
                .value();

              cb.success({ events: data });

              if (callbacks && callbacks["postRead"] && _.isFunction(callbacks["postRead"])) {
                callbacks["postRead"](data);
              }
            })
            .catch((err) => {
              cb.error(err);
            });
        },
        update: (cb: kendo.data.DataSourceTransportOptions) => {
          if (cb.data.status > 0) {
            return;
          }

          if (callbacks && callbacks["preUpdate"] && _.isFunction(callbacks["preUpdate"])) {
            callbacks["preUpdate"](cb.data);
          }

          this.$http
            .patch(this.BaseConfig.BASE_URL + this.crudServiceBaseUrl + "/" + cb.data.id, cb.data)
            .then((response) => {
              cb.success(response.data);
            })
            .catch((err) => {
              cb.error(err);
            });
        },
        destroy: (cb: kendo.data.DataSourceTransportOptions) => {
          if (cb.data.status > 0) {
            return;
          }

          this.$http
            .delete(this.BaseConfig.BASE_URL + this.crudServiceBaseUrl + "/" + cb.data.id, cb.data)
            .then((response) => {
              cb.success(response.data);
            })
            .catch((err) => {
              cb.error(err);
            });
        },
        create: (cb: kendo.data.DataSourceTransportOptions) => {
          if (cb.data.status > 0) {
            return;
          }

          if (callbacks && callbacks["preCreate"] && _.isFunction(callbacks["preCreate"])) {
            callbacks["preCreate"](cb.data);
          }

          this.$http
            .post(this.BaseConfig.BASE_URL + this.crudServiceBaseUrl, { event: cb.data })
            .then((response) => {
              cb.success(response.data);
            })
            .catch((err) => {
              cb.error(err);
            });
        },
      },
      schema: {
        data: (data: any) => {
          if (data.events) {
            data = data.events;
          } else if (data.event) {
            data = data.event;
          }
          return data;
        },
        model: {
          id: "id",
          fields: {
            title: {
              from: "summary",
              defaultValue: "Sales Call",
              validation: { required: true },
            },
            start: {
              type: "date",
              from: "start_time",
            },
            end: {
              type: "date",
              from: "end_time",
            },
            description: {
              from: "description",
            },
            job_id: {
              from: "job_id",
            },
            resourceId: {
              from: "resourceId",
              nullable: true,
            },
            jobName: {
              from: "job_name",
            },
            cronofy_invite_attendees: {
              from: "cronofy_invite_attendees",
            },
            inviteAttendee: {
              from: "invite_customer",
            },
            isEditable: {
              from: "editable",
              defaultValue: true,
            },
          },
        },
      },
      change: (event: kendo.data.DataSourceChangeEvent) => {
        if (callbacks && callbacks["change"] && _.isFunction(callbacks["change"])) {
          callbacks["change"](event);
        }
      },
    } as any as kendo.data.DataSourceOptions);

    dataSource.fetch();
    return dataSource;
  }

  public getSchedulerResourceDatasource() {
    return [
      {
        field: "resourceId",
        dataColorField: "color",
        name: "Appointments",
        title: "Appointments",
        dataSource: this.resources,
      },
    ];
  }

  public getPeriod(view: string, action: string, date: Moment) {
    let subUnitOfTime: moment.unitOfTime.DurationConstructor = "week";
    let unitOfTime: moment.unitOfTime.DurationConstructor = "month";
    switch (view) {
      case "workWeek":
      case "week":
        unitOfTime = "week";
        subUnitOfTime = "day";
        break;
      case "day":
        unitOfTime = "day";
        subUnitOfTime = "day";
        break;
      case "month":
        unitOfTime = "month";
        subUnitOfTime = "week";
        break;
    }

    date = date.startOf(unitOfTime as any as StartOf);

    const result: IDateSource = {
      start: date.clone().subtract(1, subUnitOfTime),
      end: date.clone().add(1, unitOfTime).add(1, subUnitOfTime),
    };
    return result;
  }
}
