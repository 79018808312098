import { IRepository } from "app/src/Common/Repository";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { IPagingMetadata } from "app/src/Models/PagingMetadata";

export interface IChargebeeSubscription
  extends ng.resource.IResource<IChargebeeSubscription>,
    ChargebeeSubscriptionPrototype {}

export interface IChargebeeSubscriptionResource extends ng.resource.IResourceClass<IChargebeeSubscription> {
  fromJSON?(data: any): IChargebeeSubscription;
  inject(repository: IRepository): void;
}

export interface IChargebeeSubscriptionResponse extends ng.resource.IResourceArray<IChargebeeSubscription> {
  subscriptions: IChargebeeSubscription[];
  meta: IPagingMetadata;
}

class ChargebeeSubscriptionPrototype {
  public id: number;
  public org_id: number;
  public min_value: number;
  public min_value_through: Date;
  public kind: number;
  public billable_types: string[];
  public metadata: any;
  public payload: any;
  public archive_number: string;
  public archived_at: Date;
  public created_at: Date;
  public updated_at: Date;
  public customer_id: string;
  public customer_email: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IChargebeeSubscriptionResource => {
  const url = BaseConfig.BASE_URL + "/billing/v2/subscriptions/:id";

  const singleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IChargebeeSubscription => {
    if (status < 200 || status > 299) {
      return new ChargebeeSubscription({});
    }

    return ChargebeeSubscription.fromJSON(JSON.parse(response).subscriptions);
  };

  const multipleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return { subscriptions: [] };
    }

    const data = JSON.parse(response);

    _.each(data.subscriptions, (subscription, index) => {
      data.subscriptions[index] = ChargebeeSubscription.fromJSON(subscription);
    });

    return data;
  };

  const ChargebeeSubscription: IChargebeeSubscriptionResource = <IChargebeeSubscriptionResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  ChargebeeSubscription.fromJSON = (data: any): IChargebeeSubscription => {
    if (data.subscriptions) {
      _.each(data.subscriptions, (subscription_data: any, idx: number) => {
        data.subscriptions[idx] = new resources.ChargebeeSubscription(subscription_data);
      });
    } else {
      data.subscriptions = [];
    }

    return new ChargebeeSubscription(data);
  };

  ChargebeeSubscription.inject = (injected: IRepository) => {
    resources = injected;
  };

  _.hiddenExtend(ChargebeeSubscription.prototype, ChargebeeSubscriptionPrototype.prototype);

  return ChargebeeSubscription;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
