import { ActionsUnion, createAction } from "../Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../index";
import { IFinanceOptionData, FinanceOptionRecord, FinanceOptionProvider } from "app2/src/records/FinanceOption";
import { financeOptionService, FinanceOptionIndexOptions } from "app2/src/api/financeOption.service";
import { RootDispatchType } from "../../store";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { List } from "immutable";
import { Nullable } from "app2/src/records";
import { handleErrors } from "app2/src/reducers/Utils";
import { currentJobOrgId } from "app2/src/selectors/job.selectors";

export const FETCH_ORG_FINANCE_OPTIONS = "@financeOptions/FETCH_ORG_FINANCE_OPTIONS";
export const FETCH_FINANCE_OPTION = "@financeOptions/FETCH_FINANCE_OPTION";
export const RECEIVE_ORG_FINANCE_OPTIONS = "@financeOptions/RECEIVE_ORG_FINANCE_OPTIONS";
export const RECEIVE_FINANCE_OPTION = "@financeOptions/RECEIVE_FINANCE_OPTION";
export const REMOVE_ORG_FINANCE_OPTION = "@financeOptions/REMOVE_ORG_FINANCE_OPTION";
export const REMOVE_FINANCE_OPTION = "@financeOptions/REMOVE_FINANCE_OPTION";
export const RECEIVE_ORG_ERRORS = "@financeOptions/RECEIVE_ORG_ERRORS";
export const RECEIVE_FINANCE_OPTION_ERROR = "@financeOptions/RECEIVE_FINANCE_OPTION_ERROR";

export const Actions = {
  fetchFinanceOption: (orgId: number, financeOptionId: number) =>
    createAction(FETCH_FINANCE_OPTION, { orgId: orgId, financeOptionId: financeOptionId }),
  receiveFinanceOptionError: (orgId, financeOptionId: number, errors: string[]) =>
    createAction(RECEIVE_FINANCE_OPTION_ERROR, { orgId: orgId, financeOptionId: financeOptionId, errors: errors }),
  receiveFinanceOption: (financeOption: Partial<IFinanceOptionData>) =>
    createAction(RECEIVE_FINANCE_OPTION, financeOption),
  fetchFinanceOptions: (orgId: number) => createAction(FETCH_ORG_FINANCE_OPTIONS, orgId),
  receiveErrors: (orgId, errors: string[]) => createAction(RECEIVE_ORG_ERRORS, { orgId: orgId, errors: errors }),
  receiveFinanceOptions: (orgId: number, financeOptions: Partial<IFinanceOptionData>[]) =>
    createAction(RECEIVE_ORG_FINANCE_OPTIONS, { orgId: orgId, financeOptions: financeOptions }),
  removeOrgFinanceOption: (orgId: number, financeOptionId: number) =>
    createAction(REMOVE_ORG_FINANCE_OPTION, { orgId: orgId, financeOptionId: financeOptionId }),
  removeFinanceOption: (financeOptionId: number) => createAction(REMOVE_FINANCE_OPTION, financeOptionId),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  addFinanceOption: (
    orgId: number,
    financeOption: Partial<IFinanceOptionData>,
  ): ThunkResult<Promise<IFinanceOptionData>> => {
    return (dispatch: RootDispatchType) => {
      const id = financeOption.id;
      dispatch(Actions.fetchFinanceOption(orgId, financeOption.id));

      return financeOptionService.create(orgId, financeOption).then(
        (result) => {
          dispatch(Actions.removeOrgFinanceOption(orgId, id));
          dispatch(Actions.removeFinanceOption(id));
          dispatch(Actions.receiveFinanceOption(result));

          return result;
        },
        (errors) => {
          dispatch(Actions.receiveFinanceOptionError(orgId, id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  updateFinanceOption: (financeOption: Partial<IFinanceOptionData>): ThunkResult<Promise<IFinanceOptionData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchFinanceOption(financeOption.org_id, financeOption.id));

      return financeOptionService.update(financeOption.org_id, financeOption).then(
        (financeOption) => {
          dispatch(Actions.receiveFinanceOption(financeOption));
          return financeOption;
        },
        (errors) => {
          dispatch(Actions.receiveFinanceOptionError(financeOption.org_id, financeOption.id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  batchUpdateFinanceOptions: (financeOptions: List<IFinanceOptionData>): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const promises = [];

      financeOptions.forEach((financeOption) => {
        promises.push(financeOptionService.update(financeOption.org_id, financeOption));
      });

      try {
        const financeOptions = await Promise.all(promises);
        dispatch(Actions.receiveFinanceOptions(financeOptions[0].org_id, financeOptions));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Finance Options successfully saved."));
      } catch {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, ["Sorting Failed, please try again."]));
      }
    };
  },
  archiveFinanceOption: (financeOption: FinanceOptionRecord): ThunkResult<Promise<IFinanceOptionData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchFinanceOption(financeOption.org_id, financeOption.id));

      return financeOptionService.archive(financeOption.org_id, financeOption).then(
        (archived) => {
          dispatch(Actions.removeOrgFinanceOption(archived.org_id, archived.id));
          dispatch(Actions.removeFinanceOption(archived.id));
          return archived;
        },
        (errors) => {
          dispatch(Actions.receiveFinanceOptionError(financeOption.org_id, financeOption.id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  listFinanceOptions: (
    orgId: number,
    options: FinanceOptionIndexOptions = {},
  ): ThunkResult<Promise<IFinanceOptionData[]>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchFinanceOptions(orgId));

      try {
        const response = await financeOptionService.loadByOrg(orgId, options);

        dispatch(Actions.receiveFinanceOptions(orgId, response.finance_options));
        return response.finance_options;
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(Actions.receiveErrors(orgId, errors));
        return Promise.reject(errors);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
