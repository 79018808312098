import { fetcher } from "../helpers/Fetcher";
import { IEstimateTemplateData, EstimateTemplateRecord } from "app2/src/records/EstimateTemplate";
import { QueryParamsRecord } from "app2/src/records/Page";
import { IMetaPaginationData } from "app2/src/records/MetaPagination";
import { List } from "immutable";
import { CamelToSnakeKeys } from "app2/src/helpers/Format";
import { baseCleanProps, listParams } from "app2/src/api/Api";

export interface IEstimateTemplateIndexResponse {
  estimate_templates: IEstimateTemplateData[];
  meta: { pagination: IMetaPaginationData };
}

export interface IEstimateTemplateResponse {
  estimate_template?: IEstimateTemplateData;
}

export interface IEstimateTemplateOptions {
  id: number;
  estimateTemplateIds?: List<number>;
  folderId?: number;
}

const url = "api/v1/orgs/:id/estimate_templates";

export const list = (
  options: IEstimateTemplateOptions,
  queryParams: QueryParamsRecord = new QueryParamsRecord(),
): Promise<IEstimateTemplateIndexResponse> => {
  const actionUrl = url.replace(":id", options.id.toString());
  const params = listParams(queryParams);

  if (options.folderId && options.folderId !== 0) {
    params.folder_id = options.folderId;
  }

  if (options.estimateTemplateIds) {
    params["estimate_template_ids[]"] = options.estimateTemplateIds.toArray();
  }

  const parameters = queryParams.get("parameters")?.toJS() as any;
  if (parameters?.kind) {
    params["kind[]"] = parameters.kind;
  }

  return fetcher.get<IEstimateTemplateIndexResponse>(actionUrl, params);
};

export const updateOrCreate = (estimateTemplate: EstimateTemplateRecord): Promise<IEstimateTemplateResponse> => {
  if (estimateTemplate.id > 0) {
    return update(estimateTemplate);
  } else {
    return create(estimateTemplate);
  }
};

export const create = (estimateTemplate: EstimateTemplateRecord): Promise<IEstimateTemplateResponse> => {
  const actionUrl = urlSetup(estimateTemplate);
  return fetcher.post<IEstimateTemplateResponse>(actionUrl, cleanProps(estimateTemplate));
};

export const update = (estimateTemplate: EstimateTemplateRecord): Promise<IEstimateTemplateResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(estimateTemplate), estimateTemplate.id.toString());
  return fetcher.patch(actionUrl, cleanProps(estimateTemplate));
};

export const destroy = (estimateTemplate: EstimateTemplateRecord): Promise<IEstimateTemplateResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(estimateTemplate), estimateTemplate.id.toString());
  return fetcher.delete(actionUrl);
};

export const cleanProps = (estimateTemplate: EstimateTemplateRecord): IEstimateTemplateData => {
  const estimateTemplateParams = baseCleanProps(CamelToSnakeKeys(estimateTemplate.toJS()));

  if (_.isNullOrUndefined(estimateTemplateParams.folder_id)) {
    delete estimateTemplateParams.folder_id;
  }

  return estimateTemplateParams;
};

export const urlSetup = (estimateTemplate: EstimateTemplateRecord): string => {
  return url.replace(":id", estimateTemplate.orgId.toString());
};
