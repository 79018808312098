import * as planActions from "./plan.actions";
import { fromJSON, PlanRecord } from "app2/src/records/billing/Plan";
import { Map, Record, List } from "immutable";
import { RootState, RootActions } from "..";

export const PlanStateRecord = Record({
  byId: Map<number, PlanRecord>(),
  planFamilies: List<string>(),
  loading: false,
  errors: List<string>(),
});

export const initialState = PlanStateRecord();
export type PlanState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  switch (action.type) {
    case planActions.RECEIVE_PLAN:
      const plan = fromJSON({ ...action.payload.plan, loading: false });

      if (!state.getIn(["billing", "plans", "planFamilies"]).includes(plan.family)) {
        state = state.updateIn(["billing", "plans", "planFamilies"], (pfs: List<string>) =>
          pfs.push(plan.family).sort(),
        );
      }

      return state.setIn(["billing", "plans", "byId", plan.id], plan);
    case planActions.RECEIVE_PLAN_ERROR:
      return state
        .setIn(["billing", "plans", "byId", action.payload.id, "errors"], List<string>(action.payload.errors))
        .setIn(["billing", "plans", "byId", action.payload.id, "loading"], false);
    case planActions.FETCH_PLAN:
      if (state.getIn(["billing", "plans", "byId", action.payload.id])) {
        return state.setIn(["billing", "plans", "byId", action.payload.id, "loading"], true);
      }
      return state.setIn(
        ["billing", "plans", "byId", action.payload.id],
        fromJSON({ id: action.payload.id, loading: true }),
      );
    case planActions.FETCH_PLANS:
      return state.setIn(["billing", "plans", "loading"], true);
    case planActions.RECEIVE_PLANS:
      action.payload.plans.forEach((d) => {
        state = reducer(state, planActions.Actions.receivePlan(d));
      });

      return state.setIn(["billing", "plans", "loading"], false);
    case planActions.RECEIVE_ERRORS:
      return state
        .setIn(["billing", "plans", "errors"], List<string>(action.payload.errors))
        .setIn(["billing", "plans", "loading"], false);
    case planActions.REMOVE_PLAN:
      return state.removeIn(["billing", "plans", "byId", action.payload.id]);
    case planActions.CLEAR_PLANS:
      return state.setIn(["billing", "plans", "byId"], Map());
    default:
      return state;
  }
};
