import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction } from "../Utils";
import { RootState } from "..";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { RootDispatchType } from "app2/src/store";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { handleErrors } from "app2/src/reducers/Utils";
import { eagleviewService, Page } from "app2/src/api/integrations/eagleview.service";

export const RECEIVE_PAGE = "@integrations/eagleview/RECEIVE_PAGE";
export const RESET_PAGES = "@integrations/eagleview/RESET_PAGES";
export const FETCH_PAGE = "@integrations/eagleview/FETCH_PAGE";
export const START_IMPORT = "@integrations/eagleview/START_IMPORT";
export const FINISH_IMPORT = "@integrations/eagleview/FINISH_IMPORT";

export const Actions = {
  receivePage: (page: Page) => createAction(RECEIVE_PAGE, page),
  fetchPage: (page: number) => createAction(FETCH_PAGE, page),
  resetPages: () => createAction(RESET_PAGES),
  startImport: () => createAction(START_IMPORT),
  finishImport: () => createAction(FINISH_IMPORT),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, ActionsUnion<typeof Actions>>;

export const AsyncActions = {
  getPage: (page: number): ThunkResult<Promise<Page>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(Actions.fetchPage(page));
        const data = await eagleviewService.getPage(page);
        data.page = page;
        dispatch(Actions.receivePage(data));
        return data;
      } catch {}
    };
  },
  importReport: (reportId: number): ThunkResult<Promise<boolean>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(Actions.startImport());
        const data = await eagleviewService.importReport(reportId);
        dispatch(Actions.finishImport());
        return data;
      } catch {}
    };
  },
  login: (credentials: any) => {
    return async (dispatch: RootDispatchType) => {
      try {
        const response = await eagleviewService.login(credentials);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Successfully authenticated with EagleView"));
      } catch (response) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, handleErrors(response)));
        throw response;
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
