import { Record, List } from "immutable";
import { CurrencyFormat, PercentageFormat } from "app2/src/helpers/Format";

export interface IDiscountData {
  id: number;
  org_id: number;
  name: string;
  description: string;
  breakdown: IDiscountBreakdown;
  sort_order: number;
  archived: boolean;
  created_at: Date;
  updated_at: Date;
}

// No good way to not duplicate things
type DiscountKind = "percent" | "flat" | "percent_limit";
export const DiscountKinds: Array<DiscountKind> = ["percent", "flat", "percent_limit"];

export interface IDiscountBreakdown {
  kind: DiscountKind;
  amount: number;
  limit: number;
}

export const formatKind = (type: DiscountKind): string => {
  switch (type) {
    case "percent":
      return "Percentage Off";
    case "flat":
      return "Flat Amount";
    case "percent_limit":
      return "Percentage up to $$";
  }
};

export const formatBreakdown = (breakdown: IDiscountBreakdown): string => {
  if (breakdown.kind === "flat") {
    return CurrencyFormat(breakdown.amount);
  }

  let formattedValue = PercentageFormat(breakdown.amount);

  if (breakdown.kind === "percent_limit") {
    formattedValue = `${formattedValue} up to ${CurrencyFormat(breakdown.limit)}`;
  }

  return formattedValue;
};

export interface IDiscountRecord extends IDiscountData {
  loading: boolean;
  errors: List<string>;
}

const defaultBreakdownProps: IDiscountBreakdown = {
  kind: "percent",
  amount: 0,
  limit: 0,
};

export class DiscountBreakdown extends Record(defaultBreakdownProps) implements IDiscountBreakdown {
  public kind!: DiscountKind;
  public amount!: number;
  public limit!: number;

  public constructor(values?: Partial<DiscountBreakdown>) {
    values ? super(values) : super();
  }
}

const defaultDiscountProps: IDiscountRecord = {
  id: -1,
  org_id: null,
  name: "",
  description: "",
  breakdown: new DiscountBreakdown(),
  sort_order: 0,
  archived: false,
  created_at: null,
  updated_at: null,
  loading: false,
  errors: List<string>(),
};

export class DiscountRecord extends Record(defaultDiscountProps) implements IDiscountRecord {
  public id!: number;
  public org_id!: number;
  public name!: string;
  public description!: string;
  public breakdown!: any;
  public sort_order!: number;
  public archived!: boolean;
  public created_at!: Date;
  public updated_at!: Date;

  public loading!: boolean;
  public errors!: List<string>;

  public constructor(values?: Partial<IDiscountRecord>) {
    if (values) {
      values.breakdown = new DiscountBreakdown(values.breakdown);
      super(values);
    } else {
      super();
    }
  }
}
