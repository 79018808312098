import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { IOrg } from "app/src/Models/Org";
import * as angulartics from "angulartics";
import * as ng from "angular";

export class BaseOrgTabCtrl {
  public org: IOrg;
  public spinnerPromise: ng.IPromise<any>;

  constructor(
    public OrgFetcher: IOrgFetcherService,
    protected $analytics: angulartics.IAnalyticsService,
    orgId?: string,
  ) {
    //noinspection TypeScriptValidateTypes
    if (orgId && (orgId as any as string) !== "new") {
      this.spinnerPromise = this.setupOrg(orgId);
    }
  }

  public setupOrg(orgId: string): any {
    return this.OrgFetcher.loadOrg(parseInt(orgId)).then((org: IOrg) => {
      this.org = org;
      return this.org;
    });
  }

  public trackEvent(action, props): void {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        org: this.org.id,
      }),
    );
  }

  public reset(): ng.IPromise<IOrg> {
    if (this.org.id > 0) {
      return this.OrgFetcher.reloadOrg(this.org.id);
    } else {
      this.OrgFetcher.resetState();
      return this.OrgFetcher.$q.resolve(this.OrgFetcher.org);
    }
  }

  public check(): boolean {
    return this.OrgFetcher.checkState();
  }
}
