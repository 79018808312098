import { EstimateLineItemOptionRecord, IEstimateLineItemOptionData } from "../records/EstimateLineItemOption";
import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { List, Map } from "immutable";

export const estimateLineItemOptionsById = (state: RootState) => state.getIn(["estimateLineItemOptions", "byId"]);
export const lastSavedEstimateLineItemOptionsById = (state: RootState) =>
  state.getIn(["estimateLineItemOptions", "lastSavedById"]);

export const estimateLineItemOptions = createSelector(
  [estimateLineItemOptionsById, (_, props: any) => props],
  (
    estimateLineItemOptionsById: Map<number, EstimateLineItemOptionRecord>,
    props: any,
  ): List<EstimateLineItemOptionRecord> => {
    return estimateLineItemOptionsProcess(estimateLineItemOptionsById, props);
  },
);

export const lastSavedEstimateLineItemOptions = createSelector(
  [lastSavedEstimateLineItemOptionsById, (_, props: any) => props],
  (
    estimateLineItemOptionsById: Map<number, EstimateLineItemOptionRecord>,
    props: any,
  ): List<EstimateLineItemOptionRecord> => {
    return estimateLineItemOptionsProcess(estimateLineItemOptionsById, props);
  },
);

const estimateLineItemOptionsProcess = (estimateLineItemOptionsById, props) => {
  const { estimateLineItemOptionIds } = props;
  let { fullEstimate } = props;
  if (_.isUndefined(fullEstimate)) {
    fullEstimate = false;
  }

  return estimateLineItemOptionIds
    .map((optionId: number) => {
      return estimateLineItemOptionsById.get(optionId);
    })
    .filter((lineItemOption: EstimateLineItemOptionRecord) => {
      return fullEstimate || !lineItemOption._destroy;
    })
    .toList();
};

export const toEstimateLineItemOptionsJson = createSelector(
  [estimateLineItemOptions, (state, props) => ({ state, props })],
  (estimateLineItemOptions: List<EstimateLineItemOptionRecord>, stateProps: any): IEstimateLineItemOptionData[] => {
    return estimateLineItemOptions
      .map((option: EstimateLineItemOptionRecord) => {
        const optionData = option.toJSON() as any;

        if (!optionData._destroy) {
          delete optionData._destroy;
        }

        return optionData as IEstimateLineItemOptionData;
      })
      .toArray();
  },
);
