import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { IJob } from "app/src/Models/Job";
import * as angulartics from "angulartics";
import { useState, dispatch } from "app2/src/storeRegistry";
import { PaymentAuthorizationRecord } from "app2/src/records/PaymentAuthorizationRecord";
import * as paymentActions from "app2/src/reducers/payment.actions";
import * as toolActions from "app2/src/reducers/org/tool.actions";

export class BaseTabCtrl {
  public job: IJob;
  public spinnerPromise: ng.IPromise<any>;
  public navDisplay = true;
  public paymentAuthorizations: PaymentAuthorizationRecord;

  protected _stateObserverKey: string;

  constructor(
    JobFetcher: IJobFetcherService,
    $scope: ng.IScope,
    protected $analytics: angulartics.IAnalyticsService,
    $window: ng.IWindowService,
    jobId?: number,
  ) {
    $window.scrollTo(0, 0);

    this.navDisplay = JobFetcher.currentPresentationState();

    this._stateObserverKey = JobFetcher.observePresentationState((state: boolean) => {
      this.navDisplay = state;
    });

    $scope.$on("$destroy", () => {
      JobFetcher.removePresentationStateObserver(this._stateObserverKey);
    });

    if (jobId >= 0) {
      this.job = JobFetcher.get(jobId);

      this.spinnerPromise = this.job.$promise;
      this.job.$promise.then(() => {
        if (useState().getIn(["payments", "authByOrgId", this.job.org_id], null) === null) {
          dispatch(paymentActions.AsyncActions.getAuthorized(this.job.org_id));
        }
      });
    }
  }

  public loadTools() {
    return dispatch(toolActions.AsyncActions.listTools(this.job.org_id, this.job.id));
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        job: this.job.id,
        org: this.job.org_id,
        navDisplay: !this.navDisplay,
      }),
    );
  }
}
