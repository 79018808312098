import { RootState, RootActions } from "app2/src/reducers";
import { createSelector } from "reselect";
import { PresentationTemplateRecord } from "app2/src/records/PresentationTemplate";
import { Map, List } from "immutable";

const presentationTemplatesById = (state: RootState) =>
  state.getIn(["presentationTemplates", "presentationTemplatesById"]);
const orgPresentationTemplateIds = (state: RootState, props: any) => {
  if (!props.orgId) {
    return List();
  }

  return state.getIn(["presentationTemplates", "presentationTemplatesByOrgId", props.orgId, "presentationTemplates"]);
};

export const getOrgPresentationTemplates = createSelector(
  [presentationTemplatesById, orgPresentationTemplateIds],
  (byId: Map<number, PresentationTemplateRecord>, presentations: List<number>) => {
    if (!presentations) {
      return List();
    }

    const interim = presentations.map((id) => byId.get(id)).sortBy((d) => d.sort_order);
    return interim;
  },
);
