import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { ListGroup } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { JobRecord } from "app2/src/records/Job";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as screenShareActions from "app2/src/reducers/screenShare.actions";

const mapStateToProps = (state: RootState, ownProps: ToolbarProps) => {
  const job = state.getIn(["components", "common", "currentJob"], null);
  return {
    job: job,
    screenShareStatus: state.getIn(["screenShares", "byJobId", job.id, "screenShareStatus"], "off"),
    screenShareKind: state.getIn(["screenShares", "byJobId", job.id, "screenShareKind"], "basic"),
    displayWebCam: state.getIn(["components", "common", "displayWebCam"], false),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ToolbarProps) => {
  return {
    openSendInviteModal: () => dispatch(commonActions.Actions.openSendInviteModal("screenshare", {})),
    openWebCamModal: () => dispatch(commonActions.Actions.openWebCamModal()),
    closeWebCamModal: () => dispatch(commonActions.Actions.closeWebCamModal()),
    startScreenShare: (jobId: number) =>
      dispatch(screenShareActions.AsyncActions.setRemoteScreenShareStatus(jobId, "start_requested")),
    stopScreenShare: (jobId: number) =>
      dispatch(screenShareActions.AsyncActions.setRemoteScreenShareStatus(jobId, "stop_requested")),
    startFallbackScreenShare: (jobId) => dispatch(screenShareActions.AsyncActions.startFallbackScreenShare(jobId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ToolbarProps {
  showSendInvite?: boolean;
}

interface ScreenShareState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ToolbarProps;

class Toolbar extends React.Component<Props, ScreenShareState> {
  constructor(props: Props) {
    super(props);

    this.state = {};

    this.openInviteModal = this.openInviteModal.bind(this);
  }

  public render() {
    const { screenShareKind, showSendInvite, screenShareStatus } = this.props;
    const screen_share_icon = this.screenShareIcon();
    const web_cam_icon = this.webCamIcon();

    if (showSendInvite) {
      return (
        <ListGroup className="screen-share-toolbar list-group-horizontal">
          <ListGroup.Item>{this.sendInviteIcon()}</ListGroup.Item>
        </ListGroup>
      );
    }

    return (
      <ListGroup className="screen-share-toolbar list-group-horizontal">
        <ListGroup.Item>{screen_share_icon}</ListGroup.Item>
        {screenShareKind === "premium" ? null : <ListGroup.Item>{web_cam_icon}</ListGroup.Item>}
        {screenShareKind === "premium" && _.include(["start_requested", "active", "tab_opened"], screenShareStatus) ? (
          <ListGroup.Item>{this.screenShareKindIcon()}</ListGroup.Item>
        ) : null}
        <ListGroup.Item>{this.sendInviteIcon()}</ListGroup.Item>
      </ListGroup>
    );
  }

  private screenShareIcon() {
    const { screenShareStatus, startScreenShare, stopScreenShare, job } = this.props;
    let screen_share_icon = null;
    let uid = "";
    if (job) {
      uid = job.uid;
    }

    switch (screenShareStatus) {
      case "off":
      case "stop_requested":
        screen_share_icon = (
          <a className="image-link" title={`Start Screen Sharing ${uid}`} onClick={() => startScreenShare(job.id)}>
            <img src="/assets/images/icons/start_share.1c7968bf.png" id="presentation-mode-start" />
            <div className="icon-text" id="presentation-mode-start-text"></div>
          </a>
        );
        break;
      case "start_requested":
      case "tab_opened":
      case "active":
        screen_share_icon = (
          <a className="image-link" title={`Stop Screen Sharing ${uid}`} onClick={() => stopScreenShare(job.id)}>
            <img src="/assets/images/icons/stop_share.b35105f4.png" id="presentation-mode-stop" />
            <div className="icon-text" id="presentation-mode-stop-text"></div>
          </a>
        );
        break;
    }
    return screen_share_icon;
  }

  private webCamIcon() {
    const { displayWebCam, openWebCamModal, closeWebCamModal } = this.props;
    let web_cam_icon = null;
    if (displayWebCam) {
      web_cam_icon = (
        <a className="image-link" title="Hide Camera" onClick={closeWebCamModal}>
          <img src="/assets/images/icons/stop_webcam.eb494947.png" id="presentation-mode-camera-stop" />
          <div className="icon-text" id="presentation-mode-camera-stop-text"></div>
        </a>
      );
    } else {
      web_cam_icon = (
        <a className="image-link" title="Show Camera" onClick={openWebCamModal}>
          <img src="/assets/images/icons/start_webcam.28e90bd8.png" id="presentation-mode-camera" />
          <div className="icon-text" id="presentation-mode-camera-text"></div>
        </a>
      );
    }
    return web_cam_icon;
  }

  private openInviteModal() {
    const { openSendInviteModal } = this.props;

    openSendInviteModal();
  }

  private sendInviteIcon() {
    return (
      <a className="image-link" title="Send Screen Share Invite" onClick={this.openInviteModal}>
        <img src="/assets/images/icons/send_invite.9b82395a.png" id="presentation-mode-invite" />
        <div className="icon-text" id="presentation-mode-invite-text"></div>
      </a>
    );
  }

  private screenShareKindIcon() {
    const { startFallbackScreenShare, job } = this.props;
    return (
      <a
        className="image-link"
        title="Fall Back to Basic Screen Share and Start"
        onClick={() => startFallbackScreenShare(job.id)}>
        <img src="/assets/images/icons/fallback_share.a11ac9a7.png" id="basic-start" />
        <div className="icon-text" id="basic-start-text"></div>
      </a>
    );
  }
}

export default connector(Toolbar);
