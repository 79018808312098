import { currentAccessUid } from "app2/src/reducers/auth.selectors";
import * as React from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { StoreRegistry } from "app2/src/storeRegistry";

export const addAuthUser = (route: string, accessUid: string) => {
  if (accessUid === undefined || accessUid === "") {
    return route;
  }
  let currentParams = "";
  // if we have existing query params, get them out
  // so we can append to them safely
  if (route.includes("?")) {
    [route, currentParams] = route.split("?");
  }
  const routeParams = new URLSearchParams(currentParams);
  routeParams.set("authUser", accessUid);
  return `${route}?${routeParams.toString()}`;
};

export interface CustomLinkProps<T> extends React.HTMLAttributes<T> {
  to: string;
  tag: React.ElementType;
  activeClassName?: string;
  variant?: string;
  target?: "_blank";
}

export const CustomLink: React.FunctionComponent<
  CustomLinkProps<
    | React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
    | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  >
> = ({ activeClassName, className, to, tag: Tag, children, ...rest }) => {
  const accessUid = useSelector(currentAccessUid);
  const callback = useCallback(
    (e: any) => {
      e.preventDefault();

      StoreRegistry.get<ng.IRootScopeService>("$rootScope").$apply(() => {
        StoreRegistry.get<ng.ILocationService>("$location").url(to);
      });
    },
    [to],
  );

  if (window.location.pathname === to) {
    className = className || "";
    className = `${className} ${activeClassName}`;
  }

  let attrs = { ...rest } as any;

  if (Tag === "a") {
    attrs = { ...attrs, href: addAuthUser(to, accessUid) };
    className = `${className} text-decoration-none leads-anchor-tag`;
  }

  return (
    <Tag {...attrs} className={className} onClick={attrs.target ? null : callback}>
      {children ? children : null}
    </Tag>
  );
};
