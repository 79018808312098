import { fetcher } from "../../helpers/Fetcher";
import { toJSON, IPlanData, PlanRecord } from "../../records/billing/Plan";

export interface IPlanIndexResponse {
  plans: IPlanData[];
}

export interface IPlanResponse {
  plan: IPlanData;
}

export interface IPlanQueryOptions {
  archived: boolean;
}

const url = "billing/v1/plans";

const includes = ["parent", "children_ids"];

export const query = async (options: Partial<IPlanQueryOptions> = {}): Promise<IPlanIndexResponse> => {
  return await fetcher.get<IPlanIndexResponse>(url, { "include[]": includes, ...options });
};

export const load = async (planId: number): Promise<IPlanResponse> => {
  const actionUrl = fetcher.joinUrls(url, planId.toString());

  return await fetcher.get<IPlanResponse>(actionUrl, { "include[]": includes });
};

export const create = async (plan: PlanRecord): Promise<IPlanResponse> => {
  const actionUrl = url;

  const data = toJSON(plan);

  return await fetcher.post<IPlanResponse>(actionUrl, { plan: data, "include[]": includes });
};

export const update = async (plan: PlanRecord): Promise<IPlanResponse> => {
  const actionUrl = fetcher.joinUrls(url, plan.id.toString());

  return await fetcher.patch<IPlanResponse>(actionUrl, { plan, "include[]": includes });
};

export const archive = async (plan: PlanRecord): Promise<IPlanResponse> => {
  const actionUrl = fetcher.joinUrls(url, plan.id.toString());

  return await fetcher.delete<IPlanResponse>(actionUrl);
};

export const unarchive = async (plan: PlanRecord): Promise<IPlanResponse> => {
  const actionUrl = fetcher.joinUrls(url, plan.id.toString(), "unarchive");

  return await fetcher.patch<IPlanResponse>(actionUrl);
};
