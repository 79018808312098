import { Record, Map } from "immutable";
import * as tokenActions from "./token.actions";
import { TokenRecord, TokenKind, fromJSON } from "../records/Token";
import { List } from "immutable";

export interface ITokenStateRecord {
  byOrgId: Map<number, ITokenKindRecord>;
  lastSavedByOrgId: Map<number, ITokenKindRecord>;
  authByOrgId: Map<number, TokenKindAuthorizedRecord>;
  statuses: List<any>;
}

export interface ITokenKindRecord {
  byKind: Map<TokenKind, TokenRecord>;
}

export interface ITokenKindAuthorizedRecord {
  byKind: Map<TokenKind, AuthorizedTokenRecord>;
  loading: boolean;
}

export const defaultTokenKindAuthorizedRecord: ITokenKindAuthorizedRecord = {
  byKind: Map<TokenKind, AuthorizedTokenRecord>(),
  loading: false,
};

export class TokenKindAuthorizedRecord
  extends Record<ITokenKindAuthorizedRecord>(defaultTokenKindAuthorizedRecord)
  implements ITokenKindAuthorizedRecord {}

export interface IAuthorizedTokenRecord {
  authorized: boolean;
}

export const defaultAuthorizedTokenRecord: IAuthorizedTokenRecord = {
  authorized: false,
};

export class AuthorizedTokenRecord
  extends Record<IAuthorizedTokenRecord>(defaultAuthorizedTokenRecord)
  implements IAuthorizedTokenRecord {}

export const defaultTokenStateRecord: ITokenStateRecord = {
  byOrgId: Map<number, ITokenKindRecord>(),
  lastSavedByOrgId: Map<number, ITokenKindRecord>(),
  authByOrgId: Map<number, TokenKindAuthorizedRecord>(),
  statuses: List<any>(),
};

export class TokenStateRecord extends Record<ITokenStateRecord>(defaultTokenStateRecord) implements ITokenStateRecord {}

export const initialState = new TokenStateRecord();

export const reducer = (state = initialState, action: tokenActions.Actions): TokenStateRecord => {
  switch (action.type) {
    case tokenActions.FETCH_TOKEN:
      if (state.getIn(["byOrgId", action.payload.orgId, action.payload.tokenKind])) {
        return state.setIn(["byOrgId", action.payload.orgId, action.payload.tokenKind, "loading"], true);
      }
      return state.setIn(
        ["byOrgId", action.payload.orgId, action.payload.tokenKind],
        fromJSON({ kind: action.payload.tokenKind, loading: true }),
      );
    case tokenActions.RECEIVE_TOKEN:
      return state
        .setIn(["byOrgId", action.payload.orgId, action.payload.token.kind], fromJSON(action.payload.token))
        .setIn(["lastSavedByOrgId", action.payload.orgId, action.payload.token.kind], fromJSON(action.payload.token));
    case tokenActions.EDIT_TOKEN_DATA:
      return state.setIn(["byOrgId", action.payload.orgId, action.payload.tokenKind, "data"], action.payload.data);
    case tokenActions.RESET_TOKEN:
      return state.setIn(
        ["byOrgId", action.payload.orgId, action.payload.tokenKind],
        state.getIn(["lastSavedByOrgId", action.payload.orgId, action.payload.tokenKind]),
      );
    case tokenActions.SET_LOADED:
      state = state.setIn(["byOrgId", action.payload.orgId, action.payload.tokenKind, "loading"], false);
      return state.setIn(["lastSavedByOrgId", action.payload.orgId, action.payload.tokenKind, "loading"], false);
    case tokenActions.FETCH_AUTHORIZED:
      return state
        .setIn(["authByOrgId", action.payload], new TokenKindAuthorizedRecord())
        .setIn(["authByOrgId", action.payload, "loading"], true);
    case tokenActions.RECEIVE_AUTHORIZED:
      Object.keys(action.payload.authorized).forEach((key) => {
        state = state.setIn(
          ["authByOrgId", action.payload.orgId, "byKind", key],
          new AuthorizedTokenRecord(action.payload.authorized[key]),
        );
      });
      return state.setIn(["authByOrgId", action.payload.orgId, "loading"], false);
    case tokenActions.SET_AUTHORIZED_LOADED:
      return state.setIn(["authByOrgId", action.payload, "loading"], false);
    case tokenActions.UPDATE_FORM: {
      const { isInsert, isDelete } = action.payload.event;
      let { name, value, rootPath } = action.payload.event;
      rootPath = _.without(rootPath, "tokens");
      if (isInsert) {
        const list = state.getIn(rootPath.concat(name.split("."))) as List<any>;
        value = list.insert(list.size, value);
      } else if (isDelete) {
        const nameSplitted = name.split(".");
        const index = nameSplitted.pop();
        name = nameSplitted.join(".");
        value = (state.getIn(rootPath.concat(name.split("."))) as List<any>).delete(index);
      }
      return state.setIn(rootPath.concat(name.split(".")), value);
    }
    default:
      return state;
  }
};
