import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { measurement, lastSavedMeasurement } from "app2/src/selectors/measurement.selectors";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { recordChanged } from "app2/src/helpers/Record";

/**
 * Looks to the currentJob in redux and gets the measurement_id from there
 *
 * @params {RootState} state RootState
 * @params {{}} options NONE
 * @returns {number} measurementId
 */
export const currentMeasurementId = createSelector(
  [currentJobId, (state, _) => state],
  (jobId: number, state: RootState) => {
    return state.getIn(["jobs", "byId", jobId, "measurement_id"]);
  },
);

/**
 * Looks to the currentJob in redux to get the measurement_id then loads that measurement from the store
 *
 * @params {RootState} state RootState
 * @params {{}} options NONE
 * @returns {MeasurementRecord} measurement
 */
export const currentMeasurement = createSelector(
  [currentMeasurementId, (state, _) => state],
  (measurementId: number, state: RootState) => measurement(state, { measurementId }),
);

/**
 * Looks to the currentJob in redux to get the measurement_id then loads that measurement from the store
 *
 * @params {RootState} state RootState
 * @params {{}} options NONE
 * @returns {MeasurementRecord} measurement
 */
export const currentLastSavedMeasurement = createSelector(
  [currentMeasurementId, (state, _) => state],
  (measurementId: number, state: RootState) => lastSavedMeasurement(state, { measurementId }),
);

/**
 * Looks to the currentJob in redux to get the measurement_id then loads that measurement from the store
 *
 * @params {RootState} state RootState
 * @params {{}} options NONE
 * @returns {MeasurementRecord} measurement
 */
export const measurementChanged = (state: RootState, props: any): boolean =>
  recordChanged(currentMeasurement(state, props), currentLastSavedMeasurement(state, props));
