import * as React from "react";
import useIsUserActive from "app2/src/hooks/useIsUserActive/useIsUserActive";
import ToggleAudioButton from "app2/src/components/Twilio/components/ToggleAudioButton";
import ToggleVideoButton from "app2/src/components/Twilio/components/ToggleVideoButton";
import ToggleScreenShareButton from "app2/src/components/Twilio/components/ToggleScreenShareButton";
import EndCallButton from "app2/src/components/Twilio/components/EndCallButton";
import { BrowserDetection } from "app2/src/helpers/BrowserDetection";
import { DeviceSelector } from "app2/src/components/DeviceSelector/DeviceSelector";
import useRoomState from "app2/src/components/Twilio/hooks/useRoomState/useRoomState";
import FlipCameraButton from "./FlipCameraButton";
import useVideoContext from "../hooks/useVideoContext/useVideoContext";

interface ControlsProps {
  screenShare: any;
}

export default function Controls({ screenShare }: ControlsProps) {
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  const isUserActive = useIsUserActive();
  const { isVideoTrackLoading } = useVideoContext();
  const showControls = isUserActive || roomState === "disconnected";

  return (
    <div className={showControls ? "controls-container show-controls" : "controls-container"}>
      <DeviceSelector />
      <ToggleAudioButton disabled={isReconnecting} />
      <ToggleVideoButton disabled={isReconnecting || isVideoTrackLoading} />
      {roomState !== "disconnected" && (
        <>
          {!BrowserDetection.isBrowserMobile() ? (
            <ToggleScreenShareButton disabled={isReconnecting} screenShare={screenShare} />
          ) : (
            <FlipCameraButton />
          )}
          <EndCallButton />
        </>
      )}
    </div>
  );
}
