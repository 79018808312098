import { react2angular } from "react2angular";
import FinancingApplication from "app2/src/components/FinancingApplication";

export class FinanceOptionRoutes {
  public static load(app: ng.IModule) {
    app.component(
      "financingApplication",
      react2angular(FinancingApplication, [] as never[], ["$stateParams", "$location"]),
    );
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider.state("finance_option_apply", <ng.ui.IState>{
      url: "/finance_options/:id/apply",
      views: {
        container: {
          component: "financingApplication",
        },
      },
    });
  }
}
