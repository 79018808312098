import * as React from "react";
import { WebhookEventRecord } from "app2/src/records/WebhookEvent";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { Attempt } from "./Attempt";

export interface IHistoryItemDetailsModalProps {
  event: WebhookEventRecord;
  showModal: boolean;
  url: string;
  onClose: () => any;
}

export const HistoryItemDetailsModal: React.FC<IHistoryItemDetailsModalProps> = ({
  event,
  showModal,
  url,
  onClose,
}) => {
  // hooks

  const [payloadExpanded, setPayloadExpanded] = React.useState<boolean>(false);

  const payloadElement: any = React.useRef();

  // methods

  const togglePayloadExpand = () => {
    if (payloadExpanded) payloadElement.current.classList.add("show-less");
    else payloadElement.current.classList.remove("show-less");
    setPayloadExpanded(!payloadExpanded);
  };

  return (
    <>
      <Modal show={showModal} size="lg" backdrop="static">
        <Modal.Header>
          <h3>Log History</h3>
        </Modal.Header>
        <Modal.Body className="overflow-y-auto">
          <Row>
            <Col md={4}>
              <strong>ID</strong>
            </Col>
            <Col md={4}>
              <strong>Event</strong>
            </Col>
            <Col md={4}>
              <strong>Webhook URL</strong>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="text_wrap">{event.id}</span>
            </Col>
            <Col md={4}>{_.toTitleCase(event?.payload.get("event_type").replaceAll(".", "_"))}</Col>
            <Col md={4}>
              <span className="text_wrap">{url}</span>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md={6}>
              <div className="form-section-heading">
                <h3>
                  <strong>Webhook Attempts</strong>
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card.Text>
                <pre className="code-snippet show-less" ref={payloadElement}>
                  <code>{JSON.stringify(event.payload, null, 2)}</code>
                </pre>
              </Card.Text>
              <button className="btn btn-light" onClick={togglePayloadExpand}>
                Show {payloadExpanded ? "Less" : "More"}
              </button>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Table>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Response Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {event.tries.get("attempts").map((attempt, index) => {
                    return <Attempt key={index} attempt={attempt} />;
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row align-end">
          <Button variant="cancel" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
