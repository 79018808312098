import { List, Record } from "immutable";
import { Nullable } from ".";
import { checkDate } from ".";
import { DropResult } from "react-beautiful-dnd";
import { JobAttributeTypes, orgJobAttributeLinkIdName } from "./OrgRecord";
import { sortDragEnd } from "../helpers/Record";

/**
 * Levels
 */
export interface IOrgLevelLinkData {
  id: string;
  org_uuid: string;
  level_id: string;
  sort_order: number;

  created_at: string | Date;
  updated_at: string | Date;
}

export interface ILevelData {
  id: string;
  name: string;
  org_id: Nullable<number>;
  org_level_link: Nullable<IOrgLevelLinkData>;
  org_level_links_attributes?: IOrgLevelLinkData[];

  created_at: string | Date;
  updated_at: string | Date;

  loading?: boolean;
}

export interface IOrgLevelLinkRecord {
  id: string;
  org_uuid: string;
  level_id: string;
  sort_order: number;

  created_at: Date;
  updated_at: Date;
}

export interface ILevelRecord {
  id: string;
  name: string;
  org_id: Nullable<number>;

  created_at: Date;
  updated_at: Date;

  loading: boolean;
}

export const defaultLevelProps = {
  id: "",
  name: "",
  org_id: null,
  created_at: new Date(),
  updated_at: new Date(),
  loading: false,
};

export const defaultOrgLevelLinkProps = {
  id: "",
  org_uuid: "",
  level_id: "",
  sort_order: 0,
  created_at: new Date(),
  updated_at: new Date(),
};

export class LevelRecord extends Record<ILevelRecord>(defaultLevelProps) implements ILevelRecord {}

export class OrgLevelLinkRecord
  extends Record<IOrgLevelLinkRecord>(defaultOrgLevelLinkProps)
  implements IOrgLevelLinkRecord {}

export const levelFromJSON = (json: Partial<ILevelData>): LevelRecord => {
  const recordData: ILevelRecord = { ...(json as any) };
  if (json.created_at) {
    recordData.created_at = checkDate(json.created_at);
  }
  if (json.updated_at) {
    recordData.updated_at = checkDate(json.updated_at);
  }

  delete (recordData as any).org_level_link;

  return new LevelRecord(recordData);
};

export const orgLevelLinkFromJSON = (json: Partial<IOrgLevelLinkData>): OrgLevelLinkRecord => {
  const recordData = { ...(json as any) };
  if (json.created_at) {
    recordData.created_at = checkDate(json.created_at);
  }
  if (json.updated_at) {
    recordData.updated_at = checkDate(json.updated_at);
  }
  return new OrgLevelLinkRecord(recordData);
};

/**
 * Location
 */

export interface IOrgLocationLinkData {
  id: string;
  org_uuid: string;
  location_id: string;
  sort_order: number;

  created_at: string | Date;
  updated_at: string | Date;
}

export interface ILocationData {
  id: string;
  name: string;
  org_id: Nullable<number>;
  org_location_link: Nullable<IOrgLocationLinkData>;
  org_location_links_attributes?: IOrgLocationLinkData[];

  created_at: string | Date;
  updated_at: string | Date;

  loading?: boolean;
}

export interface IOrgLocationLinkRecord {
  id: string;
  org_uuid: string;
  location_id: string;
  sort_order: number;

  created_at: Date;
  updated_at: Date;
}

export interface ILocationRecord {
  id: string;
  name: string;
  org_id: Nullable<number>;

  created_at: Date;
  updated_at: Date;

  loading: boolean;
}

export const defaultLocationProps = {
  id: "",
  name: "",
  org_id: null,
  created_at: new Date(),
  updated_at: new Date(),
  loading: false,
};

export const defaultOrgLocationLinkProps = {
  id: "",
  org_uuid: "",
  location_id: "",
  sort_order: 0,
  created_at: new Date(),
  updated_at: new Date(),
};

export class LocationRecord extends Record<ILocationRecord>(defaultLocationProps) implements ILocationRecord {}
export class OrgLocationLinkRecord
  extends Record<IOrgLocationLinkRecord>(defaultOrgLocationLinkProps)
  implements IOrgLocationLinkRecord {}

export const locationFromJSON = (json: Partial<ILocationData>): LocationRecord => {
  const recordData: ILocationRecord = { ...(json as any) };
  if (json.created_at) {
    recordData.created_at = checkDate(json.created_at);
  }
  if (json.updated_at) {
    recordData.updated_at = checkDate(json.updated_at);
  }

  delete (recordData as any).org_location_link;

  return new LocationRecord(recordData);
};

export const orgLocationLinkFromJSON = (json: Partial<IOrgLocationLinkData>): OrgLocationLinkRecord => {
  const recordData = { ...(json as any) };
  if (json.created_at) {
    recordData.created_at = checkDate(json.created_at);
  }
  if (json.updated_at) {
    recordData.updated_at = checkDate(json.updated_at);
  }
  return new OrgLocationLinkRecord(recordData);
};

/**
 * Job Object (Job Type)
 */

export interface IOrgJobObjectLinkData {
  id: number;
  org_id: number;
  object_id: number;
  sort_order: number;

  created_at: string | Date;
  updated_at: string | Date;
}

export interface IJobObjectData {
  id: number;
  name: string;
  description: string;
  org_id: Nullable<number>;
  org_job_object_link: Nullable<IOrgJobObjectLinkData>;
  org_job_object_links_attributes?: IOrgJobObjectLinkData[];

  created_at: string | Date;
  updated_at: string | Date;

  loading?: boolean;
}

export interface IOrgJobObjectLinkRecord {
  id: number;
  org_id: number;
  object_id: number;
  sort_order: number;

  created_at: Date;
  updated_at: Date;
}

export interface IJobObjectRecord {
  id: number;
  name: string;
  description: string;
  org_id: Nullable<number>;

  created_at: Date;
  updated_at: Date;

  loading: boolean;
}

export const defaultJobObjectProps = {
  id: 0,
  name: "",
  description: "",
  org_id: null,
  created_at: new Date(),
  updated_at: new Date(),
  loading: false,
};

export const defaultOrgJobObjectLinkProps = {
  id: 0,
  org_id: 0,
  object_id: 0,
  sort_order: 0,

  created_at: new Date(),
  updated_at: new Date(),
};

export class JobObjectRecord extends Record<IJobObjectRecord>(defaultJobObjectProps) implements IJobObjectRecord {}
export class OrgJobObjectLinkRecord
  extends Record<IOrgJobObjectLinkRecord>(defaultOrgJobObjectLinkProps)
  implements IOrgJobObjectLinkRecord {}

export const jobObjectFromJSON = (json: Partial<IJobObjectData>): JobObjectRecord => {
  const recordData: IJobObjectRecord = { ...(json as any) };
  if (json.created_at) {
    recordData.created_at = checkDate(json.created_at);
  }
  if (json.updated_at) {
    recordData.updated_at = checkDate(json.updated_at);
  }

  delete (recordData as any).org_job_object_link;

  return new JobObjectRecord(recordData);
};

export const orgJobObjectLinkFromJSON = (json: Partial<IOrgJobObjectLinkData>): OrgJobObjectLinkRecord => {
  const recordData = { ...(json as any) };
  if (json.created_at) {
    recordData.created_at = checkDate(json.created_at);
  }
  if (json.updated_at) {
    recordData.updated_at = checkDate(json.updated_at);
  }
  return new OrgJobObjectLinkRecord(recordData);
};

/**
 * JobAttribute Types
 */

export type JobAttribute = IJobObjectData | ILevelData | ILocationData;
export type JobAttributeRecordType = JobObjectRecord | LevelRecord | LocationRecord;
export type JobAttributeLink = IOrgJobObjectLinkData | IOrgLevelLinkData | IOrgLocationLinkData;
export type JobAttributeLinkRecordType = OrgJobObjectLinkRecord | OrgLevelLinkRecord | OrgLocationLinkRecord;

export const toJSON = <T extends JobAttribute>(jobObject: JobAttributeRecordType): T => jobObject.toJS() as any;

export const fromJsonByType = {
  job_type: jobObjectFromJSON,
  level: levelFromJSON,
  location: locationFromJSON,
};

export const linkFromJsonByType = {
  job_type: orgJobObjectLinkFromJSON,
  level: orgLevelLinkFromJSON,
  location: orgLocationLinkFromJSON,
};

export const sortJobAttributes = (
  result: DropResult,
  records: List<JobAttributeLinkRecordType>,
  byId: Map<string | number, JobAttributeRecordType>,
  updateRecord: (a, b, c) => any,
  jobAttrType: JobAttributeTypes,
): void => {
  if (!result.destination || result.destination.index === result.source.index) {
    return;
  }

  const { list, dirtyIds } = sortDragEnd(records, result, ["sort_order"]);

  // update order in backend
  // iterate through each dirty id, sending an API call to update the job source's
  // sort_order to what we got back from sortDragEnd()
  dirtyIds.forEach((id, idx) => {
    const dirtyLinkRecord: JobAttributeLinkRecordType = list.find((t: any) => t.get("id") === id);
    const jobAttrRecord: JobAttributeRecordType = byId.get(
      dirtyLinkRecord.get(orgJobAttributeLinkIdName(jobAttrType), ""),
    );
    const last = idx === dirtyIds.count() - 1;
    updateRecord(jobAttrRecord, dirtyLinkRecord, last);
  });
};
