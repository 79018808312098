import * as React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { wallFacades } from "app2/src/selectors/wallFacade.selectors";
import { useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { List } from "immutable";
import { getUoM } from "app2/src/records/Measurement";

export const Walls: React.FC = () => {
  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const wallFacadeIds = measurement?.get("wall_facade_ids") || List();
  const wallFacadeRecords = useSelector((state: RootState) => wallFacades(state, { wallFacadeIds }));
  const uom = getUoM(measurement);

  return (
    <>
      <Row>
        <Col>
          <h2>Walls</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Designator</th>
                <th>Area</th>
              </tr>
            </thead>
            <tbody>
              {wallFacadeRecords?.map((rf, idx) => {
                return (
                  <tr key={idx}>
                    <td>{rf.designator}</td>
                    <td>
                      {rf.area} {uom["wall_facade_area"]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
        <Col />
      </Row>
    </>
  );
};
