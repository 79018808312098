import { validateEmail, validatePhoneNumber } from "app2/src/helpers/InputValidator";
import { Nullable } from "app2/src/records";
import * as EmailValidator from "email-validator";

/**
 * Compose multiple FinalForm validators on one field level validation
 *
 * @param {...validators} FinalForm validators
 * @returns {Nullable<string>}
 */
export const composeValidators =
  (...validators: any[]) =>
  (value: Nullable<string>): Nullable<string> => {
    return validators.reduce((error, validator) => error || validator(value), undefined);
  };

/**
 * Returns undefined for valid inputs and the erorr message for invalid inputs.
 *
 * @param {data: Nullable<string | number>} input
 * @returns {Nullable<string>}
 */
export const required = (data: Nullable<string | number>): Nullable<string> => {
  const badValues = [null, undefined, ""];

  const dataStr: string = typeof data === "number" ? data.toString() : data;
  return badValues.includes(dataStr?.trim()) ? "Required" : undefined;
};

/**
 * Returns undefined for valid inputs and the erorr message for invalid inputs.
 *
 * @param {data: Nullable<boolean>} input
 * @returns {Nullable<string>}
 */
export const checkRequired = (data: Nullable<boolean>): Nullable<string> => {
  const badValues = [false, null, undefined];
  return badValues.includes(data) ? "Required" : undefined;
};

/**
 * Returns undefined for valid inputs and the erorr message for invalid inputs.
 *
 * @param {data: Nullable<string>} input
 * @returns {Nullable<string>}
 */
export const arrayRequired = (data: Nullable<any[]>): Nullable<string> => {
  const badValues = [null, undefined, 0];
  return badValues.includes(data?.length) ? "Required" : undefined;
};

/**
 * Returns undefined for valid phone number and the erorr message for invalid phone numbers.
 *
 * @param {data: Nullable<string>} input
 * @returns {Nullable<string>}
 */
export const phoneNumber = (phone: string): Nullable<string> => {
  return validatePhoneNumber(phone) ? undefined : "Improperly formatted Phone Number. Numeric Only.";
};

/**
 * Returns undefined for valid comma separated emails and the erorr message for invalid emails.
 *
 * @param {data: Nullable<string>} input
 * @returns {Nullable<string>}
 */
export const commaSeparatedEmail = (email: string): Nullable<string> => {
  return validateEmail(email) ? undefined : "Improperly formatted emails '_@__.__' (comma separated)";
};

/**
 * Returns undefined for valid base url (no query params) and the error message for invalid urls.
 */
export const validateBaseUrl = (input: string): Nullable<string> => {
  if (!input) return null;

  const url_regex = new RegExp(
    /^((?:http:\/\/)|(?:https:\/\/))(www.)?((?:[\-a-zA-Z0-9]+\.[\-a-z]{3})|(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::\d+)?))([\/\-a-zA-Z0-9\.]*)$/,
  );
  return input.match(url_regex) ? undefined : "Invalid URL";
};

/**
 * Returns undefined for valid https base url (including query params) and the error message for invalid  or not https urls.
 */
export const validateHttpsBaseUrl = (input: string): Nullable<string> => {
  if (!input) return null;

  const url_regex = new RegExp(
    /^((?:https:\/\/))(www\.)?((?:[\w\-]+\.)+(?:[\w\-]{2,})(?:\.[a-z]{2,})?|(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::\d+)?))([\/\w\-\.?#&=\[\]]*)$/,
  );
  return input.match(url_regex) ? undefined : "Invalid URL";
};

/*
 * Returns undefined for valid emails and the error message for invalid emails.
 *
 * @param {data: Nullable<string>} input
 * @returns {Nullable<string>}
 */
export const email = (email: string): Nullable<string> => {
  return EmailValidator.validate(email) ? undefined : "Improperly formatted email '_@__.__'";
};

/*
 * Returns undefined for number > input and the error message for number <= input
 *
 * @param {data: Nullable<string>} input
 * @returns {Nullable<string>}
 */
export const greaterThanValue = (greaterThanValue: number) => {
  return (value: any): Nullable<string> => {
    return value > greaterThanValue ? undefined : `Must be greater than ${greaterThanValue}`;
  };
};

/*
 * Returns undefined for number >= input and the error message for number < input
 *
 * @param {data: Nullable<string>} input
 * @returns {Nullable<string>}
 */
export const greaterThanOrEqualToValue = (greaterThanOrEqualToValue: number) => {
  return (value: any): Nullable<string> => {
    return value >= greaterThanOrEqualToValue
      ? undefined
      : `Must be greater than or equal to ${greaterThanOrEqualToValue}`;
  };
};

/*
 * Returns undefined for number < input and the error message for number >= input
 *
 * @param {data: Nullable<string>} input
 * @returns {Nullable<string>}
 */
export const lessThanValue = (lessThanValue: number) => {
  return (value: any): Nullable<string> => {
    return value < lessThanValue ? undefined : `Must be less than ${lessThanValue}`;
  };
};

/*
 * Returns undefined for number <= input and the error message for number > input
 *
 * @param {data: Nullable<string>} input
 * @returns {Nullable<string>}
 */
export const lessThanOrEqualToValue = (lessThanOrEqualToValue: number) => {
  return (value: any): Nullable<string> => {
    return value <= lessThanOrEqualToValue ? undefined : `Must be less than or equal to ${lessThanOrEqualToValue}`;
  };
};
