import { IEstimateMarkups } from "app/src/Models/Estimate";
import { IImageData, ImageRecord, fromJSON as imageFromJSON } from "app2/src/records/Image";
import { Record, List } from "immutable";
import { markupMultiplier } from "app2/src/records/Estimate";
import { IEstimateLineItemMarkupable, isMarkupable } from "app2/src/records/EstimateLineItem";

export const fromJSON = (json: Partial<IProductOptionData>): ProductOptionRecord => {
  const recordData: IProductOptionRecord = { ...(json as any) };

  if (!json.match_id) {
    recordData.match_id = json.uuid;
  }

  let record = new ProductOptionRecord(recordData);

  if (Array.isArray(json.images)) {
    record = record.set("images", List(json.images.map((i) => imageFromJSON(i))));
  }

  return record;
};

export const calculatePrice = (
  productOption: ProductOptionRecord,
  estimate: IEstimateMarkups,
  estimateLineItem: IEstimateLineItemMarkupable,
): number => {
  let productPrice = productOption.product_price || 0;
  let laborPrice = productOption.labor_price || 0;
  if (isMarkupable(estimateLineItem)) {
    productPrice = _.round(productOption.product_price * markupMultiplier(estimate.product_markup), -2);
    laborPrice = _.round(productOption.labor_price * markupMultiplier(estimate.labor_markup), -2);
  }

  return productPrice + laborPrice;
};

export interface IProductOptionData {
  id: number;
  matched: IProductOptionData[];
  uuid: string;
  match_id: string;
  option_group_id: number;
  name: string;
  uom: string;
  description: string;
  cost: number;
  msrp: number;
  labor_price: number;
  product_price: number;
  price: number;
  sort_order: number;
  images: IImageData[];
  charge_type: string;
  pog_type: string;
  show: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IProductOptionRecord {
  id: number;
  matched: List<ProductOptionRecord>;
  uuid: string;
  match_id: string;
  option_group_id: number;
  name: string;
  uom: string;
  description: string;
  cost: number;
  msrp: number;
  labor_price: number;
  product_price: number;
  price: number;
  sort_order: number;
  images: List<ImageRecord>;
  charge_type: string;
  pog_type: string;
  show: boolean;
  created_at: Date;
  updated_at: Date;
}

const defaultProductOptionProps: IProductOptionRecord = {
  id: 0,
  matched: List<ProductOptionRecord>(),
  uuid: "",
  match_id: "",
  option_group_id: 0,
  name: "",
  uom: "",
  description: "",
  cost: 0,
  msrp: 0,
  labor_price: 0,
  product_price: 0,
  price: 0,
  sort_order: 0,
  images: List(),
  charge_type: "",
  pog_type: "",
  show: true,
  created_at: new Date(),
  updated_at: new Date(),
};

export class ProductOptionRecord extends Record(defaultProductOptionProps) implements IProductOptionRecord {
  public readonly id!: number;
  public readonly matched!: List<ProductOptionRecord>;
  public readonly uuid!: string;
  public readonly match_id!: string;
  public readonly option_group_id!: number;
  public readonly name!: string;
  public readonly uom!: string;
  public readonly description!: string;
  public readonly cost!: number;
  public readonly msrp!: number;
  public readonly labor_price!: number;
  public readonly product_price!: number;
  public readonly price!: number;
  public readonly sort_order!: number;
  public readonly images!: List<ImageRecord>;
  public readonly charge_type!: string;
  public readonly pog_type!: string;
  public readonly show!: boolean;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IProductOptionRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
