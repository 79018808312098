import * as roomActions from "./room.actions";
import { Map, Record } from "immutable";
import { RoomRecord, fromJSON, defaultRoomProps } from "../records/Room";
import { RootActions, RootState } from ".";
import { rootKey } from "app2/src/selectors/room.selectors";
import { parseUpdateForm, reset } from "app2/src/reducers/Reducer";

export const RoomStateRecord = Record({
  byId: Map<string, RoomRecord>(),
  lastSavedById: Map<string, RoomRecord>(),
  unsavedId: -1,
});

export const initialState = RoomStateRecord();

export type RoomState = typeof initialState;

const model = rootKey;
export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let room: RoomRecord;
  switch (action.type) {
    case roomActions.RECEIVE_ROOM:
      room = fromJSON({ ...action.payload.room });

      return state
        .setIn([model, "byId", room.id.toString()], room)
        .setIn([model, "lastSavedById", room.id.toString()], room);

    case roomActions.EDIT_ROOM:
      room = fromJSON({ ...action.payload.room });

      return state.setIn([model, "byId", room.id.toString()], room);

    case roomActions.RESET_ROOMS:
      action.payload.roomIds.forEach((roomId) => {
        state = reset(state, model, roomId.toString());
      });
      return state;

    case roomActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;

      return state.setIn(rootPath.concat(name.split(".")), parseUpdateForm(defaultRoomProps, name, value));

    default:
      return state;
  }
};
