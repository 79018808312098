import { IOpening } from "app/src/Models/Opening";
import { ActivatedPriceListRecord } from "app2/src/records/ActivatedPriceList";
import { DocumentRecord } from "app2/src/records/Document";
import { findProductBracket, ProductRecord } from "app2/src/records/Product";
import { RootState } from "app2/src/reducers";
import { ProductReducerState } from "app2/src/reducers/product.reducer";
import { activatedPriceList } from "app2/src/selectors/activatedPriceList.selectors";
import { priceList } from "app2/src/selectors/priceList.selectors";
import { List } from "immutable";
import { createSelector } from "reselect";

export const product = (state: ProductReducerState, props: any) => state.getIn(["products", "byId", props.productId]);
export const productsById = (state: ProductReducerState, props: any) => state.getIn(["products", "byId"]);

export const cachedProduct = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): ProductRecord => {
    return product(activatedPriceList, props);
  },
);

export const cachedProductByUuid = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): ProductRecord => {
    const { productUuid } = props;
    return productsById(activatedPriceList, {}).find((product: ProductRecord) => {
      return product.uuid === productUuid;
    });
  },
);

export const cachedProductChildren = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): List<ProductRecord> => {
    const parent = product(activatedPriceList, props);
    return products(activatedPriceList, { ...props, productIds: parent.children_ids });
  },
);

export const cachedRootProducts = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): List<ProductRecord> => {
    const parent = priceList(activatedPriceList, { ...props, priceListId: activatedPriceList.price_list_id });
    return products(activatedPriceList, { ...props, productIds: parent.product_ids });
  },
);

export const cachedProducts = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): List<ProductRecord> => {
    const { productIds } = props;
    return products(activatedPriceList, { ...props, productIds });
  },
);

export const cachedOpeningParentProducts = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): List<ProductRecord> => {
    const { openings } = props;
    let { kinds } = props;
    if (!kinds) {
      kinds = ["window", "door"];
    }

    if (openings.length === 0) {
      return List();
    }

    const parentProducts = productsById(activatedPriceList, {})
      .filter((product: ProductRecord) => {
        return kinds.includes(product.kind) && product.children_ids.size > 0;
      })
      .map((product) => {
        return product;
      })
      .toList();

    return parentProducts.filter((parent: ProductRecord) => {
      let matchedCount = 0;
      openings.forEach((opening: IOpening) => {
        const children = products(activatedPriceList, { productIds: parent.children_ids });
        if (findProductBracket(parent, children, opening)) {
          matchedCount += 1;
        }
      });
      return openings.length === matchedCount;
    });
  },
);

export const cachedProductLeaves = createSelector(
  [activatedPriceList],
  (activatedPriceList: ActivatedPriceListRecord): List<ProductRecord> => {
    return productsById(activatedPriceList, {})
      .filter((product: ProductRecord) => {
        return product.children_ids.size === 0;
      })
      .toList();
  },
);

export const products = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<ProductRecord> => {
    const { state, props } = stateProps;
    const { productIds } = props;
    let { includeParent } = props;
    if (_.isUndefined(includeParent)) {
      includeParent = true;
    }
    return productIds
      .map((id: number) => product(state, { productId: id }))
      .filter((product: ProductRecord) => includeParent || product.children_ids.size === 0);
  },
);

export const cachedAncestors = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): List<ProductRecord> => {
    return ancestors(activatedPriceList, props);
  },
);

export const cachedAncestorDocuments = createSelector(
  [cachedAncestors, (_: RootState, props: any) => props],
  (cachedAncestors: List<ProductRecord>, props: any): List<DocumentRecord> => {
    const { display } = props;
    return cachedAncestors
      .map((product: ProductRecord) => {
        return product.documents.filter((doc) => {
          return doc.display === "both" || doc.display === display || display === "both";
        });
      })
      .flatten()
      .groupBy((doc) => doc.md5_sum)
      .map((x) => x.first())
      .toList();
  },
);

export const ancestors = createSelector(
  [(state: ProductReducerState, props: any) => ({ state, props })],
  (stateProps: any): List<ProductRecord> => {
    const { state, props } = stateProps;
    let products = List();
    const p = product(state, props);
    products = products.push(p);
    if (p.parent_id) {
      products = products.concat(ancestors(state, { ...props, productId: p.parent_id }));
    }
    return products;
  },
);

export const ancestralName = createSelector(
  [ancestors, (_: ProductReducerState, props: any) => props],
  (ancestors: List<ProductRecord>, props: any): string => {
    let ancestralName = "";
    let ipn = true;
    ancestors.forEach((product: ProductRecord, idx: number) => {
      if (idx === 0) {
        ancestralName = product.name;
      } else if (!ipn) {
        return;
      } else {
        ancestralName = product.name + " > " + ancestralName;
      }

      ipn = _.isUndefined(product.include_parent_names) || product.include_parent_names;
    });
    return ancestralName;
  },
);
