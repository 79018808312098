import * as segmentActions from "./segment.actions";
import { Map, Record, List } from "immutable";
import { FenceSegmentRecord, fromJSON } from "app2/src/records/measurements/Segment";
import { RootActions, RootState } from "app2/src/reducers";
import { rootKey } from "app2/src/selectors/measurements/segment.selectors";
import { rootKey as fenceRootKey } from "app2/src/selectors/measurements/fence.selectors";

export const SegmentStateRecord = Record({
  byId: Map<number, FenceSegmentRecord>(),
  lastSavedById: Map<number, FenceSegmentRecord>(),
  byMeasurementId: Map<number, number>(),
  unsavedId: -1,
});

export const initialState = SegmentStateRecord();

export type SegmentState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(rootKey)) {
    state = state.set(rootKey, initialState);
  }

  let segment: FenceSegmentRecord;

  switch (action.type) {
    case segmentActions.SET_GATE_COUNT:
      return state.setIn([rootKey, "byId", action.payload.segmentId, "gateCount"], action.payload.gateCount);

    case segmentActions.SET_GATE_LENGTH:
      return state.updateIn([rootKey, "byId", action.payload.segmentId], (segment: FenceSegmentRecord) => {
        const newLength = segment.length - action.payload.gateLength;
        return segment.merge({
          gateLength: action.payload.gateLength,
          netLength: newLength,
          linePosts4: Math.ceil(newLength / 4) - 1,
          linePosts6: Math.ceil(newLength / 6) - 1,
          linePosts8: Math.ceil(newLength / 8) - 1,
        });
      });

    case segmentActions.RESET_SEGMENT:
      return state.setIn(
        [rootKey, "byId", action.payload.segmentId],
        state.getIn([rootKey, "lastSavedById", action.payload.segmentId]),
      );

    case segmentActions.SET_SEGMENT:
      segment = action.payload.segment;

      if (_.isNullOrUndefined(segment.id) || segment.id === 0) {
        segment = segment.set("id", state.getIn([rootKey, "unsavedId"]));

        state = state.setIn([rootKey, "unsavedId"], segment.id - 1);
      }

      if (segment.fenceId && segment.fenceId !== 0) {
        state = state.updateIn([fenceRootKey, "byId", segment.fenceId, "segmentIds"], (segments: List<number>) => {
          if (!segments.contains(segment.id)) {
            segments = segments.push(segment.id);
          }
          return segments;
        });
      }

      return state.setIn([rootKey, "byId", segment.id], segment);

    case segmentActions.DELETE_SEGMENT:
      return state.deleteIn([rootKey, "byId", action.payload.segmentId]);

    case segmentActions.RECEIVE_SEGMENT:
      segment = fromJSON({ ...action.payload.segment });

      if (_.isNullOrUndefined(segment.id) || segment.id === 0) {
        segment = segment.set("id", state.getIn([rootKey, "unsavedId"]));

        state = state.setIn([rootKey, "unsavedId"], segment.id - 1);
      }

      if (segment.fenceId && segment.fenceId !== 0) {
        state = state.updateIn([fenceRootKey, "byId", segment.fenceId, "segmentIds"], (segments: List<number>) => {
          if (!segments.contains(segment.id)) {
            segments = segments.push(segment.id);
          }
          return segments;
        });
      }

      return state.setIn([rootKey, "byId", segment.id], segment).setIn([rootKey, "lastSavedById", segment.id], segment);

    default:
      return state;
  }
};
