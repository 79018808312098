import * as config from "react-global-configuration";
import * as userActions from "./user.actions";
import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction } from "./Utils";
import { RootState } from ".";
import { fetcher } from "../helpers/Fetcher";
import { RootDispatchType } from "../store";

export const RECEIVE_PAGE = "@analytics/RECEIVE_PAGE";
export const RESET_PAGES = "@analytics/RESET_PAGES";
export const FETCH_PAGE = "@analytics/FETCH_PAGE";

export const RECEIVE_USER_PAGE = "@analytics/RECEIVE_USER_PAGE";
export const RESET_USER_PAGES = "@analytics/RESET_USER_PAGES";
export const FETCH_USER_PAGE = "@analytics/FETCH_USER_PAGE";

export interface EventData {
  id: number;
  data: any;
}

export interface IUserAnalyticsData {
  userId: number;
  eventCount: number;
  lastSeen: Date;
  firstSeen: Date;
}

export interface Pagination {
  total_count: number;
  total_pages: number;
  current_page: number;
}

export interface Page<T> {
  page: number;
  data: T[];
  pagination: Pagination;
}

export const Actions = {
  receivePage: (page: Page<EventData>) => createAction(RECEIVE_PAGE, page),
  fetchPage: (page: number) => createAction(FETCH_PAGE, page),
  resetPages: () => createAction(RESET_PAGES),
  receiveUserPage: (page: Page<IUserAnalyticsData>) => createAction(RECEIVE_USER_PAGE, page),
  fetchUserPage: (page: number) => createAction(FETCH_USER_PAGE, page),
  resetUserPages: () => createAction(RESET_USER_PAGES),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, ActionsUnion<typeof Actions> | userActions.Actions>;

export const AsyncActions = {
  getPage: (page: number): ThunkResult<Promise<Page<EventData>>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPage(page));

      return fetcher
        .get<Page<EventData>>("/analytics/events", {
          code: config.get("RSF_ANALYTICS_KEY"),
          page: page,
        })
        .then((data: Page<EventData>) => {
          data.page = page;
          dispatch(Actions.receivePage(data));
          return data;
        });
    };
  },
  getUsersPage: (page: number): ThunkResult<Promise<Page<IUserAnalyticsData>>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchUserPage(page));

      return fetcher
        .get<Page<IUserAnalyticsData>>("/analytics/events/reports", {
          code: config.get("RSF_ANALYTICS_KEY"),
          report: "users",
          page: page,
        })
        .then((data: Page<IUserAnalyticsData>) => {
          data.page = page;

          dispatch(Actions.receiveUserPage(data));
          return data;
        });
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
