import { connect, ConnectedProps } from "app2/src/connect";
import { EstimateRecord } from "app2/src/records/Estimate";
import { getTotalName, OrgRecord, getPrefConfig, getFinanceOptionsName } from "app2/src/records/OrgRecord";
import { RootActions, RootState } from "app2/src/reducers";
import { List } from "immutable";
import * as React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { ThunkDispatch } from "redux-thunk";
import InlineEditor from "./InlineEditor";
import LineItem from "./LineItem";
import PaymentOptions from "./PaymentOptions";
import PaymentChooserWrapper from "./PaymentChooserWrapper";
import DiscountChooserWrapper from "./DiscountChooserWrapper";
import * as estimateComparisonActions from "app2/src/reducers/estimateComparison.actions";
import { EstimateLineItemRecord } from "app2/src/records/EstimateLineItem";
import { lineItemsEstimate, estimate } from "app2/src/selectors/estimate.selectors";
import { org } from "app2/src/selectors/org.selectors";
import Range from "app2/src/components/FinanceOption/Range";
import Spinner from "app2/src/components/SpinnerComponent";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { CurrencyFormat } from "app2/src/helpers/Format";
import track from "react-tracking";

const mapStateToProps = (state: RootState, ownProps: IEstimateViewProps) => {
  const est = estimate(state, { estimateId: ownProps.estimateId });
  let lineItems = List();
  if (est && !est.loading) {
    lineItems = lineItemsEstimate(state, { ...ownProps, included: true });
  }

  return {
    org: org(state, ownProps),
    estimate: est,
    lineItems: lineItems,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>) => {
  return {
    removeEstimateComparisonLink: (estimateId: number, jobId: number) =>
      dispatch(estimateComparisonActions.Actions.removeEstimateComparisonLink(estimateId, jobId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IEstimateViewProps {
  orgId: number;
  estimateId: number;
  activeKey: AccordionActiveKey;
  goToEstimate: (estimateId: number) => void;
  accordionToggle: (source: AccordionActiveKey) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IEstimateViewProps;

export type AccordionActiveKey = "" | "overview" | "total";
export type OtherAccordionActiveKey = "" | "other";

export interface IEstimateViewState {
  showAllItems: boolean;
  otherActiveKey: OtherAccordionActiveKey;
}

@track({ component: "Estimate View" })
export class EstimateView extends React.Component<Props, IEstimateViewState> {
  constructor(props) {
    super(props);

    this.toggleAllLineItems = this.toggleAllLineItems.bind(this);
    this.toggleOtherActiveKey = this.toggleOtherActiveKey.bind(this);
    this.navigateToEstimate = this.navigateToEstimate.bind(this);

    this.state = {
      showAllItems: false,
      otherActiveKey: "",
    };
  }

  public getFinancedTotalDisplay() {
    const { estimate } = this.props;
    return <h4>Total Financed Amount: {CurrencyFormat(estimate.payment_terms.financed_amount)}</h4>;
  }

  @track((props, state) => ({ action: `${state.showAllItems ? "Hide" : "Show"} Line Items` }))
  public toggleAllLineItems() {
    const { showAllItems } = this.state;

    this.setState({ showAllItems: !showAllItems });
  }

  public getLineItemsDisplay() {
    const { lineItems, estimate } = this.props;
    const { showAllItems } = this.state;

    let lineItemDisplay = null;

    if (showAllItems) {
      lineItemDisplay = lineItems.map((item, index) => (
        <LineItem key={index} lineItem={item} activatedPriceListId={estimate.activated_price_list_id} />
      ));
    } else {
      lineItemDisplay = lineItems
        .slice(0, 3)
        .map((item, index) => (
          <LineItem key={index} lineItem={item} activatedPriceListId={estimate.activated_price_list_id} />
        ));
    }

    return (
      <React.Fragment>
        {lineItemDisplay}
        {lineItems.size > 3 && (
          <h1 className="centered show-more" onClick={this.toggleAllLineItems}>
            ...
          </h1>
        )}
      </React.Fragment>
    );
  }

  public navigateToEstimate() {
    const { goToEstimate, estimateId } = this.props;
    goToEstimate(estimateId);
  }

  @track({ action: "Toggle Other" })
  public toggleOtherActiveKey() {
    const { otherActiveKey } = this.state;
    if (otherActiveKey === "") {
      this.setState({ otherActiveKey: "other" });
    } else {
      this.setState({ otherActiveKey: "" });
    }
  }

  public render() {
    const { org, orgId, estimate, estimateId, removeEstimateComparisonLink, activeKey, accordionToggle } = this.props;
    const { otherActiveKey } = this.state;

    if (!estimate || estimate.loading) {
      return <Spinner localProperty={true} inline={true} />;
    }

    const params = { type: "view" };
    return (
      <Card className="comparator-card">
        <Card.Header className="comparator-header">
          <Row>
            <Col sm={2} className="link" onClick={this.navigateToEstimate}>
              <FontAwesome name="angle-down fa-gray fa-2x" title="Go to estimate" size="2x" />
            </Col>
            <Col sm={8}>
              <InlineEditor estimateId={estimate.id} name={estimate.name} />
            </Col>
            <Col sm={2} className="delete-estimate">
              <ConfirmDialog title={"Are you sure you want to remove this from the comparison?"}>
                {(confirm) => (
                  <FontAwesome
                    className="link pull-right"
                    name="times fa-gray"
                    title="Remove this estimate from the comparison"
                    size="lg"
                    onClick={confirm(() => removeEstimateComparisonLink(estimate.id, estimate.job_id))}
                  />
                )}
              </ConfirmDialog>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="comparator-body">
          <Row>
            <Col className="range">
              {getPrefConfig(org, ["price_presentation", "finance_range"]) && (
                <Range orgId={orgId} estimateId={estimateId} asterisk={true} />
              )}
            </Col>
          </Row>
          <Accordion id="estimate-accordion" defaultActiveKey="overview" activeKey={activeKey}>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="overview"
                className="toggle-header"
                onClick={() => accordionToggle("overview")}>
                <div>
                  <span>Overview</span>
                  <span className="pull-right">
                    <FontAwesome
                      name={`${activeKey === "overview" ? "angle-down" : "angle-up"}`}
                      title="Expand"
                      size="lg"
                      className="pull-right"
                    />
                  </span>
                </div>
              </Accordion.Toggle>
            </Card>
            <Accordion.Collapse eventKey="overview">
              <Card className="overview-card">
                <Card.Body>
                  <Row className="toggle-body">
                    <Col>{this.getLineItemsDisplay()}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Accordion.Collapse>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="total"
                className="toggle-header"
                onClick={() => accordionToggle("total")}>
                <div className="small-header">
                  <span>
                    {getTotalName(org)}: {CurrencyFormat(estimate.total)}
                  </span>
                  <span className="pull-right">
                    <FontAwesome
                      name={`${activeKey === "total" ? "angle-down" : "angle-up"}`}
                      title="Expand"
                      size="lg"
                      className="pull-right"
                    />
                  </span>
                </div>
              </Accordion.Toggle>
            </Card>
            <Accordion.Collapse eventKey="total">
              <Row className="toggle-body">
                <Col>
                  <PaymentChooserWrapper orgId={orgId} estimate={estimate} />
                  <Accordion className="other-accordion" defaultActiveKey={""} activeKey={otherActiveKey}>
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="other"
                        className="toggle-header"
                        onClick={this.toggleOtherActiveKey}>
                        <div className="small-header">
                          <span>Other</span>
                          <span className="pull-right">
                            <FontAwesome
                              name={`${otherActiveKey === "other" ? "angle-down" : "angle-up"}`}
                              title="Expand"
                              size="lg"
                              className="pull-right"
                            />
                          </span>
                        </div>
                      </Accordion.Toggle>
                    </Card>
                    <Accordion.Collapse eventKey="other">
                      <Row className="toggle-body">
                        <Col>
                          <DiscountChooserWrapper orgId={orgId} estimate={estimate} params={params} />
                        </Col>
                      </Row>
                    </Accordion.Collapse>
                  </Accordion>
                </Col>
              </Row>
            </Accordion.Collapse>
          </Accordion>
          {getPrefConfig(org, ["price_presentation", "finance_options_section"]) && (
            <Card className="finance-card">
              <Card.Body>
                <Row className="finance-content finance-container">
                  <Col className="centered">
                    <span className="text-larger">{getFinanceOptionsName(org)}</span>
                    <div>
                      <React.Fragment>
                        <div className="centered finance-content">{this.getFinancedTotalDisplay()}</div>
                        <PaymentOptions orgId={orgId} estimate={estimate} />
                      </React.Fragment>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default connector(EstimateView);
