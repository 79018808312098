import { IDoc } from "app/src/Models/Doc";
import { ISignedDocumentRecipient } from "app/src/Models/SignedDocumentRecipient";
import { IEstimate } from "app/src/Models/Estimate";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface ISignedDocument extends ng.resource.IResource<ISignedDocument>, SignedDocumentPrototype {
  $email(): ng.IPromise<ISignedDocument>;
  $email(params?: Object, success?: Function, error?: Function): ng.IPromise<ISignedDocument>;
  $email(success: Function, error?: Function): ng.IPromise<ISignedDocument>;
}

export interface ISignedDocumentResponse extends ng.resource.IResourceArray<ISignedDocument> {
  signed_documents: ISignedDocument[];
  meta: any;
}

export interface ISignedDocumentResource extends ng.resource.IResourceClass<ISignedDocument>, IRsfResource {
  fromJSON?(data: any): ISignedDocument;

  create?(): ISignedDocument;
  create?(params?: Object, success?: Function, error?: Function): ISignedDocument;
  create?(success: Function, error?: Function): ISignedDocument;

  email?(): ISignedDocument;
  email?(params?: Object, success?: Function, error?: Function): ISignedDocument;
  email?(success: Function, error?: Function): ISignedDocument;
}

class SignedDocumentPrototype {
  public document: IDoc;
  public estimate: IEstimate;
  public recipients: ISignedDocumentRecipient[];
  public signed_document: ISignedDocument;

  public id: number;
  public status: string;
  public guid: string;
  public document_id: number;
  public estimate_id: number;
  public rs_created_at: Date;
  public rs_completed_at: Date;
  public rs_last_activity_at: Date;
  public rs_expires_on: Date;
  public is_trashed: boolean;
  public size: string;
  public content_type: string;
  public original_filename: string;
  public signed_pdf_checksum: string;
  public subject: string;
  public message: string;
  public processing_state: string;
  public merge_state: string;
  public state: string;
  public callback_location: string;
  public tags: string;
  public created_at: Date;
  public updated_at: Date;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/signed_documents/:id";

  const transformSingleResponse = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return new SignedDocument({});
    }

    const data = JSON.parse(response);

    return SignedDocument.fromJSON(data.signed_document);
  };

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number): ISignedDocument => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta = angular.fromJson(response);

    _.each(meta.signed_documents, (sd, index) => {
      meta.signed_documents[index] = SignedDocument.fromJSON(sd);
    });

    return meta;
  };

  const transformSingleRequest = (signedDocument: ISignedDocument) => {
    const data = JSON.parse(angular.toJson(signedDocument));

    if (data.estimate) {
      data.estimate_id = data.estimate.id;
      delete data.estimate;
    }

    return JSON.stringify({ signed_document: data });
  };

  const SignedDocument: ISignedDocumentResource = <ISignedDocumentResource>$resource(
    url,
    { id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: BaseConfig.BASE_URL + "/api/v1/estimates/:estimate_id/signed_documents",
        transformRequest: transformSingleRequest,
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      email: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: (signedDocument: ISignedDocument) => {
          return JSON.stringify({ id: signedDocument.id });
        },
        url: url + "/notify",
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        paramSerializer: "$httpParamSerializerJQLike",
        transformResponse: transformMultiple,
        isArray: false,
      },
    },
  );

  SignedDocument.fromJSON = (data: any) => {
    return new SignedDocument(data);
  };

  SignedDocument.inject = (injected: IRepository) => {
    resources = injected;
  };

  return SignedDocument;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
