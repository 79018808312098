import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { List } from "immutable";
import {
  PresentationRecord,
  fromJSON,
  IPresentationOptions,
  IPresentationData,
  PresentationTarget,
} from "app2/src/records/Presentation";
import { SlideRecord } from "app2/src/records/Slide";
import { IPresentationListOptions, presentationService } from "app2/src/api/presentation.service";
import { RootDispatchType } from "../store";
import { handleErrors } from "app2/src/reducers/Utils";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as taskActions from "app2/src/reducers/task.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { IPageData, QueryParamsRecord } from "app2/src/records/Page";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { presentation, presentationsSelectedIds } from "app2/src/selectors/presentation.selectors";
import { getBase64 } from "app2/src/records/File";
import { dirtyIds } from "app2/src/selectors/pagination.selectors";
import { ITaskContext, ITaskData } from "app2/src/records/Task";

// VIEW
export const SET_CURRENT_PRESENTATION_ID = "@presentations/SET_CURRENT_PRESENTATION_ID";
// ORG
export const FETCH_ORG_PRESENTATIONS = "@presentations/FETCH_ORG_PRESENTATIONS";
export const RECEIVE_ORG_PRESENTATIONS = "@presentations/RECEIVE_ORG_PRESENTATIONS";
export const RECEIVE_ORG_ERRORS = "@presentations/RECEIVE_ORG_ERRORS";
export const CREATE_ORG_PRESENTATION = "@presentations/CREATE_ORG_PRESENTATION";
export const CREATE_ORG_ERRORS = "@presentations/CREATE_ORG_ERRORS";
export const ADD_ORG_PRESENTATION = "@presentations/ADD_ORG_PRESENTATION";
// JOB
export const FETCH_JOB_PRESENTATIONS = "@presentations/FETCH_JOB_PRESENTATIONS";
export const RECEIVE_JOB_PRESENTATIONS = "@presentations/RECEIVE_JOB_PRESENTATIONS";
export const RECEIVE_JOB_ERRORS = "@presentations/RECEIVE_JOB_ERRORS";
export const CREATE_JOB_PRESENTATION = "@presentations/CREATE_JOB_PRESENTATION";
export const CREATE_JOB_ERRORS = "@presentations/CREATE_JOB_ERRORS";
export const ADD_JOB_PRESENTATION = "@presentations/ADD_JOB_PRESENTATION";
export const REMOVE_JOB_PRESENTATION = "@presentations/REMOVE_JOB_PRESENTATION";
// SINGLE
export const FETCH_PRESENTATION = "@presentations/FETCH_PRESENTATION";
export const RECEIVE_PRESENTATION = "@presentations/RECEIVE_PRESENTATION";
export const RECEIVE_SLIDE = "@presentations/RECEIVE_SLIDE";
export const RECEIVE_PRESENTATION_ERROR = "@presentations/RECEIVE_PRESENTATION_ERROR";
export const REMOVE_PRESENTATION = "@presentations/REMOVE_PRESENTATION";
export const EDIT_COVER_IMAGE = "@presentations/EDIT_COVER_IMAGE";
export const EDIT_SORT_ORDER = "@presentations/EDIT_SORT_ORDER";
export const EDIT_SELECTED = "@presentations/EDIT_SELECTED";
export const EDIT_NAME = "@presentation/EDIT_NAME";
export const EDIT_LINK = "@presentation/EDIT_LINK";
export const EDIT_PRESENTATION = "@presentation/EDIT_PRESENTATION";
export const RESET_PRESENTATION = "@presentation/RESET_PRESENTATION";
// MULTIPLE
export const FETCH_PRESENTATIONS = "@presentations/FETCH_PRESENTATIONS";
export const RECEIVE_PRESENTATIONS = "@presentations/RECEIVE_PRESENTATIONS";
export const SET_PRESENTATIONS_LOADED = "@presentations/SET_PRESENTATIONS_LOADED";
export const BATCH_EDIT_FOLDER_ID = "@presentations/BATCH_EDIT_FOLDER_ID";
export const BATCH_REMOVE_IDS = "@presentations/BATCH_REMOVE_IDS";
export const BATCH_EDIT_SELECTED = "@presentations/BATCH_EDIT_SELECTED";

export const Actions = {
  // VIEW
  setCurrentPresentationId: (id: number) => createAction(SET_CURRENT_PRESENTATION_ID, { id }),
  // SINGLE
  fetchPresentation: (presentationId: number) => createAction(FETCH_PRESENTATION, { presentationId }),
  receivePresentation: (presentation: PresentationRecord) => createAction(RECEIVE_PRESENTATION, { presentation }),
  receiveSlide: (slide: SlideRecord) => createAction(RECEIVE_SLIDE, { slide }),
  receivePresentationError: (presentationId: number, errors: string[]) =>
    createAction(RECEIVE_PRESENTATION_ERROR, { presentationId, errors }),
  removePresentation: (presentationId: number) => createAction(REMOVE_PRESENTATION, { presentationId }),
  editSortOrder: (presentationId: number, sortOrder: number) =>
    createAction(EDIT_SORT_ORDER, { presentationId, sortOrder }),
  editSelected: (presentationId: number, value: boolean) => createAction(EDIT_SELECTED, { presentationId, value }),
  editName: (presentationId: number, name: string) => createAction(EDIT_NAME, { presentationId, name }),
  editLink: (presentationId: number, link: string) => createAction(EDIT_LINK, { presentationId, link }),
  editCoverImage: (presentationId: number, coverImage: any) =>
    createAction(EDIT_COVER_IMAGE, { presentationId, coverImage }),
  editTarget: (presentationId: number, target: PresentationTarget) =>
    createAction(EDIT_PRESENTATION, { presentationId, name: "target", value: target }),
  resetPresentation: (presentationId: number) => createAction(RESET_PRESENTATION, { presentationId }),
  // ORG
  fetchOrgPresentations: (orgId: number) => createAction(FETCH_ORG_PRESENTATIONS, { orgId }),
  receiveOrgErrors: (orgId, errors: string[]) => createAction(RECEIVE_ORG_ERRORS, { orgId, errors }),
  receiveOrgPresentations: (orgId: number, presentations: List<PresentationRecord>) =>
    createAction(RECEIVE_ORG_PRESENTATIONS, { orgId, presentations }),
  createOrgPresentation: (orgId: number, presentation: PresentationRecord) =>
    createAction(CREATE_ORG_PRESENTATION, { orgId, presentation }),
  createOrgPresentationErrors: (orgId: number, errors: string[]) =>
    createAction(CREATE_ORG_PRESENTATION, { orgId, errors }),
  // JOB
  fetchJobPresentations: (jobId: number) => createAction(FETCH_JOB_PRESENTATIONS, { jobId }),
  receiveJobErrors: (jobId, errors: string[]) => createAction(RECEIVE_JOB_ERRORS, { jobId, errors }),
  receiveJobPresentations: (jobId: number, presentations: List<PresentationRecord>) =>
    createAction(RECEIVE_JOB_PRESENTATIONS, { jobId, presentations }),
  createJobPresentation: (jobId: number, presentation: PresentationRecord) =>
    createAction(CREATE_JOB_PRESENTATION, { jobId, presentation }),
  createJobPresentationErrors: (jobId: number, errors: string[]) => createAction(CREATE_JOB_ERRORS, { jobId, errors }),
  removeJobPresentation: (jobId: number, presentationId: number) =>
    createAction(REMOVE_JOB_PRESENTATION, { jobId, presentationId }),
  // MULTIPLE
  fetchPresentations: (presentationIds: List<number>) => createAction(FETCH_PRESENTATIONS, { presentationIds }),
  receivePresentations: (presentations: IPresentationData[]) => createAction(RECEIVE_PRESENTATIONS, { presentations }),
  setPresentationsLoaded: (presentationIds: List<number>) =>
    createAction(SET_PRESENTATIONS_LOADED, { presentationIds }),
  batchEditFolderId: (presentationIds: List<number>, folderId: number) =>
    createAction(BATCH_EDIT_FOLDER_ID, { presentationIds, folderId }),
  batchRemoveIds: (presentationIds: List<number>) => createAction(BATCH_REMOVE_IDS, { presentationIds }),
  batchEditSelected: (presentationIds: List<number>, value: boolean) =>
    createAction(BATCH_EDIT_SELECTED, { presentationIds, value }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  addJobPresentation: (jobId: number, presentation: PresentationRecord): ThunkResult<Promise<PresentationRecord>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.createJobPresentation(jobId, presentation));

      try {
        const response = await presentationService.create(jobId, presentation, "job");
        const presentationRecord = fromJSON(response.presentation);
        dispatch(Actions.receivePresentation(presentationRecord));
        return presentationRecord;
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(Actions.createJobPresentationErrors(jobId, errors));
        return Promise.reject(errors);
      }
    };
  },
  batchCreatePresentation: (files: any[], options: IPresentationOptions): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      let presentationSuccess = 0;
      await Promise.all(
        files.map(async (file) => {
          const record = fromJSON({
            name: file.name,
            kind: options.kind,
            data: file,
            org_id: currentOrgId(getState()),
            folder_id: options.folderId,
          });
          try {
            const response = await presentationService.upload(record);

            dispatch(Actions.receivePresentation(fromJSON(response.presentation)));
            dispatch(AsyncActions.processPresentation(response.presentation));
            presentationSuccess += 1;
            return response;
          } catch (response) {
            return response;
          }
        }),
      );

      let flashLevel = FlashLevels.warning;
      if (presentationSuccess === files.length) {
        flashLevel = FlashLevels.success;
      }

      dispatch(
        commonActions.Actions.flashAddAlert(
          flashLevel,
          presentationSuccess + "/" + files.length + " Presentations successfully uploaded",
        ),
      );
    };
  },
  createPresentation: (
    presentation: PresentationRecord,
    kind: "org" | "job",
  ): ThunkResult<Promise<PresentationRecord>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const id = setOptionsId({ kind: kind }, getState()).id;
      try {
        const response = await presentationService.create(id, presentation, kind);
        const presentationRecord = fromJSON(response.presentation);
        dispatch(Actions.receivePresentation(presentationRecord));
        dispatch(AsyncActions.processPresentation(response.presentation));
        return presentationRecord;
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(Actions.receivePresentationError(presentation.id, errors));
        return Promise.reject(errors);
      }
    };
  },
  updatePresentation: (presentation: PresentationRecord): ThunkResult<Promise<PresentationRecord>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPresentation(presentation.id));

      try {
        const response = await presentationService.update(presentation);
        const presentationRecord = fromJSON(response.presentation);
        dispatch(Actions.receivePresentation(presentationRecord));
        return presentationRecord;
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(Actions.receivePresentationError(presentation.id, errors));
        return Promise.reject(errors);
      }
    };
  },
  createCoverImage: (file: any, presentationId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      let record = presentation(getState(), { presentationId });
      try {
        const result = await getBase64(file);
        record = record.set("cover_image", result);
        dispatch(Actions.fetchPresentation(presentationId));

        const response = await presentationService.coverImage(record);
        const presentationRecord = fromJSON(response.presentation);
        dispatch(Actions.receivePresentation(presentationRecord));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Cover image successfully added"));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.receivePresentationError(presentationId, errors));
      }
    };
  },
  // used in gaf-app - keeping for backwards compatibility
  listJobPresentations: (jobId: number, include: string[] = []): ThunkResult<any> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchJobPresentations(jobId));

      try {
        const response = await presentationService.load(jobId, "job", include);
        const presentationRecords = List(response.presentations.map((p) => fromJSON(p)));
        dispatch(Actions.receiveJobPresentations(jobId, presentationRecords));
        return presentationRecords;
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(Actions.receiveJobErrors(jobId, errors));
        return Promise.reject(errors);
      }
    };
  },
  // used in gaf-app - keeping for backwards compatibility
  listOrgPresentations: (orgId: number, include: string[] = []): ThunkResult<any> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchOrgPresentations(orgId));

      try {
        const response = await presentationService.load(orgId, "org", include);
        const presentationRecords = List(response.presentations.map((p) => fromJSON(p)));
        dispatch(Actions.receiveOrgPresentations(orgId, presentationRecords));
        return presentationRecords;
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(Actions.receiveOrgErrors(orgId, errors));
        return Promise.reject(errors);
      }
    };
  },
  listPresentations: (
    options: Partial<IPresentationListOptions>,
    queryParams: QueryParamsRecord,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const mergedOptions = setOptionsId(options, getState());
      dispatch(paginationActions.Actions.fetchPage("presentation", queryParams));

      try {
        const response = await presentationService.list(mergedOptions, queryParams);
        dispatch(Actions.receivePresentations(response.presentations));
        const pageData: Partial<IPageData> = {
          ids: List(response.presentations.map((presentation: IPresentationData) => presentation.id)),
          pagination: response.meta.pagination,
        };
        dispatch(paginationActions.Actions.receivePage("presentation", queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(paginationActions.Actions.receivePageError("presentation", queryParams, errors));
      }
    };
  },
  destroyPresentation: (presentation: PresentationRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPresentation(presentation.id));

      try {
        await presentationService.destroy(presentation);
        dispatch(paginationActions.Actions.removeId(presentation.id, "presentation"));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Presentation successfully deleted"));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.receivePresentationError(presentation.id, errors));
      }
    };
  },
  batchUpdatePresentation: (presentationIds: List<number>, reject = false): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const promises = [];
      dispatch(Actions.fetchPresentations(presentationIds));

      presentationIds.forEach((presentationId) => {
        const updatedPresentation = presentation(getState(), { presentationId });
        promises.push(presentationService.update(updatedPresentation));
      });

      try {
        const responses = await Promise.all(promises);
        const presentations = responses.map((response) => response.presentation);
        dispatch(Actions.receivePresentations(presentations));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved presentations successfully"));
      } catch (response) {
        dispatch(Actions.setPresentationsLoaded(presentationIds));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, ["Update failed, please try again."]));
        if (reject) {
          return Promise.reject();
        }
      }
    };
  },
  onSortEnd: (oldIndex: number, newIndex: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      await dispatch(paginationActions.AsyncActions.onSortEnd("presentation", oldIndex, newIndex));

      dispatch(AsyncActions.batchUpdatePresentation(dirtyIds(getState())));
      dispatch(paginationActions.Actions.setDirtyIds(null));
    };
  },
  processPresentation: (presentation: IPresentationData): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(paginationActions.Actions.pushId(presentation.id, "presentation"));

      const { task } = presentation;
      if (task) {
        if (task) {
          const contextObj: ITaskContext = {
            model: "presentation",
            action: "taskHandler",
            action_payload: { presentationId: presentation.id },
            error_action: "errorTaskHandler",
          };
          task.context = contextObj;
          dispatch(taskActions.Actions.receiveTask(task));
        }
      }
    };
  },
  taskHandler: (task: ITaskData, payload: any = {}): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.editCoverImage(payload.presentationId, task.results.cover_image));
    };
  },
  movePresentations: (folderId: number): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const presentationIds = presentationsSelectedIds(getState(), {});
      const pres = presentation(getState(), { presentationId: presentationIds.first() });

      dispatch(Actions.batchEditFolderId(presentationIds, folderId));
      try {
        await dispatch(AsyncActions.batchUpdatePresentation(presentationIds, true));

        dispatch(Actions.batchRemoveIds(presentationIds));
        await dispatch(AsyncActions.checkPageEmpty(pres.folder_id));
      } catch {
        dispatch(Actions.batchEditFolderId(presentationIds, pres.folder_id));
      }
    };
  },
  checkPageEmpty: (folderId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      return paginationActions.checkPageEmpty(getState(), "presentation", (newQueryParams) =>
        dispatch(
          AsyncActions.listPresentations(
            {
              kind: "org",
              folderId,
            },
            newQueryParams,
          ),
        ),
      );
    };
  },
  errorTaskHandler: (task: ITaskData, payload: any = {}): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const errorMessage =
        task?.results?.error_message ||
        "There were problems processing your task. Please try again. If the problem persists contact support.";
      dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errorMessage));
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;

export const setOptionsId = (
  options: Partial<IPresentationListOptions>,
  state: RootState,
): IPresentationListOptions => {
  if (_.isNullOrUndefined(options.id)) {
    switch (options.kind) {
      case "job":
        options.id = currentJobId(state);
        break;
      case "org":
        options.id = currentOrgId(state);
        break;
    }
  }

  return options as IPresentationListOptions;
};
