import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction } from "../Utils";
import { RootState, RootActions } from "..";
import { fromJSON, IMakePaymentData, PaymentRecord } from "app2/src/records/PaymentRecord";
import * as paymentActions from "app2/src/reducers/payment.actions";
import { Nullable } from "app2/src/records";
import { RootDispatchType } from "../../store";
import { authorize, getAuthorization, makePayment } from "app2/src/api/integrations/cardconnect.service";
import { handleErrors } from "app2/src/reducers/Utils";
import { authorizationRecord } from "app2/src/selectors/cardconnect.selectors";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as commonActions from "app2/src/reducers/components/common.actions";

export const SET_AUTHORIZED = "@integrations/cardconnect/SET_AUTHORIZED";
export const FETCH_AUTHORIZATION = "@integrations/cardconnect/FETCH_AUTHORIZATION";
export const SET_AUTHORIZATION = "@integrations/cardconnect/SET_AUTHORIZATION";
export const UPDATE_FORM = "@integrations/cardconnect/UPDATE_FORM";
export const RESET = "@integrations/cardconnect/RESET";

export const Actions = {
  fetchAuthorization: (orgId: number) => createAction(FETCH_AUTHORIZATION, orgId),
  setAuthorization: (orgId: number, authorization: any) =>
    createAction(SET_AUTHORIZATION, { orgId: orgId, authorization: authorization }),
  update: (event: any) => createAction(UPDATE_FORM, { event }),
  reset: (id: number) => createAction(RESET, { id }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, RootActions>;

export const AsyncActions = {
  makePayment: (
    orgId: number,
    token: string,
    accountInfo: any,
    payment: IMakePaymentData,
  ): ThunkResult<Promise<Nullable<PaymentRecord>>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(paymentActions.Actions.makePayment());
      try {
        const result = await makePayment(orgId, token, accountInfo, payment);
        const record = fromJSON(result.payment);
        dispatch(paymentActions.Actions.receivePayment(record));
        return record;
      } catch (error) {
        let msg = "";
        if (error.status && error.status === 500) {
          msg = "Server Error: Internal Server Error";
        } else if (error.status && error.status === 422 && error.data && error.data.error === "no valid token") {
          msg =
            "No valid authorization.  Please check the organization's configuration.  If this persists please contact support.";
        } else if (
          error.status &&
          error.status === 422 &&
          error.data &&
          error.data.status_code &&
          error.data.status_code === 401
        ) {
          msg =
            "Invalid credentials.  Please check the organization's integration configuration.  If this persists please contact support.";
        } else if (error.status && error.status === 422 && error.data && error.data.error) {
          msg = error.data.error;
        } else {
          msg = error.message || "Unknown Error";
        }

        dispatch(paymentActions.Actions.paymentError(msg));
        return error;
      }
    };
  },
  getAuthorization: (orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchAuthorization(orgId));

      try {
        const result = await getAuthorization(orgId);
        dispatch(Actions.setAuthorization(orgId, result));
      } catch (error) {
        handleErrors(error, dispatch);
      }
    };
  },
  authorize: (orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const record = authorizationRecord(getState(), orgId);
      dispatch(Actions.fetchAuthorization(orgId));

      try {
        const result = await authorize(record, orgId);
        dispatch(Actions.setAuthorization(orgId, result));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Authorized successfully"));
      } catch (error) {
        handleErrors(error, dispatch);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
