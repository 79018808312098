import { combineReducers } from "redux-immutable";
import { Map } from "immutable";
import { reducer as commonReducer, initialState as CommonInitialState } from "./common.reducer";
import { Actions as commonActions } from "./common.actions";
import {
  reducer as integrationsViewReducer,
  initialState as IntegrationsViewInitialState,
} from "./integrationsView.reducer";
import { Actions as integrationsViewActions } from "./integrationsView.actions";
import { Actions as estimatorActions } from "./estimator.actions";
import { reducer as estimatorReducer, initialState as EstimatorInitialState } from "./estimator.reducer";

export const ComponentsInitialState = Map({
  common: CommonInitialState,
  estimator: EstimatorInitialState,
  integrations: IntegrationsViewInitialState,
});

export type ComponentsState = typeof ComponentsInitialState;

export const reducer = combineReducers<ComponentsState, ComponentsActions>({
  //@ts-ignore
  common: commonReducer,
  integrations: integrationsViewReducer,
  estimator: estimatorReducer,
});

export type ComponentsActions = commonActions | integrationsViewActions | estimatorActions;
