import { ActionsUnion, createAction } from "./Utils";
import { IDoorData } from "../records/Door";
import { List } from "immutable";

export const RECEIVE_DOOR = "@doors/RECEIVE_DOOR";
export const EDIT_DOOR = "@doors/EDIT_DOOR";
export const RESET_DOORS = "@doors/RESET_DOORS";
export const UPDATE_FORM = "@doors/UPDATE_FORM";

export const Actions = {
  receiveDoor: (door: Partial<IDoorData>) => createAction(RECEIVE_DOOR, { door }),
  editDoor: (door: Partial<IDoorData>) => createAction(EDIT_DOOR, { door }),
  resetDoors: (doorIds: List<number>) => createAction(RESET_DOORS, { doorIds }),
  updateForm: (event: { rootPath: any; name: string; value: any }) => createAction(UPDATE_FORM, { event }),
};

export type Actions = ActionsUnion<typeof Actions>;
