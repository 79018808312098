import { Record, List } from "immutable";

export const fromJSON = (data: Partial<IPresentationTemplateData>): PresentationTemplateRecord => {
  const record: IPresentationTemplateRecord = { ...(data as any) };

  return new PresentationTemplateRecord(record);
};

export const toJSON = (record: PresentationTemplateRecord): IPresentationTemplateData => {
  return record.toJS();
};

export interface ITemplateData {
  slides: Array<number>;
}

export interface ITemplateRecord {
  slides: List<number>;
}

const defaultTemplateProps: ITemplateRecord = {
  slides: List(),
};

export class TemplateRecord extends Record(defaultTemplateProps) implements ITemplateRecord {
  public slides!: List<number>;

  public constructor(values?: Partial<TemplateRecord>) {
    values ? super(values) : super();
  }
}

export interface IPresentationTemplateData {
  id: number;
  org_id: number;
  name: string;
  template: ITemplateData;
  sort_order: number;
  loading?: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IPresentationTemplateRecord {
  id: number;
  org_id: number;
  name: string;
  template: TemplateRecord;
  sort_order: number;

  created_at: Date;
  updated_at: Date;

  loading: boolean;
  errors: List<string>;
}

const defaultPresentationTemplateProps: IPresentationTemplateRecord = {
  id: -1,
  org_id: null,
  name: "",
  template: new TemplateRecord(),
  sort_order: 0,
  created_at: null,
  updated_at: null,
  loading: false,
  errors: List<string>(),
};

export class PresentationTemplateRecord
  extends Record(defaultPresentationTemplateProps)
  implements IPresentationTemplateRecord
{
  public readonly id!: number;
  public readonly org_id!: number;
  public readonly name!: string;
  public readonly template!: any;
  public readonly sort_order!: number;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public constructor(values?: Partial<IPresentationTemplateRecord>) {
    if (values) {
      values.template = new TemplateRecord(values.template);
      super(values);
    } else {
      super();
    }
  }
}
