import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as presentationActions from "app2/src/reducers/presentation.actions";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { Row, Col } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Sortable } from "app2/src/components/Common/Sortable";
import Presentation from "app2/src/components/OrgTabs/PresentationTab/Presentations/Presentation";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { List } from "immutable";
import { selectedChecked } from "app2/src/selectors/presentation.selectors";
import track from "react-tracking";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { Can } from "app2/src/components/Common/CanComponent";

const mapStateToProps = (state: RootState) => {
  const modelName = "presentation",
    presentationQueryParams = queryParams(state, { modelName });
  const page = presentationQueryParams.get("page");
  const presentationIds = ids(state, { modelName });
  return {
    selectedCheck: selectedChecked(state, { presentationIds }),
    presentationIds,
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    presentationQueryParams,
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: PresentationsProps) => {
  return {
    listPresentations: (queryParams: QueryParamsRecord) =>
      dispatch(
        presentationActions.AsyncActions.listPresentations(
          {
            kind: "org",
            folderId: ownProps.folderParentId,
          },
          queryParams,
        ),
      ),
    onSortEnd: (oldIndex: number, newIndex: number) =>
      dispatch(presentationActions.AsyncActions.onSortEnd(oldIndex, newIndex)),
    batchEditBoolean: (presentationIds: List<number>, name: string, value: boolean) =>
      dispatch(presentationActions.Actions.batchEditSelected(presentationIds, value)),
    batchUpdatePresentation: (presentationIds) =>
      dispatch(presentationActions.AsyncActions.batchUpdatePresentation(presentationIds)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PresentationsProps {
  folderParentId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentationsProps;

@track(() => {
  return {
    component: "Presentations",
  };
})
export class Presentations extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.queryPresentations = this.queryPresentations.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
  }

  @track((_props, _state, [page]) => ({
    action: "queryPresentations",
    page: page,
  }))
  public queryPresentations(page: number): void {
    const { listPresentations, presentationQueryParams } = this.props;
    const newQueryParams = presentationQueryParams.setIn(["page"], page);

    listPresentations(newQueryParams);
  }

  @track((props, _state, [oldIndex, newIndex]) => ({
    action: "onDragEnd",
    oldIndex: oldIndex,
    newIndex: newIndex,
    presentations: props.presentationIds.toArray(),
  }))
  public onDragEnd(oldIndex: number, newIndex: number): void {
    const { onSortEnd } = this.props;

    onSortEnd(oldIndex, newIndex);
  }

  @track((props, _state, [event]) => ({
    action: "checkAll",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
    presentations: props.presentationIds.toArray(),
  }))
  public checkBoxOnChange(event: any): void {
    const { presentationIds, batchEditBoolean, batchUpdatePresentation } = this.props;
    batchEditBoolean(presentationIds, event.target.name, event.target.checked);
    if (event.target.name !== "selected") {
      batchUpdatePresentation(presentationIds);
    }
  }

  public render(): React.ReactNode {
    const { presentationIds, page, pagination, selectedCheck, presentationQueryParams } = this.props;

    if (page?.loading) {
      return <SpinnerComponent localProperty={true} />;
    }

    if (_.isNullOrUndefined(page) || presentationIds.size === 0) {
      return (
        <Row>
          <Col>
            <div className="form-section blank-state">
              <img src="/assets/images/icons-large/docs.33cddf3f.png" />
              <h2>No presentations added. Let's add one.</h2>
              <p>
                Click on the add presentation button in the top right and then select your file. You can upload PDF's
                and revealjs html.
              </p>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col>
          <div className="standard-rl-margin">
            <Row className="table-header">
              <Col sm={1} style={{ paddingLeft: "15px" }}>
                <Can resource="org" permission="update">
                  <IncludeCheckBox name="selected" checked={selectedCheck} onChange={this.checkBoxOnChange} />
                </Can>
              </Col>
              <Col sm={3}>Cover Image</Col>
              <Col sm={3}>Name</Col>
              <Col sm={3}>Uploaded On</Col>
              <Col sm={2}>Actions</Col>
            </Row>
            <Sortable
              dragHandle
              items={presentationIds}
              onDragEnd={this.onDragEnd}
              renderItem={({ item, dragHandle }) => {
                return <Presentation presentationId={item} dragHandle={dragHandle} />;
              }}
            />
            <StandardPagination metadata={pagination} pageChanged={this.queryPresentations} />
            <br />
          </div>
        </Col>
      </Row>
    );
  }
}

export default connector(Presentations);
