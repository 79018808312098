import * as React from "react";
import { RootState } from "app2/src/reducers";
import { FormGroupProps, FormLabelProps } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getPaginationByModel, isCurrentPageLoading } from "app2/src/selectors/pagination.selectors";
import { ReactSelect, FormContext, fromJSON as reactSelectFromJSON, ReactSelectRecord } from "@tberrysoln/rsf-form";
import { queryParamsFromJSON, QueryParamsRecord } from "app2/src/records/Page";

export interface RsfQueryableDropdownProps {
  name: string;
  label: string;
  id?: string;
  modelName: string;
  modelPath: (string | number)[];
  query: (queryParams: QueryParamsRecord, modelName: string) => void;
  queryParams?: any;
  formGroupProps?: FormGroupProps;
  formLabelProps?: FormLabelProps;
  queryOnLoad?: boolean;
  closeMenuOnSelect?: boolean;
  customStyles?: any;
  selectProps?: any;
  allOption?: ReactSelectRecord;
  nameFn?: (item: any) => string;
}

export const RsfQueryableDropdown: React.FC<RsfQueryableDropdownProps> = ({
  name,
  label,
  id,
  modelName,
  formGroupProps,
  formLabelProps,
  modelPath,
  query,
  queryParams = {},
  queryOnLoad = false,
  closeMenuOnSelect = true,
  customStyles = {},
  selectProps = {},
  allOption,
  nameFn,
}) => {
  // Hooks
  React.useEffect(() => {
    if (queryOnLoad) {
      onQuery("");
    }
  }, []);
  const { rootPath } = React.useContext(FormContext);

  // Local State
  const [lastInputValue, setLastInputValue] = React.useState("");
  const selected: ReactSelectRecord = useSelector((state: any) => state.getIn(rootPath.concat(name.split("."))));

  // Selectors
  const options = useSelector((state: RootState) => {
    let items = getPaginationByModel(state, { path: modelPath, modelName });
    if (nameFn) {
      items = items.map((item) => reactSelectFromJSON({ id: item.id, name: nameFn(item) }));
    }
    if (allOption) {
      items = items.insert(0, allOption);
    }
    if (selected) {
      items = items.filter((item) => item.id !== selected.id).insert(0, selected);
    }
    return items;
  });

  const loading = useSelector((state: RootState) => isCurrentPageLoading(state, { modelName }));

  // Methods
  const handleInputChange = _.debounce((term: string, { action }) => {
    if (action === "menu-close") {
      return;
    }
    if (lastInputValue !== term) {
      onQuery(term);
    }
    setLastInputValue(term);
  }, 500);

  const onQuery = (term: string) => {
    if (term === undefined) {
      term = "";
    }
    const optns = queryParamsFromJSON({ query: term, parameters: queryParams });
    query(optns, modelName);
  };

  const onMenuOpen = () => {
    onQuery("");
  };

  const filterOption = () => true;

  return (
    <ReactSelect
      id={id}
      label={label}
      name={name}
      options={options}
      onInputChange={handleInputChange}
      closeMenuOnSelect={closeMenuOnSelect}
      selectProps={{
        onMenuOpen,
        filterOption,
        styles: customStyles,
        onSelectResetsInput: true,
        ...selectProps,
      }}
      isLoading={loading}
      formGroupProps={formGroupProps}
      formLabelProps={formLabelProps}></ReactSelect>
  );
};
