import * as React from "react";
import { Row, Col } from "react-bootstrap";

export const SelectOrgHeader = () => {
  return (
    <Row className="align-items-center pt-2 pb-1 mw-100 m-0 text-center">
      <Col sm={2}>
        <label>Select</label>
      </Col>
      <Col sm={2}>
        <label>ID</label>
      </Col>
      <Col>
        <label>Name</label>
      </Col>
      <Col>
        <label>Status</label>
      </Col>
    </Row>
  );
};
