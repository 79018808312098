import * as React from "react";

export interface ICheckCircleProps {
  checked: boolean;
  disabled?: boolean;
  column?: boolean;
  onChange?: (value: boolean) => void;
}

export interface ICheckCirlcState {
  checked: boolean;
}

export class CheckCircle extends React.Component<ICheckCircleProps, ICheckCirlcState> {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
    };
  }

  public componentDidUpdate() {
    if (this.props.checked !== this.state.checked) {
      this.setState({
        checked: this.props.checked,
      });
    }
  }

  public _handleChange = () => {
    if (this.props.onChange) {
      this.props.onChange(!this.state.checked);
    }
  };

  public render() {
    const { disabled, column } = this.props;
    const { checked } = this.state;
    let className = "check";
    if (column) {
      className = `${className} center-checkbox justify-content-start`;
    }
    return (
      <div className={className}>
        <label>
          <input
            type="checkbox"
            className="check-circle"
            checked={checked}
            disabled={disabled}
            onChange={this._handleChange}
          />
          <span className="checkbox-span" />
        </label>
      </div>
    );
  }
}
