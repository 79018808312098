import * as React from "react";
import { Component } from "react";
import { store } from "./store";
import { connect as baseConnect, Connect, Provider, ReactReduxContext } from "react-redux";
import { ErrorBoundary } from "app2/src/components/ErrorBoundary";

export type { ConnectedProps } from "react-redux";

export const createConnect =
  (store) => (mapStateToProps?, mapDispatchToProps?, mergeProps?, options?) => (WrappedComponent) => {
    const ConnectedComponent = baseConnect(mapStateToProps, mapDispatchToProps, mergeProps, options)(WrappedComponent);

    class ConnectWrapper extends Component {
      static contextType = ReactReduxContext as any;
      public render() {
        if (this.context && this.context.store) {
          return <ConnectedComponent {...this.props} />;
        }

        return (
          <Provider store={store}>
            <ErrorBoundary>
              <ConnectedComponent {...this.props} />
            </ErrorBoundary>
          </Provider>
        );
      }
    }

    return ConnectWrapper;
  };

export const connectFC = (Component) => (props) => {
  const context = React.useContext(ReactReduxContext);

  if (context) {
    return <Component {...props} />;
  }

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    </Provider>
  );
};

export const connect = createConnect(store) as any as Connect;
