import * as React from "react";
import { connectFC } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { Modal, Row, Col, Card } from "react-bootstrap";
import {
  token as tokenSelector,
  tokenChanged as tokenChangedSelector,
  lastSavedToken as lastSavedTokenSelector,
} from "app2/src/selectors/token.selectors";
import * as tokenActions from "app2/src/reducers/token.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import Spinner from "app2/src/components/SpinnerComponent";
import Task from "app2/src/components/Task";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Check, RsfForm } from "@tberrysoln/rsf-form";
import { usePrevious } from "app2/src/hooks/usePrevious";

export interface ISettingsModalProps {
  triggerOpen: number;
}

const SettingsModal: React.FunctionComponent<ISettingsModalProps> = ({ triggerOpen }) => {
  // Hooks
  const dispatch = useDispatch();
  const previousTriggerOpen = usePrevious(triggerOpen);

  // Local State
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskId, setTaskId] = useState("");

  // Selectors
  const orgId = useSelector((state: RootState) => currentOrgId(state));
  const token = useSelector((state: RootState) =>
    tokenSelector(state, {
      kind: "net_suite",
    }),
  );
  const lastSavedToken = useSelector((state: RootState) => lastSavedTokenSelector(state, { kind: "net_suite" }));
  const tokenChanged = useSelector((state: RootState) => tokenChangedSelector(state, { kind: "net_suite" }));

  // Methods
  const resetForm = () => {
    dispatch(tokenActions.Actions.resetToken(orgId, "net_suite"));
  };

  const hide = () => {
    resetForm();
    setOpen(false);
    setTaskId(null);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      const integrationResponse = await dispatch(orgActions.AsyncActions.testIntegration("net_suite"));
      setTaskId(integrationResponse["taskId"]);
      setLoading(false);

      if (integrationResponse["close"]) {
        await hide();
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const check = () => {
    return tokenChanged;
  };

  // Life Cycle
  useEffect(() => {
    if (triggerOpen !== 0 && previousTriggerOpen !== triggerOpen) {
      setOpen(true);
      dispatch(tokenActions.AsyncActions.getToken(orgId, "net_suite"));
    }
  }, [triggerOpen]);

  return lastSavedToken ? (
    <Modal size="lg" show={open} onHide={hide} backdrop="static">
      {loading && <Spinner localProperty={true} />}
      <DirtyWatcher check={check} reset={resetForm} />
      <Modal.Header closeButton>
        <Modal.Title>NetSuite Settings</Modal.Title>
        <a
          className="action-button pull-right"
          title="Help Article"
          href="https://help.oneclickcontractor.com/netsuite-configuration">
          <i className="rsf-question-circle-link rsf-base-66 text-primary"></i>
        </a>
      </Modal.Header>

      <RsfForm
        rootPath={["tokens", "byOrgId", orgId, "net_suite"]}
        updateFormReducer={tokenActions.Actions.update}
        onSubmit={onSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Card>
                <Card.Header>Job Events</Card.Header>
                <Card.Body>
                  <Check type="checkbox" label="Created" name="data.events.job.created" />
                  <Check type="checkbox" label="Assigned" name="data.events.job.assigned" />
                  <Check type="checkbox" label="Updated" name="data.events.job.updated" />
                  <Check type="checkbox" label="Status Changed" name="data.events.job.status_changed" />
                  <Check type="checkbox" label="Image Created" name="data.events.job.image_created" />
                  <Check type="checkbox" label="Document Created" name="data.events.job.document_created" />
                </Card.Body>
              </Card>
              <Card className="mt-3">
                <Card.Header>Signed Document Events</Card.Header>
                <Card.Body>
                  <Check type="checkbox" label="Signed" name="data.events.signed_document.signed" />
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>Estimate Events</Card.Header>
                <Card.Body>
                  <Check type="checkbox" label="Created" name="data.events.estimate.created" />
                  <Check type="checkbox" label="PDF Generated" name="data.events.estimate.pdf_generated" />
                  <Check type="checkbox" label="Updated" name="data.events.estimate.updated" />
                  <Check type="checkbox" label="Manual Trigger" name="data.events.estimate.manual_trigger" />
                </Card.Body>
              </Card>
              <Card className="mt-3">
                <Card.Header>Signable Document Events</Card.Header>
                <Card.Body>
                  <Check type="checkbox" label="Signed" name="data.events.signable_document.signed" />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Task taskId={taskId} />
        </Modal.Body>
        <Modal.Footer>
          <ButtonFooter cancel={hide} />
        </Modal.Footer>
      </RsfForm>
    </Modal>
  ) : (
    <Spinner localProperty={token?.loading} />
  );
};

export default connectFC(SettingsModal);
