import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { List, getIn } from "immutable";
import { EventResultReasonRecord } from "../records/EventResultReason";

const model = "eventResultReasons";

export const eventResultReasonsById = (state: RootState) => state.getIn([model, "byId"]);

export const eventResultReason = (state: RootState, props: { reasonId: number }): EventResultReasonRecord =>
  state.getIn([model, "byId", props.reasonId]);

export const orgEventResultReasonIds = (state: RootState, props: { orgId: number }) =>
  getIn(state, [model, "byOrgId", props.orgId], List());

export const getOrgEventResultReasons = createSelector(
  [eventResultReasonsById, orgEventResultReasonIds, (state: RootState, props: any) => ({ state, props })],
  (eventResultReasonsById: Map<number, EventResultRecord>, orgEventResultReasonIds: List<number>, stateProps) => {
    const { props } = stateProps;
    const { eventsResultId } = props;
    let { kind } = props;

    if (!kind) kind = "default";
    if (eventsResultId) {
      return orgEventResultReasonIds
        .map((id) => eventResultReasonsById.get(id))
        .filter((result) => result.kind === kind && result.events_result_id === eventsResultId);
    }

    return orgEventResultReasonIds.map((id) => eventResultReasonsById.get(id)).filter((result) => result.kind === kind);
  },
);
