import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Row, Col, Image } from "react-bootstrap";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { EstimateRecord } from "app2/src/records/Estimate";
import {
  getDescriptionName,
  getFinanceName,
  getPrefConfig,
  getSubtotalName,
  getTotalName,
  OrgRecord,
} from "app2/src/records/OrgRecord";
import { getUrl } from "app2/src/records/Image";
import { FinanceOptionRecord, range, rate } from "app2/src/records/FinanceOption";
import { estimate } from "app2/src/selectors/estimate.selectors";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import DiscountChooserWrapper from "app2/src/components/PriceDrawer/DrawerContainer/Comparator/EstimateView/DiscountChooserWrapper";

const mapStateToProps = (state: RootState, ownProps: EstimateProps) => {
  return {
    estimate: estimate(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: EstimateProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface EstimateProps {
  estimateId: number;
  financeOptionsById: { number: FinanceOptionRecord };
  org: OrgRecord;
  params: any;
}

export interface EstimateState {
  show_finance: boolean;
  financeOption: FinanceOptionRecord;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & EstimateProps;

class EstimatePresentationView extends React.Component<Props, EstimateState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show_finance: false,
      financeOption: null,
    };

    this.toggleFinalFinance = this.toggleFinalFinance.bind(this);
    this.selectFinanceOption = this.selectFinanceOption.bind(this);
  }

  public toggleFinalFinance() {
    this.setState((state) => {
      return { show_finance: !state.show_finance };
    });
  }

  public selectFinanceOption(event) {
    const { financeOptionsById } = this.props;
    if (event.target.value === "") {
      this.setState({
        financeOption: null,
      });
      return;
    }

    const financeOption = financeOptionsById[event.target.value];
    this.setState({
      financeOption: financeOption,
    });
  }

  public render() {
    const { estimate, org } = this.props;
    const { width } = this.props.params;
    const { show_finance } = this.state;
    const params = { type: "present" };

    if (!estimate) {
      return <SpinnerComponent localProperty={true} inline={true} />;
    }

    return (
      <Col md={width} className="present-estimate">
        <Row className="description-header">
          <Col md={12}>
            <h3 className="text-center description-name">{getDescriptionName(org)}:</h3>
          </Col>
        </Row>
        <Row className="estimate-header">
          <Col md={12}>
            <h3 className="text-center estimate-name">{estimate.name}</h3>
          </Col>
        </Row>
        {this.renderFinanceRange()}
        <Row className="padding-top">
          <Col md={12} className="text-center">
            <Image src={getUrl(org.images.first(), "medium")} className="max-250-250" />
          </Col>
        </Row>
        <Row className="padding-top">
          <Col md={6}>
            <h3 className="pull-right link subtotal-value" onClick={this.toggleFinalFinance}>
              {CurrencyFormat(estimate.subtotal)}
            </h3>
          </Col>
          <Col md={6}>
            <h3 className="subtotal-name link" onClick={this.toggleFinalFinance}>
              {getSubtotalName(org)}
            </h3>
          </Col>
        </Row>
        <DiscountChooserWrapper estimate={estimate} orgId={estimate.job.org_id} params={params} />
        <Row className="border-pricing padding-top">
          {this.estimateTotalView()}
          {show_finance ? this.renderFinalFinance() : null}
        </Row>
      </Col>
    );
  }

  public estimateTotalView() {
    const { org, estimate } = this.props;
    const pref = getPrefConfig(org, ["price_presentation", "estimate_total"]);
    let showTotal = false;
    if (pref === "always") {
      showTotal = true;
    } else if (estimate.discounts.count() > 0) {
      showTotal = true;
    }

    if (!showTotal) {
      return <span></span>;
    }

    return (
      <React.Fragment>
        <Col md={6}>
          <h3 className="pull-right bold-text link total-value" onClick={this.toggleFinalFinance}>
            {CurrencyFormat(estimate.total)}
          </h3>
        </Col>
        <Col md={6}>
          <h3 className="bold-text link total-name" onClick={this.toggleFinalFinance}>
            {getTotalName(org)}
          </h3>
        </Col>
      </React.Fragment>
    );
  }

  public renderFinalFinance() {
    const { financeOption } = this.state;
    const { estimate, financeOptionsById } = this.props;

    const financeOptions = [];
    _.each(Object.keys(financeOptionsById), (key) => {
      const fo = financeOptionsById[key];
      if (fo.status === "active") {
        financeOptions.push(fo);
      }
    });

    if (financeOptions.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        {financeOption ? (
          <React.Fragment>
            <Col md={6} className="finance-option-payment-container">
              <h3 className="pull-right bold-text finance-option-payment-name">{rate(financeOption, estimate)}</h3>
            </Col>
            <Col md={6} className="finance-option-term-container">
              <h3 className="bold-text finance-option-term">per Month</h3>
            </Col>
          </React.Fragment>
        ) : null}
        <Col md={12} className="padding-bottom select-finance-option-container">
          <select className="form-control finance-option-selector" onChange={this.selectFinanceOption}>
            <option value="" className="finance-option">
              Choose a Finance Option
            </option>
            {financeOptions.map((fo, idx) => {
              return (
                <option className="finance-option" key={idx} value={fo.id}>
                  {fo.name}
                </option>
              );
            })}
          </select>
        </Col>
      </React.Fragment>
    );
  }

  public renderFinanceRange() {
    const { estimate, financeOptionsById, org } = this.props;
    const finance_type = getPrefConfig(org, ["price_presentation", "finance_type"]);

    let type = "active";

    if (finance_type === "active_range") {
      type = finance_type;
    }

    const financeOptions = [];
    _.each(Object.keys(financeOptionsById), (key) => {
      const fo = financeOptionsById[key];
      if (fo.status === type) {
        financeOptions.push(fo);
      }
    });

    if (financeOptions.length === 0) {
      return null;
    }

    return (
      <Row className="border-pricing padding-top">
        <Col md={12} className="text-center finance-range-container">
          <h3 className="finance-name">{getFinanceName(org)}</h3>
          <h2 className="finance-value bold-text">{range(financeOptions, estimate)}</h2>
        </Col>
      </Row>
    );
  }
}

export default connector(EstimatePresentationView);
