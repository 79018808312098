import { baseCleanProps } from "app2/src/api/Api";
import { fetcher } from "../helpers/Fetcher";
import { ITokenData, TokenRecord, TokenKind } from "../records/Token";

export interface ITokenResponse {
  token: ITokenData;
}

class TokenService {
  public url = "api/v1/orgs/:org_id/tokens";

  public loadByKind(orgId: number, tokenKind: TokenKind): Promise<ITokenResponse> {
    const actionUrl = fetcher.joinUrls(this.url.replace(":org_id", orgId.toString()), tokenKind);

    return fetcher.get(actionUrl);
  }

  public loadAuthorized(orgId: number): Promise<ITokenResponse> {
    const actionUrl = fetcher.joinUrls(this.url.replace(":org_id", orgId.toString()), "authorized");

    return fetcher.get(actionUrl);
  }

  public create(token: TokenRecord): Promise<ITokenResponse> {
    const actionUrl = this.url.replace(":org_id", token.org_id.toString());

    return fetcher.post(actionUrl, token.toJS());
  }

  public update(token: TokenRecord): Promise<ITokenResponse> {
    const actionUrl = fetcher.joinUrls(this.url.replace(":org_id", token.org_id.toString()), token.kind);

    return fetcher.patch(actionUrl, cleanProps(token));
  }

  public deleteByKind(orgId: number, tokenKind: TokenKind): Promise<ITokenResponse> {
    const actionUrl = fetcher.joinUrls(this.url.replace(":org_id", orgId.toString()), tokenKind);

    return fetcher.delete<any>(actionUrl);
  }
}

export const cleanProps = (token: TokenRecord): ITokenData => {
  const tokenParams = baseCleanProps(token.toJS());

  if (tokenParams.kind === "net_suite" && tokenParams.data.events) {
    Object.keys(tokenParams.data.events).forEach((resource: string) => {
      const object = tokenParams.data.events[resource];
      tokenParams.data[resource] = [];
      Object.keys(object).forEach((key: string) => {
        if (object[key]) {
          tokenParams.data[resource].push(key);
        }
      });
    });
    delete tokenParams.data.events;
  }

  return tokenParams as any as ITokenData;
};

export const tokenService = new TokenService();
