import { ISession } from "app/src/Common/SessionService";
export class LogoutCtrl {
  public static $inject = ["$state", "Session", "$stateParams"];

  constructor($state: ng.ui.IStateService, Session: ISession, $stateParams: ng.ui.IStateParamsService) {
    Session.logout().then(() => {
      const params: any = {};
      if ($stateParams.redirect) {
        params.redirect = $stateParams.redirect;
      }
      $state.go("root.login", params);
    });
  }
}
