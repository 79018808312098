import { distance } from "@turf/turf";
import { List } from "immutable";
import { FeatureCollection } from "geojson";
import * as L from "leaflet";
import "leaflet-draw";
import {
  FencingRecord,
  FenceSegmentRecord,
  emptyFenceRecord,
  emptyFenceSegmentRecord,
} from "../records/measurements/Fencing";
import { setup as setupMap, setupDrawing } from "./MappingTool";

/**
 * setup function for Leaflet & Drawing tool.  Can be used as both a 'setup' and a 'reset' after exporting
 *
 * @param containerId map container id.  If empty, will not be setup
 * @param updated updat callback
 * @param onChangeStart callback for change start.  if both start/end are null, will be only reset Drawing tool
 * @param onChangeEnd callback for change end.  if both start/end are null, will be only reset Drawing tool

 */
export const setup = (
  containerId: string,
  updated?: (geoJSON: FeatureCollection) => void,
  onChangeStart?: () => void,
  onChangeEnd?: () => void,
): void => {
  const drawOptions: Partial<L.Control.DrawOptions> = {
    polyline: {
      allowIntersection: false,
      showLength: true,
      metric: false,
    },
    polygon: {
      metric: false,
    },
  };

  L.drawLocal.draw.toolbar.buttons.polyline = "Draw a Terminal Post Fence";
  L.drawLocal.draw.toolbar.buttons.polygon = "Draw a Closed-Loop Fence";
  L.drawLocal.draw.handlers.polyline.error = "<string>Error:</strong> fence edges cannot cross!";
  L.drawLocal.draw.handlers.polyline.tooltip.start = "Click to start drawing a fence";
  L.drawLocal.draw.handlers.polyline.tooltip.cont = "Click to continue drawing the fence";
  L.drawLocal.draw.handlers.polyline.tooltip.end = "Click the last point to finish fence";
  L.drawLocal.draw.handlers.polygon.tooltip.start = "Click to start drawing a fence";
  L.drawLocal.draw.handlers.polygon.tooltip.cont = "Click to continue drawing the fence";
  L.drawLocal.draw.handlers.polygon.tooltip.end = "Click on the first point to finish fence";
  L.drawLocal.edit.toolbar.buttons.edit = "Edit fences";
  L.drawLocal.edit.toolbar.buttons.editDisabled = "No fences to edit";
  L.drawLocal.edit.toolbar.buttons.remove = "Delete fences";
  L.drawLocal.edit.toolbar.buttons.removeDisabled = "No fences to delete";
  L.drawLocal.edit.handlers.edit.tooltip.text = "Drag handles to edit fences.";
  L.drawLocal.edit.handlers.remove.tooltip.text = "Click on a fence to delete it";

  setupMap(containerId, updated, onChangeStart, onChangeEnd);
  setupDrawing(drawOptions, !onChangeStart || !onChangeEnd);
};

const pointsToLines = (points: Array<Array<number>>): Array<Array<Array<number>>> => {
  const lines: Array<Array<Array<number>>> = [];
  for (let idx = 0; idx < points.length - 1; idx++) {
    lines.push([points[idx], points[idx + 1]]);
  }

  return lines;
};

export const geoJSONToFenceMeasurement = (geoJSON: FeatureCollection, fencing: FencingRecord): FencingRecord => {
  const validFeatureTypes: string[] = ["LineString", "Polygon"];

  if (!geoJSON) {
    return fencing;
  }

  let totalLength = 0,
    terminalPosts = 0,
    cornerPosts = 0,
    linePosts4 = 0,
    linePosts6 = 0,
    linePosts8 = 0,
    gateLength = 0,
    gateCount = 0;

  geoJSON.features
    .filter((f) => validFeatureTypes.includes(f.geometry.type))
    .forEach((f, fIdx) => {
      let fenceMeasurement = fencing.fences.get(fIdx, emptyFenceRecord),
        featureLength = 0,
        featureLinePosts4 = 0,
        featureLinePosts6 = 0,
        featureLinePosts8 = 0,
        featureCornerPosts = 0,
        featureTerminalPosts = 0,
        featureGateCount = 0,
        featureGateLength = 0;

      let markers: Array<Array<number>> = [...(f.geometry as any).coordinates];

      if (f.geometry.type === "Polygon") {
        markers = [...markers[0]] as any;
        markers.pop();
      }

      const lines = pointsToLines(markers);

      if (f.geometry.type === "Polygon") {
        lines.push([markers[markers.length - 1], markers[0]]);
        featureCornerPosts = markers.length;
        featureTerminalPosts = 0;
      } else if (f.geometry.type === "LineString") {
        featureTerminalPosts = 2;
        featureCornerPosts = markers.length - 2;
      }

      terminalPosts += featureTerminalPosts;
      cornerPosts += featureCornerPosts;

      let idx = 0;
      lines.forEach((line, lineIdx) => {
        let to = idx + 1;
        if (to > markers.length - 1) {
          to = 0;
        }
        let row = fenceMeasurement.segments.get(lineIdx, emptyFenceSegmentRecord);

        const dist = Math.ceil(distance(line[0], line[1], { units: "miles" }) * 5280 * 10) / 10;
        const netDist = dist - row.gateLength;

        featureGateCount += row.gateCount;
        featureGateLength += row.gateLength;

        row = row.merge({
          sectionId: f.properties.id.toString(),
          name: `${f.properties.markers[idx]} to ${f.properties.markers[to]}`,
          length: dist,
          netLength: netDist,
          linePosts4: Math.ceil(netDist / 4) - 1,
          linePosts6: Math.ceil(netDist / 6) - 1,
          linePosts8: Math.ceil(netDist / 8) - 1,
        });

        linePosts4 += row.linePosts4;
        linePosts6 += row.linePosts6;
        linePosts8 += row.linePosts8;
        featureLinePosts4 += row.linePosts4;
        featureLinePosts6 += row.linePosts6;
        featureLinePosts8 += row.linePosts8;

        featureLength += dist;

        fenceMeasurement = fenceMeasurement.updateIn(["segments"], (segments) => segments.set(lineIdx, row));
        idx++;
      });

      fenceMeasurement = fenceMeasurement.updateIn(["segments"], (segments: List<FenceSegmentRecord>) =>
        segments.map((seg, idx) => seg.set("_destroy", idx >= lines.length)),
      );

      fenceMeasurement = fenceMeasurement.merge({
        totalLength: featureLength,
        netLength: featureLength,
        terminalPosts: featureTerminalPosts,
        cornerPosts: featureCornerPosts,
        linePosts4: featureLinePosts4,
        linePosts6: featureLinePosts6,
        linePosts8: featureLinePosts8,
        gateLength: featureGateLength,
        gateCount: featureGateCount,
      });
      totalLength += featureLength;
      gateCount += featureGateCount;
      gateLength += featureGateLength;

      fencing = fencing.updateIn(["fences"], (fences) => fences.set(fIdx, fenceMeasurement));
    });

  fencing = fencing.update("fences", (fences) =>
    fences.map((f, idx) => f.set("_destroy", idx >= geoJSON.features.length)),
  );

  return fencing.merge({
    totalLength,
    terminalPosts,
    cornerPosts,
    linePosts4,
    linePosts6,
    linePosts8,
    gateCount,
    gateLength,
    netLength: totalLength,
    geoJSON: geoJSON,
  });
};

export { centerView, centerViewOnFeatures, load, save } from "./MappingTool";
