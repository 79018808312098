import * as React from "react";
import { connectFC } from "app2/src/connect";
import { Col, Row } from "react-bootstrap";
import { RootState } from "app2/src/reducers";
import { useSelector, useDispatch } from "react-redux";
import { AsyncActions } from "app2/src/reducers/event.actions";
import { queryParamsFromJSON, QueryParamsRecord } from "app2/src/records/Page";
import { getPaginationByModel, pageRecord } from "app2/src/selectors/pagination.selectors";
import DateTime from "app2/src/components/Common/DateTime";
import { StoreRegistry } from "app2/src/storeRegistry";

export interface IAppointmentsProps {
  count: number;
}

const AppointmentsTable: React.FC<IAppointmentsProps> = ({ count = 5 }) => {
  const modelName = "events";
  const loading = useSelector((state: RootState) => pageRecord(state, { modelName, page: 1 })?.get("loading"));
  const appointments = useSelector((state: RootState) =>
    getPaginationByModel(state, { modelName, path: ["events", "byId"] }),
  );
  const dispatch = useDispatch();
  const queryParams: QueryParamsRecord = queryParamsFromJSON({
    per_page: count,
    parameters: {
      start_time_after: new Date(),
      jobs_only: "true",
      sort_by: "start_time",
      includes: ["job_basic"],
    },
  });

  const clickCallback = React.useCallback((event) => {
    const $state: ng.ui.IStateService = StoreRegistry.get("$state");
    const id = event.currentTarget.dataset.id;
    $state.go("job_header.job_show.info", { id });
  }, []);

  React.useEffect(() => {
    dispatch(AsyncActions.loadEvents(queryParams));
  }, []);

  return (
    <>
      {!loading && (
        <div className="sidebar-appointments mb-2 standard-rl-margin">
          <Row className="table-row">
            <Col>Upcoming Appointments</Col>
          </Row>
          {appointments.size === 0 ? (
            <Row className="table-row">
              <Col>
                <p className="mb-0">No Appointments Scheduled</p>
              </Col>
            </Row>
          ) : null}
          {appointments.map((appointment, index) => (
            <Row
              key={index}
              className="table-row link"
              data-id={appointment.job_id}
              role="button"
              onClick={clickCallback}>
              <Col>{appointment.job.customer_name}</Col>
              <Col className="text-center">
                <DateTime date={appointment.start_time} variant="vertical" />
              </Col>
            </Row>
          ))}
        </div>
      )}
    </>
  );
};
export const Appointments = connectFC(AppointmentsTable);
