import {
  MeasurementRecord,
  objects,
  properties,
  propertiesMapping,
  hasMeasurementProperties,
  MeasurementHydratedRecord,
} from "app2/src/records/Measurement";
import { List, getIn } from "immutable";
import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { FencingRecord } from "../records/measurements/Fencing";
import { MeasurementLocation, MeasurementSection, OrgRecord } from "../records/OrgRecord";
import { measurementFencing } from "./measurements/fencing.selectors";
import { currentOrg } from "./org.selectors";
import { roofFaces } from "app2/src/selectors/roofFace.selectors";
import { wallFacades } from "app2/src/selectors/wallFacade.selectors";
import { windows } from "app2/src/selectors/window.selectors";
import { doors } from "app2/src/selectors/door.selectors";
import { rooms } from "app2/src/selectors/room.selectors";
import { decks } from "app2/src/selectors/deck.selectors";
import { pools } from "app2/src/selectors/pool.selectors";
import { elevations } from "app2/src/selectors/elevation.selectors";
import { currentMeasurement, currentMeasurementId } from "app2/src/selectors/measurementCommon.selectors";

export const rootKey = "measurements";
export { currentMeasurement, currentMeasurementId };

/**
 * Loads a measurement by ID
 * @param {RootState} state The RootState
 * @param {{ measurementId: number }} props The id of the measurement object
 * @returns {MeasurementRecord} MeasurementRecord
 */
export const measurement = (state: RootState, props: any) => state.getIn([rootKey, "byId", props.measurementId]);

/**
 * Loads a last saved measurement by ID
 * @param {RootState} state The RootState
 * @param {{ measurementId: number }} props The id of the measurement object
 * @returns {MeasurementRecord} MeasurementRecord
 */
export const lastSavedMeasurement = (state: RootState, props: any) =>
  state.getIn([rootKey, "lastSavedById", props.measurementId]);

/**
 * Loads whether global editing is enabled
 * @param {RootState} state The RootState
 * @returns {boolean}
 */
export const measurementEditing = (state: RootState, props?: any) => state.getIn([rootKey, "editing"]);

/**
 * Loads the measurement form
 * @param {RootState} state The RootState
 * @returns {Map}
 */
export const addMeasurementForm = (state: RootState, props?: any) => state.getIn([rootKey, "addMeasurementForm"]);

export const getMeasurementSystem = (state: RootState, props: any) =>
  state.getIn(["orgs", "orgsById", props.orgId, "preferences", "config", "measurement", "system"]);

/**
 * Rehydrates a measurement with the relationships
 *
 * @param {RootState} state The RootState
 * @param {{ measurementId: number }} props The id of the measurement object
 * @returns {MeasurementHydratedRecord} MeasurementHydratedRecord
 */
export const getMeasurementHydrated = createSelector(
  [measurement, (state, props) => ({ state, props })],
  (measurement: MeasurementRecord, stateProps): MeasurementHydratedRecord => {
    const { state, props } = stateProps;
    const measurementHydrated = new MeasurementHydratedRecord(measurement.toJS());

    return measurementHydrated
      .set("elevations", elevations(state, { ...props, elevationIds: measurement.elevation_ids }))
      .set("roof_faces", roofFaces(state, { ...props, roofFaceIds: measurement.roof_face_ids }))
      .set("wall_facades", wallFacades(state, { ...props, wallFacadeIds: measurement.wall_facade_ids }))
      .set("windows", windows(state, { ...props, windowIds: measurement.window_ids }))
      .set("doors", doors(state, { ...props, doorIds: measurement.door_ids }))
      .set("rooms", rooms(state, { ...props, roomIds: measurement.room_ids }))
      .set("decks", decks(state, { ...props, deckIds: measurement.deck_ids }))
      .set("pools", pools(state, { ...props, poolIds: measurement.pool_ids }));
  },
);

/**
 * Fetches org preference for showing the different sections of measurements
 *
 * @param {RootState} state
 * @param {{location: MeasurementLocation, section: MeasurementSection}} options
 * @returns boolean
 */
export const showMeasurementSection = createSelector(
  [currentOrg, (_, props) => props],
  (org: OrgRecord, options: { location: MeasurementLocation; section: MeasurementSection }) => {
    const { location, section } = options;
    return (getIn(org, ["preferences", "config", "measurement", location], List()) as List<string>).includes(section);
  },
);

/**
 * Looks at the measurement record values & related associations to see if any values are present
 * * If all values are 0 or associations do not exist, false is returned.
 * * If the MeasurementSection is disabled for the org, even if it has values, false is returned.
 *
 * @param {RootState} state
 * @param {location: MeasurementLocation}} options
 * @returns boolean
 */
export const hasMeasurements = createSelector(
  [currentMeasurement, currentOrg, measurementFencing, (_, props) => props.location, (state, _) => state],
  (measurement: MeasurementRecord, org: OrgRecord, fencing: FencingRecord, location: string, state: RootState) => {
    if (!measurement) {
      return false;
    }

    const prop = properties.find((mp, index) => {
      return (
        hasMeasurementProperties(measurement, propertiesMapping.get(index)) &&
        showMeasurementSection(state, { section: mp, location })
      );
    });

    if (prop) {
      return true;
    }

    const object = objects.find((obj) => {
      return (
        measurement.get(`${obj}_ids` as any).size > 0 && showMeasurementSection(state, { section: `${obj}s`, location })
      );
    });

    if (object) {
      return true;
    }

    if (fencing && fencing.linePosts4 > 0) {
      return true;
    }

    return false;
  },
);
