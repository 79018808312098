import * as React from "react";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import * as eagleviewActions from "app2/src/reducers/integrations/eagleview.actions";
import { getAuthorization } from "app2/src/selectors/token.selectors";

const mapStateToProps = (state: RootState, ownProps: EagleviewProps) => {
  return {
    authorized: getAuthorization(state, { orgId: ownProps.orgId, integration: "eagleview" }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: EagleviewProps) => {
  return {
    login: (credentials: any) => dispatch(eagleviewActions.AsyncActions.login(credentials)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface EagleviewProps {
  orgId: number;
  tokenUpdated: () => void;
}

export interface EagleviewState {
  show: boolean;
  username: string;
  password: string;
  authorizing: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & EagleviewProps;

class Eagleview extends React.Component<Props, EagleviewState> {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.login = this.login.bind(this);

    this.state = {
      show: false,
      username: "",
      password: "",
      authorizing: false,
    };
  }

  public handleShow() {
    this.setState({ show: true });
  }

  public handleHide() {
    this.setState({ show: false });
  }

  public async login(event) {
    const { login, tokenUpdated } = this.props;
    this.setState({ authorizing: true });

    event.preventDefault();
    const credentials = {
      username: this.state.username,
      password: this.state.password,
    };
    try {
      await login(credentials);
      this.handleHide();
      tokenUpdated();
    } catch {}
    this.setState({ authorizing: false });
  }

  public render() {
    const { authorized } = this.props;
    const { show, authorizing } = this.state;

    return (
      <React.Fragment>
        <div className="rsf-card">
          <Row>
            <Col className="text-center">
              <img className="img-fluid integration-logo-card" src="/assets/images/eagleview.878fcd2f.png" title="Eagleview" />
            </Col>
          </Row>
          <div className="integration-btn">
            <Button variant="add" onClick={this.handleShow}>
              {authorized ? "Reauthorize" : "Authorize"}
            </Button>
            <FontAwesome name={authorized ? "check" : "times"} />
          </div>
        </div>

        <Modal show={show} onHide={this.handleHide}>
          <Modal.Header>
            <Modal.Title>EagleView&trade; Authorization</Modal.Title>
          </Modal.Header>
          <Form className="form" onSubmit={this.login}>
            <Modal.Body>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Username:</Form.Label>
                        <Form.Control
                          type="text"
                          id="username"
                          required
                          onChange={(e) => this.setState({ username: e.target.value })}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Password:</Form.Label>
                        <Form.Control
                          type="password"
                          id="password"
                          required
                          onChange={(e) => this.setState({ password: e.target.value })}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" disabled={authorizing}>
                Authorize
              </Button>
              <Button variant="cancel" onClick={this.handleHide}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connector(Eagleview);
