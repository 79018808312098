import { baseCleanProps } from "app2/src/api/Api";
import { fetcher } from "../helpers/Fetcher";
import { IValidationData, ValidationRecord } from "../records/Validation";

interface IValidationIndexResponse {
  validations: IValidationData[];
}

interface IValidationResponse {
  validation: IValidationData;
}

class ValidationService {
  public orgUrl = "api/v1/orgs/:id/validations";

  public loadByOrg(orgId: number): Promise<IValidationIndexResponse> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());

    return fetcher.get<IValidationIndexResponse>(actionUrl);
  }

  public create(orgId: number, validation: ValidationRecord): Promise<IValidationResponse> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());
    const validationData = cleanProps(validation);

    return fetcher.post<IValidationResponse>(actionUrl, validationData);
  }

  public update(validation: ValidationRecord): Promise<IValidationResponse> {
    const actionUrl = fetcher.joinUrls(
      this.orgUrl.replace(":id", validation.org_id.toString()),
      validation.id.toString(),
    );
    const validationData = cleanProps(validation);

    return fetcher.patch<IValidationResponse>(actionUrl, validationData);
  }

  public archive(validation: ValidationRecord): Promise<any> {
    const actionUrl = fetcher.joinUrls(
      this.orgUrl.replace(":id", validation.org_id.toString()),
      validation.id.toString(),
    );

    return fetcher.delete(actionUrl);
  }
}

export const validationService = new ValidationService();

/**
 * ToJSON while removing fields that shouldn't go back to the server.
 * @param record - Estimate Record to be transformed
 */
export const cleanProps = (validation: ValidationRecord) => {
  const json = baseCleanProps(validation.toJS() as any as IValidationData);

  return json;
};
