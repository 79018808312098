import * as React from "react";
import { Map } from "immutable";
import { Row, Col } from "react-bootstrap";
import { PaymentRecord } from "app2/src/records/PaymentRecord";

interface CardConnectPaymentViewProps {
  payment: PaymentRecord;
}

export const CardConnectPaymentView: React.SFC<CardConnectPaymentViewProps> = (props) => {
  const { payment } = props;
  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={4}>Customer#: </Col>
          <Col md={8}>{payment.payload.get("account")}</Col>
        </Row>
        <Row>
          <Col md={4}>Customer: </Col>
          <Col md={8}>{payment.getIn(["payload", "receipt", "nameOnCard"]) || "N/A"}</Col>
        </Row>
        <Row>
          <Col md={4}>Status: </Col>
          <Col md={8}>{payment.status}</Col>
        </Row>
        <CardConnectPaymentFailureView failure={payment} />
      </Col>
    </Row>
  );
};

interface CardConnectPaymentFailureViewProps {
  failure: PaymentRecord;
}

export const CardConnectPaymentFailureView: React.SFC<CardConnectPaymentFailureViewProps> = (props) => {
  const { failure } = props;

  if (!failure || !failure.getIn(["payload", "FailureData", "Code"])) {
    return null;
  }
  return (
    <Row className="bg-danger">
      <Col md={12}>
        <Row>
          <Col md={12}>&nbsp;</Col>
        </Row>
        <Row>
          <Col md={4}>Status: </Col>
          <Col md={8}>{failure.get("status")}</Col>
        </Row>
        <Row>
          <Col md={4}>Code#: </Col>
          <Col md={8}>{failure.getIn(["payload", "FailureData", "Code"])}</Col>
        </Row>
        <Row>
          <Col md={4}>Description: </Col>
          <Col md={8}>{failure.getIn(["payload", "FailureData", "Description"])}</Col>
        </Row>
        <Row>
          <Col md={4}>Declined: </Col>
          <Col md={8}>{failure.getIn(["payload", "FailureData", "IsDecline"]).toString()}</Col>
        </Row>
      </Col>
    </Row>
  );
};
