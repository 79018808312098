import { Record, Map, fromJS } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IWebhookData>): WebhookRecord => {
  const record: IWebhookRecord = { ...(json as any) };

  record.auth_config = fromJS(json.auth_config || {});

  if (json.events) {
    const events = {};
    json.events.forEach((e) => {
      const [model, event] = e.split(".");
      if (model && event) events[model] = { [event]: true, ...events[model] };
    });
    record.events = fromJS(events);
  }

  return new WebhookRecord(record);
};

export interface IWebhookData {
  id: string;
  name: string;
  auth_config: any;
  auth_type: string;
  endpoint: string;
  events: Array<string>;
  status: string;
  org_id: string;
  created_at: string;
  updated_at: string;
  loading: boolean;
}

export interface IWebhookRecord {
  id: string;
  name: string;
  auth_config: Nullable<Map<string, any>>;
  auth_type: string;
  endpoint: Nullable<string>;
  events: Map<string, boolean>;
  status: string;
  org_id: string;
  created_at: Nullable<string>;
  updated_at: Nullable<string>;
  loading: boolean;
}

export const defaultWebhookProps = {
  id: "",
  name: "",
  auth_config: null,
  auth_type: "",
  endpoint: null,
  events: null,
  status: "",
  org_id: "",
  created_at: null,
  updated_at: null,
  loading: false,
};

export class WebhookRecord extends Record<IWebhookRecord>(defaultWebhookProps) implements IWebhookRecord {}
