import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IImage extends ng.resource.IResource<IImage>, ImagePrototype {
  $update(...args: any[]): ng.IPromise<IImage>;
}

export interface IImageResource extends ng.resource.IResourceClass<IImage>, IRsfResource {
  fromJSON?(data: any): IImage;
}

export interface IImageResponse {
  images: IImage[];
  meta: any;
}

export interface IImageFile {
  url: string;
}

class ImagePrototype {
  public id: number;
  public uuid: string;
  public name: string;
  public display: string;
  public title: string;
  public description: string;
  public md5_sum: string;
  public type: string;
  public file: IImageFile;
  public sort_order: number;
  public imageable_type: string;
  public imageable_id: number;
  public imageable: any;
  public displayInProposal: boolean;
  public displayInAgreement: boolean;
  public _destroy: boolean;
  public _blob: string;

  public getUrl(size) {
    if (this._blob) {
      return this._blob;
    }

    return this.file[size].url;
  }

  public clone() {
    //noinspection TypeScriptUnresolvedFunction
    return resources.Image.fromJSON(JSON.retrocycle(this.decycleToJSON()));
  }

  public decycleToJSON(): any {
    return angular.fromJson(angular.toJson(JSON.decycle(this)));
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IImageResource => {
  const url = BaseConfig.BASE_URL + "/api/v1/:imageable_type_url/:imageable_id/images/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IImage => {
    if (status < 200 || status > 299) {
      return new Image({});
    }

    return Image.fromJSON(JSON.parse(response).image);
  };

  const multipleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IImageResponse => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);

    _.each(data.images, (i: any, index: number) => {
      data.images[index] = Image.fromJSON(i);
    });

    return data;
  };

  const transformRequest = (image: IImage): string => {
    const data = JSON.decycle(image);
    if (data.imageable_type === "Job") {
      if (data.displayInProposal === true && data.displayInAgreement === true) {
        data.display = "both";
      } else if (data.displayInProposal === false && data.displayInAgreement === true) {
        data.display = "contract";
      } else if (data.displayInProposal === true && data.displayInAgreement === false) {
        data.display = "estimate";
      } else {
        data.display = "do_not_display";
      }
    }
    delete data.displayInProposal;
    delete data.displayInAgreement;
    return angular.toJson({ image: data });
  };

  const Image: IImageResource = <IImageResource>$resource(
    url,
    { imageable_type_url: "@imageable_type_url", imageable_id: "@imageable_id", id: "@id" },
    {
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  Image.fromJSON = (data: any): IImage => {
    if (data.imageable && data.imageable_type) {
      switch (data.imageable_type) {
        case "Org":
          data.org = resources.Org.fromJSON(data.imageable);
          data.imageable = data.org;
          break;
      }
    }

    data.type = "image";
    if (data.imageable_type) {
      data.imageable_type_url = data.imageable_type ? _.toUnderscore(data.imageable_type) + "s" : "";
    }

    switch (data.imageable_type) {
      case "Job":
        data.displayInProposal = data.display === "both" || data.display === "estimate";
        data.displayInAgreement = data.display === "both" || data.display === "contract";
        break;
    }
    if (data.created_at) {
      data.created_at = new Date(data.created_at as string);
    }
    if (data.updated_at) {
      data.updated_at = new Date(data.updated_at as string);
    }
    return new Image(data);
  };

  Image.inject = (injected: IRepository) => {
    resources = injected;
  };

  _.hiddenExtend(Image.prototype, ImagePrototype.prototype);

  return Image;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
