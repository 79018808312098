import * as React from "react";
import { Row, Col, ButtonGroup } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import DateTime from "app2/src/components/Common/DateTime";
import { image as imageRecord } from "app2/src/selectors/image.selectors";
import { ImageRecord, getUrl } from "app2/src/records/Image";
import * as imageActions from "app2/src/reducers/image.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { List } from "immutable";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import track, { TrackingProp } from "react-tracking";
import { Can } from "app2/src/components/Common/CanComponent";
import { coverImage } from "app2/src/selectors/job.selectors";
import { elevationExists } from "app2/src/selectors/elevation.selectors";

const mapStateToProps = (state: RootState, ownProps: ImageProps) => {
  const image = imageRecord(state, { imageId: ownProps.imageId });
  return {
    image,
    coverImageCheck: coverImage(state, { imageUuid: image?.uuid }),
    elevationExists: elevationExists(state, { imageId: ownProps.imageId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: ImageProps) => {
  return {
    destroyImage: (image: ImageRecord) => dispatch(imageActions.AsyncActions.destroyImage(image)),
    editBoolean: (name: string, value: boolean) =>
      dispatch(imageActions.Actions.editBoolean(ownProps.imageId, name, value)),
    updateImage: () => dispatch(imageActions.AsyncActions.batchUpdateImage(List([ownProps.imageId]))),
    editCoverImage: (uuid: string) => dispatch(jobActions.AsyncActions.editCoverImage(uuid)),
    setCurrentImageId: () => dispatch(imageActions.Actions.setCurrentImageId(ownProps.imageId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImageState {}

interface ImageProps {
  imageId: number;
  dragHandle?: any;
  allowDestroy?: boolean;
  tracking?: TrackingProp;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImageProps;
@track((props) => {
  return {
    component: "Image",
    image: props.imageId,
  };
})
class Image extends React.Component<Props, ImageState> {
  constructor(props: Props) {
    super(props);

    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
    this.destroyImage = this.destroyImage.bind(this);
    this.edit = this.edit.bind(this);
    this.setCoverImage = this.setCoverImage.bind(this);
    this.removeCoverImage = this.removeCoverImage.bind(this);
    this.viewImageLink = this.viewImageLink.bind(this);
  }

  @track((_props, _state, [event]) => ({
    action: "checkBoxChanged",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
  }))
  public checkBoxOnChange(event: any): void {
    const { editBoolean, updateImage } = this.props;
    editBoolean(event.target.name, event.target.checked);
    if (event.target.name !== "selected") {
      updateImage();
    }
  }

  @track(() => ({
    action: "open photo editor",
  }))
  public edit(): void {
    this.props.setCurrentImageId();
  }

  @track(() => ({
    action: "photo deleted",
  }))
  public async destroyImage() {
    const { image, destroyImage } = this.props;
    await destroyImage(image);
  }

  @track(() => ({
    action: "view photo link",
  }))
  public viewImageLink(): void {}

  @track(() => ({
    action: "cover image set",
  }))
  public setCoverImage(): void {
    const { image } = this.props;
    this.props.editCoverImage(image.uuid);
  }

  @track(() => ({
    action: "remove cover image",
  }))
  public removeCoverImage(): void {
    this.props.editCoverImage(null);
  }

  public render() {
    const { image, dragHandle, coverImageCheck, elevationExists } = this.props;

    if (_.isNullOrUndefined(image)) {
      return null;
    }

    return (
      <>
        <Row className="table-row" data-testid="Image">
          <Col sm={1} className="pr-0">
            <Can resource="job" permission="update">
              <IncludeCheckBox name="selected" checked={image.selected} onChange={this.checkBoxOnChange} />
              {coverImageCheck ? (
                <a
                  title="Remove as Cover Photo"
                  style={{ marginRight: "0.75em" }}
                  onClick={this.removeCoverImage}
                  href="#">
                  <i className="fa fa-gray fa-star link"></i>
                </a>
              ) : (
                <a title="Mark as Cover Photo" style={{ marginRight: "0.75em" }} onClick={this.setCoverImage} href="#">
                  <i className="fa fa-gray fa-star-o link"></i>
                </a>
              )}
              {dragHandle && <img {...dragHandle} src="/assets/images/icons/ic_sort.ea6f8933.png" />}
            </Can>
          </Col>
          <Col sm={1}>
            <a href={image.file.url} target="_blank" title="Open Image in new tab">
              <img className="img-contain" style={{ maxHeight: "44px" }} src={getUrl(image, "small")} />
            </a>
          </Col>
          <Col className="break-word" title={image.title || image.name}>
            {image.title || image.name}
          </Col>
          <Col sm={2}>
            <DateTime date={image.created_at} variant="vertical" />
          </Col>
          <Col sm={2}>
            <SpinnerComponent localProperty={image.loading} localInline />
            {!image.loading && (
              <Can resource="job" permission="update">
                <IncludeCheckBox
                  name="displayInProposal"
                  label=""
                  checked={image.displayInProposal}
                  onChange={this.checkBoxOnChange}
                />
              </Can>
            )}
          </Col>
          <Col sm={2}>
            <SpinnerComponent localProperty={image.loading} localInline />
            {!image.loading && (
              <Can resource="job" permission="update">
                <IncludeCheckBox
                  name="displayInAgreement"
                  checked={image.displayInAgreement}
                  onChange={this.checkBoxOnChange}
                />
              </Can>
            )}
          </Col>
          <Col sm={1}>
            <Can resource="job" permission="update">
              <ButtonGroup>
                <a
                  className="action-button job-edit-photo"
                  href="#"
                  title="Edit Title and Description"
                  onClick={this.edit}>
                  <i className="rsf-edit-link rsf-base-66"></i>
                </a>
                {elevationExists ? (
                  <a className="action-button" title="Image used for Elevation - Delete not available">
                    <i className="rsf-info-link rsf-base-66"></i>
                  </a>
                ) : (
                  <ConfirmDialog title={`Are you sure you want to delete image: ${image.name}?`}>
                    {(confirm) => (
                      <a
                        className="action-button"
                        data-testid="destroy"
                        title="Delete Image"
                        onClick={confirm(this.destroyImage)}>
                        <i className="rsf-delete-link rsf-base-66"></i>
                      </a>
                    )}
                  </ConfirmDialog>
                )}
              </ButtonGroup>
            </Can>
          </Col>
        </Row>
      </>
    );
  }
}

export default connector(Image);
