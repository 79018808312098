import { getPayload } from "app2/src/services/jwtToken.service";

export const lowesTokenData = (token: string): any => {
  const payload = getPayload(token);
  return `Store: ${payload["SiteLocation"] || ""}, SalesID: ${payload["SalesID"] || ""}, Job Code: ${
    payload["JobCode"] || ""
  }`;
};

export const lowesErrorMessage = (e: any) => {
  switch (e.status) {
    case 404:
      return "We are unable to find your store in our system. Please contact support if this problem persists.";
    case 422:
      return "We are unable to create your user in our system. Please contact support if this problem persists.";
    default:
      return "There was an unknown error logging into the system. Please contact support if this problem persists.";
  }
};
