import { fetcher } from "../../helpers/Fetcher";
import { toJSON, IAccountData, AccountRecord } from "../../records/billing/Account";

export interface IAccountIndexResponse {
  accounts: IAccountData[];
}

export interface IAccountResponse {
  account: IAccountData;
}

export interface AccountPlanSubscriptionParams {
  unsubscribe: boolean;
  include: string[];
}

const url = "billing/v1/accounts";

const includes = ["plan", "fee", "subscriptions", "customers", "billing", "invoice_text"];

export const load = async (accountId: number): Promise<IAccountResponse> => {
  const actionUrl = fetcher.joinUrls(url, accountId.toString());

  return await fetcher.get<IAccountResponse>(actionUrl, { "include[]": includes });
};

export const subscribeToPlan = async (
  accountId: number,
  planId: number,
  params: Partial<AccountPlanSubscriptionParams> = {},
): Promise<IAccountResponse> => {
  const actionUrl = fetcher.joinUrls(url, accountId.toString(), "subscribe", "plan", planId.toString());

  if (!params.include) {
    params.include = includes;
  }

  return await fetcher.post<IAccountResponse>(actionUrl, params);
};

export const loadByOrgId = async (orgId: number): Promise<IAccountResponse> => {
  const actionUrl = fetcher.joinUrls(url, "org", orgId.toString());

  return await fetcher.get<IAccountResponse>(actionUrl, { "include[]": includes });
};

export const create = async (account: AccountRecord): Promise<IAccountResponse> => {
  const actionUrl = url;

  const data = toJSON(account);

  return await fetcher.post<IAccountResponse>(actionUrl, { account: data, "include[]": includes });
};

export const update = async (account: AccountRecord): Promise<IAccountResponse> => {
  const actionUrl = fetcher.joinUrls(url, account.id.toString());

  return await fetcher.patch<IAccountResponse>(actionUrl, { account, "include[]": includes });
};
