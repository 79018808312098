import { Record, List } from "immutable";
import * as moment from "moment";
import { CamelToSnakeKeys, SnakeToCamelKeys } from "app2/src/helpers/Format";
import { checkDate } from "app2/src/records";
import {
  IFenceData,
  FenceRecord,
  emptyFenceRecord,
  fromJSON as fenceFromJSON,
  toFormData as fenceToFormData,
} from "./Fence";
import { IFenceSegmentData, FenceSegmentRecord, emptyFenceSegmentRecord, fromJSON as segmentFromJSON } from "./Segment";

export { FenceRecord, emptyFenceRecord, FenceSegmentRecord, emptyFenceSegmentRecord, fenceFromJSON, segmentFromJSON };
export type { IFenceData, IFenceSegmentData };

export const calculateNetLinePosts = (record: FencingRecord): FencingRecord => {
  let totalLinePost4 = 0,
    totalLinePost6 = 0,
    totalLinePost8 = 0,
    totalGateCount = 0,
    totalNetLength = 0;

  record = record.update("fences", (fences) => {
    return fences.map((fence: FenceRecord) => {
      let fenceLinePost4 = 0,
        fenceLinePost6 = 0,
        fenceLinePost8 = 0,
        fenceGateCount = 0,
        fenceNetLength = 0;

      fence = fence.update("segments", (segments) => {
        return segments.map((segment: FenceSegmentRecord) => {
          const linePosts4 = Math.ceil(segment.netLength / 4) - 1;
          const linePosts6 = Math.ceil(segment.netLength / 6) - 1;
          const linePosts8 = Math.ceil(segment.netLength / 8) - 1;

          fenceLinePost4 += linePosts4;
          fenceLinePost6 += linePosts6;
          fenceLinePost8 += linePosts8;
          fenceGateCount += segment.gateCount;
          fenceNetLength += segment.netLength;

          return segment.merge({ linePosts4, linePosts6, linePosts8 });
        });
      });

      totalLinePost4 += fenceLinePost4;
      totalLinePost6 += fenceLinePost6;
      totalLinePost8 += fenceLinePost8;
      totalGateCount += fenceGateCount;
      totalNetLength += fenceNetLength;

      return fence.merge({
        linePosts4: fenceLinePost4,
        linePosts6: fenceLinePost6,
        linePosts8: fenceLinePost8,
        gateCount: fenceGateCount,
        netLength: fenceNetLength,
      });
    });
  });

  return record.merge({
    linePosts4: totalLinePost4,
    linePosts6: totalLinePost6,
    linePosts8: totalLinePost8,
    gateCount: totalGateCount,
    netLength: totalNetLength,
  });
};

export const mergeGateNumbers = (previous: FencingRecord, next: FencingRecord): FencingRecord => {
  let totalLinePost4 = 0,
    totalLinePost6 = 0,
    totalLinePost8 = 0,
    totalGateCount = 0,
    totalNetLength = 0;

  next = next.update("fences", (fences) => {
    return fences.map((fence: FenceRecord, fIdx) => {
      let fenceLinePost4 = 0,
        fenceLinePost6 = 0,
        fenceLinePost8 = 0,
        fenceGateCount = 0,
        fenceNetLength = 0;

      fence = fence.update("segments", (segments) => {
        return segments.map((segment: FenceSegmentRecord, sIdx) => {
          const prevGateCount = previous.getIn(["fences", fIdx, "segments", sIdx, "gateCount"]);
          const prevGateLength = previous.getIn(["fences", fIdx, "segments", sIdx, "gateLength"]);
          if (prevGateCount > 0) {
            segment = segment.set("gateCount", prevGateCount);
          }
          if (prevGateLength > 0) {
            segment = segment.merge({
              gateLength: prevGateLength,
              netLength: segment.length - prevGateLength,
            });
          }

          const linePosts4 = Math.ceil(segment.netLength / 4) - 1;
          const linePosts6 = Math.ceil(segment.netLength / 6) - 1;
          const linePosts8 = Math.ceil(segment.netLength / 8) - 1;

          fenceLinePost4 += linePosts4;
          fenceLinePost6 += linePosts6;
          fenceLinePost8 += linePosts8;
          fenceGateCount += segment.gateCount;
          fenceNetLength += segment.netLength;

          return segment.merge({ linePosts4, linePosts6, linePosts8 });
        });
      });

      totalLinePost4 += fenceLinePost4;
      totalLinePost6 += fenceLinePost6;
      totalLinePost8 += fenceLinePost8;
      totalGateCount += fenceGateCount;
      totalNetLength += fenceNetLength;

      return fence.merge({
        linePosts4: fenceLinePost4,
        linePosts6: fenceLinePost6,
        linePosts8: fenceLinePost8,
        gateCount: fenceGateCount,
        netLength: fenceNetLength,
      });
    });
  });

  return next.merge({
    linePosts4: totalLinePost4,
    linePosts6: totalLinePost6,
    linePosts8: totalLinePost8,
    gateCount: totalGateCount,
    netLength: totalNetLength,
  });
};

export const fromJSON = (data: Partial<IFencingData>): FencingRecord => {
  const record: Partial<IFencingRecord> = SnakeToCamelKeys(data);

  if (data.fences) {
    record.fences = List(data.fences.map((s) => fenceFromJSON(s)));
  }

  if (data.geojson) {
    record.geoJSON = data.geojson;
  }

  if (data.created_at) {
    record.createdAt = checkDate(data.created_at);
  }

  if (data.updated_at) {
    record.updatedAt = checkDate(data.updated_at);
  }

  return new FencingRecord(record);
};

export const toFormData = (record: FencingRecord): IFencingData => {
  const data: IFencingData = CamelToSnakeKeys(record.toJSON());

  // CamelCaseToSnakeKeys does NOT like geoJSON
  data.geojson = record.geoJSON;
  delete (data as any).geo_j_s_o_n;

  delete (data as any).fence_ids;

  if (record.fences.size > 0) {
    data.fences = record.fences.map((r) => fenceToFormData(r)).toArray();
  } else {
    data.fences = [];
  }

  data.updated_at = moment(record.updatedAt).format("YYYY-MM-DDTHH:mm:ss.SSS");
  data.created_at = moment(record.createdAt).format("YYYY-MM-DDTHH:mm:ss.SSS");

  return data;
};

export const dummyData = (): FencingRecord => {
  const segments = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

  const fence = { id: 1, segments: segments };

  return fromJSON({
    id: 1,
    fences: [fence],
    line_posts_4: 52,
    line_posts_6: 34,
    line_posts_8: 26,
    gate_count: 1,
    gate_length: 6,
    terminal_posts: 4,
    corner_posts: 4,
    net_length: 220,
  });
};

export interface IFencingData {
  id: number;
  measurement_id: number;
  fences: Array<Partial<IFenceData>>;
  total_length: number;
  net_length: number;
  corner_posts: number;
  terminal_posts: number;
  gate_count: number;
  gate_length: number;
  line_posts_4: number;
  line_posts_6: number;
  line_posts_8: number;
  geojson: any;
  created_at: string;
  updated_at: string;
}

export interface IFencingRecord {
  id: number;
  measurementId: number;
  fences: List<FenceRecord>;
  fenceIds: List<number>;
  totalLength: number;
  netLength: number;
  cornerPosts: number;
  terminalPosts: number;
  gateCount: number;
  gateLength: number;
  linePosts4: number;
  linePosts6: number;
  linePosts8: number;
  geoJSON: any;
  createdAt: Date;
  updatedAt: Date;
}

export const defaultFencingProps = {
  id: 0,
  measurementId: 0,
  fences: List(),
  fenceIds: List(),
  totalLength: 0,
  netLength: 0,
  cornerPosts: 0,
  terminalPosts: 0,
  gateCount: 0,
  gateLength: 0,
  linePosts4: 0,
  linePosts6: 0,
  linePosts8: 0,
  geoJSON: null,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export class FencingRecord extends Record<IFencingRecord>(defaultFencingProps) implements IFencingRecord {}

export const emptyFencingRecord = new FencingRecord();
