import { fetcher } from "app2/src/helpers/Fetcher";

export interface IAuthUrlResponse {
  url: string;
}

export type IIntegrationAuthKind = "salesforce" | "improveit360" | "cronofy";
export type IAuthorizationUrlKind = "hover" | "companycam";

export const url = "auth/:kind_url";

export const getAuthUrl = async (kind: IIntegrationAuthKind, orgId: number): Promise<IAuthUrlResponse> => {
  const actionUrl = url.replace(":kind_url", `${kind}_url`);

  return fetcher.get<IAuthUrlResponse>(actionUrl, { org_id: orgId });
};

export const getAuthorizationUrl = async (kind: IAuthorizationUrlKind): Promise<string> => {
  const result = await fetcher.get<{ authorize_url: string }>(`/integrations/${kind}/authorize`);
  return result.authorize_url;
};
