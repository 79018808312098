import * as React from "react";
import { useCallback, useEffect } from "react";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import { Button } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { useTracking } from "react-tracking";

export default function FlipCameraButton() {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useVideoContext();
  const [supportsFacingMode, setSupportsFacingMode] = React.useState<boolean | null>(null);
  const { trackEvent } = useTracking();
  const videoTrack = localTracks.find((track) => track.name.includes("camera"));
  let facingMode = null;
  if (videoTrack) {
    facingMode = videoTrack.mediaStreamTrack.getSettings().facingMode;
  }

  useEffect(() => {
    // The "supportsFacingMode" variable determines if this component is rendered
    // If "facingMode" exists, we will set supportsFacingMode to true.
    // However, if facingMode is ever undefined again (when the user unpublishes video), we
    // won"t set "supportsFacingMode" to false. This prevents the icon from briefly
    // disappearing when the user switches their front/rear camera.
    if (facingMode && supportsFacingMode === null) {
      setSupportsFacingMode(Boolean(facingMode));
    }
  }, [facingMode, supportsFacingMode]);

  const toggleFacingMode = useCallback(() => {
    const newFacingMode = facingMode === "user" ? "environment" : "user";

    videoTrack!.stop();

    getLocalVideoTrack({ facingMode: newFacingMode }).then((newVideoTrack) => {
      if (localParticipant) {
        const localTrackPublication = localParticipant.unpublishTrack(videoTrack!);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant.emit("trackUnpublished", localTrackPublication);

        localParticipant.publishTrack(newVideoTrack, { priority: "low" });
      }
      trackEvent({ action: "flip camera", cameraDirection: newFacingMode });
    });
  }, [facingMode, getLocalVideoTrack, localParticipant, videoTrack]);

  return supportsFacingMode ? (
    <Button
      // @ts-ignore
      onClick={toggleFacingMode}
      className="control-button"
      disabled={!videoTrack}
      title="Flip Camera">
      <FontAwesome name="refresh" size="lg" />
    </Button>
  ) : null;
}
