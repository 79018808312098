import { CurrencyFormat } from "app2/src/helpers/Format";
import { CalculatedFinancingRecord } from "app2/src/records/CalculatedFinancing";
import { EstimateRecord } from "app2/src/records/Estimate";
import { RootState } from "app2/src/reducers";
import { estimateFinanceOptions } from "app2/src/selectors/estimateFinanceOption.selectors";
import { List } from "immutable";
import { createSelector } from "reselect";
import { model as estimateModel } from "app2/src/reducers/estimate.reducer";

export const calculatedFinancing = (state: RootState, props: any): CalculatedFinancingRecord =>
  state.getIn([estimateModel, "calculatedFinancingByFinancedAmount", props.financedAmount, props.financeOptionId]);

export const calculatedFinancings = createSelector(
  [(state: RootState, props: any) => ({ state, props })],
  (stateProps: any): List<CalculatedFinancingRecord> => {
    const { state, props } = stateProps;
    return props.financeOptionIds.map((financeOptionId: number) =>
      calculatedFinancing(state, { financedAmount: props.financedAmount, financeOptionId }),
    );
  },
);

export const range = createSelector([calculatedFinancings], (calculatedFinancings): string => {
  let min = 0,
    max = 0;

  calculatedFinancings.forEach((cf, idx) => {
    const rate = cf.monthly_payment;

    if (idx === 0) {
      min = rate;
    }
    min = _.min([rate, min]);
    max = _.max([rate, max]);
  });

  if (min === max) {
    return `${CurrencyFormat(min, 0)}`;
  } else {
    return `${CurrencyFormat(min, 0)} - ${CurrencyFormat(max, 0)}`;
  }
});

// unable to import from estimate.selectors due to circular dependency
export const estimatesById = (state: RootState): Map<number, EstimateRecord> => state.getIn([estimateModel, "byId"]);
export const estimates = createSelector([estimatesById, (state, props) => ({ state, props })], (byId, stateProps) => {
  const { props } = stateProps;

  return props.estimateIds.map((id: number) => byId.get(id));
});

export const calculatedFinancingsEstimatesLoading = createSelector(
  [estimates, (state, props) => ({ state, props })],
  (estimates, stateProps): boolean => {
    const { state } = stateProps;
    if (!estimates) {
      estimates = List();
    }
    return estimates.some((estimate) => {
      if (!estimate) {
        return true;
      }
      const financeOptionIds = estimateFinanceOptions(state, {
        estimateFinanceOptionIds: estimate.finance_option_ids,
      }).map((efo) => efo.finance_option_id);
      return calculatedFinancingsLoading(state, {
        financeOptionIds,
        financedAmount: estimate.getIn(["payment_terms", "financed_amount"]),
      });
    });
  },
);

export const calculatedFinancingsLoading = createSelector([calculatedFinancings], (calculatedFinancings): boolean => {
  return calculatedFinancings.some((cf) => {
    return !cf || cf.loading;
  });
});
