import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connectFC } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { IUser } from "app/src/Models/User";
import * as tokenActions from "app2/src/reducers/token.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import Hover from "app2/src/components/Integrations/Hover/Hover";
import Houzz from "app2/src/components/Integrations/Houzz/Houzz";
import AngiesList from "app2/src/components/Integrations/AngiesList/AngiesList";
import Renoworks from "app2/src/components/Integrations/Renoworks/Renoworks";
import HomeAdvisor from "app2/src/components/Integrations/HomeAdvisor/HomeAdvisor";
import Chameleon from "app2/src/components/Integrations/Chameleon/Chameleon";
import WellsFargo from "app2/src/components/Integrations/WellsFargo/WellsFargo";
import OCCSettings from "app2/src/components/Integrations/OCCSettings/OCCSettings";
import SalesRabbit from "app2/src/components/Integrations/SalesRabbit/SalesRabbit";
import LeadPerfection from "app2/src/components/Integrations/LeadPerfection/LeadPerfection";
import MarketSharp from "app2/src/components/Integrations/MarketSharp/MarketSharp";
import ProVia from "app2/src/components/Integrations/ProVia/ProVia";
import CardConnect from "app2/src/components/Integrations/CardConnect/CardConnect";
import PaySimple from "app2/src/components/Integrations/PaySimple/PaySimple";
import Eagleview from "app2/src/components/Integrations/Eagleview/Eagleview";
import AccuLynx from "app2/src/components/Integrations/AccuLynx";
import NetSuite from "app2/src/components/Integrations/NetSuite";
import { currentOrg, orgChanged } from "app2/src/selectors/org.selectors";
import * as orgActions from "app2/src/reducers/org.actions";
import * as paymentActions from "app2/src/reducers/payment.actions";
import GreenSky from "app2/src/components/Integrations/components/GreenSky";
import Salesforce from "app2/src/components/Integrations/Salesforce";
import { Dispatch } from "app2/src/helpers/Analytics";
import { Can } from "app2/src/components/Common/CanComponent";
import HomevestFinance from "app2/src/components/Integrations/HomevestFinance";
import IntegrationList from "app2/src/components/Common/IntegrationList";
import { Route, Switch, Redirect } from "react-router";
import { ConnectedRouter } from "connected-react-router/immutable";
import { history } from "app2/src/store";
import AuthorizationModal from "app2/src/components/Integrations/Common/AuthorizationModal";
import { useSelector, useDispatch } from "react-redux";
import { StoreRegistry } from "app2/src/storeRegistry";
import { useTracking } from "react-tracking";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { getAuthorizationUrl } from "app2/src/api/integrationAuth.service";
import { FlashLevels } from "app/src/Common/FlashLevels";
import SettingsModal from "app2/src/components/Integrations/Common/SettingsModal";
import Improveit360 from "app2/src/components/Integrations/ImproveIt360";

export interface IntegrationsProps {
  users: IUser[];
}

const Integrations: React.FC<IntegrationsProps> = ({ users }) => {
  // Selectors
  const org = useSelector(currentOrg);
  const orgId = org?.id;
  const authorizedLoading = useSelector((state: RootState) =>
    state.getIn(["tokens", "authByOrgId", orgId, "loading"], true),
  );
  const dirty = useSelector(orgChanged);

  // Hooks
  const dispatch = useDispatch();
  const $state: ng.ui.IStateService = StoreRegistry.get("$state");
  const { Track, trackEvent } = useTracking<any>(
    {
      component: "Integrations",
      category: "integrations",
      org: orgId,
    },
    { dispatch: Dispatch.dispatch },
  );

  // Methods
  const tokenUpdated = () => {
    dispatch(tokenActions.AsyncActions.getAuthorizedTokens(orgId));
    dispatch(paymentActions.AsyncActions.getAuthorized(orgId));
  };

  const saveOrg = async () => {
    trackEvent({ action: "save org" });
    await dispatch(orgActions.AsyncActions.updateOrg(org));
    tokenUpdated();
  };

  const updateSettingsConfig = (path: any, value: any) => {
    dispatch(orgActions.Actions.updateSettingsConfig(orgId, path, value));
  };

  const updatePrefConfig = (path: any, value: any) => {
    dispatch(orgActions.Actions.updatePrefConfig(orgId, path, value));
  };

  const reset = () => {
    dispatch(orgActions.Actions.resetOrg());
  };

  const authorizeCompanyCam = async () => {
    try {
      const url = await getAuthorizationUrl("companycam");
      window.open(url, "_self");
    } catch (e) {
      dispatch(
        commonActions.Actions.flashAddAlert(
          FlashLevels.danger,
          "There were problems with the authorization. If the problem continues, contact support.",
        ),
      );
    }
  };

  // Lifecycle
  React.useEffect(() => {
    tokenUpdated();
  }, []);

  return (
    <Track>
      <ConnectedRouter history={history}>
        <SpinnerComponent localProperty={authorizedLoading || org?.loading} />
        <DirtyWatcher check={() => dirty} reset={reset} reactRouter />
        <Can resource="org" permission="integrations">
          <Row>
            <Col sm={8}>
              <h1 className="admin-title">Integrations</h1>
            </Col>
            <Col sm={4}>
              <Can resource="org" permission="update">
                <Button
                  className="admin-save pull-right rsf-float-button"
                  disabled={!dirty || org?.loading}
                  onClick={saveOrg}>
                  Save
                </Button>
              </Can>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Eagleview orgId={orgId} tokenUpdated={tokenUpdated} />
            </Col>
            <Col sm={4}>
              <PaySimple orgId={orgId} tokenUpdated={tokenUpdated} />
            </Col>
            <Col sm={4}>
              <CardConnect orgId={orgId} tokenUpdated={tokenUpdated} />
            </Col>
          </Row>
          <HomevestFinance orgId={orgId} users={users} tokenUpdated={tokenUpdated} />
          <IntegrationList
            integration={"companycam"}
            integrationLogo={"/assets/images/companycam.faa71fbe.png"}
            tokenUpdated={tokenUpdated}
            occUsers={users}
            authorize={authorizeCompanyCam}
            settingsButton={true}
          />
          <IntegrationList
            integration={"job_nimbus"}
            integrationLogo={"/assets/images/job_nimbus.1846312b.png"}
            tokenUpdated={tokenUpdated}
            occUsers={users}
          />
          <IntegrationList
            integration={"jobprogress"}
            integrationLogo={"/assets/images/jobprogress.83ea7f82.png"}
            tokenUpdated={tokenUpdated}
            occUsers={users}
          />
          <Hover orgId={orgId} users={users} tokenUpdated={tokenUpdated} />
          <IntegrationList
            integration={"roof_scope"}
            integrationLogo={"/assets/images/roof_scope.c04a66a4.png"}
            tokenUpdated={tokenUpdated}
          />
          <Can resource="org" permission="update">
            <IntegrationList
              integration={"ingage"}
              integrationLogo={"/assets/images/ingage.a09e9f8b.svg"}
              tokenUpdated={tokenUpdated}
              settingsButton={true}
              authless={true}
            />
            <IntegrationList
              integration={"fene_tech"}
              integrationLogo={"/assets/images/fene_tech.c090fcbf.png"}
              tokenUpdated={tokenUpdated}
              authless={true}
            />
            <HomeAdvisor orgId={orgId} updateSettingsConfig={updateSettingsConfig} />
            <AngiesList orgId={orgId} updatePrefConfig={updatePrefConfig} />
            <Houzz orgId={orgId} updatePrefConfig={updatePrefConfig} />
            <Renoworks orgId={orgId} />
            <Chameleon orgId={orgId} updateSettingsConfig={updateSettingsConfig} />
            <ProVia orgId={orgId} />
            <IntegrationList
              integration={"quick_measure"}
              integrationLogo={"/assets/images/image-tools/quick-measure-logo.e12d25c8.png"}
              tokenUpdated={tokenUpdated}
              settingsButton={true}
              authless={true}
            />
            <WellsFargo orgId={orgId} updateSettingsConfig={updateSettingsConfig} />
            <OCCSettings orgId={orgId} updateSettingsConfig={updateSettingsConfig} />
            <SalesRabbit orgId={orgId} users={users} />
            <LeadPerfection orgId={orgId} users={users} tokenUpdated={tokenUpdated} />
            <MarketSharp orgId={orgId} users={users} />
            <GreenSky orgId={orgId} />
          </Can>
          <AccuLynx orgId={orgId} users={users} tokenUpdated={tokenUpdated} />
          <Salesforce integration="salesforce" orgId={orgId} users={users} tokenUpdated={tokenUpdated} />
          <Improveit360
            integration={"improveit360"}
            integrationLogo={"/assets/images/improveit360.9bed0d97.png"}
            tokenUpdated={tokenUpdated}
            settingsButton={true}
            occUsers={users}
          />
          <NetSuite orgId={orgId} tokenUpdated={tokenUpdated} />
          <Switch>
            <Route path={`/orgs/${orgId}/integrations/authorization/:integration`} component={AuthorizationModal} />
            <Route
              path={`/orgs/${orgId}/integrations/settings`}
              render={(props) => <SettingsModal {...props} modalProps={{ size: "lg" }} />}
            />
            <Route render={() => <Redirect to={`/orgs/${orgId}/integrations`} />} />
          </Switch>
        </Can>
        <Can resource="org" permission="integrations" inverse>
          <h2>Incorrect Permissions</h2>
        </Can>
      </ConnectedRouter>
    </Track>
  );
};

export default connectFC(Integrations);
