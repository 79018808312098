import { IOrg } from "app/src/Models/Org";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IAccess {
  id: number;
  user: IUser;
  user_id: number;
  org: IOrg;
  org_id: number;
  billable: string;
  acl: any;
  template: string;
}

export interface IUser extends ng.resource.IResource<IUser>, UserPrototype {
  $saveOrCreate(...args: any[]): ng.IPromise<IUser>;
}

export interface IUserResource extends ng.resource.IResourceClass<IUser>, IRsfResource {
  inject(injected: IRepository): void;
  check?(params: any): any;
  fromJSON?(data: any): IUser;
  generatePassword(): string;
  placeholderEmail(org_name: string, index: number): string;
}

export interface IUserResponse extends ng.resource.IResourceArray<IUser> {
  users: IUser[];
  meta: any;
}

let resources: IRepository;

class UserPrototype {
  public id: number;
  public uuid: string;
  public org_id: number;
  public preferences_id: number;
  public accesses: IAccess[];
  public first_name: string;
  public last_name: string;
  public email: string;
  public phone: string;
  public time_zone: string;
  public password?: string;
  public template?: string;
  public acl?: any;
  public billable?: string;
  public permissions: any;
  public org?: IOrg;
  public acl_edited?: boolean;

  public name(): string {
    let name = "";
    //noinspection TypeScriptUnresolvedVariable
    if (this.first_name) {
      //noinspection TypeScriptUnresolvedVariable
      name = this.first_name + " ";
    }

    // noinspection TypeScriptUnresolvedVariable
    if (this.last_name) {
      //noinspection TypeScriptUnresolvedVariable
      name += this.last_name;
    }

    return name.trim();
  }

  public $saveOrCreate(params, callback): ng.IPromise<IUser> {
    if (!this.id || this.id <= 0 || (this.id as any as string) === "Unsaved") {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$save(params, callback);
    }
  }

  public accessAtOrg(org: IOrg): IAccess {
    const access: IAccess = _.find(this.accesses, (a: IAccess) => {
      return org.id === a.org_id;
    });

    return access ? access : <IAccess>{};
  }
}

const factory = ($http: ng.IHttpService, $resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/users/:id";

  const transformSingle = (response: string, headers: ng.IHttpHeadersGetter, status: number): IUser => {
    if (status < 200 || status >= 300) {
      return JSON.parse(response);
    }

    return User.fromJSON(JSON.parse(response).user);
  };

  const responseMultiTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);
    _.each(data.users, (user, index) => {
      data.users[index] = User.fromJSON(user);
    });

    return data;
  };

  const transformRequest = (user: IUser): string => {
    const data = JSON.parse(angular.toJson(user));

    data.accesses_attributes = data.accesses.map((access) => {
      if (access.id <= 0) {
        delete access.id;
      }
      return access;
    });
    delete data.accesses;

    if (data.acl_edited || data.template === "custom") {
      data.acl = data.permissions;
    } else {
      delete data.acl;
    }

    return angular.toJson({ user: data });
  };

  const User: IUserResource = <IUserResource>$resource(
    url,
    { id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle /**/,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: transformSingle,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: transformSingle,
      },
      query: <ng.resource.IActionDescriptor>{
        transformResponse: responseMultiTransform,
        isArray: false,
      },
      check: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: url + "/check",
      },
    },
  );

  User.fromJSON = (data: any) => {
    const user: IUser = new User(data);

    if (user.org) {
      user.org = resources.Org.fromJSON(user.org);
    }

    return user;
  };

  User.inject = (injected: IRepository) => {
    resources = injected;
  };

  User.generatePassword = (): string => {
    const length = 8;
    const charset = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charsetLength = charset.length;
    let password = "";

    for (let i = 0; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * charsetLength));
    }

    return password;
  };

  User.placeholderEmail = (org_name, index): string => {
    let emailPlaceholder = "placeholder+";
    emailPlaceholder += org_name.replace(/\W/g, "").toLowerCase();
    emailPlaceholder += Math.floor(Math.random() * 100);
    emailPlaceholder += index + "@oneclickcontractor.com";
    return emailPlaceholder;
  };

  _.hiddenExtend(User.prototype, UserPrototype.prototype);

  return User;
};

factory.$inject = ["$http", "$resource", "BaseConfig"];

export default factory;
