import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { NoteRecord } from "app2/src/records/Note";
import { Form as FinalForm } from "react-final-form";
import { RsfFormGroup } from "app2/src/components/RsfForms/RsfFormGroup";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import * as noteActions from "app2/src/reducers/note.actions";
import { note, noteChanged } from "app2/src/selectors/note.selectors";
import DateTime from "app2/src/components/Common/DateTime";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { preferencesConfig } from "app2/src/selectors/org.selectors";
import { currentJobOrgId } from "app2/src/selectors/job.selectors";
import { Can } from "app2/src/components/Common/CanComponent";

const mapStateToProps = (state: RootState, ownProps: NoteProps) => {
  const orgId = currentJobOrgId(state);
  const noteEditing = preferencesConfig(state, { orgId: orgId, path: ["notes", "enable_editing"], notSet: true });

  return {
    note: note(state, { noteId: ownProps.noteId }),
    noteChanged: noteChanged(state, { noteId: ownProps.noteId }),
    noteEditingPermission: noteEditing,
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: NoteProps) => {
  return {
    updateOrCreateNote: (note: NoteRecord) => dispatch(noteActions.AsyncActions.updateOrCreateNote(note)),
    destroyNote: (note: NoteRecord) => dispatch(noteActions.AsyncActions.destroyNote(note)),
    editNote: (note: string) => dispatch(noteActions.Actions.editNote(ownProps.noteId, note)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface NoteProps {
  noteId: number;
}

export interface NoteState {
  edit: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & NoteProps;

class Note extends React.Component<Props, NoteState> {
  public componentRef: any;
  public confirm: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      edit: false,
    };

    this.componentRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.destroyNote = this.destroyNote.bind(this);
  }

  public onSubmit() {
    const { note, updateOrCreateNote, noteChanged } = this.props;
    if (noteChanged) {
      updateOrCreateNote(note);
    }
    this.setState({ edit: false });
  }

  public addEventListener(): void {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  public removeEventListener(): void {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  public handleClickOutside(event: any): void {
    if (this.componentRef && !this.componentRef.current.contains(event.target)) {
      const event = new Event("build");

      this.confirm(() => {
        this.onSubmit();
      })(event);
    }
  }

  public destroyNote() {
    const { note, destroyNote } = this.props;

    destroyNote(note);
  }

  public validateNote(data) {
    const badValues = [null, undefined, ""];
    return badValues.includes(data?.trim());
  }

  public render() {
    const { note, noteId, editNote, noteEditingPermission } = this.props;
    const { edit } = this.state;

    if (_.isNullOrUndefined(note)) {
      return null;
    }

    return (
      <div title="Table Note" className="table-note" ref={this.componentRef}>
        <ConfirmDialog title="There are unsaved note changes that will be lost. Would you like to save changes?">
          {(confirm) => {
            this.confirm = confirm;
            return null;
          }}
        </ConfirmDialog>
        <FinalForm onSubmit={this.onSubmit} initialValues={note.toJS()}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col title="Note Data" md={7}>
                  {edit ? (
                    <RsfFormGroup
                      as="textarea"
                      label=""
                      name="note"
                      id={`note-textarea-${noteId}`}
                      onChange={(e) => editNote(e.target.value)}
                      validate={this.validateNote}
                    />
                  ) : (
                    <p title="Note" className="description">
                      {note.note}
                    </p>
                  )}
                </Col>
                <Col md={2}>
                  <DateTime date={note.updated_at} variant="vertical" />
                </Col>
                <Col md={2}>{note.user}</Col>
                <Col md={1} className="btn nowrap">
                  <>
                    {noteEditingPermission && (
                      <Can resource="job" permission="update" exactMatch={false}>
                        {edit ? (
                          <i
                            className="rsf-save-link rsf-base-66 note-icon job-save-note"
                            title="Save Note"
                            onClick={handleSubmit}></i>
                        ) : (
                          <i
                            className="rsf-edit-link rsf-base-66 note-icon job-edit-note"
                            title="Edit Note"
                            onClick={() => this.setState({ edit: true })}></i>
                        )}
                      </Can>
                    )}
                    {noteEditingPermission && (
                      <Can resource="job" permission="delete" exactMatch={false}>
                        <ConfirmDialog title="Are you sure you want to delete this note?">
                          {(confirm) => (
                            <i
                              className="rsf-delete-link rsf-base-66 note-icon job-delete-note"
                              title="Delete Note"
                              onClick={confirm(this.destroyNote)}></i>
                          )}
                        </ConfirmDialog>
                      </Can>
                    )}
                  </>
                </Col>
              </Row>
            </form>
          )}
        </FinalForm>
      </div>
    );
  }
}

export default connector(Note);
