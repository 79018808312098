import * as React from "react";
import Spinner from "app2/src/components/SpinnerComponent";
import * as config from "react-global-configuration";
import { StoreRegistry } from "app2/src/storeRegistry";

interface LeadsRedirectComponentProps {}

export const LeadsRedirectComponent: React.FunctionComponent<LeadsRedirectComponentProps> = () => {
  React.useEffect(() => {
    StoreRegistry.get<Window>("window").location = config.get("LEADS_URL");
  }, []);

  return <Spinner />;
};
