import * as angulartics from "angulartics";
import { IBaseConfig } from "../Common/IBaseConfig";
import { emailRegex } from "app2/src/helpers/InputValidator";

export class ResetPasswordCtrl {
  public email: string;
  public emailPattern = emailRegex;

  public static $inject = ["$http", "$state", "$analytics", "BaseConfig"];
  constructor(
    public $http: ng.IHttpService,
    public $state: ng.ui.IStateService,
    public $analytics: angulartics.IAnalyticsService,
    public BaseConfig: IBaseConfig,
  ) {}

  public reset() {
    if (!this.email) {
      return;
    }

    this.$http.post(this.BaseConfig.BASE_URL + "/api/v1/users/send_password_reset", { email: this.email }).then(
      () => {
        this.$analytics.eventTrack("reset_password", {
          category: "Login",
          email: this.email,
        });
        this.$state.go("root.login");
      },
      () => {
        this.$analytics.eventTrack("reset_password failed", {
          category: "Login",
          email: this.email,
        });
      },
    );
  }
}
