import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FormControl, RsfForm } from "@tberrysoln/rsf-form";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import * as orgActions from "app2/src/reducers/org.actions";
import { Can } from "app2/src/components/Common/CanComponent";
import { orgChanged } from "app2/src/selectors/org.selectors";
import CrmUserMatchTable from "app2/src/components/Integrations/Common/CrmUserMatchTable";
import { IUser } from "app/src/Models/User";
import { getPaginationByModel, queryParams, pagination } from "app2/src/selectors/pagination.selectors";
import { QueryParamsRecord } from "app2/src/records/Page";
import { CrmUserRecord } from "app2/src/records/integrations/CrmUser";
import * as crmUserActions from "app2/src/reducers/crmUser.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { getAuthorization, token, tokenChanged } from "app2/src/selectors/token.selectors";
import { ICrmUserQuery } from "app2/src/components/Common/IntegrationList";
import { TokenRecord } from "app2/src/records/Token";
import * as tokenActions from "app2/src/reducers/token.actions";

const mapStateToProps = (state: RootState, ownProps: MarketSharpProps) => {
  const modelName = "MsUser";
  const integrationName = "marketsharp";
  const users = getPaginationByModel(state, {
    path: ["crmUsers", "byId"],
    modelName,
  });
  const msQueryParams = queryParams(state, { modelName });

  return {
    marketSharpToken: token(state, { kind: integrationName }) as TokenRecord,
    msPagination: pagination(state, { modelName, page: msQueryParams.get("page") }),
    msQueryParams: msQueryParams,
    msUsers: users,
    authorized: getAuthorization(state, { orgId: ownProps.orgId, integration: integrationName }),
    dirty: orgChanged(state),
    dirtyForToken: tokenChanged(state, { kind: integrationName }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: MarketSharpProps) => {
  return {
    updateUser: (msUser: CrmUserRecord) => dispatch(crmUserActions.AsyncActions.updateUser(msUser)),
    queryUsers: (orgId: number, msQueryParams: QueryParamsRecord) =>
      dispatch(crmUserActions.AsyncActions.queryUsers({ orgId, userType: "MsUser" }, msQueryParams)),
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
    sync: () => dispatch(orgActions.AsyncActions.syncIntegration(ownProps.orgId, "InitialSyncJob", "market_sharp")),
    getMarketSharpToken: () => dispatch(tokenActions.AsyncActions.getToken(ownProps.orgId, "marketsharp")),
    updateCreateMSToken: (newToken: TokenRecord) => dispatch(tokenActions.AsyncActions.updateCreateToken(newToken)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface MarketSharpProps {
  orgId: number;
  users: IUser[];
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & MarketSharpProps;

class MarketSharp extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.queryUsers = this.queryUsers.bind(this);
  }

  componentDidMount() {
    this.props.getMarketSharpToken();
    this.queryUsers({ page: 1, query: "" });
  }

  public queryUsers(queryObj: ICrmUserQuery) {
    const { queryUsers, msQueryParams, orgId } = this.props;
    const newQueryParams = msQueryParams.merge(queryObj);
    queryUsers(orgId, newQueryParams);
  }

  public render() {
    const integrationName = "marketsharp";

    const {
      orgId,
      dirtyForToken,
      marketSharpToken,
      updateCreateMSToken,
      users,
      msUsers,
      msPagination,
      updateUser,
      authorized,
      dirty,
      sync,
    } = this.props;
    const integrationPath = ["tokens", "byOrgId", orgId, integrationName, "data"];

    return (
      <Can resource="org" permission={integrationName}>
        <div className="form-section">
          <div className="form-section-content">
            <RsfForm
              rootPath={integrationPath}
              updateFormReducer={tokenActions.Actions.update}
              onSubmit={() => updateCreateMSToken(marketSharpToken)}>
              <Row>
                <Col sm={4} className="d-flex">
                  <img
                    className="img-fluid self-align-center integration-logo"
                    src="/assets/images/marketsharp.811a6581.png"
                    title={integrationName}
                  />
                </Col>
                <Col sm={8}>
                  <Row>
                    <Col sm={6}>
                      <FormControl label="Company Id" name="company_id" />
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        label="Default Duration"
                        name="default_duration"
                        formControlProps={{ type: "number" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="submit" variant="default" className="pull-right" disabled={!dirtyForToken}>
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </RsfForm>
            {!authorized && !dirty && <Button onClick={sync}>Initial Sync</Button>}
            {authorized && (
              <CrmUserMatchTable
                integrationType={integrationName}
                occUsers={users}
                integrationUsers={msUsers}
                pagination={msPagination}
                queryUsers={this.queryUsers}
                saveUser={updateUser}
                sync={sync}
              />
            )}
          </div>
        </div>
      </Can>
    );
  }
}

export default connector(MarketSharp);
