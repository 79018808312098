import { IPagingMetadata } from "../../Models/PagingMetadata";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../../Common/IBaseConfig";
export interface IPlan extends ng.resource.IResource<IPlan>, PlanPrototype {
  $subscribe?(data: any): ng.IPromise<IPlan>;
}

export interface IPlanResource extends ng.resource.IResourceClass<IPlan>, IRsfResource {
  fromJSON?(data: any): IPlan;
  subscribe?(data: any): IPlan;
}

export interface IPlanResponse extends ng.resource.IResourceArray<IPlan> {
  plans: IPlan[];
  meta: IPagingMetadata;
}

class PlanPrototype {
  public id: number;
  public stripe_id: string;
  public parent_id: number;
  public parent?: IPlan;
  public children_ids: number[];
  public max_users: number;
  public name: string;
  public amount: number;
  public currency: string;
  public interval: string;
  public interval_count: number;
  public trial_period_days: number;
  public min_interval: string;
  public min_interval_count: number;
  public metadata: any;
  public statement_descriptor: string;
  public in_stripe: boolean;

  public $saveOrCreate(params?, callback?): ng.IPromise<IPlan> {
    if (!this.id || this.id <= 0 || (this.id as any as string) === "Unsaved") {
      return (this as any).$save(params, callback);
    } else {
      return (this as any).$update(params, callback);
    }
  }

  public getName() {
    return (
      this.name +
      " [Trial: " +
      this.trial_period_days +
      " days | $" +
      (this.amount / 100).toFixed(2) +
      "/" +
      this.interval +
      "]"
    );
  }

  public buildStructure(plans: IPlan[]): IPlan[] {
    const planStructure = [];
    const start = _.find(plans, (plan: IPlan) => {
      return this.id === plan.id;
    });
    let current = start;

    let count = 0;
    let current_ids = current.children_ids;
    while (!_.isEmpty(current_ids) && count <= plans.length) {
      count++;
      let next_ids = [];
      _.each(current_ids, (id) => {
        current = _.find(plans, (plan: IPlan) => {
          return id === plan.id;
        });
        planStructure.unshift(current);
        next_ids = next_ids.concat(current.children_ids);
      });

      current_ids = next_ids;
    }

    planStructure.push(start);

    count = 0;
    current = start;
    while (current && !_.isNull(current.parent_id) && !_.isUndefined(current.parent_id) && count <= plans.length) {
      count++;
      current = _.find(plans, (plan: IPlan) => {
        return current.parent_id === plan.id;
      });
      planStructure.push(current);
    }

    return planStructure;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IPlanResource => {
  const url = BaseConfig.BASE_URL + "/billing/v1/plans/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IPlan => {
    if (status < 200 || status > 299) {
      return new Plan({});
    }

    return Plan.fromJSON(JSON.parse(response).plan);
  };

  const multipleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IPlanResponse => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);
    _.each(data.plans, (plan, index) => {
      data.plans[index] = Plan.fromJSON(plan);
    });

    return data;
  };

  const transformRequest = (plan: IPlan): string => {
    if (plan.parent && plan.parent.id) {
      plan.parent_id = plan.parent.id;
      delete plan.parent;
    }
    const data = JSON.decycle(plan);
    return angular.toJson({ plan: data });
  };

  const Plan: IPlanResource = <IPlanResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      subscribe: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: url + "/subscribe",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
    },
  );

  _.hiddenExtend(Plan.prototype, PlanPrototype.prototype);

  Plan.fromJSON = (data: any): IPlan => {
    return new Plan(data);
  };

  Plan.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Plan;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
