import useVideoContext from "../useVideoContext/useVideoContext";
import useDominantSpeaker from "../useDominantSpeaker/useDominantSpeaker";
import useParticipants from "../useParticipants/useParticipants";
import useScreenShareParticipant from "../useScreenShareParticipant/useScreenShareParticipant";
import useSelectedParticipant from "app2/src/components/VideoProvider/useSelectedParticipant/useSelectedParticipant";

export default function useMainSpeaker() {
  const [selectedParticipant] = useSelectedParticipant();
  let screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants(false);
  const {
    room: { localParticipant },
  } = useVideoContext();

  if (screenShareParticipant === localParticipant) {
    screenShareParticipant = null;
  }

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return selectedParticipant || screenShareParticipant || dominantSpeaker || participants[0] || localParticipant;
}
