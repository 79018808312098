import * as presentationActions from "./presentation.actions";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { reducer as paginationReducer } from "app2/src/reducers/pagination.reducer";
import { PresentationRecord, fromJSON } from "app2/src/records/Presentation";
import { Map, Record, List } from "immutable";
import { SlideRecord } from "../records/Slide";
import { fromJSON as fileFromJSON } from "app2/src/records/File";
import { RootActions, RootState } from "app2/src/reducers";
import { Nullable } from "app2/src/records";

export interface IIndexPresentationsRecord {
  presentations: List<number>;
  errors: List<string>;
  loading: boolean;
}

export const IndexPresentationsRecord: Record.Factory<IIndexPresentationsRecord> = Record<IIndexPresentationsRecord>({
  presentations: List<number>(),
  errors: List<string>(),
  loading: false,
});

export const PresentationStateRecord = Record({
  byId: Map<number, PresentationRecord>(),
  lastSavedById: Map<number, PresentationRecord>(),
  presentationsByOrgId: Map<number, List<typeof IndexPresentationsRecord>>(),
  presentationsByJobId: Map<number, List<typeof IndexPresentationsRecord>>(),
  // Presentation ID of the presentation being presented
  currentPresentationId: null as Nullable<number>,
  slidesById: Map<number, SlideRecord>(),
});

export const initialState = PresentationStateRecord();
export type PresentationState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "presentations";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case presentationActions.SET_CURRENT_PRESENTATION_ID:
      return state.setIn([model, "currentPresentationId"], action.payload.id);
    case presentationActions.RECEIVE_PRESENTATION:
      const presentation = action.payload.presentation;
      let basePath = "presentationsByOrgId";
      let baseProp = "org_id";

      // is this a Job presentation? if not then it's still an Org Presentation
      if (presentation.job_id > 0) {
        basePath = "presentationsByJobId";
        baseProp = "job_id";
      }

      if (!state.getIn([model, basePath, presentation[baseProp]])) {
        state = state.setIn(
          [model, basePath, presentation[baseProp]],
          IndexPresentationsRecord({ presentations: List([presentation.id]) }),
        );
      } else {
        state = state.updateIn([model, basePath, presentation[baseProp], model], (p) => {
          return p.push(presentation.id);
        });
      }

      presentation.slides.forEach((s) => (state = reducer(state, presentationActions.Actions.receiveSlide(s))));

      return state
        .setIn([model, "byId", presentation.id], presentation)
        .setIn([model, "lastSavedById", presentation.id], presentation);
    case presentationActions.RECEIVE_PRESENTATIONS:
      action.payload.presentations.forEach((d) => {
        state = reducer(state, presentationActions.Actions.receivePresentation(fromJSON(d)));
      });

      return state;
    case presentationActions.BATCH_REMOVE_IDS:
      action.payload.presentationIds.forEach((id) => {
        state = paginationReducer(state, paginationActions.Actions.removeId(id, "presentation"));
      });

      return state;
    case presentationActions.SET_PRESENTATIONS_LOADED:
      action.payload.presentationIds.forEach((id) => {
        state = state.setIn([model, "byId", id, "loading"], false);
      });

      return state;
    case presentationActions.RECEIVE_SLIDE:
      return state.setIn([model, "slidesById", action.payload.slide.id], action.payload.slide);
    case presentationActions.RECEIVE_PRESENTATION_ERROR:
      return state.updateIn([model, "byId", action.payload.presentationId], (p: PresentationRecord) => {
        return p.merge({ loading: false, errors: List(action.payload.errors) });
      });
    case presentationActions.FETCH_PRESENTATION:
      if (state.getIn([model, "byId", action.payload.presentationId])) {
        return state.setIn([model, "byId", action.payload.presentationId, "loading"], true);
      }
      return state.setIn(
        [model, "byId", action.payload.presentationId],
        fromJSON({ id: action.payload.presentationId, loading: true }),
      );
    case presentationActions.FETCH_PRESENTATIONS:
      action.payload.presentationIds.forEach((id) => {
        state = reducer(state, presentationActions.Actions.fetchPresentation(id));
      });
      return state;
    case presentationActions.REMOVE_PRESENTATION:
      return state.updateIn([model, "byId"], (byId) => {
        return byId.delete(action.payload.presentationId);
      });
    case presentationActions.FETCH_ORG_PRESENTATIONS:
      if (!state.getIn([model, "presentationsByOrgId", action.payload])) {
        return state.setIn(
          [model, "presentationsByOrgId", action.payload.orgId],
          new IndexPresentationsRecord({ loading: true }),
        );
      }

      return state.setIn([model, "presentationsByOrgId", action.payload, "loading"], true);
    case presentationActions.RECEIVE_ORG_PRESENTATIONS:
      state = state.setIn([model, "presentationsByOrgId", action.payload.orgId, model], List([]));
      action.payload.presentations.forEach((d) => {
        state = reducer(state, presentationActions.Actions.receivePresentation(d));
      });

      return state.setIn([model, "presentationsByOrgId", action.payload.orgId, "loading"], false);
    case presentationActions.RECEIVE_ORG_ERRORS:
      return state
        .setIn([model, "presentationsByOrgId", action.payload.orgId, "errors"], List<string>(action.payload.errors))
        .setIn([model, "presentationsByOrgId", action.payload.orgId, "loading"], false);
    case presentationActions.FETCH_JOB_PRESENTATIONS:
      if (!state.getIn([model, "presentationsByJobId", action.payload.jobId])) {
        return state.setIn(
          [model, "presentationsByJobId", action.payload],
          IndexPresentationsRecord({ loading: true }),
        );
      }

      return state.setIn([model, "presentationsByJobId", action.payload, "loading"], true);
    case presentationActions.RECEIVE_JOB_PRESENTATIONS:
      state = state.setIn([model, "presentationsByJobId", action.payload.jobId, model], List([]));
      action.payload.presentations.forEach((d) => {
        state = reducer(state, presentationActions.Actions.receivePresentation(d));
      });

      return state.setIn([model, "presentationsByJobId", action.payload.jobId, "loading"], false);
    case presentationActions.REMOVE_JOB_PRESENTATION:
      return state.updateIn([model, "presentationsByJobId", action.payload.jobId, model], (list: List<number>) => {
        return list.filter((n) => n !== action.payload.presentationId);
      });
    case presentationActions.RECEIVE_JOB_ERRORS:
      return state
        .setIn([model, "presentationsByJobId", action.payload.jobId, "errors"], List<string>(action.payload.errors))
        .setIn([model, "presentationsByJobId", action.payload.jobId, "loading"], false);
    case presentationActions.EDIT_COVER_IMAGE:
      return state.setIn(
        [model, "byId", action.payload.presentationId, "cover_image"],
        fileFromJSON(action.payload.coverImage),
      );
    case presentationActions.EDIT_SORT_ORDER:
      return state.setIn([model, "byId", action.payload.presentationId, "sort_order"], action.payload.sortOrder);
    case presentationActions.EDIT_NAME:
      return state.setIn([model, "byId", action.payload.presentationId, "name"], action.payload.name);
    case presentationActions.EDIT_LINK:
      return state.setIn([model, "byId", action.payload.presentationId, "link"], action.payload.link);
    case presentationActions.EDIT_PRESENTATION:
      return state.setIn([model, "byId", action.payload.presentationId, action.payload.name], action.payload.value);
    case presentationActions.RESET_PRESENTATION:
      return state.setIn(
        [model, "byId", action.payload.presentationId],
        state.getIn([model, "lastSavedById", action.payload.presentationId]),
      );
    case presentationActions.BATCH_EDIT_FOLDER_ID:
      action.payload.presentationIds.forEach((id) => {
        state = state.setIn([model, "byId", id, "folder_id"], action.payload.folderId);
      });
      return state;
    case presentationActions.EDIT_SELECTED:
      return state.setIn([model, "byId", action.payload.presentationId, "selected"], action.payload.value);
    case presentationActions.BATCH_EDIT_SELECTED:
      action.payload.presentationIds.forEach((id) => {
        state = reducer(state, presentationActions.Actions.editSelected(id, action.payload.value));
      });
      return state;
    default:
      return state;
  }
};
