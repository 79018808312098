import { IToolInfo } from "../ToolInfoService";
import { IJob } from "../../Models/Job";
import { IBaseConfig } from "../IBaseConfig";

class ToolDisplayCtrl {
  public toolConfig: any;
  public job: IJob;
  public navDisplay: boolean;
  public key: string;
  public static $inject;

  constructor(
    public ToolInfo: IToolInfo,
    public BaseConfig: IBaseConfig,
  ) {}

  public $onChanges() {
    if (this.job && this.key) {
      this.toolConfig = this.ToolInfo.tools(this.key);
    }
  }
}

export class ToolDisplayComponent {
  public controller: any;
  public bindings: any = {
    key: "<",
    job: "<",
    navDisplay: "<",
  };

  public templateUrl = "src/Common/Components/tool_display.html";

  constructor() {
    this.controller = ToolDisplayCtrl;
    this.controller.$inject = ["ToolInfo", "BaseConfig"];
  }
}
