import * as React from "react";
import { Row, Col, Dropdown, InputGroup, Button, FormControl, ButtonGroup } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import DateTime from "app2/src/components/Common/DateTime";
import { document } from "app2/src/selectors/document.selectors";
import { DocumentRecord } from "app2/src/records/Document";
import * as documentActions from "app2/src/reducers/document.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { List } from "immutable";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import track from "react-tracking";
import * as FontAwesome from "react-fontawesome";
import { EllipsisActionDropdown } from "app2/src/components/Common/EllipsisActionDropdown";
import { Can } from "app2/src/components/Common/CanComponent";
import { Acl } from "app2/src/helpers/Acl";
import { CommonTitles } from "app2/src/helpers/Format";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { documentChanged } from "app2/src/selectors/document.selectors";
import { DownloadDocumentButton } from "app2/src/components/Document/DownloadDocumentButton";

const mapStateToProps = (state: RootState, ownProps: DocumentProps) => {
  return {
    document: document(state, { documentId: ownProps.documentId }),
    documentChanged: documentChanged(state, { documentId: ownProps.documentId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: DocumentProps) => {
  return {
    destroyDocument: (document: DocumentRecord) => dispatch(documentActions.AsyncActions.destroyDocument(document)),
    editBoolean: (name: string, value: boolean) =>
      dispatch(documentActions.Actions.editBoolean(ownProps.documentId, name, value)),
    updateDocument: () => dispatch(documentActions.AsyncActions.batchUpdateDocument(List([ownProps.documentId]))),
    editName: (documentId: number, name: string) => dispatch(documentActions.Actions.editName(documentId, name)),
    addToDocumentOrder: (docType, document: DocumentRecord) =>
      dispatch(orgActions.Actions.addToDocumentOrder(docType, document)),
    resetDocument: () => dispatch(documentActions.Actions.resetDocument(ownProps.documentId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DocumentState {
  editing: boolean;
}

interface DocumentProps {
  documentId: number;
  dragHandle?: any;
  allowDestroy?: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DocumentProps;
@track((props) => {
  return {
    component: "Document",
    document: props.documentId,
  };
})
class Document extends React.Component<Props, DocumentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
    this.destroyDocument = this.destroyDocument.bind(this);
    this.edit = this.edit.bind(this);
    this.check = this.check.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.save = this.save.bind(this);
    this.addToDocumentOrder = this.addToDocumentOrder.bind(this);
    this.viewDocumentLink = this.viewDocumentLink.bind(this);
    this.ellipsisToggle = this.ellipsisToggle.bind(this);
  }

  @track((_props, _state, [event]) => ({
    action: "checkBoxChanged",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
  }))
  public checkBoxOnChange(event: any): void {
    const { editBoolean, updateDocument } = this.props;
    if (event.target.name !== "selected") {
      editBoolean("displayInResource", false);
      editBoolean("displayInProposalWatermark", false);
      editBoolean("displayInAgreementWatermark", false);
    }
    editBoolean(event.target.name, event.target.checked);
    if (event.target.name !== "selected") {
      updateDocument();
    }
  }

  @track(() => ({
    action: "edit org document name",
  }))
  public edit(): void {
    this.setState({ editing: true });
  }

  public handleNameChange(event): void {
    const { document, editName } = this.props;
    editName(document.id, event.target.value);
  }

  @track((props) => ({
    action: "save org document name",
    doc: props.document.name,
  }))
  public save(): void {
    const { updateDocument } = this.props;
    this.setState({ editing: false });
    updateDocument();
  }

  public handleKeyPress(target): void {
    if (target.charCode === 13) {
      this.save();
    }
  }

  @track(() => ({
    action: "document deleted",
  }))
  public async destroyDocument() {
    const { document, destroyDocument } = this.props;
    await destroyDocument(document);
  }

  @track((_props, _state, [event]) => ({
    action: "add to document order",
    documentType: event.target.name,
  }))
  public addToDocumentOrder(event: any) {
    const { addToDocumentOrder, document } = this.props;
    addToDocumentOrder(event.target.name, document);
  }

  @track(() => ({
    action: "view document link",
  }))
  public viewDocumentLink(): void {}

  @track((_props, _state, [isOpen]) => ({
    action: "toggle ellipsis dropdown",
    open: isOpen,
  }))
  public ellipsisToggle(_isOpen: boolean): void {}

  public check(): boolean {
    const { documentChanged } = this.props;

    return documentChanged;
  }

  public render() {
    const { document, dragHandle, resetDocument } = this.props;
    const { editing } = this.state;

    if (_.isNullOrUndefined(document)) {
      return null;
    }

    const permission = Acl.can("org", "global");

    return (
      <>
        <SpinnerComponent localProperty={document.loading} />
        <Row className="table-row" data-testid="Document">
          <Col sm={1} title={document.name}>
            <Can resource="org" permission="update">
              <IncludeCheckBox name="selected" checked={document.selected} onChange={this.checkBoxOnChange} />
              {dragHandle && <img {...dragHandle} src="/assets/images/icons/ic_sort.ea6f8933.png" />}
            </Can>
          </Col>
          <Col sm={4} className="break-word">
            {editing ? (
              <InputGroup>
                <DirtyWatcher check={this.check} reset={resetDocument} />
                <FormControl
                  autoFocus
                  data-testid="name-editing"
                  placeholder="Document Name"
                  name="name"
                  value={document.name}
                  onChange={this.handleNameChange}
                  onKeyPress={this.handleKeyPress}
                />
                <InputGroup.Append>
                  <Button onClick={this.save} data-testid="save-button">
                    <FontAwesome name="save" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            ) : (
              <>
                <Can resource="org" permission="update">
                  <a className="action-button" href="#" title="Edit Name" onClick={this.edit}>
                    <i className="rsf-edit-link rsf-base-66"></i>
                  </a>
                </Can>
                {document.name}
              </>
            )}
          </Col>
          <Col sm={2}>
            <DateTime date={document.created_at} variant="vertical" />
          </Col>
          <Col sm={permission ? 1 : 3}>
            <Can resource="org" permission="update">
              <IncludeCheckBox
                name="displayInResource"
                checked={document.displayInResource}
                onChange={this.checkBoxOnChange}
              />
            </Can>
          </Col>
          <Can resource="global" permission="org">
            <Col sm={1}>
              <IncludeCheckBox
                name="displayInProposalWatermark"
                checked={document.displayInProposalWatermark}
                onChange={this.checkBoxOnChange}
              />
            </Col>
            <Col sm={1}>
              <IncludeCheckBox
                name="displayInAgreementWatermark"
                checked={document.displayInAgreementWatermark}
                onChange={this.checkBoxOnChange}
              />
            </Col>
          </Can>
          <Col sm={2}>
            <ButtonGroup>
              <Can resource="org" permission="update">
                <EllipsisActionDropdown onToggle={this.ellipsisToggle}>
                  <Dropdown.Item href="#" name="estimate" onClick={this.addToDocumentOrder}>
                    Add to {CommonTitles("estimate")}
                  </Dropdown.Item>
                  <Dropdown.Item href="#" name="contract" onClick={this.addToDocumentOrder}>
                    Add to {CommonTitles("contract")}
                  </Dropdown.Item>
                  <Dropdown.Item href="#" name="inspection" onClick={this.addToDocumentOrder}>
                    Add to {CommonTitles("inspection")}
                  </Dropdown.Item>
                </EllipsisActionDropdown>
              </Can>
              <DownloadDocumentButton documentId={document.id}>
                <i
                  data-testid="document-link"
                  className="action-button rsf-pdf-link rsf-base-66"
                  title="Download File"></i>
              </DownloadDocumentButton>
              <Can resource="org" permission="update">
                <ConfirmDialog title={`Are you sure you want to delete document: ${document.name}?`}>
                  {(confirm) => (
                    <a
                      className="action-button"
                      data-testid="destroy"
                      title="Delete Document"
                      onClick={confirm(this.destroyDocument)}>
                      <i className="rsf-delete-link rsf-base-66"></i>
                    </a>
                  )}
                </ConfirmDialog>
              </Can>
            </ButtonGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default connector(Document);
