import * as activatedPriceListActions from "./activatedPriceList.actions";
import * as priceListActions from "./priceList.actions";
import { reducer as priceListReducer } from "./priceList.reducer";
import { Map, Record } from "immutable";
import { RootState, RootActions } from ".";
import { fromJSON, ActivatedPriceListRecord } from "app2/src/records/ActivatedPriceList";
import { fetch, loaded, receive } from "app2/src/reducers/Reducer";

export const ActivatedPriceListStateRecord = Record({
  byId: Map<number, ActivatedPriceListRecord>(),
});

export const initialState = ActivatedPriceListStateRecord();

export type ActivatedPriceListState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "activatedPriceLists";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let activatedPriceListRecord: ActivatedPriceListRecord;
  switch (action.type) {
    case activatedPriceListActions.FETCH_ACTIVATED_PRICE_LIST:
      return fetch(state, model, fromJSON({ id: action.payload.activatedPriceListId }));

    case activatedPriceListActions.RECEIVE_ACTIVATED_PRICE_LIST:
      activatedPriceListRecord = fromJSON({ ...action.payload.activatedPriceList });

      activatedPriceListRecord = priceListReducer(
        activatedPriceListRecord,
        priceListActions.Actions.receivePriceList(action.payload.cachedPriceList),
      ) as ActivatedPriceListRecord;

      return receive(state, model, activatedPriceListRecord);

    case activatedPriceListActions.SET_LOADED:
      return loaded(state, model, action.payload.activatedPriceListId);

    default:
      return state;
  }
};
