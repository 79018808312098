import * as React from "react";
import { createContext, useContext } from "react";

interface IFlashAlertContext {
  flashAlert: (level: string, message: string) => void;
  notifier: (message: string) => void;
}

const FlashAlertContext = createContext<IFlashAlertContext>(undefined);

export const useFlashAlert = () => {
  const { flashAlert } = useContext(FlashAlertContext);
  if (!flashAlert) {
    throw new Error("useFlashAlert must be used within a FlashAlertProvider");
  }
  return flashAlert;
};

export const useNotifier = () => {
  const { notifier } = useContext(FlashAlertContext);
  if (!notifier) {
    throw new Error("useNotifier must be used within a FlashAlertProvider");
  }
  return notifier;
};

export const FlashAlertProvider: React.FC<IFlashAlertContext> = ({ children, flashAlert, notifier }) => (
  <FlashAlertContext.Provider value={{ flashAlert, notifier }}>{children}</FlashAlertContext.Provider>
);
