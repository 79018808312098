import { Record } from "immutable";

export const fromJSON = (data: Partial<IEstimateCommissionUserData>): EstimateCommissionUserRecord => {
  const record: IEstimateCommissionUserRecord = { ...(data as any) };
  return new EstimateCommissionUserRecord(record);
};

export interface IEstimateCommissionUserData {
  commission_rate: number;
  overhead: number;
}

export interface IEstimateCommissionUserRecord {
  commission_rate: number;
  overhead: number;
}

const defaultIEstimateCommissionUserProps: IEstimateCommissionUserRecord = {
  commission_rate: 0,
  overhead: 0,
};

export class EstimateCommissionUserRecord
  extends Record(defaultIEstimateCommissionUserProps)
  implements IEstimateCommissionUserRecord {}
