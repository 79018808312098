import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { Col, Button, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { List } from "immutable";
import { NotificationForm } from "./NotificationForm";
import Spinner from "app2/src/components/SpinnerComponent";
import { orgService } from "app2/src/api/org.service";
import { currentOrg } from "app2/src/selectors/org.selectors";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { IDirtyWatcher } from "app/src/Common/DirtyWatcher";

const mapStateToProps = (state: RootState, ownProps: OrgNotificationsProps) => {
  return {
    org: currentOrg(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: OrgNotificationsProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OrgNotificationsProps {
  $scope: ng.IScope;
  DirtyWatcher: IDirtyWatcher;
}

interface OrgNotificationsState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OrgNotificationsProps;

class OrgNotifications extends React.Component<Props, OrgNotificationsState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { org, $scope, DirtyWatcher } = this.props;
    return (
      <Spinner localProperty={org.loading}>
        <NotificationForm org={org} $scope={$scope} DirtyWatcher={DirtyWatcher} />
      </Spinner>
    );
  }
}

export default connector(OrgNotifications);
