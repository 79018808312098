import { fetcher } from "../helpers/Fetcher";
import { IToolData, ToolRecord, toFormData } from "../records/Tool";

interface IToolIndexResponse {
  tools: IToolData[];
}

interface IToolResponse {
  tool: IToolData;
}

class ToolService {
  public orgUrl = "api/v1/orgs/:id/tools";
  public url = "api/v1/tools/:id";

  public loadByOrg(orgId: number, jobId: number): Promise<IToolData[]> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());
    let params = {};
    if (jobId) {
      params = { job_id: jobId };
    }

    return fetcher.get<IToolIndexResponse>(actionUrl, params).then((response) => response.tools);
  }

  public create(orgId: number, tool: ToolRecord): Promise<IToolData> {
    const actionUrl = this.orgUrl.replace(":id", orgId.toString());
    const toolForm = toFormData(tool);

    return fetcher.post<IToolResponse>(actionUrl, toolForm, { formData: true }).then(
      (response) => response.tool,
      (response) => Promise.reject(response.data.errors),
    );
  }

  public update(tool: ToolRecord): Promise<IToolData> {
    const actionUrl = this.url.replace(":id", tool.id.toString());
    const toolForm = toFormData(tool);

    return fetcher.patch<IToolResponse>(actionUrl, toolForm, { formData: true }).then(
      (response) => response.tool,
      (response) => {
        if (response.status === 500) {
          return Promise.reject(["Internal Server Error"]);
        }

        if (_.isArray(response.data.errors)) {
          return Promise.reject(response.data.errors);
        }

        return Promise.reject(["Unknown Error"]);
      },
    );
  }

  public archive(tool: ToolRecord): Promise<IToolData> {
    const actionUrl = this.url.replace(":id", tool.id.toString());

    return fetcher.delete<any>(actionUrl).then(
      () => {
        return tool.set("archived", true);
      },
      (response) => Promise.reject(response.data.errors),
    );
  }
}

export const toolService = new ToolService();
