import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IJob } from "app/src/Models/Job";
import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { ISession } from "app/src/Common/SessionService";
import { IOrgResource, IOrg } from "app/src/Models/Org";
import { IJobMenuOrderService, ISideBarMenu } from "../JobMenu/JobMenuOrderService";
import { IBaseConfig } from "../Common/IBaseConfig";
import * as ng from "angular";
import { JobInfoTourService } from "../Common/Tours/JobInfoTourService";
import { JobShowTourService } from "../Common/Tours/JobShowTourService";
import { IEventingFactory } from "../Common/EventingFactory";
import { RootStoreType } from "app2/src/store";
import { dispatch } from "app2/src/storeRegistry";
import * as discountActions from "app2/src/reducers/discount.actions";
import * as paymentTermTemplateActions from "app2/src/reducers/org/paymentTermTemplate.actions";
import * as financeOptionActions from "app2/src/reducers/org/financeOption.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import * as validationActions from "app2/src/reducers/validation.actions";
import { IOrgData } from "app2/src/records/OrgRecord";
import * as jobService from "app2/src/api/job.service";
import { orgService } from "app2/src/api/org.service";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { push } from "connected-react-router/immutable";

export interface INavDisplayArgs {
  navDisplay: boolean;
}

export class JobShowCtrl {
  public job: IJob;
  public org: IOrg;
  public action: string;
  public sidebar: ISideBarMenu;
  public headerHidden = false;
  public navDisplay = true;
  private store: RootStoreType;

  public static $inject = [
    "JobFetcher",
    "JobMenuOrderService",
    "Session",
    "$state",
    "$stateParams",
    "$location",
    "$scope",
    "$rootScope",
    "Org",
    "BaseConfig",
    "JobShowTourService",
    "JobInfoTourService",
    "$timeout",
    "EventingFactory",
  ];
  constructor(
    public JobFetcher: IJobFetcherService,
    private JobMenuOrderService: IJobMenuOrderService,
    private Session: ISession,
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public $location: ng.ILocationService,
    public $scope: ng.IScope,
    public $rootScope: RsfRootScope,
    public Org: IOrgResource,
    public BaseConfig: IBaseConfig,
    public JobShowTourService: JobShowTourService,
    public JobInfoTourService: JobInfoTourService,
    public $timeout: ng.ITimeoutService,
    public EventingFactory: IEventingFactory,
  ) {
    this.headerHidden = $state.params["header"] === "false";

    if (this.$stateParams["id"] && this.$stateParams["id"] !== "new") {
      this._loadJob();
    } else {
      this.tourInit();
      Session.currentUser.$promise.then(() => {
        this.org = JobFetcher.getOrg(Session.currentUser.org_id);
        this.org.$promise.then(() => {
          this.sidebar = this.JobMenuOrderService.buildTabs(this.org, this.job);
        });
      });
    }

    $scope.$on("$destroy", () => {
      dispatch(jobActions.Actions.setSelectedEstimateId(null));
      dispatch(jobActions.Actions.setCurrentJobId(null));
      orgService.setCurrentOrgId(null);
      dispatch(commonActions.Actions.setNavDisplay(true));
    });
  }

  public tourInit(): ng.IPromise<any> {
    return this.JobShowTourService.init({
      jobShowCtrl: this,
    });
  }

  public backToAllCustomers() {
    dispatch(push("/jobs"));
  }

  public analyticsInit() {
    this.EventingFactory.init({
      category: "Job",
      job: this.job.id,
      org: this.job.org_id,
      navDisplay: !this.navDisplay,
    });
  }

  public isCurrentPage(page: string): boolean {
    return new RegExp(page).test(this.$location.path());
  }

  public toggleNav() {
    this.navDisplay = !this.navDisplay;
    dispatch(commonActions.Actions.setNavDisplay(this.navDisplay));
    this.JobFetcher.setPresentationState(this.navDisplay);
    this.$rootScope.$broadcast("nav:display", <INavDisplayArgs>{ navDisplay: this.navDisplay });
  }

  private _loadJob() {
    this.job = this.JobFetcher.get(this.$stateParams["id"]);

    this.job.$promise.then(() => {
      this.org = this.job.org;
      if (this.job.viewed === false) {
        jobService.view(this.job.org_id, this.job.id).then(() => {});
      }
      // Initialize Redux for each job
      dispatch(orgActions.Actions.setOrg(this.org as any as IOrgData));
      dispatch(discountActions.AsyncActions.listDiscounts(this.job.org_id));
      dispatch(paymentTermTemplateActions.AsyncActions.listPaymentTermTemplates(this.job.org_id));
      dispatch(financeOptionActions.AsyncActions.listFinanceOptions(this.job.org_id));
      dispatch(validationActions.AsyncActions.listValidations(this.job.org_id));

      this.sidebar = this.JobMenuOrderService.buildTabs(this.org, this.job);
      this.analyticsInit();
      this.tourInit().then(() => {
        this.JobShowTourService.startTour();
      });
    });
  }
}
