import * as React from "react";
import Chooser from "app2/src/components/Discount/Chooser";
import { ChooserParams } from "app2/src/components/Discount/Chooser";
import { EstimateRecord } from "app2/src/records/Estimate";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { ThunkDispatch } from "redux-thunk";
import { EstimateDiscountRecord, IEstimateDiscountData } from "app2/src/records/EstimateDiscount";
import track from "react-tracking";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: IDiscountChooserWrapperProps,
) => {
  const { estimate } = ownProps;
  return {
    removeDiscount: (discount) => dispatch(estimateActions.AsyncActions.removeDiscount(estimate.id, discount)),
    addDiscount: (discount) => dispatch(estimateActions.AsyncActions.addDiscount(estimate.id, discount)),
    updateDiscount: (discount) => dispatch(estimateActions.AsyncActions.updateDiscount(estimate.id, discount)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IDiscountChooserWrapperProps {
  orgId: number;
  estimate: EstimateRecord;
  params: ChooserParams;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IDiscountChooserWrapperProps;

@track(() => {
  return {
    component: "DiscountChooserWrapper",
  };
})
class DiscountChooserWrapper extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.add = this.add.bind(this);
    this.update = this.update.bind(this);
    this.remove = this.remove.bind(this);
  }

  @track((_props, _state, [discount]) => ({ action: "discount added", discount }))
  public add(discount: IEstimateDiscountData) {
    this.props.addDiscount(discount);
  }

  @track((_props, _state, [discount]) => ({ action: "discount updated", discount: discount.toJS() }))
  public update(discount: EstimateDiscountRecord) {
    this.props.updateDiscount(discount);
  }

  @track((_props, _state, [discount]) => ({ action: "discount removed", discount: discount.toJS() }))
  public remove(discount: EstimateDiscountRecord) {
    this.props.removeDiscount(discount);
  }

  public render() {
    const { estimate, params } = this.props;

    return (
      <Chooser
        total={estimate.total}
        onSelect={this.add}
        params={params}
        existing={estimate.discounts}
        update={this.update}
        destroy={this.remove}
      />
    );
  }
}

export default connector(DiscountChooserWrapper);
