import * as React from "react";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";
import { Modal, Col, Button } from "react-bootstrap";
import { Nullable } from "app2/src/records";
import { required, validateBaseUrl } from "app2/src/helpers/FinalFormValidator";
import { Actions } from "../../reducers/integrations/paysimple.actions";
import { FormControl, RsfForm } from "@tberrysoln/rsf-form";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Map } from "immutable";

export interface IAuthorizePaySimpleProps {
  service: string;
  orgId: number;
  tracking?: TrackingProp;
  authorization: Nullable<Map<string, string | boolean>>;
  authorize: (orgId: number) => Promise<any>;
  loadAuthorization: (orgId: number) => Promise<any>;
  close: (success: boolean) => void;
}

const track: Track<TrackingData, IAuthorizePaySimpleProps> = _track;

export interface IAuthorizePaySimpleState {}

@track(
  (props: IAuthorizePaySimpleProps) => {
    return {
      category: `Authorize ${props.service}`,
      action: "Show",
      org: props.orgId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: true,
  },
)
export class AuthorizePaySimple extends React.Component<IAuthorizePaySimpleProps, IAuthorizePaySimpleState> {
  constructor(props: IAuthorizePaySimpleProps) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  public componentDidMount() {
    const { orgId, loadAuthorization } = this.props;

    loadAuthorization(orgId);
  }

  public submit() {
    const { close, orgId, authorize } = this.props;

    authorize(orgId).then(() => close(true));
  }

  @track({ action: "close" })
  public closeModal() {
    const { close } = this.props;

    close(false);
  }

  public render() {
    const { service, authorization } = this.props;

    return (
      <>
        <Modal.Header key={0} closeButton>
          <Modal.Title>
            <div className="row-eq-height">
              <Col md={12}>
                <h3>{service}</h3>
              </Col>
            </div>
          </Modal.Title>
        </Modal.Header>
        {!authorization || authorization?.get("loading") ? (
          <Modal.Body style={{ height: "313px" }}>
            <SpinnerComponent inline={true} localProperty={true} />
          </Modal.Body>
        ) : (
          <RsfForm
            rootPath={["integrations", "paysimple", "authorization", this.props.orgId]}
            updateFormReducer={({ name, value }) =>
              Actions.update({ rootPath: ["authorization", this.props.orgId], name, value })
            }
            onSubmit={this.submit}>
            <Modal.Body>
              <FormControl label="Username" name="username" validate={required} />
              <FormControl label="Token" name="api_key" validate={required} />
              <FormControl label="Hosted Payment Page URL" name="hpp_url" validate={validateBaseUrl} />
            </Modal.Body>

            <Modal.Footer key={2}>
              <Button type="button" variant="cancel" className="pull-right" onClick={this.closeModal}>
                Close
              </Button>
              <Button type="submit" variant="default" className="pull-right">
                Authorize
              </Button>
            </Modal.Footer>
          </RsfForm>
        )}
      </>
    );
  }
}
