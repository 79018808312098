import { fetcher } from "../helpers/Fetcher";
import { IProductProcessData } from "app2/src/records/ProductProcessRecord";

interface IPriceListProcessesResponse {
  list: any;
}

class PriceListService {
  public url_id = "api/v1/price_lists/:id";
  public url = "api/v1/price_lists";

  public loadProcesses(): Promise<IProductProcessData> {
    const actionUrl = fetcher.joinUrls(this.url, "processes");

    const response = fetcher.get<IPriceListProcessesResponse>(actionUrl);
    return response;
  }

  public process(price_list_id: number, params: any): Promise<any> {
    const actionUrl = fetcher.joinUrls(this.url_id, "process").replace(":id", price_list_id.toString());

    const response = fetcher.post<any>(actionUrl, params);
    return response;
  }
}

export const priceListService = new PriceListService();
