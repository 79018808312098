import { Deferred } from "../services/deferred";
import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IFileData>): FileRecord => {
  const data: any = {
    url: json.url,
  };

  if (json.thumb) {
    data.thumb = new UrlRecord({ url: json.thumb.url });
  }

  if (json.small) {
    data.small = new UrlRecord({ url: json.small.url });
  }

  if (json.medium) {
    data.medium = new UrlRecord({ url: json.medium.url });
  }

  if (json.large) {
    data.large = new UrlRecord({ url: json.large.url });
  }

  if (json.filename) {
    data.filename = json.filename;
  }

  return new FileRecord(data);
};

export const toJSON = (record: FileRecord): IFileData => {
  return record.toJS();
};

export const getBase64 = (file: any) => {
  const deferred = Deferred.defer();
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    return deferred.resolve(reader.result);
  };
  reader.onerror = function (error) {
    return deferred.reject(error);
  };
  return deferred.promise;
};

export interface IFileData {
  url: string;
  thumb?: { url: string };
  small?: { url: string };
  medium?: { url: string };
  large?: { url: string };
  filename?: string;
}

export interface IUrlRecord {
  url: Nullable<string>;
}

export interface IFileRecord {
  url: Nullable<string>;
  thumb: Nullable<IUrlRecord>;
  small: Nullable<IUrlRecord>;
  medium: Nullable<IUrlRecord>;
  large: Nullable<IUrlRecord>;
  filename?: string;
}

export const defaultFileProps = {
  url: "",
  thumb: null,
  small: null,
  medium: null,
  large: null,
  filename: undefined,
};

export class FileRecord extends Record<IFileRecord>(defaultFileProps) implements IFileRecord {
  public readonly url!: string;
  public readonly thumb!: IUrlRecord;
  public readonly small!: IUrlRecord;
  public readonly medium!: IUrlRecord;
  public readonly large!: IUrlRecord;

  public constructor(values?: Partial<IFileRecord>) {
    values ? super(values) : super();
  }
}

export const defaultUrlProps = {
  url: "",
};

export class UrlRecord extends Record<IUrlRecord>(defaultUrlProps) implements IUrlRecord {
  public readonly url!: string;

  public constructor(values?: Partial<IUrlRecord>) {
    values ? super(values) : super();
  }
}
