import * as ng from "angular";
import { IProductOptionGroup } from "app/src/Models/ProductOptionGroup";
import { IImage } from "app/src/Models/Image";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";
import { dispatch } from "app2/src/storeRegistry";
import { Actions } from "app2/src/reducers/sku.actions";
import { ISkuData } from "app2/src/records/Sku";

export interface IProductOptionsMatch {
  matched: IProductOption[];
  match_id: string;
  name: string;
  description: string;
  product_price: number;
  show: boolean;
  sort_order: number;
}

export interface IProductOptionResource extends ng.resource.IResourceClass<IProductOption>, IRsfResource {
  fromJSON?(data: any): IProductOption;
  bulk?(data: any): IProductOption[];
  groupOptionsByMatchId?(array: IProductOption[]): IProductOptionsMatch[];
  groupOptionsByUuid?(array: IProductOption[]): IProductOptionsMatch[];
  byGroupMatchId?(data: any): IProductOption[];
}

export interface IProductOption extends ng.resource.IResource<IProductOption>, ProductOptionPrototype {
  check?: boolean;
  quantity?: number;

  $create(): ng.IPromise<IProductOption>;
  $save(): ng.IPromise<IProductOption>;
}

export interface IProductOptionResponse extends ng.resource.IResourceArray<IProductOption> {
  product_options: IProductOption[];
  meta: any;
}

export class ProductOptionPrototype {
  public id: number;
  public classy: string;
  public uuid: string;
  public match_id: string;
  public option_group_id: number;
  public name: string;
  public uom: string;
  public description: string;
  public cost: number;
  public msrp: number;
  public labor_price: number;
  public product_price: number;
  public price: number;
  public sort_order: number;
  public created_at: Date;
  public updated_at: Date;
  public images: IImage[];
  public charge_type: string;
  public pog_type: string;
  public sku_id?: number;
  public sku?: ISkuData;

  public product_option_group: IProductOptionGroup;

  public copyValues(from: IProductOption) {
    for (const key in from) {
      if (from.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        this[key] = from[key];
      }
    }
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/product_option_groups/:option_group_id/product_options/:id";
  const nested_url = BaseConfig.BASE_URL + "/api/v1/price_lists/:price_list_id/product_options/:id";
  let bulk_request: IProductOption[];

  const transformBulkResponse = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IProductOption[] => {
    if (status < 200 || status > 299) {
      return [];
    }
    const product_options = JSON.parse(response).product_options;
    _.each(bulk_request, (po: any) => {
      const found_option = _.find(product_options, (rpo: any) => {
        return rpo.id === po.id;
      });
      if (found_option !== undefined) {
        po.name = found_option.name;
        po.description = found_option.description;
        po.match_id = found_option.match_id;
        po.sort_order = found_option.sort_order;
      }
    });
    return bulk_request;
  };

  const transformBulkRequest = (data: any): string => {
    bulk_request = data.edit_product_options;
    const ids = _.pluck(bulk_request, "id");

    return angular.toJson({ option_group_id: data.option_group_id, ids: ids, product_option: data.product_option });
  };

  const transformSingleResponse = (response: string): IProductOption => {
    const data: any = JSON.parse(response).product_option;

    if (data.sku) {
      data.sku_id = data.sku.id;
      dispatch(Actions.receiveSku(data.sku));
    } else {
      data.sku_id = 0;
    }

    return ProductOption.fromJSON(data);
  };

  const transformMultipleResponse = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta: any = JSON.parse(response);

    _.each(meta.product_options, (child, index) => {
      meta.product_options[index] = ProductOption.fromJSON(child);
    });

    return meta;
  };

  const requestTransform = (product_option: IProductOption): string => {
    //noinspection TypeScriptUnresolvedFunction
    const data = JSON.parse(angular.toJson(JSON.decycle(product_option)));

    return angular.toJson({ product_option: data });
  };

  const ProductOption: IProductOptionResource = <IProductOptionResource>$resource(
    url,
    { id: "@id", option_group_id: "@option_group_id", price_list_id: "@price_list_id", "include[]": ["sku"] },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingleResponse,
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: requestTransform,
        transformResponse: transformSingleResponse,
      },
      bulk: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: nested_url + "/update_bulk",
        isArray: true,
        transformRequest: transformBulkRequest,
        transformResponse: transformBulkResponse,
      },
      byGroupMatchId: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: nested_url + "/match_id/:option_group_match_id",
        isArray: false,
        transformResponse: transformMultipleResponse,
      },
    },
  );

  ProductOption.fromJSON = (data: any) => {
    data.classy = "ProductOption";
    if (_.isArray(data.images)) {
      _.each(data.images, (image, idx) => {
        data.images[idx] = resources.Image.fromJSON(image);
      });
    } else {
      data.images = [];
    }

    if (!data.match_id) {
      data.match_id = data.uuid;
    }

    if (data.sku) {
      data.sku_id = data.sku.id;
    } else {
      data.sku_id = 0;
    }

    return new ProductOption(data);
  };

  ProductOption.groupOptionsByMatchId = (array: IProductOption[]) => {
    const array_of_objects = [];
    const groups = _.groupBy(array, "match_id");
    _.each(Object.keys(groups), (match_id) => {
      array_of_objects.push({
        match_id: match_id,
        name: groups[match_id][0].name,
        description: groups[match_id][0].description,
        price: groups[match_id][0].price,
        uom: groups[match_id][0].uom,
        matched: groups[match_id],
        show: false,
        sort_order: groups[match_id][0].sort_order,
      });
    });
    return _.sortBy(array_of_objects, "sort_order");
  };

  ProductOption.groupOptionsByUuid = (array: IProductOption[]) => {
    const array_of_objects = [];
    const groups = _.groupBy(array, "uuid");
    _.each(Object.keys(groups), (uuid) => {
      array_of_objects.push({
        uuid: uuid,
        name: groups[uuid][0].name,
        description: groups[uuid][0].description,
        price: groups[uuid][0].price,
        uom: groups[uuid][0].uom,
        matched: groups[uuid],
        show: false,
        sort_order: groups[uuid][0].sort_order,
      });
    });
    return _.sortBy(array_of_objects, "sort_order");
  };

  _.hiddenExtend(ProductOption.prototype, ProductOptionPrototype.prototype);

  ProductOption.inject = (injected: IRepository) => {
    resources = injected;
  };

  return ProductOption;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
