import * as commonActions from "./common.actions";
import { fromJSON } from "app2/src/records/FlashAlert";
import { fromJSON as sendInviteFromJSON } from "app2/src/records/SendInvite";
import { JobRecord } from "app2/src/records/Job";
import { IFlashAlertRecord } from "app2/src/records/FlashAlert";
import { List, Record } from "immutable";
import { Nullable } from "app2/src/records";
import { LocationStateRecord } from "app2/src/records/LocationState";

export const CommonStateRecord = Record({
  flashAlerts: List<IFlashAlertRecord>(),
  currentJob: new JobRecord(),
  displayWebCam: false,
  sendInvite: sendInviteFromJSON({}),
  notifierMessages: List<string>(),
  showImportModal: false,
  savedLocationState: null as Nullable<LocationStateRecord>,
  navDisplay: true,
  pageTitle: "",
});

export const initialState = CommonStateRecord();
export type CommonState = typeof initialState;

export const reducer = (state = initialState, action: commonActions.Actions): CommonState => {
  switch (action.type) {
    case commonActions.FLASH_ADD_ALERT:
      const flashAddAlerts = state.getIn(["flashAlerts"]);
      return state.setIn(["flashAlerts"], flashAddAlerts.push(fromJSON(action.payload)));
    case commonActions.FLASH_REMOVE_ALERT:
      const flashRemoveAlerts = state.getIn(["flashAlerts"]);
      return state.setIn(["flashAlerts"], flashRemoveAlerts.delete(action.payload));
    case commonActions.GET_FLASH_MESSAGES:
      return state.getIn(["flashAlerts"]);
    case commonActions.SET_JOB:
      return state.set("currentJob", action.payload);
    case commonActions.OPEN_SEND_INVITE_MODAL:
      return state
        .setIn(["sendInvite", "modal"], true)
        .setIn(["sendInvite", "type"], action.payload.type)
        .setIn(["sendInvite", "options"], action.payload.options);
    case commonActions.CLOSE_SEND_INVITE_MODAL:
      if (action.payload.sent) {
        state = state.updateIn(["sendInvite", "sent"], (sendInviteSent) => sendInviteSent + 1);
      }
      return state.setIn(["sendInvite", "modal"], false);
    case commonActions.OPEN_WEB_CAM_MODAL:
      return state.set("displayWebCam", true);
    case commonActions.CLOSE_WEB_CAM_MODAL:
      return state.set("displayWebCam", false);
    case commonActions.NOTIFIER_ADD_MESSAGE:
      const notifierMessages = state.getIn(["notifierMessages"]);
      return state.setIn(["notifierMessages"], notifierMessages.push(action.payload));
    case commonActions.NOTIFIER_REMOVE_MESSAGE:
      const notifierRemoveMessages = state.getIn(["notifierMessages"]);
      return state.setIn(["notifierMessages"], notifierRemoveMessages.delete(action.payload));
    case commonActions.OPEN_IMPORT_MODAL:
      return state.set("showImportModal", true);
    case commonActions.CLOSE_IMPORT_MODAL:
      return state.set("showImportModal", false);
    case commonActions.SET_NAV_DISPLAY:
      return state.set("navDisplay", action.payload.value);
    case commonActions.SET_SAVED_LOCATION_STATE:
      return state.set("savedLocationState", action.payload.location);
    case commonActions.SET_PAGE_TITLE:
      return state.set("pageTitle", action.payload.value);
    default:
      return state;
  }
};
