import { List, Record } from "immutable";

export const fromJSON = (json: Partial<IProductProcessData>): ProductProcessRecords => {
  const record: IProductProcessRecords = { ...(json as any) };

  return new ProductProcessRecords(record);
};

export interface IProductProcessData {
  list: any;
  cached?: boolean;
  loading?: boolean;
}

export interface IProductProcessRecords {
  list: any;
  errors: List<string>;
  loading: boolean;
  cached: boolean;
}

const defaultProductProcessProps: IProductProcessRecords = {
  list: {},
  loading: false,
  cached: false,
  errors: List<string>(),
};

export class ProductProcessRecords extends Record(defaultProductProcessProps) implements IProductProcessRecords {
  public readonly list!: List<any>;
  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public constructor(values?: Partial<IProductProcessRecords>) {
    values ? super(values) : super();
  }
}
