import * as React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { pathname } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { paymentAuth } from "app2/src/selectors/payment.selectors";
import { RootState } from "app2/src/reducers";

export interface RootProps {
  closeModal: () => void;
}

export const Root: React.FC<RootProps> = ({ closeModal }) => {
  // selectors
  const path = useSelector(pathname);
  const orgId = useSelector(currentOrgId);

  // hooks
  const dispatch = useDispatch();

  // callbacks
  const navigate = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget;
    switch (name) {
      case "onscreen":
        dispatch(push(`${path}/onscreen`));
        break;
      case "request":
        dispatch(push(`${path}/request`));
        break;
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Make Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="pt-3 pb-3">
          <Col className="text-center" md={3}>
            <Button name="onscreen" onClick={navigate}>
              Onscreen
            </Button>
          </Col>
          <Col md={9}>Enter payment details and make a payment immediately.</Col>
        </Row>
        <Row className="pt-3 pb-3">
          <Col className="text-center" md={3}>
            <Button name="request" onClick={navigate}>
              Email/Text
            </Button>
          </Col>
          <Col md={9}>
            Send the customer an email or text message payment request. Once received, they can make the payment on
            their own device.
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="cancel" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
};
