import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { RoofFaceRecord } from "app2/src/records/RoofFace";

export const roofFace = (state: RootState, props: any) => state.getIn(["roofFaces", "byId", props.roofFaceId]);

export const roofFaces = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<RoofFaceRecord> => {
    const { state, props } = stateProps;
    const { roofFaceIds } = props;
    return roofFaceIds.map((id: number) => roofFace(state, { roofFaceId: id }));
  },
);
