import { IJob } from "app/src/Models/Job";
import { IMeasurement } from "app/src/Models/Measurement";

export class AreaCalculatorCtrl {
  public measurements = [];
  public newMeasurement = {
    area: 0,
    pitch: 0,
    degrees: 0,
  };

  private deg2rad = Math.PI / 180;

  public static $inject = ["$uibModalInstance", "job", "measurement"];
  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public job: IJob,
    public measurement: IMeasurement,
  ) {
    this.newMeasurement.area = this.measurement["roof_flat_area"];
  }

  public calculate(measurement) {
    if (measurement.area <= 0 || measurement.pitch <= 0) {
      return "Unknown";
    }

    const newLength = Math.sqrt(Math.pow(measurement.pitch, 2) + Math.pow(12, 2));
    const factor = newLength / 12;
    const newValue = Math.round(measurement.area * factor);

    return {
      flat_area: this.newMeasurement.area,
      roof_area: newValue,
    };
  }

  public setPitch(measurement) {
    measurement.degrees = 0;
  }

  public isSavable(measurement) {
    return measurement.area <= 0 || measurement.pitch <= 0;
  }

  public convert(measurement) {
    const inRadians = Math.tan(measurement.degrees * this.deg2rad);
    const unrounded = inRadians * 12;

    measurement.pitch = parseFloat((Math.round(unrounded * 2) / 2).toFixed(1));
  }
}
