import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { fencing } from "app2/src/selectors/measurements/fencing.selectors";
import { Card, Table } from "react-bootstrap";
import Fence from "./FenceSummary";

interface FencingDisplayProps {
  fencingId: number;
  expanded: boolean;
  readOnly?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: FencingDisplayProps) => {
  return {
    fencing: fencing(state, ownProps),
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FencingDisplayProps;

class FencingDisplay extends React.Component<Props> {
  public render() {
    const { fencing, expanded, readOnly } = this.props;
    if (!fencing) {
      return null;
    }

    return (
      <>
        {fencing.fenceIds.map((fId: number, fIdx) => (
          <Fence fenceId={fId} fenceNumber={fIdx + 1} key={fIdx} expanded={expanded} readOnly={readOnly} />
        ))}
        <Card data-testid="summary-card">
          <Card.Header>Summary</Card.Header>
          <Card.Body className="p-0">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Fence Ct</th>
                  <th>4'</th>
                  <th>6'</th>
                  <th>8'</th>
                  <th>Corner</th>
                  <th>Terminal</th>
                  <th>Net (ft)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-testid="size">{fencing.fenceIds.size}</td>
                  <td data-testid="line-posts-4">{fencing.linePosts4}</td>
                  <td data-testid="line-posts-6">{fencing.linePosts6}</td>
                  <td data-testid="line-posts-8">{fencing.linePosts8}</td>
                  <td data-testid="corner-posts">{fencing.cornerPosts}</td>
                  <td data-testid="terminal-posts">{fencing.terminalPosts + fencing.gateCount * 2}</td>
                  <td data-testid="net-length">{fencing.netLength.toFixed(1)}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default connector(FencingDisplay);
