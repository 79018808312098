import { Nullable } from "app2/src/records";
import { Record } from "immutable";

export const fromJSON = (json: Partial<IRoomEstimationData>): RoomEstimationRecord => {
  const recordData: IRoomEstimationRecord = { ...(json as any) };

  return new RoomEstimationRecord(recordData);
};

export interface IRoomEstimationData {
  id: number;
  room_id: number;
  estimate_group_id: number;

  created_at: Date;
  updated_at: Date;
}

export interface IRoomEstimationRecord {
  id: number;
  room_id: number;
  estimate_group_id: number;

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

const defaultSkuProps: IRoomEstimationRecord = {
  id: 0,
  room_id: 0,
  estimate_group_id: 0,

  created_at: null,
  updated_at: null,
};

export class RoomEstimationRecord extends Record(defaultSkuProps) implements IRoomEstimationRecord {}
