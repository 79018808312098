import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { List } from "immutable";
import { ReportRecord, IReportData } from "app2/src/records/Report";
import * as reportService from "app2/src/api/report.service";
import { list } from "app2/src/api/report.service";
import { getPricing, IPlnarPricingResponse } from "app2/src/api/report.service";
import { RootDispatchType } from "../store";
import * as taskActions from "app2/src/reducers/task.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { Actions as PaginationActions } from "app2/src/reducers/pagination.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { handleErrors } from "app2/src/reducers/Utils";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { IPageData, QueryParamsRecord } from "app2/src/records/Page";
import { ITaskContext, ITaskData } from "app2/src/records/Task";

// SINGLE
export const FETCH_REPORT = "@reports/FETCH_REPORT";
export const RECEIVE_REPORT = "@reports/RECEIVE_REPORT";
export const SET_REPORT_LOADED = "@reports/SET_REPORT_LOADED";
// MULTIPLE
export const RECEIVE_REPORTS = "@reports/RECEIVE_REPORTS";
// PRICING
export const FETCH_PRICING_REPORT = "@reports/FETCH_PRICING_REPORT";
export const SET_PRICING_REPORT_LOADED = "@reports/SET_PRICING_REPORT_LOADED";
export const RECEIVE_PRICING_REPORT = "@reports/RECEIVE_PRICING_REPORT";

export const Actions = {
  // SINGLE
  fetchReport: (reportId: number) => createAction(FETCH_REPORT, { reportId }),
  receiveReport: (report: IReportData) => createAction(RECEIVE_REPORT, { report }),
  setReportLoaded: (reportId: number) => createAction(SET_REPORT_LOADED, { reportId }),
  // MULTIPLE
  receiveReports: (reports: IReportData[]) => createAction(RECEIVE_REPORTS, { reports }),
  // PRICING
  fetchPricingReport: (provider: string, kind: string) => createAction(FETCH_PRICING_REPORT, { provider, kind }),
  setPricingReportLoaded: (provider: string, kind: string) =>
    createAction(SET_PRICING_REPORT_LOADED, { provider, kind }),
  receivePricingReport: (provider: string, kind: string, report: any) =>
    createAction(RECEIVE_PRICING_REPORT, { provider, kind, report }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  addJobReport: (jobId: number, report: ReportRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const result: IReportData = await reportService.create(jobId, report, "job");
        if (result.task) {
          const task = result.task;
          const contextObj: ITaskContext = {
            model: "report",
            action: "taskHandler",
            action_payload: { report_id: result.id, job_id: jobId },
            error_action: "errorTaskHandler",
          };
          task.context = contextObj;
          dispatch(taskActions.Actions.receiveTask(task));
        }
        dispatch(Actions.receiveReport(result));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Report request submitted successfully."));
      } catch (errors) {
        handleErrors(errors, dispatch);
        throw errors;
      }
    };
  },
  listReports: (queryParams: QueryParamsRecord): ThunkResult<any> => {
    return async (dispatch: RootDispatchType) => {
      const modelName = "reports";
      dispatch(PaginationActions.fetchPage(modelName, queryParams));

      try {
        const response = await list(queryParams);

        dispatch(Actions.receiveReports(response.reports));
        const pageData: Partial<IPageData> = {
          ids: List(response.reports.map((report: IReportData) => report.id)),
          pagination: response.meta.pagination,
        };
        dispatch(PaginationActions.receivePage(modelName, queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response, dispatch);
        dispatch(PaginationActions.receivePageError(modelName, queryParams, errors));
      }
    };
  },
  getPlnarPricing: (): ThunkResult<any> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const provider = "plnar";
      const kind = "interior";
      try {
        dispatch(Actions.fetchPricingReport(provider, kind));

        const { pricing } = await getPricing<IPlnarPricingResponse>({
          provider,
          kind,
          org_id: currentOrgId(getState()),
        });
        dispatch(Actions.receivePricingReport(provider, kind, pricing));
      } catch (response) {
        dispatch(Actions.setPricingReportLoaded(provider, kind));
        handleErrors(response, dispatch);
      }
    };
  },
  taskHandler: (task: ITaskData, payload: any = {}): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      let name = task?.results?.class_name;
      if (!name) {
        name = "Task Completion";
      }
      dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, `${name} Successful.`));
    };
  },
  errorTaskHandler: (task: ITaskData, payload: any = {}): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const errorMessage =
        task?.results?.error_message ||
        "There were problems processing your task. Please try again. If the problem persists contact support.";
      dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errorMessage));
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
