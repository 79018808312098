import { ICalculatedFinancingData } from "app2/src/records/CalculatedFinancing";
import {
  FinanceOptionRecord,
  IFinanceOptionData,
  fromJSON as financeOptionFromJSON,
} from "app2/src/records/FinanceOption";
import { Record } from "immutable";

export const fromJSON = (json: Partial<IEstimateFinanceOptionData>): EstimateFinanceOptionRecord => {
  const record: IEstimateFinanceOptionRecord = { ...(json as any) };

  if (json.created_at) {
    record.created_at = new Date(json.created_at as any as string);
  }

  if (json.updated_at) {
    record.updated_at = new Date(json.updated_at as any as string);
  }

  // Only needed for angular
  if (json.finance_option) {
    json.finance_option = financeOptionFromJSON(json.finance_option);
  }

  return new EstimateFinanceOptionRecord(record);
};

export const toJSON = (record: EstimateFinanceOptionRecord): IEstimateFinanceOptionData => {
  return record.toJS();
};

export const toCalculatedFinancing = (
  financedAmount: number,
  estimateFinanceOption: IEstimateFinanceOptionData,
): ICalculatedFinancingData => {
  return {
    ...estimateFinanceOption,
    financed_amount: financedAmount,
    error: "",
  };
};

export interface IEstimateFinanceOptionData {
  id: number;
  finance_option_id: number;
  estimate_id: number;
  monthly_payment: number;
  promo_payment: number;
  amount: number;
  finance_option: IFinanceOptionData;
  created_at: Date;
  updated_at: Date;
}

export interface IEstimateFinanceOptionRecord {
  id: number;
  finance_option_id: number;
  estimate_id: number;
  monthly_payment: number;
  promo_payment: number;
  amount: number;
  finance_option: FinanceOptionRecord;
  created_at: Date;
  updated_at: Date;
  _destroy: boolean;
}

export const defaultEstimateFinanceOptionProps: IEstimateFinanceOptionRecord = {
  id: 0,
  finance_option_id: 0,
  estimate_id: 0,
  monthly_payment: 0,
  promo_payment: 0,
  amount: 0,
  finance_option: null,
  created_at: new Date(),
  updated_at: new Date(),
  _destroy: null,
};

export class EstimateFinanceOptionRecord
  extends Record(defaultEstimateFinanceOptionProps)
  implements IEstimateFinanceOptionRecord {}

export const emptyEstimateFinanceOptionRecord = fromJSON({});
