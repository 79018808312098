import * as React from "react";
import { Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FinanceOptionRecord, hasRequiredApplicationData } from "app2/src/records/FinanceOption";
import { EstimateRecord } from "app2/src/records/Estimate";
import { List } from "immutable";
import { RootState, RootActions } from "app2/src/reducers";
import { ThunkDispatch } from "redux-thunk";
import * as estimateFinanceOptionActions from "app2/src/reducers/estimateFinanceOption.actions";
import * as estimateFinanceOptionSelector from "app2/src/selectors/estimateFinanceOption.selectors";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { sortedFilteredFinanceOptions } from "app2/src/selectors/financeOption.selectors";
import { connect, ConnectedProps } from "app2/src/connect";
import { EstimateFinanceOptionRecord } from "app2/src/records/EstimateFinanceOption";
import track from "react-tracking";
import { ApplicationButtons } from "./ApplicationButtons";
import { calculatedFinancings } from "app2/src/selectors/calculatedFinancing.selectors";
import { CalculatedFinancingRecord } from "app2/src/records/CalculatedFinancing";
import { JobRecord } from "app2/src/records/Job";
import { job as jobById } from "app2/src/selectors/job.selectors";
import FinanceOptionDisplay from "app2/src/components/PriceDrawer/DrawerContainer/Comparator/EstimateView/PaymentOptions/PaymentOptionsList/FinanceOptionDisplay";

const mapStateToProps = (state: RootState, ownProps: IPaymentOptionsListProps) => {
  const ids = ownProps.estimate.finance_option_ids;
  const sortedFinanceOptions = sortedFilteredFinanceOptions(state, {
    orgId: ownProps.orgId,
    sort: ownProps.sort,
    reverse: ownProps.reverse,
    estimate: ownProps.estimate,
    filterValidation: true,
  });
  const financedAmount = ownProps.estimate.getIn(["payment_terms", "financed_amount"]);
  return {
    financedAmount,
    estimateFinanceOptions: estimateFinanceOptionSelector.estimateFinanceOptions(state, {
      estimateFinanceOptionIds: ids,
    }),
    sortedFinanceOptions: sortedFinanceOptions,
    calculatedFinancings: calculatedFinancings(state, {
      financeOptionIds: sortedFinanceOptions.map((fo) => fo.id),
      financedAmount,
    }),
    currentJob: jobById(state, { jobId: ownProps.estimate.get("job_id") }),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: IPaymentOptionsListProps,
) => {
  return {
    addFinanceOption: (estimateId: number, financeOptionId: number) =>
      dispatch(estimateFinanceOptionActions.AsyncActions.addFinanceOption(estimateId, financeOptionId)),
    removeFinanceOption: (estimateId: number, financeOptionId: number) =>
      dispatch(estimateFinanceOptionActions.Actions.removeFinanceOption(estimateId, financeOptionId)),
    calculateFinanceOptions: (financeOptionIds) =>
      dispatch(estimateActions.AsyncActions.reduxCalculateCalculatedFinancing(ownProps.estimate.id, financeOptionIds)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IPaymentOptionsListProps {
  orgId: number;
  estimate: EstimateRecord;
  sort: string;
  reverse: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IPaymentOptionsListProps;

@track({ component: "Payment Options List" })
class PaymentOptionsList extends React.Component<Props> {
  public financeOptionHasRequiredData: (efo: FinanceOptionRecord) => Array<string>;

  constructor(props) {
    super(props);

    this.addOrRemoveFinanceOption = this.addOrRemoveFinanceOption.bind(this);

    this.financeOptionHasRequiredData = (efo: FinanceOptionRecord) => hasRequiredApplicationData(efo, props.currentJob);
  }

  @track({ action: "Add or Remove Finance Option" })
  public addOrRemoveFinanceOption(event: any) {
    const checkValue = event.target.checked;
    const financeOptionId = parseInt(event.target.id);
    const { addFinanceOption, removeFinanceOption, estimateFinanceOptions, estimate } = this.props;

    if (checkValue) {
      addFinanceOption(estimate.id, financeOptionId);
    } else {
      const estimateFinanceOption = estimateFinanceOptions.find((efo) => efo.finance_option_id === financeOptionId);
      removeFinanceOption(estimate.id, estimateFinanceOption.id);
    }
  }

  public componentDidMount() {
    const { calculateFinanceOptions, sortedFinanceOptions } = this.props;
    calculateFinanceOptions(sortedFinanceOptions.map((fo) => fo.id));
  }

  public componentDidUpdate(prevProps: Props) {
    const { financedAmount: prevFinancedAmount } = prevProps;
    const { financedAmount, calculateFinanceOptions, sortedFinanceOptions } = this.props;
    if (prevFinancedAmount !== financedAmount) {
      calculateFinanceOptions(sortedFinanceOptions.map((fo) => fo.id));
    }
  }

  public render() {
    const { sortedFinanceOptions, estimateFinanceOptions, estimate, calculatedFinancings } = this.props;
    const estimateFOIds = estimateFinanceOptions.map((efo) => efo.finance_option_id);
    if (sortedFinanceOptions.size <= 0) {
      return <p>None Available</p>;
    }

    return (
      <Fragment>
        {sortedFinanceOptions.map((financeOption, idx) => {
          const calculatedFinancing = calculatedFinancings.get(idx);

          return (
            <Fragment key={idx}>
              {financeOption.promo_term > 0 && (
                <Row className="payment-options v-align-middle">
                  <FinanceOptionDisplay
                    estimateId={estimate.id}
                    financeOption={financeOption}
                    calculatedFinancing={calculatedFinancing}
                    kind="promo"
                  />
                </Row>
              )}
              <Row className="payment-options v-align-middle" key={idx}>
                <Col sm={12} lg={12} className="ellipsify payment-name">
                  <span title={financeOption.name}>{financeOption.name}</span>
                </Col>
                <FinanceOptionDisplay
                  estimateId={estimate.id}
                  financeOption={financeOption}
                  calculatedFinancing={calculatedFinancing}
                  kind="default"
                />
                <Col sm={1} lg={1} className="select-finance-option">
                  {calculatedFinancing && !calculatedFinancing.loading && (
                    <Form.Check
                      id={financeOption.id.toString()}
                      type="checkbox"
                      onChange={this.addOrRemoveFinanceOption}
                      checked={estimateFOIds.contains(financeOption.id)}
                    />
                  )}
                </Col>
              </Row>
              <Row className="payment-options v-align-middle">
                <Col sm={12}>
                  <ApplicationButtons
                    financeOption={financeOption}
                    estimate={estimate}
                    applyNowValidator={this.financeOptionHasRequiredData}
                  />
                </Col>
              </Row>
              <hr />
            </Fragment>
          );
        })}
        <span>
          *{" "}
          <span className="text-smaller">
            {" "}
            Payment amounts shown are estimates. Payments may vary based on customer's credit and eligibility. Complete
            loan terms including required monthly payments and promotional product details will be disclosed in your
            loan agreement.
          </span>
        </span>
      </Fragment>
    );
  }
}

export default connector(PaymentOptionsList);
