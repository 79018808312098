import * as React from "react";
import { IDoc } from "app/src/Models/Doc";
import { downloadFile } from "app2/src/helpers/File";

export interface DocumentLinkProps {
  document: IDoc;
  iconOnly?: boolean;
}

/**
 * Used in product_editor.html and reports/show.html to download document file using proper authentication
 */
export const NgDocumentLink: React.FC<DocumentLinkProps> = ({ document, iconOnly }) => {
  const download = () => {
    downloadFile(document.file.url, document.name);
  };

  if (iconOnly) return <i onClick={download} className="rsf-download-link rsf-base-66"></i>;

  return (
    <a className="link" onClick={download}>
      {document?.name}
    </a>
  );
};

export default NgDocumentLink;
