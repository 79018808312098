import { StoreRegistry } from "app2/src/storeRegistry";
import * as React from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import * as config from "react-global-configuration";
import FencingContainer from "app2/src/components/Drawing/Fencing";
import * as measurementActions from "app2/src/reducers/measurement.actions";
import { Acl } from "app2/src/helpers/Acl";
import { useDispatch, useSelector } from "react-redux";
import { currentOrgId, currentOrgSettingsAcl, preferencesConfig } from "app2/src/selectors/org.selectors";
import { List } from "immutable";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";
import { useTracking } from "react-tracking";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { push } from "connected-react-router/immutable";
import { pathname, query } from "app2/src/selectors/router.selectors";
import { ISession } from "app/src/Common/SessionService";
import { RootState } from "app2/src/reducers";
import { addMeasurementFilter } from "app2/src/components/JobTabs/MeasurementTab/AddMeasurementModal";

interface ButtonBarProps {
  changed: boolean;
  reset: () => void;
}

export const ButtonBar: React.FC<ButtonBarProps> = ({ changed, reset }) => {
  const session: ISession = StoreRegistry.get("Session");
  const GAF_ENV = config.get("ENVIRONMENT") === "gaf";

  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "ButtonBar" });

  // Local State
  const [openFencing, setOpenFencing] = React.useState(0);
  const [showQMButton, setShowQMButton] = React.useState(GAF_ENV);

  session.canReport("quick_measure", ["order"]).then((result: boolean) => {
    setShowQMButton(GAF_ENV && result);
  });

  const permissions = useSelector((state) => currentOrgSettingsAcl(state, { path: ["measurements"] })) || List();
  const jobTabPermissions = useSelector((state: RootState) =>
    preferencesConfig(state, { orgId: currentOrgId(state), path: ["measurement", "job_tab"], notSet: List() }),
  );
  const editing = useSelector(measurementEditing);
  const measurementTools = List([
    "rapid_area",
    "satellite_measure",
    "deck_wizard",
    "fencing_tool",
    "pool_tool",
    "annotator",
  ]);
  const enabledMeasureemntTools = permissions.filter((permission) => measurementTools.includes(permission));
  const queryParams = useSelector(query);
  const rootPathname = useSelector(pathname);
  const enabledMeasurements = React.useMemo(() => addMeasurementFilter(jobTabPermissions), [jobTabPermissions]);

  // Methods
  const save = () => {
    trackEvent({ action: "Save Changes" });
    dispatch(measurementActions.AsyncActions.updateMeasurement(["elevations"]));
  };

  const enableEditing = () => {
    trackEvent({ action: "enable measurement editing" });
    dispatch(measurementActions.Actions.setEditing(true));
  };

  const disableEditing = () => {
    trackEvent({ action: "disable measurement editing" });
    reset();
    dispatch(measurementActions.Actions.setEditing(false));
  };

  const openQuickMeasureOrder = () => {
    trackEvent({ action: "import" });
    StoreRegistry.get<any>("$rootScope").$emit("order_quick_measure:open", {});
  };

  const openImporter = () => {
    trackEvent({ action: "import" });
    StoreRegistry.get<any>("$rootScope").$emit("measurement_import:open", {});
  };

  const openAnnotator = () => {
    trackEvent({ action: "show", category: "Annotations" });
    StoreRegistry.get<any>("$rootScope").$emit("annotator:open", {});
  };

  const openRapidArea = () => {
    trackEvent({ action: "open rapid area" });
    StoreRegistry.get<any>("$rootScope").$emit("rapid_area:open", {});
  };

  const openSatelliteMeasure = () => {
    trackEvent({ action: "open satellite measure" });
    StoreRegistry.get<any>("$rootScope").$emit("satellite_measure:open", {});
  };

  const openDeckWizard = () => {
    trackEvent({ action: "open deck tool" });
    StoreRegistry.get<any>("$rootScope").$emit("deck_tool:open", {});
  };

  const openPoolTool = () => {
    trackEvent({ action: "open pool tool" });
    StoreRegistry.get<any>("$rootScope").$emit("pool_tool:open", {});
  };

  const openFencingTool = () => {
    trackEvent({ action: "open fencing tool" });
    setOpenFencing((value) => value + 1);
  };

  const openAddMeasurement = () => {
    trackEvent({ action: "open add measurement" });
    const newQueryParams = new URLSearchParams({
      ...queryParams.toJS(),
      add: "global",
    });
    dispatch(push(`${rootPathname}?${newQueryParams.toString()}`));
  };

  // Lifecycle
  React.useEffect(() => {
    return disableEditing;
  }, []);

  if (!Acl.can("update", "job")) {
    return null;
  }

  if (editing) {
    return (
      <>
        <Button variant="light" onClick={save} className="float-right ml-2" disabled={!changed}>
          Save Changes
        </Button>
        {changed ? (
          <ConfirmDialog title={"There are unsaved changes that will be lost. Are you sure you want to continue?"}>
            {(confirm) => (
              <Button variant="primary" onClick={confirm(() => disableEditing())} className="float-right ml-2">
                Cancel
              </Button>
            )}
          </ConfirmDialog>
        ) : (
          <Button variant="primary" onClick={disableEditing} className="float-right ml-2">
            Cancel
          </Button>
        )}
      </>
    );
  }

  return (
    <div className="float-right">
      {showQMButton && (
        <Button variant="outline-primary" onClick={openQuickMeasureOrder} className="mr-2">
          <FontAwesome name="file-text-o" />
          &nbsp; Order QuickMeasure Report
        </Button>
      )}
      <Button variant="outline-primary" onClick={openImporter}>
        <FontAwesome name="download" />
        &nbsp; Import
      </Button>
      {enabledMeasurements.size > 0 && (
        <Button variant="outline-primary" onClick={openAddMeasurement} className="ml-2">
          <FontAwesome name="plus" />
          &nbsp; Measurement
        </Button>
      )}
      <Button variant="outline-primary" onClick={enableEditing} className="ml-2">
        <FontAwesome name="pencil" />
        &nbsp; Edit
      </Button>
      {enabledMeasureemntTools.size > 0 ? (
        <DropdownButton variant="primary" title="Tools" className="float-right ml-2">
          {permissions.includes("annotator") ? (
            <Dropdown.Item href="#" onClick={openAnnotator}>
              Window-inator
            </Dropdown.Item>
          ) : null}
          {permissions.includes("rapid_area") ? (
            <Dropdown.Item href="#" onClick={openRapidArea}>
              Rapid Area
            </Dropdown.Item>
          ) : null}
          {permissions.includes("satellite_measure") ? (
            <Dropdown.Item href="#" onClick={openSatelliteMeasure}>
              Satellite Measure
            </Dropdown.Item>
          ) : null}
          {permissions.includes("deck_wizard") ? (
            <Dropdown.Item href="#" onClick={openDeckWizard}>
              Deck Tool
            </Dropdown.Item>
          ) : null}
          {permissions.includes("fencing_tool") ? (
            <Dropdown.Item href="#" onClick={openFencingTool}>
              Fencing Tool
            </Dropdown.Item>
          ) : null}
          {permissions.includes("pool_tool") ? (
            <Dropdown.Item href="#" onClick={openPoolTool}>
              Pool Tool
            </Dropdown.Item>
          ) : null}
        </DropdownButton>
      ) : null}
      <FencingContainer openIdx={openFencing} />
    </div>
  );
};
