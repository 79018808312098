import { IPagingMetadata } from "../../Models/PagingMetadata";
import { IInvoice } from "./Invoice";
import { ICharge } from "./Charge";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../../Common/IBaseConfig";

export interface IInvoiceItem extends ng.resource.IResource<IInvoiceItem>, InvoiceItemPrototype {}

export interface IInvoiceItemResource extends ng.resource.IResourceClass<IInvoiceItem>, IRsfResource {
  fromJSON?(data: any): IInvoiceItem;
}

export interface IInvoiceItemResponse extends ng.resource.IResourceArray<IInvoiceItem> {
  invoice_items: IInvoiceItem[];
  meta: IPagingMetadata;
}

class InvoiceItemPrototype {
  public id: number;
  public stripe_id: string;
  public amount: number;
  public currency: string;
  public billing_customer_id: number;
  public date: Date;
  public description: string;
  public discountable: boolean;
  public billing_invoice_id: number;
  public livemode: boolean;
  public metadata: any;
  public period: any;
  public billing_plan_id: number;
  public proration: boolean;
  public quantity: number;
  public billing_subscription_id: number;
  public created_at: Date;
  public updated_at: Date;
  public stripe_data: any;
  public invoice?: IInvoice;

  public refunded(): boolean {
    if (!this.invoice) {
      return false;
    }

    if (!_.isArray(this.invoice.charges) || this.invoice.charges.length <= 0) {
      return false;
    }

    if (_.every(this.invoice.charges, (c: ICharge) => !c.refunds || c.refunds.length <= 0)) {
      return false;
    }

    return true;
  }

  public outstanding(): boolean {
    if (!this.invoice) {
      return false;
    }

    if (this.invoice.amount_due > this.invoice.amount_paid) {
      return true;
    }

    return false;
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IInvoiceItemResource => {
  const url = BaseConfig.BASE_URL + "/billing/v1/invoice_items/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IInvoiceItem => {
    if (status < 200 || status > 299) {
      return new InvoiceItem({});
    }

    return InvoiceItem.fromJSON(JSON.parse(response).invoice_item);
  };

  const multipleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IInvoiceItemResponse => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);
    _.each(data.invoice_items, (invoice_item, index) => {
      data.invoice_items[index] = InvoiceItem.fromJSON(invoice_item);
    });

    return data;
  };

  const InvoiceItem: IInvoiceItemResource = <IInvoiceItemResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  _.hiddenExtend(InvoiceItem.prototype, InvoiceItemPrototype.prototype);

  InvoiceItem.fromJSON = (data: any): IInvoiceItem => {
    return new InvoiceItem(data);
  };

  InvoiceItem.inject = (injected: IRepository) => {
    resources = injected;
  };

  return InvoiceItem;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
