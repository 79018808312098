import { createSelector } from "reselect";
import { RootState } from "app2/src/reducers";
import { currentJobId, selectedEstimateId } from "app2/src/selectors/job.selectors";
import { estimate as estimateSelector } from "app2/src/selectors/estimate.selectors";
import { currentEstimateGroups } from "app2/src/selectors/estimateGroup.selectors";
import { token } from "app2/src/selectors/token.selectors";
import { EstimateGroupRecord } from "app2/src/records/EstimateGroup";
import { currentUser } from "app2/src/selectors/userCommon.selectors";
import { StylizedQuotesToSimpleQuotes } from "app2/src/helpers/Format";

const formatEstimateGroup = (group: EstimateGroupRecord) => ({
  id: group.id,
  name: group.name,
  subtotal: group.subtotal,
  line_items: [],
});

export const payload = createSelector(
  [
    (state: RootState) => token(state, { kind: "ingage" }),
    (state: RootState) =>
      estimateSelector(state, { estimateId: selectedEstimateId(state), jobId: currentJobId(state) }),
    (state: RootState) => currentEstimateGroups(state, { includeAll: true }),
  ],
  (ingageToken, estimate, estimateGroups) => {
    if (!ingageToken || !estimate || !estimateGroups) return null;

    const tokenData = ingageToken.get("data").toJS();
    const payload = {
      config: {
        packages: tokenData.packages,
        discount: tokenData.discount,
        finance_options: tokenData.finance_options,
      },
      estimate: {
        id: estimate.id,
        total: estimate.total,
        groups: {
          included: [
            ...estimateGroups
              .filter((group) => group.included)
              .toJS()
              .map((group) => formatEstimateGroup(group)),
          ],
          options: [
            ...estimateGroups
              .filter((group) => !group.included)
              .toJS()
              .map((group) => formatEstimateGroup(group)),
          ],
        },
      },
    };

    return payload;
  },
);

export const urlSelector = createSelector(
  [payload, (state: RootState) => token(state, { kind: "ingage" }), currentUser],
  (payload, ingageToken, user) => {
    const presentation_url = ingageToken?.getIn(["data", "presentation_url"]);
    if (!payload || !presentation_url || !user) return null;

    const btoaPayload = btoa(StylizedQuotesToSimpleQuotes(JSON.stringify(payload)));
    return `${presentation_url}?pricing-calculator__data=${encodeURIComponent(
      btoaPayload,
    )}&pricing-calculator__hidenavbar=true&c=${encodeURIComponent(user.email)}`;
  },
);
