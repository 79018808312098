import * as fileSaver from "file-saver";
import { IOrgResource } from "app/src/Models/Org";
import { IPretty } from "app/src/Common/PrettyNameService";
import * as moment from "moment";

export class GlobalStatsCtrl {
  public spinnerPromise: ng.IPromise<any>;
  public statisticsResponse: any;
  public timePeriodValues: string[] = ["month", "week", "day", "quarter", "year"];
  public period = "month";
  public dataGen = [];
  public dataVis = [];
  public dataRep = [];
  public dataDol = [];
  public dataSpe = [];
  public dataOrg = [];
  public dataUser = [];
  public graphFormat: any = {
    month: "%b",
    week: "%b %d",
    day: "%a %d",
    quarter: "%b",
    year: "%Y",
  };
  public generalActivity: string[] = [
    "jobs",
    "estimates",
    "appointments",
    "screen_shares",
    "documents",
    "images",
    "visualizations",
    "reports",
    "signatures",
    "users",
  ];
  public specificActivity: string[] = [
    "estimate_templates",
    "price_lists",
    "activated_price_lists",
    "measurement_links",
    "presentations",
  ];
  public visualizationActivity: string[] = ["chameleon", "chameleon_v2", "renoworks", "hover"];
  public reportActivity: string[] = [
    "skymeasure_roof",
    "skymeasure_walls",
    "wvs_hail",
    "wvs_wind",
    "wvs_lightning",
    "eagleview_roofing",
    "quickmeasure_roofing",
  ];
  public dollarActivity: string[] = ["estimates_amount", "signatures_amount"];
  public userActivity: string[] = ["paid_billable_users", "free_billable_users", "freemium_users"];
  public orgActivity: string[] = ["billable_orgs", "freemium_orgs"];
  public optionsGen: any;
  public optionsVis: any;
  public optionsRep: any;
  public optionsDol: any;
  public optionsSpe: any;
  public optionsOrg: any;
  public optionsUser: any;
  public options: any = {
    chart: {
      type: "lineChart",
      height: 450,
      margin: {
        bottom: 80,
      },
      x: function (d) {
        return d.x;
      },
      y: function (d) {
        return d.y;
      },
      useInteractiveGuideline: true,
      xAxis: {
        axisLabel: "Time",
        rotateLabels: -45,
      },
      yAxis: {
        axisLabel: "Created Count",
      },
    },
  };

  public static $inject = ["$state", "$stateParams", "Org", "Pretty", "$window"];
  constructor(
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public Org: IOrgResource,
    public Pretty: IPretty,
    public $window: ng.IWindowService,
  ) {
    this.query();

    this.optionsGen = _.clone(this.options);
    this.optionsGen.title = { text: "General Activity", enable: true };
    this.optionsVis = _.clone(this.options);
    this.optionsVis.title = { text: "Visualization Activity", enable: true };
    this.optionsRep = _.clone(this.options);
    this.optionsRep.title = { text: "Report Activity", enable: true };
    this.optionsDol = _.clone(this.options);
    this.optionsDol.title = { text: "Dollar Activity", enable: true };
    this.optionsSpe = _.clone(this.options);
    this.optionsSpe.title = { text: "Specific Activity", enable: true };
    this.optionsOrg = _.clone(this.options);
    this.optionsOrg.title = { text: "Org Activity", enable: true };
    this.optionsUser = _.clone(this.options);
    this.optionsUser.title = { text: "User Activity", enable: true };
  }

  public query(): ng.IPromise<any> {
    this.statisticsResponse = this.Org.globalStats({ period: this.period });
    this.spinnerPromise = this.statisticsResponse.$promise;
    return this.statisticsResponse.$promise.then(() => {
      this.setupGeneral();
      this.setupVisualization();
      this.setupReport();
      this.setupDollar();
      this.setupSpecific();
      this.setupOrg();
      this.setupUser();
    });
  }

  public setupGeneral() {
    const app = this;
    this.optionsGen.chart.xAxis.tickFormat = function (d) {
      // noinspection TypeScriptUnresolvedVariable
      return d3.time.format(app.graphFormat[app.period])(moment(d).toDate());
    };
    this.dataGen = [];
    this.statisticsResponse.stats = _.sortBy(this.statisticsResponse.stats, "month");
    _.each(this.generalActivity, (key) => {
      this.dataGen.push(this.setStats(key));
    });
    this.optionsGen.chart.xAxis.tickValues = _.pluck(this.dataGen[0].values, "x");
    this.optionsGen.chart.xAxis.axisLabel = "Time (" + _.toTitleCase(this.period) + ")";
  }

  public setupVisualization() {
    this.dataVis = [];
    this.statisticsResponse.stats = _.sortBy(this.statisticsResponse.stats, "month");
    _.each(this.visualizationActivity, (key) => {
      this.dataVis.push(this.setStats(key));
    });
  }

  public setupReport() {
    this.dataRep = [];
    this.statisticsResponse.stats = _.sortBy(this.statisticsResponse.stats, "month");
    _.each(this.reportActivity, (key) => {
      this.dataRep.push(this.setStats(key));
    });
  }

  public setupDollar() {
    this.dataDol = [];
    this.statisticsResponse.status = _.sortBy(this.statisticsResponse.stats, "month");
    _.each(this.dollarActivity, (key) => {
      this.dataDol.push(this.setStats(key));
    });
  }

  public setupSpecific() {
    this.dataSpe = [];
    this.statisticsResponse.status = _.sortBy(this.statisticsResponse.stats, "month");
    _.each(this.specificActivity, (key) => {
      this.dataSpe.push(this.setStats(key));
    });
  }

  public setupOrg() {
    this.dataOrg = [];
    this.statisticsResponse.status = _.sortBy(this.statisticsResponse.stats, "month");
    _.each(this.orgActivity, (key) => {
      this.dataOrg.push(this.setStats(key));
    });
  }

  public setupUser() {
    this.dataUser = [];
    this.statisticsResponse.status = _.sortBy(this.statisticsResponse.stats, "month");
    _.each(this.userActivity, (key) => {
      this.dataUser.push(this.setStats(key));
    });
  }

  public saveCsv() {
    this.Org.globalCsvStats({ period: this.period }).$promise.then((data: any) => {
      fileSaver.saveAs(data.response, "Global Stats - " + moment().format() + ".csv");
    });
  }

  public setStats(key) {
    const values = [];
    _.each(this.statisticsResponse.stats, (pt: any) => {
      values.push({ x: moment(pt.month).toDate(), y: parseFloat(pt[key]) });
    });
    return {
      key: _.toTitleCase(key),
      values: values,
    };
  }

  public statsTotal(stat: string): number {
    let total = 0;
    _.each(_.pluck(this.statisticsResponse.stats, stat), function (v) {
      total = total + parseFloat(v);
    });
    return total;
  }
}
