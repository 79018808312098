import * as ng from "angular";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { IProduct, IProductResource } from "app/src/Models/Product";
import { IImageResource } from "app/src/Models/Image";
import { IDocResource } from "app/src/Models/Doc";
import { SortableOptions } from "app/src/Common/SortableOptions";
import { IConvert } from "app/src/Common/UnitConversionService";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import { IProductOptionGroupResource, IProductOptionGroupResponse } from "app/src/Models/ProductOptionGroup";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { ISession } from "app/src/Common/SessionService";
import { dispatch, useSelector } from "app2/src/storeRegistry";
import { Actions } from "app2/src/reducers/sku.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { ISkuData } from "app2/src/records/Sku";

export class OrgProductEditorCtrl {
  public visibilityOptions: string[] = ["always", "company_only"];
  public kindOptions: string[] = ["unset", "window", "door"];
  public bracketTypeOptions: string[] = ["unset_bt", "ui_bt", "sqft", "width_height_bt", "per_ui", "per_sqft"];
  public displayOptions: string[] = ["estimate", "contract", "both", "do_not_display"];
  public activeTab: number;
  public spinnerPromise: ng.IPromise<any>;
  public sortableOptionsImage = new SortableOptions();
  public sortableOptionsDoc = new SortableOptions();
  public focusHere = 0;
  public showSizes = false;
  public option_group_response: IProductOptionGroupResponse;

  public static $inject = [
    "BaseConfig",
    "$uibModalInstance",
    "Flash",
    "Product",
    "ProductOptionGroup",
    "product",
    "fileQueue",
    "Image",
    "Doc",
    "Upload",
    "$q",
    "Conversion",
    "Pretty",
    "Session",
    "$timeout",
  ];

  constructor(
    public BaseConfig: IBaseConfig,
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public Flash: IFlash,
    public Product: IProductResource,
    public ProductOptionGroup: IProductOptionGroupResource,
    public product: IProduct,
    public fileQueue: IFileQueueFactory,
    public Image: IImageResource,
    public Doc: IDocResource,
    public Upload: ng.angularFileUpload.IUploadService,
    public $q: ng.IQService,
    public Conversion: IConvert,
    public Pretty: IPretty,
    public Session: ISession,
    public $timeout: ng.ITimeoutService,
  ) {
    if (this.product.sku_id === 0) {
      const orgId = useSelector(currentOrgId);
      dispatch(
        Actions.receiveSku({
          item_uuid: this.product.uuid,
          value: "",
          org_id: orgId,
        } as ISkuData),
      );
    }

    this.sortableOptionsImage.stop = () => {
      this.product.images.map(function (i, index) {
        if (i.sort_order !== index) {
          i.sort_order = index;
        }
      });
      this.product.images = _.sortBy(this.product.images, "sort_order");
    };

    this.sortableOptionsDoc.stop = () => {
      this.product.documents.map(function (i, index) {
        if (i.sort_order !== index) {
          i.sort_order = index;
        }
      });
      this.product.documents = _.sortBy(this.product.documents, "sort_order");
    };

    this.Session.can("price_list", "Global").then((permission) => {
      this.showSizes = permission;
    });

    this.$timeout(() => {
      this.focusHere += 1;
    });
  }

  public save() {
    const the_product = this.Product.fromJSON(this.product);
    this.$uibModalInstance.close({
      product: the_product,
    });
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }

  public upload(file, invalidFiles) {
    if (!file) {
      if (invalidFiles.length > 0) {
        // this.trackEvent("upload invalid files", {});
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " + invalidFiles[0].name + ". Upload size limited to 30mb.",
        );
      }
      return;
    }
    this.spinnerPromise = this.fileQueue.getObject(file, this.product, "estimate");
  }

  public delete(file: any) {
    if (!file) {
      return;
    }

    file._destroy = true;
  }

  public loadOptionGroups() {
    this.option_group_response = <IProductOptionGroupResponse>(
      this.ProductOptionGroup.query({ product_id: this.product.id, "include[]": ["options"] })
    );
    this.spinnerPromise = this.option_group_response.$promise.then(() => {
      this.matchLinks();
    });
  }

  public matchLinks() {
    _.each(this.product.ptog_links, (link) => {
      link.group = _.find(this.option_group_response.product_option_groups, (pog) => {
        return pog.id === link.option_group_id;
      });
      link.group.show = false;

      if (_.isUndefined(link.options.default)) {
        link.options.default = {};
      }

      _.each(link.group.options, (opt: any) => {
        opt.default = _.contains(Object.keys(link.options.default), opt.uuid);
        if (opt.default) {
          opt.quantity = link.options.default[opt.uuid];
        } else {
          opt.quantity = 1;
        }
      });
    });
  }

  public defaultCheck(link, opt) {
    if (opt.default) {
      if (link.group.selection_mode === "single") {
        _.each(link.group.options, (opt: any) => {
          opt.default = false;
        });
        link.options.default = {};
        opt.default = true;
      }
      link.options.default[opt.uuid] = opt.quantity;
    } else {
      delete link.options.default[opt.uuid];
    }
  }

  public discountableOverriden() {
    if (this.product.discountable === "default" || this.product.parent_discountable === "default") {
      return false;
    }

    if (this.product.discountable !== this.product.parent_discountable) {
      return true;
    }

    return false;
  }

  public markupableOverriden() {
    if (this.product.markupable === "default" || this.product.parent_markupable === "default") {
      return false;
    }

    if (this.product.markupable !== this.product.parent_markupable) {
      return true;
    }

    return false;
  }

  public showOptions(link, value) {
    link.group.show = value;
  }
}
