import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { EstimateLineItemRecord, splitName } from "app2/src/records/EstimateLineItem";
import { useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { getUrl } from "app2/src/records/Image";
import { estimateLineItemImage } from "app2/src/selectors/estimateLineItem.selectors";

export interface ILineItemProps {
  lineItem: EstimateLineItemRecord;
  activatedPriceListId: number;
}

export default function LineItem(props: ILineItemProps) {
  const { lineItem, activatedPriceListId } = props;

  const name = splitName(lineItem);
  const image = useSelector((state: RootState) =>
    estimateLineItemImage(state, { activatedPriceListId, estimateLineItemId: lineItem.id }),
  );

  return (
    <React.Fragment>
      <Row>
        <Col sm={3} className="line-item-image-container">
          {image && <img className="img-responsive" src={getUrl(image, "small")} />}
        </Col>
        <Col sm={9} className="line-item-text-container">
          {name.map((part, index) => (
            <p key={index} className="line-item-text text-small ellipsify">
              {part}
            </p>
          ))}
        </Col>
      </Row>
      <hr className="line-item-separator" />
    </React.Fragment>
  );
}
