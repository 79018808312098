export const getPayload = (token: string): any => {
  if (!token) {
    return {};
  }

  try {
    const payloadData = atob(token.split(".")[1]);

    return JSON.parse(payloadData);
  } catch (_) {
    return {};
  }
};
