import * as React from "react";
import { connectFC } from "app2/src/connect";
import { Col, Pagination, Row } from "react-bootstrap";
import { useTracking } from "react-tracking";
import { IMetaCountlessPaginationData } from "app2/src/records/MetaPagination";

interface CountlessProps {
  pageChanged: (page: number) => void;
  metadata: IMetaCountlessPaginationData;
}

const NgCountless: React.FC<CountlessProps> = ({ metadata, pageChanged }) => {
  const { trackEvent } = useTracking();

  const handlePageChange = (page: number) => {
    trackEvent({ action: "pagination page changed", page });
    pageChanged(page);
  };

  if (!metadata || (metadata.first_page && _.isNull(metadata.next_page))) {
    return (
      <Row>
        <Col md={12}>
          <Pagination className="justify-content-center">
            <Pagination.Ellipsis />
          </Pagination>
        </Col>
      </Row>
    );
  }

  const { current_page, next_page, prev_page, first_page, last_page } = metadata;

  let preEllipsis = null,
    preArrow = null;
  let postArrow = null;

  const pre = [],
    post = [];

  if (!first_page && prev_page) {
    const preNumber = Math.max(1, current_page - 2);

    for (let i = preNumber; i < current_page; i++) {
      pre.push(
        <Pagination.Item key={i} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }

    if (preNumber > 1) {
      preEllipsis = <Pagination.Ellipsis />;
    }

    preArrow = <Pagination.Prev onClick={() => handlePageChange(prev_page)} />;
  }

  if (next_page) {
    post.push(
      <Pagination.Item key={next_page} onClick={() => handlePageChange(next_page)}>
        {next_page}
      </Pagination.Item>,
    );
  }

  if (!last_page) {
    postArrow = <Pagination.Next onClick={() => handlePageChange(next_page)} />;
  }

  return (
    <Row>
      <Col md={12}>
        <Pagination className="justify-content-center">
          <Pagination.First disabled={first_page} onClick={() => handlePageChange(1)} />
          {preArrow}
          {preEllipsis}
          {pre}
          <Pagination.Item active onClick={() => handlePageChange(current_page)}>
            {current_page}
          </Pagination.Item>
          {post}
          {postArrow}
        </Pagination>
      </Col>
    </Row>
  );
};

export default connectFC(NgCountless);
