import { LoginCtrl } from "app/src/Login/LoginCtrl";
import { ResetPasswordCtrl } from "app/src/Login/ResetPasswordCtrl";
import { ChangePasswordCtrl } from "app/src/Login/ChangePasswordCtrl";
import { PasswordMatch } from "app/src/Login/PasswordMatchDirective";
import { LogoutCtrl } from "app/src/Login/LogoutCtrl";
import { InvitationCtrl } from "./InvitationCtrl";
import UserInvitationContainer from "app2/src/components/UserInvitation/UserInvitationContainer";
import { react2angular } from "react2angular";
import OidcLogin from "app2/src/components/Authentication/OidcLogin";
import OidcCallback from "app2/src/components/Authentication/OidcCallback";
import { TermsAndConditionsCtrl } from "app/src/Login/TermsAndConditionsCtrl";
import { LeadsRedirectComponent } from "app2/src/components/Authentication/LeadsRedirectComponent";

export class LoginRoutes {
  public static load(app: ng.IModule) {
    app.controller("LoginCtrl", LoginCtrl);
    app.controller("LogoutCtrl", LogoutCtrl);
    app.controller("ResetPasswordCtrl", ResetPasswordCtrl);
    app.controller("ChangePasswordCtrl", ChangePasswordCtrl);
    app.controller("InvitationCtrl", InvitationCtrl);
    app.controller("TermsAndConditionsCtrl", TermsAndConditionsCtrl);

    app.component("userInvitation", react2angular(UserInvitationContainer, null, ["$state", "$stateParams"]));
    app.component("lowesLogin", react2angular(OidcLogin, null, ["$stateParams"]));
    app.component("oidcCallback", react2angular(OidcCallback));
    app.component("leadsAuthComponent", react2angular(LeadsRedirectComponent));

    app.directive("passwordMatch", PasswordMatch.factory());
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("root.login", <ng.ui.IState>{
        url: "/login?redirect=",
        views: {
          "container@": {
            templateUrl: "src/Login/login.html",
            controller: "LoginCtrl",
            controllerAs: "ctrl",
          },
        },
        params: {
          routeTo: null,
          routeParams: null,
        },
      })
      .state("root.logout", <ng.ui.IState>{
        url: "/logout?redirect=",
        views: {
          "container@": {
            templateUrl: "src/Login/logout.html",
            controller: "LogoutCtrl",
            controllerAs: "ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.reset_password", <ng.ui.IState>{
        url: "/reset_password",
        views: {
          "container@": {
            templateUrl: "src/Login/reset_password.html",
            controller: "ResetPasswordCtrl",
            controllerAs: "ctrl",
          },
        },
      })
      // existing user changing a password, not logged in.
      .state("root.password_reset", <ng.ui.IState>{
        url: "/password_reset?token=",
        views: {
          "container@": {
            component: "userInvitation",
          },
        },
      })
      // logged in user changing password
      .state("root.change_password", <ng.ui.IState>{
        url: "/change_password",
        views: {
          "container@": {
            templateUrl: "src/Login/change_password.html",
            controller: "ChangePasswordCtrl",
            controllerAs: "ctrl",
          },
        },
        resolve: resolveAuth,
      })
      // existing user created by us claiming the previously existing org
      .state("root.claim_org", <ng.ui.IState>{
        url: "/claim?token=&lead=",
        views: {
          "container@": {
            component: "userInvitation",
          },
        },
      })
      // user created by us just claiming the user
      .state("root.invitation", <ng.ui.IState>{
        url: "/invitation?token=",
        views: {
          "container@": {
            component: "userInvitation",
          },
        },
      })
      .state("root.terms_and_conditions", <ng.ui.IState>{
        url: "/terms",
        views: {
          "container@": {
            templateUrl: "src/Login/terms_and_conditions.html",
            controller: "TermsAndConditionsCtrl",
            controllerAs: "ctrl",
          },
        },
      })
      .state("root.screen_share_help", <ng.ui.IState>{
        url: "/screen-share-help",
        views: {
          "container@": {
            templateUrl: "src/Login/screen_share_help.html",
          },
        },
      })
      .state("root.loweslogin", <ng.ui.IState>{
        url: "/lowes/login",
        views: {
          "container@": {
            component: "lowesLogin",
          },
        },
        params: {
          routeTo: null,
          routeParams: null,
        },
      })
      .state("root.oidccallback", <ng.ui.IState>{
        url: "/auth/oidccallback",
        views: {
          "container@": {
            component: "oidcCallback",
          },
        },
      })
      .state("leads_auth", <ng.ui.IState>{
        url: "/leads_module",

        views: {
          "header@": {},
          "container@": {
            component: "leadsAuthComponent",
          },
        },
      });
  }
}
