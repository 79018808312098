import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { EstimateRecord } from "app2/src/records/Estimate";
import { RootState, RootActions } from "app2/src/reducers";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import * as FontAwesome from "react-fontawesome";
import PaymentOptionsList from "app2/src/components/PriceDrawer/DrawerContainer/Comparator/EstimateView/PaymentOptions/PaymentOptionsList";
import track from "react-tracking";

const mapStateToProps = (state: RootState, ownProps: IPaymentOptionsProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IPaymentOptionsProps {
  orgId: number;
  estimate: EstimateRecord;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IPaymentOptionsProps;

export type SortKey = "name" | "payment" | "term" | "rate";

export interface IPaymentOptionsState {
  sort: SortKey;
  reverse: boolean;
}

@track({ component: "Payment Options" })
class PaymentOptions extends React.Component<Props, IPaymentOptionsState> {
  constructor(props) {
    super(props);

    this.state = {
      sort: "payment",
      reverse: false,
    };

    this.editSort = this.editSort.bind(this);
  }

  @track({ action: "Edit Sort" })
  public editSort(newSort: SortKey) {
    const { sort, reverse } = this.state;

    if (sort === newSort) {
      this.setState({ reverse: !reverse });
    } else {
      this.setState({ sort: newSort, reverse: false });
    }
  }

  public render() {
    const { estimate, orgId } = this.props;
    const { sort, reverse } = this.state;

    return (
      <Row>
        <Col>
          <Row className="payment-options v-align-middle">
            <Col
              sm={12}
              lg={4}
              className="link centered nowrap md-padding-bottom"
              onClick={() => this.editSort("name")}>
              <span className="text-small">
                <strong>Name</strong>
              </span>
              <FontAwesome
                className="sort"
                name={`sort${sort === "name" ? (reverse ? "-down" : "-up") : ""} fa-gray`}
                title="Sort by name"
              />
            </Col>
            <Col sm={4} lg={3} className="link centered nowrap" onClick={() => this.editSort("payment")}>
              <span className="text-small">
                <strong>Payment</strong>
              </span>
              <FontAwesome
                className="sort"
                name={`sort${sort === "payment" ? (reverse ? "-down" : "-up") : ""} fa-gray`}
                title="Sort by payment"
              />
            </Col>
            <Col sm={4} lg={2} className="link centered nowrap" onClick={() => this.editSort("term")}>
              <span className="text-small">
                <strong>Term</strong>
              </span>
              <FontAwesome
                className="sort"
                name={`sort${sort === "term" ? (reverse ? "-down" : "-up") : ""} fa-gray`}
                title="Sort by term"
              />
            </Col>
            <Col sm={4} lg={3} className="link centered nowrap" onClick={() => this.editSort("rate")}>
              <span className="text-small">
                <strong>Rate</strong>
              </span>
              <FontAwesome
                className="sort"
                name={`sort${sort === "rate" ? (reverse ? "-down" : "-up") : ""} fa-gray`}
                title="Sort by rate"
              />
            </Col>
          </Row>
          <hr />
          <PaymentOptionsList orgId={orgId} estimate={estimate} sort={sort} reverse={reverse} />
        </Col>
      </Row>
    );
  }
}

export default connector(PaymentOptions);
