import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import * as jobAttributeActions from "app2/src/reducers/jobAttribute.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { JobAttributeRecordType } from "app2/src/records/JobAttribute";
import { JobAttributeTypes } from "app2/src/records/OrgRecord";
import { jobAttributeByTypeAndId } from "app2/src/selectors/jobAttribute.selectors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { push } from "connected-react-router/immutable";
import { Acl } from "app2/src/helpers/Acl";
import { pathname } from "app2/src/selectors/router.selectors";
import { Spinner } from "app2/src/components/SpinnerComponent";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";

interface IJobAttributeListItemProps {
  jobAttributeId: string | number;
  activeItem: boolean;
  attribute: JobAttributeTypes;
  isDragging?: boolean;
  provided?: any;
  innerRef?: any;
}

export const JobAttributeListItem: React.FC<IJobAttributeListItemProps> = ({
  jobAttributeId,
  provided,
  isDragging,
  innerRef,
  activeItem,
  attribute,
}: IJobAttributeListItemProps) => {
  // Hooks
  const dispatch = useDispatch();
  const path = useSelector(pathname);
  const orgId = useSelector(currentOrgId);
  const jobAttribute = useSelector<RootState, JobAttributeRecordType>((state) =>
    jobAttributeByTypeAndId(state, { jobAttrType: attribute, id: jobAttributeId }),
  );

  // Callbacks

  const showModal = () => dispatch(push(`${path}/edit/${jobAttributeId.toString()}`));

  const removeActiveJobAttribute = (jobAttr: JobAttributeRecordType) => {
    dispatch(jobAttributeActions.AsyncActions.removeJobAttribute(orgId, jobAttr.id, attribute));
  };

  const deleteJobAttribute = () => {
    dispatch(jobAttributeActions.AsyncActions.destroyJobAttribute(orgId, jobAttribute.id, attribute));
  };

  const activateJobAttribute = (jobAttr: JobAttributeRecordType) => {
    dispatch(jobAttributeActions.AsyncActions.activateJobAttribute(orgId, jobAttr.id, attribute));
  };

  const editable = Acl.can("update", "org");

  return jobAttribute ? (
    <Row
      className={`table-row  ${isDragging ? "job-list-object-item-dragging" : "width-100"}`}
      {...(provided || {}).draggableProps}
      ref={innerRef}>
      <Spinner localProperty={jobAttribute.loading} />
      <Col md={1} xs={1}>
        {activeItem && <img {...(provided || {}).dragHandleProps} src="/assets/images/icons/ic_sort.ea6f8933.png" />}
      </Col>
      <Col md={attribute === "job_type" ? 5 : 9} xs={7}>
        {jobAttribute.name}
      </Col>
      {attribute === "job_type" && <Col md={4}>{jobAttribute.get("description", "")}</Col>}
      <Col md={2} xs={3} className="justify-end">
        {jobAttribute.org_id && editable && (
          <Button onClick={showModal} variant="link" title="Edit">
            <i className="rsf-edit-link rsf-base-66" />
          </Button>
        )}
        {jobAttribute.org_id && !activeItem && editable && (
          <ConfirmDialog description="" title={`Are you sure you want to delete: ${jobAttribute.name}?`}>
            {(confirm) => (
              <Button onClick={confirm(() => deleteJobAttribute())} variant="link" title="Delete">
                <i className="rsf-delete-link rsf-base-66" />
              </Button>
            )}
          </ConfirmDialog>
        )}
        {activeItem ? (
          <>
            {editable && (
              <Button onClick={() => removeActiveJobAttribute(jobAttribute)} variant="link" title="Remove Active">
                <i className="rsf-delete-link rsf-base-66" />
              </Button>
            )}
          </>
        ) : (
          <Button variant="link" onClick={() => activateJobAttribute(jobAttribute)} title="Add Active">
            <i className="fa fa-plus" />
          </Button>
        )}
      </Col>
    </Row>
  ) : (
    <div />
  );
};
