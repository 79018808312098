import { StoreRegistry } from "app2/src/storeRegistry";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { MessageView } from "./MessageView";
import * as config from "react-global-configuration";
import { useTracking } from "react-tracking";

export interface EmbeddedViewProps {
  url: string;
  offersRedirect: () => void;
  initialUrl?: string;
  closeHandler?: () => void;
  iframeProps?: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>;
}

export const EmbeddedView: React.FunctionComponent<EmbeddedViewProps> = (props: EmbeddedViewProps) => {
  const { url, offersRedirect, initialUrl, closeHandler, iframeProps } = props;
  const [eventMsg, setEventMsg] = useState(initialUrl || "");
  const tracking = useTracking();

  const handleUrl = useCallback(
    (url: string) => {
      switch (url) {
        case config.get("HOMEVEST_FAILURE"):
          tracking.trackEvent({ action: "Homevest apply failure" });
          setEventMsg(url);
          break;
        case config.get("HOMEVEST_SUCCESS"):
          tracking.trackEvent({ action: "Homevest apply success" });
          setEventMsg(url);
          break;
        case config.get("HOMEVEST_PREQUALFAILURE"):
          tracking.trackEvent({ action: "Homevest prequalification failure" });
          setEventMsg(url);
          break;
        case config.get("HOMEVEST_PREQUALSUCCESS"):
          tracking.trackEvent({ action: "Homevest prequalification success" });
          offersRedirect();
          break;
      }
    },
    [offersRedirect],
  );

  useEffect(() => {
    const globalWindow: Window = StoreRegistry.get("window");

    const listener = (e) => {
      if (e.data?.url) {
        handleUrl(e.data.url);
      }
    };
    globalWindow.addEventListener("message", listener);

    return () => {
      globalWindow.removeEventListener("message", listener);
    };
  }, []);

  switch (eventMsg) {
    case config.get("HOMEVEST_FAILURE"):
      return (
        <MessageView
          message="Sorry, there are no offers at this time."
          imgSrc="/assets/images/icons-large/estimates.022a621b.png"
          analyticsAction="failure"
        />
      );
    case config.get("HOMEVEST_PREQUALFAILURE"):
      return (
        <MessageView
          message="Sorry, there are no offers at this time."
          imgSrc="/assets/images/icons-large/estimates.022a621b.png"
          analyticsAction="prequalfailure"
        />
      );
    case config.get("HOMEVEST_SUCCESS"):
      return (
        <MessageView
          message="You're all done for now!"
          imgSrc="/assets/images/icons-large/estimates.022a621b.png"
          analyticsAction="success"
        />
      );
    default:
      return <iframe src={url} {...(iframeProps || {})} />;
  }
};
