import { IFenetechListQuote, IFenetechQuoteFullResponse } from "app2/src/lib/fene_tech/types";
import { handleErrors } from "app2/src/reducers/Utils";
import { RootDispatchType } from "app2/src/store";

const BASE_URL = "https://secure.fenetech.com/WEB";

const fenetechFetcher = async (
  url: string,
  options: RequestInit = {},
  flashDispatch?: RootDispatchType,
): Promise<any> => {
  const response = await fetch(url, { ...options, mode: "cors", credentials: "include" });

  if (!response) return;

  if (!response.ok) {
    if (response.status === 401) {
      handleErrors(["Invalid credentials."], flashDispatch);
    }
    return;
  }

  const responseBody = await response.text();
  return responseBody ? JSON.parse(responseBody) : {};
};

const login = async (
  baseUrl: string,
  username: string,
  password: string,
  authType = "standard",
  flashDispatch?: RootDispatchType,
): Promise<void> => {
  try {
    const updatedUrl = new URL(`${baseUrl}/api/Auth/SignIn`);
    const params = new URLSearchParams({ username, password, authType });

    return await fenetechFetcher(`${updatedUrl}?${params}`, { method: "GET" }, flashDispatch);
  } catch (response) {
    errorHelper(response, flashDispatch);
  }
};

const getQuoteList = async (baseUrl: string, flashDispatch?: RootDispatchType): Promise<IFenetechListQuote[]> => {
  try {
    const quotesUrl = `${baseUrl}/api/Quotes/List`;

    return await fenetechFetcher(quotesUrl, { method: "GET" }, flashDispatch);
  } catch (response) {
    errorHelper(response, flashDispatch);
  }
};

const getQuoteFull = async (
  baseUrl: string,
  oKey: number,
  flashDispatch?: RootDispatchType,
): Promise<IFenetechQuoteFullResponse> => {
  try {
    const url = `${baseUrl}/api/Quotes/${oKey}/QuoteFull`;

    return await fenetechFetcher(url, { method: "GET" }, flashDispatch);
  } catch (response) {
    errorHelper(response, flashDispatch);
  }
};

const getLineItemImage = async (
  baseUrl: string,
  oKey: number,
  odKey: number,
  imageAccessKey: number,
  flashDispatch?: RootDispatchType,
  isOrder = false,
): Promise<Blob> => {
  try {
    const url = `${baseUrl}/api/Images/Thumbnails/${oKey}/${odKey}/largest?accessKey=${imageAccessKey}&isOrder=${isOrder}`;
    const response = await fetch(url, { method: "GET", mode: "cors", credentials: "include" });
    if (!response.ok) {
      handleErrors(response, flashDispatch);
    }
    const blob = await response.blob();
    // @ts-ignore
    blob.name = `${oKey}_${odKey}.png`;

    return blob;
  } catch (response) {
    errorHelper(response, flashDispatch);
  }
};

const errorHelper = (response: any, flashDispatch?: RootDispatchType) => {
  const errors = response.message ? [response.message] : response;
  handleErrors(errors, flashDispatch);
};

export { login, getQuoteList, getQuoteFull, getLineItemImage, BASE_URL };
