import * as priceListActions from "./priceList.actions";
import { Map, Record } from "immutable";
import { PriceListRecord, fromJSON } from "../records/PriceList";
import { RootActions, RootState } from ".";
import { ActivatedPriceListRecord } from "app2/src/records/ActivatedPriceList";
import * as productActions from "./product.actions";
import { reducer as productReducer } from "./product.reducer";
import * as productOptionGroupActions from "./productOptionGroup.actions";
import { reducer as productOptionGroupReducer } from "./productOptionGroup.reducer";

export const PriceListStateRecord = Record({
  byId: Map<number, PriceListRecord>(),
});

export const initialState = PriceListStateRecord();

export type PriceListState = typeof initialState;

export type PriceListReducerState = RootState | ActivatedPriceListRecord;

export const reducer = (state: PriceListReducerState, action: RootActions): PriceListReducerState => {
  const model = "priceLists";
  if (state && !state.getIn([model])) {
    state = state.setIn([model], initialState);
  }

  let priceList: PriceListRecord;
  switch (action.type) {
    case priceListActions.RECEIVE_PRICE_LIST:
      priceList = fromJSON({ ...action.payload.priceList });

      action.payload.priceList.products.forEach((product) => {
        state = productReducer(state, productActions.Actions.receiveProduct(product));
      });

      action.payload.priceList.product_option_groups.forEach((productOptionGroup) => {
        state = productOptionGroupReducer(
          state,
          productOptionGroupActions.Actions.receiveProductOptionGroup(productOptionGroup),
        );
      });

      return state.setIn([model, "byId", priceList.id], priceList);

    default:
      return state;
  }
};
