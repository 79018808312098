import * as React from "react";
import { jobOverviewEstimates } from "app2/src/selectors/estimate.selectors";
import { currentJobId, selectedEstimateId as selectedEstimateIdSelector } from "app2/src/selectors/job.selectors";
import { estimateTitle, getTitle } from "app2/src/selectors/org.selectors";
import { Button, Col, Row } from "react-bootstrap";
import { RootState } from "app2/src/reducers";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router/immutable";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { StoreRegistry } from "app2/src/storeRegistry";
import { useTracking } from "react-tracking";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import MakePaymentButton from "app2/src/components/PaymentsModal/MakePaymentButton";
import { JobTabTitle } from "../JobTabTitle";
import { EstimateOverviewTable } from "app2/src/components/Estimate/EstimateOverviewTable";
import * as jobActions from "app2/src/reducers/job.actions";

export const SelectProposal: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  // Selectors
  const jobId = useSelector(currentJobId);
  const title = useSelector(estimateTitle);
  const estimatorTitle = useSelector((state: RootState) => getTitle(state, { documentType: "estimator" }));
  const estimates = useSelector((state: RootState) => jobOverviewEstimates(state, { jobId }));
  const selectedEstimateId = useSelector(selectedEstimateIdSelector);

  // Local State
  const [loading, setLoading] = React.useState(false);

  // Methods
  const load = async () => {
    setLoading(true);
    await dispatch(estimateActions.AsyncActions.getJobEstimates(jobId));
    setLoading(false);
  };

  const show = (estimateId: number) => {
    trackEvent({ action: "load proposal", estimate: estimateId });
    dispatch(push(`/jobs/${jobId}/proposals/${estimateId}`));
    dispatch(jobActions.Actions.setSelectedEstimateId(estimateId));
  };

  const goToEstimates = () => {
    trackEvent({ action: "create estimate" });

    const $rootScope: RsfRootScope = StoreRegistry.get("$rootScope");
    dispatch(push("/jobs/" + jobId + "/estimates"));
    $rootScope.$digest();
  };

  // Lifecycle
  React.useEffect(() => {
    if (!selectedEstimateId) load();
  }, []);

  if (estimates?.size === 0 && !loading)
    return (
      <Row>
        <Col>
          <div className="form-section blank-state">
            <img src="/assets/images/icons-large/estimates.022a621b.png" />
            <h2>No estimates added. Let's add one.</h2>
            <Button onClick={goToEstimates}>Go to {estimatorTitle}</Button>
          </div>
        </Col>
      </Row>
    );

  return (
    <>
      <SpinnerComponent localProperty={loading} />
      <JobTabTitle title={title}>
        <div className="float-right">
          <MakePaymentButton />
        </div>
      </JobTabTitle>
      <Row>
        <Col>
          <h2>Select a {title}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <EstimateOverviewTable estimates={estimates} onClickHandler={show} />
        </Col>
      </Row>
    </>
  );
};
