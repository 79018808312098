import * as React from "react";
import { List } from "immutable";
import { Row, Col, Button } from "react-bootstrap";
import { ListManager } from "app2/src/components/Common/ListManager/ListManager";
import { StringTableRow } from "app2/src/components/Common/ListManager/components/StringTableRow";
import { DraggableRowProps } from "app2/src/components/Common/ListManager/components/DragTable";
import { Can } from "app2/src/components/Common/CanComponent";
import ToolRow from "app2/src/components/Tool/ToolRow";
import ToolAdd from "app2/src/components/Tool/ToolAdd";
import { OrgDocTableRow } from "../Common/ListManager/components/OrgDocTableRow";

interface ListProps {
  caption: string;
  name: string;
  list: any[];
  available: any[];
  rowType: string;
  orgId: number;
  updateList(options: any[]): void;
  save?: () => void;
  saveDisabled?: boolean;
}

export default class ListComponent extends React.Component<ListProps> {
  constructor(props: ListProps) {
    super(props);

    this.updateSelected = this.updateSelected.bind(this);
    this.toolAdded = this.toolAdded.bind(this);
  }

  public RenderRow(): new (props: DraggableRowProps) => React.Component<DraggableRowProps> {
    switch (this.props.rowType) {
      case "design_tool":
      case "tool":
        return ToolRow as any;
      case "string":
        return StringTableRow as any;
      case "orgdoc":
        return OrgDocTableRow as any;
    }
  }

  public toolAdded(id: number) {
    const { list } = this.props;
    this.updateSelected(List(list).push(id));
  }

  public updateSelected(options: List<any>) {
    this.props.updateList(options.toArray());
  }

  public render() {
    const { list, available, caption, name, rowType, orgId, save, saveDisabled } = this.props;
    const selected_list = List(list);
    const available_list = List(available);

    return (
      <div className="form-section">
        <div className="form-section-heading">
          <Row>
            <Col>
              <h3>{caption}</h3>
            </Col>
            <Col>
              {rowType !== "orgdoc" && (
                <Can resource="tool" permission="create">
                  <ToolAdd rowType={rowType} name={name} orgId={orgId} toolAdded={this.toolAdded} />
                </Can>
              )}
            </Col>
            <Col>
              {typeof save === "function" && (
                <Button title="list-save" className="pull-right" onClick={save} disabled={saveDisabled}>
                  Save
                </Button>
              )}
            </Col>
          </Row>
        </div>
        <div className="form-section-content">
          <ListManager
            name={name}
            list={selected_list}
            available={available_list}
            orgId={orgId}
            update={this.updateSelected}
            RenderRow={this.RenderRow()}
          />
        </div>
      </div>
    );
  }
}
