export class StripeUnit {
  public static factory() {
    const factoryFunction = (text) => {
      if (!_.isNaN(parseInt(text))) {
        return (parseInt(text) / 100).toFixed(2);
      } else {
        return text;
      }
    };

    factoryFunction.$inject = [];

    return factoryFunction;
  }
}
