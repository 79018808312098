import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEstimate, IEstimateResource } from "app/src/Models/Estimate";
import { BaseEstimateTabCtrl } from "app/src/Jobs/tabs/BaseEstimateTabCtrl";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import * as angulartics from "angulartics";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { JobRecord, fromJSON as jobFromJSON } from "app2/src/records/Job";

interface IProposalTabScope extends ng.IScope {
  pdfUrl: string;
}

export class ProposalTabCtrl extends BaseEstimateTabCtrl {
  public pdfUrl: any;
  public crmButton: boolean;
  public jobRecord: JobRecord;
  public disabledFeatures = ["Annotations"];

  public static $inject = [
    "JobFetcher",
    "$stateParams",
    "BaseConfig",
    "$scope",
    "$rootScope",
    "$state",
    "$analytics",
    "EstimatorService",
    "Flash",
    "$window",
    "$uibModal",
    "Estimate",
    "$http",
    "$timeout",
  ];

  constructor(
    public JobFetcher: IJobFetcherService,
    public $stateParams: ng.ui.IStateParamsService,
    public BaseConfig: IBaseConfig,
    protected $scope: IProposalTabScope,
    private $rootScope: RsfRootScope,
    protected $state: ng.ui.IStateService,
    protected $analytics: angulartics.IAnalyticsService,
    public EstimatorService: EstimatorService,
    public Flash: IFlash,
    public $window: ng.IWindowService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public Estimate: IEstimateResource,
    public $http: ng.IHttpService,
    public $timeout: ng.ITimeoutService,
  ) {
    super(
      JobFetcher,
      EstimatorService,
      $scope,
      $stateParams,
      $state,
      $analytics,
      $window,
      "proposals",
      $uibModal,
      Flash,
      Estimate,
      $http,
      $timeout,
      $stateParams["id"],
    );

    this.job = JobFetcher.get($stateParams["id"]);

    this.job.$promise.then(() => {
      this.jobRecord = jobFromJSON(this.job as any);
      if (!this.EstimatorService.job) {
        this.EstimatorService.job = this.job;
      }
      let estimate = [];
      if (
        this.job &&
        this.job.org &&
        this.job.org.preferences &&
        this.job.org.preferences.config &&
        this.job.org.preferences.config.marketsharp &&
        this.job.org.preferences.config.marketsharp.estimate
      ) {
        estimate = this.job.org.preferences.config.marketsharp.estimate;
      }
      this.crmButton = _.contains(estimate, "button");

      this.spinnerPromise = this._loadEstimatePdf();
    });
  }

  public pdf(estimate, doc_type) {
    if (!doc_type) {
      doc_type = "estimate";
    }
    estimate.job = this.job;
    this.EstimatorService.savePdf(estimate, doc_type);
  }

  public selectEstimateAndLoadPdf(estimate: IEstimate) {
    this.selectEstimate(estimate);
  }

  public attachMarketsharp() {
    this.trackEvent("attach proposal to marketsharp", {
      category: "Proposals",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "estimate" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to Contact in MarketSharp");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public attachSalesforce() {
    this.trackEvent("attach proposal to salesforce", {
      category: "Proposals",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "estimate" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to Opportunity in Salesforce");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public attachJobNimbus() {
    this.trackEvent("attach proposal to job nimbus", {
      category: "Proposals",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "estimate" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to Job Nimbus");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public attachJobprogress() {
    this.trackEvent("attach proposal to jobprogress", {
      category: "Proposals",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "estimate" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to JobProgress");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public attachLeadPerfection() {
    this.trackEvent("attach proposal to LeadPerfection", {
      category: "Proposals",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "estimate" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to LeadPerfection");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  protected _loadEstimatePdf() {
    if (!this.selectedEstimateId || !this.$stateParams["estimate_id"]) {
      return;
    }
    this.trackEvent("load proposal", {
      category: "Proposals",
      estimate: this.selectedEstimateId,
    });
    return this.EstimatorService.generatePdf(this.selectedEstimateId, "estimate").then((data: any) => {
      this.pdfUrl = URL.createObjectURL(data.response);
    });
  }
}
