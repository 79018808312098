import { ActionsUnion, createAction } from "./Utils";
import { INoteData, NoteRecord } from "app2/src/records/Note";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { RootDispatchType } from "app2/src/store";
import { load, updateOrCreate, destroy } from "../api/note.service";
import { handleErrors } from "app2/src/reducers/Utils";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { QueryParamsRecord, IPageData } from "../records/Page";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { List } from "immutable";
import { UserRecord } from "../records/UserRecord";

export const RECEIVE_NOTE = "@notes/RECEIVE_NOTE";
export const RECEIVE_NOTES = "@notes/RECEIVE_NOTES";
export const REMOVE_NOTE = "@notes/REMOVE_NOTE";
export const RESET_NOTE = "@notes/RESET_NOTE";
export const EDIT_NOTE = "@notes/EDIT_NOTE";
export const SET_UP_NEW_NOTE = "@notes/SET_UP_NEW_NOTE";

export const Actions = {
  receiveNote: (note: Partial<INoteData>) => createAction(RECEIVE_NOTE, { note }),
  receiveNotes: (notes: Partial<INoteData>[]) => createAction(RECEIVE_NOTES, { notes }),
  removeNote: (noteId: number) => createAction(REMOVE_NOTE, { noteId }),
  resetNote: (noteId: number) => createAction(RESET_NOTE, { noteId }),
  editNote: (noteId: number, note: string) => createAction(EDIT_NOTE, { noteId, note }),
  setUpNewNote: (jobId: number, user: UserRecord) => createAction(SET_UP_NEW_NOTE, { jobId, user }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  loadNotes: (jobId: number, queryParams: QueryParamsRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(paginationActions.Actions.fetchPage("note", queryParams));
      try {
        const response = await load(jobId, queryParams);
        dispatch(Actions.receiveNotes(response.notes));
        const pageData: Partial<IPageData> = {
          ids: List(response.notes.map((note: INoteData) => note.id)),
          pagination: response.meta.pagination,
        };
        dispatch(paginationActions.Actions.receivePage("note", queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response, dispatch);
        dispatch(paginationActions.Actions.receivePageError("note", queryParams, errors));
      }
    };
  },
  updateOrCreateNote: (note: NoteRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const response = await updateOrCreate(note);
        if (note.id > 0) {
          dispatch(paginationActions.Actions.removeId(note.id, "note"));
        }
        dispatch(Actions.receiveNote(response.note));
        dispatch(paginationActions.Actions.prependId(response.note.id, "note"));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved note successfully!"));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  destroyNote: (note: NoteRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        await destroy(note);
        dispatch(Actions.removeNote(note.id));
        await dispatch(AsyncActions.checkPageEmpty(note.noteable_id));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Note deleted successfully!"));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  checkPageEmpty: (jobId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      return paginationActions.checkPageEmpty(getState(), "note", (newQueryParams) =>
        dispatch(AsyncActions.loadNotes(jobId, newQueryParams)),
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
