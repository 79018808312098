import { fetcher } from "../helpers/Fetcher";
import { IProductProcessData } from "app2/src/records/ProductProcessRecord";

interface IProductProcessesResponse {
  list: any;
}

class ProductService {
  public url_id = "api/v1/products/:id";
  public url = "api/v1/products";

  public loadProcesses(): Promise<IProductProcessData> {
    const actionUrl = fetcher.joinUrls(this.url, "processes");

    const response = fetcher.get<IProductProcessesResponse>(actionUrl);
    return response;
  }

  public process(product_id: number, params: any): Promise<any> {
    const actionUrl = fetcher.joinUrls(this.url_id, "process").replace(":id", product_id.toString());

    const response = fetcher.post<any>(actionUrl, params);
    return response;
  }
}

export const productService = new ProductService();
