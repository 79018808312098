import { JobTabTitle } from "app2/src/components/JobTabs/JobTabTitle";
import { RootState } from "app2/src/reducers";
import { document } from "app2/src/selectors/document.selectors";
import { push } from "connected-react-router/immutable";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import PdfDisplay from "../../Pdf/PdfDisplay";

export const ShowPdf: React.FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<{ documentId: string }>();

  const pdfUrl = useSelector(
    (state: RootState) => document(state, { documentId: parseInt(match?.params.documentId) })?.url,
  );

  const navigateBack = () => {
    const prevPath = match.path.split("/view_pdf")[0];
    dispatch(push(prevPath));
  };

  React.useEffect(() => {
    if (_.isUndefined(pdfUrl)) navigateBack();
  }, [pdfUrl]);

  return (
    <Row className="signatures-show-pdf">
      <Col>
        <JobTabTitle title="Signatures">
          <Button className="pull-right" onClick={navigateBack}>
            Back to All
          </Button>
        </JobTabTitle>
        <PdfDisplay pdfUrl={pdfUrl} disabledFeatures={["Annotations"]} />
      </Col>
    </Row>
  );
};
