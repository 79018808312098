import { RootState } from "app2/src/reducers";
import * as React from "react";
import { connectFC } from "app2/src/connect";
import { Row, Col, Modal, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "app2/src/reducers/components/integrationsView.actions";
import { AddressShow } from "../../Address/AddressShow";
import { AddressRecord } from "app2/src/records/Address";
import * as reportActions from "app2/src/reducers/report.actions";
import { fromJSON as reportFromJson } from "app2/src/records/Report";
import { currentUser } from "app2/src/selectors/user.selectors";
import { currentJob } from "app2/src/selectors/job.selectors";
import { JobRecord } from "app2/src/records/Job";
import { firstName as firstNameParser, lastName as lastNameParser } from "app2/src/services/string.service";
import { fromJSON } from "app2/src/records/PhoneNumber";
import { useTracking } from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";
import { plnarPricing, plnarPricingLoading } from "app2/src/selectors/report.selectors";
import { StoreRegistry } from "app2/src/storeRegistry";
import { IPretty } from "app/src/Common/PrettyNameService";
import ButtonFooter from "../../Common/ButtonFooter";
import { ConfirmDialog } from "../../Common/ConfirmDialog";
import SpinnerComponent from "../../SpinnerComponent";

export interface PlnarReportModalProps {
  address: AddressRecord;
  jobId: number;
}

const PlnarReportModal: React.FC<PlnarReportModalProps> = ({ address }) => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "PlnarReportModal" }, { dispatch: Dispatch.dispatch });

  // Selectors
  const openModal = useSelector((state: RootState) => state.getIn(["components", "integrations", "showPlnarModal"]));
  const user = useSelector(currentUser);
  const job: JobRecord = useSelector(currentJob);
  const pricing = useSelector(plnarPricing);
  const loading = useSelector(plnarPricingLoading);
  const pretty: IPretty = StoreRegistry.get("Pretty");

  // Local State
  const [selectedJobType, setSelectedJobType] = React.useState(null);
  const [projectName, setProjectName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [formAddress, setFormAddress] = React.useState(address);
  const formRef = React.useRef<HTMLFormElement>(null);
  const [showConfirm, setShowConfirm] = React.useState(0);

  // Life Cycle
  React.useEffect(() => {
    if (openModal) {
      dispatch(reportActions.AsyncActions.getPlnarPricing());
    }
  }, [openModal]);

  React.useEffect(() => {
    setFormAddress(address);
  }, [address]);

  React.useEffect(() => {
    // Initialize selectedJobType once pricing is loaded
    if (pricing) {
      setSelectedJobType(pricing.get(0).get("type"));
    }
  }, [pricing]);

  React.useEffect(() => {
    if (job) {
      setFirstName(firstNameParser(job.get("customer_name")));
      setLastName(lastNameParser(job.get("customer_name")));
      setEmail(job.get("email", ""));
      setMobileNumber(
        job
          .get("phone_numbers")
          .first(fromJSON({ number: "" }))
          .get("number", ""),
      );
    }
  }, [job]);

  // Methods
  const close = () => {
    trackEvent({ action: "close plnar modal" });
    dispatch(Actions.closePlnarModal());
  };

  const request = async () => {
    trackEvent({ action: "request plnar report" });
    try {
      dispatch(
        reportActions.AsyncActions.addJobReport(
          job.get("id"),
          reportFromJson({
            kind: "interior",
            provider: "plnar",
            job_id: job.get("id"),
            user_id: user.get("id"),
            order_data: {
              name: projectName,
              type: "PLNAR Snap",
              jobType: selectedJobType,
              address: {
                address1: formAddress.get("line_1"),
                address2: formAddress.get("line_2", ""),
                city: formAddress.get("city"),
                stateProvince: formAddress.get("state"),
                postalCode: formAddress.get("postal_code"),
                country: "USA",
              },
              user: {
                email: user.get("email"),
                firstName: user.get("first_name"),
                lastName: user.get("last_name"),
              },
              reporter: {
                firstName,
                lastName,
                mobileNumber,
                email,
              },
              referenceNumber,
              notifications: true,
              communication: {
                personalization: true,
                orgBranding: true,
              },
            },
          }),
        ),
      );
    } catch (errors) {
      console.error(errors);
    }
    dispatch(Actions.closePlnarModal());
  };

  return (
    <>
      <SpinnerComponent localProperty={loading} />
      <Modal show={openModal} onHide={close} backdrop={"static"} size="lg" className="import-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>SmartInteriors&trade; Room Measurement Request</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef}>
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <FormLabel>
                        <span className="asterisk">*</span>&nbsp;Project Name
                      </FormLabel>
                      <FormControl
                        type="text"
                        id="project_name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <FormLabel htmlFor="job_type">
                        <span className="asterisk">*</span>&nbsp;Report Type
                      </FormLabel>
                      <FormControl
                        as="select"
                        type="text"
                        id="job_type"
                        value={selectedJobType}
                        onChange={(evt) => setSelectedJobType(evt.currentTarget.value)}
                        required>
                        {pricing?.toJS()?.map(({ type, display }) => {
                          return (
                            <option key={type} value={type}>
                              {pretty.name[type]} ({display})
                            </option>
                          );
                        })}
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <h5 className="font-weight-bold">Customer Information</h5>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <FormLabel>
                        <span className="asterisk">*</span>&nbsp;First Name
                      </FormLabel>
                      <FormControl
                        type="text"
                        id="first_name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <FormLabel>
                        <span className="asterisk">*</span>&nbsp;Last Name
                      </FormLabel>
                      <FormControl
                        type="text"
                        id="last_name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <FormLabel>
                        <span className="asterisk">*</span>&nbsp;Email
                      </FormLabel>
                      <FormControl
                        type="text"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <FormLabel>
                        <span className="asterisk">*</span>&nbsp;Mobile Number
                      </FormLabel>
                      <FormControl
                        type="text"
                        id="mobile_number"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <FormLabel>Reference Number</FormLabel>
                      <FormControl
                        type="text"
                        id="reference_number"
                        value={referenceNumber}
                        onChange={(e) => setReferenceNumber(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <h5 className="font-weight-bold">Address Information</h5>
                {formAddress && <AddressShow update={(address) => setFormAddress(address)} address={formAddress} />}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>This will be billed based upon your arrangement with One Click Contractor.</div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <ConfirmDialog
          title="This will be billed based upon your arrangement with One Click Contractor. Are you sure you wish to continue?"
          onConfirm={request}
          openDialog={showConfirm}
        />
        <Modal.Footer>
          <ButtonFooter
            cancel={close}
            update={() => {
              if (formRef.current.reportValidity()) {
                setShowConfirm(showConfirm + 1);
              }
            }}
            saveText="Request"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connectFC(PlnarReportModal);
