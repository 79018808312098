import { FormControl, RsfForm, Select } from "@tberrysoln/rsf-form";
import { connectFC } from "app2/src/connect";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import * as orgActions from "app2/src/reducers/org.actions";
import { useSelector } from "react-redux";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { validateBaseUrl } from "app2/src/helpers/FinalFormValidator";

export interface DashboardProps {
  updateSettingsConfig: (key: string, value: any) => void;
}

const Dashboard: React.FC<DashboardProps> = ({ updateSettingsConfig }) => {
  const orgId = useSelector(currentOrgId);

  const handleChange = (event) => {
    const { name, value } = event;
    updateSettingsConfig(name, value);
    return orgActions.Actions.updateForm(event);
  };

  return (
    <div className="form-section">
      <div className="form-section-heading">
        <h3>Dashboard</h3>
      </div>
      <div className="form-section-content">
        <Row>
          <Col md="12">
            <RsfForm
              rootPath={["orgs", "orgsById", orgId, "settings", "config"]}
              updateFormReducer={handleChange}
              onSubmit={() => null}>
              <Row>
                <Col md="3">
                  <FormControl
                    label="Communication URL"
                    name="dashboard.communication_url"
                    validate={validateBaseUrl}
                  />
                </Col>
              </Row>
            </RsfForm>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default connectFC(Dashboard);
