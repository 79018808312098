import * as React from "react";
import { WebhookEventRecord } from "app2/src/records/WebhookEvent";
import { DateFormat } from "app2/src/helpers/Format";
import { HistoryItemDetailsModal } from "./HistoryItemDetailsModal";

export interface IHistoryItemProps {
  event: WebhookEventRecord;
}

export const HistoryItem: React.FC<IHistoryItemProps> = ({ event }) => {
  // state

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const latestAttempt = React.useMemo(() => {
    if (event.tries && event.tries.get("attempts")) {
      return event.tries.get("attempts").reduce((latest, current) => {
        const currentTimestamp = new Date(current.get("attempted_at")).getTime();
        const latestTimestamp = new Date(latest.get("attempted_at")).getTime();
        return currentTimestamp > latestTimestamp ? current : latest;
      });
    } else return null;
  }, [event]);

  return (
    <>
      {latestAttempt ? (
        <>
          <tr key={event.id}>
            <td>{event.id}</td>
            <td>{DateFormat(event.created_at)}</td>
            <td>{_.toTitleCase(event.payload.get("event_type").replaceAll(".", "_"))}</td>
            <td>{latestAttempt.get("endpoint")}</td>
            <td>
              <span>{latestAttempt.get("status")}</span>
            </td>
            <td>
              <span>
                <i className="fa fa-expand" onClick={() => setShowModal(true)}></i>
              </span>
            </td>
          </tr>
          <HistoryItemDetailsModal
            url={latestAttempt.get("endpoint")}
            event={event}
            showModal={showModal}
            onClose={() => setShowModal(false)}
          />
        </>
      ) : null}
    </>
  );
};
