import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { PaySimple, PaySimpleError, PaySimpleAccountInfo, PaySimpleFormValidity } from "./PaySimple.service";
import * as paysimpleActions from "app2/src/reducers/integrations/paysimple.actions";
import * as paymentActions from "app2/src/reducers/payment.actions";
import _track, { Track, TrackingProp } from "react-tracking";
import { AccountInfoRecord } from "../AccountInfo.model";
import { IUser } from "app/src/Models/User";
import { IOrg } from "app/src/Models/Org";
import { IJob } from "app/src/Models/Job";
import { IEstimate } from "app/src/Models/Estimate";
import { TrackingData } from "app2/src/helpers/Analytics";
import { IMakePaymentData } from "app2/src/records/PaymentRecord";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";
import { Form } from "react-bootstrap";

const mapStateToProps = (state: RootState, ownProps: IPaySimpleFormProps) => {
  const token = state.getIn(["integrations", "paysimple", "token"], null);

  return {
    currentUser: denormalizedReduxUser(state) as any as IUser,
    fetchingToken: state.getIn(["integrations", "paysimple", "fetchingToken"]),
    checkoutToken: token ? token.toJS() : token,
    checkoutTokenError: state.getIn(["integrations", "paysimple", "token_error"], ""),
    payment: state.getIn(["integrations", "paysimple", "payment"], null),
    providerSubmitting: state.getIn(["payments", "providerSubmitting"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IPaySimpleFormProps) => {
  const { resolve } = ownProps;
  return {
    loadCheckoutToken: () => {
      dispatch(paysimpleActions.AsyncActions.getCheckoutToken(resolve.org.id));
    },
    setPaySimpleError: (error: string) => {
      dispatch(paysimpleActions.Actions.receiveGeneralError(error));
    },
    makePayment: (orgId: number, accountInfo: PaySimpleAccountInfo, payment: IMakePaymentData) => {
      return dispatch(paysimpleActions.AsyncActions.makePayment(orgId, accountInfo, payment));
    },
    setPaymentError: (error: string) => {
      return dispatch(paymentActions.Actions.paymentError(error));
    },
    setProviderValid: (valid: boolean) => {
      return dispatch(paymentActions.Actions.providerValid(valid));
    },
    setProviderLoading: (loading: boolean) => {
      return dispatch(paymentActions.Actions.providerLoading(loading));
    },
    setProviderSubmitting: (submitting: boolean) => {
      return dispatch(paymentActions.Actions.providerSubmitting(submitting));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
});

interface IPaySimpleFormProps {
  resolve: { org: IOrg; job: IJob; estimate: IEstimate };
  accountInfo: AccountInfoRecord;
  tracking?: TrackingProp;
}

const track: Track<TrackingData, IPaySimpleFormProps> = _track;

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IPaySimpleFormProps;

interface IFormMutabaleState {
  paymentType: string;
}
export interface IPaySimpleFormState extends IFormMutabaleState {
  paySimpleLoaded: boolean;
  paySimpleInitialized: boolean;
  paySimpleFormValid: boolean;
}

@track()
class PaySimpleForm extends React.Component<Props, IPaySimpleFormState> {
  protected paySimple: PaySimple;
  private _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      paySimpleLoaded: false,
      paySimpleInitialized: false,
      paySimpleFormValid: false,
      paymentType: "cc",
    };

    this.handleAccountRecieved = this.handleAccountRecieved.bind(this);
    this.handleError = this.handleError.bind(this);
    this.paySimpleFormValidationChanged = this.paySimpleFormValidationChanged.bind(this);
  }

  public componentDidMount() {
    const { loadCheckoutToken } = this.props;

    loadCheckoutToken();

    this._isMounted = true;
    PaySimple.loadJs().then(() => {
      if (this._isMounted) {
        this.setState({
          paySimpleLoaded: true,
        });
      }
    });
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public componentDidUpdate(prevProps) {
    const { checkoutToken } = this.props;
    const { paySimpleLoaded, paySimpleInitialized, paymentType } = this.state;

    if (checkoutToken !== null && paySimpleLoaded && !paySimpleInitialized) {
      this.paySimple = new PaySimple("#psjs", checkoutToken);
      this.paySimple.askForPaymentOptions(paymentType);
      this.paySimple.errorHandler = this.handleError;
      this.paySimple.customerCallback = this.handleAccountRecieved;
      this.paySimple.formCallback = this.paySimpleFormValidationChanged;
      this.setState({
        paySimpleInitialized: true,
      });
    }

    if (!prevProps.providerSubmitting && this.props.providerSubmitting) {
      this.submit();
    }
  }

  handleInput = (event) => {
    const name = event.target.name as keyof IFormMutabaleState;
    const value = event.target.value;
    this.setState((): IFormMutabaleState => {
      return { [name]: value };
    });
  };

  handlePaymentTypeChange = (event) => {
    this.handleInput(event);
    this.paySimple.askForPaymentOptions(event.target.value);
  };

  public render() {
    const { paymentType } = this.state;
    return (
      <div className="psjs">
        <Form.Group>
          <Form.Label>Payment Method</Form.Label>
          <Form.Check
            name="paymentType"
            type="radio"
            id="paymentType-cc"
            checked={paymentType === "cc"}
            value="cc"
            label="Credit Card"
            onChange={this.handlePaymentTypeChange}
            required></Form.Check>
          <Form.Check
            name="paymentType"
            type="radio"
            id="paymentType-ach"
            checked={paymentType === "ach"}
            value="ach"
            label="ACH"
            onChange={this.handlePaymentTypeChange}
            required></Form.Check>
        </Form.Group>
        <div id="psjs">{/* a PaySimpleJS Payment Form will be inserted here */}</div>
      </div>
    );
  }

  public submit() {
    const { setProviderValid, accountInfo } = this.props;
    const { paySimpleFormValid } = this.state;
    const customer = {
      firstName: accountInfo.firstName,
      lastName: accountInfo.lastName,
      email: accountInfo.email,
    };

    if (!paySimpleFormValid) {
      setProviderValid(false);
      return;
    } else {
      setProviderValid(true);
    }

    this.paySimple.sendCustomer(customer);
  }

  private handleError(error: PaySimpleError) {
    const { tracking, setPaySimpleError, accountInfo } = this.props;
    const errorMsg = _.chain(error.errors)
      .map((e) => `${e.field}: ${e.message}`)
      .join(", ")
      .value();

    tracking.trackEvent({
      action: "Submitted with Errors",
      error: errorMsg,
      amount: accountInfo.amount,
      description: accountInfo.description,
      firstName: accountInfo.firstName,
      lastName: accountInfo.lastName,
      email: accountInfo.email,
    });
    setPaySimpleError(errorMsg);
  }

  private handleAccountRecieved(psAccountInfo: PaySimpleAccountInfo) {
    const { makePayment, accountInfo, resolve, currentUser, tracking } = this.props;

    const eventData = {
      amount: accountInfo.amount,
      description: accountInfo.description,
      firstName: accountInfo.firstName,
      lastName: accountInfo.lastName,
      email: accountInfo.email,
    };

    const payment: IMakePaymentData = {
      first_name: accountInfo.firstName,
      last_name: accountInfo.lastName,
      email: accountInfo.email,
      description: accountInfo.description,
      amount: accountInfo.amount,
      job_id: resolve.job.id,
      user_id: currentUser.id,
      estimate_id: resolve.estimate ? resolve.estimate.id : null,
    };

    tracking.trackEvent({
      action: "Submitted",
      ...eventData,
    });
    makePayment(resolve.org.id, psAccountInfo, payment).then(
      () => {
        tracking.trackEvent({
          action: "Authorized",
          ...eventData,
        });
      },
      (error) => {
        tracking.trackEvent({
          action: "Error",
          message: error,
          ...eventData,
        });
      },
    );
  }

  private paySimpleFormValidationChanged(data: PaySimpleFormValidity) {
    const { setProviderValid } = this.props;
    this.setState({
      paySimpleFormValid: data.validity,
    });

    setProviderValid(data.validity);
  }
}

export default connector(PaySimpleForm);
