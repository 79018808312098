import { StoreRegistry } from "../storeRegistry";
import { RootStoreType } from "../store";
import { RootState } from "../reducers";

class Subscriber {
  protected subscribers: { string: ((data: any) => void)[] } = {} as { string: ((data: any) => void)[] };
  private _store: RootStoreType;
  private _prevState: RootState;

  constructor() {
    this._store = StoreRegistry.getStore();
    this._store.subscribe(this.callback);
    this._prevState = this._store.getState();
  }

  public subscribe<T>(path: string, fn: (data: T) => void): () => void {
    if (this.subscribers.hasOwnProperty(path)) {
      this.subscribers[path].push(fn);
    } else {
      this.subscribers[path] = [fn];
    }

    // return "unsubscribe" function
    return () => {
      this.subscribers[path] = this.subscribers[path].filter((s) => s !== fn);
    };
  }

  public reset() {
    this.subscribers = {} as { string: ((data: any) => void)[] };
  }

  protected callback = () => {
    const newState = this._store.getState();

    Object.keys(this.subscribers).forEach((key) => {
      const path = key.split(".");

      if (this._prevState.getIn(path) !== newState.getIn(path)) {
        this.subscribers[key].forEach((cb) => cb(newState.getIn(path)));
      }
    });

    this._prevState = newState;
  };
}

export const subscriber = new Subscriber();
