import { ICustomProduct } from "app/src/Models/Product";
import { IEstimateLineItem, EstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { ICommand } from "app/src/Commands/Command";
import { EstimatorService } from "app/src/Estimator/EstimatorService";

export class AddEstimateLineItemCmd implements ICommand {
  public lineItem: IEstimateLineItem;
  constructor(
    public estimate: IEstimate,
    public product: any,
    public group: IEstimateGroup,
    public EstimatorService: EstimatorService,
  ) {}

  public execute(): boolean {
    this.lineItem = new EstimateLineItem(this.group);

    if (!_.isString(this.product)) {
      if (this.product.id) {
        this.lineItem.quantity = this.EstimatorService.addMeasurementLink(
          this.product,
          undefined,
          undefined,
          this.group,
        );
        this.lineItem.setProduct(this.estimate.activated_price_list_id, this.product);
        this.lineItem.calculate(this.estimate);
      } else {
        this.lineItem.setCustomProduct(<ICustomProduct>this.product);
        this.lineItem.calculate(this.estimate);
      }
    } else {
      this.lineItem.name = "";
      this.lineItem.description = "";
      this.lineItem.quantity = 1;
      this.lineItem.price = 0;
      this.lineItem.base_product_price = 0;
      this.lineItem.base_labor_price = 0;
      this.lineItem.markupable = "yes";
      this.lineItem.ext_price = 0;
    }

    const max_line_item = _.max(this.group.line_items, (li) => {
      return li.sort_order;
    });
    if ((max_line_item as any as number) === -Infinity) {
      this.lineItem.sort_order = 0;
    } else {
      this.lineItem.sort_order = max_line_item.sort_order + 1;
    }

    this.lineItem.id = this.estimate.getLineItemId();
    this.lineItem.newly_added = true;
    this.lineItem.estimateGroup = this.group;

    this.group.line_items.push(this.lineItem);
    return true;
  }
}
