import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as documentActions from "app2/src/reducers/document.actions";
import * as folderActions from "app2/src/reducers/folder.actions";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { Row, Col } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { Sortable } from "app2/src/components/Common/Sortable";
import Document from "app2/src/components/Document";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import TableHeader from "app2/src/components/JobTabs/DocumentTab/Documents/TableHeader";
import track from "react-tracking";
import { queryNumber } from "app2/src/selectors/router.selectors";
import Folders from "app2/src/components/Folders";
import { replace } from "connected-react-router/immutable";
import { currentJobId } from "app2/src/selectors/job.selectors";

const mapStateToProps = (state: RootState) => {
  const modelName = "document",
    docQueryParams = queryParams(state, { modelName });
  const page = docQueryParams.get("page");
  const documentIds = ids(state, { modelName });
  return {
    queryFolderParentId: queryNumber(state, { queryKey: "folder_parent_id" }),
    documentIds,
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    docQueryParams,
    jobId: currentJobId(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: DocumentsProps) => {
  return {
    listFolders: (folderParentId: number, jobId: number) =>
      dispatch(
        folderActions.AsyncActions.listFolders(folderParentId, { folderable_type: "Job", name: "Documents" }, () =>
          replace(`/jobs/${jobId}/documents`),
        ),
      ),
    listDocuments: (queryParams: QueryParamsRecord) =>
      dispatch(
        documentActions.AsyncActions.listDocuments(
          {
            documentableType: "job",
            signed: "unsigned",
            folder_id: ownProps.folderParentId,
          },
          queryParams,
        ),
      ),
    replace: (path: string) => dispatch(replace(path)),
    onSortEnd: (oldIndex: number, newIndex: number) =>
      dispatch(documentActions.AsyncActions.onSortEnd(oldIndex, newIndex)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DocumentsProps {
  folderParentId: number;
  navigate: (folderId: number) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DocumentsProps;

@track(() => {
  return {
    component: "Documents",
  };
})
export class Documents extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.queryDocuments = this.queryDocuments.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  public componentDidMount(): void {
    const { listFolders, jobId, queryFolderParentId } = this.props;
    listFolders(queryFolderParentId, jobId);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { listDocuments, folderParentId } = this.props;
    if (!_.isNullOrUndefined(folderParentId) && prevProps.folderParentId !== folderParentId) {
      listDocuments(new QueryParamsRecord());
    }
  }

  public navigate(folderId: number): void {
    const { jobId, navigate, listFolders } = this.props;
    navigate(folderId);
    listFolders(folderId, jobId);
  }

  @track((_props, _state, [page]) => ({
    action: "queryDocuments",
    page: page,
  }))
  public queryDocuments(page: number): void {
    const { listDocuments, docQueryParams } = this.props;
    const newQueryParams = docQueryParams.setIn(["page"], page);

    listDocuments(newQueryParams);
  }

  @track((props, _state, [oldIndex, newIndex]) => ({
    action: "onDragEnd",
    oldIndex: oldIndex,
    newIndex: newIndex,
    documents: props.documentIds.toArray(),
  }))
  public onDragEnd(oldIndex: number, newIndex: number): void {
    const { onSortEnd } = this.props;

    onSortEnd(oldIndex, newIndex);
  }

  public render(): React.ReactNode {
    const { documentIds, page, pagination } = this.props;

    if (page?.loading) {
      return <SpinnerComponent localProperty={true} />;
    }

    return (
      <>
        <Folders {...this.props} documentableType="job" fileType="documents" navigate={this.navigate} />
        <br />
        {_.isNullOrUndefined(page) || documentIds.size === 0 ? (
          <Row>
            <Col>
              <div className="form-section blank-state">
                <img src="/assets/images/icons-large/docs.33cddf3f.png" />
                <h2>No documents added. Let's add one.</h2>
                <p>
                  Click on the add document button in the top right and then select your file. You can upload the
                  following file types: <br />
                  .pdf, .xls, .xlsx, .csv, .xml, .json, .zip, .window, .dxf, .skp, .dwg, .gltf, .doc, .docx, .odt, and
                  .ods.
                </p>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <div className="standard-rl-margin documents-table">
                <TableHeader documentableType="job" documentIds={documentIds} />
                <Sortable
                  dragHandle
                  items={documentIds}
                  onDragEnd={this.onDragEnd}
                  renderItem={({ item, dragHandle }) => {
                    return <Document documentId={item} documentableType="job" dragHandle={dragHandle} allowDestroy />;
                  }}
                />
                <StandardPagination metadata={pagination} pageChanged={this.queryDocuments} />
                <br />
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default connector(Documents);
