import * as React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { RootState } from "app2/src/reducers";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import { connect, ConnectedProps } from "app2/src/connect";
import { currentJob } from "app2/src/selectors/job.selectors";
import { sendInviteModal, sendInviteType } from "app2/src/selectors/components/common.selectors";
import { RootDispatchType } from "app2/src/store";
import { IInviteArgs } from "app2/src/api/job.service";
import { Form as FinalForm } from "react-final-form";
import { RsfFormGroup } from "app2/src/components/RsfForms/RsfFormGroup";
import { validateEmail, validatePhoneNumber } from "app2/src/helpers/InputValidator";
import { SelectMobilePhoneNumber } from "app2/src/helpers/Format";
import TroubleshootingModal from "app2/src/components/ScreenShare/TroubleshootingModal";
import track, { TrackingProp } from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";

const mapStateToProps = (state: RootState, ownProps: InviteModalProps) => {
  return {
    sendInviteModal: sendInviteModal(state),
    sendInviteType: sendInviteType(state),
    job: currentJob(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: InviteModalProps) => {
  return {
    closeSendInviteModal: (sent: boolean) => dispatch(commonActions.Actions.closeSendInviteModal(sent)),
    invite: (args: IInviteArgs) => dispatch(jobActions.AsyncActions.invite(args)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface InviteModalState {
  openScreenShareHelp: number;
}

interface InviteModalProps {
  tracking?: TrackingProp;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & InviteModalProps;

@track(
  (props: Props) => {
    return {
      component: "InviteModal",
      sendInviteType: props.sendInviteType,
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
class InviteModal extends React.Component<Props, InviteModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openScreenShareHelp: 0,
    };

    this.openScreenShareHelp = this.openScreenShareHelp.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
    this.submitText = this.submitText.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { tracking, sendInviteModal } = this.props;
    if (!prevProps.sendInviteModal && sendInviteModal) {
      tracking.trackEvent({ action: "open invite modal" });
    }
  }
  @track(() => ({
    action: "close invite modal",
  }))
  public closeModal(): void {
    this.props.closeSendInviteModal(false);
  }

  @track((props: Props) => ({
    action: `${props.sendInviteType} email invite sent`,
  }))
  public submitEmail(formData) {
    const { invite, sendInviteType, closeSendInviteModal } = this.props;

    invite({ invite_type: sendInviteType, invite_method: "email", emails: formData.emails });
    closeSendInviteModal(true);
  }

  @track((props: Props) => ({
    action: `${props.sendInviteType} sms invite sent`,
  }))
  public submitText(formData) {
    const { invite, sendInviteType, closeSendInviteModal } = this.props;

    invite({ invite_type: sendInviteType, invite_method: "sms", emails: formData.emails });
    closeSendInviteModal(true);
  }

  @track(() => ({
    action: "open screen share help",
  }))
  public openScreenShareHelp() {
    this.setState((state: InviteModalState) => ({ openScreenShareHelp: state.openScreenShareHelp + 1 }));
  }

  public render() {
    const { sendInviteModal, sendInviteType, job, closeSendInviteModal } = this.props;
    const { openScreenShareHelp } = this.state;

    if (!sendInviteModal) {
      return null;
    }

    return (
      <Modal show={sendInviteModal} onHide={() => closeSendInviteModal(false)} size={"fixed" as any} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {sendInviteType === "screenshare" ? <h3>Send Meeting Invite</h3> : null}
            {sendInviteType === "image_uploader" ? <h3>Send Upload Invite</h3> : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FinalForm
            onSubmit={this.submitEmail}
            validate={(values) => {
              const errors = {} as any;
              if (!values.emails) {
                errors.emails = "Required";
              }

              if (!validateEmail(values.emails)) {
                errors.emails = "Improperly formatted emails '_@__.__' (comma separated)";
              }

              return errors;
            }}
            initialValues={{
              emails: job?.email,
            }}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={9}>
                    <RsfFormGroup label="Emails" name="emails" component="input" id="emailsid" />
                  </Col>

                  <Col sm={3}>
                    <Button variant="default" type="submit" className="dummy-label">
                      Send Email
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          </FinalForm>

          <hr />

          <FinalForm
            onSubmit={this.submitText}
            validate={(values) => {
              const errors = {} as any;
              if (!values.emails) {
                errors.emails = "Required";
              }

              if (!validatePhoneNumber(values.emails)) {
                errors.emails = "Improperly formatted Phone Number. Numeric Only.";
              }
              return errors;
            }}
            initialValues={{
              emails: SelectMobilePhoneNumber(job.phone_numbers),
            }}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={9}>
                    <RsfFormGroup
                      label="Phone Number"
                      name="emails"
                      testId="phonenumber"
                      component="input"
                      id="phonenumberid"
                    />
                  </Col>

                  <Col sm={3}>
                    <Button variant="default" type="submit" className="dummy-label">
                      Send Text
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          </FinalForm>

          {sendInviteType === "screenshare" ? (
            <>
              <hr />
              <span className="login-text">
                <small>
                  Having trouble setting up the Screen Share?&nbsp;
                  <a href="#" onClick={this.openScreenShareHelp}>
                    Click Here
                  </a>
                </small>
              </span>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="cancel" type="button" onClick={this.closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
        {sendInviteType === "screenshare" ? <TroubleshootingModal openModal={openScreenShareHelp} /> : null}
      </Modal>
    );
  }
}

export default connector(InviteModal);
