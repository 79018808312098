import * as $ from "jquery";

export class PasswordMatch {
  public require = "ngModel";

  public link(scope, elem, attrs, ctrl) {
    const mainPassword = "#" + attrs.passwordMatch;
    elem.add(mainPassword).on("keyup", function () {
      scope.$apply(function () {
        ctrl.$setValidity("passwdMatch", elem.val() === $(mainPassword).val());
      });
    });
  }

  public static factory(): ng.IDirectiveFactory {
    const directive = () => new PasswordMatch();
    directive.$inject = [];
    return directive;
  }
}
