import * as uuid from "uuid/v4";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { IAddress } from "app/src/Models/Address";
import { IRepository } from "app/src/Common/Repository";

import * as L from "leaflet";

export interface IRsfMapOptions {
  imagery: string;
  layers: string[];
  defaultZoom: number;
  marker: boolean;
  one_layer: boolean;
  labels: boolean;
}

class RsfMapCtrl implements ng.IComponentController {
  [s: string]: any;
  public options: IRsfMapOptions;
  public defaultOptions = <IRsfMapOptions>{
    imagery: "",
    layers: [],
    defaultZoom: 19,
    marker: true,
    one_layer: false,
    labels: true,
  };
  public map_options: IRsfMapOptions;
  public address: IAddress;
  public addressChanged: (params: any) => void;
  public measureFinished: (params: any) => void;
  public exportData: (params: any) => void;
  public measureData: any[];
  public myMap: any;
  public marker: any;
  public icon: any;

  public static $inject = ["$timeout", "BaseConfig", "Repository"];

  constructor(
    public $timeout: ng.ITimeoutService,
    public BaseConfig: IBaseConfig,
    public Repository: IRepository,
  ) {}

  public initMap() {
    if (this.address) {
      const layers = [];
      if (this.map_options.imagery === "nearmap" && _.isString(this.BaseConfig.NEARMAP_API_KEY)) {
        // Nearmap.com Tiles
        const nmMap = L.tileLayer(
          "https://{rc}{number}.nearmap.com/maps?x={x}&y={y}&z={z}&nml={nml}&version={version}&httpauth={httpauth}&apikey={apikey}",
          {
            attribution: 'Imagery &copy; <a href="https://nearmap.com">NearMap</a>',
            maxZoom: 25,
            rc: "us",
            number: "0",
            nml: "v",
            version: 2,
            httpauth: false,
            apikey: this.BaseConfig.NEARMAP_API_KEY,
          } as any,
        );
        layers.push(nmMap);
      } else if (
        this.map_options.imagery === "google" ||
        (this.map_options.imagery === "nearmap" && !_.isString(this.BaseConfig.NEARMAP_API_KEY))
      ) {
        const googleSatellite = L.gridLayer.googleMutant({
          type: "satellite",
        });
        layers.push(googleSatellite);
      }

      const googleRoadonly = L.gridLayer.googleMutant({
        type: "roadmap",
        styles: [
          { featureType: "geometry", stylers: [{ visibility: "off" }] },
          { featureType: "road", stylers: [{ visibility: "on" }] },
          {
            featureType: "administrative.land_parcel",
            elementType: "geometry.stroke",
            stylers: [{ visibility: "on" }],
          },
        ],
      });
      layers.push(googleRoadonly);

      this.myMap = L.map("rsf-map", <L.MapOptions>{ layers: layers }).setView(
        [this.address.lat, this.address.lon],
        this.map_options.defaultZoom,
      );

      if (this.map_options.marker) {
        this.initMarker();
        this.setMarker();
      }

      if (_.contains(this.map_options.layers, "measure")) {
        this.setupMeasure();
      }
    }
  }

  public $onChanges() {
    if (this.myMap) {
      this.setupOptions();
      this.moveMap();
    } else {
      this.$timeout(() => {
        if (!this.measureData) {
          this.measureData = [];
        }
        this.setupOptions();
        this.initMap();
      });
    }
  }

  public setupOptions() {
    this.map_options = this.defaultOptions;
    if (this.options.defaultZoom) {
      this.map_options.defaultZoom = this.options.defaultZoom;
    }
    if (this.options.imagery) {
      this.map_options.imagery = this.options.imagery;
    }
    if (this.options.layers) {
      this.map_options.layers = this.options.layers;
    }
    if (this.options.one_layer) {
      this.map_options.one_layer = this.options.one_layer;
    }
    if (_.isBoolean(this.options.labels)) {
      this.map_options.labels = this.options.labels;
    }
    if (_.isBoolean(this.options.marker)) {
      this.map_options.marker = this.options.marker;
    }
  }

  public moveMap() {
    this.myMap.setView([this.address.lat, this.address.lon], this.map_options.defaultZoom);
    this.myMap.removeLayer(this.marker);
    this.setMarker();
  }

  public initMarker() {
    const Icon = L.Icon.extend({
      options: {
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
      },
    });
    this.icon = new (Icon as any)({
      iconUrl: "/assets/images/marker-icon.2273e3d8.png",
      shadowUrl: "/assets/images/marker-shadow.44a526ee.png",
    });
  }

  public setMarker() {
    this.marker = L.marker([this.address.lat, this.address.lon], { icon: this.icon, draggable: true }).addTo(
      this.myMap,
    );
    this.marker.on("moveend", () => {
      const place = this.marker.getLatLng();
      this.address.lat = place.lat;
      this.address.lon = place.lng;
      this.moveMap();
      this.addressChanged({ address: this.address });
    });
  }

  public setupMeasure() {
    const options = {
      labels: this.map_options.labels,
      postion: "topright",
      primaryLengthUnit: "feet",
      secondaryLengthUnit: "",
      primaryAreaUnit: "sqfeet",
      completedColor: "#ff0000",
      data: this.Repository.Measurement.toLeafletMeasure(this.measureData),
    };
    const measureControl = (L.control as any).measure(options);
    measureControl.addTo(this.myMap);
    this.myMap.on("measurefinish", (data) => {
      this.$timeout(() => {
        this.export_data();
        this.measureFinished({
          data: {
            area: _.round(data.area * 10.7639, -2),
            perimeter: _.round(data.length * 3.28084, -2),
            points: this.export_data(),
          },
        });
      });
    });

    this.myMap.on("measurestart", () => {
      if (this.map_options.one_layer) {
        this.remove_layers();
      }
    });

    this.myMap.on("layerremove", () => {
      this.export_data();
    });

    this.myMap.on("layeradd", () => {
      this.export_data();
    });

    this.myMap.on("zoomend", () => {
      this.export_data();
    });
  }

  public export_data() {
    const geoJSON_data = [];
    this.myMap.eachLayer((layer) => {
      if (
        typeof layer.toGeoJSON === "function" &&
        layer.options.className &&
        layer.options.className.indexOf("layer-measure") === 0
      ) {
        const poly = layer.toGeoJSON();

        geoJSON_data.push(poly);
      }
    });
    this.exportData({ data: { points: geoJSON_data, zoom: this.myMap._zoom } });
    return geoJSON_data;
  }

  public remove_layers() {
    this.myMap.eachLayer((layer) => {
      if (
        typeof layer.toGeoJSON === "function" &&
        layer.options.className &&
        layer.options.className.indexOf("layer-measure") === 0
      ) {
        layer.remove();
      }
    });
  }
}

export class RsfMapComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/RsfMap/rsf_map_component.html";
  public bindings: any = {
    options: "<",
    address: "<",
    measureData: "<",
    addressChanged: "&",
    measureFinished: "&",
    exportData: "&",
  };

  constructor() {
    this.controller = RsfMapCtrl;
  }
}
