import * as React from "react";
import { FormControl } from "@tberrysoln/rsf-form";
import { required } from "app2/src/helpers/FinalFormValidator";

export const AuthorizationForm: React.FC = () => {
  return (
    <>
      <p>
        If you just saved the custom application in ImproveIt360. It may take up to 10 minutes for ImproveIt360 to the
        save the configuration.
      </p>
      <FormControl label="Consumer Key" name="data.consumer_key" validate={required} />
      <FormControl label="Consumer Secret" name="data.consumer_secret" validate={required} />
    </>
  );
};
