import { fetcher } from "../helpers/Fetcher";
import { EventResultRecord } from "../records/EventResult";

export interface IEventResultResponse {
  results: EventResultRecord[];
}

export const getEventResults = async (orgId: number): Promise<IEventResultResponse> => {
  return await fetcher.get<IEventResultResponse>(`/api/v1/orgs/${orgId}/events/results`);
};
