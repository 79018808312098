import { ISession, Actions } from "app/src/Common/SessionService";
import { IUser } from "app/src/Models/User";
import { IOrgResource, IOrg } from "app/src/Models/Org";
import { IAccountResource, IAccount } from "../Billing/Models/Account";
import { IInvoiceItemResponse, IInvoiceItemResource } from "../Billing/Models/InvoiceItem";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { ISubscription } from "../Billing/Models/Subscription";
import { ICalendarResource, ICalendarResponse } from "app/src/Models/Calendar";
// noinspection TypeScriptUnresolvedVariable
import Calendar = kendo.ui.Calendar;
import { IOrderResource, IOrderResponse } from "../Billing/Models/Order";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IImage, IImageResource } from "../Models/Image";
import { includes as userIncludes } from "app2/src/api/user.service";
import { IDirtyMerge, IDirtyWatcher } from "app/src/Common/DirtyWatcher";
import { IRepository } from "app/src/Common/Repository";
import { IUserPreference } from "app/src/Models/UserPreference";
import { IChargebeeSubscriptionResponse } from "app/src/Billing/Models/ChargebeeSubscription";

export class ProfileCtrl implements IDirtyMerge {
  public spinnerPromise: ng.IPromise<any>;
  public user: IUser;
  public preferences: IUserPreference;
  public org: IOrg;
  public calendarResponse: ICalendarResponse;
  public account: IAccount;
  public invoiceItems: IInvoiceItemResponse;
  public orders: IOrderResponse;
  public subscription: ISubscription;
  public stripeData: any;
  public cronofyUrl: string;
  public readOnlyOrg = true;
  public showUserTab = true;
  public subscriptions: IChargebeeSubscriptionResponse;
  public jobListViewValues: any = [
    { id: true, text: "Card View" },
    { id: false, text: "List View" },
  ];
  public showEstimatorTourValues: any = [
    { id: true, text: "Enabled" },
    { id: false, text: "Disabled" },
  ];

  public notificationError = false;
  private _orgIncludes: string[] = ["address", "settings", "preferences"];
  private _accountIncludes: string[] = ["plan", "customers", "subscriptions", "fee", "fee_subscription"];
  private _originalState: any;

  public static $inject = [
    "Session",
    "Org",
    "Account",
    "Calendar",
    "InvoiceItem",
    "Order",
    "Flash",
    "StripeCheckout",
    "$stateParams",
    "$state",
    "$http",
    "$q",
    "BaseConfig",
    "US_STATES",
    "COUNTRIES",
    "Repository",
    "Upload",
    "$analytics",
    "Image",
    "DirtyWatcher",
    "$scope",
  ];
  constructor(
    public Session: ISession,
    public OrgResource: IOrgResource,
    public AccountResource: IAccountResource,
    public CalendarResource: ICalendarResource,
    public InvoiceItem: IInvoiceItemResource,
    public Order: IOrderResource,
    public Flash: IFlash,
    public StripeCheckout: ng.stripe.IStripeCheckoutService,
    public $stateParams: ng.ui.IStateParamsService,
    public $state: ng.ui.IStateService,
    public $http: ng.IHttpService,
    private $q: ng.IQService,
    public BaseConfig: IBaseConfig,
    public US_STATES: string[],
    public COUNTRIES: string[],
    public Repository: IRepository,
    private Upload: ng.angularFileUpload.IUploadService,
    protected $analytics: angulartics.IAnalyticsService,
    private Image: IImageResource,
    private DirtyWatcher: IDirtyWatcher,
    private $scope: ng.IScope,
  ) {
    this.spinnerPromise = Session.currentUser.$promise.then(() => {
      this.user = Repository.User.fromJSON(Session.currentUser);
      delete this.user.org;
      this.user.accesses = [];
      this.calendarResponse = <ICalendarResponse>CalendarResource.query();
      this.org = Session.currentUser.org;
      Session.can(Actions.update, "org", this.org).then((permission) => {
        if (permission) {
          this.readOnlyOrg = false;
        }
      });

      Session.can(Actions.update, "billing_account", null).then((permission) => {
        if (permission) {
          this.account = AccountResource.byOrg({ org_id: this.user.org_id, "include[]": this._accountIncludes });
          this.invoiceItems = <IInvoiceItemResponse>InvoiceItem.query({ org_id: $stateParams["id"] });
          this.orders = <IOrderResponse>Order.query({ org_id: $stateParams["id"] });
          this.account.$promise.then(() => {
            this.updateAccountData();
          });
          <IChargebeeSubscriptionResponse>this.Repository.ChargebeeSubscription.query({
            org_id: this.org.id,
          }).$promise.then((data: any) => {
            if (data.subscriptions.length) this.subscriptions = data.subscriptions;
          });
        }
      });

      this.$http.get(BaseConfig.BASE_URL + "/auth/cronofy_url").then((response: any) => {
        this.cronofyUrl = response.data.url;
      });

      return Session.preferences.$promise.then(() => {
        this.preferences = Repository.UserPreference.fromJSON(this.Session.preferences);
        if (!this.preferences.config.notifications.email) {
          this.preferences.config.notifications.email = this.user.email;
        }
        this.resetFormState();
      });
    });

    this.updateUserNotifications = this.updateUserNotifications.bind(this);
    this.setNotificationError = this.setNotificationError.bind(this);
    if (!$stateParams["authUser"]) {
      DirtyWatcher.setup($scope, this);
    }
  }

  // noinspection JSUnusedGlobalSymbols
  public tabSelected(tab) {
    if (tab === "User Tab") {
      this.showUserTab = true;
    } else {
      this.showUserTab = false;
    }
  }

  public setNotificationError(value: boolean) {
    this.notificationError = value;
    this.$scope.$digest();
  }

  public userTabSelected() {
    return this.showUserTab;
  }

  public deleteImage(image: IImage) {
    this.spinnerPromise = this.Image.delete({
      id: image.id,
      imageable_id: image.imageable_id,
      imageable_type_url: "orgs",
    })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Logo deleted successfully.");
        this.org.images = _.filter(this.org.images, (i: IImage) => {
          return image.id !== i.id;
        });
        this.trackEvent("delete_logo", {
          category: "OrgProfile",
          logoId: image.id,
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems deleting the image");
      });
  }

  public upload(file, invalidFiles) {
    if (!file) {
      if (invalidFiles.length > 0) {
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " + invalidFiles[0].name + ".  Only image file types are allowed.",
        );
      }
      return;
    }
    this.spinnerPromise = this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
      url: this.BaseConfig.BASE_URL + "/api/v1/orgs/" + this.org.id + "/images",
      data: { image: { file: file } },
    }).then(
      (resp: any) => {
        const img: IImage = this.Image.fromJSON(resp.data.image);
        this.org.images.push(img);
        this.Flash.addMessage(FlashLevels.success, "Logo added successfully.");
        this.trackEvent("upload_logo", {
          category: "OrgInfo",
        });
      },
      () => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems uploading the logo");
      },
    );
  }

  public trackEvent(action, props): void {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        org: this.org.id,
      }),
    );
  }

  // noinspection JSUnusedGlobalSymbols
  public updateStripe() {
    this.StripeCheckout.load().then(() => {
      const name = "OCC Billing Information";
      let description = "";
      if (this.account.plan) {
        description = this.account.plan.name;
      }

      const handler = this.StripeCheckout.configure(<ng.stripe.IStripeCheckoutConfigureOptions>{
        name: name,
        allowRememberMe: false,
        image: "/assets/images/rsf-circle-128x128.d0e212c3.png",
      });

      handler.open({ description: description }).then((result) => {
        this.account = this.AccountResource.update({
          id: this.account.id,
          org_id: this.account.org_id,
          email: result[0].email,
          stripe_id: result[0].id,
          include: this._accountIncludes,
        });
        this.spinnerPromise = this.account.$promise.then((k) => {
          this.updateAccountData();
          return this.Session.updateBilling();
        });
      });
    });
  }

  public updateAccountData() {
    if (!_.isEmpty(this.account.customers)) {
      this.stripeData = this.account.customers[0].stripe_data;
    }
    if (!_.isEmpty(this.account.subscriptions)) {
      this.subscription = this.account.subscriptions[0];
    }
  }

  public updateUserNotifications(type, name, value) {
    if (type === "user") {
      if (name === "phone") {
        this.preferences.sms_number.number = value;
      } else if (name === "email") {
        this.preferences.config.notifications["email"] = value;
      }
    } else if (value) {
      if (name === "all") {
        this.preferences.config.notifications[type] = [
          "job_created",
          "lead_created",
          "customer_photo_uploaded",
          "signed_document_signed",
          "signable_documents_signed",
          "job_reassigned",
        ];
      } else {
        this.preferences.config.notifications[type].push(name);
      }
    } else {
      if (name === "all") {
        this.preferences.config.notifications[type] = [];
      } else {
        this.preferences.config.notifications[type] = this.preferences.config.notifications[type].filter(
          (n) => n !== name,
        );
      }
    }
    this.$scope.$digest();
  }

  // noinspection JSUnusedGlobalSymbols
  public saveUserForm(form: ng.IFormController) {
    if (!form.$valid) {
      return;
    }

    this.Session.preferences = this.Repository.UserPreference.fromJSON(this.preferences);
    const promises = [];
    promises.push(this.Session.savePreference());
    promises.push(this.user.$saveOrCreate({ "include[]": userIncludes }));
    this.spinnerPromise = this.$q.all(promises);
    this.spinnerPromise
      .then(() => {
        this.resetFormState();
        this.Flash.addMessage(FlashLevels.success, "User successfully saved!");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems saving the User!");
      });
  }

  // noinspection JSUnusedGlobalSymbols
  public syncEvents(calendar) {
    this.spinnerPromise = calendar.$syncEvents();

    this.spinnerPromise.then(() => {
      this.Flash.addMessage(FlashLevels.success, "Full event sync started.");
    });
  }

  public saveCalendarForm(form: ng.IFormController) {
    if (form.$pristine) {
      return;
    }

    const promises = [];

    _.each(form, (v: any, k: any) => {
      if (k[0] === "$" || v.$pristine) {
        return;
      }

      const calendar = _.find(this.calendarResponse.calendars, (cal) => {
        return cal.id === parseInt(k);
      });

      if (!calendar) {
        return;
      }

      promises.push(calendar.$save());
    });

    this.spinnerPromise = this.$q.all(promises);
    this.spinnerPromise.then(() => {
      form.$setPristine();
    });
  }

  // noinspection JSUnusedGlobalSymbols
  public saveOrgForm(form: ng.IFormController) {
    if (!form.$valid) {
      return;
    }

    this.spinnerPromise = this.org
      .$saveOrCreate({ "include[]": this._orgIncludes })
      .then(() => {
        this.Flash.addMessage(FlashLevels.success, "Org successfully saved!");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems saving the Org!");
      });
  }

  // noinspection JSUnusedGlobalSymbols
  public payingOnboarding(): boolean {
    if (this.account && this.account.fee) {
      return (
        this.account.fee.installments > 0 &&
        this.account.fee_subscription &&
        this.account.fee_subscription.history.installments.length < this.account.fee.installments
      );
    }

    return false;
  }

  public resetFormState() {
    this._originalState = this.combinedToJson();
  }

  public combinedToJson() {
    return angular.toJson(this.user) + angular.toJson(this.preferences);
  }

  public reset(): ng.IPromise<any> {
    this.preferences = this.Repository.UserPreference.fromJSON(this.Session.preferences);
    this.user = this.Repository.User.fromJSON(this.Session.currentUser);
    this.resetFormState();
    const resetDefer = this.$q.defer();
    resetDefer.resolve(true);
    return resetDefer.promise;
  }

  public check(): boolean {
    if (this.notificationError) {
      return false;
    }
    return this._originalState !== this.combinedToJson();
  }
}
