import { IBaseConfig } from "./IBaseConfig";
import * as ng from "angular";
import { ISession } from "./SessionService";
import * as angulartics from "angulartics";

export interface IAdInfo {
  randomAd: any;
  randomTestimonial: any;
}

export class ILearnMore {
  public first_name: string;
  public last_name: string;
  public email: string;
  public phone: string;
  public company: string;
  public adUrl: string;
}

export class AdService implements IAdInfo {
  public adConfigPromise: ng.IPromise<any>;
  public adConfig: any;
  public learnMore: ILearnMore = <ILearnMore>{};

  public static $inject = ["BaseConfig", "Session", "$analytics", "$http", "$uibModal"];
  constructor(
    public BaseConfig: IBaseConfig,
    public Session: ISession,
    public $analytics: angulartics.IAnalyticsService,
    private $http: ng.IHttpService,
    private $uibModal: ng.ui.bootstrap.IModalService,
  ) {}

  public getRandomAdUrl = (group: string, value: string): any => {
    if (_.isUndefined(this.adConfig["ads"][group])) {
      return "";
    } else {
      return this.adConfig["ads"][group][Math.floor(Math.random() * this.adConfig["ads"][group].length)][value];
    }
  };

  public getRandomTestimonial() {
    if (_.isUndefined(this.adConfig["testimonials"])) {
      return "";
    } else {
      return this.adConfig["testimonials"][Math.floor(Math.random() * this.adConfig["testimonials"].length)];
    }
  }

  public randomTestimonial(): ng.IPromise<string> {
    return this.setupLearnMore().then(() => {
      if (_.isUndefined(this.adConfigPromise)) {
        this.adConfigPromise = this.getAdData().then((response) => {
          this.adConfig = response.data;
          return this.getRandomTestimonial();
        });
        return this.adConfig;
      } else {
        return this.adConfigPromise.then(() => {
          return this.getRandomTestimonial();
        });
      }
    });
  }

  public randomAd(group: string, value: string): ng.IPromise<string> {
    return this.setupLearnMore().then(() => {
      group = group.replace(/\./g, "_");
      if (_.isUndefined(this.adConfigPromise)) {
        this.adConfigPromise = this.getAdData()
          .then((response) => {
            this.adConfig = response.data;
            const adUrl = this.getRandomAdUrl(group, value);
            this.learnMore.adUrl = adUrl;
            return adUrl;
          })
          .catch((error) => {
            console.error(error);
          });
        return this.adConfigPromise;
      } else {
        return this.adConfigPromise.then(() => {
          const adUrl = this.getRandomAdUrl(group, value);
          this.learnMore.adUrl = adUrl;
          return adUrl;
        });
      }
    });
  }

  public setupLearnMore(): ng.IPromise<any> {
    return this.Session.currentUser.$promise.then(() => {
      this.learnMore.first_name = this.Session.currentUser.first_name;
      this.learnMore.last_name = this.Session.currentUser.last_name;
      this.learnMore.email = this.Session.currentUser.email;
      this.learnMore.phone = this.Session.currentUser.phone;
      this.learnMore.company = this.Session.currentUser.org.name;
    });
  }

  public openModal() {
    this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "LearnMoreCtrl",
      controllerAs: "ctrl",
      size: "lg",
      templateUrl: "src/Common/Ads/learn_more_modal.html",

      resolve: {
        user: this.Session.currentUser,
      },
    });
    this.trackEvent("open_modal", {
      adUrl: this.learnMore.adUrl,
    });
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        category: "AdService",
        org: this.Session.currentUser.org.id,
      }),
    );
  }

  private getAdData() {
    return this.$http.get(this.BaseConfig.ADS_URL, { headers: { Authorization: undefined } });
  }
}
