export const countries = ["United States", "Canada", "Mexico"];

export const countries_abbreviation = {
  "United States": "US",
  Canada: "CA",
  Mexico: "MX",
};

export const us_states = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States Of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Dakota",
  "North Carolina",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland",
  "Nova Scotia",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Aguascalientes",
  "Baja California Norte",
  "Baja California Sur",
  "Coahuila",
  "Chihuahua",
  "Colima",
  "Campeche",
  "Chiapas",
  "Districto Federal",
  "Durango",
  "Guerrero",
  "Guanajuato",
  "Hidalgo",
  "Jalisco",
  "Michoacan",
  "Morelos",
  "Mexico",
  "Nayarit",
  "Nuevo Leon",
  "Oaxaca",
  "Puebla",
  "Queretaro",
  "Quintana Roo",
  "Sinaloa",
  "San Luis Potosi",
  "Sonora",
  "Tamaulipas",
  "Tabasco",
  "Tlaxcala",
  "Veracruz",
  "Yucatan",
  "Zacatecas",
];

export const us_states_abbreviation = {
  Alabama: "AL",
  Alaska: "AK",
  "American Samoa": "AS",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District Of Columbia": "DC",
  "Federated States Of Micronesia": "FM",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  "Marshall Islands": "MH",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Northern Mariana Islands": "MP",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Palau: "PW",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  "Virgin Islands": "VI",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
  Aguascalientes: "AG",
  "Baja California Norte": "BN",
  "Baja California Sur": "BS",
  Coahuila: "CH",
  Chihuahua: "CI",
  Colima: "CL",
  Campeche: "CP",
  Chiapas: "CS",
  "Districto Federal": "DF",
  Durango: "DG",
  Guerrero: "GE",
  Guanajuato: "GJ",
  Hidalgo: "HD",
  Jalisco: "JA",
  Michoacan: "MC",
  Morelos: "MR",
  Mexico: "MX",
  Nayarit: "NA",
  Oaxaca: "OA",
  Puebla: "PU",
  Queretaro: "QE",
  "Quintana Roo": "QI",
  Sinaloa: "SI",
  "San Luis Potosi": "SL",
  Sonora: "SO",
  Tamaulipas: "TA",
  Tabasco: "TB",
  Tlaxcala: "TL",
  Veracruz: "VC",
  Yucatan: "YU",
  Zacatecas: "ZA",
};
