import * as React from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { usePrevious } from "app2/src/hooks/usePrevious";
import { useDispatch } from "react-redux";
import { login, getQuoteList, getQuoteFull, getLineItemImage, BASE_URL } from "app2/src/lib/fene_tech/api";
import { Spinner } from "app2/src/components/SpinnerComponent";
import { IFenetechLineItem, IFenetechQuoteFullResponse } from "app2/src/lib/fene_tech/types";
export interface FeneTechModalProps {
  openFeneTech: number;
  makeSelection: (data: IFenetechQuoteFullResponse) => void;
}

export const FeneTechModal: React.FC<FeneTechModalProps> = ({ openFeneTech, makeSelection }) => {
  // Hooks
  const previousTriggerOpen = usePrevious(openFeneTech);
  const dispatch = useDispatch();

  // Local State
  const [open, setOpen] = useState(false);
  const [loginUrl, setLoginUrl] = useState(BASE_URL);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showQuotesList, setShowQuotesList] = useState(false);
  const [quotesList, setQuotesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Methods
  const hide = () => {
    setOpen(false);
    setShowQuotesList(false);
    setLoginUrl(BASE_URL);
    setPassword("");
    setUsername("");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const response: any = await login(loginUrl, username, password, "standard", dispatch);

    if (response && response.status !== 401) {
      setShowQuotesList(true);

      const quotesListResponse = await getQuoteList(loginUrl, dispatch);
      setQuotesList(quotesListResponse);
    }

    setIsLoading(false);
  };

  const selectQuote = async (oKey: number) => {
    setIsLoading(true);
    const response = await getQuoteFull(loginUrl, oKey, dispatch);

    const promises = [];

    if (response.lineItems) {
      response.lineItems.forEach((line) => {
        const lineItem = line.lineItem;
        if (lineItem.thumbnailImageExists) {
          promises.push(getImage(line, oKey, response.permissions.imageAccessKey));
        }
      });
    }

    await Promise.all(promises);

    makeSelection(response);
    setIsLoading(false);
    hide();
  };

  const getImage = async (line: IFenetechLineItem, oKey: number, imageAccessKey: number) => {
    const response = await getLineItemImage(loginUrl, oKey, line.lineItem.odKey, imageAccessKey, dispatch);
    line.imageBlob = response;
    return;
  };

  // Life Cycle
  useEffect(() => {
    if (openFeneTech !== 0 && previousTriggerOpen !== openFeneTech) {
      setOpen(true);
      setIsLoading(false);
    }
  }, [openFeneTech]);

  return (
    <Modal show={open} onHide={hide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>FeneVision WEB</Modal.Title>
      </Modal.Header>

      <Spinner localProperty={isLoading} />

      {!showQuotesList ? (
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label htmlFor="loginURL">Login URL</Form.Label>
              <Form.Control
                type="text"
                id="loginURL"
                name="loginURL"
                value={loginUrl}
                onChange={(e) => setLoginUrl(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor="fenevisionWebUsername">Fenevision Web Username</Form.Label>
              <Form.Control
                type="text"
                id="fenevisionWebUsername"
                name="fenevisionWebUsername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit">Login</Button>
            <Button className="btn-cancel" onClick={hide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      ) : (
        showQuotesList && (
          <>
            <Modal.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Quote #</th>
                    <th>Customer Ref</th>
                    <th>PO Number</th>
                    <th>Customer</th>
                  </tr>
                </thead>

                <tbody>
                  {quotesList.map((quote) => (
                    <tr className="link" key={quote.oKey} onClick={() => selectQuote(quote.oKey)}>
                      <td>{quote.orderNumber}</td>
                      <td>{quote.customerRef}</td>
                      <td>{quote.poNumber}</td>
                      <td>{quote.webCenterCustomerName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-cancel" onClick={hide}>
                Cancel
              </Button>
            </Modal.Footer>
          </>
        )
      )}
    </Modal>
  );
};
