import * as React from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import {
  PaymentTermItemRecord,
  PaymentTermItemKinds,
  PaymentTermItemKindList,
} from "app2/src/records/PaymentTermTemplate";
import { DecimalInputComponent } from "../Common/DecimalInputComponent";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { UppercaseWord, CurrencyFormat } from "app2/src/helpers/Format";
import { useSelector } from "react-redux";
import { presentModePreferencesConfig } from "app2/src/selectors/org.selectors";

export interface ItemEditorProps {
  item: PaymentTermItemRecord;
  changeAmount: (pti: PaymentTermItemRecord, amount: number) => void;
  changeDesc: (pti: PaymentTermItemRecord, desc: string) => void;
  changeKind: (pti: PaymentTermItemRecord, kind: PaymentTermItemKinds) => void;
  changePaymentMethod: (pti: PaymentTermItemRecord, paymentMethod: "cash" | "financed") => void;
  deleteItem: (pti: PaymentTermItemRecord) => void;
}

export const ItemEditor: React.FunctionComponent<ItemEditorProps> = ({
  item,
  changeAmount,
  changeDesc,
  changeKind,
  changePaymentMethod,
  deleteItem,
}) => {
  const [amount, setAmount] = useState(item.amount);
  const [description, setDesc] = useState(item.description);
  const [kind, setKind] = useState(item.type);
  const [paymentMethod, setPaymentMethod] = useState(item.paymentMethod);

  const showPaymentTermEditorPricing = useSelector((state) =>
    presentModePreferencesConfig(state, { path: ["estimator", "show_pricing", "payment_term_editor"] }),
  );

  useEffect(() => {
    if (item.amount !== amount) {
      setAmount(item.amount);
    }

    if (item.description !== description) {
      setDesc(item.description);
    }

    if (item.type !== kind) {
      setKind(item.type);
    }

    if (item.paymentMethod !== paymentMethod) {
      setPaymentMethod(item.paymentMethod);
    }
  }, [item.amount, item.description, item.type, item.paymentMethod]);

  return (
    <div>
      <Row>
        <Col sm={12}>
          <label className="control-label">Description</label>
          <FormControl
            type="text"
            className="item-description"
            onChange={(e) => {
              changeDesc(item, e.target.value);
              setDesc(e.target.value);
            }}
            value={description}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={3}>
          <label className="control-label">Amount</label>
          <DecimalInputComponent
            type="number"
            className="item-amount"
            value={amount}
            onNumberChange={(value) => {
              changeAmount(item, value);
              setAmount(value);
            }}
          />
        </Col>
        <Col sm={3}>
          <label className="control-label">Type</label>
          <select
            className="form-control item-kind"
            onChange={(e) => {
              changeKind(item, e.target.value as any as PaymentTermItemKinds);
              setKind(e.target.value as any as PaymentTermItemKinds);
            }}
            value={kind}>
            {PaymentTermItemKindList.map((kind, idx) => {
              return (
                <option key={idx} value={kind}>
                  {UppercaseWord(kind)}
                </option>
              );
            })}
          </select>
        </Col>
        <Col sm={2} className="centered">
          <label className="control-label">Financed</label>
          <Form.Check
            checked={paymentMethod === "financed"}
            onChange={(e) => {
              changePaymentMethod(item, e.target.checked ? "financed" : "cash");
              setPaymentMethod(e.target.checked ? "financed" : "cash");
            }}
          />
        </Col>
        <Col sm={3} className="my-auto align-middle">
          {showPaymentTermEditorPricing ? CurrencyFormat(item.calculated) : null}
        </Col>
        <Col sm={1} className="align-middle">
          <ConfirmDialog title={"Are you sure you want to delete this payment term?"}>
            {(confirm) => <i className="rsf-delete-link rsf-base-66" onClick={confirm((_e) => deleteItem(item))} />}
          </ConfirmDialog>
        </Col>
      </Row>
      <hr className="hr-spacing" />
    </div>
  );
};
