import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import CrmAuthorize from "../Common/CrmAuthorize";
import { IUser } from "app/src/Models/User";
import * as crmUserActions from "app2/src/reducers/crmUser.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import * as tokenActions from "app2/src/reducers/token.actions";
import { CrmUserRecord } from "app2/src/records/integrations/CrmUser";
import { QueryParamsRecord } from "app2/src/records/Page";
import { getPaginationByModel, queryParams, pagination } from "app2/src/selectors/pagination.selectors";
import { getAuthorization } from "app2/src/selectors/token.selectors";
import HomevestFinanceModal from "./HomevestFinanceModal";
import track from "react-tracking";
import HomevestUserTable from "./HomevestUserTable";

const mapStateToProps = (state: RootState, ownProps: HomevestFinanceProps) => {
  const modelName = "FmUser";
  const crmUsers = getPaginationByModel(state, {
    path: ["crmUsers", "byId"],
    modelName,
  });
  const sfQueryParams = queryParams(state, { modelName });

  return {
    authorized: getAuthorization(state, { orgId: ownProps.orgId, integration: "fin_mkt" }),
    pagination: pagination(state, { modelName, page: sfQueryParams.get("page") }),
    queryParams: sfQueryParams,
    crmUsers,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: HomevestFinanceProps) => {
  return {
    sync: () => dispatch(orgActions.AsyncActions.syncIntegration(ownProps.orgId, "SyncJob", "fin_mkt")),
    rejectAuthorization: (orgId: number) => dispatch(tokenActions.AsyncActions.deleteToken(orgId, "fin_mkt")),
    queryUsers: (orgId: number, sfQueryParams: QueryParamsRecord) =>
      dispatch(crmUserActions.AsyncActions.queryUsers({ orgId: orgId, userType: "FmUser" }, sfQueryParams)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface HomevestFinanceProps {
  users: IUser[];
  orgId: number;
  tokenUpdated: () => void;
}

interface HomevestFinanceState {
  triggerOpen: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HomevestFinanceProps;

@track({
  component: "HomevestFinance",
  integration: "fin_mkt",
})
class HomevestFinance extends React.Component<Props, HomevestFinanceState> {
  constructor(props: Props) {
    super(props);

    this.state = { triggerOpen: 0 };

    this.openModal = this.openModal.bind(this);
    this.rejectAuthorization = this.rejectAuthorization.bind(this);
    this.queryUsers = this.queryUsers.bind(this);
    this.syncData = this.syncData.bind(this);
  }

  public componentDidMount() {
    const { queryUsers, orgId, authorized } = this.props;
    if (authorized) {
      queryUsers(orgId, new QueryParamsRecord());
    }
  }

  @track(() => ({
    action: "open modal",
  }))
  public openModal() {
    this.setState((state) => ({ triggerOpen: state.triggerOpen + 1 }));
  }

  @track(() => ({
    action: "reject authorization",
  }))
  public async rejectAuthorization() {
    const { orgId, rejectAuthorization, tokenUpdated } = this.props;
    await rejectAuthorization(orgId);
    tokenUpdated();
  }

  @track(() => ({
    action: "query users",
  }))
  public queryUsers(page: number) {
    const { queryUsers, queryParams, orgId } = this.props;
    const newQueryParams = queryParams.setIn(["page"], page);

    queryUsers(orgId, newQueryParams);
  }

  @track(() => ({
    action: "sync",
  }))
  public syncData() {
    this.props.sync();
  }

  public render() {
    const { authorized, crmUsers, pagination, users, orgId, tokenUpdated } = this.props;
    const { triggerOpen } = this.state;

    return (
      <div className="form-section">
        <div className="form-section-content">
          <CrmAuthorize
            title="HomevestFinance"
            image="/assets/images/homevest_finance.ada98c4a.png"
            authorized={authorized}
            authorize={this.openModal}
            rejectAuthorization={this.rejectAuthorization}
          />
          {authorized && (
            <HomevestUserTable
              integrationType="Homevest"
              occUsers={users}
              integrationUsers={crmUsers}
              pagination={pagination}
              queryUsers={this.queryUsers}
              sync={this.syncData}
            />
          )}
          <HomevestFinanceModal triggerOpen={triggerOpen} tokenUpdated={tokenUpdated} />
        </div>
      </div>
    );
  }
}

export default connector(HomevestFinance);
