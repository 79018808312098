import * as React from "react";
import { Fragment } from "react";
import * as FontAwesome from "react-fontawesome";
import { SearchBox } from "../../Common/SearchBox";
import { connect, ConnectedProps } from "app2/src/connect";
import { OrgAclType, OrgPrefType } from "app/src/Models/Org";
import track from "react-tracking";
import { UserPrefConfigType } from "app/src/Models/UserPreference";
import { ProductRecord } from "app2/src/records/Product";
import { List } from "immutable";
import { StoreRegistry } from "app2/src/storeRegistry";
import {
  cachedRootProducts,
  cachedProductChildren,
  cachedProduct,
  cachedProductLeaves,
} from "app2/src/selectors/product.selectors";
import { getUrl } from "app2/src/records/Image";
import { token } from "app2/src/selectors/token.selectors";
import { TokenRecord } from "app2/src/records/Token";
import * as tokenActions from "app2/src/reducers/token.actions";
import { ThunkDispatch } from "redux-thunk";
import { RootActions, RootState } from "app2/src/reducers";
import { FeneTechModal } from "app2/src/components/LineItemEditor/components/FeneTechModal";

interface ProductSelectorProps {
  activatedPriceListId: number;
  lineItemProductId: number;
  controller: any;
  handleProductSelected: any;
  handleOpeningSelected: any;
  handleImportSelected: any;
}

interface ProductSelectorState {
  listOpen: boolean;
  rootTree: boolean;
  searching: boolean;
  noMatch: boolean;
  selectedProduct: ProductRecord;
  currentSelection: List<ProductRecord>;
  parent: ProductRecord;
  parentParent: ProductRecord;
  searchSelection: List<ProductRecord>;
  searchText: string;
  openFeneTech: number;
}

const mapStateToProps = (state: RootState, _ownProps) => {
  return {
    proviaToken: token(state, { kind: "provia" }) as TokenRecord,
    feneTechToken: token(state, { kind: "fene_tech" }) as TokenRecord,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, _ownProps) => ({
  getProviaToken: () => dispatch(tokenActions.AsyncActions.getToken(_ownProps.controller.org.id, "provia")),
  getFeneTechToken: () => dispatch(tokenActions.AsyncActions.getToken(_ownProps.controller.org.id, "fene_tech")),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProviaData = {
  portal?: boolean;
  non_portal?: boolean;
  portal_id: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ProductSelectorProps;

@track({ component: "Product Selector" })
class ProductSelector extends React.Component<Props, ProductSelectorState> {
  public props: Props;
  public state: ProductSelectorState;
  public rootProducts: List<ProductRecord>;
  public productLeaves: List<ProductRecord>;
  public node: any;
  public eventListener: any;

  constructor(props) {
    super(props);

    const app = this;
    this.eventListener = function (e) {
      app.handleClick(e);
    };

    const { controller, lineItemProductId, activatedPriceListId } = this.props;
    this.rootProducts = cachedRootProducts(StoreRegistry.getStore().getState(), {
      activatedPriceListId: activatedPriceListId,
      includeParent: true,
    });
    this.productLeaves = cachedProductLeaves(StoreRegistry.getStore().getState(), {
      activatedPriceListId: activatedPriceListId,
    });
    let currentSelection = this.rootProducts;
    let rootTree = true;
    let est_tour = false;
    controller.Session.fetchUserPref(UserPrefConfigType.estimator_tour).then((tour) => {
      est_tour = tour;
    });
    const listOpen =
      _.isUndefined(lineItemProductId) &&
      controller.org.fetchPref(OrgPrefType.estimator).show_product_selector === "always" &&
      !est_tour;
    if (listOpen) {
      this.mount();
    }

    const lineItemProduct = cachedProduct(StoreRegistry.getStore().getState(), {
      activatedPriceListId: activatedPriceListId,
      productId: lineItemProductId,
    });

    let parent: ProductRecord;
    let parentParent: ProductRecord;
    if (lineItemProduct && lineItemProduct.parent_id) {
      rootTree = false;
      currentSelection = this.productChildren(lineItemProduct.parent_id);
      parent = this.product(lineItemProduct.parent_id);
      if (parent.parent_id) {
        parentParent = this.product(parent.parent_id);
      }
    }
    this.state = {
      listOpen: listOpen,
      rootTree: rootTree,
      searching: false,
      noMatch: false,
      searchText: "",
      selectedProduct: lineItemProduct,
      currentSelection: currentSelection,
      parent: parent,
      parentParent: parentParent,
      searchSelection: this.productLeaves,
      openFeneTech: 0,
    };
    this.searching = this.searching.bind(this);
    this.makeFenetechSelection = this.makeFenetechSelection.bind(this);
  }

  public componentDidMount() {
    this.props.getProviaToken();
    this.props.getFeneTechToken();
  }

  @track((props, state) => ({
    action: `product selector ${!state.listOpen ? "shown" : "hidden"}`,
  }))
  public toggleList() {
    if (this.state.listOpen) {
      this.unmount();
      this.setCurrentSelection();
    } else {
      this.mount();
    }
    this.setState((prevState) => ({
      listOpen: !prevState.listOpen,
      searching: false,
    }));
  }

  public productChildren(product_id: number) {
    const { activatedPriceListId } = this.props;
    return cachedProductChildren(StoreRegistry.getStore().getState(), {
      activatedPriceListId: activatedPriceListId,
      productId: product_id,
    });
  }

  public product(product_id: number) {
    const { activatedPriceListId } = this.props;
    return cachedProduct(StoreRegistry.getStore().getState(), {
      activatedPriceListId: activatedPriceListId,
      productId: product_id,
    });
  }

  public makeSelection(product: ProductRecord) {
    const { controller } = this.props;
    const { parent, parentParent } = this.parents(product.id);

    if (product.children_ids.size <= 0) {
      this.setState({
        listOpen: false,
        selectedProduct: product,
        parent: parent,
        parentParent: parentParent,
      });
      this.unmount();
      this.props.handleProductSelected(product);
    } else if (
      _.include(controller.org.fetchSettingsAcl(OrgAclType.estimator), "window") &&
      (product.kind === "window" || product.kind === "door")
    ) {
      this.setState({
        listOpen: false,
        selectedProduct: product,
        parent: parent,
        parentParent: parentParent,
      });
      this.unmount();
      this.props.handleOpeningSelected(product);
    } else {
      this.setState({
        currentSelection: this.productChildren(product.id),
        parent: parent,
        parentParent: parentParent,
        rootTree: false,
      });
    }
  }

  public parents(product_id: number) {
    const parent = this.product(product_id);
    let parentParent: ProductRecord;
    if (parent.parent_id) {
      parentParent = this.product(parent.parent_id);
    }
    return { parent, parentParent };
  }

  public makeImportSelection() {
    this.setState({
      listOpen: false,
    });
    this.unmount();
    this.props.handleImportSelected();
  }

  public makeFenetechSelection(data: any) {
    this.setState({
      listOpen: false,
    });
    this.unmount();
    this.props.controller.feneTechOrderSelected(data);
  }

  public openFeneTech() {
    const { openFeneTech } = this.state;
    this.setState({
      listOpen: false,
      openFeneTech: openFeneTech + 1,
    });
  }

  public backSelection() {
    const { parent, parentParent } = this.state;
    if (!parent || !parentParent) {
      this.setState({
        currentSelection: this.rootProducts,
        parent: null,
        parentParent: null,
        rootTree: true,
      });
    } else {
      const { parent: newParent, parentParent: newParentParent } = this.parents(parentParent.id);
      this.setState({
        currentSelection: this.productChildren(parentParent.id),
        parent: newParent,
        parentParent: newParentParent,
        rootTree: false,
      });
    }
  }

  public searching(query) {
    const searching = query.length > 0;
    this.setState({
      searchText: query,
    });

    if (searching) {
      const lowercaseQuery = query.toLowerCase();
      let products = List();
      // Limiting search results to 500 due to the performance on customer devices (setState rendering)
      for (let i = 0; i < this.productLeaves.size && products.size < 500; i++) {
        const leaf = this.productLeaves.get(i);
        if (leaf.ancestral_name_lowercase.indexOf(lowercaseQuery) >= 0) {
          products = products.push(leaf);
        }
      }

      this.setState({
        searchSelection: products,
        noMatch: products.size === 0,
        searching: searching,
      });
    } else {
      this.setState({
        searching: searching,
      });
    }
  }

  public setCurrentSelection() {
    const { activatedPriceListId } = this.props;
    const { selectedProduct } = this.state;
    let currentSelection = this.rootProducts;
    let rootTree = true;
    let parent: ProductRecord;
    let parentParent: ProductRecord;
    if (selectedProduct && selectedProduct.parent_id) {
      currentSelection = cachedProductChildren(StoreRegistry.getStore().getState(), {
        activatedPriceListId: activatedPriceListId,
        productId: selectedProduct.parent_id,
        includeParent: true,
      });
      parent = this.product(selectedProduct.parent_id);
      if (parent.parent_id) {
        parentParent = this.product(parent.parent_id);
      }
      rootTree = false;
    }

    this.setState({
      currentSelection: currentSelection,
      parent: parent,
      parentParent: parentParent,
      rootTree: rootTree,
    });
  }

  public mount() {
    document.addEventListener("mousedown", this.eventListener, false);
  }

  public unmount() {
    document.removeEventListener("mousedown", this.eventListener, false);
  }

  public handleClick(e) {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.toggleList();
  }

  public boxName(provia, fileImport) {
    let boxName = "";
    let importIcon = null;

    if (provia && fileImport) {
      boxName = "ProVia/Import";
      importIcon = <img className="img-responsive" src="/assets/images/image-tools/provia-product-selector.bd408849.png" />;
    } else if (provia) {
      boxName = "ProVia";
      importIcon = <img className="img-responsive" src="/assets/images/image-tools/provia-product-selector.bd408849.png" />;
    } else if (fileImport) {
      boxName = "Import";
      importIcon = <i className="fa fa-file-o fa-2x fa-gray" />;
    }
    return [boxName, importIcon];
  }

  public render() {
    const {
      listOpen,
      noMatch,
      selectedProduct,
      parent,
      parentParent,
      currentSelection,
      searchSelection,
      rootTree,
      searching,
      openFeneTech,
    } = this.state;
    const { controller, proviaToken, feneTechToken } = this.props;
    const proviaObj = (proviaToken?.get("data")?.toJSON() || {}) as ProviaData;
    const feneTechEnabled = feneTechToken?.get("data")?.get("enabled") || false;

    const provia = proviaObj.portal || proviaObj.non_portal;
    const fileImport = controller.org.fetchPref("estimator").show_product_file_upload === "always";
    const [boxName, importIcon] = this.boxName(provia, fileImport);
    const importEnabled = provia || fileImport;

    return (
      <div className="product-selector-dropdown" ref={(node) => (this.node = node)}>
        <div className="dd-wrapper" id="tour-step-product-selector">
          <div className="dd-header form-control" onClick={() => this.toggleList()}>
            {selectedProduct ? <div className="dd-header-title">{selectedProduct.name}</div> : "-- Select Product --"}
            {listOpen ? (
              <FontAwesome name="caret-down fa-gray" size="lg" />
            ) : (
              <FontAwesome name="caret-up fa-gray" size="lg" />
            )}
          </div>
          {listOpen ? (
            <ul className="dd-list">
              <li className="dd-list-item search">
                <SearchBox searching={this.searching} placeholder={"Search Products"} query="" />
              </li>
              {rootTree && !searching && feneTechEnabled ? (
                <li className="dd-list-item product" onClick={() => this.openFeneTech()}>
                  <div className="row">
                    <div className="col-12">
                      <span className="product-image">
                        <img className="img-responsive" src="/assets/images/fene_tech.c090fcbf.png" />
                      </span>
                      <span>FeneVision WEB</span>
                    </div>
                  </div>
                </li>
              ) : null}
              {rootTree && !searching && importEnabled ? (
                <li className="dd-list-item product" onClick={() => this.makeImportSelection()}>
                  <div className="row">
                    <div className="col-12">
                      <span className="product-image">{importIcon}</span>
                      <span>{boxName}</span>
                    </div>
                  </div>
                </li>
              ) : null}
              {rootTree || searching ? null : (
                <li className="dd-list-item back" onClick={() => this.backSelection()}>
                  &nbsp;« Back to
                  {parentParent ? " " + parentParent.name : " All Products"}
                </li>
              )}
              {currentSelection.get(0) && parent && !searching ? (
                <li className="dd-list-item parent product">
                  <div className="row">
                    <div className="col-12">
                      <span className="product-image">
                        {parent.images.size > 0 ? (
                          <img className="img-responsive" src={getUrl(parent.images.first(), "small")} />
                        ) : (
                          <Fragment>&nbsp;</Fragment>
                        )}
                      </span>
                      <span>
                        {parent.name}
                        <FontAwesome name="caret-down" size="lg" className="pull-right centerit fa-gray" />
                      </span>
                    </div>
                  </div>
                </li>
              ) : null}
              {searching ? (
                <span>
                  {searchSelection.map((product) => (
                    <li className="dd-list-item product" key={product.id} onClick={() => this.makeSelection(product)}>
                      <div className="row">
                        <div className="col-12">
                          <div>{product.ancestral_name}</div>
                        </div>
                      </div>
                    </li>
                  ))}
                  {noMatch ? (
                    <li className="dd-list-item product">
                      <div className="row">
                        <div className="col-12">
                          <div>No Matches</div>
                        </div>
                      </div>
                    </li>
                  ) : null}
                </span>
              ) : (
                <span>
                  {currentSelection.map((product) => (
                    <li className="dd-list-item product" key={product.id} onClick={() => this.makeSelection(product)}>
                      <div className="row">
                        <div className="col">
                          <span className="product-image">
                            {product.images.size > 0 ? (
                              <img className="img-responsive" src={getUrl(product.images.first(), "small")} />
                            ) : (
                              <Fragment>&nbsp;</Fragment>
                            )}
                          </span>
                          <span>{product.name}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </span>
              )}
            </ul>
          ) : null}
        </div>
        <FeneTechModal openFeneTech={openFeneTech} makeSelection={this.makeFenetechSelection}></FeneTechModal>
      </div>
    );
  }
}

export default connector(ProductSelector);
