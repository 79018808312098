import * as React from "react";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useState } from "react";
import { List } from "immutable";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { Row, Col } from "react-bootstrap";

export interface SortableProps<TContainerComponent extends React.ComponentType<any>> {
  items: List<number>;
  renderItem: (props: any) => React.ReactNode;
  disableDrag?: boolean;
  Container?: TContainerComponent;
  containerProps?: React.ComponentProps<TContainerComponent>;
  onDragEnd?(oldIndex: number, newIndex: number);
  dragHandle?: boolean;
  children?: React.ReactNode;
}

const DefaultContainer: React.FC<{}> = (props) => (
  <Row className="table-body">
    <Col>{props.children}</Col>
  </Row>
);

export const Sortable = <TContainerComponent extends React.ComponentType<any>>({
  Container,
  items,
  renderItem,
  onDragEnd,
  disableDrag = false,
  dragHandle = false,
  children,
  containerProps,
}: SortableProps<TContainerComponent>) => {
  const sortableItems = items.map((i) => i.toString()).toArray();
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);
    if (active.id !== over.id) {
      const oldIndex = sortableItems.indexOf(active.id);
      const newIndex = sortableItems.indexOf(over.id);

      onDragEnd(oldIndex, newIndex);
    }
  };

  let ItemContainer: React.ComponentType<any> = DefaultContainer;
  if (!_.isNullOrUndefined(Container)) {
    ItemContainer = Container;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }

        setActiveId(active.id);
      }}
      onDragCancel={() => setActiveId(null)}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToWindowEdges]}>
      <SortableContext items={sortableItems} strategy={rectSortingStrategy}>
        <ItemContainer {...containerProps}>
          <>
            {sortableItems.map((item) => (
              <SortableItem
                key={item}
                id={item}
                item={item}
                renderItem={renderItem}
                dragHandle={dragHandle}
                disableDrag={disableDrag}
              />
            ))}
            {children}
          </>
        </ItemContainer>

        {createPortal(
          <DragOverlay zIndex={3000} style={{ cursor: "grabbing" }}>
            {activeId && renderItem({ item: parseInt(activeId) })}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
};

export const SortableItem = (props) => {
  const { renderItem, item, dragHandle, disableDrag } = props;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: item });

  useEffect(() => {
    if (!isDragging) {
      return;
    }

    document.body.style.cursor = "grabbing";

    return () => {
      document.body.style.cursor = "";
    };
  }, [isDragging]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
    cursor: dragHandle || disableDrag ? "inherit" : "grab",
    outline: "none",
  };

  props = {
    item: parseInt(item),
    dragHandle: {
      ...(!dragHandle || disableDrag ? undefined : listeners),
      style: { cursor: !dragHandle ? "inherit" : "grab", display: disableDrag ? "none" : "" },
    },
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...(dragHandle || disableDrag ? undefined : listeners)}>
      {renderItem(props)}
    </div>
  );
};
