import { Record } from "immutable";

export const fromJSON = (json: IEstimateComparisonLinkData): EstimateComparisonLinkRecord => {
  const record: IEstimateComparisonLinkRecord = { ...(json as any) };

  if (json.created_at) {
    record.created_at = new Date(json.created_at as any as string);
  }

  if (json.updated_at) {
    record.updated_at = new Date(json.updated_at as any as string);
  }

  return new EstimateComparisonLinkRecord(record);
};

export const toJSON = (record: EstimateComparisonLinkRecord): IEstimateComparisonLinkData => {
  return record.toJS();
};

export interface IEstimateComparisonLinkData {
  estimate_id: number;
  id: number;
  created_at: Date;
  updated_at: Date;
}

export interface IEstimateComparisonLinkRecord {
  estimate_id: number;
  id: number;
  created_at: Date;
  updated_at: Date;
  _destroy: boolean;
}

export const defaultEstimateComparisonLinkProps: IEstimateComparisonLinkRecord = {
  id: 0,
  estimate_id: 0,
  created_at: new Date(),
  updated_at: new Date(),
  _destroy: null,
};

export class EstimateComparisonLinkRecord
  extends Record(defaultEstimateComparisonLinkProps)
  implements IEstimateComparisonLinkRecord
{
  public readonly id!: number;
  public readonly estimate_id!: number;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;
  public readonly _destroy!: boolean;

  public constructor(values?: Partial<IEstimateComparisonLinkRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
