import * as productProcessActions from "./productProcess.actions";
import { List, Record } from "immutable";
import { ProductProcessRecords, fromJSON } from "app2/src/records/ProductProcessRecord";

export const ProductProcessStateRecord = Record({
  processes: new ProductProcessRecords(),
});

export const initialState = ProductProcessStateRecord();
export type ProductProcessState = typeof initialState;

export const reducer = (state = initialState, action: productProcessActions.Actions): ProductProcessState => {
  let processes: ProductProcessRecords;
  switch (action.type) {
    case productProcessActions.FETCH_PROCESSES:
      if (state.getIn(["processes"])) {
        return state.setIn(["processes", "loading"], true);
      }
      return state.setIn(["processes"], fromJSON({ list: {}, loading: true }));
    case productProcessActions.RECEIVE_PROCESSES:
      processes = fromJSON({ ...action.payload, loading: false, cached: true });

      return state.setIn(["processes"], processes);
    case productProcessActions.RECEIVE_PROCESS_ERRORS:
      return state.setIn(["processes", "errors"], List<string>(action.payload)).setIn(["processes", "loading"], false);
    default:
      return state;
  }
};
