import { Record, List, Map } from "immutable";
import { Nullable } from ".";
import { Finance } from "financejs";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { Parser } from "hot-formula-parser";
import { EstimateRecord } from "app2/src/records/Estimate";
import { EstimatePrototype, IEstimate } from "app/src/Models/Estimate";
import { fromJSON as validationFromJSON } from "app2/src/records/FinanceOptionValidation";
import * as config from "react-global-configuration";
import { fetcher } from "app2/src/helpers/Fetcher";
import { ICalculatedFinancingData } from "app2/src/records/CalculatedFinancing";
import { JobRecord } from "./Job";

export const fromJSON = (json: Partial<IFinanceOptionData>): FinanceOptionRecord => {
  const record: IFinanceOptionRecord = { ...(json as any) };

  if (json.validation && !Record.isRecord(json.validation)) {
    record.validation = validationFromJSON(json.validation);
  }

  if (!json.validation) {
    record.validation = validationFromJSON({});
  }

  return new FinanceOptionRecord(record);
};

export const toJSON = (record: FinanceOptionRecord): Partial<any> => {
  const jsonObj: any = record.toJSON() as any;

  if (jsonObj.id < 0) {
    delete jsonObj.id;
  }

  //@ts-ignore
  if (jsonObj.loading !== undefined) {
    //@ts-ignore
    delete jsonObj.loading;
  }

  return jsonObj;
};

export const hasRequiredApplicationData = (financeOption: FinanceOptionRecord, job: JobRecord): Array<string> => {
  return [];
};

export const filterByValidation = (
  financeOptions: List<FinanceOptionRecord>,
  estimate: EstimateRecord,
  jobState: string,
): List<FinanceOptionRecord> => {
  return (financeOptions = financeOptions.filter((fo) => {
    if (!fo.validation.active) {
      return true;
    }
    let testBoolean = true;
    const { min_amount, max_amount, min_payment, state } = fo.validation;
    const { financed_amount } = estimate.payment_terms;

    if (state !== "" && jobState !== state) {
      testBoolean = false;
    }

    if (min_amount >= 0 && financed_amount < min_amount) {
      testBoolean = false;
    }

    if (max_amount > 0 && financed_amount > max_amount) {
      testBoolean = false;
    }

    if (min_payment >= 0 && calculateRate(fo, estimate) < min_payment) {
      testBoolean = false;
    }

    return testBoolean;
  }));
};

export const filterByStatus = (financeOptions: List<FinanceOptionRecord>): List<FinanceOptionRecord> => {
  return (financeOptions = financeOptions.filter((fo) => {
    return fo.status === "active" || fo.status === "active_range";
  }));
};

export const range = (financeOptions: FinanceOptionRecord[], estimate: EstimateRecord) => {
  let min = 0,
    max = 0;

  _.each(financeOptions, (fo, idx) => {
    const rate = calculateRate(fo, estimate);

    if (idx === 0) {
      min = rate;
    }
    min = _.min([rate, min]);
    max = _.max([rate, max]);
  });

  if (min === max) {
    return `${CurrencyFormat(min, 0)}`;
  } else {
    return `${CurrencyFormat(min, 0)} - ${CurrencyFormat(max, 0)}`;
  }
};

export const setupForumulaParser = (estimate) => {
  const parser = new Parser();
  const keys = ["subtotal", "total", "product_total", "taxable_amount", "labor_total"];
  _.each(keys, (k) => {
    parser.setVariable(k, estimate[k]);
  });
  let financedAmount = 0;
  if (estimate.payment_terms) {
    financedAmount = estimate.payment_terms.financed_amount;
  }
  parser.setVariable("financed_amount", financedAmount);
  return parser;
};

export const localCalculations = (
  financeOptions: List<FinanceOptionRecord>,
  estimate: EstimateRecord | IEstimate,
): ICalculatedFinancingData[] => {
  let financedAmount = 0;
  if (Record.isRecord(estimate)) {
    financedAmount = estimate.getIn(["payment_terms", "financed_amount"]);
  } else {
    financedAmount = estimate.payment_terms.financed_amount;
  }
  return financeOptions
    .map((fo) => {
      return {
        monthly_payment: calculateRate(fo, estimate),
        promo_payment: 0,
        financed_amount: financedAmount,
        finance_option_id: fo.id,
        estimate_id: estimate.id,
        error: "",
      };
    })
    .toArray();
};

export const calculateRate = (financeOption: FinanceOptionRecord, estimate: EstimateRecord | EstimatePrototype) => {
  if (!financeOption) {
    return 0;
  }

  let rate = 0;
  if (_.isUndefined(financeOption.formula) || _.isNull(financeOption.formula) || financeOption.formula === "") {
    if (financeOption.rate === 0) {
      rate = _.round(estimate.payment_terms.financed_amount / financeOption.term, 0);
    } else {
      const finance = new Finance();
      rate = _.round(finance.AM(estimate.payment_terms.financed_amount, financeOption.rate, financeOption.term, 1), 0);
    }
  } else {
    const parser = setupForumulaParser(estimate);
    rate = _.round(parser.parse(financeOption.formula).result, 0);
  }

  return rate;
};

export const rate = (financeOption: FinanceOptionRecord, estimate: EstimateRecord) => {
  const rate = calculateRate(financeOption, estimate);

  return `${CurrencyFormat(rate, 0)}`;
};

export const getUrl = (financeOption: FinanceOptionRecord, estimate: EstimateRecord) => {
  if (!financeOption) return "";

  if (financeOption.provider === FinanceOptionProviders.get(0)) {
    return financeOption.apply_now_url;
  }

  const id = `${estimate.uid}-${financeOption.org_uid}`;
  return fetcher.joinUrls(config.get("APP_URL"), "finance_options", id, "apply");
};

export interface IFinanceOptionData {
  id: number;
  name: string;
  estimate_id: number;
  org_id: number;
  org_uid: string;
  provider: FinanceOptionProvider;
  integrations_identifier: string;
  rate: number;
  sort_order: number;
  status: FinanceOptionStatus;
  term: number;
  formula: string;
  apply_now_url: string;
  merchant_fee: number;
  promo_fee: number;
  promo_rate: number;
  promo_term: number;
  source: any;
  validation: any;
  loading?: boolean;

  archive_number: string;
  archived_at: Date;
  archived: boolean;

  created_at: Date;
  updated_at: Date;
}

// No good way to not duplicate things
export type FinanceOptionStatus = "active" | "inactive" | "active_range";
export const FinanceOptionStatuses: Array<FinanceOptionStatus> = ["active", "inactive", "active_range"];

// No good way to not duplicate things
export type FinanceOptionProvider = "" | "green_sky" | "fin_mkt" | "fin_mkt_offer";
export const FinanceOptionProviders = List<FinanceOptionProvider>(["", "green_sky", "fin_mkt", "fin_mkt_offer"]);
export const DisabledCreationProviders = List<FinanceOptionProvider>([]);
export const PrequalificationProviders = List<FinanceOptionProvider>([]);
export const AsyncCalcFinanceOptionProviders = List<FinanceOptionProvider>([]);
export const LocalCalcFinanceOptionProviders = List<FinanceOptionProvider>(["", "green_sky"]);

export const formatStatus = (type: FinanceOptionStatus): string => {
  switch (type) {
    case "active":
      return "Active";
    case "inactive":
      return "Disabled";
    case "active_range":
      return "Active Range";
  }
};

export interface IFinanceOptionRecord {
  id: number;
  name: string;
  estimate_id: number;
  org_id: number;
  org_uid: string;
  provider: FinanceOptionProvider;
  integrations_identifier: string;
  rate: number;
  sort_order: number;
  status: FinanceOptionStatus;
  term: number;
  formula: string;
  apply_now_url: string;
  merchant_fee: number;
  promo_fee: number;
  promo_rate: number;
  promo_term: number;
  source: any;
  validation: any;
  metadata: Map<any, any>;

  loading: boolean;
  errors: Nullable<List<string>>;

  archive_number: Nullable<string>;
  archived_at: Nullable<Date>;
  archived: boolean;

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

export const defaultFinanceOptionProps = {
  id: 0,
  name: "",
  estimate_id: null,
  org_id: 0,
  org_uid: "",
  provider: FinanceOptionProviders.get(0),
  integrations_identifier: "",
  rate: 0,
  sort_order: 0,
  status: FinanceOptionStatuses[0],
  term: 0,
  formula: "",
  apply_now_url: "",
  merchant_fee: 0,
  promo_fee: 0,
  promo_rate: 0,
  promo_term: 0,
  source: {},
  validation: {},
  metadata: Map(),
  archive_number: "",
  archived_at: null,
  archived: false,
  loading: false,
  created_at: null,
  updated_at: null,
  errors: List<string>(),
};

export class FinanceOptionRecord
  extends Record<IFinanceOptionRecord>(defaultFinanceOptionProps)
  implements IFinanceOptionRecord
{
  public readonly id!: number;
  public readonly name!: string;
  public readonly estimate_id!: number;
  public readonly org_id!: number;
  public readonly org_uid!: string;
  public readonly provider!: FinanceOptionProvider;
  public readonly integrations_identifier!: string;
  public readonly rate!: number;
  public readonly sort_order!: number;
  public readonly status!: FinanceOptionStatus;
  public readonly term!: number;
  public readonly formula!: string;
  public readonly apply_now_url!: string;
  public readonly merchant_fee!: number;
  public readonly promo_fee!: number;
  public readonly promo_rate!: number;
  public readonly promo_term!: number;
  public readonly source!: any;
  public readonly validation!: any;

  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public readonly archive_number!: Nullable<string>;
  public readonly archived_at!: Nullable<Date>;
  public readonly archived!: boolean;

  public readonly created_at!: Nullable<Date>;
  public readonly updated_at!: Nullable<Date>;

  public constructor(values?: Partial<IFinanceOptionRecord>) {
    values ? super(values) : super();
  }
}
