import * as React from "react";
import * as config from "react-global-configuration";
import { Row, Col, Button } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";

import { UserInvitationViewProps, UserInvitationViewState } from "../UserInvitationContainer";

export interface UserInvitationFormViewProps extends UserInvitationViewProps {
  enforceTos: boolean;
}

export class FormView extends React.Component<UserInvitationFormViewProps, UserInvitationViewState> {
  public constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordChanged: false,
      passwordError: "",
      passwordValid: true,
      passwordConfirmation: "",
      passwordConfirmationChanged: false,
      passwordConfirmationValid: true,
      passwordConfirmationError: "",
      acceptTos: false,
    };

    this.passwordChanged = this.passwordChanged.bind(this);
    this.passwordConfirmationChanged = this.passwordConfirmationChanged.bind(this);

    this.passwordBlur = this.passwordBlur.bind(this);
    this.passwordConfirmationBlur = this.passwordConfirmationBlur.bind(this);
    this.formSubmission = this.formSubmission.bind(this);
    this.tosChanged = this.tosChanged.bind(this);
  }

  public formSubmission(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { onAction } = this.props;
    const { passwordValid, passwordConfirmationValid, password, passwordConfirmation, acceptTos } = this.state;

    if (onAction && passwordValid && passwordConfirmationValid) {
      onAction(password, passwordConfirmation, acceptTos);
    }
  }

  public passwordChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const { passwordConfirmation } = this.state;
    this.setState({
      password: event.target.value,
    });

    this.validatePassword(event.target.value, passwordConfirmation);
    this.validatePasswordConfirmation(event.target.value, passwordConfirmation);
  }

  public tosChanged(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      acceptTos: event.target.checked,
    });
  }

  public passwordConfirmationChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const { password } = this.state;

    this.setState({
      passwordConfirmation: event.target.value,
    });

    this.validatePassword(password, event.target.value);
    this.validatePasswordConfirmation(password, event.target.value);
  }

  public passwordBlur() {
    this.setState({
      passwordChanged: true,
    });

    const { password, passwordConfirmation } = this.state;
    this.validatePassword(password, passwordConfirmation);
    this.validatePasswordConfirmation(password, passwordConfirmation);
  }

  public passwordConfirmationBlur() {
    this.setState({
      passwordConfirmationChanged: true,
    });

    const { password, passwordConfirmation } = this.state;
    this.validatePassword(password, passwordConfirmation);
    this.validatePasswordConfirmation(password, passwordConfirmation);
  }

  public validatePassword(password, passwordConfirmation) {
    if (password.length <= 0) {
      this.setState({
        passwordError: "You did not enter a field",
        passwordValid: false,
      });
    } else if (password.length < 6) {
      this.setState({
        passwordError: "Your field is too short",
        passwordValid: false,
      });
    } else if (password !== passwordConfirmation) {
      this.setState({
        passwordError: "Your passwords do not match",
        passwordValid: false,
      });
    } else {
      this.setState({
        passwordError: "",
        passwordValid: true,
      });
    }
  }

  public validatePasswordConfirmation(password, passwordConfirmation) {
    if (passwordConfirmation.length <= 0) {
      this.setState({
        passwordConfirmationError: "You did not enter a field",
        passwordConfirmationValid: false,
      });
    } else if (password !== passwordConfirmation) {
      this.setState({
        passwordConfirmationError: "Your passwords do not match",
        passwordConfirmationValid: false,
      });
    } else {
      this.setState({
        passwordConfirmationError: "",
        passwordConfirmationValid: true,
      });
    }
  }

  public render() {
    const {
      password,
      passwordChanged,
      passwordValid,
      passwordError,
      passwordConfirmation,
      passwordConfirmationChanged,
      passwordConfirmationValid,
      passwordConfirmationError,
    } = this.state;
    const { serverErrors, enforceTos } = this.props;

    let passwordErrorDiv = null;
    if (!passwordValid) {
      passwordErrorDiv = (
        <div className="has-error" role="alert">
          <div>{passwordError}</div>{" "}
        </div>
      );
    }

    let passwordConfirmationErrorDiv = null;
    if (!passwordValid) {
      passwordConfirmationErrorDiv = (
        <div className="has-error" role="alert">
          <div>{passwordConfirmationError}</div>
        </div>
      );
    }

    let serverErrorsDiv = null;
    if (serverErrors && serverErrors.length > 0) {
      serverErrorsDiv = (
        <Row>
          <Col md={12}>
            {serverErrors.map((error, idx) => {
              return (
                <div key={idx} className="alert alert-danger">
                  {error}
                </div>
              );
            })}
          </Col>
        </Row>
      );
    }

    return (
      <SpinnerComponent stateProperty={["auth", "authenticating"]}>
        <form name="passwdForm" onSubmit={this.formSubmission}>
          <div className="form">
            <div className={passwordValid || !passwordChanged ? "form-group" : "form-group has-error"}>
              <label>New Password</label>
              <input
                type="password"
                className="form-control"
                id="new_password"
                value={password}
                onChange={this.passwordChanged}
                onBlur={this.passwordBlur}
                required
              />
              {passwordErrorDiv}
            </div>
            <div
              className={
                passwordConfirmationValid || !passwordConfirmationChanged ? "form-group" : "form-group has-error"
              }>
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                id="confirm_password"
                value={passwordConfirmation}
                onChange={this.passwordConfirmationChanged}
                onBlur={this.passwordConfirmationBlur}
                required
              />
              {passwordConfirmationErrorDiv}
            </div>
            {enforceTos ? this.renderTos() : null}
          </div>
          {serverErrorsDiv}
          <Row>
            <Col md={12}>
              <Button variant="default" type="submit">
                Change
              </Button>
            </Col>
          </Row>
        </form>
      </SpinnerComponent>
    );
  }

  public renderTos() {
    const { acceptTos } = this.state;
    return (
      <div className="form-group">
        <label>
          Accept&nbsp;<a href="/terms">Terms and Conditions</a>
        </label>
        <input
          type="checkbox"
          checked={acceptTos}
          onChange={this.tosChanged}
          className="form-control"
          name="accepted_tos"
          required
        />
      </div>
    );
  }
}
