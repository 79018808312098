import * as React from "react";
import * as eagleviewActions from "app2/src/reducers/integrations/eagleview.actions";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { JobListContainer, IJobListContainerData } from "../JobListContainer";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { RootState, RootActions } from "app2/src/reducers";
import Spinner from "app2/src/components/SpinnerComponent";
import { Page } from "app2/src/api/integrations/eagleview.service";

const mapStateToProps = (state: RootState, ownProps: EagleviewReportImportProps) => {
  const page = state.getIn(["integrations", "eagleview", "page"], 1);

  return {
    page: page,
    reports: state.getIn(["integrations", "eagleview", "pages", page]),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: EagleviewReportImportProps,
) => {
  return {
    queryReportPage: (page: number) => {
      return dispatch(eagleviewActions.AsyncActions.getPage(page));
    },
    importReport: (report: IJobListContainerData) => {
      return dispatch(eagleviewActions.AsyncActions.importReport(report.id));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface EagleviewReportImportProps {}

interface EagleviewReportImportState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & EagleviewReportImportProps;

class EagleviewReportImportComponent extends React.Component<Props, EagleviewReportImportState> {
  constructor(props: Props) {
    super(props);

    props.queryReportPage(1);
  }

  public render() {
    const { page, reports, importReport } = this.props;

    let jobs, meta;
    if (reports && reports.reports) {
      jobs = reports.reports.map((r) => {
        return {
          id: r.id,
          name: `${r.street_1} ${r.city}, ${r.state} ${r.zip}`,
        };
      });

      meta = reports.meta.pagination;
    } else {
      jobs = [];
      meta = {};
    }

    return (
      <Spinner stateProperty={["integrations", "eagleview", "pages", page, "loading"]}>
        <>
          <JobListContainer action={"Import"} actionFired={this.props.importReport} jobs={jobs}></JobListContainer>
          <StandardPagination pageChanged={this.props.queryReportPage} metadata={meta}></StandardPagination>
        </>
      </Spinner>
    );
  }
}

export default connector(EagleviewReportImportComponent);
