import * as angulartics from "angulartics";
import { IMeasurement, IMeasurementResource } from "app/src/Models/Measurement";
import { IOrg } from "app/src/Models/Org";

export class MeasurementEditorComponentCtrl implements ng.IComponentController {
  public types: string[] = [];
  public addLocation = false;
  public addLevel = false;
  public measurement: IMeasurement;
  public opening: any;
  public org: IOrg;

  constructor(
    public $analytics: angulartics.IAnalyticsService,
    protected Measurement: IMeasurementResource,
  ) {}

  public $onChanges() {
    if (this.measurement.locations) {
      this.measurement.locations = _.uniq(this.measurement.locations.concat(this.Measurement.defaultLocations));
    } else {
      this.measurement.locations = _.uniq(this.Measurement.defaultLocations);
    }
    if (this.measurement.levels) {
      this.measurement.levels = _.uniq(this.measurement.levels.concat(this.Measurement.defaultLevels));
    } else {
      this.measurement.levels = _.uniq(this.Measurement.defaultLevels);
    }

    this.measurement.levels = _.sortBy(this.measurement.levels, function (n) {
      return n.toLowerCase();
    });
    this.measurement.locations = _.sortBy(this.measurement.locations, function (n) {
      return n.toLowerCase();
    });

    if (this.org) {
      this.types = this.org.measurementTypes();
    }
  }

  public enableLocationAdd() {
    this.addLocation = true;
    this.opening.location = "";
  }

  public addNewLocation() {
    if (this.opening.location !== "") {
      if (!this.measurement.locations) {
        this.measurement.locations = [];
      }
      this.measurement.locations.unshift(this.opening.location);
      this.measurement.locations = _.sortBy(_.uniq(this.measurement.locations), function (n) {
        return n.toLowerCase();
      });
    }

    this.addLocation = false;
  }

  public enableLevelAdd() {
    this.addLevel = true;
    this.opening.level = "";
  }

  public addNewLevel() {
    if (this.opening.level !== "") {
      if (!this.measurement.levels) {
        this.measurement.levels = [];
      }
      this.measurement.levels.unshift(this.opening.level);
      this.measurement.levels = _.sortBy(_.uniq(this.measurement.levels), function (n) {
        return n.toLowerCase();
      });
    }

    this.addLevel = false;
  }

  public cleanUi() {
    if (!this.opening.height || !this.opening.width) {
      return 0;
    }
    return _.round(this.opening.height + this.opening.width, 0);
  }

  public cleanSqft() {
    if (!this.opening.height || !this.opening.width) {
      return 0;
    }
    return (this.opening.height * this.opening.width) / 144;
  }
}

export class MeasurementEditorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Jobs/measurement_editor_component.html";
  public bindings: any = {
    measurement: "<",
    opening: "<",
    org: "<",
  };
  public replace = true;

  constructor() {
    this.controller = MeasurementEditorComponentCtrl;
    this.controller.$inject = ["$analytics", "Measurement"];
  }
}
