import { fetchCustomerAddress, JobRecord } from "app2/src/records/Job";
import { toCityStateZip, toOneLiner } from "app2/src/records/Address";
import { DateFormat, SnakeToPascal } from "app2/src/helpers/Format";
import { emptyUserValue, getFullName } from "app2/src/records/UserRecord";
import { getIn } from "immutable";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { us_states_abbreviation } from "configs/states/us-ca";

export const staticFields = (job: JobRecord, org: OrgRecord) => {
  return {
    CustomerName: job.customer_name,
    JobName: job.name,
    CustomerPhone: job.phone_numbers.first({ number: "" }).number,
    CustomerPhone2: job.getIn(["phone_numbers", 1, "number"]) || "",
    CustomerPhone3: job.getIn(["phone_numbers", 2, "number"]) || "",
    CustomerFullAddress: toOneLiner(job.address),
    CustomerAddressLine1: job.address.line_1,
    CustomerAddressLine2: job.address.line_2,
    CustomerAddressCity: job.address.city,
    CustomerAddressState: job.address.state,
    CustomerAddressStateCode: us_states_abbreviation[job.address.state],
    CustomerAddressZip: job.address.postal_code,
    CustomerAddressCityStateZip: toCityStateZip(job.address),
    JobFullAddress: toOneLiner(job.address),
    JobAddressLine1: job.address.line_1,
    JobAddressLine2: job.address.line_2,
    JobAddressCity: job.address.city,
    JobAddressState: job.address.state,
    JobAddressStateCode: us_states_abbreviation[job.address.state],
    JobAddressZip: job.address.postal_code,
    JobAddressCityStateZip: toCityStateZip(job.address),
    JobCustomerFullAddress: toOneLiner(fetchCustomerAddress(job)),
    JobCustomerAddressLine1: fetchCustomerAddress(job).line_1,
    JobCustomerAddressLine2: fetchCustomerAddress(job).line_2,
    JobCustomerAddressCity: fetchCustomerAddress(job).city,
    JobCustomerAddressState: fetchCustomerAddress(job).state,
    JobCustomerAddressStateCode: us_states_abbreviation[fetchCustomerAddress(job).state],
    JobCustomerAddressZip: fetchCustomerAddress(job).postal_code,
    JobCustomerAddressCityStateZip: toCityStateZip(fetchCustomerAddress(job)),
    CustomerEmail: job.email,
    CurrentDate: DateFormat(new Date(), "shortDate"),
    SalesrepFirstName: getIn(job, ["assignments", "0", "user", "first_name"], ""),
    SalesrepLastName: getIn(job, ["assignments", "0", "user", "last_name"], ""),
    SalesrepName: getFullName(getIn(job, ["assignments", "0", "user"], emptyUserValue)),
    SalesrepEmail: getIn(job, ["assignments", "0", "user", "email"], ""),
    SalesrepPhone: getIn(job, ["assignments", "0", "user", "phone"], ""),
    OrgName: org.name,
    OrgFullAddress: toOneLiner(org.address),
    OrgAddressLine1: org.address.line_1,
    OrgAddressLine2: org.address.line_2,
    OrgAddressCity: org.address.city,
    OrgAddressState: org.address.state,
    OrgAddressStateCode: us_states_abbreviation[org.address.state],
    OrgAddressZip: org.address.postal_code,
    OrgAddressCityStateZip: toCityStateZip(org.address),
  };
};

export const dynamicFields = (job: JobRecord) => {
  const fieldsData = {};
  const jobMetadata = job.metadata.toJSON();

  if (!jobMetadata) return;

  let integrationKeyIndex = 0;

  while (integrationKeyIndex < 6) {
    if (
      jobMetadata[`integration_key_${integrationKeyIndex}`] &&
      jobMetadata[`integration_value_${integrationKeyIndex}`]
    ) {
      fieldsData[SnakeToPascal(jobMetadata[`integration_key_${integrationKeyIndex}`])] =
        jobMetadata[`integration_value_${integrationKeyIndex}`];
    }
    integrationKeyIndex++;
  }

  Object.keys(jobMetadata).forEach((key) => {
    if (key.endsWith("_id")) fieldsData[SnakeToPascal(key)] = jobMetadata[key];
  });

  return fieldsData;
};
export const fillableFields = (job: JobRecord, org: OrgRecord) => {
  if (!job || !org) return;

  return { ...staticFields(job, org), ...dynamicFields(job) };
};

export const buildFileName = (job: JobRecord, documentName: string): string => {
  const date = DateFormat(new Date(), "short-hyphenated").replace(":", "");
  if (documentName) {
    return `Filled ${documentName.replace(".pdf", "")} - Saved ${date}.pdf`;
  }

  return `${job?.name} - Saved ${date}.pdf`;
};

export const downloadFileName = (name: string): string => {
  return name.replace(/[ \:\+\(\)]/g, "_");
};
