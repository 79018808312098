class SpinnerCtrl {
  public promise: ng.IPromise<any>;
  public show: boolean;
  private _savedPromise: ng.IPromise<any>;

  constructor() {}

  public $onChanges() {
    if (this.promise && this.promise !== this._savedPromise) {
      this.show = true;
      this.promise.finally(() => {
        this.show = false;
      });
    }
  }
}

export class SpinnerComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    promise: "<",
    show: "<",
  };
  public templateUrl = "src/Common/spinner.html";

  constructor() {
    this.controller = SpinnerCtrl;
    this.controller.$inject = [];
  }
}
