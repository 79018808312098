import * as React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { List } from "immutable";
import * as config from "react-global-configuration";
import { IUserPreference } from "app/src/Models/UserPreference";
import { IUser } from "app/src/Models/User";
import { validatePhoneNumber, validateEmail } from "app2/src/helpers/InputValidator";
import { Dispatch } from "app2/src/helpers/Analytics";
import track from "react-tracking";
import { getContractName, OrgRecord } from "app2/src/records/OrgRecord";
import { currentUserOrg } from "app2/src/selectors/user.selectors";
import { RootState } from "app2/src/reducers";
import { connect } from "app2/src/connect";

interface NotificationsProps {
  user: IUser;
  userPreferences: IUserPreference;
  updateUserNotifications: any;
  setNotificationError: (value: boolean) => void;
  org: OrgRecord;
}
interface NotificationsState {
  validPhone: boolean;
  validEmail: boolean;
}

@track(
  (props) => {
    return {
      category: "Profile",
      component: "Notifications",
      user: props.user.id,
    };
  },
  {
    dispatch: (data) => Dispatch.dispatch(data),
    dispatchOnMount: (cd) => ({ action: "shown" }),
  },
)
class Notifications extends React.Component<NotificationsProps, NotificationsState> {
  constructor(props) {
    super(props);

    this.state = {
      validPhone: true,
      validEmail: true,
    };

    this.notificationOnChange = this.notificationOnChange.bind(this);
  }

  public notificationOnChange(type, name, value) {
    const { updateUserNotifications, setNotificationError } = this.props;
    updateUserNotifications(type, name, value);
    if (name === "phone") {
      const validPhone = validatePhoneNumber(value);
      setNotificationError(!validPhone);
      this.setState({ validPhone });
    } else if (name === "email") {
      const validEmail = validateEmail(value);
      setNotificationError(!validEmail);
      this.setState({ validEmail });
    }
    this.forceUpdate();
  }

  public renderCheckboxes(type) {
    const { userPreferences, org } = this.props;

    const options = [
      "job_created",
      "customer_photo_uploaded",
      "signed_document_signed",
      "signable_documents_signed",
      "job_reassigned",
    ];
    const labels = [
      "Job Created",
      "Customer Photo Uploaded",
      `Signed Document (${getContractName(org)})`,
      "Signable Documents",
      "Job Reassigned",
    ];
    const existingOptions = List(userPreferences.config.notifications[type]);

    return (
      <React.Fragment>
        <React.Fragment>
          <Form.Check
            className="notification-checkbox"
            type="checkbox"
            label="Select All"
            checked={existingOptions.size === 6}
            onChange={(e) => this.notificationOnChange(type, "all", e.target.checked)}
          />
          <br />
        </React.Fragment>

        {options.map((option, index) => (
          <React.Fragment key={index}>
            <Form.Check
              className="notification-checkbox"
              type="checkbox"
              label={labels[index]}
              checked={existingOptions.size === 6 || existingOptions.includes(option)}
              onChange={(e) => this.notificationOnChange(type, option, e.target.checked)}
            />
            {option === "all" && <br />}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  public render() {
    const { userPreferences } = this.props;
    const { validPhone, validEmail } = this.state;

    if (!userPreferences) {
      return <React.Fragment></React.Fragment>;
    }

    const smsNumber = userPreferences.sms_number;
    const phoneOptions = List(userPreferences.config.notifications.via_sms);

    return (
      <div className="form-section">
        <div className="form-section-heading">
          <Row>
            <Col md={12}>
              <h2>Notifications</h2>
            </Col>
          </Row>
        </div>
        <div className="form-section-content">
          <Row>
            <Col md={12}>
              <p>
                <strong>
                  You will receive notifications at the email address provided by your account administrator. You can
                  change your preferences at any time by following the instructions provided below.
                </strong>
              </p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  id="notification-phone"
                  placeholder="Mobile Number"
                  defaultValue={smsNumber.number}
                  onChange={(e) => this.notificationOnChange("user", "phone", e.target.value)}
                />
                {!validPhone && <span className="has-error">Please enter a valid phone number.</span>}
                {smsNumber.status !== "subscribed" && phoneOptions.size > 0 && (
                  <div className="has-error">
                    You have opted-out of receiving notification texts by previously replying “STOP” to a notification
                    text message. If you would like to resubscribe, reply “UNSTOP” to{" "}
                    <a href={`sms:${config.get("TWILIO_NUMBER")}`}>{config.get("TWILIO_NUMBER")}</a>.
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={2}></Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  id="notification-email"
                  placeholder="Email"
                  defaultValue={userPreferences.config.notifications.email}
                  onChange={(e) => this.notificationOnChange("user", "email", e.target.value)}
                />
                {!validEmail && <span className="has-error">Please enter a valid email.</span>}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <h4>Send me these notifications by text:</h4>
              {this.renderCheckboxes("via_sms")}
            </Col>
            <Col md={2}></Col>
            <Col md={4}>
              <h4>Send me these notifications by email:</h4>
              {this.renderCheckboxes("via_email")}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4}>
              <p>
                By entering a mobile number, checking the notifications by text box and clicking SAVE, you consent to
                receiving notifications via automated text messages to the mobile phone number you provided above. Your
                consent to these communications is not required for any purchase. Message frequency for notifications
                varies. Message and data rates may apply. Reply “STOP” to a notification text or uncheck the
                notifications box above and click SAVE to end notification texts.
              </p>
              <br />
              <p>
                If you opt-out of receiving notification texts by replying “STOP” to a notification text message and you
                would like to resubscribe, reply “UNSTOP”.
              </p>
              <br />
              <p>
                If you opt-out of receiving notification texts by unchecking the notifications box above and you would
                like to subscribe again, enter your mobile number, check the notifications box and click SAVE.
              </p>
            </Col>
            <Col md={2}></Col>
            <Col md={4}>
              <p>
                To update the email address where you receive notifications or to resubscribe after opting-out, enter
                your email address, check the notifications by email box and click SAVE. To stop receiving notifications
                by email, uncheck the notifications box above and click SAVE or click the unsubscribe link in any
                notification email.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const org = currentUserOrg(state);
  return { org };
};

const connector = connect(mapStateToProps);

export default connector(Notifications);
