import { Record, Map, List } from "immutable";
import * as eventResultReasonActions from "./eventResultReason.actions";
import { RootActions, RootState } from "app2/src/reducers";
import { EventResultReasonRecord, fromJSON } from "../records/EventResultReason";

export const eventResultReasonStateRecord = Record({
  byId: Map<number, EventResultReasonRecord>(),
  byOrgId: Map<number, List<number>>(),
});

export const initialState = eventResultReasonStateRecord();
export type eventResultReasonState = typeof initialState;

export const model = "eventResultReasons";
export const reducer = (state: RootState, action: RootActions): RootState => {
  switch (action.type) {
    case eventResultReasonActions.RECEIVE_EVENT_RESULT_REASON:
      const reason = fromJSON(action.payload.reason);
      return state.setIn([model, "byId", reason.id], reason);

    case eventResultReasonActions.RECEIVE_EVENT_RESULT_REASONS:
      const reasonsByOrgId = [];
      action.payload.reasons.forEach((reason) => {
        state = reducer(state, eventResultReasonActions.Actions.receiveEventResultReason(action.payload.orgId, reason));
        reasonsByOrgId.push(reason.id);
      });

      return state.setIn([model, "byOrgId", action.payload.orgId], List(reasonsByOrgId));

    default:
      return state;
  }
};
