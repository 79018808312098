import * as React from "react";
import { Button } from "react-bootstrap";
import useLocalAudioToggle from "app2/src/components/Twilio/hooks/useLocalAudioToggle/useLocalAudioToggle";
import * as FontAwesome from "react-fontawesome";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import { useTracking } from "react-tracking";

export default function ToggleAudioButton(props: { disabled?: boolean }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const {
    roomParams: { audio_preference },
  } = useVideoContext();

  const { trackEvent } = useTracking();

  const toogleAudio = () => {
    const action = isAudioEnabled ? "mute audio" : "unmute audio";
    if (typeof toggleAudioEnabled === "function") {
      trackEvent({ action });
      toggleAudioEnabled();
    }
  };

  if (audio_preference === "disabled") {
    return null;
  }

  return (
    <Button
      // @ts-ignore
      onClick={toogleAudio}
      className="control-button"
      disabled={props.disabled}>
      {isAudioEnabled ? (
        <FontAwesome name="microphone" title="Mute Audio" size="lg" />
      ) : (
        <FontAwesome name="microphone-slash" title="Unmute Audio" size="lg" />
      )}
    </Button>
  );
}
