import { DateFormat } from "app2/src/helpers/Format";
import { RootState } from "app2/src/reducers";
import { AsyncActions } from "app2/src/reducers/event.actions";
import { currentJob } from "app2/src/selectors/job.selectors";
import { push } from "connected-react-router/immutable";
import * as React from "react";
import { Button, FormControl, FormGroup, FormLabel, Modal, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { event as eventSelector } from "app2/src/selectors/event.selectors";
import { useRouteMatch } from "react-router";
import { useTracking } from "react-tracking";
import { email as validateEmail } from "app2/src/helpers/FinalFormValidator";

export const SendInviteModal: React.FC = () => {
  const dispatch = useDispatch();
  const jobEmail = useSelector((state: RootState) => currentJob(state)?.email);
  const match = useRouteMatch<{ eventId: string }>();
  const { trackEvent } = useTracking<any>({ component: "SendInviteModal" });
  const eventId = parseInt(match.params.eventId);
  const event = useSelector((state: RootState) => eventSelector(state, { eventId }));

  const [summary, setSummary] = React.useState(event?.summary);
  const [email, setEmail] = React.useState(jobEmail);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (event) setSummary(event.summary);
  }, [event]);

  const validate = (email: string) => {
    let error;

    error = validateEmail(email);
    if (!email) {
      error = "Email is required";
    }
    setError(error);

    return error;
  };

  const onEmailChange = (email: string) => {
    setEmail(email);
    validate(email);
  };

  const send = () => {
    if (validate(email)) return;

    trackEvent({ action: "Send Appointment Invite", event: event.id, email });
    dispatch(AsyncActions.sendInvite(event.job_id, { emails: email, summary, event_id: event.id }));
    close();
  };

  const close = () => {
    const prevPath = match.path.split("/invite")[0];
    dispatch(push(prevPath));
  };

  const cancel = () => {
    trackEvent({ action: "Close SendInviteModal", event: event.id });
    close();
  };

  return (
    <Modal show={true} onHide={cancel} size="sm" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Send Invite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <FormLabel htmlFor="summary">Summary</FormLabel>
          <FormControl type="text" value={summary} onChange={(e) => setSummary(e.target.value)} id="summary" />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="email">Email</FormLabel>
          <FormControl type="text" value={email} onChange={(e) => onEmailChange(e.target.value)} id="email" />
          <Form.Control.Feedback type="invalid" className="block">
            {error}
          </Form.Control.Feedback>
        </FormGroup>
        <Row>
          <Col xs={3}>From:</Col>
          <Col xs={9}>{DateFormat(event?.start_time, "shortNameTime")}</Col>
        </Row>
        <Row>
          <Col xs={3}>To:</Col>
          <Col xs={9}>{DateFormat(event?.end_time, "shortNameTime")}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={send}>Send</Button>
        <Button variant="secondary" onClick={cancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
