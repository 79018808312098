import { ActivatedPriceListRecord } from "app2/src/records/ActivatedPriceList";
import { ProductRecord } from "app2/src/records/Product";
import { ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { RootState } from "app2/src/reducers";
import { activatedPriceList } from "app2/src/selectors/activatedPriceList.selectors";
import { cachedProduct } from "app2/src/selectors/product.selectors";
import { List } from "immutable";
import { createSelector } from "reselect";

export const productOptionGroup = (state: RootState | ActivatedPriceListRecord, props: any) =>
  state.getIn(["productOptionGroups", "byId", props.productOptionGroupId]);
export const productOptionGroupsById = (state: RootState | ActivatedPriceListRecord) =>
  state.getIn(["productOptionGroups", "byId"]);

export const productOptionGroups = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<ProductOptionGroupRecord> => {
    const { state, props } = stateProps;
    const { productOptionGroupIds } = props;
    return productOptionGroupIds.map((id: number) => productOptionGroup(state, { productOptionGroupId: id }));
  },
);

export const productOptionGroupByMatchId = createSelector(
  [productOptionGroups, (state, props) => ({ state, props })],
  (productOptionGroups: List<ProductOptionGroupRecord>, stateProps: any): ProductOptionGroupRecord => {
    const { props } = stateProps;
    const { productOptionGroupMatchId } = props;
    return productOptionGroups.find(
      (productOptionGroup: ProductOptionGroupRecord) => productOptionGroup.match_id === productOptionGroupMatchId,
    );
  },
);

export const cachedProductOptionGroups = createSelector(
  [activatedPriceList, cachedProduct, (_: RootState, props: any) => props],
  (
    activatedPriceList: ActivatedPriceListRecord,
    cachedProduct: ProductRecord,
    props: any,
  ): List<ProductOptionGroupRecord> => {
    const { type } = props;
    const removeTypeFilter = !type;
    const productOptionGroupIds = cachedProduct.option_group_ids;
    const groups = productOptionGroups(activatedPriceList, {
      productOptionGroupIds,
    });
    return groups.filter((group: ProductOptionGroupRecord) => {
      return removeTypeFilter || group.pog_type === type || (!group.pog_type && type === "default");
    });
  },
);

export const cachedProductOptionGroup = createSelector(
  [activatedPriceList, (_: RootState, props: any) => props],
  (activatedPriceList: ActivatedPriceListRecord, props: any): ProductOptionGroupRecord => {
    return productOptionGroup(activatedPriceList, props);
  },
);

export const cachedProductOptionGroupsById = createSelector(
  [activatedPriceList],
  (activatedPriceList: ActivatedPriceListRecord): Map<number, ProductOptionGroupRecord> => {
    return productOptionGroupsById(activatedPriceList);
  },
);
