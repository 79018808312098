import { DateFormat } from "app2/src/helpers/Format";
import * as React from "react";

export type DateTimeVariant = "vertical";

export interface DateTimeProps {
  date: Date;
  variant: DateTimeVariant;
}

interface DateTimeState {}

export default class DateTime extends React.Component<DateTimeProps, DateTimeState> {
  constructor(props: DateTimeProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const { date } = this.props;

    return (
      <>
        <p style={{ marginBottom: "0" }}>{DateFormat(date, "shortName")}</p>
        <small>{DateFormat(date, "shortTime")}</small>
      </>
    );
  }
}
