import { fetcher } from "app2/src/helpers/Fetcher";
import { ITaskData } from "app2/src/records/Task";

class TaskService {}

export class TaskPoller {
  public promise: Promise<ITaskData>;
  public task: ITaskData;
  public callback: (task: ITaskData) => void;
  public timedOut: boolean;

  constructor(public url: string) {
    const app = this;
    this.promise = new Promise<ITaskData>((resolve, reject) => {
      function poll(time: number) {
        fetcher
          .get<{ task: ITaskData }>(app.url)
          .then((task: { task: ITaskData }) => {
            app.task = task.task;

            if (app.callback) {
              app.callback(app.task);
            }

            switch (app.task.status) {
              case "finished": // finished
                resolve(app.task);
                break;
              case "submitted": // submitted or processing
              case "processing":
                setTimeout(poll, time, time);
                break;
              case "error": // error
              default:
                // invalid status
                reject(app.task);
                break;
            }
          })
          .catch((error) => {
            if (error.status === 404) {
              // First error
              if (app.timedOut === undefined) {
                app.timedOut = false;

                // Stop trying after 2 minutes
                setTimeout(() => {
                  app.timedOut = true;
                }, 120000);

                // Try again
                setTimeout(poll, time, time);
              } else if (app.timedOut === false) {
                setTimeout(poll, time, time);
              } else {
                reject({ results: { error_message: "Our servers encountered an error. Please try again later." } });
              }
            } else {
              reject({ results: { error_message: null } });
            }
          });
      }

      poll(1000);
    });
  }
}

export const taskService = new TaskService();
