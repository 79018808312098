import * as React from "react";
import { connect } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { RootDispatchType } from "app2/src/store";
import { ConnectedProps } from "react-redux";
import track from "react-tracking";
import { IPretty } from "app/src/Common/PrettyNameService";
import Folders from "app2/src/components/Folders";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import EstimateTemplates from "app2/src/components/JobTabs/EstimateTab/EstimateTemplateModal/EstimateTemplates";
import { IEstimateTemplate } from "app/src/Models/EstimateTemplate";
import MoveFilesDropdown from "app2/src/components/Folders/MoveFilesDropdown";
import { estimateTemplatesSelected } from "app2/src/selectors/estimateTemplate.selectors";
import { SearchBox } from "app2/src/components/Common/SearchBox";
import * as estimateTemplateActions from "app2/src/reducers/estimateTemplate.actions";
import * as folderActions from "app2/src/reducers/folder.actions";
import { queryParams } from "app2/src/selectors/pagination.selectors";
import { queryParamsFromJSON, QueryParamsRecord } from "app2/src/records/Page";
import { Dispatch } from "app2/src/helpers/Analytics";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { Acl } from "app2/src/helpers/Acl";
import { OrgAclType } from "app/src/Models/Org";
import { List } from "immutable";
import { SelectRooms } from "./SelectRooms";
import { currentEstimateGroups } from "app2/src/selectors/estimateGroup.selectors";
import { StoreRegistry } from "app2/src/storeRegistry";

const mapStateToProps = (state: RootState) => {
  const modelName = "estimateTemplate",
    templateQueryParams = queryParams(state, { modelName });

  return {
    folderParentId: queryParams(state, { modelName: "folder" }).getIn(["folder_id"]),
    orgId: currentOrgId(state),
    jobId: currentJobId(state),
    estimateTemplatesSelected: estimateTemplatesSelected(state, {}),
    templateQueryParams,
    estimateGroups: currentEstimateGroups(state, {}),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    listFolders: (folderParentId: number) =>
      dispatch(
        folderActions.AsyncActions.listFolders(folderParentId, { folderable_type: "Org", name: "Estimate Templates" }),
      ),
    listEstimateTemplates: (folderId: number, orgId: number, queryParams: QueryParamsRecord) =>
      dispatch(
        estimateTemplateActions.AsyncActions.listEstimateTemplates(
          {
            id: orgId,
            folderId,
          },
          queryParams,
        ),
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface EstimateTemplateModalState {
  show: boolean;
  folderParentId: number;
  name: string;
  interior: boolean;
  searching: boolean;
  readOnly: boolean;
  selectedInteriorTemplate: IEstimateTemplate;
  selectedRoomIds: List<number>;
  groupRooms: boolean;
}

export interface EstimateTemplateModalProps {
  show: number;
  saveAs: (selection: string | IEstimateTemplate, folderId: number, kind: string) => void;
  select: (selection: IEstimateTemplate, interior?: boolean) => void;
  readOnly?: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & EstimateTemplateModalProps;

@track(
  (props) => {
    return {
      category: "EstimateTemplates",
      component: "EstimateTemplateModal",
      action: "show",
      job: props.jobId,
      org: props.orgId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
class EstimateTemplateModal extends React.Component<Props, EstimateTemplateModalState> {
  public Pretty: IPretty;

  constructor(props: Props) {
    super(props);

    this.state = {
      show: false,
      folderParentId: null,
      name: "",
      interior: false,
      searching: false,
      readOnly: props.readOnly,
      selectedInteriorTemplate: null,
      selectedRoomIds: List(),
      groupRooms: false,
    };

    this.Pretty = StoreRegistry.get("Pretty");

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.navigate = this.navigate.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleInteriorChange = this.handleInteriorChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSaveAs = this.handleSaveAs.bind(this);
    this.queryEstimateTemplates = this.queryEstimateTemplates.bind(this);
    this.setReadOnly = this.setReadOnly.bind(this);
    this.cancelRooms = this.cancelRooms.bind(this);
    this.handleInteriorAdd = this.handleInteriorAdd.bind(this);
    this.toggleRoom = this.toggleRoom.bind(this);
    this.toggleGroupRooms = this.toggleGroupRooms.bind(this);
    this.toggleAllRooms = this.toggleAllRooms.bind(this);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { show, folderParentId, listEstimateTemplates, orgId } = this.props;

    if (show !== prevProps.show && !this.state.show) {
      this.handleShow();
    }

    if (this.state.show && !_.isNullOrUndefined(folderParentId) && prevProps.folderParentId !== folderParentId) {
      const params = Acl.orgCan("setting", OrgAclType.estimator, "interiors")
        ? queryParamsFromJSON({
            parameters: { kind: ["interior", "standard"] },
          })
        : new QueryParamsRecord();

      listEstimateTemplates(folderParentId, orgId, params);
    }
  }

  @track({ action: "close" })
  public handleClose() {
    this.setState({
      show: false,
      searching: false,
      selectedInteriorTemplate: null,
      selectedRoomIds: List([]),
      groupRooms: false,
    });
  }

  @track({ action: "cancel rooms" })
  public cancelRooms() {
    this.setState({ selectedInteriorTemplate: null });
  }

  @track({ action: "show" })
  public handleShow() {
    const { listFolders } = this.props;

    listFolders(null);
    this.setState({
      show: true,
      folderParentId: null,
      name: "",
      searching: false,
      readOnly: this.props.readOnly,
    });
  }

  @track({ action: "save" })
  public async handleSave(e: React.FormEvent<any>) {
    const { name } = this.state;
    e.preventDefault();
    e.stopPropagation();

    this.handleSaveAs(name);
  }

  public navigate(folderId: number): void {
    const { listFolders } = this.props;
    listFolders(folderId);
    this.setState({ folderParentId: folderId });
  }

  public handleNameChange(event): void {
    this.setState({ name: event.target.value });
  }

  @track((_props, _state, [event]) => ({
    action: "toggle interior template checkbox",
    check: event.target.checked,
  }))
  public handleInteriorChange(event): void {
    this.setState({ interior: event.target.checked });
  }

  public handleKeyPress(target): void {
    if (target.charCode === 13) {
      const { name } = this.state;
      this.handleSaveAs(name);
    }
  }

  public handleSaveAs(selection: string | IEstimateTemplate): void {
    const { folderParentId, interior } = this.state;
    const { saveAs } = this.props;
    const kind = interior ? "interior" : "standard";

    saveAs(selection, folderParentId, kind);
    this.handleClose();
  }

  public handleSelect(selection: IEstimateTemplate) {
    if (selection.kind === "standard") {
      const { select } = this.props;
      select(selection);
      this.handleClose();
    } else {
      this.setState({ selectedInteriorTemplate: selection });
    }
  }

  public handleInteriorAdd() {
    const { selectedInteriorTemplate, selectedRoomIds, groupRooms } = this.state;
    this.props.select({
      ...selectedInteriorTemplate,
      selectedRoomIds,
      groupRooms,
    } as IEstimateTemplate);
    this.handleClose();
  }

  @track((props, _state, [room]) => ({
    action: "toggle room",
    room,
  }))
  public toggleRoom(roomId: number) {
    const { selectedRoomIds } = this.state;
    if (selectedRoomIds.includes(roomId)) {
      this.setState({
        selectedRoomIds: selectedRoomIds.filter((id) => id !== roomId),
      });
    } else {
      this.setState({
        selectedRoomIds: selectedRoomIds.push(roomId),
      });
    }
  }

  @track({ action: "toggle group rooms" })
  public toggleGroupRooms() {
    const { groupRooms } = this.state;
    this.setState({ groupRooms: !groupRooms });
  }

  @track({ action: "toggle all rooms" })
  public toggleAllRooms(ids: List<number>) {
    const { selectedRoomIds } = this.state;
    if (selectedRoomIds.size !== ids.size) {
      this.setState({ selectedRoomIds: ids });
    } else {
      this.setState({ selectedRoomIds: List([]) });
    }
  }

  @track({ action: "setReadOnly" })
  public setReadOnly() {
    this.setState({ readOnly: false });
  }

  @track((_props, _state, [query]) => ({
    action: "queryEstimateTemplates",
    query,
  }))
  public queryEstimateTemplates(query: string): void {
    if (query.length > 0) {
      this.setState({ searching: true });
    } else {
      this.setState({ searching: false });
    }
    const { listEstimateTemplates, templateQueryParams, orgId } = this.props;
    let newQueryParams = templateQueryParams.setIn(["page"], 1).setIn(["query"], query);
    if (Acl.orgCan("setting", OrgAclType.estimator, "interiors")) {
      newQueryParams = newQueryParams.setIn(["parameters", "kind"], List(["standard", "interior"]));
    }

    listEstimateTemplates(null, orgId, newQueryParams);
  }

  public render() {
    const {
      show,
      folderParentId,
      name,
      interior,
      searching,
      readOnly,
      selectedInteriorTemplate,
      selectedRoomIds,
      groupRooms,
    } = this.state;
    const { estimateTemplatesSelected, estimateGroups } = this.props;

    const capitalizeTemplate = _.toTitleCase(this.Pretty.name["template"]);
    const editableFolder = Acl.can("update", "org");
    const interiorEnabled = Acl.orgCan("setting", OrgAclType.estimator, "interiors");

    return (
      <Modal size="xl" show={show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Row>
            <Col>
              <h3>My Estimate {capitalizeTemplate}s</h3>
            </Col>
            {selectedInteriorTemplate == null && (
              <Col>
                <div className="pull-right">
                  <SearchBox
                    searching={this.queryEstimateTemplates}
                    placeholder={`Search ${capitalizeTemplate}s`}
                    query=""
                  />
                </div>
              </Col>
            )}
          </Row>
        </Modal.Header>
        <Modal.Body>
          {selectedInteriorTemplate === null ? (
            <>
              {!readOnly && interiorEnabled && (
                <div className="alert-info p-4">
                  Note: This new estimate template contains multiple groups. Saving this as an Interior Template will
                  only save the first group "{estimateGroups.first()?.name}" to the estimate template.
                </div>
              )}
              {!readOnly && (
                <Row className="sticky-template">
                  <Col>
                    {estimateTemplatesSelected ? (
                      <MoveFilesDropdown folderParentId={folderParentId} fileType="estimateTemplates" />
                    ) : (
                      <div style={{ height: "35px" }}></div>
                    )}
                  </Col>
                </Row>
              )}
              <div
                className="estimate-template-body"
                style={{ height: readOnly ? "100%" : `calc(100% - ${interiorEnabled ? 200 : 150}px)` }}>
                {!searching && (
                  <Folders
                    {...this.props}
                    folderParentId={folderParentId}
                    navigate={this.navigate}
                    documentableType="org"
                    fileType="estimateTemplates"
                    readOnly={readOnly || !editableFolder}
                  />
                )}
                <br />
                <EstimateTemplates
                  saveAs={this.handleSaveAs}
                  select={this.handleSelect}
                  setReadOnly={this.setReadOnly}
                  readOnly={readOnly}
                  folderParentId={folderParentId}
                  templateName={capitalizeTemplate}
                />
              </div>
              {!readOnly && (
                <Row className="estimate-template-name">
                  <Col>
                    <div className="d-flex align-items-center">
                      <Form.Group className="w-100">
                        <Form.Label htmlFor="name">New {capitalizeTemplate}</Form.Label>
                        <Form.Control
                          placeholder={`Estimate ${capitalizeTemplate} Name`}
                          id="name"
                          name="name"
                          value={name}
                          onChange={this.handleNameChange}
                          onKeyPress={this.handleKeyPress}
                        />
                      </Form.Group>
                      {!readOnly && interiorEnabled && (
                        <Form.Group className="mb-0 ml-3" style={{ flexShrink: 0 }}>
                          <Form.Label htmlFor="name" className="mr-1">
                            Interior {capitalizeTemplate}
                          </Form.Label>
                          <Form.Check
                            type="checkbox"
                            inline
                            id="interior"
                            name="interior"
                            checked={interior}
                            onChange={this.handleInteriorChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <SelectRooms
              selectedRoomIds={selectedRoomIds}
              groupRooms={groupRooms}
              toggleRoom={this.toggleRoom}
              toggleGroupRooms={this.toggleGroupRooms}
              toggleAllRooms={this.toggleAllRooms}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {!readOnly && (
            <Button variant="primary" onClick={this.handleSave} disabled={name.length === 0}>
              Save
            </Button>
          )}
          {selectedInteriorTemplate !== null && (
            <Button variant="primary" onClick={this.handleInteriorAdd} disabled={selectedRoomIds.size === 0}>
              Add Template
            </Button>
          )}
          <Button variant="cancel" onClick={selectedInteriorTemplate !== null ? this.cancelRooms : this.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connector(EstimateTemplateModal);
