import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { folder } from "app2/src/selectors/folder.selectors";
import { Dropdown } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { FileType } from "app2/src/components/Folders";
import { RootDispatchType } from "app2/src/store";
import * as folderActions from "app2/src/reducers/folder.actions";
import track from "react-tracking";

const mapStateToProps = (state: RootState, ownProps: DropdownItemProps) => {
  return {
    folder: folder(state, { folderId: ownProps.folderId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: DropdownItemProps) => {
  return {
    moveFiles: () => dispatch(folderActions.AsyncActions.moveFiles(ownProps.folderId, ownProps.fileType)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DropdownItemProps {
  icon: string;
  folderId: number;
  fileType: FileType;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DropdownItemProps;

@track((props) => {
  return {
    component: "DropdownItem",
    folder: props.folderId,
    folderName: props.folder.name,
    fileType: props.fileType,
  };
})
class DropdownItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.moveFiles = this.moveFiles.bind(this);
  }

  @track(() => ({
    action: "move files",
  }))
  public moveFiles() {
    const { moveFiles } = this.props;
    moveFiles();
  }

  public render() {
    const { folder, icon } = this.props;
    return (
      <Dropdown.Item data-testid="dropdown-item" href="#" onClick={this.moveFiles}>
        <FontAwesome name={icon} data-testid={icon} />
        &nbsp;&nbsp;
        {folder.name}
      </Dropdown.Item>
    );
  }
}

export default connector(DropdownItem);
