import useFullScreenToggle from "app2/src/hooks/useFullScreenToggle/useFullScreenToggle";
import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";

export interface VisualizeRoomProps {
  url: string;
}

export const VisualizeRoom: React.FC<VisualizeRoomProps> = ({ url }) => {
  const [show, setShow] = React.useState(false);
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle();

  const close = () => {
    if (isFullScreen) toggleFullScreen();
    setShow(false);
  };

  return (
    <>
      <button id="visualization-btn" className="btn mr-1 d-flex align-items-center" onClick={() => setShow(true)}>
        <img src={"/assets/images/plnar-logo-square.bf8639fb.png"} width={15} className="m-1 mr-2" />
        ShareLink
      </button>
      <Modal show={show} onHide={close} size="xl" className={isFullScreen ? "fullscreen" : ""}>
        <Modal.Header closeButton>
          <div onClick={toggleFullScreen} className="ml-auto">
            {isFullScreen ? (
              <FontAwesome name="compress fa-gray link" title="Exit Full Screen" size="lg" />
            ) : (
              <FontAwesome name="expand fa-gray link" title="Full Screen" size="lg" />
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <iframe src={url} title="Plnar Visualization" className="h-100"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
