import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { getUrl } from "app2/src/records/Image";
import track from "react-tracking";
import { Carousel } from "react-bootstrap";
import { image, showInfo } from "app2/src/selectors/image.selectors";

const mapStateToProps = (state: RootState, ownProps: ImageProps) => {
  return {
    image: image(state, { imageId: ownProps.imageId }),
    showInfo: showInfo(state),
  };
};

const mapDispatchToProps = () => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImageState {}

interface ImageProps {
  imageId: number;
  key: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImageProps;
@track((props) => {
  return {
    component: "Image",
    image: props.imageId,
  };
})
class Image extends React.Component<Props, ImageState> {
  public filesRef;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { image, showInfo } = this.props;
    return (
      <Carousel.Item {..._.omit(this.props, ["imageId", "image", "showInfo"])}>
        <img className="img-contain" src={image?.file?.url} />
        {showInfo ? (
          <Carousel.Caption className="carousel-caption">
            <h4>{image.title}</h4>
            <p>{image.description}</p>
          </Carousel.Caption>
        ) : null}
      </Carousel.Item>
    );
  }
}

export default connector(Image);
