import { ActionsUnion, createAction } from "./Utils";
import { fromJSON, IImageData, ImageRecord } from "../records/Image";
import { List } from "immutable";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { RootDispatchType } from "app2/src/store";
import {
  create,
  list,
  destroy,
  updateOrCreate,
  IImageOptions,
  ImageableTypeConversion,
  ImageableType,
  update,
} from "app2/src/api/image.service";
import { handleErrors } from "app2/src/reducers/Utils";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { IPageData, QueryParamsRecord } from "app2/src/records/Page";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import { ids, dirtyIds } from "app2/src/selectors/pagination.selectors";
import { currentJob, currentJobId } from "app2/src/selectors/job.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { image, imagesBooleanIds } from "app2/src/selectors/image.selectors";
import { fromJSON as imageFromJSON } from "app2/src/records/Image";

// VIEW
export const SET_CURRENT_IMAGE_ID = "@images/SET_CURRENT_IMAGE_ID";
export const SET_SHOW_INFO = "@images/SET_SHOW_INFO";
// SINGLE
export const FETCH_IMAGE = "@images/FETCH_IMAGE";
export const RECEIVE_IMAGE = "@images/RECEIVE_IMAGE";
export const RESET_IMAGE = "@images/RESET_IMAGE";
export const SET_IMAGE_LOADED = "@images/SET_IMAGE_LOADED";
export const DESTROY_IMAGE = "@images/DESTROY_IMAGE";
export const EDIT_IMAGE = "@images/EDIT_IMAGE";
export const EDIT_BOOLEAN = "@images/EDIT_BOOLEAN";
// MULTIPLE
export const FETCH_IMAGES = "@images/FETCH_IMAGES";
export const RECEIVE_IMAGES = "@images/RECEIVE_IMAGES";
export const SET_IMAGES_LOADED = "@images/SET_IMAGES_LOADED";
export const BATCH_EDIT_BOOLEAN = "@images/BATCH_EDIT_BOOLEAN";
export const BATCH_EDIT_FOLDER_ID = "@images/BATCH_EDIT_FOLDER_ID";
export const BATCH_REMOVE_IDS = "@images/BATCH_REMOVE_IDS";
// ESTIMATE
export const DUPLICATE_LINE_ITEM_IMAGES = "@images/DUPLICATE_LINE_ITEM_IMAGES";

export const Actions = {
  // VIEW
  setCurrentImageId: (id: number) => createAction(SET_CURRENT_IMAGE_ID, { id }),
  setShowInfo: (showInfo: boolean) => createAction(SET_SHOW_INFO, { showInfo }),
  // SINGLE
  fetchImage: (id: number) => createAction(FETCH_IMAGE, { id }),
  receiveImage: (image: Partial<IImageData>) => createAction(RECEIVE_IMAGE, { image }),
  resetImage: (id: number) => createAction(RESET_IMAGE, { id }),
  setImageLoaded: (id: number) => createAction(SET_IMAGE_LOADED, { id }),
  destroyImage: (id: number) => createAction(DESTROY_IMAGE, { id }),
  editSortOrder: (id: number, sortOrder: number) =>
    createAction(EDIT_IMAGE, { id, name: "sort_order", value: sortOrder }),
  editBoolean: (id: number, name: string, value: boolean) => createAction(EDIT_BOOLEAN, { id, name, value }),
  editName: (id: number, name: string) => createAction(EDIT_IMAGE, { id, name: "name", value: name }),
  editTitle: (id: number, name: string) => createAction(EDIT_IMAGE, { id, name: "title", value: name }),
  editDescription: (id: number, name: string) => createAction(EDIT_IMAGE, { id, name: "description", value: name }),
  editImportType: (id: number, importType: string) =>
    createAction(EDIT_IMAGE, { id, name: "import_type", value: importType }),
  //  MULTIPLE
  fetchImages: (ids: List<number>) => createAction(FETCH_IMAGES, { ids }),
  receiveImages: (images: IImageData[]) => createAction(RECEIVE_IMAGES, { images }),
  setImagesLoaded: (ids: List<number>) => createAction(SET_IMAGES_LOADED, { ids }),
  batchEditBoolean: (imageIds: List<number>, name: string, value: boolean) =>
    createAction(BATCH_EDIT_BOOLEAN, { imageIds, name, value }),
  batchEditFolderId: (imageIds: List<number>, folderId: number) =>
    createAction(BATCH_EDIT_FOLDER_ID, { imageIds, folderId }),
  batchRemoveIds: (imageIds: List<number>) => createAction(BATCH_REMOVE_IDS, { imageIds }),
  // ESTIMATE
  duplicateLineItemImages: (lineItemId: number, imageIds: List<number>) =>
    createAction(DUPLICATE_LINE_ITEM_IMAGES, { lineItemId, imageIds }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  listImages: (options: Partial<IImageOptions>, queryParams: QueryParamsRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const mergedOptions = setOptionsId(options, getState());

      if (!options.refreshLoad) {
        dispatch(paginationActions.Actions.fetchPage("image", queryParams));
      }

      try {
        const response = await list(mergedOptions, queryParams);
        if (options.refreshLoad) {
          const currentIds = ids(getState(), { modelName: "image" });
          const images = response.images.filter((image) => !currentIds.includes(image.id));
          dispatch(Actions.receiveImages(images));
        } else {
          dispatch(Actions.receiveImages(response.images));
        }
        if (options.imageableType === "job") {
          const imageRecords = response.images.map((image) => imageFromJSON(image));
          const jobWithImages = currentJob(getState())?.set("images", List(imageRecords));
          dispatch(commonActions.Actions.setJob(jobWithImages));
        }
        const pageData: Partial<IPageData> = {
          ids: List(response.images.map((image: IImageData) => image.id)),
          pagination: response.meta.pagination,
        };
        dispatch(paginationActions.Actions.receivePage("image", queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response, dispatch);
        dispatch(paginationActions.Actions.receivePageError("image", queryParams, errors));
      }
    };
  },
  destroyImage: (image: ImageRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchImage(image.id));

      try {
        await destroy(image);
        dispatch(Actions.destroyImage(image.id));
        if (image.imageable_type === "Job") {
          dispatch(jobActions.Actions.decreaseCount("image", image.imageable_id));
        }
        await dispatch(AsyncActions.checkPageEmpty(_.toUnderscore(image.imageable_type), image.folder_id));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Image successfully deleted"));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setImageLoaded(image.id));
      }
    };
  },
  updateOrCreateImage: (image: ImageRecord): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchImage(image.id));

      try {
        const response = await updateOrCreate(image);
        dispatch(Actions.receiveImage(response.image));
        if (image.id <= 0) {
          dispatch(AsyncActions.processImage(response.image));
        }
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved image successfully"));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setImageLoaded(image.id));
      }
    };
  },
  batchCreateImage: (files: any[], options: IImageOptions): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      options = setOptionsId(options, getState());
      let docSuccess = 0;
      await Promise.all(
        files.map(async (file) => {
          const record = fromJSON({
            name: file.name,
            display: "do_not_display",
            file_queue: file,
            imageable_id: options.imageableId,
            imageable_type: ImageableTypeConversion[options.imageableType],
            folder_id: options.folder_id,
          });
          try {
            const response = await create(record);
            if (_.isArray(response.images)) {
              const docs = response.images;
              dispatch(Actions.receiveImages(docs));
              docs.forEach((doc) => {
                dispatch(AsyncActions.processImage(doc));
              });

              docSuccess += 1;
              return response;
            } else {
              dispatch(Actions.receiveImage(response.image));
              dispatch(AsyncActions.processImage(response.image));
              docSuccess += 1;
              return response;
            }
          } catch (response) {
            return response;
          }
        }),
      );

      let flashLevel = FlashLevels.warning;
      if (docSuccess === files.length) {
        flashLevel = FlashLevels.success;
      }

      dispatch(
        commonActions.Actions.flashAddAlert(
          flashLevel,
          docSuccess + "/" + files.length + " Images successfully uploaded",
        ),
      );
    };
  },
  batchUpdateImage: (imageIds: List<number>, reject = false): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const promises = [];
      dispatch(Actions.fetchImages(imageIds));

      imageIds.forEach((imageId) => {
        const updatedImage = image(getState(), { imageId });
        promises.push(update(updatedImage));
      });

      try {
        const responses = await Promise.all(promises);
        const images = responses.map((response) => response.image);
        dispatch(Actions.receiveImages(images));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved images successfully"));
      } catch (response) {
        dispatch(Actions.setImagesLoaded(imageIds));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, ["Update failed, please try again."]));
        if (reject) {
          return Promise.reject();
        }
      }
    };
  },
  onSortEnd: (oldIndex: number, newIndex: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      await dispatch(paginationActions.AsyncActions.onSortEnd("image", oldIndex, newIndex));

      dispatch(AsyncActions.batchUpdateImage(dirtyIds(getState())));
      dispatch(paginationActions.Actions.setDirtyIds(null));
    };
  },
  processImage: (image: IImageData): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      if (image.imageable_type === "Job") {
        dispatch(jobActions.Actions.increaseCount("image", image.imageable_id));
      }
      dispatch(paginationActions.Actions.pushId(image.id, "image"));
    };
  },
  moveImages: (folderId: number): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const imageIds = imagesBooleanIds(getState(), { booleanName: "selected" });
      const imageRecord = image(getState(), { imageId: imageIds.first() });

      dispatch(Actions.batchEditFolderId(imageIds, folderId));
      try {
        await dispatch(AsyncActions.batchUpdateImage(imageIds, true));

        dispatch(Actions.batchRemoveIds(imageIds));
        await dispatch(AsyncActions.checkPageEmpty(_.toUnderscore(imageRecord.imageable_type), imageRecord.folder_id));
      } catch {
        dispatch(Actions.batchEditFolderId(imageIds, imageRecord.folder_id));
      }
    };
  },
  checkPageEmpty: (kind: ImageableType, folder_id: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      return paginationActions.checkPageEmpty(getState(), "image", (newQueryParams) =>
        dispatch(
          AsyncActions.listImages(
            {
              imageableType: kind,
              folder_id,
            },
            newQueryParams,
          ),
        ),
      );
    };
  },
  saveForm: (formData: any): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.editTitle(formData.id, formData.title));
      dispatch(Actions.editDescription(formData.id, formData.description));

      await dispatch(AsyncActions.updateOrCreateImage(image(getState(), { imageId: formData.id })));
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;

const setOptionsId = (options: Partial<IImageOptions>, state: RootState): IImageOptions => {
  if (_.isNullOrUndefined(options.imageableId)) {
    switch (options.imageableType) {
      case "job":
        options.imageableId = currentJobId(state);
        break;
      case "org":
        options.imageableId = currentOrgId(state);
        break;
    }
  }

  return options as IImageOptions;
};
