import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState, RootActions } from "./index";
import { ITokenData, TokenRecord, TokenKind } from "../records/Token";
import { tokenService } from "../api/token.service";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { handleErrors } from "app2/src/reducers/Utils";
import { RootDispatchType } from "../store";

export const RECEIVE_TOKEN = "@tokens/RECEIVE_TOKEN";
export const FETCH_TOKEN = "@tokens/FETCH_TOKEN";
export const EDIT_TOKEN_DATA = "@tokens/EDIT_TOKEN_DATA";
export const RESET_TOKEN = "@tokens/RESET_TOKEN";
export const SET_LOADED = "@tokens/SET_LOADED";
export const RECEIVE_AUTHORIZED = "@tokens/RECEIVE_AUTHORIZED";
export const FETCH_AUTHORIZED = "@tokens/FETCH_AUTHORIZED";
export const SET_AUTHORIZED_LOADED = "@tokens/SET_AUTHORIZED_LOADED";
export const UPDATE_FORM = "@tokens/UPDATE_FORM";

export const Actions = {
  receiveToken: (orgId: number, token: Partial<ITokenData>) => createAction(RECEIVE_TOKEN, { orgId, token }),
  fetchToken: (orgId: number, tokenKind: TokenKind) => createAction(FETCH_TOKEN, { orgId, tokenKind }),
  editTokenData: (orgId: number, tokenKind: TokenKind, data: Map<string, any>) =>
    createAction(EDIT_TOKEN_DATA, { orgId, tokenKind, data }),
  resetToken: (orgId: number, tokenKind: TokenKind) => createAction(RESET_TOKEN, { orgId, tokenKind }),
  setLoaded: (orgId: number, tokenKind: TokenKind) => createAction(SET_LOADED, { orgId, tokenKind }),
  update: (event: any) => createAction(UPDATE_FORM, { event }),
  receiveAuthorized: (orgId: number, authorized: any) => createAction(RECEIVE_AUTHORIZED, { orgId, authorized }),
  fetchAuthorized: (orgId: number) => createAction(FETCH_AUTHORIZED, orgId),
  setAuthorizedLoaded: (orgId: number) => createAction(SET_AUTHORIZED_LOADED, orgId),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, RootActions>;

export const AsyncActions = {
  getToken: (orgId: number, tokenKind: TokenKind): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, _getState) => {
      dispatch(Actions.fetchToken(orgId, tokenKind));
      try {
        const result = await tokenService.loadByKind(orgId, tokenKind);
        if (result.token) {
          dispatch(Actions.receiveToken(orgId, result.token));
        } else {
          // Setup default for redux store if token does not currently exist on the org
          dispatch(Actions.receiveToken(orgId, { org_id: orgId, kind: tokenKind }));
        }
      } catch (response) {
        const err_msg = handleErrors(response);
        dispatch(Actions.setLoaded(orgId, tokenKind));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, err_msg));
      }
    };
  },
  getAuthorizedTokens: (orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchAuthorized(orgId));
      try {
        const result = await tokenService.loadAuthorized(orgId);
        dispatch(Actions.receiveAuthorized(orgId, result));
      } catch (response) {
        const err_msg = handleErrors(response);
        dispatch(Actions.setAuthorizedLoaded(orgId));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, err_msg));
      }
    };
  },
  updateCreateToken: (token: TokenRecord, throwError = false): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, _getState) => {
      dispatch(Actions.fetchToken(token.org_id, token.kind));
      try {
        if (token.id > 0) {
          const result = await tokenService.update(token);
          dispatch(Actions.receiveToken(token.org_id, result.token));
          dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Successfully updated"));
        } else {
          const result = await tokenService.create(token);
          dispatch(Actions.receiveToken(token.org_id, result.token));
          dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Successfully created"));
        }
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setLoaded(token.org_id, token.kind));
        if (throwError) throw response;
      }
    };
  },
  deleteToken: (orgId: number, tokenKind: TokenKind): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, _getState) => {
      dispatch(Actions.fetchToken(orgId, tokenKind));
      try {
        await tokenService.deleteByKind(orgId, tokenKind);
        dispatch(Actions.receiveToken(orgId, { kind: tokenKind }));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Authorization Rejected"));
      } catch (response) {
        const err_msg = handleErrors(response);
        dispatch(Actions.setLoaded(orgId, tokenKind));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, err_msg[0]));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
