export interface IEmailEvent {
  id: number;
  recipient: string;
  record_type: string;
  sub_type: number;
  happened_at: Date;
  description: string;
  email_id: number;
  updated_at: Date;
  created_at: Date;
}

export class EmailEvent implements IEmailEvent {
  public id: number;
  public recipient: string;
  public record_type: string;
  public sub_type: number;
  public happened_at: Date;
  public description: string;
  public email_id: number;
  public updated_at: Date;
  public created_at: Date;

  public static fromJSON = (data: any): IEmailEvent => {
    const emailEvent = new EmailEvent();
    for (const key in data) {
      if (data.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        switch (key) {
          case "updated_at":
            data[key] = new Date(data[key]);
            break;
          case "created_at":
            data[key] = new Date(data[key]);
            break;
        }
      }

      emailEvent[key] = data[key];
    }

    return emailEvent;
  };
}
