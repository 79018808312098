import * as leadsActions from "./leads.actions";
import { Record, Map, List } from "immutable";

export const LeadRecord = Record({
  id: 0,
  first_name: "",
  last_name: "",
  email_address: "",
  lead_source: "",
  form_data: {},
  job_id: 0,
  created_at: new Date(),
  updated_at: new Date(),
});

export const Page = Record({
  leads: List([]),
});

export const LeadPageRecord = Record({
  loading: false,
  leads: [] as number[],
  pagination: {},
});

export const LeadStateRecord = Record({
  leadsById: Map<number, typeof LeadRecord>(),
  pages: Map<number, typeof LeadPageRecord>(),
  params: {
    page: 1,
    filter: "unclaimed",
    query: "",
  },
  total_count: 0,
  total_pages: 0,
});

export const LeadsInitialState = LeadStateRecord();
export type LeadState = typeof LeadsInitialState;

export const reducer = (state = LeadsInitialState, action: leadsActions.Actions): LeadState => {
  switch (action.type) {
    case leadsActions.FETCH_PAGE:
      if (!state.getIn(["pages", action.payload.page])) {
        return state
          .set("params", action.payload)
          .setIn(["pages", action.payload.page], LeadPageRecord({ loading: true }));
      }
      return state
        .set("params", action.payload)
        .setIn(["pages", action.payload.page, "loading"], true)
        .setIn(["pages", action.payload.page, "leads"], []);
    case leadsActions.RESET_PAGES:
      return state.set("pages", Map());
    case leadsActions.RECEIVE_PAGE:
      _.each(action.payload.leads, (lead) => {
        state = state.setIn(["leadsById", lead.id], LeadRecord(lead));
      });
      return state
        .setIn(["pages", action.payload.page, "leads"], _.pluck(action.payload.leads, "id"))
        .setIn(["pages", action.payload.page, "loading"], false)
        .setIn(["pages", action.payload.page, "pagination"], action.payload.meta.pagination)
        .set("total_count", action.payload.meta.pagination.total_count)
        .set("total_pages", action.payload.meta.pagination.total_pages);
    case leadsActions.RECEIVE_LEAD:
      return state
        .setIn(["leadsById", action.payload.id], action.payload)
        .setIn(["leadsById", action.payload.id, "loading"], false);
    case leadsActions.POST_JOB:
      return state.setIn(["leadsById", action.payload, "loading"], true);
    default:
      return state;
  }
};
