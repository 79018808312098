import { ISignerSettings } from "app/src/Models/SignatureSettings";

export class OrgSignatureSettingsEditCtrl {
  public sender_types: string[] = ["job", "salesrep"];

  public static $inject = ["$uibModalInstance", "settings", "signer"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public settings: any,
    public signer: ISignerSettings,
  ) {}

  public save() {
    if (this.signer["index"] >= 0) {
      const index = this.signer["index"];
      delete this.signer["index"];
      this.settings.config.signature_settings.signers[index] = this.signer;
    } else {
      this.settings.config.signature_settings.signers.push(this.signer);
    }

    this.$uibModalInstance.close("manual");
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }

  public validateUniqueRoles(form: ng.IFormController) {
    if (this.signer.role.length === 0) {
      form["role"].$setValidity("duplicate", true);
      return;
    }
    const matchingSigners = _.filter(
      this.settings.config.signature_settings.signers,
      (s: ISignerSettings) => this.signer.role === s.role,
    );
    form["role"].$setValidity("duplicate", !(matchingSigners.length > 0));
  }
}
