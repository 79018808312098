import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { RootState } from "app2/src/reducers";
import { getPaginationByModel, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncActions } from "app2/src/reducers/financeApplication.actions";
import { List } from "immutable";
import { FinanceApplicationRecord } from "app2/src/records/FinanceApplication";
import DateTime from "app2/src/components/Common/DateTime";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { Table } from "react-bootstrap";

export interface FinanceApplicationListProps {}

export const FinanceApplicationList = (props: FinanceApplicationListProps) => {
  const dispatch = useDispatch();
  const modelName = "financeApplications";

  const { financeApplications, queryParamsRecord } = useSelector((state: RootState) => {
    return {
      financeApplications: getPaginationByModel(state, {
        path: [modelName, "byEstimateId"],
        modelName,
      }) as List<FinanceApplicationRecord>,
      queryParamsRecord: queryParams(state, { modelName }),
    };
  });
  const paginationRecord = useSelector((state: RootState) => {
    return pagination(state, { modelName, page: queryParamsRecord.get("page") });
  });

  const queryFinanceApplications = (page: number) => {
    const newQueryParams = queryParamsRecord.set("page", page).set("per_page", 5);
    dispatch(AsyncActions.loadFinanceApplications(newQueryParams));
  };

  React.useEffect(() => {
    queryFinanceApplications(1);
  }, []);

  if (!financeApplications.count()) return <p>No applications</p>;

  return (
    <>
      <Table className="table table-striped">
        <thead>
          <tr>
            <td>Created At</td>
            <td>Loan Status</td>
            <td>Details Link</td>
          </tr>
        </thead>
        <tbody>
          {financeApplications.map((application, index) => (
            <tr key={index}>
              <td>
                <DateTime date={application.created_at} variant="vertical" />
              </td>
              <td>{application.loan_status}</td>
              <td>
                {application.details_url && (
                  <a href={application.details_url} target="_blank">
                    Details
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <StandardPagination metadata={paginationRecord} pageChanged={queryFinanceApplications} />
    </>
  );
};

export default FinanceApplicationList;
