import * as React from "react";

// eslint-disable-next-line
export default function BandwidthWarning() {
  return (
    <div className="bandwidth-warning">
      <h3>Insufficient Bandwidth</h3>
    </div>
  );
}
