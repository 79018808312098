import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { DocType } from "app2/src/records/Estimate";
import { Row, Col } from "react-bootstrap";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import Spinner from "app2/src/components/SpinnerComponent";
import { estimate as estimateSelector } from "app2/src/selectors/estimate.selectors";
import { PdfDisplay } from "../Pdf/PdfDisplay";
import { document } from "app2/src/selectors/document.selectors";

const mapStateToProps = (state: RootState, ownProps: IPdfPageProps) => {
  const estimate = estimateSelector(state, { estimateId: ownProps.estimateId });
  return {
    estimate,
    document: document(state, { documentId: estimate?.get("tempDocumentId") }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IPdfPageProps) => {
  return {
    loadPdf: (estimateId: number, docType: DocType) =>
      dispatch(estimateActions.AsyncActions.getEstimatePdf(estimateId, docType)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IPdfPageProps {
  estimateId: number;
  docType: DocType;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IPdfPageProps;

export interface IPdfPageState {}

class PdfPageContainer extends React.Component<Props, IPdfPageState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  public componentDidMount() {
    const { loadPdf, estimateId, docType } = this.props;
    loadPdf(estimateId, docType);
  }

  public render() {
    const { estimate, document } = this.props;

    if (!estimate || estimate.loading || !document) {
      return <Spinner localProperty={true} />;
    }

    return (
      <Row style={{ marginTop: -80 }}>
        <Col md={12}>
          <PdfDisplay pdfUrl={document.get("base64")} />
        </Col>
      </Row>
    );
  }
}

export default connector(PdfPageContainer);
