import { Record, List, Map } from "immutable";
import { PaymentTermItemRecord, IPaymentTermItemData, paymentTermItemFromJSON } from "./PaymentTermTemplate";

export const fromJSON = (json: Partial<IPaymentTermData>): PaymentTermRecord => {
  const record: IPaymentTermRecord = { ...(json as any) };

  if (json.payment_term_items) {
    record.payment_term_items = List(json.payment_term_items.map((pti) => paymentTermItemFromJSON(pti)));
  }

  return new PaymentTermRecord(record);
};

export const toJSON = (pt: PaymentTermRecord): IPaymentTermData => {
  return pt.toJS();
};

/**
 * Memoized version of calculatePaymentTerms that is based on the hashCode of the PaymentTermRecord
 * and the total value passed in.
 */
export const memoizedCalculatePaymentTerms = _.memoize(
  (pt: PaymentTermRecord, value: number) => {
    return calculatePaymentTerms(pt, value);
  },
  (pt: PaymentTermRecord, value: number) => {
    return pt.hashCode() + value.toString();
  },
);

/**
 * Updates the calculated value on all the PaymentTermItemRecords.
 * 'amount' records are handled first, then 'percent'.
 * Finally, any 'discrepency' in total of items vs total passed in is reconciled in the last item.
 *
 * @param pt  PaymentTermRecord to update calculated values on
 * @param value  Total of Estimate to use in the calculations
 *
 * @returns PaymentTermRecord  The updated, immutable, record.
 */
export const calculatePaymentTerms = (pt: PaymentTermRecord, value: number): PaymentTermRecord => {
  const initialValue: number = value;
  let runningValue: number = value;

  const grouped = pt.payment_term_items.groupBy((pti) => pti.type);

  if (grouped.has("amount")) {
    grouped.get("amount").forEach((pti: PaymentTermItemRecord) => {
      pt = pt.update("payment_term_items", (value) => {
        const idx = value.findIndex((record) => record.uuid === pti.uuid);
        const newPti = calculatePaymentTermItem(pti, initialValue);
        runningValue = runningValue - newPti.calculated;
        return value.set(idx, newPti);
      });
    });
  }
  const amountValue: number = runningValue;
  if (grouped.has("percent")) {
    grouped.get("percent").forEach((pti: PaymentTermItemRecord) => {
      pt = pt.update("payment_term_items", (value) => {
        const idx = value.findIndex((record) => record.uuid === pti.uuid);
        const newPti = calculatePaymentTermItem(pti, amountValue);
        runningValue = runningValue - newPti.calculated;
        return value.set(idx, newPti);
      });
    });
  }

  const sum: number = pt.payment_term_items.reduce((memo: number, item: PaymentTermItemRecord) => {
    return memo + item.calculated;
  }, 0);

  const diff = value - sum;

  if (pt.payment_term_items.count() > 0) {
    const pti = pt.payment_term_items.last(new PaymentTermItemRecord());
    pt = pt.update("payment_term_items", (value) => {
      return value.set(value.count() - 1, pti.set("calculated", pti.calculated + diff));
    });
  }

  pt = pt.set("financed_amount", calculateFinancingAmount(pt, value));

  return pt;
};

export const calculateFinancingAmount = (pt: PaymentTermRecord, value: number) => {
  let financedTotal = 0;
  if (pt !== null) {
    pt.payment_term_items.forEach((term) => {
      if (term.paymentMethod === "financed") {
        financedTotal += term.calculated;
      }
    });
  }
  if (financedTotal === 0) {
    financedTotal = value;
  }
  return financedTotal;
};

export const calculatePaymentTermItem = (pti: PaymentTermItemRecord, value: number): PaymentTermItemRecord => {
  if (pti.type === "percent") {
    return pti.set("calculated", _.round(value * (pti.amount / 100), -2));
  } else {
    return pti.set("calculated", pti.amount);
  }
};

export interface IPaymentTermData {
  id: number;
  name: string;
  description: string;
  financed_amount: number;
  payment_term_items: IPaymentTermItemData[];
}

export interface IPaymentTermRecord {
  id: number;
  name: string;
  description: string;
  financed_amount: number;
  payment_term_items: List<PaymentTermItemRecord>;
}

const defaultPaymentTermProps: IPaymentTermRecord = {
  id: 0,
  name: "",
  description: "",
  financed_amount: 0,
  payment_term_items: List<PaymentTermItemRecord>(),
};

export class PaymentTermRecord extends Record(defaultPaymentTermProps) implements IPaymentTermRecord {
  public readonly id!: number;
  public readonly name!: string;
  public readonly description!: string;
  public readonly financed_amount!: number;
  public readonly payment_term_items!: List<PaymentTermItemRecord>;

  public constructor(values?: Partial<IPaymentTermRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
