import { Record } from "immutable";
import * as moment from "moment";
import { CamelToSnakeKeys, SnakeToCamelKeys } from "app2/src/helpers/Format";
import { checkDate } from "app2/src/records";

export const fromJSON = (data: Partial<IFenceSegmentData>): FenceSegmentRecord => {
  const record: Partial<IFenceSegmentRecord> = SnakeToCamelKeys(data);

  if (data.created_at) {
    record.createdAt = checkDate(data.created_at);
  }

  if (data.updated_at) {
    record.updatedAt = checkDate(data.updated_at);
  }

  return new FenceSegmentRecord(record);
};

export const toFormData = (record: FenceSegmentRecord): IFenceSegmentData => {
  const data = CamelToSnakeKeys(record.toJSON());

  data.updated_at = moment(record.updatedAt).format("YYYY-MM-DDTHH:mm:ss.SSS");
  data.created_at = moment(record.createdAt).format("YYYY-MM-DDTHH:mm:ss.SSS");

  return data;
};

export interface IFenceSegmentData {
  id: number;
  section_id: string;
  name: string;
  length: number;
  gate_count: number;
  gate_length: number;
  net_length: number;
  line_posts_4: number;
  line_posts_6: number;
  line_posts_8: number;
  fence_id: number;
  _destroy?: boolean;
  created_at: string;
  updated_at: string;
}

export interface IFenceSegmentRecord {
  id: number;
  sectionId: string;
  fenceId: number;
  name: string;
  length: number;
  gateCount: number;
  gateLength: number;
  netLength: number;
  linePosts4: number;
  linePosts6: number;
  linePosts8: number;
  _destroy: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const defaultFenceSegmentProps = {
  id: 0,
  sectionId: "",
  fenceId: 0,
  name: "",
  length: 0,
  gateCount: 0,
  gateLength: 0,
  netLength: 0,
  linePosts4: 0,
  linePosts6: 0,
  linePosts8: 0,
  _destroy: false,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export class FenceSegmentRecord
  extends Record<IFenceSegmentRecord>(defaultFenceSegmentProps)
  implements IFenceSegmentRecord {}

export const emptyFenceSegmentRecord = new FenceSegmentRecord();
