import { CalendarCtrl } from "app/src/Calendar/CalendarCtrl";
import { AppointmentCreator } from "app/src/Calendar/AppointmentCreator";
import { EventService } from "app/src/Calendar/EventService";
import { InviteModal } from "app/src/Calendar/InviteModal";
import { ResultModal } from "app/src/Calendar/ResultModal";
import { CalendarProviderUnit } from "app/src/Calendar/CalendarProviderUnit";
import { react2angular } from "react2angular";
import SchedulerTab from "app2/src/components/SchedulerTab";

export class CalendarRoutes {
  public static load(app: ng.IModule) {
    app.controller("CalendarCtrl", CalendarCtrl);
    app.service("EventService", EventService);
    app.component("appointmentCreator", new AppointmentCreator());
    app.component("resultModal", new ResultModal());
    app.controller("InviteModal", InviteModal);
    app.filter("calendarProvider", CalendarProviderUnit.factory);
    app.component("schedulerTab", react2angular(SchedulerTab));
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("root.calendar", <ng.ui.IState>{
        url: "/calendar",
        views: {
          "container@": {
            templateUrl: "src/Calendar/calendar.html",
            controller: "CalendarCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.scheduler", <ng.ui.IState>{
        url: "/scheduler",
        views: {
          "container@": {
            component: "schedulerTab",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.scheduler.modal_route", <ng.ui.IState>{
        url: "/{path:.*}",
        views: {},
      });
  }
}
