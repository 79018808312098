import * as React from "react";
import { Button, Table, Row, Col } from "react-bootstrap";

export interface IJobListContainerData {
  id: number;
  name: string;
}

export interface JobListContainerProps {
  jobs: IJobListContainerData[];
  action: string;
  actionFired: (job: IJobListContainerData) => void;
}

export interface JobListContainerState {}

export class JobListContainer extends React.Component<JobListContainerProps, JobListContainerState> {
  constructor(props: JobListContainerProps) {
    super(props);
  }

  public render() {
    const { jobs, action, actionFired } = this.props;

    if (!_.isArray(jobs)) {
      return <h3>Loading...</h3>;
    }

    if (jobs.length === 0) {
      return this.renderEmptyList();
    }

    return (
      <Row>
        <Col md={12}>
          <Table striped>
            <thead>
              <tr>
                <td>ID</td>
                <td>Job</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job) => {
                return (
                  <tr key={job.id}>
                    <td>{job.id}</td>
                    <td>{job.name}</td>
                    <td>
                      <Button size="sm" onClick={() => actionFired(job)}>
                        {action}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  public renderEmptyList() {
    return (
      <Table>
        <tbody>
          <tr className="text-center">
            <td>
              <h2>No Jobs Found</h2>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}
