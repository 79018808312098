import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { DraggableRowProps } from "app2/src/components/Common/ListManager/components/DragTable";
import { ToolRecord, IToolData } from "app2/src/records/Tool";
import * as toolActions from "app2/src/reducers/org/tool.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { IPretty } from "app/src/Common/PrettyNameService";
import ToolEditorModal from "./ToolEditorModal";
import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { Can } from "app2/src/components/Common/CanComponent";
import { StoreRegistry } from "app2/src/storeRegistry";

const mapStateToProps = (state: RootState, ownProps: ToolRowProps) => {
  let tool = null;
  if (typeof ownProps.option === "number") {
    tool = state.getIn(["tools", "byId", ownProps.option]);
  }
  return {
    tool: tool,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>) => {
  return {
    removeTool: (tool: ToolRecord) => dispatch(toolActions.AsyncActions.archiveTool(tool)),
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ToolRowState {
  editing: boolean;
}

interface ToolRowProps extends DraggableRowProps {
  option: string | number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ToolRowProps;

class ToolRow extends React.Component<Props, ToolRowState> {
  public Pretty: IPretty;

  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.Pretty = StoreRegistry.get("Pretty");

    this.setEditing = this.setEditing.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  public async removeTool(tool: ToolRecord) {
    const { addFlashMessage } = this.props;
    return this.props
      .removeTool(tool)
      .then(() => {
        addFlashMessage(FlashLevels.success, "Tool Successfully Removed.");
      })
      .catch(() => {
        addFlashMessage(FlashLevels.danger, "There was an error removing the tool. Please try again.");
      });
  }

  public setEditing() {
    this.setState({
      editing: true,
    });
  }

  public onCloseModal() {
    this.setState({
      editing: false,
    });
  }

  public render() {
    const { option, id, addOption, removeOption, index, tool } = this.props;
    const { editing } = this.state;

    const name = tool ? tool.name : this.Pretty.name[option];

    return (
      <Draggable key={option} draggableId={option.toString()} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          let draggingClass = "";
          if (snapshot.isDragging) {
            draggingClass = "table-row-dragging";
          }
          return (
            <tr
              className={draggingClass}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}>
              {tool ? <ToolEditorModal show={editing} tool={tool} onClose={this.onCloseModal}></ToolEditorModal> : null}
              <td>
                <img className="dragHandle link" src="/assets/images/icons/ic_sort.ea6f8933.png" title={"Move " + name} />{" "}
                {"\u00A0"}
                {name}
              </td>
              <td>
                {id === "available" ? (
                  <i
                    className="rsf-plus-link rsf-base-66"
                    title={"Add " + name}
                    onClick={() => {
                      addOption(option);
                    }}></i>
                ) : (
                  <i
                    className="rsf-delete-link rsf-base-66"
                    title={"Remove " + name}
                    onClick={() => {
                      removeOption(option);
                    }}></i>
                )}
                {tool ? (
                  <React.Fragment>
                    <Can resource="tool" permission="update">
                      {"\u00A0"}
                      <i className="rsf-edit-link rsf-base-66" title={"Edit " + name} onClick={this.setEditing}></i>
                    </Can>
                    {id === "available" ? (
                      <Can resource="tool" permission="destroy">
                        {"\u00A0"}
                        <ConfirmDialog description="" title={`Are you sure you want to delete tool: ${tool.name}?`}>
                          {(confirm) => (
                            <i
                              className="rsf-delete-link rsf-base-66"
                              title={"Delete" + name}
                              onClick={confirm(() => {
                                this.removeTool(tool);
                              })}></i>
                          )}
                        </ConfirmDialog>
                      </Can>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </td>
            </tr>
          );
        }}
      </Draggable>
    );
  }
}

export default connector(ToolRow);
