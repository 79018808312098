import { ICommand } from "app/src/Commands/Command";
import { IEstimateLineItemOption, EstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { IEstimate } from "app/src/Models/Estimate";

export class AddEstimateLineItemOptionCmd implements ICommand {
  public lineItemOption: IEstimateLineItemOption;
  constructor(
    public productOption: ProductOptionRecord,
    public productOptionGroup: ProductOptionGroupRecord,
    public lineItem: IEstimateLineItem,
    public estimate: IEstimate,
  ) {}

  public execute(quantity = 0): boolean {
    const product_option_image_ids = [];

    this.productOption.images.forEach((img) => {
      product_option_image_ids.push(img.id);
    });
    this.lineItemOption = new EstimateLineItemOption({
      id: this.lineItem.getOptionId(),
      quantity: quantity,
      product_option_id: this.productOption.id,
      estimate_line_item_id: this.lineItem.id,
      estimate_line_item: this.lineItem,
      product_option_group_id: this.productOptionGroup.id,
      product_option_group_name: this.productOptionGroup.name,
      cost: this.productOption.cost,
      base_labor_price: this.productOption.labor_price,
      base_product_price: this.productOption.product_price,
      uom: this.productOption.uom,
      charge_type: this.productOption.charge_type,
      pog_type: this.productOption.pog_type,
      price: this.productOption.price,
      product_option_uuid: this.productOption.uuid,
      product_option_match_id: this.productOption.match_id,
      name: this.productOption.name,
      description: this.productOption.description,
      msrp: this.productOption.msrp,
      product_option_image_ids: product_option_image_ids,
    });
    if (this.lineItemOption.uom === "percent") {
      this.lineItemOption.labor_factor = this.productOption.labor_price;
      this.lineItemOption.product_factor = this.productOption.product_price;
    }
    this.lineItemOption.calculate(this.estimate);
    this.lineItem.options.push(this.lineItemOption);
    return true;
  }
}
