import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { SlideRecord } from "app2/src/records/Slide";

const mapStateToProps = (state: RootState, ownProps: ISlideComponentProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ISlideComponentProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ISlideComponentProps {
  slide: any;
  selected?: boolean;
  toggle?: (state: boolean) => void;
  showToggle: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ISlideComponentProps;

class SlideComponent extends React.PureComponent<Props> {
  public render() {
    const { slide, showToggle, toggle, selected } = this.props;
    if (!slide) {
      return null;
    }
    return (
      <div className="slide">
        {this.renderSlide(slide)}
        {showToggle && (
          <div className="toggle-container">
            <input
              type="checkbox"
              checked={selected}
              onChange={(event) => toggle(event.target.checked)}
              className="toggle form-control"
              id="switch-normal"
            />
          </div>
        )}
      </div>
    );
  }

  protected renderSlide = (slide: SlideRecord) => {
    if (slide && slide.cover_image && slide.cover_image.small.url) {
      return <img src={slide.cover_image.small.url} />;
    }

    return <span className="placeholder">{slide.name}</span>;
  };
}

export default connector(SlideComponent);
