import { Collection, List, mergeWith, isImmutable } from "immutable";

export const mergeDeepOverwriteArrays = <C extends Collection<any, any>>(a: C, b: C): C => {
  if (a && isImmutable(a) && !List.isList(a) && !List.isList(b)) {
    return mergeWith(mergeDeepOverwriteArrays, a, b);
  }

  return b;
};

export const removeDuplicates = (list: List<any>): List<any> => {
  return list.filter((item, index, list) => list.indexOf(item) === index);
};

/**
 * Merges two lists, returning only the items found in both lists.
 */
export const intersectLists = (a: List<any>, b: List<any>): List<any> => {
  return a.filter((i) => b.includes(i));
};
