import { RootState, RootActions } from "app2/src/reducers";
import { createSelector } from "reselect";
import { PaymentTermTemplateRecord } from "app2/src/records/PaymentTermTemplate";
import { Map, List } from "immutable";

const paymentTermTemplatesById = (state: RootState) =>
  state.getIn(["paymentTermTemplates", "paymentTermTemplatesById"]);
const orgPaymentTermTemplateIds = (state: RootState, props: any) =>
  state.getIn(["paymentTermTemplates", "paymentTermTemplatesByOrgId", parseInt(props.orgId), "paymentTermTemplates"]);

export const getOrgPaymentTermTemplates = createSelector(
  [paymentTermTemplatesById, orgPaymentTermTemplateIds],
  (byId: Map<number, PaymentTermTemplateRecord>, paymentTermTemplates: List<number>) => {
    if (!paymentTermTemplates) {
      return List();
    }

    const interim = paymentTermTemplates.map((id) => byId.get(id)).sortBy((d) => d.sort_order);
    return interim;
  },
);
