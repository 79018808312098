import { ActionsUnion, createAction } from "./Utils";
import { IEstimateLineItemData } from "../records/EstimateLineItem";
import { List } from "immutable";

export const RECEIVE_ESTIMATE_LINE_ITEM = "@estimateLineItems/RECEIVE_ESTIMATE_LINE_ITEM";
export const DUPLICATE_ESTIMATE_LINE_ITEMS = "@estimateLineItems/DUPLICATE_ESTIMATE_LINE_ITEMS";
export const PUSH_DOCUMENT_ID = "@estimateLineItems/PUSH_DOCUMENT_ID";

export const Actions = {
  receiveEstimateLineItem: (estimateLineItem: Partial<IEstimateLineItemData>) =>
    createAction(RECEIVE_ESTIMATE_LINE_ITEM, { estimateLineItem }),
  duplicateEstimateLineItems: (groupId: number, lineItemIds: List<number>) =>
    createAction(DUPLICATE_ESTIMATE_LINE_ITEMS, { groupId, lineItemIds }),
  pushDocumentId: (estimateLineItemId: number, documentId: number) =>
    createAction(PUSH_DOCUMENT_ID, { estimateLineItemId, documentId }),
};

export type Actions = ActionsUnion<typeof Actions>;
