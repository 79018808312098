import { Record, List, getIn } from "immutable";
import { fromJSON as imageFromJSON, ImageRecord } from "./Image";
import { Nullable } from ".";
import {
  fromJSON as addressFromJSON,
  toJSON as addressToJSON,
  AddressRecord,
  IAddressData,
} from "app2/src/records/Address";
import {
  fromJSON as preferencesFromJSON,
  toJSON as preferencesToJSON,
  PreferenceRecord,
  IPreferenceData,
} from "app2/src/records/Preference";
import {
  fromJSON as settingsFromJSON,
  toJSON as settingsToJSON,
  SettingsRecord,
  ISettingsData,
} from "app2/src/records/Settings";
import { CommonTitles } from "app2/src/helpers/Format";
import { baseCleanProps } from "app2/src/api/Api";

export const fromJSON = (json: Partial<IOrgData>): OrgRecord => {
  const record: IOrgRecord = { ...(json as any) };

  if (json.preferences) {
    record.preferences = preferencesFromJSON(json.preferences);
  }

  if (json.settings) {
    record.settings = settingsFromJSON(json.settings);
  }

  if (json.lead_sources) {
    record.lead_sources = List<string>(json.lead_sources);
  } else {
    record.lead_sources = List<string>();
  }

  if (json.events) {
    record.events = List(json.events);
  }

  if (json.address) {
    record.address = addressFromJSON(json.address);
  }

  if (json.activated_price_list) {
    record.activated_price_list = json.activated_price_list;
  }

  if (json.activated_inspection_list) {
    record.activated_inspection_list = json.activated_inspection_list;
  }

  if (json.emails) {
    record.emails = json.emails;
  }

  if (json.contract_languages) {
    record.contract_languages = json.contract_languages;
  }

  if (json.errors) {
    record.errors = List(json.errors);
  }

  // For some reason json.images was not truthy when it had an array
  // no 'checks' worked, only fullproof was try/catch
  try {
    //@ts-ignore
    record.images = List<ImageRecord>(
      json.images.map((i) => {
        return imageFromJSON(i);
      }),
    );
  } catch (e) {
    record.images = List<ImageRecord>();
  }

  return new OrgRecord(record);
};

export const toJSON = (org: OrgRecord): IOrgPostData => {
  const json: IOrgPostData = baseCleanProps(org.toJSON() as any as IOrgPostData);

  json.address_attributes = addressToJSON(org.address);
  //@ts-ignore
  delete json.address;

  json.preferences_attributes = preferencesToJSON(org.preferences);
  //@ts-ignore
  delete json.preferences;

  json.settings_attributes = settingsToJSON(org.settings);
  //@ts-ignore
  delete json.settings;

  json.contract_languages_attributes = org.contract_languages;

  //@ts-ignore
  delete json.contract_languages;

  //@ts-ignore
  delete json.images;
  //@ts-ignore
  delete json.activated_price_list;
  //@ts-ignore
  delete json.emails;

  return json;
};

export const getEstimateName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("estimate");
  }
  return org.getIn(["preferences", "config", "titles", "estimate"]) || CommonTitles("estimate");
};

export const getEstimatorName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("estimator");
  }
  return org.getIn(["preferences", "config", "titles", "estimator"]) || CommonTitles("estimator");
};
export const getContractName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("contract");
  }
  return org.getIn(["preferences", "config", "titles", "contract"]) || CommonTitles("contract");
};

export const getInspectionName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("inspection");
  }
  return org.getIn(["preferences", "config", "titles", "inspection"]) || CommonTitles("inspection");
};

export const getFinanceName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("finance");
  }
  return org.getIn(["preferences", "config", "titles", "finance"]) || CommonTitles("finance");
};

export const getFinanceOptionsName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("finance_options");
  }
  return org.getIn(["preferences", "config", "titles", "finance_options"]) || CommonTitles("finance_options");
};

export const getPricePresentationName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("price_presentation");
  }
  return org.getIn(["preferences", "config", "titles", "price_presentation"]) || CommonTitles("price_presentation");
};

export const getSubtotalName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("subtotal");
  }
  return org.getIn(["preferences", "config", "titles", "subtotal"]) || CommonTitles("subtotal");
};

export const getTotalName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("total");
  }
  return org.getIn(["preferences", "config", "titles", "total"]) || CommonTitles("total");
};

export const getDescriptionName = (org: OrgRecord): string => {
  if (!org) {
    return CommonTitles("description");
  }
  return org.getIn(["preferences", "config", "titles", "description"]) || CommonTitles("description");
};

export const getPrefConfig = (org: OrgRecord, pref: string[]): any => {
  return org.getIn(["preferences", "config"].concat(pref));
};

export const getSettingsAcl = (org: OrgRecord, setting: string): any => {
  return org.getIn(["settings", "acl", setting]);
};

export const includeCheck = (org: OrgRecord, include: string[]): any => {
  return _.difference(include, org.get("include")).length === 0;
};

export const defaultUserAcl = (org: OrgRecord) => {
  return getIn(org, ["settings", "config", "user_acl", "default"], "salesrep");
};

export const orgNameAddress = (org: OrgRecord): string => {
  const address = org.address;
  let cityState = "";
  if (address.city && address.state) {
    cityState = ` (${address.city}, ${address.state})`;
  } else if (address.city) {
    cityState = ` (${address.city})`;
  } else if (address.state) {
    cityState = ` (${address.state})`;
  }
  return `${org.name}${cityState}`;
};

export type MeasurementLocation = "job_tab" | "line_item_editor";
export type MeasurementSection =
  | "roofing"
  | "siding"
  | "windows"
  | "doors"
  | "roof_faces"
  | "walls"
  | "fencing"
  | "sunroom"
  | "rooms"
  | "decks"
  | "pools";

export enum JobAttributeTypes {
  job_type = "job_type",
  level = "level",
  location = "location",
}

export const orgJobAttributeLinkKey = (attribute: JobAttributeTypes) => {
  return attribute === "job_type" ? "org_job_object_link" : `org_${attribute}_link`;
};

export const orgJobAttributeLinkIdName = (attr: JobAttributeTypes): string => {
  switch (attr) {
    case JobAttributeTypes.job_type:
      return "object_id";
    case JobAttributeTypes.level:
      return "level_id";
    case JobAttributeTypes.location:
      return "location_id";
  }
};

export interface IOrgPostData {
  id: number;
  parent_id: number;
  name: string;
  ancestral_name: string;
  phone_number: string;
  website: string;
  status: string;
  metadata: any;
  "prevent_login?": boolean;
  base36_id: string;
  job_count: number;
  org_count: number;
  user_count: number;
  url: string;
  settings_attributes?: ISettingsData;
  preferences_attributes?: IPreferenceData;
  address_attributes?: IAddressData;
  contract_languages_attributes?: any;
}

export interface IOrgData {
  id: number;
  uuid: string;
  parent_id: number;
  name: string;
  ancestral_name: string;
  phone_number: string;
  website: string;
  created_at: string;
  updated_at: string;
  status: string;
  metadata: any;
  "prevent_login?": boolean;
  base36_id: string;
  job_count: number;
  org_count: number;
  user_count: number;
  presentation_count: number;
  url: string;
  settings?: ISettingsData;
  preferences?: IPreferenceData;
  address?: IAddressData;
  images?: any[];
  events?: any[];
  lead_sources?: Array<string>;
  activated_price_list?: any;
  activated_inspection_list?: any;
  contract_languages?: any[];
  emails?: any[];
  loading?: boolean;
  include?: string[];
  errors?: string[];
}

export interface IOrgRecord {
  id: number;
  uuid: string;
  parent_id: number;
  name: string;
  ancestral_name: string;
  phone_number: string;
  website: string;
  created_at: string;
  updated_at: string;
  status: string;
  metadata: any;
  "prevent_login?": boolean;
  base36_id: string;
  job_count: number;
  org_count: number;
  user_count: number;
  presentation_count: number;
  url: string;
  settings: SettingsRecord;
  preferences: PreferenceRecord;
  address: AddressRecord;
  images: List<any>;
  events: List<any>;
  lead_sources: List<string>;
  activated_price_list: any;
  activated_inspection_list: any;
  contract_languages: any[];
  emails: any[];
  loading: boolean;
  include: string[];
  errors: Nullable<List<string>>;
}

export const defaultOrgProps = {
  id: 0,
  uuid: "",
  parent_id: 0,
  name: "",
  ancestral_name: "",
  phone_number: "",
  website: "",
  created_at: "",
  updated_at: "",
  status: "created",
  metadata: {},
  "prevent_login?": false,
  base36_id: "",
  job_count: 0,
  org_count: 0,
  user_count: 0,
  presentation_count: 0,
  url: "",
  settings: settingsFromJSON({}),
  preferences: preferencesFromJSON({}),
  address: addressFromJSON({}),
  loading: false,
  images: List(),
  events: List(),
  lead_sources: List(),
  activated_price_list: {},
  activated_inspection_list: {},
  contract_languages: [],
  emails: [],
  include: [],
  errors: List<string>(),
};

export class OrgRecord extends Record(defaultOrgProps) implements IOrgRecord {}

export const emptyOrgValue = fromJSON({});
