import { Record, List } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IScreenShareData>): ScreenShareRecord => {
  const data: IScreenShareRecord = { ...(json as any) };

  return new ScreenShareRecord(data);
};

export interface IScreenShareData {
  id: number;
  kind: string;
  uuid: string;
  uid: string;
  job_id: number;
  shared: Nullable<boolean>;
  start_time: string;
  started: string;
  ended: Nullable<string>;
  event_id: Nullable<number>;
  estimate_id: number;
  metadata: any;
  created_at: string;
  updated_at: string;
  redirect_on_end_url: string;

  loading?: boolean;
}

export interface IScreenShareRecord {
  id: number;
  kind: string;
  uuid: string;
  uid: string;
  job_id: number;
  shared: boolean;
  start_time: Date;
  started: Date;
  ended: Date;
  event_id: number;
  estimate_id: number;
  metadata: any;
  created_at: Date;
  updated_at: Date;
  redirect_on_end_url: string;

  loading: boolean;
  errors: Nullable<List<string>>;
}

export const defaultScreenShareProps = {
  id: 0,
  kind: "basic",
  uuid: "",
  uid: "",
  job_id: 0,
  shared: false,
  start_time: null,
  started: null,
  ended: null,
  event_id: 0,
  estimate_id: 0,
  metadata: {},
  created_at: null,
  updated_at: null,
  redirect_on_end_url: "",
  loading: false,
  errors: List<string>(),
};

export class ScreenShareRecord
  extends Record<IScreenShareRecord>(defaultScreenShareProps)
  implements IScreenShareRecord {}
