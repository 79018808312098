import * as ng from "angular";
import * as angulartics from "angulartics";
import { IRepository } from "app/src/Common/Repository";
import { IFlash } from "app/src/Common/FlashService";
import { ISession } from "app/src/Common/SessionService";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { IAddress } from "app/src/Models/Address";
import { MeasurementRecord } from "app2/src/records/Measurement";
import { IMeasurement } from "app/src/Models/Measurement";

class RapidAreaCtrl implements ng.IComponentController {
  [s: string]: any;
  public close: (value: any) => void;
  public dismiss: () => void;
  public resolve: { address: IAddress; measurement: MeasurementRecord };
  public measurement: IMeasurement;
  public address: IAddress;
  public activeTab = 0;
  public activeImage: any;
  public new_geojson: any = {};
  public newMeasurement = {
    area: 0,
    pitch: 0,
    degrees: 0,
    pitched_area: 0,
  };
  private deg2rad = Math.PI / 180;

  public static $inject = ["$scope", "Repository", "Session", "Flash", "BaseConfig", "$analytics", "$sce"];

  constructor(
    public $scope: ng.IScope,
    public Repository: IRepository,
    public Session: ISession,
    public Flash: IFlash,
    public BaseConfig: IBaseConfig,
    public $analytics: angulartics.IAnalyticsService,
    public $sce: ng.ISCEService,
  ) {}

  public $onInit() {
    this.measurement = this.Repository.Measurement.fromJSON(this.resolve.measurement.toJS());
    this.address = this.Repository.Address.fromJSON(this.resolve.address);
    if (this.measurement.geojson.rapid_area) {
      this.new_geojson.rapid_area = this.measurement.geojson.rapid_area;
      if (this.new_geojson.rapid_area[0] && this.new_geojson.rapid_area[0].properties.measurements) {
        this.newMeasurement = _.clone(this.new_geojson.rapid_area[0].properties.measurements);
      }
    } else {
      this.new_geojson.rapid_area = [];
    }
  }

  public save() {
    if (this.new_geojson.rapid_area[0]) {
      this.new_geojson.rapid_area[0].properties.measurements = this.newMeasurement;
    }
    this.close({ $value: this.new_geojson });
  }

  public cancelBtn() {
    this.trackEvent("cancel button", {
      Category: "RapidAreaComponent",
    });
    this.dismiss();
  }

  public calculate(measurement) {
    if (measurement.area <= 0 || measurement.pitch <= 0) {
      this.newMeasurement.pitched_area = this.newMeasurement.area;
      return {
        flat_area: this.newMeasurement.area,
        roof_area: this.newMeasurement.pitched_area,
      };
    }

    const newLength = Math.sqrt(Math.pow(measurement.pitch, 2) + Math.pow(12, 2));
    const factor = newLength / 12;
    this.newMeasurement.pitched_area = Math.round(measurement.area * factor);

    return {
      flat_area: this.newMeasurement.area,
      roof_area: this.newMeasurement.pitched_area,
    };
  }

  public measureFinished(data) {
    this.newMeasurement.area = data.area;
    this.new_geojson.rapid_area = data.points;
    if (data.points.length > 0) {
      this.new_geojson.rapid_area[0].properties.measurements = this.newMeasurement;
    }
  }

  public setPitch(measurement) {
    measurement.degrees = 0;
  }

  public isSavable(measurement) {
    return measurement.area <= 0 || measurement.pitch <= 0;
  }

  public convert(measurement) {
    const inRadians = Math.tan(measurement.degrees * this.deg2rad);
    const unrounded = inRadians * 12;

    measurement.pitch = parseFloat((Math.round(unrounded * 2) / 2).toFixed(1));
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        measurement: this.measurement.id,
      }),
    );
  }
}

export class RapidAreaComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/RsfMap/rapid_area_component.html";
  public bindings: any = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };

  constructor() {
    this.controller = RapidAreaCtrl;
  }
}
