import { ElevationRecord } from "app2/src/records/Elevation";
import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { List } from "immutable";
import { currentMeasurementId } from "app2/src/selectors/measurementCommon.selectors";
import { capitalize } from "humanize-plus";

export const rootKey = "elevations";

/**
 * Returns an elevation record
 *
 * @param {RootState} state
 * @param {{ elevationId: number }} options
 * @returns {ElevationRecord}
 */
export const elevation = (state: RootState, props: any) => state.getIn([rootKey, "byId", props.elevationId]);

/**
 * Returns elevations by id
 *
 * @param {RootState} state
 * @returns {Map<number, ElevationRecord>}
 */
export const elevationsById = (state: RootState, _props?: any) => state.getIn([rootKey, "byId"]);

/**
 * Returns elevations using elevationIds
 *
 * @param {RootState} state
 * @param {{ elevationIds: List<number> }} options
 * @returns {List<ElevationRecord>}
 */
export const elevations = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<ElevationRecord> => {
    const { state, props } = stateProps;
    const { elevationIds } = props;
    return elevationIds.map((id: number) => elevation(state, { elevationId: id }));
  },
);

/**
 * Returns an elevationId if an elevation exists on an opening
 *
 * @param {RootState} state
 * @param {{ openingId: number, openingType: "Door" | "Window" }} options
 * @returns {number}
 */
export const annotation = (state: RootState, props: any) => {
  return state.getIn([rootKey, `by${capitalize(props.openingType)}Id`, props.openingId]);
};

/**
 * Returns elevation ids by measurements
 *
 * @param {RootState} state
 * @param {{ measurementId: number }} options
 * @returns {List<number>}
 */
export const elevationIdsByMeasurement = (state: RootState, props?: any) =>
  state.getIn([rootKey, "byMeasurementId", props.measurementId, "ids"], List());

/**
 * Returns elevations by measurementId loading
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const elevationsLoadingByMeasurement = (state: RootState, props?: any) =>
  state.getIn([rootKey, "byMeasurementId", props.measurementId, "loading"]);

/**
 * Returns current elevations loading
 *
 * @param {RootState} state
 * @returns {boolean}
 */
export const currentElevationsLoading = createSelector(
  [currentMeasurementId, (state: RootState, props: any) => ({ state, props })],
  (measurementId, stateProps): boolean => {
    const { state } = stateProps;
    return elevationsLoadingByMeasurement(state, { measurementId });
  },
);

/**
 * Returns elevations of currentMeasurement from list query
 *
 * @param {RootState} state
 * @returns {List<ElevationRecord>}
 */
export const currentElevations = createSelector(
  [currentMeasurementId, elevationsById, (state: RootState, props: any) => ({ state, props })],
  (measurementId, elevationsById, stateProps): List<ElevationRecord> => {
    if (_.isNullOrUndefined(currentMeasurementId)) {
      return List();
    }
    const { state } = stateProps;
    return elevationIdsByMeasurement(state, { measurementId }).map((elevationId) => {
      return elevationsById.get(elevationId);
    });
  },
);

/**
 * Returns elevation image source ids of currentElevations
 *
 * @param {RootState} state
 * @returns {List<number>}
 */
export const elevationSourceImageIds = createSelector(
  [currentElevations],
  (currentElevations: List<ElevationRecord>): List<number> => {
    return currentElevations.map((elevation) => elevation.src_image_id);
  },
);

/**
 * Returns elevation image rendered ids of currentElevations
 * @param {RootState} state
 * @returns {List<number>}
 */
export const renderedImageIds = createSelector(
  [currentElevations],
  (currentElevations: List<ElevationRecord>): List<number> => {
    return currentElevations.map((elevation) => elevation.rendered_image_id);
  },
);

/**
 * Checks if imageId has an associated elevation in currentElevations
 *
 * @param {RootState} state
 * @param {{ imageId: number }} options
 * @returns {boolean}
 */
export const elevationExists = createSelector(
  [elevationSourceImageIds, renderedImageIds, (_: RootState, props: any) => props],
  (elevationSourceImageIds: List<number>, renderedImageIds: List<number>, props: any): boolean => {
    return elevationSourceImageIds.includes(props.imageId) || renderedImageIds.includes(props.imageId);
  },
);
