import * as React from "react";
import { connectFC } from "app2/src/connect";
import { RootState } from "../reducers";
import * as FontAwesome from "react-fontawesome";
import { useSelector } from "react-redux";

interface SpinnerProps {
  stateProperty?: any[];
  localProperty?: boolean;
  inline?: boolean;
  localInline?: boolean;
  children?: React.ReactNode;
}

export const Spinner: React.FC<SpinnerProps> = ({ stateProperty, children, localProperty, inline, localInline }) => {
  const loading = useSelector((state: RootState) => {
    if (stateProperty && _.isArray(stateProperty) && stateProperty.length > 0) {
      return state.getIn(stateProperty, true);
    } else return false;
  });

  const spinner = () => {
    return React.createElement(
      "rsf-spinner",
      { key: 1 },
      <div id="spinner-overlay" className="spinner-overlay">
        <div id="spinner" className="spinner">
          <img className="graphic" data-testid="overlay-spinner" />
        </div>
      </div>,
    );
  };

  const spinnerInline = () => {
    if (localInline) {
      return React.createElement(
        "rsf-spinner-local-inline",
        { key: 1 },
        <FontAwesome name="spinner" className="fa-pulse" data-testid="button-spinner" />,
      );
    } else {
      return React.createElement("rsf-spinner-inline", { key: 1 }, <img className="spinner-graphic" />);
    }
  };

  const components = [children];
  if (loading || localProperty) {
    components.unshift(inline || localInline ? spinnerInline() : spinner());
  }

  return <>{components}</>;
};

export default connectFC(Spinner);
