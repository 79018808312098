import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { push } from "connected-react-router/immutable";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

interface IReactRouterDirtyWatcher {
  check: () => boolean;
  reset: () => void;
  title?: string;
  validPathTest?: RegExp;
}

export const dirtyWatcherTitle = "There are unsaved changes that will be lost. Are you sure you want to continue?";

export const ReactRouterDirtyWatcher: React.FC<IReactRouterDirtyWatcher> = ({ check, reset, title, validPathTest }) => {
  if (!title) {
    title = dirtyWatcherTitle;
  }
  const history = useHistory();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(0);
  const [currentPath, setCurrentPath] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");
  const unblockRef: any = React.useRef();

  useEffect(() => {
    unblockRef.current = history.block((prompt) => {
      if (validPathTest && prompt.pathname.match(new RegExp(validPathTest))) {
        return;
      }

      if (check()) {
        setCurrentPath(prompt.pathname);
        setCurrentSearch(prompt.search || "");
        setOpenDialog((prevOpenDialog) => prevOpenDialog + 1);
        return false;
      }
    });

    return () => {
      unblockRef.current();
    };
  }, [history, check]);

  const handleOK = useCallback(() => {
    reset();
    unblockRef.current();
    dispatch(push(currentPath + currentSearch));
  }, [currentPath, currentSearch, history, dispatch]);

  return <ConfirmDialog title={title} openDialog={openDialog} onConfirm={handleOK} />;
};
