import * as React from "react";
import { Row, Col, InputGroup, Button, FormControl, ButtonGroup } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import DateTime from "app2/src/components/Common/DateTime";
import { presentation } from "app2/src/selectors/presentation.selectors";
import { PresentationRecord } from "app2/src/records/Presentation";
import * as presentationActions from "app2/src/reducers/presentation.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { List } from "immutable";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import track, { TrackingProp } from "react-tracking";
import * as FontAwesome from "react-fontawesome";
import { Can } from "app2/src/components/Common/CanComponent";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { presentationChanged } from "app2/src/selectors/presentation.selectors";
import Files from "react-files";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { task } from "app2/src/selectors/task.selectors";

const mapStateToProps = (state: RootState, ownProps: PresentationProps) => {
  const presentationRecord = presentation(state, { presentationId: ownProps.presentationId });
  return {
    presentation: presentationRecord,
    presentationChanged: presentationChanged(state, { presentationId: ownProps.presentationId }),
    task: task(state, { taskId: presentationRecord?.task_id }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: PresentationProps) => {
  return {
    destroyPresentation: (presentation: PresentationRecord) =>
      dispatch(presentationActions.AsyncActions.destroyPresentation(presentation)),
    editSelected: (name: string, value: boolean) =>
      dispatch(presentationActions.Actions.editSelected(ownProps.presentationId, value)),
    updatePresentation: () =>
      dispatch(presentationActions.AsyncActions.batchUpdatePresentation(List([ownProps.presentationId]))),
    editName: (presentationId: number, name: string) =>
      dispatch(presentationActions.Actions.editName(presentationId, name)),
    resetPresentation: () => dispatch(presentationActions.Actions.resetPresentation(ownProps.presentationId)),
    createCoverImage: (file: any) =>
      dispatch(presentationActions.AsyncActions.createCoverImage(file, ownProps.presentationId)),
    flashAddAlert: (level: FlashLevels, msg: string) => dispatch(commonActions.Actions.flashAddAlert(level, msg)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PresentationState {
  editing: boolean;
  loading: boolean;
}

interface PresentationProps {
  presentationId: number;
  dragHandle?: any;
  allowDestroy?: boolean;
  tracking?: TrackingProp;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentationProps;
@track((props) => {
  return {
    component: "OrgPresentation",
    presentation: props.presentationId,
  };
})
class Presentation extends React.Component<Props, PresentationState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
      loading: false,
    };

    this.onFilesChange = this.onFilesChange.bind(this);
    this.onFilesError = this.onFilesError.bind(this);
    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
    this.destroyPresentation = this.destroyPresentation.bind(this);
    this.edit = this.edit.bind(this);
    this.check = this.check.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.save = this.save.bind(this);
    this.viewPresentationLink = this.viewPresentationLink.bind(this);
    this.ellipsisToggle = this.ellipsisToggle.bind(this);
  }

  public async onFilesChange(files: any[]): Promise<void> {
    const { tracking, createCoverImage } = this.props;

    if (files.length > 0) {
      this.setState({ loading: true });
      await createCoverImage(files[0]);
      files.forEach((file) => {
        tracking.trackEvent({ action: "cover image uploaded", document: file.id, file: file.name });
      });

      this.setState({ loading: false });
    }
  }

  public onFilesError(error: any, file: any): void {
    const { flashAddAlert, tracking } = this.props;
    flashAddAlert(FlashLevels.danger, error.message);
    tracking.trackEvent({ action: "invalid file", file: file.name });
  }

  @track((_props, _state, [event]) => ({
    action: "checkBoxChanged",
    category: "CheckBox",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
  }))
  public checkBoxOnChange(event: any): void {
    const { editSelected, updatePresentation } = this.props;
    if (event.target.name !== "selected") {
      editSelected("displayInResource", false);
      editSelected("displayInProposalWatermark", false);
      editSelected("displayInAgreementWatermark", false);
    }
    editSelected(event.target.name, event.target.checked);
    if (event.target.name !== "selected") {
      updatePresentation();
    }
  }

  @track(() => ({
    action: "edit org presentation name",
  }))
  public edit(): void {
    this.setState({ editing: true });
  }

  public handleNameChange(event: any): void {
    const { presentation, editName } = this.props;
    editName(presentation.id, event.target.value);
  }

  @track((props) => ({
    action: "save org presentation name",
    doc: props.presentation.name,
  }))
  public save(): void {
    const { updatePresentation } = this.props;
    this.setState({ editing: false });
    updatePresentation();
  }

  public handleKeyPress(target: any): void {
    if (target.charCode === 13) {
      this.save();
    }
  }

  @track(() => ({
    action: "presentation deleted",
  }))
  public async destroyPresentation() {
    const { presentation, destroyPresentation } = this.props;
    await destroyPresentation(presentation);
  }

  @track(() => ({
    action: "view presentation link",
  }))
  public viewPresentationLink(): void {}

  @track((_props, _state, [isOpen]) => ({
    action: "toggle ellipsis dropdown",
    open: isOpen,
  }))
  public ellipsisToggle(_isOpen: boolean): void {}

  public check(): boolean {
    const { presentationChanged } = this.props;

    return presentationChanged;
  }

  public render() {
    const { presentation, dragHandle, resetPresentation, task } = this.props;
    const { editing, loading } = this.state;

    if (_.isNullOrUndefined(presentation)) {
      return null;
    }

    let coverImageUrl = "/assets/images/icons-large/presentation_placeholder.8046bc02.png";
    if (presentation.cover_image?.medium?.url) {
      coverImageUrl = presentation.cover_image.medium.url;
    }

    if (presentation.loading) {
      return (
        <Row className="table-row" data-testid="Presentation">
          <Col>
            <SpinnerComponent localProperty={true} localInline />
          </Col>
        </Row>
      );
    }

    return (
      <>
        <Row className="table-row" data-testid="Presentation">
          <Col sm={1} title={presentation.name}>
            <Can resource="org" permission="update">
              <IncludeCheckBox name="selected" checked={presentation.selected} onChange={this.checkBoxOnChange} />
              {dragHandle && <img {...dragHandle} src="/assets/images/icons/ic_sort.ea6f8933.png" />}
            </Can>
          </Col>
          <Col sm={3}>
            <SpinnerComponent localProperty={loading || (task && task?.status !== "finished")} localInline />
            {!loading && (!task || task?.status === "finished") && (
              <>
                <div style={{ width: "38px", float: "left" }}>
                  <Can resource="org" permission="update">
                    <Files
                      className="files-dropzone pull-right"
                      onChange={this.onFilesChange}
                      onError={this.onFilesError}
                      accepts={["image/*"]}
                      multiple={false}
                      maxFileSize={30000000}
                      minFileSize={0}
                      clickable>
                      <a className="action-button" href="#" title="Edit Cover Image">
                        <i className="rsf-plus-link rsf-base-66"></i>
                      </a>
                    </Files>
                  </Can>
                </div>
                <div style={{ marginLeft: "38px", height: "70px" }}>
                  <img
                    style={{ objectPosition: "left" }}
                    className="img-contain"
                    data-testid="cover_image"
                    src={coverImageUrl}
                  />
                </div>
              </>
            )}
          </Col>
          <Col sm={3} className="break-word">
            {editing ? (
              <InputGroup>
                <DirtyWatcher check={this.check} reset={resetPresentation} />
                <FormControl
                  autoFocus
                  data-testid="name-editing"
                  placeholder="Presentation Name"
                  name="name"
                  value={presentation.name}
                  onChange={this.handleNameChange}
                  onKeyPress={this.handleKeyPress}
                />
                <InputGroup.Append>
                  <Button onClick={this.save} data-testid="save-button">
                    <FontAwesome name="save" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            ) : (
              <>
                <Can resource="org" permission="update">
                  <a className="action-button" href="#" title="Edit Name" onClick={this.edit}>
                    <i className="rsf-edit-link rsf-base-66"></i>
                  </a>
                </Can>
                {presentation.name}
              </>
            )}
          </Col>
          <Col sm={3}>
            <DateTime date={presentation.created_at} variant="vertical" />
          </Col>
          <Col sm={2}>
            <ButtonGroup>
              <SpinnerComponent localProperty={task && task?.status !== "finished"} localInline />
              {(!task || task?.status === "finished") && (
                <>
                  <a
                    data-testid="presentation-link"
                    className="action-button"
                    href={presentation?.url}
                    onClick={this.viewPresentationLink}
                    target="_blank"
                    title="Open Presentation">
                    {presentation.target === "blank" ? (
                      <i className="rsf-link-link rsf-base-66"></i>
                    ) : (
                      <i className="rsf-pdf-link rsf-base-66"></i>
                    )}
                  </a>
                  <Can resource="org" permission="update">
                    <ConfirmDialog title={`Are you sure you want to delete presentation: ${presentation.name}?`}>
                      {(confirm) => (
                        <a
                          className="action-button"
                          data-testid="destroy"
                          title="Delete Presentation"
                          onClick={confirm(this.destroyPresentation)}>
                          <i className="rsf-delete-link rsf-base-66"></i>
                        </a>
                      )}
                    </ConfirmDialog>
                  </Can>
                </>
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default connector(Presentation);
