import { List, Record } from "immutable";
import { defaultOpeningProps, IOpeningData, IOpeningRecord } from "app2/src/records/Opening";
import { fromJSON as openingEstimationFromJSON } from "app2/src/records/OpeningEstimation";

export const fromJSON = (json: Partial<IWindowData>): WindowRecord => {
  const recordData: IWindowRecord = { ...(json as any) };
  recordData.kind = "Window";

  if (json.opening_estimations) {
    recordData.opening_estimations = List(json.opening_estimations.map((oe) => openingEstimationFromJSON(oe)));
  }

  return new WindowRecord(recordData);
};

export type IWindowData = IOpeningData;

export type IWindowRecord = IOpeningRecord;

export class WindowRecord extends Record(defaultOpeningProps) implements IWindowRecord {}
