import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IPoolData>): PoolRecord => {
  const recordData: IPoolRecord = { ...(json as any) };

  return new PoolRecord(recordData);
};

export interface IPoolData {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  area: number;
  perimeter: number;
  volume: number;
  average_depth: number;
  metadata: any;
  _destroy?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface IPoolRecord {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  area: number;
  perimeter: number;
  volume: number;
  average_depth: number;
  metadata: any;

  _destroy: Nullable<boolean>;
  created_at: Date;
  updated_at: Date;
}

export const defaultPoolProps: IPoolRecord = {
  id: 0,
  name: "",
  designator: "",
  area: 0,
  perimeter: 0,
  volume: 0,
  average_depth: 0,
  metadata: {},

  _destroy: null,
  created_at: new Date(),
  updated_at: new Date(),
};

export class PoolRecord extends Record(defaultPoolProps) implements IPoolRecord {
  public readonly id!: number;
  public readonly name!: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  public readonly designator!: string;
  public readonly area!: number;
  public readonly perimeter!: number;
  public readonly volume!: number;
  public readonly average_depth!: number;
  public readonly metadata!: any;

  public readonly _destroy!: Nullable<boolean>;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IPoolRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
