export class PaymentInfoRoutes {
  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider.state("root.payment_info", <ng.ui.IState>{
      url: "/payment_info/:id",
      views: {
        "container@": {
          templateUrl: "src/Profile/alert.html",
          controller: "AlertCtrl",
          controllerAs: "$ctrl",
        },
      },
    });
  }
}
