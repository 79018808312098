import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState, RootActions } from ".";
import { RootDispatchType } from "../store";
import { create, loadPaymentRequests } from "../api/paymentRequest.service";
import { handleErrors } from "app2/src/reducers/Utils";
import { IPaymentRequestData } from "app2/src/records/PaymentRequest";
import { paymentRequest } from "app2/src/reducers/paymentRequest.selectors";
import { AsyncActions as EmailAsyncActions } from "app2/src/reducers/email.actions";
import { QueryParamsRecord } from "../records/Page";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { currentJobId } from "../selectors/job.selectors";
import { List } from "immutable";

export const INIT_PAYMENT_REQUEST = "@paymentRequest/INIT_PAYMENT_REQUEST";
export const FETCH_PAYMENT_REQUEST = "@paymentRequest/FETCH_PAYMENT_REQUEST";
export const RECEIVE_PAYMENT_REQUEST = "@paymentRequest/RECEIVE_PAYMENT_REQUEST";
export const SET_LOADED = "@paymentRequest/SET_LOADED";
export const UPDATE_FORM = "@paymentRequest/UPDATE_FORM";
export const RECEIVE_PAYMENT_REQUESTS = "@paymentRequest/RECEIVE_PAYMENT_REQUESTS";

export const Actions = {
  initializePaymentRequest: () => createAction(INIT_PAYMENT_REQUEST, {}),
  fetchPaymentRequest: (id: number) => createAction(FETCH_PAYMENT_REQUEST, { id }),
  receivePaymentRequest: (paymentRequest: Partial<IPaymentRequestData>) =>
    createAction(RECEIVE_PAYMENT_REQUEST, { paymentRequest }),
  setLoaded: (id: number) => createAction(SET_LOADED, { id }),
  update: (event: { rootPath: (string | number)[]; name: string; value: string }) =>
    createAction(UPDATE_FORM, { event }),
  receivePaymentRequests: (paymentRequests: IPaymentRequestData[]) =>
    createAction(RECEIVE_PAYMENT_REQUESTS, { paymentRequests }),
};

type ThunkResult<T> = ThunkAction<T, RootState, {}, RootActions>;

export const AsyncActions = {
  createPaymentRequest: (paymentRequestId: number, provider: "email" | "text"): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      let paymentRequestRecord = paymentRequest(getState(), { paymentRequestId });
      let to = "";
      switch (provider) {
        case "email":
          to = paymentRequestRecord.emails;
          break;
        case "text":
          to = paymentRequestRecord.sms;
          break;
      }
      dispatch(Actions.fetchPaymentRequest(paymentRequestId));
      paymentRequestRecord = paymentRequestRecord.merge({ provider, to });

      try {
        const response = await create(paymentRequestRecord);
        dispatch(Actions.receivePaymentRequest(response.payment_request));
        await dispatch(
          EmailAsyncActions.sendMessage({ kind: "payment_request", provider, to, data: response.payment_request }),
        );
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setLoaded(paymentRequestId));
      }
    };
  },
  loadPaymentRequests: (queryParams: QueryParamsRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        dispatch(paginationActions.Actions.fetchPage("paymentRequests", queryParams));

        const jobId = currentJobId(getState());
        const response = await loadPaymentRequests(jobId, queryParams);
        const pageData = {
          ids: List(_.pluck(response.payment_requests, "id")),
          pagination: response.meta.pagination,
        };

        dispatch(Actions.receivePaymentRequests(response.payment_requests));
        dispatch(paginationActions.Actions.receivePage("paymentRequests", queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response, dispatch);
        dispatch(paginationActions.Actions.receivePageError("paymentRequests", queryParams, errors));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
