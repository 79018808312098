import * as React from "react";
import { EstimateRecord, fromJSON as estimateFromJSON } from "app2/src/records/Estimate";
import { EstimateFinanceOptionRecord } from "app2/src/records/EstimateFinanceOption";
import { FinanceApplicationRecord, fromJSON as financeApplicationFromJSON } from "app2/src/records/FinanceApplication";
import { Record, Map, List } from "immutable";
import { noop } from "underscore";

interface ContextProps {
  estimate: EstimateRecord;
  financeApplication: FinanceApplicationRecord;
  orgId: number;
  filters: Map<FilterAction, List<number>>;
  dispatch: FilterDispatch;
  offers: List<EstimateFinanceOptionRecord>;
}

export type FilterAction = "payment" | "term" | "merchant";
export type FilterDispatch = (
  action: FilterAction | "financeApplicationReload" | "pendingApproval",
  payload?: [number, number],
) => void;
export const emptyFilter = Map<FilterAction, List<number>>([
  ["payment", List([0, 0])],
  ["term", List([0, 0])],
  ["merchant", List([0, 0])],
]);

const defaultContextProps: ContextProps = {
  estimate: estimateFromJSON({ loading: true }),
  financeApplication: financeApplicationFromJSON({ loading: true }),
  orgId: 0,
  filters: emptyFilter,
  dispatch: noop,
  offers: List(),
};

export class ContextRecord extends Record(defaultContextProps) implements ContextProps {}

export const Context = React.createContext(new ContextRecord());
