import * as React from "react";
import { Row, Col, Button, FormControl, FormGroup } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { PaymentTermRecord } from "app2/src/records/PaymentTerm";
import {
  PaymentTermTemplateRecord,
  PaymentTermItemRecord,
  PaymentTermItemKinds,
  paymentTermItemFromJSON,
} from "app2/src/records/PaymentTermTemplate";
import { getOrgPaymentTermTemplates } from "app2/src/selectors/paymentTermTemplate.selectors";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { ItemEditor } from "./ItemEditor";
import track from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";
import { presentModePreferencesConfig } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: ChooserProps) => {
  return {
    paymentTermTemplates: getOrgPaymentTermTemplates(state, ownProps),
    showPaymentTermDisplay: presentModePreferencesConfig(state, {
      path: ["estimator", "show_pricing", "payment_term_display"],
    }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ChooserProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ChooserProps {
  orgId: number;
  paymentTerm: PaymentTermRecord;
  doneButton?: boolean;
  showHeaderText?: boolean;
  view: string;
  addItem: (pti: PaymentTermItemRecord) => void;
  addTemplate: (ptt: PaymentTermTemplateRecord) => void;
  removeItem: (pti: PaymentTermItemRecord) => void;
  updateItem: (pti: PaymentTermItemRecord) => void;
}

export interface ChooserState {
  paymentTerm: PaymentTermRecord;
  previousView: string;
  view: string;
  showHeaderText: boolean; // this is not dynamically updated, current workflow assumes it's set at mount
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ChooserProps;

@track(
  () => {
    return {
      component: "PaymentTermChooser",
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
export class Chooser extends React.Component<Props, ChooserState> {
  protected debouncedUpdate: (ptir: PaymentTermItemRecord) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      previousView: null,
      view: props.view,
      showHeaderText: true,
      paymentTerm: props.paymentTerm,
    };

    this.debouncedUpdate = _.debounce(props.updateItem, 100);
    this.renderDisplay = this.renderDisplay.bind(this);
    this.renderEdit = this.renderEdit.bind(this);
    this.renderEasyEdit = this.renderEasyEdit.bind(this);
    this.changeView = this.changeView.bind(this);
    this.templateChanged = this.templateChanged.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeKind = this.changeKind.bind(this);
    this.changeNumber = this.changeNumber.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.addItem = this.addItem.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const prev = prevProps.paymentTerm ? JSON.stringify(prevProps.paymentTerm) : "";
    const current = this.props.paymentTerm ? JSON.stringify(this.props.paymentTerm) : "";

    if (prev === current) {
      return;
    }

    this.setState({
      paymentTerm: this.props.paymentTerm,
    });
  }

  public componentDidMount() {
    const { showHeaderText } = this.props;

    if (showHeaderText !== null && showHeaderText !== undefined) {
      this.setState({ showHeaderText });
    }
  }

  public render() {
    const { view } = this.state;
    if (view === "fullEdit") {
      return this.renderEdit();
    } else if (view === "easyEdit") {
      return this.renderEasyEdit();
    } else {
      // must be "display"
      return this.renderDisplay();
    }
  }

  public renderDisplay() {
    const { showHeaderText, paymentTerm } = this.state;
    const { showPaymentTermDisplay } = this.props;

    const amountDescriptionCol = showPaymentTermDisplay ? 8 : 12;

    return (
      <div className="form-section">
        <div className="form-section-heading">
          {showHeaderText && <h3>Payment Terms</h3>}
          {paymentTerm &&
            paymentTerm.payment_term_items.map((pti, idx) => {
              let col1 = <span></span>;
              if (pti.type === "percent") {
                col1 = (
                  <Col sm={amountDescriptionCol}>
                    {pti.amount}% - {pti.description}
                  </Col>
                );
              } else if (pti.type === "amount") {
                col1 = <Col sm={amountDescriptionCol}>{pti.description}</Col>;
              }

              return (
                <Row key={idx} className="fix-margin-left bottom-margin-5">
                  {col1}
                  {showPaymentTermDisplay ? <Col sm={4}>{CurrencyFormat(pti.calculated)}</Col> : null}
                </Row>
              );
            })}
          <Row>
            <Col sm={12}>
              <Button variant="default" id="edit" onClick={() => this.changeView("fullEdit")}>
                Edit
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  public renderEdit() {
    const { paymentTermTemplates } = this.props;
    let { doneButton } = this.props;
    const { showHeaderText, paymentTerm } = this.state;

    if (doneButton === undefined || doneButton === null) {
      doneButton = true;
    }

    let doneElement = null;
    if (doneButton) {
      doneElement = (
        <Row>
          <Col sm={12}>
            <Button variant="default" onClick={() => this.changeView("display")}>
              Done
            </Button>
          </Col>
        </Row>
      );
    }

    let paymentTermElement = null;
    if (paymentTermTemplates.count() > 0) {
      paymentTermElement = (
        <Row>
          <Col sm={12}>
            <FormGroup>
              <FormControl
                as="select"
                id="template"
                onChange={(e) => this.templateChanged(parseFloat((e.target as any).value))}>
                <option value="">--Payment Term Templates--</option>
                {paymentTermTemplates.map((pt, idx) => {
                  return (
                    <option key={idx} value={pt.id}>
                      {pt.description}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
      );
    }

    return (
      <div className="form-section">
        <div className="form-section-heading">
          <Row>
            {showHeaderText && (
              <Col lg={5}>
                <h3>Payment Terms</h3>
              </Col>
            )}
            <Col lg={showHeaderText ? 7 : 12}>{paymentTermElement}</Col>
          </Row>
        </div>
        <div className="form-section-content">
          {paymentTerm.payment_term_items.map((pti, idx) => {
            return (
              <ItemEditor
                key={idx}
                item={pti}
                deleteItem={this.deleteItem}
                changeAmount={this.changeNumber}
                changeDesc={this.changeDescription}
                changeKind={this.changeKind}
                changePaymentMethod={this.changePaymentMethod}
              />
            );
          })}
          <Row>
            <Col md={{ offset: 11, span: 1 }}>
              <i className="rsf-plus-link rsf-base-66" title="Add Payment Term" onClick={this.addItem} />
            </Col>
          </Row>
          {doneElement}
        </div>
      </div>
    );
  }

  public renderEasyEdit() {
    const { paymentTermTemplates } = this.props;
    const { showHeaderText, paymentTerm } = this.state;
    let paymentTermElement = null;
    if (paymentTermTemplates.count() > 0) {
      paymentTermElement = (
        <Row>
          <Col sm={12}>
            <FormGroup>
              <FormControl
                as="select"
                id="template"
                onChange={(e) => this.templateChanged(parseFloat((e.target as any).value))}>
                <option value="">--Payment Term Templates--</option>
                {paymentTermTemplates.map((pt, idx) => {
                  return (
                    <option key={idx} value={pt.id}>
                      {pt.description}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
      );
    }

    return (
      <div className="form-section">
        <div className="form-section-heading">
          <Row>
            {showHeaderText && (
              <Col lg={5}>
                <h3>Payment Terms</h3>
              </Col>
            )}
            <Col lg={showHeaderText ? 7 : 12}>{paymentTermElement}</Col>
          </Row>
          {paymentTerm &&
            paymentTerm.payment_term_items.map((pti, idx) => {
              let col1 = <span></span>;
              if (pti.type === "percent") {
                col1 = (
                  <Col sm={8}>
                    {pti.amount}% - {pti.description}
                  </Col>
                );
              } else if (pti.type === "amount") {
                col1 = <Col sm={8}>{pti.description}</Col>;
              }

              return (
                <Row key={idx} className="fix-margin-left bottom-margin-5">
                  {col1}
                  <Col sm={4}>{CurrencyFormat(pti.calculated)}</Col>
                </Row>
              );
            })}
          <Row>
            <Col sm={12}>
              <Button variant="default" id="edit" onClick={() => this.changeView("fullEdit")}>
                Edit
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  @track((_props, _state, [view]) => ({ action: `view changed ${view}` }))
  public changeView(newView) {
    const { view, previousView } = this.state;
    if (previousView === null) {
      this.setState({
        previousView: view,
        view: newView,
      });
    } else {
      this.setState({
        view: previousView,
        previousView: view,
      });
    }
  }

  public changeNumber(pti: PaymentTermItemRecord, value: number): void {
    pti = pti.set("amount", value);
    this.debouncedUpdate(pti);
    this.updateState(pti);
  }

  public changeDescription(pti: PaymentTermItemRecord, desc: string): void {
    pti = pti.set("description", desc);
    this.debouncedUpdate(pti);
    this.updateState(pti);
  }

  public changeKind(pti: PaymentTermItemRecord, kind: PaymentTermItemKinds): void {
    pti = pti.set("type", kind);
    this.debouncedUpdate(pti);
    this.updateState(pti);
  }

  public changePaymentMethod(pti: PaymentTermItemRecord, paymentMethod: "cash" | "financed") {
    pti = pti.set("paymentMethod", paymentMethod);
    this.debouncedUpdate(pti);
    this.updateState(pti);
  }

  public deleteItem(pti: PaymentTermItemRecord): void {
    const { removeItem } = this.props;
    removeItem(pti);
  }

  public updateState(pti: PaymentTermItemRecord): void {
    const { paymentTerm } = this.state;
    const index: number = paymentTerm.payment_term_items.findIndex((toMatch) => toMatch.uuid === pti.uuid);
    this.setState({
      paymentTerm: paymentTerm.setIn(["payment_term_items", index], pti),
    });
  }

  public addItem(): void {
    const { addItem } = this.props;
    addItem(paymentTermItemFromJSON({}));
  }

  public templateChanged(templateId: number) {
    const { addTemplate, paymentTermTemplates } = this.props;

    if ((templateId as any) === "") {
      return;
    }

    addTemplate(paymentTermTemplates.find((pt) => pt.id === templateId));
  }
}

export default connector(Chooser);
