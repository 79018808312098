import * as React from "react";
import { Form, Table } from "react-bootstrap";
import { currentMeasurementRooms } from "app2/src/selectors/room.selectors";
import { useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { List, Map } from "immutable";
import { IPretty } from "app/src/Common/PrettyNameService";
import { imperial_uom } from "app/src/Common/Constants";
import { StoreRegistry } from "app2/src/storeRegistry";

export interface SelectRoomsProps {
  selectedRoomIds: List<number>;
  groupRooms: boolean;
  toggleRoom: (roomId: number) => void;
  toggleGroupRooms: () => void;
  toggleAllRooms: (ids: List<number>) => void;
}

export const SelectRooms: React.FC<SelectRoomsProps> = ({
  selectedRoomIds,
  groupRooms,
  toggleRoom,
  toggleGroupRooms,
  toggleAllRooms,
}) => {
  const pretty: IPretty = StoreRegistry.get("Pretty");
  const rooms = useSelector((state: RootState) => currentMeasurementRooms(state, {}));
  const roomIds = React.useMemo(() => rooms.map((r) => r.id), [rooms]);

  const keys = List(["floor_plan_area", "wall_total_area", "ceiling_area", "floor_plan_perimeter"]);
  const roomValuesById = React.useMemo(() => {
    if (rooms?.size < 1) return List([]);

    let byId = Map<number, any>();

    rooms.forEach((r) => {
      byId = byId.set(
        r.id,
        keys.map((key) => ({
          label: pretty.name[key],
          value: r[key]?.toFixed(2),
          uom: imperial_uom[`interior_${key}`],
        })),
      );
    });

    return byId;
  }, [rooms]);

  React.useEffect(() => {
    if (selectedRoomIds.size <= 1 && groupRooms) {
      toggleGroupRooms();
    }
  }, [selectedRoomIds]);

  return (
    <>
      <h4 className="font-weight-bold mb-1">Select Rooms</h4>
      <p className="font-weight-light">Rooms will be added as groups (based on pre-defined measurements)</p>

      {rooms?.size > 0 ? (
        <>
          <div className="overflow-y-scroll rooms-table-container">
            <Table bordered striped className="mb-0">
              <thead>
                <tr>
                  <td className="px-4">
                    <Form.Check
                      type="checkbox"
                      inline
                      checked={selectedRoomIds.size === rooms.size}
                      onChange={() => toggleAllRooms(roomIds)}
                      id="select-all-checkbox"
                    />
                  </td>
                  <td>Name</td>
                  {keys.map((k) => (
                    <td key={k}>{pretty.name[k]}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rooms?.map((r, idx) => (
                  <tr key={idx}>
                    <td style={{ width: "40px" }}>
                      <Form.Check
                        type="checkbox"
                        checked={selectedRoomIds.includes(r.id)}
                        onChange={() => toggleRoom(r.id)}
                        id={"room-checkbox-" + idx}
                      />
                    </td>
                    <td>{r.name}</td>
                    {roomValuesById.get(r.id).map((obj) => (
                      <td key={obj.label}>
                        {obj.value} {obj.uom}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="d-flex align-items-center mt-4">
            <Form.Check
              type="checkbox"
              inline
              className="mr-1"
              checked={groupRooms}
              onChange={toggleGroupRooms}
              id="groupRooms-checkbox"
              disabled={selectedRoomIds.size <= 1}
            />
            <Form.Label className="mb-0">Group rooms together</Form.Label>
          </div>
        </>
      ) : (
        <div className="form-section blank-state">
          <img src="/assets/images/icons-large/design-tools.78d514fb.png" />
          <h2>No rooms were found.</h2>
          <p>Please add a room in the Measurements tab to use this Estimate Template.</p>
        </div>
      )}
    </>
  );
};
