import { ProductRecord } from "app2/src/records/Product";
import { convert, InvalidConversionError } from "app2/src/services/conversion.service";
import { FlashLevels } from "./FlashLevels";
import { FlashService, IFlash } from "./FlashService";

export interface IConvert {
  units_of_measure: string[];
  convert(value: number, unitFrom: string, unitTo: string, product: ProductRecord, showMessage?: boolean);
}

export class UnitConversionService implements IConvert {
  public units_of_measure: string[];

  public static $inject = ["valid_uom", "Flash"];
  constructor(
    public valid_uom: string[],
    public Flash: IFlash,
  ) {
    this.units_of_measure = this.valid_uom;
  }

  public convert(value: number, unitFrom: string, unitTo: string, product: ProductRecord, showMessage = true): number {
    let product_standard = 0;
    let product_standard_uom = "";
    if (product && product.standard_measurement) {
      product_standard = product.standard_measurement;
      product_standard_uom = product.standard_uom;
    }
    try {
      return convert(value, unitFrom, unitTo, product_standard, product_standard_uom);
    } catch (e) {
      if (e instanceof InvalidConversionError) {
        let message = `Unable to convert measurement ("${e.fromUom}" to "${e.toUom}")`;
        if (product) {
          message = `Product: ${product.name}. ${message}`;
        }
        if (showMessage) {
          this.Flash.addMessage(FlashLevels.danger, message);
        }
        return value;
      }

      throw e;
    }
  }
}
