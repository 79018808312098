import * as React from "react";
import { useIsOversized } from "app2/src/hooks/useIsOversized/useIsOversized";
import { RoomRecord } from "app2/src/records/Room";
import { push } from "connected-react-router/immutable";
import { List } from "immutable";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router";
import { useTracking } from "react-tracking";
import { usePrevious } from "app2/src/hooks/usePrevious";

export interface InteriorTabsProps {
  rooms: List<RoomRecord>;
}

export const InteriorTabs: React.FC<InteriorTabsProps> = ({ rooms }) => {
  // Hooks
  const match = useRouteMatch();
  const route: any = useRouteMatch(`${match.url}/:roomId`);
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "Interiors" });

  // Local State
  const [nonOverflowRoomsCount, setNonOverflowRoomsCount] = React.useState(rooms.size);
  const ref = React.useRef();
  const prevRooms = usePrevious(rooms);
  const firstRun = React.useRef(true);

  useIsOversized(
    ref,
    (isOversized: boolean, resizedUp: boolean, scrollHeight: number) => {
      if (isOversized) {
        // scrollHeight is > 70 when there are 3 rows of rooms, remove more to rely on less rerenders
        const removeCount = scrollHeight > 70 ? 3 : 1;
        setNonOverflowRoomsCount(nonOverflowRoomsCount - removeCount);
      }
      if (resizedUp) setNonOverflowRoomsCount(rooms.size);
    },
    1,
  );

  const roomsOverflow = React.useMemo(() => rooms.slice(nonOverflowRoomsCount), [rooms, nonOverflowRoomsCount]);
  const overflowActiveRoom = React.useMemo(
    () => roomsOverflow.find((r) => r.id.toString() === route?.params?.roomId)?.id,
    [roomsOverflow, route],
  );

  // Methods
  const tabNavigate = (data) => {
    trackEvent({
      action: "interior tab navigate",
      room: data,
    });
    dispatch(push(data));
  };

  const debouncedResetCount = React.useMemo(
    () =>
      _.debounce((prevRooms: List<RoomRecord>, currentRooms: List<RoomRecord>) => {
        if (!prevRooms?.equals(currentRooms)) setNonOverflowRoomsCount(currentRooms.size);
      }, 500),
    [],
  );

  // Lifecycle
  React.useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    debouncedResetCount(prevRooms, rooms);
  }, [rooms]);

  return (
    <div className="d-flex nav-tabs">
      <div ref={ref} style={{ height: 33 }} className="overflow-y-hidden">
        <Tabs
          onSelect={tabNavigate}
          aria-label="interior-tabs"
          activeKey={route?.params?.roomId}
          className="border-bottom-0">
          {rooms.map((room, idx) => {
            if (idx < nonOverflowRoomsCount)
              return <Tab key={room.id} eventKey={room.id.toString()} title={room.name || "(no name)"} />;
          })}
        </Tabs>
      </div>
      {roomsOverflow.size > 0 && (
        <Dropdown className="nav-tabs interior-tabs border-bottom-0">
          <Dropdown.Toggle as="a" className={"nav-item nav-link link" + (overflowActiveRoom ? " active" : "")}>
            More Rooms ({roomsOverflow.size})
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {roomsOverflow.map((r) => (
              <Dropdown.Item
                key={r.id}
                onClick={() => tabNavigate(r.id.toString())}
                className={overflowActiveRoom === r.id ? "active" : ""}>
                {r.name || "(no name)"}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};
