import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { capitalize } from "humanize-plus";
import { token, tokenChanged, lastSavedToken } from "app2/src/selectors/token.selectors";
import { RsfFormGroup } from "app2/src/components/RsfForms/RsfFormGroup";
import * as tokenActions from "app2/src/reducers/token.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import * as userActions from "app2/src/reducers/user.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import Spinner from "app2/src/components/SpinnerComponent";
import { required, arrayRequired } from "app2/src/helpers/FinalFormValidator";
import { Condition } from "app2/src/components/RsfForms/Condition";
import { RsfCheckboxGroup } from "app2/src/components/RsfForms/RsfCheckboxGroup";
import { Nullable } from "app2/src/records";
import Task from "app2/src/components/Task";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";
import track, { TrackingProp } from "react-tracking";
import FilterForm from "app2/src/components/Integrations/Salesforce/SettingsModal/FilterForm";
import RsfFinalForm from "app2/src/components/RsfForms/RsfFinalForm";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { Field } from "react-final-form";
import { RsfSelectAdapter } from "app2/src/components/RsfForms/RsfSelectAdapter";
import { validJobBuilderTypes } from "app/src/Common/Constants";
import { QueryableDropdown } from "app2/src/components/Common/QueryableDropdown";
import { queryParamsFromJSON, QueryParamsRecord } from "app2/src/records/Page";
import * as config from "react-global-configuration";
import { byId as usersById } from "app2/src/selectors/user.selectors";
import { getFullName } from "app2/src/records/UserRecord";

const mapStateToProps = (state: RootState, ownProps: SettingsModalProps) => {
  return {
    token: token(state, { kind: ownProps.integration }),
    lastSavedToken: lastSavedToken(state, { kind: ownProps.integration }),
    tokenChanged: tokenChanged(state, { kind: ownProps.integration }),
    orgId: currentOrgId(state),
    users: () => usersById(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: SettingsModalProps) => {
  return {
    getToken: (orgId: number) => dispatch(tokenActions.AsyncActions.getToken(orgId, ownProps.integration)),
    editTokenData: (orgId: number, data: Map<string, any>) =>
      dispatch(tokenActions.Actions.editTokenData(orgId, ownProps.integration, data)),
    resetToken: (orgId: number) => dispatch(tokenActions.Actions.resetToken(orgId, ownProps.integration)),
    testIntegration: () => dispatch(orgActions.AsyncActions.testIntegration(ownProps.integration)),
    queryUsers: (queryParams: QueryParamsRecord, modelName: string) =>
      dispatch(userActions.AsyncActions.listUsers(queryParams, modelName)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface SettingsModalProps {
  triggerOpen: number;
  integration: "salesforce" | "improveit360";
  tracking?: TrackingProp;
}

interface SettingsModalState {
  open: boolean;
  loading: boolean;
  taskId: Nullable<string>;
  selectedOccUserId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & SettingsModalProps;
@track(() => {
  return {
    component: "SettingsModal",
  };
})
class SettingsModal extends React.Component<Props, SettingsModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      taskId: null,
      selectedOccUserId: null,
    };

    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.update = this.update.bind(this);
    this.check = this.check.bind(this);
    this.onQueryList = this.onQueryList.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const { triggerOpen, getToken, token, orgId } = this.props;
    const { selectedOccUserId } = this.state;

    if (prevProps.triggerOpen !== triggerOpen) {
      this.setState({ open: true });
      getToken(orgId);
    }
    const occUserIdToken = token?.toJS()?.data?.notes_sync?.occ_user_id;
    if (selectedOccUserId === null && occUserIdToken !== undefined) {
      this.setState({ selectedOccUserId: occUserIdToken });
    }
  }

  @track(() => ({
    action: "save token settings",
  }))
  public async onSubmit(formData: any) {
    const { testIntegration } = this.props;
    try {
      this.update(formData);

      this.setState({ loading: true });
      const { taskId, close } = await testIntegration();
      this.setState({ taskId, loading: false });

      if (close) {
        this.onHide();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  public update(values: any) {
    const { editTokenData, token, orgId } = this.props;

    if (this.state.selectedOccUserId !== undefined && this.state.selectedOccUserId !== null) {
      values.data.notes_sync = { occ_user_id: this.state.selectedOccUserId };
    }

    if (values.data.job_object !== "lead") {
      values.data.lead_create = false;
      values.data.lead_update = false;
      values.data.lead_assignment_update = false;
      values.data.lead_status_update = false;
    }

    const newToken = token.mergeIn(["data"], values.data);

    editTokenData(orgId, newToken.get("data"));
  }

  @track(() => ({
    action: "reset token settings",
  }))
  public resetForm() {
    const { orgId, resetToken } = this.props;
    resetToken(orgId);
  }

  public onHide() {
    const { orgId, resetToken, token } = this.props;
    resetToken(orgId);
    this.setState({ open: false, taskId: null });
    const userIdToken = token?.toJS()?.data?.notes_sync?.occ_user_id;
    this.setState({ selectedOccUserId: userIdToken });
  }

  public check() {
    const { tokenChanged } = this.props;
    return tokenChanged;
  }

  public onQueryList(query: string) {
    const { queryUsers, orgId } = this.props;
    if (query === undefined) {
      query = "";
    }
    const queryParams = queryParamsFromJSON({ query, parameters: { org_id: orgId.toString() } });
    queryUsers(queryParams, "OccUserNotesSync");
  }

  public handleUserChange = (userId: number) => {
    this.props.tracking.trackEvent({
      action: "Salesforce notes sync Occ User changed",
      userId,
      orgId: this.props.orgId,
    });
    this.setState({ selectedOccUserId: userId });
  };

  public render() {
    const { integration, token, lastSavedToken, users } = this.props;
    const { open, loading, taskId, selectedOccUserId } = this.state;
    const selectedUser = selectedOccUserId ? users().get(selectedOccUserId) : null;

    if (!open) {
      return null;
    }

    if (_.isNullOrUndefined(lastSavedToken)) {
      return <Spinner localProperty={token?.loading} />;
    }

    return (
      <Modal size="lg" show={open} onHide={this.onHide} backdrop="static">
        <Spinner localProperty={loading || token.loading} />
        <DirtyWatcher check={this.check} reset={this.resetForm} />
        <Modal.Header closeButton>
          <Modal.Title>{capitalize(integration)} Settings</Modal.Title>
          <a
            className="action-button pull-right"
            title="Help Article"
            href="https://help.oneclickcontractor.com/knowledge/salesforce-configuration">
            <i className="rsf-question-circle-link rsf-base-66 text-primary"></i>
          </a>
        </Modal.Header>

        <RsfFinalForm
          onSubmit={this.onSubmit}
          formUpdate={this.update}
          initialValues={{
            data: {
              filters: [],
              custom_user_enable: false,
              filters_enable: false,
              valid_types: [{ value: "occ", label: "OCC" }],
              ...lastSavedToken.toJS().data,
            },
          }}>
          {({ form, form: { mutators } }) => (
            <>
              <Modal.Body>
                <Row>
                  <Col>
                    <RsfFormGroup label="Job Object" name="data.job_object" type="select" as="select">
                      <option value="">Default (Opportunity)</option>
                      {["opportunity", "lead"].map((object, idx) => (
                        <option key={idx} value={object} title={capitalize(object)}>
                          {capitalize(object)}
                        </option>
                      ))}
                    </RsfFormGroup>
                    <Condition when="data.job_object" is="lead">
                      <Row>
                        <Col>
                          <RsfCheckboxGroup label="Enable Lead Create" name="data.lead_create" />
                        </Col>
                        <Col>
                          <RsfCheckboxGroup label="Enable Lead Update" name="data.lead_update" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <RsfCheckboxGroup label="Enable Lead Assignment Update" name="data.lead_assignment_update" />
                        </Col>
                        <Col>
                          <RsfCheckboxGroup label="Enable Lead Status Update" name="data.lead_status_update" />
                        </Col>
                      </Row>
                    </Condition>
                    <Condition when="data.job_object" is="opportunity">
                      <Row>
                        <Col>
                          <RsfCheckboxGroup label="Enable Opportunity Create" name="data.opportunity_create" />
                        </Col>
                        <Col>
                          <RsfCheckboxGroup label="Enable Opportunity Update" name="data.opportunity_update" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <RsfCheckboxGroup
                            label="Enable Opportunity Assignment Update"
                            name="data.opportunity_assignment_update"
                          />
                        </Col>
                        <Col>
                          <RsfCheckboxGroup
                            label="Enable Opportunity Status Update"
                            name="data.opportunity_status_update"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <RsfCheckboxGroup label="Enable Contact Create" name="data.contact_create" />
                        </Col>
                        <Col>
                          <RsfCheckboxGroup label="Enable Contact Update" name="data.contact_update" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <RsfCheckboxGroup label="Enable Account Create" name="data.account_create" />
                        </Col>
                        <Col>
                          <RsfCheckboxGroup label="Enable Account Update" name="data.account_update" />
                        </Col>
                      </Row>
                    </Condition>
                    <Form.Label className="p-copy" htmlFor="react-select-valid-types">
                      Filter Create/Update to Salesforce by Valid Types
                    </Form.Label>
                    <Field
                      inputId="react-select-valid-types"
                      name="data.valid_types"
                      component={RsfSelectAdapter}
                      options={validJobBuilderTypes}
                      validate={arrayRequired}
                    />

                    <hr />
                    <RsfCheckboxGroup label="Enable Reassignment" name="data.reassignment" />
                    <hr />
                    <FilterForm mutators={mutators} />
                    <hr />
                    <RsfCheckboxGroup label="Enable Custom User Object" name="data.custom_user_enable" />
                    <p>
                      Default Custom User Values: model: 'User', id: 'Id', email: 'Email', name: 'Name',
                      association_key: 'OwnerId'
                    </p>
                    <Condition when="data.custom_user_enable" is={true}>
                      <RsfFormGroup
                        label="Model"
                        name="data.custom_user.model"
                        placeholder="Model"
                        validate={required}
                      />
                      <RsfFormGroup label="ID" name="data.custom_user.id" placeholder="ID" validate={required} />
                      <RsfFormGroup
                        label="Email"
                        name="data.custom_user.email"
                        placeholder="Email"
                        validate={required}
                      />
                      <RsfFormGroup label="Name" name="data.custom_user.name" placeholder="Name" validate={required} />
                      <RsfFormGroup
                        label="Association Key"
                        name="data.custom_user.association_key"
                        placeholder="Association Key"
                        validate={required}
                      />
                    </Condition>
                    <RsfCheckboxGroup label="Enable Notes Sync" name="data.notes_sync_enabled" />
                    <Condition when="data.notes_sync_enabled" is={true}>
                      <QueryableDropdown
                        selectedId={selectedOccUserId}
                        modelName="OccUserNotesSync"
                        queryList={this.onQueryList}
                        onChange={this.handleUserChange}
                        label={`${config.get("APP_NAME_SHORT")} User for notes imported into ${config.get(
                          "APP_NAME_SHORT",
                        )} from Salesforce`}
                        rootPath={["users", "usersById"]}
                        selectedItem={selectedUser}
                        optionsLabelFn={(user) => `${getFullName(user)} - ${user?.email}`}></QueryableDropdown>
                    </Condition>
                    <Button
                      variant="cancel"
                      onClick={() => {
                        form.reset();
                        this.resetForm();
                      }}>
                      Reset
                    </Button>
                  </Col>
                </Row>
                <Task taskId={taskId} />
              </Modal.Body>
              <Modal.Footer>
                <ButtonFooter cancel={this.onHide} />
              </Modal.Footer>
            </>
          )}
        </RsfFinalForm>
      </Modal>
    );
  }
}

export default connector(SettingsModal);
