import { RootState } from "app2/src/reducers";
import { AsyncActions } from "app2/src/reducers/job.actions";
import { sampleJobs } from "app2/src/selectors/job.selectors";
import { currentOrg } from "app2/src/selectors/org.selectors";
import { user as userSelector } from "app2/src/selectors/userCommon.selectors";
import { List } from "immutable";
import * as React from "react";
import { Button, Form, ListGroup, ListGroupItem, Modal, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

export interface CreateSampleJobsProps {
  userId: number;
  close: () => void;
}

export const CreateSampleJobs: React.FC<CreateSampleJobsProps> = ({ userId, close }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => userSelector(state, { userId }));
  const org = useSelector(currentOrg);
  const samples = useSelector(sampleJobs);

  const [selectedSamples, setSelectedSamples] = React.useState(List());

  React.useEffect(() => {
    dispatch(AsyncActions.loadSampleJobs(org.get("id")));
  }, []);

  const handleSelect = (selectedSample: string) => {
    if (selectedSamples.includes(selectedSample)) {
      setSelectedSamples(selectedSamples.filter((sample) => sample !== selectedSample));
    } else {
      setSelectedSamples(selectedSamples.push(selectedSample));
    }
  };

  const submit = () => {
    dispatch(AsyncActions.createSampleJobs(org.get("id"), userId, selectedSamples));
    close();
  };

  return (
    <>
      <Modal.Header>
        <h3>Sample Jobs</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <span className="font-weight-bold">User:</span> {user?.get("first_name")} {user?.get("last_name")} (
          {user?.get("email")})
        </div>
        <div className="mb-3">
          <span className="font-weight-bold">Org:</span> {org?.get("name")}
        </div>
        <Card className="mt-4">
          <Card.Header>Available Samples</Card.Header>
          <Card.Body>
            {samples?.map((sample) => (
              <Form.Check
                key={sample}
                label={sample}
                id={sample}
                className="mb-2"
                checked={selectedSamples.includes(sample)}
                onChange={() => handleSelect(sample)}
              />
            ))}
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} variant="cancel">
          Close
        </Button>
        <Button onClick={submit}>Create Sample Jobs</Button>
      </Modal.Footer>
    </>
  );
};
