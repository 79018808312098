import * as React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";
import { event as eventSelector } from "app2/src/selectors/event.selectors";
import { getOrgEventResults } from "app2/src/selectors/eventResult.selectors";
import { getOrgEventResultReasons } from "app2/src/selectors/eventResultReason.selectors";
import { EventResultRecord } from "app2/src/records/EventResult";
import { EventResultReasonRecord } from "app2/src/records/EventResultReason";
import { List } from "immutable";
import { useRouteMatch } from "react-router";
import { AsyncActions } from "app2/src/reducers/event.actions";
import { RsfForm, Select } from "@tberrysoln/rsf-form";
import { Actions } from "app2/src/reducers/event.actions";
import { required } from "app2/src/helpers/FinalFormValidator";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";
import { useTracking } from "react-tracking";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import { I360SalesOpportunity } from "app2/src/components/JobTabs/AppointmentsTab/I360SalesOpportunity";

export const I360ResultAppointmentModal: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "I360ResultAppointmentModal" });
  const { goBack } = usePreviousPath();

  // Selectors
  const { params } = useRouteMatch<{ eventId: string }>();
  const eventId = parseInt(params.eventId);
  const orgId = useSelector(currentOrgId);
  const event = useSelector((state: RootState) => eventSelector(state, { eventId }));
  const eventsResultId = event?.get("events_result_id") || -1;
  const results: List<EventResultRecord> = useSelector((state: RootState) => getOrgEventResults(state, { orgId }));
  const reasons: List<EventResultReasonRecord> = useSelector((state: RootState) =>
    getOrgEventResultReasons(state, { orgId, eventsResultId, kind: "default" }),
  );

  // Methods
  const close = () => {
    goBack("/result");
  };

  const cancel = () => {
    trackEvent({ action: "cancel result", event: eventId });
    dispatch(Actions.resetEvent(eventId));
    close();
  };

  const save = () => {
    trackEvent({ action: "result appt", event: eventId });
    dispatch(AsyncActions.resultEvent(event));
    close();
  };

  return (
    <Modal show={true} backdrop="static" onHide={cancel} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Result Appointment</Modal.Title>
      </Modal.Header>
      <RsfForm
        rootPath={["events", "byId", eventId]}
        updateFormReducer={(e) => Actions.updateForm({ ...e, value: parseInt(e.value) || e.value })}
        onSubmit={save}>
        <Modal.Body>
          <h4>Sales Appointment Details</h4>
          <Row>
            <Col>
              <Select name="events_result_id" label="Result" validate={required}>
                <option value={null} key={null}></option>
                {results.map((result) => (
                  <option value={result.id} key={result.id}>
                    {result.description}
                  </option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select name="events_result_reason_id" label="Reason" disabled={reasons.size === 0}>
                <option value={null} key={null}></option>
                {reasons.map((reason) => (
                  <option value={reason.id} key={reason.id}>
                    {reason.description}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
          <h4 className="pt-3 border-top">Sales Opportunities</h4>
          <Row className="overflow-y-auto" style={{ maxHeight: "450px" }}>
            {event?.metadata?.sales_opportunities?.map((opportunity, idx) => (
              <I360SalesOpportunity key={idx} opportunity={opportunity} idx={idx} />
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonFooter cancel={cancel} />
        </Modal.Footer>
      </RsfForm>
    </Modal>
  );
};
