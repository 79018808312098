import * as React from "react";
import { FormControl } from "@tberrysoln/rsf-form";
import { required } from "../../../helpers/FinalFormValidator";

export const AuthorizationForm: React.FC = () => {
  return (
    <>
      <FormControl label="API Key" name="payload.api_key" validate={required} />
    </>
  );
};
