import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import { useSelector } from "react-redux";
import useFullScreenToggle from "app2/src/hooks/useFullScreenToggle/useFullScreenToggle";
import * as FontAwesome from "react-fontawesome";
import { urlSelector } from "./PricePresentationModal.selectors";
import { RootState } from "app2/src/reducers";

export const PricePresentationModal: React.FC = () => {
  // Hooks
  const { goBack } = usePreviousPath();
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle();

  // Selectors
  const url = useSelector((state: RootState) => urlSelector(state));

  const close = () => {
    if (isFullScreen) toggleFullScreen();
    goBack("/price-presentation");
  };

  return (
    <Modal show={true} onHide={close} size="xl" className={isFullScreen ? "fullscreen" : ""}>
      <Modal.Header closeButton>
        <Modal.Title>Price Presentation</Modal.Title>
        <div onClick={toggleFullScreen} className="ml-auto">
          {isFullScreen ? (
            <FontAwesome name="compress fa-gray link" title="Exit Full Screen" size="lg" />
          ) : (
            <FontAwesome name="expand fa-gray link" title="Full Screen" size="lg" />
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        <iframe style={{ height: "100%" }} src={url}></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
