import { Record, Map } from "immutable";
import { NoteRecord, fromJSON } from "app2/src/records/Note";
import * as noteActions from "./note.actions";
import { RootState, RootActions } from ".";
import { reducer as paginationReducer } from "app2/src/reducers/pagination.reducer";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { rootKey } from "app2/src/selectors/note.selectors";
import { receive, reset } from "app2/src/reducers/Reducer";
import { getFullName } from "app2/src/records/UserRecord";

export const NoteStateRecord = Record({
  byId: Map<number, NoteRecord>(),
  lastSavedById: Map<number, NoteRecord>(),
});

export const initialState = NoteStateRecord();

export type NoteState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(rootKey)) {
    state = state.set(rootKey, initialState);
  }

  switch (action.type) {
    case noteActions.RECEIVE_NOTE:
      const note = fromJSON({ ...action.payload.note, loading: false });
      return receive(state, rootKey, note);
    case noteActions.RECEIVE_NOTES:
      action.payload.notes.forEach((note) => {
        state = reducer(state, noteActions.Actions.receiveNote(note));
      });
      return state;
    case noteActions.REMOVE_NOTE:
      state = paginationReducer(state, paginationActions.Actions.removeId(action.payload.noteId, "note"));
      return state;
    case noteActions.RESET_NOTE:
      return reset(state, rootKey, action.payload.noteId);
    case noteActions.EDIT_NOTE:
      return state.setIn([rootKey, "byId", action.payload.noteId, "note"], action.payload.note);
    case noteActions.SET_UP_NEW_NOTE:
      const newNote = fromJSON({
        note: "",
        noteable_id: action.payload.jobId,
        noteable_type: "Job",
        user: getFullName(action.payload.user),
        user_id: action.payload.user.id,
      });
      return receive(state, rootKey, newNote);
    default:
      return state;
  }
};
