import * as orgActions from "./org.actions";
import { Map, Record, List, fromJS } from "immutable";
import { OrgRecord, fromJSON } from "app2/src/records/OrgRecord";
import {
  initialState as FinanceOptionInitialState,
  reducer as financeOptionReducer,
} from "./org/financeOption.reducer";
import { RootState, RootActions } from "app2/src/reducers";
import { mergeDeepOverwriteArrays } from "../helpers/ImmutableData";
import { Nullable } from "app2/src/records";
import { currentOrgId } from "app2/src/selectors/org.selectors";

export const OrgStateRecord = Record({
  orgsById: Map<string, typeof OrgRecord>(),
  lastSavedOrgsById: Map<string, typeof OrgRecord>(),
  // Org ID of the current view, Job page = job.org_id, Admin page = org in current view, otherwise null
  currentOrgId: null as Nullable<number>,
  financeOptions: FinanceOptionInitialState,
});

export const initialState = OrgStateRecord();

export type OrgState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "orgs";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }
  switch (action.type) {
    case orgActions.SET_CURRENT_ORG_ID:
      return state.setIn([model, "currentOrgId"], action.payload.id);

    case orgActions.SET_ORG:
      action.payload.loading = false;
      const immutableOrg = fromJSON(action.payload);
      // if this org already exists, we should merge the data in so as to
      // not overwrite any data
      if (state.getIn([model, "orgsById", action.payload.id])) {
        const currentOrg = state.getIn([model, "orgsById", action.payload.id]);
        let mergedOrg = mergeDeepOverwriteArrays(currentOrg, fromJS(action.payload));
        mergedOrg = fromJSON(mergedOrg.toJS());
        state = state.setIn([model, "lastSavedOrgsById", action.payload.id], mergedOrg);
        state = state.setIn([model, "orgsById", action.payload.id], mergedOrg);
      } else {
        state = state.setIn([model, "lastSavedOrgsById", action.payload.id], immutableOrg);
        state = state.setIn([model, "orgsById", action.payload.id], immutableOrg);
      }
      return state;

    case orgActions.RESET_ORG:
      const orgIdReset = currentOrgId(state);
      return state.setIn([model, "orgsById", orgIdReset], state.getIn([model, "lastSavedOrgsById", orgIdReset]));

    case orgActions.FETCH_ORG:
      if (state.getIn([model, "orgsById", action.payload])) {
        return state.setIn([model, "orgsById", action.payload, "loading"], true);
      }
      return state.setIn([model, "orgsById", action.payload], fromJSON({ id: action.payload, loading: true }));

    case orgActions.RECEIVE_ORG_ERRORS:
      if (state.getIn([model, "orgsById", action.payload.id])) {
        return state
          .setIn([model, "orgsById", action.payload.id, "errors"], List<string>(action.payload.errors))
          .setIn([model, "orgsById", action.payload.id, "loading"], false);
      }
      return state
        .setIn([model, "orgsById", action.payload.id], fromJSON({ id: action.payload.id, loading: false }))
        .setIn([model, "orgsById", action.payload.id, "errors"], List<string>(action.payload.errors));

    case orgActions.UPDATE_SETTINGS_CONFIG:
      let settingsConfig = state.getIn([model, "orgsById", action.payload.orgId, "settings", "config"]);
      settingsConfig = settingsConfig.setIn(action.payload.path, action.payload.value);

      return state.setIn([model, "orgsById", action.payload.orgId, "settings", "config"], settingsConfig);

    case orgActions.UPDATE_PREF_CONFIG:
      let prefConfig = state.getIn([model, "orgsById", action.payload.orgId, "preferences", "config"]);
      prefConfig = prefConfig.setIn(action.payload.path, action.payload.value);

      return state.setIn([model, "orgsById", action.payload.orgId, "preferences", "config"], prefConfig);

    case orgActions.UPDATE_DOCUMENT_ORDER:
      const orgId = currentOrgId(state);
      return state.setIn(
        [model, "orgsById", orgId, "preferences", "config", "document_order", action.payload.docType],
        action.payload.list,
      );

    case orgActions.ADD_TO_DOCUMENT_ORDER:
      const doc = action.payload.document;
      const orgIdAdd = currentOrgId(state);
      return state.updateIn(
        [model, "orgsById", orgIdAdd, "preferences", "config", "document_order", action.payload.docType],
        (list) => {
          return list.push({
            id: doc.id,
            doc_type: "org",
            file_name: doc.name,
            filter_states: [],
          });
        },
      );

    case orgActions.RECEIVE_ORGS:
      action.payload.orgs.forEach((o) => {
        state = reducer(state, orgActions.Actions.setOrg(o));
      });
      return state;

    case orgActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);

    case orgActions.ADD_SIGNATURE_RECIPIENTS_INFO:
      const stateRootPath = [
        model,
        "orgsById",
        action.payload.orgId,
        "settings",
        "config",
        "signature_settings",
        "signers",
      ];
      const recipients = state.getIn(stateRootPath);

      recipients.map((recipient, index) => {
        switch (recipient.toJSON().signer) {
          case "job":
            state = state
              .setIn(stateRootPath.concat([index, "name"]), action.payload.job.name)
              .setIn(stateRootPath.concat([index, "email"]), action.payload.job.email);
            break;
          case "salesrep":
            if (action.payload.job.assignments && action.payload.job.assignments.size > 0) {
              const assignee = action.payload.job.assignments.toJSON()[0];
              state = state
                .setIn(
                  stateRootPath.concat([index, "name"]),
                  assignee.user?.first_name + " " + assignee.user?.last_name,
                )
                .setIn(stateRootPath.concat([index, "email"]), assignee.user.email);
            }
            break;
        }
        state = state.setIn(stateRootPath.concat([index, "status"]), "created");
      });

      return state;

    case orgActions.UPDATE_SIGNATURE_RECIPIENT_INFO:
      return state.setIn(
        action.payload.event.rootPath.concat(action.payload.event.name.split(".")),
        action.payload.event.value,
      );
    default:
      return financeOptionReducer(state, action);
  }
};
