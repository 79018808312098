import { createSelector } from "reselect";
import { OpeningEstimationRecord, IOpeningEstimationData } from "../records/OpeningEstimation";
import { List } from "immutable";
import { RootState } from "../reducers";

export const openingEstimationsById = (state: RootState) => state.getIn(["openingEstimations", "byId"]);
export const lastSavedOpeningEstimationsById = (state: RootState) =>
  state.getIn(["openingEstimations", "lastSavedById"]);

export const openingEstimations = createSelector(
  [openingEstimationsById, (_: RootState, props: any) => props],
  (openingEstimationsById, props: any): List<OpeningEstimationRecord> => {
    return openingEstimationsProcess(openingEstimationsById, props);
  },
);

export const lastSavedOpeningEstimations = createSelector(
  [openingEstimationsById, (_: RootState, props: any) => props],
  (openingEstimationsById, props: any): List<OpeningEstimationRecord> => {
    return openingEstimationsProcess(openingEstimationsById, props);
  },
);

const openingEstimationsProcess = (openingEstimationsById, props) => {
  const { openingEstimationIds } = props;
  let { fullEstimate } = props;
  if (_.isUndefined(fullEstimate)) {
    fullEstimate = false;
  }
  return openingEstimationIds
    .map((openingEstimationId: number) => {
      return openingEstimationsById.get(openingEstimationId);
    })
    .filter((openingEstimation: OpeningEstimationRecord) => {
      return fullEstimate || !openingEstimation._destroy;
    })
    .toList();
};

export const toOpeningEstimationsJson = createSelector(
  [openingEstimations],
  (openingEstimations: List<OpeningEstimationRecord>): IOpeningEstimationData[] => {
    return openingEstimations
      .map((opening: OpeningEstimationRecord) => {
        const openingData = opening.toJSON() as any;

        if (!openingData._destroy) {
          delete openingData._destroy;
        }

        return openingData as IOpeningEstimationData;
      })
      .toArray();
  },
);
