import { Nullable } from "app2/src/records";
import { Record } from "immutable";

export const fromJSON = (json: Partial<ISkuData>): SkuRecord => {
  const recordData: ISkuRecord = { ...(json as any) };

  return new SkuRecord(recordData);
};

export interface ISkuData {
  id: number;
  item_uuid: string;
  value: string;
  org_id: number;
  loading?: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface ISkuRecord {
  id: number;
  item_uuid: string;
  value: string;
  org_id: number;
  loading: boolean;

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}

const defaultSkuProps: ISkuRecord = {
  id: 0,
  item_uuid: "",
  value: "",
  org_id: 0,
  loading: false,

  created_at: null,
  updated_at: null,
};

export class SkuRecord extends Record(defaultSkuProps) implements ISkuRecord {}
