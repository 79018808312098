import { Channel, Socket } from "phoenix";
import * as config from "react-global-configuration";
import { useState, dispatch, StoreRegistry } from "app2/src/storeRegistry";
import { createAction } from "app2/src/reducers/Utils";
import { ITaskData } from "app2/src/records/Task";
import { syncTask } from "app2/src/helpers/TaskSync";

export type RemoteStorageCallback = (data: IRemoteStorageStateChange) => void;
export type RemoteStorageErrorCallback = (response?: any) => any;
export type RemoteStorageTimeoutCallback = (response?: any) => any;

export interface IRemoteStorageServiceOptions {
  callbacks: RemoteStorageCallback[];
  onError?: RemoteStorageErrorCallback;
  onTimeout?: RemoteStorageTimeoutCallback;
}

export interface IRemoteStorageStateChange {
  change: { [key: string]: any };
  kind: "clear" | "full" | "push" | "remove";
  node: string;
  sessionId: string;
  timestamp: Date;
}

export interface IRemoteStorageService {
  initialized: boolean;
  connect: (sessionId: string) => Promise<boolean>;
  clear: () => Promise<boolean>;
  disconnect: () => void;
  endSession: () => Promise<boolean>;
  getItem: (key: string) => Promise<boolean>;
  removeItem: (key: string) => Promise<boolean>;
  setItem: (key: string, value: any) => Promise<boolean>;
}

/**
 * A class to facilitate syncing values between browser instances
 */
export class RemoteStorageService implements IRemoteStorageService {
  public initialized: boolean;

  private _callbacks: RemoteStorageCallback[];
  private _channel: Channel;
  private _taskChannel: Channel;
  private _errorCallback: RemoteStorageErrorCallback;
  private _socket: Socket;
  private _socketUrl: string;
  private _timeout = 2_000;
  private _timeoutCallback: RemoteStorageTimeoutCallback;

  constructor(options: IRemoteStorageServiceOptions) {
    this._socketUrl = config.get("RSF_ANALYTICS_WS") + "/socket";
    this._callbacks = options.callbacks;

    this._errorCallback = options.onError || this.defaultErrorHandler;
    this._timeoutCallback = options.onTimeout || this.defaultTimeoutHandler;
  }

  /**
   * Connect to the server and subscribe to new data being pushed down from the remote storage.
   */
  public async connect(sessionId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // If the channel has already been initialized, unsubscribe from the existing listeners
      if (this._channel) {
        this._channel.off("session:disconnect");
        this._channel.off("state:changed");
      }

      if (this._socket && this._socket.isConnected()) {
        this._socket.disconnect();
      }

      this._socket = new Socket(this._socketUrl);
      this._socket.onError(this._errorCallback);
      this._socket.connect();

      const authToken = useState().getIn(["auth", "token"]);
      const params = { bearer: authToken };
      const topic = `remote_storage:${sessionId}`;
      this._channel = this._socket.channel(topic, params);

      this._channel.on("session:disconnect", () => this.disconnect());
      this._channel.on("state:changed", this.processStateChange.bind(this));
      this._channel
        .join()
        .receive("ok", () => resolve(true))
        .receive("error", (reasons) => reject(reasons))
        .receive("timeout", () => reject("A timeout occurred"));

      const topic2 = `task_sync:${sessionId}`;
      this._taskChannel = this._socket.channel(topic2, params);
      this._taskChannel.on("task_update_sync", async (data) => {
        const task: ITaskData = data.data;
        if (task) await syncTask(task);
      });

      this._taskChannel
        .join()
        .receive("ok", () => resolve(true))
        .receive("error", (reasons) => reject(reasons))
        .receive("timeout", () => reject("A timeout occurred"));
      this.initialized = true;
    });
  }

  /** Deletes all state for the session on the remote server */
  public async clear(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this._channel) {
        resolve(false);
        return;
      }

      this._channel
        .push("storage:clear_state", {}, this._timeout)
        .receive("ok", () => resolve(true))
        .receive("error", () => resolve(false))
        .receive("timeout", () => resolve(false))
        // Client code callbacks
        .receive("error", this._errorCallback)
        .receive("timeout", this._timeoutCallback);
    });
  }

  /**
   * Asks the server to send out debug information about the custer status to all clients
   * connected to the session. The callback function will be called on "ok", "error" and "timeout".
   * The only place to view the response is in the Developer Tools Network tab (in the web socket
   * output).
   */
  public async debugCluster(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this._channel) {
        resolve(false);
        return;
      }

      this._channel
        .push("debug:cluster", {}, this._timeout)
        .receive("ok", () => resolve(true))
        .receive("error", () => resolve(false))
        .receive("timeout", () => resolve(false));
    });
  }

  /**
   * Disconnects from the server but does not delete the remote storage for this
   * session. Consider calling `endSession()` instead to clean up the server.
   */
  public disconnect(): void {
    this.initialized = false;

    if (this._channel) {
      this._channel.leave(this._timeout);
    }

    if (this._socket) {
      this._socket.disconnect();
    }
  }

  /**
   * Sends a message to the server to delete this session data and disconnect
   * any other remote storage sessions
   * */
  public async endSession(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this._channel) {
        resolve(false);
        return;
      }

      this._channel
        .push("session:disconnect", {}, this._timeout)
        .receive("ok", () => resolve(true))
        .receive("error", () => resolve(false))
        .receive("timeout", () => resolve(false));
    });
  }

  /**
   * Request an item to be pushed out to all clients for the session.
   * **Note:** This does not return the value from this method
   */
  public async getItem(key: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this._channel) {
        resolve(false);
        return;
      }

      this._channel
        .push("storage:get_item", { data: key }, this._timeout)
        .receive("ok", () => resolve(true))
        .receive("error", () => resolve(false))
        .receive("timeout", () => resolve(false))
        // Client code callbacks
        .receive("error", this._errorCallback)
        .receive("timeout", this._timeoutCallback);
    });
  }

  /** Removes an item from the remote storage */
  public async removeItem(key: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this._channel) {
        resolve(false);
        return;
      }

      this._channel
        .push("storage:remove_item", { data: key }, this._timeout)
        .receive("ok", () => resolve(true))
        .receive("error", () => resolve(false))
        .receive("timeout", () => resolve(false))
        // Client code callbacks
        .receive("error", this._errorCallback)
        .receive("timeout", this._timeoutCallback);
    });
  }

  /**
   * Sets the key/value pair on the server. If the key already exists, it will be
   * overwritten. Changes are automatically pushed out to session clients.
   */
  public async setItem(key: string, value: any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this._channel) {
        resolve(false);
        return;
      }

      this._channel
        .push("storage:set_item", { data: { [key]: value } }, this._timeout)
        .receive("ok", () => resolve(true))
        .receive("error", () => resolve(false))
        .receive("timeout", () => resolve(false))
        // Client code callbacks
        .receive("error", this._errorCallback)
        .receive("timeout", this._timeoutCallback);
    });
  }

  /** Generic error handler that throws an exception */
  private defaultErrorHandler(err: any): never {
    throw {
      message: "Error occurred: Consider including an error callback.",
      error: err,
    };
  }

  /** Generic timeout handler that throws an exception */
  private defaultTimeoutHandler(err: any): never {
    throw {
      message: "Timeout occurred: Consider including a timeout callback.",
      error: err,
    };
  }

  /** Iterates over the callbacks that were registers during object creation */
  private processStateChange(res: any): void {
    // Massage the values into appropriate shapes
    res.data.timestamp = new Date(res.data.timestamp);
    res.data.sessionId = res.data.session_id;
    delete res.data.session_id;

    for (const cb of this._callbacks) {
      // Send the payload to the subscriber
      cb(res.data);
    }
  }
}

const remoteStorageCallback = (data) => {
  switch (data.kind) {
    case "full":
    case "push":
    case "clear":
      if (data.change) {
        _.each(Object.keys(data.change), (key) => {
          const action = () => createAction(key, data.change[key]);
          //@ts-ignore
          dispatch(action());
        });
      }
  }
};

export const remoteStorage = new RemoteStorageService({
  callbacks: [remoteStorageCallback],
  onError: () => {},
  onTimeout: () => {},
});

let remoteStorageInstance = StoreRegistry.get<IRemoteStorageService>("remoteStorage");
export const getRemoteStorage = () => {
  if (remoteStorageInstance) {
    return remoteStorageInstance;
  }

  remoteStorageInstance = StoreRegistry.get<IRemoteStorageService>("remoteStorage");
  return remoteStorageInstance;
};
