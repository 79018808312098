import { List } from "immutable";

/**
 * Generates an array of evenly distributed colors
 * @param numberOfColors
 * @returns Array of colors in `hsl(h, s, l)` format
 */
export const generateColors = (numberOfColors: number): string[] => {
  const colors = [];
  let prevColor = 1;
  for (let i = 1; i <= numberOfColors; i++) {
    colors.push(`hsl(${prevColor}, 55%, 40%)`);
    prevColor = prevColor + 360 / numberOfColors;
  }
  return colors;
};

/**
 * Gets the hue value in a color in `hsl(h, s, l)` format
 */
export const parseHueValue = (color: string): number => {
  return parseInt(color.slice(4).split(",")[0]) || 0;
};

// List of 20 colors that are recognizably unique and keep white text legible
export const baseColors = List([
  "hsl(1, 70%, 40%)",
  "hsl(241, 70%, 40%)",
  "hsl(281, 70%, 40%)",
  "hsl(41, 70%, 40%)",
  "hsl(121, 70%, 40%)",
  "hsl(321, 70%, 40%)",
  "hsl(171, 70%, 40%)",
  "hsl(60, 80%, 40%)",
  "hsl(201, 70%, 40%)",
  "hsl(24, 70%, 40%)",
  "hsl(71, 70%, 40%)",
  "hsl(260, 70%, 40%)",
  "hsl(5, 50%, 35%)",
  "hsl(245, 50%, 35%)",
  "hsl(285, 50%, 35%)",
  "hsl(45, 50%, 35%)",
  "hsl(125, 50%, 35%)",
  "hsl(325, 50%, 35%)",
  "hsl(175, 50%, 35%)",
  "hsl(65, 50%, 35%)",
]);
