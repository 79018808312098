import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { DoorRecord } from "app2/src/records/Door";
import { recordChanged } from "app2/src/helpers/Record";
import { currentLastSavedMeasurement, currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";

export const rootKey = "doors";

export const door = (state: RootState, props: any) => state.getIn([rootKey, "byId", props.doorId.toString()]);
export const lastSavedDoor = (state: RootState, props: any) =>
  state.getIn([rootKey, "lastSavedById", props.doorId.toString()]);

export const doors = createSelector([(state, props) => ({ state, props })], (stateProps: any): List<DoorRecord> => {
  const { state, props } = stateProps;
  const { doorIds } = props;
  return doorIds.map((id: number) => door(state, { doorId: id }));
});

export const lastSavedDoors = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<DoorRecord> => {
    const { state, props } = stateProps;
    const { doorIds } = props;
    return doorIds.map((id: number) => lastSavedDoor(state, { doorId: id }));
  },
);

/**
 * Looks to the currentJob in redux to get the measurement_id then loads that measurement from the store
 *
 * @params {RootState} state RootState
 * @params {{}} options NONE
 * @returns {MeasurementRecord} measurement
 */
export const doorsChanged = (state: RootState, props: any): boolean => {
  const measurement = currentMeasurement(state, props);
  const lastSavedMeasurement = currentLastSavedMeasurement(state, props);
  return recordChanged(
    doors(state, { doorIds: measurement?.get("door_ids") || List() }),
    lastSavedDoors(state, { doorIds: lastSavedMeasurement?.get("door_ids") || List() }),
  );
};
