import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { IEstimate } from "app/src/Models/Estimate";
import { IEventingFactory } from "app/src/Common/EventingFactory";
import { SortableOptions } from "app/src/Common/SortableOptions";
import { ReorderLineItems } from "app/src/Commands/Estimator/ReorderLineItems";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { RemoveEstimateLineItemCmd } from "app/src/Commands/Estimator/RemoveEstimateLineItemCmd";
import { EditLineItemGroupCmd } from "app/src/Commands/Estimator/EditLineItemGroupCmd";
import { IOrg } from "app/src/Models/Org";
import { EstimatorTourService } from "app/src/Common/Tours/EstimatorTourService";
import { EstimatorService } from "./EstimatorService";

class EstimateGroupComponentCtrl implements ng.IComponentController {
  public detailsStyle: string;
  public pricingStyle: string;
  public quantityStyle: string;
  public descriptionStyle: string;
  public editing = false;
  public estimateGroup: IEstimateGroup;
  public estimate: IEstimate;
  public onRemove: (args: any) => void;
  public editable: boolean;
  public org: IOrg;
  public navDisplay: boolean;
  public eventingFactory: IEventingFactory;
  public sortableOptions = new SortableOptions();
  public collapsedView: boolean;
  public columnWidths: any[];
  public tourEditNameAction: boolean;
  public prevTourEditNameAction: boolean;
  public $index: any;
  public hiddenLineItems: boolean;
  public focusHere = 0;
  public showGroupTitles = false;

  private _reorderCommand: ReorderLineItems;

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $scope: ng.IScope,
    public EstimatorTourService: EstimatorTourService,
    public EstimatorService: EstimatorService,
  ) {
    this.sortableOptions.receive = (event: JQueryEventObject, ui: ng.ui.UISortableUIParams<any>) => {
      const cmd = new EditLineItemGroupCmd(ui.item.sortable.model, this.estimateGroup);
      cmd.execute();
    };
    this.sortableOptions.stop = (event: JQueryEventObject, ui: ng.ui.UISortableUIParams<any>) => {
      if (!this._reorderCommand) {
        // if a stop happens and nothing was changed this is called still
        return;
      }
      const group: IEstimateGroup = _.find(this.estimate.existingGroups(), (g) => {
        return g.id === ui.item.sortable.model.estimateGroup.id;
      });

      this._reorderCommand.execute(group.line_items);
      this._reorderCommand = null;
      group.line_items = _.sortBy(group.line_items, "sort_order");

      this.eventingFactory.trackEvent("line items reordered", {});
    };
    this.sortableOptions.connectWith = ".group-connect";
    this.sortableOptions.update = (event: JQueryEventObject, ui: ng.ui.UISortableUIParams<any>) => {
      const group: IEstimateGroup = _.find(this.estimate.existingGroups(), (g) => {
        return g.id === ui.item.sortable.model.estimateGroup.id;
      });
      this._reorderCommand = new ReorderLineItems(group.line_items);
    };
    this.sortableOptions.helper = (event: JQueryEventObject, ui: ng.IAugmentedJQuery) => {
      return ui.clone();
    };
    this.sortableOptions.items = "estimate-line-item";

    $scope.$on("estimateGroup.edit", (event: ng.IAngularEvent, args: any) => {
      if (args.estimateGroup.id === this.estimateGroup.id) {
        this.editing = true;
      }
    });

    this.prevTourEditNameAction = this.tourEditNameAction;
    $scope.$watch("$ctrl.tourEditNameAction", () => {
      if (this.tourEditNameAction === true && this.prevTourEditNameAction === false) {
        this.startGroupEdit();
      } else if (this.tourEditNameAction === false && this.prevTourEditNameAction === true) {
        this.saveGroupEdit();
      }
      this.prevTourEditNameAction = this.tourEditNameAction;
    });
  }

  public $onChanges() {
    if (this.org) {
      let descriptionColumns = 3;
      let detailsColumns = 4;
      this.hiddenLineItems = this.estimate.hidden_line_items();

      if (!this.org.showDetailedMeasurements(!this.navDisplay)) {
        descriptionColumns += 2;
      }

      if (!this.org.showDetailedUnitPrice(!this.navDisplay, this.getGroupType(), this.hiddenLineItems)) {
        descriptionColumns += 1;
      }

      if (!this.org.showDetailedPricing(!this.navDisplay, this.getGroupType(), this.hiddenLineItems)) {
        descriptionColumns += 1;
      }

      descriptionColumns = Math.min(5, descriptionColumns);
      detailsColumns = detailsColumns - (descriptionColumns - 3);

      if (this.collapsedView) {
        // 2 for the existing product column + 2 to replace the img
        descriptionColumns += 4;
      }

      this.columnWidths = [descriptionColumns, detailsColumns];
      this.descriptionStyle = "col-" + this.columnWidths[0];
      this.detailsStyle = this.columnWidths[1] > 0 ? "col-" + this.columnWidths[1] : "d-none";

      if (
        this.org.showDetailedMeasurements(!this.navDisplay) &&
        (this.org.showDetailedUnitPrice(!this.navDisplay, this.getGroupType(), this.hiddenLineItems) ||
          this.org.showDetailedPricing(!this.navDisplay, this.getGroupType(), this.hiddenLineItems))
      ) {
        this.quantityStyle = "col-6";
      } else if (
        this.org.showDetailedMeasurements(!this.navDisplay) &&
        !(
          this.org.showDetailedUnitPrice(!this.navDisplay, this.getGroupType(), this.hiddenLineItems) ||
          this.org.showDetailedPricing(!this.navDisplay, this.getGroupType(), this.hiddenLineItems)
        )
      ) {
        this.quantityStyle = "col-12";
      } else if (!this.org.showDetailedMeasurements(!this.navDisplay)) {
        this.quantityStyle = "d-none";
      }

      if (
        (this.org.showDetailedUnitPrice(!this.navDisplay, this.getGroupType(), this.hiddenLineItems) ||
          this.org.showDetailedPricing(!this.navDisplay, this.getGroupType(), this.hiddenLineItems)) &&
        this.org.showDetailedMeasurements(!this.navDisplay)
      ) {
        this.pricingStyle = "col-6";
      } else if (
        (this.org.showDetailedUnitPrice(!this.navDisplay, this.getGroupType(), this.hiddenLineItems) ||
          this.org.showDetailedPricing(!this.navDisplay, this.getGroupType(), this.hiddenLineItems)) &&
        !this.org.showDetailedMeasurements(!this.navDisplay)
      ) {
        this.pricingStyle = "col-12";
      } else if (
        !this.org.showDetailedUnitPrice(!this.navDisplay, this.getGroupType(), this.hiddenLineItems) &&
        !this.org.showDetailedPricing(!this.navDisplay, this.getGroupType(), this.hiddenLineItems)
      ) {
        this.pricingStyle = "d-none";
      }

      this.columnWidths.push(this.quantityStyle);
      this.columnWidths.push(this.pricingStyle);
    }
  }

  public remove() {
    this.eventingFactory.trackEvent("estimate group deleted", {
      estimate_group_name: this.estimateGroup.name,
      estimate_group_id: this.estimateGroup.id,
    });
    if (this.estimateGroup.id > 0) {
      this.estimateGroup._destroy = true;
    } else {
      this.onRemove({ group: this.estimateGroup });
    }
  }

  public getGroupType() {
    if (!this.estimateGroup) {
      return;
    }

    return this.estimateGroup.included ? "main" : "option";
  }

  public groupTotal(): number {
    if (this.estimateGroup) {
      return this.estimateGroup.updateTotal();
    }

    return 0;
  }

  public removeLineItem(lineItem: IEstimateLineItem) {
    const cmd = new RemoveEstimateLineItemCmd(this.estimate, lineItem);
    cmd.execute();

    this.eventingFactory.trackEvent("remove line item", { category: "EstimateLineItemComponent" });
  }

  public collapseChanged() {
    this.eventingFactory.trackEvent("group collapse view toggled", { collapsedView: this.collapsedView });
    this.$scope.$emit("estimateLineItem.collapse", { collapsedView: this.collapsedView });
  }

  public startGroupEdit() {
    this.editing = !this.editing;
    if (this.tourEditNameAction === false) {
      this.prevTourEditNameAction = true;
      this.tourEditNameAction = true;
      this.EstimatorTourService.next();
    }
    this.eventingFactory.trackEvent("editing group", {
      group_id: this.estimateGroup.id,
      group_name: this.estimateGroup.name,
      group_included: this.estimateGroup.included,
    });
    this.focusHere += 1;
  }

  public saveGroupEdit() {
    this.editing = !this.editing;
    this.estimate.orderGroups();
    this.eventingFactory.trackEvent("saving group", {
      group_id: this.estimateGroup.id,
      group_name: this.estimateGroup.name,
      group_included: this.estimateGroup.included,
    });
  }

  public hideTitles() {
    const lineItemEditing = this.EstimatorService.lineItemEditorOpen();
    return (
      lineItemEditing &&
      lineItemEditing.editing &&
      lineItemEditing.sort_order === 0 &&
      this.estimateGroup.id === lineItemEditing.estimateGroup.id
    );
  }
}

export class EstimateGroupComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Estimator/estimate_group_component.html";
  public bindings: any = {
    estimateGroup: "<",
    estimate: "<",
    org: "<",
    job: "<",
    editable: "<",
    eventingFactory: "<",
    navDisplay: "<",
    collapsedView: "<",
    onRemove: "&",
    addProduct: "&",
    tourEditNameAction: "<",
    $index: "<",
  };

  constructor() {
    this.controller = EstimateGroupComponentCtrl;
    this.controller.$inject = ["$uibModal", "$scope", "EstimatorTourService", "EstimatorService"];
  }
}
