import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { Can } from "app2/src/components/Common/CanComponent";
import { token, tokenChanged } from "app2/src/selectors/token.selectors";
import * as tokenActions from "app2/src/reducers/token.actions";
import { TokenRecord } from "app2/src/records/Token";
import { RsfForm, FormControl } from "@tberrysoln/rsf-form";
import { required } from "app2/src/helpers/FinalFormValidator";

const mapStateToProps = (state: RootState, ownProps: RenoworksProps) => {
  const integrationName = "renoworks";
  return {
    renoworksToken: token(state, { kind: integrationName }) as TokenRecord,
    dirty: tokenChanged(state, { kind: integrationName }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: RenoworksProps) => {
  return {
    getRenoworksToken: () => dispatch(tokenActions.AsyncActions.getToken(ownProps.orgId, "renoworks")),
    updateCreateRenoworksToken: (newToken: TokenRecord) =>
      dispatch(tokenActions.AsyncActions.updateCreateToken(newToken)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface RenoworksProps {
  orgId: number;
  updateSettingsConfig: (path: any, value: any) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RenoworksProps;

class Renoworks extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getRenoworksToken();
  }

  public render() {
    const integrationName = "renoworks";
    const { orgId, dirty, renoworksToken, updateCreateRenoworksToken } = this.props;
    const integrationPath = ["tokens", "byOrgId", orgId, integrationName, "data"];

    return (
      <Can resource="global" permission="tools">
        <div className="form-section">
          <div className="form-section-content">
            <RsfForm
              rootPath={integrationPath}
              updateFormReducer={tokenActions.Actions.update}
              onSubmit={() => updateCreateRenoworksToken(renoworksToken)}>
              <Row>
                <Col sm={4} className="d-flex">
                  <img
                    className="img-fluid align-self-center integration-logo"
                    src="/assets/images/renoworks.27b4d748.png"
                    title={integrationName}
                  />
                </Col>
                <Col sm={8}>
                  <Row className="d-flex align-items-center">
                    <Col sm={6}>
                      <FormControl label="URL" name="renoworks_url" validate={required} />
                    </Col>
                    <Col sm={6}>
                      <Button type="submit" variant="default" disabled={!dirty}>
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </RsfForm>
          </div>
        </div>
      </Can>
    );
  }
}

export default connector(Renoworks);
