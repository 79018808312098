export class StripeInput {
  public require = "ngModel";

  constructor(public $filter: ng.IFilterService) {}

  public link = (elem, $scope, attrs, ngModel) => {
    ngModel.$formatters.push((val) => {
      return (this.$filter("stripe") as any)(val, false);
    });
    ngModel.$parsers.push((val) => {
      if (_.isNaN(parseFloat(val))) {
        return 0;
      } else {
        return Math.round(parseFloat(val) * 100);
      }
    });
  };

  public static factory(): ng.IDirectiveFactory {
    const directive = ($filter: ng.IFilterService) => new StripeInput($filter);
    directive.$inject = ["$filter"];

    return directive;
  }
}
