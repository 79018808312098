import { PlanRecord } from "app2/src/records/billing/Plan";
import * as planActions from "app2/src/reducers/billing/plan.actions";
import { plansById } from "app2/src/selectors/plan.selectors";
import { dispatch, useSelector } from "app2/src/storeRegistry";
import { subscriber } from "app2/src/helpers/Subscribe";

export class PlanListCtrl {
  public plans: PlanRecord[];
  public showArchive = false;

  private _unsubscribePlans: () => void;

  public static $inject = ["$state", "$scope"];
  constructor(
    public $state: ng.ui.IStateService,
    public $scope: ng.IScope,
  ) {
    dispatch(planActions.Actions.clearPlans());
    this.showArchive = false;
    this.loadPlans();

    this._unsubscribePlans = subscriber.subscribe<string>("billing.plans.byId", () => {
      this.plans = useSelector(plansById).toList().toArray();
      this.plans.sort((plan1, plan2) => (plan1.name > plan2.name ? 1 : -1));
      if (!($scope.$$phase === "$apply" || $scope.$$phase === "$digest")) {
        $scope.$digest();
      }
    });

    $scope.$on("$destroy", () => {
      this._unsubscribePlans();
    });
  }

  public async loadPlans() {
    await dispatch(planActions.AsyncActions.listPlans({ archived: this.showArchive }));
  }

  public fetchParentName(plan) {
    if (!plan.parent) {
      plan = plan.set(
        "parent",
        this.plans.find((p: PlanRecord) => p.id === plan.parent_id),
      );
    }

    if (plan.parent) {
      return plan.parent.name;
    }
    return null;
  }

  public async toggleArchive(plan) {
    if (plan.archived_at) {
      dispatch(planActions.AsyncActions.unarchivePlan(plan));
    } else {
      dispatch(planActions.AsyncActions.archivePlan(plan, !this.showArchive));
    }
  }

  public toggleShowArchived() {
    dispatch(planActions.Actions.clearPlans());
    if (this.showArchive) {
      this.showArchive = false;
    } else {
      this.showArchive = true;
    }
    this.loadPlans();
  }
}
