import { ActionsUnion, createAction } from "../Utils";
import { FeeRecord, IFeeData } from "app2/src/records/billing/Fee";
import { handleErrors } from "app2/src/reducers/Utils";
import { ThunkResult } from "../index";
import { RootDispatchType } from "../../store";
import * as feeService from "app2/src/api/billing/fee.service";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as commonActions from "app2/src/reducers/components/common.actions";

export const FETCH_FEES = "@fees/FETCH_FEES";
export const FETCH_FEE = "@fees/FETCH_FEE";
export const RECEIVE_FEES = "@fees/RECEIVE_FEES";
export const RECEIVE_FEE = "@fees/RECEIVE_FEE";
export const REMOVE_FEE = "@fees/REMOVE_FEE";
export const RECEIVE_ERRORS = "@fees/RECEIVE_ERRORS";
export const RECEIVE_FEE_ERROR = "@fees/RECEIVE_FEE_ERROR";
export const CLEAR_FEES = "@fees/CLEAR_FEES";

export const Actions = {
  fetchFees: () => createAction(FETCH_FEES),
  fetchFee: (id: number) => createAction(FETCH_FEE, { id }),
  receiveFee: (fee: IFeeData) => createAction(RECEIVE_FEE, { fee }),
  removeFee: (id: number) => createAction(REMOVE_FEE, { id }),
  receiveFeeError: (id: number, errors: string[]) => createAction(RECEIVE_FEE_ERROR, { id, errors }),
  receiveErrors: (errors: string[]) => createAction(RECEIVE_ERRORS, { errors }),
  receiveFees: (fees: IFeeData[]) => createAction(RECEIVE_FEES, { fees }),
  clearFees: () => createAction(CLEAR_FEES),
};

export const AsyncActions = {
  loadFee: (feeId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchFee(feeId));

      try {
        const fee = await feeService.load(feeId);
        dispatch(Actions.receiveFee(fee.fee));
      } catch (e) {
        dispatch(Actions.receiveErrors(handleErrors(e)));
      }
    };
  },
  listFees: (options: Partial<feeService.IFeeQueryOptions>): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchFees());

      try {
        const fees = await feeService.query(options);
        dispatch(Actions.receiveFees(fees.fees));
      } catch (e) {
        dispatch(Actions.receiveErrors(handleErrors(e)));
      }
    };
  },
  archiveFee: (fee: FeeRecord, remove = false): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const feeResponse = await feeService.archive(fee);
        if (remove) {
          dispatch(Actions.removeFee(feeResponse.fee.id));
        } else {
          dispatch(Actions.receiveFee(feeResponse.fee));
        }
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Fee Archived"));
      } catch (e) {
        dispatch(Actions.receiveErrors(handleErrors(e)));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, "Problems archiving Fee"));
      }
    };
  },
  unarchiveFee: (fee: FeeRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const feeResponse = await feeService.unarchive(fee);
        dispatch(Actions.receiveFee(feeResponse.fee));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Fee Unarchived"));
      } catch (e) {
        dispatch(Actions.receiveErrors(handleErrors(e)));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, "Problems unarchiving Fee"));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
