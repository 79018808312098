import { List, Set } from "immutable";
import { PresentationRecord, fromJSON as presentationFromJSON } from "app2/src/records/Presentation";
import {
  PresentationTemplateRecord,
  fromJSON as presentationTemplateFromJSON,
} from "app2/src/records/PresentationTemplate";
import { fromJSON as dplFromJSON } from "app2/src/records/DynamicPresentationLink";
import { SlideRecord } from "app2/src/records/Slide";
import { DynamicPresentationLinkRecord } from "app2/src/records/DynamicPresentationLink";
import { intersectLists, removeDuplicates } from "app2/src/helpers/ImmutableData";

export const activeDynamicLinks = (presentation: PresentationRecord): List<DynamicPresentationLinkRecord> => {
  if (presentation === null || presentation === undefined) {
    return List<DynamicPresentationLinkRecord>();
  }
  return presentation.dynamic_links.filter((dl) => !dl._destroy);
};

export const buildInitialJobPresentation = (jobId: number, inspectionId: number) => {
  let links = List<DynamicPresentationLinkRecord>();
  links = links.push(
    dplFromJSON({
      presentable_id: inspectionId,
      presentable_type: "Estimate",
      kind: "inspection",
      sort_order: 0,
    }),
  );

  return presentationFromJSON({
    name: "Job Presentation",
    job_id: jobId,
    kind: "dynamic",
    dynamic_links: links.map((dl) => dl.toJS()).toArray() as any,
    slides: [],
  });
};

export const checkInspectionDynamicPresentationLink = (
  presentation: PresentationRecord,
  inspectionId: number,
): PresentationRecord => {
  const dplIndex = presentation.dynamic_links.findIndex((x) => x.presentable_type === "Estimate");
  const dpl = presentation.dynamic_links.get(dplIndex);

  if (dpl && (!dpl.presentable_id || dpl.presentable_id <= 0)) {
    presentation = presentation.update("dynamic_links", (dpls) =>
      dpls.setIn([dplIndex, "presentable_id"], inspectionId),
    );
  }

  return presentation;
};

export const slideSelected = (dynamicPresentation: PresentationRecord, slideId: number): boolean => {
  if (!dynamicPresentation) {
    return false;
  }

  return activeDynamicLinks(dynamicPresentation).some((dl) => dl.presentable_id === slideId);
};

export const getSortOrder = (
  presentation: PresentationRecord,
  orgPresentations: List<PresentationRecord>,
): "default" | "free-for-all" => {
  if (presentation === undefined || presentation === null) {
    return "default";
  }

  const newPresentationIds = [];
  const newSlideIds = [];

  // collecting ids for presentation & slides that are included in the new presentation
  presentation.slides.forEach(({ id, presentation_id }) => {
    newSlideIds.push(id);
    newPresentationIds.push(presentation_id);
  });

  // slides from orgPresentation that are part of new presentation being created in the default sorting order
  const defaultSortedSlides = orgPresentations
    .filter(({ id }) => newPresentationIds.includes(id))
    .flatMap(({ slides }) => slides)
    .filter(({ id }) => newSlideIds.includes(id));

  // if the new presentation slides are in the same order as of the org presentation slides listing then it's the default order
  if (defaultSortedSlides.every(({ id }, index) => newSlideIds[index] === id)) {
    return "default";
  } else {
    return "free-for-all";
  }
};

export const removeSlides = (
  dynamicPresentation: PresentationRecord,
  presentation: PresentationRecord,
): PresentationRecord => {
  presentation.slides.map((slide: SlideRecord) => {
    if (slideSelected(dynamicPresentation, slide.id)) {
      dynamicPresentation = removeSlide(dynamicPresentation, slide.id);
    }
  });
  return dynamicPresentation;
};

export const removeSlide = (dynamicPresentation: PresentationRecord, slideId: number): PresentationRecord => {
  return dynamicPresentation
    .update("dynamic_links", (dls) => {
      const index = dls.findIndex((dl) => dl.presentable_id === slideId);
      const dl = dls.get(index);
      if (dl.id <= 0) {
        return dls.filter((dl) => dl.presentable_id !== slideId).map((dl, idx) => dl.set("sort_order", idx));
      } else {
        return dls.update(index, (dl) => dl.set("_destroy", true));
      }
    })
    .update("slides", (slides) => {
      return slides.filter((slide) => slide.id !== slideId);
    });
};

export const addSlides = (
  dynamicPresentation: PresentationRecord,
  presentation: PresentationRecord,
  orgPresentations: List<PresentationRecord>,
  sortOrder = "default",
): PresentationRecord => {
  presentation.slides.map((slide: SlideRecord) => {
    if (!slideSelected(dynamicPresentation, slide.id)) {
      dynamicPresentation = addSlide(dynamicPresentation, slide, orgPresentations, sortOrder);
    }
  });
  return dynamicPresentation;
};

export const addSlide = (
  dynamicPresentation: PresentationRecord,
  slide: SlideRecord,
  orgPresentations: List<PresentationRecord>,
  sortOrder = "default",
): PresentationRecord => {
  const presIds: List<number> = orgPresentations.map((p) => p.id);

  // Sort the slides based on presentation id
  dynamicPresentation = dynamicPresentation.update("slides", (slides) => {
    const updatedSlides = slides.push(slide);

    // No need of sorting or grouping if the sort_order is free-for-all, just needs appending
    if (sortOrder === "free-for-all") {
      return updatedSlides;
    }

    const grouped = updatedSlides.groupBy((a) => a.presentation_id);
    const sorted: List<List<SlideRecord>> = presIds.map((id) => {
      const list = grouped.get(id);
      if (!list) {
        return List();
      }
      return list.sortBy((s) => s.sort_order).toList();
    });

    return sorted.flatten().toList();
  });

  // Get the inspection slide figure out where it goes.
  // Location is hardcoded to 'after' the 'first' presentation.
  const slidesById = dynamicPresentation.slides.map((s) => s.id);
  const inspectionLinkIdx = dynamicPresentation.dynamic_links.findIndex((dl) => dl.kind === "inspection");
  const inspectionLink = dynamicPresentation.dynamic_links.get(inspectionLinkIdx);
  let firstOfSecondIdx = dynamicPresentation.slides.findIndex((dl) => dl.presentation_id !== presIds.first());
  if (firstOfSecondIdx < 0) {
    firstOfSecondIdx = dynamicPresentation.slides.size;
  }

  return dynamicPresentation.update("dynamic_links", (dls) => {
    // check to see if it exists as a destroyed
    const existing = dls.findIndex((dls) => dls.presentable_id === slide.id && dls.presentable_type === "Slide");

    if (existing >= 0) {
      // if it's a destroyed, just undestroy it.
      dls = dls.update(existing, (d) => d.set("_destroy", false));
    } else {
      // if it's not destroyed then add it as a new
      dls = dls.push(dplFromJSON({ presentable_id: slide.id, presentable_type: "Slide" }));
    }

    // sort the dynamic links based on the slide.
    // insert the inspection where it should go
    // update sort orders
    let finalDynamicLinks = dls;
    if (sortOrder === "free-for-all") {
      // if we are in a free-for-all sorting, put the new slide at the end
      const dsSlideIdx = finalDynamicLinks.findIndex((s) => s.presentable_id === slide.id);
      finalDynamicLinks = moveSlide(
        finalDynamicLinks,
        finalDynamicLinks.get(dsSlideIdx),
        dsSlideIdx,
        finalDynamicLinks.size,
      );
    } else {
      // otherwise do traditional sorting
      dls = dls.splice(inspectionLinkIdx, 1);
      finalDynamicLinks = dls
        .sort((a, b) => {
          if (a._destroy && b._destroy) {
            return 0;
          } else if (a._destroy && !b._destroy) {
            return 1;
          } else if (!a._destroy && b._destroy) {
            return -1;
          }

          return slidesById.indexOf(a.presentable_id) - slidesById.indexOf(b.presentable_id);
        })
        .insert(firstOfSecondIdx, inspectionLink)
        .map((dl, idx) => dl.set("sort_order", idx));
    }
    return finalDynamicLinks;
  });
};

export const moveSlide = (
  allSlides: List<DynamicPresentationLinkRecord>,
  slide: DynamicPresentationLinkRecord,
  fromIndex: number,
  toIndex: number,
): List<DynamicPresentationLinkRecord> => {
  allSlides = allSlides
    .delete(fromIndex)
    .insert(toIndex, slide)
    .map((a, idx) => {
      return a.set("sort_order", idx);
    });

  return allSlides;
};

export const createTemplateFromPresentation = (
  name: string,
  presentation: PresentationRecord,
): PresentationTemplateRecord => {
  return presentationTemplateFromJSON({
    name: name,
    org_id: presentation.org_id,
    template: {
      slides: activeDynamicLinks(presentation)
        .map<number>((dl) => dl.presentable_id)
        .toArray(),
    },
  });
};

export const applyTemplateToPresentation = (
  template: PresentationTemplateRecord,
  presentation: PresentationRecord,
  orgPresentations: List<PresentationRecord>,
): PresentationRecord => {
  presentation.dynamic_links.forEach((dls) => {
    if (dls.presentable_type === "Slide") {
      presentation = removeSlide(presentation, dls.presentable_id);
    }
  });

  let slides: List<SlideRecord> = orgPresentations
    .map((op) => op.slides)
    .flatten()
    .toList();
  const slideIds: List<number> = removeDuplicates(slides.map((s) => s.id));
  const tplSlides = removeDuplicates(List(template.template.slides));
  const good: Array<number> = intersectLists(tplSlides, slideIds).toArray();

  good.forEach((n, idx) => {
    const slideIdx = slides.findIndex((s) => s.id === n);
    if (slideIdx >= 0) {
      const newSlide = slides.get(slideIdx);
      presentation = presentation
        .update("slides", (ps) => ps.push(newSlide))
        .update("dynamic_links", (dls) => {
          // check to see if it exists as a destroyed
          const existing = dls.findIndex(
            (dls) => dls.presentable_id === newSlide.id && dls.presentable_type === "Slide",
          );

          if (existing >= 0) {
            // if it's a destroyed, just undestroy it.
            dls = dls.update(existing, (d) => d.set("_destroy", false).set("sort_order", idx));
          } else {
            // if it's not destroyed then add it as a new
            dls = dls.push(dplFromJSON({ presentable_id: newSlide.id, presentable_type: "Slide", sort_order: idx }));
          }

          return dls;
        });
      slides = slides.delete(slideIdx);
    }
  });

  presentation = presentation.update("dynamic_links", (dls) => dls.sortBy((dl) => dl.sort_order));

  return presentation;
};

export const setupDynamicLinkSlides = (
  presentation: PresentationRecord,
  orgPresentations: List<PresentationRecord>,
): PresentationRecord => {
  const existingSlides = orgPresentations
    .map((o) => o.slides)
    .flatten()
    .toList();

  return presentation.update("slides", (slides) => {
    slides = presentation.dynamic_links.map((dl) =>
      existingSlides.find((s) => dl.presentable_id === s.id && dl.presentable_type === "Slide"),
    );
    return slides.filter((a) => a !== undefined);
  });
};
