import * as React from "react";
import { Button } from "react-bootstrap";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import * as FontAwesome from "react-fontawesome";
import { useTracking } from "react-tracking";

export default function EndCallButton() {
  const { room } = useVideoContext();
  const { trackEvent } = useTracking();

  const disconnect = () => {
    trackEvent({ action: "screenshare hung up" });
    room.disconnect();
  };

  return (
    <Button
      title="End Call"
      variant="delete"
      className="control-button"
      // @ts-ignore
      onClick={disconnect}>
      <FontAwesome className="end-call" name="phone" size="lg" />
    </Button>
  );
}
