import { ActionsUnion, createAction } from "./Utils";
import { EstimateTemplateRecord, IEstimateTemplateData } from "../records/EstimateTemplate";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { List } from "immutable";
import { list, create, update, destroy, IEstimateTemplateOptions } from "app2/src/api/estimateTemplate.service";
import { RootDispatchType } from "app2/src/store";
import { RootState, ThunkResult } from "app2/src/reducers";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { IPageData, QueryParamsRecord } from "app2/src/records/Page";
import { handleErrors } from "app2/src/reducers/Utils";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { estimateTemplate, estimateTemplatesSelectedIds } from "app2/src/selectors/estimateTemplate.selectors";
import { dirtyIds } from "app2/src/selectors/pagination.selectors";

// SINGLE
export const FETCH_ESTIMATE_TEMPLATE = "@estimateTemplates/FETCH_ESTIMATE_TEMPLATE";
export const RECEIVE_ESTIMATE_TEMPLATE = "@estimateTemplates/RECEIVE_ESTIMATE_TEMPLATE";
export const RESET_ESTIMATE_TEMPLATE = "@estimateTemplates/RESET_ESTIMATE_TEMPLATE";
export const SET_ESTIMATE_TEMPLATE_LOADED = "@estimateTemplates/SET_ESTIMATE_TEMPLATE_LOADED";
export const DESTROY_ESTIMATE_TEMPLATE = "@estimateTemplates/DESTROY_ESTIMATE_TEMPLATE";
export const EDIT_ESTIMATE_TEMPLATE = "@estimateTemplates/EDIT_ESTIMATE_TEMPLATE";
// MULTIPLE
export const FETCH_ESTIMATE_TEMPLATES = "@estimateTemplates/FETCH_ESTIMATE_TEMPLATES";
export const RECEIVE_ESTIMATE_TEMPLATES = "@estimateTemplates/RECEIVE_ESTIMATE_TEMPLATES";
export const SET_ESTIMATE_TEMPLATES_LOADED = "@estimateTemplates/SET_ESTIMATE_TEMPLATES_LOADED";
export const BATCH_EDIT_ESTIMATE_TEMPLATE = "@estimateTemplates/BATCH_EDIT_ESTIMATE_TEMPLATE";
export const BATCH_REMOVE_IDS = "@estimateTemplates/BATCH_REMOVE_IDS";

export const Actions = {
  // SINGLE
  fetchEstimateTemplate: (id: number) => createAction(FETCH_ESTIMATE_TEMPLATE, { id }),
  receiveEstimateTemplate: (estimateTemplate: IEstimateTemplateData) =>
    createAction(RECEIVE_ESTIMATE_TEMPLATE, { estimateTemplate }),
  resetEstimateTemplate: (id: number) => createAction(RESET_ESTIMATE_TEMPLATE, { id }),
  setEstimateTemplateLoaded: (id: number) => createAction(SET_ESTIMATE_TEMPLATE_LOADED, { id }),
  destroyEstimateTemplate: (id: number) => createAction(DESTROY_ESTIMATE_TEMPLATE, { id }),
  editSortOrder: (id: number, sortOrder: number) =>
    createAction(EDIT_ESTIMATE_TEMPLATE, { id, name: "sortOrder", value: sortOrder }),
  editSelected: (id: number, selected: boolean) =>
    createAction(EDIT_ESTIMATE_TEMPLATE, { id, name: "selected", value: selected }),
  editName: (id: number, name: string) => createAction(EDIT_ESTIMATE_TEMPLATE, { id, name: "name", value: name }),
  // MULTIPLE
  fetchEstimateTemplates: (ids: List<number>) => createAction(FETCH_ESTIMATE_TEMPLATES, { ids }),
  receiveEstimateTemplates: (estimateTemplates: IEstimateTemplateData[]) =>
    createAction(RECEIVE_ESTIMATE_TEMPLATES, { estimateTemplates }),
  setEstimateTemplatesLoaded: (ids: List<number>) => createAction(SET_ESTIMATE_TEMPLATES_LOADED, { ids }),
  batchEditSelected: (ids: List<number>, selected: boolean) =>
    createAction(BATCH_EDIT_ESTIMATE_TEMPLATE, { ids, name: "selected", value: selected }),
  batchEditFolderId: (ids: List<number>, folderId: number) =>
    createAction(BATCH_EDIT_ESTIMATE_TEMPLATE, { ids, name: "folderId", value: folderId }),
  batchEditSortOrder: (ids: List<number>, sortOrder: number) =>
    createAction(BATCH_EDIT_ESTIMATE_TEMPLATE, { ids, name: "sortOrder", value: sortOrder }),
  batchRemoveIds: (ids: List<number>) => createAction(BATCH_REMOVE_IDS, { ids }),
};

export const AsyncActions = {
  listEstimateTemplates: (
    options: Partial<IEstimateTemplateOptions>,
    queryParams: QueryParamsRecord,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const mergedOptions = setOptionsId(options, getState());

      dispatch(paginationActions.Actions.fetchPage("estimateTemplate", queryParams));

      try {
        const response = await list(mergedOptions, queryParams);
        dispatch(Actions.receiveEstimateTemplates(response.estimate_templates));
        const pageData: Partial<IPageData> = {
          ids: List(response.estimate_templates.map((estimateTemplate: IEstimateTemplateData) => estimateTemplate.id)),
          pagination: response.meta.pagination,
        };
        dispatch(paginationActions.Actions.receivePage("estimateTemplate", queryParams, pageData));
      } catch (response) {
        const errors = handleErrors(response, dispatch);
        dispatch(paginationActions.Actions.receivePageError("estimateTemplate", queryParams, errors));
      }
    };
  },
  createEstimateTemplate: (estimateTemplate: EstimateTemplateRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const response = await create(estimateTemplate);
        dispatch(Actions.receiveEstimateTemplate(response.estimate_template));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Created estimate template successfully"));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setEstimateTemplateLoaded(estimateTemplate.id));
      }
    };
  },
  destroyEstimateTemplate: (estimateTemplate: EstimateTemplateRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchEstimateTemplate(estimateTemplate.id));

      try {
        await destroy(estimateTemplate);
        dispatch(Actions.destroyEstimateTemplate(estimateTemplate.id));
        await dispatch(AsyncActions.checkPageEmpty(estimateTemplate.folderId));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Estimate template successfully deleted"));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setEstimateTemplateLoaded(estimateTemplate.id));
      }
    };
  },
  batchUpdateEstimateTemplate: (estimateTemplateIds: List<number>, reject = false): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const promises = [];
      dispatch(Actions.fetchEstimateTemplates(estimateTemplateIds));

      estimateTemplateIds.forEach((estimateTemplateId) => {
        const updatedEstimateTemplate = estimateTemplate(getState(), { estimateTemplateId });
        promises.push(update(updatedEstimateTemplate));
      });

      try {
        const responses = await Promise.all(promises);
        const estimateTemplates = responses.map((response) => response.estimate_template);
        dispatch(Actions.receiveEstimateTemplates(estimateTemplates));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved estimate template successfully"));
      } catch (response) {
        dispatch(Actions.setEstimateTemplatesLoaded(estimateTemplateIds));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, ["Update failed, please try again."]));
        if (reject) {
          return Promise.reject();
        }
      }
    };
  },
  onSortEnd: (oldIndex: number, newIndex: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      await dispatch(paginationActions.AsyncActions.onSortEnd("estimateTemplate", oldIndex, newIndex, ["sortOrder"]));

      dispatch(AsyncActions.batchUpdateEstimateTemplate(dirtyIds(getState())));
      dispatch(paginationActions.Actions.setDirtyIds(null));
    };
  },
  moveEstimateTemplates: (folderId: number): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const estimateTemplateIds = estimateTemplatesSelectedIds(getState(), {});
      const estimateTemplateRecord = estimateTemplate(getState(), { estimateTemplateId: estimateTemplateIds.first() });

      dispatch(Actions.batchEditFolderId(estimateTemplateIds, folderId));
      try {
        await dispatch(AsyncActions.batchUpdateEstimateTemplate(estimateTemplateIds, true));

        dispatch(Actions.batchRemoveIds(estimateTemplateIds));
        await dispatch(AsyncActions.checkPageEmpty(estimateTemplateRecord.folderId));
      } catch {
        dispatch(Actions.batchEditFolderId(estimateTemplateIds, estimateTemplateRecord.folderId));
      }
    };
  },
  checkPageEmpty: (folderId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      return paginationActions.checkPageEmpty(getState(), "estimateTemplate", (newQueryParams) =>
        dispatch(
          AsyncActions.listEstimateTemplates(
            {
              folderId,
            },
            newQueryParams,
          ),
        ),
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;

const setOptionsId = (options: Partial<IEstimateTemplateOptions>, state: RootState): IEstimateTemplateOptions => {
  if (_.isNullOrUndefined(options.id)) {
    options.id = currentOrgId(state);
  }

  return options as IEstimateTemplateOptions;
};
