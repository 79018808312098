import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { DeckRecord } from "app2/src/records/Deck";

export const deck = (state: RootState, props: any) => state.getIn(["decks", "byId", props.deckId]);

export const decks = createSelector([(state, props) => ({ state, props })], (stateProps: any): List<DeckRecord> => {
  const { state, props } = stateProps;
  const { deckIds } = props;
  return deckIds.map((id: number) => deck(state, { deckId: id }));
});
