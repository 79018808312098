import { Nullable } from "app2/src/records";
import * as EmailValidator from "email-validator";

/**
 * Validates a single email address.
 * Legacy code for Angular `ng-pattern` validation. Use `validateEmail` for new code.
 **/
export const emailRegex = /^[^.\s]+[^@\s]*@[^\s]+\.[A-Za-z]{2,}$/;

/**
 * Validates a list of email addresses.
 * Legacy code for Angular `ng-pattern` validation. Use `validateEmail` for new code.
 **/
export const emailListRegex = /^((?!\.)[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,})(,(?!\.)[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,})*$/;

export function validatePhoneNumber(phone: string): boolean {
  if (phone === "") {
    return true;
  }
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone);
}

export function validateEmail(email: string): boolean {
  if (email === "") {
    return true;
  }
  let valid = true;
  email.split(",").forEach((e) => {
    if (!EmailValidator.validate(e)) valid = false;
  });

  return valid;
}

/**
 * Returns true on invalid inputs. Invalid inputs are null, undefined, or empty string
 *
 * @param {data: Nullable<string>} input
 * @returns {boolean}
 */
export const validateInputs = (data: Nullable<string>): boolean => {
  const badValues = [null, undefined, ""];
  return badValues.includes(data?.trim());
};
