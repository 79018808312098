import { PhoneNumberRecord } from "app2/src/records/PhoneNumber";
import * as moment from "moment";
import { List } from "immutable";

type DateFormatType =
  | "shortDate"
  | "shortName"
  | "shortDate-hyphenated"
  | "shortTime"
  | "shortNameTime"
  | "shortTimeMilitary"
  | "short-hyphenated"
  | "shortHyphenatedUnderscore"
  | "short";

/**
 * Default rails format: 2018-12-13T13:05:51Z || "YYYY-MM-DDTHH:mm:ssZ"
 */
export function DateFormat(date: any, format: DateFormatType = "short"): string {
  let formatString: string;
  switch (format) {
    case "shortDate":
      formatString = "MM/DD/YY";
      break;
    case "shortName":
      formatString = "MMM Do, YYYY";
      break;
    case "shortDate-hyphenated":
      formatString = "YYYY-MM-DD";
      break;
    case "shortTime":
      formatString = "h:mm A";
      break;
    case "shortNameTime":
      formatString = "MMM Do, YYYY h:mm A";
      break;
    case "shortTimeMilitary":
      formatString = "HH:mm";
      break;
    case "short-hyphenated":
      formatString = "MM-DD-YY h:mm A";
      break;
    case "shortHyphenatedUnderscore":
      formatString = "MM-DD-YY_h-mm";
      break;
    case "short":
    default:
      formatString = "MM/DD/YY h:mm A";
      break;
  }

  return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format(formatString);
}

export function CurrencyFormat(amount: number, fractionDigits = 2): string {
  const rounded: string = amount.toFixed(fractionDigits).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  return `\$${rounded}`;
}

export function PercentageFormat(amount: number): string {
  const rounded: string = parseFloat(amount.toFixed(2))
    .toString()
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return `${rounded}%`;
}

export function UppercaseWord(word: string): string {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

export const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export const SnakeToCamel = (value: string): string => {
  if (value.match(uuidRegex)) {
    return value;
  }

  let transformed = value.replace(/([-_][a-z|0-9])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });

  transformed = transformed.charAt(0).toLowerCase() + transformed.slice(1);

  if (value.startsWith("_") && !transformed.startsWith("_")) {
    transformed = `_${transformed}`;
  }

  return transformed;
};

export const SnakeToCamelKeys = (data: any): any => {
  if (data === Object(data) && !Array.isArray(data) && typeof data !== "function") {
    const newData = {};
    Object.keys(data).forEach((k) => {
      newData[SnakeToCamel(k)] = SnakeToCamelKeys(data[k]);
    });
    return newData;
  } else if (Array.isArray(data)) {
    return data.map((i) => {
      return SnakeToCamelKeys(i);
    });
  }
  return data;
};

export const CamelToSnake = (value: string, underScoreNumbers = true): string => {
  if (value.match(uuidRegex)) {
    return value;
  }

  let transformed = value;
  if (underScoreNumbers) {
    transformed = transformed.replace(/([A-Z|0-9])/g, "_$1");
  } else {
    transformed = transformed.replace(/([A-Z])/g, "_$1");
  }

  transformed = transformed.replace(/^_/, "").toLowerCase();

  if (value.startsWith("_") && !transformed.startsWith("_")) {
    transformed = `_${transformed}`;
  }

  return transformed;
};

export const CamelToSnakeKeys = (data: any): any => {
  if (data === Object(data) && !Array.isArray(data) && typeof data !== "function") {
    const newData = {};
    Object.keys(data).forEach((k) => {
      newData[CamelToSnake(k)] = CamelToSnakeKeys(data[k]);
    });
    return newData;
  } else if (Array.isArray(data)) {
    return data.map((i) => {
      return CamelToSnakeKeys(i);
    });
  }
  return data;
};

export const StylizedQuotesToSimpleQuotes = (value: string): string => {
  return value.replace(/[\u201C\u201D]/g, '\\"').replace(/[\u2018\u2019]/g, "'");
};

export const SnakeToPascal = (value: string): string => {
  return _.toTitleCase(value).replaceAll(" ", "");
};

// Common Titles Helper function
export function CommonTitles(name: ValidCommonTitles): string {
  return commonTitles[name];
}

/**
 * Takes an List of phone numbers and returns mobile number first, then next phone number if a mobile does not exist, and formats into string
 * @phoneNumbers List of phone numbers with type
 * @returns string
 */
export const SelectMobilePhoneNumber = (phoneNumbers: List<PhoneNumberRecord>): string => {
  let phoneNumber = <string>phoneNumbers
    .filter((pn) => /sms|mobile|cell/.test(pn.name?.toLowerCase()))
    .map((pn) => pn.number?.replace(/\D/g, ""))
    .first();

  if (!phoneNumber) {
    phoneNumber = <string>phoneNumbers.map((pn) => pn.number?.replace(/\D/g, "")).first();
  }

  if (!phoneNumber) {
    return "";
  }

  return phoneNumber;
};

export const minMaxIndex = (firstIndex: number, secondIndex: number): { min: number; max: number } => {
  const min = _.min([firstIndex, secondIndex]);
  const max = _.max([firstIndex, secondIndex]);
  return { min, max };
};

export type ValidCommonTitles =
  | "estimate"
  | "contract"
  | "opening_name"
  | "estimator"
  | "inspection"
  | "finance"
  | "finance_options"
  | "price_presentation"
  | "subtotal"
  | "total"
  | "description";

// Common Titles - What OCC calls things (development/support/leadership)
// These can be changed by the customer, but should not be changed in the admin screen
const commonTitles = {
  estimate: "Proposal",
  contract: "Agreement",
  opening_name: "Name",
  estimator: "Estimate",
  inspection: "Inspection",
  finance: "Investment",
  finance_options: "Finance Options",
  price_presentation: "Price Presentation",
  subtotal: "Subtotal",
  total: "Total",
  description: "Description",
};
