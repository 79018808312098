import { Record } from "immutable";

export const fromJSON = (
  data: Partial<IEstimateCommissionOutputObjectsData>,
): EstimateCommissionOutputObjectsRecord => {
  const record: IEstimateCommissionOutputObjectsRecord = { ...(data as any) };
  return new EstimateCommissionOutputObjectsRecord(record);
};

export interface IEstimateCommissionOutputObjectsData {
  title: string;
  value: string;
  formatted: string;
}

export interface IEstimateCommissionOutputObjectsRecord {
  title: string;
  value: string;
  formatted: string;
}

const defaultIEstimateCommissionOutputObjectsProps: IEstimateCommissionOutputObjectsRecord = {
  title: "",
  value: "",
  formatted: "",
};

export class EstimateCommissionOutputObjectsRecord
  extends Record(defaultIEstimateCommissionOutputObjectsProps)
  implements IEstimateCommissionOutputObjectsRecord {}
