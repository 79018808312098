import { CouponsComponent } from "./CouponsComponent";
import { CouponEditModalCtrl } from "./CouponEditModalCtrl";

export class CouponsRoutes {
  public static load(app: ng.IModule) {
    app.component("coupons", new CouponsComponent());
    app.controller("CouponEditModalCtrl", CouponEditModalCtrl);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {}
}
