import { IProductOptionGroup } from "app/src/Models/ProductOptionGroup";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IProductOption } from "app/src/Models/ProductOption";
import { IEstimateMarkups } from "app/src/Models/Estimate";
import { markupMultiplier } from "app2/src/records/Estimate";
import { isMarkupable } from "app2/src/records/EstimateLineItem";
import { dispatchException } from "app2/src/helpers/Analytics";

export interface IEstimateLineItemOption {
  id: number;
  quantity: number;
  name: string;
  description: string;
  ext_price: number;
  uom: string;
  price: number;
  product_option_uuid: string;
  product_option_match_id: string;
  estimate_line_item_id: number;
  estimate_line_item: IEstimateLineItem;
  product_option_group_id: number;
  product_option_group_name: string;
  product_option_id: number;
  product_option_image_ids: number[];
  cost: number;
  labor_price: number;
  product_price: number;
  labor_factor: number;
  product_factor: number;
  base_product_price: number;
  base_labor_price: number;
  created_at: Date;
  updated_at: Date;
  charge_type: string;
  pog_type: string;
  msrp: number;
  _destroy: boolean;

  calculate(estimate: IEstimateMarkups): number;
  calculateLaborPrice(): number;
  calculateProductPrice(): number;
}

export class EstimateLineItemOption implements IEstimateLineItemOption {
  public id: number;
  public quantity: number;
  public name: string;
  public description: string;
  public ext_price: number;
  public uom: string;
  public price: number;
  public product_option_uuid: string;
  public product_option_match_id: string;
  public estimate_line_item_id: number;
  public estimate_line_item: IEstimateLineItem;
  public product_option_group_id: number;
  public product_option_group_name: string;
  public product_option_group: IProductOptionGroup;
  public product_option_id: number;
  public product_option_image_ids: number[] = [];
  public product_option: IProductOption;
  public cost = 0;
  public labor_price = 0;
  public product_price = 0;
  public base_product_price = 0;
  public base_labor_price = 0;
  public labor_factor = 0;
  public product_factor = 0;
  public msrp = 0;
  public created_at: Date;
  public updated_at: Date;
  public charge_type: string;
  public pog_type: string;
  public _destroy: boolean;

  constructor(data: any) {
    if (data.id) {
      this.id = data.id;
    }
    if (data.quantity) {
      this.quantity = parseFloat(data.quantity);
    } else {
      this.quantity = 1;
    }
    if (data.estimate_line_item_id) {
      this.estimate_line_item_id = data.estimate_line_item_id;
    }
    if (data.estimate_line_item) {
      this.estimate_line_item = data.estimate_line_item;
    }
    if (data.product_option_group_id) {
      this.product_option_group_id = data.product_option_group_id;
    }
    if (data.product_option_group_name) {
      this.product_option_group_name = data.product_option_group_name;
    }
    if (data.product_option_group) {
      this.product_option_group = data.product_option_group;
      this.product_option_group_id = data.product_option_group.id;
    }
    if (data.product_option_id) {
      this.product_option_id = data.product_option_id;
    }
    if (data.product_option_image_ids) {
      this.product_option_image_ids = data.product_option_image_ids;
    }
    if (data.product_option) {
      this.product_option = data.product_option;
      this.product_option_id = this.product_option.id;
    }
    if (data.cost) {
      this.cost = parseFloat(data.cost);
    }
    if (data.labor_price) {
      this.labor_price = parseFloat(data.labor_price);
    }
    if (data.product_price) {
      this.product_price = parseFloat(data.product_price);
    }
    if (data.base_product_price) {
      this.base_product_price = parseFloat(data.base_product_price);
    }
    if (data.base_labor_price) {
      this.base_labor_price = parseFloat(data.base_labor_price);
    }
    if (data.labor_factor) {
      this.labor_factor = parseFloat(data.labor_factor);
    }
    if (data.product_factor) {
      this.product_factor = parseFloat(data.product_factor);
    }
    if (data.msrp) {
      this.msrp = parseFloat(data.msrp);
    }
    if (data.price) {
      this.price = data.price;
    } else {
      this.price = 0;
    }
    if (data.name) {
      this.name = data.name;
    }
    if (data.description) {
      this.description = data.description;
    }
    if (data.ext_price) {
      this.ext_price = data.ext_price;
    } else {
      this.ext_price = 0;
    }

    if (data.product_option_uuid) {
      this.product_option_uuid = data.product_option_uuid;
    }
    if (data.product_option_match_id) {
      this.product_option_match_id = data.product_option_match_id;
    }
    if (data.uom) {
      this.uom = data.uom;
    }
    if (data.charge_type) {
      this.charge_type = data.charge_type;
    }
    if (data.pog_type) {
      this.pog_type = data.pog_type;
    }
    if (data.created_at) {
      this.created_at = data.created_at;
    }
    if (data.updated_at) {
      this.updated_at = data.updated_at;
    }

    if (data._destroy) {
      this._destroy = data._destroy;
    }
  }

  public calculate(estimate: IEstimateMarkups): number {
    if (!this.estimate_line_item) {
      this.ext_price = 0;
      this.labor_price = 0;
      this.product_price = 0;
      this.price = 0;
      const error = "estimate line item not setup on option";
      console.error(error);
      dispatchException(new Error(error));
      return this.ext_price;
    }

    if (this.uom === "percent") {
      this.base_product_price = _.round(this.estimate_line_item.base_product_price * (this.product_factor / 100), -2);
      this.base_labor_price = _.round(this.estimate_line_item.base_labor_price * (this.labor_factor / 100), -2);
    }

    if (isMarkupable(this.estimate_line_item)) {
      this.product_price = _.round((this.base_product_price || 0) * markupMultiplier(estimate.product_markup), -2);
      this.labor_price = _.round((this.base_labor_price || 0) * markupMultiplier(estimate.labor_markup), -2);
    } else {
      this.product_price = this.base_product_price || 0;
      this.labor_price = this.base_labor_price || 0;
    }

    this.price = this.product_price + this.labor_price;

    this.ext_price = _.round((this.price || 0) * (this.calculateQuantity() || 0), -2);

    return this.ext_price;
  }

  public calculateLaborPrice(): number {
    if (!this.estimate_line_item) {
      return 0;
    }
    return _.round(this.labor_price * this.calculateQuantity(), -2);
  }

  public calculateProductPrice(): number {
    if (!this.estimate_line_item) {
      return 0;
    }
    return _.round(this.product_price * this.calculateQuantity(), -2);
  }

  public calculateQuantity(): number {
    if (!this.estimate_line_item) {
      return 0;
    }
    if (this.charge_type === "per") {
      return _.round(this.quantity, -2);
    } else {
      return _.round(this.quantity * this.estimate_line_item.quantity, -2);
    }
  }
}
