import * as userActions from "./user.actions";
import { Record, List, Map, fromJS, getIn, OrderedMap } from "immutable";
import { fromJSON, IUserRecord, UserRecord } from "../records/UserRecord";
import { reducer as orgReducer } from "app2/src/reducers/org.reducer";
import { Actions as orgActions } from "app2/src/reducers/org.actions";
import { RootState, RootActions } from "app2/src/reducers";
import { reducer as accessReducer } from "app2/src/reducers/access.reducer";
import { Actions as accessActions } from "app2/src/reducers/access.actions";
import { mergeDeepOverwriteArrays } from "app2/src/helpers/ImmutableData";
import { Nullable } from "app2/src/records";
import { CrmUserType } from "app2/src/records/integrations/CrmUser";

export class AclByUserAccess extends Record({
  loading: Map<string, boolean>(),
  acl: Map<string, Map<string, Map<string, List<string>>>>(),
  templates: Map<string, Map<string, List<any>>>(),
}) {
  public readonly loading!: Map<string, boolean>;
  public readonly acl!: Map<string, Map<string, Map<string, List<string>>>>;
  public readonly templates!: Map<string, Map<string, List<any>>>;
}

export const UserStateRecord = Record({
  currentUser: null as Nullable<UserRecord>,
  usersById: Map<number, IUserRecord>(),
  lastSavedById: Map<number, IUserRecord>(),
  crmUserCheckByOrgId: Map<number, Map<CrmUserType, boolean>>(),
  allowedAclByUserAccess: new AclByUserAccess(),
});

export const initialState = UserStateRecord();

export type UserState = typeof initialState;

export const model = "users";
export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case userActions.SET_USER:
      const immutableUser = fromJSON(action.payload);
      // if this user already exists, we should merge the data in so as to
      // not overwrite any data
      if (state.getIn([model, "usersById", action.payload.id])) {
        const currentUser = state.getIn([model, "usersById", action.payload.id]);
        const accesses = currentUser.accesses;
        let mergedUser = mergeDeepOverwriteArrays(currentUser, immutableUser);

        mergedUser = mergedUser.set(
          "accesses",
          List(new Set(accesses.toArray().concat(mergedUser.accesses.toArray()))),
        );

        state = state
          .setIn([model, "usersById", action.payload.id], mergedUser)
          .setIn([model, "lastSavedById", action.payload.id], mergedUser);
      } else {
        state = state
          .setIn([model, "usersById", action.payload.id], immutableUser)
          .setIn([model, "lastSavedById", action.payload.id], immutableUser);
      }

      if (action.payload.accesses?.length > 0) {
        action.payload.accesses.forEach((access) => {
          state = accessReducer(state, accessActions.setAccess(access.id, access));
        });
      }
      return state;

    case userActions.SET_USER_LOADED:
      if (getIn(state, [model, "usersById", action.payload.userId, "loading"], false)) {
        return state.setIn([model, "usersById", action.payload.userId, "loading"], false);
      }

      return state;

    case userActions.RESET_USER: {
      const currentUser = state.getIn([model, "usersById", action.payload.userId]);
      currentUser.accesses.forEach((a) => {
        state = accessReducer(state, accessActions.resetAccess(a.id));
      });
      return state.setIn(
        [model, "usersById", action.payload.userId],
        state.getIn([model, "lastSavedById", action.payload.userId]),
      );
    }

    case userActions.SET_CURRENT_USER:
      if (action.payload) {
        const user = { ...action.payload };
        if (user.accesses) {
          user.accesses.forEach((access) => {
            if (access.org) state = orgReducer(state, orgActions.setOrg(access.org));
            delete access.org;
            state = accessReducer(state, accessActions.setAccess(access.id, access));
          });
          user.accesses = user.accesses.map((a) => a.id);
        }
        if (user.org) {
          state = orgReducer(state, orgActions.setOrg(user.org));
          delete user.org;
        }
        // @ts-ignore
        const userRecord = fromJSON(user);
        return state.setIn([model, "currentUser"], userRecord).setIn([model, "usersById", user.id], userRecord);
      } else {
        const id = state.getIn(["user", "currentUser", "id"]);
        return state.setIn([model, "currentUser"], null).deleteIn([model, "usersById", id]);
      }

    case userActions.FETCH_USER:
      if (state.getIn([model, "usersById", action.payload])) {
        return state.setIn([model, "usersById", action.payload, "loading"], true);
      }
      return state.setIn([model, "usersById", action.payload], new UserRecord({ id: action.payload, loading: true }));

    case userActions.RECEIVE_USERS:
      action.payload.users.forEach((u) => {
        state = reducer(state, userActions.Actions.setUser(u));
      });
      return state;

    case userActions.SET_CRM_USER_CHECK:
      return state.setIn(
        [model, "crmUserCheckByOrgId", action.payload.orgId, action.payload.crmUserType],
        action.payload.result,
      );

    case userActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);

    case userActions.FETCH_ALLOWED_ACL:
      return state.setIn(
        [model, "allowedAclByUserAccess", "loading", `${action.payload.userId}:${action.payload.accessUid}`],
        true,
      );

    case userActions.RECEIVE_ALLOWED_ACL:
      const userIdAccessUid = `${action.payload.userId}:${action.payload.accessUid}`;
      return state
        .setIn([model, "allowedAclByUserAccess", "loading", userIdAccessUid], false)
        .setIn(
          [model, "allowedAclByUserAccess", "acl", userIdAccessUid, action.payload.template || "none"],
          fromJS(action.payload.acl.acl),
        )
        .setIn(
          [model, "allowedAclByUserAccess", "templates", userIdAccessUid, action.payload.template || "none"],
          fromJS(action.payload.acl.templates),
        );

    case userActions.ADD_ACCESSES: {
      const { userId, accessIds } = action.payload;
      if (!state.getIn([model, "usersById", userId])) {
        const userRecord = fromJSON({ id: userId }).set("accesses", List(accessIds));
        return state
          .setIn([model, "usersById", userId], userRecord)
          .setIn([model, "lastSavedById", userId], userRecord);
      }
      return state
        .updateIn([model, "usersById", userId, "accesses"], List(), (accesses: List<number>) => {
          return List(new Set(accesses.toArray().concat(accessIds)));
        })
        .updateIn([model, "lastSavedById", userId, "accesses"], List(), (accesses: List<number>) => {
          return List(new Set(accesses.toArray().concat(accessIds)));
        });
    }
    default:
      return state;
  }
};
