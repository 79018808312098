import { AbstractWizardController } from "app/src/Common/AbstractWizardController";
import { IJob } from "../Models/Job";
import { IOrg } from "../Models/Org";
import { IImage } from "../Models/Image";
import { IElevation } from "../Models/Elevation";
import { IAnnotation } from "../Models/Annotation";
import { IMeasurement } from "../Models/Measurement";
import { IWallFacade, WallFacade } from "../Models/WallFacade";
import { IFlash } from "../Common/FlashService";
import { IOpening } from "../Models/Opening";
import { IAnnotationMeasuredEvent } from "../Drawing/DrawingComponent";
import { IRepository } from "../Common/Repository";
import { IPretty } from "../Common/PrettyNameService";
import { IMeasurementMetadata } from "rsf-visualizer";
import { dispatch, useSelector } from "app2/src/storeRegistry";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { subscriber } from "app2/src/helpers/Subscribe";
import { currentMeasurementId } from "app2/src/selectors/measurementCommon.selectors";
import { getMeasurementHydrated } from "app2/src/selectors/measurement.selectors";

enum Mode {
  none = 0,
  edit = 1,
  create = 2,
}

class WindowAnnotatingComponentCtrl extends AbstractWizardController implements ng.IComponentController {
  public spinnerPromise: ng.IPromise<any>;
  public saving = false;
  public startRefreshing = 0;
  public elevation: IElevation;
  public job: IJob;
  public org: IOrg;
  public measurement: IMeasurement;
  public measurementClone: IMeasurement;
  public elevations: IElevation[] = [];
  public abbrSet = false;
  public elevationNames: string[] = ["Front", "Right", "Left", "Back"];
  public close: (value: any) => void;
  public dismiss: () => void;
  public selectedAnnotations: IAnnotation[] = [];
  public resolve: { org: IOrg; job: IJob };
  public openingTypes: string[] = ["window", "door"];

  protected mode: Mode = Mode.none;

  private lastCall: Date;
  private _currentId = -10;
  private _sendInviteSubscribe: () => void;

  public static $inject = ["Repository", "Flash", "$scope", "$q", "$timeout", "$analytics", "Pretty"];
  constructor(
    protected Repository: IRepository,
    protected Flash: IFlash,
    protected $scope: ng.IScope,
    protected $q: ng.IQService,
    protected $timeout: ng.ITimeoutService,
    protected $analytics: angulartics.IAnalyticsService,
    protected Pretty: IPretty,
  ) {
    super();

    this._sendInviteSubscribe = subscriber.subscribe<number>("components.common.sendInvite.sent", (sendInviteSent) => {
      if (this.startRefreshing !== sendInviteSent) {
        this.startRefreshing = sendInviteSent;
        this.$timeout(() => {
          this.$scope.$digest();
        });
      }
    });

    $scope.$on("$destroy", () => {
      this._sendInviteSubscribe();
    });
  }

  public $onInit() {
    this.job = this.resolve.job;
    this.org = this.resolve.org;

    this.setupMeasurement();
    this.refreshImages();

    if (this.measurement.locations) {
      this.measurement.locations = _.uniq(
        this.measurement.locations.concat(this.Repository.Measurement.defaultLocations),
      );
    } else {
      this.measurement.locations = _.uniq(this.Repository.Measurement.defaultLocations);
    }
    if (this.measurement.levels) {
      this.measurement.levels = _.uniq(this.measurement.levels.concat(this.Repository.Measurement.defaultLevels));
    } else {
      this.measurement.levels = _.uniq(this.Repository.Measurement.defaultLevels);
    }

    this.measurement.levels = _.sortBy(this.measurement.levels, function (n) {
      return n.toLowerCase();
    });
    this.measurement.locations = _.sortBy(this.measurement.locations, function (n) {
      return n.toLowerCase();
    });

    this._currentId = _.min([-10, _.chain(this.elevations).pluck("id").min().value() - 1]);

    this.$scope.$on("rsf:drawing:setmeasurements", (_event: any, args: IAnnotationMeasuredEvent) => {
      const annotation = _.find(this.allAnnotations(), (ann: IAnnotation) => ann.guid === args.annotation.guid);
      annotation.width = args.width;
      annotation.height = args.height;

      if (this.selectedAnnotations.length > 0) {
        this.propChanged(args.annotation, "width", args.width);
        this.propChanged(args.annotation, "height", args.height);
      } else {
        this.$scope.$digest();
      }
    });

    this.$scope.$on("rsf:drawing:updateshape", (_event: any, args: { shape: IMeasurementMetadata }) => {
      switch (args.shape.class) {
        case "PolygonShape":
          this._updateWallFacade(args.shape);
          break;
      }

      this.$scope.$digest();
    });
  }

  public noAnnotationImages(image: IImage) {
    return !image.file.url.includes("Annotations.png");
  }

  public addElevation(image: IImage) {
    this.mode = Mode.create;
    this.elevation = this.Repository.Elevation.fromJSON({ id: this._currentId, measurement: this.measurement });
    this.elevation.src_image = image;
    this.elevation.src_image_id = image.id;
    this.abbrSet = false;

    this._currentId -= 1;

    this.elevations.push(this.elevation);

    this.elevation = this.elevation.clone();

    this.trackEvent("elevation added", {
      elevation: this.elevation.id,
    });

    this.nextStep();
  }

  public editElevation(elevation: IElevation) {
    this.mode = Mode.edit;
    this.elevation = elevation.clone();
    this.trackEvent("start elevation edit", {
      elevation: this.elevation.id,
    });
    this.gotoStep(2);
  }

  public measureElevation(elevation: IElevation) {
    this.mode = Mode.edit;

    this.saveMeasurement();
    this.measurementClone = this.measurement.clone();
    this.elevation = _.find(this.measurementClone.elevations, (e: IElevation) => e.id === elevation.id);

    this.trackEvent("start elevation measure", {
      elevation: this.elevation.id,
    });
    this.gotoStep(3);
  }

  public removeAnnotation(annotation: IAnnotation) {
    this.elevation.removeAnnotation(annotation);

    if (annotation.opening_id) {
      this.trackEvent("remove annotation with opening", {
        opening: annotation.opening_id,
        opening_type: annotation.opening_type,
        elevation: annotation.elevation.id,
      });
    } else {
      this.trackEvent("remove annotation", {
        opening_type: annotation.opening_type,
        elevation: annotation.elevation.id,
      });
    }
  }

  public nameChanged() {
    this.trackEvent("rename elevation", {
      elevation: this.elevation.id,
    });

    if (this.abbrSet) {
      return;
    }

    this.elevation.abbr = this.elevation.name[0] || "";
  }

  public abbrChanged() {
    this.trackEvent("abbrevation set - elevation", {
      elevation: this.elevation.id,
    });
    this.abbrSet = true;
  }

  public propChanged(annotation: IAnnotation, prop: string, value: any) {
    if (
      !_.any(
        this.selectedAnnotations,
        (a: IAnnotation) => a.id === annotation.id && a.elevation.name === annotation.elevation.name,
      )
    ) {
      return;
    }

    this.trackEvent("mass edit annotations", {
      property_changed: prop,
      elevation: annotation.elevation.id,
      annotation: annotation.id,
      annotation_count: this.selectedAnnotations.length,
      annotation_ids: _.pluck(this.selectedAnnotations, "id"),
    });

    _.each(this.selectedAnnotations, (a: IAnnotation) => {
      a[prop] = value;
    });
  }

  public toggleOpeningType(annotation: IAnnotation) {
    this.trackEvent("toggle annotation type", {
      elevation: annotation.elevation.id,
      annotation: annotation.id,
    });

    if (annotation.opening_type === "window") {
      annotation.opening_type = "door";
      this.propChanged(annotation, "opening_type", "door");
    } else {
      annotation.opening_type = "window";
      this.propChanged(annotation, "opening_type", "window");
    }
  }

  /**
   * Updates annotations on current elevation in place on existing objects
   * @param annotations
   */
  public annotationsUpdated(annotations: IAnnotation[]) {
    if (annotations.length <= this.elevation.annotations.length) {
      this.trackEvent("annotation deleted", {
        elevation: this.elevation.id,
        annotation_count: annotations.length,
        annotation_ids: _.pluck(annotations, "id"),
      });
    }
    const existingGuids = _.pluck(annotations, "guid");
    this.elevation.annotations = _.select(this.elevation.annotations, (a: IAnnotation) =>
      _.includes(existingGuids, a.guid),
    );
    const needToAdd = _.difference(existingGuids, _.pluck(this.elevation.annotations, "guid"));
    _.each(this.elevation.annotations, (a: IAnnotation) => {
      const src = _.find(annotations, (toMatch: IAnnotation) => toMatch.guid === a.guid);
      a.update(src);
    });
    _.each(needToAdd, (guid: string) =>
      this.elevation.annotations.push(_.find(annotations, (a: IAnnotation) => a.guid === guid)),
    );

    this.elevation.$hasChanged = true;
    this.$scope.$digest();
  }

  public selectAll() {
    if (this.anySelected()) {
      this.trackEvent("annotation - select none", {});
      this.selectNone();
      return;
    }
    this.selectedAnnotations = this.allAnnotations();
    this.trackEvent("annotation - select all", {
      annotation_count: this.selectedAnnotations.length,
      annotation_ids: _.pluck(this.selectedAnnotations, "id"),
    });
  }

  public selectNone() {
    this.selectedAnnotations = [];
  }

  public anySelected() {
    return this.selectedAnnotations.length > 0;
  }

  public select(annotation: IAnnotation) {
    if (
      _.any(this.selectedAnnotations, (a: IAnnotation) => {
        return a.id === annotation.id && a.elevation.id === annotation.elevation.id;
      })
    ) {
      this.trackEvent("annotation - unselect", {
        annotation: annotation.id,
      });
      this.selectedAnnotations = _.select(this.selectedAnnotations, (a: IAnnotation) => {
        return a.id !== annotation.id || a.elevation.id !== annotation.elevation.id;
      });

      return;
    }
    this.trackEvent("annotation - select", {
      annotation: annotation.id,
    });

    this.selectedAnnotations = this.selectedAnnotations.concat(annotation);
  }

  public selected(annotation: IAnnotation) {
    return _.any(this.selectedAnnotations, (a: IAnnotation) => {
      return a.id === annotation.id && a.elevation.id === annotation.elevation.id;
    });
  }

  public saveAnnotations(showMeasurer: boolean) {
    this.trackEvent("finish elevation edit", {
      elevation: this.elevation.id,
    });
    const idx: number = _.findIndex(this.elevations, (e: IElevation) => e.id === this.elevation.id);

    this.elevations[idx] = this.elevation;

    if (showMeasurer) {
      this.measureElevation(this.elevation);
    } else {
      this.elevation = null;
      this.gotoStep(0);
    }

    this.mode = Mode.none;
  }

  public saveMeasurements() {
    this.$scope.$broadcast("rsf:measurements:save", { elevation: this.elevation });

    this.measurement = this.measurementClone;
    this.elevations = this.measurement.elevations;

    this.measurementClone = null;
    this.elevation = null;

    this.gotoStep(0);

    this.mode = Mode.none;
  }

  public measurementsUpdated(annotations: IAnnotation[]) {
    this.annotationsUpdated(annotations);
    this.$scope.$digest();
  }

  public cancelAnnotations() {
    if (this.mode === Mode.create) {
      this.elevations = _.select(this.elevations, (e: IElevation) => e.id !== this.elevation.id);
    }
    this.elevation = null;
    this.measurementClone = null;

    this.mode = Mode.none;
    this.gotoStep(0);
  }

  public cancelAddingElevation() {
    this.trackEvent("elevation add canceled", {});
    this.gotoStep(0);
  }

  public allAnnotations() {
    if (this.measurementClone) {
      return _.chain(this.measurementClone.elevations)
        .map((e: IElevation) => e.annotations)
        .flatten()
        .value();
    }
    return _.chain(this.elevations)
      .map((e: IElevation) => e.annotations)
      .flatten()
      .value();
  }

  public allWallFacades() {
    if (this.measurementClone) {
      return _.chain(this.measurementClone.elevations)
        .map((e: IElevation) => e.wallFacades)
        .flatten()
        .value();
    }
    return _.chain(this.elevations)
      .map((e: IElevation) => e.wallFacades)
      .flatten()
      .value();
  }

  public save() {
    this.trackEvent("save annotator", {
      elevation_count: this.elevations.length,
      annotation_count: this.allAnnotations().length,
    });
    const deferred: ng.IDeferred<void> = this.$q.defer();
    this.saveMeasurement();
    const result = {
      measurement: this.measurement,
    };
    const renderedElevations: any[] = [];

    this.saving = true;
    this.spinnerPromise = deferred.promise;

    if (this.elevations.length === 0) {
      deferred.resolve();
      this.close({ $value: result });
    }

    this.$scope.$on("rsf:drawing:rendered", (event, args: any) => {
      renderedElevations.push(args);

      if (renderedElevations.length === this.elevations.length) {
        _.each(renderedElevations, (re: any) => {
          const elevation = _.find(this.elevations, (e: IElevation) => e.id === re.id);
          elevation.renderedDataUrl = re.dataUrl;
        });
        deferred.resolve();
        this.close({ $value: result });
      }
    });

    this.$timeout(() => {
      _.each(this.elevations, (elevation: IElevation) => {
        _.each(elevation.removedAnnotations, (annotation: IAnnotation) => {
          const opening = _.find(this.measurement[`${annotation.opening_type}s`], (o: IOpening) => {
            return o.id === annotation.opening_id;
          });

          if (opening) {
            this.measurement[`delete${_.toTitleCase(annotation.opening_type)}`](opening);
          }
        });

        _.each(elevation.removedWallFacades, (wf: IWallFacade) => {
          const wallFacade = this.measurement.wall_facades.find((i) => i.id === wf.id);

          if (wallFacade) {
            this.measurement.deleteWallFacade(wallFacade);
          }
        });

        this.$scope.$broadcast("rsf:drawing:render", { id: elevation.id });
      });
    }, 10000);
  }

  public cancel() {
    this.trackEvent("cancel annotator", {});
    this.dismiss();
  }

  public sendInvite() {
    dispatch(commonActions.Actions.openSendInviteModal("image_uploader", {}));
  }

  /**
   * Callback for the Refresh Button
   */
  public refreshImages() {
    const args = {
      imageable_type_url: "jobs",
      imageable_id: this.job.id,
    };

    if (this.lastCall) {
      args["created_since"] = this.lastCall;
    }

    this.lastCall = new Date();
    const query = this.Repository.Image.query(args);

    query.$promise.then((data: any) => {
      const preImageCount = this.job.images.length;

      _.each(data.images, (i: IImage) => this.job.images.push(i));
      this.job.images = _.chain(this.job.images)
        .uniq((i: IImage) => i.id)
        // @ts-ignore
        .sort((a: IImage, b: IImage): number => {
          if (a.sort_order === b.sort_order) {
            return 0;
          }

          if (a.sort_order === null) {
            return 1;
          }

          if (b.sort_order === null) {
            return -1;
          }

          if (a.sort_order < b.sort_order) {
            return -1;
          }

          return 1;
        })
        .value();

      return preImageCount !== this.job.images.length;
    });

    return query.$promise;
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        category: "Annotations",
        job: this.job.id,
        org: this.job.org_id,
      }),
    );
  }

  public gotoStep(idx: number): void {
    this.selectNone();
    super.gotoStep(idx);
  }

  protected setupMeasurement(): void {
    const measurementId = useSelector((state) => currentMeasurementId(state, {}));
    const hydratedMeasurement = useSelector((state) => getMeasurementHydrated(state, { measurementId }));
    this.measurement = this.Repository.Measurement.fromJSON(hydratedMeasurement.toJS());
    this.elevations = this.measurement.elevations;
  }

  protected saveMeasurement(): void {
    this.measurement.elevations = this.elevations;

    if (this.allWallFacades().length > 0) {
      const area = _.reduce(this.allWallFacades(), (memo, wf) => wf.area + memo, 0);

      this.measurement.siding_total_area = _.round(area, -2);
      this.measurement.siding_5pw_total_area = _.round(area * 1.05, -2);
      this.measurement.siding_10pw_total_area = _.round(area * 1.1, -2);
      this.measurement.siding_15pw_total_area = _.round(area * 1.15, -2);
      this.measurement.siding_18pw_total_area = _.round(area * 1.18, -2);

      this.allWallFacades().forEach((wf: IWallFacade) => {
        const match = this.measurement.wall_facades.find((m) => m.ref_id === wf.ref_id);

        if (match) {
          match.area = _.round(wf.area, -2);
        } else {
          // use a clone for circular references
          this.measurement.wall_facades.push(wf.clone());
        }
      });
    }
  }

  private _updateWallFacade(shape: IMeasurementMetadata): void {
    let facade = this.elevation.wallFacades.find((wf: IWallFacade) => {
      return wf.ref_id === shape.guid;
    });

    if (!facade) {
      facade = new WallFacade();
      facade.name = `${this.elevation.abbr}-${this.elevation.wallFacades.length + 1}`;
      facade.designator = facade.name;
      facade.ref_id = shape.guid;

      this.elevation.wallFacades.push(facade);
    }

    facade.area = shape.meta.measuredArea;
  }
}

export class WindowAnnotatingComponent implements ng.IComponentOptions {
  public controller: ng.Injectable<ng.IControllerConstructor>;

  public bindings: any = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };

  public templateUrl = "src/Estimator/window_annotating.html";

  constructor() {
    this.controller = WindowAnnotatingComponentCtrl;
  }
}
