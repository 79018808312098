import * as React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { EstimateLineItem, IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimate, AddLineItemOption, RemoveLineItemOption } from "app/src/Models/Estimate";
import ProductSelector from "./components/ProductSelector";
import LineItemInput from "./components/LineItemInput";
import track from "react-tracking";
import * as angulartics from "angulartics";
import { Dispatch } from "app2/src/helpers/Analytics";
import { OrgAclType } from "app/src/Models/Org";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { EstimateLineItemComponentCtrl } from "app/src/Estimator/EstimateLineItemComponent";
import { RootActions, RootState } from "app2/src/reducers";
import { ProductRecord } from "app2/src/records/Product";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import LineItemEditorTabs from "app2/src/components/LineItemEditor/components/LineItemEditorTabs";
import { MeasurementRecord } from "app2/src/records/Measurement";
import { measurement } from "app2/src/selectors/measurement.selectors";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";

const mapStateToProps = (state: RootState, ownProps: LineItemEditorProps) => {
  return {
    measurement: measurement(state, { measurementId: parseInt(ownProps.measurementId) }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: LineItemEditorProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface LineItemEditorProps {
  lineItem: IEstimateLineItem;
  estimate: IEstimate;
  measurementId: string;
  controller: EstimateLineItemComponentCtrl;
  $analytics: angulartics.IAnalyticsService;
  estimateGroup?: IEstimateGroup;
}

export interface LineItemEditorState {
  lineItem: IEstimateLineItem;
  forceRender: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & LineItemEditorProps;

@track(
  (props) => {
    return {
      category: "Line Item Editor",
      estimate: props.estimate.id,
      job: props.estimate.job_id,
      org: props.controller.org.id,
      navDisplay: !props.controller.navDisplay,
      lineItemId: props.lineItem.id,
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: (cd) => ({ action: "shown" }),
  },
)
class LineItemEditor extends React.Component<Props, LineItemEditorState> {
  public props: Props;
  public state: LineItemEditorState;
  public eventListener: any;

  constructor(props) {
    super(props);
    const { lineItem, controller } = this.props;
    const editLineItem = EstimateLineItem.fromJSON(lineItem.estimateGroup, lineItem);
    this.state = {
      lineItem: editLineItem,
      forceRender: 0,
    };
    this.eventListener = controller.$rootScope.$on("estimateLineItem.save." + lineItem.id, () => {
      const props = angular.toJson(lineItem.transformRequest());
      const state = angular.toJson(this.state.lineItem.transformRequest());
      if (props === state) {
        this.handleCancel();
        controller.$rootScope.$broadcast("estimateLineItem.confirmed", { continueOn: true });
      } else {
        controller.ConfirmDialog.confirm("Would you like to save your line item?", {})
          .then(() => {
            this.handleSave();
            controller.$rootScope.$broadcast("estimateLineItem.confirmed", { continueOn: true });
          })
          .catch(() => {
            controller.$rootScope.$broadcast("estimateLineItem.confirmed", { continueOn: false });
          });
      }
    });
  }

  public componentWillUnmount() {
    this.eventListener();
  }

  public handleSave = () => {
    this.props.controller.saveLineItem(this.state.lineItem);
  };

  public handleCancel = () => {
    this.props.controller.cancelEdit();
  };

  public handleDelete = () => {
    this.props.controller.removeLineItem();
  };

  public handleNumberInputChange = (value, name) => {
    const { forceRender } = this.state;
    const { estimate } = this.props;
    switch (name) {
      case "base_product_price":
        const base_product_price = _.round(+value, -2);
        this.setState((state: LineItemEditorState) => {
          const editedLineItem: IEstimateLineItem = state.lineItem;
          editedLineItem.base_product_price = base_product_price;
          editedLineItem.calculate(estimate);
          return {
            lineItem: editedLineItem,
            forceRender: forceRender + 1,
          };
        });
        break;
      case "base_labor_price":
        const base_labor_price = _.round(+value, -2);
        this.setState((state: LineItemEditorState) => {
          const editedLineItem: IEstimateLineItem = state.lineItem;
          editedLineItem.base_labor_price = base_labor_price;
          editedLineItem.calculate(estimate);
          return {
            lineItem: editedLineItem,
            forceRender: forceRender + 1,
          };
        });
        break;
      case "quantity":
        const quantity = _.round(+value, -2);
        this.setState((state: LineItemEditorState) => {
          const editedLineItem: IEstimateLineItem = state.lineItem;
          editedLineItem.quantity = quantity;
          editedLineItem.calculate(estimate);
          return {
            lineItem: editedLineItem,
            forceRender: forceRender + 1,
          };
        });
        break;
      default:
        this.setState((state: LineItemEditorState) => {
          const editedLineItem: IEstimateLineItem = state.lineItem;
          editedLineItem[name] = value;
          return {
            lineItem: editedLineItem,
            forceRender: forceRender + 1,
          };
        });
    }
  };

  public handleInputChange = (event) => {
    const { forceRender } = this.state;
    const { estimate } = this.props;
    const value = event.target.value;
    const name = event.target.name;

    this.setState((state: LineItemEditorState) => {
      const editedLineItem: IEstimateLineItem = state.lineItem;
      editedLineItem[name] = value;
      editedLineItem.calculate(estimate);
      return {
        lineItem: editedLineItem,
        forceRender: forceRender + 1,
      };
    });
  };

  public handleProductSelected = (product) => {
    const { forceRender } = this.state;
    const { controller, estimate, estimateGroup } = this.props;
    controller.EventingFactory.trackEvent("select product", { category: "EstimateLineItemComponent" });
    const editedLineItem = this.state.lineItem;
    editedLineItem.quantity = controller.EstimatorService.addMeasurementLink(
      product,
      editedLineItem.quantity,
      true,
      estimateGroup,
    );
    editedLineItem.setProduct(estimate.activated_price_list_id, product, true);
    editedLineItem.calculate(estimate);
    controller.EstimatorService.addDefaultOptions(editedLineItem, product);
    this.setState({
      lineItem: editedLineItem,
      forceRender: forceRender + 1,
    });
  };

  public handleOpeningSelected = (product) => {
    this.props.controller.addOpening(product);
  };

  public handleImportSelected = () => {
    this.props.controller.importSelected();
  };

  public handleUseMeasurement = (value: number, type: string, uom: string, product: ProductRecord): void => {
    const { controller, estimate } = this.props;
    const { lineItem, forceRender } = this.state;

    let quantity = _.clone(lineItem.quantity);
    const convertedValue = controller.EstimatorService.Conversion.convert(value, uom, lineItem.uom, product);

    switch (type) {
      case "+":
        quantity += convertedValue;
        break;
      case "-":
        quantity -= convertedValue;
        break;
      case "use":
        quantity = convertedValue;
        break;
    }

    quantity = _.round(quantity, -2);
    this.setState((state: LineItemEditorState) => {
      const editedLineItem: IEstimateLineItem = state.lineItem;
      editedLineItem.quantity = quantity;
      editedLineItem.calculate(estimate);
      return {
        lineItem: editedLineItem,
        forceRender: forceRender + 1,
      };
    });
  };

  public selectOption = (
    optionGroup: ProductOptionGroupRecord,
    option: ProductOptionRecord,
    quantity: number,
  ): void => {
    let editedLineItem = this.state.lineItem;
    const { controller, estimate } = this.props;
    const { forceRender } = this.state;

    if (optionGroup.selection_mode === "single") {
      if (editedLineItem.existingSingleSelectOption(optionGroup.id)) {
        controller.EventingFactory.trackEvent("product option changed", {
          product_option: option.id,
        });
      } else {
        controller.EventingFactory.trackEvent("product option added", {
          product_option: option.id,
        });
      }
    } else {
      controller.EventingFactory.trackEvent("product option added", {
        product_option: option.id,
      });
    }

    editedLineItem = AddLineItemOption(editedLineItem, option, optionGroup, quantity, estimate);

    this.setState({
      lineItem: editedLineItem,
      forceRender: forceRender + 1,
    });
  };

  public unselectOption = (option: ProductOptionRecord): void => {
    let editedLineItem = this.state.lineItem;
    const { forceRender } = this.state;

    editedLineItem = RemoveLineItemOption(editedLineItem, option.uuid);

    this.props.controller.EventingFactory.trackEvent("product option removed", {
      product_option: option.id,
    });

    this.setState({
      lineItem: editedLineItem,
      forceRender: forceRender + 1,
    });
  };

  public handleQtyChange = (optionGroup, option, qty): void => {
    const { estimate } = this.props;
    const { lineItem, forceRender } = this.state;
    const editedLineItem = lineItem;
    const elio: IEstimateLineItemOption = _.find(editedLineItem.existingOptions(), (e: IEstimateLineItemOption) => {
      return e.product_option_group_id === optionGroup.id && e.product_option_uuid === option.uuid;
    });

    if (elio) {
      elio.quantity = qty;
      elio.calculate(estimate);
      this.props.controller.EventingFactory.trackEvent("product option quantity changed", {
        product_option: elio.id,
        quantity: qty,
      });
      this.setState({
        lineItem: editedLineItem,
        forceRender: forceRender + 1,
      });
    }
  };

  public handleFile = (file): any => {
    const { forceRender } = this.state;
    // Not Ideal - breaks rule of immutable
    return this.props.controller.fileQueue.getObject(file, this.state.lineItem, "both").then(() => {
      this.setState((state: LineItemEditorState) => {
        return {
          lineItem: state.lineItem,
          forceRender: forceRender + 1,
        };
      });
    });
  };

  public handleFileDestroy = (file): any => {
    const { forceRender } = this.state;
    // Not Ideal - breaks rule of immutable
    this.props.controller.onFileDestroy(file).then(() => {
      this.setState((state: LineItemEditorState) => {
        return {
          lineItem: state.lineItem,
          forceRender: forceRender + 1,
        };
      });
    });
  };

  public handleFileChange = (value, file): any => {
    const { forceRender } = this.state;
    // Not Ideal - breaks rule of immutable
    this.props.controller.onFileChange(value, file);
    this.setState((state: LineItemEditorState) => {
      return {
        lineItem: state.lineItem,
        forceRender: forceRender + 1,
      };
    });
  };

  public footerButtons() {
    return (
      <div className="editable-footer">
        <Button id="eli-save-changes" variant="save" className="tour-save-button" onClick={this.handleSave}>
          Save Changes
        </Button>
        <Button id="eli-cancel" variant="cancel" onClick={this.handleCancel}>
          Cancel
        </Button>
        <Button variant="delete" className="pull-right" onClick={this.handleDelete}>
          Delete Item
        </Button>
      </div>
    );
  }

  public render() {
    const { controller, estimate, measurement } = this.props;
    const { lineItem, forceRender } = this.state;
    const full = _.include(controller.org.fetchAcl(OrgAclType.estimator), "full");
    if (full) {
      let productSelector = null;
      if (!this.state.lineItem.isCustom() || this.state.lineItem.newly_added) {
        productSelector = (
          <Row>
            <Col xs={8} sm={8} md={6} lg={4}>
              <ProductSelector
                lineItemProductId={lineItem.product_id}
                controller={this.props.controller}
                activatedPriceListId={estimate.activated_price_list_id}
                handleProductSelected={this.handleProductSelected}
                handleOpeningSelected={this.handleOpeningSelected}
                handleImportSelected={this.handleImportSelected}
              />
            </Col>
          </Row>
        );
      }

      return (
        <div className="line-item-editor">
          {productSelector}
          <LineItemInput
            lineItem={lineItem}
            forceRender={forceRender}
            activatedPriceListId={estimate.activated_price_list_id}
            handleInputChange={this.handleInputChange}
            orgId={controller.org.id}
            handleNumberInputChange={this.handleNumberInputChange}
            full={full}
          />
          <LineItemEditorTabs
            lineItem={lineItem}
            forceRender={forceRender}
            estimate={estimate}
            measurement={measurement}
            controller={controller}
            handleUseMeasurement={this.handleUseMeasurement}
            selectOption={this.selectOption}
            unselectOption={this.unselectOption}
            handleQtyChange={this.handleQtyChange}
            handleFile={this.handleFile}
            handleFileDestroy={this.handleFileDestroy}
            handleFileChange={this.handleFileChange}
          />
          {this.footerButtons()}
        </div>
      );
    } else {
      return (
        <div className="line-item-editor">
          <LineItemInput
            lineItem={lineItem}
            forceRender={forceRender}
            activatedPriceListId={estimate.activated_price_list_id}
            handleInputChange={this.handleInputChange}
            orgId={controller.org.id}
            handleNumberInputChange={this.handleNumberInputChange}
            full={full}
          />
          {this.footerButtons()}
        </div>
      );
    }
  }
}

export default connector(LineItemEditor);
