import * as React from "react";
import { Accordion, Card, Table, Button } from "react-bootstrap";
import { IPretty } from "app/src/Common/PrettyNameService";
import track from "react-tracking";
import { getUoM, keysWithValues, MeasurementRecord } from "app2/src/records/Measurement";
import { RootActions, RootState } from "app2/src/reducers";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { roofFaces } from "app2/src/selectors/roofFace.selectors";
import { wallFacades } from "app2/src/selectors/wallFacade.selectors";
import { windows } from "app2/src/selectors/window.selectors";
import { doors } from "app2/src/selectors/door.selectors";
import { decks } from "app2/src/selectors/deck.selectors";
import { pools } from "app2/src/selectors/pool.selectors";
import { measurementIdFullFencing } from "app2/src/selectors/measurements/fencing.selectors";
import { FencingMeasurementCard } from "./MeasurementCards/FencingMeasurementCard";
import { MeasurementSection } from "app2/src/records/OrgRecord";
import { showMeasurementSection } from "app2/src/selectors/measurement.selectors";
import { StoreRegistry } from "app2/src/storeRegistry";

const mapStateToProps = (state: RootState, ownProps: MeasurementsTabProps) => {
  return {
    roofFaces: roofFaces(state, { roofFaceIds: ownProps.measurement.roof_face_ids }),
    wallFacades: wallFacades(state, { wallFacadeIds: ownProps.measurement.wall_facade_ids }),
    windows: windows(state, { windowIds: ownProps.measurement.window_ids }),
    doors: doors(state, { doorIds: ownProps.measurement.door_ids }),
    decks: decks(state, { deckIds: ownProps.measurement.deck_ids }),
    pools: pools(state, { poolIds: ownProps.measurement.pool_ids }),
    fencing: measurementIdFullFencing(state, { measurementId: ownProps.measurement.id }),
    showMeasurementSection: (section: MeasurementSection) =>
      showMeasurementSection(state, { location: "line_item_editor", section }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: MeasurementsTabProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface MeasurementsTabProps {
  measurement: MeasurementRecord;
  handleUseMeasurement: (value: number, type: string, uom: string) => void;
  tracking?: any;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & MeasurementsTabProps;

@track({ component: "Measurements Tab" })
class MeasurementsTab extends React.Component<Props> {
  public Pretty: IPretty;
  public props: Props;
  public uom: any;
  public roofingKeys: string[];
  public sidingKeys: string[];
  public sunroomKeys: string[];

  constructor(props) {
    super(props);
    const { measurement } = props;

    this.uom = getUoM(measurement);
    this.roofingKeys = keysWithValues(measurement, "roof");
    this.sidingKeys = keysWithValues(measurement, "siding");
    this.sunroomKeys = keysWithValues(measurement, "sunroom");
    this.Pretty = StoreRegistry.get("Pretty");
    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick(value: number, type: string, uom: string) {
    this.props.tracking.trackEvent({
      action: "use measurement",
      quantity: value,
      type: type,
      uom: uom,
    });
    this.props.handleUseMeasurement(value, type, uom);
  }

  public measurementCard(name, rows) {
    return (
      <Card key={name}>
        <Accordion.Toggle className="link" as={Card.Header} eventKey={name}>
          {name}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={name}>
          <Card.Body>
            <Table striped size="sm">
              <thead></thead>
              <tbody>{rows}</tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

  public measurementRow(key) {
    const { measurement } = this.props;

    return (
      <tr key={key}>
        <td>{this.Pretty.name[key]}</td>
        <td>
          {measurement.get(key)} {this.uom[key]}
        </td>
        <td>
          <Button
            className="measurement-minus"
            size="sm"
            onClick={() => {
              this.handleClick(measurement.get(key), "-", this.uom[key]);
            }}>
            -
          </Button>
          <Button
            className="measurement-use"
            size="sm"
            onClick={() => {
              this.handleClick(measurement.get(key), "use", this.uom[key]);
            }}>
            Use
          </Button>
          <Button
            className="measurement-plus"
            size="sm"
            onClick={() => {
              this.handleClick(measurement.get(key), "+", this.uom[key]);
            }}>
            +
          </Button>
        </td>
      </tr>
    );
  }

  public faceRow(face, include_pitch) {
    return (
      <tr key={face.id}>
        <td>{face.designator}</td>
        {include_pitch ? <td>{face.pitch}/12</td> : null}
        <td>
          {face.area} {this.uom["roof_face_area"]}
        </td>
        <td>
          <Button
            className="face-minus"
            size="sm"
            onClick={() => {
              this.handleClick(face.area, "-", this.uom["roof_face_area"]);
            }}>
            -
          </Button>
          <Button
            className="face-use"
            size="sm"
            onClick={() => {
              this.handleClick(face.area, "use", this.uom["roof_face_area"]);
            }}>
            Use
          </Button>
          <Button
            className="face-plus"
            size="sm"
            onClick={() => {
              this.handleClick(face.area, "+", this.uom["roof_face_area"]);
            }}>
            +
          </Button>
        </td>
      </tr>
    );
  }

  public openingRow(opening, include_ui) {
    return (
      <tr key={opening.id}>
        <td>{opening.name}</td>
        <td>
          {opening.width}"x{opening.height}"
        </td>
        {include_ui ? <td>{opening.ui}" UI</td> : null}
        {include_ui ? (
          <td>
            <Button
              className="opening-ui-minus"
              size="sm"
              onClick={() => {
                this.handleClick(opening.ui, "-", "ui");
              }}>
              -
            </Button>
            <Button
              className="opening-ui-use"
              size="sm"
              onClick={() => {
                this.handleClick(opening.ui, "use", "ui");
              }}>
              Use
            </Button>
            <Button
              className="opening-ui-plus"
              size="sm"
              onClick={() => {
                this.handleClick(opening.ui, "+", "ui");
              }}>
              +
            </Button>
          </td>
        ) : null}
        <td>{opening.area} sqft</td>
        <td>
          <Button
            className="opening-area-minus"
            size="sm"
            onClick={() => {
              this.handleClick(opening.area, "-", "sqft");
            }}>
            -
          </Button>
          <Button
            className="opening-area-use"
            size="sm"
            onClick={() => {
              this.handleClick(opening.area, "use", "sqft");
            }}>
            Use
          </Button>
          <Button
            className="opening-area-plus"
            size="sm"
            onClick={() => {
              this.handleClick(opening.area, "+", "sqft");
            }}>
            +
          </Button>
        </td>
      </tr>
    );
  }

  public deckRow(deck) {
    return (
      <tr key={deck.id}>
        <td>{deck.name}</td>
        <td>
          Area: {deck.area} sqft
          <br />
          <Button
            className="deck-area-minus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.area, "-", "sqft");
            }}>
            -
          </Button>
          <Button
            className="deck-area-use"
            size="sm"
            onClick={() => {
              this.handleClick(deck.area, "use", "sqft");
            }}>
            Use
          </Button>
          <Button
            className="deck-area-plus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.area, "+", "sqft");
            }}>
            +
          </Button>
        </td>
        <td>
          Perimeter: {deck.perimeter} lft
          <br />
          <Button
            className="deck-perimeter-minus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.perimeter, "-", "lft");
            }}>
            -
          </Button>
          <Button
            className="deck-perimeter-use"
            size="sm"
            onClick={() => {
              this.handleClick(deck.perimeter, "use", "lft");
            }}>
            Use
          </Button>
          <Button
            className="deck-perimeter-plus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.perimeter, "+", "lft");
            }}>
            +
          </Button>
        </td>
        <td>
          Wall Length: {deck.wall_length} lft
          <br />
          <Button
            className="deck-wl-minus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.wall_length, "-", "lft");
            }}>
            -
          </Button>
          <Button
            className="deck-wl-use"
            size="sm"
            onClick={() => {
              this.handleClick(deck.wall_length, "use", "lft");
            }}>
            Use
          </Button>
          <Button
            className="deck-wl-plus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.wall_length, "+", "lft");
            }}>
            +
          </Button>
        </td>
        <td>
          Railing Length: {deck.railing_length}
          <br />
          <Button
            className="deck-rl-minus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.railing_length, "-", "ea");
            }}>
            -
          </Button>
          <Button
            className="deck-rl-use"
            size="sm"
            onClick={() => {
              this.handleClick(deck.railing_length, "use", "ea");
            }}>
            Use
          </Button>
          <Button
            className="deck-rl-plus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.railing_length, "+", "ea");
            }}>
            +
          </Button>
        </td>
        <td>
          Railing Area: {deck.railing_area}
          <br />
          <Button
            className="deck-ra-minus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.railing_area, "-", "ea");
            }}>
            -
          </Button>
          <Button
            className="deck-ra-use"
            size="sm"
            onClick={() => {
              this.handleClick(deck.railing_area, "use", "ea");
            }}>
            Use
          </Button>
          <Button
            className="deck-ra-plus"
            size="sm"
            onClick={() => {
              this.handleClick(deck.railing_area, "+", "ea");
            }}>
            +
          </Button>
        </td>
      </tr>
    );
  }

  public poolRow(pool) {
    return (
      <tr key={pool.id}>
        <td>{pool.name}</td>
        <td>
          Area: {pool.area} sqft
          <br />
          <Button
            className="pool-area-minus"
            size="sm"
            onClick={() => {
              this.handleClick(pool.area, "-", "sqft");
            }}>
            -
          </Button>
          <Button
            className="pool-area-use"
            size="sm"
            onClick={() => {
              this.handleClick(pool.area, "use", "sqft");
            }}>
            Use
          </Button>
          <Button
            className="pool-area-plus"
            size="sm"
            onClick={() => {
              this.handleClick(pool.area, "+", "sqft");
            }}>
            +
          </Button>
        </td>
        <td>
          Perimeter: {pool.perimeter} lft
          <br />
          <Button
            className="pool-perimeter-minus"
            size="sm"
            onClick={() => {
              this.handleClick(pool.perimeter, "-", "lft");
            }}>
            -
          </Button>
          <Button
            className="pool-perimeter-use"
            size="sm"
            onClick={() => {
              this.handleClick(pool.perimeter, "use", "lft");
            }}>
            Use
          </Button>
          <Button
            className="pool-perimeter-plus"
            size="sm"
            onClick={() => {
              this.handleClick(pool.perimeter, "+", "lft");
            }}>
            +
          </Button>
        </td>
        <td>
          Average Pool Depth: {pool.average_depth} lft
          <br />
          <Button
            className="pool-ad-minus"
            size="sm"
            onClick={() => {
              this.handleClick(pool.average_depth, "-", "lft");
            }}>
            -
          </Button>
          <Button
            className="pool-ad-use"
            size="sm"
            onClick={() => {
              this.handleClick(pool.average_depth, "use", "lft");
            }}>
            Use
          </Button>
          <Button
            className="pool-ad-plus"
            size="sm"
            onClick={() => {
              this.handleClick(pool.average_depth, "+", "lft");
            }}>
            +
          </Button>
        </td>
        <td>
          Gallons: {pool.volume}
          <br />
          <Button
            className="pool-volume-minus"
            size="sm"
            onClick={() => {
              this.handleClick(pool.volume, "-", "ea");
            }}>
            -
          </Button>
          <Button
            className="pool-volume-use"
            size="sm"
            onClick={() => {
              this.handleClick(pool.volume, "use", "ea");
            }}>
            Use
          </Button>
          <Button
            className="pool-volume-plus"
            size="sm"
            onClick={() => {
              this.handleClick(pool.volume, "+", "ea");
            }}>
            +
          </Button>
        </td>
      </tr>
    );
  }

  public showMeasurement(what) {
    return this.props.showMeasurementSection(what);
  }

  public render() {
    const { roofFaces, wallFacades, windows, doors, decks, pools, fencing } = this.props;
    const panels = [];

    if (this.roofingKeys.length > 0 && this.showMeasurement("roofing")) {
      const rows = [];
      this.roofingKeys.map((k) => {
        rows.push(this.measurementRow(k));
      });
      panels.push(this.measurementCard("Roofing", rows));
    }
    if (this.sidingKeys.length > 0 && this.showMeasurement("siding")) {
      const rows = [];
      this.sidingKeys.map((k) => {
        rows.push(this.measurementRow(k));
      });
      panels.push(this.measurementCard("Siding", rows));
    }
    if (windows && windows.size > 0 && this.showMeasurement("windows")) {
      const rows = [];
      windows.map((win) => {
        rows.push(this.openingRow(win, true));
      });
      panels.push(this.measurementCard(`Windows (${rows.length})`, rows));
    }
    if (doors && doors.size > 0 && this.showMeasurement("doors")) {
      const rows = [];
      doors.map((door) => {
        rows.push(this.openingRow(door, false));
      });
      panels.push(this.measurementCard(`Doors (${rows.length})`, rows));
    }
    if (roofFaces && roofFaces.size > 0 && this.showMeasurement("roof_faces")) {
      const rows = [];
      roofFaces.map((rf) => {
        rows.push(this.faceRow(rf, true));
      });
      panels.push(this.measurementCard(`Roof Faces (${rows.length})`, rows));
    }
    if (wallFacades && wallFacades.size > 0 && this.showMeasurement("walls")) {
      const rows = [];
      wallFacades.map((wf) => {
        rows.push(this.faceRow(wf, false));
      });
      panels.push(this.measurementCard(`Walls (${rows.length})`, rows));
    }
    if (this.sunroomKeys.length > 0 && this.showMeasurement("sunroom")) {
      const rows = [];
      this.sunroomKeys.map((k) => {
        rows.push(this.measurementRow(k));
      });
      panels.push(this.measurementCard("Sunroom", rows));
    }
    if (decks && decks.size > 0 && this.showMeasurement("decks")) {
      const rows = [];
      decks.map((deck) => {
        rows.push(this.deckRow(deck));
      });
      panels.push(this.measurementCard(`Decks (${rows.length})`, rows));
    }
    if (pools && pools.size > 0 && this.showMeasurement("pools")) {
      const rows = [];
      pools.map((pool) => {
        rows.push(this.poolRow(pool));
      });
      panels.push(this.measurementCard(`Pools (${rows.length})`, rows));
    }
    if (fencing && fencing.fenceIds.size > 0 && this.showMeasurement("fencing")) {
      panels.push(<FencingMeasurementCard key="fencing" fencing={fencing} handleClick={this.handleClick} />);
    }

    return <Accordion id="measurement_accordian">{panels}</Accordion>;
  }
}

export default connector(MeasurementsTab);
