import { Record } from "immutable";

export const fromJSON = (json: Partial<ISendInviteData>): SendInviteRecord => {
  const data: ISendInviteRecord = { ...(json as any) };

  return new SendInviteRecord(data);
};

export interface ISendInviteData {
  modal: boolean;
  type: "image_uploader" | "screenshare";
  options: any;
  sent: number;
}

export interface ISendInviteRecord {
  modal: boolean;
  type: "image_uploader" | "screenshare";
  options: any;
  sent: number;
}

const defaultSendInviteProps: ISendInviteRecord = {
  modal: false,
  type: "screenshare",
  options: {},
  sent: 0,
};

export class SendInviteRecord extends Record(defaultSendInviteProps) implements ISendInviteRecord {}
