import { ActionsUnion, createAction } from "./Utils";
import { IEstimateGroupData } from "../records/EstimateGroup";
import { List } from "immutable";

export const RECEIVE_ESTIMATE_GROUP = "@estimateGroups/RECEIVE_ESTIMATE";
export const DUPLICATE_ESTIMATE_GROUPS = "@estimateGroups/DUPLICATE_ESTIMATE_GROUPS";
export const UPDATE_BASE_TOTAL = "@estimateGroups/UPDATE_BASE_TOTAL";

export const Actions = {
  receiveEstimateGroup: (estimateGroup: Partial<IEstimateGroupData>) =>
    createAction(RECEIVE_ESTIMATE_GROUP, { estimateGroup }),
  duplicateGroups: (estimateId: number, groupIds: List<number>) =>
    createAction(DUPLICATE_ESTIMATE_GROUPS, { estimateId, groupIds }),
  updateBaseTotal: (estimateGroupId: number) => createAction(UPDATE_BASE_TOTAL, { estimateGroupId }),
};

export type Actions = ActionsUnion<typeof Actions>;
