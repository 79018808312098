import * as React from "react";
import { room as roomSelector, rootKey } from "app2/src/selectors/room.selectors";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { RootState } from "app2/src/reducers";
import { FormControl, RsfForm, Select } from "@tberrysoln/rsf-form";
import { Actions } from "app2/src/reducers/room.actions";
import { RoomMeasurementTable } from "app2/src/components/JobTabs/MeasurementTab/Interiors/RoomMeasurementTable";
import { currentMeasurement, measurementEditing } from "app2/src/selectors/measurement.selectors";
import { pathname, query } from "app2/src/selectors/router.selectors";
import * as FontAwesome from "react-fontawesome";
import { push } from "connected-react-router/immutable";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { Map } from "immutable";
import { useTracking } from "react-tracking";
import { VisualizeRoom } from "../../EstimateTab/RoomSummary/VisualizeRoom";
import { RoomMeasurementInfo } from "app2/src/components/Common/RoomMeasurementInfo";

export const ceiling1 = [
  "ceiling_area",
  "ceiling_area_less_structures",
  "ceiling_perimeter",
  "ceiling_perimeter_less_structures",
];
export const ceiling2 = ["structure_total_ceiling_area", "structure_total_ceiling_width"];

export const flooring1 = [
  "floor_plan_area",
  "floor_area_less_structures",
  "floor_plan_perimeter",
  "floor_plan_perimeter_less_structures",
  "floor_plan_perimeter_without_openings",
];
export const flooring2 = [
  "structure_total_floor_width",
  "structure_total_floor_area",
  "floor_opening_total_width",
  "exposed_structure_perimeter",
];

export const walls1 = [
  "wall_total_area",
  "wall_total_area_without_openings",
  "window_total_area",
  "door_area",
  "opening_count",
];
export const walls2 = [
  "wall_count",
  "opening_total_area",
  "window_count",
  "door_count",
  "structure_total_wall_area",
  "opening_total_width",
  "door_total_width",
];

export const general = ["object_count", "volume"];

export const Room: React.FC = () => {
  // Local State
  const [accordionControl, setAccordionControl] = React.useState(
    Map({ ceiling: "ceiling", flooring: "", walls: "", general: "" }),
  );

  // Hooks
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "Room", room: match.params.roomId });
  const [initRsfForm, setInitRsfForm] = React.useState(false);

  // Selectors
  const room = useSelector((state: RootState) => roomSelector(state, { roomId: match.params.roomId }));
  const editing = useSelector(measurementEditing);
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const queryParams = useSelector(query);
  const rootPathname = useSelector(pathname);

  // Methods
  const openAddMeasurement = () => {
    trackEvent({ action: "open add measurement" });
    const newQueryParams = new URLSearchParams({
      ...queryParams.toJS(),
      add: "rooms",
    });
    dispatch(push(`${rootPathname}?${newQueryParams.toString()}`));
  };
  const destroyRoom = () => {
    trackEvent({ action: "destroy room" });
    dispatch(Actions.updateForm({ rootPath: [rootKey, "byId", room.id.toString()], name: "_destroy", value: true }));
    dispatch(push(`${rootPathname.split("/interior")[0]}/interior`));
  };
  const toggleAccordion = (key: string) => {
    if (accordionControl.get(key) === "") {
      trackEvent({ action: "open accordion", accordionName: key });
      setAccordionControl(accordionControl.set(key, key));
    } else {
      trackEvent({ action: "close accordion", accordionName: key });
      setAccordionControl(accordionControl.set(key, ""));
    }
  };

  // Life Cycle
  React.useEffect(() => {
    setInitRsfForm(true);
    setTimeout(() => {
      setInitRsfForm(false);
    }, 50);
  }, [match.params.roomId]);

  if (!room || room._destroy || initRsfForm) {
    return null;
  }

  const roomEstimationTitle = `The room ${room.name} is associated with an estimate. Deletion is disabled`;

  return (
    <RsfForm
      rootPath={[rootKey, "byId", match.params.roomId]}
      updateFormReducer={Actions.updateForm}
      onSubmit={() => {}}>
      <Row>
        <Col md={6}>
          <h2>{room.name}</h2>
        </Col>
        <Col>
          {editing && (
            <>
              {room.room_estimations.size > 0 ? (
                <ConfirmDialog title={roomEstimationTitle} hideCancel>
                  {(confirm) => (
                    <Button
                      variant="info"
                      className="float-right"
                      onClick={confirm(() => {})}
                      title={roomEstimationTitle}>
                      <FontAwesome name="info-circle" />
                    </Button>
                  )}
                </ConfirmDialog>
              ) : (
                <ConfirmDialog title={`Are you sure you want to delete: ${room.name}?`}>
                  {(confirm) => (
                    <Button variant="delete" className="float-right" onClick={confirm(destroyRoom)}>
                      <FontAwesome name="trash" />
                    </Button>
                  )}
                </ConfirmDialog>
              )}
            </>
          )}
          <div className="d-flex align-items-center float-right mr-1">
            <RoomMeasurementInfo />
            {room.viewer_url && <VisualizeRoom url={room.viewer_url} />}

            <Button
              variant="primary"
              onClick={openAddMeasurement}
              disabled={editing}
              title={editing ? "Disabled for editing" : ""}>
              <FontAwesome name="plus" />
              &nbsp;Add
            </Button>
          </div>
        </Col>
      </Row>

      {editing ? (
        <>
          <Row>
            <Col md={6}>
              <FormControl label="name" name="name" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Select label="level" name="level">
                {measurement.get("levels").map((level, idx) => (
                  <option key={idx} value={level}>
                    {level}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <p>{`Level ${room.level}`}</p>
          </Col>
        </Row>
      )}

      <Accordion activeKey={accordionControl.get("ceiling")}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="ceiling"
            onClick={() => toggleAccordion("ceiling")}>
            Ceiling
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("ceiling") !== "" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="ceiling">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <RoomMeasurementTable values={ceiling1} roomId={match.params.roomId} />
                </Col>
                <Col lg={6}>
                  <RoomMeasurementTable values={ceiling2} roomId={match.params.roomId} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <br />
      <Accordion activeKey={accordionControl.get("flooring")}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="flooring"
            onClick={() => toggleAccordion("flooring")}>
            Flooring
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("flooring") !== "" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="flooring">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <RoomMeasurementTable values={flooring1} roomId={match.params.roomId} />
                </Col>
                <Col lg={6}>
                  <RoomMeasurementTable values={flooring2} roomId={match.params.roomId} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <br />
      <Accordion activeKey={accordionControl.get("walls")}>
        <Card>
          <Accordion.Toggle className="link" as={Card.Header} eventKey="walls" onClick={() => toggleAccordion("walls")}>
            Walls
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("walls") !== "" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="walls">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <RoomMeasurementTable values={walls1} roomId={match.params.roomId} />
                </Col>
                <Col lg={6}>
                  <RoomMeasurementTable values={walls2} roomId={match.params.roomId} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <br />
      <Accordion activeKey={accordionControl.get("general")}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="general"
            onClick={() => toggleAccordion("general")}>
            General
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("general") !== "" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="general">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <RoomMeasurementTable values={general} roomId={match.params.roomId} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </RsfForm>
  );
};
