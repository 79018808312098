import { recordChanged } from "app2/src/helpers/Record";
import { RootState } from "app2/src/reducers";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { Map } from "immutable";
/**
 * Loads whether an integration is authorized
 *
 * @param {RootState} state The RootState
 * @param {{ orgId: number, integration: string }} options
 * @returns {boolean} boolean
 */
export const getAuthorization = (state: RootState, props: any) =>
  state.getIn(["tokens", "authByOrgId", props.orgId, "byKind", props.integration, "authorized"], false);

/**
 * Loads token by kind and current org
 *
 * @param {RootState} state The RootState
 * @param {{ kind: string }} options
 * @returns {TokenRecord} TokenRecord
 */
export const token = (state: RootState, props: any) =>
  state.getIn(["tokens", "byOrgId", currentOrgId(state), props.kind]);

/**
 * Loads last saved token by kind and current org
 *
 * @param {RootState} state The RootState
 * @param {{ kind: string }} options
 * @returns {TokenRecord} TokenRecord
 */
export const lastSavedToken = (state: RootState, props: any) =>
  state.getIn(["tokens", "lastSavedByOrgId", currentOrgId(state), props.kind]);

/**
 * Did the token change? Did not use createSelector since ID is not passed in as a prop
 *
 * @param {RootState} state The RootState
 * @param {{ kind: string }} options
 * @returns {boolean}
 */
export const tokenChanged = (state: RootState, props: any) =>
  recordChanged(token(state, props), lastSavedToken(state, props));

/**
 * Loads  the authorized state of all the tokens for the current org
 *
 * @param {RootState} state The RootState
 * @returns {Map<any>} The object containing authorization details for tokens
 */
export const getAuthorizedTokens = (state: RootState) =>
  state.getIn(["tokens", "authByOrgId", currentOrgId(state), "byKind"], Map({}));
