import { IOrg } from "../Models/Org";
import { IJob } from "../Models/Job";
import { IJobFetcherService } from "../Jobs/JobFetcherService";
import { HandlebarsHelperService } from "../Patterns/HandlebarsHelperService";
import { IStateService } from "angular-ui-router";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import { jobDocumentCount, jobImageCount, selectedEstimateId } from "app2/src/selectors/job.selectors";
import { push } from "connected-react-router/immutable";

export interface IMenuItem {
  id: string;
  newJobVisible?: boolean;
  analyticsEvent?: string;
  pageUrl?: string;
  iconImg?: string;
  label?: string;
  hideInPresentMode?: boolean;
  navigate?: () => void;
  badge?: () => number;
}

export interface ISection {
  class: string;
  label: string;
  renderedLabel?: string;
  menuItems: IMenuItem[];
}

export interface ISideBarMenu {
  sections: ISection[];
}

export interface IJobMenuOrderService {
  buildTabs(org: IOrg, job: IJob);

  gotoEstimateTab(resource: string);
  available(org: IOrg, job: IJob);
  countDocsNotSigned(job: IJob);
  countDesignTools(job: IJob);
  countPresentations(org: IOrg, job: IJob);
}

export class JobMenuOrderService {
  public static $inject = ["$state", "JobFetcher", "HandlebarsHelperService"];

  constructor(
    protected $state: IStateService,
    protected JobFetcher: IJobFetcherService,
    protected HandlebarsHelperService: HandlebarsHelperService,
  ) {}

  public navigate(path, job) {
    dispatch(push(`/jobs/${job.id}/${path}`));
  }

  public buildTabs(org, job) {
    const available: IMenuItem[] = this.available(org, job);
    const menu: ISideBarMenu = JSON.parse(angular.toJson(org.preferences.config.job_menu));

    const templateSettings = {
      estimates_title: org.estimateTitle(true),
      estimate_title: org.estimateTitle(false),
      contracts_title: org.contractTitle(true),
      contract_title: org.contractTitle(false),
      estimators_title: org.estimatorTitle(true),
      estimator_title: org.estimatorTitle(false),
      org_name: org.name,
    };

    menu.sections = _.filter(menu.sections, (section: ISection) => {
      if (!section.class) {
        section.class = "yellow";
      }

      try {
        section.renderedLabel = this.HandlebarsHelperService.compile(section.label, templateSettings);
      } catch (e) {
        section.renderedLabel = section.label;
      }

      _.each(section.menuItems, (menuItem: IMenuItem, idx) => {
        const config: IMenuItem = _.find(available, (mi) => mi.id === menuItem.id);
        section.menuItems[idx] = { ...config, ...menuItem };
      });

      if (!job) {
        section.menuItems = _.filter(section.menuItems, (menuItem) => {
          return menuItem.newJobVisible;
        });
      }

      return section.menuItems.length > 0;
    });

    return menu;
  }

  public countDocsNotSigned(job): number {
    if (!job) {
      return 0;
    }
    return useSelector((state) => jobDocumentCount(state, { jobId: job.id }));
  }

  public countPresentations(org, job): number {
    if (!job || !org || !org.presentation_count) {
      return 0;
    }

    return org.presentation_count;
  }

  public countDesignTools(job): number {
    if (!job || !job.visualizations) {
      return 0;
    }
    return job.visualizations.length;
  }

  public gotoEstimateTab(resource, job) {
    let path = resource;

    const estimateId = useSelector((state) => selectedEstimateId(state));
    if (estimateId) {
      path = `${path}/${estimateId}`;
    }

    this.navigate(path, job);
  }

  public buildEstimateContractTitle(org) {
    if (!org) {
      return;
    }

    return `${org.estimateTitle(false)} & ${org.contractTitle(false)}`;
  }

  public available(org: IOrg, job: IJob) {
    return [
      {
        id: "information",
        newJobVisible: true,
        analyticsEvent: "Information",
        pageUrl: "/info",
        iconImg: "/assets/images/icons/i_customer-info.4627f852.png",
        label: "Information",
        navigate: () => {
          if (job) {
            this.navigate("info", job);
          }
        },
      },
      {
        id: "appointments",
        newJobVisible: false,
        analyticsEvent: "Appointments",
        pageUrl: "/appointments",
        iconImg: "/assets/images/icons/i_customer-appts.54b57644.png",
        label: "Appointments",
        badge: () => job.appointments.length,
        navigate: () => this.navigate("appointments", job),
      },
      {
        id: "tools",
        newJobVisible: false,
        analyticsEvent: "Tools",
        pageUrl: "/tools",
        iconImg: "/assets/images/icons/i_customer-tools.fb727a50.png",
        label: "Tools",
        navigate: () => this.navigate("tools", job),
      },
      {
        id: "images",
        newJobVisible: false,
        analyticsEvent: "Photos",
        pageUrl: "/images",
        iconImg: "/assets/images/icons/i_customer-photos.26dc1b4c.png",
        label: "Photos",
        badge: () => useSelector((state) => jobImageCount(state, { jobId: job.id })),
        navigate: () => this.navigate("images", job),
      },
      {
        id: "documents",
        newJobVisible: false,
        analyticsEvent: "Documents",
        pageUrl: "/documents",
        iconImg: "/assets/images/icons/i_customer-docs.2eb4235f.png",
        label: "Documents",
        badge: () => this.countDocsNotSigned(job),
        navigate: () => this.navigate("documents", job),
      },
      {
        id: "measurements",
        newJobVisible: false,
        analyticsEvent: "Measurements",
        pageUrl: "/measurements",
        iconImg: "/assets/images/icons/i_customer-measure.75ad2262.png",
        label: "Measurements",
        navigate: () => this.navigate("measurements", job),
      },
      {
        id: "notes",
        newJobVisible: false,
        analyticsEvent: "Notes",
        pageUrl: "/notes",
        iconImg: "/assets/images/icons/i_customer-notes.b9bf3aaa.png",
        label: "Notes",
        hideInPresentMode: true,
        badge: () => job.notes.length,
        navigate: () => this.navigate("notes", job),
      },
      {
        id: "history",
        newJobVisible: false,
        analyticsEvent: "History",
        pageUrl: "/history",
        iconImg: "/assets/images/icons/i_customer-history.e28799a3.png",
        label: "History",
        hideInPresentMode: true,
        navigate: () => this.navigate("history", job),
      },
      {
        id: "presentations",
        newJobVisible: false,
        analyticsEvent: "Presentations",
        pageUrl: "/presentations",
        iconImg: "/assets/images/icons/i_presentation-present.ca10d2bd.png",
        label: "Presentations",
        badge: () => this.countPresentations(org, job),
        navigate: () => this.navigate("presentations", job),
      },
      {
        id: "design_tools",
        newJobVisible: false,
        analyticsEvent: "Design Tools",
        pageUrl: "/design-tools",
        iconImg: "/assets/images/icons/i_presentation-design.06966acd.png",
        label: "Design Tools",
        badge: () => this.countDesignTools(job),
        navigate: () => this.navigate("design-tools", job),
      },
      {
        id: "inspection",
        newJobVisible: false,
        analyticsEvent: "Inspection",
        pageUrl: "/inspection",
        iconImg: "/assets/images/inspection.2131a823.png",
        label: "Inspection",
        navigate: () => this.navigate("inspection", job),
      },
      {
        id: "estimates",
        newJobVisible: false,
        analyticsEvent: "Estimates",
        pageUrl: "/estimates",
        iconImg: "/assets/images/icons/i_presentation-estimate.93212a6d.png",
        label: org.estimatorTitle(false),
        badge: () => job.estimates.length,
        navigate: () => this.gotoEstimateTab("estimates", job),
      },
      {
        id: "proposals",
        newJobVisible: false,
        analyticsEvent: "Proposals",
        pageUrl: "/proposals",
        iconImg: "/assets/images/icons/i_contracts-proposals.23a15c54.png",
        label: org.estimateTitle(false),
        navigate: () => this.navigate("proposals", job),
      },
      {
        id: "contracts",
        newJobVisible: false,
        analyticsEvent: "Contracts",
        pageUrl: "/contracts",
        iconImg: "/assets/images/icons/i_contracts-agreement.7f7d6227.png",
        label: org.contractTitle(false),
        navigate: () => this.navigate("contracts", job),
      },
      {
        id: "signatures",
        newJobVisible: false,
        analyticsEvent: "Signatures",
        pageUrl: "/signatures",
        iconImg: "/assets/images/icons/i_signatures.ecd30c5e.png",
        label: "Signatures",
        navigate: () => this.navigate("signatures", job),
      },
    ];
  }
}
