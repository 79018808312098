import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IPresentation extends ng.resource.IResource<IPresentation>, PresentationPrototype {
  $update(): ng.IPromise<IPresentation>;
  $update(params?: Object, success?: Function, error?: Function): ng.IPromise<IPresentation>;
  $update(success: Function, error?: Function): ng.IPromise<IPresentation>;
  $cover_image(): ng.IPromise<IPresentation>;
  $create(): ng.IPromise<IPresentation>;
}

export interface IPresentationResource extends ng.resource.IResourceClass<IPresentation>, IRsfResource {
  fromJSON?(data: any): IPresentation;
}

export interface IPresentationResponse {
  images: IPresentation[];
  meta: any;
}

export interface IPresentationFile {
  url: string;
}

class PresentationPrototype {
  public id: number;
  public name: string;
  public file: IPresentationFile;
  public cover_image: IPresentationFile;
  public html: string;
  public sort_order: number;
  public url: string;
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IPresentationResource => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/presentations/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IPresentation => {
    if (status < 200 || status > 299) {
      return new Presentation({});
    }

    return Presentation.fromJSON(JSON.parse(response).presentation);
  };

  const multipleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IPresentationResponse => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);

    _.each(data.presentations, (i: any, index: number) => {
      data.presentations[index] = Presentation.fromJSON(i);
    });

    return data;
  };

  const transformRequest = (presentation: IPresentation): string => {
    const data = JSON.decycle(presentation);

    return angular.toJson({ presentation: data });
  };

  const Presentation: IPresentationResource = <IPresentationResource>$resource(
    url,
    { id: "@id", org_id: "@org_id" },
    {
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      cover_image: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: url + "/cover_image",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
    },
  );

  Presentation.fromJSON = (data: any): IPresentation => {
    return new Presentation(data);
  };

  Presentation.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Presentation;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
