import * as financeOptionActions from "./financeOption.actions";
import { FinanceOptionRecord, fromJSON } from "app2/src/records/FinanceOption";
import { Map, Record, List } from "immutable";
import { RootState, RootActions, InitRootState } from "app2/src/reducers";

export interface IOrgFinanceOptionsRecord {
  financeOptions: List<number>;
  errors: List<string>;
  loading: boolean;
}
export const OrgFinanceOptionsRecord: Record.Factory<IOrgFinanceOptionsRecord> = Record<IOrgFinanceOptionsRecord>({
  financeOptions: List<number>(),
  errors: List<string>(),
  loading: false,
});

export const FinanceOptionStateRecord = Record({
  byId: Map<number, FinanceOptionRecord>(),
  byOrgId: Map<number, List<typeof OrgFinanceOptionsRecord>>(),
});

export const initialState = FinanceOptionStateRecord();
export type FinanceOptionState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.getIn(["orgs", "financeOptions"])) {
    state = state.setIn(["orgs", "financeOptions"], initialState);
  }
  switch (action.type) {
    case financeOptionActions.RECEIVE_FINANCE_OPTION:
      const financeOption = fromJSON({ ...action.payload, loading: false });

      if (!state.getIn(["orgs", "financeOptions", "byOrgId", financeOption.org_id])) {
        state = state.setIn(["orgs", "financeOptions", "byOrgId", financeOption.org_id], OrgFinanceOptionsRecord({}));
      }

      return state.setIn(["orgs", "financeOptions", "byId", financeOption.id], financeOption).setIn(
        ["orgs", "financeOptions", "byOrgId", financeOption.org_id, "financeOptions"],
        //@ts-ignore
        state
          .getIn(["orgs", "financeOptions", "byOrgId", financeOption.org_id, "financeOptions"], List<number>())
          .push(financeOption.id)
          .toSet()
          .toList(),
      );
    case financeOptionActions.RECEIVE_FINANCE_OPTION_ERROR:
      return state
        .setIn(
          ["orgs", "financeOptions", "byId", action.payload.financeOptionId, "errors"],
          List<string>(action.payload.errors),
        )
        .setIn(["orgs", "financeOptions", "byId", action.payload.financeOptionId, "loading"], false);
    case financeOptionActions.FETCH_FINANCE_OPTION:
      if (state.getIn(["orgs", "financeOptions", "byId", action.payload.financeOptionId])) {
        return state.setIn(["orgs", "financeOptions", "byId", action.payload.financeOptionId, "loading"], true);
      }

      return state.setIn(
        ["orgs", "financeOptions", "byId", action.payload.financeOptionId],
        fromJSON({ id: action.payload.financeOptionId, org_id: action.payload.orgId, loading: true }),
      );
    case financeOptionActions.FETCH_ORG_FINANCE_OPTIONS:
      if (!state.getIn(["orgs", "financeOptions", "byOrgId", action.payload])) {
        return state.setIn(
          ["orgs", "financeOptions", "byOrgId", action.payload],
          OrgFinanceOptionsRecord({ loading: true }),
        );
      }

      return state.setIn(["orgs", "financeOptions", "byOrgId", action.payload, "loading"], true);
    case financeOptionActions.RECEIVE_ORG_FINANCE_OPTIONS:
      state = state.setIn(["orgs", "financeOptions", "byOrgId", action.payload.orgId, "financeOptions"], List([]));
      action.payload.financeOptions.forEach((d) => {
        state = reducer(state, financeOptionActions.Actions.receiveFinanceOption(d));
      });

      return state.setIn(["orgs", "financeOptions", "byOrgId", action.payload.orgId, "loading"], false);
    case financeOptionActions.RECEIVE_ORG_ERRORS:
      return state
        .setIn(
          ["orgs", "financeOptions", "byOrgId", action.payload.orgId, "errors"],
          List<string>(action.payload.errors),
        )
        .setIn(["orgs", "financeOptions", "byOrgId", action.payload.orgId, "loading"], false);
    case financeOptionActions.REMOVE_FINANCE_OPTION:
      return state.removeIn(["orgs", "financeOptions", "byId", action.payload]);
    case financeOptionActions.REMOVE_ORG_FINANCE_OPTION:
      return state.updateIn(
        ["orgs", "financeOptions", "byOrgId", action.payload.orgId, "financeOptions"],
        (financeOptions: List<number>) => {
          return financeOptions.filter((d) => d !== action.payload.financeOptionId);
        },
      );
    default:
      return state;
  }
};
