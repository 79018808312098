import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import track from "react-tracking";
import { push, replace } from "connected-react-router/immutable";
import { List, Map } from "immutable";
import { currentJobId, currentJobOrgId } from "app2/src/selectors/job.selectors";
import { Switch, Route } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";
import { AsyncActions } from "app2/src/reducers/financeApplication.actions";
import { AsyncActions as EstimateAsyncActions } from "app2/src/reducers/estimate.actions";
import { financeApplication } from "app2/src/selectors/financeApplication.selectors";
import HomevestModal from "app2/src/components/Homevest/HomevestModal";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import Create from "app2/src/components/Homevest/Create";
import { Apply } from "app2/src/components/Homevest/Apply";
import { LoanApply } from "app2/src/components/Homevest/LoanApply";
import { Offers } from "app2/src/components/Homevest/Offers/";
import { estimate } from "app2/src/selectors/estimate.selectors";
import { estimateFinanceOptions } from "./Homevest.selectors";
import { Context, ContextRecord, FilterAction, emptyFilter } from "app2/src/components/Homevest/Context";

const mapStateToProps = (state: RootState, ownProps: HomevestProps) => {
  const estimateId = parseInt(ownProps.match.params.estimateId);
  return {
    currentJobId: currentJobId(state),
    financeApplication: financeApplication(state, { estimateId }),
    estimate: estimate(state, { estimateId }),
    orgId: currentJobOrgId(state),
    offers: estimateFinanceOptions(state, { estimateId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: HomevestProps) => {
  const estimateId = parseInt(ownProps.match.params.estimateId);
  return {
    loadEstimate: () => dispatch(EstimateAsyncActions.getEstimate(estimateId)),
    loadFinanceApplication: () => dispatch(AsyncActions.loadFinanceApplication(estimateId)),
    push: (path: string) => dispatch(push(path)),
    replace: (path: string) => dispatch(replace(path)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface HomevestState {
  payment: [number, number];
  term: [number, number];
  merchant: [number, number];
  reloadFinanceAppRef: "inactive" | "processing" | "reload" | "pending";
}

interface HomevestProps extends RouteComponentProps<{ estimateId: string }> {
  tracking?: any;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HomevestProps;

@track(
  (props) => {
    return {
      component: "Homevest",
      estimate: props.match.params.estimateId,
    };
  },
  {
    dispatchOnMount: true,
  },
)
class Homevest extends React.Component<Props, HomevestState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      payment: [0, 0],
      term: [0, 0],
      merchant: [0, 0],
      reloadFinanceAppRef: "inactive",
    };

    this.filterReducer = this.filterReducer.bind(this);
  }

  public componentDidMount(): void {
    const { loadFinanceApplication, loadEstimate } = this.props;

    loadFinanceApplication();
    loadEstimate();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const { match, financeApplication, currentJobId, replace, loadFinanceApplication, loadEstimate } = this.props;
    const { reloadFinanceAppRef } = this.state;
    const estimateId = parseInt(match.params.estimateId);

    if (!financeApplication) {
      return;
    }

    if (
      !financeApplication?.get("loading") &&
      !financeApplication?.equals(prevProps.financeApplication) &&
      reloadFinanceAppRef !== "pending"
    ) {
      if (financeApplication.get("id") === 0 && financeApplication.get("loan_status") === "none") {
        replace(`/jobs/${currentJobId}/estimates/${estimateId}/homevest/create`);
      } else if (financeApplication.get("id") > 0 && financeApplication.get("loan_status") === "created") {
        window.open(financeApplication.get("redirect_url", ""), "_blank");
        replace(`/jobs/${currentJobId}/estimates/${estimateId}`);
      } else if (financeApplication.get("id") > 0) {
        replace(`/jobs/${currentJobId}/estimates/${estimateId}/homevest/offers`);
      } else {
        replace(`/jobs/${currentJobId}/estimates/${estimateId}`);
      }
    }
  }

  public filterReducer(
    action: FilterAction | "financeApplicationReload" | "pendingApproval",
    payload?: [number, number],
  ): void {
    const { financeApplication, estimate, loadEstimate, loadFinanceApplication } = this.props;
    switch (action) {
      case "financeApplicationReload":
        if (!financeApplication.get("loading", true)) {
          loadFinanceApplication();
        }

        if (!estimate.get("loading", true)) {
          loadEstimate();
        }
        return;
      case "pendingApproval":
        if (!financeApplication.get("loading", true)) {
          loadFinanceApplication();
        }
        this.setState({ reloadFinanceAppRef: "pending" });
        return;
      case "merchant":
      case "payment":
      case "term":
        const nextState = {};
        nextState[action] = payload;

        this.setState(nextState);
        return;
    }
  }

  public render(): React.ReactNode {
    const { match, financeApplication, estimate, orgId, offers } = this.props;

    return (
      <Context.Provider
        value={
          new ContextRecord({
            dispatch: this.filterReducer,
            filters: Map<FilterAction, List<number>>([
              ["payment", List(this.state.payment)],
              ["term", List(this.state.term)],
              ["merchant", List(this.state.merchant)],
            ]),
            financeApplication,
            estimate,
            orgId,
            offers,
          })
        }>
        <HomevestModal>
          <>
            {financeApplication?.get("loading") ? <SpinnerComponent localProperty={true} /> : null}
            <Switch>
              <Route path={`${match.url}/create`} component={Create} />
            </Switch>
            <Switch>
              <Route path={`${match.url}/apply`} component={Apply} />
            </Switch>
            <Switch>
              <Route path={`${match.url}/offers`} component={Offers} />
            </Switch>
            <Switch>
              <Route path={`${match.url}/loan_apply`} component={LoanApply} />
            </Switch>
          </>
        </HomevestModal>
      </Context.Provider>
    );
  }
}

export default connector(withRouter(Homevest));
