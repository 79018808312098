import * as React from "react";
import { Card, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";
import { getOrgEventResults } from "app2/src/selectors/eventResult.selectors";
import { getOrgEventResultReasons } from "app2/src/selectors/eventResultReason.selectors";
import { EventResultRecord } from "app2/src/records/EventResult";
import { EventResultReasonRecord } from "app2/src/records/EventResultReason";
import { List } from "immutable";
import { Select } from "@tberrysoln/rsf-form";
import { required } from "app2/src/helpers/FinalFormValidator";
import { SalesOpportunityRecord } from "app2/src/records/EventMetadata";

interface I360SalesOpportunityProps {
  opportunity: SalesOpportunityRecord;
  idx: number;
}

export const I360SalesOpportunity: React.FC<I360SalesOpportunityProps> = ({ opportunity, idx }) => {
  // Selectors
  const orgId = useSelector(currentOrgId);
  const eventsResultId = opportunity?.get("result_id") || -1;
  const results: List<EventResultRecord> = useSelector((state: RootState) =>
    getOrgEventResults(state, { orgId, kind: "sales_opportunity" }),
  );
  const reasons: List<EventResultReasonRecord> = useSelector((state: RootState) =>
    getOrgEventResultReasons(state, {
      orgId,
      kind: "sales_opportunity",
      eventsResultId,
    }),
  );

  return (
    <Col key={opportunity.get("id")} sm={4}>
      <Card className="mb-4">
        <Card.Header>{opportunity.get("name")}</Card.Header>
        <Card.Body>
          <Select name={`metadata.sales_opportunities.${idx}.result_id`} label="Result" validate={required}>
            <option value={null} key={null}></option>
            {results.map((result) => (
              <option value={result.id} key={result.id}>
                {result.description}
              </option>
            ))}
          </Select>
          <Select
            name={`metadata.sales_opportunities.${idx}.result_reason_id`}
            label="Reason"
            disabled={reasons.size === 0}>
            <option value={null} key={null}></option>
            {reasons.map((reason) => (
              <option value={reason.id} key={reason.id}>
                {reason.description}
              </option>
            ))}
          </Select>
        </Card.Body>
      </Card>
    </Col>
  );
};
