import * as React from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";

interface ProcessSelectorProps {
  processes: any;
  processKey: string;
  formData: any;
  onChange: (feedback: any) => void;
  onSubmit: (feedback: any) => void;
  onError: (feedback: any) => void;
}

export default class ProcessSelector extends React.Component<ProcessSelectorProps> {
  public constructor(props: ProcessSelectorProps) {
    super(props);
  }

  public render() {
    const { processes, processKey, formData, onChange, onSubmit, onError } = this.props;

    return (
      <React.Fragment>
        <Modal.Header>Process Selector - {processes.list[processKey].name}</Modal.Header>
        <Modal.Body>
          <Form
            schema={processes.list[processKey].schema}
            uiSchema={processes.list[processKey].uiSchema}
            formData={formData}
            onChange={onChange}
            onSubmit={onSubmit}
            onError={onError}
            validator={(() => null) as any}
            noValidate={true}
          />
        </Modal.Body>
      </React.Fragment>
    );
  }
}
