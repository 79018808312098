import { Map, Record, List, fromJS } from "immutable";

export const fromJSON = (json: Partial<IPreferenceData>): PreferenceRecord => {
  const record: IPreferenceRecord = { ...(json as any) };

  record.acl = fromJS(json.acl);
  record.config = fromJS(json.config);

  return new PreferenceRecord(record);
};

export const toJSON = (preference: PreferenceRecord): IPreferenceData => {
  const json: IPreferenceData = preference.toJS();

  if (json.id === 0) {
    delete json.id;
  }

  if (json.org_id === 0) {
    delete json.org_id;
  }

  return json;
};

export type PreferenceAclKeys = "reports" | "reports_columns" | "estimator" | "tools";
export type PreferenceAclValues = "eagleview";
export type PreferenceConfigKeys =
  | "report_emails"
  | "job_menu"
  | "signatures"
  | "cronofy"
  | "layout"
  | "screen_share"
  | "billing"
  | "cover_page"
  | "document_signed_notification"
  | "titles"
  | "integrations"
  | "document_order"
  | "photos"
  | "estimator"
  | "send_email"
  | "measurement"
  | "sales_tax"
  | "marketsharp"
  | "price_presentation"
  | "markup"
  | "timezone"
  | "signed_documents";

export interface IPreferenceData {
  id: number;
  org_id: number;
  acl: { [key in PreferenceAclKeys]?: PreferenceAclValues[] };
  config: { [key in PreferenceConfigKeys]?: any };
  created_at: string;
  updated_at: string;
}

export interface IPreferenceRecord {
  id: number;
  org_id: number;
  acl: Map<PreferenceAclKeys, List<string>>;
  config: Map<PreferenceConfigKeys, any>;
  created_at: string;
  updated_at: string;
}

const defaultPreferenceProps: IPreferenceRecord = {
  id: 0,
  org_id: 0,
  acl: Map<PreferenceAclKeys, List<string>>(),
  config: Map<PreferenceConfigKeys, any>(),
  created_at: "",
  updated_at: "",
};

export class PreferenceRecord extends Record(defaultPreferenceProps) implements IPreferenceRecord {
  public readonly id!: number;
  public readonly org_id!: number;
  public readonly acl!: Map<PreferenceAclKeys, List<string>>;
  public readonly config!: Map<PreferenceConfigKeys, any>;
  public readonly created_at!: string;
  public readonly updated_at!: string;

  public constructor(values?: Partial<IPreferenceRecord>) {
    values ? super(values) : super();
  }
}
