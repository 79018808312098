import * as React from "react";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Context } from "app2/src/components/SignatureRequest/Context";
import { Document } from "app2/src/components/SignatureRequest/DocumentsChooser/Document";
import { Sortable } from "app2/src/components/Common/Sortable";

export const SelectedDocuments: React.FunctionComponent = () => {
  // hooks
  const { documentIds, dispatch: contextDispatch } = useContext(Context);

  // methods
  const onDragEnd = (oldIndex, newIndex) => {
    contextDispatch("onDragEnd", { oldIndex, newIndex });
  };

  return (
    <Row id="selected_documents_table">
      <Col>
        <Sortable
          dragHandle
          items={documentIds}
          onDragEnd={onDragEnd}
          renderItem={({ item, dragHandle, ...props }) => {
            return <Document dragHandle={dragHandle} key={item} documentId={item} mode="removing" />;
          }}
        />
      </Col>
    </Row>
  );
};
