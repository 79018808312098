import { Record } from "immutable";
import { Nullable } from ".";

export type PhoneNumberSmsStatus = "subscribed" | "unsubscribed";
export const fromJSON = (json: Partial<IPhoneNumberData>): PhoneNumberRecord => {
  return new PhoneNumberRecord(json);
};

export const toJSON = (phoneNumber: PhoneNumberRecord): IPhoneNumberData => {
  const pn = phoneNumber.toJSON() as IPhoneNumberData;

  if (!pn._destroy && pn._destroy !== undefined) {
    delete pn._destroy;
  }

  if (pn.name === "" && pn.number === "") {
    pn._destroy = true;
  }

  if (pn.id < 0) {
    delete pn.id;
  }

  return pn;
};

export interface IPhoneNumberData {
  id: number;
  name: string;
  number: string;
  status: PhoneNumberSmsStatus;

  created_at: Date;
  updated_at: Date;

  _destroy?: boolean;
}

export interface IPhoneNumberRecord {
  id: Nullable<number>;
  name: Nullable<string>;
  number: Nullable<string>;
  status: PhoneNumberSmsStatus;

  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;

  _destroy?: Nullable<boolean>;
}

export const defaultPhoneNumberProps = {
  id: 0,
  name: null,
  number: null,
  status: "subscribed" as PhoneNumberSmsStatus,
  created_at: null,
  updated_at: null,
  _destroy: false,
};

export class PhoneNumberRecord
  extends Record<IPhoneNumberRecord>(defaultPhoneNumberProps)
  implements IPhoneNumberRecord
{
  public readonly id!: number;
  public readonly name!: Nullable<string>;
  public readonly number!: string;
  public readonly status!: PhoneNumberSmsStatus;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public readonly _destroy!: boolean;

  public constructor(values?: Partial<IPhoneNumberRecord>) {
    values ? super(values) : super();
  }
}
