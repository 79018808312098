import { Nullable } from "app2/src/records";
import { Record, List, Map, fromJS } from "immutable";

export const fromJSON = (json: Partial<IGeoJsonData>): GeoJsonRecord => {
  const data: IGeoJsonRecord = { ...(json as any) };

  if (json.leaflet) {
    data.leaflet = List(fromJS(json.leaflet));
  }

  if (json.rapid_area) {
    data.rapid_area = List(fromJS(json.rapid_area));
  }

  return new GeoJsonRecord(data);
};

export interface IGeoJsonData {
  leaflet: any[];
  leafletZoom: number;
  rapid_area: any[];
}

export interface IGeoJsonRecord {
  leaflet: List<Map<any, any>>;
  leafletZoom: Nullable<number>;
  rapid_area: List<Map<any, any>>;
}

const defaultGeoJsonProps: IGeoJsonRecord = {
  leaflet: List(),
  leafletZoom: null,
  rapid_area: List(),
};

export class GeoJsonRecord extends Record(defaultGeoJsonProps) implements IGeoJsonRecord {}
