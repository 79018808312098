import { Deferred } from "app2/src/services/deferred";
import { sleep } from "app2/src/helpers/Time";
import { JobRecord } from "app2/src/records/Job";
import { PhoneNumberRecord } from "app2/src/records/PhoneNumber";
import { us_states_abbreviation } from "app/src/Common/StatesConstant";
import { firstName as firstNameParser, lastName as lastNameParser } from "app2/src/services/string.service";

interface GreenSkyParams {
  GS_ENV: number;
  GS_EXPERIENCE: number;
  GS_DEALER: string;
  GS_BASE_PLAN: string;
  GS_PROMO: string;
  GS_API_KEY: string;
  GS_PROGRAM: string;
}

export const loadGreenSkyModalOnGlobal = async (params: GreenSkyParams): Promise<boolean> => {
  const deferred = Deferred.defer<boolean>();
  // @ts-ignore
  window.GS_DEALER = params.GS_DEALER; // NOTE: Should be populated by Merchant
  // @ts-ignore
  window.GS_BASE_PLAN = params.GS_BASE_PLAN; // NOTE: Should be populated by Merchant
  // @ts-ignore
  window.GS_PROGRAM = params.GS_PROGRAM; // NOTE: Should be populated by Merchant
  // @ts-ignore
  window.GS_PROMO = params.GS_PROMO; // NOTE: Should be populated by Merchant
  // @ts-ignore
  window.GS_API_KEY = params.GS_API_KEY; // NOTE: API Key received from GreenSky
  // @ts-ignore
  window.GS_EXPERIENCE = params.GS_EXPERIENCE; // NOTE: Should be populated by Merchant
  // @ts-ignore
  window.GS_ENV = params.GS_ENV; // NOTE: Should be populated by Merchant

  const s = document.createElement("script");
  s.id = "green-sky-script";
  s.type = "text/javascript";
  s.async = true;
  s.src = "https://www.greensky.com/ecommerce/aslowas/gs-api-min.js";

  document.body.appendChild(s);

  checkGreenSkyStatus(deferred, 0);

  return deferred.promise;
};

export const cleanupGreenSky = () => {
  // @ts-ignore
  delete window.greensky;
  // @ts-ignore
  delete window.GS_DEALER;
  // @ts-ignore
  delete window.GS_BASE_PLAN;
  // @ts-ignore
  delete window.GS_PROGRAM;
  // @ts-ignore
  delete window.GS_PROMO;
  // @ts-ignore
  delete window.GS_API_KEY;
  // @ts-ignore
  delete window.GS_EXPERIENCE;
  // @ts-ignore
  delete window.GS_ENV;

  const script = document.getElementById("green-sky-script");
  if (script) {
    document.body.removeChild(script);
  }
};

export const checkGreenSkyStatus = async (deferred: Deferred<boolean>, tried: number) => {
  try {
    //@ts-ignore
    window.greensky.version;
    deferred.resolve(true);
  } catch (e) {
    await sleep(100);
    tried += 1;
    if (tried > 100) {
      deferred.reject(false);
    } else {
      checkGreenSkyStatus(deferred, tried);
    }
  }
};

export const checkoutParams = (job: JobRecord, financedAmount: number) => {
  const address = job.address;

  let phoneNumber = "";
  if (job.phone_numbers.first()) {
    phoneNumber = job.phone_numbers.first<PhoneNumberRecord>().number;
  }
  const names = job.customer_name.split(" ");
  const firstName = firstNameParser(job.customer_name);
  const lastName = lastNameParser(job.customer_name);
  const state = us_states_abbreviation[address.state];
  const country = address.country || "";

  return {
    applicant: {
      firstname: firstName,
      lastname: lastName,
      streetaddress: address.line_1,
      city: address.city,
      state: state,
      zip: address.postal_code,
      country: country,
      phone: phoneNumber,
      email: job.email,
    },
    shipping: {
      streetaddress: address.line_1,
      otheraddress: address.line_2,
      city: address.city,
      state: state,
      zip: address.postal_code,
      country: country,
    },
    merchant: {
      callbackurl: "",
    },
    order: {
      orderId: job.uid,
      totalAmount: financedAmount,
    },
  };
};
