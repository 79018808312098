import * as uuid from "uuid/v4";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";

let PaymentTerm: IPaymentTermResource;

export interface IPaymentTermResponse extends ng.resource.IResourceArray<IPaymentTerm> {
  payment_terms: IPaymentTerm[];
  meta: any;
}

export interface IPaymentTermItem {
  uuid: string;
  amount: number;
  description: string;
  type: string;
  calculated: number;
  paymentMethod: "cash" | "financed";
}

export class PaymentTermItem implements IPaymentTermItem {
  public uuid: string;
  public amount: number;
  public description: string;
  public type: string;
  public calculated: number;
  public paymentMethod: "cash" | "financed";

  constructor(jsonData?: any) {
    if (jsonData) {
      // This check that amount exists was added as part of [RSF-14705]
      if (jsonData.amount) {
        this.amount = jsonData.amount;
      } else {
        this.amount = 0;
      }
      if (jsonData.description) {
        this.description = jsonData.description;
      }
      if (jsonData.type) {
        this.type = jsonData.type;
      }
      if (jsonData.calculated) {
        this.calculated = jsonData.calculated;
      }
      if (jsonData.uuid) {
        this.uuid = jsonData.uuid;
      }
      if (jsonData.paymentMethod) {
        this.paymentMethod = jsonData.paymentMethod;
      }
    }

    if (!this.uuid) {
      this.uuid = uuid();
    }
  }
}

export interface IPaymentTerm extends ng.resource.IResource<IPaymentTerm>, PaymentTermPrototype {
  id: number;
  name: string;
  description: string;
  financed_amount: number;
  kind: string;
  $update(): ng.IPromise<IPaymentTerm>;
  $update(params?: Object, success?: Function, error?: Function): ng.IPromise<IPaymentTerm>;
  $update(success: Function, error?: Function): ng.IPromise<IPaymentTerm>;
}

export interface IPaymentTermResource extends ng.resource.IResourceClass<IPaymentTerm>, IRsfResource {
  fromJSON?(data: any): IPaymentTerm;
}

class PaymentTermPrototype {
  public id: number;
  public name: string;
  public description: string;
  public financed_amount: number;
  public kind: string;
  public breakdown: any;
  public payment_term_items: IPaymentTermItem[];
  public sort_order: number;

  public clone(): IPaymentTerm {
    return PaymentTerm.fromJSON(JSON.parse(angular.toJson(this)));
  }

  public $saveOrCreate(params?, callback?): ng.IPromise<IPaymentTermItem> {
    if (!this.id || this.id <= 0 || (this as any).id === "Unsaved") {
      return (this as any).$save(params, callback);
    } else {
      return (this as any).$update(params, callback);
    }
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/payment_terms/:id";

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta = angular.fromJson(response);

    _.each(meta.payment_terms, (pt, index) => {
      meta.payment_terms[index] = PaymentTerm.fromJSON(pt);
    });
    return meta;
  };

  const transformSingle = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }
    const jsonData = angular.fromJson(response);
    return PaymentTerm.fromJSON(jsonData.payment_term);
  };

  PaymentTerm = <IPaymentTermResource>$resource(
    url,
    { org_id: "@org_id", id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformResponse: transformSingle,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingle,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultiple,
        isArray: false,
      },
    },
  );

  PaymentTerm.fromJSON = (data: any) => {
    const pt = new PaymentTerm(data);

    if (!pt.payment_term_items) {
      pt.payment_term_items = [];
    }
    _.each(pt.payment_term_items || [], (pti, index) => {
      pt.payment_term_items[index] = new PaymentTermItem(pti);
    });

    if (pt.breakdown && _.isArray(pt.breakdown.items)) {
      _.each(pt.breakdown.items || [], (pti, index) => {
        pt.breakdown.items[index] = new PaymentTermItem(pti);
      });
    }

    return pt;
  };

  _.hiddenExtend(PaymentTerm.prototype, PaymentTermPrototype.prototype);

  PaymentTerm.inject = (injected: IRepository) => {
    resources = injected;
  };

  return PaymentTerm;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
