import { IPretty } from "../Common/PrettyNameService";
import { IOrg } from "../Models/Org";
import { SortableOptions } from "../Common/SortableOptions";
import { IMenuItem, ISection, ISideBarMenu } from "./JobMenuOrderService";
import { HandlebarsHelperService } from "../Patterns/HandlebarsHelperService";

class JobMenuManagerCtrl {
  public sortableOptions = new SortableOptions();
  public org: IOrg;
  public available: string[];
  public availableMenuItems: IMenuItem[];
  public jobMenu: ISideBarMenu;
  public cloneMenu: ISideBarMenu;
  public update: (data: any) => void;
  public templateSettings;

  constructor(
    public Pretty: IPretty,
    protected HandlebarsHelperService: HandlebarsHelperService,
  ) {
    this.sortableOptions.stop = () => {
      this.triggerUpdate();
    };
  }

  public $onChanges() {
    if (this.jobMenu && !this.cloneMenu && this.available && this.org) {
      this.templateSettings = {
        estimates_title: this.org.estimateTitle(true),
        estimate_title: this.org.estimateTitle(false),
        contracts_title: this.org.contractTitle(true),
        contract_title: this.org.contractTitle(false),
        estimator_title: this.org.estimatorTitle(false),
        estimators_title: this.org.estimatorTitle(true),
        org_name: this.org.name,
      };

      this.cloneMenu = JSON.parse(angular.toJson(this.jobMenu));

      _.each(this.cloneMenu.sections, (s: ISection) => this.compileSectionLabel(s));

      this.availableMenuItems = _.chain(this.available)
        .sortBy()
        .map((id) => <IMenuItem>{ id: id })
        .reject((mi: IMenuItem) => {
          return _.any(this.cloneMenu.sections, (s: ISection) => {
            return _.any(s.menuItems, (cmi: IMenuItem) => cmi.id === mi.id);
          });
        })
        .value();
    }
  }

  public removeSection(section: ISection) {
    this.cloneMenu.sections = _.reject(this.cloneMenu.sections, (s: ISection) => {
      return (
        section.renderedLabel === s.renderedLabel &&
        section.label === s.label &&
        _.all(section.menuItems, (mi: IMenuItem) => {
          return _.find(s.menuItems, (cmi: IMenuItem) => mi.id === cmi.id) !== undefined;
        })
      );
    });

    _.each(section.menuItems, (mi: IMenuItem) => this.availableMenuItems.push(mi));

    this.triggerUpdate();
  }

  public addSection() {
    this.cloneMenu.sections.push(<ISection>{
      label: "New Section",
      renderedLabel: "New Section",
      class: "yellow",
      menuItems: [],
    });

    this.triggerUpdate();
  }

  public moveSectionUp(idx: number) {
    if (idx === 0) {
      return;
    }
    this.moveMenuItem(idx, idx - 1);
    this.triggerUpdate();
  }

  public moveSectionDown(idx: number) {
    if (idx === this.cloneMenu.sections.length - 1) {
      return;
    }
    this.moveMenuItem(idx, idx + 1);
    this.triggerUpdate();
  }

  public removeMenuItem(section: ISection, menuItem: IMenuItem) {
    section.menuItems = _.select(section.menuItems, (mi: IMenuItem) => mi.id !== menuItem.id);
    this.availableMenuItems.push(menuItem);

    this.triggerUpdate();
  }

  public sectionLabelChanged(section: ISection) {
    this.compileSectionLabel(section);
    this.triggerUpdate();
  }

  protected moveMenuItem(old_idx, new_idx) {
    this.cloneMenu.sections.splice(new_idx, 0, this.cloneMenu.sections.splice(old_idx, 1)[0]);
  }

  protected compileSectionLabel(section: ISection) {
    section.renderedLabel = this.HandlebarsHelperService.compile(section.label, this.templateSettings);
  }

  protected triggerUpdate() {
    if (this.update) {
      this.update({ menu: this.cloneMenu });
    }
  }
}

export class JobMenuManagerComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any = {
    available: "<",
    jobMenu: "<",
    org: "<",
    update: "&",
  };
  public templateUrl = "src/JobMenu/job_menu_manager.html";

  constructor() {
    this.controller = JobMenuManagerCtrl;
    this.controller.$inject = ["Pretty", "HandlebarsHelperService"];
  }
}
