import * as cardconnectActions from "./cardconnect.actions";
import { Record, Map } from "immutable";
import { fromJSON } from "app2/src/records/CardConnectAuthorization";

export const CardConnectStateRecord = Record({
  lastSavedByOrgId: Map<number, Map<string, string>>(),
  byOrgId: Map<number, Map<string, string>>(),
  token: null,
  token_error: "",
  error: "",
});

export const initialState = CardConnectStateRecord({});
export type CardConnectState = typeof initialState;

export const reducer = (state = initialState, action: cardconnectActions.Actions): CardConnectState => {
  switch (action.type) {
    case cardconnectActions.FETCH_AUTHORIZATION:
      return state.setIn(["byOrgId", action.payload], fromJSON({ loading: true }));
    case cardconnectActions.SET_AUTHORIZATION:
      let newState = state
        .setIn(["byOrgId", action.payload.orgId], fromJSON(action.payload.authorization))
        .setIn(["lastSavedByOrgId", action.payload.orgId], fromJSON(action.payload.authorization))
        .setIn(["byOrgId", action.payload.orgId, "loading"], false);

      if (
        action.payload.authorization.username &&
        action.payload.authorization.username !== "" &&
        action.payload.authorization.api_key &&
        action.payload.authorization.api_key !== "" &&
        action.payload.authorization.merchant_id &&
        action.payload.authorization.merchant_id !== ""
      ) {
        newState = newState.setIn(["byOrgId", action.payload.orgId], true);
      }
      return newState;
    case cardconnectActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);
    case cardconnectActions.RESET:
      return state.setIn(["byOrgId", action.payload.id], state.getIn(["lastSavedByOrgId", action.payload.id]));
    default:
      return state;
  }
};
