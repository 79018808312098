import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { getFullName } from "app2/src/records/UserRecord";
import ImportCsv from "app2/src/components/JobList/ImportCsv";
import { Dropdown, Row, Col, Image } from "react-bootstrap";
import { Can } from "app2/src/components/Common/CanComponent";
import { getUrl } from "app2/src/records/Image";
import * as config from "react-global-configuration";
import { ThunkDispatch } from "redux-thunk";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";
import { Actions as CommonActions } from "app2/src/reducers/components/common.actions";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";
import { getIn } from "immutable";
import { org as orgById } from "app2/src/selectors/org.selectors";
import { CustomLink } from "app2/src/components/Common/CustomLink";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>) => {
  return {
    openImportModal: () => dispatch(CommonActions.openImportModal()),
  };
};

const mapStateToProps = (state: RootState, ownProps: UserHeaderInfoProps) => {
  const currentUser = denormalizedReduxUser(state);
  const currentUserOrgId = getIn(currentUser, ["org_id"], null);
  return {
    currentUser: currentUser,
    currentOrg: orgById(state, { orgId: currentUserOrgId }),
    token: state.getIn(["auth", "token"]),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface UserHeaderInfoProps {
  detailed: boolean;
  refreshQuery: any;
  navDisplay: boolean;
  $state: any;
  tracking?: TrackingProp;
}

interface ServiceProps {
  JobInfoTourService: any;
  $rootScope: any;
  JobShowTourService: any;
}

interface UserHeaderInfoState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & UserHeaderInfoProps & ServiceProps;
const track: Track<TrackingData, UserHeaderInfoProps> = _track;

@track(
  (props: UserHeaderInfoProps) => {
    return {
      category: "UserHeaderInfo",
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: true,
  },
)
class UserHeaderInfo extends React.Component<Props, UserHeaderInfoState> {
  public constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.jobInfo = this.jobInfo.bind(this);
  }

  public logout() {
    const { $state } = this.props;
    $state.go("root.logout");
  }

  public jobInfo() {
    const { tracking, JobInfoTourService, $rootScope, JobShowTourService, navDisplay } = this.props;
    tracking.trackEvent({
      action: "job tour requested",
      navDisplay: !navDisplay,
    });
    JobInfoTourService.help().then(() => {
      $rootScope.$broadcast("header:help:job_info");
      JobShowTourService.help();
    });
  }

  public render() {
    const { currentUser, currentOrg, refreshQuery, detailed, $state, openImportModal, token } = this.props;

    if (!currentUser || !currentOrg) {
      return <div />;
    }

    return (
      <Dropdown className="user-header-info-dropdown">
        <Dropdown.Toggle
          variant="secondary"
          size="sm"
          as="div"
          id="dropdown-basic"
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
          data-testid="dropdown-toggle">
          <Row>
            {detailed && currentOrg.images && currentOrg.images.size > 0 && (
              <Col xs={3} md={3} className="d-flex align-items-center pr-0 user-header-info-image">
                <Image src={getUrl(currentOrg.images.first(), "small")} fluid />
              </Col>
            )}
            <Col xs={detailed ? 9 : 12} md={detailed ? 9 : 12} className="d-flex flex-column small">
              <h4 className="mb-0">
                <b className="d-inline-block text-truncate user-full-name">{getFullName(currentUser)}</b>
              </h4>
              <div className="org-info">
                {currentOrg.name}
                {detailed && (
                  <>
                    <br />
                    {currentOrg.address.city} {currentOrg.address.state}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Dropdown.Toggle>
        <Dropdown.Menu className="user-header-info-dropdown-menu">
          {currentUser.accesses_count > 1 && (
            <>
              <Dropdown.Item as="div" className="m-0 p-0">
                <CustomLink tag="a" to={$state.href("root.accesses")} className="dropdown-item">
                  Select Organization
                </CustomLink>
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item as="div" className="m-0 p-0">
            <CustomLink tag="a" to={$state.href("root.profile")} className="dropdown-item">
              Profile
            </CustomLink>
          </Dropdown.Item>
          <Dropdown.Divider />
          {config.get("SUPPORT_URL") && (
            <>
              <Dropdown.Item href={config.get("SUPPORT_URL")} target="_blank">
                Support Resources
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          {config.get("LEARNING_URL") && (
            <>
              <Dropdown.Item href={config.get("LEARNING_URL") + token} target="_blank">
                Learning Portal
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item onClick={this.jobInfo}>Job Tour</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item>Version {(config.get("VERSION") || "").toString().toUpperCase()}</Dropdown.Item>
          <Can resource="HeaderPanel" permission="signed_docs">
            <Dropdown.Divider />
            <Dropdown.Item as="div">
              <CustomLink tag="a" to={$state.href("root.signed_doc_list")} data-testid="signed_doc_list">
                Signatures
              </CustomLink>
            </Dropdown.Item>
          </Can>
          <Dropdown.Divider />
          <Dropdown.Item onClick={openImportModal}>Import</Dropdown.Item>
          <ImportCsv refreshQuery={refreshQuery} />
          <Dropdown.Divider />
          <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default connector(UserHeaderInfo);
