import * as React from "react";
import { AccessRecord } from "app2/src/records/UserRecord";
import { Accordion, Row, Col } from "react-bootstrap";
import { Can } from "app2/src/components/Common/CanComponent";
import { List, Map, getIn, get } from "immutable";
import { useDispatch, useSelector } from "react-redux";
import { currentUser as currentUserSelector } from "app2/src/selectors/user.selectors";
import { RootState } from "app2/src/reducers";
import * as accessActions from "app2/src/reducers/access.actions";
import * as userActions from "app2/src/reducers/user.actions";

export interface AccessEditorProps {
  access: AccessRecord;
  activeToggleKey: string;
  changeToggle: (key: string) => void;
}

const billableTypes = ["billable", "free", "estimates", "leads"];

export const AccessEditor: React.FC<AccessEditorProps> = ({ access, changeToggle, activeToggleKey }) => {
  // hooks
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);

  // selector/calculated value
  const currentUser = useSelector(currentUserSelector);
  const calculatedUserId = React.useMemo(
    () => (access.user_id < 0 ? currentUser.id : access.user_id),
    [currentUser, access],
  );

  // selectors
  const templates = useSelector<RootState, List<any>>((state) =>
    getIn(state, ["users", "allowedAclByUserAccess", "templates", `${currentUser.id}:`, "none"], List<any>()),
  );
  const allAcl = useSelector<RootState, Map<string, List<any>>>((state) =>
    getIn(state, ["users", "allowedAclByUserAccess", "acl", `${currentUser.id}:`, "none"], Map()),
  );
  const editable = useSelector<RootState, Map<string, List<any>>>((state) =>
    getIn(state, ["users", "allowedAclByUserAccess", "acl", `${calculatedUserId}:${access.uid}`, "editable"], Map()),
  );
  const selectableTemplates = useSelector<RootState, List<any>>((state) =>
    getIn(
      state,
      ["users", "allowedAclByUserAccess", "templates", `${calculatedUserId}:${access.uid}`, "none"],
      List<any>(),
    ),
  );

  // methods
  const aclCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [resource, permission] = e.target.name.split(".");

    dispatch(accessActions.Actions.updateFormAcl(access.id, resource, permission, e.target.checked));
  };

  const accessValueChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(accessActions.Actions.updateFormRecord(access.id, e.target.name, e.target.value));

    if (e.target.name === "template" && e.target.value !== "custom") {
      dispatch(
        userActions.AsyncActions.loadAndSetAccessAcl(
          access.id,
          calculatedUserId,
          access.uid,
          e.target.value,
          access.user_id < 0 ? currentUser.id : undefined,
        ),
      );
    }
  };

  // lifecycle
  React.useEffect(() => {
    if (access.id < 0) {
      dispatch(
        userActions.AsyncActions.loadAndSetAccessAcl(
          access.id,
          access.user_id,
          access.uid,
          access.template,
          currentUser.id,
        ),
      );
    }
  }, []);

  return (
    <>
      <Row className="table-row bg-light">
        <Col md={3}>{access.org?.name}</Col>
        <Col md={3}>
          {editMode && (
            <>
              <Can resource="user" permission="acl_template">
                <select name="template" className="form-control" value={access.template} onChange={accessValueChanged}>
                  <Can resource="User" permission="acl_custom">
                    <option key="customer" value="custom">
                      Custom
                    </option>
                  </Can>
                  {(access.id > 0 ? selectableTemplates : templates).map((t, idx) => (
                    <option key={idx} value={get(t, "descriptor")}>
                      {get(t, "name")}
                    </option>
                  ))}
                </select>
              </Can>
              <Can resource="user" permission="acl_template" inverse>
                <input className="form-control" type="text" value={access.template} disabled />
              </Can>
            </>
          )}
          {!editMode && <span>{access.template}</span>}
        </Col>
        <Col md={3}>
          {editMode && (
            <select name="billable" className="form-control" value={access.billable} onChange={accessValueChanged}>
              {billableTypes.map((billableType, idx) => (
                <option key={idx} value={billableType}>
                  {billableType}
                </option>
              ))}
            </select>
          )}
          {!editMode && <span>{access.billable}</span>}
        </Col>
        <Col md={3}>
          <Can resource="user" permission="update">
            {!editMode && (
              <i
                className="rsf-edit-link rsf-base-66 action-button"
                title="Edit Access"
                onClick={() => setEditMode(true)}></i>
            )}
            {editMode && (
              <i
                className="rsf-check-link rsf-base-66 action-button"
                onClick={() => setEditMode(false)}
                title="Save Access"></i>
            )}
          </Can>
          <Can resource="user" permission="acl_custom">
            <Accordion.Toggle
              eventKey={access.id.toString()}
              onClick={() => changeToggle(access.id.toString())}
              as="span">
              <i
                className="rsf-expand-link rsf-base-66 action-button"
                title="Edit ACL"
                ng-click="ctrl.editAcl(access)"></i>
            </Accordion.Toggle>
          </Can>
        </Col>
      </Row>
      <Accordion.Collapse eventKey={access.id.toString()}>
        <Row>
          <Col md={12}>
            {activeToggleKey === access.id.toString() && (
              <>
                {allAcl.entrySeq().map(([key, value]) => (
                  <Row key={key} className="table-row">
                    <Col md={3}>{_.toTitleCase(key)}:</Col>
                    <Col md={9}>
                      {value.map((perm, idx) => {
                        return (
                          <label
                            key={idx}
                            className={` checkbox-inline ${
                              (get(editable, key, List()) as List<string>).indexOf(perm) < 0 &&
                              access.template !== "custom"
                                ? ""
                                : "user-permission-editable"
                            }`}>
                            <input
                              type="checkbox"
                              name={`${key}.${perm}`}
                              checked={getIn(access, ["acl", key], List()).indexOf(perm) >= 0}
                              onChange={aclCheckboxChanged}
                              disabled={
                                (get(editable, key, List()) as List<string>).indexOf(perm) < 0 &&
                                access.template !== "custom"
                              }
                            />
                            {perm}&nbsp;&nbsp;
                          </label>
                        );
                      })}
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Col>
        </Row>
      </Accordion.Collapse>
    </>
  );
};
