import { fetcher } from "app2/src/helpers/Fetcher";
import { QueryParamsRecord } from "app2/src/records/Page";
import {
  ICrmUserIndexResponse,
  CrmUserRecord,
  ICrmUserResponse,
  CrmUserType,
} from "app2/src/records/integrations/CrmUser";
import { CamelToSnake } from "app2/src/helpers/Format";

export interface IIntegrationOptions {
  orgId: number;
  userType: CrmUserType;
  modelName?: string;
}

export const url = "api/v1/orgs/:id/:userType";

export const loadByOrg = (
  options: IIntegrationOptions,
  queryParams: QueryParamsRecord,
): Promise<ICrmUserIndexResponse> => {
  const actionUrl = url
    .replace(":id", options.orgId.toString())
    .replace(":userType", `${CamelToSnake(options.userType, false)}s`);

  return fetcher.get<ICrmUserIndexResponse>(actionUrl, queryParams.toJSON());
};

export const updateUser = (user: CrmUserRecord): Promise<ICrmUserResponse> => {
  const actionUrl =
    url.replace(":id", user.org_id.toString()).replace(":userType", `${CamelToSnake(user.type, false)}s`) +
    `/${user.id}`;

  return fetcher.patch<ICrmUserResponse>(actionUrl, user.toJSON());
};
