import { IPriceList, IPriceListResponse } from "app/src/Models/PriceList";
import { IRepository } from "app/src/Common/Repository";
import { ITask, Task } from "app/src/Models/Task";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import * as moment from "moment";
import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IOrg } from "app/src/Models/Org";

export interface IProductSearchArgs {
  searchString?: string;
}

export class OrgPriceListHeaderCtrl extends BaseOrgTabCtrl {
  public spinnerPromise: ng.IPromise<any>;
  public priceLists: IPriceList[];
  public currentPriceList: IPriceList;
  public search = "";
  public activateTask: ITask;
  public processPriceList: IPriceList = null;
  public showActivationWarning = false;

  public _cleanupListeners: Function[] = [];

  public static $inject = [
    "$stateParams",
    "$state",
    "$uibModal",
    "$q",
    "$timeout",
    "$http",
    "$rootScope",
    "$scope",
    "$analytics",
    "Repository",
    "Flash",
    "OrgFetcher",
  ];

  constructor(
    public $stateParams: ng.ui.IStateParamsService,
    public $state: ng.ui.IStateService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $q: ng.IQService,
    public $timeout: ng.ITimeoutService,
    public $http: ng.IHttpService,
    public $rootScope: ng.IRootScopeService,
    public $scope: ng.IScope,
    protected $analytics: angulartics.IAnalyticsService,
    public Repository: IRepository,
    public Flash: IFlash,
    public OrgFetcher: IOrgFetcherService,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    OrgFetcher.orgPromise.then(() => {
      return this.getCurrentPriceLists().then(() => {
        this.setupActivationWarning();
      });
    });
    if ($stateParams.query) {
      this.search = $stateParams.query;
    }

    this._cleanupListeners.push(
      $rootScope.$on("price_list_header:search_clear", (event: ng.IAngularEvent, args: IProductSearchArgs) => {
        this.search = "";
      }),
    );

    this._cleanupListeners.push(
      $rootScope.$on("price_list_header:update_price_list", (event: ng.IAngularEvent, args: IProductSearchArgs) => {
        this.currentPriceList.updated_at = new Date();
        this.setupActivationWarning();
      }),
    );

    $scope.$on("$destroy", () => {
      _.each(this._cleanupListeners, (func: () => void) => {
        if (_.isFunction(func)) {
          func();
        }
      });
    });
  }

  public getCurrentPriceLists(): ng.IPromise<any> {
    const priceListResponse = <IPriceListResponse>this.Repository.PriceList.query({
      org_id: this.$stateParams.id,
      sort_by: "updated_at",
      sort_order: "desc",
    });
    return priceListResponse.$promise.then(() => {
      this.priceLists = priceListResponse.price_lists;
      if (this.$stateParams.price_list_id) {
        this.currentPriceList = _.find(this.priceLists, (pl) => {
          return pl.id === parseInt(this.$stateParams.price_list_id);
        });
      } else if (this.priceLists.length > 0) {
        this.currentPriceList = this.priceLists[0];
        this.priceListChanged();
      }
    });
  }

  public priceListChanged() {
    if (_.isNull(this.currentPriceList)) {
      this.addPriceList();
    } else {
      this.$state.go("root.org_show.price_list_header.editor", {
        price_list_id: this.currentPriceList.id,
        active_tab: 0,
        product_parent_id: null,
        option_group_match_id: null,
      });
    }
  }

  public addPriceList() {
    this.openPriceListEditor(<IPriceList>{
      name: "",
      org_id: this.$stateParams.id,
    }).result.then((price_list: IPriceList) => {
      this.spinnerPromise = this.Repository.PriceList.create(price_list).$promise.then((new_pl: IPriceList) => {
        this.$state.go("root.org_show.price_list_header.editor", {
          price_list_id: new_pl.id,
          product_parent_id: null,
        });
      });
    });
  }

  public editPriceList() {
    this.openPriceListEditor(_.clone(this.currentPriceList)).result.then((price_list: IPriceList) => {
      const edited_price_list = this.Repository.PriceList.fromJSON(price_list);
      this.spinnerPromise = edited_price_list.$save().then(() => {
        this.currentPriceList.name = edited_price_list.name;
      });
    });
  }

  public openPriceListEditor(price_list: IPriceList) {
    return this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "OrgPriceListPropEditorCtrl",
      controllerAs: "ctrl",
      templateUrl: "src/Orgs/tabs/price_list_prop_editor.html",

      resolve: {
        price_list: price_list,
      },
    });
  }

  public activatePriceList() {
    this.spinnerPromise = this.Repository.PriceList.activate({
      org_id: this.$stateParams.id,
      id: this.$stateParams.price_list_id,
    }).$promise.then((resp) => {
      const overall = this.$q.defer();
      this.$analytics.eventTrack("activate_price_list", {
        category: "PriceListEditorTab",
        priceListId: this.$stateParams.price_list_id,
        org: this.$stateParams.id,
      });
      this.activateTask = Task.watch(this.$timeout, this.$http, resp.location, (task: ITask) => {
        if (task.status === "error") {
          overall.resolve(true);
          this.Flash.addMessage(FlashLevels.danger, "There was an error activating the Price List");
        }

        if (task.status === "finished") {
          this.OrgFetcher.reloadOrg(this.$stateParams.id);
          this.OrgFetcher.orgPromise.then((org: IOrg) => {
            this.org = org;
            this.setupActivationWarning();
            overall.resolve(true);
            this.Flash.addMessage(FlashLevels.success, "Price List successfully activated");
          });
        }
      });
      return overall.promise;
    });
  }

  public isActivating() {
    if (this.activateTask) {
      return this.activateTask.status === "processing" || this.activateTask.status === undefined;
    } else {
      return false;
    }
  }

  public setupActivationWarning() {
    const apl = this.org.activated_price_list;
    this.showActivationWarning = true;
    if (
      apl.price_list_id === this.currentPriceList.id &&
      moment(apl.created_at).diff(moment(this.currentPriceList.updated_at)) / 1000 >= -2
    ) {
      this.showActivationWarning = false;
    }
  }

  public searchChanged() {
    this.$rootScope.$broadcast("price_list_header:search_change", <IProductSearchArgs>{
      searchString: this.search,
    });
  }

  public updateProcessPriceList() {
    this.processPriceList = this.currentPriceList;
  }

  public closeProcess() {
    this.processPriceList = null;
    this.$scope.$digest();
  }

  public tasks() {
    this.$state.go("root.tasks");
  }
}
