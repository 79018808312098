import { IOrg } from "app/src/Models/Org";
import { DeckRecord, IDeckData } from "app2/src/records/Deck";
import { DoorRecord, IDoorData } from "app2/src/records/Door";
import { ElevationRecord, IElevationData } from "app2/src/records/Elevation";
import { IPoolData, PoolRecord } from "app2/src/records/Pool";
import { IRoofFaceData, RoofFaceRecord } from "app2/src/records/RoofFace";
import { IRoomData, RoomRecord } from "app2/src/records/Room";
import { IWallFacadeData, WallFacadeRecord } from "app2/src/records/WallFacade";
import { IWindowData, WindowRecord } from "app2/src/records/Window";
import { Record, List } from "immutable";
import { Nullable } from ".";
import { IMeasurement } from "app/src/Models/Measurement";
import { fromJSON as geoJsonFromJSON } from "app2/src/records/GeoJson";
import { Parser } from "hot-formula-parser";
import { imperial_uom, metric_uom } from "app/src/Common/Constants";
import { useSelector } from "app2/src/storeRegistry";
import { measurementIdFullFencing } from "app2/src/selectors/measurements/fencing.selectors";
import { FencingRecord } from "./measurements/Fencing";

export const fromJSON = (json: Partial<IMeasurementData>): MeasurementRecord => {
  const recordData: IMeasurementRecord = { ...(json as any) };

  if (json.roof_faces) {
    recordData.roof_face_ids = List(json.roof_faces.map((pog) => pog.id));
  }

  if (json.wall_facades) {
    recordData.wall_facade_ids = List(json.wall_facades.map((pog) => pog.id));
  }

  if (json.windows) {
    recordData.window_ids = List(json.windows.map((pog) => pog.id));
  }

  if (json.doors) {
    recordData.door_ids = List(json.doors.map((pog) => pog.id));
  }

  if (json.rooms) {
    recordData.room_ids = List(json.rooms.map((pog) => pog.id));
  }

  if (json.elevations) {
    recordData.elevation_ids = List(json.elevations.map((pog) => pog.id));
  }

  if (json.decks) {
    recordData.deck_ids = List(json.decks.map((pog) => pog.id));
  }

  if (json.pools) {
    recordData.pool_ids = List(json.pools.map((pog) => pog.id));
  }

  if (json.geojson) {
    recordData.geojson = geoJsonFromJSON(json.geojson);
  }

  if (json.locations) {
    recordData.locations = List(json.locations);
  }

  if (json.levels) {
    recordData.levels = List(json.levels);
  }

  const record = new MeasurementRecord(recordData);

  return record;
};

export const objects = List(["roof_face", "wall_facade", "window", "door", "room", "deck", "pool"]);
export const properties = List(["roofing", "siding", "sunroom"]);
export const propertiesMapping = List(["roof", "siding", "sunroom"]);
export const exteriorMeasurements = List([
  "roofing",
  "siding",
  "sunroom",
  "walls",
  "windows",
  "doors",
  "decks",
  "pools",
  "fencing",
]);
export const lineMeasurementUpdate = List([
  "roof_ridge",
  "roof_hip",
  "roof_valley",
  "roof_eave",
  "roof_rake",
  "roof_step_flashing",
  "roof_headwall_flashing",
]);
export const sunroomUpdate = List([
  "sunroom_a_wall_length",
  "sunroom_a_wall_height",
  "sunroom_b_wall_length",
  "sunroom_b_wall_height",
  "sunroom_c_wall_length",
  "sunroom_c_wall_height",
  "sunroom_wall_perimeter",
  "sunroom_wall_total_area",
]);
export const sunroomKneeWallUpdate = List([
  "sunroom_kw_a_length",
  "sunroom_kw_a_height",
  "sunroom_kw_b_length",
  "sunroom_kw_b_height",
  "sunroom_kw_c_length",
  "sunroom_kw_c_height",
]);

export const hasMeasurements = (measurement: MeasurementRecord, org: IOrg, location: string) => {
  if (!measurement) {
    return false;
  }

  const prop = properties.find((mp, index) => {
    return (
      hasMeasurementProperties(measurement, propertiesMapping.get(index)) && org.showMeasurementSection(mp, location)
    );
  });

  if (prop) {
    return true;
  }

  const object = objects.find((obj) => {
    return measurement.get(`${obj}_ids` as any).size > 0 && org.showMeasurementSection(`${obj}s`, location);
  });

  if (object) {
    return true;
  }

  return false;
};

export const hasMeasurementProperties = (measurement: MeasurementRecord, searchString: string): boolean => {
  const regexPattern = new RegExp("^" + searchString + ".*");

  let prop: string;
  for (prop in measurement.toJS()) {
    if (_.isFunction(prop) || !regexPattern.test(prop)) {
      continue;
    }

    if (measurement.get(prop as any) > 0) {
      return true;
    }
  }
  return false;
};

export const setupForumulaParser = (measurement: IMeasurement, addtl?: AdditionalMeasurements) => {
  const parser = new Parser();
  _.each(columnKeys(measurement), (k) => {
    parser.setVariable(k, measurement[k]);
  });
  setupFencingMeasurements(parser, measurement.id, addtl?.fencing);
  return parser;
};

const setupFencingMeasurements = (parser: any, measurementId: number, fencingRecord?: FencingRecord) => {
  let fencing: FencingRecord;

  if (fencingRecord) {
    fencing = fencingRecord;
  } else {
    fencing = useSelector((state) => measurementIdFullFencing(state, { measurementId }));
  }

  if (!fencing) {
    parser.setVariable("fencing_netLength", 0);
    parser.setVariable("fencing_totalLength", 0);
    parser.setVariable("fencing_linePosts4", 0);
    parser.setVariable("fencing_linePosts6", 0);
    parser.setVariable("fencing_linePosts8", 0);
    parser.setVariable("fencing_panels4", 0);
    parser.setVariable("fencing_panels6", 0);
    parser.setVariable("fencing_panels8", 0);
    parser.setVariable("fencing_gateCount", 0);
    parser.setVariable("fencing_gateLength", 0);
    parser.setVariable("fencing_terminalPosts", 0);
    parser.setVariable("fencing_cornerPosts", 0);
    return;
  }

  const segmentCount = fencing.fences.reduce((sum, f) => f.segmentIds.size + sum, 0);

  parser.setVariable("fencing_netLength", fencing.netLength);
  parser.setVariable("fencing_totalLength", fencing.totalLength);
  parser.setVariable("fencing_linePosts4", fencing.linePosts4);
  parser.setVariable("fencing_linePosts6", fencing.linePosts6);
  parser.setVariable("fencing_linePosts8", fencing.linePosts8);
  parser.setVariable("fencing_panels4", fencing.linePosts4 + segmentCount);
  parser.setVariable("fencing_panels6", fencing.linePosts6 + segmentCount);
  parser.setVariable("fencing_panels8", fencing.linePosts8 + segmentCount);
  parser.setVariable("fencing_gateCount", fencing.gateCount);
  parser.setVariable("fencing_gateLength", fencing.gateLength);
  parser.setVariable("fencing_terminalPosts", fencing.terminalPosts + fencing.gateCount * 2);
  parser.setVariable("fencing_cornerPosts", fencing.cornerPosts);
};

export interface AdditionalMeasurements {
  fencing?: FencingRecord;
}

export const calculateFormula = (
  formula: string,
  measurement: IMeasurement,
  addtl?: AdditionalMeasurements,
): { result: number; error: string } => {
  if (!formula) {
    return { result: 1, error: null };
  }

  try {
    const parser = setupForumulaParser(measurement, addtl);
    const { error } = parser.parse(formula);
    let { result } = parser.parse(formula);
    if (result) {
      result = _.round(result, -2);
    }
    return { result, error };
  } catch {
    return { result: null, error: "ERROR!" };
  }
};

export const columnKeys = (
  measurement: MeasurementRecord | IMeasurement,
  types: string[] = propertiesMapping.toArray(),
) => {
  let keys: string[];
  if (Record.isRecord(measurement)) {
    keys = Object.keys(measurement.toJS());
  } else {
    keys = Object.keys(measurement);
  }

  const columnKeys = [];
  keys.map((key) => {
    if (key === "roof_faces" || key === "roof_face_ids") return;

    const found = types.find((prop) => {
      return key.indexOf(prop) === 0;
    });
    if (found) {
      columnKeys.push(key);
    }
  });

  return columnKeys;
};

export const keysWithValues = (measurement: MeasurementRecord, type: string) => {
  const keys = columnKeys(measurement, [type]);
  const returnKeys = [];
  keys.map((k) => {
    if (measurement.get(k as any) > 0) {
      returnKeys.push(k);
    }
  });
  return returnKeys;
};

export const getUoM = (measurement: MeasurementRecord) => {
  if (!measurement || measurement.system === "imperial") {
    return imperial_uom;
  } else {
    return metric_uom;
  }
};

export const calculateArea = (height: number, length: number, uom: string): number => {
  if (!height || !length) {
    return 0;
  }
  if (uom === "in") {
    return _.round((height / 12) * length, -2);
  } else if (uom === "cm") {
    return _.round((height / 100) * length, -2);
  } else {
    return _.round(height * length, -2);
  }
};

export interface IMeasurementData {
  id: number;
  geojson: any;
  job_id: number;
  system: string;
  roof_1012_area: number;
  roof_10pw_sloped_area: number;
  roof_10pw_total_area: number;
  roof_12pw_total_area: number;
  roof_13p_area: number;
  roof_0_area: number;
  roof_1_area: number;
  roof_2_area: number;
  roof_3_area: number;
  roof_4_area: number;
  roof_5_area: number;
  roof_6_area: number;
  roof_7_area: number;
  roof_8_area: number;
  roof_9_area: number;
  roof_10_area: number;
  roof_11_area: number;
  roof_12_area: number;
  roof_13_area: number;
  roof_14_area: number;
  roof_15_area: number;
  roof_16_area: number;
  roof_17_area: number;
  roof_18_area: number;
  roof_19_area: number;
  roof_20_area: number;
  roof_21_area: number;
  roof_22_area: number;
  roof_23_area: number;
  roof_24_area: number;
  roof_25_area: number;
  roof_26p_area: number;
  roof_15pw_total_area: number;
  roof_20pw_total_area: number;
  roof_5pw_total_area: number;
  roof_79_area: number;
  roof_average_slope_area: number;
  roof_downspout_elbows: number;
  roof_downspouts: number;
  roof_eave: number;
  roof_flat_area: number;
  roof_flat_shallow_area: number;
  roof_gutter_miters: number;
  roof_headwall_flashing: number;
  roof_high_roof_area: number;
  roof_hip: number;
  roof_low_slope_area: number;
  roof_perimeter: number;
  roof_rake: number;
  roof_ridge: number;
  roof_ridge_hip: number;
  roof_sloped_area: number;
  roof_steep_slope_area: number;
  roof_step_flashing: number;
  roof_total_area: number;
  roof_total_flashing: number;
  roof_ultra_steep_slope_area: number;
  roof_valley: number;
  roof_valley_eave: number;
  roof_valley_eave_rake: number;
  siding_10pw_total_area: number;
  siding_15pw_total_area: number;
  siding_18pw_total_area: number;
  siding_5pw_total_area: number;
  siding_doors_qty: number;
  siding_frieze_board: number;
  siding_garage_doors_qty: number;
  siding_inside_corner_length: number;
  siding_inside_corner_qty: number;
  siding_level_frieze_board: number;
  siding_level_starter: number;
  siding_opening_qty: number;
  siding_opening_sides_length: number;
  siding_opening_sills_length: number;
  siding_opening_tops_length: number;
  siding_outside_corner_length: number;
  siding_outside_corner_qty: number;
  siding_shutter_area: number;
  siding_shutter_qty: number;
  siding_sloped_frieze_board: number;
  siding_sloped_trim: number;
  siding_soffit_eaves: number;
  siding_soffit_level_frieze: number;
  siding_soffit_rakes: number;
  siding_soffit_sloped_frieze: number;
  siding_soffit_total: number;
  siding_total_area: number;
  siding_vents_area: number;
  siding_vents_qty: number;
  siding_vertical_trim: number;
  siding_windows_qty: number;
  sunroom_a_wall_area: number;
  sunroom_a_wall_height: number;
  sunroom_a_wall_length: number;
  sunroom_b_wall_area: number;
  sunroom_b_wall_height: number;
  sunroom_b_wall_length: number;
  sunroom_c_wall_area: number;
  sunroom_c_wall_height: number;
  sunroom_c_wall_length: number;
  sunroom_cathedral_roof_area: number;
  sunroom_ceiling_area: number;
  sunroom_deck_total_area: number;
  sunroom_kw_a_area: number;
  sunroom_kw_a_height: number;
  sunroom_kw_a_length: number;
  sunroom_kw_b_area: number;
  sunroom_kw_b_height: number;
  sunroom_kw_b_length: number;
  sunroom_kw_c_area: number;
  sunroom_kw_c_height: number;
  sunroom_kw_c_length: number;
  sunroom_kw_total_area: number;
  sunroom_kw_total_length: number;
  sunroom_studio_roof_area: number;
  sunroom_wall_perimeter: number;
  sunroom_wall_total_area: number;

  roof_faces: IRoofFaceData[];
  wall_facades: IWallFacadeData[];
  windows: IWindowData[];
  doors: IDoorData[];
  rooms: IRoomData[];
  elevations: IElevationData[];
  decks: IDeckData[];
  pools: IPoolData[];
  locations: string[];
  levels: string[];

  loading: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IMeasurementRecord {
  id: number;
  geojson: any;
  job_id: number;
  system: string;
  roof_1012_area: number;
  roof_10pw_sloped_area: number;
  roof_10pw_total_area: number;
  roof_12pw_total_area: number;
  roof_13p_area: number;
  roof_0_area: number;
  roof_1_area: number;
  roof_2_area: number;
  roof_3_area: number;
  roof_4_area: number;
  roof_5_area: number;
  roof_6_area: number;
  roof_7_area: number;
  roof_8_area: number;
  roof_9_area: number;
  roof_10_area: number;
  roof_11_area: number;
  roof_12_area: number;
  roof_13_area: number;
  roof_14_area: number;
  roof_15_area: number;
  roof_16_area: number;
  roof_17_area: number;
  roof_18_area: number;
  roof_19_area: number;
  roof_20_area: number;
  roof_21_area: number;
  roof_22_area: number;
  roof_23_area: number;
  roof_24_area: number;
  roof_25_area: number;
  roof_26p_area: number;
  roof_15pw_total_area: number;
  roof_20pw_total_area: number;
  roof_5pw_total_area: number;
  roof_79_area: number;
  roof_average_slope_area: number;
  roof_downspout_elbows: number;
  roof_downspouts: number;
  roof_eave: number;
  roof_flat_area: number;
  roof_flat_shallow_area: number;
  roof_gutter_miters: number;
  roof_headwall_flashing: number;
  roof_high_roof_area: number;
  roof_hip: number;
  roof_low_slope_area: number;
  roof_perimeter: number;
  roof_rake: number;
  roof_ridge: number;
  roof_ridge_hip: number;
  roof_sloped_area: number;
  roof_steep_slope_area: number;
  roof_step_flashing: number;
  roof_total_area: number;
  roof_total_flashing: number;
  roof_ultra_steep_slope_area: number;
  roof_valley: number;
  roof_valley_eave: number;
  roof_valley_eave_rake: number;
  siding_10pw_total_area: number;
  siding_15pw_total_area: number;
  siding_18pw_total_area: number;
  siding_5pw_total_area: number;
  siding_doors_qty: number;
  siding_frieze_board: number;
  siding_garage_doors_qty: number;
  siding_inside_corner_length: number;
  siding_inside_corner_qty: number;
  siding_level_frieze_board: number;
  siding_level_starter: number;
  siding_opening_qty: number;
  siding_opening_sides_length: number;
  siding_opening_sills_length: number;
  siding_opening_tops_length: number;
  siding_outside_corner_length: number;
  siding_outside_corner_qty: number;
  siding_shutter_area: number;
  siding_shutter_qty: number;
  siding_sloped_frieze_board: number;
  siding_sloped_trim: number;
  siding_soffit_eaves: number;
  siding_soffit_level_frieze: number;
  siding_soffit_rakes: number;
  siding_soffit_sloped_frieze: number;
  siding_soffit_total: number;
  siding_total_area: number;
  siding_vents_area: number;
  siding_vents_qty: number;
  siding_vertical_trim: number;
  siding_windows_qty: number;
  sunroom_a_wall_area: number;
  sunroom_a_wall_height: number;
  sunroom_a_wall_length: number;
  sunroom_b_wall_area: number;
  sunroom_b_wall_height: number;
  sunroom_b_wall_length: number;
  sunroom_c_wall_area: number;
  sunroom_c_wall_height: number;
  sunroom_c_wall_length: number;
  sunroom_cathedral_roof_area: number;
  sunroom_ceiling_area: number;
  sunroom_deck_total_area: number;
  sunroom_kw_a_area: number;
  sunroom_kw_a_height: number;
  sunroom_kw_a_length: number;
  sunroom_kw_b_area: number;
  sunroom_kw_b_height: number;
  sunroom_kw_b_length: number;
  sunroom_kw_c_area: number;
  sunroom_kw_c_height: number;
  sunroom_kw_c_length: number;
  sunroom_kw_total_area: number;
  sunroom_kw_total_length: number;
  sunroom_studio_roof_area: number;
  sunroom_wall_perimeter: number;
  sunroom_wall_total_area: number;

  roof_face_ids: List<number>;
  wall_facade_ids: List<number>;
  window_ids: List<number>;
  door_ids: List<number>;
  room_ids: List<number>;
  elevation_ids: List<number>;
  deck_ids: List<number>;
  pool_ids: List<number>;
  locations: List<string>;
  levels: List<string>;

  _destroy: Nullable<boolean>;
  loading: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IMeasurementHydratedRecord extends IMeasurementRecord {
  roof_faces: List<RoofFaceRecord>;
  wall_facades: List<WallFacadeRecord>;
  windows: List<WindowRecord>;
  doors: List<DoorRecord>;
  rooms: List<RoomRecord>;
  elevations: List<ElevationRecord>;
  decks: List<DeckRecord>;
  pools: List<PoolRecord>;
}

export const defaultMeasurementProps: IMeasurementRecord = {
  id: 0,
  geojson: geoJsonFromJSON({}),
  job_id: 0,
  system: "imperial",
  roof_1012_area: 0,
  roof_10pw_sloped_area: 0,
  roof_10pw_total_area: 0,
  roof_12pw_total_area: 0,
  roof_13p_area: 0,
  roof_0_area: 0,
  roof_1_area: 0,
  roof_2_area: 0,
  roof_3_area: 0,
  roof_4_area: 0,
  roof_5_area: 0,
  roof_6_area: 0,
  roof_7_area: 0,
  roof_8_area: 0,
  roof_9_area: 0,
  roof_10_area: 0,
  roof_11_area: 0,
  roof_12_area: 0,
  roof_13_area: 0,
  roof_14_area: 0,
  roof_15_area: 0,
  roof_16_area: 0,
  roof_17_area: 0,
  roof_18_area: 0,
  roof_19_area: 0,
  roof_20_area: 0,
  roof_21_area: 0,
  roof_22_area: 0,
  roof_23_area: 0,
  roof_24_area: 0,
  roof_25_area: 0,
  roof_26p_area: 0,
  roof_15pw_total_area: 0,
  roof_20pw_total_area: 0,
  roof_5pw_total_area: 0,
  roof_79_area: 0,
  roof_average_slope_area: 0,
  roof_downspout_elbows: 0,
  roof_downspouts: 0,
  roof_eave: 0,
  roof_flat_area: 0,
  roof_flat_shallow_area: 0,
  roof_gutter_miters: 0,
  roof_headwall_flashing: 0,
  roof_high_roof_area: 0,
  roof_hip: 0,
  roof_low_slope_area: 0,
  roof_perimeter: 0,
  roof_rake: 0,
  roof_ridge: 0,
  roof_ridge_hip: 0,
  roof_sloped_area: 0,
  roof_steep_slope_area: 0,
  roof_step_flashing: 0,
  roof_total_area: 0,
  roof_total_flashing: 0,
  roof_ultra_steep_slope_area: 0,
  roof_valley: 0,
  roof_valley_eave: 0,
  roof_valley_eave_rake: 0,
  siding_10pw_total_area: 0,
  siding_15pw_total_area: 0,
  siding_18pw_total_area: 0,
  siding_5pw_total_area: 0,
  siding_doors_qty: 0,
  siding_frieze_board: 0,
  siding_garage_doors_qty: 0,
  siding_inside_corner_length: 0,
  siding_inside_corner_qty: 0,
  siding_level_frieze_board: 0,
  siding_level_starter: 0,
  siding_opening_qty: 0,
  siding_opening_sides_length: 0,
  siding_opening_sills_length: 0,
  siding_opening_tops_length: 0,
  siding_outside_corner_length: 0,
  siding_outside_corner_qty: 0,
  siding_shutter_area: 0,
  siding_shutter_qty: 0,
  siding_sloped_frieze_board: 0,
  siding_sloped_trim: 0,
  siding_soffit_eaves: 0,
  siding_soffit_level_frieze: 0,
  siding_soffit_rakes: 0,
  siding_soffit_sloped_frieze: 0,
  siding_soffit_total: 0,
  siding_total_area: 0,
  siding_vents_area: 0,
  siding_vents_qty: 0,
  siding_vertical_trim: 0,
  siding_windows_qty: 0,
  sunroom_a_wall_area: 0,
  sunroom_a_wall_height: 0,
  sunroom_a_wall_length: 0,
  sunroom_b_wall_area: 0,
  sunroom_b_wall_height: 0,
  sunroom_b_wall_length: 0,
  sunroom_c_wall_area: 0,
  sunroom_c_wall_height: 0,
  sunroom_c_wall_length: 0,
  sunroom_cathedral_roof_area: 0,
  sunroom_ceiling_area: 0,
  sunroom_deck_total_area: 0,
  sunroom_kw_a_area: 0,
  sunroom_kw_a_height: 0,
  sunroom_kw_a_length: 0,
  sunroom_kw_b_area: 0,
  sunroom_kw_b_height: 0,
  sunroom_kw_b_length: 0,
  sunroom_kw_c_area: 0,
  sunroom_kw_c_height: 0,
  sunroom_kw_c_length: 0,
  sunroom_kw_total_area: 0,
  sunroom_kw_total_length: 0,
  sunroom_studio_roof_area: 0,
  sunroom_wall_perimeter: 0,
  sunroom_wall_total_area: 0,

  roof_face_ids: List<number>(),
  wall_facade_ids: List<number>(),
  window_ids: List<number>(),
  door_ids: List<number>(),
  room_ids: List<number>(),
  elevation_ids: List<number>(),
  deck_ids: List<number>(),
  pool_ids: List<number>(),
  locations: List<string>(),
  levels: List<string>(),

  _destroy: null,
  loading: false,
  created_at: new Date(),
  updated_at: new Date(),
};

const defaultMeasurementHydrateProps = {
  ...defaultMeasurementProps,
  roof_faces: List<RoofFaceRecord>(),
  wall_facades: List<WallFacadeRecord>(),
  windows: List<WindowRecord>(),
  doors: List<DoorRecord>(),
  rooms: List<RoomRecord>(),
  elevations: List<ElevationRecord>(),
  decks: List<DeckRecord>(),
  pools: List<PoolRecord>(),
};

export class MeasurementRecord extends Record(defaultMeasurementProps) implements IMeasurementRecord {}

export class MeasurementHydratedRecord
  extends Record(defaultMeasurementHydrateProps)
  implements IMeasurementHydratedRecord {}
