import { JobTabTitle } from "app2/src/components/JobTabs/JobTabTitle";
import { RootState } from "app2/src/reducers";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { getTitle } from "app2/src/selectors/org.selectors";
import { push } from "connected-react-router/immutable";
import * as React from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { SignatureRequest } from "../../SignatureRequest";
import { SignatureRequestButton } from "../../SignatureRequest/SignatureRequestButton";
import EstimateDocuments from "./EstimateDocuments";
import SignableDocuments from "./SignableDocuments";
import { SigningRecipientsModal } from "./SigningRecipientsModal";

export interface DocumentTabsProps {}

export const DocumentTabs: React.FC<DocumentTabsProps> = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<{ documentKind: string }>();
  const contractTitle = useSelector((state: RootState) => getTitle(state, { documentType: "contract" }));
  const jobId = useSelector(currentJobId);

  const navigateToTab = (data) => {
    dispatch(push(data));
  };

  const navigateToContracts = () => {
    dispatch(push(`/jobs/${jobId}/contracts`));
  };

  return (
    <Row>
      <Col>
        <JobTabTitle title="Signatures">
          <SignatureRequestButton />
          <Button className="pull-right" onClick={navigateToContracts}>
            Create {contractTitle} for Signature
          </Button>
        </JobTabTitle>
        <Tabs
          id="signatures-page-tabs"
          activeKey={match?.params?.documentKind}
          className="signatures-tabs mb-3"
          onSelect={navigateToTab}>
          <Tab eventKey="estimate_documents" title={`${contractTitle}s`}>
            <EstimateDocuments />
          </Tab>
          <Tab eventKey="signable_documents" title="Signable Documents">
            <SignableDocuments />
          </Tab>
        </Tabs>

        <Switch>
          <Route path={`${match.url}/signing/:signedDocumentId`} component={SigningRecipientsModal} />
          <Route path={`${match.url}/signature_request`} component={SignatureRequest} />
        </Switch>
      </Col>
    </Row>
  );
};
