import * as React from "react";
import { DateFormat } from "../../helpers/Format";
import { ModelCache } from "./ModelCache";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { IUserData } from "app2/src/records/UserRecord";

const mapStateToProps = (state: RootState, ownProps: UserStatProps) => {
  return {
    cachedUser: state.getIn(["users", "usersById", parseInt(ownProps.user.userId)], {}),
  };
};

const mapDispatchToProps = () => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface UserStatProps {
  user: any;
  ModelCache: ModelCache;
}

export interface UserStatState {
  expanded: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & UserStatProps;

export class UserStat extends React.Component<Props, UserStatState> {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  public componentDidMount() {
    const { ModelCache, user } = this.props;

    ModelCache.getUser(parseInt(user.userId));
    // loaded.$promise.then(() => {
    //   this.setState({
    //     cachedUser: loaded
    //   });
    // });
  }

  public render() {
    const { user, cachedUser } = this.props;

    return (
      <tr>
        <td>{cachedUser.email}</td>
        <td>{user.eventCount}</td>
        <td>{DateFormat(user.lastSeen, "short")}</td>
        <td>{DateFormat(user.firstSeen, "short")}</td>
      </tr>
    );
  }
}

export default connector(UserStat);
