import * as ng from "angular";
import { IDefaultOption, IEstimate } from "app/src/Models/Estimate";
import { IOpening, IOpeningMetadata, Opening } from "app/src/Models/Opening";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { AddEstimateLineItemCmd } from "app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { IEventingFactory } from "app/src/Common/EventingFactory";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IOpeningEstimation } from "app/src/Models/OpeningEstimation";
import { RemoveEstimateLineItemCmd } from "app/src/Commands/Estimator/RemoveEstimateLineItemCmd";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { IMeasurement, IMeasurementResource } from "app/src/Models/Measurement";
import { AddEstimateLineItemOptionCmd } from "app/src/Commands/Estimator/AddEstimateLineItemOptionCmd";
import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import { IAnnotation } from "../Models/Annotation";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { ISession } from "app/src/Common/SessionService";
import { ProductRecord, findProductBracket } from "app2/src/records/Product";
import { useSelector } from "app2/src/storeRegistry";
import { cachedProduct, cachedProducts, cachedOpeningParentProducts } from "app2/src/selectors/product.selectors";
import { calculatePrice, ProductOptionRecord } from "app2/src/records/ProductOption";
import { optionSelected, ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { cachedProductOptionGroup, cachedProductOptionGroups } from "app2/src/selectors/productOptionGroup.selectors";
import { cachedProductOptions } from "app2/src/selectors/productOption.selectors";
import { List } from "immutable";
import { getUrl } from "app2/src/records/Image";
import { IOrg } from "app/src/Models/Org";
import { Acl } from "app2/src/helpers/Acl";
import { presentModePreferencesConfig } from "app2/src/selectors/org.selectors";

export interface IOpeningGroup {
  product: ProductRecord;
  openings: IOpening[];
  quantity: number;
}

export class OpeningsWizard {
  public editing = false;
  public editingOpenings = false;
  public editingEstimateLineItemOpenings = false;
  public spinnerPromise: ng.IPromise<any>;

  public activeOptionGroupTab = 0;
  public measurement: IMeasurement;
  public addingOpenings = false;
  public addOpeningQuantity = 1;
  public selectedOpening: IOpeningMetadata;
  public activeStep: number;
  public openings: IOpening[];
  public openingFilter: any;
  public filters: any[];
  public selectedOpenings: IOpening[] = [];
  public selectedAnnotations: IAnnotation[] = [];
  public groupedOpenings: any;
  public siblings: IOpeningGroup[];
  public manualEstimation = false;
  public switch_products: ProductRecord[] = [];
  public show_switch = false;
  public switch_search: ProductRecord;
  public focusHere = 0;
  public addingGroup: IOpeningGroup;
  public updatingProduct = false;
  public showQuantity = false;
  public showMarkupable = false;
  public markupableBool = true;
  public showPricingInLineItemEditor = false;

  protected measurementIncludes: string[] = ["elevations"];

  private _originalState: string;

  public static $inject = [
    "$timeout",
    "$uibModalInstance",
    "EstimatorService",
    "eventingFactory",
    "org",
    "estimate",
    "parentProduct",
    "parentChildren",
    "group",
    "estimateLineItem",
    "groups",
    "ConfirmDialog",
    "Flash",
    "Measurement",
    "Session",
    "$anchorScroll",
  ];
  constructor(
    public $timeout: ng.ITimeoutService,
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public EstimatorService: EstimatorService,
    public eventingFactory: IEventingFactory,
    public org: IOrg,
    public estimate: IEstimate,
    public parentProduct: ProductRecord,
    public parentChildren: List<ProductRecord>,
    public group: IEstimateGroup,
    public estimateLineItem: IEstimateLineItem,
    public groups: IEstimateGroup[],
    public ConfirmDialog: IConfirmDialog,
    public Flash: IFlash,
    public Measurement: IMeasurementResource,
    private Session: ISession,
    protected $anchorScroll: ng.IAnchorScrollService,
  ) {
    this.measurement = this.Measurement.get({
      id: EstimatorService.measurement.id,
      "include[]": this.measurementIncludes,
    });
    this.spinnerPromise = this.measurement.$promise.then(() => {
      this._setupOpenings();
      this.groupOpenings();
    });

    this.showPricingInLineItemEditor = useSelector((state) =>
      presentModePreferencesConfig(state, {
        path: ["estimator", "show_pricing", "line_item_editor"],
      }),
    );

    if (estimateLineItem) {
      this.markupableBool = this.estimateLineItem.markupable !== "no";
      this.showMarkupable = Acl.can("update_markup", "estimate");
      const product = useSelector((state) =>
        cachedProduct(state, {
          activatedPriceListId: estimate.activated_price_list_id,
          productId: estimateLineItem.product_id,
        }),
      );
      this.addingGroup = <IOpeningGroup>{
        product: product,
        openings: _.map(this.estimateLineItem.openingEstimations(), (oe: IOpeningEstimation) => oe.openable),
        quantity: this.estimateLineItem.quantity,
      };
      this.selectAnnotations();

      const quantity_compare = this.estimateLineItem.quantity !== this.estimateLineItem.openingEstimations().length;
      this.manualEstimation = quantity_compare || this.estimateLineItem.quantity === 0;
      this.editing = true;
      this.gotoStep(2);
    }

    Session.can("update_option_quantity", "estimate").then((value) => {
      if (value) {
        this.showQuantity = true;
      }
    });
  }

  public filterOpenings = (opening: IOpening) => {
    if (this.estimateLineItem) {
      const bracket: ProductRecord = findProductBracket(this.parentProduct, this.parentChildren, opening);

      if (!bracket) {
        return false;
      }
      if (this.parentProduct.bracket_type === "per_sqft") {
        if (
          bracket.id !== this.estimateLineItem.product_id ||
          this.estimateLineItem.openingEstimations()[0].openable.area !== opening.area
        ) {
          return false;
        }
      } else if (this.parentProduct.bracket_type === "per_ui") {
        if (
          bracket.id !== this.estimateLineItem.product_id ||
          this.estimateLineItem.openingEstimations()[0].openable.ui !== opening.ui
        ) {
          return false;
        }
      } else if (bracket.id !== this.estimateLineItem.product_id) {
        return false;
      }
    }

    if (!this.openingFilter) {
      return true;
    }

    switch (this.openingFilter.type) {
      case "location":
        return opening.location === this.openingFilter.value;
      case "level":
        return opening.level === this.openingFilter.value;
      case "name":
        return opening.name === this.openingFilter.value;
    }
  };

  public gotoStep(idx: number) {
    this.$timeout(() => {
      this.activeOptionGroupTab = 0;
    });
    this.activeStep = idx;
    if (this.activeStep === 2) {
      if (this.manualEstimation) {
        this.switch_products = useSelector((state) =>
          cachedOpeningParentProducts(state, {
            activatedPriceListId: this.estimate.activated_price_list_id,
            openings: [this.manualEstimationOpening()],
          }),
        ).toArray();
      } else {
        this.switch_products = useSelector((state) =>
          cachedOpeningParentProducts(state, {
            activatedPriceListId: this.estimate.activated_price_list_id,
            openings: this.addingGroup.openings,
          }),
        ).toArray();
      }
    }
  }

  public select(opening: IOpening) {
    this.eventingFactory.trackEvent("select opening", {
      category: "Openings LineItemEditor",
      opening_id: opening.id,
      selected_quantity: this.addingGroup ? this.addingGroup.openings.length : this.selectedOpenings.length,
      parent_id: this.parentProduct.id,
    });

    if (this.addingGroup) {
      this.addingGroup.openings.push(opening);
    } else {
      this.selectedOpenings.push(opening);
      this.groupOpenings();
    }
  }

  public selectAll() {
    this.eventingFactory.trackEvent("select all opening", {
      category: "Openings LineItemEditor",
      opening_filter: this.openingFilter,
      estimate_line_item_id: this.estimateLineItem ? this.estimateLineItem.id : "",
      selected_quantity: this.addingGroup ? this.addingGroup.openings.length : this.selectedOpenings.length,
      parent_id: this.parentProduct.id,
    });

    if (this.addingGroup) {
      _.each(this.openings, (o: IOpening) => {
        if (this.filterOpenings(o) && !this.added(o)) {
          if (!_.any(this.addingGroup.openings, (m) => m.id === o.id && m.kind === o.kind)) {
            this.addingGroup.openings.push(o);
          }
        }
      });
    } else {
      _.each(this.openings, (o: IOpening) => {
        if (this.filterOpenings(o) && !this.added(o)) {
          if (!_.any(this.selectedOpenings, (m) => m.id === o.id && m.kind === o.kind)) {
            this.selectedOpenings.push(o);
          }
        }
      });
      this.groupOpenings();
    }
  }

  public unselectAll() {
    this.eventingFactory.trackEvent("unselect all opening", {
      category: "Openings LineItemEditor",
      opening_filter: this.openingFilter,
      estimate_line_item_id: this.estimateLineItem ? this.estimateLineItem.id : "",
      selected_quantity: this.addingGroup ? this.addingGroup.openings.length : this.selectedOpenings.length,
      parent_id: this.parentProduct.id,
    });

    if (this.addingGroup) {
      _.each(this.openings, (o: IOpening) => {
        if (this.filterOpenings(o)) {
          this.addingGroup.openings = _.select(this.addingGroup.openings, (match: IOpening) => {
            return o.id !== match.id || o.kind !== match.kind;
          });
        }
      });
    } else {
      _.each(this.openings, (o: IOpening) => {
        if (this.filterOpenings(o)) {
          this.selectedOpenings = _.select(this.selectedOpenings, (match: IOpening) => {
            return o.id !== match.id || o.kind !== match.kind;
          });
        }
      });
      this.groupOpenings();
    }
  }

  public allSelected() {
    let collection;
    if (this.addingGroup) {
      collection = this.addingGroup.openings;
    } else {
      collection = this.selectedOpenings;
    }

    return _.chain(this.openings)
      .select((o) => this.filterOpenings(o))
      .reject(_.bind(this.added, this))
      .all((o: IOpening) => _.any(collection, (m: IOpening) => o.id === m.id && o.kind === m.kind))
      .value();
  }

  public unselect(opening: IOpening) {
    this.eventingFactory.trackEvent("unselect opening", {
      category: "Openings LineItemEditor",
      opening_id: opening.id,
      selected_quantity: this.addingGroup ? this.addingGroup.openings.length : this.selectedOpenings.length,
      parent_id: this.parentProduct.id,
    });

    if (this.addingGroup) {
      this.addingGroup.openings = _.select(this.addingGroup.openings, (o: IOpening) => {
        return o.id !== opening.id;
      });
    } else {
      this.selectedOpenings = _.select(this.selectedOpenings, (o: IOpening) => {
        return o.id !== opening.id;
      });
      this.groupOpenings();
    }
  }

  public selected(opening: IOpening) {
    if (this.addingGroup) {
      return _.any(this.addingGroup.openings, (o: IOpening) => o.id === opening.id && o.kind === opening.kind);
    }

    return _.any(this.selectedOpenings, (o: IOpening) => o.id === opening.id && o.kind === opening.kind);
  }

  public added(opening: IOpening) {
    return _.any(this.group.existingLineItems(), (eli: IEstimateLineItem) => {
      const match = _.any(eli.openingEstimations(), (oe: IOpeningEstimation) => {
        return opening.id === oe.openable_id && opening.kind === oe.openable_type;
      });

      if (this.estimateLineItem) {
        return match && this.estimateLineItem.id !== eli.id;
      }

      return match;
    });
  }

  public selectOption(optionGroup: ProductOptionGroupRecord, option: ProductOptionRecord, quantity: number) {
    if (optionGroup.selection_mode === "single") {
      const elio: IEstimateLineItemOption = _.find(
        this.estimateLineItem.existingOptions(),
        (e: IEstimateLineItemOption) => {
          return e.product_option_group_id === optionGroup.id;
        },
      );

      if (elio) {
        this.unselectOption(elio.product_option_id, elio.product_option_uuid);

        this.eventingFactory.trackEvent("product option changed", {
          category: "Openings LineItemEditor",
          product_option: option.id,
        });
      } else {
        this.eventingFactory.trackEvent("product option added", {
          category: "Openings LineItemEditor",
          product_option: option.id,
        });
      }

      const cmd_po = new AddEstimateLineItemOptionCmd(option, optionGroup, this.estimateLineItem, this.estimate);
      cmd_po.execute(quantity);
    } else {
      const cmd_po = new AddEstimateLineItemOptionCmd(option, optionGroup, this.estimateLineItem, this.estimate);
      cmd_po.execute(quantity);
      this.eventingFactory.trackEvent("product option added", {
        category: "Openings LineItemEditor",
        product_option: option.id,
      });
    }
  }

  public selectedOption(optionGroup: ProductOptionGroupRecord, option: ProductOptionRecord) {
    return _.chain(this.estimateLineItem.existingOptions())
      .select((elio: IEstimateLineItemOption) => !elio._destroy)
      .any((elio: IEstimateLineItemOption) => {
        return elio.product_option_uuid === option.uuid;
      })
      .value();
  }

  public unselectOption(productOptionId: number, productOptionUuid: string) {
    const addedOption = _.find(
      this.estimateLineItem.existingOptions(),
      (elio) => elio.product_option_uuid === productOptionUuid,
    );

    if (addedOption.id && addedOption.id > 0) {
      addedOption._destroy = true;
    } else {
      this.estimateLineItem.options = _.select(this.estimateLineItem.options, (elio: IEstimateLineItemOption) => {
        return elio._destroy === true || elio.product_option_uuid !== productOptionUuid;
      });
    }

    this.eventingFactory.trackEvent("product option removed", {
      category: "Openings LineItemEditor",
      product_option: productOptionId,
    });
  }

  public initOptionQuantity(optionGroup: ProductOptionGroupRecord, productOption: ProductOptionRecord): number {
    const elio: IEstimateLineItemOption = _.find(
      this.estimateLineItem.existingOptions(),
      (e: IEstimateLineItemOption) => {
        return e.product_option_group_id === optionGroup.id && e.product_option_uuid === productOption.uuid;
      },
    );
    if (elio) {
      return elio.quantity;
    }

    return this.estimateLineItem.openingEstimationMeasurement(productOption.charge_type, 1);
  }

  public optionQuantityChanged(
    optionGroup: ProductOptionGroupRecord,
    productOption: ProductOptionRecord,
    quantity: number,
  ): void {
    const elio: IEstimateLineItemOption = _.find(
      this.estimateLineItem.existingOptions(),
      (e: IEstimateLineItemOption) => {
        return e.product_option_group_id === optionGroup.id && e.product_option_uuid === productOption.uuid;
      },
    );

    if (elio) {
      elio.quantity = quantity;
      elio.calculate(this.estimate);
    }
  }

  public pricingChanged() {
    this.estimateLineItem.calculate(this.estimate);
  }

  public addProducts(group: IOpeningGroup) {
    this.addingGroup = group;
    this.selectAnnotations();

    const cmd = new AddEstimateLineItemCmd(this.estimate, group.product, this.group, this.EstimatorService);
    cmd.execute();

    this.eventingFactory.trackEvent("opening product added", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
      product_id: group.product.id,
      estimate_group_name: this.group.name,
      estimate_group_id: this.group.id,
      quantity: group.quantity,
    });

    this.estimateLineItem = cmd.lineItem;
    this.estimateLineItem.quantity = group.quantity;
    _.each(group.openings, (o: IOpening) => {
      this.estimateLineItem.opening_estimations.push(<IOpeningEstimation>{
        openable_type: o.kind,
        openable_id: o.id,
        openable: o,
      });
    });
    this.EstimatorService.addDefaultOptions(cmd.lineItem, group.product);
    const defaults = this.estimate.defaults;
    if (_.isUndefined(defaults) || _.isUndefined(defaults.options) || Object.keys(defaults.options).length > 0) {
      this.updateLineItemOptions();
    }
    this.estimateLineItem.windowPriceAdjustment(group.product);
    this.estimateLineItem.calculate(this.estimate);

    this.gotoStep(2);
  }

  public changeEliProduct() {
    this.eventingFactory.trackEvent("change product", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
      product_id: this.estimateLineItem.product_id,
      estimate_line_item_id: this.estimateLineItem.id > 0 ? this.estimateLineItem.id : "new",
      new_product_id: this.addingGroup.product.id,
    });

    if (this.manualEstimation) {
      this.changeEliProductAndOptions(this.addingGroup.product);
    }
  }

  public changeEliProductAndOptions(newProduct: ProductRecord) {
    this.updatingProduct = true;

    const newOptionGroups = useSelector((state) =>
      cachedProductOptionGroups(state, {
        activatedPriceListId: this.estimate.activated_price_list_id,
        productId: newProduct.id,
      }),
    );
    const [new_options, quantities, rejected_options] = this.estimateLineItem.compareOptions(
      this.estimate.activated_price_list_id,
      newOptionGroups,
    );

    const changes = this.estimateLineItem.existingOptions().length - new_options.length;
    if (changes !== 0) {
      this.ConfirmDialog.confirm(
        changes +
          " options will be lost. Are you sure you want to change products?\n\n" +
          rejected_options.join("\n").trim(),
        {
          size: "md",
        },
      ).then(
        () => {
          this.confirmedChangeProduct(new_options, quantities, newProduct);
        },
        () => (this.updatingProduct = false),
      );
    } else {
      this.confirmedChangeProduct(new_options, quantities, newProduct);
    }
  }

  public confirmedChangeProduct(
    new_options: ProductOptionRecord[],
    quantities: Map<number, number>,
    product: ProductRecord,
  ) {
    this.addingGroup.product = product;
    this.parentProduct = useSelector((state) =>
      cachedProduct(state, {
        activatedPriceListId: this.estimate.activated_price_list_id,
        productId: product.parent_id,
      }),
    );
    this.parentChildren = useSelector((state) =>
      cachedProducts(state, {
        activatedPriceListId: this.estimate.activated_price_list_id,
        productIds: this.parentProduct.children_ids,
      }),
    );

    this.estimateLineItem.setProduct(this.estimate.activated_price_list_id, this.addingGroup.product);
    this.estimateLineItem.calculate(this.estimate);

    _.each(this.estimateLineItem.existingOptions(), (elio) => {
      this.unselectOption(elio.product_option_id, elio.product_option_uuid);
    });

    _.each(new_options, (new_opt: ProductOptionRecord) => {
      const pog = useSelector((state) =>
        cachedProductOptionGroup(state, {
          activatedPriceListId: this.estimate.activated_price_list_id,
          productId: new_opt.option_group_id,
        }),
      );
      const quantity = quantities.get(new_opt.id);
      this.selectOption(pog, new_opt, quantity);
    });

    this.eventingFactory.trackEvent("change product", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
      product_id: this.estimateLineItem.product_id,
      estimate_line_item_id: this.estimateLineItem.id > 0 ? this.estimateLineItem.id : "new",
      new_product_id: this.addingGroup.product.id,
    });

    this.$timeout(() => {
      this.updatingProduct = false;
      this.activeOptionGroupTab = 0;
    });
  }

  public blurTypeAhead() {
    this.$timeout(() => {
      this.show_switch = false;
    }, 500);
  }

  public finishAddProduct() {
    const openings = this.addingGroup.openings;
    const eli = this.estimateLineItem;
    this.addingGroup.quantity = 0;
    this.addingGroup = null;
    this.estimateLineItem = null;

    _.each(openings, (o: IOpening) => this.unselect(o));

    this.eventingFactory.trackEvent("adding estimate line item", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
      product_id: eli.product_id,
      openings: _.map(openings, (o: IOpening) => o.id),
    });

    if (_.keys(this.groupedOpenings).length > 0 || _.any(this.siblings, (s: IOpeningGroup) => s.quantity > 0)) {
      this.gotoStep(1);
    } else {
      this.$uibModalInstance.close([eli, eli.estimateGroup]);
    }
  }

  public cancelEditAndGoBack() {
    this._cancel();
    this.eventingFactory.trackEvent("cancel adding current group", {
      category: "Openings LineItemEditor",
    });
    this.selectedAnnotations = [];
    this.gotoStep(1);
  }

  public cancelEditAndClose() {
    this.eventingFactory.trackEvent("cancel editing line item", {
      category: "Openings LineItemEditor",
      quantity: this.addOpeningQuantity,
      estimate_line_item_id: this.estimateLineItem.id > 0 ? this.estimateLineItem.id : "new",
    });
    this._cancel();

    this.$uibModalInstance.dismiss("cancel");
  }

  public cancelAddProductAndClose() {
    this.eventingFactory.trackEvent("cancel adding product", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
    });
    this._cancel();

    this.$uibModalInstance.dismiss("cancel");
  }

  public productOptionGroupsByType(type: string): ProductOptionGroupRecord[] {
    if (this.estimateLineItem && this.estimateLineItem.product_id) {
      return useSelector((state) =>
        cachedProductOptionGroups(state, {
          activatedPriceListId: this.estimate.activated_price_list_id,
          productId: this.estimateLineItem.product_id,
          type: type,
        }),
      ).toArray();
    } else {
      return [];
    }
  }

  public productOptions(productOptionIds: List<number>): ProductOptionRecord[] {
    return useSelector((state) =>
      cachedProductOptions(state, {
        activatedPriceListId: this.estimate.activated_price_list_id,
        productOptionIds,
      }),
    ).toArray();
  }

  public calculateProductOptionPrice(productOption: ProductOptionRecord) {
    return calculatePrice(productOption, this.estimate, this.estimateLineItem);
  }

  public highlightGroup(group: IOpeningGroup) {
    this.selectedAnnotations = _.chain(group.openings).pluck("annotation").compact().value();
  }

  public startAddingOpening() {
    this.cancelEditingOpening();
    this.addingOpenings = true;
    this.selectedOpening = <IOpeningMetadata>{ kind: "Window", quantity: 1 };
    this.eventingFactory.trackEvent("editing opening measurements", {
      category: "Openings LineItemEditor",
    });

    this.saveState();
  }

  public startEditingOpening(opening) {
    this.$anchorScroll("openingForm");
    this.cancelAddingOpening();
    this.selectedOpening = _.clone(opening);
    this.editingOpenings = true;
    this.eventingFactory.trackEvent("edit opening measurement", {
      category: "Openings LineItemEditor",
      opening_id: opening.id > 0 ? opening.id : "new",
    });
    this.saveState();
  }

  public cancelAddingOpening() {
    this.selectedOpening = null;
    this.addingOpenings = false;
    this.$anchorScroll("startAdding");
  }

  public cancelEditingOpening() {
    this.selectedOpening = null;
    this.editingOpenings = false;
    this.$anchorScroll("startAdding");
  }

  public saveOpening() {
    this.eventingFactory.trackEvent("save opening measurement", {
      category: "Openings LineItemEditor",
      opening_id: this.selectedOpening.id > 0 ? this.selectedOpening.id : "new",
    });
    Opening.addOpenings(this.measurement, this.selectedOpening);
    this.editingOpenings = false;
    this.selectedOpening = null;
  }

  public addOpenings() {
    this.addingOpenings = false;

    this.eventingFactory.trackEvent("adding manual opening measurement", {
      category: "Openings LineItemEditor",
      quantity: this.selectedOpening.quantity,
    });

    Opening.addOpenings(this.measurement, this.selectedOpening);

    this.selectedOpening = null;
  }

  public checkState() {
    return this._originalState === JSON.stringify(JSON.decycle(this.selectedOpening));
  }

  public resetMeasurements() {
    this.eventingFactory.trackEvent("cancel editing opening measurements", {
      category: "Openings LineItemEditor",
    });
    this.selectedOpening = null;
    this.addingOpenings = false;
    this.editingOpenings = false;
    this.measurement.$get({ "include[]": this.measurementIncludes }, () => {
      this._setupOpenings();
      this.gotoStep(0);
    });
  }

  public saveMeasurements() {
    this.eventingFactory.trackEvent("save edits on opening measurements", {
      category: "Openings LineItemEditor",
    });
    this.measurement.$update({ "include[]": this.measurementIncludes }).then(() => {
      this._setupOpenings();
      this.gotoStep(0);
    });
  }

  public goManual() {
    this.manualEstimation = true;
    this.eventingFactory.trackEvent("skip line item openings", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
    });
    this.gotoStep(1);
  }

  public editEstimateLineItemOpenings() {
    this.eventingFactory.trackEvent("edit line item openings", {
      category: "Openings LineItemEditor",
      estimate_line_item_id: this.estimateLineItem.id > 0 ? this.estimateLineItem.id : "new",
      parent_id: this.parentProduct.id,
      product_id: this.estimateLineItem.product_id,
      quantity: this.estimateLineItem.openingEstimations().length,
    });
    this.editingEstimateLineItemOpenings = true;
    this.gotoStep(0);
  }

  public updateOpeningsSelection() {
    this.estimateLineItem.opening_estimations = _.filter(
      this.estimateLineItem.opening_estimations,
      (oe: IOpeningEstimation) => {
        const match = _.any(
          this.addingGroup.openings,
          (o: IOpening) => o.kind === oe.openable_type && o.id === oe.openable_id,
        );

        if (match) {
          delete oe._destroy;
          return true;
        }

        if (oe.id > 0) {
          oe._destroy = true;
          return true;
        } else {
          return false;
        }
      },
    );

    _.each(this.addingGroup.openings, (o: IOpening) => {
      if (
        _.any(
          this.estimateLineItem.opening_estimations,
          (oe: IOpeningEstimation) => oe.openable_id === o.id && oe.openable_type === o.kind,
        )
      ) {
        return;
      }

      this.estimateLineItem.opening_estimations.push(<IOpeningEstimation>{
        openable_type: o.kind,
        openable_id: o.id,
        openable: o,
      });
    });

    this.estimateLineItem.quantity = this.estimateLineItem.openingEstimations().length;
    this.estimateLineItem.windowPriceAdjustment(this.addingGroup.product);
    this.estimateLineItem.calculate(this.estimate);
    this.editingEstimateLineItemOpenings = false;

    this.eventingFactory.trackEvent("line item openings edited", {
      category: "Openings LineItemEditor",
      estimate_line_item_id: this.estimateLineItem.id > 0 ? this.estimateLineItem.id : "new",
      parent_id: this.parentProduct.id,
      product_id: this.estimateLineItem.product_id,
      quantity: this.estimateLineItem.openingEstimations().length,
    });
    this.gotoStep(2);
  }

  public cancelOpeningsSelection() {
    this.addingGroup.openings = _.map(
      this.estimateLineItem.openingEstimations(),
      (oe: IOpeningEstimation) => oe.openable,
    );
    this.editingEstimateLineItemOpenings = false;

    this.eventingFactory.trackEvent("line item openings canceled", {
      category: "Openings LineItemEditor",
      estimate_line_item_id: this.estimateLineItem.id > 0 ? this.estimateLineItem.id : "new",
      parent_id: this.parentProduct.id,
      product_id: this.estimateLineItem.product_id,
      quantity: this.estimateLineItem.openingEstimations().length,
    });
    this.gotoStep(2);
  }

  public goBackToMeasurements() {
    this.eventingFactory.trackEvent("back to measurements", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
    });

    this.manualEstimation = false;
    this.gotoStep(0);
  }

  public goToAddOpenings() {
    this.eventingFactory.trackEvent("add opening measurements", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
    });

    this.gotoStep(3);
  }

  public goToBrackets() {
    this.eventingFactory.trackEvent("go to brackets", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
      openings: _.map(this.selectedOpenings, (o: IOpening) => o.id),
    });

    this.selectedAnnotations = [];
    this.gotoStep(1);
  }

  public filterChanged() {
    this.eventingFactory.trackEvent("opening filter selected", {
      category: "Openings LineItemEditor",
      parent_id: this.parentProduct.id,
      filter: this.openingFilter,
    });
  }

  public toggleShowSwitch() {
    this.switch_search = { ancestral_name: "" } as unknown as ProductRecord;
    this.focusHere += 1;
    this.show_switch = !this.show_switch;
  }

  public selectTypeAhead() {
    this.show_switch = !this.show_switch;
    const childProducts = useSelector((state) =>
      cachedProducts(state, {
        activatedPriceListId: this.estimate.activated_price_list_id,
        productIds: this.switch_search.children_ids,
      }),
    );
    let opening = this.addingGroup.openings[0];
    if (this.manualEstimation) {
      opening = this.manualEstimationOpening();
    }
    const product = findProductBracket(this.switch_search, childProducts, opening);

    this.changeEliProductAndOptions(product);
  }

  public manualEstimationOpening() {
    const opening_ui = (this.addingGroup.product.min_ui + this.addingGroup.product.max_ui) / 2;
    return <IOpening>{ ui: opening_ui, width: opening_ui / 2, height: opening_ui / 2 };
  }

  public setDefaultOptions() {
    this.estimate.defaults.options = {} as { string: IDefaultOption };
    _.each(this.estimateLineItem.existingOptions(), (opt) => {
      const productOptionGroup = useSelector((state) =>
        cachedProductOptionGroup(state, {
          activatedPriceListId: this.estimate.activated_price_list_id,
          productOptionGroupId: opt.product_option_group_id,
        }),
      );
      this.estimate.defaults.options[opt.product_option_match_id] = {
        quantity: opt.quantity,
        option_group_uuid: productOptionGroup.match_id,
      };
    });
    this.Flash.addMessage(FlashLevels.success, "Successfully set Estimate Default Options");
    this.eventingFactory.trackEvent("set estimate default options", {
      category: "Openings LineItemEditor",
      estimate_line_item_id: this.estimateLineItem.id > 0 ? this.estimateLineItem.id : "new",
      parent_id: this.parentProduct.id,
      product_id: this.estimateLineItem.product_id,
    });
  }

  public useDefaultOptions() {
    this.updateLineItemOptions();
    this.Flash.addMessage(FlashLevels.success, "Successfully used Estimate Default Options");
  }

  public updateLineItemOptions() {
    _.each(this.estimateLineItem.existingOptions(), (elio) => {
      this.unselectOption(elio.product_option_id, elio.product_option_uuid);
    });

    this.EstimatorService.addEstimateDefaultOptions(this.estimateLineItem);
    this.eventingFactory.trackEvent("use estimate default options", {
      category: "Openings LineItemEditor",
      estimate_line_item_id: this.estimateLineItem.id > 0 ? this.estimateLineItem.id : "new",
      parent_id: this.parentProduct.id,
      product_id: this.estimateLineItem.product_id,
    });

    // Hides then Shows Product Options Tabs to update quantities (runs ng-init)
    this.updatingProduct = true;
    this.$timeout(() => {
      this.updatingProduct = false;
    });
  }

  public optionSelected(optionGroup: ProductOptionGroupRecord, lineItem: IEstimateLineItem) {
    return optionSelected(List(this.productOptions(optionGroup.option_ids)), lineItem);
  }

  public lineItemOptionImage(productOption: ProductOptionRecord) {
    if (productOption && productOption.images && productOption.images.size > 0) {
      return getUrl(productOption.images.first(), "medium");
    }

    return false;
  }

  public updateMarkupable() {
    this.estimateLineItem.markupable = this.markupableBool ? "yes" : "no";
    this.pricingChanged();
  }

  private _setupOpenings() {
    this.openings = _.chain([this.measurement.windows || [], this.measurement.doors || []])
      .flatten()
      .compact()
      .sortBy((o: IOpening) => {
        return o.width;
      })
      .sortBy((o: IOpening) => {
        return o.height;
      })
      .sortBy((o: IOpening) => {
        return o.name;
      })
      .sortBy((o: IOpening) => {
        return o.ui;
      })
      .value();

    this.filters = _.chain(this.openings)
      .map((o: IOpening) => {
        return [
          { type: "location", name: "Location: " + o.location, value: o.location },
          { type: "level", name: "Level: " + o.level, value: o.level },
          { type: "name", name: o.name, value: o.name },
        ];
      })
      .unzip()
      .map((list: any[]) => {
        return _.sortBy(list, (obj: any) => {
          return obj.name;
        });
      })
      .flatten()
      .select((v) => {
        return v.value !== null && v.value !== undefined;
      })
      .uniq((a) => a.type.toString() + a.value.toString())
      .value();
  }

  private saveState() {
    this._originalState = JSON.stringify(JSON.decycle(this.selectedOpening));
  }

  private groupOpenings() {
    this.groupedOpenings = _.chain(this.selectedOpenings)
      .map((opening: IOpening) => {
        const bracket: ProductRecord = findProductBracket(this.parentProduct, this.parentChildren, opening);
        return [bracket || "$nomatch", opening];
      })
      .groupBy((value) => {
        if (value[0] === "$nomatch") {
          return value[0];
        }
        if ((value as any)[0].bracket_type === "per_sqft") {
          return `${(value as any)[0].id}-${(value[1] as any).area}`;
        }
        if ((value as any)[0].bracket_type === "per_ui") {
          return `${(value as any)[0].id}-${(value[1] as any).ui}`;
        }
        return (value as any)[0].id;
      })
      .mapObject((value) => {
        return {
          product: value[0][0],
          openings: _.map(value, (v) => v[1]),
          quantity: value.length,
        };
      })
      //.tap((a) => console.info("tap", a))
      .value();

    this.siblings = this.parentChildren
      .filter((p: ProductRecord) => {
        return _.isUndefined(this.groupOpenings[p.id]);
      })
      .map((p: ProductRecord) => {
        return {
          product: p,
          openings: [],
          quantity: 0,
        };
      })
      .toArray();
    this.selectAnnotations();
  }

  private selectAnnotations() {
    if (this.addingGroup) {
      this.selectedAnnotations = _.chain(this.addingGroup.openings).pluck("annotation").compact().value();
    } else {
      this.selectedAnnotations = _.chain(this.selectedOpenings).pluck("annotation").compact().value();
    }
  }

  private _cancel() {
    if (this.estimateLineItem && !this.editing) {
      const cmd = new RemoveEstimateLineItemCmd(this.estimate, this.estimateLineItem);
      cmd.execute();
    }

    this.addingGroup = null;
    this.estimateLineItem = null;
  }
}
