import * as React from "react";
import { IPretty } from "app/src/Common/PrettyNameService";
import { DraggableRowProps } from "app2/src/components/Common/ListManager/components/DragTable";
import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { StoreRegistry } from "app2/src/storeRegistry";

interface StringTableState {}
interface StringTableRowProps extends DraggableRowProps {
  option: string;
}

export class StringTableRow extends React.Component<StringTableRowProps, StringTableState> {
  public Pretty: IPretty;

  constructor(props: StringTableRowProps) {
    super(props);

    this.Pretty = StoreRegistry.get("Pretty");
  }

  public render(): JSX.Element {
    const { option, id, addOption, removeOption, index } = this.props;

    return (
      <Draggable key={option} draggableId={option.toString()} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          let draggingClass = "";
          if (snapshot.isDragging) {
            draggingClass = "table-row-dragging";
          }
          return (
            <tr
              className={draggingClass}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}>
              <td>
                <img
                  className="dragHandle link"
                  src="/assets/images/icons/ic_sort.ea6f8933.png"
                  title={"Move " + this.Pretty.name[option]}
                />{" "}
                {"\u00A0"}
                {this.Pretty.name[option] ? this.Pretty.name[option] : option.toString()}
              </td>
              <td>
                {id === "available" ? (
                  <i
                    className="rsf-plus-link rsf-base-66"
                    title={"Add " + this.Pretty.name[option]}
                    onClick={() => {
                      addOption(option);
                    }}></i>
                ) : (
                  <i
                    className="rsf-delete-link rsf-base-66"
                    title={"Remove " + this.Pretty.name[option]}
                    onClick={() => {
                      removeOption(option);
                    }}></i>
                )}
              </td>
            </tr>
          );
        }}
      </Draggable>
    );
  }
}
