import * as React from "react";
import { List } from "immutable";
import track from "react-tracking";

interface BreadCrumbsProps {
  parents: List<any>;
  showRoot?: boolean;
  navigate: (id: number) => void;
}

// Supports Closure Tree Objects with an ID
@track((props) => {
  return {
    component: "Folder",
    folder: props.folderId,
  };
})
export default class BreadCrumbs extends React.Component<BreadCrumbsProps> {
  constructor(props: BreadCrumbsProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  @track((props, _state, [idx]) => ({
    action: "navigate",
    folder: props.parents.getIn([idx, "id"]),
  }))
  public handleClick(idx: number): void {
    const { showRoot, navigate, parents } = this.props;
    if (idx === 0 && showRoot) {
      navigate(undefined);
      return;
    }

    navigate(parents.get(idx).id);
  }

  public render(): React.ReactNode {
    const { parents } = this.props;

    return (
      <span data-testid="BreadCrumbs">
        {parents.map((parent, idx) => (
          <span key={parent.id.toString()} data-testid={`parent-${idx}`}>
            <a href="#" onClick={() => this.handleClick(idx)}>
              {parent.name}
            </a>
            {parents.size !== idx + 1 && <span>&nbsp;{">"}&nbsp;</span>}
          </span>
        ))}
      </span>
    );
  }
}
