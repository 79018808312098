import * as React from "react";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { isCurrentPageLoading, getPaginationByModel } from "app2/src/selectors/pagination.selectors";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as reportActions from "app2/src/reducers/report.actions";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { queryParamsFromJSON } from "app2/src/records/Page";
import { RootState } from "app2/src/reducers";
import { connectFC } from "app2/src/connect";
import { StoreRegistry } from "app2/src/storeRegistry";
import { IPretty } from "app/src/Common/PrettyNameService";
import { DateFormat } from "app2/src/helpers/Format";
import { PaginationControl } from "app2/src/components/Common/PaginationControl";

const ReportList: React.FC = () => {
  // Constants
  const modelName = "reports";
  const pretty: IPretty = StoreRegistry.get("Pretty");

  // Hooks
  const dispatch = useDispatch();

  // Selectors
  const jobId = useSelector(currentJobId);
  const reports = useSelector((state: RootState) =>
    getPaginationByModel(state, { path: [modelName, "byId"], modelName }),
  );
  const loading = useSelector((state: RootState) => isCurrentPageLoading(state, { modelName }));

  // Methods
  const load = async (page: number) => {
    await dispatch(
      reportActions.AsyncActions.listReports(
        queryParamsFromJSON({ page, parameters: { reportable_id: jobId, reportable_type: "Job" } }),
      ),
    );
  };

  // Lifecycle
  React.useEffect(() => {
    load(1);
  }, []);

  if (!loading && reports?.size === 0) return null;

  return (
    <Row>
      <Col>
        <SpinnerComponent localProperty={loading} />
        <h4>Reports</h4>
        <Row className="reports-scroll">
          <Col>
            <Table striped>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report) => (
                  <tr key={report.id}>
                    <td>
                      <p>
                        {pretty.name[report.provider]} {pretty.name[report.kind]} Report
                      </p>
                    </td>
                    <td>
                      <p title={report.order_result?.error_message}>{_.toTitleCase(report.status)}</p>
                    </td>
                    <td>
                      <p>{DateFormat(report.created_at, "short")}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <PaginationControl modelName={modelName} pageChanged={load} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default connectFC(ReportList);
