import * as ng from "angular";
import * as angulartics from "angulartics";
import { IPretty } from "../Common/PrettyNameService";
import { IReportResponse } from "../Models/Report";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IUser } from "../Models/User";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IOrg } from "app/src/Models/Org";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { ISession } from "app/src/Common/SessionService";
import { IRepository } from "app/src/Common/Repository";
import { IHeaderSearchArgs, IHeaderShowArgs } from "app/src/Header/HeaderCtrl";

export class ReportListCtrl {
  public spinnerPromise: ng.IPromise<any>;
  public org: IOrg;
  public reportResponse: any;
  public totalCount = 0;
  public currentPage = 1;
  public perPage = 20;
  public sort: any = {
    by: "reports.created_at",
    order: "desc",
  };
  public queryParams: any = {
    id: null,
    job_id: null,
    provider: null,
    kind: null,
    status: null,
  };
  public providers: string[] = this.BaseConfig.REPORT_PROVIDERS;
  public kinds: string[] = this.BaseConfig.REPORT_KINDS;
  public statuses: string[] = [
    "created",
    "ordered",
    "exception",
    "canceled",
    "complete",
    "paid",
    "refunded",
    "partially_refunded",
  ];
  public searchVisible = false;
  public search: string;

  protected users: { number: IUser } = {} as { number: IUser };
  protected orgs: { number: IOrg } = {} as { number: IOrg };

  private validSortOrders: string[] = [
    "reports.id",
    "reports.created_at",
    "reports.status",
    "users.last_name, users.first_name",
    "reports.provider, reports.kind",
    "reports.delivered_at",
  ];

  public static $inject = [
    "$location",
    "$state",
    "$stateParams",
    "Repository",
    "Pretty",
    "$window",
    "BaseConfig",
    "$uibModal",
    "OrgFetcher",
    "Session",
    "$scope",
    "$analytics",
    "$rootScope",
  ];

  constructor(
    public $location: ng.ILocationService,
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public Repository: IRepository,
    public Pretty: IPretty,
    public $window: ng.IWindowService,
    public BaseConfig: IBaseConfig,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public OrgFetcher: IOrgFetcherService,
    public Session: ISession,
    public $scope: ng.IScope,
    public $analytics: angulartics.IAnalyticsService,
    public $rootScope: RsfRootScope
  ) {
    $scope.$emit("header:search", <IHeaderShowArgs>{ show: true });
    $scope.$on("header:search_change", (e: ng.IAngularEvent, args: IHeaderSearchArgs) => {
      this.$analytics.eventTrack("search", { category: "Report List" });
      this.search = args.searchString;
      this.$location.search("q", this.search);
      this.spinnerPromise = this.query();
    });
    this.spinnerPromise = this.updateFromQuerystring().then(() => {
      if (_.isUndefined(this.search) || this.search.length > 0) {
        $rootScope.$emit("header:search_update", { searchString: this.search });
      }
      this.totalCount = this.reportResponse.meta.pagination.total_count;
      return this.OrgFetcher.loadOrg(this.Session.currentUser.org_id).then((org: IOrg) => {
        this.org = org;
      });
    });
  }

  public sortBy(by: string) {
    if (!_.include(this.validSortOrders, by)) {
      return;
    }

    if (this.sort.by !== by) {
      this.sort.by = by;
      this.sort.order = "desc";
    } else {
      this.sort.order = this.sort.order === "asc" ? "desc" : "asc";
    }

    this.$location.search("by", this.sort.by);
    this.$location.search("order", this.sort.order);

    this.query();
  }

  public sortingBy(by: string, order: string) {
    return this.sort.by === by && this.sort.order === order;
  }

  public pageChanged() {
    this.$location.search("page", this.currentPage);
    return this.query();
  }

  public updateFromQuerystring() {
    this.currentPage = parseInt(this.$stateParams["page"]) || this.currentPage;
    this.perPage = parseInt(this.$stateParams["per"]) || this.perPage;
    this.sort.by = this.$stateParams["by"] || this.sort.by;
    this.sort.order = this.$stateParams["order"] || this.sort.order;
    this.search = this.$stateParams["q"] || this.search;
    return this.query();
  }

  public clearSearch() {
    this.search = "";
    this.$location.search("q", this.search);
    return this.query();
  }

  public query(): ng.IPromise<any> {
    const params: any = {
      page: this.currentPage,
      per_page: this.perPage,
      sort_by: this.sort.by,
      sort_order: this.sort.order,
    };

    if (this.search) {
      params["query"] = this.search;
    }

    params["include"] = ["org", "user"];

    this.reportResponse = this.Repository.Report.query(params) as IReportResponse;

    this.spinnerPromise = this.reportResponse.$promise.then(() => {
      if (this.reportResponse.meta.pagination.total_count > 0) {
        if (this.currentPage > this.reportResponse.meta.pagination.total_pages) {
          this.currentPage = 1;
          this.$location.search("page", this.currentPage);
          this.query();
        }
      }
      this.totalCount = this.reportResponse.meta.pagination.total_count;
    });

    return this.spinnerPromise;
  }

  public openSMOrder() {
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        size: "skymeasure",
        component: "skyMeasureOrder",
        backdrop: "static",
        resolve: {
          org: this.org,
        },
      })
      .result.then((result) => {
        const report = this.Repository.Report.fromJSON(result);
        this.reportResponse.reports.unshift(report);
      });
  }
}
