import * as roofFaceActions from "./roofFace.actions";
import { Map, Record } from "immutable";
import { RoofFaceRecord, fromJSON } from "../records/RoofFace";
import { RootActions, RootState } from ".";

export const RoofFaceStateRecord = Record({
  byId: Map<number, RoofFaceRecord>(),
  lastSavedById: Map<number, RoofFaceRecord>(),
  unsavedId: -1,
});

export const initialState = RoofFaceStateRecord();

export type RoofFaceState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "roofFaces";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let roofFace: RoofFaceRecord;
  switch (action.type) {
    case roofFaceActions.RECEIVE_ROOF_FACE:
      roofFace = fromJSON({ ...action.payload.roofFace });

      return state.setIn([model, "byId", roofFace.id], roofFace).setIn([model, "lastSavedById", roofFace.id], roofFace);

    default:
      return state;
  }
};
