import * as React from "react";
import { Row, Col } from "react-bootstrap";

export const OrgListDefaultHeader: React.FC = () => {
  return (
    <Row className="align-items-center pt-2 pb-1 mw-100 m-0 ">
      <Col></Col>
      <Col>
        <label>ID</label>
      </Col>
      <Col>
        <label>Name</label>
      </Col>
      <Col>
        <label>Phone #</label>
      </Col>
      <Col>
        <label>Created At</label>
      </Col>
      <Col>
        <label>Updated At</label>
      </Col>
      <Col>
        <label>Status</label>
      </Col>
      <Col>
        <label>Actions</label>
      </Col>
    </Row>
  );
};
