import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import * as screenShareActions from "app2/src/reducers/screenShare.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import Spinner from "app2/src/components/SpinnerComponent";
import { JobRecord } from "app2/src/records/Job";
import { UserRecord } from "app2/src/records/UserRecord";
import { IScreenShareData, ScreenShareRecord } from "app2/src/records/ScreenShare";
import TwilioApp from "app2/src/components/Twilio/TwilioApp";
import { RoomParams } from "app2/src/components/VideoProvider/VideoProvider";
import { getPrefConfig } from "app2/src/records/OrgRecord";
import { ScreenShareKindValues, ScreenShareStatusValues } from "app2/src/reducers/screenShare.reducer";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";

const mapStateToProps = (state: RootState, ownProps: HostProps) => {
  const job = state.getIn(["components", "common", "currentJob"], null);
  return {
    user: denormalizedReduxUser(state),
    job: job,
    activeScreenShare: state.getIn(["screenShares", "byJobId", job.id, "screenShare"]),
    screenShareKind: state.getIn(["screenShares", "byJobId", job.id, "screenShareKind"]),
    screenShareStatus: state.getIn(["screenShares", "byJobId", job.id, "screenShareStatus"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: HostProps) => {
  return {
    joinOrAddScreenShareRoom: (jobId: number, roomParams: RoomParams) =>
      dispatch(screenShareActions.AsyncActions.joinOrAddScreenShareRoom(jobId, roomParams)),
    setRemoteScreenShareStatus: (jobId: number, status: ScreenShareStatusValues) => {
      dispatch(screenShareActions.AsyncActions.setRemoteScreenShareStatus(jobId, status));
    },
    notifierAddRemoteMessage: (msg) => dispatch(commonActions.AsyncActions.notifierAddRemoteMessage(msg)),
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
    getJob: (id: number) => dispatch(jobActions.AsyncActions.getJob(id)),
    setCurrentJob: (job: JobRecord) => dispatch(commonActions.Actions.setJob(job)),
    setCurrentJobId: (id: number) => dispatch(jobActions.Actions.setCurrentJobId(id)),
    getRoomStatus: (uid) => dispatch(screenShareActions.AsyncActions.getRoomStatus(uid)),
    saveScreenShareStop: (jobId: number, screenShareId: number) =>
      dispatch(screenShareActions.AsyncActions.saveScreenShareStop(jobId, screenShareId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface HostProps {
  jobId: string;
}

interface HostState {
  stateReady: boolean;
  roomParams: RoomParams;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HostProps;

class Host extends React.Component<Props, HostState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stateReady: false,
      roomParams: {
        roomName: "",
        roomUid: "",
        identity: "",
        host: true,
        audio_preference: "enabled",
        roomExists: false,
      },
    };
    window.addEventListener("beforeunload", (e) => {
      if (this.props.screenShareKind === "premium") {
        this.props.setRemoteScreenShareStatus(this.props.job.id, "off");
      }
    });

    this.joinRoom = this.joinRoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
  }

  public async componentDidMount() {
    const { getJob, setCurrentJob, setCurrentJobId, getRoomStatus } = this.props;
    const job = await getJob(parseInt(this.props.jobId));
    setCurrentJob(job);
    setCurrentJobId(job.id);
    const status = await getRoomStatus(job.uid);
    this.setState((state) => ({
      stateReady: state.stateReady,
      roomParams: {
        ...state.roomParams,
        roomExists: status,
      },
    }));
  }

  public componentDidUpdate(prevProps) {
    const { user, job, setRemoteScreenShareStatus, screenShareStatus } = this.props;
    if (!user || !job || user.id === 0 || job.id === 0) {
      return;
    }

    if (
      _.includes(["start_requested", "active", "tab_opened"], prevProps.screenShareStatus) &&
      screenShareStatus === "stop_requested"
    ) {
      this.leaveRoom().then(() => {
        window.close();
      });
    }

    this.setState((state) => {
      if (state.stateReady) {
        return;
      }
      setRemoteScreenShareStatus(job.id, "tab_opened");
      return {
        stateReady: true,
        roomParams: {
          roomName: job.name,
          roomUid: job.uid,
          identity: `${user.first_name} ${user.last_name}`,
          host: true,
          audio_preference: getPrefConfig(user.org, ["screen_share", "host_audio"]),
          roomExists: state.roomParams.roomExists,
        },
      };
    });
  }

  public async joinRoom() {
    const { setRemoteScreenShareStatus, job, joinOrAddScreenShareRoom, notifierAddRemoteMessage } = this.props;
    const { roomParams } = this.state;
    notifierAddRemoteMessage("Premium Screen Sharing Started");
    setRemoteScreenShareStatus(job.id, "active");

    return await joinOrAddScreenShareRoom(job.id, roomParams);
  }

  public async leaveRoom() {
    const { activeScreenShare, saveScreenShareStop, setRemoteScreenShareStatus, notifierAddRemoteMessage, job } =
      this.props;

    if (activeScreenShare && !activeScreenShare.loading && activeScreenShare.id > 0) {
      await saveScreenShareStop(job.id, activeScreenShare.id);
      notifierAddRemoteMessage("Premium Screen Sharing Stopped");
      setRemoteScreenShareStatus(job.id, "off");
    }
  }

  public render() {
    const { jobId } = this.props;
    const { stateReady, roomParams } = this.state;
    if (!stateReady) {
      return <Spinner localProperty={true} />;
    }

    return (
      <Spinner stateProperty={["jobs", "byId", parseInt(jobId), "loading"]}>
        <TwilioApp roomParams={roomParams} joinRoom={this.joinRoom} leaveRoom={this.leaveRoom} />
      </Spinner>
    );
  }
}

export default connector(Host);
