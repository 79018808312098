import { createSelector } from "reselect";
import { List } from "immutable";
import { RootState } from "app2/src/reducers";
import { ids } from "app2/src/selectors/pagination.selectors";
import { EstimateTemplateRecord } from "app2/src/records/EstimateTemplate";
import { recordChanged } from "app2/src/helpers/Record";

export const estimateTemplatesById = (state: RootState) => state.getIn(["estimateTemplates", "byId"]);
export const estimateTemplate = (state: RootState, props: any): EstimateTemplateRecord =>
  state.getIn(["estimateTemplates", "byId", props.estimateTemplateId]);
export const lastSavedEstimateTemplate = (state: RootState, props: any) =>
  state.getIn(["estimateTemplates", "lastSavedById", props.estimateTemplateId]);

export const estimateTemplatesSelectedIds = createSelector(
  [
    estimateTemplatesById,
    (state: RootState, props: any) => ({
      state,
      props,
    }),
  ],
  (estimateTemplatesById, stateProps: any): List<number> => {
    const { state } = stateProps;
    const estimateTemplateIds = ids(state, { modelName: "estimateTemplate" });
    if (!estimateTemplateIds || estimateTemplateIds.size === 0) {
      return List();
    }

    const estimateTemplates = estimateTemplateIds.map((estimateTemplateId: number) =>
      estimateTemplatesById.get(estimateTemplateId),
    );

    return estimateTemplates
      .filter((estimateTemplate: EstimateTemplateRecord) => {
        return estimateTemplate.selected === true;
      })
      .map((estimateTemplate: EstimateTemplateRecord) => estimateTemplate.id);
  },
);

export const estimateTemplateChanged = createSelector(
  [estimateTemplate, lastSavedEstimateTemplate],
  (estimateTemplate: EstimateTemplateRecord, lastSavedEstimateTemplate: EstimateTemplateRecord) => {
    return recordChanged(estimateTemplate, lastSavedEstimateTemplate);
  },
);

export const estimateTemplatesSelected = createSelector(
  [estimateTemplatesSelectedIds],
  (estimateTemplatesSelectedIds): boolean => {
    return estimateTemplatesSelectedIds.size > 0;
  },
);
