import { ICommand } from "app/src/Commands/Command";
import { IEstimate, ISalesTaxable } from "app/src/Models/Estimate";

export class EditSalesTaxCmd implements ICommand {
  private _oldSalesTax;
  constructor() {}

  public execute(estimate: IEstimate, salesTax: ISalesTaxable): boolean {
    this._oldSalesTax = estimate.cloneSalesTax();
    estimate.updateSalesTax(salesTax);

    return true;
  }
}
