import * as React from "react";
import { Col, ColProps, Row, RowProps } from "react-bootstrap";

interface JobTabTitleProps {
  title: string;
  rowProps?: RowProps;
  titleColProps?: ColProps;
  childColProps?: ColProps;
}

export const JobTabTitle: React.FC<JobTabTitleProps> = ({
  title,
  children,
  rowProps,
  titleColProps,
  childColProps,
}) => {
  return (
    <Row {...rowProps}>
      <Col {...titleColProps}>
        <h1 className="tab-title">{title}</h1>
      </Col>
      <Col {...childColProps}>{children}</Col>
    </Row>
  );
};
