import { PreferenceRecord } from "app2/src/records/Preference";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { orgService } from "app2/src/api/org.service";

export interface INotificationFormData {
  customerDocumentSigned: boolean;
  signedNotification: string;
  signableNotification: string;
}

export const setupForm = (preference: PreferenceRecord): INotificationFormData => {
  const send_email = preference.config.get("send_email");
  const document_signed_notification = preference.config.get("document_signed_notification");
  if (!send_email || !document_signed_notification) {
    return {
      signedNotification: "",
      customerDocumentSigned: true,
      signableNotification: "",
    };
  }

  return {
    signedNotification: document_signed_notification.get("emails"),
    customerDocumentSigned: send_email.get("notify").includes("customer_document_signed"),
    signableNotification: document_signed_notification.get("signable_document_emails"),
  };
};

export const saveForm = (org: OrgRecord, formData: INotificationFormData) => {
  let preferences = org.preferences;
  let notify = preferences.config.getIn(["send_email", "notify"]);
  if (formData.customerDocumentSigned) {
    notify = notify.push("customer_document_signed");
  } else {
    notify = _.filter(notify, (n) => {
      return n === "customer_document_signed";
    });
  }
  preferences = preferences
    .setIn(["config", "send_email", "notify"], notify)
    .setIn(["config", "document_signed_notification", "emails"], formData.signedNotification)
    .setIn(["config", "document_signed_notification", "signable_document_emails"], formData.signableNotification);

  orgService.updateOrg(org.set("preferences", preferences));
};
