import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { Tabs, Tab, Table, Container, Col, Row } from "react-bootstrap";
import { IRepository } from "../../../../app/src/Common/Repository";
import EventStat from "./EventStat";
import UserStat from "./UserStat";
import { ModelCache } from "./ModelCache";
import { StandardPagination } from "../Pagination/Standard";
import * as eventsActions from "app2/src/reducers/analytics.actions";
import { RootState, RootActions } from "app2/src/reducers";
import Spinner from "app2/src/components/SpinnerComponent";
import { IPagination } from "app2/src/api/integrations/eagleview.service";
import { Can } from "app2/src/components/Common/CanComponent";

const mapStateToProps = (state: RootState, ownProps: DashboardComponentProps) => {
  const eventPage = state.getIn(["analytics", "eventPagination", "page"], 1);
  const userPage = state.getIn(["analytics", "userPagination", "page"], 1);

  return {
    eventPage: eventPage,
    userPage: userPage,
    eventsData: state.getIn(["analytics", "eventPagination", "pages", eventPage]),
    usersData: state.getIn(["analytics", "userPagination", "pages", userPage]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: DashboardComponentProps) => {
  return {
    queryEventData: (page: number) => {
      return dispatch(eventsActions.AsyncActions.getPage(page));
    },
    queryUserData: (page: number) => {
      return dispatch(eventsActions.AsyncActions.getUsersPage(page));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DashboardComponentProps {
  Repository: IRepository;
}

export interface DashboardComponentState {
  users: { data: any[]; pagination: any; currentPage: number };
  ModelCache: ModelCache;
  page: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DashboardComponentProps;

class DashboardComponent extends React.Component<Props, DashboardComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      users: { data: [], pagination: null, currentPage: 1 },
      ModelCache: new ModelCache(this.props.Repository),
      page: 1,
    };

    // this.queryData(1);
    props.queryEventData(1);
    props.queryUserData(1);
  }

  public render() {
    return (
      <Can resource="global" permission="org">
        <Container>
          <Row>
            <Col md={12}>
              <h2>Analytics Dashboard</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Tabs id="analytics" defaultActiveKey="users">
                <Tab eventKey="users" title="Users">
                  <br />
                  {this.renderUsers()}
                </Tab>
                <Tab eventKey="activity" title="Activity">
                  <br />
                  {this.renderEvents()}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Can>
    );
  }

  public renderUsers() {
    const { userPage, usersData, queryUserData } = this.props;
    const { ModelCache } = this.state;

    let data: eventsActions.IUserAnalyticsData[], pagination: IPagination;
    if (!usersData) {
      data = [];
      pagination = {} as IPagination;
    } else {
      data = usersData.data;
      pagination = usersData.pagination;
    }

    return (
      <Spinner stateProperty={["analytics", "userPagination", "pages", userPage, "loading"]}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>User</th>
              <th>Events</th>
              <th>Last Seen</th>
              <th>First Seen</th>
            </tr>
          </thead>
          <tbody>
            {data.map((user, idx) => {
              return <UserStat key={idx} user={user} ModelCache={ModelCache}></UserStat>;
            })}
            <tr>
              <td colSpan={4}>
                <StandardPagination metadata={pagination} pageChanged={queryUserData}></StandardPagination>
              </td>
            </tr>
          </tbody>
        </Table>
      </Spinner>
    );
  }

  public renderEvents() {
    const { eventPage, eventsData, queryEventData } = this.props;
    const { ModelCache } = this.state;

    let data: eventsActions.EventData[], pagination: IPagination;
    if (!eventsData) {
      data = [];
      pagination = {} as IPagination;
    } else {
      data = eventsData.data;
      pagination = eventsData.pagination;
    }

    return (
      <Spinner stateProperty={["analytics", "eventPagination", "pages", eventPage, "loading"]}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Category</th>
              <th>Action</th>
              <th>User</th>
              <th>Org</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((event) => {
              return <EventStat key={event.id} event={event} ModelCache={ModelCache}></EventStat>;
            })}
            <tr>
              <td colSpan={6}>
                <StandardPagination metadata={pagination} pageChanged={queryEventData}></StandardPagination>
              </td>
            </tr>
          </tbody>
        </Table>
      </Spinner>
    );
  }
}

export default connector(DashboardComponent);
