import { loadPaySimple } from "./loadPaySimple";
import { Deferred } from "app2/src/services/deferred";
import { PaySimpleCheckoutToken } from "app2/src/reducers/integrations/paysimple.actions";

export class PaySimple {
  public errorHandler: (error: PaySimpleError) => void;
  public customerCallback: (error: PaySimpleAccountInfo) => void;
  public formCallback: (data: PaySimpleFormValidity) => void;

  protected paysimpleJs: any;
  protected initPromise: Promise<void>;

  public constructor(
    public container: string,
    public checkoutToken: PaySimpleCheckoutToken,
  ) {
    this.initPromise = waitForElement(container).then(() => {
      return this.initialize();
    });
  }

  public askForPaymentOptions(mode: string): void {
    this.initPromise.then(() => {
      this.paysimpleJs.send.setMode(`${mode}-key-enter`);
    });
  }

  public sendCustomer(customer: PaySimpleCustomerData): void {
    this.paysimpleJs.send.retrieveAccount(customer);
  }

  public static loadJs(): Promise<void> {
    return loadPaySimple();
  }

  private initialize() {
    const deferred = Deferred.defer<void>();

    setTimeout(() => {
      this.paysimpleJs = (window as any).paysimpleJs({
        // the element that will contain the iframe
        container: document.querySelector(this.container),
        // checkout_token is in auth
        auth: {
          token: this.checkoutToken.JwtToken,
        },
        // allows entry of international postal codes if true
        bypassPostalCodeValidation: false,
        // Attempts to prevent browsers from using autocompletion to pre-populate
        // or suggest input previously stored by the user. Turn on for point of
        // sale or kiosk type applications where many different customers
        // will be using the same browser to enter payment information.
        preventAutocomplete: false,
        // customized styles are optional
        styles: {
          body: {
            // set the background color of the payment page
            backgroundColor: "#f9f9f9",
          },
        },
      });

      this.paysimpleJs.on("formValidityChanged", (body: any) => {
        if (this.formCallback) {
          this.formCallback(body);
        }
      });

      this.paysimpleJs.on("httpError", (error: PaySimpleError) => {
        console.error(error);
        if (this.errorHandler) {
          this.errorHandler(error);
        }
      });

      this.paysimpleJs.on("accountRetrieved", (accountInfo: PaySimpleAccountInfo) => {
        if (this.customerCallback) {
          this.customerCallback(accountInfo);
        }
      });

      deferred.resolve(null);
    }, 500);

    return deferred.promise;
  }
}

export interface PaySimpleError {
  errorKey: "timeout" | "bad_request" | "server_error" | "unauthorized" | "unknown";
  errors: Array<{ field: string; message: string }>;
  status: number;
}

export interface PaySimpleCustomerData {
  firstName: string;
  lastName: string;
  email: string;
}

/****
 * Example accountInfo:
 *
 * {
 *    "account": {
 *        "id": 7313702
 *    },
 *    "customer": {
 *        "id": 8041997,
 *        "firstName": "John",
 *        "lastName": "Snow",
 *        "email": "john@snow.com"
 *    },
 *    "paymentToken": "e1f1bb19-9fe4-4c96-a35e-cd921298d8e6"
 * }
 ****/
export interface PaySimpleAccountInfo {
  account: { id: number };
  customer: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  paymentToken: string;
}

export type PaySimplePaymentKeys =
  | "CustomerId"
  | "CustomerFirstName"
  | "CustomerLastName"
  | "CustomerCompany"
  | "ReferenceId"
  | "Status"
  | "RecurringScheduleId"
  | "PaymentType"
  | "PaymentSubType"
  | "ProviderAuthCode"
  | "TraceNumber"
  | "PaymentDate"
  | "ReturnDate"
  | "EstimatedSettleDate"
  | "EstimatedDepositDate"
  | "ActualSettledDate"
  | "CanVoidUntil"
  | "FailureData"
  | "RequiresReceipt"
  | "CVV"
  | "AccountId"
  | "InvoiceId"
  | "Amount"
  | "IsDebit"
  | "InvoiceNumber"
  | "PurchaseOrderNumber"
  | "OrderId"
  | "Description"
  | "Latitude"
  | "Longitude"
  | "SuccessReceiptOptions"
  | "Id"
  | "LastModified"
  | "CreatedOn";

export type PaySimpleFailureKeys = "Code" | "Description" | "MerchantActionText" | "IsDecline";

export interface PaySimpleToken {
  username: string;
  api_key: string;
  hpp_url?: string;
  loading?: boolean;
}

export interface PaySimpleFormValidity {
  validity: boolean;
}

export const waitForElement = (selector) => {
  return new Promise((resolve, reject) => {
    const waitForEl = (selector, count = 0) => {
      const element = jQuery(selector);

      if (element) {
        resolve(element);
      } else {
        setTimeout(() => {
          count++;

          if (count < 10) {
            waitForEl(selector, count);
          } else {
            reject();
          }
        }, 100);
      }
    };

    waitForEl(selector);
  });
};
