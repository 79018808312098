import * as React from "react";
import ParticipantStrip from "./ParticipantStrip";
import Controls from "app2/src/components/Twilio/components/Controls";
import MainParticipant from "app2/src/components/Twilio/components/MainParticipant";
import useHeight from "app2/src/hooks/useHeight/useHeight";
import useScreenShareToggle from "app2/src/components/Twilio/hooks/useScreenShareToggle/useScreenShareToggle";

export default function Room() {
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const [height] = useHeight();
  const screenShare = useScreenShareToggle();
  const [isScreenShared] = screenShare;

  return (
    <div className="room-container" style={{ height }}>
      <ParticipantStrip />
      <MainParticipant isScreenShared={isScreenShared as boolean} />
      <Controls screenShare={screenShare} />
    </div>
  );
}
