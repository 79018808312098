import * as React from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { push } from "connected-react-router/immutable";
import RsfPresentationTab from "app2/src/components/JobTabs/PresentationTab/Existing/index";
import { currentJobId, job } from "app2/src/selectors/job.selectors";
import PresentationBuilderContainer from "app2/src/components/JobTabs/PresentationTab/Customer/PresentationBuilder/PresentationBuilderContainer";

interface CustomerTabProps {
  activeKey: "customer" | "existing";
}

export const CustomerTab: React.FC<CustomerTabProps> = () => {
  // Hooks
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const route = useRouteMatch<CustomerTabProps>(`${match.url}/:activeKey`);

  // Local State
  const activeKey = route?.params?.activeKey;

  // Selectors
  const jobId = useSelector(currentJobId);

  // Methods
  const tabNavigate = (data: string) => {
    if (data === "existing") {
      dispatch(push(`${match.url}/existing`));
    } else {
      dispatch(push(`${match.url}`));
    }
  };

  return (
    <Row>
      <Col className="gaf-nav-tabs">
        <Tabs onSelect={tabNavigate} aria-label="presentation-tabs" activeKey={activeKey}>
          <Tab eventKey="dynamic" title="Dynamic" />
          <Tab eventKey="existing" title="Existing" />
        </Tabs>

        {jobId && (
          <Switch>
            <Route path={`${match.url}/dynamic`} component={PresentationBuilderContainer} />
            <Route path={`${match.url}/existing`} component={RsfPresentationTab} />
            <Route path={`${match.url}`} render={() => <Redirect to={`${match.url}/dynamic`} />} />
          </Switch>
        )}
      </Col>
    </Row>
  );
};

export default CustomerTab;
