import { IUser } from "app/src/Models/User";
import * as config from "react-global-configuration";
import { dispatch, useState } from "../storeRegistry";
import * as authActions from "../reducers/auth.actions";
import { UserManager, User as OidcUser } from "oidc-client";
import { loadByAltId, includes as userIncludes } from "../api/user.service";
import { LocationRecord } from "../records/Location";
import { getPayload } from "../services/jwtToken.service";

export type TokenType = "rsf" | "lowes";

class AuthService implements IAuthService {
  private _userManager: UserManager;

  public get userManager() {
    return this._userManager;
  }

  constructor() {
    this._userManager = new UserManager({
      authority: config.get("OIDC_AUTHORITY_URL"),
      client_id: config.get("OIDC_CLIENT_ID"),
      redirect_uri: config.get("OIDC_REDIRECT_URL"),
      response_type: "code",
      scope: config.get("OIDC_SCOPE"),
    });
    (window as any).currentUserManager = this._userManager;
  }

  public hasToken(): boolean {
    const user = localStorage.getItem("ngStorage-currentUser");
    if (!user || user === "null" || user === "undefined") {
      return false;
    }

    // We could potentially recover from a refresh without a token
    // but we can't recover from a token without a refresh
    if (!this.hasRefreshToken()) {
      return false;
    }

    try {
      const currentUser = JSON.parse(user);
      return !_.isEmpty(currentUser.token);
    } catch (e) {
      return false;
    }
  }

  public getToken() {
    if (!this.hasToken()) {
      return "";
    }

    const user = localStorage.getItem("ngStorage-currentUser");

    const currentUser = JSON.parse(user);
    return currentUser.token;
  }

  public getCurrentUser(): { id: number; email: string; token: string } {
    const user = localStorage.getItem("ngStorage-currentUser");
    if (user === null || user === "null") {
      return {} as any;
    }

    return JSON.parse(user);
  }

  public setCurrentUser(id: number, email: string, token: string): void {
    const currentUser = {
      id: id,
      email: email,
      token: token,
    };

    localStorage.setItem("ngStorage-currentUser", JSON.stringify(currentUser));
  }

  public hasRefreshToken() {
    const refresh = localStorage.getItem("ngStorage-refresh");
    if (!refresh || refresh === "null" || refresh === "undefined") {
      return "";
    }

    try {
      const refreshObj = JSON.parse(refresh);
      return !_.isEmpty(refreshObj.token);
    } catch (e) {
      return false;
    }
  }

  public getRefreshToken() {
    if (!this.hasRefreshToken()) {
      return "";
    }

    const refresh = localStorage.getItem("ngStorage-refresh");
    const refreshObj = JSON.parse(refresh);
    return refreshObj.token;
  }

  public setRefreshToken(token: string): void {
    const refresh = {
      token: token,
    };

    localStorage.setItem("ngStorage-refresh", JSON.stringify(refresh));
  }

  public hasAccessUid() {
    const rsfAuth = sessionStorage.getItem("rsf-auth");
    if (!rsfAuth || rsfAuth === "null" || rsfAuth === "undefined") {
      return "";
    }

    try {
      const rsfAuthObj = JSON.parse(rsfAuth);
      return !_.isEmpty(rsfAuthObj.accessUid);
    } catch (e) {
      return false;
    }
  }

  public getAccessUid() {
    if (!this.hasAccessUid()) {
      return "";
    }

    const rsfAuth = sessionStorage.getItem("rsf-auth");
    const rsfAuthObj = JSON.parse(rsfAuth);
    return rsfAuthObj.accessUid;
  }

  public setAccessUid(accessUid: string): void {
    let rsfAuthJson = sessionStorage.getItem("rsf-auth");
    if (!rsfAuthJson || rsfAuthJson === "null" || rsfAuthJson === "undefined") {
      rsfAuthJson = "{}";
    }

    const rsfAuth = JSON.parse(rsfAuthJson);

    rsfAuth.accessUid = accessUid;

    sessionStorage.setItem("rsf-auth", JSON.stringify(rsfAuth));
  }

  public getIdp(): "rsf" | "lowes" {
    let rsfAuthJson = localStorage.getItem("rsf-auth");
    if (!rsfAuthJson || rsfAuthJson === "" || rsfAuthJson === "null" || rsfAuthJson === "undefined") {
      rsfAuthJson = "{}";
    }

    let rsfAuth;
    try {
      rsfAuth = JSON.parse(rsfAuthJson);
    } catch (_) {
      rsfAuth = {};
    }

    return rsfAuth.idp || "rsf";
  }

  public setIdp(idp: "rsf" | "lowes"): void {
    let rsfAuthJson = localStorage.getItem("rsf-auth");
    if (!rsfAuthJson || rsfAuthJson === "null" || rsfAuthJson === "undefined") {
      rsfAuthJson = "{}";
    }

    const rsfAuth = JSON.parse(rsfAuthJson);

    rsfAuth.idp = idp;

    localStorage.setItem("rsf-auth", JSON.stringify(rsfAuth));
  }

  public clearRsfAuth(): void {
    sessionStorage.setItem("rsf-auth", null);
  }

  public clearRefreshToken(): void {
    localStorage.setItem("ngStorage-refresh", null);
  }

  public clearUser(): void {
    localStorage.setItem("ngStorage-currentUser", null);
  }

  public getLoginRoute() {
    switch (this.getIdp()) {
      case "lowes":
        return "root.loweslogin";
      default:
        return "root.login";
    }
  }

  public login(email: string, password: string): Promise<any> {
    this.setIdp("rsf");
    return dispatch(authActions.AsyncActions.login(email, password));
  }

  public async logout(refreshToken: string): Promise<void> {
    authService.clearRefreshToken();
    authService.clearRsfAuth();
    authService.clearUser();

    switch (this.tokenType(refreshToken)) {
      case "lowes":
        await this._userManager.signoutRedirect();
        return;
      default:
        return dispatch(authActions.AsyncActions.logout(refreshToken));
    }
  }

  public resetPassword(token: string, token_type: string, user: IUser): Promise<any> {
    return dispatch(authActions.AsyncActions.resetPassword(token, token_type, user));
  }

  /**
   * Parent method for refreshing a token. Takes into account the type of authentication for this deployment
   * and then delegates the refreshing to the proper, auth specific, method.
   *
   * @param token refresh token to use to get a new auth token
   * @returns Promise<void>
   */
  public async refreshToken(token: string): Promise<any> {
    switch (this.tokenType(token)) {
      case "lowes":
        const user: OidcUser = await this.userManager.signinSilent();
        this.setRefreshToken(user.access_token);
        return dispatch(authActions.Actions.setTokens({ token: user.id_token, refreshToken: user.access_token }));
      default:
        return dispatch(authActions.AsyncActions.refreshToken(token));
    }
  }

  public setupOidcUserManager() {
    this._userManager.startSilentRenew();

    this._userManager.events.addAccessTokenExpiring(() => {
      console.error("expiring");
    });

    this._userManager.events.addAccessTokenExpired(() => {
      console.error("expired");
    });

    this._userManager.events.addUserLoaded((user: OidcUser) => {
      this.setRefreshToken(user.access_token);
      dispatch(authActions.Actions.setTokens({ token: user.id_token, refreshToken: user.access_token }));
    });

    this._userManager.events.addSilentRenewError((error) => {
      console.error("Silent Renew Error: ", { error });
    });
  }

  public async oidcLogin(loginState: any): Promise<boolean> {
    this.setupOidcUserManager();

    let user: OidcUser;
    try {
      user = await this._userManager.signinSilent();
    } catch (e) {
      console.error("SilentLogin Attempt: ", e);
    }

    if (!user) {
      sessionStorage.setItem("LoginAppState", JSON.stringify(loginState));
      this._userManager.signinRedirect();
      return false;
    }

    return await this.setupOidcUser(user);
  }

  public async setupOidcUser(user: OidcUser): Promise<boolean> {
    let id = user.profile.SalesID;
    if (!id) {
      id = user.profile.salesid;
    }
    if (!id) {
      id = user.profile.sub;
    }

    const rsfUser = await loadByAltId("lowes_id", id, userIncludes, user.id_token);

    dispatch(authActions.AsyncActions.fromData(user.id_token, rsfUser, user.access_token));
    this.setIdp("lowes");

    return true;
  }

  public tokenType(token: string): TokenType {
    const payload = getPayload(token);

    if (!payload.iss || payload.iss.indexOf("remotesf") >= 0) {
      return "rsf";
    }
    if (payload.iss && (payload.iss.indexOf("windows") >= 0 || payload.iss.indexOf("microsoft") >= 0)) {
      return "lowes";
    }

    return "rsf";
  }

  public initialize(): void {
    if (!this.hasToken()) {
      return;
    }

    switch (this.tokenType(this.getToken())) {
      case "lowes":
        const locationRecord: LocationRecord = useState().getIn(["routerPush", "location"]);
        this.oidcLogin({
          goToRoute: locationRecord.pathname,
          goToParams: locationRecord.query,
        });
        return;
      default:
        return;
    }
  }
}

export interface IAuthService {
  hasToken(): boolean;
  getToken(): string;

  hasRefreshToken(): boolean | "";
  getRefreshToken(): string;
  setRefreshToken(token: string): void;

  hasAccessUid(): boolean | "";
  getAccessUid(): string;
  setAccessUid(accessUid: string): void;

  refreshToken(token: string): Promise<any>;

  setCurrentUser(id: number, email: string, token: string): void;
  getCurrentUser(): { id: number; email: string; token: string };

  login(email: string, password: string): Promise<IUser>;
  logout(refreshToken: string): Promise<void>;
  resetPassword(token: string, token_type: string, user: IUser): Promise<any>;

  initialize(): void;
}

export const authService = new AuthService();
