import { ActionsUnion, createAction } from "app2/src/reducers/Utils";
import { RootState, ThunkResult } from "app2/src/reducers/index";
import { EstimateRecord, IEstimateData, DocType, getCreatedLineItemDocuments } from "app2/src/records/Estimate";
import { IEstimateOverviewData } from "app2/src/records/EstimateOverview";
import { EmailEstimateArgs, estimateService, IEstimateLandingResponse } from "app2/src/api/estimate.service";
import { financeOptionService } from "app2/src/api/financeOption.service";
import { PaymentTermTemplateRecord, PaymentTermItemRecord } from "app2/src/records/PaymentTermTemplate";
import { EstimateDiscountRecord, IEstimateDiscountData } from "app2/src/records/EstimateDiscount";
import {
  estimateFinanceOptionIds,
  toEstimateJson,
  estimate as estimateSelector,
} from "app2/src/selectors/estimate.selectors";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { handleErrors } from "app2/src/reducers/Utils";
import * as activatedPriceListActions from "app2/src/reducers/activatedPriceList.actions";
import { ICalculatedFinancingData } from "app2/src/records/CalculatedFinancing";
import { filteredFinanceOptions } from "app2/src/selectors/financeOption.selectors";
import { List } from "immutable";
import {
  AsyncCalcFinanceOptionProviders,
  LocalCalcFinanceOptionProviders,
  localCalculations,
} from "app2/src/records/FinanceOption";
import { IEstimate } from "app/src/Models/Estimate";
import { currentJob, currentJobId, currentJobOrgId, job, selectedEstimateId } from "app2/src/selectors/job.selectors";
import { RootDispatchType } from "app2/src/store";
import { EstimateSignedDocumentRecord } from "app2/src/records/EstimateSignedDocument";
import * as fileSaver from "file-saver";
import { IEstimateCommissionData } from "app2/src/records/EstimateCommission";
import * as moment from "moment";
import { contractTitle, settingsConfig } from "app2/src/selectors/org.selectors";
import * as taskActions from "app2/src/reducers/task.actions";
import * as documentActions from "app2/src/reducers/document.actions";
import { DocumentableType, download } from "app2/src/api/document.service";
import { IDocumentData } from "app2/src/records/Document";

export const FETCH_JOB_ESTIMATES = "@estimates/FETCH_JOB_ESTIMATES";
export const FETCH_ESTIMATE = "@estimates/FETCH_ESTIMATE";
export const RECEIVE_JOB_ESTIMATES = "@estimates/RECEIVE_JOB_ESTIMATES";
export const RECEIVE_ESTIMATE = "@estimates/RECEIVE_ESTIMATE";
export const SET_ESTIMATE_LOADED = "@estimates/SET_ESTIMATE_LOADED";
export const SET_LAST_SAVED_ESTIMATE = "@estimates/SET_LAST_SAVED_ESTIMATE";
export const REMOVE_JOB_ESTIMATE = "@estimates/REMOVE_JOB_ESTIMATE";
export const RECEIVE_ESTIMATE_PDF = "@estimates/RECEIVE_ESTIMATE_PDF";
export const REMOVE_ESTIMATE_PDF = "@estimates/REMOVE_ESTIMATE_PDF";
export const REMOVE_ESTIMATE = "@estimates/REMOVE_ESTIMATE";
export const RECEIVE_JOB_ERRORS = "@estimates/RECEIVE_JOB_ERRORS";
export const RECEIVE_ESTIMATE_ERROR = "@estimates/RECEIVE_ESTIMATE_ERROR";
export const UPDATE_ESTIMATE_OVERVIEW = "@estimates/UPDATE_ESTIMATE_OVERVIEW";
export const FETCH_LANDING_ESTIMATE = "@estimates/FETCH_LANDING_ESTIMATE";
export const RECEIVE_LANDING_ESTIMATE = "@estimates/RECEIVE_LANDING_ESTIMATE";
export const SET_LANDING_LOADED = "@estimates/SET_LANDING_LOADED";
export const ADD_PT_TEMPLATE = "@estimates/ADD_PT_TEMPLATE";
export const ADD_PT_ITEM = "@estimates/ADD_PT_ITEM";
export const REMOVE_PT_ITEM = "@estimates/REMOVE_PT_ITEM";
export const UPDATE_PT_ITEM = "@estimates/UPDATE_PT_ITEM";
export const REMOVE_DISCOUNT = "@estimates/REMOVE_DISCOUNT";
export const ADD_DISCOUNT = "@estimates/ADD_DISCOUNT";
export const UPDATE_DISCOUNT = "@estimates/UPDATE_DISCOUNT";
export const UPDATE_BASE_TOTAL = "@estimates/UPDATE_BASE_TOTAL";
export const UPDATE_DISCOUNT_TOTAL = "@estimates/UPDATE_DISCOUNT_TOTAL";
export const UPDATE_TAX_TOTAL = "@estimates/UPDATE_TAX_TOTAL";
export const UPDATE_PAYMENT_TERM_TOTAL = "@estimates/UPDATE_PAYMENT_TERM_TOTAL";
export const DUPLICATE_ESTIMATE = "@estimates/DUPLICATE_ESTIMATE";
export const EDIT_NAME = "@estimates/EDIT_NAME";
export const ADD_FINANCE_OPTION_ID = "@estimates/ADD_FINANCE_OPTION_ID";
export const SET_ESTIMATE_FINANCE_OPTIONS = "@estimates/SET_ESTIMATE_FINANCE_OPTIONS";
export const FETCH_CALCULATIONS = "@estimates/FETCH_CALCULATIONS";
export const RECEIVE_CALCULATIONS = "@estimates/RECEIVE_CALCULATIONS";
export const SET_CALCULATIONS_ERROR = "@estimates/SET_CALCULATIONS_ERROR";
export const FETCH_ESTIMATE_COMMISSION = "@estimates/FETCH_ESTIMATE_COMMISSION";
export const RECEIVE_ESTIMATE_COMMISSION = "@estimates/RECEIVE_ESTIMATE_COMMISSION";
export const SET_ESTIMATE_SIGNED_DOCUMENTS = "@estimates/SET_ESTIMATE_SIGNED_DOCUMENTS";

export const Actions = {
  fetchEstimate: (estimateId: number) => createAction(FETCH_ESTIMATE, { estimateId }),
  receiveEstimateError: (estimateId: number, errors: string[]) =>
    createAction(RECEIVE_ESTIMATE_ERROR, { estimateId, errors }),
  receiveEstimate: (estimate: Partial<IEstimateData>) => createAction(RECEIVE_ESTIMATE, { estimate }),
  setEstimateLoaded: (estimateId: number) => createAction(SET_ESTIMATE_LOADED, { estimateId }),
  setLastSavedEstimate: (estimateId: number) => createAction(SET_LAST_SAVED_ESTIMATE, { estimateId }),
  fetchEstimates: (jobId: number) => createAction(FETCH_JOB_ESTIMATES, jobId),
  receiveErrors: (jobId: number, errors: string[]) => createAction(RECEIVE_JOB_ERRORS, { jobId, errors }),
  receiveEstimates: (jobId: number, estimates: IEstimateOverviewData[]) =>
    createAction(RECEIVE_JOB_ESTIMATES, { jobId, estimates }),
  updateEstimateOverview: (estimate: EstimateRecord) => createAction(UPDATE_ESTIMATE_OVERVIEW, { estimate }),
  receiveEstimatePdf: (estimateId: number, data: Blob, documentId: number) =>
    createAction(RECEIVE_ESTIMATE_PDF, { estimateId, data, documentId }),
  removeEstimatePdf: (estimateId: number) => createAction(REMOVE_ESTIMATE_PDF, { estimateId }),
  fetchLandingEstimate: (key: string) => createAction(FETCH_LANDING_ESTIMATE, { key }),
  receiveLandingEstimate: (key: string, response: IEstimateLandingResponse) =>
    createAction(RECEIVE_LANDING_ESTIMATE, { key, response }),
  setLandingLoaded: (key: string) => createAction(SET_LANDING_LOADED, { key }),
  addPaymentTermTemplate: (estimateId: number, template: PaymentTermTemplateRecord) =>
    createAction(ADD_PT_TEMPLATE, { estimateId, template }),
  addPaymentTermItem: (estimateId: number, item: PaymentTermItemRecord) =>
    createAction(ADD_PT_ITEM, { estimateId, item }),
  removePaymentTermItem: (estimateId: number, item: PaymentTermItemRecord) =>
    createAction(REMOVE_PT_ITEM, { estimateId, item }),
  updatePaymentTermItem: (estimateId: number, item: PaymentTermItemRecord) =>
    createAction(UPDATE_PT_ITEM, { estimateId, item }),
  removeDiscount: (estimateId: number, discount: EstimateDiscountRecord) =>
    createAction(REMOVE_DISCOUNT, { estimateId, discount }),
  addDiscount: (estimateId: number, discount: IEstimateDiscountData) =>
    createAction(ADD_DISCOUNT, { estimateId, discount }),
  updateDiscount: (estimateId: number, discount: EstimateDiscountRecord) =>
    createAction(UPDATE_DISCOUNT, { estimateId, discount }),
  updateBaseTotal: (estimateId: number) => createAction(UPDATE_BASE_TOTAL, { estimateId }),
  updateDiscountTotal: (estimateId: number) => createAction(UPDATE_DISCOUNT_TOTAL, { estimateId }),
  updateTaxTotal: (estimateId: number) => createAction(UPDATE_TAX_TOTAL, { estimateId }),
  updatePaymentTermTotal: (estimateId: number) => createAction(UPDATE_PAYMENT_TERM_TOTAL, { estimateId }),
  duplicateEstimate: (estimateId: number) => createAction(DUPLICATE_ESTIMATE, { estimateId }),
  editEstimateName: (estimateId: number, name: string) => createAction(EDIT_NAME, { estimateId, name }),
  addFinanceOptionId: (estimateId: number, financeOptionId: number) =>
    createAction(ADD_FINANCE_OPTION_ID, { estimateId, financeOptionId }),
  setEstimateFinanceOptions: (estimateId: number) => createAction(SET_ESTIMATE_FINANCE_OPTIONS, { estimateId }),
  fetchCalculations: (financedAmount: number, financeOptionIds: List<number>) =>
    createAction(FETCH_CALCULATIONS, { financedAmount, financeOptionIds }),
  receiveCalculations: (financedAmount: number, calculations: ICalculatedFinancingData[]) =>
    createAction(RECEIVE_CALCULATIONS, { financedAmount, calculations }),
  fetchEstimateCommission: (estimateId: number) => createAction(FETCH_ESTIMATE_COMMISSION, { estimateId }),
  receiveEstimateCommission: (commissionData: IEstimateCommissionData) =>
    createAction(RECEIVE_ESTIMATE_COMMISSION, { commissionData }),
  setCalulationsError: (financedAmount: number, financeOptionIds: List<number>, errors: string[]) =>
    createAction(SET_CALCULATIONS_ERROR, { financedAmount, financeOptionIds, errors }),
  setEstimateSignedDocuments: (jobId: number, estimateSignedDocuments: List<EstimateSignedDocumentRecord>) =>
    createAction(SET_ESTIMATE_SIGNED_DOCUMENTS, { jobId, estimateSignedDocuments }),
};

export const AsyncActions = {
  getEstimate: (estimateId: number, orgId?: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      //TODO: at some point this should do a quick etag call, something, to do cache validation
      // if (estimateSelector(getState(), { estimateId }) !== undefined) {
      //   return Promise.resolve(estimateSelector(getState(), { estimateId }));
      // }

      dispatch(Actions.fetchEstimate(estimateId));

      try {
        const response = await estimateService.load(estimateId);
        if (!orgId) {
          orgId = currentJobOrgId(getState());
        }
        await dispatch(
          activatedPriceListActions.AsyncActions.getCached(orgId, response.estimate.activated_price_list_id),
        );
        dispatch(Actions.receiveEstimate(response.estimate));
        dispatch(AsyncActions.updateEstimateTotal(estimateId));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.receiveEstimateError(estimateId, errors));
        return Promise.reject(response.data.errors);
      }
    };
  },
  updateOrCreateEstimate: (estimateId: number, idx = 0): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.fetchEstimate(estimateId));
      const estimate = toEstimateJson(getState(), { estimateId: estimateId, fullEstimate: true });

      try {
        const response = await estimateService.updateOrCreate(estimate);
        const updatedEstimate = response.estimate;
        dispatch(Actions.receiveEstimate(updatedEstimate));
        const documentsArray: IDocumentData[] = getCreatedLineItemDocuments(estimate, updatedEstimate);
        await dispatch(AsyncActions.directUploadEstimateLineItemDocuments(documentsArray));
        dispatch(AsyncActions.updateEstimateTotal(updatedEstimate.id));
        const estimateRecord = estimateSelector(getState(), { estimateId: updatedEstimate.id });
        dispatch(Actions.updateEstimateOverview(estimateRecord));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved estimate successfully"));
        return { index: idx, id: updatedEstimate.id };
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.receiveEstimateError(estimateId, errors));
        return Promise.reject(errors);
      }
    };
  },
  directUploadEstimateLineItemDocuments: (documentsArray: IDocumentData[]): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      if (documentsArray.length) {
        await Promise.all(
          documentsArray.map(async (document: IDocumentData) => {
            const options = {
              documentableType: "estimate_line_item" as DocumentableType,
              documentableId: document.documentable_id,
              display: document.display,
              disableFlash: true,
            };
            const files = [];
            try {
              const file: File = await download(document as any);
              files.push(file);
            } catch (response) {
              handleErrors([`A document named "${document.name}" failed to be fetched and attached.`], dispatch);
              return;
            }
            await dispatch(documentActions.AsyncActions.batchCreateDocument(files, options));
          }),
        );
      }
    };
  },
  updateEstimateTotal: (estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.updateBaseTotal(estimateId));
      dispatch(Actions.updateDiscountTotal(estimateId));
      dispatch(Actions.updateTaxTotal(estimateId));
      dispatch(Actions.updatePaymentTermTotal(estimateId));
      await dispatch(
        AsyncActions.reduxCalculateCalculatedFinancing(
          estimateId,
          estimateFinanceOptionIds(getState(), { estimateId }),
        ),
      );
      dispatch(Actions.setEstimateFinanceOptions(estimateId));
      dispatch(Actions.setLastSavedEstimate(estimateId));
    };
  },
  getJobEstimates: (jobId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchEstimates(jobId));

      try {
        const response = await estimateService.loadByJob(jobId);
        dispatch(Actions.receiveEstimates(jobId, response.estimates));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.receiveErrors(jobId, errors));
        return Promise.reject(response.data);
      }
    };
  },
  shallowUpdateEstimate: (estimate: EstimateRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.fetchEstimate(estimate.id));

      try {
        const response = await estimateService.shallowUpdate(estimate);
        dispatch(Actions.receiveEstimate(response.estimate));

        const estimateRecord = estimateSelector(getState(), { estimateid: estimate.id });
        dispatch(Actions.updateEstimateOverview(estimateRecord));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Updated estimate successfully"));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.receiveEstimateError(estimate.id, errors));
        return Promise.reject(response.data);
      }
    };
  },
  landingEstimate: (queryParams: any): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const key = queryParams.key;
      dispatch(Actions.fetchLandingEstimate(key));

      try {
        const response = await estimateService.landing(queryParams);
        dispatch(Actions.receiveLandingEstimate(key, response));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.setLandingLoaded(key));
        return Promise.reject(errors);
      }
    };
  },
  getEstimatePdf: (estimateId: number, docType: DocType, tempFolder = false): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const jobId = currentJobId(getState());
        dispatch(Actions.fetchEstimate(estimateId));
        const response = await estimateService.fetchPdf(jobId, estimateId, docType, tempFolder);
        const blob = await response.blob();
        const documentId = Number(response.headers.get("document-id"));
        dispatch(Actions.receiveEstimatePdf(estimateId, blob, documentId));
      } catch (response) {
        dispatch(Actions.setEstimateLoaded(estimateId));
        handleErrors(response, dispatch);
      }
    };
  },
  addDiscount: (estimateId: number, discount: IEstimateDiscountData): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.addDiscount(estimateId, discount));
      dispatch(AsyncActions.updateDiscountTotal(estimateId));
    };
  },
  removeDiscount: (estimateId: number, discount: EstimateDiscountRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.removeDiscount(estimateId, discount));
      dispatch(AsyncActions.updateDiscountTotal(estimateId));
    };
  },
  updateDiscount: (estimateId: number, discount: EstimateDiscountRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.updateDiscount(estimateId, discount));
      dispatch(AsyncActions.updateDiscountTotal(estimateId));
    };
  },
  updateDiscountTotal: (estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.updateDiscountTotal(estimateId));
      dispatch(Actions.updateTaxTotal(estimateId));
      dispatch(Actions.updatePaymentTermTotal(estimateId));
      await dispatch(
        AsyncActions.reduxCalculateCalculatedFinancing(
          estimateId,
          estimateFinanceOptionIds(getState(), { estimateId }),
        ),
      );
      dispatch(Actions.setEstimateFinanceOptions(estimateId));
    };
  },
  addPaymentTermTemplate: (estimateId: number, template: PaymentTermTemplateRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.addPaymentTermTemplate(estimateId, template));
      dispatch(Actions.updatePaymentTermTotal(estimateId));
      await dispatch(
        AsyncActions.reduxCalculateCalculatedFinancing(
          estimateId,
          estimateFinanceOptionIds(getState(), { estimateId }),
        ),
      );
      dispatch(Actions.setEstimateFinanceOptions(estimateId));
    };
  },
  addPaymentTermItem: (estimateId: number, item: PaymentTermItemRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.addPaymentTermItem(estimateId, item));
      dispatch(Actions.updatePaymentTermTotal(estimateId));
      await dispatch(
        AsyncActions.reduxCalculateCalculatedFinancing(
          estimateId,
          estimateFinanceOptionIds(getState(), { estimateId }),
        ),
      );
      dispatch(Actions.setEstimateFinanceOptions(estimateId));
    };
  },
  removePaymentTermItem: (estimateId: number, item: PaymentTermItemRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.removePaymentTermItem(estimateId, item));
      dispatch(Actions.updatePaymentTermTotal(estimateId));
      await dispatch(
        AsyncActions.reduxCalculateCalculatedFinancing(
          estimateId,
          estimateFinanceOptionIds(getState(), { estimateId }),
        ),
      );
      dispatch(Actions.setEstimateFinanceOptions(estimateId));
    };
  },
  updatePaymentTermItem: (estimateId: number, item: PaymentTermItemRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.updatePaymentTermItem(estimateId, item));
      dispatch(Actions.updatePaymentTermTotal(estimateId));
      await dispatch(
        AsyncActions.reduxCalculateCalculatedFinancing(
          estimateId,
          estimateFinanceOptionIds(getState(), { estimateId }),
        ),
      );
      dispatch(Actions.setEstimateFinanceOptions(estimateId));
    };
  },
  addFinanceOptionId: (estimateId: number, financeOptionId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.addFinanceOptionId(estimateId, financeOptionId));
      await dispatch(
        AsyncActions.reduxCalculateCalculatedFinancing(
          estimateId,
          estimateFinanceOptionIds(getState(), { estimateId }),
        ),
      );
      dispatch(Actions.setEstimateFinanceOptions(estimateId));
    };
  },
  reduxCalculateCalculatedFinancing: (
    estimateId: number,
    financeOptionIds: List<number>,
    forceRefresh = false,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const estimateRecord = estimateSelector(getState(), { estimateId });
      const financedAmount = estimateRecord.getIn(["payment_terms", "financed_amount"]);
      dispatch(
        AsyncActions.calculateCalculatedFinancing(estimateRecord, financedAmount, financeOptionIds, forceRefresh),
      );
    };
  },
  calculateCalculatedFinancing: (
    estimate: EstimateRecord | IEstimate,
    financedAmount: number,
    financeOptionIds: List<number>,
    forceRefresh: boolean,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const jobRecord = job(getState(), { jobId: estimate.job_id });
      const asyncFinanceOptionIds = filteredFinanceOptions(getState(), {
        providers: AsyncCalcFinanceOptionProviders,
        financeOptionIds,
        financedAmount,
        forceRefresh,
      }).map((fo) => fo.id);
      const localFinanceOptions = filteredFinanceOptions(getState(), {
        providers: LocalCalcFinanceOptionProviders,
        financeOptionIds,
        financedAmount,
      });

      if (localFinanceOptions.size > 0) {
        dispatch(Actions.receiveCalculations(financedAmount, localCalculations(localFinanceOptions, estimate)));
      }

      if (asyncFinanceOptionIds.size > 0) {
        dispatch(Actions.fetchCalculations(financedAmount, asyncFinanceOptionIds));
        try {
          const response = await financeOptionService.calculate(
            jobRecord.org_id,
            asyncFinanceOptionIds.toArray(),
            financedAmount,
          );
          dispatch(Actions.receiveCalculations(financedAmount, response.calculations));
        } catch (response) {
          const errors = handleErrors(response);
          dispatch(Actions.setCalulationsError(financedAmount, asyncFinanceOptionIds, errors));
        }
      }
    };
  },
  calculateEstimateCommission: (estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchEstimateCommission(estimateId));

      try {
        const response = await estimateService.getEstimateCommission(estimateId);
        dispatch(Actions.receiveEstimateCommission(response));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.receiveEstimateError(estimateId, errors));
        return Promise.reject(response.data.errors);
      }
    };
  },
  getCommissionPdf: (estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const data = await estimateService.getCommissionPdf(estimateId);
        fileSaver.saveAs(data, `estimate_${estimateId}_commission_sheet.pdf`);
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  emailEstimate: (args: EmailEstimateArgs): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const estimateId = selectedEstimateId(getState());
        await estimateService.emailEstimate(estimateId, args);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Document was successfully sent."));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  attachIntegration: (
    estimateId: number,
    integrationType: string,
    documentType: "estimate" | "contract",
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        await estimateService.attachIntegration(estimateId, documentType);
        const message = `Successfully attached document to ${
          integrationType === "marketsharp"
            ? "Contact in MarketSharp"
            : integrationType === "salesforce"
              ? "Opportunity in Salesforce"
              : integrationType === "job_nimbus"
                ? "Job Nimbus"
                : integrationType === "job_progress"
                  ? "JobProgress"
                  : integrationType === "lead_perfection"
                    ? "LeadPerfection"
                    : "integration"
        }.`;
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, message));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  signContract: (estimateId: number, notify: boolean, recipients: any[]): ThunkResult<Promise<number>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const state = getState();
        const estimate = estimateSelector(state, { estimateId });
        const fileName =
          currentJob(state).name + " - " + moment().format("MM-DD-Y HH:mm") + " - " + contractTitle(state) + ".pdf";

        const data = {
          include: ["document", "recipients"],
          notify: notify,
          document: {
            name: fileName,
          },
          signed_document: {
            document_id: estimate.get("tempDocumentId"),
            platform: settingsConfig(state, { path: ["signature_settings", "signature_platform"], notSet: "scribble" }),
            recipients_attributes: _.select(recipients, (recipient) => {
              return recipient.required || recipient.include;
            }),
          },
        };
        const { signed_document } = await estimateService.signContract(estimateId, data);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.info, "Signed Document uploaded.  Processing..."));
        await dispatch(taskActions.AsyncActions.pollTask(signed_document.document.task));
        return signed_document.document.signed_document.id;
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  manuallyTrigger: (estimateId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        await estimateService.manuallyTrigger(estimateId);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Successfully triggered."));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
