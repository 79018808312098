import * as productOptionActions from "./productOption.actions";
import { Map, Record } from "immutable";
import { ProductOptionRecord, fromJSON } from "../records/ProductOption";
import { RootActions, RootState } from ".";
import { ActivatedPriceListRecord } from "app2/src/records/ActivatedPriceList";

export const ProductOptionStateRecord = Record({
  byId: Map<number, ProductOptionRecord>(),
});

export const initialState = ProductOptionStateRecord();

export type ProductOptionState = typeof initialState;

export type ProductOptionReducerState = RootState | ActivatedPriceListRecord;

export const reducer = (state: ProductOptionReducerState, action: RootActions): ProductOptionReducerState => {
  const model = "productOptions";
  if (state && !state.getIn([model])) {
    state = state.setIn([model], initialState);
  }

  let productOption: ProductOptionRecord;
  switch (action.type) {
    case productOptionActions.RECEIVE_PRODUCT_OPTION:
      productOption = fromJSON({ ...action.payload.productOption });

      return state.setIn([model, "byId", productOption.id], productOption);

    default:
      return state;
  }
};
