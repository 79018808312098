import { DateFormat } from "app2/src/helpers/Format";
import * as React from "react";
import { Card, Dropdown, DropdownButton, InputGroup, Button } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import * as folderActions from "app2/src/reducers/folder.actions";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import { folder, folderChanged, lastSavedFolder } from "app2/src/selectors/folder.selectors";
import track from "react-tracking";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import RsfFinalForm from "app2/src/components/RsfForms/RsfFinalForm";
import { Field } from "react-final-form";
import { BootstrapInputAdapter } from "app2/src/components/RsfForms/BootstrapInputAdapter";
import { required } from "app2/src/helpers/FinalFormValidator";

const mapStateToProps = (state: RootState, ownProps: FolderProps) => {
  return {
    folder: folder(state, { folderId: ownProps.folderId }),
    lastSavedFolder: lastSavedFolder(state, { folderId: ownProps.folderId }),
    folderChanged: folderChanged(state, { folderId: ownProps.folderId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: FolderProps) => {
  return {
    editName: (folderId: number, name: string) => dispatch(folderActions.Actions.editName(folderId, name)),
    updateOrCreateFolder: (folderId: number) => dispatch(folderActions.AsyncActions.updateOrCreateFolder(folderId)),
    archiveFolder: (folderId: number) => dispatch(folderActions.AsyncActions.archiveFolder(folderId)),
    removeFolder: () => dispatch(folderActions.Actions.archiveFolder(ownProps.folderId)),
    resetFolder: () => dispatch(folderActions.Actions.resetFolder(ownProps.folderId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface FolderState {
  editing: boolean;
}

interface FolderProps {
  folderId: number;
  dragHandle: any;
  dragLock: boolean;
  readOnly?: boolean;
  navigate: (id: number) => void;
  setDragLock: (dragLock: boolean) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FolderProps;

@track((props) => {
  return {
    component: "Folder",
    folder: props.folderId,
  };
})
class Folder extends React.Component<Props, FolderState> {
  public componentRef: any;
  public confirm: any;
  public submit: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: props.folderId <= 0,
    };

    this.componentRef = React.createRef();
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.save = this.save.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.navigate = this.navigate.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.check = this.check.bind(this);
  }

  public componentDidMount(): void {
    const { editing } = this.state;
    const { setDragLock } = this.props;
    if (editing) {
      this.addEventListener();
      setDragLock(true);
    }
  }

  public componentWillUnmount(): void {
    this.removeEventListener();
  }

  public addEventListener(): void {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  public removeEventListener(): void {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  public handleClickOutside(event: any): void {
    if (this.componentRef && !this.componentRef.current.contains(event.target)) {
      const event = new Event("build");

      this.confirm(() => {
        this.submit(new Event("submit", { cancelable: true, bubbles: true }));
      })(event);
    }
  }

  @track(() => ({
    action: "edit folder",
  }))
  public edit(): void {
    const { setDragLock } = this.props;
    this.addEventListener();
    this.setState({ editing: true });
    setDragLock(true);
  }

  @track(() => ({
    action: "archive folder",
  }))
  public delete(): void {
    const { folder, archiveFolder } = this.props;
    archiveFolder(folder.id);
  }

  @track(() => ({
    action: "save folder",
  }))
  public save(): void {
    const { updateOrCreateFolder, folder } = this.props;
    this.closeEditing();
    updateOrCreateFolder(folder.id);
  }

  public onSubmit(formData): void {
    if (formData.name === "") {
      return;
    }
    this.handleNameChange(formData);
    this.save();
  }

  public navigate(): void {
    const { folder, navigate } = this.props;
    const { editing } = this.state;

    if (!editing && folder.id > 0) {
      navigate(folder.id);
    }
  }

  public handleNameChange(formData): void {
    const { folder, editName } = this.props;
    editName(folder.id, formData.name);
  }

  public stopPropagation(e): void {
    e.stopPropagation();
  }

  @track(() => ({
    action: "cancel",
  }))
  public cancel(): void {
    const { folderId, resetFolder, removeFolder } = this.props;
    if (folderId > 0) {
      resetFolder();
    } else {
      removeFolder();
    }
    this.closeEditing();
  }

  public closeEditing(): void {
    const { setDragLock } = this.props;
    this.setState({ editing: false });
    this.removeEventListener();
    setDragLock(false);
  }

  public check(): boolean {
    const { folderChanged } = this.props;

    return folderChanged;
  }

  public render(): React.ReactNode {
    const { lastSavedFolder, folder, dragHandle, readOnly, dragLock, resetFolder } = this.props;
    const { editing } = this.state;

    return (
      <Card className="folder-card" data-testid="folder-card" onClick={this.navigate} ref={this.componentRef}>
        <ConfirmDialog title="There are unsaved folder changes that will be lost. Would you like to save changes?">
          {(confirm) => {
            this.confirm = confirm;
            return null;
          }}
        </ConfirmDialog>
        <Card.Body>
          <FontAwesome className="card-icon-top fa-gray" name="folder-open-o" />
          <Card.Title className="mb-0">
            {editing ? (
              <>
                <DirtyWatcher check={this.check} reset={resetFolder} />
                <RsfFinalForm
                  onSubmit={this.onSubmit}
                  formUpdate={this.handleNameChange}
                  initialValues={{ name: lastSavedFolder.name }}>
                  {({ handleSubmit }) => {
                    this.submit = handleSubmit;
                    return (
                      <InputGroup>
                        <Field
                          autoFocus
                          name="name"
                          data-testid="name-editing"
                          component={BootstrapInputAdapter}
                          aria-label="Folder Name"
                          placeholder="Folder Name"
                          validate={required}
                          append={
                            <InputGroup.Append>
                              <Button type="submit" data-testid="save-button" title="Save Folder">
                                <FontAwesome name="save" />
                              </Button>
                              <Button
                                onClick={this.cancel}
                                variant="light"
                                data-testid="cancel-button"
                                title="Cancel Editing">
                                <FontAwesome name="times" />
                              </Button>
                            </InputGroup.Append>
                          }
                        />
                      </InputGroup>
                    );
                  }}
                </RsfFinalForm>
              </>
            ) : (
              folder.name
            )}
          </Card.Title>
          {!readOnly && !editing && (
            <>
              {!dragLock && (
                <Button
                  aria-label="drag"
                  className="top-right-1"
                  variant="link"
                  {...dragHandle}
                  onClick={this.stopPropagation}>
                  <FontAwesome data-testid="drag-handle" name="bars" size="lg" />
                </Button>
              )}
              <DropdownButton
                onClick={this.stopPropagation}
                drop="left"
                className="top-right-2"
                title={
                  <FontAwesome data-testid="dropdown-button" name="ellipsis-v" size="lg" style={{ width: "16px" }} />
                }
                variant="link">
                <Dropdown.Item href="#" onClick={this.edit}>
                  <FontAwesome name="pencil" />
                  &nbsp;Edit
                </Dropdown.Item>
                <ConfirmDialog
                  title={`Are you sure you want to delete the folder named ${folder.name} and all of the contents?`}>
                  {(confirm) => (
                    <Dropdown.Item href="#" onClick={confirm(this.delete)}>
                      <FontAwesome name="trash" />
                      &nbsp;Delete
                    </Dropdown.Item>
                  )}
                </ConfirmDialog>
              </DropdownButton>
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <small>
            Last Updated: {DateFormat(folder.updated_at, "shortName")}
            <FontAwesome name="arrow-right" className="float-right fa-gray" />
          </small>
        </Card.Footer>
      </Card>
    );
  }
}

export default connector(Folder);
