import * as React from "react";
import { Acl } from "app2/src/helpers/Acl";

export interface CanProps {
  resource: string;
  permission: string;
  /**
   * true - `read` does not match `read_assigned`
   * false - `read` matches `read_assigned`
   *
   * example:
   * allow 'read' to match 'read_assigned' defaults to `true`
   */
  exactMatch?: boolean;
  object?: any;
  inverse?: boolean;
}

export class Can extends React.Component<CanProps, {}> {
  protected _subscription: () => void;
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this._subscription = Acl.subscribe(() => {
      this.forceUpdate();
    });
  }

  public componentWillUnmount() {
    if (this._subscription) {
      this._subscription();
      this._subscription = null;
    }
  }

  public render() {
    const { resource, permission, inverse, exactMatch } = this.props;

    let inittedExactMatch = false;
    if (exactMatch !== undefined) {
      inittedExactMatch = exactMatch;
    }

    let decision = Acl.can(permission as any, resource as any, inittedExactMatch);
    if (inverse) {
      decision = !decision;
    }
    if (decision) {
      return this.props.children;
    } else {
      return null;
    }
  }
}
