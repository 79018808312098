import { QueryParamsRecord } from "app2/src/records/Page";

export interface IBaseData {
  id: number;
  selected: boolean;
  loading: boolean;
  _destroy: boolean;
  sort_order: number;
  errors: string[];
  created_at: Date;
  updated_at: Date;
}

export const baseCleanProps = <T extends Partial<IBaseData>>(params: T): T => {
  if (params.id <= 0) {
    delete params.id;
  }

  if (params.sort_order === null || params.sort_order === undefined) delete params.sort_order;
  if (!params._destroy) delete params._destroy;

  delete params.selected;
  delete params.loading;
  delete params.errors;
  delete params.created_at;
  delete params.updated_at;

  return params;
};

export const listParams = (queryParams: QueryParamsRecord): any => {
  let params: any = { sort_by: "sort_order", sort_order: "asc" };

  if (!queryParams.equals(new QueryParamsRecord())) {
    params = { ...params, ...queryParams.toJS() };
    delete params.parameters;
  }
  if (!params.folder_id) delete params.folder_id;
  if (!params.filter) delete params.filter;
  if (!params.query) delete params.query;

  return params;
};
