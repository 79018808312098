import * as React from "react";
import { PaymentRecord } from "app2/src/records/PaymentRecord";
import { PaySimplePaymentView } from "./PaySimple/PaySimplePaymentView";
import { CardConnectPaymentView } from "./CardConnect/CardConnectPaymentView";

export interface IPaymentViewProps {
  payment: PaymentRecord;
}

export default class PaymentView extends React.PureComponent<IPaymentViewProps> {
  public render() {
    const { payment } = this.props;
    switch (payment.kind) {
      case "paysimple":
        return <PaySimplePaymentView payment={payment} />;
      case "cardconnect":
        return <CardConnectPaymentView payment={payment} />;
      default:
        return <div>Payment</div>;
    }
  }
}
