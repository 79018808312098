import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IDoc } from "app/src/Models/Doc";
import { BaseEstimateTabCtrl } from "app/src/Jobs/tabs/BaseEstimateTabCtrl";
import { ISignedDocument } from "app/src/Models/SignedDocument";
import { IEstimate, IEstimateResource } from "app/src/Models/Estimate";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { ITask, Task } from "../../Models/Task";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import * as angulartics from "angulartics";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as documentActions from "app2/src/reducers/document.actions";
import { getPaginationByModel } from "app2/src/selectors/pagination.selectors";
import { DocumentRecord } from "app2/src/records/Document";

export class ContractTabCtrl extends BaseEstimateTabCtrl {
  public triggerSign = false;
  public selectionDisplay = false;
  public selectedContract: IDoc;
  public contractFileUrl: any;
  public signing = false;
  public showEmailAttachButton = false;
  public crmButton: boolean;
  public pdfUrl: string;
  public documents: DocumentRecord[] = [];
  public disabledFeatures = ["Annotations"];

  public static $inject = [
    "JobFetcher",
    "EstimatorService",
    "$stateParams",
    "BaseConfig",
    "$timeout",
    "$sce",
    "$scope",
    "$rootScope",
    "$state",
    "$analytics",
    "Flash",
    "$window",
    "$uibModal",
    "$q",
    "$http",
    "Estimate",
  ];

  constructor(
    public JobFetcher: IJobFetcherService,
    public EstimatorService: EstimatorService,
    public $stateParams: ng.ui.IStateParamsService,
    public BaseConfig: IBaseConfig,
    protected $timeout: ng.ITimeoutService,
    public $sce: ng.ISCEService,
    public $scope: ng.IScope,
    public $rootScope: RsfRootScope,
    protected $state: ng.ui.IStateService,
    protected $analytics: angulartics.IAnalyticsService,
    public Flash: IFlash,
    public $window: ng.IWindowService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    protected $q: ng.IQService,
    protected $http: ng.IHttpService,
    public Estimate: IEstimateResource,
  ) {
    super(
      JobFetcher,
      EstimatorService,
      $scope,
      $stateParams,
      $state,
      $analytics,
      $window,
      "contracts",
      $uibModal,
      Flash,
      Estimate,
      $http,
      $timeout,
      $stateParams["id"],
    );

    this.job.$promise.then((async () => {
      this.crmButton = _.contains(this.job.org.preferences.config.marketsharp.contract, "button");

      if (_.isNullOrUndefined(this.selectedEstimateId)) {
        return;
      }

      await dispatch(
        documentActions.AsyncActions.listDocuments(
          { documentableId: this.job.id, documentableType: "job", signed: "signed" },
          new QueryParamsRecord(),
        ),
      );

      this.documents = useSelector((state) =>
        getPaginationByModel(state, {
          modelName: "document",
          path: ["documents", "byId"],
        }),
      ).toJS();

      if (this.filteredSignedDocs().length <= 0) {
        this.signing = true;
      }
      this.showEmailAttachButton = this.filteredSignedDocs().length <= 0;
      this.$timeout(() => {
        this.$scope.$digest();
      });
    }) as () => ng.IPromise<void>);
  }

  public selectEstimateAndCheckForSigning(estimate: IEstimate) {
    super.selectEstimate(estimate);
    if (this.selectedEstimateId && this.filteredSignedDocs().length <= 0) {
      this.signing = true;
    }
    this.showEmailAttachButton = this.filteredSignedDocs().length <= 0;
  }

  public selectContract(doc: IDoc) {
    this.trackEvent("existing contract selected", {
      category: "Contracts",
      estimate: this.selectedEstimateId,
      contract: doc.id,
    });
    this.selectedContract = doc;
    this.pdfUrl = doc.file.url;
  }

  public notify(doc: IDoc) {
    this.showSigningNotificationComponent(doc.signed_document);
  }

  public attachMarketsharp() {
    this.trackEvent("attach agreement to marketsharp", {
      category: "Agreements",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "contract" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to Contact in MarketSharp");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public attachSalesforce() {
    this.trackEvent("attach agreement to salesforce", {
      category: "Agreements",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "contract" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to Opportunity in Salesforce");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public attachJobNimbus() {
    this.trackEvent("attach agreement to job nimbus", {
      category: "Agreements",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "contract" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to Job Nimbus");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public attachJobprogress() {
    this.trackEvent("attach agreement to jobprogress", {
      category: "Agreements",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "contract" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to JobProgress");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public attachLeadPerfection() {
    this.trackEvent("attach agreement to LeadPerfection", {
      category: "Agreements",
      estimate: this.selectedEstimateId,
    });
    this.Estimate.ms_upload({ id: this.selectedEstimateId, doc_type: "contract" })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Successfully attached document to LeadPerfection");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems attaching the document!");
      });
  }

  public clearContract() {
    this.trackEvent("clear selected contract", {
      category: "Contracts",
      estimate: this.selectedEstimateId,
    });
    this.showEmailAttachButton = false;
    this.selectedContract = null;
    this.contractFileUrl = null;
    this.cancelSigning();
  }

  public filteredSignedDocs(): DocumentRecord[] {
    return _.select(this.documents, (doc: DocumentRecord) => {
      return !!doc.signed_document && doc.signed_document.estimate_id === this.selectedEstimateId;
    });
  }

  public signedDocHtml(doc: IDoc): string {
    let htmlString = '<p class="tooltip-title">Recipients</p>';

    _.each(doc.signed_document.recipients, (recipient) => {
      htmlString = htmlString + '<p class="tooltip-recipient">' + recipient.name + ": " + recipient.status + "</p>";
    });

    htmlString = htmlString + "";

    return htmlString;
  }

  public pdf(estimate: IEstimate, doc_type: string) {
    if (!doc_type) {
      doc_type = "estimate";
    }
    estimate.job = this.job;
    this.EstimatorService.savePdf(estimate, doc_type);
  }

  public signingTitle() {
    return "Signature";
  }

  public signedTitle() {
    return "Email/Sign";
  }

  public toggleSelectionDisplay() {
    this.selectionDisplay = !this.selectionDisplay;
  }

  public signContract() {
    this.showEmailAttachButton = true;
    this.signing = true;
  }

  public triggerSigning() {
    this.triggerSign = true;
    this.$timeout(() => {
      this.trackEvent("sign contract", {
        category: "Contracts",
        estimate: this.selectedEstimateId,
      });
      this.triggerSign = false;
    }, 100);
  }

  public cancelSigning() {
    this.trackEvent("cancel sign contract", {
      category: "Contracts",
      estimate: this.selectedEstimateId,
    });
    this.signing = false;
    this.showEmailAttachButton = false;
  }

  public signingFinished(signedDocument: ISignedDocument, notify: boolean) {
    // Event category & action used for analytics job reporting - do not rename and keep upon refactor
    this.trackEvent("contract signed", {
      category: "Contracts",
      estimate: this.selectedEstimateId,
      document: signedDocument.document.id,
    });
    this.signing = false;

    const deferred: ng.IDeferred<void> = this.$q.defer();
    this.spinnerPromise = deferred.promise;
    Task.watch(this.$timeout, this.$http, signedDocument.document.location, (task: ITask) => {
      if (task.status === "error") {
        deferred.reject();
        this.Flash.addMessage(FlashLevels.danger, "There was an error in generating Signature Document.");
      }

      if (task.status === "finished") {
        if (notify) {
          signedDocument.document.$get().then(() => {
            deferred.resolve();
            this.Flash.addMessage(FlashLevels.success, "Signature Document successfully generated & sent.");
          });
        } else {
          deferred.resolve();
          this.Flash.addMessage(FlashLevels.success, "Signature Document successfully generated.");
        }
      }
    });

    if (!notify) {
      deferred.promise.then(() => {
        this.showSigningNotificationComponent(signedDocument);
      });
    }
  }

  protected showSigningNotificationComponent(signedDocument: ISignedDocument) {
    this.$state.go("job_header.job_show.contract.signing", {
      estimate_id: signedDocument.estimate_id,
      document_id: signedDocument.id,
    });
  }
}
