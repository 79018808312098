import { Room, TwilioError } from "twilio-video";
import { useEffect } from "react";
import { Callback } from "app2/src/components/VideoProvider/types";

export default function useHandleRoomDisconnectionErrors(room: Room, onError: Callback): any {
  useEffect(() => {
    const onDisconnected = (room: Room, error: TwilioError) => {
      if (error) {
        onError(error);
      }
    };

    room.on("disconnected", onDisconnected);
    return () => {
      room.removeListener("disconnected", onDisconnected);
    };
  }, [room, onError]);
}
