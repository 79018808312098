import * as ng from "angular";
import { IOrg } from "app/src/Models/Org";
import { IQueryMetadata } from "app/src/Models/PagingMetadata";
import { IMeasurementLink } from "app/src/Models/MeasurementLink";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IProductOptionGroup } from "./ProductOptionGroup";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IDoc } from "./Doc";
import { IProductOption } from "./ProductOption";
import { dispatch } from "app2/src/storeRegistry";
import * as skuActions from "app2/src/reducers/sku.actions";
import { ISkuData } from "app2/src/records/Sku";
export interface IProduct extends ng.resource.IResource<IProduct>, ProductPrototype {
  $saveOrCreate(...args: any[]): ng.IPromise<IProduct>;
}

export type AbleProp = "default" | "yes" | "no";

export interface ICustomProduct {
  name: string;
  uuid?: string;
  description: string;
  sort_order?: number;
  price: number;
  product_price: number;
  labor_price: number;
  included?: boolean;
  quantity: number;
  uom: string;
  image_ids?: number[];
  document_ids?: number[];
  images?: any[];
  documents?: any[];
  visibility?: string;
  image_url?: string;
  document_url?: string;
  estimate_group?: string;
}

export interface IProductToOptionGroupLink {
  id: number;
  option_group_id: number;
  group: IProductOptionGroup;
  product_id: number;
  product: IProduct;
  options: any;
  required_option_group: boolean;
}

export interface IProductResource extends ng.resource.IResourceClass<IProduct>, IRsfResource {
  byOrg?(params: any): IProductResponse;
  fromJSON?(data: any): IProduct;
  queryFromJSON?(data: any): IProductResponse;
}

export interface IProductResponse extends ng.resource.IResourceArray<IProduct> {
  products: IProduct[];
  parents: IProduct[];
  meta: IQueryMetadata;
}

export class Product {
  public static leaves(product, win_estimator): IProduct[] {
    let products = [];

    products = products.concat(this.getLeaves(product, win_estimator));

    return products;
  }

  public static getLeaves(product, win_estimator): IProduct[] {
    let products = [];
    const opening = win_estimator && (product.kind === "window" || product.kind === "door");
    if (product.children.length > 0 && !opening) {
      _.each(product.children, (child) => {
        products = products.concat(this.getLeaves(child, win_estimator));
      });
    } else {
      products = products.concat(product);
    }

    return products;
  }
}

export class ProductPrototype {
  public classy: string;
  public id: number;
  public org_id: number;
  public org: IOrg;
  public parent_id: number;
  public price_list_id: number;
  public parent: IProduct;
  public parents: IProduct[];
  public children: IProduct[];
  public images: any[];
  public documents: any[];
  public parent_docs: any[];
  public name: string;
  public ancestral_name: string;
  public ancestral_name_lowercase: string;
  public option_groups: number[];
  public description: string;
  public cost: number;
  public msrp: number;
  public price: number;
  public product_price: number;
  public labor_price: number;
  public visibility: string;
  public uom: string;
  public sort_order: number;
  public created_at: Date;
  public updated_at: Date;
  public uuid: string;
  public bracket_type: string;
  public min_ui: number;
  public max_ui: number;
  public min_width: number;
  public max_width: number;
  public min_height: number;
  public max_height: number;
  public base_product_price: number;
  public base_labor_price: number;
  public base_measure: number;
  public kind: string;
  public standard_measurement: number;
  public standard_uom: string;
  public measurement_link: IMeasurementLink;
  public size_html: string;
  public doc_html: string;
  public ptog_links: IProductToOptionGroupLink[];
  public options?: IProductOption[];
  public discountable?: AbleProp;
  public parent_discountable?: AbleProp;
  public markupable?: AbleProp;
  public parent_markupable?: AbleProp;
  public leaf: boolean;
  public include_parent_names: boolean;
  public sku_id?: number;
  public sku?: ISkuData;

  public $saveOrCreate(params, callback) {
    if (!this.id || this.id <= 0) {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$save(params, callback);
    }
  }

  public childrenLeaves() {
    return _.filter(this.children, (child) => {
      return child.children.length === 0;
    });
  }

  public showItem(presentation_mode = false) {
    // always, company_only
    if (presentation_mode) {
      return this.visibility === "always" || this.visibility === undefined;
    } else {
      return this.visibility === "always" || this.visibility === "company_only" || this.visibility === undefined;
    }
  }

  public searchChildren(id: number): IProduct {
    let match: IProduct;
    _.any(this.children, (child) => {
      child.parent = this as any as IProduct;
      if (child.id === id) {
        match = child;
        return true;
      }

      match = child.searchChildren(id);

      return match !== undefined;
    });

    // noinspection JSUnusedAssignment
    return match;
  }

  public selfAndDescendants(): IProduct[] {
    let products = [];
    products.push(this);

    products = products.concat(this.getChildren(this));

    return products;
  }

  public getChildren(product) {
    let products = [];
    if (product.children.length > 0) {
      products = products.concat(product.children);
      _.each(product.children, (child) => {
        products = products.concat(this.getChildren(child));
      });
    }

    return products;
  }

  public searchChildrenUuid(uuid: string): IProduct {
    let match: IProduct;
    _.any(this.children, (child) => {
      if (child.uuid === uuid) {
        match = child;
        return true;
      }

      match = child.searchChildrenUuid(uuid);

      return match !== undefined;
    });

    // noinspection JSUnusedAssignment
    return match;
  }

  public priceListEditorParentDocs(display, docs = false) {
    const doc_md5 = {};

    _.each(this.parents, (product) => {
      this.pullDocs(doc_md5, product, display, docs);
    });
    const doc_ids = [];
    _.each(Object.keys(doc_md5), (k) => {
      doc_ids.push(doc_md5[k]);
    });

    return doc_ids;
  }

  public pullDocs(doc_md5, product, display, docs) {
    _.each(product.documents, (d: IDoc) => {
      if (d.display === "both" || d.display === display || display === "both") {
        if (docs) {
          doc_md5[d.md5_sum] = d;
        } else {
          doc_md5[d.md5_sum] = d.id;
        }
      }
    });
    return doc_md5;
  }

  public copyValues(from: IProduct) {
    for (const key in from) {
      if (from.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        this[key] = from[key];
      }
    }
  }

  public hasSizes() {
    this.showSizes();
    return (
      this.min_ui > 0 ||
      this.max_ui > 0 ||
      this.min_width > 0 ||
      this.max_width > 0 ||
      this.min_height > 0 ||
      this.max_height > 0 ||
      this.kind === "window" ||
      this.kind === "door"
    );
  }

  public showSizes() {
    this.size_html = "";
    if (this.kind) {
      this.size_html = "Kind: " + this.kind.charAt(0).toUpperCase() + this.kind.slice(1);
      if (this.bracket_type) {
        this.size_html += "<br/>Bracket Type: " + this.bracket_type.toUpperCase().slice(0, -3);
      }
      this.size_html += "<br/>UI: " + this.min_ui + "-" + this.max_ui;
      this.size_html += "<br/>Min: " + this.min_width + " x " + this.min_height;
      this.size_html += "<br/>Max: " + this.max_width + " x " + this.max_height;
    }
  }

  public hasDocs() {
    if (_.isEmpty(this.documents)) {
      this.doc_html = "";
      return false;
    }
    this.doc_html = "Documents: ";
    _.each(this.documents, (doc) => {
      this.doc_html += "<br/>" + doc.name;
    });
    return true;
  }

  public ancestral_names(use_parent_names = false) {
    this.ancestral_name = this.name;
    const ipn = use_parent_names || _.isUndefined(this.include_parent_names) || this.include_parent_names;
    if (this.parent && ipn) {
      this.ancestral_name = this.parent.ancestral_names(use_parent_names) + " > " + this.name;
    }

    return this.ancestral_name;
  }

  public cloneMin() {
    const clone_product = new ProductPrototype() as any as IProduct;
    clone_product.name = this.name;
    clone_product.uom = this.uom;
    clone_product.documents = this.documents;
    clone_product.id = this.id;
    clone_product.uuid = this.uuid;
    clone_product.images = this.images;
    clone_product.option_groups = this.option_groups;
    clone_product.ptog_links = this.ptog_links;
    clone_product.parent_id = this.parent_id;
    clone_product.description = this.description;
    clone_product.include_parent_names = this.include_parent_names;
    clone_product.standard_measurement = this.standard_measurement;
    clone_product.standard_uom = this.standard_uom;
    clone_product.labor_price = this.labor_price;
    clone_product.base_measure = this.base_measure;
    clone_product.base_product_price = this.base_product_price;
    clone_product.base_labor_price = this.base_labor_price;
    clone_product.product_price = this.product_price;
    clone_product.bracket_type = this.bracket_type;

    // Properties defined in this way are ignored by JSON.stringify (on prototype, not own)
    Object.defineProperty(clone_product, "parent", { value: this.parent });

    return clone_product;
  }

  public optionGroups(type: string, optionGroupsById: IProductOptionGroup[]) {
    let option_groups = [];
    if (this.option_groups) {
      // supports old activated price lists
      option_groups = this.option_groups;
    } else {
      option_groups = _.pluck(this.ptog_links, "option_group_id");
    }
    return _.filter(
      _.map(option_groups || [], (og_id) => {
        let backwards = false;
        if (type === "default") {
          backwards = _.isUndefined(optionGroupsById[og_id].pog_type);
        }
        if (backwards || optionGroupsById[og_id].pog_type === type) {
          return optionGroupsById[og_id];
        }
      }),
      (og) => {
        return !_.isUndefined(og);
      },
    );
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/products/:id";

  const transform = (data: any, options: any = {}): IProduct => {
    data.classy = "Product";
    if (data.org !== undefined) {
      data.org = resources.Org.fromJSON(data.org);
    }

    if (_.isArray(data.images)) {
      _.each(data.images, (image, idx) => {
        data.images[idx] = resources.Image.fromJSON(image);
      });
    } else {
      data.images = [];
    }

    if (_.isUndefined(data.documents)) {
      data.documents = [];
    }

    // if there's "nothing" then it's true
    if (data.include_parent_names === undefined) {
      data.include_parent_names = true;
    }

    // check if we need to pass down discountable
    // else if options has discountable, set this data as discountable if it's a leaf node
    if (data.discountable) {
      options.discountable = data.discountable;
    } else if (
      options.discountable &&
      data.children !== undefined &&
      _.isArray(data.children) &&
      data.children.length <= 0
    ) {
      data.discountable = options.discountable;
    }

    if (!data.base_measure) {
      data.base_measure = 0;
    }

    if (!data.base_product_price) {
      data.base_product_price = 0;
    }

    if (!data.base_labor_price) {
      data.base_labor_price = 0;
    }

    if (data.children !== undefined && _.isArray(data.children)) {
      _.each(data.children, (child, index) => {
        data.children[index] = transform(child, {
          ...options,
        });
      });
    } else {
      data.children = [];
    }

    if (data.sku) {
      data.sku_id = data.sku.id;
    } else {
      data.sku_id = 0;
    }

    const new_product = new Product(data);
    _.each(new_product.children, (child) => {
      child.parent = new_product;
    });

    return new_product;
  };

  const requestTransform = (product: IProduct) => {
    const data = JSON.parse(angular.toJson(JSON.decycle(product)));
    data.images_attributes = data.images;
    data.documents_attributes = data.documents;
    data.product_to_option_group_links_attributes = data.ptog_links;
    delete data.images;
    delete data.documents;
    delete data.ptog_links;

    return angular.toJson({ product: data });
  };

  const receiveSku = (data: any) => {
    if (data.sku) {
      dispatch(skuActions.Actions.receiveSku(data.sku));
    }
  };

  const Product: IProductResource = <IProductResource>$resource(
    url,
    { id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return new Product({});
          }

          const data: any = JSON.parse(response).product;
          receiveSku(data);
          return transform(data);
        },
        isArray: false,
      },
      save: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: requestTransform,
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return new Product({});
          }

          const data: any = JSON.parse(response).product;
          receiveSku(data);
          return transform(data);
        },
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: requestTransform,
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return new Product({});
          }

          const data: any = JSON.parse(response).product;
          receiveSku(data);
          return transform(data);
        },
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/api/v1/products/",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const meta: IProductResponse = JSON.parse(response);

          _.each(meta.products, (child, index) => {
            meta.products[index] = transform(child);

            receiveSku(meta.products[index]);
          });

          _.each(meta.parents, (child, index) => {
            meta.parents[index] = transform(child);
          });

          return meta;
        },
        isArray: false,
      },
      byOrg: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/products",
        transformResponse: (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
          if (status < 200 || status > 299) {
            return JSON.parse(response);
          }

          const meta: any = JSON.parse(response);

          _.each(meta.products, (child, index) => {
            meta.products[index] = transform(child);
          });

          return meta;
        },
        isArray: false,
      },
    },
  );

  Product.fromJSON = (data: any): IProduct => {
    return transform(data);
  };

  Product.queryFromJSON = (data: any) => {
    const response: IProductResponse = {
      products: [],
      meta: {},
    } as IProductResponse;

    if (!data.products) {
      return response;
    }

    _.each(data.products, (p) => {
      response.products.push(transform(p));
    });

    if (data.meta) {
      response.meta = data.meta;
    }

    return response;
  };

  _.hiddenExtend(Product.prototype, ProductPrototype.prototype);

  Product.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Product;
};

factory.$inject = <ReadonlyArray<string>>["$resource", "BaseConfig"];

export default factory;
