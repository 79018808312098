import * as React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";
import { history } from "app2/src/store";
import { connectFC } from "app2/src/connect";
import { currentOrgId, orgLoading } from "app2/src/selectors/org.selectors";
import { useSelector } from "react-redux";
import UsersTab from "./UsersTab";
import { JobAttributeTab } from "./JobAttributeTab";
import WebhooksTab from "./WebhooksTab";
import { useTracking } from "react-tracking";
import { RootState } from "app2/src/reducers";

const OrgTabs = () => {
  const loading = useSelector(orgLoading);
  const orgId = useSelector((state: RootState) => currentOrgId(state));
  const { Track } = useTracking<any>({ component: "OrgTabs", org: orgId });

  return (
    <Track>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={"/orgs/:orgId"}>{loading === false && <OrgRoutes />}</Route>
        </Switch>
      </ConnectedRouter>
    </Track>
  );
};

const OrgRoutes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/info`} />
      <Route path={`${match.url}/notifications`} />
      <Route path={`${match.url}/setup`} />
      <Route path={`${match.url}/preferences`} />
      <Route path={`${match.url}/settings`} />
      <Route path={`${match.url}/duplicate_data`} />
      <Route path={`${match.url}/jobs`} />
      <Route path={`${match.url}/orgs`} />
      <Route path={`${match.url}/users`} component={UsersTab} />
      <Route path={`${match.url}/billing`} />
      <Route path={`${match.url}/integrations`} />
      <Route path={`${match.url}/documents`} />
      <Route path={`${match.url}/presentations`} />
      <Route path={`${match.url}/price_lists`} />
      <Route path={`${match.url}/price_list_editor`} />
      <Route path={`${match.url}/measurement_links`} />
      <Route path={`${match.url}/patterns`} />
      <Route path={`${match.url}/payment_terms`} />
      <Route path={`${match.url}/job_attributes`} component={JobAttributeTab} />
      <Route path={`${match.url}/discounts`} />
      <Route path={`${match.url}/stats`} />
      <Route path={`${match.url}/emails`} />
      <Route path={`${match.url}/webhooks`} component={WebhooksTab} />
      <Route path={`${match.url}`} render={() => <Redirect to={`${match.url}/info`} />} />
      <Route render={() => <Redirect to="/orgs" />} />
    </Switch>
  );
};

export default connectFC(OrgTabs);
