import * as ng from "angular";
import { EagleviewOrderingModalComponent } from "./EagleviewOrderingModalComponent";
import { EagleviewLoginModalComponent } from "./EagleviewLoginModalComponent";
import { ReportListCtrl } from "./ReportListCtrl";
import { react2angular } from "react2angular";
import DashboardComponent from "app2/src/components/Analytics/DashboardComponent";
import LeadsComponent from "app2/src/components/Leads/LeadsComponent";
import EagleviewReportImportComponent from "app2/src/components/Integrations/Eagleview/EagleviewReportImportComponent";
import HoverImportJob from "app2/src/components/Integrations/Hover/ImportJob";
import QuickMeasureOrder from "app2/src/components/Integrations/QuickMeasure/QuickMeasureOrder";
import RoofScopeReportModal from "app2/src/components/Integrations/RoofScope/RoofScopeReportModal";
import PlnarReportModal from "app2/src/components/Integrations/Plnar/PlnarReportModal";

export class ReportRoutes {
  public static load(app: ng.IModule) {
    app.controller("ReportListCtrl", ReportListCtrl);
    app.component("eagleviewOrderingModal", new EagleviewOrderingModalComponent());
    app.component("eagleviewLoginModal", new EagleviewLoginModalComponent());
    app.component("analyticsDashboard", react2angular(DashboardComponent, null, ["Repository"]));
    app.component("leadsList", react2angular(LeadsComponent, null, ["$state", "$scope"]));
    app.component("eagleviewImport", react2angular(EagleviewReportImportComponent, null, ["Repository"]));
    app.component("hoverImport", react2angular(HoverImportJob, ["modalInstance"], ["Repository"]));
    app.component("quickMeasureModal", react2angular(QuickMeasureOrder, ["address"]));
    app.component("roofScopeReportModal", react2angular(RoofScopeReportModal, ["address"]));
    app.component("plnarReportModal", react2angular(PlnarReportModal, ["address"]));
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("root.analytics", <ng.ui.IState>{
        url: "/analytics",
        views: {
          "container@": {
            component: "analyticsDashboard",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.leads_list", <ng.ui.IState>{
        url: "/leads",
        views: {
          "container@": {
            component: "leadsList",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.report_list", <ng.ui.IState>{
        url: "/reports?page&per&by&order&q",
        views: {
          "container@": {
            templateUrl: "src/Reports/list.html",
            controller: "ReportListCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.report_show", <ng.ui.IState>{
        url: "/reports/:id",
        views: {
          "container@": {
            templateUrl: "src/Reports/show.html",
            controller: "ReportShowCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      });
  }
}
