import * as React from "react";
import { Row, Col, Pagination } from "react-bootstrap";
import track from "react-tracking";

export interface StandardPaginationProps {
  pageChanged: (page: number) => void;
  metadata: any;
}

export interface StandardPaginationState {}

@track(() => {
  return {
    component: "StandardPagination",
  };
})
export class StandardPagination extends React.Component<StandardPaginationProps, StandardPaginationState> {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  @track((_props, _state, [page]) => ({
    action: "pagination page changed",
    page: page,
  }))
  public handlePageChange(page: number): void {
    this.props.pageChanged(page);
  }

  public render() {
    const { metadata } = this.props;

    if (!metadata) {
      return (
        <Row>
          <Col md={12}>
            <Pagination className="justify-content-center">
              <Pagination.First />
              <Pagination.Last />
            </Pagination>
          </Col>
        </Row>
      );
    }

    let { total_pages, current_page } = metadata;

    let preEllipsis = null,
      preArrow = null;
    let postEllipsis = null,
      postArrow = null;

    current_page = parseInt(current_page || 1);
    total_pages = parseInt(total_pages || 1);

    if (total_pages === 1) {
      return (
        <Row>
          <Col md={12}>
            <Pagination className="justify-content-center">
              <Pagination.Ellipsis />
            </Pagination>
          </Col>
        </Row>
      );
    }

    const pre = [],
      post = [];

    if (current_page > 1) {
      const preNumber = Math.max(1, current_page - 2);

      for (let i = preNumber; i < current_page; i++) {
        pre.push(
          <Pagination.Item key={i} onClick={() => this.handlePageChange(i)}>
            {i}
          </Pagination.Item>,
        );
      }

      if (preNumber > 1) {
        preEllipsis = <Pagination.Ellipsis />;
      }

      preArrow = <Pagination.Prev onClick={() => this.handlePageChange(current_page - 1)} />;
    }

    if (current_page < total_pages) {
      const available = 4 - pre.length;
      const postNumber = Math.min(total_pages, current_page + available);

      for (let i = current_page + 1; i <= postNumber; i++) {
        post.push(
          <Pagination.Item key={i} onClick={() => this.handlePageChange(i)}>
            {i}
          </Pagination.Item>,
        );
      }

      if (postNumber < total_pages) {
        postEllipsis = <Pagination.Ellipsis />;
      }

      postArrow = <Pagination.Next onClick={() => this.handlePageChange(current_page + 1)} />;
    }

    return (
      <Row>
        <Col md={12}>
          <Pagination className="justify-content-center">
            <Pagination.First disabled={current_page === 1} onClick={() => this.handlePageChange(1)} />
            {preArrow}
            {preEllipsis}
            {pre}
            <Pagination.Item active onClick={() => this.handlePageChange(current_page)}>
              {current_page}
            </Pagination.Item>
            {post}
            {postEllipsis}
            {postArrow}
            <Pagination.Last
              disabled={current_page === total_pages}
              onClick={() => this.handlePageChange(total_pages)}
            />
          </Pagination>
        </Col>
      </Row>
    );
  }
}
