import { Record, List } from "immutable";

export const fromJSON = (json: Partial<IInvoiceTextData>): InvoiceTextRecord => {
  const record = { ...json } as any as IInvoiceTextRecord;

  if (json.lines && json.lines.length > 0) {
    record.lines = List(json.lines.map((l) => lineFromJSON(l)));
  } else {
    record.lines = List();
  }

  return new InvoiceTextRecord(record);
};

export interface IInvoiceTextData {
  lines: IInvoiceTextLineData[];
}

export interface IInvoiceTextRecord {
  lines: List<InvoiceTextLineRecord>;
}

const defaultInvoiceTextProps: IInvoiceTextRecord = {
  lines: List(),
};

export class InvoiceTextRecord extends Record(defaultInvoiceTextProps) implements IInvoiceTextRecord {
  public readonly lines!: List<InvoiceTextLineRecord>;

  public constructor(values?: Partial<IInvoiceTextRecord>) {
    values ? super(values) : super();
  }
}

export const lineFromJSON = (json: Partial<IInvoiceTextLineData>): InvoiceTextLineRecord => {
  return new InvoiceTextLineRecord(json);
};

export interface IInvoiceTextLineData {
  header: string;
  text: string;
}

export interface IInvoiceTextLineRecord {
  header: string;
  text: string;
}

const defaultInvoiceTextLineProps: IInvoiceTextLineRecord = {
  header: "",
  text: "",
};

export class InvoiceTextLineRecord extends Record(defaultInvoiceTextLineProps) implements IInvoiceTextLineRecord {
  public readonly header!: string;
  public readonly text!: string;

  public constructor(values?: Partial<IInvoiceTextLineRecord>) {
    values ? super(values) : super();
  }
}
