import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { useTracking } from "react-tracking";

export interface MessageViewProps {
  analyticsAction: string;
  message: string;
  imgSrc: string;
  detailsUrl?: string;
  closeHandler?: () => void;
}

export const MessageView: React.FC<MessageViewProps> = (props) => {
  const { message, imgSrc, analyticsAction, closeHandler, detailsUrl, children } = props;

  useTracking(
    {
      action: analyticsAction,
    },
    {
      dispatchOnMount: true,
    },
  );

  return (
    <Row>
      <Col>
        <div className="form-section blank-state">
          {closeHandler && (
            <div className="form-section-heading">
              <Button onClick={closeHandler} variant="link" title="Close" className="pull-right">
                <FontAwesome name="times" />
              </Button>
            </div>
          )}
          <img src={imgSrc} />
          <h2>{message}</h2>
          {children}
          {detailsUrl && (
            <Button href={detailsUrl} target="_blank">
              Get Details
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};
