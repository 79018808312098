import * as React from "react";
import Spinner from "app2/src/components/SpinnerComponent";
import { Context } from "app2/src/components/Homevest/Context";
import { MessageView } from "../EmbeddedView/MessageView";
import { useTracking } from "react-tracking";

export interface IOffersProps {}

/**
 * Container for the Offers Listing for Homevest.  Contains the Filters & Listing sections.
 *
 * Also responsible for displaying messages according to the loan_status, if no FinanceOptions
 * are present, the Finance Application is out of date, or if the Estimate is dirty and the user
 * needs to either Apply changes or cancel.
 *
 */
export const Offers: React.FunctionComponent<IOffersProps> = (props: IOffersProps) => {
  const { financeApplication } = React.useContext(Context);
  useTracking({ action: "Homevest Offers shown" }, { dispatchOnMount: true });

  const loan_status = React.useMemo(() => financeApplication.get("loan_status"), [financeApplication]);
  const isLoading = React.useMemo(() => !financeApplication || financeApplication.get("loading"), [financeApplication]);

  if (loan_status === "DECLINED") {
    return (
      <>
        <Spinner localProperty={isLoading} />
        <MessageView
          detailsUrl={financeApplication.details_url}
          message="Sorry, the status of the loan is DECLINED."
          imgSrc="/assets/images/homevest_finance.ada98c4a.png"
          analyticsAction="declined"
        />
      </>
    );
  }

  if (loan_status === "APPROVED") {
    return (
      <>
        <Spinner localProperty={isLoading} />
        <MessageView
          detailsUrl={financeApplication.details_url}
          message="Congratulations, the loan was APPROVED."
          imgSrc="/assets/images/homevest_finance.ada98c4a.png"
          analyticsAction="approved"
        />
      </>
    );
  }

  if (loan_status === "PREQUALAPPROVE")
    return (
      <>
        <Spinner localProperty={isLoading} />
        <MessageView
          detailsUrl={financeApplication.details_url}
          message="Application status is PREQUALAPPROVE."
          imgSrc="/assets/images/homevest_finance.ada98c4a.png"
          analyticsAction="prequalapprove"
        />
      </>
    );

  if (loan_status === "PREQUALDECLINE")
    return (
      <>
        <Spinner localProperty={isLoading} />
        <MessageView
          detailsUrl={financeApplication.details_url}
          message="Application status is PREQUALDECLINE."
          imgSrc="/assets/images/homevest_finance.ada98c4a.png"
          analyticsAction="no offers"
        />
      </>
    );

  return (
    <>
      <Spinner localProperty={isLoading} />
      <MessageView
        detailsUrl={financeApplication.details_url}
        message={`Finance application status exception. Current status is ${loan_status}.`}
        imgSrc="/assets/images/homevest_finance.ada98c4a.png"
        analyticsAction="status exception">
        <p>
          Please contact <a href="mailto:support@homevestfinance.com">support@homevestfinance.com</a>
        </p>
      </MessageView>
    </>
  );
};
