import { baseCleanProps, listParams } from "app2/src/api/Api";
import { IMetaPaginationData } from "app2/src/records/MetaPagination";
import { QueryParamsRecord } from "app2/src/records/Page";
import { List } from "immutable";
import { fetcher } from "../helpers/Fetcher";
import { IPresentationData, PresentationRecord, toFormData, toJSON } from "../records/Presentation";

export interface IPresentationIndexResponse {
  presentations: IPresentationData[];
  meta?: { pagination: IMetaPaginationData };
}

export interface IPresentationResponse {
  presentation: IPresentationData;
}

export interface IPresentationListOptions {
  id: number;
  kind: "org" | "job";
  folderId?: number;
  presentationIds?: List<number>;
}

class PresentationService {
  public orgUrl = "api/v1/orgs/:id/presentations";
  public jobUrl = "api/v1/jobs/:id/presentations";
  public url = "api/v1/presentations";

  public load(id: number, kind: "org" | "job" = "org", include: string[] = []): Promise<IPresentationIndexResponse> {
    let actionUrl = this[`${kind}Url`].replace(":id", id.toString());

    if (include.length > 0) {
      actionUrl = fetcher.urlOptions(actionUrl, {
        "include[]": include,
      });
    }
    return fetcher.get<IPresentationIndexResponse>(actionUrl);
  }

  public list(
    options: IPresentationListOptions,
    queryParams: QueryParamsRecord = new QueryParamsRecord(),
  ): Promise<IPresentationIndexResponse> {
    const actionUrl = this[`${options.kind}Url`].replace(":id", options.id.toString());
    const params = listParams(queryParams);

    if (options.folderId && options.folderId !== 0) {
      params.folder_id = options.folderId;
    }

    if (options.presentationIds) {
      params["presentation_ids[]"] = options.presentationIds.toArray();
    }

    return fetcher.get<IPresentationIndexResponse>(actionUrl, params);
  }

  public create(
    id: number,
    presentation: PresentationRecord,
    kind: "org" | "job" = "org",
  ): Promise<IPresentationResponse> {
    const actionUrl = this[`${kind}Url`].replace(":id", id.toString());

    const data: Partial<IPresentationData> = this.cleanProps(toJSON(presentation));

    return fetcher.post<IPresentationResponse>(actionUrl, { presentation: data });
  }

  public upload(presentation: PresentationRecord): Promise<IPresentationResponse> {
    const actionUrl = this.orgUrl.replace(":id", presentation.org_id.toString());
    return fetcher.post<IPresentationResponse>(actionUrl, toFormData(presentation), { formData: true });
  }

  public update(presentation: PresentationRecord): Promise<IPresentationResponse> {
    const actionUrl = fetcher.joinUrls(this.url, presentation.id.toString());

    const data: Partial<IPresentationData> = this.cleanProps(toJSON(presentation));

    return fetcher.patch<IPresentationResponse>(actionUrl, { presentation: data });
  }

  public destroy(presentation: PresentationRecord): Promise<IPresentationResponse> {
    const actionUrl = fetcher.joinUrls(
      this.orgUrl.replace(":id", presentation.org_id.toString()),
      presentation.id.toString(),
    );
    return fetcher.delete(actionUrl);
  }

  public coverImage(presentation: PresentationRecord): Promise<IPresentationResponse> {
    const actionUrl = fetcher.joinUrls(
      this.orgUrl.replace(":id", presentation.org_id.toString()),
      presentation.id.toString(),
      "cover_image",
    );
    return fetcher.post(actionUrl, toFormData(presentation), { formData: true });
  }

  public cleanProps(presentation: Partial<IPresentationData>): Partial<IPresentationData> {
    presentation = baseCleanProps(JSON.parse(JSON.stringify(presentation)));

    if (_.isNullOrUndefined(presentation.folder_id) || presentation.folder_id === 0) {
      delete presentation.folder_id;
    }

    if (_.isNullOrUndefined(presentation.file)) {
      delete presentation.file;
    }

    if (presentation.dynamic_links) {
      if (presentation.dynamic_links.length > 0) {
        (presentation as any).dynamic_presentation_links_attributes = presentation.dynamic_links;
        delete presentation.slides;
      }
      delete presentation.dynamic_links;
    }

    if (presentation.slides) {
      (presentation as any).slides_attributes = presentation.slides;
      delete presentation.slides;
    }

    delete presentation.dynamic_slides;

    return presentation;
  }
}

export const presentationService = new PresentationService();
