import * as wallFacadeActions from "./wallFacade.actions";
import { Map, Record } from "immutable";
import { WallFacadeRecord, fromJSON } from "../records/WallFacade";
import { RootActions, RootState } from ".";
import { rootKey } from "app2/src/selectors/wallFacade.selectors";

export const WallFacadeStateRecord = Record({
  byId: Map<number, WallFacadeRecord>(),
  lastSavedById: Map<number, WallFacadeRecord>(),
  unsavedId: -1,
});

export const initialState = WallFacadeStateRecord();

export type WallFacadeState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(rootKey)) {
    state = state.set(rootKey, initialState);
  }

  let wallFacade: WallFacadeRecord;
  switch (action.type) {
    case wallFacadeActions.RECEIVE_WALL_FACADE:
      wallFacade = fromJSON({ ...action.payload.wallFacade });

      return state
        .setIn([rootKey, "byId", wallFacade.id], wallFacade)
        .setIn([rootKey, "lastSavedById", wallFacade.id], wallFacade);

    case wallFacadeActions.EDIT_WALL_FACADE:
      wallFacade = fromJSON({ ...action.payload.wallFacade });

      return state.setIn([rootKey, "byId", wallFacade.id], wallFacade);

    default:
      return state;
  }
};
