import * as React from "react";
import { List, Record } from "immutable";
import Select, { Options } from "react-select";

interface ReactSelectWrapperProps {
  defaultValue: List<Record<{ id: number; name: string }>>;
  available: List<Record<{ id: number; name: string }>>;
  onChange: (list: Options<List<Record<{ id: number; name: string }>>>) => void;
}

export interface ReactSelectWrapperState {}

export class ReactSelectWrapper extends React.Component<ReactSelectWrapperProps, ReactSelectWrapperState> {
  constructor(props: ReactSelectWrapperProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { defaultValue, available, onChange } = this.props;

    if (!defaultValue) {
      return <></>;
    }

    return (
      <Select
        defaultValue={defaultValue || []}
        options={(available || List()).toArray()}
        onChange={onChange}
        closeMenuOnSelect={false}
        isMulti
        getOptionLabel={(x: any) => x.name.toString()}
        getOptionValue={(x: any) => x.id.toString()}></Select>
    );
  }
}
