import * as React from "react";
import { Field } from "react-final-form";

interface ConditionProps {
  when: string;
  is: string | boolean;
  children: JSX.Element[] | JSX.Element;
}

export const Condition: React.FunctionComponent<ConditionProps> = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);
