import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IProductOptionGroup } from "../../../Models/ProductOptionGroup";

export class MatchWizardCtrl {
  public show_groups = false;
  public list: IProductOptionGroup[];
  public check_count: number;
  public currentPage = 1;
  public pageSize = 20;

  public static $inject = ["$uibModalInstance", "Flash", "objects", "matchesInput", "Pretty"];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    public Flash: IFlash,
    public objects: any,
    public matchesInput: any,
    public Pretty: IPretty,
  ) {
    if (matchesInput.selection_mode) {
      this.show_groups = true;
      this.list = this.matchingGroups();
    } else {
      this.list = this.objects;
    }

    _.each(this.list, (input) => {
      input.check = input.match_id === matchesInput.match_id;
    });
    this.list = _.sortBy(this.list, function (item) {
      return !item.check;
    });
    this.countChecks();
  }

  public save() {
    this.$uibModalInstance.close(this.list);
  }

  public cancel() {
    this.$uibModalInstance.dismiss("cancel");
  }

  public matchingGroups(): IProductOptionGroup[] {
    return _.filter(this.objects, (pog: IProductOptionGroup) => {
      return pog.selection_mode === this.matchesInput.selection_mode;
    });
  }

  public uncheckAll() {
    _.each(this.list, (group, index) => {
      if (index === 0) {
        return;
      }
      group.check = false;
    });
    this.countChecks();
    this.Flash.addMessage(FlashLevels.success, "Match on name successful.");
  }

  public matchOnName() {
    _.each(this.list, (group) => {
      if (group.name === this.matchesInput.name) {
        group.check = true;
      }
    });
    this.countChecks();
    this.list = _.sortBy(this.list, function (item) {
      return !item.check;
    });
    this.Flash.addMessage(FlashLevels.success, "Match on name successful.");
  }

  public countChecks() {
    this.check_count = _.where(this.list, { check: true }).length;
  }
}
