import { ActionsUnion, createAction } from "./Utils";
import { IProductOptionData } from "app2/src/records/ProductOption";

export const RECEIVE_PRODUCT_OPTION = "@productOptions/RECEIVE_PRODUCT_OPTION";

export const Actions = {
  receiveProductOption: (productOption: IProductOptionData) => createAction(RECEIVE_PRODUCT_OPTION, { productOption }),
};

export type Actions = ActionsUnion<typeof Actions>;
