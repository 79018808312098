import * as React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import * as config from "react-global-configuration";
import { Can } from "../../Common/CanComponent";
import { settingsConfig } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: OCCSettingsProps) => {
  const settings = settingsConfig(state, { orgId: ownProps.orgId });
  return {
    occEmail: settings?.getIn(["email", "send_from"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: OCCSettingsProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface OCCSettingsProps {
  orgId: number;
  updateSettingsConfig: (path: any, value: any) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OCCSettingsProps;

class OCCSettings extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { occEmail, updateSettingsConfig } = this.props;

    return (
      <Can resource="global" permission="tools">
        <div className="form-section gaf-section-heading">
          <div className="form-section-heading">
            <h3>{config.get("APP_NAME_SHORT")} Settings</h3>
          </div>
          <div className="form-section-content">
            <Row>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Send From Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="send_from_email"
                    defaultValue={occEmail}
                    onChange={(e) => updateSettingsConfig(["email", "send_from"], e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </Can>
    );
  }
}

export default connector(OCCSettings);
