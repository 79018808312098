import { Record, List, Map, fromJS } from "immutable";
import { IAddressData, fromJSON as addressFromJSON, AddressRecord, toJSON as addrToJSON } from "./Address";
import { IImageData, ImageRecord, fromJSON as imageFromJSON } from "./Image";
import { IPhoneNumberData, fromJSON as phoneFromJSON, toJSON as phoneToJSON, PhoneNumberRecord } from "./PhoneNumber";
import { fromJSON as overviewFromJSON } from "./EstimateOverview";
import { fromJSON as assignmentsFromJSON, JobAssignmentRecord, IJobAssignmentData } from "./JobAssignment";
import { Nullable, YesNoProp } from ".";
import { IEstimateOverviewData, EstimateOverviewRecord } from "./EstimateOverview";
import { UserRecord } from "./UserRecord";
import { fromJSON as documentFromJSON, IDocumentData, DocumentRecord } from "./Document";
import {
  IEstimateComparisonData,
  EstimateComparisonRecord,
  fromJSON as estimateComparisonFromJSON,
} from "./EstimateComparison";
import { IMeasurementData } from "app2/src/records/Measurement";

export const fromJSON = (json: Partial<IJobData>): JobRecord => {
  const record: IJobRecord = { ...(json as any) };

  if (json.address) {
    record.address = addressFromJSON(json.address);
  } else {
    record.address = addressFromJSON({});
  }

  if (json.customer_address) {
    record.customer_address = addressFromJSON(json.customer_address);
  } else {
    record.customer_address = addressFromJSON({});
  }

  if (json.phone_numbers) {
    record.phone_numbers = List<PhoneNumberRecord>(json.phone_numbers.map((pn) => phoneFromJSON(pn)));
  } else {
    record.phone_numbers = List<PhoneNumberRecord>();
  }

  if (json.assignments) {
    record.assignments = List<JobAssignmentRecord>(json.assignments.map((asgn) => assignmentsFromJSON(asgn)));
  } else {
    record.assignments = List<JobAssignmentRecord>();
  }

  if (json.images) {
    record.images = List<ImageRecord>(json.images.map((i) => imageFromJSON(i)));
  } else {
    record.images = List<ImageRecord>();
  }

  if (json.documents) {
    record.documents = List<DocumentRecord>(json.documents.map((i) => documentFromJSON(i)));
  } else {
    record.documents = List<DocumentRecord>();
  }

  if (json.estimates) {
    record.estimates = List<EstimateOverviewRecord>(json.estimates.map((i) => overviewFromJSON(i)));
  } else {
    record.estimates = List<EstimateOverviewRecord>();
  }

  if (json.inspections) {
    record.inspections = List<EstimateOverviewRecord>(json.inspections.map((i) => overviewFromJSON(i)));
  } else {
    record.estimates = List<EstimateOverviewRecord>();
  }

  if (json.estimate_comparison) {
    record.estimate_comparison = estimateComparisonFromJSON(json.estimate_comparison);
  } else {
    record.estimate_comparison = estimateComparisonFromJSON({});
  }

  if (json.measurement) {
    record.measurement_id = json.measurement.id;
  }

  if (json.config) {
    record.config = fromJS(json.config);
  }

  if (json.metadata) {
    record.metadata = fromJS(json.metadata);
  }

  return new JobRecord(record);
};

export const toJSON = (job: JobRecord): IJobPostData => {
  const json: IJobPostData = job.toJSON() as any as IJobPostData;

  json.address_attributes = addrToJSON(job.address);
  //@ts-ignore
  delete json.address;
  json.customer_address_attributes = addrToJSON(job.customer_address);
  //@ts-ignore
  delete json.customer_address;
  json.phone_numbers_attributes = job.phone_numbers.map((pn) => phoneToJSON(pn)).toArray();
  //@ts-ignore
  delete json.phone_numbers;
  json.config = job.config.toJS();

  //@ts-ignore
  delete json.images;
  //@ts-ignore
  delete json.documents;
  //@ts-ignore
  delete json.estimate_count;
  //@ts-ignore
  delete json.loading;
  //@ts-ignore
  delete json.created_at;
  //@ts-ignore
  delete json.updated_at;
  //@ts-ignore
  delete json.estimates;
  //@ts-ignore
  delete json.estimate_comparison;

  return json;
};

export const currentlyAssignedUser = (job: JobRecord): Nullable<UserRecord> => {
  if (job.assignments.count() <= 0) {
    return null;
  }

  return job.assignments.first(new JobAssignmentRecord()).get("user");
};

export const fetchCustomerAddress = (job: JobRecord): AddressRecord => {
  if (job.use_job_address === "yes") {
    return job.address;
  }

  return job.customer_address;
};

export const hasIntegration = (job: JobRecord, integrationKey: string): boolean => {
  const metadata = job?.metadata?.toJS();
  if (!metadata) return false;
  return [0, 1, 2, 3, 4].some((idx) => {
    return (
      metadata["integration_key_" + idx]?.includes(integrationKey) && metadata["integration_value_" + idx] !== undefined
    );
  });
};

interface IJobsPageData {
  jobs: List<number>;
  errors: List<string>;
  loading: boolean;
}

export interface IJobStateEventInfo {
  from: string | Array<string>;
  to: string;
  on: string;
}

export interface IJobStateInfo {
  pretty_name: string;
  color: string;
}

export type IJobsPageRecord = Record<IJobsPageData>;

export const JobsPageRecord: Record.Factory<IJobsPageData> = Record<IJobsPageData>({
  jobs: List<number>(),
  errors: List<string>(),
  loading: false,
});

export interface IJobPostData {
  id: number;
  uid: string;
  org_id: number;
  name?: string;
  customer_name?: string;
  email: string;
  state: string;
  raw_state: string;
  lead_source: string;
  config: any;
  use_job_address: YesNoProp;
  address_attributes?: IAddressData;
  customer_address_attributes?: IAddressData;
  phone_numbers_attributes?: IPhoneNumberData[];
  cover_image_uuid?: string;
}

export interface IJobData {
  id: number;
  uid: string;
  org_id: number;
  org_uid: string;
  name?: string;
  customer_name?: string;
  email?: string;
  state?: string;
  config: any;
  raw_state?: string;
  lead_source?: string;
  loading?: boolean;
  address?: IAddressData;
  customer_address?: IAddressData;
  phone_numbers?: IPhoneNumberData[];
  cover_image_uuid?: string;
  estimate_count?: number;
  estimates?: IEstimateOverviewData[];
  inspections?: IEstimateOverviewData[];
  measurement: IMeasurementData;
  archived: boolean;
  viewed: boolean;
  document_count: number;
  image_count: number;
  use_job_address: YesNoProp;

  images?: Array<IImageData>;
  documents?: Array<IDocumentData>;
  assignments?: Array<IJobAssignmentData>;
  estimate_comparison: IEstimateComparisonData;
  additional_info: string;
  metadata: any;

  created_at: Date;
  updated_at: Date;
}

export interface IJobRecord {
  id: number;
  uid: string;
  name: Nullable<string>;
  org_id: number;
  org_uid: string;
  customer_name: Nullable<string>;
  state: Nullable<string>;
  config: Map<any, any>;
  raw_state: Nullable<string>;
  lead_source: Nullable<string>;
  email: Nullable<string>;
  address: AddressRecord;
  customer_address: AddressRecord;
  cover_image_uuid: Nullable<string>;
  estimate_count: number;
  measurement_id: number;
  viewed: boolean;
  loading: boolean;
  archived: boolean;
  document_count: number;
  image_count: number;
  use_job_address: YesNoProp;

  phone_numbers: List<PhoneNumberRecord>;
  images: List<ImageRecord>;
  documents: List<DocumentRecord>;
  estimates: List<EstimateOverviewRecord>;
  inspections: List<EstimateOverviewRecord>;
  assignments: List<JobAssignmentRecord>;
  estimate_comparison: EstimateComparisonRecord;
  additional_info: string;
  metadata: Map<any, any>;

  created_at: Date;
  updated_at: Date;
}

export const defaultJobProps = {
  id: 0,
  uid: "",
  org_id: 0,
  org_uid: "",
  name: "",
  customer_name: "",
  state: "New",
  config: Map(),
  raw_state: "new",
  lead_source: "Manual",
  email: "",
  measurement_id: 0,
  viewed: false,
  loading: false,
  use_job_address: "yes" as YesNoProp,
  address: addressFromJSON({}),
  customer_address: addressFromJSON({}),
  cover_image_uuid: null,
  estimate_count: 0,
  archived: false,
  document_count: 0,
  image_count: 0,
  images: List<ImageRecord>(),
  documents: List<DocumentRecord>(),
  phone_numbers: List<PhoneNumberRecord>(),
  estimates: List<EstimateOverviewRecord>(),
  inspections: List<EstimateOverviewRecord>(),
  assignments: List<JobAssignmentRecord>(),
  estimate_comparison: new EstimateComparisonRecord(),
  additional_info: "",
  metadata: Map(),

  created_at: new Date(),
  updated_at: new Date(),
};

export class JobRecord extends Record<IJobRecord>(defaultJobProps) implements IJobRecord {}
