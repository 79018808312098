import * as React from "react";
import { LocalAudioTrack } from "twilio-video";
import useVideoContext from "app2/src/components/Twilio/hooks/useVideoContext/useVideoContext";
import AudioLevelIndicator from "./AudioLevelIndicator";

export default function LocalAudioLevelIndicator() {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;

  return <AudioLevelIndicator size={30} audioTrack={audioTrack} />;
}
