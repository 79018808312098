import * as React from "react";
import { Button, Modal } from "react-bootstrap";

export interface InfoModalProps {
  show: boolean;
  cancel: () => void;
  confirm: () => void;
}

export const InfoModal: React.FC<InfoModalProps> = ({ show, cancel, confirm }: InfoModalProps) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <h3>You have selected Measurement Links or Estimate Templates</h3>
      </Modal.Header>
      <Modal.Body>
        When duplicating measurement links and estimate templates it is assumed you have "identical" UUID values for
        products in the price list.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={confirm}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
};
