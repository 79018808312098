import { baseCleanProps } from "app2/src/api/Api";
import { ITaskData, TaskRecord } from "app2/src/records/Task";
import { fetcher } from "../helpers/Fetcher";
import { IJobData, JobRecord } from "../records/Job";

export interface IJobOptions {
  org_id: number;
  id?: number;
}

export interface IJobIndexResponse {
  jobs: IJobData[];
}

export interface IJobResponse {
  job: IJobData;
}

export interface IJobIndexOptions {
  org_id: number;
}

export interface IInviteArgs {
  id?: number;
  options?: any;
  emails: string;
  invite_method: "sms" | "email";
  invite_type: "image_uploader" | "screenshare";
}

export const includes = [
  "address",
  "customer_address",
  "assignments",
  "notes",
  "state_log",
  "job_states",
  "appointments",
  "org",
  "settings",
  "lead_sources",
  "job_types",
  "presentation_count",
  "visualizations",
  "preferences",
  "reports",
  "lead",
  "weather_property",
  "estimate_comparison",
];

export const url = "api/v1/orgs/:org_id/jobs";
export const jobUrl = "api/v1/jobs";

export const load = async (options: IJobOptions): Promise<IJobResponse> => {
  const { org_id, id } = options;
  const actionUrl = fetcher.joinUrls(url.replace(":org_id", org_id.toString()), id.toString());
  return await fetcher.get<IJobResponse>(actionUrl);
};

export const update = async (job: JobRecord): Promise<IJobResponse> => {
  const actionUrl = fetcher.joinUrls(jobUrl, job.id.toString());
  return fetcher.patch<IJobResponse>(actionUrl, { job: cleanProps(job), include: includes });
};

export const archive = async (options: IJobOptions): Promise<void> => {
  const { org_id, id } = options;
  const actionUrl = fetcher.joinUrls(url.replace(":org_id", org_id.toString()), id.toString());
  await fetcher.delete<void>(actionUrl);
};

export const unarchive = async (options: IJobOptions): Promise<void> => {
  const { org_id, id } = options;
  const actionUrl = fetcher.joinUrls(url.replace(":org_id", org_id.toString()), id.toString(), "unarchive");
  await fetcher.patch<void>(actionUrl);
};

export const view = async (orgId: number, jobId: number): Promise<any> => {
  const actionUrl = fetcher.joinUrls(url.replace(":org_id", orgId.toString()), jobId.toString(), "view");
  const response = await fetcher.post(actionUrl);
  return response;
};

export const importJobs = async (orgId: number, jobCsvData: string) => {
  const actionUrl = fetcher.joinUrls(url.replace(":org_id", orgId.toString()), "import");
  const response = await fetcher.post(actionUrl, { file: jobCsvData });
  return response;
};

export const invite = async (args: IInviteArgs): Promise<{ task: TaskRecord }> => {
  const actionUrl = fetcher.joinUrls(jobUrl, args.id.toString(), "invite");

  return await fetcher.post(actionUrl, args);
};

export const cleanProps = (job: JobRecord): IJobData => {
  let jobParams = job.toJS();
  jobParams = baseCleanProps(jobParams);

  return jobParams as any as IJobData;
};

export const loadSamples = async (org_id: number): Promise<{ sample_jobs: string[] }> => {
  const url = fetcher.joinUrls(jobUrl, "sample_list");
  return await fetcher.get(url, { org_id });
};

export const createSample = async (
  org_id: number,
  user_assigned_id: number,
  sample_types: string[],
): Promise<{ task: ITaskData }> => {
  const url = fetcher.joinUrls(jobUrl, "sample");
  return await fetcher.post(url, { org_id, user_assigned_id, sample_types });
};
