import * as React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { Can } from "../../Common/CanComponent";
import { settingsConfig } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: WellsFargoProps) => {
  const settings = settingsConfig(state, { orgId: ownProps.orgId });
  return {
    wellsFargoUrl: settings?.getIn(["visualization", "wells_fargo", "url"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: WellsFargoProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface WellsFargoProps {
  orgId: number;
  updateSettingsConfig: (path: any, value: any) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & WellsFargoProps;

class WellsFargo extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { wellsFargoUrl, updateSettingsConfig } = this.props;

    return (
      <Can resource="global" permission="tools">
        <div className="form-section">
          <div className="form-section-content">
            <Row>
              <Col sm={4} className="d-flex">
                <img
                  className="img-fluid align-self-center integration-logo"
                  src="/assets/images/wells_fargo.fd29b8e1.png"
                  title="Wells Fargo"
                />
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Merchant URL</Form.Label>
                  <Form.Control
                    type="text"
                    id="wellsfargo_url"
                    defaultValue={wellsFargoUrl}
                    onChange={(e) => updateSettingsConfig(["visualization", "wells_fargo", "url"], e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </Can>
    );
  }
}

export default connector(WellsFargo);
