import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Button, Modal, Form, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { ToolRecord, IToolData } from "app2/src/records/Tool";
import * as toolActions from "app2/src/reducers/org/tool.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import Files from "react-files";
import Card from "../Common/Card";
import { getUrl } from "app2/src/records/Image";
import Spinner from "app2/src/components/SpinnerComponent";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import _track, { Track } from "react-tracking";
import { Dispatch, TrackingData } from "app2/src/helpers/Analytics";

const mapStateToProps = (state: RootState, ownProps: ToolEditorModalProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ToolEditorModalProps) => {
  return {
    addTool: (tool: ToolRecord) => dispatch(toolActions.AsyncActions.addTool(tool)),
    updateTool: (tool: ToolRecord) => dispatch(toolActions.AsyncActions.updateTool(tool)),
    removeImage: (tool: ToolRecord) => dispatch(toolActions.AsyncActions.removeImage(tool)),
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface ToolEditorModalState {
  show: boolean;
  tool: ToolRecord;
}

export interface ToolEditorModalProps {
  show: boolean;
  tool: ToolRecord;
  onClose: (tool_id?: number) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ToolEditorModalProps;

const track: Track<TrackingData, Props> = _track;

@track(
  (props: Props) => {
    return {
      category: "ToolEditorModal",
      action: "show",
      tool: props.tool && props.tool.id ? props.tool.id : -1,
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
class ToolEditorModal extends React.Component<Props, ToolEditorModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: false,
      tool: props.tool,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFilesUpload = this.onFilesUpload.bind(this);
    this.onFilesError = this.onFilesError.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  public componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show && !this.state.show) {
      this.handleShow();
    }
  }

  @track({ action: "close" })
  public handleClose() {
    const { onClose } = this.props;
    this.setState({
      show: false,
      tool: this.props.tool,
    });
    if (onClose) {
      onClose();
    }
  }

  public handleInput(e: React.FormEvent<any>): void {
    //@ts-ignore
    const value = e.target.value;
    //@ts-ignore
    const name = e.target.id;

    this.setState({
      tool: this.state.tool.set(name, value),
    });
  }

  @track({ action: "show" })
  public handleShow() {
    this.setState({
      show: true,
      tool: this.props.tool,
    });
  }

  @track({ action: "removeImage" })
  public async removeImage() {
    const { addFlashMessage, tool } = this.props;

    return this.props
      .removeImage(tool)
      .then(() => {
        addFlashMessage(FlashLevels.success, "Image Successfully Removed.");
        this.handleShow();
      })
      .catch(() => {
        addFlashMessage(FlashLevels.danger, "There was an error removing the image. Please try again.");
      });
  }

  public async handleSave(e: React.FormEvent<any>) {
    e.preventDefault();
    e.stopPropagation();

    const { tool } = this.state;
    if (tool.id && tool.id > 0) {
      return this.updateTool(tool);
    } else {
      return this.addTool(tool);
    }
  }

  @track({ action: "updateTool" })
  public async updateTool(tool: ToolRecord) {
    const { addFlashMessage } = this.props;
    return this.props
      .updateTool(tool)
      .then(() => {
        addFlashMessage(FlashLevels.success, "Tool Successfully Updated.");
        this.handleClose();
      })
      .catch(() => {
        addFlashMessage(FlashLevels.danger, "There was an error updating the tool. Please try again.");
      });
  }

  @track({ action: "addTool" })
  public async addTool(tool: ToolRecord) {
    const { addFlashMessage, onClose, addTool } = this.props;
    this.setState({
      tool: tool.set("loading", true),
    });
    return addTool(tool)
      .then((new_tool) => {
        addFlashMessage(FlashLevels.success, "Tool Successfully Added.");
        this.setState({
          show: false,
          tool: this.props.tool,
        });
        if (onClose) {
          onClose(new_tool.id);
        }
      })
      .catch(() => {
        addFlashMessage(FlashLevels.danger, "There was an error adding the tool. Please try again.");
        this.setState({
          tool: tool.set("loading", false),
        });
      });
  }

  @track({ action: "addImage" })
  public onFilesUpload(files: any) {
    const { tool } = this.state;
    if (files.length > 0) {
      const brand_image = tool.brand_image.set("file_queue", files[0]);
      this.setState({
        tool: this.state.tool.set("brand_image", brand_image),
      });
    }
  }

  @track({ action: "addImageError" })
  public onFilesError(_error, _file) {
    const { addFlashMessage } = this.props;
    addFlashMessage(FlashLevels.danger, "There was an error uploading the image. Please try again.");
  }

  public render() {
    const { tool, show } = this.state;
    if (!tool) {
      return null;
    }
    const imageUrl = getUrl(tool.brand_image, "medium");

    return (
      <Modal size="lg" show={show} onHide={this.handleClose}>
        <Form onSubmit={this.handleSave}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Tool Editor</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Spinner localProperty={this.props.tool.loading || tool.loading}>
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl type="text" id="name" value={tool.name} onChange={this.handleInput} required></FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel title="Includes ability to add information from org and job via handlebars ex: {{job.email}}">
                  Link - Must start with https:// or http://
                </FormLabel>
                <FormControl
                  type="text"
                  id="link"
                  value={tool.link}
                  onChange={this.handleInput}
                  pattern="^(?:http(s)?:\/\/).+"
                  required></FormControl>
                <br />
                <p>Example Links: Add information from org and job to the link. ex: {"{{job.email}}"}</p>
                <p>https://www.google.com</p>
                <p>https://www.renoworkspro.com/designrequest?job_id={"{{job.id}}"}</p>
              </FormGroup>
              {tool.brand_image && tool.brand_image.id > 0 ? (
                <ConfirmDialog title={"Are you sure you want to delete the image?"} description={""}>
                  {(confirm) => (
                    <Button variant="delete" onClick={confirm(() => this.removeImage())}>
                      Remove Image
                    </Button>
                  )}
                </ConfirmDialog>
              ) : (
                <Files
                  className="files-dropzone"
                  onChange={this.onFilesUpload}
                  onError={this.onFilesError}
                  accepts={["image/*"]}
                  multiple={false}
                  maxFileSize={30000000}
                  minFileSize={0}
                  clickable>
                  <Button variant="default">Add Image</Button>
                </Files>
              )}
              <p>Tool Preview</p>
              <Card className="" title={tool.name} link={tool.link} imageUrl={imageUrl}></Card>
            </Spinner>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="cancel" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="save" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default connector(ToolEditorModal);
