import * as accountActions from "./account.actions";
import { fromJSON, AccountRecord } from "app2/src/records/billing/Account";
import { Map, Record, List } from "immutable";
import { RootState, RootActions } from "..";

export const AccountStateRecord = Record({
  byId: Map<number, AccountRecord>(),
  byOrgId: Map<number, number>(),
});

export const initialState = AccountStateRecord();
export type AccountState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  switch (action.type) {
    case accountActions.RECEIVE_ACCOUNT:
      const account = fromJSON({ ...action.payload.account, loading: false });

      return state
        .setIn(["billing", "accounts", "byId", account.id], account)
        .setIn(["billing", "accounts", "byOrgId", account.org_id], account.id);
    case accountActions.RECEIVE_ACCOUNT_ERROR:
      return state
        .setIn(["billing", "accounts", "byId", action.payload.id, "errors"], List<string>(action.payload.errors))
        .setIn(["billing", "accounts", "byId", action.payload.id, "loading"], false);
    case accountActions.FETCH_ACCOUNT:
      if (state.getIn(["billing", "accounts", "byId", action.payload.id])) {
        return state.setIn(["billing", "accounts", "byId", action.payload.id, "loading"], true);
      }
      return state.setIn(
        ["billing", "accounts", "byId", action.payload.id],
        fromJSON({ id: action.payload.id, loading: true }),
      );
    default:
      return state;
  }
};
