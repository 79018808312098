import * as skuActions from "./sku.actions";
import { SkuRecord, fromJSON } from "app2/src/records/Sku";
import { Map, Record } from "immutable";
import { RootActions, RootState } from "app2/src/reducers";
import { fetch, loaded, receive, reset } from "./Reducer";

export const SkuStateRecord = Record({
  byId: Map<number, SkuRecord>(),
  lastSavedById: Map<number, SkuRecord>(),
});

export const initialState = SkuStateRecord();
export type SkuState = typeof initialState;

const model = "skus";

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case skuActions.FETCH_SKU:
      return fetch(state, model, fromJSON({ id: action.payload.id }));

    case skuActions.RECEIVE_SKU:
      return receive(state, model, fromJSON(action.payload.sku));

    case skuActions.SET_LOADED:
      return loaded(state, model, action.payload.id);

    case skuActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      return state.setIn(rootPath.concat(name.split(".")), value);

    case skuActions.CLEAR_SKU:
      return reset(state, model, action.payload.id);

    case skuActions.UPDATE_VALUE:
      return state.setIn([model, "byId", action.payload.id, "value"], action.payload.newValue);

    default:
      return state;
  }
};
