import * as elevationActions from "./elevation.actions";
import { Map, Record, List } from "immutable";
import { ElevationRecord, fromJSON } from "../records/Elevation";
import { RootActions, RootState } from ".";
import { capitalize } from "humanize-plus";

export interface IElevationMeasurementRecord {
  ids: List<number>;
  loading: boolean;
}

export const defaultElevationMeasurementRecord = {
  ids: List(),
  loading: false,
};

export class ElevationMeasurementRecord
  extends Record<IElevationMeasurementRecord>(defaultElevationMeasurementRecord)
  implements IElevationMeasurementRecord {}

export const ElevationStateRecord = Record({
  byId: Map<number, ElevationRecord>(),
  byMeasurementId: Map<number, ElevationMeasurementRecord>(),
  lastSavedById: Map<number, ElevationRecord>(),
  // Map<doorId, elevationId>
  byDoorId: Map<number, number>(),
  // Map<windowId, elevationId>
  byWindowId: Map<number, number>(),
  unsavedId: -1,
});

export const initialState = ElevationStateRecord();

export type ElevationState = typeof initialState;
const model = "elevations";

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let elevation: ElevationRecord;
  switch (action.type) {
    // SINGLE
    case elevationActions.RECEIVE_ELEVATION:
      elevation = fromJSON({ ...action.payload.elevation });

      action.payload.elevation.annotations?.list.forEach((annotation) => {
        state = state.setIn([model, `by${capitalize(annotation.opening_type)}Id`, annotation.opening_id], elevation.id);
      });

      return state
        .setIn([model, "byId", elevation.id], elevation)
        .setIn([model, "lastSavedById", elevation.id], elevation);

    // MULTIPLE
    case elevationActions.FETCH_ELEVATIONS:
      const id = action.payload.measurementId;
      if (state.getIn([model, "byMeasurementId", id])) {
        return state.setIn([model, "byMeasurementId", id, "loading"], true);
      }
      return state.setIn([model, "byMeasurementId", id], new ElevationMeasurementRecord({ loading: true }));

    case elevationActions.RECEIVE_ELEVATIONS:
      action.payload.elevations.forEach((elevation) => {
        state = reducer(state, elevationActions.Actions.receiveElevation(elevation));
      });

      return state
        .setIn(
          [model, "byMeasurementId", action.payload.measurementId, "ids"],
          List(action.payload.elevations.map((e) => e.id)),
        )
        .setIn([model, "byMeasurementId", action.payload.measurementId, "loading"], false);

    case elevationActions.SET_ELEVATIONS_LOADED:
      return state.setIn([model, "byMeasurementId", action.payload.measurementId, "loading"], false);

    default:
      return state;
  }
};
