import * as moment from "moment";

class RefreshButtonController implements ng.IComponentController {
  [s: string]: any;
  public refresh: () => ng.IPromise<boolean>;
  public trigger: any;

  public countdown: number = null;
  public defaultTimer = 10;
  public currentTimer;
  public defaultTimerPeriod = 5;

  protected refreshing: moment.Moment;

  private _toggleState: any;

  public static $inject = ["$timeout"];
  constructor(protected $timeout: ng.ITimeoutService) {}

  public $onInit() {
    this._toggleState = this.trigger;
  }

  public $onChanges() {
    if (this._toggleState !== undefined && this._toggleState !== this.trigger) {
      this.startRefreshing();
      this._toggleState = this.trigger;
    }
  }

  public $doCheck(): void {}

  public $onDestroy(): void {}

  public $postLink(): void {}

  public startRefreshing() {
    if (!this.refreshing) {
      this.refreshing = moment();

      this.refresh().then((extend: boolean) => {
        if (this.refreshing) {
          if (extend) this.refreshing = moment();
        }

        this.countdown = this.defaultTimer;
        this.currentTimer = this.$timeout(() => this.counter(), 1000);
      });
    } else {
      this.countdown = this.defaultTimer;
      this.currentTimer = this.$timeout(() => this.counter(), 1000);
    }
  }

  public stopRefreshing() {
    if (this.currentTimer) {
      this.$timeout.cancel(this.currentTimer);
    }

    this.countdown = null;
    this.refreshing = null;
  }

  private counter() {
    this.countdown--;

    if (this.countdown <= 0) {
      this.refresh().then(() => {
        if (this.refreshing.clone().add(this.defaultTimerPeriod, "minutes").isAfter(moment())) {
          this.startRefreshing();
        } else {
          this.countdown = null;
          this.refreshing = null;
        }
      });
    } else {
      this.currentTimer = this.$timeout(() => this.counter(), 1000);
    }
  }
}

export class RefreshButtonComponent implements ng.IComponentOptions {
  public controller = RefreshButtonController;
  public templateUrl = "src/Common/Components/refresh_button.html";
  public bindings: any = {
    refresh: "&",
    trigger: "<",
  };
}
