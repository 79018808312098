import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Row, Col, Button } from "react-bootstrap";
import { is, List } from "immutable";
import { Can } from "app2/src/components/Common/CanComponent";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";
import Spinner from "app2/src/components/SpinnerComponent";
import * as financeOptionActions from "app2/src/reducers/org/financeOption.actions";
import { Editor } from "./components/Editor";
import Display from "./components/Display";
import { FinanceOptionRecord, IFinanceOptionData } from "app2/src/records/FinanceOption";
import { getOrgFinanceOptions } from "app2/src/selectors/financeOption.selectors";
import { createNew } from "app2/src/components/FinanceOption/List.service";
import { onSortEnd } from "app2/src/helpers/Record";
import { Sortable } from "app2/src/components/Common/Sortable";

const mapStateToProps = (state: RootState, ownProps: ListProps) => {
  const props: any = ownProps;
  props.orgId = ownProps.orgid;
  return {
    financeOptions: getOrgFinanceOptions(state, props),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ListProps) => {
  return {
    loadFinanceOptions: (orgId: number) => dispatch(financeOptionActions.AsyncActions.listFinanceOptions(orgId)),
    save: (financeOption: IFinanceOptionData) => {
      if (financeOption.id <= 0) {
        return dispatch(financeOptionActions.AsyncActions.addFinanceOption(parseInt(ownProps.orgid), financeOption));
      }

      return dispatch(financeOptionActions.AsyncActions.updateFinanceOption(financeOption));
    },
    batchUpdateFinanceOptions: (financeOptions: List<IFinanceOptionData>) => {
      return dispatch(financeOptionActions.AsyncActions.batchUpdateFinanceOptions(financeOptions));
    },
    archive: (financeOption: FinanceOptionRecord) => {
      return dispatch(financeOptionActions.AsyncActions.archiveFinanceOption(financeOption));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ListProps {
  $scope: ng.IScope;
  orgid: string;
  Flash: IFlash;
}

export interface ListState {
  editing: number;
  adding: boolean;
  financeOptions: List<FinanceOptionRecord>;
  spinner: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ListProps;

class ListComponent extends React.Component<Props, ListState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: null,
      adding: false,
      financeOptions: props.financeOptions,
      spinner: false,
    };

    this.addNew = this.addNew.bind(this);
    this.updateFinanceOption = this.updateFinanceOption.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.edit = this.edit.bind(this);
    this.archive = this.archive.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!is(this.props.financeOptions, prevProps.financeOptions)) {
    }
  }

  public async componentDidMount() {
    const { orgid, loadFinanceOptions } = this.props;

    await loadFinanceOptions(parseInt(orgid));

    this.setState({
      financeOptions: this.props.financeOptions,
    });
  }

  public addNew() {
    this.setState((prevState) => {
      const { newId, financeOptions } = createNew(prevState.financeOptions);
      return {
        financeOptions: financeOptions,
        editing: newId,
        adding: true,
      };
    });
  }

  public updateFinanceOption(financeOption: FinanceOptionRecord) {
    const { save, Flash, $scope } = this.props;
    this.setState({
      spinner: true,
    });
    save(financeOption).then(
      () => {
        $scope.$apply(() => Flash.addMessage(FlashLevels.success, "Finance Options successfully saved."));
        this.setState({
          financeOptions: this.props.financeOptions,
          editing: null,
          adding: false,
          spinner: false,
        });
      },
      () => {
        this.setState({
          spinner: false,
        });
        $scope.$apply(() => Flash.addMessage(FlashLevels.danger, "There were problems saving finance options."));
      },
    );
  }

  public edit(id: number) {
    if (this.state.editing) {
      this.cancelEditing();
    }
    this.setState({
      editing: id,
    });
  }

  public archive(id: number) {
    const { archive } = this.props;
    const { financeOptions } = this.state;

    const financeOption = financeOptions.find((d) => d.id === id);

    this.setState({
      spinner: true,
    });
    archive(financeOption).then(
      () => {
        this.setState({
          financeOptions: this.props.financeOptions,
          spinner: false,
        });
      },
      () => {
        this.setState({
          financeOptions: this.props.financeOptions,
          spinner: false,
        });
      },
    );
  }

  public cancelEditing() {
    this.setState((prevState) => {
      const { adding, editing } = prevState;
      let { financeOptions } = prevState;

      const nextState: Partial<ListState> = {
        editing: null,
        adding: false,
      };

      if (adding) {
        financeOptions = financeOptions.filter((fo) => fo.id !== editing);
        nextState.financeOptions = financeOptions;
      }

      return nextState as ListState;
    });
  }

  public async onDragEnd(oldIndex: number, newIndex: number) {
    const { batchUpdateFinanceOptions } = this.props;
    const { financeOptions } = this.state;

    const { list, dirtyIds } = onSortEnd(financeOptions, oldIndex, newIndex);
    this.setState({
      financeOptions: list,
      spinner: true,
    });

    const dirtyFinanceOptions = dirtyIds.map((id) => list.find((fo) => fo.id === id).toJSON());
    await batchUpdateFinanceOptions(dirtyFinanceOptions);
    this.setState({
      spinner: false,
    });
  }

  public render() {
    const { financeOptions, editing, spinner } = this.state;

    return (
      <>
        <Spinner localProperty={spinner} />
        <Can resource="finance_option" permission="read">
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <h1>Finance Options</h1>
                </Col>
                <Col md={6}>
                  <Can resource="finance_option" permission="create">
                    <Button variant="add" className="pull-right" onClick={this.addNew} disabled={!_.isNull(editing)}>
                      Add Finance Option
                    </Button>
                  </Can>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {this.renderHeader(financeOptions)}
              {this.renderBody(financeOptions, editing)}
            </Col>
          </Row>
        </Can>
      </>
    );
  }

  public reset() {
    this.setState({
      financeOptions: this.props.financeOptions,
    });

    return Promise.resolve(true) as any as ng.IPromise<boolean>;
  }

  public trackEvent(action: any, props: any): void {
    //console.info(action, props);
  }

  private renderHeader(financeOptions: List<FinanceOptionRecord>) {
    if (financeOptions.size > 0) {
      return (
        <Row>
          <Col md={12}>
            <div className="match-form-section">
              <Row className="match-table-header">
                <Col md={4}>Name / Status / URL</Col>
                <Col md={3}>Type</Col>
                <Col md={3}>Filters</Col>
                <Col md={2}></Col>
              </Row>
            </div>
          </Col>
        </Row>
      );
    }

    return <span></span>;
  }

  private renderBody(financeOptions, editing) {
    if (financeOptions.size <= 0) {
      return (
        <div className="form-section blank-state">
          <img src="/assets/images/icons-large/estimates.022a621b.png" />
          <h2>No Finance Options added. Let's add one.</h2>
          <p>Click on the add finance options button in the top right to begin.</p>
        </div>
      );
    }
    return (
      <Sortable
        dragHandle
        disableDrag={!_.isNull(editing)}
        items={financeOptions.map((fo) => fo.id)}
        onDragEnd={this.onDragEnd}
        renderItem={({ item, dragHandle }) => {
          const financeOption = financeOptions.find((fo) => fo.id === item);

          if (editing === financeOption.id) {
            return (
              <Editor financeOption={financeOption} update={this.updateFinanceOption} cancel={this.cancelEditing} />
            );
          }
          return (
            <Display financeOption={financeOption} edit={this.edit} archive={this.archive} dragHandle={dragHandle} />
          );
        }}
      />
    );
  }
}

export default connector(ListComponent);
