import { IMeasurement } from "app/src/Models/Measurement";
import { IAnnotation } from "./Annotation";
import { IOpeningEstimation } from "./OpeningEstimation";
import { IWindow } from "./Window";
import { IDoor } from "./Door";

export interface IOpeningMetadata extends IOpening {
  quantity: number;
}

export interface IOpening {
  id: number;
  name: string;
  /**
   * The assumption is that an designator would only be set by an importer
   * Anything with a designator will be dropped during imports
   */
  designator: string;
  location: string;
  level: string;
  height: number;
  width: number;
  area: number;
  ui: number;
  kind: string;
  sort_order: number;

  opening_estimations?: IOpeningEstimation[];

  annotation_id?: number;
  annotation?: IAnnotation;

  _destroy?: boolean;

  created_at: Date;
  updated_at: Date;
}

export abstract class Opening implements IOpening {
  public id: number;
  public name: string;
  public designator: string;
  public location: string;
  public level: string;
  public height: number;
  public width: number;
  public area: number;
  public ui: number;
  public kind: string;
  public sort_order: number;
  public created_at: Date;
  public updated_at: Date;

  public opening_estimations?: IOpeningEstimation[];

  constructor() {}

  public static addOpenings(measurement: IMeasurement, opening: IOpeningMetadata) {
    if (!opening.quantity || opening.quantity <= 1) {
      this._addOpenings(measurement, opening);
    } else {
      const type = opening.kind.toLowerCase() + "s";

      for (let i = 0; i < opening.quantity; i++) {
        let index = i + 1;
        while (_.any(measurement[type], (o: IOpening) => o.name === opening.name + "-" + index)) {
          index += 1;
        }
        const clone = JSON.parse(JSON.stringify(opening));
        clone.name = opening.name + "-" + index;
        this._addOpenings(measurement, clone);
      }
    }
  }

  public static copyValues(opening: IOpening, data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        switch (key) {
          case "id":
            data[key] = parseInt(data[key]);
            break;
          case "area":
          case "height":
          case "width":
          case "ui":
            data[key] = parseFloat(data[key]);
            break;
          case "updated_at":
          case "created_at":
            data[key] = new Date(data[key]);
            break;
        }

        opening[key] = data[key];
      }
    }
  }

  private static _addOpenings(measurement: IMeasurement, opening: IOpeningMetadata) {
    opening.area = _.round((opening.width * opening.height) / 144, -2);
    opening.ui = opening.width + opening.height;

    switch (opening.kind) {
      case "Door":
        if (opening.id) {
          const index = _.findIndex(measurement.doors, (d: IDoor) => {
            return d.id === opening.id;
          });
          measurement.doors[index] = opening as any as IDoor;
        } else {
          opening.id = measurement.getId();
          measurement.doors.push(opening as any as IDoor);
        }
        break;
      case "Window":
        if (opening.id) {
          const index = _.findIndex(measurement.windows, (w: IWindow) => {
            return w.id === opening.id;
          });
          measurement.windows[index] = opening as any as IWindow;
        } else {
          opening.id = measurement.getId();
          measurement.windows.push(opening as any as IWindow);
        }
        break;
    }
  }
}
