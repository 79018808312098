_.mixin(
  {
    parseFloat: function(value) {
      var result = parseFloat(value);
      if (_.isNaN(result)) {
        return 0;
      }
      return result;
    },
    toUnderscore: function(value) {
      return value.replace(/([A-Z])/g, "_$1").replace(/^_/, '').toLowerCase();
    },
    toTitleCase: function(value) {
      return value.replace(/([^A-Z])([A-Z])/g, '$1 $2') // split cameCase
        .replace(/[_\-]+/g, ' ') // split snake_case and lisp-case
        .toLowerCase()
        .replace(/(^\w|\b\w)/g, function(m) { return m.toUpperCase(); }) // title case words
        .replace(/\s+/g, ' ') // collapse repeated whitespace
        .replace(/^\s+|\s+$/, ''); // remove leading/trailing whitespace
    },
    round: function(value, exponent) {
      value = +value;
      exponent = +exponent;
      value = value.toString().split('e');
      value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] - exponent) : -exponent)));
      value = value.toString().split('e');
      return +(value[0] + 'e' + (value[1] ? (+value[1] + exponent) : exponent));
    },
    fileType: function(file) {
      var index = file.name.toLowerCase().indexOf(".pdf");
      var target = file.name.length - 4;
      if (index === target) {
        return "document";
      } else {
        return "image";
      }
    },
    dataURLToFile: function(dataurl, filename) {
      var arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    getSortOrder: function(array) {
      var firstSO = 0;

      var currentMax = _.chain(array)
        .pluck("sort_order")
        .max()
        .value();

      return _.max([firstSO, currentMax + 1]);
    },
    parseXml: function(xml, arrayTags) {
      var dom = null;
      if (window.DOMParser) {
        dom = (new DOMParser()).parseFromString(xml, "text/xml");
      }
      else if (window.ActiveXObject) {
        dom = new ActiveXObject('Microsoft.XMLDOM');
        dom.async = false;
        if (!dom.loadXML(xml)) {
          throw dom.parseError.reason + " " + dom.parseError.srcText;
        }
      } else {
        throw "cannot parse xml string!";
      }

      function isArray(o) {
        return Object.prototype.toString.apply(o) === '[object Array]';
      }

      function parseNode(xmlNode, result) {
        if (xmlNode.nodeName == "#text") {
          var v = xmlNode.nodeValue;
          if (v.trim()) {
            result['#text'] = v;
          }
          return;
        }

        var jsonNode = {};
        var existing = result[xmlNode.nodeName];
        if(existing) {
          if(!isArray(existing))
          {
            result[xmlNode.nodeName] = [existing, jsonNode];
          }
          else
          {
            result[xmlNode.nodeName].push(jsonNode);
          }
        } else {
          if(arrayTags && arrayTags.indexOf(xmlNode.nodeName) != -1)
          {
            result[xmlNode.nodeName] = [jsonNode];
          }
          else
          {
            result[xmlNode.nodeName] = jsonNode;
          }
        }

        if(xmlNode.attributes) {
          var length = xmlNode.attributes.length;
          for(var i = 0; i < length; i++) {
            var attribute = xmlNode.attributes[i];
            jsonNode[attribute.nodeName] = attribute.nodeValue;
          }
        }

        var length = xmlNode.childNodes.length;
        for(var i = 0; i < length; i++) {
          parseNode(xmlNode.childNodes[i], jsonNode);
        }
      }

      var result = {};
      if(dom.childNodes.length) {
        parseNode(dom.childNodes[0], result);
      }
      return result;
    },
    hiddenExtend: function(dst, src) {
      var keys = Object.getOwnPropertyNames(src);

      _.each(keys, function(key) {
        if (dst[key] || key === "constructor") {
          return;
        }

        dst[key] = src[key];
      })
    },
    arrayMove: function(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    },
    findIndexBy: function (array, iter) {
      for (var i = 0; i < array.length; i += 1) {
        if (iter(array[i])) {
          return i;
        }
      }
      return -1;
    },
    isNullOrUndefined: function(value) {
      return _.isNull(value) || _.isUndefined(value);
    }
  }
);
