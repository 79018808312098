import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IOrg } from "app/src/Models/Org";
import { IBaseConfig } from "../Common/IBaseConfig";

export interface IToken extends ng.resource.IResource<IToken>, TokenPrototype {}

export interface ITokenResource extends ng.resource.IResourceClass<IToken>, IRsfResource {
  create?(params: any): IToken;
  fromJSON?(data: any): IToken;
}

class TokenPrototype {
  public classy: string;
  public id: number;
  public org_id: number;
  public kind: string;
  public data: any;
  public updated_at: Date;
  public created_at: Date;

  public $saveOrCreate(params?, callback?): ng.IPromise<IOrg> {
    if (!this.id || this.id <= 0 || (this.id as any as string) === "new") {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$update(params, callback);
    }
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/tokens/:kind";

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta = angular.fromJson(response);

    _.each(meta.tokens, (et, index) => {
      meta.tokens[index] = Token.fromJSON(et);
    });

    return meta;
  };

  const transformSingle = (data) => {
    const jsonData = angular.fromJson(data);
    return Token.fromJSON(jsonData.token);
  };

  const requestTransform = (token: IToken): string => {
    const data = JSON.parse(angular.toJson(token));

    return angular.toJson({ token: data });
  };

  const Token: ITokenResource = <ITokenResource>$resource(
    url,
    { org_id: "@org_id", kind: "@kind" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        url: BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/tokens",
        transformResponse: transformSingle,
        transformRequest: requestTransform,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingle,
        transformRequest: requestTransform,
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultiple,
        isArray: false,
      },
    },
  );

  Token.fromJSON = (data: any) => {
    const token = new Token(data);
    token.classy = "Token";

    return token;
  };

  _.hiddenExtend(Token.prototype, TokenPrototype.prototype);

  Token.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Token;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
