import { Nullable } from "app2/src/records";
import { Record } from "immutable";

export interface IMetaCountlessPaginationData {
  current_page: number;
  next_page: Nullable<number>;
  prev_page: Nullable<number>;
  first_page: boolean;
  last_page: boolean;
}

export interface IMetaPaginationData {
  current_page: number;
  total_pages: number;
  total_count: number;
}

export interface IMetaPaginationRecord {
  current_page: number;
  total_pages: number;
  total_count: number;
}

const defaultMetaPaginationProps: IMetaPaginationRecord = {
  current_page: null,
  total_pages: null,
  total_count: null,
};

export class MetaPaginationRecord extends Record(defaultMetaPaginationProps) implements IMetaPaginationRecord {
  public readonly current_page!: number;
  public readonly total_pages: number;
  public readonly total_count: number;

  public constructor(values?: Partial<MetaPaginationRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
