import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import * as hoverActions from "app2/src/reducers/integrations/hover.actions";
import Spinner from "../../SpinnerComponent";
import * as config from "react-global-configuration";
import { Button } from "react-bootstrap";

const mapStateToProps = (state: RootState, ownProps: AuthCallbackProps) => {
  return {
    errors: state.getIn(["integrations", "hover", "errors"]),
    authorized: state.getIn(["integrations", "hover", "authorized"]),
    authorizing: state.getIn(["integrations", "hover", "authorizing"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: AuthCallbackProps) => {
  return {
    setAuthorizing: (v: boolean) => dispatch(hoverActions.Actions.setAuthorizing(v)),
    getAuthorization: (code: string) =>
      dispatch(hoverActions.AsyncActions.getAuthorization(code)) as any as Promise<boolean>,
    setErrors: (errors: string[]) => dispatch(hoverActions.Actions.setErrors(errors)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface AuthCallbackProps {
  $stateParams: { code: string };
  $state: ng.ui.IStateService;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & AuthCallbackProps;

export interface AuthCallbackState {
  triggered: boolean;
}

class AuthCallback extends React.Component<Props, AuthCallbackState> {
  constructor(props) {
    super(props);

    this.state = {
      triggered: false,
    };

    this.props.setAuthorizing(true);
  }

  public componentDidMount() {
    const { $stateParams, setErrors, getAuthorization, setAuthorizing } = this.props;

    if (!$stateParams.code) {
      setErrors(["Authorization Code not specified"]);
      setAuthorizing(false);
      return;
    }

    getAuthorization($stateParams.code);
    this.setState({ triggered: true });
  }

  public componentWillUnmount() {
    this.props.setAuthorizing(false);
    this.props.setErrors([]);
  }

  public render() {
    const { $state, authorized, errors } = this.props;
    const { triggered } = this.state;

    let message;
    if (triggered && authorized) {
      message = (
        <p className="text-center">
          Successfully Authorized. <br />
          <Button variant="info" onClick={() => $state.go("root.job_list")}>
            Go to Job List
          </Button>
        </p>
      );
    } else {
      message = <p className="text-center">Authorizing</p>;
    }

    let bodyElement = (
      <div className="text-center" style={{ margin: "auto", height: "75px" }}>
        <img style={{ height: "100%" }} src={config.get("APP_ICON_URL")} alt="HOVER" className="align-middle" />
        &nbsp;
        <i style={{ height: "100%" }} className="align-middle fa fa-arrows-h fa-5x"></i>
        &nbsp;
        <img
          style={{ height: "100%" }}
          src="/assets/images/icons/hover-logo.80a62fcf.svg"
          alt="HOVER"
          className="align-middle"
        />
        {message}
      </div>
    );

    if (errors.length > 0) {
      bodyElement = (
        <ul className="text-center">
          {_.map(errors, (error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul>
      );
    }

    return <Spinner stateProperty={["integrations", "hover", "authorizing"]}>{bodyElement}</Spinner>;
  }
}

export default connector(AuthCallback);
