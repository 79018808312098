import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { IImage, IImageResource } from "app/src/Models/Image";
import { IDirtyWatcher, IDirtyMerge } from "app/src/Common/DirtyWatcher";
import * as angulartics from "angulartics";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IOrg } from "app/src/Models/Org";
import { titleCaseString } from "app2/src/services/string.service";
import { useSelector } from "app2/src/storeRegistry";
import { currentAccessUid } from "app2/src/reducers/auth.selectors";

export class OrgInfoTabCtrl extends BaseOrgTabCtrl implements IDirtyMerge {
  public gafEnvironment = false;
  public authUser = useSelector(currentAccessUid);
  public static $inject = [
    "OrgFetcher",
    "Image",
    "Flash",
    "Upload",
    "BaseConfig",
    "US_STATES",
    "$stateParams",
    "$state",
    "DirtyWatcher",
    "$scope",
    "$analytics",
    "COUNTRIES",
    "Pretty",
  ];

  constructor(
    public OrgFetcher: IOrgFetcherService,
    private Image: IImageResource,
    private Flash: IFlash,
    private Upload: ng.angularFileUpload.IUploadService,
    public BaseConfig: IBaseConfig,
    public US_STATES: string[],
    public $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    public DirtyWatcher: IDirtyWatcher,
    public $scope: ng.IScope,
    protected $analytics: angulartics.IAnalyticsService,
    public COUNTRIES: string[],
    public Pretty: IPretty,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    if ($stateParams["id"] === "new") {
      this.$state.go("root.org_list");
    }

    this.gafEnvironment = BaseConfig.ENVIRONMENT === "gaf";
    DirtyWatcher.setup($scope, this);
  }

  public save() {
    this.spinnerPromise = this.OrgFetcher.save(this.org)
      .then((org: IOrg) => {
        this.org = org;
        if (this.$stateParams["id"] === "new") {
          this.$state.go("root.org_list");
        }
        this.trackEvent("save", {
          category: "OrgInfo",
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "Problems Saving Org!");
      });
  }

  public deleteImage(image: IImage) {
    this.spinnerPromise = this.Image.delete({
      id: image.id,
      imageable_id: image.imageable_id,
      imageable_type_url: "orgs",
    })
      .$promise.then(() => {
        this.Flash.addMessage(FlashLevels.success, "Logo deleted successfully.");
        this.org.images = _.filter(this.org.images, (i: IImage) => {
          return image.id !== i.id;
        });
        this.trackEvent("delete_logo", {
          category: "OrgInfo",
          logoId: image.id,
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems deleting the image");
      });
  }

  public titleCaseStringHelper(input: string): string {
    return titleCaseString(input);
  }

  public upload(file, invalidFiles) {
    if (!file) {
      if (invalidFiles.length > 0) {
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " + invalidFiles[0].name + ".  Only image file types are allowed.",
        );
      }
      return;
    }
    this.spinnerPromise = this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
      url: this.BaseConfig.BASE_URL + "/api/v1/orgs/" + this.org.id + "/images",
      data: { image: { file: file } },
    }).then(
      (resp: any) => {
        const img: IImage = this.Image.fromJSON(resp.data.image);
        this.org.images.push(img);
        this.Flash.addMessage(FlashLevels.success, "Logo added successfully.");
        this.trackEvent("upload_logo", {
          category: "OrgInfo",
        });
      },
      () => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems uploading the logo");
      },
    );
  }
}
