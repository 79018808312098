import { baseCleanProps } from "app2/src/api/Api";
import { fetcher } from "app2/src/helpers/Fetcher";
import { IMeasurementData, MeasurementHydratedRecord } from "app2/src/records/Measurement";

export interface IMeasurementResponse {
  measurement: IMeasurementData;
}

export const url = "api/v1/measurements";
export const measurementRelations = ["roof_faces", "wall_facades", "windows", "doors", "rooms", "decks", "pools"];

export const load = async (id: number, include: string[]): Promise<IMeasurementResponse> => {
  const actionUrl = fetcher.joinUrls(url, id.toString());
  return await fetcher.get<IMeasurementResponse>(actionUrl, { "include[]": include });
};

export const update = async (
  measurement: MeasurementHydratedRecord,
  include: string[],
): Promise<IMeasurementResponse> => {
  const actionUrl = fetcher.joinUrls(url, measurement.id.toString());
  const measurementData = cleanProps(measurement);
  return await fetcher.patch<IMeasurementResponse>(actionUrl, {
    measurement: measurementData,
    include,
  });
};

export const cleanProps = (measurement: MeasurementHydratedRecord): any => {
  let measurementData: any = { ...measurement.toJS() };
  measurementData = baseCleanProps(measurementData);

  measurementRelations.forEach((relation) => {
    if (measurementData[relation]) {
      measurementData[`${relation}_attributes`] = [];
      _.each(measurementData[relation], (data: any) => {
        const props = baseCleanProps(data);
        delete props.annotation;
        measurementData[`${relation}_attributes`].push(props);
      });
      delete measurement[relation];
    }
  });

  delete measurementData.levels;
  delete measurementData.locations;
  delete measurementData.elevations;
  delete measurementData.elevation_ids;

  return measurementData;
};
