export interface IPagingMetadata {
  total_count: number;
  total_pages: number;
  current_page: number;
}

export interface IQueryMetadata {
  pagination: IPagingMetadata;
}

export class PagingQueryMetadata implements IPagingQueryMetadata {
  public perPage = 10;
  public sortBy = "id";
  public sortOrder = "desc";
  public totalCount = 0;
  public currentPage = 0;
}
export interface IPagingQueryMetadata {
  currentPage: number;
  perPage: number;
  sortBy: string;
  sortOrder: string;
  totalCount: number;
}
