import * as React from "react";
import track from "react-tracking";
import { Button } from "react-bootstrap";

interface ClearWorkersButtonProps {}

interface ClearWorkersButtonState {}

@track(() => ({
  component: "ClearWorkersButton",
}))
export default class ClearWorkersButton extends React.Component<ClearWorkersButtonProps, ClearWorkersButtonState> {
  constructor(props: ClearWorkersButtonProps) {
    super(props);

    this.clearWorkers = this.clearWorkers.bind(this);
  }

  @track(() => ({
    action: "clear workers",
  }))
  public async clearWorkers(): Promise<void> {
    const registrations = await navigator.serviceWorker.getRegistrations();

    registrations.forEach((registration) => {
      registration.unregister();
    });

    const cacheNames = await caches.keys();
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName);
    });

    window.location.reload();
  }
  public render(): React.ReactNode {
    return (
      <Button variant="danger" onClick={this.clearWorkers}>
        Clear Workers And Reload
      </Button>
    );
  }
}
