import * as authActions from "./auth.actions";
import { Record } from "immutable";

export const AuthStateRecord = Record({
  token: null,
  refreshToken: null,
  authenticating: false,
  refreshTokenExpired: false,
  accessUid: null,
});

export const initialState = AuthStateRecord({ token: null, refreshToken: null });
export type AuthState = typeof initialState;

export const reducer = (state = initialState, action: authActions.Actions): AuthState => {
  switch (action.type) {
    case authActions.SET_TOKENS:
      return state.set("token", action.payload.token).set("refreshToken", action.payload.refreshToken);
    case authActions.SET_TOKEN:
      return state.set("token", action.payload);
    case authActions.SET_ACCESS_UID:
      return state.set("accessUid", action.payload.uid);
    case authActions.SET_AUTHENTICATING:
      return state.set("authenticating", action.payload);
    case authActions.SET_REFRESH_TOKEN_EXPIRED:
      return state.set("refreshTokenExpired", action.payload);
    default:
      return state;
  }
};
