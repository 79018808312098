import * as React from "react";
import useLocalVideoToggle from "app2/src/components/Twilio/hooks/useLocalVideoToggle/useLocalVideoToggle";
import { Button } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { useTracking } from "react-tracking";

export default function ToggleVideoButton(props: { disabled?: boolean }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { trackEvent } = useTracking();

  const toogleVideo = () => {
    const action = isVideoEnabled ? "hide video" : "show video";
    if (typeof toggleVideoEnabled === "function") {
      trackEvent({ action });
      toggleVideoEnabled();
    }
  };

  return (
    <Button
      // @ts-ignore
      onClick={toogleVideo}
      className="control-button fa-stack"
      disabled={props.disabled}>
      {isVideoEnabled ? (
        <FontAwesome name="video-camera" title="Hide Video" size="lg" />
      ) : (
        <FontAwesome name="video-camera" className="fa-rsf-slash" title="Show Video" size="lg" />
      )}
    </Button>
  );
}
