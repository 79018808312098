import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { Check } from "@tberrysoln/rsf-form";

export const Events: React.FC = () => {
  return (
    <>
      <Row>
        <Col md={6}>
          <div className="form-section-heading">
            <h3>Events to Send</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="form-section-heading">
            <h4>Job Events</h4>
          </div>
          <Row>
            <Col>
              <Check type="checkbox" label="Created" name="events.job.created" />
              <Check type="checkbox" label="Document Created" name="events.job.document_created" />
              <Check type="checkbox" label="Assigned" name="events.job.assigned" />
            </Col>
            <Col>
              <Check type="checkbox" label="Image Created" name="events.job.image_created" />
              <Check type="checkbox" label="Updated" name="events.job.updated" />
              <Check type="checkbox" label="Status Changed" name="events.job.status_changed" />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <div className="form-section-heading">
            <h4>Estimate Events</h4>
          </div>
          <Row>
            <Col>
              <Check type="checkbox" label="Created" name="events.estimate.created" />
              <Check type="checkbox" label="Manually Triggered" name="events.estimate.manually_triggered" />
            </Col>
            <Col>
              <Check type="checkbox" label="Updated" name="events.estimate.updated" />
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={6}>
          <div className="form-section-heading">
            <h4>Signable Document Events</h4>
          </div>
          <Check type="checkbox" label="Signed" name="events.signable_document.signed" />
        </Col>
        <Col md={6}>
          <div className="form-section-heading">
            <h4>Signed Document Events</h4>
          </div>
          <Check type="checkbox" label="Signed" name="events.signed_document.signed" />
        </Col>
      </Row>
    </>
  );
};
