import * as React from "react";
import { useState } from "react";
import { Table, Button, Col, Row } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import * as config from "react-global-configuration";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { IUser } from "app/src/Models/User";
import CrmUserMatchModal from "app2/src/components/Integrations/Common/CrmUserMatchModal";
import { List } from "immutable";
import { MetaPaginationRecord } from "app2/src/records/MetaPagination";
import { SearchBox } from "app2/src/components/Common/SearchBox";
import { ICrmUserQuery } from "../../Common/IntegrationList";

export interface CrmUserMatchTableProps {
  integrationType: string;
  integrationUsers: List<any>;
  occUsers: IUser[];
  pagination: MetaPaginationRecord;
  queryUsers: (queryObj: ICrmUserQuery) => void;
  saveUser: (crmUser: any) => void;
  sync: () => void;
}

export default function CrmUserMatchTable(props: CrmUserMatchTableProps) {
  if (!props.pagination) {
    return null;
  }
  const [modalOpen, setModalOpen] = useState(false);
  const [currentCrmUser, setCurrentCrmUser] = useState(null);

  const editUser = (crmUser: any) => {
    setCurrentCrmUser(crmUser);
    setModalOpen(true);
  };

  const pageChanged = (page: number) => {
    props.queryUsers({ page: page, query: "" });
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const handleSearchChange = (query: string) => {
    props.queryUsers({ query: query, page: 1 });
  };

  return (
    <div className="mt-3">
      <Row>
        <Col lg="8"></Col>
        <Col lg="4">
          <SearchBox
            query=""
            searching={handleSearchChange}
            placeholder={"Search..."}
            inputProps={{ autoFocus: false }}
          />
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th></th>
            <th>{props.integrationType} User</th>
            <th>{config.get("APP_NAME_SHORT")} User</th>
            <th>
              <Button onClick={props.sync}>Sync</Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.integrationUsers.map((crmUser: any) => {
            const occ_user = _.find(props.occUsers, (u) => u.id === crmUser.user_id);
            return (
              <tr key={crmUser.id}>
                <td>
                  {crmUser.user_id && crmUser.active ? <FontAwesome name="check" /> : <FontAwesome name="times" />}
                </td>
                <td>{`${crmUser.name} - ${crmUser.email}`}</td>
                <td>{occ_user && `${occ_user.name()} - ${occ_user.email}`}</td>
                <td>
                  <FontAwesome
                    name="pencil"
                    title="Edit CRM User"
                    className="rsf-base-link rsf-base-66"
                    onClick={() => editUser(crmUser)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <StandardPagination metadata={props.pagination} pageChanged={pageChanged} />
      {modalOpen && (
        <CrmUserMatchModal
          integrationType={props.integrationType}
          crmUser={currentCrmUser}
          occUsers={props.occUsers}
          open={modalOpen}
          hide={hideModal}
          save={props.saveUser}
        />
      )}
    </div>
  );
}
