import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import * as jobActions from "app2/src/reducers/job.actions";
import * as folderActions from "app2/src/reducers/folder.actions";
import * as documentActions from "app2/src/reducers/document.actions";
import { Row, Col } from "react-bootstrap";
import { Sortable } from "app2/src/components/Common/Sortable";
import Document from "app2/src/components/Document";
import TableHeader from "app2/src/components/JobTabs/DocumentTab/Documents/TableHeader";
import DocumentsTable from "app2/src/components/JobTabs/DocumentTab/ResourceDocuments/DocumentsTable";
import track from "react-tracking";
import Folders from "app2/src/components/Folders";
import { currentJobId, jobLoading, jobResourceIds } from "app2/src/selectors/job.selectors";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { replace } from "connected-react-router/immutable";
import { queryNumber } from "app2/src/selectors/router.selectors";
import { QueryParamsRecord } from "app2/src/records/Page";

const mapStateToProps = (state: RootState) => {
  return {
    queryFolderParentId: queryNumber(state, { queryKey: "folder_parent_id" }),
    resourceIds: jobResourceIds(state),
    jobLoading: jobLoading(state),
    orgId: currentOrgId(state),
    jobId: currentJobId(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: ResourceDocumentsProps) => {
  return {
    listFolders: (folderParentId: number, replace: () => void) =>
      dispatch(
        folderActions.AsyncActions.listFolders(folderParentId, { folderable_type: "Org", name: "Documents" }, replace),
      ),
    listDocuments: () =>
      dispatch(
        documentActions.AsyncActions.listDocuments(
          {
            documentableType: "org",
            display: "resource",
            folder_id: ownProps.folderParentId,
          },
          new QueryParamsRecord(),
        ),
      ),
    replace: (path: string) => dispatch(replace(path)),
    onSortEnd: (oldIndex: number, newIndex: number) => dispatch(jobActions.AsyncActions.onSortEnd(oldIndex, newIndex)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ResourceDocumentsProps {
  folderParentId: number;
  navigate: (folderId: number) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ResourceDocumentsProps;

@track(() => {
  return {
    component: "ResourceDocuments",
  };
})
class ResourceDocuments extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.redirect = this.redirect.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  public componentDidMount(): void {
    const { listFolders, queryFolderParentId } = this.props;
    listFolders(queryFolderParentId, this.redirect());
  }

  public componentDidUpdate(prevProps: Props): void {
    const { listFolders, listDocuments, queryFolderParentId, folderParentId } = this.props;

    if (prevProps.queryFolderParentId !== queryFolderParentId) {
      listFolders(queryFolderParentId, this.redirect());
    }

    if (!_.isNullOrUndefined(folderParentId) && prevProps.folderParentId !== folderParentId) {
      listDocuments();
    }
  }

  public navigate(folderId: number): void {
    const { navigate, listFolders } = this.props;
    navigate(folderId);
    listFolders(folderId, this.redirect());
  }

  public redirect(): () => any {
    const { jobId, replace } = this.props;
    const queryParams = new URLSearchParams({
      documentable_type: "org",
    });
    return () => replace(`/jobs/${jobId}/documents?${queryParams.toString()}`);
  }

  @track((props, _state, [oldIndex, newIndex]) => ({
    action: "onDragEnd",
    oldIndex: oldIndex,
    newIndex: newIndex,
    documents: props.resourceIds.toArray(),
  }))
  public onDragEnd(oldIndex: number, newIndex: number): void {
    const { onSortEnd } = this.props;

    onSortEnd(oldIndex, newIndex);
  }

  public render(): React.ReactNode {
    const { resourceIds, jobLoading } = this.props;

    if (jobLoading) {
      return <SpinnerComponent localProperty={true} />;
    }

    return (
      <Row>
        <Col>
          {resourceIds.size > 0 && (
            <>
              <h4>Included Resources</h4>
              <TableHeader documentableType="org" />
              <div className="standard-rl-margin">
                <Sortable
                  dragHandle
                  items={resourceIds}
                  onDragEnd={this.onDragEnd}
                  renderItem={({ item, dragHandle }) => {
                    return <Document documentId={item} documentableType="org" dragHandle={dragHandle} />;
                  }}
                />
              </div>
            </>
          )}
          <br />
          <h4>Available Resources</h4>
          <Folders {...this.props} documentableType="org" fileType="documents" readOnly navigate={this.navigate} />
          <br />
          <DocumentsTable {...this.props} />
          <br />
        </Col>
      </Row>
    );
  }
}

export default connector(ResourceDocuments);
