import * as angulartics from "angulartics";
import { IDateSource, IEventService } from "app/src/Calendar/EventService";
import { ISession } from "app/src/Common/SessionService";
import { IEvent } from "app/src/Models/Event";
import { IJob } from "app/src/Models/Job";
import { IOrg } from "app/src/Models/Org";
import { IUserResource, IUserResponse } from "app/src/Models/User";
import { BrowserDetection } from "app2/src/helpers/BrowserDetection";
import * as moment from "moment";
import { IBaseConfig } from "../Common/IBaseConfig";
import SchedulerAddEvent = kendo.ui.SchedulerAddEvent;
import SchedulerDataBoundEvent = kendo.ui.SchedulerDataBoundEvent;
import SchedulerEditEvent = kendo.ui.SchedulerEditEvent;
import SchedulerMoveStartEvent = kendo.ui.SchedulerMoveStartEvent;
import { useSelector } from "app2/src/storeRegistry";
import { currentAccessUid as accessUidSelector } from "app2/src/selectors/auth.selectors";

class AppointmentCreatorCtrl implements ng.IComponentController {
  /**
   * Due to KendoUI the _dateSource field has to be before the schedulerOptions field
   */
  /* eslint-disable @typescript-eslint/member-ordering */
  private _dateSource: IDateSource = {
    start: moment().startOf("month").subtract(1, "week"),
    end: moment().endOf("month").add(1, "week"),
  };

  public dataSource: kendo.data.DataSource;
  public appointmentWindow: kendo.ui.Window;
  public scheduler: kendo.ui.Scheduler;
  public job: IJob;
  public org: IOrg;
  public users: IUserResponse;
  public selectedUsers: number[];
  public modalInstance: ng.ui.bootstrap.IModalServiceInstance;

  public schedulerOptions = <kendo.ui.SchedulerOptions>{
    date: moment().toDate(),
    views: ["day", "workWeek", { type: "week", selected: true }, "month"],
    autoBind: false,
    allDaySlot: false,
    height: "60vh",
    width: "90vw",
    editable: { template: kendo.template(jQuery("#customEditorTemplate").html()) },
    timezone: "Etc/UTC",
    dataSource: this.buildDataSource(),
    add: (e: SchedulerAddEvent) => {
      const currentAccessUid = useSelector(accessUidSelector);
      let params = "";
      if (currentAccessUid) {
        const searchParams = new URLSearchParams();
        searchParams.set("authUser", currentAccessUid);

        params = `?${searchParams.toString()}`;
      }

      e.event.title = "Sales Call: " + this.job.name;
      e.event.summary = e.event.title;
      e.event.location = this.job.address.fullAddress();
      e.event.description = `Location:
${e.event.location}
${this.job.additional_info ? `\nAdditional Info:\n${this.job.additional_info}\n` : ""}
Phone Numbers:
${this.job.phone_numbers.map((pn) => `${pn.name}: ${pn.number}`).join("\n")}

Link: ${this.BaseConfig.APP_URL}/jobs/${this.job.id}${params}`;

      e.event.job_id = this.job.id;
      e.event.jobName = this.job.name;

      if (
        this.org.preferences.config.cronofy.invite_attendees === "always" ||
        this.org.preferences.config.cronofy.invite_attendees === "by_default"
      ) {
        e.event.inviteAttendee = true;
      }
      this.trackEvents("add appt");
    },
    edit: (e: SchedulerEditEvent) => {
      if ((e.event as any).isEditable === false) {
        e.preventDefault();
        return;
      }
      const validator = e.container.data("kendoValidator");

      delete validator.options.rules.dateCompare;
      _.extend(validator.options.rules, {
        greaterDate: function (input) {
          if (input.is("[data-greaterdate]") && input.val() !== "") {
            // @ts-ignore
            const startDate = kendo.parseDate($("[name='start']").val());
            // @ts-ignore
            const endDate = kendo.parseDate($("[name='end']").val());
            const theReturn = endDate == null || endDate.getTime() > startDate.getTime();
            if (!theReturn) {
              $("#date-errors").text("The End Date must be greater than the Start Date.");
              $(".k-scheduler-update").prop("disabled", true);
            } else {
              $("#date-errors").text("");
              $(".k-scheduler-update").prop("disabled", false);
            }
            return theReturn;
          }

          $("#date-errors").text("");
          $(".k-scheduler-update").prop("disabled", false);
          return true;
        },
        required: function (input) {
          if (input.is("[required]") && input.val().trim().length === 0) {
            return false;
          }

          return true;
        },
      });
      this.trackEvents("appt edit");
    },
    moveStart: (e: SchedulerMoveStartEvent) => {
      if ((e.event as any).isEditable === false) {
        e.preventDefault();
        return;
      }
    },
    dataBound: (event: SchedulerDataBoundEvent) => {
      this.$scope.$emit("appointment_creator:change", event);
    },
    navigate: (e) => {
      const dateSource = this.EventService.getPeriod(e.view, e.action, moment(e.date));
      this._dateSource.start = dateSource.start;
      this._dateSource.end = dateSource.end;
      this.dataSource.read();

      if (e.view === "week" || e.view === "workWeek" || e.view === "day") {
        this.$timeout(() => {
          this.scrollToCurrent();
        });
      }
    },
  };
  /* tslint:enable:member-ordering */

  constructor(
    public $scope: ng.IScope,
    public EventService: IEventService,
    public User: IUserResource,
    private Session: ISession,
    public BaseConfig: IBaseConfig,
    private $uibModal: ng.ui.bootstrap.IModalService,
    private $analytics: angulartics.IAnalyticsService,
    private $timeout: ng.ITimeoutService,
    private $q: ng.IQService,
  ) {
    this.schedulerOptions.mobile = BrowserDetection.isBrowserMobile();

    $scope.$on("appointment_creator:open", (event: ng.IAngularEvent, appointment: IEvent) => {
      this.selectedUsers = [this.Session.currentUser.id];
      this._dateSource.userIds = this.selectedUsers;
      this.modalInstance = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
        size: "fill",
        windowClass: "appointment-creator",
        templateUrl: "schedulerTemplate.html",
        scope: this.$scope,
      });

      this.modalInstance.closed.then(() => {
        this.$scope.$emit("appointment_creator:close", {});
      });

      this.$timeout(() => {
        $(window).trigger("resize");
        this.scrollToCurrent();
        if (appointment) {
          this.edit(appointment.id);
        }
      });
    });

    $scope.$on("appointment_creator:refresh", (id) => {
      this.dataSource.read();
    });

    Session.currentUser.$promise.then(() => {
      this.org = Session.currentUser.org;
    });

    this.schedulerOptions = _.extend(this.schedulerOptions, {
      save: this.trackEvents("appt saved"),
      remove: this.trackEvents("appt removed"),
      cancel: this.trackEvents("appt edit canceled"),
      changed: this.trackEvents("appt changed"),
      moveEnd: this.trackEvents("appt moved"),
      resizeEnd: this.trackEvents("appt resized"),
    });
  }

  public $onChanges() {
    if (this.job && !this.users) {
      this.job.$promise.then(() => {
        const promises: ng.IPromise<boolean>[] = [];

        promises.push(this.Session.can("read_self", "event", null));
        promises.push(this.Session.can("read_assigned", "event", null));

        this.$q.all(promises).then((results) => {
          if (
            !_.some(results, (result) => {
              return result;
            })
          ) {
            this.users = <IUserResponse>this.User.query({
              org_id: this.Session.currentUser.org_id,
              version: "v2",
            });
          }
        });
      });
    }
  }

  public edit(id: number) {
    let idx = 0;
    const found = _.find(this.scheduler.dataSource.data(), (e, index) => {
      idx = index;
      return e.id === id;
    });
    if (found) {
      const event = this.scheduler.dataSource.at(idx);
      // @ts-ignore
      this.scheduler.editEvent(event);
    }
  }

  public toggleStateFilter(id: number) {
    if (!_.include(this.selectedUsers, id)) {
      this.selectedUsers.push(id);
    } else {
      this.selectedUsers = _.without(this.selectedUsers, id);
    }

    this._dateSource.userIds = this.selectedUsers;
    this.dataSource.read();
  }

  public stateSelected(id: number) {
    return _.include(this.selectedUsers, id);
  }

  public getSchedulerOptions() {
    return this.schedulerOptions;
  }

  private buildDataSource() {
    this.dataSource = this.EventService.getSchedulerDatasource(this._dateSource, {});

    return this.dataSource;
  }

  private trackEvents(eventName: string) {
    return (e: any) => {
      if (e.event.isEditable === false) {
        e.preventDefault();
        return;
      }
      this.$analytics.eventTrack(eventName, {
        category: "Appointment",
        job: this.job.id,
        org: this.job.org_id,
        appointment: e.event.id,
      });
    };
  }

  private scrollToCurrent() {
    const time = new Date();
    time.setHours(time.getHours() + 1);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);

    const contentDiv = this.scheduler.element.find("div.k-scheduler-content");
    const rows = contentDiv.find("tr");

    for (let i = 0; i < rows.length; i++) {
      const slot = this.scheduler.slotByElement(rows[i]);

      const slotTime = kendo.toString(slot.startDate, "HH:mm");
      const targetTime = kendo.toString(time, "HH:mm");

      if (targetTime === slotTime) {
        // noinspection TypeScriptUnresolvedFunction
        (this.scheduler.view() as any)._scrollTo($(rows[i]).find("td:first")[0], contentDiv[0]);
      }
    }
  }
}

export class AppointmentCreator implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Calendar/appointment_creator.html";
  public bindings: any = {
    job: "<",
  };

  constructor() {
    this.controller = AppointmentCreatorCtrl;
    this.controller.$inject = [
      "$scope",
      "EventService",
      "User",
      "Session",
      "BaseConfig",
      "$uibModal",
      "$analytics",
      "$timeout",
      "$q",
    ];
  }
}
