import { RootState } from "app2/src/reducers";
import { org } from "app2/src/selectors/org.selectors";
import { List } from "immutable";
import * as React from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export interface ConfirmModalProps {
  show: boolean;
  cancel: () => void;
  confirm: () => void;
  dataTypes: List<string>;
  selectedOrgs: List<number>;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show,
  cancel,
  confirm,
  dataTypes,
  selectedOrgs,
}: ConfirmModalProps) => {
  const prettyNames = {
    discounts: "Discounts",
    job_types: "Job Types",
    documents: "Org Documents",
    presentations: "Presentations",
    payment_terms: "Payment Terms",
    finance_options: "Finance Options",
    measurement_links: "Measurement Links",
    estimate_templates: "Estimate Templates",
    validations: "Validations",
  };

  const orgRecords = useSelector((state: RootState) => selectedOrgs?.map((orgId) => org(state, { orgId })));

  return (
    <Modal show={show}>
      <Modal.Header>
        <h3>Are you sure you wish to duplicate the selected data?</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">Duplicating:</div>
        <ListGroup>
          {dataTypes?.map((dataType, index) => <ListGroup.Item key={index}>{prettyNames[dataType]}</ListGroup.Item>)}
        </ListGroup>
        <div className="my-3">To:</div>
        <ListGroup>
          {orgRecords?.map((org) => <ListGroup.Item>{`Org ${org.id}: ${org.name}`}</ListGroup.Item>)}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={confirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};
