import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { ICommand } from "app/src/Commands/Command";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import { ICustomProduct } from "app/src/Models/Product";
import { AddEstimateLineItemCmd } from "app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IFenetechQuoteFullResponse } from "app2/src/lib/fene_tech/types";

export class AddFeneTechOrderCmd implements ICommand {
  public hasLineItemPricing: boolean;

  constructor(
    public estimate: IEstimate,
    public order: IFenetechQuoteFullResponse,
    public group: IEstimateGroup,
    public fileQueue: IFileQueueFactory,
    public $http: ng.IHttpService,
    public spinnerPromise: ng.IPromise<any>,
    public $q: ng.IQService,
    public EstimatorService: EstimatorService,
  ) {}

  public execute(): boolean {
    const promises = [];
    const lineItems = this.order.lineItems;

    if (lineItems.length === 0) {
      return false;
    }

    lineItems.forEach((line) => {
      const subLineItemOptions = [];

      if (line.subLineItems.length) {
        subLineItemOptions.push({ newLine: true });

        line.subLineItems.forEach((subLineItem, index) => {
          const subLi = subLineItem.lineItem;
          subLineItemOptions.push({
            name: `${subLi.formattedPart} (${subLi.displayWidth}" X ${subLi.displayHeight}")\n`,
          });

          subLineItem.options.forEach((opt) => {
            subLineItemOptions.push(opt);
          });

          if (line.subLineItems.length !== index + 1) subLineItemOptions.push({ newLine: true });
        });
      }

      const newProduct = this.setProduct(line, subLineItemOptions);

      const cmd = new AddEstimateLineItemCmd(this.estimate, newProduct, this.group, this.EstimatorService);
      cmd.execute();
      cmd.lineItem.newly_added = false;

      if (line.imageBlob) {
        promises.push(this.fileQueue.getObject(line.imageBlob, cmd.lineItem));
      }
    });

    this.spinnerPromise = this.$q.all(promises).then(() => {
      this.fileQueue.setQueue();
    });

    return true;
  }

  private setProduct(lineItem, subLineItemOptions: any[]) {
    const setLineItem = lineItem.lineItem;
    const options = [...lineItem.options, ...subLineItemOptions];
    const newProduct = <ICustomProduct>{ uom: "ea", description: "", labor_price: 0 };
    newProduct.name = setLineItem.formattedPart;
    if (
      setLineItem.displayHeight &&
      setLineItem.displayWidth &&
      setLineItem.displayWidth > 0 &&
      setLineItem.displayHeight > 0
    ) {
      newProduct.name = `${newProduct.name} (${setLineItem.displayWidth}" X ${setLineItem.displayHeight}")`;
    }
    if (options && options.length > 0) {
      options.forEach((option) => {
        if (option.newLine) {
          newProduct.description += "\n";
        } else if (option.name) {
          newProduct.description += `${option.name}`;
        } else {
          newProduct.description += `<b>${option.optionGroup}</b>: ${option.description}${
            option.optionValue ? ` - ${option.optionValue}` : ""
          }\n`;
        }
      });
    }

    newProduct.quantity = setLineItem.quantity;
    newProduct.product_price = setLineItem.unitPrice;
    newProduct.price = newProduct.product_price * newProduct.labor_price;

    return newProduct;
  }
}
