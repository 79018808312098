import * as React from "react";
import { RootState } from "app2/src/reducers";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { TokenRecord } from "app2/src/records/Token";
import * as tokenActions from "app2/src/reducers/token.actions";
import Spinner from "app2/src/components/SpinnerComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, RsfForm } from "@tberrysoln/rsf-form";
import { required } from "../../../helpers/FinalFormValidator";
import { usePrevious } from "app2/src/hooks/usePrevious";
import { token, tokenChanged as tokenChangedSelector } from "app2/src/selectors/token.selectors";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";

export interface NetSuiteModalProps {
  triggerOpen: number;
  orgId: number;
  tokenUpdated: () => void;
}

export const NetSuiteModal: React.FC<NetSuiteModalProps> = ({ triggerOpen, orgId, tokenUpdated }) => {
  // Hooks
  const previousTriggerOpen = usePrevious(triggerOpen);
  const dispatch = useDispatch();

  // Local State
  const integration = "net_suite";
  const [open, setOpen] = useState(false);

  // Selectors
  const tokenRecord: TokenRecord = useSelector((state: RootState) => token(state, { kind: integration }));
  const tokenChanged = useSelector((state: RootState) => tokenChangedSelector(state, { kind: "net_suite" }));

  // Methods
  const hide = () => {
    setOpen(false);
    dispatch(tokenActions.Actions.resetToken(orgId, "net_suite"));
  };

  const resetForm = () => {
    dispatch(tokenActions.Actions.resetToken(orgId, "net_suite"));
  };

  const onSubmit = async () => {
    await dispatch(tokenActions.AsyncActions.updateCreateToken(tokenRecord));
    hide();
    setTimeout(tokenUpdated, 250);
  };

  // Life Cycle
  useEffect(() => {
    if (triggerOpen !== 0 && previousTriggerOpen !== triggerOpen) {
      setOpen(true);
      dispatch(tokenActions.AsyncActions.getToken(orgId, integration));
    }
  }, [triggerOpen]);

  return (
    <Modal show={open} onHide={hide} size="lg" backdrop="static">
      <Spinner localProperty={open && tokenRecord ? tokenRecord.loading : false} />
      <DirtyWatcher check={() => tokenChanged} reset={resetForm} />
      <Modal.Header closeButton>
        <Modal.Title>NetSuite Authorization</Modal.Title>
      </Modal.Header>
      <RsfForm
        rootPath={["tokens", "byOrgId", orgId, "net_suite"]}
        updateFormReducer={tokenActions.Actions.update}
        onSubmit={onSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <FormControl label="Job RESTlet URL" name="data.job_restlet_url" validate={required} />
              <FormControl label="oAuth Consumer Secret" name="data.oauth_consumer_secret" validate={required} />
              <FormControl label="oAuth Consumer Key" name="data.oauth_consumer_key" validate={required} />
              <FormControl label="Access Token" name="data.access_token" validate={required} />
              <FormControl label="Token Secret" name="data.token_secret" validate={required} />
              <FormControl label="Realm" name="data.realm" validate={required} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Authorize</Button>
          <Button className="btn-cancel" onClick={hide}>
            Cancel
          </Button>
        </Modal.Footer>
      </RsfForm>
    </Modal>
  );
};
