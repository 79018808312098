import { IEstimate } from "app/src/Models/Estimate";
import { IOrgBaseConfig } from "./Org";

export interface ISignatureSettings extends IOrgBaseConfig {
  signers: ISignerSettings[];
  recipients: IDocumentRecipient[];
  signature_platform: string;

  buildRecipients(estimate: IEstimate): IDocumentRecipient[];
}

export interface ISignerSettings {
  signer: string;
  title: string;
  role: string;
  required: boolean;
  pricing: boolean;
}

export interface ISigner {
  title: string;
  name: string;
  email: string;
  status: string;
  required: boolean;
  include: boolean;
  pricing: boolean;
}

export interface IDocumentRecipient {
  title: string;
  name: string;
  email: string;
  required: boolean;
  include: boolean;
}

export class SignatureSettings implements ISignatureSettings {
  public acl: string[] = [];
  public signers: ISignerSettings[];
  public recipients: IDocumentRecipient[];
  public signature_platform: string;
  constructor(data: any) {
    if (data.signers) {
      this.signers = data.signers.map((signer: ISignerSettings) => {
        if (!signer.role) signer.role = "";
        return signer;
      });
    } else {
      this.signers = [];
    }
    if (data.signature_platform) {
      this.signature_platform = data.signature_platform;
    }
  }

  public buildRecipients(estimate: IEstimate): ISigner[] {
    const signers: ISigner[] = [];
    _.each(this.signers, (signerConfig: any) => {
      const signer: ISigner = <ISigner>{};

      signer.title = signerConfig.title;
      signer.required = signerConfig.required;
      signer.pricing = signerConfig.pricing;
      switch (signerConfig.signer) {
        case "job":
          signer.name = estimate.job.customer_name;
          signer.email = estimate.job.email;
          break;
        case "salesrep":
          if (estimate.job.assignments && estimate.job.assignments.length > 0) {
            const assignee = estimate.job.assignments[0];
            // signer.name = assignee.user.first_name + " " + assignee.user.last_name;
            signer.name = assignee.user.name();
            signer.email = assignee.user.email;
          }
          break;
      }

      signer.status = "created";

      signers.push(signer);
    });

    this.recipients = signers;
    return signers;
  }

  public static fromJSON = (data: any): ISignatureSettings => {
    const signatureSettings = new SignatureSettings(data);
    return signatureSettings;
  };
}
