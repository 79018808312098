import { FlashLevels } from "app/src/Common/FlashLevels";
import { getRemoteStorage } from "app2/src/api/remoteStorage.service";
import { Nullable } from "app2/src/records";
import { JobRecord } from "app2/src/records/Job";
import { LocationStateRecord } from "app2/src/records/LocationState";
import { ThunkResult } from "app2/src/reducers/index";
import { ActionsUnion, createAction } from "../Utils";
import { RootDispatchType } from "app2/src/store";

export const FLASH_ADD_ALERT = "@components/common/FLASH_ADD_ALERT";
export const FLASH_REMOVE_ALERT = "@components/common/FLASH_REMOVE_ALERT";
export const GET_FLASH_MESSAGES = "@components/common/GET_FLASH_MESSAGES";
export const SET_JOB = "@components/common/SET_JOB";
export const OPEN_SEND_INVITE_MODAL = "@components/common/OPEN_SEND_INVITE_MODAL";
export const CLOSE_SEND_INVITE_MODAL = "@components/common/CLOSE_SEND_INVITE_MODAL";
export const OPEN_WEB_CAM_MODAL = "@components/common/OPEN_WEB_CAM_MODAL";
export const CLOSE_WEB_CAM_MODAL = "@components/common/CLOSE_WEB_CAM_MODAL";
export const NOTIFIER_ADD_MESSAGE = "@components/common/NOTIFIER_ADD_MESSAGE";
export const NOTIFIER_REMOVE_MESSAGE = "@components/common/NOTIFIER_REMOVE_MESSAGE";
export const OPEN_IMPORT_MODAL = "@components/common/OPEN_IMPORT_MODAL";
export const CLOSE_IMPORT_MODAL = "@components/common/CLOSE_IMPORT_MODAL";
export const SET_NAV_DISPLAY = "@components/common/SET_NAV_DISPLAY";
export const SET_SAVED_LOCATION_STATE = "@views/SET_SAVED_LOCATION_STATE";
export const SET_PAGE_TITLE = "@views/SET_PAGE_TITLE";

export const Actions = {
  flashAddAlert: (level: FlashLevels, message: string | Array<string>) =>
    createAction(FLASH_ADD_ALERT, { level, message }),
  flashRemoveAlert: (index: number) => createAction(FLASH_REMOVE_ALERT, index),
  getFlashMessages: () => createAction(GET_FLASH_MESSAGES),
  setJob: (job: JobRecord) => createAction(SET_JOB, job),
  openSendInviteModal: (type: "image_uploader" | "screenshare", options: any = {}) =>
    createAction(OPEN_SEND_INVITE_MODAL, { type, options }),
  closeSendInviteModal: (sent: boolean) => createAction(CLOSE_SEND_INVITE_MODAL, { sent }),
  openWebCamModal: () => createAction(OPEN_WEB_CAM_MODAL),
  closeWebCamModal: () => createAction(CLOSE_WEB_CAM_MODAL),
  notifierAddMessage: (msg: string) => createAction(NOTIFIER_ADD_MESSAGE, msg),
  notifierRemoveMessage: (index: number) => createAction(NOTIFIER_REMOVE_MESSAGE, index),
  openImportModal: () => createAction(OPEN_IMPORT_MODAL),
  closeImportModal: () => createAction(CLOSE_IMPORT_MODAL),
  setNavDisplay: (value: boolean) => createAction(SET_NAV_DISPLAY, { value }),
  setSavedLocationState: (location: Nullable<LocationStateRecord>) =>
    createAction(SET_SAVED_LOCATION_STATE, { location }),
  // Should only be called by PageTitleUpdater
  setPageTitle: (value: string) => createAction(SET_PAGE_TITLE, { value }),
};

export const AsyncActions = {
  notifierAddRemoteMessage: (msg: string): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      const success = await getRemoteStorage().setItem(NOTIFIER_ADD_MESSAGE, msg);
      if (!success) {
        dispatch(Actions.notifierAddMessage(msg));
      } else {
        setTimeout(() => {
          getRemoteStorage().removeItem(NOTIFIER_ADD_MESSAGE);
        }, 500);
      }
    };
  },
  // Any component should call this async action NOT the non-async one
  setPageTitle: (value: string): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      await Promise.resolve();
      dispatch(Actions.setPageTitle(value));
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
