import * as React from "react";
import { connectFC } from "app2/src/connect";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { OrgList } from "app2/src/components/Common/OrgList/index";
import { DataTypesList } from "./DataTypesList";
import { OrgCheckboxRow } from "./OrgCheckboxRow";
import { SelectOrgHeader } from "./SelectOrgHeader";
import { List } from "immutable";
import { InfoModal } from "./InfoModal";
import { ConfirmModal } from "./ConfirmModal";
import { Context, ContextRecord } from "./Context";
import { AsyncActions } from "app2/src/reducers/org.actions";
import { StoreRegistry } from "app2/src/storeRegistry";
import { Can } from "../../Common/CanComponent";

export const DuplicateDataTab: React.FC = () => {
  const dispatch = useDispatch();

  const [selectedDataTypes, setSelectedDataTypes] = React.useState<List<string>>(List());
  const [selectedOrgs, setSelectedOrgs] = React.useState<List<number>>(List());
  const [showInfoModal, setShowInfoModal] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showTasksButton, setShowTasksButton] = React.useState(false);

  const handleDataTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedDataTypes.includes(e.target.name)) {
      setSelectedDataTypes(selectedDataTypes.filter((dataType) => dataType !== e.target.name));
    } else {
      setSelectedDataTypes(selectedDataTypes.push(e.target.name));
    }
  };

  const handleOrgSelect = (orgId: number) => {
    if (selectedOrgs.includes(orgId)) {
      setSelectedOrgs(selectedOrgs.filter((id) => id !== orgId));
    } else {
      setSelectedOrgs(selectedOrgs.push(orgId));
    }
  };

  const duplicate = () => {
    if (selectedDataTypes.includes("estimate_templates") || selectedDataTypes.includes("measurement_links")) {
      setShowInfoModal(true);
    } else {
      setShowConfirmModal(true);
    }
  };

  const confirmDuplicate = () => {
    dispatch(AsyncActions.duplicateOrg(selectedDataTypes, selectedOrgs));
    setShowConfirmModal(false);
    setShowTasksButton(true);
  };

  const goToTasks = () => {
    const $state = StoreRegistry.get<ng.ui.IStateService>("$state");
    $state.go("root.tasks");
  };

  return (
    <Can resource="global" permission="org">
      <Context.Provider value={new ContextRecord({ selectedDataTypes, selectedOrgs })}>
        <Row className="pb-5">
          <Col>
            <Row>
              <Col>
                <Row className="justify-content-between align-items-center">
                  <h1 className="admin-title">Duplicate Data</h1>
                  {showTasksButton && <Button onClick={goToTasks}>Tasks</Button>}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={10}>
                <p>
                  Note: When duplicating measurement links and estimate templates it is assumed you have "identical"
                  UUID values for products in the price list
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="mb-4">
                <DataTypesList dataTypes={selectedDataTypes} handleChange={handleDataTypeChange} />
              </Col>
              <Col lg={8} className="mb-4">
                <OrgList
                  HeaderComponent={SelectOrgHeader}
                  RowComponent={OrgCheckboxRow}
                  rowClickHandler={handleOrgSelect}
                  headerTitle="Duplicate to"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={11} className="d-flex justify-content-end">
                <Button onClick={duplicate} disabled={selectedDataTypes.size === 0 || selectedOrgs.size === 0}>
                  Duplicate
                </Button>
              </Col>
            </Row>
            <InfoModal
              show={showInfoModal}
              cancel={() => setShowInfoModal(false)}
              confirm={() => {
                setShowInfoModal(false);
                setShowConfirmModal(true);
              }}
            />
            <ConfirmModal
              show={showConfirmModal}
              cancel={() => setShowConfirmModal(false)}
              confirm={() => confirmDuplicate()}
              dataTypes={selectedDataTypes}
              selectedOrgs={selectedOrgs}
            />
          </Col>
        </Row>
      </Context.Provider>
    </Can>
  );
};

export default connectFC(DuplicateDataTab);
