import * as React from "react";
import { Participant as IParticipant } from "twilio-video";
import ParticipantInfo from "app2/src/components/Twilio/components/ParticipantInfo";
import ParticipantTracks from "app2/src/components/Twilio/components/ParticipantTracks";

interface ParticipantProps {
  participant: IParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  onClick: () => void;
  isSelected: boolean;
  remoteParticipant: boolean;
}

export default function Participant({
  participant,
  disableAudio,
  enableScreenShare,
  onClick,
  isSelected,
  remoteParticipant,
}: ParticipantProps) {
  return (
    <ParticipantInfo
      participant={participant}
      onClick={onClick}
      remoteParticipant={remoteParticipant}
      isSelected={isSelected}>
      <ParticipantTracks
        participant={participant}
        disableAudio={disableAudio}
        enableScreenShare={enableScreenShare}
        isScreenShared={false}
      />
    </ParticipantInfo>
  );
}
