import * as React from "react";
import * as config from "react-global-configuration";
import { StoreRegistry } from "app2/src/storeRegistry";
import { IAuthService } from "app2/src/helpers/Auth.service";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState } from "app2/src/reducers";
import { RootDispatchType } from "app2/src/store";
import { push, replace } from "app2/src/reducers/router.actions";
import { ILocationData } from "app2/src/records/Location";
import { Nullable } from "app2/src/records";
import { lowesErrorMessage, lowesTokenData } from "./Authentication.service";
import { OidcView } from "./OidcView";
import { User, UserManager } from "oidc-client";

interface OidcLoginProps {
  $stateParams: ng.ui.IStateParamsService;
}

interface OidcLoginState {
  errorMsg: Nullable<string>;
  user: Nullable<User>;
}

const mapStateToProps = (state: RootState, ownProps: OidcLoginProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: OidcLoginProps) => {
  return {
    push: (location: Partial<ILocationData>) => dispatch(push(location)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OidcLoginProps;

class OidcLogin extends React.Component<Props, OidcLoginState> {
  public invalidRoutes: string[] = ["root.logout"];

  public constructor(props) {
    super(props);

    this.state = {
      errorMsg: null,
      user: null,
    };
  }

  public async componentDidMount() {
    const { $stateParams, push } = this.props;
    const authService = StoreRegistry.get<IAuthService>("authService");

    let goToRoute = config.get("DEFAULT_ROUTE");
    let goToParams = {};

    if (
      $stateParams.routeTo &&
      $stateParams.routeTo.name &&
      !_.include(this.invalidRoutes, $stateParams.routeTo.name)
    ) {
      goToRoute = $stateParams.routeTo.name;
      goToParams = $stateParams.routeParams;
    }

    try {
      const result = await (authService as any).oidcLogin({ goToRoute, goToParams });

      if (result) {
        push({ pathname: goToRoute, query: goToParams });
      }
    } catch (e) {
      const user = await ((authService as any).userManager as UserManager).getUser();
      this.setState({
        errorMsg: lowesErrorMessage(e),
        user,
      });
    }
  }

  public render() {
    const { errorMsg, user } = this.state;

    return <OidcView errorMsg={errorMsg} user={user} />;
  }
}

export default connector(OidcLogin);
