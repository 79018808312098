import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IPresentation, IPresentationResource } from "app/src/Models/Presentation";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { SortableOptions } from "app/src/Common/SortableOptions";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { ITask, Task } from "app/src/Models/Task";
import * as angulartics from "angulartics";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { IPretty } from "app/src/Common/PrettyNameService";

export class OrgPresentationTabCtrl extends BaseOrgTabCtrl {
  public sortableOptions = new SortableOptions();
  public selectedPresentation: string;
  public selectedPresentationKind = "default";
  public task: { IPresentation: ITask } = {} as { IPresentation: ITask };
  public focusHere = 0;
  public kindValues: string[] = ["default", "link"];
  public link: string;
  public presentations: IPresentation[];

  public static $inject = [
    "Pretty",
    "$uibModal",
    "$scope",
    "OrgFetcher",
    "Presentation",
    "Flash",
    "Upload",
    "BaseConfig",
    "$http",
    "$timeout",
    "$stateParams",
    "$analytics",
  ];

  constructor(
    public Pretty: IPretty,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $scope: ng.IScope,
    public OrgFetcher: IOrgFetcherService,
    public Presentation: IPresentationResource,
    public Flash: IFlash,
    public Upload: ng.angularFileUpload.IUploadService,
    public BaseConfig: IBaseConfig,
    public $http: ng.IHttpService,
    public $timeout: ng.ITimeoutService,
    public $stateParams: ng.ui.IStateParamsService,
    protected $analytics: angulartics.IAnalyticsService,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.sortableOptions.stop = () => {
      _.each(this.presentations, (p: IPresentation, index: number) => {
        if (p.sort_order !== index) {
          p.sort_order = index;
          p.$update();
        }
      });
      this.presentations = _.sortBy(this.presentations, "sort_order");
    };

    OrgFetcher.orgPromise.then(() => {
      return Presentation.query({
        org_id: $stateParams["id"],
      }).$promise.then((data: any) => {
        this.presentations = data.presentations;
      });
    });
  }

  public deletePresentation(presentation: IPresentation) {
    this.trackEvent("delete_presentation", {
      category: "OrgPresentation",
      presentationId: presentation.id,
    });
    this.spinnerPromise = <ng.IPromise<any>>presentation.$delete(() => {
      this.presentations = _.filter(this.presentations, (p: IPresentation) => {
        return presentation.id !== p.id;
      });
    });
    return this.spinnerPromise;
  }

  public btnDeletePresentation(presentation: IPresentation) {
    this.deletePresentation(presentation)
      .then(() => {
        this.Flash.addMessage(FlashLevels.success, "Presentation deleted successfully.");
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems deleting the presentation");
      });
  }

  public uploadPresentation(file, invalidFiles) {
    if (this.selectedPresentation === "") {
      this.Flash.addMessage(FlashLevels.warning, "Please choose a presentation type you would like to upload!");
      return;
    }
    if (!file) {
      if (invalidFiles && invalidFiles.length > 0) {
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " + invalidFiles[0].name + ".  Only file types allowed are: .pdf, .html.",
        );
      }
      return;
    }
    this.spinnerPromise = this.Upload.upload(<ng.angularFileUpload.IFileUploadConfigFile>{
      url: this.BaseConfig.BASE_URL + "/api/v1/orgs/" + this.org.id + "/presentations",
      data: { presentation: { name: this.selectedPresentation, file: file, kind: this.selectedPresentationKind } },
    }).then(
      (resp: any) => {
        if (resp.data) {
          this.trackEvent("upload_presentation", {
            category: "OrgPresentation",
          });
          this.Flash.addMessage(FlashLevels.success, "Presentation successfully uploaded.");
          this.selectedPresentation = "";
          this.selectedPresentationKind = "default";
          this.focusHere += 1;
          const presentation: IPresentation = this.Presentation.fromJSON(resp.data.presentation);
          this.presentations.push(presentation);
          this.presentations = _.sortBy(this.presentations, "sort_order");

          this.task[presentation.id] = Task.watch(
            this.$timeout,
            this.$http,
            resp.data.presentation.location,
            (task: ITask) => {
              if (task.status === "error") {
                this.deletePresentation(presentation);
                delete this.task[presentation.id];
                this.Flash.addMessage(FlashLevels.danger, "There was an error processing the presentation.");
              }

              if (task.status === "finished") {
                delete this.task[presentation.id];
                this.Flash.addMessage(FlashLevels.success, "Task successfully finished");
                presentation.$get();
              }
            },
          );
        }
      },
      () => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems processing your file on our servers.  Please try again.  If the problem persists contact support.",
        );
      },
    );
  }

  public uniqueName(form, name, id = -1) {
    if (name) {
      const name_found = _.any(this.presentations, (p: IPresentation) => {
        if (id !== p.id) {
          return p.name.toLowerCase() === name.toLowerCase();
        }
      });
      if (name_found) {
        form.$error.unique = "error";
        form.$setValidity("unique", false);
      } else {
        form.$error.unique = "";
        form.$setValidity("unique", true);
      }
    }
  }

  public savePresentationName(presentation: IPresentation) {
    presentation.$update().catch(() => {
      this.trackEvent("save_presentation_name", {
        category: "OrgPresentation",
        presentationId: presentation.id,
        presentationName: presentation.name,
      });
      this.Flash.addMessage(FlashLevels.danger, "There were problems saving the Presentation.");
    });
  }

  public openLinkModal() {
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        size: "lg",
        templateUrl: "src/Orgs/tabs/presentation_name_modal.html",
        scope: this.$scope,
      })
      .result.then(() => {
        const data = {
          kind: this.selectedPresentationKind,
          name: this.selectedPresentation,
          link: this.link,
          org_id: this.org.id,
        };
        const presentation = this.Presentation.fromJSON(data);

        presentation
          .$create()
          .then(() => {
            this.Flash.addMessage(FlashLevels.success, "Presentation successfully created.");
            this.presentations.push(presentation);
            this.presentations = _.sortBy(this.presentations, "sort_order");
            this.selectedPresentation = "";
            this.selectedPresentationKind = "default";
            this.focusHere += 1;
          })
          .catch(() => {
            this.Flash.addMessage(FlashLevels.danger, "There were problems creating the Presentation.");
          });
      });
  }

  public uploadCoverImage(file, invalidFiles, presentation) {
    if (!file) {
      if (invalidFiles && invalidFiles.length > 0) {
        this.Flash.addMessage(
          FlashLevels.warning,
          "Invalid file: " + invalidFiles[0].name + ".  Only image file types are allowed.",
        );
      }
      return;
    }
    this.spinnerPromise = this.Upload.base64DataUrl(file)
      .then((base64) => {
        presentation.cover_image = base64;
        return presentation.$cover_image().then(() => {
          this.trackEvent("upload_cover_image", {
            category: "OrgPresentation",
          });
          this.Flash.addMessage(FlashLevels.success, "Cover Image successfully uploaded.");
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "There were problems uploading the cover image");
      });
  }
}
