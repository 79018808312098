import { Record } from "immutable";

export const fromJSON = (json: Partial<ITaskData>): TaskRecord => {
  const data: Partial<ITaskData> = { ...(json as any) };
  return new TaskRecord(data);
};

export type TaskStatus = "submitted" | "processing" | "error" | "finished";

export interface ITaskContext {
  model?: string;
  action?: string;
  action_payload?: any;
  error_action?: string;
}

export interface ITaskData {
  id: string;
  job_id: string;
  lock_version: number;
  logs: string;
  location: string;
  results: any;
  status: TaskStatus;
  created_at: Date;
  updated_at: Date;
  context: ITaskContext;
}

export type ITaskRecord = ITaskData;

const defaultTaskProps = {
  id: "",
  job_id: null,
  lock_version: null,
  logs: "",
  location: "",
  results: {},
  status: "submitted",
  created_at: new Date(),
  updated_at: new Date(),
  context: { action: "taskHandler" } as ITaskContext,
};

export class TaskRecord extends Record(defaultTaskProps) implements ITaskRecord {
  public readonly id!: string;
  public readonly job_id!: string;
  public readonly lock_version!: number;
  public readonly logs!: string;
  public readonly location!: string;
  public readonly results!: any;
  public readonly status!: TaskStatus;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;
  public context!: ITaskContext;

  public constructor(values?: Partial<ITaskRecord>) {
    values ? super(values) : super();
  }
}
