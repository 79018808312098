import * as React from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import ButtonFooter from "../../Common/ButtonFooter";
import { useDispatch, useSelector } from "react-redux";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import { useTracking } from "react-tracking";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { selectedEstimateId } from "app2/src/selectors/job.selectors";

export const ManualTriggerModal: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { goBack } = usePreviousPath();
  const estimateId = useSelector(selectedEstimateId);
  const { trackEvent } = useTracking<any>({ category: "Estimate", estimate: estimateId });

  // Methods
  const close = () => {
    goBack("/manual_trigger");
  };

  const submit = () => {
    dispatch(estimateActions.AsyncActions.manuallyTrigger(estimateId));
    trackEvent({ action: "manual trigger" });
    close();
  };

  return (
    <Modal show={true} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Manual Trigger</Modal.Title>
      </Modal.Header>
      <ModalFooter>
        <ButtonFooter cancel={close} update={submit} saveText="OK" />
      </ModalFooter>
    </Modal>
  );
};
