import * as folderActions from "./folder.actions";
import { FolderRecord, fromJSON, IFolderData } from "app2/src/records/Folder";
import { Map, Record } from "immutable";
import { RootActions, RootState } from "app2/src/reducers";
import { reducer as paginationReducer } from "app2/src/reducers/pagination.reducer";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { fetch, receive, reset, loaded } from "app2/src/reducers/Reducer";

export const FolderStateRecord = Record({
  byId: Map<number, FolderRecord>(),
  lastSavedById: Map<number, FolderRecord>(),
  unsavedId: -1,
});

export const initialState = FolderStateRecord();
export type FolderState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "folders";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case folderActions.FETCH_FOLDER:
      return fetch(state, model, fromJSON({ id: action.payload.id }));

    case folderActions.RECEIVE_FOLDER:
      const folder = fromJSON({ ...action.payload.folder, loading: false });
      return receive(state, model, folder);

    case folderActions.RESET_FOLDER:
      return reset(state, model, action.payload.id);

    case folderActions.CREATE_FOLDER:
      const unsavedId = state.getIn([model, "unsavedId"]);
      state = reducer(
        state,
        folderActions.Actions.receiveFolder({
          id: unsavedId,
          parent_id: action.payload.folderParentId,
          createParams: action.payload.createParams,
        } as IFolderData),
      );
      state = paginationReducer(state, paginationActions.Actions.pushId(unsavedId, "folder"));

      return state.setIn([model, "unsavedId"], unsavedId - 1);

    case folderActions.EDIT_NAME:
      return state.setIn([model, "byId", action.payload.folderId, "name"], action.payload.name);

    case folderActions.EDIT_SORT_ORDER:
      return state.setIn([model, "byId", action.payload.folderId, "sort_order"], action.payload.sortOrder);

    case folderActions.SET_FOLDER_LOADED:
      return loaded(state, model, action.payload.id);

    case folderActions.ARCHIVE_FOLDER:
      state = paginationReducer(state, paginationActions.Actions.removeId(action.payload.id, "folder"));

      return state.setIn([model, "byId", action.payload.id, "archived"], true);

    case folderActions.RECEIVE_FOLDERS:
      action.payload.folders.forEach((folder) => {
        state = reducer(state, folderActions.Actions.receiveFolder(folder));
      });
      return state;

    default:
      return state;
  }
};
