import * as ng from "angular";
import { JobShowCtrl } from "./JobShowCtrl";
import { JobListCtrl } from "./JobListCtrl";
import { JobFetcherService } from "./JobFetcherService";
import { ToolTabCtrl } from "./tabs/ToolTabCtrl";
import { EstimateTabCtrl } from "./tabs/EstimateTabCtrl";
import { EstimatesTabCtrl } from "./tabs/EstimatesTabCtrl";
import { MeasurementTabCtrl } from "./tabs/MeasurementTabCtrl";
import { HistoryTabCtrl } from "./tabs/HistoryTabCtrl";
import { InfoTabCtrl } from "./tabs/InfoTabCtrl";
import { InspectionTabCtrl } from "app/src/Jobs/tabs/InspectionTabCtrl";
import { DesignToolTabCtrl } from "./tabs/DesignToolTabCtrl";
import { ProposalTabCtrl } from "./tabs/ProposalTabCtrl";
import { ContractTabCtrl } from "./tabs/ContractTabCtrl";
import { AreaCalculatorCtrl } from "app/src/Jobs/tabs/AreaCalculatorCtrl";
import { MeasurementEditorCtrl } from "./tabs/MeasurementEditorCtrl";
import { MeasurementEditorComponent } from "app/src/Jobs/MeasurementEditorComponent";
import { UploaderCtrl } from "app/src/Jobs/uploader/UploaderCtrl";
import { PropertyDataTabCtrl } from "./tabs/PropertyDataTabCtrl";
import { OrderCoreLogicCtrl } from "./tabs/OrderCoreLogicCtrl";
import { ImportMeasurementsCtrl } from "./tabs/ImportMeasurementsCtrl";
import { CoreLogicOrderModalCtrl } from "./tabs/CoreLogicOrderModalCtrl";
import { VisualizationModalCtrl } from "./tabs/VisualizationModalCtrl";
import { EmailModal } from "./tabs/EmailModal";
import { PinDropComponent } from "./Components/PinDropComponent";
import { ScreenShareService } from "app/src/Jobs/ScreenShareService";
import { ProductOptionSelectorComponent } from "app/src/ProductOptionSelector/ProductOptionSelectorComponent";
import { ImageUploadComponent } from "./Components/ImageUploadComponent";
import { RapidAreaComponent } from "app/src/RsfMap/RapidAreaComponent";
import { SatelliteMeasureComponent } from "app/src/RsfMap/SatelliteMeasureComponent";
import { DeckWizardComponent } from "app/src/RsfMap/DeckWizardComponent";
import { PoolToolComponent } from "app/src/RsfMap/PoolToolComponent";
import { LearnMoreCtrl } from "../Common/Ads/LearnMoreCtrl";
import { react2angular } from "react2angular";
import PresentationContainer from "app2/src/components/PricePresentation/PresentationContainer";
import PdfPageContainer from "app2/src/components/PdfPage/PdfPageContainer";
import FilterButton from "app2/src/components/JobList/FilterButton";
import Sidebar from "app2/src/components/JobList/Sidebar";
import DownloadDocumentButton from "app2/src/components/Document/DownloadDocumentButton";
import PricePresentationButton from "app2/src/components/JobTabs/EstimateTab/IngagePricePresentation/PricePresentationButton";
import ReportList from "app2/src/components/JobTabs/MeasurementTab/ReportList";
import InspectionContainer from "app2/src/components/JobTabs/InspectionTab/InspectionContainer";
import PresentationBuilderContainer from "app2/src/components/JobTabs/PresentationTab/Customer/PresentationBuilder/PresentationBuilderContainer";

export class JobRoutes {
  public static load(app: ng.IModule) {
    app.controller("JobListCtrl", JobListCtrl);
    app.controller("JobShowCtrl", JobShowCtrl);
    app.controller("UploaderCtrl", UploaderCtrl);
    app.controller("LearnMoreCtrl", LearnMoreCtrl);

    // Tab Ctrls
    app.controller("EstimateTabCtrl", EstimateTabCtrl);
    app.controller("EstimatesTabCtrl", EstimatesTabCtrl);
    app.controller("HistoryTabCtrl", HistoryTabCtrl);
    app.controller("InfoTabCtrl", InfoTabCtrl);
    app.controller("DesignToolTabCtrl", DesignToolTabCtrl);
    app.controller("MeasurementTabCtrl", MeasurementTabCtrl);
    app.controller("ToolTabCtrl", ToolTabCtrl);
    app.controller("ProposalTabCtrl", ProposalTabCtrl);
    app.controller("ContractTabCtrl", ContractTabCtrl);
    app.controller("InspectionTabCtrl", InspectionTabCtrl);

    app.controller("AreaCalculatorCtrl", AreaCalculatorCtrl);
    app.controller("MeasurementEditorCtrl", MeasurementEditorCtrl);
    app.controller("PropertyDataTabCtrl", PropertyDataTabCtrl);
    app.controller("OrderCoreLogicCtrl", OrderCoreLogicCtrl);
    app.controller("ImportMeasurementsCtrl", ImportMeasurementsCtrl);
    app.controller("CoreLogicOrderModalCtrl", CoreLogicOrderModalCtrl);
    app.controller("VisualizationModalCtrl", VisualizationModalCtrl);
    app.controller("EmailModal", EmailModal);

    app.component("measurementEditorComponent", new MeasurementEditorComponent());
    app.component("pinDrop", new PinDropComponent());
    app.component("jobImageUpload", new ImageUploadComponent());
    app.component("productOptionSelector", new ProductOptionSelectorComponent());
    app.component("rapidArea", new RapidAreaComponent());
    app.component("satelliteMeasure", new SatelliteMeasureComponent());
    app.component("deckWizard", new DeckWizardComponent());
    app.component("poolTool", new PoolToolComponent());
    app.component("pricePresentation", react2angular(PresentationContainer, ["estimateids", "orgid"], ["$state"]));
    app.component("pdfPage", react2angular(PdfPageContainer, ["estimateId", "docType"], []));
    app.component("filterButton", react2angular(FilterButton, null, ["$scope"]));
    app.component("sidebar", react2angular(Sidebar));
    app.component("downloadDocumentButton", react2angular(DownloadDocumentButton, ["documentId", "signed"]));
    app.component("pricePresentationButton", react2angular(PricePresentationButton, ["isEstimateDirty"]));
    app.component("reportList", react2angular(ReportList));

    app.service("JobFetcher", JobFetcherService);
    app.service("ScreenShareService", ScreenShareService);
    app.component(
      "inspectionContainer",
      react2angular(
        InspectionContainer,
        ["queue", "email", "download"],
        ["EstimatorService", "$scope", "DirtyWatcher", "$analytics", "JobFetcher"],
      ),
    );
    app.component(
      "presentationBuilder",
      react2angular(
        PresentationBuilderContainer,
        [],
        ["$state", "EstimatorService", "$scope", "DirtyWatcher", "$analytics"],
      ),
    );
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("job_header", <ng.ui.IState>{
        abstract: true,
        views: {
          header: {
            templateUrl: "src/Header/job_header.html",
            controller: "HeaderCtrl",
            controllerAs: "$ctrl",
          },
        },
      })
      .state("root.job_list", <ng.ui.IState>{
        url: "/jobs?status=",
        views: {
          "container@": {
            templateUrl: "src/Jobs/list.html",
            controller: "JobListCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show", <ng.ui.IState>{
        url: "/jobs/:id?embedded&header",
        abstract: true,
        views: {
          "container@": {
            templateUrl: "src/Jobs/show.html",
            controller: "JobShowCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.info", <ng.ui.IState>{
        url: "/info",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/info.html",
            controller: "InfoTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.property", <ng.ui.IState>{
        url: "/property",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/property.html",
            controller: "PropertyDataTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.estimate", <ng.ui.IState>{
        url: "/estimates/:estimate_id",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/estimate.html",
            controller: "EstimateTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.estimate.price_presentation", <ng.ui.IState>{
        url: "/price_presentation",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/estimate.html",
            controller: "EstimateTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.estimates", <ng.ui.IState>{
        url: "/estimates",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/estimates.html",
            controller: "EstimatesTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.measurements", <ng.ui.IState>{
        url: "/measurements/:path",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/measurements.html",
            controller: "MeasurementTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        params: {
          path: { type: "string", raw: true },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.history", <ng.ui.IState>{
        url: "/history",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/history.html",
            controller: "HistoryTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.design_tools", <ng.ui.IState>{
        url: "/design-tools",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/design_tools.html",
            controller: "DesignToolTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.signing", <ng.ui.IState>{
        url: "/:job_tab/signing/:signed_document_id",
        views: {
          "jobShow@job_header.job_show": {},
        },
      })
      .state("job_header.job_show.price", <ng.ui.IState>{
        url: "/price?estimateids&orgid",
        views: {
          "jobShow@job_header.job_show": {
            component: "pricePresentation",
          },
        },
        resolve: {
          ...resolveAuth,
          estimateids: [
            "$stateParams",
            ($stateParams) => {
              return $stateParams.estimateids;
            },
          ],
          orgid: [
            "$stateParams",
            ($stateParams) => {
              return $stateParams.orgid;
            },
          ],
        },
      })
      .state("upload_job_images", <ng.ui.IState>{
        url: "/jobs/upload/:key?token=?folder_id",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            templateUrl: "src/Jobs/uploader/uploader.html",
            controller: "UploaderCtrl",
            controllerAs: "$ctrl",
          },
        },
      })
      .state("pdf_viewer", <ng.ui.IState>{
        url: "/estimates/:estimateId/pdf/:docType?embedded&header",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            component: "pdfPage",
          },
        },
        resolve: {
          ...resolveAuth,
          estimateId: ["$stateParams", ($stateParams) => parseInt($stateParams.estimateId)],
          docType: ["$stateParams", ($stateParams) => $stateParams.docType],
        },
      })
      .state("job_header.job_show.inspection", <ng.ui.IState>{
        url: "/inspection",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/inspection.html",
            controller: "InspectionTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.react-job-tabs", <ng.ui.IState>{
        url: "/:path",
        params: {
          path: { type: "string", raw: true },
        },
        views: {
          "jobShow@job_header.job_show": {},
        },
      });
  }
}
