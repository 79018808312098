import { ThunkAction } from "redux-thunk";
import { IFolderCreateRecord, IFolderData } from "app2/src/records/Folder";
import { archive, list, byName, updateOrCreate, IFolderOptions } from "app2/src/api/folder.service";
import { Actions as PagingActions } from "app2/src/reducers/pagination.actions";
import { QueryParamsRecord, IPageData } from "app2/src/records/Page";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import * as documentActions from "app2/src/reducers/document.actions";
import * as presentationActions from "app2/src/reducers/presentation.actions";
import * as estimateTemplateActions from "app2/src/reducers/estimateTemplate.actions";
import * as imageActions from "app2/src/reducers/image.actions";
import { handleErrors } from "app2/src/reducers/Utils";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { List } from "immutable";
import { ActionsUnion, createAction } from "app2/src/reducers/Utils";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import { folder } from "app2/src/selectors/folder.selectors";
import { FileType } from "app2/src/components/Folders";
import { dirtyIds } from "app2/src/selectors/pagination.selectors";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";

export const FETCH_FOLDER = "@folders/FETCH_FOLDER";
export const FETCH_FOLDERS = "@folders/FETCH_FOLDERS";
export const RECEIVE_FOLDER = "@folders/RECEIVE_FOLDER";
export const SET_FOLDER_LOADED = "@folders/SET_FOLDER_LOADED";
export const ARCHIVE_FOLDER = "@folders/ARCHIVE_FOLDER";
export const RESET_FOLDER = "@folders/RESET_FOLDER";
export const RECEIVE_FOLDERS = "@folders/RECEIVE_FOLDERS";
export const EDIT_NAME = "@folders/EDIT_NAME";
export const EDIT_SORT_ORDER = "@folders/EDIT_SORT_ORDER";
export const CREATE_FOLDER = "@folders/CREATE_FOLDER";

export const Actions = {
  fetchFolder: (id: number) => createAction(FETCH_FOLDER, { id }),
  receiveFolder: (folder: IFolderData) => createAction(RECEIVE_FOLDER, { folder }),
  createFolder: (folderParentId: number, createParams: IFolderCreateRecord) =>
    createAction(CREATE_FOLDER, { folderParentId, createParams }),
  editName: (folderId: number, name: string) => createAction(EDIT_NAME, { folderId, name }),
  editSortOrder: (folderId: number, sortOrder: number) => createAction(EDIT_SORT_ORDER, { folderId, sortOrder }),
  setFolderLoaded: (id: number) => createAction(SET_FOLDER_LOADED, { id }),
  archiveFolder: (id: number) => createAction(ARCHIVE_FOLDER, { id }),
  resetFolder: (id: number) => createAction(RESET_FOLDER, { id }),
  receiveFolders: (folders: IFolderData[]) => createAction(RECEIVE_FOLDERS, { folders }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  listFolders: (
    folderParentId: number,
    options: Partial<IFolderOptions>,
    redirect = () => {},
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      let queryParams = new QueryParamsRecord();
      const mergedOptions = setOptionsFolderId(options, getState());
      if (_.isNullOrUndefined(folderParentId)) {
        await dispatch(AsyncActions.listFoldersByName(mergedOptions, queryParams));
        return;
      }

      if (folderParentId && folderParentId > 0) {
        queryParams = queryParams.set("folder_id", folderParentId);
        dispatch(PagingActions.fetchPage("folder", queryParams));

        try {
          const response = await list(folderParentId, queryParams);
          const rootFolder = response.parents[response.parents.length - 1];
          if (!folderableCheck(rootFolder, mergedOptions)) {
            redirect();
            return;
          }
          dispatch(Actions.receiveFolders(response.folders));
          dispatch(Actions.receiveFolders(response.parents));
          const pageData: Partial<IPageData> = {
            ids: List(response.folders.map((folder: IFolderData) => folder.id)),
          };
          dispatch(PagingActions.receivePage("folder", queryParams, pageData));
        } catch (response) {
          if (response.status === 404) {
            redirect();
            return;
          }
          const errors = handleErrors(response);
          dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
          dispatch(PagingActions.receivePageError("folder", queryParams, errors));
        }
      } else {
        redirect();
      }
    };
  },
  listFoldersByName: (options: IFolderOptions, queryParams: QueryParamsRecord): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(PagingActions.fetchPage("folder", queryParams));

      try {
        const response = await byName(options, queryParams);
        if (response) {
          dispatch(Actions.receiveFolders(response.folders));
          dispatch(Actions.receiveFolders(response.parents));
          const pageData: Partial<IPageData> = {
            ids: List(response.folders.map((folder: IFolderData) => folder.id)),
          };
          dispatch(PagingActions.receivePage("folder", queryParams.set("folder_id", response.parents[0].id), pageData));
        } else {
          dispatch(PagingActions.receivePageError("folder", queryParams, ["No content"]));
        }
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(PagingActions.receivePageError("folder", queryParams, errors));
      }
    };
  },
  archiveFolder: (folderId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchFolder(folderId));

      try {
        await archive(folderId);
        dispatch(Actions.archiveFolder(folderId));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.setFolderLoaded(folderId));
      }
    };
  },
  onSortEnd: (oldIndex: number, newIndex: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      await dispatch(paginationActions.AsyncActions.onSortEnd("folder", oldIndex, newIndex));

      dispatch(AsyncActions.batchUpdateOrCreateFolder(dirtyIds(getState())));
      dispatch(paginationActions.Actions.setDirtyIds(null));
    };
  },
  batchUpdateOrCreateFolder: (folderIds: List<number>): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const promises = [];

      folderIds.forEach((folderId) => {
        const updatedFolder = folder(getState(), { folderId });
        promises.push(updateOrCreate(updatedFolder));
      });

      try {
        const responses = await Promise.all(promises);
        const folders = responses.map((response) => response.folder);
        dispatch(Actions.receiveFolders(folders));
        folderIds.forEach((folderId, idx) => {
          if (folderId <= 0) {
            dispatch(paginationActions.Actions.replaceId(folderId, folders[idx].id, "folder"));
          }
        });
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved folders successfully"));
      } catch (response) {
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, ["Sorting Failed, please try again."]));
      }
    };
  },
  updateOrCreateFolder: (folderId: number): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState) => {
      dispatch(Actions.fetchFolder(folderId));
      const updatedFolder = folder(getState(), { folderId });

      try {
        const response = await updateOrCreate(updatedFolder);
        dispatch(Actions.receiveFolder(response.folder));
        if (folderId <= 0) {
          dispatch(paginationActions.Actions.replaceId(folderId, response.folder.id, "folder"));
        }

        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Saved folder successfully"));
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.setFolderLoaded(folderId));
      }
    };
  },
  moveFiles: (folderId: number, fileType: FileType): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType) => {
      switch (fileType) {
        case "documents":
          dispatch(documentActions.AsyncActions.moveDocuments(folderId));
          break;
        case "presentations":
          dispatch(presentationActions.AsyncActions.movePresentations(folderId));
          break;
        case "estimateTemplates":
          dispatch(estimateTemplateActions.AsyncActions.moveEstimateTemplates(folderId));
          break;
        case "images":
          dispatch(imageActions.AsyncActions.moveImages(folderId));
          break;
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;

export const setOptionsFolderId = (options: Partial<IFolderOptions>, state: RootState): IFolderOptions => {
  if (_.isNullOrUndefined(options.folderable_id)) {
    switch (options.folderable_type) {
      case "Job":
        options.folderable_id = currentJobId(state);
        break;
      case "Org":
        options.folderable_id = currentOrgId(state);
        break;
    }
  }

  return options as IFolderOptions;
};

export const folderableCheck = (rootFolder: IFolderData, options: IFolderOptions): boolean => {
  return rootFolder.folderable_type === options.folderable_type && rootFolder.folderable_id === options.folderable_id;
};
