import * as ng from "angular";
import User from "app/src/Models/User";
import UserPreference from "app/src/Models/UserPreference";
import Address from "app/src/Models/Address";
import Measurement from "app/src/Models/Measurement";
import Org from "app/src/Models/Org";
import Job from "app/src/Models/Job";
import Estimate from "app/src/Models/Estimate";
import PriceList from "app/src/Models/PriceList";
import ActivatedPriceList from "app/src/Models/ActivatedPriceList";
import Product from "app/src/Models/Product";
import PaymentTerm from "app/src/Models/PaymentTerm";
import EstimateTemplate from "app/src/Models/EstimateTemplate";
import MeasurementLink from "app/src/Models/MeasurementLink";
import Doc from "app/src/Models/Doc";
import Image from "app/src/Models/Image";
import Presentation from "app/src/Models/Presentation";
import Note from "app/src/Models/Note";
import Event from "app/src/Models/Event";
import Result from "app/src/Models/Result";
import ResultReason from "app/src/Models/ResultReason";
import ScreenShare from "app/src/Models/ScreenShare";
import SignedDocument from "app/src/Models/SignedDocument";
import SignedDocumentRecipient from "app/src/Models/SignedDocumentRecipient";
import ProductOptionGroup from "app/src/Models/ProductOptionGroup";
import ProductOption from "app/src/Models/ProductOption";
import Plan from "app/src/Billing/Models/Plan";
import Fee from "app/src/Billing/Models/Fee";
import Order from "app/src/Billing/Models/Order";
import Subscription from "app/src/Billing/Models/Subscription";
import Account from "app/src/Billing/Models/Account";
import Coupon from "app/src/Billing/Models/Coupon";
import InvoiceItem from "app/src/Billing/Models/InvoiceItem";
import Visualization from "app/src/Models/Visualization";
import Calendar from "app/src/Models/Calendar";
import Report from "app/src/Models/Report";
import Pattern from "app/src/Models/Pattern";
import PhoneNumber from "app/src/Models/PhoneNumber";
import Token from "app/src/Models/Token";
import SfUser from "app/src/Models/SfUser";
import I360User from "app/src/Models/I360User";
import MsUser from "app/src/Models/MsUser";
import JnUser from "app/src/Models/JnUser";
import SrUser from "app/src/Models/SrUser";
import LpUser from "app/src/Models/LpUser";
import Email from "app/src/Models/Email";
import Elevation from "app/src/Models/Elevation";

export class ModelRoutes {
  public static load(app: ng.IModule) {
    app.factory("User", User);
    app.factory("UserPreference", UserPreference);
    app.factory("Address", Address);
    app.factory("Doc", Doc);
    app.factory("Measurement", Measurement);
    app.factory("Org", Org);
    app.factory("Job", Job);
    app.factory("Product", Product);
    app.factory("Estimate", Estimate);
    app.factory("PaymentTerm", PaymentTerm);
    app.factory("EstimateTemplate", EstimateTemplate);
    app.factory("MeasurementLink", MeasurementLink);
    app.factory("Image", Image);
    app.factory("Presentation", Presentation);
    app.factory("Note", Note);
    app.factory("Event", Event);
    app.factory("Result", Result);
    app.factory("ResultReason", ResultReason);
    app.factory("ScreenShare", ScreenShare);
    app.factory("SignedDocumentRecipient", SignedDocumentRecipient);
    app.factory("SignedDocument", SignedDocument);
    app.factory("ProductOptionGroup", ProductOptionGroup);
    app.factory("ProductOption", ProductOption);
    app.factory("PriceList", PriceList);
    app.factory("ActivatedPriceList", ActivatedPriceList);
    app.factory("Plan", Plan);
    app.factory("Fee", Fee);
    app.factory("Subscription", Subscription);
    app.factory("Order", Order);
    app.factory("Account", Account);
    app.factory("InvoiceItem", InvoiceItem);
    app.factory("Coupon", Coupon);
    app.factory("Visualization", Visualization);
    app.factory("Calendar", Calendar);
    app.factory("Report", Report);
    app.factory("Pattern", Pattern);
    app.factory("PhoneNumber", PhoneNumber);
    app.factory("Token", Token);
    app.factory("SfUser", SfUser);
    app.factory("I360User", I360User);
    app.factory("MsUser", MsUser);
    app.factory("JnUser", JnUser);
    app.factory("SrUser", SrUser);
    app.factory("LpUser", LpUser);
    app.factory("Email", Email);
    app.factory("Elevation", Elevation);
  }

  public static configure(): void {}
}
