import { List, Record, Map, fromJS } from "immutable";

export const fromJSON = (json: Partial<ITokenData>): TokenRecord => {
  const record: ITokenRecord = { ...(json as any) };

  record.data = fromJSWithDefaults(json.kind, json.data);

  if (json.payload) {
    record.payload = fromJS(json.payload);
  }

  return new TokenRecord(record);
};

const fromJSWithDefaults = (kind: string, data: any): Map<string, any> => {
  data = data || {};
  switch (kind) {
    case "companycam":
      data.settings = data.settings || {};
      if (data.settings.create_jobs_from_cc_projects === undefined) {
        data.settings.create_jobs_from_cc_projects = true;
      }
      break;
    case "job_nimbus":
      if (data.jn_type === undefined) {
        data.jn_type = "job";
      }
      break;
    case "net_suite":
      // Front End Only
      data.events = { job: {}, estimate: {}, signed_document: {}, signable_document: {} };
      if (data.job) {
        data.job.forEach((j: string) => {
          data.events["job"][j] = true;
        });
      }
      if (data.estimate) {
        data.estimate.forEach((j: string) => {
          data.events["estimate"][j] = true;
        });
      }
      if (data.signed_document) {
        data.signed_document.forEach((j: string) => {
          data.events["signed_document"][j] = true;
        });
      }
      if (data.signable_document) {
        data.signable_document.forEach((j: string) => {
          data.events["signable_document"][j] = true;
        });
      }
      delete data.job;
      delete data.estimate;
      delete data.signed_document;
      delete data.signable_document;
      break;
    case "improveit360":
      data.create_prospect_sale = {
        appointment_resulted: data.create_prospect_sale?.appointment_resulted ?? true,
        signed_document_signed: data.create_prospect_sale?.signed_document_signed ?? true,
      };
      data.create_marketing_opportunity = data.create_marketing_opportunity ?? true;
      break;
    case "ingage":
      data.presentation_url = data.presentation_url || "";
      data.discount = data.discount || {
        name: "",
        description: "",
        amount: 0,
      };
      data.packages = data.packages || [
        {
          name: "",
          description: "",
          markup_factor: 0,
          markdown_factor: 0,
          default: true,
        },
      ];
      data.packages = List(
        data.packages.map((p) => ({
          name: p.name,
          description: p.description,
          markup_factor: p.markup_factor,
          markdown_factor: p.markdown_factor,
          default: p.default,
        })),
      );
      data.finance_options = data.finance_options || [
        {
          name: "",
          rate: 0,
          term: 0,
        },
      ];
      data.finance_options = List(
        data.finance_options.map((financeOption) => ({
          name: financeOption.name,
          rate: financeOption.rate,
          term: financeOption.term,
        })),
      );
      data.enabled = data.enabled || false;
      break;
  }
  return fromJS(data);
};

export type TokenKind =
  | "marketsharp"
  | "eagleview"
  | "salesforce"
  | "job_nimbus"
  | "hover"
  | "improveit360"
  | "sales_rabbit"
  | "paysimple"
  | "lead_perfection"
  | "card_connect"
  | "companycam"
  | "jobprogress"
  | "roof_scope"
  | "accu_lynx"
  | "fin_mkt"
  | "plnar"
  | "net_suite"
  | "renoworks"
  | "ingage"
  | "provia"
  | "fene_tech"
  | "green_sky";

export interface ITokenData {
  id: number;
  uuid: string;
  org_id: number;
  user_id: number;
  kind: TokenKind;
  data: any;
  payload: any;
  created_at: Date;
  updated_at: Date;
  loading?: boolean;
}

export interface ITokenRecord {
  id: number;
  uuid: string;
  org_id: number;
  user_id: number;
  kind: TokenKind;
  data: Map<string, any>;
  payload: Map<string, any>;

  created_at: Date;
  updated_at: Date;

  loading: boolean;
}

const defaultTokenProps: ITokenRecord = {
  id: 0,
  uuid: "",
  user_id: 0,
  org_id: 0,
  kind: "marketsharp",
  data: Map(),
  payload: Map(),
  created_at: new Date(),
  updated_at: new Date(),
  loading: false,
};

export class TokenRecord extends Record(defaultTokenProps) implements ITokenRecord {}
