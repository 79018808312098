import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as documentActions from "app2/src/reducers/document.actions";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { Row, Col } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import Document from "app2/src/components/Document";
import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { List } from "immutable";
import TableHeader from "app2/src/components/JobTabs/DocumentTab/Documents/TableHeader";
import track from "react-tracking";
import { jobResourceIds } from "app2/src/selectors/job.selectors";

const mapStateToProps = (state: RootState) => {
  const modelName = "document",
    docQueryParams = queryParams(state, { modelName });
  const page = docQueryParams.get("page");
  const documentIds = ids(state, { modelName }) || List();
  return {
    resourceIds: jobResourceIds(state),
    documentIds,
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    docQueryParams,
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: DocumentsTableProps) => {
  return {
    listDocuments: (queryParams: QueryParamsRecord) =>
      dispatch(
        documentActions.AsyncActions.listDocuments(
          {
            documentableType: "org",
            display: "resource",
            folder_id: ownProps.folderParentId,
          },
          queryParams,
        ),
      ),
    loadDocumentsById: (resourceIds) =>
      dispatch(
        documentActions.AsyncActions.loadDocumentsById(resourceIds, {
          documentableType: "org",
        }),
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DocumentsTableProps {
  folderParentId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DocumentsTableProps;

@track(() => {
  return {
    component: "DocumentsTable",
  };
})
class DocumentsTable extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.queryDocuments = this.queryDocuments.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const { loadDocumentsById, resourceIds } = this.props;
    loadDocumentsById(resourceIds);
  }

  @track((_props, _state, [page]) => ({
    action: "queryDocuments",
    page: page,
  }))
  public queryDocuments(page: number): void {
    const { listDocuments, docQueryParams } = this.props;
    const newQueryParams = docQueryParams.setIn(["page"], page);

    listDocuments(newQueryParams);
  }

  public render(): React.ReactNode {
    const { documentIds, page, pagination } = this.props;

    if (page?.loading) {
      return <SpinnerComponent localProperty={true} />;
    }

    if (_.isNullOrUndefined(page) || documentIds.size === 0) {
      return (
        <Row>
          <Col>
            <div className="form-section blank-state">
              <img src="/assets/images/icons-large/docs.33cddf3f.png" />
              <h2>No documents added.</h2>
              <p>Contact your Organization Administrator to add resources.</p>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <div className="standard-rl-margin">
        <TableHeader documentableType="org" />
        {documentIds.map((id) => {
          return <Document key={id.toString()} documentId={id} documentableType="org" />;
        })}
        <StandardPagination metadata={pagination} pageChanged={this.queryDocuments} />
        <br />
      </div>
    );
  }
}

export default connector(DocumentsTable);
