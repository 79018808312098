import { ICommand } from "app/src/Commands/Command";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";

export class ReorderLineItems implements ICommand {
  public originalOrder: IEstimateLineItem[];
  public updatedOrder: IEstimateLineItem[];

  constructor(lineItems: IEstimateLineItem[]) {
    this.originalOrder = lineItems.slice(0);
  }

  public execute(lineItems: IEstimateLineItem[]): boolean {
    for (const index in lineItems) {
      lineItems[index].sort_order = parseInt(index);
    }

    this.updatedOrder = lineItems.slice(0);
    return true;
  }
}
