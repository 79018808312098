export class OrgActivationHttpInterceptor implements ng.IHttpInterceptor {
  constructor(
    private $q: ng.IQService,
    private $injector: ng.auto.IInjectorService,
  ) {}

  public responseError = (rejection: ng.IHttpPromiseCallbackArg<any>): ng.IPromise<any> => {
    if (rejection.status === 455) {
      const $state = this.$injector.get("$state") as ng.ui.IStateService;
      $state.go("root.alert");
    }
    return this.$q.reject(rejection);
  };

  public static factory($q: ng.IQService, $injector: ng.auto.IInjectorService): OrgActivationHttpInterceptor {
    return new OrgActivationHttpInterceptor($q, $injector);
  }
}

OrgActivationHttpInterceptor.factory.$inject = ["$q", "$injector"];
