import * as React from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import * as config from "react-global-configuration";
import { IPaginationRecord } from "app2/src/reducers/pagination.reducer";
import { IUser } from "app/src/Models/User";
import { useForm } from "react-hook-form";
import { toJSON } from "app2/src/records/OrgRecord";

export interface CrmUserMatchModalProps {
  open: boolean;
  crmUser: any;
  occUsers: IUser[];
  integrationType: string;
  hide: () => void;
  save: (crmUser: any) => void;
}

export default function CrmUserMatchModal(props: CrmUserMatchModalProps) {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: props.crmUser,
  });
  const { dirty } = formState;

  const onSubmit = (data) => {
    const newCrmUser = props.crmUser.set("user_id", parseInt(data.user_id)).set("active", data.active);
    props.save(newCrmUser);
    props.hide();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal show={props.open} onHide={props.hide} size="lg">
        <Modal.Header closeButton>
          <h3 className="modal-title">{props.integrationType} User Match</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>Active</Form.Label>
              <Form.Check type="checkbox" name="active" ref={register}></Form.Check>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{props.integrationType} User</Form.Label>
              <p>{`${props.crmUser.name} - ${props.crmUser.email}`} </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{config.get("APP_NAME_SHORT")} User</Form.Label>
              <Form.Control as="select" name="user_id" ref={register}>
                <option value={""}>-- No User --</option>
                {props.occUsers.map((user, idx) => (
                  <option key={idx} value={user.id}>
                    {user.first_name} {user.last_name} - {user.email}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="cancel" onClick={props.hide}>
            Cancel
          </Button>
          <Button variant="save" disabled={!dirty} onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
}
