import { Record } from "immutable";

export const fromJSON = (json: Partial<ICalculatedFinancingData>): CalculatedFinancingRecord => {
  const record: ICalculatedFinancingRecord = { ...(json as any) };

  return new CalculatedFinancingRecord(record);
};

export interface ICalculatedFinancingData {
  monthly_payment: number;
  promo_payment: number;
  financed_amount: number;
  finance_option_id: number;
  estimate_id: number;
  error: string;
  loading?: boolean;
}

export interface ICalculatedFinancingRecord {
  monthly_payment: number;
  promo_payment: number;
  financed_amount: number;
  finance_option_id: number;
  estimate_id: number;
  error: string;
  loading: boolean;
}

export const defaultCalculatedFinancingProps = {
  monthly_payment: 0,
  promo_payment: 0,
  financed_amount: 0,
  finance_option_id: null,
  estimate_id: null,
  error: null,
  loading: false,
};

export class CalculatedFinancingRecord
  extends Record<ICalculatedFinancingRecord>(defaultCalculatedFinancingProps)
  implements ICalculatedFinancingRecord
{
  public readonly monthly_payment!: number;
  public readonly promo_payment!: number;
  public readonly financed_amount!: number;
  public readonly finance_option_id!: number;
  public readonly estimate_id!: number;
  public readonly error!: string;
  public readonly loading!: boolean;

  public constructor(values?: Partial<ICalculatedFinancingRecord>) {
    values ? super(values) : super();
  }
}
