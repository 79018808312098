import * as ng from "angular";
import { IBaseConfig } from "../Common/IBaseConfig";
import { ISession } from "app/src/Common/SessionService";

export class InvitationCtrl {
  public $errors: string[] = [];
  public token: string;
  public current_password: string;
  public new_password: string;
  public confirm_password: string;
  public accepted_tos: boolean;
  public spinnerPromise: ng.IPromise<any>;

  public static $inject = ["$http", "$stateParams", "$state", "BaseConfig", "Session"];
  constructor(
    public $http: ng.IHttpService,
    public $stateParams: ng.ui.IStateParamsService,
    public $state: ng.ui.IStateService,
    public BaseConfig: IBaseConfig,
    public Session: ISession,
  ) {
    this.token = $stateParams["token"];
    this.spinnerPromise = this.Session.logout();
  }

  public setPassword(form: ng.IFormController) {
    if (form.$invalid) {
      return;
    }

    if (this.token) {
      return this.Session.resetPassword(
        this.token,
        "invite",
        this.new_password,
        this.confirm_password,
        this.accepted_tos,
      )
        .then(() => {
          this.$state.go(this.BaseConfig.DEFAULT_ROUTE);
        })
        .catch((response: Response) => {
          if (response.status === 422) {
            this.$errors.push("Invalid or Expired reset request.");
          }
        });
    }
  }
}
