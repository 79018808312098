import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { activatedPriceListService } from "../api/activatedPriceList.service";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { RootDispatchType } from "../store";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { handleErrors } from "app2/src/reducers/Utils";
import { IActivatedPriceListData } from "app2/src/records/ActivatedPriceList";
import { IPriceListData } from "app2/src/records/PriceList";
import { activatedPriceList } from "app2/src/selectors/activatedPriceList.selectors";
import { StoreRegistry } from "app2/src/storeRegistry";

export const FETCH_ACTIVATED_PRICE_LIST = "@activatedPriceLists/FETCH_ACTIVATED_PRICE_LIST";
export const RECEIVE_ACTIVATED_PRICE_LIST = "@activatedPriceLists/RECEIVE_ACTIVATED_PRICE_LIST";
export const SET_LOADED = "@activatedPriceLists/SET_LOADED";

export const Actions = {
  fetchActivatedPriceList: (activatedPriceListId: number) =>
    createAction(FETCH_ACTIVATED_PRICE_LIST, { activatedPriceListId }),
  receiveActivatedPriceList: (activatedPriceList: IActivatedPriceListData, cachedPriceList: IPriceListData) =>
    createAction(RECEIVE_ACTIVATED_PRICE_LIST, { activatedPriceList, cachedPriceList }),
  setLoaded: (activatedPriceListId: number) => createAction(SET_LOADED, { activatedPriceListId }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  getCached: (orgId: number, activatedPriceListId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const apl = activatedPriceList(getState(), { activatedPriceListId });
      if (apl.id) {
        return;
      }

      dispatch(Actions.fetchActivatedPriceList(activatedPriceListId));

      try {
        const responseApl = await activatedPriceListService.loadCached(orgId, activatedPriceListId);
        const responseCachedPriceList = await activatedPriceListService.loadFile(
          responseApl.activated_price_list.file.file.url,
        );
        dispatch(
          Actions.receiveActivatedPriceList(responseApl.activated_price_list, responseCachedPriceList.price_list),
        );
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
        dispatch(Actions.setLoaded(activatedPriceListId));
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
