import { ActionsUnion, createAction } from "./Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./index";
import { TaskPoller } from "app2/src/api/task.service";
import { ITaskData } from "app2/src/records/Task";
import { RootDispatchType } from "../store";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";

export const RECEIVE_TASK = "@tasks/RECEIVE_TASK";

export const Actions = {
  receiveTask: (task: Partial<ITaskData>) => createAction(RECEIVE_TASK, task),
};

export type ITaskOptions = {
  disableFlashAlert?: boolean;
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  pollTask: (task: Partial<ITaskData>, options: ITaskOptions = {}): ThunkResult<Promise<TaskPoller>> => {
    return (dispatch: RootDispatchType, getState: () => RootState) => {
      const poller = new TaskPoller(task.location);

      poller.callback = (task: ITaskData) => {
        if (task.results.tasks) {
          _.each(task.results.tasks, (new_task: Partial<ITaskData>) => {
            if (_.isUndefined(getState().getIn(["tasks", "byId", new_task.id]))) {
              dispatch(AsyncActions.pollTask(new_task));
              dispatch(Actions.receiveTask(new_task));
            }
          });
        }
        dispatch(Actions.receiveTask(task));
      };

      return poller.promise.then(
        (task: ITaskData) => {
          let name = task.results.class_name;
          if (!name) {
            name = "Task Completion";
          }
          if (!options.disableFlashAlert) {
            dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, `${name} Successful.`));
          }
          dispatch(Actions.receiveTask(task));
          return getState().getIn(["tasks", "byId", task.id]);
        },
        (task: ITaskData) => {
          let err_msg = task.results.error_message;
          if (!err_msg) {
            err_msg =
              "There were problems processing your task. Please try again. If the problem persists contact support.";
          }
          dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, err_msg));
          dispatch(Actions.receiveTask(task));
          return getState().getIn(["tasks", "byId", task.id]);
        },
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
