import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { Map, List, get, getIn } from "immutable";
import { AccessRecord, UserRecord, emptyAccessValue } from "app2/src/records/UserRecord";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { byId as orgsById } from "app2/src/selectors/org.selectors";
import { user, currentUser } from "./userCommon.selectors";

// legacy code requires these
export { user, currentUser };

/**
 * Loads the accesses byId
 *
 * @param {RootState} state The RootState
 * @param {{}} options
 * @returns {Map<number, AccessRecord>} byId
 */
export const byId = (state: RootState) => state.getIn(["accesses", "byId"]);

/**
 * Loads the accesses from lastSavedByID
 *
 * @param {RootState} state The RootState
 * @param {{}} options
 * @returns {Map<number, AccessRecord>} lastSavedById
 */
export const lastSavedById = (state: RootState) => state.getIn(["accesses", "lastSavedById"]);

/**
 * Loads an access from byId
 *
 * @param {RootState} state The RootState
 * @param {{accessId: number}} options
 * @returns {AccessRecord} access
 */
export const selectById = createSelector(
  [byId, (state, props) => ({ state, props })],
  (accessesById: Map<number, AccessRecord>, stateProps: any) => {
    return get(accessesById, stateProps.props.accessId, emptyAccessValue);
  },
);

/**
 * this selector should only be used for old code that expects
 * denormalized redux state. no new code (components) should be using this.
 * new code should instead access redux state in a normalized fashion
 * using selectors
 */
export const currentUserAccesses = createSelector(
  [currentUser, byId, orgsById],
  (
    currentUser: UserRecord,
    accessesById: Map<number, AccessRecord>,
    orgsById: Map<number, OrgRecord>,
  ): List<AccessRecord> => {
    if (!currentUser || currentUser.accesses.size === 0) {
      return List();
    }

    return List(
      currentUser.accesses.map((accessId: number) => {
        let access = accessesById.get(accessId);
        access = access.set("org", orgsById.get(access.org_id));

        return access;
      }),
    );
  },
);

/**
 * Returns a list of all the user's accesses
 *
 * @param {RootState} state The RootState
 * @param {{userId: number}} options
 * @returns {List<AccessRecord>} accesses
 */
export const userAccesses = createSelector(
  [user, byId],
  (user: UserRecord, accessesById: Map<number, AccessRecord>): List<AccessRecord> => {
    if (!user) {
      return List();
    }

    return user.get("accesses", List()).map((accessId: number) => accessesById.get(accessId));
  },
);

/**
 * hydrates a user's accesses ( should only be used when you know it's limited, e.g. on a 'new user')
 *
 * @param {RootState} state The RootState
 * @param {{userId: number}} options
 * @returns {List<AccessRecord>} list of accesses
 */
export const hydratedUserAccesses = createSelector(
  [userAccesses, orgsById],
  (userAccesses, orgsById: Map<number, OrgRecord>): List<AccessRecord> => {
    if (userAccesses.size === 0) {
      return List();
    }

    return List(userAccesses.map((access: AccessRecord) => access.set("org", orgsById.get(access.org_id))));
  },
);

/**
 * hydrates a users w/ any access that have been changed (are dirty).
 * For usage when saving back to the server and it's needed in 1 object.
 *
 * @param {RootState} state The RootState
 * @param {{userId: number}} options
 * @returns {UserRecord} user
 */
export const dirtyUserAccesses = createSelector(
  [user, byId, lastSavedById],
  (
    user: UserRecord,
    accessesById: Map<number, AccessRecord>,
    lastSavedById: Map<number, AccessRecord>,
  ): List<AccessRecord> => {
    if (!user || user.accesses.size === 0) {
      return List();
    }

    return List<AccessRecord>(
      user.accesses
        .map((accessId: number) => {
          const access = accessesById.get(accessId);
          const lastSaved = lastSavedById.get(accessId);

          if (access.equals(lastSaved)) {
            return null;
          }

          return access;
        })
        .filter((a: AccessRecord | null) => a !== null),
    );
  },
);

/**
 * Gets the access that matches the given org and user id
 *
 * @param {RootState} state The RootState
 * @param {{userId: number, orgId: number}} options
 * @returns {AccessRecord} access
 */
export const accessByOrgId = createSelector(
  [userAccesses, (state, props) => ({ state, props })],
  (userAccesses: List<AccessRecord>, { props }: any): AccessRecord => {
    return userAccesses.find((access) => access.get("org_id") === props.orgId);
  },
);

/**
 * Gets the access config using accessByOrgId
 *
 * @param {RootState} state The RootState
 * @param {{userId: number, orgId: number}} options
 * @returns config value
 */
export const accessConfig = createSelector(
  [accessByOrgId, (state, props) => ({ state, props })],
  (access: AccessRecord, { props }: any) => {
    if (props?.path) {
      return getIn(access, ["config"].concat(props.path), props?.notSet) || props?.notSet;
    }
    return access?.get("config");
  },
);
