import { DiscountRecord, IDiscountData } from "app2/src/records/DiscountRecord";
import { discountService } from "app2/src/api/discount.service";
import { RootDispatchType } from "../store";
import { ThunkResult } from "./index";
import { ActionsUnion, createAction } from "./Utils";

export const FETCH_ORG_DISCOUNTS = "@discounts/FETCH_ORG_DISCOUNTS";
export const FETCH_DISCOUNT = "@discounts/FETCH_DISCOUNT";
export const RECEIVE_ORG_DISCOUNTS = "@discounts/RECEIVE_ORG_DISCOUNTS";
export const RECEIVE_DISCOUNT = "@discounts/RECEIVE_DISCOUNT";
export const REMOVE_ORG_DISCOUNT = "@discounts/REMOVE_ORG_DISCOUNT";
export const REMOVE_DISCOUNT = "@discounts/REMOVE_DISCOUNT";
export const RECEIVE_ORG_ERRORS = "@discounts/RECEIVE_ORG_ERRORS";
export const RECEIVE_DISCOUNT_ERROR = "@discounts/RECEIVE_DISCOUNT_ERROR";

export const Actions = {
  fetchDiscount: (orgId: number, discountId: number) =>
    createAction(FETCH_DISCOUNT, { orgId: orgId, discountId: discountId }),
  receiveDiscountError: (orgId, discountId: number, errors: string[]) =>
    createAction(RECEIVE_DISCOUNT_ERROR, { orgId: orgId, discountId: discountId, errors: errors }),
  receiveDiscount: (discount: Partial<IDiscountData>) => createAction(RECEIVE_DISCOUNT, discount),
  fetchDiscounts: (orgId: number) => createAction(FETCH_ORG_DISCOUNTS, orgId),
  receiveErrors: (orgId, errors: string[]) => createAction(RECEIVE_ORG_ERRORS, { orgId: orgId, errors: errors }),
  receiveDiscounts: (orgId: number, discounts: Partial<IDiscountData>[]) =>
    createAction(RECEIVE_ORG_DISCOUNTS, { orgId: orgId, discounts: discounts }),
  removeOrgDiscount: (orgId: number, discountId: number) =>
    createAction(REMOVE_ORG_DISCOUNT, { orgId: orgId, discountId: discountId }),
  removeDiscount: (discountId: number) => createAction(REMOVE_DISCOUNT, discountId),
};

export const AsyncActions = {
  addDiscount: (orgId: number, discount: Partial<IDiscountData>): ThunkResult<Promise<IDiscountData>> => {
    return (dispatch: RootDispatchType) => {
      const id = discount.id;
      dispatch(Actions.fetchDiscount(orgId, discount.id));

      return discountService.create(orgId, discount).then(
        (result) => {
          dispatch(Actions.removeOrgDiscount(orgId, id));
          dispatch(Actions.removeDiscount(id));
          dispatch(Actions.receiveDiscount(result));

          return result;
        },
        (errors) => {
          dispatch(Actions.receiveDiscountError(orgId, id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  updateDiscount: (discount: Partial<IDiscountData>): ThunkResult<Promise<IDiscountData>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchDiscount(discount.org_id, discount.id));

      return discountService.update(discount).then(
        (discount) => {
          dispatch(Actions.receiveDiscount(discount));
          return discount;
        },
        (errors) => {
          dispatch(Actions.receiveDiscountError(discount.org_id, discount.id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  archiveDiscount: (discount: DiscountRecord): ThunkResult<Promise<DiscountRecord>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchDiscount(discount.org_id, discount.id));

      return discountService.archive(discount).then(
        (archived) => {
          dispatch(Actions.removeOrgDiscount(archived.org_id, archived.id));
          dispatch(Actions.removeDiscount(archived.id));
          return archived;
        },
        (errors) => {
          dispatch(Actions.receiveDiscountError(discount.org_id, discount.id, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
  listDiscounts: (orgId: number): ThunkResult<any> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchDiscounts(orgId));

      return discountService.loadByOrg(orgId).then(
        (discounts) => {
          dispatch(Actions.receiveDiscounts(orgId, discounts));
          return discounts;
        },
        (errors) => {
          dispatch(Actions.receiveErrors(orgId, errors));
          return Promise.reject(errors);
        },
      );
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
