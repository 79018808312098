import { List } from "immutable";
import { EstimateFinanceOptionRecord } from "app2/src/records/EstimateFinanceOption";
import { createSelector } from "reselect";
import { estimate } from "app2/src/selectors/estimate.selectors";
import { EstimateRecord } from "app2/src/records/Estimate";
import { estimateFinanceOptions as base } from "app2/src/selectors/estimateFinanceOption.selectors";

/**
 * Selector for EstimateFinanceOptions on an estimate with a default to 0 if the estimate isn't loaded yet
 *
 * @param {RootState} The redux state
 * @param {estimateId: number} The estimate id to load the estimate finance options from
 */
export const estimateFinanceOptions = createSelector(
  [estimate, (state) => ({ state })],
  (estimate: EstimateRecord, stateProps): List<EstimateFinanceOptionRecord> => {
    const { state } = stateProps;

    if (!estimate) {
      return List();
    }

    return base(state, {
      estimateFinanceOptionIds: estimate.get("finance_option_ids", List()),
      fullEstimate: estimate,
    });
  },
);
