import * as React from "react";
import { Card, Accordion, Table } from "react-bootstrap";

export interface MeasurementCardProps {
  name: string;
  children: JSX.Element;
}

export const BaseMeasurementCard: React.FunctionComponent<MeasurementCardProps> = (props: MeasurementCardProps) => {
  const { name, children } = props;

  return (
    <Card>
      <Accordion>
        <Accordion.Toggle data-testid="toggle" className="link" as={Card.Header} eventKey={name}>
          {name}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={name} data-testid="collapse">
          <Card.Body>
            <Table striped size="sm">
              <thead></thead>
              <tbody data-testid="tbody-children">{children}</tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </Card>
  );
};
