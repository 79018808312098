import { Record, Map, fromJS } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IWebhookEventData>): WebhookEventRecord => {
  const record: IWebhookEventRecord = { ...(json as any) };

  if (json.payload) {
    record.payload = fromJS(json.payload);
  }
  if (json.tries) {
    record.tries = fromJS(json.tries);
  }

  return new WebhookEventRecord(record);
};

export interface IWebhookEventData {
  id: string;
  webhook_id: string;
  payload: any;
  tries: any;
  status: string;
  created_at: string;
  updated_at: string;
}

export interface IWebhookEventRecord {
  id: string;
  webhook_id: string;
  payload: Nullable<Map<string, any>>;
  tries: Nullable<Map<string, any>>;
  status: string;
  created_at: Nullable<string>;
  updated_at: Nullable<string>;
}

export const defaultWebhookEventProps = {
  id: "",
  webhook_id: "",
  payload: null,
  tries: null,
  status: "",
  created_at: null,
  updated_at: null,
};

export class WebhookEventRecord
  extends Record<IWebhookEventRecord>(defaultWebhookEventProps)
  implements IWebhookEventRecord {}
