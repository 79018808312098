import { DocumentRecord } from "app2/src/records/Document";
import { EstimateRecord } from "app2/src/records/Estimate";
import { EstimateFinanceOptionRecord } from "app2/src/records/EstimateFinanceOption";
import { EstimateGroupRecord } from "app2/src/records/EstimateGroup";
import { EstimateLineItemRecord } from "app2/src/records/EstimateLineItem";
import { EstimateLineItemOptionRecord } from "app2/src/records/EstimateLineItemOption";
import { ImageRecord } from "app2/src/records/Image";
import { RootState } from "app2/src/reducers";
import { estimate, lastSavedEstimate } from "app2/src/selectors/estimate.selectors";
import * as estimateFinanceOptionSelectors from "app2/src/selectors/estimateFinanceOption.selectors";
import * as estimateGroupSelectors from "app2/src/selectors/estimateGroup.selectors";
import * as estimateLineItemSelectors from "app2/src/selectors/estimateLineItem.selectors";
import * as estimateLineItemOptionSelectors from "app2/src/selectors/estimateLineItemOption.selectors";
import * as imageSelectors from "app2/src/selectors/image.selectors";
import * as documentSelectors from "app2/src/selectors/document.selectors";
import * as openingEstimationSelectors from "app2/src/selectors/openingEstimation.selectors";

import { List } from "immutable";
import { createSelector } from "reselect";
import { OpeningEstimationRecord } from "app2/src/records/OpeningEstimation";

export const estimateGroups = createSelector(
  [estimate, (state: RootState) => state],
  (estimate: EstimateRecord, state: any): List<EstimateGroupRecord> => {
    if (!estimate) {
      return null;
    }
    return estimateGroupSelectors.estimateGroups(state, { estimateGroupIds: estimate.group_ids, fullEstimate: true });
  },
);

export const lastSavedEstimateGroups = createSelector(
  [lastSavedEstimate, (state: RootState) => state],
  (estimate: EstimateRecord, state: any): List<EstimateGroupRecord> => {
    if (!estimate) {
      return null;
    }
    return estimateGroupSelectors.lastSavedEstimateGroups(state, {
      estimateGroupIds: estimate.group_ids,
      fullEstimate: true,
    });
  },
);

export const estimateFinanceOptions = createSelector(
  [estimate, (state: RootState) => state],
  (estimate: EstimateRecord, state: any): List<EstimateFinanceOptionRecord> => {
    if (!estimate) {
      return null;
    }
    return estimateFinanceOptionSelectors.estimateFinanceOptions(state, {
      estimateFinanceOptionIds: estimate.finance_option_ids,
    });
  },
);

export const lastSavedEstimateFinanceOptions = createSelector(
  [lastSavedEstimate, (state: RootState) => state],
  (estimate: EstimateRecord, state: any): List<EstimateFinanceOptionRecord> => {
    if (!estimate) {
      return null;
    }
    return estimateFinanceOptionSelectors.lastSavedEstimateFinanceOptions(state, {
      estimateFinanceOptionIds: estimate.finance_option_ids,
    });
  },
);

export const estimateLineItems = createSelector(
  [estimateGroups, (state: RootState) => state],
  (estimateGroups: List<EstimateGroupRecord>, state: any): List<EstimateLineItemRecord> => {
    if (!estimateGroups) {
      return null;
    }
    return estimateLineItemSelectors.estimateLineItems(state, {
      estimateLineItemIds: estimateLineItemIds(estimateGroups),
    });
  },
);

export const lastSavedEstimateLineItems = createSelector(
  [lastSavedEstimateGroups, (state: RootState) => state],
  (estimateGroups: List<EstimateGroupRecord>, state: any): List<EstimateLineItemRecord> => {
    if (!estimateGroups) {
      return null;
    }

    return estimateLineItemSelectors.lastSavedEstimateLineItems(state, {
      estimateLineItemIds: estimateLineItemIds(estimateGroups),
      fullEstimate: true,
    });
  },
);

export const estimateLineItemOptions = createSelector(
  [estimateLineItems, (state: RootState) => state],
  (estimateLineItems: List<EstimateLineItemRecord>, state: any): List<EstimateLineItemOptionRecord> => {
    if (!estimateLineItems) {
      return null;
    }
    return estimateLineItemOptionSelectors.estimateLineItemOptions(state, {
      estimateLineItemOptionIds: estimateLineItemOptionIds(estimateLineItems),
    });
  },
);

export const lastSavedEstimateLineItemOptions = createSelector(
  [lastSavedEstimateLineItems, (state: RootState) => state],
  (estimateLineItems: List<EstimateLineItemRecord>, state: any): List<EstimateLineItemOptionRecord> => {
    if (!estimateLineItems) {
      return null;
    }
    return estimateLineItemOptionSelectors.lastSavedEstimateLineItemOptions(state, {
      estimateLineItemOptionIds: estimateLineItemOptionIds(estimateLineItems),
    });
  },
);

export const estimateLineItemImages = createSelector(
  [estimateLineItems, (state: RootState) => state],
  (estimateLineItems: List<EstimateLineItemRecord>, state: any): List<ImageRecord> => {
    if (!estimateLineItems) {
      return null;
    }
    return imageSelectors.images(state, {
      imageIds: estimateLineItemImageIds(estimateLineItems),
    });
  },
);

export const lastSavedEstimateLineItemImages = createSelector(
  [lastSavedEstimateLineItems, (state: RootState) => state],
  (estimateLineItems: List<EstimateLineItemRecord>, state: any): List<ImageRecord> => {
    if (!estimateLineItems) {
      return null;
    }
    return imageSelectors.lastSavedImages(state, {
      imageIds: estimateLineItemImageIds(estimateLineItems),
    });
  },
);

export const estimateLineItemDocuments = createSelector(
  [estimateLineItems, (state: RootState) => state],
  (estimateLineItems: List<EstimateLineItemRecord>, state: any): List<DocumentRecord> => {
    if (!estimateLineItems) {
      return null;
    }
    return documentSelectors.documents(state, {
      documentIds: estimateLineItemDocumentIds(estimateLineItems),
    });
  },
);

export const lastSavedEstimateLineItemDocuments = createSelector(
  [lastSavedEstimateLineItems, (state: RootState) => state],
  (estimateLineItems: List<EstimateLineItemRecord>, state: any): List<DocumentRecord> => {
    if (!estimateLineItems) {
      return null;
    }
    // documents are not editable, so use current documents
    return documentSelectors.documents(state, {
      documentIds: estimateLineItemDocumentIds(estimateLineItems),
    });
  },
);

export const estimateLineItemOpeningEstimations = createSelector(
  [estimateLineItems, (state: RootState) => state],
  (estimateLineItems: List<EstimateLineItemRecord>, state: any): List<OpeningEstimationRecord> => {
    if (!estimateLineItems) {
      return null;
    }
    return openingEstimationSelectors.openingEstimations(state, {
      openingEstimationIds: openingEstimationIds(estimateLineItems),
    });
  },
);

export const lastSavedEstimateLineItemOpeningEstimations = createSelector(
  [lastSavedEstimateLineItems, (state: RootState) => state],
  (estimateLineItems: List<EstimateLineItemRecord>, state: any): List<OpeningEstimationRecord> => {
    if (!estimateLineItems) {
      return null;
    }
    return openingEstimationSelectors.lastSavedOpeningEstimations(state, {
      openingEstimationIds: openingEstimationIds(estimateLineItems),
    });
  },
);

const estimateLineItemIds = (estimateGroups: List<EstimateGroupRecord>): List<number> => {
  return estimateGroups
    .map((group) => {
      return group.line_item_ids;
    })
    .flatten()
    .toList();
};

const estimateLineItemOptionIds = (estimateLineItems: List<EstimateLineItemRecord>): List<number> => {
  return estimateLineItems
    .map((eli) => {
      return eli.option_ids;
    })
    .flatten()
    .toList();
};

const estimateLineItemImageIds = (estimateLineItems: List<EstimateLineItemRecord>): List<number> => {
  return estimateLineItems
    .map((eli) => {
      return eli.image_ids;
    })
    .flatten()
    .toList();
};

const estimateLineItemDocumentIds = (estimateLineItems: List<EstimateLineItemRecord>): List<number> => {
  return estimateLineItems
    .map((eli) => {
      return eli.document_ids;
    })
    .flatten()
    .toList();
};

const openingEstimationIds = (estimateLineItems: List<EstimateLineItemRecord>): List<number> => {
  return estimateLineItems
    .map((eli) => {
      return eli.opening_estimation_ids;
    })
    .flatten()
    .toList();
};
