import { PaymentRequest } from "app2/src/components/PaymentsModal/PaymentRequest";
import { pathname } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Root } from "app2/src/components/PaymentsModal/Root";
import MakePayment from "app2/src/components/Payments/MakePayment";

export interface PaymentsModalProps {}

export const PaymentsModal: React.FC<PaymentsModalProps> = () => {
  // selectors
  const path = useSelector(pathname);

  // hooks
  const dispatch = useDispatch();
  const match = useRouteMatch();

  // callbacks
  const closeModal = React.useCallback(() => {
    const prevPath = path.split("/payments")[0];
    dispatch(push(prevPath));
  }, [dispatch, path]);

  return (
    <Modal show={true} onHide={closeModal} size="lg" backdrop="static">
      <Switch>
        <Route path={`${match.url}/onscreen`}>
          <MakePayment closeModal={closeModal} />
        </Route>
        <Route path={`${match.url}/request`}>
          <PaymentRequest closeModal={closeModal} />
        </Route>
        <Route path={"/"}>
          <Root closeModal={closeModal} />
        </Route>
      </Switch>
    </Modal>
  );
};
