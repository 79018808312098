import { IFeeResource, IFee, IFeeResponse } from "../Models/Fee";
import { IFlash, FlashLevels } from "app/src/Common/FlashService";

export interface IFeeStateParams extends ng.ui.IStateParamsService {
  id: number;
}

export class FeeNewCtrl {
  public fee: IFee;
  public feeResponse: IFeeResponse;
  public permission = "create";

  public static $inject = ["Fee", "Flash", "$stateParams", "$state"];
  constructor(
    public FeeResource: IFeeResource,
    public Flash: IFlash,
    public $stateParams: IFeeStateParams,
    public $state: ng.ui.IStateService,
  ) {
    this.feeResponse = <IFeeResponse>FeeResource.query();
    if ($stateParams.id) {
      this.fee = FeeResource.get({ id: $stateParams.id });
      this.permission = "update";
    } else {
      this.fee = FeeResource.fromJSON({
        min_interval_count: 0,
        min_interval: "month",
        interval: "month",
        installments: 1,
      });
    }
  }

  public submit(form: ng.IFormController) {
    if (form.$invalid) {
      return;
    }

    this.fee.$saveOrCreate().then(() => {
      this.$state.go("root.fee_show", this.fee);
    });
  }
}
