import * as React from "react";
import { List } from "immutable";
import { Button, Table } from "react-bootstrap";
import { EstimateOverviewRecord } from "app2/src/records/EstimateOverview";
import { CurrencyFormat, DateFormat } from "app2/src/helpers/Format";
import { useTracking } from "react-tracking";

export interface EstimateOverviewTableProps {
  estimates: List<EstimateOverviewRecord>;
  onClickHandler: (estimateId: number) => void;
}

export const EstimateOverviewTable: React.FC<EstimateOverviewTableProps> = ({ estimates, onClickHandler }) => {
  const { trackEvent } = useTracking<any>({
    category: "Estimate",
  });

  const select = (estimateId: number) => {
    trackEvent({ action: "selected" });
    onClickHandler(estimateId);
  };
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Last Updated</th>
          <th>Products</th>
          <th>Amount</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {estimates?.map((e) => (
          <tr key={e.id} className="link" onClick={() => select(e.id)}>
            <td>{e.name}</td>
            <td>{DateFormat(e.updated_at)}</td>
            <td>{e.line_items_count}</td>
            <td>{CurrencyFormat(e.total)}</td>
            <td>
              <Button>Show</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
