import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ShowPdf } from "./ShowPdf";
import { DocumentTabs } from "./DocumentTabs";
import { useSelector } from "react-redux";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { useTracking } from "react-tracking";

export const SignaturesTab: React.FC = () => {
  const match = useRouteMatch();
  const jobId = useSelector(currentJobId);
  const { Track } = useTracking<any>({ component: "SignaturesTab", job: jobId, category: "Signatures" });

  return (
    <Track>
      <Switch>
        <Route path={`${match.url}/view_pdf/:documentId`} component={ShowPdf} />
        <Route path={`${match.url}/:documentKind`} component={DocumentTabs} />
        <Route path={`${match.url}`} render={() => <Redirect to={`${match.url}/estimate_documents`} />} />
      </Switch>
    </Track>
  );
};
