import * as ng from "angular";
import { IScreenShareResource } from "app/src/Models/ScreenShare";
import { RootStoreType } from "app2/src/store";
import { dispatch } from "app2/src/storeRegistry";
import { subscriber } from "app2/src/helpers/Subscribe";
import { JobRecord } from "app2/src/records/Job";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as screenShareActions from "app2/src/reducers/screenShare.actions";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import * as angulartics from "angulartics";
import { ScreenShareRecord } from "app2/src/records/ScreenShare";

export class ScreenShareService {
  public activeScreenShare: ScreenShareRecord;
  public $scope: ng.IScope;
  public job: JobRecord;
  private store: RootStoreType;
  private _currentJob: () => void;
  private _unloadBinding: () => void;

  public static $inject = ["ScreenShare", "Flash", "$analytics"];

  constructor(
    public ScreenShare: IScreenShareResource,
    public Flash: IFlash,
    public $analytics: angulartics.IAnalyticsService,
  ) {
    this._currentJob = subscriber.subscribe<JobRecord>("components.common.currentJob", (job: JobRecord) => {
      if (!this.job || this.job.id !== job.id) {
        this.job = job;
      }
    });
  }

  public setup($scope) {
    this.$scope = $scope;
    this.setupDirtyWatcher();
  }

  public setupDirtyWatcher() {
    this._unloadBinding = this.$scope.$on("onBeforeUnload", (event: ng.IAngularEvent, args: any) => {
      if (this.currentScreenShare()) {
        args["message"] = "You have a Screen Share active. Please stop the screen share, then refresh.";
        event.preventDefault();
      }
    });

    this.$scope.$on("$destroy", () => {
      this.cleanup();
    });
  }

  public async create(job_id) {
    const screen_share = await dispatch(screenShareActions.AsyncActions.addScreenShare(job_id, "basic"));
    return screen_share;
  }

  public stop() {
    //noinspection TypeScriptUnresolvedVariable
    screenleap.stopSharing();
    this.cleanup();
  }

  public currentScreenShare() {
    if (
      this.activeScreenShare &&
      (_.isUndefined(this.activeScreenShare.ended) || _.isNull(this.activeScreenShare.ended))
    ) {
      return this.activeScreenShare;
    } else {
      return undefined;
    }
  }

  public startSharing() {
    if (!this.job) {
      this.Flash.addMessage(FlashLevels.danger, "No Job Available for Screen Share!");
      return;
    }
    if (this.currentScreenShare()) {
      this.stop();
    }
    this.create(this.job.id).then((screenShare: ScreenShareRecord) => {
      this.activeScreenShare = screenShare;
      this.connectScreenShare(this.activeScreenShare);
      // Event category & action used for analytics job reporting - do not rename and keep upon refactor
      this.$analytics.eventTrack("screenshare started", {
        category: "Job Header",
        kind: "basic",
        job: this.job.id,
        org: this.job.org_id,
      });
    });
  }

  public connectScreenShare(screenShare) {
    const callbacks = {
      appConnectionFailed: [
        () => {
          dispatch(screenShareActions.Actions.setScreenShareStatus(this.job.id, "stop_requested"));
          this.Flash.addMessage(FlashLevels.danger, "Screen Sharing Connection Failed");
        },
      ],
      screenShareStartError: [
        () => {
          dispatch(screenShareActions.Actions.setScreenShareStatus(this.job.id, "stop_requested"));
          this.Flash.addMessage(FlashLevels.danger, "Screen Sharing Start Error");
        },
      ],
    };

    //noinspection TypeScriptUnresolvedVariable,TypeScriptValidateJSTypes
    screenleap.startSharing(screenShare.metadata.presenterAppType, screenShare.metadata, callbacks);
    const started = _.debounce(() => {
      dispatch(screenShareActions.AsyncActions.setRemoteScreenShareStatus(this.job.id, "active"));
      dispatch(commonActions.Actions.notifierAddMessage("Screen Sharing Started"));
    }, 500);
    //noinspection TypeScriptUnresolvedVariable
    screenleap.onScreenShareStart = () => {
      started();
    };
    //noinspection TypeScriptUnresolvedVariable
    screenleap.onScreenShareEnd = async () => {
      if (!_.isUndefined(this.activeScreenShare) || (this.activeScreenShare && this.activeScreenShare.id !== 0)) {
        dispatch(commonActions.Actions.notifierAddMessage("Screen Sharing Stopped"));
        dispatch(
          screenShareActions.AsyncActions.saveScreenShareStop(this.activeScreenShare.job_id, this.activeScreenShare.id),
        );
        dispatch(screenShareActions.AsyncActions.setRemoteScreenShareStatus(this.activeScreenShare.job_id, "off"));
        this.activeScreenShare = undefined;
      }
    };
    //noinspection TypeScriptUnresolvedVariable,JSUnusedLocalSymbols
    screenleap.onViewerConnect = (participantId, externalId) => {
      // Event category & action used for analytics job reporting - do not rename and keep upon refactor
      this.$analytics.eventTrack("screenshare viewer connected", {
        category: "Job Header",
        kind: "basic",
        job: this.activeScreenShare.job_id,
        org: this.job.org_id,
      });
      dispatch(commonActions.Actions.notifierAddMessage("Viewer Connected: " + participantId));
    };
    //noinspection TypeScriptUnresolvedVariable,JSUnusedLocalSymbols
    screenleap.onViewerDisconnect = (participantId, externalId) => {
      this.$analytics.eventTrack("screenshare viewer disconnected", {
        category: "Job Header",
        kind: "basic",
        job: this.activeScreenShare.job_id,
        org: this.job.org_id,
      });
      dispatch(commonActions.Actions.notifierAddMessage("Viewer Disconnected: " + participantId));
    };
  }

  public cleanup() {
    if (this._unloadBinding) {
      this._unloadBinding();
    }
  }
}
