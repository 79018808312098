import * as React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { preferencesConfig } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: HouzzProps) => {
  const prefs = preferencesConfig(state, { orgId: ownProps.orgId });
  return {
    houzzUrl: prefs?.getIn(["integrations", "houzz", "url"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: HouzzProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface HouzzProps {
  orgId: number;
  updatePrefConfig: (path: any, value: any) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & HouzzProps;

class Houzz extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { houzzUrl, updatePrefConfig } = this.props;

    return (
      <div className="form-section">
        <div className="form-section-content">
          <Row>
            <Col sm={4} className="d-flex">
              <img
                className="img-fluid align-self-center integration-logo"
                src="/assets/images/houzz.09ab8781.png"
                title="Houzz"
              />
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  id="houzz_url"
                  defaultValue={houzzUrl}
                  onChange={(e) => updatePrefConfig(["integrations", "houzz", "url"], e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connector(Houzz);
