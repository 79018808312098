import { Record } from "immutable";
import { Nullable } from ".";

export const fromJSON = (json: Partial<IWallFacadeData>): WallFacadeRecord => {
  const recordData: IWallFacadeRecord = { ...(json as any) };

  return new WallFacadeRecord(recordData);
};

export interface IWallFacadeData {
  id: number;
  name: string;
  designator: string;
  ref_id: string;
  location: string;
  level: string;
  orientation: number;
  pitch: number;
  area: number;
  ui: number;
  created_at: Date;
  updated_at: Date;

  _destroy?: boolean;
}

export interface IWallFacadeRecord {
  id: number;
  name: string;
  designator: string;
  ref_id: string;
  location: string;
  level: string;
  orientation: number;
  pitch: number;
  area: number;
  ui: number;
  created_at: Date;
  updated_at: Date;

  _destroy: Nullable<boolean>;
}

const defaultWallFacadeProps: IWallFacadeRecord = {
  id: 0,
  name: "",
  designator: "",
  ref_id: "",
  location: "",
  level: "",
  orientation: 0,
  pitch: 0,
  area: 0,
  ui: 0,
  created_at: new Date(),
  updated_at: new Date(),
  _destroy: null,
};

export class WallFacadeRecord extends Record(defaultWallFacadeProps) implements IWallFacadeRecord {
  public readonly id!: number;
  public readonly name!: string;
  public readonly designator!: string;
  public readonly ref_id!: string;
  public readonly location!: string;
  public readonly level!: string;
  public readonly orientation!: number;
  public readonly pitch!: number;
  public readonly area!: number;
  public readonly ui!: number;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;
  public readonly _destroy!: Nullable<boolean>;

  public constructor(values?: Partial<IWallFacadeRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
