import * as React from "react";
import { Table, Button } from "react-bootstrap";
import * as config from "react-global-configuration";
import { StandardPagination } from "../../Pagination/Standard";
import { IUser } from "app/src/Models/User";
import { List } from "immutable";
import { MetaPaginationRecord } from "app2/src/records/MetaPagination";

export interface HomevestUserTableProps {
  integrationType: string;
  integrationUsers: List<any>;
  occUsers: IUser[];
  pagination: MetaPaginationRecord;
  queryUsers: (page: number) => void;
  sync: () => void;
}

export default function HomevestUserTable(props: HomevestUserTableProps) {
  if (!props.pagination) {
    return null;
  }

  return (
    <div className="mt-3">
      <Table striped>
        <thead>
          <tr>
            <th>{config.get("APP_NAME_SHORT")} User</th>
            <th>{props.integrationType} User</th>
            <th>
              <Button onClick={props.sync}>Sync</Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {(props?.occUsers || []).map((occ_user: any) => {
            const integrationUser = props.integrationUsers.find((u) => u.user_id === occ_user.id);
            return (
              <tr key={occ_user.id}>
                <td>{`${occ_user.name()} - ${occ_user.email}`}</td>
                <td>{integrationUser && `${integrationUser.name} - ${integrationUser.email}`}</td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <StandardPagination metadata={props.pagination} pageChanged={props.queryUsers} />
    </div>
  );
}
