import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IJob } from "app/src/Models/Job";
import { IMeasurement } from "app/src/Models/Measurement";
import * as angulartics from "angulartics";
import { IBaseConfig } from "../../Common/IBaseConfig";
import * as ng from "angular";
import { AddressRecord } from "app2/src/records/Address";
import { dispatch, useSelector } from "app2/src/storeRegistry";
import * as integrationsViewActions from "app2/src/reducers/components/integrationsView.actions";
import { currentJob, currentJobAddress, currentJobId } from "app2/src/selectors/job.selectors";
import { currentMeasurement, rootKey } from "app2/src/selectors/measurement.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { AsyncActions, Actions } from "app2/src/reducers/measurement.actions";
import { IElevation } from "app/src/Models/Elevation";
import { IRepository } from "app/src/Common/Repository";
import { RootState } from "app2/src/reducers";
import { IOpeningData } from "app2/src/records/Opening";
import { IAnnotation } from "app/src/Models/Annotation";
import { IWallFacadeData } from "app2/src/records/WallFacade";

export class MeasurementTabCtrl {
  public spinnerPromise: ng.IPromise<any>;
  public addressRecord: AddressRecord;
  public fencing: number;
  public gafEnvironment = false;

  public static $inject = [
    "Upload",
    "BaseConfig",
    "$rootScope",
    "$scope",
    "Repository",
    "$analytics",
    "$uibModal",
    "$q",
  ];
  constructor(
    public Upload: ng.angularFileUpload.IUploadService,
    public BaseConfig: IBaseConfig,
    public $rootScope: RsfRootScope,
    public $scope: ng.IScope,
    public Repository: IRepository,
    protected $analytics: angulartics.IAnalyticsService,
    private $uibModal: ng.ui.bootstrap.IModalService,
    private $q: ng.IQService,
  ) {
    this.gafEnvironment = BaseConfig.ENVIRONMENT === "gaf";
    const listeners = [];
    listeners.push(
      this.$rootScope.$on("order_quick_measure:open", () => {
        this.openQuickMeasureModal();
      }),
    );
    listeners.push(
      this.$rootScope.$on("measurement_import:open", () => {
        this.showImportScreen();
      }),
    );
    listeners.push(
      this.$rootScope.$on("annotator:open", () => {
        this.showAnnotator();
      }),
    );
    listeners.push(
      this.$rootScope.$on("pool_tool:open", () => {
        this.openPoolTool();
      }),
    );
    listeners.push(
      this.$rootScope.$on("rapid_area:open", () => {
        this.openRapidArea();
      }),
    );
    listeners.push(
      this.$rootScope.$on("satellite_measure:open", () => {
        this.openSatelliteMeasure();
      }),
    );
    listeners.push(
      this.$rootScope.$on("deck_tool:open", () => {
        this.openDeckWizard();
      }),
    );

    this.$scope.$on("$destroy", () => {
      listeners.forEach((listener) => {
        listener();
      });
    });
  }

  public showImportScreen() {
    this.trackEvent("show import measurements", {
      category: "Job",
    });
    const job: IJob = this.Repository.Job.fromJSON(useSelector(currentJob).toJS());
    const modalInstance: ng.ui.bootstrap.IModalServiceInstance = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "src/Jobs/tabs/import_measurements.html",
      controller: "ImportMeasurementsCtrl",
      backdrop: "static",
      controllerAs: "$ctrl",
      size: "lg",
      windowClass: "import-modal",
      resolve: {
        job: job,
      },
    });

    modalInstance.result
      .then((returned) => {
        if (returned.type === "upload") {
          this.spinnerPromise = returned.object.then(() => {
            this.loadMeasurement();
          });
        } else if (returned.type === "hover_modal") {
          returned.object.result.then(() => {
            this.loadMeasurement();
          });
        } else if (returned.type === "order_modal") {
          this.loadMeasurement();
        } else if (!this.gafEnvironment && returned.type === "quick_measure_modal") {
          this.openQuickMeasureModal();
        } else if (returned.type === "roof_scope_modal") {
          this.openRoofScopeModal();
        } else if (returned.type === "plnar_modal") {
          this.openPlnarModal();
        }
      })
      .catch(() => {
        this.trackEvent("cancel import measurements", {
          category: "Job",
        });
      });
  }

  public loadMeasurement() {
    dispatch(AsyncActions.loadMeasurement(["elevations"]));
  }

  public openQuickMeasureModal() {
    this.addressRecord = useSelector(currentJobAddress);
    dispatch(integrationsViewActions.Actions.openQuickMeasureModal());
  }

  public openRoofScopeModal() {
    this.addressRecord = useSelector(currentJobAddress);
    dispatch(integrationsViewActions.Actions.openRoofScopeModal());
  }

  public openPlnarModal() {
    this.addressRecord = useSelector(currentJobAddress);
    dispatch(integrationsViewActions.Actions.openPlnarModal());
  }

  public showAnnotator() {
    const job: IJob = useSelector(currentJob).toJS();
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        size: "xl",
        windowClass: "window-annotator",
        component: "windowAnnotator",
        backdrop: "static",
        resolve: {
          job,
          org: null,
        },
      })
      .result.then((result: { measurement: IMeasurement }) => {
        const measurement = result.measurement;

        _.each(result.measurement.elevations, (e: IElevation) => {
          e.merge(result.measurement);
        });

        const promises: ng.IPromise<any>[] = [];

        const elevations: IElevation[] = measurement.elevations;
        measurement.job_id = job.id;
        _.each(elevations, (e: IElevation, idx: number) => {
          if (e.$hasChanged) {
            e.measurement = { job_id: measurement.job_id } as IMeasurement;
            promises.push(e.saveRendered(this.BaseConfig, this.Upload, job.images.length + idx));
          }
        });

        this.spinnerPromise = this.$q.all(promises).then((async () => {
          const rootPath = [rootKey, "byId", measurement.id];
          dispatch(Actions.updateForm({ rootPath, name: "siding_total_area", value: measurement.siding_total_area }));
          dispatch(Actions.updateWindows(measurement.windows as any as IOpeningData[]));
          dispatch(Actions.updateDoors(measurement.doors as any as IOpeningData[]));
          dispatch(Actions.updateWallFacades(measurement.wall_facades as any as IWallFacadeData[]));
          await dispatch(AsyncActions.updateMeasurement(["elevations"]));
          const measurementRecord = useSelector((state: RootState) => currentMeasurement(state, {}));
          const ePromises: any[] = [];

          _.each(elevations, (e: IElevation) => {
            e.measurement = null;
            e.measurement_id = result.measurement.id;
            _.each(e.annotations, (a: IAnnotation) => {
              a.decycleToJSON();
              delete a.elevation;
              if (!a.opening_id) {
                a.opening_id = measurementRecord.getIn([`${a.opening_type}_ids`, a.sort_order]);
                delete a.sort_order;
              }
            });
            ePromises.push(e.$saveOrCreate());
          });
          return this.$q.all(ePromises).then((elevations) => {
            dispatch(Actions.updateElevations(elevations));
          });
        }) as () => ng.IPromise<any>);
      });
  }

  public openRapidArea() {
    const address = useSelector(currentJobAddress).toJS();
    const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        size: "xl",
        component: "rapidArea",
        backdrop: "static",
        animation: false,
        resolve: {
          address,
          measurement,
        },
      })
      .result.then((data) => {
        dispatch(Actions.updateRapidAreaTool(data.rapid_area));
        dispatch(AsyncActions.updateMeasurement(["elevations"]));
      });
  }

  public openDeckWizard() {
    const address = useSelector(currentJobAddress).toJS();
    const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        size: "xl",
        component: "deckWizard",
        backdrop: "static",
        animation: false,
        resolve: {
          measurement,
          address,
        },
      })
      .result.then((data) => {
        dispatch(Actions.updateDecks(data.decks));
        dispatch(AsyncActions.updateMeasurement(["elevations"]));
      });
  }

  public openPoolTool() {
    const address = useSelector(currentJobAddress).toJS();
    const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        size: "xl",
        component: "poolTool",
        backdrop: "static",
        animation: false,
        resolve: {
          measurement: measurement,
          address: address,
        },
      })
      .result.then((data) => {
        dispatch(Actions.updatePools(data.pools));
        dispatch(AsyncActions.updateMeasurement(["elevations"]));
      });
  }

  public openSatelliteMeasure() {
    const address = useSelector(currentJobAddress).toJS();
    const measurement = useSelector((state: RootState) => currentMeasurement(state, {})).toJS();
    this.$uibModal
      .open(<ng.ui.bootstrap.IModalSettings>{
        size: "xl",
        component: "satelliteMeasure",
        backdrop: "static",
        animation: false,
        resolve: {
          measurement,
          address,
        },
      })
      .result.then((data) => {
        dispatch(Actions.updateSatelliteMeasurementTool(data.leaflet, data.leafletZoom));
        dispatch(AsyncActions.updateMeasurement(["elevations"]));
      });
  }

  public trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        job: useSelector(currentJobId),
        org: useSelector(currentOrgId),
        navDisplay: true,
      }),
    );
  }
}
