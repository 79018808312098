import "./preload";
import "./Vendor";
import "./templates.js";

import "app2/src/Ioc";
import { StoreRegistry, dispatch } from "app2/src/storeRegistry";
import * as angular from "angular";
import * as ng from "angular";
import * as moment from "moment";
import { IBaseConfig } from "app/src/Common/IBaseConfig";
import { TransitionService, Transition } from "@uirouter/core";
import { HeaderCtrl } from "app/src/Header/HeaderCtrl";
import { Session, ISession } from "app/src/Common/SessionService";
import { RsfPermissions } from "app/src/Common/PermissionsDirective";
import { RsfConfirmationDialog } from "app/src/Common/ConfirmationDialogDirective";
import { DecimalPlaces } from "app/src/Common/DecimalPlacesDirective";
import { JobAnalytics } from "app/src/Common/JobAnalyticsDirective";
import { us_states, countries, us_states_abbreviation, countries_abbreviation } from "app/src/Common/StatesConstant";
import { JobRoutes } from "app/src/Jobs/JobRoutes";
import { OrgRoutes } from "app/src/Orgs/OrgRoutes";
import { ProductColorDropdown } from "app/src/Estimator/ProductColorDropdown";
import { HumanizeFilter } from "app/src/Common/HumanizeFilter";
import { CalendarRoutes } from "app/src/Calendar/CalendarRoutes";
import { EstimatorRoutes } from "app/src/Estimator/EstimatorRoutes";
import { ScreenShareRoutes } from "app/src/ScreenShare/ScreenShareRoutes";
import { ProfileRoutes } from "app/src/Profile/ProfileRoutes";
import { AuthHttpInterceptor } from "app/src/Common/AuthHttpInterceptor";
import { FakeFormAction } from "app/src/Common/FakeFormAction";
import { SignatureRoutes } from "app/src/Signature/SignatureRoutes";
import { SpinnerComponent } from "app/src/Common/SpinnerComponent";
import { EmbedSrc } from "app/src/Common/EmbedSrcDirective";
import { FlashService } from "app/src/Common/FlashService";
import { ConfirmDialogService } from "app/src/Common/ConfirmDialogService";
import { PaymentInfoRoutes } from "app/src/PaymentInfo/PaymentInfoRoutes";
import { ModelRoutes } from "app/src/Models/ModelRoutes";
import { LoginRoutes } from "app/src/Login/LoginRoutes";
import { VisualizationRoutes } from "app/src/Visualization/VisualizationRoutes";
import { UnitConversionService } from "app/src/Common/UnitConversionService";
import { PrettyNameService } from "app/src/Common/PrettyNameService";
import { ToolInfoService } from "app/src/Common/ToolInfoService";
import { HandlebarsHelperService } from "app/src/Patterns/HandlebarsHelperService";
import { EstimatorTourService } from "app/src/Common/Tours/EstimatorTourService";
import { JobInfoTourService } from "app/src/Common/Tours/JobInfoTourService";
import { JobShowTourService } from "app/src/Common/Tours/JobShowTourService";
import { DirtyWatcher } from "app/src/Common/DirtyWatcher";
import EventingService from "app/src/Common/EventingFactory";
import FileQueueInit from "app/src/Common/FileQueueFactory";
import MeasurementImporterFactory from "app/src/Common/MeasurementImporterFactory";
import * as Humanize from "humanize-plus";
import { IRepository, Repository } from "app/src/Common/Repository";
import { SetFocus } from "app/src/Common/SetFocus";
import { RsfTab } from "app/src/Common/RsfTab";
import { ScrollIf } from "app/src/Common/ScrollIf";
import { valid_uom, valid_kind } from "app/src/Common/Constants";
import { StartFromFilter } from "app/src/Common/StartFromFilter";
import { BillingRoutes } from "./Billing/BillingRoutes";
import { JobMenuRoutes } from "./JobMenu/JobMenuRoutes";
import { ReportRoutes } from "./Reports/ReportRoutes";
import { DrawingComponent } from "./Drawing/DrawingComponent";
import { ToolDisplayComponent } from "./Common/Components/ToolDisplayComponent";
import { RefreshButtonComponent } from "./Common/Components/RefreshButtonComponent";
import { TosHttpInterceptor } from "./Common/TosHttpInterceptor";
import { GmapsAutocompleteComponent } from "app/src/GoogleMapsAutocomplete/GmapsAutocompleteComponent";
import { LeafletMapComponent } from "app/src/LeafletMap/LeafletMapComponent";
import { RsfMapComponent } from "app/src/RsfMap/RsfMapComponent";
import { OrgActivationHttpInterceptor } from "./Common/OrgActivationHttpInterceptor";
import { SignedDocRoutes } from "./SignedDocuments/SignedDocRoutes";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { ForbiddenHttpInterceptor } from "./Common/ForbiddenHttpInterceptor";
import * as ngResource from "angular-resource";
import * as ngMap from "ngmap";
import uiTour from "angular-ui-tour/dist/angular-ui-tour";
import { OrgAclType } from "./Models/Org";
import { LearnMoreAds } from "app2/src/components/LearnMoreAds";
import { react2angular } from "react2angular";
import { AdService } from "./Common/AdService";
import PdfDisplay from "app2/src/components/Pdf/PdfDisplay";
import SaveablePdfDisplay from "app2/src/components/Pdf/SaveablePdfDisplay";
import FlashAlert from "app2/src/components/Common/FlashAlert";
import InviteModal from "app2/src/components/Common/InviteModal";
import ScreenShare from "app2/src/components/ScreenShare/ScreenShare";
import Toolbar from "app2/src/components/ScreenShare/Toolbar";
import Notifier from "app2/src/components/Common/Notifier";
import { ReactSelectWrapper } from "app2/src/components/Common/ReactSelectWrapper";
import UserHeaderInfo from "app2/src/components/Common/UserHeaderInfo";
import { FinanceOptionRoutes } from "app/src/FinanceOption/FinanceOptionRoutes";
import RsfSref from "app/src/Routing/RsfSrefDirective";
import { Router as ReactRouter } from "app2/src/helpers/Router";
import JobTabs from "app2/src/components/JobTabs";
import OrgTabs from "app2/src/components/OrgTabs";
import Connector from "app2/src/components/Common/Connector";
import { push } from "connected-react-router/immutable";
import { setupServiceWorker, checkForUpdate } from "app2/src/services/serviceWorker.service";
import NgDocumentLink from "app2/src/components/Document/NgDocumentLink";
import PageTitleUpdater from "app2/src/components/Common/PageTitleUpdater";

const modules = [
  ModelRoutes,
  LoginRoutes,
  JobRoutes,
  JobMenuRoutes,
  OrgRoutes,
  EstimatorRoutes,
  CalendarRoutes,
  ScreenShareRoutes,
  ProfileRoutes,
  SignatureRoutes,
  PaymentInfoRoutes,
  VisualizationRoutes,
  BillingRoutes,
  ReportRoutes,
  SignedDocRoutes,
  FinanceOptionRoutes,
];

const app = angular
  .module("RSF", [
    "RSF.templates",
    "RSF.globals",
    ngResource,
    "ngStorage",
    "ngSanitize",
    "ui.router",
    "ui.bootstrap",
    "ui.sortable",
    "kendo.directives",
    "ngFileUpload",
    "ngMessages",
    ngMap,
    "angulartics",
    "angulartics.rsf.analytics",
    "stripe.checkout",
    "infinite-scroll",
    "focus-if",
    "cfp.hotkeys",
    uiTour,
    "nvd3",
  ])
  .config(config)
  .constant("COUNTRIES", countries)
  .constant("US_STATES", us_states)
  .run(run);

config.$inject = <ReadonlyArray<string>>[
  "$provide",
  "$locationProvider",
  "$stateProvider",
  "$urlRouterProvider",
  "$httpProvider",
  "$analyticsProvider",
  "StripeCheckoutProvider",
  "$qProvider",
  "BaseConfig",
  "$uiRouterProvider",
];

function config(
  $provide: ng.auto.IProvideService,
  $locationProvider: ng.ILocationProvider,
  $stateProvider: ng.ui.IStateProvider,
  $urlRouterProvider: ng.ui.IUrlRouterProvider,
  $httpProvider: ng.IHttpProvider,
  $analyticsProvider: angulartics.IAnalyticsServiceProvider,
  StripeCheckoutProvider: ng.stripe.IStripeCheckoutProvider,
  $qProvider: ng.IQProvider,
  BaseConfig: IBaseConfig,
  $uiRouterProvider: any,
) {
  ($analyticsProvider.settings as any).rsf_analytics_key = BaseConfig.RSF_ANALYTICS_KEY;
  ($analyticsProvider.settings as any).rsf_analytics_url = BaseConfig.RSF_ANALYTICS_URL;
  $analyticsProvider.trackExceptions(true);
  $qProvider.errorOnUnhandledRejections(false);

  $uiRouterProvider.stateService.defaultErrorHandler(_.noop());

  //html5 removes the need for # in URL
  $locationProvider
    .html5Mode({
      enabled: true,
      requireBase: false,
    })
    .hashPrefix("!");

  const resolveAuth = {
    auth: [
      "Session",
      "$q",
      (Session, $q) => {
        if (!Session.hasToken()) {
          return $q.reject({ reason: "unauthorized" });
        }

        return Session.currentUser.$promise;
      },
    ],
  };

  $provide.constant("resolveAuth", resolveAuth);

  if (process.env.NODE_ENV !== "development" && "serviceWorker" in navigator) {
    setupServiceWorker(navigator, window, sessionStorage);
  }

  $urlRouterProvider.otherwise(BaseConfig.DEFAULT_ROUTE_LINK);
  $urlRouterProvider.when("/jobs/{id:[0-9]{1,10}|new}", "/jobs/:id/info");
  $urlRouterProvider.when("/orgs/{id:[0-9]{1,10}}", "/orgs/:id/info");

  //angular-ui-router for multiple view
  $stateProvider.state("root", <ng.ui.IState>{
    abstract: true,
    views: {
      header: {
        templateUrl: "src/Header/header.html",
        controller: "HeaderCtrl",
        controllerAs: "ctrl",
      },
    },
  });

  $httpProvider.interceptors.push(ForbiddenHttpInterceptor.factory);

  if (BaseConfig.ENFORCE_TOS === true) {
    $httpProvider.interceptors.push(TosHttpInterceptor.factory);
  }
  $httpProvider.interceptors.push(OrgActivationHttpInterceptor.factory);
  $httpProvider.interceptors.push(AuthHttpInterceptor.factory);

  _.each(modules, (routes) => {
    //
    routes.configure($stateProvider, resolveAuth);
  });

  StripeCheckoutProvider.defaults(<ng.stripe.IStripeCheckoutConfig>{
    key: BaseConfig.STRIPE_KEY,
  });
}

run.$inject = <ReadonlyArray<string>>[
  "$rootScope",
  "$state",
  "$window",
  "$location",
  "Repository",
  "Session",
  "$transitions",
  "BaseConfig",
  "$uibModalStack",
];
function run(
  $rootScope: RsfRootScope,
  $state: ng.ui.IStateService,
  $window: ng.IWindowService,
  $location: ng.ILocationService,
  Repository: IRepository,
  Session: ISession,
  $transitions: TransitionService,
  BaseConfig: IBaseConfig,
  $uibModalStack: ng.ui.bootstrap.IModalStackService,
) {
  ReactRouter.setup($state);
  StoreRegistry.set("$state", $state);
  StoreRegistry.set("$location", $location);
  StoreRegistry.set("$rootScope", $rootScope);
  StoreRegistry.set("Pretty", new PrettyNameService());
  StoreRegistry.set("Session", Session);
  $transitions.onFinish({}, ({ router }) => {
    Session.stripAuthUser();
  });

  $transitions.onStart({}, (transition) => {
    $rootScope.$broadcast("RSF_StateChange", { transition: transition });
    const fromUrl = $location.url();
    transition.onSuccess(
      {},
      async () => {
        if (fromUrl !== $location.url()) {
          dispatch(push($location.url()));
        }

        if (process.env.NODE_ENV !== "development" && "serviceWorker" in navigator) {
          const regs = await navigator.serviceWorker.getRegistrations();
          regs.forEach((reg) => {
            if (reg.waiting) {
              reg.waiting?.postMessage({ type: "SKIP_WAITING" });
              checkForUpdate(reg.waiting, navigator, window, localStorage);
            } else {
              reg.update();
            }
          });
        }
      },
      { priority: -1 },
    );
  });

  $transitions.onError({}, function (trans: Transition) {
    if (trans.error().detail && trans.error().detail.reason === "unauthorized") {
      $rootScope.$broadcast("auth:logout");
      $rootScope.savedState = trans.$to().name;
      $rootScope.savedParams = trans.$to().params;
      $state.go(StoreRegistry.get<any>("authService").getLoginRoute(), {
        routeTo: trans.to(),
        routeParams: trans.params(),
      });
    }
  });

  window.addEventListener("beforeunload", function (e) {
    const confirmation: any = {};
    const event = $rootScope.$broadcast("onBeforeUnload", confirmation);
    if (event.defaultPrevented) {
      e.returnValue = confirmation["message"];
      return confirmation["message"];
    }
  });

  $window.onunload = function () {
    $rootScope.$broadcast("onUnload");
  };

  $rootScope.underscore = _;
  $rootScope.Humanize = Humanize;
  $rootScope.OrgAclType = OrgAclType;

  $rootScope.moment = moment;
  $rootScope.setAppName = () => {
    return BaseConfig.APP_NAME;
  };
  $rootScope.setCompanyName = () => {
    return BaseConfig.COMPANY_NAME;
  };
  $rootScope.setAppLogoUrl = () => {
    return BaseConfig.APP_LOGO_URL;
  };
  $rootScope.setAppIconUrl = () => {
    return BaseConfig.APP_ICON_URL;
  };
  $rootScope.setFaviconUrl = () => {
    return BaseConfig.FAVICON_FILE;
  };
  $rootScope.$on("$locationChangeStart", () => $uibModalStack.dismissAll());
}

_.each(modules, (routes) => {
  if (routes.load) {
    routes.load(app);
  }
});

app.controller("HeaderCtrl", HeaderCtrl);
app.service("Session", Session);
app.service("Flash", FlashService);
app.service("ConfirmDialog", ConfirmDialogService);
app.service("Conversion", UnitConversionService);
app.service("Pretty", PrettyNameService);
app.service("ToolInfo", ToolInfoService);
app.service("AdService", AdService);
app.service("EstimatorTourService", EstimatorTourService);
app.service("JobInfoTourService", JobInfoTourService);
app.service("JobShowTourService", JobShowTourService);
app.service("Repository", Repository);
app.service("HandlebarsHelperService", HandlebarsHelperService);
app.factory("DirtyWatcher", DirtyWatcher);
app.factory("EventingFactory", EventingService);
app.factory("FileQueueFactory", FileQueueInit);
app.factory("MeasurementImporterFactory", MeasurementImporterFactory);
app.directive("rsfPermissions", RsfPermissions.factory());
app.directive("rsfConfirmationDialog", RsfConfirmationDialog.factory());
app.directive("decimalPlaces", DecimalPlaces.factory());
app.directive("jobAnalytics", JobAnalytics.factory());
app.directive("productColorDropdown", ProductColorDropdown.factory());
app.directive("fakeFormAction", FakeFormAction.factory());
app.directive("embedSrc", EmbedSrc.factory());
app.directive("setFocus", SetFocus.factory());
app.directive("scrollIf", ScrollIf.factory());
app.directive("sortableTab", RsfTab.factory());
app.directive("uiSref", ["$timeout", RsfSref]);
app.component("rsfSpinner", new SpinnerComponent());
app.component("toolDisplay", new ToolDisplayComponent());
app.component("rsfDrawing", new DrawingComponent());
app.component("refreshButton", new RefreshButtonComponent());
app.component("gmapsAutocomplete", new GmapsAutocompleteComponent());
app.component("leafletMap", new LeafletMapComponent());
app.component("rsfMap", new RsfMapComponent());
app.filter("humanize", HumanizeFilter.factory);
app.filter("startFrom", StartFromFilter.factory);
app.constant("valid_uom", valid_uom);
app.constant("valid_kind", valid_kind);
app.constant("US_STATES_ABBREV", us_states_abbreviation);
app.constant("COUNTRIES_ABBREV", countries_abbreviation);

app.component("learnMoreAds", react2angular(LearnMoreAds, ["index", "controller"]));
app.component("pdfDisplay", react2angular(PdfDisplay, ["pdfUrl", "disabledFeatures"]));
app.component("saveablePdfDisplay", react2angular(SaveablePdfDisplay, ["job", "pdfUrl", "disabledFeatures"]));
app.component("flashAlert", react2angular(FlashAlert));
app.component("inviteModal", react2angular(InviteModal));
app.component("screenShare", react2angular(ScreenShare, null, ["$scope", "ScreenShareService", "$state"]));
app.component("notifer", react2angular(Notifier));
app.component("reactSelectWrapper", react2angular(ReactSelectWrapper, ["defaultValue", "available", "onChange"]));
app.component("screenShareToolbar", react2angular(Toolbar));
app.component("orgTabs", react2angular(OrgTabs));
app.component("jobTabs", react2angular(JobTabs));
app.component("connector", react2angular(Connector));
app.component("documentLink", react2angular(NgDocumentLink, ["document", "iconOnly"]));
app.component("pageTitleUpdater", react2angular(PageTitleUpdater));

app.component(
  "userHeaderInfo",
  react2angular(
    UserHeaderInfo,
    ["refreshQuery", "navDisplay", "detailed"],
    ["$state", "$rootScope", "JobInfoTourService", "JobShowTourService"],
  ),
);

export default app;
