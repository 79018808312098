import * as React from "react";

export const ActionButtonToggle = React.forwardRef(({ onClick }: any, ref: React.RefObject<any>) => (
  <a
    href=""
    ref={ref}
    data-testid="action-button-toggle"
    className="action-button"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    <i className="rsf-ellipsis-v-link rsf-base-66"></i>
  </a>
));
