import { IScreenShare, IScreenShareResource } from "app/src/Models/ScreenShare";
import { IScreenLeapEvent } from "app/src/ScreenShare/ScreenLeapEvent";
import { IBaseConfig } from "../Common/IBaseConfig";

export class ScreenShareShowCtrl {
  public screenShare: IScreenShare;
  public viewerUrl: string;
  public showScreenShare = false;

  private timeoutInterval = 5000;
  private retries = 12;
  private _eventListener;

  public static $inject = [
    "ScreenShare",
    "$stateParams",
    "$sce",
    "$timeout",
    "$q",
    "$window",
    "$location",
    "BaseConfig",
  ];
  constructor(
    public ScreenShare: IScreenShareResource,
    public $stateParams: ng.ui.IStateParamsService,
    public $sce: ng.ISCEService,
    public $timeout: ng.ITimeoutService,
    public $q: ng.IQService,
    public $window: ng.IWindowService,
    private $location: ng.ILocationService,
    private BaseConfig: IBaseConfig,
  ) {
    if ($window.location.protocol === "https") {
      $window.location.href = BaseConfig.CUSTOMER_URL + $location.path();
      return;
    }

    this.loadScreenShare();

    this._eventListener = (e) => {
      this.handleEvents(this.screenShare, e);
    };

    $window.addEventListener("message", this._eventListener);
  }

  public refreshScreenShare() {
    this.retries = 12;
    this.loadScreenShare();
  }

  public handleEvents(screenshare: IScreenShare, event: IScreenLeapEvent) {
    if (event.data === "SCREEN_SHARE_ENDED") {
      if (screenshare.redirect_on_end_url) {
        if (screenshare.redirect_on_end_url.indexOf("http") === 0) {
          window.location.href = screenshare.redirect_on_end_url;
        }
      }
    }
  }

  private loadScreenShare() {
    this.ScreenShare.landing(
      { key: this.$stateParams["id"] },
      (data: IScreenShare) => {
        this.screenShare = data;

        if (this.screenShare.kind === "basic") {
          this.viewerUrl = this.$sce.trustAsResourceUrl(this.screenShare.metadata.viewerUrl);
        }
        this.showScreenShare = true;
      },
      () => {
        this.showScreenShare = false;
        this.$timeout(() => {
          if (this.retries > 0) {
            this.retries -= 1;
            this.loadScreenShare();
          }
        }, this.timeoutInterval);
      },
    );
  }
}
