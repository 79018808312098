import { SignedDocumentRecord } from "../records/SignedDocument";
import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const currentSignedDocumentId = (state: RootState): number => state.getIn(["signedDocuments", "currentId"]);
export const byId = (state: RootState): Map<number, SignedDocumentRecord> => state.getIn(["signedDocuments", "byId"]);

/**
 * Returns a signed document with documentId
 *
 * @param {RootState} state The RootState
 * @param {number} documentId
 * @returns {SignedDocumentRecord} SignedDocumentRecord
 */
export const signedDocument = createSelector(
  [byId, (state, props) => ({ state, props })],
  (byId: Map<number, SignedDocumentRecord>, stateProps: any): SignedDocumentRecord => byId.get(stateProps.props),
);

/**
 * Returns a signed document based on signedDocuments -> currentId
 *
 * @param {RootState} state The RootState
 * @param {number} documentId
 * @returns {SignedDocumentRecord} SignedDocumentRecord
 */
export const currentSignedDocument = createSelector(
  [byId, currentSignedDocumentId],
  (byId: Map<number, SignedDocumentRecord>, id: number): SignedDocumentRecord => byId.get(id),
);

export const currentSignedDocumentKind = createSelector([currentSignedDocument], (sd: SignedDocumentRecord) =>
  sd.estimate_id ? "contract" : "signableDocument",
);
