import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import Spinner from "app2/src/components/SpinnerComponent";
import ProcessSelector from "app2/src/components/Process/ProcessSelector";
import ProcessList from "app2/src/components/Process/ProcessList";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";

const mapStateToProps = (state: RootState, ownProps: ProcessModalProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ProcessModalProps) => {
  return {
    addFlashMessage: (level: FlashLevels, message: string) =>
      dispatch(commonActions.Actions.flashAddAlert(level, message)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ProcessModalProps {
  object: any;
  processes: any;
  process(id: number, params: any): void;
  onClose(): void;
}

interface ProcessModalState {
  open: boolean;
  list: boolean;
  formData: any;
  processKey: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ProcessModalProps;

class ProcessModal extends React.Component<Props, ProcessModalState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      processKey: "",
      list: true,
      formData: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.cancel = this.cancel.bind(this);
    this.select = this.select.bind(this);
  }

  public componentDidUpdate(prevProps) {
    if (!_.isNull(this.props.object) && _.isNull(prevProps.object)) {
      this.setState({ open: true });
    }
  }

  public select(processKey: string) {
    this.setState({
      list: false,
      processKey: processKey,
    });
  }

  public onChange(feedback: any) {
    this.setState({ formData: feedback.formData });
  }

  public onSubmit(feedback: any) {
    const { process, object } = this.props;
    const params = {
      process_params: feedback.formData,
      process: this.state.processKey,
    };
    process(object.id, params);
    this.setState({
      open: false,
      list: true,
      formData: null,
    });
    this.props.addFlashMessage(FlashLevels.success, "Process submitted successfully.");
    this.props.onClose();
  }

  public onError(feedback: any) {
    this.props.addFlashMessage(FlashLevels.danger, "There was an error submiting the process. Please try again.");
  }

  public cancel() {
    this.setState({
      open: false,
      list: true,
      formData: null,
    });
    this.props.onClose();
  }

  public render() {
    const { list, open, formData, processKey } = this.state;
    const { processes } = this.props;

    if (processes.loading) {
      return <Spinner localProperty={processes.loading} />;
    }

    return (
      <Modal show={open} onHide={this.cancel} size="lg" className="process-modal">
        {list ? (
          <ProcessList processes={processes} select={this.select} />
        ) : (
          <ProcessSelector
            processes={processes}
            formData={formData}
            processKey={processKey}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            onError={this.onError}
          />
        )}
        <Modal.Footer>
          <Button variant="cancel" onClick={this.cancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connector(ProcessModal);
