import { RootState } from "app2/src/reducers";
import { UserRecord } from "app2/src/records/UserRecord";
import { model } from "app2/src/reducers/user.reducer";

/**
 * Loads the current logged in user
 *
 * @param {RootState} state The RootState
 * @param {{}} options
 * @returns {UserRecord} UserRecord
 */
export const currentUser = (state: RootState): UserRecord => state.getIn([model, "currentUser"]);

/**
 * Loads the user from byID
 *
 * @param {RootState} state The RootState
 * @param {{userId: number}} options
 * @returns {UserRecord} UserRecord
 */
export const user = (state: RootState, props: any): UserRecord => state.getIn([model, "usersById", props.userId]);
