import { AddressRecord } from "../records/Address";

export const isAddressValid = (address: AddressRecord): boolean =>
  Boolean(!_.isEmpty(address.line_1?.trim()) && !_.isEmpty(address.city?.trim()) && !_.isEmpty(address.state?.trim()));

export const isValidLatLon = (address: AddressRecord): boolean => {
  if (address?.lat && address?.lon) {
    return address.lat >= -90 && address.lat <= 90 && address.lon >= -180 && address.lon <= 180;
  }

  return false;
};

export const zillowAddress = (address: AddressRecord) => {
  let addr = "";

  if (address.line_1) {
    addr = addr + "-" + address.line_1.split(" ").join("-");
    addr = addr.split(",").join("");
  }
  if (address.line_2) {
    addr = addr + "-" + address.line_2;
  }
  if (address.city) {
    addr = addr + "-" + address.city;
  }
  if (address.state) {
    addr = addr + "-" + address.state;
  }
  if (address.postal_code) {
    addr = addr + "-" + address.postal_code;
  }

  if (addr !== "") {
    addr = addr + "_rb";
  }

  return addr.trim().substr(1);
};
