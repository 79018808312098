import * as React from "react";
import { Button, InputGroup, FormControl, Form } from "react-bootstrap";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { connect, ConnectedProps } from "app2/src/connect";
import track from "react-tracking";
import { RootDispatchType } from "app2/src/store";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as FontAwesome from "react-fontawesome";

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    flashAddAlert: (msg: string) => dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, msg)),
  };
};

const connector = connect(null, mapDispatchToProps);

interface InputCopyState {}

interface InputCopyProps {
  value: string;
  label?: string;
  id?: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & InputCopyProps;

@track((props: Props) => {
  return {
    component: "InputCopy",
    value: props.value,
  };
})
class InputCopy extends React.Component<Props, InputCopyState> {
  constructor(props: Props) {
    super(props);

    this.copyInput = this.copyInput.bind(this);
  }

  @track(() => ({
    action: "copy input",
  }))
  public copyInput() {
    const { value, flashAddAlert } = this.props;
    navigator.clipboard.writeText(value);
    flashAddAlert(`Copied - ${value}`);
  }

  public render() {
    const { value, label, id } = this.props;
    const nameId = id || "inputCopy";
    return (
      <>
        {label === "" ? null : (
          <Form.Label htmlFor={nameId} className="p-copy">
            {label}
          </Form.Label>
        )}
        <InputGroup>
          <FormControl aria-label="input" id={nameId} defaultValue={value} />
          <Button aria-label="copy" variant="secondary" onClick={this.copyInput}>
            <FontAwesome name="clone" />
          </Button>
        </InputGroup>
      </>
    );
  }
}

export default connector(InputCopy);
