import { ISession } from "app/src/Common/SessionService";
import { IUser, IUserResource } from "app/src/Models/User";
import * as angulartics from "angulartics";
import { IBaseConfig } from "../Common/IBaseConfig";
import { embeddedService, IEmbeddedEvent } from "app2/src/services/embedded.service";
import { dispatch } from "app2/src/storeRegistry";
import * as authActions from "app2/src/reducers/auth.actions";
import { emailRegex } from "app2/src/helpers/InputValidator";

export class LoginCtrl {
  public user: IUser;
  public errors: string[];
  public goToRoute: string;
  public goToParams: any;
  public invalidRoutes: string[] = ["root.logout"];
  public emailPattern = emailRegex;

  public static $inject = [
    "$state",
    "$scope",
    "Session",
    "User",
    "$window",
    "$analytics",
    "BaseConfig",
    "$stateParams",
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    private Session: ISession,
    private User: IUserResource,
    private $window: ng.IWindowService,
    public $analytics: angulartics.IAnalyticsService,
    public BaseConfig: IBaseConfig,
    public $stateParams: ng.ui.IStateParamsService,
  ) {
    this.user = new User();

    this.receiveMessage = this.receiveMessage.bind(this);
    this.goToRoute = this.BaseConfig.DEFAULT_ROUTE;
    this.goToParams = {};

    if (
      $stateParams.routeTo &&
      $stateParams.routeTo.name &&
      !_.include(this.invalidRoutes, $stateParams.routeTo.name)
    ) {
      this.goToRoute = $stateParams.routeTo.name;
      this.goToParams = $stateParams.routeParams;
    }

    embeddedService.addListener(this.receiveMessage);

    $scope.$on("$destroy", () => {
      embeddedService.removeListener(this.receiveMessage);
    });

    Session.currentUser.$promise.then(() => {
      this.goToWithRedirect();
    });

    embeddedService.sendEvent("RSF.loaded");
  }

  public receiveMessage(event: IEmbeddedEvent) {
    switch (event.event) {
      case "RSF.auth":
        this.setAuth(event.payload);
        this.goTo(event.payload.user);
        break;
    }
    this.$scope.$digest();
  }

  public login() {
    this.Session.login(this.user.email, this.user.password)
      .then(
        (user: IUser) => {
          this.$analytics.eventTrack("login", {
            category: "User",
            label: "authorization",
            userId: user.id,
            user: user.id,
            org: user.org.id,
          });
          this.goToWithRedirect();
        },
        (error) => {
          this.$analytics.eventTrack("failed-login", {
            category: "User",
            email: this.user.email,
            label: "authorization",
          });
          this.errors = error.data.errors.user;
        },
      )
      .catch((e) => console.error(e));
  }

  protected goTo(user: any) {
    if (this.goToRoute) {
      this.$analytics.eventTrack("embeddedLogin", {
        category: "User",
        label: "authorization",
        userId: user.id,
        user: user.id,
        org: user.org.id,
      });
      this.$state.go(this.goToRoute, this.goToParams);
    }
  }

  protected goToWithRedirect() {
    if (this.$stateParams.redirect && (this.$stateParams.redirect as string).startsWith("http")) {
      let redirect = this.$stateParams.redirect;
      if (this.BaseConfig.UNSECURE_AUTH) {
        redirect = `${redirect}?token=${this.Session.getToken()}&refresh=${this.Session.getRefreshToken()}&email=${
          this.Session.currentUser.email
        }&id=${this.Session.currentUser.id}`;
      }
      this.$window.location.href = redirect as any;
    } else {
      this.$state.go(this.goToRoute, this.goToParams);
    }
  }

  protected setAuth(auth: any) {
    return dispatch(authActions.AsyncActions.fromData(auth.authToken, auth.user, auth.refreshToken));
  }
}
