import { selectedEstimateId } from "app2/src/selectors/job.selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncActions } from "app2/src/reducers/estimate.actions";
import { useTracking } from "react-tracking";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import { EmailMessageArgs, EmailMessageModal } from "app2/src/components/Common/EmailMessageModal";
import { currentOrgPrefConfig } from "app2/src/selectors/org.selectors";
import { RootState } from "app2/src/reducers";

export const EmailOrderCsvModal: React.FC = () => {
  // Selectors
  const estimateId = useSelector(selectedEstimateId);
  const path = ["estimator", "order_csv_emails"];
  const initEmails = useSelector((state: RootState) => currentOrgPrefConfig(state, { path })) || "";

  // Hooks
  const dispatch = useDispatch();
  const { goBack } = usePreviousPath();
  const { trackEvent } = useTracking<any>({ category: "Estimate", estimate: estimateId });

  // Methods
  const close = () => {
    goBack("/email_order_csv");
  };

  const emailEstimate = (args: EmailMessageArgs) => {
    trackEvent({ action: "email order csv sent" });
    dispatch(AsyncActions.emailEstimate({ kind: "email_csv", csv_type: "order", ...args }));
    close();
  };

  const cancel = () => {
    trackEvent({ action: "email order csv canceled" });
    close();
  };

  return (
    <EmailMessageModal
      cancel={cancel}
      initEmail={initEmails}
      emailMessage={emailEstimate}
      title="Email Document (Order CSV)"
    />
  );
};
