import MakePaymentButton from "app2/src/components/PaymentsModal/MakePaymentButton";
import { RootState } from "app2/src/reducers";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { JobTabTitle } from "../../JobTabTitle";
import { SignatureSetupModal } from "./SignatureSetupModal";
import { estimate as estimateSelector } from "app2/src/selectors/estimate.selectors";
import { currentJob, selectedEstimateId } from "app2/src/selectors/job.selectors";
import SaveablePdfDisplay from "app2/src/components/Pdf/SaveablePdfDisplay";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { push } from "connected-react-router/immutable";
import { pathname } from "app2/src/selectors/router.selectors";
import { Route, Switch, useRouteMatch } from "react-router";
import { contractTitle } from "app2/src/selectors/org.selectors";
import { EmailDocumentModal } from "app2/src/components/Document/EmailDocumentModal";
import { IntegrationsButtons } from "app2/src/components/Estimate/IntegrationsButtons";
import { PaymentsModal } from "app2/src/components/PaymentsModal";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import * as signedDocumentActions from "app2/src/reducers/signedDocument.actions";
import { AsyncActions as DocumentAsyncActions } from "app2/src/reducers/document.actions";
import { useTracking } from "react-tracking";
import { JobRecord } from "app2/src/records/Job";
import { EstimateRecord } from "app2/src/records/Estimate";
import { document as documentSelector } from "app2/src/selectors/document.selectors";
import { DocumentRecord } from "app2/src/records/Document";

export const CreateContract: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch<{ estimateId: string }>();
  const { trackEvent } = useTracking<any>({
    category: "Contract",
  });

  // Selectors
  const path = useSelector(pathname);
  const estimateId = useSelector(selectedEstimateId);
  const estimate: EstimateRecord = useSelector((state: RootState) => estimateSelector(state, { estimateId }));
  const job: JobRecord = useSelector(currentJob);
  const title = useSelector(contractTitle);
  const document = useSelector((state: RootState) =>
    documentSelector(state, { documentId: estimate?.get("tempDocumentId") }),
  );
  const documentValueRef = React.useRef<DocumentRecord>();

  // Methods
  const email = () => {
    trackEvent({ action: "email contract" });
    dispatch(push(`${path}/email`));
  };
  const sign = async () => {
    trackEvent({ action: "sign contract" });
    dispatch(push(`${path}/signature`));
  };
  const cancel = () => {
    trackEvent({ action: "cancel sign contract" });
    dispatch(push(path.split("/view_pdf/new")[0], { noRedirect: true }));
  };
  const resetSignedDocument = () => {
    const document = documentValueRef.current;
    if (!document?.signed_document) {
      dispatch(DocumentAsyncActions.deleteDocument(document));
    }
    dispatch(signedDocumentActions.Actions.setCurrentSignedDocumentId(-1));
    dispatch(signedDocumentActions.Actions.receiveDocument({ id: -1 }));
    dispatch(estimateActions.Actions.removeEstimatePdf(estimateId));
  };

  // Lifecycle
  React.useEffect(() => {
    dispatch(estimateActions.AsyncActions.getEstimatePdf(Number(match.params.estimateId), "contract", true));
    return resetSignedDocument;
  }, []);

  React.useEffect(() => {
    documentValueRef.current = document;
  }, [document]);

  return (
    <>
      <SpinnerComponent localProperty={estimate?.get("loading") || document?.get("loading")} />
      <JobTabTitle title={title}>
        <div className="pull-right">
          <IntegrationsButtons estimateId={estimateId} documentType="contract" />
          <Button onClick={email}>Email {title}</Button>
          <MakePaymentButton />
          <Button onClick={sign}>Signature</Button>
          <Button onClick={cancel}>Cancel</Button>
        </div>
      </JobTabTitle>
      <SaveablePdfDisplay pdfUrl={document?.get("base64")} job={job} disabledFeatures={["Annotations"]} />
      {document && (
        <Switch>
          <Route path={`${match.url}/signature`} component={SignatureSetupModal} />
          <Route path={`${match.url}/email`} render={() => <EmailDocumentModal documentType="contract" />} />
          <Route path={`${match.url}/payments`} component={PaymentsModal} />
        </Switch>
      )}
    </>
  );
};
