import { pathname } from "app2/src/selectors/router.selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "react-global-configuration";
import { connectFC } from "app2/src/connect";
import { pageTitle as pageTitleSelector } from "app2/src/selectors/components/common.selectors";
import { Actions } from "app2/src/reducers/components/common.actions";

export const PageTitleUpdater: React.FC = () => {
  const dispatch = useDispatch();
  const path = useSelector(pathname);
  const pageTitle = useSelector(pageTitleSelector);

  const updateTitle = (pageTitle: string) => {
    document.title = pageTitle;
  };

  const debouncedUpdateTitle = React.useCallback(_.debounce(updateTitle, 100), []);

  React.useEffect(() => {
    debouncedUpdateTitle(pageTitle || config.get("APP_NAME_SHORT"));
  }, [pageTitle]);

  React.useEffect(() => {
    dispatch(Actions.setPageTitle(""));
  }, [path]);

  return <></>;
};

export default connectFC(PageTitleUpdater);
