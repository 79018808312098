import { RootState } from "app2/src/reducers";

/**
 * Fetches the query map for for the router
 *
 * @param {RootState} state
 * @returns {Map}
 */
export const query = (state: RootState, _props?: any) => state.getIn(["router", "location", "query"]);

/**
 * Fetches the a value from the query map and parses as integer
 *
 * @param {RootState} state
 * @param {{queryKey: string }} props
 * @returns {number}
 */
export const queryNumber = (state: RootState, props: any) => {
  let value = query(state).get(props.queryKey);
  if (value) {
    value = parseInt(value);
  }
  return value;
};

/**
 * Fetches a value from the query map
 *
 * @param {RootState} state
 * @param {{queryKey: string }} props
 * @returns {string}
 */
export const queryString = (state: RootState, props: any) => query(state).get(props.queryKey);

/**
 * Fetches the location pathname for the router
 *
 * @param {RootState} state
 * @returns {string}
 */
export const pathname = (state: RootState, _props?: any) => state.getIn(["router", "location", "pathname"]);

/**
 * Fetches the location state for the router
 *
 * @param {RootState} state
 * @returns {string}
 */
export const locationState = (state: RootState, _props?: any): Map<any, any> | undefined =>
  state.getIn(["router", "location", "state"]);
