import * as React from "react";
import { useEffect } from "react";
import { Card, Row, Col, Container, Button, ButtonGroup } from "react-bootstrap";
import { JobAttributeModal } from "app2/src/components/OrgTabs/JobAttributeTab/JobAttributeModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { currentOrgId, currentOrg, orgLoading } from "app2/src/selectors/org.selectors";
import * as orgActions from "app2/src/reducers/org.actions";
import * as jobAttributeActions from "app2/src/reducers/jobAttribute.actions";
import Spinner from "app2/src/components/SpinnerComponent";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { JobAttributeTypes, orgJobAttributeLinkIdName } from "app2/src/records/OrgRecord";
import { JobAttributeLinkRecordType, JobAttributeRecordType, sortJobAttributes } from "app2/src/records/JobAttribute";
import { JobAttributeListItem } from "./JobAttributeListItem";
import {
  activeJobAttributeLinksByTypeAndOrgId,
  availableJobAttributeIdsByTypeAndOrgId,
  jobAttributesByTypeAndId,
  loadingByTypeAndOrgId,
} from "app2/src/selectors/jobAttribute.selectors";
import { Route, Switch, useRouteMatch } from "react-router";
import { push, replace } from "connected-react-router/immutable";
import { pathname } from "app2/src/selectors/router.selectors";
import { Acl } from "app2/src/helpers/Acl";

export const JobAttributeTab: React.FC<{}> = () => {
  // Hooks
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const route = useRouteMatch<{ attribute: JobAttributeTypes }>(`${match.url}/:attribute`);
  const jobAttrType = route?.params?.attribute;

  const orgId = useSelector(currentOrgId);
  const loadingOrg = useSelector(orgLoading);
  const loading = useSelector((state) => loadingByTypeAndOrgId(state, { orgId, jobAttrType }));
  const path = useSelector(pathname);
  const jobAttrsById = useSelector((s: RootState) => jobAttributesByTypeAndId(s, { jobAttrType }));
  const activeJobAttributeLinks = useSelector((s: RootState) =>
    activeJobAttributeLinksByTypeAndOrgId(s, { orgId: orgId, jobAttrType: JobAttributeTypes[jobAttrType] }),
  );
  const availableJobAttributeIds = useSelector((s: RootState) =>
    availableJobAttributeIdsByTypeAndOrgId(s, { orgId: orgId, jobAttrType: JobAttributeTypes[jobAttrType] }),
  );

  // Callbacks
  const showModal = () => dispatch(push(path + "/add"));
  const changeJobAttrTypeTab = (type: JobAttributeTypes) => dispatch(push(`/orgs/${orgId}/job_attributes/${type}`));
  const updateSortedRecord = (
    jobAttrRecord: JobAttributeRecordType,
    jobAttrLinkRecord: JobAttributeLinkRecordType,
    showFlash: boolean,
  ) => {
    dispatch(
      jobAttributeActions.AsyncActions.updateJobAttribute(
        orgId,
        jobAttrRecord,
        jobAttrLinkRecord,
        jobAttrType,
        showFlash,
      ),
    );
  };

  const sortJobAttrs = (result: any) =>
    sortJobAttributes(result, activeJobAttributeLinks, jobAttrsById, updateSortedRecord, jobAttrType);

  //Lifecycle
  useEffect(() => {
    if (!jobAttrType || !JobAttributeTypes[jobAttrType]) {
      dispatch(replace(`/orgs/${orgId}/job_attributes/job_type`));
    } else if (orgId) {
      dispatch(jobAttributeActions.AsyncActions.getJobAttributes(orgId, JobAttributeTypes[jobAttrType]));
    }
  }, [orgId, dispatch, jobAttrType]);

  return loadingOrg || !jobAttrType ? (
    <Spinner localProperty={true} />
  ) : (
    <Container className="job-object-list">
      <Row data-testid="documentTab">
        <SpinnerComponent localProperty={loading} />
        <Col>
          <Row className="documents title-row sticky-job-title">
            <Col sm={4}>
              <ButtonGroup>
                <Button
                  variant={jobAttrType === "job_type" ? "primary" : "light"}
                  onClick={() => changeJobAttrTypeTab(JobAttributeTypes.job_type)}>
                  Job Types
                </Button>
                <Button
                  variant={jobAttrType === "level" ? "primary" : "light"}
                  onClick={() => changeJobAttrTypeTab(JobAttributeTypes.level)}>
                  Levels
                </Button>
                <Button
                  variant={jobAttrType === "location" ? "primary" : "light"}
                  onClick={() => changeJobAttrTypeTab(JobAttributeTypes.location)}>
                  Locations
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Active {_.toTitleCase(jobAttrType)}s</h3>
        </Col>
      </Row>
      <Card>
        <Row>
          <DragDropContext onDragEnd={sortJobAttrs}>
            {activeJobAttributeLinks.size === 0 && (
              <Col md={12} className="text-center">
                <h2>No {_.toTitleCase(jobAttrType)}s added for org.</h2>
              </Col>
            )}
            <Col md={12}>
              <Row className="table-header width-100">
                <Col md={1} xs={1} />
                <Col md={5} xs={6}>
                  Name
                </Col>
                <Col md={4}>{jobAttrType === "job_type" && "Description"}</Col>
                <Col md={2} xs={2} className="justify-end">
                  {Acl.can("update", "org") && <Button onClick={showModal}>Add</Button>}
                </Col>
              </Row>
              <Droppable droppableId="active">
                {(provided) => (
                  <Row ref={provided.innerRef}>
                    <Col md={12}>
                      {activeJobAttributeLinks.map((link: JobAttributeLinkRecordType, idx: number) => (
                        <Draggable key={link.get("id", idx)} draggableId={link.get("id", idx).toString()} index={idx}>
                          {(provided, snapshot) => {
                            return (
                              <JobAttributeListItem
                                innerRef={provided.innerRef}
                                provided={provided}
                                isDragging={snapshot.isDragging}
                                jobAttributeId={link.get(orgJobAttributeLinkIdName(jobAttrType), "")}
                                activeItem={true}
                                attribute={jobAttrType}
                              />
                            );
                          }}
                        </Draggable>
                      ))}
                    </Col>
                    {provided.placeholder}
                  </Row>
                )}
              </Droppable>
            </Col>
          </DragDropContext>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <Row>
              <Col>
                <h3>Available {_.toTitleCase(jobAttrType)}s</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Row className="table-header width-100">
                  <Col md={1} xs={1} />
                  <Col md={jobAttrType === "job_type" ? 5 : 9} xs={5}>
                    Name
                  </Col>
                  {jobAttrType === "job_type" && <Col md={4}>Description</Col>}
                  <Col md={2} xs={2} />
                </Row>
                {availableJobAttributeIds.map((jobAttributeId: string | number, idx: number) => (
                  <JobAttributeListItem
                    key={idx}
                    jobAttributeId={jobAttributeId}
                    activeItem={false}
                    attribute={jobAttrType}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Switch>
        <Route path={`${match.url}/:attribute/:method/:id`} component={JobAttributeModal} />
        <Route path={`${match.url}/:attribute/:method`} component={JobAttributeModal} />
      </Switch>
    </Container>
  );
};
