import { RootState } from "app2/src/reducers";
import { settingsConfig } from "app2/src/selectors/org.selectors";
import { currentUser } from "app2/src/selectors/userCommon.selectors";
import * as React from "react";
import { useSelector } from "react-redux";

export const SystemNotes: React.FC = () => {
  const user = useSelector(currentUser);
  const url = useSelector((state: RootState) =>
    settingsConfig(state, { orgId: user?.org_id, path: ["dashboard", "communication_url"] }),
  );

  if (!url) {
    return null;
  }
  return (
    <div className="system-notes">
      <iframe src={url} title="SystemNotes component"></iframe>
    </div>
  );
};
