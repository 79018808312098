import { IMeasurement } from "app/src/Models/Measurement";

export interface IPoolMetadata extends IPool {
  quantity: number;
}

export interface IPool {
  id: number;
  name: string;
  designator: string;
  area: number;
  perimeter: number;
  volume: number;
  average_depth: number;
  metadata: any;
  created_at: Date;
  updated_at: Date;

  _destroy?: boolean;
}

export class Pool implements IPool {
  public id: number;
  public name: string;
  public designator: string;
  public area: number;
  public perimeter: number;
  public volume: number;
  public average_depth: number;
  public metadata: any;
  public created_at: Date;
  public updated_at: Date;

  constructor() {}

  public static fromJSON = (data: any): IPool => {
    const pool = new Pool();

    Pool.copyValues(pool, data);
    pool["kind"] = "Pool";
    return pool;
  };

  public static clone(pool: IPool): IPool {
    const clone = new Pool();
    Pool.copyValues(clone, pool);
    return clone;
  }

  public static addPools(measurement: IMeasurement, pool: IPoolMetadata) {
    if (!pool.quantity || pool.quantity <= 1) {
      this._addPools(measurement, pool);
    } else {
      for (let i = 0; i < pool.quantity; i++) {
        let index = i + 1;
        while (_.any(measurement["pool"], (r: IPool) => r.name === pool.name + "-" + index)) {
          index += 1;
        }
        const clone = JSON.parse(JSON.stringify(pool));
        clone.name = pool.name + "-" + index;
        this._addPools(measurement, clone);
      }
    }
  }

  public static copyValues(pool: IPool, data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !(key.charAt(0) === "$" && key.charAt(1) === "$")) {
        switch (key) {
          case "id":
            pool[key] = !_.isNaN(parseInt(data[key])) ? parseInt(data[key]) : data[key];
            break;
          case "area":
          case "perimeter":
          case "volume":
          case "average_depth":
            pool[key] = parseFloat(data[key]);
            break;
          case "updated_at":
          case "created_at":
            pool[key] = new Date(data[key]);
            break;
          default:
            pool[key] = data[key];
            break;
        }
      }
    }
  }

  private static _addPools(measurement: IMeasurement, pool: IPool) {
    if (pool.id) {
      const index = _.findIndex(measurement.pools, (r) => {
        return r.id === pool.id;
      });
      measurement.pools[index] = pool;
    } else {
      pool.id = measurement.getId();
      measurement.pools.push(pool);
    }
  }
}
