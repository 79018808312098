import { fetcher } from "../helpers/Fetcher";
import { IOrgData, OrgRecord, toJSON } from "app2/src/records/OrgRecord";
import { StoreRegistry, dispatch, useState } from "app2/src/storeRegistry";
import * as orgActions from "app2/src/reducers/org.actions";
import { QueryParamsRecord } from "app2/src/records/Page";
import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { Actions as commonActions } from "app2/src/reducers/components/common.actions";
import { LocationStateRecord } from "../records/LocationState";
import { listParams } from "app2/src/api/Api";
import { ITaskData } from "app2/src/records/Task";
import { ITokenData } from "app2/src/records/Token";

export interface ILoginData {
  token: string;
  refresh_token: string;
  org: IOrgData;
}

export interface IOrgResponse {
  org: IOrgData;
}

export interface IOrgMetadata {
  pagination: IPagingMetadata;
}
export interface IOrgIndexResponse {
  orgs: IOrgData[];
  meta: IOrgMetadata;
}

export interface ISyncParams {
  integration: string;
  process: string;
}

export interface ITestParams {
  integration: string;
  token: ITokenData;
}

class OrgService {
  public orgIncludes: string[] = ["address", "settings", "preferences", "contract_languages", "emails", "lead_sources"];
  public url = "/api/v1/orgs/";

  public load(id: number, include: string[] = this.orgIncludes): Promise<IOrgData> {
    let actionUrl = fetcher.joinUrls(this.url, id.toString());

    if (include.length > 0) {
      actionUrl = fetcher.urlOptions(actionUrl, {
        "include[]": include,
      });
    }
    return fetcher.get<{ org: IOrgData }>(actionUrl).then(
      (data) => {
        data.org.include = include;
        return data.org;
      },
      (response) => {
        if (response.status === 403) {
          const $state = StoreRegistry.get<ng.ui.IStateService>("$state");
          const $location = StoreRegistry.get<ng.ILocationService>("$location");
          dispatch(
            commonActions.setSavedLocationState(
              new LocationStateRecord({
                pathname: $location.path(),
                search: $location.search(),
                hash: $location.hash(),
              }),
            ),
          );
          $state.go("root.permissions");
        }

        throw response;
      },
    );
  }

  public list(queryParams: QueryParamsRecord = new QueryParamsRecord()): Promise<IOrgIndexResponse> {
    let actionUrl = this.url;
    const params = { ...listParams(queryParams), ...queryParams.get("parameters")?.toJS() };
    delete params.parameters;

    params.sort_by = "name";

    actionUrl = fetcher.urlOptions(actionUrl, params);
    return fetcher.get<IOrgIndexResponse>(actionUrl);
  }

  public update(org: OrgRecord): Promise<IOrgData> {
    const actionUrl = fetcher.joinUrls(this.url, org.id.toString());

    const orgPostData = toJSON(org);

    return fetcher
      .patch<IOrgResponse>(actionUrl, {
        org: orgPostData,
        include: org.include,
      })
      .then(
        (response) => {
          response.org.include = org.include;
          return response.org;
        },
        (response) => {
          if (response.status === 500) {
            return Promise.reject(["Internal Server Error"]);
          }

          if (_.isArray(response.data.errors)) {
            return Promise.reject(response.data.errors);
          }

          return Promise.reject(["Unknown Error"]);
        },
      );
  }

  public getOrg(id: number, set_current_org = false): Promise<any> {
    return dispatch(orgActions.AsyncActions.getOrg(id, this.orgIncludes, set_current_org));
  }

  public setCurrentOrgId(id: number): void {
    dispatch(orgActions.Actions.setCurrentOrgId(id));
  }

  public updateOrg(org: OrgRecord): Promise<any> {
    return dispatch(orgActions.AsyncActions.updateOrg(org));
  }

  public getStoredOrg(id: number): OrgRecord {
    return useState().getIn(["orgs", "orgsById", id]);
  }

  public async sync(orgId: number, params: ISyncParams) {
    const actionUrl = fetcher.joinUrls(this.url, orgId.toString(), "sync", params.integration);
    await fetcher.post(actionUrl, params);
  }

  public test(orgId: number, params: ITestParams): Promise<{ task: ITaskData }> {
    const actionUrl = fetcher.joinUrls(this.url, orgId.toString(), "test", params.integration);
    return fetcher.post(actionUrl, params);
  }

  public duplicate(targetId: number, source_org_id: number, data_types: string[]): Promise<{ task: ITaskData }> {
    const actionUrl = fetcher.joinUrls(this.url, targetId.toString(), "duplicate");
    return fetcher.patch(actionUrl, { source_org_id, data_types });
  }
}

export const orgService = new OrgService();
