export class HumanizeFilter {
  public static factory() {
    const factoryFunction = (text) => {
      if (text) {
        let result = text.split("_").join(" ").toLowerCase();
        result = result.charAt(0).toUpperCase() + result.slice(1);
        return result;
      }
    };

    factoryFunction.$inject = [];

    return factoryFunction;
  }
}
