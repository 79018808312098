import { currentOrgId } from "app2/src/selectors/org.selectors";
import { List, Map, Record, fromJS } from "immutable";
import { RootActions, RootState } from ".";
import { SignedDocumentRecord, fromJSON } from "../records/SignedDocument";
import { fromJSON as recipientFromJSON } from "../records/Recipient";
import { fetch, loaded } from "./Reducer";
import * as signedDocumentActions from "./signedDocument.actions";
import { currentJob } from "app2/src/selectors/job.selectors";
import { getFullName } from "app2/src/records/UserRecord";

export const SignedDocumentStateRecord = Record({
  currentId: -1,
  byId: Map<number, SignedDocumentRecord>(),
});

export const initialState = SignedDocumentStateRecord();
export type SignedDocumentState = typeof initialState;

const model = "signedDocuments";

export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case signedDocumentActions.SET_CURRENT_ID:
      return state.setIn([model, "currentId"], action.payload.id);
    case signedDocumentActions.FETCH_DOCUMENT:
      return fetch(state, model, fromJSON({ id: action.payload.id }));
    case signedDocumentActions.RECEIVE_DOCUMENT:
      return state.setIn([model, "byId", action.payload.document.id], fromJSON(action.payload.document));
    case signedDocumentActions.SET_DOCUMENT_LOADED: {
      const { id, status } = action.payload;
      return loaded(state, model, id).setIn([model, "byId", id, "validation_status"], status);
    }

    case signedDocumentActions.INIT_RECIPIENT_INFO:
      const { id, index } = action.payload;
      let { recipient } = action.payload;
      const job = currentJob(state);
      switch (recipient.signer) {
        case "job":
          recipient = recipient.merge({ name: job.customer_name, email: job.email });
          break;
        case "salesrep":
          if (job.assignments && job.assignments.size > 0) {
            const assignee = job.assignments.get(0);
            recipient = recipient.merge({ name: getFullName(assignee.user), email: assignee.user?.email });
          } else {
            recipient = recipient.merge({ name: "", email: "" });
          }

          break;
        default:
          recipient = recipient.merge({ name: "", email: "" });
          break;
      }
      return state.setIn([model, "byId", id, "recipients", index], recipient);
    case signedDocumentActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;

      if (name.includes("signer")) {
        const recipientPath = name.split(".");
        recipientPath.pop();
        let recipient = state.getIn(rootPath.concat(recipientPath));
        recipient = recipient.set("signer", value);

        const index = parseInt(recipientPath[recipientPath.length - 1]);
        state = reducer(
          state,
          signedDocumentActions.Actions.initRecipientInfo(recipient, state.getIn([model, "currentId"]), index),
        );
      }

      return state.setIn(rootPath.concat(name.split(".")), value);

    case signedDocumentActions.INIT_RECIPIENTS: {
      const scribbleSigners = action.payload.validations?.getIn(["data", "signers"]) || List();
      const orgSigners = state.getIn(
        ["orgs", "orgsById", currentOrgId(state), "settings", "config", "signature_settings", "signers"],
        List(),
      );
      const initSigners = orgSigners.setSize(scribbleSigners.size).map(() => recipientFromJSON({}));
      state = state.setIn([model, "byId", action.payload.id, "recipients"], initSigners);

      scribbleSigners.forEach((scribbleSigner, index) => {
        const role_id = scribbleSigner.get("role");
        const orgSigner = orgSigners.find((os) => os.get("role") === role_id) || orgSigners.get(index);
        let recipient = recipientFromJSON({ role_id });
        if (orgSigner) {
          recipient = recipient.merge({
            signer: orgSigner.get("signer"),
            required: orgSigner.get("required"),
            included: orgSigner.get("required"),
          });
        }

        state = reducer(state, signedDocumentActions.Actions.initRecipientInfo(recipient, action.payload.id, index));
      });

      return state;
    }

    case signedDocumentActions.CREATE_SIGNED_DOCUMENT: {
      const { response, estimateId } = action.payload;
      const { document, signature_validations } = response;
      const validations = fromJS(JSON.parse(signature_validations));
      const signedDocumentId = -1;
      const record = fromJSON({
        id: signedDocumentId,
        document_id: document.id,
        estimate_id: estimateId,
        validation_status: 200,
        metadata: { validation_uuid: validations.getIn(["data", "id"]) },
      });
      state = state.setIn([model, "byId", signedDocumentId], record);
      return reducer(state, signedDocumentActions.Actions.initRecipients(signedDocumentId, validations));
    }

    case signedDocumentActions.CLEAR_SIGNED_DOCUMENT: {
      return reducer(state, signedDocumentActions.Actions.receiveDocument({ id }));
    }
    default:
      return state;
  }
};
