import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import SaveablePdfDisplay from "../../Pdf/SaveablePdfDisplay";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { currentJob, selectedEstimateId as selectedEstimateIdSelector } from "app2/src/selectors/job.selectors";
import { fetcher } from "app2/src/helpers/Fetcher";
import { estimateService } from "app2/src/api/estimate.service";
import { IntegrationsButtons } from "../../Estimate/IntegrationsButtons";
import MakePaymentButton from "../../PaymentsModal/MakePaymentButton";
import { useTracking } from "react-tracking";
import { push } from "connected-react-router/immutable";
import * as jobActions from "app2/src/reducers/job.actions";
import { estimateTitle } from "app2/src/selectors/org.selectors";
import { EmailDocumentModal } from "../../Document/EmailDocumentModal";
import { pathname } from "app2/src/selectors/router.selectors";
import { JobTabTitle } from "../JobTabTitle";

export const ViewProposal: React.FC = () => {
  // Hooks
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const match = useRouteMatch<{ estimateId: string }>();
  const estimateId = parseInt(match.params.estimateId);

  // Selectors
  const path = useSelector(pathname);
  const selectedEstimateId = useSelector(selectedEstimateIdSelector);
  const jobRecord = useSelector(currentJob);
  const title = useSelector(estimateTitle);
  const pdfUrl = React.useMemo(() => fetcher.joinUrls(estimateService.url, `${estimateId}.pdf`), [estimateId]);

  // Methods
  const backToAll = () => {
    trackEvent({ action: "back to all" });
    const prevPath = path.split("/proposals/" + match.params.estimateId)[0] + "/proposals";
    dispatch(jobActions.Actions.setSelectedEstimateId(null));
    dispatch(push(prevPath));
  };

  const openEmailModal = () => {
    trackEvent({ action: "email estimate", category: "Estimate", estimate: estimateId });
    dispatch(push(path + "/email"));
  };

  // Lifecycle
  React.useEffect(() => {
    // This is needed in case there is a reload of the page
    if (selectedEstimateId !== estimateId) {
      dispatch(jobActions.Actions.setSelectedEstimateId(estimateId));
    }
  }, [estimateId, selectedEstimateId]);

  return (
    <>
      <JobTabTitle title={title}>
        <div className="float-right">
          <IntegrationsButtons estimateId={estimateId} documentType="estimate" />
          <MakePaymentButton />

          <Button onClick={backToAll} className="ml-2">
            Back to All
          </Button>
          <Button onClick={openEmailModal} className="ml-2">
            Email {title}
          </Button>
        </div>
      </JobTabTitle>
      <Row>
        <Col>
          <SaveablePdfDisplay pdfUrl={pdfUrl} job={jobRecord} disabledFeatures={["Annotations"]} />
        </Col>
      </Row>
      <Switch>
        <Route path={`${match.url}/email`} render={() => <EmailDocumentModal documentType="estimate" />} />
      </Switch>
    </>
  );
};
