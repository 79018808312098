import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { Modal, Button } from "react-bootstrap";
import * as tokenActions from "app2/src/reducers/token.actions";
import { getIn } from "immutable";
import { FormControl, RsfForm, Check, Select } from "@tberrysoln/rsf-form";
import { Context } from "app2/src/components/Integrations/LeadPerfection/Context";
import { required } from "app2/src/helpers/FinalFormValidator";
import { TokenRecord } from "app2/src/records/Token";
import { token } from "app2/src/selectors/token.selectors";

const SettingsModal: React.FunctionComponent = () => {
  const timezoneValues = [
    "Argentina Standard Time",
    "Venezuela Standard Time",
    "Central Standard Time",
    "Mountain Standard Time",
    "Greenland Standard Time",
    "Central America Standard Time",
    "SA Western Standard Time",
    "Atlantic Standard Time",
    "US Eastern Standard Time",
    "Alaskan Standard Time",
    "SA Pacific Standard Time",
    "Pacific Standard Time",
    "Mountain Standard Time (Mexico)",
    "Central Standard Time (Mexico)",
    "Montevideo Standard Time",
    "Eastern Standard Time",
    "US Mountain Standard Time",
    "Canada Central Standard Time",
    "Pacific SA Standard Time",
    "E. South America Standard Time",
    "Newfoundland Standard Time",
    "Samoa Standard Time",
    "Central Pacific Standard Time",
    "West Pacific Standard Time",
    "Hawaiian Standard Time",
    "UTC+12",
    "UTC-11",
    "Tonga Standard Time",
  ];

  // Hooks
  const { orgId, dispatch: contextDispatch } = React.useContext(Context);
  const tokenData: TokenRecord = useSelector((state: RootState) => token(state, { kind: "lead_perfection" }));

  // Methods
  const validateForm = () => {
    const productCsvError = { name: "data.product_csv_dtyid", error: undefined };
    const laborSummaryCsvError = { name: "data.labor_summary_csv_dtyid", error: undefined };
    const jobImageDocumentTypeId = { name: "data.job_image_dtyid", error: undefined };
    const laborSummaryPatternCsvError = { name: "data.labor_summary_csv_pattern", error: undefined };
    const laborSummaryPdfError = { name: "data.labor_summary_pdf_dtyid", error: undefined };
    const laborSummaryPatternPdfError = { name: "data.labor_summary_pdf_pattern", error: undefined };

    if (getIn(tokenData, ["data", "attach_product_csv"], false)) {
      productCsvError.error = required(getIn(tokenData, ["data", "product_csv_dtyid"]));
    }

    if (getIn(tokenData, ["data", "attach_labor_summary_csv"], false)) {
      laborSummaryCsvError.error = required(getIn(tokenData, ["data", "labor_summary_csv_dtyid"]));
      laborSummaryPatternCsvError.error = required(getIn(tokenData, ["data", "labor_summary_csv_pattern"]));
    }

    if (getIn(tokenData, ["data", "attach_job_image_on_upload"], false)) {
      jobImageDocumentTypeId.error = required(getIn(tokenData, ["data", "job_image_dtyid"]));
    }

    if (getIn(tokenData, ["data", "attach_labor_summary_pdf"], false)) {
      laborSummaryPdfError.error = required(getIn(tokenData, ["data", "labor_summary_pdf_dtyid"]));
      laborSummaryPatternPdfError.error = required(getIn(tokenData, ["data", "labor_summary_pdf_pattern"]));
    }

    return [
      productCsvError,
      laborSummaryCsvError,
      jobImageDocumentTypeId,
      laborSummaryPdfError,
      laborSummaryPatternCsvError,
      laborSummaryPatternPdfError,
    ];
  };
  return (
    <Modal show={true}>
      <Modal.Header closeButton>
        <Modal.Title>Lead Perfection</Modal.Title>
      </Modal.Header>

      <RsfForm
        rootPath={["tokens", "byOrgId", orgId, "lead_perfection"]}
        updateFormReducer={tokenActions.Actions.update}
        validate={validateForm}
        onSubmit={() => contextDispatch("submit", {})}>
        <Modal.Body>
          <Select label="Time zone" name="data.timezone">
            <option value="">None</option>
            {timezoneValues.map((tz, index) => (
              <option key={index} value={tz}>
                {tz}
              </option>
            ))}
          </Select>
          <FormControl
            label="Agreement / Proposal Document Type Id"
            name="data.lead_perfection_dtyid"
            formControlProps={{ type: "number" }}
          />
          <hr />
          <Check label="Attach Cost CSV on Fully Executed Agreement" name="data.attach_product_csv" />
          <FormControl
            label="Cost CSV Document Type Id"
            name="data.product_csv_dtyid"
            formControlProps={{ type: "number" }}
            disabled={!getIn(tokenData, ["data", "attach_product_csv"], false)}
          />
          <hr />
          <Check label="Attach Job Image on Upload" name="data.attach_job_image_on_upload" />
          <FormControl
            label="Job image Document Type ID"
            name="data.job_image_dtyid"
            formControlProps={{ type: "number" } as any}
            disabled={!getIn(tokenData, ["data", "attach_job_image_on_upload"], false)}
          />
          <hr />
          <Check label="Attach Labor Summary CSV on Fully Executed Agreement" name="data.attach_labor_summary_csv" />
          <FormControl
            label="Labor Summary CSV Document Type Id"
            name="data.labor_summary_csv_dtyid"
            formControlProps={{ type: "number" }}
            disabled={!getIn(tokenData, ["data", "attach_labor_summary_csv"], false)}
          />

          <Select
            label="Labor Summary CSV Pattern"
            name="data.labor_summary_csv_pattern"
            disabled={!getIn(tokenData, ["data", "attach_labor_summary_csv"], false)}>
            <option value=""></option>
            <option value="custom_greenline">Custom Greenline</option>
          </Select>

          <hr />
          <Check label="Attach Labor Summary PDF on Fully Executed Agreement" name="data.attach_labor_summary_pdf" />
          <FormControl
            label="Labor Summary PDF Document Type Id"
            name="data.labor_summary_pdf_dtyid"
            formControlProps={{ type: "number" }}
            disabled={!getIn(tokenData, ["data", "attach_labor_summary_pdf"], false)}
          />
          <Select
            label="Labor Summary PDF Pattern"
            name="data.labor_summary_pdf_pattern"
            disabled={!getIn(tokenData, ["data", "attach_labor_summary_pdf"], false)}>
            <option value=""></option>
            <option value="custom_greenline">Custom Greenline</option>
          </Select>
        </Modal.Body>

        <Modal.Footer key={2}>
          <Button type="button" variant="cancel" className="pull-right" onClick={() => contextDispatch("close", {})}>
            Close
          </Button>
          <Button type="submit" variant="default" className="pull-right">
            Save Changes
          </Button>
        </Modal.Footer>
      </RsfForm>
    </Modal>
  );
};

export default SettingsModal;
