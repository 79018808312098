import { PlanListCtrl } from "app/src/Billing/Plans/PlanListCtrl";
import { PlanNewCtrl } from "app/src/Billing/Plans/PlanNewCtrl";
import { PlanShowCtrl } from "app/src/Billing/Plans/PlanShowCtrl";

export class PlansRoutes {
  public static load(app: ng.IModule) {
    app.controller("PlanListCtrl", PlanListCtrl);
    app.controller("PlanNewCtrl", PlanNewCtrl);
    app.controller("PlanShowCtrl", PlanShowCtrl);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("root.plan_list", <ng.ui.IState>{
        url: "/plans",
        views: {
          "container@": {
            templateUrl: "src/Billing/Plans/list.html",
            controller: "PlanListCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.plan_new", <ng.ui.IState>{
        url: "/plans/new",
        views: {
          "container@": {
            templateUrl: "src/Billing/Plans/new.html",
            controller: "PlanNewCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.plan_edit", <ng.ui.IState>{
        url: "/plans/:id/edit",
        views: {
          "container@": {
            templateUrl: "src/Billing/Plans/new.html",
            controller: "PlanNewCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.plan_show", <ng.ui.IState>{
        url: "/plans/:id",
        views: {
          "container@": {
            templateUrl: "src/Billing/Plans/show.html",
            controller: "PlanShowCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      });
  }
}
