import * as React from "react";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimate } from "app/src/Models/Estimate";
import { Tabs, Tab } from "react-bootstrap";
import MeasurementsTab from "app2/src/components/LineItemEditor/components/MeasurementsTab";
import { FilesTab } from "app2/src/components/LineItemEditor/components/FilesTab";
import ProductOptionsTab from "app2/src/components/LineItemEditor/components/ProductOptionsTab";
import * as FontAwesome from "react-fontawesome";
import track from "react-tracking";
import { Dispatch } from "app2/src/helpers/Analytics";
import { EstimateLineItemComponentCtrl } from "app/src/Estimator/EstimateLineItemComponent";
import { RootActions, RootState } from "app2/src/reducers";
import { ProductRecord } from "app2/src/records/Product";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { cachedProduct } from "app2/src/selectors/product.selectors";
import { StoreRegistry } from "app2/src/storeRegistry";
import { cachedProductOptionGroup, cachedProductOptionGroups } from "app2/src/selectors/productOptionGroup.selectors";
import { cachedProductOptions } from "app2/src/selectors/productOption.selectors";
import { hasMeasurements } from "app2/src/selectors/measurement.selectors";
import { optionSelected, ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { List } from "immutable";
import { MeasurementRecord } from "app2/src/records/Measurement";

const mapStateToProps = (state: RootState, ownProps: LineItemEditorTabsProps) => {
  if (ownProps.lineItem.product_id) {
    return {
      product: cachedProduct(state, {
        activatedPriceListId: ownProps.estimate.activated_price_list_id,
        productId: ownProps.lineItem.product_id,
      }),
      productOptionGroups: cachedProductOptionGroups(state, {
        activatedPriceListId: ownProps.estimate.activated_price_list_id,
        productId: ownProps.lineItem.product_id,
        type: "default",
      }),
      hasMeasurements: hasMeasurements(state, { measurementId: ownProps.measurement.id, location: "line_item_editor" }),
    };
  } else {
    return {
      product: null,
      productOptionGroups: null,
      hasMeasurements: hasMeasurements(state, { measurementId: ownProps.measurement.id, location: "line_item_editor" }),
    };
  }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: LineItemEditorTabsProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface LineItemEditorTabsProps {
  lineItem: IEstimateLineItem;
  forceRender: number;
  estimate: IEstimate;
  measurement: MeasurementRecord;
  controller: EstimateLineItemComponentCtrl;
  handleUseMeasurement: (value: number, type: string, uom: string, product: ProductRecord) => void;
  selectOption: (optionGroup: ProductOptionGroupRecord, option: ProductOptionRecord, quantity: number) => void;
  unselectOption: (option: ProductOptionRecord) => void;
  handleQtyChange: (optionGroup, option, qty) => void;
  handleFile: (file) => any;
  handleFileDestroy: (file) => any;
  handleFileChange: (value, file) => any;
}

export interface LineItemEditorTabsState {
  activeTab: string;
  activeTabs: string[];
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & LineItemEditorTabsProps;

@track(
  (props) => {
    return {
      category: "Line Item Editor",
      estimate: props.estimate.id,
      job: props.estimate.job_id,
      org: props.controller.org.id,
      navDisplay: !props.controller.navDisplay,
      lineItemId: props.lineItem.id,
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: (cd) => ({ action: "shown" }),
  },
)
class LineItemEditorTabs extends React.Component<Props, LineItemEditorTabsState> {
  public props: Props;
  public state: LineItemEditorTabsState;
  public eventListener: any;

  constructor(props) {
    super(props);
    const { lineItem } = this.props;
    const active_tabs = this.getActiveTabs(lineItem);
    this.state = {
      activeTab: active_tabs[0],
      activeTabs: active_tabs,
    };
    this.handleUseMeasurement = this.handleUseMeasurement.bind(this);
  }

  public componentDidUpdate(prevProps: Props, prevState: LineItemEditorTabsState) {
    const { forceRender, hasMeasurements } = this.props;
    if (prevProps.forceRender !== forceRender || prevProps.hasMeasurements !== hasMeasurements) {
      const { lineItem } = this.props;
      const active_tabs = this.getActiveTabs(lineItem);
      if (!_.isEqual(active_tabs, prevState.activeTabs)) {
        this.setState({ activeTab: active_tabs[0], activeTabs: active_tabs });
      }
    }
  }

  public getActiveTabs(lineItem: IEstimateLineItem) {
    const { product, productOptionGroups, hasMeasurements } = this.props;
    const active_tabs = [];

    if (hasMeasurements) {
      active_tabs.push("measurements");
    }

    let pogs = List();
    if (product && productOptionGroups) {
      pogs = productOptionGroups;
    }
    if (product && pogs.size > 0) {
      pogs.map((pog: ProductOptionGroupRecord) => {
        active_tabs.push(pog.id.toString());
      });
    }
    const noProduct = _.isNull(lineItem.product_id) || _.isUndefined(lineItem.product_id);
    if (noProduct) {
      active_tabs.push("files");
    }
    return active_tabs;
  }

  public handleTabSelect = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  public handleUseMeasurement = (value: number, type: string, uom: string) => {
    const { product } = this.props;
    this.props.handleUseMeasurement(value, type, uom, product);
  };

  public render() {
    const { activeTabs } = this.state;
    const active_tabs = [];
    activeTabs.forEach((tab) => {
      if (_.includes(["measurements", "files"], tab)) {
        active_tabs.push(this["_get_" + tab]());
      } else {
        active_tabs.push(this._get_options(tab));
      }
    });

    let options_tab = null;
    if (active_tabs.length > 0) {
      options_tab = (
        <Tabs
          className="line-item-editor-tabs"
          id="tabs"
          activeKey={this.state.activeTab}
          onSelect={this.handleTabSelect}>
          {active_tabs}
        </Tabs>
      );
    }

    return <div>{options_tab}</div>;
  }

  private _get_measurements() {
    const { measurement } = this.props;
    return (
      <Tab key="measurements" eventKey="measurements" title="Measurements">
        <MeasurementsTab measurement={measurement} handleUseMeasurement={this.handleUseMeasurement} />
      </Tab>
    );
  }

  private _get_files() {
    const { controller, lineItem, handleFile, handleFileChange, handleFileDestroy } = this.props;
    return (
      <Tab key="files" eventKey="files" title="Files">
        <FilesTab
          lineItem={lineItem}
          handleFile={handleFile}
          controller={controller}
          handleFileChange={handleFileChange}
          handleFileDestroy={handleFileDestroy}
        />
      </Tab>
    );
  }

  private _get_options(pog_id: string) {
    const { controller, estimate, lineItem, selectOption, unselectOption, handleQtyChange } = this.props;
    const pog = cachedProductOptionGroup(StoreRegistry.getStore().getState(), {
      productOptionGroupId: parseInt(pog_id),
      activatedPriceListId: estimate.activated_price_list_id,
    });
    const options = cachedProductOptions(StoreRegistry.getStore().getState(), {
      productOptionIds: pog.option_ids,
      activatedPriceListId: estimate.activated_price_list_id,
    });

    let title: any;
    if (optionSelected(options, lineItem)) {
      title = (
        <span>
          {pog.name} <FontAwesome name="check" className="checkmark" />
        </span>
      );
    } else {
      title = <span>{pog.name}</span>;
    }
    return (
      <Tab key={pog_id} eventKey={pog_id} title={title}>
        <ProductOptionsTab
          lineItem={lineItem}
          estimate={estimate}
          Session={controller.Session}
          optionGroup={pog}
          options={options}
          selectOption={selectOption}
          unselectOption={unselectOption}
          handleQtyChange={handleQtyChange}
        />
      </Tab>
    );
  }
}

export default connector(LineItemEditorTabs);
