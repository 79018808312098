import { ISignedDocumentRecipientResource, ISignedDocumentRecipient } from "app/src/Models/SignedDocumentRecipient";

export class SignatureRedirectCtrl {
  public recipient: ISignedDocumentRecipient;
  public signatureUrlSignNow: string;
  public signatureUrlRightSignature: string;

  public static $inject = ["$stateParams", "SignedDocumentRecipient", "$sce", "$window"];
  constructor(
    public $stateParams: ng.ui.IStateParamsService,
    public SignedDocumentRecipient: ISignedDocumentRecipientResource,
    public $sce: ng.ISCEService,
    public $window: ng.IWindowService,
  ) {
    this.recipient = this.SignedDocumentRecipient.sign({ key: $stateParams["id"] });
    this.recipient.$promise.then(() => {
      // Really old documents (Sign Now) might not pass this if statement. Mark said to ignore it.
      if ((this.recipient?.rs_url || "").indexOf("remotesf.com") > 0) {
        $window.location.href = this.recipient.rs_url;
      }
    });
  }
}
