import * as React from "react";
import { useSelector } from "react-redux";
import { EstimateLineItemRecord } from "app2/src/records/EstimateLineItem";
import { getUrl } from "app2/src/records/Image";
import { images as imagesSelector } from "app2/src/selectors/image.selectors";

export interface IInspectionSlideProps {
  lineItem: EstimateLineItemRecord;
  key: number;
}

export const InspectionSlide: React.FC<IInspectionSlideProps> = ({ lineItem, key }) => {
  const images = useSelector((state: any) => imagesSelector(state, { imageIds: lineItem.image_ids }));

  return (
    <div key={key} className="slide">
      <div className="placeholder">
        <span>{lineItem.name}</span>
        {images.size > 0 && <img src={getUrl(images.first(), "small")} />}
      </div>
    </div>
  );
};
