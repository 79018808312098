import { Record } from "immutable";
import { DocumentRecord, IDocumentData, fromJSON as docFromJSON } from "./Document";
import { Nullable } from ".";
import { PriceListState, initialState as PriceListInitialState } from "app2/src/reducers/priceList.reducer";
import { ProductState, initialState as ProductInitialState } from "app2/src/reducers/product.reducer";
import {
  ProductOptionGroupState,
  initialState as ProductOptionGroupInitialState,
} from "app2/src/reducers/productOptionGroup.reducer";
import { ProductOptionState, initialState as ProductOptionInitialState } from "app2/src/reducers/productOption.reducer";

export const fromJSON = (json: Partial<IActivatedPriceListData>): ActivatedPriceListRecord => {
  const recordData: IActivatedPriceListRecord = { ...(json as any) };

  if (json.file) {
    recordData.file = docFromJSON(json.file);
  }

  return new ActivatedPriceListRecord(recordData);
};

export interface IActivatedPriceListData {
  id: number;
  org_id: number;
  name: string;
  price_list_id: number;
  file: IDocumentData;
  hidden_products: boolean;
  csv_url: string;
  csv_generated_on: Date;
  location: string;
  estimate_count: number;
  activated_price_list_id: number;
  loading: boolean;

  created_at: Date;
  updated_at: Date;
}

export interface IActivatedPriceListRecord {
  id: number;
  org_id: number;
  name: string;
  price_list_id: number;
  priceLists: PriceListState;
  products: ProductState;
  productOptionGroups: ProductOptionGroupState;
  productOptions: ProductOptionState;
  file: Nullable<DocumentRecord>;
  hidden_products: boolean;
  csv_url: string;
  csv_generated_on: Nullable<Date>;
  location: string;
  estimate_count: number;
  activated_price_list_id: Nullable<number>;
  loading: boolean;

  created_at: Date;
  updated_at: Date;
}

export const defaultActivatedPriceListProps = {
  id: 0,
  org_id: 0,
  name: "",
  price_list_id: 0,
  priceLists: PriceListInitialState,
  products: ProductInitialState,
  productOptionGroups: ProductOptionGroupInitialState,
  productOptions: ProductOptionInitialState,
  file: null,
  hidden_products: false,
  csv_url: "",
  csv_generated_on: null,
  location: "",
  activated_price_list_id: null,
  loading: false,
  estimate_count: 0,
  created_at: new Date(),
  updated_at: new Date(),
};

export class ActivatedPriceListRecord
  extends Record<IActivatedPriceListRecord>(defaultActivatedPriceListProps)
  implements IActivatedPriceListRecord
{
  public id!: number;
  public org_id!: number;
  public name!: string;
  public price_list_id!: number;
  public priceLists!: PriceListState;
  public products!: ProductState;
  public productOptionGroups!: ProductOptionGroupState;
  public productOptions!: ProductOptionState;
  public file!: Nullable<DocumentRecord>;
  public hidden_products!: boolean;
  public csv_url!: string;
  public csv_generated_on!: Nullable<Date>;
  public location!: string;
  public estimate_count!: number;
  public activated_price_list_id!: Nullable<number>;
  public loading!: boolean;

  public created_at!: Date;
  public updated_at!: Date;

  public constructor(values?: Partial<IActivatedPriceListRecord>) {
    values ? super(values) : super();
  }
}
