import * as quickMeasureActions from "app2/src/reducers/integrations/quickMeasure.actions";
import { Record } from "immutable";

export const QuickMeasureStateRecord = Record({
  authorizing: false,
  authorized: false,
});

export const initialState = QuickMeasureStateRecord();
export type QuickMeasureState = typeof initialState;

export const reducer = (state = initialState, action: quickMeasureActions.Actions): QuickMeasureState => {
  switch (action.type) {
    case quickMeasureActions.SET_AUTHORIZING:
      return state.set("authorizing", action.payload);
    case quickMeasureActions.SET_AUTHORIZED:
      return state.set("authorized", action.payload);
    default:
      return state;
  }
};
