import * as roofScopeActions from "app2/src/reducers/integrations/roofscope.actions";
import { Record } from "immutable";

export const RoofScopeStateRecord = Record({
  authorized: false,
});

export const initialState = RoofScopeStateRecord();
export type RoofScopeState = typeof initialState;

export const reducer = (state = initialState, action: roofScopeActions.Actions): RoofScopeState => {
  switch (action.type) {
    case roofScopeActions.SET_AUTHORIZED:
      return state.set("authorized", action.payload);
    default:
      return state;
  }
};
