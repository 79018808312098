import * as React from "react";
import { Button, Modal, Row, Col, ButtonGroup, FormGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";
import { FormControl, RsfForm, Select } from "@tberrysoln/rsf-form";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { RootState } from "app2/src/reducers";
import { pathname, query } from "app2/src/selectors/router.selectors";
import { push } from "connected-react-router/immutable";
import { capitalize } from "humanize-plus";
import { AsyncActions, Actions } from "app2/src/reducers/measurement.actions";
import { addMeasurementForm, rootKey } from "app2/src/selectors/measurement.selectors";
import { greaterThanValue, required } from "app2/src/helpers/FinalFormValidator";
import { getUoM } from "app2/src/records/Measurement";
import { useTracking } from "react-tracking";
import { currentOrgId, preferencesConfig } from "app2/src/selectors/org.selectors";
import { List } from "immutable";

export const addMeasurementObjects = List(["windows", "doors", "rooms"]);
export const addMeasurementFilter = (permissions: List<string>): List<string> =>
  addMeasurementObjects.filter((p) => permissions.includes(p));

export const AddMeasurementModal: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "AddMeasurementModal" });

  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const queryParams = useSelector(query);
  const rootPathname = useSelector(pathname);
  const object = useSelector(addMeasurementForm);
  const addType = queryParams.get("add");
  const uom = getUoM(measurement);
  const permissions = useSelector((state: RootState) =>
    preferencesConfig(state, { orgId: currentOrgId(state), path: ["measurement", "job_tab"], notSet: List() }),
  );
  const enabledMeasurements = React.useMemo(() => addMeasurementFilter(permissions), [permissions]);

  // Local State
  let initState = enabledMeasurements.first();
  if (enabledMeasurements.includes(addType)) {
    initState = addType;
  }
  const rootPath = [rootKey, "addMeasurementForm"];
  const [measurementType, setMeasurementType] = React.useState(initState);

  // Methods
  const close = () => {
    trackEvent({ action: "close" });
    const newQueryParams = new URLSearchParams({
      ...queryParams.toJS(),
    });
    newQueryParams.delete("add");
    dispatch(push(`${rootPathname}?${newQueryParams.toString()}`));
  };

  const save = async () => {
    trackEvent({ action: `save new ${initState}` });
    dispatch(Actions.saveAddMeasurement());
    await dispatch(AsyncActions.updateMeasurement());
    close();
  };

  const changeType = (data) => {
    trackEvent({ action: "change type", label: data.currentTarget.name });
    const { name } = data.currentTarget;
    setMeasurementType(name);
    dispatch(Actions.updateForm({ rootPath, name: "kind", value: name }));
  };

  // Lifecycle
  React.useEffect(() => {
    setMeasurementType(initState);
    dispatch(Actions.initAddMeasurement());
    dispatch(Actions.updateForm({ rootPath, name: "kind", value: initState }));
  }, [addType]);

  if (!measurement) {
    return null;
  }

  return (
    <Modal show={!!addType} onHide={close} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Add New {addType === "global" ? "Measurement" : capitalize(addType || "").slice(0, -1)}
        </Modal.Title>
      </Modal.Header>
      <RsfForm rootPath={rootPath} updateFormReducer={Actions.updateAddMeasurement} onSubmit={save}>
        <Modal.Body>
          <Row>
            <Col>
              <label className="form-label w-100">Type</label>
              {addType === "global" ? (
                <ButtonGroup aria-label="type" className="mb-3">
                  {enabledMeasurements.includes("rooms") && (
                    <Button
                      variant={measurementType === "rooms" ? "primary" : "outline-primary"}
                      name="rooms"
                      onClick={changeType}
                      tabIndex={1}>
                      Room
                    </Button>
                  )}
                  {enabledMeasurements.includes("doors") && (
                    <Button
                      variant={measurementType === "doors" ? "primary" : "outline-primary"}
                      name="doors"
                      onClick={changeType}
                      tabIndex={2}>
                      Door
                    </Button>
                  )}
                  {enabledMeasurements.includes("windows") && (
                    <Button
                      variant={measurementType === "windows" ? "primary" : "outline-primary"}
                      name="windows"
                      onClick={changeType}
                      tabIndex={3}>
                      Window
                    </Button>
                  )}
                </ButtonGroup>
              ) : (
                <Button variant="primary" name={addType} onClick={changeType} className="mb-3" tabIndex={1}>
                  {capitalize(addType || "").slice(0, -1)}
                </Button>
              )}
            </Col>
            <Col>
              <FormControl label="name" name="name" validate={required} formControlProps={{ tabIndex: 4 } as any} />
            </Col>
          </Row>

          <Row>
            <Col>
              {measurementType === "rooms" ? null : (
                <Select label="location" name="location" formControlProps={{ tabIndex: 5 } as any}>
                  <option key={-1} value={""}></option>
                  {measurement.get("locations").map((level, idx) => (
                    <option key={idx} value={level}>
                      {level}
                    </option>
                  ))}
                </Select>
              )}
              <FormControl
                label="quantity"
                name="quantity"
                formControlProps={{ type: "number" }}
                numberInputProps={{ tabIndex: measurementType === "rooms" ? 5 : 7 } as any}
                validate={greaterThanValue(0)}
              />
            </Col>
            <Col>
              <Select label="level" name="level" formControlProps={{ tabIndex: 6 } as any}>
                <option key={-1} value={""}></option>
                {measurement.get("levels").map((level, idx) => (
                  <option key={idx} value={level}>
                    {level}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>

          {measurementType === "rooms" ? null : (
            <>
              <h3>Measurements</h3>
              <Row>
                <Col>
                  <FormControl
                    label="width"
                    name="width"
                    validate={required}
                    formControlProps={{ type: "number" }}
                    numberInputProps={{ tabIndex: 8 } as any}
                  />
                </Col>
                <Col>
                  <FormControl
                    label="height"
                    name="height"
                    validate={required}
                    formControlProps={{ type: "number" }}
                    numberInputProps={{ tabIndex: 9 } as any}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  UI: {object?.get("ui")} {uom["window_ui"]}
                </Col>
              </Row>
              <Row>
                <Col>
                  Area: {object?.get("area")} {uom["window_area"]}
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonFooter cancel={close} saveButtonProps={{ tabIndex: 10 }} cancelButtonProps={{ tabIndex: 11 }} />
        </Modal.Footer>
      </RsfForm>
    </Modal>
  );
};
