import { FlashLevels } from "app/src/Common/FlashLevels";
import { handleErrors } from "app2/src/reducers/Utils";
import { onSortEnd } from "app2/src/helpers/Record";
import * as commonActions from "app2/src/reducers/components/common.actions";
import * as taskActions from "app2/src/reducers/task.actions";
import { sendInviteOptions } from "app2/src/selectors/components/common.selectors";
import { currentJobId, job, resourceDocuments } from "app2/src/selectors/job.selectors";
import { List } from "immutable";
import { ThunkAction } from "redux-thunk";
import { RootActions, RootState } from ".";
import * as jobService from "../api/job.service";
import { JobQueryRecord } from "../components/JobList/JobQuery";
import { fetcher } from "../helpers/Fetcher";
import { IEstimateOverviewData } from "../records/EstimateOverview";
import { ImageRecord } from "../records/Image";
import {
  fromJSON as jobFromJSON,
  IJobData,
  IJobRecord,
  IJobsPageRecord,
  IJobStateInfo,
  JobRecord,
  JobsPageRecord,
} from "../records/Job";
import { IStatsData } from "../records/Statistics";
import { ITaskData } from "../records/Task";
import { UserRecord } from "../records/UserRecord";
import { RootDispatchType } from "../store";
import { IPagination } from "./leads.actions";
import { ActionsUnion, createAction } from "./Utils";

export const SET_CURRENT_JOB_ID = "@job/SET_CURRENT_JOB_ID";
export const SAVING_JOB = "@job/SAVING_JOB";
export const RECEIVE_JOB = "@job/RECEIVE_JOB";
export const FETCH_JOB = "@job/FETCH_JOB";
export const FETCH_PAGE = "@job/FETCH_PAGE";
export const RECEIVE_PAGE = "@job/RECEIVE_PAGE";
export const RESET_PAGES = "@job/RESET_PAGES";
export const SWIPE_JOB = "@job/SWIPE_JOB";
export const ADDING_PHOTO = "@job/ADDING_PHOTO";
export const ADDED_PHOTO = "@job/ADDED_PHOTO";
export const CANCEL_PHOTO = "@job/CANCEL_PHOTO";
export const CANCEL_SAVING_JOB = "@job/CANCEL_SAVING_JOB";
export const SET_JOB_LOADED = "@job/SET_JOB_LOADED";
export const ADD_JOB_TO_PAGE = "@job/ADD_JOB_TO_PAGE";
export const JOB_RECEIVE_ESTIMATE = "@job/JOB_RECEIVE_ESTIMATE";
export const SET_SELECTED_ESTIMATE_ID = "@job/SET_SELECTED_ESTIMATE_ID";
export const SET_JOB_QUERY = "@job/SET_JOB_QUERY";
export const REVERT_JOB_QUERY = "@job/REVERT_JOB_QUERY";
export const SET_LAST_SAVED_JOB_QUERY = "@job/SET_LAST_SAVED_JOB_QUERY";
export const RESET_LAST_CHANGES_JOB_QUERY = "@job/RESET_LAST_CHANGES_JOB_QUERY";
export const RESET_JOB_QUERY = "@job/RESET_JOB_QUERY";
export const SET_JOB_STATES = "@job/SET_JOB_STATES";
export const EDIT_RESOURCE_DISPLAY = "@job/EDIT_RESOURCE_DISPLAY";
export const SET_RESOURCE_DOCUMENTS = "@job/SET_RESOURCE_DOCUMENTS";
export const INCREASE_COUNT = "@job/INCREASE_COUNT";
export const DECREASE_COUNT = "@job/DECREASE_COUNT";
export const EDIT_JOB = "@job/EDIT_JOB";
export const RECEIVE_STATS = "@job/RECEIVE_STATS";
export const SET_SAMPLE_JOBS = "@job/SET_SAMPLE_JOBS";

export interface Page {
  loading?: boolean;
  page: number;
  jobs: IJobRecord[];
  meta: IPagination;
}

export interface IJobQueryField {
  name: string;
  value: any;
}

export const Actions = {
  setCurrentJobId: (id: number) => createAction(SET_CURRENT_JOB_ID, { id }),
  fetchPage: (page: number) => createAction(FETCH_PAGE, page),
  receivePage: (page: Page) => createAction(RECEIVE_PAGE, page),
  resetPages: () => createAction(RESET_PAGES),
  receiveJob: (job: IJobData) => createAction(RECEIVE_JOB, job),
  setJobLoaded: (id: number) => createAction(SET_JOB_LOADED, { id }),
  fetchJob: (id: number) => createAction(FETCH_JOB, { id }),
  swipeJob: (id: number) => createAction(SWIPE_JOB, id),
  addingPhoto: (jobId: number) => createAction(ADDING_PHOTO, jobId),
  cancelPhoto: (jobId: number) => createAction(CANCEL_PHOTO, jobId),
  addedPhoto: (jobId: number, image: ImageRecord) => createAction(ADDED_PHOTO, { jobId, image }),
  savingJob: (jobId: number) => createAction(SAVING_JOB, jobId),
  addJobTopage: (jobId: number, page: number) => createAction(ADD_JOB_TO_PAGE, { jobId, page }),
  cancelSavingJob: (jobId: number) => createAction(CANCEL_SAVING_JOB, jobId),
  receiveEstimate: (jobId: number, estimate: IEstimateOverviewData) =>
    createAction(JOB_RECEIVE_ESTIMATE, { jobId, estimate }),
  setSelectedEstimateId: (estimateId: number) => createAction(SET_SELECTED_ESTIMATE_ID, { estimateId }),
  setJobQuery: (jobQuery: IJobQueryField) => createAction(SET_JOB_QUERY, { jobQuery }),
  setLastSavedJobQuery: (jobQuery: JobQueryRecord) => createAction(SET_LAST_SAVED_JOB_QUERY, { jobQuery }),
  resetLastChangesJobQuery: () => createAction(RESET_LAST_CHANGES_JOB_QUERY),
  revertJobQuery: () => createAction(REVERT_JOB_QUERY),
  resetJobQuery: () => createAction(RESET_JOB_QUERY),
  setJobStates: (jobStates: { [k: string]: IJobStateInfo }) => createAction(SET_JOB_STATES, { jobStates }),
  editResourceDisplay: (jobId: number, documentId: number, name: string, value: boolean) =>
    createAction(EDIT_RESOURCE_DISPLAY, { jobId, documentId, name, value }),
  setResourceDocuments: (jobId: number, resourceDocuments: List<any>) =>
    createAction(SET_RESOURCE_DOCUMENTS, { jobId, resourceDocuments }),
  increaseCount: (fileType: "document" | "image", jobId: number) => createAction(INCREASE_COUNT, { fileType, jobId }),
  decreaseCount: (fileType: "document" | "image", jobId: number) => createAction(DECREASE_COUNT, { fileType, jobId }),
  editCoverImage: (jobId: number, imageUuid: string) =>
    createAction(EDIT_JOB, { jobId, name: "cover_image_uuid", value: imageUuid }),
  receiveStats: (statistics: IStatsData) => createAction(RECEIVE_STATS, { statistics }),
  setSampleJobs: (sampleJobs: string[]) => createAction(SET_SAMPLE_JOBS, { sampleJobs }),
};

type ThunkResult<T> = ThunkAction<T, RootState, {}, RootActions>;

export const AsyncActions = {
  getJob: (jobId: number): ThunkResult<Promise<JobRecord>> => {
    return (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchJob(jobId));

      const params = {
        "include[]": jobService.includes,
      } as any;

      return fetcher.get<{ job: IJobData }>(`/api/v1/jobs/${jobId}`, params).then((j) => {
        dispatch(Actions.receiveJob(j.job));
        return jobFromJSON(j.job);
      });
    };
  },
  getJobs: (query: string, page: number, indicator = true): ThunkResult<Promise<IJobsPageRecord>> => {
    return (dispatch: RootDispatchType, getState) => {
      const state = getState();
      const totalPages = state.getIn(["jobs", "total_pages"], 0);
      const params = {
        sort_by: "updated_at",
        sort_order: "desc",
        page: page,
        "include[]": jobService.includes,
      } as any;

      if (totalPages < page) {
        return Promise.resolve(JobsPageRecord());
      }

      dispatch(Actions.fetchPage(page));

      if (query !== "" && query !== undefined) {
        params.query = query;
      }

      return fetcher
        .get<Page>("/api/v1/jobs", params)
        .then(
          (result: Page) => {
            result.page = page;

            dispatch(Actions.receivePage(result));

            return getState().getIn(["jobs", "byPage", page]);
          },
          (...args) => {
            handleErrors(args);
          },
        )
        .catch((errors) => {
          handleErrors(errors);
          return Promise.reject(errors);
        });
    };
  },
  updateJob: (jobId: number): ThunkResult<Promise<any>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const jobRecord = job(getState(), { jobId });
      dispatch(Actions.fetchJob(jobId));

      try {
        const response = await jobService.update(jobRecord);
        dispatch(Actions.receiveJob(response.job));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setJobLoaded(jobId));
      }
    };
  },
  assignJob: (job: JobRecord, user: UserRecord): ThunkResult<Promise<JobRecord>> => {
    return (dispatch: RootDispatchType, _getState) => {
      const url = `/api/v1/jobs/${job.get("id")}/assign`;

      const params = {
        include: jobService.includes,
        user_id: user.id,
      };

      const options = {
        method: "PATCH",
      };

      return fetcher
        .fetch(url, params, options)
        .then(fetcher.json)
        .then((result) => {
          dispatch(Actions.receiveJob(result.job));
          return jobFromJSON(result.job);
        });
    };
  },
  processJobImportTask: (task: Partial<ITaskData>): ThunkResult<any> => {
    return (dispatch: RootDispatchType) => {
      dispatch(taskActions.AsyncActions.pollTask(task));
    };
  },
  importJobs: (orgId: number, csvText: string): ThunkResult<any> => {
    return async (dispatch: RootDispatchType) => {
      const response: Partial<ITaskData> = await jobService.importJobs(orgId, csvText);
      dispatch(AsyncActions.processJobImportTask(response));
      return response.location.split("/").pop();
    };
  },
  onSortEnd: (oldIndex: number, newIndex: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const jobId = currentJobId(getState());
      const docs = resourceDocuments(getState(), { jobId });

      const { list } = onSortEnd(docs, oldIndex, newIndex, []);

      dispatch(Actions.setResourceDocuments(jobId, list));
      dispatch(AsyncActions.updateJob(jobId));
    };
  },
  editJobResourceDisplay: (documentId: number, name: string, value: boolean): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const jobId = currentJobId(getState());
      dispatch(Actions.editResourceDisplay(jobId, documentId, name, value));

      dispatch(AsyncActions.updateJob(jobId));
    };
  },
  editCoverImage: (imageUuid: string): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      const jobId = currentJobId(getState());
      dispatch(Actions.editCoverImage(jobId, imageUuid));

      dispatch(AsyncActions.updateJob(jobId));
    };
  },
  invite: (args: jobService.IInviteArgs): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState) => {
      args.id = currentJobId(getState());
      args.options = sendInviteOptions(getState());
      const response = await jobService.invite(args);
      const { task } = response;
      if (task) {
        dispatch(taskActions.Actions.receiveTask(task));
        await dispatch(taskActions.AsyncActions.pollTask(task));
      }
    };
  },
  loadSampleJobs: (orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const response = await jobService.loadSamples(orgId);
        dispatch(Actions.setSampleJobs(response.sample_jobs));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },

  createSampleJobs: (orgId: number, userAssigned: number, sampleTypes: List<string>) => {
    return async (dispatch: RootDispatchType) => {
      try {
        const { task } = await jobService.createSample(orgId, userAssigned, sampleTypes.toJS());
        dispatch(
          commonActions.Actions.flashAddAlert(FlashLevels.success, "Sample Job(s) task submitted successfully."),
        );
        dispatch(taskActions.Actions.receiveTask(task));
        await dispatch(taskActions.AsyncActions.pollTask(task));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
