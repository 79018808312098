import * as React from "react";
import { Record } from "immutable";
import { noop } from "underscore";

interface ContextProps {
  sessionUrl: string;
  orgId: number;
  dispatch: SubscriptionDispatch;
}

export type SubscriptionAction = "createPortalSession" | "close";
export type SubscriptionDispatch = (action: SubscriptionAction, payload?: any) => void;

const defaultContextProps: ContextProps = {
  sessionUrl: "",
  orgId: 0,
  dispatch: noop,
};

export class ContextRecord extends Record(defaultContextProps) implements ContextProps {}

export const Context = React.createContext(new ContextRecord());
