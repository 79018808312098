export class SortableOptions implements ng.ui.UISortableOptions<any> {
  public wscrolltop = 0;
  public items: string;
  public handle = ".dragHandle";
  public cursor = "grabbing";
  public connectWith = ".connected-list";
  public distance = 15;
  public update: (event: JQueryEventObject, ui: ng.ui.UISortableUIParams<any>) => any;
  public stop: (event: JQueryEventObject, ui: ng.ui.UISortableUIParams<any>) => any;

  protected sortableFirst = false;

  public helper = (event: JQueryEventObject, ui: ng.IAugmentedJQuery) => {
    const originals = ui.find("td");
    const helper = ui.clone();
    helper.find("td").each(function (index) {
      $(this).width(originals.eq(index).width());
    });
    return helper;
  };

  public start = () => {
    // first drag after load doesn't need offset
    if (this.sortableFirst) {
      this.wscrolltop = $(window).scrollTop();
    }
    this.sortableFirst = true;
  };

  public sort = (event: JQueryEventObject, ui: ng.ui.UISortableUIParams<any>) => {};
  public remove = (event: JQueryEventObject, ui: ng.ui.UISortableUIParams<any>) => {};
  public receive = (event: JQueryEventObject, ui: ng.ui.UISortableUIParams<any>) => {};

  public change = () => {
    // after the first drag change, we need the offset
    this.sortableFirst = true;
    this.wscrolltop = $(window).scrollTop();
  };
}
