import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import { ConfirmDialog } from "app2/src/components/Common/ConfirmDialog";
import DateTime from "app2/src/components/Common/DateTime";
import { document, jobResourceDocument } from "app2/src/selectors/document.selectors";
import { DocumentRecord, isMeasurementFile, isPdfFile } from "app2/src/records/Document";
import * as documentActions from "app2/src/reducers/document.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import { IncludeCheckBox } from "app2/src/components/Common/IncludeCheckBox";
import { List } from "immutable";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { task } from "app2/src/selectors/task.selectors";
import { DocumentableType } from "app2/src/api/document.service";
import track from "react-tracking";
import { currentJob } from "app2/src/selectors/job.selectors";
import { push } from "connected-react-router/immutable";
import { query } from "app2/src/selectors/router.selectors";
import { DownloadDocumentButton } from "./DownloadDocumentButton";

const mapStateToProps = (state: RootState, ownProps: DocumentProps) => {
  let documentRecord;
  switch (ownProps.documentableType) {
    case "job":
      documentRecord = document(state, { documentId: ownProps.documentId });
      break;
    case "org":
      documentRecord = jobResourceDocument(state, { documentId: ownProps.documentId });
      break;
  }

  return {
    document: documentRecord,
    job: currentJob(state),
    task: task(state, { taskId: documentRecord?.task_id }),
    query: query(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: DocumentProps) => {
  return {
    destroyDocument: (document: DocumentRecord) => dispatch(documentActions.AsyncActions.destroyDocument(document)),
    editBoolean: (name: string, value: boolean) =>
      dispatch(documentActions.Actions.editBoolean(ownProps.documentId, name, value)),
    editJobResourceDisplay: (name: string, value: boolean) =>
      dispatch(jobActions.AsyncActions.editJobResourceDisplay(ownProps.documentId, name, value)),
    updateDocument: () => dispatch(documentActions.AsyncActions.batchUpdateDocument(List([ownProps.documentId]))),
    importMeasurementDocument: (document: DocumentRecord) =>
      dispatch(documentActions.AsyncActions.importMeasurementDocument(document)),
    push: (path: string) => dispatch(push(path)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DocumentProps {
  documentId: number;
  documentableType: DocumentableType;
  dragHandle?: any;
  allowDestroy?: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DocumentProps;
@track(() => {
  return {
    component: "Document",
  };
})
class Document extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.checkBoxOnChange = this.checkBoxOnChange.bind(this);
    this.importMeasurement = this.importMeasurement.bind(this);
    this.destroyDocument = this.destroyDocument.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  @track((props, _state) => ({
    action: "import measurements",
    label: "",
    import_type: props.document.import_type,
    document: props.document.id,
  }))
  public importMeasurement() {
    const { importMeasurementDocument, document } = this.props;
    importMeasurementDocument(document);
  }

  @track((props, _state, [event]) => ({
    action: "checkBoxChanged",
    label: "",
    type: event.target.name,
    value: event.target.checked,
    checkboxType: event.target.name,
    document: props.document.id,
  }))
  public checkBoxOnChange(event: any): void {
    const { documentableType, editBoolean, editJobResourceDisplay, updateDocument } = this.props;
    switch (documentableType) {
      case "job":
        editBoolean(event.target.name, event.target.checked);
        if (event.target.name !== "selected") {
          updateDocument();
        }
        break;
      case "org":
        editJobResourceDisplay(event.target.name, event.target.checked);
        break;
    }
  }

  @track((props, _state) => ({
    action: "document deleted",
    label: "",
    document: props.document.id,
  }))
  public async destroyDocument() {
    const { document, destroyDocument } = this.props;
    await destroyDocument(document);
  }

  public navigate() {
    const { document, job, push, query } = this.props;
    const queryParams = new URLSearchParams({
      ...query.toJS(),
      document_id: document.id,
    });
    push(`/jobs/${job.id}/documents?${queryParams.toString()}`);
  }

  public render() {
    const { document, task, dragHandle, allowDestroy, documentableType } = this.props;

    if (_.isNullOrUndefined(document)) {
      return null;
    }

    const deletedDocument = _.isNull(document.name);
    const isTaskLoading = task && task?.status !== "finished";
    const showCheckBox = deletedDocument || (isPdfFile(document) && !document.loading && !isTaskLoading);

    return (
      <>
        <Row className="table-row">
          <Col sm={1} title={document.name}>
            {documentableType === "job" && (
              <IncludeCheckBox name="selected" checked={document.selected} onChange={this.checkBoxOnChange} />
            )}
            {dragHandle && <img {...dragHandle} src="/assets/images/icons/ic_sort.ea6f8933.png" />}
          </Col>
          <Col className="break-word">{deletedDocument ? `${document.id}: Deleted Document` : document.name}</Col>
          <Col sm={2}>{deletedDocument ? null : <DateTime date={document.created_at} variant="vertical" />}</Col>
          <Col sm={2}>
            <SpinnerComponent localProperty={isTaskLoading || document.loading} localInline />
            {showCheckBox && (
              <IncludeCheckBox
                name="displayInProposal"
                checked={document.displayInProposal}
                onChange={this.checkBoxOnChange}
              />
            )}
          </Col>
          <Col sm={2}>
            <SpinnerComponent localProperty={isTaskLoading || document.loading} localInline />
            {showCheckBox && (
              <IncludeCheckBox
                name="displayInAgreement"
                checked={document.displayInAgreement}
                onChange={this.checkBoxOnChange}
              />
            )}
          </Col>
          <Col sm={2}>
            {isTaskLoading ? (
              <a className="action-button" title="Processing" data-testid="gear-spinner">
                <i className="rsf-cog-link fa-spin rsf-base-66"></i>
              </a>
            ) : (
              <>
                {deletedDocument ? null : (
                  <DownloadDocumentButton documentId={document.id}>
                    <i
                      className="action-button rsf-pdf-link rsf-base-66"
                      title="Download File"
                      data-testid="download-link"></i>
                  </DownloadDocumentButton>
                )}
                {isPdfFile(document) && (
                  <i
                    title="View PDF"
                    className="action-button rsf-magic-link rsf-base-66 document-view-pdf"
                    onClick={this.navigate}></i>
                )}
                {documentableType === "job" && (
                  <>
                    {allowDestroy && (
                      <ConfirmDialog title={`Are you sure you want to delete document: ${document.name}?`}>
                        {(confirm) => (
                          <a
                            className="action-button"
                            data-testid="destroy"
                            title="Delete Document"
                            onClick={confirm(this.destroyDocument)}>
                            <i className="rsf-delete-link rsf-base-66"></i>
                          </a>
                        )}
                      </ConfirmDialog>
                    )}
                    {isMeasurementFile(document) && (
                      <a
                        className="action-button"
                        data-testid="measurement"
                        title={`Import ${document.import_type}`}
                        onClick={this.importMeasurement}>
                        <i className="rsf-import-link rsf-base-66"></i>
                      </a>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default connector(Document);
