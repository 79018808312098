import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { IAccount, IInvoiceTextLine } from "app/src/Billing/Models/Account";
import { IInvoiceItemResponse } from "app/src/Billing/Models/InvoiceItem";
import { IUser } from "app/src/Models/User";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { IPlan, IPlanResponse } from "app/src/Billing/Models/Plan";
import { IFee, IFeeResponse } from "app/src/Billing/Models/Fee";
import { ISession } from "app/src/Common/SessionService";
import { ISubscription, ISubscriptionResponse } from "app/src/Billing/Models/Subscription";
import { IDirtyWatcher, IDirtyMerge } from "app/src/Common/DirtyWatcher";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IOrderResponse } from "../../Billing/Models/Order";
import { IPagingQueryMetadata, PagingQueryMetadata } from "../../Models/PagingMetadata";
import { IRepository } from "../../Common/Repository";
import * as angulartics from "angulartics";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { IOrg } from "app/src/Models/Org";
import { IUserResource } from "app/src/Models/User";
import { IChargebeeSubscriptionResponse } from "app/src/Billing/Models/ChargebeeSubscription";

export class OrgBillingTabCtrl extends BaseOrgTabCtrl implements IDirtyMerge {
  public orderMeta: IPagingQueryMetadata = new PagingQueryMetadata();
  public account: IAccount;
  public planResponse: IPlanResponse;
  public feeResponse: IFeeResponse;
  public selectedPlan: IPlan;
  public selectedFee: IFee;
  public emailingPromise: ng.IPromise<any>;
  public disablePayMe = false;
  public payMeEmail: string;
  public invoiceItems: IInvoiceItemResponse;
  public orders: IOrderResponse;
  public subscription: ISubscription;
  public stripeData: any;
  public billingTypes = [
    "unset",
    "free",
    "freemium",
    "test",
    "pilot",
    "stripe",
    "invoice",
    "canceled",
    "partner",
    "internal",
  ];
  public visualizerTypes = ["none", "renoworks", "chameleon_gaf"];
  public leadSources = this.BaseConfig.BILLING_LEAD_SOURCES;
  public planStructure: IPlan[] = [];
  public selectedPlanStructure: IPlan[] = [];
  public _accountIncludes: string[] = ["plan", "fee", "subscriptions", "customers", "billing", "invoice_text"];
  public trustedLines: IInvoiceTextLine[];
  public subscriptions: IChargebeeSubscriptionResponse;
  public static $inject = [
    "OrgFetcher",
    "Repository",
    "Flash",
    "User",
    "$stateParams",
    "$state",
    "$sce",
    "$q",
    "Session",
    "StripeCheckout",
    "DirtyWatcher",
    "$scope",
    "Pretty",
    "$analytics",
    "BaseConfig",
  ];
  constructor(
    public OrgFetcher: IOrgFetcherService,
    public Repository: IRepository,
    public Flash: IFlash,
    public User: IUserResource,
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private $sce: ng.ISCEService,
    private $q: ng.IQService,
    private Session: ISession,
    public StripeCheckout: ng.stripe.IStripeCheckoutService,
    public DirtyWatcher: IDirtyWatcher,
    public $scope: ng.IScope,
    public Pretty: IPretty,
    protected $analytics: angulartics.IAnalyticsService,
    public BaseConfig: IBaseConfig,
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    this.planResponse = <IPlanResponse>Repository.Plan.query({ "include[]": ["children_ids"], archived: false });

    Session.can("billing", "Global", null).then((value) => {
      <IChargebeeSubscriptionResponse>Repository.ChargebeeSubscription.query({
        org_id: this.$stateParams.id,
      }).$promise.then((data: any) => {
        if (data.subscriptions.length) this.subscriptions = data.subscriptions;
      });
      if (value) {
        this.feeResponse = <IFeeResponse>Repository.Fee.query({ archived: false });
      }
    });

    this.setupAccount();

    OrgFetcher.orgPromise.then(() => {
      this.queryUsers().then((users: IUser[]) => {
        const firstOrgAdmin = _.find(users, (u: IUser) => {
          return u.accessAtOrg(this.org).template === "org_admin";
        });
        if (firstOrgAdmin) {
          this.payMeEmail = firstOrgAdmin.email;
        }
      });
    });

    DirtyWatcher.setup($scope, this);
  }

  public queryUsers() {
    return this.User.query({ org_id: this.$stateParams.id }).$promise.then((data: any) => {
      return data.users;
    });
  }

  public accountDataSetup() {
    if (!_.isEmpty(this.account.subscriptions)) {
      this.subscription = this.account.subscriptions[0];
    }
    if (!_.isEmpty(this.account.customers)) {
      this.stripeData = this.account.customers[0].stripe_data;
    }

    if (this.account.plan) {
      this.account.plan = this.Repository.Plan.fromJSON(this.account.plan);
      this.planResponse.$promise.then(() => {
        this.planStructure = this.account.plan.buildStructure(this.planResponse.plans);
        this.selectedPlanStructure = this.planStructure;
        this.selectedPlan = this.planStructure[0];
      });
    }

    if (this.account.fee) {
      this.selectedFee = this.account.fee;
    }
  }

  public sendPayMeEmail(reminder = false) {
    if (!this.payMeEmail || this.disablePayMe) {
      return;
    }

    const args: any = {
      email: this.payMeEmail,
    };

    if (reminder) {
      args["reminder"] = true;
    }

    this.disablePayMe = true;
    this.emailingPromise = this.org
      .$invitePayMe(args)
      .then(() => {
        this.disablePayMe = false;
        this.trackEvent("send_pay_me_email", {
          category: "OrgBilling",
        });
        this.Flash.addMessage(FlashLevels.success, "Email successfully sent!");
      })
      .catch(() => {
        this.disablePayMe = false;
        this.Flash.addMessage(FlashLevels.danger, "There were problems sending the email.");
      });
  }

  public save() {
    let promise: ng.IPromise<any>;
    if (
      this.selectedFee &&
      this.selectedFee.id &&
      (!this.account || !this.account.fee || this.account.fee.id !== this.selectedFee.id)
    ) {
      promise = this.Repository.Fee.subscribe({ id: this.selectedFee.id, org_id: this.org.id }).$promise;
    }

    if (promise) {
      this.spinnerPromise = promise.then(() => {
        return this.setupAccount().then(() => {
          this.trackEvent("save_billing", {
            category: "OrgBilling",
            selectedPlan: this.selectedPlan.id,
          });
          this.Flash.addMessage(FlashLevels.success, "Billing Saved");
        });
      });
    }
  }

  public saveBillingType() {
    this.spinnerPromise = this.OrgFetcher.save(this.org).then((org: IOrg) => {
      this.org = org;
      this.trackEvent("save_billing_type", {
        category: "OrgBilling",
      });
      this.Flash.addMessage(FlashLevels.success, "Billing Type successfully set.");
    });
  }

  public filterPlans(plan: IPlan) {
    return !(_.isArray(plan.children_ids) && plan.children_ids.length > 0);
  }

  public updateSelectedStructure() {
    this.selectedPlanStructure = this.selectedPlan.buildStructure(this.planResponse.plans);
    this.trackEvent("update_selected_structure", {
      category: "OrgBilling",
    });
  }

  public updateStripe() {
    this.StripeCheckout.load().then(() => {
      const handler = this.StripeCheckout.configure(<ng.stripe.IStripeCheckoutConfigureOptions>{
        name: this.BaseConfig.STRIPE_NAME,
        allowRememberMe: false,
        image: this.BaseConfig.STRIPE_IMAGE,
      });

      handler
        .open({
          description: this.account.plan.name,
        })
        .then((result) => {
          this.account = this.Repository.Account.update({
            id: this.account.id,
            org_id: this.account.org_id,
            email: result[0].email,
            stripe_id: result[0].id,
            include: this._accountIncludes,
          });
          this.spinnerPromise = this.account.$promise.then(() => {
            this.accountDataSetup();
          });
        });
    });
  }

  public queryOrders(): ng.IPromise<any> {
    const params: any = {
      page: this.orderMeta.currentPage,
      sort_by: this.orderMeta.sortBy,
      per_page: this.orderMeta.perPage,
      sort_order: this.orderMeta.sortOrder,
    };

    this.orders = <IOrderResponse>this.Repository.Order.query({ org_id: this.$stateParams["id"] });

    this.orders.$promise.then(() => {
      this.orderMeta.totalCount = this.orders.meta.total_count;
    });

    return this.orders.$promise;
  }

  public updateInvoiceText(lines: IInvoiceTextLine[]) {
    this.$scope.$apply(() => {
      this.account.invoice_text.lines = lines;
      this.updateTrustedInvoiceText();
    });
  }

  public saveAccount() {
    this.spinnerPromise = this.account
      .$update({
        "include[]": this._accountIncludes,
      })
      .then(() => {
        this.updateTrustedInvoiceText();
      });
  }

  private updateTrustedInvoiceText() {
    this.trustedLines = _.map(this.account.invoice_text.lines, (line) => {
      const nl = { ...line };

      if (nl.header === "") {
        nl.header = "&nbsp;"; // this is needed else spacing isn't even
      }

      nl.header = this.$sce.trustAsHtml(nl.header);
      nl.text = this.$sce.trustAsHtml(nl.text);
      return nl;
    });
  }

  private setupAccount(): ng.IPromise<IAccount> {
    this.account = this.Repository.Account.byOrg({
      org_id: this.$stateParams["id"],
      "include[]": this._accountIncludes,
    });

    return this.account.$promise.then(() => {
      this.account.org = this.org;
      this.invoiceItems = <IInvoiceItemResponse>this.Repository.InvoiceItem.query({ org_id: this.$stateParams["id"] });

      this.updateTrustedInvoiceText();

      this.queryOrders();
      this.accountDataSetup();

      return this.account;
    });
  }
}
