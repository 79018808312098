import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import { PresentationTemplateRecord, fromJSON as templateFromJSON } from "app2/src/records/PresentationTemplate";
import { getOrgPresentationTemplates } from "./presentationTemplateSelector";
import { List } from "immutable";
import { activeDynamicLinks } from "../PresentationBuilder/PresentationBuilder.service";
import * as presentationTemplateAction from "app2/src/reducers/presentationTemplate.actions";
import NoTemplateComponent from "./NoTemplatesComponent";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { PresentationRecord } from "app2/src/records/Presentation";
import ManagementItem from "./ManagementItem";
import _track, { Track, TrackingProp } from "react-tracking";
import { TrackingData } from "app2/src/helpers/Analytics";

const mapStateToProps = (state: RootState, ownProps: ManagementProps) => {
  return {
    presentationTemplates: getOrgPresentationTemplates(state, { orgId: ownProps.orgId }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ManagementProps) => {
  return {
    createTemplate: (name: string, sortOrder: number) => {
      let template = templateFromJSON({ name: name, sort_order: sortOrder });
      template = template.updateIn(["template", "slides"], (l) => {
        return ownProps.presentation.dynamic_links.map((dl) => dl.presentable_id);
      });

      return dispatch(presentationTemplateAction.AsyncActions.addPresentationTemplate(ownProps.orgId, template));
    },
    updateTemplateName: (pt: PresentationTemplateRecord) => {
      return dispatch(presentationTemplateAction.AsyncActions.updatePresentationTemplate(pt));
    },
    updateTemplate: (pt: PresentationTemplateRecord) => {
      pt = pt.updateIn(["template", "slides"], (l) => {
        return activeDynamicLinks(ownProps.presentation).map((dl) => dl.presentable_id);
      });

      return dispatch(presentationTemplateAction.AsyncActions.updatePresentationTemplate(pt));
    },
    removeTemplate: (pt: PresentationTemplateRecord) => {
      return dispatch(presentationTemplateAction.AsyncActions.deletePresentation(pt));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ManagementProps {
  orgId: number;
  show: boolean;
  presentation: PresentationRecord;
  onClose: () => void;
  tracking?: TrackingProp;
}

const track: Track<TrackingData, ManagementProps> = _track;

export interface ManagementState {
  newName: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ManagementProps;

@track(
  (props: Props) => {
    return {
      category: "Presentation Template Management",
      action: "Show",
    };
  },
  { dispatchOnMount: false },
)
class Management extends React.Component<Props, ManagementState> {
  public state: ManagementState = { newName: "" };

  constructor(props) {
    super(props);

    this.saveNew = this.saveNew.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.updateTemplateName = this.updateTemplateName.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
  }

  public componentDidMount() {
    this.setState({ newName: "" });
  }

  public render() {
    const { orgId, presentationTemplates } = this.props;

    return (
      <SpinnerComponent stateProperty={["presentationTemplates", "presentationTemplatesByOrgId", orgId, "loading"]}>
        <Modal size="lg" show={this.props.show} onHide={this.closeWindow}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="row-eq-height">
                <Col md={12}>
                  <h3>Manage Presentation Templates</h3>
                </Col>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Row className="align-items-center">
                  <Col sm={12}>{this.renderTemplates(presentationTemplates)}</Col>
                </Row>
                <hr />
                <Form onSubmit={this.saveNew}>
                  <Row className="align-items-center">
                    <Col sm={8}>
                      <Form.Group controlId="name">
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Template Name"
                          value={this.state.newName}
                          onChange={this.setNewName}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Button variant="secondary" type="submit">
                        Save New
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeWindow}>Close</Button>
          </Modal.Footer>
        </Modal>
      </SpinnerComponent>
    );
  }

  public closeWindow() {
    this.clearName();
    this.props.onClose();
  }

  public clearName() {
    this.setState({
      newName: "",
    });
  }

  @track({ action: "Save New Template" })
  public saveNew() {
    event.preventDefault();
    const { newName } = this.state;
    const { createTemplate, presentationTemplates, tracking } = this.props;

    createTemplate(newName, presentationTemplates.size + 1).then((ptr) => {
      tracking.trackEvent({
        action: "Save New Template Successful",
        presentationTemplate: ptr.id,
      });
    });
    this.clearName();
  }

  public setNewName = (e) => {
    this.setState({
      newName: e.target.value,
    });
  };

  public renderTemplates = (templates: List<PresentationTemplateRecord>) => {
    if (templates.size <= 0) {
      return <NoTemplateComponent />;
    }

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: "75%" }}>Existing Template</th>
            <th style={{ width: "25%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((t, idx) => {
            return (
              <ManagementItem
                key={idx}
                presentationTemplate={t}
                update={this.updateTemplate}
                updateName={this.updateTemplateName}
                remove={this.deleteTemplate}
              />
            );
          })}
        </tbody>
      </table>
    );
  };

  public updateTemplate(ptr: PresentationTemplateRecord) {
    const { updateTemplate, tracking } = this.props;
    tracking.trackEvent({
      action: "Update Presentation Template",
      presentationTemplate: ptr.id,
    });
    updateTemplate(ptr).then((updatedPtr) => {
      tracking.trackEvent({
        action: "Update Presentation Template Successful",
        presentationTemplate: ptr.id,
      });
    });
  }

  public updateTemplateName(ptr: PresentationTemplateRecord) {
    const { updateTemplateName, tracking } = this.props;
    tracking.trackEvent({
      action: "Update Presentation Template Name",
      presentationTemplate: ptr.id,
    });
    updateTemplateName(ptr).then((updatedPtr) => {
      tracking.trackEvent({
        action: "Update Presentation Template Name Successful",
        presentationTemplate: ptr.id,
      });
    });
  }

  public deleteTemplate(ptr: PresentationTemplateRecord) {
    const { removeTemplate, tracking } = this.props;
    tracking.trackEvent({
      action: "Delete Presentation Template",
      presentationTemplate: ptr.id,
    });
    removeTemplate(ptr).then((updatedPtr) => {
      tracking.trackEvent({
        action: "Delete Presentation Template Successful",
        presentationTemplate: ptr.id,
      });
    });
  }
}

export default connector(Management);
