import * as ng from "angular";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import * as fileSaver from "file-saver";
import * as uuid from "uuid/v4";
import { List, Map } from "immutable";
import { IOrg, OrgAclType, OrgPrefType } from "app/src/Models/Org";
import { IOrgPrefMarkup } from "app/src/Models/OrgPreference";
import { IEstimate, EstimateKind } from "app/src/Models/Estimate";
import { IJob, IJobEvent } from "app/src/Models/Job";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import { IPaymentTermResponse } from "app/src/Models/PaymentTerm";
import { IEstimateTemplate, IEstimateTemplateGroup, IEstimateTemplateGroups } from "app/src/Models/EstimateTemplate";
import { IMeasurementLink, IMeasurementLinkResponse } from "app/src/Models/MeasurementLink";
import { ICustomProduct } from "app/src/Models/Product";
import { IMeasurement } from "app/src/Models/Measurement";
import { IConvert } from "app/src/Common/UnitConversionService";
import { IOpeningEstimation } from "app/src/Models/OpeningEstimation";
import { IOpening } from "app/src/Models/Opening";
import { AddEstimateLineItemOptionCmd } from "app/src/Commands/Estimator/AddEstimateLineItemOptionCmd";
import { IEventingFactory } from "app/src/Common/EventingFactory";
import { IRepository } from "../Common/Repository";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { Task, ITask } from "app/src/Models/Task";
import { paymentTermItemFromJSON } from "app2/src/records/PaymentTermTemplate";
import { useSelector, dispatch } from "app2/src/storeRegistry";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import * as jobActions from "app2/src/reducers/job.actions";
import * as measurementActions from "app2/src/reducers/measurement.actions";
import * as activatedPriceListActions from "app2/src/reducers/activatedPriceList.actions";
import * as fencingActions from "app2/src/reducers/measurements/fencing.actions";
import { estimate, toEstimateJson } from "app2/src/selectors/estimate.selectors";
import { org } from "app2/src/selectors/org.selectors";
import { job } from "app2/src/selectors/job.selectors";
import { currentMeasurementId, measurement } from "app2/src/selectors/measurement.selectors";
import { ProductRecord } from "app2/src/records/Product";
import { cachedProduct, cachedProductByUuid } from "app2/src/selectors/product.selectors";
import { cachedProductOptionGroup } from "app2/src/selectors/productOptionGroup.selectors";
import {
  cachedProductOption,
  cachedProductOptions,
  cachedProductOptionByMatchId,
  cachedProductOptionsByUuid,
} from "app2/src/selectors/productOption.selectors";
import { ProductToOptionGroupLinkRecord } from "app2/src/records/ProductToOptionGroupLink";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { door } from "app2/src/selectors/door.selectors";
import { window } from "app2/src/selectors/window.selectors";
import { calculateFormula } from "app2/src/records/Measurement";
import { estimateService } from "app2/src/api/estimate.service";
import { measurementFencing } from "app2/src/selectors/measurements/fencing.selectors";
import { rooms as roomsSelector } from "app2/src/selectors/room.selectors";
import { calculateRoomFormula } from "app2/src/records/Room";
import { IEstimateGroup } from "../Models/EstimateGroup";

export class EstimatorService {
  public priceListPromise: ng.IPromise<any>;
  public includes: string[] = estimateService.include;
  public estimate: IEstimate;
  public job: IJob;
  public org: IOrg;
  public measurement: IMeasurement;
  public paymentTerms: IPaymentTermResponse;
  public links: IMeasurementLinkResponse;
  public linksById: { number: IMeasurementLink } = {} as { number: IMeasurementLink };

  private _originalState: any;

  public static $inject = [
    "Repository",
    "$q",
    "$timeout",
    "$http",
    "$rootScope",
    "Conversion",
    "EventingFactory",
    "Flash",
    "$analytics",
  ];
  constructor(
    public Repository: IRepository,
    public $q: ng.IQService,
    public $timeout: ng.ITimeoutService,
    public $http: ng.IHttpService,
    public $rootScope: RsfRootScope,
    public Conversion: IConvert,
    public EventingFactory: IEventingFactory,
    public Flash: IFlash,
    protected $analytics: angulartics.IAnalyticsService,
  ) {
    $rootScope.$on("job:updated", (event: ng.IAngularEvent, args: IJobEvent) => {
      this.receiveReduxJob(args.job);
    });
  }

  public loadEstimate(id: number): IEstimate {
    const estimateRecord = useSelector((state) => estimate(state, { estimateId: id }));

    //noinspection TypeScriptValidateTypes
    if ((id as any as string) === "new") {
      return this.estimate;
    } else if (!estimateRecord) {
      return this.get(id);
    } else {
      if (estimateRecord.loading) {
        return this.estimate;
      }

      let previous_id = "none" as any as number;
      if (this.estimate) {
        previous_id = this.estimate.id;
      }

      const reduxEstimate = this.getReduxEstimate(id);
      const deferred: ng.IDeferred<IEstimate> = this.$q.defer();
      this.estimate.$promise = deferred.promise;
      Object.assign(this.estimate, reduxEstimate);

      _.each(this.estimate.lineItems(), (lineItem) => {
        lineItem.editing = false;
      });
      this.job = this.getReduxJob(this.estimate.job_id);
      this.org = this.getReduxOrg(this.job.org_id);
      if (previous_id !== id) {
        this.resetEstimateState();
      }
      this.setupEstimate(deferred);
      return this.estimate;
    }
  }

  public async loadActivatedPriceList(): Promise<void> {
    const activatedPriceListId = this.estimate.activated_price_list_id;
    const deferred: ng.IDeferred<void> = this.$q.defer();
    this.priceListPromise = deferred.promise;

    await dispatch(activatedPriceListActions.AsyncActions.getCached(this.org.id, activatedPriceListId));
    deferred.resolve();

    return this.priceListPromise;
  }

  public reloadEstimate(id: number): IEstimate {
    if (id === ("new" as any as number)) {
      this.estimate = this.Repository.Estimate.createNew({});
      const deferred: ng.IDeferred<IEstimate> = this.$q.defer();
      this.estimate.$promise = deferred.promise;
      this.resetEstimateState();
      deferred.resolve();
      return this.estimate;
    } else {
      return this.get(id);
    }
  }

  public createEstimate(jobId: number, estimateKind: EstimateKind = "price"): IEstimate {
    const deferred: ng.IDeferred<IEstimate> = this.$q.defer();
    this.estimate = this.Repository.Estimate.createNew({});

    this.estimate.id = "new" as any as number;
    this.estimate.job_id = jobId;

    if (estimateKind !== "price") {
      this.estimate.kind = estimateKind;
    }

    const job = this.Repository.Job.get({ id: jobId, "include[]": this.includes });

    job.$promise
      .then(() => {
        this.setupEstimatorService(job);

        this.estimate.createGroup({ name: "Included", included: true });
        if (this.org.can("layered", OrgAclType.estimator, "multiple_groups")) {
          this.estimate.createGroup({ name: "Options", included: false });
        }

        this.estimate.activated_price_list_id = this.org[`activated_${estimateKind}_list`]?.id;
        this.loadActivatedPriceList();

        this.estimate.tax_rate = this.org.preferences.config?.sales_tax?.default_rate;
        this.estimate.taxable_amount = 0.0;
        this.estimate.default_taxable_amount = this.org.preferences.config?.sales_tax?.default_taxable_amount;

        if (this.org.fetchPref<IOrgPrefMarkup>(OrgPrefType.markup).enabled) {
          this.estimate.product_markup = this.org.fetchPref<IOrgPrefMarkup>(OrgPrefType.markup).product_default || 0;
          this.estimate.labor_markup = this.org.fetchPref<IOrgPrefMarkup>(OrgPrefType.markup).labor_default || 0;
        }

        this.paymentTerms = <IPaymentTermResponse>this.Repository.PaymentTerm.query({ org_id: this.org.id });

        return this.$q
          .all([this.priceListPromise, this.paymentTerms.$promise, this.setupMeasurementLinks()])
          .then(() => {
            if (this.paymentTerms.payment_terms.length > 0) {
              const items = _.map(this.paymentTerms.payment_terms[0].breakdown.items, (ptid) =>
                paymentTermItemFromJSON(ptid),
              );

              this.estimate.payment_terms = this.estimate.payment_terms.set("payment_term_items", List(items));
            }

            this.estimate.updateTotal();

            deferred.resolve(this.estimate);
          });
      })
      .catch((e) => {
        deferred.reject(e);
      });

    deferred.promise.then(() => {
      this.resetEstimateState();
    });

    this.estimate.$promise = deferred.promise;
    return this.estimate;
  }

  public saveEstimate(): ng.IPromise<any> {
    this.estimate.updateTotal();
    const deferred: ng.IDeferred<IEstimate> = this.$q.defer();
    this.estimate.$promise = deferred.promise;

    let estimateResource: IEstimate;
    this.estimate["include"] = this.includes;
    if (this.estimate.id > 0) {
      dispatch(estimateActions.Actions.fetchEstimate(this.estimate.id));
      estimateResource = this.Repository.Estimate.save(this.estimate);
    } else {
      estimateResource = this.Repository.Estimate.create(this.estimate);
    }

    estimateResource.$promise
      .then((estimate: IEstimate) => {
        this.setupEstimatorService(estimate.job);
        this.receiveAndSetReduxEstimate(estimate);

        const reduxEstimate = this.getReduxEstimate(estimate.id);
        Object.assign(this.estimate, reduxEstimate);

        deferred.resolve();
      })
      .catch((error: any) => {
        deferred.reject();
        this.Flash.addMessage(
          FlashLevels.danger,
          "There was an error saving your estimate. Please contact support! HERE!",
        );
        throw error;
      });
    return this.estimate.$promise;
  }

  public resetEstimateState() {
    if (this.estimate) {
      this._originalState = angular.toJson(this.Repository.Estimate.transformRequest(this.estimate));
    }
  }

  /**
   * Compares current estimate against saved state
   * true if state has changed else false
   * @returns {boolean}
   */
  public checkEstimateState(): boolean {
    if (!this.estimate) {
      return false;
    }
    return (
      this._originalState !== "" &&
      this._originalState !== angular.toJson(this.Repository.Estimate.transformRequest(this.estimate))
    );
  }

  public savePdf(estimate?: IEstimate, doc_type?: string, options?: any): ng.IPromise<any> {
    if (!estimate) {
      estimate = this.estimate;
    }
    return this.generatePdf(estimate.id, doc_type, options)
      .then((data: any) => {
        fileSaver.saveAs(data.response, estimate.job.name + " - " + estimate.id + ".pdf");
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems downloading your document.  Please try again.  If the problem persists contact support.",
        );
      });
  }

  public saveCsv(estimate?: IEstimate, csv_type?: string): ng.IPromise<any> {
    if (!estimate) {
      estimate = this.estimate;
    }
    return this.generateCsv(estimate, csv_type)
      .then((data: any) => {
        fileSaver.saveAs(data.response, estimate.job.name + " - " + estimate.id + ".csv");
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems downloading your document.  Please try again.  If the problem persists contact support.",
        );
      });
  }

  public generatePdf(estimateId?: number, doc_type?: string, options?: any): ng.IPromise<IEstimate> {
    if (!estimateId) {
      estimateId = this.estimate.id;
    }

    if (!doc_type) {
      doc_type = "estimate";
    }

    return <ng.IPromise<IEstimate>>(
      this.Repository.Estimate.pdf({ id: estimateId, doc_type: doc_type, options: options }).$promise
    );
  }

  public emailPdf(estimateId: number, docType: string, returnObject: any): ng.IPromise<any> {
    return this.Repository.Estimate.email({
      id: estimateId,
      doc_type: docType,
      emails: returnObject.emails,
      message: returnObject.message,
      options: returnObject.options,
    })
      .$promise.then((response: any) => {
        this.Flash.addMessage(FlashLevels.success, "Document was successfully sent.");
        // Event category & action used for analytics job reporting - do not rename and keep upon refactor
        this.trackEvent(docType + " emailed", {
          category: _.toTitleCase(docType),
          estimate: estimateId,
          emails: returnObject.emails,
          message: returnObject.message,
          options: returnObject.options,
        });

        Task.watch(this.$timeout, this.$http, response.location, (task: ITask) => {
          if (task.status === "error") {
            this.Flash.addMessage(FlashLevels.danger, "There was an error with your email: " + task.logs);
          }
        });
      })
      .catch(() => {
        this.Flash.addMessage(
          FlashLevels.danger,
          "There were problems sending your document.  Please try again.  If the problem persists contact support.",
        );
      });
  }

  public generateCsv(estimate?: IEstimate, csv_type?: string): ng.IPromise<IEstimate> {
    if (!estimate) {
      estimate = this.estimate;
    }

    if (!csv_type) {
      csv_type = "product";
    }

    return <ng.IPromise<IEstimate>>this.Repository.Estimate.csv({ id: estimate.id, csv_type: csv_type }).$promise;
  }

  public addMeasurementLink(product: ProductRecord, quantity = 1, showMessage = true, estimateGroup?: IEstimateGroup) {
    const measurement = this.find_link(product);
    if (_.isUndefined(measurement)) {
      return quantity;
    }

    switch (measurement.kind) {
      case "measurement":
        const measure_value = measurement.measure_value.measurement;
        if (_.isUndefined(this.measurement[measure_value]) || _.isNull(this.measurement[measure_value])) {
          if (!_.isNaN(parseFloat(measure_value))) {
            quantity = parseFloat(measure_value);
          }
        } else {
          const measurement_uom = this.measurement.measurement_to_uom[measure_value];
          quantity = this.Conversion.convert(
            this.measurement[measure_value],
            measurement_uom,
            product.uom,
            product,
            showMessage,
          );
        }
        break;
      case "formula":
        {
          const { formula, formula_uom } = measurement.measure_value;
          const { result, error } = calculateFormula(formula, this.measurement);
          if (error) {
            this.Flash.addMessage(
              FlashLevels.warning,
              `There was a problem with the measurement link formula. ${error}`,
            );
          } else {
            quantity = this.Conversion.convert(result, formula_uom, product.uom, product, showMessage);
          }
        }
        break;
      case "interior_measurement":
        {
          if (this.measurement.room_ids?.length === 0) break;

          quantity = 0;
          const measurementLabel = measurement.measure_value.measurement;
          let roomIds = _.isUndefined(estimateGroup)
            ? List()
            : List(estimateGroup.room_estimations.map((room_estimation) => room_estimation.room_id));
          if (roomIds.size === 0) roomIds = List(this.measurement.room_ids);

          const rooms = useSelector((state) => roomsSelector(state, { roomIds }));

          rooms.forEach((r) => {
            if (!_.isUndefined(r.get(measurementLabel)) && !_.isNull(r.get(measurementLabel))) {
              quantity += _.round(r.get(measurementLabel), -2);
            }
          });
          quantity = _.round(quantity, -2);
        }
        break;
      case "interior_formula":
        if (this.measurement.room_ids?.length === 0) break;

        quantity = 0;
        let roomIds = _.isUndefined(estimateGroup)
          ? List()
          : List(estimateGroup.room_estimations.map((room_estimation) => room_estimation.room_id));
        if (roomIds.size === 0) roomIds = List(this.measurement.room_ids);

        const rooms = useSelector((state) => roomsSelector(state, { roomIds }));
        const { formula, formula_uom } = measurement.measure_value;

        rooms.forEach((r) => {
          const { result, error } = calculateRoomFormula(formula, r);
          if (error) {
            this.Flash.addMessage(
              FlashLevels.warning,
              `There was a problem with the measurement link formula. ${error}`,
            );
          } else {
            quantity += this.Conversion.convert(result, formula_uom, product.uom, product, showMessage);
          }
        });
        quantity = _.round(quantity, -2);
        break;
    }

    return quantity;
  }

  public find_link(product: ProductRecord) {
    const link = this.linksById[product.uuid];
    if (_.isUndefined(link)) {
      if (product.parent_id) {
        const parent_product = useSelector((state) =>
          cachedProduct(state, {
            productId: product.parent_id,
            activatedPriceListId: this.estimate.activated_price_list_id,
          }),
        );
        return this.find_link(parent_product);
      }
    }
    return link;
  }

  public createTemplate(data: any) {
    return this.Repository.EstimateTemplate.create({ org_id: this.org.id }, data).$promise.then((resp) => {
      return resp;
    });
  }

  public updateTemplate(template: IEstimateTemplate) {
    return template.$update().then((resp) => {
      return resp;
    });
  }

  public setupNewTemplate() {
    const template: IEstimateTemplateGroups = <IEstimateTemplateGroups>{};
    template.groups = [];
    _.each(this.estimate.existingGroups(), (eg) => {
      const items = {};
      const custom_products = [];
      const line_item_order: Array<{ type: string; order: string }> = [];
      _.each(eg.existingLineItems(), (li) => {
        if (_.isUndefined(li.product_id) || _.isNull(li.product_id)) {
          const custom_product = this.setupCustomProduct(li);
          custom_products.push(custom_product);
          line_item_order.push({
            type: "custom",
            order: custom_product.uuid,
          });
        } else {
          const product = useSelector((state) =>
            cachedProduct(state, {
              activatedPriceListId: this.estimate.activated_price_list_id,
              productId: li.product_id,
            }),
          );
          items[product.uuid] = [];
          line_item_order.push({
            type: "item",
            order: product.uuid,
          });
          _.each(li.options, (opt) => {
            const productOption = useSelector((state) =>
              cachedProductOption(state, {
                activatedPriceListId: this.estimate.activated_price_list_id,
                productOptionId: opt.product_option_id,
              }),
            );
            items[product.uuid].push(productOption.uuid);
          });
        }
      });
      template.groups.push(<IEstimateTemplateGroup>{
        name: eg.name,
        included: eg.included,
        sort_order: eg.sort_order,
        item_uuids: items,
        custom_products: custom_products,
        line_item_order,
      });
    });
    return template;
  }

  public setupTemplate(est_template: IEstimateTemplate) {
    if (!est_template.template.groups) {
      return;
    }
    est_template.template.groups.forEach((etg: IEstimateTemplateGroup) => {
      etg.products = [];
      // we dont have a line item order yet, use the old way.
      if (!etg.line_item_order || etg.line_item_order.length === 0) {
        if (etg.item_uuids) {
          _.each(Object.keys(etg.item_uuids), (key) => {
            this.setupTemplateProductsOptions(key, etg, etg.item_uuids);
          });
        } else {
          // backwards compatibility for templates that don't have support for product options
          _.each(etg.items, (i) => {
            this.setupTemplateProductsOptions(i, etg, etg.items);
          });
        }
        if (etg.custom_products) {
          _.each(etg.custom_products, (cp) => {
            etg.products.push(cp as any as ProductRecord);
          });
        }
      } else {
        etg.line_item_order.forEach((lineItemOrder: { type: string; order: string }) => {
          if (lineItemOrder.type === "custom") {
            const customProduct: ICustomProduct = etg.custom_products.find(
              (custom_product: ICustomProduct) => custom_product.uuid === lineItemOrder.order,
            );
            etg.products.push(customProduct as any as ProductRecord);
          } else if (lineItemOrder.type === "item") {
            this.setupTemplateProductsOptions(lineItemOrder.order, etg, etg.item_uuids);
          }
        });
      }
    });
  }

  public setupTemplateProductsOptions(key: string, etg: IEstimateTemplateGroup, items: any) {
    const product = useSelector((state) =>
      cachedProductByUuid(state, {
        productUuid: key,
        activatedPriceListId: this.estimate.activated_price_list_id,
      }),
    );

    if (!product) {
      return;
    }

    let options = [];
    if (items[key] && items[key].length > 0) {
      options = useSelector((state) =>
        cachedProductOptionsByUuid(state, {
          productId: product.id,
          optionUuids: items[key],
          activatedPriceListId: this.estimate.activated_price_list_id,
        }),
      ).toArray();
    }

    etg.products.push(product);
    if (!etg.optionsByProductId) {
      etg.optionsByProductId = Map<number, ProductOptionRecord[]>();
    }
    etg.optionsByProductId = etg.optionsByProductId.set(product.id, options);
  }

  public setMeasurement(measurement: IMeasurement) {
    this.receiveReduxMeasurement(measurement);
  }

  public addDefaultOptions(lineItem: IEstimateLineItem, product: ProductRecord) {
    if (!product.ptog_links) {
      return;
    }
    product.ptog_links.forEach((link: ProductToOptionGroupLinkRecord) => {
      if (!_.isUndefined(link.options.default)) {
        _.each(Object.keys(link.options.default), (opt_uuid) => {
          const group = useSelector((state) =>
            cachedProductOptionGroup(state, {
              productOptionGroupId: link.option_group_id,
              activatedPriceListId: this.estimate.activated_price_list_id,
            }),
          );
          const options = useSelector((state) =>
            cachedProductOptions(state, {
              productOptionIds: group.option_ids,
              activatedPriceListId: this.estimate.activated_price_list_id,
            }),
          );
          const po: ProductOptionRecord = options.find((opt) => opt.uuid === opt_uuid);

          const cmd_po = new AddEstimateLineItemOptionCmd(po, group, lineItem, this.estimate);
          const quantity = lineItem.openingEstimationMeasurement(po.charge_type, link.options.default[opt_uuid]);
          cmd_po.execute(quantity);
          this.EventingFactory.trackEvent("default product option added", {
            product_option: po.id,
          });
        });
      }
    });
  }

  public addEstimateDefaultOptions(lineItem: IEstimateLineItem) {
    _.each(Object.keys(this.estimate.defaults.options), (opt_match_id) => {
      const pog_match_id = this.estimate.defaults.options[opt_match_id].option_group_uuid;
      const po = useSelector((state) =>
        cachedProductOptionByMatchId(state, {
          productId: lineItem.product_id,
          productOptionGroupMatchId: pog_match_id,
          productOptionMatchId: opt_match_id,
          activatedPriceListId: this.estimate.activated_price_list_id,
        }),
      );
      if (!po) {
        return;
      }
      const optionGroup = useSelector((state) =>
        cachedProductOptionGroup(state, {
          productOptionGroupId: po.option_group_id,
          activatedPriceListId: this.estimate.activated_price_list_id,
        }),
      );

      const cmd_po = new AddEstimateLineItemOptionCmd(po, optionGroup, lineItem, this.estimate);
      const quantity = lineItem.openingEstimationMeasurement(
        po.charge_type,
        this.estimate.defaults.options[opt_match_id].quantity,
      );
      cmd_po.execute(quantity);
      this.EventingFactory.trackEvent("default estimate product option added", {
        product_option: po.id,
      });
    });
  }

  public lineItemEditorOpen() {
    return _.find(this.estimate.lineItems(), (li) => {
      return li.editing === true;
    });
  }

  public prettyOpenings(input_array: any[]) {
    let openings = [];
    if (input_array[0] && input_array[0].openable) {
      // input are opening estimations
      openings = _.pluck(input_array, "openable");
    } else {
      // input are openings
      openings = input_array;
    }
    return (
      _.chain(openings)
        .groupBy((opening) => opening.location)
        .mapObject((oes, _location) => _.map(oes, (opening) => opening.name))
        .pairs()
        // s == ["location", ["opening", "opening", "opening"]]
        .map((s) => `${!s[0] || (s[0] as any) === "null" ? "" : s[0]}: ${s[1].join(", ")}`)
        .join(" | ")
        .value()
    );
  }

  public prettyOpeningsSize(input_array: any[]) {
    let openings = [];
    if (input_array[0] && input_array[0].openable) {
      // input are opening estimations
      openings = _.pluck(input_array, "openable");
    } else {
      // input are openings
      openings = input_array;
    }
    return (
      _.chain(openings)
        .groupBy((opening) => opening.location)
        .mapObject((oes, _location) => {
          return _.chain(oes)
            .groupBy((opening) => `${opening.width}x${opening.height}`)
            .value();
        })
        .pairs()
        // s == ["location", {31x31: ["opening", "opening"], 36x60: ["opening"]}]
        .map((s) => {
          const opening_text = _.chain(s[1])
            .mapObject((oes, _size) => _.map(oes, (opening: IOpening) => opening.name))
            .pairs()
            .map((s) => {
              return `${s[1].join(", ")} (${!s[0] || (s[0] as any) === "null" ? "" : s[0]})`;
            })
            .value();
          return `${!s[0] || (s[0] as any) === "null" ? "" : s[0]}: ${opening_text.join(" ")}`;
        })
        .join(" | ")
        .value()
    );
  }

  public receiveAndSetReduxEstimate(estimate: IEstimate): IEstimate {
    this.receiveReduxEstimate(estimate);
    const reduxEstimate = this.getReduxEstimate(estimate.id);
    Object.assign(this.estimate, reduxEstimate);
    const deferred: ng.IDeferred<IEstimate> = this.$q.defer();
    this.estimate.$promise = deferred.promise;
    this.setupEstimate(deferred);
    return this.estimate;
  }

  public receiveReduxEstimate(estimate: IEstimate): void {
    dispatch(estimateActions.Actions.receiveEstimate(estimate.toEstimateDataJSON()));
  }

  public receiveReduxOrg(org: IOrg): void {
    dispatch(orgActions.Actions.setOrg(JSON.decycle(org)));
  }

  public receiveReduxJob(job: IJob): void {
    dispatch(jobActions.Actions.receiveJob(JSON.decycle(job)));
  }

  public receiveReduxMeasurement(measurement: IMeasurement): void {
    dispatch(measurementActions.Actions.receiveMeasurement(JSON.decycle(measurement)));
  }

  public getReduxEstimate(id: number): IEstimate {
    return this.Repository.Estimate.fromJSON(
      useSelector((state) => toEstimateJson(state, { estimateId: id, fullEstimate: true })),
    );
  }

  public getReduxOrg(id: number): IOrg {
    return this.Repository.Org.fromJSON(useSelector((state) => org(state, { orgId: id })).toJS());
  }

  public getReduxJob(id: number): IJob {
    return this.Repository.Job.fromJSON(useSelector((state) => job(state, { jobId: id })).toJS());
  }

  public getReduxMeasurement(id: number): IMeasurement {
    return this.Repository.Measurement.fromJSON(
      useSelector((state) => measurement(state, { measurementId: id })).toJS(),
    );
  }

  public checkReduxFencing(measurementId: number): void {
    const fencing = useSelector((state) => measurementFencing(state, { measurementId }));
    if (_.isNullOrUndefined(fencing)) {
      dispatch(fencingActions.AsyncActions.fetchByMeasurementId(measurementId, false));
    }
  }

  public setupEstimatorService(job: IJob) {
    this.receiveReduxJob(job);
    this.receiveReduxOrg(job.org);
    this.checkReduxFencing(job.measurement_id);
    this.job = this.getReduxJob(job.id);
    this.org = this.getReduxOrg(job.org_id);
  }

  private get(id: number): IEstimate {
    this.estimate = this.Repository.Estimate.fromJSON({});
    const deferred: ng.IDeferred<IEstimate> = this.$q.defer();
    this.estimate.$promise = deferred.promise;
    const estimateResource = this.Repository.Estimate.get({ id: id, "include[]": this.includes });
    dispatch(estimateActions.Actions.fetchEstimate(id));

    estimateResource.$promise
      .then((estimate: IEstimate) => {
        this.setupEstimatorService(estimate.job);
        this.receiveReduxEstimate(estimate);

        const reduxEstimate = this.getReduxEstimate(id);
        Object.assign(this.estimate, reduxEstimate);

        this.paymentTerms = <IPaymentTermResponse>this.Repository.PaymentTerm.query({ org_id: this.org.id });

        return this.$q.all([this.paymentTerms.$promise, this.setupMeasurementLinks()]).then(() => {
          return this.setupEstimate(deferred) as ng.IPromise<void>;
        });
      })
      .catch((error: any) => {
        deferred.reject();
        this.Flash.addMessage(FlashLevels.danger, "There was an error loading your estimate. Please contact support!");
        console.error(error);
        throw error;
      });

    return this.estimate;
  }

  private async setupEstimate(deferred): Promise<void> {
    await this.loadActivatedPriceList();
    this.setupEstimateLineItems();
    this.estimate.updateTotal();
    this.resetEstimateState();
    deferred.resolve();
    await deferred.promise;
  }

  private setupMeasurement(): ng.IPromise<void> {
    // use $q to 'convert' dispatch/redux Promise to angularjs ng.IPromise
    const deferred = this.$q.defer<void>();

    dispatch(measurementActions.AsyncActions.loadMeasurement()).then(deferred.resolve).catch(deferred.reject);

    deferred.promise.then(() => {
      this.measurement = this.getReduxMeasurement(useSelector((s) => currentMeasurementId(s, {})));
    });

    return deferred.promise;
  }

  private setupEstimateLineItems() {
    _.each(this.estimate.lineItems(), (line_item: IEstimateLineItem) => {
      _.each(line_item.options, (option: IEstimateLineItemOption) => {
        option.estimate_line_item = line_item;
      });
      _.each(line_item.opening_estimations, (oe: IOpeningEstimation) => {
        if (oe.openable) {
          return;
        }
        let openable;
        if (oe.openable_type === "Window") {
          openable = useSelector((state) => window(state, { windowId: oe.openable_id })).toJS();
        } else if (oe.openable_type === "Door") {
          openable = useSelector((state) => door(state, { doorId: oe.openable_id })).toJS();
        }
        if (openable) {
          Object.defineProperty(oe, "openable", { value: openable });
        } else {
          console.error(`Unable to find openable ${oe.openable_type} - ${oe.openable_id}`);
        }
      });
    });
  }

  private setupMeasurementLinks(): ng.IPromise<any> {
    this.links = <IMeasurementLinkResponse>this.Repository.MeasurementLink.query({ org_id: this.org.id });
    return this.$q.all([this.links.$promise, this.setupMeasurement()]).then(() => {
      this.linksById = {} as { number: IMeasurementLink };
      _.each(this.links.measurement_links, (link: IMeasurementLink) => {
        this.linksById[link.item_uuid] = link;
      });
    });
  }

  private setupCustomProduct(li: IEstimateLineItem): ICustomProduct {
    let product_uuid;
    if (li.product_uuid) {
      product_uuid = li.product_uuid;
    } else {
      product_uuid = uuid();
      li.product_uuid = product_uuid;
    }
    return <ICustomProduct>{
      name: li.name,
      uuid: product_uuid,
      description: li.description,
      sort_order: li.sort_order,
      product_price: li.product_price,
      labor_price: li.labor_price,
      quantity: li.quantity,
      uom: li.uom,
      image_ids: [],
      document_ids: [],
    };
  }

  private trackEvent(action, props) {
    this.$analytics.eventTrack(
      action,
      angular.extend(props, {
        job: this.job.id,
        org: this.job.org_id,
      }),
    );
  }
}
