import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { Accordion, Card, Modal, Row, Col, Table } from "react-bootstrap";
import { RootActions, RootState } from "app2/src/reducers";
import { fence } from "app2/src/selectors/measurements/fence.selectors";
import SegmentMeasurements from "./SegmentMeasurements";
import SegmentPost from "./SegmentPosts";

interface FenceSummaryProps {
  fenceId: number;
  fenceNumber: number;
  expanded: boolean;
  readOnly: boolean;
}

const mapStateToProps = (state: RootState, ownProps: FenceSummaryProps) => {
  return {
    fence: fence(state, ownProps),
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FenceSummaryProps;

class FenceSummary extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render() {
    const { fence, fenceNumber, expanded, readOnly } = this.props;

    if (!fence) {
      return null;
    }

    if (fence._destroy) return null;

    let defaultKey = "";
    if (expanded === true) {
      defaultKey = `fence${fence.id}`;
    }

    return (
      <Accordion data-testid="main-accordion" defaultActiveKey={defaultKey}>
        <Card className="mb-2 border-bottom">
          <Accordion.Toggle eventKey={`fence${fence.id}`} as={Card.Header}>
            Fence {fenceNumber}
          </Accordion.Toggle>
          <Accordion.Collapse data-testid="collapse" as={Card.Body} eventKey={`fence${fence.id}`}>
            <>
              <Card className="mb-1 border-left-0">
                <Card.Header>Segments</Card.Header>
                <Card.Body className="p-0">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ width: "25%" }}></th>
                        <th style={{ width: "13%" }}>Length (ft)</th>
                        <th style={{ width: "25%" }}>Gate Count</th>
                        <th style={{ width: "25%" }}>Gate Length(ft)</th>
                        <th style={{ width: "12%" }}>Net (ft)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fence.segmentIds.map((segmentId, idx) => (
                        <SegmentMeasurements key={idx} segmentId={segmentId} readOnly={readOnly} />
                      ))}
                      <tr>
                        <td></td>
                        <td data-testid="total-length">{fence.totalLength.toFixed(1)}</td>
                        <td data-testid="gate-count">{fence.gateCount}</td>
                        <td data-testid="gate-length">{fence.gateLength}</td>
                        <td data-testid="net-length">{fence.netLength.toFixed(1)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Card className="mb-1 border-left-0">
                <Card.Header>Posts</Card.Header>
                <Card.Body className="p-0">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ width: "25%" }}></th>
                        <th style={{ width: "16.66%" }}>4'</th>
                        <th style={{ width: "16.66%" }}>6'</th>
                        <th style={{ width: "16.66%" }}>8'</th>
                        <th style={{ width: "12%" }}>Corner</th>
                        <th style={{ width: "13%" }}>Terminal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fence.segmentIds.map((segmentId, idx) => (
                        <SegmentPost key={idx} segmentId={segmentId} />
                      ))}
                      <tr>
                        <td></td>
                        <td data-testid="line-posts-4">{fence.linePosts4}</td>
                        <td data-testid="line-posts-6">{fence.linePosts6}</td>
                        <td data-testid="line-posts-8">{fence.linePosts8}</td>
                        <td data-testid="corner-posts">{fence.cornerPosts}</td>
                        <td data-testid="terminal-posts">{fence.terminalPosts + fence.gateCount * 2}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default connector(FenceSummary);
