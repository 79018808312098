import * as React from "react";
import { Check } from "@tberrysoln/rsf-form";
import * as config from "react-global-configuration";
import { token as selectToken } from "app2/src/selectors/token.selectors";
import { useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";

export const SettingsForm: React.FC = () => {
  const token = useSelector((state: RootState) => selectToken(state, { kind: "companycam" }));

  return (
    <>
      <Check label="Create a Project when a job is created" name="data.settings.create_project" />
      <Check
        label={`Create a Job in ${config.get("APP_NAME_SHORT")} from Project in CompanyCam`}
        name="data.settings.create_jobs_from_cc_projects"
      />
      <Check
        label={`Reassign a Project in CompanyCam when Updated in ${config.get("APP_NAME_SHORT")}`}
        name="data.settings.reassign_cc_projects"
      />
      <Check
        label="Use the Primary Contact for Naming"
        name="data.settings.use_primary_contact"
        disabled={!token?.getIn(["data", "settings", "create_jobs_from_cc_projects"])}
      />
      <Check
        label="Upon Reassignment, Delete Previously Assigned user in CompanyCam"
        name="data.settings.reassign_delete_previous_assignment"
        disabled={!token?.getIn(["data", "settings", "reassign_cc_projects"])}
      />
    </>
  );
};
