import { fetcher } from "../helpers/Fetcher";
import { IWebhookData, WebhookRecord } from "app2/src/records/Webhook";
import { baseCleanProps, listParams } from "app2/src/api/Api";
import { QueryParamsRecord } from "../records/Page";
import { IMetaPaginationData } from "../records/MetaPagination";
import { IWebhookEventData } from "../records/WebhookEvent";
import { getIn } from "immutable";

export interface IWebhookResponse {
  webhook?: IWebhookData;
}

export interface IWebhookIndexResponse {
  webhooks: IWebhookData[];
  meta: { pagination: IMetaPaginationData };
}

export interface IWebhookEventIndexResponse {
  webhook_events: IWebhookEventData[];
  meta: { pagination: IMetaPaginationData };
}

const url = "api/v1/orgs/:id/webhooks";

export const load = (orgId: number, queryParams: QueryParamsRecord): Promise<IWebhookIndexResponse> => {
  const params: any = listParams(queryParams);

  // if (getIn(queryParams, ["parameters", "org_id"], []).length > 0) {
  //   params["org_id"] = getIn(queryParams, ["parameters", "org_id"], "");
  // }
  // params["org_id"] =
  let actionUrl = urlSetup(orgId);
  actionUrl = fetcher.urlOptions(actionUrl, params);
  return fetcher.get<IWebhookIndexResponse>(actionUrl);
};

export const loadEvents = (
  webhookID: string,
  orgId: number,
  queryParams: QueryParamsRecord,
): Promise<IWebhookEventIndexResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(orgId), webhookID, "events");

  return fetcher.get<IWebhookEventIndexResponse>(actionUrl, queryParams.toJS());
};

export const create = (webhook: WebhookRecord, orgId: number): Promise<IWebhookResponse> => {
  const actionUrl = urlSetup(orgId);
  return fetcher.post<IWebhookResponse>(actionUrl, formatWebhookParams(webhook));
};

export const update = (webhook: WebhookRecord, orgId: number): Promise<IWebhookResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(orgId), webhook.id);
  return fetcher.patch(actionUrl, formatWebhookParams(webhook));
};

export const destroy = async (webhook: WebhookRecord, orgId: number): Promise<IWebhookResponse> => {
  const actionUrl = fetcher.joinUrls(urlSetup(orgId), webhook.id);
  return await fetcher.delete(actionUrl);
};

export const urlSetup = (orgId: number): string => {
  return url.replace(":id", orgId.toString());
};

export const formatWebhookParams = (webhook: WebhookRecord) => {
  const webhookObject = baseCleanProps(webhook.toJS());
  if (webhookObject.auth_type === "none") delete webhookObject.auth_config;
  if (webhookObject.events) {
    const eventsArray = _.chain(Object.keys(webhookObject.events))
      .map((model) => {
        return Object.keys(webhookObject.events[model])
          .filter((e) => webhookObject.events[model][e] === true)
          .map((filtered_event) => `${model}.${filtered_event}`);
      })
      .flatten()
      .value();
    webhookObject.events = eventsArray;
  }
  return { webhook: webhookObject };
};
