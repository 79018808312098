import { RootState } from "app2/src/reducers";
import { document } from "app2/src/selectors/document.selectors";
import { List, Map } from "immutable";
import * as config from "react-global-configuration";

export const setupAvailableDocuments = (currentOrder: List<any>): List<any> => {
  let availableValues = List(config.get("DOCUMENT_ORDER_VALUES"));

  currentOrder.forEach((option) => {
    if (availableValues.includes(option)) {
      return;
    }

    availableValues = availableValues.push(option);
  });

  return availableValues;
};

export const oldDocValueIds = (currentOrder: List<any>): List<number> => {
  return List(currentOrder)
    .filter((co) => {
      if (typeof co === "string") {
        return false;
      }
      return co.get("file_name") === "Migrate";
    })
    .map((co) => co.get("id"));
};

export const migrateOldDocs = (state: RootState, currentOrder: List<any>): List<any> => {
  return currentOrder.map((doc) => {
    if (typeof doc === "string" && doc.startsWith("org_doc:")) {
      const splits = doc.split(":");
      let file_name = "Migrate";
      const id = parseInt(splits[1]);
      const docRecord = document(state, { documentId: id });
      if (docRecord) {
        file_name = docRecord.name;
      }
      return Map({
        id,
        doc_type: "org",
        file_name,
        filter_states: [],
      });
    }

    return doc;
  });
};
