import { useState, useSelector } from "app2/src/storeRegistry";
import { subscriber } from "app2/src/helpers/Subscribe";
import { Permissions, AclKeys, AclValues, UserRecord } from "../records/UserRecord";
import { Map, List } from "immutable";
import { OrgRecord } from "app2/src/records/OrgRecord";
import { SettingsAclKeys, SettingsAclValues } from "app2/src/records/Settings";
import { PreferenceAclKeys, PreferenceAclValues } from "app2/src/records/Preference";
import { OrgAclType } from "app/src/Models/Org";
import { denormalizedReduxUser } from "app2/src/selectors/user.selectors";

class AclPolicy {
  public acl: Permissions;
  public currentUser: UserRecord;
  public org: OrgRecord;
  protected _unsubscribeAcl: () => void;
  protected _unsubscribeUser: () => void;
  protected _savedPrefAcl = Map<string, List<string>>();
  protected _savedSetAcl = Map<string, List<string>>();
  protected _subscribers = List<() => void>();

  constructor() {
    this.acl = useState().getIn(["users", "currentUser", "permissions"]);
    this.currentUser = useState().getIn(["users", "currentUser"]);
    if (this.currentUser) {
      this.org = useState().getIn(["orgs", "orgsById", this.currentUser.org_id]);
    }

    this._unsubscribeAcl = subscriber.subscribe("users.currentUser.permissions", (data: Permissions) => {
      this.acl = data;
      this._subscribers.forEach((callback) => callback());
    });

    this._unsubscribeUser = subscriber.subscribe("users.currentUser", (data: UserRecord) => {
      this.currentUser = data;
      if (this.currentUser) {
        this.org = useState().getIn(["orgs", "orgsById", this.currentUser.org_id]);
      }
      this._subscribers.forEach((callback) => callback());
    });
  }

  public subscribe(fn: () => void): () => void {
    this._subscribers = this._subscribers.push(fn);
    // return "unsubscribe" function
    return () => {
      this._subscribers = this._subscribers.filter((s) => s !== fn);
    };
  }

  public update() {
    this.acl = useState().getIn(["users", "currentUser", "permissions"]);
    this.currentUser = useSelector(denormalizedReduxUser);
    this.org = useState().getIn(["orgs", "orgsById", this.currentUser.org_id]);
  }

  public can(
    action: AclValues | SettingsAclValues | PreferenceAclValues,
    name: AclKeys | SettingsAclKeys | PreferenceAclKeys,
    exactMatch = false,
  ): boolean {
    if (!this.acl) {
      return false;
    }

    if (name.indexOf("org_") === 0) {
      return this.tools(name.substr(4), action);
    }

    if (name.indexOf("orgpref_") === 0) {
      return this.preferencesSetup(name.substr(8), action);
    }

    if (name.indexOf("orgset_") === 0) {
      return this.settingsSetup(name.substr(7), action);
    }

    const resource = _.toUnderscore(name);
    const mappedAction = this.mapAction(action);

    if (!_.isArray(this.acl.get(resource))) {
      return false;
    }

    if (
      _.any(this.acl.get(resource), (defined: string) => {
        if (exactMatch) {
          return defined === mappedAction;
        }
        return defined.indexOf(mappedAction) === 0;
      })
    ) {
      return true;
    }
    return false;
  }

  public orgCan(kind: "setting" | "preference" | "layered", key: OrgAclType, action: string): boolean {
    let acl, permission;

    switch (kind) {
      case "setting":
        acl = this.org.settings.acl;
        permission = acl.get(key) || [];
        break;
      case "preference":
        acl = this.org.preferences.acl;
        permission = acl.get(key) || [];
        break;
      case "layered":
        permission = this.fetchAcl(key);
        break;
    }

    return permission.includes(action);
  }

  private fetchAcl(key: OrgAclType | SettingsAclKeys | PreferenceAclKeys): List<string> {
    this._savedPrefAcl = this._savedPrefAcl || this.pluckAcl(this.org.preferences.config);
    this._savedSetAcl = this._savedSetAcl || this.pluckAcl(this.org.settings.config);

    let pref = this.org.preferences.acl.get(key as PreferenceAclKeys);
    if (!pref) {
      pref = this._savedPrefAcl.get(key);
    }

    let set = this.org.settings.acl.get(key as SettingsAclKeys) as List<string>;
    if (!set) {
      set = this._savedSetAcl.get(key);
    }

    if (!pref) {
      return set || List<string>();
    }

    if (!set) {
      return pref || List<string>();
    }

    const merged = List<string>(
      _.intersection((pref as any as List<string>).toArray(), (set as any as List<string>).toArray()),
    );
    return merged;
  }

  private pluckAcl(config: Map<string, string>): Map<string, List<string>> {
    const keys = config.keySeq();
    let result: Map<string, List<string>> = Map();

    keys.forEach((key) => {
      result = result.set(key, config.getIn([key, "acl"]));
    });

    return result;
  }

  private tools(name: string, action: string): boolean {
    const tools = this.org.settings.acl.get(name as SettingsAclKeys) || List<string>();
    return _.include(tools.toArray(), action);
  }

  private preferencesSetup(name: string, action: string): boolean {
    return this.orgCan("preference", name as any as OrgAclType, action);
  }

  private settingsSetup(name: string, action: string): boolean {
    return this.orgCan("setting", name as any as OrgAclType, action);
  }

  private mapAction(action: string) {
    return action.replace("destroy", "delete");
  }
}

export const Acl = new AclPolicy();
