import * as React from "react";
import Spinner from "app2/src/components/SpinnerComponent";
import { Row, Col, Button } from "react-bootstrap";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import * as noteActions from "app2/src/reducers/note.actions";
import { currentJobId } from "app2/src/selectors/job.selectors";
import { NoteRecord } from "app2/src/records/Note";
import Note from "./Note";
import { Form as FinalForm } from "react-final-form";
import { RsfFormGroup } from "app2/src/components/RsfForms/RsfFormGroup";
import { currentUser } from "app2/src/selectors/user.selectors";
import { UserRecord } from "app2/src/records/UserRecord";
import { StandardPagination } from "../../Pagination/Standard";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import DirtyWatcher from "app2/src/components/Common/DirtyWatcher";
import { noteChanged as noteChangedSelector, note } from "app2/src/selectors/note.selectors";
import { List } from "immutable";
import { Acl } from "app2/src/helpers/Acl";
import { Can } from "app2/src/components/Common/CanComponent";

const mapStateToProps = (state: RootState) => {
  const modelName = "note";
  const noteQueryParams = queryParams(state, { modelName });
  const page = noteQueryParams.get("page");
  const noteIds = ids(state, { modelName });
  const jobId = currentJobId(state);
  let noteChanged = false;
  (noteIds || List()).push(0).forEach((noteId) => {
    noteChanged = noteChanged || noteChangedSelector(state, { noteId });
  });
  const jobCreateOrAssignedAclPermission = Acl.can("create", "job", false);

  return {
    jobId: jobId,
    noteIds,
    currentUser: currentUser(state),
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    noteQueryParams,
    noteChanged,
    note: note(state, { noteId: 0 }),
    jobCreateOrAssignedAclPermission,
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    loadNotes: (jobId: number, queryParams: QueryParamsRecord) =>
      dispatch(noteActions.AsyncActions.loadNotes(jobId, queryParams)),
    updateOrCreateNote: (note: NoteRecord) => dispatch(noteActions.AsyncActions.updateOrCreateNote(note)),
    clearNotes: () => dispatch(paginationActions.Actions.clearPage("note")),
    resetNote: (id: number) => dispatch(noteActions.Actions.resetNote(id)),
    editNote: (note: string) => dispatch(noteActions.Actions.editNote(0, note)),
    setUpNewNote: (jobId: number, currentUser: UserRecord) =>
      dispatch(noteActions.Actions.setUpNewNote(jobId, currentUser)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface NotesProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & NotesProps;

interface INoteTabState {
  loading: boolean;
}
class NoteTab extends React.Component<Props, INoteTabState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.queryNotes = this.queryNotes.bind(this);
    this.validateNote = this.validateNote.bind(this);
    this.check = this.check.bind(this);
    this.reset = this.reset.bind(this);
  }

  public componentDidMount() {
    const { loadNotes, jobId, setUpNewNote, currentUser } = this.props;
    loadNotes(jobId, new QueryParamsRecord());
    setUpNewNote(jobId, currentUser);
  }

  public componentWillUnmount() {
    this.props.clearNotes();
  }

  public onSubmit() {
    const { updateOrCreateNote, note, resetNote } = this.props;
    this.setState({ loading: true });
    const self = this;
    updateOrCreateNote(note).then(() => {
      self.setState({ loading: false });
    });

    resetNote(0);
  }

  public queryNotes(page: number) {
    const { jobId, loadNotes, noteQueryParams } = this.props;
    const newQueryParams = noteQueryParams.setIn(["page"], page);

    loadNotes(jobId, newQueryParams);
  }

  public validateNote(data) {
    const badValues = [null, undefined, ""];
    return badValues.includes(data?.trim());
  }

  public check(): boolean {
    const { noteChanged } = this.props;

    return noteChanged;
  }

  public reset(): void {
    const { noteIds, resetNote } = this.props;
    (noteIds || List()).push(0).forEach((id) => {
      resetNote(id);
    });
  }

  public render() {
    const { noteIds, pagination, editNote, jobCreateOrAssignedAclPermission } = this.props;
    const { loading } = this.state;

    return (
      <React.Fragment>
        <Spinner localProperty={loading} />
        <DirtyWatcher check={this.check} reset={this.reset} reactRouter />
        <Row>
          <Col md={12}>
            <h1 className="tab-title">Notes</h1>
          </Col>
        </Row>
        {noteIds?.size === 0 ? (
          <Row className="text-center" title="No Notes">
            <Col md={12}>
              <div className="form-section blank-slate">
                <img src="/assets/images/icons-large/docs.33cddf3f.png" />
                <h2>No notes added. Let's add one.</h2>
                <FinalForm onSubmit={this.onSubmit}>
                  {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                      <RsfFormGroup
                        as="textarea"
                        name="note"
                        id="initial-note-textarea"
                        label=""
                        placeholder="Add in a new note about this customer."
                        validate={this.validateNote}
                        onChange={(e) => editNote(e.target.value)}
                      />
                      <Button
                        id="job-save-note"
                        title="Add Note None Exist"
                        type="submit"
                        variant="default"
                        className="save-note"
                        disabled={jobCreateOrAssignedAclPermission === false}>
                        Save New Note
                      </Button>
                    </form>
                  )}
                </FinalForm>
              </div>
            </Col>
          </Row>
        ) : (
          <Row title="Note Table Header">
            <Col md={12}>
              <Row>
                <Col md={7} className="table-col-header">
                  Note
                </Col>
                <Col md={2} className="table-col-header">
                  Last Updated
                </Col>
                <Col md={2} className="table-col-header">
                  User
                </Col>
                <Col md={1} className="table-col-header">
                  Actions
                </Col>
              </Row>
              <Can resource="job" permission="create" exactMatch={false}>
                <div className="table-note">
                  <FinalForm onSubmit={this.onSubmit}>
                    {({ handleSubmit, form, submitSucceeded }) => {
                      if (submitSucceeded) {
                        form.reset();
                      }
                      return (
                        <form
                          onSubmit={(...args) => {
                            handleSubmit(...args);
                            form.reset();
                          }}>
                          <Row>
                            <Col md={7} title="Note Textarea">
                              <RsfFormGroup
                                as="textarea"
                                name="note"
                                id="table-note-textarea"
                                label=""
                                validate={this.validateNote}
                                onChange={(e) => editNote(e.target.value)}
                              />
                            </Col>
                            <Col md={4}></Col>
                            <Col md={1} className="btn">
                              <i
                                className="rsf-save-link rsf-base-66"
                                id="job-save-note"
                                title="Save New Note"
                                onClick={handleSubmit}></i>
                            </Col>
                          </Row>
                        </form>
                      );
                    }}
                  </FinalForm>
                </div>
              </Can>
              {noteIds?.map((noteId, idx) => <Note key={idx} noteId={noteId} />)}
              <StandardPagination metadata={pagination} pageChanged={this.queryNotes} />
              <br />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default connector(NoteTab);
