import { IUserResource } from "../Models/User";
import { ISession } from "../Common/SessionService";
import { IBaseConfig } from "../Common/IBaseConfig";

export class AlertCtrl {
  public static $inject = ["User", "Session", "$state", "BaseConfig"];
  constructor(
    public User: IUserResource,
    public Session: ISession,
    public $state: ng.ui.IStateService,
    public BaseConfig: IBaseConfig,
  ) {}

  public cancel() {
    this.$state.go("root.logout");
  }
}
