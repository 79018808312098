import * as imageActions from "./image.actions";
import { Map, Record, List } from "immutable";
import { ImageRecord, fromJSON } from "../records/Image";
import { RootState, RootActions } from ".";
import { reducer as paginationReducer } from "app2/src/reducers/pagination.reducer";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { fetch, loaded, receive, reset } from "app2/src/reducers/Reducer";
import { setDisplay } from "app2/src/records/Image";
import { Nullable } from "app2/src/records";

export const ImageStateRecord = Record({
  byId: Map<number, ImageRecord>(),
  lastSavedById: Map<number, ImageRecord>(),
  unsavedId: -1,
  currentImageId: null as Nullable<number>,
  showInfo: false,
});

export const initialState = ImageStateRecord();

export type ImageState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "images";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let image: ImageRecord;
  switch (action.type) {
    // VIEW
    case imageActions.SET_CURRENT_IMAGE_ID:
      return state.setIn([model, "currentImageId"], action.payload.id);

    case imageActions.SET_SHOW_INFO:
      return state.setIn([model, "showInfo"], action.payload.showInfo);

    // SINGLE
    case imageActions.FETCH_IMAGE:
      return fetch(state, model, fromJSON({ id: action.payload.id }));

    case imageActions.RECEIVE_IMAGE:
      image = fromJSON({ ...action.payload.image });
      return receive(state, model, image);

    case imageActions.RESET_IMAGE:
      return reset(state, model, action.payload.id);

    case imageActions.SET_IMAGE_LOADED:
      return loaded(state, model, action.payload.id);

    case imageActions.DESTROY_IMAGE:
      state = paginationReducer(state, paginationActions.Actions.removeId(action.payload.id, "image"));

      return state;

    case imageActions.EDIT_IMAGE:
      return state.setIn([model, "byId", action.payload.id, action.payload.name], action.payload.value);

    case imageActions.EDIT_BOOLEAN:
      state = state.setIn(["images", "byId", action.payload.id, action.payload.name], action.payload.value);

      const record = state.getIn(["images", "byId", action.payload.id]);
      return state.setIn(["images", "byId", action.payload.id, "display"], setDisplay(record));

    // MULTIPLE
    case imageActions.FETCH_IMAGES:
      const { ids } = action.payload;
      ids.forEach((id) => {
        state = reducer(state, imageActions.Actions.fetchImage(id));
      });
      return state;

    case imageActions.RECEIVE_IMAGES:
      action.payload.images.forEach((image) => {
        state = reducer(state, imageActions.Actions.receiveImage(image));
      });
      return state;

    case imageActions.SET_IMAGES_LOADED:
      action.payload.ids.forEach((id) => {
        state = reducer(state, imageActions.Actions.setImageLoaded(id));
      });

      return state;

    case imageActions.BATCH_EDIT_BOOLEAN:
      action.payload.imageIds.forEach((id) => {
        state = reducer(state, imageActions.Actions.editBoolean(id, action.payload.name, action.payload.value));
      });
      return state;

    case imageActions.BATCH_EDIT_FOLDER_ID:
      action.payload.imageIds.forEach((id) => {
        state = state.setIn(["images", "byId", id, "folder_id"], action.payload.folderId);
      });
      return state;

    case imageActions.BATCH_REMOVE_IDS:
      action.payload.imageIds.forEach((id) => {
        state = paginationReducer(state, paginationActions.Actions.removeId(id, "image"));
      });

      return state;

    // ESTIMATE
    case imageActions.DUPLICATE_LINE_ITEM_IMAGES:
      const lineItem = state.getIn(["estimateLineItems", "byId", action.payload.lineItemId]);

      let imgIds = List();
      let unsavedId = state.getIn([model, "unsavedId"]);
      action.payload.imageIds.map((imageId: number) => {
        let image = state.getIn([model, "byId", imageId]);
        image = image.set("id", unsavedId);
        imgIds = imgIds.push(unsavedId);
        state = state.setIn([model, "byId", image.id], image);
        unsavedId -= 1;
      });

      return state
        .setIn(["estimateLineItems", "byId", lineItem.id, "image_ids"], imgIds)
        .setIn([model, "unsavedId"], unsavedId);

    default:
      return state;
  }
};
