import { IOpening, Opening } from "app/src/Models/Opening";

export interface IDoor extends IOpening {
  clone(): IDoor;
}

export class Door extends Opening implements IDoor {
  constructor() {
    super();
  }

  public clone(): IDoor {
    return Door.fromJSON(this);
  }

  public static fromJSON = (data: any): IDoor => {
    const door = new Door();

    Opening.copyValues(door, data);

    door["kind"] = "Door";
    return door;
  };
}
