import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IRepository } from "../../Common/Repository";
import { ICouponTemplate } from "../Coupons/CouponTemplates";
import * as moment from "moment";
import { IAccount } from "./Account";
import { ISku } from "./Sku";
import { IBaseConfig } from "../../Common/IBaseConfig";

export interface ICoupon extends ng.resource.IResource<ICoupon>, CouponPrototype {}

export interface ICouponResource extends ng.resource.IResourceClass<ICoupon> {
  fromJSON?(data: any): ICoupon;
  inject(repository: IRepository): void;
}

export interface ICouponResponse extends ng.resource.IResourceArray<ICoupon> {
  coupons: ICoupon[];
  meta: IPagingMetadata;
}

class CouponPrototype {
  public id: number;
  public code: string;
  public duration: string;
  public duration_in_months: number;
  public amount_off: number;
  public percent_off: number;
  public max_redemptions: number;
  public redeem_by: Date;
  public skus?: ISku[];
  public current_sku?: ISku;
  public sku_ids?: number[];
  public accounts?: IAccount[];
  public account_ids?: number[];
  public account_id?: number;

  public applyTemplate(template: ICouponTemplate, templateSettings: any): void {
    if (template.duration) {
      this.duration = template.duration;
    }

    if (template.duration_in_months) {
      this.duration_in_months = template.duration_in_months;
    }

    if (template.code) {
      this.code = template.code(templateSettings);
    }

    if (template.max_redemptions) {
      this.max_redemptions = template.max_redemptions;
    }

    if (template.amount_off) {
      this.amount_off = template.amount_off;
    }

    if (template.percent_off) {
      this.percent_off = template.percent_off;
    }

    if (template.redeem_by) {
      this.redeem_by = template.redeem_by;
    } else {
      if (template.redeem_by_days_from_now) {
        this.redeem_by = moment().add(template.redeem_by_days_from_now, "days").toDate();
      }
    }
  }

  public addSku(sku: ISku) {
    _.chain(this.skus).push(sku).uniq().value();

    _.chain(this.sku_ids).push(sku.id).uniq().value();
  }

  public removeSku(sku: ISku) {
    this.skus = _.select(this.skus, (s: ISku) => sku.id !== s.id);
    this.sku_ids.splice(this.sku_ids.indexOf(sku.id), 1);
  }

  public calculate(price?: number): number {
    if (this.amount_off) {
      return this.amount_off;
    }

    if (!price && this.current_sku) {
      price = this.current_sku.price;
    }

    if (this.percent_off && price) {
      return price * (this.percent_off / 100);
    }

    return 0;
  }

  public $saveOrCreate(params?: any, callback?): ng.IPromise<any> {
    if (!this.id || this.id <= 0 || (this.id as any as string) === "Unsaved") {
      return (this as any).$create(params, callback);
    } else {
      return (this as any).$save(params, callback);
    }
  }
}

let resources: IRepository = null;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/billing/v1/accounts/:account_id/coupons/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): ICoupon => {
    if (status < 200 || status > 299) {
      return new Coupon({});
    }
    return Coupon.fromJSON(JSON.parse(response).coupon);
  };

  const multiResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return { coupons: [], metadata: {} };
    }

    const meta: any = JSON.parse(response);

    _.each(meta.coupons, (child, index) => {
      meta.coupons[index] = Coupon.fromJSON(child);
    });

    return meta;
  };

  const transformRequest = (coupon: ICoupon): string => {
    const data = JSON.decycle(coupon);
    delete data.stripe_data;
    return angular.toJson({ coupon: data });
  };

  const Coupon: ICouponResource = <ICouponResource>$resource(
    url,
    { id: "@id", account_id: "@account_id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multiResponseTransform,
        isArray: false,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
      },
    },
  );

  Coupon.fromJSON = (data: any): ICoupon => {
    if (data.redeem_by) {
      data.redeem_by = moment(data.redeem_by + "+0000", "YYYY-MM-DDTHH:mm:ssZ").toDate();
    }

    if (data.skus) {
      _.each(data.skus, (sku_data: any, idx: number) => {
        data.skus[idx] = new resources.Sku(sku_data);
      });
    } else {
      data.skus = [];
    }

    if (!data.sku_ids) {
      data.sku_ids = [];
    }

    return new Coupon(data);
  };

  Coupon.inject = (repository: IRepository) => {
    resources = repository;
  };

  _.hiddenExtend(Coupon.prototype, CouponPrototype.prototype);

  return Coupon;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
