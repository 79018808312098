import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IJobEstimateAddedEvent } from "app/src/Estimator/EstimatorEvents";
import * as angulartics from "angulartics";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IFlash } from "app/src/Common/FlashService";
import { IEstimateResource } from "../../Models/Estimate";
import { IBaseConfig } from "../../Common/IBaseConfig";
import { BaseTabCtrl } from "app/src/Jobs/tabs/BaseTabCtrl";
import { IPromise } from "angular";
import { dispatch } from "app2/src/storeRegistry";
import * as jobActions from "app2/src/reducers/job.actions";

export class EstimateTabCtrl extends BaseTabCtrl {
  public spinnerPromise: IPromise<any>;
  public static $inject = [
    "JobFetcher",
    "$stateParams",
    "$state",
    "$scope",
    "$rootScope",
    "$analytics",
    "EstimatorService",
    "$window",
    "$uibModal",
    "Flash",
    "Estimate",
    "$http",
    "$timeout",
    "BaseConfig",
  ];

  constructor(
    public JobFetcher: IJobFetcherService,
    public $stateParams: ng.ui.IStateParamsService,
    protected $state: ng.ui.IStateService,
    protected $scope: ng.IScope,
    public $rootScope: RsfRootScope,
    protected $analytics: angulartics.IAnalyticsService,
    public EstimatorService: EstimatorService,
    public $window: ng.IWindowService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public Flash: IFlash,
    public Estimate: IEstimateResource,
    public $http: ng.IHttpService,
    public $timeout: ng.ITimeoutService,
    public BaseConfig: IBaseConfig,
  ) {
    super(JobFetcher, $scope, $analytics, $window, $stateParams["id"]);

    if ($stateParams["estimate_id"] === "new") {
      this.spinnerPromise = this.EstimatorService.createEstimate(parseInt($stateParams["id"])).$promise;
    } else {
      const estimateId = parseInt($stateParams["estimate_id"]);
      dispatch(jobActions.Actions.setSelectedEstimateId(estimateId));
      this.spinnerPromise = EstimatorService.reloadEstimate(estimateId).$promise;
    }

    const listener = $rootScope.$on("job:estimate_added", (event: ng.IAngularEvent, args: IJobEstimateAddedEvent) => {
      dispatch(jobActions.Actions.setSelectedEstimateId(args.estimate.id));
      this.$state.go(
        "job_header.job_show.estimate",
        { id: this.job.id, estimate_id: args.estimate.id },
        { notify: false, location: "replace" },
      );
    });

    $scope.$on("$destroy", () => {
      listener();
    });
  }
}
