import * as React from "react";
import { Button } from "react-bootstrap";

export interface FloatButtonProps {
  variant: string;
  buttonName: string;
  disabled: boolean;
  submit(): void;
}

export const FloatButton = (props: FloatButtonProps) => {
  const { variant, buttonName, submit, disabled } = props;

  return (
    <div className="rsf-float-button">
      <Button onClick={submit} variant={variant} disabled={disabled}>
        {buttonName}
      </Button>
    </div>
  );
};
