export class ScrollIf implements ng.IDirective {
  public restrict = "A";

  constructor() {}

  public link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
    scope.$watch(attrs.scrollIf, (value) => {
      if (value) {
        (element as any).scrollParent()[0].scroll(0, 0);
      }
    });
  };

  public static factory(): ng.IDirectiveFactory {
    const directive = ($parse: ng.IParseService, $timeout: ng.ITimeoutService) => new ScrollIf();
    directive.$inject = [];
    return directive;
  }
}
