import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { Map, List } from "immutable";
import { fromJSON as overviewFromJSON, EstimateOverviewRecord } from "../records/EstimateOverview";
import { EstimateRecord, IBaseTotals, IDiscountTotals, ITaxTotals, IEstimateData } from "../records/Estimate";
import { memoizedCalculatePaymentTerms } from "../records/PaymentTerm";
import { EstimateGroupRecord } from "../records/EstimateGroup";
import { EstimateLineItemRecord, isDiscountable } from "../records/EstimateLineItem";
import {
  EstimateLineItemOptionRecord,
  calculateLaborPrice,
  calculateProductPrice,
} from "../records/EstimateLineItemOption";
import { EstimateDiscountRecord } from "../records/EstimateDiscount";
import { toEstimateGroupsJson, estimateGroupsById, estimateGroups } from "./estimateGroup.selectors";
import { estimateLineItems } from "./estimateLineItem.selectors";
import { estimateLineItemOptions } from "./estimateLineItemOption.selectors";
import {
  estimateFinanceOptions,
  toEstimateFinanceOptionsJson,
} from "app2/src/selectors/estimateFinanceOption.selectors";
import { model } from "app2/src/reducers/estimate.reducer";
import { EstimateSignedDocumentRecord } from "../records/EstimateSignedDocument";
import { documents } from "./document.selectors";
import { DocumentRecord } from "../records/Document";

export const estimate = (state: RootState, props: any) => state.getIn([model, "byId", props.estimateId]);
export const lastSavedEstimate = (state: RootState, props: any) =>
  state.getIn([model, "lastSavedById", props.estimateId]);
export const estimateFinancedAmount = (state: RootState, props: any): number =>
  state.getIn([model, "byId", props.estimateId, "payment_terms", "financed_amount"]);
export const landingEstimate = (state: RootState, props: any) => state.getIn([model, "byKey", props.key]);
export const estimatesById = (state: RootState) => state.getIn([model, "byId"]);
export const lastSavedById = (state: RootState) => state.getIn([model, "lastSavedById"]);
export const estimateUnsavedId = (state: RootState) => state.getIn([model, "unsavedId"]);
export const jobOverviewEstimates = (state: RootState, props: any): List<EstimateOverviewRecord> =>
  state.getIn([model, "estimateOverviewsByJob", props.jobId]);
export const financeOptionsById = (state: RootState) => state.getIn(["orgs", "financeOptions", "byId"]);

export const estimateFinanceOptionIds = createSelector(
  [estimate, (state, props) => ({ state, props })],
  (estimate, stateProps) => {
    const { state } = stateProps;
    const efos = estimateFinanceOptions(state, { estimateFinanceOptionIds: estimate.finance_option_ids });
    return efos.map((efo) => efo.finance_option_id);
  },
);

export const toEstimateOverview = createSelector(
  [estimate, estimateGroupsById],
  (estimate: EstimateRecord, estimateGroupsById: Map<number, EstimateGroupRecord>): EstimateOverviewRecord => {
    return overviewFromJSON({
      id: estimate.id,
      line_items_count: countLineItems(estimate.group_ids, estimateGroupsById),
      name: estimate.name,
      updated_at: estimate.updated_at,
      total: estimate.total,
    });
  },
);

/**
 * Gets the line items in the estimate
 * Pass in the `includeAll` prop to get line items in all groups, not just the included ones.
 * @param {RootState} state The RootState
 * @param {{ estimateId: number, includeAll?: boolean }} props The props
 */
export const lineItemsEstimate = createSelector(
  [estimate, (state, props) => ({ state, props })],
  (estimate: EstimateRecord, stateProps: any) => {
    const { state, props } = stateProps;

    const groups = props.includeAll
      ? List([
          ...estimateGroups(state, { estimateGroupIds: estimate.group_ids, included: true }),
          ...estimateGroups(state, { estimateGroupIds: estimate.group_ids, included: false }),
        ])
      : estimateGroups(state, { estimateGroupIds: estimate.group_ids });

    const estimateLineItemIds = groups
      .map((group: EstimateGroupRecord) => {
        return group.line_item_ids;
      })
      .flatten();
    return estimateLineItems(state, { ...props, estimateLineItemIds });
  },
);

export const lineItemTotals = createSelector(
  [lineItemsEstimate, (state, props) => ({ state, props })],
  (lineItemsEstimate: List<EstimateLineItemRecord>, stateProps: any): IBaseTotals => {
    return calculateLineItemSum(stateProps, lineItemsEstimate);
  },
);

export const updateBaseTotal = createSelector(
  [estimate, lineItemTotals],
  (estimate: EstimateRecord, lineItemTotals: IBaseTotals): EstimateRecord => {
    return estimate.merge(lineItemTotals);
  },
);

export const updateDiscountTotal = createSelector([estimate], (estimate: EstimateRecord): EstimateRecord => {
  const totals: IDiscountTotals = { discount: 0, running_discountable: estimate.discountable_total };
  totals.discount = estimate.discounts.reduce((memo: number, discount: EstimateDiscountRecord) => {
    totals.running_discountable = _.round(totals.running_discountable - discount.calculated, -2);
    return _.round(memo + discount.calculated, -2);
  }, 0);
  return estimate.merge(totals);
});

export const updateTaxTotal = createSelector([estimate], (estimate: EstimateRecord): EstimateRecord => {
  const totals: ITaxTotals = { taxable_amount: 0, tax_total: 0, total: 0 };
  switch (estimate.default_taxable_amount) {
    case "product":
      totals.taxable_amount = estimate.product_total - estimate.discount;
      break;
    case "labor":
      totals.taxable_amount = estimate.labor_total;
      break;
    case "product_labor":
      totals.taxable_amount = estimate.product_total - estimate.discount + estimate.labor_total;
      break;
  }

  totals.taxable_amount = _.max([totals.taxable_amount, 0]);
  if (totals.taxable_amount && estimate.tax_rate) {
    totals.tax_total = _.round(totals.taxable_amount * (estimate.tax_rate / 100.0), -2);
  } else {
    totals.tax_total = 0.0;
  }

  totals.total = _.round(estimate.product_total - estimate.discount + estimate.labor_total + totals.tax_total, -2);
  return estimate.merge(totals);
});

export const updatePaymentTermTotal = createSelector([estimate], (estimate: EstimateRecord): EstimateRecord => {
  return estimate.set("payment_terms", memoizedCalculatePaymentTerms(estimate.payment_terms, estimate.total));
});

export const toEstimateJson = createSelector(
  [estimate, (state, props) => ({ state, props })],
  (estimate: EstimateRecord, stateProps): IEstimateData => {
    const { state, props } = stateProps;
    const estimateData = estimate.toJS() as any;

    estimateData.groups = toEstimateGroupsJson(state, { ...props, estimateGroupIds: estimate.group_ids });
    estimateData.finance_options = toEstimateFinanceOptionsJson(state, {
      ...props,
      estimateFinanceOptionIds: estimate.finance_option_ids,
    });
    delete estimateData.group_ids;
    delete estimateData.finance_option_ids;
    delete estimateData.discountable_total;
    delete estimateData.labor_total;
    delete estimateData.product_total;
    delete estimateData.running_discountable;
    delete estimateData.loading;
    delete estimateData.line_items_count;
    if (!estimateData._destroy) {
      delete estimateData._destroy;
    }

    return estimateData as IEstimateData;
  },
);

export const countLineItems = (
  group_ids: List<number>,
  estimateGroupsById: Map<number, EstimateGroupRecord>,
): number => {
  return group_ids.reduce((value, gid) => {
    const group = estimateGroupsById.get(gid);
    return value + group.line_item_ids.count();
  }, 0);
};

export const calculateLineItemSum = (stateProps, lineItems) => {
  const { state, props } = stateProps;
  return lineItems.reduce(
    (sum: IBaseTotals, item: EstimateLineItemRecord) => {
      sum.subtotal = _.round(sum.subtotal + item.ext_price, -2);
      sum.labor_total = sum.labor_total + _.round(item.quantity * item.labor_price, -2);
      sum.product_total = sum.product_total + _.round(item.quantity * item.product_price, -2);
      if (isDiscountable(item)) {
        sum.discountable_total = _.round(sum.discountable_total + item.ext_price, -2);
      }
      sum = estimateLineItemOptions(state, { ...props, estimateLineItemOptionIds: item.option_ids }).reduce(
        (sum: IBaseTotals, option: EstimateLineItemOptionRecord) => {
          if (option._destroy || option.pog_type === "cost") {
            return;
          }
          sum.subtotal = sum.subtotal + option.ext_price;
          sum.labor_total = sum.labor_total + calculateLaborPrice(option, item);
          sum.product_total = sum.product_total + calculateProductPrice(option, item);
          if (isDiscountable(item)) {
            sum.discountable_total = sum.discountable_total + option.ext_price;
          }

          return sum;
        },
        sum,
      );

      return sum;
    },
    { subtotal: 0, labor_total: 0, product_total: 0, discountable_total: 0 },
  );
};

/**
 * Calculates the sum of the cost of all line items in the included groups.
 *
 * @param {RootState} state The RootState
 * @param {number} estimateId
 * @returns {number} cost
 */
export const calculateEstimateCost = (state: RootState, estimateId: number) => {
  const estimateRecord: EstimateRecord = estimate(state, { estimateId });
  if (!estimateRecord) return 0;

  const lineItems: List<EstimateLineItemRecord> = lineItemsEstimate(state, { estimateId });
  if (lineItems.isEmpty()) return 0;

  let sum = 0;
  lineItems.forEach((lineItem) => {
    sum += lineItem.get("cost") * lineItem.get("quantity");
    const options = estimateLineItemOptions(state, { estimateLineItemOptionIds: lineItem.get("option_ids") });
    options.forEach((option) => {
      if (option.get("charge_type") === "per_quantity") {
        sum += option.get("quantity") * lineItem.get("quantity") * option.get("cost");
      } else {
        sum += option.get("quantity") * option.get("cost");
      }
    });
  });

  return sum;
};

export const isEstimateLoading = (state: RootState, estimateId: number): boolean => {
  return state.getIn(["estimates", "byId", estimateId, "loading"]);
};

/**
 * Returns a single job overview estimate
 *
 * @param {RootState} state The RootState
 * @param {number} estimateId
 * @returns {EstimateRecord} EstimateRecord
 */
export const estimateOverview = createSelector(
  [jobOverviewEstimates, (state, props) => ({ state, props })],
  (estimates: List<EstimateOverviewRecord>, { props }) => estimates?.find((e) => e.id === props.estimateId),
);

/**
 * Returns a list of EstimateSignedDocumentRecords for the given job id
 *
 * @param {RootState} state The RootState
 * @param {{jobId: number}} options
 * @returns {List<EstimateSignedDocumentRecord>} List
 */
export const jobEstimateSignedDocuments = (
  state: RootState,
  props: { jobId: number },
): List<EstimateSignedDocumentRecord> => state.getIn(["estimates", "estimateDocumentsByJobId", props.jobId]);

/**
 * Returns the ids of the signed documents for the given estimate id
 *
 * @param {RootState} state The RootState
 * @param {{jobId: number, estimateId: number }} options
 * @returns {List<EstimateSignedDocumentRecord>} List
 */
export const estimateSignedDocuments: (
  state: RootState,
  props: { jobId: number; estimateId: number },
) => List<DocumentRecord> = createSelector(
  [jobEstimateSignedDocuments, (state, props: { jobId: number; estimateId: number }) => ({ state, props })],
  (list: List<EstimateSignedDocumentRecord>, { state, props }) => {
    const documentIds = list?.find((e) => e.estimateId === props.estimateId)?.signedDocumentIds;
    if (!documentIds) return List();
    return documents(state, { documentIds });
  },
);
