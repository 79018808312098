import * as estimateTemplateActions from "./estimateTemplate.actions";
import { Map, Record } from "immutable";
import { EstimateTemplateRecord, fromJSON } from "../records/EstimateTemplate";
import { RootActions, RootState } from ".";
import { reducer as paginationReducer } from "app2/src/reducers/pagination.reducer";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { fetch, receive, reset, loaded } from "app2/src/reducers/Reducer";

export const EstimateTemplateStateRecord = Record({
  byId: Map<number, EstimateTemplateRecord>(),
  lastSavedById: Map<number, EstimateTemplateRecord>(),
  unsavedId: -1,
});

export const initialState = EstimateTemplateStateRecord();

export type EstimateTemplateState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = "estimateTemplates";
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let estimateTemplate: EstimateTemplateRecord;
  switch (action.type) {
    // SINGLE
    case estimateTemplateActions.FETCH_ESTIMATE_TEMPLATE:
      return fetch(state, model, fromJSON({ id: action.payload.id }));

    case estimateTemplateActions.RECEIVE_ESTIMATE_TEMPLATE:
      estimateTemplate = fromJSON({ ...action.payload.estimateTemplate });
      return receive(state, model, estimateTemplate);

    case estimateTemplateActions.RESET_ESTIMATE_TEMPLATE:
      return reset(state, model, action.payload.id);

    case estimateTemplateActions.SET_ESTIMATE_TEMPLATE_LOADED:
      return loaded(state, model, action.payload.id);

    case estimateTemplateActions.DESTROY_ESTIMATE_TEMPLATE:
      state = paginationReducer(state, paginationActions.Actions.removeId(action.payload.id, "estimateTemplate"));

      return state;

    case estimateTemplateActions.EDIT_ESTIMATE_TEMPLATE:
      return state.setIn(["estimateTemplates", "byId", action.payload.id, action.payload.name], action.payload.value);

    // MULTIPLE
    case estimateTemplateActions.FETCH_ESTIMATE_TEMPLATES:
      action.payload.ids.forEach((id) => {
        state = reducer(state, estimateTemplateActions.Actions.fetchEstimateTemplate(id));
      });
      return state;

    case estimateTemplateActions.RECEIVE_ESTIMATE_TEMPLATES:
      action.payload.estimateTemplates.forEach((estimateTemplate) => {
        state = reducer(state, estimateTemplateActions.Actions.receiveEstimateTemplate(estimateTemplate));
      });
      return state;

    case estimateTemplateActions.SET_ESTIMATE_TEMPLATES_LOADED:
      action.payload.ids.forEach((id) => {
        state = reducer(state, estimateTemplateActions.Actions.setEstimateTemplateLoaded(id));
      });

      return state;

    case estimateTemplateActions.BATCH_EDIT_ESTIMATE_TEMPLATE:
      return state.updateIn(["estimateTemplates", "byId"], (byId) => {
        action.payload.ids.forEach((id) => {
          byId = byId.setIn([id, action.payload.name], action.payload.value);
        });
        return byId;
      });

    case estimateTemplateActions.BATCH_REMOVE_IDS:
      action.payload.ids.forEach((id) => {
        state = paginationReducer(state, paginationActions.Actions.removeId(id, "estimateTemplate"));
      });

      return state;

    default:
      return state;
  }
};
