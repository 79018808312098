import * as reportActions from "./report.actions";
import { ReportRecord, fromJSON } from "app2/src/records/Report";
import { Map, Record } from "immutable";
import { fromJS } from "immutable";
import { rootKey } from "app2/src/selectors/report.selectors";
import { RootActions, RootState } from "app2/src/reducers";
import { fetch, loaded, receive } from "app2/src/reducers/Reducer";

export const ReportStateRecord = Record({
  byId: Map<number, ReportRecord>(),
  lastSavedById: Map<number, ReportRecord>(),
  pricingReports: Map<any, any>(),
});

export const initialState = ReportStateRecord();
export type ReportState = typeof initialState;

export const model = rootKey;
export const reducer = (state: RootState, action: RootActions): RootState => {
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  switch (action.type) {
    case reportActions.FETCH_REPORT:
      return fetch(state, model, fromJSON({ id: action.payload.reportId }));
    case reportActions.RECEIVE_REPORT:
      return receive(state, model, fromJSON({ ...action.payload.report }));
    case reportActions.SET_REPORT_LOADED:
      return loaded(state, model, action.payload.reportId);
    case reportActions.RECEIVE_REPORTS:
      action.payload.reports.forEach((report) => {
        state = reducer(state, reportActions.Actions.receiveReport(report));
      });
      return state;
    case reportActions.FETCH_PRICING_REPORT:
      return state.setIn(
        [model, "pricingReports", action.payload.provider + "_" + action.payload.kind, "loading"],
        true,
      );
    case reportActions.RECEIVE_PRICING_REPORT:
      return state.setIn(
        [model, "pricingReports", action.payload.provider + "_" + action.payload.kind],
        fromJS({ loading: false, data: action.payload.report }),
      );
    case reportActions.SET_PRICING_REPORT_LOADED:
      return state.setIn(
        [model, "pricingReports", action.payload.provider + "_" + action.payload.kind, "loading"],
        false,
      );

    default:
      return state;
  }
};
