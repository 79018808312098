import { Record, List } from "immutable";

export const fromJSON = (json: Partial<IAccountInfoData>): AccountInfoRecord => {
  return new AccountInfoRecord(json);
};

export interface IAccountInfoData {
  firstName: string;
  lastName: string;
  email: string;
  description: string;
  amount: number;
  postalCode: string;
}

export type IAccountInfoRecord = IAccountInfoData;

const defaultAccountInfoProps: IAccountInfoRecord = {
  firstName: "",
  lastName: "",
  email: "",
  description: "",
  amount: 0,
  postalCode: "",
};

export class AccountInfoRecord extends Record(defaultAccountInfoProps) implements IAccountInfoRecord {
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public description!: string;
  public amount!: number;
  public postalCode!: string;

  public constructor(values?: Partial<IAccountInfoRecord>) {
    if (values) {
      if (values.amount) {
        values.amount = parseFloat(values.amount as any);
      }
      super(values);
    } else {
      super();
    }
  }
}
