import { ActionsUnion, createAction } from "app2/src/reducers/Utils";
import { FenceRecord, IFenceData } from "app2/src/records/measurements/Fence";
import { ThunkResult, RootState } from "app2/src/reducers";
import * as fencingActions from "app2/src/reducers/measurements/fencing.actions";
import { fenceFencingId } from "app2/src/selectors/measurements/fence.selectors";
import { RootDispatchType } from "app2/src/store";

export const RECEIVE_FENCE = "@measurements_fences/RECEIVE_FENCE";
export const SET_FENCE = "@measurements_fences/SET_FENCE";
export const RESET_FENCE = "@measurements_fences/RESET_FENCE";
export const DELETE_FENCE = "@measurements_fences/DELETE_FENCE";
export const UPDATE_TOTALS = "@measurements_fences/UPDATE_TOTALS";

export const Actions = {
  receiveFence: (fence: Partial<IFenceData>) => createAction(RECEIVE_FENCE, { fence }),
  setFence: (fence: FenceRecord) => createAction(SET_FENCE, { fence }),
  resetFence: (fenceId: number) => createAction(RESET_FENCE, { fenceId }),
  deleteFence: (fenceId: number, cascade = true) => createAction(DELETE_FENCE, { fenceId, cascade }),
  updateTotals: (fenceId: number) => createAction(UPDATE_TOTALS, { fenceId }),
};

export type Actions = ActionsUnion<typeof Actions>;

export const AsyncActions = {
  updateTotals: (fenceId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.updateTotals(fenceId));
      dispatch(fencingActions.Actions.updateTotals(fenceFencingId(getState(), { fenceId })));
    };
  },
};
