import { fetcher } from "app2/src/helpers/Fetcher";
import * as fileSaver from "file-saver";
import { dispatch } from "app2/src/storeRegistry";
import { handleErrors } from "../reducers/Utils";

export async function downloadFile(url, filename) {
  try {
    const response = await fetcher.fetch(url);
    const blob = await response.blob();

    fileSaver.saveAs(blob, filename);
  } catch (error) {
    handleErrors(error, dispatch);
  }
}
