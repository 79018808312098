import { ActionsUnion, createAction } from "../Utils";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../index";
import { IPaymentTermTemplateData, PaymentTermTemplateRecord } from "app2/src/records/PaymentTermTemplate";
import { paymentTermTemplateService } from "app2/src/api/paymentTermTemplate.service";
import { RootDispatchType } from "../../store";

export const FETCH_ORG_PAYMENT_TERM_TEMPLATES = "@paymentTermTemplates/FETCH_ORG_PAYMENT_TERM_TEMPLATES";
export const FETCH_PAYMENT_TERM_TEMPLATE = "@paymentTermTemplates/FETCH_PAYMENT_TERM_TEMPLATE";
export const RECEIVE_ORG_PAYMENT_TERM_TEMPLATES = "@paymentTermTemplates/RECEIVE_ORG_PAYMENT_TERM_TEMPLATES";
export const RECEIVE_PAYMENT_TERM_TEMPLATE = "@paymentTermTemplates/RECEIVE_PAYMENT_TERM_TEMPLATE";
export const REMOVE_ORG_PAYMENT_TERM_TEMPLATE = "@paymentTermTemplates/REMOVE_ORG_PAYMENT_TERM_TEMPLATE";
export const REMOVE_PAYMENT_TERM_TEMPLATE = "@paymentTermTemplates/REMOVE_PAYMENT_TERM_TEMPLATE";
export const RECEIVE_ORG_ERRORS = "@paymentTermTemplates/RECEIVE_ORG_ERRORS";
export const RECEIVE_PAYMENT_TERM_TEMPLATE_ERROR = "@paymentTermTemplates/RECEIVE_PAYMENT_TERM_TEMPLATE_ERROR";

export const Actions = {
  fetchPaymentTermTemplate: (orgId: number, paymentTermTemplateId: number) =>
    createAction(FETCH_PAYMENT_TERM_TEMPLATE, { orgId: orgId, paymentTermTemplateId: paymentTermTemplateId }),
  receivePaymentTermTemplateError: (orgId: number, paymentTermTemplateId: number, errors: string[]) =>
    createAction(RECEIVE_PAYMENT_TERM_TEMPLATE_ERROR, {
      orgId: orgId,
      paymentTermTemplateId: paymentTermTemplateId,
      errors: errors,
    }),
  receivePaymentTermTemplate: (paymentTermTemplate: Partial<IPaymentTermTemplateData>) =>
    createAction(RECEIVE_PAYMENT_TERM_TEMPLATE, paymentTermTemplate),
  fetchPaymentTermTemplates: (orgId: number) => createAction(FETCH_ORG_PAYMENT_TERM_TEMPLATES, { orgId }),
  receiveErrors: (orgId, errors: string[]) => createAction(RECEIVE_ORG_ERRORS, { orgId: orgId, errors: errors }),
  receivePaymentTermTemplates: (orgId: number, paymentTermTemplates: Partial<IPaymentTermTemplateData>[]) =>
    createAction(RECEIVE_ORG_PAYMENT_TERM_TEMPLATES, { orgId: orgId, paymentTermTemplates: paymentTermTemplates }),
  removeOrgPaymentTermTemplate: (orgId: number, paymentTermTemplateId: number) =>
    createAction(REMOVE_ORG_PAYMENT_TERM_TEMPLATE, { orgId: orgId, paymentTermTemplateId: paymentTermTemplateId }),
  removePaymentTermTemplate: (paymentTermTemplateId: number) =>
    createAction(REMOVE_PAYMENT_TERM_TEMPLATE, paymentTermTemplateId),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  listPaymentTermTemplates: (orgId: number): ThunkResult<any> => {
    return async (dispatch: RootDispatchType) => {
      dispatch(Actions.fetchPaymentTermTemplates(orgId));

      try {
        const paymentTermTemplates = await paymentTermTemplateService.loadByOrg(orgId);
        dispatch(Actions.receivePaymentTermTemplates(orgId, paymentTermTemplates));
        return paymentTermTemplates;
      } catch (errors) {
        dispatch(Actions.receiveErrors(orgId, errors));
        return Promise.reject(errors);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
