import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { token as selectToken } from "app2/src/selectors/token.selectors";
import * as tokenActions from "app2/src/reducers/token.actions";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { TokenKind } from "app2/src/records/Token";
import { Modal, ModalProps } from "react-bootstrap";
import { RsfForm } from "@tberrysoln/rsf-form";
import { SettingsForm as CompanyCamSettingsForm } from "app2/src/components/Integrations/Companycam/SettingsForm";
import { SettingsForm as ImproveIt360SettingsForm } from "app2/src/components/Integrations/ImproveIt360/SettingsForm";
import { SettingsForm as IngageSettingsForm } from "app2/src/components/Integrations/Ingage/SettingsForm";
import { SettingsForm as QuickMeasureSettingsForm } from "app2/src/components/Integrations/QuickMeasure/SettingsForm";
import { connectFC } from "app2/src/connect";
import { useRouteMatch } from "react-router-dom";
import { usePreviousPath } from "app2/src/hooks/usePreviousPath";
import { useTracking } from "react-tracking";
import { StoreRegistry } from "app2/src/storeRegistry";
import { IPretty } from "app/src/Common/PrettyNameService";
import ButtonFooter from "app2/src/components/Common/ButtonFooter";
import { Route, Switch, Redirect } from "react-router-dom";

interface SettingsModalProps {
  modalProps: ModalProps;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ modalProps }) => {
  //Constants
  const match = useRouteMatch();
  const route = useRouteMatch<{ integration: TokenKind }>(`${match.url}/:integration`);
  const integration = route?.params?.integration;
  const pretty: IPretty = StoreRegistry.get("Pretty");
  const integrationTitle = pretty.name[integration];
  const { trackEvent } = useTracking<any>({ component: "SettingsModal", integration: integration });

  //Selectors
  const orgId = useSelector(currentOrgId);

  //Hooks
  const dispatch = useDispatch();
  const { goBack } = usePreviousPath();

  //Selectors
  const token = useSelector((state: RootState) => selectToken(state, { kind: integration }));

  //Methods
  const close = () => {
    trackEvent({ action: "close" });
    goBack(`/settings/${integration}`);
  };

  const submit = async () => {
    trackEvent({ action: "save token settings" });
    try {
      await dispatch(tokenActions.AsyncActions.updateCreateToken(token, true));
      close();
      dispatch(tokenActions.AsyncActions.getAuthorizedTokens(orgId));
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle
  React.useEffect(() => {
    dispatch(tokenActions.AsyncActions.getToken(orgId, integration));
  }, []);

  return (
    <Modal show={true} backdrop="static" onHide={close} {...modalProps}>
      <Modal.Header closeButton>
        <Modal.Title>{integrationTitle} Settings</Modal.Title>
      </Modal.Header>

      {token?.loading ? null : (
        <RsfForm
          rootPath={["tokens", "byOrgId", orgId, integration]}
          updateFormReducer={tokenActions.Actions.update}
          onSubmit={submit}>
          <Modal.Body>
            <Switch>
              <Route path={`${match.url}/companycam`} component={CompanyCamSettingsForm} />
              <Route path={`${match.url}/improveit360`} component={ImproveIt360SettingsForm} />
              <Route path={`${match.url}/ingage`} component={IngageSettingsForm} />
              <Route path={`${match.url}/quick_measure`} component={QuickMeasureSettingsForm} />
              <Route path={`${match.url}`} render={() => <Redirect to={`/orgs/${orgId}/integrations`} />} />
            </Switch>
          </Modal.Body>

          <Modal.Footer>
            <ButtonFooter cancel={close} />
          </Modal.Footer>
        </RsfForm>
      )}
    </Modal>
  );
};

export default connectFC(SettingsModal);
