import { useLocalStorage } from "app2/src/hooks/useLocalStorage";
import * as React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { LocalStorageKeys } from "../../VideoProvider/types";
import { Pipeline } from "@twilio/video-processors";
import useVideoContext from "../../Twilio/hooks/useVideoContext/useVideoContext";
import { LocalVideoTrack } from "twilio-video";
import { useTracking } from "react-tracking";
import { BrowserDetection } from "app2/src/helpers/BrowserDetection";

export const AdvancedSettings: React.FC = () => {
  // Context
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    isVideoTrackLoading,
  } = useVideoContext();
  const localVideoTrack = localTracks.find((track) => track.kind === "video") as LocalVideoTrack;

  // Analytics
  const { trackEvent } = useTracking();

  // Local Storage
  const defaultGraphicsSetting =
    BrowserDetection.isMobileIOS() || BrowserDetection.isBrowserSafari() ? Pipeline.WebGL2 : Pipeline.Canvas2D;
  const [graphicsSetting, setGraphicsSetting] = useLocalStorage<Pipeline.Canvas2D | Pipeline.WebGL2>(
    LocalStorageKeys.GraphicsSetting,
    defaultGraphicsSetting,
  );
  const [reduceResolution, setReduceResolution] = useLocalStorage(LocalStorageKeys.ReduceResolution, false);

  // Local State
  const [showModal, setShowModal] = React.useState(false);

  // Methods
  const open = () => setShowModal(true);
  const close = () => setShowModal(false);
  const replaceTrack = async () => {
    const newTrack = await getLocalVideoTrack();
    if (localParticipant) {
      if (localVideoTrack) {
        const localTrackPublication = localParticipant.unpublishTrack(localVideoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant.emit("trackUnpublished", localTrackPublication);
      }

      localParticipant.publishTrack(newTrack);
    }
  };
  const handleGraphicSettingChange = (setting: Pipeline.Canvas2D | Pipeline.WebGL2) => {
    trackEvent({
      action: "graphics setting change",
      graphics: setting,
    });
    setGraphicsSetting(setting);
    replaceTrack();
  };
  const toggleReduceResolution = () => {
    trackEvent({
      action: "reduce resolution change",
      reduceResolution: !reduceResolution,
    });
    setReduceResolution(!reduceResolution);
    replaceTrack();
  };

  const labelStyles = "text-transform-none text-black font-weight-regular";

  return (
    <>
      <Button variant="secondary" onClick={open} size="sm">
        Advanced Settings
      </Button>
      <Modal
        show={showModal}
        onHide={close}
        backdrop="static"
        backdropClassName="z-index-1060"
        className="z-index-1070">
        <Modal.Header>Advanced Settings</Modal.Header>
        <Modal.Body>
          <Form.Group controlId="graphics-setting">
            <Form.Label>Graphics</Form.Label>
            <Form.Check type="radio" id="graphics-webgl2">
              <Form.Check.Input
                type="radio"
                checked={graphicsSetting === Pipeline.WebGL2}
                onChange={() => handleGraphicSettingChange(Pipeline.WebGL2)}
                disabled={isVideoTrackLoading}
              />
              <Form.Check.Label className={labelStyles}>WebGL2</Form.Check.Label>
            </Form.Check>
            <Form.Check type="radio" id="graphics-canvas2d">
              <Form.Check.Input
                type="radio"
                checked={graphicsSetting === Pipeline.Canvas2D}
                onChange={() => handleGraphicSettingChange(Pipeline.Canvas2D)}
                disabled={isVideoTrackLoading}
              />
              <Form.Check.Label className={labelStyles}>Canvas2D</Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <Form.Group>
            <Form.Label>Resolution</Form.Label>
            <Form.Check type="checkbox" label="Reduce video output resolution">
              <Form.Check.Input
                type="checkbox"
                checked={reduceResolution}
                onChange={toggleReduceResolution}
                disabled={isVideoTrackLoading}
                id="reduce-resolution"
              />
              <Form.Check.Label className={labelStyles}>Reduce input video resolution</Form.Check.Label>
            </Form.Check>
            <p className="text-small mt-2">
              Reducing video input resolution can greatly improve performance when using a Virtual Background
            </p>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdvancedSettings;
