import * as toolActions from "./tool.actions";
import { ToolRecord, fromJSON } from "app2/src/records/Tool";
import { Map, Record, List } from "immutable";

export interface IOrgToolsRecord {
  tools: List<number>;
  design_tools: List<number>;
  errors: List<string>;
  loading: boolean;
}
export const OrgToolsRecord: Record.Factory<IOrgToolsRecord> = Record<IOrgToolsRecord>({
  tools: List<number>(),
  design_tools: List<number>(),
  errors: List<string>(),
  loading: false,
});

export const ToolStateRecord = Record({
  byId: Map<number, ToolRecord>(),
  byOrgId: Map<number, List<typeof OrgToolsRecord>>(),
});

export const initialState = ToolStateRecord();
export type ToolState = typeof initialState;

export const reducer = (state = initialState, action: toolActions.Actions): ToolState => {
  switch (action.type) {
    case toolActions.RECEIVE_TOOL:
      const tool = fromJSON({ ...action.payload, loading: false });

      if (!state.getIn(["byOrgId", tool.org_id])) {
        state = state.setIn(["byOrgId", tool.org_id], OrgToolsRecord({}));
      }
      return state.setIn(["byId", tool.id], tool).setIn(
        ["byOrgId", tool.org_id, `${tool.kind}s`],
        state
          //@ts-ignore
          .getIn(["byOrgId", tool.org_id, `${tool.kind}s`], List<number>())
          .push(tool.id)
          .toSet()
          .toList(),
      );
    case toolActions.RECEIVE_TOOL_ERROR:
      return state
        .setIn(["byId", action.payload.toolId, "errors"], List<string>(action.payload.errors))
        .setIn(["byId", action.payload.toolId, "loading"], false);
    case toolActions.FETCH_TOOL:
      if (state.getIn(["byId", action.payload.toolId])) {
        return state.setIn(["byId", action.payload.toolId, "loading"], true);
      }
      return state.setIn(
        ["byId", action.payload.toolId],
        new ToolRecord({ id: action.payload.toolId, org_id: action.payload.orgId, loading: true }),
      );
    case toolActions.FETCH_ORG_TOOLS:
      if (!state.getIn(["byOrgId", action.payload])) {
        return state.setIn(["byOrgId", action.payload], OrgToolsRecord({ loading: true }));
      }

      return state.setIn(["byOrgId", action.payload, "loading"], true);
    case toolActions.RECEIVE_ORG_TOOLS:
      state = state.setIn(["byOrgId", action.payload.orgId, "tools"], List([]));
      action.payload.tools.forEach((d) => {
        state = reducer(state, toolActions.Actions.receiveTool(d));
      });

      return state.setIn(["byOrgId", action.payload.orgId, "loading"], false);
    case toolActions.RECEIVE_ORG_ERRORS:
      return state
        .setIn(["byOrgId", action.payload.orgId, "errors"], List<string>(action.payload.errors))
        .setIn(["byOrgId", action.payload.orgId, "loading"], false);
    case toolActions.REMOVE_TOOL:
      return state.removeIn(["byId", action.payload]);
    case toolActions.REMOVE_ORG_TOOL:
      return state.updateIn(["byOrgId", action.payload.orgId, "tools"], (tools: List<number>) => {
        return tools.filter((d) => d !== action.payload.toolId);
      });
    default:
      return state;
  }
};
