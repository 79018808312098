import * as React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "app2/src/reducers/user.actions";
import { queryParamsFromJSON } from "app2/src/records/Page";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { List } from "immutable";
import { currentUser, users as usersSelector } from "app2/src/selectors/user.selectors";
import { RootState } from "app2/src/reducers";
import { getFullName, UserRecord } from "app2/src/records/UserRecord";
import { Context } from "./Context";
import { Actions } from "app2/src/reducers/event.actions";
import { colorsByUserId as colorsByUserIdSelector } from "app2/src/selectors/event.selectors";
import Select, { StylesConfig } from "react-select";
import { getPaginationByModel, isCurrentPageLoading } from "app2/src/selectors/pagination.selectors";
import { useTracking } from "react-tracking";

export interface UserFilterProps {
  onUsersChange: (newUsersList: List<number>) => void;
  // Used for Scheduler
  userToAssignId?: number;
}

export const UserFilter: React.FC<UserFilterProps> = ({ onUsersChange, userToAssignId }) => {
  const modelName = "user";
  // Hooks
  const dispatch = useDispatch();
  const { trackEvent } = useTracking<any>({ component: "UserFilter" });

  // Context
  const { userIds, setUserIds } = React.useContext(Context);

  // Selectors
  const orgId = useSelector((state: RootState) => currentOrgId(state) || currentUser(state)?.get("org_id"));
  const users: List<UserRecord> = useSelector((state: RootState) => {
    const paginatedUsers = getPaginationByModel(state, { path: ["users", "usersById"], modelName });
    return paginatedUsers
      .filter((u) => u.id !== userToAssignId && !userIds.includes(u.id))
      .concat(usersSelector(state, { userIds }));
  });
  const loading = useSelector((state: RootState) => isCurrentPageLoading(state, { modelName }));
  const colorsByUserId = useSelector(colorsByUserIdSelector);

  // Select Values
  const selectOptions = React.useMemo(() => {
    return users.map((u) => ({ label: getFullName(u), value: u.id, color: colorsByUserId?.get(u.id) })).toJS();
  }, [users, userToAssignId, colorsByUserId]);
  const selectValues = React.useMemo(() => {
    return selectOptions.filter((o) => userIds.includes(o.value));
  }, [selectOptions, userIds]);

  // Local State
  const [lastInputValue, setLastInputValue] = React.useState("");

  // Methods
  const listUsers = async (query = null) => {
    await dispatch(
      userActions.AsyncActions.listUsers(queryParamsFromJSON({ parameters: { org_id: orgId.toString() }, query })),
    );
  };

  const handleChange = (value: any[] | null) => {
    trackEvent({ action: "handle user filter change", value });
    const newList = List(value.map((obj) => obj.value));
    setUserIds(newList);
    onUsersChange(newList);
  };

  const handleInputChange = _.debounce((query: string) => {
    if (lastInputValue !== query) listUsers(query);
    setLastInputValue(query);
  }, 500);

  // Lifecycle
  React.useEffect(() => {
    listUsers();
  }, []);

  React.useEffect(() => {
    dispatch(Actions.generateColors(userIds, userToAssignId));
  }, [userIds, userToAssignId]);

  const customStyles: StylesConfig<any, false> = {
    container: (styles) => ({
      ...styles,
      zIndex: 2,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        borderLeft: `5px solid ${data.color}`,
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      minWidth: "215px",
      maxWidth: "30vw",
      maxHeight: "65px",
      overflowY: "auto",
    }),
  };

  return (
    <Form.Group>
      <Form.Label>Users</Form.Label>
      <Select
        styles={customStyles}
        isMulti
        value={selectValues}
        onChange={handleChange}
        options={selectOptions}
        onInputChange={handleInputChange}
        isLoading={loading}
        filterOption={() => true}
      />
    </Form.Group>
  );
};
