import * as config from "react-global-configuration";

export const loadPaySimple = (): Promise<void> => {
  const existingScript = document.getElementById("paySimple");

  return new Promise((resolve, reject) => {
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = config.get("PAYSIMPLE_SCRIPT");
      script.id = "paySimple";
      document.body.appendChild(script);

      script.onload = () => {
        resolve();
      };
    } else {
      resolve();
    }
  });
};
