import { ICoupon, ICouponResponse } from "../Models/Coupon";
import { IRepository, Repository } from "../../Common/Repository";
import { IAccount } from "../Models/Account";
import { ISku, ISkuResponse } from "../Models/Sku";

class CouponsComponentCtrl implements ng.IComponentController {
  public spinnerPromise: ng.IPromise<any>;
  public couponResponse: ICouponResponse;
  public account: IAccount;

  public static $inject = ["Repository", "$uibModal"];
  constructor(
    protected Repository: IRepository,
    protected $uibModal: ng.ui.bootstrap.IModalService,
  ) {}

  public $onChanges() {
    if (this.account) {
      this.account.$promise.then(() => {
        this.couponResponse = <ICouponResponse>this.Repository.Coupon.query({ account_id: this.account.id });
        this.couponResponse.$promise.then(() => {
          _.each(this.couponResponse.coupons, (c: ICoupon) => {
            if (c.sku_ids.length > 0) {
              this.Repository.Sku.query({ sku_ids: c.sku_ids }).$promise.then((skuResponse: any) => {
                c.skus = skuResponse.skus;
              });
            }

            return (c.account_id = this.account.id);
          });
        });
      });
    }
  }

  public addCoupon() {
    const modalInstance: ng.ui.bootstrap.IModalServiceInstance = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "src/Billing/Coupons/coupon_edit_modal.html",
      controller: "CouponEditModalCtrl",
      backdrop: "static",
      controllerAs: "$ctrl",
      size: "lg",
      resolve: {
        coupon: this.Repository.Coupon.fromJSON({ account_id: this.account.id }),
        account: this.account || {},
      },
    });

    modalInstance.result.then((returned: ICoupon) => {
      const skus: ISku[] = returned.skus;
      this.spinnerPromise = returned.$saveOrCreate().then(() => {
        returned.skus = skus;
      });
      this.couponResponse.coupons.push(returned);
    });
  }

  public deleteCoupon(coupon) {
    this.spinnerPromise = coupon.$delete().then(() => {
      this.couponResponse.coupons = _.select(this.couponResponse.coupons, (c: ICoupon) => {
        return c.id !== coupon.id;
      });
    });
  }
}

export class CouponsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Billing/Coupons/coupons_component.html";
  public bindings: any = {
    account: "<",
  };

  constructor() {
    this.controller = CouponsComponentCtrl;
  }
}
