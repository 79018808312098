import { ActionsUnion, createAction } from "app2/src/reducers/Utils";
import { FenceSegmentRecord, IFenceSegmentData } from "app2/src/records/measurements/Segment";
import { ThunkResult, RootState } from "app2/src/reducers";
import { segmentFenceId } from "app2/src/selectors/measurements/segment.selectors";
import { fenceFencingId } from "app2/src/selectors/measurements/fence.selectors";
import * as fenceActions from "app2/src/reducers/measurements/fence.actions";
import * as fencingActions from "app2/src/reducers/measurements/fencing.actions";
import { RootDispatchType } from "app2/src/store";

export const RECEIVE_SEGMENT = "@measurements_segments/RECEIVE_SEGMENT";
export const SET_SEGMENT = "@measurements_segments/SET_SEGMENT";
export const RESET_SEGMENT = "@measurements_segments/RESET_SEGMENT";
export const DELETE_SEGMENT = "@measurements_segments/DELETE_SEGMENT";
export const SET_GATE_COUNT = "@measurements_fences/SET_GATE_COUNT";
export const SET_GATE_LENGTH = "@measurements_fences/SET_GATE_LENGTH";

export const Actions = {
  receiveSegment: (segment: Partial<IFenceSegmentData>) => createAction(RECEIVE_SEGMENT, { segment }),
  setSegment: (segment: FenceSegmentRecord) => createAction(SET_SEGMENT, { segment }),
  resetSegment: (segmentId: number) => createAction(RESET_SEGMENT, { segmentId }),
  deleteSegment: (segmentId: number) => createAction(DELETE_SEGMENT, { segmentId }),
  setGateCount: (segmentId: number, gateCount: number) => createAction(SET_GATE_COUNT, { segmentId, gateCount }),
  setGateLength: (segmentId: number, gateLength: number) => createAction(SET_GATE_LENGTH, { segmentId, gateLength }),
};

export type Actions = ActionsUnion<typeof Actions>;

export const AsyncActions = {
  setGateCount: (segmentId: number, gateCount: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.setGateCount(segmentId, gateCount));
      const fenceId = segmentFenceId(getState(), { segmentId });
      dispatch(fenceActions.AsyncActions.updateTotals(fenceId));
      dispatch(fencingActions.Actions.updateTotals(fenceFencingId(getState(), { fenceId })));
    };
  },
  setGateLength: (segmentId: number, gateLength: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      dispatch(Actions.setGateLength(segmentId, gateLength));
      dispatch(fenceActions.AsyncActions.updateTotals(segmentFenceId(getState(), { segmentId })));
    };
  },
};
