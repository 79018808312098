import { StandardPagination } from "app2/src/components/Pagination/Standard";
import { RootState } from "app2/src/reducers";
import { getPaginationByModel, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncActions } from "app2/src/reducers/paymentRequest.actions";
import DateTime from "app2/src/components/Common/DateTime";
import { CurrencyFormat } from "app2/src/helpers/Format";
import { Button, Table } from "react-bootstrap";
import { PaymentRequestRecord } from "app2/src/records/PaymentRequest";
import { QueryParamsRecord } from "app2/src/records/Page";
import { AsyncActions as EmailAsyncActions } from "app2/src/reducers/email.actions";

export const PaymentRequestList = () => {
  const modelName = "paymentRequests";
  // Hooks
  const dispatch = useDispatch();

  // Selectors
  const { paymentRequests, queryParamsRecord } = useSelector((state: RootState) => {
    return {
      paymentRequests: getPaginationByModel(state, { modelName, path: [modelName, "byId"] }),
      queryParamsRecord: queryParams(state, { modelName }) as QueryParamsRecord,
    };
  });
  const paginationRecord = useSelector((state: RootState) => {
    return pagination(state, { modelName, page: queryParamsRecord.get("page") });
  });

  // Methods
  const queryPaymentRequests = (page: number) => {
    const newQueryParams = queryParamsRecord.set("page", page);
    dispatch(AsyncActions.loadPaymentRequests(newQueryParams));
  };
  const resend = (paymentRequest: PaymentRequestRecord) => {
    dispatch(
      EmailAsyncActions.sendMessage({
        kind: "payment_request",
        provider: paymentRequest.provider,
        to: paymentRequest.to,
        data: paymentRequest.toJS(),
      }),
    );
  };

  // Lifecycle
  React.useEffect(() => {
    queryPaymentRequests(1);
  }, []);

  if (!paymentRequests.count()) return <p>No payment requests</p>;

  return (
    <>
      <Table className="table table-striped">
        <thead>
          <tr>
            <td>To</td>
            <td>Amount</td>
            <td>Provider</td>
            <td>Description</td>
            <td>Created At</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {paymentRequests.map((pr, index) => (
            <tr key={index}>
              <td>{pr.to}</td>
              <td>{!pr.amount || pr.kind === "paysimple" ? "N/A" : CurrencyFormat(parseFloat(pr.amount))}</td>
              <td>{pr.provider}</td>
              <td>{pr.description}</td>
              <td>
                <DateTime date={pr.created_at} variant="vertical" />
              </td>
              <td>
                <Button onClick={() => resend(pr)}>Resend</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <StandardPagination metadata={paginationRecord} pageChanged={queryPaymentRequests} />
    </>
  );
};

export default PaymentRequestList;
