import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { connectFC } from "app2/src/connect";
import { Dispatch } from "app2/src/helpers/Analytics";
import { RootState } from "app2/src/reducers";
import { rooms } from "app2/src/selectors/room.selectors";
import { List } from "immutable";
import * as React from "react";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { GroupedRooms } from "./GroupedRooms";
import { IndividualRoom } from "./IndividualRoom";

export interface RoomSummaryProps {
  estimateGroup: IEstimateGroup;
}

export const RoomSummary: React.FC<RoomSummaryProps> = ({ estimateGroup }) => {
  // Hooks
  const { Track } = useTracking<any>({ category: "RoomSummary" }, { dispatch: Dispatch.dispatch });

  const roomIds = React.useMemo(
    () => List(estimateGroup.room_estimations.map((estimation) => estimation.room_id)),
    [estimateGroup],
  );
  const roomRecords = useSelector((state: RootState) => rooms(state, { roomIds }));

  if (roomIds.size === 0) return null;
  return (
    <Track>
      {roomIds.size === 1 ? <IndividualRoom room={roomRecords.get(0)} /> : <GroupedRooms rooms={roomRecords} />}
    </Track>
  );
};

// Determines what keys to display in room summaries with multiple rooms
export const roomKeys = List(["floor_plan_area", "wall_total_area", "ceiling_area", "floor_plan_perimeter"]);

export default connectFC(RoomSummary);
