import * as React from "react";
import { Card } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import { folder } from "app2/src/selectors/folder.selectors";
import track from "react-tracking";

const mapStateToProps = (state: RootState, ownProps: MiniFolderProps) => {
  return {
    folder: folder(state, { folderId: ownProps.folderId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: MiniFolderProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface MiniFolderProps {
  folderId: number;
  navigate: (id: number) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & MiniFolderProps;

@track((props) => {
  return {
    component: "MiniFolder",
    folder: props.folderId,
  };
})
class MiniFolder extends React.Component<Props> {
  public componentRef: any;
  public confirm: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: props.folderId <= 0,
    };

    this.navigate = this.navigate.bind(this);
  }

  public navigate(): void {
    const { folder, navigate } = this.props;
    navigate(folder.id);
  }

  public render(): React.ReactNode {
    const { folder } = this.props;

    return (
      <Card className="mini-folder-card" data-testid="mini-folder-card" onClick={this.navigate}>
        <Card.Body>
          <Card.Title className="mb-0 ellipsify" title={folder.name}>
            <FontAwesome className="fa-gray float-left" name="folder-open-o" />
            &nbsp;{folder.name}
          </Card.Title>
        </Card.Body>
      </Card>
    );
  }
}

export default connector(MiniFolder);
