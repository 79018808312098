import { BaseOrgTabCtrl } from "app/src/Orgs/tabs/BaseOrgTabCtrl";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { ISignerSettings } from "app/src/Models/SignatureSettings";
import { IDirtyMerge, IDirtyWatcher } from "app/src/Common/DirtyWatcher";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IReport } from "app/src/Models/Report";
import * as angulartics from "angulartics";
import { IMenuItem, ISection } from "../../JobMenu/JobMenuOrderService";
import { OrgAclType } from "../../Models/Org";
import { IOrgPreference } from "../../Models/OrgPreference";
import { ToolService } from "app/src/Common/ToolService";
import { IOrg } from "app/src/Models/Org";
import { setIn } from "immutable";

export class OrgSettingsTabCtrl extends BaseOrgTabCtrl implements IDirtyMerge {
  public visualizationsValues: string[] = ["renoworks", "chameleon", "chameleon_v2", "hover"];
  public windowAnnotatorValues: string[] = [
    "annotator",
    "elevation_scaling",
    "rapid_area",
    "satellite_measure",
    "sidinginator",
    "deck_wizard",
    "pool_tool",
    "fencing_tool",
  ];
  public modulesValues: string[] = ["leads_module", "estimate_module"];
  public profileValues: string[] = ["cronofy"];
  public screenLeapTypes: string[] = ["NATIVE", "IN_BROWSER"];
  public premiumRoomTypes: string[] = ["peer-to-peer", "group-small", "group"];
  public windowEstimatorTypes: string[] = ["disabled", "enabled"];
  public sharedTrueFalseValues: boolean[] = [true, false];

  public checkboxValuesAnnotator: any = {};

  public estimatorAclValues: string[] = ["window", "full", "multiple_groups", "price_comparison", "interiors"];
  public boolEstimatorAcl: {} = {};
  public joblistAclValues: string[] = ["estimate_amount", "appointment_time"];
  public boolJobListAcl: {} = {};
  public toolService: ToolService;

  protected preferencesCache: any;

  public static $inject = [
    "OrgFetcher",
    "Flash",
    "Report",
    "$stateParams",
    "$uibModal",
    "DirtyWatcher",
    "$scope",
    "Pretty",
    "$analytics",
    "JobMenuValues",
  ];
  constructor(
    public OrgFetcher: IOrgFetcherService,
    private Flash: IFlash,
    public Report: IReport,
    public $stateParams: ng.ui.IStateParamsService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public DirtyWatcher: IDirtyWatcher,
    public $scope: ng.IScope,
    public Pretty: IPretty,
    protected $analytics: angulartics.IAnalyticsService,
    public jobMenuValues: string[],
  ) {
    super(OrgFetcher, $analytics, $stateParams["id"]);

    DirtyWatcher.setup($scope, this);

    OrgFetcher.orgPromise.then(() => {
      this.preferencesCache = JSON.parse(JSON.stringify(this.org.preferences));

      _.each(this.windowAnnotatorValues, (cv: string) => {
        this.checkboxValuesAnnotator[cv] = _.contains(this.org.fetchSettingsAcl(OrgAclType.measurements), cv);
      });

      _.each(this.estimatorAclValues, (v: string) => {
        this.boolEstimatorAcl[v] = _.include(this.org.fetchSettingsAcl(OrgAclType.estimator), v);
      });

      _.each(this.joblistAclValues, (v: string) => {
        this.boolJobListAcl[v] = _.include(this.org.fetchSettingsAcl(OrgAclType.job_list), v);
      });

      this.toolService = new ToolService(this.org, "setting", $scope);
    });

    this.updateReports = this.updateReports.bind(this);
    this.updateVisualizations = this.updateVisualizations.bind(this);
    this.updateModules = this.updateModules.bind(this);
    this.updateDesignTools = this.updateDesignTools.bind(this);
    this.updateTools = this.updateTools.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.updateJobMenu = this.updateJobMenu.bind(this);
    this.updateSettingsConfig = this.updateSettingsConfig.bind(this);
  }

  public save() {
    this.validateJobMenuValues();
    this.spinnerPromise = this.OrgFetcher.save(this.org)
      .then((org: IOrg) => {
        this.org = org;
        this.Flash.addMessage(FlashLevels.success, "Settings Saved");
        this.trackEvent("save_org_settings", {
          category: "OrgSettings",
        });
      })
      .catch(() => {
        this.Flash.addMessage(FlashLevels.danger, "Problems Saving Settings!");
      });
  }

  public updateReports(values: string[]) {
    this.org.setSettingsAcl(OrgAclType.reports, values);
    this.trackEvent("update_reports", {
      category: "OrgSettings",
    });
  }

  public updateTools(values: string[]) {
    this.toolService.update(values, "tools");

    this.trackEvent("update_tools", {
      category: "OrgSettings",
    });
  }

  public updateJobMenu(values: string[]) {
    this.org.settings.config.job_menu.enabled = values;
    this.trackEvent("update_job_menu", {
      category: "OrgSettings",
    });
  }

  public updateDesignTools(values: string[]) {
    this.toolService.update(values, "design_tools");
    this.trackEvent("update_design_tools", {
      category: "OrgSettings",
    });
  }

  public updateVisualizations(values: string[]) {
    this.org.setSettingsAcl(OrgAclType.visualizations, values);
    this.trackEvent("update_visualization", {
      category: "OrgSettings",
    });
  }

  public updateModules(values: string[]) {
    this.org.setSettingsAcl(OrgAclType.modules, values);
    this.trackEvent("update_modules", {
      category: "OrgSettings",
    });
  }

  public updateProfile(values: string[]) {
    this.org.setSettingsAcl(OrgAclType.profile, values);
    this.trackEvent("update_profile", {
      category: "OrgSettings",
    });
  }

  public addSigner() {
    const signer = <ISignerSettings>{
      required: false,
      pricing: false,
      role: "",
    };
    this.openModal(signer);
    this.trackEvent("add_signer", {
      category: "OrgSettings",
    });
  }

  public editSigner(signer: ISignerSettings, index) {
    const clonedSigner: ISignerSettings = _.clone(signer);
    clonedSigner["index"] = index;
    this.openModal(clonedSigner);
    this.trackEvent("edit_signer", {
      category: "OrgSettings",
    });
  }

  public deleteSigner(index) {
    this.trackEvent("delete_signer", {
      category: "OrgSettings",
    });
    this.org.settings.config.signature_settings.signers.splice(index, 1);
  }

  public openModal(signer: ISignerSettings) {
    this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      controller: "OrgSignatureSettingsEditCtrl",
      controllerAs: "ctrl",
      templateUrl: "src/Orgs/tabs/signer_editor.html",

      resolve: {
        settings: this.org.settings,
        signer: signer,
      },
    });
    this.trackEvent("open_modal", {
      category: "OrgSettings",
    });
  }

  public nextChar(index: number) {
    return String.fromCharCode("A".charCodeAt(0) + index);
  }

  public syncWindowAnnotatorValues(item) {
    const value = this.org.fetchSettingsAcl(OrgAclType.measurements);
    if (this.checkboxValuesAnnotator[item]) {
      this.org.setSettingsAcl(OrgAclType.measurements, value.concat(item));
    } else {
      this.org.setSettingsAcl(
        OrgAclType.measurements,
        _.filter(value, (e) => {
          return e !== item;
        }),
      );
    }
  }

  public updateSettingsConfig(key: string, value: any) {
    this.$scope.$apply(() => {
      if (key.startsWith("estimator") && !this.org.settings.config.estimator) {
        this.org.settings.config.estimator = { acl: [] };
      }
      if (key.endsWith("greenline_percentage") && (value === "" || value === null)) {
        this.org.settings.config = setIn(this.org.settings.config, key.split("."), 0);
      } else {
        this.org.settings.config = setIn(this.org.settings.config, key.split("."), value);
      }
    });
  }

  public syncEstimatorAcl(value: string) {
    const data = this.org.fetchSettingsAcl(OrgAclType.estimator);
    if (this.boolEstimatorAcl[value]) {
      this.org.setSettingsAcl(OrgAclType.estimator, data.concat(value));
      if (value === "full") {
        this.triggerFullLineItemEditorAdded();
      }
    } else {
      this.org.setSettingsAcl(
        OrgAclType.estimator,
        _.filter(data, (c) => {
          return c !== value;
        }),
      );

      if (value === "full") {
        this.triggerFullLineItemEditorRemoved();
      }
    }
  }

  public syncJobListAcl(value: string) {
    const data = this.org.fetchSettingsAcl(OrgAclType.job_list);
    if (this.boolJobListAcl[value]) {
      this.org.setSettingsAcl(OrgAclType.job_list, data.concat(value));
    } else {
      this.org.setSettingsAcl(
        OrgAclType.job_list,
        _.filter(data, (c) => {
          return c !== value;
        }),
      );
    }
  }

  protected triggerFullLineItemEditorAdded() {
    this.org.preferences = JSON.parse(JSON.stringify(this.preferencesCache)) as IOrgPreference;
  }

  protected triggerFullLineItemEditorRemoved() {
    this.org.preferences.config.estimator.show_detailed_measurements = "never";
    this.org.preferences.config.estimator.show_detailed_unit_price = "never";

    const data = this.org.fetchPreferencesAcl(OrgAclType.estimator);
    this.org.setPreferencesAcl(
      OrgAclType.estimator,
      _.filter(data, (c) => {
        return c !== "full";
      }),
    );
  }

  protected validateJobMenuValues() {
    _.each(this.org.preferences.config.job_menu["sections"], (s: ISection) => {
      s.menuItems = _.filter(s.menuItems, (mi: IMenuItem) => {
        return _.any(this.org.settings.config.job_menu.enabled, (id: string) => mi.id === id);
      });
    });
  }
}
