import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { RootState } from "app2/src/reducers";
import track from "react-tracking";
import { push } from "connected-react-router/immutable";
import { get } from "immutable";
import { currentJobId, selectedEstimateId } from "app2/src/selectors/job.selectors";
import { Button, Col, Container, Row } from "react-bootstrap";
import { RsfForm, Select, Check } from "@tberrysoln/rsf-form";
import { required, checkRequired } from "app2/src/helpers/FinalFormValidator";
import { AsyncActions, Actions } from "app2/src/reducers/financeApplication.actions";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { sortedFilteredFinanceOptions } from "app2/src/selectors/financeOption.selectors";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { Context } from "app2/src/components/Homevest/Context";

const mapStateToProps = (state: RootState, ownProps: CreateProps) => {
  const orgId = currentOrgId(state);
  return {
    currentJobId: currentJobId(state),
    financeOptions: sortedFilteredFinanceOptions(state, { orgId, providers: ["fin_mkt"] }),
    estimateId: selectedEstimateId(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: CreateProps) => {
  return {
    push: (path: string) => dispatch(push(path)),
    createFinanceApplication: (estimateId: number) => dispatch(AsyncActions.createFinanceApplication(estimateId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface CreateState {}

interface CreateProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & CreateProps;

@track((props) => {
  return {
    component: "Create",
    estimate: props.estimateId,
    dispatchOnMount: () => ({ action: "Create Finance Application shown" }),
  };
})
class Create extends React.Component<Props, CreateState> {
  context!: React.ContextType<typeof Context>;
  static contextType = Context;

  constructor(props: Props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.openRedirectUrl = this.openRedirectUrl.bind(this);
  }

  public openRedirectUrl(): void {
    const { financeApplication } = this.context;
    const { push, currentJobId, estimateId } = this.props;

    window.open(financeApplication.get("redirect_url", ""), "_blank");
    push(`/jobs/${currentJobId}/estimates/${estimateId}`);
  }

  @track(() => ({
    action: "submit create application form",
  }))
  public async submit(): Promise<void> {
    const { createFinanceApplication } = this.props;
    await createFinanceApplication(this.context.estimate.id);

    this.openRedirectUrl();
  }

  public render(): React.ReactNode {
    const { financeOptions } = this.props;
    const { financeApplication, estimate } = this.context;
    const financeApplicationLoading = get(financeApplication, "loading", true);
    const estimateLoading = get(estimate, "loading", true);

    return (
      <Container className="p-3">
        <SpinnerComponent localProperty={financeApplicationLoading || estimateLoading} />
        <Row className="text-center">
          <Col>
            <RsfForm
              rootPath={["financeApplications", "byEstimateId", estimate.get("id", 0)]}
              updateFormReducer={Actions.update}
              onSubmit={this.submit}>
              <Row>
                <Col>
                  <h3>Additional information required to view Finance Programs</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Please fill out the form with your personal and financial information. The system will review your
                    data and previous applicable finance options.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select label="" name="offer_code" validate={required}>
                    <option value="">None</option>
                    {financeOptions.map((fo, idx) => (
                      <option key={idx} value={fo.get("integrations_identifier")}>
                        {fo.get("name")}
                      </option>
                    ))}
                  </Select>
                  <Check
                    label="By checking this box, I verify the following"
                    name="verify_check"
                    validate={checkRequired}
                  />

                  <p>
                    <small>I have reviewed and agree to FinMkt's Privacy Policy and Terms of Use.</small>
                  </p>

                  <p>
                    <small>
                      I have been authorized by the applicant to process their credit application using the information
                      provided. The applicant has provided a signed agreement consenting to the sharing of this
                      infomration ( and whether this application is approved or declined) with interested third parties,
                      including dealers that accept this application. I have been authorized to make inquries considered
                      necessary (including requesting reports from consumer reporting agencies and other sources and
                      collecting information to verify the applicant's identity) in evaluating the application and,
                      subsequently, for purposes for reviewing, maintaining or collecting on the applicant's account.
                      Upon request, I will advice the applicant of the name and address of each consumer reporting
                      agency from which I obtained a credit report.
                    </small>
                  </p>
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col>
                  <Button type="submit" block>
                    Fill Information
                  </Button>
                </Col>
              </Row>
            </RsfForm>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connector(Create);
