import * as angular from "angular";
import "angulartics";
import * as UAParser from "ua-parser-js";
import { dispatchException, dispatchToRsf } from "app2/src/helpers/Analytics";
import ReactGA from "react-ga4";

angular.module("angulartics.rsf.analytics", ["angulartics"])
  .config(["$analyticsProvider", "$windowProvider", ($analyticsProvider, $windowProvider) => {
    const localStorageKey = "rsf-analytics-queue";

    /**
     * Adds element(s) to the RSF-Analytics queue and updates `localStorage`
     */
    const enqueueAnalyticsData = (data: any | any[]) => {
      // Queue the record to be POSTED later
      const queue = getAnalyticsQueue();
      if (Array.isArray(data)) {
        queue.push(...data);
      } else {
        queue.push(data);
      }
      setAnalyticsQueue(queue);
    };

    /**
     * Dequeue a batch of records that matches `chunkSize` (or 5,000 when
     * omitted). The remaining items are returned to `localStorage`.
     */
    const dequeueAnalyticsChunk = (chunkSize?: number) => {
      if (chunkSize === undefined) {
        chunkSize = 5_000;
      }

      const queue = getAnalyticsQueue();
      const chunk = queue.splice(0, chunkSize);
      setAnalyticsQueue(queue);

      return chunk;
    };

    /**
     * Returns an array representing RSF-Analytics data that exists in
     * `localStorage`
     */
    const getAnalyticsQueue = (): any[] => {
      const queueJSON = localStorage.getItem(localStorageKey) || "[]";
      return JSON.parse(queueJSON);
    };

    /**
     * Overwrites the RSF-Analytics queue in `localStorage` with the value in
     * `queue`
     */
    const setAnalyticsQueue = (queue: any[]) => {
      localStorage.setItem(localStorageKey, JSON.stringify(queue));
    };

    const parser = new UAParser();

    const settings: any = {
      url: "https://analytics.remotesf.com/api/v1/events",
      userId: null,
      orgId: null,
      appVersion: null,
    };

    const $window: ng.IWindowService = $windowProvider.$get();
    let pagePathCache: string;

    $analyticsProvider.registerSetUsername((userId: string) => {
      settings.userId = userId;
    });

    $analyticsProvider.registerSetUserProperties((props: any) => {
      if (props.orgId) {
        settings.orgId = props.orgId;
      }

      if (props.appVersion) {
        settings.appVersion = props.appVersion;
      }
    });

    $analyticsProvider.registerPageTrack(function (path, properties) {
      pagePathCache = path;

      properties = properties || {};

      if ($window.location.search.indexOf("mobile") >= 0) {
        properties.mobile = true;
      }

      if ($window.location.search.indexOf("embedded") >= 0) {
        properties.embedded = true;
      }

      dispatchToRsf(angular.extend(JSON.parse(angular.toJson(properties)), {
        appVersion: settings.appVersion,
        hitType: "pageview",
        page: path,
        userAgent: parser.getResult()
      }));
    });

    $analyticsProvider.registerEventTrack((action, properties) => {
      if (!action) {
        return;
      }

      if ($window.location.search.indexOf("mobile") >= 0) {
        properties.mobile = true;
      }

      if ($window.location.search.indexOf("embedded") >= 0) {
        properties.embedded = true;
      }

      // Sets default properties
      properties = properties || {};
      properties.category = properties.category || "Event";

      // GA requires that eventValue be an integer, see:
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#eventValue
      // https://github.com/luisfarzati/angulartics/issues/81
      if (properties.value) {
        const parsed = parseInt(properties.value, 10);
        properties.value = isNaN(parsed) ? 0 : parsed;
      }

      ReactGA.event({
        category: properties.category,
        action: action,
      });

      dispatchToRsf(angular.extend(angular.copy(properties), {
        hitType: "event",
        action: action,
        page: getPage_(properties)
      }));
    });

    $analyticsProvider.registerExceptionTrack(dispatchException);

    const getPage_ = (properties) => {
      return properties.page || pagePathCache ||
        $window.location.hash.substring(1) || window.location.pathname;
    };
  }]);
