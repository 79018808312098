import * as React from "react";
import { connect, ConnectedProps } from "app2/src/connect";
import { Row, Col, Button } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { RootDispatchType } from "app2/src/store";
import Folders from "app2/src/components/Folders";
import Documents from "app2/src/components/OrgTabs/DocumentTab/Documents";
import Files from "react-files";
import * as documentActions from "app2/src/reducers/document.actions";
import * as folderActions from "app2/src/reducers/folder.actions";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import { ILocationData } from "app2/src/records/Location";
import { push, replace } from "app2/src/reducers/router.actions";
import track from "react-tracking";
import { RootState } from "app2/src/reducers";
import { documentsSelected } from "app2/src/selectors/document.selectors";
import MoveFilesDropdown from "app2/src/components/Folders/MoveFilesDropdown";
import DocumentOrder from "app2/src/components/OrgTabs/DocumentTab/DocumentOrder";
import { Can } from "app2/src/components/Common/CanComponent";
import { Acl } from "app2/src/helpers/Acl";
import { Dispatch } from "app2/src/helpers/Analytics";
import { currentOrgId } from "app2/src/selectors/org.selectors";
import { IDocumentOptions } from "app2/src/api/document.service";
import { queryParams } from "app2/src/selectors/pagination.selectors";
import { QueryParamsRecord } from "app2/src/records/Page";

const mapStateToProps = (state: RootState) => {
  return {
    orgId: currentOrgId(state),
    documentsSelected: documentsSelected(state, {}),
    folderParentId: queryParams(state, { modelName: "folder" }).getIn(["folder_id"]),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType) => {
  return {
    listFolders: (folderParentId: number, replace: () => void) =>
      dispatch(
        folderActions.AsyncActions.listFolders(folderParentId, { folderable_type: "Org", name: "Documents" }, replace),
      ),
    listDocuments: (folderId: number) =>
      dispatch(
        documentActions.AsyncActions.listDocuments(
          {
            documentableType: "org",
            signed: "unsigned",
            folder_id: folderId,
          },
          new QueryParamsRecord(),
        ),
      ),
    createDocuments: (files: any[], options: IDocumentOptions) =>
      dispatch(documentActions.AsyncActions.batchCreateDocument(files, options)),
    flashAddAlert: (level: FlashLevels, msg: string) => dispatch(commonActions.Actions.flashAddAlert(level, msg)),
    push: (location: Partial<ILocationData>) => dispatch(push(location)),
    replace: (location: Partial<ILocationData>) => dispatch(replace(location)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface DocumentTabProps {
  queryFolderParentId: number;
  tracking?: any;
}

interface DocumentTabState {
  loading: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & DocumentTabProps;

@track(
  (props) => {
    return {
      category: "OrgDocs",
      component: "OrgDocumentsTab",
      action: "show",
      org: props.orgId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
  },
)
class DocumentTab extends React.Component<Props, DocumentTabState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.onFilesChange = this.onFilesChange.bind(this);
    this.onFilesError = this.onFilesError.bind(this);
    this.navigate = this.navigate.bind(this);

    props.tracking.trackEvent({ action: "shown" });
  }

  public componentDidMount(): void {
    const { listFolders, orgId, queryFolderParentId, replace } = this.props;
    listFolders(queryFolderParentId, () =>
      replace({ pathname: "root.org_show.documents", query: { id: orgId, folder_parent_id: null } }),
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    const { listDocuments, folderParentId } = this.props;

    if (!_.isNullOrUndefined(folderParentId) && prevProps.folderParentId !== folderParentId) {
      listDocuments(folderParentId);
    }
  }

  public async onFilesChange(files: any[]): Promise<void> {
    const { orgId, createDocuments, tracking, folderParentId } = this.props;

    if (files.length > 0) {
      this.setState({ loading: true });
      await createDocuments(files, {
        documentableType: "org",
        documentableId: orgId,
        folder_id: folderParentId,
      });
      files.forEach((file) => {
        tracking.trackEvent({ action: "document uploaded", document: file.id, file: file.name });
      });
      this.setState({ loading: false });
    }
  }

  public onFilesError(error: any, file: any): void {
    const { flashAddAlert, tracking } = this.props;
    flashAddAlert(FlashLevels.danger, error.message);
    tracking.trackEvent({ action: "invalid file", file: file.name });
  }

  public navigate(folderId: number) {
    const { orgId, push } = this.props;
    push({ pathname: "root.org_show.documents", query: { id: orgId, folder_parent_id: folderId } });
  }

  public render() {
    const { documentsSelected, folderParentId } = this.props;
    const { loading } = this.state;

    const permission = Acl.can("update", "org");

    return (
      <Row data-testid="documentTab">
        <SpinnerComponent localProperty={loading} />
        <Col>
          <Row>
            <Col sm={4}>
              <h1 className="admin-title">Documents</h1>
            </Col>
            <Col sm={8}>
              <Can resource="org" permission="update">
                <Files
                  className="files-dropzone pull-right"
                  onChange={this.onFilesChange}
                  onError={this.onFilesError}
                  accepts={[".pdf"]}
                  multiple
                  maxFileSize={30000000}
                  minFileSize={0}
                  clickable>
                  <Button variant="default">
                    <FontAwesome name="plus" /> Add Documents
                  </Button>
                </Files>
              </Can>
              {documentsSelected && <MoveFilesDropdown {...this.props} fileType="documents" />}
              <p className="text--small mb-0">You can upload PDFs</p>
            </Col>
          </Row>
          <br />
          <Folders
            {...this.props}
            fileType="documents"
            readOnly={!permission}
            documentableType="org"
            navigate={this.navigate}
          />
          <br />
          <Documents folderParentId={folderParentId} />
          <DocumentOrder />
        </Col>
      </Row>
    );
  }
}

export default connector(DocumentTab);
