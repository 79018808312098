import { createAction, ActionsUnion } from "app2/src/reducers/Utils";
import { IPageData } from "../records/Page";
import { QueryParamsRecord } from "app2/src/records/Page";
import { List } from "immutable";
import { getPaginationByModel, ids, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { RootState, ThunkResult } from "app2/src/reducers";
import { RootDispatchType } from "app2/src/store";

export const FETCH_PAGE = "@pagination/FETCH_PAGE";
export const RECEIVE_PAGE = "@pagination/RECEIVE_PAGE";
export const SET_PAGE_IDS = "@pagination/SET_PAGE_IDS";
export const RECEIVE_PAGE_ERROR = "@pagination/RECEIVE_PAGE_ERROR";
export const PUSH_ID = "@pagination/PUSH_ID";
export const PREPEND_ID = "@pagination/PREPEND_ID";
export const REPLACE_ID = "@pagination/REPLACE_ID";
export const REMOVE_ID = "@pagination/REMOVE_ID";
export const CLEAR_PAGE = "@pagination/CLEAR_PAGE";
export const SET_BY_ATTR_PATH = "@pagination/SET_BY_ATTR_PATCH";
export const SET_DIRTY_IDS = "@pagination/SET_DIRTY_IDS";

export const Actions = {
  fetchPage: (model: string, queryParams: QueryParamsRecord) =>
    createAction(FETCH_PAGE, { model: model, queryParams: queryParams }),
  receivePage: (model: string, queryParams: QueryParamsRecord, pageData: Partial<IPageData>) =>
    createAction(RECEIVE_PAGE, {
      model: model,
      queryParams: queryParams,
      pageData: pageData,
    }),
  setPageIds: (modelName: string, ids: List<number>) => createAction(SET_PAGE_IDS, { modelName, ids }),
  receivePageError: (model: string, queryParams: QueryParamsRecord, errors: string[]) =>
    createAction(RECEIVE_PAGE_ERROR, {
      model: model,
      queryParams: queryParams,
      errors: errors,
    }),
  pushId: (id: number | string, modelName: string) => createAction(PUSH_ID, { id, modelName }),
  prependId: (id: number, modelName: string) => createAction(PREPEND_ID, { id, modelName }),
  replaceId: (replacedId: number, newId: number, modelName: string) =>
    createAction(REPLACE_ID, { replacedId, newId, modelName }),
  removeId: (removeId: number | string, modelName: string) => createAction(REMOVE_ID, { removeId, modelName }),
  clearPage: (modelName: string) => createAction(CLEAR_PAGE, { modelName }),
  setByAttrPath: (id: number, modelName: string, attrPath: string[], value: number) =>
    createAction(SET_BY_ATTR_PATH, { id, modelName, attrPath, value }),
  setDirtyIds: (ids: List<number>) => createAction(SET_DIRTY_IDS, { ids }),
};

export type Actions = ActionsUnion<typeof Actions>;

export const AsyncActions = {
  onSortEnd: (
    modelName: string,
    oldIndex: number,
    newIndex: number,
    attrPath = ["sort_order"],
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      if (oldIndex === newIndex) {
        return Promise.reject();
      }
      const recordList = getPaginationByModel(getState(), { modelName, path: [`${modelName}s`, "byId"] });

      const moving = recordList.get(oldIndex);
      let dirtyIds = List();

      const min = _.min([oldIndex, newIndex]);
      const max = _.max([oldIndex, newIndex]);
      const sorted = recordList.map((l) => l.getIn(attrPath)).sortBy((l) => l);

      const sortedIds = recordList
        .delete(oldIndex)
        .insert(newIndex, moving)
        .map((record, idx) => {
          const id = record.get("id");
          if (idx >= min && idx <= max) {
            dirtyIds = dirtyIds.push(id);
            dispatch(Actions.setByAttrPath(id, modelName, attrPath, sorted.get(idx)));
          }

          return id;
        });

      dispatch(Actions.setDirtyIds(dirtyIds.toSet().toList()));
      dispatch(Actions.setPageIds(modelName, sortedIds));
    };
  },
};

export const checkPageEmpty = (
  state: RootState,
  modelName: string,
  callback: (newQueryParams: QueryParamsRecord) => Promise<void>,
): Promise<void> => {
  const modelIds = ids(state, { modelName });
  const recordQueryParams = queryParams(state, { modelName });
  const page = recordQueryParams.get("page");
  const paginationRecord = pagination(state, { modelName, page });

  if (paginationRecord && paginationRecord.total_pages > 1 && modelIds.size === 0) {
    const page = _.max([paginationRecord.current_page - 1, 1]);
    const newQueryParams = recordQueryParams.setIn(["page"], page);

    return callback(newQueryParams);
  }
};
