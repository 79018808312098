import * as React from "react";
import EstimateView, { AccordionActiveKey } from "./EstimateView";
import { Button, Row, Col, Form } from "react-bootstrap";
import { List } from "immutable";
import { connect, ConnectedProps } from "app2/src/connect";
import { Actions } from "app2/src/reducers/estimate.actions";
import { RootState } from "app2/src/reducers";
import * as estimateComparisonActions from "app2/src/reducers/estimateComparison.actions";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import { EstimateComparisonLinkRecord } from "app2/src/records/EstimateComparisonLink";
import { estimateComparisonLinks } from "app2/src/selectors/estimateComparison.selectors";
import { EstimateOverviewRecord } from "app2/src/records/EstimateOverview";
import { jobOverviewEstimates, estimateUnsavedId } from "app2/src/selectors/estimate.selectors";
import { getEstimatorName, OrgRecord } from "app2/src/records/OrgRecord";
import { org } from "app2/src/selectors/org.selectors";
import track from "react-tracking";
import { RootDispatchType } from "app2/src/store";

const mapStateToProps = (state: RootState, ownProps: IComparatorProps) => {
  const { jobId } = ownProps;
  return {
    org: org(state, ownProps),
    unsavedEstimateId: estimateUnsavedId(state),
    jobOverviewEstimates: jobOverviewEstimates(state, { jobId }),
    estimateComparisonLinks: estimateComparisonLinks(state, { jobId }),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: IComparatorProps) => {
  const { jobId, orgId } = ownProps;
  return {
    duplicateEstimate: (estimateId: number) => dispatch(Actions.duplicateEstimate(estimateId)),
    addEstimateComparisonLink: (estimateId: number) =>
      dispatch(estimateComparisonActions.Actions.addEstimateComparisonLink(estimateId, jobId)),
    getEstimate: (estimateId: number) => dispatch(estimateActions.AsyncActions.getEstimate(estimateId, orgId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IComparatorProps {
  orgId: number;
  jobId: number;
  goToEstimate: (estimateId: number) => void;
}

export interface IComparatorState {
  activeKey: AccordionActiveKey;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IComparatorProps;

@track({ component: "Comparator" })
class Comparator extends React.Component<Props, IComparatorState> {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: "overview",
    };

    this.duplicate = this.duplicate.bind(this);
    this.selectEstimate = this.selectEstimate.bind(this);
    this.accordionToggle = this.accordionToggle.bind(this);
  }

  @track({ action: "Duplicate" })
  public duplicate() {
    const { unsavedEstimateId, estimateComparisonLinks, duplicateEstimate, addEstimateComparisonLink } = this.props;
    duplicateEstimate(estimateComparisonLinks.first<EstimateComparisonLinkRecord>().estimate_id);
    addEstimateComparisonLink(unsavedEstimateId);
  }

  @track({ action: "Select Estimate" })
  public async selectEstimate(e) {
    const { addEstimateComparisonLink, getEstimate } = this.props;
    const id = parseInt(e.target.value);
    try {
      await getEstimate(id);
      addEstimateComparisonLink(id);
    } catch {}
  }

  @track((_props, state, [newKey]) => ({ action: "Accordion Toggle", activeKey: state.activeKey, newKey }))
  public accordionToggle(newKey: AccordionActiveKey) {
    const { activeKey } = this.state;

    if (activeKey === newKey) {
      this.setState({ activeKey: "" });
    } else {
      this.setState({ activeKey: newKey });
    }
  }

  public render() {
    const { orgId, org, jobOverviewEstimates, estimateComparisonLinks, goToEstimate } = this.props;
    const { activeKey } = this.state;

    const estimateIds = estimateComparisonLinks.map((ecl) => ecl.estimate_id);

    return (
      <Row className="comparator">
        {estimateIds.map((estimateId, index) => {
          return (
            <Col key={index} sm={4}>
              <EstimateView
                key={index}
                activeKey={activeKey}
                orgId={orgId}
                accordionToggle={this.accordionToggle}
                estimateId={estimateId}
                goToEstimate={goToEstimate}
              />
            </Col>
          );
        })}
        {estimateIds.count() === 1 && <Col sm={4} />}
        {estimateIds.count() < 3 && (
          <Col sm={4} className="add-estimate-column">
            {estimateIds.count() > 0 && (
              <React.Fragment>
                <Button onClick={this.duplicate}>Duplicate</Button>
                <h5 className="lined-title grey-text"> OR </h5>
              </React.Fragment>
            )}
            <Form.Control as="select" className="estimate-select" onChange={this.selectEstimate}>
              <option value="">--Select Existing {getEstimatorName(org)}--</option>
              {jobOverviewEstimates.map((d, idx) => {
                return (
                  <option key={idx} value={d.id} disabled={estimateIds.includes(d.id)}>
                    {d.name}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        )}
      </Row>
    );
  }
}

export default connector(Comparator);
