import { createSelector } from "reselect";
import { EstimateLineItemRecord, IEstimateLineItemData } from "../records/EstimateLineItem";
import { List } from "immutable";
import { RootState } from "../reducers";
import { images, toImagesJson } from "./image.selectors";
import { toOpeningEstimationsJson } from "./openingEstimation.selectors";
import { toDocumentsJson } from "./document.selectors";
import { toEstimateLineItemOptionsJson } from "./estimateLineItemOption.selectors";
import { ImageRecord } from "app2/src/records/Image";
import { cachedProduct } from "app2/src/selectors/product.selectors";

export const estimateLineItem = (state: RootState, props: any) =>
  state.getIn(["estimateLineItems", "byId", props.estimateLineItemId]);
export const estimateLineItemsById = (state: RootState) => state.getIn(["estimateLineItems", "byId"]);
export const lastSavedEstimateLineItemsById = (state: RootState) => state.getIn(["estimateLineItems", "lastSavedById"]);
export const openingEstimationsById = (state: RootState) => state.getIn(["openingEstimations", "byId"]);

export const estimateLineItems = createSelector(
  [estimateLineItemsById, (_, props: any) => props],
  (estimateLineItemsById: Map<number, EstimateLineItemRecord>, props: any): List<EstimateLineItemRecord> => {
    return estimateLineItemProcess(estimateLineItemsById, props);
  },
);

export const lastSavedEstimateLineItems = createSelector(
  [lastSavedEstimateLineItemsById, (_, props: any) => props],
  (estimateLineItemsById: Map<number, EstimateLineItemRecord>, props: any): List<EstimateLineItemRecord> => {
    return estimateLineItemProcess(estimateLineItemsById, props);
  },
);

const estimateLineItemProcess = (estimateLineItemsById: Map<number, EstimateLineItemRecord>, props: any) => {
  const { estimateLineItemIds } = props;
  let { fullEstimate } = props;
  if (_.isUndefined(fullEstimate)) {
    fullEstimate = false;
  }
  return estimateLineItemIds
    .map((lineItemId: number) => {
      return estimateLineItemsById.get(lineItemId);
    })
    .filter((lineItem: EstimateLineItemRecord) => {
      return fullEstimate || !lineItem._destroy;
    })
    .toList();
};

export const estimateLineItemImage = createSelector(
  [estimateLineItem, (state: RootState, props: any) => ({ state, props })],
  (estimateLineItem: EstimateLineItemRecord, stateProps: any): ImageRecord => {
    const { state, props } = stateProps;
    if (estimateLineItem.image_ids.size > 0) {
      const imgs = images(state, { imageIds: estimateLineItem.image_ids }).filter((img) => !img._destroy);
      if (imgs.size > 0) {
        return imgs.first();
      }
    }

    if (estimateLineItem.product_id) {
      const prod = cachedProduct(state, {
        activatedPriceListId: props.activatedPriceListId,
        productId: estimateLineItem.product_id,
      });
      if (prod && prod.images.size > 0) {
        return prod.images.first();
      }
    }

    return null;
  },
);

export const toEstimateLineItemsJson = createSelector(
  [estimateLineItems, (state, props) => ({ state, props })],
  (estimateLineItems: List<EstimateLineItemRecord>, stateProps: any): IEstimateLineItemData[] => {
    const { state, props } = stateProps;
    return estimateLineItems
      .map((lineItem: EstimateLineItemRecord) => {
        const lineItemData = lineItem.toJSON() as any;

        lineItemData.images = toImagesJson(state, { ...props, imageIds: lineItem.image_ids });

        lineItemData.documents = toDocumentsJson(state, { ...props, documentIds: lineItem.document_ids });

        lineItemData.opening_estimations = toOpeningEstimationsJson(state, {
          ...props,
          openingEstimationIds: lineItem.opening_estimation_ids,
        });

        lineItemData.options = toEstimateLineItemOptionsJson(state, {
          ...props,
          estimateLineItemOptionIds: lineItem.option_ids,
        });

        delete lineItemData.option_ids;
        delete lineItemData.opening_estimation_ids;
        delete lineItemData.image_ids;
        delete lineItemData.document_ids;
        delete lineItemData.editing;
        delete lineItemData.newly_added;
        if (!lineItemData._destroy) {
          delete lineItemData._destroy;
        }

        return lineItemData;
      })
      .toArray();
  },
);
