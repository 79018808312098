import * as React from "react";
import { List, Map, getIn } from "immutable";
import { Accordion, Row, Col } from "react-bootstrap";
import { QueryParamsRecord } from "app2/src/records/Page";
import { AccessRecord } from "app2/src/records/UserRecord";
import { AccessEditor } from "./AccessEditor";
import { SearchBox } from "app2/src/components/Common/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { getPaginationByModel, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import * as userActions from "app2/src/reducers/user.actions";
import { userAccessCount, currentUserId as currentUserIdSelector } from "app2/src/selectors/user.selectors";
import { StandardPagination } from "../../../../Pagination/Standard";
import { RootState } from "app2/src/reducers";
import { AsyncActions } from "app2/src/reducers/access.actions";
import { model as modelName } from "app2/src/reducers/access.reducer";
import { AclByUserAccess } from "app2/src/reducers/user.reducer";
import { hydratedUserAccesses } from "app2/src/selectors/access.selectors";
import { MetaPaginationRecord } from "app2/src/records/MetaPagination";

export interface AccessesProps {
  userId: number;
}

export const Accesses: React.FC<AccessesProps> = ({ userId }) => {
  // hooks
  const dispatch = useDispatch();
  const [orgName, setOrgName] = React.useState<string>("");
  const [toggleActiveKey, setToggleActiveKey] = React.useState<string>("");

  // selectors
  const accessCount = useSelector<RootState, number>((state) => userAccessCount(state, { userId }));
  const currentUserId = useSelector<RootState, number>(currentUserIdSelector);
  const currentQueryParams = useSelector<RootState, QueryParamsRecord>((state) => queryParams(state, { modelName }));
  const allowedAclValues = useSelector<RootState, AclByUserAccess>((state) =>
    state.getIn(["users", "allowedAclByUserAccess"]),
  );
  const paginationRecord = useSelector<RootState, MetaPaginationRecord>((state) =>
    pagination(state, { modelName, page: currentQueryParams.get("page") }),
  );

  // if it's an existing user we load access via the API and pagination
  // if it's a new user, we load accesses directly from redux
  let accesses: List<AccessRecord>;
  if (userId >= 0) {
    // existing User
    accesses = useSelector<RootState, List<AccessRecord>>(
      (state) =>
        getPaginationByModel(state, {
          path: [modelName, "byId"],
          modelName,
        }) as List<AccessRecord>,
    );
  } else {
    // new User
    accesses = useSelector<RootState, List<AccessRecord>>((state) => hydratedUserAccesses(state, { userId: userId }));
  }

  // hooks
  const calculatedUserId = React.useMemo(() => (userId < 0 ? currentUserId : userId), [userId, currentUserId]);

  // lifecycle
  React.useEffect(() => {
    if (userId >= 0) {
      searching();
    }
  }, [userId]);

  React.useEffect(() => {
    accesses.forEach((access) => {
      const key = `${calculatedUserId}:${access.uid}`;
      if (
        !getIn(allowedAclValues, ["acl", key, "editable"], null) &&
        !getIn(allowedAclValues, ["loading", key], false)
      ) {
        dispatch(userActions.AsyncActions.loadAllowedAcl(calculatedUserId, access.uid, "editable"));
        dispatch(userActions.AsyncActions.loadAllowedAcl(calculatedUserId, access.uid, ""));
      }
    });
  }, [accesses]);

  // methods
  const searching = (newOrgName = "") => {
    const nextQueryParams = currentQueryParams.merge({
      per_page: 10,
      page: 1,
      parameters: Map({ includes: List(["acl", "org"]) }),
    });
    setOrgName(newOrgName);
    dispatch(AsyncActions.loadAccesses(userId, newOrgName, nextQueryParams));
  };

  const paging = (page: number) => {
    const nextQueryParams = currentQueryParams.merge({ page: page, per_page: 10 });

    dispatch(AsyncActions.loadAccesses(userId, orgName, nextQueryParams));
  };

  const changeToggle = (key: string) => {
    if (key === toggleActiveKey) {
      setToggleActiveKey("");
    } else {
      setToggleActiveKey(key);
    }
  };

  return (
    <>
      <Row className="bg-light p-2">
        <Col md={2} sm={3} className="align-self-center">
          <h3 className="p-0 m-0 align-self-center">Accesses ({accessCount})</h3>
        </Col>
        <Col md={10} sm={9} className="align-self-center">
          <SearchBox
            placeholder="Search for Organization Name"
            query={orgName}
            searching={searching}
            inputProps={{ disabled: userId < 0 }}
          />
        </Col>
      </Row>
      <Accordion data-testid="main-accordion" defaultActiveKey="" activeKey={toggleActiveKey} className="pt-3">
        <Row className="table-header">
          <Col md={3}>Org Name</Col>
          <Col md={3}>Role</Col>
          <Col md={3}>Billable</Col>
          <Col md={3}></Col>
        </Row>
        {accesses.map((access, idx) => (
          <AccessEditor key={idx} access={access} activeToggleKey={toggleActiveKey} changeToggle={changeToggle} />
        ))}
        <Row className="table-footer">
          <Col md={12}>{userId > 0 && <StandardPagination metadata={paginationRecord} pageChanged={paging} />}</Col>
        </Row>
      </Accordion>
    </>
  );
};
