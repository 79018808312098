import * as windowActions from "./window.actions";
import { Map, Record } from "immutable";
import { WindowRecord, fromJSON } from "../records/Window";
import { RootActions, RootState } from ".";
import { rootKey } from "app2/src/selectors/window.selectors";
import { calculateOpeningArea, calculateOpeningUI, defaultOpeningProps } from "app2/src/records/Opening";
import { parseUpdateForm, reset } from "app2/src/reducers/Reducer";

export const WindowStateRecord = Record({
  byId: Map<string, WindowRecord>(),
  lastSavedById: Map<string, WindowRecord>(),
  unsavedId: -1,
});

export const initialState = WindowStateRecord();

export type WindowState = typeof initialState;

export const reducer = (state: RootState, action: RootActions): RootState => {
  const model = rootKey;
  if (state && !state.get(model)) {
    state = state.set(model, initialState);
  }

  let window: WindowRecord;
  switch (action.type) {
    case windowActions.RECEIVE_WINDOW:
      window = fromJSON({ ...action.payload.window });

      return state
        .setIn([model, "byId", window.id.toString()], window)
        .setIn([model, "lastSavedById", window.id.toString()], window);

    case windowActions.EDIT_WINDOW:
      window = fromJSON({ ...action.payload.window });

      return state.setIn([model, "byId", window.id.toString()], window);

    case windowActions.RESET_WINDOWS:
      action.payload.windowIds.forEach((windowId) => {
        state = reset(state, model, windowId.toString());
      });
      return state;

    case windowActions.UPDATE_FORM:
      const { rootPath, name, value } = action.payload.event;
      const nameArray = name.split(".");
      const rootPathName = rootPath.concat(nameArray);
      state = state.setIn(rootPathName, parseUpdateForm(defaultOpeningProps, name, value));

      nameArray.pop();
      const width = state.getIn(rootPath.concat(nameArray.concat(["width"])));
      const height = state.getIn(rootPath.concat(nameArray.concat(["height"])));
      return state
        .setIn(rootPath.concat(nameArray.concat(["area"])), calculateOpeningArea(width, height))
        .setIn(rootPath.concat(nameArray.concat(["ui"])), calculateOpeningUI(width, height));

    default:
      return state;
  }
};
