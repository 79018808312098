import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import { Container, Col, Row, Button, FormControl, DropdownButton, Dropdown } from "react-bootstrap";
import { StandardPagination } from "../Pagination/Standard";
import * as leadsActions from "app2/src/reducers/leads.actions";
import { RootState, RootActions } from "app2/src/reducers";
import Spinner from "app2/src/components/SpinnerComponent";
import { IPagination } from "app2/src/api/integrations/eagleview.service";
import { Map } from "immutable";
import { LeadRecord } from "app2/src/reducers/leads.reducer";
import * as FontAwesome from "react-fontawesome";
import { IHeaderSearchArgs } from "app/src/Header/HeaderCtrl";
import { ILeadQueryParams } from "app2/src/reducers/leads.actions";

const mapStateToProps = (state: RootState, ownProps: LeadsComponentProps) => {
  const page = state.getIn(["leads", "page"], 1);
  return {
    leadsData: state.getIn(["leads", "pages", page]),
    leadsById: state.getIn(["leads", "leadsById"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: LeadsComponentProps) => {
  return {
    queryLeadData: (params: ILeadQueryParams) => {
      return dispatch(leadsActions.AsyncActions.getPage(params));
    },
    createJob: (lead_id: number) => {
      return dispatch(leadsActions.AsyncActions.createJob(lead_id));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface LeadsComponentProps {
  $state: ng.ui.IStateService;
  $scope: ng.IScope;
}

export interface LeadsComponentState {
  params: ILeadQueryParams;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & LeadsComponentProps;

class LeadsComponent extends React.Component<Props, LeadsComponentState> {
  constructor(props: Props) {
    super(props);

    props.$scope.$emit("header:search", { show: true });
    props.$scope.$on("header:search_change", (e: ng.IAngularEvent, args: IHeaderSearchArgs) => {
      // this.$analytics.eventTrack("search", { category: "Job List" });
      const editedParams = this.state.params;
      editedParams.query = args.searchString;
      props.queryLeadData(editedParams);
    });

    this.state = {
      params: {
        page: 1,
        filter: "unclaimed",
        query: "",
      },
    };

    props.queryLeadData(this.state.params);
    this.clearSearch = this.clearSearch.bind(this);
  }

  public queryPage = (page: number) => {
    const editParams = this.state.params;
    editParams.page = page;
    this.props.queryLeadData(editParams);
  };

  public handleAccept(lead) {
    this.props.createJob(lead.id);
  }

  public handleJob(lead) {
    this.props.$state.go("job_header.job_show.info", { id: lead.job_id });
  }

  public handleFilter(filter: string) {
    const editedParams = this.state.params;
    editedParams.filter = filter;
    this.props.queryLeadData(editedParams);
  }

  public handleReassign(event, lead) {
    // let newLeadsById = _.clone(this.state.leadsById);
    // newLeadsById[lead.id].org_id = event.target.value;
    // this.setState({
    //   leadsById: newLeadsById
    // });
  }

  public isEven(idx) {
    return idx % 2 === 0;
  }

  public buttonActions(lead_id) {
    const { leadsById } = this.props;
    if (leadsById.getIn([lead_id]).loading) {
      return <FontAwesome name="circle-o-notch fa-spin fa-gray" size="lg" />;
    } else if (leadsById.getIn([lead_id]).job_id > 0) {
      return (
        <Button
          size="sm"
          variant="default"
          onClick={() => {
            this.handleJob(leadsById.getIn([lead_id]));
          }}>
          Details
        </Button>
      );
    } else {
      return (
        <Button
          size="sm"
          variant="add"
          onClick={() => {
            this.handleAccept(leadsById.getIn([lead_id]));
          }}>
          Accept
        </Button>
      );
    }
  }

  public clearSearch() {
    const editedParams = this.state.params;
    editedParams.query = "";
    this.props.queryLeadData(editedParams);
  }

  public render() {
    const { leadsData } = this.props;
    const { query } = this.state.params;
    return (
      <Spinner stateProperty={["leads", "pages", this.state.params.page, "loading"]}>
        <Container>
          <Row>
            <Col xs={6}>
              <h1>Leads</h1>
            </Col>
            <Col xs={6}>
              <div className="pull-right">
                <DropdownButton title={"Filter"} id={"dropdown-filter"} variant="info">
                  <Dropdown.Item eventKey="1" onClick={() => this.handleFilter("claimed")}>
                    Claimed
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={() => this.handleFilter("unclaimed")}>
                    Unclaimed
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3" onClick={() => this.handleFilter("")}>
                    All
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </Col>
          </Row>
          <Row>
            {query.length > 0 && leadsData && leadsData.leads.length === 0 ? (
              <Row>
                <Col md={12}>
                  <div className="form-section blank-state">
                    <img src="/assets/images/icons-large/docs.33cddf3f.png" />
                    <h2>Your search returned no results.</h2>
                    <Button onClick={this.clearSearch}>Reset Search</Button>
                  </div>
                </Col>
              </Row>
            ) : null}
            {query.length === 0 && leadsData && leadsData.leads.length === 0 ? (
              <Row>
                <Col md={12}>
                  <div className="form-section blank-state">
                    <img src="/assets/images/icons-large/docs.33cddf3f.png" />
                    <h2>Sorry, you don't have any leads.</h2>
                  </div>
                </Col>
              </Row>
            ) : null}
            {leadsData && leadsData.leads.length > 0 ? <Col md={12}>{this.renderLeadList()}</Col> : null}
          </Row>
        </Container>
      </Spinner>
    );
  }

  public renderAdminLeadList() {
    const { leadsData } = this.props;
    const { leads } = leadsData;
    return (
      <Container>
        <Row className="list-header">
          <Col sm={3}>Name</Col>
          <Col sm={3}>Created</Col>
          <Col sm={3}>Assignment</Col>
        </Row>
        {Object.keys(leads).map((lead_id, idx) => {
          return (
            <Row key={idx} className={`list-row ${this.isEven(idx) ? "even-row" : "odd-row"}`}>
              <Col sm={4}>{leads[lead_id].first_name + " " + leads[lead_id].last_name}</Col>
              <Col sm={4}>
                {new Date(leads[lead_id].created_at).toLocaleString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Col>
              <Col sm={4}>
                <FormControl
                  as="select"
                  value={leads[lead_id].org_id}
                  onChange={(ev) => {
                    this.handleReassign(ev, leads[lead_id]);
                  }}
                  disabled={leads[lead_id].job_id > 0}>
                  {/*{Object.keys(this.state.orgsById).map((org_id) => {*/}
                  {/*return (*/}
                  {/*<option key={lead_id + "-" + org_id} value={this.state.orgsById[org_id].id}>{this.state.orgsById[org_id].name}</option>*/}
                  {/*);*/}
                  {/*})}*/}
                  {/*<option key={lead_id + "-unassign"} value="unassign">Unassign</option>*/}
                </FormControl>
              </Col>
            </Row>
          );
        })}
        <StandardPagination metadata={this.props.leadsData.pagination} pageChanged={this.queryPage} />
      </Container>
    );
  }

  public renderLeadList() {
    const { leadsData, leadsById } = this.props;
    let leads: number[], pagination: IPagination;
    if (!leadsData) {
      leads = [];
      pagination = {} as IPagination;
    } else {
      leads = leadsData.leads;
      pagination = leadsData.pagination;
    }

    return (
      <Container>
        <Row className="list-header">
          <Col sm={5}>Name</Col>
          <Col sm={5}>Created</Col>
          <Col sm={2} />
        </Row>
        {leads.map((lead_id, idx) => {
          return (
            <Row key={idx} className={`list-row ${this.isEven(idx) ? "even-row" : "odd-row"}`}>
              <Col sm={5}>{leadsById.getIn([lead_id]).first_name + " " + leadsById.getIn([lead_id]).last_name}</Col>
              <Col sm={5}>
                {new Date(leadsById.getIn([lead_id]).created_at).toLocaleString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Col>
              <Col sm={2}>{this.buttonActions(lead_id)}</Col>
            </Row>
          );
        })}
        <StandardPagination metadata={pagination} pageChanged={this.queryPage} />
      </Container>
    );
  }
}

export default connector(LeadsComponent);
