import { IOrg, OrgAclType } from "app/src/Models/Org";
import { useState, dispatch } from "app2/src/storeRegistry";
import { PrettyNameService, IPretty } from "app/src/Common/PrettyNameService";
import * as toolActions from "app2/src/reducers/org/tool.actions";
import { subscriber } from "app2/src/helpers/Subscribe";
import { StoreRegistry } from "app2/src/storeRegistry";

const TOOL_VALUES = [
  "renoworks_masking",
  "eagleview_order",
  "google_maps",
  "bing_ortho",
  "bing_birdseye",
  "zoom_earth",
  "terra_server",
  "pictometry_online",
  "pictometry_connect",
  "map_developers_area",
  "zillow",
  "trulia",
  "greensky_link",
  "chi_overhead_door",
  "homeguard",
  "houzz_link",
  "sketchup",
  "global_transport_link",
  "service_finance_link",
  "foundation_finance_link",
  "foundation_finance_dealer_link",
  "ami_design_showcase_siding",
  "ami_design_showcase_pres_window",
  "ami_design_showcase_alside_window",
  "sighten_solar",
  "enerbank_link",
  "pella_portal",
  "eagleview_order_decra",
  "provia",
  "provia_non_portal",
  "advisar_link",
  "square_d_link",
  "lp_smartside_link",
  "york_hvac_link",
  "sigler_online_link",
  "hearth_financing_link",
  "sherwin_williams_colorsnap",
  "wellsfargo_link",
  "angies_list_link",
  "home_advisor_link",
  "gaf_quick_measure_link",
  "gaf_smart_money_link",
  "timbertech_worry_less",
  "gaf_vhr_link",
  "gaf_vent_calc_link",
  "lowes_deck_designer",
  "thermatru_design_your_door",
  "pella_lowes_product_designer",
];

const DESIGN_TOOL_VALUES = [
  "ca_3d_demo",
  "oc_design_eyeq",
  "gaf_vhr",
  "daltile_demo",
  "abbeycarpet_demo",
  "provia",
  "provia_non_portal",
  "plygem_visualizer",
  "renuit_cabinet_demo",
  "colorview",
  "alside_renoworks",
  "lp_smartside_visualizer",
  "sunrise_configurator",
  "royal_homeplay",
  "iko_roof_viewer",
  "square_d_ezsel",
  "paragon_visualizer",
  "timbertech_deck_designer",
  "lowes_deck_designer",
  "thermatru_design_your_door",
  "pella_lowes_product_designer",
];

export class ToolService {
  public toolValues: string[];
  public designToolValues: string[];
  public tools: string[] = [];
  public design_tools: string[] = [];
  public Pretty: IPretty;
  private _subscribeToolValues: () => void;

  constructor(
    public org: IOrg,
    public type: string,
    public $scope: ng.IScope,
  ) {
    this.Pretty = StoreRegistry.get("Pretty");

    switch (type) {
      case "setting":
        this.tools = org.fetchSettingsAcl(OrgAclType.tools);
        this.toolValues = TOOL_VALUES;
        this.design_tools = org.fetchSettingsAcl(OrgAclType.design_tools);
        this.designToolValues = DESIGN_TOOL_VALUES;
        break;
      case "preference":
        this._subscribeToolValues = subscriber.subscribe<string>("tools.byOrgId", () => {
          const tool_ids = useState().getIn(["tools", "byOrgId", this.org.id, "tools"]).toArray();
          const design_ids = useState().getIn(["tools", "byOrgId", this.org.id, "design_tools"]).toArray();

          this.tools = org.fetchPreferencesAcl(OrgAclType.tools);
          this.toolValues = tool_ids.concat(org.fetchSettingsAcl(OrgAclType.tools));
          this.design_tools = org.fetchPreferencesAcl(OrgAclType.design_tools);
          this.designToolValues = design_ids.concat(org.fetchSettingsAcl(OrgAclType.design_tools));
          _.defer(() => {
            this.$scope.$digest();
          });
        });

        dispatch(toolActions.AsyncActions.listTools(this.org.id, null));
        _.defer(() => {
          this.$scope.$digest();
        });
    }
  }

  public update(tools: string[], type: string) {
    this[type] = tools;

    switch (this.type) {
      case "setting":
        this.org.setSettingsAcl(OrgAclType[type], tools);
        break;
      case "preference":
        this.org.setPreferencesAcl(OrgAclType[type], tools);
        break;
    }
  }
}
