import { IPagingMetadata } from "app/src/Models/PagingMetadata";
import { IPlan } from "app/src/Billing/Models/Plan";
import { ISubscription } from "app/src/Billing/Models/Subscription";
import { IFee } from "app/src/Billing/Models/Fee";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IOrg } from "../../Models/Org";
import { IBaseConfig } from "../../Common/IBaseConfig";

export interface IAccount extends ng.resource.IResource<IAccount>, AccountPrototype {
  $update(): ng.IPromise<IAccount>;
  $update(params?: Object, success?: Function, error?: Function): ng.IPromise<IAccount>;
  $update(success: Function, error?: Function): ng.IPromise<IAccount>;
}

export interface IBillingStatus {
  kind: string;
  status: number;
  $promise: ng.IPromise<any>;
}

export interface IAccountResource extends ng.resource.IResourceClass<IAccount>, IRsfResource {
  fromJSON?(data: any): IAccount;
  byOrg?(data: any): IAccount;
  check?(data: any): IBillingStatus;
  landing?(data: any): IAccount;
  setup?(data: any): IAccount;
  update?(data: any): IAccount;
  createSession?(data: any): any;
}

export interface IAccountResponse extends ng.resource.IResource<IAccountResponse> {
  accounts: IAccount[];
  meta: IPagingMetadata;
}

export interface IFeeSubHistory {
  installments: any[];
}

export interface IFeeSubscription {
  next_date: Date;
  history: IFeeSubHistory;
}

class AccountPrototype {
  public id: number;
  public uuid: string;
  public org_id: number;
  public org?: IOrg;
  public billing_plan_id: number;
  public billing_fee_id: number;
  public plan?: IPlan;
  public fee?: IFee;
  public fee_subscription?: IFeeSubscription;
  public customers: any[];
  public subscriptions: ISubscription[];
  public created_at: Date;
  public updated_at: Date;
  public user_count: number;
  public invoice_text?: IInvoiceText;
}

export interface IInvoiceTextLine {
  header: string;
  text: string;
}

export interface IInvoiceText {
  lines: IInvoiceTextLine[];
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig): IAccountResource => {
  const url = BaseConfig.BASE_URL + "/billing/v1/accounts/:id";

  const singleResponseTransform = (response: string, headers: ng.IHttpHeadersGetter, status: number): IAccount => {
    if (status < 200 || status > 299) {
      return new Account({});
    }

    return Account.fromJSON(JSON.parse(response).account);
  };

  const multipleResponseTransform = (
    response: string,
    headers: ng.IHttpHeadersGetter,
    status: number,
  ): IAccountResponse => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const data = JSON.parse(response);
    _.each(data.accounts, (account, index) => {
      data.accounts[index] = Account.fromJSON(account);
    });

    return data;
  };

  const transformRequest = (account: IAccount): string => {
    const data = JSON.parse(angular.toJson(account));

    const clone = {} as any;

    if (data.org_id) {
      clone.org_id = data.org_id;
    }

    if (data.user_count) {
      clone.user_count = data.user_count;
    }

    if (data.invoice_text) {
      clone.invoice_text = data.invoice_text;
    }

    const request: any = { account: clone };

    if (data.stripe_id) {
      request.stripe_id = data.stripe_id;
    }

    if (data.email) {
      request.email = data.email;
    }

    if (data.include) {
      request.include = data.include;
    }

    return angular.toJson(request);
  };

  const Account: IAccountResource = <IAccountResource>$resource(
    url,
    { id: "@id" },
    {
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: multipleResponseTransform,
        isArray: false,
      },
      byOrg: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/billing/v1/accounts/org/:org_id",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      landing: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/billing/v1/accounts/landing",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      setup: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        url: BaseConfig.BASE_URL + "/billing/v1/accounts/setup",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformRequest: transformRequest,
        transformResponse: singleResponseTransform,
        isArray: false,
      },
      check: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/billing/v1/accounts/check/:org_id",
        isArray: false,
      },
      createSession: <ng.resource.IActionDescriptor>{
        method: "GET",
        url: BaseConfig.BASE_URL + "/billing/v2/accounts/:org_id/session",
        params: {
          org_id: "@org_id",
          customer_id: "@customer_id",
        },
      },
    },
  );

  _.hiddenExtend(Account.prototype, AccountPrototype.prototype);

  Account.fromJSON = (data: any): IAccount => {
    return new Account(data);
  };

  Account.inject = (injected: IRepository) => {
    resources = injected;
  };

  return Account;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
