import * as React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { RootState, RootActions } from "app2/src/reducers";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "app2/src/connect";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import * as FontAwesome from "react-fontawesome";
import track from "react-tracking";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IInlineEditorProps) => {
  return {
    editEstimateName: (name: string) => dispatch(estimateActions.Actions.editEstimateName(ownProps.estimateId, name)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IInlineEditorProps {
  estimateId: number;
  name: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IInlineEditorProps;

export interface IInlineEditorState {
  editing: boolean;
}

@track({ component: "InlineEditor" })
export class InlineEditor extends React.Component<Props, IInlineEditorState> {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
  }

  @track((props) => ({ action: "edit estimate name", estimateName: props.name }))
  public edit(): void {
    this.setState({ editing: true });
  }

  @track((props) => ({ action: "save estimate name", estimateName: props.name }))
  public save(): void {
    this.setState({ editing: false });
  }

  public getHeaderElement() {
    const { editEstimateName, name } = this.props;
    const { editing } = this.state;

    if (editing) {
      return (
        <InputGroup className="estimate-name-editor">
          <Form.Control
            type="text"
            defaultValue={name}
            onChange={(e) => editEstimateName((e.target as HTMLTextAreaElement).value)}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <FontAwesome
                className="link"
                name="save fa-gray"
                title="Save estimate name"
                size="lg"
                onClick={this.save}
              />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      );
    } else {
      return (
        <React.Fragment>
          <div className="centered">
            <span className="align-middle text-largest estimate-name">{name}</span>
            <FontAwesome
              className="link"
              name="edit fa-gray"
              title="Edit estimate name"
              size="lg"
              onClick={this.edit}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  public render() {
    return (
      <Row className="inline-editor">
        <Col>{this.getHeaderElement()}</Col>
      </Row>
    );
  }
}

export default connector(InlineEditor);
