import { RootState } from "app2/src/reducers";
import { List } from "immutable";
import { createSelector } from "reselect";
import { RoomRecord } from "app2/src/records/Room";
import { currentLastSavedMeasurement, currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { recordChanged } from "app2/src/helpers/Record";
import { MeasurementRecord } from "../records/Measurement";

export const rootKey = "rooms";

/**
 * Returns a Room record
 *
 * @param {RootState} state
 * @param {{ roomId: number }} options
 * @returns {RoomRecord}
 */
export const room = (state: RootState, props: any) => state.getIn([rootKey, "byId", props.roomId.toString()]);

/**
 * Returns a last loaded from API Room record
 *
 * @param {RootState} state
 * @param {{ roomId: number }} options
 * @returns {RoomRecord}
 */
export const lastSavedRoom = (state: RootState, props: any) =>
  state.getIn([rootKey, "lastSavedById", props.roomId.toString()]);

/**
 * Returns a list of Room records
 *
 * @param {RootState} state
 * @param {{ roomIds: number }} options
 * @returns {List<RoomRecord>}
 */
export const rooms = createSelector([(state, props) => ({ state, props })], (stateProps: any): List<RoomRecord> => {
  const { state, props } = stateProps;
  const { roomIds } = props;
  return roomIds.map((id: number) => room(state, { roomId: id }));
});

/**
 * Returns a list of last loaded from API Room records
 *
 * @param {RootState} state
 * @param {{ roomIds: number }} options
 * @returns {List<RoomRecord>}
 */
export const lastSavedRooms = createSelector(
  [(state, props) => ({ state, props })],
  (stateProps: any): List<RoomRecord> => {
    const { state, props } = stateProps;
    const { roomIds } = props;
    return roomIds.map((id: number) => lastSavedRoom(state, { roomId: id }));
  },
);

/**
 * Looks for room changes
 *
 * @params {RootState} state RootState
 * @params {{}} options NONE
 * @returns {MeasurementRecord} measurement
 */
export const roomsChanged = (state: RootState, props: any): boolean => {
  const measurement = currentMeasurement(state, props);
  const lastSavedMeasurement = currentLastSavedMeasurement(state, props);
  return recordChanged(
    rooms(state, { roomIds: measurement?.get("room_ids") || List() }),
    lastSavedRooms(state, { roomIds: lastSavedMeasurement?.get("room_ids") || List() }),
  );
};

/**
 * Gets the current measurement's rooms
 *
 * @params {RootState} state The RootState
 * @params {{}} options NONE
 * @returns {List<EstimateSignedDocumentRecord>} List
 */
export const currentMeasurementRooms = createSelector(
  [currentMeasurement, (state) => ({ state })],
  (measurement: MeasurementRecord, { state }): List<RoomRecord> => {
    return measurement?.room_ids.map((id) => room(state, { roomId: id }));
  },
);
