import { Map, List, Record, fromJS } from "immutable";
import { fromJSON as orgFromJSON, OrgRecord, IOrgData } from "./OrgRecord";
import { checkDate, Nullable } from ".";

export const fromJSON = (data: Partial<IUserData>): UserRecord => {
  const record: IUserRecord = { ...(data as any) };

  if (data.accesses) {
    record.accesses = List(
      new Set(
        data.accesses.map((a) => {
          if (typeof a === "number") return a;
          return a.id;
        }),
      ),
    );
  }

  if (data.permissions) {
    record.permissions = Map<AclKeys, AclValues[]>(data.permissions as any);
  }

  if (data.org) {
    record.org = orgFromJSON(data.org);
  }

  if (data.created_at) {
    record.created_at = checkDate(data.created_at);
  }

  return new UserRecord(record);
};

export const getFullName = (user: Nullable<UserRecord>): string => {
  if (user) {
    return `${user.first_name || ""} ${user.last_name || ""}`.trim();
  }

  return "Unset";
};

export const accessFromJSON = (json: IAccessData): AccessRecord => {
  const record: IAccessRecord = { ...(json as any) };

  if (json.org) {
    record.org = orgFromJSON(json.org);
  }

  if (json.acl) {
    record.acl = fromJS(json.acl) as any;
  }

  return new AccessRecord(record);
};

export const generatePassword = (): string => {
  const length = 8;
  const charset = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const charsetLength = charset.length;
  let password = "";

  for (let i = 0; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * charsetLength));
  }

  return password;
};

export const placeholderEmail = (orgName, index): string => {
  const prefix = "placeholder+";
  const cleanOrgName = orgName.replace(/\W/g, "").toLowerCase();
  const random = Math.floor(Math.random() * 100);
  const postfix = index + "@oneclickcontractor.com";

  return `${prefix}${cleanOrgName}${random}${postfix}`;
};

export interface IAccessData {
  id: number;
  template: string;
  user_id: number;
  org_id: number;
  org: IOrgData;
  uid: string;
  acl?: { [key: string]: string[] };
  billable: string;
  config?: {
    commission_calculator?: {
      overhead: number;
      commission_rate: number;
    };
  };
}

export interface IAccessRecord {
  id: number;
  template: string;
  user_id: number;
  org_id: number;
  org: Nullable<OrgRecord>;
  uid: string;
  acl: Map<string, List<string>>;
  billable: string;
  config?: Map<"commission_calculator", Map<"overhead" | "commission_rate", number>>;
}

const defaultAccessProps: IAccessRecord = {
  id: 0,
  template: "",
  user_id: 0,
  org_id: 0,
  org: null,
  uid: "",
  acl: Map<string, List<string>>(),
  billable: "",
  config: Map(),
};

export class AccessRecord extends Record(defaultAccessProps) implements IAccessRecord {}

export type AclKeys =
  | "org"
  | "price_list"
  | "product"
  | "product_option_group"
  | "product_option"
  | "payment_term"
  | "token"
  | "estimate_template"
  | "pattern"
  | "report"
  | "user"
  | "crm_user"
  | "presentation"
  | "event"
  | "events_result"
  | "events_result_reason"
  | "job"
  | "estimate"
  | "screen_share"
  | "visualization"
  | "storm"
  | "area"
  | "lead"
  | "weather_property"
  | "billing_account"
  | "billing_customer"
  | "billing_invoice"
  | "billing_order"
  | "billing_subscription"
  | "billing_invoice_item"
  | "billing_fee_subscription"
  | "billing_plan"
  | "billing_fee"
  | "global"
  | "admin_panel"
  | "header_panel"
  | "roof_scope"
  | "discount";

export type AclValues =
  | "read"
  | "create"
  | "update"
  | "delete"
  | "read_assigned"
  | "create_assigned"
  | "update_assigned"
  | "delete_assigned"
  | "read_self"
  | "create_self"
  | "update_self"
  | "delete_self"
  | "acl_template"
  | "acl_custom"
  | "marketsharp"
  | "status"
  | "events"
  | "email"
  | "price_list"
  | "tools"
  | "billing"
  | "org"
  | "apl_csv"
  | "job"
  | "user"
  | "link"
  | "info"
  | "preferences"
  | "settings"
  | "jobs"
  | "child_orgs"
  | "users"
  | "billing"
  | "documents"
  | "presentations"
  | "price_list_editor"
  | "measurement_links"
  | "payment_terms"
  | "pattern_editor"
  | "integrations"
  | "email_history"
  | "statistics"
  | "leads"
  | "weather_properties"
  | "reports"
  | "calendar"
  | "signed_docs"
  | "update_pricing"
  | "update_markup"
  | "custom"
  | "order"
  | "update_billing"
  | "duplicate_org";

export interface IUserData {
  id: number;
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  accepted_tos: boolean;
  created_at: string;
  org_id: number;
  preferences_id: number;
  permissions: { [k in AclKeys]?: AclValues[] };
  url: string;
  accesses: any[];
  accesses_count: number;
  current_access_uid?: string;
  org?: IOrgData;
  loading: boolean;
  time_zone: string;
}

export interface IUserRecord {
  id: number;
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  accepted_tos: boolean;
  created_at: Date;
  org_id: number;
  preferences_id: number;
  url: string;
  accesses: List<number>;
  accesses_count: number;
  permissions: Map<AclKeys, AclValues[]>;
  loading: boolean;
  org?: OrgRecord;
  password: string;
  password_confirmation: string;
  current_access_uid: string;
  time_zone: string;
}

const defaultUserProps: IUserRecord = {
  id: 0,
  uuid: "",
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  accepted_tos: false,
  created_at: new Date(),
  org_id: 0,
  preferences_id: 0,
  url: "",
  accesses: List([]),
  accesses_count: 0,
  permissions: Map<AclKeys, AclValues[]>(),
  loading: false,
  org: null,
  current_access_uid: "",
  time_zone: "",
  password: "",
  password_confirmation: "",
};

export type Permissions = Map<AclKeys, AclValues[]>;

export class UserRecord extends Record(defaultUserProps) implements IUserRecord {}

export const emptyUserValue = fromJSON({} as IUserData);

export const emptyAccessValue = accessFromJSON({} as IAccessData);
