import { handleErrors } from "app2/src/reducers/Utils";
import { RootDispatchType } from "../store";
import { ThunkResult } from "./index";
import { getAuthUrl, IIntegrationAuthKind } from "app2/src/api/integrationAuth.service";

export const AsyncActions = {
  getAndOpenAuthUrl: (kind: IIntegrationAuthKind, orgId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        const response = await getAuthUrl(kind, orgId);
        window.open(response.url, "_blank");
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
};
