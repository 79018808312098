import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import track from "react-tracking";
import { List } from "immutable";
import { Row, Col, Carousel } from "react-bootstrap";
import Image from "app2/src/components/JobTabs/ImageTab/Images/ImageCarousel/Image";
import { ids, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import ImageThumb from "app2/src/components/JobTabs/ImageTab/Images/ImageCarousel/ImageThumb";
import { StandardPagination } from "app2/src/components/Pagination/Standard";

const mapStateToProps = (state: RootState) => {
  const modelName = "image";
  const imageQueryParams = queryParams(state, { modelName });
  const imageIds = ids(state, { modelName }) || List();
  const page = imageQueryParams.get("page");
  return {
    imageIds,
    pagination: pagination(state, { modelName, page }),
  };
};

const mapDispatchToProps = () => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImageCarouselProps {
  queryImages: (page: number) => void;
}
interface ImageCarouselState {
  index: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImageCarouselProps;

@track(() => {
  return {
    component: "ImageCarousel",
  };
})
class ImageCarousel extends React.Component<Props, ImageCarouselState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      index: 0,
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  @track((props) => ({
    action: "show",
    image: props.imageIds.first(),
    index: 0,
  }))
  public componentDidMount() {}

  @track((props, _state, [index]) => ({
    action: "select photo",
    image: props.imageIds.get(index),
    index: index,
  }))
  public handleSelect(index: number) {
    this.setState({ index });
  }

  public render(): React.ReactNode {
    const { imageIds, queryImages, pagination } = this.props;
    const { index } = this.state;
    return (
      <>
        <Carousel activeIndex={index} onSelect={this.handleSelect} interval={null}>
          {imageIds.map((id: number) => {
            return <Image key={id.toString()} imageId={id} />;
          })}
        </Carousel>
        <Row>
          <Col>
            <div className="image-slider">
              <ul>
                {imageIds.map((id: number, idx: number) => (
                  <ImageThumb key={idx.toString()} imageId={id} index={idx} handleSelect={this.handleSelect} />
                ))}
              </ul>
            </div>
            <StandardPagination metadata={pagination} pageChanged={queryImages} />
            <br />
          </Col>
        </Row>
      </>
    );
  }
}

export default connector(ImageCarousel);
