import * as moment from "moment";
import { RsfRootScope } from "app/src/Common/RsfRootScope";
import { IJobFetcherService } from "app/src/Jobs/JobFetcherService";
import { BaseTabCtrl } from "app/src/Jobs/tabs/BaseTabCtrl";
import * as angulartics from "angulartics";
import { IUser, IUserResource } from "app/src/Models/User";
import { IEstimate } from "app/src/Models/Estimate";
import { IPresentation } from "app/src/Models/Presentation";
import { ISession } from "app/src/Common/SessionService";
import { IEvent } from "app/src/Models/Event";
import { IOrg } from "app/src/Models/Org";
import { IOrgFetcherService } from "app/src/Orgs/OrgFetcherService";
import { IPretty } from "../../Common/PrettyNameService";
import { IEmailResource, IEmailResponse } from "../../Models/Email";
import { IBaseConfig } from "../../Common/IBaseConfig";

export class HistoryTabCtrl extends BaseTabCtrl {
  public emailResponse: IEmailResponse;
  public totalCount = 0;
  public analytics: { data: any[] };
  public reports: any;
  public org: IOrg;
  public params: any = {
    perPage: 25,
    currentPage: 1,
    totalCount: 0,
  };
  public metadata: any = {
    "page-number": 1,
  };
  protected users: { number: IUser } = {} as { number: IUser };
  protected durations: { number: any } = {} as { number: any };
  private _inactiveUser = {
    name: () => "Inactive User",
  };

  public static $inject = [
    "JobFetcher",
    "User",
    "OrgFetcher",
    "Session",
    "$stateParams",
    "$scope",
    "$rootScope",
    "$http",
    "$analytics",
    "BaseConfig",
    "$window",
    "Pretty",
    "Email",
  ];
  constructor(
    public JobFetcher: IJobFetcherService,
    public User: IUserResource,
    public OrgFetcher: IOrgFetcherService,
    public Session: ISession,
    public $stateParams: ng.ui.IStateParamsService,
    public $scope: ng.IScope,
    public $rootScope: RsfRootScope,
    private $http: ng.IHttpService,
    protected $analytics: angulartics.IAnalyticsService,
    public BaseConfig: IBaseConfig,
    public $window: ng.IWindowService,
    public Pretty: IPretty,
    public Email: IEmailResource,
  ) {
    super(JobFetcher, $scope, $analytics, $window, $stateParams["id"]);

    this.job.$promise.then(() => {
      this.OrgFetcher.loadOrg(this.job.org_id).then((org: IOrg) => {
        this.org = org;
        this.query();
        this.queryEmails();

        this.Session.can("events", "Job").then((permission) => {
          if (!permission) {
            return;
          }
          this.$http
            .post(this.BaseConfig.RSF_ANALYTICS_URL + "/api/v1/reports/job", {
              code: BaseConfig.RSF_ANALYTICS_KEY,
              job_id: this.job.id,
              user_id: this.job.assignedUser().id,
              org_id: this.job.org_id,
              appointments: _.map(this.job.appointments, (a: IEvent) => {
                return { id: a.id, date: moment(a.start_time).format() };
              }),
            })
            .then((result: any) => {
              this.reports = result.data.data;
            });
        });
      });
    });
  }

  public toggleEventDisplay(email) {
    if (email.events.length > 0) {
      email._display = !email._display;
    }
  }

  public fetchUser(userId: number) {
    if (!this.users[userId]) {
      this.users[userId] = this.User.get({ id: userId, catchAuth: false });

      this.users[userId].$promise.then(
        (user) => user,
        () => {
          const assignment = this.job.assignments.find((assignment) => assignment.user.id === userId);
          if (assignment) {
            const assignmentUser = new this.User({ ...assignment.user });

            if (!assignmentUser.last_name.endsWith("INACTIVE")) {
              assignmentUser.last_name += " INACTIVE";
            }

            this.users[userId] = assignmentUser;
          } else {
            this.users[userId] = this._inactiveUser;
          }
        },
      );
    }

    return this.users[userId];
  }

  public formatData(event: any) {
    switch (event.data.category) {
      case "Tools":
      case "Estimate":
      case "LineItemEditor":
      case "Proposals":
        return this["handle" + event.data.category](event);
      case "Presentation":
      case "Presentations":
        return this.handlePresentation(event);
      case "Signatures":
        return this.handleSignatures(event);
      default:
        return "";
    }
  }

  public duration(index) {
    if (index === this.analytics.data.length - 1) {
      return "";
    }

    if (!this.durations[index]) {
      const previous = moment(this.analytics.data[index].inserted_at + "+0000", "YYYY-MM-DDTHH:mm:ssZ");
      const current = moment(this.analytics.data[index + 1].inserted_at + "+0000", "YYYY-MM-DDTHH:mm:ssZ");

      this.durations[index] = moment.duration(current.diff(previous));
    }

    const duration = this.durations[index];

    return (
      this.padWithZeros(Math.round(Math.abs(duration.asHours())), 2) +
      ":" +
      this.padWithZeros(Math.abs(duration.minutes()), 2) +
      ":" +
      this.padWithZeros(Math.abs(duration.seconds()), 2)
    );
  }

  public humanDuration(index) {
    if (index === this.analytics.data.length - 1) {
      return "";
    }

    if (!this.durations[index]) {
      const previous = moment(this.analytics.data[index].inserted_at + "+0000");
      const current = moment(this.analytics.data[index + 1].inserted_at + "+0000");

      this.durations[index] = moment.duration(current.diff(previous));
    }

    return this.durations[index].humanize();
  }

  public apptSummary(id) {
    const appt = _.find(this.job.appointments, (appt) => {
      return appt.id === id;
    });

    if (appt) {
      return appt.summary;
    }

    return "No Appointment";
  }

  public reportName(testName) {
    switch (testName) {
      case "start_time":
        return "On Time";
      case "screen_share_used":
        return "Used Screen Share";
      case "visualization_used":
        return "Used Visualization";
      case "estimate_created":
        return this.org.estimateTitle() + " Created";
      case "estimate_emailed":
        return this.org.estimateTitle() + " Emailed";
      case "contract_signature_sent":
        return this.org.contractTitle() + " Emailed";
      case "contract_signature_signed":
        return this.org.contractTitle() + " Signed";
      case "presentation_duration":
        return "Presentation Duration";
    }
  }

  public sanitizeMessage(message) {
    let regex = new RegExp("(estimate)", "gi");
    message = message.replace(regex, (match) => {
      return this.matchCase(this.org.estimateTitle(), match);
    });

    regex = new RegExp("(contract)", "gi");
    return message.replace(regex, (match) => {
      return this.matchCase(this.org.contractTitle(), match);
    });
  }

  public queryEmails(): ng.IPromise<any> {
    const queryParams = {
      page: this.params.currentPage,
      per_page: this.params.perPage,
      emailable_type_url: "jobs",
      emailable_id: this.job.id,
    };
    const query = this.Email.query(queryParams);
    query.$promise.then((data: any) => {
      this.emailResponse = data;
      this.params.totalCount = this.emailResponse.meta.pagination.total_count;
    });

    return query.$promise;
  }

  public query(): ng.IPromise<any> {
    return this.Session.can("job", "Global").then((global_permission) => {
      return this.Session.can("events", "Job").then((permission) => {
        if (!permission && !global_permission) {
          return;
        }
        const params = {
          code: this.BaseConfig.RSF_ANALYTICS_KEY,
          job_id: this.job.id,
          page: this.metadata["page-number"],
        };
        if (!global_permission) {
          params["org_id"] = this.Session.currentUser.org_id;
        }
        return this.$http
          .get(this.BaseConfig.RSF_ANALYTICS_URL + "/api/v1/events", { params: params })
          .then((data: any) => {
            this.analytics = data.data as { data: any[] };
            this.metadata = data.headers();
          });
      });
    });
  }

  protected matchCase(newStr, oldStr) {
    if (!newStr) {
      return newStr;
    }
    const p = oldStr.charCodeAt(0);
    if (p >= 65 && p < 91) {
      return newStr.charAt(0).toUpperCase() + newStr.slice(1);
    } else {
      return newStr.toLowerCase();
    }
  }

  protected handleTools(event) {
    return "";
  }

  protected handleEstimate(event) {
    if (event.data.estimate === "new" || event.data.estimate === "Unsaved" || !event.data.estimate) {
      return "Estimate: New";
    }

    const id = parseInt(event.data.estimate);
    return "Estimate: " + _.find(this.job.estimates, (e: IEstimate) => e.id === id).name;
  }

  protected handleSignatures(event) {
    switch (event.data.action) {
      case "viewed":
      case "recipient_signed":
        return "by " + event.data.recipient_name;
      case "signed":
        return "fully signed";
      default:
        return event.data.action;
    }
  }

  protected handleLineItemEditor(event) {
    const estimate = this.handleEstimate(event);
    if (event.data.measurement_name) {
      const measurement = event.data.measurement_name.split(".").join(" - ").split("_").join(" ");

      return estimate + "\nMeasurement: " + measurement;
    } else {
      return estimate;
    }
  }

  protected handlePresentation(event) {
    let presentation = "";

    if (event.data.action === "Slide Changed") {
      if (!event.data.presentation) {
        presentation = "Presentation: Unknown";
      } else {
        const id = parseInt(event.data.presentation);
        presentation = "Presentation: " + _.find(this.job.org.presentations, (p: IPresentation) => p.id === id).name;
      }
      presentation = presentation + "\nPage: " + event.data.page;
    }

    return presentation;
  }

  protected handleProposals(event) {
    return this.handleEstimate(event);
  }

  private padWithZeros(input, length) {
    // Cast input to string
    input = "" + input;

    const paddingSize = Math.max(0, length - input.length);
    return new Array(paddingSize > 0 ? paddingSize + 1 : 0).join("0") + input;
  }
}
