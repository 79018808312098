import { Visualizer, IMeasurementMetadata } from "rsf-visualizer";
import { Annotator, IAnnotation as IDrawingAnnotation } from "rsf-visualizer";
import { WindowScaler, WindowScalerTool } from "rsf-visualizer";
import * as Konva from "konva";
import { TextCallout } from "rsf-visualizer";
import { Annotation, IAnnotation } from "../Models/Annotation";
import { IElevation } from "../Models/Elevation";

export interface UpdatedParams {
  annotations: IAnnotation[];
}

export interface IAnnotationMeasuredEvent {
  annotation: IAnnotation;
  width: number;
  height: number;
}

let currentId = 0;

class DrawingComponentCtrl implements ng.IComponentController {
  public zoomLevel = 50;
  public editable = true;
  public measuring = true;
  public imageUrl: string;
  public elevation: IElevation;
  public selectedAnnotations: IAnnotation[];
  public prefix: string;
  public updated: (params: UpdatedParams) => void;
  public id: number;
  public canvasId: string;
  public lockZoom = false;
  public showingGrid = true;
  public currentTool = 0;

  public annotationAdded: () => string;
  protected visualizer: Visualizer;
  protected annotator: Annotator;
  protected measurer: WindowScaler;

  private _element: any;
  private _annotationsGuids: string[];
  private _abbr: string;

  public static $inject = ["$timeout", "$scope", "$window"];
  constructor(
    protected $timeout: ng.ITimeoutService,
    protected $scope: ng.IScope,
    protected $window: ng.IWindowService,
  ) {
    this.id = currentId++;
    this.canvasId = `rsf-drawing-canvas${this.id}`;

    this.$scope.$on("rsf:drawing:render", (event, args: any) => {
      if (this.elevation && this.elevation.id === args.id) {
        this._render();
      }
    });

    this.$scope.$on("rsf:measurements:save", (e: ng.IAngularEvent, args: { elevation: IElevation }) => {
      if (this.elevation && this.elevation.id === args.elevation.id) {
        args.elevation.drawingMetadata = this.measurer.save() as any;
      }
    });
  }

  public $onInit() {
    this._abbr = this.elevation.abbr;

    if (this.editable === null || this.editable === undefined) {
      this.editable = true;
    }

    if (this.measuring === null || this.measuring === undefined) {
      this.measuring = false;
    }

    this.$timeout(() => {
      this.visualizer = new Visualizer(this.canvasId, { width: 800, height: 800 });

      // Not 100% why this is required
      this.elevation.annotations = this.elevation.annotations.slice(0);

      if (!this.measuring) {
        this._setupAnnotator();
      }

      if (this.measuring) {
        this._setupMeasurer();
      }

      this._element = (angular.element(`#${this.canvasId}`) as any).closest("rsf-drawing");
      this._resize();

      angular.element(this.$window).bind("resize", () => {
        this.visualizer.setSize(this._element.width(), this._element.height());
      });

      /**
       * Highlight potential initial selection
       */
      this.$timeout(() => {
        this.$onChanges();
      });
    });
  }

  public isCurrentTool(tool: number) {
    if (this.currentTool === 0) {
      return false;
    }
    return this.currentTool === tool;
  }

  public $doCheck() {
    this._checkGuids();
    this._checkAbbr();
  }

  public $onChanges() {
    if (this.annotator && this.selectedAnnotations) {
      this.annotator.highlightAnnotations(this.selectedAnnotations as any as IDrawingAnnotation[], "#a9de94");
    }
  }

  public zoomIn() {
    this.zoomLevel += 0.25;
    this._zoom();
  }

  public zoomOut() {
    this.zoomLevel -= 0.25;
    this._zoom();
  }

  public zoomLock() {
    this.lockZoom = !this.lockZoom;
    if (this.lockZoom) {
      this.zoomLevel = this.measurer.zoom;
    } else {
      this.zoomLevel = this.measurer.imageZoom;
    }
  }

  public zoomChanged() {
    this._zoom();
  }

  public measureLines() {
    this._setTool(WindowScalerTool.line);
  }

  public measureWindows() {
    this._setTool(WindowScalerTool.window);
  }

  public reset() {
    (this.measurer as any).reset();
    this.lockZoom = false;
    this.zoomLevel = this.measurer.imageZoom;
  }

  public toggleGrid() {
    this._setTool(WindowScalerTool.scaling);
  }

  public measureDaylight() {
    this._setTool(WindowScalerTool.daylight);
  }

  public measurePolygon() {
    this._setTool(WindowScalerTool.polygon);
  }

  private _render() {
    this.annotator.highlightAnnotations([], "#a9de94");
    this.visualizer.setSize(1000, 1000);
    this.visualizer.renderAll();
    this.annotator.layer.batchDraw();

    const image = this.visualizer.stage.toDataURL({ mimeType: "png", quality: 1 });

    this.visualizer.setSize(this._element.width(), this._element.height());
    this.visualizer.renderAll();
    this.annotator.layer.batchDraw();
    this.annotator.highlightAnnotations(this.selectedAnnotations as any as IDrawingAnnotation[], "#a9de94");

    this.$scope.$emit("rsf:drawing:rendered", { id: this.elevation.id, dataUrl: image });
  }

  private _fireUpdate() {
    if (this.updated) {
      this.updated({ annotations: this.elevation.annotations });
    }
  }

  private _saveGuids() {
    this._annotationsGuids = _.chain(this.elevation.annotations).pluck("guid").sort().value();
  }

  private _checkGuids() {
    if (!this._annotationsGuids) {
      return;
    }

    const currentGuids = _.chain(this.elevation.annotations).pluck("guid").sort().value();

    const toRemove = _.difference(this._annotationsGuids, currentGuids);

    if (toRemove.length <= 0) {
      return;
    }

    _.each(toRemove, (guid: string) => {
      const ann = _.find(this.annotator.annotations, (annotation: TextCallout) => {
        return guid === annotation.guid;
      });

      if (ann) {
        this.annotator.removeAnnotation(ann);
      }
    });

    _.each(this.elevation.annotations, (ann: IAnnotation, idx: number) => {
      ann.id = idx + 1;
    });

    this._updateText();

    this.visualizer.renderAll();

    this._saveGuids();
  }

  private _checkAbbr() {
    if (this._abbr === this.elevation.abbr) {
      return;
    }
    this._abbr = this.elevation.abbr;

    this._updateText();

    this.visualizer.renderAll();
  }

  private _updateText() {
    this.elevation.annotations.forEach((annotation: IAnnotation) => {
      this.annotator.updateAnnotation(annotation.guid, annotation.text);
    });
  }

  private _setupAnnotator() {
    this.annotator = new Annotator(
      (a: TextCallout) => {
        const annotation = new Annotation(this.elevation.annotations.length + 1, this.elevation);
        const position = this.visualizer.getScaledLocation(a);
        annotation.x = position.x;
        annotation.y = position.y;
        annotation.guid = a.guid;

        this.elevation.annotations.push(annotation);

        a.setText(annotation.text);
        this._checkGuids();
        this._saveGuids();
        this._fireUpdate();
        return a;
      },
      (a: TextCallout) => {
        const ann = _.find(this.elevation.annotations, (annotation: IAnnotation) => {
          return a.guid === annotation.guid;
        });

        if (!ann) {
          return;
        }

        const position = this.visualizer.getScaledLocation(a);
        ann.x = position.x;
        ann.y = position.y;

        this._fireUpdate();
      },
    );

    this.visualizer.setTool(this.annotator);

    this.visualizer.addBackgroundImage(this.elevation.src_image.file.url + "?cb=1", () => {
      this.annotator.loadAnnotations(this.elevation.annotations as any as IDrawingAnnotation[]);

      if (!this.editable) {
        this.annotator.disable();
      }

      this.annotator.layer.batchDraw();
      this._saveGuids();
      this._resize();
    });
  }

  private _setupMeasurer() {
    this.measurer = new WindowScaler();
    this.visualizer.setTool(this.measurer);
    this.measurer.addImage(
      this.elevation.src_image.file.url + "?cb=1",
      (img: Konva.Image) => {
        this.zoomLevel = this.measurer.imageZoom;
        this.$scope.$digest();

        this.measurer.loadAnnotations(this.elevation.annotations as any as IDrawingAnnotation[]);
        this._resize();

        if (this.measurer.all.length > 0) {
          this.zoomLock();
        }
      },
      this.elevation.drawingMetadata as any,
    );

    this.measurer.onWindowIntersection = (guid: string, width: number, height: number) => {
      const window = _.find(this.elevation.annotations, (a: IAnnotation) => a.guid === guid);
      if (window) {
        this.$scope.$emit("rsf:drawing:setmeasurements", { annotation: window, width: width, height: height });
      }
    };

    this.measurer.onMeasurementChange = (shape: IMeasurementMetadata) => {
      this.$scope.$emit("rsf:drawing:updateshape", { shape: shape });
    };
  }

  private _zoom() {
    if (this.lockZoom) {
      this.measurer.setZoom(this.zoomLevel);
    } else {
      this.measurer.setImageZoom(this.zoomLevel);
    }
    this.visualizer.renderAll();
  }

  private _setTool(tool: WindowScalerTool) {
    if (this.currentTool === tool) {
      this.currentTool = 0;
    } else {
      this.currentTool = tool;
    }
    this.measurer.setTool(tool);
    this.showingGrid = !!this.measurer.grid;
  }

  private _resize() {
    this.visualizer.setSize(this._element.width(), this._element.height());
  }
}

export class DrawingComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl = "src/Drawing/drawing_component.html";
  public bindings: any = {
    imageUrl: "<",
    elevation: "<",
    selectedAnnotations: "<",
    updated: "&",
    editable: "<",
    measuring: "<",
  };

  constructor() {
    this.controller = DrawingComponentCtrl;
  }
}
