import { RootState } from "app2/src/reducers";
import { decks } from "app2/src/selectors/deck.selectors";
import { currentMeasurement } from "app2/src/selectors/measurementCommon.selectors";
import { StoreRegistry } from "app2/src/storeRegistry";
import { List } from "immutable";
import * as React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { measurementEditing } from "app2/src/selectors/measurement.selectors";
import { getUoM } from "app2/src/records/Measurement";

export const Decks: React.FC = () => {
  // Hooks
  const { trackEvent } = useTracking<any>({ component: "Decks" });

  // Selectors
  const measurement = useSelector((state: RootState) => currentMeasurement(state, {}));
  const deckIds = measurement?.get("deck_ids") || List();
  const deckRecords = useSelector((state: RootState) => decks(state, { deckIds }));
  const editing = useSelector(measurementEditing);
  const uom = getUoM(measurement);

  const openDeckWizard = () => {
    trackEvent({ action: "open deck tool" });
    StoreRegistry.get<any>("$rootScope").$emit("deck_tool:open", {});
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Decks</h2>
        </Col>
        <Col>
          <Button
            variant="primary"
            onClick={openDeckWizard}
            className="float-right"
            disabled={editing}
            title={editing ? "Disabled for editing" : ""}>
            Deck Tool
          </Button>
        </Col>
      </Row>
      {deckRecords.size > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Area</th>
              <th>Perimeter</th>
              <th>Railing</th>
              <th>Wall</th>
              <th>Railing Area</th>
            </tr>
          </thead>
          <tbody>
            {deckRecords?.map((deck, idx) => {
              return (
                <tr key={idx}>
                  <td>{deck.name}</td>
                  <td>
                    {deck.area} {uom["deck_area"]}
                  </td>
                  <td>
                    {deck.perimeter} {uom["deck_perimeter"]}
                  </td>
                  <td>
                    {deck.railing_length} {uom["deck_railing_length"]}
                  </td>
                  <td>
                    {deck.wall_length} {uom["deck_wall_length"]}
                  </td>
                  <td>
                    {deck.railing_area} {uom["deck_railing_area"]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">
          <h2>No decks added. Let's add one.</h2>
          <Button
            variant="primary"
            onClick={openDeckWizard}
            disabled={editing}
            title={editing ? "Disabled for editing" : ""}>
            Deck Tool
          </Button>
        </div>
      )}
    </>
  );
};
