import { ITaskContext, ITaskData } from "app2/src/records/Task";
import { dispatch, useState } from "../storeRegistry";
import { Actions } from "app2/src/reducers/task.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import { task } from "app2/src/selectors/task.selectors";
import * as commonActions from "app2/src/reducers/components/common.actions";

export const syncTask = async (taskObj: ITaskData): Promise<void> => {
  const state = useState();
  const taskRecord = await task(state, { taskId: taskObj.id });

  if (taskRecord && taskRecord.context.model) {
    switch (taskObj.status) {
      case "finished":
        await handleTaskSuccess(taskObj, taskRecord.context);
        break;
      case "error":
        await handleTaskError(taskObj, taskRecord.context);
        break;
      default:
        break;
    }
    dispatch(Actions.receiveTask(taskObj));
  }
};

export const handleTaskSuccess = async (newTask: ITaskData, context: ITaskContext) => {
  if (context.model && context.action) {
    await dispatchContextAction(newTask, context.model, context.action, context.action_payload);
  } else {
    const name = newTask.results.class_name || "Task Completion";
    dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, `${name} Successful.`));
  }
};

export const handleTaskError = async (newTask: ITaskData, context: ITaskContext) => {
  if (context.model && context.error_action) {
    await dispatchContextAction(newTask, context.model, context.error_action, context.action_payload);
  } else {
    const errorMessage =
      newTask?.results?.error_message ||
      "There were problems processing your task. Please try again. If the problem persists contact support.";

    dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errorMessage));
  }
};

const dispatchContextAction = async (newTask: ITaskData, model: string, actionHandler: string, action_payload: any) => {
  try {
    const module = await import(`app2/src/reducers/${model}.actions.ts`);
    const action = module.AsyncActions[actionHandler];
    dispatch(action(newTask, action_payload));
  } catch (error) {
    console.error("Error in dispatching function dynamically:", error.message);
  }
};
