import { SnakeToCamelKeys } from "app2/src/helpers/Format";
import { Record } from "immutable";

export const fromJSON = (data: Partial<ILineItemOrderData>): LineItemOrderRecord => {
  const record: ILineItemOrderRecord = SnakeToCamelKeys(data);

  return new LineItemOrderRecord(record);
};

export interface ILineItemOrderData {
  type: string;
  order: string;
}

export interface ILineItemOrderRecord {
  type: string;
  order: string;
}

const defaultLineItemOrderProps: ILineItemOrderRecord = {
  type: "",
  order: "",
};

export class LineItemOrderRecord extends Record(defaultLineItemOrderProps) implements ILineItemOrderRecord {}
