import * as React from "react";
import { connect } from "app2/src/connect";
import { List } from "immutable";
import { Row, Col, Form, FormControlProps, Button } from "react-bootstrap";
import { AccountRecord } from "app2/src/records/billing/Account";
import { PlanSubscriptionRecord } from "app2/src/records/billing/PlanSubscription";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "app2/src/reducers";
import { PlanRecord } from "app2/src/records/billing/Plan";
import * as accountActions from "app2/src/reducers/billing/account.actions";
import { getPlanHierarchy, getPlanRoots, getPlanFamilyRoots } from "app2/src/selectors/plan.selectors";
import { ConnectedProps } from "react-redux";
import { SubscriptionStripeDetailView } from "./SubscriptionStripeDetailsView";
import { ReplaceProps, BsPrefixProps } from "react-bootstrap/helpers";
import { Nullable } from "app2/src/records";
import { AccountPlanSubscriptionParams } from "app2/src/api/billing/account.service";

export interface IPlanSubscriptionViewerProps {
  account: AccountRecord;
  planSubscription: PlanSubscriptionRecord;
}

export interface IPlanSubscriptionViewerState {
  changedPlan: Nullable<PlanRecord>;
  changedHierarchy: List<PlanRecord>;
}

function mapStateToProps(state: RootState, ownProps: IPlanSubscriptionViewerProps) {
  const plan = ownProps.planSubscription.plan;
  let plans = List<PlanRecord>();
  if (plan) {
    plans = getPlanHierarchy(state, { id: plan.id });
  }

  const roots = getPlanFamilyRoots(state, { family: ownProps.planSubscription.family });

  return {
    planHierarchy: plans,
    roots: roots,
    hierarchies: roots.map((r) => getPlanHierarchy(state, { id: r.id })),
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  _ownProps: IPlanSubscriptionViewerProps,
) {
  return {
    subscribeToPlan: (accountId: number, planId: number, params: Partial<AccountPlanSubscriptionParams> = {}) =>
      dispatch(accountActions.AsyncActions.subscribeToPlan(accountId, planId, params)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPlanSubscriptionViewerProps;

export class PlanSubscriptionViewer extends React.Component<Props, IPlanSubscriptionViewerState> {
  public constructor(props) {
    super(props);

    this.state = {
      changedPlan: null,
      changedHierarchy: List(),
    };

    this.handleSave = this.handleSave.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.canUnsubscribe = this.canUnsubscribe.bind(this);
  }

  public handlePlanChange(event: React.ChangeEvent) {
    const { roots, hierarchies } = this.props;
    const id = parseInt((event.target as any).value);

    if (!Number.isNaN(id)) {
      const idx = roots.findIndex((p) => p.id === id);

      this.setState({
        changedPlan: roots.get(idx),
        changedHierarchy: hierarchies.get(idx),
      });
    } else {
      this.setState({
        changedPlan: null,
        changedHierarchy: List(),
      });
    }
  }

  public handleSave() {
    const { subscribeToPlan, account } = this.props;
    const { changedPlan } = this.state;

    subscribeToPlan(account.id, changedPlan.id);
  }

  public unsubscribe() {
    const { subscribeToPlan, planSubscription, account } = this.props;

    subscribeToPlan(account.id, planSubscription.plan.id, { unsubscribe: true });
  }

  public canUnsubscribe() {
    const { planSubscription } = this.props;

    return planSubscription.plan && !planSubscription.subscription;
  }

  public render() {
    const { planSubscription, planHierarchy, roots } = this.props;
    const { changedPlan, changedHierarchy } = this.state;

    const current = planSubscription.plan ? planSubscription.plan.name : "Unset";

    return (
      <Row>
        <Col md={12}>
          <div className="form-section">
            <div className="form-section-heading">
              <h3>Plan Manager for {planSubscription.family}</h3>
            </div>
            <div className="form-section-content">
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Current Plan</Form.Label>
                    <Form.Control value={current} readOnly></Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Plan Structure</Form.Label>
                    <ol>
                      {planHierarchy.map((p, idx) => {
                        return (
                          <li key={idx} className={p.id === planSubscription.plan.id ? "bg-success" : ""}>
                            {p.name}
                          </li>
                        );
                      })}
                    </ol>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <SubscriptionStripeDetailView subscription={planSubscription.subscription} />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  <h4>Update Plan</h4>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Update Plan</Form.Label>
                    <Form.Control
                      as={"select"}
                      value={changedPlan ? changedPlan.id.toString() : ""}
                      onChange={this.handlePlanChange}>
                      <option value="">No Change</option>
                      {roots.map((p, idx) => (
                        <option key={idx} value={p.id}>
                          {p.getName()}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Updated Plan Structure</Form.Label>
                    <ol>
                      {changedHierarchy.map((p, idx) => {
                        return <li key={idx}>{p.name}</li>;
                      })}
                    </ol>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 4, offset: 4 }} md={{ span: 2, offset: 8 }}>
                  {this.canUnsubscribe() && (
                    <Button className="pull-right" onClick={this.unsubscribe}>
                      Unsubscribe to Plan
                    </Button>
                  )}
                </Col>
                <Col sm={4} md={2}>
                  <Button className="pull-right" onClick={this.handleSave} disabled={changedPlan === null}>
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default connector(PlanSubscriptionViewer);
