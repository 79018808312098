import * as React from "react";
import { IEstimateLineItem, IsOptionSelected } from "app/src/Models/EstimateLineItem";
import { Button, Row, Col, OverlayTrigger, Tooltip, FormControl } from "react-bootstrap";
import * as FontAwesome from "react-fontawesome";
import { IEstimateLineItemOption } from "app/src/Models/EstimateLineItemOption";
import * as CurrencyFormatter from "react-currency-formatter";
import { ISession } from "app/src/Common/SessionService";
import { ProductOptionGroupRecord } from "app2/src/records/ProductOptionGroup";
import { calculatePrice, ProductOptionRecord } from "app2/src/records/ProductOption";
import { List } from "immutable";
import { IEstimate } from "app/src/Models/Estimate";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootActions, RootState } from "app2/src/reducers";
import { ThunkDispatch } from "redux-thunk";
import { presentModePreferencesConfig } from "app2/src/selectors/org.selectors";

const mapStateToProps = (state: RootState, ownProps: ProductOptionsTabProps) => {
  return {
    showPricingInLineItemEditor: presentModePreferencesConfig(state, {
      path: ["estimator", "show_pricing", "line_item_editor"],
    }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ProductOptionsTabProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface ProductOptionsTabProps {
  estimate: IEstimate;
  lineItem: IEstimateLineItem;
  optionGroup: ProductOptionGroupRecord;
  options: List<ProductOptionRecord>;
  selectOption: any;
  unselectOption: any;
  handleQtyChange: any;
  Session: ISession;
}

export interface ProductOptionsTabState {
  optionsById: any;
  showQuantity: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ProductOptionsTabProps;

class ProductOptionsTab extends React.Component {
  public props: Props;
  public state: ProductOptionsTabState;

  constructor(props) {
    super(props);

    this.state = {
      optionsById: {},
      showQuantity: false,
    };
    props.options.map((opt: ProductOptionRecord) => {
      const elio = this.checkOptionQuantity(opt);
      if (elio) {
        this.state.optionsById[opt.id] = elio.quantity;
      } else {
        this.state.optionsById[opt.id] = 1;
      }
    });

    this.props.Session.can("update_option_quantity", "estimate").then((value) => {
      if (value) {
        this.setState({
          showQuantity: true,
        });
      }
    });
  }

  public checkOptionQuantity(opt: ProductOptionRecord) {
    const { lineItem } = this.props;
    return _.find(lineItem.existingOptions(), (e: IEstimateLineItemOption) => {
      return e.product_option_id === opt.id;
    });
  }

  public select(optionGroup, option) {
    this.props.selectOption(optionGroup, option, this.state.optionsById[option.id]);
  }

  public unselect(optionGroup, option) {
    this.props.unselectOption(option);
  }

  public handleQtyChange(event, opt) {
    const { optionGroup } = this.props;
    const eventName = event.target.name;
    const eventValue = event.target.value;
    this.setState((state: ProductOptionsTabState) => {
      const editedOptionsById: any = state.optionsById;
      editedOptionsById[eventName] = _.parseFloat(eventValue);

      this.props.handleQtyChange(optionGroup, opt, editedOptionsById[eventName]);
      return {
        optionsById: editedOptionsById,
      };
    });
  }

  public optionRow(opt: ProductOptionRecord) {
    const { optionGroup, lineItem, estimate, showPricingInLineItemEditor } = this.props;
    const { showQuantity } = this.state;

    return (
      <Row key={opt.id.toString()} data-name={opt.id.toString()}>
        <Col xs={12}>
          <div className="form-section">
            <Row className="row-eq-height">
              <Col xs={6} md={4}>
                {opt.charge_type === "per" ? (
                  <OverlayTrigger
                    //@ts-ignore
                    placement="right"
                    overlay={<Tooltip id="Charge">Charge Per Line Item</Tooltip>}>
                    <FontAwesome name="info-circle" />
                  </OverlayTrigger>
                ) : null}
                &nbsp;{opt.name}
              </Col>
              <Col xs={6} md={2}>
                {opt.images[0] ? (
                  <img className="img-responsive img-product" src={opt.images[0].file.medium.url} />
                ) : null}
              </Col>
              <Col xs={6} md={2}>
                {showQuantity ? (
                  <FormControl
                    name={opt.id.toString()}
                    type="number"
                    min="-9999999"
                    max="9999999"
                    value={this.state.optionsById[opt.id]}
                    onChange={(e) => {
                      this.handleQtyChange(e, opt);
                    }}
                  />
                ) : null}
              </Col>
              <Col xs={6} md={2}>
                {showPricingInLineItemEditor ? (
                  opt.uom === "percent" ? (
                    <span>{opt.price}%</span>
                  ) : (
                    <CurrencyFormatter quantity={calculatePrice(opt, estimate, lineItem)} />
                  )
                ) : null}
              </Col>
              <Col xs={6} md={2}>
                {IsOptionSelected(lineItem, opt) ? (
                  <Button
                    variant="add"
                    onClick={() => {
                      this.unselect(optionGroup, opt);
                    }}>
                    Selected
                  </Button>
                ) : (
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      this.select(optionGroup, opt);
                    }}>
                    Select
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  public render() {
    const { optionGroup, options, showPricingInLineItemEditor } = this.props;
    const { showQuantity } = this.state;

    const optionRows = [];
    options.map((opt) => {
      optionRows.push(this.optionRow(opt));
    });

    return (
      <div className="options-select">
        <Row className="table-header">
          <Col xs={12}>
            <Row>
              <Col md={4} xs={6}>
                Description
              </Col>
              <Col md={2} xs={6}></Col>
              <Col md={2} xs={6}>
                {showQuantity ? <span>Quantity Per</span> : null}
              </Col>
              <Col md={2} xs={6}>
                {showPricingInLineItemEditor ? "Unit Price" : null}
              </Col>
              <Col md={2} xs={6}>
                {optionGroup.selection_mode === "single" ? "Select One" : "Select"}
              </Col>
            </Row>
          </Col>
        </Row>
        {optionRows}
      </div>
    );
  }
}

export default connector(ProductOptionsTab);
