import { RootState } from "app2/src/reducers";
import { createSelector } from "reselect";
import { emptyOrgValue, OrgRecord } from "../records/OrgRecord";
import { getIn, get, Map } from "immutable";
import { navDisplay } from "app2/src/selectors/components/common.selectors";
import { recordChanged } from "app2/src/helpers/Record";

export const byId = (state: RootState) => state.getIn(["orgs", "orgsById"]);
export const lastSavedOrg = (state: RootState, _props?: any) =>
  state.getIn(["orgs", "lastSavedOrgsById", currentOrgId(state)]);

/**
 * Returns the currentOrgId
 * Org ID of the current view, Job page = job.org_id, Admin page = org in current view, otherwise null
 *
 * @param {RootState} state The RootState
 * @param {{}} options
 * @returns {number} org id
 */
export const currentOrgId = (state: RootState): number => state.getIn(["orgs", "currentOrgId"]);

/**
 * Loads the current org using currentOrgId
 *
 * @param {RootState} state The RootState
 * @param {{}} options
 * @returns {OrgRecord} OrgRecord
 */
export const currentOrg = createSelector([currentOrgId, byId], (orgId, byId): OrgRecord => {
  return get(byId, orgId);
});

/**
 * Loads the settings config with a path (if provided)
 *
 * @param {RootState} state The RootState
 * @param {{orgId: number, path: string[], notSet?: any}} options
 * @returns {string | number} settings config
 */
export const settingsConfig = (state: RootState, props: any) => {
  if (props?.path) {
    return getIn(
      state,
      ["orgs", "orgsById", props.orgId, "settings", "config"].concat(props.path),
      props?.notSet || "",
    );
  }
  return state.getIn(["orgs", "orgsById", props.orgId, "settings", "config"]);
};

/**
 * Loads the preferences config with a path (if provided)
 *
 * @param {RootState} state The RootState
 * @param {{orgId: number, path: string[], notSet?: any}} options
 * @returns {string | number} preferences config
 */
export const preferencesConfig = (state: RootState, props: any) => {
  if (props?.path) {
    return getIn(
      state,
      ["orgs", "orgsById", props.orgId, "preferences", "config"].concat(props.path),
      props?.notSet || "",
    );
  }

  return state.getIn(["orgs", "orgsById", props.orgId, "preferences", "config"]);
};

/**
 * Loads the preferences config with a path (if provided) using currentOrgId
 *
 * @param {RootState} state The RootState
 * @param {{orgId: number, path: string[], notSet?: any}} options
 * @returns {string | number} preferences config
 */
export const currentOrgPrefConfig = createSelector(
  [currentOrg, (state, props) => ({ state, props })],
  (org, stateProps) => {
    const { props } = stateProps;
    return getIn(org, ["preferences", "config"].concat(props.path || []), undefined);
  },
);

/**
 * Loads the preferences acl with a path (if provided)
 *
 * @param {RootState} state The RootState
 * @param {{orgId: number, path: string[], notSet?: any}} options
 * @returns {string | number} preferences acl
 */
export const preferencesAcl = (state: RootState, props: any) => {
  if (props?.path) {
    return getIn(
      state,
      ["orgs", "orgsById", props.orgId, "preferences", "acl"].concat(props.path),
      props?.notSet || "",
    );
  }

  return state.getIn(["orgs", "orgsById", props.orgId, "preferences", "acl"]);
};

/**
 * Loads the preferences acl with a path (if provided) using currentOrgId
 *
 * @param {RootState} state The RootState
 * @param {{orgId: number, path: string[], notSet?: any}} options
 * @returns {string | number} preferences acl
 */
export const currentOrgPrefAcl = createSelector(
  [currentOrg, (state, props) => ({ state, props })],
  (org, stateProps) => {
    const { props } = stateProps;
    return getIn(org, ["preferences", "acl"].concat(props.path || []), undefined);
  },
);

/**
 * Loads the settings acl with a path (if provided) using currentOrgId
 *
 * @param {RootState} state The RootState
 * @param {{orgId: number, path: string[], notSet?: any}} options
 * @returns {string | number} preferences config
 */
export const currentOrgSettingsAcl = createSelector(
  [currentOrg, (state, props) => ({ state, props })],
  (org, stateProps) => {
    const { props } = stateProps;
    return getIn(org, ["settings", "acl"].concat(props.path || []), undefined);
  },
);

export const currentOrgSettingsConfig = createSelector(
  [currentOrg, (state, props) => ({ state, props })],
  (org, stateProps) => {
    const { props } = stateProps;
    return getIn(org, ["settings", "config"].concat(props.path || []), props.default || undefined);
  },
);

export const orgLoading = (state: RootState, _props?: any) =>
  state.getIn(["orgs", "orgsById", currentOrgId(state), "loading"]);

export const org = createSelector(
  [byId, (state, props) => ({ state, props })],
  (byId: Map<number, OrgRecord>, stateAndParams: any) => {
    const { props } = stateAndParams;
    if (!props.orgId) {
      return emptyOrgValue;
    }

    if (!byId.get(props.orgId)) {
      return emptyOrgValue;
    }

    return byId.get(props.orgId);
  },
);

/**
 * Did the org change? Did not use createSelector since ID is not passed in as a prop
 *
 * @param {RootState} state The RootState
 * @returns {boolean}
 */
export const orgChanged = (state: RootState, _props?: any) => recordChanged(currentOrg(state), lastSavedOrg(state));

export const getTitle = createSelector(
  [currentOrgId, (state, props) => ({ state, props })],
  (orgId: OrgRecord, stateProps: any) => {
    const { state, props } = stateProps;
    const { documentType } = props;

    const title = preferencesConfig(state, { orgId, path: ["titles", documentType] });

    let val = "";

    switch (documentType) {
      case "contract":
        val = title ? title : "Agreement";
        break;
      case "estimate":
        val = title ? title : "Proposal";
        break;
      case "opening_name":
        val = title ? title : "Name";
        break;
      case "estimator":
        val = title ? title : "Estimate";
        break;
      case "inspection":
        val = title ? title : "Inspection";
        break;
    }

    return val;
  },
);

export const estimateTitle = (state: RootState): string => {
  return getTitle(state, { documentType: "estimate" });
};

export const contractTitle = (state: RootState): string => {
  return getTitle(state, { documentType: "contract" });
};

export const inspectionTitle = (state: RootState): string => {
  return getTitle(state, { documentType: "inspection" });
};

/**
 * Returns true/false depending on presentation mode and preferences config
 * supported preferences config: never/always/customer_only/company_only
 *
 * @param {RootState} state The RootState
 * @param {{path: string[], notSet: boolean}} options
 * @returns {boolean} boolean
 */
export const presentModePreferencesConfig = createSelector(
  [currentOrgPrefConfig, navDisplay, (state, props) => ({ state, props })],
  (config, editMode, stateProps) => {
    const { props } = stateProps;
    if (_.isNullOrUndefined(config)) {
      if (_.isUndefined(props.notSet)) return false;

      return props.notSet;
    }

    if (editMode) {
      return _.include(["always", "company_only"], config);
    } else {
      return _.include(["always", "customer_only"], config);
    }
  },
);
