import { RsfForm } from "@tberrysoln/rsf-form";
import { MeasurementTable } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/MeasurementTable";
import { RootState } from "app2/src/reducers";
import { Actions } from "app2/src/reducers/measurement.actions";
import { rootKey } from "app2/src/selectors/measurement.selectors";
import { currentMeasurementId } from "app2/src/selectors/measurementCommon.selectors";
import { Map } from "immutable";
import * as React from "react";
import { Accordion, Card, Col, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as FontAwesome from "react-fontawesome";
import { useTracking } from "react-tracking";

export const sunroomGeneral1 = [
  "sunroom_a_wall_length",
  "sunroom_a_wall_height",
  "sunroom_a_wall_area",
  "sunroom_b_wall_length",
  "sunroom_b_wall_height",
  "sunroom_b_wall_area",
];

export const sunroomGeneral2 = [
  "sunroom_c_wall_length",
  "sunroom_c_wall_height",
  "sunroom_c_wall_area",
  "sunroom_wall_perimeter",
  "sunroom_wall_total_area",
];

export const deckAndRoof = [
  "sunroom_deck_total_area",
  "sunroom_ceiling_area",
  "sunroom_studio_roof_area",
  "sunroom_cathedral_roof_area",
];

export const kneeWall1 = [
  "sunroom_kw_a_length",
  "sunroom_kw_a_height",
  "sunroom_kw_a_area",
  "sunroom_kw_b_length",
  "sunroom_kw_b_height",
  "sunroom_kw_b_area",
];

export const kneeWall2 = [
  "sunroom_kw_c_length",
  "sunroom_kw_c_height",
  "sunroom_kw_c_area",
  "sunroom_kw_total_length",
  "sunroom_kw_total_area",
];

export const Sunroom: React.FC = () => {
  // Hooks
  const { trackEvent } = useTracking<any>({ component: "Sunroom" });

  // Local State
  const [accordionControl, setAccordionControl] = React.useState(
    Map({ sunroomGeneral: "sunroomGeneral", deckRoofAreas: "", kneeWall: "" }),
  );

  // Selectors
  const measurementId = useSelector((state: RootState) => currentMeasurementId(state, {}));

  // Methods
  const toggleAccordion = (key: string) => {
    if (accordionControl.get(key) === "") {
      trackEvent({ action: "open accordion", accordionName: key });
      setAccordionControl(accordionControl.set(key, key));
    } else {
      trackEvent({ action: "close accordion", accordionName: key });
      setAccordionControl(accordionControl.set(key, ""));
    }
  };

  return (
    <RsfForm rootPath={[rootKey, "byId", measurementId]} updateFormReducer={Actions.updateForm} onSubmit={() => {}}>
      <Row>
        <Col>
          <h2>Sunroom</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center">
            <Image src="/assets/images/measurements/sunroom-measurements-diagram.12e5ba2d.jpg" />
          </div>
        </Col>
      </Row>
      <Accordion activeKey={accordionControl.get("sunroomGeneral")}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="sunroomGeneral"
            onClick={() => toggleAccordion("sunroomGeneral")}>
            Sunroom General
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("sunroomGeneral") !== "" ? "angle-down" : "angle-up"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="sunroomGeneral">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <MeasurementTable values={sunroomGeneral1} />
                </Col>
                <Col lg={6}>
                  <MeasurementTable values={sunroomGeneral2} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <br />
      <Accordion activeKey={accordionControl.get("deckRoofAreas")}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="deckRoofAreas"
            onClick={() => toggleAccordion("deckRoofAreas")}>
            Deck and Roof Areas
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("deckRoofAreas") !== "" ? "angle-down" : "angle-up"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="deckRoofAreas">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <MeasurementTable values={deckAndRoof} />
                </Col>
                <Col lg={6}></Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <br />
      <Accordion activeKey={accordionControl.get("kneeWall")}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="kneeWall"
            onClick={() => toggleAccordion("kneeWall")}>
            Knee Wall Measurements
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("kneeWall") !== "" ? "angle-down" : "angle-up"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="kneeWall">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <MeasurementTable values={kneeWall1} />
                </Col>
                <Col lg={6}>
                  <MeasurementTable values={kneeWall2} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </RsfForm>
  );
};
