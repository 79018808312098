import { Record, List, Map } from "immutable";
import * as uuid from "uuid/v4";

const dateFields = ["created_at", "updated_at"];

export const PaymentTermItemKindList: List<PaymentTermItemKinds> = List<PaymentTermItemKinds>(["percent", "amount"]);
export type PaymentTermItemKinds = "percent" | "amount";

export const fromJSON = (json: Partial<IPaymentTermTemplateData>): PaymentTermTemplateRecord => {
  const record: IPaymentTermTemplateRecord = { ...(json as any) };

  if (json.breakdown) {
    record.breakdown = breakdownFromJSON(json.breakdown);
  }

  dateFields.forEach((f) => {
    if (json[f]) {
      record[f] = new Date(json[f]);
    } else {
      record[f] = new Date();
    }
  });

  return new PaymentTermTemplateRecord(record);
};

export const paymentTermItemFromJSON = (
  json: Partial<IPaymentTermItemData>,
  forceUuid = true,
): PaymentTermItemRecord => {
  const record: IPaymentTermItemRecord = { ...(json as any) };

  if (!record.uuid && forceUuid) {
    record.uuid = uuid();
  }

  return new PaymentTermItemRecord(record);
};

export const breakdownFromJSON = (json: Partial<IPaymentTermBreakdown>): PaymentTermBreakdownRecord => {
  const record: IPaymentTermBreakdownRecord = { ...(json as any) };

  if (record.items) {
    record.items = List(record.items.map((pti) => paymentTermItemFromJSON(pti, false)));
  }

  return new PaymentTermBreakdownRecord(record);
};

export interface IPaymentTermBreakdown {
  items: IPaymentTermItemData[];
}

export interface IPaymentTermBreakdownRecord {
  items: List<PaymentTermItemRecord>;
}

const defaultPaymentTermBreakdownProps: IPaymentTermBreakdownRecord = {
  items: List<PaymentTermItemRecord>(),
};

export class PaymentTermBreakdownRecord
  extends Record(defaultPaymentTermBreakdownProps)
  implements IPaymentTermBreakdownRecord
{
  public readonly items!: List<PaymentTermItemRecord>;

  public constructor(values?: Partial<IPaymentTermBreakdownRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}

export interface IPaymentTermItemData {
  uuid: string;
  amount: number;
  description: string;
  type: PaymentTermItemKinds;
  calculated: number;
  method: "cash" | "financed";
}

export interface IPaymentTermItemRecord {
  uuid: string;
  amount: number;
  description: string;
  type: PaymentTermItemKinds;
  calculated: number;
  paymentMethod: "cash" | "financed";
}

const defaultPaymentTermItemProps: IPaymentTermItemRecord = {
  uuid: "",
  amount: 0,
  description: "",
  type: "amount",
  calculated: 0,
  paymentMethod: "cash",
};

export class PaymentTermItemRecord extends Record(defaultPaymentTermItemProps) implements IPaymentTermItemRecord {
  public readonly uuid!: string;
  public readonly amount!: number;
  public readonly description!: string;
  public readonly type!: PaymentTermItemKinds;
  public readonly calculated!: number;
  public readonly paymentMethod!: "cash" | "financed";

  public constructor(values?: Partial<IPaymentTermItemRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}

export interface IPaymentTermTemplateData {
  id: number;
  org_id: number;
  name: string;
  description: string;
  breakdown: { items: IPaymentTermItemData[] };
  sort_order: number;
  created_at: Date;
  updated_at: Date;
  loading?: boolean;
  errors?: string[];
}

export interface IPaymentTermTemplateRecord {
  id: number;
  org_id: number;
  name: string;
  description: string;
  breakdown: PaymentTermBreakdownRecord;
  sort_order: number;
  created_at: Date;
  updated_at: Date;
  loading: boolean;
  errors: List<string>;
}

const defaultPaymentTermTemplateProps: IPaymentTermTemplateRecord = {
  id: 0,
  org_id: 0,
  name: "",
  description: "",
  breakdown: new PaymentTermBreakdownRecord(),
  sort_order: 0,
  created_at: new Date(),
  updated_at: new Date(),
  errors: List<string>(),
  loading: false,
};

export class PaymentTermTemplateRecord
  extends Record(defaultPaymentTermTemplateProps)
  implements IPaymentTermTemplateRecord
{
  public readonly id!: number;
  public readonly org_id!: number;
  public readonly name!: string;
  public readonly description!: string;
  public readonly breakdown!: PaymentTermBreakdownRecord;
  public readonly sort_order!: number;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;
  public readonly errors!: List<string>;
  public readonly loading!: boolean;

  public constructor(values?: Partial<IPaymentTermTemplateRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
