import { Record, List } from "immutable";
import { fromJSON as invoiceTextFromJSON, InvoiceTextRecord } from "./InvoiceText";
import {
  fromJSON as planSubscriptionFromJSON,
  IPlanSubscriptionData,
  PlanSubscriptionRecord,
} from "./PlanSubscription";
import { fromJSON as feeFromJSON, IFeeData, FeeRecord } from "./Fee";
import { fromJSON as feeSubscriptionFromJSON, IFeeSubscriptionData, FeeSubscriptionRecord } from "./FeeSubscription";
import { Nullable, checkDate } from "..";

export const fromJSON = (json: Partial<IAccountData>): AccountRecord => {
  const record = json as any as IAccountRecord;

  if (json.plan_subscriptions && json.plan_subscriptions.length > 0) {
    record.plan_subscriptions = List(json.plan_subscriptions.map((ps) => planSubscriptionFromJSON(ps)));
  } else {
    record.plan_subscriptions = List();
  }

  if (json.fee) {
    record.fee = feeFromJSON(json.fee);
  }

  if (json.fee_subscription) {
    record.fee_subscription = feeSubscriptionFromJSON(json.fee_subscription);
  }

  if (json.invoice_text) {
    record.invoice_text = invoiceTextFromJSON(json.invoice_text);
  } else {
    record.invoice_text = invoiceTextFromJSON({});
  }

  if (json.created_at) {
    record.created_at = checkDate(json.created_at);
  }

  if (json.updated_at) {
    record.updated_at = checkDate(json.updated_at);
  }

  return new AccountRecord(record);
};

export const toJSON = (account: AccountRecord): IAccountData => {
  return account.toJS() as IAccountData;
};

export interface IAccountData {
  id: number;
  billing_fee_id: number;
  billing_plan_id: number;
  created_at: Date | string;
  invoice_text: any;
  org_id: number;
  updated_at: Date | string;
  user_count: number;
  uuid: string;

  fee: Nullable<IFeeData>;
  fee_subscription: IFeeSubscriptionData;

  plan_subscriptions: IPlanSubscriptionData[];

  loading?: boolean;
  errors?: string[];
}

export interface IAccountRecord {
  id: number;
  billing_fee_id: number;
  billing_plan_id: number;
  created_at: Date;
  invoice_text: InvoiceTextRecord;
  org_id: number;
  updated_at: Date;
  user_count: number;
  uuid: string;

  fee: Nullable<FeeRecord>;
  fee_subscription: Nullable<FeeSubscriptionRecord>;

  plan_subscriptions: List<PlanSubscriptionRecord>;

  loading: boolean;
  errors: List<string>;
}

const defaultAccountProps: IAccountRecord = {
  id: 0,
  billing_fee_id: 0,
  billing_plan_id: 0,
  created_at: new Date(),
  invoice_text: invoiceTextFromJSON({}),
  org_id: 0,
  updated_at: new Date(),
  user_count: 0,
  uuid: "",
  fee: null,
  fee_subscription: null,
  plan_subscriptions: List(),
  loading: false,
  errors: List(),
};

export class AccountRecord extends Record(defaultAccountProps) implements IAccountRecord {
  public readonly id!: number;
  public readonly billing_fee_id!: number;
  public readonly billing_plan_id!: number;
  public readonly invoice_text!: InvoiceTextRecord;
  public readonly org_id!: number;
  public readonly uuid!: string;
  public readonly user_count!: number;

  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public readonly fee!: Nullable<FeeRecord>;
  public readonly fee_subscription!: Nullable<FeeSubscriptionRecord>;

  public readonly plan_subscriptions!: List<PlanSubscriptionRecord>;

  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public constructor(values?: Partial<IAccountRecord>) {
    values ? super(values) : super();
  }
}

export const defaultAccount = new AccountRecord({});
