import { fetcher } from "../helpers/Fetcher";
import { IScreenShareData } from "../records/ScreenShare";

interface IScreenShareResponse {
  screen_share: IScreenShareData;
}

interface IScreenShareTokenResponse {
  token: string;
}

class ScreenShareService {
  public url = "api/v1/screen_shares/:id";
  public tokenUrl = "api/v1/screen_shares/token";

  public create(jobId: number, kind: string): Promise<IScreenShareData> {
    const actionUrl = this.url.replace(":id", "");

    return fetcher.post<IScreenShareResponse>(actionUrl, { job_id: jobId, kind: kind }).then(
      (response) => response.screen_share,
      (response) => Promise.reject(response.data.errors),
    );
  }

  public load(id: number): Promise<IScreenShareData> {
    const actionUrl = this.url.replace(":id", id.toString());

    return fetcher.get<IScreenShareResponse>(actionUrl).then(
      (response) => response.screen_share,
      (response) => Promise.reject(response.data.errors),
    );
  }

  public stop(id: number): Promise<IScreenShareData> {
    const actionUrl = `${this.url.replace(":id", id.toString())}/stop`;

    return fetcher.patch<IScreenShareResponse>(actionUrl).then(
      (response) => response.screen_share,
      (response) => Promise.reject(response.data.errors),
    );
  }

  public loadToken(params): Promise<string> {
    return fetcher.get<IScreenShareTokenResponse>(this.tokenUrl, params).then(
      (response) => response.token,
      (response) => Promise.reject(response.data.errors),
    );
  }

  public removeParticipant(id: number, participant_id: string): Promise<boolean> {
    const actionUrl = `${this.url.replace(":id", id.toString())}/remove_participant`;
    return fetcher
      .patch<IScreenShareTokenResponse>(actionUrl, {
        participant_id: participant_id,
      })
      .then(
        () => true,
        () => false,
      );
  }

  public getRoomStatus(uid: string): Promise<boolean> {
    const actionUrl = `api/v1/screen_shares/room_status/${uid}`;
    return fetcher.get(actionUrl).then(
      (result: { exists: boolean }) => {
        return result.exists;
      },
      () => false,
    );
  }
}

export const screenShareService = new ScreenShareService();
