import { Record, List } from "immutable";
import { checkDate, Nullable } from ".";
import { ITaskData } from "app2/src/records/Task";

export const fromJSON = (json: Partial<IReportData>): ReportRecord => {
  const data: Partial<IReportData> = { ...(json as any) };
  if (data.task) {
    data.task_id = data.task.id;
  }

  if (data.created_at) {
    data.created_at = checkDate(json.created_at);
  }

  if (data.updated_at) {
    data.updated_at = checkDate(json.updated_at);
  }
  return new ReportRecord(data);
};

export const toJSON = (record: ReportRecord): IReportData => {
  return record.toJS();
};

export type ReportKind = "roofing" | "walls" | "hail" | "wind" | "lightning" | "permit" | "ortho_image" | "interior";
export type ReportProvider = "wvs" | "eagleview" | "quick_measure" | "roof_scope" | "plnar";
export type ReportStatus =
  | "created"
  | "ordered"
  | "exception"
  | "canceled"
  | "complete"
  | "paid"
  | "refunded"
  | "partially_refunded";

export interface IReportData {
  id: number;
  kind: ReportKind;
  provider: ReportProvider;
  status: ReportStatus;
  metadata: any;
  order_data: any;
  order_result: any;
  user_id: Nullable<number>;
  org_id: Nullable<number>;
  job_id: Nullable<number>;
  task?: ITaskData;
  task_id?: string;
  loading?: boolean;

  delivered_at: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IReportRecord {
  id: number;
  kind: ReportKind;
  provider: ReportProvider;
  status: ReportStatus;
  metadata: any;
  order_data: any;
  order_result: any;
  user_id: Nullable<number>;
  org_id: Nullable<number>;
  job_id: Nullable<number>;
  task_id: Nullable<string>;
  loading: boolean;
  errors: List<string>;

  delivered_at: Date;
  created_at: Date;
  updated_at: Date;
}

const defaultReportProps: IReportRecord = {
  id: 0,
  kind: null,
  provider: null,
  status: null,
  metadata: {},
  order_data: {},
  order_result: {},
  user_id: null,
  org_id: null,
  job_id: null,
  task_id: null,
  loading: false,
  errors: List<string>(),
  delivered_at: new Date(),
  created_at: new Date(),
  updated_at: new Date(),
};

export class ReportRecord extends Record(defaultReportProps) implements IReportRecord {
  public readonly id!: number;
  public readonly kind!: ReportKind;
  public readonly provider!: ReportProvider;
  public readonly status!: ReportStatus;
  public readonly metadata!: any;
  public readonly order_data!: any;
  public readonly order_result!: any;
  public readonly user_id!: Nullable<number>;
  public readonly org_id!: Nullable<number>;
  public readonly job_id!: Nullable<number>;
  public readonly task_id!: Nullable<string>;
  public readonly loading!: boolean;
  public readonly errors!: List<string>;

  public readonly delivered_at!: Date;
  public readonly created_at!: Date;
  public readonly updated_at!: Date;

  public constructor(values?: Partial<IReportRecord>) {
    if (values) {
      super(values);
    } else {
      super();
    }
  }
}
