import { RootState } from "../reducers";
import { createSelector } from "reselect";
import { NoteRecord } from "app2/src/records/Note";

export const rootKey = "notes";

export const note = (state: RootState, props: any) => state.getIn([rootKey, "byId", props.noteId]);
export const lastSavedNote = (state: RootState, props: any) => state.getIn([rootKey, "lastSavedById", props.noteId]);

export const noteChanged = createSelector([note, lastSavedNote], (note: NoteRecord, lastSavedNote: NoteRecord) => {
  if (!lastSavedNote) {
    return true;
  }
  if (note.equals(lastSavedNote)) {
    return false;
  } else {
    return true;
  }
});
