import * as React from "react";
import { Modal, Row, Col } from "react-bootstrap";

interface ProcessListProps {
  processes: any;
  select: (processKey: string) => void;
}

export default class ProcessList extends React.Component<ProcessListProps> {
  public constructor(props: ProcessListProps) {
    super(props);
  }

  public render() {
    const { processes, select } = this.props;

    return (
      <React.Fragment>
        <Modal.Header closeButton>Process Selector</Modal.Header>
        <Modal.Body>
          {Object.keys(processes.list).map((processKey, idx) => {
            return (
              <Row className="link task-row" key={idx} onClick={() => select(processKey)}>
                <Col md={6}>{processes.list[processKey].name}</Col>
                <Col md={6}>{processes.list[processKey].description}</Col>
              </Row>
            );
          })}
        </Modal.Body>
      </React.Fragment>
    );
  }
}
