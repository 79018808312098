import { fetcher } from "app2/src/helpers/Fetcher";

export interface IEagleviewReportData {
  id: number;
  street_1: string;
  street_2: string;
  city: string;
  state: string;
  zip: string;
}

export interface IPagination {
  pagination: {
    total_count: number;
    total_pages: number;
    current_page: number;
  };
}

export interface Page {
  page: number;
  reports: IEagleviewReportData[];
  meta: IPagination;
}

class EagleviewService {
  public async getPage(page: number) {
    return await fetcher.get<Page>("/integrations/eagleview/reports", { page: page });
  }

  public async importReport(reportId: number) {
    return await fetcher.get<boolean>("/integrations/eagleview/import", { report_id: reportId });
  }

  public async login(credentials: any) {
    return await fetcher.post("/integrations/eagleview/login", credentials);
  }
}

export const eagleviewService = new EagleviewService();
