import * as React from "react";
import { RootState } from "app2/src/reducers";
import { connect, ConnectedProps } from "app2/src/connect";
import { RootDispatchType } from "app2/src/store";
import { QueryParamsRecord } from "app2/src/records/Page";
import * as imageActions from "app2/src/reducers/image.actions";
import { ids, pageRecord, pagination, queryParams } from "app2/src/selectors/pagination.selectors";
import { Row, Col } from "react-bootstrap";
import SpinnerComponent from "app2/src/components/SpinnerComponent";
import track from "react-tracking";
import { List } from "immutable";
import { push } from "app2/src/reducers/router.actions";
import { ILocationData } from "app2/src/records/Location";
import { navDisplay } from "app2/src/selectors/components/common.selectors";
import ImageList from "app2/src/components/JobTabs/ImageTab/Images/ImageList";
import ImageCarousel from "app2/src/components/JobTabs/ImageTab/Images/ImageCarousel";

const mapStateToProps = (state: RootState) => {
  const modelName = "image",
    imageQueryParams = queryParams(state, { modelName });
  const page = imageQueryParams.get("page");
  const imageIds = ids(state, { modelName }) || List();
  return {
    imageIds,
    page: pageRecord(state, { modelName, page }),
    pagination: pagination(state, { modelName, page }),
    imageQueryParams,
    navDisplay: navDisplay(state),
  };
};

const mapDispatchToProps = (dispatch: RootDispatchType, ownProps: ImagesProps) => {
  return {
    listImages: (queryParams: QueryParamsRecord) =>
      dispatch(
        imageActions.AsyncActions.listImages(
          {
            imageableType: "job",
            folder_id: ownProps.folderParentId,
          },
          queryParams,
        ),
      ),
    push: (location: Partial<ILocationData>) => dispatch(push(location)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ImagesProps {
  folderParentId: number;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ImagesProps;

@track(() => {
  return {
    component: "Images",
  };
})
class Images extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.queryImages = this.queryImages.bind(this);
  }

  @track((_props, _state, [page]) => ({
    action: "queryImages",
    page: page,
  }))
  public queryImages(page: number): void {
    const { listImages, imageQueryParams } = this.props;
    const newQueryParams = imageQueryParams.setIn(["page"], page);
    listImages(newQueryParams);
  }

  public render(): React.ReactNode {
    const { imageIds, page, navDisplay } = this.props;

    if (page?.loading) {
      return <SpinnerComponent localProperty={true} />;
    }

    if (_.isNullOrUndefined(page) || imageIds.size === 0) {
      return (
        <Row>
          <Col>
            <div className="form-section blank-state">
              <img src="/assets/images/icons-large/photos.08c0f5bd.png" />
              <h2>No photos added. Let's add one.</h2>
              <p>Click on the add photos button in the top right to add one.</p>
            </div>
          </Col>
        </Row>
      );
    }

    if (navDisplay) {
      return <ImageList queryImages={this.queryImages} />;
    } else {
      return <ImageCarousel queryImages={this.queryImages} />;
    }
  }
}

export default connector(Images);
