import * as ng from "angular";
import { IEstimateLineItem } from "app/src/Models/EstimateLineItem";
import { IConfirmDialog } from "app/src/Common/ConfirmDialogService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { ProductRecord } from "app2/src/records/Product";
import { useSelector } from "app2/src/storeRegistry";
import { cachedProductChildren } from "app2/src/selectors/product.selectors";
import { IEstimate } from "app/src/Models/Estimate";
import { IEstimateGroup } from "../Models/EstimateGroup";

export interface IProductColorDropdownScope extends ng.IScope {
  lineItem: IEstimateLineItem;
  product: ProductRecord;
  activatedPriceListId: number;
  parent_id: number;
  current_product: ProductRecord;
  children: ProductRecord[];
  estimate: IEstimate;
  estimateGroup: IEstimateGroup;
  colorChanged(cp: ProductRecord): void;
}

export class ProductColorDropdown implements ng.IDirective {
  public restrict = "E";
  public templateUrl = "src/Estimator/productColorDropdown.html";
  public scope = {
    lineItem: "=",
    product: "=",
    activatedPriceListId: "<",
    estimate: "<",
    estimateGroup: "<",
  };

  constructor(
    public ConfirmDialog: IConfirmDialog,
    public EstimatorService: EstimatorService,
    public $timeout: ng.ITimeoutService,
  ) {}

  public link = (scope: IProductColorDropdownScope) => {
    const app = this;

    scope.$watchGroup(["lineItem.id", "lineItem.product_id", "product.id"], () => {
      if (scope.lineItem && scope.product) {
        scope.current_product = scope.product;
        scope.parent_id = scope.product.parent_id;
        scope.children = [];
        if (scope.parent_id) {
          scope.children = useSelector((state) =>
            cachedProductChildren(state, {
              activatedPriceListId: scope.activatedPriceListId,
              productId: scope.parent_id,
              includeParent: false,
            }),
          ).toArray();
        }
      } else {
        scope.parent_id = undefined;
      }
    });

    scope.colorChanged = (cp) => {
      if (!cp) {
        // RSF-15752 - Fixes a very specific error, see ticket
        const selectCurrentProduct = scope.current_product;
        scope.current_product = null;
        app.$timeout(() => {
          scope.current_product = selectCurrentProduct;
        });
        return;
      }
      scope.current_product = cp;
      if (scope.lineItem.checkDescription(scope.current_product)) {
        app.productSetup(scope, true);
        return;
      }

      if (scope.lineItem.checkDescription(scope.product)) {
        app.productSetup(scope, true);
      } else {
        app.ConfirmDialog.confirm(
          "You edited the current line item description. Would you like to change the description to the new product?",
        )
          .then(() => {
            app.productSetup(scope, true);
          })
          .catch(() => {
            app.productSetup(scope, false);
          });
      }
    };
  };

  public productSetup(scope, setDescription) {
    scope.lineItem.options = [];
    const quantity = this.EstimatorService.addMeasurementLink(
      scope.current_product,
      scope.lineItem.quantity,
      undefined,
      scope.estimateGroup,
    );
    const current_link_quantity = this.EstimatorService.addMeasurementLink(
      scope.product,
      scope.lineItem.quantity,
      false,
      scope.estimateGroup,
    );
    if (current_link_quantity !== scope.lineItem.quantity) {
      this.ConfirmDialog.confirm(
        "You edited the current line item quantity (" +
          scope.lineItem.quantity +
          "). Would you like to change the quantity to " +
          quantity +
          "?",
        {
          template:
            '<div class="confirm-modal modal-header"><div class="modal-title description"><h3>{{msg}}</h3></div></div><div class="modal-footer"><button class="btn btn-default" type="button" ng-click="$close()">YES</button><button class="btn btn-cancel" type="button" ng-click="$dismiss(false)">NO</button></div>',
        },
      )
        .then(() => {
          scope.lineItem.quantity = quantity;
          scope.lineItem.setProduct(scope.activatedPriceListId, scope.current_product, setDescription);
          scope.lineItem.calculate(scope.estimate);
          this.EstimatorService.addDefaultOptions(scope.lineItem, scope.product);
        })
        .catch(() => {
          scope.lineItem.setProduct(scope.activatedPriceListId, scope.current_product, setDescription);
          scope.lineItem.calculate(scope.estimate);
          this.EstimatorService.addDefaultOptions(scope.lineItem, scope.product);
        });
    } else {
      scope.lineItem.quantity = quantity;
      scope.lineItem.setProduct(scope.activatedPriceListId, scope.current_product, setDescription);
      scope.lineItem.calculate(scope.estimate);
      this.EstimatorService.addDefaultOptions(scope.lineItem, scope.product);
    }
  }

  public static factory(): ng.IDirectiveFactory {
    const directive = (
      ConfirmDialog: IConfirmDialog,
      EstimatorService: EstimatorService,
      $timeout: ng.ITimeoutService,
    ) => new ProductColorDropdown(ConfirmDialog, EstimatorService, $timeout);
    directive.$inject = <ReadonlyArray<string>>["ConfirmDialog", "EstimatorService", "$timeout"];

    return directive;
  }
}
