import { ThunkAction } from "redux-thunk";
import { ActionsUnion, createAction } from "app2/src/reducers/Utils";
import { RootState } from "app2/src/reducers";
import { RootDispatchType } from "app2/src/store";
import { handleErrors } from "app2/src/reducers/Utils";
import { ISkuData } from "../records/Sku";
import { skuRecord, skuValue } from "../selectors/sku.selectors";
import { create, update, destroy } from "../api/sku.service";

export const FETCH_SKU = "@skus/FETCH_SKU";
export const RECEIVE_SKU = "@skus/RECEIVE_SKU";
export const SET_LOADED = "@skus/SET_LOADED";
export const UPDATE_FORM = "@skus/UPDATE_FORM";
export const CLEAR_SKU = "@skus/CLEAR_SKU";
export const UPDATE_VALUE = "@skus/UPDATE_VALUE";

export const Actions = {
  fetchSku: (id: number) => createAction(FETCH_SKU, { id }),
  receiveSku: (sku: ISkuData) => createAction(RECEIVE_SKU, { sku }),
  setLoaded: (id: number) => createAction(SET_LOADED, { id }),
  update: (event: any) => createAction(UPDATE_FORM, { event }),
  clearSku: (id: number) => createAction(CLEAR_SKU, { id }),
  updateValue: (id: number, newValue: string) => createAction(UPDATE_VALUE, { id, newValue }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  saveOrCreateOrDestroy: (skuId: number): ThunkResult<Promise<void | number>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState): Promise<void | number> => {
      const value = skuValue(getState(), skuId).trim();
      dispatch(Actions.updateValue(skuId, value));

      if (skuId <= 0 && value) {
        return dispatch(AsyncActions.create(skuId));
      }
      if (value) {
        await dispatch(AsyncActions.save(skuId));
      } else if (skuId > 0) {
        await dispatch(AsyncActions.destroy(skuId));
      }
    };
  },
  save: (skuId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState): Promise<void> => {
      const record = skuRecord(getState(), skuId);
      try {
        dispatch(Actions.fetchSku(skuId));
        const response = await update(record);
        dispatch(Actions.receiveSku(response.sku));
      } catch (response) {
        dispatch(Actions.setLoaded(skuId));
        handleErrors(response, dispatch);
      }
    };
  },
  create: (skuId: number): ThunkResult<Promise<number>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState): Promise<number> => {
      const record = skuRecord(getState(), skuId);
      try {
        const response = await create(record);
        dispatch(Actions.receiveSku(response.sku));
        return response.sku.id;
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  destroy: (skuId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const record = skuRecord(getState(), skuId);
      try {
        dispatch(Actions.fetchSku(skuId));
        await destroy(record);
        dispatch(Actions.setLoaded(skuId));
      } catch (response) {
        dispatch(Actions.setLoaded(skuId));
        handleErrors(response, dispatch);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
