import * as validationActions from "./validation.actions";
import { ValidationRecord, IValidationData, fromJSON } from "app2/src/records/Validation";
import { Map, Record, List, Set } from "immutable";

export interface IOrgValidationsRecord {
  validations: Set<number>;
  errors: List<string>;
  loading: boolean;
}
export const OrgValidationsRecord: Record.Factory<IOrgValidationsRecord> = Record<IOrgValidationsRecord>({
  validations: Set<number>(),
  errors: List<string>(),
  loading: false,
});

export const ValidationStateRecord = Record({
  byId: Map<number, ValidationRecord>(),
  byItemUuid: Map<string, number>(),
  byOrgId: Map<number, List<typeof OrgValidationsRecord>>(),
});

export const initialState = ValidationStateRecord();
export type ValidationState = typeof initialState;

export const reducer = (state = initialState, action: validationActions.Actions): ValidationState => {
  switch (action.type) {
    case validationActions.RECEIVE_VALIDATION:
      const validation = fromJSON({ ...action.payload.validation, loading: false });

      if (!state.getIn(["byOrgId", validation.org_id])) {
        state = state.setIn(["byOrgId", validation.org_id], OrgValidationsRecord({}));
      }

      return state
        .setIn(["byId", validation.id], validation)
        .setIn(["byItemUuid", validation.item_uuid], validation.id)
        .setIn(
          ["byOrgId", validation.org_id, "validations"],
          state.getIn(["byOrgId", validation.org_id, "validations"]).add(validation.id),
        );
    case validationActions.FETCH_ORG_VALIDATIONS:
      if (!state.getIn(["byOrgId", action.payload.orgId])) {
        return state.setIn(["byOrgId", action.payload.orgId], OrgValidationsRecord({ loading: true }));
      }

      return state.setIn(["byOrgId", action.payload.orgId, "loading"], true);
    case validationActions.RECEIVE_ORG_VALIDATIONS:
      action.payload.validations.forEach((v: IValidationData) => {
        state = reducer(state, validationActions.Actions.receiveValidation(v));
      });
      return state.setIn(["byOrgId", action.payload.orgId, "loading"], false);
    case validationActions.ORG_VALIDATIONS_LOADED:
      return state.setIn(["byOrgId", action.payload.orgId, "loading"], false);
    default:
      return state;
  }
};
