import { Record, List } from "immutable";
import {
  fromJSON as discountFromJson,
  IEstimateDiscountData,
  EstimateDiscountRecord,
  calculate,
} from "./EstimateDiscount";
import { IEstimateGroupData } from "./EstimateGroup";
import { fromJSON as defaultFromJSON, DefaultsRecord, IDefaultsData } from "./Defaults";
import { fromJSON as paymentTermsFromJSON, PaymentTermRecord, IPaymentTermData } from "./PaymentTerm";
import { IJobData, JobRecord } from "./Job";
import { IEstimateFinanceOptionData } from "./EstimateFinanceOption";
import { IDocumentData } from "./Document";

export const fromJSON = (json: Partial<IEstimateData>): EstimateRecord => {
  const record: IEstimateRecord = { ...(json as any) };

  if (json.estimated_start && typeof json.estimated_start === "string") {
    const timezoneDate = new Date(json.estimated_start);
    record.estimated_start = new Date(
      `${json.estimated_start}T00:00:00.000${getTimezoneString(timezoneDate.getTimezoneOffset())}`,
    );
  }
  if (json.estimated_end && typeof json.estimated_end === "string") {
    const timezoneDate = new Date(json.estimated_end);
    record.estimated_end = new Date(
      `${json.estimated_end}T00:00:00.000${getTimezoneString(timezoneDate.getTimezoneOffset())}`,
    );
  }

  if (json.payment_terms && !Record.isRecord(json.payment_terms)) {
    record.payment_terms = paymentTermsFromJSON(json.payment_terms);
  }

  if (json.groups) {
    record.group_ids = List(json.groups.map((g) => g.id));
  }

  if (json.discounts) {
    record.discounts = List(json.discounts.map((d) => discountFromJson(d)));
  }

  if (json.finance_options) {
    record.finance_option_ids = List(json.finance_options.map((o) => o.id));
  }

  if (json.defaults) {
    record.defaults = defaultFromJSON(json.defaults);
  }

  return new EstimateRecord(record);
};

export const getCreatedLineItemDocuments = (
  rawEstimateData: Partial<IEstimateData>,
  duplicatedEstimate: Partial<IEstimateData>,
): IDocumentData[] => {
  const documentsArray: IDocumentData[] = [];

  _.each(rawEstimateData.groups, (group) => {
    const g_sort_order = group.sort_order;
    const duplicatedGroup = duplicatedEstimate.groups.find((dGroup) => dGroup.sort_order === g_sort_order);
    if (duplicatedGroup) {
      _.each(group.line_items, (lineItem) => {
        const li_sort_order = lineItem.sort_order;
        const duplicatedLineItem = duplicatedGroup.line_items.find(
          (dLineItem) => dLineItem.sort_order === li_sort_order,
        );
        const rawDocuments = lineItem.documents || [];
        if (rawDocuments.length && duplicatedLineItem) {
          rawDocuments
            .filter((doc) => doc.id < 0)
            .forEach((doc) => {
              doc.documentable_id = duplicatedLineItem.id;
              delete doc.id;

              documentsArray.push(doc);
            });
        }
      });
    }
  });

  return documentsArray;
};

export const getTimezoneString = (timezoneOffset: number): string => {
  let timezoneString = "";

  if (timezoneOffset > 0) {
    timezoneString += "-";
  } else {
    timezoneString += "+";
  }
  const hours = Math.abs(timezoneOffset) / 60;
  if (hours < 10) {
    timezoneString += "0";
  }
  timezoneString += `${hours.toString()}:00`;

  return timezoneString;
};

export const markupMultiplier = (markup: number): number => {
  return (markup || 0) / 100 + 1;
};

export const addDiscount = (estimate: EstimateRecord, discount: Partial<IEstimateDiscountData>): EstimateRecord => {
  estimate = estimate.update("discounts", (discounts: List<EstimateDiscountRecord>) => {
    return discounts.push(calculate(discount, estimate.running_discountable));
  });

  return estimate;
};

export const updateDiscount = (estimate: EstimateRecord, discount: EstimateDiscountRecord): EstimateRecord => {
  estimate = estimate.update("discounts", (discounts: List<EstimateDiscountRecord>) => {
    return discounts.map((d) => {
      if (d.uuid === discount.uuid) {
        return discount;
      }
      return d;
    });
  });

  return updateDiscounts(estimate);
};

export const removeDiscount = (estimate: EstimateRecord, uuid: string): EstimateRecord => {
  estimate = estimate.update("discounts", (discounts: List<EstimateDiscountRecord>) => {
    return discounts.filter((d) => d.uuid !== uuid);
  });

  return updateDiscounts(estimate);
};

export const updateDiscounts = (estimate: EstimateRecord) => {
  const totals = { running_discountable: estimate.discountable_total };
  estimate = estimate.update("discounts", (discounts: List<EstimateDiscountRecord>) => {
    return discounts.map((d) => {
      const discount = calculate(d.toJSON(), totals.running_discountable);
      totals.running_discountable = _.round(totals.running_discountable - discount.calculated, -2);
      return discount;
    });
  });

  return estimate.merge(totals);
};

export interface IBaseTotals {
  subtotal: number;
  total: number;
  labor_total: number;
  product_total: number;
  discountable_total: number;
}

export interface IDiscountTotals {
  discount: number;
  running_discountable: number;
}

export interface ITaxTotals {
  taxable_amount: number;
  tax_total: number;
  total: number;
}

export interface IEstimateData {
  id: number;
  activated_price_list_id: number;
  price_list_id: number;
  customer_name: string;
  name: string;
  customer_phone: string;
  org_uid: string;
  uid: string;
  payment_terms: IPaymentTermData;
  estimated_start: Date;
  estimated_end: Date;
  groups: IEstimateGroupData[];
  discounts: IEstimateDiscountData[];
  job_id: number;
  job: IJobData;
  discount: number;
  subtotal: number;
  total: number;
  labor_total: number;
  product_total: number;
  discountable_total: number;
  created_at: Date;
  updated_at: Date;
  tax_rate: number;
  tax_total: number;
  taxable_amount: number;
  default_taxable_amount: string;
  defaults: IDefaultsData;
  loading?: boolean;
  finance_options: IEstimateFinanceOptionData[];
  product_markup: number;
  labor_markup: number;
}

export interface IEstimateRecord {
  id: number;
  activated_price_list_id: number;
  price_list_id: number;
  customer_name: string;
  name: string;
  customer_phone: string;
  org_uid: string;
  uid: string;
  payment_terms: PaymentTermRecord;
  estimated_start: Date;
  estimated_end: Date;
  group_ids: List<number>;
  discounts: List<EstimateDiscountRecord>;
  job_id: number;
  job: JobRecord;
  discount: number;
  subtotal: number;
  total: number;
  labor_total: number;
  product_total: number;
  discountable_total: number;
  running_discountable: number;
  created_at: Date;
  updated_at: Date;
  tax_rate: number;
  tax_total: number;
  taxable_amount: number;
  default_taxable_amount: string;
  defaults: DefaultsRecord;
  loading: boolean;
  finance_option_ids: List<number>;
  product_markup: number;
  labor_markup: number;
  tempDocumentId: string;
}

const defaultEstimateProps: IEstimateRecord = {
  id: 0,
  activated_price_list_id: 0,
  price_list_id: null,
  customer_name: "",
  name: "",
  customer_phone: "",
  org_uid: "",
  uid: "",
  payment_terms: paymentTermsFromJSON({}),
  estimated_start: null,
  estimated_end: null,
  group_ids: List<number>(),
  discounts: List<EstimateDiscountRecord>(),
  job_id: 0,
  job: null,
  discount: null,
  subtotal: 0,
  total: 0,
  labor_total: 0,
  product_total: 0,
  discountable_total: 0,
  running_discountable: 0,
  created_at: null,
  updated_at: null,
  tax_rate: 0,
  tax_total: 0,
  taxable_amount: 0,
  default_taxable_amount: "",
  defaults: new DefaultsRecord(),
  loading: false,
  finance_option_ids: List<number>(),
  product_markup: 1,
  labor_markup: 1,
  tempDocumentId: null,
};

export class EstimateRecord extends Record(defaultEstimateProps) implements IEstimateRecord {}

export type DocType = "estimate" | "contract" | "inspection";

export enum CommissionMethod {
  marginWithOverhead = "margin_with_overhead",
  greenline = "greenline",
  formula = "formula",
  custom = "custom",
}
