/**
 * Lightens (or Darkens) a hex string by the percent change
 * @param hex Hex String
 * @param change Percent string 15 => %15
 */
export const lighten = (hex: string, change = 0): string => {
  hex = hex.replace(/[^0-9a-f]/gi, "");

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    return hex;
  }

  // convert to decimal and change luminosity
  let result = "#";
  let hexSegment: number;
  let strSegment: string;
  let idx: number;
  for (idx = 0; idx < 3; idx++) {
    hexSegment = parseInt(hex.substr(idx * 2, 2), 16);

    strSegment = Math.round(Math.min(Math.max(0, hexSegment + (255 - hexSegment) * (change / 100)), 255)).toString(16);

    result += ("00" + strSegment).substr(strSegment.length);
  }

  return result.toUpperCase();
};
