import { LocalVideoTrack, RemoteVideoTrack, TwilioError } from "twilio-video";

declare module "twilio-video" {
  interface LocalParticipant {
    setBandwidthProfile: (bandwidthProfile: BandwidthProfileOptions) => void;
  }

  interface VideoCodecSettings {
    simulcast?: boolean;
  }

  interface LocalVideoTrack {
    isSwitchedOff: undefined;
    setPriority: undefined;
  }
}

declare global {
  interface MediaDevices {
    getDisplayMedia(constraints: MediaStreamConstraints): Promise<MediaStream>;
  }
}

export type Callback = (...args: any[]) => void;

export type ErrorCallback = (error: TwilioError) => void;

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

export enum BlurTypes {
  Slight = "slight-blur",
  Full = "full-blur",
}

export enum LocalStorageKeys {
  SelectedBackground = "twilio-selected-background",
  CustomBackgrounds = "twilio-custom-backgrounds",
  AudioInputId = "twilio-audio-input",
  AudioOutputId = "twilio-audio-output",
  VideoInputId = "twilio-video-input",
  GraphicsSetting = "twilio-graphics-setting",
  ReduceResolution = "twilio-reduce-resolution-setting",
}
