import { createStore, applyMiddleware, compose, Store } from "redux";
import thunk, { ThunkDispatch, ThunkMiddleware } from "redux-thunk";
import { RootState, RootActions, createRootReducer } from "./reducers";
import { composeWithDevTools, EnhancerOptions } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { StoreRegistry } from "app2/src/storeRegistry";

export const history = createBrowserHistory({
  basename: "/",
});
StoreRegistry.set("history", history);

//TODO: Need to only do this is staging
const options: EnhancerOptions = { trace: true, traceLimit: 25 } as any as EnhancerOptions;
const composeEnhancers: typeof compose = composeWithDevTools(options);
export type RootDispatchType = ThunkDispatch<RootState, {}, RootActions>;
export type RootStoreType = Store<RootState, RootActions> & { dispatch: RootDispatchType };
export type RootGetState = () => RootState;

export const store: RootStoreType = createStore<RootState, RootActions, ThunkMiddleware<RootState, RootActions>, {}>(
  createRootReducer(history),
  //required to make all 'future' typings happy
  //@ts-ignore
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history))),
);
