import { ICustomProduct } from "app/src/Models/Product";
import { IImage } from "app/src/Models/Image";
import { IDoc } from "app/src/Models/Doc";
import { AddEstimateLineItemOptionCmd } from "app/src/Commands/Estimator/AddEstimateLineItemOptionCmd";
import { AddEstimateLineItemCmd } from "app/src/Commands/Estimator/AddEstimateLineItemCmd";
import { IEstimateGroup } from "app/src/Models/EstimateGroup";
import { FlashLevels, IFlash } from "app/src/Common/FlashService";
import { EstimatorService } from "app/src/Estimator/EstimatorService";
import { IEventingFactory } from "app/src/Common/EventingFactory";
import { IFileQueueFactory } from "app/src/Common/FileQueueFactory";
import { IEstimate } from "app/src/Models/Estimate";
import { IRepository, IRsfResource } from "app/src/Common/Repository";
import { IBaseConfig } from "../Common/IBaseConfig";
import { IEstimateLineItem } from "./EstimateLineItem";
import { ProductRecord } from "app2/src/records/Product";
import { ProductOptionRecord } from "app2/src/records/ProductOption";
import { cachedProductOptionGroup } from "app2/src/selectors/productOptionGroup.selectors";
import { useState, useSelector } from "app2/src/storeRegistry";
import { List, Map } from "immutable";
import { RoomRecord } from "app2/src/records/Room";

export interface IEstimateTemplateResponse extends ng.resource.IResourceArray<IEstimateTemplate> {
  estimate_templates: IEstimateTemplate[];
  meta: any;
}

export interface IEstimateTemplateGroups {
  groups: IEstimateTemplateGroup[];
}

export interface IEstimateTemplateGroup {
  name: string;
  included: boolean;
  sort_order: number;
  items: string[];
  item_uuids: {};
  products: ProductRecord[];
  optionsByProductId: Map<number, ProductOptionRecord[]>;
  custom_products: ICustomProduct[];
  line_item_order?: Array<{ type: string; order: string }>;
}

export class EstimateTemplateGroup implements IEstimateTemplateGroup {
  public name: string;
  public included: boolean;
  public sort_order: number;
  public items: string[];
  public item_uuids: {};
  public products: ProductRecord[] = [];
  public optionsByProductId: Map<number, ProductOptionRecord[]>;
  public custom_products: ICustomProduct[];
  public line_item_order?: Array<{ type: string; order: string }>;

  constructor(jsonData?: any) {
    if (jsonData) {
      this.name = jsonData.name;
      this.included = jsonData.included;
      this.sort_order = jsonData.sort_order;
      this.items = jsonData.items;
      this.item_uuids = jsonData.item_uuids;
      this.custom_products = jsonData.custom_products;
      this.line_item_order = jsonData.line_item_order;
    }
  }
}

export interface IEstimateTemplate extends ng.resource.IResource<IEstimateTemplate>, EstimateTemplatePrototype {
  $update(): ng.IPromise<IEstimateTemplate>;
}

export interface IEstimateTemplateResource extends ng.resource.IResourceClass<IEstimateTemplate>, IRsfResource {
  create?(params: any, data?: any): IEstimateTemplate;
  fromJSON?(data: any): IEstimateTemplate;
}

class EstimateTemplatePrototype {
  public classy: string;
  public id: number;
  public name: string;
  public template: IEstimateTemplateGroups;
  public updated_at: Date;
  public sort_order: number;
  public images: IImage[];
  public documents: IDoc[];
  public kind: "standard" | "interior";

  // Used for adding interior templates
  public selectedRoomIds: List<number>;
  public groupRooms: boolean;

  public addTemplate(
    estimate: IEstimate,
    EstimatorService: EstimatorService,
    FileQueue: IFileQueueFactory,
    EventingFactory: IEventingFactory,
    $q: ng.IQService,
    Flash: IFlash,
  ): ng.IPromise<any> {
    let product_count = 0;
    let item_count = 0;
    const promises = [];
    const overall = $q.defer();
    promises.push(overall);
    _.each(this.template.groups, (g: IEstimateTemplateGroup) => {
      product_count += g.products.length;
      item_count += Object.keys(g.item_uuids).length;
      if (g.custom_products) {
        item_count += g.custom_products.length;
      }

      const group_index = _.findIndex(_.pluck(estimate.estimateGroups(g.included), "name"), (name) => {
        return name === g.name;
      });
      let group: IEstimateGroup;
      if (group_index >= 0) {
        group = estimate.estimateGroups(g.included)[group_index];
      } else {
        EventingFactory.trackEvent("estimate group created", {
          estimate_group_name: g.name,
          estimate_template: this.id,
        });
        group = estimate.createGroup({ name: g.name, included: g.included });
      }

      _.each(g.products, (p: ProductRecord) => {
        const cmd = new AddEstimateLineItemCmd(estimate, p, group, EstimatorService);
        cmd.execute();
        cmd.lineItem.newly_added = false;
        EventingFactory.trackEvent("product added", {
          product: p.id,
          estimate_group_name: g.name,
          estimate_template: this.id,
        });
        const fileDefer = $q.defer();
        promises.push(fileDefer.promise);
        this.addCustomLineItemFiles(this, cmd.lineItem, p, $q, FileQueue).finally(() => {
          fileDefer.resolve(true);
        });

        if (g.optionsByProductId && g.optionsByProductId.get(p.id)) {
          _.each(g.optionsByProductId.get(p.id), (po: ProductOptionRecord) => {
            const pog = useSelector((state) =>
              cachedProductOptionGroup(state, {
                productOptionGroupId: po.option_group_id,
                activatedPriceListId: estimate.activated_price_list_id,
              }),
            );
            const cmd_po = new AddEstimateLineItemOptionCmd(po, pog, cmd.lineItem, estimate);
            cmd_po.execute();
            EventingFactory.trackEvent("product option added", {
              estimate_template: this.id,
              product_option: po.id,
              product: p.id,
            });
          });
        }
      });
    });
    overall.resolve(true);
    return $q.all(promises).finally(() => {
      if (product_count >= item_count) {
        Flash.addMessage(FlashLevels.success, "My Estimate Template Successfully Added to Estimate.");
      } else {
        Flash.addMessage(
          FlashLevels.warning,
          product_count + " out of possible " + item_count + " products were added.",
        );
      }
    });
  }

  public findGroupByRooms(estimate: IEstimate, g: IEstimateTemplateGroup, rooms: List<RoomRecord>) {
    const estimateGroups = estimate.estimateGroups(g.included);

    const roomIds = rooms.map((r) => r.id).sort();

    return estimateGroups.findIndex((estimateGroup) => {
      const groupRoomIds = List(estimateGroup.room_estimations.map((roomEstimation) => roomEstimation.room_id)).sort();

      return groupRoomIds.equals(roomIds);
    });
  }

  public addInteriorTemplate(
    estimate: IEstimate,
    EstimatorService: EstimatorService,
    FileQueue: IFileQueueFactory,
    EventingFactory: IEventingFactory,
    $q: ng.IQService,
    Flash: IFlash,
    rooms: List<RoomRecord>,
    showMessage = true,
  ): ng.IPromise<any> {
    let product_count = 0;
    let item_count = 0;
    const promises = [];
    const overall = $q.defer();
    promises.push(overall);

    _.each(this.template.groups, (g: IEstimateTemplateGroup) => {
      product_count += g.products.length;
      item_count += Object.keys(g.item_uuids).length;
      if (g.custom_products) {
        item_count += g.custom_products.length;
      }

      const group_index = this.findGroupByRooms(estimate, g, rooms);

      let group: IEstimateGroup;
      if (group_index >= 0) {
        group = estimate.estimateGroups(g.included)[group_index];
      } else {
        const groupName = rooms.size === 1 ? rooms.get(0).name : rooms.map((r) => r.name).join(", ");
        EventingFactory.trackEvent("estimate group created", {
          estimate_group_name: groupName,
          estimate_template: this.id,
        });

        group = estimate.createGroup({ name: groupName, included: g.included, rooms: rooms.toJS() });
      }

      _.each(g.products, (p: ProductRecord) => {
        const cmd = new AddEstimateLineItemCmd(estimate, p, group, EstimatorService);
        cmd.execute();
        cmd.lineItem.newly_added = false;
        EventingFactory.trackEvent("product added", {
          product: p.id,
          estimate_group_name: g.name,
          estimate_template: this.id,
        });
        const fileDefer = $q.defer();
        promises.push(fileDefer.promise);
        this.addCustomLineItemFiles(this, cmd.lineItem, p, $q, FileQueue).finally(() => {
          fileDefer.resolve(true);
        });

        if (g.optionsByProductId && g.optionsByProductId.get(p.id)) {
          _.each(g.optionsByProductId.get(p.id), (po: ProductOptionRecord) => {
            const pog = useSelector((state) =>
              cachedProductOptionGroup(state, {
                productOptionGroupId: po.option_group_id,
                activatedPriceListId: estimate.activated_price_list_id,
              }),
            );
            const cmd_po = new AddEstimateLineItemOptionCmd(po, pog, cmd.lineItem, estimate);
            cmd_po.execute();
            EventingFactory.trackEvent("product option added", {
              estimate_template: this.id,
              product_option: po.id,
              product: p.id,
            });
          });
        }
      });
    });
    overall.resolve(true);
    return $q.all(promises).finally(() => {
      if (product_count >= item_count) {
        if (showMessage) Flash.addMessage(FlashLevels.success, "My Estimate Template Successfully Added to Estimate.");
      } else {
        Flash.addMessage(
          FlashLevels.warning,
          product_count + " out of possible " + item_count + " products were added.",
        );
      }
    });
  }

  public addCustomLineItemFiles(est_template, lineItem, p, $q, FileQueue): ng.IPromise<any> {
    const promises = [];
    const token = useState().getIn(["auth", "token"]);
    _.each(["image", "document"], (type) => {
      if (p[type + "_ids"]) {
        _.each(p[type + "_ids"], (id) => {
          const fileDefer = $q.defer();
          promises.push(fileDefer.promise);

          const object: any = _.clone(
            _.find(est_template[type + "s"], (obj: any) => {
              return obj.id === id;
            }),
          );
          if (!object) {
            fileDefer.resolve(true);
            return;
          }
          const request = new XMLHttpRequest();
          request.open("GET", object.file.url, true);
          request.responseType = "blob";
          request.setRequestHeader("occ-authorization", "Bearer " + token);
          request.onload = function () {
            const file = request.response;
            file.name = object.name;
            file.original_filename = object.name;
            FileQueue.getObject(file, lineItem).then(() => {
              FileQueue.setQueue();
              fileDefer.resolve(true);
            });
          };
          request.send();
        });
      }
    });
    return $q.all(promises);
  }

  public uploadFromLineItemFiles(
    estimate: IEstimate,
    FileQueue: IFileQueueFactory,
    $q: ng.IQService,
    EventingFactory: IEventingFactory,
    EstimatorService: EstimatorService,
  ): ng.IPromise<any> {
    const uploadPromises = [];
    const token = useState().getIn(["auth", "token"]);
    _.each(["image", "document"], (type: string) => {
      _.each(estimate.lineItems(), (li: IEstimateLineItem) => {
        const files = li[type + "s"];
        if ((_.isUndefined(li.product_id) || _.isNull(li.product_id)) && files.length > 0) {
          _.each(files, (f: any) => {
            if (f.id > 0) {
              const fileDefer = $q.defer();
              uploadPromises.push(fileDefer.promise);
              const request = new XMLHttpRequest();
              request.open("GET", f.file.url, true);
              request.responseType = "blob";
              request.setRequestHeader("occ-authorization", "Bearer " + token);
              const app = this;
              const file = f;
              request.onload = function () {
                const blob = request.response;
                blob.name = file.name;
                blob.original_filename = file.name;
                FileQueue.uploadFile(type, "estimate_templates", app.id, blob, file.sort_order).then((respObj) => {
                  const group = _.find(app.template.groups, (g) => {
                    return g.sort_order === li.estimateGroup.sort_order;
                  });
                  const custom_product = _.find(group.custom_products, (cp) => {
                    return cp.sort_order === li.sort_order;
                  });
                  custom_product[type + "_ids"].push(respObj.id);
                  EventingFactory.trackEvent(type + " added from saved line item", {
                    estimate_template: app.id,
                    [type]: respObj.id,
                  });

                  EstimatorService.updateTemplate(app as any as IEstimateTemplate).finally(() => {
                    fileDefer.resolve(true);
                  });
                });
              };
              request.send();
            }
          });
        }
      });
    });
    return $q.all(uploadPromises);
  }
}

let resources: IRepository;

const factory = ($resource: ng.resource.IResourceService, BaseConfig: IBaseConfig) => {
  const url = BaseConfig.BASE_URL + "/api/v1/orgs/:org_id/estimate_templates/:id";

  const transformMultiple = (response: string, headers: ng.IHttpHeadersGetter, status: number) => {
    if (status < 200 || status > 299) {
      return JSON.parse(response);
    }

    const meta = angular.fromJson(response);

    _.each(meta.estimate_templates, (et, index) => {
      meta.estimate_templates[index] = EstimateTemplate.fromJSON(et);
    });

    return meta;
  };

  const transformSingle = (data) => {
    const jsonData = angular.fromJson(data);
    return EstimateTemplate.fromJSON(jsonData.estimate_template);
  };

  const EstimateTemplate: IEstimateTemplateResource = <IEstimateTemplateResource>$resource(
    url,
    { org_id: "@org_id", id: "@id" },
    {
      get: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformSingle,
      },
      create: <ng.resource.IActionDescriptor>{
        method: "POST",
        transformResponse: transformSingle,
        isArray: false,
      },
      update: <ng.resource.IActionDescriptor>{
        method: "PATCH",
        transformResponse: transformSingle,
        isArray: false,
      },
      delete: <ng.resource.IActionDescriptor>{
        method: "DELETE",
        isArray: false,
      },
      query: <ng.resource.IActionDescriptor>{
        method: "GET",
        transformResponse: transformMultiple,
        isArray: false,
      },
    },
  );

  EstimateTemplate.fromJSON = (data: any) => {
    const et = new EstimateTemplate(data);
    et.classy = "EstimateTemplate";

    if (et.template && _.isArray(et.template.groups)) {
      _.each(et.template.groups || [], (etg, index) => {
        et.template.groups[index] = new EstimateTemplateGroup(etg);
      });
    }

    if (_.isArray(et.images)) {
      _.each(et.images, (image: IImage, index: number) => {
        et.images[index] = resources.Image.fromJSON(image);
      });
    }

    return et;
  };

  _.hiddenExtend(EstimateTemplate.prototype, EstimateTemplatePrototype.prototype);

  EstimateTemplate.inject = (injected: IRepository) => {
    resources = injected;
  };

  return EstimateTemplate;
};

factory.$inject = ["$resource", "BaseConfig"];

export default factory;
