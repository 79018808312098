import * as React from "react";
import * as config from "react-global-configuration";
import { Row, Col } from "react-bootstrap";
import { FormView } from "./FormView";
import { UserInvitationViewProps } from "../UserInvitationContainer";

export class InvitationView extends React.Component<UserInvitationViewProps, {}> {
  public render() {
    const appName = config.get("APP_NAME");

    return (
      <Row>
        <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
          <div className="form-section text-center">
            <div className="form-section-heading">
              <h3>Welcome to {appName}</h3>
            </div>
            <div className="form-section-content">
              <p>
                To accept your invitation, please enter your desired password in both of the boxes below and click "Set
                Password".
              </p>

              <FormView {...this.props} enforceTos={config.get("ENFORCE_TOS")} />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
