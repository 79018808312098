import * as React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { MeasurementTable } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/MeasurementTable";
import { RsfForm } from "@tberrysoln/rsf-form";
import { currentMeasurementId, rootKey } from "app2/src/selectors/measurement.selectors";
import { Actions } from "app2/src/reducers/measurement.actions";
import { useSelector } from "react-redux";
import { RootState } from "app2/src/reducers";
import { RoofFaces } from "app2/src/components/JobTabs/MeasurementTab/Exteriors/RoofFaces";
import * as FontAwesome from "react-fontawesome";
import { Map } from "immutable";
import { useTracking } from "react-tracking";

export const roofingTotal1 = [
  "roof_total_area",
  "roof_5pw_total_area",
  "roof_10pw_total_area",
  "roof_12pw_total_area",
  "roof_15pw_total_area",
  "roof_20pw_total_area",
  "roof_flat_area",
  "roof_high_roof_area",
  "roof_flat_shallow_area",
  "roof_low_slope_area",
  "roof_average_slope_area",
  "roof_steep_slope_area",
  "roof_ultra_steep_slope_area",
  "roof_sloped_area",
  "roof_10pw_sloped_area",
  "roof_79_area",
  "roof_1012_area",
  "roof_13p_area",
];

export const roofingTotal2 = [
  "roof_ridge",
  "roof_hip",
  "roof_ridge_hip",
  "roof_valley",
  "roof_downspout_elbows",
  "roof_downspouts",
  "roof_gutter_miters",
  "roof_eave",
  "roof_rake",
  "roof_perimeter",
  "roof_step_flashing",
  "roof_headwall_flashing",
  "roof_total_flashing",
  "roof_valley_eave",
  "roof_valley_eave_rake",
];

export const roofAreas1 = [
  "roof_0_area",
  "roof_1_area",
  "roof_2_area",
  "roof_3_area",
  "roof_4_area",
  "roof_5_area",
  "roof_6_area",
  "roof_7_area",
  "roof_8_area",
  "roof_9_area",
  "roof_10_area",
  "roof_11_area",
  "roof_12_area",
];

export const roofAreas2 = [
  "roof_13_area",
  "roof_14_area",
  "roof_15_area",
  "roof_16_area",
  "roof_17_area",
  "roof_18_area",
  "roof_19_area",
  "roof_20_area",
  "roof_21_area",
  "roof_22_area",
  "roof_23_area",
  "roof_24_area",
  "roof_25_area",
  "roof_26p_area",
];

export const Roofing: React.FC = () => {
  // Hooks
  const { trackEvent } = useTracking<any>({ component: "Roofing" });

  // Local State
  const [accordionControl, setAccordionControl] = React.useState(
    Map({ roofingTotal: "roofingTotal", roofAreasByPitch: "" }),
  );

  // Selectors
  const measurementId = useSelector((state: RootState) => currentMeasurementId(state, {}));

  // Methods
  const toggleAccordion = (key: string) => {
    if (accordionControl.get(key) === "") {
      trackEvent({ action: "open accordion", accordionName: key });
      setAccordionControl(accordionControl.set(key, key));
    } else {
      trackEvent({ action: "close accordion", accordionName: key });
      setAccordionControl(accordionControl.set(key, ""));
    }
  };

  return (
    <RsfForm rootPath={[rootKey, "byId", measurementId]} updateFormReducer={Actions.updateForm} onSubmit={() => {}}>
      <Row>
        <Col>
          <h2>Roofing</h2>
        </Col>
      </Row>

      <Accordion activeKey={accordionControl.get("roofingTotal")}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="roofingTotal"
            onClick={() => toggleAccordion("roofingTotal")}>
            Roofing Total
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("roofingTotal") !== "" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="roofingTotal">
            <Card.Body>
              <Row>
                <Col lg={6}>
                  <MeasurementTable values={roofingTotal1} />
                </Col>
                <Col lg={6}>
                  <MeasurementTable values={roofingTotal2} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <br />
      <Accordion activeKey={accordionControl.get("roofAreasByPitch")}>
        <Card>
          <Accordion.Toggle
            className="link"
            as={Card.Header}
            eventKey="roofAreasByPitch"
            onClick={() => toggleAccordion("roofAreasByPitch")}>
            Roof Areas by Pitch
            <span className="pull-right">
              <FontAwesome
                name={`${accordionControl.get("roofAreasByPitch") !== "" ? "angle-up" : "angle-down"}`}
                title="Expand"
                size="lg"
                className="pull-right"
              />
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="roofAreasByPitch">
            <Card.Body>
              <Row>
                <Col>
                  <MeasurementTable values={roofAreas1} />
                </Col>
                <Col>
                  <MeasurementTable values={roofAreas2} />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <br />
      <RoofFaces />
    </RsfForm>
  );
};
