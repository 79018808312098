import { RootState } from ".";
import { ThunkAction } from "redux-thunk";
import { handleErrors } from "app2/src/reducers/Utils";
import {
  ICreateOptions,
  IValidateSignedDocumentResponse,
  create,
  loadSigned,
  notify,
  validate,
} from "../api/signedDocument.service";
import { ISignedDocumentData } from "../records/SignedDocument";
import { RecipientRecord } from "../records/Recipient";
import { RootDispatchType } from "../store";
import { ActionsUnion, createAction } from "./Utils";
import * as commonActions from "app2/src/reducers/components/common.actions";
import { FlashLevels } from "app/src/Common/FlashLevels";
import * as documentActions from "app2/src/reducers/document.actions";
import * as estimateActions from "app2/src/reducers/estimate.actions";
import * as taskActions from "app2/src/reducers/task.actions";
import * as paginationActions from "app2/src/reducers/pagination.actions";
import { QueryParamsRecord } from "../records/Page";
import { jobOverviewEstimates } from "../selectors/estimate.selectors";
import { getPaginationByModel } from "../selectors/pagination.selectors";
import { DocumentRecord } from "../records/Document";
import { List } from "immutable";
import { estimateSignedDocumentFromJSON, EstimateSignedDocumentRecord } from "../records/EstimateSignedDocument";
import { EstimateOverviewRecord } from "../records/EstimateOverview";
import { currentSignedDocument, currentSignedDocumentId } from "../selectors/signedDocument.selectors";
import { Nullable } from "app2/src/records";
import { format } from "date-fns";
import { contractTitle } from "app2/src/selectors/org.selectors";
import { currentJob } from "app2/src/selectors/job.selectors";

export const SET_CURRENT_ID = "@signedDocuments/SET_CURRENT_ID";
export const FETCH_DOCUMENT = "@signedDocuments/FETCH_DOCUMENT";
export const RECEIVE_DOCUMENT = "@signedDocuments/RECEIVE_DOCUMENT";
export const SET_DOCUMENT_LOADED = "@signedDocuments/SET_DOCUMENT_LOADED";
export const VALIDATE = "@signedDocuments/VALIDATE";
export const INIT_RECIPIENT_INFO = "@signedDocuments/INIT_RECIPIENT_INFO";
export const UPDATE_FORM = "@signedDocuments/UPDATE_FORM";
export const INIT_RECIPIENTS = "@signedDocuments/INIT_RECIPIENTS";
export const CREATE_SIGNED_DOCUMENT = "@signedDocuments/CREATE_SIGNED_DOCUMENT";
export const CLEAR_SIGNED_DOCUMENT = "@signedDocuments/CLEAR_SIGNED_DOCUMENT";

export const Actions = {
  setCurrentSignedDocumentId: (id: number) => createAction(SET_CURRENT_ID, { id }),
  fetchDocument: (id: number) => createAction(FETCH_DOCUMENT, { id }),
  receiveDocument: (document: Partial<ISignedDocumentData>) => createAction(RECEIVE_DOCUMENT, { document }),
  setDocumentLoaded: (id: number, status?: Nullable<number>) => createAction(SET_DOCUMENT_LOADED, { id, status }),
  validate: (documentId: number) => createAction(VALIDATE, { documentId }),
  initRecipientInfo: (recipient: RecipientRecord, id: number, index: number) =>
    createAction(INIT_RECIPIENT_INFO, { recipient, id, index }),
  updateForm: (event: { rootPath: (string | number)[]; name: string; value: any }) =>
    createAction(UPDATE_FORM, { event }),
  initRecipients: (id: number, validations: any) => createAction(INIT_RECIPIENTS, { id, validations }),
  createSignedDocument: (response: IValidateSignedDocumentResponse, estimateId: Nullable<number>) =>
    createAction(CREATE_SIGNED_DOCUMENT, { response, estimateId }),
  clearSignedDocument: (id: number) => createAction(CLEAR_SIGNED_DOCUMENT, { id }),
};

type ThunkResult<T> = ThunkAction<T, RootState, undefined, Actions>;

export const AsyncActions = {
  loadSignedDocument: (documentId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        dispatch(Actions.fetchDocument(documentId));
        const { signed_document } = await loadSigned(documentId);
        dispatch(Actions.receiveDocument(signed_document));
      } catch (response) {
        handleErrors(response, dispatch);
        dispatch(Actions.setDocumentLoaded(documentId));
      }
    };
  },
  notify: (documentId: number, recipient?: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        await notify(documentId, recipient);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Signature Document successfully sent."));
      } catch (response) {
        handleErrors(response, dispatch);
      }
    };
  },
  listEstimateSignedDocuments: (jobId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        await dispatch(estimateActions.AsyncActions.getJobEstimates(jobId));
        await dispatch(
          documentActions.AsyncActions.listDocuments(
            { documentableId: jobId, documentableType: "job", signed: "signed", signed_kind: "contract" },
            new QueryParamsRecord(),
            "estimateDocuments",
          ),
        );
        const estimateOverviews: List<EstimateOverviewRecord> = jobOverviewEstimates(getState(), { jobId });
        const documents: List<DocumentRecord> = getPaginationByModel(getState(), {
          modelName: "estimateDocuments",
          path: ["documents", "byId"],
        });

        const estimateSignedDocuments: List<EstimateSignedDocumentRecord> = estimateOverviews
          .map((estimateOverview) => {
            const estimateDocuments = documents.filter((d) => d.signed_document.estimate_id === estimateOverview.id);
            if (estimateDocuments.size > 0)
              return estimateSignedDocumentFromJSON({
                estimateId: estimateOverview.id,
                signedDocumentIds: estimateDocuments.map((d) => d.id).toJS(),
              });
          })
          .filter((record) => record); // filter undefined

        dispatch(estimateActions.Actions.setEstimateSignedDocuments(jobId, estimateSignedDocuments));
      } catch (error) {
        handleErrors(error, dispatch);
      }
    };
  },
  validateDocument: (
    document: DocumentRecord,
    isModified: boolean,
  ): ThunkResult<Promise<IValidateSignedDocumentResponse>> => {
    return async (dispatch: RootDispatchType) => {
      try {
        if (document.id) {
          dispatch(documentActions.Actions.fetchDocument(document.id));
        }

        const response = await validate(document, isModified);
        dispatch(documentActions.Actions.receiveDocument(response.document));
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.success, "Document validated successfully"));
        return response;
      } catch (response) {
        dispatch(documentActions.Actions.resetDocument(document.id));

        const detailedError = response.data?.errors?.[0]?.message;
        if (detailedError) {
          handleErrors({ ...response, data: { errors: detailedError } }, dispatch);
        } else {
          handleErrors(response, dispatch);
        }

        return Promise.reject(response);
      }
    };
  },
  validateSignedDocument: (
    document: DocumentRecord,
    isModified = false,
    estimateId?: number,
  ): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType) => {
      const signedDocumentId = -1;

      try {
        dispatch(Actions.setCurrentSignedDocumentId(signedDocumentId));
        dispatch(Actions.fetchDocument(signedDocumentId));
        const response = await dispatch(AsyncActions.validateDocument(document, isModified));

        dispatch(Actions.createSignedDocument(response, estimateId));
      } catch (response) {
        dispatch(Actions.setDocumentLoaded(signedDocumentId, response.status));
      }
    };
  },
  combineDocument: (document_ids: List<number>, name: string, jobId: number): ThunkResult<Promise<void>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      try {
        const documentId = await dispatch(documentActions.AsyncActions.combine(document_ids, name, jobId));
        const id = currentSignedDocumentId(getState());
        dispatch(
          Actions.updateForm({
            rootPath: ["signedDocuments", "byId", id],
            name: "document_id",
            value: documentId,
          }),
        );
      } catch (response) {
        const errors = handleErrors(response);
        dispatch(commonActions.Actions.flashAddAlert(FlashLevels.danger, errors));
      }
    };
  },
  contractFlowCreate: (document: DocumentRecord, options: ICreateOptions): ThunkResult<Promise<number>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const state = getState();
      const job = currentJob(state);
      const orgContractName = contractTitle(state);
      const name = job.name + " - " + format(new Date(), "MM-dd-Y HH:mm") + " - " + orgContractName + ".pdf";

      return dispatch(AsyncActions.create(document.set("name", name), options));
    };
  },
  create: (document: DocumentRecord, options: ICreateOptions): ThunkResult<Promise<number>> => {
    return async (dispatch: RootDispatchType, getState: () => RootState) => {
      const signedDocument = currentSignedDocument(getState());
      try {
        dispatch(Actions.fetchDocument(signedDocument.id));
        const { signed_document: sd } = await create(document, signedDocument, options);
        dispatch(Actions.setCurrentSignedDocumentId(sd.id));
        dispatch(Actions.receiveDocument(sd));
        dispatch(documentActions.Actions.receiveDocument(sd.document));
        // TODO: make modelName configurable
        dispatch(paginationActions.Actions.pushId(sd.document.id, "signableDocuments"));
        await dispatch(taskActions.AsyncActions.pollTask(sd.document.task));
        return sd.id;
      } catch (response) {
        dispatch(Actions.setDocumentLoaded(signedDocument.id));
        handleErrors(response, dispatch);
      }
    };
  },
};

export type Actions = ActionsUnion<typeof Actions>;
